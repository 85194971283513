import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import RegularInput from 'components/Input/RegularInput';
import { onBlurEmail } from 'components/auth/utils';
import { Button } from 'components/elements/button/Button';
import { GilroyFontWrapper } from 'shared/theme/style';


const LoginForm = styled.form`
background:white;
height: 100vh;
position: fixed;
overflow: hidden;
width: 100%;
.heading{
  padding: 33px 0px 32px 16px;
  color: #283241;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.form{
    padding: 12px 16px 0px 16px;
    width: 39%;
    display: flex;
    flex-direction: column;
    margin: auto;
   }
  .forget-password-wrapper{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 32px;
      a{
      color: #00092D;
      text-align: right;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      }
  } 
  .footer{
    color: #51635D;
    text-align: center;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 32px;
  }
  a{
   text-decoration: underline !important;
   text-underline-offset: 3px;
  }
  .button{
    max-width: 280px;
  }

    @media (max-width: 991px) {
      .form{
        width: 100% !important;
      }
    }
`
const FormGroupStyle = styled.div`
  margin-bottom: 8px;
  width: 100%;
    label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom:9px;
    display: inline-block;
    }
   input {
    height: 44px;
    border-radius: 6px;
    border: 1px solid #E0E3E0;
      :hover, :focus {
         border-color: #00092D !important;
      }
      :focus::placeholder {
        color: transparent;
      }
      ::placeholder{
        color: #949D9B;
        font-style: normal;
        font-weight: 500;
      }
    }
  .value {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    }
`;

export default function Login() {
  const { afterLogin, isMobile, isSaving, loadPantryMeals, getdiaryData, isGetMealLoading, TimeZone, setLogin } = useAppContext();
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [login, { isLoading, error, setError }] = useRequest('auth/login', 'post');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isEmailValid = (email) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const onChangeEmail = (value, setEmail) => {
    if (!value) return setEmail({ value, error: 'Email is required' });
    const { error } = email;
    if (error === 'Invalid email' && !isEmailValid(value)) return setEmail({ value, error });
    setEmail({ value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { value: emailValue, error: emailError } = email;
    if (!emailValue) setEmail({ value: emailValue, error: 'Email is required' });

    const { value: passwordValue, error: passwordError } = password;
    if (!passwordValue) setPassword({ value: passwordValue, error: 'Password is required' });

    if (emailError || passwordError) return setError('Some fields are empty or invalid');
    await login({
      body: { email: emailValue, password: passwordValue, TimeZone },
    }).then(async (loginRes) => {
      if (loginRes?.user?.type.toString() === 'user') {
        localStorage.setItem('user', JSON.stringify(loginRes.token));
        isMobile && await getdiaryData()
        await loadPantryMeals().then((response) => {
          afterLogin(loginRes);
          setLogin(true);
        })
      } else {
        afterLogin(loginRes);
        setLogin(true);
      }
    }).catch((err) => {
      console.log(err);
    })
    localStorage.setItem('steps', 'true');
    window.scrollTo(0, 0);
  };

  console.log(error,'error')
  return (
    <>
     <GilroyFontWrapper>
      <LoginForm >
        <div className='heading'>Nutritt Merchant</div>
        <div className='form'>
          <FormGroupStyle>
            <label>Email</label>
            <RegularInput
              type="email"
              name="email"
              placeholder="Email Address"
              value={email.value}
              onChange={(e) => {onChangeEmail(e.target.value, setEmail),setError('')}}
              onBlur={(e) => onBlurEmail(e.target.value, setEmail)}
            />
          </FormGroupStyle>
          <FormGroupStyle>
            <label>Password</label>
            <RegularInput
              type="password"
              name="password"
              placeholder="Password"
              icon={!passwordVisible ? '/icons/eye-slash.svg' : '/icons/eye.svg'}
              onSuffixClick={togglePasswordVisibility}
              passwordVisible={passwordVisible}
              value={password.value}
              onChange={(e) => {setPassword({ value: e.target.value }),setError('')}}
              onBlur={(e) => {
                const { value } = e.target;
                if (!value) setPassword({ value, error: `Password is required` });
              }}
            />
          </FormGroupStyle>
          <div className="forget-password-wrapper">
            <Link to="/forgot-password">Forgot password?</Link> <br />
          </div>
          <text className='footer'>By logging in, you agree to Nutritt Merchant <br />
          <span className="link">
              <a onClick={() => nativeEvent("terms_and_services", "/users/terms-&-conditions")}> Security Terms</a>
            </span>
          .</text>
          <Button type={'mobile_save'} title="Log in" onClick={handleLogin} disabled={isLoading || isSaving ||isGetMealLoading || !email.value || !password.value}/>
        </div>
      </LoginForm>
      </GilroyFontWrapper>
    </>
  );
}