import { Description } from '@material-ui/icons';
import styled from 'styled-components';
import SubmitButton from 'components/SubmitButton';
import { useNutritionistContext } from 'context/nutritionistContext';

const EmptyCardWrapper = styled.div`
    .cardWrapper{
     display: flex;
     justify-content: center;
     align-items: center;
     height: 76vh;
     flex-direction: column;

     .textwrapper{
        font-family: 'Roboto';
        font-style: normal;
        text-align: center;
        h4{
            font-weight: 700;
            font-size: 24px;
            line-height: 28px; 
            color: #51635D;
            margin: 8% 0 3% 0;
        }
        p{
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #949D9B;
        }
     }
     .inviteButton{
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        width: ${props => props.followersBtn? "210px" : props.offeringBtn ? "139px" : "179px"};
        height: 40px;
        margin: unset;
     }
     .buttonWrapper{
        width: 71%;
        display: flex;
        justify-content: center;
        margin-top: 3%;
     }
    }

    @media(max-width: 991px){
     .cardWrapper{
        background: #FFFFFF;
        height: 49vh;
        margin-top: 6%;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
        border-radius: 10px;
        padding: 7%;

        .textwrapper{
            h4{
                font-size: 18px;
                line-height: 24px;
            }
            p{
                font-weight: 400;
                font-size: 16px; 
                line-height: 19px;
                color: #949D9B;
            }
            .des1{
                margin-top: 7% !important;
            }
            .des3{
                margin-bottom: 10%;
            }
        }

        .buttonWrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 6px;
            justify-content: center;
            .inviteButton{
                width: 68%;
                height: 40px;
                background: #52C41A;
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
            }
            .inviteButton2{
                width: 62%;
                height: 40px;
                border: 1px solid #97DC76 !important;
                background: #FFFFFF;
                color: #51635D;
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
            }
      }
    }
`;
export default function ({ 
img,
alt,
title, 
description, 
buttonText, 
redirectLink,
description2,
description3,
redirectLink2,
buttonText2, 
offersList,
offeringBtn,
followersBtn
}) {
    const {imitateNutritionist} = useNutritionistContext()

    function disableBtn() {
        return imitateNutritionist?.userId === null
      }
      
    return (
        <EmptyCardWrapper 
        offeringBtn={offeringBtn} 
        followersBtn={followersBtn}>
            <div className="cardWrapper">
                <img src={img} alt={alt} />
                <div className="textwrapper">
                <h4>{title}</h4>
                <p className="des1">{description}</p>
                <p className="des2">{description2}</p>
                <p className="des3">{description3}</p>
                </div>
                <div className='buttonWrapper'>
                {offersList &&
                
                <SubmitButton
                  className="inviteButton2"
                  disabled={disableBtn()}
                  onClick={redirectLink2}>
                  {buttonText2}
                </SubmitButton>
                }   
                <SubmitButton
                  className="inviteButton"
                  disabled={disableBtn()}
                  onClick={redirectLink}>
                  {buttonText}
                </SubmitButton>
                </div>
            </div>
        </EmptyCardWrapper>
    )
};