import React, { useRef, useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import DietaryTags from './DietaryTags';
import Progress from '../../../../components/Progress';

const DietaryModalStyle = Styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
height:100%;
z-index: 114;
//padding-top: 21px;
transition: all .5s ease-in-out;
display: flex;
flex-direction: column;
align-items: center;
overflow:hidden;
font-family: 'Roboto';
font-style: normal;

::before{
    content:'';
    width:100%;
    height:100vh;
    position:absolute;
    left:0;
    background-color:#51635D;
    opacity:0.5;
}
.dietary-div{
  background:#ffffff;
  background: #ffffff;
  height: 100%;
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  overflow:auto;
  &::-webkit-scrollbar{
    display:none;
  }
  .cross-img{
     padding:24px 0 0 23px;
  }
  .dietary{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    margin-bottom:31px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: -20px;
  }
  .recomend{
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #51635D;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-bottom: 60px;
  }

  .apply-div{
    background: #FFFFFF;
    border-top: 1px solid #F6F5F8;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height:89px;
    width:100%;
    position: fixed;
    bottom: 0;
     button{
        width: 271px;
        height: 40px;
        background: #52C41A;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        cursor:pointer;
     }
  }
    
}
`;

export default function DietaryModal({ dietaryModal, setDietaryModal, dietTemplate}) {
  
  const [showAnim, setShowAnim] = useState(dietaryModal);
  const [allergies, setAllergies] = useState([]);
  const [getAllergies, {isLoading}] = useRequest('meal/allergies');
  const [meal, setMeal] = useState(false);
  const [selected, setSelected]= useState(0)
  const [notSelected, setNotSelected]= useState(0)
  const [showSelectedTags, setShowSelectedTags] = useState({ tags: [], restrictions: []});
 
  useEffect(() => {
    if (dietaryModal) {
      setShowAnim(true);
      getAllergies().then((as) => setAllergies(as));
    }
  }, [dietaryModal]);

  const onAnimationEnd = () => {
    if (!dietaryModal) setShowAnim(false);
  };
  
  const handleclose = ()=>{
     if((showSelectedTags?.tags.length || showSelectedTags?.restrictions.length) > 0){
      return (setShowAnim(false),setTimeout(() => {
        setDietaryModal(false)
        setShowSelectedTags({ tags: [], restrictions: []});
      }, 500) );
     }
     else{
      return
     }
  }
  const closedietaryModal = () => {
    setShowAnim(false);
    setTimeout(() => {
        setDietaryModal(false);
    }, 500)
  }
  
  return (

      <DietaryModalStyle className={showAnim ? "fadeInMiddle" : "fadeOutBottom"} >
       <div className='dietary-div'>
       <div className='cross-img'> <img src='/icons/close-cross-green.svg' onClick={closedietaryModal}/> </div>
       <div className='dietary'>
        <p>Dietary</p>
       </div>
      { isLoading ?
         (<Progress />) :
      (<div className='recomend'>
         <DietaryTags allTags={allergies} meal={meal} setMeal={setMeal} dietTemplate={dietTemplate} setSelected={setSelected} setNotSelected={setNotSelected} selected={selected} notSelected={notSelected} showSelectedTags={showSelectedTags} setShowSelectedTags={setShowSelectedTags} />
       </div>)}
       <div className='apply-div'>
        <button className={(showSelectedTags?.tags.length || showSelectedTags?.restrictions.length) >0  ? '' : 'disabled'} onClick={handleclose}>Apply</button>
       </div>
       </div>
      </DietaryModalStyle>
    
  )
}
