import React from 'react';
import { Skeleton } from '@mui/material';
import { KitchenCardWrapper } from 'components/elements/KitchenCard/style';
import { KitchensListWrapper } from 'components/shop/KitchenHome/KitchensList/style';

const HomeSkeleton = () => {
  return (
    <KitchensListWrapper>
      <div className="kitchens-list-wrapper">
        <div className="kitchens-title">
          <Skeleton
            variant="rectangular"
            style={{ borderRadius: '18px' }}
            width={148}
            height={18}
          />
        </div>
        <div className="kitchens-list-container">
          {Array.from({ length: 3 }, (_, index) => index + 1)?.map((item) => (
            <KitchenCardWrapper key={item}>
              <div className="card-img-wrapper">
                <Skeleton variant="rectangular" width="100%" height={192} />
              </div>
              <div className="card-details" style={{ height: '69px' }}></div>
            </KitchenCardWrapper>
          ))}
        </div>
      </div>
    </KitchensListWrapper>
  );
};

export default HomeSkeleton;
