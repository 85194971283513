import { useState, useEffect } from 'react';
import { useRequest } from 'hooks/useRequest';
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import { StyledForm, HasAccount } from 'components/auth/StyledLoginForm';
import { Link } from 'react-router-dom';
import { onChangeEmail, onBlurEmail, isEmailValid } from './utils';
import { useAppContext } from 'context/appContext';
import Header from 'layout/Header';
import GoogleLogin from 'react-google-login';
import Progress from 'components/Progress';
import { useLocation } from 'react-router-dom';
import {toast} from "react-toastify";
import styled from 'styled-components';

const RadioWrapper = styled.div`
 display: flex;
 flex-direction: row;
 margin-bottom: 22px;
 margin-top: 6px;
 .text-wrapper{
    width: 100%;
    margin-top: 12px !important;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    p{
      color: #51635D;
    }
    a {
      color: #52C41A !important;
      float: right;
    }
    .agree{
      margin-top: 6px !important;
    }
    .agree, .policy{
      display:flex;
    }
 }
 .MuiCheckbox-root{
  padding: 12px 5px 0 0 !important;
 } 
`;

export default function RegisterForm({
  register,
  isLoading,
  error,
  setError,
  nutritionistId,
  providerRegister,
  mealPlanId,
}) {
  const { afterLogin, setIsAppLoading, isMobile, setProfileObj, isSaving, TimeZone } = useAppContext();

  const { providerEmail, providerId, token } = providerRegister || {};
  const [name, setName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({ value: providerEmail || '', error: '' });
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [zip, setZip] = useState({ value: '', error: '' });
  const [agree, setAgree] = useState({ value: false, error: '' });
  const [getProviderName] = useRequest(`provider/provider/name/${providerId}`, 'post');
  const [registerUser, { isLoading: isRegestering }] = useRequest('visitor/register', 'post');
  const [googleRegister,{isLoading:googleLoading}]=useRequest("auth/googleSignup",'post')
  const { pathname } = useLocation();
  const isDesktop = !isMobile;

  useEffect(async () => {
    if (providerId) {
      const response = await getProviderName({ body: { email: providerEmail, providerId, token } });
      await
        setName ({value: response.name});
    }
    if(mealPlanId){
      history.pushState(null, document.title, location.href);
     window.addEventListener('popstate', function (event)
    {
     history.pushState(null, document.title, location.href);
    });
    }
  }, []);
  const isFormValid = () => {
    setError(null);
    const nameValue = name.value;
    const emailValue = email.value;
    const phoneNumberValue = phoneNumber.value;
    const passwordValue = password.value;
    const checkedValue = agree.value;
    const zipValue = zip.value;
    const validEmail = isEmailValid(email.value);

    if ((!nameValue && !providerEmail) || (!nameValue && !zipValue) || !nameValue || !emailValue || !validEmail || !phoneNumberValue || !passwordValue) {
      if (!nameValue) setName({ error: 'Name is required' });
      if(!emailValue && !validEmail) setEmail({error:  !emailValue ? 'Email is required' : 'Invalid Email'});
      if (!phoneNumberValue) setPhoneNumber({error: 'Phone Number is required'});
      if (!passwordValue) setPassword({ error: 'Password is required' });
      // if (!checkedValue) setAgree({ value: checkedValue, error: 'Agreement is required' });
      if (!zipValue) setZip({ value: zipValue, error: 'zip is required' });

      //Return if any errors
      setError('Some fields are empty or invalid');
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = isEmailValid(email.value);

  const validateUserInfo = (!name.value || !email.value || !validateEmail || !password.value);

  const disableSignupButton = (isLoading || isSaving || validateUserInfo || isRegestering || googleLoading); 

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;
    if(phoneNumber.value.length>13||phoneNumber.value.length<10){
      toast.error("Invalid Phone Number")
      return;
    }

    const res = await register({
      body: {
        name: name.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        password: password.value,
        agreeToTerms: agree.value,
        zip: zip.value,
        providerId,
        mealPlanId,
        nutritionistId,
        invitecode: token,
        TimeZone
      },
    });
    await afterLogin(res);
    
    if(res?.user?.type[0]=="user"){
      const name=res?.user.profile.name.split(' ');
      await window.analytics.identify(res?.user.id, {
        firstName: name[0],
        lastName:name[1]?name[1]:"" ,
        email: email.value,
      usersignup: res?.user?.profile?.createdAt,
      usertype: res?.user?.type[0],
      lifecycle:'new'
      })
      
    }
   
    // 'userId12345' is your internal ID for the user, this should be persistent throughout the life of the customer.
    // usertype - determines if the user is a customer (user), coach, provider etc. 
    // Lifecycle - determines where the user is on the purchase funnel
    
    // setIsAppLoading(true);
    // history.push('/nutritionist/login');
    // window.location.href = '/onboarding';
  }

  const handleRegVisitor = async (e) => {
    e.preventDefault();
    if (phoneNumber.value && (phoneNumber?.value.length > 13 || phoneNumber?.value.length < 10)) {
      toast.error("Invalid Phone Number")
      return;
    }

    if (name.value || email.value || !validateEmail || password.value){
    registerUser({
      body: {
        name: name.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        password: password.value,
        TimeZone
      },
    }).then((data) => {
      afterLogin(data);

    }).catch((e) => {
      toast.error(e);
    });
    }
  };

  const handleGoogleSignup=async (googleRes)=>{
    try {
       googleRegister({
        body:{
          googleRes:{responseGoogle:googleRes},
          nutritionistId,
          mealPlanId,
          TimeZone
        }
       }).then((res)=>{
         toast.success('user registered successfully')
          afterLogin(res);
       }).catch((err)=>{
        toast.error(err)
       })
    } catch (error) {
      toast.error('something went wrong')
      console.log(error)
    }
  }

  const handleTerms = () => {
    setAgree({...agree,value:!agree.value})
  };

  return (
    <>
      {!mealPlanId && !providerEmail && <Header/>}
      {/* {(mealPlanId && !isMobile) && <Header/>} */}
      <StyledForm onSubmit={pathname=='/signup' ? handleRegVisitor : handleRegister} autocomplete={true} noValidate>
      {!mealPlanId && <div className='heading'>Sign up</div>}
      {(mealPlanId && !isMobile) && <div className='heading'>Sign up</div>}
        <div className='form-wrapper'>
          {(isSaving || isRegestering || isLoading) && <Progress />}
        {isMobile && 
        <>
        <div className='google-login'>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Continue with Google"
          onSuccess={(responseGoogle)=>{
            console.log(responseGoogle)
            if(mealPlanId && nutritionistId){
              handleGoogleSignup(responseGoogle)
            } else {
              setProfileObj({ responseGoogle });
            }
          }}
          onFailure={(responseGoogle)=>{
            console.log({responseGoogle, fail: true})
          }}
          cookiePolicy={'single_host_origin'}
        />
        </div>
        <div className='or-login'>
            <img src='/icons/or.svg' alt='or login' />
        </div>
        </>
        }  
        <Input
          label="Name"
          name="name"
          value={name.value}
          onChange={(e) => {
            const inputValue = e.target.value.slice(0, 50); 
            setName({ value: inputValue });
        }}
          bordered
          required
          disabled={providerId}
          onBlur={(e) => {
            const { value } = e.target;
            if (!value) setName({ value, error: `Name is required` });
          }}
        />
        <h5 style={{color: '#e0242f', fontSize : '10px'}}>{name.error}</h5>
        <Input
          label={(mealPlanId || providerEmail) ? "Phone Number" : "Phone Number (Optional)"}
          type= "number"
          name="phoneNumber"
          value={phoneNumber.value}
          onChange={(e) => {
            if(e.target.value.length>13) return;
            setPhoneNumber({value: e.target.value})
          }}
          placeholder="+1 ...."
          bordered
          required={(mealPlanId || providerEmail) ? true : false}
          onBlur={(e) => {
            const { value } = e.target;
            if (!value) setPhoneNumber({ value, error: `Phone Number is required` });
          }}
        />
        {(mealPlanId || providerEmail) && <h5 style={{color: '#e0242f' , fontSize : '10px'}}>{phoneNumber.error}</h5>}
        <Input
          label="Email"
          type="email"
          name="email"
          value={email.value}
          onChange={(e) => {
            const inputValue = e.target.value.slice(0, 50); 
            onChangeEmail(inputValue, setEmail)
        }}
          placeholder="Email address"
          bordered
          required
          onBlur={(e) => onBlurEmail(e.target.value, setEmail)}
          disabled={providerEmail}
        />
        <h5 style={{color: '#e0242f' , fontSize : '10px'}}>{email.error}</h5>
        {
          providerEmail && 
          <>
          <Input
          label="Zipcode"
          name="zip"
          value={zip.value}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (!isNaN(inputValue)) {
              setZip({ value: inputValue });
            } else {
              // Input is not a number, show an error or handle it as needed
              setZip({error:"ZIP must be a number"});
            }
          }}
          
          bordered
          required
          onBlur={(e) => {
            const { value } = e.target;
            if (!value) setZip({ value, error: `zip is required` });
          }}
        />
        <h5 style={{color: '#e0242f' , fontSize : '10px'}}>{zip.error}</h5>
        </>
        }
        <Input
          label="Password"
          type="password"
          name="password"
          id="new-password"
          placeholder="password"
          value={password.value}
          onChange={(e) => setPassword({ value: e.target.value })}
          bordered
          required
          autoComplete="new-password"
          onBlur={(e) => {
            const { value } = e.target;
            if (!value) setPassword({ value, error: `Password is required` });
          }}
        />
        <h5 style={{color: '#e0242f', fontSize : '10px'}}>{password.error}</h5>
          {isMobile &&
            <RadioWrapper>  
              <div className="text-wrapper">
                <div className='agree'>
                 <p>By continuing you agree with the</p>
                 <p style={{paddingLeft: "4px"}}> 
                   <Link  to={`/${providerRegister?.providerEmail ? 'provider' : 'users'}/terms`}
                   target="_blank">terms &</Link>
                 </p>
                 </div>
                 <div className='policy'>
                  <p> <Link  to={`/${providerRegister?.providerEmail ? 'provider' : 'users'}/terms`}
                  target="_blank"
                 >conditions</Link> </p> {!providerEmail && <p style={{paddingLeft: "4px"}}> and</p>}
                
                 {!providerEmail && <p style={{paddingLeft: "4px"}}>
                  <Link to="/nutritt/privacy-policy" target="_blank">privacy policy</Link>
                 </p>}
                 </div>
              </div>
            </RadioWrapper>}

        {isDesktop && <div className="terms-wrapper">
          <p>By continuing you agree to</p>
          <Link to={`/${providerRegister?.providerEmail ? 'provider' : 'users'}/terms`}
            target="_blank">terms & conditions</Link>
          <div className='policy'>
                 {!providerEmail && <p style={{paddingLeft: "3px"}}> and</p>}
                 {!providerEmail && <p style={{paddingLeft: "4px"}}>
                  <Link to="/nutritt/privacy-policy" target="_blank">privacy policy</Link>
                 </p>}
                 </div>  
        </div>}
        <SubmitButton disabled={disableSignupButton || (!phoneNumber.value && (mealPlanId || providerEmail))} type="submit" error={error?.body?.message || error}>
          {isLoading ? 'Signing you up...' : 'Sign up'}
        </SubmitButton>
        {
         isDesktop && <div className="signup">
        <p>Already have an account?</p>
        <Link to="/login">Log in</Link>
        </div>
        }
        {!providerEmail && 
        <>
        {isDesktop && 
        <>
        <div className='or-login'>
            <img src='/icons/or.svg' alt='or login' />
        </div>
          <div className='google-login'>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Continue with Google"
              onSuccess={(responseGoogle)=>{
                console.log(responseGoogle)
                if(mealPlanId && nutritionistId){
                  handleGoogleSignup(responseGoogle)
                } else {
                  setProfileObj({ responseGoogle });
                }
              }}
              onFailure={(responseGoogle)=>{
                console.log({responseGoogle, fail: true})
              }}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          </>}
          </>
          }
      </div>
      </StyledForm>
      {isMobile &&
      <HasAccount>
        <p>Already have an account?</p>
        <Link to="/login">Log in</Link>
      </HasAccount>
      }
    </>
  );
}

