import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from "swiper";

export default function ServingSlider({servings,activeIndex,setActiveIndex,servingUnit,servingSizeRef}){
    const [imagesNavSlider, setImagesNavSlider] = useState(null);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex);
    }

    useEffect(() => {
        if (servingSizeRef.current) {
      servingSizeRef.current.slideTo(activeIndex >=0 ? activeIndex : 0);
      }
      }, [servingSizeRef,activeIndex]);

    return(
        <>
          <Swiper
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={5}
              centeredSlides={true}
              className="mySwiper"
              onInit={(swiper) => servingSizeRef.current = swiper}
              onSlideChange={handleSlideChange}
              onSwiper={setImagesNavSlider}
            >
                {servings.map((item, index) => (
                    <SwiperSlide>
                        <span
                            key={index}
                        >
                            {servingUnit(index)}
                        </span>
                    </SwiperSlide>
                ))}
          </Swiper>
          <Swiper thumbs={{ swiper: imagesNavSlider }} modules={[Thumbs]} />
        </>
    )
}