import styled from 'styled-components';

const CaloriesStyle = styled.div`

  // .fresh-card-container {
  //   margin-right: 5px;
  //   padding-right: 5px;
  //   color: #747c7a;
  //   font-size: 12px;
  //   border-right: 1px solid #e0e3e0;
  //   text-transform: capitalize;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;

  //   &:last-child {
  //     border: 0;
  //   }
  // }

  .cart-tags{
    width: 146px;
    justify-content: center;
  }
`;

export default function Calories({ carbs = 0, protein = 0, fat = 0, calories = 0 }) {
  return ( 
    <CaloriesStyle>
    <div className='cart-tags'>
      <div className="fresh-card-container">
        <span data-testid="custom-cals-value">{Math.round(calories)}</span>
        <span>cals</span>
      </div>
      <div className="fresh-card-container">
        <span data-testid="custom-carbs-value">{`${Math.round(carbs)}g`}</span>
        <span>carbs</span>
      </div>
      <div className="fresh-card-container">
        <span data-testid="custom-fat-value">{`${Math.round(fat)}g`}</span>
        <span>fat</span>
      </div>
      <div className="fresh-card-container">
        <span data-testid="custom-protein-value">{`${Math.round(protein)}g`}</span>
        <span>protein</span>
      </div>
    </div>
    </CaloriesStyle>
  );
}
