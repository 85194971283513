export const formatDate = (isoDate) => {
    if(isoDate){
        var date = new Date(isoDate);
        var timeZoneOffset = -5 * 60;
        // Adjust the date for the time zone offset
        date.setMinutes(date.getMinutes() - timeZoneOffset);
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        // Format the date in an international date string format
        var formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }
    return null
}