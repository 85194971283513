import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useNutritionistContext } from '../../context/nutritionistContext';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';

const NutristionistPaymentStyle = styled.div`
  .payment-title {
    margin-bottom: 44px;

    h3 {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 500;
    }

    p {
      color: #656565;
    }
  }

  .add-account {
    margin-bottom: 38px;
  }

  p {
    font-size: 18px;
  }

  .payment-btn {
    width: 179px;
    height: 49px;
    margin-right: 63px;
    background-color: #52c41a;
    color: #fff;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
  }

  .account-info-wrapper {
    width: 40%;
    margin-bottom: 38px;

    p,
    .account-info {
      margin-bottom: 13px;
    }

    .account-info {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
    }
  }

  .payment-btn-wrapper {
    display: flex;

    .payment-delete {
      background-color: transparent;
      color: #52c41a;
      font-size: 18px;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    .payment-title {
      margin-bottom: 30px;

      h3 {
        font-size: 20px;
      }
    }

    p,
    .account-info-wrapper .account-info {
      font-size: 16px;
    }

    .payment-btn {
      width: 100%;
      height: 44px;
      margin: 0;
      padding: 0;
      border-radius: 6px;
    }

    .account-info-wrapper {
      width: 100%;
    }

    .payment-btn-wrapper {
      flex-direction: column;

      .payment-btn {
        margin-bottom: 15px;
      }
    }
  }
`;

export default function NutristionistPayment() {
  const { nutritionist: nutritionistData, updateNutritionist } = useNutritionistContext();
  const { accountNumber = '', routingNumber = '' } = nutritionistData?.nutritionist?.account || {};
  const [open, setOpen] = useState(false);
  const [isAccountNumberExist, setIsAccountNumberExist] = useState(false);
  const [isAccountRoutingExist, setIsAccountRoutingExist] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accountData, setAccountData] = useState({
    accountNumber,
    routingNumber,
  });
  const [updateNutritionistAccount] = useRequest(
    `nutritionist/profile/edit/${nutritionistData?.nutritionist?.id}`,
    'post',
  );

  useEffect(() => {
    if(open || showDeleteModal){
    setAccountData({
      accountNumber,
      routingNumber,
    });
    }
  }, [accountNumber, routingNumber, open, showDeleteModal]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsAccountNumberExist(false);
    setIsAccountRoutingExist(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (isDelete) => {
    if (accountData.accountNumber.length && accountData.routingNumber.length) {
      const { id,createdAt,updatedAt,userId,socialProfile,isDeleted,dietTemplates,mantra,phoneNumber,isApproved,img,  ...nutritionistWithoutId } = nutritionistData?.nutritionist;
      const account = {
        ...nutritionistWithoutId,
        account: {
          accountNumber: isDelete === 'true' ? '' : accountData.accountNumber,
          routingNumber: isDelete === 'true' ? '' : accountData.routingNumber,
        },
      };

      const body = {
        body: account,
      };
      updateNutritionistAccount(body)
        .then((data) => {
          updateNutritionist({
            ...nutritionistData,
            nutritionist: {
              ...nutritionistData.nutritionist,
              ...account,
            },
          });
          const message =
            isDelete === 'true' ? 'Account removed successfully' : 'Account updated successfully';
          toast.success(message);
        })
        .catch((err) => {
          toast.error('Oops! something went wrong');
        });
      setShowDeleteModal(false);
      setOpen(false);
    }

    if (!accountData.accountNumber.length) {
      setIsAccountNumberExist(true);
    }

    if (!accountData.routingNumber.length) {
      setIsAccountRoutingExist(true);
    }
  };

  return (
    <NutristionistPaymentStyle>
      <div className="payment-title">
        <h3>Link to Bank Account</h3>
        <p>Link to your bank account to get commission from Nutritt</p>
      </div>
      {!(accountNumber && routingNumber) && (
        <div className="add-account">
          <p>You haven’t added any bank account yet.</p>
          <p> Add a bank account to get commission from Nutritt</p>
        </div>
      )}
      {accountNumber && routingNumber && (
        <div className="account-info-wrapper">
          <p>Current bank account you linked to:</p>
          <div className="account-info">
            <span>Account Number</span>
            <span>{accountNumber}</span>
          </div>
          <div className="account-info">
            <span>Routing Number</span>
            <span>{routingNumber}</span>
          </div>
        </div>
      )}
      <div className="payment-btn-wrapper">
        <button type="button" className="payment-btn" onClick={handleClickOpen}>
          {accountNumber && routingNumber ? 'Edit Account' : 'Add Account'}
        </button>
        {accountNumber && routingNumber && (
          <button type="button" className="payment-delete" onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
        )}
      </div>
      <Dialog open={open} onClose={handleClose} className="nutritionist-payment-dialog">
        <h3>Link to your bank account</h3>
        <DialogContent>
          <div className="form-group">
            <label>Account number</label>
            <input
              type="text"
              autoComplete="off"
              value={accountData.accountNumber}
              onChange={(e) => {
                const input = e.target.value;
                const regex = /^\d{0,25}$/;
                if (regex.test(input)) {
                  setAccountData({
                    ...accountData,
                    accountNumber: input,
                  });
                  setIsAccountNumberExist(false);
                }
              }}
            />
            {isAccountNumberExist && (
              <p className="validate-input">Please enter your Account Number</p>
            )}
          </div>
          <div className="form-group">
            <label>Routing number</label>
            <input
              type="number"
              autoComplete="off"
              value={accountData.routingNumber}
              onChange={(e) => {
                const input = e.target.value;
                const regex = /^\d{0,15}$/;
                if (regex.test(input)) {
                  setAccountData({
                    ...accountData,
                    routingNumber: input,
                  });
                }
                setIsAccountRoutingExist(false);
              }}
            />
            {isAccountRoutingExist && (
              <p className="validate-input">Please enter your Routing Number</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button type="button" onClick={handleSave}>
            Save
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        className="nutritionist-payment-dialog"
      >
        <h3>Are you sure want to delete this account ?</h3>
        <DialogContent>
          <div className="form-group">
            <label>Account number</label>
            <input type="number" autoComplete="off" value={accountNumber} disabled />
          </div>
          <div className="form-group">
            <label>Routing number</label>
            <input type="number" autoComplete="off" value={routingNumber} disabled />
          </div>
        </DialogContent>
        <DialogActions>
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </button>
          <button type="button" onClick={() => handleSave('true')}>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
    </NutristionistPaymentStyle>
  );
}
