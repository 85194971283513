import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Input from 'components/Input';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import ExcludeDates from 'components/Nutritionist/Coaching/ExcludeDates';
import DaySlotsPanel from 'components/Nutritionist/Coaching/DaySlotsPanel';
import OfferingAvailability from 'components/Nutritionist/Coaching/OfferingAvailability';
import NutritionistControls from 'components/Nutritionist/Coaching/NutritionistControls';
import { availabilityTimes } from 'components/Nutritionist/Coaching/OfferingData';
import { useRequest } from 'hooks/useRequest';
import OverlayLoader from 'components/overlayLoader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Alert from 'components/Alert';
import classNames from 'classnames';
import Progress from 'components/Progress';
import UploadWidget from 'components/UploadWidget';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NutritionistOfferingEditWrapper = styled.div`
  width: calc(100% - 300px);
  margin: 40px 0 75px 300px;

  .offering-container {
    width: 60%;
    margin: 0 auto;
  }

  .form-img-container {
    display:flex;
    height:100%;
    width:100%;
    justify-content:center;
    align-items:center;

    & > img {
      object-fit: contain;
      height: 120px;
    }
  }

  .form-group .form-control {
    display: flex;
    postion:absolute;
   

  

    & > input.duration-number {
      width: 60%;
      border-radius: 6px 0 0 6px;
    }

    & > div {
      width: 40%;ss
      margin-bottom: 0;s
    }

    div.selected-value {
      border-radius: 0 6px 6px 0;
      border-left: 0;
    }
  }
   .btn-previous{
                background-color: #f6f5f8;
                position: absolute;
                top: 80px;
                left: 10px;
                img{
                    width: 20px;
                    height: 20px;
                }
            }

  .offering-title {
    margin-bottom: 45px;
    color: #51635d;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
  }

  form {
    label {
      margin-bottom: 7px;
      color: #51635d;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      display: block;
    }
    

    textarea,
    input {
      height: 44px;
      margin-bottom: 8px;
      padding: 0 10px 0 14px;
      border: 1px solid #e0e3e0;
      border-radius: 6px;

      ::placeholder {
        color: #949d9b;
      }
    }

    textarea {
      height: 118px;
      padding-top: 9px;
    }

    .upload-cover-img{
      text-align: center;
      height: 230px;
      background: #F6F5F8;
      margin-top:10px;
      /* Light Grey */
      border: 1px solid #E0E3E0;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding-top: 40px;

      .gallery-icon{
        height: 28.67px;
      }

      .coverText {
        font-size: 18px;
        padding-top: 20px;
        line-height: 24px;
        color: #51635D;
      }

      .coverDesc {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #949D9B;
      }
    }
  }

  .offering-schedule-wrapper {
    margin-bottom: 32px;
  }

  .nutritionist-controls {
    display: flex;
    justify-content: center;

    & > div {
      position: static;
    }
  }

  .google-calendar-wrapper {
    margin-bottom: 32px;

    label span {
      color: #f00;
    }

    .google-calendar {
      padding: 30px 0;
      background-color: #f6f5f8;
      border: 1px solid #e0e3e0;
      border-radius: 10px;

      &.hasGoogleAccess {
        background-color: #f0faeb;
        border: 1px solid #cbedba;
      }
    }

    .google-calendar-container {
      width: 60%;
      margin: 0 auto;
      ${FlexColumnCenter};

      p {
        margin-bottom: 13px;
      }
    }

    p {
      color: #51635d;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }

    a {
      width: 200px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #97dc76;
      border-radius: 21px;
      ${FlexCenter};
    }

    button {
      margin-left: 10px;
      padding: 0;
      background-color: transparent;
      color: #51635d;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      cursor: pointer;
    }
  }

  .goolge-alert-wrapper {
    .alert-container {
      position: relative;
      width: 300px;
      padding: 0 30px;
    }

    .title {
      ${FlexCenter};

      .img-wrapper {
        width: 64px;
        height: 64px;
        background-color: #f0faeb;
        border-radius: 50%;
        ${FlexCenter};
      }
    }

    .title-desc-wrapper {
      border-bottom: 0;
      padding-bottom: 0;
    }

    p span {
      font-family: 'Roboto';
    }

    .btn-wrapper {
      button:first-child {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        border: 0 !important;
        border-radius: 0;
      }

      button:last-child {
        position: relative;
        width: 204px;
        margin-bottom: 10px;
        padding-left: 40px;
        background-color: #0085f7;
        border: 2px solid #0085f7 !important;
      }import { format } from 'date-fns';


      .img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        padding: 0 8px 0 14px;
        background-color: #fff;
        border-radius: 21px 0 0 21px;
        ${FlexCenter};
      }

      a {
        color: #fff;
      }
    }
  }

  .has-google-account {
    ${FlexColumnCenter};

    .google-account {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }

    p {
      margin-left: 7px;

      span {
        font-family: 'Roboto';
      }
    }
  }

  @media (max-width: 1270px) {
    .offering-container {
      width: 85%;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    margin: 77px 0 33px 0;

    .offering-container {
      width: 90%;
    }

    .offering-title {
      margin-bottom: 24px;
    }

    form {
      padding: 20px;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

      & > div:first-child {
        margin-top: 0;
      }

      input,
      textarea {
        margin-bottom: 0;
      }
    }

    .google-calendar-wrapper .google-calendar-container {
      width: 75%;
    }

    .has-google-account .google-account {
      width: 70%;
      margin-top: 20px;
      align-items: flex-start;
    }

    .nutritionist-controls {
      & > div {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 13px;
        .cancel-btn,
        .primary-btn {
          width: 100%
        }
      }
    }
    .goolge-alert-wrapper {
      p span {
        font-weight: bold;
      }
      .alert-container {
        button {
          width: auto !important;
          margin-right: 0;
        }
        button:last-child {
          width: 100% !important;
        }
      }
    }
  }
`;

export default function NutritionistOfferingEdit(props) {
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const { user, isSuper } = useAppContext();
  const defaultState = useMemo(() => {

    return {
      id: undefined,
      nutritionistId: user?.nutritionistProfile.id || imitateNutritionist?.id,
      title: '',
      description: '',
      price: null,
      availability: null,
      schedule: [
        {
          Monday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
        {
          Tuesday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
        {
          Wednesday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
        {
          Thursday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
        {
          Friday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
        {
          Saturday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
        {
          Sunday: {
            slots: [
              {
                from: '09:00',
                to: '17:00',
              },
            ],
            available: false,
          },
        },
      ],
      excludeDates: [],
    };
  }, [user, imitateNutritionist]);
  const history = useHistory();
  const { offerForm, setOfferForm } = useAppContext();
  const [form, setForm] = useState(defaultState);
  const [isActive, setIsActive] = useState(true);
  const [isCustom, setIsCustom] = useState(false);
  const [isCustomEdit, setIsCustomEdit] = useState(true);
  const [customValue, setCustomValue] = useState();
  const { isMobile ,convertToHttps} = useAppContext();
  const [getCoachingById] = useRequest();
  const [updateCoachingById, { isProgress }] = useRequest();
  const [createCoaching, { isLoading }] = useRequest('coachingOffer/create', 'post');
  const [showGoogleSignInAlert, setShowGoogleSignInAlert] = useState(false);
  const [hasGoogleAccess, setHasGoogleAccess] = useState(true);
  const [authUrl, setAuthUrl] = useState('');
  const [checkGoogleAccess] = useRequest();
  const [generateAuthUrl] = useRequest('googleAuth/generateAuthUrl', 'post');
  const [saveToken] = useRequest('googleAuth/saveToken', 'post');
  const [showGoogleSignInSucess, setShowGoogleSignInSucess] = useState(false);
  const [FormErrors, setFormErrors] = useState({});
  const [googleUserInfo, setGoogleUserInfo] = useState({});
  const [disablePublishBtn, setDisablePublishBtn] = useState(false);
  const [showOverlayLoader, setShowOverlayLoader] = useState(false);

  let params = useParams();

  useEffect(() => {
    if (offerForm) {
      setForm(offerForm);
      setOfferForm(null);
    }
  }, []);

  window.onbeforeunload = () => {
    setOfferForm(form);
    localStorage.setItem('offerForm', JSON.stringify(form));
  };

  const getInitialData = () => {
    const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
    const reqId = isSuper ? imitateNutritionist?.userId : user?.id;
    if (reqId) {
      setShowOverlayLoader(true);
      checkGoogleAccess({
        path: `googleAuth/verifyGoogleAccess/${reqId}`,
      })
        .then((data) => {
          setHasGoogleAccess(data.access);
          if (!data.access) {
            generateAuthUrl()
              .then((data) => {
                setShowOverlayLoader(false);
                setAuthUrl(data.authUrl);
              })
              .catch((e) => {
                console.log(e);
                setShowOverlayLoader(false);
              });
          } else {
            setShowOverlayLoader(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setShowOverlayLoader(false);
        });
    }
  };

  useEffect(async () => {
    if (params?.id) {
      getInitialData();
      getCoachingById({
        path: `coachingOffer/offer/${params?.id}`,
      }).then((data) => {
        setForm({ ...data, price: data.price / 100 });
      });
    } else {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      if (code) {
        setShowOverlayLoader(true);
        saveToken({ body: { code } })
          .then((response) => {
            setShowOverlayLoader(false);
            setShowGoogleSignInSucess(true);
            setGoogleUserInfo(response.signInUser);
            history.push('/nutritionist/coaching/offering/create');
            getInitialData();
          })
          .catch(() => {
            setShowOverlayLoader(false);
          });
      } else {
        getInitialData();
      }
    }
  }, []);

  const handleChange = (e, key) => {
    setForm({ ...form, [key]: e.target.value });
  };

  const handleAvailabilityValue = (value) => {
    setForm({ ...form, availability: value });
    setIsActive(true);
    setIsCustom(false);
  };

  const handleDisplayCustom = () => {
    setIsCustom(true);
    setIsActive(false);
    setIsCustomEdit(true);
    setCustomValue();
  };

  const handleCustomBlur = () => {
    setIsCustomEdit(false);
    if (customValue < 5 || customValue == null) {
      setCustomValue(5);
      setForm({ ...form, availability: 5 });
    } else {
      if (customValue % 1 !== 0) {
        setCustomValue(Math.round(customValue));
      }
      setForm({ ...form, availability: customValue });
    }
  };
  const validate = (title, price, availability, slots) => {
    let slotCheck = [
      slots[0].Monday?.available,
      slots[1].Tuesday?.available,
      slots[2].Wednesday?.available,
      slots[3].Thursday?.available,
      slots[4].Friday?.available,
      slots[5].Saturday?.available,
      slots[6].Sunday?.available,
    ];
    let setSlotCheck = slotCheck.some((x) => x);
    const errors = {};
    if (!title) {
      errors.title = 'Title is required!';
    }
    if (!price) {
      errors.price = 'Price is required!';
    }
    if (!availability) {
      errors.availability = 'Availability is required!';
    }
    if (!setSlotCheck) {
      errors.slots = 'Atleast one day is required!';
    }
    return errors;
  };
  const changeSwitch = (available, currentDay) => {
    const schedule = form.schedule.map((day) => {
      if (Object.keys(day)[0] !== currentDay) {
        return day;
      }
      return {
        [currentDay]: {
          ...day[currentDay],
          available,
        },
      };
    });
    setForm({ ...form, schedule });
  };

  const changeTime = ({ from, index, currentDay, to }) => {
    const schedule = form.schedule.map((day) => {
      if (Object.keys(day)[0] !== currentDay) {
        return day;
      }

      const slots = day[currentDay].slots.map((slot, i) => {
        if (i !== index) {
          return slot;
        }
        return {
          ...slot,
          ...(from && { from }),
          ...(to && { to }),
        };
      });

      return {
        [currentDay]: {
          ...day[currentDay],
          slots,
        },
      };
    });
    setForm({ ...form, schedule });
  };

  const addNewSlots = (currentDay) => {
    const schedule = form.schedule.map((day) => {
      if (Object.keys(day)[0] !== currentDay) {
        return day;
      }
      return {
        [currentDay]: {
          ...day[currentDay],
          slots: [
            ...day[currentDay].slots,
            {
              from: '09:00',
              to: '17:00',
            },
          ],
        },
      };
    });
    setForm({ ...form, schedule });
  };

  const removeSlots = (index, currentDay) => {
    const schedule = form.schedule.map((day) => {
      if (Object.keys(day)[0] !== currentDay) {
        return day;
      }

      day[currentDay].slots.splice(index, 1);
      return {
        [currentDay]: {
          ...day[currentDay],
          slots: day[currentDay].slots,
        },
      };
    });
    setForm({ ...form, schedule });
  };

  const publishHandler = useCallback(
    (e) => {
      e?.preventDefault();
      if (!hasGoogleAccess) {
        setShowGoogleSignInAlert(true);
        return;
      }
      setFormErrors(validate(form?.title, form?.price, form.availability, form?.schedule));
      const slots = form?.schedule;
      let slotCheck = [
        slots[0].Monday?.available,
        slots[1].Tuesday?.available,
        slots[2].Wednesday?.available,
        slots[3].Thursday?.available,
        slots[4].Friday?.available,
        slots[5].Saturday?.available,
        slots[6].Sunday?.available,
      ];
      let setSlotCheck = slotCheck.some((x) => x);


      if (form?.title && form?.price && form.availability && setSlotCheck) {
        setDisablePublishBtn(true);
        setShowOverlayLoader(true);

        if (params?.id) {
          updateCoachingById({
            path: `coachingOffer/update/${params?.id}`,
            method: 'put',
            body: {
              title: form.title,
              description: form.description,
              price: form.price,
              availability: form.availability,
              schedule: form.schedule,
              excludeDates: form.excludeDates,
              img:form.img,
            },
          })
            .then((data) => {
              toast.success('Offer updated successfully.');
              history.push('/nutritionist/clients');
              setShowOverlayLoader(false);
            })
            .catch((e) => {
              toast.error('Oops! something went wrong');
              setShowOverlayLoader(false);
            });
        } else {
          createCoaching({ body: form })
            .then((data) => {
              toast.success('Offer created successfully.');
              setShowOverlayLoader(false);
              setForm(defaultState);
              const params01 = new URLSearchParams(location.search);
              const redirectBackTo = params01.get('redirectBackTo');
              if (redirectBackTo) {
                history.push(`${redirectBackTo}?createdOfferId=${data.id}`);
              } else {
                history.push('/nutritionist/clients');
              }
            })
            .catch((e) => {
              toast.error('Oops! something went wrong');
              setShowOverlayLoader(false);
            });
        }
      } else {
        toast.error('Please fill all the necessary details');
      }
    },
    [form, hasGoogleAccess],
  );

  const undoChanges = () => {
    setForm(defaultState);
  };

  const setNewImgUrl = (url) => {
    setForm({ ...form, coverImgURL: url });
  };
  return (
    <NutritionistOfferingEditWrapper>
      <div className="offering-container">
        {!isMobile && (
          <NutritionistControls
            undoIcon
            cancelLink="/nutritionist/coaching/offering"
            undoChanges={undoChanges}
            publishHandler={publishHandler}
            disablePublish={disablePublishBtn}
          />
        )}
      
        <div className="offering-title">
        <button 
                        type="button" 
                        className='btn-previous'
                        onClick={()=>{
                          history?.goBack();
                        }}>
                      <img src="/icons/arrow-left.svg" alt="Back Icon" /> 
                    </button>
          Offering</div>
        <form onSubmit={publishHandler} disabled={params?.id ? isProgress : isLoading}>
          <Input
            label="Title"
            name="title"
            type="text"
            onChange={(e) => handleChange(e, 'title')}
            value={form.title}
            placeholder="E.g. Fitness Consultations"
            autoComplete="off"
            required
          />
          <p style={{ color: 'red' }}>{FormErrors.title}</p>
          <Input
            label="Description"
            name="description"
            type="textarea"
            onChange={(e) => handleChange(e, 'description')}
            value={form.description}
            placeholder="E.g. We will work togetheer to provide a customized meal and fitness plan, shop together..."
            autoComplete="off"
          />
          <div className="upload-cover-img">
            <div>{form?.img && <img src={convertToHttps(form?.img)} height="50px" alt="Diet picture" />}</div>
            <label className='coverText'>Cover image</label>
            <label className='coverDesc'>Upload or drag your cover image here </label>
    
           <div className='form-img-container'> <UploadWidget
            style={{
              width:"200px",
              borderRadius:"21px",
              background:"#949D9B",
              color:" #FFFFFF"
            }}
                setNewImgUrl={(image) => {
                  setForm((prev) => ({ ...prev, img:image }));
                }}
                img={form?.img}
              /></div>
          </div>
        
          
          <div>
            <Input
              label="Price"
              name="price"
              type="text"
              onChange={(e) => {
                const value = parseInt(e.target.value)
                if (typeof value == "NaN") {
                  return toast.error("price should be in Number")
                } else {
                  setForm({ ...form, price: value })
                }
              }}
              value={form.price}
              placeholder="$15"
              autoComplete="off"
              required
            />
            <p style={{ color: 'red' }}>{FormErrors.price}</p>
          </div>
          <OfferingAvailability
            availabilityTimes={availabilityTimes}
            handleAvailabilityValue={handleAvailabilityValue}
            isActive={isActive}
            isCustom={isCustom}
            handleDisplayCustom={handleDisplayCustom}
            formAvailability={form.availability}
            isCustomEdit={isCustomEdit}
            customValue={customValue}
            setCustomValue={setCustomValue}
            handleCustomBlur={handleCustomBlur}
            onChange={(value) => setCustomValue(value)}
            handleCustomValue={() => setIsCustomEdit(true)}
          />
          <p style={{ color: 'red', paddingTop: 0, margin: 0 }}>{FormErrors.availability}</p>
          <br></br>
          <div className="google-calendar-wrapper">
            {!hasGoogleAccess && (
              <>
                <label>
                  Connect calendar <span>*</span>
                </label>
                <div className={classNames('google-calendar', { hasGoogleAccess })}>
                  {!hasGoogleAccess && (
                    <div className="google-calendar-container">
                      <p>
                        Connect your google calendar to allow us to generate a calendar event for
                        your meetings
                      </p>
                      <a href={authUrl}>
                        <img src="/icons/Google.svg" alt="Google Icon" />
                        <button type="button">Sign in with Google</button>
                      </a>
                    </div>
                  )}
                </div>
              </>
            )}
            {showGoogleSignInSucess && (
              <div className={classNames('google-calendar', { hasGoogleAccess })}>
                <div className="has-google-account">
                  <img src="/icons/tick-circle.svg" alt="Tick Icon" />
                  <div className="google-account">
                    <img src="/icons/google-calendar.svg" alt="Google Calendar Icon" />
                    <p>
                      You signed successful as <span>{googleUserInfo.email}</span>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="offering-schedule-wrapper">
            {form?.schedule?.map((day, index) => {
              const currentDay = Object.keys(day)[0];
              return (
                <DaySlotsPanel
                  key={index}
                  day={currentDay}
                  available={day[currentDay].available}
                  slots={day[currentDay].slots}
                  onChangeSwitch={(available) => changeSwitch(available, currentDay)}
                  onChangeTime={({ from, to, index }) =>
                    changeTime({ from, index, currentDay, to })
                  }
                  addNewSlots={() => addNewSlots(currentDay)}
                  removeSlots={(index) => removeSlots(index, currentDay)}
                />
              );
            })}
            <p style={{ color: 'red' }}>{FormErrors.slots}</p>
          </div>
          <ExcludeDates
            onChange={(date) => {
              if (date.getTime() + 86400 >= new Date().getTime()) {
                setForm({ ...form, excludeDates: [...form.excludeDates, date] });
              }
            }}
            excludeDates={form.excludeDates}
            removeDate={(index) => {
              form.excludeDates.splice(index, 1);
              setForm({ ...form, excludeDates: [...form.excludeDates] });
            }}
          />
          <div className="nutritionist-controls">
            <NutritionistControls
              cancelBtn
              cancelLink="/nutritionist/clients"
              publishHandler={publishHandler}
              disablePublish={disablePublishBtn}
            />
            <br />
          </div>
          <br />
        </form>
        {(isProgress || (form.title?.length > 0 && isLoading)) && <Progress />}
        <br />
        {showGoogleSignInAlert && (
          <div className="goolge-alert-wrapper">
            <Alert
              title={
                <div className="img-wrapper">
                  <img src="/icons/info-circle.svg" alt="Info Icon" />
                </div>
              }
              desc={
                <>
                  You need to sign in <span>Google Calendar</span> to schedule events
                </>
              }
              cancelText={<img src="/icons/close-circle3.svg" alt="Close Icon" />}
              okText={
                <a href={authUrl}>
                  <div className="img-wrapper">
                    <img src="/icons/Google.svg" alt="Google Icon" />
                  </div>
                  Sign in with Google
                </a>
              }
              cancel={() => {
                setShowGoogleSignInAlert(false);
              }}
            />
          </div>
        )}
      </div>
    </NutritionistOfferingEditWrapper>
  );
}
