import React from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from "recharts";
import { useAppContext } from "context/appContext";

const data = [
    {
        name: "Jan",
        sales: 2300,
        amt: 2400
    },
    {
        name: "Feb",
        sales: 3000,
        amt: 2210
    },
    {
        name: "Mar",
        sales: 2000,
        amt: 2290
    },
    {
        name: "Apr",
        sales: 2780,
        amt: 2000
    },
    {
        name: "May",
        sales: 1890,
        amt: 2181
    },
    {
        name: "June",
        sales: 2390,
        amt: 2500
    },
    {
        name: "July",
        sales: 3490,
        amt: 2100
    },
    {
        name: "Aug",
        sales: 3000,
        amt: 2100
    },
    {
        name: "Sept",
        sales: 2500,
        amt: 2100
    },
    {
        name: "Oct",
        sales: 3400,
        amt: 2100
    },
    {
        name: "Nov",
        sales: 4000,
        amt: 2100
    },
    {
        name: "Dec",
        sales: 2500,
        amt: 2100
    }
];

class CartesianGridNoDashVertical extends CartesianGrid {
    renderVertical(verticalPoints) {
      const element = super.renderVertical(verticalPoints);
      const lines = element.props.children.map((el) =>
        React.cloneElement(el, { strokeDasharray: "0" })
      );
      return React.cloneElement(element, [], lines);
    }
  }

function Chart({ chartWidth = 700, chartData, isDataLoading = false, slideIndex = 0, value = 0 }) {
    const {isMobile} = useAppContext();

    const customTick = (tickObject) => {
        const {payload: {value}, x} = tickObject;
        return <text {...tickObject} x={26} textAnchor="start" width={35}>{value > 999 ? `${slideIndex === 0 ? '$' : ''}${value / 1000}k`: value === 0 ? value : `${slideIndex === 0 ? '$' : ''}${value}`}</text>
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <span className="custom-tooltip" style={{backgroundColor: '#283241', padding: '5px 8px', borderRadius: '4px', color: '#fff', fontSize: '12px', fontWeight: '600', lineHeight: '16px'}}>
                <span>{`${isMobile ? (slideIndex === 0 ? 'Amount: ' : 'Count: ') : ''}${slideIndex === 0 ? '$' : ''}${payload[0].value}`}</span>
                {isMobile && <span className="tooltip-name">{value === 2 ? "Date" : value === 3 ? "Day" : "Time"}: {payload[0]?.payload?.name}</span>}
            </span>
          );
        }
      
        return null;
    };

    const gradient = (
        <defs>
            <linearGradient id="areaGradient" x1="-0.0799842" y1="48.0012" x2="-0.0799842" y2="213" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5C9BFA" stopOpacity="0.326568" />
                <stop offset="1" stopColor="white" stopOpacity="0.01" />
            </linearGradient>
        </defs>
    );

    if (!chartData || chartData.length === 0) {
        return (
            <AreaChart
                width={chartWidth}
                height={300}
                isAnimationActive={true} 
                animationDuration={2000}
                data={data}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={{ fill: '#283241' }} axisLine={{ stroke: '#E9EAEC' }} padding={{ left: 0, right: 0 }} tickLine={false} />
                <YAxis dataKey={'sales'} axisLine={{ stroke: 'white' }} tick={{ fill: '#9CA3AD', opacity: 0.69 }} tickLine={false} />
                <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#283241", strokeDasharray: 0 }} />
                {!isDataLoading ? <>
                {gradient}
                <Area type="linear" dataKey="sales" stroke="#9CA3AD" fill="url(#areaGradient)" dot={{ stroke: '#9CA3AD', strokeWidth: 1, r: 4, strokeDasharray: '', fill: "#000A2C", fillOpacity: 1 }} />
                </> : ''
                }
            </AreaChart>
        );
    }

    return (
        <AreaChart
            width={chartWidth}
            height={isMobile ? 278 : 300}
            data={chartData}
            isAnimationActive={true} 
            animationDuration={2000}
        >
            {gradient}
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fill: '#283241' }} axisLine={{ stroke: '#E9EAEC' }} padding={{ left: 0, right: 0 }} tickLine={false} interval={isMobile && (value === 3 ? 4 : (value === 0 || value === 1) && 3)} />
            {isMobile ? 
                <YAxis axisLine={{ stroke: '#E9EAEC' }} tick={(tickObject) => customTick(tickObject)} tickLine={false} />
            :
                <YAxis axisLine={{ stroke: 'white' }} tick={{ fill: '#9CA3AD', opacity: 0.69 }} tickLine={false} />
            }
            <Tooltip content={<CustomTooltip />} cursor={{ stroke: `${isMobile ? "#000A2C" : "#283241"}`, strokeDasharray: 0 }} />
            {isMobile ? 
                <Area type="linear" dataKey="sales" stroke="#757E89" fill="url(#areaGradient)" dot={{ stroke: "transparent", strokeWidth: 1, r: 4, strokeDasharray: '', fill: "transparent", fillOpacity: 1 }} />
            :
                <Area type="linear" dataKey="sales" stroke="#9CA3AD" fill="url(#areaGradient)" dot={{ stroke: "#9CA3AD", strokeWidth: 1, r: 4, strokeDasharray: '', fill: "#000A2C", fillOpacity: 1 }} />
            }
        </AreaChart>
    );
}

const SingleLineChart = React.memo(Chart);
export default SingleLineChart;