import styled from 'styled-components';
import MealPlanCards from 'components/OnboardingPlans/MealPlanCards';
import RegisteredDetitianCard from 'components/OnboardingPlans/RegisteredDetitianCard';
import OnboardingFirstTime from 'pages/onboardingFirstTime';
import { useAppContext } from 'context/appContext';

const LandingStyle = styled.div`
  padding: 23px 0 20px;
  background-color: #f6f5f8;

  .landing-tips {
    width: 266px;
    margin: 0 auto;
    padding: 26px 0;
    color: #51635d;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;

    span {
      color: #52c41a;
      cursor: pointer;
    }
  }
`;

export default function OnboardingPlans({
  dietitianPhoto,
  sortedDietTemplates,
  selectPlan,
  name,
  title,
  socialProfile,
  setShowDietPlans,
  showDietPlans,
}) {
  const { isMobile } = useAppContext();
  return (
    <>
      {!showDietPlans && isMobile ? (
        <OnboardingFirstTime setShowDietPlans={setShowDietPlans} />
      ) : (
        <LandingStyle>
          <div className="container">
            <RegisteredDetitianCard
              dietitianPhoto={dietitianPhoto || '/images/avatar.png'}
              name={name}
              title={title}
              socialProfile={socialProfile}
            />
            <h3 className="landing-tips">
              Enjoy personalized, healthy and delicious food with my <span>Nutritt</span> meal plans
            </h3>
            <MealPlanCards sortedDietTemplates={sortedDietTemplates} selectPlan={selectPlan} />
          </div>
        </LandingStyle>
      )}
    </>
  );
}
