import Styled from "styled-components";
import { FormContainer } from 'components/auth/StyledLoginForm';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';
import { useState ,useEffect} from 'react';
import Header from 'layout/Header';
import MobileHeader from 'components/elements/MobileHeader';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

const ForgotPasswordWrapper = Styled.div`
    background: #fff;
    @media(min-width: 992px) {
        min-height: calc(100vh - 220px);
    }
`;

const ForgotPassword = () => {
    const [windowWidth,setWindowWidth]=useState(window.innerWidth);
    const history = useHistory();
    const location = useLocation();
    const fromRoute = location.state?.from
    const { isDesktop } = useAppContext();

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }

    )

    return (
        <>       
            <ForgotPasswordWrapper>
                <FormContainer>
                    <ForgotPasswordForm windowWidth={windowWidth}/>
                </FormContainer>
            </ForgotPasswordWrapper>
        </>
    );
};

export default ForgotPassword;
