import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Polygon,
  Circle,
  Polyline,
} from 'google-maps-react';
import Geocode from 'react-geocode';
import { chain, unnest, identity, range, isEmpty } from 'ramda';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';
const MILES_TO_METERS = 1609;
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage('en');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('us');

const DeliveryCoverageStyle = styled.div`
  .form-section {
    margin-bottom: 40px;
    display: flex;
    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 14px;
      }

      input {
        height: 46px;
        padding: 0 16px;
        font-size: 16px;
        border: 1px solid #c3cbc9;
        border-radius: 6px;
        ::placeholder {
          color: #939b99;
        }
      }
    }

    .delivery-coverage-wrapper {
      position: relative;
      margin-top: 16px;
    }
    .add-zipcode {
      position: absolute;
      bottom: 11px;
      right: 15px;
      color: #52c41a;
      font-size: 16px;
      cursor: pointer;
    }

    .delivery-coverage-container {
      margin-bottom: 12px;
      display: flex;
      max-width: 600px;
      overflow: scroll;
    }

    .delivery-coverage-item {
      height: 30px;
      margin: 0 5px 8px 0;
      padding: 0 10px;
      color: #000000;
      border: 1px solid #172f0b;
      border-radius: 6px;
      font-size: 14px;
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
      }
    }
  }
`;

const Zone = ({ providerAddress, zipcodes, setZipcodes, radius, ...props }) => {
  const [center, setCenter] = useState();
  const [polygons, setPolygons] = useState([]);
  const [bounds, setBounds] = useState(new props.google.maps.LatLngBounds());
  // Get coords for center of map (provider address)
  useEffect(() => {
    // Get address coords
    Geocode.fromAddress(providerAddress).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({ lat, lng });
      },
      (error) => {
        console.error(error);
      },
    );
  }, [providerAddress]);

  // Get polygons of zips
  useEffect(() => {
    if (isEmpty(zipcodes)) return setPolygons([]);

    fetch(
      `https://vanitysoft-boundaries-io-v1.p.rapidapi.com/rest/v1/public/boundary/zipcode?zipcode=${zipcodes.join(
        '%2C',
      )}`,
      {
        method: 'GET',
        headers: {
          'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
          'x-rapidapi-host': 'vanitysoft-boundaries-io-v1.p.rapidapi.com',
        },
        mode: 'cors',
      },
    )
      .then((response) => response.json())
      .then(({ features }) =>
        setPolygons(chain(({ geometry: { coordinates } }) => coordinates, features)),
      )
      .catch((err) => {
        console.error(err);
      });
  }, [zipcodes]);
  console.log('polygons', polygons);
  console.log('center', center);

  useEffect(() => {
    const newBounds = new props.google.maps.LatLngBounds();

    unnest(polygons).forEach(([lng, lat]) => newBounds.extend({ lat, lng }));

    if (center) newBounds.extend(center);

    setBounds(newBounds);
  }, [polygons, center]);

  console.log('radius, center', radius, center);
  
  const triangleCoords = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
    { lat: 25.774, lng: -80.19 },
  ];
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        Delivery Zipcodes
        <ZipSelector zipcodes={zipcodes} setZipcodes={setZipcodes} />
      </div>
      {/* <Map
        google={props.google}
        zoom={14}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: "400px",
        }}
      >
        <Marker
          onMouseover={this.onMouseoverMarker}
          name={"Current location"}
        />
        <Polyline
          path={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
        />
        <Polygon
          paths={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#0000FF"
          fillOpacity={0.35}
        />
      </Map> */}
      {center && (
        <Map
          google={props.google}
          zoom={14}
          initialCenter={center}
          //   center={center}
          bounds={bounds}
          maxZoom={14}
          containerStyle={{
            position: 'relative',
            width: '100%',
            height: '400px',
            // display: "none",
          }}
        >
          {radius && center && (
            <Circle
              radius={radius * MILES_TO_METERS}
              center={center}
              // strokeColor="transparent"
              strokeOpacity={0}
              strokeWeight={5}
              fillColor="#FF0000"
              fillOpacity={0.2}
            />
          )}
          {polygons.map(
            (p, i) =>
              console.log('p', p) || (
                <Polygon
                  key={i}
                  paths={p.map(([lng, lat]) => ({ lat, lng }))}
                  strokeColor="#0000FF"
                  strokeOpacity={0.8}
                  strokeWeight={2}
                  fillColor="#0000FF"
                  fillOpacity={0.35}
                />
              ),
          )}
          {/* <Marker onClick={this.onMarkerClick}
                 name={'Current location'} />
  
         <InfoWindow onClose={this.onInfoWindowClose}>
             <div>
               <h1>{this.state.selectedPlace.name}</h1>
             </div>
         </InfoWindow> */}
        </Map>
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(Zone);

export const ZipSelector = ({ zipcodes, setZipcodes }) => {
  const [start, setStart] = useState('');
  const genZipOpts = (start) =>
    start.length == 5
      ? [start]
      : range(0, 10).map((s) => ({
          label: `${start}${s}`.padEnd(5, '0'),
          value: `${start}${s}`.padEnd(5, '0'),
        }));
  const options = genZipOpts(start);
  const value = useMemo(() => {
    return zipcodes?.map((zip) => ({ label: zip, value: zip }));
  }, [zipcodes]);

  return (
    <Select
      closeMenuOnSelect={false}
      value={value}
      isMulti={true}
      options={options}
      onChange={(selected) => {
        setZipcodes(selected);
      }}
    />
  );
};

export const DeliveryCoverage = ({ zipcodes, handleChange }) => {
  const [zip, setZip] = useState('');

  const handleZipButtonAction = useCallback(
    (item) => {
      const arr = zipcodes.filter((spec) => spec != item);
      handleChange({
        target: {
          id: 'deliveryCoverage',
          value: arr,
        },
      });
    },
    [zipcodes],
  );

  const handleInputChange = useCallback((e) => {
    const value = e.target.value;
    const isNumber = /^\d*\.?\d*$/.test(value);
    if ((isNumber || value === '') && value.length < 11) {
      const roundNumber = Math.trunc(value);
      const data = roundNumber >= 0 ? value : '';
      setZip(data);
    }
  }, []);

  const addZipHandler = useCallback(() => {
    if (zip) {
      const set = new Set();
      zipcodes?.forEach((item) => set.add(item));
      set.add(zip);
      handleChange({
        target: {
          id: 'deliveryCoverage',
          value: Array.from(set),
        },
      });
      setZip('');
    }
  }, [zipcodes, zip]);

  return (
    <DeliveryCoverageStyle>
      <div className="form-section">
        <div className="form-group delivery-coverage-wrapper">
          <label>Delivery Zipcodes</label>
          <div className="delivery-coverage-container">
            {zipcodes?.map((item, index) => (
              <span className="delivery-coverage-item" key={index}>
                {item}
                <AiOutlineClose onClick={() => handleZipButtonAction(item)} />
              </span>
            ))}
          </div>
          <input
            name="zip"
            type="text"
            autoComplete="off"
            value={zip}
            placeholder="E.g. 10001"
            onChange={handleInputChange}
          />
          {
            <span className="add-zipcode" onClick={addZipHandler}>
              Add
            </span>
          }
        </div>
      </div>
    </DeliveryCoverageStyle>
  );
};
