import styled from 'styled-components';
import Nutritions from './components/Nutritions';
import { useAppContext } from 'context/appContext';
import { HiOutlineLightBulb } from 'react-icons/hi';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  .smash-goals-wrapper {
    display: flex;
    align-items: center;
  }

  @media (max-width: 991px) {
    position: relative;
    padding: 45px 0 0;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);

    .nutritions-wrapper {
      width: 100%;

      & > div {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }

    .wrapper-2 {
      width: 250px;
    }

    .smash-goals-wrapper {
      width: 100%;
      padding: 24px 32px;
      border-top: 1px solid #e0e3e0;
      flex-direction: column;
      align-items: normal;
    }
  }
`;

export default function AnalyzeResults({ nextButton }) {
  const {
    isMobile,
    profile: {
      dietMetrics: {
        calories = 0,
        macros: { carbs = 0, fat = 0, protein = 0 } = {},
      } = {},
    } = {},
  } = useAppContext();
  return (
    <Wrapper>
      <div className="nutritions-wrapper">
        {!isMobile && <p>Your daily nutritional goals</p>}
        <Nutritions calories={calories} carbs={carbs} fat={fat} protein={protein} />
        <div className="smash-goals-wrapper">
          {isMobile ? <>{nextButton}</> : <HiOutlineLightBulb size={28} color="#52C41A" />}
        </div>
        {!isMobile && (
          <p className="eating-healthy">Eating healthy is more important than counting calories!</p>
        )}
      </div>
    </Wrapper>
  );
}
