import { useState, useEffect, memo, useCallback, useRef } from 'react';
import Input from 'components/Input';
import Select from 'components/Select';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import { providersFilter, columns } from './Data';
import { useAppContext } from 'context/appContext';
import ManageModal from 'components/admin/ManageModal';
import BasicButton from 'components/admin/BasicButton';
import VirtualTable from 'components/VirtualTable/VirtualTable';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ProvidersManagementWrapper, ButtonStyles } from './style';
import CreateInviteModal from 'components/admin/CreateInviteModal';
import { useDebounce } from 'use-debounce';
import ImageContainer from 'components/elements/imageUploader/ImageUpload';
import { ASSET_TYPE } from '../../../constants'
import { Modal } from 'components/elements/modal/Modal';

function ProvidersManagement() {
    const [providers, setProviders] = useState([]);
    const [image, setImage] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);  
    const [isActive, setIsActive] = useState({ createProvider: false, inviteProvider: false });
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('active');
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isManageModal, setIsManageModal] = useState(false);
    const [providerData, setProviderData] = useState({});
    const [id, setId] = useState(null);
    const [newProvider, setNewProvider] = useState('');
    const [inviteProvider, setInviteProvider] = useState('');
    const [newProviderCount, setNewProviderCount] = useState(0)
    const { isMobile } = useAppContext();
    const [debouncedSearchText] = useDebounce(search, 700);
    const [isOpen, setIsOpen] = useState(false);
    const modalRef = useRef(null);
    const [getProviders] = useRequest(
        `admin/providers-list?page=${page}&searchText=${debouncedSearchText}&accountStatus=${filter}&pageSize=30`,
    );
    const [createProvider, {isLoading: createIsLoding, error: createError }] = useRequest(`provider/create`, 'post');
    const [inviteNewProvider, { error, setState: setSuccess }] = useRequest(
        `provider/invite`,
        'post',
    );
    const [totalProviders, setTotalProviders] = useState(0);
    const [isEmailExist, {isLoading: inviteIsLoding}] = useRequest('auth/isEmailPhoneNumberExist', 'get');
    const [kitchenHasSubscription, setKitchenHasSubscription] = useState(null);

    const loadProviders = async () => {
        if (!hasNextPage || isLoading) return;
        setIsLoading(true);
        try {
            const { data } = await getProviders();
            setProviders((prevProviders) => [...prevProviders, ...data?.providers]),
            setTotalProviders(data?.totalCount);
            setHasNextPage(page !== data.totalPages);
            setPage((prevPage) => prevPage + 1);
        } catch (e) {
            toast.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const resetTable = useCallback(() => {
        setHasNextPage(true);
        setPage(1);
        setProviders([]);
    }, [filter, debouncedSearchText]);

    const handleSettings = useCallback(
        (provider) => {
            setIsManageModal(true);
            setProviderData(provider);
            setId(provider.id);
        },
        [isManageModal],
    );
    const tableActions = { handleSettings };

    const handleCreateSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const res = await createProvider({ body: { name: newProvider } });
            if (res && Object.keys(res).length) {
                resetTable();
                setNewProviderCount(newProviderCount + 1)
            }
            if (createError) {
                toast.error('There is an error creating provider');
                return;
            }
            setNewProvider('');
            toast.success('Provider Created Successfully');
            setIsActive((prevActive) => ({ ...prevActive, createProvider: false }));
        },
        [newProvider],
    );

    const handleInviteSubmit = useCallback(async (e) => {
        e.preventDefault();
        const findEmail = await isEmailExist({ path: `auth/isEmailPhoneNumberExist?email=${inviteProvider}` });
        if (findEmail?.data?.isEmailPhoneNumberExist) {
            toast.error('Email already exists. Try with another email.');
            return;
        } 
        const res = await inviteNewProvider({ body: { email: inviteProvider } });
        setSuccess(res);
        if (error) {
            toast.error("Can't invite provider");
            return;
        }
        if (!res?.success) {
            toast.error(res?.message);
            return;
        }
        setInviteProvider('');
        toast.success('Provider Invited Successfully');
        setIsActive((prevActive) => ({ ...prevActive, inviteProvider: false }));
    }, [inviteProvider]);

    const handleImageClick = () => {
        setIsVisible(true);
    };    
    
    useEffect(() => {
      resetTable();
      loadProviders();
    }, [filter, debouncedSearchText, newProviderCount]);

    const renderTable = () => {
      if (!providers.length && isLoading) {
        return (
          <p className='empty-provider-list'>Loading<span className="loading-dots">...</span></p>
        );
      } else if (!providers.length && !isLoading) {
        return (<p className='empty-provider-list'>No records found</p>);
      } else {
        return (
          <VirtualTable
            data={providers}
            isLoading={isLoading}
            columns={columns}
            loadMoreItems={loadProviders}
            threshold={2}
            hasNextPage={hasNextPage}
            actions={tableActions}
            id={id}
            tableHeight={isMobile ? window.innerHeight - 428 : window.innerHeight - 300}
          />
        );
      }
    };

    const handleSearchChange = useCallback((e) => {
        setSearch(e.target.value);
        setPage(1);
        setHasNextPage(true);
    }, []);

    const subscriptionCount = kitchenHasSubscription?.subscriptionCount;

    return (
        <ProvidersManagementWrapper>
            <ButtonStyles>
                <BasicButton
                    onClick={() => {
                        setIsActive((prevActive) => ({ ...prevActive, createProvider: true }));
                    }}
                    content="+ Create"
                />
                <BasicButton
                    onClick={() => {
                        setIsActive((prevActive) => ({ ...prevActive, inviteProvider: true }));
                    }}
                    content="+ Invite"
                />
            </ButtonStyles>
            {isOpen && (
            <div className="modal-position">
                <Modal
                  size="large"
                  type="error"
                  description={`System detects ${subscriptionCount} active subscriptions for this kitchen, and any changes made will affect their recurring orders. Are you sure you want to proceed?`}
                  onOk={() => modalRef.current.handleSave()}
                  isOpen={isOpen}
                  onClose={() => setIsOpen(!isOpen)}
                  onCancel={() => setIsOpen(!isOpen)}
                  title={'Are you sure?'}
                  primaryText={`Save`}
                  secondaryText={'Cancel'}
                  isBackdropEnabled={true}
                  buttonType={'dark'}
                />
              </div>
            )}
            <div
                className="providers-management-wrapper"
                style={{ height: isMobile ? window.innerHeight - 196 : '100%' }}
            >
                <h2 className="providers-title">Providers - {totalProviders}</h2>
                <div className="providers-management-container">
                    <div className="provider-search-wrapper">
                        <div className="search-wrapper">
                            <Input
                                Icon={SearchIcon}
                                onChange={handleSearchChange}
                                value={search}
                                placeholder="Search provider..."
                            />
                        </div>
                        <Select
                            key="type"
                            defaultChecked={filter}
                            label="Account status"
                            icon={'SelectArrow'}
                            options={providersFilter}
                            updateValue={(value) => {
                                value !== filter && resetTable();
                                setFilter(value);
                            }}
                        />
                    </div>
                   {
                    renderTable()
                   }
                </div>
            </div>
            {isActive.createProvider && (
                <CreateInviteModal
                    title="Create provider"
                    label="Name"
                    inputType="text"
                    buttonText="Create"
                    isActive={isActive.createProvider}
                    setIsActive={setIsActive}
                    value={newProvider}
                    setValue={setNewProvider}
                    handleSubmit={handleCreateSubmit}
                    isLoding={createIsLoding}
                />
            )}
            {isActive.inviteProvider && (
                <CreateInviteModal
                    title="Invite provider"
                    label="Email"
                    inputType="email"
                    buttonText="Invite"
                    isActive={isActive.inviteProvider}
                    setIsActive={setIsActive}
                    value={inviteProvider}
                    setValue={setInviteProvider}
                    handleSubmit={handleInviteSubmit}
                    isLoding={inviteIsLoding}
                />
            )}
        {isVisible && (
          <div className="modal-container">
            <ImageContainer
              image={image}
              setImage={setImage}
              setIsVisible={setIsVisible}
              setNewImgUrl={(image) => {
                setUploadedImage(image);
              }}
              greenBtn={"true"}
              assetType={ASSET_TYPE?.kitchenBusinessStore}
              id={id}
            />
          </div>
        )}
      {isManageModal && (
        <ManageModal
          setIsManageModal={setIsManageModal}
          info={providerData}
          setId={setId}
          providers={providers}
          setProviders={setProviders}
          filter={filter}
          image={image}
          setIsVisible={setIsVisible}
          handleImageClick={handleImageClick}
          setUploadedImage={setUploadedImage}
          uploadedImage={uploadedImage}
          setImage={setImage}
          setTotalProviders={setTotalProviders}
          setIsOpen={setIsOpen}
          ref={modalRef}
          kitchenHasSubscription={kitchenHasSubscription}
          setKitchenHasSubscription={setKitchenHasSubscription}
        />
      )}
        </ProvidersManagementWrapper>
    );
}
export default memo(ProvidersManagement);