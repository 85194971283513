import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useAppContext } from 'context/appContext';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import MealDragDrop from './MealDragDrop';
import DietPlannerCheckout from './MealDragDrop/DietPlannerCheckout';
import UserInfo from './UserInfo';

const MealsDietPlannerWrapper = styled.div`
  width: 430px;
  padding-top: 90px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) -6px 0px 4px -2px;

  .title-wrapper {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      margin-left: 12px;
      color: #0b1d17;
    }
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #e0e3e0;
    justify-content: center;

    .MuiTab-textColorInherit {
      margin-bottom: 10px;

      span {
        color: #51635d;
        font-size: 18px;
        font-family: 'Roboto';
        line-height: 24px;
      }

      &.Mui-selected span {
        color: #52c41a;
        font-family: 'Roboto';
      }
    }
  }

  .MuiTabs-indicator {
    height: 3px !important;
  }

  @media (min-width: 992px) {
    &.guestDietPlanner {
      .title-wrapper {
        margin-bottom: 0;
      }
    }
  }
`;

{
  TabPanel;
}

export default function MealsDietPlanner() {
  const [value, setValue] = useState(0);
  const {isGuest , isVisitor} = useAppContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MealsDietPlannerWrapper className={classNames({guestDietPlanner: isGuest || isVisitor})}> 
      <div className="title-wrapper">
        <img src="/icons/menu-board-black.svg" alt="menuBoard icon" />
        <h2>Diet planner</h2>
      </div>
      {!(isGuest || isVisitor) && (
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Meals" />
          {( !isGuest && !isVisitor) && <Tab label="Plan Info" />}
        </Tabs>
      )}
      <TabPanel value={value} index={0}>
        <MealDragDrop />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserInfo />
      </TabPanel>
      <DietPlannerCheckout />
    </MealsDietPlannerWrapper>
  );
}
