import styled from 'styled-components';
import { ReactComponent as UserIcon } from '../../../../assets/user.svg';
import { ReactComponent as SmsIcon } from '../../../../assets/sms.svg';
import { ReactComponent as CallIcon } from '../../../../assets/call.svg';
import VoidAllForm from './VoidAllForm';
import PayAllForm from './PayAllForm';
import SubmitButton from '../../../../components/SubmitButton';
import { useState, useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import { useHistory } from 'react-router-dom';

const ProviderCardStyle = styled.div`
  padding: 24px 12px 1px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  .provider_num {
    position: absolute;
    top: 0px;
    right: 20px;
    .icon_counter {
      display: flex;
      .icon {
        color: #e0e3e0;
        cursor: pointer;
        &.active {
          color: #52c41a;
        }
      }
    }
    .number_couter {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.05em;
      color: #51635d;
      margin-top: 5px;
      text-align: center;
    }
  }
  .card_header {
    display: flex;
    gap: 24px;
    margin-bottom: 27px;
    .provider_image {
      width: 114px;
      padding-left: 14px;
      img {
        width: 100%;
        height: 100px;
      }
    }
    .provider_details {
      flex: 1;
      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635d;
        margin-bottom: 20px;
        .img{
          cursor:"pointer";
        }
      }
      .name-img{
        display:flex;
        column-gap:7px;
        img{
            margin-top: -22px;
            margin-left: 7px;
            cursor:pointer;
        }

    }
      .provider_details_wrapper {
        display: flex;
        row-gap: 10px;
        flex-wrap: wrap;
        padding-top: 10px;
        .details {
          display: flex;
          width: 50%;
          gap: 7px;
          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #51635d;
            word-break: break-all;
            .bold {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .card_body {
    .coach_details,
    .customer_details {
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
      width: 100%;
    }
    .coach_details {
      margin-top: 24px;
    }
    td {
      border: 1px solid #e0e3e0;
    }
    .box {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #949d9b;
      padding: 11px 14px;
      display: flex;
      gap: 5px;
      span {
        color: #51635d;
        font-weight: 700;
        display: block;
      }
      &.address {
        flex-wrap: wrap;
      }
      &.payout {
        background: #f6f5f8;
      }
    }
  }
  .chevron_down {
    position: absolute;
    bottom: -14px;
    background: #ffffff;
    border: 1px solid #cbedba;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    .icon {
      color: #51635d;
      &.reverse {
        transform: rotate(-180deg);
      }
    }
  }
  @media (min-width: 2300px) {
    padding: 24px 48px;
    .provider_num {
      position: absolute;
      top: 40px;
      right: 20px;
    }
    .card_header {
      .provider_details {
        .provider_details_wrapper {
          padding: 0px 186px 0px 10px !important;
          .details {
            width: 22%;
          }
        }
      }
    }
  }
  @media (max-width: 1420px) {
    padding: 24px 12px 66px;
    .card_header {
      margin-bottom: 0px !important;
    }
    .button-display {
      position: absolute;
      display: flex;
      .icon_counter {
        flex-direction: row;
        display: flex;
        position: absolute;
        padding-bottom: 30px;
        padding-left: 122px;
      }
    }
    .card_header {
      gap: 14px;
    }
    .card_body {
      td {
        width: 50% !important;
      }
    }
  }
  @media (max-width: 1280px) {
    .card_body {
      .box {
        padding: 8px 14px;
      }
    }
  }
  .btn-end2 {
    border-radius: 31px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6.22% 2.22% 0 0;
    gap: 5px;
    background: #52c41a;
    height: 33px;
    width: 120px;
    color: #ffffff;
    &:disabled {
      background-color: #c3c3c3 !important;
      border: 1px solid #c3c3c3 !important;
    }
  }
  @media (max-width: 991px) {
    padding: 44px 24px 45px;
    .provider_num {
      right: auto;
      left: 24px;
      top: 200px;
    }
    .card_header {
      flex-direction: row-reverse;
      gap: 0;
      margin-bottom: 15px;
      padding-bottom: 24px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: -24px;
        width: 99vw;
        top: 240px;
        height: 1px;
        background: #e0e3e0;
      }
      .provider_image {
        padding-left: 0;
        width: 50px;
        img {
          height: 48px;
          width: 48px;
        }
      }

      .provider_details {
        .provider_details_wrapper {
          row-gap: 8px;
          .details {
            width: 100%;
          }
        }
      }
    }
    .card_body {
      td {
        display: block;
        border: 0px;
        width: 100% !important;
      }
      .box {
        padding: 7px 0px;
        &.address {
          flex-wrap: unset;
        }
        &.payout {
          background: #fff;
        }
      }
      .coach_details {
        margin-top: 0;
      }
    }
  }
`;

export default function ProviderCard({
  order,
  size,
  fetchAllProviders,
  sum,
  selectedMeals,
  selectedNonMeals,
  totalMeals,
  setActiveOrderId,
  setIsOrderDetailsActive,
  activeTab,
  setIsModalOpen = () => {},
}) {
  const { isMobile } = useAppContext();
  const [isModal, setIsModal] = useState(false);
  const [isPayModal, setPayIsModal] = useState(false);
  const { images, name, email, phoneNumber, address, zip, id, city, state } = order?.provider || {};
  const history = useHistory();

  const providerAddress = `${address}${address ? ', ' + city : ''}${state ? ', ' + state : ''}${zip ? ', ' + zip : ''}`;

  useEffect(() => {
    if ((isModal || isPayModal) && isMobile) {
      document.body.classList.add('bodyFixed');
      setIsModalOpen(true);
    } else {
      document.body.classList.remove('bodyFixed');
      setIsModalOpen(false);
    }
  }, [isModal, isPayModal]);

  return (
    <ProviderCardStyle>
      {size >= 1419.99 && (
        <div className="provider_num">
          <div className="icon_counter">
            <SubmitButton
              className="btn-end2"
              onClick={() => setPayIsModal(true)}
              disabled={activeTab === 'paid' || activeTab === 'voided'}
              title={
                activeTab === 'paid'
                  ? 'Items are already paid'
                  : activeTab === 'voided'
                  ? "Items can't pay here"
                  : ''
              }
            >
              <img src="/images/dollar-white.svg" alt="dollar Icon" />
              Pay All
            </SubmitButton>
            <SubmitButton
              className="btn-end2"
              onClick={() => setIsModal(true)}
              disabled={activeTab === 'paid' || activeTab === 'voided'}
              title={
                activeTab === 'paid'
                  ? "Items can't voided here"
                  : activeTab === 'voided'
                  ? 'Items are already voided'
                  : ''
              }
            >
              <img src="/images/Close-cross.svg" alt="CrossIcon" />
              Void All
            </SubmitButton>
          </div>
        </div>
      )}
      {size <= 991 && (
        <div className="provider_num">
          <div className="icon_counter">
            <SubmitButton
              className="btn-end2"
              onClick={() => setPayIsModal(true)}
              disabled={activeTab === 'paid' || activeTab === 'voided'}
              title={
                activeTab === 'paid'
                  ? 'Items are already paid'
                  : activeTab === 'voided'
                  ? "Items can't pay here"
                  : ''
              }
            >
              <div className="icon">
                <img src="/images/dollar-white.svg" alt="dollar Icon" />
              </div>
              Pay All
            </SubmitButton>
            <SubmitButton
              className="btn-end2"
              onClick={() => setIsModal(true)}
              disabled={activeTab === 'paid' || activeTab === 'voided'}
              title={
                activeTab === 'paid'
                  ? "Items can't voided here"
                  : activeTab === 'voided'
                  ? 'Items are already voided'
                  : ''
              }
            >
              <div className="icon">
                <img src="/images/Close-cross.svg" alt="CrossIcon" />
              </div>
              Void All
            </SubmitButton>
          </div>
        </div>
      )}
      {!isMobile ? (
        <>
          <div className="card_header">
            <div className="provider_image">
            <img
              src={images || "/images/avatar.png"}
              alt="Avatar"
              onError={(e) => {e.target.src = '/images/avatar.png'}}
            />
            </div>
            <div className="provider_details">
            <span className='name-img'>
              <h2 className="title">
                {name}
                <img 
                src="/images/export1.svg" 
                alt="export Icon"  
                onClick={() => {
                  const url = `/kitchen?providerId=${id}`
                  window.open(url, "_blank");
                }}/>
              </h2>
              </span>
              <div className="provider_details_wrapper">
                <div className="details">
                  <div className="icon">
                    <UserIcon />
                  </div>
                  <div className="text">
                    {!isMobile
                      ? window.innerWidth > 1440
                        ? 'Contact person:'
                        : ''
                      : 'Contact person:'}{' '}
                    <span className="bold">{name}</span>
                  </div>
                </div>
                <div className="details">
                  <div className="icon">
                    <SmsIcon />
                  </div>
                  <div className="text">
                    {!isMobile ? (window.innerWidth > 1440 ? 'Email:' : '') : 'Email:'}{' '}
                    <span className="bold">{email}</span>
                  </div>
                </div>
                <div className="details">
                  <div className="icon">
                    <CallIcon />
                  </div>
                  <div className="text">
                    {!isMobile ? (window.innerWidth > 1440 ? 'Phone:' : '') : 'Phone:'}{' '}
                    <span className="bold">{phoneNumber}</span>
                  </div>
                </div>
                <div className="details">
                  <div className="icon">
                    <img src="/images/Location.svg" alt="location Icon" />
                  </div>
                  <div className="text">
                    {!isMobile ? (window.innerWidth > 1440 ? 'Address:' : '') : 'Address:'}{' '}
                    <span className="bold">
                      {providerAddress}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {size < 1420 && size >= 991 && (
            <div className="button-display">
              <div className="icon_counter">
                <SubmitButton
                  className="btn-end2"
                  onClick={() => setPayIsModal(true)}
                  disabled={activeTab === 'paid' || activeTab === 'voided'}
                  title={
                    activeTab === 'paid'
                      ? 'Items are already paid'
                      : activeTab === 'voided'
                      ? "Items can't pay here"
                      : ''
                  }
                >
                  <div className="icon">
                    <img src="/images/dollar-white.svg" alt="dollar Icon" />
                  </div>
                  Pay All
                </SubmitButton>
                <SubmitButton
                  className="btn-end2"
                  onClick={() => setIsModal(true)}
                  disabled={activeTab === 'paid' || activeTab === 'voided'}
                  title={
                    activeTab === 'paid'
                      ? "Items can't voided here"
                      : activeTab === 'voided'
                      ? 'Items are already voided'
                      : ''
                  }
                >
                  <div className="icon">
                    <img src="/images/Close-cross.svg" alt="CrossIcon" />
                  </div>
                  Void All
                </SubmitButton>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="card_header">
          <div className="provider_image">
            <img
              src={images || "/images/avatar.png"}
              alt="Avatar"
              onError={(e) => {e.target.src = '/images/avatar.png'}}
            />
          </div>
          <div className="provider_details">
            <h2 className="title">
              {order?.name}
              <img 
              src="/images/export1.svg" 
              alt="location Icon"  
              onClick={() => {
                const url = `/kitchen?providerId=${id}`
                window.open(url, "_blank");
              }}
              />
            </h2>
            <div className="provider_details_wrapper">
              <div className="details">
                <div className="icon">
                  <UserIcon />
                </div>
                <div className="text">
                  Contact Person: <span className="bold">{order?.name}</span>
                </div>
              </div>
              <div className="details">
                <div className="icon">
                  <SmsIcon />
                </div>
                <div className="text">
                  Email: <span className="bold">{order?.email}</span>
                </div>
              </div>
              <div className="details">
                <div className="icon">
                  <CallIcon />
                </div>
                <div className="text">
                  Phone: <span className="bold">{order?.phoneNumber}</span>
                </div>
              </div>
              <div className="details">
                <div className="icon">
                  <img src="/images/dollar-square.svg" alt="dollar Icon" />
                </div>
                <div className="text">
                  Total payout: <span className="bold">${order?.total}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isModal && (
        <VoidAllForm
          setIsModal={setIsModal}
          isModal={isModal}
          order={order}
          fetchAllProviders={fetchAllProviders}
          sum={sum}
          selectedMeals={selectedMeals}
          selectedNonMeals={selectedNonMeals}
          totalMeals={totalMeals}
          setIsOrderDetailsActive={setIsOrderDetailsActive}
          setActiveOrderId={setActiveOrderId}
        />
      )}
      {isPayModal && (
        <PayAllForm
          setPayIsModal={setPayIsModal}
          isPayModal={isPayModal}
          order={order}
          fetchAllProviders={fetchAllProviders}
          sum={sum}
          selectedMeals={selectedMeals}
          selectedNonMeals={selectedNonMeals}
          totalMeals={totalMeals}
          setIsOrderDetailsActive={setIsOrderDetailsActive}
          setActiveOrderId={setActiveOrderId}
        />
      )}
    </ProviderCardStyle>
  );
}
