import React from 'react'
import styled from 'styled-components';
import Profile from 'components/elements/profileCard/Profile'
import { theme } from '../../../shared/theme/theme';


const HeaderWrapper = styled.div`
display:flex;
height:78px;
background-color:${theme.colors.white};
align-items: center;
padding-right:30px;
justify-content: flex-end;
`

function Header({ profileName, profileEmail, showLogout, showProfile, showBilling, showSetting, redColor, img }) {
    return (
        <HeaderWrapper>
            <Profile profileName={profileName} profileEmail={profileEmail} redColor={redColor} showLogout={showLogout} showProfile={showProfile} showBilling={showBilling} showSetting={showSetting} img={img}/>
        </HeaderWrapper>
    )
}

export default Header