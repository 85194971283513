import styled from 'styled-components';
import icons from '../../../../../.././components/TagIcons';
import Toggle from 'components/elements/toggleModule/Toggle';
import { theme } from 'shared/theme/theme';
import Tooltip from 'rc-tooltip';
import CustomTooltip from './customToolTip'

const Container = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap:8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; /* Set cursor to not-allowed when disabled */
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')}; /* Disable pointer events when disabled */
  .icon-wrapper {
    width:28px;
    height:28px;
    border: ${(props) => (props.isSelected ? `1px solid ${theme?.colors?.success}` :  `1px solid ${theme?.colors?.grayLight}` )};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props?.isSelected ? `${theme?.colors?.success}` : `${theme?.colors?.grayLight}`)};
    img{
      width:24px;
      height:24px;
      filter: ${(props) => (props?.isSelected ? 'invert(57%) sepia(46%) saturate(1920%) hue-rotate(62deg) brightness(107%) contrast(80%)' : '')};
    }
  }
  .tag-name {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: ${(props) => (props?.isSelected ? `${theme.colors.success}` : `${theme?.colors?.grayDarker}` )};
    font-weight: 400;
  }
  svg{
    width: 16.333px;
    height: 16.333px;
  }
  .cCRJNH{
    width:30px !important;
  }
  .tagIcon{
  path{
    fill: ${(props) => (props?.isSelected ? `${theme.colors.success}`:  '' )};
  }
}
`;


export default function Tags({ tag, selectedTags, onTagClick, isSelected, avoid, recomend, type = '', disabled }) {
  const Icon = icons[tag];
  let formattedTag = tag;
  if (tag.includes('-')) {
      formattedTag = tag.split('-').join(' - ');
  }

  return (
      <Container key={tag} disabled={disabled} onClick={() => onTagClick(tag, isSelected, avoid, recomend)} isSelected={isSelected} selectedTags={selectedTags} avoid={avoid} recomend={recomend}>
          <Toggle type="checkbox" toggleColor="black" checked={isSelected} disabled={disabled} /> {/* Pass the disabled prop to the checkbox */}
          <span className={`icon-wrapper ${type} ${recomend || avoid ? 'selected':''}`}>
            <div className='tagIcon'>
          {Icon && <Icon />}
            </div>
          </span>
          <span className="tag-name">{tag.includes('-') ? formattedTag : tag}</span>
          {/* {disabled && <CustomTooltip placement={'top'}     tooltipText={`The ${avoid ? "allergen" : recomend ? "diet" : ""} was identified from the database.`} showArrow={false}/>} */}
      </Container>
  );
}
