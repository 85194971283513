import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BsArrowUp } from 'react-icons/bs';
import { IoMdPerson } from 'react-icons/io';
import { IoCartOutline, IoWalletOutline } from 'react-icons/io5';

const NutritionalPlanWrapper = styled.div`
  margin-bottom: 30px;
  padding: 17px 16px;
  color: #0b1c18;
  border: 1px solid #e3e5e2;
  border-radius: 12px;
  box-shadow: 0 3px 6px #0000000b;

  .lose-belly-description {
    margin-bottom: 24px;
    padding: 0 8px 16px;
    border-bottom: 1px solid #d0d1d0;
    display: flex;
    justify-content: space-between;
  }

  .lose-belly-info {
    width: 80%;

    .title {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
    }

    span,
    p {
      font-size: 16px;
    }
  }

  h4 {
    margin-right: 57px;
    font-size: 20px;
    font-weight: 500;
  }

  .plans-item-wrapper {
    padding: 0 8px;
    color: #000000;
    display: flex;
  }

  .item-container {
    margin-right: 32px;
    padding: 20px;
    background-color: #f7f6f7;
    border: 1px solid #f3f5f2;
    border-radius: 12px;

    .title {
      margin-bottom: 14px;
      font-size: 18px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 17px;
        font-size: 24px;
      }
    }

    .total {
      margin-bottom: 8px;
      font-size: 16px;

      span:first-child {
        margin-right: 47px;
      }
    }

    .change {
      font-size: 16px;
      display: flex;
      align-items: center;

      span:first-child {
        margin-right: 19px;
      }

      svg {
        font-size: 20px;
      }

      span:last-child {
        margin-left: 10px;
        color: #9d9d9d;
        font-size: 14px;
      }
    }
  }

  .edit-plan-btn-wrapper {
    background: white;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    position: relative;
    margin-bottom: 24px;
    padding: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .lose-belly-info {
      width: 100%;

      .title {
        margin-bottom: 15px;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    h4 {
      margin: 0 0 5px;
      font-size: 18px;
    }

    .lose-belly-edit {
      position: absolute;
      top: 7px;
      right: 5px;

      img {
        height: 25px;
        width: 25px;
      }
    }

    .lose-belly-description {
      margin-bottom: 18px;
    }

    .plans-item-wrapper {
      flex-direction: column;

      .item-container + .item-container {
        margin-top: 16px;
      }
    }

    .item-container {
      margin-right: 0;
      padding: 10px;

      .total,
      .change {
        display: flex;

        span:first-child {
          width: 40%;
          margin-right: 0;
        }
      }

      .total span:last-child {
        margin-left: 6px;
      }
    }
  }
`;

export default function NutritionistPlan(prop) {
  const history = useHistory();
  const handleEdit = useCallback(() => {
    history.push(`/nutritionist/meal-plan/edit/${prop.id}`);
    window.scrollTo(0, 0);
  }, [prop?.id]);

  return (
    <NutritionalPlanWrapper>
      <div className="lose-belly-description">
        <div className="lose-belly-info">
          <div className="title">
            <h4 data-testid="plan-name">{prop.name}</h4>
            <span data-testid="plan-duration">
              {prop.duration}{' '}
              {prop.duration_type.charAt(0).toLowerCase() + prop.duration_type.slice(1)}
            </span>
          </div>
          <p>{prop.introduction}</p>
        </div>
        <div>
          <button className="edit-plan-btn-wrapper" onClick={handleEdit}>
            <div className="lose-belly-edit">
              <img src="/images/pen.svg" alt="Editor" />
            </div>
          </button>
        </div>
      </div>
      <div className="plans-item-wrapper">
        <div className="item-container">
          <div className="title">
            <IoWalletOutline />
            <span>Earnings</span>
          </div>
          <div className="total">
            <span>Total</span>
            <span data-testid="total-earnings">$ {(prop.totalEarning / 100).toFixed(2)}</span>
          </div>
          <div className="change">
            <span>Change</span>
            <BsArrowUp />
            <span>$ 0</span>
            <span>last 3 months</span>
          </div>
        </div>
        <div className="item-container">
          <div className="title">
            <IoMdPerson />
            <span>Clients</span>
          </div>
          <div className="total">
            <span>Total</span>
            <span data-testid="total-clients">{prop.totalClient}</span>
          </div>
          <div className="change">
            <span>Change</span>
            <BsArrowUp />
            <span>{prop?.lastThreeMonthsUsers}</span>
            <span>last 3 months</span>
          </div>
        </div>
        <div className="item-container">
          <div className="title">
            <IoCartOutline />
            <span>Orders</span>
          </div>
          <div className="total">
            <span>Total</span>
            <span data-testid="total-orders">{prop.totalOrders}</span>
          </div>
          <div className="change">
            <span>Change</span>
            <BsArrowUp />
            <span>0</span>
            <span>last 3 months</span>
          </div>
        </div>
      </div>
    </NutritionalPlanWrapper>
  );
}

