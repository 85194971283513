import { memo, useState, useRef } from 'react';
import { theme } from 'shared/theme/theme';
import { useAppContext } from 'context/appContext';
import { Modal } from 'components/elements/modal/Modal';
import { Button } from 'components/elements/button/Button';

import { ShareLinkModalWrapper, ActionButtonStyle } from './style';

function ShareLinkModal({ setShareEnabled, setIsCopied }) {
  const {user, isSuper} = useAppContext();
  const imitateProviderStorage = sessionStorage.getItem("imitateProvider");
  const [storeUrl, setStoreUrl] = useState(`${process.env.REACT_APP_CLIENT_URL}/store/${isSuper ? JSON.parse(imitateProviderStorage)?.user?.username : user?.username}`);

  const handleClose = () => {
    setShareEnabled(false);
  };

  const ActionButton = ({ theme, storeUrl }) => {
    const copyRef = useRef();

    const handleCopyClick = () => {
      if (copyRef.current) {
        const textToCopy = copyRef.current.innerText;
        if (textToCopy) {
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              setIsCopied(true);
              handleClose();
              setTimeout(() => {
                setIsCopied(false);
              }, 2000);
            })
            .catch((error) => {
              console.error('Error copying text:', error);
            });
        }
      }
    };

    return (
      <ActionButtonStyle theme={theme}>
        <div className="link" ref={copyRef}>
          {storeUrl}
        </div>
        <Button type={'dark'} onClick={handleCopyClick} title="Copy link" />
      </ActionButtonStyle>
    );
  };

  return (
    <ShareLinkModalWrapper>
      <Modal
        type=""
        title={'Share Link'}
        description={'Anyone with this link can access the online menu.'}
        customAction={<ActionButton storeUrl={storeUrl} theme={theme} />}
        onClose={handleClose}
      />
    </ShareLinkModalWrapper>
  );
}

export default memo(ShareLinkModal);
