import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#fff',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#00092D',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 34%,transparent 41%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#00092D',
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const RadioButton = ({ id, label, options, value, onChange, providerSubscriptionList, setProviderSubscriptionSelection, isApplePay }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  useEffect(() => {
    const filteredOptions = options.filter((option) => {
      const subscription = providerSubscriptionList.find(
        (sub) => sub.intervaldays === option.intervaldays
      );
      return option.value === "once" || subscription;
    }).map((option) => {
      const subscription = providerSubscriptionList.find(
        (sub) => sub.intervaldays === option.intervaldays
      );
      if (subscription) {
        return {
          ...option,
          discount: subscription.discount,
          kitchenSubscriptionId: subscription?.id
        };
      }
      return option;
    });
  
    setFilteredOptions(filteredOptions);
  }, [options, providerSubscriptionList]);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = filteredOptions.find(option => option.value === selectedValue);
    
    if (selectedOption && selectedOption.value !== "once") {
      setProviderSubscriptionSelection(prevState => {
        const updatedSelection = [...prevState];
        const index = updatedSelection.findIndex(item => item.providerId === id);

        if (index !== -1) {
          updatedSelection[index] = {
            providerId: id,
            kitchenSubscriptionId: selectedOption.kitchenSubscriptionId
          };
        } else {
          updatedSelection.push({
            providerId: id,
            kitchenSubscriptionId: selectedOption.kitchenSubscriptionId
          });
        }

        return updatedSelection;
      });
    } else {
      setProviderSubscriptionSelection(prevState => {
        const updatedSelection = [...prevState];
        const index = updatedSelection.findIndex(item => item.providerId === id);
        if (index !== -1) {
          updatedSelection.splice(index, 1);
        }
        return updatedSelection;
      });
    }

    onChange(event);
  };
    
  return (
    <FormControl component="fieldset">
       <div className="title">{label}</div>
      <RadioGroup value={value} onChange={handleOptionChange}>
        {filteredOptions.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<BpRadio />}
            label={
                <span className='label'>{option.label} {option?.discount && <span className='title-value'>{`- ${option?.discount}% off`}</span>}</span>
              }
            labelPlacement="end"
             />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
