import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-responsive-modal';
import { ReactComponent as Close } from 'assets/shop/close-cross.svg';
import { Button } from '../button/Button';
import { theme } from 'shared/theme/theme';

const ConfirmationModalStyle = styled.div`
  .modal {
    border-radius: 10px;
    width: 100%;
    height: 162px;
    background: ${theme?.colors?.white};
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    padding: 14px 0 24px 0px;
    .close-image {
      position: relative;
      left: 16px;
    }
    .container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0px 8.976px 0px 8px;
      .delete-title {
        color: ${theme?.colors?.deepForestGreen};
        text-align: center;
        font-family: ${theme?.typography?.fontGilroy};
        font-size: ${theme?.typography?.fontBase};
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .delete-buttons {
        width: 220px;
        align-self: center;
      }
      .macronutrients {
        margin-top: 24px;
      }
      .serving {
        border: none !important;
      }
      .button {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .size {
        color: ${theme?.colors?.blueBlack} !important;
        position: relative;
        right: 14px;
        font-family: ${theme?.typography?.fontGilroy};
        font-size: ${theme?.typography?.fontRegular};
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
      .swiper-slide.swiper-slide-active {
        color: ${theme?.colors?.darkBlue} !important;
        text-align: center;
        font-family: Gilroy;
        font-size: ${theme?.typography?.fontSmall};
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  @media (min-width: 991px) {
    .delete-buttons{
      width: 270px !important;
    }
    .button{
      flex-direction: row!important;
      button{
        width: 129.5px;
      }
    }
  }
  @media (max-width: 991px  ){
    .modal{
      height: 100% !important;
    }
  }
`;

const deleteCustomStyles = {
  modalContainer: {
    position: 'relative',
    top: window?.innerWidth > 991 ? 0 : '70%',
    margin: '0 16px',
    overflowY: 'hidden',
    background: 'none !important',
  },
  modal: {
    width: window?.innerWidth > 991 ? '343px' : 'auto',
  }
};

const ConfirmationModal = ({
  title,
  primaryTitle,
  secondaryTitle,
  isOpen,
  onClickClose,
  handlePrimaryClick,
  handleSecondaryClick,
}) => {
  return (
    <Modal classNames={{modal : "delete__modal"}} open={isOpen} blockScroll={false} center showCloseIcon={false} styles={{ modalContainer: deleteCustomStyles.modalContainer, modal: deleteCustomStyles.modal }}>
      <ConfirmationModalStyle theme={theme}>
        <div className="modal">
          <Close className="close-image" onClick={onClickClose} />
          <div className="container">
            <p className="delete-title" dangerouslySetInnerHTML={{__html: title}}></p>
            <div className="delete-buttons">
              <div className="button">
                <Button type={'mobile_save'} title={primaryTitle} onClick={handlePrimaryClick} />
                <Button
                  type={'mobile_secondary'}
                  title={secondaryTitle}
                  onClick={handleSecondaryClick}
                />
              </div>
            </div>
          </div>
        </div>
      </ConfirmationModalStyle>
    </Modal>
  );
};

export default ConfirmationModal;
