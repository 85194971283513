import moment from 'moment';
import { displayDateTime } from 'utils';
import { useKitchenContext } from 'context/kitchenContext';
import OrderItems from 'components/elements/orderDetail/OrderItems';
import DeliveryInfo from 'components/elements/orderDetail/DeliveryInfo';
import PaymentBreakdown from 'components/elements/orderDetail/PaymentBreakdown';
import OrderNumberStatus from 'components/elements/orderDetail/OrderNumberStatus';
import { useLocation } from 'react-router-dom';
import { PrintShapeWrapper, PrintHeaderWrapper } from './style';
import { useAppContext } from 'context/appContext';

export default function PrintShape({ selectedOrder, checkOrder }) {
  const { friendlyOrderNum, orderStatus, orderedAt, dateTimeOrdered, providers, orderLine, user } =
    selectedOrder || checkOrder;
    const { isSuper } = useAppContext();
  const { kitchenProvider } = useKitchenContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const providerIdFromUrl = searchParams.get('providerId');

  const findProviderIndex = providers?.findIndex(
    (provider) =>
      provider?.Provider?.id === Number(providerIdFromUrl) ||
      provider?.Provider?.id === kitchenProvider?.id,
  );

  const {
    selectedDate,
    selectedTime,
    taxAmount,
    providerSubTotal,
    providerPayout,
    isDelivery,
    deliveryFee,
    deliveryData,
    phoneNumber,
    orderNote,
    isRecurringOrder,
    providerFee,
    street,
    city,
    state,
    zip,
    Provider: { id: providerId } = {},
    repeatOrderDiscount,
  } = isSuper ? providers[0] : providers[findProviderIndex];
  const formattedTime = moment(dateTimeOrdered).format('LT');
  const orderStatusIndex = orderStatus.findIndex((item) => item?.providerId === providerId);
  const orderType = orderStatus[orderStatusIndex]?.action;
  const { name, lastName } = user;
  const customerName = name + (lastName?.length ? ' ' + lastName : '');
  const method = isDelivery ? 'Delivery' : 'Pickup';
  const printNow = moment(new Date()).format('MM/DD/YYYY LT');

  return (
    <>
      <PrintHeaderWrapper id="printHeaderScreen">
        <div className="print-date-time">{printNow}</div>
        <div className="print-title">Nutritt</div>
      </PrintHeaderWrapper>
      <PrintShapeWrapper id="PrintShapeScreen">
        <div className="order-number-wrapper">
          <OrderNumberStatus
            orderNumber={friendlyOrderNum}
            orderType={orderType}
            displayOrderDate={displayDateTime(orderedAt)}
            formattedTime={formattedTime}
          />
        </div>
        <div className="delivery-info-wrapper">
          <DeliveryInfo
            customerName={customerName}
            phoneNumber={phoneNumber}
            method={method}
            displaySelectedDate={displayDateTime(selectedDate)}
            selectedTime={selectedTime}
            deliveryData={deliveryData}
            orderNote={orderNote}
            street={street}
            city={city}
            state={state}
            zip={zip}
            orderType={orderType}
          />
        </div>
        <div className="orders-items-wrapper">
          <OrderItems orderLine={orderLine} />
        </div>
        <div className="payment-breakdown-wrapper">
          <PaymentBreakdown
            providerSubTotal={providerSubTotal}
            deliveryFee={deliveryFee}
            taxAmount={taxAmount}
            providerPayout={providerPayout}
            repeatOrderDiscount={repeatOrderDiscount}
            isRecurringOrder={isRecurringOrder}
          />
        </div>
      </PrintShapeWrapper>
    </>
  );
}
