import InfiniteScroll from 'react-infinite-scroll-component';
import { Circle } from 'rc-progress';
import { useState } from 'react';
import { useAppContext } from 'context/appContext';

export default function FollowerList({ 
    className, setSearchValue, 
    isMobile, searchValue, 
    followersToShow, fetchMoreData, 
    hasMore, setClientModal, 
    setFollowerProfile, value, 
    followerProfile, setHasMore
 }) {
    const {unreadMsgCount, user:currentUser} = useAppContext();
    let followersList = followersToShow;
    const [followerListActive, setfollowerListActive] = useState(false)
    if (isMobile && followersToShow?.length) {
        const filteredFollowers = followersToShow.filter((item) => item.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
        if (filteredFollowers.length > 0) {
            followersList = filteredFollowers
        } else {
            return <p style={{ color: '#51635D', fontSize: '14px', paddingLeft: '25px' }}>No Results Found</p>
        }
    }
    !isMobile && setHasMore(false);
    return (
        <div id='scrollableDiv'>
            <div className={`infinite-div ${className}`}>
                <InfiniteScroll
                    dataLength={followersToShow?.length}
                    next={fetchMoreData}
                    pullDownToRefreshThreshold={50}
                    hasMore={hasMore}
                    loader={!isMobile && !value && <p style={{ textAlign: "center", marginTop: '5px', marginBottom: '5px' }}>Loading...</p>}
                    scrollableTarget={!isMobile ? "scrollableDiv" : ''}
                >
                    {followersList?.map((user) => {
                        const roomName = `nutritt_qa_${user?.userid}_${currentUser?.id}`;
                        const filteredNotification = unreadMsgCount.filter((item) => item.conversationUniqueName == roomName);
                        const unreadCount = filteredNotification[0]?.conversationUnreadMsg;
                        return (
                        <div className={`card ${user?.userid === followerProfile?.userid ? 'active' : ''}`} onClick={(e) => {
                            e.preventDefault()
                            setClientModal(true);
                            setSearchValue('')
                            setFollowerProfile(user);
                            setfollowerListActive(!followerListActive)
                        }}>
                            <div className='img-name'>
                                <div className='circle'>
                                    <Circle
                                        percent={user?.percentCalories}
                                        strokeWidth="6"
                                        strokeColor="#52C41A"
                                        trailWidth="6"
                                        trailColor="#F6F5F8"
                                        width='64px'
                                        height='64px'
                                    />
                                    <span>
                                        <img src={user?.img || '/icons/Avatar.svg'} fallbackSrc='/icons/Avatar.svg' />
                                    </span>
                                </div>
                                <p className='follower-name' title={user?.name}>{user?.name}</p>
                                {unreadCount > 0 && isMobile &&
                                <div className='relative'>
                                    <span className='unseenMsg'>{unreadCount}</span>
                                    <img src="/icons/sms.svg" alt="chat icon" />
                                </div>
                                }
                                <div className='star-icons'>
                                    <img src={user?.isFavorite ? '/icons/colord-star.svg' : '/icons/star.svg'} />
                                </div>
                            </div>
                        </div>
                    )})}
                </InfiniteScroll>
            </div>
        </div>
    )
}