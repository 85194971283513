import React, { useEffect, useState,useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import DropDown from 'components/elements/drop-down/Dropdown';
import Pagination from 'components/elements/pagination/Pagination';
import { useRequest } from 'hooks/useRequest';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import RegularInput from 'components/Input/RegularInput';
import { useDebounce } from 'use-debounce';
import { displayDateTime } from 'utils';
import { useAppContext } from 'context/appContext';
import { CircularProgress } from '@material-ui/core';
import PayoutDetails from 'pages/KitchenMobile/views/PayoutDetails';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useKitchenContext } from 'context/kitchenContext';

const UpcomingPayoutsStyle = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
  background: ${({ theme }) => theme?.colors?.white};
  .title_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 21px;
    .input-wrapper{
      input{
        height: 40px;
      }
    }
  }
  .table_title {
    padding-top: 19px;
    padding-left: 20px;
    padding-bottom: 21px;
    padding-right: 25px;
    font-size: ${({ theme }) => theme?.typography?.fontBase};
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-weight: 600;
    line-height: 24px;
  }
  .table_header {
    display: flex;
    padding-left: 44px;
    background-color: ${({ theme }) => theme?.colors?.grayLightest};
    padding-left: 28px;
    padding-right: 28px;
    .table_head {
      width: 25%;
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      color: ${({ theme }) => theme?.colors?.grayDarker};
      font-weight: 600;
      line-height: 20px;
      padding: 14px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      position: relative;
      span{
        flex: 1;
      }
      .select-wrapper{
        width: auto;
        margin-right: 0;
        .options-dropdown{
            top: 100%;
        }
        .selected-img{
            display: block;
        }
      }
    }
  }
  .table_body{
    height: calc(100vh - 501px);
    overflow: auto;
    position: relative;
    .table_row{
        padding-left: 28px;
        padding-right: 28px;
        display: flex;
        border-top: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
        .table_data{
            padding: 20px 16px;
            color: ${({ theme }) => theme?.colors?.primaryDark};
            font-size: ${({ theme }) => theme?.typography?.fontRegular};
            font-weight: 400;
            line-height: 20px;
            width: 25%;
        }
        &:last-child{
            border-bottom: 0px;
        }
    }
    .order-not-found-wrapper {
      overflow: hidden;
      height: 100%;
      display:flex;
      font-weight: 600; 
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Progress = styled.div`
  flex: 1;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MobileUpcomingPayoutsStyle = styled.div`
.earned-balance{
  padding: 16px;
  text-align: center;
  background: white;
  .balance_container{
      border-radius: 6px;
      background: ${({ theme }) => theme?.colors?.grayLightest};
      color: ${({ theme }) => theme?.colors?.primaryDefault};
      font-family: Gilroy;
      font-style: normal;
      height: 73px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .main-text{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
      }  
      .amount{
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
      }      
  }
}
.card-div{
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
  .order-not-found-wrapper {
    overflow: hidden;
    height: 100vh;
    display:flex;
    font-weight: 600; 
    margin-top: 10px;
    justify-content: center;
  }
  ::-webkit-scrollbar-thumb{
    background-color: transparent; !important
  }
}
.infinite-scroll-component {
  ::-webkit-scrollbar-thumb{
    background-color: transparent; !important
  }
}
.space_between{
  display: flex;
  justify-Content: space-between;
  align-items: center;
}
`

export const UpcomingPayouts = ({ setTotalEarned, setCurrentBalance, searchTerm, setSearchTerm, currentBalance, pageAnimation }) => {
  const [getUpcomingPayouts, {isLoading}] = useRequest();
  const { upcomingPayouts, setUpcomingPayouts } = useKitchenContext();
  const [payoutsPageNumber, setPayoutsPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  const [totalPayoutCount, setTotalPayoutCount] = useState();
  const [debounceSearchTerm] = useDebounce(searchTerm, 700);
  const [hasMore, setHasMore] = useState(true);
  const [heightOrders, setHeightOrders] = useState();
  const [isDataGenerating, setIsDataGenerating] = useState(true);
  const defaultOptions = [
    { value: 'asc', label: 'Sort Ascending', imgSrc: '/icons/arrow-up-black.svg' },
    { value: 'desc', label: 'Sort Descending', imgSrc: '/icons/arrow-down-black.svg' },
  ];
  const icon = "/icons/Right-Icon.svg";
  const itemsPerPage = 8;
  const startItem = (payoutsPageNumber - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalPayoutCount);
  const [sortBy, setSortBy] = useState('');
  const [sort, setSort] = useState('');
  const { isSuper, isDesktop, isWebView, isPlatformIOS } = useAppContext();
  const imitateProviderStorage = sessionStorage.getItem("imitateProvider");
  const numberFromApi = parseFloat(currentBalance);
  let formattedNumber

  if (!isNaN(numberFromApi)) {
    formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberFromApi);
  
    console.log(formattedNumber);
  } else {
    console.error('Invalid number format');
  }
    
  const imitateProviderId = useMemo(() => {
    const parsedId = JSON.parse(imitateProviderStorage)?.id;
    return parsedId
  }, [imitateProviderStorage]);
  const fetchUpcomingPayouts = async (pageNumber, searchTerm) => {
    setIsDataGenerating(true);
    const currentPage = 1;
    await getUpcomingPayouts({path: `financials/upcoming-disbursement?limit=${isDesktop ? '8' : '100'}${sortBy && sort ? `&${sortBy}=${sort}` : ''}&isDesktop=${isDesktop}&page=${pageNumber}${searchTerm ? `&orderNumber=${searchTerm}` : ''}${isSuper?`&providerId=${imitateProviderId}`:''}`, method: "get"})
    .then((data) => {
        setUpcomingPayouts(data?.upcomingDisbursements);
        console.log(data?.upcomingDisbursements,'data?.upcomingDisbursements')
        setIsDataGenerating(false);
        setTotalPageCount(data?.pagination?.totalPages);
        setTotalPayoutCount(data?.pagination?.totalRecords);
        setCurrentBalance(data?.currentBalance);
        setTotalEarned(data?.totalEarnings);
        if (currentPage === data?.pagination?.totalPages) {
          setHasMore(false);
          return;
        } else {
          setHasMore(true);
        }   
    })
    .catch((err) => {
        setUpcomingPayouts([]);
        setTotalPageCount(0);
        setTotalPayoutCount(0);
        console.log('err', err);
    })
  }
  const fetchMore = async () => {
    const currentPage = payoutsPageNumber + 1;
    if (currentPage > totalPageCount) {
      setHasMore(false);
      return;
    }
    try {
      const data = await getUpcomingPayouts({
        path: `financials/upcoming-disbursement?limit=${isDesktop ? '8' : '100'}&page=${currentPage}&isDesktop=${isDesktop}${searchTerm ? `&orderNumber=${searchTerm}` : ''}${isSuper ? `&providerId=${imitateProviderId}` : ''}`,
        method: 'get',
      });
      setUpcomingPayouts([...upcomingPayouts, ...data?.upcomingDisbursements]);
      setPayoutsPageNumber(currentPage);
    } catch (err) {
      setUpcomingPayouts([]);
      setTotalPageCount(0);
      setTotalPayoutCount(0);
      console.error('Error fetching more data:', err);
    }
  };
  
  useEffect(() => {
    if(isDesktop){
      fetchUpcomingPayouts(payoutsPageNumber, debounceSearchTerm);
    }
  }, [debounceSearchTerm, payoutsPageNumber]);

  useEffect(() => {
    if(!isDesktop && !pageAnimation){
      fetchUpcomingPayouts(payoutsPageNumber, debounceSearchTerm);
    }
  },[debounceSearchTerm, pageAnimation])

  useEffect(() => {
    if(sortBy && sort && isDesktop){
      fetchUpcomingPayouts(payoutsPageNumber, debounceSearchTerm);
    }
  }, [sortBy, sort])

  const handlePageChange = (event, page) => {
    setPayoutsPageNumber(page);
  };

  const handleSearchChange = (e) => {
    const query = e?.target?.value;
    setSearchTerm(query);
  };

  useEffect(() => {
    isWebView && isPlatformIOS() ? setHeightOrders(window.innerHeight - 360) : setHeightOrders(window.innerHeight - 270);
  }, [])

  return (
    isDesktop ? 
    (
    <UpcomingPayoutsStyle theme={theme}>
      <div className='title_flex'>
      <div className="table_title">Upcoming</div>
      <RegularInput className="search-input" width="270px" prefix={<img src='/icons/search-input2.svg' alt=" Search Icon" />} placeholder="Search for orders" onChange={handleSearchChange}/>
      </div>
      <div className="table_header">
        <div className="table_head"> <span>Created date</span> <DropDown updateValue={setSort} onChange={() => setSortBy('createdAt')} icon={icon} option={defaultOptions}/> </div>
        <div className="table_head"> <span>Period Start</span> <DropDown updateValue={setSort} onChange={() => setSortBy('periodStart')} icon={icon} option={defaultOptions}/> </div>
        <div className="table_head"> <span>Period End</span> <DropDown updateValue={setSort} onChange={() => setSortBy('periodEnd')} icon={icon} option={defaultOptions}/> </div>
        <div className="table_head"> <span>Amount</span></div>
      </div>
      <div className='table_body'>
      {isLoading & upcomingPayouts === undefined?
        <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> 
              : (
                upcomingPayouts?.length > 0 ? upcomingPayouts?.map((payout) => {
            const { total, createdAt, periodEnd, periodStart } = payout;
            return (
                <div className='table_row'>
                    <div className='table_data'>
                         {displayDateTime(createdAt)}
                    </div>
                    <div className='table_data'>
                         {displayDateTime(periodStart)}
                    </div>
                    <div className='table_data'>
                         {displayDateTime(periodEnd)}
                    </div>
                    <div className='table_data'>
                        ${total?.toFixed(2)}
                    </div>
                </div>
            )
        }) : <div className='order-not-found-wrapper'>Upcoming payout not found</div>)}
        {debounceSearchTerm && upcomingPayouts.length <= 0 && <div className='table_row'><p className='table_data' style={{width: '100%', textAlign: 'center'}}>No results found</p></div>}
      </div>
      <Pagination handlePageChange={handlePageChange} totalPageCount={totalPageCount} totalCount={totalPayoutCount} startItem={startItem} endItem={endItem}/>
    </UpcomingPayoutsStyle>
      )
    :
    (
      <MobileUpcomingPayoutsStyle theme={theme}>
      <div className='earned-balance'>
            <div className='balance_container'>
            {isDataGenerating ? <div style={{alignSelf: "center"}}><Skeleton variant="text" width={150} height={20} /> </div> : <span className='main-text'>Current balance</span>}
            {isDataGenerating ? <div style={{alignSelf: "center"}}><Skeleton variant="text" width={150} height={30} /> </div> : <span className='amount'>${formattedNumber}</span>}
            </div>
      </div>
      <div className='card-div' id='scrollableDiv'>
        {isDataGenerating ? (
          <>
            {Array.from({ length: 10 }, (_, index) => index + 1)?.map((item) => (
              <React.Fragment key={item}>
                <Stack style={{ padding: '16px', background:"#F9F9F9" }}>
                  <div className='space_between' style={{marginBottom: "16px"}}>
                    <Skeleton variant="text" width={231} height={20} />
                  </div>
                  <div className='space_between'>
                  <Skeleton variant="text" width={90} height={14} />
                  <Skeleton variant="text" width={90} height={14} />
                  </div>
                  <div className='space_between' style={{borderBottom:"1px solid #E9EAEC", paddingBottom: "10px"}}>
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={150} height={20} />
                  </div>
                  <div className='space_between' style={{marginTop: "10px"}}>
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={90} height={20} />
                  </div>
                  <div className='space_between' >
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={90} height={20} />
                  </div>
                </Stack>
                <Skeleton variant="rectangle" height={10} />
              </React.Fragment>
            ))}
          </>
        ) : 
          (
            upcomingPayouts?.length > 0 ? (
              <InfiniteScroll
                dataLength={upcomingPayouts.length}
                next={fetchMore}
                hasMore={hasMore}
                height={heightOrders}
                loader={
                  <>
                  <Stack style={{ padding: '16px' }}>
                  <div className='space_between' style={{marginBottom: "16px"}}>
                    <Skeleton variant="text" width={231} height={20} />
                  </div>
                  <div className='space_between'>
                  <Skeleton variant="text" width={90} height={14} />
                  <Skeleton variant="text" width={90} height={14} />
                  </div>
                  <div className='space_between' style={{borderBottom:"1px solid #E9EAEC", paddingBottom: "10px"}}>
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={150} height={20} />
                  </div>
                  <div className='space_between' style={{marginTop: "10px"}}>
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={90} height={20} />
                  </div>
                  <div className='space_between' >
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={90} height={20} />
                  </div>
                </Stack>
                <Skeleton variant="rectangle" height={1} />
                </>
                }
              >
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", background: "#F9F9F9" }}>
            {upcomingPayouts.map((payout, index) => (
              <div key={payout.id} style={{ marginTop: index === 0 ? "10px" : "0" }}>
                <PayoutDetails Details={payout} upcomingPayout={true} />
              </div>
            ))}
          </div>
              </InfiniteScroll>
            ) : (
              <div className='order-not-found-wrapper'>No payment found</div>
            )
          )}
      </div>
      </MobileUpcomingPayoutsStyle>
    )    

  );
};
