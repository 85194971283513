export const supportsDeliveryAndPickup = (provider) => {
  const dayWithDelivey = [];
  const pickupDays = [];
  for (let dayKey in provider?.schedule) {
    const deliveryAvailable = provider?.schedule[dayKey].delivery.hasOwnProperty('isDeliveryOn')
      ? provider?.schedule[dayKey].delivery.isDeliveryOn
      : provider?.schedule[dayKey].delivery.start;
    const pickupAvailable = provider?.schedule[dayKey].pickup.hasOwnProperty('isPickupOn')
      ? provider?.schedule[dayKey].pickup.isPickupOn
      : provider?.schedule[dayKey].pickup.start;
    dayWithDelivey.push(deliveryAvailable);
    pickupDays.push(pickupAvailable);
  }
  const isOnForDelivery = dayWithDelivey.some((item) => item);
  const isAnyAddressForPickup = !!(
    provider?.address &&
    provider?.city &&
    provider?.state &&
    provider?.zip
  );
  const isOnForPickup = pickupDays.some((item) => item) && isAnyAddressForPickup;

  return { isOnForDelivery, isOnForPickup };
};

export const pointInPolygon=(point, polygon) =>{
  const x = point[0];
  const y = point[1];
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i][0];
      const yi = polygon[i][1];
      const xj = polygon[j][0];
      const yj = polygon[j][1];

      const intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

      if (intersect) inside = !inside;
  }

  return inside;
};
