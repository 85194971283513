import styled from 'styled-components';
import NutrittSteps from 'components/NutrittSteps';
import { Link } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { useAppContext } from 'context/appContext';

const flexCenter = `
  display: flex;
  flext-direction: row;
  justify-content: center;
  align-items: center;
`;

const CartWrapperStyle = styled.div`
  padding: 24px 0;
  .container {
    padding: 0;
    justify-content: center;
    align-items: center;
    // ${flexCenter};
  }
`;

const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function CheckoutHeader({ path, title, description }) {
  const { isGuest, isVisitor } = useAppContext();

  return (
    <CartWrapperStyle>
      <BackButton perviousLink={path} />
      <div className="container">
        <NutrittSteps
          title={title}
          description={description}
          activeStep={3}
          steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
          stepNumber={1}
          stepTitle="Let's specify your plan"
          isFooterHidden={true}
        />
      </div>
    </CartWrapperStyle>
  );
}
