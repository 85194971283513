import styled from 'styled-components';

const MealCardContentStyle = styled.div`
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fresh-card-content {
    display: flex;
  }

  .fresh-card-container {
    margin-right: 4px;
    padding-right: 9px;
    color: #747c7a;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &:not(:last-child)::after {
      content: '';
      width: 1px;
      height: 14px;
      background-color: #E0E4E8;
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
    }
    :last-child {
      padding-right: 0;
      border-right: 0 !important;
    }
    span {
      :first-child {
        margin-bottom: 3px;
      }
      :last-child {
        font-size: 10px;
        text-transform: capitalize;
      }
    }
  }

  @media (min-width: 992px) {
    .fresh-card-content {
      width: 100%;
      height: auto;
      border: 1px solid #f6f5f8;
      border-radius: 5px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .fresh-card-container {
        margin: 3px 0 0;
        padding: 0;

        span:first-child {
          font-size: 10px;
          font-family: 'Roboto';
          line-height: 12px;
        }

        span:last-child {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
`;

export default function MealCardContent({ calories = 0, carbs = 0, fat = 0, protein = 0 }) {
  const formatNumber = (inputNumber) => {
    if (Number.isInteger(inputNumber)) {
      return inputNumber;
    } else if (typeof inputNumber === 'number') {
      return inputNumber.toFixed(2);
    } else {
      return NaN;
    }
  }
  return (
    <MealCardContentStyle>
      <div className="fresh-card-content">
        <div className="fresh-card-container">
          <span>{formatNumber(calories)}</span>
          <span>cals</span>
        </div>
        <div className="fresh-card-container">
          <span>{formatNumber(carbs)}g</span>
          <span>carbs</span>
        </div>
        <div className="fresh-card-container">
          <span>{formatNumber(fat)}g</span>
          <span>fat</span>
        </div>
        <div className="fresh-card-container">
          <span>{formatNumber(protein)}g</span>
          <span>protein</span>
        </div>
      </div>
      {/* <div className="oz-number">5 oz</div> */}
    </MealCardContentStyle>
  );
}
