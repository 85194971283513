import axios from 'axios';
import { useState } from 'react';
// const baseUrl = `${location.origin}/v1/`;
const baseUrl = process.env.REACT_APP_SERVER_URL;

/*
 * @typedef {Object} ReturnObj
 * @property {boolean} isLoading - The http request is loading
 * @property {number} y - The Y Coordinate
 */

/**
 * @param  {string=} path
 * @param  {("get"|"post")} method - default="get"
 * @example const [runRequest] = useRequest("/user","get")
 * @returns {Array<runRequest,{isLoading:Boolean,state, setState, error:String, setError}>}
 */

export const useRequest = (path, method = 'get', rawResponse = false) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(null);
  const [error, setError] = useState('');
  /**
   * @param {Object} runRequestObj
   * @param {object} runRequestObj.body - post request body
   * @param {string} runRequestObj.path
   * @param {("get"|"post")} runRequestObj.method
   * @example runRequest({body: { name: "John Doe" }, method: "post", path: "/update" })
   * @returns {object} - result data object
   */

  const runRequest = async ({ body, path: requestPath, method: requestMethod, params } = {}) => {
    setError('');
    setState(null);
    setIsLoading(true);
    const theMethod = requestMethod || method;
    const url = `${baseUrl}${requestPath || path}`;
    const methodAndUrlLog = `${theMethod.toUpperCase()} - ${url}`;
    return new Promise(async (resolve, reject) => {
      try {
        const { token } = JSON.parse(localStorage.getItem('user')) || {
          token: '',
        };
        const res = await axios({
          method: theMethod,
          url,
          data: body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          params
        });
        // // route probably doesn't exists
        // // console.log(`res`, res);
        // if (res.headers["content-type"].startsWith("text/html")) {
        //   const resError = `${methodAndUrlLog} is not found`;
        //   console.log(`resError:`, resError);
        //   setError(resError);
        //   reject(resError);
        //   setIsLoading(false);
        //   return;
        // }
        setState(res.data);
        resolve(res.data);
      } catch (error) {
        const { response, message } = error;
        console.log(`error.response`, response);

        let resError;

        //  if (response?.status === 500)
        //   resError = response?.statusText;
        if (typeof response?.data === 'object')
          resError = response.data.validation || response.data.error || response.data.message;
        // resError = JSON.stringify(
        //   response.data.error || response.data.message
        // );
        else resError = message;
        // console.log(`resError:`, resError);

        setError(resError);
        reject(rawResponse ? response : resError);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    });
  };
  return [runRequest, { isLoading, state, setState, error, setError }];
};

