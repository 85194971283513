import React, { useState } from 'react'
import styled from 'styled-components'
import { MdEdit } from 'react-icons/md';
import { theme } from 'shared/theme/theme';
import { useAppContext } from 'context/appContext';
import { useKitchenContext } from 'context/kitchenContext';
import { ASSET_TYPE } from '../../../../constants'
import ImageContainer from "components/elements/imageUploader/ImageUpload";


const UserProfileCardStyle = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    padding-bottom: 28px;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.borderLight};
    .icon{
        width: 80px;
        height: 80px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
        .edit{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme?.colors?.grayLighter};
            position: absolute;
            right: -4px;
            cursor: pointer;
            bottom: -4px;
            .i{
                color: ${({ theme }) => theme?.colors?.grayDark};
            }
        }
    }
    .content{
        .title{
            font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
        }
        .desc{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: ${({ theme }) => theme?.colors?.grayDarker};
        }
    }
`

export const UserProfileCard = ({ setProviderImage, providerImage, handleSave ,name}) => {
    const { provider } = useAppContext();
    const [image, setImage] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const { imitateProviderCredentials } = useKitchenContext();

    return (
        <UserProfileCardStyle theme={theme}>
            <div className='icon'>
            <img src={providerImage || "/images/avatar.png"} onError={(e) => { e.target.src = '/images/avatar.png' }}/>
                <span className='edit' onClick={() => setIsVisible(true)}>
                    <MdEdit className='i' />
                </span>
            </div>
            <div className='content'>
                <h5 className='title'>{name?.replace(/(.{30})..+/, "$1")+'...'}</h5>
                <p className='desc'>Admin</p>
                <p className='desc'>{provider?.street} {provider?.city} {provider?.state} {provider?.zip}</p>
            </div>
            {isVisible && (
                  <div className="modal-container">
                    <ImageContainer
                      image={image}
                      setImage={setImage}
                      setIsVisible={setIsVisible}
                      setNewImgUrl={(image) => {
                        setProviderImage(image);
                      }}
                      uploadContainer={"true"}
                      assetType={ASSET_TYPE?.kitchenProfile}
                      id={imitateProviderCredentials?.providerId}
                      circle={true}
                    />
                  </div>
                )}
        </UserProfileCardStyle>
    )
}