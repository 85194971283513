import styled from 'styled-components';
import moment from 'moment';

const OrderCardStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 10px;
    &.active{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 4px;
            background-color: #52C41A;
            height: calc(100% + 2px);
            top: -1px;
            left: -1px;
            border-radius: 6px 0 0 6px;
        }
        &.completed{
            &::before{
                background-color: #CBEDBA;
            }
        }
        .order_number, .order_amount{
            font-weight: 700 !important;
            color: #51635D !important;
        }
        .order_body{
            .order_details{
                p{
                    &.value{
                        color: #51635D !important;
                    }
                }
            }
        }
    }
    .order_header{
        padding: 14px 14px 10px 16px;
        border-bottom: 1px solid #E0E3E0;
        display: flex;
        justify-content: space-between;
        .order_number, .order_amount{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
        }
        .order_number{
            color: #52C41A;
        }
    }
    .order_body{
        padding: 14px 14px 14px 16px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        .order_details{
            display: flex;
            gap: 2px;
            align-items: flex-start;
            width: 50%;
            justify-content: flex-start;
            p{
                font-weight: 400;
                font-size: 11px;
                line-height: 12px;
                letter-spacing: 0.02em;
                color: #949D9B;
                &.value{
                    color: #51635D;
                    font-weight: 700;
                    word-break: break-word;
                }
            }
            &:nth-child(even){
                justify-content: flex-end;
            }
        }
    }
    &.seen{
        .order_number, .order_amount{
            font-weight: 400;
            color: #949D9B;
        }
        .order_body{
            .order_details{
                p{
                    &.value{
                        color: #949D9B;
                    }
                }
            }
        }
    }
    &.refused{
        position: relative;
        .order_header{
            .order_number, .order_amount{
                color: #51635D;
                font-weight: 700;
            }
        }
        &::before{
            content: '';
            position: absolute;
            width: 4px;
            height: calc(100% + 2px);
            top: -1px;
            left: -1px;
            border-radius: 6px 0 0 6px;
            background-color: #E0E3E0;
        }
        &.seen{
            &::before{
                display: none;
            }
            .order_header{
                .order_number, .order_amount{
                    color: #949D9B;
                    font-weight: 400;
                }
            }
        }
        &.active{
            &::before{
                background-color: #AFB5B4 !important;
                display: block;
            }
            .order_header{
                .order_number{
                    color: #51635D;
                }
            }
        }
    }
    @media (max-width: 991px){
        .order_body{
            .order_details{
                width: 100%;
                &:nth-child(even){
                    justify-content: flex-start;
                }
                &:nth-child(2){
                    order: 3;
                }
                &:nth-child(3){
                    order: 2;
                }
                &:nth-child(4){
                    order: 4;
                }
            }
        }
    }
`

export default function OrderCard({onClick, active = false, order, activeTab, isSeen, providers}){
    const {friendlyOrderNum, user, userData,orderData, orderedAt: dateOrdered, name: providerName} = order || {};
    const {orderedAt, name, userTotalCharge, selectedDate, scheduledDate} = providers || {};
    const userSplit=user?.split('') || userData?.name?.split('');

    const displayDateTime = (date) => {
        const dateSplit = date?.split("T");
        const toBeCompletedDate = dateSplit && dateSplit[0];
        return moment(toBeCompletedDate).format('M/D/YYYY')
    };

    return (
        <OrderCardStyle onClick={onClick} className={`${active ? 'active' : ''} ${activeTab ? activeTab : ''} ${isSeen ? 'seen': ''}`}>
            <div className='order_header'>
                <h2 className='order_number'>{friendlyOrderNum || orderData?.friendlyOrderNum}</h2>
                <p className='order_amount'>${providers?.totalUserPayout || userTotalCharge}</p>
            </div>
            <div className='order_body'>
                <div className='order_details'>
                    <p className='title'>Provider:</p>
                    <p className='value'>{providers?.Provider?.name || name}</p>
                </div>
                <div className='order_details'>
                    <p className='title'>Ordered at:</p>
                    <p className='value'>{displayDateTime(orderedAt || dateOrdered)}</p>
                </div>
                <div className='order_details'>
                    <p className='title'>Customer:</p>
                    <p className='value'>{userSplit?.[0]?.toUpperCase()+userSplit?.slice(1,userSplit?.length)?.join("")}</p>
                </div>
                <div className='order_details'>
                    <p className='title'>To be completed:</p>
                    <p className='value'>{displayDateTime(selectedDate || scheduledDate)}</p>
                </div>
            </div>
        </OrderCardStyle>
    )
}