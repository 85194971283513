import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useState, useEffect ,useMemo} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';
import MobileHeader from 'components/elements/MobileHeader';
import classNames from 'classnames';
import MultiScreenStyle from './MultiScreenStyle';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import moment from 'moment';
import { formatDate } from 'utils/formatDate';

const UserAgeStyle = styled.div`
  background-color: #fff;
  .subtitle {
    margin: 0 auto;
  }
  .form_group {
    position: relative;
    .form_control {
      width: 100%;
      height: 44px;
      background: #ffffff;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      padding: 0 16px;
      font-weight: 400;
      font-size: 14px !important;
      font-family: inherit;
      line-height: 44px;
      color: #51635d;
      text-align: start;
      -webkit-appearance: none;
    }
    input {
      &:hover,:focus {
        border-color: #00092D !important;
      }
    }
    label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #949d9b;
      .icon {
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
  .scroll-container {
    position: relative;
    padding-top: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .scroll_wrapper {
    overflow: hidden;
    width: calc(100% + 12px);
    max-height: 194px;
    margin: 0 -14px;
    padding: 17px 0;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    .picker-window {
      position: absolute;
      height: 32px;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 96%;
      margin: 3px 2% 0;
      background-color: #f7f5f8;
      border-radius: 7px;
      pointer-events: none;
    }
    .scroller {
      overflow: hidden;
      width: 30%;
      .swiper-slide {
        height: 24px;
        text-align: center;
        font-size: 16px;
        line-height: 16px;
        background: rgba(0, 0, 0, 0);
        color: #fff;
      }
      .swiper-slide.swiper-slide-active {
        margin: 3px 0;
        color: #000000;
        transform: scale(1.3);
      }
      .swiper-slide-prev {
        color: #a8a6a9;
        transform: perspective(200px) rotateX(20deg) scale(1.2);
      }
      .swiper-slide-next {
        color: #a8a6a9;
        transform: perspective(200px) rotateX(-20deg) scale(1.2);
      }
      .swiper-slide.firstVisibleSlide + .swiper-slide {
        color: #b5b3b6;
        transform: perspective(200px) rotateX(40deg) scale(1.1);
      }
      .swiper-slide-next + .swiper-slide {
        color: #b5b3b6;
        transform: perspective(200px) rotateX(-40deg) scale(1.1);
      }
      .swiper.swiper-vertical .swiper-wrapper .swiper-slide-next + .swiper-slide + .swiper-slide {
        color: #d7d7d9;
        transform: perspective(200px) rotateX(-60deg);
      }
      &:nth-child(2) {
        width: 40%;
        .swiper {
          .swiper-wrapper {
            align-items: center;
          }
          .swiper-slide {
            padding-left: 45px;
            justify-content: flex-start;
          }
          .swiper-slide.swiper-slide-active {
            transform: scale(1.3) translateX(-12px);
          }
          .swiper-slide-prev {
            transform: perspective(200px) rotateX(20deg) scale(1.2) translateX(-10px);
          }
          .swiper-slide-next {
            transform: perspective(200px) rotateX(-20deg) scale(1.2) translateX(-8px);
          }
          .swiper-slide.firstVisibleSlide + .swiper-slide {
            transform: perspective(200px) rotateX(40deg) scale(1.1) translateX(-6px);
          }
          .swiper-slide-next + .swiper-slide {
            transform: perspective(200px) rotateX(-40deg) scale(1.1) translateX(-6px);
          }
        }
      }
      &:last-child {
        .swiper {
          .swiper-slide {
            padding-right: 30px;
            justify-content: center;
          }
          .swiper-slide.swiper-slide-active {
            transform: scale(1.3) translateX(8px);
          }
          .swiper-slide-prev {
            transform: perspective(200px) rotateX(20deg) scale(1.2) translateX(6px);
          }
          .swiper-slide-next {
            transform: perspective(200px) rotateX(-20deg) scale(1.2) translateX(6px);
          }
          .swiper-slide.firstVisibleSlide + .swiper-slide {
            transform: perspective(200px) rotateX(40deg) scale(1.1) translateX(4px);
          }
          .swiper-slide-next + .swiper-slide {
            transform: perspective(200px) rotateX(-40deg) scale(1.1) translateX(4px);
          }
        }
      }
      .swiper.swiper-vertical .swiper-wrapper .swiper-slide:nth-child(1 of div.swiper-slide-visible),
      .swiper.swiper-vertical .swiper-wrapper .swiper-slide.firstVisibleSlide {
        color: #d7d7d9;
        transform: perspective(200px) rotateX(60deg);
      }
    }
  }
  .age_restriction {
    position: absolute;
    top: 20px;
    left: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #eb3223;
  }
`;

export default function BirthDate({ activeModalStack, setActiveModalStack, isHealthGoalFlow, nextScreen, modal, totalPagesCount }) {
  const history = useHistory();
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
    }, 0);
  };
  const years = useMemo(() => {
    return Array.from({ length: 64 }, (_, i) => i + new Date().getFullYear() - 63);
  }, []);
  const month = (f) =>
    Array.from(Array(12), (e, i) => new Date(25e8 * ++i).toLocaleString('en-US', { month: f }));
  const [months, setMonths] = useState([
    ...month`long`,
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    ...month`long`,
  ]);
  const [totalDays, setTotalDays] = useState(Array.from({ length: 31 }, (_, i) => i + 1));
  const [days, setDays] = useState(totalDays);
  const [monthsSlider, setMonthsSlider] = useState(null);
  const [daysSlider, setDaysSlider] = useState(null);
  const [yearsSlider, setYearsSlider] = useState(null);
  const [age, setAge] = useState();
  const [generateIndex, setGenerateIndex] = useState({ month: '', day: '', year: '' });
  const [isSwiper, setIsSwiper] = useState({ month: false, date: false, year: false });
  const { healthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const [buttonDisable, setButtonDisable] = useState(false);
  const parsedDate = formatDate(healthGoal?.birthday);
  const formattedDate = moment(parsedDate, 'MMMM D, YYYY');
  const userDob = {
    month: formattedDate.format('MMMM'),
    date: formattedDate.date(),
    year: formattedDate.year(),
  };
  const isDobAvailable = userDob?.month && userDob?.date && userDob?.year;
  const [dob, setDob] = useState(isDobAvailable ? userDob : { date: '', month: '', year: '' });
  const [copyDob, setCopyDob] = useState(isDobAvailable ? userDob : null);
  const isSaveDisabled = useMemo(() => {
    return userDob && Object.keys(userDob).every(item => {
      return dob[item] === userDob[item];
    });
  }, [userDob, dob]);

  const generateActiveIndex = (index, date, count) => {
    if (index == 0) {
      setGenerateIndex((prevState) => ({ ...prevState, [date]: count }));
    } else if (index == 1) {
      setGenerateIndex((prevState) => ({ ...prevState, [date]: count + 1 }));
    } else if (index == 2) {
      setGenerateIndex((prevState) => ({ ...prevState, [date]: count + 2 }));
    } else {
      setGenerateIndex((prevState) => ({ ...prevState, [date]: index - 3 }));
    }
  };

  const handleMonthChange = (swiper) => {
    setDob((prev) => ({
      ...prev,
      month: months[swiper?.realIndex],
    }));
    generateActiveIndex(swiper?.realIndex, 'month', months.length - 3);

    if (
      swiper?.realIndex == 3 ||
      swiper?.realIndex == 5 ||
      swiper?.realIndex == 8 ||
      swiper?.realIndex == 10
    ) {
      setDays([...days.slice(0, -1)]);
      return;
    }

    if (swiper?.realIndex == 1) {
      setDays([...days.slice(0, -2)]);
      return;
    }
    setDays(totalDays);
  };
  const handleDayChange = (swiper) => {
    setDob((prev) => ({
      ...prev,
      date: Number(days[swiper?.realIndex]),
    }));
    generateActiveIndex(swiper?.realIndex, 'day', days.length - 3);
  };
  const handleYearChange = (swiper) => {
    if (!swiper?.destroyed) {
      setDob((prev) => ({
        ...prev,
        year: Number(years[swiper?.realIndex]),
      }));
    }
    generateActiveIndex(swiper?.realIndex, 'year', years.length - 3);
  };
  useEffect(() => {
    if (dob?.date || dob?.month || dob?.year) {
      setCopyDob(dob);
      calculateAge(dob);
    }
  }, [dob]);

  useEffect(() => {
    if(!parsedDate){
      setDob({ month: months[new Date().getMonth()], date: days[new Date().getDate() - 1], year: years[33] })
    }else{
      setDob(userDob);
      setCopyDob(userDob);
    }
  }, [])

  const calculateAge = (x) => {
    const today = new Date();
    let age = today.getFullYear() - x.year;
    const monthDiff = today.getMonth() - months.indexOf(x.month);
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < x.date)) {
      age--;
    }
    if(age < 18){
      setButtonDisable(true);
    }
    else{
      setButtonDisable(false);
    }
    setAge(String(age));
  };

  useEffect(() => {
    if (age && copyDob?.month && copyDob?.date && copyDob?.year) {
      const dateObj = new Date(`${copyDob.month} ${copyDob.date}, ${copyDob.year} 00:00:00 UTC`);
      setUpdatedHealthGoal((prev) => ({
        ...prev,
        age: age,
        birthday: dateObj?.toISOString(),
      }));
    }
  }, [age, copyDob]);

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
  };

  return (
    <>
      <motion.div
        initial={animation?.initial}
        animate={{ opacity: 1, x: 0 }}
        exit={animation?.exit}
        transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
      >
        <MultiScreenStyle>
          <UserAgeStyle>
            <MobileHeader
              text={'Birth date'}
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={
                !isHealthGoalFlow
                  ? () =>
                      setActiveModalStack((prev) => [
                        ...prev.filter((item) => item?.modal !== modal?.modal),
                      ])
                  : false
              }
            />
            <div className="containerr">
              <p className="subtitle">
                Age influences your nutritional needs, and <br /> we calculate them using your
                birthdate
              </p>
              <div className="form_group">
                <input
                  id="age"
                  type="button"
                  readOnly
                  value={
                    copyDob?.month || copyDob?.date || copyDob?.year
                      ? copyDob?.month + ' ' + copyDob?.date + ',' + ' ' + copyDob?.year
                      : ''
                  }
                  className={classNames('form_control', { focused: dob?.year })}
                />
              </div>
              <div className="scroll-container">
                {age < 18 && (
                  <p className="age_restriction">
                    Sorry! <br /> Age Restriction Applies
                  </p>
                )}
                <div className="scroll_wrapper" style={{ position: 'relative', zIndex: 9 }}>
                  <div className="picker-window" />
                  <div className="scroller">
                    <Swiper
                      initialSlide={
                        copyDob ? months.indexOf(copyDob?.month) : new Date().getMonth()
                      }
                      direction={'vertical'}
                      onSlideChange={handleMonthChange}
                      onTouchMove={() =>
                        setIsSwiper((prevState) => ({ ...prevState, month: true }))
                      }
                      loop={true}
                      spaceBetween={0}
                      slidesPerView={7}
                      centeredSlides={true}
                      onSwiper={setMonthsSlider}
                    >
                      {months?.map((item, index) => (
                        <SwiperSlide
                          className={classNames(`item`, {
                            firstVisibleSlide: index == generateIndex?.month,
                          })}
                          key={index}
                        >
                          {item}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    {isSwiper.month && (
                      <Swiper
                        initialSlide={
                          copyDob ? months.indexOf(copyDob?.month) + 2 : new Date().getMonth() + 3
                        }
                        thumbs={{ swiper: monthsSlider }}
                        modules={[Thumbs]}
                      />
                    )}
                  </div>
                  <div className="scroller">
                    <Swiper
                      initialSlide={
                        copyDob ? days.indexOf(copyDob?.date) : new Date().getDate() - 1
                      }
                      direction={'vertical'}
                      onSlideChange={handleDayChange}
                      onTouchMove={() => setIsSwiper((prevState) => ({ ...prevState, date: true }))}
                      loop={true}
                      spaceBetween={0}
                      slidesPerView={7}
                      centeredSlides={true}
                      onSwiper={setDaysSlider}
                    >
                      {days?.map((item, index) => (
                        <SwiperSlide
                          className={classNames('item', {
                            firstVisibleSlide: index == generateIndex?.day,
                          })}
                          key={index}
                        >
                          {item}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    {isSwiper.date && (
                      <Swiper
                        initialSlide={
                          copyDob ? days.indexOf(copyDob?.date) + 2 : new Date().getDate() - 3
                        }
                        thumbs={{ swiper: daysSlider }}
                        modules={[Thumbs]}
                      />
                    )}
                  </div>
                  <div className="scroller">
                    <Swiper
                      initialSlide={copyDob ? years.indexOf(copyDob?.year) : 33}
                      direction={'vertical'}
                      onSlideChange={handleYearChange}
                      onTouchMove={() => setIsSwiper((prevState) => ({ ...prevState, year: true }))}
                      loop={true}
                      spaceBetween={0}
                      slidesPerView={7}
                      centeredSlides={true}
                      onSwiper={setYearsSlider}
                    >
                      {years?.map((item, index) => (
                        <SwiperSlide
                          className={classNames('item', {
                            firstVisibleSlide: index == generateIndex?.year,
                          })}
                          key={index}
                        >
                          {item}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    {isSwiper.year && (
                      <Swiper
                        initialSlide={copyDob ? years.indexOf(copyDob?.year) + 4 : 34}
                        thumbs={{ swiper: yearsSlider }}
                        modules={[Thumbs]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <Button onBackClick={handleBack} disabled={!age || age < 18} onContinueClick={() => history.push('/self-onboarding/userGender')} /> */}
            <div className="button_holder">
              {isHealthGoalFlow ? (
                <Button
                  type={'button_combo'}
                  iconLeft={<ChevronLeft />}
                  iconRight={<ChevronRight />}
                  comboLeftTitle={'Back'}
                  comboLeftClick={handleBackClick}
                  comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}
                  comboRightTitle={'Continue'}
                />
              ) : (
                <Button
                  type={'mobile_save'}
                  title={'Save'}
                  onClick={handleSave}
                  isDisabled={isSaveDisabled || buttonDisable}
                />
              )}
            </div>
          </UserAgeStyle>
        </MultiScreenStyle>
      </motion.div>
    </>
  );
}

