import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const RegularInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ width }) => width};

  label {
    margin-bottom: 8px;
    color: #000000;
    font-size: 12px;
    font-family: ${() => theme.typography.fontOpenSans};
    font-weight: 600;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hint {
      color: rgba(0, 0, 0, 0.5);
      font-family: ${() => theme.typography.fontOpenSans};
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    img{
      cursor:pointer;
    }
    .prefix,
    .suffix {
      position: absolute;
      top: auto;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .prefix {
      left: 8px;
    }
    .suffix {
      right: 8px;
      z-index: 100;
    }
    &:has(> .prefix) input {
      padding-left: 36px;
    }
    &:has(+ .box .errorMessage) input {
      border-color: #f0373880;
      &:hover,
      &:focus {
        border-color: #f03738 !important;
      }
    }
    &:has(+ .box .warningMessage) input {
      border-color: #f3aa1c80;
      &:hover,
      &:focus {
        border-color: #f3aa1c !important;
      }
    }
    &:has(+ .box .successMessage) input {
      border-color: #c2c9d1;
      &:hover,
      &:focus {
        border-color: #3e66fb !important;
      }
    }
    &:has(+ .box .ErrorMessage) input {
      border: 1px solid #FEC8B4;
      color: #EB3223;
      background: #FEF1F0;
      height: 44px;
      border-radius: 6px;
      &:focus {
        border-color: #f03738 !important;
      }
    }
  }

  input {
    height: 32px;
    width: 100%;
    padding-left: 8px;
    background-color: #fff;
    color: #000000;
    border-radius: 6px;
    border: 1px solid #c2c9d1;
    font-size: 14px !important;
    font-family: ${() => theme.typography.fontOpenSans};
    font-weight: 400;
    line-height: 20px;
    max-width: ${({ width }) => width};
    &:hover {
      border-color: #3e66fb !important;
    }
    &:focus {
      border-color: #3e66fb !important;
      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    &[disabled],
    &[readonly] {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.75) 100%
      );
      background-color: #c2c9d1 !important;
      color: rgba(0, 0, 0, 0.5) !important;
      &:hover {
        border-color: #c2c9d1 !important;
        cursor: not-allowed;
      }
      &:focus {
        border-color: #c2c9d1 !important;
        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    &[readonly] {
      color: #000000 !important;
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
    &:has(~ .suffix) {
      padding-right: 36px;
    }
  }
`;

export const InputMessageWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  .img-wrapper {
    margin-right: 8px;
    display: flex;
    img {
      width: 16px;
      height: 16px;
    }
  }

  p {
    color: #9499A0;
    font-size: 12px;
    font-family: ${() => theme.typography.fontGilroy};
    font-weight: 400;
  }
`;
