import { createContext, useContext, useEffect, useState } from "react";
import { useAppContext } from "./appContext";
import { useLocation } from "react-router-dom";
import { useRequest } from "hooks/useRequest";
import axios from "axios";

const ChatContext = createContext();
let uniqueMessageSid = {};
function ChatContextProvider({ children }) {
    const { twilioClient, getAllTwilioConversations, setUnreadMsgCount, unreadMsgCount, chatData, setChatData, setChatsLoading, user, getAllUsersOfConversation, setGetAllUsersOfConversation, coachFcmToken, setIsTwilioConnectionError, setTwilioError,platform } = useAppContext();
    const [participantTyping, setParticipantTyping] = useState(null);
    const [activeConversation, setActiveConversation] = useState(null);
    const [readMessages, setReadMessages] = useState([]);
    const [addParticipantInRoom] = useRequest('chat/create-participant-in-room', 'post');
    const chatNotificationApi = process.env.REACT_APP_CHAT_PUSH_NOTIFICATION_API;
    const [reActivateConversation, setReActivateConversation] = useState(false);
    const currentUserId = user?.id;

    const { token } = JSON.parse(localStorage.getItem('user')) || {
        token: '',
    };

    function pushNotification(message, fcm_token, clientId){
        const maxLength = 3000;
        const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
        const senderName = user?.type?.includes('nutritionist') ? 
                       user?.nutritionistProfile?.name : 
                       user?.profile?.name;                           
        const sendMessage = message.length > maxLength ? `${message.slice(0, maxLength)}...` : message;
        axios.post(chatNotificationApi, {
            authorization: `Bearer ${token}`,
            env: process.env.REACT_APP_ENV,
            platform:platform,
            data: {
                to: fcm_token,
                notification: {
                  title: user?.type.includes("super") ? imitateNutritionist?.name : senderName,
                  body: sendMessage,
                  mutable_content: true,
                  sound: "Tri-tone"
                },
                data: {
                  senderId: user?.type.includes("super") ? imitateNutritionist?.userId : user?.id,
                  receiverId: clientId,
                  redirectUrl : user?.type?.includes('super')|| user?.type?.includes("nutritionist") ? `${window.origin}/shop/chat-with-coach?timeStamp=${new Date().getTime()}` : `${window.location.origin}/nutritionist/followers?userId=${user?.id}&timeStamp=${new Date().getTime()}`
                },

            }
        },
         {
        })
          .then((result) => {
            if (result) {
              console.log("result success", result);
            } else {
              console.log("result failed");
            }
          }).catch((e)=>{
            console.log(e);
          })
    };

    function updateMessageCount() {
        activeConversation != null && activeConversation?.getMessages()?.then((messages) => {
            var lastReadMessageIndex = messages.items.length > 0 ? messages.items.slice(-1)[0].index : 0;
            activeConversation.updateLastReadMessageIndex(lastReadMessageIndex);
            setUnreadMsgCount((prev) => [{
                    conversationUnreadMsg: 0,
                    conversationUniqueName: activeConversation.uniqueName,
                }])
        }).catch((error) => {
            console.log('error', error);
            setTwilioError(error.message);
            setReActivateConversation(!reActivateConversation);
        });
    }

    useEffect(() => {
        activeConversation != null && activeConversation?.getUnreadMessagesCount()?.then((unreadCount) => {
            setUnreadMsgCount((prev) => [
                ...prev.filter((conversation) => conversation.uniqueName == activeConversation.sid), {
                    conversationUnreadMsg: unreadCount,
                    conversationUniqueName: activeConversation.uniqueName,
                }
            ])
        }).catch((error) => {
            console.log('error', error);
            setTwilioError(error.message);
            setReActivateConversation(!reActivateConversation);
        });
    }, [chatData])

    const addConversationListener = (conversation, roomName, clientFcmToken, clientId) => {
        conversation?.getParticipants()?.then((participants) => {
            console.log("participants, fcmtoken, clientId", participants, clientFcmToken, clientId, coachFcmToken);
            participants.map((participant, index) => {
                setReadMessages((prev) => [
                    ...prev.filter((item) => item.identity != participant.identity),
                    {
                        identity: participant.identity,
                        lastReadMessageIndex: participant.lastReadMessageIndex
                    }
                ])
                participant?.getUser()?.then((user) => {
                    setGetAllUsersOfConversation((prev) => [
                        ...prev.filter((item) => item.identity != user.identity), 
                        {
                            identity: user.identity, 
                            isOnline: user.isOnline, 
                            isNotifiable: user.isNotifiable, 
                            isSubscribed: user.isSubscribed 
                        }
                    ]);

                    user.on("updated", (userUpdated) => {
                        console.log("userUpdated", userUpdated);
                        setGetAllUsersOfConversation((prev) => [
                            ...prev.filter((item) => item.identity != userUpdated.user.identity), 
                            {
                                identity: userUpdated.user.identity, 
                                isOnline: userUpdated.user.isOnline, 
                                isNotifiable: userUpdated.user.isNotifiable, 
                                isSubscribed: userUpdated.user.isSubscribed 
                            }
                        ]);
                    })
                }).catch((error) => {
                    console.log("error", error);
                    setTwilioError(error.message);
                })
            })
        }).catch((error) => {
            console.log("error", error);
            setTwilioError(error.message);
        })
        console.log('addConversationListener', conversation);

        conversation?.on("typingStarted", (participant) => {
            console.log("typingStarted", participant);
            setParticipantTyping(participant);
        });

        conversation?.on("typingEnded", (participant) => {
            console.log("typingEnded", participant);
            setParticipantTyping(null);
        });

        conversation?.on("messageAdded", (message) => {
            console.log('messageAdded', {
                Author: message.getParticipant(), Index: message.Index,
                Type: message.type, body: message.body, message
            });
            const messageExist = chatData?.length > 0 ?
                chatData.find((chat) => chat?.sid === message?.state?.sid)
                : false;
            console.log("conversation matched", !messageExist);
            if (!uniqueMessageSid[message?.sid]) {
                if (!messageExist) {
                    setTimeout(() => {
                        console.log("message not matched........", message, new Date());
                        setChatData(
                            (chats) => ([...chats, {
                                account_sid: '',
                                attributes: message.attributes,
                                author: message.author,
                                body: message.body,
                                conversation_sid: '',
                                dateCreated: message.dateCreated,
                                dateUpdated: message.dateUpdated,
                                delivery: null,
                                index: message.index,
                                links: [],
                                media: message.media,
                                participant_sid: message.participantSid,
                                sid: message.sid,
                                uniqueName: message.uniqueName,
                                url: '',
                            }])
                        );
                    }, 0)
                    uniqueMessageSid[message?.sid] = message?.sid;
                }
            }
        });

        conversation?.on('participantUpdated', function(event) {
            setReadMessages((prev) => [
                ...prev.filter((item) => item.identity != event.participant.identity),
                {
                    identity: event.participant.identity,
                    lastReadMessageIndex: event.participant.lastReadMessageIndex
                }
            ])
        });

        conversation?.getMessages(100)?.then((messages) => {
            console.log('conversation.getMessages: ', messages);
            setChatData(messages?.items);
            setChatsLoading(false);
            setTwilioError('');
            console.log("chatData............", chatData);
        }).catch((err) => {
            console.log('conversation.getMessages err:', err);
            setReActivateConversation(!reActivateConversation);
        });
    }

    const activateConversation = async (roomName, clientId, clientFcmToken) => {
        setChatsLoading(true);
        uniqueMessageSid = {};
        console.log('activateConversation for roomName', roomName, clientId, clientFcmToken);
        const findExistingConversation = getAllTwilioConversations?.filter(element => element?.uniqueName == roomName);
        console.log("find Existing Conversation", findExistingConversation);
        if (findExistingConversation?.length > 0) {
            addConversationListener(findExistingConversation[0], roomName, clientFcmToken, clientId);
            setActiveConversation(findExistingConversation[0]);
        } else {
            twilioClient && twilioClient?.getConversationByUniqueName(roomName)?.then((conversation) => {
                console.log('fetched Conversation', conversation);
                addConversationListener(conversation, roomName, clientFcmToken, clientId);
                setActiveConversation(conversation);
            }).catch((err) => {
                console.log('creating Conversation');
                twilioClient.createConversation({
                    uniqueName: roomName,
                    friendlyName: roomName
                }).then(async (conversation) => {
                    console.log('clientId:', clientId);
                    try {
                        const joinResp = await conversation.join();
                        console.log('conversation.join: ', joinResp);
                        addParticipantInRoom({ body: { identity: clientId, conversationSid: conversation?.sid } })
                        addConversationListener(conversation, roomName, clientFcmToken, clientId);
                        setActiveConversation(conversation);
                    } catch (err) {
                        console.log('createConversation error: ', err);
                    }
                    console.log('created Conversation', conversation);
                }).catch((error) => {
                    console.log("error", error);
                });
            });
        }
        setChatData([])
    };

    return (
        <ChatContext.Provider value={{
            chatData,
            setChatData,
            activateConversation,
            activeConversation,
            participantTyping,
            updateMessageCount,
            pushNotification,
            readMessages,
            reActivateConversation
        }}>
            {children}
        </ChatContext.Provider>
    )
}

const useChatContext = () => useContext(ChatContext);

export { ChatContextProvider, useChatContext };              