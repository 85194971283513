import React from 'react'

const Sesame = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_4095_11380)">
                <path d="M3.3508 4.58575C3.17494 4.55549 1.60625 4.31589 0.693461 5.22861C-0.219213 6.14136 0.0203006 7.71009 0.0506059 7.88594C0.0927443 8.13026 0.284105 8.32162 0.528383 8.36372C0.593094 8.37485 0.846237 8.41433 1.18592 8.41433C1.76961 8.41433 2.60875 8.2978 3.18572 7.72083C4.09843 6.80815 3.85888 5.23939 3.82857 5.06353C3.78644 4.81925 3.59511 4.62793 3.3508 4.58575ZM2.35728 6.89243C2.0777 7.17197 1.57337 7.24196 1.17967 7.24196C1.17725 7.24196 1.17487 7.24196 1.17249 7.24196C1.17159 6.84674 1.24079 6.33819 1.5219 6.05704C1.80144 5.77754 2.30573 5.70756 2.69939 5.70756H2.70661C2.70693 6.09992 2.63808 6.6116 2.35728 6.89243Z" fill={color || "#757E89"} />
                <path d="M10.9417 4.58575C10.6974 4.62789 10.506 4.81925 10.4639 5.06353C10.4336 5.23939 10.194 6.80815 11.1068 7.72083C11.6838 8.29784 12.5228 8.41433 13.1065 8.41433C13.4462 8.41433 13.6994 8.37485 13.7641 8.36372C14.0084 8.32162 14.1998 8.13022 14.2419 7.88594C14.2722 7.71009 14.5117 6.14136 13.599 5.22861C12.6863 4.31593 11.1176 4.55549 10.9417 4.58575ZM11.9352 6.89243C11.654 6.61121 11.5848 6.10238 11.5857 5.70763H11.5933C11.9874 5.70763 12.4912 5.77773 12.7706 6.05704C13.0518 6.3383 13.121 6.84709 13.1201 7.24184C12.7238 7.24293 12.2163 7.17353 11.9352 6.89243Z" fill={color || "#757E89"} />
                <path d="M9.05822 7.94023C8.88245 7.91 7.31368 7.67037 6.40089 8.58308C5.48821 9.49584 5.72772 11.0646 5.75803 11.2404C5.80017 11.4847 5.99153 11.6761 6.23581 11.7182C6.30052 11.7293 6.55366 11.7688 6.89335 11.7688C7.47704 11.7688 8.31617 11.6523 8.89315 11.0753C9.80586 10.1626 9.56631 8.59386 9.536 8.418C9.49386 8.17369 9.3025 7.98236 9.05822 7.94023ZM8.06467 10.2469C7.78509 10.5264 7.28076 10.5964 6.88706 10.5964C6.88464 10.5964 6.88226 10.5964 6.87987 10.5964C6.87897 10.2012 6.94818 9.69262 7.22928 9.41148C7.51051 9.13026 8.01828 9.06125 8.414 9.06199C8.41435 9.45436 8.3455 9.96607 8.06467 10.2469Z" fill={color || "#757E89"} />
                <path d="M18.814 11.7688C19.1536 11.7688 19.4068 11.7293 19.4715 11.7182C19.7158 11.6761 19.9072 11.4847 19.9493 11.2404C19.9796 11.0646 20.2191 9.49584 19.3064 8.58308C18.3937 7.67041 16.825 7.91 16.6491 7.94023C16.4048 7.98237 16.2135 8.17373 16.1713 8.418C16.141 8.59386 15.9015 10.1626 16.8142 11.0753C17.3912 11.6524 18.2302 11.7688 18.814 11.7688ZM17.3007 9.06211C17.6948 9.06211 18.1987 9.13221 18.478 9.41152C18.7593 9.69278 18.8284 10.2016 18.8275 10.5963C18.825 10.5963 18.8226 10.5963 18.82 10.5963C18.4259 10.5963 17.922 10.5262 17.6427 10.2469C17.3615 9.96569 17.2923 9.45686 17.2932 9.06211H17.3007Z" fill={color || "#757E89"} />
                <path d="M0.528383 12.8169C0.284066 12.859 0.0927053 13.0504 0.0506059 13.2947C0.0203006 13.4706 -0.219213 15.0394 0.693461 15.952C1.27047 16.529 2.10953 16.6455 2.69326 16.6455C3.03286 16.6455 3.28612 16.6061 3.3508 16.5949C3.59511 16.5528 3.78647 16.3614 3.82857 16.1172C3.85888 15.9413 4.09839 14.3725 3.18572 13.4598C2.27297 12.5471 0.704239 12.7866 0.528383 12.8169ZM1.5219 15.1236C1.24067 14.8424 1.17151 14.3336 1.17237 13.9388H1.17991C1.57399 13.9388 2.0779 14.0089 2.35724 14.2882C2.63847 14.5694 2.70763 15.0783 2.70677 15.473C2.31292 15.4738 1.80304 15.4047 1.5219 15.1236Z" fill={color || "#757E89"} />
                <path d="M13.7641 12.8169C13.5882 12.7867 12.0195 12.5472 11.1068 13.4598C10.1941 14.3725 10.4336 15.9413 10.4639 16.1172C10.5061 16.3615 10.6974 16.5528 10.9417 16.5949C11.0064 16.6061 11.2596 16.6455 11.5992 16.6455C12.1829 16.6455 13.022 16.529 13.599 15.952C14.5117 15.0394 14.2722 13.4706 14.2419 13.2947C14.1998 13.0504 14.0084 12.859 13.7641 12.8169ZM12.7706 15.1236C12.491 15.4031 11.9867 15.4731 11.593 15.4731C11.5906 15.4731 11.5882 15.4731 11.5858 15.4731C11.5855 15.0807 11.6544 14.5691 11.9352 14.2882C12.2148 14.0087 12.7191 13.9387 13.1128 13.9387H13.1199C13.1203 14.3311 13.0514 14.8428 12.7706 15.1236Z" fill={color || "#757E89"} />
                <path d="M6.23581 16.1714C5.99149 16.2135 5.80013 16.4049 5.75803 16.6492C5.72772 16.825 5.48821 18.3939 6.40089 19.3065C6.9779 19.8835 7.81696 20 8.40068 20C8.74029 20 8.99355 19.9606 9.05822 19.9494C9.30254 19.9073 9.4939 19.7159 9.536 19.4716C9.56631 19.2958 9.80582 17.727 8.89315 16.8143C7.98032 15.9015 6.41166 16.1411 6.23581 16.1714ZM8.40666 18.8275C8.01257 18.8275 7.50867 18.7574 7.22932 18.4781C6.9481 18.1969 6.87894 17.688 6.8798 17.2933H6.88733C7.28142 17.2933 7.78532 17.3634 8.06467 17.6427C8.34589 17.9239 8.41506 18.4327 8.4142 18.8275C8.41166 18.8275 8.4092 18.8275 8.40666 18.8275Z" fill={color || "#757E89"} />
                <path d="M19.4715 16.1714C19.2957 16.1411 17.7269 15.9016 16.8142 16.8143C15.9016 17.727 16.1411 19.2958 16.1714 19.4716C16.2135 19.716 16.4049 19.9073 16.6491 19.9494C16.7139 19.9605 16.967 20 17.3067 20C17.8904 20 18.7295 19.8835 19.3065 19.3065C20.2192 18.3938 19.9797 16.825 19.9494 16.6492C19.9072 16.4049 19.7158 16.2135 19.4715 16.1714ZM18.478 18.4781C18.1984 18.7576 17.6941 18.8276 17.3004 18.8276C17.298 18.8276 17.2956 18.8276 17.2933 18.8276C17.2929 18.4352 17.3618 17.9235 17.6426 17.6427C17.9222 17.3632 18.4265 17.2932 18.8202 17.2932H18.8274C18.8277 17.6856 18.7588 18.1973 18.478 18.4781Z" fill={color || "#757E89"} />
                <path d="M8.40065 3.87928C8.74025 3.87928 8.99351 3.8398 9.05819 3.82867C9.3025 3.78657 9.49386 3.59517 9.53596 3.35089C9.56627 3.17503 9.80578 1.60623 8.89311 0.693555C7.98032 -0.219275 6.41166 0.0203168 6.23581 0.050622C5.99149 0.0927214 5.80013 0.284121 5.75803 0.528399C5.72772 0.704255 5.48821 2.27306 6.40089 3.18573C6.9779 3.76278 7.81696 3.87928 8.40065 3.87928ZM6.88733 1.17254C7.28142 1.17254 7.78532 1.24264 8.06467 1.52195C8.34589 1.80317 8.41506 2.312 8.4142 2.70675C8.4117 2.70675 8.4092 2.70675 8.40666 2.70675C8.01257 2.70675 7.50867 2.63665 7.22932 2.35734C6.9481 2.07612 6.87894 1.56729 6.8798 1.17254H6.88733Z" fill={color || "#757E89"} />
                <path d="M16.6491 3.82867C16.7138 3.8398 16.967 3.87928 17.3066 3.87928C17.8903 3.87928 18.7295 3.76275 19.3064 3.18577C20.2191 2.2731 19.9796 0.704295 19.9493 0.528438C19.9072 0.284121 19.7158 0.0927605 19.4715 0.0506611C19.2957 0.0203949 17.7269 -0.219118 16.8142 0.693555C15.9016 1.60623 16.1411 3.17503 16.1714 3.35089C16.2134 3.59517 16.4048 3.78657 16.6491 3.82867ZM17.6426 1.52195C17.9222 1.24241 18.4265 1.17243 18.8202 1.17243H18.8274C18.8277 1.56483 18.7588 2.07651 18.478 2.35734C18.1967 2.63856 17.6894 2.70706 17.2933 2.70686C17.2929 2.31446 17.3618 1.80278 17.6426 1.52195Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_4095_11380">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Sesame