import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const SubscriptionWrapper = styled.div`
  position: relative;
  .alert{
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top:12px;
  }
  .modal-position {
    position: fixed;
    inset: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(40, 50, 65, 0.6);
    .btn{
      span{
        color: unset;
      }
    }
  }
  .btn{
    height: 40px;
    border-radius: 6px;
    span{
      color: #FFF;
      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .btn_flex {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0;
    top: 0;
    .custom_button {
      position: relative;
      &:last-child {
        background: ${theme?.colors?.primaryDefault};
        color: ${theme?.colors?.white};
      }
    }
    .btn{
      height: 32px;
      border-radius: 6px;
    }
  }
  .custom_button {
    height: 32px;
    padding: 8px 16px;
    font-family: Gilroy;
    font-size: 12px;
    gap: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${theme?.colors?.primaryDark} !important;
    color: ${theme?.colors?.primaryDark};
    background: ${theme?.colors?.white};
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
  > .title {
    color: ${theme?.colors?.blackDefault};
    font-size: ${theme?.typography?.fontLarge};
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 22px;
  }
  .sub_text {
    color: ${theme?.colors?.blackDefault};
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 34px;
  }
  .details {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 290px);
    img {
      width: 150px;
      height: 150px;
    }
    p {
      width: 386px;
      margin: 0 auto;
      color: ${theme?.colors?.blueBlack};
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-top: 8px;
      padding-bottom: 36px;
    }
  }
  text {
    color: ${theme?.colors?.blueBlack};
    text-align: center;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-top: 11px;
  }
  .data_title {
    display: flex;
    margin-bottom: 8px;
    gap: 6px;
    .titles {
      color: ${({ theme, isEdit }) =>
        isEdit ? theme?.colors?.blackDefault : theme?.colors?.grayDark};
      font-size: ${theme?.typography?.fontRegular};
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .left {
      width: 200px;
    }
  }
  .schedules_date_time {
    display: flex;
    gap: 6px;
    padding-bottom: 16px;
    .toggle {
      margin-top: 6px;
      width: 200px;
      .header-checkbox {
        input[type="checkbox"]{
          border: 3px solid ${theme.colors.grayDark} !important;
          padding: 0 !Important;
        }
        #check:checked {
          border: none !important;
        }
      } 
      text {
        color: ${theme?.colors?.blackDefault};
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 0;
      }
    }
  }
  input {
    height: 40px;
    padding: 10px 8px;
    width: 329px;
  }
  .value {
    font-size: ${theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    overflow-wrap: anywhere;
    align-self: center;
    padding-top: 10px;
    color: ${theme?.colors?.blackDefault};
  }
  .subscription-preview {
    width: 651px;
    padding: 16px 0 0 20px;
    background-color: #f5f7f8;
    .note {
      color: ${theme?.colors?.primaryDefault};
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
    .gap {
      margin-top: 5px;
      margin-bottom: 13px;
    }
    .desc {
      color: ${theme?.colors?.blackDefault};
    }
    .preview-img{
      transform: translateX(-12px);
    }
  }
  .input-wrapper{
    position: relative;
  }
  .input-wrapper input {
    color: transparent;
    caret-color: black;
    border: 1px solid #E0E4E8;
    ::placeholder {
      color: #9499A0;
    }
    ::selection {
      background-color: #3367d1;
    }    
}

  .input-wrapper:after{
    content: attr(input-wrapper) "%";
    position: absolute;
    color: ${theme?.colors?.primaryDefault};
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    top: 11px;
    left: 8px;
  }
  .input-wrapper:not([input-wrapper]):after {
    content: "";
  }
  
`;
