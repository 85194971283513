import React from 'react'
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Link } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

const BottomNavigationStyle = styled.div`
  padding-top: 13px;
  padding-left: 16px;
  padding-right: 17px;
  height: ${({ kitchen, isWebView ,platform}) => (kitchen && isWebView && platform? '90px' : '70px')};
  border-top: 1px solid #e0e4e8;
  position: ${({ kitchen, platform}) => (kitchen && platform ?  "absolute" : 'fixed')};
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 111;
  background-color: #fff;
  .nav {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    .nav_items {
      width: 60px;
      display: inline-block;
      text-align: center;
      user-select: none;
      .nav_title {
        font-size: 9px;
        font-weight: 600;
        margin-top: 5px;
        color: ${({theme}) => theme?.colors?.grayDarker};
        letter-spacing: 0.45px;
      }
      svg{
        display: block;
        margin: auto;
      }
      &.active {

        svg {
          path {
            fill: #0b1d17;
          }
        }
        .nav_title {
          color: #0b1d17;
        }
      }
    }
  }
`;

const Navigation = ({ navLinks = [], kitchen = false }) => {
  const { isWebView , isPlatformIOS} = useAppContext()
  return (
    <BottomNavigationStyle theme={theme} kitchen={kitchen} isWebView={isWebView} platform={isPlatformIOS() ? true : false}>
        <div className="nav">
          {navLinks?.map((item) => {
            return <Link to={item?.to} onClick={item?.onClick} className={`nav_items ${item?.active && 'active'}`} draggable={false}>
              {item?.icon}
              <p className='nav_title'>{item?.title}</p>
            </Link>
          })}
        </div>
      </BottomNavigationStyle>
  )
}

export default Navigation