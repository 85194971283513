import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right.svg';
import { ReactComponent as AddPayment } from 'assets/shop/add-payment.svg';
import { ReactComponent as ApplePay } from 'assets/shop/appple-pay.svg';
import { ReactComponent as Check } from 'assets/shop/check.svg';
import { ReactComponent as Trash } from 'assets/shop/trash.svg';
import { AnimatePresence, motion } from 'framer-motion';
import ManagePaymentMethod from './ManagePaymentMethod';
import { paymentMethod } from 'components/svg/PaymentMethod';
import { useAppContext } from 'context/appContext';
import { Button } from 'components/elements/button/Button';
import { useRequest } from 'hooks/useRequest';
import { formatExpiryDate } from 'utils';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/elements/modal/ConfirmationModal';
import { CircularProgress } from '@material-ui/core';

const PaymentMethodStyle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  height: 100%;
  .main_heading {
    padding: 16px 16px 12px;
    font-size: ${({ theme }) => theme?.typography?.fontMedium};
    line-height: 24px;
    color: ${({ theme }) => theme?.colors?.deepForestGreen};
    font-weight: 600;
  }
  .payment_card_div {
    height: 52px;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    gap: 14px;
    background-color: ${({ theme }) => theme?.colors?.white};
    cursor: pointer;
    .payment {
      img {
        width: 24px;
      }
      .icon {
        display: block;
      }
    }
    .desc {
      flex: 1;
      .card_details {
        color: ${({ theme }) => theme?.colors?.deepForestGreen};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        font-weight: 500;
        line-height: 20px;
      }
      .card_expiry {
        font-weight: 600;
      }
      .isInvalid {
        color: #EB3223;
      }  
    }
  }
  .update-card{
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .delete__card {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: ${({ theme }) => theme?.colors?.alizarinCrimson};
    position: absolute;
    left: 0;
    top: 1px;
    right: 0;
    width: 100%;
    height: calc(100% - 2px);
    color: #ffffff;
    padding-right: 17px;
    z-index: 1;
  }
  .containerr {
    height: calc(100% - 48px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media (min-width: 991px) {
    width: 400px;
    max-height: 350px;
    border-radius: 8px;
    overflow: clip;
    display: ${({ errorModal }) => (errorModal ? 'none' : '')};
    position: relative;
    .main_heading{
      font-size: ${({ theme }) => theme?.typography?.fontBase};
      font-weight: 700;
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      padding-top: 0;
      padding-bottom: 8px;
    }
    .payment_card_div{
      &:hover {
        background-color: #F0F2F4;
      }    
    }
    .modal__cross{
      padding: 16px;
    }
    .containerr{
      height: calc(100% - 106px - 48px);
    }
  }
`;

const DesktopPopupStyle = styled.div`
  @media (min-width: 991px) {
    .desktop_wrapper{
      width: 100vw;
      height: 100vh;
      background: rgba(81, 99, 93, 0.65);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add-payment{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute !important;
      inset: 0;
      .mobile_save{
        position: absolute;
      }
    }
    .payment-button{
      height: 106px;
      padding: 32px 0;
      button{
        max-width: 283px;
      }
    }
  }
`;

const PaymentMethod = ({ setIsPaymentModalActive, isPaymentModalActive, activeSavedCard, setSavedCards, customerId, setActiveSavedCard, savedCards, isApplePay, setIsApplePay, isAddPayment, setIsAddPayment, getSavedCards, errorModal, setErrorModal, providerSubscriptionSelection, checkCardDetails, setCheckCardDetails }) => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const { isMobile } = useAppContext();
  const [deletePaymentMethod, { isLoading: isPaymentDeleting, error: paymentDeletingError }] = useRequest('paymentMethod/delete-payment-method', 'post');
  const [updateActiveCard, { isLoading: isUpdateActive, error: updateActiveError}] = useRequest('paymentMethod/update-payment-method', 'Patch')
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState();
  const filteredPaymentMethods = savedCards.filter(method => !method.applePay);

  const handleDragEnd = async (info, paymentMethodId) => {
    const dragDistance = info.offset.x;
    const velocity = info.velocity.x;
    if (info.point.x !== 0 && (dragDistance < -50 || velocity < -20)) {
      // handleDeleteCard(paymentMethodId, closeModals = false);
      setSelectedMethodId(paymentMethodId);
      setIsDeleteModal(true);
    }
  };

  const handleDeleteCard = async (paymentMethodId, closeModals = true) => {
    try {
      await deletePaymentMethod({ body: { paymentMethodId } });
      const updatedCards = savedCards.filter((card) => card.PaymentMethodId !== paymentMethodId);
      setSavedCards(updatedCards);
      setIsDeleteModal(false);
      if(closeModals || savedCards?.length === 1){
        setIsPaymentModalActive(false);
        setIsAddPayment(false)
        setActiveSavedCard(null);
      }
    } catch (error) {
      console.error('Error deleting payment method:', error);
      toast.error(error);
    }
};

  const onUpdateClick = (e, cards) => {
    e.stopPropagation();
    setIsAddPayment(true);
    setisEdit(true);
    const formattedExpiryDate = `${cards?.Exp.slice(0, 2)} / ${cards?.Exp.slice(2)}`;
    setPaymentInfo({
      ...cards,
      cardNumber: cards?.MaskedNumber?.replace(/^./, 'x'),
      expiryDate: formattedExpiryDate,
      paymentMethodId: cards?.PaymentMethodId,
      setAsDefault: true,
      zipcode: cards?.Zip
    });

  }

  const isCardExpired = (exp) => {
    const month = parseInt(exp.substring(0, 2), 10);
    const year = parseInt(exp.substring(2), 10);
    const fullYear = year + (year < 100 ? 2000 : 0);
    const lastDayOfMonth = new Date(fullYear, month, 0).getDate();
    const expiryDate = new Date(fullYear, month - 1, lastDayOfMonth);
    const currentDate = new Date();  
    return expiryDate < currentDate;
  };
        
  const handleActiveCard = async (cards) => {
    if (isUpdateActive) {
      return;
    }
      setCheckCardDetails(cards);
      setIsApplePay("credit");
      isMobile && handleSaveActiveCard(cards)
    } 

  const handleSaveActiveCard = async (checkCardDetails) => {
    const {PaymentMethodId, Exp:expiry} = checkCardDetails
    try {
      await updateActiveCard({ body: { paymentMethodId: PaymentMethodId, expiry, setAsDefault: true } });
      setActiveSavedCard(checkCardDetails);
      setIsPaymentModalActive(false);
    } catch  (error) {
      console.error('Error changing acitve Card', error);
      toast.error(error);
      setCheckCardDetails(activeSavedCard)
    }
  }

  const handleBackClick = () => {
    setIsAddPayment(false);
    setisEdit(false);
    setPaymentInfo({});
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  useEffect(() => {
    !isMobile && isPaymentModalActive && document.body.classList.add('hide-body-scroll');
    setPaymentInfo({});
    return () => document.body.classList.remove('hide-body-scroll');
  }, [isPaymentModalActive])

  useEffect(() => {
    setCheckCardDetails(activeSavedCard);
  },[activeSavedCard])

  return (
    <>
      <AnimatePresence>
        <DesktopPopupStyle>
          {isPaymentModalActive &&
          <>
          <motion.div
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ type: 'spring', duration: 0.5 }}
            exit={{ x: '100%', opacity: 0 }}
            style={{ position: 'fixed', zIndex: 9, inset: 0 }}
            className="desktop_wrapper"
          >
            <>
              <PaymentMethodStyle theme={theme} errorModal={errorModal}>
                {isMobile ? (
                  <MobileHeader
                    text={'Payment method'}
                    onClickLeft={() => setIsPaymentModalActive(false)}
                  />
                ) : (
                  <MobileHeader
                    className={"modal__cross"}
                    onClickClose={() => setIsPaymentModalActive(false)}
                  />
                )
                }
                <div className="containerr">
                  <h2 className="main_heading">Saved payment methods</h2>
                  <AnimatePresence>
                    {filteredPaymentMethods?.map((cards, index) => (<>
                    {isMobile ? (
                    <>
                      <div style={{ position: 'relative' }} key={index}>
                        <motion.div
                          drag={'x'}
                          dragConstraints={{ left: 0, right: 0 }}
                          dragMomentum={false}
                          dragElastic={{ left: 1, right: 0 }}
                          onDragEnd={(_, info) => handleDragEnd(info, cards?.PaymentMethodId)}
                          style={{ position: 'relative', zIndex: 2 }}
                        >
                          <div className="payment_card_div" onClick={() => handleActiveCard(cards)}>
                            <div className="payment">{paymentMethod(cards?.CardType)}</div>
                            <div className="desc">
                              <h2 className="card_details card_number">
                                {cards?.CardType} ...{cards?.MaskedNumber.slice(-4)}
                              </h2>
                              <h3 className={`card_details card_expiry ${isCardExpired(cards?.Exp) ? 'isInvalid' : ''}`}>{isCardExpired(cards?.Exp) ? "Card expired" : `Exp. ${formatExpiryDate(cards?.Exp)}`}</h3>
                            </div>
                            {
                              isCardExpired(cards?.Exp) && (
                                <text
                                  className='update-card'
                                  onClick={(e) => onUpdateClick(e, cards)}
                                >
                                  Update
                                </text>
                              )
                            }
                            {cards?.PaymentMethodId === checkCardDetails?.PaymentMethodId && isApplePay !== "apple pay" && <Check /> }
                          </div>
                        </motion.div>
                        <div className="delete__card">Delete</div>
                      </div>
                      {!window.ApplePaySession && index !== savedCards.length - 1 && <div style={{borderBottom: '1px solid #F5F7F8', width: 'calc(100% - 16px)', margin: '4px auto'}} />}
                    </>
                    ) 
                    :
                    (
                      <>
                      <div style={{ position: 'relative' }} key={index}>
                          <div className="payment_card_div" onClick={() => handleActiveCard(cards)} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                            <div className="payment">{paymentMethod(cards?.CardType)}</div>
                            <div className="desc">
                              <h2 className="card_details card_number">
                                {cards?.CardType} ...{cards?.MaskedNumber.slice(-4)}
                              </h2>
                              <h3 className={`card_details card_expiry ${isCardExpired(cards?.Exp) ? 'isInvalid' : ''}`}>{isCardExpired(cards?.Exp) ? "Card expired" : `Exp. ${formatExpiryDate(cards?.Exp)}`}</h3>
                            </div>
                            {
                              isCardExpired(cards?.Exp) && (
                                <text
                                  className='update-card'
                                  onClick={(e) => onUpdateClick(e, cards)}
                                >
                                  Update
                                </text>
                              )
                            }
                            {hoveredCard === index && <Trash onClick={(e) => {e.stopPropagation(), setSelectedMethodId(cards?.PaymentMethodId), setIsDeleteModal(true)}}/>}
                            {cards?.PaymentMethodId === checkCardDetails?.PaymentMethodId && isApplePay !== "apple pay" && <Check /> }
                          </div>
                      </div>
                      {!window.ApplePaySession && index !== savedCards.length - 1 && <div style={{borderBottom: '1px solid #F5F7F8', width: 'calc(100% - 16px)', margin: '4px auto'}} />}
                    </>
                    )}
                      </>
                    ))}
                  </AnimatePresence>
                  {isMobile && window.ApplePaySession && providerSubscriptionSelection?.length < 1 && <div className="payment_card_div" onClick={() => {
                    setIsApplePay("apple pay");
                    setCheckCardDetails(null);
                    setIsPaymentModalActive(false);
                  }}>
                    <div className="payment">
                      <ApplePay className="icon" />
                    </div>
                    <div className="desc">
                      <h2 className="card_details card_number">Apple Pay</h2>
                    </div>
                    {isApplePay === "apple pay" && <Check />}
                  </div>}

                  <div
                    style={{
                      width: 'calc(100% - 33px)',
                      height: '1px',
                      opacity: '0.1',
                      background: '#51635D',
                      margin: '12px auto',
                    }}
                  />

                  <h2 className="main_heading">Add payment methods</h2>

                  <div className="payment_card_div" onClick={() => setIsAddPayment(true)}>
                    <div className="payment">
                      <AddPayment className="icon" />
                    </div>
                    <div className="desc">
                      <h3 className="card_details card_expiry">Credit/Debit</h3>
                    </div>
                    <ChevronRight />
                  </div>
                </div>
                <div className='payment-button'>
                  <Button type={'mobile_save'} title='Confirm payment method' onClick={() => handleSaveActiveCard(checkCardDetails)}/>
                </div>
                <AnimatePresence>
                {isAddPayment && (
                  <motion.div
                    initial={{
                      x: '100%',
                      opacity: 0,
                    }}
                    animate={{
                      x: '0',
                      opacity: 1,
                    }}
                    exit={{
                      x: '100%',
                      opacity: 0,
                    }}
                    transition={{
                      type: 'spring',
                      duration: 0.5,
                    }}
                    style={{
                      position: 'fixed',
                      inset: 0,
                      zIndex: 9,
                    }}
                    className='add-payment'
                  >
                    <ManagePaymentMethod handleLeftClick={handleBackClick} customerId={customerId} isEdit={isEdit} paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} getSavedCards={getSavedCards} handleDeleteCard={handleDeleteCard} errorModal={errorModal} setErrorModal={setErrorModal} setIsPaymentModalActive={setIsPaymentModalActive} setIsAddPayment={setIsAddPayment}/>
                  </motion.div>
                )}
              </AnimatePresence>
              </PaymentMethodStyle>
            </>
          </motion.div>
          {isUpdateActive && <div style={{ position: 'fixed', inset: 0, zIndex: 9, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress style={{ color: '#000A2C' }} /></div>}
          </>
          }
        </DesktopPopupStyle>
        <ConfirmationModal title={"Are you sure you’d like <br /> to delete this card?"} isOpen={isDeleteModal} primaryTitle={"Yes"} secondaryTitle={"Cancel"} handlePrimaryClick={() => handleDeleteCard(selectedMethodId, false)} onClickClose={() => setIsDeleteModal(false)} handleSecondaryClick={() => setIsDeleteModal(false)}/>
      </AnimatePresence>
    </>
  );
};

export default PaymentMethod;
