import React, { useState } from 'react';
import styled from 'styled-components';
import { createImageFromInitials } from './AvatarLetters';
import { theme } from '../../../shared/theme/theme';
import Tooltip from 'rc-tooltip';

const ProfileIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  .icon {
    width: 32px;
    height: 32px;
  }
`;

const ProfileButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  width:  ${({ width }) => (width ? width : "32px")};
  height: ${({ height }) => (height ? height : "32px")};
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
`;

const Status = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${({ status }) => (status ? status : '#FF0000')};
  border-radius: 50%;
  bottom: 0;
  right: 0;
  border: 1px solid #fff;
`;
const Number = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: ${({ numbgColor }) => (numbgColor ? numbgColor : '#FF0000')};
  border-radius: 50%;
  top: 0;
  right: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme?.typography?.fontInter};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${({ textColor }) => (textColor ? textColor : '#fff')};
`;

const IconContainer = styled.div`
position: absolute;
width: 28px;
height: 28px;
border-radius: 50%;
bottom: 5px;
background-color: ${({ numbgColor }) => (numbgColor ? numbgColor : '#FF0000')};
right: -5px;
display: flex;
justify-content: center;
align-items: center;
font-family: ${({ theme }) => theme?.typography?.fontInter};
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 16px;
color: ${({ textColor }) => (textColor ? textColor : '#fff')};
img{
    height: 10.5px;
    width: 10.5px;
  }
`

const overlayInnerStyle = {
    backgroundColor: (props) => (props.tooltipColor ? props.tooltipColor : '#FF0000'),
    borderRadius: '4px',
    color: (props) => (props.tooltipTextColor ? props.tooltipTextColor : '#FF0000'),
    fontSize: '12px',
    textAlign: 'center',
    maxWidth: '250px',
    width: '100%',
    bottom: 0,
    fontFamily: (props) => (props.heme?.typography?.fontInter),
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
};

function avatar({
    color,
    name,
    bgColor,
    status,
    imgSrc,
    height,
    width,
    icon,
    count,
    numbgColor,
    textColor,
    tooltipColor,
    tooltipTextColor,
    tooltipDisplay,
    onClick
}) {
    
    return (
        <ProfileIconWrapper>
            {tooltipDisplay ? (
                <Tooltip
                    overlayInnerStyle={overlayInnerStyle}
                    overlay={name}
                    tooltipColor={tooltipColor}
                    tooltipTextColor={tooltipTextColor}
                    placement={"bottom"}
                >
                    <ProfileButton>
                        {count && (
                            <Number theme={theme} numbgColor={numbgColor} textColor={textColor}>
                                {count}
                            </Number>
                        )}
                        <ProfileImage
                            src={!imgSrc ? createImageFromInitials(500, name, color, bgColor) : imgSrc}
                            alt="Profile Image"
                            onError={(e) => { e.target.src = '/images/avatar.png' }}
                        />
                        {status && <Status status={status} />}
                    </ProfileButton>
                </Tooltip>
            ) : (
                <ProfileButton>
                    {count && (
                        <Number theme={theme} numbgColor={numbgColor} textColor={textColor}>
                            {count}
                        </Number>
                    )}
                        {icon && (
                            <IconContainer theme={theme} numbgColor={numbgColor} textColor={textColor}>
                                <img src={icon} onClick={onClick}/>
                            </IconContainer>
                        )}
                    <ProfileImage
                        src={!imgSrc ? createImageFromInitials(500, name, color, bgColor) : imgSrc}
                        alt="Profile Image"
                        height={height}
                        width={width}
                        onError={(e) => { e.target.src = '/images/avatar.png' }}
                    />
                    {status && <Status status={status} />}
                </ProfileButton>
            )}
        </ProfileIconWrapper>
    );
}

export default avatar;