import React from 'react'
import styled from 'styled-components';
import ImageViewer from 'components/ImageViewer';
import { theme } from 'shared/theme/theme';
import { useAppContext } from 'context/appContext';
import { ReactComponent as Info } from 'assets/shop/info-circle.svg';

const OrderItemStyle = styled.div`
        border-bottom: 1px solid ${({ theme }) => theme?.colors?.solitudeBlue};
        display: flex;
        width: 100%;
        padding: 12px 0;
        &:last-child{
            border-bottom: 0px;
            margin-bottom: 0px;
            padding: 16px 0px 16px 0 !Important;
        }
        &:first-child{
            padding: 0px 0px 12px 0;
        }
    .food-avtar{
        position: relative;
        margin-left: 12px;
        opacity: ${({ paused}) => paused ? "0.6" : "1"}; 
        .badge-count{
            position: absolute;
            left: -10px;
            bottom: 4px;
            border-radius: 10px;
            border: 1px solid ${theme?.colors?.solitudeBlue};
            background: #F0F2F4;
            box-sizing: border-box;
            font-family: ${theme?.typography?.fontGilroy};
            font-size: 8px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: ${theme.colors.primaryDefault};
            padding: 3px 5px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        img {
            height: 65px;
            width: 65px;
            border-radius: 8px;
            object-fit: cover;
        }
    
    }
    .product-name{
        padding: 15px 0px 0px 11px;
        h4{
            color: ${({ paused}) => paused ? theme.colors.grayDarker : theme.colors.primaryDarker};
            font-family: ${theme.typography.fontGilroy};
            font-size: ${theme.typography.fontRegular};
            font-style: normal;
            font-weight: 600;
            line-height: 20px; 

        }
        .amount{
            font-weight: 500;
        }
    }
    .outOfStock-container{
        display: flex;
        gap: 15px;
        .out-of-stock{
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: left;
            color: ${theme.colors.primaryDark};
            font-family: ${theme?.typography?.fontGilroy};
            font-size: ${theme.typography.fontRegular};
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }              
    }
    @media(min-width:991px){
        align-items: center;
        border-bottom: none !Important;
        padding: 16px 0 0 0;
        &:last-child{
            border-bottom: 0px;
            margin-bottom: 0px;
        }
        &:first-child{
            padding: 0px !important;
        }
        .food-avtar{
            .badge-count{
                bottom: -3px;
            }
        }
        .outOfStock-container{
            gap: 24px;
        }
            .product-name{
                display: flex;
                width: 100%;
                padding: 0 !important;
                justify-content: space-between;    
                h4{
                    font-size: ${theme.typography.fontBase};
                }
            }
    }
`

export const OrderItem = ({ item, subscription, paused}) => {
    const { isMobile } = useAppContext();
    return (
        <OrderItemStyle theme={theme} paused={paused}>
            <div className='food-avtar'>
                <ImageViewer
                    src={item?.img}
                    alt={item?.name}
                    fallbackSrc={'/images/Kitchen-image.png'}
                />
                <span className='badge-count'> {item?.qty} </span>
            </div>
            <div className='product-name'>
                <div className='outOfStock-container'>
                <h4>{item?.name}</h4>
                {!item.isAvailable && !subscription && (
                    <div className='out-of-stock'>
                        <Info />
                        <text>Out of stock</text>
                    </div>
                )}
                </div>
                {!isMobile && <h4 className='amount'>${parseFloat(item?.price/100).toFixed(2)}</h4>}
            </div>
        </OrderItemStyle>
    )
}
