import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Input from 'components/Input';
import Select from 'components/Select';
import { BMR_FORMULAS, CHALLANGES, createPlanItems, PLAN_PERIODS, BMR_FORMULA_VARS, DEFAULT_ACTIVITY } from '../../../../constants';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Recomended from "components/Challange/Recomended";
import { useNutritionistContext } from "context/nutritionistContext";


const AdvanCeSettingStyling = styled.div`
.progress-Advance{
    height: 32px;
    left: 44px;
    right: 192px;
    top: 308px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #52C41A;
    margin-bottom: 14px;
    margin-top: 14px;
}
.card-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Column {
    display: table-cell;
}
.Fat{
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #51635D;
  }
  .Fat-price{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #51635D;
  }
  .Carbs-price{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.05em;
      color: #949D9B;
  }
      span, select, a {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        color: #51635D;
      }
      .main-incremen{
        display:flex
      }
      .margin-styling-fat{
        margin-bottom:24px;
        div{
          margin-bottom:0px;
        }
      }
      .carbs-percent {
        span:nth-child(1){
          height:10px;
        }
        span:nth-child(2){
          height:10px !important;
          color:#916AE2; 
          transition: 0.3s all;
        }
        span:nth-child(3){
          color:white;
          transition: 0.3s all;
        }
      }
      .protein-percent {
        span:nth-child(1){
          height:10px;
        }
        span:nth-child(2){
          height:10px !important;
          color:#EE786B; 
          transition: 0.3s all;
        }
        span:nth-child(3){
          color:white;
          transition: 0.3s all;
        }
      }
      .fat-percent {
        span:nth-child(1){
          height:10px;
        }
        span:nth-child(2){
          height:10px !important;
          color:#FEB95A; 
          transition: 0.3s all;
        }
        span:nth-child(3){
          color:white;
          transition: 0.3s all;
        }
      }
      .card-detail1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .p25{
            position: relative;
            left: 3px;
        }
        .p50{
            position: relative;
            left: 8px;
        }
        .p75{
            position: relative;
            left: 10px;
        }
      }
      .progress-setting{
        height: 32px;
        left: 44px;
        right: 192px;
        top: 308px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #52C41A;
        margin-top: 13px;
        margin-bottom: 14px;
}
.Challange-formula {  
    font-size: 14px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #51635D;
    div{
        width: 100%;
        input{
            background: #F6F5F8;
        }
    }
}
.progress-setting1{
    height: 32px;
    left: 44px;
    right: 192px;
    top: 308px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #52C41A;
    margin-top: 13px;
  }

`
const AdvanceSetting = ({form,setForm,activityType,setCustomize,Customize,handleMacroChanges,progress,setprogress, setCustomizeValue, setUseCustom, useCustom}) => {
	const { nutritionist: allTemplates} = useNutritionistContext();
    const [setting, setsetting] = useState(false);
    const dietTemplate = allTemplates?.nutritionist?.dietTemplates.find((dietTemplate) => dietTemplate?.id === form?.id);
    const handleUpdateForm = useCallback((e) => {
          const PAL_formula = e === "instituteOfMedicine" ? "PA" : "PAL";
          const defaultActivity = DEFAULT_ACTIVITY[PAL_formula];
      
          let updatedPAL;
      
          if (e === dietTemplate?.BMR_formula) {
            updatedPAL = dietTemplate.PAL;
            setCustomizeValue(dietTemplate.PAL);
          } else {
            updatedPAL = defaultActivity;
            setCustomizeValue(defaultActivity);
          }

          setForm((prevForm) => ({
            ...prevForm,
            BMR_formula: e,
            PAL_formula,
            PAL: updatedPAL,
          }));
        },
        [form.BMR_formula, setCustomizeValue]
      );

    return (
        <>
            <AdvanCeSettingStyling>
                <div className="progress-Advance"  onClick={() => setprogress(!progress)}>
                    {progress ? '-' : '+'} Advance
                </div>
                {
                    progress ?
                        <>
                            <div class="Row card-detail">
                                <div class="Column">
                                    <div className="Fat">Fat</div>
                                </div>
                                <div class="Column">
                                    <div className="Fat-price">{form.fat_percent}%</div></div>
                            </div>
                            <div className='margin-styling-fat'>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <Slider value={form.fat_percent} className='fat-percent' min={0}
                                        onChange={(v) => handleMacroChanges('fat_percent', v.target.value)}
                                    />
                                </Stack>
                                <div class="Row card-detail1">
                                <div class="Column">
                                        <div className="Carbs-price">0%</div>
                                    </div>
                                    <div class="Column p25">
                                        <div className="Carbs-price">25%</div>
                                    </div>
                                    <div class="Column p50">
                                        <div className="Carbs-price">50%</div>
                                    </div>
                                    <div class="Column p75">
                                        <div className="Carbs-price">75%</div>
                                    </div>
                                    <div class="Column">
                                        <div className="Carbs-price">100%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="Row card-detail">
                                <div class="Column">
                                    <div className="Fat">Protein</div>
                                </div>
                                <div class="Column">
                                    <div className="Fat-price">{form.protein_percent}% </div></div>
                            </div>
                            <div className='margin-styling-fat'>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <Slider value={form.protein_percent} className="protein-percent" min={0}
                                        onChange={(v) => { handleMacroChanges('protein_percent', v.target.value) }}
                                    />
                                </Stack>
                                <div class="Row card-detail1">
                                    <div class="Column">
                                        <div className="Carbs-price">0%</div>
                                    </div>
                                    <div class="Column p25">
                                        <div className="Carbs-price">25%</div>
                                    </div>
                                    <div class="Column p50">
                                        <div className="Carbs-price">50%</div>
                                    </div>
                                    <div class="Column p75">
                                        <div className="Carbs-price">75%</div>
                                    </div>
                                    <div class="Column">
                                        <div className="Carbs-price">100%</div>
                                    </div>
                                </div>
                            </div>

                            <div class="Row card-detail">
                                <div class="Column">
                                    <div className="Fat">Carbs</div>
                                </div>
                                <div class="Column">
                                    <div className="Fat-price">{form.carbs_percent}%</div></div>
                            </div>
                            <div className='margin-styling-fat'>
                                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                    <Slider aria-label="Volume" value={form.carbs_percent} className='carbs-percent'
                                        onChange={(v) => handleMacroChanges('carbs_percent', v.target.value)}
                                    />
                                </Stack>
                                <div class="Row card-detail1">
                                    <div class="Column">
                                        <div className="Carbs-price">0%</div>
                                    </div>
                                    <div class="Column p25">
                                        <div className="Carbs-price">25%</div>
                                    </div>
                                    <div class="Column p50">
                                        <div className="Carbs-price">50%</div>
                                    </div>
                                    <div class="Column p75">
                                        <div className="Carbs-price">75%</div>
                                    </div>
                                    <div class="Column">
                                        <div className="Carbs-price">100%</div>
                                    </div>
                                </div>
                            </div>
                            <div className='progress-setting' onClick={() => setsetting(!setting)}>
                                {setting ? '-' : '+'} Advance Settings
                            </div>
                            {
                                setting ?
                                    <>
                                        <div className='MacroBreakdown'>BMR FORMULA</div>
                                        <Select
                                            key="BMR"
                                            options={BMR_FORMULAS}
                                            updateValue={handleUpdateForm}
                                            defaultSelected={form.BMR_formula}
                                        />
                                        <div>
                                            <Input
                                                label={`Energy Formula `}
                                                name="energyFormula"
                                                id="energyFormula"
                                                type="text"
                                                value={form.BMR_formula==="instituteOfMedicine" ? "PA (Physical Activity)" : "PAL (Physical Activity Level)"}
                                                onChange={(e) => setForm({ ...form, EnergyFormula: e.target.value })}
                                                autoComplete="off"
                                                placeholder="PAL (Physical Activity Level)"
                                                className='Challange-formula'
                                                disabled={true}
                                            />
                                        </div>
                                        <Input
                                            label="Target calories formula "
                                            name="calorieFormula"
                                            id="calorieFormula"
                                            type="text"
                                            value={form.BMR_formula==="instituteOfMedicine" ? "EER" : "BMR*PAL"}
                                            onChange={(e) => setForm({ ...form, Target: e.target.value })}
                                            autoComplete="off"
                                            placeholder="BMR*PAL"
                                            className='Challange-formula'
                                            disabled={true}
                                        />
                                        <div className='progress-setting1' onClick={() => {{setCustomize(!Customize)}}}>
                                            Customize PAL
                                        </div>
                                        <Recomended form={form} setForm={setForm} />
                                    </>
                                    : null 
                            }
                        </>
                        : null
                }
            </AdvanCeSettingStyling>
        </>
    )
}

export default AdvanceSetting