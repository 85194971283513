import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import {formatNumberDecimalPlaces} from '../../../utils';
import moment from 'moment';

const OfferPanelWrapper = styled.div`
  .offer-container {
    background-color: #fff;
    border: 1px solid #e0e3e0;
    border-radius: 10px;
  }

  .offer-title-price {
    padding-top: 18px;
    border-bottom: 1px solid #f6f5f8;
  }

  .offer-title {
    margin-bottom: 6px;
    padding: 0 18px;
    color: #52c41a;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .offer-price {
    margin-bottom: 5px;
    padding: 0 18px;
    color: #0b1d17;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
  }

  .plan-image-wrapper {
    display: flex;

    img {
      width: 100%;
      height: 170px;
      object-fit: contain;
    }
  }

  .offer-desc-edit {
    padding: 18px 18px 20px;
  }

  .offer-desc {
    position: relative;
    margin-bottom: 18px;
    color: #51635d;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    span {
      color: #52c41a;
      cursor: pointer;
    }
  }

  .offer-edit-remove {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 10px;
      color: #949d9b;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-transform: uppercase;
    }

    .edit-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }
  }

  @media (max-width: 991px) {
    .offer-container {
      border: 0;
      border: 1px solid #E0E3E0;
    }
  }
`;

export default function OfferPanel({offer, deleteCoachingOffer}) {
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();

  var sortedOfferByUpdatedDate = offer.coachingOffer.sort(function(a,b){ 
    return moment(b.updatedAt) - moment(a.updatedAt)
    });

  return (
    <>
      {sortedOfferByUpdatedDate.map((item) => (
        <OfferPanelWrapper>
      <div className="offer-container">
        <div className="offer-title-price">
          <div className="offer-title">{item.title}</div>
          <div className="offer-price">${formatNumberDecimalPlaces(item.price / 100)}</div>
          <div className="plan-image-wrapper">
            <img src={item.img?item.img:"/images/plan-cover.png" }alt={item.title} width="200" height="100" className='offer-image'/>
          </div>
        </div>
        <div className="offer-desc-edit">
          <div className="offer-desc">
            {showMore ? (
              <div>
                {item.description}
                <span onClick={() => setShowMore(false)}> show less</span>
              </div>
            ) : (
              <LinesEllipsis
                text={item.description}
                maxLine="2"
                ellipsis={<span onClick={() => setShowMore(true)}> show more...</span>}
                trimRight
                basedOn="letters"
              />
            )}
          </div>
          <div className="offer-edit-remove">
            <div
              className="edit-wrapper"
              onClick={useCallback(() => {
                document.body.classList.remove('hide-scroll');
                history.push(`/nutritionist/coaching/offering/edit/${item.id}`);
              }, [item?.id])}
            >
              <img src="/icons/edit-gray.svg" alt="Edit Icon" />
              <span>edit</span>
            </div>
            <img
              src="/icons/gray-trash.svg"
              alt="Trash Icon"
              onClick={() => {
                  deleteCoachingOffer(item.id);
              }}
            />
          </div>
        </div>
      </div>
      </OfferPanelWrapper>
       ))}
      </>
  );
}
