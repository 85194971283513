import React, { useRef, useEffect, useState, createContext } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import classNames from 'classnames';
import styled from 'styled-components';
import SliderDays from './SliderDays';
import PantryCalories from './PantryCalories';
import DiaryTagContent from './DiaryTagContent';
import { useAppContext } from 'context/appContext';
import ProgressMacroLine from './ProgressMacroLine';
import MobileHeader from 'components/elements/MobileHeader';

export const DiaryMealsContext = createContext();

const DiaryModalWrapper = styled.div`
  position: fixed;
  top: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 121;
  background-color: #fff;
  visibility: hidden;
  transition: all .5s ease-in-out;
  &.slideUp {
    top: 0;
    visibility: visible;
  }
  &.heightLess600 {
    .log-btn-wrapper {
      margin: 0;
    }
    .log-meals-btn {
      position: absolute;
      bottom: 22px;
      left: auto;
      z-index: 3;
      box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
    }
  }
  .week-days-wrapper {
    padding: 8px 17px 7px 18px;
    background-color: #fff;
    .days-number-wrapper {
      display: flex !important;
      justify-content: space-between;
    }
    .day-number {
      width: 40px;
      height: 40px;
      color: #51635D;
      border: 1px solid #E0E3E0;
      border-radius: 50%; 
      font-size: 9px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.05em;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: #52C41A;
        color: #fff;
        border: 1px solid #52C41A;
      }
    }
    &:empty{
      display: none;
    }
  }
  .progress-macros-wrapper {
    padding: 16px 36.79px 16px 38px;
    background-color: #fff;
  }
  .log-meals-btn {
    width: 120px;
    height: 36px;
    background: #52C41A;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 21px;
  }
  .slick-dots {
    top: 0;
    bottom: auto;
  }
  .wrapper__scroller{
    height: calc(100vh - 48px);
    overflow: auto;
    background-color: #F5F7F8;
    &::-webkit-scrollbar{
      display: none;
    }
  }
`;

export default function DiaryModal({
  isDiaryModal,
  setIsDiaryModal,
  activeDay,
  days,
  setDays,
  fullDiaryMeals,
  setIsLogMealsModal,
  weeklyDiaryMeals,
  setWeeklyDiaryMeals,
  setCurrentDayInfo,
  setActiveDay,
  updateFullDiaryMeals,
  removeMealById,
  updatePantryData
}) {
  const {
    profile: {
      dietMetrics: { macros },
    },
  } = useAppContext();
  const sliderRef = useRef(null);
  const mainSliderRef = useRef(null);
  const [diaryMeals, setDiaryMeals] = useState([]);
  const [stopSwipe, setStopSwipe] = useState(false);
  const [memoActiveDay, setMemoActiveDay] = useState(false);
  const [diarymealDate, setDiarymealDate] = useState(moment().format('MM-DD-YYYY'));

  const settings = {
    dots: false,
    speed: 200,
    swipe: fullDiaryMeals ? true : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: false,
    beforeChange: (oldIndex, newIndex) => {
      setDiaryMeals(weeklyDiaryMeals[newIndex]);
      if (oldIndex == 6 && newIndex == 0 && !stopSwipe) {
        sliderRef?.current?.slickNext();
        setMemoActiveDay(true);
      } else if (oldIndex == 0 && newIndex == 6 && !stopSwipe) {
        sliderRef?.current?.slickPrev();
        setMemoActiveDay(true);
      }
    },
    afterChange: () => {
      stopSwipe && setStopSwipe(false);
    },
    initialSlide: moment(new Date(), 'YYYY-MM-DD').day(),
  };

  const convert = (uniqueMeals, date) => {
    const updatedUniqueMeals = [uniqueMeals];
    const groupedDiaryMeals= [];
    updatedUniqueMeals?.map((item)=>{
  { groupedDiaryMeals.push(item)}})
    
    let calcCals = 0;
    let consumedInfo = { fat: 0, carbs: 0, protein: 0 };

    groupedDiaryMeals?.[0] && Object.keys(groupedDiaryMeals?.[0]).length > 0 && Object.keys(groupedDiaryMeals?.[0])?.map((objKey) => {
      return groupedDiaryMeals?.[0]?.[objKey].map((meal) => {
        calcCals += (meal.calories || 0) * (meal?.quantity || 1);
        consumedInfo.fat += (meal.fat || 0) * (meal?.quantity || 1);
        consumedInfo.carbs += (meal.carbs || 0) * (meal?.quantity || 1);
        consumedInfo.protein += (meal.protein || 0) * (meal?.quantity || 1);
      })
    })

    return {
      date,
      // meals: Object.values(res),
      meals: groupedDiaryMeals,
      totalCals: calcCals,
      consumedInfo,
    };
  };
  
  const updateActiveWeek = (days, diaryIndex) => {
    setWeeklyDiaryMeals([]);
    let weeklyDiaryMeals = [];

    for (let day of days) {
      let isActive = false;
      fullDiaryMeals?.length &&
        fullDiaryMeals.map((item) => {
            for (let key in item) {
              if (day == moment().format('MM-DD-YYYY') && key == moment().format('MM-YYYY')) {
                setCurrentDayInfo(convert(item[key][day], day));
              }
              if (item[key][day]) {
                isActive = true;
                weeklyDiaryMeals.push(convert(item[key][day], day));
              }
            }
        });

      if (!isActive) {
        weeklyDiaryMeals.push(convert([], day));
      }
    }
    setWeeklyDiaryMeals(weeklyDiaryMeals);
    setDiaryMeals(weeklyDiaryMeals[diaryIndex]);
  };

  useEffect(() => {
    updateActiveWeek(days, moment(activeDay).day());
  }, [fullDiaryMeals, isDiaryModal, activeDay]);

  useEffect(()=>{
    if(isDiaryModal){
    setActiveDay(new Date());
    }
  },[isDiaryModal])

  useEffect(()=>{
    if(sliderRef?.current){
     const selectedDate = moment(diaryMeals?.date, 'MM-DD-YYYY').format('MM-DD-YYYY');
     setDiarymealDate(selectedDate);
    }
   },[settings])
   
   const handleCloseDiaryModal=()=>{
     setActiveDay(new Date().toISOString());
    setIsDiaryModal(false);
   }

  return (
    <DiaryMealsContext.Provider
      value={{ diaryMeals, updateActiveWeek, fullDiaryMeals, weeklyDiaryMeals }}
    >
      <DiaryModalWrapper className={classNames({ slideUp: isDiaryModal, heightLess600: window.innerHeight < 600 })}>
        <MobileHeader text={'Diary'} onClickClose={handleCloseDiaryModal} />
        <div className='wrapper__scroller'>
        <div className="week-days-wrapper">
          <SliderDays
            ref={sliderRef}
            mainSliderRef={mainSliderRef}
            activeDay={activeDay}
            setDays={setDays}
            setStopSwipe={setStopSwipe}
            memoActiveDay={setMemoActiveDay}
            isDiaryModal={isDiaryModal}
            setActiveDay={setActiveDay}
            diarymealDate={diarymealDate}
            setDiarymealDate={setDiarymealDate}
          />
        </div>
        {isDiaryModal && (
          <Slider ref={mainSliderRef} {...settings} style={{ height: window.innerHeight - 225 }} swipe={!!fullDiaryMeals?.length && isDiaryModal &&(true) }>
            {weeklyDiaryMeals.map((diary) => (
              <div>
                <div className="progress-macros-wrapper">
                  <PantryCalories diaryMeals={diaryMeals?.totalCals || 0} />
                  <div className="containerr">
                    <ProgressMacroLine
                      itemContent="Fats"
                      consumedMealItem={diaryMeals?.consumedInfo?.fat}
                      userDietMacro={macros?.fat}
                      lineColor="#EE786B"
                      isDiaryModal={isDiaryModal}
                      weight="g"
                    />
                    <ProgressMacroLine
                      itemContent="Proteins"
                      consumedMealItem={diaryMeals?.consumedInfo?.protein}
                      userDietMacro={macros?.protein}
                      lineColor="#8ACEBF"
                      isDiaryModal={isDiaryModal}
                      weight="g"
                    />
                    <ProgressMacroLine
                      itemContent="Carbs"
                      consumedMealItem={diaryMeals?.consumedInfo?.carbs}
                      userDietMacro={macros?.carbs}
                      lineColor="#2399BF"
                      isDiaryModal={isDiaryModal}
                      weight="g"
                    />
                  </div>
                </div>
                <DiaryTagContent 
                 setIsDiaryModal={setIsDiaryModal}
                 setIsLogMealsModal={setIsLogMealsModal} 
                 diaryMeals={diary.meals} 
                 isDiaryModal={isDiaryModal} 
                 weeklyDiaryMeals={weeklyDiaryMeals} 
                 diarymealDate={diarymealDate} 
                 setWeeklyDiaryMeals={setWeeklyDiaryMeals}
                 updateFullDiaryMeals={updateFullDiaryMeals}
                 removeMealById={removeMealById}
                 updatePantryData={updatePantryData}
                 setActiveDay={setActiveDay}
                 />
              </div>
            ))}
          </Slider>
        )}
        </div>
      </DiaryModalWrapper>
    </DiaryMealsContext.Provider>
  );
}

