import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { BsCheck2 } from 'react-icons/bs';


const SwitchStyle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap || '8px')};
  height: 32px;
  flex-wrap: wrap;
  flex-direction: 'inherit';
  place-content: ${({ placement, type }) =>
  placement === 'right' && type === 'switch' ? 'space-between' : 'inherit'};     
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ toggleWidth }) => toggleWidth || '20px'};
  height: ${({ toggleHeight }) => toggleHeight || '11.67px'};
  background-color: ${({ toggle, color }) => (toggle ? color : 'white')};
  border-radius: 15px;
  transition: 0.4s;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ toggle ,color}) => (toggle ? color : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 15px;
  transition: 0.4s;

  &:before {
    content: '';
    position: absolute;
    left: 2px;
    bottom: 2px;
    width: ${({ width }) => width || '8.33px'};
    height: ${({ height }) => height || '8.33px'};
    border-radius: 100%;
    background-color: ${({ toggle }) => (toggle ? 'white' : 'white')};
    transition: 0.4s;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${Slider}:before {
    transform: translateX(${({ transform }) => transform || '9px'});
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const CheckboxStyle = styled.div`
.wrapper {
  height: 12.5em;
  width: 25em;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: ${({ size }) => size || "20px"};
  width: ${({ size }) => size || "20px"};
  border: 2px solid ${({ theme }) => theme.colors.grayDark} !important;
  cursor: pointer;
  display: flex;
  outline: none;
  align-items: center;
  border-radius:2px;
  justify-content: center;
}
label {
  color: #4c4c4c;
  font-size: 3.4em;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
text{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color:  #000;
}
input[type="checkbox"]:after {
  content: '';
  display: none;
}

input[type="checkbox"]:checked {
  background-color: ${({ theme, toggleColor }) => toggleColor ? toggleColor : theme.colors.primary} ;
  border:none !Important;
  position: relative;
}
input[type="checkbox"]:checked:after {
  display: block;
  content: "";
  position: absolute;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FFFFFF" class="bi bi-check2" viewBox="0 0 16 16"><path stroke="%23FFFFFF" stroke-width="1" d="M5.646 11.854a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L6 10.293l7.646-7.647a.5.5 0 1 1 .700.708l-8 8z"/></svg>');
  background-position: center center;
  background-size: contain;
  width: 70%;
  height: 70%;
}
button.disabled, input[disabled] {
  background-color: #757E89 !important;
  color: black !important;
  font-weight: 700;
  cursor: not-allowed!important;
}
`

const Toggle = ({
    checked,
    setIsChecked,
    toggleColor,
    text,
    placement,
    type,
    toggleWidth,
    toggleHeight,
    height,
    width,
    gap,
    transform,
    handleToggleSave,
    onChange,
    className,
    disabled,
    size
}) => {
    const [toggle, setToggle] = useState(checked);
    const switchPlacement = placement || 'left';
    const color = toggleColor || theme?.colors?.primary;
    
    useEffect(() => {
      setToggle(checked);
  }, [checked]);

    const handleToggleChange = useCallback(() => {
      onChange  && handleToggleSave(checked)
    },[onChange,toggle]);


    return (
        <SwitchStyle placement={switchPlacement} type={type} gap={gap} className={className} >
            {type === 'switch' && (
                <>
                    {switchPlacement === 'left' && (
                        <>
                            <Switch {...{ toggleWidth, toggleHeight, toggleColor }}>
                                <Input
                                    {...{ color, transform }}
                                    type="checkbox"
                                    checked={toggle}
                                    onChange={handleToggleChange}
                                />
                                <Slider {...{ toggle, color, width, height }} />
                            </Switch>
                            <Text>{text}</Text>
                        </>
                    )}
                    {switchPlacement === 'right' && (
                        <>
                            <Text>{text}</Text>
                            <Switch {...{ toggleWidth, toggleHeight, toggleColor }}>
                                <Input
                                    {...{ color, transform }}
                                    type="checkbox"
                                    checked={toggle}
                                    onChange={handleToggleChange}
                                />
                                <Slider {...{ toggle, color, width, height }}  />
                            </Switch>
                        </>
                    )}
                </>
            )}

        {
          type === "checkbox" && (
            <CheckboxStyle toggleColor={toggleColor} theme={theme} size={size}>
              <label className="custom-checkbox-label" style={{ display: 'flex', gap: gap || '8.33px', cursor: 'pointer', alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="check"
                  checked={toggle}
                  onChange={onChange}
                  disabled={disabled} 
                />
                <text for='check'>{text}</text>
              </label>
            </CheckboxStyle>
          )
        }
      {['radio'].includes(type) && (
        <label style={{ display: 'flex', gap: gap || '8.33px', cursor: 'pointer' }}>
          <input
            type={type}
            checked={toggle}
            onChange={onChange}
            style={{ width: '15px', height: '15px', accentColor: color }}
          />
          <Text>{text}</Text>
        </label>
      )}
        </SwitchStyle>
    );
};

export default Toggle;