import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

const PantryDateStyle = styled.div`
.calendar{
  position: relative;
  right: 27px;
  top: 32px;
  cursor:pointer;
}

&.isLogMealsModal {
   margin-bottom: 16px;
   padding: 0;
   display: flex;
   gap:6px;
   place-content: center;
   .react-datepicker-wrapper {
     display: flex;
     align-items: center;
   }
   .current-date {
     color: #000A2C;
     font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 3px;
   }
 }
 .react-datepicker__day--outside-month{
  color:#E0E4E8 !Important;
}
   .react-datepicker-wrapper {
     display: flex;
     align-items: center;
   }
 &.notSelected .react-datepicker__month .react-datepicker__day--selected,
 &selected .react-datepicker__month .react-datepicker__day--today {
   // background-color: #f0faeb;
   color: #fff;
   font-weight: 400;
 }
 .react-datepicker__month .react-datepicker__day.future-day {
   color: #aaa;
 }
 .calendar-icon{
  width: 20px;
  height: 20px;
 }
 .react-datepicker-wrapper {
   width: fit-content;
 }
 .current-date {
   color: #000000;
   font-size: 16px;
   font-family: Roboto;
   font-weight: 700;
   cursor: pointer;
 }
 .react-datepicker,
 .react-datepicker__month-container {

   font-family: "Helvetica Neue",helvetica,arial,sans-serif;
   font-size: .8rem;
   background-color: #fff;
   color: #000;
   border-radius: 0.3rem;
   display: inline-block;
  left: 60px;
  width: 250px;

  .react-datepicker__week{
      display:flex;
      height: 34px;
  }
 .react-datepicker__navigation {
   -webkit-align-items: center;
   align-items: center;
   background: none;
   display: -webkit-flex;
   display: flex;
   -webkit-justify-content: center;
   justify-content: center;
   text-align: center;
   cursor: pointer;
   position: absolute;
   top: 2px;
   padding: 0;
   border: none;
   z-index: 1;
   height: 41px;
   width: 23px;
   text-indent: -999em;
   overflow: hidden;
 }
 }
 .react-datepicker__navigation-icon--next {
   left: -10px;
 }
 .react-datepicker__navigation-icon--previous {
  left: 10px;
    justify-content: center;
 }
 .react-datepicker-popper {
   width: 300px;
   z-index: 100;
  padding-top: 11px !important;
 
 }
 .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
   border-bottom-color: #fff;
 }
 .react-datepicker__tab-loop{
  position: absolute;
 }
 
 .react-datepicker {
   border: 0;
   box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
   transform: translate3d(-18%, 0px, 0px);
    font-family: 'Gilroy';
   .react-datepicker__triangle::before,
   .react-datepicker__triangle::after {
      border-bottom-color: #fff;
     transform: translate3d(5px, 0px, 0px);
     visibility:hidden;
   }

   
 .react-datepicker__header {

   text-align: center;
   background-color: #fff;
   border-bottom: none;
   border-top-left-radius: 0.3rem;
   padding: 12px 0 0px;
   position: relative;
}
   .react-datepicker__current-month {
     margin-top: 0;
     color: #000;
     font-weight: 600;
     font-size: 14px;
     line-height: 20px;
     font-family: 'Gilroy';
   }
   .react-datepicker__day-names {
     margin: 0px 6px -6px 6px;
     padding-top: 12px;
     color: #757E89;
     font-size: 12px;
     font-weight: 500;
     line-height: 12px;
     display:flex;
   }
 }
 .react-datepicker__day-name {
   width: 32px;
   height: 25px;
   color: #757E89;
   margin: 5px;
   font-weight: 500;
 }
 .react-datepicker__navigation-icon::before {
   width: 8px;
   height: 8px;
  
 }
 .react-datepicker__navigation-icon::before {
   border-width: 2px 2px 0 0;
 }
 .react-datepicker__month {
   margin: 6px;
   .react-datepicker__day,
   .react-datepicker__day--disabled,
   .react-datepicker__day--keyboard-selected,
   .react-datepicker__day--selected {
      width: 25px;
      height: 24.59px;
      border-radius: 100px;
      margin: 5px;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
   .react-datepicker__day,
   .react-datepicker__day--selected.react-datepicker__day--outside-month,
   .react-datepicker__day--keyboard-selected {

  
   }
   .react-datepicker__day--outside-month {
   }
 }
 .react-datepicker__navigation--previous {
   left: auto;
   // right: 60px;
 }
}
.react-datepicker__year-text{
  color:000A2C;
  width: 25px;
  height: 24.59px;
  border-radius: 100px;
}
.react-datepicker__day--selected{
  background-color: #00092D;
}
.react-datepicker__quarter-text{
  color: #000A2C;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
  color: #E0E4E8;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color:#00092D;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: #00092D;
}
`;
  
export default function PantryDate({ setActiveDay, activeDay, isLogMealsModal }) {

  const day = useMemo(() => {
    const formatStr = 'MMM DD, YYYY';
    const formattedDay = moment(activeDay).format(formatStr);
    // setActiveDay(activeDay);
    if (formattedDay === moment().format(formatStr)) {
      return 'Today';
    } else if (formattedDay === moment().subtract(1, 'days').format(formatStr)) {
      return 'Yesterday';
    } else {
      return formattedDay;
    }
  }, [activeDay]);
  const ExampleCustomInput = <div className="current-date">{day}</div>;
  const [isSelected, setIsSelected] = useState(false);
  const dateRef=useRef()

  const handleChange = (date) => {
    setActiveDay(new Date(date));
    setIsSelected(true);
  }

  const handleIconClick=()=>{
    dateRef.current.setOpen(true)
  }

  return (
    <PantryDateStyle className={classNames({selected: isSelected}, {notSelected: !isSelected}, {isLogMealsModal: isLogMealsModal})}>
            {isLogMealsModal && <img src="/icons/calendar.svg" alt="Calendar icon" className="calendar-icon" onClick={handleIconClick}/>}

      <DatePicker
        popperPlacement="bottom"
        selected={new Date(activeDay)}
        onChange={(date) => handleChange(date)}
        customInput={ExampleCustomInput}
        ref={dateRef}
        minDate={moment().subtract(2,'days').toDate()}
        maxDate={moment().toDate()}
        dayClassName={(date) => {
        return moment(date, "YYYY-MM-DD").isBetween(moment().subtract(3,'days').format("YYYY-MM-DD")) ?"": 'future-day'      
        }}
      />
    </PantryDateStyle>
  );
}
