import styled from 'styled-components';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import BackButton from 'components/BackButton';
import { useLocation } from 'react-router-dom';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShopStepsStyle = styled.div`
  @media (min-width: 992px) {
    width: 30% !important;
    margin: auto;
    margin-top: 20px;
  }
  .step-header {
    margin-bottom: 15px;

    .title-wrapper {
      position: relative;
      ${FlexCenter};
    }

    h2 {
      margin-bottom: 5px;
      color: #0b1d17;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      text-align: center;
    }

    .description {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      color: #949d9b;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      justify-content: center;
    }
  }

  .MuiStepper-root {
    margin-bottom: 15px;

    .MuiStep-root:first-child:before,
    .MuiStep-root:last-child:after {
      content: '';
      position: absolute;
      top: 10px;
    }

    .MuiStep-root:first-child:before {
      left: 0;
      right: 50%;
      border-top: 4px solid #52c41a;
      border-radius: 2px 0 0 2px;
    }

    .MuiStep-root:last-child:after {
      right: 0;
      left: 50%;
      border-top: 4px solid #e0e3e0;
      border-radius: 0 2px 2px 0;
    }

    .MuiStepConnector-root {
      .MuiStepConnector-line {
        border-color: #52c41a;
      }

      &.Mui-disabled .MuiStepConnector-line {
        border-color: #e0e3e0;
      }
    }

    .MuiStepLabel-root {
      .MuiSvgIcon-root {
        color: #52c41a;

        .MuiStepIcon-text {
          fill: #fff;
        }
      }

      &.Mui-disabled .MuiSvgIcon-root {
        color: #e0e3e0;

        .MuiStepIcon-text {
          fill: #51635d;
        }
      }
    }

    .MuiSvgIcon-root {
      z-index: 3;
      width: 22px;
      height: 22px;

      &.Mui-completed {
        background-color: #fff;
        color: #52c41a;
        border-radius: 50%;
      }

      .MuiStepIcon-text {
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .MuiStepConnector-root {
      width: 100%;
      left: -50%;
      right: 50%;
      top: 10px;

      .MuiStepConnector-line {
        border-top-width: 4px;
      }
    }

    .MuiStepLabel-label {
      margin-top: 6px !important;
      color: #51635d;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
      text-align: center;
    }

    .MuiStepLabel-label.Mui-active {
      font-family: 'Roboto';
      font-weight: 700;
    }

    .MuiStepLabel-label.Mui-completed {
      font-weight: 400;
    }

    .MuiStep-root {
      padding: 0 !important;
    }

    .MuiStepLabel-label.Mui-disabled {
      color: #949d9b;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column-reverse;

    .step-header {
      margin-bottom: 0;

      .title-wrapper {
        position: static;
      }
    }

    .MuiStepper-root {
      width: 335px;
      margin: 0 auto 32px;
    }
  }
`;

export default function ShopSteps({ title, description, activeStep, steps, perviousLink }) {
  const {pathname }= useLocation()
  return (
    <ShopStepsStyle>
      <div className="step-header">
        <div className="title-wrapper">
          {perviousLink && <BackButton perviousLink={perviousLink} />}
          <h2>{title}</h2>
        </div>
        {description && <div className="description">{description}</div>}
      </div>
      {steps && (
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
         )}
    </ShopStepsStyle>
  );
}

