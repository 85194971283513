import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { Circle } from 'rc-progress';
import {toast} from "react-toastify";

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 120;
  background-color: #fff;
  flex-direction: column;
  ${FlexCenter};

  .content {
    width: 275px;
    margin-top: 23px;
    color: #51635d;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  ${FlexCenter};

  p:nth-child(1) {
    top: 48%;
    left: 52%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 38px;
    font-weight: bold;
  }

  p:nth-child(2) {
    top: 64%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    font-size: 20px;
    color: #939b99;
  }
  .pentry-circle-wrapper {
    display: flex;
    justify-content: center;
    height:300px;
  }
  .pentry-circle {
    position: relative;
    width: 180px;
    display: grid;
    justify-items: center;
    align-items: center;
  }
`;

const Analyzing = ({ onNext,setIsProfileUpdateLoading , questions, setActive }) => {
  const [percentage, setPercentage] = useState(0);
  const { setProfile,isVisitor, profile ,isGuest,nutritionistId,setupLocalUser} = useAppContext();
  const [updateProfile, { isLoading, error, setError }] = useRequest('profile', 'post');
  const [updateGuestProfile]=useRequest("guest/update-profile", 'post');
  const [getProfile] = useRequest('profile');

  useEffect(() => {
    runCalculation();
    let timer = setInterval(() => setPercentage((prevState) => prevState + 1), 30);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const runCalculation = async () => {
    try {

      if (isGuest || isVisitor) {
       const res= await updateGuestProfile({
          body: { ...profile, ...questions ,nutritionistId},
        });
        if(res.success ) {
        const profileRes = await getProfile();
        await setProfile(profileRes);
        await setIsProfileUpdateLoading(false)
      }
      } else {
        const res = await updateProfile({
          body: { ...profile, ...questions },
        })
        if(res.success){
          const profileRes = await getProfile();
          await setProfile(profileRes);
          await setIsProfileUpdateLoading(false)
        }
      }
    
    } catch (error) {
      console.log(`updateProfile error:`, error);
      toast.error(error)
      setIsProfileUpdateLoading(false)
      setActive(0)
    }
  };
  useEffect(() => {
    if (percentage >= 100) {
      onNext();
      console.log('Calculation is done');
    }
  }, [percentage, setPercentage]);

  return (
    <Wrapper>
      <ProgressBar>
        <p>{percentage}%</p>
        <p>LEFT</p>
        {/* <CircularProgressbar
          strokeWidth={8}
          value={percentage}
          styles={{
            root: {},
            path: {
              stroke: `#52C41A`,
              strokeLinecap: 'round',
              transition: 'stroke-dashoffset 0.01s ease 0s',
              // transition: "stroke-dashoffset 0.5s ease 0s",
              transform: 'rotate(1turn)',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#CBEDBA',
              strokeLinecap: 'round',
              transform: 'rotate(0.25turn)',
              transformOrigin: 'center center',
            },
            text: {
              fill: 'black',
              fontSize: '18px',
            },
          }}
        /> */}
        <div className="pentry-circle-wrapper">
          <div className="pentry-circle">
             <Circle
                percent={percentage}
                strokeWidth="8"
                strokeColor="#52C41A"
                trailWidth="8"
                trailColor="#52C41A4F"
              />
              </div>
              </div>

      </ProgressBar>
      <p className="content">I'm customizing your daily intake based on your BMI...</p>
    </Wrapper>
  );
};

export default Analyzing;
