import { memo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Progress from 'components/Progress';
import {toast} from "react-toastify";

import 'animate.css';

const CreateInviteModalWrapper = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9;
  --animate-duration: 500ms;
  .shade {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #51635d;
    opacity: 0.5;
  }
  .modal_wrapper {
    width: 100%;
    max-width: 370px;
    padding: 30px 48px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    position: relative;
    z-index: 2;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #51635d;
      margin-bottom: 16px;
    }
    .form_group {
      margin-bottom: 24px;
      label {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635d;
        padding: 9px 0;
        display: block;
      }
      .form_control {
        padding: 9px 10px 9px 14px;
        background: #ffffff;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 16px;
        color: #51635d;
        width: 100%;
        height: 44px;
        display: block;
        &:disabled {
          background-color: #fff !important;
          color: #51635d !important;
          cursor: auto !important;
        }
      }
    }
    .btn {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      padding: 12px;
      width: 100%;
      border-radius: 50px;
      cursor: pointer;
      transition: all 0.2s;
      color: #51635d;
      background-color: #fff;
      border: 1px solid #97dc76 !important;
      &.save_btn {
        color: #ffffff;
        background-color: #52c41a !important;
        margin-bottom: 10px;
        &:disabled {
          background-color: #c3c3c3 !important;
          border: 1px solid #c3c3c3 !important;
          cursor: not-allowed;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .modal_wrapper {
      max-width: 327px;
    }
  }
`;

function CreateInviteModal({
    title,
    label,
    inputType,
    buttonText,
    isActive,
    setIsActive,
    value,
    setValue,
    isLoading,
    handleSubmit,
    isLoding
}) {
    const inputRef = useRef();

    const handleClose = useCallback(
        (e) => {
            e.preventDefault();
            setIsActive(false);
        },
        [isActive],
    );

    useEffect(() => {
        inputRef.current.focus();
    }, []);

  const toastId = useRef(null);
  let isErrorToastDisplayed = false;

  const handleChange = useCallback((e) => {
    const trimValue = title === "Create provider" ? e.target.value :  e.target.value.replace(/\s/g, "");
    const containsOnlySpecialChars = /^[^a-zA-Z0-9]+$/.test(trimValue);

    if (toast.isActive(toastId.current)) {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
    isErrorToastDisplayed = false;
    if (containsOnlySpecialChars && !isErrorToastDisplayed) {
      if (toast.isActive(toastId.current)) {
        toast.dismiss(toastId.current); // Dismiss previous error message
      }
      toastId.current = toast.error(
        "Please enter a valid name."
      );
      isErrorToastDisplayed = true;
      return;
    }
    setValue(trimValue);
  }, []);
  

    return (
        <>
            <CreateInviteModalWrapper className="animate__animated animate__fadeIn">
                <div className="shade" onClick={handleClose} />
                <div className="modal_wrapper">
                    <form onSubmit={handleSubmit}>
                        <h2 className="title">{title}</h2>
                        <div className="form_group">
                            <label htmlFor={label}>{label}</label>
                            <input
                                ref={inputRef}
                                type={inputType}
                                id={label}
                                name={label}
                                onChange={handleChange}
                                value={value}
                                className="form_control"
                            />
                        </div>
                        <button disabled={!value || isLoding} type="submit" className="btn save_btn">
                            {buttonText}
                        </button>
                        <button className="btn cancel_btn" onClick={handleClose}>
                            Cancel
                        </button>
                    </form>
                    {isLoading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <Progress />
                        </div>
                    )}
                </div>
            </CreateInviteModalWrapper>
        </>
    );
}

export default memo(CreateInviteModal);