import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useNutritionistContext } from 'context/nutritionistContext';
import { useAppContext } from 'context/appContext';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectTimeWrapper = styled.div`
  .title {
    margin-bottom: 14px;
    color: #51635d;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 14px;
    font-weight: 700;
  }

  .time-wrapper + .time-wrapper {
    margin-top: 10px;
  }

  .time {
    height: 40px;
    color: #51635d;
    border: 1px solid #97dc76;
    border-radius: 21px;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
    cursor: pointer;
    ${FlexCenter};
  }

  .time-wrapper.selected {
    display: flex;

    .time,
    button {
      width: 100%;
    }

    .time {
      margin-right: 7px;
      background-color: #f0faeb;
      border: 1px solid #cbedba;
    }

    button {
      height: 40px;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      ${FlexCenter};
    }
  }

  @media(min-width: 992px){
    .primary-btn{
      padding: 0px !important;
    }
    .times{
      display: grid !important;
      grid-template-columns: 49% 49% !important;
      gap: 2% !important;
      align-items: end !important;
      width:100%;
      max-height: 337px;
      overflow-y: auto;

      .time-wrapper.selected {
        display: grid;
        grid-template-columns: auto auto;
        gap: 8px;
        .time{
          margin-right: 25px !important;
        }
        button {
          font-size: 13px !important;
        }
      }
    }
  }
`;

export default function SelectTime({ selectedDate, availableSlots, selectedTimezone }) {
  const [timeIndex, setTimeIndex] = useState();
  const {user ,guestId, selectedGuestOfferDetails , updateSelectedGuestOfferDetails, isMobile } = useAppContext()
  const {selectedOfferDetails, updateSelectedOfferDetails} = useNutritionistContext();
  const history = useHistory();
// let paymentUrl = selectedOfferDetails?.clientEmail || user ? '/booking/payment' : '/guest/info' 

 let paymentUrl 
  if(selectedOfferDetails?.clientEmail == 'undefined' ) {
   paymentUrl = '/guest/info' 
  } else {
    paymentUrl = '/booking/payment'
  }

  return (
    <SelectTimeWrapper>
      <div className="title">Select a time</div>
      <div className="times">
      {availableSlots.map((time, index) => (
        <div key={index} className={classNames('time-wrapper', { selected: time.value === timeIndex })}>
          <div
            className="time"
            onClick={() => {
              setTimeIndex(time.value);
            }}
          >
            {time.label}
          </div>
          {time.value === timeIndex && (
            <button
              className="primary-btn"
              onClick={ () => {
                selectedOfferDetails?.clientEmail == 'undefined'  ? updateSelectedGuestOfferDetails({...selectedGuestOfferDetails, selectedDate: time.value, selectedTimezone}) :
                 updateSelectedOfferDetails({...selectedOfferDetails, selectedDate: time.value, selectedTimezone}) 
                  history.push(paymentUrl);
                  window.scrollTo(0, 0);
              }}
            >
              {isMobile? "Confirm Time" : "Confirm time"}
            </button>
          )}          
        </div>
      ))}
      </div>
    </SelectTimeWrapper>
  );
}
