import React, { useEffect, useState, useRef, useCallback,useMemo } from 'react';
import OrderCard from '../components/OrderCard';
import styled from 'styled-components';
import RegularInput from 'components/Input/RegularInput';
import { BottomSheet } from 'react-spring-bottom-sheet';
import SortSheet from 'pages/KitchenMobile/components/SortSheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { ReactComponent as SearchIcon } from 'assets/kitchen/search.svg';
import { ReactComponent as FilterIcon } from 'assets/kitchen/filter.svg';
import { theme } from 'shared/theme/theme';
import { motion, AnimatePresence } from 'framer-motion';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import OrderDetails from './OrderDetails';
import InfiniteScroll from 'react-infinite-scroll-component';
import OrderAlert from 'pages/KitchenMobile/components/OrderAlert';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import classNames from 'classnames';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const OrderStyled = styled.div`
  width: 100%;
  .search_input {
    position: relative;
    padding: 8px 13px 8px 14px;
    input {
      height: 40px;
      padding-right: 50px;
      border: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
      font-weight: 500;
      font-family: inherit;
    }
    .search-input-mask {
      position: fixed;
      inset: 0;
      z-index: 112;
    }
    .input-wrapper {
      svg {
        display: block;
      }
    }
    .searchInputIndex .input-wrapper {
      z-index: 113;
    }
    .filter-icon-wrapper {
      position: absolute;
      right: auto;
      top: auto;
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sort-sign {
      position: absolute;
      right: 19px;
      top: 19px;
      z-index: 112;
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme?.colors?.primaryDarker};
      color: ${({ theme }) => theme?.colors?.secondary};
      border-radius: 50%;
      font-size: 6px;
      font-weight: 700;
      line-height: 10px;
      letter-spacing: 0.3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nav_tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
    gap: 10px;
    padding: 0 14px;
    overflow: auto;
    justify-content: flex-start;
    &::-webkit-scrollbar {
      display: none;
    }
    .nav {
      flex: 0 0 93px;
      height: 42px;
      min-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: ${({ theme }) => theme?.colors?.primaryDefault};
      &.active .MuiTab-wrapper {
        color: ${({ theme }) => theme?.colors?.primaryDarker};
        font-weight: 600;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          bottom: 0;
          background: ${({ theme }) => theme?.colors?.primaryDarker};
        }
      }
      .MuiTabs-scroller,
      .MuiTabs-flexContainer,
      .MuiButtonBase-root {
        width: 93px;
        height: 42px;
        min-height: 42px;
      }
      .MuiTab-textColorInherit {
        opacity: 1;
      }
      .MuiTab-wrapper {
        color: ${({ theme }) => theme?.colors?.primaryDefault};
        font-family: ${({ theme }) => theme?.typography?.fontGilroy};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        font-weight: 500;
        line-height: 20px;
        letter-spacing: normal;
      }
    }
    &.autoAccept {
      justify-content: center;
      gap: 0;
      .nav {
        flex: 0 0 125px;
      }
    }
  }
  .overflow_container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 217px);
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
    .order_card {
      &:last-child {
        .border {
          display: none;
        }
      }
    }
    .infinite-scroll-component__outerdiv {
      overflow: hidden;
      .infinite-scroll-component {
        overflow-x: hidden !important;
      }
    }
  }
`;

const OrdersTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.autoAcceptOrders .nav_tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .MuiTabs-scroller,
    .MuiTabs-flexContainer,
    .MuiButtonBase-root {
      width: 100%;
    }
  }
  .order-details-motion {
    position: fixed;
    inset: 0;
    z-index: 112;
    background-color: ${theme.colors.secondary};
  }
  .order-alert-container {
    position: fixed;
    bottom: 94px;
    left: auto;
    z-index: 120;
  }
  .order-card-wrapper {
    height: 112px;
    &:last-child .order-card-container {
      border-bottom: 0 
    }
  }
  .orderCardIndex {
    position: relative;
    z-index: 113;
  }
  .order-details-mask {
    position: fixed;
    inset: 0;
    z-index: 9;
  }
`;

const Orders = ({ selectedTabId, setSelectedTabId, setIsOrderStatusChanged, sortBy, setSortBy,activeTab, setSort, sort, isTransition, setIsTransition }) => {
  const { isSuper, user: {provider: {BusinessProfileSettings}}, isWebView, isPlatformIOS } = useAppContext();
  const [orderDataList, setOrderDataList] = useState({pending: {}, accepted: {}, completed:{}, refused: {}});
  const orderDataListRef = useRef({pending: {}, accepted: {}, completed:{}, refused: {}})

  const [selectedOrder, setSelectedOrder] = useState({});
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAlert, setIsAlert] = useState(false);
  const alertRef = useRef(isAlert);
  const [status, setStatus] = useState('');
  const [acceptSelectedOrder,] = useRequest(`fulfill`, 'post');
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState();
  const selectedOrderIdRef = useRef();
  const selectedAllOrderIdRef = useRef([]);
  const [fetchOrderData,{isLoading}] = useRequest();
  const [noOrders,setNoOrders] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [isOrderLoading, setIsOrderLoading] = useState(isLoading);
  const [heightOrdersWrapper, setHeightOrdersWrapper] = useState();
  const [heightOrders, setHeightOrders] = useState();
  const [isSearchInputMask, setIsSearchInputMask] = useState(false);
  const searchRef = useRef();

  const navTabs = BusinessProfileSettings[0]?.autoAcceptOrder ? ['Accepted', 'Completed', 'Rejected'] : ['New', 'Accepted', 'Completed', 'Rejected'];

  const fetchOrders =  useCallback(async (selectedPageNumber, prevOrderDataList)=> {
    try {
      const path= `provider/orders?pageSize=100&action=${activeTab}&pageNumber=${selectedPageNumber}${orderNumber ? `&orderNumber=${orderNumber}` : ''}${sortBy && sort ? `&${sortBy}=${sort}` : ''}${isSuper ? `&providerId=${imitateProviderId}` : ''}`
      const { data } = await fetchOrderData({ path }, 'get');
      // if (prevOrderDataList[activeTab]?.orders === undefined) setIsOrderLoading(true);
      setOrderDataList(
        {...orderDataList, [activeTab]: {...(!!prevOrderDataList[activeTab]?.orders ? {...data, orders: [...prevOrderDataList[activeTab]?.orders, ...data.orders]}: data)}}
      )
      orderDataListRef.current = {...orderDataListRef.current, [activeTab]:  {...(!!prevOrderDataList[activeTab]?.orders ? {...data, orders: [...prevOrderDataList[activeTab]?.orders, ...data.orders]}: data)}};
      setTimeout(() => {setNoOrders(data.orders.length === 0)}, 200) 
    }
    catch (error) {
      console.log('e', error)
      if (orderNumber && orderNumber?.length > 3) setNoOrders(true);
    }  
  }, [activeTab, orderNumber, sortBy, sort])

  useEffect(() => { 
    if (orderDataListRef.current[activeTab].orders?.length) {
      setOrderDataList(orderDataListRef.current)
    } 
  }, [orderDataListRef.current,activeTab, orderDataList])

  const loadMoreItems =  async () => {
    if (pageNumber === orderDataList[activeTab]?.totalPages) return;
    setPageNumber(prevState => prevState + 1);
    fetchOrders(pageNumber + 1, orderDataList);
  }

  useEffect(() => {
    isWebView && isPlatformIOS() ? setHeightOrdersWrapper(window.innerHeight - 190) : setHeightOrdersWrapper(window.innerHeight - 118);
    isWebView && isPlatformIOS() ? setHeightOrders(window.innerHeight - 290) : setHeightOrders(window.innerHeight - 217);
  }, [])

  const resetOrders = () => {
    setPageNumber(1); 
    if (!orderNumber) fetchOrders(1, {});
    if (orderNumber.length > 3) { fetchOrders(1, {}); return }
  }  

  useEffect(() => {     
    if (orderNumber) setOrderNumber('');
    setSort();
    setSortBy();
    if (selectedOrderId) setSelectedOrderId('')
    if (isAlert) setIsAlert(() => {alertRef.current = false ; return false});
    setIsOrderLoading(true)
  }, [activeTab])
  
  useEffect(() => { 
    resetOrders();
  }, [sortBy, orderNumber, activeTab])

  useEffect(() => { 
    if (!!orderDataList[activeTab]?.orders) setTimeout(() => {setIsOrderLoading(false)}, 300)
  }, [orderDataList[activeTab]])

  const closeOrderDetails = () => {
    setIsTransition(false);
  };

  const handleInputChange = (e) => { setOrderNumber(e.target.value) }

  const handleSave = (status, providerId, orderId) => {
    // setOrderDataList((prev) => ({...prev, [activeTab]: {...prev[activeTab], orders: prev[activeTab]?.orders?.filter((item) => item?.orderMainId !== orderId)}}))
    try {
      const result = acceptSelectedOrder({
        body: { newStatus: status, providerId, orderIds: [orderId] },
      });
      if (result) {
        setIsOrderStatusChanged((prev) => !prev);
        selectedAllOrderIdRef.current = selectedAllOrderIdRef.current.filter( selected => selected !== orderId)
      }
    } catch (e) {
      console.log(e);
    }
  }
    
  const generateRequest = (status, providerId, orderId) => {
    setSelectedOrderId(orderId);
    selectedOrderIdRef.current = orderId;
    selectedAllOrderIdRef.current = [...selectedAllOrderIdRef.current, orderId];
    closeOrderDetails();
    setIsRejectModal(false);
    status === 'refused' ? setStatus('Rejected') : activeTab === 'pending' ? setStatus('Accepted') : setStatus('Completed');
    setTimeout(() => {
      setIsAlert(() => {alertRef.current = true ;return true});
    }, 300);
    setTimeout(() => {
      if (orderId === selectedOrderIdRef?.current) {
        setIsAlert(() => {alertRef.current = false ; return false});
      }
      if (selectedAllOrderIdRef.current.includes(orderId)) handleSave(status, providerId, orderId);
    }, 5300);
  }

  const generateSort = () => {
    if(isSearchInputMask) return;
    setOpen(true);
  }

  useEffect(() => {
    if(isSearchInputMask) {
      searchRef?.current.focus()
    } else {
      searchRef?.current.blur();
    }
  }, [isSearchInputMask])

  return (
    <OrdersTabWrapper style={{height: heightOrdersWrapper}} className={classNames({autoAcceptOrders: BusinessProfileSettings[0]?.autoAcceptOrder})}>
    <OrderStyled theme={theme} style={{height: heightOrdersWrapper}}>
      <div className="search_input">
        {sortBy && <div className='sort-sign'>1</div>}
        {isSearchInputMask && <div className='search-input-mask' 
          onMouseMove={() => setIsSearchInputMask(false)} 
          onTouchMove={() =>setIsSearchInputMask(false)} />
        }
        <RegularInput
          placeholder={'Search your orders'}
          className={classNames({searchInputIndex: isSearchInputMask})}
          prefix={<SearchIcon />}
          ref={searchRef}
          suffix={<div className='filter-icon-wrapper' onClick={generateSort}><FilterIcon /></div>}
          onChange={handleInputChange}
          value={orderNumber}
          onFocus={() => setIsSearchInputMask(true)}
          onBlur={() => setIsSearchInputMask(false)}
        />
      </div>
      <div className={`nav_tabs ${navTabs?.length > 2 ? '' : 'autoAccept'}`}>
        {navTabs?.map((tab) => (
          <Tabs
            className={`nav ${selectedTabId === navTabs.indexOf(tab) ? 'active' : ''}`}
            onClick={() => {
              setSelectedTabId(navTabs.indexOf(tab));
            }}
          >
             <Tab label={tab} />
          </Tabs>
        ))}
      </div>
      {noOrders ? <p style={{ padding: '19px 16px 12px', textAlign: 'center' }}>No order found</p> 
      : isOrderLoading ? (
        <>
          {Array.from({ length: 10 }, (_, index) => index + 1)?.map((item) => (
            <>
              <Stack style={{ padding: '19px 16px 12px' }}>
                <Skeleton variant="text" width={35} height={16} />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Skeleton variant="text" width={140} height={24} />
                  <Skeleton variant="text" width={40} height={16} />
                </div>
                <Skeleton variant="text" width={90} height={16} />
                <Skeleton variant="text" width={210} height={22} />
              </Stack>
              <Skeleton variant="rectangle" height={1} />
            </>
          ))}
        </>
      ) : (
          <AnimatePresence>
          <div className="overflow_container" style={{height: heightOrders}}
            onDrag={(e) => {e.stopPropagation(); e.preventDefault()}}
          >
            {navTabs[selectedTabId] === 'New' && !orderNumber ? (
                <InfiniteScroll
                  dataLength={orderDataList[activeTab]?.orders?.length || 1}
                  next={loadMoreItems}
                  hasMore={pageNumber < orderDataList[activeTab]?.totalPages}
                  height={heightOrders}
                >
                    {orderDataList[activeTab]?.orders?.map((data) => {
                      if (data?.providers[0]?.providerSubTotal) {
                      return (
                        <OrderCard
                          activeTab={activeTab}
                          key={data?.orderMainId}
                          setOrderDataList={setOrderDataList}
                          orderNumber={orderNumber}
                          setSelectedOrder={setSelectedOrder}
                          setIsOrderDetails={setIsTransition}
                          selectedOrder={selectedOrder}
                          selectedOrderId={selectedOrderId}
                          setSelectedOrderId={setSelectedOrderId}
                          selectedOrderIdRef={selectedOrderIdRef}
                          selectedAllOrderIdRef={selectedAllOrderIdRef}
                          isAlert={isAlert}
                          setIsAlert={setIsAlert}
                          alertRef={alertRef}
                          generateRequest={generateRequest}
                          {...data}
                        />
                        )}
                    })}
                </InfiniteScroll>
            ) : (
              <InfiniteScroll
                dataLength={orderDataList[activeTab]?.orders?.length || 1 }
                next={loadMoreItems}
                hasMore={pageNumber < orderDataList[activeTab]?.totalPages}
                height={heightOrders}
                className={classNames({orderCardIndex: isSearchInputMask})}
              >
                {orderDataList[activeTab]?.orders?.map((data) => {
                  if (data?.providers[0]?.providerSubTotal) {
                    return (
                    <OrderCard
                      activeTab={activeTab}
                      key={data?.orderMainId}
                      setOrderDataList={setOrderDataList}
                      orderNumber={orderNumber}
                      setSelectedOrder={setSelectedOrder}
                      setIsOrderDetails={setIsTransition}
                      selectedOrder={selectedOrder}
                      selectedOrderId={selectedOrderId}
                      setSelectedOrderId={setSelectedOrderId}
                      selectedOrderIdRef={selectedOrderIdRef}
                      selectedAllOrderIdRef={selectedAllOrderIdRef}
                      isAlert={isAlert}
                      setIsAlert={setIsAlert}
                      alertRef={alertRef}
                      generateRequest={generateRequest}
                      {...data}
                    />
                  )}}
                )}
              </InfiniteScroll>
            )}
          </div>
          </AnimatePresence>
        )}
      </OrderStyled>
      {isTransition && <div className='order-details-mask' />}
      <AnimatePresence>
        {isTransition && (
          <motion.div
            initial= {{ x: window.innerWidth + 100 }}
            exit= {{ x: window.innerWidth + 100 }}
            animate={{ x: 100 }}
            transition={{
              duration: 0.3,
              delay: 0,
              ease: 'linear',
              x: {
                type: "spring",
                stiffness: 40,
              }
            }}
            className='order-details-motion'
          >
            <OrderDetails
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              status={status}
              setStatus={setStatus}
              isRejectModal={isRejectModal}
              setIsRejectModal={setIsRejectModal}
              closeOrderDetails={closeOrderDetails}
              generateRequest={generateRequest}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <BottomSheet
          open={open}
          onDismiss={() => setOpen(false)}
          scrollLocking={false}
          expandOnContentDrag={true}
          snapPoints={({ maxHeight }) => [maxHeight * 0.80]}
          className="sortSheet-bottomSheet"
        >
          <SortSheet setOpen={setOpen} setSortBy={setSortBy} setSort={setSort} sortBy={sortBy} />
      </BottomSheet>
      <AnimatePresence>
        {isAlert && (
          <>
            <OrderAlert 
              status={status.toLowerCase()}
              setIsAlert={setIsAlert}
              ref={alertRef}
              setSelectedOrderId={setSelectedOrderId}
              selectedOrderIdRef={selectedOrderIdRef}
              selectedAllOrderIdRef={selectedAllOrderIdRef}
              selectedOrderId={selectedOrderId}
            />
          </>
        )}
      </AnimatePresence>
    </OrdersTabWrapper>
  );
};

export { Orders };
