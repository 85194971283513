import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { ReactComponent as Tip } from 'icons/tip.svg';
import PantryDietProgress from './PantryDietProgress';

const MealPlanDurationWrapper = styled.div`
flex: 0 0 80%;
  .meal-plan-progress {
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    height:70px;
  }

  .nutritionist-img-wrapper {
    position: relative;
    margin-right: 10px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .icon-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      background-color: #ff9a10;
      border: 1px solid rgba(255, 255, 255, 0.31);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default function MealPlanDuration({ userProgress }) {
  const {
    nutritionist: { img },
  } = useAppContext();
  const history = useHistory();

  return (
    <MealPlanDurationWrapper>
      <div className="meal-plan-progress">
        {/* <div className="nutritionist-img-wrapper">
          <img src={img || '/images/avatar.png'} alt="Nutritionist Photo" />
          <div className="icon-wrapper">
            <Tip />
          </div>
        </div> */}
        <PantryDietProgress userProgress={userProgress} />
      </div>
    </MealPlanDurationWrapper>
  );
}
