import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const FooterDesktopWrapper = styled.footer`
  background-color: #f5f7f8;
  .footer-container {
    max-width: 1254px;
    margin: 0 auto;
    padding: 60px 25px 22px;
    color: ${theme.colors.primaryDarker};
    display: flex;
    flex-direction: column;
    gap: 66px;
  }
  .footer-links-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .logo-wrapper {
    width: 311px;
    margin: -5px 43px 5px 0;
    .nutritt-brand {
      width: 111px;
      height: 48px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .slogan {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
    }
  }
  .follow-wrapper {
    width: 240px;
    margin-right: 64.33px;
    .social-media-wrapper {
      margin-bottom: 29px;
      display: flex;
      align-items: center;
      gap: 12px;
      .icon-wrapper {
        display: flex;
      }
    }
    .app-download {
      display: flex;
      align-items: center;
      gap: 7px;
    }
  }
  .support-wrapper {
    width: 169.39px;
    margin-right: 134.27px;
  }
  .footer-title {
    margin-bottom: 9px;
    color: ${theme.colors.grayDarker};
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }
  .footer-info {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: ${theme.colors.primaryDarker};
  }
  .footer-copyright-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .copyright {
      color: ${theme.colors.mediumGray};
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
    }
    .terms-links {
      display: flex;
      align-items: center;
      gap: 26px;
      .link {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: ${theme.colors.primaryDarker};
      }
    }
  }
  @media (max-width: 1280px) {
    .footer-container {
      max-width: 100%;
    }
    .logo-wrapper,
    .support-wrapper {
      width: max-content;
    }
  }
  @media (max-width: 991px) {
    padding: 32px 24px;
    font-family: Gilroy;
    .footer-container {
      padding: 0;
      gap: 48px;
    }
    .footer-links-wrapper {
      display: grid;
      grid-template-areas:
        'logo logo'
        'follow follow'
        'support about';
      .logo-wrapper {
        grid-area: logo;
      }
      .follow-wrapper {
        grid-area: follow;
      }
      .support-wrapper {
        grid-area: support;
      }
      .about-wrapper {
        grid-area: about;
        width: 57px;
        margin-bottom: 32px;    
      }
    }

    .logo-wrapper {
      width: auto;
      margin: 0 0 48px;
      .nutritt-brand {
        margin: 0 0 5px -10px;
      }
      .slogan {
        margin: 0;
      }
    }

    .follow-wrapper {
      width: auto;
      margin: 0 0 48px;
      .social-media-wrapper {
        margin-bottom: 32px;
      }
      .app-download {
        a {
          display: flex;
        }
      }
    }

    .support-wrapper {
      width: 169.397px;
      margin-right: 9.6px;
    }

    .footer-copyright-wrapper {
      display: flex;
      flex-direction: column;
      flex-flow: column-reverse;
      align-items: flex-start;
      gap: 14px;
      .copyright {
        color: ${theme.colors.mediumGrey};
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px){
    .footer-links-wrapper{
      grid-template-columns: repeat(3,2fr);
      height: 100%;
      grid-row-gap: 62px;
    }
    .logo-wrapper, .mobile-app-wrapper, .follow-wrapper {
      grid-column: span 1;
    }
    .support-wrapper, .about-wrapper{
      grid-column: span 1;
      transform: translateX(260px);
      margin-bottom: 48px;
    }
  }
`;
