import { memo } from 'react';
import { Line } from 'rc-progress';

import { ProgressWrapper } from './style';

function Progress({
  width, // number
  label, // string
  value,
  percent, // number
  strokeWidth, // number
  strokeColor, // string
  trailWidth, // number
  trailColor, // string
}) {
  return (
    <ProgressWrapper style={{ width: `${width}px` }}>
      <div className="progress-info">
        <div className="progress-label">{label}</div>
        {value && (
          <div className="progress-value">
            {(value === '0g' && percent === '0') ? '-g' : value} {percent !== '0' && <span className="progress-percent">({percent}%)</span>}
          </div>
        )}
      </div>
      <Line
        percent={percent}
        strokeWidth={strokeWidth}
        strokeColor={strokeColor}
        trailWidth={trailWidth}
        trailColor={trailColor}
      />
    </ProgressWrapper>
  );
}

export default memo(Progress);
