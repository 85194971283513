import styled from 'styled-components';
import Dropzone from 'react-dropzone-uploader';
import { RiUploadCloudFill } from 'react-icons/ri';
import 'react-dropzone-uploader/dist/styles.css';

const DropzoneStyle = styled.div`
  .dzu-dropzone {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='1' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border: 0;
    border-radius: 4px;
  }

  label.dzu-inputLabel {
    margin-bottom: 0 !important;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 8px;
      color: #52c41a;
      font-size: 33px;
    }

    p {
      font-size: 14px;
      color: #0b1c18;

      span {
        color: #52c41a;
      }
    }
  }

  .dzu-previewContainer {
    padding: 20px 0;
    border: 0;

    progress {
      width: 250px;
      height: 7px;
      margin-right: 20px;
      background-color: #52c41a;

      &::-webkit-progress-bar {
        background-color: #cfedc1;
      }

      &::-webkit-progress-value {
        background-color: #52c41a;
        border-radius: 100px;
      }
    }

    span {
      background-size: 10px;
    }
  }

  @media (max-width: 767px) {
    .dzu-dropzone {
      width: 99%;
      margin: 0 auto;
    }

    .dzu-previewContainer progress {
      width: 200px;
    }

    .dzu-previewImage {
      width: 60px;
      max-height: 60px;
    }

    .dzu-previewContainer {
      padding: 15px 0;
    }
  }
`;

const Layout = ({ input, previews, dropzoneProps, files }) => {
  return (
    <div>
      {!files.length && <div {...dropzoneProps}>{input}</div>}
      {previews}
    </div>
  );
};

const content = (
  <div className="content-wrapper">
    <RiUploadCloudFill />
    <p>
      Drag your image here or <span>browse</span>
    </p>
  </div>
);

export default function DropzoneUploader() {
  const getUploadParams = () => ({ url: 'https://httpbin.org/post' });

  // const handleSubmit = (files, allFiles) => {
  //   console.log(files.map((f) => f.meta));
  //   allFiles.forEach((f) => f.remove());
  // };

  return (
    <DropzoneStyle>
      <Dropzone
        getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        // onSubmit={handleSubmit}
        inputContent={content}
      />
    </DropzoneStyle>
  );
}
