import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const ProgressWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Progress({ height }) {
  return (
    <ProgressWrapper style={{height: window.innerHeight}}>
      <CircularProgress style={{ color: '#000A2C', top: '45%', left: '45%', position: 'absolute' }} />
    </ ProgressWrapper>
  );
}
