import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { useEffect ,useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from 'react-router-dom';
import Progress from 'components/Progress';
import { values } from 'lodash';
import { useRequest } from 'hooks/useRequest';

const NutritionistListStyle = styled.div`
 display:flex;
 justify-content:center;
 font-family: 'Roboto';
 font-style: normal;
.container{
    width:70%;
    background:#ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    //padding: 32px 50px 32px;
    padding: 30px;
    row-gap:20px;

    @media(min-width:992px){
    .infinite-scroll-component__outerdiv{
      .infinite-scroll-component{
         display: grid;
         grid-template-columns: repeat(4, auto);
         row-gap:20px;
         padding:10px 5px 10px;  
     }
    }
    .coach-card:hover{
        box-shadow: 0px 4px 15px  rgba(0, 0, 0, 0.1);
       background:rgb(251 251 249 / 50%);
        border-radius: 15px; 
        cursor:pointer;
        .image{
            img{
                -webkit-transform:scale(1.1);
            }
        }
        .ntf{
            -webkit-transform:scale(1.1);
        }
    }
   }
    .coach-card{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:flex-start;
       // padding: 31px 38px 21px;
        padding:21px 0 21px;

        .image{
            img{
                width:150px;
                height:150px;
                border-radius: 125px;              
            }
        }

        .ntf{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 240px;
            text-align: center;
        }

        .name{
            margin-top:14px;
            p{
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #000000;
            }
        }
        .title{
            margin-top:10px;
            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
                
            }
        }
        .dietplan-coaching{
            display:flex;
            margin-top: 11px;
            border-top: 1px solid #E0E3E0;
          //  border-bottom: 1px solid #E0E3E0;
            padding: 12px 0 12px;

            .dietplan, .coaching, .diets-plan, .coaching-offer{
                display:flex;
                gap: 5px;
                align-items:center;
        
                p{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #949D9B;
                }
            }
            .dietplan{
                border-right: 1px solid #E0E3E0;
                padding-right:14px;
            }
            .diets-plan{
                padding-right:25px;
                padding-left:25px;
            }
            .coaching{
                padding-left:14px;
            }
            .coaching-offer{
                padding-left:25px;
                padding-right:25px;
            }
        }
        .dietplan-or-coaching{
            display:flex;
            //margin-top: 11px;
           // padding: 12px 12px 12px;

            .dietplan, .coaching{
                display:flex;
                gap: 5px;
        
                p{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #949D9B;
                }
            }
            .dietplan{
                padding-right:14px;
            }
            .coaching{
                //padding-left:14px;
            }
        }
        .followers{
            display:flex;
            align-items:flex-end;
            gap:5px;
            margin-top:11px;

            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
            }
        }
    }
}

@media(max-width:992px){
  .container{
    width:90% !important;
    background: #f6f5f8 !important;
    padding:0 !important;

    .infinite-scroll-component__outerdiv{
        .infinite-scroll-component{
        //    display: flex !important;
        //    flex-direction:column;
        //    row-gap:10px !important;
           
           .seen-all{
            text-align: center;
            position: absolute;
            bottom: 80px !important;
            width: 86%;
           }
       }
      }

      .coach-card{
        display: grid !important;
        grid-template-columns: 29% 68% !important;
        gap: 3% !important;
        background:#ffffff !important;
        padding:10px !important;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom:10px !important;
        border:none;

        .ntf{
            display: flex;
            flex-direction: column;
            align-items: flex-start !important;
            justify-content:flex-start !important;
            height:100%;
            width:100% !important;
            text-align: justify !important;
        }

        .image{
            img{
                width:72px !important;
                height:72px !important;
                border-radius:5px !important;
                object-fit:contain;
                cursor:pointer;              
            }
        }
        .name{
            margin-top:0 !important;
            word-break: break-word;
            p{
                font-size: 16px !important;
                line-height: 19px !important;
            }
        }
        .title{
            margin-top:5px !important;
            p{
                font-size:12px !important;
                line-height:14px !important;
            }
        }
        .dietplan-coaching{
            padding:7px 0 7px !important;
            margin-top:6px !important;
            width:95% !important;
            .dietplan, .coaching, .diets-plan, .coaching-offer{
                p{
                    font-size: 11px !important;
                    line-height: 12px !important;
                }
            }
            .diets-plan{
                padding:0px !important;
                width:95% !important;
                    
            }
            .coaching-offer{
                padding:0px !important;
                width:95% !important;
                 
            }
        }
        .followers{
            margin-top:6px !important;
        }
      }

  }
}

@media (min-width:280px) and (max-width:290px){
   .container{
     .coach-card{
        display: grid !important;
        grid-template-columns: 36% 59% !important;
        gap: 5% !important;
     }
   }
}

@media (min-width:992px) and (max-width:1279px){
    .container{
        width:95% !important;
        padding:9px !important;
        .name{
            margin-top:14px;
            p{
                font-weight: 500 !important;
                font-size: 15px !important;
            }
        }
    }
}
@media (min-width:1279px) and (max-width:1439px){
    .container{
        width:90% !important;
        padding:15px !important;

    }
}
@media (min-width:1439px) and (max-width:1600px){
    .container{
        width:80% !important;
        padding:16px !important;
         
    }
}
@media (min-width:1600px) and (max-width:1920px){
    .container{
        width:78% !important;
         
    }
}
@media (min-width:1920px) and (max-width:2559px){
    .container{
        width:76% !important;
        .infinite-scroll-component__outerdiv{
            .infinite-scroll-component{
               display: grid;
               grid-template-columns: repeat(5, auto) !important;
           }
          }
    }
}
@media (min-width:2559px){
    .container{
        width:75% !important;
        .infinite-scroll-component__outerdiv{
            .infinite-scroll-component{
               display: grid;
               grid-template-columns: repeat(5, auto) !important; 
           }
          }
    }
}
@media (min-width:992px) and (max-width:1120px){
    .ntf{
        width:200px !important;
    }
}

`

export default function NutritionistList({searchedNutritionist,value}){
    const {allNutritionist, setNutritionistId, isMobile, setSelectedNutritionist } = useAppContext();
    const [data,setData]=useState([]);
    const [currentSlice,setCurrentSlice]=useState(10)
    const history = useHistory()

  const fetchMoreData = () => {
    setTimeout(() => {
      setCurrentSlice(currentSlice+10)
    }, 1500);
    
  };

  useEffect(()=>{
   setData(allNutritionist.slice(0,currentSlice))
  },[currentSlice,allNutritionist])

  const handleNutritionist = async(item) =>{
    setSelectedNutritionist(item)
    await setNutritionistId(item.id),
    setTimeout(() => {
        history.push('/shop/my-coach')
        window.scrollTo(0, 0);
      }, 700);
   
  }

    return(
        <NutritionistListStyle>
            <div className="container">
            <InfiniteScroll
          dataLength={data?.length}
          pullDownToRefreshThreshold={10}

          next={fetchMoreData}
          hasMore={true}
          loader={
           (value && searchedNutritionist?.length===0 )&&( <>no coach found</>)|| (!value && !value?.length && data.length<allNutritionist.length )&& <h4>loading...</h4>||
           (data?.length===allNutritionist?.length )&&  <p className='seen-all' style={{ textAlign: "center", position:"absolute",bottom:"164px",width:"86%" }}>
           {/* <b>Yay! You have seen it all</b> */}
         </p>
          }  
        //   endMessage={
        //     <p style={{ textAlign: "center" }}>
        //       <b>Yay! You have seen it all</b>
        //     </p>
        //   }
        >

           { (value && value.length> 0 ?searchedNutritionist: data ).map((item, index) => (
   
              <div className='coach-card' key={index} onClick = {() =>{handleNutritionist(item)}}>
                <div className='image'> 
                    <img src={item?.img || '/icons/Avatar.svg'}/>
                </div>
                <div className='ntf'>
                <div className='name'>
                    <p>{item?.name}</p>
                </div>
                <div className='title'>
                    <p>{item?.title}</p>
                </div>
                {/* {item?.dietTemplates?.length>0 || item?.coachingOffer?.length>0  ? */}
                <div className={item?.dietTemplates?.length>0 || item?.coachingOffer?.length>0 ? 'dietplan-coaching' : 'dietplan-or-coaching'}>
                {item?.dietTemplates?.length>0 ? 
                    <div className={item?.dietTemplates?.length>0 && item?.coachingOffer?.length>0  ?  'dietplan' : 'diets-plan'}>
                        <img src='/icons/arrow-tick.svg'/>
                        <p>Challenges</p>
                    </div>
                :
                ""
                }
                {item?.coachingOffer?.length>0 ?
                    <div className={item?.dietTemplates?.length>0 && item?.coachingOffer?.length>0 ? 'coaching' : 'coaching-offer'}>
                        <img src='/icons/arrow-tick.svg'/>
                        <p>Coaching</p>
                    </div>
                :
               ""

                }
                </div>
                {/* :
                ''
            } */}
                {/* <div className='followers'>
                    <img src='/icons/profile-user.svg'/>
                    <p>Followers:</p>
                    <p>{item?.users?.length|| 0}</p>
                </div> */}
                </div>
                </div> 
                 ))}  
      
        </InfiniteScroll>           
            </div>
        </NutritionistListStyle>
    )
}


