import styled from 'styled-components'
import Title from './components/Title'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Button from './components/Button'
import DietCard from "./components/DietCard"
import { ReactComponent as Slippers } from "./../../assets/Slippers.svg"
import { ReactComponent as ManWithLaptop } from "./../../assets/Man with laptop.svg"
import { ReactComponent as Yoga } from "./../../assets/Yoga.svg"
import { ReactComponent as Fitness } from "./../../assets/Fitness.svg"
import { ReactComponent as Runner } from "./../../assets/Runner.svg"
import { useOnboardingContext } from './index'
import PageTransition from './components/PageTransition'
import { CrossButton } from './components/CrossButton'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'

const ActiveStatusStyle = styled.div`
    padding: 48px 30px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: overlay;
    height: 100%;
    .flex{
        flex: 1;
    }
    .diet_cards{
        margin-top: 65px;
        padding: 0 20px;
        .dietCard{
            height:68px;
            svg{
                path{
                    stroke:none;
                }
            }
        }
    }
`

export default function ActiveStatus() {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isNewGoal = params.get('newGoal');
    const [activePreference, setActivePreference] = useState('');
    const {onboardingUser, setOnboardingUser} = useOnboardingContext();
    const [visitorOnboard, {loading}] = useRequest('profile/visitor-onboard/v2', 'post');
    const handleBack = () => {
        if (isNewGoal === 'true') {
            onboardingUser?.selfMealPlan?.healthGoal === "maintain-weight" ? history.push('/self-onboarding/current-weight?newGoal=true') : history.push('/self-onboarding/ideal-weight?newGoal=true');
        } else {
            onboardingUser?.selfMealPlan?.healthGoal === "maintain-weight" ? history.push('/self-onboarding/userGender') : history.push('/self-onboarding/ideal-weight');
        }
    }

    const activeStatusData = [
        {
            icon: <Slippers />,
            title: "Inactive",
            desc: "I need to move more"
        },
        {
            icon: <ManWithLaptop />,
            title: "Slightly Active",
            desc: "Daily life activity only"
        },
        {
            icon: <Yoga />,
            title: "Moderately Active",
            desc: "Slow workouts"
        },
        {
            icon: <Fitness />,
            title: "Active",
            desc: "Workout 4-5x weekly"
        },
        {
            icon: <Runner />,
            title: "Extremely Active",
            desc: "I'm always on the move!"
        }
    ]

    const handleClick = (value) => {
        setActivePreference(value);
        switch (value) {
            case "Inactive":
                setOnboardingUser((prev) => ({ ...prev, activity: 1 }));
                break;
            case "Slightly Active":
                setOnboardingUser((prev) => ({ ...prev, activity: 2 }))
                break;
            case "Moderately Active":
                setOnboardingUser((prev) => ({ ...prev, activity: 3 }))
                break;
            case "Active":
                setOnboardingUser((prev) => ({ ...prev, activity: 4 }))
                break;
            case "Extremely Active":
                setOnboardingUser((prev) => ({ ...prev, activity: 5 }))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        switch (onboardingUser?.activity) {
            case 1:
                setActivePreference("Inactive");
                break;
            case 2:
                setActivePreference("Slightly Active");
                break;
            case 3:
                setActivePreference("Moderately Active");
                break;
            case 4:
                setActivePreference("Active");
                break;
            case 5:
                setActivePreference("Extremely Active");
                break;
            default:
                break;
        }
    }, [])

    const handleContinue = async () => {
        if(onboardingUser?.selfMealPlan?.healthGoal === "maintain-weight"){
            try{
                const res = await visitorOnboard({body: {...onboardingUser}})
                if(res?.data?.success){
                    history.push(`/self-onboarding/personalize-macros${isNewGoal === 'true'?'?newGoal=true':''}`);
                }
            }catch(err){
                if(err!="jwt expired"){
                    toast.error(err);
                }
            }
        }else{
            history.push(`/self-onboarding/select-goal${isNewGoal === 'true'?'?newGoal=true':''}`)
        }
    }

    return (
        <PageTransition>
            <ActiveStatusStyle>
                {isNewGoal === "true" && <CrossButton />}
                <div className='flex'>
                    <Title title="How active are you?" subtitle={"Your activity level affects your calorie needs. More activity = more calories needed"} />

                    <div className="diet_cards">
                        {activeStatusData?.map((item, index) => {
                            return <DietCard key={index} onClick={() => handleClick(item.title)} isActive={activePreference === item.title} {...item} />
                        })}
                    </div>
                </div>
                <Button onBackClick={handleBack} onContinueClick={handleContinue} disabled={!activePreference} />
            </ActiveStatusStyle>
        </PageTransition>
    )
}