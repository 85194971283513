import { forwardRef, useState } from 'react';
import InputMessage from './InputMessage';
import InputErrorMessage from './InputErrorMessage'

import { RegularInputWrapper } from './style';

function RegularInput({
  className,
  id,
  label,
  type = 'text',
  hint = '',
  width,
  prefix,
  suffix,
  passwordVisible,
  icon,
  iconPrefix,
  messageStatus = 'error',
  message,
  onSuffixClick,
  errorMessage,
  caretColor = "black",
  autoFocus,
  inputWrapperValue,
  ...props
}, ref) {
  return (
    <RegularInputWrapper className={className} style={{ width: `${width}` }}>
      {label && (
        <label htmlFor={id}>
          {label}
          {hint && <div className="hint">{hint}</div>}
        </label>
      )}
      <div className="input-wrapper" {...(inputWrapperValue || inputWrapperValue === 0 ? { 'input-wrapper': inputWrapperValue } : null)}>
        {prefix && <div className="prefix">{prefix}</div>} 
        {iconPrefix && <img className="prefix" onClick={onSuffixClick} src={iconPrefix}/>}
        <input type={passwordVisible ? 'text' : type} id={id} {...props} ref={ref} autoFocus={autoFocus} />
        {suffix && <div className="suffix" onClick={onSuffixClick}>{suffix}</div>}
        {icon && <img className="suffix" onClick={onSuffixClick} src={icon}/>}
      </div>
      {message && <InputMessage messageStatus={messageStatus} message={message} />}
      {errorMessage && <InputErrorMessage errorMessage={errorMessage} />}
    </RegularInputWrapper>
  );
}

export default forwardRef(RegularInput);
