import { useState, useEffect, useMemo } from 'react';
import produce from 'immer';
import Slider from 'react-slick';
import useCart from 'hooks/useCart';
import TagIcons from 'components/TagIcons';
import MealTag from 'components/shop/MealTag';
import NutritionFacts from '../NutritionFacts';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { NavHashLink } from 'react-router-hash-link';
import { useShopContext } from 'context/shopContext';
import { motion, AnimatePresence } from 'framer-motion';
import PieLegend from 'components/elements/Pie/PieLegend';
import { Button } from 'components/elements/button/Button';
import QuantityButton from 'components/elements/QuantityButton';
import { macroSplitData } from './Data';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-spring-bottom-sheet/dist/style.css';
import { KitchenMealDetailsWrapper } from './style';
import { GilroyFontWrapper } from 'shared/theme/style';
import { useVisitorContext } from 'context/visitorContext';
import useVisitorCart from 'hooks/useVisitorCart';
import Progress from 'components/Progress';
import Fade from '@mui/material/Fade';

function SampleNextArrow({ onClick }) {
  return (
    <div className="arrow arrow-next" onClick={onClick}>
      <img src="/icons/angle-right-slick-black.svg" alt="Angle Icon" />
    </div>
  );
}

function SamplePrevArrow({ onClick }) {
  return (
    <div className="arrow arrow-prev" onClick={onClick}>
      <img src="/icons/angle-left-slick-black.svg" alt="Angle Icon" />
    </div>
  );
}

export default function KitchenMealDetails({
  meal,
  mealInfo,
  moveToMeals,
  open,
  setOpen,
  setMealInfo,
  beforeCheckoutSubmitRef,
  beforeCheckoutSubmitShown,
  isMealGenerating,
  countMeal
}) {
  const [initialQty, setInitialQty] = useState();
  const { images, orderLimit } = meal;
  const { name, id, description, nutritionLabel, ingredients } = mealInfo || [];
  const { isMobile, user } = useAppContext();
  const {
    cart,
    updateSettings,
    settings: { mealPlan, provider },
    kitchensList
  } = user ? useShopContext() : useVisitorContext();
  const { pathname } = useLocation();
  const { addToCart, updateMealQty, removeFromCart } = user ? useCart() : useVisitorCart();
  const mealInCart = cart?.find((meal) => meal?.id === id);
  const [count, setCount] = useState();
  const [providerUsername, setProviderUsername] = useState('');
  const displayOrderLimitMessage = orderLimit && isMobile ? orderLimit === countMeal : orderLimit === count;

  const Allergens = [
    'CEREAL/GLUTEN',
    'PEANUT',
    'LUPINE',
    'MOLLUSK',
    'MILK',
    'TREE_NUT',
    'SESAME',
    'CRUSTACEAN',
    'FISH',
    'EGG',
    'CELERY',
    'SOY',
    'MUSTARD',
    ];

    const tagMapping = {
      "GLUTEN": "CEREAL/GLUTEN",
      "CEREAL":"CEREAL/GLUTEN",
      "TREE_NUTS":"TREE_NUT",
      "EGGS":"EGG"
    };  

  const settings = {
    dots: isMobile ? true : false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -250;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const closeMealDetails = () => {
    setOpen(false);
    pathname === '/shop/kitchen-home' && moveToMeals();
    setMealInfo({});
  };

  useEffect(() => {
    setInitialQty(mealInCart?.qty);
    mealInCart ? setCount(mealInCart?.qty) : setCount(1);
  }, [open, mealInfo]);

  const uniqueIngredients = useMemo(() => {
    const dIngredients =  Array.from(new Set(ingredients?.allergens || [])).map((tag, index) => {
      const formattedTag = tag.replace(/\s/g, '_').toUpperCase();
      const mappedTag = tagMapping[formattedTag] || formattedTag;
      if(Allergens.includes(mappedTag)){
        return mappedTag;
      }
    })
    return Array.from(new Set(dIngredients)).filter((item) => item);
  }, [ingredients])

  useEffect(() => {
    const bPId = provider?.businessProfileId || provider?.BusinessProfileSettings?.[0]?.businessProfileId;
    const foundKitchen = kitchensList?.find(kitchen => kitchen?.businessProfileId === bPId);
    setProviderUsername(provider?.username || foundKitchen?.username);
  }, [kitchensList])

  return (
    <GilroyFontWrapper>
    <KitchenMealDetailsWrapper>
      <AnimatePresence>
        {!isMobile && !beforeCheckoutSubmitShown && (
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            exit={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            className="header-sticky"
          >
            <NavHashLink
              smooth
              to={`/store/${providerUsername}#${id}`}
              scroll={(el) => scrollWithOffset(el)}
            >
              <div className="close-image-wrapper" onClick={closeMealDetails}>
                <img src="/icons/close-black.svg" alt="Close Icon" />
              </div>
            </NavHashLink>
            <div className="meal-title">{name}</div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="meal-details-container">
        <div className="meal-details-header" ref={beforeCheckoutSubmitRef}>
          <NavHashLink
            smooth
            to={`/store/${providerUsername}#${id}`}
            scroll={(el) => scrollWithOffset(el)}
          >
            <div className="close-image-wrapper" onClick={closeMealDetails}>
              <img src="/icons/close-black.svg" alt="Close Icon" />
            </div>
          </NavHashLink>
          {images?.length > 1 ? (
            <Slider {...settings}>
              {images?.map((img, index) => (
                <div key={index} className="slide meal-image-wrapper">
                  <img src={img} alt="Meal Image" />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="meal-image-wrapper">
              <img
                src={
                  images?.length && images[0]?.startsWith('http')
                    ? images[0]
                    : '/images/sexy_just_eat_now.jpg'
                }
                alt="Meal Image"
              />
            </div>
          )}
        </div>
        <div className="meal-details-body">
          <div className="meal-desc-wrapper">
            <div className="meal-title">{name}</div>
            {!!ingredients?.diets?.length && (
              <div className="meal-tags">
                {ingredients?.diets?.map((tag, index) => (
                  <div key={index} className="tag">
                    {tag[0].toUpperCase() + tag.slice(1).toLowerCase()}
                  </div>
                ))}
              </div>
            )}
            {displayOrderLimitMessage &&
              <Fade in={displayOrderLimitMessage}><div className='limit-order'>Limit {orderLimit} per order</div></Fade>
            }
            {description && <div className="meal-desc">{description}</div>}
          </div>
          {!isMobile && (
            <div className="all-ingredients-wrapper">
              <div className="ingredients-title">All ingredients</div>
              <div className="ingredients-contains-wrapper">
                {!!uniqueIngredients?.length && <> 
                  <div className="label">Contains:</div>
                  <div className="tags-wrapper">
                  {uniqueIngredients.map((mappedTag, index) => {
                    return (
                      <div key={index} className="tag-container">
                        <MealTag Icon={TagIcons[mappedTag]} tag={mappedTag.charAt(0).toUpperCase() + mappedTag.slice(1).toLowerCase().replace(/_/g, ' ')} />
                      </div>
                    );
                  })}
                  </div>
                </>}
              </div>
              <div className="ingredients-name-wrapper">{ingredients?.name?.join(', ')}</div>
            </div>
          )}
            {
              isMealGenerating ? <Progress />
                : (
                  <>
                    <div className="macro-split-wrapper">
                      <div className="macro-title">Macro {isMobile && 'split'}</div>
                      <div className="macro-pie-wrapper">
                        <PieLegend
                          width={122}
                          height={122}
                          titleNumber={ingredients?.nutritionFact?.calories}
                          titleText="Calories"
                          data={macroSplitData(
                            +nutritionLabel?.fat?.totalMacroCalories,
                            +nutritionLabel?.protein?.totalMacroCalories,
                            +nutritionLabel?.carbohydrates?.totalMacroCalories,
                            +nutritionLabel?.fat?.macroPercent,
                            +nutritionLabel?.protein?.macroPercent,
                            +nutritionLabel?.carbohydrates?.macroPercent,
                          )}
                          innerRadius={0.7}
                          OuterRadiusHover={0}
                          colors={['#EE786B', '#2399BF', '#8ACEBF', '#E0E3E0',]}
                          legendLabelWidth={63}
                        />
                      </div>
                    </div>
                    <NutritionFacts ingredients={ingredients} nutritionLabel={nutritionLabel} name={name} />
                  </>
                )
          }
        </div>
      </div>
      {!isMobile && (
        <div className="add-cart-wrapper">
          <QuantityButton
            count={count}
            variant="default"
            onClickRemove={() => {
              if (count === 1) return;
              setCount(count - 1);
            }}
            onClickAdd={() => {
              setCount(count + 1);
            }}
            className="meal-count"
            orderLimit={orderLimit}
          />
          <NavHashLink to={`/store/${providerUsername}#${id}`} scroll={(el) => scrollWithOffset(el)}>
            <Button
              title="Add to cart"
              type="dark"
              isDisabled={count === initialQty}
              onClick={() => {
                setOpen(false);
                if (mealInCart) {
                  updateMealQty(meal, count);
                } else {
                  addToCart({...meal,qty:count})
                }
                setMealInfo({});
              }}
            />
          </NavHashLink>
        </div>
      )}
    </KitchenMealDetailsWrapper>
    </GilroyFontWrapper>
  );
}
