import styled from 'styled-components'
import CartCard from './CartCard'
import { useNutritionistContext } from 'context/nutritionistContext';
import autoAnimate from '@formkit/auto-animate';
import { useRef, useEffect } from 'react';

const EmptyCart = styled.div`

    padding: 40px 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
    border-radius: 12px;

    p{
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }

`

export default function CartCardList({setMealPlannerModal, setShowAnim, providerId, setProviderId}){

    const { clientcart, clientsettings } = useNutritionistContext();
    const { providerSettings} = clientsettings;

    const parent = useRef(null);

    useEffect(() => {
        parent.current && autoAnimate(parent.current)
    }, [parent])
     
    return(
        <div ref={parent}>
            {
                clientcart.length > 0 ? 
                providerSettings?.map((provider, index) => {
                    return <CartCard setMealPlannerModal={setMealPlannerModal} setShowAnim={setShowAnim} provider={provider} providerSettings={providerSettings} key={index} providerId={providerId} setProviderId={setProviderId} length={providerSettings?.length}/>
                })
                :
                <EmptyCart className='empty-cart'>
                  <p> Seems that your cart is empty,<br/>
                  Fill Your Cart with healthy and tasty meals.</p>
                </EmptyCart>
            }
        </div>
    )

}