import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../.././shared/theme/theme';
import { ReactComponent as DragIcon } from 'assets/drag-handle.svg';
import { useDrag, useDrop } from 'react-dnd';
import { useRequest } from 'hooks/useRequest';
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useAppContext } from 'context/appContext';
import { useKitchenContext } from 'context/kitchenContext';

export const MainCardStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  background: white;
  padding-top: 12px;
  svg {
    display: block;
    cursor: grab;
  }
`;
export const MealStyle = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 6px;
  min-height: 80px;
  width: 100%;
  display: flex;
  border: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
  align-items: center;
  .image {
    position: relative;
  }
  .meal_img {
    width: 80px;
    height: 73.94px;
    object-fit: contain;
    display: block;
  }
  .meal_name {
    font-family: ${theme.typography.fontInter};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding-left: 16px;
    line-height: 20px;
    color: ${theme.colors.blackDefault};
    flex: 1;
  }
  .amount {
    font-family: ${theme.typography.fontInter};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.colors.primaryDark};
    width: 17%;
    word-break: break-all;
  }
  .icon-container {
    padding-right: 21px;
    gap: 16px;
    display: flex;
    .icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
`;

function Meal({
  name = '(Sample item) Grandma’s chicken pho',
  price = '18.00',
  img,
  onDelete,
  onEdit,
  id,
  setMealsData,
  index,
  categoryId,
  mealsData,
  mealsIndexList,
  setMealsIndexList,
  categoryList,
  setCategoryList,
  mealItem,
  mealCardRef
}) {
  const [updateCategory, { isLoading }] = useRequest();
  const ref = useRef(null);
  const { isSuper } = useAppContext();
  const {imitateProviderCredentials} = useKitchenContext()
    const updateMealCategory = async (mealCategoryId, id, newIndex) => {
      let path = `meal/update`
      if( isSuper ){
        path += `?providerId=${imitateProviderCredentials?.providerId}&businessProfileId=${imitateProviderCredentials?.businessProfileId}`
      }
    await updateCategory({
      path: path,
      method: 'patch',
      body: {
        mealCategoryId,
        id,
        newIndex
      },
    })
      .then((res) => {
        console.log('res', res);
        if(res?.success){
          setCategoryList(res?.categoryList);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const changeItemCategory = (item, categoryName, newCategoryId) => {
    const updatedMealdata = mealsData?.map((meal) => {
      if (meal.id === item?.id) {
        return { ...meal, category: categoryName };
      }
      return meal;
    });

    let maxOrderIndex;
    if(newCategoryId === categoryId){
      maxOrderIndex = item?.index;
    }else{
      maxOrderIndex = 0;
      const categoryData = categoryList.find((cat) => cat.categoryId === newCategoryId);
  
      if (categoryData?.meals && categoryData.meals.length > 0) {
        maxOrderIndex = Math.max(...categoryData.meals.map((meal) => meal.mealOrderIndex)) + 1;
      }
    }

    setMealsData(updatedMealdata);
    updateMealCategory(newCategoryId, item?.id, maxOrderIndex);
  };

  const [{ isDragging, cursorStyle }, drag, preview] = useDrag({
    type: 'ITEM',
    item: { id, index, ...mealItem },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      dropResult &&
        changeItemCategory(item, dropResult?.name, dropResult?.categoryId);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      cursorStyle: monitor.isDragging() ? 'grabbing' : 'grab'
    }),
    canDrop: () => true,
  });

  useEffect(() => {
    isDragging ? document.body.style.cursor = 'grabbing' :  document.body.style.cursor = 'unset'
  }, [isDragging])

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const [, dropMeal] = useDrop({
    accept: 'ITEM',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardHandler(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  dropMeal(ref);

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = mealsIndexList[dragIndex];
    if (dragItem && mealsIndexList?.length) {
      setMealsIndexList((prevState) => {
        const coppiedStateArray = [...prevState];
        const prevItem = coppiedStateArray?.splice(hoverIndex, 1, dragItem);
        coppiedStateArray?.splice(dragIndex, 1, prevItem[0]);
        coppiedStateArray?.forEach((item, index) => {
          item.mealOrderIndex = index;
        });
        return coppiedStateArray;
      });
    }
  };

  const updateMealIndex = (mealsArray, updatedArray) => {
    return mealsArray.map(mealItem => {
      const updatedMealItem = updatedArray.find(item =>
        item?.category === mealItem?.category && item?.id === mealItem?.id
      );
  
      if (updatedMealItem) {
        mealItem.mealOrderIndex = updatedMealItem.mealOrderIndex;
      }
  
      return mealItem;
    });
  };

  useEffect(() => {
    if (mealsIndexList?.length) {
      const updatedMeals = updateMealIndex(mealsData, mealsIndexList);
      setMealsData(updatedMeals);
    }
  }, [mealsIndexList]);

  return (
    <>
      <div ref={ref}>
        <MainCardStyle ref={mealCardRef}>
          <div className="drag_icon" style={{ cursor: cursorStyle }} ref={drag}>
            <DragIcon />
          </div>
          <MealStyle theme={theme}>
            <div className="image">
              <img src={img ? img : '/images/dish.png'} className="meal_img" />
            </div>
            <h2 className="meal_name">{name}</h2>
            <h3 className="amount">${price.toFixed(2)}</h3>
            <div className="icon-container">
              <img className="icon" src="/icons/edit2.svg" onClick={onEdit}></img>
              <img className="icon" src="/icons/trash2.svg" onClick={onDelete}></img>
            </div>
          </MealStyle>
        </MainCardStyle>
      </div>
    </>
  );
}

export default Meal;