import { useHistory } from 'react-router-dom';
import { socialMedia } from './Data';
import { FooterDesktopWrapper } from './style';
import { Link } from 'react-router-dom';

export default function FooterDesktop() {
  const history = useHistory();

  return (
    <FooterDesktopWrapper>
      <div className="footer-container">
        <div className="footer-links-wrapper">
          <div className="logo-wrapper">
            <div className="nutritt-brand" onClick={() => history.push('/')}>
              <img src="/images/nutritt-logo-2.svg" alt="Logo" />
            </div>
            <div className="slogan">Your goals, a meal away</div>
          </div>
          <div className="follow-wrapper">
            <div className="footer-title">Follow us</div>
            <div className="social-media-wrapper">
              {socialMedia.map((icon) => (
                <a href={icon.link} target={icon.link !== "#" ? '_blank' : '_self'} key={icon.id} className='icon-wrapper'>
                  <img src={icon.icon} alt="SocialMedia Icon" />
                </a>
              ))}
            </div>
            <div className="app-download">
              <a
                href="https://apps.apple.com/us/app/nutritt/id1605156638"
                target="-blank"
                className="app-store-image"
              >
                <img src="/images/landing-app-store.svg" alt="App Store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nutritt"
                target="-blank"
                className="google-play-image"
              >
                <img src="/images/google-play.svg" alt="Google Play" />
              </a>
            </div>
          </div>
          <div className="support-wrapper">
            <div className="footer-title">Support</div>
            <Link to='/nutritt/support' className="footer-info">Contact Support</Link>
          </div>
          <div className="about-wrapper">
            <div className="footer-title">About</div>
            <a href='https://nutritt.typeform.com/careers' className="footer-info">Career</a>
          </div>
        </div>
        <div className="footer-copyright-wrapper">
	          <div className="copyright">© 2021-2024 Nutritt.com – All Rights Reserved</div>
          <div className="terms-links">
            <Link to='/nutritt/privacy-policy' className="link" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
            <Link to="/users/terms-&-conditions" className="link" target="_blank" rel="noopener noreferrer">Terms of Use</Link>
          </div>
        </div>
      </div>
    </FooterDesktopWrapper>
  );
}
