import styled from 'styled-components';
import { useShopContext } from 'context/shopContext';
import useDisplayAmount from 'hooks/useDisplayAmount';
import { useEffect,useCallback } from 'react';
import { prop, sum } from 'ramda';
import { useRequest } from 'hooks/useRequest';
import {useAppContext} from 'context/appContext';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

const OrderSummaryStyle = styled.div`
  color: #0b1c18;
  .container {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;

    h3 {
      margin-bottom: 13px;
      color: #0b1c18;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .item-wrapper {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    &.meals-count {
      font-size: 16px;
    }

    .discountWrapper{
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }

  .meals-days-count {
     margin-bottom: 10px;
    padding-bottom: 10px;
    color: #707070;
     border-bottom: 2px solid #ededed;
  }

  .total-amount {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 900;
  }

  @media (max-width: 991px) {
    margin-top: ${({isProviderSummary}) => isProviderSummary ? "10px" : "14px"};
    margin-bottom: ${({isProviderSummary}) => isProviderSummary ? "10px" : "0"};
    padding: ${({isProviderSummary}) => isProviderSummary ? "24px 15px" : "16px 16px 26px"};
    background: #ffffff;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);

    .container.order-summary-wrapper {
      width: 100%;
      font-size: 12px;
      line-height: 14px;

      h3 {
        margin: 0 0 14px 0px;
        color: #0b1d17;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .item-wrapper.meals-count,
      .item-wrapper {
        color: #51635d;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 16px;
        height: 17px;
        line-height: 17px;
        &.total-amount{
          height: auto;
        }
        span:last-child {
          color: #00092D;
        }
        &:last-child{
          margin-bottom: ${({isProviderSummary}) => isProviderSummary ? "0px" : "16px"};
        }
      }

      .meals-days-count {
         margin-bottom: 17px;
        padding-bottom: 17px;
         border-bottom: 1px solid #51635d1a;
         color:#51635D !important;
      }

      .total-amount {
        margin: 20px 0 0;
        padding-top: 20px;
        color: #0b1d17;
        font-size: 16px;
        font-weight: 600;
        border-top: 1px solid #51635d1a;
      }
      .text{
        font-weight:500 !important;
        &.discount{
          color: #52C41A !important;
        }
      }
      .subtotal{
        font-weight: 700 !important;
        color: #00092D;
      }
    }
  }

  @media (min-width: 992px) {
    .container.order-summary-wrapper {
      width: 100%;
      padding: 24px;

      h3{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #0B1D17;
        margin-bottom: 14px;
      }

      .item-wrapper {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: normal;
        color: #51635D;
        margin-bottom: 16px;
        &.meals-count {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          font-size: 12px;
          color: #51635D;
        }
        .text{
          color: #00092D; 
          &.discount{
            color: #52C41A !important;
          }
        }
        .subtotal{
          font-weight: 700;
        }
      }

      .meals-days-count{
        font-weight: 400;
        font-size: 12px !important;
        line-height: 14px;
        color: #51635D;
      }

      .total-amount{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 8px;
        color:#0B1D17;
      }
    }
  }
`;

export default function OrderSummary({ isProviderSummary = false, providerId, showTitle = true, showServiceFee = true, showDivider = true, providerCard = false, providerSubscriptionSelection, setIsOrderSummaryLoading = () => {} }) {
  const { settings, cartCount, cart, setOrderSummaryInfo, orderSummaryInfo, promoCodeInfo, appliedCode, useNutrittCash } = useShopContext();
  const { userInfo, providerSettings } = settings;
  const { meals, days } = settings;
  const [getOrderSummary, {isLoading}] = useRequest('order/getOrderSummary', 'post');


  const getOrderSummaryFn =async (cart,userInfo,providerSettings,promoCodeInfo,providerSubscriptionSelection,useNutrittCash) => {
    const items = cart.map(({ id, qty, isCombo, components, providerId }) => {
      let selectionIds = [];
      if (isCombo) selectionIds = components.map(({ id }) => id);
      return {
        mealId: id,
        qty,
        isCombo,
        selectionIds,
        providerId,
      };
    });
    try {
    const orderSummarySuccess = await getOrderSummary({
      body: {
        items,
        userInfo,
        providerSettings,
        promoCodeInfo: {
        promoCode: promoCodeInfo?.promoCode,
        },
        applyCashAmount:useNutrittCash,
        ...(providerSubscriptionSelection && providerSubscriptionSelection.length > 0 && { subscriptionInfo: providerSubscriptionSelection })
      },
    });
    if (orderSummarySuccess) {
      setOrderSummaryInfo(orderSummarySuccess);
    }
  } catch (error) {
    toast.error(error);
  }
  }

  const OfferText = () => {
    if(appliedCode?.promoCodeInfo?.minOrderAmount && appliedCode?.promoCodeInfo?.data?.maximumOrderLimit && appliedCode?.promoCodeInfo?.discount_type === "percentage"){
      return (  
        <span>
            {appliedCode?.promoCodeInfo?.discount}%, up to ${appliedCode?.promoCodeInfo?.minOrderAmount}.
        </span>
        )
      }      
    return (
      <span>Discount {appliedCode?.promoCodeInfo?.code}</span>
    )
  
   }
  
  const {isMobile, isDesktop} = useAppContext();
  const debouncedGetOrderSummary = useCallback(debounce(getOrderSummaryFn, 400), []);

  useEffect(() => {
  if(!providerCard){
    debouncedGetOrderSummary(cart,userInfo,providerSettings,promoCodeInfo, providerSubscriptionSelection, useNutrittCash);
  }
  }, [cart, userInfo, providerSettings,appliedCode,promoCodeInfo?.promoCode, providerCard, providerSubscriptionSelection, useNutrittCash]);

  useEffect(() => {
    setIsOrderSummaryLoading(isLoading);
  },[isLoading])
  
  const displayNumbers = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  let userSubTotal, shipping, tax, userTotalAmount, discount, serviceFee, grandTotal, totalDiscount, totalRepeatOrderDiscount, userTotalAmountAfterRepeatDis, nutrittCash ;
  
  if(!isProviderSummary || !providerId){
    userSubTotal = sum(orderSummaryInfo?.orderSummary?.providerOrderSummary?.map(prop('userSubTotal')) || [0]) / 100;
    shipping = sum(orderSummaryInfo?.orderSummary?.providerOrderSummary?.map(prop('deliveryFee')) || [0]) /
    100;
    tax = sum(orderSummaryInfo?.orderSummary?.providerOrderSummary?.map(prop('taxAmount')) || [0]) / 100;
    userTotalAmount = sum(orderSummaryInfo?.orderSummary?.providerOrderSummary?.map(prop('userTotalAmount')) || [0]) / 100;
    userTotalAmountAfterRepeatDis = (orderSummaryInfo?.orderSummary?.totalAmount || 0) / 100;
    discount = sum(orderSummaryInfo?.orderSummary?.providerOrderSummary?.map(prop('appliedDiscount')) || [0]) / 100;
    serviceFee = (orderSummaryInfo?.orderSummary?.serviceFee || 0) / 100;
    grandTotal = (orderSummaryInfo?.orderSummary?.grandTotal || 0) / 100;
    totalDiscount = orderSummaryInfo?.orderSummary?.totalDiscount;
    nutrittCash = (orderSummaryInfo?.orderSummary?.usedNutrittCash || 0) / 100;
    totalRepeatOrderDiscount = (orderSummaryInfo?.orderSummary?.totalRepeatOrderDiscount || 0) / 100;
  }

  if(isProviderSummary && providerId){
    userSubTotal = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.userSubTotal || 0) / 100;
    shipping = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.deliveryFee || 0) / 100;
    tax = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.taxAmount || 0) / 100;
    userTotalAmount = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.userTotalAmount || 0) / 100;
    discount = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.appliedDiscount || 0) / 100;
    serviceFee = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.serviceFee || 0) / 100;
    totalRepeatOrderDiscount = (orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.repeatOrderDiscount || 0) / 100;
  }
 
  return (
    <OrderSummaryStyle isProviderSummary={isProviderSummary}>
      <div className="container order-summary-wrapper">
        {showTitle && <h3>Summary</h3>}
        {/* <div className="item-wrapper meals-count">
          <span>{cartCount} Meals</span>
          <span>${displayNumbers(userSubTotal)}</span>
        </div>
        <div className="meals-days-count">
          {meals} meals for {days} days
        </div> */}
        <div className="item-wrapper">
          <span>Subtotal</span>
          <span className='text subtotal'>${useDisplayAmount(displayNumbers(userTotalAmount))}</span>
        </div>
        {!isProviderSummary && providerSubscriptionSelection?.length !== 0 && (
            <div className="item-wrapper">
                <span>Repeat order discount</span>
                <span className='text discount'>-{totalRepeatOrderDiscount ? `$${useDisplayAmount(displayNumbers(totalRepeatOrderDiscount))}` : "$0.00"}</span>
            </div>        
        )}
        {orderSummaryInfo?.orderSummary?.providerOrderSummary?.find(summary => summary.providerId === providerId)?.repeatOrderDiscount > 0 && (
            <div className="item-wrapper">
                <span>Repeat order discount</span>
                <span className='text discount'>-{totalRepeatOrderDiscount ? `$${useDisplayAmount(displayNumbers(totalRepeatOrderDiscount))}` : "$0.00"}</span>
            </div>
        )}
        {totalDiscount !=0 && !isProviderSummary && appliedCode?.promoCodeInfo?.code && (
        <div className="item-wrapper">
          <OfferText />
          {(discount > userTotalAmountAfterRepeatDis) ? 
          <div className='discountWrapper'>
            <span className='text discount'>-${displayNumbers(displayNumbers(userTotalAmountAfterRepeatDis))}</span>
          </div> : 
          <span className='text discount'>-${displayNumbers(displayNumbers(discount))}</span> 

          }
        </div>
        )
        }
        { useNutrittCash && !isProviderSummary &&  <div className="item-wrapper">
          <span>Nutritt Cash</span>
          <span className='text discount'>-${displayNumbers(displayNumbers(nutrittCash))}</span> 
        </div>}
        <div className="item-wrapper">
          <span>Delivery Fee</span>
          <span className='text'>${useDisplayAmount(displayNumbers(shipping))}</span>
        </div>
        <div className="item-wrapper">
          <span>Tax</span>
          <span className='text'>${useDisplayAmount(displayNumbers(tax))}</span>
        </div>
        {showServiceFee &&
        <div className="item-wrapper">
          <span>Service Fee</span>
          <span className='text'>${displayNumbers(displayNumbers(serviceFee))}</span>
        </div>
        }
        {!isMobile && showDivider &&
        <div style={{ backgroundColor: '#51635D', opacity: '0.1', height: '1px' }}/>
        }
        {!isProviderSummary &&
        <div className="item-wrapper total-amount">
        <span style={{color: "#0B1D17"}}>          
          Total
        </span>
          <span>${displayNumbers(displayNumbers(grandTotal))}</span>
        </div>
        }
      </div>
    </OrderSummaryStyle>
  );
}


