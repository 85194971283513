import styled from 'styled-components';
import OnboardCard from './OnboardCard';
import Slider from 'react-slick';
import MagicSliderDots from 'react-magic-slider-dots';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';
import { NutritionistContextProvider } from '../../context/nutritionistContext';

const OnboardingPageStyle = styled.div`
  background: #ffffff;
  min-height: calc(100vh - 32px);

  .slick-slider{
    transform: translate3d(-26px, 0, 0);
    .slick-list {
      transform: translate3d(25px, 0, 0);
    }
    .slick-next:before {
      color: currentColor;
    }
  }
  .img-wrapper {
    width: 100%;

    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 9px;
      height: 20px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots {
      position: relative !important;
      width: 180px !important;
      margin-top: -83% !important;
      left: 7%;
      @media (max-width:320px){
        margin-top: -90% !important;
      }

      li {
        button:before {
          color: #e0e3e0;
          font-size: 6px !important;
          opacity: 1;
        }
      }

      li.slick-active button:before {
        color: #cbedba;
        font-size: 8px !important;
      }
    }
    .dots-container {
      width: 105px !important;
    }
    .magic-dots.slick-dots li.small button:before {
      font-size: 8px;
      line-height: 20px;
    }
    .magic-dots.slick-dots ul {
      padding: 33px;
      display: flex;
      transition: all 0.2s;
      // margin-top: revert;
    }
    .magic-dots.slick-dots li button:before {
      font-size: 11px !important;
      opacity: 1 !important;
      color: #e0e3e0;
    }
    .magic-dots.slick-dots li.slick-active {
      // margin-right: 36px;
      // margin-left: 3px;
      margin-right: 41px;
      margin-left: 11px;
    }
    .magic-dots.slick-dots li.slick-active button:before {
      color: transparent;
      width: 53px;
      background-color: #cbedba;
      height: 8px;
      border-radius: 4px;
      font-size: 0px !important;
      top: 5.5px;
    }
  }

  @media(max-width: 991px){
    .img-wrapper{
    }
  }

  @media(min-width: 992px){
    .img-wrapper{
      width: 50%;
      margin-left: 25%;
      position: fixed;
    }
  }

  @media only screen and (device-width: 375px) {
    .img-wrapper{
      position: fixed;
      .slick-dots {
        margin-top: -217% !important;
      }

    }
  }
  @media only screen and (device-width: 393px) {
    .img-wrapper{
      position: fixed;
      .slick-dots {
        margin-top: -242% !important;
      }
    }
  }

  // @media only screen and(device-width: 320px){
  //   .img-wrapper{
  //     position: fixed;
  //     .slick-dots {
  //       margin-top: -90% !important;
  //       position: relative !important;
  //       bottom:0 !important;
  //     }
  //   }
  // }
`;

function Onboarding({ setShowDietPlans }) {
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 8000,
    appendDots: (dots) => {
      return (
        <MagicSliderDots
          dotsContainer={'magic-dots slick-dots dots-container'}
          dots={dots}
          numDotsToShow={4}
          dotWidth={60}
        />
      );
    },
  };
  return (
    <OnboardingPageStyle>
      <div className="img-wrapper">
        <Slider {...settings}>
          <OnboardCard
            icon={'/icons/healthy-lunch.svg'}
            title="Love what you eat again"
            des="Choose from thousands of delicious meals from specialized local providers to fill out your meal plan"
          />
          <OnboardCard
            icon={'/icons/yoga-image.svg'}
            title="Connect with your coach"
            des="Discover your recommended intake to unlock your ideal body"
          />
          <OnboardCard
            icon={'/icons/morning-walk.svg'}
            title="Smash your goals"
            des="Easily track your macros and stay accountable with a more connected approach to eating."
            showButton={true}
            setShowDietPlans={setShowDietPlans}
          />
        </Slider>
      </div>
    </OnboardingPageStyle>
  );
}

const OnboardingFirstTimeWrapper = ({ setShowDietPlans }) => {
  return (
    <NutritionistContextProvider>
      <Onboarding setShowDietPlans={setShowDietPlans} />
    </NutritionistContextProvider>
  );
};

export default OnboardingFirstTimeWrapper;

