import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const PantrySortWrapper = styled.div`
  width: 40%;

  .sort-wrapper {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sort-container {
      width: 90%;
      display: flex;
      align-items: center;
    }

    p {
      width: 100%;
      margin-left: 2px;
      color: #949d9b;
      font-size: 11px;
      line-height: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .angle-icon {
      width: 11px;
    }
  }

  .sort-popupp-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: #949d9b80;
    display: flex;
    align-items: center;

    .sort-popupp-container {
      width: 90%;
      margin: 0 auto;
      padding: 24px 0;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    .sort-popup-title {
      padding: 0 20px;
      color: #0b1d17;
      font-size: 16px;
      font-family: 'Roboto';
      line-height: 19px;
    }

    .sort-options {
      margin: 15px 0;

      .item {
        padding: 13px 20px;
        color: #51635d;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;

        &.selected {
          background-color: #f6f5f8;
          font-family: 'Roboto';
        }
      }
    }
  }

  @media (min-width: 992px) {
    position: relative;
    width: 150px;

    .sort-wrapper .angle-icon {
      margin-left: 10px;

      &.isList {
        transform: rotate(180deg);
      }
    }

    .mask {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }

    p {
      color: #949d9b;
      letter-spacing: 0.02em;
    }

    .sort-list-wrapper {
      position: absolute;
      top: 15px;
      right: 0;
      z-index: 6;
      width: 120px;
      background: #ffffff;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

      .item {
        height: 32px;
        padding-left: 11px;
        color: #949d9b;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.selected,
        &:hover {
          background-color: #f0faeb;
          color: #52c41a;
        }
      }
    }
  }
`;

const SortMethods = [
  'Calories: Low to High',
  'Calories: High to Low',
  'Fat: Low to High',
  'Fat: High to Low',
  'Protein: Low to High',
  'Protein: High to Low',
];

const SortMethodsDesktop = [
  'Low Calories',
  'High Calories',
  'Low Fat',
  'High Fat',
  'Low Protein',
  'High Protein',
];

export default function PantrySort({ sortMethod, setSortMethod }) {
  const [isPopup, setIsPopup] = useState(false);
  const [isList, setIsList] = useState(false);
  const [selected, setSelected] = useState();
  const { isMobile } = useAppContext();

  useEffect(() => {
    isPopup
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [isPopup]);

  useEffect(() => {
    if (!isMobile) {
      setIsList(false);
      setSortMethod(SortMethodsDesktop[selected]);
    }
  }, [selected]);

  return (
    <PantrySortWrapper>
      {isList && <div className="mask" onClick={() => setIsList(false)} />}
      <div className="sort-wrapper" onClick={() => (isMobile ? setIsPopup(true) : setIsList(true))}>
        <div className="sort-container">
          {isMobile && <img src="/icons/sort.svg" alt="Sort Icon" />}
          <p>
            {!isMobile && <span>SORT BY </span>}
            {sortMethod}
          </p>
        </div>
        <img
          src="/icons/angle-bottom.svg"
          alt="Angle Icon"
          className={classNames('angle-icon', { isList })}
        />
      </div>
      {isMobile
        ? isPopup && (
            <div className="sort-popupp-wrapper">
              <div className="sort-popupp-container">
                <div className="sort-popup-title">Sort by</div>
                <div className="sort-options">
                  {SortMethods.map((sort, index) => (
                    <div
                      key={index}
                      onClick={() => setSelected(index)}
                      className={classNames('item', { selected: selected == index })}
                    >
                      {sort}
                    </div>
                  ))}
                </div>
                <div className="btns-wrapper">
                  <button onClick={() => setIsPopup(false)}>Cancel</button>
                  <button
                    className="primary-btn"
                    onClick={() => {
                      setIsPopup(false);
                      setSortMethod(SortMethods[selected]);
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )
        : isList && (
            <div className="sort-list-wrapper">
              {SortMethodsDesktop.map((sort, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setSelected(index);
                  }}
                  className={classNames('item', { selected: selected == index })}
                >
                  {sort}
                </div>
              ))}
            </div>
          )}
    </PantrySortWrapper>
  );
}
