import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

const ImageViewer = ({ src, fallbackSrc, className, ...others }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [errorClass, setErrorClass] = useState('');
  useEffect(() => {
    if (src) {
      setImgSrc(src);
    } else {
      setImgSrc(fallbackSrc);
    }
  }, [src]);

  const onError = () => {
    setImgSrc(fallbackSrc);
    setErrorClass('fallbackImage');
  };

  return (
    <img src={imgSrc} onError={onError} className={classNames(className, errorClass)} {...others} />
  );
};


export default ImageViewer;
