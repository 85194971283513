import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageTransition from './components/PageTransition';
import RegularInput from 'components/Input/RegularInput';
import AddressesList from 'pages/shop/Addresses/AddressesList';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { GilroyFontWrapper } from 'shared/theme/style';
import { useVisitorContext } from 'context/visitorContext';
import Area from 'components/shop/FavoriteKitchen/Area';

const AddressStyle = styled.div`
  display: flex;
  flex-direction: column;
  overflow: overlay;
  height: 100vh;
  height: -webkit-fill-available;
  .image-cover{
    width: 100%;
    height: auto;
    background: #F6F5F8;
    img{
        width: 100%;
        display: block;
        object-fit: cover;
    }
}
::-webkit-scrollbar-thumb {
  background-color: transparent;
}
  .container-box {
    padding: 33px 35px 36px 35px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 12px;
    >.title {
      color: #00092d;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 12px;
      border-bottom: 1px solid #e0e3e0;
    }
    input {
      height: 44px;
      border-radius: 6px;
      border: 1px solid #e0e3e0;
      ::placeholder {
        color: #afb5b4;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
  .alert {
    position: absolute;
    top: 16px;
    left: auto;
    width: 100%;
    .alert_bar {
      margin: auto;
    }
  }
`;

const AddressWrapper = styled.div`
  position: fixed;
  inset: 0;
  padding-top: 15px;
  background: white;
  animation: slideOutTop 500ms ease 0s;
  &.slideInDown {
    animation-name: slideInDown;
  }

  @keyframes slideOutTop {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideInDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }
}
`
export const Address = () => {
  const [open, setOpen] = useState(false);
  const [isAddressWrapperVisible, setIsAddressWrapperVisible] = useState(open);
  const { setHeaderAddress, isMobile } = useAppContext();
  const { waitingList } = useShopContext();

  useEffect(() => {
    if (open) {
      setIsAddressWrapperVisible(true);
    } else {
      setTimeout(() => {
        setIsAddressWrapperVisible(false);
      }, 500);
    }
  }, [open]);
  
  return (
    <PageTransition>
    <GilroyFontWrapper>
    <AddressStyle>
      <div className='image-cover'>
        <img src={'/images/PlateHeader.png'} />
      </div>
      <div className="container-box">
        <h2 className="title">
          Before diving in,
          <br />
          let’s make sure we can serve your <br /> location
        </h2>
        <RegularInput
          type="text"
          name="address"
          placeholder="Enter delivery address"
          iconPrefix="/icons/search-box.svg"
          onClick={() => {setOpen(true)}}
          autoComplete="off"
          readOnly
        />
        {isAddressWrapperVisible && (
          <AddressWrapper className={`slide ${open ? "slideOutTop" : "slideInDown"}`}>
             <AddressesList
              setOpen={setOpen}
              selectHeaderAddress={(address) => setHeaderAddress(address)}
            />
          </AddressWrapper>
        )}
      {
        waitingList && isMobile && <Area />
      }
      </div>
    </AddressStyle>
    </GilroyFontWrapper>
    </PageTransition>
  );
};
