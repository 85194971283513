import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useState, useMemo, useCallback } from 'react';
import { isEmailValid } from 'components/auth/utils';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import PageTransition from '../components/PageTransition';
import RegularInput from 'components/Input/RegularInput';
import { GilroyFontWrapper } from 'shared/theme/style';
import { ReactComponent as ArrowRight } from 'assets/shop/chevron-right.svg';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ArrowLeft } from 'assets/shop/chevronLeft.svg';

const SignInStyled = styled.div`
width: 100vw;
height: 100vh;
z-index: 99;
position: fixed;
background: #F0F2F4;
inset: 0;
display: flex;
justify-content: center;
align-items: center;
.modal {
  max-width: 536px;
  width: 100%;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 58px 48px 48px 48px;
  display: flex;
  flex-direction: column;
   .heading{
     display: flex;
     flex-direction: column;
     gap: 12px;
    .title{
      color: #000A2C;
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .desc{
      color: #757E89;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
   }
   .button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 81px;
    button{
        span{
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
      :disabled {
        background-color: #F0F2F4 !Important;
        color: #9CA3AD !Important;
      }
    }
    .btn{
        height: 42px;
        width:124px;  
        border-radius: 21px;  
    }
  }
  .first-button{
    width: 103px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid #E0E4E8;
    background: #FFF;
    display: flex;
    height: 42px;
    padding: 13px 24px 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
      .icon{
        top: 2px;
      }
    }
}

`
const FormGroupStyle = styled.div`
  margin-top: 14px;
  width: 100%;
  display:flex;
  flex-direction: column;
  gap: 14px;
  label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: inline-block;
  }
  input {
    height: 44px;
    border: 1px solid #e0e3e0;
    :hover, :focus {
      border-color: #00092d !important;
    }
    :focus::placeholder {
      color: transparent;
    }
    ::placeholder {
      font-family: Gilroy;
      color: #AFB5B4;
      font-style: normal;
      font-weight: 500;
    }
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 500;
    line-height: 20px;
    color: #000A2C;
  }
`;

export default function EmailVerification() {
  const history = useHistory();
  const [isEmailExist, { isLoading: isEmailVerifying, error, setError }] = useRequest('auth/isEmailPhoneNumberExist', 'get');
  const {setDesktopOnboardingUser, desktopOnboardingUser} = useAppContext();
  const validEmail = useMemo(() => isEmailValid(setDesktopOnboardingUser?.email), [setDesktopOnboardingUser.email]);
    
  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setError('');
    setDesktopOnboardingUser({
      ...desktopOnboardingUser,
      [name]: value,
    });
  };

  const handleButtonClick = useCallback(async () => {
    try {
        const res = await isEmailExist({ path: `auth/isEmailPhoneNumberExist?email=${desktopOnboardingUser?.email}` });
        if (res.data.isEmailPhoneNumberExist) {
          setError('Email already exists. Try with another email.');
          return;
        } else {
          history.push('/account/password')
        }
    } catch (error) {
      toast.error(error);
    }
  }, [desktopOnboardingUser, validEmail]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  return (
      <GilroyFontWrapper>
      <SignInStyled>
        <div className='modal'>
            <div className='heading'>
                <h1 className='title'>What’s your email?</h1>
                <text className='desc'>Receipts will be sent to your email</text>
            </div>
            <FormGroupStyle>
            <label>Email</label>
                  <RegularInput
                    type="text"
                    name="email"
                    onChange={handleInputChange}
                    value={desktopOnboardingUser?.email}
                    placeholder="Email"
                    errorMessage={error}
                    onKeyPress={handleKeyPress}
                    autoFocus
                    />
            </FormGroupStyle>
            <div className={`button`}>
                <Button type={'secondary'} title="Back" iconLeft={<ArrowLeft />} onClick={()=> history.goBack()} className="first-button" />
                <Button type={'dark'} title="Continue" iconRight={<ArrowRight />} onClick={handleButtonClick} isDisabled={desktopOnboardingUser?.email === '' || isEmailVerifying || !desktopOnboardingUser?.email}/>
              </div>
        </div>
      </SignInStyled>
      </GilroyFontWrapper>
  );
}

