import { useEffect, useState,useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from "swiper";

export default function NumberQuantitySlider({
  items,
  activeNumberQtyIndex,
  setActiveNumberQtyIndex,
  fullQtyRef,
  servings,
  servingSliderIndex,
  fractionalConstants,
  toFraction,
  activeFractionalIndex,
  isRecentLog
}) {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);

  const getActiveUnitIndex = useCallback(() => {
    const activeUnit = Array.isArray(servings)
      ? servings[servingSliderIndex]?.selectedQuantity ? servings[servingSliderIndex]?.selectedQuantity : servings[servingSliderIndex]?.number_of_units
      : servings?.selectedQuantity ? servings?.selectedQuantity : servings?.number_of_units;
    const numIndex = items.findIndex((item) => Number(item) === Number(activeUnit));
    const index = fractionalConstants.findIndex((item) => toFraction(item) === toFraction(activeUnit));
    return { numIndex, index };
  }, [servings, servingSliderIndex, items, fractionalConstants]);
   

  useEffect(() => {
    setTimeout(() => {
    if (fullQtyRef.current) {
      const { numIndex,index } = getActiveUnitIndex();
      if(numIndex === -1){
        const qty = servings[servingSliderIndex]?.selectedQuantity ? servings[servingSliderIndex]?.selectedQuantity : servings[servingSliderIndex]?.number_of_units;
        fullQtyRef.current.slideTo(qty);
      }
      else{
        fullQtyRef.current.slideTo(numIndex);
      }
    }
  },100)
  }, [fullQtyRef, servingSliderIndex]);

  useEffect(()=>{
   if(fullQtyRef.current){
    const { numIndex,index } = getActiveUnitIndex();
    if(activeNumberQtyIndex===0 && numIndex===1 && index===0 && activeFractionalIndex===0){
      fullQtyRef.current.slideTo(numIndex);
    }
   }
  },[activeFractionalIndex])

  const handleSlideChange = (swiper) => {
    const { numIndex, index } = getActiveUnitIndex();
    const activeIndex = swiper.realIndex;
    const slideToIndex =
      activeIndex === 0 && activeFractionalIndex === 0 && numIndex === 1 && index === 0
        ? 1
        : activeIndex;
    swiper.slideTo(slideToIndex);
    setActiveNumberQtyIndex(slideToIndex);
  };
      
    return (
        <>
          <Swiper
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={5}
              centeredSlides={true}
              className="mySwiper"
              onInit={(swiper) => fullQtyRef.current = swiper}
              onSlideChange={handleSlideChange}
              onSwiper={setImagesNavSlider}
            >
                {items.map((item, index) => (
                  <SwiperSlide>
                    <span
                        key={index}
                    >
                        {item}
                    </span>
                  </SwiperSlide>
                ))}
          </Swiper>
          <Swiper thumbs={{ swiper: imagesNavSlider }} modules={[Thumbs]} />
        </>
    )
}