import { Line } from 'rc-progress';
import classNames from 'classnames';
import MealCard from './MealCard';
import NutPlan from './NutPlan';
import Combos from './Combos';
import SelectedProvider from './SelectedProvider';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Progress from 'components/Progress';
import ErrorComponent from 'components/ErrorComponent';
import Tooltip from '@material-ui/core/Tooltip';
import MealsDietPlanner from 'components/shop/MealsDietPlanner';
import Footer from 'layout/Footer';
import EditDietPrefrences from 'pages/EditDietPrefrences';

const FreshMealsWrapper = styled.div`
  overflow-y: auto;
  margin-bottom: 20px;
  &.desktopPageInner.mobileView {
    padding-bottom: 125px !important;
  }

  h3 {
    width: 90%;
    margin: 0 auto;
    padding: 31px 0 18px;
    color: var(--dark);
    font-size: 18px;
    font-weight: 600;
  }

  .selected-meal-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -3px 6px #00000019;
  }

  .selected-meal-container {
    width: 90%;
    margin: 0 auto;
    padding: 24px 0 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selected-meal-container p {
    margin-bottom: 6px;
    color: var(--dark);
    font-size: 14px;
  }

  .selected-meal-container span {
    margin-top: 5px;
    color: var(--nutritt-green);
    display: inline-flex;
    align-items: center;
  }

  .selected-meal-container button {
    width: 160px;
    height: 44px;
    color: #fff;
    background-color: var(--nutritt-green);
    font-size: 16px;
    font-weight: 600;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
  }

  .selected-meal-container button:disabled {
    cursor: not-allowed;
    background-color: #c3cbc9;
  }
  .meal-tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    max-height: 30px;
  }
  .meal-tooltip-icon {
    margin-left: 12px;
    color: var(--nutritt-green);
  }
  .nutritional-plan-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 3;
    background-color: #fff;
    border-top: 2px solid #e0e3e0;
    border-bottom: 2px solid #e0e3e0;

    .container {
      width: 90%;
      margin: 0 auto;
      padding: 24px 0;
    }
  }

  .MuiTooltip-popperArrow .MuiTooltip-tooltip {
    margin: 5px 0;

    .MuiTooltip-arrow {
      color: rgba(97, 97, 97, 0.9) !important;
    }
  }

  @media (min-width: 992px) {
    overflow-y: hidden;
    height: 100vh;
    max-height: fit-content;
    padding-bottom: 0 !important;

    .shop-meals-container {
      height: 100%;
      display: flex;
    }

    .provider-meals-wrapper {
      overflow-y: auto;
      height: 100%;
      width: calc(100% - 430px);
      padding-top: 70px;
      background-color: #f6f5f8;

      .shopMeals {
        display: block;
      }
    }

    .provider-meals-info {
      min-height: calc(100vh - 216px);
      padding: 0 24px 40px;
    }

    .meals-card-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      grid-gap: 24px 14px;
    }
  }
  @media(max-width:991px){
    &.desktopPageInner.isDragMeals {
      overflow-y: hidden;
    }
    .fresh-meal{
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #51635D;
      font-style: normal;
      font-family: 'Roboto';
      padding:15px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter_icon{
        cursor: pointer;
      }
    }
  }
  .no-meal-provider {
    text-align:center
  }
`;

const NoMealAvailable = ({ text }) => {
  return (
    <div className="no-meal-provider">
      <h3>{text}</h3>
    </div>
  );
};
export default function Meals() {
  const [meals, setMeals] = useState([]);
  const [comboComponents, setComboComponents] = useState([]);
  const [isDragMeals, setIsDragMeals] = useState(false);
  const history = useHistory();
  let {
    settings,
    cartCount,
    settings: { mealPlan },
  } = useShopContext();
  const {
    isMobile,
    isVisitor,
    profile : {selfMealPlan = {}, dietTemplate}
  } = useAppContext();
  const maxMealsCount = settings.days * settings.meals;
  const [getMeals, { isLoading, error }] = useRequest();
  const [isDietPreferences, setIsDietPreferences] = useState(false);
  useEffect(() => {
    if (!settings.provider) return history.push('/shop/providers');
    getMeals({
      path: `provider/meals/${settings.provider.id}?noEmptyComponents=true&rawPrice=false`,
    }).then(({ meals, components }) => {
      setMeals(meals);
      setComboComponents(components);
    });
  }, []);
  // if (isLoading || !settings.provider) return <Progress />;
  if (error) return <ErrorComponent error={error} />;
  const mobileView = isMobile ? 'mobileView' : '';
  return (
    <>
    <FreshMealsWrapper className={classNames(`desktopPageInner ${mobileView}`, {isDragMeals})} style={{height: isMobile ? window.innerHeight - 52 : window.innerHeight}}>
      <div className="shop-meals-container">
        <div className="provider-meals-wrapper">
          <div className='provider-meals-info'>
          <SelectedProvider provider={settings.provider} />
          {/* {!isMobile && (
            <div className="nutritional-plan-wrapper">
              <div className="container">
                <NutPlan displayImg mealPlan />
              </div>
            </div>
          )} */}
          {false && comboComponents.length > 0 && (
            <>
              {isMobile ? (
                <div className={`${mobileView}`}>
                  <div className="Customize-meal-label">
                    <h3>Customize your meal</h3>
                    <div className="mobile-customize-meal">
                      <Combos comboComponents={comboComponents} />
                      <img src="/icons/arrow-right.svg" alt="Arrow Icon" />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {/* <h3>Customize your meal</h3>
                  <Combos comboComponents={comboComponents} /> */}
                </>
              )}
            </>
          )}
           {isLoading ? (
        <Progress />
          ) :meals.length ? (
            <div className={`${mobileView}`}>
              <div className="meals-card-list">
                {isMobile && <p className='fresh-meal'>Fresh meals <img src='/icons/filter-gray.svg' onClick={() => setIsDietPreferences(true)} className='filter_icon'/></p>}
                {meals.map((meal) => {
                  if(meal.isActive === true){
                  return (
                    <MealCard
                      key={meal.id}
                      {...meal}
                      mealsCount={cartCount}
                      maxMealsCount={maxMealsCount}
                    />
                  );
                  }
                })}
              </div>
            </div>
          ) : (
            <NoMealAvailable text="This provider has no meals to show." />
          )}
          {isMobile && <NutPlan displayImg mealPlan setIsDragMeals={setIsDragMeals} />}
          </div>
          {!isMobile && <Footer />}
          </div>
        {!isMobile && <MealsDietPlanner />}
      </div>
    </FreshMealsWrapper>
    {isMobile && isDietPreferences && <EditDietPrefrences selfMealPlan={selfMealPlan || dietTemplate} preventUpdate={true} buttonTitle={'Apply'} editPrefrenceModal={isDietPreferences} setEditPrefrenceModal={setIsDietPreferences} showMacroBreakdown={false}/>}
    </>
  );
}

