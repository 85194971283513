import { useEffect } from 'react';
import { DEFAULT_ACTIVITY } from '../../constants';
export default function CustomizePalGender({
  useCustom,
  gender,
  customizeValue,
  setCustomizeValue,
  activityType,
  form,
  setForm,
}) {
  const customValue = (e, key) => {
    let value = parseFloat(e.target.value); 
    if (value < 1.0) value = 1.0;
    if (value > 1.9) value = 1.9; 
  
    setCustomizeValue({
      ...customizeValue,
      [gender]: { ...customizeValue[gender], [key]: Number(value )},
    });
  };

  useEffect(()=>{
    setForm({...form, PAL: customizeValue, PAL_formula: activityType})
  },[customizeValue]);

  const renderInput = (key) => (
    <input
      type="number"
      min={1.0}
      max={1.9}
      value={(useCustom ? form.PAL : DEFAULT_ACTIVITY[activityType])[gender][key]}
      onChange={(e) => customValue(e, key, gender)} // wheres gender
      disabled={!useCustom}
    />
  );

  const options = [
    { key: 'inactive', label: 'Inactive' },
    { key: 'slightly', label: 'Slightly Active' },
    { key: 'moderately', label: 'Moderately Active' },
    { key: 'active', label: 'Active' },
    { key: 'extremely', label: 'Extremely Active' },
  ];

  return (
    <div className="form-group-wrapper">
      {options.map(({ key, label }, i) => (
        <div key={`form-group-id-${i}`} className="form-group">
          <label>{label}</label>
          {renderInput(key)}
        </div>
      ))}
    </div>
  );
}

