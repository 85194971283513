import classNames from 'classnames';
import styled from 'styled-components';
import PantryFooter from 'components/Pantry/PantryFooter';
import PlanListHeader from 'components/shop/CoachProfile/PlansList';
import PlanCard from 'components/shop/CoachProfile/PlansList/PlanCard';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import React, { useState, useEffect } from 'react';
import RegisterForm from 'components/auth/RegisterForm';
import { useLocation } from 'react-router-dom';
import { FormContainer } from 'components/auth/StyledLoginForm';
import RegisteredDetitianCard from 'components/OnboardingPlans/RegisteredDetitianCard';
import NutrittSteps from 'components/NutrittSteps';
import Progress from 'components/Progress';


const MealPlansListStyle = styled.div`
  min-height: calc(100vh - 210px);
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0 50px !important;
  justify-content: space-between;

  .plan-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 24px;

    // &.planCardWrapper {
    //   display: flex;
    //   justify-content: center;
    //   flex-wrap: wrap;

      & > div {
        width: 95%;
        margin-bottom : 10px;
        margin-left : 2.5%;
      }
    // }
  }

  @media (max-width: 991px) {
    min-height: calc(100vh - 32px);
    background-color: #f6f5f8;

    .plan-card-wrapper {
      grid-gap: 0;
    }
  }
`;

const steps = ['Plan', 'Get started', 'Personalize', 'Preview'];

export default function MealPlansList() {
  const { user , nutritionistId, isMobile,isVisitor ,setProfile , profile , isGuest , nutritionistInfo: nutritionist} = useAppContext();
  const [getMealPlanList, {isLoading : isPlanListLoading}] = useRequest();
  const [mealList, setMealList] = useState();
  const [registerCard,setRegisterCard] = useState(false)
  const [mealPlanId,setMealPlanId]=useState(null)
  const { pathname } = useLocation();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  useEffect(() => {
    getMealPlanListData();
    (isGuest || isVisitor) && setProfile({...profile , dietTemplateId:null})
    document.body.style.overflowY = "overlay"
  }, []);
  const getMealPlanListData = () => {
    const selectedNutritionistId = (!isVisitor && user && user?.nutritionist) ? user?.nutritionist?.id : nutritionistId;
    const route = `nutritionist/dietTemplate?nutritionistId=${selectedNutritionistId}`;
    getMealPlanList({
      path: route,
    })
      .then((data) => {
        setMealList(data?.dietTemplates);
      })
      .catch(console.log);
  };
  const [register, { isLoading, error, setError }] = useRequest('auth/register', 'post');
  return registerCard? <>
  <FormContainer>
  {isMobile ? (
    <div className="dietitian-card-wrapper">
      <RegisteredDetitianCard
        dietitianPhoto={nutritionist?.img || '/images/avatar.png'}
        name={nutritionist?.name}
        title={nutritionist?.title}
        setMealPlanId={setMealPlanId}
        socialProfile={nutritionist?.socialProfile}
      />
    </div>
  ) : (
    ""
  )}
  {isMobile && (
    <NutrittSteps
      title="Let's get started"
      description="Create an account and begin your health journey"
      steps={steps}
      stepNumber={2}
      activeStep={1}
      stepTitle="Get Started"
      perviousLink={() => {
        setMealPlanId(null);
        window.scrollTo(0, 0);
      }}
    />
  )}
  </FormContainer>
  <RegisterForm register={register}  error={error} setError={setError} isLoading={isLoading} nutritionistId={nutritionistId} mealPlanId={mealPlanId}/> </> : (
    <>
      <MealPlansListStyle>
        <PlanListHeader
          planTitle="My meal plans"
          planDescription="Delivered by a verity of local meal providers"
        />
        {
          isPlanListLoading ? <Progress/> :
          mealList?.length ?
        <div className={classNames('plan-card-wrapper', {planCardWrapper: mealList?.length < 4})}>
        {  mealList?.map((item, index) => {
          return (
            <PlanCard
              name={item?.name}
              image={item?.image}
              duration={item?.duration}
              duration_type={item?.duration_type}
              tags={item?.tags}
              introduction={item.introduction}
              mealPlan={true}
              id={item?.id}
              key={index}
              setRegisterCard={setRegisterCard}
              setMealPlanId={setMealPlanId}
            />
          );
        })
      }
        </div>:
          <div style={{minHeight : "40vh" ,display:"flex" , justifyContent :"center" , marginTop: "40px"}}>
         <h3 style={{display:"flex" , justifyContent :"center" }}> No meal plans are available</h3>
       </div>
          
        }
      </MealPlansListStyle>
      {isMobile && user?.id && <PantryFooter myCoach={true} shop={false} progress={false}/>}
    </>
  );
}