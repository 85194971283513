import Tag from './Tag';
import styled from 'styled-components';

const Container = styled.div`
  h2 {
    margin-top: 30px;
  }
`;
const TagsContainer = styled.div`
  margin-bottom: 11px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  grid-row-gap: 20px;
`;
export default function MealTags({ allTags, meal, setMeal }) {
  // console.log("meal", meal);
  const { tags, allergies } = allTags;

  const handleAlergyClick = (selectedTag, isSelected) => {
    // console.log({ selectedTag, isSelected });
    setMeal((m) => ({
      ...m,
      restrictions: isSelected
        ? m.restrictions?.filter((tag) => tag !== selectedTag)
        : [...m.restrictions, selectedTag],
    }));
  };
  const handleTagClick = (selectedTag, isSelected) => {
    // console.log({ selectedTag, isSelected });
    setMeal((m) => ({
      ...m,
      tags: isSelected ? m.tags?.filter((tag) => tag !== selectedTag) : [...m.tags, selectedTag],
    }));
  };
  return (
    <Container>
      <h2>Restrictions</h2>
      <TagsContainer>
        {allergies?.map((tag) => (
          <Tag
            tag={tag}
            key={tag}
            onTagClick={handleAlergyClick}
            selectedTags={meal.restrictions}
          />
        ))}
      </TagsContainer>
      <h2>Tags</h2>
      <TagsContainer>
        {tags?.map((tag) => (
          <Tag tag={tag} key={tag} onTagClick={handleTagClick} selectedTags={meal.tags} />
        ))}
      </TagsContainer>
    </Container>
  );
}
