import React, { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import { BsCaretDownFill, BsCaretUp } from 'react-icons/bs';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CoachingDisbursement from './coachingDisbursement';
import Progress from 'components/Progress';

const TableWrapper = styled.div`
 display: flex;
  justify-content: center;
  margin-top: 40px;
  width:100%
  max-width: 95%;
  tr {
  text-align: center !important;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #52c41a;
    color: white;
    text-align: center !important;
    padding: 8px;
    width: 180px;
    border: 1px solid #ddd;
  }
  tr button {
    height: 40px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
`;
const HoverWrapper = styled.div`
  .pay_button {
    color: #52c41a;
  }
  .pay_button:hover {
    background-color: #52c41a !important;
    color: white;
  }
  .pay_button:focus {
    // box-shadow:none !important;
    box-shadow: 0 0 0 4px #52c41a !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export default function Disbursement(props) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [getDisbursement, { isLoading }] = useRequest();
  const [updateDisbursement] = useRequest('financials/disbursements/update', 'put');
  const [disbursementData, setDisbursementData] = useState();
  const [isMealsReport, setMealsReport] = useState('mealsReport');
  const getDisbursementFn = () => {
    const path = `financials/disbursements?type=${props.type.toLowerCase()}`;
    getDisbursement({ path, method: 'GET' })
      .then((data) => {
        {
          setDisbursementData(data);
        }
      })
      .catch(console.log);
  };

  useEffect(getDisbursementFn, []);

  const handleExpandRow = (event, uuid) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(uuid);

    let obj = {};
    isRowExpanded ? (obj[uuid] = false) : (obj[uuid] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== uuid)
      : currentExpandedRows.concat(uuid);
    setExpandedRows(newExpandedRows);
  };

  const onDisbursementClick = useCallback((item) => {
    const ids = item.disbursementList.map((data) => data.id);
    updateDisbursement({ body: { ids, datePaid: new Date().toISOString() } })
      .then((result) => {
        toast.success('Disbursement has been made successfully');
        getDisbursementFn();
      })
      .catch((e) => {
        toast.error('Oops!, something went wrong');
      });
  }, []);
  const onVoidClick = useCallback((item = {}, isVoid) => {
    const ids = item.disbursementList.map((data) => data.id);
    updateDisbursement({ body: { ids, isVoid: true } })
      .then((result) => {
        toast.success('Record has been marked as void');
        getDisbursementFn();
      })
      .catch((e) => {
        toast.error('Oops!, something went wrong');
      });
  }, []);

    return (
        <TableWrapper>
          <div style={{ display: 'flex', position: 'absolute', left: 0, top: 65}}>
            <Link to="/admin" style={{ margin: '0 8px' }}>
              Admin home
            </Link>
            {props.type === 'Nutritionist' && (
              <>
              <span
               onClick={() => {
                  setMealsReport('mealsReport');
               }}
               style={{
                cursor: 'pointer',
                marginRight: '10px',
                color: '#551A8B',
                margin: '0 8px',
               }}
               >
                 Meals Report
               </span>
               <span
              onClick={() => {
                setMealsReport('offeringReport');
              }}
              style={{ cursor: 'pointer', margin: '0 8px', color: '#551A8B' }}
            >
              Offering Report
            </span>
              </>
            )}
          </div>  
            <Container>
                <Row>
                    <Col>
                        <HeaderWrapper>
                          {
                            <h1>
                              {props.type}{' '}
                              {props.type === 'Nutritionist' && (
                                <>{isMealsReport === 'mealsReport' ? 'Meals' : 'Offering'}</>
                              )}{' '}
                               Disbursement
                            </h1>
                          }
                        </HeaderWrapper>
                        <p style={{ display: 'block', marginTop: '65px' }}>
                          Note: this report is updated every hour.
                        </p>
                    </Col>
                </Row>
                <CoachingDisbursement
                  disbursementData={disbursementData}
                  isMealsReport={isMealsReport}
                >      
                </CoachingDisbursement>
                {isMealsReport === 'mealsReport' && (
                <Row>
                    <Col sm={12}>
                        <Table>
                            <thead>
                            <tr>
                                <th style={{width: "450px"}}>{props.type}</th>
                                <th style={{width: "150px"}}>Amount</th>
                                <th style={{width: "200px"}}>Period</th>
                                <th style={{width: "100px"}}></th>
                                <th style={{width: "100px"}}></th>
                                <th style={{width: "100px"}}></th>
                            </tr>
                            </thead>
                            {isLoading ? (
                              <tbody>
                                <tr>
                                  <td>
                                    <Progress />
                                  </td>
                                </tr>
                              </tbody>
                            ) : ( 
                            <tbody>
                              {disbursementData?.length ? (
                                disbursementData?.map((item) => (
                                        <>
                                            <tr key={item.id}>
                                                <td>{item['provider']['name']}</td>
                                                <td>${(item['total'] / 100).toFixed(2)}</td>
                                                <td>
                                                    {moment(item.periodStart).utc().format('ll')} <strong>-</strong>{' '}
                                                    {moment(item.periodEnd).utc().format('ll')}
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="link"
                                                        onClick={event => handleExpandRow(event, item.uuid)}
                                                    >
                                                      {expandState[item.uuid] ? <BsCaretUp/> : <BsCaretDownFill/>}
                                                    </Button>
                                                </td>
                                                <td>
                                                    <HoverWrapper>
                                                        <Button
                                                            className="pay_button"
                                                            type="button"
                                                            variant="outline-primary"
                                                            onClick={() => onDisbursementClick(item)}
                                                        >
                                                            Pay
                                                        </Button>
                                                    </HoverWrapper>
                                                </td>
                                                <td>
                                                    <Button variant="outline-secondary" onClick={() => onVoidClick(item)}>
                                                      Void
                                                    </Button>
                                                </td>
                                            </tr>
                                            <>
                                                {expandedRows.includes(item.uuid) &&
                                                    <Table>
                                                        <thead>
                                                        <tr>
                                                            {props.type === 'Nutritionist' && <th>Diet Template</th>}
                                                            <th style={{width: "50px"}}>DisbursementId</th>
                                                            <th style={{width: "50px"}}>OrderMainId</th>
                                                            <th style={{width: "50px"}}>Promo Code</th>
                                                            <th style={{width: "50px"}}>Discount Amount</th>
                                                            <th style={{width: "50px"}}>Discount Type</th>
                                                            <th style={{width: "50px"}}>Disbursement Created Date</th>
                                                            <th style={{width: "50px"}}>User Paid</th>
                                                            <th style={{width: "70px"}}>Amount</th>
                                                            <th style={{width: "140px"}}>Date Ordered</th>
                                                            <th>OrderLine Details</th>
                                                            <th>Order Completed</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {item.disbursementList.map((item) => {
                                                            return (
                                                              <>
                                                                <tr>
                                                                    {props.type === 'Nutritionist' && <td>{item.providerOrder.orderMain.dietTemplate.name}</td>}
                                                                    <td>{item.id}</td>
                                                                    <td>{item.providerOrder.orderMain.id}</td>
                                                                    <td>{item.providerOrder.providerOrderSummary.promoCode}</td>
                                                                    <td>{item.providerOrder.providerOrderSummary.promoCodeDiscount}</td>
                                                                    <td>{item.providerOrder.providerOrderSummary.promoCodeDiscount_type}</td>
                                                                    <td>{moment(item.createdAt).utc().format('ll')}</td>
                                                                    <td>{item.providerOrder.orderMain.paymentInfo.xAuthAmount}</td>
                                                                    <td>{(item.amount / 100).toFixed(2)}</td>
                                                                    <td>{moment(item.providerOrder.orderMain.dateOrdered).format('ll')}</td>
                                                                    <td>{item.providerOrder.orderMain.orderLine.map(item => {
                                                                        return <>
                                                                            <span>{item.data.name} X ({item.quantity})</span><br/></>
                                                                    })}</td>
                                                                    <td>{moment(item.providerOrder.orderMain.providerOrderAction[0].date).format('ll')}</td>
                                                                </tr>
                                                              </>
                                                            );  
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                }
                                            </>
                                        </>
                                ))
                              ) : (
                                    <tr>
                                        <td></td>
                                        <td>No record</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                              )}
                            </tbody>
                            )}
                        </Table>
                    </Col>
                </Row>
                )}
            </Container>
        </TableWrapper>
    )
};
