import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { Circle } from 'rc-progress';
import Styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import ProgressMacroLine from '../../../components/Pantry/ProgressMacroLine';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useOnboardingContext } from '../index'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";



const FlexColumn = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserProgressWrapper = Styled.div`
  // overflow: hidden;
  // min-height: 170px;
  margin-bottom: 14px;
  padding: 32px 0 28px;
  background: #FFFFFF; 
  border-radius: 15px;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  &.dailyProgressHeight {
    padding: 14px 0;
    .slick-dots {
      bottom: -28px;
    }
  }
  &.dailyProgressSmallHeight {
    .cals-wrapper {
      transform: scale(0.8);
    }

    .slick-dots {
      bottom: ${window.innerHeight < 600 ? 0 : '-30px'};
    }
  }
  .slide,
  .calc-circle-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .cals-wrapper {
    position: absolute;
    color: #51635D;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${window.innerHeight < 600 ? '166px' : '176px'};
    height: ${window.innerHeight < 600 ? '166px' : '176px'};
    justify-content: center;
    border: 3px solid #EBEBEB;
    border-radius: 125px;
    .cals-count {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
    .cals {
      margin: 5px 0 10px;
      font-size: 12px;
      line-height: 14px;
    }
    .cals-left-wrapper {
      color: #AFB5B4;
      font-size: 16px;
      line-height: 19px;
      ${FlexColumn};
      span:last-child {
        margin-top: 5px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  svg {
    min-width: 120px;
  }
  .slick-dots {
    bottom: -40px;
    li {
      margin: 0 6px;
    }
    li button:before {
      color: #E0E3E0;
      font-size: 9.2px;
      opacity: 1;
    }
    li.slick-active button:before {
      color: #949D9B;
      opacity: 1;
    }
  }
  .macros-wrapper {
    justify-content: center;
    ${FlexColumn};
  }
  .macros-title {
    color: #51635D;
    font-size: 18px;
    line-height: 24px;
  }
  .slick-track{
    height:200px;
  }
  .swiper{
    padding-bottom: 50px;
  }
  .swiper-pagination-bullets{
    bottom: 0;
    .swiper-pagination-bullet{
      opacity: 0.1;
    }
    .swiper-pagination-bullet-active{
      background-color: #949D9B;
      opacity: 1;
    }
  }
  &.dailyProgressHeight{
    margin-top: -20px;
    .swiper{
      padding-bottom: 30px;
    }
    .swiper-slide{
      background-color: rgba(0,0,0,0);
    }
  }
`;

export default function UserProgress({ isSwipe, setIsSwipe, visitorData, newCalories, setNewCalories }) {
  const [percent, setPercent] = useState(100);
  const [slide, setSlide] = useState(0);
  const intervalId = useRef(null);
  const heightCalc = window.innerHeight - 468;
  const { onboardingUser } = useOnboardingContext();

  const {
    profile: {
      dietMetrics: { calories, macros },
    } = { dietMetrics: { macros: {} } },
  } = visitorData || {};
  const { profile } = useAppContext();

  return (
    <UserProgressWrapper
      style={{ height: window.innerHeight < 600 ? 'auto' : heightCalc }}
      className={classNames({
        dailyProgressHeight: window.innerHeight < 700,
        dailyProgressSmallHeight: heightCalc <= 170,
      })}
    >
      <div>

      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
        prev: {
            translate: ["-80%", 0, -1],
            opacity: 0,
        },
        next: {
            translate: ["100%", 0, 0],
            opacity: 0,
        },
        }}
        pagination={{
            clickable: true,
        }}
        modules={[EffectCreative, Pagination]}
        className="mySwiper"
        loop={true}
        speed={300}
      >
          <SwiperSlide>
          <div className="slide">
              <div className="calc-circle-wrapper">
                <Circle
                  percent={percent}
                  strokeWidth={4}
                  strokeColor={"#52C41A"}
                  trailWidth={4}
                  trailColor="#EBEBEB"
                  strokeLinecap="square"
                  style={{
                    width: '200px',
                    height: window.innerHeight < 600 ? '150px' : 'auto'
                  }}
                />
                <div className="cals-wrapper">
                  <span className="cals-count">{calories}</span>
                  <span className="cals">cal</span>
                  <div className="cals-left-wrapper">
                    <span>
                      {calories}
                    </span>
                    <span>left</span>
                  </div>
                </div>
              </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="slide">
              <div
                className="macros-wrapper"
                style={{
                  height:
                    heightCalc > 170
                      ? window.innerHeight < 700
                        ? `${heightCalc - 50}px`
                        : `${heightCalc - 97}px`
                      : '120px',
                }}
              >
                <div className="macros-title">Macros</div>
                <ProgressMacroLine
                  slide={slide}
                  item="Fats"
                  consumedMealItem={macros?.fat}
                  userDietMacro={macros?.fat}
                  lineColor="#FEB95A"
                  weight="g"
                  isSwipe={isSwipe}
                  animate={false}
                />
                <ProgressMacroLine
                  slide={slide}
                  item="Proteins"
                  consumedMealItem={macros?.protein}
                  userDietMacro={macros?.protein}
                  lineColor="#EE786B"
                  weight="g"
                  isSwipe={isSwipe}
                  animate={false}
                />
                <ProgressMacroLine
                  slide={slide}
                  item="Carbs"
                  consumedMealItem={macros?.carbs}
                  userDietMacro={macros?.carbs}
                  lineColor="#916AE2"
                  weight="g"
                  isSwipe={isSwipe}
                  animate={false}
                />
              </div>
          </div>
          </SwiperSlide>
      </Swiper>
      </div>
    </UserProgressWrapper>
  );
}