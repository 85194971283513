import { useVisitorContext } from 'context/visitorContext';
import Header from 'layout/Header';
import { Link } from 'react-router-dom';
import { ReactComponent as ShoppingCart } from 'icons/shopping-cart.svg';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import VisitorUserFooter from '../VisitorUserFooter';
import { useHistory, useLocation } from 'react-router-dom';

const StyledCartLink = styled(Link)`
  margin-right: 12px;
  color: gray;
  position: relative;
  align-items: center;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }
  .cart-number {
    position: absolute;
    left: 15px;
    top: -10px;
    background: #eb3223;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    .active{
      
    }
  }

  @media (min-width: 992px) {
    margin-right: 20px;

    svg {
      width: 32px;
      height: 32px;
    }

    .cart-number {
      top: -5px;
      left: 19px;
      width: auto;
      height: 18px;
      min-width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      font-family: 'Roboto';
      border-radius: 10px;
      padding: 2px;
   
    }
  }
`;


const CartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-self: end;
`;

export default function VisitorHeaderNew() {
  const { cartCount, noNavigation } = useVisitorContext();
  const { isMobile } = useAppContext();
  const history = useHistory();

  return (
    <Header>
      <>
        {!isMobile ?
          <>
            {!noNavigation && <>
            <VisitorUserFooter />
            <CartContainer>
              <StyledCartLink to="/visitor/get-started">
                <ShoppingCart />
                {cartCount > 0 &&
                  <span className="cart-number">{cartCount}</span>}
              </StyledCartLink>
            </CartContainer>
            </>
          }
          </>
          :
          <img
            onClick={() => {
              history.push('/visitor/get-started');
            }}
            src={'/icons/Sandwich.svg'}
            alt="Sandwich Icon"
            className={'header-sandwich-menu'}
          />
        }
      </>
    </Header>
  );
}

