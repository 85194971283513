import Drawer from '@material-ui/core/Drawer';
import { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import styled from 'styled-components';
import NavItems from './NavItems';
const StyledNav = styled.nav`
  width: 150px;

  a {
    width: 150px;
    display: block;
    /* text-align: center; */

    /* grid-template-columns: 1fr auto; */
    /* grid-gap: 5px; */
    /* align-items: center; */
    color: gray;
    font-size: 1.1rem;
    /* height: 50px; */
    padding: 10px;
    text-decoration: none;
    padding: 10px;
    /* margin-bottom: 5px; */
    svg {
      font-size: 1rem;
      margin-right: 10px;
    }
  }
  .active {
    color: white;
    background: #52c41a;
  }
`;

const StyledHamburger = styled(GiHamburgerMenu)`
  font-size: 1.5rem;
  color: #52c41a;
  cursor: pointer;
`;
export default function NavDrawer({ updateProvider, provider, isAlert }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ justifySelf: 'end', paddingTop: '5px' }}>
      <img src="/icons/Sandwich.svg" alt="Sandwich Icon" onClick={() => setIsOpen(!isOpen)} />
      <Drawer
        // disableBackdropClick={true}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ padding: '20px' }}
      >
        <StyledNav onClick={() => setIsOpen(!isOpen)}>
          <NavItems
            isMobile={true}
            updateProvider={updateProvider}
            provider={provider}
            isAlert={isAlert}
          />
        </StyledNav>
      </Drawer>
    </div>
  );
}
