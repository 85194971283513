import { Button } from '@material-ui/core';
import styled from 'styled-components';
import ProviderGroups from 'components/cartSummary/ProviderGroups';
import SummaryTotals from 'components/cartSummary/SummaryTotals';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router-dom';
const StyledCart = styled.div`
  background: #f6f6f6;
  padding: 20px;
  margin-top: 20px;
  display: grid;
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  hr {
    color: gray;
  }
`;

export default function Cart() {
  const { settings, cart, taxRate } = useShopContext();
  const history = useHistory();
  const { userInfo, days, meals, providerSettings } = settings;
  const { address, address2, zipcode } = userInfo;
  return (
    <StyledCart>
      <h2 style={{ justifySelf: 'center' }}>Review your order</h2>
      <div style={{ background: 'white', padding: 20, marginBottom: 20 }}>
        <div style={{ marginBottom: 10 }}>
          <h6 style={{ display: 'inline' }}>Address</h6> {[address, address2, zipcode].join(', ')}
        </div>
        <h6>
          <span style={{ fontSize: 25 }}>{days}</span> days
          <span style={{ fontSize: 25, marginLeft: 20 }}>{meals}</span> meals per day
        </h6>
      </div>
      <ProviderGroups cart={cart} providerSettings={providerSettings} taxRate={taxRate} />
      {providerSettings.length > 1 && (
        <div style={{ padding: '20px', marginTop: 20 }}>
          <SummaryTotals cart={cart} isGrandTotal={true} taxRate={taxRate} />
        </div>
      )}
      <Button
        style={{ marginTop: 10 }}
        color="primary"
        onClick={() => history.push('/shop/checkout')}
      >
        Proceed to checkout
      </Button>
    </StyledCart>
  );
}
