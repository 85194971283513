import styled from 'styled-components';
const Container = styled.div`
  padding: 10px;
  border: 1px solid #ffa4a4;
  margin-top: 10px;
  border-radius: 5px;
  background: #fff0f2;

  @media (max-width: 991px) {
    margin-top: 5px;
    padding: 5px;
    font-size: 12px;
  }
`;
export default function ErrorComponent({ error, title = 'Error:', style = {} }) {
  return (
    <Container style={style}>
      <b>{title}</b>
      {title && <br />}
      {error}
    </Container>
  );
}

