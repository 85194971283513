import { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { equals, propEq } from 'ramda';
import { useAppContext } from 'context/appContext';
import classNames from 'classnames';
import { theme } from '../../../shared/theme/theme';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import RegularInput from 'components/Input/RegularInput';
import { Button } from '../button/Button';
import { useRequest } from 'hooks/useRequest';


const StyledSelectContainer = styled.div`
  position: ${({ icon }) => icon ? "" : "relative"};
  width: 100%;
  font-size: 14px;
  text-transform: capitalize;
  text-align: right;
  cursor: pointer;

  &:last-child {
    margin-right: 8px;
  }

  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

  .select-label {
    margin-bottom: 7px;
  }
  .options-dropdown {
    margin-top:13px;
    border: 1px solid #e0e3e0;
    border-radius: 4px;
    cursor: pointer;
    width:100%;
  }

  .selected-value {
    height: 40px;
    padding: 0 14px;
    background: ${theme.colors.lightGrey};
    min-width: ${({ width }) => width || '240px'};
    color: ${theme.colors.blackLighter};
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 1px solid  ${theme.colors.grayLight};


    &:hover{
      background-color: ${theme.colors.lightGrey};
          }

    img{
      width:20px;
      height:20px;
    }
  }
  .selected-img {
    display: flex;
    justify-content: flex-end;

    img{
      width:20px;
      height:20px;
      cursor: pointer;
      &:hover{
        background-color:${theme.colors.lightGrey};
       }
    }
  }
  .options-dropdown {
    position: absolute;
    top: auto;
    // right: 0;
    pointer-events: auto;
    min-width:${({ width }) => width || '240px'};
    z-index: 2;
    overflow: auto;
    max-height: 160px;
    background: ${theme.colors.white};
    padding: 10px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  }
  
  .select-option {
    height: 40px;
    color: ${theme.colors.blueBlack};
    padding: 0px 12px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    display: flex;
    align-items: center;
    gap:8px;
    &.valueSelected {
      background-color: #F4F5F6;
    }  
    &:hover {
      background-color: ${theme.colors.background};
    }
  }

  .btn_flex {
    display: flex;
    justify-content: flex-end;
    padding: 8px 8px 0px;
    .custom_button {
      position: relative;
      &:last-child {
        background: ${({ theme }) => theme?.colors?.primaryDefault};
        color: ${({ theme }) => theme?.colors?.white};
      }
    }
  }

  .custom_button {
    padding: 10px 16px;
    font-size: ${({ theme }) => theme?.typography?.fontSmall};
    font-weight: 600;
    line-height: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 6px;
    border: none;
    color: ${({ theme }) => theme?.colors?.primaryDark};
    background: ${({ theme }) => theme?.colors?.white};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
 .crfCYK{
  padding:0px 5px;
 }
`;

const findFromValue = (val, options) =>
  options.find(equals(val)) || options.find(propEq('value', val));
export default function DropDown({
  label,
  updateValue,
  value,
  option = [1, 2, 3, 4, 5],
  defaultChecked = value,
  onChange,
  addIcon,
  icon,
  enableAdd = false,
  isDisabled = false,
  placeholderText,
  width,
  kitchenProvider,
  parentRef
}) {
  const [options, setOptions] = useState(option);
  const initialSelectedValue = options.find((option) => option.isPlaceholder);
  const [isClicked, setIsClicked] = useState(false);
  const [displayOptions, toggleDisplayOptions] = useState(false);
  const dropdownRef = useRef(null);
  const [addItem, setAddItem] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [createCategory] = useRequest(`meal/category`, 'post');
  const [isHovered, setIsHovered] = useState(false);
  const bottomRef = useRef(null);
  const [updatedStyle, setUpdatedStyle] = useState({});

  const handleIconHover = () => {
    setIsHovered(true);
  };

  const handleIconLeave = () => {
    setIsHovered(false);
  };

  const [selectedValue, setSelectedValue] = useState(
    findFromValue(defaultChecked, options) || initialSelectedValue,
  );
  const { isMobile } = useAppContext();

  useEffect(() => {
    if (options?.length <= 1) {
      setOptions(option);
    }
  }, [option]);

  useEffect(() => {
    if (defaultChecked) {
      const selectedOption =
        findFromValue(defaultChecked, options) || options[0]?.value || options[0];
      setSelectedValue(selectedOption);
    }
  }, [defaultChecked]);


  useEffect(() => {
    const opt = findFromValue(defaultChecked, options) || options[0]?.label || options[0];
    updateValue && updateValue(opt?.value || opt);
    equals(opt, selectedValue) || setSelectedValue(opt);
  }, [options.length]);

  useEffect(() => {
    if (addItem) {
      dropdownRef.current.scrollTop = dropdownRef.current.scrollHeight;
    }
  }, [addItem]);

  const handleToggleDisplayOptions = () => {
    setTimeout(() => {
      toggleDisplayOptions(!displayOptions);
      setIsClicked(!isClicked);  
    }, 0)
  };

  const handleChange = (option) => {
    setSelectedValue(option);
    handleToggleDisplayOptions();
    onChange && onChange(option.value || option);
    updateValue && updateValue(option.value || option);
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    const categoryName = value.replace(/ {2,}/g, ' ');
    setInputValue(categoryName);
  };

  const handleAddItem = useCallback(async (inputValue) => {
    if (inputValue.trim() === '') {
      return;
    }
    try {
      const result = await createCategory({
        body: {
          categoryName: inputValue,
          providerId: kitchenProvider?.id,
          businessProfileId: kitchenProvider?.BusinessProfile[0]?.id
        }
      });
      const addedOption = {
        label: result?.data?.createdCategory?.name,
        value: result?.data?.createdCategory?.id
      };
      const updatedOptions = [...option, addedOption];
      updateValue && updateValue(inputValue);
      setSelectedValue(inputValue);
      setOptions(updatedOptions);
      setInputValue('');
      setAddItem(false);
    } catch (error) {
      console.error(error);
    }
  }, [option, createCategory, updateValue, kitchenProvider]);

  useEffect(() => {
    if(dropdownRef?.current && parentRef?.current){
      const element = dropdownRef.current;
      const parentElement = parentRef.current;
      const parentBoundingRect = parentElement.getBoundingClientRect();
      const boundingRect = element.getBoundingClientRect();
      const isOverflowingBottom = boundingRect?.top + boundingRect?.height > parentBoundingRect?.height + parentBoundingRect?.top;
      const isOverflowingTop = parentBoundingRect?.top > boundingRect?.top;
      if(isOverflowingTop){
        setUpdatedStyle((prev) => ({
          ...prev,
          top: 'auto',
          bottom: 'auto'
        }))
      }
      if(isOverflowingBottom){
        setUpdatedStyle((prev) => ({
          ...prev,
          bottom: '55px',
          top: 'auto'
        }))
      }
      parentRef.current.style.overflow = "hidden";
    }
    return () => {
      if(parentRef?.current){
        parentRef.current.style.overflow = "auto";
      }
    }
  }, [displayOptions, dropdownRef, parentRef])

  return (
    <StyledSelectContainer className='select-wrapper' theme={theme} icon={icon} width={width}>
      {icon ? (
        <div
          className={classNames('selected-img', { selectedValue: displayOptions })}
          onMouseEnter={handleIconHover}
          onMouseLeave={handleIconLeave}
        >
          <img
            src={isHovered ? '/icons/right-icon-hover.svg' : icon}
            onClick={() => handleToggleDisplayOptions()}
            style={{
              color: selectedValue?.labelColor ? selectedValue.labelColor : '',
              pointerEvents: isDisabled ? 'none' : '',
            }}
            alt="icon"
          />
        </div>
      )
        :
        <>
          <div className="select-label">{label}</div>
          <div
            className={classNames('selected-value', { selectedValue: displayOptions })}
            onClick={() => handleToggleDisplayOptions()}
            style={{ color: selectedValue?.labelColor ? selectedValue.labelColor : '', pointerEvents: isDisabled ? 'none' : '', }}
          >
            <span className={selectedValue?.isPlaceholder ? 'select-placeholder' : ''}>
            {placeholderText ? <label style={{ marginRight: "10px" }}>{placeholderText}</label> : ""}  {selectedValue?.label && selectedValue?.label.length > 25 ? `${selectedValue?.label.slice(0, 25)}...` : selectedValue?.label || selectedValue}
            </span>
            {icon ? <img src={`/icons/${icon}.svg`} alt="icon" /> :
              displayOptions ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
          </div>
        </>
      }
      {displayOptions && (
        <>
          <div className="mask" onClick={() => toggleDisplayOptions(false)} />
          <div className="options-dropdown" ref={dropdownRef} style={updatedStyle}>
            {options
              .filter((option) => !option.isPlaceholder)
              .map((option) => (
                <div
                  key={option.value}
                  className={classNames('select-option', { valueSelected: selectedValue == option })}
                  onClick={() => handleChange(option)}
                >
                  {option.imgSrc && <img src={option.imgSrc} alt="Option image" />}
                  {option.label && option.label.length > 30
                    ? `${option.label.slice(0, 30)}...`
                    : option.label || option}
                </div>
              ))}
            {
              enableAdd && (
                !addItem ? (
                  <>
                  <div  style={{border:"1px solid #F0F2F4"}} />
                  <div className='select-option' onClick={() => { setAddItem(true) }}>
                    <img src='/icons/add-black.svg' alt="Add icon" />
                    Create a new category
                  </div>
                  </>
                ) : (
                  <>
                    <div  style={{border:"1px solid #F0F2F4"}} />
                    <RegularInput
                      placeholder="Input new category"
                      value={inputValue}
                      name="categoryName"
                      onChange={(e) => handleInputChange(e)}
                    />
                    <div className="btn_flex">
                      <Button title="Cancel" className="custom_button" onClick={() => setAddItem(false)} />
                      <Button
                        type={'dark'}
                        title="Save"
                        onClick={() => {handleAddItem(inputValue)}}
                        size='small'
                      />
                    </div>
                  </>
                )
              )
            }
      <div ref={bottomRef}></div>
          </div>
        </>
      )}
    </StyledSelectContainer>
  );
}
