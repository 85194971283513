import { useState, useEffect,useCallback,useMemo, useRef } from 'react';
import Styled from 'styled-components';
import Empty from './Empty';
import PantryDate from './PantryDate';
import SearchMeal from './SearchMeal';
import HowMuchModal from './HowMuchModal';
import SearchMacrosModal from './SearchMacrosModal';
import { useDebounce } from 'use-debounce';
import Progress from 'components/Progress';
import InfiniteScroll from 'react-infinite-scroll-component';
import BarCodeScanner from './BarCodeScanner';
import Drawer from '@material-ui/core/Drawer';
import {useLocation} from "react-router-dom";
import RecentLoggedMeals from './RecentLoggedMeals';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { GilroyFontWrapper } from 'shared/theme/style';
import BarcodeSearchResult from 'components/elements/barcodeSearch/BarcodeSearchResult';
import { ReactComponent as CrossIcon } from 'assets/shop/close-cross.svg'
import { AnimatePresence, motion } from 'framer-motion';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchTabWrapper = Styled.div`
  .input-scanCode-wrapper {
    margin: 24px 0 14px;
    ${FlexCenter};
    .input-wrapper {
      width: auto;
      margin: 0 12.5px 0 0;
      input {
        width: 275px;
        padding: 0 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #51635D;
        &:foucs{
          border-color: #00092D;
        }
      }
    }
    .scanCode-icon {
      width: 24px;
      height: 24px
    }
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      left: auto;
      width: 16px;
      height 16px;
    }
  }
  .search-meals-wrapper {
    overflow: auto;
    margin: 0 16px;
    &::-webkit-scrollbar {
      width: 0
    }
  }
  .search-empty-meal {
    overflow: auto;
    ${FlexCenter};
    & > div {
      height: 100% !important;
      justify-content: center;
    }
  }
  .fat-secret-wrapper {
    position: absolute;
    bottom: 13px;
    left: auto;
    width: 100%;
    ${FlexCenter};
    span {
      margin-right: 4px;
      color: #DCDCDC;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.02em;
    }
    a {
      ${FlexCenter};
    }
    img {
      width: 63px;
      height: 13px;
    }
  }
  .recent-logged-div{
    .recent-logged{
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      height: 32px;
      line-height: 32px;
      color: #00092D;
      padding-left:14px;
      margin-bottom:3px;
    }
    .meals-div{
      overflow:auto;
      &::-webkit-scrollbar {
        display:none;
      }
    }
  }
  .result{
    margin:22px 0 20px;
    padding-left:14px;
    display: flex;
    span{
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: #51635D;
      letter-spacing: 0.02em;
      // &:last-child{
      //   font-weight: 700;
      //   margin-left:6px;
      // }
    }
  }
`;
const BarcodeModelStyle = Styled.div`
   width:100%;
   background: #FFFFFF;
   padding: 0 16px 27px;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: center;
   background: rgba(11, 29, 23, 0.65);
   position: fixed;
   height: 100%;
   top: 0;
   left: 0;
   .modal{
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    padding-bottom: 24px;
    padding-top: 32px;
    position: relative;
    .cross_icon{
      position: absolute;
      top: 12px;
      left: 16px;
    }
   }
  .no-match {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #51635D;
    text-align: center;
  }
  .match-found-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 220px;
      height: 42px;
      background: #000A2C;
      color:#fff;
      font-weight: 700;
      font-size: 16px;
      line-height: normal;
      border-radius: 30px;
      padding: 18px 16px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.cancel-btn {
        padding: 14px 16px 12px;
        margin-top: 8px;
        background: #F0F2F4;
        color: #00092D;
      }
    }
  }
`

const mealMacrosData = {
  name: '',
  calories: Number,
  carbs: Number,
  fat: Number,
  protein: Number,
  img: '/images/dish.svg',
  fatSecretSearchResult: {}
};



export default function SearchTab({ 
  isLogMealsModal,
  setActiveDay, 
  activeDay, 
  getdiaryData, 
  customMealDiary, 
  isCustomMealSaving, 
  setIsLogMealsModal,
  customform,
  setCustomForm,
  setValue,
  currentDayinfo,
  updateFullDiaryMeals,
  resetParams
}) {
  const [searchValue, setSearchValue] = useState('');
  const [resultMeals, setResultMeals] = useState([]);
  const [mealInfo, setMealInfo] = useState({});
  const [isHowMuchModal, setIsHowMuchModal] = useState();
  const [isMacrosModal, setIsMacrosModal] = useState(false);
  const [mealId, setMealId] = useState();
  const [disableAddingMeals, setDisableAddingMeals] = useState(false);
  const [mealsCount, setMealsCount] = useState(0);
  const [foodId, setFoodId] = useState();
  const [isDone, setIsDone] = useState(false);
  const [searchMealsHeight, setSearchMealsHeight] = useState();
  const [mealMacroInfo, setMealMacroInfo] = useState({});
  const [searchQuantity,setSearchQuantity]= useState(1)
  const [value] = useDebounce(searchValue, 700);
  const [form, setForm] = useState(mealMacrosData);
  const [searchedFoodDetails, setSearchedFoodDetails] = useState({});
  const [logMealFromList, setLogMealFromList] = useState(false);
  const [totalPages,setTotalPages]=useState(1)
  const [currentPage,setCurrentPage]=useState(0)
  const [isEmpty, setIsEmpty] = useState(false);
  const [hasMore,setHasMore]=useState(false);
  const num="082666701200";
  const [barCode,setBarCode]=useState(null);
  const [camera,setCamera]=useState(false)
  const [barcodeModal, setBarcodeModal] = useState(false)
  const [loggedMealsHeight, setLoggedMealsHeight] = useState();
  const [loggedMeals, setLoggedMeals] = useState([]);
  const [getRecentLoggedMeals, { isLoading:isLoggedMealsLoading, error: err }] = useRequest();
  const [customMealInfo, setCustomMealInfo] = useState({});
  const [isRecentMeal, setIsRecentMeal] = useState(false);
  const {platform,isWebView}=useAppContext()
  const [showServingInfo, setShowServingInfo] = useState(false);
  const [isFoodDetailsLoading, setIsFoodDetailsLoading]=useState(false);
  const [openMealIndex, setOpenMealIndex] = useState(null);
  const [fatSearchApi,{isLoading:isFoodListLoading}]=useRequest("fat/search",'post')
  const inputRef = useRef();
  const { consumedMealType } = useShopContext();
  const [barCodeResultModal, setBarCodeResultModal] = useState(false);
  const [selectedSliderIndex, setSelectedSliderIndex] = useState(null)

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setBarcodeModal(open);
  };
  const searchMealsApiFn = useCallback(async () => {
    setIsEmpty(false);
    try {
      const result = await fatSearchApi({
        body: {
          search_expression: value,
          page_number: currentPage,
          max_results: 20,
          generic_description: true,
        },
      });
      if (result?.data?.foods?.food && Array.isArray(result.data.foods.food)) {
        setHasMore(true);
        const pages = Math.round(result?.data.foods.total_results / 50);
        setTotalPages(pages);
        setCurrentPage(0);
        const resultData = filterDuplicates(result.data.foods.food);
        setResultMeals(resultData);
      } else {
        setResultMeals([]);
        setIsEmpty(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, value]);
   
  const params = new URLSearchParams(useLocation().search);
  useEffect(() => {
    const Barcode = params.get('barcode');
    if (Barcode == 'true') {
      if( isWebView ){
        isWebView.postMessage(JSON.stringify({event:"bar-code-click",screen:"Scanner"}))
        return;
      }
    }
    if(value.length>0 && searchValue.length>0){
      searchMealsApiFn();
    } else {
      // setResultMeals([])
      setCurrentPage(0)
    }
  },[value]);


  useEffect(() => {
    setSearchMealsHeight(isDone ? window.innerHeight - 324 : window.innerHeight - 298);
     isDone && resetParams();
  }, [isDone])

  const handleSearch = (e) => {
    const { value } = e.target;
    setIsEmpty(false);
    setResultMeals([])
    setSearchValue(value);
    if(value===''){
      setResultMeals([]);
    } 
  };

  const getRecentLoggedMealsData = () => {
    const route = `diary/recentLoggedMeals`;
    getRecentLoggedMeals({
      path: route,
    })
      .then((data) => {
        setLoggedMeals(data?.data?.result);
      })
      .catch(console.log);
  };

  useEffect(() => {
    setLoggedMealsHeight(window.innerHeight - 250);
     getRecentLoggedMealsData();
  }, []);
 
  const clearInput = () => {
    setSearchValue('');
    setResultMeals([]);
    setIsEmpty(false);
  };
  const moveMealFormSearch = (id) => {
    setMealId(id);
    setDisableAddingMeals(true);
    setTimeout(() => {
      setResultMeals((prevState) => prevState.filter((meal) => meal.food_id !== id));
      setDisableAddingMeals(false);
      setOpenMealIndex(null);
      setIsDone(true);
      setMealsCount((prevState) => prevState + 1);
    }, 500);
    setMealId('');
  };

  const moveMealFromRecentList = (id) => {
    setMealId(id);
    setDisableAddingMeals(true);
    setTimeout(() => {
      setLoggedMeals((prevState) => prevState.filter((meal) => meal.id !== id));
      setDisableAddingMeals(false);
      setOpenMealIndex(null);
      setIsRecentMeal(false);
      setIsDone(true);
      setMealsCount((prevState) => prevState + 1);
    }, 500);
    setMealId('');
  };
  
  const servings = useMemo(() => {
    const servingsArray = Array.isArray(searchedFoodDetails?.servings?.serving)
      ? searchedFoodDetails?.servings?.serving
      : [searchedFoodDetails?.servings?.serving];
  
    if (!selectedSliderIndex) {
      return servingsArray;
    }
  
    const servingToUpdate = servingsArray.find((item) => item?.serving_id === selectedSliderIndex?.serving_id);
    const servingIndex = servingToUpdate ? servingsArray.indexOf(servingToUpdate) : -1;
  
    if (servingToUpdate && servingIndex !== -1) {
      const updatedServing = {
        ...servingToUpdate,
        selectedQuantity: selectedSliderIndex?.selectedQuantity,
        measureIndex: selectedSliderIndex?.measureIndex,
      };
  
      const updatedServings = [...servingsArray];
      updatedServings[servingIndex] = updatedServing;
  
      return updatedServings;
    } else {
      return servingsArray;
    }
  }, [searchedFoodDetails?.servings?.serving, selectedSliderIndex]);
  
  
  const handleMacrosBtnClick = async () => {
    if (mealMacroInfo?.serving_id) {
      let searchMeal = 0
      if (searchQuantity > 1) {
        searchMeal = searchQuantity
      }
      const newId = Number(searchedFoodDetails?.food_id) + Number(searchMeal)

      await customMealDiary({
        body: {
          name: searchedFoodDetails?.food_name,
          calories: isMacrosModal ? Number(mealMacroInfo?.calories) * searchQuantity : Number(Math.round(mealMacroInfo?.calories)),
          carbs: isMacrosModal ? Number(mealMacroInfo?.carbohydrate) * searchQuantity : Number(Math.round(mealMacroInfo?.carbohydrate)),
          fat: isMacrosModal ? Number(mealMacroInfo?.fat) * searchQuantity : Number(Math.round(mealMacroInfo?.fat)),
          protein: isMacrosModal ? Number(mealMacroInfo?.protein) * searchQuantity : Number(Math.round(mealMacroInfo?.protein)),
          img: '/images/dish.svg',
          createdAt: new Date(activeDay).toISOString(),
          fatSecretSearchResult: {
            ...searchedFoodDetails, food: { ...searchedFoodDetails.food, food_id: newId }, servings: {
              ...searchedFoodDetails?.servings,
              serving: servings
            }
          },
          categoryType: consumedMealType
        }
      }).then(async (res) => {
        if (res.success == true) {
          updateFullDiaryMeals(res.result)
          setIsMacrosModal(false)
          resultMeals?.length && moveMealFormSearch(foodId);
          isRecentMeal && moveMealFromRecentList(foodId);
          setMealMacroInfo({});
          setIsFoodDetailsLoading(false);
        }
      }).catch((error) => {
        console.log("error....", error);
      });
    }
  };

  const handleAddCustomMealBtnClick = async () => {
    await customMealDiary({
      body: {
        name: customMealInfo?.food_name,
        calories: Number(Math.round(customMealInfo?.calories)),
        carbs: Number(Math.round(customMealInfo?.carbs)),
        fat: Number(Math.round(customMealInfo?.fat)),
        protein: Number(Math.round(customMealInfo?.protein)),
        img:customMealInfo?.img,
        createdAt: new Date(activeDay).toISOString(),
        categoryType: consumedMealType  
      }
    }).then( async (res) => {
      if(res.success == true) {
       await getdiaryData();
       moveMealFromRecentList(customMealInfo?.id);
       setCustomMealInfo({});
       setIsFoodDetailsLoading(false);
      }
    }) .catch((error) => {
      console.log("error....", error);
    });
   
  };
  useEffect(async()=> {
    if(mealMacroInfo?.serving_id && logMealFromList){
      await handleMacrosBtnClick()
      setLogMealFromList(false);
    }
  },[mealMacroInfo]);

  useEffect(async()=> {
      if(Object.keys(customMealInfo)?.length >0 && logMealFromList){
       await handleAddCustomMealBtnClick()
       setLogMealFromList(false);
    }
  },[customMealInfo]);

  const fetchMore = async () => {
    if (totalPages > currentPage && searchValue) {
      const nextPage = currentPage + 1;
      try {
        const result = await fatSearchApi( {
          body: {
            search_expression: searchValue,
            page_number: nextPage,
            max_results: 20,
            generic_description: true,
          },
           headers: {
            'Content-Type': 'application/json',
            
          },
        });
        if (result?.data?.foods?.food && Array.isArray(result?.data?.foods?.food)) {
          const resultData = filterDuplicates(result.data.foods.food);
          setResultMeals((prevResultMeals) => [
            ...prevResultMeals,
            ...resultData,
          ]);
        }
      } catch (e) {
        console.log(e);
      }
      setCurrentPage(nextPage);
    } else {
      setHasMore(false);
    }
  };
  
  const filterDuplicates = (arr) => {
    const uniqueIds = new Set();
    return arr.filter(obj => {
      if (uniqueIds.has(obj.food_id)) {
        return false; // Skip if the ID is already present
      }
      uniqueIds.add(obj.food_id); // Add the ID to the set
      return true; // Include the object in the filtered array
    });
  };

  const onDetected = result => {
    setResultMeals([])
    let code = result.codeResult.code;
    setBarCode(String(code));
    setCamera(false)
  };

  const getFoodIdBarCode = async (barcodeId) => {
    const barCodeResult = await fatSearchApi( {
      body: {
        method: "food.find_id_for_barcode",
        format: "json",
        barcode: barcodeId,
      },
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((result) => {
      return result?.data
    }).catch((err) => {
      console.log(err)
    })
    return barCodeResult;
  }
  useEffect(async () => {
    if (barCode) {
      const barCodeFoodIdResult = await getFoodIdBarCode(Number(barCode))
      if (barCodeFoodIdResult?.food_id && barCodeFoodIdResult?.food_id?.value !== "0") {
        await fatSearchApi( {
          body: {
            method: "food.get.v2",
            format: "json",
            food_id: barCodeFoodIdResult.food_id.value,
          },
          headers: {
            'Content-Type': 'application/json'
          },
        }).then((result) => {
          if (result?.data?.food) {
            setSearchValue('');
            const serving = result?.data?.food?.servings?.serving
            setResultMeals([{ ...result.data.food, food_description: `Per ${serving?.serving_description} - Calories: ${serving?.calories}kcal | Fat: ${serving?.fat}g | Carbs: ${serving?.carbohydrate}g | Protein: ${serving?.protein}g` }])
            setBarCode(null);
            setBarCodeResultModal(true);
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        setBarcodeModal(true)
        setBarCode(null)
      }
    }
  }, [barCode])

  useEffect(() => {

    const isAndroidWebView = () => {
      return isWebView && /Android/i.test(navigator.userAgent);
    };
    const globalObject = isAndroidWebView() ? document : window;
    function handleMessage(event) {
      const result = JSON.parse(event.data);
        if (event.data && typeof event.data === "string" && result?.servings?.serving) {
          setSearchValue('')
          const serving = result?.servings?.serving;
          setResultMeals([
            {
              ...result,
              food_description: `Per ${serving?.serving_description} - Calories: ${serving?.calories}kcal | Fat: ${serving?.fat}g | Carbs: ${serving?.carbohydrate}g | Protein: ${serving?.protein}g`,
            },
          ]);
          setBarCodeResultModal(true);
        }
      }
   
      isWebView && globalObject.addEventListener("message", handleMessage);
      return () => {
        globalObject.removeEventListener("message", handleMessage);
      };
  }, []);

  const generateScanCode = () => {
    setBarcodeModal(false);
    if( isWebView ){
      isWebView.postMessage(JSON.stringify({event:"bar-code-click",screen:"Scanner"}))
    }else {
      setBarCode(null)
      setCamera(!camera)
      // setResultMeals([])
    }
  }

const handleMealClick = (index) =>{
   setOpenMealIndex(index);
}
  const memorizedMeals = useMemo(() => {
    const filteredMeals = resultMeals.filter((meal) => {
      const servings = meal?.servings?.serving;
      if (!servings) {
        return false;
      } else {
        return true;
      }   
    })
    return filteredMeals;
  }, [resultMeals]);

  useEffect(() => {
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [])

  function handleClickOutside(event) {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      inputRef.current.blur();
    }
  }

  return (
    <GilroyFontWrapper>
    <SearchTabWrapper>
      <PantryDate
        setActiveDay={setActiveDay}
        activeDay={activeDay}
        isLogMealsModal={isLogMealsModal}
      />
      <div className="input-scanCode-wrapper">
        <div className="input-wrapper">
          <input
            type="text"
            onChange={handleSearch}
            value={searchValue}
            placeholder="Search meals..."
            ref={inputRef}
          />
          <img src="/icons/search-input.svg" alt="Search Icon" className="search-icon" />
          {searchValue && (
            <img
              src="/icons/close-circle2.svg"
              alt="Close Icon"
              className="close-icon"
              onClick={clearInput}
            />
          )}
        </div>
        <img src="/icons/scan-code.svg" alt="Scan Code Icon" className="scanCode-icon" onClick={generateScanCode} />
      </div>

      {camera &&
        <BarCodeScanner
          onDetected={onDetected} 
          camera={camera} 
          setCamera={setCamera}/>
      }
      

      {memorizedMeals?.length > 0 &&
        <div className='result'>
          <span>Search results</span>
        </div>
      }

      {searchValue.length || memorizedMeals?.length || camera ? (
        searchValue.length > 0 && memorizedMeals?.length < 1 && isEmpty ? (
          <div className='search-empty-meal' style={{ minHeight: searchMealsHeight }}>
            <Empty img="/images/dish.png" title={`No Results for "${searchValue}"`} />
          </div>
        ) : (
          <div className="search-meals-wrapper" id="scrollableDiv" style={{ height: searchMealsHeight }}>
            <InfiniteScroll
              dataLength={memorizedMeals?.length}
              next={fetchMore}
              pullDownToRefreshThreshold={50}
              hasMore={hasMore}
              loader={isFoodListLoading}
              scrollableTarget="scrollableDiv"
            >
              {memorizedMeals?.map((meal, index) => (
                <SearchMeal
                  key={meal?.food_id}
                  meal={meal}
                  searchValue={searchValue}
                  setIsMacrosModal={setIsMacrosModal}
                  setMealInfo={setMealInfo}
                  moveMealFormSearch={moveMealFormSearch}
                  mealId={mealId}
                  disabled={disableAddingMeals}
                  setFoodId={setFoodId}
                  handleMacrosBtnClick={handleMacrosBtnClick}
                  setMealMacroInfo={setMealMacroInfo}
                  setSearchedFoodDetails={setSearchedFoodDetails}
                  searchedFoodDetails={searchedFoodDetails}
                  mealMacroInfo={mealMacroInfo}
                  form={form}
                  setForm={setForm}
                  setLogMealFromList={setLogMealFromList}
                  showServingInfo={showServingInfo}
                  setShowServingInfo={setShowServingInfo}
                  isFoodDetailsLoading={isFoodDetailsLoading}
                  setIsFoodDetailsLoading={setIsFoodDetailsLoading}
                  setSearchQuantity={setSearchQuantity}
                  handleMealClick={handleMealClick}
                  openMealIndex={openMealIndex}
                  index={index}
                  currentDayinfo={currentDayinfo}
                  selectedSliderIndex={selectedSliderIndex}
                  setSelectedSliderIndex={setSelectedSliderIndex}
                />
              ))}
            </InfiniteScroll>
          </div>
        )
      ) : (
        <div className='recent-logged-div' style={{ height: loggedMealsHeight }}>
          <p className='recent-logged'>Recent logged</p>
          {isLoggedMealsLoading ?
            <Progress />
            :
            <div className='meals-div' style={{ height: loggedMealsHeight - 26 }}>
              {loggedMeals?.map((meal, index) => (
                <RecentLoggedMeals
                  key={meal?.id}
                  meal={meal}
                  meal_name={meal?.name}
                  calories={meal?.calories}
                  protein={meal?.protein}
                  fat={meal?.fat}
                  carbs={meal?.carbs}
                  img={meal?.img}
                  servings={meal?.fatSecretSearchResult?.servings}
                  setMealInfo={setMealInfo}
                  setFoodId={setFoodId}
                  setMealMacroInfo={setMealMacroInfo}
                  setCustomMealInfo={setCustomMealInfo}
                  customfoodId={meal?.id}
                  mealId={mealId}
                  fatSecretMealId={meal?.fatSecretSearchResult?.food_id}
                  setLogMealFromList={setLogMealFromList}
                  setSearchedFoodDetails={setSearchedFoodDetails}
                  searchValue={searchValue}
                  isRecentMeal={isRecentMeal}
                  setIsRecentMeal={setIsRecentMeal}
                  setCustomForm={setCustomForm}
                  setValue={setValue}
                  setIsFoodDetailsLoading={setIsFoodDetailsLoading}
                  setSearchQuantity={setSearchQuantity}
                  handleMealClick={handleMealClick}
                  openMealIndex={openMealIndex}
                  index={index}
                  currentDayinfo={currentDayinfo}
                  showServingInfo={showServingInfo}
                  setShowServingInfo={setShowServingInfo}
                  isCustomMealSaving={isCustomMealSaving}
                  setSelectedSliderIndex={setSelectedSliderIndex}
                />
              ))}
            </div>
          }
        </div>
      )
      }
      {isDone ? (
        <div className="done-btn-wrapper">
          <button onClick={() =>{
             setIsLogMealsModal(false)
          }}>
            Done <span>{mealsCount}</span>
          </button>
        </div>
      ) : (

        <div className='fat-secret-wrapper'>
          {
            isWebView? (
              <a target='_blank' onClick={() => isWebView.postMessage(JSON.stringify({ event: "fat_sec_link", data: "https://platform.fatsecret.com" }))
              }>
                <span>powered by</span>
                <img src='/images/fatsecret.png' alt='FatSecret Img' />
              </a>
            ) : (
              <a href="https://platform.fatsecret.com" target='_blank'>
                <span>powered by</span>
                <img src='/images/fatsecret.png' alt='FatSecret Img' />
              </a> 
            )
          }

        </div>
      )}
      {isMacrosModal && (
        <SearchMacrosModal
          foodName={mealInfo?.foodName}
          setSearchQuantity={setSearchQuantity}
          setIsMacrosModal={setIsMacrosModal}
          setIsHowMuchModal={setIsHowMuchModal}
          moveMealFormSearch={moveMealFormSearch}
          id={foodId}
          mealMacroInfo={mealMacroInfo}
          getdiaryData={getdiaryData}
          handleMacrosBtnClick={handleMacrosBtnClick}
          setForm={setForm}
          form={form}
          searchedFoodDetails={searchedFoodDetails}
          isCustomMealSaving={isCustomMealSaving}
          setMealMacroInfo={setMealMacroInfo}
        />
      )}
      {isHowMuchModal && (
        <HowMuchModal
        searchedFoodDetails={searchedFoodDetails?.servings?.serving}
          setMealMacroInfo={setMealMacroInfo}
          setIsHowMuchModal={setIsHowMuchModal}
        />
      )}
      {isFoodListLoading &&
        <Progress/>
      }
    
    <AnimatePresence>
      {barcodeModal && 
        <BarcodeModelStyle>
            <motion.div
              initial={{ opacity: 0, y: '100%' }}
              exit={{ opacity: 0, x: 0, y: '100%' }}
              animate={{ opacity: 1, x: 0, y: 0 }}
              transition={{ duration: 0.3, type: 'spring' }}
              style={{ width: '100%' }}
            >
              <div className="modal">
                <CrossIcon className='cross_icon' onClick={()=>setBarcodeModal(false)}/>
                <div className='no-match'>No match found</div>
                <div className='match-found-btns'>
                  <button onClick={generateScanCode}>Scan again</button>
                  <button className='cancel-btn' onClick={()=>setBarcodeModal(false)}>Cancel</button>
                </div>
              </div>
            </motion.div>
        </BarcodeModelStyle>
      }
    </AnimatePresence>
    {/* </Drawer> */}

    </SearchTabWrapper>
    {barCodeResultModal &&
    <BarcodeSearchResult 
      memorizedMeals={memorizedMeals}
      searchValue={searchValue}
      setIsMacrosModal={setIsMacrosModal}
      setMealInfo={setMealInfo}
      moveMealFormSearch={moveMealFormSearch}
      mealId={mealId}
      disabled={disableAddingMeals}
      setFoodId={setFoodId}
      handleMacrosBtnClick={handleMacrosBtnClick}
      setMealMacroInfo={setMealMacroInfo}
      setSearchedFoodDetails={setSearchedFoodDetails}
      searchedFoodDetails={searchedFoodDetails}
      mealMacroInfo={mealMacroInfo}
      form={form}
      setForm={setForm}
      setLogMealFromList={setLogMealFromList}
      showServingInfo={showServingInfo}
      setShowServingInfo={setShowServingInfo}
      isFoodDetailsLoading={isFoodDetailsLoading}
      setIsFoodDetailsLoading={setIsFoodDetailsLoading}
      setSearchQuantity={setSearchQuantity}
      handleMealClick={handleMealClick}
      openMealIndex={openMealIndex}
      currentDayinfo={currentDayinfo}
      setBarCodeResultModal={setBarCodeResultModal}
      setResultMeals={setResultMeals}
      setIsLogMealsModal={setIsLogMealsModal}
      setSelectedSliderIndex={setSelectedSliderIndex}
    />}
    </GilroyFontWrapper>
  );
}

