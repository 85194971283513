import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useAppContext } from "./appContext";
import { useRequest } from "hooks/useRequest";
import { useLocation } from "react-router-dom";

const KitchenContext = createContext();

const KitchenContextProvider = ({ children }) => {
    const { user, provider, isSuper, isMobile } = useAppContext();
    const [kitchenProvider, setKitchenProvider] = useState(provider);
    const [selectedMealId,setSelectedMealId]=useState(null);
    const defaultMealState={ name: '', mealCategoryId: null, description: '', price: '', images: [], restrictions: [], tags: [] }
    const [mealCategoriesList, setMealCategoriesList] = useState([]);
    const [mealData, setMealData] = useState(defaultMealState);
    const [activeTab, setActiveTab] = useState('');
    const [newOrdersCount, setNewOrdersCount] = useState(0);
    const [fetchOrderData] = useRequest();
    const [getItemListApi] = useRequest();
    const [fetchSubcriptionList] = useRequest();
    const [orderData, setOrderData] = useState();
    const imitateProviderStorage = sessionStorage.getItem("imitateProvider");
    const [items, setItems] = useState([]);
    const [totalPages,setTotalPages]=useState(0);
    const [totalMealCount,setTotalMealCount]=useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [hasItem, setHasItem] = useState(false);
    const [upcomingPayouts, setUpcomingPayouts] = useState();
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [kitchenHasSubscription, setKitchenHasSubscription] = useState(null);
    const params = new URLSearchParams(useLocation().search);
    const kitchenId = parseInt(params.get('providerId'));

    const imitateProviderCredentials = useMemo(() => {
        const parsedProvider =isSuper? JSON.parse(imitateProviderStorage) : provider;
        const providerId = parsedProvider?.id;
        const businessProfileId = parsedProvider?.BusinessProfile?.length && parsedProvider?.BusinessProfile[0]?.id;
        return { providerId, businessProfileId }
    }, [imitateProviderStorage,provider,isSuper]);
    const [isCreateCategory, setIsCreateCategory] = useState(false);

    const getItemList = async () => {
        let path= `meal/list?pageSize=50&pageNumber=1&providerId=${imitateProviderCredentials?.providerId || kitchenId}`
        try {
          const result = await getItemListApi({path,method:"get"});
          const {data}=result;
          setItems(data?.data);
          setHasItem(data?.data?.length > 0);
          setTotalPages(data?.totalPages);
          setTotalMealCount(data?.totalRecords);
        } catch (error) {
          console.log(error);
        }
      };

      const getSubscriptionList = async () => {
        try {
          const path = `subscription/get/${imitateProviderCredentials?.providerId || kitchenId}`;
          const response = await fetchSubcriptionList({ path });
          if (response?.success) {
            setSubscriptionList(response.result || []);
          } else {
            console.log('Invalid API response structure');
          }
        } catch (err) {
          console.log('Error fetching saved cards', err);
        }
      };
    
    useEffect(async()=>{
        setKitchenProvider(provider);
        getItemList();
        getSubscriptionList();
            try {
                const path = `provider/orders?pageSize=50&action=${isMobile ? 'pending' : 'all'}&pageNumber=1${isSuper ? `&providerId=${imitateProviderCredentials?.providerId || kitchenId}` : ''}`
                const { data } = await fetchOrderData({ path }, 'get');
                setOrderData(data);
                const ordersCount = data?.totalUnreadOrders;
                setNewOrdersCount(ordersCount);
            }
            catch (error) {
                console.log("error", error);
            }
    },[provider]);

    return (
        <KitchenContext.Provider value={{
            kitchenProvider,
            setKitchenProvider,
            mealData,
            setMealData,
            defaultMealState,
            selectedMealId,
            setSelectedMealId,
            isSuper,
            activeTab,
            setActiveTab,
            newOrdersCount,
            setNewOrdersCount,
            mealCategoriesList,
            setMealCategoriesList,
            orderData,
            setOrderData,
            isCreateCategory, 
            setIsCreateCategory,
            items,
            setItems,
            hasItem,
            totalPages,
            setTotalPages,
            totalMealCount,
            setTotalMealCount,
            categoryList,
            setCategoryList,
            upcomingPayouts, 
            setUpcomingPayouts,
            imitateProviderCredentials,
            subscriptionList,
            setSubscriptionList,
            kitchenHasSubscription,
            setKitchenHasSubscription
        }}>
            {children}
        </KitchenContext.Provider>
    )
}

const useKitchenContext = () => useContext(KitchenContext);

export { KitchenContextProvider, useKitchenContext }
