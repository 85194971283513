export const socialMedia = [
  {
    id: 1,
    icon: '/icons/facebook-black.svg',
    link: 'https://www.facebook.com/nutrittofficial'
  },
  {
    id: 2,
    icon: '/icons/twitter-black.svg',
    link: '#'
  },
  {
    id: 3,
    icon: '/icons/instagram-black.svg',
    link: 'https://www.instagram.com/nutritt_/'
  },
  {
    id: 4,
    icon: '/icons/linkedin-black.svg',
    link: 'https://www.linkedin.com/company/nutrittofficial'
  }
];
