import Styled from 'styled-components';

const CheckRightWrapper = Styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  .checkmark {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: block;
    stroke-width: 3;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 0;
    box-shadow: inset 0px 0px 0px #00092D;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
  }
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
  } 
  @keyframes stroke {
    100%{stroke-dashoffset: 0}
  }
  @keyframes scale {
    0%, 100%{transform: none}
    50%{transform: scale3d(1.1, 1.1, 1)}
  } 
  @keyframes fill {
    100%{box-shadow: inset 0px 0px 0px 30px #00092D}
  }
`;

export default function CheckRight() {
  return (
    <CheckRightWrapper>
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </CheckRightWrapper>
  );
}
