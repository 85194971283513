import { useState, useEffect } from 'react';
import Styled from 'styled-components';
import Input from 'components/Input';
import { useAppContext } from 'context/appContext';
import ProgressMacroLine from './ProgressMacroLine';

const positionFixed = `
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const SearchMacrosModalWrapper = Styled.div`
  z-index: 116;
  min-height: 100vh;
  padding: 90px 20px 0;
  background-color: #51635d80;
  ${positionFixed};
  .macros-mask {
    z-index: 117;
    ${positionFixed};
  }
  .macros-modal-wrapper {
    position: relative;
    padding: 21px 42px 32px; 
    background-color: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    .food-title {
      margin-bottom: 32px;
      color: #51635D;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    .count-serving-description {
      height: 44px;
      margin-bottom: 24px;
      display: flex;
      & > div:first-child {
        width: 46px;
        margin: 0 10px 0 0;
      }
      .input-wrapper {
        width: 100%;
        margin: 0
      }
      input {
        z-index: 118;
        width: 46px;
        height: 44px;
        padding: 0;
        background-color: #fff;
        color: #51635D;
        text-align: center;
      }
      .serving-description-wrapper {
        position: relative;
        width: calc(100% - 56px);
        padding: 0 38px 0 14px; 
        border: 1px solid #E0E3E0;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          overflow: hidden;
          color: #51635D;
          font-size: 14px;
          line-height: 16px;
          white-space: nowrap;
        }
        img {
          position: absolute;
          top: auto;
          right: 10px;
        }
      }
    }
    .macros-wrapper {
      & > div {
        padding: 0;
      }
    }
    .macros-title {
      margin-bottom: 5px;
      color: #51635D;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
    .log-btn {
      width: 170px;
      height: 36px;
      margin-top: 20px;
      font-size: 14px;
      line-height: 16px;
    }
    button: disabled{
      background-color: #e0e3e0;
      color: #949d9b;
      cursor: not-allowed;
    }
  }
`;
export default function SearchMacrosModal({
  foodName,
  setIsMacrosModal,
  setIsHowMuchModal,
  setSearchQuantity,
  moveMealFormSearch,
  id,
  mealMacroInfo,
  handleMacrosBtnClick,
  setForm,
  form,
  searchedFoodDetails,
  isCustomMealSaving,
  setMealMacroInfo
}) {
  const [count, setCount] = useState(1);
  const [isMacrosMask, setIsMacrosMask] = useState(false);
  const [macrosHeight, setMacrosHeight] = useState();
  const servingDescriptionSplit = mealMacroInfo && mealMacroInfo?.serving_description?.split(' (');
  const servingDescription = servingDescriptionSplit && servingDescriptionSplit[0];
  const {
    profile: {
      dietMetrics: { calories, macros },
    },
  } = useAppContext();

  const handleChange = (e) => {
    const { value } = e.target;
    if (value < 0) {
      setCount(0);
    } else if (value > 999) {
      setCount(999);
    } else {
      setCount(value);
    }
    setIsMacrosMask(true);
  };

  const mealInfoCalc = (item) => item * count;

  useEffect(() => {
    setMacrosHeight(window.innerHeight);
  }, []);

  const closeMacrosModal = () => {
    setIsMacrosModal(false);
    setMealMacroInfo({});
  }

  const inputCountBlur = (e) => {
    const { value } = e.target;
    if (value < 1) {
      setCount(1);
    }
    else{
      setSearchQuantity(Number(value))
    }
  };

  console.log(mealMacroInfo,'mealMacroInfo')
  return (
    <SearchMacrosModalWrapper style={{ height: macrosHeight }}>
      {isMacrosMask && <div className="macros-mask" onClick={() => setIsMacrosMask(false)} />}
      <div className="macros-modal-wrapper">
        <img
          src="/icons/close-green.svg"
          className="close-icon"
          onClick={closeMacrosModal}
        />
        <h3 className="food-title">{foodName}</h3>
        <div className="count-serving-description">
          <Input
            type="number"
            value={count}
            pattern="\d*"
            onChange={handleChange}
            onBlur={inputCountBlur}
          />
          <div className="serving-description-wrapper" onClick={() => setIsHowMuchModal(true)}>
            <span>{servingDescription}</span>
            <img src="/icons/arrow-square-down.svg" alt="Arrow Icon" />
          </div>
        </div>
        <div className="macros-wrapper">
          <h3 className="macros-title">Macros</h3>
          <ProgressMacroLine
            item="Calories"
            consumedMealItem={mealInfoCalc(mealMacroInfo?.calories)}
            userDietMacro={calories}
            lineColor="#52C41A"
            weight=" cal"
            fixed
          />
          <ProgressMacroLine
            item="Fat"
            consumedMealItem={Math.round(mealInfoCalc(mealMacroInfo?.fat))}
            userDietMacro={macros?.fat}
            lineColor="#FEB95A"
            weight=" g"
            fixed
          />
          <ProgressMacroLine
            item="Protein"
            consumedMealItem={Math.round(mealInfoCalc(mealMacroInfo?.protein))}
            userDietMacro={macros?.protein}
            lineColor="#EE786B"
            weight=" g"
            fixed
          />
          <ProgressMacroLine
            item="Carbs"
            consumedMealItem={Math.round(mealInfoCalc(mealMacroInfo?.carbohydrate))}
            userDietMacro={macros?.carbs}
            lineColor="#916AE2"
            weight=" g"
            fixed
          />
        </div>
        <button className="log-btn" onClick={handleMacrosBtnClick} disabled={isCustomMealSaving}>
          LOG
        </button>
      </div>
    </SearchMacrosModalWrapper>
  );
} 
