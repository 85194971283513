import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const NutritionFactsWrapper = styled.div`
  .nutrition-title {
    color: ${() => theme.colors.primaryDarker};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .nutrition-facts-container {
    .item-wrapper {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .item-text,
    .gram,
    .percent {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .item-text {
      color: ${() => theme.colors.blackDefault};
    }
    .item-value {
      color: ${() => theme.colors.blackDefault};
      display: flex;
      align-items: center;
    }
    .gram {
      color: ${() => theme.colors.grayDarker};
    }
    .percent {
      width: 60px;
      color: ${() => theme.colors.blackDefault};
      font-weight: 500;
      text-align: right;
    }
    .bold {
      font-weight: 500;
    }
    .bolder {
      font-weight: 600;
    }
    .divider {
      margin-bottom: 8px;
      border-bottom: 1px solid ${() => theme.colors.grayLight};
    }
    .serving {
      margin: 6px 0 4px;
      .item-text {
        color: ${() => theme.colors.grayDarker};
        font-weight: 500;
      }
    }
    .divider.main {
      margin: 9px 0 11px;
      border-color: ${() => theme.colors.blackDefault};
    }
    .daily-values {
      color: ${() => theme.colors.blackDefault};
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .ingredients-wrapper {
    margin: 21px 0 14px;
    .ingredients-title {
      color: ${() => theme.colors.primaryDarker};
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .ingredients-contains {
      margin: 12px 0 10px;
      padding: 14px;
      background-color: ${() => theme.colors.secondary};
      border: 1px solid ${() => theme.colors.grayLight};
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      img {
        width: 20px;
        height: 20px;
      }
      .ingredients-text {
        color: ${() => theme.colors.primaryDarker};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    p {
      color: ${() => theme.colors.primaryDefault};
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 23px;
    
    .nutrition-facts-container .serving {
      margin: 16px 0 4px;
    }
  }
`;
