import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import SuccessComponent from 'components/SuccessComponent';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { sortBy, prop, compose } from 'ramda';
import UpdateProviderButton from '../pages/provider/UpdateProviderButton';
import InviteNutritionist from '../pages/Nutritionist/inviteNutritionist';
import { ToastContainer, toast } from 'react-toastify';

const Container = styled.div`
  min-height: calc(100vh - 103px);
  display: grid;
  grid-template-columns: 620px auto;
  justify-content: center;
  align-content: center;
  form,
  .success {
    width: 280px;
  }
  gap: 40px;
  .inviteInput {
    display: grid;
    grid-template-columns: 300px 300px ;
    row-gap: 26px;
    padding: 20px 0;

    .provider-nutritionist{
      display:flex;
    }
  }
  @media (max-width: 1020px) {
    grid-template-columns: 300px 300px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 300px;
    gap: 20px;
    justify-content: center;
    .inviteInput {
      display: block;
    }

    .profile{
      margin-top:10px;
    }
  }
`;

export default function Admin() {
  const { provider, setProvider } = useAppContext();
  const history = useHistory();
  const [providers, setProviders] = useState([]);
  const [getProviders] = useRequest('provider/providers');
  // const [getProvider] = useRequest("provider/provider/1");
  const [approveProvider] = useRequest('admin/provider/profile', 'post');

  const loadProviders = () => getProviders().then(compose(setProviders, sortBy(prop('id'))));
  useEffect(() => {
    // getProvider().then((x) => console.log(x));
    loadProviders();
  }, []);

  const [email, setEmail] = useState('');
  const [newProviderName, setNewProviderName] = useState('');
  const [inviteProvider, { isLoading, error, state: success, setState: setSuccess }] = useRequest(
    `provider/invite`,
    'post',
  );

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    const res = await inviteProvider({ body: { email } });
    setEmail('');
    setSuccess(null);
    toast.success(`Successfully sent invite to ${email}`);
  };
  const [
    createProvider,
    // { isLoading, error, state: success, setState: setSuccess },
  ] = useRequest(`provider/create`, 'post');

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    const res = await createProvider({ body: { name: newProviderName } });
    loadProviders();
    // console.log(`res`, res);
  };

  
  const Provider = memo((props) => {
    const { approved, id, name, userId } = props;
    
    const [providerFee, setProviderFee] = useState();
    const [userMarkup, setUserMarkup] = useState();
    useEffect(() => {
      const { providerFee: providerFeeProp = '0', userMarkup: userMarkupProp = '0' } = props;
      setProviderFee(providerFeeProp);
      setUserMarkup(userMarkupProp);
    }, [props]);

    return (
      <li key={id}>
        <input
          type="checkbox"
          checked={approved}
          onChange={() => {
            approveProvider({
              body: { id, approved: !approved },
            }).then((res) => {
              loadProviders()
            }).catch((err)=>{
              if(err?.success===false)
              toast.error(err.message)
            })
          }}
        />
        <a
          onClick={() => {
            sessionStorage.setItem('imitateProvider', JSON.stringify(props));
            history.push('/provider');
          }}
        >{`${id} ${name} `}</a>
        {userId ? (
          <span style={{ color: 'green', fontSize: '0.5em' }}>claimed</span>
        ) : (
          <span style={{ color: 'red', fontSize: '0.5em' }}>not claimed</span>
        )}
        <br />
        {approved && (
          <>
            <label>Provider Fee </label>
            <input
              type="number"
              value={providerFee}
              onChange={(e) => {
                setProviderFee(e.target.value);
              }}
            />
            <br />
            <label>User markup </label>
            <input
              value={userMarkup}
              type="number"
              onChange={(e) => {
                setUserMarkup(e.target.value);
              }}
            />
            <UpdateProviderButton
              body={{
                ...props,
                userMarkup: parseInt(userMarkup),
                providerFee: parseInt(providerFee),
              }}
              path="provider/update"
              setContextProvider={setProvider}
              successCb={loadProviders}
            />
          </>
        )}
        <hr />
      </li>
    );
  });

  return (
    <Container>
      <div className="inviteInput">
        <div className='provider-nutritionist'>
          <Link to="/admin/provider-disbursement">Provider disbursement</Link>
          <Link to="/admin/nutritionist-disbursement">Nutritionist disbursement</Link>
          </div>
          <div className='profile'>
          <Link to="/admin/users">Profile</Link>
          </div>
        <form onSubmit={handleInviteSubmit}>
          <h1>Invite provider</h1>
          <Input
            type="email"
            label="Email address"
            required
            bordered
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus={true}
          />
          <SubmitButton
            disabled={isLoading || !email}
            style={{ marginTop: '10px' }}
            type="submit"
            error={error}
          >
            {isLoading ? 'Sending' : 'Send Invite'}
          </SubmitButton>
        </form>
        <form onSubmit={handleCreateSubmit}>
          <h1>Create provider</h1>
          <Input
            type="text"
            label="Name"
            required
            bordered
            value={newProviderName}
            onChange={(e) => setNewProviderName(e.target.value)}
          />
          <SubmitButton
            disabled={
              // isLoading ||
              !newProviderName
            }
            style={{ marginTop: '10px' }}
            type="submit"
            // error={error}
          >
            Create Provider{/* {isLoading ? "Sending" : "Send Invite"} */}
          </SubmitButton>
        </form>
        <InviteNutritionist />
      </div>
      <ul>
        {providers.map((item) => (
          <Provider {...item} />
        ))}
      </ul>
    </Container>
  );
}

