import React from 'react';

const Level = ({ difficulty }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect
                x="4.5"
                y="22.5"
                width="23"
                height="4"
                rx="2"
                stroke="#00092D"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></rect>
            <rect
                x="4.5"
                y="14.5"
                width="23"
                height="4"
                rx="2"
                stroke="#00092D"
                stroke-linecap="round"
                stroke-opacity={difficulty === 'easy' ? 0.25 : 1}
                stroke-linejoin="round"
            ></rect>
            <rect
                x="4.5"
                y="6.5"
                width="23"
                height="4"
                rx="2"
                stroke="#00092D"
                stroke-opacity={difficulty === 'easy' || difficulty === 'medium' ? 0.25 : 1}
                stroke-linecap="round"
                stroke-linejoin="round"
            ></rect>
        </svg>
    );
};

export default Level;
