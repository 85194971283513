import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron_right_24px.svg';
import { theme } from 'shared/theme/theme';

const SettingsRowItemStyle = styled.div`
  .account_urls {
    padding: 14px 24px;
    background-color: #fff;
    display: flex;
    gap: 14px;
    align-items: center;
    transition: all 0.3s;
    .title {
      color: ${({ theme }) => theme?.colors?.deepForestGreen};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-style: normal;
      font-family: Gilroy;
      font-weight: 600;
      line-height: 20px;
      flex: 1;
    }
    .sub-title {
      color: ${({ theme }) => theme?.colors?.lighterGrey};;
      font-size: ${({ theme }) => theme?.typography?.fontSmall};
      font-weight: 500;
      line-height: 14px;
      font-family: Gilroy;
      flex: 1;
    }
    .tab {
      flex-direction: column;
      display: flex;
      flex:1
    }
    .chevron {
      width: 23px;
    }
    &:hover, &:focus, &:active{
        background: linear-gradient(0deg, rgba(245, 247, 248, 0.60) 0%, rgba(245, 247, 248, 0.60) 100%), #FFF;
    }
  }
`;

const SettingsRowItem = ({ icon, title, onClick, subTitle }) => {
  return (
    <>
      <SettingsRowItemStyle theme={theme} onClick={onClick}>
        <div className="account_urls">
          {icon}
          <div className='tab'>
            <p className="title">{title}</p>
            <p className="sub-title">{subTitle}</p>
          </div>
          <ChevronRight className="chevron" />
        </div>
      </SettingsRowItemStyle>
    </>
  );
};

export default SettingsRowItem;
