import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import ProviderDetailsSection from './ProviderDetailsSection';
import OrderCard from './components/OrderCard';
import Select from 'react-select';
import { useRequest } from 'hooks/useRequest';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import OrderDetailsSection from './OrderDetailsSection';
import Progress from 'components/Progress';
import { useDebounce } from 'use-debounce';
import Toggle from 'components/elements/toggleModule/Toggle';

const ProviderManagementStyle = styled.section`
  display: flex;
  width: calc(100% - 300px);
  margin-left: auto;
  background:rgb(246, 245, 248);
  @media (max-width: 991px) {
    width: 100%;
    padding: 24px 20px 0;
  }
`;

const OrderListSectionStyle = styled.div`
  max-width: 444px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  z-index: 2;
  .search-div {
    width: 380px;
    height: 44px;
    padding-top: 12px;
    margin-top: 24px;
    padding-left: 14px;
    border: 1px solid #e0e3e0;
    border-radius: 6px;
    label {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .section_header {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    padding: 49px 32px 19px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #51635d;
      margin-bottom: 24px;
    }
    .toogle-div{
      display: flex;
      justify-content: space-between;
      align-items: center;
   }
    .select_header,
    .status_select {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #51635d;
      margin-bottom: 14px;
    }
    .status_select {
      font-weight: 400;
      & > div {
        // padding-right: 12px;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        color: #51635d;
      }
      .status-select__menu {
        div {
          background-color: #fff;
        }
      }
    }
    .status-select__control {
      padding-right: 12px;
    }
    .MuiTabs-root {
      background: #f6f5f8;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      padding: 0 5px;
      .MuiTabs-indicator {
        height: 36px !important;
        background-color: #fff !important;
        border-radius: 4px !important;
        bottom: 5px;
      }
      .MuiTabs-flexContainer {
        z-index: 4;
        position: relative;
      }
      .MuiTabs-scroller {
        padding: 5px 0;
      }
      .MuiTab-root {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #51635d;
        text-transform: initial;
        min-width: auto;
        min-height: auto;
        padding: 8px 20px;
        position: relative;
        &:last-child {
          color: #949d9b;
          &::before {
            right: 0px !important;
          }
        }
      }
      .Mui-selected {
        font-weight: 700;
        overflow: visible;
        &.showNum {
          &::before {
            content: attr(neworders);
            position: absolute;
            top: -4px;
            right: -4px;
            background: #f08b00;
            border-radius: 5px;
            height: 14px;
            font-size: 9px;
            letter-spacing: 0.05em;
            color: #ffffff;
            text-align: center;
            line-height: 15px;
            padding: 0 2px;
          }
        }
      }
    }
    .search_sort {
      margin-top: 20px;
      display: flex;
      align-items: center;
      position: relative;
      .search {
        flex: 1;
        label {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .form-control {
          font-weight: 400;
          font-size: 14px !important;
          line-height: 16px !important;
          padding-left: 0;
          width: 100%;
          color: #51635d;
          &::placeholder {
            font-size: 14px;
            line-height: 16px;
            color: #afb5b4;
          }
        }
      }
      .sort {
        display: inline-flex;
        cursor: pointer;
        gap: 10px;
        align-items: center;
        p {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #51635d;
          margin-left: 25px;
        }
        .sortIcon {
          &.active {
            filter: invert(69%) sepia(33%) saturate(6234%) hue-rotate(62deg) brightness(175%)
              contrast(80%);
          }
        }
      }
      .wrap {
        position: fixed;
        inset: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
      }
      .sortable_options {
        background: #ffffff;
        border: 1px solid #e0e3e0;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: inline-block;
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        z-index: 99;
        max-width: 135px;
        width: 100%;
        .opt {
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #51635d;
          padding: 10px 13px;
          cursor: pointer;
          user-select: none;
          &.active {
            color: #52c41a;
            background: #f0faeb;
          }
        }
      }
    }
  }
  .section_body {
    padding: 32px 32px 15px;
    height: calc(100vh - 250px);
    overflow: auto;
    overflow-x: hidden;
  }
  @media (max-width: 1440px) {
    max-width: 398px;

    .section_body {
      height: calc(100vh - 230px);
    }
    .search-div {
      width: 335px;
    }
    .section_header {
      .MuiTabs-root {
        .MuiTab-root {
          padding: 8px 12px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    border-radius: 10px;
    overflow: hidden;
    .section_header {
      padding: 24px 16px 20px;
      box-shadow: unset;
      .title {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
      }
      .MuiTabs-root {
        min-height: auto;
        .MuiTab-root {
          font-size: 12px;
          line-height: 14px;
          padding: 8px 14px;
        }
        .MuiTabs-indicator {
          height: 30px !important;
        }
      }
      .search_sort {
        .search {
          label {
            margin-bottom: 0 !important;
          }
          .form-control {
            font-size: 12px !important;
            line-height: 14px !important;
            &::placeholder {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
        .sort {
          p {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
    .section_body {
      padding: 8px 20px 100px;
      height: calc(100vh - 251px);
    }
    .infinite-scroll-component {
      overflow-x: hidden !important;
    }
  }
`;

export default function ProviderManagement() {
  const [size, setSize] = useState();
  const [value, setValue] = useState(0);
  const [activeOrderId, setActiveOrderId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [orderData, setOrderData] = useState(null);
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [isOrderDetailsActive, setIsOrderDetailsActive] = useState(false);
  const { isMobile } = useAppContext();
  const [activeTab, setActiveTab] = useState('unpaid');
  const [getAllProvider, { isLoading, error, state: success, setState: setSuccess }] = useRequest();
  const [hasMore, setHasMore] = useState(true);
  const [isSortOptionsActive, setIsSortOptionsActive] = useState(false);
  const [sortBy, setSortBy] = useState('newest');
  const [searchTerm, setSearchTerm] = useState('');
  const [SearchedValue] = useDebounce(searchTerm, 600);
  const [orderCountByCat, setOrderCountByCat] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [showTestDisbursements,setShowTestDisbursements]=useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
    setOrderCountByCat({});
    switch (newValue) {
      case 0:
        setActiveTab('unpaid');
        break;
      case 1:
        setActiveTab('paid');
        break;
      case 2:
        setActiveTab('voided');
        break;
      default:
        setActiveTab('paid');
        break;
    }
  };
  useEffect(() => {
    isMobile && setActiveOrderId('0');
    document.body.classList.add('hide-scroll');
    return () => document.body.classList.remove('hide-scroll');
  }, []);


  const handleProviderClick = (order) => {
    isMobile && setActiveOrderId(orderData[0]);
    setIsOrderDetailsActive(true);
    setOrderDetailsData(order ? order : ''); 
    setActiveOrderId(order ? order.uuid : ''); 
  };

  const handleOrderClick = (disbursement, order) => {
    isMobile && setActiveOrderId(orderData[0]);
    setIsOrderDetailsActive(true);
    setActiveOrderId(disbursement.uuid);
    setOrderDetailsData({
      ...disbursement,
      ...order,
      total: order?.total,
      name: order?.provider?.name,
      phoneNumber: order?.provider?.phoneNumber,
      email: order?.provider?.email,
      img: order?.provider?.images,
    });
  };
  const selectOptions = [
    {
      label: 'Unpaid',
      value: 'unpaid',
      index: 0,
    },
    {
      label: 'Paid',
      value: 'paid',
      index: 1,
    },
    {
      label: 'Voided',
      value: 'voided',
      index: 2,
    },
  ];

  const DropdownIndicator = () => {
    return <img className="image" style={{ marginRight: '10px' }} src="/icons/SelectArrow.svg" />;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#51635D' : '#51635D',
      backgroundColor: state.isFocused ? '#ffffff' : '#ffffff',
      textAlign: 'left',
      '&:hover': {
        color: 'rgb(82, 196, 26)',
      },
    }),
  };

  const handleStatusChange = (e) => {
    setCurrentPage(1);
    switch (e.value) {
      case 'unpaid':
        setActiveTab('unpaid');
        break;
      case 'paid':
        setActiveTab('paid');
        break;
      case 'voided':
        setActiveTab('voided');
        break;

      default:
        setActiveTab('unpaid');
        break;
    }
  };

  useEffect(() => {
    setIsDataLoaded(false);
    setCurrentPage(1);
    fetchAllProviders();
    setHasMore(true);
  }, [SearchedValue, activeTab,showTestDisbursements]);
  
  useEffect(() => {
    setSearchTerm(prevSearchTerm => {
      if (prevSearchTerm.toUpperCase() === "NT") {
        return "NT";
      } else {
        return prevSearchTerm.slice(0, 2).toUpperCase() + prevSearchTerm.slice(2);
      }
    });
    if(searchTerm.length > 0){
      setCurrentPage(1);
    }
  }, [searchTerm]);
  

  const fetchAllProviders = async () => {
    try {
      let path = `financials/disbursements/v2?type=provider&pageSize=50&pageNumber=${currentPage}&actionType=${activeTab}&showTestDisbursement=${showTestDisbursements}`;
      const isNTOrder = SearchedValue.includes("NT");
      if (isNTOrder) {
        path += `&orderId=${SearchedValue}`;
      } else {
        path += `&providerName=${SearchedValue}`;
      }
      const result = await getAllProvider({ path });
      const res = result.data.response;
      const totalProvider = result.data.totalCount;
      if (searchTerm.length > 0) {
        setOrderCountByCat(res.length);
      } else {
        setOrderCountByCat(totalProvider);
      }
      setOrderData(res);
      setIsDataLoaded(true);
      setTotalPages(res?.data?.totalPages);
      setActiveOrderId(res[0]?.uuid || '');
    } catch (err) {
      console.log(err);
      setOrderDetailsData('');
      setActiveOrderId('');
    }
  };

  const fetchMore = async () => {
    setIsDataLoaded(true);
    const nextPage = currentPage + 1;
    try {
      const res = await getAllProvider({
        path: `financials/disbursements/v2?type=provider&pageSize=50&pageNumber=${nextPage}&actionType=${activeTab}`,
      });
      if (res?.data?.response.length > 0) {
        setOrderData([...orderData, ...res.data.response]);
        setCurrentPage(nextPage);
        if(searchTerm.length > 0){
        const totalProvider = res?.data?.response.length;
        setOrderCountByCat((prevCount) => prevCount + totalProvider);
        }
      } else {
        setHasMore(false);
      }
      if(searchTerm.length > 0){
      const isNTOrder = SearchedValue.includes("NT");
      if (isNTOrder) {
        path += `&orderId=${SearchedValue}`;
      } else {
        path += `&providerName=${SearchedValue}`;
      }
      if (currentPage == totalPages) {
        setHasMore(false);
      }
    }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setSize(window.innerWidth);
    window.addEventListener('resize', updateSize);
    function updateSize() {
      setSize(window.innerWidth);
    }
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const memorizedOrderData = useMemo(() => {
    let dataList = orderData?.filter((item) => item);

    if (sortBy) {
      switch (sortBy) {
        case 'newest':
          dataList?.sort((a, b) => moment(b.periodStart) - moment(a.periodStart));
          break;
        case 'oldest':
          dataList?.sort((a, b) => moment(a.periodStart) - moment(b.periodStart));
          break;
        default:
          break;
      }
    }

    return dataList;
  }, [orderData, sortBy]);

  useEffect(() => {
      setTimeout(() => {
        !isMobile && handleProviderClick(memorizedOrderData?.[0]);
      }, 1);
  }, [activeTab]);

  useEffect(() => {
      if(orderData?.length && !isMobile) {
        setTimeout(() => {
          handleProviderClick(orderData?.[0]);
        }, 1);
      }
  }, [activeTab, orderData]);

  const handleToggleChange=(checked)=>{
    setShowTestDisbursements(!checked)
}
  return (
    <ProviderManagementStyle>
      <OrderListSectionStyle>
        <div className="section_header">
          <h2 className="title">Providers</h2>
          <div className='toogle-div'>
                <text>Show Test Disbursements </text>
                <Toggle
                  name="autoAcceptOrder"
                  text={showTestDisbursements ? 'On' : 'Off'}
                  toggleColor={'#3CC13B'}
                  type="switch"
                  toggleWidth="38px"
                  toggleHeight="18px"
                  width="13.33px"
                  height="14.33px"
                  gap="12px"
                  transform="20px"
                  onChange={handleToggleChange}
                  checked={showTestDisbursements}
                  handleToggleSave={handleToggleChange}
                />
              </div>
          {size > 990 ? (
            <>
              <div className="search_sort">
                <Tabs value={value} onChange={handleChange}>
                  <Tab
                    disableRipple
                    newOrders={orderCountByCat}
                    className={orderCountByCat > 0 ? 'showNum' : ''}
                    label="Unpaid"
                    onClick={() => setIsSortOptionsActive(false)}
                  />
                  <Tab
                    disableRipple
                    newOrders={orderCountByCat}
                    className={orderCountByCat > 0 ? 'showNum' : ''}
                    label="Paid"
                    onClick={() => setIsSortOptionsActive(false)}
                  />
                  <Tab
                    newOrders={orderCountByCat}
                    className={orderCountByCat > 0 ? 'showNum' : ''}
                    disableRipple
                    label="Voided"
                    onClick={() => setIsSortOptionsActive(false)}
                  />
                </Tabs>
                <div
                  className="sort"
                  onClick={() => {
                    setIsSortOptionsActive(!isSortOptionsActive);
                  }}
                >
                  <p>Sort by date</p>
                  <img
                    className={`sortIcon ${isSortOptionsActive ? 'active' : ''}`}
                    src="/icons/sort.svg"
                    alt=""
                  />
                </div>
                {isSortOptionsActive && (
                  <>
                    <div
                      className="wrap"
                      onClick={() => {
                        setIsSortOptionsActive(!isSortOptionsActive);
                      }}
                    ></div>
                    <div className="sortable_options">
                      <div
                        className={`opt ${sortBy === 'newest' && 'active'}`}
                        onClick={() => {
                          setSortBy('newest');
                          setIsSortOptionsActive(false);
                        }}
                      >
                        Descending
                      </div>
                      <div
                        className={`opt ${sortBy === 'oldest' && 'active'}`}
                        onClick={() => {
                          setSortBy('oldest');
                          setIsSortOptionsActive(false);
                        }}
                      >
                        Ascending
                      </div>
                    </div>
                  </>
                )}
              </div>
                
              <div className="search-div">
                <label for="search-desk">
                  <img src="/images/search-normal.svg" alt="search Icon" />
                  <input
                    className="form-control"
                    id="search-desk"
                    type="text"
                    placeholder="Search Provider..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
              </div>
            </>
          ) : (
            <>
              <p className="select_header">Order status</p>
              <Select
                className="status_select"
                classNamePrefix="status-select"
                options={selectOptions}
                styles={customStyles}
                defaultValue={selectOptions[0]}
                onChange={handleStatusChange}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  SingleValue: ({ children }) => (
                    <span>{`${children} - ${orderCountByCat > 0 ? orderCountByCat : 0}`}</span>
                  ),
                }}
              />
              <div className="search_sort">
                <div className="search">
                  <label for="search">
                    <img src="/images/search-normal.svg" alt="search Icon" />
                    <input
                      className="form-control"
                      id="search"
                      type="text"
                      placeholder="Search order..."
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </label>
                </div>
                <div
                  className="sort"
                  onClick={() => {
                    setIsSortOptionsActive(!isSortOptionsActive);
                  }}
                >
                  <p>Sort by</p>
                  <img
                    className={`sortIcon ${isSortOptionsActive ? 'active' : ''}`}
                    src="/icons/sort.svg"
                    alt=""
                  />
                  {isSortOptionsActive && (
                    <>
                      <div
                        className="wrap"
                        onClick={() => {
                          setIsSortOptionsActive(!isSortOptionsActive);
                        }}
                      ></div>
                      <div className="sortable_options">
                        <div
                          className={`opt ${sortBy === 'newest' && 'active'}`}
                          onClick={() => {
                            setSortBy('newest');
                            setIsSortOptionsActive(false);
                          }}
                        >
                          Descending
                        </div>
                        <div
                          className={`opt ${sortBy === 'oldest' && 'active'}`}
                          onClick={() => {
                            setSortBy('oldest');
                            setIsSortOptionsActive(false);
                          }}
                        >
                          Ascending
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="section_body" id="scrollableDiv">
          {!isDataLoaded ? (
            <Progress />
          ) : (
            orderData?.length > 0 &&
            !searchTerm?.length && (
              <InfiniteScroll
                dataLength={orderData.length}
                next={fetchMore}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
              >
                {!isMobile
                  ? memorizedOrderData?.map((order, index) => (
                      <OrderCard
                        activeTab={activeTab}
                        onClick={() => handleProviderClick(order)}
                        active={activeOrderId === order?.uuid}
                        order={order}
                        key={index}
                      />
                    ))
                  : memorizedOrderData?.map((order, index) =>
                      order.disbursementList.map((disbursement, disbursementIndex) => (
                        <OrderCard
                          activeTab={activeTab}
                          onClick={() => handleOrderClick(disbursement, order)}
                          active={activeOrderId === disbursement?.id}
                          order={order}
                          disbursement={disbursement}
                        />
                      )),
                    )}
              </InfiniteScroll>
            )
          )}
          {searchTerm?.length > 0 && isLoading ? (
            <>
              <Progress />
            </>
          ) : (
            <>
              {searchTerm?.length > 0 && memorizedOrderData?.length ? (
                !isMobile ? (
                  memorizedOrderData.map((order, index) => (
                    <OrderCard
                      activeTab={activeTab}
                      onClick={() => handleProviderClick(order)}
                      active={activeOrderId === order?.uuid}
                      order={order}
                      key={index}
                    />
                  ))
                ) : (
                  searchTerm?.length > 0 &&
                  memorizedOrderData.flatMap((order, index) =>
                    order.disbursementList.map((disbursement, disbursementIndex) => (
                      <OrderCard
                        activeTab={activeTab}
                        onClick={() => handleOrderClick(disbursement, order)}
                        active={activeOrderId === disbursement?.id}
                        order={order}
                        disbursement={disbursement}
                      />
                    )),
                  )
                )
              ) : (
                <>
                  {isMobile && (
                    <p style={{ fontSize: '14px', textAlign: 'center' }}>No order found.</p>
                  )}
                  {!isMobile && (
                    <p style={{ fontSize: '14px', textAlign: 'center' }}>No provider found.</p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </OrderListSectionStyle>
      {!isMobile
        ? orderDetailsData && (
            <ProviderDetailsSection
              size={size}
              orderDetailsData={orderDetailsData}
              isLoading={isLoading}
              fetchAllProviders={fetchAllProviders}
              activeTab={activeTab}
            />
          )
        : orderDetailsData &&
          isOrderDetailsActive && (
            <OrderDetailsSection
              size={size}
              orderDetailsData={orderDetailsData}
              isLoading={isLoading}
              setIsOrderDetailsActive={setIsOrderDetailsActive}
              setActiveOrderId={setActiveOrderId}
              fetchAllProviders={fetchAllProviders}
              activeTab={activeTab}
            />
          )}
    </ProviderManagementStyle>
  );
}
