import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../shared/theme/theme';
import { useHistory } from 'react-router';
import { useAppContext } from 'context/appContext';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import 'animate.css';


const ProfileIconWrapper = styled.div`
position: ${({icon}) => icon ? "" : "relative"};
display: inline-block;
  .icon {
    width: 20px;
    height: 20px;
  }
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
  }

`;

const ProfileButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  .name, .email{
    font-size: 12px !important;
    font-weight: 400;
    text-align: left;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  display: flex;
  margin-left: 10px;

  flex-shrink: 0;

  border-radius: 50%;
`;

const DropdownMenu = styled.div`
  position: absolute;
  bottom: -20px;
  left: calc(100% + 22px);
  width: 300px;
  background-color: #fff;
  border-radius: 4px 4px 4px 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  z-index: 100;
  &:last-child {
    padding-bottom: 5px;
  }
  .animate__slideOutUp {
    animation-name: slideOutUp;
  }
  
  @keyframes slideOutUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-400px);
    }
  }
  
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 10px 0px;
`;

const ProfileInfo = styled.div`
  margin-left: 12px;
  .name {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  .email {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    overflow-wrap: anywhere;
    color: ${({ theme }) => theme?.colors?.blackLight};
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${theme.colors.lightGrey};
  padding: 2px 0;
`;

const DropdownSection = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;
  gap: 8px;

  &:hover {
    background-color: #fbfbfc;
  }
`;

const Text = styled.div`
color: ${({ redColor, theme }) => (redColor ? '#ff0000' : theme?.colors?.blackDefault)};
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const profile = ({ profileName, profileEmail, redColor, img, ...props }) => {
    const history = useHistory();
    const { logout } = useAppContext();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [animation, setAnimation] = useState(false);
    const profileImg = img || '/images/avatar.png';
    const handleToggleDropdown = () => {
      setAnimation((prevState) => !prevState);
        setIsDropdownOpen((prevState) => !prevState);
    };
    const handleClick = (path) => {
        history.push(path);
        setIsDropdownOpen(false);
        setAnimation(false);
    };

    const handleDropClick = () => {
      setTimeout(()=> {
        setIsDropdownOpen(false);
      },1000) 
      setAnimation(false);
    }
        
    return (
        <ProfileIconWrapper>
            {isDropdownOpen && <div className="mask"  onClick={handleDropClick}/>}
            <ProfileButton onClick={handleToggleDropdown}>
                <ProfileImage src={profileImg} alt="Profile Image" onError={(e) => { e.target.src = '/images/avatar.png' }}/>
                <ProfileInfo className='info' theme={theme}>
                    <div className="name">{profileName}</div>
                    <div className="email">{profileEmail}</div>
                </ProfileInfo>
            </ProfileButton>
            {isDropdownOpen && (
            <DropdownMenu style={{backgroundColor: theme.colors.white}} className={`animate__animated ${animation ? 'animate__slideInUp animate__faster' : 'animate__slideOutDown animate__faster'}`}>
                    <ProfileSection>
                        <ProfileImage src={profileImg} alt="Profile Image" onError={(e) => { e.target.src = '/images/avatar.png' }}/>
                        <ProfileInfo theme={theme}>
                            <div className="name">{profileName.length > 30 ? profileName.slice(0, 30) + '...' : profileName }</div>
                            <div className="email">{profileEmail}</div>
                        </ProfileInfo>
                    </ProfileSection>
                    {props.showProfile && (
                        <>
                            <Divider />
                            <DropdownSection  onClick={() => handleClick('/kitchen/profile')}>
                                <div className="icon">
                                    <img src="/icons/Account.svg" alt="profile Icon" />
                                </div>
                                <Text style={{color: theme?.colors?.blackDefault}}>Personal Profile</Text>
                            </DropdownSection>
                        </>
                    )}
                    {props.showBilling && (
                        <DropdownSection>
                            <div className="icon">
                                <img src="/icons/support.svg" alt="billing Icon" />
                            </div>
                            <Text>Billing</Text>
                        </DropdownSection>
                    )}
                    {props.showSetting && (
                        <DropdownSection>
                            <div className="icon">
                                <img src="/icons/setting-2.svg" alt="setting Icon" />
                            </div>
                            <Text>Setting</Text>
                        </DropdownSection>
                    )}
                    {props.showLogout && (
                        <>
                            <Divider />
                            <Link to="/logout" onClick={logout} style={{ display: "contents" }}>
                                <DropdownSection>
                                    <div className="icon">
                                        <img src={redColor?"/icons/ExitRed.svg" : "/icons/Exit.svg"} alt="logout Icon" />
                                    </div>
                                    <Text redColor={redColor} theme={theme}>Logout</Text>
                                </DropdownSection>
                            </Link>
                        </>
                    )}
                </DropdownMenu>
            )}
        </ProfileIconWrapper>
    );
};


export default profile;