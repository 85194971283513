import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { ReactComponent as Logout } from 'assets/logout.svg';
import { ReactComponent as ChevronRight } from 'assets/chevron-right.svg';
import { useAppContext } from 'context/appContext';
import ImageViewer from 'components/ImageViewer';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';

const KitchenSidebarMobile = styled.div`
  height: ${window.innerHeight}px;
  position: fixed;
  inset: 0;
  z-index: 112;
  .sidebar_wrapper {
    background: ${({ theme }) => theme?.colors?.white};
    width: 316px;
    height: 100%;
    inset: 0;
    padding-top: ${({ isWebView, platform}) => ( isWebView && platform ? '60px' : '44px')};
    padding-bottom: 20px;
    position: relative;
  }
  .provider_profile {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-right: 13px;
    padding-left: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
    .icon {
      width: 40px;
      height: 40px;
      overflow: clip;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .information {
      flex: 1;
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      .provider_name {
        color: ${({ theme }) => theme?.colors?.primaryGray};
        font-weight: 500;
      }
      .provider_email {
        color: ${({ theme }) => theme?.colors?.grayDarker};
      }
    }
  }
  .logout {
    position: absolute;
    bottom: 20px;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 14px;
    padding: 14px 13px 14px 24px;
    svg {
      display: block;
    }
    .title {
      flex: 1;
      color: ${({ theme }) => theme?.colors?.deepForestGreen};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 600;
      line-height: 20px;
    }
  }
`;

const ShadeBox = styled.div`
  position: absolute;
  inset: 0;
  width: 100vh;
  height: 100%;
  background: rgba(11, 29, 23, 0.65);
`;

const KitchenSidebar = ({ setIsSidebarActive }) => {
  const { user, provider, logout, isWebView, isPlatformIOS} = useAppContext();
  const history = useHistory();

  function renderProviderInfo(data, maxCharacterCount, elementClass) {
    if (data && data.length > maxCharacterCount) {
      // Truncate the data and add "..." at the end
      const truncatedData = `${data.slice(0, maxCharacterCount - 3)}...`;
      return <p className={elementClass}>{truncatedData}</p>;
    } else {
      // Display the full data
      return <p className={elementClass}>{data}</p>;
    }
  }
    
  return (
    <KitchenSidebarMobile theme={theme} isWebView={isWebView} platform={isPlatformIOS() ? true : false}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        style={{ position: 'absolute', inset: 0 }}
      >
        <ShadeBox className="shade" onClick={() => setIsSidebarActive(false)} />
      </motion.div>
      <motion.div
        initial={{ x: '-100%', opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ x: '-100%', opacity: 0 }}
        transition={{ duration: 0.3 }}
        style={{ height: '100%', display: 'inline-block' }}
      >
        <div className="sidebar_wrapper">
          <div className="provider_profile" onClick={() => {history.push('/kitchen/profile'), setIsSidebarActive(false)}}>
            <div className="icon">
              <ImageViewer src={provider?.images?.[0]} fallbackSrc={'/images/avatar.png'} />
            </div>
            <div className="information">
            {renderProviderInfo(
            `${provider?.name} ${provider?.lastName}`,
            28,
            "provider_name"
             )}
              <p className="provider_email">{renderProviderInfo(user?.email, 27, "provider_email")}</p>
            </div>
            <ChevronRight />
          </div>
          <div className="logout" onClick={logout}>
            <Logout />
            <p className="title">Log out</p>
            <ChevronRight />
          </div>
        </div>
      </motion.div>
    </KitchenSidebarMobile>
  );
};

export default KitchenSidebar;
