import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useAppContext } from 'context/appContext';

export default function MealTag({
  Icon,
  hideTooltip,
  tag,
  recommendedTags = [],
  notRecommendedRestrictions = [],
}) {
  const [open, setOpen] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isNotRecommended, setIsNotRecommended] = useState(false);
  const { pathname } = useLocation();

  const handleIsRecommended = () => {
    if (notRecommendedRestrictions.length !== 0) {
      notRecommendedRestrictions.forEach((notRecommendedRestriction) => {
        if (notRecommendedRestriction.toLowerCase() == tag.toLowerCase()) {
          return setIsNotRecommended(true);
        }
      });
    }
  };

  const handleRecommended = () => {
    if (recommendedTags.length !== 0 && notRecommendedRestrictions.length === 0) {
      recommendedTags.forEach((recommendedTag) => {
        if (recommendedTag.toLowerCase() === tag.toLowerCase()) {
          return setIsRecommended(true);
        }
      });
    }
  };
  useEffect(() => {
    handleIsRecommended();
    handleRecommended();
  }, [recommendedTags, notRecommendedRestrictions]);
  let { isMobile } = useAppContext();
  const desktopView = isMobile ? '' : 'desktopView';

  return pathname !== "/shop/providers" && pathname !== "/visitor/providers" || isMobile? (
    <div onClick={(e) => e.stopPropagation()} className={`${tag?.length<9?'smallPopper':''} ${isNotRecommended ? 'notRecommended iconList45' : isRecommended ? 'recommended iconList45' : 'iconList45'}`}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpen(false)}
          open={open}
          title={isNotRecommended ? `${tag} is not recommended in your diet plan` : tag}
          enterDelay={3000}
          placement={isMobile ||  pathname !== "/shop/meals" ? 'bottom' : 'right'}
        >
          <div
            key={tag}
            className={`tag-container ${
              open
                ? isRecommended
                  ? 'recommded'
                  : isNotRecommended
                  ? 'no-Recommended'
                  : 'activeTooltip'
                : ''
            }`}
          >
            <div className={`${desktopView} display-tag`}>
              <span 
                className={`icon-wrapper ${tag}`} 
                onClick={() => !hideTooltip && setOpen(true)} 
                style={{cursor:"pointer"}}
                >
                {Icon && (
                  <Icon
                    color={
                      open && (isRecommended || isNotRecommended)
                        ? '#f8f8f8'
                        : isRecommended
                        ? '#52C41A'
                        : isNotRecommended
                        ? '#FEB95A'
                        : '#949D9B'
                    }
                  />
                )}
              </span>
              <span className="tag-name">{tag}</span>
            </div>
          </div>
        </Tooltip>
      </ClickAwayListener>
    </div>
  ) : (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`${tag?.length < 9 ? 'smallPopper' : ''} ${
        isNotRecommended
          ? 'notRecommended iconList45 tag-list'
          : isRecommended
          ? 'recommended iconList45 tag-list'
          : 'iconList45 tag-list'
      }`}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpen(false)}
          open={open}
          title={isNotRecommended ? `${tag} is not recommended in your diet plan` : tag}
          enterDelay={3000}
          placement="right"
        >
          {isMobile ? (
            <>
              <div
                key={tag}
                className={`tag-container ${
                  open
                    ? isRecommended
                      ? 'recommded'
                      : isNotRecommended
                      ? 'no-Recommended'
                      : 'activeTooltip'
                    : ''
                }`}
              >
                <div>
                  <span
                    className={`icon-wrapper ${tag}`}
                    onClick={() => !hideTooltip && setOpen(true)}
                  >
                    {Icon && (
                      <Icon
                        color={
                          open && (isRecommended || isNotRecommended)
                            ? '#f8f8f8'
                            : isRecommended
                            ? '#52C41A'
                            : isNotRecommended
                            ? '#FEB95A'
                            : '#949D9B'
                        }
                      />
                    )}
                  </span>
                  <span className="tag-name">{tag}</span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div key={tag} className={`tag-container ${open ? isRecommended  ? 'recommded'
                 : isNotRecommended
                      ? 'no-Recommended'
                      : 'activeTooltip' : '' }`} >
                <div className={`${desktopView}`}>
                  <span
                    className={`icon-wrapper ${tag}`}
                     // onClick={() => !hideTooltip && setOpen(true)}
                  >
                    {Icon && (
                      <Icon
                        color={
                          open && (isRecommended || isNotRecommended)
                            ? '#f8f8f8'
                            : isRecommended
                            ? '#52C41A'
                            : isNotRecommended
                            ? '#FEB95A'
                            : '#949D9B'
                        }
                      />
                    )}
                  </span>
                  <span className="tagname">{tag}</span>
                </div>
              </div>

            </>
          )
          }
        </Tooltip>
      </ClickAwayListener>
    </div>
  )
}

