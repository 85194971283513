import _ from 'lodash';
import useCart from 'hooks/useCart';
import styled from 'styled-components';
import { useShopContext } from 'context/shopContext';
import React, { useEffect, useState } from 'react';
import { sum, multiply } from 'ramda';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import ProviderMethod from './ProviderMethod';
import { useAppContext } from 'context/appContext';
import HandleCartMealProvider from './HandleCartMealProvider';
import {getEarliestTime, toDateString} from 'pages/shop/providerTimes';

const ProviderTitle = styled.div`
  .provider-head {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width: 992px) {
    .provider-head {
      border-bottom: 1px solid #e0e3e0;
      width: 100%;
      margin-bottom: 24px;
      .provider-head-name {
        margin: 15px 20px;
      }
    }
  }
  @media (max-width: 440px) {
    .provider-head {
      display: block;
      h3 {
        margin-bottom: 5px;
      }
    }
  }
`;
const ProviderMain = styled.div`
  &:last-child .add-more-wrapper {
    border-bottom: 0
  }
  .meals-count-clear-wrapper {
    height: 41px;
    padding: 0 24px 0 17px;
    border-bottom: 1px solid #E0E3E0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .meals-count {
      color: #51635D;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
    }
    .meals-clear-all {
      color: #52C41A;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .add-more-wrapper {
    height: 36px;
    background: #F0FAEB;
    border-top: 1px solid #CBEDBA;
    border-bottom: 1px solid #CBEDBA;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
    span {
      color: #52C41A;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .provider-head-title-providerImg {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    .provider-img-wrapper {
      margin-right: 10px;
      img {
        width: 40px;
        height: 40px;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        object-fit: cover;
      }
    }
    .provider-head-title {
      display: flex;
      flex-direction: column;
      span {
        color: #949D9B;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;
      }
    }
  }
  .provider-delivery-pickup-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
@media (min-width: 992px) {
  :first-child {
    border-bottom: 1px solid #e0e3e0;
  }
  :second-child {
    border-bottom: none;
  }
  .provider-main {
    .provider-card {
      height: 388px;
      overflow-y: auto;
    }
    .provider-minimum-order-ammount {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-weight: 700;
        font-size: 18px;
        color: #d13726;
        margin-right: 0 !important;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
      .provider-link {
        width: 143px;
        height: 40px;
        background: #52c41a;
        border-radius: 21px;
        font-weight: 700;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
`;

export default function ProviderCardAdded({ uniqMealData, setDisableCheckoutBtn, disableCheckoutBtn }) {
  const { isMobile } = useAppContext();
  const { settings, updateSettings } = useShopContext();
  const { removeProviderFromCart } = useCart();
  const [ minimumOrderNotMeetArr, setMinimumOrderNotMeetArr ] = useState([]);
  const history = useHistory();
  const providersArray = settings.providerSettings.map((i) => i);

  useEffect(() => {
    const minimumOrderNotMeetArr = uniqMealData.map((mealData, i) => {
      const machedProvider = providersArray.find(
        (i) => mealData?.[0] && i.providerId === mealData?.[0].providerId,
      );
      
      const mealTotalPrice = sum(mealData.map((i) => multiply(i.price, i.qty)));
      const minimumOrderNotMeet = mealTotalPrice / 100 < machedProvider?.minOrderAmount;  
      return {providerId: machedProvider?.providerId, minimumOrderNotMeet, minOrderAmount: machedProvider?.minOrderAmount};
    });
    const minimumOrderNotMeet = minimumOrderNotMeetArr?.every((item)=>!item.minimumOrderNotMeet)
    setDisableCheckoutBtn(!minimumOrderNotMeet);
    setMinimumOrderNotMeetArr(minimumOrderNotMeetArr)
  }, [uniqMealData]);

  return uniqMealData.map((mealData, i) => {
    const machedProvider = providersArray.filter(
      (i) => mealData?.[0] && i.providerId === mealData?.[0].providerId,
    );
    const minimumOrderNotMeet = minimumOrderNotMeetArr.find(item => item?.providerId === machedProvider[0]?.providerId)?.minimumOrderNotMeet
    const handleUpdateProvider = async () => {
      const providerSelected = machedProvider[0];
      await updateSettings(
        produce((draft) => {
          // Add provider to Settings
          draft.provider = providerSelected;
        }),
      );
    };
    const shopMeals = async () => {
      await handleUpdateProvider();
      history.push(`/shop/meals`);
      window.scrollTo(0, 0);
    };
    const earliestDeliveryTime = getEarliestTime(machedProvider?.[0]?.schedule || {}, 'delivery');
    const earliestPickupTime = getEarliestTime(machedProvider?.[0]?.schedule || {}, 'pickup');

    return mealData[0]?.id ? (
      <ProviderMain>
        <div className="provider-main" key={`main${mealData[0].id}`}>
          <ProviderTitle>
            {isMobile ? (
              <div className="provider-head">
                <div className='provider-head-title-providerImg'>
                  <div className='provider-img-wrapper'>
                    <img src={machedProvider?.[0]?.image?.[0] || `/images/dish.png`} />
                  </div>
                  <div className='provider-head-title'>
                    <h3 className="provider-head-name">{machedProvider?.[0].name}</h3>
                    <span>Min order: ${machedProvider?.[0].minOrderAmount}</span>
                  </div>
                </div>
                <div className='provider-delivery-pickup-wrapper'>
                  {earliestDeliveryTime && 
                    <ProviderMethod 
                      methodImg="delivery-icon.svg"
                      methodBy="Delivery by:"
                      methodDate={toDateString(earliestDeliveryTime)}
                    />
                  }
                  {earliestPickupTime &&
                    <ProviderMethod 
                      methodImg="pickup-icon.svg"
                      methodBy="Pick-up by:"
                      methodDate={toDateString(earliestPickupTime)}
                    />
                  }
                </div>
              </div>
            ) : (
              <div className="provider-head">
                <h3 className="provider-head-name">
                  {machedProvider?.[0].name}
                  {`(${sum(mealData.map((i) => i.qty))})`}
                </h3>
              </div>
            )}
          </ProviderTitle>
          {isMobile && 
            <div className='meals-count-clear-wrapper'>
              <div className='meals-count'>{`${sum(mealData.map((i) => i.qty))}`} meals</div>
              <div className='meals-clear-all' onClick={() => removeProviderFromCart(mealData)}>Clear all</div>
            </div>
          }
          {!isMobile && mealData.length > 4 && providersArray.length > 1 ? (
            <div className="provider-card">
              <HandleCartMealProvider mealData={mealData} key={mealData[0].id} />
            </div>
          ) : (
            <HandleCartMealProvider mealData={mealData} key={mealData[0].id} />
          )}
          {isMobile && !minimumOrderNotMeet && (
            <div className='add-more-wrapper'  onClick={shopMeals}>
              <img src='/icons/add-green.svg' alt="Add Icon" />
              <span>Add more</span>
            </div>
          )}
          {isMobile ? (
              minimumOrderNotMeet ? (
              <div className="provider-minimum-order-ammount">
                <p>Order minimum not met</p>
                <p className="provider-link" onClick={shopMeals}>
                  Add meals
                </p>
              </div>
            ) : (
              ''
            )
          ) :  minimumOrderNotMeet ? (
            <div className="provider-minimum-order-ammount">
              <p>
                <img src="/icons/danger.svg" />
                Order minimum not met
              </p>
              <button className="provider-link" onClick={shopMeals}>
                Add meals
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </ProviderMain>
    ) : (
      ''
    );
  });
}