import classNames from 'classnames';
import Styled from 'styled-components';
import CheckRight from '../CheckRight';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import MealMacrosInfo from './MealMacrosInfo';
import autoAnimate from '@formkit/auto-animate';
import { debounce } from "lodash";
import { calculateNutritionPerGram } from 'utils';
import UserMealCard from 'components/elements/MealCard/UserMealCard';

const SearchMealWrapper = Styled.div`
  // max-height: 110px;
  margin: 0 auto;
  opacity: 1;
  &.moveMeal {
  //  max-height: 0;
    opacity: 0;
    transition: max-height 1s ease-in-out 1s, opacity .7s ease-in-out 1s;
  }
  .meal-details-wrapper {
    display: flex;
    justify-content:flex-start;
    .meal-img-wrapper {
      margin-right: 20px;
      display: flex;
      img {
        width: 60px;
        height: 70px;
        object-fit: contain;
      }
    }
    .meal-content-wrapper {
      overflow:hidden;
      z-index:${(props) => (props?.index === props?.openMealIndex ? '1' : 'unset')};
      width:100%;
      .meal-title {
        margin-bottom: 3px;
        color: #0B1D17;
        font-size: 14px;
        line-height: 16px;
        padding-right: 25px;
        .meal-name{
           width:85%;
        }
      }
      .brand-name {
        margin-bottom: 9px;
        color: #949D9B;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
      }
      .meal-info-add {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .meal-info {
        height: 100%;
        margin-right: 10px;
        // border: 1px solid #E0E3E0;
        // border-radius: 6px;
        display: flex;
        column-gap: 14px;
      }
      .item {
        position: relative;
        width: auto;
        height: auto; 
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
         // width: 136px;
          // border-left: 1px solid #E0E3E0;
          span{
            color: #51635D;
            font-weight: 400;
          }
          .item-count{
            margin-left:4px;
          }
        }
        span {
          color: #52C41A;
          font-size: 11px;
          line-height: 12px;
          letter-spacing: 0.02em;
          font-weight: 700;
        }
        .item-count {
          // margin-left: 4px;
          font-weight: 700 !important;
        }
      }
      .edit-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        width: 10px;
        height: 10px;
      }
      .add-icon-wrapper {
        width: 28px;
        height: 28px;
        padding: 0;
        border: 1px solid #CBEDBA !important;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        flex-shrink: 0;
        margin-top: -14px;
        margin-right:4px;
      }
      .add-icon {
        width: 14px;
        height: 14px;
        display: flex;
      }
      button: disabled{
        cursor: not-allowed;
      }
    }
  }
`;
const { token } = JSON.parse(localStorage.getItem('user')) || {
  token: '',
};
export default function SearchMeal({
  meal,
  searchValue,
  setMealInfo,
  mealId,
  setFoodId,
  setMealMacroInfo,
  setSearchedFoodDetails,
  setLogMealFromList,
  showServingInfo,
  setShowServingInfo,
  setIsFoodDetailsLoading,
  setSearchQuantity,
  handleMealClick,
  openMealIndex,
  index,
  currentDayinfo,
  logMealBarcode,
  selectedSliderIndex,
  setSelectedSliderIndex,
  setAccessMealDetails
}) {
  const [macrosInfo, setMacrosInfo] = useState({});
  const parent = useRef(null);
  const [showMacros, setShowMacros] = useState(false);
  const { food_name, food_id, brand_name } = meal;
  const servings = meal.servings
  const serving = servings?.serving
  const numberQtyConstants = Array.from({length: 11}, (_, i) => i);
  const gramsUnitConstant = Array.from({length: 100}, (_, i) => (i + 1) * 10);
  var fractionalConstants = ["--",1/4,1/2,3/4]; 
  const safeSearchValue = searchValue?.replace(/[^a-zA-Z0-9 ]/g, '');
  const foodName = safeSearchValue?.length ? (
    <div
      dangerouslySetInnerHTML={{
        __html: food_name?.replace(new RegExp(`(${safeSearchValue})`, 'ig'), '<b>$1</b>'),
      }}    
    />
  ) : (
    food_name
  );

  const getDefaultServingMacros = useCallback(() => {
    const servingList = Array.isArray(servings?.serving) ? servings?.serving[0] : servings?.serving;
    const defaultServing= servingList || {};
    const { calories, fat, protein, carbohydrate } = defaultServing || {};
    return { calories, fat, protein, carbohydrate };
  }, [servings]);
  
  const defaultServing = useMemo(() => getDefaultServingMacros(), [servings]);
  
  const accessMealDetails = () => {
    setFoodId(food_id);
    setMealInfo({ foodName: food_name, caloriesNumber:defaultServing?.calories });
    const selectedServing = Array.isArray(serving) ? serving[0] : serving;
    setIsFoodDetailsLoading(true);
    setSearchedFoodDetails(meal)
    if (showMacros) {
      setMealMacroInfo(macrosInfo);
    } else {
      setMealMacroInfo(selectedServing)
    }

  };

  useEffect(() => {
    if(logMealBarcode){
      accessMealDetails();
      setAccessMealDetails(false);
    }
  }, [logMealBarcode])

const isServingArray = useMemo(() => {
  if (serving instanceof Array || !serving?.metric_serving_unit) {
    return [serving];
  }

  const {
    caloriesPerGram,
    fatPerGram,
    proteinPerGram,
    carbsPerGram,
  } = calculateNutritionPerGram(serving);

  return [
    serving,
    {
      ...serving,
      calories: caloriesPerGram,
      protein: proteinPerGram,
      fat: fatPerGram,
      carbohydrate: carbsPerGram,
      measurement_description: "g",
      number_of_units: "100.00",
      metric_serving_unit: "g",
      serving_description: "100 g",
      isCustom: true,
    },
  ];
}, [serving]);



  useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent])

  const handleMealTitleClick = debounce(async () => {
    if (openMealIndex === index) {
      setShowMacros(!showMacros);
      setShowServingInfo(false);
    } else {
      setShowMacros(true);
      setShowServingInfo(false);
      handleMealClick(index);
    }
  }, 300);
  return (
    <SearchMealWrapper className={classNames({ moveMeal: mealId == food_id })} index={index} openMealIndex={openMealIndex}>
      <div className="meal-details-wrapper">
        <div className="meal-content-wrapper" ref={parent}>
          <div className='meal-content-container' onClick={serving && handleMealTitleClick}>
          <UserMealCard 
            mealName={food_name?.toLowerCase().includes(searchValue?.toLowerCase()) ? foodName : food_name} 
            cals={defaultServing?.calories} 
            fat={defaultServing?.fat} 
            protein={defaultServing?.protein} 
            carbs={defaultServing?.carbohydrate} 
            onAddClick={(e) =>{
              e.stopPropagation();
              accessMealDetails();
              setLogMealFromList(true);
            }} 
            imageDimension={65}
            iconPositionEnd={14}
          />
          </div>
          {
             ( showMacros && index===openMealIndex) &&
              <MealMacrosInfo 
              showMacros={showMacros} 
              servings={Array.isArray(serving) ? serving : isServingArray }
              showServingInfo={showServingInfo}
              macrosInfo={macrosInfo}
              setShowServingInfo={setShowServingInfo}
              numberQtyConstants={numberQtyConstants}
              fractionalConstants={fractionalConstants}
              setMacrosInfo={setMacrosInfo}
              setSearchQuantity={setSearchQuantity}
              gramsUnitConstant={gramsUnitConstant}
              currentDayinfo={currentDayinfo}
              selectedSliderIndex={selectedSliderIndex}
              setSelectedSliderIndex={setSelectedSliderIndex}
              />
            }
          <div className='border' style={{ padding: '6px 0px' }}>
            <div style={{ height: '1px', width: '100%', backgroundColor: '#E0E3E0' }}></div>
          </div>
        </div>
      </div>
    </SearchMealWrapper>
  );
}
