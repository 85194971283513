import React, { useState } from 'react';
import styled from 'styled-components';
import LinesEllipsis from 'react-lines-ellipsis';
import QuantityButton from '../QuantityButton';
import useCart from 'hooks/useCart';
import { useAppContext } from 'context/appContext';
import useVisitorCart from 'hooks/useVisitorCart';
import Fade from '@mui/material/Fade';

const CartItemStyle = styled.div`
  background-color: #fff;
  .main_box {
    display: flex;
    gap: 15px;
    margin-left: 16px;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .title {
    color: #0b1d17;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    word-wrap: break-word;
  }
  .plate_box {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1px;
    overflow: hidden;
  }
  .row {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .price {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color:#00092D
  }
  .limit-order {
    color: #EB3223;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const CartItem = ({ svg, title, price, meal, calculateTotalAmount, updateMealQty, mealID, removeFromCart, orderLimit }) => {
  const [mealCount, setMealCount] = useState({
    default: 1,
    dark: 1,
    light: 1,
    smallLight: meal.qty ?? 1,
    smallLightRemove: 1,
    mealCard: 1,
  });
  const { user, isMobile } = useAppContext();
  const { addToCart } = user ? useCart() : useVisitorCart();

  const displayLimitOrderMessage = () => <Fade in={orderLimit && orderLimit === meal?.qty}><div className='limit-order'>Limit {orderLimit} per order</div></Fade>;

  return (
    <CartItemStyle>
      <div className="main_box">
        <div className="plate_box">
          <img src={svg || '/images/Kitchen-image.png'} />
        </div>

        <div className="div">
          <text className="title">
          <LinesEllipsis
            text={title}
            maxLine={1}
            ellipsis='...'
            trimRight
            basedOn='letters'
          />
          </text>
          {!isMobile && displayLimitOrderMessage()}
          <div className="row">
            <text className="price">
              {price}
              {isMobile && displayLimitOrderMessage()}
            </text>
            <QuantityButton
              count={meal?.qty}
              remove={meal?.qty === 1}
              variant="light"
              size="small"
              onClickRemove={() => {
                if (meal?.qty === 1) { removeFromCart(mealID) } else {
                  setMealCount({ ...mealCount, smallLight: mealCount.smallLight - 1 });
                  updateMealQty(meal, +meal?.qty-1);
                  calculateTotalAmount()
                }
              }}
              onClickAdd={() => {
                setMealCount({ ...mealCount, smallLight: mealCount.smallLight + 1 });
                addToCart({...meal, qty: 1});
                calculateTotalAmount()
              }}
              orderLimit={orderLimit}
            />
          </div>
        </div>
      </div>
    </CartItemStyle>
  );
};
