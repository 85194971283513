import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ShareCard from './ShareCard';
import 'animate.css';
import { useState, useEffect } from 'react';
import { useRequest } from 'hooks/useRequest';
import Progress from 'components/Progress';
import { useNutritionistContext } from "context/nutritionistContext"
import { useAppContext } from 'context/appContext';

const Container = styled.div`
	background: #fff;
	.container{
		max-width: 500px;
		margin: auto;
		width: 100% !important;
		margin:0 auto;
		padding-right: 24px;
		padding-left: 24px;
		@media (max-width:376px){
			padding-right: 20px;
			padding-left: 20px;
		}
		@media (max-width:321px){
			padding-right: 14px;
			padding-left: 14px;
		}
	}
        .close-btn{
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			display: flex;
			padding:10px 20px;
			align-items: center;
			color: #52C41A;
        }
  `
export default function Share() {
	const [close, setclose] = useState(false);
	const history = useHistory();
	const { challangeId } = useParams();
	const { nutritionist: nutritionistData } = useNutritionistContext();
	const [challangeData, setChallengeData] = useState()
	const [getChallenge, {isLoading}] = useRequest();
	const dataFromCtx = nutritionistData?.nutritionist?.dietTemplates?.filter((obj) => obj?.id == challangeId);
	const{modalShow,setModalshow} = useAppContext();
	
	const getChallengeFn = async (id) => {
		const path = `nutritionist/challenge/${id}`
		await getChallenge({
			path
		}).then((result) => {
			setChallengeData(result?.data)
		})
	}
	useEffect(() => {
		if (challangeId && !dataFromCtx?.length) {
			getChallengeFn(parseInt(challangeId))
		}
	}, [challangeId])

	const handleClose = () => {
		setclose(!close);
		setModalshow(false);
		setTimeout(() => { history.push('/nutritionist/Challanges') }, 500);
	};
	
	return (
		<Container>
			{isLoading && !dataFromCtx?.length ? 
				<Progress /> : 
				<div className={close ? 'animate__animated animate__slideOutRight animate__faster' : 'animate__animated animate__slideInRight animate__fast'}>
					<Link >
						<div className='close-btn' onClick={handleClose}>Close</div>
					</Link>
					<ShareCard nutritionistUrl={nutritionistData?.nutritionist?.url} challangeId={challangeId} isCreate={history?.location?.pathname.includes('create/sharechallenge')} challangeData= {dataFromCtx?.length ? dataFromCtx[0] : challangeData} />		
				</div>
			}
		</Container>
	)
}