import { useState, useContext, useEffect } from 'react'
import ProviderHeader from './ProviderHeader'
import styled from 'styled-components'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { usePreview } from 'react-dnd-preview';
import { useAppContext } from 'context/appContext';
import CartCardList from './CartCardList';
import Drawer from '@mui/material/Drawer';
import { PlannerCardItemInfo } from './PlannerCardItem';
import { useRequest } from 'hooks/useRequest';
import UseClientCart from 'hooks/UseClientCart';
import { ClientDiaryContext } from '../NutritionistClientProfile';
import { useNutritionistContext } from 'context/nutritionistContext';
import { renderTotalCalories } from 'hooks/caloriesColorRules';
import PlannerCard from './PlannerCard';
import PlannerCardItem from './PlannerCardItem';
import { toast } from 'react-toastify';
import produce from 'immer';
import { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
 
const MealPlannerStyle = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 114;
    background-color: #F6F5F8;

    ::-webkit-scrollbar{
        display: none;
    }

    .header__holder{
        padding: 24px 8px;
        background-color: #fff;
        border-bottom: 1px solid #E0E3E0;
        position: fixed;
        z-index: 3;
        width: 100%;

        .title{
            margin-bottom: 0;
        }
    }

    .tabs__section{
        padding: 24px 20px 0;
        margin-bottom: 114px;
        margin-top: 73px;

        @supports  (selector(:nth-child(1 of x))) or (-webkit-touch-callout: none) {
            margin-bottom: 200px;
        }

        .MuiTabs-root{
            max-width: 290px;
            margin: auto;
            min-height: 32px;
            border: 1px solid #52C41A;
            border-radius: 4px;
            background-color: #fff;

            button{
                width: 50%;
                min-height: 32px;

                .MuiTab-wrapper{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #51635D;
                }

                .MuiTouchRipple-root{
                    display: none;
                }
            }

            .MuiTabs-indicator{
                height: 100% !important;
                border-radius: 0 !important;
            }

            .Mui-selected .MuiTab-wrapper{
                color: #fff;
            }

            .PrivateTabIndicator-root-1{
                z-index: 1;
            }

            .MuiTabs-flexContainer{
                position: relative;
                z-index: 2;
            }
        }

        .tab__panel{
            
            .info{
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #949D9B;
                margin: 16px 0;
                gap: 6px;
            }

            .MuiBox-root{
                padding-top: 0 !important;
            }
        }
    }

    .item-list__item__outer{
        width: 315px;
        z-index: 9;

        & > div{
            border: 2px solid #97DC76;
            box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
        }
    }

    .send__to__cart{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 24px 30px;
        background: #FFFFFF;
        border: 1px solid #F6F5F8;
        button{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
            background: #52C41A;
            border-radius: 21px;
            padding: 13px;
            width: 100%;
        }
    }

    @media (min-width: 390px) and (max-width: 429px){

        .tabs__section{
            padding: 24px 24px 0;

            .MuiTabs-root{
                max-width: 294px;
            }
        }

    }

    @media (min-width: 430px){

        .tabs__section{
            .MuiTabs-root{
                max-width: 334px;
            }
        }

    }

    @media (max-width: 360px){
        
        .tabs__section{
            padding: 24px 14px 0;

            .MuiTabs-root{
                max-width: 296px;
            }
        }
    }

    @media (max-width: 320px){
        
        .tabs__section{
            .MuiTabs-root{
                max-width: 272px;
            }
        }
    }
    .__react_component_tooltip{
        width: 240px;
        word-break: break-all;
        text-align:center;
       }

   
      .__react_component_tooltip{
          width: 240px;
          word-break: break-all;
          text-align:center;
         }

`

const MoreOptionsModal = styled.div`
 
    padding: 20px 15px;

    .div__holder{
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 24px 26px;
        width: 100%;
    
        .option{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
            padding: 4px 0;
            display: flex;
            gap: 12px;
            margin-bottom: 12px;
            cursor:pointer;

            &:last-child{
                margin-bottom: 0;
            }

            img{
                width: 16px;
                height: 16px;
            }
        }
    }
`
const MovedayModal = styled.div`

 padding:20px 15px;
 height:100%;
 
 .choose-day {
     width:92%;
     height:auto;
     background:#fff;
     margin: 0 auto;
     padding:20px 24px;
     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
     border-radius: 10px;
     max-height:94%;
     overflow:auto;
     position:absolute;
     bottom:20px;
     &::-webkit-scrollbar {
        display:none;
      }

     .meal{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #0B1D17;
        margin-bottom:14px;
     }
     .days{
        height: 42px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #51635D;
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:0 18px;
        margin-bottom:11px;
     }
     .matched-day{
        border: 1px solid #CBEDBA;
        border-radius: 21px;    
     }
     
    .btn-div{
        display:flex;
        justify-content:space-between;
        align-items:center;
        .cancel, .apply{
            border-radius: 21px;
            width: 138px;
            height: 40px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            display:flex;
            justify-content:center;
            align-items:center;
        }
        .apply{
            background: #52C41A;
            color: #FFFFFF;
        }
        .cancel{
            background:#FFFFFF;
            border:1px solid #97DC76 !important;
            color: #51635D;
        }
    }
     
    }
`
const MoveCartModal = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 11;
padding-top: 21px;
background-color: #fff;
//visibility: hidden;
transition: all .5s ease-in-out;
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;

.week{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    margin-top:34px;
}
.added{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #51635D;
    margin-top:12px;
    margin-bottom:31px;
    p{
        padding: 0 64px;
        text-align: center;
    }
}
.follower-btn{
    button{
    background: #52C41A;
    border-radius: 21px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items:center;
    width:170px;
    color:#fff;
    }
}

`

function shortName(clientName){
   
    return(
       <>
     { clientName?.length >10 ?  <p data-tip={clientName} data-for="sname" style={{cursor: 'pointer'}}>{ clientName.substring(0, 10) + "..."}'s meal planner </p>
       :
      <p>{clientName}'s meal planner</p>}
       <ReactTooltip id="sname" place="bottom" effect="solid" className='name-tooltip'>
        </ReactTooltip>
      </>
    )
  }
  
export default function MealPlanner({setMealPlannerModal, clientName,setPickMealsModal,setProviderModal,setClientModal, providerId, setProviderId, setShowPickMealAnimation, setProviderModalAnim, setPickMealsAnim, setMealsModalsAnim, setClientAnim, mealPlannerModal,setMealPlannerClose, setVisibility}) {

    const [showAnim, setShowAnim] = useState(true)
    const [mealImg, setMealImg] = useState(null);
    const [activeReport, setActiveReport] = useState(0);
    const { isMobile } = useAppContext();
    const [moreOptionsModal, setMoreOptionsModal] = useState(false);
    const [moveDayModal, setMoveDayModal] = useState(false);
    const { removeFromClientCart, updateClientMealQty } = UseClientCart();
    const [data, setData] = useState({});
    const {followerProfile} = useContext(ClientDiaryContext);
    const [cartModal, setCartModal] = useState(false)
    const [selectday, setSelectday] = useState(data.day)
    const autoScrollDiv = useRef();
    const {getLoadCartMeal} = useAppContext();

    const {
        updateClientsettings,
        clientsettings: { mealPlan, days, meals },
        updateClientcart,
        clientcart,
        nutritionist,
    } = useNutritionistContext();
   
    const coachid = nutritionist?.user?.id;

    const [loadClientCartApi, {isLoading}] = useRequest(`nutritionist/addToClientCart/${coachid}`, 'post');

    const [currentDays, setcurrentDays] = useState([]);
    const [currentDragDay, setCurrentDragDay] = useState();
    let loadCartPayload={};
    const mealInCart = clientcart.find((meal) => meal.id === data.id);

    useEffect(() => {
        setcurrentDays([]);
        [...Array(days)].map((_, i) => setcurrentDays((prevState) => [...prevState, `Day ${i + 1}`]));
    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMoreOptionsModal(open);
        setMoveDayModal(open);
    };

    const handleChange = (event, value) => {
        setActiveReport(value);
    };

    const MyPreview = () => {
        const { display, style } = usePreview();
        if (!display) {
            return null;
        }
        return (
            <div className="item-list__item__outer" style={style}>
                <PlannerCardItemInfo meal={mealImg}/>
            </div>
        );
    };

    let payload = [{ date: new Date().toISOString(), day: 'Day 1', mealIds: [] }];

    const getAcceptedDays = (currentDay) => currentDays.filter((day) => day !== currentDay);

    const checkDayExist = (day) => {
        const result = payload.findIndex((item) => item.day === day)
        if (result < 0) {
            return false
        } else {
            return true
        }
    };

    const injectAtDayPosition = (day, id) => {
        const updatedItem = payload.map((item) => {
            if (item.day === day) {
                return { ...item, mealIds: [...item.mealIds, id] }
            } else {
                return item;
            }
        })
        payload = updatedItem
        loadCartPayload.cart = payload
    };

    const renderItemsForColumns = (day) => {
        return mealPlan
            .filter((meal) => meal.day === day)
            .map((meal, index) => {
                const doesExist = checkDayExist(meal.day)
                if (doesExist) {
                    injectAtDayPosition(meal.day, meal.id)
                } else {
                    payload.push({ date: new Date().toISOString(), day: meal.day, mealIds: [meal.id] })
                    loadCartPayload.cart = payload
                }
                return (
                    <PlannerCardItem autoScrollDiv={autoScrollDiv} setData={setData} setMoreOptionsModal={setMoreOptionsModal} meal={meal} key={`${meal.id}-${index}`} setMealImg={setMealImg} day={day} setSelectday={setSelectday} />
                );
            });
    };

    const loadClientCartFn = async()=> {
        await loadClientCartApi({body: {...loadCartPayload, clientId: followerProfile?.userid, meals, days}})
        .then((data)=>{
            if(data.success===true){
                setCartModal(true);
                setVisibility(true)
                setTimeout(()=>{
                    updateClientsettings(
                        produce((draft) => {
                        draft.mealPlan = [];
                        draft.providerSettings = [];
                        }),
                    );
                    updateClientcart([]);
                },800)
               
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            console.log(error.message);
            toast.error(data.message);
        });
        
    }
    
    const handleRemove = () => {
        mealInCart.qty == 1
        ? removeFromClientCart(data.id)
        : updateClientMealQty(data, +mealInCart.qty - 1)
        setMoreOptionsModal(false);
    };

    const handleclick = () => {
        setMealPlannerClose(true)
        setMealsModalsAnim && setMealsModalsAnim(false)
        setProviderModalAnim && setProviderModalAnim(false)
        setPickMealsAnim && setPickMealsAnim(false)
        setShowAnim && setShowAnim(false) 
        document.body.classList.remove('hide-scroll');

        
        setTimeout(() => {
            setCartModal(false)
            setMealPlannerModal(false)
            setPickMealsModal(false)
            setProviderModal(false)
            setClientModal(false)
            
        }, 500);
       
    };

    const changeday =(propDay)=>{
       const result =  mealPlan.map((item)=>{
        if(String(item.idx)==String(data.idx)){
               return {...item,day:propDay}
            }
            else{
                return item
            }
    })
    updateClientsettings(
        produce((draft) => {
          draft.mealPlan=result;
        }),
      );
      setSelectday(data.day)
      setMoveDayModal(false);
      setMoreOptionsModal(false);
     
    }

    useEffect(()=>{
        ReactTooltip.rebuild();
      },[cartModal,mealPlannerModal])    

      useEffect(() => {
        if(mealPlannerModal){
            document.body.classList.add('bodyFixed');
        }
        else{
          document.body.classList.remove('bodyFixed');
        }
    }, [mealPlannerModal])
 
    return (
        <>
            <MealPlannerStyle ref={autoScrollDiv} className={showAnim ? 'fadeInMiddle' : 'fadeOutMiddle'}>
                <div className='header__holder'>
                    <ProviderHeader
                        title={shortName(clientName)}
                        closeModal={setMealPlannerModal}
                        setShowAnim={setShowAnim}
                        setModal={setMealPlannerModal}
                        hideCrossButton={true}
                        setShowPickMealAnimation={setShowPickMealAnimation}
                    />
                </div>

                <div className='tabs__section'>
                    <Tabs value={activeReport} onChange={handleChange}>
                        <Tab label="Planner" />
                        <Tab label="Cart" />
                    </Tabs>
                    <TabPanel component={'div'} value={activeReport} className='tab__panel' index={0} >
                        <div className='info'><img src='/icons/drag-n-drop.svg' />Drag n' drop meals to do changes on the meal plan.</div>

                        <DndProvider
                            backend={isMobile ? TouchBackend : HTML5Backend}
                            options={{ enableTouchEvents: true, enableMouseEvents: true, delay: 500 }}
                        >
                            {currentDays.map((day) => {
                                return (
                                    <PlannerCard title={day}
                                        accept={getAcceptedDays(day)}
                                        currentDays={currentDays}
                                        setCurrentDragDay={setCurrentDragDay} mealPlan={mealPlan}
                                        setMealPlannerModal={setMealPlannerModal}
                                    >
                                        {renderItemsForColumns(day)}
                                    </PlannerCard>
                                )
                            })}
                            {isMobile && <MyPreview />}
                        </DndProvider>

                        <Drawer anchor='bottom' overlayStyle={{ backgroundColor: "yellow" }} transitionDuration={500} open={moreOptionsModal} onClose={toggleDrawer(false)} className='move-modal'
                        >
                        {moreOptionsModal &&
                            <MoreOptionsModal>
                                <div className='div__holder'>
                                    <div className='option' onClick={()=>setMoveDayModal(true)}><img src='/icons/calendar-gray.svg'/>Move to another day</div>
                                    <div className='option' onClick={handleRemove}><img src='/icons/trash2.svg' />Remove from diet plan</div>
                                </div>
                           </MoreOptionsModal>
                        }
                        </Drawer>

                        <Drawer anchor='bottom' overlayStyle={{ backgroundColor: "yellow" }} transitionDuration={500} open={moveDayModal} onClose={toggleDrawer(false)} className='another-modal'
                        >
                        {moveDayModal &&
                            <MovedayModal>
                                <div className='choose-day'>
                                    <div className='meal'><p>{`${data.name}: Calories – ${data.calories}g, Fat – ${data.fat}g, Protein – ${data.protein}g, Carb – ${data.carbs}g.`}</p></div>
                                   {currentDays.map((day)=>{
                                    const calcCalorie = renderTotalCalories('calories', mealPlan, day);
                                    return(
                                    <div className={`days ${day===selectday ? 'matched-day' : '' }`} onClick={()=>setSelectday(day)}>
                                        <p>{day}: Calories {Math.round(calcCalorie)}/{followerProfile?.recommndedNutrients?.calories}</p>
                                        {day===selectday &&<img src='/icons/check.svg' />}
                                    </div>)})}
                                    <div className='btn-div'>
                                        <button className='cancel' onClick={()=>setMoveDayModal(false)}>Cancel</button>
                                        <button className='apply' onClick={()=>changeday(selectday)}>Apply</button>
                                    </div>
                                </div>
                           </MovedayModal>
                        }
                        </Drawer>

                    </TabPanel>
                    <TabPanel component={'div'} value={activeReport} className='tab__panel' index={1}>
                        <div className='info'><img src='/icons/manage-cart.svg' />Add or remove items from the cart</div>

                        <CartCardList setMealPlannerModal={setMealPlannerModal} setShowAnim={setShowAnim} providerId={providerId} setProviderId={setProviderId}/>

                    </TabPanel>
                </div>

                <div className='send__to__cart'>
                    <button disabled={isLoading} className={isLoading || clientcart?.length <= 0 ? 'disabled' : ''} onClick={loadClientCartFn}>Send To {clientName.length > 10 ? clientName.substring(0, 10) + "..."  : clientName }'s Cart</button>
                </div>
                {
                    cartModal && 
                    <MoveCartModal className={showAnim ? 'fadeInMiddle' : 'animate__animated animate__fadeOut animate__delay-0.5s'}>
                        <div>
                           <img src='/icons/trolley.png'/>
                        </div>
                        <div className='week'>
                           {clientName.length > 10 ?
                            <p data-tip={clientName} data-for="name" style={{cursor: 'pointer'}}>You’ve just made {`${clientName.substring(0, 10) + "..." }'s`} week!</p>
                             :
                             <p>You’ve just made {`${clientName}'s`} week!</p>
                                                      
                        }
                        </div>
                        <div className='added'>
                        {clientName.length>8 ?
                            <p data-tip={clientName} data-for="clientname" style={{cursor: 'pointer'}}>{clientName.substring(0, 8) + "..." } has been notified that you’ve <br/> added meals to their cart.</p>
                            :
                            <p>{clientName} has been notified that you’ve <br/> added meals to their cart.</p>
                        }
                        </div>
                        <div className='follower-btn'>
                            <button onClick={handleclick}>
                            Go to followers 
                            </button>
                        </div>

                    </MoveCartModal>
                }
                  <ReactTooltip id="name" place="top" effect="solid">
                 </ReactTooltip>
                 <ReactTooltip id="clientname" place="top" effect="solid">
                 </ReactTooltip>
            </MealPlannerStyle>

        </>
    )
}

