import { useEffect, useState } from 'react';
import classNames from "classnames";
// import { Copyright } from "./Copyright";
import { useParams, useLocation } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import { FormContainer } from 'components/auth/StyledLoginForm';
import Progress from 'components/Progress';
import NutritionistQuote from 'components/NutritionistQuote';
import RegisterForm from 'components/auth/RegisterForm';
import SelectAPlan from 'components/onboarding/SelectAPlan';
import NutrittLogo from 'layout/NutrittLogo';
import ErrorComponent from 'components/ErrorComponent';
import { useAppContext } from 'context/appContext';
import NutrittSteps from 'components/NutrittSteps';
import RegisteredDetitianCard from 'components/OnboardingPlans/RegisteredDetitianCard';
import styled from 'styled-components';
import CoachProfile from 'pages/shop/CoachProfile';
import MyCoach from './../shop/PublicUrlLandingPage/MyCoach';


import BackButton from 'components/BackButton';

const RegisterStyle = styled.div`
  @media (max-width: 991px) {
    padding: 23px 0 25px;
    background-color: #f6f5f8;

    .social-dietatian-wrapper .registered-title h4 {
      font-size: 18px;
      line-height: 24px;
    }

    .step-header {
      height: fit-content;
      margin-bottom: 24px;

      h2 {
        margin-bottom: 5px;
        font-size: 24px;
        line-height: 28px;
      }

      .description {
        padding: 0;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
`;

const ProgressWrapper = styled.div`
  &.planProgress {
    min-height: calc(100vh - 141px);
  }
`;

const steps = ['Plan', 'Get started', 'Personalize', 'Preview'];

export default function Register({setShowDietPlans, showDietPlans, }) {
  const { nutritionistId:nutritionistPublicLink } = useParams();
  const [mealPlanId, setMealPlanId] = useState(null);
  const { isMobile,isDesktop ,user , setNutritionistId, setNutritionistInfo} = useAppContext();
  const { pathname } = useLocation();

  const [
    getNutritionist,
    { isLoading: nutritionistLoading, state: nutritionist, error: nutritionistError },
  ] = useRequest(`nutritionist/${nutritionistPublicLink}`);

  useEffect(() => {
    getNutritionist()
  }, []);
  setNutritionistId(nutritionist?.id)
  setNutritionistInfo(nutritionist)
  // const selectPlan = (id) => {
  //   setMealPlanId(id);
  //   localStorage.setItem("selectedPlanId", id);
  // };
  const [register, { isLoading, error, setError }] = useRequest('auth/register', 'post');

  if (nutritionistLoading) return <ProgressWrapper className={classNames({planProgress: pathname.startsWith('/plan/') && !isMobile})}><Progress /></ProgressWrapper>;
  if (nutritionistError) return <ErrorComponent error={nutritionistError} />;
  if (!nutritionist) return null;

  // if (!mealPlanId) return <SelectAPlan nutritionist={nutritionist} selectPlan={setMealPlanId} setShowDietPlans={setShowDietPlans} showDietPlans={showDietPlans}/>;
  if (!mealPlanId) return <MyCoach  publicNutritionist={nutritionist} selectPlan={setMealPlanId} setShowDietPlans={setShowDietPlans} showDietPlans={showDietPlans}/>;
  return (
    <RegisterStyle>
      <FormContainer>
        {isMobile ? (
          <div className="dietitian-card-wrapper">
            <RegisteredDetitianCard
              dietitianPhoto={nutritionist?.img || '/images/avatar.png'}
              name={nutritionist?.name}
              title={nutritionist?.title}
              setMealPlanId={setMealPlanId}
              socialProfile={nutritionist?.socialProfile}
            />
          </div>
        ) : (
          <>
          <NutritionistQuote image={nutritionist?.img || '/images/avatar.png'}>
            “Get started to follow my plan. <br /> Get{' '}
            <span style={{ color: 'var(--nutritt-green)' }}>FREE</span> guidance to reach your
            nutritional goals!”
          </NutritionistQuote>
          <BackButton perviousLink={() => setMealPlanId(null)} />
          </>
        )}
        {isMobile && (
          <NutrittSteps
            title="Let's get started"
            description="Create an account and begin your health journey"
            activeStep={1}
            stepTitle="Create your account"
            perviousLink={() => {
              setMealPlanId(null);
              window.scrollTo(0, 0);
            }}
          />
        )}
        <RegisterForm
          register={register}
          isLoading={isLoading}
          error={error || nutritionistError}
          setError={setError}
          nutritionistId={nutritionist?.id}
          mealPlanId={typeof mealPlanId === 'number' ? mealPlanId : null}
        />
      </FormContainer>
    </RegisterStyle>
  );
}


