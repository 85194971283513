import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { BiPlus } from 'react-icons/bi';
import { ReactComponent as Edit } from 'assets/edit.svg';
import { ReactComponent as Cross } from 'assets/cross-fill.svg';
import { IngredientsModal } from './IngredientsModal';
import { Modal } from 'components/elements/modal/Modal';
import { useRequest } from 'hooks/useRequest';
import { useDebounce } from 'use-debounce';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useKitchenContext } from 'context/kitchenContext';


const IngredientsStyle = styled.div`
  .modal-position {
    position: fixed;
    inset: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(40, 50, 65, 0.6);
  }
  .modal_body .desc {
    color: ${({ theme }) => theme?.colors?.blackDefault} !important;
    font-family: ${({ theme }) => theme?.typography?.fontInter} !important;
  }
  .modal_actions {
    margin-top: 46px;
  }
  .cvpWNh {
    padding: 24px 24px 27px 24px;
  }
  .ingredients_desc {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
    max-width: 633px;
  }
  .ingredients_header {
    position: relative;
    margin-bottom: 12px;
    .add_ingredients_button {
      position: absolute;
      right: 0;
      bottom: 0;
      button {
        border-radius: 6px;
      }
      .plus_icon {
        font-size: 16px;
        display: block;
      }
    }
  }
  .ingredients_table {
    .one {
      width: 35.6%;
    }
    .two {
      width: 12.4%;
    }
    .three {
      width: 17.25%;
    }
    .four {
      width: 18.54%;
    }
    .five {
      width: 15.9%;
    }
    .ingredients_table_head {
      display: flex;
      .ingredients_table_th {
        padding: 14px 0 14px 16px;
        background-color: ${({ theme }) => theme?.colors?.grayLightest};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        font-weight: 600;
        line-height: 20px;
        color: ${({ theme }) => theme?.colors?.grayDarker};
      }
    }
    .ingredients_table_body{
      min-height: 87px;
    }
    .ingredients_table_row {
      display: flex;
      position: relative;
      &::before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: ${({ theme }) => theme?.colors?.grayLighter};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .ingredients_table_td {
        padding: 20px 16px;
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        color: ${({ theme }) => theme?.colors?.blackDefault};
        font-weight: 400;
        line-height: 20px;
        position: relative;
        .edit_ingredients {
          display: flex;
          align-items: center;
          gap: 8px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translatey(-50%);
          .icon {
            .i {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;

export const Ingredients = ({ingredientData,setIngredientData, setDeleteIndex,mealData,setMealData}) => {
  const [isAddActive, setIsAddActive] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [editedIngredient, setEditedIngredient] = useState(null);
  const [searchText,setSearchText]=useState('');
  const [debouncedSearchText]= useDebounce(searchText, 600);
  const prevSearchText = useRef('');
  const [getIngredientsApi, {isLoading}]=useRequest();
  const [allIngredients,setAllIngredients]=useState([])

  const [deleteName, setDeleteName] = useState('');
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [recentDataList, setRecentDataList] = useState(null);
  const { kitchenProvider } = useKitchenContext() || {};
  const [getRecentOptions] = useRequest();


  const ingredientTabs = [
    {
      id: 0,
      title: 'All',
      onClick: () => { handleTabSwitch('all')},
      render: () => {
        return <h2>All</h2>;
      },
    },
    {
      id: 1,
      title: 'Generic',
      onClick: () => { handleTabSwitch('generic')},
      render: () => {
        return <h2>Generic</h2>;
      },
    },
    {
      id: 2,
      title: 'Packaged',
      onClick: () => { handleTabSwitch('packaged')},
      render: () => {
        return <h2>Packaged</h2>;
      },
    },
    {
      id: 3,
      title: 'Recent',
      onClick: () => { handleTabSwitch('recent')},
      render: () => {
        return <h2>Recent</h2>;
      },
    },
  ];
  const [selectedTabId, setSelectedTabId] = useState(ingredientTabs[0].id);

  const handleDelete = () => {
    const updatedData = [...ingredientData];
    const ingredientIndexToDelete = updatedData.findIndex((ingredient) => ingredient.name === deleteName);
  
    if (ingredientIndexToDelete !== -1) {
      const ingredient=updatedData[ingredientIndexToDelete];
      const allergens = ingredient?.allergies;
      const updatedRestrictions = mealData.restrictions.filter(restriction => !allergens.includes(restriction));
      setMealData({...mealData,restrictions:updatedRestrictions});
      updatedData[ingredientIndexToDelete].isDeleted = true;
      setIngredientData(updatedData);
    }
  
    setShowModel(false);
    setDeleteName(''); 
  };
  
  const getingredientsList = useCallback(async () => {
    try {
      if (debouncedSearchText && !debouncedSearchText.includes('%')) {
      const trimmedEncodedSearchText = debouncedSearchText.trim();
      const path = `meal/ingredients?foodName=${trimmedEncodedSearchText}`;
      const result = await getIngredientsApi({ path });
      setAllIngredients(result?.data?.hints);
     } else {
        setAllIngredients([]);
     }
    } catch (error) {
      console.log(error);
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText !== prevSearchText.current && (selectedTabId === 0 || selectedTabId === 1 || selectedTabId === 2)) {
      getingredientsList()
    }
    prevSearchText.current = debouncedSearchText;
    if (selectedTabId === 3) {
      fetchRecentOptionFn()
    }
  },[debouncedSearchText,selectedTabId]);

  const handleTabSwitch = useCallback((tab) => {
    setSelectedItemIndex(null);    
    switch (tab) {
        case "all":
            setSelectedTabId(0);
            break;
        case "generic":
            setSelectedTabId(1);
            break;
        case "packaged":
            setSelectedTabId(2);
            break;
        case "recent":
            setSelectedTabId(3);
            break;
        default:
            break;
    }
}, [ingredientTabs]);
  
  const handleEditClick = (ingredient) => {
    setSelectedTabId(0);
    setEditedIngredient(ingredient);
    setIsAddActive(true);
  }

  const fetchRecentOptionFn = useCallback(async () => {
    try {
      const path=`meal/recent-ingredients/${kitchenProvider?.id} ${debouncedSearchText? `?searchText=${debouncedSearchText}`:''}`;
      const result = await getRecentOptions({path,method:'get'});
      const res = result?.data?.ingredientsList;
      setRecentDataList(res);
    } catch (err) {
      console.log(err);
    }
  }, [debouncedSearchText,kitchenProvider?.id]);



  return (
    <>
      <IngredientsStyle theme={theme} className="add_item_form ingredients">
        {showModel && (
          <div className="modal-position">
            <Modal
              size="large"
              type=""
              description={'Are you sure to delete the ingredient? You can’t undo this action.'}
              onOk={handleDelete}
              isOpen={showModel}
              onClose={() => {
                setShowModel(!showModel), setDeleteIndex(-1);
              }}
              onCancel={() => setShowModel(!showModel)}
              title={'Are you sure?'}
              primaryText={'Delete'}
              secondaryText={'Cancel'}
              isBackdropEnabled={true}
              buttonType={'danger'}
            />
          </div>
        )}
        <div className="ingredients_header">
          <p className="ingredients_desc">
            You're welcome to add ingredients below, and there's no need to worry about the
            nutrition analysis – we'll take care of that for you!
          </p>
          <div className="add_ingredients_button">
            <Button
              title="Add ingredients"
              type={'secondary'}
              size="small"
              onClick={() => {setIsAddActive(true), setEditedIngredient(null)}}
              iconLeft={<BiPlus className="plus_icon" />}
            />
          </div>
        </div>
        <div className="ingredients_table">
          <div className="ingredients_table_head">
            <div className="ingredients_table_th one">Description</div>
            <div className="ingredients_table_th two">Amount</div>
            <div className="ingredients_table_th three">Unit</div>
            <div className="ingredients_table_th four">Calories</div>
            <div className="ingredients_table_th five">Weight</div>
          </div>
          <div className="ingredients_table_body">
            {ingredientData?.filter((ingredient) => !ingredient.isDeleted)
            .map((ingredient, index) => (
              <div className="ingredients_table_row">
                <div className="ingredients_table_td one">{ingredient?.name}</div>
                <div className="ingredients_table_td two">{ingredient?.amount}</div>
                <div className="ingredients_table_td three">{ingredient?.unit?.split(' - ')[0]}</div>
                <div className="ingredients_table_td four">{!isNaN(ingredient?.macros?.totalNutrients?.ENERC_KCAL?.quantity) ? Math.round(ingredient?.macros?.totalNutrients?.ENERC_KCAL?.quantity) : 0}</div>
                <div className="ingredients_table_td five">
                {parseFloat(ingredient?.weight).toFixed(1)}
                  <div className="edit_ingredients">
                    <div className="icon edit" onClick={()=>handleEditClick(ingredient)}>
                      <Edit className="i" />
                    </div>
                    <div
                      className="icon delete"
                      onClick={() => {
                        setShowModel(true), setDeleteName(ingredient.name);
                      }}
                    >
                      <Cross className="i" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </IngredientsStyle>
      {isAddActive && (
        <IngredientsModal 
        isLoading={isLoading}
        setIsAddActive={setIsAddActive} 
        setIngredientData={setIngredientData} 
        editedIngredient={editedIngredient} 
        ingredientData={ingredientData} 
        searchText={searchText}
        setSearchText={setSearchText}
        allIngredients={allIngredients}
        setAllIngredients={setAllIngredients}
        ingredientTabs={ingredientTabs}
        selectedTabId={selectedTabId}
        setSelectedTabId={setSelectedTabId}
        selectedItemIndex={selectedItemIndex}
        setSelectedItemIndex={setSelectedItemIndex}
        recentDataList={recentDataList}
        />
      )}
    </>
  );
};
