import { memo, useCallback, useMemo, useState } from 'react';
import Tooltip from 'rc-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import RegularInput from 'components/Input/RegularInput';
import { Button } from 'components/elements/button/Button';
import Toggle from 'components/elements/toggleModule/Toggle';
import Pagination from 'components/elements/pagination/Pagination';
import { useHistory } from 'react-router-dom';
import { ItemsTableWrapper, DeleteModalWrapper } from './style';
import Checkbox from '@mui/material/Checkbox';
import { useRequest } from 'hooks/useRequest';
import { Modal } from 'components/elements/modal/Modal';
import { useKitchenContext } from 'context/kitchenContext';
import DropDown from 'components/elements/drop-down/Dropdown';
import { useAppContext } from 'context/appContext';

function ItemsTable({
  children,
  items,
  isCheckAll,
  setIsCheckAll,
  updateSelectedAll,
  totalPages,
  totalMealCount,
  setSearchInput,
  activePageNumber,
  setActivePageNumber,
  itemsPerPage,
  getItemList,
  setSortBy,
  setIsSearch,
  searchInput
}) {
  const { setActiveTab, imitateProviderCredentials, kitchenHasSubscription, setKitchenHasSubscription ,kitchenProvider} = useKitchenContext();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isInactiveModal,setIsInactiveModal]=useState(false);
  const history = useHistory();
  const providerId = imitateProviderCredentials?.providerId;
  const businessProfileId = useMemo(()=>{
    return kitchenProvider.BusinessProfile[0].id;
  },[kitchenProvider]);
  const [deleteMealsApi,{isLoading:isDeleting}] = useRequest(`meal/delete?providerId=${providerId}`,'delete');
const [updateMealApi]=useRequest(`meal/update?providerId=${providerId}&businessProfileId=${businessProfileId}`,'patch')
const [statusText , setStatusText]=useState();
  const { provider, isKitchenHasSubscription } = useAppContext();
  const isSelectedItem = useMemo(() => {
    return !items.some(item => item.selected);
}, [items]);

const defaultOptions = [
  { value: 'asc', label: 'Sort Ascending (A-Z)', imgSrc: '/icons/arrow-up-black.svg' },
  { value: 'desc', label: 'Sort Descending (Z-A)', imgSrc: '/icons/arrow-down-black.svg' }
];
const icon = "/icons/Right-Icon.svg";

  const countSelectedItems = useMemo(() => {
    let allSelected = [];
    allSelected = items.map((item) => item.selected);
    const countSelected = allSelected.filter((item) => item === true).length;
    return countSelected;
  }, [items]);

  const startItem = (activePageNumber - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalMealCount);

  const onAddItemClick = () => {
    history.push('/kitchen/menu/addItem')
    setActiveTab('items');
  }

  const handlePageChange=(e,page)=>{
    setActivePageNumber(page)
    setIsSearch(true);
  }
  const handleSearchInputChange=(e)=>{
    setSearchInput(e.target.value);
    setActivePageNumber(1);
    setIsSearch(true);
  }

  const selectionSummary = useMemo(() => {
    const selectedActiveMealIds = items
      .filter((item) => item.selected && item.status === "active")
      .map((item) => item.id);
  
    const selectedInactiveMealIds = items
      .filter((item) => item.selected && item.status !== "active")
      .map((item) => item.id);
  
    const allSelected = selectedActiveMealIds.length + selectedInactiveMealIds.length === items.length;
    const showButton = !(selectedActiveMealIds.length > 0 && selectedInactiveMealIds.length > 0);
    const buttonLabel = selectedActiveMealIds.length > 0 ? "Inactive" : "Active";
    setStatusText(selectedActiveMealIds.length > 0 ? "inactive" : "active")
  
    return {
      totalSelectedId: [...selectedActiveMealIds, ...selectedInactiveMealIds],
      selectedActiveMealIds,
      showButton,
      isAllActive: selectedActiveMealIds.length === items.length,
      isAllInactive: selectedInactiveMealIds.length === items.length,
      buttonLabel,
    };
  }, [items]);
  

  const handleDeleteMultiple = useCallback( async() => {
    try {
      await deleteMealsApi({body:{mealIds:selectionSummary?.totalSelectedId}})
      .then((res) => {
        getItemList();
        setIsDeleteModal(false);
        setIsSearch(false);
        setSearchInput('');
        setKitchenHasSubscription(null);
      })
    } catch (error) {
      console.log(error)
    }
  }, [items])

  const handleStatusUpdateMultiple = useCallback( async() => {
    try {
      const isActive = selectionSummary?.buttonLabel === 'Active' ? 'true' : 'false';
      const body={mealIds: selectionSummary?.totalSelectedId,isActive:isActive}
      await updateMealApi({body})
      .then((res) => {
        setSearchInput('');
        getItemList(activePageNumber);
        setIsInactiveModal(false);
        setKitchenHasSubscription(null);
      })
    } catch (error) {
      console.log(error)
    }
  }, [items,activePageNumber]);

  const fetchHasSubscriptionData = async () => {
    try {
        const hasSubscription = await isKitchenHasSubscription({ kitchenId:providerId, mealId: selectionSummary?.totalSelectedId });
        setKitchenHasSubscription(hasSubscription);
      
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const handleInactiveClick = (status) => {
    setKitchenHasSubscription(null);
    if (status === 'inactive') {
      fetchHasSubscriptionData()
    }
    setIsInactiveModal(true)
  }

  const inactiveModalDec = useMemo(() => {
    let text = `Are you sure to mark ${selectionSummary?.selectedActiveMealIds?.length > 1 ? 'these items' : 'this item'} as ${selectionSummary?.buttonLabel}? `
    if (selectionSummary.buttonLabel === 'Inactive') {
      text += "Inactive item will be removed from the menu. "
    }
    if (kitchenHasSubscription?.subscriptionCount > 0) {
      text += `Please note that the system detects ${kitchenHasSubscription.subscriptionCount} active subscriptions for this kitchen, and any changes made may affect their recurring orders.`
    }
    return text;

  }, [selectionSummary.totalSelectedId, kitchenHasSubscription?.subscriptionCount])
  const deleteMealModalDesc = useMemo(() => {
    let message = `Are you sure to delete ${countSelectedItems} items? You can’t undo this action.`
    if (kitchenHasSubscription?.subscriptionCount > 0) {
      message += `Please note that the system detects ${kitchenHasSubscription.subscriptionCount} active subscriptions for this kitchen, and any changes made may affect their recurring orders.`
    }
    return message;
  }, [kitchenHasSubscription?.subscriptionCount, countSelectedItems]);

  const handleDeleteClick = async () => {
    await fetchHasSubscriptionData();
    setIsDeleteModal(true);
  }
  return (
    <>
    <ItemsTableWrapper>
      <div className="table-title">
        <div className="items-length">
          {!!items.length && (
            <>
              {isSelectedItem ? totalMealCount : countSelectedItems}{' '}
              {countSelectedItems > 1 || (isSelectedItem && items.length > 1) ? 'items' : 'item'}{' '}
              {!!countSelectedItems && 'selected'}
            </>
          )}
          {items?.length === 0 && <> 0 items</>}
          <AnimatePresence>
            {!!countSelectedItems && (
              <motion.div
                className="box"
                initial={{ opacity: 0, x: -28 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 0.5,
                  delay: 0.2,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
                exit={{ opacity: 0, x: -28 }}
              >
                <div className="delete-inactive-wrapper">
                  <div className="divider" />
                  <Tooltip
                    overlayClassName="items-tooltip-style"
                    name="Delete items"
                    overlay={<span>Delete items</span>}
                    transitionName= "rc-tooltip-zoom"
                    placement={"bottom"}
                    onClick={handleDeleteClick}
                >
                  <div className="delete-inactive">
                    <img src="/icons/delete.svg" alt="Delete Icon" /> Delete
                  </div>
                </Tooltip>
                {
                  selectionSummary?.showButton && (
                    <Tooltip
                      overlayClassName="items-tooltip-style"
                      name="Mark as inactive"
                      overlay={<span>Mark as {statusText}</span>}
                      transitionName="rc-tooltip-zoom"
                      placement={"bottom"}
                      onClick={()=>handleInactiveClick(statusText)}
                    >
                      <div className="delete-inactive">
                        <img src="/icons/visibility.svg" alt="Visibility Icon" /> {selectionSummary?.buttonLabel}
                      </div>
                    </Tooltip>
                  )
                }
                    
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="table-search-newItem">
          <RegularInput
            className="search-input"
            width="270px"
            prefix={<img src="/icons/search-input2.svg" alt=" Search Icon" />}
            placeholder="Search for orders"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <Button title="New item" type="dark" iconLeft={<img src="/icons/add2.svg" alt="Add"/>} onClick={onAddItemClick} />
        </div>
      </div>
      <div className="table-header">
        <div className="header-photo">
          <Checkbox
            className="header-checkbox"
            icon={<Toggle type="checkbox" size={'18px'} />}
            checked={items.length && isCheckAll}
            indeterminate={countSelectedItems > 0 && countSelectedItems < items.length}
            onChange={() => {
              setIsCheckAll(!isCheckAll);
              updateSelectedAll(!isCheckAll);
            }}
            disableRipple={true}
          />
          Photo
        </div>
        <div className="header-name">Name</div>
        <div className="header-price">Price</div>
        <div className="header-category">Category <DropDown  onChange={(value) => setSortBy(value)} icon={icon} option={defaultOptions}/></div>
        <div className="header-status">Status</div>
        <div className="header-update">Last update</div>
      </div>
      {children}
      <Pagination startItem={startItem} endItem={endItem} totalPageCount={totalPages} totalCount={totalMealCount} activePageNumber={activePageNumber} handlePageChange={handlePageChange}/>
    </ItemsTableWrapper>
    {isDeleteModal && 
      <DeleteModalWrapper>
        <Modal
          type=""
          title="Are you sure?"
          description={deleteMealModalDesc}
          secondaryText="Cancel"
          onCancel={() => setIsDeleteModal(false)}
          onClose={() => setIsDeleteModal(false)}
          primaryText="Delete"
          onOk={handleDeleteMultiple}
          buttonType="danger"
        />
      </DeleteModalWrapper>
    }
     {isInactiveModal && (
        <DeleteModalWrapper>
          <Modal
            type=''
            title="Are you sure?"
            description={inactiveModalDec}
            secondaryText="Cancel"
            onCancel={() => setIsInactiveModal(false)}
            onClose={() => setIsInactiveModal(false)}
            primaryText="Ok"
            onOk={handleStatusUpdateMultiple}
            buttonType="dark"
          />
        </DeleteModalWrapper>
      )}
    </>
    
  );
}

export default memo(ItemsTable);
