import styled from 'styled-components';
import MealPlanCard from './MealPlanCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-magic-slider-dots/dist/magic-dots.css';

const MealPlanCardsStyle = styled.div`
  margin: 0;
`;

export default function MealPlanCards({ sortedDietTemplates, selectPlan }) {
  return (
    <MealPlanCardsStyle>
      {sortedDietTemplates?.map((plan) => (
        <MealPlanCard key={plan.id} selectPlan={selectPlan} plan={plan} />
      ))}
    </MealPlanCardsStyle>
  );
}
