import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const NutritionistChattingStyle = styled.div`
  @media (max-width: 991px) {
    .nutritionist-image {
      position: fixed;
      bottom: 50px;
      left: 24px;
      z-index: 3;
      width: 58px;
      height: 58px;
      background-color: #fff;
      border: 3px solid #6942ba;
      border-radius: 75px;
      cursor: pointer;
      transform: translate(0, 0px);

      &.animated {
        animation: bounce 3s infinite;
      }
    }

    .chatting-content {
      position: fixed;
      bottom: 128px;
      left: auto;
      z-index: 3;
      width: 90%;
      height: 150px;
      padding: 0 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgb(105 66 186 / 30%);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          width: 33px;
          height: 28px;
        }
      }

      .title {
        color: #52c41a;
        font-size: 16px;
        font-family: 'Roboto';
        line-height: 19px;
        text-transform: capitalize;
      }

      p {
        margin: 15px 12px 0 0;
        color: #51635d;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        flex-wrap: wrap;

        img:first-child {
          margin-left: 4px;
        }
      }

      span {
        position: absolute;
        bottom: -15px;
        left: 35px;
        z-index: 3;
        width: 0;
        height: 0;
        border-top: 15px solid #fff;
        border-right: 20px solid transparent;
        box-shadow: -20px 10px 23px rgb(105 66 186 / 30%);
      }
    }

    .icon-close-wrapper {
      position: absolute;
      right: -12px;
      top: -12px;
      width: 24px;
      height: 24px;
      background-color: #52c41a;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  @keyframes bounce {
    0%,
    8%,
    18% {
      bottom: 50px;
      transform: translate(0, 0);
    }

    3%,
    13% {
      transform: translate(0, -25px);
    }

    100% {
      transform: translate(0, 0px);
    }
  }
`;

const fruits = ['grapes', 'avocado', 'lettuce', 'meat', 'nut'];

export default function NutritionistChatting({ nutritionistImage, username }) {
  const [isAnimated, setIsAnimated] = useState(true);
  const [displayChattingBox, setDisplayChattingBox] = useState(false);

  return (
    <NutritionistChattingStyle>
      <img
        className={classNames('nutritionist-image', { animated: isAnimated })}
        src={nutritionistImage}
        alt="Nutritionist Image"
        onClick={() => {
          setIsAnimated(false);
          setDisplayChattingBox(true);
        }}
      />
      {displayChattingBox && (
        <div className="chatting-content">
          <div className="icon-close-wrapper" onClick={() => setDisplayChattingBox(false)}>
            <img src="icons/close-circle.svg" alt="Chatting Close Icon" />
          </div>
          <div className="title-wrapper">
            <div className="title">Hey {username},</div>
            <img src="icons/note.svg" alt="Note Icon" />
          </div>
          <p>Here's the nutrition you should aim for to reach your health goals.</p>
          <p>
            With Nutritt, it's effortless to plan and track your diet!
            {/* <div className="icons-wrapper"> */}
            {fruits.map((icon, index) => (
              <img key={index} src={`icons/${icon}.svg`} alt={`${icon} Icon`} />
            ))}
            {/* </div> */}
          </p>
        </div>
      )}
    </NutritionistChattingStyle>
  );
}
