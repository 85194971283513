import { GilroyFontWrapper } from 'shared/theme/style';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right.svg';
import { ReactComponent as AddIcon } from 'assets/shop/Tracker-AddIcon.svg';
import { useShopContext } from 'context/shopContext';

const CardStyle = styled.div`
  .box {
    border-radius: 7px;
    background: var(--White, #fff);
  }
  .calories {
    color: var(--Black, #0b1d17);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .bottom-div {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f0f2f4;
  }
  .chevron {
    width: 23px;
  }
  .row {
    display: flex;
    align-items: center;
  }
  .main-icon {
    border-radius: 24px;
    background: #f8f8f8;
    display: inline-flex;
    padding: 13px;
    justify-content: center;
    align-items: center;
    svg{
      width: 22px;
      height: 22px;
    }
  }
  .add-icon {
    border-radius: 24px;
    background: #f8f8f8;
    display: inline-flex;
    padding: 13px;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #00092d;
    margin-left: 10px;
  }
  .row2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
  }
  svg{
    display: block;
    height: 32px;
    width: 32px;
  }
`;

export default function TrackerCard({ icon, title, onPressAdd, totalCalories, consumedCalories, onClick, consumedMealCategory }) {
  const { updateConsumedMealType } = useShopContext();
  return (
    <GilroyFontWrapper>
      <CardStyle>
        <div className="box">
          <div className="row2">
            <div className="row">
              <div className="main-icon">{icon}</div>
              <text className="title">{title}</text>
            </div>
            <div onClick={() => {
              onPressAdd && onPressAdd();
              consumedMealCategory && updateConsumedMealType(consumedMealCategory);
            }}>
              <AddIcon />
            </div>
          </div>
          {(totalCalories > 0 && consumedCalories > 0) &&
          <div className="bottom-div">
            <text className="calories">{consumedCalories + ' / ' + totalCalories} calories</text>
            <div onClick={() => {
              onClick && onClick();
              consumedMealCategory && updateConsumedMealType(consumedMealCategory);
            }}>
              <ChevronRight className="chevron" />
            </div>
          </div>
          }
        </div>
      </CardStyle>
    </GilroyFontWrapper>
  );
}
