import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DesktopHamburger } from 'assets/desktop-hamburger.svg';
import { ReactComponent as NavNotification } from 'assets/nav-notification.svg';
import { ReactComponent as ArrowLeft } from 'assets/arrowLeft.svg';
import { ReactComponent as NavShare } from 'assets/nav-share.svg';
import { theme } from 'shared/theme/theme';
import { useAppContext } from 'context/appContext';

const KitchenHeaderStyle = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  margin-top: ${({ isWebView, platform }) => (isWebView && platform ? '60px' : '0')};
  .menu_holder, .icons_holder{
    width: 25%;
  }
  .icon{
    display: block;
  }
  .pageName {
    width: 50%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
        color: ${({theme}) => theme?.colors?.deepForestGreen};
        font-size: ${({theme}) => theme?.typography?.fontMedium};
        text-align: center;
        font-weight: 600;
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        text-transform: capitalize;
    }
  }
  .icons_holder{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
`;

const KitchenHeader = ({pageName, showShareIcon, setIsSidebarActive, showNotification, onNavClick, onShareClick, hamburgerIcon, onBackClick, isWebView}) => {
  const { isPlatformIOS} = useAppContext();
  return (
    <KitchenHeaderStyle theme={theme} isWebView={isWebView} platform={isPlatformIOS() ? true : false}>
      <div className="menu_holder">
      {hamburgerIcon &&
        <DesktopHamburger className="hamburger icon" onClick={() => setIsSidebarActive(true)} />
      }
      </div>
      <div className="pageName">
        <div className="title">{pageName}</div>
      </div>
      <div className="icons_holder">
        {showShareIcon && <NavShare className='share icon'/>}
        {showNotification && <NavNotification className='notification icon'/>}
      </div>
    </KitchenHeaderStyle>
  );
};

export default KitchenHeader;
