import { memo, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import SaveAddress from '../SaveAddress';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import RemoveAddressModal from '../RemoveAddressModal';
import { motion, AnimatePresence } from 'framer-motion';
import MobileHeader from 'components/elements/MobileHeader';
import { AddressBlock } from 'components/elements/addressBlock/AddressBlock';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
  getDetails,
} from 'use-places-autocomplete';
import { AddressesListWrapper, UsePlacesAutocompleteWrapper } from './style';
import { useShopContext } from 'context/shopContext';
import produce from 'immer';
import { useHistory, useLocation } from 'react-router';
import { filterKitchens } from 'utils';
import getAddress from 'utils/getAddress';

function AddressesList({
  open = true,
  setOpen,
  providerDeliverableAddress,
  setSelectedAddress,
  setKitchenNotDelivers,
  userAddressZip, 
  setUserAddressZip,
  processedIndices, 
  setProcessedIndices,
  setShowPopup,
  isDeliveryAvailable,
  selectHeaderAddress,
  checkIfDeliveryAvailable
}) {
  const defaultForm = {
    address: { value: '' },
    apartment: '',
    type: 'home',
    prevType: '',
    place_id: '',
    customType: null,
    instruction: '',
  };
  const [addressInfo, setAddressInfo] = useState({});
  const [addressDetails, setAddressDetails] = useState({});
  const [location, setLocation] = useState({ lat: '', lng: '' });
  const [isSaveAddress, setIsSaveAddress] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isRemoveAddressModal, setIsRemoveAddressModal] = useState(false);
  const [addressId, setAddressId] = useState();
  const [form, setForm] = useState(defaultForm);
  const { user, headerAddress, userDeliveryAddress, setUserDeliveryAddress, setSelectedValue, isMobile, setChangeAddress, profile, showArea , setShowArea } = useAppContext();
  const [errorMessage, setErrorMessage] = useState(false);
  const inputRef = useRef();
  const [deleteAddress, { isLoading, error }] = useRequest();
  const favoriteKitchenData = profile?.favoriteKitchenData;
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { updateSettings, showBummer, setShowBummer, setWaitingList, kitchensList} = useShopContext();
  const Activelocation = useLocation();
  const history = useHistory();
  const handleDelete = async () => {
    const path = 'user-delivery-address/';
    await deleteAddress({
      path,
      method: 'delete',
      body: { deliveryAddressId: addressId },
    })
      .then((result) => {
        const filter = userDeliveryAddress.filter((item) => item.id !== addressId);
        setUserDeliveryAddress(filter);
        setOpen(false);
        setIsRemoveAddressModal(false);
        setIsSaveAddress(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateLocation = (address) => {
    getGeocode({ address }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setLocation({ lat, lng });
    });
  };
  const generateZip = (address, add) => {
    const addressId = add?.id;
    getGeocode({ address })
      .then((results) => {
        const zipCode = getZipCode(results[0], false);
        setUserAddressZip((prev) => [...prev, { addressId, zipCode, isSeen: add?.isSeen || false }]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    if (form.address?.value) {
      setIsSaveAddress(true);
    }
  }, [form]);

  useEffect(() => {
    if (location.lat) {
      setForm({ ...form, address: { value: addressDetails } });
    }
  }, [location]);

  useEffect(() => {
    if (!isSaveAddress) {
      setForm(defaultForm);
      isEditAddress && setValue('');
      setIsEditAddress(false);
      setLocation({ lat: '', lng: '' });
    }
  }, [isSaveAddress]);

  useEffect(() => {
    window.scrollTo(0, -10);
    return () => setChangeAddress(false);
  }, [])

  const handleSelect = (selectedAdd) => async () => {
    const address = selectedAdd.description;
    const results = await getGeocode({ address })
    const zipCode = getZipCode(results[0], false);
    if(zipCode){
      if(Activelocation.pathname === '/self-onboarding/address'){
        const userInfo = {
          zipcode: results[0].address_components.find((component) =>
              component.types.includes('postal_code'),
          )?.short_name,
          userAddressLatLong: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
          },
        };
        const kitchenFilterResult = filterKitchens(kitchensList, userInfo);
        if (kitchenFilterResult && !kitchenFilterResult.isDeliveryAvailable && !(profile?.favoriteKitchenData && Object.keys(profile?.favoriteKitchenData)?.length)) {
          setWaitingList(true);
        }
        else {
          generateLocation(address);
          setAddressDetails(selectedAdd);
          setValue(address, false);
        }
      } else {
        generateLocation(address);
        setAddressDetails(selectedAdd);
        setValue(address, false);
      }
      
    } else {
      setErrorMessage(true);
      clearSuggestions();
    }
  };
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <img src="/icons/location-black.svg" alt="Location Icon" />
          <p>
            {main_text} {secondary_text}
          </p>
        </li>
      );
    });

  const findAddress = (address) => {
    return userAddressZip?.find(item => item?.addressId === address?.id)
  }  

  const handleAddressClick = async (address, isNotDeliverable) => {
    let placeId = address?.address?.address?.value?.place_id
    let label = address?.name || address?.type;
    let addressId = address?.id;
    const clickedAddress = await getAddress(placeId, label);
    const result = filterKitchens(kitchensList, clickedAddress);
    if(selectHeaderAddress && Activelocation.pathname === '/self-onboarding/address'){
      setSelectedValue(addressId);
      await updateSettings(
        produce((draft) => {
          draft.userInfo.userAddressLatLong = clickedAddress?.userAddressLatLong;
          draft.userInfo.address = clickedAddress?.address;
          draft.userInfo.address2 = clickedAddress?.address2;
          draft.userInfo.zipcode = clickedAddress?.zipcode;
          draft.userInfo.city = clickedAddress?.city;
          draft.userInfo.state = clickedAddress?.state;
        }),
      )
      if(result.isDeliveryAvailable){
        setOpen(false);
        history.push('/shop/kitchen-home')
      } else {
        if(!(profile?.favoriteKitchenData && Object.keys(profile?.favoriteKitchenData)?.length)){
          setWaitingList(true);
        }else{
          setShowBummer(true);
        }
      }
    }
    if (selectHeaderAddress && updateSettings ) {
      await updateSettings(
        produce((draft) => {
          draft.userInfo.userAddressLatLong = clickedAddress?.userAddressLatLong;
          draft.userInfo.address = clickedAddress?.address;
          draft.userInfo.address2 = clickedAddress?.address2;
          draft.userInfo.zipcode = clickedAddress?.zipcode;
          draft.userInfo.city = clickedAddress?.city;
          draft.userInfo.state = clickedAddress?.state;
        }),
      )
      setSelectedValue(addressId);
      setOpen(false);
      return
    }
    
    if (setSelectedAddress && setOpen) {
      let changeIsSeen = findAddress(address);
      changeIsSeen.isSeen = true;

      setSelectedAddress(clickedAddress);
      const isDeliverable = checkIfDeliveryAvailable(clickedAddress);
      if(isNotDeliverable && !isDeliverable){
        setKitchenNotDelivers(true);
      }else{
        setKitchenNotDelivers(false);
        setOpen(false);
        setUserAddressZip((prev) => ([
          ...prev?.filter(item => item?.addressId !== changeIsSeen?.addressId),
          changeIsSeen
        ]))
      }
    }
  };

  useEffect(() => {
    if(!open && inputRef?.current){
      inputRef?.current.blur();
    }
  }, [open, inputRef])
  
  useEffect(() => {
    if (userDeliveryAddress?.length < 1 && isMobile) {
      history.push("/self-onboarding/address");
    }
  },[userDeliveryAddress])  

  return (
    <AddressesListWrapper>
      {isMobile ? (
        <MobileHeader
          className={classNames('address-header', { addressHeaderValue: value && data.length })}
          text="Addresses"
          onClickClose={(e) => {
            inputRef.current.blur();
            e.stopPropagation();
            setOpen(false)
          }}
          onClickLeft={() => setValue('')}
        />
      ) : (
        <div className='address-desktop-header'>
          {!user && Object?.keys(headerAddress)?.length <= 0 ? '' : 
          <div className='address-close-wrapper' onClick={() => {
            if(!user && Object?.keys(headerAddress)?.length <= 0){
              return;
            }
            setOpen(false);
          }}>
            <img src='/icons/close-black.svg' alt='close Icon' />
          </div>
          }
          <div className='address-header-title'>Enter your address</div>
        </div>
      )}
      <UsePlacesAutocompleteWrapper>
        <div className={`input-container ${errorMessage ? "error" : ''}`}>
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => {setValue(e.target.value), setErrorMessage(false)}}
            placeholder="Search for an address"
            className="place-autocomplete-input"
          />
          {value && (
            <img
              src="/icons/close-black.svg"
              alt="Close icon"
              className="clear-input"
              onClick={() => {setValue(''), setErrorMessage(false)}}
            />
          )}
        </div>
        {errorMessage && <text className='error-message'>Please enter a valid address</text>}
        {status === 'OK' && (
          <motion.ul
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              delay: 0,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            style={{ paddingBottom: '20px' }}
          >
            {renderSuggestions()}
          </motion.ul>
        )}
      </UsePlacesAutocompleteWrapper>
      {userDeliveryAddress?.length > 0 && !value && data.length < 1 && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            delay: 0,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="saved-addresses-wrapper">
            <div className="saved-addresses-header">Saved addresses</div>
            <div className="addresses-info-wrapper">
              {userDeliveryAddress?.map((address) => {
                const addressDescription = address?.address?.address?.value?.description;
                if(setProcessedIndices && setUserAddressZip){
                  if (!processedIndices?.has(addressDescription)) {
                    generateZip(addressDescription, address);
                    setProcessedIndices((prevProcessedIndices) =>
                      new Set(prevProcessedIndices).add(addressDescription),
                    );
                  }
                }
                let isNotDeliverable = false;
                  const addressNotIncludedInZip = !providerDeliverableAddress?.includes(
                      userAddressZip?.find((item) => item?.addressId == address?.id)?.zipCode
                    )
                  if (
                    addressNotIncludedInZip && userAddressZip?.length
                  ) {
                    isNotDeliverable = true;
                  }
                  let isAddressSeen = findAddress(address)?.isSeen;
                  let showNoDelivery = (providerDeliverableAddress?.length && userAddressZip?.length && isAddressSeen && addressNotIncludedInZip) ? isNotDeliverable : false
                return (
                  <AddressBlock
                    type={address?.type}
                    title={address?.name || address?.type}
                    address={address?.address?.address?.value?.description}
                    selected={headerAddress?.id === address?.id}
                    onEditClick={() => {
                      const {
                        apartment,
                        instruction,
                        value: addressValue,
                      } = address?.address?.address;
                      generateLocation(addressValue?.description);
                      setForm({
                        apartment,
                        instruction,
                        place_id: addressValue?.place_id,
                        prevType: address?.type,
                        type: address?.type,
                        customType: address?.name,
                      });
                      setIsEditAddress(true);
                      setAddressId(address?.id);
                      setAddressInfo(address);
                      setAddressDetails(addressValue);
                    }}
                    isNotDeliverable={showNoDelivery}
                    onClick={() => showNoDelivery ? '' : handleAddressClick(address, isNotDeliverable)}
                  />
                );
              })}
            </div>
          </div>
        </motion.div>
      )}
      {!isMobile && userDeliveryAddress?.length < 1 && 
        <div className='map-img-wrapper'>
          <img src='/images/map.svg' alt='Map Image' />
        </div>
      }
      <AnimatePresence>
        {isSaveAddress && (
          <motion.div
            className="save-address-box"
            initial={isMobile && { opacity: 1, left: '200vw' }}
            animate={isMobile && { left: 0 }}
            exit={isMobile && { left: '200vw' }}
            transition={{
              duration: 0.3,
              delay: 0,
            }}
          >
            <SaveAddress
              setOpen={setOpen}
              setIsSaveAddress={setIsSaveAddress}
              location={location}
              form={form}
              setForm={setForm}
              isEditAddress={isEditAddress}
              setIsRemoveAddressModal={setIsRemoveAddressModal}
              addressId={addressId}
              addressInfo={addressInfo}
              setSelectedAddress={setSelectedAddress}
              setKitchenNotDelivers={setKitchenNotDelivers}
              providerDeliverableAddress={providerDeliverableAddress}
              setShowPopup={setShowPopup}
              isDeliveryAvailable={isDeliveryAvailable}
              setUserAddressZip={setUserAddressZip}
              showBummer={showBummer}
              setShowBummer={setShowBummer}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isRemoveAddressModal && (
          <RemoveAddressModal
            title={"Are you sure you’d like to remove address?"}
            setIsRemoveAddressModal={setIsRemoveAddressModal}
            handleDelete={handleDelete}
          />
        )}
      </AnimatePresence>
    </AddressesListWrapper>
  );
}

export default memo(AddressesList);
