import { useState } from 'react';
import { motion } from 'framer-motion';
import { Button } from 'components/elements/button/Button';
import { SortSheetWrapper } from './style';

const SortOptions = [
  {
    id: 1,
    img: <img src="/icons/time.svg" alt="Time Icon" />,
    info: 'Order time',
    sortKey: 'orderedAt',
  },
  {
    id: 2,
    img: <img src="/icons/calendar-black.svg" alt="Calendar Icon" />,
    info: 'Delivery date',
    sortKey: 'scheduledFor',
  },
  {
    id: 3,
    img: <img src="/icons/amount.svg" alt="Amount Icon" />,
    info: 'Amount',
    sortKey: 'providerPayout',
  },
];

export default function SortSheet({ setOpen, setSortBy, setSort, sortBy }) {
  const [selected, setSelected] = useState(SortOptions.find((sort) => sort.sortKey === sortBy));
  const [showApply, setShowApply] = useState(false);

  const changeSort = (item) => {
    setSelected(item);
    setShowApply(true);
  };

  const applySort = () => {
    setSortBy(selected.sortKey);
    setSort('desc');
    setOpen(false);
  };

  return (
    <SortSheetWrapper>
      <div className="sort-header">
        <img
          src="/icons/close-black.svg"
          alt="Close Icon"
          className="close-img"
          onClick={() => setOpen(false)}
        />
        <div className="header-title">Sort and filter</div>
      </div>
      <div className="sort-title">Sort</div>
      <div className="sort-options">
        {SortOptions.map((item) => (
          <div key={item.id} className="sort-item-wrapper" onClick={() => changeSort(item)}>
            <div className="sort-item-container">
              {item.img}
              <div className="sort-info">{item.info}</div>
            </div>
            {selected?.id === item.id && <img src="/icons/tick-black.svg" alt="Tick Icon" />}
          </div>
        ))}
      </div>
      {showApply && (
        <motion.div
          className="apply-btn-wrapper"
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{
            duration: 0.3,
            delay: 0,
            ease: 'linear',
          }}
        >
          <Button title="Apply" type="dark" onClick={applySort} />
        </motion.div>
      )}
    </SortSheetWrapper>
  );
}
