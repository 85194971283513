import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import OrderInfo from 'components/Booking/OrderInfo';
import {useNutritionistContext} from "../../context/nutritionistContext";
import { useRequest } from 'hooks/useRequest';
import Steps from '../GuestUser/Steps';
import Footer from 'layout/Footer';
import Progress from 'components/Progress';

const BookedSuccessfullyWrapper = styled.div`
  .desc {
    width: 80%;
    margin: 23px auto;
    text-align: center;

    h2 {
      margin-bottom: 5px;
      color: #0b1d17;
      font-family: 'Roboto';
    }

    p {
      color: #949d9b;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .booked-details {
    padding: 0 15px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  .nutritionist-info-wrapper {
    margin-bottom: 24px;
    padding: 20px 5px 12px;
    border-bottom: 1px solid #51635d1a;

    .title {
      margin-bottom: 5px;
      color: #0b1d17;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      font-weight:700 !important;
    }

    .nutritionist-info {
      display: flex;
      align-items: center;
    }

    
    img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
      border-radius: 50%;
      object-fit: cover;
    }

    .nutritionist-name {
      color: #51635d;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
      font-weight:700;

      span {
        font-weight: 400;
      }
    }
  }

  .booked-info-wrapper {
    padding: 0 5px;
  }

  .booked-desc-wrapper {
    padding: 24px 0;
    border-bottom: 1px solid #51635d1a;
    display: flex;
    align-items: flex-start;

    img {
      margin-right: 7px;
    }

    .booked-desc {
      color: #51635d;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .btn-wrapper {
    padding: 24px 0;

    .primary-btn {
      height: 44px;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  button: disabled {
    background-color: #e0e3e0;
    color: #949d9b;
    cursor: not-allowed;
  }

  @media(min-width: 992px){
    min-height: calc(100vh - 151px);
    .booked-details {
      width: 45%;
      margin: auto;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding: 0px !important;
    }

    .nutritionist-info-wrapper{
      padding: 21px 32px 20px;

      .title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0px !important;
      }

      img{
        border: 1.5px solid #E0E3E0;
      }

      .nutritionist-name {
        font-family: 'Roboto';
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700 !important;
      }
    } 

    .booked-info-wrapper {
      padding: 3px 31px 12px;
    }

    .booked-desc-wrapper{
      padding-top: 30px;
      padding: 24px 17px;
      border-bottom: none;

      .booked-desc{
        font-family: 'Roboto';
        font-style: normal;
        font-size: 14px;
        line-height: 16px;

      }
    }  

    .btn-wrapper {
      padding: 14px 0;
      display: flex !important;
      justify-content: center !important;

      .primary-btn {
        height: 56px;
        width: 54%;
        border-radius: 28px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }

    button: disabled {
      background-color: #e0e3e0;
      color: #949d9b;
      cursor: not-allowed;
    }

  }
`;


export default function BookedSuccessfully() {
  const { nutritionist, user , guestId , loginInfo , afterLogin, isMobile} = useAppContext();
  const [login,{isLoading}] = useRequest('auth/login', 'post');
  const {offerData} = useNutritionistContext();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  const history = useHistory();
  const handleLogin = async(e) => {
    e.preventDefault();
     if(selectedOfferDetail?.userId == "undefined"){
      const loginResp = await login({
        body: { email:loginInfo.email,
                password:loginInfo.password },
      });
      loginResp && history.push("/shop/my-coach")
      localStorage.removeItem("guestinfo")
      window.scrollTo(0, 0);
     await afterLogin(loginResp);
      localStorage.setItem('steps', 'true');
    }
    else{
      history.push(
        user ? '/shop/pantry' : '/booking/create-password');
        window.scrollTo(0, 0);
    }
  }


  return (
    <>
    <BookedSuccessfullyWrapper>
      {!(user?.id || selectedOfferDetail?.userId !== "undefined") ?
      isMobile ?
      <Steps
        title="Congrats!"
        description1="Your meeting has been scheduled"
        description2="successfully"
        perviousLink={() => {
          history.push('/guest/create-password');
          window.scrollTo(0, 0);
        }}
      /> :
      <Steps
        title="Congrats!"
        description1="Your meeting has been scheduled successfully"
        perviousLink={() => {
         history.push('/guest/create-password');
         window.scrollTo(0, 0);
        }}
      />
       : 
      <div className="desc">
        <h2>Congrats!</h2>
        <p>Your meeting has been scheduled successfully</p>
      </div>
      }
      <div className="booked-details">
        <div className="nutritionist-info-wrapper">
          <div className="title">{offerData?.title}</div>
          <div className="nutritionist-info">
            <img src={offerData?.nutritionist?.img || '/images/man.svg'} alt="Nutritionist" />
            <div className="nutritionist-name">
              <span>by</span> {offerData?.nutritionist?.name}
            </div>
          </div>
        </div>
        <div className="booked-info-wrapper">
          <OrderInfo />
          <div className="booked-desc-wrapper">
            <img src="/icons/document-text.svg" alt="Document Icon" />
            <div className="booked-desc">
              Description: {offerData?.description}
            </div>
          </div>
          <div className="btn-wrapper">
            <button
              className="primary-btn"
              onClick={handleLogin}
              disabled={isLoading}
            >
              {selectedOfferDetail?.userId == "undefined" ? "Explore Nutritt":"Access my account"}
            </button>
          </div>
        </div>
      </div>
      {
        isLoading && <Progress/>
      }
    </BookedSuccessfullyWrapper>
    </>
  );
}
