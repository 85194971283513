import { useState } from 'react';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useAppContext } from 'context/appContext';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import UserDetails from 'components/Nutritionist/Planning/UserDetails';
import PlanningMeals from 'components/Nutritionist/Planning/PlanningMeals';
import Diary from 'components/Nutritionist/Planning/Diary';
import { useHistory } from 'react-router-dom';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NutritionistPlanningWrapper = styled.div`
  .nutritionist-planning-container {
    padding: 23px 0 33px;
  }
  .planning-title {
    position: relative;
    margin-bottom: 61px;
    ${FlexCenter};
    img {
      position: absolute;
      top: auto;
      left: 0;
    }
    h3 {
      color: #0b1d17;
      font-family: 'Roboto';
      line-height: 24px;
    }
  }
  .nutritionist-planning-wrapper {
    position: relative;
    padding: 57px 0 24px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .MuiTabs-root {
    border-bottom: 1px solid #f6f5f8;
    .MuiTab-root {
      min-width: 120px;
      .MuiTab-wrapper {
        font-size: 16px;
        line-height: 19px;
      }
      &.Mui-selected .MuiTab-wrapper {
        font-family: 'Roboto';
      }
    }
    .MuiTabs-flexContainer {
      justify-content: center;
    }
  }
  .MuiBox-root {
    padding: 18px 0 0 !important;
    .MuiTypography-body1 {
      padding: 0 10px;
    }
  }
  .btns-wrapper {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;

    button {
      height: 40px;
      color: #fff;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      cursor: pointer;

      &:first-child {
        background-color: transparent;
        color: #51635d;
        border: 1px solid #97dc76 !important;
      }
    }
  }
  @media (min-width: 992px) {
    margin-left: 300px;
    display: flex;
    .nutritionist-planning-container {
      position: relative;
      width: calc(100% - 300px);
      padding: 35px 0 0;
      background-color: #fff;
      .planning-title {
        margin-bottom: 0;
        h3 {
          color: #51635d;
          font-size: 24px;
        }
      }
    }
    .btns-wrapper {
      display: flex;
      justify-content: center;

      button {
        width: 150px;
        height: 42px;
        ${FlexCenter};
      }
    }
    .nutritionist-planning-wrapper {
      padding: 25px 0 32px;
      box-shadow: none;
    }
    .MuiBox-root {
      padding: 24px 0 0 !important;
      .MuiTypography-body1 {
        padding: 0;
      }
    }
    .MuiTabs-root {
      border-color: #e0e3e0;
      .MuiTab-root {
        &.Mui-selected {
          border-bottom: 2.46px solid #52c41a !important;
        }
        &:first-child {
          margin-right: 40px;
        }
      }
    }
    .MuiTabs-indicator {
      height: 0 !important;
    }
    .user-sidebar-wrapper {
      width: 300px;
      min-height: 100vh;
      padding-top: 48px;
      background-color: #f7f6f7;
      box-shadow: 6px 0 4px -2px rgb(0 0 0 / 10%) inset;
      transition: all 0.3s ease-in-out;
    }
  }
  @media (min-width: 1200px) {
    .nutritionist-planning-container .container {
      width: 80%;
    }
  }
  @media (min-width: 1600px) {
    .nutritionist-planning-container .container {
      width: 70%;
    }
  }
`;

{
  TabPanel;
}

export default function NutritionistPlanning() {
  const [value, setValue] = useState(0);
  const { isMobile } = useAppContext();
  const [diaryData, setDiaryData] = useState([]);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    history.push('/nutritionist/coaching/clients');
  };

  return (
    <NutritionistPlanningWrapper>
      <div className="nutritionist-planning-container">
        <div className="container">
          <div className="planning-title">
            <img src="/icons/arrow-left.svg" alt="Arrow Icon" onClick={handleClick} />
            <h3>Meal plan</h3>
          </div>
          <div className="nutritionist-planning-wrapper">
            {isMobile && <UserDetails />}
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Plan" />
              <Tab label="Diary" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <PlanningMeals setDiaryData={setDiaryData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Diary diaryData={diaryData} />
            </TabPanel>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="user-sidebar-wrapper">
          <div className="user-details-wrapper">
            <UserDetails />
          </div>
        </div>
      )}
    </NutritionistPlanningWrapper>
  );
}
