import styled from 'styled-components';

const SliderCardStyled = styled.div`
    .image{
        width: 100%;
        height: auto;
        margin-top: 38px;
        background: #F6F5F8;
        img{
            width: 100%;
            height: ${window.innerWidth}px;
            display: block;
            object-fit: cover;
        }
    }
    .content{
        padding: 53px 24px;
        text-align: left;
        .title{
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #0B1D17;
            margin-bottom: 16px;
        }
        .text{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #51635D;
        }
    }
`

export default function SliderCard({ imgUrl, title, description, keyy }) {

    return (
        <SliderCardStyled className={`key-${keyy}`}>
            <div className='image'>
                <img src={imgUrl} />
            </div>
            <div className='content'>
                <h2 className='title' style={{ whiteSpace: 'pre-line' }}>{title}</h2>
                <p className='text' style={{ whiteSpace: 'pre-line' }}>{description}</p>
            </div>
        </SliderCardStyled>
    )
}