import { useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Input from 'components/Input';
import { FormControl, FormControlLabel, RadioGroup, Radio, Hidden } from '@material-ui/core';
import { useRequest } from 'hooks/useRequest';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router-dom';

const AddressFormModalStyle = styled.div`
font-family: 'Roboto';
font-style: normal;
position: fixed;
top:0;
left: 0;
right: 0;
bottom: 0;
z-index: 122;
padding:20px 0px;
background-color: #fff;
height: 100%;
overflow: auto;
&::-webkit-scrollbar {
  display:none;
}
.image-div{
    display:flex;
    img{
        position: relative;
        left: 20px;
        top: 15px;
    }
}
.text{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;

}
.button-div{
    
    button{
        width: 271px;
        height: 40px;
        border-radius: 21px;
        background: #52C41A;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }
}

.address-form{
    padding:32px 24px;

    label{
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635D;
        text-align:start;
       }
        input{
         font-family: 'Roboto';
         font-style: normal;
         font-weight: 400;
         font-size: 14px !important;
         line-height: 16px;
         color:#51635D;
         &::placeholder{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
         }
       }

    .selectWrapper{
        display: flex;
        flex-direction: column;
        width:100% !important;
        margin-top:20px;
    
        .labels{
          display:flex;
          column-gap:2.49%;
          margin-top: 7px;
    
         .label-tag{
          // width:67px;
          width: 23.13%;
          height:32px;
          display:flex;
          align-items:center;
          justify-content:center;
          column-gap:8px;
          border: 1px solid #E0E3E0;
          border-radius: 16px;
          .image{
            margin-top: 2px;
          }
          p{
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            color: #51635D;
            font-family: 'Roboto';
            font-style: normal;
            text-transform: capitalize;
          }
          &.active{
            border: 1px solid #CBEDBA;
            .image{
               img{
               filter: invert(57%) sepia(46%) saturate(1920%) hue-rotate(62deg) brightness(107%) contrast(80%);
               }
            }
            p{
              color: #52C41A;
            }
          }
         }
        }
          
    }
    .label-input{
       margin-top:10px ;
    }
    .error-input{
      input{
        background: rgba(235, 50, 35, 0.07);
        border: 1px solid rgba(235, 50, 35, 0.25);
        &::placeholder{
          color: #D62B21;
        }
      }
    }
    .save-later{
        display:flex;
        align-items: center;
        margin-top: 10px;
        p{
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #51635D;
        }
        .save{
          color: #AFB5B4;
        }
        .MuiFormControlLabel-root{
          margin-left:0;
          margin-right:0;
        }
    }

    .textarea-input{
      label{
        display:flex;
      }
        textarea{
         height:111px;
         background: #FFFFFF;
         border-radius: 6px;
         border: 1px solid #E0E3E0;
         margin-top:7px;
         font-family: 'Roboto';
         font-style: normal;
         padding-top:12px;
         font-weight: 400;
         font-size: 14px !important;
         line-height: 16px;
         color:#51635D;
         padding-inline: 16px; 
         &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #949D9B;
         }      
        }
    }
    
}
`
;
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#ffffff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    // backgroundColor: "#52C41A"
  }));
  const BpCheckedIcon = styled(BpIcon)({
    //backgroundColor: "#52C41A",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundImage:  "url('/icons/tick.svg')",
        backgroundRepeat:'no-repeat',
        backgroundPosition:'50%',
        backgroundColor:'radial-gradient(#fff,#fff,28%,transparent 32%)',
        border:'1px solid #52C41A',
        borderRadius:'50%',
        content: '""',
  
    },
  
  });

export default function DeliveryAddressForm({setAddressFormModal,value,form,setForm,handleClick,checked,setChecked,isLoading}){
  
    const [showAnim, setShowAnim] = useState(true);
    const [customInput, setCustomInput] = useState(false);
    const [error, setError] = useState(false);
    const [style, setStyle] = useState('');
  
    useEffect(()=>{
      if(value){
        setForm({...form,address:value});
       
      }
    },[value])

    const handleChange = (e, key) => {
      setForm({ ...form, [key]: e.target.value });
      setStyle('');
      setError(false);
    };
    
    const handletype = (value) => {
        if(value==='other'){
          setCustomInput(true);
          setForm({ ...form, type:value});
          setStyle('');
          setError(false);
        }
        else{
          setCustomInput(false)
         setForm({ ...form, type: value });
        }
      };
   
    const closeAddressModal = () => {
      setShowAnim(false);
      setTimeout(() => {
        setAddressFormModal(false);
      }, 500)
  }
     
  const Labels = [
    {id:1, Img: <img src='/icons/house.svg' />, Name: 'home',activeImg: <img src='/icons/house-green.svg' /> },
    {id:2, Img: <img src='/icons/buliding2.svg' />, Name: 'office',activeImg: <img src='/icons/buliding2-green.svg' /> },
    {id:3, Img: <img src='/icons/gym.svg' />, Name: 'gym',activeImg: <img src='/icons/gym-green.svg' /> },
    {id:4, Img: <img src='/icons/building.svg' />, Name: 'other',activeImg: <img src='/icons/building-green.svg' /> }
  ]

      return(
         <AddressFormModalStyle className={showAnim ? "fadeInMiddle" : "fadeOutMiddle"}>
          <div className='image-div'>
          <img src="/icons/close-cross-green.svg" onClick={closeAddressModal} className="close_cross" />
          </div>
          <div className='text'>
              <p>Add delivery address</p>
          </div>
          
          <div className='address-form'>
          <Input
          name="address"
          value={form.address.label}
          readOnly
          bordered
          type="text"
          placeholder="Address"
          className='input2'
          />
         <Input
          name="apartment"
          value={form.apartment}
          onChange={(e) => handleChange(e, 'apartment')}
          bordered
          type="text"
          placeholder="Apartment, Suite or Floor"
          className='input2'
          disabled={checked ? false : true}
          />
           
       <div className='selectWrapper'>
        <label>
            Address Label 
        </label>
        <div className='labels'>
         {
          Labels.map((item)=>{
            return (
              <div className={`label-tag ${item.Name===form.type && checked && 'active'}`} onClick={() => handletype(item.Name)}>
                <div className='image'>{item.Img}</div>
                <p>{item?.Name}</p>
              </div>
            )
          })
         }
        </div>
        </div>

        {customInput && checked &&
           <Input
           name="customType"
           value={form.customType}
           onChange={(e) => handleChange(e, 'customType')}
           bordered
           type="text"
           placeholder={error ? "Label name required" : "Add name of label"}
           className={`input4 ${style}`}
           maxLength={14}
           onBlur={(e) => {
            const { value } = e.target;
            if (!value){
              setError(true);
              setStyle('error-input')
            }
          }}

         />
        }

          <div className='save-later'>
               <FormControl>
               <RadioGroup row name="second-order">
               <FormControlLabel
               // value={}
                 checked={checked}
                 control={<Radio onClick={() => setChecked(!checked)} checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />}
                 />
                </RadioGroup>
                </FormControl>
                <p className={!checked && 'save'}>Save for later</p>
            </div>

          <Input
          label="Special Instructions"
          name="instruction"
          value={form.instruction}
          onChange={(e) => handleChange(e, 'instruction')}
          bordered
          type="textarea"
          placeholder="Special instrcutions"
          className='textarea-input'
          disabled={checked ? false : true}
        />

          </div>   

              <div className='button-div'>
                  <button
                     className={classNames({
                      disabled:isLoading || customInput && checked && form.customType===null || customInput && checked && form.customType==='',
                    })}
                    disabled={isLoading || customInput && checked && form.customType===null || customInput && checked && form.customType===''}
                    onClick={handleClick}
                  >
                      Next
                  </button>
              </div>

           

         </AddressFormModalStyle> 
      )
  }