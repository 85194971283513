import styled, { css } from 'styled-components';
// import { GiPeanut, GiPeas } from "react-icons/gi";
import Calories from 'components/shop/SharedComponents/Calories';
import useDisplayAmount from 'hooks/useDisplayAmount';
import Tags from 'components/shop/MealTags';
import ImageViewer from '../../../../components/ImageViewer';

const CombosCardStyle = styled.div`
  margin-bottom: 24px;
  padding: 12px 6px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px #0000000d;
  border: 1.5px solid transparent;
  cursor: pointer;
  .drawer-card-content {
    width: 73%;
    .drawer-card-title {
      margin-bottom: 8px;
      color: var(--dark);
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .drawer-count-wrapper {
      display: grid;
      grid-template-columns: auto 50px;
      /* justify-content: space-between; */
      align-items: center;
      .icons-wrapper {
        display: flex;
      }
      .icon-container {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        border: 0.5px solid #c3cbc9;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        font-size: 10px;
      }
      .price {
        font-size: 14px;
        font-weight: 600;
        text-align: right;
      }

      @media (min-width: 768px) {
        padding-right: 50px;
      }
    }
  }
  img {
    width: 27%;
    height: 90px;
    margin-right: 15px;
    border-radius: 8px;
    object-fit: cover;
  }
`;

const WeightWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  .weight-degrees {
    text-align: right;
  }

  @media (min-width: 768px) {
    padding-right: 50px;
  }
`;

export default function ComponentCard({ meal, selected, onSelectComboMeal }) {
  const {
    name,
    price,
    carbs,
    protein,
    fat,
    calories,
    img,
    componentName,
    tags,
    restrictions,
    weight,
  } = meal;

  return (
    <CombosCardStyle className='meal-item-boxen' selected={selected} onClick={onSelectComboMeal}>
      <ImageViewer src={img || '/img_coming_soon.png'} fallbackSrc={'/img_coming_soon.png'} alt={name} />
      <div className="drawer-card-content">
        <div className="drawer-card-title">
          <span>{name}</span>
        </div>
        <WeightWrapper>
          <Calories carbs={carbs} protein={protein} fat={fat} calories={calories} />
          <div className="weight-degrees">{weight}g</div>
        </WeightWrapper>
        <div className='drawer-tags-area'>
          <Tags tags={[...restrictions]} />
          <Tags tags={[...tags]} />
        </div>
        <div className="drawer-count-wrapper">
          {/* <div className="icons-wrapper"> */}
          {/* <div className="icon-container">
              <GiPeanut />
            </div>
            <div className="icon-container">
              <GiPeas />
            </div> */}
          {/* </div> */}
          <div className="price">{useDisplayAmount(price / 100)}</div>
          <label className="radio-wrapper">
            <input
              type="radio"
              name={componentName}
              onChange={onSelectComboMeal}
              checked={selected}
            />
            <span className="radio-check"></span>
          </label>
        </div>
      </div>
    </CombosCardStyle>
  );
}
