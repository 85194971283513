import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useAppContext } from 'context/appContext';
import PantryTab from './PantryTab';
import SearchTab from './SearchTab';
import CustomMealTab from './CustomMealTab';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import {useLocation,useParams,useHistory} from "react-router-dom";
import MobileHeader from 'components/elements/MobileHeader';

import { useRequest } from 'hooks/useRequest';
const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogMealsModalWrapper = Styled.div`
  position: fixed;
  top: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 115;
  background-color: #fff;
  visibility: hidden;
  transition: all .5s ease-in-out;
  &.slideUp {
    top: 0;
    visibility: visible;
  }
  .close-icon {
    position: absolute;
    top: 14px;
    left: 14px;
  }
  .log-title {
    margin-bottom: 5px;
    color: #51635D;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  .input-wrapper {
    position: relative;
    margin: 24px 37px 14px;
    ${flexCenter};
    input {
      width: 100%;
      height: 36px;
      padding: 0 14px 0 40px;
      background: #F6F5F8;
      border: 1px solid #E0E3E0;
      border-radius: 6px;
      &::placeholder {
        color: #AFB5B4;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .search-icon {
      position: absolute;
      top: auto;
      left: 16px;
      width: 16px;
      height: 16px;
    }
  }
  .MuiTabs-root::before {
    left : 0;
    bottom : 0;
    content : ' ' ;
    position: absolute;
    width : 100%;
    border-bottom: 1px solid #E0E3E0;
  }
  .MuiTabs-root {
    min-height: auto;
    // border-bottom: 1px solid #E0E3E0;
     position: relative;
    
    .MuiTab-textColorInherit {
      width: 33.3%;
      min-height: 39px;
      padding: 5px 0px 0px;
      &.Mui-selected { 
          border-bottom: 2px solid #00092D !important;     
      }
    }
    .MuiTab-root {
      .MuiTab-wrapper {
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;   
        color: #000A2C;       
       }
      &.Mui-selected .MuiTab-wrapper {
        font-weight: 600; 
        color: #00092D;       
      }
    }
    .MuiTabs-indicator {
      display: none
    }
  }
  .MuiBox-root {
    padding: 24px 0 0 !important;
  }

  .MuiTabs-flexContainer{
    justify-content: space-around;
  }
  .done-btn-wrapper {
    padding: 24px 0;
    ${flexCenter};
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom:0;
    button {
      position: relative;
      width: 300px;
      height: 42px;
      background: #000A2C;
      color: #FFFFFF;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      span {
        position: absolute;
        top: calc(50% - 28px/2);
        left: 6px;
        width: 28px;
        height: 28px;
        background: #fff;
        color: #000A2C;
        border-radius: 14px;
        ${flexCenter};
      }
    }
  }
`;

{
  TabPanel;
}

const mealData = {
  name: '',
  calories: Number,
  carbs: '',
  fat: '',
  protein: '',
  img: '/images/dish.svg',
};

export default function LogMealsModal({
  setIsLogMealsModal,
  isLogMealsModal,
  setActiveDay,
  activeDay,
  getdiaryData,
  currentDayinfo,
  updateFullDiaryMeals,
  revertFullDiaryMeals
}) {
  
  const [value, setValue] = useState(0);
  const [height, setHeight] = useState();
  const {isMobile} = useAppContext();
  const [form, setForm] = useState(mealData);
  const [customMealDiary, {isLoading:isCustomMealSaving}] = useRequest('customMealDiary/create', 'post');
  const history=useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setForm(mealData);
  };
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    const Barcode = params.get('barcode');
    if (Barcode == 'true') {
      setValue(2);
     }else{
       setValue(0);
     }
  }, [isLogMealsModal]);

  useEffect(() => {
    isMobile && setActiveDay(activeDay);
  }, [isLogMealsModal, value])

  useEffect(() => {
    setHeight(window.innerHeight);   
  }, []);

  const resetParams=()=>{
    const barcode=params.get("barcode")
    const logMeal=params.get("logmeal")
    if(barcode==="true" || logMeal==="true"){
      params.delete('logmeal');
      params.delete('barcode');
      const newSearch = params.toString() ? `?${params.toString()}` : '';
      const newUrl = `${location.pathname}${newSearch}`;
      history.replace(newUrl);
    }
  }

  const handleClose=()=>{
    resetParams();
    setIsLogMealsModal(false);

 
  }

  return (
    <LogMealsModalWrapper className={classNames({ slideUp: isLogMealsModal })} style={{ height }}>

      <MobileHeader onClickClose={handleClose} text={'Log Meals'} />
    
      <Tabs value={value} onChange={handleChange}  aria-label="simple tabs example">
        <Tab label="Pantry" />
        <Tab label="Custom Meal" />
        <Tab label="Search" /> 
      </Tabs>
    
      <TabPanel value={value} index={0}>
        <PantryTab
          setActiveDay={setActiveDay}
          activeDay={activeDay}
          isLogMealsModal={isLogMealsModal}
          getdiaryData={getdiaryData}
          value={value}
          updateFullDiaryMeals={updateFullDiaryMeals}
          revertFullDiaryMeals={revertFullDiaryMeals}
          setIsLogMealsModal={setIsLogMealsModal}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CustomMealTab
          setIsLogMealsModal={setIsLogMealsModal}
          isLogMealsModal={isLogMealsModal}
          setActiveDay={setActiveDay}
          activeDay={activeDay}
          getdiaryData={getdiaryData}
          customMealDiary={customMealDiary}
          isCustomMealSaving={isCustomMealSaving}
          form={form}
          setForm={setForm}
          mealData={mealData}
          updateFullDiaryMeals={updateFullDiaryMeals}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SearchTab
          isLogMealsModal={isLogMealsModal}
          setActiveDay={setActiveDay}
          activeDay={activeDay}
          getdiaryData={getdiaryData}
          customMealDiary={customMealDiary}
          isCustomMealSaving={isCustomMealSaving}
          setIsLogMealsModal={setIsLogMealsModal}
          customform={form}
          setCustomForm={setForm}
          setValue={setValue}
          currentDayinfo={currentDayinfo}
          updateFullDiaryMeals={updateFullDiaryMeals}
          resetParams={resetParams}
        />
      </TabPanel>
    </LogMealsModalWrapper>
  );
}