import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GilroyFontWrapper } from 'shared/theme/style';
import { ReactComponent as Calories } from 'assets/shop/calories.svg';
import SettingsRowItem from 'components/elements/settingsRowItem/SettingsRowItem';
import { useHistory } from 'react-router-dom';
import DesiredGoalPace from './DesiredGoalPace';
import DesiredHealthGoal from './DesiredHealthGoal';
import CurrentWeight from './CurrentWeight';
import Height from './Height';
import Gender from './Gender';
import BirthDate from './BirthDate';
import TargetWeight from './TargetWeight';
import ActivityLevel from './ActivityLevel';
import { AnimatePresence, motion } from 'framer-motion';
import PersonalizedMacros from './PersonalizedMacros';
import MobileHeader from 'components/elements/MobileHeader';
import { useShopContext } from 'context/shopContext';
import RecommendedCalories from './RecommendedCalories';
import { formatDate } from 'utils/formatDate';

const HealthGoalStyle = styled.div`
visibility: ${({lastLoginUrl}) => lastLoginUrl === '/shop/track-quiz' ? "hidden" : ""};
.header{
    background:white;
}
.main_list{
  overflow:auto;
   height: calc(100vh - 118px);
}
.list{
    background: white;
    padding:12px 16px 12px 15px;
}
.nutritt_cash_balance {
    border-radius: 7px;
    background: #f0faeb;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 14px 14px 14px 10px;
    height: 48px;
    .title {
      color: #0b1d17;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      flex: 1;
    }
    .cash_balance {
      color: #0b1d17;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &.isCashAvailable {
      background: #f0faeb;
    }
  }
}
.account_body{
    padding-top: 12px;
}
.account_urls{
    padding: 12px 24px;
}
`;

const FixedModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 112;
`;

const OverflowHidden = styled.div`
  overflow: hidden;
`;

export const HealthGoal = ({ calories = 1400 }) => {
  const history = useHistory();
  const [activeModalStack, setActiveModalStack] = useState([]);
  const { healthGoal: { heightFeet, heightInches, gender, activity, selfMealPlan = {}, weight, birthday }, healthGoal, updatedProfile, updatedHealthGoal, setUpdatedHealthGoal } = useShopContext();
  const { healthGoal: desiredHealthGoal, idealWeight, goal } = selfMealPlan;
  const lastLoginUrl = history?.location?.state?.from;
  let myGoal = '';
  let activityLevel = '';
  let desiredPace = ''

  switch(desiredHealthGoal){
    case "maintain-weight":
      myGoal = "Maintain healthy weight";
      break;
    case "weight-gain":
    case "bulkup":
      myGoal = "Weight gain";
      break;
    case "weight-loss":
    case "lose-weight":
      myGoal = "Weight loss";
      break;
    default:
      myGoal = "N/A";
      break;
  }

  switch(activity){
    case 1:
      activityLevel = "Slightly active";
      break;
    case 2:
      activityLevel = "Active";
      break;
    case 3:
      activityLevel = "Very active";
      break;
    case 4:
      activityLevel = "Intensively active";
      break;
    case 5:
      activityLevel = "Extremely active";
      break;
    default: 
      activityLevel = "N/A";
      break;
  }

  switch(goal){
    case 0.5:
      desiredPace = "Easy / Gain 0.5 lbs/week"
      break;
    case 1:
      desiredPace = "Medium / Gain 1 lbs/week"
      break;
    case 2:
      desiredPace = "Extreme / Gain 2 lbs/week"
      break;
    case -0.5:
      desiredPace = "Easy / Lose 0.5 lbs/week"
      break;
    case -1:
      desiredPace = "Medium / Lose 1 lbs/week"
      break;
    case -2:
      desiredPace = "Extreme / Lose 2 lbs/week"
      break;
    default:
      desiredPace = "N/A"
      break;
  }

  const [animation, setAnimation] = useState({
    initial: {
      x: '100%',
      opacity: 0,
    },
    exit: {
      x: '-100%',
      opacity: 0,
    },
  });

  const healthGoalModalArray = [
    {
      id: 0,
      modal: 'desired-goal',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <DesiredHealthGoal
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 1,
      modal: 'current-weight',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <CurrentWeight
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 2,
      modal: 'target-weight',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <TargetWeight
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 3,
      modal: 'activity-level',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <ActivityLevel
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 4,
      modal: 'desired-goal-pace',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <DesiredGoalPace
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 5,
      modal: 'gender',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <Gender
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 6,
      modal: 'age',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <BirthDate
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 7,
      modal: 'height',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <Height
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 8,
      modal: 'personalized-macros',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <PersonalizedMacros
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
    {
      id: 9,
      modal: 'daily-target-calories',
      component: (activeModalStack, setActiveModalStack, modal, nextScreen, isHealthGoalFlow, totalPagesCount) => (
        <RecommendedCalories
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
          nextScreen={nextScreen}
          isHealthGoalFlow={isHealthGoalFlow}
          totalPagesCount={totalPagesCount}
        />
      ),
    },
  ];

  const handleItemClick = (component) => {
    if (!activeModalStack?.includes(component)) {
      setTimeout(() => {
        setActiveModalStack([...activeModalStack, component]);
      }, 0)
    }
  };

  const handleClick = (value) => {
    setAnimation({
      initial: {
        x: '-100%',
        opacity: 0,
      },
      exit: {
        x: '-100%',
        opacity: 0,
      },
    });
    handleItemClick(healthGoalModalArray[value]);
  }

  useEffect(() => {
    if(activeModalStack?.length === 0){
      setUpdatedHealthGoal(healthGoal);
    }
    document.body.classList.add('hide-body-scroll');
    return () => document.body.classList.remove('hide-body-scroll');
  }, [activeModalStack])

  useEffect(() => {
  if(lastLoginUrl === '/shop/track-quiz'){
    handleItemClick(healthGoalModalArray[0]);
  }
  },[])

  return (
    <>
      <OverflowHidden>
        <GilroyFontWrapper>
          <AnimatePresence>
            {activeModalStack?.length > 0 ? (
              ''
            ) : (
              <motion.div
                initial={animation?.initial}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={animation?.exit}
                transition={{ duration: 0.7, type: 'spring' }}
              >
                <HealthGoalStyle activeModalStack={activeModalStack} lastLoginUrl={lastLoginUrl}>
                  <MobileHeader className={'header'} text={'Health goals'} onClickLeft={() => history.push('/shop/account')} />
                  <div className='main_list'>
                  <div className="list">
                    <div className={`nutritt_cash_balance`}>
                      <Calories className="dollar_icon" />
                      <p className="title">Target daily calories</p>
                      <span className="cash_balance">{updatedProfile?.selfMealPlan?.customDietMetrics?.isActive ? updatedProfile?.selfMealPlan?.customDietMetrics?.customCalories : updatedProfile?.dietMetrics?.calories || 'N/A'}</span>
                    </div>
                  </div>
                  <div className="account_body">
                    <SettingsRowItem
                      title={'Health goal'}
                      subTitle={myGoal}
                      onClick={() => handleClick(0)}
                    />
                    <SettingsRowItem
                      title={'Current weight'}
                      subTitle={weight > 0 ? weight + ' lbs' : 'N/A'}
                      onClick={() => handleClick(1)}
                    />
                    <SettingsRowItem
                      title={'Target weight'}
                      subTitle={idealWeight > 0 ? idealWeight + ' lbs' : 'N/A'}
                      onClick={() => handleClick(2)}
                    />
                    <SettingsRowItem
                      title={'Activity level'}
                      subTitle={activityLevel}
                      onClick={() => handleClick(3)}
                    />
                    <SettingsRowItem
                      title={'Desired goal pace'}
                      subTitle={desiredPace}
                      onClick={() => handleClick(4)}
                    />
                  </div>
                  <div className="account_body">
                    <SettingsRowItem
                      title={'Gender'}
                      subTitle={gender === 'f' ? 'Female' : 'Male'}
                      onClick={() => handleClick(5)}
                    />
                    <SettingsRowItem
                      title={'Date of birth'}
                      subTitle={birthday ? formatDate(birthday) : 'N/A'}
                      onClick={() => handleClick(6)}
                    />
                    <SettingsRowItem
                      title={'Height'}
                      subTitle={(heightFeet ? `${heightFeet} ft` : "") + (heightFeet && heightInches ? " " : "") + (heightInches ? `${heightInches} in` : '')}
                      onClick={() => handleClick(7)}
                    />
                  </div>
                  </div>
                </HealthGoalStyle>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {activeModalStack?.length &&
              activeModalStack?.map((item) => {
                const currentScreenId = healthGoalModalArray
                  ?.map((screen) => screen?.id)
                  .indexOf(item?.id);
                let nextScreen = healthGoalModalArray[currentScreenId + 1];
                let totalPagesCount = 8;
                if(updatedHealthGoal?.selfMealPlan?.healthGoal === "maintain-weight"){
                  totalPagesCount = 6;
                  if(currentScreenId === 1 || currentScreenId === 3){
                    nextScreen = healthGoalModalArray[currentScreenId + 1 + 1];
                  }
                }
                const isHealthGoalFlow = !!activeModalStack?.find(
                  (item) => item?.modal === 'desired-goal',
                );
                return (
                  <FixedModalStyle key={item?.id}>
                    {item?.component(
                      activeModalStack,
                      setActiveModalStack,
                      item,
                      nextScreen,
                      isHealthGoalFlow,
                      totalPagesCount
                    )}
                  </FixedModalStyle>
                );
              })}
          </AnimatePresence>
        </GilroyFontWrapper>
      </OverflowHidden>
    </>
  );
};
