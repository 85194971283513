import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const FooterDesktopWrapper = styled.footer`
  background-color: #fff;

  .footer-container {
    max-width: 1620px;
    margin: 0 auto;
    padding: 60px 25px 22px;
    color: ${theme.colors.primaryDarker};
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .footer-links-wrapper {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(5, 1fr);
    height: 100px;
    border-bottom: 1px solid #E0E4E8;
  }

  .mobile-app-wrapper{
    .app-download, img {
      height: 32px;
    }
  }

  .logo-wrapper {
    width: 311px;
    margin: -5px 43px 5px 0;
    .nutritt-brand {
      width: 111px;
      height: 48px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .slogan {
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      color: #2F3E3D;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .follow-wrapper {
    width: 168px;
    .social-media-wrapper {
      margin-bottom: 29px;
      display: flex;
      align-items: center;
      gap: 25px;
      .icon-wrapper {
        display: flex;
      }
    }
    .app-download {
      display: flex;
      align-items: center;
      gap: 7px;
      img{
        height: 32px;
      }
    }
  }

  .support-wrapper {
    width: 109px;
  }

  .footer-title {
    margin-bottom: 20px;
    color: #2F3E3D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .footer-info {
    color: #757E89;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footer-copyright-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2F3E3D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .terms-links {
      display: flex;
      align-items: center;
      gap: 48px;
      .link {
        color: #2F3E3D;
      }
    }
  }
  @media (max-width: 1280px) {
    .footer-container {
      max-width: 100%;
    }

    .logo-wrapper,
    .support-wrapper {
      width: max-content;
    }
  }

  @media (max-width: 767px) {
    padding: 32px 24px;
    font-family: Gilroy;

    .footer-container {
      padding: 0;
      gap: 48px;
    }

    .footer-links-wrapper {
      display: grid;
      height: auto;
      grid-template-areas:
        'logo logo'
        'follow follow'
        'support about';
      .logo-wrapper {
        grid-area: logo;
      }
      .follow-wrapper {
        grid-area: follow;
      }
      .support-wrapper {
        grid-area: support;
      }
      .about-wrapper {
        grid-area: about;
        width: 57px;
        margin-bottom: 32px;    
      }
    }

    .logo-wrapper {
      width: auto;
      margin: 0 0 48px;
      .nutritt-brand {
        margin: 0 0 5px -10px;
      }
      .slogan {
        margin: 0;
      }
    }

    .follow-wrapper {
      width: auto;
      margin: 0 0 48px;
      .social-media-wrapper {
        margin-bottom: 32px;
      }
      .app-download {
        a {
          display: flex;
        }
      }
    }

    .support-wrapper {
      width: 169.397px;
      margin-right: 9.6px;
    }

    .footer-copyright-wrapper {
      display: flex;
      flex-direction: column;
      flex-flow: column-reverse;
      align-items: flex-start;
      gap: 14px;
      .copyright {
        color: ${theme.colors.mediumGrey};
      }
    }
  }
  @media (min-width: 767px) and (max-width: 1024px){
    .footer-links-wrapper{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      height: 100%;
      padding-bottom: 48px;
      grid-row-gap: 62px;
      .support-wrapper{
        width: 222px;
      }
      .about-wrapper{
        width: 168px;
      }
    }
  }
`;
