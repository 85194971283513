import classNames from 'classnames';
import styled from 'styled-components';
import PantryFooter from 'components/Pantry/PantryFooter';
import PlanListHeader from 'components/shop/CoachProfile/PlansList';
import PlanCard from 'components/shop/CoachProfile/PlansList/PlanCard';
import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { formatNumberDecimalPlaces } from '../../../utils';
import Header from 'layout/Header';
import { useLocation } from 'react-router-dom';

const OfferPlansListStyle = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0 50px !important;
  justify-content: space-between;

  .plan-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 24px;

    // &.planCardWrapper {
    //   display: flex;
    //   justify-content: center;
    //   flex-wrap: wrap;

      & > div {
        width: 95%;
        margin-left:2.5%;
      }
    
  }

  @media (max-width: 991px) {
    min-height: calc(100vh - 32px);
    background-color: #f6f5f8;

    .plan-card-wrapper {
      grid-gap: 0;
      // & > div {
      //   width: 100%;
      // }
    }

    .days-tags {
      padding: 18px 16px 22px;
      
      &.daysIntroduction {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5px;
      }

      .days {
        width: auto;
        display: flex;
        flex-shrink: 0;
      }

      .show-more {
        margin: 0 !important;
        justify-self: center;
      }

      .tags-wrapper {
        justify-self: end;
      }
    }

  }

  @media(min-width:992px){
    .plan-card-wrapper{
    grid-template-columns: repeat(auto-fill, minmax(0, 367px));
    justify-content: center;
    & > div {
      width: 100%;
      margin-left:0;
    }
    }

    .days-wrapper .time{
        width:65px;
    }
  }
`;

export default function OfferPlansList() {
  const { offerList } = useAppContext();
  const { isMobile , user } = useAppContext();
  const { pathname } = useLocation();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <>
      {/* <>
        {(pathname.startsWith('/guest') && isMobile) && 
        <Header
         activeSteps="2"
        />}
      </> */}
      <>
      {/* {!( user?.id || selectedOfferDetail?.userId ) && <Header activeSteps="2"/>} */}
      {(pathname.startsWith('/guest') ) && <Header activeSteps="2"/>}
      </>
      <OfferPlansListStyle>
        <PlanListHeader planTitle="Coaching" planDescription="Schedule 1:1 with me" />
        <div className={classNames('plan-card-wrapper', {planCardWrapper: offerList?.length < 4})}>
        {offerList?.map((item) => {
          return (
            <PlanCard
              OfferPlanId={item?.id}
              nutritionistId={item?.nutritionistId}
              name={item?.title}
              availability={item?.availability}
              duration_type="minutes"
              price={formatNumberDecimalPlaces(item?.price / 100)}
              introduction={item?.description}
              offerPlan={true}
              image={item?.img}
            />
          );
        })}
        </div>
      </OfferPlansListStyle>
      {(isMobile && user ) && <PantryFooter myCoach={true} />}
    </>
  );
}

