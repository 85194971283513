import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { theme } from 'shared/theme/theme';
import { useAppContext } from 'context/appContext';
import Pie from 'components/elements/Pie';
import { useShopContext } from 'context/shopContext';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as Edit } from 'assets/shop/edit.svg';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCreative } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import EditCalories from './EditCalories';

const RecommendedCaloriesStyle = styled.div`
  background-color: #fff;
  height: ${({ window }) => window.innerHeight}px;
  position: relative;
  .containerr {
    padding: 53px 30px 0px;
    height: ${({ window }) => window.innerHeight - 135}px;
    overflow: auto;
    background-color: #fff;
    .swiper-slide{
      align-items: flex-start;
    }
    .first {
      z-index: 1;
      .sliderPoint {
        margin-top: 20px;
      }
    }
    z-index: 3;
  }
  .title {
    font-size: ${({ theme }) => theme?.typography?.fontLarge};
    color: ${({ theme }) => theme?.colors?.primaryDarker};
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
  }
  .subtitle {
    font-size: ${({ theme }) => theme?.typography?.fontBase};
    color: ${({ theme }) => theme?.colors?.mediumGrey};
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .edit_calories {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    p {
      color: #949d9b;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .user-progress {
    margin-top: 73px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .title-wrapper {
      .title-number {
        font-size: 32px !important;
        margin-bottom: 0;
        font-weight: 700;
        line-height: normal;
        color: ${({ theme }) => theme?.colors?.primaryDarker};
      }
    }
    .sliderPoint {
      width: 100%;
      text-align: center;
      margin-top: 60px;
    }
    .macro_title {
      margin-bottom: 8px;
      color: ${({ theme }) => theme?.colors?.mediumGrey};
      font-size: ${({ theme }) => theme?.typography?.fontMedium};
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 52px;
    }
    .progress_holder {
      width: 100%;
      .progress {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 15px;
        max-width: 250px;
        .titlee {
          color: ${({ theme }) => theme?.colors?.mediumGrey};
          font-size: 12px;
          font-weight: 500;
          line-height: 14.56px;
          height: 24px;
        }
        .value {
          font-weight: 600;
          line-height: 14.7px;

        }
        .progress_line_holder{
          width: 100%;
          background-color: #E0E3E0;
          .progress_line {
            width: 100%;
            height: 4px;
          }
        }
      }
    }
    .rc-progress-line {
      display: block;
    }
  }
  .button_holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 44px 16px 29px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
  }
`;

const SliderPoints = ({ slide }) => {
  if (slide == 'first') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="8" viewBox="0 0 23 8" fill="none">
        <circle cx="4" cy="4" r="4" fill="#949D9B" />
        <circle cx="20" cy="4" r="3" fill="#E0E3E0" />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="8" viewBox="0 0 23 8" fill="none">
      <circle cx="3" cy="4" r="3" fill="#E0E3E0" />
      <circle cx="19" cy="4" r="4" fill="#949D9B" />
    </svg>
  );
};

export default function RecommendedCalories({ setActiveModalStack }) {
  const history = useHistory();
  const [isEditCalories, setIsEditCalories] = useState(false);
  const [isAnimation, setIsAnimation] = useState(false);
  const [newCalories, setNewCalories] = useState();
  const { updatedProfile, healthGoal } = useShopContext();
  const { setupLocalUser, user, setRouteBeforeSignup } = useAppContext();
  const [loading, setIsLoading] = useState(false);
  const lastLoginUrl = history?.location?.state?.from;

  const pieData = [
    {
      id: 'Fat',
      value: updatedProfile?.selfMealPlan?.preferredDiet?.fatPercent,
    },
    {
      id: 'Protein',
      value: updatedProfile?.selfMealPlan?.preferredDiet?.proteinPercent,
    },
    {
      id: 'Carbs',
      value: updatedProfile?.selfMealPlan?.preferredDiet?.carbsPercent,
    },
  ];

  const progressLines = [
    {
      id: 'Fat',
      label: 'Fat',
      gram: `${updatedProfile?.dietMetrics?.macros?.fat}g`,
      color: '#EE786B',
      percent: updatedProfile?.selfMealPlan?.preferredDiet?.fatPercent
    },
    {
      id: 'Protein',
      label: 'Protein',
      gram: `${updatedProfile?.dietMetrics?.macros?.protein}g`,
      color: '#8ACEBF',
      percent: updatedProfile?.selfMealPlan?.preferredDiet?.proteinPercent,
    },
    {
      id: 'Carbs',
      label: 'Carbs',
      gram: `${updatedProfile?.dietMetrics?.macros?.carbs}g`,
      color: '#2399BF',
      percent: updatedProfile?.selfMealPlan?.preferredDiet?.carbsPercent,
    },
  ];

  useEffect(() => {
    const newCal =
      updatedProfile?.selfMealPlan?.customDietMetrics?.customCalories > 0
        ? updatedProfile?.selfMealPlan?.customDietMetrics?.customCalories
        : updatedProfile?.dietMetrics?.calories;
    setNewCalories(newCal);
  }, [updatedProfile]);

  useEffect(() => {
    isEditCalories
      ? (document.body.style.touchAction = 'none')
      : (document.body.style.touchAction = 'unset');
    return () => (document.body.style.touchAction = 'unset');
  }, [isEditCalories]);

  const handleSave = (e) => {
    if(lastLoginUrl === '/shop/track-quiz'){
      setIsLoading(true);
      user?.type.includes('visitor') && setupLocalUser().then(() => {
        setIsLoading(false);
        history.push('/shop/pantry', { from: "/shop/account/health-goal" })
        setRouteBeforeSignup('/shop/pantry')
      })
    }
    else {
      setActiveModalStack([]);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, y: '100%' }}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        <RecommendedCaloriesStyle window={window} theme={theme}>
          <div className="containerr">
            <Swiper
              grabCursor={true}
              effect={'creative'}
              creativeEffect={{
                prev: {
                  translate: ['-70%', 0, -1],
                  opacity: 0,
                },
                next: {
                  translate: ['100%', 0, 0],
                  opacity: 0,
                },
              }}
              pagination={{
                clickable: true,
              }}
              modules={[EffectCreative]}
              className="mySwiper"
              loop={true}
              speed={300}
            >
              <SwiperSlide className={'first'} onClick={(e) => e.stopPropagation()}>
                <div>
                  <h2 className="title">Your daily target calories</h2>
                  <p className="subtitle">Swipe right to see your Macro</p>
                  <div className="user-progress">
                    <Pie
                      width={260}
                      height={260}
                      titleNumber={newCalories}
                      titleText="Calories"
                      data={pieData}
                      marginTop={5}
                      marginRight={5}
                      marginBottom={5}
                      marginLeft={5}
                      innerRadius={0.75}
                      OuterRadiusHover={5}
                      colors={['#EE786B', '#8ACEBF', '#2399BF']}
                    />
                    <div className="sliderPoint">
                      <SliderPoints slide={'first'} />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <h2 className="title">Your daily target calories</h2>
                  <p className="subtitle">Swipe left to see your Calories</p>
                  <div className="user-progress">
                    <h3 className="macro_title">Macros</h3>
                    <div className="progress_holder">
                      {progressLines?.map((item) => {
                        return (
                          <div className="progress">
                            <div className="titlee">{item?.label}</div>
                            <div className="titlee value">{item?.gram}</div>
                            <div className='progress_line_holder'>
                            <div
                              className="progress_line"
                              style={{ backgroundColor: item?.color, width: item?.percent + '%' }}
                            ></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sliderPoint">
                      <SliderPoints />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="edit_calories">
            <p
              onClick={() => {
                setIsEditCalories(true);
                setIsAnimation(true);
              }}
            >
              Edit target calories
            </p>
            <Edit
              className="edit-img"
              onClick={() => {
                setIsEditCalories(true);
                setIsAnimation(true);
              }}
            />
          </div>
          <div className="button_holder">
            <Button type={'mobile_save'} title={'Save'} onClick={handleSave} isDisabled={loading}/>
          </div>
        </RecommendedCaloriesStyle>
      </motion.div>
      {isEditCalories && (
        <EditCalories
          isAnimation={isAnimation}
          setIsAnimation={setIsAnimation}
          setIsEditCalories={setIsEditCalories}
          newCalories={newCalories}
          setNewCalories={setNewCalories}
        />
      )}
    </>
  );
}
