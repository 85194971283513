import { DAYS_OF_WEEK } from '../../constants';

import {
  add,
  isBefore,
  setDay,
  format,
  set,
  eachDayOfInterval,
  eachHourOfInterval,
  eachMinuteOfInterval
} from 'date-fns';
import { chain, isNil } from 'ramda';
import moment from 'moment';
const toPair = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return { hours, minutes };
};
export const toTimeString = (d) => d.toTimeString().substr(0, 5);
export const toDateAndTimeString = (d) => `${toTimeString(d)} ${format(d, 'EEE LLL dd')}`;
export const toDateString = (d) => `${format(d, 'EEE, LLL dd')}`;
export const removeYearFromDate = (d) => d.substr(13, 16);
export const convertTimeTo12HrFormat = (d) => {
  let hours = d.split(':')[0];
  const AmOrPm = hours >= 12 ? 'pm' : 'am';
  hours = (hours % 12) || 12;
  const minutes = d.split(':')[1];
  return hours + ":" + minutes + " " + AmOrPm; 
}
const getScheduleData = (schedule, type, date) => {
  const day = date.getDay();
  const dayString = DAYS_OF_WEEK[day];
  const daySchedule = schedule?.[dayString]?.[type] || {};
  if (type === 'pickup' && daySchedule) {
    const { isPickupOn = true } = daySchedule;
    return isPickupOn ? daySchedule : {};
  } else if (type === 'delivery' && daySchedule) {
    const { isDeliveryOn = true } = daySchedule;
    return isDeliveryOn ? daySchedule : {};
  } else {
    return daySchedule;
  }
};

// detrmines if date is lead time away from now
export const isAfterLeadTime = (schedule, type, date) => {
  const newDate = new Date(date);
  const { start, end, lead ,isDeliveryOn, isPickupOn,cutoffDay,cutoffTime} = getScheduleData(schedule, type, newDate);
  if (cutoffDay && cutoffTime) return true
  if (!start || !end || type === 'delivery' ? !isDeliveryOn : !isPickupOn) return false;
  if (!lead) return true;

  const afterLead = add(new Date(), { hours: parseInt(lead) });
  return isBefore(afterLead, date);
};
export const isBeforeCutoff = (schedule, type, date) => {
  const newDate = new Date(date);
  // if(!schedule[dayString]?.[type])
  const { start, end, cutoffDay, cutoffTime } = getScheduleData(schedule, type, newDate);

  // If no delivery that day always false
  if (!start || !end) return false;

  // If no cutoff always true
  if (isNil(cutoffDay) || isNil(cutoffTime)) return true;

  const { hours, minutes } = toPair(cutoffTime);
  const cutoffDateDay = setDay(newDate, parseInt(cutoffDay), {
    weekStartsOn: parseInt(cutoffDay),
  });
  const cutoffDate = set(cutoffDateDay, {
    hours,
    minutes,
  });
  return isBefore(new Date(), cutoffDate);
};

export const isValidOrderDate = (schedule, type, date) =>
  isAfterLeadTime(schedule, type, date) && isBeforeCutoff(schedule, type, date);

const getHoursForDay = (schedule, type, date) => {
  const { start, end } = getScheduleData(schedule, type, date);
  if (!start || !end) return [];
  const { hours: startHours, minutes: startMinutes } = toPair(start);
  const { hours: endHours, minutes: endMinutes } = toPair(end);
  try {
    const intervalStart = set(date, { hours: startHours, minutes: startMinutes });
    const intervalEnd = set(date, { hours: endHours, minutes: endMinutes });
    const intervals = eachMinuteOfInterval({ start: intervalStart, end: intervalEnd }).map(minute => {
      return set(minute, { minutes: startMinutes });
    });
    return intervals;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const timesInNextN = (n, schedule, type) => {
  const days = eachDayOfInterval({
    start: new Date(),
    end: add(new Date(), { days: n }),
  });
  return chain((d) => getHoursForDay(schedule, type, d), days);
};
export const validInNextNDays = (n, schedule, type) => timesInNextN(n, schedule, type)
    .filter((d) => isValidOrderDate(schedule, type, d))
    .filter(date => {
      return new Date(date).getTime() > new Date().getTime();
    });

    export const getEarliestTime = (schedule, type) => {
      const validDates = validInNextNDays(14, schedule, type);
      if (validDates.length === 0) {
        return null;
      }
    
      const earliestDate = validDates[0];
      const { end, lead, cutoffTime ,cutoffDay} = getScheduleData(schedule, type, new Date(earliestDate));
      const leadHours = parseInt(lead);
      const day = new Date().getDay();
      let earliestTime;
      if (cutoffTime && Number(day) === Number(cutoffDay)) {
        const { hours: cutoffHours, minutes: cutoffMinutes } = toPair(cutoffTime);
        const cutoffDateTime = new Date(earliestDate);
        cutoffDateTime.setHours(cutoffHours);
        cutoffDateTime.setMinutes(cutoffMinutes);

        // If current time is before cutoff time, consider cutoff time
        if (new Date() < cutoffDateTime) {
          earliestTime = cutoffDateTime;
        } else {
          // If current time is after cutoff time, consider end time only
          const { hours: endHours, minutes: endMinutes } = toPair(end);
          earliestTime = new Date(earliestDate);
          earliestTime.setHours(endHours, endMinutes);
        }
      } else {
        // If no cutoff time, consider lead time
        const { hours: endHours, minutes: endMinutes } = toPair(end);
        earliestTime = new Date(earliestDate);
        leadHours ? earliestTime.setHours(endHours - leadHours, endMinutes) : earliestTime.setHours(endHours, endMinutes);
      }
    return earliestTime;
    };
    
    
export const getSelectionOptions = (schedule, type, date) => {
  const newDate = new Date(date);
  const day = newDate.getUTCDay();
  const dayString = DAYS_OF_WEEK[day];

  const { start, end } = schedule[dayString]?.[type];
  return start && end ? [start + ' - ' + end] : [];
};


export function getNextDeliveryAndPickup(schedule,method) {
  const availableDates = validInNextNDays(14, schedule, method).reduce((acc, date) => {
    const formattedDate = moment(date).format('ddd, ll');
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(date);
    return acc;
  }, {});

  const uniqueDates = new Set();
  for (let key in availableDates) {
    const data = availableDates[key];
    const formattedDate = moment(key).format('MMM D, dddd');
    const timeRange = `${moment(data[0]).format('hh:mm A')}-${moment(data[data.length-1]).format('hh:mm A')}`;
    const item = `${formattedDate}, ${timeRange}`;
    uniqueDates.add(timeRange);
  }
  const sortedDates = Array.from(uniqueDates).sort();
  if (sortedDates.length > 0) {
    const earliestDate = sortedDates[0];
    return [earliestDate];
  } else {
    return "N/A";
  }
}





