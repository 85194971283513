import { OrderItemWrapper } from './style';

export default function OrderItem({ order }) {
  const { img, quantity, name, price } = order;

  return (
    <OrderItemWrapper>
      <div className="order-image-title">
        <div className="order-img-wrapper">
          <img
            src={ img ? img :  '/images/Kitchen-image.png'}
            alt="Order image"
          />
          <div className="qty-wrapper">{quantity}</div>
        </div>
        <div className="order-name">{name}</div>
      </div>
      <div className="order-price">${price}</div>
    </OrderItemWrapper>
  );
}
