import React from 'react';

const VegetarianSvg = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_3899_35106)">
        <path
          d="M18.9653 0.470327C18.9091 0.250302 18.7353 0.0802831 18.5165 0.0240268C18.2978 -0.0334796 18.0615 0.034028 17.9052 0.197796C17.8952 0.209047 16.8351 1.28167 13.2935 2.63932C12.0521 3.11187 11.1482 4.01322 10.7507 5.17335C10.4244 6.12096 10.2981 7.68613 11.5445 9.74886C11.5583 9.77261 11.5895 9.78012 11.6058 9.80137C10.7594 11.7366 10.2231 13.7256 9.89933 15.387C7.07527 2.72183 1.75218 0.166543 1.50715 0.0552803C1.19212 -0.0859854 0.822078 0.0527801 0.678312 0.366565C0.537046 0.68035 0.675812 1.05164 0.989596 1.19291C1.0496 1.22041 7.00026 4.08448 9.39553 19.4712C9.44304 19.7762 9.70681 20 10.0131 20C10.0256 20 10.0368 20 10.0481 19.9988C10.3694 19.98 10.6244 19.7225 10.6382 19.4024C10.6407 19.3387 10.8732 14.5707 12.7847 10.2264C13.0409 10.2652 13.3247 10.2964 13.656 10.2964C15.0762 10.2964 17.0689 9.78262 18.6466 7.3836C18.6578 7.36485 18.6691 7.34734 18.6791 7.32734C20.0292 4.71455 19.0091 0.641596 18.9653 0.470327ZM17.5827 6.72477C16.1763 8.84376 14.4361 9.12629 13.3485 9.05128C13.9223 7.96491 14.6136 6.9373 15.4525 6.05595C15.69 5.80592 15.6812 5.40963 15.4299 5.1721C15.1812 4.93708 14.7849 4.94333 14.5461 5.1946C13.6322 6.15471 12.8922 7.26608 12.2721 8.42746C11.7833 7.37985 11.6508 6.40224 11.9333 5.57965C12.2096 4.77456 12.8509 4.14574 13.7398 3.80445C15.8925 2.97936 17.1951 2.24802 17.944 1.74547C18.1465 3.00561 18.3428 5.22336 17.5827 6.72477Z"
          fill={color || "#757E89"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3899_35106">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VegetarianSvg;
