import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { ReactComponent as CalendarIcon } from 'assets/calendarTime.svg';

const DeliveryDayHoursStyle = styled.div`
  display: flex;
  width: 100%;
  .text {
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
  }
  .day {
    font-weight: 600;
    margin-top: 11px;
  }
  .from_to {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    .placeholder {
      color: ${({ theme }) => theme?.colors?.blackLight};
      border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
      background: ${({ theme }) => theme?.colors?.white};
      padding: 10px 8px;
      border-radius: 6px;
      max-width: 240px;
      width: 100%;
      height: 40px;
      span {
        color: ${({ theme }) => theme?.colors?.blackDefault};
        margin-left: 8px;
      }
    }
  }
  .order_settings {
    padding-left: 16px;
    border-left: 2px solid ${({ theme }) => theme?.colors?.grayLight};
    .order_settings_title {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${({ theme }) => theme?.colors?.grayDarker};
      margin-bottom: 21px;
    }
    .order_settings_day {
      font-weight: 600;
      margin-bottom: 24px;
    }
    .order_settings_hours_title {
      color: ${({ theme }) => theme?.colors?.grayDark};
      font-weight: 600;
      margin-bottom: 6px;
    }
  }
`;

export const DeliveryDayHours = ({ day, data, days }) => {
  const { lead, cutoffTime, end, start, cutoffDay } = data;
  const isSameDaySelected = data.lead && !data.cutoffDay;
  const formatTime = (time) => {
    if (!time) return '';
    const [hour, minute] = time.split(':');
    const hourIn12Format = hour % 12 === 0 ? 12 : hour % 12;
    const amPm = hour < 12 ? 'AM' : 'PM';
    return `${hourIn12Format}:${minute.padStart(2, '0')} ${amPm}`;
  };
  const daysValue = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return (
    <DeliveryDayHoursStyle theme={theme}>
      <div className="left">
        <h3 className="text day">{day}</h3>
      </div>
      <div className="right">
        <div className="from_to">
          <div className="text placeholder">
            From<span>{formatTime(start)}</span>
          </div>
          <div className="text placeholder">
            To<span>{formatTime(end)}</span>
          </div>
        </div>
        <div className="order_settings">
          <div className="order_settings_title text">
            <CalendarIcon className="icon" /> Order Settings
          </div>
          <h3 className="text order_settings_day">{isSameDaySelected ? "Same day" : "Cut-off time"}</h3>
          {isSameDaySelected ? (
            <>
              <h3 className="text order_settings_hours_title">Lead hours</h3>
              <p className="text order_settings_lead_hours">{lead} hr</p>
            </>
          ) : (
            <div style={{ display: "flex", width: "200px", placeContent: "space-between" }}>
              <div>
                <h3 className="text order_settings_hours_title">Day</h3>
                <p className="text order_settings_lead_hours">  {cutoffDay && days && cutoffDay >= 0 && cutoffDay <= days.length
        ? daysValue[cutoffDay]
        : ""}</p>
              </div>
              <div>
                <h3 className="text order_settings_hours_title">Time</h3>
                <p className="text order_settings_lead_hours">{formatTime(cutoffTime)}</p>
              </div>
            </div>
          )}

        </div>
      </div>
    </DeliveryDayHoursStyle>
  );
};
