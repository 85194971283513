import React ,{useEffect} from 'react'
import styled from 'styled-components'
import {ReactComponent as SadEmojiBlue} from '../assets/sad-emoji-blue.svg';
import Header from 'layout/Header';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';

const ClaimAccountStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: calc(100vh - 52px);
    height: 100%;
    width: 100%;
    background-color: #F6F5F8;
    margin-top: 52px;
    .container{
        font-family: 'Roboto';
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 53px 26px 48px;
        text-align: center;
        max-width: 627px;
        width: 100%;
        .heading{
          margin-top: 40px;
          margin-bottom: 16px;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #51635D;
        }
        .sub_heading{
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #51635D;
          margin-bottom: 24px;
        }
        .claim_back{
          padding: 13px;
          background: #52C41A;
          border-radius: 21px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          cursor: pointer;
          max-width: 211px;
          width: 100%;
        }
    }
    .progress{
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
    @media (min-width: 991px){
      min-height: calc(100vh - 52px - 140px);
      .container{
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 48px 26px 48px;
        .heading{
          margin-bottom: 15px;
        }
        .sub_heading{
          margin-bottom: 55px;
        }
        .claim_back{
          padding: 20px;
          max-width: 281px;
          border-radius: 28px;
        }
      }
    }
`

export const ClaimSuspendedAccount = ({
  isUserSuspended, 
  heading1 = 'Oops!', 
  heading2 = 'Suspended due to lack of activity.', 
  showButton = true,
  isProvider
}) => {
  const history=useHistory();
  const [claimBackAccount, { isLoading, error  }] = useRequest('admin/send-email','get');
  useEffect(() => {

    if (!isUserSuspended) {
      history.push("/")
      return;
    }

    document.body.classList.add('hide-scroll');
    return () => {
      document.body.classList.remove('hide-scroll');
    }
  }, [])
  const claimAccount = async () => {
    const response = await claimBackAccount();
    if(error){
      toast.error("Can't send account recovery request. Please try again later.");
      return;
    }
    toast.success("Account recovery request successfully sent to admin.");
  }
  return (
    <>
    <Header/>
    <ClaimAccountStyle>
        <div className='container'>
            <SadEmojiBlue />
            <h2 className='heading'>{heading1}</h2>
            <p className='sub_heading'>{isProvider ? "Your account is suspended. Please contact the admin." : heading2}</p>
            {showButton && !isProvider && <button className='claim_back' onClick={claimAccount}>Claim back your account</button>}
        </div>
        {isLoading &&
        <div className='progress'>
          <CircularProgress style={{ color: 'var(--nutritt-green)'}} />
        </div>
        }
    </ClaimAccountStyle>
    </>
  )
}
