import { memo } from 'react';
import { motion } from "framer-motion";
import { InputMessageWrapper } from './style';
import styled from 'styled-components';

const InputMessageErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    color: #EB3223;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
      }
`;


function InputErrorMessage({ errorMessage }) {
  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, x: 100}}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: 0.5,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    >
      <InputMessageErrorWrapper className={`ErrorMessage`}>
        <p>{errorMessage}</p>
      </InputMessageErrorWrapper>
    </motion.div>
  );
}

export default memo(InputErrorMessage);