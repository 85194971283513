import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { UserProfileCard } from './UserProfileCard';
import { Button } from 'components/elements/button/Button';
import { MdEdit } from 'react-icons/md';
import RegularInput from 'components/Input/RegularInput';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router';
import Alert from 'components/elements/alert/Alert';
import { useKitchenContext } from 'context/kitchenContext';

const ProfileStyle = styled.div`
  padding: 25px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .alert {
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top: 12px;
  }
  .main_heading {
    font-size: ${({ theme }) => theme?.typography?.fontXl};
    font-weight: 600;
    line-height: 32px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
    margin-bottom: 20px;
  }
  .details_title {
    font-size: ${({ theme }) => theme?.typography?.fontBase};
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 24px;
  }
  .container {
    padding: 42px 36px 0px 36px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    background: #fff;
    height: 540px;
    display: block;
    overflow: auto;
    position: relative;
    .btn_flex {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 0;
      top: 0;
      .custom_button_edit {
        position: relative;
        &:last-child {
          background: ${({ theme }) => theme?.colors?.primaryDefault};
          color: ${({ theme }) => theme?.colors?.white};
        }
      }
    }
  }
  .custom_button_edit {
    padding: ${({ isEdit }) => (isEdit ? '8px 8px 8px 16px' : '8px 16px')};
    font-size: ${({ theme }) => theme?.typography?.fontSmall};
    font-weight: 600;
    line-height: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
    color: ${({ theme }) => theme?.colors?.primaryDark};
    background: ${({ theme }) => theme?.colors?.white};
    position: absolute;
    right: 45px;
    top: 185px;
    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
  .flex {
    display: flex;
    gap: 8px;
    max-width: 660px;
  }
  .iconBack {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    margin-top: 4px;
  }
`;
const FormGroupStyle = styled.div`
  margin-bottom: 24px;
  width: 100%;
  label {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.grayDark};
    margin-bottom: 8px;
    display: inline-block;
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
`;
function Profile() {
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();
  const { user, isSuper } = useAppContext();
  const { kitchenProvider: provider, setKitchenProvider: setProvider } = useKitchenContext();
  const [updatedProvider] = useRequest(`provider/update`, 'post');
  const profileEmail = isSuper ? provider?.user?.email : user?.email;
  const [providerImage, setProviderImage] = useState(provider?.images?.[0] || '');
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });

  const [initialProfile, setInitialProfile] = useState({
    firstName: provider?.name||'',
    lastName: provider?.lastName || '',
    email: profileEmail,
    phone: provider?.phoneNumber,
    role: 'admin',
  });

  const [updatedProfile, setUpdatedProfile] = useState({ ...initialProfile });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const numericValue = value.replace(/\D/g, '');
      const truncatedValue = numericValue.slice(0, 13);

      setUpdatedProfile((prevState) => ({
        ...prevState,
        [name]: truncatedValue,
      }));
    } else if ((name === 'firstName', 'lastName')) {
      if (value.length > 256) {
        return;
      }
      setUpdatedProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setUpdatedProfile((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleClick = () => {
    history.push('/kitchen');
  };

  const handleSave = async (updatedProfile) => {
    try {
      const updatedBody = {
        id: provider?.id,
        providerEmail: profileEmail,
        images: [providerImage],
        phoneNumber: updatedProfile?.phone,
        name: updatedProfile.firstName,
        lastName: updatedProfile.lastName
      };
      await updatedProvider({ body: updatedBody });
      setProvider((prev) => ({
        ...prev,
        images: [providerImage],
        name: updatedProfile?.firstName || prev.firstName,
        lastName:updatedProfile?.lastName || prev.lastName,
        phoneNumber: updatedProfile?.phone || prev.phoneNumber,
      }));
      setInitialProfile((prev) => ({
        ...prev,
        images: [providerImage],
        name: updatedProfile?.firstName || prev.firstName,
        lastName:updatedProfile?.lastName || prev.lastName,
        phoneNumber: updatedProfile?.phone,
      }));
      showSuccessMessage('You have successfully updated personal profile.');
      setIsEdit(false);
    } catch (error) {
      if (error.includes("An account is already")) {
        return showErrorMessage(error);
      }
      showErrorMessage('Error occurred while saving changes');
    }
  };

  const handleCancel = () => {
    setUpdatedProfile({ ...initialProfile });
    setIsEdit(false);
  };
      const isMountedRef = useRef(false);
      useEffect(() => {
        if (isMountedRef.current) {
          handleSave(updatedProfile);
        } else {
          isMountedRef.current = true;
        }
      }, [providerImage]);

  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  useEffect(() => {
    isSuper && sessionStorage.setItem('imitateProvider', JSON.stringify(provider));
    const updatedData = {
      firstName: provider?.name || '',
      lastName: provider?.lastName || '',
      phone: provider?.phoneNumber || '',
      email: isSuper ? user?.email : provider?.email,
    };
    setInitialProfile((prevState) => ({
      ...prevState,
      ...updatedData,
    }));
    setUpdatedProfile((prevState) => ({
      ...prevState,
      ...updatedData,
    }));
  }, [provider, isSuper]);

  return (
    <ProfileStyle theme={theme}>
      {alertData.type && (
        <div className="alert">
          <Alert
            type={alertData.type}
            title={alertData.title}
            width="461px"
            onClose={() => setAlertData({ type: '', title: '' })}
          />
        </div>
      )}
      <div className="flex" onClick={handleClick}>
        <img src="/icons/arrow-left.svg" alt="Arrow Left" className="iconBack" />
        <h2 className="main_heading">Personal Profile</h2>
      </div>
      <div className="container">
        <UserProfileCard
          providerImage={providerImage}
          setProviderImage={setProviderImage}
          handleSave={handleSave}
          name={provider?.name}
        />
        <h3 className="details_title">Personal Information</h3>
        {!isEdit ? (
          <Button
            title="Edit"
            className="custom_button_edit"
            isEdit={isEdit}
            onClick={() => setIsEdit(true)}
            iconRight={<MdEdit />}
          />
        ) : (
          <div className="btn_flex" isEdit={isEdit}>
            <Button
              title="Cancel"
              className="custom_button_edit"
              isEdit={isEdit}
              onClick={handleCancel}
            />
            <Button
              title="Save"
              className="custom_button_edit"
              isEdit={isEdit}
              onClick={() => handleSave(updatedProfile)}
            />
          </div>
        )}
        <div className="flex">
          <FormGroupStyle theme={theme}>
            <label>First name</label>
            {!isEdit ? (
              <p className="value">
                {updatedProfile.firstName.length > 30
                  ? updatedProfile.firstName.replace(/(.{30})..+/, '$1') + '...'
                  : updatedProfile.firstName}
              </p>
            ) : (
              <RegularInput
                type="text"
                name="firstName"
                onChange={handleChange}
                value={updatedProfile.firstName}
              />
            )}
          </FormGroupStyle>

          <FormGroupStyle theme={theme}>
            <label>Last name</label>
            {!isEdit ? (
              <p className="value">
                {updatedProfile.lastName.length > 30
                  ? updatedProfile.lastName.replace(/(.{20})..+/, '$1') + '...'
                  : updatedProfile.lastName}
              </p>
            ) : (
              <RegularInput
                type="text"
                name="lastName"
                onChange={handleChange}
                value={updatedProfile.lastName}
              />
            )}
          </FormGroupStyle>
        </div>
        <div className="flex">
          <FormGroupStyle theme={theme}>
            <label>Email address</label>
            {!isEdit ? (
              <p className="value">{profileEmail}</p>
            ) : (
              <RegularInput
                type="text"
                name="email"
                onChange={handleChange}
                value={profileEmail}
                readOnly
              />
            )}
          </FormGroupStyle>
          <FormGroupStyle theme={theme}>
            <label>Phone</label>
            {!isEdit ? (
              <p className="value">{updatedProfile.phone}</p>
            ) : (
              <RegularInput
                type="text"
                name="phone"
                onChange={handleChange}
                value={updatedProfile.phone}
              />
            )}
          </FormGroupStyle>
        </div>
        <FormGroupStyle theme={theme}>
          <label>Role</label>
          {!isEdit ? (
            <p className="value">{updatedProfile.role}</p>
          ) : (
            <RegularInput
              type="text"
              style={{ maxWidth: '325px' }}
              value={updatedProfile.role}
              readOnly
            />
          )}
        </FormGroupStyle>
      </div>
    </ProfileStyle>
  );
}

export default Profile;