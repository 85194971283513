import React from 'react';
import MobileHeader from 'components/elements/MobileHeader';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'assets/shop/check-gray.svg';

const SuccessStyle = styled.div`
  background-color: #fff;
  height: 100vh;
  .mobile-header {
    height: 56px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    .icon-wrapper {
      width: 40px;
      height: 40px;
    }
  }
  .check-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 81px 0px 0px;
  }
  .success-heading {
    color: #0b1d17;
    padding: 17px 0px 9px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
  .success-text {
    color: #757e89;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Success = ({ setIsOpenSuccessMsg, setIsPromoCodeOpen }) => {
  return (
    <SuccessStyle>
      <MobileHeader className={'mobile-header'} onClickLeft={() => {setIsOpenSuccessMsg(false), setIsPromoCodeOpen(false)}} />
      <div className="check-img">
        <CheckIcon alt="check-icon" />
      </div>
      <div className="success-heading">Successful!</div>
      <div className="success-text">
        Your Nutritt cash has been successfully <br />
        added to your account
      </div>
    </SuccessStyle>
  );
};

export default Success;
