import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from '../../../shared/theme/theme';
import { ReactComponent as House } from 'assets/shop/house.svg';
import { ReactComponent as Building } from 'assets/shop/building.svg';
import { ReactComponent as Gym } from 'assets/shop/gym.svg';

const AddressBlockStyle = styled.div`
padding: 20px 14px;
background: ${({ theme, selected }) => selected ? "#F0F2F4" : theme?.colors?.white};
border-bottom: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
align-items: flex-start;
transition: all 0.3s;
display: flex;
justify-content: space-between;
&:active {
    background: rgba(245, 247, 248, 0.60);
  }
  &:hover{
    background-color: rgba(245, 247, 248, 0.60);
}
.main{
    display:flex;
    gap: 14px;
}
.info{
    white-space: pre-line;
    display: flex;
    flex-direction: column;
}
.title{
    color: ${({ theme }) => theme?.colors?.primaryDarker};
font-family: ${({ theme }) => theme?.typography?.fontGilroy};
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.address{
    color: ${({ theme }) => theme?.colors?.primaryDarker};
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18px;
width: 256px;
}
.icon{
    width: 20px;
height: 20px;
display: block;
}
.editicon{
    width: 25px;
    height: 25px;
}
&.not_deliverable{
    background: #FEF1F0;
    .title{
        color: #D13726;
    }
    .address{
        color: #D13726;
    }
    .icon{
        path{
            stroke: #D13726;
        }
    }
}
`

export const AddressBlock = ({title="Address Name", address="2 rows of address 2 rows of address 2 rows of address", onClick, onEditClick, type="other", isNotDeliverable = false, selected=false }) => {

  return (
    <AddressBlockStyle theme={theme} onClick={(event) => {event.stopPropagation(); onClick()}} className={isNotDeliverable ? 'not_deliverable' : ''} selected={selected}>
        <div className='main'>
    {type === "home" && <House className="icon"/>}
    {type === "office" && <Building className="icon"/>}
    {type === "gym" && <Gym className="icon"/>}
    {type === "other" && <Building className="icon"/>}
    <div className='info'>
        <text className='title'>{title}</text>
        <label className='address'>{address}</label>
    </div>
    </div>
    <div className='editicon' onClick={(event) => {event.stopPropagation(); onEditClick()}}>
    <img src='/icons/edit2.svg'></img>
    </div>
    </AddressBlockStyle>
  )
}
