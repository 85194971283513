import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select from 'components/Select';
import { useAppContext } from 'context/appContext';
import styled from 'styled-components';

const SelectOptionsStyle = styled.div`
  margin-top: 24px;

  p {
    margin-top: 5px;
    color: #f00;
    text-align: center;
  }

  .title{
    span{
      font-weight: 700;
    }
  }

  .radio-buttons {
    width: 100%;
    display: flex;

    .static-number {
      width: 100%;
    }
  }

  @media (min-width: 992px) {
    margin: 0 0 32px;
  }
`;

export default function SelectOptions({
  title,
  Icon,
  options,
  id,
  value,
  setValue,
  valueCondition,
  customOptionVal,
}) {
  const [isCustomValueEdit, setIsCustomValueEdit] = useState(false);
  const [isCustomValue, setIsCustomValue] = useState(!options.includes(value));
  const [isSelectedValue, setIsSelectedValue] = useState(false);
  const [customValue, setCustomValue] = useState(value);
  const [isDaysFalse, setIsDaysFalse] = useState(false);
  const initialValue = value;
  const { isMobile } = useAppContext();
  const mobViewStyle = isMobile ? { marginTop: '0px', marginLeft: '10px' } : undefined;

  const createNumbers = Array.from(Array(31).keys()).slice(customOptionVal[0], customOptionVal[1]);
  const selectOptionsCustom = ['Custom', ...createNumbers];

  const generateCustomValue = (e) => {
    let value = e.target.valueAsNumber;
    if (value < valueCondition) value = valueCondition;
    setCustomValue(value);
    setValue(value);
    if (e.target.value < 4) {
      setIsDaysFalse(true);
      setTimeout(() => {
        setIsDaysFalse(false);
      }, 2500);
    }
  };

  useEffect(() => {
    if (!options.includes(initialValue)) {
      setIsCustomValue(true);
      setCustomValue(initialValue);
    }
  }, [initialValue]);

  return (
    // return isMobile ? (
    <SelectOptionsStyle>
      <div className="days-wrapper">
        <div className="title">
          <img src={Icon} alt="dinner" /> <span>{title}</span>
        </div>
        <div className="radio-button-wrapper">
          <div className="radio-buttons">
            {options.map((option) => (
              <div
                key={option}
                className={classNames(
                  'radio-button-container static-number',
                  option === value && !isCustomValue && 'radio-checked',
                )}
                onClick={() => {
                  setIsCustomValueEdit(false);
                  setIsCustomValue(false);
                  setValue(option);
                }}
              >
                <span className="numberText34">{option}</span>
              </div>
            ))}
            {/* {isMobile ? (
              <div
                style={mobViewStyle}
                className={classNames(
                  'radio-button-container custom-number',
                  isCustomValue && 'radio-checked',
                )}
                onClick={() => {
                  setIsCustomValueEdit(true);
                  setIsCustomValue(true);
                  setValue(customValue);
                }}
              >
                {isCustomValue ? (
                  <div>
                    {isCustomValueEdit ? (
                      <input
                        autoFocus
                        type="number"
                        maxLength={8}
                        min="1"
                        value={customValue}
                        onChange={generateCustomValue}
                        className="custom-input"
                        onBlur={() => setIsCustomValueEdit(false)}
                      />
                    ) : (
                      <span>
                        {isNaN(value) || (id === 'days' && value < 4) ? valueCondition : value}
                      </span>
                    )}
                    <span>{` ${id}`}</span>
                  </div>
                ) : (
                  <span onClick={() => setIsCustomValueEdit(true)}>Custom</span>
                )}
              </div>
            ) : ( */}
              <div
                className={classNames(
                  'radio-button-container custom-number',
                  isCustomValue && 'radio-checked',
                )}
              >
                <Select
                  options={selectOptionsCustom}
                  updateValue={(value) => {
                    setCustomValue(value);
                    setValue(value);
                    typeof value == Number && setIsCustomValue(true);
                  }}
                  defaultChecked="Custom"
                />
              </div>
            {/* )} */}
          </div>
          {isDaysFalse && id === 'days' && <p>Minimum 4 Days Required</p>}
        </div>
      </div>
    </SelectOptionsStyle>
    // ) : (
    // <SelectOptionsStyle>
    //   <div className="days-wrapper">
    //     <div className="title">
    //       {' '}
    //       <img src={Icon} alt="dinner" /> {title}
    //     </div>
    //     <div className="radio-button-wrapper">
    //       <div className="radio-buttons">
    //         {options.map((option) => (
    //           <div
    //             key={option}
    //             className={classNames(
    //               'radio-button-container static-number',
    //               option === value && !isCustomValue && 'radio-checked',
    //             )}
    //             onClick={() => {
    //               setIsCustomValueEdit(false);
    //               setIsCustomValue(false);
    //               setValue(option);
    //             }}
    //           >
    //             <span>
    //               {option} {id}
    //             </span>
    //           </div>
    //         ))}
    //       </div>
    //       <div
    //         className={classNames(
    //           'radio-button-container custom-number',
    //           isCustomValue && 'radio-checked',
    //         )}
    //         onClick={() => {
    //           setIsCustomValueEdit(true);
    //           setIsCustomValue(true);
    //           setValue(customValue);
    //         }}
    //       >
    //         {isCustomValue ? (
    //           <div>
    //             {isCustomValueEdit ? (
    //               <input
    //                 autoFocus
    //                 type="number"
    //                 maxLength={8}
    //                 min="1"
    //                 value={customValue}
    //                 onChange={generateCustomValue}
    //                 className="custom-input"
    //                 onBlur={() => setIsCustomValueEdit(false)}
    //               />
    //             ) : (
    //               <span>
    //                 {isNaN(value) || (id === 'days' && value < 4) ? valueCondition : value}
    //               </span>
    //             )}
    //             <span>{` ${id}`}</span>
    //           </div>
    //         ) : (
    //           <span onClick={() => setIsCustomValueEdit(true)}>Custom</span>
    //         )}
    //       </div>
    //       {isDaysFalse && id === 'days' && <p>Minimum 4 Days Required</p>}
    //     </div>
    //   </div>
    // </SelectOptionsStyle>
  );
}
