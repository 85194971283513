import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import Tab from 'components/elements/tab/Tab';
import Overview from './components/Overview';
import Items from './components/Items/index';
import { motion, AnimatePresence } from 'framer-motion';
import { useKitchenContext } from 'context/kitchenContext';

const MenuStyle = styled.section`
  overflow: auto;
  height: 100vh;
  padding-top: 28px;
  padding-bottom: 28px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  .page_title {
    font-size: ${({ theme }) => theme?.typography?.fontXl};
    font-weight: 600;
    line-height: 32px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  .tabs {
    margin: 24px 0px 12px 0px;
    position: relative;
    .nav_item {
      background-color: transparent;
      color: ${() => theme?.colors?.blackLight};
      .text {
        font-weight: 500;
      }
      &.active {
        color: ${() => theme?.colors?.primaryDefault};
      }
    }
    .tab_container {
      height: 32px;
      place-items: center;
    }
  }
`;
export const Menu = () => {
  const { activeTab, setActiveTab, setCategoryList, categoryList, setMealData, setSelectedMealId,defaultMealState } = useKitchenContext();
  
  const menuTabs = [
    {
      id: 0,
      title: 'Overview',
      onClick: () => {},
      render: (categoryList, setCategoryList) => <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><Overview categoryList={categoryList} setCategoryList={setCategoryList}/></motion.div>,
    },
    {
      id: 1,
      title: 'Items',
      onClick: () => {},
      render: (categoryList, setCategoryList) => <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><Items categoryList={categoryList} setCategoryList={setCategoryList}/></motion.div>,
    },
  ];
  const [selectedTabId, setSelectedTabId] = useState(menuTabs[0].id);

  useEffect(() => {
    if(activeTab === 'items'){
      setSelectedTabId(1);
      setActiveTab('');
    }
    if(activeTab === 'overview'){
      setSelectedTabId(0);
      setActiveTab('');
    }
  }, [activeTab])

  useEffect(() => {
    setSelectedMealId(null);
    setMealData(defaultMealState);
  },[])

  return (
    <>
      <MenuStyle theme={theme}>
        <h2 className="page_title">Menu</h2>
        <div className="tabs">
          <Tab
            type="horizontal"
            navItems={menuTabs}
            selectedTabId={selectedTabId}
            setSelectedTabId={setSelectedTabId}
          />
        </div>
        <div>
          <AnimatePresence exitBeforeEnter>
            <motion.div className='tabPanel' key={selectedTabId}>
              {menuTabs[selectedTabId].render(categoryList, setCategoryList)}
            </motion.div>
          </AnimatePresence>
        </div>
      </MenuStyle>
    </>
  );
};
