import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const OrderNumberStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;

  .order-number-status {
    display: flex;
    align-items: center;
    gap: 8px;
    .order-number {
      color: ${theme.colors.blackDefault};
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
    .order-status {
      padding: 3px 8px;
      background-color: #a0deff;
      color: ${theme.colors.deepForestGreen};
      border-radius: 80px;
      font-family: ${theme.typography.fontGilroy};
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      text-transform: capitalize;
      &.pending {
        background-color: #ffd580;
      }
      &.completed {
        background-color: #90f290;
      }
      &.refused {
        background-color: #ebebeb;
      }
    }
    .recurring-order-container {
      height: 24px;
      margin-left: 4px;
      padding: 4px 12px 4px 8px;
      background: #c2fdc2;
      border-radius: 80px;
      display: flex;
      align-items: center;
      gap: 4px;
      img {
        width: 16px;
        height: 16px;
      }
      .recurring-label {
        color: ${theme.colors.primaryDefault};
        font-family: ${theme.typography.fontGilroy};
        font-size: 11px;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .order-time {
    color: ${theme.colors.primaryDefault};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: lowercase;
  }
`;
