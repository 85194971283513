import { useState } from 'react';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import NutritionistReports from './NutritionistReports';
import NutritionistOfferingReport from './NutritionistOfferingReport';


const NutritionistReportsWrapper = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;

  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.form-group-section {
      width: 50%;

      &:first-child {
        margin-right: 75px;
      }
    }

    label {
      margin-bottom: 14px;
      color: #000000;
      font-size: 20px;
    }

    input {
      height: 46px;
      padding: 0 16px;
      font-size: 16px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;

      ::placeholder {
        color: #939b99;
      }
    }
  }

  @media (max-width: 991px) {
    width: 90%;
    margin: 60px auto;
    padding-bottom:25px !important;
    .MuiTabs-root{
      border-bottom: 1px solid #E0E3E0;
    
    .MuiTabs-flexContainer {
      display:flex;
      justify-content: space-evenly !important;;
    }
  }
   .MuiBox-root{
     padding:0 !important;
  }
    .Reports{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #51635D;
      margin-top:24px;
      text-align:center;
    }
    .form-div{
      background: #ffffff;
      margin-top:24px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }

  }
`;

{
  TabPanel;
}

export default function NutritionistPayReports() {
    const [activeReport, setActiveReport] = useState(0);

    const handleChange = (event, value) => {
        setActiveReport(value);
      };

  return (
    <NutritionistReportsWrapper>
     <div className='Reports'>
     <p>Reports</p>
     </div>
     <div className='form-div'>
      <form>
        <Tabs value={activeReport} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Meal plans" />
          <Tab label="Coaching" />
        </Tabs>
        <TabPanel value={activeReport} index={0} >
          <NutritionistReports/>
        </TabPanel>
        <TabPanel value={activeReport} index={1}>
          <NutritionistOfferingReport/>
        </TabPanel>
      </form>
      </div>
    </NutritionistReportsWrapper>
  );
}
