import styled from "styled-components";
import { theme } from '../../../shared/theme/theme';

export const PrimaryButtonStyle = styled.button`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    &:disabled{
        background-color: ${theme.colors.gray};
        color: ${theme.colors.gray};
        &::after{
            background-color: ${theme.colors.white} !important;
            opacity: 0.75 !important;
        }
    }
`

export const DangerButtonStyle = styled.button`
    background-color: ${theme.colors.danger};
    color: ${theme.colors.white};
    &:disabled{
        color: ${theme.colors.danger};
        &::after{
            background-color: ${theme.colors.white} !important;
            opacity: 0.9 !important;
        }
    }
`

export const SecondaryButtonStyle = styled.button`
    background-color: ${theme.colors.white};
    color: ${theme.colors.primaryDark};
    border: 1px solid ${theme.colors.gray} !important;
`

export const TertiaryButtonStyle = styled.button`
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary};
    &:disabled{
        color: ${theme.colors.gray};
        background-color: ${theme.colors.white};
        &::after{
            background-color: ${theme.colors.white} !important;
            opacity: 1 !important;
        }
    }
`

export const DarkButtonStyle = styled.button`
    border: 1px solid ${theme?.colors?.primaryDefault} !important;
    color: ${theme?.colors?.white};
    background-color: ${theme?.colors?.primaryDefault};
    &:disabled{
        background-color: ${theme.colors.lightGrey} !important;
        color: ${theme.colors.grayDark} !important;
        border: none !Important;
        cursor: no-drop;
        &::after{
        display: none;
    }
}
`