import styled from 'styled-components';
import useVisitorCart from 'hooks/useVisitorCart';
import { useVisitorContext } from 'context/visitorContext';
import useDisplayAmount from 'hooks/useDisplayAmount';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import MealCardContent from 'components/shop/MealCardContent';
import { useEffect, useRef } from 'react';

const MealCardDescriptionModalWrapper = styled.div`
  @media (min-width: 991px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 111;
    background-color: rgba(81 99 93 / 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    .meal-card-description-container {
      position: relative;
      overflow-y: auto;
      width: 800px;
      max-height: calc(100vh - 150px);
      padding: 54px 48px 48px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      display: flex;

      .close-icon-wrapper {
        position: absolute;
        top: 24px;
        right: 24px;

        img {
          cursor: pointer;
          width:25px;
         
        }
      }

      .meal-img-section {
        width: 240px;
        height: 100%;
        margin-right: 48px;

        .meal-img-wrapper {
          margin-bottom: 30px;

          img {
            width: 240px;
            height: 240px;
            object-fit: cover;
          }
        }
      }

      .meal-recomaneded-tags-wrapper {
        display: flex;
        justify-content: center;
      }

      .meal-recomaneded-tags {
        width: 180px;
        padding: 15px;
        background: #f0faeb;
        color: #51635d;
        font-size: 14px;
        line-height: 18px;
        border: 2px solid rgba(203, 237, 186, 0.5);
        border-radius: 6px;

        .tags {
          color: #52c41a;
          font-family: 'Roboto';

          .and {
            color: #51635d;
            font-family: RobRegular, 'Roboto';
          }
        }
      }

      .meal-info-section {
        height: 100%;

        h2 {
          margin-bottom: 14px;
          color: #0b1d17;
          line-height: 28px;
        }

        .price {
          color: #0b1d17;
          font-size: 24px;
          line-height: 28px;
        }
      }
      .fresh-card-content {
        width: 375px;
        margin: 32px 0 20px;
        padding: 7px 0;
        border: 1px solid #f6f5f8;
        border-radius: 5px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .fresh-card-container {
          margin: 0;
          border: 0;

          & + .fresh-card-container {
            border-left: 1px solid #f6f5f8;
          }

          span:first-child {
            font-family: 'Roboto';
          }
        }
      }

      .title {
        margin-bottom: 10px;
        font-size: 14px;
        font-family: 'Roboto';
        line-height: 16px;
        color: #51635d;
      }

      .meal-description-wrapper {
        margin-bottom: 24px;

        .content {
          color: #51635d;
          font-size: 14px;
          line-height: 16px;
        }
      }

      .meal-tags-wrapper {
        margin-bottom: 32px;

        .content {
          display: flex;
          flex-wrap: wrap;
        }

        .tag-wrapper {
          margin: 0 5px 5px 0;
          padding: 4px 9px;
          border: 1px solid #cbedba;
          border-radius: 10px;

          .tag {
            color: #52c41a;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }

      .add-cart-wrapper button {
        width: 150px;
        height: 40px;
        background: #52c41a;
        color: #ffffff;
        font-size: 14px;
        font-family: 'Roboto';
        line-height: 16px;
        border-radius: 21px;
        cursor: pointer;

        &.math-wrapper {
          padding: 0 12px;
          line-height: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
`;

export default function VisitorMealCardDescriptionModal({
    meal,
    recommendedTags,
    notRecommendedRestrictions,
    setIsModal,
}) {
    const { img, name, price, calories, carbs, fat, protein, description, tags, id } = meal;
    const { cart } = useVisitorContext();
    const { addToCart, updateMealQty, removeFromCart } = useVisitorCart();
    const mealInCart = cart.find((meal) => meal.id === id);
    const modalRef = useRef()

    const renderRecomandedTags = (
        <span className="tags">
            {recommendedTags.length > 2 ? (
                <>
                    {recommendedTags.slice(0, -1).join(', ')}
                    <span className="and"> and </span>
                    {recommendedTags.slice(-1)}
                </>
            ) : recommendedTags.length === 2 ? (
                <>
                    {recommendedTags[0]}
                    <span className="and"> and </span>
                    {recommendedTags[1]}
                </>
            ) : (
                <>{recommendedTags[0]}</>
            )}
        </span>
    );

    const handleClickOutside = e => {
        if (!modalRef.current.contains(e.target)) {
            setIsModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <MealCardDescriptionModalWrapper>
            <div className="meal-card-description-container" ref={modalRef}>
                <div className="close-icon-wrapper" onClick={() => setIsModal(false)}>
                    <img src="/icons/close-circle3.svg" alt="close Icon" />
                </div>
                <div class="meal-img-section">
                    <div className="meal-img-wrapper">
                        <img src={img || '/images/dish.png'} />
                    </div>
                    <div className="meal-recomaneded-tags-wrapper">
                        {notRecommendedRestrictions.length === 0 && recommendedTags.length > 0 && (
                            <div className="meal-recomaneded-tags">
                                {renderRecomandedTags} is recommended in your diet plan
                            </div>
                        )}
                    </div>
                </div>
                <div className="meal-info-section">
                    <h2>{name}</h2>
                    <div class="price">{useDisplayAmount(price / 100)}</div>
                    <MealCardContent calories={calories} carbs={carbs} fat={fat} protein={protein} />
                    <div className="meal-description-wrapper">
                        <div className="title">Description:</div>
                        <div className="content">{description}</div>
                    </div>
                    <div className="meal-tags-wrapper">
                        <div className="title">Tags:</div>
                        <div className="content">
                            {tags.map((tag, index) => (
                                <div className="tag-wrapper" key={index}>
                                    <div className="tag">{tag}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="add-cart-wrapper">
                        {mealInCart && mealInCart.qty >= 1 ? (
                            <button className="math-wrapper">
                                <span
                                    className="math"
                                    onClick={() =>
                                        mealInCart.qty == 1
                                            ? removeFromCart(meal.id)
                                            : updateMealQty(meal, +mealInCart.qty - 1)
                                    }
                                >
                                    <CgMathMinus />
                                </span>
                                <span>{mealInCart.qty}</span>
                                <span className="math" onClick={() => addToCart(meal)}>
                                    <CgMathPlus />
                                </span>
                            </button>
                        ) : (
                            <button onClick={() => addToCart(meal)}>Add to cart</button>
                        )}
                    </div>
                </div>
            </div>
        </MealCardDescriptionModalWrapper>
    );
}
