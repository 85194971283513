import styled from 'styled-components';

const SoicalMediaIconsStyle = styled.div`

`;

export default function SoicalIcons({ img }) {
  console.log('img.....',img)
  return (
    <SoicalMediaIconsStyle>
      <img src={img} alt="soical Media" />
    </SoicalMediaIconsStyle>
  );
}