import styled from "styled-components";
import Title from "./components/Title";
import Button from "./components/Button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useOnboardingContext } from "./index";
import PageTransition from './components/PageTransition'
import { useRef, useEffect } from "react";

const CurrentHeightStyle = styled.div`
    padding: 48px 32px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: overlay;
    height: 100%;
    .flex{
        flex: 1;
    }
    .form_holder{
        display: flex;
        gap: 14px;
        padding: 0 18px;
    }
    .form_group{
        position: relative;
        margin-top: 75px;
        .form_control{
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            padding: 0 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
        }
        label{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
        }
    }
    .subtitle{
        max-width: 211px;
        margin: auto;
    }
`

export default function CurrentHeight() {
    const history = useHistory();
    const [height, setHeight] = useState({ heightFeet: '', heightInches: '' });
    const { onboardingUser, setOnboardingUser } = useOnboardingContext();
    const inchRef = useRef();
    const feetRef = useRef();
    const handleBack = () => {
        history.push('/self-onboarding/current-weight');
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const regex = /^(0|[0-9]\d*)(,\d{0,2})?$/;

        if (name === 'heightFeet') {
            if (value.length === 1 && regex.test(value)) {
              setHeight({ ...height, [name]: value ? Number(value) : '' });
              setOnboardingUser((prev) => ({
                ...prev,
                [name]: Number(value),
              }));
            } else {
              const validHeight = value.slice(0, -1);
              setHeight({ ...height, [name]: validHeight ? Number(validHeight) : '' });
            }
          } else if (name === 'heightInches') {
            if (value.length <= 2 && regex.test(value)) {
              const updatedHeight = value <= 11 ? value : 11;
              setHeight({ ...height, [name]: updatedHeight ? Number(updatedHeight) : '' });
              setOnboardingUser(prev => ({ ...prev, [name]: Number(updatedHeight) }));
            } else {
              const validHeight = value.slice(0, -1);
              const updatedHeight = validHeight ? Number(validHeight) : '';
              setHeight({ ...height, [name]: updatedHeight });
            }
          }
          
    }

    useEffect(() => {
        feetRef?.current?.value && inchRef?.current?.focus();
    }, [feetRef?.current?.value])

    useEffect(() => {
        setHeight({
            heightFeet: onboardingUser?.heightFeet || '',
            heightInches: onboardingUser?.heightInches || ''
        })
    }, [])

    useEffect(() => {
        if (feetRef?.current) {
            feetRef?.current?.focus();
        }
    }, [feetRef?.current])

    return (
        <PageTransition>
            <CurrentHeightStyle>
                <div className="flex">
                    <Title title={"How tall are you?"} subtitle={"The taller you are, the more your body needs"} />

                    <div className="form_holder">
                        <div className="form_group">
                            <input id="heightFeet" ref={feetRef} type="number" pattern="\d*" name="heightFeet" onChange={handleChange} className="form_control" value={height.heightFeet} autoFocus />
                            <label for="heightFeet">ft</label>
                        </div>
                        <div className="form_group">
                            <input id="heightInch" ref={inchRef} type="number" pattern="\d*" name="heightInches" onChange={handleChange} className="form_control" value={height.heightInches} />
                            <label for="heightInch">in</label>
                        </div>
                    </div>
                </div>

                <Button onBackClick={handleBack} disabled={!height.heightFeet || height.heightInches === ''} onContinueClick={() => history.push('/self-onboarding/userAge')} />
            </CurrentHeightStyle>
        </PageTransition>
    )
}