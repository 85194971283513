import styled from 'styled-components';
import MealDrawer from './addEditMeal/MealDrawer';
import { useState, useEffect } from 'react';
import { useRequest } from '../../../hooks/useRequest';
const Container = styled.div`
  width: 100%;
  overflow: auto;
  /* max-width: calc(100vw - 309px); */
  max-height: calc(100vh - 121px);
  padding: 10px;
  padding-top: 0;
  @media (max-width: 800px) {
    /* max-height: calc(100vh - 375px); */
  }

    .meals-wrapper{
      text-align: center;
      .title{
        color: #51635d;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        margin-top: 40px;
      }
      p{
        margin-top: 140px;
        color: #949d9b;
        font-size: 36px;
        font-weight: 400;
        line-height: 42px;
      }
      button{
        width: 200px;
        height: 40px;
        padding: 0;
        border-radius: 21px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        background-color: #52c41a;
        color: #fff;
        margin-top: 22px;
        font-family: 'Roboto';
        cursor: pointer;
      }
    }
`;
const StyledMealsList = styled.div`
  display: grid;
  justify-items: center;
  /* width: 100vw; */

  b {
    color: gray;
  }
`;
const StyledLabel = styled.label`
  font-size: 1.1rem;
`;
const StyledColumns = styled.div`
  display: grid;
  grid-template-columns: 100px 180px 50px 120px 50px 100px 60px 60px 70px;
  gap: 50px;
  /* justify-items: center; */
  align-items: center;
  padding: 10px;
`;
const StyledHeader = styled.div`
  background: #47c547;
  color: white;
  border-radius: 5px;
  width: max-content;
  position: sticky;
  top: 0;
  margin: auto;
  margin-bottom: 20px;
`;
const StyledRow = styled.div`
  background: #edfded;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1.2rem;
`;
export default function Meals({
  provider,
  meals,
  setMeals,
  allergies,
  isCombo = false,
  parentComponentId,
  parentComponentName,
  getData
}) {
  const [getUser] = useRequest('auth/user');
  const [getMeals, { isLoading, error }] = useRequest();
  const [postData] = useRequest(`provider/update_tags`, 'post');
  const [openForm, setOpenForm] = useState(false);
  const [isDelete, setIsDelete] = useState(false);  

  const postTags = async () => {
    let newTags = [];
    let newMeals = {};
    let comboComponent = {};
    await getMeals({ path: `provider/meals/${provider.id}` }).then(({ meals, components }) => {
      comboComponent = components;
      newMeals = meals;
    });
    
    if (isCombo) {
      //Retrive tags from comboComponent
      const comboComponentArray = comboComponent.map((i) => i.comboSelection);
      let allComboTagArr = [];
      comboComponentArray.forEach((comboComponentArray) => {
        const comboComponentTags = comboComponentArray.map((i) => i.tags);
        allComboTagArr.push(...comboComponentTags);
      });
      const newArrOldCombo = Array.prototype.concat(...allComboTagArr);

      //Retrive tags from Meals
      const mealsArray = newMeals.map((meal) => meal);
      const mealTagsArray = mealsArray.map((i) => i.tags);
      const newMealTags = Array.prototype.concat(...mealTagsArray);

      //Combine tags of Meals and ComboComponent
      const newMealsAndComboTags = newArrOldCombo.concat(newMealTags);
      newTags = [...new Set(newMealsAndComboTags)];
    }
    if (!isCombo) {
      //Retrive tags from Meals
      const om1 = newMeals.map((i) => i.tags);
      const newArrOldMeal = Array.prototype.concat(...om1);

      //Retrive tags from comboComponent
      const c1 = comboComponent.map((i) => i.comboSelection);
      let comboTagArr = [];
      c1.forEach((c1) => {
        const c2 = c1.map((i) => i.tags);
        comboTagArr.push(...c2);
      });
      const newArrCombo = Array.prototype.concat(...comboTagArr);

      //Combine tags of Meals and ComboComponent
      const newProviderTags = newArrOldMeal.concat(newArrCombo);
      newTags = [...new Set(newProviderTags)];
    }
    provider.id ? await postData({ body: { id: provider.id, tags: newTags } }) : '';
  };

  return (
    <Container>
      {meals?.length ? (
      <StyledHeader>
        <StyledColumns>
          <StyledLabel>Name</StyledLabel>
          <StyledLabel>Description</StyledLabel>
          <StyledLabel>Price</StyledLabel>
          <StyledLabel>Nutritions</StyledLabel>
          <StyledLabel>Weight</StyledLabel>
          <StyledLabel>Allergies</StyledLabel>
          <StyledLabel>Tags</StyledLabel>
          <StyledLabel>Image</StyledLabel>

          <MealDrawer
            setMeals={setMeals}
            allergies={allergies}
            isCombo={isCombo}
            provider={provider}
            postMethod={postTags}
            parentComponentId={parentComponentId}
            parentComponentName={parentComponentName}
            meals={meals} 
            setIsDelete={setIsDelete}
            getData={getData}
          />
        </StyledColumns>
      </StyledHeader>
      ) : (

        <>
          <div className="meals-wrapper">
            <div className="title">My Meals</div>
            <p>You currently don't have any meals</p>
            <button onClick={()=> {
              setIsDelete(false);
              setOpenForm(true);
            }}>+ Create a meal</button>
          </div>

          {(!isDelete && openForm) &&
            <MealDrawer
              setMeals={setMeals}
              allergies={allergies}
              isCombo={isCombo}
              provider={provider}
              postMethod={postTags}
              parentComponentId={parentComponentId}
              parentComponentName={parentComponentName}
              meals={meals}
              openForm={true}
              setOpenForm={setOpenForm}
              setIsDelete={setIsDelete}
              getData={getData}
             />
          }
          </>
      )}
      <StyledMealsList>
        {meals?.map((meal) => {
          const {
            id,
            name,
            description,
            carbs,
            calories,
            protein,
            fat,
            weight,
            restrictions = [],
            tags = [],
            img,
          } = meal;
          const price = (meal.price / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return (
            <StyledRow key={id}>
              <StyledColumns>
                <div>{name}</div>
                <div>{description}</div>
                <div>{price}</div>
                <div>
                  <b>Carbs</b> {carbs} <br />
                  <b>Calories</b> {calories} <br />
                  <b>Protein</b> {protein} <br />
                  <b>Fat</b> {fat} <br />
                </div>
                <div>{weight}</div>
                <div>{restrictions.join(', ')}</div>
                <div>{tags.join(', ')}</div>
                <img src={img} style={{ borderRadius: '5px', maxWidth: 100 }} />
                <MealDrawer
                  meal={meal}
                  setMeals={setMeals}
                  isCombo={isCombo}
                  postMethod={postTags}
                  allergies={allergies}
                  provider={provider}
                  meals={meals}
                  setIsDelete={setIsDelete}
                  getData={getData}
                />
              </StyledColumns>
            </StyledRow>
          );
        })}
      </StyledMealsList>
    </Container>
  );
}
