import styled from "styled-components"
import DietCard from "./components/DietCard"
import { ReactComponent as Scale } from "./../../assets/scale2.svg"
import { ReactComponent as Skewer } from "./../../assets/skewer.svg"
import { ReactComponent as Lite } from "./../../assets/lite.svg"
import { useState, useEffect } from "react"
import StackSlider from "./components/StackSlider"
import Button from "./components/Button"
import { useHistory } from "react-router-dom"
import { useOnboardingContext } from "."
import PageTransition from "./components/PageTransition"
import { CrossButton } from "./components/CrossButton"

const PreferredDietStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 32px 0px;
    background-color: #fff;
    overflow: overlay;
    height: 100%;
    .flex{
        flex: 1;
    }
    .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #0B1D17;
        margin-bottom: 48px;
    }
    .diet_cards{
        margin-bottom: 40px;
        padding: 0 18px;
        .custom{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            margin-top: 24px;
            text-align: center;
            margin-bottom: -30px;
        }
        .dietCard{
            svg{
                path{
                    stroke:none;
                }
            }
        }
    }
   
    .button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .back{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #97DC76;
            border-radius: 50%;
            .icon{
                color: #51635D;
                font-size: 22px;
            }
        }
        .continue{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            background-color: #52C41A;
            border-radius: 31px;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 10.5px 24px;
            color: #fff;
            .icon{
                color: #fff;
                font-size: 20px;
            }
            :disabled{
                background-color: #E0E3E0;
                color: #949D9B;
                .icon{
                    color: #949D9B;
                }
            }
        }
    }
`

export default function PreferredDiet() {
    const [activePreference, setActivePreference] = useState('Balanced');
    const [type, setType] = useState('balanced');
    const [preferredDiet, setPreferredDiet] = useState({ fatPercent: 30, carbsPercent: 40, proteinPercent: 30, type: type })
    const [isCustom, setIsCustom] = useState(false);
    const history = useHistory();
    const { onboardingUser, setOnboardingUser, isNewGoal, setIsRestrictionModalActive, setAnimation } = useOnboardingContext();

    useEffect(() => {
        if (onboardingUser?.selfMealPlan?.preferredDiet?.fatPercent || onboardingUser?.selfMealPlan?.preferredDiet?.carbsPercent || onboardingUser?.selfMealPlan?.preferredDiet?.proteinPercent === 100) {
            setPreferredDiet({
                fatPercent: onboardingUser?.selfMealPlan?.preferredDiet?.fatPercent,
                carbsPercent: onboardingUser?.selfMealPlan?.preferredDiet?.carbsPercent,
                proteinPercent: onboardingUser?.selfMealPlan?.preferredDiet?.proteinPercent,
                type: onboardingUser?.selfMealPlan?.preferredDiet?.type
            })
        } else {
            setPreferredDiet({
                fatPercent: onboardingUser?.selfMealPlan?.preferredDiet?.fatPercent || 30,
                carbsPercent: onboardingUser?.selfMealPlan?.preferredDiet?.carbsPercent || 40,
                proteinPercent: onboardingUser?.selfMealPlan?.preferredDiet?.proteinPercent || 30,
                type: onboardingUser?.selfMealPlan?.preferredDiet?.type || 'balanced'
            })
        }
    }, [])

    const dietCardData = [
        {
            icon: <Scale />,
            title: "Balanced",
            desc: "40% Carb, 30% Protein, 30% Fat"
        },
        {
            icon: <Skewer />,
            title: "Low Carb",
            desc: "20% Carb, 30% Protein, 50% Fat"
        },
        {
            icon: <Lite />,
            title: "Low Fat",
            desc: "30% Carb, 50% Protein, 20% Fat"
        }
    ]

    const handleBack = () => {
        if (isNewGoal) {
            history.goBack();
            setIsRestrictionModalActive(true);
            setAnimation(true);
          } else {
            history.push('/self-onboarding/dietary-preference?isRestriction=true');
          }
    }

    const handleClick = (value) => {
        setActivePreference(value);
        switch (value) {
            case "Balanced":
                setPreferredDiet({
                    fatPercent: 30,
                    carbsPercent: 40,
                    proteinPercent: 30,
                    type: 'balanced'
                })
                break;
            case "Low Carb":
                setPreferredDiet({
                    fatPercent: 50,
                    carbsPercent: 20,
                    proteinPercent: 30,
                    type: 'low-carb'
                })
                break;
            case "Low Fat":
                setPreferredDiet({
                    fatPercent: 20,
                    carbsPercent: 30,
                    proteinPercent: 50,
                    type: 'low-fat'
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (preferredDiet?.fatPercent == 30 && preferredDiet?.carbsPercent == 40 && preferredDiet?.proteinPercent == 30) {
            setActivePreference("Balanced");
            setType('balanced');
            setIsCustom(false);
        } else if (preferredDiet?.fatPercent == 50 && preferredDiet?.carbsPercent == 20 && preferredDiet?.proteinPercent == 30) {
            setActivePreference("Low Carb");
            setType('low-carb');
            setIsCustom(false);
        } else if (preferredDiet?.fatPercent == 20 && preferredDiet?.carbsPercent == 30 && preferredDiet?.proteinPercent == 50) {
            setActivePreference("Low Fat");
            setType('low-fat');
            setIsCustom(false);
        } else {
            setActivePreference('');
            setType('custom');
            setIsCustom(true);
        }
    }, [preferredDiet])

    useEffect(() => {
        setOnboardingUser((prev) => ({
            ...prev,
            selfMealPlan: {
                ...prev.selfMealPlan,
                preferredDiet: {
                    ...preferredDiet,
                    type: type
                }
            }
        }))
    }, [preferredDiet, type])

    const macros = ['fatPercent', 'carbsPercent', 'proteinPercent'];
    const handleMacroChanges = (name, value) => {
        const index = macros?.indexOf(name);
        const diff = preferredDiet[name] - value;
        const newVals = macros?.reduce(
            ({ diff, changes, index }, _) => {
                if (diff == 0) return { diff, changes, index };
                const nextName = macros[(index + 1) % macros?.length];
                const nextVal = preferredDiet[nextName] + diff;
                if (nextVal <= 100 && nextVal >= 0)
                    return { diff: 0, changes: { ...changes, [nextName]: nextVal } };
                if (nextVal > 100)
                    return {
                        diff: nextVal - 100,
                        changes: { ...changes, [nextName]: 100, index: index + 1 },
                    };
                if (nextVal < 0)
                    console.log("less than 0", { nextName, nextVal, changes });
                return {
                    diff: nextVal,
                    changes: { ...changes, [nextName]: 0 },
                    index: index + 1,
                };
            },
            { diff, changes: { [name]: value }, index },
        );
        setPreferredDiet({
            ...preferredDiet,
            ...newVals.changes,
        });
    };

    return (
        <PageTransition>
            <PreferredDietStyle>
                {isNewGoal && <CrossButton goBack={'/shop/profile'}/>}
                <div className="flex">
                    <h2 className="title">What is your preferred diet?</h2>
                    <div className="diet_cards">
                        {dietCardData?.map((item) => {
                            return <DietCard onClick={() => handleClick(item.title)} isActive={activePreference === item.title} {...item} />
                        })}
                        {(isCustom) && <p className="custom">Custom</p>}
                    </div>

                    <StackSlider preferredDiet={preferredDiet} type="fat" handleMacroChanges={handleMacroChanges} />
                    <StackSlider preferredDiet={preferredDiet} type="protein" handleMacroChanges={handleMacroChanges} />
                    <StackSlider preferredDiet={preferredDiet} type="carbs" handleMacroChanges={handleMacroChanges} />
                </div>

                <Button onBackClick={handleBack} disabled={!preferredDiet?.type} onContinueClick={() => history.push(`/self-onboarding/health-goals${isNewGoal?'?newGoal=true':''}`)} />
            </PreferredDietStyle>
        </PageTransition>
    )
}
