import React from 'react';

const LandingPage = (props) => {
    return (
        <div>
            {/* Required meta tags */}
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
            {/* Bootstrap CSS */}
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"
                  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                  crossOrigin="anonymous"/>
            <link rel="stylesheet" type="text/css" href="css/all.css"/>
            <link rel="stylesheet" type="text/css" href="css/style.css"/>
            <title>Nutritt</title>
            {/* BEGIN: HEADER SECTION */}
            <header className="header_section">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <a href="index.html" className="logo" title><img src="img/logo.png" className="img-fluid"
                                                                             alt=""/></a>
                        </div>
                        <div className="col-8 text-end">
                            <ul className="list-inline socialmedia_handles">
                                <li className="list-inline-item"><label htmlFor>Follow us</label></li>
                                <li className="list-inline-item"><a href="https://www.facebook.com/nutrittofficial/"
                                                                    target="_blank" title><i
                                    className="fab fa-facebook-f"/></a></li>
                                <li className="list-inline-item"><a
                                    href="https://www.linkedin.com/company/nutrittofficial" target="_blank" title><i
                                    className="fab fa-linkedin-in"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* END: HEADER SECTION */}
            {/* BEGIN: BANNER SECTION */}
            <div className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6 text-center">
                            <h3 className="bs_title">Share your food with an enthusiastic new demographic</h3>
                            <p>Grow your business and enjoy consistent revenue by partnering with Nutritt’s nutritional
                                health platform and health-driven userbase.</p>
                            <a href="https://forms.gle/TdhsSydi6uagFTQ59" target='_blank' className="btn btn-primary mb-3" title>Get Started For Free</a>
                            <ul className="list-inline bs_highlights">
                                <li className="list-inline-item">NO Startup Costs</li>
                                <li className="list-inline-item">NO Risks</li>
                                <li className="list-inline-item">NO Commitments</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* END: BANNER SECTION */}
            {/* BEGIN: GRAPHICS SECTION */}
            <section className="graphics_section">
                <div className="container">
                    <img src="img/DishImage.png" className="img-fluid" alt=""/>
                </div>
            </section>
            {/* END: GRAPHICS SECTION */}
            {/* BEGIN: BLOG SECTION */}
            <section className="blog_section">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6">
                            <h3 className="section_title text-center">How does Nutritt help Meal Prep Providers reach
                                new heights?</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            {/* BEGIN: BLOG ITEM */}
                            <a href="#" className="blog_item">
                                <img src="img/Rectangle136.png" className="w-100" alt=""/>
                                <div className="blog_item_content">
                                    <div>
                                        <h4 className="bic_title">Limitless growth with minimum overhead</h4>
                                        <p>Nutritt provides new business awareness and growth opportunities for a modest
                                            commission</p>
                                    </div>
                                </div>
                            </a>
                            {/* BEGIN: BLOG ITEM */}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            {/* BEGIN: BLOG ITEM */}
                            <a href="#" className="blog_item">
                                <img src="img/Rectangle137.png" className="w-100" alt=""/>
                                <div className="blog_item_content">
                                    <div>
                                        <h4 className="bic_title">Save time for focusing on making great food</h4>
                                        <p>Nutritt partners stress less, unlock hours more cooking time a day and enjoy
                                            hassle-free success</p>
                                    </div>
                                </div>
                            </a>
                            {/* BEGIN: BLOG ITEM */}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            {/* BEGIN: BLOG ITEM */}
                            <a href="#" className="blog_item">
                                <img src="img/Rectangle138.png" className="w-100" alt=""/>
                                <div className="blog_item_content">
                                    <div>
                                        <h4 className="bic_title">First-class benefits with zero commitments</h4>
                                        <p>With no startup costs or obligations, becoming our Partner is 100%
                                            risk-free</p>
                                    </div>
                                </div>
                            </a>
                            {/* BEGIN: BLOG ITEM */}
                        </div>
                    </div>
                </div>
            </section>
            {/* END: BLOG SECTION */}
            {/* BEGIN: STATS SECTION */}
            <section className="stats_section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <div>
                                <h3 className="ss_title">Intuitively designed <br/> for Meal Prep Providers</h3>
                                <p>Nutritt offers easy-to-follow diet plans by connecting leading fitness coaches and
                                    nutritionists with cutting-edge meal prep providers - just like you!</p>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="stats_item">
                                            <h4>150+</h4>
                                            <p>coaches</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="stats_item">
                                            <h4>10,000+</h4>
                                            <p>USERS</p>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="stats_item">
                                            <h4>16</h4>
                                            <p>STATES</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offset-sm-1 col-sm-6 text-center">
                            <img src="img/ChefandTrainer.png" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
            {/* END: STATS SECTION */}
            {/* BEGIN: GROWTH SECTION */}
            <section className="growth_section">
                <div className="container">
                    <div className="row">
                        <div className="offset-lg-3 col-lg-6 text-center">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-10">
                                    <img src="img/image1.png" className="img-fluid" alt=""/>
                                    <h3 className="gs_title">Your reliable growth engine</h3>
                                    <p>When you partner with Nutritt, the sky's the limit! We’re confident in providing
                                        meal prep providers with an unparalleled opportunity to increase their brand
                                        awareness, loyalty and equity.</p>
                                    <h4 className="gs_note">With nothing to lose and a world of repeat business to gain,
                                        how can you afford NOT to partner with Nutritt?</h4>
                                    <a href="https://forms.gle/TdhsSydi6uagFTQ59" target='_blank' className="btn btn-primary" title>Get Started For Free</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* END: GROWTH SECTION */}
            {/* BEGIN: FOOTER SECTION */}
            <footer className="footer_section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 text-center text-sm-start">
                            <p className="cpright_text">© Nutritt INC 2021</p>
                        </div>
                        <div className="col-sm-6 text-center text-sm-end">
                            <ul className="list-inline socialmedia_handles">
                                <li className="list-inline-item"><label htmlFor>Follow us</label></li>
                                <li className="list-inline-item"><a href="https://www.facebook.com/nutrittofficial/"
                                                                    target="_blank" title><i
                                    className="fab fa-facebook-f"/></a></li>
                                <li className="list-inline-item"><a
                                    href="https://www.linkedin.com/company/nutrittofficial" target="_blank" title><i
                                    className="fab fa-linkedin-in"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;