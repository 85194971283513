import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../src/assets/arrow-left2.svg';

const BackButtonWrapper = styled.div`
  position: fixed;
  top: 64px;
  left: 12px;
  z-index: 6;
  width: 36px;
  height: 36px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #E0E3E0;
  border-radius: 50%;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: inherit;
    width: 20px;
    height: 20px;
  }
  @media (min-width: 992px) {
    top: 94px;
    left: 2%;
    width: 48px;
    height: 48px;
    margin-left : 24px;
    border: 0;
    cursor: pointer;
    img {
      width: 26px;
      height: 26px;
    }
  }
`;

export default function BackButton({ perviousLink, closeModal, setShowAnim, setProviderModal }) {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (pathname === '/onboarding' || pathname === '/shop/checkout'|| pathname === '/shop/meals' || pathname === '/shop/providers') {
      history.push(perviousLink);
    } else if (pathname === '/nutritionist/followers') {
      goBack();
    } else {
      history.goBack();
      window.scrollTo(0, 0);
    }
  };


  const goBack = () => {
      setShowAnim(false);
      setTimeout(()=> {
        closeModal(false);
      }, 500)
  }

  return (
    <BackButtonWrapper onClick={handleClick}>
      {pathname.includes('/nutritionist') ? (
        <img src="/icons/arrow-left-green.svg" alt="Arrow Icon" />
      ) : (
        <ArrowLeft />
      )}
    </BackButtonWrapper>
  );
}

