import React, { useEffect } from 'react';
import NutritionistReportsPayTable from './NutritionistReportsPayTable';
import Progress from 'components/Progress';
import ErrorComponent from 'components/ErrorComponent';
import { useNutritionistContext } from '../../context/nutritionistContext';
import {  useState } from 'react';

export default function NutritionistReports() {
  const { disbursements,pay,isLoaded,error, isClient, setIsClient } = useNutritionistContext();
  useEffect(() => {
    if (!isClient) {
      setIsClient(true);
      }
      }, [isClient]);

  if (isLoaded) return <Progress />;
  if (error) return <ErrorComponent error={error} />;
  return !disbursements ? (
    <Progress />
  ) : (
    <NutritionistReportsPayTable disbursements={disbursements} pay={pay} isClient={isClient} />
  );
}

