import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const RadioButton = ({ mealData, setMealData }) => {
    const option = [
        { value: "always_available", label: "Always available" },
        { value: "rotation_menu", label: "Rotation menu item", desc: "Set up the availability schedule of the item by date." }
    ]
    const defaultSelectedValue = mealData?.isRotationMenu ? "rotation_menu" : "always_available";
    const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    if(selectedValue === "rotation_menu"){
        setMealData(prevDetails => ({
            ...prevDetails,
            isRotationMenu: true
        }));
    } else {
        setMealData(prevDetails => ({
            ...prevDetails,
            isRotationMenu: false
        }));
    }
};
    
  return (
    <FormControl component="fieldset">
      <RadioGroup value={defaultSelectedValue} onChange={handleOptionChange}>
        {option.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={
            <Radio   
                sx={{
                color: "#000A2C",
                '&.Mui-checked': {
                  color: "#000A2C",
                },
              }}
             />}
            label={
                <>
                    <span className='label'>{option.label}</span>
                    <span className='desc'>{option.desc}</span>
                </>
              }
            labelPlacement="end"
             />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
