import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BottomSheet } from 'react-spring-bottom-sheet';
import AddressesList from '../Addresses/AddressesList';
import { ReactComponent as ChevronDown } from 'assets/shop/chevron-down.svg';
import 'react-spring-bottom-sheet/dist/style.css';
import { useAppContext } from 'context/appContext';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as CloseCross } from 'assets/shop/close-cross.svg';
import { motion } from 'framer-motion';
import { getDetails } from 'use-places-autocomplete';
import { ReactComponent as Add } from 'assets/add.svg';

const DeliveryAddressStyle = styled.div`
  margin-bottom: 8px;
  .title {
    font-size: ${({theme}) => theme?.typography?.fontRegular};
    font-style: normal;
    font-weight: 500;
    color: ${({theme}) => theme?.colors?.primaryDarker};
  }
  .to {
    position: relative;
    height: 44px;
    border-radius: 6px;
    border: 1px solid ${({theme}) => theme?.colors?.lightGrey};
    background: #fff;
    padding: 0 12.5px 0 14px;
    display: flex;
    align-items: center;
    &.error{
      border: 1px solid #FEC8B4;
      background: #FEF1F0;
    }
    .selected-value{
        font-size: ${({theme}) => theme?.typography?.fontRegular};
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      right: 13px;
      path {
        fill: #949d9b;
      }
    }
  }
  .alert {
    position: absolute;
    top: 16px;
    left: auto;
    width: 100%;
    .alert_bar {
      margin: auto;
    }
  }
  @media (min-width: 992px){
    margin-bottom: 0px;
  }
`;

const AddressModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9;
  background-color: #0b1d17a6;
  display: flex;
  align-items: center;
  justify-content: center;
  .address-mask {
    position: fixed;
    inset: 0;
  }
  .address-motion {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-color: ${theme.colors.secondary};
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.2s linear;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  cursor: pointer;
  .selected-value{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #00092D;
    height: 44px;
    border: 1px solid #e0e3e0;
    border-radius: 6px;
  }
  .select-option{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #00092D;
    cursor: pointer;
    &:hover{
      background: #F0F2F4;
    }
    &.selected{
      background: #F0F2F4;
    }
  }
  .mask {
    position: fixed;
    inset: 0;
    z-index: 1;
    cursor: auto;
  }
  .options-dropdown{
    position: absolute;
    top: auto;
    left: auto;
    z-index: 2;
    width: 100%;
    margin-top: 6px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.05);
    border-radius: 6px;
    border: 1px solid #E0E3E0;
    .options-scroller{
      overflow: auto;
      max-height: 160px;
      ::-webkit-scrollbar{
        display: none;
      }
    }
    > .select-option{
      height: 48px;
      display: flex;
      gap: 8px;
      align-items: center;
      border-top: 1px solid #E0E4E8;
    }
  }
  .icon{
    font-size: 20px;
  }
`


const DeliveryAddress = ({ providerDeliveryArea, setSelectedDeliveryAddress, thisProvider, isDeliveryAvailable, checkIfDeliveryAvailable }) => {
  const [open, setOpen] = useState(false);
  const { selectedValue, isMobile, isDesktop, userDeliveryAddress } = useAppContext();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [providerDeliverableAddress, setProviderDeliverableAddress] = useState(providerDeliveryArea);
  const [kitchenNotDelivers, setKitchenNotDelivers] = useState(!isDeliveryAvailable);
  const [userAddressZip, setUserAddressZip] = useState([]);
  const [processedIndices, setProcessedIndices] = useState(new Set());
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAddressLabel, setSelectedAddressLabel] = useState('');
  const [userAddressTitleArray, setUserAddressTitleArray] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(selectedValue || userDeliveryAddress[0]?.id);

  useEffect(() => {
    setProviderDeliverableAddress(providerDeliveryArea);
  }, [providerDeliveryArea])

  useEffect(() => {
    if (selectedValue) {
      setSelectedAddressId(selectedValue);
    } else {
      setSelectedAddressId(userDeliveryAddress[0]?.id);
    }
  }, [selectedValue]);

  useEffect(() => {
    if(userDeliveryAddress?.length){
      const defaultAddress = userDeliveryAddress?.find(address => Number(address?.id) === Number(selectedAddressId));
      setSelectedAddressLabel(defaultAddress?.name || defaultAddress?.type);
    } else {
      setSelectedAddressLabel("");
      setSelectedAddress({});
    }
  }, [userDeliveryAddress,selectedAddressId])

  useEffect(() => {
    if(Object.keys(selectedAddress)?.length > 0){
      setSelectedDeliveryAddress({...selectedAddress, providerId: thisProvider?.providerId});
    }
  }, [selectedAddress])

  useEffect(() => {
    if(userDeliveryAddress?.length){
      const array = userDeliveryAddress?.map((item) => {
        return {
          name: item?.name || item?.type,
          id: item?.id
        }
      })
      setUserAddressTitleArray(array);
    }
  }, [userDeliveryAddress])

  const handleDesktopAddressClick = async (id) => {
    const newAddress = userDeliveryAddress?.find(address => address?.id === id);
    let placeId = newAddress?.address?.address?.value?.place_id
    let label = newAddress?.name || newAddress?.type;
    const placeDetails = await getDetails({ placeId });
    let clickedAddress = {
      address: placeDetails.formatted_address,
      address2: '',
      city: placeDetails.address_components.find((component) =>
        component.types.includes('locality') || component.types.includes('sublocality'),
      )?.long_name,
      state: placeDetails.address_components.find((component) =>
        component.types.includes('administrative_area_level_1'),
      )?.long_name,
      zipcode: placeDetails.address_components.find((component) =>
        component.types.includes('postal_code'),
      )?.short_name,
      userAddressLatLong: {
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
      },
      label
    }
    setSelectedAddress(clickedAddress)
  }

  const AddressListComponent = (
    <AddressesList
      open={open && isMobile}
      setOpen={setOpen}
      providerDeliverableAddress={providerDeliverableAddress}
      setSelectedAddress={setSelectedAddress}
      setKitchenNotDelivers={setKitchenNotDelivers}
      userAddressZip={userAddressZip}
      setUserAddressZip={setUserAddressZip}
      processedIndices={processedIndices}
      setProcessedIndices={setProcessedIndices}
      setShowPopup={setShowPopup}
      isDeliveryAvailable={isDeliveryAvailable}
      checkIfDeliveryAvailable={checkIfDeliveryAvailable}
    />
  )

  return (
    <>
      <DeliveryAddressStyle theme={theme}>
        <div className="title">
          <span>Deliver to</span>
          <span class="required">*</span>
        </div>
        {
        isMobile ?
        <div className={`to ${!isDeliveryAvailable && 'error'}`} onClick={() => {isMobile && setOpen(true)}}>
          {selectedAddress?.zipcode && !isDeliveryAvailable && isMobile ?
          <span className='selected-value' style={{ color: '#EB3223' }}>Change a different addresses</span>
          :
          <span className='selected-value'>{selectedAddress?.label
            ? selectedAddress.label.charAt(0).toUpperCase() + selectedAddress.label.slice(1)
            : selectedAddressLabel.charAt(0).toUpperCase() + selectedAddressLabel.slice(1)}</span>
          }
          <ChevronDown className="icon" />
        </div>
        :
        <SelectWrapper className='select-wrapper'>
          <div className={`selected-value to ${(!isDeliveryAvailable || userDeliveryAddress?.length < 1) && 'error'}`} onClick={() => setDisplayOptions(true)}>
            <span className='select-placeholder'>{selectedAddress?.label || selectedAddressLabel}</span>
            <img src={`/icons/Chevron-down.svg`} alt="icon" />
          </div>
          {displayOptions && (<>
          <div className="mask" onClick={() => setDisplayOptions(false)} />
          <div className="options-dropdown">
            <div className='options-scroller'>
            {userDeliveryAddress?.length > 0 && userDeliveryAddress?.map(address => {
              return <div value={address?.id} onClick={() => {
                setSelectedAddressId(address?.id);
                handleDesktopAddressClick(address?.id)
                setDisplayOptions(false);
              }} className={`select-option ${selectedAddressId === address?.id ? 'selected' : ''}`}>{address?.name ?? address?.type}</div>
            })}
            </div>
            <div className='select-option' onClick={() => {
              setOpen(true);
              setDisplayOptions(false)
            }}>
              <Add />
              Add new address
            </div>
          </div>
          </>)}
         
        </SelectWrapper>
        }
      </DeliveryAddressStyle>
      <BottomSheet
        open={open && isMobile}
        onDismiss={() => setOpen(false)}
        scrollLocking={false}
        expandOnContentDrag={true}
        snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
        className="addresses-bottomSheet"
      >
        {AddressListComponent}
        {kitchenNotDelivers && <NotDeliverablePopup setKitchenNotDelivers={setKitchenNotDelivers} provider={thisProvider} setOpen={setOpen}/>}
      </BottomSheet>
      
      {open && isDesktop &&
        <AddressModalWrapper>
          <div className='address-mask' onClick={() => setOpen(false)} />
          <motion.div
            initial={{ opacity: 0, }}
            exit={{ opacity: 0  }}
            animate={{ opacity: 1}}
            className="address-motion"
          >
            {AddressListComponent}
          </motion.div>
        </AddressModalWrapper>
      }

      {(kitchenNotDelivers && showPopup) &&
      <NotDeliverablePopup setKitchenNotDelivers={setKitchenNotDelivers} provider={thisProvider} setOpen={setOpen}/>}
    </>
  );
};

export default DeliveryAddress;


const NotDeliverablePopupStyled = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9;
  .popup{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy';
    background: rgba(11, 29, 23, 0.65);
    width: 100vw;
    height: 100vh;
  }
  .popup__body{
    max-width: 334px;
    width: 100%;
    padding: 48px 16px 30px;
    border-radius: 12px;
    background: #FFF;
    position: relative;
    .popup__text{
      font-size: ${({theme}) => theme?.typography?.fontBase};
      color: ${({theme}) => theme?.typography?.primaryDarker};
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 24px;
      text-align: center;
      span{
        font-weight: 600;
      }
    }
    .popup__button{
      max-width: 182px;
      margin: auto;
    }
    .cross__icon{
      position: absolute;
      left: 16px;
      top: 16px;
    }
  }
`

const NotDeliverablePopup = ({provider, setOpen, setKitchenNotDelivers}) => {
  const handleClick = () => {
    setOpen(false);
    setKitchenNotDelivers(false);
  }
  return (
    <NotDeliverablePopupStyled theme={theme}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
        }}
      >
        <div className='popup'>
        <div className='popup__body'>
          <CloseCross className='cross__icon' onClick={() => handleClick()} />
          <p className='popup__text'>The <span>"{provider?.name}"</span> kitchen does not deliver to your location. Select an alternative kitchen or update your delivery address.</p>
          <div className='popup__button'>
            <Button title='Ok' type={'mobile_secondary'} onClick={() => handleClick()} />
          </div>
        </div>
        </div>
      </motion.div>
    </NotDeliverablePopupStyled>
  )
}