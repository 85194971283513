import styled from 'styled-components';
import { useVisitorContext } from 'context/visitorContext';
import { useState, useRef, useEffect } from 'react';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { BiArrowBack } from 'react-icons/bi';
import ImageViewer from 'components/ImageViewer';
import { useAppContext } from 'context/appContext';
import Header from 'layout/Header';
import Progress from 'components/Progress';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { isEmailValid } from '../../components/auth/utils'
import { useHistory } from 'react-router-dom';


const SignUpStyled = styled.section`
    min-height: calc(100vh - 210px);
    height: 100%;
    padding-top: 30px;
    padding-bottom: 50px;
    .container{
        max-width: 702px;
        margin: auto;
        position: relative;
        .back-arrow{
            position: absolute;
            left: 24px;
            top: 0;
            font-size: 20px;
            color: #949D9B;
        }
        .page_title{
            font-weight: 700;
            font-size: 36px;
            line-height: 77px;
            color: #0B1D17;
            text-align: center;
            margin-bottom: 141px;
        }
        .row{
            display: flex;
            flex-wrap: wrap;
        }
        .content{
            flex: 1;
            padding-right: 42px;
            form{
                margin-bottom: 32px;
                .form_group{
                    margin-bottom: 22px;
                    .back{
                        margin-bottom: 14px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        .icon{
                            line-height: 26px;
                            color: #949D9B;
                            font-size: 20px;
                            cursor: pointer;   
                        }
                        span{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 26px;
                            color: #AFB5B4;
                            display: inline-block;
                        }
                    }
                    label{
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 32px;
                        color: #51635D;
                        span{
                            color: #AFB5B4;
                        }
                    }
                    .form_control{
                        background: #FFFFFF;
                        border: 1px solid #E0E3E0;
                        border-radius: 6px;
                        width: 100%;
                        height: 44px;
                        padding: 9px 14px;
                        font-weight: 400;
                        font-size: 14px !important;
                        line-height: 16px;
                        color: #51635D;
                        &::placeholder{
                            color: #AFB5B4;
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
                .submit_btn{
                    background: #52C41A;
                    border-radius: 28px;
                    padding: 16px;
                    width: 100%;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #FFFFFF;
                    cursor: pointer;
                    &:disabled{
                        background: #c3cbc9;
                    }
                }
            }
            .agree{
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #AFB5B4;
            }
        }
        .image{
            width: 100%;
            max-width: 360px;
            .img-fluid{
                width: 100%;
                height: auto;
                display: block;
                filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
            }
        }
    }
    @media (max-width: 992px){
        height: 100%;
        min-height: calc(100vh - 52px);
        padding-top: 24px;
        padding-bottom: 24px;
        .container{
            width: 100%;
            padding: 0 24px;
            .page_title{
                margin-bottom: 32px;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #0B1D17;
            }
            .row{
                flex-direction: column-reverse;
            }
            .content{
                width: 100%;
                padding-right: 24px;
                padding-left: 24px;
                padding-top: 48px;
                form{
                    margin-bottom: 24px;
                }
                .agree{
                    font-size: 12px;
                    line-height: 14px;
                }
            }
            .image{
                width: 100%;
            }
        }
    }
`

const GetStarted = () => {
    const [visitorDetails, setVisitorDetails] = useState(null);
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const { settings, cart, rowId, deliveryUnavailable, zip, value } = useVisitorContext();
    const [registerUser, { isLoading: isRegestering }] = useRequest('visitor/register', 'post');
    const [isEmailExist, { isLoading: loading }] = useRequest('auth/isEmailPhoneNumberExist', 'get');
    const { isMobile, afterLogin, TimeZone } = useAppContext();
    const [saveDeliveryAddress, { isLoading }] = useRequest("user-delivery-address/", "post")
    const [saveUnavilableAddress]=useRequest("unavailable-delivery-locations/","post");
    const address = settings?.userInfo?.address_Value;
    const type = settings?.userInfo?.addressType;
    const passwordInput = useRef();
    const history = useHistory();
    const validEmail = isEmailValid(visitorDetails?.email);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setVisitorDetails({
            ...visitorDetails,
            [name]: value
        })
    }
    const handleBtnClick = async (e) => {
        e.preventDefault()
        if (!validEmail) {
            toast.error('Invalid Email')
            return
        }
        try{
            const res = await isEmailExist({ path: `auth/isEmailPhoneNumberExist?email=${visitorDetails?.email}`});
            if(res.data.isEmailPhoneNumberExist){
                toast.error("Email already exists. Try with another email.");
                return;
            }
            !showPasswordInput && setShowPasswordInput(true);
            if (visitorDetails.email && visitorDetails.password && validEmail) {
                await handleFormSubmit()
            }
        }catch(error){
            toast.error(error);
        }

    }
    const disableSignupButton = (isLoading || isRegestering);
    const handleFormSubmit = async () => {
        await registerUser({
            body: {
                email: visitorDetails.email,
                password: visitorDetails.password,
                name: visitorDetails.email.split("@")[0],
                TimeZone
            },
        }).then((data) => {
            afterLogin(data);
            deliveryUnavailable && saveUnavilableAddress({
                body: { address: { ...value }, zipcode: zip, userId: data?.user?.id, savedAddressId: rowId}
            })
            address && saveDeliveryAddress({
                body: { address: { ...address }, type: type }
            })
        }).catch((e) => {
            toast.error(e);
        });
    };

    const goBackToEmail = () => {
        setShowPasswordInput(false);
        setVisitorDetails({
            ...visitorDetails,
            password: null
        })
    }
    useEffect(() => {
        if (!showPasswordInput) {
            return;
        }
        passwordInput?.current?.focus();
    }, [showPasswordInput])
    const goBackToPrev = () => {
        history.goBack('');
    }
console.log('production loaded')
    return (
        <>
            <Header />
            <SignUpStyled>
                <div className='container'>
                    {isMobile && <BiArrowBack onClick={goBackToPrev} className='back-arrow' />}
                    <h1 className='page_title'>Get started</h1>
                    <div className='row'>
                        <div className='content'>
                            <form onSubmit={handleFormSubmit}>
                                {!showPasswordInput ?
                                    <div className='form_group'>
                                        <label htmlFor='email'>Email <span>*</span></label>
                                        <input className='form_control' onChange={handleChange} value={visitorDetails?.email} type='email' id='email' name='email' placeholder='Your email' />
                                    </div>
                                    : ''
                                }

                                {(visitorDetails?.email && showPasswordInput) ?
                                    <div className='form_group'>
                                        <div className='back'>
                                            <HiOutlineChevronLeft onClick={goBackToEmail} className='icon' />
                                            <span>{visitorDetails?.email}</span>
                                        </div>
                                        <label htmlFor='passowrd'>Password <span>*</span></label>
                                        <input className='form_control' ref={passwordInput} onChange={handleChange} value={visitorDetails?.password} type='password' id='password' name='password' placeholder='Choose a password' />
                                    </div>
                                    : ''
                                }
                                <button className='submit_btn' disabled={disableSignupButton || !visitorDetails?.email || (showPasswordInput && !visitorDetails?.password)} onClick={handleBtnClick} type='submit'>    {isRegestering ? 'Loading...' : 'Continue'}</button>
                            </form>
                            <p className='agree'>By continuing, you agree to our Terms and Privacy Policy.</p>
                        </div>
                        <div className='image'>
                            <ImageViewer src='/img/sign-up-food.png' className='img-fluid' />
                        </div>
                    </div>
                </div>
                {(isRegestering || isLoading) && <Progress />}
            </SignUpStyled>
        </>
    )
}

export { GetStarted };  