import classNames from 'classnames';
import Input from 'components/Input';
import styled from 'styled-components';

const OfferingAvailabilityWrapper = styled.div`
  margin: 24px 0 10px;

  .availability-time-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .availability-time {
    width: 112px;
    height: 42px;
    color: #51635d;
    border: 1px solid #e0e3e0;
    border-radius: 20px;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.isActive {
      background-color: #f0faeb;
      color: #51635d;
      border: 1px solid #97dc76;
      font-weight: bold;
    }

    & > div {
      width: 60px;
      margin: 0 5px 0;
    }

    label {
      margin: 0 !important;
    }

    .input-wrapper {
      height: 25px !important;
    }

    input {
      height: 25px !important;
      min-height: auto;
      margin: 0;
      padding: 0 5px;
      border: 0 !important;
    }
  }

  @media (max-width: 991px) {
    margin: 20px 0 15px;

    .availability-time-wrapper {
      flex-wrap: wrap;

      .availability-time {
        width: 65px;
        height: 32px;
        margin-bottom: 5px;
        font-size: 10px;

        &:last-child {
          width: 80px;
        }

        .input-wrapper {
          height: 20px !important;
        }

        input {
          height: 20px !important;
        }

        & > div {
          width: 45px;
        }
      }
    }
  }
`;

export default function OfferingAvailability({
  availabilityTimes,
  isActive,
  handleAvailabilityValue,
  isCustom,
  handleDisplayCustom,
  formAvailability,
  isCustomEdit,
  customValue,
  setCustomValue,
  handleCustomBlur,
  onChange,
  handleCustomValue,
}) {
  let selectedTime = parseInt(formAvailability);
  return (
    <OfferingAvailabilityWrapper>
      <label>
        <span>Set your availability</span>
        <span style={{color: 'red'}}> * </span>
     </label>
      <div className="availability-time-wrapper">
        {availabilityTimes.map((time, index) => {
          const timeValue = time.split(' ')[0];
          return (
            <div
              key={index}
              className={classNames('availability-time', {
                isActive: isActive && timeValue == formAvailability,
              })}
              onClick={() => handleAvailabilityValue(timeValue)}
            >
              {time}
            </div>
          );
        })}
        <div
          className={classNames('availability-time', {
            isActive: isCustom && !isActive,
          })}
          onClick={() => handleDisplayCustom()}
        >
          {isCustom ? (
            isCustomEdit ? (
              <>
                <Input
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setCustomValue(value);
                  }}
                  value={customValue}
                  autoComplete="off"
                  maxLength={5}
                  onBlur={handleCustomBlur}
                  autoFocus
                />
                <span> min</span>
              </>
            ) : (
              <span onClick={() => handleCustomValue()}>{customValue} min</span>
            )
          ) : (

           (selectedTime !== 15 && selectedTime !==  30 && selectedTime !== 45 &&selectedTime !== 60 ) && formAvailability ?(
              <span className={classNames('availability-time', {
                isActive: true
              })} >{formAvailability} min</span>
            ):(
              <span>Custom</span>
            ))}
            </div>
          </div>
    </OfferingAvailabilityWrapper>
  );
}
