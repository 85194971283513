import styled from 'styled-components';
import FoodTags from './FoodTags';
import tagIcons from 'components/TagIcons';
import { useAppContext } from 'context/appContext';
import Nutritions from 'pages/onboarding/components/Nutritions';

const UserInfoWrapper = styled.div`
  @media (min-width: 992px) {
    padding: 0 32px;

    .user-title {
      margin-bottom: 30px;
      color: #51635d;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      text-align: center;
    }

    .wrapper-1 {
      width: 254px;
      height: 254px;

      .counting-calories-number {
        p:first-child {
          font-size: 48px;
          line-height: 56px;
        }

        p:last-child {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }

    .wrapper-2 {
      width: 100%;
      margin: 12px 0 18px;

      .dots-wrapper {
        flex-direction: row;
      }

      .legend-first {
        margin: 0 5px 0 0;
      }

      p {
        color: #51635d;
        font-size: 18px;
        line-height: 24px;
      }

      .legend-second {
        color: #0b1d17;
      }
    }

    .food-recommanded-wrapper {
      padding: 10px 0 25px;

      .title {
        margin-bottom: 25px;
        color: #51635d;
        font-size: 16px;
        font-family: 'Roboto';
        line-height: 19px;
        text-align: center;
      }

      .food-recommanded-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
      }

      .food-valid {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:first-child .food-tags-wrapper {
          justify-content: flex-end;
        }
      }

      .food-title {
        margin-bottom: 10px;
        color: #51635d;
        font-size: 14px;
        line-height: 16px;
      }

      .food-tags-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
`;

export default function UserInfo() {
  const {
    profile: { dietTemplate, dietMetrics,selfMealPlan },
  } = useAppContext();
  const {
    macros: { carbs, fat, protein },
  } = dietMetrics;

  return (
    <UserInfoWrapper>
      <div className="user-title">
        {dietTemplate.name ? dietTemplate.name.charAt(0).toUpperCase() + dietTemplate.name.slice(1) : ''}
      </div>
      <Nutritions calories={dietMetrics.calories} carbs={carbs} fat={fat} protein={protein} />
      <div className="food-recommanded-wrapper">
        <div className="title">Food recommendations</div>
        <div className="food-recommanded-container">
          <div className="food-valid">
            <div className="food-title">Eat</div>
            <div className="food-tags-wrapper">
            {dietTemplate.tags || selfMealPlan.dietaryPreferences ? (
              (dietTemplate.tags || selfMealPlan.dietaryPreferences).map((tag, index) => (
                <FoodTags
                  tag={tag}
                  Icon={tagIcons[tag.charAt(0).toUpperCase() + tag.slice(1).toLocaleLowerCase()]}
                  key={index}
                />
              ))):null}
            </div>
          </div>
          <div className="food-valid">
            <div className="food-title">Avoid</div>
            <div className="food-tags-wrapper">
            {dietTemplate.food_to_avoid || selfMealPlan.allergies ? (
              (dietTemplate.food_to_avoid || selfMealPlan.allergies).map((tag, index) => (
                <FoodTags
                  tag={tag}
                  Icon={tagIcons[tag.charAt(0).toUpperCase() + tag.slice(1).toLocaleLowerCase()]}
                  key={index}
                />
                ))):null}
            </div>
          </div>
        </div>
      </div>
    </UserInfoWrapper>
  );
}
