import { useState, useEffect } from 'react'
import { useRequest } from 'hooks/useRequest'
import styled from 'styled-components'
import  MealCard  from './MealCard'
import { useNutritionistContext } from 'context/nutritionistContext'
import Progress from 'components/Progress'

const FreshMealsStyle = styled.div`
    margin: 0 20px 125px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .title__holder{
        padding: 14px 20px;
        display: flex;
        border-bottom: 1px solid #F6F5F8;
        margin-bottom: 14px;

        .title{
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #51635D;
            flex: 1;
        }
    }

    .meals__holder{
        margin-bottom: 125px;
    }

    .no_meals{
        font-size: 14px;
        line-height: 22px;
        padding: 0 20px 20px;
    }

    @media (min-width: 390px) and (max-width: 576px){

        margin: 0 24px 125px;

        @supports  (selector(:nth-child(1 of x))) or (-webkit-touch-callout: none) {
            margin: 0 20px 220px;
        }
        
    }
    
    @media (max-width: 374px){
        
        margin: 0px 14px 124px;

        @supports  (selector(:nth-child(1 of x))) or (-webkit-touch-callout: none) {
            margin: 0 20px 220px;
        }

        .meal__info{

            .nutritional__info{

                .info__card{
                    padding: 0 5px;
                }
            }
        }

    }
`

export const FreshMeals = ({ setDietaryModal, pickMealsModal, dietTemplate, providerId, dietaryModal}) => {
    const [meals, setMeals] = useState([]);

    const [getMeals, { isLoading: getMealsLoading, error }] = useRequest();


    useEffect(() => {
        getMeals({
            path: `provider/meals/${providerId}?noEmptyComponents=true&rawPrice=false`,
        }).then(({ meals }) => {
            setMeals(meals);
        });
    }, [providerId,dietaryModal]);
    return (
        <>
            {getMealsLoading ?
                <Progress /> :
                <FreshMealsStyle>
                    <div className='title__holder'>
                        <h3 className='title'>Fresh meals</h3>
                        <img src='/icons/filter-gray.svg' onClick={()=>setDietaryModal(true)}/>
                    </div>

                    {
                        meals.length > 0 ? meals?.map((meal) => 
                            meal.isActive && <MealCard key={meal.id} meal={meal} pickMealsModal={pickMealsModal} dietTemplate={dietTemplate}/>
                        ) : <div className='no_meals'>No meals found for this provider.</div>
                    }

                </FreshMealsStyle>}

        </>
    )
}