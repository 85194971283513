import { useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';

import './style.css';

export default function Column({ children, title, accept, setCurrentDragDay }) {
  const ref = useRef(null);

  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept,
    drop: () => ({ name: title }),
    canDrop: () => true,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    setCurrentDragDay(isOverCurrent ? title : '');
  }, [isOverCurrent]);

  return (
    <div className="meal-plan-item" ref={drop}>
      {children}
      {canDrop && <div className={`movable-item ${canDrop && 'dragging-item'}`} ref={ref} />}
    </div>
  );
}
