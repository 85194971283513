import styled from 'styled-components';
import Select, { components } from 'react-select';
import Input from 'components/Input';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import AddressSaveForm from './AddressSaveForm';
import AddressEditForm from './AddressEditForm';
import { useAppContext } from 'context/appContext';
import { useRequest } from '../../src/hooks/useRequest';
import { toast } from 'react-toastify';
import { useShopContext } from 'context/shopContext';

const AddressEditModal = styled.div`
 padding: 23px 0;
 display: flex;
 justify-content: center;
 flex-direction: column;
 align-items:center;

 .address-wrapper{
  border-bottom-left-radius:unset !important;
  border-bottom-right-radius:unset !important;
  border-bottom: 1px solid #51635d1a;
 }

 .address-info-wrapper{
    padding: 23px 24px 20px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    width: 90%;
    margin: 0 auto;

    .title-edit-wrapper {
        padding: 0;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .title {
          margin-left: 10px;
          color: #0b1d17;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
        }
  
        .edit {
          display: flex;
          align-items: center;
          cursor: pointer;
  
          span {
            margin-left: 5px;
            color: #52c41a;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
  
      .title-wrapper {
        display: flex;
        align-items: center;
  
        img {
          cursor: pointer;
        }
      }  
 }
 .new-address{
  padding:24px 32px;
  //border-top: 1px solid #51635d1a;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  background:#fff;
  width:90%;
  button{
      width: 100%;
      height: 40px;
      border-radius: 21px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      background: #FFFFFF;
      border: 1px solid #97DC76 !important;
      color: #51635D;
      cursor:pointer;
       
  }
}
.address-form{
  .hr{
    width: 100%;
    margin: 20px 0;
    border: 1px solid #51635d1a;
  }
  &:last-child{
     .hr{
    //  display:none;
     }
  }
}
.delete-button{
  width: 100%;
  height: 40px;
  border-radius: 21px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor:pointer;
  background: #FFFFFF;
  border: 1px solid #E0E3E0 !important;
  color: #51635D;
  margin-top:12px;
}

`
 const DeleteModal = styled.div`

 padding:20px 15px;
 height:100%;
 display: flex;
 align-items: center;
 
 .delete-div {
     width:100%;
     height:auto;
     background:#fff;
     margin: 0 auto;
     padding:43px 0 25px;
     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
     border-radius: 10px;
     font-family: 'Roboto';
     font-style: normal;
    
    .delete{
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #0B1D17;
      text-align: center;
      margin-bottom:15px;
    }
    .sure, .lose{
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    } 
    .sure{
       margin-bottom:14px;
    }
    .lose{
      margin-bottom:28px;
   }

    .btn-div{
        display:flex;
        justify-content:center;
        column-gap: 6px;
        align-items:center;
        padding:24px 32px 0;
        border-top:1px solid #F6F5F8;
        .cancel, .apply{
            border-radius: 21px;
            width: 138px;
            height: 40px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            display:flex;
            justify-content:center;
            align-items:center;
        }
        .apply{
            background: #52C41A;
            color: #FFFFFF;
        }
        .cancel{
            background:#FFFFFF;
            border:1px solid #97DC76 !important;
            color: #51635D;
        }
    }
     
    }
`
  ;

export default function AddressEdit({createForm, setCreateForm}) {
  const defaultForm = {
    address:{},
    apartment:'',
    type:'home',
    prevType:'',
    place_id:'',
    customType:null,
    instruction:'',   
   }
  const [form, setForm] = useState(defaultForm);
  const [createAddress,setCreateAddress]=useState({id:undefined,address:{},apartment:"",type:"home",instruction:"",customType:null,prevType:'',place_id:''})
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [addform,setAddform] = useState(0);
  const {user,setUser,userDeliveryAddress, setUserDeliveryAddress } = useAppContext();
  const [addressId, setAddressId] = useState(null);
  const [deleteAddress, { isLoading, error }] = useRequest();
  const { pathname } = useLocation();
  const [showAnim, setShowAnim] = useState(true);
  const {id} = useParams();
  
  const handleDelete = async(id) => {

    const path = "user-delivery-address/";
    await deleteAddress({
        path,
        method: 'delete',
        body:{deliveryAddressId:Number(id)}
    }).then((result) => {
          const filter = userDeliveryAddress.filter((item)=>item.id!==Number(id));
          setUserDeliveryAddress(filter);
          toast.success('Address deleted successfully');
          console.log(result);
          history.push('/shop/profile')
        })
        .catch((err) => {
          console.log(err)
        });
};

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDeleteModal(open);
  };

  const closeAddressModal = () => {
    setShowAnim(false);
    setCreateForm(false)
    setTimeout(() => {
      history.push('/shop/profile')
    }, 500)
}

const formDelete = ()=>{
  setAddform(addform - 1);
  setDeleteModal(false);
  window.scrollTo(0, 0);
}
  
  return (
    <AddressEditModal className={showAnim ? "fadeInMiddle" : "fadeOutMiddle"}>
      <div className={`address-info-wrapper ${pathname===`/shop/profile/edit-address/${id}` ? 'address-wrapper' : ''}`}>
        <div className="title-edit-wrapper">
          <div className="title-wrapper">
            <img
              src="/icons/arrow-left.svg"
              alt="Arrow Icon"
              onClick={closeAddressModal}
            />
            <div className="title">Delivery Info</div>
          </div>
          <div className="edit" onClick={closeAddressModal} >
            <span>Cancel</span>
          </div>
        </div>
        {/* { [...Array(addform)].map((_, i) => */}
         {pathname==='/shop/profile/add-address' ? <div className='address-form'>
         <AddressSaveForm  setDeleteModal={setDeleteModal} createForm={createForm} form={form} setForm={setForm} />
         </div>
         :
         <>
          <div className='address-form'>
         <AddressEditForm  setDeleteModal={setDeleteModal} createForm={createForm} form={form} setForm={setForm} handleDelete={handleDelete} setAddressId={setAddressId}/>
         {addform >0 && <hr className='hr'/>}
         { [...Array(addform)].map((_, i) =>
          <div className='address-form' key={i}>
         <AddressSaveForm  setDeleteModal={setDeleteModal} createForm={createForm} form={createAddress} setForm={setCreateAddress}/>
         <button className='delete-button' onClick={()=>setDeleteModal(true)}>
            Delete Address
          </button>
         </div>
           ) }
            </div>
         </>
        }
         {/* ) } */}
  
      </div>
      {pathname===`/shop/profile/edit-address/${id}` && addform<1 && <div className='new-address'>
        <button onClick={() => {setAddform(addform + 1)}}>
          Add New Address
        </button>
      </div>}
      {deleteModal && <div>
        <Drawer anchor='bottom' overlayStyle={{ backgroundColor: "yellow" }} transitionDuration={500} open={deleteModal} onClose={toggleDrawer(false)}  className=' delete-modal'>
          <DeleteModal>
            <div className='delete-div'>
              <p className='delete'>Delete Address</p>
              <p className='sure'>Are you sure you want to delete this address?</p>
              <p className='lose'>You will lose all of your data.</p>
              <div className='btn-div'>
               <button className='cancel' onClick={()=>{setDeleteModal(false),setAddressId(null)}}>Cancel</button>
              {addressId ? <button className='apply' onClick={()=>{handleDelete(addressId),setDeleteModal(false)}}>Yes</button>
              :
              <button className='apply' onClick={formDelete}>Yes</button>
               }
              </div>
            </div>
          </DeleteModal>
        </Drawer>
        </div>}
    </AddressEditModal>
  )
}