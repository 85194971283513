import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'shared/theme/theme'
import Tab from 'components/elements/tab/Tab'
import { BusinessProfile } from './tabPanels/BusinessProfile'
import BankAccount from './tabPanels/BankDetails'
import Orders from './tabPanels/Orders'
import { DeliveryArea } from './tabPanels/DeliveryArea'
import { Subscription } from './tabPanels/Subscription/index'
import TimeZone from './tabPanels/TimeZone';
import { motion, AnimatePresence } from 'framer-motion';

const SettingsStyle = styled.div`
    padding: 28px 24px 28px 25px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    > .title{
        font-size: ${({ theme }) => theme?.typography?.fontXl};
        font-weight: 600;
        line-height: 32px;
        color: ${({ theme }) => theme?.colors?.blackDefault};
        margin-bottom: 20px;
    }
    .container{
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
        background: #FFF;
        height: 100%;
        flex: 1;
        display: flex;
        overflow: auto;
        .tabs{
            width: 184px;
            border-right: 1px solid ${({ theme }) => theme?.colors?.borderLight};
            height: 100%;
            padding: 24px 40px 24px 24px;
            .nav_item{
                margin-bottom: 20px;
                padding: 6px 5px 6px 8px;
                white-space: nowrap;
                background-color: transparent;
                .text{
                    color: ${({ theme }) => theme?.colors?.blackLight};
                    font-weight: 500;
                }
                &.active{
                    .text{
                        color: ${({ theme }) => theme?.colors?.primaryDefault};
                    }
                  &:hover {
                    background-color: transparent;
                  }
                }
                &:hover {
                  background-color: ${({ theme }) => theme?.colors?.grayLightest};
                }
            }
        }
        .tabPanel{
            padding: 24px 24px 26px 34px;
            flex: 1;
            overflow: auto;
        }
        
          .tabPanel::-webkit-scrollbar-thumb {
            background-color: transparent;
          }
    }
`

export const Settings = () => {
    const [selectedTabId, setSelectedTabId] = useState(0);
    const tabs = [
        {
            id: 0,
            title: 'Business profile',
            onClick: () => { },
        },
        {
            id: 1,
            title: 'Orders',
            onClick: () => { }
        },
        {
          id: 2,
          title: 'Subscription',
          onClick: () => { }
        },
        {
            id: 3,
            title: 'Bank account',
            onClick: () => { }
        },
        {
            id: 4,
            title: 'Delivery area',
            onClick: () => { }
        },
        {
            id: 5,
            title: 'Time zone',
            onClick: () => { }
        }
    ];

    return (
        <SettingsStyle theme={theme}>
        <h2 className='title'>Settings</h2>
        <div className='container'>
          <div className='tabs'>
            <Tab navItems={tabs} selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
          </div>
          <AnimatePresence exitBeforeEnter>
            <motion.div className='tabPanel' key={selectedTabId}>
              {selectedTabId === 0 && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <BusinessProfile />
                </motion.div>
              )}
              {selectedTabId === 1 && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Orders />
                </motion.div>
              )}
              {selectedTabId === 2 && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Subscription />
                </motion.div>
              )}
              {selectedTabId === 3 && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <BankAccount />
                </motion.div>
              )}
              {selectedTabId === 4 && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <DeliveryArea />
                </motion.div>
              )}
              {selectedTabId === 5 && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <TimeZone />
                </motion.div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </SettingsStyle>
  
    )
}