import { useAppContext } from 'context/appContext';

export const MACRONUTRIENTS = {
  CALORIES_PER_CARB: 4,
  CALORIES_PER_PROTEIN: 4,
  CALORIES_PER_FAT: 9,
};

export const SHOP_STAGES = {
  START: 'start',
  SELECT_PROVIDER: 'providers',
  SELECT_MEALS: 'meals',
  COMBO_BUILDER: 'combobuilder',
  PROVIDER_SCHEDULE: 'providerschedule',
  CART: 'cart',
  CHECKOUT: 'checkout',
  SUCCESS: 'success',
};

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const FULFILLMENT_STATUS = {
  RECEIVED: 'received',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  COMPLETED: 'completed',
};

const DEFAULT_SCHEDULE_ITEM = { start: '', end: '', lead: '' };
const DEFAULT_SCHEDULE_DAY = {
  open: null,
  close: null,
  delivery: DEFAULT_SCHEDULE_ITEM,
  pickup: DEFAULT_SCHEDULE_ITEM,
};

export const DEFAULT_PROVIDER_SCHEDULE = {
  sunday: DEFAULT_SCHEDULE_DAY,
  monday: DEFAULT_SCHEDULE_DAY,
  tuesday: DEFAULT_SCHEDULE_DAY,
  wednesday: DEFAULT_SCHEDULE_DAY,
  thursday: DEFAULT_SCHEDULE_DAY,
  friday: DEFAULT_SCHEDULE_DAY,
  saturday: DEFAULT_SCHEDULE_DAY,
};

export const DEFAULT_ACTIVITY = {
  PAL: {
    men: {
      inactive: 1.2,
      slightly: 1.375,
      moderately: 1.55,
      active: 1.725,
      extremely: 1.9,
    },
    women: {
      inactive: 1.2,
      slightly: 1.375,
      moderately: 1.55,
      active: 1.725,
      extremely: 1.9,
    },
  },
  PA: {
    men: {
      inactive: 1.0,
      slightly: 1.11,
      moderately: 1.35,
      active: 1.25,
      extremely: 1.48,
    },
    women: {
      inactive: 1.0,
      slightly: 1.12,
      moderately: 1.37,
      active: 1.27,
      extremely: 1.45,
    },
  },
};

export const BMR_FORMULA_VARS = {
  harrisBenedict: {
    energyFormula: 'PAL (Physical Activity Level)',
    targetCaloriesFormula: 'BMR * PAL',
    activityType: 'PAL',
  },
  mifflinJeor: {
    energyFormula: 'PAL (Physical Activity Level)',
    targetCaloriesFormula: 'BMR * PAL',
    activityType: 'PAL',
  },
  instituteOfMedicine: {
    energyFormula: '',
    targetCaloriesFormula: 'EER',
    activityType: 'PA',
  },
};

export const BMR_FORMULAS = [
  { label: 'Harris Benedict 1919',  value: 'harrisBenedict' },
  { label: 'Mifflin - St. Jeor 1990',  value: 'mifflinJeor' },
  { label: 'Institute of Medicine',  value: 'instituteOfMedicine' },
];

export const CHALLANGES = [
  { label: 'Select Goal', value: '', isPlaceholder: true},
  { label: 'Weight loss', value: 'lose-weight' },
  { label: 'Bulk up', value: 'bulkup' },
  { label: 'Maintain', value: 'maintain' },
];
export const BREAKDOWN = [
  { label: 'Percentage - %', value: 'percent' },
  { label: 'Number', value: 'number' }
];

export const BMR_FORMULAS_id = [
  { label: 'Select breakdown', value: '', isPlaceholder: true},
  { label: 'Balanced(40% Carb, 30% Protein, 30% Fat)', value: 'Balanced(40% Carb, 30% Protein, 30% Fat)' },
  { label: 'Low Fat(30% Carb, 50% Protein, 20% Fat)', value: 'Low Fat(30% Carb, 50% Protein, 20% Fat)' },
  { label: 'Low Carb(20% Carb, 30% Protein, 50% Fat)', value: 'Low Carb(20% Carb, 30% Protein, 50% Fat)' },
];
export const PLAN_PERIODS = [
  { id: 'days', label: 'Days', value: 'days' },
  { id: 'weeks', label: 'Weeks', value: 'weeks' },
  { id: 'months', label: 'Months', value: 'months' },
];

export const EXPERIENCE_PERIODS = [
  { id: 'days', label: 'Days', value: 'days' },
  { id: 'weeks', label: 'Weeks', value: 'weeks' },
  { id: 'months', label: 'Months', value: 'months' },
  { id: 'years', label: 'years', value: 'years' },
];

export const PLAN_TYPES = [
  { id: 'lose-weight', label: 'Lose Weight', value: 'lose-weight' },
  { id: 'gain-muscle', label: 'Gain Muscle', value: 'gain-muscle' },
];

export function createPlanItems() {
  const { user } = useAppContext();
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));

  const createPlan = {
    id: undefined,
    nutritionistId: user?.nutritionistProfile.id || imitateNutritionist?.id,
    name: '',
    introduction: '',
    duration: 0,
    duration_type: 'days',
    type: '',
    tags: [],
    // intensive : '10',
    // TargerCalroies : '',
    BMR_formula:  "harrisBenedict",
    carbs_percent: 40,
    protein_percent:35,
    fat_percent: 25,
    goal_type: 'percent',
    goal: 0,
    PAL: {
      men: {
        inactive: 1.2,
        slightly: 1.375,
        moderately: 1.55,
        active: 1.725,
        extremely: 1.9,
      },
      women: {
        inactive: 1.2,
        slightly: 1.375,
        moderately: 1.55,
        active: 1.725,
        extremely: 1.9,
      },
    },
    PAL_formula: 'PAL',
    tips: '',
    food_to_avoid: [],
    // breakdown:'',
    // EnergyFormula : 'PAL (Physical Activity Level) ',
    // Target : 'BMR*PAL',
    // challenge_goal : ''
  };

  return createPlan;
}

export const SubcriptionOptions = [
  { value: "once", label: "Order once" },
  { value: "every_other_week", label: "Every other week", intervaldays: 14 },
  { value: "every_week", label: "Every week", intervaldays: 7 },
];

export const SubcriptionDesktopOptions = [
  { value: "once", label: "Order once" },
  { value: "every_week", label: "Every week", intervaldays: 7 },
  { value: "every_other_week", label: "Every other week", intervaldays: 14 },
]

if (process.env.REACT_APP_ENV === 'qa') {
  const qaOption = [
  { value: "every-day", label: "Every day", intervaldays: 1 },
  { value: "every-other-day", label: "Every other day", intervaldays: 2 }
  ]
  SubcriptionOptions.push(...qaOption);
  SubcriptionDesktopOptions.push(...qaOption);
}

export const REACT_ENV = {
  QA: "qa",
  PRODUCTION: "production"
}


export const  AVAILABLE_TAGS = [
  'Fish',
  'Milk',
  'Mustard',
  'Peanut',
  'Crustacean',
  'Celery',
  'TreeNut',
  'Egg',
  'Soy',
  'Dairy',
  'Gluten',
  'Molluscs',
  'Sesame',
  'Lupin',
  'Sulphites',
  'Halal',
  'High-protein',
  'LowCarb',
  'Natural',
  'Organic',
  'Vegetarian',
  'OvoVegetarian',
  'LactoVegetarian',
  'Vegan',
  'Kosher',
  'Ketogenic',
  'Paleo',
  'Seafood',
  'Ovo vegetarian',
  'Lacto vegetarian',
  'Low carb',
  'vegan',
  'low-carb',
  'kosher',
  'high-protein',
  'vegetarian',
  'EGG',
  'MILK',
  'MOLLUSCS',
  'Cereal/Gluten',
  'SOY',
  'FISH',
  'CELERY',
  'MUSTARD',
  'SESAME',
  'SULPHITES',
  'Sulfur Dioxide',
  'PEANUTS',
  'CRUSTACEANS',
  'ketogenic',
  'paleo',
  'lacto-vegetarian',
  'halal',
  'ovo-vegetarian',
  'Tree Nut',
  'Lupine',
  'Mollusk',
  'Cereal/Gluten',
  'Sulfur Dioxide',
  'Ovo-vegetarian',
  'High-protein',
  'Ketogenic',
  'Low-carb',
  'Lacto-vegetarian',
];

export const State_Abbreviation = {
  "alabama": "AL",
  "alaska": "AK",
  "arizona": "AZ",
  "arkansas": "AR",
  "california": "CA",
  "colorado": "CO",
  "connecticut": "CT",
  "delaware": "DE",
  "florida": "FL",
  "georgia": "GA",
  "hawaii": "HI",
  "idaho": "ID",
  "illinois": "IL",
  "indiana": "IN",
  "iowa": "IA",
  "kansas": "KS",
  "kentucky": "KY",
  "louisiana": "LA",
  "maine": "ME",
  "maryland": "MD",
  "massachusetts": "MA",
  "michigan": "MI",
  "minnesota": "MN",
  "mississippi": "MS",
  "missouri": "MO",
  "montana": "MT",
  "nebraska": "NE",
  "nevada": "NV",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  "new york": "NY",
  "north carolina": "NC",
  "north dakota": "ND",
  "ohio": "OH",
  "oklahoma": "OK",
  "oregon": "OR",
  "pennsylvania": "PA",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  "tennessee": "TN",
  "texas": "TX",
  "utah": "UT",
  "vermont": "VT",
  "virginia": "VA",
  "washington": "WA",
  "west virginia": "WV",
  "wisconsin": "WI",
  "wyoming": "WY"
};

export const SUPPORTED_ALLERGEN_TAGS = [
  'CEREAL/GLUTEN',
  'PEANUT',
  'LUPINE',
  'MOLLUSK',
  'MILK',
  'TREE_NUT',
  'SESAME',
  'CRUSTACEAN',
  'FISH',
  'EGG',
  'CELERY',
  'SOY',
  'MUSTARD',
];

export const ASSET_TYPE = {
  kitchenMeal: "kitchen_meal",
  userProfile: "user_profile",
  kitchenBusinessProfile: "kitchen_b_profile",
  kitchenBusinessStore: "kitchen_b_store",
  kitchenProfile: "kitchen_profile",
  coachProfile: "coach_profile"
}