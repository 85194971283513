import { useState, useEffect } from 'react';
import BasicInfo from './BasicInfo';
import Schedule from './Schedule';
import Menu from './menu';
import { Switch, Route, useLocation, useParams, Redirect } from 'react-router-dom';
import SelectDeliverableAreaOnMap from 'pages/provider/map/Map';

// import Orders from "./Orders";
import Payouts from './Payouts';
import ManageComboComponent from './ManageComboComponent';
import { useAppContext } from '../../context/appContext';
import OrderListPage from '../../OrderFulfillment/OrderList';
import OrderPage from '../../OrderFulfillment/Order';
import Header from 'layout/Header';
import Nav from './menu/Nav';
import { useMediaQuery } from '@react-hook/media-query';
import NavMobile from './menu/NavMobile';
import ProviderTermsConditions from 'pages/provider/ProviderTerms&Conditions';
import styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import Progress from 'components/Progress';

const SectionStyle = styled.div`
 margin-top:72px;
 
 .section{
  margin-bottom:20px;
  min-height: 80vh;
 }

 @media(max-width:992px){
  margin-top:57px !important;
  .section{
    min-height:unset !important;
  }
 }
`

export default function Provider({ provider, setProvider }) {
  const isWide = useMediaQuery('only screen and (min-width: 700px)');
  const [updateProvider, setUpdateProvider] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [link, setLink] = useState();
  const {isMobile, isSuper} = useAppContext();
  const { pathname, search } = useLocation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const providerId = searchParams.get('providerId');
  const imitateProvider = JSON.parse(sessionStorage.getItem('imitateProvider'));
  const activeProviderId = providerId ? providerId : imitateProvider?.id;
  const [getProviders, { isLoading }] = useRequest(`provider/provider/${activeProviderId}`);


  useEffect(()=> {
    window.scrollTo(0, 0);
  },[])

  const getProviderData = () => {
    getProviders()
      .then((data) => {
        setProvider(data);
        sessionStorage.setItem('imitateProvider', JSON.stringify(data));
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (activeProviderId && isSuper) {
      getProviderData();
    }
  }, [activeProviderId]);

  if(isLoading){
    return <Progress/>
  }

  if (!provider.id && !search.includes('providerId')) return <Redirect to="/" />

  return (
    <div>
      <Header
        updateProvider={updateProvider}
        provider={provider}
        isAlert={(link) => {
          setIsAlert(true);
          setLink(link);
        }}
      >
        {isWide ? (
          <Nav
            updateProvider={updateProvider}
            provider={provider}
            isAlert={(link) => {
              setIsAlert(true);
              setLink(link);
            }}
          />
        ) : (
          <NavMobile
            updateProvider={updateProvider}
            provider={provider}
            isAlert={(link) => {
              setIsAlert(true);
              setLink(link);
            }}
          />
        )}
      </Header>
      <SectionStyle>
      <section className={!(pathname==='/provider') ? 'section' : ''}>
        <Switch>
          <Route exact path="/provider">
            <BasicInfo
              provider={provider}
              setProvider={setProvider}
              updateProvider={(value) => setUpdateProvider(value)}
              isAlert={isAlert}
              setIsAlert={() => setIsAlert(false)}
              link={link}
            />
          </Route>
          <Route exact path="/provider/deliverable-areas">
            <SelectDeliverableAreaOnMap provider={provider} setProvider={setProvider} />
          </Route>
          <Route exact path="/provider/schedule">
            <Schedule provider={provider} setProvider={setProvider} />
          </Route>
          <Route path="/provider/menu">
            <Menu />
          </Route>
          <Route path="/provider/manage-combo-component">
            <ManageComboComponent />
          </Route>
          <Route exact path="/provider/orders">
            <OrderListPage />
          </Route>
          <Route exact path="/provider/order/:orderId">
            <OrderPage />
          </Route>
          <Route exact path="/provider/payouts">
            <Payouts />
          </Route>
        </Switch>
      </section>
      </SectionStyle>
    </div>
  );
}
