import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const MultiScreenStyleStyled = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  .containerr {
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 24px;
    flex: 1;
    > .subtitle {
      color: ${({ theme }) => theme?.colors?.mediumGrey};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      text-align: center;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .button_holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 44px 16px 29px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
  }
`;

const MultiScreenStyle = ({ children }) => {
  const [screenHeight, setScreenHeight] = useState();

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, [])

  return (
    <>
      <MultiScreenStyleStyled window={window} style={{ height: screenHeight, display: 'flex', flexDirection: 'column' }} theme={theme}>
        {children}
      </MultiScreenStyleStyled>
    </>
  );
};

export default MultiScreenStyle;
