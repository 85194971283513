import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { addressTypes } from './Data';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { motion, AnimatePresence } from 'framer-motion';
import RegularInput from 'components/Input/RegularInput';
import MobileHeader from 'components/elements/MobileHeader';
import Textarea from 'components/elements/textarea/Textarea';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { SaveAddressWrapper } from './style';
import { useHistory, useLocation } from 'react-router';
import { filterKitchens } from 'utils';
import { useShopContext } from 'context/shopContext';
import produce from 'immer';
import getAddress from 'utils/getAddress';

function SaveAddress({
  setOpen,
  setIsSaveAddress,
  location,
  form,
  setForm,
  isEditAddress,
  setIsRemoveAddressModal,
  addressId,
  addressInfo,
  setSelectedAddress,
  providerDeliverableAddress,
  setKitchenNotDelivers,
  setShowPopup,
  isDeliveryAvailable,
  setUserAddressZip,
  showBummer,
  setShowBummer,
  ...props
}) {
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [isChangeType, setIsChangeType] = useState(false);
  const [isInputMask, setIsInputMask] = useState(false);
  const [changeOther, setChangeOther] = useState({
    name: addressTypes[3].Name,
    img: addressTypes[3].img,
  });
  const optRef = useRef();
  const customRef = useRef();
  const deliveryRef = useRef();
  const [saveDeliveryAddress, { isLoading }] = useRequest('user-delivery-address/', 'post');
  const { user, userDeliveryAddress, setUserDeliveryAddress, isWebView, setupLocalUser, isMobile, setSelectedValue, routeBeforeSignup } = useAppContext();
  const { updateSettings, settings } = useShopContext();
  const Activelocation = useLocation();
  const history = useHistory();
  const otherAddressName = Array.isArray(userDeliveryAddress) ? (userDeliveryAddress.find(item => item.id === addressId)?.name || 'Other'): 'Other';
  const handleChange = (e, key) => {
    setForm({ ...form, [key]: e.target.value });
  };
  const handleType = (value) => {
    if (value === 'other') {
      setIsCustomInput(true);
      setForm({ ...form, type: value });
      setIsChangeType(false);
      setIsInputMask(true);
    } else {
      setIsCustomInput(false);
      setForm({ ...form, type: value, customType: '' });
    }
  };

  const saveUserDeliveryAddress = async () => {
    const availableProvidersString = localStorage.getItem("availableProviders");
    const availableProviders = JSON.parse(availableProvidersString)?.data;

    await saveDeliveryAddress({
      body: {
        address: { ...form.address, apartment: form.apartment, instruction: form.instruction },
        type: form.type,
        customType: form.customType,
      },
    })
      .then(async (result) => {
        if(Activelocation.pathname === '/self-onboarding/address'){
          setUserDeliveryAddress([...userDeliveryAddress,result?.data]);
        }
        else {
          setUserDeliveryAddress([...userDeliveryAddress, {...result?.data, isSeen: true}]);
        }
        let placeId = result?.data?.address?.address?.value?.place_id;
        let label = result?.data?.name || result?.data?.type;
    
        const savedAddress = await getAddress(placeId, label);
        const kitchenAvailable = filterKitchens(availableProviders, savedAddress);

        setSelectedValue(result?.data?.id)
          updateSettings(
          produce((draft) => {
            draft.userInfo.userAddressLatLong = savedAddress?.userAddressLatLong;
            draft.userInfo.address = savedAddress?.address;
            draft.userInfo.address2 = savedAddress?.address2;
            draft.userInfo.zipcode = savedAddress?.zipcode;
            draft.userInfo.city = savedAddress?.city;
            draft.userInfo.state = savedAddress?.state;
            return draft;
          }), 
        )
        if(setSelectedAddress && setKitchenNotDelivers){
          setSelectedAddress(savedAddress);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = async () => {
    try {
      if(!user && Activelocation.pathname !== '/self-onboarding/address'){
        localStorage.setItem("visitorDeliveryAddress",JSON.stringify(form));
        setOpen(false);
        return;
      }
      await saveUserDeliveryAddress()
      setIsCustomInput(false);
      setOpen(false);
      if (Activelocation.pathname === '/self-onboarding/address') {
        if (isWebView) {
          isWebView.postMessage(JSON.stringify({ event: "onboarding_complete", action: "webview" }));
          history.push('/shop/kitchen-home');
          return ;
        }
        await setupLocalUser();
        const kitchenUsername = sessionStorage.getItem('kitchenUsername');
        if(kitchenUsername){

          if(!routeBeforeSignup){
            history.push(`/store/${kitchenUsername}`)
          }
          sessionStorage.removeItem('kitchenUsername')
        
        }else{
          history.push('/shop/kitchen-home');
        }
        
      }
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
    const updateAddress = async () => {
    await saveDeliveryAddress({
      body: {
        id: addressId,
        address: { ...form.address, apartment: form?.apartment, instruction: form?.instruction },
        type: form?.type,
        customType: form?.customType,
        prevType: form?.prevType,
        place_id: form?.place_id,
      },
    })
      .then((result) => {
        const updatedData = userDeliveryAddress?.map((item) => {
          if (item.id === result.data.id) {
            return result.data;
          } else {
            return item;
          }
        });

        const doesIdExist = userDeliveryAddress?.find((item) => item.id === result.data.id);
        if (!doesIdExist) {
          setUserDeliveryAddress([...userDeliveryAddress, result?.data]);
        } else {
          setUserDeliveryAddress(updatedData);
        }
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = async () => {
    await updateAddress().then((response) => {
      setOpen(false);
      window.scrollTo(0, 0);
    });
  };

  const blurCustomInput = () => {
    setIsCustomInput(false);
    setIsChangeType(true);
    setIsInputMask(false);
    !form?.customType && setForm({ ...form, type: 'home' });
  };

  const blurInput = () => {
    setIsInputMask(false);
    optRef.current.blur();
    customRef.current.blur();
    deliveryRef.current.blur();
  };

  const cancelBtn = (
    <button className='cancel-btn' onClick={() => setIsSaveAddress(false)}>Cancel</button>
  );

  useEffect(() => {
    setTimeout(() => {
      if (isCustomInput) {
        customRef?.current?.focus();
        // setIsInputMask(true);
      }
    }, 800);
  }, [isCustomInput]);

  useEffect(() => {
    if (
      (form?.type === 'other' && form?.customType?.length && isChangeType) ||
      (form?.type === 'other' && form?.customType?.length && isEditAddress)
    ) {
      setChangeOther({
        name: form?.customType,
        img: <img className="icon" src="/icons/building-black.svg" />,
      });
    } else {
      setChangeOther({ name: 'other', img: <img src="/icons/add-black2.svg" /> });
    }
  }, [isChangeType, form?.customType]);

  return (
    <SaveAddressWrapper>
      {/* {isInputMask && <div className="input-mask" onClick={blurInput} onTouchMove={blurInput} />} */}
      <div className="saveAddress-container">
        {isMobile ? (
          <MobileHeader
            className="saveAddress-header"
            text={`${isEditAddress ? 'Update' : 'Save'} address`}
            onClickLeft={() => setIsSaveAddress(false)}
          />
        ) : (
          <div className='address-desktop-header'>
            <div className='address-close-wrapper' onClick={() => setIsSaveAddress(false)}>
              <img src='/icons/close-black.svg' alt='close Icon' />
            </div>
            <div className='address-header-title'>Enter your address</div>
          </div>
        )}
        <div className='address-save-body'>
        <div className="address-map-wrapper">
          <Map
            google={props.google}
            initialCenter={{
              lat: location.lat,
              lng: location.lng,
            }}
            zoom={16}
          >
            <Marker />
          </Map>
          <div className="address-info">
            <div className="address-first">
              {form?.address?.value?.structured_formatting?.main_text+","}
            </div>
            <div className="address-second">
              {form?.address?.value?.structured_formatting?.secondary_text}
            </div>
          </div>
        </div>
        <div className="address-details-wrapper">
          <RegularInput
            className="apt-number"
            label="Apt / Suite"
            ref={optRef}
            type="text"
            placeholder="Apartment number or suite"
            value={form.apartment}
            onChange={(e) => handleChange(e, 'apartment')}
            onFocus={() => setIsInputMask(true)}
            onBlur={() => setIsInputMask(false)}
          />
          <div className="address-type-wrapper">
            <label>Save address as</label>
            <div className={classNames('address-types', { typesWithCustomInput: isCustomInput })}>
              {addressTypes.map((item) => {
                return (
                  <div
                    className={`label-tag ${item.Name === form.type && 'active'}`}
                    onClick={() => handleType(item.Name)}
                  >
                    <div className="image">
                      {item?.Name === 'other' ? changeOther?.img : item?.img}
                    </div>
                    <div className="type">
                      {item?.Name === 'other' ?  otherAddressName : item?.Name}
                    </div>
                  </div>
                );
              })}
              {isInputMask && <div className="input-mask" onClick={blurInput} />}
              <AnimatePresence>
                {isCustomInput && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.3,
                      delay: 0,
                    }}
                  >
                    <RegularInput
                      className={classNames("custom-input", {customEditInput : isEditAddress})}
                      ref={customRef}
                      placeholder="Save as"
                      value={form.customType}
                      onChange={(e) => handleChange(e, 'customType')}
                      onBlur={blurCustomInput}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
          <Textarea
            className="delivery-instructions"
            label="Delivery instructions"
            ref={deliveryRef}
            placeholder="Delivery instructions"
            value={form.instruction}
            onChange={(e) => handleChange(e, 'instruction')}
            onFocus={() => setIsInputMask(true)}
            onBlur={() => setIsInputMask(false)}
          />
          {isEditAddress && (
            <div className="delete-address" onClick={() => setIsRemoveAddressModal(true)}>
              Delete address
            </div>
          )}
        </div>
        </div>
      </div>
      <div className="save-btn-wrapper">
        {isEditAddress ? (
          <>
          {!isMobile && cancelBtn}
          <button
            className="save-address-btn"
            disabled={
              addressInfo?.address?.address?.apartment === form?.apartment &&
              addressInfo?.type === form?.type &&
              addressInfo?.name === form?.customType &&
              addressInfo?.address?.address?.instruction === form?.instruction
            }
            onClick={handleUpdate}
          >
            {isMobile ? 'Update address': 'Update'}
          </button>
          </>
        ) : (
          <>
          {!isMobile && cancelBtn}
          <button
            className="save-address-btn"
            disabled={isLoading || !form?.address || (form?.type === 'other' && !form?.customType)
            }
            onClick={handleClick}
          >
            {Activelocation.pathname === '/self-onboarding/address' ? "Start shopping" : isMobile ? 'Save address' : 'Save'}
          </button>
          </>
        )}
      </div>
    </SaveAddressWrapper>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(SaveAddress);
