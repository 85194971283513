import { memo, useEffect, useState, useCallback,useMemo,useRef } from 'react';
import Tab from 'components/elements/tab/Tab';
import All from 'components/Provider/StoreFront/Orders/All/All';
import New from 'components/Provider/StoreFront/Orders/New/New';
import Accepted from 'components/Provider/StoreFront/Orders/Accepted/Accepted';
import Rejected from 'components/Provider/StoreFront/Orders/Rejected/Rejected';
import Completed from 'components/Provider/StoreFront/Orders/Completed/Completed';
import { OrdersWrapper } from './style';
import { useRequest } from 'hooks/useRequest';
import Alert from 'components/elements/alert/Alert';
import { debounce } from 'lodash';
import { useKitchenContext } from 'context/kitchenContext';
import { useHistory, useLocation } from 'react-router-dom';
import {Orders as MobileOrders} from 'pages/KitchenMobile/views/Orders';
import { useAppContext } from 'context/appContext';
import PrintOrder from 'components/Provider/StoreFront/Orders/PrintOrder';

function Orders({isSuper, isTransition, setIsTransition}) {
  const [isOrderStatusChanged, setIsOrderStatusChanged] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const handleTabClick = () => {setSortBy('')};
  const [sortBy, setSortBy] = useState();
  const [sort, setSort] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { orderData={}, setOrderData, setNewOrdersCount } = useKitchenContext();
  const { provider: {BusinessProfileSettings}, isDesktop } = useAppContext();
  const [selectedOrder, setSelectedOrder] = useState({});
  const [checkedOrders, setCheckedOrders] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [filterQuery, setFilterQuery] = useState('');
  const newOrdersTab = (id) => ({
    id: id,
    title: 'New',
    render:
      (
        orderData, totalOrdersCount, setOrderDataPageNumber,
        orderDataPageNumber, handleInputChange, setSortBy,
        setSort,isOrdersApiCallProcessing
      ) =>
        <New
          orderData={orderData}
          totalOrdersCount={totalOrdersCount}
          setOrderDataPageNumber={setOrderDataPageNumber}
          orderDataPageNumber={orderDataPageNumber}
          setIsOrderStatusChanged={setIsOrderStatusChanged}
          handleInputChange={handleInputChange}
          orderNumber={orderNumber}
          setSortBy={setSortBy}
          setSort={setSort}
          isCheckAll={orderData?.orders?.every((item) => item?.selected)}
          setIsCheckAll={setIsCheckAll}
          updateSelected={updateSelected}
          updateSelectedAll={updateSelectedAll}
          fetchOrder={fetchOrder}
          activeTab={activeTab}
          sort={sort}
          sortBy={sortBy}
          orderFound={orderFound}
          setOrderData={setOrderData}
          isOrdersApiCallProcessing={isOrdersApiCallProcessing}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setIsPrint={setIsPrint}
          setCheckedOrders={setCheckedOrders}
          imitateProviderId={imitateProviderId}
          setFilterQuery={setFilterQuery}
          filterQuery={filterQuery}
          selectedTabId={selectedTabId}
        />,
    onClick: handleTabClick
  });
  const acceptedTab = (id) => ({
    id: id,
    title: 'Accepted',
    render:
      (
        orderData, totalOrdersCount,
        setOrderDataPageNumber, orderDataPageNumber,
        handleInputChange, setSortBy,
        setSort,isOrdersApiCallProcessing
      ) =>
        <Accepted
          orderData={orderData}
          totalOrdersCount={totalOrdersCount}
          setOrderDataPageNumber={setOrderDataPageNumber}
          orderDataPageNumber={orderDataPageNumber}
          setOrderNumber={setOrderNumber}
          handleInputChange={handleInputChange}
          orderNumber={orderNumber}
          setSortBy={setSortBy}
          setSort={setSort}
          isCheckAll={orderData?.orders?.every((item) => item?.selected)}
          setIsCheckAll={setIsCheckAll}
          updateSelected={updateSelected}
          updateSelectedAll={updateSelectedAll} 
          fetchOrder={fetchOrder}
          activeTab={activeTab}
          orderFound={orderFound}
          setIsOrderStatusChanged={setIsOrderStatusChanged}
          isOrdersApiCallProcessing={isOrdersApiCallProcessing}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setIsPrint={setIsPrint}
          setCheckedOrders={setCheckedOrders}
          imitateProviderId={imitateProviderId}
          setFilterQuery={setFilterQuery}
          filterQuery={filterQuery}
          selectedTabId={selectedTabId}
          sort={sort}
          sortBy={sortBy}
          />,
    onClick: handleTabClick
  });
  const completedTab = (id) => ({
    id: id,
    title: 'Completed',
    render:
      (
        orderData, totalOrdersCount,
        setOrderDataPageNumber, orderDataPageNumber,
        handleInputChange, setSortBy, setSort,
        isOrdersApiCallProcessing
      ) =>
        <Completed
          orderData={orderData}
          totalOrdersCount={totalOrdersCount}
          setOrderDataPageNumber={setOrderDataPageNumber}
          orderDataPageNumber={orderDataPageNumber}
          setOrderNumber={setOrderNumber}
          handleInputChange={handleInputChange}
          orderNumber={orderNumber}
          setSortBy={setSortBy}
          setSort={setSort}
          orderFound={orderFound}
          isOrdersApiCallProcessing={isOrdersApiCallProcessing}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setIsPrint={setIsPrint}
          updateSelected={updateSelected}
          updateSelectedAll={updateSelectedAll}
          isCheckAll={orderData?.orders?.every((item) => item?.selected)}
          setIsCheckAll={setIsCheckAll}
          fetchOrder={fetchOrder}
          activeTab={activeTab}
          setIsOrderStatusChanged={setIsOrderStatusChanged}
          setCheckedOrders={setCheckedOrders}
          imitateProviderId={imitateProviderId}
          setFilterQuery={setFilterQuery}
          filterQuery={filterQuery}
          selectedTabId={selectedTabId}
          sort={sort}
          sortBy={sortBy}
        />,
    onClick: handleTabClick
  });
  const rejectedTab = (id) => ({
    id: id,
    title: 'Rejected',
    render:
      (
        orderData, totalOrdersCount,
        setOrderDataPageNumber, orderDataPageNumber,
        handleInputChange, setSortBy,
        setSort,isOrdersApiCallProcessing
      ) =>
        <Rejected
          orderData={orderData}
          totalOrdersCount={totalOrdersCount}
          setOrderDataPageNumber={setOrderDataPageNumber}
          orderDataPageNumber={orderDataPageNumber}
          setOrderNumber={setOrderNumber}
          handleInputChange={handleInputChange}
          orderNumber={orderNumber}
          setSortBy={setSortBy}
          setSort={setSort}
          orderFound={orderFound}
          isOrdersApiCallProcessing={isOrdersApiCallProcessing}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setIsPrint={setIsPrint}
          updateSelected={updateSelected}
          updateSelectedAll={updateSelectedAll}
          isCheckAll={orderData?.orders?.every((item) => item?.selected)}
          setIsCheckAll={setIsCheckAll}
          fetchOrder={fetchOrder}
          activeTab={activeTab}
          setIsOrderStatusChanged={setIsOrderStatusChanged}
          setCheckedOrders={setCheckedOrders}
          imitateProviderId={imitateProviderId}
          setFilterQuery={setFilterQuery}
          filterQuery={filterQuery}
          selectedTabId={selectedTabId}
          sort={sort}
          sortBy={sortBy}
        />,
    onClick: handleTabClick
  });
  const allTab = (id) => ({
    id: id,
    title: 'All',
    render:
      (
        orderData, totalOrdersCount, setOrderDataPageNumber,
        orderDataPageNumber, handleInputChange, setSortBy,
        setSort,isOrdersApiCallProcessing
      ) =>
        <All
          orderData={orderData}
          totalOrdersCount={totalOrdersCount}
          setOrderDataPageNumber={setOrderDataPageNumber}
          orderDataPageNumber={orderDataPageNumber}
          setIsOrderStatusChanged={setIsOrderStatusChanged}
          handleInputChange={handleInputChange}
          orderNumber={orderNumber}
          setSortBy={setSortBy}
          setSort={setSort}
          isCheckAll={orderData?.orders?.every((item) => item?.selected)}
          setIsCheckAll={setIsCheckAll}
          updateSelected={updateSelected}
          updateSelectedAll={updateSelectedAll}
          fetchOrder={fetchOrder}
          activeTab={activeTab}
          sort={sort}
          sortBy={sortBy}
          orderFound={orderFound}
          isOrdersApiCallProcessing={isOrdersApiCallProcessing}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          setIsPrint={setIsPrint}
          setCheckedOrders={setCheckedOrders}
          imitateProviderId={imitateProviderId}
          setFilterQuery={setFilterQuery}
          filterQuery={filterQuery}
          selectedTabId={selectedTabId}
        />,
    onClick: handleTabClick
  })
  const ordersTabs = !isDesktop ? [newOrdersTab(0), acceptedTab(1), completedTab(2), rejectedTab(3)]
  : BusinessProfileSettings && BusinessProfileSettings[0]?.autoAcceptOrder ? 
    [allTab(0), acceptedTab(1), completedTab(2)]
  : [allTab(0), newOrdersTab(1), acceptedTab(2), completedTab(3), rejectedTab(4)];
  const [selectedTabId, setSelectedTabId] = useState(ordersTabs[0].id);
  const [fetchOrderData] = useRequest();
  // const activeTabStatus = BusinessProfileSettings && BusinessProfileSettings[0]?.autoAcceptOrder ? 'accepted' : isDesktop ? 'all' : 'pending';
  const activeTabStatus = isDesktop ? 'all' : 'pending';
  const [activeTab, setActiveTab] = useState(activeTabStatus);
  const [totalOrdersCount, setTotalOrdersCount] = useState();
  const [orderDataPageNumber, setOrderDataPageNumber] = useState(1);
  const [orderFound, setOrderFound] = useState('');
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
  const tabDetailsRef = useRef()
  const [isOrdersApiCallProcessing, setIsOrdersApiCallProcessing]=useState(true)
  const imitateProviderStorage = sessionStorage.getItem("imitateProvider");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderNo = searchParams.get('orderNo');
  const orderAction = searchParams.get('action');
  const history = useHistory();

  const imitateProviderId = useMemo(() => {
    const parsedProviderId = searchParams.get('providerId');
    const parsedId = parsedProviderId ? parsedProviderId : JSON.parse(imitateProviderStorage)?.id;
    return parsedId
  }, [imitateProviderStorage]);

  const getTitleById = useCallback((id) => {
    const tab = ordersTabs.find(tab => tab.id === id);
    return tab ? tab.title.toLowerCase() : null;
  }, [selectedTabId])

  useEffect(() => {
    let active
    if((selectedTabId === 1 && isDesktop) || (selectedTabId === 0 && !isDesktop)) {
      BusinessProfileSettings && BusinessProfileSettings[0]?.autoAcceptOrder ? active = 'accepted' : active = 'pending';
    } else if((selectedTabId === 2 && isDesktop) || (selectedTabId === 1 && !isDesktop)) {
      BusinessProfileSettings && BusinessProfileSettings[0]?.autoAcceptOrder ? active = 'completed' : active = 'accepted';
    } else if((selectedTabId === 4 && isDesktop) || (selectedTabId === 3 && !isDesktop) || (selectedTabId === 2 && !isDesktop && BusinessProfileSettings[0]?.autoAcceptOrder)) {
      active = 'refused';
    } else{
      active = getTitleById(selectedTabId)
    };
    setActiveTab(active);
    setOrderDataPageNumber(1);
    setOrderNumber("")
    if(orderNo){
      setOrderNumber(orderNo)
    } else {
      setOrderNumber("")
    }
  }, [selectedTabId])

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  useEffect(() => {
    if (tabDetailsRef.current){
      setOrderData(tabDetailsRef.current[activeTab]?.data);
      setIsOrdersApiCallProcessing(tabDetailsRef.current[activeTab]?.isLoading);
      setTotalOrdersCount(tabDetailsRef.current[activeTab]?.data?.ordersCount);
    }
  }, [tabDetailsRef.current, activeTab])

  const fetchOrder = async (activeTab,orderDataPageNumber,orderNumber, sort, sortBy) => {
    setOrderFound('');
    try{
      tabDetailsRef.current = {...tabDetailsRef.current, [activeTab]: { isLoading: true }}
      const path= `provider/orders?pageSize=50&action=${activeTab}&pageNumber=${orderDataPageNumber}${filterQuery ? `&${filterQuery}` : ''}${orderNumber ? `&orderNumber=${orderNumber}` : ''}${sortBy && sort ? `&${sortBy}=${sort}` : ''}${isSuper ? `&providerId=${imitateProviderId}` : ''}`
      const { data } = await fetchOrderData({path}, 'get');
      const addSelected = data?.orders?.map((order) => ({ ...order, selected: false }));
      // setOrderData({ ...data, orders: addSelected });
      tabDetailsRef.current = {...tabDetailsRef.current, [activeTab]: {  data: { ...data, orders: addSelected },isLoading:false }}
      const newOrdersCount = data?.totalUnreadOrders;
      setNewOrdersCount(newOrdersCount);
      setAlertData({ type: '', title: '' });
      const updatedSearchParams = new URLSearchParams(searchParams.toString()); // Create a copy of searchParams
      updatedSearchParams.delete('orderNo');
      updatedSearchParams.delete('action');
      history.replace({
        search: updatedSearchParams.toString(),
      });
    }
    catch(error){
      console.log("error", error);
      setOrderData({...orderData,totalPages:1})
      showErrorMessage(error.message);
      setOrderFound("No matching order found");
    } finally {
      tabDetailsRef.current = {...tabDetailsRef.current, [activeTab]: {  ...tabDetailsRef.current[activeTab], isLoading: false }}
    }
  }

  const debouncedFetchOrder = useCallback(debounce(fetchOrder, 500), [filterQuery]);
  
  useEffect(() => {
    activeTab && debouncedFetchOrder(activeTab,orderDataPageNumber,orderNumber, sort, sortBy);
  }, [orderNumber, isOrderStatusChanged])

  useEffect(() => {
    activeTab && isDesktop && debouncedFetchOrder(activeTab,orderDataPageNumber,orderNumber, sort, sortBy);
  }, [orderDataPageNumber])

  useEffect(() => {
    activeTab && !isDesktop && fetchOrder(activeTab,orderDataPageNumber,orderNumber, sort, sortBy);
  }, [orderDataPageNumber])


  useEffect(() => {
    if(sort && sortBy && activeTab ){
      debouncedFetchOrder(activeTab,orderDataPageNumber,orderNumber, sort, sortBy)
    }
  }, [sort, sortBy])

  useEffect(() => {
    (filterQuery || activeTab) && isDesktop && fetchOrder(activeTab, orderDataPageNumber, orderNumber, sort, sortBy);
  }, [activeTab, filterQuery])

  useEffect(() => {
    activeTab && setFilterQuery('');
  }, [activeTab])

  const handleInputChange = (e) => {
    if (orderDataPageNumber > 1) {
      setOrderDataPageNumber(1)
    }
    const trimmedInpt = e.target.value.trim();
    setOrderNumber(trimmedInpt);
    setSort()
    setSortBy('')
    setOrderFound('');
    setAlertData({ type: '', title: '' });
  }

  const updateSelected = ({ id, selected }) => {
    const getOrders = orderData?.orders?.map((order) => {
      if (order?.orderMainId === id) return { ...order, selected };
      return order;
    })
    setOrderData((prevState) => ({...prevState, orders: getOrders}));
  };

  const updateSelectedAll = (selected) => {
    const selectedItem = orderData?.orders?.map((order) => ({ ...order, selected }))
    setOrderData((prevState) => ({...prevState, orders: selectedItem}));
  };

  useEffect(() => {
    const addSelected = orderData?.orders?.map((order) => ({ ...order, selected: false }));
    orderData?.orders?.length && setOrderData((prevState) => ({...prevState, orders: addSelected}));
  }, [orderData?.orders?.length]);

  useEffect(() => { 
    if (orderAction) {
      const activeTab = orderAction === "all" ? ordersTabs[0].id : ordersTabs[1].id;
      setSelectedTabId(activeTab);
      setActiveTab(orderAction)
    }
  }, [orderNo, orderAction]);

  useEffect(() => {
    if (location.pathname?.includes('print') && localStorage.hasOwnProperty('selectedOrder')) {
      const selectedOrderString = localStorage.getItem('selectedOrder');
      const selectedOrderParsed = JSON.parse(selectedOrderString); 
      setSelectedOrder(selectedOrderParsed);
      setIsPrint(true);
    } else if (location.pathname?.includes('print') && localStorage.hasOwnProperty('checkedOrders')) {
      setIsPrint(true);
    } else {
      localStorage.removeItem('checkedOrders');
      setIsPrint(false);
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname?.includes('orderDetails') && localStorage.hasOwnProperty('selectedOrderDetails')) {
      const selectedOrderString = localStorage.getItem('selectedOrder');
      const selectedOrderParsed = JSON.parse(selectedOrderString); 
      setSelectedOrder(selectedOrderParsed);

      const selectedOrderDetailsString = localStorage.getItem('selectedOrderDetails');
      const selectedOrderDetailsParsed = JSON.parse(selectedOrderDetailsString);
      setSelectedTabId(selectedOrderDetailsParsed?.selectedTabId);
      setOrderNumber(selectedOrderDetailsParsed?.orderNumber);
      setOrderDataPageNumber(selectedOrderDetailsParsed?.orderDataPageNumber);
      setFilterQuery(selectedOrderDetailsParsed?.filterQuery);
      setSort(selectedOrderDetailsParsed?.sort);
      setSortBy(selectedOrderDetailsParsed?.sortBy);
    }
  }, [location.pathname])

  useEffect(() => {
    if ((localStorage.getItem('selectedOrderDetails') || localStorage.getItem('orderDetails'))
    && location.pathname === "/kitchen/orders" && Object.keys(selectedOrder)?.length < 1) {
      fetchOrder(activeTab,orderDataPageNumber,orderNumber, sort, sortBy);
      localStorage.removeItem('selectedOrderDetails');
      localStorage.removeItem('orderDetails');
    } 
  }, [location.pathname, selectedOrder])
  
  return (
    <>
    {isDesktop ? 
    <OrdersWrapper>
      {alertData?.type && (
        <div className="alert">
          <Alert
            type={alertData.type}
            title={alertData.title}
            width="fit-content"
            onClose={() => setAlertData({ type: '', title: '' })}
          />
        </div>
      )}
      <div className='orders-title'>Orders</div>
      <div className='orders-tabs'>
        <Tab
          type="horizontal"
          navItems={ordersTabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
        />
      </div>
      {isPrint &&
        <div className='order-print-wrapper'>
          <PrintOrder
            selectedOrder={selectedOrder}
            setIsPrint={setIsPrint}
            fetchOrder={fetchOrder}
            selectedTabId={selectedTabId}
            setSelectedTabId={setSelectedTabId}
            orderDataPageNumber={orderDataPageNumber}
            orderNumber={orderNumber}
            sort={sort}
            sortBy={sortBy}
            checkedOrders={checkedOrders}
            setCheckedOrders={setCheckedOrders}
            setOrderNumber={setOrderNumber}
            setOrderDataPageNumber={setOrderDataPageNumber}
            setFilterQuery={setFilterQuery}
            setSort={setSort}
            setSortBy={setSortBy}
          />
        </div>
      }
      <div>{orderData && ordersTabs[selectedTabId].render(orderData, totalOrdersCount, setOrderDataPageNumber, orderDataPageNumber,handleInputChange, setSortBy, setSort,isOrdersApiCallProcessing)}</div>
    </OrdersWrapper>
    :
    <MobileOrders 
      selectedTabId={selectedTabId}
      setSelectedTabId={setSelectedTabId}
      setIsOrderStatusChanged={setIsOrderStatusChanged}
      handleInputChange={handleInputChange}
      orderNumber={orderNumber}
      setSortBy={setSortBy}
      setSort={setSort}
      sortBy={sortBy}
      setOrderDataPageNumber={setOrderDataPageNumber}
      sort={sort}
      activeTab={activeTab}
      isTransition={isTransition}
      setIsTransition={setIsTransition}
    />
    }
  </>
  );
}

export default memo(Orders);
