import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { useHistory } from 'react-router-dom';

const NoItemScreenStyle = styled.div`
  .details {
    margin-top: 77px;
    margin-bottom: 100px;
    text-align: center;
    .menu-title {
      color: ${theme.colors.primaryDarker};
      text-align: center;
      font-family: ${theme.typography.fontInter};
      font-size: ${theme.typography.fontLarge};
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 9px;
    }
    p {
      width: 300px;
      margin: 0 auto;
      font-family: ${theme.typography.fontInter};
      font-size: ${theme.typography.fontRegular};
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    p.info {
      color: ${theme.colors.primaryDarker};
      padding-bottom: 20px;
    }
    p.note {
      color: ${theme.colors.grayDarker};
      padding-bottom: 38px;
    }
  }
  .button-container {
    display: flex;
    place-content: center;
    gap: 8px;
    > div {
      > button {
        width: 113px;
        height: 40px;
        border-radius: 6px;
        white-space: nowrap;
      }
    }
  }

  .custom_button {
    padding: 10px 16px;
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    width: 154px;
    height: 40px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
    color: ${({ theme }) => theme?.colors?.primaryDark};
    background: ${({ theme }) => theme?.colors?.white};

    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
`;

export default function noItemScreen({handleCreateCategory}) {
  const history = useHistory();

  return (
    <>
        <NoItemScreenStyle theme={theme}>
          <div className="details">
            <img src="/images/Menu-library.svg"></img>
            <h4 className="menu-title">Create Menu</h4>
            <p className="info">
              To set up a menu, you can begin by creating either an item or a category.
            </p>
            <p className="note">
              Note: An item on a kitchens menu refers to a specific dish. A menu category in a
              kitchens organizes similar items, helping customers easily find and choose their
              preferred dishes
            </p>
            <div className="button-container">
              <Button
                type="dark"
                title="Add an item"
                onClick={() => history.push('/kitchen/menu/addItem')}
              ></Button>
              <Button
                title="Create a category"
                className="custom_button"
                onClick={handleCreateCategory}
              ></Button>
            </div>
          </div>
        </NoItemScreenStyle>
    </>
  );
}
