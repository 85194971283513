import styled from 'styled-components';

const FoodTagsWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 5px 5px 0;
  border: 1px solid #e0e3e0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;
    font-size: 14px;
    height: 14px;
  }
`;

export default function FoodTags({ tag, Icon }) {
  return <FoodTagsWrapper className={tag}>{Icon && <Icon color="#949D9B" />}</FoodTagsWrapper>;
}
