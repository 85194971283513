import { useEffect, useState } from 'react';
import { useShopContext } from 'context/shopContext';
import { useVisitorContext } from 'context/visitorContext';
import { useAppContext } from 'context/appContext';
import { filterKitchens } from 'utils';
import { useLocation, useParams } from 'react-router-dom';

export const useKitchenDeliveryInfo = () => {
  const location = useLocation();

  if (!useVisitorContext()) {
    return { isCurrentKitchenDeliveryAvailable: true }
  }

  const [selectProviderId, setSelectProviderId] = useState();
  const [isCurrentKitchenDeliveryAvailable, setIsCurrentKitchenDeliveryAvailable] = useState(true);
  const { user, currentDeliveryAddress, isShoppingUser, profile = {}, setSelectedProviderId } = useAppContext();
  const { kitchensList } = (user && isShoppingUser) ? useShopContext() : useVisitorContext();
  let { providerId } = useParams();

  const { favoriteKitchenData = {} } = profile || {};

  const checkIfKitchenDelivering = (filteredKitchenList, selectProviderId) => {
    return !!filteredKitchenList.find(
      (kitchen) => {
        if(kitchen.businessProfileId === favoriteKitchenData.businessProfileId && !location.pathname.includes('/kitchen-home')){
          setSelectProviderId(kitchen?.id);
        }
        return kitchen.businessProfileId === favoriteKitchenData.businessProfileId || kitchen?.id === selectProviderId;
      }
    );
  };

  useEffect(() => {
    if(kitchensList?.length){
      const foundKitchen = kitchensList?.find(kitchen => kitchen?.username === providerId);
      setSelectProviderId(foundKitchen?.id);
    }
  }, [kitchensList, providerId])

  useEffect(() => {
    if (kitchensList?.length) {
      const result = filterKitchens(kitchensList, currentDeliveryAddress);
      
      const isKitchenDelivering = result?.filteredKitchenList?.length ? checkIfKitchenDelivering(
        result?.filteredKitchenList,
        selectProviderId,
      ) : false;
      // visitor
      if (!user && !isShoppingUser) {
        setIsCurrentKitchenDeliveryAvailable(result?.filteredKitchenList?.length === 0 || !isKitchenDelivering ? false : true)
        return;
      }

      // user
      if (
        (favoriteKitchenData && Object?.keys(favoriteKitchenData).length > 0) ||
        selectProviderId
      ) {
        setIsCurrentKitchenDeliveryAvailable(isKitchenDelivering);
      } else {
        setIsCurrentKitchenDeliveryAvailable(false);
      }
    }
  }, [kitchensList, currentDeliveryAddress, selectProviderId]);


  return { isCurrentKitchenDeliveryAvailable };
};