import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SideHeaderMenuWrapper = styled.div`
  overflow: hidden;

  .sidebar-header-mask {
    position: fixed;
    inset: 0;
    z-index: 5;
    height: 100vh;
    background-color: rgba(81, 99, 93, 0.65);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    &.displayMask {
      opacity: 1;
      visibility: visible;
    }
  }

  .sidebar-container {
    position: absolute;
    left: -300px;
    top: 0;
    z-index: 6;
    overflow: hidden;
    width: 286px;
    height: 100vh;
    padding: 32px 0;
    background-color: ${theme.colors.secondary};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s linear;
    &.isSideMenu {
      left: 0;
    }
  }

  .sign-buttons-wrapper {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    button {
      width: 100%;
      height: 42px;
      border-radius: 30px;
      font-family: ${theme.typography.fontGilroy};
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
      &.dark::after {
        display: none 
      }
    }
  }

  .app-download-wrapper {
    .love-app {
      padding: 15px 0 14px 24px;
      background: ${theme.colors.grayLighter};
      border-top: 1px solid ${theme.colors.grayLight};
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .brand-wrapper {
      width: 54px;
      height: 54px;
      border-radius: 12px;
      border: 1px solid ${theme.colors.lightGrey};
      background-color: ${theme.colors.secondary};
      ${FlexCenter};
      img {
        width: 42px;
        height: 24px;
      }
    }
    p {
      width: 160px;
      color: ${theme.colors.black};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .app-download {
    padding: 14px 24px 0;
    display: flex;
    align-items: center;
    gap: 7px;
    .app-store-image {
      width: 108px;
      height: 36px;
    }
    .google-play-image {
      width: 123px;
      height: 36px;
    }
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .user-navigation-links {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
  }

  .user-info {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    .user-logo {
      width: 52px;
      height: 52px;
      img {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        object-fit: cover;
      }
      .user-image-placeholder {
        width: 100%;
        height: 100%;
        background-color: #e8f9fb;
        border-radius: 50%;
        color: #71a2a8;
        font-family: ${theme.typography.fontRoboto};
        font-size: 26px;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        ${FlexCenter};
      }
    }
    .user-profile-wrapper {
      .user-name {
        color: ${theme.colors.deepForestGreen};
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
      }
      .user-profile {
        color: ${theme.colors.mediumGray};
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .sidebar-navigation-links {
    padding: 24px 0;
    border-top: 1px solid ${theme.colors.grayLight};
    border-bottom: 1px solid ${theme.colors.grayLight};
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .link-wrapper,
  .sign-out-container {
    width: 100%;
    height: 44px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    svg {
      width: 20px;
      height: 20px;
    }
    .title {
      color: ${theme.colors.primaryDefault};
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    &:hover {
      border-radius: 4px;
      background: ${theme.colors.primaryDefault};
      .title {
        color: ${theme.colors.secondary};
      }
      svg path {
        fill: ${theme.colors.secondary};
      }
    }
  }

  .sign-out-button-wrapper {
    padding: 12px 0;
    border-top: 1px solid ${theme.colors.grayLight};
  }
`;
