import SuccessComponent from 'components/SuccessComponent';
import SubmitButton from 'components/SubmitButton';
import { useRequest } from 'hooks/useRequest';
import { useHistory } from 'react-router-dom';
import Alert from 'components/Alert';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useEffect } from 'react';

export default function UpdateProviderButton({
  body,
  label,
  path,
  method,
  setContextProvider,
  successCb,
  navigateBack,
  isAlert,
  setIsAlert,
  link,
  setProvider,
  contextProvider,
  setUpdatedProfile,
  profileUpdating=false
}) {

  const {bankAccountNumber, zip, address} = body;
  const [updateProvider, { isLoading, state, error }] = useRequest(path, method);
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();
  const handleUpdate = async () => {
    if(profileUpdating){
      if(zip && bankAccountNumber && address) {
        setContextProvider && setContextProvider(body);
        await updateProvider({ body });
        setShowSuccess(true);
        if (navigateBack) { 
          setTimeout(() => {
            history.goBack();
          }, 3000);
        }
        if (typeof successCb === 'function') {
          successCb();
        }
        if(typeof setUpdatedProfile === 'fucntion'){
          setUpdatedProfile({});
        }
      } else{
        toast.error('Please fill all the necessary details');
      }
  
    } else {
      await updateProvider({ body });
      setShowSuccess(true)
      setContextProvider && setContextProvider(body);
      if (navigateBack) { 
        setTimeout(() => {
          history.goBack();
        }, 3000);
      }
      if (typeof successCb === 'function') {
        successCb();
      }
      if(typeof setUpdatedProfile === 'fucntion'){
        setUpdatedProfile({});
      }
    }
  };

  const DiscardChanges = () => {
    setIsAlert();
    setProvider(contextProvider);
    history.push(link);
  };
  useEffect(() => {
    let timer;
    if (showSuccess) {
      timer = setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showSuccess]);
  return (
    <>
      <SubmitButton
        disabled={isLoading}
        style={{ marginTop: '20px' }}
        // type="submit"
        onClick={handleUpdate}
        error={error}
      >
        {isLoading ? 'Updating...' : label || 'Update info'}
      </SubmitButton>
      {showSuccess && <div className='success-message'><SuccessComponent message="Successfully updated" /></div>}
      {isAlert && (
        <Alert
          title="Unsaved Changes"
          desc="Do you want to save the changes?"
          cancel={() => setIsAlert()}
          cancelText="Cancel"
          discard={DiscardChanges}
          discardText="Discard"
          ok={() => {
            handleUpdate();
            history.push(link);
          }}
          okText="Save changes"
        />
      )}
    </>
  );
}
