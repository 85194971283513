export const renderTotalCalories = (mealsDataItem, mealPlan, day) => {
    let count = 0;
    mealPlan
      .filter((meal) => meal.day === day)
      .map((meal) => {
        if (meal.isCombo)
          return meal.components.forEach((selection) => (count += selection[mealsDataItem]));
        return (count += meal[mealsDataItem]);
      });
    return Math.round(count);
  };

export const calcProgress = (mealsDataItem, mealPlan, calories, day) => {
    let color = '';
    let precent = 0;
    precent = (renderTotalCalories(mealsDataItem, mealPlan, day) / calories) * 100;

    if (precent == 0) {
      color = '#E0E3E0';
    } else if (precent > 0 && precent <= 110) {
      color = '#7CBB00';
    } else if (precent > 110 && precent <= 125) {
      color = '#35A956';
    } else if (precent > 125 && precent <= 135) {
      color = '#FFCA06';
    } else if (precent > 135 && precent <= 145) {
      color = '#F3972F';
    } else if (precent > 145 && precent <= 155) {
      color = '#FF4F00';
    } else if (precent > 155) {
      color = '#D62B21';  
    }
    return { color, precent };
  };
  