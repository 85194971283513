import { useState } from 'react';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';
import classNames from 'classnames';
import MealCardContent from 'components/shop/MealCardContent';
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { ReactComponent as DefaultMeal } from 'assets/shop/defaultMeal.svg';
import CheckRight from '../CheckRight';
import {ReactComponent as ClockIcon} from '../../../src/assets/clock-icon.svg';
import {ReactComponent as AddIcon} from '../../../src/assets/add.svg';

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PantryMealsStyle = styled.div`
  padding: 0px 16px 0px 16px;
  display: flex;
  align-items: center;
  & + & {
    border-top: 1px solid #7070704d;
  }
  .oz-number {
    display: none;
  }
  .pantry-meal-icon {
    align-self: center;
    .icon-wrapper {
      width: 32px;
      height: 32px;
      background-color: #F0F2F4 !important;
      border-radius: 50%;
      cursor: pointer;
      ${flexCenter};
      svg {
        color: #00092D !important;
        font-size: 16px;
      }
    }
  }
  .meal-details {
    display: flex;
    align-items: center;
    gap: 13px;
    .add-icon-wrapper {
      display: flex;
    }
    .awaiting-icon-wrapper {
      display: flex;
      img {
        padding: 7px;
        border-radius: 50%;
        height: 28px;
        width: 28px;
      }
      svg{
        background: rgb(224, 227, 224);
        padding: 7px;
        border-radius: 50%;
        height: 28px;
        width: 28px;
      }
    }
    .icon-wrapper {
      background: #fff;
      img {
        width: 32px;
        height: 24px;
      }
    }
  }
  .meal-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
  }

  @media (min-width: 992px) {
    width: auto;
    padding: 15px;
  }
`;

export default function PantryMeals({
  images,
  img,
  name,
  calories,
  carbs,
  fat,
  protein,
  qty,
  id,
  moveToPantryDate,
  orderLineId,
  consumeCustomMealData,
  string_agg,
  swipeAbleBoolean,
  deletePantryMeal,
  isAwaitingMeal,
  isPersonalizedMeals = false,
  isSuccess,
  isDisabled,
  setIsDisabled,
  mealId
}) {
  const [updatedQty, setUpdatedQty] = useState(qty);
  const isCompleted = string_agg?.includes('completed');
  const [isMask, setIsMask] = useState(false);
  // Temp changes to fix production deployment
  // const { isMobile } = useAppContext();
  const isMobile = true;
  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        destructive={updatedQty == 1}
        onClick={() => {
          setUpdatedQty(updatedQty - 1);
          deletePantryMeal(orderLineId);
        }}
      >
        <img src="/icons/trash.svg" alt="Trash Icon" />
        <span>Delete</span>
      </SwipeAction>
    </TrailingActions>
  );
  const mealImage = images?.length > 0 ? images[0] : img;
  return (
    <>
      {swipeAbleBoolean == false ? (
        <>
          {isAwaitingMeal > 0 ? (
            <PantryMealsStyle>
              <div className="img-wrapper">
                {mealImage? <img src={mealImage} alt="meal" /> : <DefaultMeal alt="meal" />}
                {qty > 1 && <span>{qty > 9 ? '9+' : qty}</span>}
              </div>
              <div className="meal-content">
                <div className="pantry-meal-title">{name || 'Test'}</div>
                <div className="meal-details">
                  <MealCardContent calories={calories} carbs={carbs} fat={fat} protein={protein} />
                  <div className="pantry-meal-icon">
                    <div
                      className={classNames({
                        'icon-wrapper': isCompleted || isPersonalizedMeals,
                      })}
                      onClick={() => {
                        if (isCompleted || isPersonalizedMeals) {
                          setUpdatedQty(updatedQty - 1);
                          consumeCustomMealData(orderLineId);
                        }
                      }}
                    >
                      {isCompleted || isPersonalizedMeals ? (
                        <>
                          {isMobile ? (
                            <div className="add-icon-wrapper" data-tip data-for="addToDiary">
                              <img src="/icons/add.svg" alt="Add Icon" />
                            </div>
                          ) : (
                            <AiOutlinePlus data-tip data-for="addToDiary" />
                          )}
                        </>
                      ) : (
                        <>
                          {isAwaitingMeal ? (
                            <div className="awaiting-icon-wrapper">
                              {/* <img src="/icons/clock.svg" alt="Clock Icon" /> */}
                              <ClockIcon/>
                            </div>
                          ) : (
                            <img src="/icons/pause.svg" alt="Pause Icon" />
                          )}
                        </>
                      )}
                      {!isMobile && (
                        <>
                          <ReactTooltip id="orderNotCompleted" place="top" effect="solid">
                            Order must be completed to move in diary.
                          </ReactTooltip>
                          <ReactTooltip id="addToDiary" place="top" effect="solid">
                            Click to add meal in diary.
                          </ReactTooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </PantryMealsStyle>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {isCompleted && updatedQty > 0 ? (
            <SwipeableList fullSwipe={false} type={ListType.IOS}>
              <SwipeableListItem trailingActions={trailingActions()}>
                <PantryMealsStyle>
                  {(isMask && isMobile) && <div className='meal-mask' />}
                  <div className="img-wrapper">
                   {mealImage ? <img src={mealImage} alt="meal" /> : <DefaultMeal alt="meal" />}
                    {updatedQty > 1 && <span>{updatedQty > 9 ? '9+' : updatedQty}</span>}
                  </div>
                  <div className="meal-content">
                    <div className="pantry-meal-title">{name || 'Test'}</div>
                    <div className="meal-details">
                      <MealCardContent
                        calories={calories}
                        carbs={carbs}
                        fat={fat}
                        protein={protein}
                      />
                      <div className="pantry-meal-icon">
                        <button
                          className={classNames({
                            'icon-wrapper': isCompleted,
                          })}
                          onClick={() => {
                            setIsMask(true);
                            setIsDisabled(true);
                            isSuccess && setUpdatedQty(updatedQty - 1);
                            moveToPantryDate(orderLineId);
                          }}
                          disabled={isDisabled}
                        >
                          {isCompleted ? (
                            <>
                              {isMobile ? (
                                <>
                                  {mealId == orderLineId ? (
                                    <CheckRight />
                                  ) : (
                                    <div className="add-icon-wrapper" data-tip data-for="addToDiary">
                                      <AddIcon />
                                    </div>
                                  )}
                                </>
                                ) : (
                                  <AiOutlinePlus data-tip data-for="addToDiary" />
                                )
                              }
                            </>
                          ) : (
                            <InfoIcon data-tip data-for="orderNotCompleted" />
                          )}
                          {!isMobile && (
                            <>
                              <ReactTooltip id="orderNotCompleted" place="top" effect="solid">
                                Order must be completed to move in diary.
                              </ReactTooltip>
                              <ReactTooltip id="addToDiary" place="top" effect="solid">
                                Click to add meal in diary.
                              </ReactTooltip>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </PantryMealsStyle>
              </SwipeableListItem>
            </SwipeableList>
          ) : (
            ''
          )}{' '}
        </>
      )}
    </>
  );
}
