export const macroSplitData = (fat, protein, carbs, fatPercentage, proteinPercentage, carbsPercentage) => {
  const hasPositiveValue = fat > 0 || protein > 0 || carbs > 0 || fatPercentage > 0 || proteinPercentage > 0 || carbsPercentage > 0;
  if (hasPositiveValue) {
    const data = [
      {
        id: 'Fat',
        label: 'Fat',
        value: fat,
        color: '#EE786B',
        macroPercent: fatPercentage
      },
      {
        id: 'Protein',
        label: 'Protein',
        value: protein,
        color: '#2399BF',
        macroPercent: proteinPercentage
      },
      {
        id: 'Carbs',
        label: 'Carbs',
        value: carbs,
        color: '#8ACEBF',
        macroPercent: carbsPercentage
      },

    ];
    return data
  }
  else {
    const data = [{
      id: 'Fat',
      label: 'Fat',
      value: fat,
      color: '#EE786B',
      macroPercent: fatPercentage
    },
    {
      id: 'Protein',
      label: 'Protein',
      value: protein,
      color: '#2399BF',
      macroPercent: proteinPercentage
    },
    {
      id: 'Carbs',
      label: 'Carbs',
      value: carbs,
      color: '#8ACEBF',
      macroPercent: carbsPercentage
    }, 
    { 
      id: 'No nutrition', 
      label: '', 
      value: true, 
      color: '#E0E3E0' 
    }];
    return data
  };
}