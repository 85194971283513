import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import Input from 'components/Input';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { toast } from 'react-toastify';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddCustomMealWrapper = styled.div`
  .icon-wrapper {
    width: 28px;
    height: 28px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    ${FlexCenter};
  }

  .custom-meal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5;
    background-color: #949d9b80;
    ${FlexCenter};

    .custom-meal-container {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 35px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    .title-wrapper {
      position: relative;
      padding: 28px 20px 10px;
      border-bottom: 1px solid #f6f5f8;

      img {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }

    .custom-meal-title {
      color: #0b1d17;
      font-size: 16px;
      font-family: 'Roboto';
      line-height: 19px;
    }

    form {
      label {
        color: #51635d;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .form-input-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 15px;

      input::placeholder {
        visibility: hidden;
      }
    }

    .form-wrapper {
      overflow-y: auto;
      max-height: calc(100vh - 230px);
      padding: 5px 20px 0;
    }

    .select-meal-images {
      margin: 30px 0 10px;
      grid-row-gap: 24px;
    }

    .radio-wrapper {
      position: relative;
      top: 0;
      height: 90px;
      margin: 0 !important;
      padding: 0;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      ${FlexCenter};

      &.selected {
        border-color: #52c41a;
      }

      .radio-check {
        position: absolute;
        top: auto;
        bottom: -10px;
        left: auto;
        height: 21px;
        width: 21px;
        background-color: #fff;
        border-color: #97dc76;

        &:after {
          background: #52c41a;
          width: 10px;
          height: 10px;
        }
      }

      input:checked ~ .radio-check {
        border: 1px solid #52c41a !important;
      }
    }

    .btns-wrapper {
      padding-top: 35px;
    }
  }

  @media (min-width: 992px) {
    width: 70px;
    ${FlexColumnCenter};

    .create-custom {
      color: #afb5b4;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.02em;
      text-align: center;
      ${FlexColumnCenter};
    }

    .custom-meal-wrapper {
      .custom-meal-container {
        width: 646px;
      }

      .title-wrapper {
        padding: 28px 24px 11px;
      }

      .form-wrapper {
        padding: 10px 48px 0;
      }

      form {
        label {
          margin-bottom: 7px;
          font-size: 12px;
          line-height: 14px;
        }

        input {
          height: 44px;
          border: 1px solid #e0e3e0;
          border-radius: 6px;
        }
      }

      .form-input-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
      }

      .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        &.inputInfo input {
          padding-right: 44px;
        }

        span {
          position: absolute;
          top: auto;
          right: 16px;
          color: #949d9b;
          font-size: 14px;
          line-height: 16px;
        }
      }

      .radio-wrapper {
        width: 80px;
        height: 100px;
        margin-right: 14px;
      }

      .select-meal-images {
        display: flex;
      }
    }
  }
`;

const food = ['apple', 'dish', 'snack', 'orange-juice'];

const addCustomMeal = {
  id: Number,
  data: {
    name: '',
    calories: Number,
    fat: Number,
    protein: Number,
    carbs: Number,
    img: '/images/dish.svg',
    description: 'Custom Meal',
    images: [],
    restrictions: [],
    tags: [],
  },
  string_agg: 'completed, accepted',
};

export default function AddCustomMeal({ setCustomMeal }) {
  const [form, setForm] = useState(addCustomMeal);
  const [isAdd, setIsAdd] = useState(false);
  const [selected, setSelected] = useState();
  const { isMobile } = useAppContext();
  const customMeal = form.data;
  const {name, calories, fat, protein, carbs} = customMeal;

  const handleChange = (e, key) => {
    let value = key == 'name' ? e.target.value : Number(e.target.value);
    const prevData = { ...form.data, [key]: value };
    setForm({ ...form, data: prevData });
  };

  useEffect(() => {
    isAdd ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'visible');
    setSelected();
  }, [isAdd]);

  
  const handleSubmit = useCallback(
     (e) => {
      e.preventDefault();
      if (name=="" || isNaN(calories) || isNaN(fat) || isNaN(protein) || isNaN(carbs) ) {
        toast.error('Please fill all the necessary details');
      } else {
        setCustomMeal(form);
        setIsAdd(false);
      }
    },
  );

  return (
    <AddCustomMealWrapper>
      <div
        className="icon-wrapper"
        onClick={() => {
          setIsAdd(true);
          setForm(addCustomMeal);
        }}
      >
        <img src="/icons/add-green.svg" alt="add Icon" />
      </div>
      {!isMobile && (
        <div className="create-custom">
          <span>Create</span>
          <span>custom meal</span>
        </div>
      )}
      {isAdd && (
        <div className="custom-meal-wrapper">
          <div className="custom-meal-container">
            <div className="title-wrapper">
              <img
                src="/icons/close-circle2.svg"
                alt="Close Icon"
                onClick={() => setIsAdd(false)}
              />
              <div className="custom-meal-title">Add custom meal</div>
            </div>
            <form>
              <div className="form-wrapper">
                <Input
                  label="Title"
                  placeholder="i.e. Salmon with rice"
                  value={form.data.name}
                  onChange={(e) => {
                    handleChange(e, 'name');
                    setForm((prevState) => ({ ...prevState, id: Date.now() }));
                  }}
                  required
                />
                <div className="form-input-wrapper">
                  <div className="form-input-group">
                    <Input
                      type="number"
                      label="Calories"
                      value={form.data.calories}
                      onChange={(e) => handleChange(e, 'calories')}
                      info="Cals"
                      required
                    />
                    <Input
                      type="number"
                      label="Fat"
                      value={form.data.fat}
                      onChange={(e) => handleChange(e, 'fat')}
                      info="g"
                      required
                    />
                  </div>
                  <div className="form-input-group">
                    <Input
                      type="number"
                      label="Protein"
                      value={form.data.protein}
                      onChange={(e) => handleChange(e, 'protein')}
                      info="g"
                      required
                    />
                    <Input
                      type="number"
                      label="Carbs"
                      value={form.data.carbs}
                      onChange={(e) => handleChange(e, 'carbs')}
                      info="g"
                      required
                    />
                  </div>
                </div>
                <div className="select-meal-images form-input-group">
                  {food.map((item, index) => (
                    <label
                      className={classNames('radio-wrapper', { selected: selected == index })}
                      key={index}
                      onClick={() => {
                        setSelected(index);
                        const prevData = { ...form.data, img: `/images/${item}.svg` };
                        setForm({ ...form, data: prevData });
                      }}
                    >
                      <img src={`/images/${item}.svg`} alt={`${item} Image`} />
                      <input type="radio" name="payment" value={item} />
                      <span className="radio-check" />
                    </label>
                  ))}
                </div>
              </div>
              <div className="btns-wrapper">
                <button onClick={() => setIsAdd(false)}>Cancel</button>
                <button
                  className="primary-btn"
                  onClick={handleSubmit}
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </AddCustomMealWrapper>
  );
}
