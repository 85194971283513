import { ReactComponent as Facebook } from 'icons/facebook.svg';
import { ReactComponent as Instagram } from 'icons/instagram.svg';
import { ReactComponent as Linkedin } from 'icons/linkedin.svg';

export const socialMediaIcons = [
  { title: <Facebook />, link: 'https://www.facebook.com/nutrittofficial' },
  { title: <Instagram />, link: 'https://www.instagram.com/nutritt_' },
  { title: <Linkedin />, link: 'https://www.linkedin.com/company/nutrittofficial' },
];

export const increasePeopleMoney = [
  {
    title: 'personalized2',
    content: 'Your Personalized Page',
  },
  {
    title: 'meeting1',
    content: 'Schedule 1:1 with your clients',
  },
  {
    title: 'payment1',
    content: 'Accept payment through the app',
  },
  {
    title: 'profit1',
    content: 'Effortless income generation',
  }
];

export const healthyFoodData = [
  'Meal plans they can follow to achieve their specific fitness goals',
  'Schedule 1:1 with you based on your availability',
  'Make payment through the app',
  'Get accountability and gain exclusive support from you ',
  'Easily track macros and body improvement from the app',
  'Get a variety of delicious meals from local chefs with macros that fit their meal plan',
];

export const planStepsData = [
  {
    id: 'sign-up',
    title: 'Sign Up',
    description: 'Create your own custom profile page on Nutrit in under 10 minutes.',
  },
  {
    id: 'direct',
    title: 'Send Your Clients',
    description:
      'Create meal plan templates so you can easily onboard new clients and serve them better, and faster.',
  },
  {
    id: 'invoice',
    title: 'Get Paid',
    description:
      'Earn 100% of the profits on your 1:1 bookings, plus earn a % of every meal your clients order.',
  },
];

export const frequentlyAskedQuestions = [
  {
    question: 'What is Nutritt?',
    answer:
      'Nutritt is a concept that we’re turning into a community. It’s a group of trained nutritionists like you trying to make a difference in the world by curating custom meal plans that can help people achieve their dietary goals. It’s an even larger community of followers looking for coaches like you, striving to eat better and make smarter, more informed dietary decisions, plus track their progress, so they’re not constantly struggling to find the right foods and supplements.',
  },
  {
    question: 'Does Nutritt cost me or my clients money?',
    answer:
      'Nutritt is 100% free of cost for you and your clients. You can set up your own coaching page for free in less than 10 minutes, access and build your own templates for free, and invite as many clients as you want for free. Your clients only pay money for specialized, delicious meals that fit their diet when they click on their meal plans ordered from vendors.',
  },
  {
    question: 'How do I get more clients for my nutrition coaching business?',
    answer:
      'Word of mouth is the strongest factor to sell anything. The better you can serve your clients, the more they will refer their friends to you. Nutritt is designed to empower you with professionalism, easy custom meal planning, and 1-click delicious meals that will wow your clients and make them want to refer their friends to you. Additionally, it’s an easy way to harness organic growth, as we have a marketplace with a review system and search engine which allows new clients to discover great niche coaches like you. You get an unlimited number of followers who can earn you more money.',
  },
  {
    question: 'How much money can I earn from Nutritt?',
    answer:
      'Nutritt offers you a new method to scale your business and earn more income - the sky’s the limit! While your primary income is from your clients themselves, Nutritt empowers you with a platform that’s easy to use and delivers a high value to them, which allows you to retain your high-value clients for longer. Plus, you earn a percentage of each of their meals ordered - for life!* This can add up to a full time passive income on its own depending on how many clients you onboard. The more clients who follow your program, the more you earn - similar to any business.',
  },
  {
    question: 'Is there an easier way to offer meal planning for my clients?',
    answer:
      'Yes! Diet is one of the hardest things for anyone to change. But with drag-and-drop templates and custom pages, you can easily design custom meal plans for each of your clients in minutes - and reuse.',
  },
  {
    question: 'How do I get paid when my clients order meals?',
    answer:
      'Nutritt was designed for your success, so we made getting paid incredibly simple. Once your clients order meals, a percentage of each meal they order goes to you - every time they order. Just imagine how fast that passive income will stack up! We direct deposit your earnings straight into your bank account every month - no need to spend time invoicing us to get paid.',
  },
  {
    question: 'How do I know that the meals are right for my clients?',
    answer:
      'Nutritt was built on trust and the desire to make sure that coaches like you got paid for your hard work, and your clients could easily access custom meals. The vendors we work with are very careful to label foods for sensitivities and macros. No carbs? No problem. Gluten-free? Dairy-free? We’ve got loads of options. Whatever you set up for your clients in their meal plan connects directly to our specialized list of meal providers. Your clients get to choose from all kinds of delicious meals, all of which are guaranteed to have the foods that make them stronger, without the stuff they’re avoiding.',
  },
  {
    question: 'Who are the vendors preparing the meals?',
    answer:
      'Nutritt only signs up qualified, local meal prep vendors. Ranging from chefs to specialty niche health coaches themselves, every vendor is required to pass and exceed any health code standards. We also make sure to try all of their foods ourselves to make sure they’re delicious. After all, health food is only healthy if you eat it.',
  },
];
