import React from "react";
import {Typography} from '@material-ui/core';


const TermsAndConditions = () => {
    return <div style={{
        marginLeft: "80px",
        marginRight: "80px"
    }}>
        <div style={{
            display: "grid",
            justifyContent: "center",
            color: "#52c41a",
        }}>
            <Typography variant="h3" component="h3">
                Nutritt</Typography>
        </div>
        <br/>
        <div style={{
            display: "grid",
            justifyContent: "center",
        }}>
            <Typography variant="h4" component="h4">
                <b>Member Terms & Conditions of Use</b>
            </Typography>
        </div>
        <div style={{
            display: "grid",
            justifyContent: "center"
        }}>
            <Typography variant="h5" component="h5">
                Of
            </Typography>
        </div>
        <div style={{
            display: "grid",
            justifyContent: "center"
        }}>
            <Typography variant="h5" component="h5">
                Nutritt, Inc., a Delaware corporation
            </Typography>
        </div>
        <br/>
        <div style={{
            display: "grid",
            justifyContent: "center"
        }}>
            <Typography variant="h5" component="h5">
                "Celebrate the end of diet fatigue”
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                Last Updated: December 17, 2021
            </Typography>
        </div>
        <br/>
        <div style={{
            display: "grid",
            justifyContent: "center",
            textAlign: "center",
        }}>
            <Typography>
                <b>THESE TERMS ARE IMPORTANT AND AFFECT YOUR LEGAL RIGHTS. PLEASE CAREFULLY READ THIS MEMBER TERMS AND
                    CONDITIONS OF USE PRIOR TO SIGNING UP AND USING OUR SERVICES. THESE TERMS CONTAIN A BINDING
                    ARBITRATION CLAUSE. PLEASE READ THESE TERMS AND THE ARBITRATION PROVISION SO THAT YOU ARE AWARE OF
                    YOUR LEGAL RIGHTS.</b>
            </Typography>
        </div>
        <br/>
        <div className="selection">
            <Typography>
                <b>Section 1 – Introduction & Consent to Terms</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Welcome to Nutritt, Inc., a Delaware corporation’s (referred to herein as “Nutritt”, “we”, “us”, or
                “our”) Member Terms & Conditions of Use (referred to herein as the “Terms”). Nutritt was created to help
                our Members (as defined below) achieve their nutritional goals by utilizing a customized nutrition plan
                created by third-party nutrition influencers in conjunction with healthy food delivery options fulfilled
                by our third-party food vendors.<br/><br/>
                <b>If you do not agree to these Terms, we ask that you please not use our nutrition platform (the
                    “Services”). Signing up and becoming a Member of our Platform constitutes acceptance of these
                    Terms.</b><br/><br/>
                These Terms apply to all users of our website, <u><a href="https://www.nutritt.com/admin"
                                                                     target="_blank">www.nutritt.com</a></u> (our
                “Website”), our mobile website, our social media accounts, our platform, or other mediums now in effect
                or hereinafter created (our “Platform). By using our Platform or signing up for our Services, you agree
                to be bound by these Terms.<br/><br/>
                If you are using our Website or our Services on behalf of a third-party, you represent and warrant that
                you are authorized to accept these Terms on such party’s behalf and that such entity agrees to be
                responsible to us if you or that person violates these Terms.<br/><br/>
                We reserve the right to modify the contents of these Terms at any time. You agree that it is your
                responsibility to monitor changes to these Terms. Any modification to these Terms will be notated at the
                top of these Terms.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 2 – Medical & Nutrition Disclaimer</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Nutritt and our third-party vendors and nutrition influencers are not health care providers and no
                information communicated to you from Nutritt and third-party vendors or nutrition influencers is or
                should be a substitute medical advice. Only your physician or other healthcare provider should be
                offering you medical advice. If you believe you have a medical condition, please see a licensed medical
                professional.<br/><br/>
                Any nutrition advice is provided and intended for general information purposes only. We typically do not
                work with licensed nutritionists; rather, passionate individuals who have acquired knowledge of
                nutrition and healthy living through self-education and their own personal experience. We do not claim
                to use licensed nutrition professionals unless otherwise stated. It is your duty to be aware of
                allergies, sensitivities, or similar with respect to your body.<br/><br/>
                While there is substantial evidence that consistently eating better food and leading an overall
                healthier lifestyle can lead to overall better wellness, Nutritt makes no claims, representations, or
                guarantees about the Services or your personal results which will differ from person to person based on
                a variety of factors such as age, weight, sleep, exercise, and other elements not in the control of
                Nutritt.<br/><br/>
                <b>By signing up for our Services and becoming a Member, you acknowledge and agree that you have either
                    (a) spoken with a physician or other medical professional who has advised you of your ability to use
                    our Services; (b) tested yourself for food allergies, sensitivities, or similar, or (c) you have
                    voluntarily chosen not to do so.</b>While the general risk of an adverse reaction is generally
                small, you may have an underlying health condition, allergy, or sensitivity unknown to Nutritt or our
                third-party vendors or nutrition influencers that could cause or contribute to an adverse reaction of
                some kind. Any nutrition advice should be taken at your own risk.<br/><br/>
                All diets, plans, and advice given to you are not requirements of your use of our Services but are
                designed to be offered to you to be used at your election based on your needs. It is your responsibility
                to ensure that the food you order and put into your body will not cause you an adverse reaction of any
                kind.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 3 – Eligibility & Member Account</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Access to and use of our Services and Platform is not meant to be used by anyone under the age of
                eighteen (18) and we do not target persons under this age to provide our Services. By using our
                Services, you represent and warrant that (a) you are at least eighteen (18) years old; (b) have a
                working smartphone or computer; and (c) you have the full power and authority to enter into these Terms.<br/><br/>
                To register for our Services, you are required to create a member account (a “Member Account”) and
                provide information including, without limitation, your name, age, height, weight, allergies,
                sensitivities, email address, home address, and other information as requested when prompted to create a
                user account (referred to herein as becoming a “Member”). Your specific plans, diets, information,
                meals, and other elements of our Services can be accessed through your Member Account.<br/><br/>
                By registering for our Services and becoming a Member, you further represent and warrant that (a) all
                information submitted is true and correct to the best of your knowledge; (b) if there are any errors or
                inaccuracies on your Member Account, you will update this information as soon as you discover the
                inaccuracy; (c) you will maintain and continue to update your Member Account to ensure that at all times
                it is current and accurate; and (d) you are only authorized to register a third-party if you have
                unambiguous consent from that person, preferably in writing, to do so. You will solely be responsible
                for any and all issues arising out of signing up a third-party.<br/><br/>
                Please be advised that your customized nutrition plan is, in part, crafted from the information you
                provide us which is why the accuracy of this information if of the utmost importance. Any errors or
                omissions in the information you provide us and any resulting consequences (ex – injury or adverse
                reaction) will be borne solely by you. You agree to indemnify and hold harmless Nutritt and our
                third-party vendors and nutrition influencers from any issues caused by an error or omission in the
                information you provide.
                <br/><br/>
                Nutritt does it’s utmost to follow the latest research updates in terms of calorie and health recommendations. However, calories, fats, proteins, carbs, and any other amounts, including count for consumption, amount in meals, and the amount recommended, or any other amount, for each buyer/user may vary slightly. 
                <br/><br/>
                Finally, you are solely responsible for maintaining confidentiality of your password and account
                information and are responsible for all activity that takes place on your account.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 4 – Services and How Our Process Works</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Nutritt and our third-party nutrition influencers provide you with a customized nutrition plan based on
                the information you provide and Nutritt connects you with local food vendors who can fill the needs of
                your customized nutrition plan without you needing to go to the grocery store. Simple as that!<br/><br/>
                We do not charge a membership fee for you to use our Services. The only fees you pay are in connection
                with the food you order or if you select the 1:1 personalized coaching option.<br/><br/>
                If you no longer wish to use our Services, there is no cancellation or notice needed – simply stop using
                our Services. All Services are paid for prior to the meals or other services being rendered
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 5 –Terms of Sale & Payment</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                The food options and other services offered by our third-party vendors are described on our Website at
                the time of purchase. All prices shown for the Services are shown in U.S. Dollars. Nutritt and our
                third-party food vendors or nutrition influencers reserve the right to change prices at any time, with
                or without notice.<br/><br/>
                No chargebacks of any kind are permitted. If you charge back a payment made to Nutritt, you agree to pay
                a one-hundred-dollar ($100.00) administrative fee which is based on the time and labor needed to deal
                with your chargeback and is not a penalty.<br/><br/>
                All monies owed are due and payable at the time of purchase. Failure to pay for meals ordered, in full,
                will accrue interest at one percent (1%) per month until paid in full. At any time after failure to pay,
                Nutritt can transfer your invoice, account, and any past-due payments to a collection agency or
                attorney. If any past-due payments are transferred to a collection agency or attorney, Nutritt shall be
                entitled to recover all costs and fees in collecting your past-due balance, including all reasonable
                attorneys’ fees.<br/><br/>
                When you provide payment information, you represent and warrant that the information you provide is
                accurate and that you are authorized to use the payment method provided. By providing a credit card, you
                represent and warrant that you authorize us or our third-party payment processing company to charge your
                payment method for all charges you incur in connection with your use of our Services. You agree that you
                are responsible to pay for and will pay for all such charges.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 6 –Refund Policy</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Once you place an order for meals, we cannot offer you a refund. The reason for this is that we are not
                connected to our third-party food vendors who solely control the food you are sent and the services they
                provide.<br/><br/>
                If you are unhappy with the meals you receive, you agree that it is your responsibility to contact our
                third-party food provider as soon as possible detailing the issue(s) with your meal(s), if any. We
                highly recommend that any such issues, if any, be resolved as amicably as possible. You catch more bees
                with honey than vinegar.<br/><br/>
                You hereby acknowledge and agree that you shall not bring any action of any kind against Nutritt arising
                out of a dispute with one of our third-party vendors or issue a chargeback to the money paid Nutritt.
                All disputes arising out of or related to your food orders must be resolved directly with our
                third-party food vendors.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 7 – Nutritt Intellectual Property.</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                The contents of our Website and Platform are protected by United States and international copyright
                laws. The contents of our Website and Platform are owned exclusively by Nutritt or licensed to us. You
                may not, and may not cause or encourage others to, reproduce, distribute, display, sell, transfer,
                assign, license or use for commercial purposes any copyrighted material on our Platform or Website (the
                “Intellectual Property”) without our prior written consent. All rights not expressly granted in these
                Terms are reserved to Nutritt. <br/><br/>
                Nutritt and its name, logos, slogans, or otherwise are trademarks or service marks ("Marks") of Nutritt.
                All rights in these Marks are reserved by Nutritt. You may not use any Nutritt-provided Marks or other
                logos or graphics, without our prior written consent.<br/><br/>
                We grant you a limited, revocable, non-transferable and non-exclusive license to access and make
                personal use of our Website and Platform. This limited license does not include the right to: (a)
                republish, redistribute, transmit, sell, license or download the Website or any and/or all content
                except as is necessary to view and/or use our Website; (b) make any use of the Website or any and/or all
                content other than uses consistent with the Services or exploring the Services; (c) modify, reverse
                engineer or create any derivative works based upon either the Website or any and/or content; (d) collect
                account information for the benefit of yourself or another party; or (e) use software robots, spiders,
                crawlers, or similar data gathering and extraction tools, or take any other action that may impose an
                unreasonable burden or load on our infrastructure.<br/><br/>
                Any unauthorized use by you of the Website or Platform automatically terminates this license without
                prejudice to any other remedy provided by applicable law or these Terms.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 8 – Your Content</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                If you send submissions of any kind, with or without a request from us, including ideas, suggestions,
                proposals, plans, or other materials, whether online, by email, or otherwise (“Submissions”), you agree
                that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise
                use in any medium any Submissions that you forward to us. This does not include any confidential or
                personal information that you send to us (ex – when signing up for our Services).<br/><br/>
                We are and shall be under no obligation (1) to maintain any Submissions in confidence; (2) to pay
                compensation to you for any Submissions; or (3) to respond to any Submissions. By you submitting
                Submissions to us, you hereby transfer and assign any claim to any rights that you may have had in those
                Submissions and do so with full acknowledgment of the same. You agree that your Submissions will not
                violate any right of any third-party, including copyright, trademark, privacy, personality or other
                personal or proprietary right. We take no responsibility and assume no liability for any Submissions
                submitted by you.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 9 – Third Party Vendors & Nutrition Influencers</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                You understand that Nutritt does not employ the third-party food vendors or nutrition influencers who
                can be found on our Website and Platform and that there is no agency relationship between Nutritt and
                the third-party food vendors or nutrition influencers, all of whom are separate persons or entities. The
                nutrition plans and meals are produced, created, listed, created, and sold directly by these independent
                third-parties. Due to this, Nutritt cannot make any warranties about their quality, safety, or similar.
                All information provided to you is informational in nature and should not be solely relied on by you. We
                advise you to speak with a licensed medical professional or licensed nutritionist before using our
                Services. Any legal claim(s) related to a nutrition plan you have selected or meals you have purchased
                must be brought directly against the third-party vendor or nutrition influencer. We recommend trying to
                resolve everything before taking any such legal actions.<br/><br/>
                By using the Services and our Platform, you expressly waive Nutritt, our employees, agents, owners, and
                principals from all liability arising from your use of any content, plans, websites, meals, or services
                provided by third-parties.<br/><br/>
                YOU AGREE THAT NUTRITT WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE, DIRECTLY OR
                INDIRECTLY, FOR ANY NUTRITION CONTENT OF ANY KIND OR MEALS PURCHASED DIRECTLY FROM OUR THIRD-PARTY
                VENDORS OR NUTRITION INFLUENCERS, OR FOR ANY HARM RELATED THERETO, OR FOR ANY DAMAGES OR LOSS CAUSED OR
                ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR BUSINESS PRACTICES
                OF ANY THIRD-PARTY. YOU ACKNOWLEDGE AND AGREE THAT NUTRITT IS A PLATFORM PROVIDING A SPACE FOR
                THIRD-PARTIES TO PROVIDE THEIR VARIOUS SERVICES AND DOES NOT CREATE, SUPPLY, OR DELIVER THE FOOD PLANS
                OR MEALS THROUGH OUR SERVICES.<br/><br/>
                Our Platform and Website contains links to third-party websites or services that are not owned or
                controlled by us. Nutritt has no control over, and assumes no responsibility for, the content, privacy
                policies, or business practices of any third-party companies or persons. We strongly encourage you to
                read the terms and conditions and privacy policies of any third-party websites or services that you
                visit.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 10 – Copyright Infringement</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                If you believe that your copyright has been infringed by Nutritt or our third-party vendors or nutrition
                influencers, please immediately send us a notice to info@nutritt.com with the following information:
            </Typography>
        </div>
        <div>
            <ol>
                <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an
                    exclusive right that is allegedly infringed.
                </li>
                <br/>
                <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted
                    works at a single online site are covered by a single notification, a representative list of such
                    works at that site.
                </li>
                <br/>
                <li>Identification of the material that is claimed to be infringing or to be the subject of infringing
                    activity and that is to be removed or access to which is to be disabled, and information reasonably
                    sufficient to permit the service provider to locate the material.
                </li>
                <br/>
                <li>Information reasonably sufficient to permit the service provider to contact the complaining party,
                    such as an address, telephone number, and, if available, an electronic mail address at which the
                    complaining party may be contacted.
                </li>
                <br/>
                <li>A statement that the complaining party has a good faith belief that use of the material in the
                    manner complained of is not authorized by the copyright owner, its agent, or the law.
                </li>
                <br/>
                <li>A statement that the information in the notification is accurate, and under penalty of perjury, that
                    the complaining party is authorized to act on behalf of the owner of an exclusive right that is
                    allegedly infringed.
                </li>
            </ol>
        </div>
        <br/>
        <div>
            <Typography>
                We respond to notices of copyright infringement immediately and take any such allegations seriously. The
                reported content will be taken down within seventy-two (72) hours of written notice. We do not mediate
                disputes. However, if content that is alleged to be infringing, in our opinion, has no merit, the
                content will be placed back on our Website or Platform.<br/><br/>
                Nutritt prohibits any infringement of intellectual property rights by any user of the Services.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 11 – Term & Errors</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                These Terms will remain active and in full force and effect so long as they are posted on our
                Website.<br/><br/>
                Occasionally there may be information on our Website or Platform that contains typographical errors,
                inaccuracies or omissions that may relate to descriptions, pricing, promotions, offers, and
                availability. We reserve the right to correct any errors, inaccuracies or omissions, at any time.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 12 - Disclaimer</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                OUR WEBSITE, PLATFORM, AND SERVICES ARE PROVIDED 'AS IS' AND, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
                NUTRITT AND ITS OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, THIRD PARTY
                PROVIDERS AND AFFILIATES, EXCLUDE ALL REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED
                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, AND FITNESS
                FOR A PARTICULAR PURPOSE.<br/><br/>
                WE EXPRESSLY DISCLAIM ANY LIABILITY FOR LOSS OR DAMAGE SUSTAINED BY YOU AS A RESULT OF USING OUR
                SERVICES.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 13 – Limitation of Liability</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                WE SHALL NOT BE LIABLE TO YOU FOR INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
                PROFITS, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR SHALL WE BE HELD LIABLE FOR
                DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL. IN NO EVENT SHALL
                OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED THE TOTAL AMOUNT NUTRITT
                RECEIVED IN CONNECTION WITH YOUR SERVICES.<br/><br/>
                YOU AGREE THAT NO CLAIMS OR ACTION IN CONTRACT, WARRANTY, OR IN TORT (INCLUDING NEGLIGENCE) ARISING OUT
                OF, OR RELATED TO, THE USE OF OUR WEBSITE, OUR SERVICES, OR THESE TERMS MAY BE BROUGHT MORE THAN ONE (1)
                YEAR AFTER THE CAUSE OF ACTION RELATING TO SUCH CLAIM OR ACTION AROSE. IF YOU ARE DISSATISFIED WITH OUR
                SERVICES, YOU ARE NOT REQUIRED TO CONTINUE TO USE OUR SERVICES. WE HAVE NO OTHER OBLIGATION, LIABILITY,
                OR RESPONSIBILITY TO YOU.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 14 – Indemnification</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                You agree to defend, indemnify and hold Nutritt, its agents, employees, directors, officers, owners,
                etc. harmless for any loss, damages or costs, including reasonable attorneys' fees, resulting from any
                claim, action, or demand arising from (i) your use of the Website or Platform; or (ii) your breach of
                these Terms.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 15 – General Provisions</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <u>Entire Agreement.</u> These Terms contains the entire agreement between you and Nutritt except for
                any Services-specific information found on our Website (for example, the cost of a meal).<br/><br/>
                <u>Waiver.</u> The failure by Nutritt to enforce any provision of these Terms shall not be construed as
                a waiver or limitation of our right to subsequently enforce and compel strict compliance with every
                provision of this Agreement.<br/><br/>
                <u>Assignment.</u> You may not assign, pledge, delegate,or otherwise transfer any of your rights or
                obligations under this Agreement without Nutritt prior written consent.<br/><br/>
                <u>Relationship.</u> Nothing in these Terms shall create,or is intended to create an agency, employment,
                franchise, joint venture, or partnership relationship between you and Nutritt.<br/><br/>
                <u>Applicable Law/Dispute Resolution.</u> This Agreement shall be governed by the laws of the State of
                New York. Any dispute arising from this Agreement shall be subject to binding arbitration in New York
                city. The prevailing Party in any dispute shall be entitled to recover its/his/her reasonable attorney’s
                fees and costs. The governing rules shall be the rules,then-implemented, by the American Arbitration
                Association commercial division. Nutritt and you agree that any such final decisions may be presented to
                a court of competent jurisdiction for purposes of being confirmed as a judgment enforceable under the
                law in which that party is domiciled or where their headquarters are located. Should either party forego
                arbitration,that party shall be barred from recovering their attorneys’ fees or costs.<br/><br/>
                <u>Severability.</u> If any provision of this Agreement shall be held to be invalid or unenforceable for
                any reason, the remaining provisions shall continue to be valid and enforceable. If an arbitrator or
                panel of arbitrators finds that any provision of thisAgreement is invalid or unenforceable, but that by
                limiting such provision it would become valid and enforceable, then such provision shall be deemed to be
                written, construed, and enforced as so limited.<br/><br/>
                <u>Updates.</u> We recommend that you check the Terms periodically for updates.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 16 – Privacy Policy</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Please refer to our full Privacy Policy which includes information about how we collect, use, and
                disclose information about you.<br/><br/>
                If you are a California or Nevada resident, please also review our additional privacy disclosures that
                are directed towards you.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 17 – FTC Disclosures</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                In connection with our Platform and providing you with the Services: (i) we may run advertisements on
                our Website andPlatform concerning restaurants or other food providers who may provide us compensation
                to be advertised on our Website; and (ii) we may receive compensation if you click one of the links on
                our Website or Platform and purchase meals our third-party food vendors.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 18 – Questions</b>
            </Typography>
            <br/>
            <Typography>
                If you have any questions or comments regarding these Terms, please feel free to contact us by email at
                info@nutritt.com
            </Typography>
        </div>
        <br/>
    </div>
}


export default TermsAndConditions;