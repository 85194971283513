import styled from 'styled-components';
import { useCallback, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import 'animate.css'
import { toast } from 'react-toastify';
import Progress from 'components/Progress';
import { useLocation } from 'react-router-dom';

const HandleNutritionistModalStyle = styled.section`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9;
    --animate-duration: 500ms;
    .shade{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #51635d;
        opacity: 0.5;
    }
    .modal_wrapper{
        width: 100%;
        max-width: 370px;
        padding: 30px 48px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        position: relative;
        z-index: 2;
        .title{
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #51635D;
            margin-bottom: 16px;
        }
        .form_group{
            margin-bottom: 24px;
            label{
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #51635D;
                padding: 9px 0;
                display: block;
            }
            .form_control{
                padding: 9px 10px 9px 14px;
                background: #FFFFFF;
                border: 1px solid #E0E3E0;
                border-radius: 6px;
                font-weight: 400;
                font-size: 14px !important;
                line-height: 16px;
                color: #51635D;
                width: 100%;
                height: 44px;
                display: block;
                &:disabled{
                    background-color: #fff !important;
                    color: #51635D !important;
                    cursor: auto !important;
                }
            }
        }
        .btn{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            padding: 12px;
            width: 100%;
            border-radius: 50px;
            cursor: pointer;
            transition: all 0.2s;
            color: #51635D;
            background-color: #fff;
            border: 1px solid #97DC76 !important;
            &.save_btn{
                color: #FFFFFF;
                background-color: #52C41A !important;
                margin-bottom: 10px;
                &:disabled{
                    background-color: #c3c3c3 !important;
                    border: 1px solid #c3c3c3 !important;
                    cursor: not-allowed;
                }
            }
        }
    }
    @media (max-width:576px){
        .modal_wrapper{
            max-width: 327px;
        }
    }
`

export const CreateNutritionistModal = ({ setIsCreateActive,setUsers }) => {
    const [newNutritionistName, setNewNutritionistName] = useState('');
    const [createNutritionist, { isLoading, error: createError }] = useRequest(`nutritionist/create`, 'post');
    const {pathname} = useLocation();
    const handleCreateSubmit = async (e) => {
        e.preventDefault();
        const res = await createNutritionist({ body: { name: newNutritionistName } });
        if (res && Object.keys(res).length) {
          setUsers((prevUsers) => [res, ...prevUsers]);
        }
        if (createError) {
          toast.error("There is an error creating nutritionist");
          return;
        }
        setNewNutritionistName('');
        toast.success("Nutritionist Created Successfully");
        setIsCreateActive(false);
      };
      
    const handleClose = (e) => {
        e.preventDefault();
        setIsCreateActive(false);
    }

    const handleChange = useCallback((e) => {
        const trimValue = e.target.value.replace(/\s/g, "");
        setNewNutritionistName(trimValue)
    }, []);

    return (
        <>
            <HandleNutritionistModalStyle className='animate__animated animate__fadeIn'>
                <div className='shade' onClick={handleClose} />
                <div className='modal_wrapper'>
                    <form onSubmit={handleCreateSubmit}>
                        <h2 className='title'>{pathname === "/admin/users/provider-management" ? "Create provider" : "Create coach"}</h2>
                        <div className='form_group'>
                            <label htmlFor='name'>Name</label>
                            <input type='text' id="name" name="name" onChange={handleChange} value={newNutritionistName} className='form_control' />
                        </div>
                        <button
                            disabled={!newNutritionistName || isLoading}
                            type="submit"
                            className='btn save_btn'
                        >
                            Create
                        </button>
                        <button className='btn cancel_btn' onClick={handleClose}>Cancel</button>
                    </form>
                    {isLoading && <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><Progress/></div>}
                </div>
            </HandleNutritionistModalStyle>
        </>
    )
}

export const InviteNutritionistModal = ({setIsInviteActive}) => {
    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteNutritionist, { isLoading, error, state, setState: setSuccess }] = useRequest(
        `nutritionist/invite`,
        'post',
    );
    const {pathname} = useLocation();
    const [isEmailExist] = useRequest('auth/isEmailPhoneNumberExist', 'get');

    const handleInviteNutritionit = async (e) => {
        e.preventDefault();
        const findEmail = await isEmailExist({ path: `auth/isEmailPhoneNumberExist?email=${inviteEmail}` });
        if (findEmail?.data?.isEmailPhoneNumberExist) {
            toast.error('Email already exists. Try with another email.');
            return;
        }
        const res = await inviteNutritionist({ body: { email: inviteEmail } });
        console.log("hello");
        setSuccess(res);
        if(error){
            toast.error("Can't invite nutritionist");
            return;
        }
        if(!res?.success){
            toast.error(res?.message);
            return;
        }
        setInviteEmail('');
        toast.success("Nutritionist Invited Successfully");
        setIsInviteActive(false);
    };
    const handleClose = (e) => {
        e.preventDefault();
        setIsInviteActive(false);
    }
    return (
        <>
            <HandleNutritionistModalStyle className='animate__animated animate__fadeIn'>
                <div className='shade' onClick={handleClose} />
                <div className='modal_wrapper'>
                    <form onSubmit={handleInviteNutritionit}>
                        <h2 className='title'>{pathname === "/admin/users/provider-management" ? "Invite provider" : "Invite coach"}</h2>
                        <div className='form_group'>
                            <label htmlFor='email'>Email</label>
                            <input type='email' id="email" name="email" onChange={(e) => setInviteEmail(e.target.value)} value={inviteEmail} className='form_control' />
                        </div>
                        <button
                            disabled={!inviteEmail || isLoading}
                            type="submit"
                            className='btn save_btn'
                        >
                            Invite
                        </button>
                        <button className='btn cancel_btn' onClick={handleClose}>Cancel</button>
                    </form>
                    {isLoading && <Progress/>}
                </div>
            </HandleNutritionistModalStyle>
        </>
    )
}