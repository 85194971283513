import { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import tagIcons from 'components/TagIcons';
import MealTag from './MealTag';
import Fade from '@mui/material/Fade';


export default function MealMoreTag({
  providerName,
  Icon,
  tag,
  tags,
  recommendedTags = [],
  notRecommendedRestrictions = [],
}) {
  const [open, setOpen] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isNotRecommended, setIsNotRecommended] = useState(false);
  const [showAnim, setShowAnim] = useState(true);

  const handleIsRecommended = () => {
    if (notRecommendedRestrictions.length !== 0 && tag) {
      notRecommendedRestrictions.forEach((notRecommendedRestriction) => {
        if (notRecommendedRestriction.toLowerCase() == tag.toLowerCase()) {
          return setIsNotRecommended(true);
        }
      });
    }
  };

  const handleRecommended = () => {
    if (recommendedTags.length !== 0 && notRecommendedRestrictions.length === 0 && tag) {
      recommendedTags.forEach((recommendedTag) => {
        if (recommendedTag.toLowerCase() === tag.toLowerCase()) {
          return setIsRecommended(true);
        }
      });
    }
  };
  useEffect(() => {
    handleIsRecommended();
    handleRecommended();
  }, [recommendedTags, notRecommendedRestrictions]);

  const [modalOpen, setmodalOpen] = useState(false)
  const handleOpen = () => {
    setmodalOpen(true);
  };
  const handleClose = () => {
    setShowAnim(false);
    setTimeout(() => {
    setmodalOpen(false);
  }, 500)
  };

  useEffect(() => {
    modalOpen ? document.body.classList.add('bodyFixed') : document.body.classList.remove('bodyFixed')
  }, [modalOpen])

  return (
    <div className='openMoreTags' onClick={(e) => e.stopPropagation()}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpen(false)}
          open={open}
          title={tag}
          enterDelay={3000}
          
        >
          <div key={tag} onClick={handleOpen} className={`tag-container more_icon ${open ? isRecommended  ? 'recommded' 
                : isNotRecommended 
                ? 'no-Recommended' 
                : 'activeTooltip' : '' }`}>
            <span className="icon-wrapper"
              onClick={() => setOpen(true)}
            >
              <img src='/icons/more.svg' alt="More Tag" />
            </span>
           <span className="tag-name">{tag}</span> 
          </div>
        </Tooltip>
      </ClickAwayListener>
     
      <Modal
        hideBackdrop
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="allTag-modal"
        
      >
        <Fade in={modalOpen}>
        <Box className='tagModal_popup'>
            <div className="modal-close">
                <button onClick={handleClose} className='closeIcon'>
                    <img  src='/icons/close.svg' alt='Close Circle' style={{marginRight:-4,height:24,width:24}}/>
                </button>
            </div>
          <h2 id="child-modal-title">{providerName}</h2>
          <p id="child-modal-description">Tags: </p>
          <div className='tags-inner-page'>
            {tags.map((tag, index) => (
                <div className='multiTags-listing' key={index}>
                    <MealTag
                        Icon={tagIcons[tag]}
                        tag={tag}
                        hideTooltip={true}
                        recommendedTags={recommendedTags}
                        notRecommendedRestrictions={notRecommendedRestrictions}
                    />
                </div>
            ))}
          </div>
        </Box>
        </Fade>
      </Modal>
    </div>
  );
}
