import { style } from '@mui/system';
import styled from 'styled-components';
import ErrorComponent from './ErrorComponent';

const StyledButton = styled.button`
  background: ${({ background }) => background};
  color: ${({ txtcolor }) => txtcolor};
  cursor: ${({ isLoading, disabled }) =>
    disabled ? 'not-allowed' : isLoading ? 'wait' : 'pointer'};
    width: 70%;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 28px !important;
    padding: 2px !important;
    justify-content: center;
    margin: auto;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  :hover {
    background: ${({ disabled, background }) => (disabled ? background : '#379608')};
  }
  // :active {
  //   box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
  //     1px -1px 20px 0px rgb(0 0 0 / 12%);
  // }

  @media (max-width: 991px) {
    height: 50px;
    border-radius: 21px;
    font-size: 18px;
    font-family: Roboto;
    line-height: 39px;
    width: 100%;
  }
`;
export default function SubmitButton({
  type,
  isLoading,
  background = '#52c41a',
  txtcolor =  '#fff',
  children,
  style,
  error,
  disabled,
  onClick,
  ...props
}) {
  const bgColor = disabled ? '#E0E3E0' : background;
 const color = disabled ? '#949D9B' : txtcolor;
  return (
    <>
      {error && (
        <ErrorComponent title="" error={error} style={{ marginTop: '15px', marginBottom: '5px' }} />
      )}
      <StyledButton
        type={type}
        background={bgColor}
        txtcolor = {color}
        style={style}
        disabled={disabled}
        isLoading={isLoading}
        onClick={onClick}
        {...props}
      >
        {children}
      </StyledButton>
    </>
  );
}

