import { Container, CssBaseline, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Progress from '../components/Progress';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { FULFILLMENT_STATUS } from '../constants';
import { Link } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { format } from 'date-fns';
import { addTimeInDate } from '../utils';
import { useAppContext } from 'context/appContext';
import moment from 'moment';
import { useRequest } from 'hooks/useRequest';

const useStylesButtonGroup = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const ButtonGroupComponent = ({ buttons }) => {
    
  const classes = useStylesButtonGroup();
  return (
    <div className={classes.root}>
      <ButtonGroup color="secondary" aria-label="outlined secondary button group">
        {buttons.map(({ label, onClick }, i) => (
          <Button key={`${label}-${i}`} data-testid={label} onClick={onClick}>
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

const useStylesList = makeStyles((theme) => ({

  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const orderContentStyle = makeStyles((theme) => ({
  orderContent: {
    color: 'gray',
    fontSize: '.8rem',
  },
  label: {
    fontWeight: 600,
  },
  mealId: {
    paddingRight: '8px',
  },
}));

const MealItem = ({ meal, quantity, index }) => {
  const id=meal?.id;
  const description=meal?.description;
  const img=meal?.img;
  const name=meal?.name;
  const classes = orderContentStyle();
  const indexView = <span className={classes.mealId}>{index + 1}.</span>;
  const mealIdView = (
    <span>
      Meal Id: {id} {'   '}
      <br />
    </span>
  );
  const quantityAndNameView = (
    <span>
      {quantity} x {name}
      <br />
      <img src={img} alt={name} width={100} />
      <br />
    </span>
  );
  const descriptionView = (
    <span>
      Description: {description}
      <br />
    </span>
  );

  return (
    <ListItem key={`${index}${id}`}>
      <ListItemText
        primary={
          <>
            {indexView}
            {quantityAndNameView}
            {mealIdView}
            {descriptionView}
          </>
        }
      />
    </ListItem>
  );
};

const ComboItem = ({ components, index, quantity }) => {
  const txt = `${index + 1}. ${quantity} x COMBO: ${components
    .map(({ name: selection, comboComponent: { name: component } }) => `${component}: ${selection}`)
    .join(',\n')}`;
  return (
    <ListItem>
      <ListItemText primary={txt} />
    </ListItem>
  );
};
const Lines = ({ lines }) => {
  const classes = useStylesList();
  return (
    <div className={classes.root}>
      <List component="nav">
        {lines?.map((item, i) => {
          return isEmpty(item.components) ? (
            <MealItem key={`${i}${item.quantity}`} {...item} index={i} />
          ) : (
            <ComboItem {...item} index={i} />
          );
        })}
      </List>
    </div>
  );
};
const OrderPage = (props) => {
  const { orderId } = useParams();
  const query = new URLSearchParams(window.location.search);
  const providerId=query.get("providerId")
  const { provider } = useAppContext();
  const classes = orderContentStyle();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [orderData, setOrder] = useState({
    orderMain: {
      client: { memberName: '' },
      dateOrdered: '',
      address: JSON.stringify({ label: '' }),
      address2: '',
    },
    isDelivery: '',
    selectedDate: '',
    selectedTime: '',
    fulfillmentStatus: '',
    lines: [],
    providerOrderSummary:'',
  });
  const [getOrderApi]=useRequest();  
  const getProviderOrderDetails = async ({orderId,providerId }) => {
    const path=`fulfill/${orderId}?providerId=${providerId}`
    return await getOrderApi({
      path,
    })
  };

  const [updateProviderOrderStatusApi] = useRequest();
  const updateProviderOrderStatus = async ({ orderId, providerId, newStatus }) => {
    const path = `fulfill/`
    return await updateProviderOrderStatusApi({
      path,
      method: 'post',
      body: { newStatus, providerId, orderIds: [orderId] }
    })
  };
  const getButtons = (fulfillmentStatus) =>
    fulfillmentStatus == FULFILLMENT_STATUS.RECEIVED
      ? [
          {
            label: 'Accept',
            onClick: () =>
              updateProviderOrderStatus({
                orderId,
                providerId: provider.id||providerId,
                newStatus: FULFILLMENT_STATUS.ACCEPTED,
              }).then(setOrder),
          },
          {
            label: 'Refuse',
            onClick: () =>
              updateProviderOrderStatus({
                orderId,
                providerId: provider.id||providerId,
                newStatus: FULFILLMENT_STATUS.REFUSED,
              }).then(setOrder),
          },
        ]
      : fulfillmentStatus == FULFILLMENT_STATUS.ACCEPTED
      ? [
          {
            label: 'Click to Complete',
            onClick: () =>
              updateProviderOrderStatus({
                orderId,
                providerId: provider.id||providerId,
                newStatus: FULFILLMENT_STATUS.COMPLETED,
              }).then(setOrder),
          },
        ]
      : [];

  useEffect(() => {
    getProviderOrderDetails({ orderId, providerId: provider.id ||providerId})
      .then((data) => {
        setOrder(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  const {
    orderMain = {},
    isDelivery,
    fulfillmentStatus,
    createdAt,
    selectedDate,
    selectedTime,
    totalAmount,
    deliveryData,
    providerOrderSummary,
  } = orderData;
  const {
    user: { id, email: clientEmail, profile: { name: clientName } = {} } = {},
  } = orderMain;

  const ToBeCompletedDate = selectedDate?.split('T')[0];

  const displayDateTime = (date, time) => {
    if (time?.includes("-")) {
      return `${moment(date).format('ddd Do MMM YYYY')}, ${time}`
    }
    return format(addTimeInDate(moment(date), time), 'ccc do LLL y p')
  }

  if (isLoading) {
    return <Progress />;
  }
  
  if (isError) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <Typography style={{ color: 'red', padding: '10px' }}>
        Sorry, we couldn't find the order you're looking for.
        </Typography>
      </div>
    )
  }

  const {subTotal, userSubTotal, providerSubTotal, deliveryFee, taxAmount, providerTaxAmount} = providerOrderSummary || {};
  const totalPayout = ((providerSubTotal / 100) + (deliveryFee / 100) + ((providerTaxAmount? providerTaxAmount : taxAmount) / 100)).toFixed(2);
  const neworderId = orderMain?.friendlyOrderNum !==""? orderMain?.friendlyOrderNum: `NT-${Number(orderId) + 1000}`;

  const orderIdView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Order Id</span> : {neworderId}
    </span>
  );

  const orderedDateView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Ordered at</span> : {new Date(createdAt).toLocaleDateString()}
    </span>
  );

  const orderTypeView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Method</span>: {isDelivery ? 'Delivery' : 'Pickup'}
    </span>
  );

  const orderSubTotalView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Subtotal</span>: ${((userSubTotal? userSubTotal: subTotal) / 100).toFixed(2)}
    </span>
  );

  const orderVendorSubtotalView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Vender Subtotal</span>: ${(providerSubTotal / 100).toFixed(2)}
      <br/>
      {providerOrderSummary?.note && <small className={classes.label}>Note: {providerOrderSummary?.note}</small>}
    </span>
  );
  
  const ordeDeliveryFeeView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Delivery Fee</span>: ${(deliveryFee / 100).toFixed(2)}
    </span>
  );

  const orderTaxView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Tax</span>: ${((providerTaxAmount? providerTaxAmount : taxAmount) / 100).toFixed(2)}
    </span>
  );

  const orderTotalPayoutView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Total Payout</span>: ${totalPayout}
    </span>
  );

  const completeDateView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>To Be Completed</span>:{' '}
      {displayDateTime(ToBeCompletedDate,selectedTime)}
    </span>
  );

  const orderPriceView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Price</span>: ${(totalAmount / 100).toFixed(2)}
    </span>
  );

  const orderClientView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Customer Name</span>: {clientName} <br/>
        {deliveryData?.note ? <><span className={classes.label}>Customer Note : </span>{deliveryData.note}</> : ''}
    </span>
  );

  const shippingAddressView = (
    <span className={classes.orderContent}>
      <span className={classes.label}>Deliver To: </span>:{' '}
      {deliveryData?.deliveryAddress && ` ${deliveryData.deliveryAddress}`}
      {deliveryData?.deliveryAddress2 && `,  ${deliveryData.deliveryAddress2}`}
      {deliveryData?.deliveryCity && `,  ${deliveryData.deliveryCity}`}
      {deliveryData?.deliveryState && `,  ${deliveryData.deliveryState}`}
      {deliveryData?.deliveryZip && `,  ${deliveryData.deliveryZip}`}
    </span>
  );


  const mainTxt = (
    <>
      <Typography variant="body2" color="textSecondary" component="h3">
        {orderIdView}
      </Typography>

      <Typography variant="body2" color="textSecondary" component="h3">
        {orderedDateView}
      </Typography>

      <Typography variant="body2" color="textSecondary" component="h3">
        {orderTypeView}
      </Typography>

      {/* <Typography variant="body2" color="textSecondary" component="h3">
        {orderSubTotalView}
      </Typography> */}

      <Typography variant="body2" color="textSecondary" component="h3">
        {orderVendorSubtotalView}
      </Typography>

      <Typography variant="body2" color="textSecondary" component="h3">
        {ordeDeliveryFeeView}
      </Typography>

      <Typography variant="body2" color="textSecondary" component="h3">
        {orderTaxView}
      </Typography>

      <Typography variant="body2" color="textSecondary" component="h3">
        {orderTotalPayoutView}
      </Typography>
      
      <Typography variant="body2" color="textSecondary" component="h3">
        {completeDateView}
      </Typography>
    </>
  );

  const addressTxt = (
    <>
      <Typography variant="body2" color="textSecondary" component="h3">
        {orderClientView}
      </Typography>

      {isDelivery && (
        <Typography variant="body2" color="textSecondary" component="h3">
          {shippingAddressView}
        </Typography>
      )}
    </>
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Link to="/provider/orders">Back to Orders</Link>
      <hr />
      <Typography variant="body2" color="textSecondary" component="h3">
        {mainTxt}
      </Typography>
      {addressTxt}
      <ButtonGroupComponent buttons={getButtons(fulfillmentStatus)} />
      <Lines lines={orderData.lines} />
    </Container>
  );
};
export default OrderPage;
