import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Styled from 'styled-components';
import Input from 'components/Input';
import PantryDate from './PantryDate';
import Progress  from 'components/Progress';
import { useShopContext } from 'context/shopContext';
import { Button } from 'components/elements/button/Button';

const isAndroidWebView = () => {
  return window.ReactNativeWebView && /Android/i.test(navigator.userAgent);
};
const CustomMealTabWrapper = Styled.div`
  padding: 0 16px;
  font-family: 'Gilroy';
  label {
    color: #51635D;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
  }
  .form-inputs-wrapper {
    position: relative;
    z-index: 4;
    margin-bottom: ${isAndroidWebView() ? "-20px" : "20px"};
    overflow: auto;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      height: 0;
      display: none;
    }
    .input-wrapper {
      width: 100%;
      margin: 0;
      input {
        height: 44px;
        padding-left: 14px;
        background-color: #fff;
        color : #51635D
      }
    }
    .calendar-icon{
      width: 20px;
      height: 20px;
    }
    .input-container{
      margin-top: 12px;
      label{
        color: #00092D;
        font-size: 14px;
        font-weight: 600;
        line-height: 32px;
        height: 32px;
        display: block;
        margin-bottom: 0px;
      }
      .field-wrapper{
        input{
          font-size: 14px !important;
          font-weight: 500;
          line-height: 20px;
          font-family: 'Gilroy';
          &:placeholder{
            color: #949D9B;
          }
          &:focus{
            border-color: #00092D !important;
          }
        }
      }
    }
  }
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
  input.focused {
    z-index: 3;
  }
  input.fieldError {
    border-color: rgb(224, 36, 47);
  }
  .add-more-meals-date {
    height: 68px;
    margin: 16px 0 0;
    color: #00092D;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    span {
      color: #757E89;
    }
  }
  .display-macronutrients-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    .input-wrapper input {
      padding-right: 30px
    }
  }
  .select-meals-wrapper {
    margin-top: ${isAndroidWebView() ? "10px" : "32px"};     
    display: flex;
    justify-content: space-between;
    align-items: center;
    .meal-radio-wrapper {
      position: relative;
      width: 64px;
      height: 90px;
      border: 1px solid #E0E3E0;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.mealSelected {
        border-color: #97DC76;
        .radio-circle {
          display: none;
        }
      }
    }
    input {
      position: absolute;
      visibility: hidden;
    }
    .tick-icon,
    .radio-circle {
      position: absolute;
      bottom: -6px;
      left: 25px;
      width: 13.33px;
      height: 13.13px;
      background-color: #fff; 
    }
    .meal-img {
      width: 39px;
      height: 39px;
    }
    .radio-circle {
      border: 1.5px solid #97DC76;
      border-radius: 50%;
    }
  }
  .form-btns-wrapper {
    margin-bottom:${isAndroidWebView() ? "10%" : ""};
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 50px 16px 23px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
    > div{
      gap: 13px;
    }
    button{
      width: 50%;
      justify-content: center;
      padding: 0;
      font-size: 16px;
      font-weight: 700;
    }
    .combo_left{
      background: #F0F2F4;
      border: 0px !important;
    }
    .combo_right{
      background: #000A2C;
      &:disabled{
        background: #F0F2F4 !important;
      }
    }
  }

  .error-message {
    position: absolute;
    top: auto;
    left: auto;
    margin-top: 2px;
    color: rgb(224, 36, 47);
    font-size: 13px;
    line-height: 16px;
  }
`;

const meals = [
  {id:1,title:'dish'},
  {id:2,title:'orange-juice'},
   {id:3,title:'snack'},
    {id:4,title:'apple'}
  ];
export default function CustomMealTab({
  setIsLogMealsModal,
  isLogMealsModal,
  activeDay,
  setActiveDay,
  getdiaryData,
  customMealDiary,
  isCustomMealSaving,
  form,
  setForm,
  mealData,
  updateFullDiaryMeals,
}) {
  const [isFoodSelected, setIsFoodSelected] = useState([]);
  const [isAddMacronutrients, setIsAddMacronutrients] = useState(false);
  const [isMask, setIsMask] = useState(false);
  const [inputFocus, setInputFocus] = useState();
  const [formInputHeight, setFormInputHeight] = useState();
  const [message, setMessage] = useState({meal: '', calories: ''})
  const { consumedMealType } = useShopContext();

  useEffect(() => {
    isAndroidWebView() ? setFormInputHeight("75vh") : setFormInputHeight(window.innerHeight - 200);
  }, []);

  useEffect(() => {
    setIsFoodSelected(form?.img);
    setIsAddMacronutrients(false);
  }, [isLogMealsModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "img") {
      setForm((prevState) => ({ ...prevState, [name]: value }))
    } else if (name === "name") {
      if (value.length >= 35) {
        return
      } else {
        setForm((prevState) => ({ ...prevState, [name]: value }))
      }
    } else if (name === "calories" || name === "fat" || name === "carbs" || name === "protein") {
      if (value > 5000 || value < 0) {
        return;
      } else {
        setForm((prevState) => ({ ...prevState, [name]: parseInt(value) }))
      }
    }
    else {
      setForm((prevState) => ({ ...prevState, [name]: parseInt(value) }));
    }
  };

  const handleCustMealBtnClick = async () => {
    await customMealDiary({
      body: {
        ...form,
        carbs: form?.carbs ? form.carbs : 0,
        protein: form?.protein ? form.protein : 0,
        fat: form?.fat ? form.fat : 0,
        createdAt: new Date(activeDay).toISOString(),
        calories: parseInt(form?.calories),
        categoryType: consumedMealType
}
    }).then( async (res) => {
      if(res.success == true) {
        setForm(mealData)
        updateFullDiaryMeals(res.result)
        setIsLogMealsModal(false);
       
      }
    }) .catch(() => {
      !form.name && setMessage((prevState) => ({...prevState, meal: 'Meal name required'}));
      isNaN(form.calories) && setMessage((prevState) => ({...prevState, calories: 'Calorie intake required'}));
    });
  }
 const foodSelected = (item) => {
    if(    isFoodSelected.indexOf(item) === -1    ){
      setIsFoodSelected(`/images/${item}.svg`)
      setForm((prevState) => ({ ...prevState, img: `/images/${item}.svg` })); 
    }
};
  const logMealModalCancel = (e) => {
    e.preventDefault();
    setIsLogMealsModal(false);
    window.scrollTo(0, 0);
  }
 
  const handleKeyDown = (e) => {
    if (e.key === "e"|| e.key==="-") {
        e.preventDefault();
    }
};

  return (
    <CustomMealTabWrapper>
      {isMask && <div className="mask" onClick={() => setIsMask(false)} />}
      <form>
        <div className="form-inputs-wrapper" style={{ height: formInputHeight }}>
          {isLogMealsModal && (
            <PantryDate
              setActiveDay={setActiveDay}
              activeDay={activeDay}
              isLogMealsModal={isLogMealsModal}
            />
          )}
          <Input
            label="Meal"
            name="name"
            placeholder="Food name"
            value={form.name}
            onChange={handleChange}
            className={classNames({ focused: inputFocus == 'name' }, {fieldError: message.meal?.length})}
            required
            onFocus={() => setMessage((prevState) => ({...prevState, meal: ''}))}
            onBlur={(e) => {
              const {value} = e.target;
              !value && setMessage((prevState) => ({...prevState, meal: 'Meal name required'}));
            }}
          />
          {message.meal && <div className='error-message'>{message.meal}</div>}
          {isCustomMealSaving ? 
          <Progress/>: "" }
          <Input
            type="number"
            label="Calories"
            name="calories"
            placeholder="0"
            pattern="\d*"
            value={form.calories}
            onChange={handleChange}
            info="Cals"
            className={classNames({ focused: inputFocus == 'calories' }, {fieldError: message.calories?.length})}
            required
            onKeyDown={handleKeyDown}
            onFocus={() => setMessage((prevState) => ({...prevState, calories: ''}))}
            onBlur={(e) => {
              const {value} = e.target;
              !value && setMessage((prevState) => ({...prevState, calories: 'Calorie intake required'}));
            }}
          />
          {message.calories && <div className='error-message'>{message.calories}</div>}
          {isAddMacronutrients ? (
            <div className="display-macronutrients-wrapper">
              <Input
                type="number"
                label="Carbs"
                name="carbs"
                placeholder="0"
                pattern="\d*"
                value={form.carbs}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className={classNames({ focused: inputFocus == 'carbs' })}
                info="g"
              />
              <Input
                type="number"
                label="Fat"
                name="fat"
                placeholder="0"
                pattern="\d*"
                value={form.fat}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className={classNames({ focused: inputFocus == 'fat' })}
                info="g"
              />
              <Input
                type="number"
                label="Protein"
                name="protein"
                placeholder="0"
                pattern="\d*"
                value={form.protein}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className={classNames({ focused: inputFocus == 'protein' })}
                info="g" 
                
              />
            </div>
          ) : (
            <div className="add-more-meals-date">
              <div onClick={() => setIsAddMacronutrients(true)}>
                + Add macronutrients <span>(optional)</span>
              </div>
            </div>
          )}
        </div>
      </form>
      <div className="form-btns-wrapper">
        <Button 
          type={'button_combo'} 
          comboLeftTitle={'Cancel'} 
          comboLeftClick={logMealModalCancel} 
          comboRightClick={handleCustMealBtnClick} 
          comboRightTitle={'Log meal'} 
          comboRightDisabled={!form.name || !form.calories || isNaN(form.calories)} 
        />
        {/* <button
          onClick={logMealModalCancel}
          className="cancel-btn"
        >
          Cancel
        </button>
        <button type="button" 
        className={classNames({loading: isCustomMealSaving,disabled:!form?.name || isNaN(form?.calories) || form?.calories==="0" || !form?.calories})}
        onClick={ handleCustMealBtnClick}
        disabled={!form.name || !form.calories || isNaN(form.calories)}
        >{isCustomMealSaving ? "Logging" : "Log Meal"}</button> */}
      </div>
    </CustomMealTabWrapper>
  );
}

