import { useEffect, useState } from "react"
import styled from "styled-components"
import { Switch, Route } from "react-router-dom"
import { GetStarted } from "./GetStarted"
import ChooseMealPlanVisitor from "./ChooseMealPlanVisitor"
import classNames from "classnames"
import { useAppContext } from "context/appContext"
import { useLocation } from "react-router-dom"
import VisitorSteps from "./components/VisitorSteps"
import { VisitorContextProvider } from "context/visitorContext"
import Header from "layout/Header"
import VisitorHeaderNew from "./components/VisitorHeaderNew"
import VisitorProviders from "./VisitorProviders"
import VisitorMeals from "./VisitorMeals"
import KitchenHome from "pages/shop/KitchenHome"
import ShopHeader from "layout/ShopHeader_v2"
import { GilroyFontWrapper } from "shared/theme/style"
import CheckDeliveryAvailable from "components/CheckDeliveryAvailable/CheckDeliveryAvailable"
import FooterDesktop from "components/shop/FooterDesktop"

const ShopStyle = styled.div`
  min-height: calc(100vh - 141px);
  &.coachPlan{
    overflow-x: hidden;
 }
  padding-top: 70px;
  &.shopMeals {
    height: 100vh;
    padding-top: 0;
  }
  .shop-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 120;
  }

  .header-sandwich-menu {
    width: 32px;
    height: 32px;
    margin: 0 24px 0 0;
  }

  &.pantryHeader {
    width: 100%;
    padding-top: 0;
    background-color: #f6f5f8 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .shop-header {
      transition: all 0.3s ease-in-out;  
      width: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  @media (max-width: 991px) {
    min-height: auto;
    margin-top: 52px;
    padding-top: 0;
    &.shopMeals {
      height: auto;
    }
    &.pantryHeader header {
      background-color: ${props => props.showChangeDietPlan ? "#FFFFFF" : "#f6f6f8"};
    }

    .header-sandwich-menu {
      width: auto;
      height: auto;
      margin: 0 0 0 10px;
      position: absolute;
      right: 17px;
    }

    span.cart-number {
      left: 10px;
      top: -7px;
      width: auto;
      min-width: 15px;
      height: auto;
      padding: 1px 2px;
      color: #ffffff;
      font-size: 9px;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.05em;
    }

    .place-order-wrapper {
      height: 80px;
      border-radius: 0;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);

      button {
        width: 287px;
        height: 42px;
        color: #fff;
        border-radius: 21px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        flex-shrink: 0;
      }
    }

    &.noFooter + footer{
      display: none;
    }
  }
`;


const VisitorUserNew = () => {
  const [scrollUp, setScrollUp] = useState(false);
  const [prevScroll, setPrevScroll] = useState(window.scrollY);
  const { isMobile, setSelectedProviderId } = useAppContext();
  const { pathname, search } = useLocation();
  const mobStyle = isMobile ? { background: '#F6F5F8' } : undefined;
  const params = new URLSearchParams(search);
  const isNavActive = params.get('navigation');

  window.onscroll = function () {
    if (location.pathname === '/shop/providers') {
      const currScroll = window.scrollY;
      if (prevScroll < currScroll || currScroll < 110) {
        setScrollUp(false);
      } else if (prevScroll > currScroll) {
        setScrollUp(true);
      }
      setPrevScroll(currScroll);
    }
  };

  useEffect(() => {
    if(pathname === '/visitor/kitchens'){
      setSelectedProviderId();
    }
  }, [pathname])

  return (
    <>
    <GilroyFontWrapper>
      <VisitorContextProvider>
        <ShopStyle style={mobStyle}
          className={classNames({
            shopMeals: location.pathname === '/visitor/meals',
            noFooter: isNavActive === 'false'
          })}>
          {
            pathname != '/visitor/get-started' &&
            <div
              className={classNames('shop-header', {
                headerFixed: pathname.includes('/visitor/') && scrollUp,
              })}
            >
              <ShopHeader />
            </div>
          }
          <Switch>
            <Route path="/visitor/plan" component={ChooseMealPlanVisitor} />
            <Route path="/visitor/providers" component={VisitorProviders} />
            <Route path="/visitor/meals" component={VisitorMeals} />
            <Route path="/visitor/get-started" component={GetStarted} />
            <Route path="/visitor/kitchens" render={() => (
            <CheckDeliveryAvailable showAvailableOnly={true}>
              <KitchenHome />
            </CheckDeliveryAvailable>
          )}/>
          </Switch>
          <div style={{ height: '10px', backgroundColor: '#fff' }}></div>
          <FooterDesktop />
        </ShopStyle>
      </VisitorContextProvider>
      </GilroyFontWrapper>
    </>
  )
}

export { VisitorUserNew }