import React from 'react';
import Progress from 'components/Progress';
import LoadingOverlay from "react-loading-overlay";
import styled from 'styled-components';

const LoaderBackground = styled.div`
  position: fixed; 
  z-index: 999; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0, 0, 0, 0.4);
  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
    ._loading_overlay_content {
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        position: fixed;
      }
`

const OverLayLoader = ({ active = false, text = "Please wait..." }) => {
    return <LoaderBackground>
        <LoadingOverlay
            className='loader'
            active={active}
            spinner={<Progress />}
            text={text}
        />
    </LoaderBackground>
}

export default OverLayLoader;