import React from "react";
import {Typography} from '@material-ui/core';
import TermsAndConditions from "pages/termsAndConditions";

const NutritionistTermsAndConditions = () => {
    return <div style={{
        marginLeft: "80px",
        marginRight: "80px"
    }}>
        <div style={{
            display: "grid",
            justifyContent: "center",
            color: "#52c41a",
            marginTop: "80px",
        }}>
            <Typography variant="h3" component="h3">
                Nutritt</Typography>
        </div>
        <br/>
        <div style={{
            display: "grid",
            justifyContent: "center",
        }}>
            <Typography variant="h4" component="h4">
                <b>Nutrition - Terms & Conditions</b>
            </Typography>
        </div>
        <br/>
        <div style={{
            display: "grid",
            justifyContent: "center"
        }}>
            <Typography variant="h5" component="h5">
                “Celebrate the end of diet fatigue”
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                Last Updated: December 21, 2021
            </Typography>
        </div>
        <br/>
        <div style={{
            display: "grid",
            justifyContent: "center",
            textAlign: "center",
        }}>
            <Typography>
                <b>THESE TERMS ARE IMPORTANT AND AFFECT YOUR LEGAL RIGHTS. PLEASE CAREFULLY READ THIS NUTRITION
                    INFLUENCER - TERMS & CONDITIONS PRIOR TO SIGNING UP AND USING OUR PLATFORM. THESE TERMS CONTAIN A
                    BINDING ARBITRATION CLAUSE. PLEASE READ THESE TERMS AND THE ARBITRATION PROVISION SO THAT YOU ARE
                    AWARE OF YOUR LEGAL RIGHTS.</b>
            </Typography>
        </div>
        <br/>
        <div className="selection">
            <Typography>
                <b>Section 1 – Introduction & Consent to Terms</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Welcome to Nutritt, Inc., a Delaware corporation’s (referred to herein as “Nutritt”, “we”, “us”, or
                “our”) Nutrition Influencer - Terms & Conditions (referred to herein as the “Terms”). Nutritt was
                created to help our Members (as defined below) achieve their nutritional goals by utilizing customized
                nutrition plans created by you, our third-party nutrition influencers (a “Nutrition Influencer” or
                “Nutrition Influencers”).<br/><br/>
                <b>If you do not agree to these Terms, we ask that you please not use our nutrition platform (the
                    “Services”). Signing up and becoming a Nutrition Influencer constitutes acceptance of these
                    Terms.</b><br/><br/>
                These Terms apply to all Nutrition Influencers. By using our Platform and signing up to become a
                Nutrition Influencer, you agree to be bound by these Terms.<br/><br/>
                If you are using our Website or our Services on behalf of an entity, you represent and warrant that you
                are authorized to accept these Terms on such entity’s behalf and that such entity agrees to be
                responsible to us if you or that person violates these Terms.<br/><br/>
                We reserve the right to modify the contents of these Terms at any time. You agree that it is your
                responsibility to monitor changes to these Terms. Any modification to these Terms will be notated at the
                top of these Terms.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 2 – Your Role & How You Make Money</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                As a Nutrition Influencer, you have the ability to create customized nutritional plans and create other
                content based off of your own health, nutrition, and wellness knowledge, education, and experience. Our
                Platform will give you the ability to create an impact in the lives of countless others by spreading
                your knowledge and changing the eating and wellness habits of people. If you have a diet or nutrition
                plan that has worked for you, it is likely that it will work for others – so share the
                knowledge!<br/><br/>
                By becoming a Nutrition Influencer, you will have the opportunity to make money from the meal purchases
                of our Members, which are chosen, in part, based on the customized nutritional plans you create. The
                more people that use your customized nutritional plan and purchases meals from our third-party vendors,
                the more money you can make on our platform!<br/><br/>
                By signing up to be a Nutrition Influencer, you are not required to post any minimum number of content,
                the type, quality, and specifics are in your sole discretion, subject to the Terms of this
                Agreement.<br/><br/>
                We also offer Nutrition Influencers the ability to conduct 1:1 live, online, coaching
                sessions.<br/><br/>
                Further specifics about our two revenue methods for Nutrition Influencers are found on our Website.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 3 - Account</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                In order to become a Nutrition Influencer, you must be at least eighteen (18) years of age and have a
                working smartphone or computer. All of your uploads, analytics, and finances will be tracked, managed,
                and handled on our Platform. You will be required to connect a bank account to our Platform in order to
                receive Compensation. Failure to do so or failure to keep an active bank account will delay payment
                being made to you. Any such delays will be your responsibility and not the responsibility of
                Nutritt.<br/><br/>
                You are solely responsible for maintaining confidentiality of your password and account information and
                are responsible for all activity that takes place on your account.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 4 – Compensation.</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                As a Nutrition Influencer, you will have the ability to make money on our Platform. In order to receive
                payment for your activities (“Compensation”) you are required to have and keep an active bank account on
                our Platform.<br/><br/>
                Payment of your Compensation will be made to you shortly after the conclusion of each calendar month.
                All of your transactions from that month will be paid out in one lump-sum payment, less any withholdings
                by Nutritt. Payments are not made to you at the time the transaction is processed. Your revenue, as well
                as analytics, usage, and other trends will be calculated and run through out Platform. We cannot
                guarantee Compensation, or any minimum amount, which will be based on the content you post, Members who
                use your profile, and purchases by Members.<br/><br/>
                If you believe there is an error, omission, or other inaccuracy in connection with your Compensation,
                please contact us directly at info@nutritt.com with the reason by you believe there has been an error.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 5 – Content you Post & Nutritt License</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                By becoming a Nutrition Influencer, you are being given the opportunity to make money through your
                knowledge of nutrition and wellness. As a Nutrition Influencer, you are able to upload nutrition,
                wellness, and health related content, such as meal plans, pictures, videos, and writings onto our
                Platform. We do not review your content before it is posted, so please make sure what you post complies
                with the sections of these Terms and applicable law.<br/><br/>
                You are not permitted to post any content that is in breach of these Terms, including content that is
                not related to nutrition, health, and wellness.<br/><br/>
                You do not transfer ownership of any of your content simply by posting or uploading it on our Platform.
                All content you post will be and remain owned by you. By uploading or posting content on our Platform,
                each Nutrition Influencer hereby grants to Nutritt, a limited, worldwide, nonexclusive, non-assignable,
                royalty free right and license to reproduce, store, copy, transmit, publish, post, broadcast, display,
                distribute, adapt, modify, and use your content in connection with our Services. Unless you remove
                content from our Platform, this license will extend past termination. This license also grants Nutritt
                the ability to use your posted content for promotional and marketing purposes.<br/><br/>
                You acknowledge and agree that any content you post on our Platform may be seen and used by others and
                is available to the public. You understand that by posting content on our platform you have no
                expectation of privacy your content. Your content should be appropriate for all ages.<br/><br/>
                While we do specifically bar all users of our Platform from copying anything found therein, including
                your content, we cannot and do not represent that your content will not be copied. If you believe your
                content has been copied by a third-party, you should handle the dispute with the third-party and not
                Nutritt.<br/><br/>
                By posting content on our Platform, you hereby represent and warrant that: (a) the content you post is
                owned or licensed by you; (b) if the content is not owned or licensed by you, that you have the
                necessary consents or permissions to use said content; (c) your content will not infringe on the
                intellectual property rights of any third-parties; and (d) your content will not violate any other
                applicable laws.<br/><br/>
                Nutritt reserves the right to remove or delete any content of any kind posted by you on our Platform for
                any reason, in its sole discretion.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 6 – Use of our Messaging Platform</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                We enable our Platform users to communicate directly with other users, including Nutrition Influencers.
                This can be a great way for users to ask questions and learn more about what they access on our
                Platform.<br/><br/>
                Our messages system may not be used for any of the following:<br/>
                <ol type="a">
                    <li>Sending unsolicited advertising or promotions</li>
                    <li>Spam;</li>
                    <li>Harassing or abusing another member;</li>
                    <li>Exchanging personal contact, financial or other information for the purposes of evading payment
                        through the Platform.
                    </li>
                </ol>
                Nutritt reserves the right to suspend or cancel your Platform privileges for any of these reasons, or
                any other reasons in the sole discretion of Nutritt.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 7 – Nutritt Intellectual Property.</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                The contents of our Website and Platform are protected by United States and international copyright
                laws. The contents of our Website and Platform are owned exclusively by Nutritt or licensed to us. You
                may not, and may not cause or encourage others to, reproduce, distribute, display, sell, transfer,
                assign, license or use for commercial purposes any copyrighted material on our Platform or Website (the
                “Intellectual Property”) without our prior written consent. All rights not expressly granted in these
                Terms are reserved to Nutritt.<br/><br/>
                Nutritt and its name, logos, slogans, or otherwise are trademarks or service marks ("Marks") of Nutritt.
                All rights in these Marks are reserved by Nutritt. You may not use any Nutritt-provided Marks or other
                logos or graphics, without our prior written consent.<br/><br/>
                We grant you a limited, revocable, non-transferable and non-exclusive license to access and make
                personal use of our Website and Platform. This limited license does not include the right to: (a)
                republish, redistribute, transmit, sell, license or download the Website or any and/or all content
                except as is necessary to view and/or use our Website; (b) make any use of the Website or any and/or all
                content other than uses consistent with the Services; (c) modify, reverse engineer or create any
                derivative works based upon either the Website or any and/or content; (d) collect account information
                for the benefit of yourself or another party; or (e) use software robots, spiders, crawlers, or similar
                data gathering and extraction tools, or take any other action that may impose an unreasonable burden or
                load on our infrastructure.<br/><br/>
                Any unauthorized use by you of the Website or Platform automatically terminates this license without
                prejudice to any other remedy provided by applicable law or these Terms.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 8 - DMCA Policy</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Nutritt respects the intellectual property rights of others and expects you to do the same.<br/><br/>
                Below is our Digital Millennium Copyright Act (“DMCA”) policy. The notification process outlined here is
                consistent with the statutory requirements and protects Nutritt from copyright infringement claims as a
                ‘service provider’. We reserve the right to block access to and/or attempt to remove material that we
                believe in good faith to be copyrighted material or that is flagged by a third-party. We also reserve
                the right to discontinue or block repeat offenders from using our Platform.<br/><br/>
                DMCA Takedown<br/><br/>
                If you are accused of copyright infringement by a third-party, we require all of the following in the
                DMCA Takedown:<br/><br/>
                <ol type="a">
                    <li>a physical or electronic signature of the owner of the copyright (or a person authorized to act
                        on behalf of the owner);
                    </li>
                    <li>sufficient detail about the copyrighted work claimed to have been infringed;</li>
                    <li>identification of the URL or other specific location on our websites that contains the material
                        that the accuser claims infringes their copyright;
                    </li>
                    <li>the name, mailing address, telephone number and email address of the accuser;</li>
                    <li>a statement that the accuser has a good faith belief that such disputed use of the copyright
                        materials is not authorized; and
                    </li>
                    <li>a statement that the information in the notice is accurate, and the accuser attests under
                        penalty of perjury, that they are the copyright owner or that you are authorized to act on
                        behalf of the copyright owner.
                    </li>
                </ol>
                <br/>
                Upon receipt of a DMCA Takedown, we typically remove the allegedly infringing material within
                twenty-four (24) hours, unless we have reason to believe it was sent in error, is not truthful, or for
                any other legitimate reason.<br/><br/>
                DMCA Counter-Notice
                If you believe that your content (whether picture, video, writing, or other content) is not infringing
                on the rights of a third-party copyright holder, or if you have authorization to use the allegedly
                infringing content from the copyright holder, the holder's agent, or pursuant to law, please send us a
                DMCA Counter-Notice containing all of the following:
                <ol type="a">
                    <li>Your physical or electronic signature;</li>
                    <li>Identification of the content that was removed or to which access has been disabled as a result
                        of mistake or a misidentification of the content; and
                    </li>
                    <li>Your name, address, telephone number, and email address, a statement that you consent to the
                        jurisdiction of the federal court in New York, and a statement that you will accept service of
                        process from the person who provided notification of the alleged infringement.
                    </li>
                    <li>If a Counter-Notice is received by Nutritt, Nutritt may send a copy of the Counter-Notice to the
                        original complaining party informing that person that it may replace the removed content or
                        cease disabling it in ten (10) to fourteen (14) days.
                    </li>
                    <li>Unless the copyright owner files an action seeking a court order against Nutritt or the
                        person/entity who has posted allegedly infringing content, the removed content will be
                        reinstated ten (10) to fourteen (14) days later.
                    </li>
                </ol>
                Repeat Offender Policy<br/>
                Nutritt does not tolerate copyright infringement or any violation of the intellectual property rights of
                Nutritt or of others. Nutritt reserves the right to terminate the access and/or use privileges of any
                person or entity who has been determined to be a “repeat infringer”.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 9 – Further Responsibilities</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                You further understand, represent, and warrant that:<br/>
                <ol type="a">
                    <li>at no time hereunder, unless you are actually a licensed medical or other professional, will you
                        ever represent that you are a licensed medical or other professional;
                    </li>
                    <li>all content, statements, or other forms of communication done by you on our Platform are yours
                        solely and are not binding on or a statement of Nutritt;
                    </li>
                    <li>Nutritt does not and cannot review every piece of content you post; thus it is your sole
                        responsibility to ensure that your content abides by all applicable laws and regulations.
                    </li>
                </ol>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 10 – Relationship to Nutritt</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Each Nutrition Influencer is an independent contractor with respect to Nutritt, and not an employee of
                Nutritt. Nothing contained in this Agreement shall be regarded as creating an employment, joint venture,
                or partnership relationship between Nutritt and a Nutrition Influencer.<br/><br/>
                Nutritt will not offer nor is Nutrition Influencer eligible to participate in any vacation, medical, or
                life insurance, disability, or retirement benefits, or any other fringe benefit plans offered by Nutritt
                to its employees, if any.<br/><br/>
                Nutrition Influencer shall be solely responsible for all applicable taxes resulting from the
                Compensation. Nutrition Influencer shall supply all materials needed to adequately perform the Services
                for Nutritt, which include but are not limited to a smartphone and a computer.<br/><br/>
                Nutrition Influencer shall have no authority to bind Nutritt by any promise or representation unless
                specifically authorized in writing by Nutritt. Nutrition Influencer shall not represent to any third
                party, or hold himself out to any third party, as having such authority unless previously so authorized
                by Nutritt.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 11 – Community Guidelines/Prohibited Content</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                You are prohibited from using our Platform:
                <ol type="a">
                    <li>for any unlawful purpose;</li>
                    <li>to solicit others to perform or participate in any unlawful acts;</li>
                    <li>to violate any international, federal, provincial or state regulations, rules, laws, or local
                        ordinances;
                    </li>
                    <li>to infringe upon or violate our intellectual property rights or the intellectual property rights
                        of others;
                    </li>
                    <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on
                        gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
                    </li>
                    <li>to submit false or misleading information;</li>
                    <li>to upload or transmit viruses or any other type of malicious code that will or may be used in
                        any way that will affect the functionality or operation of the Platform or Services;
                    </li>
                    <li>to collect or track the personal information of others;</li>
                    <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                    <li>for any obscene or immoral purpose; or</li>
                    <li>to interfere with or circumvent the security features of the Platform or Services or any related
                        website, other websites, or the Internet.
                    </li>
                </ol>
                We reserve the right to terminate your use of our Platform for violating any of the prohibited
                uses.<br/><br/>
                In addition to the prohibitions above, you are not permitted to take part in any of the foregoing on the
                Platform or while using the Services:
                <ol type="a">
                    <li>nudity or sexual content;</li>
                    <li>harmful or dangerous content;</li>
                    <li>hateful content;</li>
                    <li>violent or graphic content;</li>
                    <li>harassment or bullying;</li>
                    <li>spam or scamming;</li>
                    <li>threats;</li>
                    <li>any other act or actions that are immoral, unethical, or abhorrent.</li>
                </ol>
                We reserve the right to terminate your use of the Services for violating any of these community
                guidelines.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 12 - No Self-Proportion</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                Nutrition Influencer agrees that he/she will not self-promote competing products or services to Nutritt
                while using the Platform.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 13 – Term & Errors</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                These Terms will remain active and in full force and effect so long as they are posted on our
                Website.<br/><br/>
                Occasionally there may be information on our Website or Platform that contains typographical errors,
                inaccuracies or omissions that may relate to descriptions, pricing, promotions, offers, and
                availability. We reserve the right to correct any errors, inaccuracies or omissions, at any time.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 14 - Disclaimer</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                OUR WEBSITE, PLATFORM, AND SERVICES ARE PROVIDED 'AS IS' AND, TO THE EXTENT PERMITTED BY APPLICABLE LAW,
                NUTRITT AND ITS OWNERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, THIRD PARTY
                PROVIDERS AND AFFILIATES, EXCLUDE ALL REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED
                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, AND FITNESS
                FOR A PARTICULAR PURPOSE.<br/><br/>
                WE EXPRESSLY DISCLAIM ANY LIABILITY FOR LOSS OR DAMAGE SUSTAINED BY YOU AS A RESULT OF USING OUR
                SERVICES.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 15 – Limitation of Liability</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                WE SHALL NOT BE LIABLE TO YOU FOR INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
                PROFITS, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR SHALL WE BE HELD LIABLE FOR
                DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL. IN NO EVENT SHALL
                OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED THE TOTAL AMOUNT NUTRITT
                RECEIVED IN CONNECTION WITH YOUR SERVICES.<br/><br/>
                YOU AGREE THAT NO CLAIMS OR ACTION IN CONTRACT, WARRANTY, OR IN TORT (INCLUDING NEGLIGENCE) ARISING OUT
                OF, OR RELATED TO, THE USE OF OUR WEBSITE, OUR SERVICES, OR THESE TERMS MAY BE BROUGHT MORE THAN ONE (1)
                YEAR AFTER THE CAUSE OF ACTION RELATING TO SUCH CLAIM OR ACTION AROSE.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 16 – Indemnification</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                You agree to defend, indemnify and hold Nutritt, its agents, employees, directors, officers, owners,
                etc. harmless for any loss, damages or costs, including reasonable attorneys' fees, resulting from any
                claim, action, or demand arising from (i) your use of the Website or Platform; (ii) your breach of these
                Terms; (iii) any statement or representation by you; (iv) your content; and (v) injuries of any kind to
                Members arising out of your content or actions on the Platform.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 17 – General Provisions</b>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <u>Entire Agreement.</u> These Terms contains the entire agreement between you and Nutritt except for
                any Services-specific information found on our Website (for example, your revenue cut from a meal).<br/><br/>
                <u>Waiver.</u> The failure by Nutritt to enforce any provision of these Terms shall not be construed as
                a waiver or limitation of our right to subsequently enforce and compel strict compliance with every
                provision of this Agreement.<br/><br/>
                <u>Assignment.</u> You may not assign, pledge, delegate,or otherwise transfer any of your rights or
                obligations under this Agreement without Nutritt prior written consent.<br/><br/>
                <u>Applicable Law/Dispute Resolution.</u> This Agreement shall be governed by the laws of the State of
                New York. Any dispute arising from this Agreement shall be subject to binding arbitration in New York
                City. The prevailing Party in any dispute shall be entitled to recover its/his/her reasonable attorney’s
                fees and costs. The governing rules shall be the rules,then-implemented, by the American Arbitration
                Association commercial division. Nutritt and you agree that any such final decisions may be presented to
                a court of competent jurisdiction for purposes of being confirmed as a judgment enforceable under the
                law in which that party is domiciled or where their headquarters are located. Should either party forego
                arbitration, that party shall be barred from recovering their attorneys’ fees or costs.<br/><br/>
                <u>Severability.</u> If any provision of this Agreement shall be held to be invalid or unenforceable for
                any reason, the remaining provisions shall continue to be valid and enforceable. If an arbitrator or
                panel of arbitrators finds that any provision of thisAgreement is invalid or unenforceable, but that by
                limiting such provision it would become valid and enforceable, then such provision shall be deemed to be
                written, construed, and enforced as so limited.<br/><br/>
                <u>Updates.</u> We recommend that you check the Terms periodically for updates.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography>
                <b>Section 18 – Questions</b>
            </Typography>
            <br/>
            <Typography>
                If you have any questions or comments regarding these Terms, please feel free to contact us by email at
                Info@nutritt.com.
            </Typography>
        </div>
        <br/>
    </div>
}

export default NutritionistTermsAndConditions;