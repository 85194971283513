import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const DropdownArrowWrapper = styled.div`
color: #52c41a;
    .Box{
        position: relative;
        left: 0;
        top: 0;
    }
    .DropArrow{
        position: absolute;
        left: 110px;
        top: 19px;
    }
    .select-label {
        color: #747c7a;
      }

@media(max-width:991px){
  margin-right: 8px;
  display: flex;
}
`;

export default function DropdownArrow() {
  const {isMobile} = useAppContext();

  return (
   <DropdownArrowWrapper>
      {isMobile ? (
        <img src='/icons/arrow-square-down.svg' alt='Arrow-square-down' />
      ) : (
        <>
          <img className='Box' src="/icons/box.svg" alt="Box Icon" />
          <img className='DropArrow' src="/icons/boxArrow.svg" alt="Arrow Icon" />
        </> 
      )}
       
   </DropdownArrowWrapper>
  );
}
