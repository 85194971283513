import { useEffect, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import RegularInput from 'components/Input/RegularInput';
import { Button } from 'components/elements/button/Button';
import  MessageModal  from 'components/elements/modal/MessageModal';
import { isEmailValid } from 'components/auth/utils';
import { useMemo } from 'react';
import  FooterLink  from 'layout/FooterLinks' 
import { ReactComponent as Lock } from 'assets/shop/Lock.svg';
import { ReactComponent as Success } from 'assets/successMessage.svg';
import MobileHeader from 'components/elements/MobileHeader';
import PageTransition from './components/PageTransition';
import { AnimatePresence } from 'framer-motion';

const StyledForgotForm = styled.form`
    background:#fff;
    padding: 12px 16px 0px 16px;
    height: calc(100vh - 80px);
    .form-wrapper{
        width: 39%;
        margin: auto;
      }    
    .Signup-Wrapper{
        display:flex;
        place-content:center;
        gap: 4px;
        margin-top:12px;
        color: #00092D;
        text-align: center;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        .signUp{
            color: #00092D !Important;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
    }
    @media (max-width: 991px) {
        .form-wrapper{
          width: 100% !important;
        }
      }
      @media (min-width: 991px) {
        width: 100vw;
        height: 100vh;
        z-index: 99;
        position: fixed;
        inset: 0;
        background: #F0F2F4;
        display: flex;
        justify-content: center;
        align-items: center;
        .form-wrapper{
          background: #fff;
          padding: 58px 48px 48px 48px;
          width: 536px;
          height: ${({token}) => token ? "476px" : "379px"};
          .password-recommendation{
            height: 59px;
            margin-top: 14px;
            color: var(--grey-2, #949D9B);
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            &.error{
              color: #EF6F64;
           }
          ul{
            margin: 0 !Important;
            padding-left: 26px;
          }
        
           }
        
        }
        .button-div{
          display: flex;
          justify-content: flex-end;
          margin-top: 50px;
          gap: 10px;
          button{
            padding: 14px 24px 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            :disabled{
              color: #757E89 !important;
            }
          }
        }
        .reset-password{
          width: 150px;
          height: 42px;
          float: right;
        }
        .heading{
          display: flex;
          flex-direction: column;
          gap: 12px;
         .title{
           color: #000A2C;
           font-family: Gilroy;
           font-size: 20px;
           font-style: normal;
           font-weight: 700;
           line-height: 24px;
         }
         .desc{
           color: #757E89;
           font-family: Gilroy;
           font-size: 14px;
           font-style: normal;
           font-weight: 500;
           line-height: 24px;
           margin-bottom: 14px;
         }
        }
     
      }
`;

const FormGroupStyle = styled.div`
  margin-bottom: 8px;
  width: 100%;
    label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom:9px;
    display: inline-block;
    }
    .ErrorMessage{
    P{
      font-size: 14px;
    }
  } 
   input {
    height: 44px;
    border-radius: 6px;
    border: 1px solid #E0E3E0;
    z-index:99;
    margin-bottom: 8px;
      :hover, :focus {
         border-color: #00092D !important;
      }
      :focus::placeholder {
        color: transparent;
      }
      ::placeholder{
        color: #949D9B;
        font-style: normal;
        font-weight: 500;
      }
    }
  .value {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
      }  
      .black{
        color: #000 !important;
        cursor: pointer;
        font-weight: 600;
      }
`;

 const ActiveModalWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: 500;
  background-color: #0B1D1760;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .button{
    width:${({emailErrorModel}) => emailErrorModel ? "220px" : "122px"};
  }
`;

const SuccessWrapper = styled.div`
position: fixed;
inset: 0;
background: white;
top: 0;
bottom: 0;
z-index: 999;
.container{
  display: flex;
  height: calc(100vh - 170px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 32px;
  .text{
    display:flex;
    flex-direction: column;
    gap: 8px;
    color: #00092D;
    text-align: center;
    font-family: Gilroy;
    font-style: normal;
    .title{
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }
    .desc{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;      
    }
  }
}
`

export default function ForgotPasswordForm({
    error,
    setError,
    windowWidth
}) {
    const history = useHistory();
    const params = new URLSearchParams(useLocation().search);
    const token = params.get('token');
    const userEmail = params.get('email');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { afterLogin, isMobile, TimeZone, setLogin, loadPantryMeals, getdiaryData, setCheckAutoLogin } = useAppContext();
    const validEmail = useMemo(() => isEmailValid(email), [email]);
    const [showSuccessModel, setShowSuccessModel] = useState(false);
    const [emailErrorModel, setEmailErrorModel] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfimrVisible, setConfirmPasswordVisible] = useState(false);
    const [matchAccountNumber, setMatchAccountNumber] = useState(""); 
    const [passwordNotMet, setPasswordNotMet] = useState(false)
    const [errorMessage, setErrorMessage] = useState(""); 
    const location = useLocation();
    const fromRoute = location.state?.from
    const [login, { isLoading }] = useRequest('auth/login', 'post');
    const { isWebView } = useAppContext()
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    const baseURL = window.location.protocol + "//" + window.location.host;
    const [resetErrorMessage, setResetErrorMessage] = useState('');

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };  

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!passwordConfimrVisible);
      };  

    const [sendForgotPasswordLink, { isLoading: isInviteLoading, state, error: inviteError }] = useRequest(`auth/forgot-password`,'post',true);

    const [resetPassword, { isLoading: isResetPasswordLoading, state: g, error: f }] = useRequest(
        `auth/reset-password`,
        'post',
        true
    );

    function isPasswordValid(password) {
      const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
      return passwordRegex.test(password);
    }
  
    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage(`Your password doesn’t match`);
            return;
        }
        if (!isPasswordValid(password) && !isMobile) {
          setMatchAccountNumber(' ');
          setPasswordNotMet(true);
          return;
        }

        if (!passwordRegex.test(password)) {
          setMatchAccountNumber(' ');
          setErrorMessage("Password must be 8 characters long with at least one letter and one number.");
          return;
        }
        
        resetPassword({ body: { token, password: password } })
            .then((data) => {
                isMobile ? setShowSuccessModel(true) : history.push('/account/home')
                }).catch((e) => {
                  console.log(e,'error')
                  if(e?.data?.message === "Invalid token"){
                    setResetErrorMessage('Uh-oh! It seems the link to reset your password has expired.')
                  }else{
                    setResetErrorMessage(e?.data?.message);
                  }
              });
    }
    useEffect(() => {
      if(token && baseURL === process.env.REACT_APP_MERCHANT_URL){
      const isPlatformWeb = () => {
          return !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
      };
      const isPlatformIOS = () => {
          return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      };
      const isPlatformAndroid = () => {
          return /Android/i.test(navigator.userAgent);
      };
      const scheme = 'nutrittmerchant://';
            if (!isPlatformWeb()) {
          if ((isPlatformIOS() || isPlatformAndroid()) && !isWebView) {
            const urlWithParams = `${scheme}?email=${userEmail}?nutritt/token=${token}`;
                  window.location.href = urlWithParams;
          }
    }
  }
  }, [token])
    

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setEmailErrorModel(false);
      const origin = window.location.origin;
      const isProvider = origin === process.env.REACT_APP_MERCHANT_URL ? true : false;

        if (email) {
            sendForgotPasswordLink({
                body: {
                    email: email,
                    isProvider
                },
            }).then((data) => {
                setShowSuccessModel(true);
            }).catch((e) => {
                console.log(e.data.message)
                if(e.data.message === "We could not found an account for that email address"){
                  setErrorMessage("Email not found");
                } else {
                  setErrorMessage(e.data.message); 
                }
                setEmailErrorModel(true);
            });
        } else {
            setEmailErrorModel(true);
        }
    };

    const handleLogin = async (e) => {
      e.preventDefault()
      await login({
        body: { email: userEmail, password: password, TimeZone },
      }).then(async (loginRes) => {
        if (loginRes?.user?.type.toString() === 'user') {
          localStorage.setItem('user', JSON.stringify(loginRes.token));
          isMobile && await getdiaryData()
          await loadPantryMeals().then((response) => {
            afterLogin(loginRes);
            setLogin(true);
          })
        } else {
          afterLogin(loginRes);
          setLogin(true);
          setCheckAutoLogin(true);
        }  
      }).catch((err) => {
        console.log(err);
      })
      window.scrollTo(0, 0);
  }

  console.log("Base URL: " + baseURL);
      return (
        token ?
          <PageTransition>
            <MobileHeader text={baseURL === process.env.REACT_APP_MERCHANT_URL ? "Reset password" : "Forgot password"} onClickLeft={() => { history.goBack() }} />            
            <StyledForgotForm noValidate token={token}>
                {showSuccessModel &&
                (
                  <ActiveModalWrapper>
                <MessageModal img={<Lock />} title={"Success!"} description='Password has been updated' firstBtnTitle={"Ok"} firstBtnClick={(e) => baseURL === process.env.REACT_APP_MERCHANT_URL ? handleLogin(e)  : history.push('/login')} success={true}/>              
                  </ActiveModalWrapper>
                )
                }
                <div className='form-wrapper'>
                <FormGroupStyle errorMessage={errorMessage} matchAccountNumber={matchAccountNumber}>
                {!isMobile &&
                <div className='heading' style={{marginBottom: "14px"}}>
                  <h1 className='title'>Please enter your new password</h1>
                  </div>
                }
                  {isMobile &&
                    <RegularInput
                        label="Email"
                        value={userEmail}
                        bordered
                        required
                        disabled={true}
                    />
                  }
                    <RegularInput
                        label="New Password"
                        type="password"
                        name="password"
                        id="new-password"
                        placeholder={"Input new password"}
                        value={password}
                        onChange={(e) => {setPassword(e.target.value), setErrorMessage(''), setMatchAccountNumber(''), setPasswordNotMet(false), setResetErrorMessage(''); }}
                        bordered
                        required
                        icon={passwordVisible ? '/icons/eye.svg' : '/icons/eye-slash.svg'}
                        onSuffixClick={togglePasswordVisibility}
                        passwordVisible={passwordVisible} 
                        errorMessage={ errorMessage.length || resetErrorMessage?.length ? ' ' : ''}         
                    />
                    <RegularInput
                        label={isMobile ? "Re-type new password" : "Confirm new password"}
                        type="password"
                        name="confirmPassword"
                        id="new-password"
                        placeholder={"Input new password"}
                        value={confirmPassword}
                        onChange={(e) => {setConfirmPassword(e.target.value), setErrorMessage(''), setResetErrorMessage('')}}
                        bordered
                        required
                        icon={passwordConfimrVisible ? '/icons/eye.svg' : '/icons/eye-slash.svg'}
                        onSuffixClick={toggleConfirmPasswordVisibility}
                        passwordVisible={passwordConfimrVisible}  
                        errorMessage={errorMessage || resetErrorMessage}
                        />
                    </FormGroupStyle>
                    {!isMobile && 
                    <div className={`password-recommendation ${passwordNotMet ? "error" : ''}`}>
                  <p>We recommend choosing a password that:</p>
                  <ul>
                    <li>Is 8 characters long or more</li>
                    <li>Uses numbers (0-9) and special symbols (!#@$...)</li>
                  </ul>
                  </div>
                    }
                    <Button title={isMobile ? 'Change password' : 'Reset password'} disabled={isInviteLoading || isResetPasswordLoading || !password || !confirmPassword || matchAccountNumber } onClick={handlePasswordSubmit} type={"mobile_save"} error={error} style={{marginTop:"32px", fontFamily: "Gilroy"}} className={!isMobile ? "reset-password" : ""}/>
                </div>
                {isMobile && <FooterLink /> }
            </StyledForgotForm> 
            </PageTransition>
:
            <>
            {showSuccessModel && !isMobile ? 
              <>
              <MobileHeader text={"Reset password"} onClickLeft={() => { history.goBack() }} />
              <StyledForgotForm noValidate>
                  <div className='form-wrapper'>
                  <div className='heading'>
                  <h1 className='title'>Password reset</h1>
                  <text className='desc'>Check {email} for an email to reset your password. You’ll only receive an email if there is an associated Nutritt account.</text>
                  <FormGroupStyle>
                  <text className='value black' onClick={handleEmailSubmit}>Didn’t receive an email?</text>
                  </FormGroupStyle>
                  </div>
                        <div className='button-div'>
                        <Button type={'mobile_secondary'} title="Resend reset email" onClick={() => history.push('/forgot-password')} />
                        <Button type={'mobile_save'} title="Back to sign in" onClick={() => history.push('/account/home')} />
                        </div>
                        </div>
                </StyledForgotForm>
                </>
              :
              <PageTransition>
              <MobileHeader text={baseURL === process.env.REACT_APP_MERCHANT_URL ? "Reset password" : "Forgot password"} onClickLeft={() => { history.goBack() }} />                <StyledForgotForm noValidate>
                {emailErrorModel && isMobile && (
                    <ActiveModalWrapper emailErrorModel={emailErrorModel}>
                      <MessageModal
                        firstBtnClick={() => setEmailErrorModel(false)}
                        secondBtnClick={() => history.push('/nutritt/support')}
                        success={false}
                      />
                    </ActiveModalWrapper>
                  )}
                  <AnimatePresence>
                  {showSuccessModel && (
                    baseURL === process.env.REACT_APP_MERCHANT_URL ? (
                      <PageTransition>
                      <SuccessWrapper>
                        <MobileHeader text={"Check your mail"} onClickClose={() => setShowSuccessModel(false)} />
                        <div className='container'>
                        <Success />
                        <div className='text'>
                          <h3 className='title'>Check your email</h3>
                          <p className='desc'>We have e-mailed you instructions to <br /> reset your password</p>
                        </div>
                        </div>
                      </SuccessWrapper>
                      </PageTransition>
                    ) : (
                      <ActiveModalWrapper>
                        <MessageModal
                          img={<Lock />}
                          title="Success"
                          description="An email has been sent.<br /> Please check your inbox"
                          firstBtnTitle="Ok"
                          success={true}
                        />
                      </ActiveModalWrapper>
                    )
                  )} 
                  </AnimatePresence>                                 
                  <div className='form-wrapper'>
                  {!isMobile && 
                  <div className='heading'>
                  <h1 className='title'>Forgot password?</h1>
                  <text className='desc'>Please specify your email address to receive <br /> instructions for resetting it.</text>
                  </div>
                  }
                    <FormGroupStyle>
                        <RegularInput
                            name="email"
                            label="Email"
                            value={email}
                            onChange={(e) => {setEmail(e.target.value), setErrorMessage('')}}
                            bordered
                            required
                            autoFocus
                            placeholder={isMobile ? 'Email address': 'Input your email here'}
                            className="input-div"
                            errorMessage={!emailErrorModel ? errorMessage: ""}
                        />
                        </FormGroupStyle>
                        {!isMobile ? 
                        <div className='button-div'>
                        <Button type={'mobile_secondary'} title="Back to sign in" onClick={() => history.push('/account/home')} />
                        <Button type={'mobile_save'} title="Reset password" onClick={handleEmailSubmit} isDisabled={isInviteLoading ||isResetPasswordLoading || !validEmail}/>
                        </div>
                        :
                        <Button type={'mobile_save'} title="Continue" onClick={handleEmailSubmit} isDisabled={isInviteLoading||isResetPasswordLoading || !validEmail} style={{marginTop:"16px"}}/>
                        }
                        {isMobile && baseURL !== process.env.REACT_APP_MERCHANT_URL &&
                          <text className='Signup-Wrapper'>Don’t have an account?<Link to={isMobile ? '/self-onboarding/signin' : '/account/home'} className='signUp'>Sign up</Link></text>
                        }
                        </div>
                        {isMobile && <FooterLink /> }
                </StyledForgotForm>
              </PageTransition>
            }
            </>
    );
}

