import styled from "styled-components"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"

const ButtonStyle = styled.div`
    .button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 48px;
        .back{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #97DC76;
            border-radius: 50%;
            .icon{
                color: #949D9B;
                font-size: 22px;
            }
        }
        .continue{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            background-color: #52C41A;
            border-radius: 31px;
            display: inline-flex;
            align-items: center;
            margin-left: auto;
            gap: 8px;
            padding: 10px 19.5px;
            color: #fff;
            .icon{
                color: #fff;
                font-size: 20px;
            }
            &:disabled{
                background-color: #E0E3E0;
                color: #949D9B;
                .icon{
                    color: #949D9B;
                }
            }
        }
    }
`

export default function Button({ onBackClick, onContinueClick, disabled, showBackButton = true, showContinueButton = true }) {

    return (
        <ButtonStyle>
            <div className='button'>
                {showBackButton &&
                    <div
                        className='back'
                        onClick={() => {
                            onBackClick();
                            window.scrollTo(0, 0);
                        }}
                    >
                        <BsArrowLeft className="icon" /></div>}
                {showContinueButton &&
                    <button className="continue"
                        onClick={() => {
                            onContinueClick();
                            window.scrollTo(0, 0);
                        }}
                        disabled={disabled}
                    >
                        Next
                        <BsArrowRight className="icon" />
                    </button>}
            </div>
        </ButtonStyle>
    )
}