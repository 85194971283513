import styled, { keyframes } from 'styled-components';
import { useState, useRef, useEffect, createContext } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import NutritionistClientCalories from './NutritionistClientCalories'
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from 'context/nutritionistContext';
import ClientLoggedMeals from './ClientLoggedMeals';
import Slider from 'react-slick';
import { useRequest } from 'hooks/useRequest';
import SliderWeekDays from './SliderWeekDays';
import ImageViewer from 'components/ImageViewer';
import { toast } from "react-toastify";
import { fadeIn, slideInDown } from 'react-animations'
import SendMealsModal from './ClientsCart/SendMealsModal';
import Progress from 'components/Progress';
import { useChatContext } from 'context/chatContext';
import { ChatWithFollower } from './ChatWithFollower';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
 
export const ClientDiaryContext = createContext();

const slideInDownAnimation = keyframes`${slideInDown}`

const NutritionistClientProfileStyle = styled.div`
height: calc(100vh - 190px);
&.hidden{
  display:none;
}
&.slide{
  &::before{
    content: '';
    inset: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: rgba(0,0,0,0.8);
    opacity: 0;
    z-index:-1;
  }
}
&.slideToTop{
  transform: translateY(-30%);
  transition: all 0.8s;
  &::before{
    z-index: 112;
    animation: o 0.8s linear;
  }
}
&.slideToBottom{
  transform: translateY(0%);
  transition: all 0.5s;
  &::before{
    z-index: 112;
    animation: oo 0.5s linear;
  }
}
@keyframes o{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes oo{
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
@media screen and (max-width: 767px){
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 111;
  overflow-y:auto;
  &::-webkit-scrollbar{
    display:none;
  }
}
&.slideUp {
  left: 0;
  visibility: visible;
}
.clientprofileFullD{
  height: calc(100vh - 470px);
  overflow : hidden !important;
  @media screen and (max-width: 767px){
    height: calc(100vh - 350px);
  }
  .logged-div{
    height:100%;
  }
}
&.desktopDesign {
  border: 1px solid #E0E3E0;
  padding-top: 0px;
  border-radius: 6px;
  height: 100%;
}
 .client-div::-webkit-scrollbar{
    display:none;
 }
 .arrow-left{
    cursor:pointer;
    position:absolute;
    top: 12px;
    left:0;
    margin-left:17px;
 }
 .profile{
    font-weight: 700;
    font-size: 18px;
    color: #51635D;
    display:flex;
    justify-content:center;
    position:relative;
    @media screen and (max-width: 767px){
      padding-bottom: 15px;
      padding-top: 20px;
      border-bottom: 1px solid #E0E3E0;
    }
 }
 .follower_cart{
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
 }

 .client-info{
    width:100%;
    padding: 20px 20px 20px 0px;
    border-bottom:1px solid #E0E3E0;
    display:flex;
    justify-content:center;
    align-items:center;
    position: relative;
   
    .inner-div{
      width: 100%;
      display: flex;
      @media (min-width:1440px) and (max-width: 1920px){
        padding-left: 53px;
      }
      @media (max-width: 768px){
        padding-left: 66px !important;
      }
      @media (max-width: 576px){
        padding-left: 30px !important;
      }
      @media (max-width: 375px){
        padding-left: 30px !important;
      }
   
        .user-img{
            width:100px;
            height:100px;
            border-radius:50%;
            @media screen and (max-width: 767px){
              width:64px;
              height:64px;
            }
        }
        .name-plan{
            width:67.24%;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media(max-width:991px){
              margin-left:10px !important;
            }
            .name{
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
                color: #51635D;
                padding-bottom: 0px;
                display: flex;
                align-items: center;
                word-break:break-all;
                @media screen and (max-width: 767px){
                  font-size: 18px;
                  line-height: 24px;
                 padding-bottom: 4px !important;         
                }
                .fadedClass{
                  margin-left: 10px;
                  // position: absolute;
                  top: 15px;
                  right: 15px;
                  // margin: 0px;
                  margin-left: 4px;
                  width: 15px;
                  height: 15px;
                  display: flex;
                  @media screen and (min-width: 768px){
                    position: relative;
                    top: 0px;
                    right: 0px;
                    margin-left: 8px;
                  }
                }
            }
            .plan{
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #949D9B;
                @media screen and (max-width: 767px){
                  font-size: 14px;
                  line-height: 16px;
                }
            }
            .create-meal-bttn{
              background: #52C41A;
              border-radius: 21px;
              font-weight: 700;
              font-size: 14px;
              line-height: 16px;
              display: flex;
              align-items: center;
              text-align: center;
              min-width:140px;
              height:32px;
              color: #fff;
              max-width: 140px;
              justify-content: center;
              margin-top: 16px;
              cursor: pointer;
            }
            .plan-name{
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #949D9B;
                @media(max-width:991px){
                  font-size: 14px;
                  line-height: 16px;
                }
            }
        }
        .star{
            margin-left:5px;
            .MuiRating-root{
                label{
                   
                }
            }
        }

        .fadedClass[wobble='1'] {
            animation: wobble  2s ease 1;
          }
          @keyframes wobble  {
            0% { transform: scale(1); }
            25% { transform: scale(.95); }
            50% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 0;
              transform: scale(.3);
              } 
          }
          
    }
 }
 .slick-list{
  height: 100%;
 }
 .slick-slide{
  position: relative;
 }
  .macros{
     display:flex;
     flex-direction:column;
     align-items:center;
     width: 100%;
     left: 0px;
     background-color: #fff;
     z-index: 9;
     .daily-progress{
      width:92%;
      border-bottom: none !important;
     } 
      
    }
    .img-div{
        display:flex;
        justify-content:center;
        cursor: pointer;
        &+.slick-slider{
          height: calc(100vh - 483px);
          @media screen and (max-width: 767px) {
            height: calc(100vh - 320px);
          }
        }
    }
    
     .macros-down{
        position: relative;
        top: -13px;
     }
     .macros-up{
        position: relative;
        top: 151px;
        z-index: 1;
        height: 20px;
     }

  .total-calories{
    width:100%;
    padding-bottom:12px;
    border-bottom: 1px solid #E0E3E0;
    margin-bottom: 19px;
  }
  .clientMeals-module{
    padding: 0px 20px;
    @media screen and (min-width:1441px) and (max-width: 1920px){
      padding: 0px 42px;
    }
    @media screen and (max-width: 767px){
      padding: 0px;
      background-color: #ffffff;
      min-height: calc(100vh - 320px);
      max-height: calc(100vh - 320px);
    }

    // &.slideInDown{
    //   animation: 1s ${slideInDownAnimation};
    // }
  }
  .logged{
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #51635D;
    padding:14px 24px;
    border-bottom: 1px solid #E0E3E0;
    margin: 0px 20px;
    @media screen and (min-width:1441px) and (max-width: 1920px){
      margin: 0px 30px;
    }
    @media screen and (max-width: 767px){
      border-bottom: none;
      margin: 0px;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .profile_holder{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .user-img{
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }

    .name{
      font-size: 18px;
      line-height: 26px;
      display: flex;
      align-items: center;
      gap: 6px;
      span{
        width: 13px;
        img{
          width: 100%;
        }
      }
    }
  }

  .clients_plan{
    text-align: center;
    span{
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: #51635D;
    }
    .plan{
      font-weight: 700;
      color: #51635D;
    }
  }

  .follower_days{
    padding-top: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E0E3E0;
  }

  .macros-toggler{
    text-align: center;
    margin-top: -13px;
    img{
      width: 78px;
    }
  }

  .__react_component_tooltip{
    width: 400px;
    word-break: break-all;
   }

   @media(max-width:991px){
    .__react_component_tooltip{
      width: 240px;
     }
   }
  
`
const fadeinAnimation = keyframes`${fadeIn}`;
const FadeDiv = styled.div`
animation: 1s ${fadeinAnimation};
`;

const FixedContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 111;
`

const ClientMessageNotificationStyle = styled.div`
  background: #FFFFFF;
  border-top: 1px solid #F6F5F8;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 14px 14px 40px;

  .input_container{
    background: #F6F5F8;
    border-radius: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #AFB5B4;
    padding: 5px 5px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;

    span{
      overflow: hidden;
      word-break: break-all;
      width: calc(100% - 40px);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .notification_icon{
      position: relative;
      img{
        display: block;
      }
      .unseenMsg{
        position: absolute;
        right: -4px;
        top: -4px;
        font-weight: 400;
        font-size: 7px;
        line-height: 14px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        text-align: center;
        background: #EB3223;
        border: 0.5px solid #EB3223;
        color: #fff;
        z-index: 2;
      }
    }
  }
`

export default function NutritionistClientProfile({ 
  setClientModal, clientModal,
  followerProfile, setFollowerProfile,
  isNotificationRedirect,setIsNotificationRedirect
 }) {

  const date = moment().format('YYYY-MM-DD');
  const [activeDay, setActiveDay] = useState(date);
  const [showMacros, setShowMacros] = useState(false);
  const [days, setDays] = useState([]);
  const sliderRef = useRef(null);
  const mainSliderRef = useRef(null);
  const [stopSwipe, setStopSwipe] = useState(false);
  const [memoActiveDay, setMemoActiveDay] = useState(false);
  const [image, setImage] = useState(false)
  const [diaryMeals, setDiaryMeals] = useState([]);
  const [weeklyDiaryMeals, setWeeklyDiaryMeals] = useState([]);
  const [data, setData] = useState([]);
  const [wobble, setWobble] = useState(0);
  const { isMobile, isSuper, user, unreadMsgCount, setChatData } = useAppContext();
  const [userDeliveryAddress, setUserDeliveryAddress] = useState();
  const [mealsModal, setMealsModal] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [clientAnim, setClientAnim] = useState(true);
  const [showMealsCart, setShowMealsCart] = useState(false);
  const {activateConversation, activeConversation} = useChatContext();

  const [makeFavorite, { isLoading, state, error: favoriteError }] = useRequest(
    `coachingOffer/updateFavorite`,
    'post');
  const [getdiaryMeals, { isLoading: isGetdiaryMealLoading }] = useRequest();
  const { followersList, setFollowersList, getActiveClientsFn } = useNutritionistContext()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const clientId = followerProfile?.userid;
  const [showChatScreen, setShowChatScreen] = useState(false);
  const [showChatAnim, setShowChatAnim] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [bgAnim, setBgAnim] = useState({open: false, close: false});
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const [isHandleFavoriteClickCalled, setIsHandleFavoriteClickCalled] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const [currentDate, setCurrentDate] = useState(activeDay);
  const location=useLocation();
  const [mealPlannerOpen,setMealPlannerClose] = useState(false)
  const [visibility, setVisibility] = useState(false)

 
  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    const userId = isSuper ? imitateNutritionist?.userId : user?.id;
    const roomName = `nutritt_${env}_${clientId}_${userId}`;
    const filteredNotification = unreadMsgCount.filter((item) => item.conversationUniqueName == roomName);
    setUnreadMessages(filteredNotification[0]?.conversationUnreadMsg);
  }, [unreadMsgCount])

  useEffect(() => {
    if(!showChatScreen && activeConversation){
      activeConversation.removeAllListeners();
      setChatData([]);
    }
  }, [showChatScreen])

  const getdiaryData = async (id) => {
    try {
      const route = `diary/getDiaryMeals?userId=${id} &timeZone=${timeZone}`;
      const data = await getdiaryMeals({
        path: route
      })
      setData(data?.diaryData);
      setUserDeliveryAddress(data?.userDeliveryAddress);
    } catch (error) {
      console.log(error)
    }

  };
  const updateFollowerData = (id) => {
    const followerToUpdate = followersList.map((user) => {
      if (user.userid === id) {
        return { ...user, isFavorite: !user.isFavorite }
      } else {
        return user;
      }
    })
    const reqId = isSuper ? imitateNutritionist?.userId : user?.id;
    getActiveClientsFn(reqId)
    setFollowersList(followerToUpdate)
    setFollowerProfile({ ...followerProfile, isFavorite: !followerProfile.isFavorite })
    return followerToUpdate;

  };

  const handleChatScreenClick = () => {
    setBgAnim({...bgAnim, open: true});
    setShowChatAnim(true);
    setShowChatScreen(true);
    const env = process.env.REACT_APP_ENV;
    const userId = isSuper ? imitateNutritionist?.userId : user?.id;
    const roomName = `nutritt_${env}_${clientId}_${userId}`;
    activateConversation(roomName, clientId, followerProfile.fcm_token);
  };

  useEffect(() => {
      if (activeDay) {
        const changeformat = moment(activeDay, 'YYYY-MM-DD').format('MM-DD-YYYY');
        mainSliderRef?.current?.slickGoTo(moment(changeformat, 'MM-DD-YYYY').day());
      }
    

  }, [activeDay])

  useEffect(() => {
    let startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    let days = [];

    while (startOfWeek <= endOfWeek) {
      days.push(moment(startOfWeek).format('MM-DD-YYYY'));
      startOfWeek = moment(startOfWeek).add(1, 'd');
    }
    setDays(days);
    if (!data?.length) {
      setShowMacros(true);
    } else {
      setShowMacros(false)
    }
  }, [data, clientModal]);

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: false,
    beforeChange: (oldIndex, newIndex) => {
      setDiaryMeals(weeklyDiaryMeals[newIndex]);
      if (oldIndex == 6 && newIndex == 0) {
        sliderRef.current.slickNext();
        setMemoActiveDay(true);
      } else if (oldIndex == 0 && newIndex == 6) {
        sliderRef.current.slickPrev();
        setMemoActiveDay(true);
      }
    },
    // afterChange: () => {
    //   stopSwipe && setStopSwipe(false);
    // },
    initialSlide: moment(activeDay, 'YYYY-MM-DD').day(),
  };

  const convert = (groupedDiaryMeals, date) => {
    const res = {};
    let calcCals = 0;
    let consumedInfo = { fat: 0, carbs: 0, protein: 0 };

    // generate meal count
    if (groupedDiaryMeals) {
      groupedDiaryMeals.forEach((obj) => {
        const key = `${obj.id}${obj['New Lv1−Lv2']}`;
        if (!res[key]) {
          res[key] = { ...obj, count: 0 };
        }
        res[key].count += 1;
      });
    }

    // get total calories
    Object.values(res).map((meal) => {
      calcCals += meal.calories * meal.count;
      consumedInfo.fat += meal.fat * meal.count;
      consumedInfo.carbs += meal.carbs * meal.count;
      consumedInfo.protein += meal.protein * meal.count;
    });

    return {
      date,
      meals: Object.values(res),
      totalCals: calcCals,
      consumedInfo,
    };
  };

  const updateActiveWeek = (days, diaryIndex) => {
    setWeeklyDiaryMeals([]);
    let weeklyDiaryMeals = [];
    for (let day of days) {
      let isActive = false;
      data?.length &&
        data.map((item) => {
          for (let key in item) {
            if (item[key][day]) {
              isActive = true;
              weeklyDiaryMeals.push(convert(item[key][day], day));
            }
          }
        });

      if (!isActive) {
        weeklyDiaryMeals.push(convert([], day));
      }
    }
    setWeeklyDiaryMeals(weeklyDiaryMeals);
    setDiaryMeals(weeklyDiaryMeals[diaryIndex]);
  };

  useEffect(() => {
    updateActiveWeek(days, moment(activeDay, 'YYYY-MM-DD').day());
  }, [data, clientModal ,activeDay]);

  const handleFavoriteClick = async () => {
    if (isApiCallInProgress) {
      return;
    }
    setIsHandleFavoriteClickCalled(true);
    const isFav = followerProfile?.isFavorite ? false : true;
    setIsApiCallInProgress(true);
    try {
      await makeFavorite({
        body: { userSubscribedDietPlanId: followerProfile?.dietPlanId, isFavorite: isFav }
      });
      let name = followerProfile?.name;
      if (name.length > 25) {
        name = name.substr(0, 25) + '..';
      }
      await updateFollowerData(followerProfile?.userid);
      getdiaryData(followerProfile?.userid);
      toast.success(`${name || "Follower"} Marked as ${followerProfile?.isFavorite ? "Regular" : "Favorite "} successfully`);
    } catch (err) {
      console.log(err);
      toast.error("Oops! Something went wrong");
      setImage(false);
    } finally {
      setIsApiCallInProgress(false);
      setIsHandleFavoriteClickCalled(false);
    }
  };
  const handleCart = () => {
    setMealsModal(true);
    setShowMealsCart(true);
  }

  useEffect(() => {
      if(!mealsModal){
         document.body.classList.remove('bodyFixed');
         document.body.classList.remove('hide-scroll');
      }
  }, [mealsModal])

  const name = followerProfile?.name;

  useEffect(()=>{
    if (!isHandleFavoriteClickCalled && followerProfile?.userid) {
    getdiaryData(followerProfile?.userid)
  }

  },[followerProfile?.userid, isHandleFavoriteClickCalled]);
  
  useEffect(() => {
    ReactTooltip.rebuild();
    const searchParams = new URLSearchParams(location.search);
    if (followerProfile?.userid && isNotificationRedirect) {
      handleChatScreenClick();
    }
    return () => {
      searchParams.delete('userId');
      const search = searchParams.toString() ? `?${searchParams.toString()}` : '';
      window.history.replaceState({}, '', `${location.pathname}${search}`);
      setIsNotificationRedirect &&  setIsNotificationRedirect(false)
    };
  }, [followerProfile?.userid, location.search]);



  return (isMobile ?
    <ClientDiaryContext.Provider
      value={{ diaryMeals, followerProfile, setDiaryMeals, updateActiveWeek, weeklyDiaryMeals, data }}
    >
      <NutritionistClientProfileStyle className={mealPlannerOpen ? "fadeOutMiddle":`slide ${animation && clientAnim ? 'slideInRight' : 'slideOutBottom'} ${bgAnim.open ? 'slideToTop':''} ${bgAnim.close ? 'slideToBottom':''} ${visibility ? 'hidden' : ''}`}>
        <div className='client-div'>
          <div className='profile'>
            <img className='arrow-left' src='/icons/arrow-left-green.svg' onClick={() => {setAnimation(false); setTimeout(() => {setClientModal(false)}, 500)}} />

            <div className='profile_holder'>
              <ImageViewer
                className='user-img'
                src={followerProfile?.img || '/images/man.svg'}
                alt={'user profile'}
                fallbackSrc={'/images/man.svg'}
              />
              <div className='name'> {name?.length > 20 ? <p data-tip={name} data-for="followername" style={{cursor: 'pointer'}}>{name.substring(0, 20) + "..." || 'User'}</p> : <p>{name || 'User'}</p>} <span onClick={() => setWobble(1)}
                onAnimationEnd={() => setWobble(0)}
                wobble={wobble}
                className='fadedClass' >
                <img src={followerProfile?.isFavorite ? '/icons/colord-star.svg' : '/icons/star.svg'}
                  onClick={handleFavoriteClick}
                />
              </span>
              </div>
            </div>

            
            {!isGetdiaryMealLoading && <img className='follower_cart' src='/icons/cart-green.svg' onClick={handleCart} />}
          </div>

          {isGetdiaryMealLoading ? <Progress/> : 
          <>
            <div className='follower_days'>
              <p className='clients_plan'><span className='plan'>Plan:</span> <span className='plan-name'>{followerProfile?.dietTemplateName}</span></p>
              {
                data?.length > 0 && (
                  <SliderWeekDays
                    ref={sliderRef}
                    mainSliderRef={mainSliderRef}
                    activeDay={activeDay}
                    setDays={setDays}
                    setStopSwipe={setStopSwipe}
                    memoActiveDay={setMemoActiveDay}
                    clientModal={clientModal}
                    recommendedCalories={followerProfile?.recommndedNutrients?.calories || 0}
                    setCurrentDate={setCurrentDate}
                  />
                )
              }

            </div>

            <div className='macros-toggler'> <img src={showMacros ? '/icons/macros-up.svg' : '/icons/macros-down.svg'} onClick={() => setShowMacros(!showMacros)} /> </div>

            <Slider ref={mainSliderRef} {...settings}>
              {weeklyDiaryMeals.map((diary) => (
                <div key={diary.id} >
                  {showMacros &&
                    <FadeDiv>
                      <div className='macros'>
                        <div className='total-calories'>
                          <NutritionistClientCalories diaryMeals={diaryMeals} recommndedNutrients={followerProfile?.recommndedNutrients} />
                        </div>
                      </div>
                    </FadeDiv>
                  }
                  <div className={`clientMeals-module ${showMacros ? 'slideInDown' : 'slideOutTop'}`}>
                    <p className='logged'>Logged meals</p>
                    <ClientLoggedMeals
                      activeDay={activeDay}
                      diaryMeals={diary.meals}
                      followerProfile={followerProfile?.name}
                      showMacros={showMacros}
                      currentDate={currentDate}
                      data={data}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            </>
          }
        </div>
        <ReactTooltip id="followername" place="top" effect="solid">
     </ReactTooltip>
      </NutritionistClientProfileStyle>

      {/* SEND MEALS MODAL */}
        
        {mealsModal && 
        <SendMealsModal 
        mealsModal={mealsModal}
        setMealsModal={setMealsModal} 
        userDeliveryAddress={userDeliveryAddress} 
        showMealsCart={showMealsCart} 
        setShowMealsCart={setShowMealsCart} 
        clientId={clientId} 
        followerProfile={followerProfile} 
        setClientModal={setClientModal}
        setClientAnim={setClientAnim}
        setMealPlannerClose={setMealPlannerClose}
        />
        }
      
      <FixedContainer onClick={handleChatScreenClick}>
        <ClientMessageNotificationStyle>
            <div className='input_container'>
              <span>
                Message {followerProfile?.name}...
              </span>
              <div className='notification_icon'>
                <img src='/icons/notification-green.svg'/>
                {unreadMessages > 0 && <span className='unseenMsg'>{unreadMessages}</span>}
              </div>
            </div>
        </ClientMessageNotificationStyle>
      </FixedContainer> 
        {showChatScreen && 
        <ChatWithFollower bgAnim={bgAnim} setBgAnim={setBgAnim} showChatAnim={showChatAnim} clientId={clientId} followerProfile={followerProfile} setShowChatAnim={setShowChatAnim} setShowChatScreen={setShowChatScreen}/>
        }
    </ClientDiaryContext.Provider>
    :
    <ClientDiaryContext.Provider
      value={{ diaryMeals, setDiaryMeals, updateActiveWeek, weeklyDiaryMeals, data }}
    >
      <NutritionistClientProfileStyle className={classNames({ slideUp: clientModal, desktopDesign: true })}>
        <div className='client-div'>
          <div className='client-info'>
            <div className='inner-div'>
              <img className='user-img' src={followerProfile?.img || '/images/man.svg'} />
              <div className='name-plan'>
                <div className='name'>
                  {name?.length > 27 ? <p data-tip={name} data-for="clientname" style={{cursor: 'pointer'}}>{name.substring(0, 27) + "..." || 'User'}</p> : <p>{name || 'User'}</p>}
                  <div onClick={() => setWobble(1)}
                    onAnimationEnd={() => setWobble(0)}
                    wobble={wobble}
                    className='fadedClass' >
                    <img src={followerProfile?.isFavorite ? '/icons/colord-star.svg' : '/icons/star.svg'}
                      onClick={handleFavoriteClick}
                      width={15}
                    />
                  </div>
                </div>
                <p><span className='plan'>Plan:</span> <span className='plan-name'>{followerProfile?.dietTemplateName || 'Loose-weight'}</span></p>
                {/* <button className='create-meal-bttn'>Create meal plan</button> */}
              </div>
            </div>
          </div>
          <div>
            {
              data?.length > 0 && (
                <SliderWeekDays
                  ref={sliderRef}
                  mainSliderRef={mainSliderRef}
                  days={days}
                  activeDay={activeDay}
                  setActiveDay={setActiveDay}
                  setDays={setDays}
                  setStopSwipe={setStopSwipe}
                  memoActiveDay={setMemoActiveDay}
                  recommendedCalories={followerProfile?.recommndedNutrients?.calories || 0}
                  setCurrentDate={setCurrentDate}
                />
              )
            }
          </div>
          <div className="d-block position-relative">
            <div className='img-div'> <img className={showMacros ? 'macros-up' : 'macros-down'} src={showMacros ? '/icons/macros-up.svg' : '/icons/macros-down.svg'} onClick={() => setShowMacros(!showMacros)} /> </div>
            <Slider ref={mainSliderRef} {...settings}>
              {weeklyDiaryMeals.map((diary) => (
                <div className='clientprofileFullD' key={diary.id}>
                  {showMacros &&
                    <FadeDiv>
                      <div className='macros'>
                        <div className='total-calories'>
                          <NutritionistClientCalories diaryMeals={diaryMeals} recommndedNutrients={followerProfile?.recommndedNutrients} />
                        </div>
                      </div>
                    </FadeDiv>
                  }
                  <div className='logged-div'>
                    <p className='logged'>Logged meals</p>
                    <ClientLoggedMeals
                      activeDay={activeDay}
                      diaryMeals={diary.meals}
                      followerProfile={followerProfile?.name}
                      showMacros={showMacros}
                      currentDate={currentDate}
                      data={data}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      <ReactTooltip id="clientname" place="top" effect="solid">
     </ReactTooltip>
      </NutritionistClientProfileStyle>
    </ClientDiaryContext.Provider>
  )
}

