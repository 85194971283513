import { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Timezone from 'components/Booking/Timezone';
import SelectTime from 'components/Booking/SelectTime';
import InlineCalendar from 'components/Booking/InlineCalendar';
import { formatNumberDecimalPlaces } from '../../utils';
import Progress from 'components/Progress';
import { useNutritionistContext } from '../../context/nutritionistContext';
import { Link,useHistory } from 'react-router-dom';
import Footer from 'layout/Footer';
import Steps from '../GuestUser/Steps';
import { useAppContext } from 'context/appContext';

const slotGenerator = (selectedDate, slotInterval, openTime, closeTime) => {
  let x = {
    slotInterval,
    openTime,
    closeTime,
  };

  let startTime = moment(selectedDate).add(moment.duration(x.openTime));
  let endTime = moment(selectedDate).add(moment.duration(x.closeTime));
  let allTimes = [];
  while (startTime < endTime) {
    if(startTime.add(x.slotInterval, 'minutes').isAfter(endTime)){break;}
    startTime.subtract(x.slotInterval, 'minutes');
    allTimes.push({ label: startTime.format('LT'), value: startTime.toString() });
    startTime.add(x.slotInterval, 'minutes');
  }
  return allTimes;
};

const BookingScheduleWrapper = styled.div`
  .desc {
    width: 80%;
    margin: 23px auto;
    text-align: center;

    h2 {
      margin-bottom: 5px;
      color: #0b1d17;
      font-family: 'Roboto';
    }

    p {
      color: #949d9b;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .schedule-wrapper {
    padding: 20px 0;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  .amout-wrapper {
    padding: 0 20px 14px;
    border-bottom: 1px solid #f6f5f8;
    display: flex;
    align-items: center;

    span {
      margin-left: 7px;
      color: #51635d;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .calendar-wrapper {
    padding: 14px 0;
    border-bottom: 1px solid #f6f5f8;
  }

  .timezone-wrapper {
    margin-bottom: 20px;

    label {
      margin-bottom: 7px;
      color: #51635d;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
      display: block;
      font-weight: 700;
    }
  }

  .select-time-wrapper {
    padding: 14px 20px 10px;
  }

  .step-header{
    padding-bottom: 0 !important;
  }

  @media(min-width: 992px){
    .schedule-wrapper{
      width: 46%;
      justify-content: center;
      margin: auto;
      padding: 0 !important;

      .amout-wrapper{
        padding: 23px 29px 14px;
        border-bottom: 1.5px solid #f6f5f8 !important;
        
        img{
          width: 20px;
        }
        span {
          font-size: 24px;
          line-height: 28px;
          margin-left: 11px;

        }
      }

      .calendar-wrapper{
        border-bottom: 1.5px solid #f6f5f8 !important;
      } 
    } 

    .select-time-wrapper {
      padding: 40px 61px;
      .sc-aiooD cRa-dxF{
        width: 50%;
      }
    }
  }
`;
const ErrorMessage = styled.div`
  p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635d;
    padding-top: 40px;
    padding-bottom: 25px;
  }
  .login_button {
    background-color: #52c41a;
    border-radius: 31px;
    width: 200px;
    height: 62px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 10px 12px 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin: auto;
    cursor: pointer;
  }
`;

const TokenError = ({ message }) => {
  return (
    <ErrorMessage>
      <p>{message}</p>
      <br></br>
      <Link className="login_button" to="/">
        Home
      </Link>
    </ErrorMessage>
  );
};

const getExcludedDatesOfMonth = (date, unavailableDaysOfMonth, excludeDates) => {
  const data = unavailableDaysOfMonth.map((day) => {
    const monday = moment(date).startOf('month').day(day);
    if (monday.date() > 7) monday.add(7, 'd');
    const month = monday.month();
    const dateArr = [];
    while (month === monday.month()) {
      dateArr.push(monday.valueOf());
      monday.add(7, 'd');
    }
    return dateArr;
  });
  const excludeList = [].concat.apply([], data);
  excludeDates.forEach((item) => {
    excludeList.push(moment(item).valueOf());
  });
  return excludeList;
};

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const daysMap = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const daysMapNumber = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const firstDay = (dayINeed) => {
  const today = moment().isoWeekday();
  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(dayINeed).valueOf();
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment().add(1, 'weeks').isoWeekday(dayINeed).valueOf();
  }
};

export default function BookingSchedule() {
  const { offerData } = useNutritionistContext();
  const { price, schedule, excludeDates, availability, bookedSlots, description, title } =
    offerData;
  const { isMobile } = useAppContext();  
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState();
  const [excludeDateList, setExcludeDateList] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [minDate, setMinDate] = useState(new Date());
  const history=useHistory();

  const setExcludeDate = (excludeStartDate) => {
    if (schedule) {
      const unavailableDaysOfMonth = [];
      schedule.forEach((item) => {
        if (item.Monday && !item.Monday.available) {
          unavailableDaysOfMonth.push('Monday');
        } else if (item.Tuesday && !item.Tuesday.available) {
          unavailableDaysOfMonth.push('Tuesday');
        } else if (item.Wednesday && !item.Wednesday.available) {
          unavailableDaysOfMonth.push('Wednesday');
        } else if (item.Thursday && !item.Thursday.available) {
          unavailableDaysOfMonth.push('Thursday');
        } else if (item.Friday && !item.Friday.available) {
          unavailableDaysOfMonth.push('Friday');
        } else if (item.Saturday && !item.Saturday.available) {
          unavailableDaysOfMonth.push('Saturday');
        } else if (item.Sunday && !item.Sunday.available) {
          unavailableDaysOfMonth.push('Sunday');
        }
      });

      const availableDaysOfMonth = days.filter((day) => {
        const isDayMarkedUnavailable = unavailableDaysOfMonth.includes(day);
        return !isDayMarkedUnavailable;
      });
      const availableDaysOfMonthNumber = availableDaysOfMonth.map((day) => daysMap[day]);
      const currentDay = new Date().getDay();
      let i = currentDay;
      let doIterate = true;
      let startDay;
      while (i < 8 && doIterate) {
        const index = availableDaysOfMonthNumber.indexOf(i);
        if (index !== -1) {
          startDay = availableDaysOfMonthNumber[index];
          doIterate = false;
        } else {
          ++i;
        }
      }
      const date = startDay ? firstDay(startDay) : firstDay(new Date().getDay());
      setMinDate(date);
      const excludeDatesList = getExcludedDatesOfMonth(
        excludeStartDate,
        unavailableDaysOfMonth,
        excludeDates,
      );
      setExcludeDateList(excludeDatesList);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      let day = daysMapNumber[selectedDate?.day()];
      let selectedDay = schedule?.find((item) => item[day]);
      day = selectedDay[day];
      const slots = day.slots.map((slot) => {
        return slotGenerator(selectedDate, availability, slot.from, slot.to);
      });
      const availableSlots = [].concat.apply([], slots);
      const data = availableSlots.filter((item) => {
        const dateValue = new Date(item.value).toISOString();
        return !bookedSlots.includes(dateValue);
      });
      const filteredSlots = data.filter(function (item) {
        if(new Date(item.value) == new Date()){
          return (new Date(item.value).getHours()*60)+(new Date(item.value).getMinutes())>=(new Date().getHours()*60)+(new Date().getMinutes());
        }
        return new Date(item.value) > new Date();
      });
      setAvailableSlots(filteredSlots);
    }
  }, [selectedDate]);

  useEffect(async () => {
    setExcludeDate();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [schedule]);

  if (isLoading) {
    return <Progress />;
  }

  return (
    <>
    <BookingScheduleWrapper>
      {offerData.offerError ? (
        <TokenError message={offerData.offerError} />
      ) : (
        <>
          {isMobile ?
          <Steps
          title="Schedule"
          description1="Pick a time that works best"
          description2="for your schedule."
          perviousLink={()=>{
            history.goBack();
           }}
         /> :
         <Steps
           title="Schedule"
           description1="Pick a time that works best for your schedule."
           perviousLink={()=>{
            history.goBack();
           }}
          />
          }
          <div className="schedule-wrapper">
            <div className="amout-wrapper">
              <img src="/icons/card.svg" alt="Credit Icon" />
              <span>${formatNumberDecimalPlaces(price / 100)}</span>
            </div>
            <div className="calendar-wrapper">
              <InlineCalendar
                excludeDateList={excludeDateList}
                selectedDate={(date) => setSelectedDate(date)}
                minDate={minDate}
                setExcludeDate={setExcludeDate}
              />
            </div>
            <div className="select-time-wrapper">
              <div className="timezone-wrapper">
                <label>Time Zone</label>
                <Timezone selectedTimezone={setSelectedTimezone} />
              </div>
              {selectedDate && (
                <SelectTime
                  selectedDate={selectedDate}
                  selectedTimezone={selectedTimezone}
                  availableSlots={availableSlots}
                />
              )}
            </div>
          </div>
        </>
      )}
    </BookingScheduleWrapper>
    </>
    
  );
}

