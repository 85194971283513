import React, { useEffect, useMemo, useState } from 'react';
import { theme } from 'shared/theme/theme';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import MobileHeader from 'components/elements/MobileHeader';
import { Button } from 'components/elements/button/Button';
import { useAppContext } from 'context/appContext';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { ReactComponent as Level } from "assets/shop/pause-subscription.svg";
import { ReactComponent as PauseTimer } from "assets/shop/pause-timer.svg";
import moment from 'moment';

const PauseSubscriptionStyled = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  height: 100%;
  .close{
    img{
      height: 20px;
      widht: 20px;
    }
  }  
  .main_heading {
    margin: 16px 25px 12px;
    font-size: ${({ theme }) => theme?.typography?.fontMedium};
    line-height: 24px;
    color: ${({ theme }) => theme?.colors?.deepForestGreen};
    height: 50px;
    font-weight: 600;
  }
  .containerr {
    height: calc(100% - 48px);
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .checkbox___card {
        margin: 8px 16px;
        .card-container{
          height: 68px;
        }
        .content{
          .title{
            height: 20px;
            color: #000A2C;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
          }
          .subtitle{
            :nth-child(2){
              color: #949D9B !important;
              height: 12px;
              font-family: Gilroy;
              font-size: 11px;
              font-style: normal;
              font-weight: 500;
              line-height: 12px;
              letter-spacing: 0.22px;
              margin-bottom: 4px;
            }
          }
          .secondarySubtitle{
            height: 12px;
            color: #000A2C;
            font-family: Gilroy;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.22px;
          }
        }
        .checked .icon{
            background-color: inherit;
            svg{
              height: 34px;
              width: 34px;          
            }
            circle{
                fill: #fff;
              }        
        }
        .icon{
            span{
                width: 34px;
                height: 34px;
            }
        }
      }    
  }
  .button_holder{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    gap: 12px;
    padding: 44px 16px 29px;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
    button{
      height: 42px;
      font-family: Gilroy;
  }
  }

  @media (min-width: 991px) {
    width: 400px;
    max-height: 571px;
    border-radius: 8px;
    overflow: clip;
    position: relative;
    .modal__cross{
      padding: 16px;
    }
    .containerr{
      height: calc(100% - 106px - 48px);
      .main_heading{
        margin: 0 45px 17px 44px;
        text-align: left;
        font-size: ${({ theme }) => theme?.typography?.fontMedium};
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.primaryDarker};
        padding-top: 0;
        padding-bottom: 8px;  
      }
      .checkbox___card{
        margin: 8px 36px 8px 37px;
        .content{
          text-align: left;
        }
      }
    }
    .button_holder{
      position: absolute;
      button{
        font-family: Gilroy;
    }
    }
  }
`;

const PausedSubscriptionStyled = styled.div`
background-color: ${({ theme }) => theme?.colors?.white};
height: 100%;
.close{
  img{
    height: 20px;
    widht: 20px;
  }
}
.main_heading {
  padding: 16px 16px 12px;
  font-size: ${({ theme }) => theme?.typography?.fontMedium};
  line-height: 24px;
  color: ${({ theme }) => theme?.colors?.deepForestGreen};
  font-weight: 600;
}
.bottom-text{
  position: fixed;
  left: 0;
  bottom: 10%;
  width: 100%;
  text-align: center;
  span{
    color: #000A2C;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  }

.containerr {
  height: calc(100% - 48px);
  overflow: auto;
  text-align: center;
  margin-top: 97px;
  &::-webkit-scrollbar {
    display: none;
  }
  .text-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    span{
      color: #000A2C;
      text-align: center;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .date{
        font-weight: 500;
    }
    .resume{
        font-weight: 500;  
        color: #949D9B;
    }
  }
}

@media (min-width: 991px) {
  width: 400px;
  max-height: 571px;
  border-radius: 8px;
  overflow: clip;
  position: relative;
  .main_heading{
    font-size: ${({ theme }) => theme?.typography?.fontBase};
    font-weight: 700;
    color: ${({ theme }) => theme?.colors?.primaryDarker};
    padding-top: 0;
    padding-bottom: 8px;
  }
  .bottom-text{
    position: relative;
  }
  .modal__cross{
    padding: 16px;
  }
  .containerr{
    height: calc(100% - 106px - 10px);
    margin: 0;
  }
}

`

const DesktopPopupStyle = styled.div`
  @media (min-width: 991px) {
    .desktop_wrapper{
      width: 100vw;
      height: 100vh;
      background: rgba(81, 99, 93, 0.65);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add-payment{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute !important;
      inset: 0;
      .mobile_save{
        position: absolute;
      }
    }
  }
`;

const pauseSubscription = ({ 
  setIsPaymentModalActive,
  isPaymentModalActive,
  subscriptionStatus,
  orderItems,
  isSubscriptionPaused,
  pausedSubscriptionClose,
  isLoading 
}) => {
  
  const calculateDateAfterWeeks = (skip, intervaldays, nextOrderDate) => {
    const dateAfterWeeks = moment(nextOrderDate).add(intervaldays*skip, "days").format('MMM DD YYYY');
    return dateAfterWeeks;
  };

  const activityLevels = [
    {
      icon: <Level />,
      title: 'Skip next 1 order ',
      desc: 'Your subscription will resume on:',
      desc1: `${calculateDateAfterWeeks(1, orderItems?.intervaldays, orderItems?.nextOrderDate)}`,
      orderSkipped: 1,
      value: 7
    },
    {
      icon: <Level />,
      title: 'Skip next 2 orders',
      desc: 'Your subscription will resume on:',
      desc1: `${calculateDateAfterWeeks(2, orderItems?.intervaldays, orderItems?.nextOrderDate)}`,
      orderSkipped: 2,
      value: 14
    },
    {
      icon: <Level />,
      title: 'Skip next 3 orders',
      desc: 'Your subscription will resume on:',
      desc1: `${calculateDateAfterWeeks(3, orderItems?.intervaldays, orderItems?.nextOrderDate)}`,
      orderSkipped: 3,
      value: 21
    },
    {
        icon: <Level />,
        title: 'Skip next 4 orders',
        desc: 'Your subscription will resume on:',
        desc1: `${calculateDateAfterWeeks(4, orderItems?.intervaldays, orderItems?.nextOrderDate)}`,
        orderSkipped: 4,
        value: 28
    },

  ];    

    const { isMobile } = useAppContext();
    const [selectedValue, setSelectedValue] = useState('');

    const pausedSubscriptionData = useMemo(() => {
     return activityLevels?.find((data) => data?.value === selectedValue[0]);
    },[activityLevels, selectedValue])

    const handleClick = (value) => {
      setSelectedValue(value);
    };
      
  return (
    <DesktopPopupStyle>
      <AnimatePresence>
      {isPaymentModalActive &&
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}
        style={{ position: 'fixed', zIndex: 9, inset: 0 }}
        className="desktop_wrapper"
      >
        <>
          <PauseSubscriptionStyled theme={theme}>
            {isMobile ? (
              <MobileHeader
                text={'Pause subscription'}
                onClickLeft={() => {setIsPaymentModalActive(false), setSelectedValue('')}}
              />
            ) : (
              <MobileHeader
                className={"modal__cross"}
                onClickClose={() => {setIsPaymentModalActive(false), setSelectedValue('')}}
              />
            )
            }
            <div className="containerr">
              <h2 className="main_heading">How long would you like to pause your subscription?</h2>
              <div className="levels_holder">
                {activityLevels?.map((level, index) => (
                  <div className="checkbox___card" key={index}>
                    <CheckboxCard
                      icon={level?.icon}
                      title={level?.title}
                      subtitle={level?.desc}
                      secondarySubtitle={level?.desc1}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      radio={true}
                      count={true}
                      orderSkipped={level?.orderSkipped}
                      value={level?.value}
                      onClick={handleClick}
                      className={"card-container"}
                    />
                  </div>
                ))}
              </div>

            </div>
            <div className="button_holder">
                <Button
                  type={'mobile_save'}
                  title={'Pause subscription'}
                  onClick={() => subscriptionStatus(orderItems?.id, "paused", pausedSubscriptionData)}
                  isDisabled={!selectedValue?.length || isLoading}
                />
                <Button
                  type={'mobile_secondary'}
                  title={'Cancel'}
                  onClick={() => {setIsPaymentModalActive(false), setSelectedValue('')}}
                />
            </div>
          </PauseSubscriptionStyled>
        </>
      </motion.div>
      }
      </AnimatePresence>
      <AnimatePresence>
      {
        isSubscriptionPaused && 
        <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', duration: 0.5 }}
        exit={{ x: '100%', opacity: 0 }}
        style={{ position: 'fixed', zIndex: 9, inset: 0 }}
        className="desktop_wrapper"
      >
        <>
        <PausedSubscriptionStyled theme={theme}>
        {isMobile ? (
              <MobileHeader
                text={'Subscription paused'}
                onClickLeft={() => {pausedSubscriptionClose(), setSelectedValue('')}}
              />
            ) : (
              <MobileHeader
                className={"modal__cross"}
                onClickClose={() => {pausedSubscriptionClose(), setSelectedValue('')}}
              />
            )
            }
            <div className="containerr">
                <PauseTimer />
                <div className='text-container'>
                    <span>Order skipped: {pausedSubscriptionData?.orderSkipped}</span>
                    <span className='resume'>Your subscription will resume on:</span>
                    <span className='date'>{pausedSubscriptionData?.desc1}</span>
                </div>
            </div>
            <div className="bottom-text">
            {isMobile ? <span>Need to adjust? you can easily <br /> change or resume your subscription <br /> in your settings at any time.</span> : <span>Need to adjust? you can easily change or <br /> resume your subscription in your settings at <br /> any time.</span>}
            </div>
        </PausedSubscriptionStyled>
        </>
      </motion.div>
      }
      </AnimatePresence>
    </DesktopPopupStyle>
)
}

export default pauseSubscription
