import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import Drawer from '@material-ui/core/Drawer';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import useCart from 'hooks/useCart';
import ComponentCard from './ComponentCard';
import CombosCardContent from './CombosCardContent';
import { useAppContext } from 'context/appContext';

const ComposWrapperStyle = styled.div`
  padding: 16px 0;
  border-top: 1px solid #e2e3e2;
  border-bottom: 1px solid #e2e3e2;
  .container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    cursor: pointer;
    img {
      width: 28%;
      height: 67px;
      margin-right: 16px;
      border-radius: 8px;
      object-fit: cover;
    }
    .title {
      margin-bottom: 8px;
      color: #091714;
      font-size: 18px;
    }
  }
`;

const ComposDrawerStyle = styled.div`
  overflow: auto;
  height: 100%;
  .drawer-container {
    width: 90%;
    margin: 0 auto;
    padding: 18px 0 99px;
    h2 {
      margin: 0 6px;
      padding: 14px 0 20px;
      color: #091714;
      font-size: 20px;
      border-bottom: 1px solid #e0e3e0;
      font-weight: 600;
    }
    h3 {
      margin: 18px 6px;
      color: #091714;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .card-button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -3px 6px #00000019;
    height: 85px;
    display: flex;
    align-items: center;
    z-index: 9999 !important;
    .container {
      width: 90%;
      margin: 0 auto;
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .math {
      color: #bdbfbd;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .count {
      width: 36px;
      color: var(--dark);
      font-size: 14px;
      cursor: auto;
    }
    .math-wrapper {
      display: flex;
      background: #FFFFFF;
      border: 1px solid #E0E3E0;
      box-sizing: border-box;
      border-radius: 6px;
      min-width: 131px;
      justify-content: space-between;
      .math{
        flex: 1;
      }
      input {
        width: 36px;
        height: 36px;
        margin: 0 4px;
        color: #0b1c18;
        font-size: 16px;
        text-align: center;
        border: none;
        flex: 1;
        font-family: 'Roboto';
      }
      span {
        svg {
          color: #949D9B;
          font-size: 20px;
        }
      }
    }

    .card-button {
      width: 100%;
      max-width: 181px;
      height: 44px;
      background-color: #52c41a;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 0 #00000014;
      cursor: pointer;
      span {
        margin-right: 15px;
        color: #fff;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        font-family: 'Roboto';
      }
    }
  }
`;

export default function Combos({ comboComponents }) {
  let {
    isMobile
  } = useAppContext();
  const { addToCart } = useCart();

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(1);
  const [selectedComponents, setSelectedComponents] = useState([]);

  useEffect(() => {
    //*clear drawer when closing
    if (!isOpen) {
      setCount(1);
      setSelectedComponents([]);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   console.log("selectedComponents changed:", selectedComponents);
  // }, [selectedComponents]);

  const handleCountChange = (e) => setCount(e.target.value);
  const requiredComponentIds = comboComponents
    .filter((item) => item.isRequired)
    .map((item) => item.id);
  const selectedCompIds = selectedComponents.map((item) => item.componentId);
  const isComboComplete =
    !!selectedComponents.length && requiredComponentIds.every((id) => selectedCompIds.includes(id));
  const handleAddComboToCart = () => {
    addToCart({
      components: selectedComponents,
      qty: count,
      isCombo: true,
    });
    setIsOpen(false);
  };

  const handleSelect = (componentId, meal) => {
    const filtered = selectedComponents.filter(
      (component) => component.componentId !== componentId,
    );
    setSelectedComponents([...filtered, meal]);
  };
  const mobileView  = isMobile ? 'mobileView' : '';

  return (
    <>
      <ComposWrapperStyle className='customizeMeal-inner' onClick={() => setIsOpen(true)}>
        <CombosCardContent title="Customize your meal" />
      </ComposWrapperStyle>
      <Drawer className="drawer-main" anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
        <ComposDrawerStyle>
          <div className={`drawer-container ${mobileView}`}>
            <div className="close">
              <IoMdClose onClick={() => setIsOpen(false)} />
            </div>
            <h2>Customize your meal</h2>
            <div className='meal-drawer-items'>
              {comboComponents.map(
                ({ name: componentName, id: componentId, comboSelection, isRequired }) => {
                  return (
                    <div className='meal-items-inner' key={componentId}>
                      <h3>
                        Select a {componentName} {isRequired ? '(Required)' : '(Optional)'}
                      </h3>
                      {comboSelection.map((meal) => (
                        <ComponentCard
                          key={meal.selectionId}
                          meal={meal}
                          onSelectComboMeal={() => handleSelect(componentId, meal)}
                          selected={selectedComponents.some(({ id }) => id === meal.id)}
                        />
                      ))}
                    </div>
                  );
                },
              )}
            </div>
          </div>

          <div className="card-button-wrapper">
            <div className="container">
              <div className="math-wrapper">
                <span className="math" onClick={() => count > 1 && setCount(Number(count) - 1)}>
                  <CgMathMinus />
                </span>
                <input
                  type="number"
                  autoComplete="off"
                  className="math"
                  value={count}
                  onChange={handleCountChange}
                />
                <span className="math" onClick={() => setCount(Number(count) + 1)}>
                  <CgMathPlus />
                </span>
              </div>
              <button
                type="button"
                className={classNames('card-button', !isComboComplete && 'disabled')}
                disabled={!isComboComplete}
                onClick={handleAddComboToCart}
              >
                <span>{`Add ${count} to Cart`}</span>
              </button>
            </div>
          </div>
        </ComposDrawerStyle>
      </Drawer>
    </>
  );
}
