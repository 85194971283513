import { useState, useEffect, useRef, useMemo } from 'react';
import useCart from 'hooks/useCart';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { NavHashLink } from 'react-router-hash-link';
import { BottomSheet } from 'react-spring-bottom-sheet';
import KitchenMealsDesktop from '../KitchenMealsDesktop';
import { Button } from 'components/elements/button/Button';
import Tab from 'components/shop/FavoriteKitchen/stickyTab';
import Header from 'components/shop/FavoriteKitchen/Header';
import MobileHeader from 'components/elements/MobileHeader';
import QuantityButton from 'components/elements/QuantityButton';
import Description from 'components/shop/FavoriteKitchen/Description';
import KitchenMealCard from 'components/shop/FavoriteKitchen/KitchenMealCard';
import KitchenMealDetails from 'components/shop/FavoriteKitchen/KitchenMealDetails';
import useVisibility from 'components/shop/FavoriteKitchen/KitchenMealDetails/VisibilityHook';
import { FavoriteKitchenWrapper } from './style';
import 'react-spring-bottom-sheet/dist/style.css';
import { isPointInPolygon } from 'utils/verify-coordinates-in-polygon.util';
import { useVisitorContext } from 'context/visitorContext';
import useVisitorCart from 'hooks/useVisitorCart';
import Progress from 'components/Progress';
import KitchenDetailsSkeleton from 'skeletons/user/KitchenDetailsSkeleton';
import Bummer from 'components/shop/FavoriteKitchen/Bummer';
import { setKitchenToSession } from 'utils/setFavoriteKitchen';

export default function FavoriteKitchen() {
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTabClick, setIsTabClick] = useState(false);
  const [categoriesHaveMeals, setCategoriesHaveMeals] = useState([]);
  const [mealDetails, setMealDetails] = useState({});
  const [mealInfo, setMealInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState();
  const [initialQty, setInitialQty] = useState();
  const itemsRef = useRef([]);
  const descriptionRef = useRef();
  const categoriesTabsRef = useRef();
  const {
    user,
    isMobile,
    isDesktop,
    setSelectedProviderId,
    isShoppingUser,
    favoriteKitchenMeals: kitchenMeals,
    setFavoriteKitchenMeals,
    profile,
    setRouteBeforeSignup
  } = useAppContext() || {};
  const { cart, settings, setShowBummer, showBummer,numBummer, isFetchingKitchenDetails, isKitchenNotFound } = (user && isShoppingUser) ? useShopContext() : useVisitorContext();
  const { addToCart, updateMealQty } = user ? useCart() : useVisitorCart();
  const mealInCart = useMemo(() => cart.find(meal => meal.id === mealInfo?.id), [cart, mealInfo]);
  const history = useHistory();
  const [beforeCheckoutSubmitShown, beforeCheckoutSubmitRef] = useVisibility(open);
  const [generateMealDetails, { isLoading: isMealGenerating }] = useRequest();
  const [generateFavoriteKitchenMeals, { isLoading: isFetchingKitchenMeals, error }] = useRequest();
  const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(true);
  const { userInfo } = settings;
  const [showArea, setShowArea] = useState(false);
  const kitchenData = user?.profile?.favoriteKitchenData;
  const {
    settings: { mealPlan, provider },
    setKitchenUserName,
    availableProviders,
  } = (user && isShoppingUser) ? useShopContext() : useVisitorContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlMealId = searchParams.get('mealId');
  const [animationComplete, setAnimationComplete] = useState(false);
  const {providerId: storeUrl}= useParams();
  const [toCartPage, setToCartPage] = useState(false);

  const favoriteKitchenMeals = useMemo(() => {
    return kitchenMeals?.filter(item => {
      const restrictions = (item?.restrictions || []).map(tag => tag.toLowerCase());
      const allergies = (profile?.selfMealPlan?.allergies || []).map(tag => tag.toLowerCase());
      if (allergies.length === 0) {
        return true;
      }
      if (restrictions.length === 0) {
        return true;
      }
      return !allergies.some(allergy =>
        restrictions.includes(allergy)
      );
    });
  }, [kitchenMeals, profile?.selfMealPlan?.allergies]);

  useEffect(() => {
    if(urlMealId && favoriteKitchenMeals?.length){
      const found = favoriteKitchenMeals?.find(meal => Number(meal?.id) === Number(urlMealId));
      if(found && Object.keys(found)?.length){
        setMealDetails(found);
        animationComplete && setOpen(true);
      }
    }
  }, [urlMealId, favoriteKitchenMeals, animationComplete])

  useEffect(() => {
    if(provider?.id || provider?.providerId){
      setSelectedProviderId(provider?.id || provider?.providerId);
    }else{
      const foundProvider = availableProviders?.data?.find((x) => x?.BusinessProfileSettings[0]?.businessProfileId === provider?.businessProfileId);
      setSelectedProviderId(foundProvider?.id);
    }
  }, [availableProviders, provider])

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -88;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const categoriesItems = categoriesHaveMeals.map((category) => {
    return {
      id: category?.id,
      title: (
        <div className="category-wrapper">
          <NavHashLink smooth to={`#${category?.name}`} scroll={(el) => scrollWithOffset(el)}>
            {category?.name.toLowerCase()}
          </NavHashLink>
        </div>
      ),
      onClick: () => {
        setSelectedTabId(category?.id);
        setIsTabClick(true);
        setTimeout(() => {
          setIsTabClick(false);
        }, 1000);
      },
    };
  });

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const getCategoriesHaveMeals = () => {
    let categories = [];
    favoriteKitchenMeals?.map((meal) => {
      if (meal?.category?.id) {
        return categories.push(meal?.category);
      }
    });
    const uniqueCategories = [
      ...new Map(categories.map((category) => [category.id, category])).values(),
    ];
    return setCategoriesHaveMeals(uniqueCategories);
  };

  const generateMealsPrice = () => {
    let mealsPrice = [];
    mealPlan.map((meal) => mealsPrice.push(+meal.price));
    const getMealsPrice = mealsPrice.reduce((a, b) => a + b, 0);
    return getMealsPrice;
  };

  const getFavoriteKitchenMeals = async (provider) => {
    const path = `provider/meal-info?businessProfileId=${
      provider?.businessProfileId ? provider?.businessProfileId : provider?.BusinessProfile[0]?.id
    }&username=${storeUrl}`;
    await generateFavoriteKitchenMeals({ path })
      .then((res) => {
        if (res?.data?.success) {
            setFavoriteKitchenMeals(res?.data?.storeInfo?.meals);
            setKitchenUserName(res?.data?.storeInfo?.username);
        }
      })
      .catch((err) => console.log({ err }));
  };

  const getMealDetails = async () => {
    const path = `meal/list?mealId=${mealDetails?.id}&providerId=${mealDetails?.providerId}`;
    await generateMealDetails({ path })
      .then((res) => {
        setMealInfo(res?.data?.data[0]);
      })
      .catch((err) => console.log({ err }));
  };

  const closeMealDetails = () => {
    setOpen(false);
    setMealInfo({})
  };

  useEffect(() => {
    if (!isMobile) {
      !user && sessionStorage.setItem('storeUrl', storeUrl);
    }
  }, []);

  useEffect(() => {
    if(storeUrl && provider && Object.keys(provider)?.length){
      setKitchenToSession(storeUrl, history);
      if(provider.username === storeUrl){
        getFavoriteKitchenMeals(provider)
      }
    }
  }, [provider, storeUrl])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    categoriesHaveMeals.map((category, index) => {
      const itemsOffset = itemsRef.current[index]?.offsetTop - 150;
      if (scrollPosition > itemsOffset && !isTabClick) {
        setSelectedTabId(category?.id);
      }
    });

    if (scrollPosition < descriptionRef.current?.offsetTop + 100) setSelectedTabId(null);
  }, [scrollPosition]);

  useEffect(() => {
    getCategoriesHaveMeals();
  }, [favoriteKitchenMeals?.length]);

  useEffect(() => {
    if (open) {
      getMealDetails();
    }
  }, [open]);

  useEffect(() => {
    setInitialQty(mealInCart?.qty);
    mealInCart ? setCount(mealInCart?.qty) : setCount(1);
  }, [open, mealInfo]);

  const hasMealsWithoutCategory = useMemo(() => {
    if(favoriteKitchenMeals?.length){
      return favoriteKitchenMeals.some((meal) => meal?.category?.id === null);
    }
  }, [favoriteKitchenMeals]);

  const checkIfDeliveryAvailable = () => {
    const deliverableAreas = provider && provider?.deliverableAreas?.data || [];
    if (deliverableAreas.length > 0 && Object.keys(userInfo).length > 0) {
      for (let polygon of deliverableAreas) {
        const polygonCoords = polygon?.map((coordinate) => [coordinate?.lat, coordinate?.lng]);
        const isPointInsidePolygon = userInfo?.userAddressLatLong?.lat && userInfo?.userAddressLatLong?.lng && isPointInPolygon(
          userInfo?.userAddressLatLong?.lat,
          userInfo?.userAddressLatLong?.lng,
          polygonCoords,
        );

        if (isPointInsidePolygon) {
          return true;
        }
      }
    }
    if (provider?.deliveryCoverage?.includes(userInfo?.zipcode)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (provider && userInfo) {
      const status = checkIfDeliveryAvailable();
      setIsDeliveryAvailable(status);
    }
  }, [provider, userInfo])

  useEffect(() => {
    if (user) {
      if (!isDeliveryAvailable && isDesktop) {
        setShowBummer(true);
        if (numBummer >= 2) {
          setShowBummer(false);
          setShowArea(true);
        }
      }else{
        setShowBummer(false);
      }
    }

  }, [isDeliveryAvailable]);

  const handleAddToCart = () => {
    setOpen(false);
    if(isMobile && !isDeliveryAvailable && user){
      setShowBummer(true);
      return;
    }
    if (!mealInCart) {
      addToCart({...mealDetails, qty: count});
    } else {
      updateMealQty(mealDetails, count);
    }
    setMealInfo({});
  };
  if(isKitchenNotFound && !isFetchingKitchenDetails && isDesktop){
    return (
      <FavoriteKitchenWrapper>
        <div className='kitchen__details__bummer'>
        <Bummer 
          showCross={false}
          showButtons={false}
          title={`Kitchen not found 😞`}
          subTitle={`Uh-oh! It seems like the URL you entered couldn't locate a kitchen. Please ensure you're on the right kitchen URL`}
          className="bummer__style"
          removeBodyScroll={false}
        />
        </div>
      </FavoriteKitchenWrapper>
    )
  }
  
  if (isFetchingKitchenDetails || (!isDesktop && !(provider && Object.keys(provider).length)) || (provider?.username !== storeUrl)) {
    return <div style={{ backgroundColor: '#fff', width: '100vw', height: 'calc(100vh - 76px)' }}><Progress /></div>
  }

  return isMobile ? (
    <>
    <motion.div
      initial= {{ opacity: history?.location?.state?.fromCart ? 1 : 0, x: history?.location?.state?.fromCart ? 0 : window.innerWidth }}
      exit= {{ opacity: 0, x: toCartPage ? 0 : window.innerWidth }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.7, type: 'spring' }}
      onAnimationComplete={() => setAnimationComplete(true)}
      style={{ position: 'absolute', inset: 0, zIndex: 112 }}
    >
      <FavoriteKitchenWrapper style={{ minHeight: window.innerHeight }}>
      <Header favoriteKitchenData={kitchenData} provider={provider} />
        <div ref={descriptionRef}>
          <Description provider={provider} showBummer={showBummer} isDeliveryAvailable={isDeliveryAvailable} setShowBummer={setShowBummer} showArea={showArea} setShowArea={setShowArea}/>
        </div>
        {scrollPosition > 100 && (
          <MobileHeader
            className={classNames('header-fixed', {
              headerSticky: scrollPosition > descriptionRef.current?.offsetTop + 50,
            })}
            text={provider?.name}
            onClickLeft={() => {
              history.push('/shop/kitchen-home',{fromStore:true});
            }}
          />
        )}
        {scrollPosition > descriptionRef.current?.offsetTop + 100 && (
          <div
            className={classNames('categories-container', {
              categoriesSticky: scrollPosition > categoriesTabsRef.current?.offsetTop - 20,
            })}
          >
            <Tab
              type="horizontal"
              navItems={categoriesItems}
              selectedTabId={selectedTabId}
              setSelectedTabId={setSelectedTabId}
            />
          </div>
        )}
        <motion.div
          initial= {{ opacity: 0}}
          animate={{ opacity: 1}}
          transition={{
            duration: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >
        <section className="categories-tabs-wrapper" ref={categoriesTabsRef}>
          {isFetchingKitchenMeals ? <KitchenDetailsSkeleton provider={provider} /> : <>
          {!!categoriesHaveMeals.length && (
            <div className="categories-title">Browse by category</div>
          )}
          <div className="categories-static">
            {categoriesHaveMeals.map((category) => (
              <div
                key={category?.id}
                className="category-container"
                onClick={() => setSelectedTabId(category?.id)}
              >
                <NavHashLink smooth to={`#${category?.name}`} scroll={(el) => scrollWithOffset(el)}>
                  {category?.name.toLowerCase()}
                </NavHashLink>
              </div>
            ))}
          </div>
          </>
          }
        </section>

        {isFetchingKitchenMeals ? <> </> :
        <>
        <section className="categories-dishes-wrapper">
          {categoriesHaveMeals.map((category, index) => (
            <div
              key={category?.id}
              className="category-wrapper"
              id={category?.name}
              ref={(el) => (itemsRef.current[index] = el)}
            >
              <div className="dishes-title">{category?.name.toLowerCase()}</div>
              <div className="dishes-container">
                {favoriteKitchenMeals?.filter((meal) => meal?.category?.id === category?.id)
                .sort((a, b) => a.orderIndex - b.orderIndex)
                  ?.map((meal) =>
                    meal?.category?.id === category?.id && (
                      <KitchenMealCard
                        key={meal?.id}
                        meal={meal}
                        setMealDetails={setMealDetails}
                        setOpen={setOpen}
                        isDeliveryAvailable={isDeliveryAvailable}
                      />
                    ),
                )}
              </div>
            </div>
          ))}
        </section>
        <section
          className={classNames(
            'meals-no-categories-wrapper',
            { displayCartBtn: mealPlan?.length },
            { noCategories: categoriesHaveMeals?.length < 1 },
          )}
        >
          {!!categoriesHaveMeals.length && hasMealsWithoutCategory && (
            <div
              className={classNames('dishes-title', {
                mealsTitle: favoriteKitchenMeals?.map((meal) => !meal?.category?.id),
              })}
            >
              Meals
            </div>
          )}
          <div className="dishes-container">
            {favoriteKitchenMeals?.map(
              (meal) =>
                !meal?.category?.id && (
                  <KitchenMealCard
                    key={meal?.id}
                    meal={meal}
                    setMealDetails={setMealDetails}
                    setOpen={setOpen}
                    isDeliveryAvailable={isDeliveryAvailable}
                  />
                ),
            )}
          </div>
        </section>
        </>
        }
        <section
          className={classNames('favorite-kitchen-btn-wrapper', {
            cartHasMeals: mealPlan?.length && favoriteKitchenMeals,
          })}
        >
          <button onClick={() => {
            setToCartPage(true);
            if(user){
              history.push('/shop/cartScreen', { fromMealListing: true })
            } else {
              history.push('/self-onboarding/signin');
              setKitchenToSession(storeUrl, history);
              setRouteBeforeSignup('/shop/cartScreen');
            }
          }} className="favorite-kitchen-btn">
            View cart ({mealPlan.length}) – ${generateMealsPrice().toFixed(2)}
          </button>
        </section>
        </motion.div>
        {/* {(!favoriteKitchenMeals.length && !error) &&
          <div className='meals-skeleton-wrapper'>
          {[...Array(10)].map(() => (
            <div className='meal-skeleton-container'>
              <Skeleton variant="rectangular" width={'100%'} height={192} className='meal-img-skeleton' />
              <div className='meal-info-skeleton'>
                <Skeleton variant="rectangular" className='title-skeleton' width={'90%'} height={18} />
                <div className='cart-skeleton'>
                  <Skeleton variant="rectangular" className='price-skeleton' width={100} height={20} />
                  <Skeleton variant="circular" className='add-meals-skeleton' width={32} height={32} />
                </div>
              </div>
            </div>
        ))}
        </div>
        } */}
      </FavoriteKitchenWrapper>
    </motion.div>
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        scrollLocking={false}
        expandOnContentDrag={true}
        blocking={true}
        snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
        className="kitchenMealDetails-bottomSheet"
        header={
          beforeCheckoutSubmitShown ? (
            false
          ) : (
            <div className="close-sticky">
              <NavHashLink to={`/store/${provider?.username}#${mealInfo?.id}`} scroll={(el) => scrollWithOffset(el)}>
                <img src="/icons/close-black.svg" alt="Close Icon" onClick={closeMealDetails} />
              </NavHashLink>
            </div>
          )
        }
        footer={
          <div className="add-cart-wrapper">
            <QuantityButton
              count={count}
              variant="default"
              onClickRemove={() => {
                if (count === 1) return;
                setCount(count - 1);
              }}
              onClickAdd={() =>{
                setCount(count + 1);
              }}
              className="meal-count"
              orderLimit={mealDetails?.orderLimit}
            />
            <NavHashLink to={`/store/${provider?.username}#${mealInfo?.id}`} scroll={(el) => scrollWithOffset(el)}>
              <Button title="Add to cart" type="dark" isDisabled={initialQty < 1} onClick={handleAddToCart} />
            </NavHashLink>
          </div>
        }
      >
        <KitchenMealDetails
          meal={mealDetails}
          isMealGenerating={isMealGenerating}
          mealInfo={mealInfo}
          setMealInfo={setMealInfo}
          open={open}
          setOpen={setOpen}
          beforeCheckoutSubmitRef={beforeCheckoutSubmitRef}
          countMeal={count}
        />
      </BottomSheet>
    </>
  ) : (
    <KitchenMealsDesktop isFetchingKitchenMeals={isFetchingKitchenMeals} categoriesHaveMeals={categoriesHaveMeals} storeUrl={storeUrl}/>
  );
}
