import styled from 'styled-components';
import {ReactComponent as DownloadQr} from '../../assets/nutritt-qr.svg';
import { useEffect, useState } from 'react';
import 'animate.css';
import { Skeleton } from '@mui/material';

const GetAppStyled = styled.section`
    position: fixed;
    inset: 0;
    background: rgba(81, 99, 93, 0.6);
    width: 100vw;
    hieght: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    --animate-duration: 0.3s;
    align-items: center;
    .mask{
        width: 100%;
        hieght: 100%;
        position: absolute;
        inset: 0;
    }
    .qr_holder{
        max-width: 508px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.14);
        border-radius: 15px;
        padding: 14px;
        text-align: center;
        position: relative;
        position: relative;
        z-index: 1;
        .logo{
            width: 124px;
            margin: 28px 0;
        }
        .qr{
            background: #E0E4E8;
            box-shadow: inset 3px 10px 15px rgba(0, 0, 0, 0.04);
            border-radius: 11px;
            padding: 76px 80px 24px;
            .text{
                margin-top: 28px;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #51635D;
            }
            .img{
                width: 272px;
                height: 272px;
            }
        }
        .cross{
            position: absolute;
            top: 14px;
            left: 14px;
            width: 24px;
            cursor: pointer;
        }
    }

    @media (max-width: 1300px){
        .qr_holder{
            max-width: 380px;
            .qr{
                padding: 50px 40px 20px;
                height: 422px;
            }
            .logo{
                margin-top: 20px;
                margin-bottom: 30px;
                width: 100px;
            }
        }
    }

    @media (max-width: 576px){
        .qr_holder{
            max-width: 300px;
            border-radius: 10px;
            .logo{
                width: 80px;
                margin-top: 14px;
                margin-bottom: 24px;
            }
            .qr{
                padding: 40px 36px 20px;
                border-radius: 10px;
                .text{
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 14px;
                }
            }
            .cross{
                width: 20px;
            }
        }
    }
`

const GetApp = ({setIsQrOpen}) => {
    const [showAnim, setShowAnim] = useState(true);
    const [isImgLoaded, setIsImgLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsImgLoaded(true);
      };
    
    useEffect(() => {
        const preventBodyScroll = event => {
              event.preventDefault();
        };

        document.body.addEventListener('touchmove', preventBodyScroll, { passive: false });
        document.body.addEventListener('mousewheel', preventBodyScroll, { passive: false });

        return () => {
        document.body.removeEventListener('touchmove', preventBodyScroll, { passive: false });
        document.body.removeEventListener('mousewheel', preventBodyScroll, { passive: false });
        };
    }, [])

    const handleBack = () => {
        setShowAnim(false);
        setTimeout(() => {
            setIsQrOpen(false);
        }, 300)
    }

    return (
        <GetAppStyled className={`animate__animated ${showAnim ? 'animate__fadeIn' : 'animate__fadeOut'} `}>
            <div className='mask' onClick={handleBack}></div>
            <div className='qr_holder'>
                <img src="/icons/close-cross-black.svg" onClick={handleBack} className='cross'/>
                <img src="/images/nutritt-black.svg" className='logo' alt="Logo" />

                <div className='qr'>
                {!isImgLoaded && (
                    <Skeleton
                    variant='rectangular'
                    style={{ position: 'absolute', marginTop: "10px", marginLeft: "30px", zIndex: 9, width: '272px', height: '272px' }}
                    />
                )}
                <img
                src={"/images/Qr-code.png"}
                alt="Meal Img"
                className='img'
                onLoad={handleImageLoad}
            />
                    <p className='text'>Get app now <br/> by scanning the QR code </p>
                </div>
            </div>
        </GetAppStyled>
    )
}

export default GetApp