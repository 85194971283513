export default (id, email, role, full_name) =>
  // in your authentication promise handler or callback
  pendo.initialize({
    visitor: {
      id: process.env.NODE_ENV == 'production' ? id : process.env.NODE_ENV + '-' + id, // Required if user is logged in
      email, // Recommended if using Pendo Feedback, or NPS Email
      full_name, // Recommended if using Pendo Feedback
      role, // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
