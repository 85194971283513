import { memo } from 'react';
import Pie from './index';

import { PieLegendWrapper } from './style';

function PieLegend({
  width,
  height,
  titleNumber,
  titleNumberSize,
  titleText,
  titleTextWeight,
  titleGap,
  data,
  OuterRadiusHover,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  innerRadius,
  colors,
  padAngle,
  legendLabelWidth, // number
}) {
  return (
    <PieLegendWrapper className='pie_legend_wrapper'>
      <Pie
        width={width}
        height={height}
        titleNumber={titleNumber}
        titleNumberSize={titleNumberSize}
        titleText={titleText}
        titleTextWeight={titleTextWeight}
        titleGap={titleGap}
        data={data}
        OuterRadiusHover={OuterRadiusHover}
        marginTop={marginTop}
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        innerRadius={innerRadius}
        colors={colors}
        padAngle={padAngle}
      />
      <div className="legend-wrapper">
      {data?.filter(item => item.id !== "Other" && item.id !== 'No nutrition').map(item => (
          <div key={item?.id} className="legend-container">
            <div className="legend-symbol" style={{ backgroundColor: item?.color }} />
            <div className="legend-label" style={{ width: `${legendLabelWidth}px` }}>
              {item?.label}:
            </div>
            <div className="legend-size">
            {item?.gram} <span className="legend-value">({item?.macroPercent > 0 ? item?.macroPercent : 0}%)</span>
            </div>
          </div>
        ))}
      </div>
    </PieLegendWrapper>
  );
}

export default memo(PieLegend);

