import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { profile } from './PlanningData';
import Tags from 'components/shop/MealTags';

const MealTagsWrapper = styled.div`
  .tags-listing {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    
    .meal-card-tags {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      height: 200px;
      overflow-y: clip; 
    }  
    .icon-wrapper {
      width: 24px;
      height: 24px;

      svg {
        width: 14px;
      }
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0 !important;
      padding: 0 !important;
      border-top: 0 !important;
      grid-template-columns: unset;

    }
    .tag-container .desktopView {
      grid-template-columns: auto;
    }
    .iconList45{
      .MuiTooltip-popper {
        top: -9px !important;
      }
    }
  }
`;

export default function MealTags(meal) {
  const {
    meal: { restrictions, tags },
  } = meal;

  const combinedMealTag=[...restrictions , ...tags]

  const { dietTemplate } = profile;

  const [notRecommendedRestrictions, setNotRecommendedRestrictions] = useState([]);
  const [recommendedTags, setRecommendedTags] = useState([]);

  function getNotRecommendedRestrictions() {
    let notRecommendedMeal = [];

    return new Promise((resolve) => {
      restrictions?.length &&
        dietTemplate?.food_to_avoid?.map((food) => {
          restrictions.forEach((restriction) => {
            if (restriction.toLowerCase() === food.toLowerCase()) {
              !notRecommendedRestrictions.includes(food) && notRecommendedMeal.push(food);
            }
          });
        });
      setNotRecommendedRestrictions(notRecommendedMeal);
      resolve(true);
    });
  }

  const clearString = (string) => string.replace(/-|\s/g, '');

  function getRecommendedTags() {
    let recommendedMeal = [];

    tags?.length &&
      dietTemplate.tags.forEach((dietTag) => {
        let clearDietTag = clearString(dietTag);
        tags.forEach((tag) => {
          let clearTag = clearString(tag);
          if (clearTag.toLowerCase() === clearDietTag.toLowerCase()) {
            !recommendedTags.includes(tag) && recommendedMeal.push(tag);
          }
        });
      });
    setRecommendedTags(recommendedMeal);
  }

  useEffect(async () => {
    await getNotRecommendedRestrictions();

    if (notRecommendedRestrictions?.length !== 0) {
      return;
    }

    getRecommendedTags();
  }, []);

  return (
    <MealTagsWrapper>
                <div className="meal-card-tags" style={{display:"flex"}}>
            <Tags
              tags={combinedMealTag}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
            />
          </div>
    </MealTagsWrapper>
  );
}
