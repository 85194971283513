import styled from 'styled-components';
import Title from './components/Title';
import Button from './components/Button';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useOnboardingContext } from './index';
import PageTransition from './components/PageTransition';

const UserGenderStyle = styled.div`
    padding: 48px 32px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: overlay;
    height: 100%;
    .flex{
        flex: 1;
    }
    .goals_wrapper{
        max-width: 220px;
        margin: auto;
        margin-top: 115px;
        .goal_card{
            margin-bottom: 10px;
            .radio_control{
                display: none;
                &:checked + label{
                    background: #F0FAEB;
                    border: 3px solid #CBEDBA;
                }
            }
            label{
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
                background: #FFFFFF;
                border: 1px solid #E0E3E0;
                border-radius: 30px;
                height: 60px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }
`

export default function UserGender() {
    const history = useHistory();
    const [gender, setGender] = useState('');
    const { onboardingUser, setOnboardingUser } = useOnboardingContext();
    const handleBack = () => {
        history.push('/self-onboarding/userAge');
    }

    const handleChange = (e) => {
        setGender(e?.target?.value);
        setOnboardingUser((prev) => ({
            ...prev,
            gender: e?.target?.value
        }))
    }

    const handleContinue = () => {
        onboardingUser?.selfMealPlan?.healthGoal === "maintain-weight" ? history.push('/self-onboarding/active-status') : history.push('/self-onboarding/ideal-weight')
    }

    useEffect(() => {
        setGender(onboardingUser?.gender);
    }, [])

    return (
        <PageTransition>
            <UserGenderStyle>
                <div className='flex'>
                    <Title title="What is your sex?" subtitle={"Your sex impacts your metabolic rate and nutritional needs"} />

                    <div className='goals_wrapper'>
                        <div className='goal_card'>
                            <input type="radio" value={'m'} checked={gender === 'm'} onChange={handleChange} className='radio_control' name='health_goal' id="health_goal1" />
                            <label for="health_goal1">Male</label>
                        </div>

                        <div className='goal_card'>
                            <input type="radio" value={'f'} checked={gender === 'f'} onChange={handleChange} className='radio_control' name='health_goal' id="health_goal2" />
                            <label for="health_goal2">Female</label>
                        </div>
                    </div>
                </div>
                <Button onBackClick={handleBack} onContinueClick={handleContinue} disabled={!gender} />
            </UserGenderStyle>
        </PageTransition>
    )
}