import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { useAppContext } from 'context/appContext';

const EditWrapper = styled.div`
  height: 19px;
  color: #52c41a;
  font-size: 14px;
  cursor: pointer;

  a {
    color: #52c41a;
  }

  @media (max-width: 991px) {
    a {
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 5px;
    }
  }
`;

export default function Edit({ link }) {
  const { isMobile } = useAppContext();

  return (
    <EditWrapper>
      <HashLink smooth to={link}>
        {isMobile && <img src="/icons/edit.svg" alt="Edit Icon" />}
        Edit
      </HashLink>
    </EditWrapper>
  );
}

