import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenHomeWrapper = styled.div`
  background-color: ${() => theme.colors.secondary};
  overflow: auto;
  height: calc(100vh - 118px);
  .favorite-kitchen-card {
    margin: 16px;
  }
  .bummer__style{
    z-index: 112;
  }
`;

export const ProgressWrapper = styled.div`
position: relative;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 5;
display: flex;
justify-content: center;
align-items: center;
`;

