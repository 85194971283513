import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const FavoriteKitchenWrapper = styled.div`
  position: relative;
  overflow: auto;
  background-color: ${() => theme.colors.secondary};
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  scroll-behavior: smooth;
  transition: all .5s ease-in-out;
  .kitchen__details__bummer{
    width: 100vw;
    height: calc(100vh - 76px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    .image-container{
      margin-top: 0;
    }
  }
  .bummer__style{
    position: relative;
    z-index: 2;
  }
  /* .header-icons {
    margin-top:25px
  } */
  .categories-container {
    position: fixed;
    top: -48px;
    left: 0;
    right: 0;
    z-index: 3;
    padding-left: 16px;
    background-color: ${() => theme.colors.secondary};
    border-bottom: 1px solid ${() => theme.colors.grayLight};
    transition: all 0.5s ease-in-out;
    &.categoriesSticky {
      top: 46px;
      transition: all 0.5s ease-in-out;
    }
    &.endOfCategorySticky {
      top: -48px;
      transition: all 0.5s ease-in-out;
    }
    .tab_container {
      overflow: auto;
      padding-right: 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }
      .nav_item {
        height: 42px;
        padding: 0;
        border-radius: 0;
        border-bottom: 2px solid ${() => theme.colors.secondary} !important;
        transition: all .3s ease-in-out;
        text {
          display: flex;
        }
        &.active {
          color: ${() => theme.colors.primaryDarker};
          border-color: ${() => theme.colors.primaryDarker} !important;
          a {
            font-weight: 600;
          }
        }
        a {
          height: 40px;
          padding: 0 12px;
          background-color: ${() => theme.colors.secondary};
          color: ${() => theme.colors.primaryDefault};
          border: 0;
          font-family: ${() => theme.typography.fontGilroy};
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }
        .category-wrapper {
          width: max-content;
        }
      }
    }
  }

  .categories-tabs-wrapper {
    background-color: ${() => theme.colors.secondary};
    color: ${() => theme.colors.deepForestGreen};
    font-family: ${() => theme.typography.fontGilroy};
    transition: all 0.3s ease-in-out;
    .categories-title {
      margin-bottom: 8px;
      padding-left: 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    .categories-static {
      overflow: auto;
      width: 100%;
      padding: 0 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }
      .category-container {
        width: max-content;
        height: 40px;
        display: flex;
        flex-shrink: 0;
        a {
          height: 40px;
          padding: 0 12px;
          background-color: ${() => theme.colors.secondary};
          color: ${() => theme.colors.deepForestGreen};
          border: 1px solid ${() => theme.colors.grayLight};
          border-radius: 8px;
          font-family: ${() => theme.typography.fontGilroy};
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }
      }
    }
  }

  .categories-dishes-wrapper,
  .meals-no-categories-wrapper {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    .category-wrapper {
      padding-top: 36px;
      &:first-child {
        padding-top: 17px;
      }
    }
    .dishes-title {
      margin-bottom: 8px;
      color: ${() => theme.colors.primaryDarker};
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-transform: capitalize;
    }
    .dishes-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .header-fixed {
    position: fixed;
    top: -48px;
    left: 0;
    z-index: 4;
    background-color: ${() => theme.colors.secondary};
    &.headerSticky {
      top: 0;
      transition: all 0.5s ease-in-out;
    }
    .header-text {
      text-transform: capitalize;
    }
  }

  .meals-no-categories-wrapper {
    margin-bottom: 33px;
    &.displayCartBtn {
      margin-bottom: 91px;
    }
    .dishes-title {
      padding-top: 36px;
      display: none;
      &.mealsTitle {
        display: flex;
      }
    }
    &.noCategories {
      padding-top: 24px;
    }
  }

  .favorite-kitchen-btn-wrapper {
    position: fixed;
    bottom: -100px;
    left: 0;
    right: 0;
    z-index: 3;
    height: 74px;
    background-color: #ffffffe6;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    &.cartHasMeals {
      bottom: 0;
    }
    .favorite-kitchen-btn {
      width: 271px;
      height: 42px;
      background-color: ${() => theme.colors.primaryDefault};
      color: ${() => theme.colors.secondary};
      font-family: ${() => theme.typography.fontGilroy};
      font-size: 16px;
      font-weight: 700;
      border-radius: 30px;
    }
  }

  .meals-skeleton-wrapper {
    margin-top: -91px;
  }

  .meal-skeleton-container {
    margin-bottom: 12px;
    padding: 0 16px;
    .meal-img-skeleton {
      border-radius: 12px 12px 0 0;
    }
    .meal-info-skeleton {
      padding: 10px 16px 16px;
      .cart-skeleton {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .add-meals-skeleton {
        border-radius: 50%;
      }
    }
  }
`;
