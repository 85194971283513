import MealNutritions from './MealNutritions';
import ImageViewer from '../../components/ImageViewer';

export default function CartLine({ cartItem, StyledRow }) {
  // console.log(`cartItem`, cartItem);
  const { providerId, components, isCombo, price, qty, description, img } = cartItem;

  const Images = () =>
    !isCombo ? (
      <img width="80" src={img} alt={name} />
    ) : (
      <div
        style={{
          width: '100px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          justifyItems: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {components
          .filter((component) => component.comboComponent)
          .map((component) => (
            <ImageViewer 
              style={{ justifySelf: 'center', width: '100%', margin: 0 }}
              key={component.componentId}
              src={component.img || '/img_coming_soon.png'}
              alt={component.selectionName}
              fallbackSrc={'/img_coming_soon.png'}
            />
          ))}
      </div>
    );

  const name = !isCombo
    ? cartItem.name
    : components
        .filter((component) => component.comboComponent)
        .map(({ componentId, comboComponent, selectionName }) => (
          <div key={componentId}>
            {comboComponent.name} - {selectionName}
          </div>
        ));

  return (
    <div style={{ marginBottom: 20 }}>
      <StyledRow>
        <Images />
        <div>
          {name} <br />
          {description}
        </div>
        <div>{qty}</div>
        <MealNutritions nutritions={cartItem} />
        <div style={{ justifySelf: 'end', marginRight: 10 }}>
          {(price / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
      </StyledRow>
    </div>
  );
}
