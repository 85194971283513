import { useState, useEffect } from 'react';
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import { StyledForm } from 'components/auth/StyledLoginForm';
import { Link } from 'react-router-dom';
import { onChangeEmail, onBlurEmail } from './utils';
import { useAppContext } from 'context/appContext';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';

export default function RegisterFormNutritionist({
  register,
  isLoading,
  error,
  setError,
  nutritionistRegister,
}) {
  const { afterLogin } = useAppContext();
  let history = useHistory();
  const { nutritionistEmail, token, nutritionistId } = nutritionistRegister || {};
  const [name, setName] = useState({ value: '', error: '' });
  const [email, setEmail] = useState({
    value: nutritionistEmail || '',
    error: '',
  });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [agree, setAgree] = useState({ value: false, error: '' });
  const [getNutritionistName] = useRequest(`nutritionist/name/${nutritionistId}`, 'post');

  useEffect(async () => {
    if (nutritionistId) {
      const response = await getNutritionistName({
        body: { email: nutritionistEmail, nutritionistId, token },
      });
      await setName({ value: response.name });
    }
  }, []);

  const isFormValid = () => {
    setError(null);
    const nameValue = name.value;
    const emailValue = email.value;
    const passwordValue = password.value;
    const checkedValue = agree.value;

    if (!nameValue ||  !nutritionistEmail || !nameValue || !emailValue || !passwordValue || !checkedValue) {
      if (!nameValue) setName({ error: 'Name is required' });
      if (!emailValue) setEmail({ error: 'Email is required' });
      if (!passwordValue) setPassword({ error: 'Password is required' });
      if (!checkedValue) setAgree({ value: checkedValue, error: 'Agreement is required' });

      //Return if any errors
      setError('Some fields are empty or invalid');
      console.log('set errors');
      return false;
    } else {
      return true;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const res = await register({
      body: {
        name: name.value,
        email: email.value,
        password: password.value,
        agreeToTerms: agree.value,
        invitecode: token,
        nutritionistId: nutritionistId ? nutritionistId : '',
      },
    });
    history.push('/nutritionist/home');
    await afterLogin(res);
  };
  return (
    <StyledForm className='styled-form' onSubmit={handleRegister} autocomplete={true} noValidate>
      <div className="heading">Let’s get started</div>
      <div className="subheading">
        Create an account to start your {!nutritionistRegister && 'healthy '}
        journey as Nutritionist with Nuttrit
      </div>
      <Input
        label="Name"
        name="name"
        value={name.value}
        onChange={(e) => setName({ value: e.target.value })}
        bordered
        required
        // autoFocus
        error={name.error}
        disabled={nutritionistId}
        onBlur={(e) => {
          const { value } = e.target;
          if (!value) setName({ value, error: `Name is required` });
        }}
      />
      <Input
        label="Email"
        type="email"
        name="email"
        value={email.value}
        onChange={(e) => onChangeEmail(e.target.value, setEmail)}
        bordered
        required
        error={email.error}
        onBlur={(e) => onBlurEmail(e.target.value, setEmail)}
        disabled={nutritionistEmail}
      />
      <Input
        label="Password"
        type="password"
        name="password"
        id="new-password"
        value={password.value}
        onChange={(e) => setPassword({ value: e.target.value })}
        bordered
        required
        autoComplete="new-password"
        error={password.error}
        onBlur={(e) => {
          const { value } = e.target;
          if (!value) setPassword({ value, error: `Password is required` });
        }}
      />
      <Input
        type="checkbox"
        id="agree"
        name="agree"
        checked={agree.value}
        onChange={(e) => setAgree({ value: e.target.checked })}
        label={<Link to='/nutritionist/terms' target='_blank'>I agree with terms & conditions</Link>}
        required
        error={agree.error}
      />
      <SubmitButton disabled={isLoading || !name.value || !password.value || !agree.value } style={{ marginTop: '10px' }} type="submit" error={error}>
        {isLoading ? 'Signing you up...' : 'Sign up'}
      </SubmitButton>
      <div className="already">
        Already have an account? <Link to="/login">Log in</Link>
      </div>
    </StyledForm>
  );
}

