import { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'components/Select';
import { useAppContext } from 'context/appContext';
import {validInNextNDays} from 'pages/shop/providerTimes';
import { useShopContext } from 'context/shopContext';
import {DeliveryDatesWrapper} from './style';

export default function DeliveryDates({provider}) {
  const [scheduleDates, setScheduleDates] = useState([]);
  const {profile} = useAppContext();
  const {availableProviders} = useShopContext();
  const getCurrentProvider = availableProviders?.data?.find((prov) => prov?.BusinessProfile[0]?.id === profile?.favoriteKitchenData?.businessProfileId);

  const generateScheduleDates = () => {
    const availableDates = validInNextNDays(14, provider?.schedule, 'delivery').reduce((acc, date) => {
      const formattedDate = moment(date).format('ddd, ll');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(date);
      return acc;
    }, {});

    const uniqueDates = new Set();
    for (let key in availableDates) {
      const formattedDate = moment(key).format('ddd, MMM D');
      const item = `${formattedDate}`;
      uniqueDates.add(item);
    }

    return setScheduleDates(Array.from(uniqueDates));
  };

  const getDefaultChecked = () => {
    if (localStorage.getItem('providerDeliveryDate')) {
      const providerDeliveryDates = JSON.parse(localStorage.getItem('providerDeliveryDate'));
      const getDateIndex = scheduleDates.findIndex((date) => {
        const dateSplit = date?.split(",");
        const deliveryDate = moment(dateSplit?.[dateSplit?.length-1], 'MMM DD, dddd').valueOf();
        return deliveryDate == providerDeliveryDates[provider?.id || getCurrentProvider?.id];
      });
      return getDateIndex;
    }
  }

  useEffect(() => {
    generateScheduleDates();
  }, [provider || getCurrentProvider])

  return (
    <DeliveryDatesWrapper>
      {(scheduleDates && scheduleDates?.length) ?
      <Select
        label="Delivery by:"
        icon="angle-bottom-black"
        defaultChecked={scheduleDates[getDefaultChecked() || 0]}
        options={scheduleDates}
        updateValue={(date) => {
          if (date) {
            const dateSplit = date?.split(",");
            const deliveryDate = moment(dateSplit?.[dateSplit?.length-1], 'MMM DD, dddd').valueOf();
            const providerDeliveryDate = JSON.parse(localStorage.getItem('providerDeliveryDate'));
            localStorage.setItem('providerDeliveryDate', JSON.stringify({...providerDeliveryDate, [provider?.id || getCurrentProvider?.id]: deliveryDate}))
          }
        }}
      />
       : ""
      }
    </DeliveryDatesWrapper>
  );
}