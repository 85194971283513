import { useAppContext } from 'context/appContext';
import styled from 'styled-components';
 import SoicalIcons from './SocialIcons';
import { FaRegCopy } from 'react-icons/fa';
import { useState, useRef, useEffect} from 'react';

const CoachProfileCardStyle = styled.div`
  padding: 27.7px 0 16px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px #0000000d;
  display: grid;
  grid-template-columns: 39% 1% 60%;
  position: absolute;
  width: 70%;
  height: auto;
  top: 75px;

  .profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
  }
  .hr{
    hr{
      height:360px;
      display:inline-block;
      background:#D9D9D9;
      border:1px solid #D9D9D9;
    }
  }
  .details{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left:45px;

    .share{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      margin-top: -10px;
      color: rgb(82, 196, 26);
      align-items:center;
      svg{
         cursor: pointer;
         font-size: 22px;
      }

      .copied-text {
        color:#949D9B;
        font-size:16px;
        margin-right:5px;
      }
       
    }
    .about{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #51635D;
      
    }
    .experience, .info{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #949D9B;
    }
    .info{
      padding-right: 30px;
      text-align: justify;
    }
    .experience{
      margin-top:18px;
      display:flex;
      gap:5px;
    }
    .info-text{
       height:auto;
    }
  } 
    .specialization{
      ont-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #51635D;
    }
    .specialization , .info{
      margin-top:20px;
    }
    .tag-container{
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-top: 20px;
    .tags-wrapper{
      display:flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: 0 10px;
      border: 1px solid #E0E3E0;
      border-radius: 16px;
      .tags{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #949D9B;
        
      }
    }
   }
  
  
  .coach-photo {
    margin-bottom: 11px;
    width: 200px;
    height:200px;
    left: 141.7px;
    top: 74px;
    border: 1px solid #E0E3E0;
    box-sizing: border-box;
    border-radius: 125px;
    padding: 2px;
    object-fit: cover;
    margin-bottom:26px;
  }
    .registered-title {
      text-align: center;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin-bottom:6px;
      }

      span {
        color: #949d9b;
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .social-image-start,
    .social-image-end {
      cursor: pointer;
      margin-top: 27px;
      display: flex;
      justify-content: center;
      width:100%;
      gap:10px;

    }

    @media (min-width: 992px) {
      position: relative;
      top: 35px;
    }


@media(max-width:991px){
  width:100% !important;
  display: flex !important;
  flex-direction: column;
  position:relative !important;
  top:0 !important;
  .coach-photo{
    width:70px !important;
    height:70px !important;
  }
  .registered-title p{
    font-size: 16px !important;
    line-height: 19px !important;
  }
  .share{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin-top: -10px;
    color: rgb(82, 196, 26);
    align-items:center;
    svg{
       cursor: pointer;
       font-size: 22px;
    }

    .copied-text {
      color:#949D9B;
      font-size:16px;
      margin-right:5px;
    }
     
  }
  .detail-div{
    padding:0 14px 0;
    margin-top:15px;
   .detail{
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-top:1px solid #E0E3E0;
    padding-top:11px;
    .about{
      padding-left:7px;
    }
    .social-icon{
      display:flex;
      gap:14px;
    }
   }
  }
 .show-intro{
   padding: 0 21px 0;
   margin-top: 18px;
   .info{
    margin-top:0 !important;
    .info-text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px; 
      line-height: 20px;
      color: #949D9B;
      text-align: justify; 
    }
   }
  }
  .specialization{
    font-size: 14px !important;
    line-height: 16px !important;
  }
 }


@media(min-width:992px) and (max-width:1290px){
  width:85% !important;
}
@media(min-width:1290px) and (max-width:1550px){
  width:75% !important;
}
@media(min-width:1920px) and (max-width:2550px){
    width:60% !important;
}
@media(min-width:2550px){
  width:56% !important;
}

`;


export default function CoachCard({
  nutritionist,
  coachPhoto,
  name,
  insta,
  faceBook: fb,
  tiktok : tk,
  title,
  description,
  experience,
  experience_type,
}) {
  const {isMobile} = useAppContext();
  const [showIntro, setShowIntro] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false)
  const textAreaRef = useRef(null)
  async function copyToClip() {
      await navigator.clipboard.writeText(location.href);
      setCopySuccess(true);
  }
  useEffect(() => {
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  }, [copySuccess]);

  const tiktok_ROOT = `https://www.tiktok.com/`;
  const instagram_ROOT = `https://www.instagram.com/`;
  const facebook_ROOT = `https://www.facebook.com/`;

  function verifyLink(Link, name){
    const link1 = Link.split('//');
    if(link1.length > 1){
        return Link;
    } else{
      if(Link.includes('.com')){
        var finalLink = 'https://'.concat(Link)
        return finalLink;
      } else{
        var name = name=='facebook' ? facebook_ROOT: name=='instagram'? instagram_ROOT : tiktok_ROOT;
        return name.concat(Link)
      }  
    }
  }
  var {instagram, tiktok, facebook }= nutritionist?.socialProfile || {};
  if(insta){instagram = verifyLink(instagram, 'instagram');}
  if(tk){tiktok= verifyLink(tiktok, 'tiktok');}
  if(fb){facebook= verifyLink(facebook, 'facebook');}
  return (
    <CoachProfileCardStyle>
        <div className='profile'>
        { isMobile && <div className='share'>
        {/* <img src='/icons/share.svg'/> */}
        {copySuccess && <p className="copied-text"> Url Copied</p>}
        <FaRegCopy onClick={copyToClip} />
  
      </div>}
      <img className="coach-photo" src={coachPhoto || '/images/avatar.png'} alt="Registered Dietician" />
      <div className="registered-title">
        <p>{name}</p>
        <span>{title}</span>
      </div>
     {!isMobile && <div className='social-image-start'>
       {fb && 
        <a href={facebook} target="_blank"><SoicalIcons img='/icons/1.svg'/></a>
       }  
         {insta &&
        <a href={instagram} target="_blank"><SoicalIcons img='/icons/2.svg'/></a>
       } 
        {tk &&
        <a href={tiktok} target="_blank"><SoicalIcons img='/icons/3.svg'/></a>
       } 
      </div>}
      </div>
     {!isMobile && <div className='hr'> 
      <hr />
         </div>}
     {!isMobile ? <div className='details'>
    <div className='share'>
        {/* <img src='/icons/share.svg'/> */}
        {copySuccess && <p className="copied-text">URL Copied</p>}
        <FaRegCopy onClick={copyToClip} />
  
      </div>
        <div className='about'>
       <p>About</p>
        </div>
          <div className='info'>
          {description && <p className='info-text'>{description}</p>}
          </div>
          <div className='specialization'>
     <p>{nutritionist?.specialization.length>0 ? 'Specialization' : ''}</p>
      </div>
      <div className='tag-container'>
      {nutritionist?.specialization.map((item,index)=>(    
        <div className='tags-wrapper' key={index}>
          <div className='tags'>
            {item}
          </div>
        </div>
      ))}
      </div>
      </div>

      :
       <>
      <div className='detail-div'>
      <div className='detail'>
        <div className='about'  onClick={() => setShowIntro(!showIntro)}>
        {showIntro ? (
                  <img src="/images/About-reverse.svg" alt="show less" />
                ) : (
                  <img src="/images/About-black.svg" alt="show more" />
                )}
        </div>
        <div className='social-icon'>
        {fb && 
        <a href={facebook} target="_blank"><SoicalIcons img='/images/fb-black.svg'/></a>
       }  
         {insta &&
        <a href={instagram} target="_blank"><SoicalIcons img='/images/insta-black.svg'/></a>
       } 
        {tk &&
        <a href={tiktok} target="_blank"><SoicalIcons img='/images/tiktok-black.svg'/></a>
       } 
        </div>
      </div>
      </div>

       { showIntro &&  <div className='show-intro'>
       <div className='intro-div'>
       <div className='info'>
           {description && <p className='info-text'>{description}</p>}
           </div>
           <div className='specialization'>
      <p>{nutritionist?.specialization.length>0 ? 'Specialization' : ''}</p>
       </div>
       <div className='tag-container'>
       {nutritionist?.specialization.map((item,index)=>(    
         <div className='tags-wrapper' key={index}>
           <div className='tags'>
             {item}
           </div>
         </div>
       ))}
       </div>
       </div>
       </div>
       }
       </>

  }
   
    

     
    
        </CoachProfileCardStyle>
  );
}

