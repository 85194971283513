import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenMealCardWrapper = styled.div`
  cursor: pointer;
  .cart-wrapper {
    height: 32px;
  }
  
  @media (min-width: 992px) and (max-width: 1279px) {
    width: 317px;

    .meal-img-wrapper {
      height: 178px;
    }

    .meal-details {
      padding: 24px 16px 16px;
    }
  }

  @media (min-width: 1280px) and (max-width: 1439px) {
    width: 276px;

    .meal-img-wrapper {
      height: 154px;
    }
  }

  @media (min-width: 1440px) {
    width: 343px;
    cursor: pointer;
  }
`;