import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

const ReviewWrapper = styled.div`
  position: relative;
  margin-left: 15px;
  margin-bottom: 40px;
  padding: 48px 78.19px 32px 79.24px;
  background: #FFF;
  font-family: Gilroy;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 10px 15px 0px rgba(0, 10, 44, 0.15);

  .people-info{
    margin-top: 8px;
  }

  .people-content{
    padding-top: 34px;
  }

  .people-img-wrapper {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 125px;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15) inset;
    }
  }

  p {
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
   }

  .people-name {
    color: ${theme.colors.primaryDarker};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .people-location {
    color: ${theme.colors.grayDarker};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  @media (max-width: 992px) {
    padding: 24px 40px 24px 40px;
    margin: 55px 29px 32px 8px;
    height: 446px;
    .comma-img{
      left: -11%;
      width: 159px;
      align-self: center;  
    }
  }
  @media (min-width: 991px){
    height: 400px;
  }
  @media (min-width: 768px) and (max-width: 1270px){
    height: 480px;
    padding: 48px 31px 32px 31px
  }
`;

export default function Review({ name, img, review, location }) {
  return (
    <ReviewWrapper>
      <img src="/images/stars.png" alt="Comma Image" className="comma-img" />
      <div className='people-info'>
        <p>{review}</p>
      </div>
      <div className='people-content'>
        <div className="people-name">{name}</div>
        <div className="people-location">{location}</div>
      </div>
    </ReviewWrapper>
  )
}