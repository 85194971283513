import React from 'react';

const Peanuts = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_4095_6641)">
                <path d="M15.8489 0.75264C15.3366 0.141355 14.8347 -0.0993276 14.3575 0.0370186C13.9978 0.139788 13.6906 0.357667 13.4061 0.844596C13.0251 1.49044 12.3744 1.83369 11.6851 2.19646C9.51144 3.30737 8.05477 4.92287 7.35545 6.99808C6.87056 8.39723 6.04566 9.45419 4.75801 10.3253C3.55648 11.144 2.69296 12.1985 2.19145 13.4594C1.69371 14.7109 1.57296 16.2084 1.73132 17.4578C1.93565 19.1834 3.2774 20.2274 5.35327 19.9578C8.86047 19.4993 11.4293 17.7415 12.7826 14.8734C13.6148 13.1021 14.5347 11.745 15.6773 10.6024C18.4095 7.87011 18.3948 3.59491 15.8489 0.75264ZM11.6483 14.3393C10.4031 16.9783 8.08545 18.3362 5.19127 18.7145C4.01708 18.8673 3.11951 18.5043 2.97678 17.3142C2.71866 14.8519 3.39976 12.768 5.46223 11.3626C6.91624 10.379 7.95326 9.12994 8.5428 7.40065C9.17281 5.52907 10.4903 4.22504 12.2657 3.30772C12.6595 3.10046 13.0798 2.87905 13.4719 2.58708C12.3402 4.77571 9.08881 5.48358 8.50914 10.01C8.11954 11.6622 6.39346 11.1238 4.51592 14.6075C4.35168 14.9122 4.46557 15.2925 4.77036 15.4568C5.07639 15.6216 5.45597 15.5059 5.61962 15.2023C6.53106 13.5112 7.35745 12.9417 8.08663 12.4392C8.79261 11.9528 9.46074 11.4936 9.73857 10.2583C10.3683 7.45805 11.3325 6.3357 12.5392 5.50791C13.732 4.68972 14.7331 3.55945 15.0603 1.75792C17.0412 4.13117 16.9724 7.5341 14.7907 9.71588C13.5424 10.9641 12.5437 12.4336 11.6483 14.3393Z" fill={color || "#757E89"} />
                <path d="M15.1527 5.95975C15.1145 5.61567 14.8049 5.36786 14.4605 5.40587C14.1164 5.44411 13.8685 5.75402 13.9066 6.0981C13.9415 6.41197 13.8607 6.87613 13.5185 7.15149C13.2488 7.36851 13.2061 7.76313 13.4231 8.03288C13.6398 8.30225 14.0343 8.34566 14.3045 8.12832C15.0416 7.53526 15.226 6.61927 15.1527 5.95975Z" fill={color || "#757E89"} />
                <path d="M11.8182 10.0567C11.5109 9.89709 11.1324 10.0166 10.9728 10.3238C10.9198 10.4258 10.8512 10.516 10.7689 10.5918C10.3504 10.9774 10.6251 11.6797 11.1938 11.6797C11.3457 11.6797 11.4979 11.6249 11.6184 11.5139C11.8079 11.3394 11.965 11.1335 12.0852 10.9021C12.2449 10.5949 12.1254 10.2164 11.8182 10.0567Z" fill={color || "#757E89"} />
                <path d="M8.0211 14.1176C7.68431 14.0361 7.34575 14.2427 7.26422 14.5792C7.18986 14.8861 6.95552 15.2948 6.53994 15.4367C6.21231 15.5486 6.03742 15.9049 6.14927 16.2326C6.23825 16.4931 6.48172 16.657 6.7425 16.657C7.20474 16.657 8.20329 16.0277 8.48272 14.8745C8.56421 14.538 8.35758 14.1992 8.0211 14.1176Z" fill={color || "#757E89"} />
                <path d="M18.3665 13.2398C17.9844 12.6897 17.55 12.4234 17.0793 12.4486C16.2668 12.4919 15.7876 13.3673 15.3648 14.1397C14.9436 14.9089 14.9174 14.9931 13.9877 15.5979C13.4253 15.9638 12.8438 16.342 12.8156 16.9833C12.789 17.5896 13.2969 17.9874 13.4639 18.1182C14.0714 18.594 14.8451 18.7921 15.6329 18.6985C18.0666 18.4096 19.9141 15.4677 18.3665 13.2398ZM16.9445 16.6974C15.9691 17.6791 14.7477 17.6413 14.125 17.0346C14.2527 16.9212 14.4969 16.7624 14.6713 16.6489C15.8033 15.9124 15.9481 15.6851 16.4645 14.7417C16.6235 14.4513 16.9414 13.8705 17.1355 13.7214C17.1752 13.7537 17.2437 13.821 17.3369 13.9551C17.8145 14.6428 17.8784 15.7575 16.9445 16.6974Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_4095_6641">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Peanuts;
