import styled from 'styled-components';
import {ReactComponent as CloseIcon} from '../../../assets/close-cross-green.svg';
import { useState,useEffect } from 'react';
import {ReactComponent as LikeIcon} from '../../../assets/Like.svg';
import { useAppContext } from 'context/appContext';
import { useHistory } from 'react-router-dom';
 
const ExploreAppModalStyle = styled.div`
font-family: 'Roboto';
font-style: normal;
position: fixed;
top:0;
left: 0;
right: 0;
bottom: 0;
z-index: 123;
padding:20px 22px;
background-color: #fff;
height: 100%;
overflow: auto;
&::-webkit-scrollbar {
  display:none;
}
.cross-div{
    display:flex;
    .img{
        position: relative;
        left: -2px;
        top: 0px;
    }
}
.like{
    margin-top:32px;
    text-align: center;
}
.thanks{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635D;
    margin-top:63px;
    text-align: center;
}
.notify{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #51635D;
    margin-top:20px;
    text-align: center;
}
.explore-btn{
    margin-top:48px;
    display:flex;
    justify-content:center;
    button{
        width:80.34%;
        height: 62px;
        background: #52C41A;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius: 31px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
}

@media(min-width:992px){
  background:rgba(175,181,180,0.5);
   display:flex;
   justify-content:center;
   align-items:center;
  .modal-div{
    background:#fff; 
    width:800px;
    height:576px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    @media(max-height:650px){
      height:96% !important;
    }
  }
  .cross-div{
    display: flex;
    justify-content: flex-end;
    img{
      position:relative;
      top:25.25px;
      right:25.25px;
      cursor:pointer;
    }
  }
  .like{
    margin-top:49px;
  }
  .thanks{
    margin-top:59px;
    display:flex;
    justify-content:center;
    p{
      width:429px;
    }
    @media(max-height:650px){
      margin-top:40px;
    }
  }
  .notify{
   display:flex;
   justify-content:center;
   margin-top:11px;
   P{
    width:399px;
   }
  }
  .explore-btn{
    margin-top:32px;
    button{
      width:250px;
      cursor:pointer;
       
    }
  }
}

`;

export default function ExploreAppModal({
  exploreAppModal,
  setExploreAppModal,
  setBringMealModal,
  setAddressModal,
  addressModal,
  setBringMealAnim,
  setShowbringAnim,
  setShowAnim,
  }){
    const [showexploreAnim, setShowexploreAnim] = useState(true);
    const { user, isMobile } = useAppContext();
    const userType = user?.type?.toString();
    const history = useHistory();
  
    const closeAppModal = () => {
      setShowexploreAnim(false);
      setTimeout(() => {
        setExploreAppModal(false);
      }, 500)
   }

    const Modalsclose = () => {
      setShowbringAnim(false);
      setShowexploreAnim(false);  
    setShowAnim(false);
    }

    const handleclose = () => {
      setShowbringAnim(false);
      setShowexploreAnim(false);
    }

    const handleClick = () =>{
       if(userType==='user' || userType==='guest'){
         history.push('/shop/pantry');
         window.scrollTo(0, 0);
       }
       else{
        addressModal ?
       (
        !user && history.push('/visitor/get-started'),
        Modalsclose(),
        setTimeout(() => {
          setExploreAppModal(false);
          setBringMealModal(false);
            setAddressModal(false);
           
         //   window.scrollTo(0, 0);     
          }, 500)
          )
          :
          isMobile ?
       ( !user && history.push('/visitor/get-started'), history.push('/shop/plan'),
         window.scrollTo(0, 0)
         )
         :
         (
          !user && history.push('/visitor/get-started'),
          handleclose(),
          setTimeout(() => {
            setBringMealModal(false);
            setExploreAppModal(false);
          }, 500)
          )
       }
    }
   
    return(
        <ExploreAppModalStyle className={showexploreAnim ? "fadeInMiddle" : "fadeOutMiddle"}>
        <div className='modal-div'>
        <div className='cross-div'>
       {isMobile ? <CloseIcon onClick={closeAppModal} className='img'/> : <img src='/icons/close-circle3.svg' onClick={closeAppModal}/>}
        </div>
        <div className='like'>
         <LikeIcon/>
        </div>
        <div className='thanks'>
            <p>Thanks for requesting meal prep delivery in your area!</p>
        </div>
        <div className='notify'>
            <p>We've received your address and we'll notify you as soon as we expand to your neighborhood.</p>
        </div>
        <div className='explore-btn'>
          <button onClick={handleClick}>Explore the app</button>
        </div>
        </div>
        </ExploreAppModalStyle>
    )
}
  