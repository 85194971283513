import styled from 'styled-components';
import icons from 'components/TagIcons';
// { Fish,Milk,Mustard,Peanuts,Crustaceans,Celery,TreeNuts }
const Container = styled.div`
  user-select: none;
  margin-right: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .icon-wrapper {
    width: 35px;
    height: 35px;
    margin-bottom: 3px;
    border: ${(props) => (props.isSelected ? '2px solid #39ab39' : ' 0.5px solid #e6e6e6')};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.isSelected ? '#39ab39' : '#939b99')};
  }
  .tag-name {
    font-size: 14px;
    color: ${(props) => (props.isSelected ? '#39ab39' : '#939b99')};
    font-weight: ${(props) => (props.isSelected ? '600' : 'unset')};
    text-align: center;
  }
`;
export default function Tag({ tag, selectedTags, onTagClick }) {
  const Icon = icons[tag];
  const isSelected = selectedTags?.indexOf(tag) !== -1;

  return (
    <Container key={tag} onClick={() => onTagClick(tag, isSelected)} isSelected={isSelected}>
      {/* <span className="icon-wrapper">{tagIcons[tag]}</span> */}
      <span className="icon-wrapper">
        {Icon && <Icon color={isSelected ? '#39ab39' : '#939b99'} />}
        {/* {icons[tag]({color:isSelected ? "#39ab39" : "#939b99" )}} */}
      </span>
      <span className="tag-name">{tag}</span>
    </Container>
  );
}
