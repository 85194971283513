import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'components/elements/button/Button';
import MobileHeader from 'components/elements/MobileHeader';
import { useKitchenContext } from 'context/kitchenContext';
import OrderItem from 'pages/KitchenMobile/components/OrderItem';
import RejectModal from 'pages/KitchenMobile/components/RejectModal';
import OrderDetailsStatus from 'pages/KitchenMobile/components/OrderDetailsStatus';
import { OrderDetailsWrapper } from './style';
import { useAppContext } from 'context/appContext';

export default function OrderDetails({
  selectedOrder,
  setSelectedOrder,
  status,
  setStatus,
  isRejectModal,
  setIsRejectModal,
  closeOrderDetails,
  generateRequest
}) {
  const { friendlyOrderNum, providers, orderLine, orderMainId, activeTab } = selectedOrder;
  const {
    isDelivery,
    deliveryData: { deliveryAddress, note, deliveryCity, deliveryState, deliveryZip },
    street,
    city,
    state,
    zip,
    taxAmount,
    deliveryFee,
    providerSubTotal,
    providerPayout,
    Provider,
    isOrderSeen,
    id: providerOrderId,
    repeatOrderDiscount,
    isRecurringOrder
  } = providers[0];
  const [updateOrderStatus] = useRequest();
  const { newOrdersCount, setNewOrdersCount } = useKitchenContext();
  const { isWebView, isPlatformIOS} = useAppContext();
  const recurringOrderObj = {
    id: 3,
    title: 'Repeat order discount',
    amount: repeatOrderDiscount,
  }
  const paymentBreakdown = [
    {
      id: 1,
      title: 'Tax amount',
      amount: taxAmount,
    },
    {
      id: 2,
      title: 'Subtotal',
      amount: providerSubTotal,
    },
    {
      id: 4,
      title: 'Delivery fee',
      amount: deliveryFee,
    },
  ];
  isRecurringOrder && paymentBreakdown.splice(2, 0,recurringOrderObj);

  const addressInfo = useMemo(() => {
    if (isDelivery) {
      if(deliveryAddress){
        return `${deliveryAddress}`;
      }
      return `${deliveryCity}, ${deliveryState} ${deliveryZip}`
    } else {
      return `${street}, ${city} ${state} ${zip}`;
    }
  }, [
    isDelivery,
    deliveryAddress,
    deliveryCity,
    deliveryState,
    deliveryZip,
    street,
    city,
    state,
    zip,
  ]);

  const handleOrderViewed = async (providerOrderId, providerId, isOrderSeen) => {
    try {
      if (!isOrderSeen) {
        await updateOrderStatus({
          path: `fulfill/provider-order/${providerOrderId}`,
          method: 'put',
          body: {
            providerId,
            isOrderSeen: !isOrderSeen,
          },
        });
        const updatedOrderData = { ...selectedOrder };
        updatedOrderData?.providers.map((item) => {
          if (item.id === providerOrderId) {
            item['isOrderSeen'] = !isOrderSeen;
          }
        });
        setSelectedOrder(updatedOrderData);
        const updatedOrdersCount = newOrdersCount - 1;
        setNewOrdersCount(updatedOrdersCount);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleOrderViewed(providerOrderId, Provider?.id, isOrderSeen);
  }, []);

  return (
    <OrderDetailsWrapper isWebView={isWebView} platform={isPlatformIOS() ? true : false}>
      <MobileHeader
        text={`Order: ${friendlyOrderNum}`}
        onClickLeft={closeOrderDetails}
      />
      <div className="order-details-body" style={isWebView && isPlatformIOS() ? { maxHeight: window.innerHeight - 115 } : { maxHeight: window.innerHeight - 48 }}>
        <OrderDetailsStatus selectedOrder={selectedOrder} status={status} setStatus={setStatus} />
        <section className="delivery-info-wrapper">
          <div className="order-details-title">Delivery information</div>
          <div className="delivery-section">
            <div className="label">method</div>
            <div className="info">{isDelivery ? 'Delivery' : 'Pickup'}</div>
          </div>
          <div className="delivery-section">
            <div className="label">Delivery address</div>
            <div className="info">{addressInfo}</div>
          </div>
          {note && (
            <div className="delivery-section">
              <div className="label">order notes</div>
              <div className="info">{note}</div>
            </div>
          )}
        </section>
        <section className="order-items-wrapper">
          <div className="order-details-title">Items</div>
          <div className="order-items-container">
            {orderLine?.map((order) => (
              <div key={order?.id} className="order-container">
                <OrderItem order={order} />
                <div className="divider" />
              </div>
            ))}
          </div>
        </section>
        <section className="payment-breakdown-wrapper">
          <div className="order-details-title">Payment breakdown</div>
          <div className="payment-breakdown-container">
            {paymentBreakdown.map((payment) => (
              <div className="payment-breakdown-item" key={payment?.id}>
                <div className="payment-title">{payment?.title}</div>
                <div className={`payment-amount ${payment?.id === 3 ? 'discountOrder' : ''}`}>
                  {payment?.id === 3 && '-'} ${payment?.amount}
                </div>
              </div>
            ))}
          </div>
          <div className="divider" />
          <div className="total-payout-wrapper">
            <div className="payout-title">Total payout</div>
            <div className="payout-amount">${providerPayout}</div>
          </div>
        </section>
      </div>
      {Object.keys(selectedOrder)?.length && (activeTab === "pending" || activeTab === "accepted") && (
        <motion.div
          className="mark-complete-wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.3,
            delay: 0.2,
            ease: 'linear',
          }}
        >
          {activeTab === 'pending' && (
            <div className="reject-accepted-wrapper">
              <Button
                title="Reject"
                type="mobile_secondary"
                onClick={() => setIsRejectModal(true)}
              />
              <Button
                title="Accept"
                type="dark"
                onClick={() => generateRequest('accepted', Provider?.id, orderMainId)}
              />
            </div>
          )}
          {activeTab === 'accepted' && (
            <Button
              title="Mark as complete"
              type="dark"
              onClick={() => generateRequest('completed', Provider?.id, orderMainId)}
            />
          )}
        </motion.div>
      )}
      <AnimatePresence>
        {isRejectModal && (
          <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0, transition: { delay: 0.3 } }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.3,
              delay: 0,
              ease: 'linear',
            }}
          >
            <RejectModal
              setIsRejectModal={setIsRejectModal}
              generateRequest={generateRequest}
              providerId={Provider?.id}
              orderMainId={orderMainId}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </OrderDetailsWrapper>
  );
}
