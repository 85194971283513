import { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Switch, Typography } from '@material-ui/core';
import UploadWidget from 'components/UploadWidget';
import Input from 'components/Input';
import MealTags from './MealTags';
import SubmitButton from 'components/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledForm = styled.div`
  width: 400px;
  padding: 30px;
  padding-top: 10px;
`;
const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

const StyledImage = styled.img`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  border-radius: 5px;
`;
export default function DrawerForm({ meal, handleSubmit, setMeal, allTags, error, isLoading }) {
  const toastId = useRef(null);
  const [isMealActive, setIsMealActive] = useState(true);
  const handleChange = (event) => {
    const { id, value, name, checked } = event.target;
    if(name === 'isActive'){
      setMeal((prevProvider) => ({ ...prevProvider, [name]: checked }));
    }
    setMeal((prevProvider) => ({ ...prevProvider, [id]: value }));
  };

  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    const isNumber = /^\d*\.?\d{0,2}$/.test(value);
    if ((isNumber || value === '') && value.length < 11) {
      const roundNumber = Math.trunc(value);
      const data = roundNumber >= 0 ? value : '';
      setMeal((prevProvider) => ({ ...prevProvider, [id]: data }));
    } else {
      e.preventDefault();
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(`Only Numeric values allowed for this field`);
      }
    }
  }, []);

  const handleIntInputChange = useCallback((e) => {
    const { id, value } = e.target;
    const isNumber = /^\d*\d*$/.test(value);
    if ((isNumber || value === '') && value.length < 11) {
      const roundNumber = Math.trunc(value);
      const data = roundNumber >= 0 ? value : '';
      setMeal((prevProvider) => ({ ...prevProvider, [id]: data }));
    } else {
      e.preventDefault();
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(`Only Numeric values allowed for this field`);
      }
    }
  }, []);

  const setNewImgUrl = (url) => {
    setMeal((prevProvider) => ({ ...prevProvider, img: url }));
  };
  const { name, description, price, carbs, calories, protein, fat, weight, img, isActive } = meal;
  return (
    <StyledForm>
      <Switch
        checked={isActive}
        onChange={handleChange}
        name="isActive"
        color="primary"
        className='switch-button'
      />
      <Input
        label="Name"
        name="name"
        value={name}
        onChange={handleChange}
        bordered
        required
        autoFocus
      />
      <Input
        label="Description"
        name="description"
        value={description}
        onChange={handleChange}
        bordered
        type="textarea"
        rows="4"
      />
      <TwoColumns>
        <Input
          label="Price"
          name="price"
          value={price}
          onChange={handleInputChange}
          type="text"
          bordered
          required
        />
        <Input
          label="Carbs"
          name="carbs"
          value={carbs}
          onChange={handleInputChange}
          type="text"
          bordered
          step="any"
        />
      </TwoColumns>
      <TwoColumns>
        <Input
          label="Calories"
          name="calories"
          value={calories}
          type="text"
          onChange={handleIntInputChange}
          bordered
          step="any"
        />
        <Input
          label="Protein"
          name="protein"
          value={protein}
          type="text"
          onChange={handleInputChange}
          bordered
          step="any"
        />
      </TwoColumns>
      <TwoColumns>
        <Input
          label="Fat"
          name="fat"
          value={fat}
          type="text"
          onChange={handleInputChange}
          bordered
          step="any"
        />
        <Input
          label="Weight"
          name="weight"
          value={weight}
          type="text"
          onChange={handleInputChange}
          bordered
        />
      </TwoColumns>
      <MealTags meal={meal} setMeal={setMeal} allTags={allTags} />
      <StyledImage width="150" src={img} />
      <UploadWidget setNewImgUrl={setNewImgUrl} img={img} />

      <SubmitButton
        disabled={isLoading}
        style={{ marginTop: '20px' }}
        error={error}
        isLoading={isLoading}
        onClick={handleSubmit}
      >
        {isLoading ? 'Saving...' : 'Save'}
      </SubmitButton>
    </StyledForm>
  );
}
