import React from 'react';

const Milk = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M15.2924 9.62346C15.293 8.20884 14.6974 6.82365 13.6584 5.82298C13.4435 5.61595 12.9102 5.18536 12.9102 4.35481V3.41485C13.5922 3.17298 14.0821 2.52168 14.0821 1.75782C14.0821 0.788556 13.2935 0 12.3242 0H7.63672C6.66746 0 5.87891 0.788556 5.87891 1.75782C5.87891 2.52168 6.36879 3.17298 7.05079 3.41485V4.38075C7.05079 5.1913 6.57141 5.58825 6.2516 5.90806C5.25559 6.90408 4.70703 8.22834 4.70703 9.63693C4.70703 9.92959 4.70703 16.6332 4.70703 17.0703C4.70703 18.6857 6.02129 20 7.63672 20H12.329C13.9601 20 15.2897 18.6884 15.293 17.075L15.2924 9.62346ZM7.63672 1.17188H12.3242C12.6473 1.17188 12.9102 1.43473 12.9102 1.75782C12.9102 2.0809 12.6473 2.34375 12.3242 2.34375H7.63672C7.31364 2.34375 7.05079 2.0809 7.05079 1.75782C7.05079 1.43473 7.31364 1.17188 7.63672 1.17188ZM7.0802 6.73669C7.40723 6.40966 8.22266 5.71708 8.22266 4.38071V3.51563H11.7383V4.35485C11.7383 5.14669 12.0654 5.91579 12.6357 6.46505L12.8455 6.66712C13.6563 7.44794 14.1288 8.52533 14.1284 9.6233L14.1286 11.7482C13.0239 11.8781 11.4788 11.5056 10.5709 10.9004L10.0401 10.5465C8.79938 9.71932 7.34547 9.35424 5.8818 9.48994C5.91817 8.44951 6.34032 7.47662 7.0802 6.73669ZM12.329 18.8282H7.63672C6.66746 18.8282 5.87891 18.0396 5.87891 17.0703V10.6762C6.89946 10.5604 8.55782 10.9667 9.39005 11.5215L9.92091 11.8755C11.1424 12.6898 12.6444 13.0683 14.1287 12.9266L14.129 17.0739C14.1269 18.0412 13.3152 18.8282 12.329 18.8282Z" fill={color || "#757E89"} />
        </svg>
    );
};

export default Milk;
