import { memo, useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Input from 'components/Input';
import Select from 'components/Select';
import { providerStatus, providerDisabled } from 'pages/admin/ProvidersManagement/Data';
import { ManageModalWrapper, Mask } from 'pages/admin/ProvidersManagement/style';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import Progress from 'components/Progress';
import ConfirmationRemoveAccount from './ConfirmationRemoveAccount';
import 'animate.css';
import { useAppContext } from 'context/appContext';

const ManageModal = forwardRef(({ setIsManageModal, info, setId, providers, setProviders, filter, setTotalProviders, image, setImage, uploadedImage, setUploadedImage, handleImageClick, setIsOpen, kitchenHasSubscription, setKitchenHasSubscription }, ref) => {
  const { id, images, name, email, phoneNumber, accountStatus, providerFee, userMarkup, tag, storeImage } = info;
  const [animation, setAnimation] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isConfirmRemove, setIsConfirmRemove] = useState(false);
  const { isKitchenHasSubscription } = useAppContext();
  const [changeInfo, setChangeInfo] = useState({
    id,
    name,
    tag,
    phoneNumber: phoneNumber,
    accountStatus,
    providerFee,
    userMarkup,
    storeImage
  });
  const [updateProvider, {isLoading: isUpdating}] = useRequest('admin/provider/profile', 'POST');
  const [deleteProvider, {isLoading: isDeleting}] = useRequest(`admin/provider/delete/${id}`, 'DELETE');
  const fileInputRef = useRef(null);

    const handleClose = useCallback(
        (e) => {
            e?.preventDefault();
            setAnimation(false);
            setId(null);
            setUploadedImage(null)
            setTimeout(() => {
                setIsManageModal(false);
            }, 500);
        },
        [setIsManageModal],
    );

    const handleChange = useCallback((e) => {
      const { name, value } = e.target;
      const numericValue = parseFloat(value);
  
      // Helper function to update the state
      const updateState = (newValue) => {
        setChangeInfo((prevState) => ({ ...prevState, [name]: newValue }));
      };
    
      if (name === 'providerFee' || name === 'userMarkup') {
        if (/^\d+(\.\d{0,2})?$/.test(value)) {
            if (numericValue <= 100) {
                setChangeInfo((prevState) => ({ ...prevState, [name]: value }));
            } else {
                setChangeInfo((prevState) => ({ ...prevState, [name]: '100' }));
            }
        } else if (value === '') {
            setChangeInfo((prevState) => ({ ...prevState, [name]: value }));
        }
    } else if (name === 'phoneNumber') {
        if (value.length > 13) return;
        const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        updateState(numericValue);
      } else {
        updateState(value);
      }
    }, [changeInfo]);
  

  const handleSave = async (e) => {
    let isApproved=changeInfo?.accountStatus === "active" || changeInfo?.accountStatus==="suspended";
    const isSuspended=changeInfo.accountStatus==="suspended";
    const {accountStatus ,storeImage , ...payloadInfo} = changeInfo;
    setIsOpen(false);
    await updateProvider({
      body: {
        ...payloadInfo,
        approved: isApproved,
        isSuspended,
        storeImages: storeImage
      }
    }).then((data) => {
      if(data?.data?.success){
        toast.success('Provider updated successfully');
        updateProviderData(data?.data?.result)
        handleClose();
      }
    }).catch((err) => {
      console.log(err);
      toast.error(err.message);
    })
  }

  const updateProviderData = (updatedResult) => {
    let newAccountStatus;
    if (updatedResult?.approved) {
        newAccountStatus = "active"
    } else if (!updatedResult?.approved && !updatedResult?.user) {
        newAccountStatus = "unclaimed"
    } else {
        newAccountStatus = "unlisted"
    }

    const updatedDAta = providers.map((provider) => {
    if (provider.id === id) {
    if (filter !== "all" && provider.accountStatus !== newAccountStatus) {
      setTotalProviders((pre) => pre - 1);
      return null;
    }
    return { ...provider, name: updatedResult?.name, phoneNumber: updatedResult?.phoneNumber, providerFee: updatedResult?.providerFee, userMarkup: updatedResult?.userMarkup, accountStatus: newAccountStatus, storeImage: updatedResult?.storeImages, tag: updatedResult?.tag }
    }
    return provider;
    });
    const filteredData = updatedDAta.filter((provider) => provider !== null);
    setProviders(filteredData);
  };

    useEffect(() => {
        if (
            (name === changeInfo.name || changeInfo.name === '') &&
            (phoneNumber === changeInfo.phoneNumber || changeInfo.phoneNumber === '' || changeInfo.phoneNumber === 'N/A') &&
            accountStatus === changeInfo.accountStatus &&
            (providerFee === parseInt(changeInfo.providerFee) || changeInfo.providerFee === '') &&
            (userMarkup === parseInt(changeInfo.userMarkup) || changeInfo.userMarkup === '') &&
            (tag === changeInfo.tag || changeInfo.tag === '') &&
            (storeImage === changeInfo.storeImage || changeInfo.storeImage === null)
        ) {
            setIsChanged(false);
        } else {
            setIsChanged(true);
        }
    }, [changeInfo]);

    const handleDelete = useCallback(async (e) => {
        e.preventDefault();
        try {
            const result = await deleteProvider()
            if (result?.data?.success) {
                toast.success(result.data.message)
                const filteredProviders = providers.filter((provider) => provider?.id !== id);
                setProviders(filteredProviders)
            }
        } catch (error) {
            toast.error("oops! something went wrong.")
            console.error('An error occurred:', error);
        } finally {
            handleClose();
        }
    }, [id]);

    const generateConfirmationRemove = useCallback((e) => {
        e.preventDefault();
        setIsConfirmRemove(true);
    }, [isConfirmRemove])

    const handleFileChange = async (e) => {
      const imageFile = e.target.files[0];
      e.target.value = '';

      if (imageFile) {
        if (
          imageFile.type.startsWith("image/") &&
          imageFile.size <= 5 * 1024 * 1024
        ) {
          const imageUrl = URL.createObjectURL(imageFile);
          setImage(imageUrl);
        } else {
          console.error("Invalid file type or size");
        }
      }
    };

    useImperativeHandle(ref, () => ({
      handleSave: handleSave
    }));
  

    const handleSaveClick = useCallback((e) => {
      e.preventDefault();
      if(kitchenHasSubscription?.hasSubsciption && Number(userMarkup) !== Number(changeInfo?.userMarkup)){
        setIsOpen(true);
      } else{
        handleSave(e);
      }
    },[userMarkup, changeInfo, kitchenHasSubscription])

    useEffect(() => {
        if(uploadedImage !== null){
            setChangeInfo((prevState) => ({ ...prevState, storeImage:uploadedImage}))
        }
    },[uploadedImage])

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (id) {
            const isSubscription = await isKitchenHasSubscription({ kitchenId: id });
            setKitchenHasSubscription(isSubscription);
          }
        } catch (err) {
          console.log(err, 'err');
        }
      };
    
      fetchData();
    }, [id]);
    
    return (
        <>
            <Mask onClick={handleClose} />
            <ManageModalWrapper
                className={`animate__animated ${animation ? 'animate__fadeInRight' : 'animate__fadeOutRight'
                    }`}
            >
                <div className="provider-title-wrapper">
                    <h3>Provider Info</h3>
                    <img src="/icons/close.svg" alt="close icon" onClick={handleClose} />
                </div>
            <div className="provider-image-wrapper">
              {changeInfo?.storeImage ?
                <div className='main-image'>
                 <img src='/icons/Remove.svg' className='cross_icon' onClick={handleImageClick}/>
                  <img src={changeInfo?.storeImage} className='main-img' />
                </div>
                :
                <img src={'/icons/providerImageUpload.svg'} className='image-container' alt="provider Img" onClick={handleImageClick} />
              }
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </div>
                <form>
                    <div className="form-inputs">
                        <Input label="Name" name="name" value={changeInfo.name} onChange={handleChange} />
                        <Input label="Tag" name="tag" value={changeInfo?.tag} placeholder={"Tag here"} onChange={handleChange}/>
                        <Input label="Email" value={email || 'N/A'} disabled />
                        <Input
                            type="tel"
                            label="Phone number"
                            name="phoneNumber"
                            placeholder={"N/A"}
                            value={changeInfo.phoneNumber}
                            onChange={handleChange}
                        />
                        <Select
                            label="Account status"
                            options={accountStatus === 'unclaimed' ? providerDisabled : providerStatus}
                            defaultChecked={changeInfo.accountStatus}
                            updateValue={(value) =>
                                setChangeInfo((prevState) => ({ ...prevState, accountStatus: value }))
                            }
                            icon={'SelectArrow'}
                            isDisabled={accountStatus === 'unclaimed' && true}
                        />
                        <Input
                            type="number"
                            label="Provider fee"
                            name="providerFee"
                            value={changeInfo.providerFee}
                            onChange={handleChange}
                            imgSrc="/icons/precentage.svg"
                            fieldIcon
                        />
                        <Input
                            type="number"
                            label="User markup"
                            name="userMarkup"
                            value={changeInfo.userMarkup}
                            onChange={handleChange}
                            imgSrc="/icons/precentage.svg"
                            fieldIcon
                        />
                    </div>
                    <div className="form-btns">
                        <button type="submit" onClick={(e) => handleSaveClick(e)} className="btn save-btn" disabled={!isChanged || isUpdating}>
                            Save
                        </button>
                        <button className="btn cancel-btn" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="btn" onClick={generateConfirmationRemove} disabled={isDeleting}>Delete provider</button>
                    </div>
                </form>
                {isUpdating || isDeleting && <Progress />}
            </ManageModalWrapper>
            {isConfirmRemove && <ConfirmationRemoveAccount setIsConfirmRemove={setIsConfirmRemove} accountType="provider" handleRemove={handleDelete} isDeleting={isDeleting}/>}
        </>
    );
})

export default memo(ManageModal);