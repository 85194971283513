
// import SelectOptions from './SelectOptions';
import SelectOptions from "components/shop/CreateMealPlanForm/SelectOptions";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { AddressWrapper } from './AddressWrapper';
import ProviderList from "./ProviderList";
import { useNutritionistContext } from "context/nutritionistContext";
import UseClientCart from "hooks/UseClientCart";
import ReactTooltip from 'react-tooltip';

const SendMealsPopup = styled.div`
    text-align: center;
    background-color: #fff;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0;
    z-index: 112;
    width: 100%;
    padding: 20px 0;
    max-width: 470px;
    overflow-y:auto;
    overflow-x: hidden;
    &.hidden{
        display:none;
    }
    &::-webkit-scrollbar{
        display:none;
      }

    .modal-content_holder{
        display: block;
        padding: 0 48px;

        > .title{
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #51635D;
            margin-bottom: 10px;
        }

    }

    @media (min-width: 992px){
        height: auto;
    }

    .close_cross{
        position: absolute;
        left: 14px;
        top: 14px;
        z-index: 2;
        cursor: pointer;
    }


    .sub__title{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #51635D;
        margin-bottom: 24px;
        display:flex;
        justify-content:center;
        p{
            margin-left:3px;
        }
    }

    .send__meals__svg{
        margin-left: 30px;
        width: calc(100% - 30px);
        max-width: 250px;
    }

    .select-options{
        .title{
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            color: #51635D;
            margin-bottom: 12px;

            img{
                width: 14px;
                height: 14px;
            }
        }
    }

    .radio-buttons{
        align-items: center;
    }

    .radio-button-container{
        height: 44px;
        line-height: 44px;
        width: 100%;

       span{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
        }
        
        &:first-child{
            border-radius: 6px 0 0 6px; 
        }

        &.static-number{
            background: #FFFFFF;
            border: 1.5px solid #E0E3E0;
            border-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        &:last-child{
            .selected-value{
                border-radius: 0 6px 6px 0;
                border: 1.5px solid #E0E3E0;
            }
        }

        &.custom-number{
            .select-option{
                &:first-child{
                    display: none;
                }
            }

            .options-dropdown{
                max-height: 150px;
            }
        }

        &.radio-checked{
            border-radius: 4px; 
            height: 50px;
            border: 1.5px solid #97DC76;
            background: #F0FAEB;
            span{
                color: #52C41A;
                font-weight: 700;
            }

            &.custom-number{
                .selected-value{
                    border: 0px;
                }
            }

            + .radio-button-container{
                border-left: 0px;
                .selected-value{
                    border-left: 0px;
                }
            }
        }

        .select-label{
            margin: 0;
        }
    }

    .no-user-div{
        border: 1.5px solid #E0E3E0;
        border-radius: 6px;
        padding: 8px 20px;
        max-width: 150px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #51635D;
        display: inline-block; 
        margin: 28px auto 0;
        position: relative;
        background-color: #fff;

        &::before{
            content: "";
            position: absolute;
            width: 100vw;
            height: 1.5px;
            background: #51635D;
            opacity: 0.1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }

    @media (max-width: 360px){
        .modal-content_holder{
            padding: 0 24px;
        }

        .send__meals__svg{
            max-width: 200px;
            width: 100%;
            margin-left: 15px;
        }
    }

    .__react_component_tooltip{
        width: 240px;
        word-break: break-all;
       }
`

export default function SendMealsModal({ 
    userDeliveryAddress,
    setShowMealsCart,
    clientId,
    setMealsModal,
    followerProfile,
    setClientModal,
    setClientAnim,
    mealsModal,
    setMealPlannerClose
 }) {
    const [meals, setMeals] = useState(3);
    const [days, setDays] = useState(7);
    const [showAnim, setShowAnim] = useState(true);
    const [providerModal, setProviderModal] = useState(false);
    const [focus, setFocus] = useState(false);
    const moveToBottom = useRef(null);
    const { updateClientsettings, isSafari } = useNutritionistContext();
    const [mealsModalsAnim, setMealsModalsAnim] = useState(true);
    const {OnlyFirstName} = UseClientCart();
    const clientName = OnlyFirstName(followerProfile?.name);
    const [userAddressLatLong, setUserAddressLatLong] = useState([]);
    const [zip, setZip] = useState('');
    const [visibility, setVisibility] = useState(false)

    console.log(visibility,'visibility')
    useEffect(() => {
        setMeals(3);
        setDays(7);
    }, [showAnim])

    const closeMealsModal = () => {
        setShowAnim(false);
        setTimeout(() => {
            setMealsModal(false);
        }, 500)
    }

    useEffect(() => {
        if(focus){
            setTimeout(() => {
                moveToBottom.current.scroll(0, 800);
            }, 0)
        }
    }, [focus])

    useEffect(() => {
        if(mealsModal){
            document.body.classList.add('bodyFixed');
        }
        else{
       document.body.classList.remove('bodyFixed');
        }
    }, [mealsModal])

    useEffect(()=>{
        ReactTooltip.rebuild();
      },[])    

    return (
        <>
        <SendMealsPopup ref={moveToBottom} className={`${showAnim && mealsModalsAnim ? "fadeInMiddle" : "fadeOutMiddle"} ${visibility ? 'hidden' : ''}`}>
            <div className="modal-content_holder">
                <img src="/icons/close-cross-green.svg" onClick={closeMealsModal} className="close_cross" />

                <h3 className="title">Send Meals Directly</h3>
                <p className="sub__title">Add meals directly to {clientName.length > 12 ? <p data-tip={clientName} data-for="name" style={{cursor: 'pointer'}}>{clientName.substring(0, 12) + "..." }</p> : <p>{clientName}</p>}’s cart.</p>
                <div>{isSafari?"Safari": ""}</div>
                <img src="/images/send-meals-modal.svg" className="send__meals__svg" />

                <div className="select-options">
                    <SelectOptions
                        id="meals"
                        Icon="/icons/dinner-gray.svg"
                        title="How many meals per day?"
                        options={[2, 3, 4]}
                        value={meals}
                        setValue={setMeals}
                        valueCondition={1}
                        customOptionVal={[5, 9]}
                    />
                    <SelectOptions
                        id="days"
                        Icon="/icons/calendar-gray.svg"
                        title="For how many days?"
                        options={[4, 7, 12]}
                        value={days}
                        setValue={setDays}
                        valueCondition={4}
                        customOptionVal={[13, 31]}
                    />

                    <div className='no-user-div'>
                        {meals && days ?
                            <p className='no-user'> = {meals * days} meals </p> : <p className='no-user'> = select meals </p>
                        }
                    </div>
                </div>

                <AddressWrapper 
                    days={days} 
                    meals={meals} 
                    updateSettings={updateClientsettings} 
                    setFocus={setFocus} 
                    userDeliveryAddress={userDeliveryAddress} 
                    clientId={clientId}
                    setProviderModal={setProviderModal}
                    setMealsModal={setMealsModal}
                    setUserAddressLatLong={setUserAddressLatLong}
                    setZip={setZip}
                    clientName={clientName}
                 />
            </div>

            <ReactTooltip id="name" place="top" effect="solid">
            </ReactTooltip>

        </SendMealsPopup>

      {providerModal &&
       <ProviderList 
       setMealsModalsAnim={setMealsModalsAnim} 
       providerModal={providerModal} 
       setProviderModal={setProviderModal} 
       setMealsModal={setMealsModal} 
       followerProfile={followerProfile} 
       setClientModal={setClientModal}
       setClientAnim={setClientAnim}
       zip={zip}
       userAddressLatLong={userAddressLatLong}
       setMealPlannerClose={setMealPlannerClose}
       setVisibility={setVisibility}
       visibility={visibility}
       />
       }
        </>
    )
}