import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { MdEdit } from 'react-icons/md';
import { theme } from 'shared/theme/theme';
import { useAppContext } from 'context/appContext';
import { Button } from 'components/elements/button/Button';
import Textarea from 'components/elements/textarea/Textarea';
import ImageContainer from "components/elements/imageUploader/ImageUpload";
import { useRequest } from 'hooks/useRequest';
import { useKitchenContext } from 'context/kitchenContext';
import { ASSET_TYPE } from '../../../../../constants'


const BusinessProfileCardStyle = styled.div`
border-bottom: 1px solid ${({ theme }) => theme?.colors?.borderLight};
padding-bottom: 24px;
.Image-details{
    display: flex;
    gap: 24px;
    align-items: center;
    padding-bottom: 28px;
    position: relative;    
  }
  .chip_wrapper {
    padding: 6px 8px;
  }
    .icons{
        width: 80px;
        height: 80px;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
        .edit{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${({ theme }) => theme?.colors?.grayLighter};
            position: absolute;
            right: -4px;
            bottom: -4px;
            cursor: pointer;
            .i{
                color: ${({ theme }) => theme?.colors?.grayDark};
            }
        }
    }
    .content{
        .title{
            margin-bottom: 8px;
            font-size: ${({ theme }) => theme?.typography?.fontXl};
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            color: ${({ theme }) => theme?.colors?.blackDefault}
        }
        .desc{
            font-size: ${({ theme }) => theme?.typography?.fontRegular};
            font-weight: 400;
            line-height: 20px;
            width: 90%;
            color: ${({ theme }) => theme?.colors?.grayDarker};
        }
    }
    .custom_button {
        padding: ${({ isEdit }) => (!isEdit ? '8px 8px 8px 16px' : '8px 16px')};
        font-size: ${({ theme }) => theme?.typography?.fontSmall};
        font-weight: 600;
        line-height: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
        color: ${({ theme }) => theme?.colors?.primaryDark};
        background: ${({ theme }) => theme?.colors?.white};
        position: absolute;
        right: 0;
        top: 15px;
        z-index: 9;
        cursor: pointer;
        .icon {
            width: 0px !important;
            height: 0px !important;
            display: contents;
          svg {
            display: block;
          }
        }
    }
    .button_flex {
        display: flex;
        gap: 8px;
        position: absolute;
        right: 0;
        top: 15px;
        .custom_button {
          position: relative;
          &:last-child {
            background: ${({ theme }) => theme?.colors?.primaryDefault};
            color: ${({ theme }) => theme?.colors?.white};
          }
        }
      }
      .message{
        width: 388px;
        padding-bottom:20px;
        .fIqsyH{
            padding-right:13px;
        }
      }
      textarea{
        height:104px !important;
        margin-bottom: ${({ isEdit }) => isEdit ? "18px" : "0px"}
        overflow-x: hidden;
      }
      label {
        color: ${({ theme, isEdit }) => isEdit ? theme?.colors?.blackDefault : theme?.colors?.grayDark};
        font-family: ${theme?.typography?.fontInter};
        font-size: ${theme?.typography?.fontRegular};
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .Details{
        width: 100%;
      }
      .multi-images{
        height: 200px;
        margin-top: 8px;
        border-radius: 6px;
        border: 1px solid  ${({ theme }) => theme?.colors?.grayLight};
        padding: 12px 0px 13px 20px;
        .main-image{
          position: relative;
          display: inline-flex;
          width: 297px;
          height: 166px;      
          padding: 29px 28px 28px 29px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #878A98;
          position: relative;
          cursor: ${({ isEdit }) => !isEdit ? 'not-allowed' : 'pointer'}; /* Set cursor to not-allowed when disabled */
          pointer-events: ${({ isEdit }) => !isEdit ? 'none' : 'auto'}; /* Disable pointer events when disabled */        

          .add_icon{
            cursor: pointer;
          }
          .cross_icon{
            position: absolute;
            top: 5px;
            right: 3px;
            cursor: pointer;
          }
        }
        .main-img{
          width: 377.56px;
          height: 179px;
          border-radius: 6px;
          cursor: grab;
        }
        }
      }
  
    
`
const FormGroupStyle = styled.div`
  margin-bottom: ${({ isEdit }) => (isEdit ? '16px' : '24px')};
  width: 100%;
  label {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
    display: inline-block;
  }
  input {
    height: 40px;
    width: 388px;
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    overflow-wrap: anywhere;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  
`;

const DeleteModalStyle = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 50, 65, 0.4);
`

export const BusinessProfileCard = ({ setProviderImage, providerImage, showErrorMessage, showSuccessMessage  }) => {
    const { provider, setProvider } = useAppContext();
    const [updatedprovider] = useRequest(`provider/businessProfile`, 'put');
    const { BusinessProfile } = provider || {};
    const [addressInfo] = BusinessProfile || [];
    const [isEdit, setIsEdit] = useState(false);
    const { description } = addressInfo || {};
    const [errorMessage, setErrorMessage] = useState(false)
    const [changedValues, setChangedValues] = useState({description: description});
    const [image, setImage] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const { imitateProviderCredentials } = useKitchenContext();

    const handleNewImgUrlChange = (newUrl) => {
        setProviderImage(newUrl);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setChangedValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSave = useCallback( async (changedValues) => {
      try {
          const updatedBusinessProfile = {
              ...addressInfo,
              ...changedValues,
              image:providerImage
          };
          updatedBusinessProfile.deliveryFee = provider.deliveryFee;
          setProvider((prevProvider) => ({
              ...prevProvider,
              BusinessProfile: [updatedBusinessProfile],
          }));
          const body = {
              ...changedValues, id:addressInfo?.id, image:providerImage
          };
          await updatedprovider({ body });
          showSuccessMessage('You have successfully updated business profile');
          setIsEdit(false);
      } catch (error) {
          console.error('Error occurred while saving changes:', error);
          showErrorMessage(error.message);
      }
  },[addressInfo]);
  
    return (
        <>
            <BusinessProfileCardStyle theme={theme} isEdit={isEdit}>
                <div className='Image-details'>
                    {!isEdit ? (
                        <Button
                            title="Edit"
                            className="custom_button"
                            onClick={() => setIsEdit(true)}
                            iconRight={<MdEdit />}
                        />
                    ) : (
                        <div className="button_flex">
                            <Button title="Cancel" size={"small"} type={'secondary'} onClick={() => setIsEdit(false)} />
                            <Button title="Save" size={"small"} type={"dark"} onClick={() => {handleSave(changedValues),setIsEdit(false)}} isDisabled={changedValues?.description === description}/>
                        </div>
                    )}
                    <div className='icons'>
                    <img src={providerImage} />
                        <span className='edit' onClick={() => setIsVisible(true)}>
                          <MdEdit className='i' />
                        </span>
                    </div>
                    <div className='content'>
                        <h3 className='title'>{addressInfo?.name}</h3>
                        <p className='desc'>
                            {addressInfo?.street && <>{addressInfo.street}{addressInfo.city || addressInfo.state || addressInfo.zip ? ', ' : ''}</>}
                            {addressInfo?.city && <>{addressInfo.city}{addressInfo.state || addressInfo.zip ? ', ' : ''}</>}
                            {addressInfo?.state && <>{addressInfo.state}{addressInfo.zip ? ', ' : ''}</>}
                            {addressInfo?.zip}
                        </p>
                    </div>
                </div>
                <div className='Details'>
                    {!isEdit ? (
                        <FormGroupStyle theme={theme}>
                            <label>Description</label>
                            <p className="value">{addressInfo?.description?.length > 350 ? addressInfo?.description.slice(0, 350) + "..." : addressInfo?.description}</p>
                        </FormGroupStyle>
                    ) :
                        <Textarea
                            id="default"
                            label="Description"
                            placeholder=""
                            name="description"
                            value={changedValues?.description ?? addressInfo?.description}
                            message={errorMessage}
                            onChange={(e) => {
                              if (e.target.value.length <= 500) {
                                handleInputChange(e);
                                setErrorMessage("");
                              } else {
                                setErrorMessage("Description should not be greater than 500");
                                setProvider((prevState) => ({
                                  ...prevState,
                                  ["description"]: e.target.value.substring(0, 500)
                                }));
                              }
                              }}          
                        />
                    }
                </div>
                {isVisible && (
                  <div className="modal-container">
                    <ImageContainer
                      image={image}
                      setImage={setImage}
                      setIsVisible={setIsVisible}
                      setNewImgUrl={(image) => {
                        setProviderImage(image);
                      }}
                      assetType={ASSET_TYPE?.kitchenBusinessStore}
                      id={imitateProviderCredentials?.businessProfileId}
                      circle={true}
                    />
                  </div>
                )}
            </BusinessProfileCardStyle>
        </>
    )
}


