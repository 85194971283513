import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import EnterAddressModal from '../EnterAddressModal';
import { useAppContext } from 'context/appContext';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode} from 'use-places-autocomplete';
import { DeliveryAddressWrapper } from './style';

export default function DeliveryAddress() {
  const defaultForm = {
    address: { value: '' },
    apartment: '',
    type: 'home',
    prevType: '',
    place_id: '',
    customType: null,
    instruction: '',
  };
  const [form, setForm] = useState(defaultForm);
  const [addressDetails, setAddressDetails] = useState({});
  const [isRenderSuggestion, setIsRenderSuggestion] = useState(false);
  // const [isEnterAddress, setIsEnterAddress] = useState(false);
  const [location, setLocation] = useState({ lat: '', lng: '' });
  const [isMask, setIsMask] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { isAddress, setIsAddress, isMobile } = useAppContext();
  const inputRef = useRef();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const blurInput = () => {
    inputRef.current.blur();
    setValue('');
    setErrorMessage(false);
  };

  const generateLocation = (address) => {
    getGeocode({ address }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setLocation({ lat, lng });
    });
  };

  const handleSelect = (selectedAdd) => async () => {
    const address = selectedAdd.description;
    const results = await getGeocode({ address })
    const zipCode = getZipCode(results[0], false);
    if(zipCode){
      generateLocation(address);
      setAddressDetails(selectedAdd);
      setValue(address, false);
      clearSuggestions();
    } else {
      setErrorMessage(true);
      clearSuggestions();
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <img src="/icons/location-black.svg" alt="Location Icon" />
          <p>
            {main_text} {secondary_text}
          </p>
        </li>
      );
    });

  useEffect(() => {
    if (location.lat) {
      setForm({ ...form, address: { value: addressDetails } });
      setIsAddress(true);
    }
  }, [location]);

  useEffect(() => {
    if (!isAddress) {
      setForm(defaultForm);
      setValue('');
      setLocation({ lat: '', lng: '' });
    }
  }, [isAddress]);

  useEffect(() => {
    const landingWrapper = document.querySelector('.visitor-landing-wrapper');
    if (landingWrapper) {
      if ((status === 'OK' && isRenderSuggestion) && !isMobile) {
        landingWrapper.classList.add('bodyOverflow');
      } else {
        landingWrapper.classList.remove('bodyOverflow');
      }
    } 
  }, [isRenderSuggestion, status]);
    
  return (
    <>
      <DeliveryAddressWrapper landingPage={window.location.pathname === '/'}>
      {!!value?.length && isMask && <div className="input-mask" onClick={blurInput} />}
        <div className={`input-container ${errorMessage ? "error" : ''}`}>
          {window.location.pathname === '/' ? 
           <img src="/icons/locationBlack.svg" alt="Search Icon" />
            :
            <img src="/icons/search-input3.svg" alt="Search Icon" />
          }
          {window.location.pathname === '/' && <button>Get Meals</button>}
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setIsRenderSuggestion(true);
              setErrorMessage(false);
              setIsMask(true);
            }}
            disabled={!ready}
            placeholder="Enter delivery address"
            className="place-autocomplete-input"
          />
        </div>
        {errorMessage && <text className='error-message'>Please enter a valid address</text>}
        <AnimatePresence>
          {status === 'OK' && isRenderSuggestion && (
            <motion.ul
            initial={window.location.pathname === '/' ? { opacity: 0 } : { opacity: 0, y: 100 }}
            exit={window.location.pathname === '/' ? { opacity: 0 } : { opacity: 0, y: 100 }}
            animate={window.location.pathname === '/' ? { opacity: 1 } : { opacity: 1, y: 0 }}
            transition={ 
              {
                duration: 0.8,
                delay: 0,
                ease: [0, 0.71, 0.2, 1.01],
              }}
          >
              {renderSuggestions()}
            </motion.ul>
          )}
        </AnimatePresence>
      </DeliveryAddressWrapper>
      {isAddress && (
        <EnterAddressModal
          location={location}
          form={form}
          setForm={setForm}
          isEnterAddress={isAddress}
          setIsEnterAddress={setIsAddress}
          setValue={setValue}
        />
      )}
    </>
  );
}
