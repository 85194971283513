import React, { useState } from 'react'
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import RadioButton from './radioButton';
import { BiPlus } from 'react-icons/bi';
import { Button } from 'components/elements/button/Button';
import SchduleModal from './scheduleModal';
import { MdEdit } from 'react-icons/md';

export const AvailabilityWrapper = styled.div`
.MuiFormControl-root{
  label{
    margin-left: 19px;
    .MuiRadio-root{
      margin-right: 12px;
    }
  }
  label:nth-of-type(2){
    margin-bottom: 17px;
    margin-top: 4px;
    .MuiRadio-root{
      transform: translateY(-10px);
    }
}
}
.MuiRadio-root{
  color: #9CA3AD !Important;
}
.MuiRadio-root.Mui-checked{
  color: #000A2C !Important;
}
.MuiFormControlLabel-label{
  color: #283241;
  font-family: Gilroy !Important;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  .label{
    display: block;
    font-weight: 600;
  }
  .desc{
    font-weight: 400;
  }
}
.add_schedule_button {
  position: absolute;
  right: 24px;
  top: 64px;
  button {
    border-radius: 6px;
    font-family: Gilroy;
    &:disabled{
      color: #C2C9D1;
      border: 1px solid #E0E4E8 !important;
      cursor: no-drop;
    }
  
  }
  .plus_icon {
    font-size: 16px;
    display: block;
    &:disabled{
      path{
        fill: #C2C9D1;
      }
    }
  }
}

`

export default function Availability({ mealData, setMealData }) {
  const [isAddActive, setIsAddActive] = useState(false);
  return (
    <>
    <AvailabilityWrapper>
        <RadioButton mealData={mealData} setMealData={setMealData} />
        <div className="add_schedule_button">
            <Button
              title={mealData?.rotationMenuSchedule?.length ? "Edit schedule" : "Add schedule"}
              type={'secondary'}
              size="small"
              onClick={() => {setIsAddActive(true)}}
              iconLeft={mealData?.rotationMenuSchedule?.length ? <MdEdit className='plus_icon'/> : <BiPlus className="plus_icon" />}
              isDisabled={!mealData?.isRotationMenu}
            />
          </div>

    </AvailabilityWrapper>
    {isAddActive && (
      <SchduleModal isAddActive={isAddActive} setIsAddActive={setIsAddActive} mealData={mealData} setMealData={setMealData}/>
    )}
    </>
  );
}
