import React, {useEffect} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import UserDetails from 'components/Nutritionist/Planning/UserDetails';
import { filter } from 'ramda';
import { ContactlessOutlined } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { split } from 'lodash';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

const NutritionistReportsContainer = styled.div`
  .main-layout {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 720px;
    //border: 1px solid #52c41a;

    button: disabled{
      background-color: #e0e3e0;
      color: #949d9b;
      cursor: not-allowed;
    }
  }
  .request-payment-disabled {
    background-color: #e0e3e0 !important;
    color: #949d9b !important;
    cursor: not-allowed !important;
  }

  .layout-title {
    margin: 27px;
    color: #51635d;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }
  .section1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18px;
  }
  .section1-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:158px;
    background: #52C41A;
    border-radius: 6px 0px 0px 6px;
    height:50px;

    .price-card-wrapper{
      .price-mobile{
        display: flex;
        flex-direction:column;
      }
    }
  }
  .section2-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F0FAEB;
    border-radius: 0px 6px 6px 0px;
     width:158px;
    height:50px;

    .price-card-wrapper{
      .price-mobile{
        display: flex;
        flex-direction:column;
      }
    }
  }
  .text1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px; 
    color: #FFFFFF;
  }
  .text2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .text-1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #52C41A;  
  }
  .text-2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #52C41A;
  }
  .section2 {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    width: 100%;
    padding-bottom: 25px;
    border-bottom: 1px solid #E0E3E0;

     .show{
       width:100%;
       display:flex;
       justify-content:center;
       margin-top:14px;
    .show-more{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 123px;
      height: 42px;
      background: #FFFFFF;
      border: 1px solid #97DC76;
      border-radius: 21px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #51635D;
      &:hover {
        background: #cbedba;
        color: #51635d;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  }
  .cust-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    heigth:100%;
  }
  .cust-header {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
  .cust-header span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    display: block;
    width: 100%;
    padding-right: 10px;
  }
  .cust-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    align-items:center;
    ::-webkit-scrollbar {
      width: 3px;
    }
  }
  .cust-scroll-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 210px;
    overflow: scroll;
    align-items:center;
    padding-bottom:30px;
  }
  .upcoming-coaching-body-inner{
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    width: 90%;
    margin-bottom:6px; 
  }
  .name-amount{
    display:flex;
    width: 100%;
    justify-content: space-between;
    .name-img{
      display:flex;
    }
    .user-img{
      display: flex;
    align-items: center;
    margin-right:5px;
      img{
        width:16px;
        height:16px;
        object-fit:cover;
        border-radius:50%;
      }
    }
    .name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #51635D;
      
    }
    .amount{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #52C41A;   
    }
  }
  .history-disbursement{
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    width: 90%;
    margin-bottom:6px;
  }
  .voided{
    padding: 2px;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-55%);
  }
  .id-amount{
    display:flex;
    .span{
      display:flex;
      align-items:center;
      .id-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #51635D;
      }
      .id{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #51635D;
      }
    }
    .ammount{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: #52C41A;
    }
  }
  .upcoming-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 194px;
    overflow: hidden;
    align-items:center;
  }
  .upcoming-scroll-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: overlay;
    align-items:center;
    ::-webkit-scrollbar {
      width: 3px;
    }
  }
  .coming-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    align-items:center;
    ::-webkit-scrollbar {
      width: 3px;
    }
  }
  .coming-scroll-body{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    align-items:center;
    ::-webkit-scrollbar {
      width: 3px;
    }
  }
  .cust-body-inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 62px;
    justify-content: space-evenly;
  }
  .accordion-body-inner{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 62px;
    padding-left: 14px;
  }
  .meeting-body-inner{
    display: flex;
    align-items:center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 62px;
    padding-left: 14px;
    .start-end{
      display:flex;
      flex-direction:column;
      justify-content: center;
      row-gap: 5px;
      .meeting{
        display:flex;
        .meeting-text{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #51635D;
        }
        .meeting-date {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #51635D;
        }
      }
      .paiid{
        display:flex;
        .paiid-text{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #51635D;
        }
        .paiid-date {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #51635D;
        }
      }
    }
    .isvoid{
      width: 43px;
      height: 15px;
      background: #EB3223;
      border-radius: 3px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 9px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content:center;
      color: #FFFFFF;
      margin: -18px 40px 0px 0px;
      span{
        display:flex;
        justify-content:center;
      }
    }
  }
  .cust-body-inner:nth-of-type(odd){
    background: #F6F5F8;
  }
  .accordion-body-inner:nth-of-type(odd){
    background: #F6F5F8;
  }
  .meeting-body-inner:nth-of-type(odd){
    background: #F6F5F8;
  }
  .start-end{
    display:flex;
    flex-direction:column;
    .start{
      display:flex;
      padding: 14px 0px 5px;
      .start-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #51635D;
      }
      .start-span{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #51635D;
      }
    }
    .end{
      display:flex;
      padding-bottom: 14px;
      .end-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #51635D;
      }
      .end-span{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #51635D;
      }
    }
  }
  .paid{
    display: flex;
    align-items: self-end;
    padding: 14px;
    flex-direction: column;
    .paid-text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #51635D;
    }
    .paid-span{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: #51635D;
    }
    .isvoid{
      img{
        width: 45px;
        height: 12px;
        display: flex;
      }
    }
  }
  .upcoming-body-inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px 20px;
    background:#F6F5F8;
  }
  .upcoming-body-inner:nth-of-type(even){
    background: #ffffff;
  }
  .upcoming-body-inner :nth-child(4n) {
    color:#52C41A;
    font-weight: 700;
  }
  .cust-body-inner span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
  }
  .upcoming-body-inner span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    display: block;
    width: 100%;
    padding-right: 10px;
  }
 
  .upcoming-disbursement{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }
  .upcoming-coaching-disbursement{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    display: flex;
    justify-content: center;
  }
  .history{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }
  .history-summary{
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items:center;
    .name-img{
      display:flex;
      align-items:center;
    }
    .user-img{
      display:flex;
      align-items:center;
      margin-right:8px;
      img{
        width:16px;
        height:16px;
        border-radius:50%;
        object-fit:cover;
      }
    }
    .summary-name{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #51635D; 
      width:128px; 
    }
    .summary-id{
       display:flex;
       width:auto !important;
       margin-right: 15px; 
       .id-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px; 
        color: #51635D; 
       }
       .id{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635D;  
       }
    }
    .summary-amount{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #52C41A;
    }
  }
  .no-data{
     text-align:center;
  }
  .MuiAccordion-root{
    box-shadow:none;
  }
  .MuiAccordionSummary-root{
    min-height: unset;
    height: 34px;
    background: #F6F5F8;
  }
  .MuiAccordionDetails-root{
     padding:0 !important;
  }
  .history-disbursement:nth-of-type(even){
    .MuiAccordionSummary-root{
      background: #ffffff;
    }
  }
  .upcoming-coaching-body-inner:nth-of-type(even){
    .MuiAccordionSummary-root{
      background: #ffffff;
    }
  }

  @media (max-width:991px){
    .main-layout{
      width:auto !important;
    }import CoachingDisbursement from './../admin/disbursement/coachingDisbursement';

    .section1-inner{
     
    }
    .section2-inner{
     
    }
    .payment{
      margin-top:18px;
      margin-left: 19px;
      button{
        padding:10px;
        color:#51635d;
        font-weight:600;
        cursor:pointer;
      }
    }
    .cust-table{
      padding: 0 10px;
    }
    .cust-header{
      padding: 0 10px;
    }
    .upcoming-body-inner{
      padding: 8px 10px;
    }
    .upcoming-body{
      height: 204px;
    }
  }

  @media(min-width: 992px){
    background: #FFFFFF;
    .main-layout{
      margin-left: 36%;

      .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #51635D;
        display: flex;
        justify-content: center;
        margin: 29px;
      }

      .section1{
        gap: 22px;
        margin-top: 10px;
        .section1-inner{
          width: 291px;
          height: 93px;
          border-radius: 10px;
          padding: 14px;

          .price-card-wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            .price-wrapper{
              display: flex;
              flex-direction: column;
              margin-top: 16px;
              align-items: flex-start;
            }
          }

          img{
            width: 40px;
            height: 40px;
          }
          .text2 {
            font-size: 41px;
            line-height: 46px;
          }
        }
        .section2-inner{
          width: 291px;
          height: 93px;
          background: rgb(240, 250, 235);
          border-radius: 10px;
          padding: 14px;

          .price-card-wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            .price-wrapper{
              display: flex;
              flex-direction: column;
              margin-top: 16px;
              align-items: flex-start;

              .text-2{
                font-size: 41px;
                line-height: 46px;
              }
            }
          }

          img{
            width: 40px;
            height: 40px;
          }

          .text2 {
            font-size: 41px;
            line-height: 44px;
          }
        
        }
      }

      .section2{
        border-bottom: none;
        padding-bottom: 18px;

        .upcoming-coaching-disbursement{
          padding-bottom: 24px;
          font-size: 24px;
          line-height: 28px;
        }
        .upcoming-disbursement{
          font-size: 24px;
          line-height: 28px;
        }
        .history{
          font-size: 24px;
          line-height: 28px;
          font-weight: 700;
        }
        .cust-table{
          .no-data{
            text-align:center;
            height: 118px;
         }
          .cust-header {
            justify-content: space-between;
            padding: 0 20px 0 20px;
            align-items: flex-end;
          }
          .upcoming-body{
            height: 235px;
            .upcoming-body-inner{
              width: 100%;
              height: auto !important;
              padding: 12px 20px 12px 20px;
            }
          }
          .cust-body{
            height: 100%;
            .upcoming-body-inner{
              height: auto;
              width: 100%;
              padding: 12px 20px 12px 20px;

              .body-inner-wrapper{
                min-width: 111px;
                max-width: fit-content;
                display: flex;
                gap: 2px;

                span{
                  img{
                    width: 16px;
                    height: 16px;
                    objectFit: cover;
                    border-radius: 50%;
                  }
                }
              }
              .isvoid{
                img{
                  width: 45px;
                  height: 12px;
                  display: flex;
                }
              }
            }
            .upcoming-body-inner :nth-child(5n) {
              color:#52C41A;
              font-weight: 700;
            }
            .upcoming-body-inner :nth-child(4n) {
              color: #51635D;
              font-weight: 400;
            }
          }
          .coming-body{
            .upcoming-body-inner{
              width: 100%;
              padding: 12px 20px 12px 20px;
              .body-inner-wrapper{
                min-width: 75px;
                max-width: fit-content;
                display: flex;
                gap: 2px;

                span{
                  img{
                    width: 16px;
                    height: 16px;
                    objectFit: cover;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
          .mealPlanReport span{
            &:last-child{
              width: 40%;
            }
          }
          .history-body span{
            &:nth-last-child(2){
              width: 45%;
            }
            &:last-child{
              width: 40%;
            }
          }
          .coachingReport span{
            &:last-child{
              width: 50%;
            }
          }
        }
        .show{
          .show-more{
            height: 38px;
            cursor: pointer;
          }
        }
      }
    }
    .request{
      display:flex;
      justify-content:flex-end;
      button{
        width: 171px;
        height: 40px;
        background: #52C41A;
        border-radius: 21px;
        color:#ffffff;
        cursor:pointer;
        position:relative;
        top:-60px;
        font-weight: 600;
      }
    }
  }

  .__react_component_tooltip{
    width: 200px !important;
    word-break: break-all;
    @media(min-width:992px){
       width:300px !important;
    }
   }
`;
export default function NutritionistReportsPayTable(props) {
 
  const [disbursementsList,setDisbursementsList]=useState(false);
  // disbursementsList && window.scroll(0,0)
  const {isMobile} = useAppContext();
  const isDesktop = !isMobile;
  const disbursements = props?.disbursements?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
  const newdisbursementsList=disbursementsList?disbursements:disbursements.slice(0,5)
  const pay = props?.pay;
  const [requestPayment,{isLoading:isRequestPaymentLoading}] = useRequest('nutritionist/requestPayment', 'post');
  const Historydisbursements = props?.pay?.upcomingDisbursements.reduce((a, c) => {
    c.disbursementList.forEach((item) => {
      a.push(item);
    });
    return a;
  }, [])?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
  
  const handleReqPayButtonClick = async(currentBalance, requestFor)=> {
    await requestPayment({
      body: {requestedAmount: currentBalance,
             requestFor: requestFor}
    }).then((res) => {
       if(res.success == true){
        toast.success(res.message)
       }
    }) .catch((e) => {toast.error("Something went wrong")})
  };

  const disableRequestButton = isRequestPaymentLoading || !pay?.currentBalance;

  const [upcomingDisbursementsList,setUpcomingDisbursementsList]=useState(false);
  // upcomingDisbursementsList && window.scroll(0,0)
  const newupcomingdisbursementsList = upcomingDisbursementsList?Historydisbursements:Historydisbursements.slice(0,5);

  const [style, setStyle] = useState("upcoming-body"); 
  const changeStyle = () => {
    if(style==='upcoming-body'){
      setStyle("upcoming-scroll-body")
    } else if(style==='upcoming-scroll-body'){
      setStyle("upcoming-body")
    }
  };
  const [isstyle, setIsstyle] = useState("coming-body"); 
  const changeisStyle = () => {
    setIsstyle("coming-scroll-body");
  }; 

  useEffect(()=>{
    ReactTooltip.rebuild();
  },[])

  const UpcomingDisbursement = () => {
      return(
          <div className="section2" data-testid="future-disbursements">
            <div className={props.isClient ? 'upcoming-disbursement' : 'upcoming-coaching-disbursement'}>
              <p className="p_10">Upcoming Disbursements</p>
              </div>
              <div className="cust-table">
                {(pay.upcomingDisbursements?.length>=1 && newupcomingdisbursementsList?.length>1) ?
                   <div className={`cust-header ${props.isClient ? 'mealPlanReport':'coachingReport'}`}>
                   {props.isClient ? <span>{isMobile ? "Created" : "Created date"}</span> : <span style={{width: '80%', maxWidth: '80%'}}>{isDesktop && "Client Name"}</span>}
                    {props.isClient ? <span>{isMobile ? "Start" : "Period start"}</span> : <span>{isDesktop && "Created date"}</span>}
                    {props.isClient ? <span>{isMobile ? "Period end" : "Period end"}</span> : <span>{isDesktop && "Date of Session"}</span>}
                    {props.isClient ? <span> Amount</span> : <span>{isDesktop && "Amount"}</span>}
                  </div>
                  :
                  ''
                 }
                 
          {pay.upcomingDisbursements?.length === 0 ? (
            <div className="no-data">No Data Available</div>
          ) : props.isClient ? (
            <div  className={style}>
              {pay.upcomingDisbursements?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))?.map((item, i) => (
                <div className="upcoming-body-inner mealPlanReport">
                  <span data-testid="future-disbursements-date">
                    {isMobile ? moment(item?.createdAt).utc().format('MMM DD,YY') :
                    moment(item?.createdAt).utc().format('MMMM DD YYYY, h:mm a')}
                  </span>
                  <span data-testid="future-disbursements-periodstart">
                    {isMobile ? moment(item?.periodStart).utc().format('MMM DD,YY') : 
                    moment(item?.periodStart).utc().format('MMMM DD YYYY, h:mm a')}
                  </span>
                  <span data-testid="future-disbursements-periodend">
                    {isMobile ? moment(item?.periodEnd).utc().format('MMM DD,YY') : 
                    moment(item?.periodEnd).utc().format('MMMM DD YYYY, h:mm a')}
                  </span>
                  <span className='amount' data-testid="future-disbursements-amount">
                    ${(item.total / 100).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className={isstyle} >
               {newupcomingdisbursementsList.map((item) => {
                if (isDesktop) {
                  return (
                    <>
                    <div className="upcoming-body-inner coachingReport">
                      <div className='body-inner-wrapper' style={{width: '80%', maxWidth: '80%'}}>
                        <span data-testid="future-disbursements-periodend" style={{width: 'auto'}}>
                          <img src={item?.nutritionistMeeting?.user?.profile.img || '/images/avatar.png'} alt='user image' />
                        </span>
                        <span className='summary-name' style={{width: 'auto'}} data-testid="history-disbursements-date">
                        {item?.nutritionistMeeting?.user?.profile.name.length >20 ? <p data-tip={item?.nutritionistMeeting?.user?.profile.name} data-for="name" style={{cursor: 'pointer'}}>{item?.nutritionistMeeting?.user?.profile.name.substring(0, 20) + "..." }</p>
                       :
                       <p>{item?.nutritionistMeeting?.user?.profile.name}</p>
                       }
                        </span>
                      </div>
                      <span data-testid="future-disbursements-periodstart">
                        {moment(item?.createdAt).utc().format('MMMM DD YYYY, h:mm a')}
                      </span>
                      <span data-testid="future-disbursements-periodend">
                        {moment(
                          item?.nutritionistMeeting?.NutritionistMeetingMapping[0].scheduledDateTime,
                        )
                          .utc()
                          .format('MMMM DD YYYY, h:mm a')
                        }
                      </span>
                      <span className='amount' data-testid="future-disbursements-amount">
                        ${Number(item?.amount / 100).toFixed(2)}
                      </span>
                    </div>
                    <ReactTooltip id="name" place="top" effect="solid">
                  </ReactTooltip>
                    </>
                  )
                }
                  return (
                    <>
                  <div className="upcoming-coaching-body-inner">
                      <Accordion >
                      <AccordionSummary
                       expandIcon={<img src='/icons/hide.svg'/>}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                      >
                       <div className='name-amount'> 
                       <div className='name-img'>
                       <span className='user-img' data-testid="future-disbursements-periodend">
                     <img src={item?.nutritionistMeeting?.user?.profile.img || '/images/avatar.png'} alt='user image'/> 
                    </span>
                  
                    <span className='name' data-testid="future-disbursements-periodend">
                      {item?.nutritionistMeeting?.user?.profile.name.length >20 ? <p data-tip={item?.nutritionistMeeting?.user?.profile.name} data-for="name" style={{cursor: 'pointer'}}>{item?.nutritionistMeeting?.user?.profile.name.substring(0, 20) + "..." }</p>
                       :
                       <p>{item?.nutritionistMeeting?.user?.profile.name}</p>
                       }
                    </span>
                    </div>
                    <span className='amount' data-testid="future-disbursements-amount">
                      $
                      {Number(
                        item?.amount/100
                      ).toFixed(2)}
                    </span>
                    </div>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div className="accordion-body-inner">
                    <div className='start-end'>
                    <div className='start'>
                    <span className='start-text'>Created:</span> 
                    <span className='start-span' data-testid="future-disbursements-date">
                      {moment(item?.createdAt).utc().format('ll')}
                    </span>
                    </div>
                    <div className='end'>
                    <span className='end-text'>Session:</span> 
                    <span className='end-span' data-testid="future-disbursements-periodstart">
                      {moment(
                        item?.nutritionistMeeting?.NutritionistMeetingMapping[0].scheduledDateTime,
                      )
                        .utc()
                        .format('ll|hh:mm A')}
                    </span>
                    </div>
                    </div>
                    </div>
                    </AccordionDetails>
                    </Accordion>
                  </div>
                  <ReactTooltip id="name" place="top" effect="solid">
                  </ReactTooltip>
                  </>
                )})
                }
            </div>
          )}
        </div>
        {pay.upcomingDisbursements?.length>5 ?
          <div className='show'>
          {props?.isClient ?
           <div onClick={changeStyle} className='show-more'>
           <p>{ style==="upcoming-body" ?"Show more":"Show less"}</p>
         </div>
         :
         <div onClick={()=>setUpcomingDisbursementsList(!upcomingDisbursementsList)} className='show-more'>
         <p>{!upcomingDisbursementsList ? "Show more" : "Show less"}</p>
         </div>
          }
       
        </div>    
        :
        ''
      }
          
      </div>
    );
  };

  const DisbursementsHistory = () => {
    return (
      <div className="section2">
        <div className='history'>
        <h3 className="p_10">History</h3>
        </div>
        <div className="cust-table">
            {isDesktop && disbursements?.length>=1 ?
              <div className={`cust-header ${props.isClient ? 'mealPlanReport':'coachingReport'}`}>
                   {props.isClient ? <span style={{width: '30%'}}>Id</span> : <span style={{width: '80%', maxWidth: '80%'}}>Client Name</span>}
                    {props.isClient ? <span>Payment date</span> : <span style={{width: '50%', maxWidth: '50%'}}>Id</span>}
                    {props.isClient ? <span>Period Start</span> : <span>Meeting date</span>}
                    <span style={{width: '100%'}}>Period end</span>
                    {props.isClient ? <span style={{width: '45%'}}>Paid Amount</span> : <span style={{width: '45%'}}>Amount</span>}
                    {props.isClient ? <span>Voided</span> : ''}
              </div>
              :
              ''
            }
          {!disbursements || disbursements?.length == 0 ? (
            <div className="no-data">No Data Available</div>
          ) : props.isClient ? (
            <div className='cust-body mealPlanReport'>
              {disbursements
                ? newdisbursementsList.map((d, i) => {
                  if(isDesktop){
                    return (
                      <div className="upcoming-body-inner history-body">
                        <span style={{width: '30%'}} data-testid="future-disbursements-date">{d.id}</span>
                        <span data-testid="future-disbursements-date">
                          {d.datePaid ? moment(d.datePaid).utc().format('MMMM DD YYYY, h:mm a') : 'N/A'}
                        </span>
                        <span data-testid="future-disbursements-date">{moment(d.periodStart).utc().format('MMMM DD YYYY, h:mm a')}</span>
                        <span data-testid="future-disbursements-date">{moment(d.periodEnd).utc().format('MMMM DD YYYY, h:mm a')}</span> 
                        <span data-testid="future-disbursements-date">${(d.paidAmount / 100).toFixed(2)}</span>
                        <span data-testid="future-disbursements-date" className='isvoid'>
                          {d.isVoid ?
                            <img src='/icons/voided.svg' alt='voided' /> :'N/A'
                          }
                        </span>
                      </div>
                    )
                  }
                return(
                  <div className='history-disbursement'>
                  <div>
                  {isMobile ?<Accordion >
                    <AccordionSummary
                        expandIcon={<img src='/icons/hide.svg'/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{expanded:'expandedStyle'}}
                      >
                        <div>{d.isVoid &&
                          <span className='voided'>
                            <img src='/icons/voided-circle.svg'/>
                          </span>}
                        </div>
                        <div className='id-amount'>
                        <span className='span' data-testid="history-disbursements-date" style={{ width: '200px' }}>
                        <p className='id-text'>ID:</p> <p className='id'>{d.id}</p>
                      </span>
                      <span className='ammount' data-testid="history-disbursements-amount">
                        ${(d.paidAmount / 100).toFixed(2)}
                      </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div className="cust-body-inner">
                    <div className='start-end'>
                      <div className='start'>
                    {props.isClient ? <span className='start-text'>Start:</span> : <span className='start-text'>Client Name:</span>}
                    <span className='start-span' data-testid="history-disbursements-periodstart">
                        {moment(d.periodStart).utc().format('ll')}
                      </span>
                      </div>
                      <div className='end'>
                    {props.isClient ? <span className='end-text'>End:</span> : <span className='end-text'>Meeting Date:</span>}
                      <span className='end-span' data-testid="history-disbursements-periodend">
                        {moment(d.periodEnd).utc().format('ll')}
                      </span>
                      </div>
                      </div>
                      <div className='paid'>
                      <div className='start-end'>
                        <span className="isVoid">
                          {d.isVoid && <img src='/icons/voided.svg' alt='voided' />}
                        </span>
                      </div>  
                      <div className='end'>
                      <span className='paid-text'>Paid:</span>
                      <span className='paid-span' data-testid="history-disbursements-date">
                        {d.datePaid ? moment(d.datePaid).utc().format('ll') : 'N/A'}
                      </span>
                      </div>
                      </div>
                    </div>
                    </AccordionDetails>
                  </Accordion> : ""}
                    </div>
                    </div>
                  )})

                : ' '}
            </div>
          ) : (
            <div className="cust-body coachingReport">
              {newdisbursementsList.map((item) => {
                if (isDesktop) {
                  return (
                    <>
                    <div className="upcoming-body-inner coachingReport">
                      <div className='body-inner-wrapper' style={{width: '80%', maxWidth: '80%'}}>
                        <span data-testid="future-disbursements-periodend" style={{width: 'auto'}}>
                          <img src={item?.nutritionistMeeting?.user?.profile.img || '/images/avatar.png'} alt='user image' />
                        </span>
                        <span className='summary-name' style={{width: 'auto'}} data-testid="history-disbursements-date">
                        {item?.nutritionistMeeting?.user?.profile.name.length >15 ? <p data-tip={item?.nutritionistMeeting?.user?.profile.name} data-for="history-name" style={{cursor: 'pointer'}}>{item?.nutritionistMeeting?.user?.profile.name.substring(0, 15) + "..." }</p>
                       :
                       <p>{item?.nutritionistMeeting?.user?.profile.name}</p>
                       }
                        </span>
                      </div>
                      <span data-testid="future-disbursements-date" style={{width: '50%', maxWidth: '50%'}}>
                        {item.id}
                      </span>
                      <span data-testid="future-disbursements-date">
                        {moment(
                          item?.nutritionistMeeting?.NutritionistMeetingMapping[0]?.scheduledDateTime)
                          .utc()
                          .format('MMMM DD YYYY, h:mm a')}</span>
                      <span data-testid="future-disbursements-date">{moment(item.periodEnd).utc().format('MMMM DD YYYY, h:mm a')}</span>
                      <span data-testid="future-disbursements-date">
                        ${Number(
                          item?.amount / 100
                        ).toFixed(2)}</span>
                    </div>
                    <ReactTooltip id="history-name" place="top" effect="solid">
                    </ReactTooltip>
                    </>
                  )
                }
                return (
                  <>
                 <div className='history-disbursement'>
                  <div>
                  <Accordion>
                  <AccordionSummary
                        expandIcon={<img src='/icons/hide.svg'/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{expanded:'expandedStyle'}}
                      >
                        <div className='history-summary'>
                          <div className='name-img'>
                      <span className='user-img' data-testid="future-disbursements-periodend">
                     <img src={item?.nutritionistMeeting?.user?.profile.img || '/images/avatar.png'} alt='user image'/> 
                    </span>
                     <span className='summary-name' data-testid="history-disbursements-date">
                     {item?.nutritionistMeeting?.user?.profile.name.length >15 ? <p data-tip={item?.nutritionistMeeting?.user?.profile.name} data-for="history-name" style={{cursor: 'pointer'}}>{item?.nutritionistMeeting?.user?.profile.name.substring(0, 15) + "..." }</p>
                       :
                       <p>{item?.nutritionistMeeting?.user?.profile.name}</p>
                       }
                  </span>
                  </div>
                  <span className='summary-id' data-testid="history-disbursements-date" style={{ width: '200px' }}>
                  <p className='id-text'>ID:</p> <p className='id'>{item.id}</p>  
                  </span>

                  <span className='summary-amount' data-testid="future-disbursements-amount">
                    $
                    {Number(
                      item?.amount/100
                    ).toFixed(2)}
                  </span>
                  </div>
                  </AccordionSummary>
                   <AccordionDetails>
                   <div className="meeting-body-inner">
                   <div className='start-end'>
                    <div className='meeting'>
                   <span className='meeting-text'>Meeting-time:</span>
                  <span className='meeting-date' data-testid="history-disbursements-date">
                    {moment(
                      item?.nutritionistMeeting?.NutritionistMeetingMapping[0]?.scheduledDateTime,
                    )
                      .utc()
                      .format('ll|hh:mm A')}
                  </span>
                  </div>
                  <div className='paiid'>
                  <span className='paiid-text'>Paid:</span> 
                  <span className='paiid-date' data-testid="history-disbursements-date">
                    {moment(item.datePaid).utc().format('ll')}
                  </span>
                  </div>
                  </div>
                 {item.isVoid ? 
                      <div className='isvoid'>
                      <span className='void' data-testid="history-disbursements-amount" style={{ width: '200px' }}>
                       <span>VOIDED</span> 
                      </span>
                      </div>
                      :
                      '' 
                    } 
                  </div>
                   </AccordionDetails>
                  </Accordion>
                  </div>
                </div>
                <ReactTooltip id="history-name" place="top" effect="solid">
               </ReactTooltip>
                </>
                  )})}
            </div>
          )}
        </div>
       
       {disbursements.length>5 ?
        <div className='show'>
        <div onClick={()=>setDisbursementsList(!disbursementsList)} className='show-more'>
          <p>{disbursementsList?"Show less":"Show more"}</p>
        </div>
        </div>
        :
        ''
      }
         
       
       
      </div>
    );
  };
  return (
    <NutritionistReportsContainer>
      <div className="main-layout">
        {isDesktop &&
        <>
        <div className='title'>Reports: {props.isClient? "Meal Plans" : "Coaching" }</div>
        </>
        }
        <span className={isDesktop ?'request' : 'payment'}>
        <button className={`${isRequestPaymentLoading && "request-payment-disabled"}`}  disabled={disableRequestButton} onClick={()=>handleReqPayButtonClick(pay?.currentBalance, props?.isClient)}>Request Payment</button>
        </span>
        <div className="section1">
          <div className="section1-inner">
            <div className='price-card-wrapper'>
            <div className={isDesktop ? 'price-wrapper' : 'price-mobile'}>
            <span className="text1">Current Balance</span>
            <span className="text2"> 
              $
              {props.isClient
                ? `${(pay?.currentBalance / 100).toFixed(2)}`
                : `${(pay?.currentBalance/100).toFixed(2)}`}
            </span>
            </div>
            <div>
            {isDesktop &&<img src="/icons/Current-balance.svg" alt="Current balance icon" />}
            </div>
            </div> 
          </div>
          <div className="section2-inner">
            <div className='price-card-wrapper'>
            <div className={isDesktop ? 'price-wrapper' : "price-mobile"}>
            <span className="text-1">Total Earned</span>
            <span className="text-2">
              $
              {props.isClient
                ? `${(pay?.totalEarnings?._sum?.amount / 100).toFixed(2)}`
                : `${
                    pay?.totalEarnings?._sum?.amount
                      ? (pay?.totalEarnings?._sum?.amount/100).toFixed(2)
                      : '0.00'
                  }`}
            </span>
            </div>
            <div>
            {isDesktop &&<img src="/icons/Total-earned.svg" alt="Total earned icon" />}
            </div>
            </div>
          </div>

        </div>
        <UpcomingDisbursement />
        <DisbursementsHistory />
      </div>
    </NutritionistReportsContainer>
  );
}
