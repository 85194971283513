import { usePersistedState } from 'hooks/usePersistedState';
import { useRequest } from 'hooks/useRequest';
import {createContext, useContext, useEffect, useState} from 'react';
import { useAppContext } from './appContext';
import {useLocation,useParams} from "react-router-dom";
import produce from "immer";

const NutritionistContext = createContext({});

const NutritionistContextProvider = ({ children, values = {} }) => {
  const mealDataString = localStorage.getItem('customMealData');
  const mealData = mealDataString ? JSON.parse(mealDataString) : null;
  const { user, isSuper } = useAppContext();
  const imitateNutritionistString = localStorage.getItem('imitateNutritionist');
  const imitateNutritionist = imitateNutritionistString ? JSON.parse(imitateNutritionistString) : null;
  const [nutritionist, updateNutritionist] = usePersistedState('nutritionist', {});
  const [selectedOfferDetails, updateSelectedOfferDetails] = usePersistedState('selectedOfferDetails', {});
  const [inviteFormData, setInviteFormData] = usePersistedState('inviteFormData', null);
  const [getOffer, {error:offerError}] = useRequest();
  const [offerData, setOfferData] = usePersistedState('offerData', {});
  const [activeClients, setActiveClients] = usePersistedState('activeClients', []);
  const params = new URLSearchParams(useLocation().search);
  const [customMealData, setCustomMealData] = useState(mealData ? mealData : []);
  const [pantryMeals, setPantryMeals] = useState([]); 
  const [getActiveClients,{isLoading:isActiveClientsLoading}] = useRequest();
  const [pay, setPay] = useState();
  const [disbursements, setdisbursements] = useState();
  const [isClient,setIsClient] = useState(false);
  const [offeringListData, setOfferingListData] = useState([]); 
  const [followersList, setFollowersList] = useState([]);
  const [getFollowers, {isLoading: isFollowersLoading}] = useRequest();
  const [mostPopularMealPlan,setMostPopularMealPlan]= useState({});
  const [clientcart, updateClientcart] = usePersistedState('clientcart', []);
  const [clientcartCount, setClientcartCount] = useState(0);
  const [freshMealsProvider, setFreshMealsProvider] = useState({});
  const { profile } = useAppContext();
  const [firstName, lastName] =( profile?.name ? profile?.name : 'Visitor').split(' ');
  const [allTemplates, setAllTemplates] = usePersistedState('allTemplates', []);
  const [getDietTemplates, { isLoading: isDietPlanLoading }] = useRequest();
  const [availableProviders, setAvailableProviders] = usePersistedState('availableProviders', {
    data: [],
    expiry: new Date().getTime() + (5 * 60 * 1000)
});
  const [coachingPay, setCoachingPay] = useState();
  const [CoachingUpcomingDisbursement,{error: coachingError,isLoading:isCoachingPayLoading}] = useRequest(
    `financials/upcoming-coaching-disbursement/${nutritionist?.nutritionist?.id}`,
  );
   
  useEffect(async () => {
    if(type?.includes("nutritionist")|| type?.includes("super")){
    if (nutritionist?.nutritionist?.id) {
      await CoachingUpcomingDisbursement().then((res) => {
        setCoachingPay(res);
      });
    }
  }}, [nutritionist?.nutritionist?.id]);

  useEffect(() => {
      localStorage.setItem('customMealData', JSON.stringify(customMealData));
  let updatedpantrymeals = pantryMeals
  setPantryMeals(updatedpantrymeals);
  console.log('updatedpantrymeals..',{updatedpantrymeals})
 } , [customMealData]);

 useEffect(() => {
    if(offerError){
      setOfferData({
        offerError
      })
    }
  } , [offerError]);

  const [clientsettings, updateClientsettings] = usePersistedState('clientsettings', {
    days: 8,
    meals: 3,
    provider: null,
    providerSettings: [], //{providerId, method ["pickup/delivery"], date, time,note,name,schedule}
    userInfo: {
      firstName: firstName || '',
      lastName: lastName || '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '' || '',
      userAddressLatLong: {},
    },
    mealPlan: [],
    pantryDatesCalories: [],
  });
 
  const getNutritionistUrl =
    isSuper && imitateNutritionist
      ? `nutritionist/nutritionistId/${imitateNutritionist.id}`
      : `nutritionist/userId/${user?.id}`;
  const [getNutritionist,{ isLoading }] = useRequest(getNutritionistUrl);

  const [upcomingDisbursement, { isLoading:isLoadingDisbursement, error }] = useRequest(
    `nutritionist/upcoming-disbursement/${nutritionist?.nutritionist?.id}`,
  );

  const [getDisbursementsHistory, { isLoading: isLoaded, error: err }] = useRequest(
    `nutritionist/disbursement-history/${nutritionist?.nutritionist?.id}`,
  );
  const type=user?.type;

  useEffect(async () => {
  if(type?.includes("nutritionist")|| type?.includes("super")){
    if (user?.id || imitateNutritionist?.id) {
      const response = await getNutritionist();
      updateNutritionist({
        ...response,
      });
    } }
  }, []);

  const getFollowersFn = async(reqId) => {
    try{
    const followersUrl = `nutritionist/getFollowers/${reqId}`;
    const res = await getFollowers({
        path: `${followersUrl}`
    });
        if(res?.users){
            setFollowersList(res?.users);
        }
} catch(error) {
  console.log(error)
}
  }
const [getMostPopularMealPlan,{isLoading:ismostPopularMealPlanLoading}] = useRequest(
  `nutritionist/mostPopularMealPlan/${nutritionist?.nutritionist?.id}`,
);

useEffect(async () => {
     if(type?.includes("nutritionist")|| type?.includes("super")){
      const imitateNutritionist = localStorage.getItem('imitateNutritionist');
      const reqId = isSuper && imitateNutritionist ? JSON.parse(imitateNutritionist)?.id : user?.nutritionistProfile?.id;
    getFollowersFn(reqId);
  }
    
}, []);

useEffect(() => {
     if(type?.includes("nutritionist")|| type?.includes("super")){
      const imitateNutritionist = localStorage.getItem('imitateNutritionist');
      const reqId = isSuper && imitateNutritionist ? JSON.parse(imitateNutritionist)?.id : user?.id;
  const isNutritionistId = isSuper ? 'true' : 'false';
  const getDietTemplatesUrl = `nutritionist/getDietPlansSummary/${reqId}?isNutritionistId=${isNutritionistId}`;
  if (reqId) {
    getDietTemplates({
      path: `${getDietTemplatesUrl}`,
    }).then((templates) => {
      setAllTemplates(templates.data);
    });
  }  }
}, []);

    const getInitialData = (reqId, token) => {
        const getOfferPlan = `coachingOffer/offer/booking/${reqId}?token=${token}`;
        getOffer({
            path: `${getOfferPlan}`,
        }).then((data) => {
            setOfferData({...data, token});
        })
    };

     const getActiveClientsFn = (reqId) => {
    const path = `coachingOffer/getActiveClients/${reqId}`;
      getActiveClients({ path, method: 'GET' })
      .then((data) => {
        if (data.length < 1) {
          setActiveClients([])
        } else {
          const filtereByDate = data?.sort((a, b) => {
            return new Date(a?.[0]) - new Date(b?.[0])
          });
          setActiveClients(filtereByDate);
        }
      }
      ).catch(console.log);
  };

    useEffect(async () => {
        const offerId = params.get('offerId');
        const clientEmail = params.get('clientEmail');
        const userId = params.get('userId');
        const token = params.get('token');
        const isUserExist = params.get('isUserExist');
        if (offerId) {
            updateSelectedOfferDetails({
                ...selectedOfferDetails,
                clientEmail,
                userId,
                isUserExist
            })
            getInitialData(offerId, token);
        }
    }, []);
    
    useEffect(async () => {
      if(type?.includes("nutritionist")|| type?.includes("super")){
        const imitateNutritionist = localStorage.getItem('imitateNutritionist');
        const reqId = isSuper && imitateNutritionist ? JSON.parse(imitateNutritionist)?.userId : user?.id;
      getActiveClientsFn(reqId)
    }}, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(type?.includes("nutritionist")|| type?.includes("super")){
          if (nutritionist?.nutritionist?.id) {
            const [upcomingDisbursements, disbursementsHistory, mostPopularMealPlan] = await Promise.all([
              upcomingDisbursement(),
              getDisbursementsHistory(),
              getMostPopularMealPlan(),
            ]);
            setPay(upcomingDisbursements);
            setdisbursements(disbursementsHistory);
            setMostPopularMealPlan(mostPopularMealPlan);
          }
         }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, [nutritionist?.nutritionist?.id]);

    useEffect(() => {
      const mealProvideArray = clientcart?.map(({providerId}) => providerId)
          .filter(function onlyUnique(value, index, self) {
              return self.indexOf(value) === index;
          });
      updateClientsettings(
          produce((draft) => {
              const updatedProviderSettings = availableProviders?.data?.filter((provider) => {
                  const isProviderMealExist = mealProvideArray?.includes(provider.id);
                  return isProviderMealExist;
              }).map(item=>({
                ...item,
                  address: item.address,
                  date: Date.now(),
                  deliveryCoverage: item.deliveryCoverage,
                  deliverableAreas: item.deliverableAreas,
                  minOrderAmount: item.minOrderAmount,
                  name: item.name,
                  providerId: item.id,
                  schedule: item.schedule
              }));
              draft.providerSettings = updatedProviderSettings;
          }),
      );
  }, [clientcart]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < clientcart.length; i++) {
      count += clientcart[i].qty;
    }
    setClientcartCount(count);
  }, [clientcart]);

  return (
    <NutritionistContext.Provider
      value={{
        nutritionist,
        updateNutritionist,
        selectedOfferDetails,
        updateSelectedOfferDetails,
        offerData,
        activeClients,
        disbursements,
        pay,
        isLoaded,
        error,
        isLoading,
        ...values,
        inviteFormData, 
        setInviteFormData,
        customMealData,
        setCustomMealData,
        setPantryMeals,
        pantryMeals,
        mealData,
        setIsClient,
        isClient,
        setOfferingListData,
        offeringListData,
        setFollowersList,
        followersList,
        isFollowersLoading,
        mostPopularMealPlan,
        getActiveClientsFn,
        clientcart,
        clientcartCount,
        updateClientcart,
        freshMealsProvider,
        setFreshMealsProvider,
        clientsettings,
        updateClientsettings,
        allTemplates,
        setAllTemplates,
        isDietPlanLoading,
        availableProviders,
        setAvailableProviders,
        coachingPay,
        coachingError,
        ismostPopularMealPlanLoading,
        isCoachingPayLoading,
        isActiveClientsLoading,
        imitateNutritionist,
        isLoadingDisbursement
      }}
    >
      {children}
    </NutritionistContext.Provider>
  );
};

const useNutritionistContext = () => useContext(NutritionistContext);

export { NutritionistContextProvider, useNutritionistContext };