import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { useHistory } from 'react-router-dom';
import { theme } from 'shared/theme/theme';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { GilroyFontWrapper } from 'shared/theme/style';
import OvoVegetarianSvg from 'components/svg/OvoVegetarianSvg';
import VegetarianSvg from 'components/svg/VegetarianSvg';
import Vegan from 'components/svg/Vegan';
import Kosher from 'components/svg/Kosher';
import LactoVegetarian from 'components/svg/LactoVegetarian';
import HighProtein from 'components/svg/HighProtein';
import Ketogenic from 'components/svg/Ketogenic';
import LowCarb from 'components/svg/LowCarb';
import Halal from 'components/svg/Halal';
import Paleo from 'components/svg/Paleo';
import { Button } from 'components/elements/button/Button';
import MultiScreenStyle from '../healthGoal/MultiScreenStyle';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import arraysAreEqual from 'utils/equalArrays';
import { useAppContext } from 'context/appContext';

const FoodPreferencesStyle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  .containerr {
    padding: 24px;
    padding-bottom: 115px;
    height: ${({window}) => window?.innerHeight - 48}px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item_list {
      margin: 6px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .save__btn {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    z-index: 150;
  }
`;

const FoodPreferences = ({ setActiveModalStack, modal }) => {
  const { saveHealthGoal, healthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const [selectedPreferences, setSelectedPreferences] = useState(
    healthGoal?.selfMealPlan?.dietaryPreferences || []
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const isSaveDisabled = arraysAreEqual(healthGoal?.selfMealPlan?.dietaryPreferences, selectedPreferences) || isDisabled;
  const { isVisitor } = useAppContext();

  useEffect(() => {
    setUpdatedHealthGoal((prev) => ({
      ...prev,
      selfMealPlan: { ...prev.selfMealPlan, dietaryPreferences: selectedPreferences?.map((item) => item?.toUpperCase()) },
    }));
  }, [selectedPreferences]);

  const preferences = [
    {
      id: 0,
      title: 'Ovo-vegetarian',
      icon: <OvoVegetarianSvg color={theme?.colors?.grayDarker} />,
      activeIcon: <OvoVegetarianSvg color={'#51635D'} />,
      value: 'OVO-VEGETARIAN'
    },
    {
      id: 1,
      title: 'Vegetarian',
      icon: <VegetarianSvg color={theme?.colors?.grayDarker} />,
      activeIcon: <VegetarianSvg color={'#51635D'} />,
      value: 'VEGETARIAN'
    },
    {
      id: 2,
      title: 'Vegan',
      icon: <Vegan color={theme?.colors?.grayDarker} />,
      activeIcon: <Vegan color={'#51635D'} />,
      value: 'VEGAN'
    },
    {
      id: 3,
      title: 'Kosher',
      icon: <Kosher color={theme?.colors?.grayDarker} />,
      activeIcon: <Kosher color={'#51635D'} />,
      value: 'KOSHER'
    },
    {
      id: 4,
      title: 'Lacto-vegetarian',
      icon: <LactoVegetarian color={theme?.colors?.grayDarker} />,
      activeIcon: <LactoVegetarian color={'#51635D'} />,
      value: 'LACTO-VEGETARIAN'
    },
    {
      id: 5,
      title: 'High-protein',
      icon: <HighProtein color={theme?.colors?.grayDarker} />,
      activeIcon: <HighProtein color={'#51635D'} />,
      value: 'HIGH-PROTEIN'
    },
    {
      id: 6,
      title: 'Ketogenic',
      icon: <Ketogenic color={theme?.colors?.grayDarker} />,
      activeIcon: <Ketogenic color={'#51635D'} />,
      value: 'KETOGENIC'
    },
    {
      id: 6,
      title: 'Low carb',
      icon: <LowCarb color={theme?.colors?.grayDarker} />,
      activeIcon: <LowCarb color={'#51635D'} />,
      value: 'LOW-CARB'
    },
    {
      id: 7,
      title: 'Halal',
      icon: <Halal color={theme?.colors?.grayDarker} />,
      activeIcon: <Halal color={'#51635D'} />,
      value: 'HALAL'
    },
    {
      id: 8,
      title: 'Paleo',
      icon: <Paleo color={theme?.colors?.grayDarker} />,
      activeIcon: <Paleo color={'#51635D'} />,
      value: 'PALEO'
    },
  ];

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack([]);
  };

  return (
    <>
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ x: '100%', opacity: 0 }}
        transition={{ duration: 0.7, type: 'spring' }}
      >
        <GilroyFontWrapper>
          <MultiScreenStyle>
            <FoodPreferencesStyle window={window} theme={theme}>
              <MobileHeader onClickLeft={() => setActiveModalStack([])} text={'Food preferences'} />
              <div className="containerr">
                {preferences?.map((item, index) => (
                  <div key={index} className="item_list">
                    <CheckboxCard
                      type={'title_only'}
                      value={item?.value.toUpperCase()}
                      title={item?.title}
                      icon={item?.icon}
                      activeIcon={item?.activeIcon}
                      checked={selectedPreferences?.includes(item?.value.toUpperCase())}
                      selectedValue={selectedPreferences}
                      setSelectedValue={setSelectedPreferences}
                      onClick={() => setIsDisabled(false)}
                    />
                  </div>
                ))}
              </div>
              <div className="button_holder">
                <Button
                  type={'mobile_save'}
                  title={'Save'}
                  onClick={handleSave}
                  isDisabled={isSaveDisabled}
                />
              </div>
            </FoodPreferencesStyle>
          </MultiScreenStyle>
        </GilroyFontWrapper>
      </motion.div>
    </>
  );
};

export default FoodPreferences;
