import styled from 'styled-components';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import useCart from 'hooks/useCart';
import { useAppContext } from 'context/appContext';
import Calories from '../SharedComponents/Calories';
import useDisplayAmount from 'hooks/useDisplayAmount';
import ImageViewer from '../../../components/ImageViewer';

const ProviderCardAddedMainDiv = styled.div`
  .cart-listing3 {
    display: flex;
    justify-content: center;
  }
`;

const RemoveButton = styled.div`
  cursor: pointer;
  margin-right: 5px;
  padding: 6px;
  font-size: 14px;
  text-decoration-line: underline;
  color: #52c41a;
`;

const ProviderCardAddedStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 24px 14px 14px;
  border-bottom: 1px solid #f6f5f8;
  &.cart-list-item45 img,
  &.cart-list-item45 .svg-img {
    width: 80px;
    height: 80px
  }
  @media(max-width:991px){
    .cart-list-img{
      margin-right: 15px;
      img,.svg-img{
        margin-right:0;
      }
    }
  }
  @media (min-width: 992px) {
    display: flex;
    justify-content: end;
    width: 92%;
    margin-bottom: 19px;
    padding: 0;
    img,
    .svg-img {
      width: 70px !important;
      height: 70px !important;
      object-fit: contain !important;
      border-radius: 7% !important;
      margin-right: 0 !important;
      margin-bottom: 7px;
    }

    .content {
      width: 85% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin-bottom: 6px;
        color: #0b1d17 !important;
        font-weight: 700;
        font-size: 16px !important;
        margin-left: 20px;
      }

      .price-wrapper {
        display: flex;
        justify-content: right;
        align-items: center;

        .cartIncDec {
          display: flex;
          border: 1px solid #e0e3e0;
          border-radius: 6px;
          height: 44px;
          align-items: center;
          margin-right: 45px;

          input {
            padding: 1px 3px;
            text-align: center;
          }
          .math {
            border: 0;
            width: 50px;
            height: 28px;
          }
        }
        .math-wrapper {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 26px;

          .price {
            margin-right: 11px;
            color: #51635d;
            font-weight: 700;
            font-size: 14px;
            padding-top: 6px;
            width: 50.86px;
          }
        }
      }
    }
  }
  img,
  .svg-img {
    width: 24%;
    height: 95px;
    margin-right: 15px;
    border-radius: 8px;
    object-fit: contain;
  }

  .content {
    width: 76%;

    .title {
      margin-bottom: 8px;
      color: #0b1c18;
      font-size: 14px;
      width: 214px;
    }

    .price-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .price {
      color: #0b1c18;
      font-size: 16px;
    }

    .math-wrapper {
      display: flex;

      input {
        width: 70px;
        margin: 0 2px;
        color: #0B1D17;
        text-align: center;
        cursor: inherit;
      }
    }

    .math {
      width: 40px;
      height: 100%;
      border: 1px solid #daddda;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        color: #bdbfbd;
        font-size: 14px;
      }
    }
  }
`;

export default function HandleCartMealProvider({ mealData }) {
  const { isMobile } = useAppContext();
  const { updateMealQty, removeFromCart } = useCart();

  return mealData.map((meal) => {
    let { img, images, carbs, protein, fat, calories, price, name } = meal;
    if (meal.isCombo) {
      const totals = getComboTotals(meal.components);
      carbs = totals.carbs;
      protein = totals.protein;
      fat = totals.fat;
      calories = totals.calories;
      price = totals.price;
      name = totals.name;
    }
    const checkQuantity = (e) =>{
      if(e?.target?.value<1){
        updateMealQty(meal, 1)
      }
    }
    const handleRemove = () => removeFromCart(meal.id);
    const mobileView = isMobile ? 'mobileView' : '';
    return (
      <ProviderCardAddedMainDiv>
        <div className="cart-listing3" key={`outer${meal.id}`}>
          <ProviderCardAddedStyle key={meal.id} className="cart-list-item45">
            {(img || images !== null) ? (
              <div className='cart-list-img'>
              <ImageViewer src={img || images?.[0]} fallbackSrc={'/img_coming_soon.png'} alt={meal.name} />
              </div>
            ) : (
              <div className='cart-list-img'>
              <ImageViewer className="svg-img" src={'/images/dish.png'} />
              </div>
            )}
            <div className="content">
              <div className="title">{name}</div>
              {mobileView ? (
                <div className="calories-prices">
                  <Calories {...{ carbs, protein, fat, calories }} key="cal" />
                  <div className="price" data-testid="custom-price">
                    {useDisplayAmount(price / 100)}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="price-wrapper">
                {mobileView ? (
                  ''
                ) : (
                  <div className="cartIncDec">
                    <span
                      className="math"
                      data-testid="custom-CgMathMinus"
                      onClick={() => updateMealQty(meal, +meal.qty - 1)}
                    >
                      <CgMathMinus />
                    </span>
                    <input
                      data-testid="qty-content"
                      type="number"
                      autoComplete="off"
                      className="math"
                      value={meal.qty ==0 ? '':meal.qty}
                      onChange={(e) => {
                        if(e.target.value>1000) return 
                        return updateMealQty({...meal,method:'input'}, +e.target.value)
                      }}
                      onBlur = {checkQuantity}
                    />
                    <span
                      className="math"
                      data-testid="custom-CgMathPlus"
                      onClick={() => meal.qty === 1000 ? updateMealQty(meal, +meal.qty) : updateMealQty(meal, +meal.qty + 1)}
                    >
                      <CgMathPlus />
                    </span>
                  </div>
                )}
                <div className="math-wrapper">
                  {isMobile ? (
                    <button className="remove-cart" onClick={handleRemove}>
                      <img src="/icons/trash.svg" alt="trash" />
                    </button>
                  ) : (
                    <div className="price" data-testid="custom-price">
                      {useDisplayAmount(price / 100)}
                    </div>
                  )}
                  {!isMobile && <RemoveButton onClick={handleRemove}>Remove</RemoveButton>}
                  {isMobile && (
                    <div className="cartIncDec">
                      <span
                        className="math"
                        data-testid="custom-CgMathMinus"
                        onClick={() => updateMealQty(meal, +meal.qty - 1)}
                      >
                        <CgMathMinus />
                      </span>
                      <input
                        data-testid="qty-content"
                        type="number"
                        autoComplete="off"
                        className="math"
                        pattern="\d*"
                        value={meal.qty ==0 ? '':meal.qty}
                        onChange={(e) => {
                          if(e.target.value>1000) return 
                          return updateMealQty({...meal,method:'input'}, +e.target.value)
                        }}
                        onBlur = {checkQuantity}
                      />
                      <span
                        className="math"
                        data-testid="custom-CgMathPlus"
                        onClick={() => meal.qty === 1000 ? updateMealQty(meal, +meal.qty) : updateMealQty(meal, +meal.qty + 1)}
                      >
                        <CgMathPlus />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ProviderCardAddedStyle>
        </div>
      </ProviderCardAddedMainDiv>
    );
  });
}