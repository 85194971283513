import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Account } from './Account'
import DietPreferences from '../dietPreferences/DietPreferences'
import { HealthGoal } from '../healthGoal/HealthGoal'
import CurrentWeight from '../healthGoal/CurrentWeight'
import TargetWeight from '../healthGoal/TargetWeight'
import ActivityLevel from '../healthGoal/ActivityLevel'
import DesiredGoalPace from '../healthGoal/DesiredGoalPace'
import Gender from '../healthGoal/Gender'
import Height from '../healthGoal/Height'
import BirthDate from '../healthGoal/BirthDate'
import DesiredHealthGoal from '../healthGoal/DesiredHealthGoal'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'

const AccountRoutes = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Switch key={location.pathname} location={location}>
            <Route exact path="/shop/account" component={Account} />
            <Route exact path="/shop/account/diet-preferences" component={DietPreferences} />
            <Route exact path="/shop/account/health-goal" component={HealthGoal} />
            <Route exact path="/shop/account/desired-goal" component={DesiredHealthGoal}/>
            <Route exact path="/shop/account/current-weight" component={CurrentWeight} />
            <Route exact path="/shop/account/target-weight" component={TargetWeight} />
            <Route exact path="/shop/account/activity-level" component={ActivityLevel} />
            <Route exact path="/shop/account/desired-goal-pace" component={DesiredGoalPace} />
            <Route exact path="/shop/account/gender" component={Gender} />
            <Route exact path="/shop/account/birth-date" component={BirthDate} />
            <Route exact path="/shop/account/height" component={Height} />
        </Switch>
      </AnimatePresence>
    </>
  )
}

export default AccountRoutes