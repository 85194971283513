import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TimezoneSelect from 'react-timezone-select';

const TimezoneWrapper = styled.div`
  position: relative;

  .css-1s2u09g-control,
  .css-1pahdxg-control {
    height: 44px;
    border: 1px solid #e0e3e0;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      border: 1px solid #e0e3e0;
    }
  }

  .css-1pahdxg-control {
    border-color: #52c41a;
    box-shadow: none;

    &:hover {
      border-color: #52c41a;
    }
  }

  .css-17adf03-Sa {
    padding-left: 12px;
    font-size: 14px;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-yt9ioa-option {
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: #f0faeb;
      color: #52c41a;
      font-size: 14px;
    }
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option {
    background-color: #f0faeb;
    color: #52c41a;
    font-size: 14px;
    cursor: pointer;
  }

  .css-26l3qy-menu {
    z-index: 5;
  }

  .css-tj5bde-Svg {
    width: 14px;
    height: 14px;
    padding: 1px;
    color: #949d9b;
    border: 1px solid #949d9b;
    border-radius: 4px;
  }

  @media(min-width: 992px){
    .css-qc6sy-singleValue{
      color: #949D9B;
    }

    . css-1s2u09g-control{
      color: #949D9B;
    }
  }
`;

export default function Timezone({ selectedTimezone }) {
  const [currentTimezone, setSelectedTimezone] = useState({
    abbrev: 'GMT',
    altName: 'ETC/GMT',
    label: 'UTC',
    offset: 0,
    value: 'Etc/GMT',
  });

  useEffect(() => {
    selectedTimezone('Etc/GMT');
  }, []);

  return (
    <TimezoneWrapper>
      <TimezoneSelect
        value={currentTimezone}
        onChange={(timezone) => {
          setSelectedTimezone(timezone);
          selectedTimezone(timezone.value);
        }}
      />
    </TimezoneWrapper>
  );
}
