import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { DataCard } from '../home/DataCard';
import Tab from 'components/elements/tab/Tab';
import { UpcomingPayouts } from './UpcomingPayouts';
import { HistoryPayouts } from './HistoryPayouts';
import { useAppContext } from 'context/appContext';
import { Payouts as MobilePayouts} from 'pages/KitchenMobile/views/Payouts';

const PayoutsStyle = styled.section`
  overflow: auto;
  height: 100vh;
  padding-top: 28px;
  padding-left: 24px;
  padding-right: 24px;
  .page_title {
    font-size: ${({ theme }) => theme?.typography?.fontXl};
    font-weight: 600;
    line-height: 32px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
    margin-bottom: 24px;
  }
  .card_wrapper {
    display: flex;
    gap: 20px;
    max-width: 620px;
    margin-bottom: 24px;
    .data_card {
      padding-bottom: 27px;
      .title {
        margin-bottom: 0;
        margin-top: 12px;
      }
    }
  }
  .payoutTabs{
    margin-top: 24px;
    margin-bottom: 24px;
    .nav_item {
      background-color: transparent;
      color: ${() => theme?.colors?.blackLight};
      .text{
        font-weight: 500;
      }
      &.active {
        color: ${() => theme?.colors?.primaryDefault};
      }
    }
  }
`;

export const Payouts = () => {
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [currentBalance, setCurrentBalance] = useState();
  const [totalEarned, setTotalEarned] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const { isDesktop } = useAppContext();
  const payoutsTabs = [
    {
      id: 0,
      title: 'Upcoming',
      onClick: () => {},
      render: (setCurrentBalance, setTotalEarned, setDataLoading) => <UpcomingPayouts setTotalEarned={setTotalEarned} setCurrentBalance={setCurrentBalance} setDataLoading={setDataLoading} searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>,
    },
    {
      id: 1,
      title: 'History',
      onClick: () => {},
      render: () => <HistoryPayouts searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>,
    },
  ];

  useEffect(() => {
    if(isDesktop){
      setSearchTerm('');
    }
  },[selectedTabId])

  return (
    <>
    {isDesktop ? 
    <PayoutsStyle theme={theme}>
      <h2 className="page_title">Payouts</h2>
   
      <div className="card_wrapper">
        <DataCard preTitle="Current Balance" title={`$${currentBalance || 0}`} showPercentageData={false} />
        <DataCard
          preTitle="Total earned"
          dotColor="#3CC13B"
          title={`$${totalEarned || 0}`}
          showPercentageData={false}
        />
      </div>
      <div className="payoutTabs">
        <Tab
          type="horizontal"
          navItems={payoutsTabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
        />
      </div>
      <div className='payoutTables'>
        {payoutsTabs[selectedTabId].render(setCurrentBalance, setTotalEarned, setDataLoading)}
      </div>

    </PayoutsStyle>
    :
    <MobilePayouts 
    selectedTabId={selectedTabId}
    setSelectedTabId={setSelectedTabId}
    currentBalance={currentBalance}
    setCurrentBalance={setCurrentBalance}
    totalEarned={totalEarned}
    setTotalEarned={setTotalEarned}
    searchTerm={searchTerm} 
    setSearchTerm={setSearchTerm}
    />
    }
    </>
  );
};
