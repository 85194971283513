import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import RegularInput from 'components/Input/RegularInput';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { addressTypes } from './Data';
import { EnderAddressModalWrapper } from './style';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

function EnderAddressModal({
  location,
  form,
  setForm,
  isEnterAddress,
  setIsEnterAddress,
  setValue,
  ...props
}) {
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [changeOther, setChangeOther] = useState({
    name: addressTypes[3].Name,
    img: addressTypes[3].img,
  });
  const [isChangeType, setIsChangeType] = useState(false);
  const customRef = useRef();
  const history = useHistory();
  const handleChange = (e, key) => {
    setForm({ ...form, [key]: e.target.value });
  };
  const { setIsAddress } = useAppContext();
 
  const handleType = (value) => {
    if (value === 'other') {
      setIsCustomInput(true);
      setForm({ ...form, type: value });
      setIsChangeType(false);
    } else {
      setIsCustomInput(false);
      setForm({ ...form, type: value, customType: '' });
    }
  };

  const blurCustomInput = () => {
    setIsCustomInput(false);
    setIsChangeType(true);
    !form?.customType && setForm({ ...form, type: 'home' });
  };

  const closeCustomInput = () => {
    setIsCustomInput(false);
    setIsChangeType(false);
    setForm({ ...form, type: 'home', customType: '' });
  };

  useEffect(() => {
    setTimeout(() => {
      if (isCustomInput) {
        customRef?.current?.focus();
      }
    }, 800);
  }, [isCustomInput]);

  useEffect(() => {
    if (form?.type === 'other' && form?.customType?.length && isChangeType) {
      setChangeOther({
        name: form?.customType,
        img: <img className="icon" src="/icons/building-black.svg" />,
      });
    } else {
      setChangeOther({ name: 'other', img: <img src="/icons/add-black2.svg" /> });
    }
  }, [isChangeType, form?.customType]);


  const handleClick=()=>{
    localStorage.setItem("visitorDeliveryAddress",JSON.stringify(form))
    history.push("/visitor/kitchens");
    setIsAddress(false);
  }
  return (
    <EnderAddressModalWrapper>
      <AnimatePresence>
        {isEnterAddress && (
          <div className="enter-address-mask" onClick={() => setIsEnterAddress(false)} />
        )}
      </AnimatePresence>
      <div className="Enter-address-container">
        <div className="address-desktop-header">
          <div className="address-close-wrapper">
            <img
              src="/icons/close-black.svg"
              alt="close Icon"
              onClick={() => setIsEnterAddress(false)}
            />
          </div>
          <div className="address-header-title">Enter your address</div>
        </div>
        <div className="address-map-wrapper">
          <Map
            google={props.google}
            initialCenter={{
              lat: location.lat,
              lng: location.lng,
            }}
            zoom={16}
          >
            <Marker />
          </Map>
          <div className="address-info">
            <div className="address-first">
              {form?.address?.value?.structured_formatting?.main_text + ','}
            </div>
            <div className="address-second">
              {form?.address?.value?.structured_formatting?.secondary_text}
            </div>
          </div>
        </div>
        <div className="enter-address-divider" />
        <div className="address-details-wrapper">
          <RegularInput
            className="apt-number"
            label="Apt / Suite"
            type="text"
            placeholder="Apartment number or suite"
            value={form.apartment}
            onChange={(e) => handleChange(e, 'apartment')}
          />
          {isCustomInput && <div className="custom-input-mask" onClick={blurCustomInput} />}
          <div className="address-type-wrapper">
            <label>Save address as</label>
            <div className={classNames('address-types', { typesWithCustomInput: isCustomInput })}>
              {addressTypes.map((item) => {
                return (
                  <div
                    className={`label-tag ${item.Name === form.type && 'active'}`}
                    onClick={() => handleType(item.Name)}
                  >
                    <div className="image">
                      {item?.Name === 'other' ? changeOther?.img : item?.img}
                    </div>
                    <div className="type">
                      {item?.Name === 'other' ? changeOther?.name : item?.Name}
                    </div>
                  </div>
                );
              })}
              <AnimatePresence>
                {isCustomInput && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.3,
                      delay: 0,
                    }}
                    className="custom-input-motion"
                  >
                    <RegularInput
                      className="custom-input"
                      ref={customRef}
                      placeholder="Save as"
                      value={form.customType}
                      onChange={(e) => handleChange(e, 'customType')}
                    />
                    <img src="/icons/close-black.svg" alt="close Icon" onClick={closeCustomInput} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
        <button
          className="save-address-btn"
          disabled={!form?.address || (form?.type === 'other' && !form?.customType)}
          onClick={handleClick}
        >
          Start shopping
        </button>
      </div>
    </EnderAddressModalWrapper>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  LoadingContainer: () => <></>
})(EnderAddressModal);
