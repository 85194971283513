import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import {ReactComponent as Undo} from 'assets/undo.svg';
import { useAppContext } from 'context/appContext';

const MapStyle = styled.div`
    position: relative;
    .instructions{
        background-color: rgba(40, 50, 65, 0.89);
        padding: 10px 0 10px 24px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        width: 100%;
        height: 40px;
        left: 0;
        right: 0;
        z-index: 9;
        .title{
            color: ${({theme}) => theme?.colors?.white};
            font-size: ${({theme}) => theme?.typography?.fontRegular};
            font-weight: 400;
            line-height: 20px;
        }
        .cancel{
            color: ${({theme}) => theme?.colors?.white};
            font-size: ${({theme}) => theme?.typography?.fontRegular};
            font-weight: 400;
            line-height: 20px;
            padding: 0 20px;
            border-left: 1px solid ${({theme}) => theme?.colors?.grayLighter} !important;
            background-color: rgba(0,0,0,0);
            cursor: pointer;
        }
    }
    .undo_redo{
        background-color: rgba(40, 50, 65, 0.89);
        color: ${({theme}) => theme?.colors?.white};
        padding: 8px 12px;
        position: absolute;
        top: 0;
        width: 100%;
        height: 40px;
        left: 0;
        right: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        gap: 16px;
        user-select: none;
        .icon{
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: ${({theme}) => theme?.typography?.fontRegular};
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;
        }
        .redo{
            transform: rotateY(180deg);
        }
        .line{
            width: 1px;
            height: 100%;
            border: 1px solid ${({theme}) => theme?.colors?.white};
        }
        .remove{
            font-size: ${({theme}) => theme?.typography?.fontRegular};
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;
        }
    }
`

export const Map = ({ isMapEditable, setIsMapEditable, setUpdatedArea, isCancelled ,setIsButtonEnabled,deliveryZipcodes}) => {
  const { provider: {BusinessProfileSettings} } = useAppContext();
  const { deliverableAreas } = BusinessProfileSettings[0] || {};
  const [drawingInProgress, setDrawingInProgress] = useState(deliverableAreas?.data?.length > 0);
  const [map, setMap] = useState();
  const drawingManagerRef = useRef(null);
  const shapesRef = useRef([]);
  const undoHistoryRef = useRef([]);
  const redoHistoryRef = useRef([]);
  const [deliveryArea, setDeliveryArea] = useState(deliverableAreas?.data || []);
  
  const setupMap = () => {
    const drawingManagerConfig = {
        drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        polygonOptions: {
          strokeOpacity: 0,
          clickable: false,
          zIndex: 1,
        },
    };
    const gMap = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 37.0902, lng: -95.7129 },
      zoom: 4,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    });
    const drawingManager = new google.maps.drawing.DrawingManager(
      drawingManagerConfig
    );
    setMap(gMap);
    setTimeout(() => {renderSavedPolygons(deliverableAreas?.data, gMap)}, 200)
    drawingManagerRef.current = drawingManager;
    google.maps.event.addListener(drawingManager, "overlaycomplete", function (
      event
    ) {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {   
        setIsButtonEnabled(false)
        setDrawingInProgress(true);
        const newShape = event.overlay;
        shapesRef.current.push(newShape);
        undoHistoryRef.current.push(newShape);
        const cords = event.overlay
          .getPath()
          .getArray()
          .map((cord) => {
            return {
              lat: cord.lat(),
              lng: cord.lng(),
            };
          });
        setDeliveryArea((prev) => [...prev, cords]);
      }
    });
  };

  useEffect(() => {
    setupMap();
  }, [isCancelled]);

  useEffect(() => {
    setUpdatedArea(deliveryArea);
  }, [deliveryArea])

  useEffect(() => {
    const drawingManager = drawingManagerRef.current;
    if (isMapEditable) {
      drawingManager?.setMap(map);
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    } else {
      drawingManager.setDrawingMode(null);
    }
  }, [isMapEditable, map]);

  const handleCancel = () => {
    setIsButtonEnabled(true)
    setIsMapEditable(false);
    const drawingManager = drawingManagerRef.current;
    drawingManager.setDrawingMode(null);
    setupMap()
  };

  const undoClick = () => {
    setIsButtonEnabled(true)
    if (shapesRef.current.length > 0) {
      const lastShape = shapesRef.current.pop();
      lastShape.setMap(null);
      redoHistoryRef.current.push(lastShape);
      setDeliveryArea(prevArray => prevArray.slice(0, -1));
    }
  };

  const redoClick = () => {
    setIsButtonEnabled(false)
    if (redoHistoryRef.current.length > 0) {
      const lastRedoShape = redoHistoryRef.current.pop();
      lastRedoShape.setMap(map);
      shapesRef.current.push(lastRedoShape);
      const lastRedoLatLong = lastRedoShape.getPath()
      .getArray()
      .map((cord) => {
        return {
          lat: cord.lat(),
          lng: cord.lng(),
        };
      });
      setDeliveryArea(prevArray => [...prevArray, lastRedoLatLong]);
    }
  };

  const removeAllShapes = () => {
    shapesRef.current.forEach((shape) => shape.setMap(null));
    shapesRef.current = [];
    undoHistoryRef.current = [];
    redoHistoryRef.current = [];
    setDrawingInProgress(false);
    setDeliveryArea([]);
    if (deliveryZipcodes?.length < 1) {
      setIsButtonEnabled(true)
    } else {
      setIsButtonEnabled(false);
    }
  };

  const renderSavedPolygons = (polygons, gMap) => {
    if (gMap && polygons && polygons.length > 0) {
      let allCords = polygons?.map((cords) => {
          return cords.map(({ lat, lng }) => new google.maps.LatLng(lat, lng));
        }) || [];
        const polygon = new google.maps.Polygon({
          paths: allCords,
          strokeOpacity: 0,
          strokeWeight: 0,
          fillColor: "#00092D",
          fillOpacity: 0.3,
        });
        polygon.setMap(gMap);
        shapesRef.current.push(polygon);
    }
  };

  return (
        <MapStyle theme={theme}>
            {isMapEditable && !drawingInProgress &&
                <div className='instructions'>
                    <h3 className='title'>Click and drag to draw your search</h3>
                    <button className='cancel' onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            }
            {isMapEditable && drawingInProgress && <div className='undo_redo'>
                <div className='icon' onClick={undoClick}>
                    <Undo/> Undo
                </div>
                <div onClick={redoClick} className='icon'>
                    <Undo className='redo'/> Redo
                </div>
                <div className='line'></div>
                <div className='remove' onClick={removeAllShapes}>
                    Remove All
                </div>
            </div>}
            <div id="map"></div>
        </MapStyle>
  );
};
