import React from 'react';
import styled from 'styled-components';
import Progress from 'components/elements/Progress';
import { ReactComponent as Alarm } from 'assets/shop/order-not-met-alarm.svg';

const MinimumOrderBarStyle = styled.div`
  .error_box {
    background-color: #fef1f0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 12px;
    padding-bottom: 5px;
    .order_amount {
      color: rgb(235, 50, 35);
    }
  }
  .alarm {
    display: flex;
    align-items: end;
    gap: 5px;
  }
  .minOrder {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.22px;
  }
  .order_amount {
    color: #000a2c;
    font-size: 11px;
    font-weight: 700;
  }
`;

const MinimumOrderBar = (props) => {
  const {
    totalSumNumber = 0,
    minValueNumber = 0,
    buttonGotPressed = false,
  } = props;
  return (
    <>
      <MinimumOrderBarStyle>
        <div className={minValueNumber > totalSumNumber && buttonGotPressed && 'error_box'}>
          <Progress
            percent={totalSumNumber * (100 / minValueNumber)}
            strokeWidth={1}
            strokeColor={
              minValueNumber > totalSumNumber && buttonGotPressed ? '#EB3223' : '#52C41A'
            }
            trailWidth={3}
            trailColor={minValueNumber > totalSumNumber && buttonGotPressed ? '#FACAC6' : '#E9EAEC'}
          />
          <div className="alarm">
            {minValueNumber > totalSumNumber && buttonGotPressed && <Alarm />}
            <text
              className="minOrder"
              style={{
                color: minValueNumber > totalSumNumber && buttonGotPressed ? '#EB3223' : '#000A2C',
              }}
            >
              You’re $
              <text className="order_amount">
                {minValueNumber <= totalSumNumber
                  ? 0
                  : Math.abs(totalSumNumber - minValueNumber).toFixed(2)}{' '}
              </text>
              away from minimum order
            </text>
          </div>
        </div>
      </MinimumOrderBarStyle>
    </>
  );
};

export default MinimumOrderBar;
