import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import ClientDashboard from 'components/Dashboard';
import InviteActiveCard from 'components/Dashboard/clients/ActiveCard';
import OfferingCard from 'components/Dashboard/clients/CreateOffering';
import OverlayLoader from 'components/overlayLoader';
import Input from 'components/Input';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useRequest } from '../../../hooks/useRequest';
import { useAppContext } from "../../../context/appContext";
import { useNutritionistContext } from "../../../context/nutritionistContext";
import { useHistory } from "react-router-dom";
import Progress from 'components/Progress';
import BackButton from 'components/BackButton';

const PageTitleStyled = styled.div`
    text-align: center;
    h1{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #51635D;
    }

    @media (max-width: 991px) {
        margin-bottom: 22px;
         margin-top:50px;
        .back-button{
            position:absolute;
        }
    }
`
const MainContentWrapper = styled.form`
    min-width: 600px;
    width: 50%;
    margin: auto;
    button: disabled {
        background-color: #e0e3e0;
        color: #949d9b;
        cursor: not-allowed;
      }
    
    @media (max-width: 991px) {
        min-width: 100%;
        width: 100%;
        padding: 20px 20px 32px;
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }
`
const ClientInviteFormStyled = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`
const FormFieldGroupStyled = styled.div`
    flex: 0 0 100%;
    &.multiField{
        flex: 0 0 48%;
    }
    &.multiField4{
        flex: 0 0 23%;
        position: relative;
        min-width: auto !important;
        .fieldValue{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
            position: absolute;
            bottom: 12px;
            right: 10px;
        }
    }
    .userExist{
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        color: #F53E3E;
        padding: 9px;
    }
    .field-wrapper{
        box-sizing: border-box;
        input{
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
        }
        .userExistField{
            background: rgba(235, 50, 35, 0.07);
            border: 1px solid rgba(235, 50, 35, 0.25);  
            color: #D13726;
        }
    }
    label{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #51635D;
        margin-bottom: 2px;
        min-height: 20px;
    }
    input{
        font-size: 13px !important;
        padding-right: 40px;
    }

    @media (max-width: 991px) {
        &:first-child > div {
            margin-top: 0
        }

        &.multiField {
            flex: auto;
        }

        &.multiField4 {
            flex: none;
            flex-basis: 47%;

            input {
                padding-right: 45px;
            }
        }

        label {
            font-weight: 700;
        }

        input {
            padding-right: 14px;

            &::placeholder {
                color: #fff;
            }
        }
    }
`
const FormSubmitButtonStyled = styled.div`
    .btn{
        background-color: #52C41A;
        border-radius: 31px;
        width: 200px;
        height: 62px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 10px 12px 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #fff;
        margin: auto;
        cursor: pointer;
        img{
            margin-left: 7px;
        }
    }
`
const JakeBoxUIStyled = styled.div`
    background: #FFFFFF;
    border: 1px solid #E0E3E0;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 30px;

    @media (max-width: 991px) {
        margin-bottom: 24px;
    }
`
const JakeBoxHeaderStyled = styled.div`
    background: #F0FAEB;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #CBEDBA;

    @media (max-width: 991px) {
        padding: 12px
    }
`
const JakeBoxHeaderLeftStyled = styled.div`
    display: flex;
    align-items: center;
    img{
        margin-right: 8px;
    }
    h4{
        font-weight: 400;
        color: #51635D;
        b{
            font-weight: 600;
        }
    }

    @media (max-width: 991px) {
        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }

        h4 {
            font-size: 14px;
            line-height: 16px;
        }
    }
`;

const JakeBoxContentStyled = styled.div`
    padding: 25px;
    display: flex;
    justify-content: space-between;
          >div{
            margin-top: 0px;
        }
    }
    @media (max-width: 991px) {
        padding: 20px;
        flex-direction: column;
    }
`
const GenderForm = styled.div`
    margin-top: 24px;
`
const LabelFont = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    span{
        color: red;
    }

`

export default function DashboardClientInvite() {
    const { user, isSuper, isMobile } = useAppContext();
    const { inviteFormData, setInviteFormData,nutritionist } = useNutritionistContext();
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [age, setAge] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [heightInch, setHeightInch] = useState('');
    const [activitySelect, setActivitySelect] = useState(null);
    const [userExist, setUserExist] = useState(false);
    const [belongUser,setBelongUser]=useState(true)
    const [userExistData, setUserExistData] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [selectedMealPlan, setSelectedMealPlan] = useState(null);
    const [getUserExist] = useRequest();
    const [inviteUser, {isLoading: inviteUserLoading}] = useRequest('nutritionist/inviteUser', 'Post');
    const [gender, setGender] = useState("m");
    const history = useHistory();
    const [Recommended,setRecommended] = useState(false);
    const [nutrittUser, setNutrittUser] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
    const reqId = isSuper ? imitateNutritionist?.id : user?.nutritionistProfile?.id;
    const currentNutritionistId = nutritionist?.nutritionist?.id;

    useEffect(() => {
        if (email && age && weight && height && gender && activitySelect) {
            console.log({ selectedMealPlan });
            onBlur();
        }
    }, [email, age, weight, gender, height, heightInch, activitySelect, selectedMealPlan]);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => setisLoading(false), 4000);
        }
    }, [isLoading]);

    const onInvite = (e) => {
        e.preventDefault();
        if(validateEmail(email)){
        if (email && (userExist === false)) {
            if (email && selectedOffer?.value && selectedMealPlan?.value && age && weight && height && height && firstname && activitySelect) {
                const InvitePayload = {
                    "id": reqId,
                    "user": {
                        "clientEmail": email,
                        "firstName": firstname,
                        "lastName": lastname,
                        "clientAddress": clientAddress,
                        "age": parseInt(age),
                        "weight": parseInt(weight),
                        "height": parseInt(height),
                        "heightInches": parseInt(heightInch),
                        "activity": parseInt(activitySelect),
                        "gender": gender
                    },
                    "offerId": parseInt(selectedOffer?.value),
                    "dietTemplateId": parseInt(selectedMealPlan?.value)
                }
                inviteUser({
                    body: InvitePayload
                }).then((response) => {
                    toast.success("Invite user successfully");
                    history.push('/nutritionist/clients');
                })
                    .catch((error) => toast.error("Something went wrong"))
            } else {
                if((email && selectedOffer?.value && selectedMealPlan?.value && age && weight && height && height && firstname) && !activitySelect){
                    toast.error("Please select your activity")
                }else{
                    toast.error('Please fill all the necessary details');
                }
            }
        } else{
            if(!Recommended){
                if(email && selectedOffer?.value) {
                    const InvitePayload = {
                        "id": reqId,
                        "user": {
                            "clientEmail": email,
                        },
                        "offerId": parseInt(selectedOffer?.value),
                        "dietTemplateId": null
                    }
                    inviteUser({
                        body: InvitePayload
                    }).then((response) =>{ 
                        toast.success("Invite user successfully");
                        history.push('/nutritionist/clients');
                    })
                        .catch((error) => toast.error("Something went wrong"))
                } else {
                    toast.error('Please fill all the necessary details');
                }
            } else {
                if(email && selectedOffer?.value && selectedMealPlan?.value) {
                    const InvitePayload = {
                        "id": reqId,
                        "user": {
                            "clientEmail": email,
                        },
                        "offerId": parseInt(selectedOffer?.value),
                        "dietTemplateId": parseInt(selectedMealPlan?.value)
                    }
                    inviteUser({
                        body: InvitePayload
                    }).then((response) => {
                        toast.success("Invite user successfully");
                        history.push('/nutritionist/clients');
                    })
                        .catch((error) => toast.error("Something went wrong"))
                } else {
                    toast.error('Please fill all the necessary details');
                }
            }
        }
    } else {
        toast.error("Please enter a valid email");
    }
    }

    const validateEmail = (email) => {
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(String(email).toLowerCase());
      };
    function onBlur() {
        if(!validateEmail(email) && email !== ''){
            setTimeout(() => {
                toast.error("Please enter a valid email");
              }, 3000);
        }
        if (email) {
            getUserExist({
                path: `nutritionist/checkExistingUser/${email}`,
                params: {
                    age,
                    gender,
                    heightFeet: height,
                    heightInches: heightInch,
                    weight,
                    activity: activitySelect,
                    dietTemplateId: selectedMealPlan?.value,
                    nutritionistId: currentNutritionistId
                },
            })
                .then((response) => {
                    toast.error(response.message);
                    setNutrittUser(response.message);
                    setUserExistData(response);
                    setUserExist(response.success);
                    setBelongUser(response.isNutritionistMatched),
                    response.isNutritionistMatched == false && toast.error("User belongs to another nutritionist");
                })
                .catch((error) => toast.error('Something Went Wrong'));
        }
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: "#52C41A",
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff,28%,transparent 32%)',
            content: '""',

        },

    });


    const handleGender = (event) => {
        if (event.target.value === "f") {
            setGender("f");
        } else {
            setGender("m");
        }
        setGender(event.target.value);
    }

    const handleChange = (val, e) => {
        const value = e.target.value;
        const isNumber = /^\d*\.?\d{0,2}$/.test(value);
        if (isNumber === true) {
          const roundNumber = Math.trunc(value);
        if(val=='Age'){
            setAge(roundNumber)
        }else if(val=='Weight'){
            setWeight(roundNumber)
        }else if(val=='Height'){
            setHeight(roundNumber)
        }else if(val=='HeightInch'){
            setHeightInch(roundNumber)
        }
      }  else {
        toast.error(`Only Numaric values allowed for this field`);
      }
    };

    useEffect(() => {
        if (inviteFormData) {
            const {
                email,
                age,
                firstname,
                lastname,
                clientAddress,
                weight,
                height,
                heightInch,
                activitySelect,
                selectedOffer,
                selectedMealPlan,
                gender,
            } = inviteFormData;
            setEmail(email);
            setAge(age);
            setFirstname(firstname);
            setLastname(lastname);
            setClientAddress(clientAddress);
            setWeight(weight);
            setHeight(height);
            setHeightInch(heightInch);
            setActivitySelect(activitySelect);
            setSelectedOffer(selectedOffer);
            setSelectedMealPlan(selectedMealPlan);
            setGender(gender);
            setInviteFormData(null);
        }
    }, []);

    const persistInviteFormData = (value) => {
        setInviteFormData({
            email,
            age,
            firstname,
            lastname,
            clientAddress,
            weight,
            height,
            heightInch,
            activitySelect,
            selectedOffer,
            selectedMealPlan,
            gender,
        });
        setTimeout(() => {
            if (value === "offer") {
                history.push( '/nutritionist/coaching/offering/create?redirectBackTo=/nutritionist/coaching/invite',
                );
            } else {
                {
                    isMobile ? 
                    history.push(
                    '/nutritionist/Challanges/create?redirectBackTo=/nutritionist/coaching/invite',
                ) : 
                history.push(
                    '/nutritionist/meal-plan/new?redirectBackTo=/nutritionist/coaching/invite',
                )}
            }
        }, 500);
    }


    return (
        <ClientDashboard>
            <PageTitleStyled>
          {isMobile && <div className='back-button'><BackButton perviousLink="/nutritionist/clients" /></div> }
                <h1>1:1 coaching Invite</h1>
            </PageTitleStyled>
            <MainContentWrapper noValidate onSubmit={onInvite}>
                <ClientInviteFormStyled>
                    <FormFieldGroupStyled>
                        <Input
                            label="Clients email"
                            autoFocus={true}
                            value={email}
                            className={userExist ? 'userExistField' : ''}
                            onBlur={onBlur}
                            onChange={(e) => setEmail(e.target.value)}
                            bordered
                            required
                        />
                        {userExist && <span className='userExist'> * user already exist </span>}
                    </FormFieldGroupStyled>
                    {
                        !userExist &&
                        <>
                            <FormFieldGroupStyled >
                                <Input
                                    label="First name"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    bordered
                                    required
                                />
                            </FormFieldGroupStyled>
                            <FormFieldGroupStyled >
                                <Input
                                    label="Last name"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    bordered
                                />
                            </FormFieldGroupStyled>
                            {isLoading && <OverlayLoader active={isLoading} />}
                            <FormFieldGroupStyled>
                                <Input
                                    label="Clients address"
                                    value={clientAddress}
                                    onChange={(e) => setClientAddress(e.target.value)}
                                    bordered
                                />
                            </FormFieldGroupStyled>
                            <FormFieldGroupStyled className='multiField4'>
                                <Input
                                    label="Age"
                                    placeholder=""
                                    value={age}
                                    onChange={(e) => handleChange('Age', e)}
                                    bordered
                                    required
                                    type='tel'
                                />
                                <span className='fieldValue'>years</span>
                            </FormFieldGroupStyled>
                            <FormFieldGroupStyled className='multiField4'>
                                <Input
                                    label="Weight"
                                    placeholder=""
                                    value={weight}
                                    onChange={(e) => handleChange('Weight', e)}
                                    bordered
                                    required
                                    type='tel'
                                />
                                <span className='fieldValue'>lbs</span>
                            </FormFieldGroupStyled>
                            <FormFieldGroupStyled className='multiField4'>
                                <Input
                                    label="Height"
                                    placeholder=""
                                    value={height}
                                    onChange={(e) => handleChange('Height', e)}
                                    bordered
                                    required
                                    type='tel'
                                />
                                <span className='fieldValue'>ft</span>
                            </FormFieldGroupStyled>
                            <FormFieldGroupStyled className='multiField4'>
                                <Input
                                    label=""
                                    placeholder=""
                                    value={heightInch}
                                    onChange={(e) => handleChange('HeightInch', e)}
                                    bordered
                                    type='tel'
                                />
                                <span className='fieldValue'>Inch</span>
                            </FormFieldGroupStyled>
                            <GenderForm>
                                <LabelFont>Gender<span>*</span></LabelFont>
                                <FormControl>

                                    <RadioGroup row name="gender" value={gender}>
                                        <FormControlLabel
                                            className='checkmark'
                                            value="m"
                                            control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} onClick={handleGender} />}
                                            label={<LabelFont>Male</LabelFont>}
                                        />
                                        <FormControlLabel
                                            className='checkmark'
                                            value="f"
                                            control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} onClick={handleGender} />}
                                            label={<LabelFont>Female</LabelFont>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </GenderForm>
                        </>
                    }
                </ClientInviteFormStyled>
                {   
                    !userExist &&
                    <InviteActiveCard setActivitySelect={setActivitySelect} />
                }
                <OfferingCard
                    userExist={userExist}
                    setSelectedOffer={setSelectedOffer}
                    setSelectedMealPlan={setSelectedMealPlan}
                    persistInviteFormData={persistInviteFormData}
                    selectedMealPlan={selectedMealPlan}
                    selectedOffer={selectedOffer}
                    Recommended={Recommended} 
                    setRecommended={setRecommended}                   
                />

                <JakeBoxUIStyled>
                    <JakeBoxHeaderStyled>
                        <JakeBoxHeaderLeftStyled>
                            <img src="/icons/verify-icon.png" alt="Verify" />
                            <h4> 
                                <b>{(userExist?
                                    (userExistData.dietTemplates?.result?.profile?.name ? userExistData.dietTemplates?.result?.profile?.name : 'Your') :
                                    (firstname ? firstname : 'Your') )}'s </b>
                                 daily recomended intake is
                                 </h4>
                        </JakeBoxHeaderLeftStyled>
                        {/* <JakeBoxHeaderEditStyled>
                            <img src="/icons/edit-2.svg" alt="Edit Icon" />
                        </JakeBoxHeaderEditStyled> */}
                    </JakeBoxHeaderStyled>
                    <JakeBoxContentStyled>
                        <FormFieldGroupStyled className='multiField4'>
                            <Input
                                disabled ={true}
                                label="Calories"
                                autoFocus={true}
                                value={userExistData.dietTemplates?.calories}
                            />
                        </FormFieldGroupStyled>
                        <FormFieldGroupStyled className='multiField4'>
                            <Input
                                disabled ={true}
                                label="Fat"
                                autoFocus={true}
                                bordered
                                value={userExistData.dietTemplates?.macros?.fat}
                            />
                            <span className='fieldValue'>g</span>
                        </FormFieldGroupStyled>
                        <FormFieldGroupStyled className='multiField4'>
                            <Input
                                disabled ={true}
                                label="Protein"
                                autoFocus={true}
                                bordered
                                value={userExistData.dietTemplates?.macros?.protein}
                            />
                            <span className='fieldValue'>g</span>
                        </FormFieldGroupStyled>
                        <FormFieldGroupStyled className='multiField4'>
                            <Input
                                disabled ={true}
                                label="Carbs"
                                autoFocus={true}
                                bordered
                                value={userExistData.dietTemplates?.macros?.carbs}
                            />
                            <span className='fieldValue'>g</span>
                        </FormFieldGroupStyled>
                    </JakeBoxContentStyled>
                </JakeBoxUIStyled>
                <FormSubmitButtonStyled>
                    <button type="submit" className='btn' disabled={inviteUserLoading ||( userExist && !belongUser) || nutrittUser}> 
                        {inviteUserLoading ? 'Sending' : 'Send Invite'} 
                        <img src="/icons/send-icon.svg" alt="Send Icon" />
                    </button>
                </FormSubmitButtonStyled>
                {!isMobile && <br />}
                {inviteUserLoading && <Progress/>}
            </MainContentWrapper>
        </ClientDashboard>
    )
}
