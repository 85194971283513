import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import MultiScreenStyle from './MultiScreenStyle';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router';

const ActivityLevelStyle = styled.div`
  .checkbox___card {
    margin: 8px 0;
  }
`;

const ActivityLevel = ({ activeModalStack, setActiveModalStack, isHealthGoalFlow, nextScreen, modal, totalPagesCount }) => {
  const activityLevels = [
    {
      id: 0,
      title: 'Slightly active',
      subtitle: 'Light activities',
      value: 1,
    },
    {
      id: 1,
      title: 'Active',
      subtitle: 'Daily life activity ',
      value: 2,
    },
    {
      id: 2,
      title: 'Very active',
      subtitle: 'Always on the go',
      value: 3,
    },
    {
      id: 3,
      title: 'Intensively active',
      subtitle: 'Frequent intense workouts',
      value: 4,
    },
    {
      id: 4,
      title: 'Extremely active',
      subtitle: 'High performance athlete ',
      value: 5,
    },
  ];
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });
  const [activePreference, setActivePreference] = useState('');
  const { healthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const isSaveDisabled = healthGoal?.activity === activePreference?.[0];
  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
    }, 0);
  };

  useEffect(() => {
    setActivePreference([healthGoal?.activity]);
  }, []);

  const handleClick = (value) => {
    setUpdatedHealthGoal((prev) => ({ ...prev, activity: value }))
  }

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
  };

  return (
    <>
      <motion.div
        initial={animation?.initial}
        animate={{ opacity: 1, x: 0, y: 0 }}
        exit={animation?.exit}
        transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
      >
        <ActivityLevelStyle theme={theme}>
          <MultiScreenStyle>
            <MobileHeader
              text={'Activity level'}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={
                !isHealthGoalFlow
                  ? () =>
                      setActiveModalStack((prev) => [
                        ...prev.filter((item) => item?.modal !== modal?.modal),
                      ])
                  : false
              }
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
            />
            <div className="containerr">
              <p className="subtitle">
                Your activity level affects your calorie needs. <br />
                More activity = more calories needed
              </p>
              <div className="activity_value_holder">
                {activityLevels?.map((item, index) => (
                  <div className="checkbox___card" key={index}>
                    <CheckboxCard
                      title={item?.title}
                      subtitle={item?.subtitle}
                      selectedValue={activePreference}
                      setSelectedValue={setActivePreference}
                      checked={activePreference?.includes(item?.value)}
                      onClick={handleClick}
                      value={item?.value}
                      radio={true}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="button_holder">
              {isHealthGoalFlow ? (
                <Button
                  type={'button_combo'}
                  iconLeft={<ChevronLeft />}
                  iconRight={<ChevronRight />}
                  comboLeftTitle={'Back'}
                  comboLeftClick={handleBackClick}
                  comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}
                  comboRightTitle={'Continue'}
                  comboRightDisabled={!activePreference?.[0]}
                />
              ) : (
                <Button type={'mobile_save'} title={'Save'} onClick={handleSave} isDisabled={isSaveDisabled}/>
              )}
            </div>
          </MultiScreenStyle>
        </ActivityLevelStyle>
      </motion.div>
    </>
  );
};

export default ActivityLevel;
