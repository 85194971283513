import { memo, useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { ActionButtonWrapper } from './style';

function ActionButton({
  className,
  btnText,
  handleSave,
  handleEdit,
  handleDelete,
  handleMarkAsInActive,
  handleMoveToPantry,
  markStatusLabel,
  parentRef,
  setIsOpenAction
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [updatedStyle, setUpdatedStyle] = useState({});
  const dropdownRef = useRef(null);  
  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if(dropdownRef?.current && parentRef?.current){
        const element = dropdownRef.current;
        const parentElement = parentRef.current;
        const parentBoundingRect = parentElement.getBoundingClientRect();
        const boundingRect = element.getBoundingClientRect();
        const isOverflowingBottom = boundingRect?.top + boundingRect?.height > parentBoundingRect?.height + parentBoundingRect?.top;
        const isOverflowingTop = parentBoundingRect?.top > boundingRect?.top;
        if(isOverflowingTop){
          setUpdatedStyle((prev) => ({
            ...prev,
            top: '0',
            bottom: 'auto'
          }))
        }
        if(isOverflowingBottom){
          setUpdatedStyle((prev) => ({
            ...prev,
            bottom: '42px',
            top: 'auto'
          }))
        }
      }
    }, [isOpen, dropdownRef, parentRef])

    useEffect(() => {
      if(!isOpen && setIsOpenAction){
        setIsOpenAction(false)
      }
    }, [isOpen])

  return (
    <ActionButtonWrapper className={className} isOpen={isOpen}>
      {isOpen && <div className="action-mask" onClick={() => setIsOpen(false)} />}
      <div className="btn" onClick={() => setIsOpen(!isOpen)}>
        {btnText}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="box"
            initial={{ opacity: 0, y: 50, zIndex: 9, position: 'relative' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.3,
              delay: 0.2,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            exit={{ opacity: 0, y: 50 }}
          >
          <div className="menu" ref={dropdownRef} style={updatedStyle}>
              {handleSave && (
                <div
                  onClick={() => {
                    setIsOpen(false);
                    handleSave();
                  }}
                  className="menu-item"
                >
                  <img src="/icons/save.svg" alt="Save" />
                  <span>Save</span>
                </div>
              )}
              {handleEdit && (
                <div
                  onClick={() => {
                    setIsOpen(false);
                    handleEdit();
                  }}
                  className="menu-item"
                >
                  <img src="/icons/edit2.svg" alt="Edit" />
                  <span>Edit</span>
                </div>
              )}
              {handleDelete && (
                <div
                  onClick={() => {
                    setIsOpen(false);
                    handleDelete();
                  }}
                  className="menu-item"
                >
                  <img src="/icons/delete.svg" alt="Delete" />
                  <span>Delete</span>
                </div>
              )}
              {handleMarkAsInActive && (
                <div
                  onClick={() => {
                    setIsOpen(false);
                    handleMarkAsInActive();
                  }}
                  className="menu-item"
                >
                  <img src="/icons/visibility.svg" alt="Visibility" />
                  <span>{markStatusLabel ? markStatusLabel : 'Mark as inactive'}</span>
                </div>
              )}
              {handleMoveToPantry && (
                <div
                  onClick={() => {
                    setIsOpen(false);
                    handleMoveToPantry();
                  }}
                  className="menu-item"
                >
                  <img src="/icons/back.svg" alt="Visibility" />
                  <span>Move to pantry</span>
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </ActionButtonWrapper>
  );
}

export default memo(ActionButton);
