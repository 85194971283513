import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const ItemsWrapper = styled.div`
  .item-details-wrapper {
    overflow: auto;
    flex: 1;
    & > div:first-child {
      border-top: 1px solid ${() => theme.colors.lightGrey};
    }
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    .order-not-found-wrapper {
      overflow: hidden;
      height: calc(100vh - 331.5px);
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: center;
      gap:20px;
      .order-not-found-text-wrapper{
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        gap:8;
        text-align: center;
        font-family: Gilroy;
        font-style: normal;
        text{
          color:${()=>theme.colors.primaryDarker};
          font-size: 20px;
          font-weight: 600;
          line-height: 28px; 
        }
        p{
          color:${()=>theme.colors.primaryDarker};
          font-size: 14px;
          font-weight: 400;
          line-height: 20px; 

        }
      }

    }
  }

  .alert-successDelete-wrapper {
    position: absolute;
    top: 16px;
    left: 288px;
    right: 0;
    display: flex;
    justify-content: center;
    .alert_bar {
      width: 445px;
      max-width: 445px;
      border: 1px solid #cef0ce;
      .title {
        color: #283241;
      }
    }
  }
`;
export const Progress = styled.div`
  flex: 1;
  .icon {
    position: absolute;
    top: 60%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
`