import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Header from 'layout/Header';
import BookingPayment from './BookingPayment';
import CreatePassword from './CreatePassword';
import BookingSchedule from './BookingSchedule';
import BookedSuccessfully from './BookedSuccessfully';
import RegisteredDetitianCard from 'components/OnboardingPlans/RegisteredDetitianCard';
import {
  NutritionistContextProvider,
  useNutritionistContext,
} from '../../context/nutritionistContext';
import {useAppContext} from '../../context/appContext';

const BookingWrapper = styled.div`
  min-height: calc(100vh - 141px);
  
  .container {
    margin-top: ${props => props.isGuestFlow ? "10px" : "10px"};

     padding: 23px 0 33px;
  }

  @media (min-width: 992px) {
  .container {
    // margin-top: 75px;
    margin-top: ${props => props.isGuestFlow ? "50px" : "50px"};
  }
`;

function Booking() {
  const { offerData: offeringData } = useNutritionistContext();
  const {user, isMobile} = useAppContext();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  const { pathname } = useLocation();
  const [isGuestFlow,setIsGuestFlow]=useState(false);

  useEffect(() => {
    if (!user) {
      setIsGuestFlow(true);
    }
  }, [])

  return (
    <BookingWrapper isGuestFlow={isGuestFlow}>
      { !(user?.id || selectedOfferDetail?.userId !== "undefined") && pathname=="/booking/one-to-one-meeting" ? (
      <Header activeSteps="2"/>) : pathname== '/booking/booked' && !user?.id && selectedOfferDetail?.userId == "undefined" ? <Header activeSteps="8"/> :  (pathname == '/booking/payment' && !user?.id && selectedOfferDetail?.userId == "undefined")  ? <Header activeSteps="4"/> : <Header/> }
      
      <div className="container">
        {/* <RegisteredDetitianCard
        dietitianPhoto={offeringData?.nutritionist?.img || '/images/man.svg'}
        name={offeringData?.nutritionist?.name}
        title={offeringData?.nutritionist?.title}
        /> */}
        <Switch>
          <Route
            exact
            path="/booking/one-to-one-meeting"
            component={BookingSchedule}
          />
          <Route exact path="/booking/payment" component={BookingPayment} />
          <Route exact path="/booking/booked" component={BookedSuccessfully} />
          <Route exact path="/booking/create-password" component={CreatePassword} />
          <Redirect to="/login" />
        </Switch>
      </div>
      <Switch>
      </Switch>
    </BookingWrapper>
  );
}

const BookingContextWrapper = () => {
  return (
    <NutritionistContextProvider>
      <Booking />
    </NutritionistContextProvider>
  );
};

export default BookingContextWrapper;

