import React, { useState, useEffect, memo, useRef } from 'react';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Empty from './Empty';
import LogMealsModal from './LogMealsModal';
import DiaryTagContent from "./DiaryTagContent";
import { useAppContext } from 'context/appContext';
import { RiErrorWarningLine } from 'react-icons/ri';
import PantryMeals from 'components/Pantry/PantryMeals';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import Progress from 'components/Progress';
import BlurBackground from 'components/BlurBackground';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { VariableSizeList } from 'react-window';
import moment from 'moment';
import { useShopContext } from 'context/shopContext';

{
  TabPanel;
}

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PantryMealsSnacksStyle = styled.div`
  overflow-y: hidden;

  .tap-to-refuel {
    height: 42px;
    background: #f0faeb;
    padding: 13px 15px;
    color: #51635d;
    font-size: 14px;
    display: flex;
    align-items: center;
    .icon-warning-wrapper {
      margin-right: 4px;
      display: flex;
    }

    svg {
      color: #51635d;
      font-size: 19px;
      transform: rotate(180deg);
    }

    span {
      color: #52c41a;
      text-decoration: underline;
    }
  }
`;

const PantryStyle = styled.div`
  .MuiTab-textColorInherit.Mui-selected {
    border-bottom: 2px solid #52C41A !important;
  }

  .MuiTabs-root {
    min-height: auto;
    padding: 0 4px;
    border-bottom: 1px solid #F6F5F8;
  }

  .MuiBox-root {
    padding: 12px 0 0 !important;
  }

  .MuiTab-textColorInherit {
    width: 50%;
    min-height: 39px;
    padding: 5px 0 0 0;
  }

  .MuiTab-root.Mui-selected .MuiTab-wrapper {
    font-weight: 700;
  }

  .MuiTab-root .MuiTab-wrapper {
    color: #51635D;
    font-size: 14px;
    font-weight: 400;
  }

  .MuiTabs-indicator {
    display: none
  }

  .List {
    &::-webkit-scrollbar {
      width: 0
    }

    div > div::-webkit-scrollbar {
      width: 0
    } 
  }

  .meals-left-wrapper {
    margin: 0 15px 5px;
    color: #51635D;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .meals-left-container {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 11px;
      line-height: 12px;
    }

    .meals-count {
      width: 25px;
      height: 25px;
      margin-left: 6px;
      border: 1px solid #52C41A;
      border-radius: 20px;
      ${flexCenter};

      span {
        font-size: 8px;
        font-weight: 700;
      }
    }

    .log-meals-btn {
      width: 100px;
      height: 25px;
      background-color: transparent;
      color: #52C41A;
      border: 1px solid #52C41A !important;
      border-radius: 21px;
      font-weight: 700;
    }
  }

  .swipeable-list-item__trailing-actions {
    background-color: #0B1D17;
  }

  .swipeable-list-item__content {
    display: block;
  }

  .swipe-action__trailing {
    width: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
      margin-bottom: 10px;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .swipe-action > * {
    flex: 0;
  }

  .img-wrapper {
    position: relative;
    height: 70px;
    margin-right: 20px;

    img {
      width: 50px;
      height: 100%;
      border-radius: 8px;
      object-fit: contain;
    }

    span {
      position: absolute;
      bottom: -5px;
      left: -9px;
      padding: 3px 5px;
      color: #51635D;
      background-color: #fff;
      border: 1px solid #CBEDBA;
      border-radius: 10px;
      font-size: 8px;
      line-height: 8px;
    }
  }

  .meal-content {
    width: calc(100% - 70px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pantry-meal-title {
      margin-bottom: 9px;
      color: #0b1d17;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .meal-details > div:first-child {
      margin: 0;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      flex-grow: 1;
    }

    .fresh-card-content {
      width: 100%;
      height: 37px !important;
    }

    .fresh-card-container {
      width: 100%;
      margin: 0;
      padding: 5px;
      font-size: 10px;
      position: relative;
      &::after {
        content: '';
        width: 1px;
        height: 14px;
        background-color: #E0E4E8;
        position: absolute;
        right: -4px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child {
        border: 0;
      }
      span {
        color: #51635d;
        font-size: 9px;
        line-height: 12px;
      }

      span:first-child {
        font-weight: 700;
      }
    }
  }
  @media (min-width: 992px) {
    .List::-webkit-scrollbar {
      width: 8px
    }
  }
`;

function PantryMealsSnacks({ activeDay, getCalories, isLogMealsModal, setIsLogMealsModal,diaryMeals, setDiaryMeals, displaySteps }) {
 const {pantryMeals,setPantryMeals,awaitingMeals,setawaitingMeals,pantryMealsCount, pantryMealsSource, awaitingMealsSource, setPantryMealsSource, 
  setPantryMealsCount,loadPantryMeals,isGetMealLoading,}=useAppContext();
  const [value, setValue] = useState(0);
  // const [getPantryMeals, { isLoading: isGetMealLoading }] = useRequest();
  const [addOrderToDiary, { isLoading: isAddToDiaryLoading }] = useRequest();
  const [deletePantryMeals, { isLoading: isDeletePantryMeals }] = useRequest();
  const history = useHistory();
  const [getdiaryMeals, { isLoading: isGetdiaryMealLoading }] = useRequest();
  const listHeight = window.innerHeight - 394;
  const listHeightWithSteps = window.innerHeight - 558;
  const formatMonth = moment(activeDay).format('MM-YYYY');
  const formatDay = moment(activeDay).format('MM-DD-YYYY');
  const {isMobile} = useAppContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const [pantryLoading,setPantryLoading]=useState(false);
  const itemSize = (index) => index === pantryMeals.length ? 42 : 101;
  const listRef = useRef();
  const { consumedMealType } = useShopContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(window.ReactNativeWebView){
      loadPantryMeals();
    }
  }, []);


  useEffect(() => {
    getdiaryData();
  }, [activeDay]);

  useEffect(() => {
    listRef.current && listRef.current.resetAfterIndex(0);
  }, [pantryMeals.length])

  const convert = (groupedDiaryMeals) => {
    const res = {};
    let calcCals = 0;
    let consumedInfo = { fat: 0, carbs: 0, protein: 0 };

    // generate meal count
    if (groupedDiaryMeals) {
      groupedDiaryMeals.forEach((obj) => {
        const key = `${obj.id}${obj['New Lv1−Lv2']}`;
        if (!res[key]) {
          res[key] = { ...obj, count: 0 };
        }
        res[key].count += 1;
      });
    }

    // get total calories
    Object.values(res).map((meal) => {
      calcCals += meal.calories * meal.count;
      consumedInfo.fat += meal.fat * meal.count;
      consumedInfo.carbs += meal.carbs * meal.count;
      consumedInfo.protein += meal.protein * meal.count;
    });

    return {
      meals: Object.values(res),
      totalCals: calcCals,
      consumedInfo,
    };
  };

  const setUpPantryData = (id) => {
    const remainingPiaryData = pantryMealsSource.length && pantryMealsSource.map((item) => {
      if (item.orderLineId === id) {
        const quantity = item.qty;
        if (quantity >1) {
          return { ...item, qty: quantity - 1 }
        } else {
          return 'remove'
        }
      }
      else {
        return item;
      }
    }).filter((item)=>item !=='remove')
    setPantryMeals(remainingPiaryData);
    setPantryMealsCount(remainingPiaryData?.reduce((acc, val) => acc + parseInt(val.qty), 0));
    setPantryMealsSource(remainingPiaryData);
  }

  const onErrorPantryData = (id) => {
    const toupdate = pantryMeals.map((item) => {
      const data = pantryMeals.find((item) => item.orderLineId === id);
      if (item.orderLineId === id) {
        if (item.qty < 1) {
          return { ...item, qty: item.qty + 1 }
        } else {
          return data;
        }
      } else {
        return item;
      }
    })
    setPantryMeals(toupdate);
    setPantryMealsCount(toupdate?.reduce((acc, val) => acc + parseInt(val.qty), 0));

  }

  useEffect(() => {
    setPantryMeals(pantryMealsSource);
    setawaitingMeals(awaitingMealsSource);
  }, [value])

  const getdiaryData = () => {
    const timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone
    const route = `diary/getDiaryMeals?timeZone=${timeZone}`;
    getdiaryMeals({
      path: route,
    })
      .then((data) => {
        data?.diaryData?.map((item) => {
          for (let key in item) {
            if (key === formatMonth) {
              for (let childKey in item[key]) {
                if (childKey === formatDay) {
                  const groupedDiaryMeals = item[key][childKey];
                  setDiaryMeals(convert(groupedDiaryMeals))
                  return;
                } else {
                  setDiaryMeals([]);
                }
              }
            }
          }
        })
      })
      .catch(console.log);
  }

  const moveMealToDiary = async(id) => {
    const path = `diary/addOrderToDiary/${id}`;
    setUpPantryData(id);
    setPantryLoading(true);
    setTimeout(() =>{
      toast.success('Meal moved to diary successfully');
      setPantryLoading(false);
    }, 400);
    await addOrderToDiary({
      path,
      method: 'POST',
      body: {
        createdAt: new Date(activeDay).toISOString(),
        categoryType: consumedMealType
      },
    }).then( async()=>{
      await getdiaryData();
      setIsDisabled(false);
    })
      .catch((err) => {
        setIsDisabled(false);
        console.log(err);
        toast.error('Oops! something went wrong');
        onErrorPantryData(id);
        }
      ) 
  };

  const deletePantryMeal = (id) => {
    const path = `pantry/deletePantryMeals/${id}`;
    setUpPantryData(id);
    setTimeout(() =>{
      // off loader for 200
      toast.success('The meal was deleted successfully');
      setPantryLoading(false);
    }, 400);
    deletePantryMeals({
      path,
      method: 'Delete',
    })
      .then(() => {
        console.log('The meal was deleted successfully');
      })
      .catch(() => {
        toast.error('Oops! something went wrong');
        onErrorPantryData(id);
        setPantryLoading(false);
      });
  };

  const PantryRow = ({ index, style }) => { 
    if (index === pantryMeals.length && awaitingMeals.length !== 0) {
      return (
        <div style={style}>
          <div className="awaitingReceiptSection">
            <Typography>
              Your meals are awaiting receipt...
              <Link to="/shop/order">Check Order Status</Link>
            </Typography>
          </div>
        </div>
      )
    }
    if (index === pantryMeals.length && pantryMealsCount < 5 && awaitingMeals.length === 0) {
      return (
        <div style={style}>
          <div className="tap-to-refuel">
            <div className="icon-warning-wrapper">
              <RiErrorWarningLine />
            </div>
            Your are running low on meals,{' '}
            <span onClick={() => history.push('/shop/kitchen-homeplan')}>Tap to refuel</span>
          </div>
        </div>
      )
    }
    if (index <= pantryMeals.length ) {
      return (
        <div style={style}>
          <PantryMeals
            {...pantryMeals[index]}
            moveToPantryDate={moveMealToDiary}
            deletePantryMeal={deletePantryMeal}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
        </div>
      )
    }
    return (
      <div style={style}>
        <PantryMeals
          {...awaitingMeals[index - (pantryMeals.length + 1)]}
          swipeAbleBoolean={false}
          isAwaitingMeal={true}
          moveToPantryDate={moveMealToDiary}
          style={{ zIndex: -1 }}
        />
      </div>
    )
  };

  const itemCount=pantryMeals.length===0? (pantryMeals.length + awaitingMeals.length)+1: (pantryMeals.length + awaitingMeals.length+1)

  return (
    <PantryStyle>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Pantry" />
        <Tab label="Diary" />
      </Tabs>
      <TabPanel value={value} index={0}>
        {((pantryMeals.length < 1) && (awaitingMeals.length < 1)) ? (
          <Empty
            img="/images/dish.png"
            title="Your pantry is empty"
            info="Refuel your pantry with local healthy meals"
            btnText="Shop meals"
            btnDirection={() => history.push("/shop/kitchen-home")}
          />
        ) : (
          <>
            <div className='meals-left-wrapper'>
              <div className="meals-left-container">
                <div className='title'>Meals left</div>
                <div className='meals-count'><span>{pantryMealsCount}</span></div>
              </div>
              {isMobile && <button className="log-meals-btn" onClick={() => setIsLogMealsModal(true)}>Log Meals</button>}
            </div>
            <PantryMealsSnacksStyle>
              <VariableSizeList className="List" ref={listRef} itemCount={itemCount} itemSize={itemSize} height={displaySteps ? listHeightWithSteps : listHeight}  >
                {PantryRow}
              </VariableSizeList>
            </PantryMealsSnacksStyle>
          </>
        )}
        <LogMealsModal setIsLogMealsModal={setIsLogMealsModal}  isLogMealsModal={isLogMealsModal} activeDay={activeDay} />
        {(pantryLoading) && (
          <BlurBackground>
            <Progress />
         </BlurBackground>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DiaryTagContent setValue={setValue} diaryMeals={diaryMeals?.meals} displaySteps={displaySteps} />
      </TabPanel>
    </PantryStyle>
  );
}

export default memo(PantryMealsSnacks);
