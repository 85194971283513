import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { OrderCard } from 'components/elements/orderCard/OrderCard';
import { ReactComponent as EmptyPantry } from 'assets/shop/empty-order.svg';
import { Button } from 'components/elements/button/Button';
import OrderStatus from './order-status';
import { useHistory } from 'react-router';
import useCart from 'hooks/useCart';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { toast } from 'react-toastify';
import { theme } from 'shared/theme/theme';
import OrderLimitOops from './OrderLimitOops'

const EmptyWrapper = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:24px;
  &::-webkit-scrollbar {
    width: 0;
  }
  .empty-img-wrapper {
    display: flex;
    flex-direction: column;
    gap: 28.12px;
    align-items: center;
    margin-bottom: 24px;
    svg{
      height: 121px;
    }
  }
  .text{
    display:flex;
    flex-direction: column;
    gap: 8px;
  }
  .empty-title {
    color: ${theme?.colors?.primaryDarker};
    text-align: center;
    font-family: ${theme?.typography?.fontGilroy};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    }
  p {
    color: ${theme?.colors?.primaryDarker};
    text-align: center;
    font-family: ${theme?.typography?.fontGilroy};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: 19px;
    }
  @media (min-width: 992px) {
    height: 100%;
    justify-content: normal;
    align-items: normal;
    .order-list-container{
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
`;

const ButtonWrapper = styled.div`
button {
    width: 220px;
    height: 42px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 0px solid #DFE2E8;
    background: ${theme?.colors?.primaryDefault};
    cursor: pointer;
    font-family: ${theme?.typography?.fontGilroy};
}
`;

const allOrders = () => {
  const { VerifyProviderAndMealsFn, orderList, setKitchensList, cart } = useShopContext();
  const history = useHistory();
  const { addToCart } = useCart();
  const [updateCart, setUpdateCart] = useState();
  const [numOfMeals, setNumOfMeals] = useState(0);
  const [mealsDiff, setMealsDiff] = useState(0);
  const [clicked, setClicked] = useState(false);
  const { setCustomQtyMeal, isModal, setIsModal } = useAppContext();
  const [showOrderStatus, setShowOrderStatus] = useState(false);
  const [orderMainId, setOrderMainId] = useState();
  const [providerId, setProviderId] = useState();
  const [orderItems, setOrderItems] = useState({});
  const [showOrderAnim, setShowOrderAnim] = useState(true);
  const { isDesktop } = useAppContext();
  const [mealsExceedLimit, setMealsExceedLimit] = useState([]);
  const [orderKitchenUsername, setOrderKitchenUsername] = useState('');
  const [mealNotAvailability, setMealNotAvailability] = useState([]);
  const [mealAlertsList, setMealAlertList] = useState([]);
  let diff;
  
  const getMealQty = (meal) => {
    let mealQty = meal?.qty
    const selectedMealCart = cart?.find(mealCart => mealCart?.id === meal?.mealId)

    if (selectedMealCart) {
      mealQty += selectedMealCart?.qty
    }
    
    return { mealQty, cartMealQty: selectedMealCart?.qty }
  } 

  const isMealExceedOrderLimit = (meal) => getMealQty(meal).mealQty > meal?.orderLimit && meal?.orderLimit !== 0;

  const getNumberOfQuantity = (meal) => {
    const { cartMealQty, mealQty } = getMealQty(meal)

    if (cartMealQty === meal.orderLimit) return 0

    if (mealQty > meal.orderLimit && !cartMealQty) {
      return meal.orderLimit;
    }

    return meal.orderLimit - cartMealQty;
  }

  const handleMealsLimit = (mealItems) =>  {
    const mealsExceedLimitRef = [];
    const mealsAvailabilityRef = [];

    const meals = mealItems.meals?.map(meal => {
      if(!meal?.isRotationMealAvaialbleToday){
        mealsAvailabilityRef.push(meal)
      }
      if (!isMealExceedOrderLimit(meal)) return meal;

      mealsExceedLimitRef.push(meal)
      
      return { ...meal, qty:  getNumberOfQuantity(meal)}
    }
  ).filter(m => m.qty)

  setMealsExceedLimit(mealsExceedLimitRef)
  setMealNotAvailability(mealsAvailabilityRef);
  if(mealsExceedLimitRef?.length || mealsAvailabilityRef?.length){
    const combinedMeals = [...mealsExceedLimitRef, ...mealsAvailabilityRef];
    const uniqueMeals = Array.from(new Set(combinedMeals));
    setMealAlertList(uniqueMeals);
    setIsModal(true)
    }
  return { meals, mealsExceedLimitRef, mealsAvailabilityRef }
};  

  const handlePreOrder = async (mealItems) => {
    const kitchenUsername = mealItems?.kitchen?.username;
    setOrderKitchenUsername(kitchenUsername);
    const { meals, mealsExceedLimitRef, mealsAvailabilityRef } = handleMealsLimit(mealItems)
    if (!meals.length) return 

    const prevoiusMealData = {
      mealId: [],
      qty: {},
    };
    const { mealId, qty } = meals?.reduce((a, item) => {
      a.mealId.push(item?.mealId);
      a.qty[item?.mealId] = item.qty;
      return a;
    }, prevoiusMealData);
    const body = { providerId: Number(mealItems?.providerId), mealsId: mealId };
    await VerifyProviderAndMealsFn({ ...body })
      .then((data) => {
        if (data?.success) {
          setKitchensList((prev) => [...prev?.filter(item => item?.id !== data?.providerData?.id), data?.providerData]);
          const mealsLengthRes = data?.mealsData?.length || 0;
          const totalMeals = numOfMeals + mealsLengthRes;
          setNumOfMeals(totalMeals);
          setClicked(true);
          diff = mealId?.length - mealsLengthRes;
          setMealsDiff(diff);
          mealsLengthRes && data.mealsData.map(i => {
              const customQty = qty[i.id];
              i['qty'] = customQty;
              setUpdateCart(i);
              setCustomQtyMeal(true);
          })
          isDesktop && (mealsExceedLimitRef.length == 0 && mealsAvailabilityRef?.length == 0) && history.push(`/store/${kitchenUsername}`)
      }else{
          return toast.error("Certain meals have been removed or couldn't be found. Please try again.", { toastId: "error" });
      }     
     })
      .catch((e) => {
        toast.error(
          'Provider Not Found: The chosen provider is not listed for checkout. Please select a different provider.',
        );
      });
  };

  const completedOrders = orderList?.orders.filter(
    (orderItem) => orderItem.providerOrderAction?.action === 'completed' || orderItem.providerOrderAction?.action === 'refused',
  );
  const inProgressOrders = orderList?.orders.filter(
    (orderItem) => orderItem.providerOrderAction?.action === 'pending' || orderItem.providerOrderAction?.action === 'accepted',
  );

  const ErrorText = () => {
    if(mealsExceedLimit?.length && mealNotAvailability?.length){
      return "The item below can’t be added to the cart."
    } else if (mealsExceedLimit?.length){
      return "The item below exceed the the order limit and can’t be added to the cart."
    } else if (mealNotAvailability?.length){
      return "The item below is not available for today's menu and can't be added to the cart."
    } else {
      return "The item below exceed the the order limit and can’t be added to the cart."
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setClicked(false);
    }, [6000]);
  }, [clicked]);

  useEffect(async () => {
    if (updateCart) {
      await addToCart(updateCart);
    }
  }, [updateCart]);

  useEffect(() => {setIsModal(false)}, [])

  useEffect(() => {
    (mealsExceedLimit?.length || mealNotAvailability?.length) && setIsModal(true);

    return() => {
      setIsModal(false);
    }
  }, [mealsExceedLimit?.length, mealNotAvailability?.length])

  return (
    <>
      {inProgressOrders?.length > 0 && (
        <div>
          <h2 className="heading category">In Progress</h2>
          <div style={isDesktop ? { display: "flex", flexDirection: "column", gap: "24px" } : null}>
          {inProgressOrders
            .slice()
            .reverse()
            .map((orderItem, index) => (
              <div key={index} className="order-card-container-div">
                <OrderCard
                  key={index}
                  orderItem={orderItem}
                  orderList={orderList}
                  handlePreOrder={handlePreOrder}
                  setShowOrderStatus={setShowOrderStatus}
                  setOrderItems={setOrderItems}
                  setOrderMainId={setOrderMainId}
                  setProviderId={setProviderId}
                  setShowOrderAnim={setShowOrderAnim}
                  trackOrder={true}
                  inProgressOrders={true}
                />
              </div>
            ))}
            </div>
        </div>
      )}
      {completedOrders?.length > 0 && (
        <div style={{ marginTop: "10px"}}>
          <h2 className="heading category" style={{ marginTop: '48px' }}>
            Completed
          </h2>
          <div style={isDesktop ? { display: "flex", flexDirection: "column", gap: "24px" } : null}>
          {completedOrders
            .slice()
            .reverse()
            .map((orderItem, index) => (
              <div key={index} className="order-card-container-div">
                <OrderCard
                  key={index}
                  orderItem={orderItem}
                  orderList={orderList}
                  handlePreOrder={handlePreOrder}
                  setShowOrderStatus={setShowOrderStatus}
                  setOrderItems={setOrderItems}
                  setOrderMainId={setOrderMainId}
                  setProviderId={setProviderId}
                  setShowOrderAnim={setShowOrderAnim}
                  inProgressOrders={false}
                />
              </div>
            ))}
            </div>
        </div>
      )}
      {orderList?.orders?.length === 0 && (
        <EmptyWrapper theme={theme}>
          <div className="empty-img-wrapper">
            <EmptyPantry />
            <div className="text">
              <h2 className="empty-title">No order history</h2>
              <p>You don't have any orders</p>
            </div>
          </div>
          <ButtonWrapper theme={theme}>
            <Button
              type={'mobile_save'}
              title={'Order now'}
              onClick={() => history.push('/shop/kitchen-home')}
            />
          </ButtonWrapper>
        </EmptyWrapper>
      )}
      {showOrderStatus && (
        <OrderStatus
          setShowOrderStatus={setShowOrderStatus}
          orderItems={orderItems}
          handlePreOrder={handlePreOrder}
          orderMainId={orderMainId}
          providerId={providerId}
          numOfMeals={numOfMeals}
          mealsDiff={mealsDiff}
          clicked={clicked}
          showOrderAnim={showOrderAnim}
          setShowOrderAnim={setShowOrderAnim}
          setClicked={setClicked}
        />
      )}
      {(clicked && !isDesktop) && mealAlertsList?.length === 0 && (
        <div className="items-popup">
          {mealsDiff > 0 && (
            <div className="check">
              <img src="/icons/alarm-icon.svg" />
              <p>{mealsDiff} items out of Stock</p>
            </div>
          )}
          {numOfMeals > 0 && mealAlertsList?.length === 0 && (
            <div className="check">
              <img src="/icons/check-white.svg" />
              <p>{numOfMeals} items have been added to your cart.</p>
              <p onClick={() => history.push('/shop/cartScreen')}>
                <u>View cart</u>
              </p>
            </div>
          )}
        </div>
      )}
      {!!mealAlertsList?.length && isModal && 
        <OrderLimitOops mealAlertsList={mealAlertsList} setMealNotAvailability={setMealNotAvailability} setMealsExceedLimit={setMealsExceedLimit} kitchenUsername={orderKitchenUsername} setMealAlertList={setMealAlertList} setIsModal={setIsModal} isModal={isModal} text={<ErrorText />}/>
      }
    </>
  );
};

export default allOrders;
