import styled from 'styled-components';
import Title from './components/Title';
import { useHistory } from 'react-router-dom';
import Button from './components/Button';
import UserProgress from './components/UserProgress';
import PageTransition from './components/PageTransition';
import React, { useEffect, useState } from "react";
import { useRequest } from 'hooks/useRequest'
import EditCalories from './EditCalories';
import { useAppContext } from 'context/appContext';
import Progress from 'components/Progress';
import { useOnboardingContext } from '.';

const RecommendedCaloriesStyle = styled.div`
    padding: 48px 30px;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    background-color: #fff;
    height: 100%;
    .flex{
        flex: 1;
    }
    .edit_calories{
        display:flex;
        justify-content:center;
        margin-top:${window.innerHeight < 600 ? '-10px' : '80px'};
        column-gap:5px;
        margin-bottom:${window.innerHeight < 600 ? '30px' : '0px'};
     p{
        color: #949D9B;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        cursor:pointer;
     }
    }
    .user-progress{
        margin-top:56px;
        .macros-wrapper{
            max-height: 200px;
        }
    }
    .button{
        padding: 0 14px;
        .continue{
            width: 100%;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            background-color: #52C41A;
            border-radius: 31px;
            text-align: center;
            padding: 12px;
            color: #fff;
        }
    } 
    @media (max-width: 340px){
        .slick-track{
            height: 145px !important;
            .cals-wrapper{
                width: 155px;
                height: 155px;
            }
            .rc-progress-circle{
                width: 140px !important;
            }
        }
    }
`

export default function RecommendedCalories() {
    const history = useHistory();
    const [isSwipe, setIsSwipe] = useState(false);
    const [isEditCalories, setIsEditCalories] = useState(false);
    const [isAnimation, setIsAnimation] = useState(false);
    const { visitorData, setOnboardingUser, onboardingUser } = useOnboardingContext();
    const defaultCalories = visitorData?.profile?.dietMetrics?.calories;
    const [newCalories, setNewCalories] = useState(defaultCalories);

    const handleBack = () => {
        history.goBack();
    }
    const { isWebView, setupLocalUser, profile } = useAppContext();


    useEffect(() => {
        setNewCalories(visitorData?.profile?.dietMetrics?.calories || profile?.dietMetrics?.calories);
    }, [])

    useEffect(() => {
        isEditCalories ? (document.body.style.touchAction = 'none') : (document.body.style.touchAction = 'unset');
        return () => (document.body.style.touchAction = 'unset');
    }, [isEditCalories])

    const handleContinueClick = async() => {
        try {
        await setupLocalUser()
        if (isWebView) {
            isWebView.postMessage(JSON.stringify({ event: "onboarding_complete", action: "webview" }))
            return;
        }
        }
        catch(error) {
            console.log(error)
        }
    }

    return (
        <PageTransition>
            <RecommendedCaloriesStyle>
                <>
                    <div className='flex'>
                        <Title title="This is your daily recommended calories" subtitle={isSwipe ? "Swipe left to see your Сalories" : "Swipe right to see your Macros"} />
                        <div className='user-progress'>
                            <UserProgress
                                isSwipe={isSwipe}
                                setIsSwipe={setIsSwipe}
                                visitorData={visitorData}
                                newCalories={newCalories}
                                setNewCalories={setNewCalories}
                            />
                        </div>

                        <div className='edit_calories'>
                            <p onClick={() => { setIsEditCalories(true); setIsAnimation(true); }}>Edit target calories</p>
                            <img src="/icons/edit.svg" alt="Edit icon" className='edit-img' onClick={() => { setIsEditCalories(true); setIsAnimation(true); }} />
                        </div>

                    </div>
                    <div className='button'>
                        <button className="continue" onClick={handleContinueClick}>Start meal planning</button>
                    </div>
                </>
            </RecommendedCaloriesStyle>
            {isEditCalories &&
                <EditCalories
                    isAnimation={isAnimation}
                    setIsAnimation={setIsAnimation}
                    setIsEditCalories={setIsEditCalories}
                    visitorData={visitorData}
                    newCalories={newCalories}
                    setNewCalories={setNewCalories}
                />
            }
        </PageTransition>
    )
}