import { useState, useEffect } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import { GilroyFontWrapper } from 'shared/theme/style';
import RegularInput from 'components/Input/RegularInput';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import { motion } from 'framer-motion';
import MultiScreenStyle from './MultiScreenStyle';
import { toast } from 'react-toastify';
import { useShopContext } from 'context/shopContext';
import { useRef } from 'react';
import { useDebounce } from "use-debounce/lib";
import { useHistory } from 'react-router';

const CurrentWeightStyle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  .containerr {
    input {
      height: 44px;
      border: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
      padding-left: 16px;
      &:hover,&:focus {
        border-color: #00092D !important;
    }
    }
    .suffix {
      right: 16px;
      color: ${({ theme }) => theme?.colors?.lighterGrey};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export default function TargetWeight({ activeModalStack, setActiveModalStack, isHealthGoalFlow, nextScreen, modal, totalPagesCount }) {
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
    }, 0);
  };

  const [idealWeight, setIdealWeight] = useState('');
  const { healthGoal, updatedHealthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const idealWeightRef = useRef();
  const toastId = useRef(null);
  let errorOccurred = false;
  const [SearchedValue] = useDebounce(idealWeight, 800);
  const isWeightNotValid = (updatedHealthGoal?.selfMealPlan?.healthGoal === "weight-gain" && Number(idealWeight) <= updatedHealthGoal?.weight) || 
  (updatedHealthGoal?.selfMealPlan?.healthGoal === "weight-loss" && Number(idealWeight) >= updatedHealthGoal?.weight)
  const isSaveDisabled = !idealWeight || isWeightNotValid || Number(idealWeight) === healthGoal?.selfMealPlan?.idealWeight;

  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleChange = (e) => {
    let weight = e?.target?.value;
    const regex = /^(0|[0-9]\d*)(,\d{0,2})?$/;
    if (weight.length <= 3 && regex.test(weight)) {
      setIdealWeight(weight);
      setUpdatedHealthGoal((prev) => ({
        ...prev,
        selfMealPlan: {
            ...prev.selfMealPlan,
            idealWeight: Number(weight)
        }
      }));
      if (toast.isActive(toastId.current)) {
        toast.dismiss(toastId.current);
        toastId.current = null;
      }
      errorOccurred = false; // Reset the error flag
    } else {
      const validWeight = weight.slice(0, -2);
      setIdealWeight(validWeight);
      errorOccurred = true;
    }
  };
  useEffect(() => {
    setIdealWeight(updatedHealthGoal?.selfMealPlan?.idealWeight || '');
  }, []);

  useEffect(() => {
    if (String(SearchedValue).length > 0) {
      if (
        (updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-loss' || updatedHealthGoal?.selfMealPlan?.healthGoal === 'lose-weight') &&
        Number(SearchedValue) >= updatedHealthGoal?.weight
      ) {
        setIdealWeight(SearchedValue);
        if (!errorOccurred) {
          if (toast.isActive(toastId.current)) {
            toast.dismiss(toastId.current); // Dismiss previous error message
          }
          toastId.current = toast.error(
            'Please enter a weight less than your current weight for weight loss goals.',
          );
          errorOccurred = true;
        }
      } else if (
        (updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-gain' || updatedHealthGoal?.selfMealPlan?.healthGoal === 'bulkup') &&
        Number(SearchedValue) <= updatedHealthGoal?.weight
      ) {
        setIdealWeight(SearchedValue);
        if (!errorOccurred) {
          if (toast.isActive(toastId.current)) {
            toast.dismiss(toastId.current); // Dismiss previous error message
          }
          toastId.current = toast.error(
            'Please enter a weight greater than your current weight for weight gain goals.',
          );
          errorOccurred = true;
        }
      }
    }
  }, [SearchedValue]);

  useEffect(() => {
    if (idealWeightRef?.current) {
      idealWeightRef?.current?.focus();
    }
  }, [idealWeightRef?.current]);

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack((prev) => [...prev.filter(item => item?.modal !== modal?.modal)]);
  }

  return (
    <motion.div
      initial={animation?.initial}
      animate={{ opacity: 1, x: 0 }}
      exit={animation?.exit}
      transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
    >
      <GilroyFontWrapper>
        <CurrentWeightStyle style={{ height: window.innerHeight }} theme={theme}>
          <MultiScreenStyle>
            <MobileHeader
              text={'Target weight'}
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={
                !isHealthGoalFlow
                  ? () =>
                      setActiveModalStack((prev) => [
                        ...prev.filter((item) => item?.modal !== modal?.modal),
                      ])
                  : false
              }
            />
            <div className="containerr">
              <p className="subtitle">
                Knowing your ideal weight helps us personalize your meal plan to achieve your health
                goals
              </p>

              <RegularInput
                type="tel"
                suffix={'lbs'}
                pattern="\d*"
                value={idealWeight}
                onChange={handleChange}
                ref={idealWeightRef}
                autoFocus={true}
              />
            </div>
            <div className="button_holder">
              {isHealthGoalFlow ? (
                <Button
                  type={'button_combo'}
                  iconLeft={<ChevronLeft />}
                  iconRight={<ChevronRight />}
                  comboLeftTitle={'Back'}
                  comboLeftClick={handleBackClick}
                  comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}
                  comboRightTitle={'Continue'}
                  comboRightDisabled={isWeightNotValid || !idealWeight || Number(idealWeight) === 0}
                />
              ) : (
                <Button type={'mobile_save'} title={'Save'} onClick={handleSave} isDisabled={isSaveDisabled}/>
              )}
            </div>
          </MultiScreenStyle>
        </CurrentWeightStyle>
      </GilroyFontWrapper>
    </motion.div>
  );
}
