import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const AddressesListWrapper = styled.div`
.address-header {
    .icon-wrapper {
      z-index: 3;
    }
    .left {
      display: none;
    }
    &.addressHeaderValue {
      .left {
        display: flex;
      }
      .close {
        display: none;
      }
    }
  }

  .input-focus {
    position: absolute;
    top: -99vh;
  }

  .input-mask {
    position: fixed;
    inset: 0;
    z-index: 2;
  }

  .saved-addresses-header {
    padding: 0 24px;
    color: ${() => theme.colors.deepForestGreen};
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
  }

  .addresses-info-wrapper {
    padding: 0 11px 0 12px;
    .title {
      text-transform: capitalize;
      line-height: 24px;
    }
  }

  .save-address-box {
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: ${() => theme.colors.secondary};
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    & > div {
      height: 100%;
    }
  }

  @media (min-width: 992px) {
    width: 400px;
    height: 662px;
    background-color: ${theme.colors.secondary};
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);

    .address-desktop-header {
      padding: 16px 16px 0;
      .address-close-wrapper {
        margin-bottom: 15px;
        display: flex;
        cursor: pointer;
      }
      .address-header-title {
        margin-bottom: 8px;
        color: ${theme.colors.primaryDarker};
        font-size: 16px;
        font-weight: 700;
      }
    }

    .save-address-box {
      position: absolute;
      top: 0;
      left: 0;
    }

    .map-img-wrapper {
      height: 430px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .saved-addresses-header {
      margin-bottom: 4px;
      line-height: 20px;
    }

    .addresses-info-wrapper {
      overflow-y: auto;
      height: 499px;
      padding: 0 16px 16px;
      & > div {
        cursor: pointer;
        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .address {
      cursor: pointer;
    }
  }

  @media (max-height: 720px) {
    height: 100%;
  }
`;

export const UsePlacesAutocompleteWrapper = styled.div`
  position: relative;
  padding: 0 24px;

  .input-container {
    position: relative;
    margin: 14px 0 24px;
    display: flex;
    align-items: center;
    &.error {
      margin: 14px 0 8px;
      input {
        border: 1px solid #fec8b4;
        background: #fef1f0;
        color: #eb3223;
      }
    }
  }

  .place-autocomplete-input {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 44px;
    padding: 0 38px 0 14px;
    background: ${() => theme.colors.secondary};
    color: ${() => theme.colors.primaryDarker};
    border: 1px solid #e0e3e0;
    border-radius: 6px;
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    &:focus,
    &:hover {
      border-color: ${() => theme.colors.primaryDarker} !important;
    }
  }

  .clear-input {
    position: absolute;
    top: auto;
    right: 10px;
    z-index: 3;
    width: 18px;
    height: 18px;
  }
  .error-message {
    color: #eb3223;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      position: relative;
      z-index: 3;
      list-style: none;
      display: flex;
      align-items: center;
      img {
        margin-right: 7px;
      }
      p {
        color: ${() => theme.colors.primaryDefault};
        font-family: ${() => theme.typography.fontGilroy};
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      & + li {
        margin-top: 30px;
      }
    }
  }

  @media (min-width: 992px) {
    padding: 0 16px;

    .input-container {
      margin: 0 0 12px;
    }

    ul {
      margin-top: 14px;
      li + li {
        margin-top: 28px;
      }
    }
  }
`;
