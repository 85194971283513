
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import styled from 'styled-components';
import { VscInfo } from 'react-icons/vsc';

const TooltipStyle = styled.div`
    position: absolute;
    top: 0;
    left: calc(100% + 7px);
    display: inline-block;
`

const overlayInnerStyle = {
    backgroundColor: '#F0FAEB',
    border: '1px solid #E0E3E0',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    color: '#51635D',
    fontSize: '11px',
    textAlign: 'left',
    maxWidth: '250px',
    width: '100%'
}

export default function CustomToolTip({tooltipText, placement, showArrow=false}){

    return (
        <TooltipStyle>
            <Tooltip overlayClassName={`custom_tooltip ${!showArrow ? 'noArrow' : ''}`} overlayInnerStyle={overlayInnerStyle} placement={placement} overlay={<span>{tooltipText}</span>}>
                <VscInfo style={{fontSize: '8.5px', display: 'block'}} />
            </Tooltip>
        </TooltipStyle>
    )
}