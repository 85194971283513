import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const VoidFormModel = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 111;
  background-color: rgb(28 28 28 / 60%);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 0px;
  font-family: 'Roboto';
  font-style: normal;

  .invite-box {
    position: fixed;
    width: 440px !important;
    height: 531px !important;
    padding-bottom: 35px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .close-img-div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      img {
        cursor: pointer;
      }
    }
    .send-invite {
      font-weight: 700;
      font-size: 16px;
      color: #0b1d17;
      padding-bottom: 11px;
      border-bottom: 1px solid #f6f5f8;
      padding-left: 20px;
      border: none;
      p {
        padding-left: 0px !important;
      }
    }
    hr {
      width: 400px;
      background: rgb(81, 99, 93);
      opacity: 0.1;
    }
    .meal-container {
      margin: 13px 0px 10px 30px;
      display: flex;
    }
    .title {
      font-weight: 500;
      font-size: 13px;
      color: #51635d;
      line-height: 14.06px;
      padding-bottom: 11px;
      border-bottom: 1px solid #f6f5f8;
      padding-left: 35px;
      padding-top: 28px;
      border: none;
    }
    .image {
      position: relative;
    }
    .meal_img {
      width: 64px;
      height: 64px;
      margin-right: 14px;
      object-fit: contain;
      display: block;
      margin-left: 10px;
    }
    .meal_name {
      font-size: 14px;
      line-height: 16px;
      color: #51635d;
      cursor: default;
      flex: 1;
      margin-top: 20px;
    }

    .coupon-form {
      display: flex;
      flex-direction: column;

      .multiple-form-div {
        height: 230.4px;
        overflow-y: scroll;
        overflow-x: hidden;
        scroll-behavior: smooth;
        overflow: hidden;

        ::-webkit-scrollbar {
          width: 5px;
          height: 100px;
        }
      }

      .input-container {
        margin-top: 0 !important;
        width: auto;
        label {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #51635d;
          margin-bottom: 7px;
        }
        div:nth-child(1) {
          display: flex;
          flex-direction: column;
        }
      }

      input {
        width: 230px;
        height: 44px;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(224, 227, 224);
      }
      .cEOuE {
        width: 230px;
        margin: 0;
        .select-option:hover {
          background: #f6f5f8;
          color: #949d9b;
        }
        .selected-value.selectedValue {
          border-color: #e0e3e0;
        }
        .selected-value {
          color: #949d9b;
        }
      }

      .name-type {
        display: flex;
        padding-left: 34px;
        gap: 16px;
        margin-bottom: 24px;
      }

      .selectWrapper {
        display: flex;
        flex-direction: column;
        label {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #51635d;
          margin-bottom: 7px;
        }
        .css-yk16xz-control,
        .css-xd1qmr-control {
          width: 230px;
          height: 44px;
          border: 1px solid #e0e3e0;
          border-radius: 6px;
        }
        .css-1wa3eu0-placeholder,
        .css-1uccc91-singleValue {
          color: #949d9b !important;
          top: 38% !important;
        }
        // .css-1hb7zxy-IndicatorsContainer{
        //    padding-right: 10px;
        //    margin-top: -16px;
        // }
        .css-2613qy-menu {
          color: #949d9b !important;
        }
        .image {
          margin-top: -18px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      textarea {
        height: 140px;
        padding: 15px;
        resize: none;
        width: 370px;
        border: 1.5px solid rgb(224, 227, 224);
        border-radius: 6px;

        ::placeholder {
          color: #949d9b;
          font-size: 14px;
          line-height: 16px;
          font-family: 'Roboto', sans-serif;
        }
      }

      .publish {
        display: flex;
        justify-content: center;

        button {
          width: 140px;
          height: 42px;
          background: #f08b00;
          border-radius: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          cursor: pointer;
        }

        button: disabled {
          background-color: #e0e3e0;
          color: #949d9b;
          cursor: not-allowed;
        }
      }
    }
  }
  .meal-card {
    height: 25%;
    overflow: auto;
  }
  .meal-card::-webkit-scrollbar {
    width: 10px; /* increase the width of the scrollbar */
  }

  .meal-card::-webkit-scrollbar-thumb {
    background-color: #999; /* set the color of the scrollbar thumb */
    border-radius: 10px; /* round the corners of the scrollbar thumb */
  }
  @media (max-width: 992px) {
    .invite-box {
      width: 85% !important;
      height: 100%;
      border-radius: 0 !important;
      padding-bottom: 0 !important;
      hr {
        width: 335px;
      }

      .send-invite {
        display: flex;
        justify-content: center;
        padding-left: 0 !important;
        color: #51635d !important;
        font-size: 18px !important;
        line-height: 24px;
      }

      textarea {
        width: 287px !important;
      }

      .close-img-div {
        display: flex;
        justify-content: flex-start !important;
        padding: 20px 0px 0px 20px !important;
        img {
          cursor: pointer;
        }
      }
    }
    .meal-container {
      margin: 13px 0px 10px 15px !important;
    }
    .meal-card {
      height: 22%;
      overflow: auto;
    }
    .name-type {
      display: flex;
      flex-direction: column !important;
      align-items: center !important;
      padding: 0px !important;
    }
    .coupon-form {
      height: calc(100vh - 200px);

      .multiple-form-div {
        height: calc(100vh - 225px) !important;
        height: auto !important;

        .sc-hgIrPW {
          .input-container {
            div:nth-child(1) {
              width: 100% !important;
            }
          }
        }
      }
      .input-container {
        width: 100% !important;
        display: flex;
        justify-content: center;

        div:nth-child(1) {
          width: 80% !important;
        }
      }
      input {
        width: 100% !important;
      }

      .selectWrapper {
        width: 80% !important;

        .css-yk16xz-control,
        .css-xd1qmr-control {
          width: 100% !important;
        }
      }
    }

    .publish {
      height: 20px !important;
      margin-top: 18px;
      button {
        width: 150px !important;
      }
    }

    .react-datepicker {
      right: 50px !important;
    }
  }
`;
export default function VoidForm({
  setIsModal,
  selectedMeals,
  fetchAllProviders,
  setIsOrderDetailsActive,
}) {
  const { isMobile } = useAppContext();
  const handleFormClose = () => {
    setIsModal(false);
  };
  const [updateNewDisbursement] = useRequest(`financials/disbursements/void`, 'put');
  const [note, setNote] = useState('');
  const [voidDisabled, setVoidDisabled] = useState(false);
  const finalObject = Object.entries(selectedMeals).map(([orderId, meals]) => {
    return {
      orderMainId: Number(orderId),
      disbursementId: meals.disbursementId,
      orderLineIds: meals.data.map((meal) => ({ id: meal.id, note: note })),
    };
  });

  const onDisbursementClick = useCallback((finalObject) => {
    updateNewDisbursement({ body: { orders: finalObject, isVoided: true } })
      .then((result) => {
        toast.success('Items has been void successfully');
        fetchAllProviders();
        setIsModal(false);
        isMobile && setIsOrderDetailsActive(false);
      })
      .catch((e) => {
        setVoidDisabled(false);
        if (e.message != 'jwt expired') {
          toast.error('Oops!, something went wrong');
        }
      });
      setVoidDisabled(true);
  }, []);

  const ordersToMap = useMemo(() => {
    let resp = [];
    for (let key in selectedMeals) {
      const item = selectedMeals[key];
      for (let index = 0; index < item.data.length; index++) {
        const element = item.data[index];
        resp.push(element);
      }
    }
    return resp;
  }, [selectedMeals]);

  return (
    <VoidFormModel>
      <div className="invite-box">
        <div className="close-img-div">
          <img src="/images/add.svg" alt="cross Icon" onClick={handleFormClose} />
        </div>
        <div className="send-invite">{<p>Void</p>}</div>
        <hr />
        <div className={ordersToMap.length > 1 ? 'meal-card' : ''}>
          {ordersToMap?.map(({ data: { img, name } }) => {
            return (
              <div className="meal-container">
                <div className="image">
                  <img src={img ? img : '/images/dish.png'} className="meal_img" />
                </div>
                <h2 className="meal_name">{name}</h2>
              </div>
            );
          })}
        </div>
        <hr />

        <div className="coupon-form">
          <div className="multiple-form-div">
            <div className="title">Why this item has been voided</div>
            <div className="name-type">
              <textarea
                placeholder="Placeholder Text"
                autoComplete="off"
                type="textarea"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                maxLength={160}
              />
            </div>
          </div>

          <div className="publish">
            {
              <button
                onClick={() => {
                  onDisbursementClick(finalObject);
                }}
                disabled={voidDisabled}
              >
                Void
              </button>
            }
          </div>
        </div>
      </div>
    </VoidFormModel>
  );
}
