import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CustomizePalGender from './CustomizePalGender';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import { DEFAULT_ACTIVITY } from '../../constants';

const CustomizePalStyle = styled.div`
  .customize-pal-wrapper {
    flex-direction: column;

    .MuiTab-root .MuiTab-wrapper {
      font-size: 18px;
    }

    .form-group-wrapper {
      display: flex;
      flex-wrap: wrap;

      .form-group {
        width: 28%;
        margin-right: 5%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        input {
          width: 46px;
          height: 46px;
          padding: 0;
          font-size: 16px;
          text-align: center;
          -webkit-color: #383737;
        }

        label {
          margin-bottom: 0;
        }
      }
    }

    .btn-switcher div.btns-wrapper {
      width: 123px;

      .switcher:after {
        content: 'No';
        width: 58px;
        font-size: 18px;
        line-height: 28px;
      }

      .checkbox:checked + .switcher:after {
        content: 'Yes';
        left: 60px;
      }

      .layer:before {
        content: 'Yes';
        right: 22px;
        font-size: 18px;
      }

      .layer:after {
        content: 'No';
        left: 22px;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 767px) {
    .customize-pal-wrapper .title-wrapper {
      margin-bottom: 15px;
      flex-wrap: wrap;

      .title {
        font-size: 18px;
      }
    }

    .form-section.customize-pal-wrapper {
      margin-bottom: 40px;
      gap: 0;

      .form-group-wrapper {
        flex-direction: column;

        .form-group {
          width: auto;
          margin: 0 0 15px 0;

          label {
            margin-right: 15px;
          }

          input {
            width: 43px;
            height: 43px;
            -webkit-color: #383737;
          }
        }
      }
    }
  }
`;

{
  TabPanel;
}

export default function CustomizePal({
  customizeValue,
  setCustomizeValue,
  useCustom,
  setUseCustom,
  activityType,
  form,
  setForm,
}) {
  const [value, setValue] = useState(0);
  const [displayMen, setDisplayMen] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 const isEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length || !keys1.every(key => keys2.includes(key))) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!isEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }
    return true;
  }

  useEffect(()=>{
    if (form.id && form.PAL) {
      const defaultActivity = DEFAULT_ACTIVITY[form.PAL_formula];
      const updatedPal = form.PAL;
      const isPalEqual = isEqual(defaultActivity, updatedPal);
      if(!isPalEqual){
        setUseCustom(true);
      }
    }
  },[form]);

  return (
    <CustomizePalStyle>
      <div className="form-section customize-pal-wrapper">
        <div className="title-wrapper">
          <div className="title">Customize PAL</div>
          <div className="btn-switcher">
            <div className="btns-wrapper">
              <input
                type="checkbox"
                className="checkbox"
                checked={useCustom}
                onClick={() => setUseCustom(!useCustom)}
              />
              <div className="switcher"></div>
              <div className="layer"></div>
            </div>
          </div>
        </div>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Men" onClick={() => setDisplayMen(true)} />
          <Tab label="Women" onClick={() => setDisplayMen(false)} />
        </Tabs>
        <div className={classNames('section-wrapper', { active: displayMen })}>
          <CustomizePalGender
            useCustom={useCustom}
            gender={'men'}
            customizeValue={customizeValue}
            setCustomizeValue={setCustomizeValue}
            activityType={activityType}
            form={form}
            setForm={setForm}

          />
        </div>
        <div className={classNames('section-wrapper', { active: !displayMen })}>
          <CustomizePalGender
            useCustom={useCustom}
            gender={'women'}
            customizeValue={customizeValue}
            setCustomizeValue={setCustomizeValue}
            activityType={activityType}
            form={form}
            setForm={setForm}
          />
        </div>
      </div>
    </CustomizePalStyle>
  );
}
