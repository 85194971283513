import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const KitchenHomeDesktopWrapper = styled.div`
  min-height: calc(100vh - 77px);
  padding-top: 24px;
  background-color: ${theme.colors.secondary};

  .kitchen-home-container {
    max-width: 1254px;
    margin: 0 auto;
  }
`;

export const MealDetailsModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: #0b1d17a6;
  display: flex;
  align-items: center;
  justify-content: center;

  .meal-details-mask {
    position: fixed;
    inset: 0;
  }

  .meal-details-motion {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-color: ${theme.colors.secondary};
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;
