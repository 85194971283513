import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const MessageComponent = styled.div`
  padding: 21px 5px 18px 21px;
  background: ${theme.colors.successLightest};
  gap: 2px;
  max-width: 472px;
  display: flex;
  flex-direction: column;
  .message-head {
    color: ${theme.colors.successDark};
    font-family: ${theme.typography.fontInter};
    font-size: ${theme.typography.fontRegular};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .message-desc {
    color: ${theme.colors.blackDefault};
    font-family: ${theme.typography.fontInter};
    font-size: ${theme.typography.fontRegular};
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

function Message({ title, message }) {
  return (
    <MessageComponent theme={theme}>
      <h5 className="message-head">{title}</h5>
      <p className="message-desc">{message}</p>
    </MessageComponent>
  );
}

export default Message;
