import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { Button } from 'components/elements/button/Button';
import { useHistory } from 'react-router';

const EditCaloriesStyle = styled.div`
  padding: 0 20px;
  position: fixed;
  z-index: 10;
  inset: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);

  &.fadeOut {
    animation: fadeOut 200ms ease forwards;
  }
  .wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
  }
  .edit_calories_popup {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px;
    width: 100%;
    position: relative;
    z-index: 2;
    margin-bottom: 250px;
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #0b1d17;
    }
    .button {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      background-color: #52c41a;
      border-radius: 31px;
      gap: 8px;
      padding: 12px;
      width: 100%;
      color: #fff;
      &:disabled {
        background-color: #e0e3e0;
        color: #949d9b;
        .icon {
          color: #949d9b;
        }
      }
    }
    &.animation {
      animation: fadeInOutBottom 400ms ease forwards;
    }
  }
  .form_group {
    position: relative;
    margin-top: 24px;
    margin-bottom: 32px;
    .form_control {
      width: 100%;
      height: 44px;
      background: #ffffff;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      padding: 0 16px;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 16px;
      color: #51635d;
    }
    input {
      &:hover,:focus {
        border-color: #00092D !important;
    }
    }
    label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #949d9b;
    }
  }

  @keyframes fadeInOutBottom {
    from {
      transform: translateY(150%);
      opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export default function EditCalories({
  setIsEditCalories,
  isAnimation,
  setIsAnimation,
  newCalories,
  setNewCalories,
}) {
  const caloriesRef = useRef();
  const { setUpdatedHealthGoal, setUpdatedProfile, updatedHealthGoal } = useShopContext();
  const [updateCalories, { isLoading:isCaloriesUpdating }] = useRequest('profile/visitor-onboard/v2', 'post');
  const [visitorOnboard, { isLoading }] = useRequest('profile/visitor-onboard/v2', 'get');
  const { profile, user, setUser, setProfile, isWebView} = useAppContext();
  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  useEffect(() => {
    if (caloriesRef?.current) {
      caloriesRef?.current?.focus();
    }
  }, [caloriesRef?.current]);

  const handleChange = (e) => {
    const inputValue = e?.target?.value;
    if (inputValue > 9999) {
      return;
    }
    setNewCalories(inputValue);
  };

  const handleClick = async () => {
    try {
      const updatedHealthGoall = (lastLoginUrl === '/shop/track-quiz')
        ? {
          ...updatedHealthGoal,
          selfMealPlan: {
            ...updatedHealthGoal.selfMealPlan,
            preferredDiet: {
              carbsPercent: 40,
              proteinPercent: 30,
              fatPercent: 30,
              type: "balanced",
            },
            allergies: [],
            dietaryPreferences: [],
            customDietMetrics: {
              ...updatedHealthGoal.selfMealPlan.customDietMetrics,
              customCalories: Number(newCalories),
              isActive: true,
            },
          },
        }
        : {
          ...updatedHealthGoal,
          selfMealPlan: {
            ...updatedHealthGoal.selfMealPlan,
            customDietMetrics: {
              ...updatedHealthGoal.selfMealPlan.customDietMetrics,
              customCalories: Number(newCalories),
              isActive: true,
            },
          }
        };

      await setUpdatedHealthGoal(updatedHealthGoall);
      const res = await updateCalories({ body: updatedHealthGoall });
      if (res) {
        setUpdatedProfile(res?.data?.profile);
        setProfile((prevState) => ({...prevState,dietMetrics: {...prevState.dietMetrics,calories: newCalories}}));
        setIsAnimation(false);
        setTimeout(() => {
          setIsEditCalories(false);
        }, 200);
      }
    } catch (err) {
      if (err != 'jwt expired') {
        toast.error(err);
      }
    }
  };

  return (
    <EditCaloriesStyle className={`${!isAnimation ? 'fadeOut' : ''}`}>
      <div
        className="wrap"
        onClick={() => {
          setIsAnimation(false);
          setTimeout(() => {
            setIsEditCalories(false);
          }, 200);
        }}
      ></div>
      <div
        className={`edit_calories_popup ${isAnimation ? 'animation' : ''}`}
        style={{ marginBottom: !isWebView ? '250px' : '' }}
      >
        <div className="title">Daily calories</div>
        <div className="form_group">
          <input
            id="calories"
            type="number"
            pattern="\d*"
            onChange={handleChange}
            ref={caloriesRef}
            value={newCalories}
            name=""
            className="form_control"
            autoFocus
          />
          <label for="calories">cal</label>
        </div>
        <Button 
            isDisabled={
            !newCalories ||
            newCalories === profile?.dietMetrics?.calories || isCaloriesUpdating
          }
          onClick={handleClick}
          title='Update'
          type="mobile_save"
        />
      </div>
    </EditCaloriesStyle>
  );
}
