import { useEffect, useRef } from 'react';
import PrintShape from './PrintShape';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'components/elements/button/Button';
import { PrintOrderWrapper } from './style';
import { useKitchenContext } from 'context/kitchenContext';

export default function PrintOrder({
  selectedOrder,
  setIsPrint,
  checkedOrders,
  setCheckedOrders,
  setOrderNumber,
  setOrderDataPageNumber,
  setSelectedTabId,
  setFilterQuery,
  setSort,
  setSortBy
}) {
  const selectedOrderRef = useRef();
  const checkedOrdersRef = useRef();
  const history = useHistory();
  const {setOrderData} = useKitchenContext();
  const printSelectedOrder = useReactToPrint({
    content: () => selectedOrderRef.current,
  });
  const printCheckedOrders = useReactToPrint({
    content: () => checkedOrdersRef.current,
  });

  const viewOrder = () => {
    history.push('/kitchen/orders', { fromPrint: true });
    if (localStorage.getItem("orderDetails")) {
      setOrderData();
      const orderDetailsString = localStorage.getItem('orderDetails');
      const orderDetailsParsed = JSON.parse(orderDetailsString);
      setOrderDataPageNumber(orderDetailsParsed?.pageNumber);
      setSort(orderDetailsParsed?.sort);
      setSortBy(orderDetailsParsed?.sortBy);
    }
    if (localStorage.getItem("selectedOrderDetails")) {
      const selectedOrderDetailsString = localStorage.getItem('selectedOrderDetails');
      const selectedOrderDetailsParsed = JSON.parse(selectedOrderDetailsString);
      setFilterQuery(selectedOrderDetailsParsed?.filterQuery);
    }
  };

  useEffect(() => {
    localStorage.removeItem('selectedOrder');

    const selectedOrderString = localStorage.getItem('checkedOrders');
    const selectedOrderParsed = JSON.parse(selectedOrderString);
    setCheckedOrders(selectedOrderParsed);

    if (localStorage.getItem("orderDetails")) {
      const orderDetailsString = localStorage.getItem('orderDetails');
      const orderDetailsParsed = JSON.parse(orderDetailsString);
      setSelectedTabId(orderDetailsParsed?.selectedTabId);
      setOrderNumber(orderDetailsParsed?.orderNumber);
      setOrderDataPageNumber(orderDetailsParsed?.pageNumber);
      setFilterQuery(orderDetailsParsed?.filterQuery);
      setSort(orderDetailsParsed?.sort);
      setSortBy(orderDetailsParsed?.sortBy);
    }
  }, []);

  return (
    <PrintOrderWrapper>
      <div className="print-title">Print order</div>
      <div className="print-buttons">
        <Button
          title={checkedOrders?.length > 1 ? 'View order list' : 'View order'}
          type="secondary"
          onClick={viewOrder}
        />
        {!!Object.keys(selectedOrder)?.length ? (
          <Button
            title="Print"
            type="dark"
            iconLeft={<img src="/icons/printer-white.svg" alt="Recept Icon" />}
            onClick={printSelectedOrder}
          />
        ) : (
          <Button
            title="Print"
            type="dark"
            iconLeft={<img src="/icons/printer-white.svg" alt="Recept Icon" />}
            onClick={printCheckedOrders}
          />
        )}
      </div>
      {!!Object.keys(selectedOrder)?.length && (
        <div className="print-mode-wrapper" ref={selectedOrderRef}>
          <PrintShape selectedOrder={selectedOrder} />
        </div>
      )}
      {!!checkedOrders?.length && (
        <div className="print-mode-wrapper" ref={checkedOrdersRef}>
          {checkedOrders.map((checkOrder, index) => (
            <div key={index} className="PrintShapeWrapper">
              <PrintShape checkOrder={checkOrder} />
            </div>
          ))}
        </div>
      )}
    </PrintOrderWrapper>
  );
}
