import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import MultiScreenStyle from './MultiScreenStyle';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import MobileHeader from 'components/elements/MobileHeader';
import { Button } from 'components/elements/button/Button';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router';

const GenderStyle = styled.div`
  .gender_card_holder {
    .card {
      margin: 6px 0;
    }
  }
`;

export default function Gender({ activeModalStack, setActiveModalStack, isHealthGoalFlow, nextScreen, modal, totalPagesCount }) {
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });
  const [gender, setGender] = useState('');
  const { healthGoal, setUpdatedHealthGoal, updatedHealthGoal, saveDietPreferences } = useShopContext();
  const isSaveDisabled = healthGoal?.gender === gender?.[0];
  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
    }, 0);
  };

  const handleChange = (value) => {
    setUpdatedHealthGoal((prev) => ({
      ...prev,
      gender: value,
    }));
  };

  useEffect(() => {
    setGender([healthGoal?.gender]);
  }, []);

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
  };

  return (
    <>
      <motion.div
        initial={animation?.initial}
        animate={{ opacity: 1, x: 0 }}
        exit={animation?.exit}
        transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
      >
        <MultiScreenStyle>
          <GenderStyle theme={theme}>
            <MobileHeader
              text={'Your gender'}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={
                !isHealthGoalFlow
                  ? () =>
                      setActiveModalStack((prev) => [
                        ...prev.filter((item) => item?.modal !== modal?.modal),
                      ])
                  : false
              }
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
            />
            <div className="containerr">
              <p className="subtitle">
                Your gender impacts your metabolic rate and <br /> nutritional needs
              </p>
              <div className="gender_card_holder">
                <div className="card">
                  <CheckboxCard
                    type={'title_only'}
                    value={'f'}
                    selectedValue={gender}
                    setSelectedValue={setGender}
                    title={'Female'}
                    onClick={handleChange}
                    checked={gender?.includes('f')}
                    radio
                  />
                </div>
                <div className="card">
                  <CheckboxCard
                    type={'title_only'}
                    value={'m'}
                    selectedValue={gender}
                    setSelectedValue={setGender}
                    title={'Male'}
                    onClick={handleChange}
                    checked={gender?.includes('m')}
                    radio
                  />
                </div>
              </div>
            </div>
            <div className="button_holder">
              {isHealthGoalFlow ? (
                <Button
                  type={'button_combo'}
                  iconLeft={<ChevronLeft />}
                  iconRight={<ChevronRight />}
                  comboLeftTitle={'Back'}
                  comboLeftClick={handleBackClick}
                  comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}
                  comboRightTitle={'Continue'}
                  comboRightDisabled={!gender?.[0]}
                />
              ) : (
                <Button
                  type={'mobile_save'}
                  title={'Save'}
                  onClick={handleSave}
                  isDisabled={isSaveDisabled}
                />
              )}
            </div>
          </GenderStyle>
        </MultiScreenStyle>
      </motion.div>
    </>
  );
}
