import axios from 'axios';
const baseUrl = process.env.REACT_APP_SERVER_URL;

const checkDirectVisit = (history) => {
  const isDirectAccess = history.action === 'POP';
  const navigationType =  window.performance.getEntriesByType('navigation')[0].type;
  return isDirectAccess && navigationType==='navigate';
};

export const setKitchenToSession = (username, history) => {
  const redirectedFrom = sessionStorage.getItem('redirectedFrom');
  const storeUrl = sessionStorage.getItem('storeUrl');
  if (storeUrl && !redirectedFrom) {
    sessionStorage.setItem('kitchenUsername', username);
    sessionStorage.removeItem('storeUrl');
    sessionStorage.removeItem('redirectedFrom');
  }
};

export const setFavoriteKitchen = async (userToken, username, removeKitchenUsername = true) => {
  if (username && userToken) {
    const path = `profile/mark-store-favorite`;
    const url = `${baseUrl}${path}`;
    const response = await axios({
      url,
      method: 'PATCH',
      data: { username },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userToken,
      },
    });
    if(removeKitchenUsername){
      sessionStorage.removeItem('kitchenUsername')
    }
    return response?.data;
  }
};
