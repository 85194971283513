import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useShopContext } from 'context/shopContext';
import MobileHeader from 'components/elements/MobileHeader';
import RegularInput from 'components/Input/RegularInput';
import { Button } from 'components/elements/button/Button';
import { motion } from 'framer-motion';
import { ReactComponent as GrayInfo } from 'assets/shop/gray-info.svg';
import { useRequest } from 'hooks/useRequest';
import { prop, sum } from 'ramda';
import { useAppContext } from 'context/appContext';
import classNames from 'classnames';

const PromoCodeMobileStyle = styled.div`
  position: fixed;
  inset: 0;
  z-index: 114;
  .mobile_promocode_holder {
    background-color: #fff;
    width: 100vw;
    height: ${window.innerHeight}px;
    > div:first-child{
      height: 56px;
    }
  }
  .input_wrapper {
    padding: 16px 16px;
    position: relative;
    label {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #00092d;
      font-family: Gilroy;
      display: block;
      margin-bottom: 16px;
    }
    input {
      height: 44px;
      padding-left: 14px;
      padding-right: 82px;
      font-family: 'Gilroy';
      border: 1px solid #E0E3E0;
      &:focus,
      &:active {
        border: 1px solid #00092d;
        border-color: #00092d !important;
      }
      &:hover {
        border-color: #00092d !important;
      }
      &::placeholder{
        color: #949D9B;
      }
    }
    .input_error {
      input {
        border-radius: 6px;
        border: 1px solid #fec8b4;
        background: #fef1f0;
        color: #eb3223;
        font-weight: 500;
        &:hover {
          border-color: #fec8b4 !important;
        }
      }
      .suffix {
        right: 11px;
      }
    }
    .cross_icon {
      display: block;
    }
    .enter_valid_code {
      font-size: 14px;
      font-weight: 500;
      color: #eb3223;
      height: 20px;
      line-height: 20px;
      margin-top: 5px;
    }
    .apply__button{
      color: rgba(117, 126, 137, 0.5);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 32px;
      position: absolute;
      right: 32px;
      bottom: 22px;
      cursor: pointer;
      &.active{
        color: #00092D;
      }
    }
  }
  .button__holder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 32px 52px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
  }
  .border-line{
    border-top: 0px solid rgba(50, 64, 84, 0.10);
    width: calc(100% - 32px);
    margin: 8px auto 24px;
  }
  @media (min-width: 992px){
    background-color: rgba(81, 99, 93, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    .mobile_promocode_holder{
      width: 433px;
      height: 100%;
      border-radius: 12px;
      position: relative;
      padding-bottom: 38px;
      .desktop{
        height: 46px;
      }
      .icon-wrapper{
        img{
          height: 20px !important;
          widht: 20px !important;
        }
      }
      .input_wrapper{
        padding: 0 24.5px;
        label{
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      .button__holder {
        position: absolute;
        bottom: 24px;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 0 24.5px;
        button{
          width: 155px;
          cursor: pointer;
        }
      }
      .apply__button{
        bottom: 8px;
        color: #AFB5B4;

      }
      .available__cash{
        padding: 0 24.5px;
      }
      .border-line{
        margin: 20px auto 20px;
        width: calc(100% - 53px);
      }
    }
  }
`;

const AvailableCashStyle = styled.div`
  padding: 0px 16px;
  .info-container{
    display: flex;
    gap: 8px;
    margin-top: 12px;
    svg{
      width: 20px;
      height: 20px;
    }
    text{
      color: #9CA3AD;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #00092d;
    margin-bottom: 15px;
  }
  .available__cash__amount {
    border-radius: 6px;
    border: 1px solid #E0E3E0;
    background: #FFF;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .amount__title {
      color: #0b1d17;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 6px;
    }
    .amount__value{
      color: #0b1d17;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    .apply__btn{
      color: #00092D;
      font-size: 14px;
      font-weight: 500;
      font-family: Gilroy;
      line-height: 32px;
      width: 80px;
      height: 32px;
      background: #F0F2F4;
      border-radius: 30px;
      text-align: center;
      cursor: pointer;
      &:focus, &:active{
        background-color: #757E89;
      }
    }
    .remove__btn{
      color: #FFF;
      font-size: 14px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      width: 80px;
      height: 32px;
      background: #00092D;
      border-radius: 30px;
      text-align: center;
      cursor: pointer;
    }
  }
  .addCashAmount{
    border: 1px solid #000A2C;
  }
  @media (min-width: 992px){
    .subtitle{
      font-size: 14px;
      margin-bottom: 7px;
    }
  }
`;

const PromoCodeDetailsStyle =  styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
padding: 16px 10px 14px 16px;
background:#F0FAEB;
border-radius: 6px;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
.text_wrapper{
  display: flex;
  flex-direction: column;
  color: #0B1D17;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.text_wrapper text:nth-child(2) {
  color: #51635D;
}
.remove_wrapper{
  display:flex;
  align-items:center;
}
.remove_wrapper text{
  color:#00092D;
}

`

const PromoCodeMobile = ({ setShowPromocode, showPromocode }) => {
  const { orderSummaryInfo, promoCodeInfo, setPromoCodeInfo, appliedCode, setAppliedCode, cashBalance, useNutrittCash, setUseNutrittCash } = useShopContext();
  const [mobilePromoValid, setMobilePromoValid] = useState(true);
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [promoCodeValue, setPromoCodeValue] = useState(promoCodeInfo.promoCode);

  const { isMobile,isDesktop } = useAppContext();

  const [getPromoCodeInfo,{isLoading:isPromoCodeValidating}] = useRequest('promoCode/checkPromoCode', 'post');
  const subTotal =
    sum(orderSummaryInfo?.orderSummary?.providerOrderSummary?.map(prop('userTotalAmount')) || [0]) /
    100;

  const validatePromoCode = async (promoCode, amount) => {
    try {
      const result = await getPromoCodeInfo({
        body: {
          promoCode,
          subTotal: amount,
        },
      });
      if (result?.promoCodeInfo) {
        setAppliedCode(result);
        setPromoCodeInfo({
          ...promoCodeInfo, promoCode: result?.promoCodeInfo?.code
        })
        return;
      }
      if (!result?.success) {
        setMobilePromoValid(false);
        setCodeErrorMessage(result?.message);
        return;
      }
    } catch (err) {
      setMobilePromoValid(false);
      console.log('err', err);
    }
  };

  const handleRedeem = () => {
    validatePromoCode(promoCodeValue, subTotal);
  };

 const removePromoCode = () => {
  setPromoCodeInfo((prev) => ({...prev, promoCode: '' }))
  setAppliedCode('');
  setPromoCodeValue('')
 }

 const OfferText = () => {

  if(appliedCode?.promoCodeInfo?.minOrderAmount && appliedCode?.promoCodeInfo?.data?.maximumOrderLimit && appliedCode?.promoCodeInfo?.discount_type === "percentage"){
    return (  
    <>
      <text>
          {appliedCode?.promoCodeInfo?.discount}% off, up to ${appliedCode?.promoCodeInfo?.minOrderAmount}.
      </text>
      <text>
        {appliedCode?.promoCodeInfo?.discount} off orders over {appliedCode?.promoCodeInfo?.minOrderAmount}, up to ${appliedCode?.promoCodeInfo?.data?.maximumOrderLimit}.
      </text>
    </>
      )
    }  
  
  return (
    <text>{appliedCode?.promoCodeInfo?.code}</text>
  )

 }

 const DisplayInfo = () => {
  const { totalDiscount, usedNutrittCash, finalAppliedGiftCardDiscount } = orderSummaryInfo?.orderSummary || {};
  const isTotalDiscountEqualToSubtotal = finalAppliedGiftCardDiscount/100 === subTotal;
  const isUsedNutrittCashEqualToSubtotal = usedNutrittCash/100 === subTotal;
  const isTotalDiscountAndUsedNutrittCashGreaterThanZero = (totalDiscount + usedNutrittCash)/100 > subTotal;
if (isTotalDiscountEqualToSubtotal || isUsedNutrittCashEqualToSubtotal || isTotalDiscountAndUsedNutrittCashGreaterThanZero) {
    return (
      <div className='info-container'>
        <GrayInfo />
        <text>Nutritt Cash that exceeds the payment total will be returned to your balance.</text>
      </div>
    );
  } else {
    return null;
  }
};

const handleOnKeyDown = (e) => {
  if (e.key === 'Enter' && showPromocode) {
    handleRedeem();
  }
};

  const PromoCodeDetails = ()=>{
    return <PromoCodeDetailsStyle> 
     {appliedCode?.promoCodeInfo?.code_type !=="giftCard" ? <div className='text_wrapper'>
        <OfferText />
      </div> : 
      <text>{appliedCode?.promoCodeInfo?.code}</text>}
      <div className='remove_wrapper' onClick={removePromoCode}>
      <text>Remove</text>
      </div>
    </PromoCodeDetailsStyle>
  }
  return (
    <PromoCodeMobileStyle>
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: '100%', opacity: 0, transition: { duration: isMobile ? 0.7 : 0.2, type: 'spring' } }}
        transition={{ duration: isMobile ? 0.7 : 0.5, type: 'spring' }}
      >
        <div className="mobile_promocode_holder">
          {isMobile ? 
          <MobileHeader
            text={'Add promo or gift card'}
            onClickLeft={() => setShowPromocode(false)}
          />
          :
          <MobileHeader
            onClickClose={() => setShowPromocode(false)}
            className={'desktop'}
          />
          }
          <div className="input_wrapper">
            <label>Promo or gift card code </label>
           {(appliedCode === '' || !Object?.keys(appliedCode)?.length)  && <RegularInput
              value={promoCodeValue}
              onChange={(e) => {
                setMobilePromoValid(true);
                setPromoCodeValue(e.target.value);
              }}
              placeholder={"Input code here"}
              className={!mobilePromoValid && promoCodeValue && 'input_error'}
              onKeyDown={(e) => handleOnKeyDown(e)}
              autoComplete="off"
            />}
          {appliedCode?.promoCodeInfo?.code  &&  <PromoCodeDetails />}
           { mobilePromoValid && (appliedCode === '' || !Object?.keys(appliedCode)?.length) &&  <div className={`apply__button ${(!promoCodeValue || isPromoCodeValidating) ? '' : 'active'}`} onClick={isPromoCodeValidating || !promoCodeValue ? null : handleRedeem}>Apply</div>}
            {!mobilePromoValid && promoCodeValue && (
              <p className="enter_valid_code">{codeErrorMessage || 'Please enter a valid code'}</p>
            )}
          </div>
         {cashBalance?.leftAmount > 0 && <>
          <hr className='border-line'/>
          <AvailableCashStyle className='available__cash'>
            <h2 className='subtitle'>
              Available Nutritt Cash
            </h2>
            <div className={classNames(`available__cash__amount`, { addCashAmount: useNutrittCash })}>
              <div>
                <div className='amount__title'>Nutritt Cash Balance</div>
                <div className='amount__value'>
                  ${cashBalance?.leftAmount.toFixed(2)}
                </div>
              </div>
             {!useNutrittCash && <div className='apply__btn' onClick={()=>{ setUseNutrittCash(true)}}>Apply</div>}
             {useNutrittCash &&  <div className='remove__btn' onClick={()=>{ setUseNutrittCash(false)}}>Remove</div>}
            </div>
          <DisplayInfo />
          </AvailableCashStyle>
          </>}
        </div>
      </motion.div>
    </PromoCodeMobileStyle>
  );
};

export default PromoCodeMobile;
