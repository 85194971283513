import NutristionistSidebar from 'components/Nutritionist/NutristionistSidebar';
import { useAppContext } from 'context/appContext';
import styled from 'styled-components';

const ClientInviteWrapper = styled.div`
    .dashboard-main{
        display: flex;
        justify-content: space-between;
        background: #F6F5F8;
        .sidebar-left{
            width: 290px;
            padding: 20px;
        }
        .dashboard-content{
            min-width: calc(100vw - 290px);
            min-height:100vh;
            background: #FFFFFF;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            padding: 40px 25px;
            position: relative;
            margin-left: 282px;
            box-sizing: border-box;
        }
    }
  }

  @media (max-width: 991px) {
    .dashboard-main {
      padding: 24px 0 33px;

      .dashboard-content {
        position: static;
        width: 90%;
        min-width: 90%;
        min-height: auto;
        margin: 0 auto;
        padding: 0;
        background: #F6F5F8;
        box-shadow: none;
      }
    }
  }
`;

export default function ClientDashboard({ children }) {
  const { isMobile } = useAppContext();
  const mobileView = isMobile ? 'mobileView' : '';
  return (
    <ClientInviteWrapper>
      <div className="dashboard-main">
        {!isMobile && <NutristionistSidebar />}
        <div className="dashboard-content">{children}</div>
      </div>
    </ClientInviteWrapper>
  );
}
