import { useState, useEffect, forwardRef, useContext } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import { Circle } from 'rc-progress';
import classNames from 'classnames';
import styled from 'styled-components';
import { DiaryMealsContext } from './DiaryModal';
import { useAppContext } from 'context/appContext';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderDaysWrapper = styled.div`
  .day-number-wrapper {
    width: 40px !important;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    margin: auto;
    .day-string {
      margin-bottom: 6px; 
      width: 14px;
      height: 14px;
      border-radius: 50%;
      ${FlexCenter};
      span {
        color: #757E89;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.22px;
        display: block;
        font-weight: 500;
      }
    }
    .day-circle-wrapper {
      margin-bottom: 8px;
      ${FlexCenter};
      span {
        position: absolute;
        top: auto;
        left: auto;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.22px;
        color: #51635D;
        font-weight: 500;
      }
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .mark {
      width: 4px;
      height: 4px;
      background: transparent;
      border-radius: 2px;
    }
    &.active {
      .day-string { 
        background: #7CBB00;
        height: 16px;
        width: 16px;
        span {
          margin-top: 3px;
          color: #FFFFFF;
        }
      }
      .day-circle-wrapper span {
        font-weight: 700;
        color: #00092D;
      }
    }

    .mark.activeMark {
      background: #7CBB00;
    } 
  }
`;

const SliderDays = forwardRef(
  ({ setDays, mainSliderRef, setStopSwipe, memoActiveDay, isDiaryModal,setActiveDay,diarymealDate,setDiarymealDate }, ref) => {
    const [daysRange, setDaysRange] = useState([]);
    const { updateActiveWeek, diaryMeals, weeklyDiaryMeals, fullDiaryMeals } =
      useContext(DiaryMealsContext);
    const firstDayCurrentWeek = moment(new Date(), 'MM-DD-YYYY')
      .startOf('week')
      .format('MM-DD-YYYY');
    const {
      profile: {
        dietMetrics: { calories },
      },
    } = useAppContext();
    const settings = {
      dots: false,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 7,
      arrows: false,
      swipeToSlide: false,
      initialSlide: daysRange.indexOf(firstDayCurrentWeek),
      beforeChange: (oldIndex, newIndex) => {
        let days;
        let diaryIndex;
        days =
          oldIndex > newIndex
            ? daysRange.slice(newIndex, oldIndex)
            : daysRange.slice(newIndex, newIndex + 7);

        setDays(days);

        if (memoActiveDay) {
          diaryIndex = weeklyDiaryMeals.findIndex(
            (meal) =>
              moment(meal.date, 'MM-DD-YYYY').format('MM-DD-YYYY') ==
              moment(diaryMeals.date, 'MM-DD-YYYY').format('MM-DD-YYYY'),
          );
        } else {
          diaryIndex = oldIndex > newIndex ? 6 : 0;
        }

        updateActiveWeek(days, diaryIndex);
      },
    };

    useEffect(() => {
      let diaryMonths = fullDiaryMeals?.length && Object.keys(fullDiaryMeals[0]) || [];
      const modifyMonths = diaryMonths.map((element) => {
        return moment(element, 'MM-YYYY');
      });
      const minOfMonths = moment.min(modifyMonths).format('MM-YYYY');
      const dayOfMinOfMonths = fullDiaryMeals?.length && Object.keys(fullDiaryMeals[0][minOfMonths])[0];
      let startDate = moment(dayOfMinOfMonths, 'MM-DD-YYYY').subtract(3, 'months').startOf('week');
      const lastDay = moment(new Date(), 'MM-DD-YYYY').endOf('week').add(3, 'months');
      const endDate = moment(lastDay, 'MM-DD-YYYY');

      let days = [];

      while (startDate <= endDate) {
        days.push(moment(startDate).format('MM-DD-YYYY'));
        startDate = moment(startDate).add(1, 'd');
      }

      setDaysRange(days);
    }, [fullDiaryMeals?.length, isDiaryModal]);

    useEffect(() => {
      let startOfWeek = moment(new Date(), 'MM-DD-YYYY').startOf('week');
      const endOfWeek = moment(new Date(), 'MM-DD-YYYY').endOf('week');
      let weekDays = [];

      while (startOfWeek <= endOfWeek) {
        weekDays.push(moment(startOfWeek).format('MM-DD-YYYY'));
        startOfWeek = moment(startOfWeek).add(1, 'd');
      }
      setDays(weekDays);
    }, [isDiaryModal]);

    return (
      !!daysRange.length && !!fullDiaryMeals?.length &&
      isDiaryModal && (
        <SliderDaysWrapper>
          <Slider ref={ref} {...settings}>
            {daysRange.map((day, index) => (
              <div
                key={index}
                className={classNames('day-number-wrapper', {
                  active: moment(day, 'MM-DD-YYYY').format('MM-DD-YYYY') == diarymealDate,
                })}
                onClick={async() => {
                  mainSliderRef.current.slickGoTo(moment(daysRange[index], 'MM-DD-YYYY').day());
                  setStopSwipe(true);
                  const activeDay = await moment(day, 'MM-DD-YYYY').startOf('day').toDate();
                  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                  const [hours, minutes] = currentTime.split(':');
                  activeDay.setHours(parseInt(hours), parseInt(minutes), 0, 0);
                  const selectedDate = await moment(day, 'MM-DD-YYYY').format('MM-DD-YYYY')
                 await setDiarymealDate(selectedDate);
                 await setActiveDay(activeDay);
                }}
              >
                <div className="day-string">
                  <span>{moment(day, 'MM-DD-YYYY').format('dddd')[0]}</span>
                </div>
                <div className="day-circle-wrapper">
                  <span className="">{moment(day, 'MM-DD-YYYY').format('D')}</span>
                  <Circle
                    percent={
                      (weeklyDiaryMeals[moment(day, 'MM-DD-YYYY').day()]?.totalCals / calories) *
                        100 || 0
                    }
                    style={{ width: '40px', height: '40px' }}
                    strokeColor="#7CBB00"
                    trailWidth={12}
                    strokeWidth={12}
                    trailColor="#F6F5F8"
                    height="8px"
                    strokeLinecap="round"
                  />
                </div>
                <span
                  className={classNames('mark', {
                    activeMark:
                      moment(day, 'MM-DD-YYYY').format('MM-DD-YYYY') ==
                      moment(new Date(), 'MM-DD-YYYY').format('MM-DD-YYYY'),
                  })}
                />
              </div>
            ))}
          </Slider>
        </SliderDaysWrapper>
      )
    );
  },
);

export default SliderDays;




