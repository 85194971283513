import styled from 'styled-components';

const BlurBackgroundWrapper = styled.div`
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 215; // or, higher
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;

const BlurBackground = ({ children }) => {
  return (
    <BlurBackgroundWrapper>
      <div id="cover-spin">{children}</div>
    </BlurBackgroundWrapper>
  );
};

export default BlurBackground;
