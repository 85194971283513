import { memo } from 'react';
import classNames from 'classnames';
import { ReactComponent as Trash } from 'icons/trash.svg';
import { QuantityButtonWrapper } from './style';

function QuantityButton({
  className,
  count,
  variant = 'default', // {'default', 'dark', 'light'}
  size = 'large', // {'large', 'small'}
  onClickRemove,
  onClickAdd,
  disabled,
  remove,
  orderLimit,
}) {
  return (
    <QuantityButtonWrapper
      className={classNames(
        className,
        { default: variant === 'default' },
        { dark: variant === 'dark' },
        { light: variant === 'light' },
        { smallSize: size === 'small' },
        { disabled },
      )}
    >
      <div className="decrement" onClick={onClickRemove}>
        {remove & (size === 'small') ? <Trash /> : '-'}
      </div>
      <div className="count">{count}</div>
      <div
        className={classNames('increment', { disabled: orderLimit && count >= orderLimit})}
        style={{ pointerEvents: `${orderLimit && count >= orderLimit ? 'none' : 'auto'}` }}
        onClick={onClickAdd}
      >
        +
      </div>
    </QuantityButtonWrapper>
  );
}

export default memo(QuantityButton);
