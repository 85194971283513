import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@material-ui/core';
import queryString from 'query-string';
import ChatBot from './pages/chatBot';
import Shop from './pages/shop/index';
import OrderList from './OrderFulfillment/OrderList';
import OrderPage from './OrderFulfillment/Order';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/Main.scss';
import Header from './layout/Header';
import 'react-responsive-modal/styles.css';
import AddProvider from './pages/Admin';
import Disbursement from 'pages/admin/disbursement';
import Provider from './pages/provider';
import Onboarding from 'pages/onboarding';
import { AppContextProvider, useAppContext } from './context/appContext';
import { ErrorAndLogout } from './components/ErrorAndLogout';
import ErrorComponent from 'components/ErrorComponent';
import Footer from 'layout/Footer';
import TermsOfUse from 'pages/TermsOfUse';
import TermsOfUsePDF from 'pages/TermsOfUse';
import Nutritionist from 'pages/Nutritionist';
import AuthRoutes from 'context/AuthRoutes';
import ClaimAccount from 'components/admin/ClaimAccount';
import ProtectedRoute from './ProtectedRoutes';
import NutrittPrivacyPolicy from 'pages/NutrittPrivacyPolicy';
import BookingContextWrapper from 'pages/Booking';
import GoogleOauth from 'pages/GoogleOauth';
import TermsAndConditions from 'pages/termsAndConditions';
import Admin from 'pages/admin/index';
import { ClaimSuspendedAccount } from 'components/ClaimSuspendedAccount';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import styled from 'styled-components';
import UserOnboarding from 'pages/UserOnboarding';
import UserDesktopOnboarding from 'pages/UserOnboarding/desktop';
const envs = ['stage', 'production'];
import {ProviderStorefront} from './pages/provider/index2';
import FavoriteKitchen from 'pages/shop/FavoriteKitchen';
import ShopHeader from 'layout/ShopHeader_v2';
import { VisitorContextProvider } from "context/visitorContext"
import { ShopStyle } from './pages/shop/index';
import { GilroyFontWrapper } from 'shared/theme/style';
import CheckDeliveryAvailable from 'components/CheckDeliveryAvailable/CheckDeliveryAvailable';
import { ShopContextProvider } from 'context/shopContext';
import KitchenMobile from 'pages/KitchenMobile/KitchenMobile';
import AdminPage from '../src/pages/admin/index';
import SignUp from 'pages/UserOnboarding/desktop/SignUp';

const theme = createTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: '#ccc',
        '&$checked': {
          color: '#52C41A',
        },
      },
      track: {
        opacity: 0.2,
        backgroundColor: '#52C41A',
        '$checked$checked + &': {
          opacity: 0.7,
          backgroundColor: '#52C41A',
        },
      },
      thumb: {
        color: '#52C41A',
      },
    },
  },
});

const AppRoutes = () => {
  const { user, profile, isSuper, isProvider, isGuest, isVisitor, logout, isNutritionist, provider, setProvider, isMobile, isUserSuspended, isModal, isAddress ,isUser, userDeliveryAddress, isSideHeader, isShoppingUser, isCart, setSelectedProviderId, setIsCart, otpSend} =
    useAppContext();
  const location = useLocation();
  const history = useHistory();
  const imitateProvider = sessionStorage.getItem('imitateProvider');
  const params = new URLSearchParams(location.search);
  const { isDesktop } = useAppContext();

  const requiredPhoneVerification = (user) => {
    return !user?.phoneNumber || (!user?.isPhoneNumberVerified && user?.phoneNumber);
  };
  const requiredAddressVerification = () => {
    return userDeliveryAddress?.length < 1
  };
  const RedirectRoute = () => {
    if (user && user?.type?.includes("provider") && window.location.origin === `${process.env.REACT_APP_CLIENT_URL}`) {
      const { token } = JSON.parse(localStorage.getItem('user')) || {
        token: '',
      };
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_MERCHANT_URL}/session?authorization=${token}`);
    }
    if (user && user?.type?.includes("super") && window.location.origin === `${process.env.REACT_APP_CLIENT_URL}`) {
      const { token } = JSON.parse(localStorage.getItem('user')) || {
        token: '',
      };
      localStorage.clear();
      window.location.replace(`${process.env.REACT_APP_ADMIN_URL}/session?authorization=${token}`);
    }
    if(isSuper){
      return <Redirect to="/admin/users/user-management" />;
    }
    if (isUserSuspended) {
      return <Redirect to="/claim-suspended-account" />
    } 
    if (isProvider) return <Redirect to="/kitchen" />;
    if (isNutritionist) {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      if (code) {
        return <Redirect to={`/nutritionist/coaching/offering/create?code=${code}`} />;
      } else {
        return <Redirect to="/nutritionist/" />;
      }
    }
    if (isShoppingUser) {
      if (requiredPhoneVerification(user)) {
        return <Redirect to={isMobile ? "/self-onboarding/phone-verfication" : otpSend ? "/shop/verify-phone" : "/shop/phoneNumber"} />;
      }
      if (requiredAddressVerification() && isMobile) {
        history.push("/self-onboarding/address");
      }
    }
    if(isVisitor) {
        return <Redirect to="/shop" />
    }
    if(isGuest) return <Redirect to="/shop/my-coach" />;
    if (user?.type.includes('user')){
      if(window.location.pathname.split('/').includes("store")){
        <Redirect to="/store" />
      }
      else{
        return <Redirect to="/shop" />;
      }
    }
    
    return <ErrorComponent error="Path not found" />;
  };

  useEffect(() => {
    const parsed = queryString.parse(location?.search);
    const logmeal = params.get('logmeal');
    const barcode = params.get('barcode');
    if(isUserSuspended){
      return history.push("/claim-suspended-account");
    } 
    if (user && (logmeal == 'true' || barcode == 'true')) {
      parsed.redirectTo = `/shop/pantry?logmeal=${logmeal}&barcode=${barcode}`;
    }
    if (user && parsed?.redirectTo) {
      setTimeout(() => {
        history.push(parsed.redirectTo);
      }, 2000);
    }
  }, [user]);

  useEffect(() => {
    if (isShoppingUser) {
      if (requiredPhoneVerification(user)) {
        return history.push(isMobile ? "/self-onboarding/phone-verfication" : otpSend ? "/shop/phone-verfiy" : "/shop/phoneNumber");
      } else if (requiredAddressVerification() && isMobile) {
        history.push("/self-onboarding/address");
      } else {
        const excludedPaths = [
          "/shop/account/health-goal",
          "/shop/notifications",
          "/nutritt/privacy-policy",
          "/users/terms-&-conditions"
        ];
        if (!excludedPaths.some(path => location.pathname.includes(path)) && !window.location.pathname.split('/').includes("store")) {
          history.push("/shop/");
        }
      }
    }
  }, [user]);

  useEffect(() => {
    {((location.pathname === '/shop/pantry' || location.pathname === '/shop/meals' || location.pathname === '/shop/account' || location.pathname === '/shop/cartScreen' || location.pathname === '/kitchen/orders' || location.pathname === '/kitchen/payouts' || location.pathname === '/shop/kitchen-home' || location.pathname === '/shop/notifications' ) && isMobile) ?
      document.body.classList.add('bodyFixed')
    : document.body.classList.remove('bodyFixed')
    }
  }, [location.pathname])

  const bodyOverflowIncludedRoutes = ['/shop/kitchen-meals', '/shop/kitchen-home', '/', '/shop/order'];
  useEffect(() => {
    (bodyOverflowIncludedRoutes.includes(location.pathname) && (isModal || isAddress || isSideHeader || isCart) && !isMobile)
    ? document.body.classList.add('bodyOverflow') : document.body.classList.remove('bodyOverflow')
  }, [location.pathname, isModal, isAddress, isSideHeader, isCart])

  useEffect(() => {
    if (location.pathname === '/shop/kitchen-home' || location.pathname === '/shop/order') {
      setIsCart(false);
      setSelectedProviderId();
    }
  }, [location.pathname])

  if (!user)
    return (
      <>
        <ToastContainer autoClose={5000}  />
        <AuthRoutes />
      </>
    );
    
  return (
    <>
      <ToastContainer autoClose={5000}  style={{zIndex:"999999"}}/>
      <Switch>
        <Route path="/login">
          <RedirectRoute />
        </Route>
        <Route path="/account/home">
          <RedirectRoute />
        </Route>
        <Route exact path="/signup">
        {isDesktop && <Header showSocialIcons={false}/>}
          <SignUp />
        </Route>
        <Route path="/account/password">
          <RedirectRoute />
        </Route>
        <Route path="/account/phone-verify">
          <RedirectRoute />
        </Route>
        <Route path="/self-onboarding/signin">
          <RedirectRoute />
        </Route>
        <Route exact path="/nutritt/privacy-policy" component={NutrittPrivacyPolicy} />
        <Route exact path="/users/terms-&-conditions" component={TermsAndConditions} />
        <Route exact path="/nutritt/google-data" component={GoogleOauth} />
        <Route exact path="/">
          <RedirectRoute />
        </Route>
        <Route path="/register">
          <RedirectRoute isNewUser={true} />
        </Route>
        <Route path="/registerprovider">
          {isProvider ? (
            <RedirectRoute />
          ) : (
            <ErrorAndLogout error="You are already logged in" logout={logout} />
          )}
        </Route>
        <Route exact path="/nutritt/support">
          <Header />
          <ChatBot />
        </Route>

        {(isProvider || (isSuper && imitateProvider)) && (
          <Route path="/kitchen">
            {isDesktop ? (
              <>
                {isSuper && <p style={{ backgroundColor: 'red', width: '100%' }}>ADMIN</p>}
                {isSuper && !provider.userId && <ClaimAccount providerId={provider.id} />}
                <ProviderStorefront />
              </>
            ) : (
              <KitchenMobile />
            )}
          </Route>
        )}

        {(isProvider || (isSuper && imitateProvider)) && (
          <Route path="/kitchen">
            {isSuper && <p style={{ backgroundColor: 'red', width: '100%' }}>ADMIN</p>}
            {isSuper && !provider.userId && <ClaimAccount providerId={provider.id} />}
            <Provider
              isSuper
              provider={provider}
              setProvider={
                imitateProvider
                  ? (p) => {
                      sessionStorage.setItem('imitateProvider', JSON.stringify(p));
                      setProvider(p);
                    }
                  : setProvider
              }
            />
          </Route>
        )}

        {(!user || isSuper || isProvider) ? (
          <Route exact path="/store/:providerId">
            <ShopStyle>
              <GilroyFontWrapper>
                <VisitorContextProvider>
                  {!isMobile && <ShopHeader />}
                  <CheckDeliveryAvailable isHomepage={false}>
                    <FavoriteKitchen />
                  </CheckDeliveryAvailable>
                </VisitorContextProvider>
              </GilroyFontWrapper>
            </ShopStyle>
          </Route>
        ) : (
          <Route path={"/store/"}>
            <Shop user={user} />
          </Route>
        )}

        <Route path={"/shop/"}>
          {user?.type.includes('user') || user?.type.includes('guest') || user?.type.includes('visitor') ? (
            <Shop user={user} />
          ) : (
            <RedirectRoute />
          )}
        </Route>
        {isMobile &&
        <Route path="/self-onboarding/">
          <UserOnboarding/>
        </Route>
        }
        <Route path="/account/">
          <UserDesktopOnboarding/>
        </Route>
        <Route exact path="/fulfill">
          <OrderList />
        </Route>
        <Route exact path="/fulfill/:orderId">
          <OrderPage />
        </Route>
        <Route path={"/admin/"}>
          <AdminPage />
        </Route>
        {(isNutritionist || isSuper) && (
          <Route path="/nutritionist">
            <Nutritionist />
          </Route>
        )}
        <Route exact path="/onboarding">
          {user?.type.includes('user') ? <Onboarding /> : <RedirectRoute />}
        </Route>
        <Route exact path="/guest-onboarding">
          {user?.type.includes('guest') || isVisitor ? <Onboarding /> : <RedirectRoute />}
        </Route>
        <Route path="/terms" component={TermsOfUse} />
        <Route path="/terms_pdf" component={TermsOfUsePDF} />
        <Route path="/booking" component={BookingContextWrapper} />

        {/* <Route path="/nutritionist/meal-plan">
          <div style={{ display: "flex" }}>
            <NutristionistSidebar />
            <Nutritionist />
          </div>
        </Route> */}
        <Route
          path="/claim-suspended-account"
          render={(props) => <ClaimSuspendedAccount {...props} isUserSuspended={isUserSuspended} isProvider={isProvider} />}/>      
        <Route exact path="/logout">
          {() => logout()}
        </Route>
        <Route>
          <RedirectRoute />
        </Route>
      </Switch>
      {!location.pathname.includes('/kitchen') || !location.pathname.includes('/shop/account') && !location.pathname.includes('/login') && !location.pathname.includes('/forgot-password') && !location.pathname.includes('/kitchen/profile') && <Footer showTerms />} 
    </>
  );
};

const FallbackUIstyled = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  height: 100vh;
  width: 100%;
  .container{
    text-align: center;
  }
  .heading{
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: #51635D;
    margin-bottom: 20px;
  }
  .text{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #51635D;
    text-align: center;
    margin-bottom: 24px;
  }
  .back_to_home{
    font-family: Gilroy;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.8px;
    color: #FFFFFF;
    padding: 13px 27.5px;
    background: #000A2C;
    border-radius: 50px;
    display: inline-block;
  }
`

function FallbackComponent() {
  return (
    <FallbackUIstyled>
      <div className='container'>
        <h2 className='heading'>Oops!</h2> 
        <p className='text'>Something went wrong.</p>
        <a href='/' className='back_to_home'>Go back to home</a>
      </div>
      
    </FallbackUIstyled>
  )
}


export default function App() {
  const fallbackUI = <FallbackComponent />;
  useEffect(() => {
    console.log('---test log from App()')
    const REACT_APP_ENV = process.env.REACT_APP_ENV;
    const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
    if (REACT_APP_SENTRY_DSN && envs.includes(REACT_APP_ENV)) {
      Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: REACT_APP_ENV
      });
    }
    console.log({ REACT_APP_ENV });
    window.scrollTo(0, 0);
  }, []);
  return (
    <Sentry.ErrorBoundary fallback={fallbackUI}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <AppRoutes />
          </Router>
        </ThemeProvider>
      </AppContextProvider>
    </Sentry.ErrorBoundary>
  );
}
