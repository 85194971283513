import { OrderItemsWrapper } from './style';

export default function OrderItems({ orderLine }) {
  return (
    <OrderItemsWrapper>
      <div className="section-title">
        {orderLine?.length} {orderLine?.length > 1 ? 'Items' : 'Item'}
      </div>
      <div className="order-items-title">
        <div className="title-item">Name</div>
        <div className="title-item">Qty</div>
        <div className="title-item">Price</div>
      </div>
      <div className="order-items-details">
        {orderLine?.map((order) => (
          <div className="order-line-container">
            <div className="item-name">
              <img src={order?.img || '/images/dish.png'} alt="Order Image" className="order-img" />
              <div className="order-name">{order?.name}</div>
            </div>
            <div className="item-qty">{order?.quantity}</div>
            <div className="item-price">${order?.price}</div>
          </div>
        ))}
      </div>
    </OrderItemsWrapper>
  );
}
