import React, { useEffect } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { useHistory } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import { motion } from 'framer-motion';
import { useAppContext } from 'context/appContext';
import useCart from 'hooks/useCart';
import { useRequest } from 'hooks/useRequest';

const ThankyouStyle = styled.div`
  height: ${({ window }) => window.innerHeight}px;
  .containerr {
    text-align: center;
    margin-top: 80px;
    .icon {
      margin-bottom: 17px;
      > svg {
        display: block;
        margin: auto;
      }
    }
    .thank_you {
      font-size: ${({ theme }) => theme?.typography?.fontXl};
      font-style: normal;
      font-weight: 700;
      color: ${({ theme }) => theme?.colors?.deepForestGreen};
      margin-bottom: 14px;
      height: 24px;
    }
    .subtext {
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: ${({ theme }) => theme?.colors?.grayDarker};
    }
  }
  .button_holder{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding-bottom: 43px;
  }
  @media (min-width: 992px){
    height: calc(100vh - 78px);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    .containerr {
      border: 1px solid #E0E4E8;
      background: #FFF;
      margin: 0 auto;
      max-width: 760px;
      width: 100%;
      padding-top: 95px;
      padding-bottom: 79px;
      .subtext{
        height: 26px;
      }
    }
    .button_holder{
      position: relative;
      padding-bottom: 0;
      width: 100%;
      margin-top: 11.74px;
    }
  }
`;

const check = (
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
    <circle cx="32.5" cy="32.5" r="32.5" fill="#282B35" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.9912 22.0585C45.675 22.5537 45.8242 23.5045 45.3246 24.1822L31.3072 41.7025C30.4525 42.8617 28.7425 42.9587 27.76 41.9037L19.1918 32.7041C18.6174 32.0873 18.6562 31.1258 19.2785 30.5565C19.9008 29.9872 20.8709 30.0257 21.4453 30.6424L29.3811 39.1631L42.8484 22.3888C43.3481 21.7111 44.3075 21.5632 44.9912 22.0585Z"
      fill="white"
    />
  </svg>
);

const desktopCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" width="95" height="94" viewBox="0 0 95 94" fill="none">
    <circle cx="47.4982" cy="46.631" r="46.631" fill="#F5F7F8"/>
    <path d="M46.6623 24.4258C34.4272 24.4258 24.457 34.3959 24.457 46.631C24.457 58.8661 34.4272 68.8362 46.6623 68.8362C58.8973 68.8362 68.8675 58.8661 68.8675 46.631C68.8675 34.3959 58.8973 24.4258 46.6623 24.4258ZM57.2764 41.5238L44.686 54.1142C44.3751 54.425 43.9532 54.6027 43.5091 54.6027C43.065 54.6027 42.6431 54.425 42.3322 54.1142L36.0482 47.8301C35.4042 47.1861 35.4042 46.1203 36.0482 45.4763C36.6921 44.8324 37.758 44.8324 38.4019 45.4763L43.5091 50.5835L54.9226 39.1701C55.5666 38.5261 56.6324 38.5261 57.2764 39.1701C57.9203 39.814 57.9203 40.8577 57.2764 41.5238Z" fill="#000A2C"/>
  </svg>
)

const Thankyou = () => {
  const history = useHistory();
  const { updateSettings, cart, updateCart, getUserOrders, setCashBalance } = useShopContext();
  const { isMobile, setRouteBeforeSignup, TimeZone } = useAppContext();
  const { addMultipleToCart, removeProviderFromCart } = useCart();
  const unfullfilledOrders = JSON.parse(localStorage.getItem('unfullfilledOrders'));
  const [getUser] = useRequest();

  useEffect(() => {
    getUserOrders();
    setRouteBeforeSignup('');
    if(unfullfilledOrders?.length){
      removeProviderFromCart(cart);
      addMultipleToCart(unfullfilledOrders)
        .then((result) => {
          if(result.success){
            localStorage.removeItem('unfullfilledOrders');
          }
        })
        .catch((err) => console.log(err))
    }else{
      updateSettings((prevSettings) => ({
        ...prevSettings,
        provider: {},
        providerSettings: [],
        mealPlan:[]
      }));
      updateCart([]);
    }
  }, [])

  useEffect(async () => {
    const path = `auth/user?TimeZone=${TimeZone}`;
    const { user } = await getUser({
      path: path,
    });
    setCashBalance(user.cashBalance);
  }, []);

  return (
    <>
    <motion.div 
      initial={{ y: '100%', opacity: 0 }} 
      animate={{ y: 0, opacity: 1 }} 
      exit={{ opacity: 0, y: '100%' }}
    >
      <ThankyouStyle window={window} theme={theme}>
        {isMobile && <MobileHeader onClickClose={() => history.push('/shop/kitchen-home')} />}
        <div className="containerr">
          <div className="icon">{isMobile ? check : desktopCheck}</div>
          <h2 className="thank_you">Thank you!</h2>
          <p className="subtext">Your orders have been placed!</p>
          <div className="button_holder">
            <Button type={'mobile_secondary'} title="View my order" onClick={() => history.push('/shop/order')} />
          </div>
        </div>
      </ThankyouStyle>
      </motion.div>
    </>
  );
};

export default Thankyou;
