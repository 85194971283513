import { useEffect } from 'react';
import moment from 'moment';
import { displayDateTime } from 'utils';
import { OrderDetailsStatusWrapper } from './style';

export default function OrderDetailsStatus({ selectedOrder, status, setStatus }) {
  const {
    providers,
    friendlyOrderNum,
    orderLine,
    dateTimeOrdered,
    user: { name, phoneNumber, lastName },
    orderStatus,
  } = selectedOrder;
  const { selectedDate, selectedTime, providerPayout } = providers[0];
  const placeTimeOrdered = moment(dateTimeOrdered).format('LT');

  const providerStatus = () => {
    switch (orderStatus[0]?.action) {
      case 'pending':
        setStatus('Not Accepted');
        break;
      case 'accepted':
        setStatus('In Progress');
        break;
      case 'completed':
        setStatus('Completed');
        break;
      case 'refused':
        setStatus('Rejected');
        break;
    }
  };

  useEffect(() => {
    providerStatus();
  }, []);

  return (
    <OrderDetailsStatusWrapper>
      <div className="order-title">
        <div className="order-info">
          <div className="title">{name} {lastName}</div>
          <div className="items-count-wrapper">
            <div className="order-number">Order {friendlyOrderNum}</div>
            <div className="point" />
            <div className="items-count">{orderLine?.length} items</div>
          </div>
        </div>
        <div className="order-contact">
          {false && (
            <div className="icon-wrapper">
              <img src="/icons/chat.svg" alt="Chat Icon" />
            </div>
          )}
          <a href={`tel: ${phoneNumber}`} className="btn">
            <div className="icon-wrapper">
              <img src="/icons/call-black.svg" alt="Call Icon" />
            </div>
          </a>
        </div>
      </div>
      <div className="order-status-placed">
        <div className="order-status">
          <div className="label">Status</div>
          <div className={`status-info ${status.replace(/\s/g, '')}`}><span>{status}</span></div>
        </div>
        <div className="order-placed">
          <div className="label">Placed on</div>
          <div className="info">
            {displayDateTime(dateTimeOrdered, 'MMM DD, YYYY')}{' '}
            {placeTimeOrdered}
          </div>
        </div>
      </div>
      <div className="order-delivered">
        <div className="label">
          {orderStatus[0]?.action === 'pending' ? 'scheduled for' : 'Delivered on'}
        </div>
        <div className="info">
          {displayDateTime(selectedDate, 'MMM DD, YYYY')}{' '}
          {selectedTime?.toLowerCase().replace('-', ' - ')}
        </div>
      </div>
      <div className="order-amount">
        <div className="label">Total amount</div>
        <div className="info">${providerPayout}</div>
      </div>
    </OrderDetailsStatusWrapper>
  );
}
