import styled from 'styled-components';

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    color: #000000;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .hint {
      color: rgba(0, 0, 0, 0.5);
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  textarea {
    width: 100%;
    height: 80px;
    padding: 10px 8px;
    background-color: #fff;
    color: #000000;
    border-radius: 8px;
    border: 1px solid #c2c9d1;
    font-size: 14px !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 20px;
    resize: none;
    &:hover {
      border-color: #3e66fb !important;
    }
    &:focus {
      border-color: #3e66fb !important;
      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    &[disabled],
    &[readonly] {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.75) 100%
      );
      background-color: #c2c9d1 !important;
      color: rgba(0, 0, 0, 0.5) !important;
      &:hover {
        border-color: #c2c9d1 !important;
        cursor: not-allowed;
      }
      &:focus {
        border-color: #c2c9d1 !important;
        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    &[readonly] {
      color: #000000 !important;
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
    &:has(+ .box .errorMessage) {
      border-color: #f0373850;
      &:hover,
      &:focus {
        border-color: #f03738 !important;
      }
    }
    &:has(+ .box .warningMessage) {
      border-color: #f3aa1c50;
      &:hover,
      &:focus {
        border-color: #f3aa1c !important;
      }
    }
    &:has(+ .box .successMessage) {
      border-color: #c2c9d1;
      &:hover,
      &:focus {
        border-color: #3e66fb !important;
      }
    }
  }
`;