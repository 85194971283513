import { forwardRef, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import { Circle } from 'rc-progress';
import classNames from 'classnames';
import Styled from 'styled-components';
import { ClientDiaryContext } from './NutritionistClientProfile';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderDaysWrapper = Styled.div`
  padding: 16px 15px 20px;
  // border-bottom: 1px solid #E0E3E0;
  @media screen and (max-width: 767px){
    padding: 16px 18px 0px;
  }
  .slick-slider{
    padding: 0px 20px;
    @media screen and (max-width: 767px){
      padding: 0px;
    }
    .slick-track{
      @media screen and (max-width: 767px){
        padding-bottom: 8px;
      }
    }
    .slick-prev{
      left: -9px;
      top: 44px;
      &:before{
        color: #949D9B;
      }
    }
    .slick-list{
      .day-number-wrapper{
        margin:auto;
        height:82px;
        @media screen and (max-width: 767px){
          height:60px;
        }
        @media(min-width:900px)and (max-width: 768px){
          height:72px;
        }
      }
    }
    .slick-next{
      right: -9px;
      top: 44px;
      &:before{
        color: #949D9B;
      }
    }
  }
  .day-number-wrapper {
    width: 40px !important;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    position: relative;

    .day-string {
      margin-bottom: 2px; 
      width: 14px;
      height: 14px;
      border-radius: 50%;
      ${FlexCenter};

      span {
        color: #AFB5B4;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.05em;
      }
    }

    .day-circle-wrapper {
      ${FlexCenter};

      span {
        position: absolute;
        top: auto;
        left: auto;
        color: #51635D;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.05em;
      }

      svg {
        width: 40px;
        height: 40px;
        @media screen and (max-width: 767px){
          width: 40px !important;
          height: 40px !important;
        }
      }
    }

    .mark {
      width: 4px;
      height: 4px;
      background: #52C41A;
      border-radius: 2px;
      position: absolute;
      bottom:0px;
      @media screen and (max-width: 767px){
        bottom: -7px;
      }
    }

    &.active {
      .day-string { 
        background: #52C41A;

        span {
          color: #FFFFFF;
        }
      }
    }
  }
`;

const SliderWeekDays = forwardRef(({ setDays, mainSliderRef, setStopSwipe, memoActiveDay, clientModal ,recommendedCalories,setCurrentDate}, ref) => {
  const [daysRange, setDaysRange] = useState([]);
  const { updateActiveWeek, diaryMeals, setDiaryMeals, weeklyDiaryMeals, data } =useContext(ClientDiaryContext);
 


  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    arrows: true,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 600,
        arrows: false,
        settings: {
          arrows: false,
        }
      },
    ],
    initialSlide: daysRange.indexOf(
      moment(new Date(), 'MM-DD-YYYY').startOf('week').format('MM-DD-YYYY'),
    ),
    beforeChange: (oldIndex, newIndex) => {
      let days;
      let diaryIndex;
      days =
        oldIndex > newIndex
          ? daysRange.slice(newIndex, oldIndex)
          : daysRange.slice(newIndex, newIndex + 7);

      setDays(days);

      if (memoActiveDay) {
        diaryIndex = weeklyDiaryMeals.findIndex(
          (meal) =>
            moment(meal.date, 'MM-DD-YYYY').format('MM-DD-YYYY') ==
            moment(diaryMeals.date, 'MM-DD-YYYY').format('MM-DD-YYYY'),
        );
      } else {
        diaryIndex = oldIndex > newIndex ? 6 : 0;
      }

      updateActiveWeek(days, diaryIndex);
    },
  };


  useEffect(() => {
    setDaysRangeFn();
  }, [clientModal,data.length]);
  
  useEffect(() => {
    setDaysFn()
  }, [clientModal])

  useEffect(() => {
    setCurrentDate(diaryMeals?.date)
  }, [diaryMeals?.date])
    
  const setDaysFn = function () {
    let startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    let days = [];
    while (startOfWeek <= endOfWeek) {
      days.push(moment(startOfWeek).format('MM-DD-YYYY'));
      startOfWeek = moment(startOfWeek).add(1, 'd');
    }
    setDays(days);
  }

  const setDaysRangeFn = function () {
  if(data && data.length>0){
    let dataMonths = data?.length && Object.keys(data[0]) || [];
    const modifyMonths = dataMonths.map((element) => {
      return moment(element, 'MM-YYYY');
    });
    const minOfMonths = moment.min(modifyMonths).format('MM-YYYY');
    const dayOfMinOfMonths = data?.length && Object.keys(data[0][minOfMonths])[0];
    let startDate =moment(dayOfMinOfMonths, 'MM-DD-YYYY').subtract(3, 'months').startOf('week');
    const lastDay = moment(new Date(), 'MM-DD-YYYY').endOf('week').add(3, 'months');
    const endDate = moment(lastDay, 'MM-DD-YYYY');
    let days = [];
    
    while (startDate <= endDate) {
      days.push(moment(startDate).format('MM-DD-YYYY'));
      startDate = moment(startDate).add(1, 'd');
    }   
    setDaysRange(days);
  }
  
}



  return (
    <SliderDaysWrapper>
      {daysRange.length>0 && (
        <Slider ref={ref} {...settings}>
          {daysRange.map((day, index) => (
            <div
              key={index}
              className={classNames('day-number-wrapper', {
                active: moment(day, 'MM-DD-YYYY').format('MM-DD-YYYY') == diaryMeals?.date,
              })}
              onClick={() => {
                mainSliderRef.current.slickGoTo(moment(daysRange[index], 'MM-DD-YYYY').day());
                // setStopSwipe(false);
              }}
            >
              <div className="day-string">
                <span>{moment(day, 'MM-DD-YYYY').format('dddd')[0]}</span>
              </div>
              <div className="day-circle-wrapper">
                <span className="">{moment(day, 'MM-DD-YYYY').format('D')}</span>
                <Circle
                  percent={
                    (weeklyDiaryMeals[moment(day, 'MM-DD-YYYY').day()]?.totalCals / recommendedCalories) *
                    100 || 0
                  }
                  style={{ width: '54px', height: '54px' }}
                  strokeColor="#52c41a"
                  trailWidth={13}
                  strokeWidth={13}
                  trailColor="#F6F5F8"
                  height="8px"
                  strokeLinecap="round"
                />
              </div>
              {(day === moment(new Date(), 'MM-DD-YYYY').format('MM-DD-YYYY')) && <span className="mark" />}
            </div>
          ))}
        </Slider>
      )}
    </SliderDaysWrapper>
  );

});

export default SliderWeekDays;
