import ProviderCard from 'pages/shop/providers/ProviderCard';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useVisitorContext } from 'context/visitorContext';
import { useHistory } from 'react-router-dom';
import { prop } from 'ramda';
import Switch from '@material-ui/core/Switch';
import Progress from 'components/Progress';
import { isPointInPolygon } from 'utils/verify-coordinates-in-polygon.util';
import ShopSteps from 'components/NutrittSteps/ShopSteps';
import { useAppContext } from 'context/appContext';
import NutrittSteps from 'components/NutrittSteps';
import BackButton from "components/BackButton";
import SwitchOnOff from 'components/Nutritionist/Coaching/SwitchOnOff';
import VisitorProviderCard from './components/VisitorProviderCard';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 24px 0 50px;
  min-height: calc(100vh - 210px);

  h1 {
  
    color:#51635D;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  .provider-head {
    display: flex;
    justify-content: space-between;
  }
  @media (min-width:992px) {
    .provider-head {
      display: flex;
      align-items:center;
      flex-direction:column;
      margin-bottom:10px;

      .provider-change{
       display:flex;
       justify-content:flex-end;
       width:68%;
       align-items: center;
      }
    }
    .Mui-checked.Mui-checked + .MuiSwitch-track {
      opacity:unset !important;
    }
    .MuiSwitch-track{
      //  background-color: #52C41A !important;
    }
    .MuiSwitch-thumb {
      width: 16px !important;
      height: 22px !important;
      transform: rotate(90deg) !important;
      border-radius: 8px !important;
    }
    .MuiSwitch-switchBase {
      left: 10px !important;
      padding: 6px !important;
    }
    .MuiSwitch-switchBase.MuiSwitch-switchBase.Mui-checked {
      transform: translateX(12px) !important;
    }
  }
  @media (max-width: 440px) {
    .provider-head {
      display: block;
      h1 {
        font-size: 18px;
        margin-bottom: 2px;
        align-items: center;
      }
    }
  }
  @media (max-width: 540px) {
    .provider-head {
      display: block;
      h1 {
        font-size: 18px;
        margin-bottom: 2px;
        align-items: center;
      }
    }
  }
  @media (max-width: 768px) {
    .provider-head {
      h1 {
        font-size: 18px;
        margin-bottom: 2px;
        align-items: center;
      }
    }
  }
  .provider-change {
    display: flex;
    padding: 5px;
  }

  .provider-cards {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(345px, 380px));
    gap: 24px;
    width: 70%;
    margin: 0 auto;
   

    .card-box .provider-card-info .tag-min-order-sec .tags-listing .smallPopper:first-child .MuiTooltip-popper {
      top: -20px !important;
      
    }

    .MuiTooltip-popper div {
     background-color: #52c41a !important;
     color: #fff !important;
     border: 0 !important;
   }
    @media (max-width: 1200px) {
      width: 85%;
    }
    @media (max-width: 767px) {
      width:auto;
      justify-content: center;
      .card-box{
        border-radius:10px;
      }
    }
  }
  .no-provider {
    display: flex;
    justify-content: center;
  }
`;

const NoProviderAvailable = ({ text }) => {
  return (
    <div className="no-provider">
      <h3>{text}</h3>
    </div>
  );
};

const steps = ['Plan', 'Providers', 'Meals', 'Checkout'];

export default function VisitorProviders() {
  const {
    settings: { userInfo },
    availableProviders,
    setAvailableProviders,
    providerListCalled
  } = useVisitorContext();
  const history = useHistory();
  const [providers, setProviders] = useState([]);
  const [showOnlyDeliversToMe, setShowOnlyDeliversToMe] = useState(true);
  const [getProvidersAPICall, { isLoading }] = useRequest(`provider/public/providers`);

  useEffect(() => {
    const e = {
      target: {
        checked: true
      }
    };
    if (!userInfo.zipcode) {
      return history.push('/visitor/plan');
    }
    deliverToMeHandler(e);
    // if (!availableProviders?.data?.length) {
    //   getProviderFn();
    // } else {
    //   if (availableProviders.expiry < new Date().getTime()) {
    //     getProviderFn();
    //   } else {
    //     setProviders(availableProviders.data);
    //   }
    // }
  }, []);


  const deliverToMeHandler = async (e) => {
    isMobile ? setShowOnlyDeliversToMe(e.target.checked) : setShowOnlyDeliversToMe(e)
    const target = isMobile ? e.target.checked : e;
    if (target) {
      const newProviders = await getProvidersAPICall();
      setAvailableProviders({
        data: newProviders,
        expiry: new Date().getTime() + (5 * 60 * 1000)
      });
      const pro = newProviders.filter((p) => {
        const dayWithDelivey = [];
        for (let dayKey in p.schedule) {
          dayWithDelivey.push(p.schedule[dayKey].delivery.isDeliveryOn)
        }
        const isAnyDayOnForDelivery = dayWithDelivey.some(item => item)
        let isProviderReadyToDeliver = p.deliveryCoverage?.includes(userInfo.zipcode) && isAnyDayOnForDelivery;
        if (!isProviderReadyToDeliver) {
          const deliverableAreas = p?.deliverableAreas?.data || [];
          for (let polygon of deliverableAreas) {
            const polygonCords = polygon.map((coordinate) => ([coordinate.lat, coordinate.lng]));
            const isPointInsidePolygon = isPointInPolygon(
              userInfo.userAddressLatLong.lat,
              userInfo.userAddressLatLong.lng,
              polygonCords,
            );
            if (isPointInsidePolygon) {
              isProviderReadyToDeliver = true
              break;
            }
          }
        }
        return isProviderReadyToDeliver;
      });
      setProviders(pro);
    } else {
      // await getProviderFn();
      const newProviders = await getProvidersAPICall();
      setAvailableProviders({
        data: newProviders,
        expiry: new Date().getTime() + (5 * 60 * 1000)
      });
      const providersReadyForPickup = newProviders.filter((p) => {
        const dayWithPickup = [];

        for (let dayKey in p.schedule) {
          if (p.schedule[dayKey]?.pickup?.isPickupOn === true && p.schedule[dayKey]?.pickup?.start !== "" && p.schedule[dayKey]?.pickup?.end !== "") {
            dayWithPickup.push(true);
          } else {
            dayWithPickup.push(false);
          }
        }
        const isProviderReadyToPickup = dayWithPickup.some(item => item);
        return isProviderReadyToPickup;
      });

      //we are concatinating already filtered providers and providers available to pickup
      const defaultProviders = providers.concat(providersReadyForPickup);
      //filtering only unique providers
      const filteredProviders = defaultProviders.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setProviders(filteredProviders);
    }
  };
  const { isMobile, isGuest, isVisitor } = useAppContext();
  const mobileView = isMobile ? 'mobileView' : '';

  return (
    <Container className={`providerCard-listing ${mobileView}`}>
      {!isMobile && <BackButton perviousLink="/shop/plan" />}
      <div className="provider-head">
        {!isMobile &&
          <NutrittSteps
            title="Pick Your Provider"
            description="Enjoy the freshest meals from your local providers!"
            activeStep={1}
            steps={steps}
            stepNumber={1}
            stepTitle="Let's specify your plan"
            isFooterHidden={true}
          />}
        {!isMobile &&
          <span className="provider-change">
            <SwitchOnOff
              checked={showOnlyDeliversToMe}
              onChange={deliverToMeHandler}
              name="isPickupOn"
              color="primary"
            />
            <h1>Delivers to me</h1>
          </span>
        }
        {isMobile && <ShopSteps
          title="Pick your provider"
          description="Enjoy the freshest meals from your local providers!"
          activeStep={1}
          steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
          perviousLink="/shop/plan"
        />
        }
        {isMobile &&
          <span className="provider-change">
            <h1>Delivers to me</h1>
            <Switch
              checked={showOnlyDeliversToMe}
              onChange={deliverToMeHandler}
              name="isPickupOn"
              color="primary"
            />
          </span>
        }

      </div>
      {isLoading ? (
        <Progress />
      ) : providers.length != 0 ? (
        <div className="provider-cards">
          {providers
            /*filter where not ready to accept orders*/
            .filter(prop('schedule'))
            .filter(prop('approved'))
            .map((provider) => (
              <VisitorProviderCard
                key={provider.id}
                {...provider}
              /*schedule={provider.schedule || DEFAULT_PROVIDER_SCHEDULE}*/
              />
            ))}
        </div>
      ) : (
        <NoProviderAvailable text="Oops.. There are currently no providers available, try another address." />
      )}
    </Container>
  );
}
