import styled from 'styled-components';
import { ReactComponent as Scale } from 'assets/scale2.svg';
import { ReactComponent as Skewer } from 'assets/skewer.svg';
import { ReactComponent as Lite } from 'assets/lite.svg';
import { useState, useEffect } from 'react';
import StackSlider from 'components/elements/stackSlider/StackSlider';
import { useHistory } from 'react-router-dom';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { ReactComponent as ArrowLeft } from 'assets/shop/arrow-left.svg';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { motion } from 'framer-motion';
import MultiScreenStyle from '../healthGoal/MultiScreenStyle';
import { useShopContext } from 'context/shopContext';
import MobileHeader from "components/elements/MobileHeader"
import { useAppContext } from 'context/appContext';


const PreferredDietStyle = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: 100%;
    .flex{
        flex: 1;
    }
    .page-header {
        display: flex;
        align-items: center;
        background: white;
        position: relative;
        padding-bottom: 24px;
        .title{
            right: 27px;
            position: relative;
            justify-content: center;
            flex: 1;       
            padding: 12px 0px 12px 16px; 
        }
    }
    .title{
        color: ${theme.colors.primaryDarker};
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: ${theme.typography.fontGilroy};
        font-size: ${theme.typography.fontMedium};
        font-style: normal;
        font-weight: 600;
        line-height: 24px; 
      }
        .diet_cards{
        margin-bottom: 32px;
        display: flex;
        padding: 0px 24px;
        flex-direction: column;
        gap: 8px;
        position: relative;
        .icon{
            min-width: 40px !important;
        }
        .content .title {
            text-align: -webkit-left;
        }
        .custom{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -10px);
        }
        .dietCard{
            svg{
                path{
                    stroke:none;
                }
            }
        }
    }
`;

export default function MacroSplit({ setActiveModalStack, modal }) {
  const { saveHealthGoal, healthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const [activePreference, setActivePreference] = useState([healthGoal?.selfMealPlan?.preferredDiet?.type]);
  const [type, setType] = useState(healthGoal?.selfMealPlan?.preferredDiet?.type);
  const [preferredDiet, setPreferredDiet] = useState({
    type: type,
    fatPercent: healthGoal?.selfMealPlan?.preferredDiet?.fatPercent,
    carbsPercent: healthGoal?.selfMealPlan?.preferredDiet?.carbsPercent,
    proteinPercent: healthGoal?.selfMealPlan?.preferredDiet?.proteinPercent,
  });
  const [isCustom, setIsCustom] = useState(false);
  const isSaveDisabled = !activePreference || (JSON.stringify(healthGoal?.selfMealPlan?.preferredDiet) === JSON.stringify(preferredDiet));
  const { isVisitor } = useAppContext();

  const dietCardData = [
    {
      icon: <Scale />,
      title: 'Balanced',
      subtitle: '40% Carb, 30% Protein, 30% Fat',
      value: 'balanced',
    },
    {
      icon: <Skewer />,
      title: 'Low Carb',
      subtitle: '20% Carb, 30% Protein, 50% Fat',
      value: 'low-carb',
    },
    {
      icon: <Lite />,
      title: 'Low Fat',
      subtitle: '30% Carb, 50% Protein, 20% Fat',
      value: 'low-fat',
    },
  ];

  const handleClick = (value) => {
    switch (value) {
      case 'balanced':
        setPreferredDiet({
          fatPercent: 30,
          carbsPercent: 40,
          proteinPercent: 30,
          type: 'balanced',
        });
        break;
      case 'low-carb':
        setPreferredDiet({
          fatPercent: 50,
          carbsPercent: 20,
          proteinPercent: 30,
          type: 'low-carb',
        });
        break;
      case 'low-fat':
        setPreferredDiet({
          fatPercent: 20,
          carbsPercent: 30,
          proteinPercent: 50,
          type: 'low-fat',
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      preferredDiet?.fatPercent == 30 &&
      preferredDiet?.carbsPercent == 40 &&
      preferredDiet?.proteinPercent == 30
    ) {
      setActivePreference(['balanced']);
      setType('balanced');
      setIsCustom(false);
    } else if (
      preferredDiet?.fatPercent == 50 &&
      preferredDiet?.carbsPercent == 20 &&
      preferredDiet?.proteinPercent == 30
    ) {
      setActivePreference(['low-carb']);
      setType('low-carb');
      setIsCustom(false);
    } else if (
      preferredDiet?.fatPercent == 20 &&
      preferredDiet?.carbsPercent == 30 &&
      preferredDiet?.proteinPercent == 50
    ) {
      setActivePreference(['low-fat']);
      setType('low-fat');
      setIsCustom(false);
    } else {
      setActivePreference([]);
      setType('custom');
      setIsCustom(true);
    }
  }, [preferredDiet]);

  useEffect(() => {
    setUpdatedHealthGoal((prev) => ({
        ...prev,
        selfMealPlan: {
            ...prev.selfMealPlan,
            preferredDiet: {
                ...preferredDiet,
                type: type
            }
        }
    }))
}, [preferredDiet, type])

  const macros = ['fatPercent', 'carbsPercent', 'proteinPercent'];
  const handleMacroChanges = (name, value) => {
    const index = macros?.indexOf(name);
    const diff = preferredDiet[name] - value;
    const newVals = macros?.reduce(
      ({ diff, changes, index }, _) => {
        if (diff == 0) return { diff, changes, index };
        const nextName = macros[(index + 1) % macros?.length];
        const nextVal = preferredDiet[nextName] + diff;
        if (nextVal <= 100 && nextVal >= 0)
          return { diff: 0, changes: { ...changes, [nextName]: nextVal } };
        if (nextVal > 100)
          return {
            diff: nextVal - 100,
            changes: { ...changes, [nextName]: 100, index: index + 1 },
          };
        if (nextVal < 0) console.log('less than 0', { nextName, nextVal, changes });
        return {
          diff: nextVal,
          changes: { ...changes, [nextName]: 0 },
          index: index + 1,
        };
      },
      { diff, changes: { [name]: value }, index },
    );
    setPreferredDiet({
      ...preferredDiet,
      ...newVals.changes,
    });
  };

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    if (isVisitor) {
      saveDietPreferences();
    } else {
      saveHealthGoal();
    }
    e.preventDefault();
    setActiveModalStack([]);
  };

  return (
    <motion.div
      initial={{ x: '100%', opacity: 0 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: '100%', opacity: 0 }}
      transition={{ duration: 0.7, type: 'spring' }}
    >
      <MultiScreenStyle>
        <PreferredDietStyle theme={theme}>
          <div className="flex">
            <div className="page-header">
              <MobileHeader text={'Macro split'} onClickLeft={() => setActiveModalStack([])} />
            </div>
            <div className="diet_cards">
              {dietCardData?.map((item) => {
                return (
                  <CheckboxCard
                    onClick={() => handleClick(item?.value)}
                    checked={activePreference?.includes(item?.value)}
                    selectedValue={activePreference}
                    setSelectedValue={setActivePreference}
                    value={item?.value}
                    radio={true}
                    {...item}
                  />
                );
              })}
            </div>
            <div className="diet_cards">
              {isCustom && <p className="custom">Custom</p>}
              <StackSlider
                preferredDiet={preferredDiet}
                type="fat"
                handleMacroChanges={handleMacroChanges}
              />
              <StackSlider
                preferredDiet={preferredDiet}
                type="protein"
                handleMacroChanges={handleMacroChanges}
              />
              <StackSlider
                preferredDiet={preferredDiet}
                type="carbs"
                handleMacroChanges={handleMacroChanges}
              />
            </div>
          </div>
          <div className="button_holder">
            <Button
              type={'mobile_save'}
              title="Save"
              disabled={isSaveDisabled}
              onClick={handleSave}
            />
          </div>
        </PreferredDietStyle>
      </MultiScreenStyle>
    </motion.div>
  );
}
