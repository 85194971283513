import Styled from 'styled-components';
import { useLocation } from 'react-router';
import { useAppContext } from 'context/appContext';
import { ReactComponent as EmptyPantry } from 'assets/shop/empty pantry.svg';
import { Button } from 'components/elements/button/Button';

const EmptyWrapper = Styled.div`
  padding: 0 30px;
  height: calc(90vh - 114px);
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  gap:24px;
  &::-webkit-scrollbar {
    width: 0;
  }
  .empty-img-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-bottom: 118px;
    img {
      width: 125px;
      height: 125px;
      min-height: 125px;
      object-fit: cover;
    }
    svg{
      width: 96px;
      height: 96px;
    }
  }
  .empty-title {
    color: #0B1D17;
    text-align: center;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29.71px;
      }
  p {
    color: #000A2C;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 312px;
      }
  @media (min-width: 992px) {
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
`;

const ButtonWrapper = Styled.div`
button {
  width: 271px;
  height: 42px;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 0px solid #DFE2E8;
  background: #F0F2F4;
  cursor: pointer;
}

`;

export default function Empty({ img, title, info, btnText, btnDirection, showImage = true }) {
  const {isMobile} = useAppContext();
  const {pathname} = useLocation();
  const emptyHeight = window.innerHeight - (pathname == '/shop/pantry' && isMobile ? 180 :  380);

  return (
    <>
    <EmptyWrapper >
      <div className="empty-img-wrapper">
        {showImage && <EmptyPantry />}
      <h2 className="empty-title">{title}</h2>
      {info && <p>{info}</p>}
      </div>
    </EmptyWrapper>
    <ButtonWrapper>
          {btnText && <Button type={"mobile_secondary"} title={btnText} onClick={btnDirection} />}
          </ButtonWrapper>
          </>
  );
}
