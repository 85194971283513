import React from 'react';
import styled from 'styled-components';
import { LetsConnectData } from './VisitorLandingData';
import { useHistory } from 'react-router';
import { useAppContext } from 'context/appContext';

const LetsConnectWrapper = styled.div`
  padding: 150px 0px 99px 0px;
  border-bottom: 1px solid #E0E4E8;
  background: #fff;
  width: 1560px;
  margin: auto;
  @media (min-width: 768px) and (max-width: 1440px) {
    padding: 100px 0px 99px 0px;
  }
  h2 {
    color: #283241;
    text-align: center;
    font-family: Gilroy;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media (min-width: 768px) and (max-width: 1280px){
      font-size: 48px;
    }
  }
  .green {
    color: #49a332;
  }
  .container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 1166px;
    padding-top: 101px;
    gap: 19px;
    margin: 0 auto;
    @media (max-width: 1200px){
      width: 100%;
    }
    .card{
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        img{
          height: 254px;
          width: 254px;
          @media (min-width: 768px) and (max-width: 1024px){
            height: 226px;
            width: 226px;  
          }
        }
        @media (max-width: 992px) {
          img{
            height: 128px;
            width: 128px;
          }  
        }
        .details{
            display: flex;
            flex-direction: column;
            gap: 14px;
        }
        h2{
            color: var(--New-283241, #283241);
            text-align: center;
            font-family: Gilroy;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .desc{
            color: #757E89;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
  }
  button{
    display: flex;
    width: 160px;
    height: 42px;
    padding: 18px 16px 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 30px;
    background: var(--Primary-Default, #000A2C);
    color: var(--white, #FFF);
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media (max-width: 1500px) {
    width: 100% !important;
  }
  @media (max-width: 767px) {
    padding: 69px 0 0;
    h2{
        font-size: 26px;
        font-weight: 600;
    }

    .container{
        grid-template-columns: repeat(1,1fr);
        gap: 72px;
        margin-bottom: 88px;
        padding-top: 48px;
        width: 100%;
        .card{
            width: 100%;
            height: 100%;
            gap: 24px;
        }
    }
    }
    @media (min-width: 768px) and (max-width: 1024px){
      .container{
        .card{
          h2{
            font-size: 24px;
            @media (min-width: 768px) and (max-width: 964px){
              font-size: 24px;
            }
          }
          .desc{
            line-height: 24px;
            font-size: 16px;
            @media (min-width: 768px) and (max-width: 964px){
              line-height: 20px;
              font-size: 12px;  
            }
          }  
        }
      }
    }
`;

export const LetsConnect = ({ setIsQrOpen }) => {
    const history = useHistory();
    const { isMobile, userSchemeNavigation } = useAppContext();
    const handleClick = (text) => {
        if (text === 'Get app') {
          isMobile ? userSchemeNavigation({ appStoreLink: "https://apps.apple.com/in/app/nutritt/id1605156638", playStoreLink: "https://play.google.com/store/apps/details?id=com.nutritt&hl=en&gl=US", schemeURL: "nutrittapp://" }) : setIsQrOpen(true);
        } else if (text === 'Join') {
          window.open(process.env.BECOME_A_PARTNER_TYPE_FORM_URL ?? "https://nutritt.typeform.com/partnership", "_blank");
        } else if (text === 'Apply') {
          window.open(process.env.JOIN_OUR_TEAM_TYPE_FORM_URL ?? "https://nutritt.typeform.com/careers", "_blank");
        }
    };

  return (
    <LetsConnectWrapper>
      <h2>
        let’s do it <span className="green">together.</span>{' '}
      </h2>
      <div className="container">
        {LetsConnectData.map((item) => (
          <>
            <div className="card">
              <img key={item?.id} src={`/images/${item?.img}.jpg`} alt="image" />
              <div className='details'>
              <h2>{item?.title}</h2>
              <h2 className='desc'>{item?.desc}</h2>
              </div>
              <button onClick={() => handleClick(item.text)}>{item.text}</button>
            </div>
          </>
        ))}
      </div>
    </LetsConnectWrapper>
  );
};
