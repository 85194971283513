import { useEffect, useState ,useMemo,useCallback} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import NutritionistMetric from 'components/Nutritionist/NutritionistMetric';
import { useRequest } from 'hooks/useRequest';
import NutritionistPlanInfo from 'components/Nutritionist/NutritionistPlanInfo';
import { BMR_FORMULAS, DEFAULT_ACTIVITY, createPlanItems, BMR_FORMULA_VARS } from '../../constants';
import Alert from 'components/Alert';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useNutritionistContext } from 'context/nutritionistContext';

const NewPlanStyle = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 70px 68px 30px;

  .go-back {
    width: 110px;
    height: 38px;
    background-color: #52c41a;
    border-radius: 8px;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
  }

  .plan-btn button {
    width: 174px;
    height: 57px;
    background-color: #52c41a;
    color: #fff;
    font-size: 18px;
    border-radius: 6px;
    cursor: pointer;
  }

  .form-section {
    display: flex;

    .form-img {
      img {
        width: 100%;
        height: 200px;
        border-radius: 10px;
      }
    }
    .form-group {
      margin-bottom: 41px;
      display: flex;
      flex-direction: column;

      label {
        font-size: 18px;
        color: #707070;
        margin-bottom: 14px;

        span:first-child {
          margin-right: 18px;
        }
      }

      input,
      textarea,
      .selected-value {
        height: 43px;
        padding: 0 15px;
        -webkit-color: #707070;
        border: 1px solid #c3cbc9;
        border-radius: 6px;
      }

      textarea {
        height: 168px;
        padding: 15px;
        resize: none;
      }
    }

    .select-label {
      margin: 0;
    }
  }

  .section-wrapper {
    padding-top: 36px;
    display: none;

    &.active {
      display: block;
    }
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: 60px auto;
    padding: 24px 0;

    .form-group-wrapper {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }

      .form-group {
        margin-bottom: 0;

        .selected-value {
          -webkit-color: #707070;
        }
      }
    }

    .form-section .form-img {
      img {
        width: 100%;
        height: 184px;
        border-radius: 10px;
      }
    }

    .form-section .form-group {
      width: 100%;
      margin-bottom: 0;
    }

    .primary-btn {
      margin-top: 24px;
    }

    .go-back{
      width: 120px; 
    }
  }
`;

{
  TabPanel;
}

const defaultBMRFormula = {
  energyFormula: 'PAL (Physical Activity Level)',
  targetCaloriesFormula: 'BMR * PAL',
  activityType: 'PAL',
};

export default function NutritionistNewPlan(props) {
  const [value, setValue] = useState(0);
  const [displaySection, setDisplaySection] = useState(true);
  const { nutritionist: nutritionistData, updateNutritionist, allTemplates ,  setAllTemplates } = useNutritionistContext();
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const nutritionistReqId = imitateNutritionist?.id;
  const [showPrompt, setShowPrompt] = useState(true);
  const [isAlert, setIsAlert] = useState(false);
  const [isSubmitAlert, setIsSubmitAlert] = useState(false);
  const [useCustom, setUseCustom] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  const [form, setForm] = useState(createPlanItems());
  const { id } = useParams();
  const dataId = id;
  const [updateDietPlan] = useRequest(`dietplan/update/${dataId}`, 'put');
  const { activityType } = useMemo(()=>{return BMR_FORMULA_VARS[form.BMR_formula] || defaultBMRFormula},[form,useCustom]);
  const [customizeValue, setCustomizeValue] = useState(DEFAULT_ACTIVITY[activityType]);
  const location = useLocation();
  const [postData] = useRequest('dietplan/create', 'post');

  useEffect(() => {
    const dietTemplates = nutritionistData?.nutritionist?.dietTemplates?.find(
      (item) => item.id == dataId,
    );
    if (dietTemplates) {
      const palData = dietTemplates.PAL ? dietTemplates.PAL : dietTemplates.BMR_formula === 'instituteOfMedicine' ? DEFAULT_ACTIVITY["PA"] : DEFAULT_ACTIVITY["PAL"];
      setForm((prev) => ({
        ...prev,
        ...dietTemplates,
        PAL: palData,
        BMR_formula: dietTemplates?.BMR_formula || BMR_FORMULAS[0].value,
      }));
      setCustomizeValue(palData);
    }
  }, [id]);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const moveToMetricSettings = () => {
    setValue(1);
    setDisplaySection(false);
    window.scrollTo(0, 0);
  };
  const items = createPlanItems();
  const generateGoBack = () => {
    if (JSON.stringify(form) === JSON.stringify(items)) {
      history.push('/nutritionist/meal-plan');
    } else {
      setIsAlert(true);
    }
  };
  const calculatePAL = useCallback(() => {
    if (useCustom) {
      return customizeValue;
    }else if (form.BMR_formula = "instituteOfMedicine") {
      return DEFAULT_ACTIVITY[activityType];
    } else {
      return DEFAULT_ACTIVITY[activityType]
    }
  }, [useCustom, form, customizeValue])
  
  const handleSubmit = async () => {
    let createdPlan;
    const updatedForm = { ...form, goal: Number(form.goal) }
    setForm(updatedForm);
    if (updatedForm.name.length < 1 || form.duration === "0") {
      setIsAlert(false);
      props.setIsAlert(false);
      setIsSubmitAlert(true);
      return;
    }
    if (updatedForm) {
      if (dataId) {
        const body = { ...form, PAL: useCustom ? customizeValue : calculatePAL() };
        const result = await updateDietPlan({
          body,
        });
        setAllTemplates({
          ...allTemplates, dietTemplates: allTemplates?.dietTemplates.map((item) => {
            if (item.id == result.id) {
              return {...result,totalEarning:item?.totalEarning,lastThreeMonthsUsers:item?.lastThreeMonthsUsers,totalClient:item?.totalClient,totalOrders:item?.totalOrders};
            }
            return item;
          })
        });
        updateNutritionist({
          ...nutritionistData,
          nutritionist: {
            ...nutritionistData.nutritionist,
            dietTemplates : nutritionistData.nutritionist.dietTemplates.map((item) => {
              if (item.id === result.id) {
                return result;
              }
              return item;
            }),
          },
        });
      }
      else {
        createdPlan = await postData({
          body: { ...updatedForm, PAL:  customizeValue },
        });
        nutritionistData?.nutritionist?.dietTemplates?.push(createdPlan);
        allTemplates?.dietTemplates?.push(createdPlan);
        updateNutritionist({
          ...nutritionistData,
          nutritionist: {
            ...nutritionistData.nutritionist,
          },
        });
      }
      !props.isAlert && setSuccess(true);
    } else {
      setSuccess(false);
      console.log('Error');
    }
    setIsSubmitAlert(false);
    setIsAlert(false);
    props.setIsAlert(false);
    props.getForm(setForm(items));
    const params = new URLSearchParams(location.search);
    const redirectBackTo = params.get('redirectBackTo');
    console.log(redirectBackTo);
    if (redirectBackTo) {
      history.push(`${redirectBackTo}?createdPlanId=${createdPlan.id}`);
    } else {
      props.isAlert && history.push(props.link);
    }
  };
  useEffect(() => {
    console.log(form)
    props.getForm && props.getForm(form);
  }, [form]);

  const generateAlertDiscard = () => {
    setIsAlert(false);
    props.setIsAlert(false);
    props.getForm(setForm(items));
    isAlert && history.push('/nutritionist/meal-plan');
    props.isAlert && history.push(props.link);
  };

  const generateAlertSave = () => {
    setIsSubmitAlert(false);
    setValue(0);
    setDisplaySection(true);
    setIsAlert(false);
    props.isAlert && props.setIsAlert(false);
    window.scrollTo(0, 0);
  };

  return (
    <NewPlanStyle>
      <div>
        <button className="go-back" onClick={generateGoBack}>
          Go Back
        </button>
      </div>
      {(isAlert || props.isAlert) && (
        <Alert
          title="Unsaved Changes"
          desc="Do you want to save the changes?"
          cancel={() => {
            isAlert && setIsAlert(false);
            props.isAlert && props.setIsAlert(false);
          }}
          cancelText="Cancel"
          discard={generateAlertDiscard}
          discardText="Discard"
          ok={() => handleSubmit()}
          okText="Save changes"
        />
      )}
      {isSubmitAlert && (
        <Alert
          title="Error"
          desc="Program name is empty. May you write program name, please?"
          ok={generateAlertSave}
          okText="Ok"
        />
      )}
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Plan Information" onClick={() => setDisplaySection(true)} />
        <Tab label="Metric Settings" onClick={() => setDisplaySection(false)} />
      </Tabs>
      <div className={classNames('section-wrapper', { active: displaySection })}>
        <NutritionistPlanInfo form={form} setForm={setForm} />
        <button type="button" className="primary-btn" onClick={moveToMetricSettings}>
          Next
        </button>
      </div>
      <div className={classNames('section-wrapper', { active: !displaySection })}>
        <NutritionistMetric
          form={form}
          setForm={setForm}
          dataId={dataId}
          handleSubmit={handleSubmit}
          success={success}
          useCustom={useCustom}
          setUseCustom={setUseCustom}
          customizeValue={customizeValue}
          setCustomizeValue={setCustomizeValue}
        />
      </div>
    </NewPlanStyle>
  );
}




