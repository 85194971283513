import styled from 'styled-components';

const PromocodeDeleteModalStyle = styled.div`
position: fixed;
inset: 0px;
z-index: 111;
background-color: rgb(28 28 28 / 60%);
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
padding-top:0px;
font-family: 'Roboto';
font-style: normal;

.delete-box{
 position: fixed;
 width:335px;
 background:#ffffff;
 box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
 border-radius: 10px;
 padding:12px;
 display:flex;
 flex-direction:column;
 align-items:center;
 min-height:262px;

 .close{
     display:flex;
     justify-content:flex-end;
     width:100%;
     img{
         cursor:pointer;
        }
   }
   .trash-div{
     width:64px;
     height:64px;
     background:#F0FAEB;
     border-radius:125px;
     display:flex;
     justify-content:center;
     align-items:center;
   }
   .text{
     text-align:center;
     margin-top:30px;
     font-weight: 400;
     font-size: 14px;
     line-height: 16px;
     color: #51635D;
   }
   .remove{
    width: 150px;
    height: 40px;
    background: #52C41A;
    border-radius: 21px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin-top:28px;
    margin-bottom:20px;
    cursor:pointer;
   }
}
@media(max-width:992px){
    .delete-box{
        width:315px !important;
    }
} 
`
export default function PromocodeDeleteModal({setIsOpen, isOpen, deletePromoCodeFn, idsToDelete, isDeletingUser, setIsDeletingUser, userInfo, handleDeleteUser}){
  console.log(isDeletingUser)
    return(
        <PromocodeDeleteModalStyle>
            <div className='delete-box'>
            <div className='close'>
              <img src='/icons/Clear-circle-cross.svg' onClick={() => {isDeletingUser ? setIsDeletingUser(false) : setIsOpen(false)}}/>
            </div>
            <div className='trash-div'>
               <img src='/images/trash-light.svg'/>
            </div>
          <div className='text'>
          {idsToDelete ? 
          <p>
          You are removing {idsToDelete?.length} promo codes.<br/>Are you sure?  
        </p> : 
        <p>
        You are deleting user account<br/>Are you sure?  
      </p>
          }
          </div>
          <div className='remove' 
          onClick={()=>{isDeletingUser ? handleDeleteUser(userInfo?.id, userInfo?.type?.toString()) : deletePromoCodeFn()}}>
            <p>Remove</p>
          </div>
            </div>
        </PromocodeDeleteModalStyle>
    )
}
