import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ImageViewer from 'components/ImageViewer';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import 'animate.css';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import Progress from 'components/Progress';
import ConfirmationRemoveAccount from 'components/admin/ConfirmationRemoveAccount';

const ManageProfileModalStyle = styled.section`
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    padding: 28px 48px 32px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 4;
    max-width: 370px;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    --animate-duration: 0.5s;
    .popup__header{
        .popup__title{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #51635D;
        }
        .cross_icon{
            position: absolute;
            top: 32px;
            right: 32px;
            cursor: pointer;
            width: 16px;
            height: 16px;
        }
    }
    .popup__body{
        flex: 1;
        form{
            display: flex;
            flex-direction: column;
            height: 100%;
            > div:first-child{
                flex: 1;
            }
        }
        .user_img_name{
            padding: 16px 0;
            text-align: center;
            .img{
                width: 128px;
                height: 128px;
                object-fit: cover;
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 14px;
            }
            .name{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-decoration-line: underline !important;
                color: #52C41A;
                display: block;
                word-break: break-all;
            }
        }
        .form_group{
            margin-bottom: 14px;
            position: relative;
            label{
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #51635D;
                padding: 9px 0;
                display: block;
            }
            .form_control{
                padding: 9px 10px 9px 14px;
                background: #FFFFFF;
                border: 1px solid #E0E3E0;
                border-radius: 6px;
                font-weight: 400;
                font-size: 14px !important;
                line-height: 16px;
                color: #51635D;
                width: 100%;
                height: 44px;
                display: block;
                &:disabled{
                    background-color: #fff !important;
                    color: #51635D !important;
                    cursor: auto !important;
                }
                &:empty{
                    position: relative;
                    &::before{
                        content: 'N/A';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 14px;
                    }
                }
            }
            .percent{
                position: absolute;
                right: 10px;
                bottom: 15px;
                color: #949D9B;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }
            .status__single-value{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
            }
            .status__indicators{
                margin-right: 10px;
            }
            .status__placeholder{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
            }
            .account_status_select{
                .status__control{
                    border-color: #E0E3E0;
                    height: 44px;
                    border-radius: 6px;
                }
            }
        }
        .btn{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            padding: 12px;
            width: 100%;
            border-radius: 50px;
            border: 1px solid #E0E3E0 !important;
            cursor: pointer;
            background-color: #fff;
            transition: all 0.2s;
            color: #51635D;
            &.save_btn{
                color: #FFFFFF;
                background-color: #52C41A !important;
                border: 1px solid #97DC76 !important;
                &:disabled{
                    background-color: #c3c3c3 !important;
                    border: 1px solid #c3c3c3 !important;
                }
            }
            &.cancel_btn{
                margin: 10px 0;
            }
            &:hover{
                border: 1px solid #97DC76 !important;
            }
            
        }
    }
    @media(max-width: 576px){
        padding: 20px 48px 32px;
        max-width: 100%;
        .popup__header{
            .popup__title{
                text-align: center;
            }
            .cross_icon{
                width: 24px;
                height: 24px;
                top: 14px;
                right: auto;
                left: 14px;
            }
        }
        .popup__body{
            .user_img_name{
                padding: 24px 0 16px;
                .img{
                    width: 96px;
                    height: 96px;
                }
            }
            .form_group{
                label{
                    margin-bottom: 0 !important;
                }
            }
        }
    }
`

const ManageProfileModalBg = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
`

export const ManageProfileModal = ({setShowManageModal, userToManage, setUsers, fetchAllNutritionist, setActiveRow, setIsAlert, setCoachIsSuspended, coachIsSuspended, handleProfileClick}) => {
    const [deleteUser,{isLoading:isDeleting}]=useRequest("/nutritionist/delete/","post")
    const [updateNutritionist, {isLoading: isUpdating, error, state: updatedState}] = useRequest(
        `nutritionist/profile/edit/${userToManage?.id}`,
        'post',
      );
    const [suspendNutritionist, {isLoading: isSuspending, suspendedError}] = useRequest('admin/account-suspended', 'put');
    const {isMobile} = useAppContext();
    const [animation, setAnimation] = useState(true);
    const [coach, setCoach] = useState(null);
    const [previousData, setPreviousData] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [isCommissionChanged, setIsCommissionChanged] = useState(false);
    const [defAccountStatus, setDefAccountStatus] = useState(null);
    const [isStatusChanged, setIsStatusChanged] = useState(false);
    const [isBackKey, setIsBackKey] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false);
    const [isConfirmRemove, setIsConfirmRemove] = useState(false);
    const defaultCommission = 2;
    const accountStatusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'suspended', label: 'Suspend' },
        { value: 'unlisted', label: 'Unlist' }
    ]
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleMenuOpen = () => {
      setMenuIsOpen(true);
    };

    const handleMenuClose = () => {
        setMenuIsOpen(false);
      };

    const DropdownIndicator = (props) => {
        return (
            <img
            className='image'
            style={{
              width: '16px',
              transform: props.menuIsOpen ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s ease',
            }}
            src='/icons/SelectArrow.svg'
          />
        )
    };
    const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#51635D' : "#51635D",
        backgroundColor: state.isFocused ? "#ffffff" : "#ffffff",
        textAlign: 'left',
        fontSize: '14px',
    })
    }
    const handleClose = (e) => {
        e.preventDefault();
        setAnimation(false);
        setActiveRow(null);
        setTimeout(() => {
            setShowManageModal(false);
        }, 500)
    }
    const handleSave = async (e) => {
        let resError;
        e.preventDefault();
        if(coach.name.length > 100){
            toast.error("Name must be less than or equal to 100");
            return;
        }
        if(coach.commission < 0 || coach.commission > 100){
            toast.error("Orders commission percentage must be less than or equal to 100");
            return;
        }
        if (coach?.phoneNumber && coach?.phoneNumber!=="N/A"){
            const phoneRegex = /^\d{3,}[-]?\d{3,}[-]?\d{4,}$/;
            const phoneVerified = phoneRegex.test(coach?.phoneNumber);
            if(coach?.phoneNumber?.length > 13 || coach?.phoneNumber?.length < 10){
                toast.error("Invalid Phone Number")
                return;
            }
            if(!phoneVerified){
                toast.error("Invalid Phone Number");
                return;
            }
        }   
        if(isChanged){
            const data = await updateNutritionist({
                body: {
                  ...coach
                },
            }).catch((err)=>{
                resError = {
                    message: String(err),
                    error: true
                }
            });
        }
        if(isStatusChanged){
            if(isSuspended){
                setIsAlert(true);
                return;
            }
            const res = await suspendNutritionist({
                body: {
                    ...coachIsSuspended
                }
            }).then((res) => {
                if(res.success == false){
                    resError = {
                        message: String(res.message),
                        error: true
                    }
                }
            })
        }
        if(error || suspendedError || resError?.error){
            toast.error(resError?.message || "Error updating profile.")
            return;
        }
        fetchAllNutritionist();
        setAnimation(false);
        setTimeout(()=>{
            setShowManageModal(false);
        }, 500)
        toast.success("Profile updated successfully.");
        setIsChanged(false);
    }
    const handleDelete = async (e) => {
        e.preventDefault();
        await deleteUser({
            path:`nutritionist/delete/${userToManage?.id}`,
        }).then((result)=>{
            if(!result?.success){
                toast.error(result.message);
                return;
            }
            setUsers((prevUsers)=>prevUsers?.filter((user)=>user?.id !== userToManage?.id));
            setAnimation(false);
            setTimeout(()=>{
                setShowManageModal(false);
            }, 500)
            toast.success(result?.message);
        }).catch((err)=>{
            console.log(err)
        })
    }
    useEffect(() => {
        setPreviousData({
            name: userToManage?.name,
            isApproved: userToManage?.isApproved,
            isSuspended: userToManage?.isSuspended || false,
            url: userToManage?.url,
            commission: userToManage?.commission || defaultCommission,
            phoneNumber: userToManage?.phoneNumber
        })
        setCoach({
            name: userToManage?.name,
            isApproved: userToManage?.isApproved,
            commission: userToManage?.commission || defaultCommission,
            phoneNumber: userToManage?.phoneNumber
        })
    
        userToManage?.isApproved ? 
        setDefAccountStatus(accountStatusOptions[0]) 
        : userToManage?.isSuspended ? 
        setDefAccountStatus(accountStatusOptions[1]) 
        : setDefAccountStatus(accountStatusOptions[2])

    }, [userToManage])
    const handleStatusChange = (e) => {
        setIsSuspended(false);
        let approved = e.value === 'active' ? true : false;
        let suspended = e.value === 'suspended' ? true : false;
        setCoach({
            ...coach,
            isApproved: approved,
        })
        setCoachIsSuspended({
            nutritionistId: userToManage?.id,
            isSuspended: suspended,
            active: approved,
        })
        if(suspended){
            setIsSuspended(true);
        }
        setIsStatusChanged(() => {
            if(previousData?.isApproved == approved && previousData?.isSuspended == suspended){
                return false;
            }else{
                return true;
            }
        });
        setDefAccountStatus(e);
    }
    const handleChange = (e) => {
        const {name:target_name, value} = e.target;
        const isCommission = target_name === "commission";
        let newValue = value;
        
        if (isCommission) {
            newValue = newValue.replace(/^0[0-9]*/, "0");
            if (newValue < 0 || newValue === "") {
                newValue = "";
            } else if (newValue > 100) {
                newValue = 100;
            } else if (newValue?.length > 5) {
                newValue = parseFloat(newValue).toFixed(2);
            }
        }
    
        setCoach((prev) => {
            return {
                ...prev,
                [target_name]: newValue
            }
        })
        setIsChanged(() => {
            if((String(previousData[target_name]) == String(newValue).trim()) || !value || (!newValue && newValue !== 0) ){
                return false;
            }else{
                return true;
            }
        })
    }
    const handleKeyDown = (e) => {
        if(e.keyCode == 8){
            setIsBackKey(true);
        }else{
            setIsBackKey(false);
        }
    }

    const generateConfirmationRemove = useCallback((e) => {
        e.preventDefault();
        setIsConfirmRemove(true);
    }, [isConfirmRemove])

    return (
        <>
            <ManageProfileModalBg className='wrap' onClick={handleClose}></ManageProfileModalBg>
            <ManageProfileModalStyle className={`animate__animated ${animation ? 'animate__slideInRight' : 'animate__slideOutRight'}`}>
                <div className='popup__header'>
                    <h3 className='popup__title'>Coach Info</h3>
                    <ImageViewer src={isMobile ? '/icons/close-cross-green.svg' : '/icons/close-cross.svg'} fallbackSrc={isMobile ? '/icons/close-cross-green.svg' : '/icons/close-cross.svg'} className={'cross_icon'} onClick={handleClose}/>
                </div>
                <div className='popup__body'>
                    <form>
                        <div>
                            <div className='user_img_name'>
                                <ImageViewer src={userToManage?.img || '/images/man.svg'} fallbackSrc='/images/man.svg' className='img'/>
                                <Link className='name' onClick={() => {handleProfileClick(userToManage)}}>{coach?.name} Profile</Link>
                            </div>
                            <div className='form_group'>
                                <label htmlFor='name'>Name</label>
                                <input type='text' id="name" name="name" onChange={handleChange} value={coach?.name} className='form_control'/>
                            </div>
                            <div className='form_group'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' id="email" name="email" disabled  value={userToManage?.email || 'N/A'} className='form_control'/>
                            </div>
                            <div className='form_group'>
                                <label htmlFor='phone'>Phone number</label>
                                <input type='tel' id="phone" name="phoneNumber" onChange={handleChange} value={coach?.phoneNumber} placeholder={'N/A'} className='form_control'/>
                            </div>
                            <div className='form_group'>
                                <label htmlFor='AccountStatus'>Account status</label>
                                <Select
                                    className="account_status_select"
                                    classNamePrefix="status"
                                    options={accountStatusOptions}
                                    styles={customStyles}
                                    id="AccountStatus"
                                    name="AccountStatus"
                                    onMenuOpen={handleMenuOpen}
                                    onMenuClose={handleMenuClose}
                                    defaultValue={userToManage?.isApproved ? accountStatusOptions[0] : userToManage?.isSuspended ? accountStatusOptions[1] : accountStatusOptions[2]}
                                    onChange={handleStatusChange}
                                    components={{
                                    DropdownIndicator: (props) => <DropdownIndicator {...props} menuIsOpen={menuIsOpen} />,
                                    IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                            <div className='form_group'>
                                <label htmlFor='commission'>Orders commission percentage</label>
                                <input type='number' min={0} max={100} id="commission" name="commission" onChange={handleChange} value={coach?.commission} className='form_control'/>
                                <span className='percent'>%</span>
                            </div>
                        </div>
                        <div>
                            <button type='submit' className='btn save_btn' disabled={!isChanged && !isStatusChanged || isUpdating || isSuspending} onClick={handleSave}>Save</button>
                            <button className='btn cancel_btn' onClick={handleClose}>Cancel</button>
                            <button className='btn' onClick={generateConfirmationRemove}>Delete coach profile</button>
                        </div>
                    </form>
                    {(isUpdating || isSuspending || isDeleting) && <Progress/>}
                </div>
            </ManageProfileModalStyle>
            {isConfirmRemove && <ConfirmationRemoveAccount setIsConfirmRemove={setIsConfirmRemove} accountType="coach" handleRemove={handleDelete} isDeleting={isDeleting} />}
        </>
    )
}   