import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDrop } from 'react-dnd';
import CartCalories from './CartCalories';
import { renderTotalCalories } from 'hooks/caloriesColorRules';
import autoAnimate from '@formkit/auto-animate'

const PlannerCardStyle = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 18px;

  .day__number{
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F6F5F8;

    .number{
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #51635D;
      font-family: 'Roboto';
    }

    .add_more_meal{
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #52C41A;
      background-color: unset;
    }
  }

  .planner__items__holder{
    padding: 12px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;

    &.slideDownAnim{
      animation: slideDown60 500ms ease 0s;
    }

    &.slideUpAnim{
      animation: slideUpAnim60 500ms ease 0s;
    }

    @keyframes slideDown60{
      0%{
        transform: translateY(-60px);
      }
    
      100%{
        transform: translateY(0px);
      }
    }

    @keyframes slideUpAnim60{
      0%{
        transform: translateY(60px);
      }
    
      100%{
        transform: translateY(0px);
      }
    }
  }

`

export default function PlannerCard({ children, title, accept, setCurrentDragDay, mealPlan, setMealPlannerModal }) {

  const [{ canDrop, isOverCurrent }, drop] = useDrop({
    accept,
    drop: () => ({ name: title }),
    canDrop: () => true,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    setCurrentDragDay(isOverCurrent ? title : '');
  }, [isOverCurrent]);

  const calcCalorie = renderTotalCalories('calories', mealPlan, title);
  const calcProtein = renderTotalCalories('protein', mealPlan, title);
  const calcFat = renderTotalCalories('fat', mealPlan, title);
  const calcCarbs = renderTotalCalories('carbs', mealPlan, title);
  const calculatedValues = {calcCalorie, calcFat, calcProtein, calcCarbs};
  
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  return (
    <>
    <PlannerCardStyle ref={drop}>
      <div className='day__number'>
        <h3 className='number'>{title}</h3>
        <button className='add_more_meal' onClick={()=>setMealPlannerModal(false)}>+ Add more meal</button>
      </div>

      <CartCalories calculatedValues={calculatedValues}/>

      <div className={`planner__items__holder`} ref={parent}>
        {children}

        {canDrop && isOverCurrent && <div style={{backgroundColor: '#52C41A', width: "100%", height: "4px", borderRadius:"2px"}}></div>}
      </div>
    </PlannerCardStyle>
    </>
  )
}
