import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import NoItemScreen from './NoItemScreen';
import { useRequest } from 'hooks/useRequest';
import { CategoryCard } from './CategoryCard';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { BiPlus } from 'react-icons/bi';
import { useKitchenContext } from 'context/kitchenContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useAppContext } from 'context/appContext';
import { CircularProgress } from '@material-ui/core';
import { useDragLayer } from 'react-dnd';
import { CategoryCardStyle } from './CategoryCard';
import { ReactComponent as DragIcon } from 'assets/drag-handle.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right2.svg';
import { ReactComponent as More } from 'assets/more-vertical.svg';
import ActionButton from 'components/elements/ActionButton';
import { MealStyle, MainCardStyle } from 'components/elements/meal/Meal';

const AddCategoryStyle = styled.div`
  &.add_category {
    max-width: 900px;
    margin-top: 16px;
    text-align: right;
    button {
      color: ${({ theme }) => theme?.colors?.primaryDark};
    }
  }
  .btn::after {
    padding: 0px 3px;
  }
`;

const ParentCategoryStyle = styled.div`
padding-right: 202px;
display: flex;
flex-direction: column;
align-items: flex-end;
border-radius: 8px;
@media (max-width: 1024px){
  padding-right: 0;
  max-width: 900px;
}
`
const Progress = styled.div`
  flex: 1;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const CustomDragPreview = ({categoryCardRef}) => {
  const { itemType, isDragging, currentOffset, initialOffset, item } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getClientOffset(),
    initialOffset: monitor.getInitialClientOffset(),
    item: monitor.getItem(),
  }));

  if (!isDragging || itemType !== 'CATEGORY' || !currentOffset || !initialOffset || !categoryCardRef) {
    return null;
  }

  const x = currentOffset?.x;
  const y = currentOffset?.y;
  const width = categoryCardRef?.current?.offsetWidth;

  return (
    <CategoryCardStyle
      theme={theme}
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 9999,
        left: x - 25,
        top: y - 15,
        opacity: 1,
        width: width,
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)'
      }}
    >
    <div className="category_title_holder">
          <div className="drag_input_holder">
            <div className='drag_icon'>
            <DragIcon />
            </div>
            <h2 className="category_title">{item?.categoryName}</h2>
          </div>
          <div className="button_arrow_holder">
              <div
              className="dropdown-icon"
            >
              <ActionButton
                className="dropdown-action"
                btnText={<More />}
              />
            </div>
            <div className="accordion_handler">
              <ArrowRight/>
            </div>
          </div>
        </div>
    </CategoryCardStyle>
  );
};

const CustomMealDragPreview = ({mealCardRef}) => {
  const { itemType, isDragging, currentOffset, initialOffset, item } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getClientOffset(),
    initialOffset: monitor.getInitialClientOffset(),
    item: monitor.getItem(),
  }));

  if (!isDragging || itemType !== 'ITEM' || !currentOffset || !initialOffset || !mealCardRef) {
    return null;
  }

  const x = currentOffset?.x;
  const y = currentOffset?.y;
  const width = mealCardRef?.current?.offsetWidth;

  return (
    <MainCardStyle 
    style={{
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 9999,
      left: x - 10,
      top: y - 40,
      opacity: 1,
      width: width,
      paddingTop: 0
    }}>
      <div className="drag_icon">
        <DragIcon />
      </div>
      <MealStyle style={{ boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)' }} theme={theme}>
        <div className="image">
          <img src={item?.images?.length > 0 ? item?.images[0] : '/images/dish.png'} className="meal_img" />
        </div>
        <h2 className="meal_name">{item?.name}</h2>
        <h3 className="amount">${item?.price.toFixed(2)}</h3>
        <div className="icon-container">
          <img className="icon" src="/icons/edit2.svg"></img>
          <img className="icon" src="/icons/trash2.svg"></img>
        </div>
      </MealStyle>
    </MainCardStyle>
  )
}

export default function Overview({categoryList, setCategoryList}) {
  const [fetchCategories, { isLoading }] = useRequest();
  const { isSuper, mealCategoriesList, setMealCategoriesList, isCreateCategory, setIsCreateCategory } = useKitchenContext();
  const imitateProviderStorage=sessionStorage.getItem("imitateProvider");
  const { isMobile } = useAppContext();
  const [mealsData, setMealsData] = useState([]);
  const categoryCardRef = useRef();
  const mealCardRef = useRef();

  const imitateProviderId = useMemo(() => {
    const parsedId = JSON.parse(imitateProviderStorage)?.id;
    return parsedId
}, [imitateProviderStorage]);

const profileId = useMemo(() => {
  const parsedId = JSON.parse(imitateProviderStorage)?.BusinessProfile?.[0]?.id;
  return parsedId
},[imitateProviderStorage])

  const fetchAllCategories = async (providerId, profileId) => {
    const path = providerId ? `meal/category-list?providerId=${providerId}&businessProfileId=${profileId}` : 'meal/category-list'
    await fetchCategories({
      path,
      method: "get"
    }).then((data) => {
      setMealCategoriesList(data?.data);
      setCategoryList(data?.data);
      isCreateCategory && handleAddCategory();
      setIsCreateCategory(false);
    })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    if (isSuper) {
      fetchAllCategories(imitateProviderId, profileId)
    } else {
      fetchAllCategories()
    }
  }, [imitateProviderId, profileId])


  const handleAddCategory = () => {
    setCategoryList((prev) => [
      ...prev,
      {
        categoryId: prev.length > 0 ? 'dummy' + prev[prev.length - 1].categoryId + 1 : 'dummy' + 0,
        categoryName: '',
        meals: '',
        editing: true
      }
    ])
  };

  useEffect(() => {
    if(categoryList?.length){
      let mealsDataa = [];
      categoryList?.forEach((category) => {
        if(category?.meals?.length){
          category?.meals?.forEach((meal) => {
            const updatedMealWithCategoryId = {...meal, mealCategoryId: category?.categoryId, categoryOrderIndex: category?.orderIndex};
            mealsDataa.push(updatedMealWithCategoryId);
          });
        }
      });
      setMealsData(mealsDataa);
    }
  }, [categoryList])

  return (
    <>
      {isLoading && categoryList?.length < 1?
        <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> 
              : (
        <DndProvider 
          backend={isMobile ? TouchBackend : HTML5Backend}
          options={{ enableTouchEvents: true, enableMouseEvents: true }}
        >
            <CustomDragPreview categoryCardRef={categoryCardRef} />
            <CustomMealDragPreview mealCardRef={mealCardRef} />
              {categoryList?.length > 0 ? (
                <>
                  <ParentCategoryStyle>
                  {categoryList?.map((category, index) => (
                    <div style={{width: '100%'}} ref={categoryCardRef}>
                    <CategoryCard 
                    category={category}
                    editing={category?.editing} 
                    setCategoryList={setCategoryList} 
                    categoryList={categoryList}
                    mealsData={mealsData}
                    setMealsData={setMealsData}
                    key={index}
                    index={index}
                    mealCardRef={mealCardRef}
                    profileId={profileId}
                    />
                    </div>
                  ))}
                  <AddCategoryStyle theme={theme} className="add_category">
                    <Button
                      type={'tertiary'}
                      title="Add category"
                      iconLeft={<BiPlus />}
                      onClick={handleAddCategory}
                    />
                  </AddCategoryStyle>
                  </ParentCategoryStyle>
                </>
              ) : (
                <NoItemScreen handleCreateCategory={handleAddCategory}/>
              )}
        </DndProvider>
      )}
    </>
  );
}
