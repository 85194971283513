import styled from 'styled-components';
import MealNutritions from './MealNutritions';

const StyledTotals = styled.div`
  margin-top: 20;
  display: grid;
  grid-template-columns: 2fr 1.25fr;
`;

export default function SummaryTotals({ cart, isGrandTotal, taxRate }) {
  const totals = cart.reduce(
    (accum, meal) => {
      const { calories, carbs, fat, protein, weight, price, qty } = meal;
      accum.calories += calories * qty;
      accum.carbs += carbs * qty;
      accum.fat += fat * qty;
      accum.protein += protein * qty;
      accum.weight += weight * qty;
      accum.price += price * qty;
      return accum;
    },
    { calories: 0, carbs: 0, fat: 0, protein: 0, weight: 0, price: 0 },
  );

  const total = totals.price / 100;
  const tax = total * taxRate || 0; //|| 8.875;
  const shipping = 0;
  const grandTotal = (tax + total + shipping).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <StyledTotals>
      <div>
        <MealNutritions nutritions={totals} />
      </div>
      <StyledTotals>
        <div>
          <h6>Total</h6>
          <h6>Shipping</h6>
          <h6>Tax</h6>
          {isGrandTotal ? <h3>Grand total</h3> : <h5>Provider total</h5>}
        </div>

        <div
          style={{
            justifySelf: 'end',
            display: 'grid',
            justifyItems: 'end',
            marginRight: 10,
          }}
        >
          <h6>
            {total.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </h6>
          <h6>
            {shipping.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </h6>
          <h6>
            {tax.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </h6>
          {isGrandTotal ? <h3>{grandTotal}</h3> : <h5>{grandTotal}</h5>}
        </div>
      </StyledTotals>
    </StyledTotals>
  );
}
