import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { theme } from 'shared/theme/theme';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  height: 64px;
  background: rgba(255, 255, 255);

  .header-container {
    height: 100%;
    padding: 10px 14px 10.59px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-wrapper {
    display: flex;
      }

  .header-btn-wrapper {
    button {
      width: 90px;
      height: 32px;
      padding: 0;
      border-radius: 30px;
      background: white;
      border: 1px solid #757E89 !important;
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      a {
        width: 100%;
        height: 100%;
        color: ${theme.colors.primaryDarker};
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Primary-Darker, #00092D);
        text-align: center;
        /* Gilroy/Subhead – 16pt Bold */
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        
      }
    }

    /* button:first-child {
      padding: 0 14px;
      background: #52C41A;
      color: #fff;
    } */

    /* button:last-child {
      width: 70px;
      background: #fff;
      color: #51635D;
      border: 1px solid #97DC76 !important;
    } */
  }

  @media (min-width: 992px) {
    height: 74px;
    border: 1px solid ${theme.colors.lightGrey};
    background: rgba(255, 255, 255, 0.9);
    box-shadow:none;
    .header-container {
      padding: 0 48px;
    }
    .header-btn-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      button {
        width: 122px;
        height: 42px;
        background: transparent;
        color: ${theme.colors.primaryDarker};
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        :first-child{
          &:hover {
            color: var(--New-757E89, #757E89);
            border-color: var(--New-000A2C, #000A2C) !important;
          }
        
          &:active {
            border-color: var(--New-E0E4E8, #E0E4E8) !important;
          }
        }
      }
      Button:last-child {
        background-color: ${theme.colors.primaryDefault};
        color: ${theme.colors.secondary};
        &:hover {
          background: var(--Primary-Dark, #3B425D);
        }
      }
    }
  }
`;

export default function Header({ setIsQrOpen }) {
  const history = useHistory();
  const { isMobile, isIOS, isAndroid, userSchemeNavigation } = useAppContext();
  return (
    <HeaderWrapper>
      <div className="header-container">
        <div className="logo-wrapper">
          <img src="/images/nutritt-black.svg" alt="Logo" />
        </div>
        <div className="header-btn-wrapper">
          {!isMobile && <button onClick={() => setIsQrOpen(true)}>Get app</button>}
          {isMobile && <button onClick={() => userSchemeNavigation({ appStoreLink: "https://apps.apple.com/in/app/nutritt/id1605156638", playStoreLink: "https://play.google.com/store/apps/details?id=com.nutritt&hl=en&gl=US", schemeURL: "nutrittapp://" })}><a>Get app</a></button>}
          {!isMobile &&<button onClick={() => history.push('/account/home')}>Login</button>}
        </div>
      </div>
    </HeaderWrapper>
  )
}