import { styled } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import { theme } from 'shared/theme/theme';

export const OrderLimitOopsWrapper = styled(Dialog)({
  backgroundColor: `rgba(11, 29, 23, 0.65)`,
  fontFamily: `${theme.typography.fontGilroy}`,

  '.MuiBackdrop-root': {
    backgroundColor: 'transparent !important',
  },

  '.MuiDialog-container': {
    backgroundColor: 'transparent',
  },

  '.MuiPaper-root': {
    margin: '16px',
    backgroundColor: `${theme.colors.white}`,
    borderRadius: '10px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.1)',
  },

  '.order-limit-container': {
    minWidth: '400px',
    maxWidth: '500px',
    padding: '52px 0 45px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    h3: {
      padding: '0 27.5px',
      color: `${theme.colors.primaryDarker}`,
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '24px',
    },
    p: {
      maxWidth: '277px',
      margin: '8px 0 32px',
      color: `${theme.colors.primaryDarker}`,
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
    },
  },

  '.btn.dark': {
    width: '220px',
    height: '42px',
    borderRadius: '30px',
    fontFamily: `${theme.typography.fontGilroy}`,
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: 'normal',
    cursor: 'pointer',
  },

  '@media (max-width: 991px)': {
    '.MuiPaper-root': {
      width: '100%',
    },
    '.order-limit-container': {
      minWidth: '100%',
      maxWidth: '100%',
      p: {
        margin: '8px auto 32px',
      },
    },
  },
});

export const MealLimitDetailsWrapper = styled('div')({
  overflowY: 'auto',
  width: '100%',
  maxHeight: 'calc(100vh - 450px)',
  marginBottom: '39px',
  padding: '0 27.5px',

  '.meal-limit-container': {
    marginBottom: '20px',
    display: 'flex',
    gap: '14px',
    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  img: {
    width: '110px',
    height: '110px',
    borderRadius: '10px',
    objectFit: 'cover',
  },

  '.meal-details': {
    padding: '11px 0 13px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '.meal-name': {
    color: `${theme?.colors.deepForestGreen}`,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '18px',
  },

  '.meal-price': {
    color: `${theme.colors.primaryDarker}`,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
  },
});
