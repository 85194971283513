import { useState, useEffect, useMemo } from 'react';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import Tags from 'components/shop/MealTags';
import useCart from 'hooks/useCart';
import useDisplayAmount from 'hooks/useDisplayAmount';
import styled from 'styled-components';
import LinesEllipsis from 'react-lines-ellipsis';
import MealCardContent from 'components/shop/MealCardContent';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import { NavigateBeforeSharp } from '@material-ui/icons';
import ImageViewer from 'components/ImageViewer';
import MealCardDescriptionModal from 'components/shop/MealCardDescriptionModal';
import { AVAILABLE_TAGS } from '../../../constants';

const Container = styled.div`
  margin-bottom: 10px;
  padding: 15px 0;
  border-top: 1px solid var(--grayBorder);
  border-bottom: 1px solid var(--grayBorder);

  .container {
    position: relative;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .img-wrapper {
    width: 40%;

    img {
      width: 100%;
      height: 150px;
      border-radius: 8px;
      box-shadow: 0 4px 16px var(--imgShadow);
      object-fit: contain;
    }
  }

  .title-wrapper {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fresh-card-info {
    width: 60%;
    .title {
      color: var(--black);
      font-size: 18px;
      line-height: 24px;
    }
  }

  .meal-card-description {
    min-width: 196px;
    margin-bottom: 6px;
    color: #747c7a;
    font-size: 12px;
    line-height: 18px;

    &.LinesEllipsis--clamped {
      cursor: pointer;
    }

    .LinesEllipsis-ellipsis {
      color: #52c41a;
    }
  }

  .add-to-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 110px;
      height: 36px;
      background-color: var(--nutritt-green);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border: 0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 0 #00000014;
      cursor: pointer;
      :disabled {
        cursor: not-allowed;
        background-color: #c3cbc9;
      }

      &.math-wrapper {
        justify-content: space-between;
        cursor: auto;

        .math {
          margin-top: 3px;
          cursor: pointer;
        }
      }
    }
  }
  .oz-number {
    color: #747c7a;
    font-size: 14px;
  }
  .price {
    color: var(--dark);
    font-size: 16px;
    line-height: 21px;
  }

  @media (min-width: 992px) {
    border: 0;
    
    .meal-card-box{
      position: relative;
      height: 421px;
      margin: 0;
      padding: 30px 20px 22px;
      background: #ffffff;
      border: 0;
      border-radius: 10px;
      align-items: flex-start;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
     -webkit-transition: transform .2s;
      &:hover{
        -webkit-transform:scale(0.95);
      }
    }

    // &:hover .img-wrapper img {
    //   opacity: 0.55;
    //   transition: all 0.3s ease-in-out;
    // }

    .cardInner-info {
      position: static;
      width: 100%;
      flex-direction: column;
      cursor: pointer;
    
      .img-wrapper {
        width: 160px;
        height: 160px;
        margin: 0 0 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .recommended.only span {
          position: absolute;
          right: -5px;
          z-index: 99;
          top: -4px;
          width: 78.58px;
          height: 78.58px;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      .fresh-card-info {
        width: 100%;

        .fresh-card-content {
          height: auto;
          padding: 5px 0;
        }

        .fresh-card-container {
          padding: 1px 0;
          border-right: 1px solid #f6f5f8;;
        }

        .title-wrapper {
          min-height: 65px;
          margin-bottom: 16px;
          display: block;
        }

        .title {
          margin-bottom: 8px;
          color: #0b1d17;
          font-size: 16px;
          font-family: 'Roboto';
          line-height: 19px;
          font-weight: 700;
          font-style: normal;
          text-overflow:ellipsis;
          overflow:hidden;  
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
         
        }

        .price{
          line-height: 19px;
        }
      }

      .meal-card-tags {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        height: 200px;
        overflow-y: clip;

        .recommended-list {
          margin-bottom: 0;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 5px;
          .icon-wrapper {
            width: 24px;
            height: 24px;
            cursor: pointer;

            svg {
              width: 15px;
              height: 15px;
            }
          }

          .iconList45 + .iconList45 {
            margin-top: 5px;
          }
        }
      }

      .add-to-cart {
        margin-top: 24px;

        button {
          width: 100%;
          height: 40px;
          background: #ffffff;
          color: #51635d;
          border: 1px solid #97dc76 !important;
          border-radius: 21px;
          font-size: 14px;
          font-family: 'Roboto';
          line-height: 16px;

          &.math-wrapper {
            padding: 0 15px;
            background-color: #52c41a;
            color: #ffffff;
            stroke: #52c41a;
          }
        }
      }
    }
  }
  @media (max-width: 576px){
    .fresh-card-info{
      .title{
        &.small{
          width: 75% !important;
          word-break: break-word;
        }
        
      }
    }

    .meal-card-description {
      padding-right: 20px !important;
    }
  }
`;



export default function MealCard(meal) {
  const { name, img, description, calories, carbs, fat, protein, price, id, restrictions:mealRestrictions, tags:mealTags } =
    meal;
  const [maxLines, setMaxLines] = useState(2);
  const [notRecommendedRestrictions, setNotRecommendedRestrictions] = useState([]);
  const [recommendedTags, setRecommendedTags] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const { cart } = useShopContext();

  function filterAndModifyTags(serverTags, availableTags) {
    const filteredAndModifiedTags = [];
  
    for (const serverTag of serverTags) {
      const sanitizedServerTag = serverTag
        .toLowerCase() // Convert to lowercase
        .replace(/[\s_]/g, ''); // Remove spaces and underscores
  
      const matchingAvailableTag = availableTags.find(availableTag =>
        sanitizedServerTag === availableTag.toLowerCase().replace(/[\s_]/g, '')
      );
  
      if (matchingAvailableTag) {
        filteredAndModifiedTags.push(matchingAvailableTag);
      }
    }
  
    return filteredAndModifiedTags;
  }

  const restrictions = filterAndModifyTags(mealRestrictions,AVAILABLE_TAGS)
  const tags = filterAndModifyTags(mealTags, AVAILABLE_TAGS);

console.log(tags)
  let {
    isMobile,
    profile: { dietTemplate = {}, selfMealPlan = {} } = {},
  } = useAppContext();
  const { addToCart, updateMealQty, removeFromCart } = useCart(); 
  const mealInCart = cart.find((meal) => meal.id === id);
  function getNotRecommendedRestrictions() {
    let notRecommendedMeal = [];

    return new Promise((resolve) => {
      const avoidTags = dietTemplate?.food_to_avoid || selfMealPlan?.allergies || []; 
      avoidTags?.length &&
          avoidTags?.forEach((food) => {
          restrictions.forEach((restriction) => {
            if (restriction.toLowerCase() === food.toLowerCase()) {
              !notRecommendedRestrictions.includes(food) && notRecommendedMeal.push(food);
            }
          });
        });
      setNotRecommendedRestrictions(notRecommendedMeal);
      resolve(true);
    });
  }

  const clearString = (string) => string.replace(/-|\s/g, '');

  function getRecommendedTags() {
    let recommendedMeal = [];
    const recommendTags = dietTemplate?.tags || selfMealPlan?.dietaryPreferences || [];

    tags.length &&
      recommendTags?.forEach((dietTag) => {
        let clearDietTag = clearString(dietTag);
        tags.forEach((tag) => {
          let clearTag = clearString(tag);
          if (clearTag.toLowerCase() === clearDietTag.toLowerCase()) {
            !recommendedTags.includes(tag) && recommendedMeal.push(tag);
          }
        });
      });
    setRecommendedTags(recommendedMeal);
  }

  useEffect(async () => {
    await getNotRecommendedRestrictions();

    if (notRecommendedRestrictions.length !== 0) {
      return;
    }

    getRecommendedTags();
  }, [dietTemplate, selfMealPlan]);

  useEffect(() => {
    isModal && !isMobile ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
  }, [isModal, !isMobile])

  function convertToHttps(imageUrl) {
    if (typeof imageUrl === 'string' && imageUrl.trim().length > 0 && imageUrl.indexOf("http://") === 0) {
      return "https://" + imageUrl.slice(7);
    }
    return imageUrl;
  }

  const generateAllTags = useMemo(() => {
    const allTags = tags.concat(restrictions);
    return allTags;
  });

  const imageUrl = img;
const secureImageUrl = convertToHttps(imageUrl);
  return (
    <Container className="meal-card-inner-box">
      <div className='meal-card-box'>
      <div className="container cardInner-info" onClick={() => setIsModal(true)}>
        <div className="img-wrapper">
          <ImageViewer
            src={secureImageUrl || '/images/dish.png'}
            fallbackSrc={'/images/dish.png'}
            className={secureImageUrl || 'img-coming-soon'}
            alt={name}
          />
          {notRecommendedRestrictions.length === 0 && recommendedTags.length !== 0 && (
            <div className="recommended only">
              <span style={{ backgroundImage: 'url("/images/recommanded-shape.svg")' }} />
            </div>
          )}
          {notRecommendedRestrictions.length > 0 && (
            <div className="recommended not-recommended">
              <span></span>
            </div>
          )}
        </div>
        <div className="fresh-card-info">
          <div className="title-wrapper">
            <div className={`title ${recommendedTags.length !== 0 || notRecommendedRestrictions.length > 0 ? 'small' : ''}`}>{name.indexOf(' ') === -1 && name.length > 15 ? name.slice(0, 15) + '...' : name}</div>
            {!isMobile && <div className="price">{useDisplayAmount(price)}</div>}
          </div>
          {isMobile && (
            <LinesEllipsis
              text={description}
              ellipsis="...more"
              maxLine={maxLines}
              className="meal-card-description"
              onClick={() => setMaxLines(maxLines + 8)}
            />
          )}
          <MealCardContent calories={calories} carbs={carbs} fat={fat} protein={protein} />
          <div className="meal-card-tags">
            <Tags
              tags={tags}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
              generateAllTags={generateAllTags}
            />
            {/* <Tags
              tags={restrictions}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
            /> */}
          </div>
          <div className="add-to-cart">
            {isMobile && <div className="price">{useDisplayAmount(price)}</div>}
            {mealInCart && mealInCart.qty >= 1 ? (
              <button className="math-wrapper" onClick={(e) => e.stopPropagation()}>
                <span
                  className="math"
                  onClick={() =>
                    mealInCart.qty == 1
                      ? removeFromCart(meal.id)
                      : updateMealQty(meal, +mealInCart.qty - 1)
                  }
                >
                  <CgMathMinus />
                </span>
                <span>{mealInCart.qty}</span>
                <span className="math" onClick={() => addToCart(meal)}>
                  <CgMathPlus />
                </span>
              </button>
            ) : (
                <button onClick={(e) => {
                  e.stopPropagation();
                  addToCart(meal)
                }}> {isMobile ? <img src="/icons/add.svg" alt="Add Cart" /> : 'Add to cart'} </button>
            )}
          </div>
        </div>
      </div>
      </div>
      {!isMobile && isModal && <MealCardDescriptionModal meal={meal} setIsModal={setIsModal} recommendedTags={recommendedTags} notRecommendedRestrictions={notRecommendedRestrictions} />}
    </Container>
  );
}
