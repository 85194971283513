import Message from './components/Message';
import Toggle from 'components/elements/toggleModule/Toggle';
import DropDown from 'components/elements/drop-down/Dropdown';
import 'animate.css';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const EditScheduleStyle = styled.div`
.schedules_date {
  display: flex;
  flex-direction:column;
  gap: 40px;
  padding-bottom: 8px;
}
.schedules_date_time {
  display: flex;
  gap: 40px;
  padding-bottom: 8px;
  .toggle {
      margin-top: 11px;
      width:200px;
      text {
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .time-dropdown {
    display: flex;
    gap: 12px;
    span{
      color: ${({ theme }) => theme.colors.blackDefault}
    }
    label{
      color: ${({ theme }) => theme?.colors?.blackLight};
    }
    &.selected{
      .selected-value{
          background:  ${({ theme, selectedDays }) =>
  selectedDays.length > 0 ? theme?.colors?.secondary : theme.colors.lightGrey};
        }
    }
  }
}
.text {
  color: ${({ theme }) => theme?.colors?.blackDefault};
  font-size: ${({ theme }) => theme?.typography?.fontRegular};
  font-weight: 400;
  font-style: normal
  line-height: 20px;
}
.order_settings {
  padding-left: 16px;
  margin-top:24px;
  border-left: 2px solid ${({ theme }) => theme?.colors?.grayLight};
  .order_settings_title {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 21px;
  }
}
.radio-btn{
  display:flex;
  width: 50%;
  justify-content: space-between;
  &.selected{
      .selected-value{
          background:  ${({ theme, selectedDays }) =>
  selectedDays.length > 0 ? theme?.colors?.secondary : theme.colors.lightGrey};
        }
    }
}
.order_title {
  display: flex;
  margin-top: 25px;
  .titles {
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
  }
}
.message-div{
  margin-top:21px;
  margin-right: 11px;
  margin-bottom:15px;
}
`
export const EditSchedule = ({
  days,
  selectedRadio,
  selectedDays,
  options,
  selectedTabId,
  hourOptions,
  daysOptions,
  handleDayToggle,
  handleRadioToggle,
  handleTimeChange,
  selectedTimes,
  handleLeadTimeChange,
  handleCutoffDateChange,
  parentRef
}) => {
  const DropdownVariants = {
    hidden: {
      opacity: 0,
      y: 0,
      pointerEvents: 'none',
    },
    visible: {
      opacity: 1,
      y: 0,
      pointerEvents: 'auto',
    },
  };

  const dropdownValues = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  return (
    <EditScheduleStyle theme={theme} selectedDays={selectedDays}>
          <div className="data_title">
        <div className="titles left">Day</div>
        <div className="titles right">{selectedTabId === 1 ? "Pickup hour" : "Delivery hour"}</div>
      </div>
      {days.map((day) => (
        <div className="schedules_date_time" key={day}>
          <div className="toggle">
            <Toggle
              text={day.charAt(0).toUpperCase() + day.slice(1)}
              type="checkbox"
              toggleColor="black"
              checked={Array.isArray(selectedDays) && selectedDays.includes(day)}
              onChange={() => handleDayToggle(day)}
            />
          </div>
          <div>
            <div className={`time-dropdown  ${Array.isArray(selectedDays) && selectedDays?.includes(day) ? 'selected' : ''}`}>
              <DropDown
                placeholderText="From"
                option={[...options, { label: 'Select time', isPlaceholder: true }]}
                onChange={(value) => handleTimeChange(day, 'start', value)}
                value={selectedTimes[day]?.start ? selectedTimes[day]?.start : { label: 'Select time', isPlaceholder: true }}
                parentRef={parentRef}
              />
              <DropDown
                placeholderText="To"
                option={[...options, { label: 'Select time', isPlaceholder: true }]}
                onChange={(value) => handleTimeChange(day, 'end', value)}
                value={selectedTimes[day]?.end ? selectedTimes[day]?.end : { label: 'Select time', isPlaceholder: true }}
                parentRef={parentRef}
              />
            </div>
            <AnimatePresence>
              {Array.isArray(selectedDays) && selectedDays?.includes(day) && (
                <motion.div
                  className="DropdownMenu"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={DropdownVariants}
                  transition={{ duration: 0.2, type: 'spring', damping: 15 }}
                >
                  {' '}
                  <div className="order_settings">
                    <div className="order_settings_title text">
                      <img src="/images/Orders 2.svg"></img>
                      Order Settings
                    </div>
                    <div className="radio-btn">
                      <Toggle
                        text="Same day"
                        type="radio"
                        toggleColor="black"
                        checked={selectedRadio[day] === 'Same day'}
                        onChange={() => handleRadioToggle(day, 'Same day')}
                      />
                      <Toggle
                        text="Cut-off time"
                        type="radio"
                        toggleColor="black"
                        checked={selectedRadio[day] === 'Cut-off time'}
                        onChange={() => handleRadioToggle(day, 'Cut-off time')}
                      />
                    </div>
                    {selectedRadio[day] === 'Same day' && (
                      <div className="cut-off-time">
                        <div className="order_title">
                          <div className="titles left">Lead hour</div>
                        </div>
                        <div
                          className={`time-dropdown ${
                            selectedDays?.includes(day) ? 'selected' : ''
                          }`}
                        >
                          <DropDown
                            option={[
                              ...hourOptions,
                              { label: 'Select Hour', isPlaceholder: true },
                            ]}
                            width="185px"
                            onChange={(value) => handleLeadTimeChange(day, value)}
                            value={
                              selectedTimes[day]?.lead
                                ? `${selectedTimes[day]?.lead.toString().padStart(2, '0')}:00`
                                : { label: 'Select Hour', isPlaceholder: true }
                            }
                            parentRef={parentRef}
                          />
                        </div>
                        <div className="message-div">
                          <Message
                            title={'Note:'}
                            message={
                              'You can set delivery lead time if the kitchen need some time for preparation.'
                            }
                          />
                        </div>
                      </div>
                    )}
                    {selectedRadio[day] === 'Cut-off time' && (
                      <div className="cut-off-time">
                        <div className="order_title">
                          <div className="titles left">Day</div>
                          <div className="titles right">Time</div>
                        </div>
                        {console.log("Selected Cutoff Day:", selectedTimes[day]?.cutoffDay)}
                        <div
                          className={`time-dropdown ${
                            selectedDays?.includes(day) ? 'selected' : ''
                          }`}
                        >
                          <DropDown
                            option={[
                              ...daysOptions,
                              { label: 'Select Day', isPlaceholder: true },
                            ]}
                            width="185px"
                            onChange={(value) => handleCutoffDateChange(day, value)}
                            value={dropdownValues[selectedTimes[day]?.cutoffDay]}
                            parentRef={parentRef}
                          />
                          <DropDown
                            option={[...options, { label: 'Select time', isPlaceholder: true }]}
                            value={selectedTimes[day]?.cutoffTime ? selectedTimes[day]?.cutoffTime : { label: 'Select time', isPlaceholder: true }}
                            onChange={(value) => handleTimeChange(day, 'cutoffTime', value)}
                            parentRef={parentRef}
                          />
                        </div>
                        <div className="message-div">
                          <Message
                            title={'Example'}
                            message={
                              'Customers can place their order for the above selected date before the cut-off time'
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      ))}
</EditScheduleStyle>
    );
};
