import styled from 'styled-components';
import {useHistory} from "react-router-dom";
import { useAppContext } from 'context/appContext';
import { ReactComponent as Tip } from 'icons/tip.svg';
import ImageViewer from '../../../components/ImageViewer';

const flexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DailyRecommendedCalsWrapper = styled.div`
  height: 75px;
  padding: 6px 0;
  background-color: #fff;
  border-top: 1px solid #e0e3e0;

  .daily-recommended-container {
    width: 95%;
    margin: 0 auto;
    padding-right: 8px;
    display: flex;
    align-items: center;
  }

  .diet-plan-wrapper {
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;

    .diet-plan-btn {
      position: static;
      width: 100%;
      height: 42px;
    }
  }

  .nutritionist-img-wrapper {
    position: relative;
    margin-right: 10px;

    img:first-child {
      width: 58px;
      height: 58px;
      border: 3px solid #916ae2;
      border-radius: 50%;
    }

    .tip-wrapper {
      position: absolute;
      top: 0;
      right: -6px;
      width: 20px;
      height: 20px;
      background-color: #52c41a;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .description-wrapper {
    width: 100%;

    .description-cals {
      margin-bottom: 5px;
      padding-bottom: 8px;
      font-weight: 700;
      border-bottom: 1px solid #51635d1a;
      ${flexBetween};

      .text {
        color: #51635d;
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }

      .count {
        color: #52c41a;
        font-size: 16px;
        font-family: 'Roboto';
        line-height: 19px;
      }
    }

    .description-contains {
      ${flexBetween};

      .item {
        color: #51635d;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
      }

      .point {
        width: 3px;
        height: 3px;
        margin-right: 5px;
        background-color: #51635d;
        border-radius: 50%;
        display: block;
      }
    }
  }
`;

export default function DailyRecommendedCals() {
  const {
    nutritionist,
    profile: { dietMetrics },
    isGuest,
    isVisitor
  } = useAppContext();
  const history = useHistory();

  const dietMatricsContain =dietMetrics?.macros &&  Object.keys(dietMetrics?.macros).map(function (key) {
    return (
      <div className="item">
        <span className="point" />
        <span>
          {key} {dietMetrics.macros[key]}g
        </span>
      </div>
    );
  });

  return (
    <DailyRecommendedCalsWrapper>
      {(isGuest || isVisitor) ?
        <div className='diet-plan-wrapper'>
          {!isVisitor && <button className="diet-plan-btn" onClick={() => history.push('/shop/my-coach/mealplans')}>Browse diet plans</button>}
        </div>
        :
        <div className="daily-recommended-container">
          <div className="nutritionist-img-wrapper">
            <ImageViewer src={nutritionist?.img || '/images/avatar.png'} fallbackSrc={'/img_coming_soon.png'} alt="Nutritionist Image" />
            <div className="tip-wrapper">
              <Tip />
            </div>
          </div>
          <div className="description-wrapper">
            <div className="description-cals">
              <div className="text">Your daily recommended calories</div>
              <div className="count">{dietMetrics.calories}</div>
            </div>
            <div className="description-contains">{dietMatricsContain}</div>
          </div>
        </div>
      }
    </DailyRecommendedCalsWrapper>
  );
}
