import { useState } from 'react';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import NutritionistProfile from 'components/Nutritionist/NutritionistProfile';
import NutritionistAccount from 'components/Nutritionist/NutritionistAccount';
import NutristionistPayment from 'components/Nutritionist/NutristionistPayment';

const NutritionistSettingsStyle = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 15px 68px 30px;

  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.form-group-section {
      width: 50%;

      &:first-child {
        margin-right: 75px;
      }
    }

    label {
      margin-bottom: 14px;
      color: #000000;
      font-size: 20px;
    }

    input {
      height: 46px;
      padding: 0 16px;
      font-size: 16px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;

      ::placeholder {
        color: #939b99;
      }
    }
  }

  @media (max-width: 767px) {
    width: 90%;
    padding: 24px 0;
    margin: 60px auto;
  }

  @media(min-width:992px){
    min-height: calc(100vh - 32px);
  }
`;

{
  TabPanel;
}

export default function NutritionistSettings() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <NutritionistSettingsStyle>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          {/* <Tab label="Profile" /> */}
          <Tab label="Account" />
          <Tab label="Payment" />
        </Tabs>
        {/* <TabPanel value={value} index={0}>
          <NutritionistProfile />
        </TabPanel> */}
        <TabPanel value={value} index={0}>
          <NutritionistAccount />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NutristionistPayment />
        </TabPanel>
    </NutritionistSettingsStyle>
  );
}
