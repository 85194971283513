import { Container, CssBaseline } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { groupBy, sort } from 'ramda';
import { addTimeInDate } from '../utils';
import { format } from 'date-fns';
import moment from 'moment';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  orderContent: {
    color: 'gray',
    fontSize: '.8rem',
  },
}));

const ListItemLink = (props) => <ListItem button component={props.component} {...props} />;

const OrderList = ({ orders, type }) => {
  const classes = useStyles();

  const displayDateTime = (date, time) => {
    if (time.includes("-")) {
      return `${moment(date).format('ddd Do MMM YYYY')}, ${time}`
    }
    return format(addTimeInDate(moment(date), time), 'ccc do LLL y p')
  }

  return (
    <div className={classes.root}>
      <List key={type} data-testid={type} component="nav">
        {orders.map(({ orderMain, isDelivery, selectedDate, selectedTime }) => {
          const selectedOrderDate = selectedDate.split('T')[0];
          const { dateOrdered, createdAt, id: orderId,friendlyOrderNum } = orderMain;
          const neworderId = friendlyOrderNum!==""? friendlyOrderNum:`NT-${orderId + 1000}`;
          const orderIdView = <span className={classes.orderContent}>Order Id : {neworderId}</span>;
          const orderedDateView = (
            <span className={classes.orderContent}>
              Ordered at : {new Date(createdAt).toLocaleDateString()}
            </span>
          );
          const orderTypeView = (
            <span className={classes.orderContent}>Type: {isDelivery ? 'Delivery' : 'Pickup'}</span>
          );
          const completeDateView = (
            <span className={classes.orderContent}>
              To Be Completed: {displayDateTime(selectedOrderDate,selectedTime)}
            </span>
          );
          //hide order Id from UI
          const txt = (
            <>
            {orderIdView} | {orderedDateView} | {orderTypeView} | {completeDateView}
            </>
          );
          return (
            <ListItemLink
              key={`order/${orderId}`}
              data-testid={`test-id-order/${orderId}`}
              href={`order/${orderId}`}
              component="a"
            >
              <ListItemText primary={txt} />
            </ListItemLink>
          );
        })}
        {/* <ListItem button>
          <ListItemText primary="Trash" />
        </ListItem>
        <ListItemLink href="#Order-list">
          <ListItemText primary="Spam" />
        </ListItemLink> */}
      </List>
    </div>
  );
};

const OrderListPage = (props) => {
  let [orders, setOrders] = useState([]);
  const {user} = useAppContext();
  const [getProviderOrders] = useRequest('fulfill', 'get');

  useEffect(() => {
    let value = sessionStorage.getItem('imitateProvider');
    value = value ? JSON.parse(value) : {};
    try {
    getProviderOrders({ path: `fulfill?id=${user?.type.includes('super') && value.id}`})
      .then(setOrders)
      .catch(console.log);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const groupedOrders = orders.length > 0 ? groupBy((o) => o?.fulfillmentStatus, orders) : {};
  const receivedOrder = groupedOrders['received'] || [];
  const acceptedOrder = groupedOrders['accepted'] || [];
  const refusedOrder = groupedOrders['refused'] || [];
  const completedOrder = groupedOrders['completed'] || [];
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      Received
      <OrderList type="received" orders={receivedOrder} />
      Accepted
      <OrderList type="accepted" orders={acceptedOrder} />
      Refused
      <OrderList type="refused" orders={refusedOrder} />
      Completed
      <OrderList type="completed" orders={completedOrder} />
    </Container>
  );
};
export default OrderListPage;
