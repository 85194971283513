import NutrittLogo from 'layout/NutrittLogo';
import { useEffect } from 'react';

export default function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NutrittLogo style={{ padding: '14px 19px' }} />
      <div style={{ padding: '50px' }}>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Terms and Conditions of Service
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Introduction &amp; Scope.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Welcome, these are the Terms and Conditions of Service (hereinafter, the “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Terms
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”) for our website available at www.nutritt.com, including its sub-domains and its
            mobile optimized versions (collectively called “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”), along with any products and services offered thereby. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            These Terms shall supplement our Privacy Policy (the “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Policy
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”), incorporated herein by reference. &nbsp;Nutritt and any provided products and
            services are proudly delivered by Nutritt Inc. (also called “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”).
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The following terminology applies to our Terms, Policy and other guidelines and
            agreements indicated by us from time to time: “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            client
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”, “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            user
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”, “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            you
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ” and “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            your
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ” refers to you, the person logged on this Nutritt and in compliance with our terms and
            conditions and Privacy Policy. &nbsp;The words “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ourselves
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”, “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            we
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”, “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            our
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ” and “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            us
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”, refers to us and our parent and holding companies. &nbsp;Any use of the above
            terminology or other words in the singular, plural, capitalization and/or he/she or
            they, are taken as interchangeable and therefore as referring to the same.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            All terms hereof refer to the offer, acceptance, and consideration of payment necessary
            to undertake the process of our assistance to the user in the most appropriate manner
            for the express purpose of meeting each of our customer’s needs in respect of the
            provision of Nutritt’s stated services, in accordance with and subject to prevailing
            applicable laws for our sale of goods.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Henceforth, by accessing Nutritt, on behalf of yourself or the entity that you
            represent, you represent and warrant that you have the right, authority, and capacity to
            enter into both these Terms and our Policy (on behalf of yourself or the entity that you
            represent), in accordance with all applicable laws and regulations. &nbsp;If you do not
            agree with any of these Terms and Privacy Policy, you are prohibited from using or
            accessing Nutritt. &nbsp;Please, ensure that you read them thoroughly, because, by using
            Nutritt, you consent to these Terms and Policy.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            These terms apply to all our visitors and registered end users. &nbsp;There will be
            specific sections that will individually apply to users who register to be a buyer and
            also for sellers.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Finally, these terms require the use of arbitration on an individual basis to resolve
            disputes, rather than jury trials or class actions, and also limit the remedies
            available to you in the event of a dispute.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Accordingly, Nutritt agrees to furnish its online services (the “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Services
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”) to: (i) the visitors that browse the Nutritt; and (ii) registered users (both buyers
            and sellers); all subject to the following Terms.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Certain features or future Services may demand that you enter into a distinct and
            supplemental written agreement with us prior to use, in which case we will provide them
            to you for your full review prior to any acceptance.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Account Registration, Verification and Safety.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Some of our Services are offered free of charge to guests, and others are offered to
            users who create an account, including all mandatory fields on the registration form.
            &nbsp;You must provide accurate and complete information, and you hereby agree to keep
            the password chosen upon creating your account as confidential and not to communicate it
            to third parties. &nbsp;Users can sign up / sign in via: Facebook, Google, Apple and
            email.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you lose or disclose your account credentials, you must promptly inform us, since you
            are solely responsible for the activity that occurs on your account and for keeping your
            password secure and confidential. &nbsp;Please notify us immediately of any breach or
            unauthorized use of your account.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Account Suspension &amp; Termination.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt encourages you to report violations of our guidelines and Terms. &nbsp;We
            reserve the right, at our sole and final discretion, to deactivate, freeze, suspend or
            terminate any account upon any factual or alleged breach of these Terms. &nbsp;You must
            notify us immediately of any change in your eligibility to use our platform, or if you
            suspect a breach of security or unauthorized use of your account.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Users undertaking conducts that may constitute a factual –or even alleged– breach of
            these Terms, including but not limiting, using automated mechanisms to make fraudulent
            communications, may become subject to immediate account suspension/termination, at our
            sole and final discretion, without notice and without responsibility.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You acknowledge and agree that we may report any activity that we believe may violate
            any law-to-law enforcement, regulators or other relevant third parties, and that any
            violation of the aforementioned provisions may result in the immediate termination of
            your access to the Nutritt or our Services.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You may terminate your account at any time by going to the “delete account” button
            located inside the “Edit Profile” section which can be accessed through the dropdown at
            the side of your username. &nbsp;We may terminate or suspend your user account if you go
            contrary to any Nutritt’s policies or for any other reason(s), which we will notify you
            about.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Parental Notice.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            backgroundColor: '#ffffff',
            marginTop: '0pt',
            marginBottom: '0pt',
            padding: '0pt 0pt 10pt 0pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            “Nutritt” does not knowingly provide its Services to persons: (i) under the age of
            eighteen (18); (ii) that have had their account previously disabled for violations of
            our Terms or Policy; (iii) that are otherwise prohibited from receiving our products,
            Services, or software under applicable laws.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            backgroundColor: '#ffffff',
            marginTop: '0pt',
            marginBottom: '0pt',
            padding: '0pt 0pt 10pt 0pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you are under the age of eighteen, you may access “Nutritt” and our Services only
            under the direct, express and unequivocal supervision of your parent or legal guardian.
            &nbsp;Any payments for persons under the age of eighteen (18) will need to be authorized
            and cleared by your parent or guardian no exceptions. &nbsp;Parent and guardians must
            review these Terms with their minors so that both understand all of your rights and
            responsibilities.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            backgroundColor: '#ffffff',
            marginTop: '0pt',
            marginBottom: '0pt',
            padding: '0pt 0pt 10pt 0pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Guests are prohibited from providing Nutritt with personally identifiable information of
            persons under the age of thirteen (13) without the consent of their parents or legal
            guardians. &nbsp;Nutritt does not knowingly collect any kind of information from any
            person under the age of thirteen (13). &nbsp;In compliance with the Children's Online
            Privacy Protection Act of 1998, 15 U.S.C. 6501–6505 ("COPPA"), if we learn or have
            reason to suspect that any user or that any client, project or customer data appertains
            persons under the age of thirteen (13), we will freeze and/or delete any PI under that
            user’s account, without prior notice and without responsibility.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We reserve the right to request any and all applicable proof of identification and
            consent proof from our users, at any moment, without prior notice, and at our sole and
            final discretion. &nbsp;Upon the failure to provide such proof of age, we reserve the
            right to immediately freeze, block or cancel the account, with no liability.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Account for buyers.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Using Nutritt as a buyer is totally free, and only purchasable items will be paid.
            &nbsp;All buyers need to create a user profile before signing in, and their profile can
            include a profile image (not mandatory), along with their name and last name, phone
            number (not mandatory) and also location access permission to show food for sale close
            to their location.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Please take into account that buyers will need to pay the full order if they are not
            home when it is delivered or if they do not hear the doorbell or if they do not have
            their phone with them when receiving the notification from Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Other functionalities include, but are not limited to:
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Receive and interact with news feeds, push and social media notifications about
                seller’s profiles, posts, feeds and recipes.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Purchase special one-time services from sellers.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '10pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Purchase foods via takeout or personal delivery.
              </span>
            </p>
          </li>
        </ul>
        <p dir="ltr" style={{ lineHeight: '1.2', marginTop: '0pt', marginBottom: '10pt' }}>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Account for sellers.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Buyers who want to register for a seller account must first apply for their seller
            profile and send it to us for approval. &nbsp;Nutritt may request proof of experience
            from sellers, such as resume, social media profiles, certifications, operating licenses,
            health and sanitary licenses, restaurant information, social media channels and other
            information.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Approval is subject to Nutritt’s sole and final discretion, not to be unreasonably
            withheld. &nbsp;Approved seller accounts allow sellers to use Nutritt as a sales
            platform where sellers will be able to:
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Interact with all buyers
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Create news feeds posts, upload and post photos, text, audio/videos and community
                pages
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Create posts which can be followed, rated and reviewed by buyers
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Reply to reviews
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Sell and exploit their food products
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Use advanced filters (analytics) targeting age, gender, location and other buyer’s
                preferences
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Receive email notifications
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Access and configure their admin account
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                marginTop: '0pt',
                marginBottom: '10pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Access and configure their payment account (e.g. Stripe, PayPal, Apple Pay, Google
                Pay)
              </span>
            </p>
          </li>
        </ul>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt has the right to contact or do further additional research on the seller for
            verification purposes, in order to validate that all info provided by the seller is
            accurate. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Sellers are fully responsible for the operation of their restaurants, kitchens and for
            their delivery means to buyers, not us. &nbsp;We are a payment pass-through, marketing
            and customer retention platform.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Please note that sellers may not upload any content that may entice buyers out of the
            Nutritt website, including contact details, addresses and social media profiles,
            regardless of whether they are express or implied or just appearing onto the background.
            &nbsp;Also, sellers cannot send any solicitations along with the order delivery.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Sellers will be able to access and use the personal data of the buyers, which will be
            treated as further detailed in our Privacy Policy.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Please be careful when selecting the details of each service for sale, because the total
            price indicated to buyers must include taxes, fees and delivery costs (if any), which
            will be paid by the seller.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Specific Food Health and Hygiene Disclaimer.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You bear sole responsibility for your own diet, health, food and culinary personal
            decisions. &nbsp;None of the contact details, telephones, emails, content, text, audio,
            video, information, metrics, data, notifications, measurements or recommendations from
            sellers should be followed, eaten, performed or otherwise undertook without previous
            clearance from a health care provider. &nbsp;Our Services are not a substitute for the
            advice of a medical professional or doctor, and the information made available on or
            through the Services or Nutritt should not be relied upon when making medical decisions,
            or to diagnose or treat a medical or health condition.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt does not own, manage and/or control any food preparation or catering company,
            nor does it engages into the legal, managerial, logistical, safety or regulatory
            activities necessary for any type of restaurant, food, cuisine, food ingredients,
            health, food preservation, food sanitation or medical profession planning, organization
            or execution. &nbsp;Henceforth, Nutritt’s responsibilities are limited to providing a
            platform for the promotion of the sellers and their products and services.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            For people in poor health or with pre-existing physical or allergy conditions, there may
            be risks associated with certain types of food and ingredients. &nbsp;Due to the
            existence of these risks, no one should not take any nutrition or eating decisions,
            food, experiences, tutorials, courses, programs or recommendations, nor any exercise or
            physical routine if such person is in poor health or has a pre-existing allergic history
            or health condition, or if such person is not currently able to assess whether he or she
            will be overall fit to take any type of food from any person or entity.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Neither Nutritt nor its affiliates, agents, representatives, employees or officers are
            responsible for any injuries or health problems which you or any person may experience,
            or even death, as a result of purchasing any food item or product from any seller, or
            following any content, text, audio, video, information, metrics, data, recipes,
            instructions, notifications, measurements or recommendations of any seller.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Payment terms for sellers.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Our platform will facilitate to set up payments made by buyers, and Nutritt may also
            assist sellers in issues relating to payment disputes, fraud and/or chargebacks.
            &nbsp;Sellers hereby agree that all payments made by your buyers through Nutritt will be
            collected and processed via our available payment processors.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Net payments made by buyers will be deposited directly into seller’s payment account,
            less the deduction of the following fees: (i) Nutritt’s 15% fee; and (ii) payment
            processing fees which are applied by payment processors or other financial institutions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We do not manage, account for or make any tax withholdings in connection with applicable
            federal, provincial, state, local or other governmental sales, goods and services or
            other taxes, fees, or charges, whether now in force or enacted in the future. &nbsp;Each
            seller is responsible for the compliance and fulfillment of its tax obligations, no
            exceptions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Refunds for buyers.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Purchases have a cool-off period where they can be canceled by sellers (XX minutes) or
            buyers (XX minutes). &nbsp;After the cancellation period ends, there will be no refunds
            for purchases, even if the buyer refuses or is unable to receive the product.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Sellers may cancel the sale if they run out of stock, if the sale was made on or near
            its kitchen closing time, or for other extraordinary circumstances. &nbsp;Nonetheless,
            sellers should offer buyers alternative product options.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Payment Processing Terms.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We rely on authorized third-party payment processors in order to bill all users you
            through a linked payment account, such as debt, credit cards and third party platforms,
            and henceforth you hereby authorize us to charge the pertinent fees through your
            indicated payment processing account. &nbsp;Sensitive financial details are stored only
            by our payment providers.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Such third party payment processors are also the ones that process, manage, operate and
            deliver the payments of your end customers, not Nutritt. &nbsp;For more information,
            please refer to the terms of use of our third party payment processing platform, which
            may include Stripe, PayPal and those used by Apple, Google and others.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt shall not be held responsible for any and all errors, fees and currency
            conversion fees by our payment processors, and you should review its terms and policies
            from time to time, which will govern the provision of Services to our users.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Agent Appointment
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;You hereby appoint Nutritt as your payment agent when you provide a payment
            credential to us, and therefore you acknowledge and agree that we will be permitted to
            use, collect and process that payment credential in order to process payments, charges
            and fees of Nutritt. &nbsp;We may also use certain payment card updater services, whose
            availability varies by issuer, to ensure we have the most up-to-date information about
            the payment credentials that we store.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            For payments by credit or debit card, we will have the right to obtain a pre-approval
            from the issuer of the card for an amount which may be a low verification amount or as
            high as the full price of the payment. &nbsp;In such case, your card will be charged
            when you initiate a payment on Nutritt, and if you cancel a transaction before it can be
            completed, the security pre-approval may cause those charges to be available to you on
            your account’s limit.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Payments made person to person.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt may activate person to person payments for selected products offered by our
            sellers. &nbsp;Such payments may include cash option and also debit and credit card
            option. &nbsp;Such payments can only be used for the payment of products offered by
            sellers to buyers via Nutritt, and any use for other personal, business, commercial or
            merchant transactions is explicitly forbidden to all users. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Sellers who receive and accept a person to person payment will be liable to us for the
            full payment amount and also for any third-party fees which may arise from subsequent
            charge reversal and invalidation, including but not limiting, debit or credit card
            claims and chargebacks. &nbsp;For more information, please refer to our chargebacks
            section. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Sellers will be able to use their payment processing account linked to their Nutritt
            account for remittance of amounts and consolidation of charges and fees with Nutritt.
            &nbsp;All of these charge credits are net 30.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If we detect any evidence of undercharges, overcharges or payments not related to
            Nutritt, we reserve the right to freeze, deactivate or delete any user account.
            &nbsp;For recurring cases, we may deactivate person to person payments to such users,
            and we may also reverse or place a hold on your present and future transactions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Person to person payments will be done in accordance with all local, state and federal
            laws applying to each case, and cannot be used in relation to any illegal or illicit
            activity.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Lastly, these types of payments are undertaken at the sole and final risk of all users,
            and Nutritt hereby disclaims any and all liability for each transaction person to
            person. &nbsp;We will not cover disputes among users regarding products and services and
            payment done person to person, nor listen or study the arguments of either party, nor
            will we be held responsible for making any pronunciation on the matter.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Disputes &amp; Charge-backs.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            All users must provide us with valid and current billing information. &nbsp;Except as
            expressly set forth herein, all payment charges are final and non-cancelable. &nbsp;If
            your transaction results in an overdraft or other fee from your bank, or if we detect
            any chargeback or if any payment is not received by us or our payment processors for any
            reason, you will promptly pay us any and all amounts due to us upon notice. &nbsp;Any
            failure or inability by us to process any payment hereunder does not relieve you from
            your payment obligations.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            A chargeback is typically caused when a customer disputes a charge that appears on their
            bank or payment processing statement. &nbsp;A charge-back may result in the reversal of
            a transaction, with the amount charged back to you. &nbsp;You can be assessed
            chargebacks for: (i) customer disputes; (ii) unauthorized or improperly authorized
            transactions; (iii) transactions that do not comply with payment processor network rules
            or are allegedly unlawful or suspicious; or (iv) any reversals for any reason by our
            payment processor or the institutions handling the transaction.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            When a chargeback is issued, you are immediately liable to Nutritt for the full amount
            of payment of the chargeback, plus any associated fees, fines, expenses or penalties
            (including those assessed by our payment processor or the financial institutions
            handling the transaction). &nbsp;Accordingly, you hereby represent and warrant that you
            expressly appoint Nutritt as your agent, with full power to recover these amounts by
            debiting your account or setting off any amounts owed to you by us.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If we are unable to recover funds related to a charge-back for which you are liable, you
            will pay us the full amount of the chargeback immediately upon demand; thus you agree to
            pay all costs and expenses, including without limitation, costs assessed by our payment
            processor, legal fees and other legal expenses, incurred by or on behalf of us in
            connection with the collection of any unpaid charge-backs unpaid by you.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Updates &amp; Amendments.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt reserves the right to modify, amend, suspend, terminate, upgrade, update, or
            otherwise modify these Terms, at any time and without notice. &nbsp;Any changes will be
            displayed in the Nutritt, and we may notify you by email. &nbsp;Please refer to the last
            effective date where changes were last undertaken by us. &nbsp;Your use of our Services
            after the effective date of any update– either by an account registration or simple use
            – thereby indicates your acceptance thereof.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            User Privacy.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            By disclosing any data and personally identifying information to us, you agree to our
            Policy, including the collection, process, storage and disclosure of such personally
            identifiable information, including to our affiliates, partners and clients. &nbsp;Our
            Policy indicates how we collect information used to serve advertising online, including
            behavioral advertising and remarketing. &nbsp;Using Nutritt means that you accept our
            Policy, regardless of whether you are a registered user or not.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Content Licenses.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Limited License by Nutritt
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;We grant you a limited, non-exclusive, revocable, royalty free and
            non-transferable license to utilize and access Nutritt and the Services. &nbsp;You are
            prohibited from duplicating, re-engineering, reverse engineering, modifying or otherwise
            using Nutritt, in whole or in part. &nbsp;We do not grant any express or implied right
            to you under any patents, trademarks, copyrights or trade secret information; and you
            shall have no right, either directly or indirectly, to own, use, loan, sell, rent,
            lease, license, sublicense, assign, copy, translate, modify, adapt, improve or create
            any new or derivative works from, or display, distribute, perform or in any way exploit
            any downloaded Services and computer applications, in whole or in part.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Please be reminded that we have the discretion to terminate our license to you at any
            time for any reason. &nbsp;We have the right, but not the duty, to refuse to share any
            content on Nutritt or to remove or delete it. &nbsp;Apart from the rights and license
            granted in these Terms, we reserve all other rights and grant no license, implied or
            otherwise.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Limited License by sellers
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;As our seller, you hereby grant us a limited, non-exclusive, revocable, royalty
            free and non-transferable license to utilize and access the content that you upload to
            the Nutritt platform website and our Services. &nbsp;We do not receive any express or
            implied right to your content, trademarks, copyrights or trade secret information; and
            we will have no right, either directly or indirectly, to own, use, loan, sell, rent,
            lease, license, sublicense, assign, copy, translate, modify, adapt, improve or create
            any new or derivative works from, or display, distribute, perform or in any way exploit
            any such content, in whole or in part.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Feedback License
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;All users hereby grant Nutritt an unlimited, non-exclusive, sub-licensable,
            assignable, royalty-free, perpetual, irrevocable, for all the countries and territories
            through the world, right and license to use, host, store, reproduce, modify, create
            derivative works (such as those resulting from translations, adaptations or other
            changes), communicate, publish, publicly perform, publicly display and distribute such
            any suggestions, feedback, recommendations, comments and know how that you provide to
            Nutritt regarding the Nutritt and Services.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Intellectual Proprietary Rights.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The trademarks, copyright, service marks, trade names and other intellectual property
            rights and proprietary notices displayed on Nutritt and the Services are the property of
            or otherwise are licensed to Nutritt and its licensors and affiliates, whether
            acknowledged (or not), and which are protected under intellectual property laws,
            including copyright laws and treaties and other jurisdictions throughout the world.
            &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Respective title holders may or may not be affiliated with us or our affiliates,
            partners and advertisers. &nbsp;No section hereof shall be construed as intent to grant
            to you any right transfer or interest in the Nutritt or our Services, in whole or in
            part.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            For ease of understanding, ‘intellectual property rights’ shall mean any and all patent
            rights, copyright rights, mask work rights, moral rights, rights of publicity,
            trademark, trade dress and service mark rights, goodwill, trade secret rights and other
            intellectual property rights as may now exist or hereafter come into existence, and all
            applications therefore and registrations, renewals and extensions thereof, under the
            laws of the United States and other applicable jurisdictions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You acknowledge and agree that any and all infringing use or exploitation of copyrighted
            content in Nutritt and/or the Services may cause us, our affiliates, licensors or
            content providers irreparable injury, which may not be remedied solely at law, and
            therefore our affiliates, licensors or content providers may seek remedy for breach of
            these Terms, either in equity or through injunctive or other equitable relief.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you think content on Nutritt infringes these Terms or otherwise infringes any
            applicable law (apart from copyright infringements) or other Nutritt policies, please
            contact us.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We are under no obligation to remove or delete content that you may find objectionable
            or offensive. We promise to respond promptly to requests for content removal, consistent
            with our policies and applicable law.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Third-party Websites and Content.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            From time to time, Nutritt and the Services may contain hyperlinks to other websites.
            &nbsp;These links are for the personal convenience of our users and to provide them with
            further information which may be of interest to them. &nbsp;The provision of such links
            does not imply any endorsement of such third-party websites (or their products and
            services). &nbsp;Please review the applicable terms and policies of such websites,
            including their privacy and data collection practices.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We may place ads and promotions from third-party sources on Nutritt. &nbsp;Accordingly,
            your participation or undertakings in promotions of third-parties other than Nutritt,
            and any terms, conditions, warranties or representations associated with such
            undertakings, are solely between you and such third-party. &nbsp;We are not responsible
            or liable for any loss or damage of any sort incurred as the result of any such dealings
            or as the result of the presence of third-party advertisers on the Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Digital Millennium Copyright Act (‘DMCA’) Notice.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            In compliance with the DMCA, we inform you that Nutritt Inc. is the Designated Copyright
            Agent for DMCA Takedown Notices and intellectual property rights infringement policing.
            &nbsp;For more information, including detailed information about how to submit a request
            for takedown if you believe content on Nutritt infringes your intellectual property
            rights, please contact us. &nbsp;You can review the current DMCA text at the U.S.
            Copyright Office website.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt takes copyright infringement matters seriously, and is ready to remove any
            allegedly or factually infringing content displayed on the Nutritt upon due notice and
            request by the title holder.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The following procedure will apply for any content displayed through the Nutritt that
            allegedly infringes the intellectual property rights of you or of any third party.
            &nbsp;You must notify us of your claim with the email subject: "Takedown Request".
            &nbsp;Once received, Nutritt will study and consider your claim and, if it believes or
            has reason to believe any content on the Nutritt infringes on another’s copyright,
            Nutritt may delete it, disable or otherwise stop displaying it.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Your notification claim must be in writing and must at least contain the following
            information: (i) your signature and identification, or the ones of the person authorized
            to act on behalf of you or the title holder; (ii) a clear and concise description of the
            content of which its copyright has allegedly been infringed; (iii) contact information
            (e.g., address and email); and (iv) a statement, under penalty of perjury, indicating
            that you have a good faith belief that the information provided in your claim is true
            and accurate.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Our affiliates, advertisers, partners and third party syndicated content providers may
            be adversely affected due to a DMCA Takedown Notice. &nbsp;Accordingly, upon receipt of
            any such notice, we will try to contact the content owner or webmaster of the affected
            site to uphold their right to a Counter Claim Notice, under Sections 512(g)(2), and (3)
            of the DMCA.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            To file a Counter Claim Notice, please contact our Designated Agent indicated above, in
            writing, and with a Counter Claim Notice containing the following information, at a
            minimum: (i) express identification of the content that we may have or have removed from
            our Nutritt and section or domain it was displayed; (ii) contact information, such as
            your address and your email address; (iii) a signature and identification of the title
            holder and/or the person authorized to act; and (iv) a statement indicating that you
            swear, under penalty of perjury, that you have a good faith belief that the information
            provided in the Counter Claim Notice is true and accurate.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Community Guidelines and Guest Code of Conduct.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            As our user, you agree not to undertake, motivate, or facilitate the use or access of
            the Nutritt or the Services in order to:
          </span>
        </p>
        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Infringe these Terms or allow, encourage, or facilitate others to do so.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Plagiarize and/or infringe on the intellectual property rights or privacy rights of
                any third party, including any breach of confidence, copyright, trademark, patent,
                trade secret, moral right, privacy right, right of publicity, or any other
                intellectual property or proprietary right.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Collect, receive, transfer or disseminate any personally identifiable information of
                any person without due consent from the title holder.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Republish, sell, rent, or sub-license content or materials from the Nutritt without
                our authorization.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Disseminate any content that attacks or disparages an individual or group based on
                race, gender, religion, nationality, ethnicity, political group, sexual orientation
                or another similar characteristic. Any generalizations about these topics should be
                phrased as neutrally as possible.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Undertake any kind of abusive behavior directed towards private individuals is not
                allowed, including make threats against others or advocate violence, including
                self-harm. &nbsp;Repeated and unwanted contact constitutes a form of harassment.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Distribute, post, or otherwise make available any content that: (i) infringes on or
                endangers the intellectual property rights of any person (e.g. trademark, moral
                rights, trade secret, copyright, ancillary rights or other); (ii) enables any act
                that could promote or cause discrimination, racism, harm, libel, hatred, or violence
                against any individual or group; (iii) endangers children and underage persons; (iv)
                is or allows illegal or fraudulent activities to take place; (v) is or may
                constitute a criminal or capital offense or otherwise infringes on any applicable
                law; (vi) is or can be considered to be obscene, sexually explicit material,
                pornographic, threatening/defamatory, plagiarized, firearms, ammunition, explosives,
                tobacco, alcohol, marijuana, pornographic or analogous material; and/or (vii) is or
                can be considered to be gambling, games of skill, lotteries, raffles, fantasy
                sports, binary options, Forex and crypto currencies.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Use any automated tool, such as artificial intelligence or machine learning pursuant
                to create derivative works of our content and materials.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Create any service in rivalry with Nutritt or for other commercial reasons, except
                as expressly permitted by these Terms or the written consent of Nutritt.&nbsp;
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '0pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Bypass or attempt to bypass any security or access control technology implemented on
                our platform.
              </span>
            </p>
          </li>
          <li
            aria-level={1}
            dir="ltr"
            style={{
              listStyleType: 'disc',
              fontSize: '11pt',
              fontFamily: 'Calibri,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre',
            }}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: '1.2',
                textAlign: 'justify',
                marginTop: '0pt',
                marginBottom: '10pt',
              }}
            >
              <span
                style={{
                  fontSize: '11pt',
                  fontFamily: 'Lato,sans-serif',
                  color: '#000000',
                  backgroundColor: 'transparent',
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontVariant: 'normal',
                  textDecoration: 'none',
                  verticalAlign: 'baseline',
                  whiteSpace: 'pre-wrap',
                }}
              >
                Otherwise, reverse engineer, decompile or extract the proprietary code of the
                Nutritt and our Services.
              </span>
            </p>
          </li>
        </ul>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Content Moderation.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Our Services may incorporate interactive discussion forums, public forum groups,
            bulletin boards, review services or other types of public forums in which you or other
            users may post user generated content (e.g., reviews, messages, videos, selfies, posts
            and other content).
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Since we may use third party platforms open to the public, there may be no full time
            content moderation by Nutritt regarding public or private rooms and social media groups.
            &nbsp;Nutritt expects that its users will behave with courtesy and common sense, but
            notwithstanding, if something offensive, uncomfortable or otherwise objectionable
            happens, any member can leave the chat, video call or class immediately and report the
            problem to Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You acknowledge and agree that we may offer help to our community of users, remove
            objectionable content, remove or even restrict access to certain Nutritt’s features to
            one or more users, disable or permanently ban a user account, or contact law enforcement
            agencies in order to report any activity that we believe may violate any law. &nbsp;In
            summary, any violation of the aforementioned provisions may result in the immediate
            termination of your access to Nutritt and our Services.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We reserve the right (but not the obligation) to monitor disputes between our users, but
            not with third parties nor related to Nutritt or our Services.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            No Spam Policy.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We reserve the right to screen any user generated content to locate and delete any spam
            or deceiving coupon, code, offer or link to any product or service. &nbsp;In compliance
            with the Controlling the Assault of Non-Solicited Pornography and Marketing (CAN-SPAM)
            Act of 2003, we will not tolerate, and we will not allow others to undertake though our
            Services or Nutritt, any and all massive delivery of unsolicited bulk communications to
            our users or to any third party.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Any commercial electronic communication that you receive from us, our partners,
            licensors, suppliers and affiliates will require your prior consent to such
            communication. &nbsp;Our commercial communications will include measures in order for
            you to stop receiving them, usually through an unsubscribe link. &nbsp;Please see our
            Policy for more information.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            User Support.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You can always contact our team with questions about our Services, and we will strive to
            answer them and work together in order to resolve any such inquiries. &nbsp;You must
            provide us with full details of your service query, so we can clearly assess your
            concerns.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you have any questions or complaints regarding Nutritt or our Services, please
            contact us by email as indicated on our contact web page. &nbsp;We will undertake
            commercially reasonable efforts in order to answer as quickly as possible.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Advertising, Opt-out.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We may support the Nutritt and our Services through advertising and other commercial
            opportunities. &nbsp;These advertisements may be tailored to the content of the Nutritt,
            and the nature and extent of such advertising by us is subject to change without prior
            notice. &nbsp;Please refer to our Policy for information regarding certain advertisement
            opt-out.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Newsletters.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The Nutritt may allow you to create and/or subscribe to a newsletter service, which may
            be provided by us or through an authorized third party. &nbsp;As a user, you will
            receive a conspicuous communication indicating subscription therefore and you will be
            able to select the amount and type of emails received by you. &nbsp;If you wish to
            unsubscribe, you will find ‘unsubscribe’ and similar links on our electronic
            communications and also in your account preferences.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Content Liability.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We shall not be held responsible for any content that appears on any of the vendor
            stores on Nutritt. &nbsp;You agree to protect and defend us against all claims that are
            arising from content on our Nutritt. &nbsp;No link(s) should appear on any website that
            may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise
            violates, or advocates the infringement or other violation of any third-party rights.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Removal of links from Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you find any link on Nutritt that is offensive for any reason, you are free to
            contact and inform us at any time. We will consider requests to remove links, but we are
            not obligated to do so or to respond to you directly.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You acknowledge and agree that we shall not be required to actively monitor nor exercise
            any editorial control whatsoever over any piece, item, message or material or
            information created, obtained or accessible through the Services or Nutritt. &nbsp;Each
            visitor or user is solely responsible for the contents of his or her communications and
            may be held legally liable or accountable for the content of his or her comments or
            other material or information.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            No Endorsement.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The images, texts, posts, information, photographs and other content and media displayed
            on or through Nutritt are not necessarily available and any results therefrom – which we
            cannot ultimately control – are out of our ultimate and complete control. &nbsp;Some or
            all of the images shown in Nutritt are licensed and/or purchased stock photos and are
            only shown for illustration purposes. &nbsp;Some or all of the content displayed in
            Nutritt is delivered by external third parties and does not reflect Nutritt’s opinions,
            nor does Nutritt, its affiliates, subsidiaries, officers, employees and agents guarantee
            its actual veracity or make any endorsement thereof.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Disclaimer of Warranty.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            To the fullest extent permissible under applicable law, Nutritt and the Services are
            provided to you “as is,” with “all faults” and “as available,” without warranty of any
            kind. &nbsp;Nutritt, and its affiliates, clients, agents, officers, licensors and/or
            distributors do not make, and hereby disclaim, any and all express, implied or statutory
            warranties, either by statute, common law, custom or otherwise, however arising,
            including implied warranties of description, quality, fitness for a particular purpose,
            non-infringement, non-interference with use and/or enjoyment, peaceful enjoyment, and
            any warranties emanating out of course of dealing or trade usage.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nothing in this disclaimer will limit or exclude our or your liability for death or
            personal injury resulting from negligence; limit or exclude our or your liability for
            fraud or fraudulent misrepresentation; limit any of our or your liabilities in any way
            that is not permitted under applicable law; or exclude any of our or your liabilities
            that may not be excluded under applicable law.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt expressly disclaims any representation or warranty that Nutritt complies with
            all applicable laws and regulations outside the United States. &nbsp;If you make use of
            Nutritt outside of the United States, you expressly fully comprehend and consent that it
            is your responsibility to determine compliance with variant laws, regulations, or
            customs that may apply in connection with your use of Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            No Damages.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            In no event shall Nutritt, its affiliates, clients, agents, officers, licensors,
            distributors and/or any authorized third party, be held liable for any special,
            indirect, incidental or consequential damages, including losses, costs or expenses of
            any kind resulting from possession, access, use, inaccessibility or malfunction of
            Nutritt or the Services including, but not limited to, loss of revenue, profits,
            business, loss of use or lack of availability of computer resources, business glitch,
            defamation, or loss of data (notwithstanding that we might have been advised of the
            possibility of such damages or such damages are foreseeable); whatsoever arising out of
            or related thereto, whether arising in tort (including negligence), contract, strict
            liability or other legal or equitable theory and whether or not Nutritt, its affiliates,
            clients, licensors and/or distributors have been advised of the possibility of such
            damages; emanating from or connected, in any way, with your use of, or inability to use
            Nutritt. &nbsp;Your singular redress for dissatisfaction with Nutritt is your prompt
            withdrawal from using Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Limitation of Liability.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If a user suffers loss or damage as a result of Nutritt’s negligence or failure to
            comply with these Terms, any claim by such user against us will be limited in respect of
            any one incident, or series of connected incidents, to the amount paid to Nutritt in
            connection with its Services within the three (3) months before the action giving rise
            to the liability.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            These Terms provide you with specific legal rights, and you may have other rights that
            may vary from jurisdiction to jurisdiction. &nbsp;Legislation of some states/countries
            does not allow certain limitations of liability, and henceforth this limitation of
            liability shall apply to the fullest extent permitted by law in the applicable
            jurisdiction.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Indemnification.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You agree and acknowledge to indemnify, hold harmless, and defend Nutritt, its
            affiliates, clients, agents, officers, licensors, distributors and/or any authorized
            representatives, and the officers, directors and employees of each (jointly, the “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Nutritt’s Indemnitees
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”) from and against any and all third party liabilities, claims, causes of action,
            suits, losses, damages, fines, judgments, settlements and expenses (including any and
            all reasonable outside attorneys’ fees and court costs) which may be suffered, made or
            incurred by any of such Nutritt’s Indemnitees arising out of or relating to: (i) any
            breach of any warranties, representations and/or covenants made by you hereunder (to the
            extent not arising substantially from any breach hereof by Nutritt); (ii) your use of
            Nutritt; (ii) your seller content; (iii) your conduct or interactions with other users
            of Nutritt; (iv) your violation of any part of these Terms; and/or (v) any third party
            claim arising out of or in relation to Nutritt or our Services or use thereof in
            combination with another platform, including without limitation, any claim Nutritt or
            the Services violate, infringe, or misappropriate any proprietary or intellectual
            property right of any third party, including without limitation, any privacy right of
            any person.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            We will immediately notify you of any such claim and will provide you (at your own
            expense) with assistance in defending the claim. &nbsp;Unless our prior written consent
            is first obtained, you will not settle any claim the defense of which we need to
            participate in. &nbsp;We reserve the right, at our own expense, to assume the exclusive
            defense of any matter otherwise subject to indemnification by you. &nbsp;In that
            circumstance, you will be under no obligation to defend us in that matter.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Generals
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Assignment
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;These Terms will inure to the benefit of any successors of the parties. &nbsp;We
            may assign any rights or obligations hereunder to any current or future affiliated
            company and to any successor in interest. &nbsp;Any rights not expressly granted herein
            are thereby reserved. &nbsp;We reserve the right, at any time, to transfer some or all
            of Nutritt’s assets in connection with a merger, acquisition, reorganization or sale of
            assets or in the event of bankruptcy.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Force Majeure
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;Nutritt is not liable for any failure of performance on its obligations as set
            forth herein, where such failure arises from any cause beyond Nutritt’s reasonable
            control, including but not limiting to, electronic, power, mechanic or Internet failure,
            from acts of nature, forces or causes beyond our control, including without limitation,
            Internet failures, computer, telecommunications or any other equipment failures,
            electrical power failures, strikes, labor disputes, riots, insurrections, civil
            disturbances, shortages of labor or materials, fires, flood, storms, explosions, acts of
            God, war, governmental actions, government mandated lockdowns, pandemics, orders of
            domestic or foreign courts or tribunals, or non-performance of third parties.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Interpretation
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;In understanding or interpreting the terms of these Terms: (i) the headings in
            this Agreement are for convenience only, and are not to be considered, and (ii) no
            presumption is to operate in either party’s favor as a result of its counsel’s role in
            drafting these Terms.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Entire Agreement
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;These Terms constitute the complete and exclusive statement of the agreement
            between the Parties with respect to the subject matter of these Terms, and these Terms
            supersede any and all prior oral or written communications, proposals, representations,
            and agreements. The Terms may be amended only by mutual agreement expressed in writing
            and signed by both parties, and any attempted amendment in violation of this section
            shall be void.
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Equitable remedies
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            : &nbsp;You hereby acknowledge and agree that if these Terms are not specifically
            enforced, Nutritt will be irreparably damaged, and therefore you agree that Nutritt
            shall be entitled, without bond, other security or proof of damages, to appropriate
            equitable remedies with respect to your breach of any of these Terms, in addition to any
            other available remedies.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Language
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;These Terms may be translated into other languages, but English shall be and
            remain the official language of this agreement and in any conflict between the English
            language version and any other version, the English language version shall control.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Notices
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;All notices and other communications given or made pursuant to these Terms must
            be in writing and will be deemed to have been given upon the earlier of actual receipt
            or: (a) personal delivery to the party to be notified; (b) when sent, if sent by
            facsimile or electronic mail during normal business hours of the recipient, and if not
            sent during normal business hours, then on the recipient’s next business day; (c) five
            days after having been sent by registered or certified mail, return receipt requested,
            postage prepaid; or (d) one business day after deposit with a nationally recognized
            overnight courier, freight prepaid, specifying next business day delivery, with written
            verification of receipt. &nbsp;Each party agrees to receive electronic documents and to
            accept electronic signatures, which shall thereto be considered valid substitutes for
            hardcopy documents and hand inked signatures.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            You agree to receive correspondence from us by email in accordance with these Terms and
            applicable law. You consent and acknowledge that all agreements, notices, disclosures,
            and other communications that we provide to you electronically will satisfy any legal
            requirement that such communications be in writing.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            No Waiver
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;Failure by Nutritt to enforce any rights hereunder shall not be construed as a
            waiver of any rights with respect to the subject matter hereof.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            No Relationship
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;You and Nutritt are independent contractors, and no agency, partnership, joint
            venture, employee-employer, or franchiser-franchisee relationship is intended or created
            by these Terms.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Severability
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;If any provision of these Terms is held unenforceable, then such provision will
            be modified to reflect the parties' intention. &nbsp;All remaining provisions of these
            Terms will remain in full force and effect.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Taxes
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;You are responsible for complying with all tax obligations associated with your
            account. &nbsp;It is important that you keep complete and accurate contact details in
            order for us to remit invoices, especially payment processing account details.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Dispute Resolution.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Applicability of Arbitration Agreement
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;All claims and disputes (excluding claims for injunctive or other equitable
            relief as set forth below) in connection with the Terms or the use of any product or
            service provided by Nutritt that cannot be resolved informally shall be resolved by
            binding arbitration on an individual basis under the terms of these Terms. &nbsp;Unless
            otherwise agreed to, all arbitration proceedings shall be held in English. &nbsp;This
            arbitration applies to you and Nutritt, and to any subsidiaries, affiliates, agents,
            employees, predecessors in interest, successors, and assigns, as well as all authorized
            or unauthorized users or beneficiaries of services or goods provided under these Terms.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Notice Requirement and Informal Dispute Resolution
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;Before either party may seek arbitration, the party must first send to the other
            party a written Notice of Dispute (a “
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Notice
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            ”) describing the nature and basis of the claim or dispute, and the requested relief.
            &nbsp;A Notice to Nutritt should be sent to our address as indicated in our contact
            section. &nbsp;After the Notice is received, you and Nutritt may attempt to resolve the
            claim or dispute informally. &nbsp;If you and Nutritt do not resolve the claim or
            dispute within thirty (30) days after the Notice is received, either party may begin an
            arbitration proceeding. The amount of any settlement offer made by any party may not be
            disclosed to the arbitrator until after the arbitrator has determined the amount of the
            award, if any, to which either party is entitled. &nbsp;The amount of the award shall
            also be limited by the Limitation of Liability section of these Terms, to the extent
            applicable.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Arbitration Rules
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;Arbitration shall be initiated through the American Arbitration Association
            (AAA) Rules, an established alternative dispute resolution provider (the “ADR Provider”)
            that offers arbitration as set forth in this section. &nbsp;The AAA Consumer Arbitration
            Rules (the “ADR Provider Rules”) governing the arbitration are available online at
            www.adr.org or by calling the AAA at 1-800-778-7879. &nbsp;If the AAA is not available
            to arbitrate, the parties shall agree to select an alternative ADR Provider. &nbsp;The
            ADR Provider Rules shall govern all aspects of the arbitration, including but not
            limited to the method of initiating and/or demanding arbitration, except to the extent
            such rules are in conflict with these Terms. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The arbitration shall be conducted by a single, neutral arbitrator. &nbsp;Any claims or
            disputes may be resolved through binding non-appearance-based oral arbitration using
            secure communication means that are able to be recorded and stores online by all parties
            seeking relief. &nbsp;The arbitrator shall give the parties reasonable notice of the
            date, time and place of any oral hearings. &nbsp;Any judgment on the award rendered by
            the arbitrator may be entered in any court of competent jurisdiction. &nbsp;Each party
            shall bear its own costs (including attorney’s fees) and disbursements arising out of
            the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Time Limits
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;If you or Nutritt elect to pursue arbitration, the arbitration action must be
            initiated and/or demanded within the statute of limitations (i.e., the legal deadline
            for filing a claim) and within any deadline imposed under the ADR Provider Rules for the
            pertinent claim.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Authority of Arbitrator
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;If arbitration is initiated, the arbitrator will decide the rights and
            liabilities, if any, of you and Nutritt, and the dispute will not be consolidated with
            any other matters or joined with any other cases or parties. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The arbitrator shall have the authority to award monetary damages, and to grant any
            non-monetary remedy or relief available to an individual under applicable law, the ADR
            Provider Rules, and these Terms. &nbsp;The award of the arbitrator is final and binding
            upon you and Nutritt.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            The arbitrator shall issue a written award and statement of decision describing the
            essential findings and conclusions on which the award is based, including the
            calculation of any damages awarded. &nbsp;The arbitrator has the same authority to award
            relief on an individual basis that a judge in a court of law would have. &nbsp;The
            arbitrator shall have the authority to grant motions dispositive of all or part of any
            claim. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Each party shall bear its own costs (including attorney's fees) and disbursements
            arising out of the arbitration and shall pay an equal share of the fees and costs of the
            ADR Provider.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Emergency Equitable Relief
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . Notwithstanding the foregoing, either party may seek emergency equitable relief before
            a state or federal court in order to maintain the status quo pending arbitration.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Waiver of Class Actions, Non-Individualized Relief
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . You acknowledge and accept that claims brought against Nutritt shall be only on an
            individual basis and not as a plaintiff or class member in any possible future class or
            representative action or similar proceeding. &nbsp;Unless otherwise agreed by you and
            Nutritt, you may not adjoin or consolidate any claim with more than one person's; and
            you may not otherwise supervise or take over any form of a class, representative or
            consolidated proceeding.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Applicable Law
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;You agree to submit to the applicable laws in the State of Delaware, United
            States of America, which will govern these Terms and any claim, without regard to
            conflict of law provisions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Forum
          </span>
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            . &nbsp;For the application of this arbitration agreement, and of other matters
            appertaining these Terms, the parties hereto agree to submit to the personal
            jurisdiction of the courts located in the State of Delaware, United States of America.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you bring a dispute in a manner other than in accordance with this section, you agree
            that we may move to have it dismissed, and that you will be responsible for our
            reasonable attorney’s fees, court costs, and disbursements in doing so.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Contact
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 300,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            If you have any questions or queries about us, the Nutritt, our Services or these Terms,
            please contact us via info@nutritt.com. &nbsp;Note that communications made via email or
            the “Contact Us” page does not constitute legal notice to the Nutritt legal entity.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: '1.2',
            textAlign: 'justify',
            marginTop: '0pt',
            marginBottom: '10pt',
          }}
        >
          <span
            style={{
              fontSize: '11pt',
              fontFamily: 'Lato,sans-serif',
              color: '#000000',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            Date of last effective update is 02/01/2022.
          </span>
        </p>
      </div>
    </>
  );
}
