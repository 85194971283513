import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CreditCardInput from 'react-credit-card-input';
import { theme } from 'shared/theme/theme';

const CardInformationStyle = styled.div`
  .credit_card_wrapper {
    label {
      width: 100%;
      margin: 0px;
      display: block;
      &::after {
        display: none;
      }
    }
    .credit-card-input {
      height: 44px;
      padding: 0px 14px;
      border: 1px solid rgb(224, 227, 224);
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #00092d;
      font-size: 14px !important;
      position: relative;
      font-family: 'Gilroy';
      &#card-number {
        padding-left: 50px;
      }
      &:focus {
        border-color: #00092d !important;
      }
      &::placeholder {
        color: #949d9b;
      }
      &.isInvalid {
        border: 1px solid #fec8b4;
        background: #fef1f0;
      }
    }
    #field-wrapper {
      flex-wrap: wrap;
      column-gap: 8px;
      row-gap: 12px;
      position: relative;
      padding: 0 16px;
      > img {
        position: absolute;
        top: 14px;
        left: 30px;
        z-index: 3;
        display: block;
      }
      label {
        &:nth-of-type(2) {
          width: 103px;
        }
        &:nth-of-type(3) {
          width: 91px;
        }
        &:nth-of-type(4) {
          width: calc(100% - 210px);
        }
      }
    }
    > p:nth-of-type(1) {
      display: none;
    }
  }
  .card__error {
    color: ${({ theme }) => theme?.colors?.alizarinCrimson};
    font-size: ${({ theme }) => theme?.typography?.fontSmall};
    font-weight: 500;
    line-height: normal;
    margin-top: 5px;
    height: 14.5px;
  }
  .is-invalid{
    border: 0px !important;
  }
  @media (min-width: 991px){
    #field-wrapper{
      label {
        &:nth-of-type(2) {
          width: 112px !important;
        }
        &:nth-of-type(3) {
          width: 100px !important;
        }
        &:nth-of-type(4) {
          width: calc(100% - 228px) !important;
        }
      }
    }
  }
`;

const CardInformation = ({ paymentInfo, handleUpdatePaymentInfo, cardInputError, setCardInputError }) => {
  const zipInputRef = useRef(null);
  const zipRegrex = /^[a-zA-Z0-9]{4,5}$/
  const handleCardZipBlur = () => {
    const value = zipInputRef.current.value;
    if (!zipRegrex.test(value)) {
      setCardInputError({...cardInputError, cardZIP: 'Zipcode is invalid'});
    }
  };

  const isCardExpired = (exp) => {
    const [monthStr, yearStr] = exp.split(" / ");
    const month = parseInt(monthStr, 10);
    const year = parseInt(yearStr, 10);
    const fullYear = year + (year < 100 ? 2000 : 0);
    const lastDayOfMonth = new Date(fullYear, month, 0).getDate();
    const expiryDate = new Date(fullYear, month - 1, lastDayOfMonth);
    const currentDate = new Date();

    return expiryDate < currentDate;
};

  useEffect(() => {
    if(paymentInfo && paymentInfo?.expiryDate && paymentInfo?.PaymentMethodId){
      if(isCardExpired(paymentInfo?.expiryDate)){
        setCardInputError({...cardInputError, cardExpiry: 'Card Expired'})
      }else{
        setCardInputError({...cardInputError, cardExpiry: ''})
      }
    }
  }, [paymentInfo])
  
  return (
    <>
      <CardInformationStyle theme={theme}>
        <CreditCardInput
          onError={({ inputName, error }) => {
            return setTimeout(() => {
              setCardInputError((prev) => ({
                ...prev,
                [inputName]: error,
              }));
            }, 10);
          }}
          containerClassName="credit_card_wrapper"
          cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
            <>
              <input
                {...props}
                value={paymentInfo.cardNumber}
                onChange={handleCardNumberChange((event) => {
                  setCardInputError((prev) => ({
                    ...prev,
                    cardNumber: undefined, 
                  }));
                  handleUpdatePaymentInfo('cardNumber', event.target.value);
                })}
                className={`credit-card-input ${cardInputError?.cardNumber ? 'isInvalid' : ''}`}
                placeholder="XXXX . XXXX . XXXX . XXXX"
              />
                {cardInputError?.cardNumber && <p className="card__error">Invalid card number</p>}
            </>
          )}
          cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
            <>
              <input
                {...props}
                value={paymentInfo.expiryDate}
                onChange={handleCardExpiryChange((event) => {
                  setCardInputError((prev) => ({
                    ...prev,
                    cardExpiry: undefined, 
                  }));
                  handleUpdatePaymentInfo('expiryDate', event.target.value);
                })}
                className={`credit-card-input ${cardInputError?.cardExpiry ? 'isInvalid' : ''}`}
                placeholder="MM / YY"
              />
              <p className="card__error">
                {cardInputError?.cardExpiry && paymentInfo?.expiryDate
                  ? 'Card expired'
                  : cardInputError?.cardExpiry && !paymentInfo?.expiryDate
                  ? 'Card expiry required'
                  : ''}
              </p>
            </>
          )}
          cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
            <>
              <input
                {...props}
                value={paymentInfo.cvv}
                onChange={handleCardCVCChange((event) => {
                  setCardInputError((prev) => ({
                    ...prev,
                    cardCVC: undefined, 
                  }));
                  handleUpdatePaymentInfo('cvv', event.target.value);
                  if(event.target.value.length === 3){
                    zipInputRef.current.focus();
                  }
                })}
                className={`credit-card-input ${cardInputError?.cardCVC ? 'isInvalid' : ''}`}
                placeholder="CVC"
              />

              <p className="card__error">{cardInputError?.cardCVC ? 'Invalid CVC' : ''}</p>
            </>
          )}
          cardZipInputRenderer={({ handleCardZipChange, props }) => (
            <>
              <input
                {...props}
                ref={zipInputRef}
                value={paymentInfo.zipcode}
                onBlur={handleCardZipBlur}
                onChange={handleCardZipChange((event) => {
                  setCardInputError((prev) => ({
                    ...prev,
                    cardZIP: undefined, 
                  }));
                  handleUpdatePaymentInfo('zipcode', event.target.value);
                })}
                className={`credit-card-input ${cardInputError?.cardZIP ? 'isInvalid' : ''}`}
                placeholder="ZIP"
                type='tel'
              />
              <p className="card__error">{cardInputError?.cardZIP ? 'Invalid Zip' : ''}</p>
            </>
          )}
        />
      </CardInformationStyle>
    </>
  );
};

export default CardInformation;
