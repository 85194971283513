import { memo, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import OrderDetails from 'components/elements/orderDetail/orderDetails/OrderDetails';
import { ParentContainer } from 'pages/provider/storefront/orders/style';
import OrderTable from '../OrderTable';
import OrderDetailsPageContainer from '../OrderDetailsPageContainer';
import { useHistory, useLocation } from 'react-router-dom';
import SplitPanel from '../SplitPanel';
import { Skeleton } from '@mui/material';
import FiltersModal from '../FiltersModal';
import { useKitchenContext } from 'context/kitchenContext';
import { useAppContext } from 'context/appContext';

function Accepted({ totalNumber = '3', tab = 'Accepted', orderData, totalOrdersCount, setOrderDataPageNumber, orderDataPageNumber, handleInputChange, orderNumber, sort, sortBy, setSortBy, setSort, isCheckAll, setIsCheckAll, updateSelectedAll, updateSelected ,fetchOrder, activeTab, orderFound, setIsOrderStatusChanged,isOrdersApiCallProcessing, selectedOrder, setSelectedOrder, setIsPrint, setCheckedOrders, imitateProviderId, setFilterQuery, filterQuery, selectedTabId}) {
  const { orders = [], totalPages = 1 } = orderData;
  const {pathname} = useLocation();
  const { isSuper } = useAppContext();
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
  const [isSplit, setIsSplit] = useState(false);
  const [selectedOrderPanel, setSelectedOrderPanel] = useState({});
  const [isFilters, setIsFilters] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!isSplit) {
      setSelectedOrderPanel({});
    }
  }, [isSplit])

  return (
    <ParentContainer>
      <OrderTable 
        totalNumber={totalOrdersCount} 
        tab={tab} 
        totalOrdersCount={totalOrdersCount} 
        orderDataPageNumber={orderDataPageNumber} 
        setOrderDataPageNumber={setOrderDataPageNumber} 
        onChange={handleInputChange} 
        totalPages={totalPages} 
        orderNumber={orderNumber} 
        setSortBy={setSortBy} 
        setSort={setSort} 
        isCheckAll={isCheckAll} 
        setIsCheckAll={setIsCheckAll} 
        updateSelectedAll={updateSelectedAll} 
        orders={orders} 
        fetchOrder={fetchOrder} 
        activeTab={activeTab} 
        orderFound={orderFound}
        isOrdersApiCallProcessing={isOrdersApiCallProcessing}
        setIsSplit={setIsSplit}
        isSplit={isSplit}
        imitateProviderId={imitateProviderId}
        setIsFilters={setIsFilters}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
      >
        <div className='table_holder'>
          {orders?.length > 0 ? orders?.map((order) => {
            const {friendlyOrderNum, orderedAt, providers, orderLine, user,orderMainId, dateTimeOrdered, selected,isTestOrder, orderStatus} = order;
            const { kitchenProvider } = useKitchenContext();
            const findProviderIndex = providers?.findIndex(
              (provider) => provider?.Provider?.id === kitchenProvider?.id,
            );
            const {selectedDate, selectedTime, taxAmount, providerSubTotal, providerPayout, isDelivery, deliveryFee, deliveryData, street, city, state, zip, phoneNumber, Provider: {id: providerId} = {}, isOrderSeen, id: providerOrderId,orderNote, providerFee} = isSuper ? providers[0] : providers[findProviderIndex];
            const {name, lastName} = user;
            const customerName = name + (lastName?.length ? ' ' + lastName : '');
            return <OrderDetails 
            orderNumber={friendlyOrderNum} 
            orderNote={orderNote}
            selectedDate={selectedDate}
            selectedTime={selectedTime} 
            orderLine={orderLine} 
            taxAmount={taxAmount} 
            subTotal={providerSubTotal} 
            total={providerPayout} 
            customerName={customerName} 
            phoneNumber={phoneNumber} 
            method={isDelivery?'Delivery':'Pickup'}
            deliveryFee={deliveryFee}
            deliveryData={deliveryData}
            orderType={'accepted'}
            orderId={orderMainId}
            isDelivery={isDelivery}
            street={street}
            city={city}
            state={state}
            zip={zip}
            orderStatus={orderStatus}
            dateTimeOrdered={dateTimeOrdered}
            orderedAt={orderedAt}
            providerId={providerId}
            updateSelected={updateSelected}
            selected={selected}
            tab={tab}
            isOrderSeen={isOrderSeen}
            providerOrderId={providerOrderId}
            setIsOrderStatusChanged={setIsOrderStatusChanged}
            providerFee={providerFee}
            isTestOrder={isTestOrder}
            order={order}
            setSelectedOrder={setSelectedOrder}
            alertData={alertData}
            setAlertData={setAlertData}
            isOrdersApiCallProcessing={isOrdersApiCallProcessing}
            setCheckedOrders={setCheckedOrders}
            isSplit={isSplit}
            selectedOrderPanel={selectedOrderPanel}
            setSelectedOrderPanel={setSelectedOrderPanel}
            imitateProviderId={imitateProviderId}
              />
          }) : !isOrdersApiCallProcessing && <div className='order-found-wrapper'>No Order Found</div>}
          {isOrdersApiCallProcessing && (
            <div className="skeleton-container">
              {Array.from({ length: 10 }, (_, index) => (
                <div className="skeleton-wrapper">
                  {Array.from({ length: 7 }, (_, index) => (
                    <div className="skeleton-item">
                      <Skeleton variant="rectangle" width="80%" height={20} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </OrderTable>
      <AnimatePresence>
      {(Object.keys(selectedOrder)?.length > 0 && (pathname === '/kitchen/orders' || pathname === '/kitchen/orders/orderDetails')) &&
          <motion.div
            className="order-details-motion"
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 0}}
            transition={{ duration: (history.location?.state?.fromPrint || pathname === '/kitchen/orders/orderDetails') ? 0 : 0.3 }}
          >
            <OrderDetailsPageContainer
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              setIsOrderStatusChanged={setIsOrderStatusChanged}
              setAlertData={setAlertData}
              orderDataPageNumber={orderDataPageNumber}
              tab={tab}
              imitateProviderId={imitateProviderId}
              sort={sort}
              sortBy={sortBy}
              orderNumber={orderNumber}
              filterQuery={filterQuery}
              setIsSplit={setIsSplit}
              selectedTabId={selectedTabId}
              setFilterQuery={setFilterQuery}
            />
          </motion.div>
        }
      </AnimatePresence>
      {isSplit && (
        <SplitPanel
          selectedOrderPanel={selectedOrderPanel}
          setSelectedOrderPanel={setSelectedOrderPanel}
          setAlertData={setAlertData}
          setIsOrderStatusChanged={setIsOrderStatusChanged}
          setSelectedOrder={setSelectedOrder}
          setIsSplit={setIsSplit}
          orderDataPageNumber={orderDataPageNumber}
          selectedTabId={selectedTabId}
          imitateProviderId={imitateProviderId}
          orderNumber={orderNumber}
          filterQuery={filterQuery}
          sort={sort}
          sortBy={sortBy}
        />
      )}
      <FiltersModal
        isFilters={isFilters}
        setIsFilters={setIsFilters}
        setFilterQuery={setFilterQuery}
        filterQuery={filterQuery}
        setOrderDataPageNumber={setOrderDataPageNumber}
        setIsSplit={setIsSplit}
      />
    </ParentContainer>
  )
}

export default memo(Accepted);