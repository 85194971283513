import styled from 'styled-components';
import CreditCardInput from 'react-credit-card-input';
import { useAppContext } from 'context/appContext';
import {  useState } from 'react';

const PaymentInfoWrapper = styled.div`
  .title {
    margin-bottom: 7px;
    color: #51635d;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 14px;

    &.payment-title {
      margin-bottom: 18px;
    }
  }
  .is-invalid{
    border: 0px !important;
  }
  .isInvalid{
    border: 1px solid #ff3860 !important;
  }
  .select-payment {
    height: 44px;
    margin-bottom: 12px;
    padding: 0 14px 0 38px;
    border: 1px solid #e0e3e0;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.apple-pay {
      margin-bottom: 25px;
    }
  }

  .radio-wrapper {
    top: 0;
    margin-bottom: 0;
    padding-left: 0;

    .radio-label {
      color: #51635d;
      font-size: 12px;
      line-height: 14px;
    }

    .radio-check {
      top: auto;
      left: 14px;
      width: 16px;
      height: 16px;
      border-width: 1px;

      &:after {
        width: 8px;
        height: 8px;
      }
    }
  }

  .img-wrapper {
    display: flex;

    img {
      width: 24px;
      height: 16px;
      margin-left: 5px;
      object-fit: cover;
    }
  }

  .payment-details {
    margin-bottom: 0;

    & > div {
      width: 100%;
    }
  }

  #field-wrapper {
    padding: 0;
    flex-wrap: wrap;

    label {
      width: 100%;
      margin: 0;
      display: block;

      :after {
        display: none;
      }

      &:nth-of-type(2) {
        width: 60%;
      }

      &:nth-of-type(3) {
        width: 40%;
      }
    }

    img {
      position: absolute;
      top: 14px;
      right: 16px;
      z-index: 3;
    }

    .credit-card-input {
      height: 44px;
      padding: 0 14px;
      position: static;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
    }

    #card-number {
      margin-bottom: 12px;
    }

    #cvc {
      width: -webkit-fill-available;
      margin-left: 12px;
    }
  }

  .billing-address {
    margin-top: 18px;

    textarea {
      width: 100%;
      height: 68px;
      padding: 6px 14px;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      resize: none;
    }
  }

  @media(min-width: 992px){
    .title {
      &.payment-title{
        padding: 12px 24px;
        border-bottom: 1px solid black;
        border-bottom: 1.5px solid #ededed;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 26px;
      }
    }

    .paymentInfo{
      padding: 0px 45px 20px;
      
      .title{
          margin-bottom: 7px;
          color: #51635d;
          font-size: 12px;
          font-family: 'Roboto';
          line-height: 14px;
          width: 100%;
          padding: 8px 0px 7px;
      }
      .paymentType-wrapper{
        display: grid;
        justify-content: center;
        gap: 12px;
        width: 100%;
        grid-template-columns: 49% 49%;
      }
    }
    .payment-details{
      padding: 0;
      flex-wrap: wrap;
    }

    #field-wrapper {
      padding: 0;
      flex-wrap: wrap;

      #label img:first-child {
        margin-right: -7px;
      }

      .paymentCard-wrapper{
        display: flex;
        flex-direction: row;
      }

      label {
        width: 42%;
        margin: 0;
        display: block;
        margin-right: 12px;

        :after {
          display: none;
        }

        &:nth-of-type(2) {
          width: 60%;
        }

        &:nth-of-type(3) {
          width: 40%;
        }
      }

      label:nth-of-type(1){
        width: 49%;
      }

      label:nth-of-type(2){
        width: 27.7%;
        margin-top: -11px;
      }

      label:nth-of-type(3){
        margin-left: 0;
        margin-right: 0;
        width: 18%;
        margin-top: -11px;
      }
        
      }
      img {
        top: 15px !important;
        right: 7px !important;
        z-index: 5 !important;
        font-size: 1px;
        width: 13px !important;
      }

      .credit-card-input {
        height: 44px;
        padding: 0 32px 0 14px;
        position: static;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        font-size: 14px !important;
      }

      #card-number.credit-card-input {
        &::placeholder {
          font-size: 14px;
          text-transform: uppercase
        }
      }

      #cvc {
        width: -webkit-fill-available;
        margin-left: 0px !important;
      }
    }

    .img-wrapper {
  
      img {
        width: 24px !important;
        height: 16px;
        margin-left: 3px !important;
        object-fit: cover;
      }
    }

    .billing-address{
      margin-top: 26px;

      textarea{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 16px;
        color: #949D9B;
      }
    }

    .select-payment {
      height: 42px;
    }
  }
`;

export default function PaymentInfo({paymentInfo, handlePaymentInfo, offerData,creditCardError}) {
   const {isMobile} = useAppContext();
   const [cardInputError,setCardInputError] = useState(false)
  return (
    <PaymentInfoWrapper>
      <div className="title payment-title">{!isMobile && offerData?.nutritionist?.name}</div>
      <div className='paymentInfo'>
      <h3 className="title">Payment information</h3>
      <div className="paymentType-wrapper">
      <label className="radio-wrapper">
        <div className="select-payment">
          <span className="radio-label">Credit Card</span>
          <input
              type="radio"
              name="payment"
              value="credit"
              defaultChecked
              checked={paymentInfo.method === 'credit' && true}
              onChange={(event) => handlePaymentInfo('method', event.target.value)}
          />
          <span className="radio-check" />
          {isMobile ?
           <div className="img-wrapper">
             <img src="/images/visa.svg" alt="Visa" />
             <img src="/images/amex.svg" alt="Amex" />
             <img src="/images/jcb.svg" alt="jcp" />
             <img src="/images/discover.svg" alt="Discover" />
            </div> :
           <div className="img-wrapper">
             <img src="/images/visa.svg" alt="Visa" />
             <img src="/images/amex.svg" alt="Amex" />
             <img src="/images/discover.svg" alt="Discover" />
             <img src="/icons/Mastercard.svg" alt="Mastercard" />

           </div>  
          }
        </div>
      </label>
      <label className="radio-wrapper">
        {window.ApplePaySession &&(
           <div className="select-payment apple-pay">
           <span className="radio-label">Apple Pay</span>
           <input
               type="radio"
               name="payment"
               value="apple pay"
               checked={paymentInfo.method === 'apple pay'}
               onChange={(event) => handlePaymentInfo('method', event.target.value)}
           />
           <span className="radio-check"></span>
           <div className="img-wrapper">
             <img src="/images/apple-pay.svg" alt="A pple Pay" />
           </div>
         </div>
        )
        }
      </label>
      </div>
      <div className="payment-details">
        <div className="paymentDetail-wrapper">
        <CreditCardInput
          onError={({ inputName, error }) => setTimeout(() => {setCardInputError(inputName)},10) }

          cardNumberInputRenderer={({handleCardNumberChange, props }) => (
           <>
            <input
                {...props}
                value={paymentInfo.cardNumber}
                onChange={handleCardNumberChange((event) =>
                   { 
                    setCardInputError(false);
                    handlePaymentInfo('cardNumber', event.target.value)
                   }
                )}
                className={`credit-card-input ${cardInputError == "cardNumber" ? 'isInvalid' : ''}`}
                placeholder="XXXX . XXXX . XXXX . XXXX"
               
            />
            <img src="/icons/card-icon.svg" alt="Card Icon" />
           </> 
          )}
          cardExpiryInputRenderer={({handleCardExpiryChange, props }) => (
            <>
              {isMobile ?
              <>
              <input
              {...props}
              value={paymentInfo.expiryDate}
              onChange={handleCardExpiryChange((event) =>
                {
                  setCardInputError(false)
                  handlePaymentInfo('expiryDate', event.target.value)
                }
              )}
              className={`credit-card-input ${cardInputError == "cardExpiry" ? 'isInvalid' : ''}`}
              placeholder="mm / yyyyy"
              />
              <img src="/icons/checkoutCalendar.svg" alt="Calendar Icon" />
              </>
              : 
              <>
              <input
                  {...props}
                  onChange={handleCardExpiryChange((event) =>
                     {
                      handlePaymentInfo('expiryDate', event.target.value)
                     }
                  )}
                  placeholder="MM / YYYY"
              /> 
              <img src="/icons/checkoutCalendar.svg" alt="Calendar Icon" />
              </>
               }
            </> 
          )}
          cardCVCInputRenderer={({handleCardCVCChange, props }) => (
            <>
              <input
                  {...props}
                  value={paymentInfo.cvv}
                  onChange={handleCardCVCChange((event) =>
                      {
                        setCardInputError(false);
                        handlePaymentInfo('cvv', event.target.value)
                      }
                  )}
                  className={`credit-card-input ${cardInputError == "cardCVC" ? 'isInvalid' : ''}`}
                  placeholder="CVC"
              />
              <img src="/icons/lock.svg" alt="Lock Icon" />
            </>
          )}
        />
        </div>
        <div className="billing-address">
          {isMobile?
            <div className="title">Billing Address</div>:
            <div className="title">Billing address</div>  
          }
          <textarea 
          value={paymentInfo.billingAddress}
          onChange={(event) => handlePaymentInfo('billingAddress', event.target.value)}
          placeholder="28 46 th dribbe apt 20a, Long Island city, NY, 11103" />
        </div>
      </div>
      </div>
    </PaymentInfoWrapper>
  );
}
