import styled from "styled-components";
import { Circle } from "rc-progress";

const FollowersDataStyle = styled.div`
display: flex;
flex-direction: row;
align-items: center;
border-bottom: 1px solid #d9d9d9;
margin:0 15px;
.client-card {
  display: flex;
  width: 85%;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  @media screen and (min-width:1281px) and (max-width: 1440px){
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 767px){
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .client-info {
    width: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    row-gap: 5px;
    @media(max-width:991px){
      margin-left:12px !important;
    }
    .date-time {
      p {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 1px;
        line-height: 16px;
        color: #51635d;
      }
    }
    .client-name {
      p {
        overflow: hidden;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color:#51635d;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .circle{
    position:relative;
    display: flex;
    span{
        position: absolute;
        left: 6px;
        top: 6px;
    }
  } 
}
`;
export  default function FollowersData({ user }) {
    const profileImg = user?.img || '/images/avatar.png';
  
    return (
      <FollowersDataStyle>
        <div className="client-card">
          <div className='circle'>
            <Circle
              percent={user?.percentCalories}
              strokeWidth="6"
              strokeColor="#52C41A"
              trailWidth="6"
              trailColor="#F6F5F8"
              width='52px'
              height='52px'
            />
            <span>
              <img
                style={{ height: 40, width: 40, borderRadius: '50%' }}
                src={profileImg}
                alt="ellipse-271"
              />
            </span>
          </div>
  
          <div className="client-info">
            <div className="client-name">
              <p>{user?.name}</p>
            </div>
          </div>
        </div>
        <div>
          <img src="/icons/favorite.svg" />
        </div>
      </FollowersDataStyle>
    );
  }
  