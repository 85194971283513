import moment from 'moment';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const ExcludeDatesWrapper = styled.div`
  margin-bottom: 75px;

  .exclude-date {
    margin: 0 0 25px 25px;
    display: flex;
    align-items: center;

    .title {
      margin-right: 13px;
      color: #51635d;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
    }
  }

  .react-datepicker-wrapper {
    width: auto;

    .add-date-btn {
      width: 96px;
      height: 32px;
      color: #51635d;
      border: 1px solid #afb5b4;
      border-radius: 21px;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1px;
      cursor: pointer;

      &:hover,
      &.react-datepicker-ignore-onclickoutside {
        background: #51635d;
        color: #fff;
      }
    }
  }

  .exclude-date-items {
    .item {
      margin-top: 10px;
      padding: 20px 24px;
      border: 1px solid #f6f5f8;
      border-radius: 10px;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        margin-top: 0;
      }

      img {
        cursor: pointer;
      }
    }

    .date {
      color: #51635d;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
  }

  .react-datepicker {
    border: 0;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    transform : translate3d(-60px,3.188px,15px);

    .react-datepicker__header {
      padding: 10px;
      background-color: #fff;
      border-bottom-color: #f0f0f0;
    }

    div.react-datepicker__day--selected,
    .react-datepicker__day:hover {
      background-color: #f0faeb;
      color: #52c41a;
      border-radius: 50%;
      font-weight: bold;
    }

    .react-datepicker__day {
      margin: 5px;
      color: #afb5b4;
    }

    .react-datepicker__triangle::before,
    .react-datepicker__triangle::after {
      border-bottom-color: #fff;
      transform : translate3d(50px,0px,0px);
    }
  }

  @media (max-width: 991px) {
    margin-bottom: 22px;

    .exclude-date { 
      margin-left: 0;
    }
  }
`;

export default function ExcludeDates({ onChange, excludeDates, removeDate }) {
  const ExampleCustomInput = <div className="add-date-btn">Add date</div>;

  return (
    <ExcludeDatesWrapper>
      <div className="exclude-date">
        <span className="title">Exclude specific date</span>
        <DatePicker
          selected={new Date()}
          onChange={(date) => onChange(date)}
          customInput={ExampleCustomInput}
        />
      </div>
      <div className="exclude-date-items">
        {excludeDates?.map((date, index) => (
          <div key={index} className="item">
            <div className="date">
              {moment(date).format('LLLL').split(' ').splice(0, 4).join(' ')}
            </div>
            <img src="/icons/gray-trash.svg" alt="Trash Icon" onClick={() => removeDate(index)} />
          </div>
        ))}
      </div>
    </ExcludeDatesWrapper>
  );
}
