import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import {ReactComponent as HomeGreenIcon} from '../../../src/assets/Home-green.svg'
import {ReactComponent as HomeIcon} from '../../../src/assets/Home-icon.svg'
import {ReactComponent as UserGreen} from '../../../src/assets/user2-green.svg'
import {ReactComponent as UserImg} from '../../../src/assets/profile-2user.svg'
import {ReactComponent as CupGreen} from '../../../src/assets/cup-green.svg'
import {ReactComponent as Cupp} from '../../../src/assets/cupp.svg'
import {ReactComponent as DeviceMessage} from '../../../src/assets/device-message.svg'
import {ReactComponent as DeviceMessages} from '../../../src/assets/device-message-green.svg'

const NutritionistNavigationBarStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 74px;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  //border-radius: 10px 10px 0px 0px;
  position: fixed;
  z-index: 110;
  bottom: 0px;
  background-color: #fff;
  font-family: 'Roboto';
  font-style: normal;
  .nutritionist-footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 74px;
    .nutritionist-footer-item {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 4px;
      &.active {
        p {
          color: #52c41a;
        }
      }
      img {
        width: 20px;
        height: 20px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        text-align: center;
        color: #51635D;
        margin-top: 2px;
      }
      svg{
        width:20px;
        height:20px;
      }
    }
    .icon-wrapper{
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        text-align: center;
        color: #51635D;
        p{
          margin-top: 2px;
        }
    }
  }

  @media (min-width: 992px) {
    position: static;
    z-index: 1;
    width: 35%;
    height: 69px;
    box-shadow: none;

    .nutritionist-footer-inner .nutritionist-footer-item {
      height: 69px;
      padding: 0 24px;
      border-bottom: 2px solid transparent;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      p {
        font-size: 14px;
        line-height: 16px;
      }

      &.active {
        border-color: #52c41a;

        svg path {
          stroke: #52c41a;
        }
      }
    }
  }
`;

const NutritionistNavigationBar = ({ toggleDrawer,setModalshow }) => {
  const { pathname } = useLocation();
  const { user } = useAppContext();
  const userType = user.type.toString();
  const handleclick = ()=>{
    setModalshow(false);
    window.scrollTo(0, 0);
  }

  return (
    <>
    { pathname.includes('/nutritionist/Challanges/create') || pathname.includes('/nutritionist/Challanges/create/sharechallenge/:id')  || pathname.includes('/nutritionist/Challanges/edit') || pathname.includes('/nutritionist/Challanges/share') ? '' : 
    <NutritionistNavigationBarStyle>
      <div className="nutritionist-footer-inner">
        {pathname?.includes("/home") ? (
          <div onClick={handleclick}>
          <Link to="/nutritionist/home" className="nutritionist-footer-item active">
          <HomeGreenIcon/>
            <p>Home</p>
          </Link>
          </div>
        ) : (
          <div onClick={handleclick}>
          <Link to="/nutritionist/home" className="nutritionist-footer-item">
          <HomeIcon/>
            <p>Home</p>
          </Link>
          </div>
        )}

        {pathname?.includes("/followers") ? (
           <div onClick={handleclick}>
          <Link to="/nutritionist/followers" className="nutritionist-footer-item active">
          <UserGreen/>
            <p>Followers</p>
          </Link>
          </div>
        ) : (
          <div onClick={handleclick}> 
          <Link to="/nutritionist/followers" className="nutritionist-footer-item">
          <UserImg/>
            <p>Followers</p>
          </Link>
          </div>
        )}
        {pathname.includes('/nutritionist/Challanges') ? (
           <div onClick={handleclick}>
          <Link to="/nutritionist/Challanges" className="nutritionist-footer-item active">
          <CupGreen/>
            <p>Challenges</p>
          </Link>
          </div>
        ) : (
          <div onClick={handleclick}>
          <Link to="/nutritionist/Challanges" className="nutritionist-footer-item">
          <Cupp/>
            <p>Challenges</p>
          </Link>
          </div>
        )}
        {pathname.includes('/clients') ? (
           <div onClick={handleclick}>
          <Link to="/nutritionist/clients" className="nutritionist-footer-item active">
          <DeviceMessages/>
            <p>Sessions</p>
          </Link>
           </div>
        ) : (
          <div onClick={handleclick}>
          <Link to="/nutritionist/clients" className="nutritionist-footer-item">
          <DeviceMessage/>
            <p>Sessions</p>
          </Link>
          </div>
        )}
        <div className="icon-wrapper" onClick={toggleDrawer(true)}>
          <img src="/icons/menu-dark.svg" alt="Menu Icon" />
          <p>Menu</p>
        </div>
      </div>
    </NutritionistNavigationBarStyle>
}
    </>
  );
};

export default NutritionistNavigationBar;
