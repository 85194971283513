import { createContext, useContext, useEffect, useState,useMemo,useCallback } from 'react';
import Progress from '../components/Progress';
import { useRequest } from '../hooks/useRequest';
import { ErrorAndLogout } from '../components/ErrorAndLogout';
// import AuthRoutes from "./AuthRoutes" ;
import { getUserName, sendDataToReactNativeApp } from 'utils';
import pendo from '../pendo';
import { usePersistedState } from 'hooks/usePersistedState';
import { toast } from 'react-toastify';
import { Skeleton, Stack } from '@mui/material';
import Styled from 'styled-components';
import moment from 'moment';
import { Client } from '@twilio/conversations';
import { useRef } from 'react';
import axios from 'axios';
import getAddress from 'utils/getAddress';

const AppContext = createContext({});

const AppContextProvider = (props) => {
  const { children, values = {} } = props;
  const [user, setUser] = useState(null);
  const [provider, setProvider] = useState(null);
  const [profile, setProfile] = useState(null);
  const [nutritionist, setNutritionist] = useState(null);
  const [nutritionistProfile, setNutritionistProfile] = useState(null);
  const [offerForm, setOfferForm] = useState(null);
  const [isAppLoading, setIsAppLoading] = useState(true);
  const [connectionError, setConnectionError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [getUser] = useRequest();
  const [getOfferList] = useRequest();
  const [offerList, setOfferList] = useState([]);
  const [loginInfo, setLoginInfo] = usePersistedState('loginInfo', {});
  const [nutritionistId, setNutritionistId] = usePersistedState('publicNutritionistId', null);
  const [selectedGuestOfferDetails, updateSelectedGuestOfferDetails] = usePersistedState(
    'selectedGuestOfferDetails',
    {},
  );
  const [guestId, setGuestId] = usePersistedState('guestId', null);
  const orderCount = localStorage.getItem('orderCount');
  const [nutritionistInfo, setNutritionistInfo] = useState(null);
  const [guestInfo, setGuestInfo] = usePersistedState('guestinfo');
  const [saveToken_v2, { isLoading: isSaving }] = useRequest('visitor/saveToken_v2', 'post');
  const [isEmailExist, { isLoading: isEmailVerifying }] = useRequest('auth/isEmailPhoneNumberExist', 'get');
  const [profileObj, setProfileObj] = useState();
  const [allNutritionist, setAllNutritionist] = useState([]);
  const [selectedNutritionist, setSelectedNutritionist] = usePersistedState(
    'selectedNutritionist',
    null,
  );
  const [getNutritionistAll, { isLoading, error: err }] = useRequest();
  const [getPantryMeals, { isLoading: isGetMealLoading }] = useRequest('pantry/getPantryMeals');
  const [pantryMeals, setPantryMeals] = useState([]);
  const [pantryMealsSource, setPantryMealsSource] = useState([]);
  const [awaitingMeals, setawaitingMeals] = useState([]);
  const [awaitingMealsSource, setAwaitingMealsSource] = useState([]);
  const [pantryMealsCount, setPantryMealsCount] = useState(0);
  const TimeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [getTwilioAccessToken] = useRequest('chat/token', 'post');
  const [twilioClient, setTwilioClient] = useState(null);
  const [getAllTwilioConversations, setGetAllTwilioConversations] = useState([]);
  const [unreadMsgCount, setUnreadMsgCount] = useState([])
  const [twilioConnectionState, setTwilioConnectionState] = useState('');
  const [chatData, setChatData] = useState([]);
  const [chatsLoading, setChatsLoading] = useState(true);
  const [twilioError, setTwilioError] = useState('');
  const [getAllUsersOfConversation, setGetAllUsersOfConversation] = useState([]);
  const [coachFcmToken, setCoachFcmToken] = usePersistedState('coachFcmToken', "");
  const [isTwilioConnectionError, setIsTwilioConnectionError] = useState(false);
  const [appInBackGround, setAppInBackGround] = useState(false);
  const [fullDiaryMeals, setFullDiaryMeals] = useState([]);
  const [isUserSuspended, setIsUserSuspended] = useState(false);
  const [selectedNutritionistId, setSelectedNutritionistId] = useState(null);
  const [twilioAccessToken, setTwilioAccessToken] = usePersistedState("twilioAccessToken","");
  const twilioDeniedError = useRef(false);
  const [platform,setPlatForm]=useState()
  const [modalShow , setModalshow]=useState(false);
  const [getDeliveryAddress, {isLoading: isDeliveryLoading}] = useRequest();
  const [userDeliveryAddress, setUserDeliveryAddress] = useState([]);
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [getdiaryMeals, { isLoading: isGetdiaryMealLoading }] = useRequest();
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [selectedValue, setSelectedValue] = usePersistedState('SelectedDeliveryAddress','');
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [login,setLogin] = useState(false)
  const [customQtyMeal,setCustomQtyMeal] = usePersistedState("customQtyMeal",false);
  const isWebView=window?.ReactNativeWebView;
  const [getAllergies, { isLoading:isTagLoading }] = useRequest('meal/allergies');
  const [dietaryTags, setDietaryTags] = useState(null);
  const baseUrl = process.env.REACT_APP_SERVER_URL;
  const [desktopOnboardingUser, setDesktopOnboardingUser] = useState(null);
  const [userEmailExist, setUserEmailExist] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [isSideHeader, setIsSideHeader] = useState(false);
  const [isCart, setIsCart] = useState(false);
  const [allowNotification , setAllowNotification] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState();
  const [headerAddress, setHeaderAddress] = useState({});
  const [currentDeliveryAddress, setCurrentDeliveryAddress] = useState({});
  const [changeAddress, setChangeAddress] = useState(false);
  const [checkAutoLogin, setCheckAutoLogin] = useState(false);
  const [isCheckedToggle, setIsCheckedToggle] = useState();
  const [otpSend, setOtpSend] = useState(false);
  const [routeBeforeSignup, setRouteBeforeSignup] = useState(null);
  const [isKitchenDeliveryAvailable, setIsKitchenDeliveryAvailable] = useState(false);
  const [favoriteKitchenMeals, setFavoriteKitchenMeals] = useState([]);
  const [countStoreVisits] = useRequest();
  const [showArea, setShowArea] = useState(false);
  const [saveDeliveryAddress]=useRequest("user-delivery-address/","post");
  const [ hasSubscribedUsers, { isLoading: isFetchingSubscribedUser } ] = useRequest();


  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod') || userAgent.includes('mac'));
    setIsAndroid(userAgent.includes('android'));
  }, []);

  const combinedPantryMeals = (data) => {
    return data.reduce((acc, val) => {
      const isMealExist = acc.find(item => item.id === val.id)
      if (isMealExist) {
        acc[acc.indexOf(isMealExist)].qty = parseInt(acc[acc.indexOf(isMealExist)].qty) + parseInt(val.qty)
      } else {
        acc.push(val);
      }
      return acc
    },[])
  }

  const uploadProfilePic = async (file, key, assetType) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('oldKey', key);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user'))?.token,
      },
    };
    return axios
      .post(`${baseUrl}assets/upload/${assetType}`, bodyFormData, config)
      .then((res) => res.data);
  };

  const loadPantryMeals = async () => {
    await getPantryMeals({
      path: 'pantry/getPantryMeals',
    })
      .then((data) => {
        let acceptedOrder = data?.pantryData?.acceptedOrder;
        let awaitingOrders = data?.pantryData?.awaitingOrders;
        acceptedOrder = acceptedOrder.filter((item) => {
          if (!item.string_agg?.includes('completed')) {
            awaitingOrders.push(item);
          }
          return item.string_agg?.includes('completed');
        });
        const formattedData = acceptedOrder.map((item) => {
          return {
            orderLineId: item.id,
            qty: item.quantity,
            ...item,
            ...item.data,
          };
        });
        const formattedDataClone = [...formattedData];
        const totalFormattedData = combinedPantryMeals(formattedDataClone);
        setPantryMealsSource(totalFormattedData);
        setPantryMealsCount(totalFormattedData?.reduce((acc, val) => acc + parseInt(val.qty), 0));
        const fmtAwaitingOrders = awaitingOrders.map((item) => {
          return {
            orderLineId: item.id,
            qty: item.quantity,
            ...item,
            ...item.data,
          };
        });
        const fmtAwaitingOrdersClone = [...fmtAwaitingOrders];
        const totalFmtAwaitingOrders = combinedPantryMeals(fmtAwaitingOrdersClone);
        setAwaitingMealsSource(totalFmtAwaitingOrders);
      })
      .catch(console.log);
  };

  const getdiaryData = async () => {
    const route = `diary/getDiaryMeals?timeZone=${TimeZone}&groupByCategory=true`;
   await getdiaryMeals({
      path: route,
    })
      .then((data) => {
        setFullDiaryMeals(data?.diaryData);
      })
      .catch(console.log);
  };

  const getAllNutritionistListData = () => {
    const route = `visitor/getAllApprovedNutritionist`;
    getNutritionistAll({
      path: route,
    })
      .then((data) => {
        setAllNutritionist(data);
      })
      .catch(console.log);
  };

  const getUserDeliveryAddress = async (id) => {
    try {
      const route = `user-delivery-address/?id=${id}`;
      await getDeliveryAddress({
        path: route,
        method: 'get',
      }).then((data) => {
          setUserDeliveryAddress(data?.data);
          setIsAddressLoading(false);
        })
        .catch((e) => console.log(e))

    } catch (e) {
      console.log("api failed", e);
    }
  };
 
  const dropdownValues = useMemo(() => {
    const values = [{ value: 'new', label: 'Enter a new address', address: null }];
    if (userDeliveryAddress?.length > 0) {
      userDeliveryAddress?.forEach((item) => {
        values.push({
          value: item?.address?.address?.label,
          label: item.type === 'other' ? item.name : item.type,
          address: item,
        });
      });
      values.push(values.shift());
    }
    return values;
  }, [userDeliveryAddress]);

  const visitorDeliveryAddress = useMemo(() => {
    const deliveryAddress = localStorage.getItem("visitorDeliveryAddress");
    if (deliveryAddress) {
      const parsedAddress = JSON.parse(deliveryAddress)
      return parsedAddress;
    };
  },[localStorage.getItem("visitorDeliveryAddress")])


  useEffect(() => {
    setOptions(dropdownValues);
  }, [dropdownValues,userDeliveryAddress]);


  const handleUserDeliveryAddressChange = useCallback(() => {
    if (userDeliveryAddress?.length) {
      setValue(userDeliveryAddress[0].address.address);
    }
  }, [userDeliveryAddress]);

  useEffect(() => {
    if (userDeliveryAddress?.length) {
      const foundAddress = userDeliveryAddress.find(address => Number(address?.id) === Number(selectedValue));

      if (!selectedValue || !foundAddress) {
        setSelectedValue(userDeliveryAddress[0]?.id);
      }
    }
  }, [userDeliveryAddress])

  useEffect(async () => {
    if(headerAddress && Object.keys(headerAddress)?.length){
      const currentDelivery = headerAddress;
      let placeId = currentDelivery?.address?.address?.value?.place_id;
      let label = currentDelivery?.name || currentDelivery?.type;
      const userAddress = await getAddress(placeId, label);
      setSelectedValue(headerAddress?.id);
      setCurrentDeliveryAddress(userAddress);
    }
  }, [headerAddress])

  useEffect(() => {
    handleUserDeliveryAddressChange();
  }, [handleUserDeliveryAddressChange]);

  useEffect( async() => {
    const clientId = user?.type?.includes('super') && selectedNutritionistId;
    const clientIdForTwilioToken = user?.type?.includes('super') ? selectedNutritionistId : user?.id;
    console.log("clientId............", clientId);

    if(twilioAccessToken && user?.type?.includes('super')){
      const client = new Client(twilioAccessToken);
      const data = await client?.getSubscribedConversations()
      setGetAllTwilioConversations(data?.items);
      console.log("conversations when token exist", data.items, getAllTwilioConversations)
      setTwilioClient(client);
      setIsTwilioConnectionError(false);
    } 

    if(clientIdForTwilioToken && !appInBackGround) {
      let token;
      console.log("selectedNutritionist..", clientId);
      try {
        const data = await getTwilioAccessToken({body: {clientId}});
        token = data.token;
        setTwilioAccessToken(data.token);
        setCoachFcmToken(data?.coachFcm_Token);
        const client = new Client(token);
        console.log("client created: ", client);
        setTwilioClient(client);
        client.on("connectionStateChanged", async(state) => {
          console.log(state);
          if (state === "connecting"){
            console.log("Connecting to Twilio…");
            setTwilioConnectionState(state);
          }
          if (state === "connected") {
            console.log("connected")
            setTwilioConnectionState(state);
            setIsTwilioConnectionError(false);
          }
          if (state === "disconnecting"){
            console.log("Disconnecting from Twilio")
            setTwilioConnectionState(state);
          }
          if (state === "disconnected"){
            console.log("Disconnected from Twilio")
            setTwilioConnectionState(state);
          }
          if (state === "denied"){
            console.log("Failed to connect.")
            setTwilioError("Failed to connect.");
            setTwilioConnectionState(state);
            try {
              if(!twilioDeniedError.current){
                const data = await getTwilioAccessToken({body: {clientId}});
                setCoachFcmToken(data?.coachFcm_Token);
                token = data.token;
                setTwilioAccessToken(data?.token);
                console.log("twilioAccessDenied....new..token...........", twilioDeniedError.current);
                client.updateToken(token);
              }
            } catch {
              console.log("twilioAccessDenied unable to get token, please restart the page", twilioDeniedError.current);
            }
            twilioDeniedError.current = true
          }
        });
    
        client.on("connectionError", (error) => {
          console.log("error", error);
          setTwilioError(error.message);
          setIsTwilioConnectionError(true);
          setChatsLoading(false);
        })
        
        client.on("tokenAboutToExpire", async () => {
          console.log("tokenAboutToExpire...........................");
          const data = await getTwilioAccessToken({body: {clientId}});
          setCoachFcmToken(data?.coachFcm_Token);
          token = data.token;
          setTwilioAccessToken(data?.token);
          console.log("tokenAboutToExpire....new token.......................", token);
          client.updateToken(token);
        });
        
        client.on("tokenExpired", async () => {
          console.log("token Expired.........");
          const data = await getTwilioAccessToken({body: {clientId}});
          setCoachFcmToken(data?.coachFcm_Token);
          token = data.token;
          setTwilioAccessToken(data.token);
          console.log("token Expired.....new token....", token);
          client.updateToken(token);
        });
        
        const res  = await getSubscribedConversations()
        setGetAllTwilioConversations(res?.items);
        console.log("conversations", res.items, getAllTwilioConversations)
      } catch {
        console.log("unable to get token, please reload this page");
        setTwilioError("Unable to get token, please reload this page.");
      }
    }
  }, [user, appInBackGround, selectedNutritionistId]);

  useEffect(()=>{
    const isAndroidWebView = () => {
      return isWebView && /Android/i.test(navigator.userAgent);
    };
    const globalObj = isAndroidWebView() ? document : window;
    globalObj.addEventListener("message",(message)=>{
      if(message?.data && typeof message.data == 'string'){
        const result=JSON.parse(message.data);
        result?.showAddress && setAllowNotification(true)
        result?.autoLogin ? setCheckAutoLogin(true) : setCheckAutoLogin(false)
        result?.toggleValue ? setIsCheckedToggle(true) : setIsCheckedToggle(false)
        const checkAppInBackGround = result?.IsAppInBackGround;
        const checkDiaryForegroundReload = result?.diaryForegroundReload;

        if(window.ReactNativeWebView){
          setAppInBackGround(checkAppInBackGround);
        }
        console.log("checkAppinBackground", checkAppInBackGround, result);
        if(checkAppInBackGround===true && twilioClient){
         try {
          twilioClient?.shutdown()?.then(() => {
            console.log("twilioClient disconnected");
          });
          setTwilioClient(null);
         } catch (error) {
          console.log(error);
         }
        }
        if (checkDiaryForegroundReload) {
          try {
            getdiaryData();
          } catch (error) {
            console.log(error);
          }
        }
      }
    });
  },[]);

  useEffect(()=>{
    if(getAllTwilioConversations.length > 0){
      getAllTwilioConversations.map(conversation => {
        conversation?.getUnreadMessagesCount()?.then((count) => {
          console.log("conversation unread msg", {conversation, count})
          setUnreadMsgCount(prevCount => [...prevCount, {
              conversationUnreadMsg: count,
              conversationUniqueName: conversation.uniqueName,
            }
          ])
        }).catch((error) => {
          console.log("err", error);
          setTwilioError(error.message);
        })
      })

      getAllTwilioConversations.map((conversation)=>{
        conversation?.getParticipants()?.then((participants)=>{
          participants.map((participant) => {
            participant?.getUser()?.then((user) => {
                setGetAllUsersOfConversation((prev) => [
                    ...prev.filter((item) => item.identity != user.identity), 
                    {
                        identity: user.identity, 
                        isOnline: user.isOnline, 
                        isNotifiable: user.isNotifiable, 
                        isSubscribed: user.isSubscribed 
                    }
                ]);

                user.on("updated", (userUpdated) => {
                    console.log("userUpdated", userUpdated);
                    setGetAllUsersOfConversation((prev) => [
                        ...prev.filter((item) => item.identity != userUpdated.user.identity), 
                        {
                            identity: userUpdated.user.identity, 
                            isOnline: userUpdated.user.isOnline, 
                            isNotifiable: userUpdated.user.isNotifiable, 
                            isSubscribed: userUpdated.user.isSubscribed 
                        }
                    ]);
                })
            }).catch((error) => {
              console.log(error);
              setTwilioError(error.message);
            })
          })
        })
      })
    }
  }, [getAllTwilioConversations])
 
  useEffect(async () => {
    if (user?.nutritionist?.id || nutritionistId) {
      const reqId = user?.nutritionist?.id || nutritionistId;
      const isNutritionistId = user || nutritionistId ? 'true' : 'false';
      const getOfferListUrl = `coachingOffer/offers/${reqId}?isNutritionistId=${isNutritionistId}`;
      getOfferList({
        path: `${getOfferListUrl}`,
      }).then((data) => {
        if (isVisitor) {
          setNutritionist(data.getData[0]);
        }

        if (data?.getData && data.getData?.length) {
          setOfferList(data?.getData[0]?.coachingOffer);
        } else {
          setOfferList([]);
        }
      });
    }
  }, [user?.nutritionist?.id, nutritionistId]);

  useEffect(() => {
    const persistForm = JSON.parse(localStorage.getItem('offerForm'));
    if (persistForm) {
      setOfferForm(persistForm);
    }
    localStorage.removeItem('offerForm');
  }, []);

  useEffect(async () => {
    if (profileObj) {
      try {
        const email = profileObj?.responseGoogle?.profileObj?.email;
        if (!email) {
          return;
        }  
        const saveTokenResponse = await saveToken_v2({ body: { googleRes: profileObj } });
        if(visitorDeliveryAddress && window.innerWidth > 991){
          const { token } = saveTokenResponse;
          localStorage.setItem("user", JSON.stringify(token));
          const response = await saveDeliveryAddress({ body: { ...visitorDeliveryAddress } });
          setSelectedValue(response?.data?.id);
          localStorage.removeItem("visitorDeliveryAddress");
      }      
        await afterLogin(saveTokenResponse);
      } catch (error) {
        console.log(error,'error')
        toast.error('Some required fields are missing');
      }
    }
  }, [profileObj]);  

  const setupUser = async (user, token, orderCount) => {
    pendo(user.id, user.email, user.type[0], getUserName(user));
    setIsUserSuspended(user?.isSuspended);
    localStorage.setItem('user', JSON.stringify(token));
    if (user?.type?.includes("user") || user?.type?.includes("guest") || user?.type?.includes("visitor")) {
      await getUserDeliveryAddress(user?.id);
    }
    const prevOrderCount = Number(localStorage.getItem('orderCount'));
    let totalOrder = 0;
    if (Number(orderCount) || prevOrderCount) {
      totalOrder = Number(orderCount) > prevOrderCount ? Number(orderCount) : prevOrderCount;
    }

    localStorage.setItem('orderCount', totalOrder);
    setUser({ ...user, orderCount: totalOrder });

    // Admin --- imitating provider.
    const providerData = {
      ...user?.provider,
      deliveryFee: (user?.provider?.deliveryFee / 100).toFixed(2),
    };
    const imitateProvider = JSON.parse(sessionStorage.getItem('imitateProvider'));
    imitateProvider ? setProvider(imitateProvider) : setProvider(providerData);
    setProfile(user?.profile);
    setNutritionist(user?.nutritionist);
    setNutritionistProfile(user?.nutritionistProfile);
    setIsAppLoading(false);
  };

  const setupLocalUser = async () => {
    const userToken = JSON.parse(localStorage.getItem('user'));

    //* If user not in localStorage,  it will redirect to /login/
    if (!userToken) return setIsAppLoading(false);

    try {
      const path=`auth/user?TimeZone=${TimeZone}`
      const { user, token } = await getUser({
        path:path,
    });
      setupUser(user, token);
      if (window.clarity) {
        window.clarity("identify", user?.email);
      }      
      if(user?.type?.includes("user")){
        await loadPantryMeals();
        (isMobile && fullDiaryMeals?.length<1) &&  await getdiaryData()
      }
    } catch (error) {
      console.log(`setupLocalUser() error:`, error);
      setAuthError(error);
      setIsAppLoading(false);
    }
  };

  const logout = () => {
    twilioClient?.shutdown();
    sendDataToReactNativeApp({ event: 'logout', screen: 'Login' });
    setUser(null);
    setProvider(null)
    const selectedDeliveryAddressId = localStorage.getItem('SelectedDeliveryAddress');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('SelectedDeliveryAddress', selectedDeliveryAddressId);
    window.location.href = '/';
    sessionStorage.removeItem("isBummerModal");
  };

  useEffect(() => setupLocalUser(), []);

  const afterLogin = async (res) => {
    setIsAppLoading(true);
    const { user, token, orderCount } = res;
    setIsUserSuspended(user?.isSuspended);
    setupUser(user, token, orderCount);
  };


  function convertToHttps(imageUrl) {
    if (typeof imageUrl === 'string' && imageUrl.trim().length > 0 && imageUrl.indexOf("http://") === 0) {
      return "https://" + imageUrl.slice(7);
    }
    return imageUrl;
  }

  const getAllTags = async()=>{
    try {
      const response = await getAllergies();
      setDietaryTags(response);
  } catch (err) {
      console.log(err);
  }
   }

   const isKitchenHasSubscription = async ({ kitchenId, mealId }) => {
    try{
      const path = `subscription/has-subscribed-users?kitchenId=${kitchenId}${mealId ? `&mealId=${mealId}` : ''}`;
      const response = await hasSubscribedUsers({ path });
      if(response?.success){
        return response;
      } else{
        return false
      }
    } catch(err){
      console.log(err,'err')
    }
  }

   useEffect(()=>{
    if(user?.id && isUser)
     getAllTags();
   },[user?.id])



  const type = user?.type || '';
  const isSuper = type.includes('super');
  const isMobile = window.innerWidth < 992;
  const isDesktop = window.innerWidth > 991;
  const isNutritionist = type.includes('nutritionist') || isSuper;
  const isProvider = type.includes('provider') || isSuper;
  const isGuest = type.includes('guest');
  const isVisitor = type.includes('visitor');
  const isUser = type.includes('user');
  const userTypes = [isGuest, isVisitor, isUser];
  const isShoppingUser = userTypes.includes(true);

  if (isAppLoading) return <Progress />;

  if (authError)
    return (
      <ErrorAndLogout
        error={authError}
        logout={() => {
          logout();
          location.reload();
        }}
      />
    );
  // if (!user) return <AuthRoutes - setupLocalUser = {afterLogin} />
  const handleOpenInterCom = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'navigation_click' }));
    }
  
    const intercomConfig = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      hide_default_launcher: true
    };
  
    Intercom('boot', intercomConfig);
    Intercom('show');
  };
  
  const userSchemeNavigation = ({appStoreLink, playStoreLink, schemeURL}) => {
    const isPlatformWeb = () => {
      return !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    const isPlatformIOS = () => {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    };
    const isPlatformAndroid = () => {
      return /Android/i.test(navigator.userAgent);
    };

  
    // Define the app store URLs for iOS and Android
    const appStoreURL = isPlatformIOS() ? appStoreLink : playStoreLink;
    // Define the custom URL scheme for your app
    const scheme = schemeURL;
    // Try to open the app using the custom URL scheme  
    if (!isPlatformWeb()) {
      if ((isPlatformIOS() || isPlatformAndroid()) && !isWebView) {
        window.location.href = scheme;
        setTimeout(() => {
          // If the app did not open, redirect to the app store or play store
          window.location.href = appStoreURL;
        }, 500);
      }
    }
  }

  async function countStoreVisit(storeUrl) {
    await countStoreVisits({
      path: `provider/kitchen/${storeUrl}`,
      method: 'post',
      body: {
        "userId": user?.id
      }
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log('error', error);
      })
  }
  const isPlatformIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };
  const isPlatformAndroid = () => {
    return /Android/i.test(navigator.userAgent);
  };
  
  return (
    <AppContext.Provider
      value={{
        isAppLoading,
        setIsAppLoading,
        user,
        profile,
        setProfile,
        provider: isProvider && (provider || { images: [] }),
        setProvider,
        nutritionistProfile,
        setNutritionistProfile,
        nutritionist,
        isNutritionist,
        isProvider,
        isSuper,
        isGuest,
        isVisitor,
        isUser,
        connectionError,
        logout,
        afterLogin,
        isMobile,
        isDesktop,
        offerForm,
        offerList,
        guestId,
        setGuestId,
        setOfferForm,
        setupLocalUser,
        setNutritionistId,
        nutritionistId,
        orderCount,
        setLoginInfo,
        loginInfo,
        setNutritionistInfo,
        nutritionistInfo,
        selectedGuestOfferDetails,
        updateSelectedGuestOfferDetails,
        guestInfo,
        setGuestInfo,
        setProfileObj,
        profileObj,
        isSaving,
        allNutritionist,
        selectedNutritionist,
        setSelectedNutritionist,
        pantryMeals,
        setPantryMeals,
        awaitingMeals,
        setawaitingMeals,
        pantryMealsCount,
        setPantryMealsCount,
        loadPantryMeals,
        isGetMealLoading,
        pantryMealsSource,
        awaitingMealsSource,
        setPantryMealsSource,
        TimeZone,
        twilioClient,
        getAllTwilioConversations,
        setUnreadMsgCount,
        unreadMsgCount,
        setUser,
        twilioConnectionState,
        chatData,
        setChatData,
        chatsLoading,
        setChatsLoading,
        coachFcmToken,
        isTwilioConnectionError,
        setIsTwilioConnectionError,
        twilioError,
        setTwilioError,
        routeBeforeSignup, 
        setRouteBeforeSignup,
        ...values,
        getAllUsersOfConversation,
        setGetAllUsersOfConversation,
        fullDiaryMeals,
        setFullDiaryMeals,
        isUserSuspended,
        setSelectedNutritionistId,
        platform,
        setPlatForm,
        modalShow,
        setModalshow,
        setupUser,
        userDeliveryAddress,
        setUserDeliveryAddress,
        isAddressLoading,
        convertToHttps,
        getdiaryData,
        isGetdiaryMealLoading,
        value,
        setValue,
        options,
        setOptions,
        selectedValue,
        setSelectedValue,
        isIOS,
        isAndroid,
        login,
        setLogin,
        customQtyMeal,
        setCustomQtyMeal,
        isWebView,
        dietaryTags,
        uploadProfilePic,
        handleOpenInterCom,
        desktopOnboardingUser,
        setDesktopOnboardingUser,
        userEmailExist,
        isModal,
        setIsModal,
        isAddress,
        setIsAddress,
        isSideHeader,
        setIsSideHeader,
        allowNotification,
        userSchemeNavigation,
        selectedProviderId,
        setSelectedProviderId,
        isShoppingUser,
        setHeaderAddress, 
        headerAddress,
        currentDeliveryAddress,
        changeAddress,
        setChangeAddress,
        isCart,
        setIsCart,
        isDeliveryLoading,
        checkAutoLogin, 
        setCheckAutoLogin,
        isCheckedToggle, 
        setIsCheckedToggle,
        setOtpSend,
        otpSend,
        isKitchenDeliveryAvailable,
        setIsKitchenDeliveryAvailable,
        favoriteKitchenMeals,
        setFavoriteKitchenMeals,
        countStoreVisit,
        showArea, 
        setShowArea,
        isPlatformIOS,
        isPlatformAndroid,
        isKitchenHasSubscription
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
