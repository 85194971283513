import { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import tagIcons from 'components/TagIcons';
import MealTag from './MealTag';
import styled from 'styled-components';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
const Container = styled.div`

display: grid;
grid-template-columns: auto auto auto auto;
margin-top: 10px;


@media (max-width:2560px){
  .multiTags-listings{
    margin-inline-start: -97vh  !important;
    grid-template-columns: 30.5% 33% 29.5% auto  !important;
    display: grid;
  
  }
}
@media (max-width:2000px)  {
  .multiTags-listings{
    display: grid !important;
    grid-template-columns: 30% 34% 29% 25%;
    margin-inline-start: -81vh;
   
  }
}
@media(max-width : 1920px) {
  .multiTags-listings{
    display: grid !important;
    grid-template-columns: 30.5% 33.5% 29% 25%  !important;
    margin-inline-start: -77vh;
   
  }
}
@media(max-width : 1750px) {
  .multiTags-listings{
    display: grid !important;
    grid-template-columns: 30.5% 33.5% 29% 25%;
    margin-inline-start: -68vh;
   
  }
}
@media(max-width : 1500px) {
  .multiTags-listings{
    grid-template-columns: 30.5% 33.5% 29% 25%;
    margin-inline-start: -63vh;
    display: grid;
  
  }
}
@media(max-width : 1440px) {
  .multiTags-listings{
    grid-template-columns: 30% 33% 29% 25% !important;
    margin-inline-start: -77vh  !important;
    display: grid;
   
  }
}
@media(max-width : 1400px) {
  .multiTags-listings{
    grid-template-columns: 29.5% 33.5% 27% 25%;
    margin-inline-start: -57vh;
    display: grid;
  }
}

@media (max-width : 1300px) {
  .multiTags-listings{
    margin-inline-start: -52vh;
    grid-template-columns: 27% 32% 27% 25%;
    display: grid;
   
  }
}
@media(max-width : 1255px) {
  .multiTags-listings{
    margin-inline-start: -51vh;
    grid-template-columns: 27% 32% 24% 25%;
    display: grid;
   
  }
}
@media(max-width : 1024px) {
  .multiTags-listings{
    margin-inline-start: -47vh  !important;
    grid-template-columns: 26% 25% 27% 25%  !important;
    display: grid;   
  
  }
}
@media (max-width:992px){
  .multiTags-listings{
    margin-inline-start: -50vh  !important;
    grid-template-columns: 32% 33% 27%  !important;
    display: grid;
  }
}

`


export default function MealMoreTags({
  providerName,
  Icon,
  tag,
  tags,
  recommendedTags = [],
  notRecommendedRestrictions = [],
}) {
  const [open, setOpen] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isNotRecommended, setIsNotRecommended] = useState(false);
  const [show, setShow] = useState(false);
  const [displayTags, setDisplayTags] = useState([]);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { pathname } = useLocation();
  
  const handleClick = () => {
    setVisibleDetails(!visibleDetails)
    setIsActive(!isActive);
  }

  const handleIsRecommended = () => {
    if (notRecommendedRestrictions.length !== 0 && tag) {
      notRecommendedRestrictions.forEach((notRecommendedRestriction) => {
        if (notRecommendedRestriction.toLowerCase() == tag.toLowerCase()) {
          return setIsNotRecommended(true);
        }
      });
    }
  };

  const handleRecommended = () => {
    if (recommendedTags.length !== 0 && notRecommendedRestrictions.length === 0 && tag) {
      recommendedTags.forEach((recommendedTag) => {
        if (recommendedTag.toLowerCase() === tag.toLowerCase()) {
          return setIsRecommended(true);
        }
      });
    }
  };
  useEffect(() => {
    handleIsRecommended();
    handleRecommended();
  }, [recommendedTags, notRecommendedRestrictions]);

  return (
    <div className='openMoreTags' onClick={(e) => e.stopPropagation()}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => setOpen(false)}
          open={open}
          title={tag}
          enterDelay={3000}
          
        >
          <div key={tag} onClick={handleClick} className={`tag-container more_icon ${open ? isRecommended  ? 'recommded' 
                : isNotRecommended 
                ? 'no-Recommended' 
                : 'activeTooltip' : '' }`}>
            <span className="icon-wrapper"
              onClick={() => setOpen(true)}
            >
              <img  src = {!isActive ? '/icons/moretag.svg' : '/icons/arrowicon.svg' } alt="More Tag" />
            </span>
           <span className="tag-name">{tag}</span> 
           <span className='moretag'>
               {!isActive ? ' +'+ tags.slice(7,12).length +' More' : 'Close '} 
             </span>
          </div>
        </Tooltip>
      </ClickAwayListener>
    
      <Container
      className={classNames('tags-listing recommended-list', {
        MealsTag: pathname == '/shop/meals',
      })}
    >  
    <div className='multiTags-listings'>
      {/* {!visibleDetails  
        ? displayTags.map((tag) => (
            <MealTag
              Icon={tagIcons[tag]}
              tag={tag}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
            />
          ))
        :tags .map((tag) => (
            <MealTag
              Icon={tagIcons[tag]}
              tag={tag}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
            />
          ))
          } */}
            {(!visibleDetails   ? tags.slice(0,0) : tags.slice(7,15)).map((tag, index) => (
      <div className='multiTags-listing' key={index}>
          <MealTag
              Icon={tagIcons[tag]}
              tag={tag}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
          />
         
      </div>
    
    ))}
    </div>
    </Container>
    </div>
    
  );
}
