import Input from 'components/Input';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import { useRequest } from '../hooks/useRequest';
import SubmitButton from 'components/SubmitButton';
import ProfilePhoto from 'components/Profile/ProfilePhoto';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Select from 'components/Select';
import Progress from 'components/Progress';
import BackButton from 'components/BackButton';
import Alert from 'components/Alert';
import PantryFooter from 'components/Pantry/PantryFooter';
import { ASSET_TYPE } from '../constants'


const Container = styled.div`
  display: grid;
  justify-content: center;
  padding-top: 10px;
  background-color: #ffffff;

  .height-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  @media (max-width: 991px) {
    margin-top: 0;
    padding:${(props) => (props?.selfMealPlan ? '23px 0 50px' : '23px 0')};
    display:flex;
    justify-content:center;
    background: none;

    .basic-info-wrapper {
      padding: 24px 20px;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
      border-radius: 10px;
    }

    .title-wrapper {
      display: flex;
      align-items: center;

      h4 {
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }

      img {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .height-wrapper {
      .input-container:last-child label {
        visibility:hidden;
      }
    }

    .deleteAccountWrapper{
      .alert-container{
        position: absolute;
        bottom: 30px;
        .title-desc-wrapper{
          padding: 18px;
        }
        .btn-wrapper{
          padding: 24px 0;
          display: flex;
          justify-content: center;

          button: last-child{
            width: 40%!important;
            margin-top: 0px;
          }
        }

        p{
          margin-top: 21px;
        }
      }
    }
  }

  @media(min-width:992px){
    width: 47%;
    margin: 40px auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px;
    display: block;

    .border-wrapper{
      border: 0.1px solid #51635D;
      opacity: 0.1;
      margin-bottom: 20px;
    }

    .input-wrapper.inputInfo span {
      position: absolute;
      top: auto;
      color: rgb(148, 157, 155);
      font-size: 14px;
      line-height: 41px;
      margin-left:-40px;
    }

    .btn-wrapper {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      margin-top: 5px;
      border-top: 2px solid balck;
    }
  }
`;
const StyledForm = styled.form`
  /* background: #f4f6f4; */
  // max-width: 500px;
  padding: 20px;

  button[type='submit'] {
    width: calc(50% - 10px);
    margin: 10px 0 0 10px;
  }
  @media(min-width:992px){
    padding: 0 20px 0 20px;
    max-width: 100% !important;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 28px;
    margin-right: 18px;
     
    div{
      margin-top: 0!important;
      margin-bottom: 0!important;
    }

    label{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      // margin-bottom: 8px;
      color: #51635D;
    }

    input{
      background: #FFFFFF;
      border: 1px solid #E0E3E0;
      border-radius: 6px;
      color: #51635D;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px !important;
      line-height: 17px;
    }

    div input{
      margin-top: 0 !important;
    ::placeholder{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #51635D;
    }
   }

    .select{
      background: #FFFFFF;
    }
    
    .select-label {
      margin-top: 5px !important;
    }

    .selected-value{
      height: 40px;
    }

    .border-wrapper{
      border: 0.1px solid #51635D;
      opacity: 0.1;
      margin-bottom: 20px
    }
  }

  @media (max-width: 991px) {
    padding: 0;

    label,
    .diet-plan-wrapper h5 {
      margin-bottom: 7px;
      color: #51635d;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;

      span {
        display: none;
      }
    }

    input {
      color: #51635d;
    }

    .select-label {
      margin-top: 20px;
      font-weight:700;
    }

    .diet-plan-wrapper {
      margin-bottom: 24px;

      p {
        height: 44px;
        padding: 0 14px;
        background-color: #fff;
        color: #51635d;
        font-size: 14px;
        line-height: 16px;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        display: flex;
        align-items: center;
      }
    }

    .form-btn-wrapper {
      display: flex;
      flex-direction: column !important;

      button {
        width: 100%;
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        border-radius: 21px;
      }

      button[type='submit'] {
        margin: 0 0 13px 0;
      }
    }
    .selectedValue{
      img{
        transform: rotate(180deg);
      }
    }
  }
`;
const CancelButton = styled.button`
  height: 50px;
  width: 50%;
  background-color: #ffffff;
  color: #51635d !important;
  border: 1px solid #97dc76 !important;
  border-radius: 28px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    background: #379608;
    color: #ffff;
  }
  :active {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
      1px -1px 20px 0px rgb(0 0 0 / 12%);
  }

  @media (max-width: 991px) {
    margin-bottom: 13px;
    background-color: #ffffff;
    color: #51635d !important;
    border: 1px solid #97dc76 !important;

    &:hover {
      background-color: #ffffff;
    }
  }
  @media(min-width:991px){
    height: 50px !important;
    border-radius: 28px !important;
    background: rgb(255, 255, 255) !important;
    color: rgb(81, 99, 93) !important;
    border: 1px solid #97DC76 !important;
  }
`;
export default function ProfileEdit({path}) {
  const { setProfile, profile, nutritionist, isMobile , isVisitor , isGuest, logout, setupLocalUser, uploadProfilePic } = useAppContext();
  const [localProfile, setLocalProfile] = useState({});
  const [originalValues, setOriginalValues] = useState({});
  const [allTemplates, setAllTemplates] = useState([]);
  const [showChnagePlan, setShowChnagePlan] = useState(false);
  const [isPictureBeingUploaded, setIsPictureBeingUploaded] = useState(false);
  const [updateProfile, { isLoading, error, setError }] = useRequest('profile', 'post');
  const [getProfile] = useRequest('profile');
  const [getDietTemplates] = useRequest();
  const toastId = useRef(null);
  const isRequired = isVisitor || isGuest ? false : true;
  const [open, setOpen] = useState(false);
  const [deleteAccount, {isLoading: isAccountDeleting}] = useRequest('auth/delete-user', 'post');
  const modalRef=useRef();
const  [getnumber] = useRequest();
  const isDesktop = !isMobile;

  useEffect(() => {
    if (!profile) return;
    const {
      name,
      gender,
      phoneNumber,
      birthday,
      age,
      img,
      heightFeet,
      heightInches,
      weight,
      activity,
      dietGoal,
      dietTemplateId,

    } = profile;
    setLocalProfile({
      name,
      gender,
      birthday: birthday && profile.birthday.split('T')[0],
      heightFeet,
      heightInches,
      weight,
      activity,
      dietGoal,
      dietTemplateId,
      age,
      img,
      phoneNumber,
    });
    setOriginalValues({
      name,
      gender,
      birthday: birthday && profile.birthday.split('T')[0],
      heightFeet,
      heightInches,
      weight,
      activity,
      dietGoal,
      dietTemplateId,
      age,
      img,
      phoneNumber,
    });
  }, [profile]);

  //* Get diet templates
  useEffect(() => {
    if (allTemplates.length) return;
    if (nutritionist?.id) {
      getDietTemplates({
        path: `nutritionist/dietplans/${nutritionist.id}`,
      }).then((templates) => {
        const templateSelectOptions = templates.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
        setAllTemplates(templateSelectOptions);
      });
    }
  }, [nutritionist]);

  let history = useHistory();
  const handleCancel = () => {
    // history.goBack();
    history.push('/shop/plan');
    window.scrollTo(0, 0);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleSelect = (key, value) =>
    setLocalProfile((prevProfile) => ({ ...prevProfile, [key]: value }));

  const handleIntInputChange = useCallback((e) => {
    const { name, value } = e.target;
    let currentValue = value
    let MAX_VALUE;

    if (!profile.dietTemplateId && profile.selfMealPlan && Object.keys(profile.selfMealPlan)?.length) {
      setLocalProfile((prevProfile) =>
      ({
        ...prevProfile, selfMealPlan: {
          ...profile.selfMealPlan,
          customDietMetrics: {
            ...profile.selfMealPlan.customDietMetrics,
            isActive: false
          }
        }
      }))
    } 
    if (name == 'age') { 
      MAX_VALUE = 100;
      if (currentValue > MAX_VALUE) {
        currentValue = MAX_VALUE;
        if (!toast.isActive(toastId.current)) {
          return toastId.current = toast.error(`Age entered exceeds the maximum limit. Please enter a valid age.`);
         }
      }
    }
    const isNumber = /^(\s*|[1-9][0-9]*)$/.test(currentValue);
    if ((isNumber || !currentValue === '') && currentValue.length <= 15) {
      const roundNumber = Math.trunc(currentValue);
      const data = roundNumber >= 0 ? currentValue : '';
      setLocalProfile((prevProfile) => ({ ...prevProfile, [name]: currentValue }));
    } else {
      e.preventDefault();
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(`${currentValue <= 0 ? 'Please enter a valid number' : 'Only Numeric values allowed for this field'}`);
      }
    }
  
    if (name == "heightInches"){
      MAX_VALUE = 11;
      if (currentValue > MAX_VALUE) {
        currentValue = MAX_VALUE
        if(!isNumber){
          if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(`Only Numeric values allowed for this field`);
            }
        }

      }
   
    }

    if (name == "heightFeet"){
      MAX_VALUE = 9;
      if (currentValue > MAX_VALUE) {
        currentValue = MAX_VALUE
        if(!isNumber){
          if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(`Only Numeric values allowed for this field`);
            }
        }

      }
   
    }
   


}
  , []);

  const handleUpdate = async (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setError(false);
    if(localProfile.phoneNumber && localProfile.phoneNumber.length<8){
      return toast.error(' Please enter a valid Phone Number');
    }
    if ((!isVisitor && !isGuest)  && (!localProfile.name ||
       !localProfile.gender  ||
       !localProfile.age ||
       !localProfile.heightFeet ||
       !localProfile.weight ||
       (!localProfile.phoneNumber && (localProfile.dietTemplateId)) ||
       !localProfile.activity) || (isGuest || isVisitor) && !localProfile.name
       ) {
      return toast.error(' Please fill all the details');

    };
    try {
      delete localProfile.dietTemplateId;
      await updateProfile({
        body: localProfile,
      }).then((res)=>{
        if(window.ReactNativeWebView){
          window.ReactNativeWebView.postMessage(JSON.stringify({event:"reload",screen:"null"}));
         }
      })
      const profileRes = await getProfile();
      setProfile(profileRes);
      history.push('/shop/plan');
      window.scrollTo(0, 0);
    } catch (error) {
      console.log(`updateProfile error:`, error);
      setError(error);
    }
  };

  const handlePhotoUpload = useCallback(
    async (picture, fileType, userId) => {
      const blob = await fetch(picture.croppedImg).then((r) => r.blob());
      const file = new File([blob], `${userId}.jpeg`, {
        type: 'image/jpeg',
      });
      let key;
      if (localProfile?.img?.includes('.s3.')) {
        const urlPart = localProfile?.img.split('/');
        key = urlPart[urlPart.length - 1];
      }
      setIsPictureBeingUploaded(true);
      uploadProfilePic(file, key, fileType)
        .then(async (uploadProfilePicResponse) => {
          toast.success('Profile picture uploaded successfully');
          setIsPictureBeingUploaded(false);
          setLocalProfile({
            ...localProfile,
            img: uploadProfilePicResponse?.result?.location,
          });
          delete localProfile.dietTemplateId;
          await updateProfile({
            body: {
              ...localProfile,
              img: uploadProfilePicResponse?.result?.location,
            },
          });
          await setupLocalUser();
        })
        .catch((e) => {
          toast.error('Oops!, something went wrong while uploading Image');
          setIsPictureBeingUploaded(false);
          console.log(e);
        });
    },
    [localProfile],
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteAccount = async() => {
    await deleteAccount().then((res) => {
      if(res.success == true){
        toast.success(res.message);
        setOpen(false);
        setTimeout(() => {
          logout()
        }, 1000);
      } else {
        toast.error("We are unable to process your request at this moment. Please try again later.");
        setOpen(false);
      }
    });
  };

  const selectedPlan = !allTemplates
    ? 'Unknown'
    : allTemplates.find(({ value }) => value === localProfile.dietTemplateId)?.label ||
    'Diet plan';  

  const {
    name,
    gender,
    birthday,
    phoneNumber,
    heightFeet,
    heightInches,
    weight,
    activity,
    dietGoal,
    age,
    img,
    dietTemplateId,
  } = localProfile;

  const isFormChanged = JSON.stringify(originalValues) !== JSON.stringify(localProfile);
  const handleDeleteAndClose = () => {
    handleDeleteAccount();
    setOpen(false);
  }
  return (
    <>
    <Container selfMealPlan={profile?.selfMealPlan}>
      {isPictureBeingUploaded && <Progress />}
      {isAccountDeleting && <Progress />}
      <>
      <div className="container basic-info-wrapper" >
        {isDesktop && <BackButton perviousLink={path} />}
        <div className="title-wrapper" >
          {isMobile && <img
            src="/icons/arrow-left.svg"
            alt="Arrow Icon"
            onClick={() => history.push('/shop/profile')}
            />
          }
          <h4>Basic Info</h4>
        </div>
        <ProfilePhoto imageSrc={profile?.img} handlePhotoUpload={handlePhotoUpload} fileType={ASSET_TYPE?.userProfile}/>
        <div className="border-wrapper"/>
        <StyledForm noValidate onSubmit={handleUpdate}>
          <Input
            label="Name"
            name="name"
            required
            value={name}
            onChange={handleChange}
            autoFocus
            bordered
            maxlength='100'
          />
          {isDesktop &&
            <>
              <div className="height-wrapper">
                <Input
                  label="Height"
                  name="heightFeet"
                  required={isRequired}
                  value={heightFeet}
                  onChange={handleIntInputChange}
                  type="number"
                  bordered
                  info="ft"
                />
                <Input
                  label="Inches"
                  name="heightInches"
                  value={heightInches}
                  onChange={handleIntInputChange}
                  type="number"
                  bordered
                  info="inch"
                  placeholder='0'
                />
              </div>
              <Input
                label="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleIntInputChange}
                bordered
                required={isRequired}
                type="number"
                style={{left: '58%'}}
              />
              <Input
                label="Weight"
                name="weight"
                required={isRequired}
                value={weight}
                onChange={handleIntInputChange}
                type="number"
                bordered
                info="lb"
              />
            </>
          }
          <Select
            key="gender"
            label="Gender"
            defaultChecked={gender}
            icon={isDesktop ? 'SelectArrow' : profile?.selfMealPlan ? 'arrow-square-down' : ""}
            options={[
              { label: 'Male', value: 'm' },
              { label: 'Female', value: 'f' },
            ]}
            updateValue={(value) => handleSelect('gender', value)}
          />
          {isDesktop &&
            <Select
              key="activity"
              label="Activity Level"
              defaultChecked={activity}
              icon={isDesktop ? 'SelectArrow' : ''}
              options={[
                { value: 1, label: 'Inactive' },
                { value: 2, label: 'Slightly Active' },
                { value: 3, label: 'Moderately Active' },
                { value: 4, label: 'Active' },
                { value: 5, label: 'Extremely Active' },
              ]}
              updateValue={(value) => handleSelect('activity', value)}
            />
          }
          <Input
            label="Age"
            name="age"
            value={age}
            onChange={handleIntInputChange}
            bordered
            required={isRequired}
            type="number"
            info="years"
          />
          {isDesktop &&
          <>
            <div className="diet-plan-wrapper">
              {
                (!isGuest && !isVisitor) &&
                <Input
                  label="Diet Plan"
                  name="dietplan"
                  value={selectedPlan}
                  bordered
                  required={isRequired}
                  disabled
                />
              }
              {showChnagePlan && (
                <Input
                  label="Meal plan"
                  name="dietTemplateId"
                  value={dietTemplateId}
                  onChange={handleChange}
                  options={allTemplates}
                  type="select"
                  bordered
                />
              )}
            </div>
            </>
          }
          {isMobile &&
            <>
              <div className="height-wrapper">
                <Input
                  label="Height"
                  name="heightFeet"
                  required={isRequired}
                  value={heightFeet}
                  onChange={handleIntInputChange}
                  type="number"
                  bordered
                  info="ft"
                />
                <Input
                  label="Inches"
                  name="heightInches"
                  value={heightInches}
                  onChange={handleIntInputChange}
                  type="number"
                  bordered
                  info="inch"
                  placeholder='0'
                />
              </div>
              <Input
                label="Weight"
                name="weight"
                required={isRequired}
                value={weight}
                onChange={handleIntInputChange}
                type="number"
                bordered
                info="lb"
              />
             {(!profile?.selfMealPlan || dietTemplateId) && <Input
                label="Phone Number"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleIntInputChange}
                bordered
                required={isRequired}
                type="number"
              />}
              <Select
                key="activity"
                label="Activity Level"
                defaultChecked={activity}
                options={[
                  { value: 1, label: 'Inactive' },
                  { value: 2, label: 'Slightly Active' },
                  { value: 3, label: 'Moderately Active' },
                  { value: 4, label: 'Active' },
                  { value: 5, label: 'Extremely Active' },
                ]}
                updateValue={(value) => handleSelect('activity', value)}
                icon={profile?.selfMealPlan ? 'arrow-square-down' : ''}
              />
              <div className="diet-plan-wrapper">
                {
                  (!isGuest && !isVisitor && profile?.dietTemplateId) && <>
                    <h5 style={{ marginTop: 20 }}>Diet plan</h5>
                    <p>
                      {selectedPlan}
                    </p>
                  </>
                }
                {showChnagePlan && (
                  <Input
                    label="Meal plan"
                    name="dietTemplateId"
                    value={dietTemplateId}
                    onChange={handleChange}
                    options={allTemplates}
                    type="select"
                    bordered
                  />
                )}
              </div>
            </>
          }
          {isMobile &&<div className="form-btn-wrapper">
            <SubmitButton disabled={isLoading || isFormChanged===false} type="submit" error={error}>
              {isLoading ? 'Saving...' : 'Save'}
            </SubmitButton>
            <CancelButton variant="contained" type="button" onClick={handleCancel}>
              Cancel
            </CancelButton>
            {
              window.ReactNativeWebView && (
            <CancelButton variant="contained" type="button" onClick={handleOpen}>
              Delete Account
            </CancelButton>
              )
            }
          </div>}
        </StyledForm>
        {isDesktop &&
        <>
        <div className="border-wrapper" style={{margin: '22px'}}/>
        <div className="btn-wrapper">
        <CancelButton variant="contained" type="button" onClick={handleCancel}>
          Cancel
        </CancelButton>
        <SubmitButton disabled={isLoading || isFormChanged===false} type="submit" error={error} style={{width: "50%"}} onClick={handleUpdate}>
          {isLoading ? 'Saving...' : 'Save'}
        </SubmitButton>
      </div>
      </>}
      </div>
      {open && (
        <div className="deleteAccountWrapper">
          <Alert
            title={'Delete Account'}
            desc={'Are you sure you want to delete your account?'}
            desc2={'You will lose all of your data.'}
            cancel={() => {
              setOpen(false);
            }}
            cancelText="Cancel"
            okText="Yes"
            ok={handleDeleteAndClose}
            disabled={isAccountDeleting}
            discardText="Discard"
            style={{color:"#51635D"}}
          />
        </div>
      )}
      </>
    </Container>
    {(profile?.selfMealPlan && isMobile) &&
    <PantryFooter progress={false} shop={false} myCoach={false} />
    }
    </>
  );
}
