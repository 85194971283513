import { useEffect, useState } from 'react';
import Analyzing from './Analyzing';
import AnalyzeResults from './AnalyzeResults';
import Questions from './Questions';
import nutritionistImage from 'assets/n.png';
import NutritionistQuote from 'components/NutritionistQuote';
import SubmitButton from 'components/SubmitButton';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import styled from 'styled-components';
import Header from 'layout/Header';
import classNames from 'classnames';
import NutrittSteps from 'components/NutrittSteps';
import ShopSteps from 'components/NutrittSteps/ShopSteps';
import NutritionistChatting from './components/NutritionistChatting';
import RegisteredDetitianCard from 'components/OnboardingPlans/RegisteredDetitianCard';
import BackButton from 'components/BackButton';
import Progress from 'components/Progress';

const NutritionistQuoteStyle = styled.div`
  min-height: calc(100vh - 32px);

  .nutritt-logo {
    margin: 20px;
  }

  
  .next-button-wrapper {
    padding: 40px;
    display: grid;
    justify-content: center;

    button {
      width: 301px;
    }
  }

  @media (max-width: 991px) {
    min-height: calc(100vh - 32px);
    background-color: #f6f5f8;

    .step-header .title-wrapper h2 {
      font-size: 18px;
      line-height: 24px;
    }

    .img-wrapper {
      padding: 12px 16px;
      box-shadow: 0 3px 6px #0000001a;
    }

    .nutritt-logo {
      margin: 0;
    }

    .step-header {
      height: fit-content;
      margin-bottom: 24px;
      h2 {
        margin-bottom: 5px;
        font-size: 24px;
        line-height: 28px;
      }
      .description {
        padding: 0;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .container {
      width: 90%;
      margin: 0 auto;
      padding: 36px 0;

      &.onboarding-body {
        padding: 85px 0 63px;
      }

      &.onboardingTypicalDay {
        padding: 23px 0 32px;
      }

      .social-dietatian-wrapper {
        justify-content: space-between;
      }

      .dietitian-card-wrapper {
        margin-bottom: 37px;
        margin-top: 53px;
      }

      .next-button-wrapper {
        padding: 0;
        display: block;

        button {
          width: 100%;
          border-radius: 21px;
        }
      }
    }
  }
`;

const steps = ['Plan', 'Get started', 'Personalize', 'Preview'];

export default function Onboarding() {
  const history = useHistory();
  const [active, setActive] = useState(0);
  const [error, setError] = useState('');
  const [isProfileUpdateLoading,setIsProfileUpdateLoading] = useState(true)
  const {
    setProfile,
    isMobile,
    nutritionist,
    profile: { name ,id},
    user,
    isGuest,
    profile ,
    setupLocalUser
  } = useAppContext();

  const [questions, setQuestions] = useState({
    gender: !profile?.gender ? '' : profile?.gender,
    activity: !profile?.activity ? '' : profile?.activity,
    age: !profile?.age ? '' : profile?.age,
    weight: !profile?.weight ? '' : profile?.weight,
    heightFeet: !profile?.heightFeet ? '' : profile?.heightFeet,
    heightInches: !profile?.heightInches ? '' : profile?.heightInches,
  });
 
  useEffect(() => {
    if(isGuest&& !profile.dietTemplateId){
      history.push('/shop/my-coach')
    }
  }, []);
  const type = user?.type[0];
  // useEffect(() => {
  //   console.log(`profile changed`, profile);
  // }, [profile]);
  const handleNext = async () => {
    const { activity, gender, age, heightFeet, heightInches, weight } = questions;

    const pAge = parseInt(age);
    const pHeightFeet = parseInt(heightFeet);
    const pHeightInches = parseInt(heightInches);
    const pWeight = parseInt(weight);

    if (active === 0) {
      if (!gender || !activity || [pAge, pHeightFeet, pWeight].includes(NaN))
        return setError('* Please complete all questions.');
        analytics.identify(user.id, {
          typicalday: active,
          gender:gender,
          age:pAge,
          heightinch: pHeightFeet+(pHeightInches?pHeightInches/12:0),
          weight0: pWeight    
          });
          
      setError(false);
      setProfile((prevProfile) => ({
        ...prevProfile,
        ...questions,
      }));
      window.scrollTo(0, 0);
      setActive(1);
      return;
    }
    if (active === 2) {
      // setProfile((prevProfile) => ({
      //   ...prevProfile,
      //   ...questions,
      // }));
    await  setupLocalUser()
    window.scrollTo(0, 0);
    history.push('/shop/plan');
  }
  };

  const NextButton = () => (
    <div className="next-button-wrapper" style={{}}>
      <SubmitButton onClick={handleNext} style={{}}>
        {active === 0 ? 'Get your recommended intake' : "Yeah, I'm in!"}
      </SubmitButton>
      {error && (
        <div
          style={{
            marginTop: '5px',
            color: '#F5222D',
            textAlign: 'center',
          }}
        >
          {error}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <NutritionistQuoteStyle>
      {isMobile ? (
        <Header />
      ) : (
        <div className="img-wrapper">
          <img className="nutritt-logo" src="/logo.png" alt="logo" width="100" />
        </div>
      )}
      <div
        className={classNames('container onboarding-body', { onboardingTypicalDay: active === 0 })}
      >
        {isMobile ? (
          active === 0 && (
            <div className="dietitian-card-wrapper">
              <RegisteredDetitianCard dietitianPhoto={nutritionist?.img || '/images/avatar.png'} name={nutritionist?.name} title={nutritionist?.title} />
            </div>
          )
        ) : (
          <>
          <NutritionistQuote image={nutritionist?.img|| '/images/avatar.png'}>
            “Answer a few questions and get customized plan to meet your personal dietary needs!”
          </NutritionistQuote>
          {/* {active === 2 && <BackButton perviousLink={() => setActive(0)} />} */}
          <BackButton perviousLink={() => {active === 0 ? (type == "user" && history.push("/shop/my-coach/offers")) : setActive(0)}} />
          </>
        )}
        {isMobile &&
          (active === 0 ? (
            <NutrittSteps
              title="Help me get to know you"
              description="Tell me about your current lifestyle"
              activeStep={2}
              stepTitle="What's a typical day  look like?"
              perviousLink={() => type == "user" && history.push("/shop/my-coach/offers")}
            />
          ) : (
            <ShopSteps
              title="Your daily goals are ready!"
              activeStep={3}
              perviousLink={() => setActive(0)}
            />
          ))}
        {active === 0 ? (
          <Questions questions={questions} setQuestions={setQuestions} nextButton={<NextButton/>} />
        ) : active === 1 ? (
          <Analyzing onNext={() => setActive(2)} setIsProfileUpdateLoading={setIsProfileUpdateLoading} nutritionistImage={nutritionistImage} setActive={setActive}/>
        ) : (
          <>{ isProfileUpdateLoading ?
            <> <Progress/></> :
          <> 
          <AnalyzeResults nextButton={<NextButton/>} />
          {isMobile && (
            <NutritionistChatting nutritionistImage={nutritionist?.img || '/images/avatar.png'} username={name} />
          )}</> }
          </>
        )}
        {!isMobile && active !== 1 && <NextButton />}
      </div>
    </NutritionistQuoteStyle>
  );
}

