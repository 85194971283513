import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { ReactComponent as SignOut } from 'icons/sign-out.svg';
import { Button } from 'components/elements/button/Button';
import { sidebarLinks } from './Data';
import { SideHeaderMenuWrapper } from './style';
import { useMemo } from 'react';

export default function SideHeaderMenu({ setIsSideMenu, isSideMenu, setIsAddress }) {
  const { user, profile, logout, setIsSideHeader } = useAppContext();
  const history = useHistory();

  const handleClick = () => {
    setIsSideHeader(false);
    history.push('/account/home');
  }

  const displayname = useMemo(()=>{
    let name = profile?.name 
    if( profile?.lastName){
      name = profile?.name +' '+ profile?.lastName; 
    }
    return name?.length > 15 ? name?.slice(0, 15) + "..." : name 
  },[profile])

  return (
    <SideHeaderMenuWrapper>
      <div
        className={classNames('sidebar-header-mask', { displayMask: isSideMenu })}
        onClick={() => setIsSideMenu(false)}
      />
      <div className={classNames('sidebar-container', { isSideMenu })}>
        {!user ? (
          <div className="sign-buttons-wrapper">
            <Button title="Sign up" type="dark" onClick={handleClick}/>
            <Button title="Log in" type="mobile_secondary" onClick={handleClick}/>
          </div>
        ) : (
          <div className="user-navigation-links">
            <div className="user-info-wrapper">
              <div className="user-info">
                <div className="user-logo">
                  {profile?.img ? (
                    <div className="user-image">
                      <img src={profile?.img} alt="User Image" />
                    </div>
                  ) : (
                    <div className="user-image-placeholder">{profile?.name[0]}</div>
                  )}
                </div>
                  <div className="user-profile-wrapper" onClick={() => {
                    setIsSideMenu(false)
                    history.push('/shop/myProfile')
                  }}>
                  <div className="user-name">{displayname}</div>
                  <div className="user-profile">My profile</div>
                </div>
              </div>
              <div className="sidebar-navigation-links">
                {sidebarLinks.map((link) => (
                  <div
                    key={link.id}
                    className="link-wrapper"
                    onClick={() => {
                      setIsSideMenu(false);
                      link.id === 2 ? setIsAddress(true) : history.push(link.click);
                    }}
                  >
                    {link.icon}
                    <div className="title">{link.title}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="sign-out-button-wrapper">
              <div className="sign-out-container" onClick={() => logout()}>
                <SignOut />
                <div className="title">Sign out</div>
              </div>
            </div>
          </div>
        )}
        <div className="app-download-wrapper">
          <div className="love-app">
            <div className="brand-wrapper">
              <img src="/images/nutritt-logo-2.svg" alt="Logo" />
            </div>
            <p>There’s more to love in the app</p>
          </div>
          <div class="app-download">
            <a
              href="https://apps.apple.com/us/app/nutritt/id1605156638"
              target="-blank"
              class="app-store-image"
            >
              <img
                src="/images/app-store.svg"
                alt="App Store"
                onClick={() => setIsSideMenu(false)}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.nutritt"
              target="-blank"
              class="google-play-image"
            >
              <img
                src="/images/google-play.svg"
                alt="Google Play"
                onClick={() => setIsSideMenu(false)}
              />
            </a>
          </div>
        </div>
      </div>
    </SideHeaderMenuWrapper>
  );
}
