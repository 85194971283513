import React, { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { FavoriteKitchenWrapper } from 'pages/shop/FavoriteKitchen/style';
import { KitchenCardWrapper } from 'components/elements/KitchenCard/style';

const KitchenDetailsSkeleton = ({ provider }) => {
  return (
    <FavoriteKitchenWrapper>
      <section className="categories-tabs-wrapper">
        {provider?.mealCategories?.length && (
          <>
            <div className="categories-title">
              <Skeleton variant="text" width={150} height={36} />
            </div>
            <div className="categories-static">
              {provider?.mealCategories?.map((item) => (
                <Skeleton
                  variant="rounded"
                  style={{ borderRadius: '8px', flex: `0 0 ${item?.length * 10 + 24}px` }}
                  width={item?.length * 8}
                  height={38}
                  key={item}
                />
              ))}
            </div>
          </>
        )}
      </section>
      <section className="categories-dishes-wrapper">
        <div className="category-wrapper">
          <div className="dishes-title">
            <Skeleton variant="text" width={56} height={32} />
          </div>
          <div className="dishes-container">
            {Array.from({ length: 3 }, (_, index) => index + 1)?.map((item) => (
              <KitchenCardWrapper key={item}>
                <div className="card-img-wrapper">
                  <Skeleton variant="rectangular" width="100%" height={192} />
                </div>
                <div className="card-details" style={{ height: '99px' }}></div>
              </KitchenCardWrapper>
            ))}
          </div>
        </div>
      </section>
    </FavoriteKitchenWrapper>
  );
};

export default KitchenDetailsSkeleton;
