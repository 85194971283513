import { Line } from 'rc-progress';
import styled from 'styled-components';
import { useState, useRef, useEffect } from 'react';
import { useContext } from 'react';
import { ClientDiaryContext } from '../NutritionistClientProfile';
import autoAnimate from '@formkit/auto-animate'

const CartCaloriesStyle = styled.div`
  padding: 14px 16px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  border-bottom: 1px solid #F6F5F8;
  overflow: hidden;

  .cal__info__wrapper{
    flex: 1;
  }

  .cal__item{
    display: flex;
    align-items: center;
   // column-gap: 4px;
    padding: 4px 0 2px;

    .title{
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      //letter-spacing: 0.02em;
      color: #51635D;
      width: 47px;
      font-family: 'Roboto';
      margin-right:2px;
    }

    .line{
      width: 100%;
      border-radius: 10px;
      flex: 1;
      margin: 0 2px;
    }

    .total__cal{
      font-weight: 700;
      font-size: 11px;
      line-height: 12px;
      //text-align: right;
      letter-spacing: 0.02em;
      color: #51635D;
      margin-left:2px;
    }
  }

  .expand{
    display: flex;
  }

  .rc-progress-line-path{
    transition-duration: 1s, 1s, 1s !important;
  }

`

export default function CartCalories({calculatedValues}) {

  const [showMacros, setShowMacros] = useState(false);
  const {followerProfile} = useContext(ClientDiaryContext);
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  const displayNumbers = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const showMacrosAnimation = () => {
    setShowMacros(true);
  }

  const hideMacrosAnimation = () => {
    setShowMacros(false);
  }

  const recom_Calories = followerProfile?.recommndedNutrients?.calories;
  const recom_Fat = followerProfile?.recommndedNutrients?.macros.fat;
  const recom_Protein = followerProfile?.recommndedNutrients?.macros.protein;
  const recom_Carbs = followerProfile?.recommndedNutrients?.macros.carbs;

  return (
    <CartCaloriesStyle>
      <div className='cal__info__wrapper'>
        <div className='cal__item'>
          <p className='title'>Calories</p>
          <Line
            percent={Math.round((calculatedValues.calcCalorie / recom_Calories ) * 100)}
            strokeColor="#52c41a"
            trailColor="#E0E3E0"
            height="6px"
            strokeLinecap='round'
            className='line'
          />
          <div className='total__cal'>
          {Math.round((calculatedValues.calcCalorie))} {recom_Calories && `/${recom_Calories}`}
          </div>
        </div>
        <div ref={parent}>
        { showMacros && <>
          <div className='cal__item'>
            <p className='title'>Fats</p>
            <Line
              percent={Math.round((calculatedValues.calcFat / recom_Fat ) * 100)}
              strokeColor="#FEB95A"
              trailColor="#E0E3E0"
              height="6px"
              strokeLinecap='round'
              className='line'
            />
            <div className='total__cal'>
            {Math.round((calculatedValues.calcFat))} {recom_Fat && `/${recom_Fat}g`}
            </div>
          </div>
          <div className='cal__item'>
            <p className='title'>Proteins</p>
            <Line
              percent={Math.round((calculatedValues.calcProtein / recom_Protein ) * 100)}
              strokeColor="#EE786B"
              trailColor="#E0E3E0"
              height="6px"
              strokeLinecap='round'
              className='line'
            />
            <div className='total__cal'>
            {Math.round((calculatedValues.calcProtein))} {recom_Protein && `/${recom_Protein}g`}
            </div>
          </div>
          <div className='cal__item'>
            <p className='title'>Carbs</p>
            <Line
              percent={Math.round((calculatedValues.calcCarbs / recom_Carbs ) * 100)}
              strokeColor="#916AE2"
              trailColor="#E0E3E0"
              height="6px"
              strokeLinecap='round'
              className='line'
            />
            <div className='total__cal'>
            {Math.round((calculatedValues.calcCarbs))} {recom_Carbs && `/${recom_Carbs}g`}
            </div>
          </div>
          </>
        }
        </div>
      </div>

      {/* EXPAND THE CALORIES */}
      <div className='expand'>
        {!showMacros ? 
          <img src='/icons/expand.svg' onClick={showMacrosAnimation} /> :
          <img src='/icons/hide.svg' onClick={hideMacrosAnimation} />
        }
        </div>
    </CartCaloriesStyle>
  )
}
