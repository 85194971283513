import produce from 'immer';
import { useNutritionistContext } from 'context/nutritionistContext';

export default function UseClientCart() {
  const { clientsettings, clientcart, updateClientcart, clientcartCount, updateClientsettings } = useNutritionistContext();

  const randomNumber = () => {
    return Math.floor(Math.random() * clientsettings.days + 1);
  };

  let dayNumber = 1;

  const getClientMealDay = () => {
    if (dayNumber > clientsettings.days) {
      dayNumber = randomNumber();
    } else {
      if (clientcartCount + 1 <= clientsettings.meals * dayNumber) {
        return `Day ${dayNumber}`;
      } else {
        dayNumber++;
        getClientMealDay();
      }
    }
    return `Day ${dayNumber}`;
  };

  const addToClientCart = (meal) => {
    updateClientsettings(
      produce((draft) => {
        draft.mealPlan.push({
          ...meal,
          idx: `${meal.id}-${draft.mealPlan.length}`,
          day: getClientMealDay(),
        });
      }),
    );
    const mealPlan = getClientMealDay();
    //*If its a combo, generate a new unique ID
    if (meal.isCombo) {
      const nextId = 'combo' + (clientcart.filter(({ isCombo }) => isCombo).length + 1);
      return updateClientcart((prevCart) => [
        ...prevCart,
        {
          ...meal,
          mealPlan,
          id: nextId,
          providerId: clientsettings.provider.id,
        },
      ]);
    }

    /*Not a combo. check if meal already in cart, then just update the qty*/
    if (clientcart.find((item) => item.id === meal.id)) {
      const updatedCart = clientcart.map((item) => {
        let updateitem = item.qty;
        if (item.id === meal.id) {
          return {
            ...item,
            qty: meal.customQty ? (item.qty + meal.customQty) : (updateitem += 1),
            mealPlan: [...item.mealPlan, mealPlan],
          };
        }
        return item;
      });
      return updateClientcart(updatedCart);
    }
    updateClientcart((prevCart) => [
      ...prevCart,
      {
        ...meal,
        qty: meal.customQty ? meal.customQty : 1,
        mealPlan: [mealPlan],
      },
    ]);
  };
  const removeFromClientCart = (mealId) => {
    const index = clientcart.findIndex((item) => item.id === mealId);
    if (index !== -1) {
      updateClientcart(
        produce((draft) => {
          draft.splice(index, 1);
        }),
      );
      updateClientsettings(
        produce((draft) => {
          draft.mealPlan = draft.mealPlan.filter((meal) => meal.id !== mealId);
        }),
      );
    }
  };

  const removeMeal = (data) => {
    if (data?.idx) {
      updateClientsettings(
        produce((draft) => {
          draft.mealPlan = draft.mealPlan.filter((meal) => meal.idx !== data.idx);
        }),
      );
      if (data.id) {
        const cartItem = localStorage.getItem("clientcart")
        if (cartItem) {
          let jsonCart = JSON.parse(cartItem);

          const updatedItem = jsonCart.map((item) => {
            if (item.id === data.id) {
              console.log("item", {item, data});
              if(item.qty > 1){
                console.log("item", item.qty);
                return { ...item, qty: item.qty - 1 }
              }else{
                return removeFromClientCart(data.id);
              }
            }

            return item;
          }).filter((item) => item != undefined)

          console.log('updatedItem', updatedItem);

          // localStorage.setItem("clientcart", JSON.stringify(updatedItem))
          // return updateClientcart(updatedItem)
        }

      }
    }
  };


  const updateClientMealQty = (meal, newQty) => {
    if (newQty < 1) return;
    const updatedCart = clientcart.map((item) => {
      if (item.id === meal.id) {
        return { ...item, qty: newQty };
      }
      return item;
    });

    updateClientsettings(
      produce((draft) => {
        const currentMeal = draft.mealPlan.filter((item) => item.id === meal.id);

        const currentMealQty = currentMeal.length;

        if (currentMealQty < newQty) {
          for (let i = 0; i < newQty - currentMealQty; i++) {
            draft.mealPlan.push({
              ...meal,
              idx: `${meal.id}-${draft.mealPlan.length}`,
              day: getClientMealDay(),
            });
          }
        }

        if (currentMealQty > newQty) {
          const mealRemoved = draft.mealPlan.filter((item) => item.id == meal.id).pop();
          const mealIndex = draft.mealPlan.indexOf(mealRemoved);
          draft.mealPlan.splice(mealIndex, 1);
          draft.mealPlan;
        }
      }),
    );

    return updateClientcart(updatedCart);
  };

  function NameHasTheWhiteSpaceOrNot(value) {
    return value?.indexOf(' ') >= 0;
  }

  function OnlyFirstName(name) {
    var Firstname = NameHasTheWhiteSpaceOrNot(name);
    if (Firstname == true) {
      const SplitFullname = name.split(' ')
      const shortname = SplitFullname[0];
      return shortname;
    }
    else {
      return name;
    }
  }
  return { 
    addToClientCart,
    removeFromClientCart,
    updateClientMealQty, 
    OnlyFirstName, 
    removeMeal 
  };
}

