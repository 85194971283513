import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useRequest } from 'hooks/useRequest';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { useNutritionistContext } from '../../context/nutritionistContext';
import { useAppContext } from 'context/appContext';
import Alert from 'components/Alert';
import { useHistory } from 'react-router-dom';

const NutritionistAccountStyle = styled.div`
  width: 50%;

  .form-group {
    margin-bottom: 40px;

    .form-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #52c41a;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    .form-group .form-title span {
      font-size: 16px;
    }
  }

  .password-btn {
    width: 179px;
    height: 49px;
    margin-right: 33px;
    background-color: #52c41a;
    color: #fff;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
  }
  .delete-btn{
    font-size: 17px;
    background: #f5f5f5;
  }
  @media (max-width: 425px) {
    .delete-btn{
      font-size: 15px;
      border-radius: 21px;
      border: 1px solid #c3cbc9 !important;
      width: 384px;
      height: 45px;
    }
    .password-btn{
      width: 384px ;
      font-size: 16px;
      border-radius: 21px;
      margin-bottom: 20px;  
    }
  }

  @media (max-width: 320px) {
    .delete-btn{
      font-size: 15px;
      border-radius: 21px;
      border: 1px solid #c3cbc9 !important;
      width: 291px !important;
      height: 45px;
    }
    .password-btn{
      width: 291px !important;
      font-size: 16px;
      border-radius: 21px;
      margin-bottom: 20px;  
    }
  }


  @media (max-width: 768px) {
    .delete-btn{
      font-size: 15px;
      border-radius: 21px;
      border: 1px solid #c3cbc9 !important;
      width: 168px;
      height: 45px;
     
    }
    .password-btn{
      width: 168px;
      font-size: 16px;
      border-radius: 21px;
      margin-bottom: 20px;  
      height: 45px;
    }
  }

  .password-btn-wrapper {
    display: flex;
  }

  .account-info-wrapper {
    width: 40%;
    margin-bottom: 38px;

    p,
    .account-info {
      margin-bottom: 13px;
    }

    .account-info {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

export default function NutritionistAccount() {
  const { nutritionist: nutritionistData } = useNutritionistContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogs, setOpenDialogs] = useState(false);
  const { isMobile ,user } = useAppContext()
  const history = useHistory();
  const [accountData, setAccountData] = useState({
    email: nutritionistData?.user?.email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const { email, oldPassword, newPassword, confirmPassword } = accountData;
  const [changePassword] = useRequest(`profile/password`, 'put');
  const nutritionistId = user?.nutritionistProfile?.id;
  const [deleteAccount, {isLoading: isAccountDeleting}] = useRequest(`nutritionist/delete/${nutritionistId}`, 'post');

  const handleChange = useCallback(
    (e) => {
      setAccountData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [oldPassword, newPassword, confirmPassword],
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const data = { body: { oldPassword, confirmPassword, newPassword } };
      if (oldPassword && newPassword && confirmPassword) {
        if (newPassword === confirmPassword) {
          changePassword(data)
            .then((result) => {
              if (result.success) {
                handleClose();
                toast.success(result.message);
              } else {
                toast.error(result.message);
              }
            })
            .catch(console.error);
        } else {
          toast.error('New password and Confirm password are not same');
        }
      } else {
        toast.error('All fields are required');
      }
    },
    [oldPassword, confirmPassword, newPassword],
  );
  const handleDelete = async (e)=>{
     await  deleteAccount()
        .then((res) => {
          setOpenDialogs(false)
          if(res.success == true){
            toast.success(res.message);
            setTimeout(()=>{
              history.push("/logout")
              },1000)
          }
        }).catch((error) => {
          toast.error("We are unable to process your request at this moment. Please try again later.");
          setOpenDialogs(false)
        })
  }
  
  const handleClose = useCallback(() => {
    setOpenDialog(false);
    setAccountData((prev) => ({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      email: prev?.email,
    }));
  }, []);

  return (
    <NutritionistAccountStyle>
      <div className="form-group">
        <div className="form-title">
          <label>Email</label>
        </div>
        <input
          type="email"
          autoComplete="off"
          value={
            nutritionistData?.user?.email
              ? nutritionistData?.user?.email
              : nutritionistData?.nutritionist?.name.includes('@')
              ? nutritionistData?.nutritionist?.name
              : ''
          }
          readOnly={true}
        />
      </div>
      
        <div className="password-btn-wrapper">
          <button type="button" className="password-btn" onClick={() => setOpenDialog(true)}>
            Change Password
          </button>
         {window.ReactNativeWebView && (
            <button type="button" className="delete-btn" onClick={() => setOpenDialogs(true)}>
              Delete account
            </button>
         )}  
             </div>
      {openDialogs && <Alert
        title={'Delete Account'}
        desc={'Are you sure you want to delete your account?'}
        desc2={'You will lose all of your data.'}
        cancel={() => {
          setOpenDialogs(false);
        }}
        cancelText="Cancel"
        okText={"Yes"}
        ok={handleDelete}
        discardText="Discard"
        disabled={isAccountDeleting}
      />}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Change password</DialogTitle>
        <DialogContent>
          <TextField
            id="oldPassword"
            name="oldPassword"
            autoFocus
            margin="dense"
            label="Old Password"
            type="password"
            fullWidth
            variant="standard"
            value={oldPassword}
            onChange={handleChange}
            required
          />
          <TextField
            id="newPassword"
            name="newPassword"
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            size="medium"
            value={newPassword}
            onChange={handleChange}
            required
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            margin="dense"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
            value={confirmPassword}
            onChange={handleChange}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </NutritionistAccountStyle>
  );
}

