import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right.svg';
import { theme } from 'shared/theme/theme';

const ListWithArrowStyle = styled.div`
  .account_urls {
    padding: 13px 18.086px 13px 19px;
    background-color: #fff;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    .title {
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      flex: 1;
    }
    .sub-title {
      color: ${({ theme }) => theme?.colors?.grayDarker };
      font-size: ${({ theme }) => theme?.typography?.fontSmall};
      font-weight: 500;
      line-height: 14.56px;
      flex: 1;
    }
    .tab {
      flex-direction: column;
      display: flex;
      flex:1
    }
    .chevron {
      width: 23px;
    }
    &:hover{
        background-color: ${({ theme }) => theme?.colors?.lightGrey};
        }
  }
`;

const ListWithArrow = ({ icon, title="Test Title", onClick, subTitle="test subtitle" }) => {
  return (
    <>
      <ListWithArrowStyle theme={theme} onClick={onClick}>
        <div className="account_urls">
          {icon}
          <div className='tab'>
            <p className="title">{title}</p>
            <p className="sub-title">{subTitle}</p>
          </div>
          <ChevronRight className="chevron" />
        </div>
      </ListWithArrowStyle>
    </>
  );
};

export default ListWithArrow;
