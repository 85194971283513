import { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { useAppContext } from 'context/appContext';
import { Link, useLocation } from 'react-router-dom';
import UserManagement from './UserManagement';
import AdminSidebar from './AdminSidebar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import './style.scss';
import PromoCodeTable from './PromoCodeTable'
import { CoachManagement } from './CoachManagement';
import {Coaches} from './Coaches'
import Providers from './DisbursementManagement';
import OrderManagement from './OrderManagement';
import ProvidersManagement from './ProvidersManagement';

const AdminStyle = styled.div`
  overflow-x: hidden;
  min-height: calc(100vh - 32px);
  position: relative;
  z-index: 5;
  .nutritionistbody{
    background:#ffffff !important;
    min-height: calc(100vh - 32px);
  }
  .main-screen {
    width: 100%;
  }
  .is-admin {
    background-color: red;
    width: calc(100% - 300px);
    text-align: center;
    margin: 0 0 0 auto;
  }
  header {
    height: 52px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px #0000001a;

    .icon-wrapper {
      display: flex;
      cursor: pointer;

      svg {
        font-size: 20px;
         cursor: pointer;
         
      }
      img{
        cursor:pointer;
      }
    }

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .container {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    header {
      position: relative;
      background-color: #fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

      .img-wrapper {
        display: flex;
        img{
          width: 71px;
          height: 28.38px;
        }
      }
    }
    .nutritionist-image{
      object-fit:cover;
      width: 50%;
      display: flex;
      justify-content: right;
      img{
        width:25px;
        height:25px;
        object-fit:cover;
        border-radius:50%;
      }
    }

    .admin-wrapper {
      .drawer-div{
       .MuiDrawer-root .MuiDrawer-paper{
        height:90vh !important;
       }
     } 
    }

    .MuiTabs-flexContainer {
      justify-content: space-between;

      .MuiTab-root {
        padding: 0 12px;
      }

      .MuiButtonBase-root .MuiTab-wrapper {
        font-size: 18px;
      }
    }

    .MuiTabs-indicator {
      height: 3px !important;
    }

    .form-section {
      margin-bottom: 24px;
      flex-direction: column;

      .form-group-wrapper + .form-group-wrapper {
        margin-top: 24px;
      }

      // .form-group {
      //   margin-bottom: 24px;

      //   &.form-group-section {
      //     width: 100%;
      //   }

      //   &.form-group-section:first-child {
      //     margin-right: 0;
      //   }

      //   &:last-child {
      //     margin-bottom: 0;
      //   }
      // }
    }

    .form-group {
      margin-bottom: 24px;
    }

    label {
      margin-bottom: 7px !important;
      font-size: 12px !important;
    }import { includes } from 'ramda';


    .save-btn,
    .plan-btn button {
      width: 100%;
      height: 44px;
      padding: 0;
      border-radius: 6px;
    }
    .admin-div{
      display:flex;
      justify-content:center;
      align-items: center;
      gap: 22px;
    }

    .admin-img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      border-radius:50%;
      background:url('/images/green-circle.svg');
      display:flex;
      justify-content:center;
      align-items:center;
      background-size:100%;
      img{
        width:8px;
        height:8px;
      }
    }
  }
  @media(min-width:992px){
    .main-screen{
      width: calc(100vw - 8px) !important;
      background:#ffffff;
    }
    .admin-wrapper{
      background:#ffffff;
    }
  }
`;

export default function Admin() {
  const { isMobile, isDesktop, isSuper } = useAppContext();
  const [displayMobileSidebar, setDisplayMobileSidebar] = useState(false);
  const [getForm, setGetForm] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [link, setLink] = useState();
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const localnutritionist = JSON.parse(localStorage.getItem('nutritionist'));
  const { pathname } = useLocation();

  // const toggleDrawer = (open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setDisplayMobileSidebar(open);
  // };

  useEffect (() => {
    if (pathname === '/admin/users/provider-management') {
      document.body.classList.add('hide-body-scroll');
      document.body.style.height = `${window.innerHeight - 32}px`;
    } else {
      document.body.classList.remove('hide-body-scroll');
      document.body.style.height = '100%';
    }
  }, [pathname])

  return (
      <AdminStyle>
        <div>
        {isMobile && (
          <header>
            <div className="container">
              <div className="header-wrapper">
                <div className="img-wrapper">
                  <img className="nutritt-logo" src="/images/nutritt-logo.svg" alt="logo" />
                </div>
                <div className='admin-div'>
                <div className='admin-img'> <img src='/images/big-n.svg'/></div>
                <div className="icon-wrapper">
                {displayMobileSidebar ? <img src= "/icons/close-circle2.svg" alt="Menu Icon" onClick={() => setDisplayMobileSidebar(false)} /> : <img src= "/icons/Sandwich.svg" alt="Menu Icon" onClick={() => setDisplayMobileSidebar(true)} /> } 
                </div> 
                </div>
              </div>
            </div>
          </header>
        )}
        <div className="admin-wrapper" style={{ display: 'flex' }}>
          <div className='drawer-div'>
          {/* <Drawer anchor="bottom" open={displayMobileSidebar} onClose={toggleDrawer(false)}> */}
          <Drawer
            anchor="bottom"
            open={displayMobileSidebar} onClose={() => setDisplayMobileSidebar(false)}>
            <AdminSidebar
              isAlert={(link) => {
                setIsAlert(true);
                setLink(link);
              }}
              closeSidebar={() => setDisplayMobileSidebar(false)}
            />
           </Drawer>

          {/* </Drawer> */}
          </div>
          <div className="main-screen">
            {isDesktop && (
              <AdminSidebar/>
            )}
            <Switch>
              <Route exact path="/admin/users">
                <Redirect to="/admin/users/user-management" />
              </Route>
              <Route exact path="/admin/users/user-management" component={UserManagement} />
              <Route exact path="/admin/users/promocodes" component={PromoCodeTable} />
              <Route exact path="/admin/users/coach-management" component={CoachManagement}/>
              <Route exact path="/admin/users/provider-management" component={ProvidersManagement}/>
              <Route exact path="/admin/users/coaches" component={Coaches}/>
              <Route exact path="/admin/users/providers" component={Providers}/>
              <Route exact path="/admin/users/order-management" component={OrderManagement}/>

            </Switch>
          </div>
        </div>
        </div>
      </AdminStyle>
   
  );
}


