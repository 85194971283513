import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import { useHistory } from 'react-router-dom';
import SettingsRowItem from 'components/elements/settingsRowItem/SettingsRowItem';
import { GilroyFontWrapper } from 'shared/theme/style';
import { motion, AnimatePresence } from 'framer-motion';
import FoodPreferences from './FoodPreferences';
import Allergies from './Allergies';
import MacroSplit from './MacroSplit';
import { useShopContext } from 'context/shopContext';

const DietPreferencesStyle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  .account_urls{
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const FixedModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 112;
`;

const OverflowHidden = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme?.colors?.white};
`;

const DietPreferences = () => {
  const history = useHistory();
  const [activeModalStack, setActiveModalStack] = useState([]);
  const [animation, setAnimation] = useState({
    initial: {
      x: '100%',
      opacity: 0,
    },
    exit: {
      x: '-100%',
      opacity: 0,
    },
  });
  const { healthGoal } = useShopContext();

  useEffect(() => {
    if (activeModalStack?.length > 0) {
      setAnimation({
        initial: {
          x: '-100%',
          opacity: 0,
        },
        exit: {
          x: '-100%',
          opacity: 0,
        },
      });
    }
    document.body.classList.add('hide-body-scroll');
    return () => document.body.classList.remove('hide-body-scroll');

  }, [activeModalStack]);

  const dietPreferencesArray = [
    {
      id: 0,
      modal: 'food-preferences',
      component: (activeModalStack, setActiveModalStack, modal) => (
        <FoodPreferences
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
        />
      ),
    },
    {
      id: 1,
      modal: 'allergies',
      component: (activeModalStack, setActiveModalStack, modal) => (
        <Allergies
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
        />
      ),
    },
    {
      id: 2,
      modal: 'macro-split',
      component: (activeModalStack, setActiveModalStack, modal) => (
        <MacroSplit
          modal={modal}
          activeModalStack={activeModalStack}
          setActiveModalStack={setActiveModalStack}
        />
      ),
    },
  ];

  return (
    <>
      <OverflowHidden theme={theme}>
        <GilroyFontWrapper>
          <AnimatePresence>
            {activeModalStack?.length > 0 ? (
              ''
            ) : (
              <motion.div
                initial={animation?.initial}
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={animation?.exit}
                transition={{ duration: 0.7, type: 'spring' }}
              >
                <DietPreferencesStyle theme={theme} style={{ height: window.innerHeight - 70 }}>
                  <MobileHeader
                    text={'Diet preferences'}
                    onClickLeft={() => history.push('/shop/account')}
                  />
                  <div className="containerr">
                    <SettingsRowItem
                      title={'Food preferences'}
                      subTitle={healthGoal?.selfMealPlan?.dietaryPreferences?.map((item) => {
                        const formattedString = item[0].toUpperCase() + item.slice(1).toLowerCase();
                        return formattedString
                      }).join(', ') || 'N/A'}
                      onClick={() => setActiveModalStack([dietPreferencesArray[0]])}
                    />
                    <SettingsRowItem
                      title={'Allergies'}
                      subTitle={healthGoal?.selfMealPlan?.allergies?.map((item) => {
                        const formattedString = item[0].toUpperCase() + item.slice(1).toLowerCase();
                        return formattedString
                      }).join(', ') || 'N/A'}
                      onClick={() => setActiveModalStack([dietPreferencesArray[1]])}
                    />
                    <SettingsRowItem
                      title={'Macro split'}
                      subTitle={healthGoal?.selfMealPlan?.preferredDiet?.type?.split('-')?.join(' ').replace(/^\w/, match => match.toUpperCase()) + ` (${healthGoal?.selfMealPlan?.preferredDiet?.carbsPercent}% Carb, ${healthGoal?.selfMealPlan?.preferredDiet?.proteinPercent}% Protein, ${healthGoal?.selfMealPlan?.preferredDiet?.fatPercent}% Fat)` || 'N/A'}
                      onClick={() => setActiveModalStack([dietPreferencesArray[2]])}
                    />
                  </div>
                </DietPreferencesStyle>
              </motion.div>
            )}

            {activeModalStack?.length &&
              activeModalStack?.map((modal, index) => {
                const currentScreenId = dietPreferencesArray
                  ?.map((screen) => screen?.id)
                  .indexOf(modal?.id);
                const nextScreen = dietPreferencesArray[currentScreenId + 1];
                return (
                  <FixedModalStyle key={index}>
                    {modal?.component(activeModalStack, setActiveModalStack, nextScreen)}
                  </FixedModalStyle>
                );
              })}
          </AnimatePresence>
        </GilroyFontWrapper>
      </OverflowHidden>
    </>
  );
};

export default DietPreferences;
