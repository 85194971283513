import styled from 'styled-components';
import { useState, useRef, useEffect } from 'react';
import { useChatContext } from 'context/chatContext';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from 'context/nutritionistContext';

const ChatButtonStyle = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all 0.2s;
    background-color: #fff;

  .comment__in__progress{
    padding: 14px 14px;
    width: 100%;
    background-color: #fff;
    overflow-x: hidden;

    .editable-div{
      width: 100%;
      min-height: 42px;
      border: 1px solid #F6F5F8;
      background-color: #F6F5F8;
      border-radius: 25px;
      padding: 10px 40px 10px 20px;
      position: relative;

      & > div{
        font-size: 16px;
        line-height: 20px;
        word-break: break-word;
        position: relative;
        overflow-y: scroll;
        max-height: 100px;
        padding-right: 5px;
        min-height: 20px;

        &:-webkit-scrollbar{
          right: 20px;
        }
      }
      
      .placeholder{
        position: absolute;
        background-color: transparent;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        word-break: break-all;
        width: calc(100% - 60px);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #AFB5B4;
        pointer-events: none;
        &.hide{
          display: none;
        }
      }
      
    }

    .submit__btn{
      background-color: #52C41A;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 5px;
      bottom: 5px;

      img{
        transform: translateX(-1px) rotate(45deg);
      }
    }

  }

`

export default function SendChatButton({ followerProfile, setShowPantryFooter, setBtnHeight, setAutoScroll, autoScroll }) {

  const [messageToSend, setMessageToSend] = useState('');
  const messageBox = useRef();
  const [styles, setStyles] = useState({});
  const { activeConversation, pushNotification } = useChatContext();
  const {isMobile, coachFcmToken, getAllUsersOfConversation, user} = useAppContext();
  const location = useLocation();
  const buttonRef = useRef();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (location.pathname == "/shop/chat-with-coach") {
      isMobile ? setStyles({
        paddingBottom: '8px',
      }) : setStyles({
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
      })
    }else{
      setStyles({
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      })
    }
  }, [])

  useEffect(() => {
    if(messageBox.current.textContent !== ''){
      setHide(true);
    }else{
      setHide(false);
    }
  }, [messageBox?.current?.textContent])

  const setMessage = (e) => {
    const message = e.target.textContent.trim(); // remove any extra whitespace
    const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1);
    setBtnHeight(buttonRef?.current?.clientHeight);
    setMessageToSend(capitalizedMessage);
  }

  const isFocused = (e) => {
    setBtnHeight(buttonRef?.current?.clientHeight);
    setShowPantryFooter && setShowPantryFooter(false);
    isMobile && setStyles({
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    })
  }

  const isBlurred = (e) => {
    setShowPantryFooter && setShowPantryFooter(true);
    if (location.pathname == "/shop/chat-with-coach") {
      if(isMobile){
        setBtnHeight(buttonRef?.current?.clientHeight + 8);
        setStyles({
          paddingBottom: '8px',
        })
      }
    } else {
      setBtnHeight(buttonRef?.current?.clientHeight);
      setStyles({
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
      })
    }
    setAutoScroll(!autoScroll);
  }

  const isTyping = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      sendMessageToChat();
    } else {
      // else send the Typing Indicator signal
      console.log('activeConversation.typing', activeConversation);
      activeConversation.typing().then((data)=>{
        console.log('activeConversation?.typing: ', data);
      }).catch((err)=>{
        console.log('activeConversation?.typing err:', err);
      })
    }
  }
  const sendMessageToChat = () => {
      messageBox.current.textContent = '';
      setBtnHeight(buttonRef?.current?.clientHeight);
      messageBox.current.focus();

      const fcmToken = (user?.type?.includes('nutritionist') || user?.type?.includes('super')) ?
        followerProfile?.fcm_token : coachFcmToken;
      const clientId = (user?.type?.includes('nutritionist') || user?.type?.includes('super')) ?
        followerProfile?.userid : user?.nutritionist?.userId;
      if (!messageToSend.trim() || messageToSend === '') {
        return;
      }
      activeConversation.sendMessage(messageToSend.trim()).then((data) => {
        if (followerProfile?.fcm_token || coachFcmToken ) {
          const clientActive = getAllUsersOfConversation?.length && getAllUsersOfConversation.find((item) => String(item.identity) == String(clientId));
          console.log("clientActive......", clientActive, followerProfile?.userid);
          if (!clientActive.isOnline && user?.pushNotificationAllowed) {
            pushNotification(messageToSend, fcmToken, clientId)
          }
        }
        console.log('activeConversation?.sendMessage: ', data);
      }).catch((err) => {
        console.log('activeConversation?.sendMessage err:', err);
      })
      setMessageToSend('');
    
  }

  const buttonMouseDown = (e) => {
    e.preventDefault();
  }

  return (
    <ChatButtonStyle style={styles}>
      <div className='comment__in__progress' ref={buttonRef}>
        <div style={{ position: 'relative' }}>
          <div className='editable-div'>
            <div autoComplete="off" autoCorrect="off" autocapitalize="sentences" ref={messageBox} onKeyDown={isTyping} onFocus={isFocused} onBlur={isBlurred} onInput={setMessage} placeholder={`Message ${user?.nutritionist?.name||followerProfile?.name}...`} contentEditable={true}></div>
            <span className={`placeholder ${hide && 'hide'}`}>{`Message ${user?.nutritionist?.name||followerProfile?.name}...`}</span>
          </div>
          <button className='submit__btn' onMouseDown={buttonMouseDown} onClick={sendMessageToChat}>
            <img src='/icons/send-icon.svg' />
          </button>
        </div>
      </div>
    </ChatButtonStyle>
  )
}