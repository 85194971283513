import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const RemoveAddressModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 6;
  padding: 0 16px 26px;
  background-color: #3a3c44cc;
  display: flex;
  align-items: flex-end;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  .remove-address-container {
    position: relative;
    width: 100%;
    padding: 35px 0 21px;
    background-color: ${() => theme.colors.secondary};
    border-radius: 12px;
  }

  .close-modal-wrapper {
    position: absolute;
    top: 15px;
    left: 16px;
    display: flex;
    img {
      width: 20px;
      height: 20px;
    }
  }

  p {
    width: 200px;
    margin: 0 auto 16px;
    color: #0b1d17;
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  .control-btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    button {
      width: 214px;
      height: 42px;
      background-color: ${() => theme.colors.primaryDefault};
      color: ${() => theme.colors.secondary};
      border-radius: 30px;
      font-family: ${() => theme.typography.fontRoboto};
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      &.cancel {
        background-color: ${() => theme.colors.lightGrey};
        color: ${() => theme.colors.primaryDefault};
      }
    }
  }

  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0b1d17a6;
    border-radius: 10px;
  }
`;
