import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const MealCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: inherit;
  background: ${() => theme.colors.secondary};
  border-radius: 12px;
  font-family: ${() => theme.typography.fontGilroy};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  .meal-img-wrapper {
    position: relative;
    height: 192px;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: clip;
    .meal-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .calories-wrapper {
      position: absolute;
      bottom: 15px;
      left: 16px;
      height: 20px;
      padding: 0 6px 0 4px;
      background-color: ${() => theme.colors.secondary};
      border-radius: 4px;
      display: flex;
      align-items: center;
      span {
        margin-left: 4px;
        color: ${() => theme.colors.primaryDefault};
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  .meal-details {
    position: relative;
    padding: 10px 16px 16px;
    .title {
      overflow: hidden;
      margin-bottom: 4px;
      color: ${() => theme.colors.deepForestGreen};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .tags-wrapper {
      overflow: auto;
      height: 19px;
      display: flex;
      align-items: center;
      gap: 6px;
      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }
      .tag {
        height: 19px;
        padding: 0 8px;
        background-color: #f5f7f8;
        color: ${() => theme.colors.primaryDarker};
        border-radius: 10px;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }
    .price {
      color: ${() => theme.colors.primaryDarker};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      .limit-order {
        color: ${theme.colors.alizarinCrimson};
        font-size: 12px;
        line-height: normal;
      }
    }
  }

  .add-cart {
    display: flex;
  }

  .cart-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .order-btn {
      width: 73px;
      height: 32px;
      color: ${() => theme.colors.primaryDarker};
      border: 1px solid ${theme.colors.grayDarker};
      border-radius: 30px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;
