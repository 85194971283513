import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import produce from 'immer';
import { useEffect } from 'react';
import moment from 'moment';
import differenceBy from 'lodash/differenceBy';
import { useVisitorContext } from 'context/visitorContext';

export default function useCart() {
  const {
    profile,
    user,customQtyMeal,setCustomQtyMeal,
    isSuper
  } = useAppContext();
  const { settings, cart, updateCart, cartCount, updateSettings, availableProviders } = (user && (!user?.type?.includes('provider') || isSuper)) ? useShopContext() : useVisitorContext();
  const { dietTemplate } = profile || {};
  const duration = dietTemplate?.duration; 
  const duration_type = dietTemplate?.duration_type; 
  //const loadCart =  user?.loadCart;
  const randomNumber = () => {
    return Math.floor(Math.random() * settings.days + 1);
  };

  // let dayNumber = 1;
  // const getMealDay = (mealArray) => {
  //   if (dayNumber > settings.days) {
  //     dayNumber = randomNumber();
  //   } else {
  //     if (cartCount + 1 <= settings.meals * dayNumber || mealArray + 1 <= settings.meals * dayNumber) {
  //       return `Day ${dayNumber}`;
  //     } else {
  //       dayNumber++;
  //       getMealDay(mealArray);
  //     }
  //   }
  //   return `Day ${dayNumber}`;
  // };

  const getMealDay =  (mealPlan = settings.mealPlan) => {
    if (!mealPlan.length) return 'Day 1';
    // get last day meals count
    const lastMealDay = mealPlan[mealPlan.length - 1].day
    const lastMealDayQty = mealPlan.filter(meal => meal.day === lastMealDay).length

    // check if last day need to add more meals
    if (lastMealDayQty < settings.meals) {
      // add that meal on the same day
      return lastMealDay
    }
    const getLastDayNumber = Number(lastMealDay.slice(4))
    const nextDay = getLastDayNumber + 1

    if (nextDay <= settings.days) {
      return `Day ${nextDay}`;
    }

    return `Day ${randomNumber()}`;
  };

  const addToCart = (meal, isClientCart = false) => {
    if(meal?.customQty){
      const provider= availableProviders?.data?.length && availableProviders?.data?.filter((provider)=> provider.id ===meal?.providerId);
      let mealArray  = [...settings?.mealPlan];
      for(let i=0; i<meal?.customQty; i++){
        mealArray.push({
          ...meal,
          idx: `${meal.id}-${i}`,
          day: getMealDay(mealArray),
        });
      }
      if(!isClientCart){
        updateSettings({...settings, 
          mealPlan: mealArray,
          provider: provider[0]
        })
      }
    } else {
      if(meal?.qty > 1){
        let mealArray  = [...settings?.mealPlan];
        for(let i=0; i<meal?.qty; i++){
          mealArray.push({
            ...meal,
            idx: `${meal.id}-${i}`,
            day: getMealDay(mealArray),
          });
        }
        updateSettings(
          produce((draft) => {
            draft.mealPlan = mealArray;
          })
        );
      }
      else{
        updateSettings(
          produce((draft) => {
            draft.mealPlan.push({
              ...meal,
              idx: `${meal.id}-${draft.mealPlan.length}`,
              day: getMealDay(),
            });
          }),
        );
      }
      
    }
    const mealPlan = getMealDay();
    //*If its a combo, generate a new unique ID
    if (meal.isCombo) {
      const nextId = 'combo' + (cart.filter(({ isCombo }) => isCombo).length + 1);
      return updateCart((prevCart) => [
        ...prevCart,
        {
          ...meal,
          mealPlan,
          id: nextId,
          providerId: settings.provider.id,
        },
      ]);
    }

    /*Not a combo. check if meal already in cart, then just update the qty*/
    if (cart.find((item) => item.id === meal.id)) {
      const updatedCart = cart.map((item) => {
      let updateitem = item.qty;
     if (item.id === meal.id) {
          return {
            ...item,
            qty: meal.customQty ? (item.qty + meal.customQty) : (meal.qty ? (item.qty + meal.qty) : (updateitem += 1)),
            mealPlan: [...item.mealPlan, mealPlan],
          };
        }
        return item;
      });
      return updateCart(updatedCart);
    }
    if (isClientCart && Object.isExtensible(cart)){
      cart.push({
        ...meal,
        qty: meal.customQty ? meal.customQty : 1,
        mealPlan: [mealPlan],
      });
      updateCart([...cart]);
    } else {
      updateCart((prevCart) => [
      ...prevCart,
      {
        ...meal,
        qty: meal.customQty ? meal.customQty : meal?.qty ? meal?.qty : 1,
        mealPlan: [mealPlan],
      },
    ]);
    }
  };

  const addMultipleToCart = (meals, isClientCart = false) => {
    return new Promise((resolve, reject) => {
      try {
        meals.forEach((meal, index) => {
          addToCart(meal, isClientCart);
          if (index === meals.length - 1) {
            resolve({message: 'All meals added to cart', success: true});
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  

  const removeProviderFromCart = (meals) => {
    setCustomQtyMeal(false);
    updateCart(
      produce(() => differenceBy(cart, meals, 'id'))
    );
    updateSettings(
      produce((draft) => ({...draft, mealPlan:differenceBy(settings.mealPlan, meals, 'id')})),
    );
  };

  const removeFromCart = (mealId) => {
    const index = cart.findIndex((item) => item.id === mealId);
    if (index !== -1) {
      updateCart(
        produce((draft) => {
          draft.splice(index, 1);
        }),
      );
      updateSettings(
        produce((draft) => {
          draft.mealPlan = draft.mealPlan.filter((meal) => meal.id !== mealId);
        }),
      );
    }
  };

  const generatePantryDatesCalories = () => {
    updateSettings(
      produce((draft) => {
        if (draft.pantryDatesCalories.length == 0) {
          let pantryDatesCalories = [],
            calories = 0,
            date = moment().day(-30).format('L');
          for (let i = 0; i < 200; i++) {
            pantryDatesCalories.push({
              date,
              calories,
            });
            date = moment(date).add(1, 'd').format('L');
            calories = 0;
          }
          draft.pantryDatesCalories = pantryDatesCalories;
        }
      }),
    );
  };

  useEffect(() => {
    if (user && !isSuper) {
      generatePantryDatesCalories();
    }
  }, []);

  const updateMealQty = (meal, newQty) => {
    if (newQty < 1 && meal?.method !== 'input') return;
    const updatedCart = cart.map((item) => {
      if (item.id === meal.id) {
        return { ...item, qty: meal?.customQty && !customQtyMeal ? (item.qty+newQty) : newQty };
      }
      return item;
    });

    updateSettings(
      produce((draft) => {
        const currentMeal = draft.mealPlan.filter((item) => item.id === meal.id);

        const currentMealQty = currentMeal.length;

        if (currentMealQty < newQty) {
          for (let i = 0; i < newQty - currentMealQty; i++) {
            draft.mealPlan.push({
              ...meal,
              idx: `${meal.id}-${draft.mealPlan.length}`,
              day: getMealDay(draft.mealPlan),
            });
          }
        }

        if (currentMealQty > newQty) {
          for (let i = 0; i < currentMealQty - newQty; i++) {
          const mealRemoved = draft.mealPlan.filter((item) => item.id == meal.id).pop();
          const mealIndex = draft.mealPlan.indexOf(mealRemoved);
          draft.mealPlan.splice(mealIndex, 1);
          draft.mealPlan;
          }
        }
      }),
    );

    return updateCart(updatedCart);
  };
  return { addToCart, removeFromCart, updateMealQty, removeProviderFromCart, getMealDay, addMultipleToCart };
}
