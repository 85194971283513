import ClientDiaryContent from './ClientDiaryContent';
import styled from 'styled-components';
import moment from 'moment';
import { useAppContext } from 'context/appContext';
import ImageViewer from 'components/ImageViewer';

const ClientDiaryMealStyle = styled.div`
  .img-wrapper{
     img{
        width: 60px;
        height: 60px;
        margin-top : 5px;
        border-radius: 8px;
        object-fit: contain;
     }
    .meal-count {
      position: absolute;
      bottom: 13px;
      left: 11px;
      padding: 3px 5px;
      color: rgb(81, 99, 93);
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(203, 237, 186);
      border-radius: 10px;
      font-size: 8px;
      line-height: 8px;
    }
  }
  .meal-content{
    width: 60%;
    height: 100%;
  }
  .pantry-meal-title{
    margin-bottom: 9px;
    width : 130%;
    color: rgb(11, 29, 23);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    span{
        color:#949D9B;
        font-weight: 400;
    }
  }
  .meal-details > div:first-child {
    margin: 0px;
    border: 1px solid rgb(224, 227, 224);
    width : 130%;
    border-radius: 6px;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }

  @media(min-width: 992px){
    .img-wrapper {
      position: relative;
      .meal-count {
        bottom: -7px;
        left: -9px;
      }
    } 
    .card-wrapper{
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;

      display: -webkit-box;
    
    display: -ms-flexbox;
   
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

      .time-wrapper{
        border: 1px solid #CBEDBA;
        border-radius: 6px; 
        width: 118px;
        height: 67px;
        align-items: center;
        text-align: center;
        display: flex;
      }
      .time-content{
        background: #F0FAEB;
        border-radius: 4px;
        width: 118px;
        height: 63px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 8px 2px 8px 2px;
      }
      .time-icon{
        width:18px;
        padding-right:3px;
      }
    }
  }
`

export default function ClientDiaryMeal({ id, name, images, img, calories, carbs, protein, fat, count, updatedAt }) {
  const { isMobile } = useAppContext();
  return (
    <ClientDiaryMealStyle>
    <div className='card-wrapper'>  
    {!isMobile && 
    
    <div className='time-wrapper'>
      <div className='time-content'>
        <ImageViewer
          src='/icons/time.png' className='time-icon'
          />
        <p>{moment(updatedAt).format("h:mm a")}</p>
      </div>
    </div>
    }
    <div key={id} className="diary-meal-wrapper">
      <div className="img-wrapper">
      {(images?.[0] || img) ?
          <img src={img || images?.[0]} alt="meal" />
          :
          <img src={'/images/sample.jpg'} alt="meal" />
        }
        {count > 1 && <span className='meal-count'>{count > 9 ? '+9' : count}</span>}
      </div>
      <div className="meal-content">
        <div className="pantry-meal-title"> {name || 'Test'} {isMobile && <span>| {moment(updatedAt).format("h:mm a")}</span>}</div>
        <div className="meal-details">
          <ClientDiaryContent
            calories={calories}
            carbs={carbs}
            fat={fat}
            protein={protein}
          />
        </div>
      </div>
    </div>
    </div>
    </ClientDiaryMealStyle>
  );
}
