import styled from 'styled-components';
import { ReactComponent as DefaultImage } from 'assets/shop/defaultImage.svg';
import { theme } from 'shared/theme/theme';
import MinimumOrderBar from 'components/minimumOrderBar/MinimumOrderBar';
import { Button } from 'components/elements/button/Button';
import ImageViewer from 'components/ImageViewer';
import { ReactComponent as TrashBin } from 'assets/shop/trashbin.svg';
import { useEffect, useState } from 'react';
import useCart from 'hooks/useCart';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { useVisitorContext } from 'context/visitorContext';
import { useHistory } from 'react-router-dom';
import produce from 'immer';

const OtherProviderCartStyle = styled.div`
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  .delete {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 12px;
  }
  .provider__details {
    display: flex;
    align-items: center;
    gap: 12px;
    .icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      border: 1.5px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 4px;
      .title {
        color: ${({ theme }) => theme?.colors?.deepForestGreen};
        font-size: ${({ theme }) => theme?.typography?.fontLarge};
        font-weight: 700;
        line-height: 24px;
        width: 95%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      }
      .min_order {
        color: ${({ theme }) => theme?.colors?.grayDarker};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.28px;
      }
    }
  }
  .error_box {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-bottom: -5px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme?.colors?.grayLight};
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .progress-info {
    height: 5px;
  }
  .meal__image {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    .image {
      background-color: #f8f8f8;
      border-radius: 10px;
      width: 72px;
      height: 72px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .extra__meal__numbers {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      background: ${({ theme }) => theme?.colors?.lightGrey};
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      text-align: center;
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 500;
      line-height: 20px;
    }
  }
  .add__button{
    button{
      cursor: pointer;
      font-weight: 600;
    }
  }
`;

const OtherProviderCart = (props) => {
  const {
    providerName = 'Demo Kitchen',
    minValueNumber = 10,
    buttonGotPressed = false,
    providerImage,
    mealList = [],
    provider,
  } = props;
  const [totalSum, setTotalSum] = useState();
  const { removeProviderFromCart } = useCart();
  const { user, selectedProviderId, setSelectedProviderId, setIsCart } = useAppContext();
  const { updateSettings } = (user && !user?.type?.includes('provider')) ? useShopContext() : useVisitorContext();
  const history = useHistory();

  const calculateTotalCost = (uniqMealData) => {
    let total = 0;
    uniqMealData?.forEach((item) => {
      const itemTotal = item.price * item.qty;
      total += itemTotal;
    });
    setTotalSum(total);
  };

  useEffect(() => {
    mealList?.length && calculateTotalCost(mealList);
  }, [mealList])

  const generateKitchenMeals = (provider) => {
    updateSettings(
      produce((draft) => {
        draft.provider = provider;
      }),
    );
    history.push(`/store/${provider?.username}`)
    window.scrollTo(0, 0);
  };

  return (
    <>
      <OtherProviderCartStyle theme={theme}>
        <TrashBin className="delete" onClick={() => removeProviderFromCart(mealList)}/>
        <div className="provider__details">
          <div className="icon">
            {providerImage ? 
              <img src={providerImage} className='img' onError={(e) => { e.target.src = '/images/avatar.png' }}/>
            :
            <DefaultImage className="img" />
            }
          </div>
          <div className="details">
            <h2 className="title" title={providerName}>{providerName}</h2>
            <p className="min_order">{
              minValueNumber
                  ? `Min order: $${minValueNumber}`
                  : 'No minimum order'
            }</p>
          </div>
        </div>
        {minValueNumber > totalSum &&
        <MinimumOrderBar
          minValueNumber={minValueNumber}
          totalSumNumber={totalSum}
          buttonGotPressed={buttonGotPressed}
        />}
        <div className="divider" />
        <div className="meal__image">
          {
            mealList?.length > 3 ? (
            <>
              {mealList?.slice(0, 3)?.map((meal) => (
                  <div className="image">
                    <ImageViewer src={meal?.images?.[0]} alt={'/images/kitchen-image-small.png'} fallbackSrc={'/images/kitchen-image-small.png'} />
                  </div>
                )
              )}
              {mealList?.slice(3)?.length > 0 && (
                <div className="extra__meal__numbers">+{mealList?.slice(3)?.length}</div>
              )}
            </>
            )
            :
            mealList.map((meal) => (
              <div className="image">
                <ImageViewer src={meal?.images?.[0]} alt={'/images/kitchen-image-small.png'} fallbackSrc={'/images/kitchen-image-small.png'} />
              </div>
            ))
          }
        </div>
        <div className="add__button">
          <Button type={'mobile_secondary'} title="Add more" onClick={() => {
            generateKitchenMeals(provider);
            setIsCart(false);
          }} />
        </div>
      </OtherProviderCartStyle>
    </>
  );
};

export default OtherProviderCart;
