import Input from 'components/Input';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {useNutritionistContext} from "../../context/nutritionistContext";
import Progress from 'components/Progress';
import {toast} from "react-toastify";
import {useState} from "react";
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';

const CreatePasswordPanelWrapper = styled.div`
  padding: 4px 20px 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  label {
    margin-bottom: 7px;
    color: #51635d;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 14px;
  }

  button: disabled {
    background-color: #e0e3e0;
    color: #949d9b;
    cursor: not-allowed;
  }

  input::placeholder {
    visibility: hidden;
  }

  button {
    height: 44px;
    margin-top: 32px;
    border-radius: 21px;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default function CreatePasswordPanel({
                                                form,
                                                setForm,
                                                createPassword,
                                                isLoading
}) {

  const history = useHistory();
  const { afterLogin } = useAppContext();
  const {selectedOfferDetails} = useNutritionistContext();
    const [FormError, setFormError] = useState({});
    const [login] = useRequest('auth/login', 'post');
    const passwordValidation = (createPass, confirmPass) =>{
        const errors = {};
        if(!createPass){
            errors.createPass = "Password is required!"
        }
        if(!confirmPass){
            errors.confirmPass = "Confirm Password is required!"
        }
        if((createPass !== confirmPass)){
            errors.confirmPass = "Password mismatch!"
        }
        return errors;
    }

    const handlePassword = async (e) => {
      e.preventDefault();
      if((form?.createPass && form?.confirmPass) && (form?.createPass === form.confirmPass)){
        const res = await createPassword({
          body: {
            userId: parseInt(selectedOfferDetails.userId),
            password: form.createPass,
            confirmPassword: form.confirmPass
          },
        })

        if(res.success === true){
          toast.success('Password Created successfully');
          const loginResp = await login({
            // TO DO - need user's email to login in to nutitt.
            body: { email: selectedOfferDetails.clientEmail,
                    password: form.confirmPass },
          });
          afterLogin(loginResp);
          localStorage.setItem('steps', 'true');
          window.scrollTo(0, 0);
          window.location.href = "/shop/pantry";
        } else {
          toast.error('Oops! something went wrong');
        }
      }
      setFormError(passwordValidation(form?.createPass, form?.confirmPass));
    };

  return (
    <CreatePasswordPanelWrapper>
      <Input
        type="password"
        label="Create password"
        value={form.createPass}
        error={form.error}
        onChange={(e) => setForm({ ...form, createPass: e.target.value })}
      />
        <h5 style={{color: 'red', paddingTop: 0, margin:0}}>{FormError.createPass}</h5>
      <Input
        type="password"
        label="Confirm password"
        value={form.confirmPass}
        error={form.error}
        onChange={(e) => setForm({ ...form, confirmPass: e.target.value })}
      />
        <h5 style={{color: 'red', paddingTop: 0, margin:0}}>{FormError.confirmPass}</h5>
      <button className="primary-btn" onClick={handlePassword} disabled={ !form.createPass || !form.confirmPass || isLoading}>
          {isLoading ? "Confirming..." : "Confirm" }
      </button>
        <br/>
        {isLoading && <Progress/>}
    </CreatePasswordPanelWrapper>
  );
}
