import React from 'react'

const Ketogenic = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_3899_36841)">
                <path d="M12.7642 1.05539L15.4622 3.75619C15.4622 3.75619 12.3376 4.06644 11.4013 7.81709C11.1936 8.6481 10.4207 10.8447 7.93878 11.2769C6.88051 11.4211 5.8987 11.9082 5.14373 12.6637C4.38876 13.4192 3.90227 14.4013 3.75877 15.4597L1.05797 12.7616C1.20205 11.703 1.68908 10.7208 2.44452 9.9654C3.19996 9.20996 4.18216 8.72292 5.24075 8.57885C7.71995 8.14395 8.49556 5.95007 8.70332 5.11628C9.63959 1.36841 12.7642 1.05539 12.7642 1.05539ZM12.6922 0C12.5343 0.0166203 8.82797 0.434898 7.72549 4.84759C7.54821 5.56226 6.93048 7.25477 5.04685 7.5844C1.36822 8.22705 0.14109 11.2076 0.00535725 12.6951C-0.00975349 12.8416 0.00719422 12.9895 0.0550302 13.1287C0.102866 13.2679 0.180443 13.395 0.282362 13.5012L2.99978 16.2186C3.09352 16.3127 3.20492 16.3873 3.32756 16.4381C3.4502 16.489 3.58168 16.5151 3.71445 16.515C3.83167 16.5155 3.94803 16.4948 4.05794 16.4541C4.23845 16.3886 4.39657 16.2731 4.51381 16.121C4.63105 15.9689 4.70256 15.7866 4.71998 15.5954C4.80365 15.0658 4.99199 14.5583 5.27399 14.1023C5.87232 13.1217 6.82522 12.5123 8.10221 12.2907C11.2407 11.7367 12.1797 8.90848 12.393 8.05531C13.1437 5.05534 15.5121 4.73124 15.6118 4.73124C15.7989 4.70989 15.9761 4.63618 16.1231 4.51857C16.2701 4.40096 16.381 4.24424 16.4428 4.06643C16.5063 3.88614 16.5173 3.69156 16.4746 3.50525C16.432 3.31895 16.3374 3.14856 16.2018 3.01382L13.4844 0.296398C13.3034 0.116014 13.0611 0.0102264 12.8058 0H12.6922Z" fill={color || "#757E89"} />
                <path d="M2.83361 14.6978L1.82808 14.6064L1.8724 14.1023C2.03542 12.9294 2.57441 11.841 3.4085 11.0004C4.24259 10.1598 5.32679 9.61238 6.49839 9.44028C8.67288 9.06078 9.37924 7.14113 9.57869 6.33228C10.5953 2.26031 13.9637 1.87804 14.1077 1.86419L14.6091 1.81433L14.7088 2.81986L14.1908 2.8808C14.0772 2.8808 11.382 3.22429 10.5427 6.58713C10.2934 7.58712 9.41525 9.96659 6.66459 10.4458C5.70932 10.5745 4.82204 11.011 4.13722 11.6894C3.4524 12.3677 3.00739 13.2508 2.86962 14.2048L2.83361 14.6978Z" fill={color || "#757E89"} />
                <path d="M13.2599 19.997C11.3791 19.9527 9.1741 18.8447 8.77522 16.5151C8.47051 14.7422 9.47326 13.1411 11.332 12.4348C11.3518 12.4276 11.3722 12.422 11.3929 12.4182C11.4511 12.4015 12.9469 11.9777 13.3319 9.58163C13.609 7.80879 14.8915 6.54841 16.379 6.58166C17.0771 6.59828 18.7862 6.96393 19.6061 10.0082C20.3623 12.8309 19.897 15.7782 18.3901 17.7007C17.1685 19.2519 15.3956 20.0552 13.2599 19.997ZM11.6976 13.3877C10.0356 14.022 9.64224 15.3267 9.81399 16.3156C10.1159 18.0663 11.8306 18.9001 13.2959 18.9333C15.0826 18.9749 16.5591 18.3212 17.5646 17.0414C18.8665 15.3794 19.2599 12.7977 18.5867 10.2963C18.1463 8.65643 17.3042 7.66475 16.3347 7.64259C15.3652 7.62043 14.5286 8.51793 14.3292 9.77276C13.8693 12.6453 11.9968 13.299 11.6976 13.3877Z" fill={color || "#757E89"} />
                <path d="M14.3904 17.5621C13.9521 17.5621 13.5236 17.4321 13.1592 17.1886C12.7948 16.9451 12.5107 16.599 12.343 16.1941C12.1753 15.7892 12.1314 15.3436 12.2169 14.9137C12.3024 14.4839 12.5135 14.089 12.8234 13.7791C13.1333 13.4692 13.5282 13.2581 13.958 13.1726C14.3879 13.0871 14.8335 13.131 15.2384 13.2987C15.6433 13.4664 15.9894 13.7505 16.233 14.1149C16.4765 14.4793 16.6064 14.9078 16.6064 15.3461C16.6064 15.9338 16.3729 16.4975 15.9574 16.9131C15.5418 17.3286 14.9781 17.5621 14.3904 17.5621ZM14.3904 14.1522C14.1524 14.1516 13.9195 14.2217 13.7214 14.3536C13.5233 14.4855 13.3688 14.6732 13.2774 14.893C13.1861 15.1128 13.1621 15.3548 13.2084 15.5882C13.2547 15.8217 13.3692 16.0362 13.5375 16.2045C13.7058 16.3728 13.9203 16.4873 14.1537 16.5336C14.3872 16.5799 14.6292 16.5559 14.8489 16.4645C15.0687 16.3732 15.2565 16.2187 15.3884 16.0206C15.5202 15.8224 15.5904 15.5896 15.5898 15.3516C15.5869 15.0352 15.4593 14.7326 15.2348 14.5096C15.0102 14.2867 14.7068 14.1612 14.3904 14.1605V14.1522Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_3899_36841">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Ketogenic