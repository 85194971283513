import styled from "styled-components"
import Title from "./components/Title"
import Button from "./components/Button"
import { useHistory, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from "react";
import { useOnboardingContext } from "./index";
import PageTransition from "./components/PageTransition";
import { CrossButton } from "./components/CrossButton";
import { toast } from 'react-toastify';
import { useDebounce } from "use-debounce/lib";

const IdealWeightStyle = styled.div`
    padding: 48px 32px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: overlay;
    height: 100%;
    .flex{
        flex: 1;
    }
    .form_group{
        position: relative;
        margin: 75px 18px 0;
        .form_control{
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            padding: 0 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
        }
        label{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
        }
    }
`
export default function IdealWeight() {
    const [idealWeight, setIdealWeight] = useState("");
    const { onboardingUser, setOnboardingUser } = useOnboardingContext();
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isNewGoal = params.get('newGoal');
    const toastId = useRef(null);
    let errorOccurred = false;
    const [SearchedValue] = useDebounce(idealWeight, 800);
    const handleBack = () => {
        isNewGoal === 'true' ? history.push('/self-onboarding/current-weight?newGoal=true') : history.push('/self-onboarding/userGender');
    }
    const handleChange = (e) => {
        let weight = e?.target?.value;
        const regex = /^(0|[0-9]\d*)(,\d{0,2})?$/;
        if (weight.length <= 3 && regex.test(weight)) {
            setIdealWeight(weight);
            setOnboardingUser((prev) => ({
                ...prev,
                selfMealPlan: {
                    ...prev.selfMealPlan,
                    idealWeight: Number(weight)
                }
            }));
            if (toast.isActive(toastId.current)) {
                toast.dismiss(toastId.current);
                toastId.current = null;
            }
            errorOccurred = false; // Reset the error flag
        } else {
            const validWeight = weight.slice(0, -2);
            setIdealWeight(validWeight);
            errorOccurred = true;
        }
    }
    useEffect(() => {
        setIdealWeight(onboardingUser?.selfMealPlan?.idealWeight || '');
    }, [])

    useEffect(() => {
        if(SearchedValue.length > 0){ 
            if (onboardingUser?.selfMealPlan?.healthGoal === "weight-loss" && Number(SearchedValue) >= onboardingUser?.weight) {
                setIdealWeight(SearchedValue);
                if (!errorOccurred) {
                    if (toast.isActive(toastId.current)) {
                        toast.dismiss(toastId.current); // Dismiss previous error message
                    }
                    toastId.current = toast.error(
                        "Please enter a weight less than your current weight for weight loss goals."
                        );
                        errorOccurred = true;
                    }
                }
                
                else if (onboardingUser?.selfMealPlan?.healthGoal === "weight-gain" && Number(SearchedValue) <= onboardingUser?.weight) {
                    setIdealWeight(SearchedValue);
                    if (!errorOccurred) {
                        if (toast.isActive(toastId.current)) {
                            toast.dismiss(toastId.current); // Dismiss previous error message
                        }
                        toastId.current = toast.error(
                            "Please enter a weight greater than your current weight for weight gain goals."
                            );
                            errorOccurred = true;
                        }
                    }
                }
            },[SearchedValue])

    return (
        <PageTransition>
            <IdealWeightStyle>
                {isNewGoal && <CrossButton />}
                <div className="flex">
                    <Title title={"What is your ideal weight?"} subtitle={"Knowing your ideal weight helps us personalize your meal plan to achieve your health goals"} />
                    <div className="form_group">
                        <input id="weight" type="number" pattern="\d*" name="idealWeight" onChange={handleChange} value={idealWeight} className="form_control" onFocus={handleFocus} autoFocus={true}/>
                        <label for="weight">lbs</label>
                    </div>
                </div>
                <Button onBackClick={handleBack} onContinueClick={() => 
                    history.push(`/self-onboarding/active-status${isNewGoal === 'true' ? '?newGoal=true' : ''}`)} 
                    disabled={!idealWeight || 
                    (onboardingUser?.selfMealPlan?.healthGoal === "weight-gain" && Number(idealWeight) <= onboardingUser?.weight) || 
                    (onboardingUser?.selfMealPlan?.healthGoal === "weight-loss" && Number(idealWeight) >= onboardingUser?.weight)} 
                />
            </IdealWeightStyle>
        </PageTransition>
    )
}