import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const PrintOrderWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  font-family: ${theme.typography.fontGilroy};

  .print-title {
    padding: 32px 0 24px 63px;
    color: ${theme.colors.blackDefault};
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .print-buttons {
    padding: 12px 145px 12px 63px;
    border-top: 1px solid rgba(50, 64, 84, 0.1);
    border-bottom: 1px solid rgba(50, 64, 84, 0.1);
    display: flex;
    justify-content: space-between;
    .btn {
      height: 32px;
      font-family: ${theme.typography.fontGilroy};
      line-height: 16px;
      border: 1px solid ${theme.colors.primaryDark} !important;
      border-radius: 6px;
      &.secondary {
        width: 91px;
        font-size: 12px;
        span {
          white-space: nowrap;
        }
      }
      &::after {
        display: none;
      }
      &.icon_left {
        padding: 8px 16px 8px 8px;
        font-size: 12px;
        border: 0 !important;
      }
    }
  }

  .print-mode-wrapper {
    overflow-y: auto;
    height: calc(100vh - 146px);
    padding: 24px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @page {
    size: auto;
    margin: 20px 0 15mm;
  }
`;
