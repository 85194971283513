import { useEffect } from 'react';
import ErrorComponent from 'components/ErrorComponent';
import { FormContainer } from 'components/auth/StyledLoginForm';
import { useLocation } from 'react-router-dom';
import Progress from 'components/Progress';
import { useRequest } from 'hooks/useRequest';
import RegisterFormNutritionist from 'components/auth/RegisterFormNutritionist';
import NutrittLogo from 'layout/NutrittLogo';
import styled from 'styled-components';

const RegisterNutritionistStyle = styled.div`
  .header{
    background:#fff;
    height:70px;
    display:flex;
    align-items:center;
    padding-left:24px;
    @media(max-width:991px){
      height:52px;
    }
  }
  @media(min-width:992px){
    .container{
      padding:20px 0;
    }
  }
`

const RegisterNutritionist = () => {
  const params = new URLSearchParams(useLocation().search);
  const email = params.get('email');
  const nutritionistId = params.get('nutritionistId');
  const token = params.get('code');

  const [verifyNutritionistInvite, { isLoading: isInviteLoading, state, error: inviteError }] =
    useRequest(`nutritionist/verifyinvite`, 'post');

  useEffect(async () => {
    verifyNutritionistInvite({ body: { email, nutritionistId, token } });
  }, []);

  const [registerNutritionist, { isLoading, error, setError }] = useRequest(
    'nutritionist/register',
    'post',
  );

  if (isInviteLoading) return <Progress />;
  if (inviteError) return <ErrorComponent error={inviteError} />;

  if (state && !state.success) return <ErrorComponent error={'Invalid Invitation'} />;

  return (
    <RegisterNutritionistStyle>
      <div className='header'>
      <NutrittLogo/>
      </div>
      <div
        style={{
          minHeight: 'calc(100vh - 116px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
        }}
        className='container'
      >
        <FormContainer className='form-container'>
          <RegisterFormNutritionist
            register={registerNutritionist}
            isLoading={isLoading}
            error={error}
            setError={setError}
            nutritionistRegister={{ token, nutritionistEmail: email, nutritionistId }}
          />
        </FormContainer>
      </div>
    </RegisterNutritionistStyle>
  );
};


export default RegisterNutritionist;
