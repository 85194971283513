import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';


const CalenderStyle = styled.div`
.react-datepicker__day--outside-month{
    color:#E0E4E8 !Important;
}
     .react-datepicker-wrapper {
       display: flex;
       align-items: center;
     }
   &.notSelected .react-datepicker__month .react-datepicker__day--selected,
   &selected .react-datepicker__month .react-datepicker__day--today {
     // background-color: #f0faeb;
     color: #00092D;
     font-weight: 400;
   }
   .react-datepicker__month .react-datepicker__day.future-day {
     background-color: #00092D;
     color: #aaa;
   }
   .react-datepicker-wrapper {
     width: fit-content;
   }
   .current-date {
     color: #000000;
     font-size: 16px;
     font-family: Roboto;
     font-weight: 700;
     cursor: pointer;
   }
   .react-datepicker,
   .react-datepicker__month-container {
  
     font-family: "Helvetica Neue",helvetica,arial,sans-serif;
     font-size: .8rem;
     background-color: #fff;
     color: #000;
     border-radius: 0.3rem;
     display: inline-block;
    left: 360px;
    width: 250px;

    .react-datepicker__week{
        display:flex;
        height: 34px;
    }
   .react-datepicker__navigation {
     -webkit-align-items: center;
     align-items: center;
     background: none;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
     justify-content: center;
     text-align: center;
     cursor: pointer;
     position: absolute;
     top: 2px;
     padding: 0;
     border: none;
     z-index: 1;
     height: 41px;
     width: 23px;
     text-indent: -999em;
     overflow: hidden;
   }
   }
   .react-datepicker__navigation-icon--next {
     left: -10px;
   }
   .react-datepicker__navigation-icon--previous {
    left: 10px;
      justify-content: center;
   }
   .react-datepicker-popper {
     width: 300px;
     z-index: 100;

   
   }
   .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
     border-bottom-color: #fff;
   }

   
   .react-datepicker {
     border: 0;
     box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
     transform: translate3d(-18%, 36px, 0px);
     .react-datepicker__triangle::before,
     .react-datepicker__triangle::after {
        border-bottom-color: #fff;
       transform: translate3d(5px, 0px, 0px);
       visibility:hidden;
     }

     
   .react-datepicker__header {

     text-align: center;
     background-color: #fff;
     border-bottom: none;
     border-top-left-radius: 0.3rem;
     padding: 12px 0 0px;
     position: relative;
 }
     .react-datepicker__current-month {
       margin-top: 0;
       color: #000;
       font-weight: 700;
       font-size: .944rem;
     }
     .react-datepicker__day-names {
       margin: 0px 6px -15px 6px;
       padding-top: 14px;
       color: #757E89;
       font-size: 11px;
       font-weight: 400;
       line-height: 12px;
       display:flex;
     }
   }
   .react-datepicker__day-name {
     width: 32px;
     height: 25px;
     color: #757E89;
     margin: 5px;
   }
   .react-datepicker__navigation-icon::before {
     width: 8px;
     height: 8px;
    
   }
   .react-datepicker__navigation-icon::before {
     border-width: 2px 2px 0 0;
   }
   .react-datepicker__month {
     margin: 6px;
     .react-datepicker__day,
     .react-datepicker__day--disabled,
     .react-datepicker__day--keyboard-selected,
     .react-datepicker__day--selected {
        width: 25px;
        height: 24.59px;
        border-radius: 100px;
        margin: 5px;
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
      }
     .react-datepicker__day,
     .react-datepicker__day--selected.react-datepicker__day--outside-month,
     .react-datepicker__day--keyboard-selected {
  
    
     }
     .react-datepicker__day--outside-month {
     }
   }
   .react-datepicker__navigation--previous {
     left: auto;
     // right: 60px;
   }
 }
  .react-datepicker__year-text{
    color:000A2C;
    width: 25px;
    height: 24.59px;
    border-radius: 100px;
 }
 .react-datepicker__day--selected{
    background-color: #00092D;
 }
  .react-datepicker__quarter-text{
    color: #000A2C;
 }
 .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
    color: #E0E4E8;
 }
 .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color:#00092D;
 }
 .react-datepicker__day.react-datepicker__day--keyboard-selected {
    background: none;
    color: #00092D;
  }
`

const Calender = () => {
    const day = useMemo(() => {
        const formatStr = 'MMM DD, YYYY';
        const formattedDay = moment(new Date()).format(formatStr);
        // setActiveDay(activeDay);
        if (formattedDay === moment().format(formatStr)) {
          return 'Today';
        } else if (formattedDay === moment().subtract(1, 'days').format(formatStr)) {
          return 'Yesterday';
        } else {
          return formattedDay;
        }
      }, []);
      const ExampleCustomInput = <div className="current-date">{day}</div>;
    
  return (
    <CalenderStyle>
    <DatePicker
    selected={new Date()}
    formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
    customInput={ExampleCustomInput}
    />
    <img src="/icons/calendar.svg" alt="Calendar icon" className="calendar-icon" />
 </CalenderStyle>
)
}

export default Calender