import ProviderCartLines from './ProviderCartLines';

export default function ProviderGroups({ cart, providerSettings, taxRate }) {
  return providerSettings.map((provider) => {
    const cartByProvider = cart.filter((item) => item.providerId === provider.providerId);
    return (
      <ProviderCartLines
        key={provider.providerId}
        cart={cartByProvider}
        taxRate={taxRate}
        providerSettings={provider}
      />
    );
  });
}
