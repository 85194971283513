import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import PantryMeals from './PantryMeals';
import AlertAssignMeals from './AlertAssignMeals';
import { useAppContext } from 'context/appContext';
import OrganizeUserPantry from './OrganizeUserPantry';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { NutritionistContextProvider,useNutritionistContext } from '../../../context/nutritionistContext';


const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlanningMealsWrapper = styled.div`
  .drag-drop-navigator {
    position: relative;
    padding: 10px 0;
    display: flex;
    justify-content: center;

    .navigator-desc {
      z-index: 2;
      width: 85%;
      height: 28px;
      background: #f0faeb;
      border: 1px solid #97dc76;
      border-radius: 14px;
      ${FlexCenter};
    }

    p {
      margin-left: 5px;
      color: #51635d;
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.02em;
    }

    img:last-child {
      position: absolute;
      top: -3px;
      left: auto;
    }
  }

  .send-button-wrapper {
    display: flex;
    justify-content: center;

    .primary-btn {
      width: 150px;
      height: 42px;
      border-radius: 31px;
      font-size: 16px;
      font-family: 'Roboto';
      line-height: 19px;
      ${FlexCenter};
    }

    span {
      flex-shrink: 0;
    }

    img {
      margin-left: 10px;
    }
  }

  @media (min-width: 992px) {
    .send-button-wrapper .primary-btn {
      width: 200px;
      height: 62px;
    }

    .drag-drop-navigator {
      position: relative;
      padding: 0;

      .navigator-desc {
        position: absolute;
        top: -22px;
        left: auto;
        width: 220px;
      }

      img:last-child {
        top: -33px;
      }
    }
  }
`;

const getDefaultState = () => {
  var a = moment();
  var b = moment().add(7, 'days');
  const defaultState = {
    data: [],
  };

  for (var m = moment(a); m.isBefore(b); m.add(1, 'days')) {
    defaultState.data.push({ suggestedDate: m.format('YYYY-MM-DD'), orderLineIds: [], note: '' });
  }

  return defaultState;
};

export default function PlanningMeals({ setDiaryData }) {
  // const [pantryMeals, setPantryMeals] = useState([]);
   const {setCustomMealData, setPantryMeals,pantryMeals,customMealData,mealData} = useNutritionistContext();
  const { user, isSuper, isMobile } = useAppContext();
  const [pantryMealsData, setPantryMealsData] = useState([]);
  const [dailyMeals, setDailyMeals] = useState(getDefaultState());
  const [resetTooltip, setResetTooltip] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [previousDailyMeals, setPreviousDailyMeals] = useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [nutritionistPlanningId, setNutritionistPlanningId] = useState(null);
  const [createPlan] = useRequest('nutritionistPlanning/create', 'Post');
  const [updatePlan] = useRequest();
  const [getAvailablePlanningMeals] = useRequest();
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const nutritionistId = isSuper ? imitateNutritionist?.userId : user?.id;
  let params = useParams();
  const { userId } = params;  
  
  const getInitialData = (reqId) => {
    const getOfferListUrl = `nutritionistPlanning/getAvailablePlanningMeals?userId=${userId}&nutritionistId=${user.nutritionistProfile.id}`;
    getAvailablePlanningMeals({
      path: `${getOfferListUrl}`,
    }).then((templates) => {
      let data = [];
      templates?.pantryData?.acceptedOrder.forEach((item) => {
        let spreadedItems = [];
        for (let i = 0; i < item?.quantity || 0; ++i) {
          spreadedItems.push({ ...item, quantity: 1, uniqueKey: Math.random().toString(36).slice(2) });
        }
        data = data.concat(spreadedItems);
      });
      if(mealData.length == 0){
      setPantryMeals(data);
      }
      else{
        setPantryMeals(mealData.concat(data));
      }
    //   setCustomMealData(mealData)
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getInitialData(user.id);
    }, 2000);
  }, []);
  const dailyMealsPlan = [
    dailyMeals.data.map((item) => {
      return {
        suggestedDate: moment(item.suggestedDate).format('YYYY-MM-DD'),
        notes: item.notes,
        orderLineIds: item.orderLineIds ? item.orderLineIds : [],
      };
    }),
  ];
  const sendPlan = (e) => {
    e.preventDefault();

    const CreatePayload = {
      userId: userId,
      nutritionistId: user.nutritionistProfile.id,
      items: dailyMealsPlan[0],
    };
    // if (JSON.stringify(dailyMeals) !== JSON.stringify(previousDailyMeals) || nutritionistPlanningId ) {
    if (nutritionistPlanningId) {
      updatePlan({
        path: `nutritionistPlanning/updateNutritionistPlanning/${nutritionistPlanningId}`,
        method: 'put',
        body: {
          items: dailyMealsPlan[0],
        },
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    } else {
      createPlan({
        body: CreatePayload,
      })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
  };

  const reorder = (destinationId, startIndex, endIndex) => {
    if (destinationId !== 'pantry') {
      const result = Array.from(dailyMeals.data[destinationId].orderLineIds);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      const dailyMealsClone = { ...dailyMeals };
      dailyMealsClone.data[destinationId].orderLineIds = result;
      setDailyMeals(dailyMealsClone);
      return;
    }
  };

  const moveFromPantry = (destinationId, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(pantryMeals);
    const destClone = Array.from(dailyMeals.data[destinationId].orderLineIds);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    const destinationDate = dailyMeals?.data[destinationId].suggestedDate;
    if (
      (moment(destinationDate).isBefore(removed?.selectedDate) ||
        moment(destinationDate).isSame(removed?.selectedDate)) &&
      !removed?.isCustomMeal
    ) {
      setIsCompleted(true);
      return;
    }
    destClone.splice(droppableDestination.index, 0, removed);
    const dailyMealsClone = { ...dailyMeals };
    dailyMealsClone.data[destinationId].orderLineIds = destClone;

    setPantryMeals(sourceClone);
    setDailyMeals(dailyMealsClone);
  };

  const moveInDialyMeals = (sourceId, destinationId, droppableSource, droppableDestination) => {
    const dailyMealsClone = Array.from(dailyMeals.data);
    const [removed] = dailyMealsClone[sourceId].orderLineIds.splice(droppableSource.index, 1);
    const destinationDate = dailyMeals?.data[destinationId].suggestedDate;
    if (
      (moment(destinationDate).isBefore(removed?.selectedDate) ||
        moment(destinationDate).isSame(removed?.selectedDate)) &&
      !removed?.isCustomMeal
    ) {
      setIsCompleted(true);
      dailyMealsClone[sourceId].orderLineIds.splice(droppableSource.index, 0, removed);
      return;
    }
    dailyMealsClone[destinationId].orderLineIds.splice(droppableDestination.index, 0, removed);

    setDailyMeals({ ...dailyMeals, data: dailyMealsClone });
  };

  const moveToPantry = (sourceId, destinationId, droppableSource, droppableDestination) => {
    const destClone = Array.from(pantryMeals);
    const sourceClone = Array.from(dailyMeals.data[sourceId].orderLineIds);

    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const dailyMealsClone = { ...dailyMeals };
    dailyMealsClone.data[sourceId].orderLineIds = sourceClone;

    setPantryMeals(destClone);
    setDailyMeals(dailyMealsClone);
  };

  function onDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      setIsDrag(false);
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    if (destination.droppableId !== 'pantry') {
      sInd === dInd
        ? reorder(dInd, source.index, destination.index)
        : source.droppableId === 'pantry'
        ? moveFromPantry(dInd, source, destination)
        : moveInDialyMeals(sInd, dInd, source, destination);
    } else {
      source.droppableId !== 'pantry' && moveToPantry(sInd, destination.index, source, destination);
    }
    setIsDrag(false);
  }

  useEffect(() => {
    setDiaryData(previousDailyMeals);
  }, [previousDailyMeals]);

  return (
    <PlanningMealsWrapper>
      <form onSubmit={sendPlan}>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={() => {
            setResetTooltip(!resetTooltip);
            setIsDrag(true);
          }}
        >
          <PantryMeals
            setFilterMeals={setPantryMeals}
            filterMeals={pantryMeals}
            pantryMeals={pantryMealsData}
            resetTooltip={resetTooltip}
            setResetTooltip={setResetTooltip}
          />
          {isCompleted && <AlertAssignMeals setIsCompleted={setIsCompleted} />}
          <div className="drag-drop-navigator">
            <div className="navigator-desc">
              <img src="/icons/export.svg" alt="Export Icon" />
              <p>Drag 'n' drop meals to fit your plan</p>
            </div>
            <img src="/icons/dnd-arrow.svg" alt="DndArrow Icon" />
          </div>
          <OrganizeUserPantry
            userId={userId}
            nutritionistId={user.nutritionistProfile.id}
            dailyMeals={dailyMeals}
            setDailyMeals={setDailyMeals}
            setPreviousDailyMeals={setPreviousDailyMeals}
            setNutritionistPlanningId={setNutritionistPlanningId}
          />
        </DragDropContext>
        <div className="send-button-wrapper">
          <button className="primary-btn" type="submit">
            <span>Send plan</span>
            <img src="/icons/send-icon.svg" alt="Send Icon" />
          </button>
        </div>
      </form>
    </PlanningMealsWrapper>
  );
}


