import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Meal from './Meal';
import PantrySort from './PantrySort';
import PantrySearch from './PantrySearch';
import AddCustomMeal from './AddCustomMeal';
import { useAppContext } from 'context/appContext';
import { profile, pantryMealsDummy } from './PlanningData';
import { useParams } from 'react-router-dom';
import { useNutritionistContext } from '../../../context/nutritionistContext';

const FlexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PantryMealsWrapper = styled.div`
  padding: 20px 10px 10px;
  background: #f5f6f6;
  box-shadow: inset 1px 3px 7px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  .title {
    margin-bottom: 10px;
    color: #51635d;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;

    span:last-child {
      color: #52c41a;
    }
  }

  .search-sort-wrapper {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
  }

  .pantry-meals-wrapper {
    ${FlexBetween};

    .pantry-meals {
      overflow-x: auto;
      overflow-y: hidden;
      width: calc(100% - 40px);
      height: 70px;
      padding-bottom: 10px;

      div[data-rbd-droppable-id='pantry'] {
        display: flex;
      }

      ::-webkit-scrollbar {
        height: 5px;
      }
    }
  }

  .pantry-meals-containter {
    display: flex;
  }

  @media (min-width: 992px) {
    margin-bottom: 14px;
    padding: 24px 24px 15px;

    .search-sort-wrapper {
      margin-bottom: 24px;
      justify-content: space-between;
    }

    .title {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 19px;
    }

    .pantry-meals-wrapper {
      align-items: flex-end;

      .pantry-meals {
        width: calc(100% - 90px);
        height: 80px;
      }
    }
  }
`;

export default function PantryMeals({ filterMeals, setFilterMeals, resetTooltip, pantryMeals }) {
  const [value, setValue] = useState('');
  const [isRemove, setIsRemove] = useState(false);
  const [customMeal, setCustomMeal] = useState();
  const [searchedMeal, setSearchedMeal] = useState(filterMeals);
  const {customMealData,setCustomMealData} = useNutritionistContext();

  useEffect(()=>{
    setSearchedMeal(filterMeals);
  },[filterMeals]);
  const { userId } = useParams();
  const { activeClients } = useNutritionistContext();
  const userInfo = activeClients?.nutritionistMeeting?.filter((item) => item?.userId == userId)[0];
  // const profile = userInfo?.user?.profile;
  const { isMobile } = useAppContext();
  const [sortMethod, setSortMethod] = useState(isMobile ? 'SORT BY' : '');
  const [searchMeals, setSearchMeals] = useState(filterMeals);
  const sortLowToHigh = (property) => {
    const copyFilterMeals = cloneDeep(filterMeals);
    setFilterMeals(
      copyFilterMeals.sort((a, b) =>
        a.data[property] > b.data[property] ? 1 : b.data[property] > a.data[property] ? -1 : 0,
      ),
    );
  };

  const sortHighToLow = (property) => {
    const copyFilterMeals = cloneDeep(filterMeals);
    setFilterMeals(
      copyFilterMeals.sort((a, b) =>
        a.data[property] < b.data[property] ? 1 : b.data[property] < a.data[property] ? -1 : 0,
      ),
    );
  };

  useEffect(() => {
    switch (sortMethod) {
      case 'Calories: Low to High':
      case 'Low Calories':
        sortLowToHigh('calories');
        break;
      case 'Calories: High to Low':
      case 'High Calories':
        sortHighToLow('calories');
        break;
      case 'Fat: Low to High':
      case 'Low Fat':
        sortLowToHigh('fat');
        break;
      case 'Fat: High to Low':
      case 'High Fat':
        sortHighToLow('fat');
        break;
      case 'Protein: Low to High':
      case 'Low Protein':
        sortLowToHigh('protein');
        break;
      case 'Protein: High to Low':
      case 'High Protein':
        sortHighToLow('protein');
        break;
      default:
        filterMeals;
    }
  }, [sortMethod]);
  
  console.log('filterMeals pantry', filterMeals, pantryMeals);
  const handleChange = (e) => {
    setValue(e.target.value);
    e.target.value ? setIsRemove(true) : setIsRemove(false);
    const pantryMealsClone = Array.from(filterMeals);
    setSearchedMeal(
      pantryMealsClone.filter(
        (meal) => meal.data.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1,
      ),
    );
    if(!e.target.value){
      setSearchedMeal(filterMeals)
    }
  };

  useEffect(() => {
    if (customMeal) {
      setFilterMeals([
        {
          ...customMeal,
          isCustomMeal: true,
          isConsumed: false,
          quantity: 1,
          uniqueKey: Math.random().toString(36).slice(2),
        },
        ...filterMeals,
      ]);
      setCustomMealData([
        {
          ...customMeal,
          isCustomMeal: true,
          isConsumed: false,
          quantity: 1,
          uniqueKey: Math.random().toString(36).slice(2),
        },
        ...customMealData,
      ]);
    }
  }, [customMeal]);

  const pantryTilte = (
    <div className="title">
      <span>{profile?.name.split(' ')[0]}</span>’s pantry <span>/ {filterMeals.length}</span>
    </div>
  );

  return (
    <PantryMealsWrapper>
      {isMobile && pantryTilte}
      <div className="search-sort-wrapper">
        {!isMobile && pantryTilte}
        <PantrySearch
          value={value}
          handleChange={handleChange}
          isRemove={isRemove}
          setValue={setValue}
          setIsRemove={setIsRemove}
          setFilterMeals={() => setFilterMeals(pantryMeals)}
          filterMeals={filterMeals}
          setSearchedMeal={setSearchedMeal}
        />
        <PantrySort sortMethod={sortMethod} setSortMethod={setSortMethod} />
      </div>
      <div className="pantry-meals-wrapper">
        <div className="pantry-meals">
          <Droppable
            key="0"
            droppableId="pantry"
            index="0"
            className="pantry-column"
            direction="horizontal"
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="pantry-meals-containter">
                 {searchedMeal?.map((meal, index) => (
                    <Meal
                      meal={meal}
                      key={meal?.uniqueKey}
                      draggableId={meal?.uniqueKey}
                      index={index}
                      customMeal={customMeal}
                      resetTooltip={resetTooltip}
                      removeMeal={(meal) => {
                        setFilterMeals((prevState) =>
                          prevState.filter((item) => item.id !== meal.id),
                        );
                      }}
                      module="pantryMeals"
                    />
                  ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <AddCustomMeal customMeal={customMeal} setCustomMeal={setCustomMeal} />
      </div>
    </PantryMealsWrapper>
  );
}
