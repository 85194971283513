import React, { useEffect, useMemo, useState } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import KitchenNavigation from './components/KitchenNavigation';
import KitchenHeader from './components/KitchenHeader';
import styled from 'styled-components';
import { GilroyFontWrapper } from 'shared/theme/style';
import KitchenSidebar from './components/KitchenSidebar';
import { KitchenContextProvider } from 'context/kitchenContext';
import { MyProfile } from 'pages/shop/myProfile/MyProfile';
import { motion, AnimatePresence } from 'framer-motion';
import Login from './components/Login';
import Orders from 'pages/provider/storefront/orders/Orders';
import { Payouts } from 'pages/provider/storefront/payouts/Payouts';
import { useAppContext } from 'context/appContext';
import { ReactComponent as Close } from 'assets/kitchen/Close-cross.svg';
import Home from './views/Home';

const KitchenMobileStyle = styled.div`
  position: fixed;
  inset: 0;
  background-color: #fff;
  .items-popup{
    position: fixed;
    bottom: 84px;
    width:93%;
    background: #000A2C;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    transform: translateX(4%);
    padding: 9px 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    z-index:200;
    p{
      font-family: Gilroy;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
     }
  }
  .nav .nav_items.active {
    svg path{
      fill: #283241;
    }
    .nav_title{
      color: #283241;
    }
  }
`;

const KitchenMobile = () => {
  const [pageName, setPageName] = useState('');
  const [showShare, setShowShare] = useState(false);
  const [hamburgerIcon, setHamburgerIcon] = useState(false);
  const location = useLocation();
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const { user: {provider: {name}}, setCheckAutoLogin, checkAutoLogin, isWebView } = useAppContext();
  const bottomNavigationIncludedRoutes = ['/kitchen/orders', '/kitchen', '/kitchen/payouts'];
  const history = useHistory();
  const [windowHeight, setWindowHeight] = useState();
  const [isTransition, setIsTransition] = useState(false);

  const onBackClick = () => {
    history.goBack();
  }

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setPageName(name);
        break;
      case '/kitchen':
        setPageName(name);
        setShowShare(true);
        setHamburgerIcon(true);
        break;
      case '/kitchen/orders':
        setPageName('Orders');
        setShowShare(false);
        setHamburgerIcon(true);
        break;
      case '/kitchen/payouts':
        setPageName('Payouts');
        setShowShare(false);
        setHamburgerIcon(true);
        break;
        case '/kitchen/profile':
          setPageName('');
          setShowShare(false);
          setHamburgerIcon(false);
          break;
    
      default:
        setPageName(name);
        setShowShare(false);
        setHamburgerIcon(false);
        break;
    }
  }, [location]);

  const showNotification = useMemo(() => {
    const path = location.pathname;
    if (path === '/kitchen/profile') {
      return false;
    }
    return true;
  }, [location.pathname])
  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, [])

  useEffect(() => {
    if (checkAutoLogin) {
      setTimeout(() => {
        setCheckAutoLogin(false);
      }, 3000)
    }
  }, [checkAutoLogin])

  useEffect(() => {
    return () => {
      setIsTransition(false);
    };
  }, [location.pathname]);

  return (
    <>
      <KitchenContextProvider>
        <GilroyFontWrapper>
          <KitchenMobileStyle style={{height: windowHeight}}>
          <AnimatePresence>
            <motion.div
              initial= {{ x: 0 }}
              animate={isTransition ? { x: -100 } : {x: 0, transition: {duration: 0.3}}}
              transition={isTransition && {
                duration: 0.3,
                delay: 0,
                ease: 'linear',
              }}
              style={{height: '100%'}}
            >
            <KitchenHeader
              pageName={pageName}
              showShareIcon={showShare}
              showNotification={showNotification}
              setIsSidebarActive={setIsSidebarActive}
              hamburgerIcon={hamburgerIcon}
              onBackClick={onBackClick}
              isWebView={isWebView}
            />
            <AnimatePresence>
              {isSidebarActive && (
                <KitchenSidebar
                  isSidebarActive={isSidebarActive}
                  setIsSidebarActive={setIsSidebarActive}
                />
              )}
            </AnimatePresence>
            <Switch>
              <Route exact path={'/kitchen'}>
                <div style={{height: window.innerHeight - 118}}>
                <Home />
                {checkAutoLogin &&
                <div className='items-popup'>
                    <p>Password has been updated.</p>
                    <Close onClick={() => setCheckAutoLogin(false)}/>
                </div>
                }
                </div>
              </Route>
              <Route exact path={'/kitchen/orders'}>
                <Orders isTransition={isTransition} setIsTransition={setIsTransition} />
              </Route>
              <Route exact path={'/kitchen/profile'}>
                <MyProfile setIsSidebarActive={setIsSidebarActive}/>
              </Route>
              <Route exact path={'/kitchen/payouts'}>
                <Payouts />
              </Route>
              <Route exact path={'/kitchen/login'}>
                <Login />
              </Route>
            </Switch>
            {bottomNavigationIncludedRoutes.includes(location.pathname) && <KitchenNavigation />}
            </motion.div>
          </AnimatePresence>
          </KitchenMobileStyle>
        </GilroyFontWrapper>
      </KitchenContextProvider>
    </>
  );
};

export default KitchenMobile;