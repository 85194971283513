import { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useHistory, Redirect } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useShopContext } from 'context/shopContext';
import OrderSummary from 'components/shop/Checkout/OrderSummary';
import CheckoutHeader from 'components/shop/Checkout/CheckoutHeader';
import { useRequest } from 'hooks/useRequest';
import ErrorComponent from 'components/ErrorComponent';
import Edit from 'components/shop/Edit';
import { useAppContext } from 'context/appContext';
import ShopSteps from 'components/NutrittSteps/ShopSteps';
import ApplePayButton from './ApplePayButton';
import ReactTooltip from 'react-tooltip';

const CheckoutPaymentWrapper = styled.div`
  @media (max-width: 991px) {
    width: 90%;
    margin: 0 auto;
    padding: 23px 0 0;
    font-family: 'Roboto';
    font-style: normal;

    .payment-preview {
      padding: 20px 0;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);

      .ProviderPayment-container {
        margin-bottom: 25px;
        padding: 0 0 10px;
        border-bottom: 1px solid #51635d1a;
      }

      .container {
        width: 100%;
        padding: 0 20px;

        .provider-details-wrapper {
          margin-bottom: 15px;
        }
        
        .provider-details,
        .content {
          width: auto;
          color: #51635d;
          font-size: 12px;
          font-family: 'Roboto';
          line-height: 14px;
          text-transform: capitalize;
          font-weight: 700;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 4 !important ;
          -webkit-box-orient: vertical;
          white-space: normal ;
          p {
                    margin-bottom: 0;      
          }
        }
      }

      .provider-content .provider-title,
      .payment-wrapper h3 {
        margin-bottom: 20px;
        color: #0b1d17;
        font-size: 14px;
        font-family: 'Roboto';
        font-weight: 700;
        line-height: 17px;
      }

      .payment-wrapper {
        padding: 0;
        border-top: 0;

        h3 {
          color: #51635d;
        }

        .item-wrapper {
          margin-bottom: 15px;
          align-items: center;
        }

        .item-container {
          margin-right: 5px;
        }

        .edit-wrapper {
          align-self: flex-start;
        }
      }

      .title {
        width: 36%;
        color: #949d9b;
        font-size: 12px;
        line-height: 14px;
        flex-shrink: 0;
      }
    }

    .place-order-wrapper{
      button{
        width:287px !important;
        height:42px !important;
        font-weight:700 !important;  
      }
    }
    .__react_component_tooltip{
      width:190px;
      text-align:center;
      word-break:break-all;
   }
  }

  @media(min-width: 992px){
    background-color: #f6f5f8;
    .provider-details{
      font-weight: 700;
      font-size: 14px;
      color:#51635D !important;
      font-family: 'Roboto';
      font-style: normal;
      text-transform: capitalize !important;
    }

    .payment-wrapper{
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #E0E3E0;   
      padding:0px !important;
      padding-bottom: 25px !important;
      .container{
        h3{
          padding: 12px 0px 12px 24px;
          border-bottom:1px solid #E0E3E0;
          font-weight: 700;
          font-size: 16px;
          color: #0B1D17;
        }
        .item-wrapper{
          padding-left: 24px;
          .item-container{
            .content{
              color: #51635D;
              font-weight: 700;
              font-size: 14px;

            }
          }
        }
      }
    }
    .preview-form-wrapper{
      display: flex;
      gap: 24px;
      justify-content: center;

      .payment-preview{
        margin-left: 0% !important;
        background: #FFFFFF;
        width: 619px;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-bottom: 1px solid #E0E3E0;

        .ProviderPayment-container {
          border-bottom: 1px solid #ededed;
          padding-top: 0rem !important;
        }

        .payment-wrapper{
          border-top: 1px solid #ededed;
          border-bottom: 1px solid #E0E3E0;   
          padding: 0px 24px;
        }

        .paymentBtn-wrapper{
          display: flex;
          flex-direction: row;
          gap: 14px;
          padding: 28px;

          .editBtn{
            color: #51635D;
            border: 1px solid #97DC76 !important;
            border-radius: 27px;
            background: #FFFFFF;
          }

          button{
            width: 250px;
            height: 53px;
            background: #52C41A;
            border-radius: 28px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }

      .order-summary{
        width: 430px;
        height: 307px;
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      }
    } 
    
    .__react_component_tooltip{
      width:300px;
      text-align:center;
      word-break:break-all;
      //left:350px !important;
   }
  }

  .provider-details .fullname {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const CheckPaymentStyle = styled.div`
  // padding: 0px 0 40px;
  padding: 0px 0 75px;
  .ProviderPayment-container {
    color: #0b1c18;
    border-bottom: 4px solid #ededed;
    padding-top: 1rem;
  }
  .container {
    width: 90%;
    display: flex;
    justify-content: space-between;

    .provider-content {
      width: 90%;
    }

    .provider-title {
      margin-bottom: 25px;
      color: #747c7a;
      font-size: 16px;
      font-weight: 600;
    }

    .provider-details-wrapper {
      margin-bottom: 24px;
      font-size: 14px;
      display: flex;
    }

    .provider-details {
      width: 75%;
      color: #000000;
      text-transform: uppercase;

      p {
        margin-bottom: 7px;

        span {
          margin-bottom: 3px;
          display: block;
        }
      }

      .date {
        margin-right: 10px;
      }
    }
  }

  .title {
    width: 25%;
    color: #747c7a;
  }

  .payment-wrapper {
    padding: 18px 0;
    color: #0b1c18;
    border-top: 4px solid #ededed;

    h3 {
      margin-bottom: 25px;
      color: #747c7a;
      font-size: 16px;
      font-weight: 600;
    }

    .container {
      flex-direction: column;
    }

    .item-wrapper {
      margin-bottom: 24px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .item-container {
      width: 90%;
      display: flex;
    }

    .content {
      width: 75%;
      color: #000000;
    }
  }

  @media(min-width:992px){
    .container{
      width: 100%;

      .provider-content{
        width: 100%;
      }
      
      .provider-title{
        padding: 9px 24px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #0B1D17;
      }
      .provider-details-wrapper {
        padding: 0px 24px;
        .title{
          font-weight: 400;
         font-size: 14px;
         color: #949D9B;
        }
      } 
    }

    .provider-title{
      border-bottom: 1px solid #ededed;
    }  }
`;

const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function CheckPayment() {
  const history = useHistory();
  const { settings, cart, cartCount, paymentInfo, setPaymentInfo, promoCodeInfo, setPromoCodeInfo} =
    useShopContext();  
  const { userInfo, providerSettings } = settings;
  const [createOrder, { isLoading, error }] = useRequest('order/create', 'post');
  const { isMobile, user, isGuest, isVisitor,setCustomQtyMeal } = useAppContext();
  const paymentMethod =  JSON.parse(localStorage.getItem('method'));
 // const loadCart = JSON.parse(localStorage.getItem('loadCart'));

  
  useEffect(() => {
    if (!paymentInfo.cardNumber && paymentInfo.method === 'credit') {
      history.push('/shop/checkout/order');
    }
  }, []);
  const afterOrderSuccess = (orderSuccess) => {
    window.analytics.identify(user.id, {
      firstName: userInfo.firstName,
      lastName:userInfo.lastName,
      email: user.email,
     phone:orderSuccess?.mainOrder?.phoneNumber,
    lifecycle:'madepurchase '
    });
    history.push(`/shop/checkout/thanks`);
    localStorage.removeItem('steps');
  }
  const submitOrder = async () => {
    const items = cart.map(({ id, qty, isCombo, components, providerId }) => {
      let selectionIds = [];
      if (isCombo) selectionIds = components.map(({ id }) => id);
      return {
        mealId: id,
        qty,
        isCombo,
        selectionIds,
        providerId,
      };
    });
    const paymentInfoData = { ...paymentInfo };
    for (let key in paymentInfoData) {
      if (paymentInfoData[key]?.replace) {
        paymentInfoData[key] = paymentInfoData[key]?.replace(/\s/g, '');
      }
    }
    paymentInfoData.cardNumber = paymentInfoData.cardNumber;
    paymentInfoData.cvv = paymentInfoData.cvv;
    paymentInfoData.expiryDate = paymentInfoData.expiryDate;
    console.log({ paymentInfoData });
    const orderSuccess = await createOrder({
      body: {
        items,
        paymentInfo: paymentInfoData,
        userInfo,
        providerSettings: cloneDeep(providerSettings).map((item) => {
          delete item.schedule;
          delete item.deliverableAreas;
          return item;
        }),
        // pCode: promoCodeInfo?.promoCode
        promoCodeInfo: {
          promoCode: promoCodeInfo?.promoCode
        }
      },
    });
    setPaymentInfo({});
    setPromoCodeInfo({ promoCode: "", subTotal: "" });
    setCustomQtyMeal(false);
    if (orderSuccess) {
      afterOrderSuccess(orderSuccess);
    }
  };

  const cartData = cart.map((i) => i.providerId);
  const providerInCart = [...new Set(cartData)];
  const provider = providerSettings.filter((i) => providerInCart.includes(i.providerId));

  const ProviderPayment = ({ index }) => {
    const thisPro = provider[index];
    let providerIndex = providerSettings.findIndex((i) => i.providerId === thisPro.providerId);
    const thisProvider = providerSettings[providerIndex];
    const fName = userInfo?.firstName;
    const lName =  userInfo?.lastName;

    useEffect(()=>{
      ReactTooltip.rebuild();
    },[])

    return (
      <>
      <div className="container">
        <div className="provider-content" data-testid="custom-provider">
          <div className="provider-title">{thisProvider.name}</div>
          <div className="provider-details-wrapper">
            <div className="title">Method</div>
            <div className="provider-details">{thisProvider.method}</div>
          </div>
          {thisProvider.method === 'delivery' && (
            <div className="provider-details-wrapper">
              <div className="title">Address</div>
              <div className="provider-details provider-details-info">
                {/* <div className='fullname'>{fName.length > 15 ? <p data-tip={fName} data-for="name" style={{cursor: 'pointer'}}>{ fName.substring(0, 15) + "..."  }</p> : <p>{fName}</p>} 
                 {lName.length>10 ? <p data-tip={lName} data-for="lname" style={{cursor: 'pointer'}}>{lName.substring(0, 10) + "..." }</p> : <p>{lName}</p>} */}
                <div className='fullname'>{fName} {lName}</div>
                <p>
                  <span>{`${userInfo.address},`}</span>
                  <span>{`${userInfo?.address2 ?? ''} , ${userInfo?.city ?? ''}, ${userInfo?.state ?? ''}, ${
                    userInfo?.zipcode ?? ''
                  }`}</span>
                </p>
                <p>{userInfo.phone}</p>
              </div>
            </div>
          )}
          {thisProvider.method === 'pickup' && (
            <div className="provider-details-wrapper">
              <div className="title">Address</div>
              <div className="provider-details provider-details-info">
                <p>
                  <span>{thisProvider.address}</span>
                </p>
              </div>
            </div>
          )}
          <div className="provider-details-wrapper">
            <div className="title">Time</div>
            <div className="provider-details" data-testid="custom-date">
              <span className="date">{`${moment(providerSettings[index].date).format('MMM DD, dddd')}, ${providerSettings[index].time}`}</span>
            </div>
          </div>
          <div className="provider-details-wrapper">
            <div className="title">Phone Number</div>
            <div className="provider-details">{userInfo.orderPhone}</div>
          </div>
          <div className="provider-details-wrapper">
            <div className="title">Note</div>
            <div className="provider-details">{thisProvider.note || 'None'}</div>
          </div>
        </div>
        {isMobile &&
        <Edit link="/shop/checkout/order#checkoutOrder" />
        }
      </div>
      <ReactTooltip id="name" place="top" effect="solid">
     </ReactTooltip>
     <ReactTooltip id="lname" place="top" effect="solid">
     </ReactTooltip>
      </>
    );
  };
  return (
    <CheckoutPaymentWrapper>
      {isMobile ? (
        <ShopSteps
          title="Checkout"
          description="Congrats on a perfect plan!"
          activeStep={3}
          steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
          perviousLink="/shop/checkout/order"
        />
      ) : (
        <CheckoutHeader path={`/shop/checkout/order`} title="Checkout" description="Congrats on a perfect plan!" />
      )}
      <CheckPaymentStyle>
        <div className="preview-form-wrapper">
        <div className="payment-preview">
          {provider.map((p, i) => (
            <div className="ProviderPayment-container">
              <ProviderPayment index={i} key={p.id} />
            </div>
          ))}
          <div className="payment-wrapper" data-testid="custom-payment">
            <div className="container">
            <h3>Payment</h3>
              <div className="item-wrapper">
                <div className="item-container">
                  <div className="title">Promo Code</div>
                  <div className="content">
                    {promoCodeInfo?.promoCode || "NA"}
                  </div>
                </div>
                {isMobile &&
                <div className="edit-wrapper">
                 <Edit link="/shop/checkout/order#paymentDetails" />
                </div>
                }
              </div>
              <div className="item-wrapper">
                <div className="item-container">
                  <div className="title">Card</div>
                  <div className="content">
                    {paymentInfo.payment} Ending with *
                    {paymentInfo?.cardNumber?.replace(/ +/g, '')?.substr(-4)}
                  </div>
                </div>
                {isMobile &&
                <div className="edit-wrapper">
                 <Edit link="/shop/checkout/order#paymentDetails" />
                </div>
                }
              </div>
              <div className="item-wrapper">
                <div className="item-container">
                  <div className="title">Address</div>
                  <div className="content">{paymentInfo.billingAddress}</div>
                </div>
                {isMobile &&
                <div className="edit-wrapper">
                <Edit link="/shop/checkout/order#billingAddress" />
                </div>
                }
              </div>
            </div>
          </div>
          {!isMobile &&
          <div className="paymentBtn-wrapper">
            <button 
             className="editBtn"
             onClick={() => history.push('/shop/checkout/order')}
             >
              Edit payment info
            </button>
            { paymentMethod == 'apple pay' ?  
          <ApplePayButton afterOrderSuccess = {afterOrderSuccess} /> : 
          <button type="button" onClick={submitOrder} disabled={isLoading}>
            {isLoading ? 'Processing...' : 'CONFIRM & PAY'}
          </button> 
         }
            {error && <ErrorComponent error={error} />}
          </div>    
          }
        </div>
        <div className="order-summary">
        {!isMobile &&
        <OrderSummary />
        }
        </div>
        </div>
        {isMobile &&
        <OrderSummary />
        }
        {isMobile &&
        <div className="place-order-wrapper">

          { paymentMethod == 'apple pay' ?  
          <ApplePayButton afterOrderSuccess = {afterOrderSuccess} /> : 
          <button type="button" onClick={submitOrder} disabled={isLoading}>
            {isLoading ? 'Processing...' : 'Confirm & pay'}
          </button> 
         }
          {error && <ErrorComponent error={error} />}
        </div>
         }
      </CheckPaymentStyle>
    </CheckoutPaymentWrapper>
  );
}


