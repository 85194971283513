import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import Nutrition from './Nutrition';
import tagIcons from 'components/TagIcons';
import MealTag from 'components/shop/MealTag';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import { Calories } from 'components/shop/SharedComponents/Calories';
import { DEFAULT_ACTIVITY,BMR_FORMULAS, BMR_FORMULA_VARS  } from '../../../constants';
import { useMemo } from 'react';

const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    marginTop: '30px'
  },
})(Tooltip);


const FlexColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const MoreUserDetailsWrapper = styled.div`
  width: 100%;
  ${FlexColumnCenter};

  .no-details{
    display:flex;
    justify-content:center;
  }

  .more-details{
    width: 100%;
    display: grid;
    grid-template-columns: 30% 67%;
    column-gap:3%;
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
  }
  .descriptions{
    display:flex;
    flex-direction:column;
    .plan-desc{
    display:flex;
    flex-direction:column;
    padding: 30px 10px 18px 30px;
    border-bottom: 1px solid #D9D9D9;
    .desc-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
       // padding: 30px 0px 10px 30px;
    }
    .desc{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #51635D;
        margin-top: 10px;
    }
    }
  }

  .lose-days-wrapper {
    padding: 40px 0px 0px 40px;
  }

  .more-title {
    margin-bottom: 24px;
    color: #51635d;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 19px;
    text-align: center;

    &.lose-days + div {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }

  .food-recommanded-wrapper {
    width: 100%;
    padding-top:14px;
  }

  .eat-avoid-wrapper {
    border-bottom: 1px solid #D9D9D9;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom:15px;
    .food-wrapper {
      padding-left:30px;
      ${FlexColumnCenter};
      padding-top:13px;

      &:first-child .images-container {
        justify-content: flex-end;

      }

      & + .food-wrapper {
        border-left: 1px solid #e0e3e0;
      }

      .food-title {
        margin-bottom: 11px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
      }
    }
    .tags-container{
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 20px;
      row-gap: 5px;
      .image-tag{
        display:flex;
        gap:10px;
        span{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #51635D;
        }
      }
    }
    .images-container {
        display: grid;
        grid-template-columns: auto auto;

      @media (max-width:992px){
         display: grid;
         grid-template-columns: auto auto auto;
         gap: 5px;
      }

      .image-wrapper {
        width: 24px;
        height: 24px;
        margin: 0 5px 5px 0;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .tips-desc{
    display:flex;
    flex-direction:column;
    padding: 30px 10px 18px 30px;
    .tips-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
       // padding: 30px 0px 10px 30px;
    }
    .tips{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #51635D;
        margin-top: 10px;
    }
    }
  .tables{
    background:#ffffff;
    width:100%;
    display:grid;
    grid-template-columns:29% 69%;
    column-gap:2%;
    padding:20px;
    .diet-formula{
      border:1px solid #E0E3E0;
      display:grid;
      grid-template-columns:auto auto;

      .diet{
        text-align:center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        justify-content:center;
        align-items:center;
        color: #51635D;
        background:#F0FAEB;
        padding: 15px 0px 15px;
        border-bottom: 1px solid #E0E3E0;
      }
      .formula{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align:center;
        display: flex;
        align-items: center;
        justify-content:center;
        color: #51635D;
        padding: 15px 0px 15px;
        border-bottom: 1px solid #E0E3E0;
      }
    }
    .activity{
      display:flex;
      flex-direction:column;
      border:1px solid #E0E3E0;
      .gender{
        display:flex;
        border-bottom:1px solid #E0E3E0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #51635D;
      }
      
      .male, .female{
        display:flex;
        border-bottom:1px solid #E0E3E0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #51635D;
      }
      .gender,.male,.female{
        width:100%;
        height:46px;
        div:nth-of-type(odd) {
          background: #F6F5F8;
      }
      div:first-child{
        width:13%;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      div:nth-child(2){
        width:11%;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      div:nth-child(3){
        width:19%;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
      }
      div:nth-child(4){
        width:11%;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      div:nth-child(5){
        width:23%;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
      }
      div:nth-child(6){
        width:23%;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
      }

      }
     
    }
  }
  .templates{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #949D9B;
    display:flex;
    justify-content:center;
    width:100%;
    padding-bottom:20px;
    
  }
  .formula1{
    p::first-letter {
      text-transform:capitalize;
  }
  }
  @media (min-width: 992px) {
    .lose-days-wrapper {
      //padding: 30px 10px;
    }

    .eat-avoid-wrapper {
     
    }

    .food-wrapper {
     

      .icon-wrapper {
        display: flex;

        svg {
          width: 14px;
          font-size: 14px;
        }
      }
    }
  }

  @media (min-width:1120px) and (max-width:1250px){
    .image-tag{
      span{
        font-size:14px !important;
      }
    }
  }
  @media (min-width:1070px) and (max-width:1120px){
    .image-tag{
      span{
        font-size:13px !important;
      }
    }
  }
  @media (min-width:992px) and (max-width:1070px){
    .image-tag{
      span{
        font-size:11px !important;
      }
    }
  }
  @media (min-width:992px) and (max-width:996px){
    .image-tag{
       display:flex;
       gap:1px !important;
    }
  }
  @media (min-width:996px) and (max-width:1300px){
    .image-tag{
       display:flex;
       gap:5px !important;
    }
  }
  @media (min-width:280px) and (max-width:300px){
      .tag-name{
        font-size:10px !important;
      }
      .tables{
        .activity{
          .gender, .male, .female{
            p{
              padding: 10px 0 10px 6px !important;
            }
          }
        }
      }
  }

  @media(max-width:991px){
    .no-details{
      display: flex;
      flex-direction: column !important;
      align-items:center;
    }
     .more-details{
      display: flex !important;
      flex-direction: column;
      padding-bottom: 10px;

      .tags-container{
        row-gap:10px !important;
      }

      .lose-days-wrapper{
        padding: 35px 0 0 0 !important;
        border-bottom: 1px solid #D9D9D9;
      }
      .plan-desc{
        border-bottom:none !important;
        .desc{
          text-align: justify;
        }
      }
      .food-recommanded-wrapper{
        padding-top:0 !important;
        .eat-avoid-wrapper{
           display:flex !important;
           flex-direction:column;
           border-bottom:none !important;
           padding-bottom:0 !important;

           .food-wrapper{
            & + .food-wrapper {
              border-left:none !important;
              padding-top:20px !important;
            }
           }

           .image-tag{
            gap:0 !important;
           .tag-name{
             display:flex;
             align-items:center;
           }
          }

           .image-wrapper{
            width: 32px !important;
            height: 32px !important;
            margin:0 !important;

            svg{
              width:18px;
            }
            span{
              display:flex;
              justify-content:center;
              align-items:center;
            }

           }
        }
      }

    }

    .tables{
      display: flex !important;
      flex-direction: column;
      row-gap: 15px;
      padding:15px !important;
      margin-top:10px;

      .diet-formula{
        display: grid;
        grid-template-columns: 45% 55% !important;
        .diet{
          display:flex;
          justify-content:flex-start !important;
          padding:15px 0 15px 10px !important;
        }
        .diet ~ .diet{
          border-bottom:none !important;
        }
        .formula{
          display:flex;
          justify-content:flex-start !important;
          padding:15px 0 15px 10px !important;
        }
        .formula ~ .formula{
          border-bottom:none !important;
        }
      }
      .activity{
        display:grid !important;
        grid-template-columns: auto auto auto;
      
        .gender, .male, .female{
          display:flex;
          flex-direction:column !important;
          height:auto !important;
          font-size: 14px !important;
          line-height: 16px !important;
          p{
           padding:10px 0 10px 14px;
          }
        }
        div:first-child{
          width:100% !important;
          justify-content:flex-start !important;
        }
        div:nth-child(2){
          width:100% !important;
          justify-content:flex-start !important;
        }
        div:nth-child(3){
          width:100% !important;
          justify-content:flex-start !important;
        }
        div:nth-child(4){
          width:100% !important;
          justify-content:flex-start !important;
        }
        div:nth-child(5){
          width:100% !important;
          justify-content:flex-start !important;
        }
        div:nth-child(6){
          width:100% !important;
          justify-content:flex-start !important;
        }
      }
    }

    .templates{
      p{
        width: 85%;
        text-align: center;
      }
    }

  }
`;


export default function NutritionistMoreDetails({
  carbs,
  fat,
  protein,
  introduction,
  tags,
  food_to_avoid,
  tips,
  diet_formula,
  PAL,
  goal,
  goal_type,
}) {
  const calculateDisplayUnits = useMemo(() => {
     if (PAL) {
      const displayUnits = {
        men: {
          inactive: PAL.men.inactive,
          slightly: PAL.men.slightly,
          active: PAL.men.active,
          moderately: PAL.men.moderately,
          extremely: PAL.men.extremely
        },
        women: {
          inactive: PAL.women.inactive,
          slightly: PAL.women.slightly,
          active: PAL.women.active,
          moderately: PAL.women.moderately,
          extremely: PAL.women.extremely
        }
      };
      return displayUnits;
    }  else {
      return DEFAULT_ACTIVITY.PAL;
    }
  }, [PAL, diet_formula]);
  
  return (
    <MoreUserDetailsWrapper>
        <div className='more-details'>
      <div className="lose-days-wrapper">
        {/* <div className="more-title lose-days">
          {profile?.dietTemplate?.name.charAt(0).toUpperCase() +
            profile?.dietTemplate?.name.slice(1).toLowerCase()}
        </div> */}
        <Nutrition
          calories="Macronutrient breakdown"
          carbs={carbs}
          fat={fat}
          protein={protein}
        />
      </div>
      <div className='descriptions'>
     { introduction &&   <div className='plan-desc'>
      <div className='desc-text'>
        <p>Description</p> 
      </div>
      <div className='desc'>
        <p>{introduction}</p>
      </div>
      </div>}
      <div className="food-recommanded-wrapper">
      {tags.length>0 || food_to_avoid.length>0 ?  <div className="eat-avoid-wrapper">
          <div className="food-wrapper" style={{paddingRight:'10px'}}>
            { tags.length>0 && <span className="food-title">Food recomended</span>}
            <div className="tags-container">
                  {tags.map((tag, index) => (
                    // <StyledTooltip title={tag} key={index} arrow>
                    <div className='image-tag'>
                    <div className='images-container'>
                    <div className="image-wrapper" key={index}>
                      <MealTag Icon={tagIcons[tag]}/>
                      </div>
                      </div>                
                      <span className='tag-name'>{tag}</span>
                     </div> 
                   
                  // </StyledTooltip>
                  ))}    
            </div>
          </div>

          <div className="food-wrapper">
           {food_to_avoid.length>0 &&  <span className="food-title">Food to avoid</span>}
            <div className="tags-container">
                  {food_to_avoid.map((tag, index) => (
                  //  <StyledTooltip title={food} key={index} arrow>
                  <div className='image-tag'>
                  <div className='images-container'>
                      <div className="image-wrapper" key={index}>
                        <MealTag Icon={tagIcons[tag]}/>
                      </div>
                    </div>
                      <span className='tag-name'>{tag}</span>
                      </div>
                //    </StyledTooltip>
                  ))}
            </div>
          </div>
        </div>
      :
       "" 
      }   
    {tips && <div className='tips-desc'>
      <div className='tips-text'>
        <p>{tips? 'Tips' : ''}</p>
      </div>
      <div className='tips'>
        <p>{tips}</p>
      </div>
      </div>}
      </div>
      </div>
      </div>

      <div className='tables'>
       <div className='diet-formula'>
        <div className='diet'>
          <p >Diet formula:</p>
        </div>
        <div className='formula formula1'>
          <p>{diet_formula === "harrisBenedict" ? "Harris Benedict 1919" : diet_formula === "mifflinJeor" ? "Mifflin - St. Jeor 1990" : diet_formula === "instituteOfMedicine" ? "Institute of Medicine" : diet_formula}</p>
          </div> 
        <div className='diet'>
          <p>Target Calories:</p>
          </div> 
        <div className='formula'>
        <p>{goal_type === 'percent' ? diet_formula.trim().toLowerCase() === "instituteofmedicine" 
            ? `${(goal / 100) + 1 + ' * EER'}` : `${((goal / 100) + 1).toFixed(2) + ' * BMR * PAL'}` 
            : diet_formula.trim().toLowerCase() === "instituteofmedicine" ? goal >= 0 ? `${'EER + ' + goal}` : `${'EER - ' + Math.abs(goal)}` : goal >= 0 ? `${'BMR * PAL + ' + goal}` : `${'BMR * PAL - ' + Math.abs(goal)}`}</p>
          </div> 
       </div>
       <div className='activity'>
        <div className='gender'>
        <div className=''><p >Sex</p></div>
        <div className=''><p>Inactive</p></div>
        <div className=''><p>Slightly active</p></div>
        <div className=''><p>Active</p></div>
        <div className=''><p>Moderately active</p></div>
        <div className=''><p>Extremely active</p></div>
        </div>

        <div className='male'>
        <div className=''><p >Male</p></div>
        <div className=''><p>{calculateDisplayUnits.men.inactive}</p></div>
        <div className=''><p>{calculateDisplayUnits.men.slightly}</p></div>
        <div className=''><p>{calculateDisplayUnits.men.active}</p></div>
        <div className=''><p>{calculateDisplayUnits.men.moderately}</p></div>
        <div className=''><p>{calculateDisplayUnits.men.extremely}</p></div>
        </div>

        <div className='female'>
        <div className=''><p>Female</p></div>
        <div className=''><p>{calculateDisplayUnits.women.inactive}</p></div>
        <div className=''><p>{calculateDisplayUnits.women.slightly}</p></div>
        <div className=''><p>{calculateDisplayUnits.women.active}</p></div>
        <div className=''><p>{calculateDisplayUnits.women.moderately}</p></div>
        <div className=''><p>{calculateDisplayUnits.women.extremely}</p></div>
        </div>
       </div>
      </div>

      <div className='templates'>
        <p>These basic templates help calculate general intake.  please speak with your physician before starting any new diet </p>
      </div>
     
    </MoreUserDetailsWrapper>
  );
}
