import styled from 'styled-components';
import Tags from 'pages/Nutritionist/Followers/ClientsCart/Tags';
import { useState } from 'react';
import { useAppContext } from 'context/appContext';

const TagsModalStyle = styled.div`
    width: 100%;
    padding: 14px 20px 30px;
    flex: 1;

    .title{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color:#51635D;
        margin-bottom: 48px;
    }
   
`

const TagsContainer = styled.div`
    margin: 32px 0px 0px;
    padding-bottom:30px;
    display: grid;
    grid-template-columns: repeat(4,minmax(77px,1fr));
    grid-row-gap: 25px;
    &:last-child{
        border-bottom:1px solid #E0E3E0;
    }
`;

export default function TagsModal({ title, tags, setShowSelectedTags, showSelectedTags, type, setProfile }) {

    const [selectedTags, setSelectedTags] = useState({ tags: [], restrictions: [] });
    const {profile: {dietTemplateId, selfMealPlan}, profile} = useAppContext();
    console.log('profile', profile);

    const handleTagClick = (selectedTag, isSelected, recomend) => {
        setSelectedTags((m) => ({
            ...m,
            tags: isSelected
                ? m.tags?.filter((tag) => tag !== selectedTag)
                : [...m.tags, selectedTag],
        }));

        setShowSelectedTags((prev) => ({
            ...prev,
            tags: isSelected ? prev.tags?.filter((tag) => tag !== selectedTag) : [...prev.tags, selectedTag]
        }))

        
        setProfile((prev) => {
            if (prev?.selfMealPlan && !prev?.dietTemplateId) {
                return {
                    ...prev,
                    selfMealPlan: {
                        ...prev.selfMealPlan,
                        dietaryPreferences: isSelected ? prev.selfMealPlan?.dietaryPreferences.filter((tag) => tag !== selectedTag) : [...prev.selfMealPlan?.dietaryPreferences, selectedTag]
                    }
                }
            }else{
                return {
                    ...prev,
                    dietTemplate: {
                        ...prev.dietTemplate,
                        tags: isSelected ? prev.dietTemplate?.tags.filter((tag) => tag !== selectedTag) : [...prev.dietTemplate?.tags, selectedTag]
                    }
                }
            }
        })
    };

    const handleAlergyClick = (selectedTag, isSelected, avoid) => {
        setSelectedTags((m) => ({
            ...m,
            restrictions: isSelected
                ? m.restrictions?.filter((tag) => tag.toUpperCase() !== selectedTag.toUpperCase())
                : [...m.restrictions, selectedTag],
        }));

        setShowSelectedTags((prev) => ({
            ...prev,
            restrictions: isSelected ? prev.restrictions?.filter((tag) => tag.toUpperCase() !== selectedTag.toUpperCase()) : [...prev.restrictions, selectedTag]
        }))

        setProfile((prev) => {
            if (prev?.selfMealPlan && !prev?.dietTemplateId) {
                return {
                    ...prev,
                    selfMealPlan: {
                        ...prev.selfMealPlan,
                        allergies: isSelected ? prev.selfMealPlan?.allergies.filter((tag) => tag.toUpperCase() !== selectedTag.toUpperCase()) : [...prev.selfMealPlan?.allergies, selectedTag]
                    }
                }
            }else{
                return {
                    ...prev,
                    dietTemplate: {
                        ...prev.dietTemplate,
                        food_to_avoid: isSelected ? prev.dietTemplate?.food_to_avoid.filter((tag) => tag.toUpperCase() !== selectedTag.toUpperCase()) : [...prev.dietTemplate?.food_to_avoid, selectedTag]
                    }
                }
            }
        })
    };

    const onTagClick = (tag) => {
        if (type === 'preferences') {
            const isSelected = showSelectedTags?.tags?.indexOf(tag) !== -1;
            const recomend = showSelectedTags?.tags?.indexOf(tag) !== -1;
            handleTagClick(tag, isSelected, recomend)
        } else {
            const isSelected = showSelectedTags?.restrictions?.indexOf(tag) !== -1;
            const avoid = showSelectedTags?.restrictions?.indexOf(tag) !== -1;
            handleAlergyClick(tag, isSelected, avoid)
        }
    }


    return (
        <TagsModalStyle>

            <h2 className='title'>{title}</h2>

            <TagsContainer>
                {tags?.map((tag, index) => (
                    <Tags tag={tag} key={index} onTagClick={() => onTagClick(tag)}
                        selectedTags={type === 'preferences' ? selectedTags?.tags : selectedTags?.restrictions}
                        isSelected={type === 'preferences' ? showSelectedTags?.tags?.indexOf(tag) !== -1 : showSelectedTags?.restrictions?.indexOf(tag) !== -1}
                        recomend={showSelectedTags?.tags?.indexOf(tag) !== -1}
                        avoid={showSelectedTags?.restrictions?.indexOf(tag) !== -1}
                        type={type === 'preferences' ? 'preference' : 'avoid'}
                    />
                ))}
            </TagsContainer>
        </TagsModalStyle>
    )
}