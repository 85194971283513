import { memo } from 'react';
import { MobileHeaderWrapper } from './style';
import { ReactComponent as AngleLeftBlack } from "assets/shop/angle-left-black.svg";


function MobileHeader({ className, text, onClickLeft, onClickRight, pageNumber, onClickClose, onCloseRight }) {
  return (
    <MobileHeaderWrapper className={className}>
      {onClickClose && (
        <div className="icon-wrapper close" onClick={(e) => onClickClose(e)}>
          <img src="/icons/close-black.svg" alt="Close icon" />
        </div>
      )}
      {onClickLeft && (
        <div className="icon-wrapper left" onClick={onClickLeft}>
          <AngleLeftBlack alt="Left icon" />
        </div>
      )}
      <div className="header-text">{text}</div>
      {onClickRight && (
        <div className="icon-wrapper right" onClick={onClickRight}>
          <img src="/icons/angle-right-black.svg" alt="Right icon" />
        </div>
      )}
      {onCloseRight && (
        <div className="icon-wrapper close_right" onClick={onCloseRight}>
          <img src="/icons/close-black.svg" alt="Close icon" />
        </div>
      )}
      {pageNumber && <div className="page-number">{pageNumber}</div>}
    </MobileHeaderWrapper>
  );
}

export default memo(MobileHeader);