export const FieldKeys = {
  orderAt: "orderAt",
  scheduledFor: 'scheduledFor',
  total: 'total',
  items: 'items',
};

export const FieldTypes = {
  [FieldKeys.orderAt]: 'Order at',
  [FieldKeys.scheduledFor]: 'Scheduled for',
  [FieldKeys.total]: 'Total',
  [FieldKeys.items]: 'Items',
};

export const initConditions = {
  [FieldKeys.orderAt]: '',
  [FieldKeys.scheduledFor]: '',
  [FieldKeys.total]: '',
  [FieldKeys.items]: '',
};

export const WeekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const TotalFields = { lessThan: 'Less than', equalTo: 'Equal to', moreThan: 'More than' };
