import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Meal from './Meal';
import AddNotes from './AddNotes';
import { Line } from 'rc-progress';
import FilterMealsData from './FilterMealsData';
import { useRequest } from 'hooks/useRequest';
import moment from 'moment';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from '../../../context/nutritionistContext';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrganizeUserPantryWrapper = styled.div`
  margin-bottom: 14px;
  padding-top: 20px;
  background: #f5f6f6;
  border-radius: 10px;

  .pantry-user-title {
    margin-bottom: 15px;
    padding: 0 14px;
    color: #51635d;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
  }

  .colummn-title-wrapper {
    position: relative;
    padding-bottom: 8px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 6%);
    display: flex;

    .pantry-title-shadow {
      width: 100%;
      height: 7px;
      background: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(3, 3, 3, 0));
      opacity: 0.7;
      position: absolute;
      top: 20px;
      left: auto;
    }

    .calumn-title {
      width: 60px;
      color: #949d9b;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-align: center;
      flex-shrink: 0;
      text-transform: uppercase;
      ${FlexCenter};

      img {
        width: 8px;
        margin-left: 3px;
      }

      .filter-meals-data {
        display: flex;
        cursor: pointer;
      }
    }

    .meals-title-wrapper {
      overflow: hidden;
      width: calc(100% - 125px);
      padding: 0 5px 0 10px;
      scrollbar-width: none;
      display: flex;

      ::-webkit-scrollbar {
        height: 0;
        display: none;
      }
    }

    .column-title {
      width: 44px;
      margin-right: 10px;
      color: #949d9b;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.05em;
      flex-shrink: 0;
      text-align: center;
    }
  }

  .organize-meals-wrapper {
    overflow-y: auto;
    height: 350px;

    ::-webkit-scrollbar {
      width: 5px;
    }
  }

  .organize-table-wrapper {
    position: relative;
    display: flex;

    .pantry-cals-wrapper {
      width: 60px;
    }

    .cals-container {
      padding-top: 10px;
      box-shadow: 6px 0 4px -4px rgb(0 0 0 / 5%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .pantry-meals-wrapper {
      overflow-y: hidden;
      overflow-x: auto;
      width: calc(100% - 120px);
      padding: 10px 0 0 10px;

      ::-webkit-scrollbar {
        height: 5px;
      }
    }

    .meals-title-wrapper {
      margin-bottom: 10px;
      padding-bottom: 10px;
      display: flex;
    }

    .pantry-notes-wrapper {
      box-shadow: -6px 0 4px -4px rgb(0 0 0 / 5%);
    }

    .pantry-notes-container {
      width: 60px;
      padding-top: 10px;
    }

    .cals-number-wrapper,
    .notes-wrapper {
      display: flex;
      justify-content: center;
    }

    .notes-wrapper {
      z-index: 3;
      background: #f5f6f6;
    }

    .cals-number-container {
      width: 44px;
      height: 55px;
      margin-bottom: 14px;
      padding: 2px 0;
      background-color: #fff;
      color: #51635d;
      border-radius: 6px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      text-align: center;
    }

    .day-name {
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #949d9b;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.05em;
      }

      span:last-child {
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .cals-count {
      margin-top: -1px;
      font-size: 10px;
      line-height: 12px;
      display: block;
    }

    .progress-count-wrapper {
      margin-top: -9px;
    }

    .meals-area-wrapper {
      position: relative;
      padding-bottom: 14px;
    }

    .meals-placeholder {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;

      .item {
        width: 44px;
        height: 55px;
        margin-right: 10px;
        background: rgba(224, 227, 224, 0.5);
        border: 1px dashed #afb5b4;
        border-radius: 6px;
      }
    }

    .meals-area-container {
      height: 55px;
      display: flex;

      .meal-wrapper {
        margin-right: 10px;
      }
    }

    .notes-container {
      width: 44px;
      height: 55px;
      margin-bottom: 14px;
      background: rgba(224, 227, 224, 0.5);
      border-radius: 6px;
      box-shadow: 1px 2px 2px rgba(255, 255, 255, 0.8), inset 1px 3px 2px rgba(0, 0, 0, 0.04);
      ${FlexCenter};
      cursor: pointer;

      img {
        width: 14px;
      }

      &.noted {
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

        img {
          width: 20px;
        }
      }
    }

    .add-new-day {
      width: 20px;
      height: 20px;
      margin: -6px 0 5px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
      cursor: pointer;
      ${FlexCenter};

      img {
        width: 10px;
      }
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 24px;
    padding-top: 24px;

    .pantry-user-title {
      margin-bottom: 22px;
    }

    .colummn-title-wrapper {
      .calumn-title {
        width: auto;
        padding: 0 30px 0 40px;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;

        .date-column {
          width: 60px;
        }

        &:last-child {
          padding: 0 40px 0 30px;

          span {
            width: 56px;
          }
        }

        .filter-meals-data {
          width: 80px;
          ${FlexCenter};

          img {
            width: 10px;
            margin-bottom: 3px;
            transition: all 0.3s ease-in-out;

            &.isFilterMealsData {
              transform: rotate(180deg);
            }
          }
        }
      }

      .pantry-title-shadow {
        top: 21px;
      }

      .meals-title-wrapper {
        padding: 0 0 0 28px;

        .column-title {
          width: 56px;
          margin-right: 30px;
          font-size: 11px;
        }
      }
    }

    .organize-meals-wrapper {
      height: 462px;
    }

    .organize-table-wrapper {
      .pantry-cals-wrapper {
        width: auto;
        padding: 0 30px 0 40px;
        box-shadow: 6px 0 4px -4px rgb(0 0 0 / 5%);
      }

      .cals-container {
        padding-top: 15px;
        box-shadow: none;
      }

      .cals-number-wrapper {
        height: 70px;
        margin-bottom: 14px;
        ${FlexCenter};
      }

      .cals-number-container {
        width: 140px;
        height: 56px;
        margin-bottom: 0;
        border-radius: 8px;
        display: flex;
      }

      .day-name {
        width: 60px;
        flex-direction: column;
        ${FlexCenter};

        span:last-child {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .progress-count-wrapper {
        width: 80px;
        margin: 0;
        padding: 0 10px;
        border-left: 1px solid #f6f5f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-flow: column-reverse;
      }

      .cals-count {
        margin: 0 0 5px;
        font-size: 14px;
        line-height: 16px;
      }

      .pantry-meals-wrapper {
        padding: 15px 0 0 28px;
      }

      .meals-placeholder .item {
        width: 56px;
        height: 70px;
        margin-right: 30px;

        &:last-child {
          margin-right: 20px;
        }
      }

      .meals-area-wrapper {
        margin-bottom: 14px;
        padding-bottom: 0;
      }

      .meals-area-container {
        height: 70px;

        .meal-wrapper {
          margin-right: 30px;
        }
      }

      .pantry-notes-container {
        width: auto;
        padding: 15px 40px 0 30px;
      }

      .notes-wrapper {
        height: 70px;
        margin-bottom: 14px;
        background: transparent;
        ${FlexCenter};
      }

      .notes-container {
        width: 56px;
        height: 70px;
        margin-bottom: 0;
      }

      .add-new-day {
        width: 28px;
        height: 28px;

        img {
          width: 14px;
        }
      }
    }
  }
`;

const mealsData = ['calories', 'protein', 'fat', 'carbs'];

export default function OrganizeUserPantry({
  dailyMeals,
  setDailyMeals,
  setNutritionistPlanningId,
  setPreviousDailyMeals,
  userId,
  nutritionistId,
}) {
  const { user, isSuper } = useAppContext();
  const [isNote, setIsNote] = useState(false);
  const [noteIndex, setNotIndex] = useState();
  const [note, setNote] = useState('');
  const [isFilterMealsData, setIsFilterMealsData] = useState(false);
  const [active, setActive] = useState(0);
  const [mealsDataItem, setMealsDataItem] = useState(mealsData[0]);
  const { userId: id } = useParams();
  const mealsTitleWrapper = useRef();
  const pantryMealsWrapper = useRef();
  const mealsPlaceholder = useRef();
  const { isMobile } = useAppContext();
  const mealsPlaceholderWidth = mealsPlaceholder.current?.clientWidth;
  const { activeClients } = useNutritionistContext();
  const userInfo = activeClients?.nutritionistMeeting?.filter((item) => item?.userId == id)[0];
  const profile = userInfo?.user?.profile;
  const {
    dietMetrics: { calories, macros },
  } = profile;

  const [getIntialMeal] = useRequest();
  const [getDiaryMeals] = useRequest();

  const getInitialData = () => {
    const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
    const reqId = isSuper ? imitateNutritionist?.userId : user?.nutritionistProfile?.id;
    const getIntialMealUrl = `nutritionistPlanning/getPersonalizedMeals?nutritionistId=${reqId}&userId=${id}`;
    const getDiaryUrl = `diary/getDiaryMeals?userId=${id}`;
    getIntialMeal({
      path: `${getIntialMealUrl}`,
    }).then((templates) => {
      if (templates?.suggestion?.data?.length) {
        templates?.suggestion?.data?.forEach((item) => {
          for (let index = 0; index < dailyMeals.data.length; index++) {
            const dailyMeal = dailyMeals.data[index];
            if (item.suggestedDate === dailyMeal.suggestedDate) {
              dailyMeals.data[index] = item;
              break;
            }
          }
        });
        setDailyMeals(dailyMeals);
        setNutritionistPlanningId(templates.id);
      }
    });

    getDiaryMeals({
      path: getDiaryUrl,
    }).then((templates) => {
      setPreviousDailyMeals(templates?.diaryData);
     
    });

   
  };

  const generateNote = (index) => {
    setIsNote(true);
    setNotIndex(index);
    setNote(dailyMeals.data[index].note);
  };

  const generateCals = (el, item) => {
    const mealsItems = el.orderLineIds?.map((meal) => meal.data[item]);
    const calculateItems = mealsItems?.reduce((acc, val) => acc + val, 0);
    return calculateItems;
  };

  const progressColor = (el, item) => {
    let color = '';
    let precent = 0;
    const profiledataItem = item == 'calories' ? calories : macros[item];
    precent = (generateCals(el, item) / profiledataItem) * 100;

    if (generateCals(el, item) == 0) {
      color = '#e0e3e0';
    } else if (generateCals(el, item) > 0 && generateCals(el, item) <= profiledataItem) {
      color = '#52C41A';
    } else if (
      generateCals(el, item) > profiledataItem &&
      generateCals(el, item) <= profiledataItem + 125
    ) {
      color = '#35A956';
    } else if (
      generateCals(el, item) > profiledataItem + 125 &&
      generateCals(el, item) <= profiledataItem + 250
    ) {
      color = '#FFCA06';
    } else if (generateCals(el, item) > profiledataItem + 250) {
      color = '#FF4F00';
    }

    return { color, precent };
  };

  useEffect(() => {
    const onScroll = () => {
      mealsTitleWrapper.current.scrollLeft = pantryMealsWrapper.current.scrollLeft;
    };
    pantryMealsWrapper.current.addEventListener('scroll', onScroll);
  });

  const removeCustomMeal = (el, index) => {
    el.orderLineIds.splice(index, 1);
    const prevData = dailyMeals.data;
    prevData[index] = { ...prevData[index], el };
    setDailyMeals((prevState) => ({ ...prevState, data: prevData }));
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    isMobile && isFilterMealsData
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [isFilterMealsData]);

  useEffect(() => {
    if (!isMobile) {
      setMealsDataItem(mealsData[active]);
      setIsFilterMealsData(false);
    }
  }, [active]);

  return (
    <OrganizeUserPantryWrapper>
      <div className="pantry-user-title">
        Organize <span>{profile.name.split(' ')[0]}</span>'s pantry
      </div>
      <div className="colummn-title-wrapper">
        <div className="pantry-title-shadow" />
        <div className="calumn-title">
          {!isMobile && <div className="date-column">DATE</div>}
          <div className="filter-meals-data" onClick={() => setIsFilterMealsData(true)}>
            {isMobile ? (
              <span>{mealsDataItem == mealsData[0] ? 'Cals' : mealsDataItem}</span>
            ) : (
              <span>{mealsDataItem}</span>
            )}
            <img
              src="/icons/arrow-down.svg"
              alt="Arrow Icon"
              className={classNames({ isFilterMealsData })}
            />
          </div>
        </div>
        {isFilterMealsData && (
          <FilterMealsData
            setIsFilterMealsData={setIsFilterMealsData}
            setMealsDataItem={setMealsDataItem}
            mealsData={mealsData}
            active={active}
            setActive={setActive}
          />
        )}
        <div className="meals-title-wrapper" ref={mealsTitleWrapper}>
          {[...Array(6)].map((_, index) => (
            <div key={index} className="column-title">
              MEAL {index + 1}
            </div>
          ))}
        </div>
        <div className="calumn-title">
          <span>NOTES</span>
        </div>
      </div>
      <div className="organize-meals-wrapper">
        <div className="organize-table-wrapper">
          <div className="pantry-cals-wrapper">
            <div className="cals-container">
              {dailyMeals?.data?.map((el, ind) => (
                <div className="cals-number-wrapper" key={ind}>
                  <div className="cals-number-container">
                    <div className="day-name">
                      <span>{moment(el.suggestedDate).format('MMM DD')?.split(' ')[0]}</span>
                      <span>{moment(el.suggestedDate).format('MMM DD')?.split(' ')[1]}</span>
                    </div>
                    <div className="progress-count-wrapper">
                      <Line
                        percent={progressColor(el, mealsDataItem).precent}
                        strokeWidth="15"
                        strokeColor={progressColor(el, mealsDataItem).color}
                        trailWidth="15"
                        height="6px"
                        trailColor="#e0e3e0"
                      />
                      <span className="cals-count">{generateCals(el, mealsDataItem)}</span>
                    </div>
                  </div>
                </div>
              ))}
              <div
                className="add-new-day"
                onClick={() => {
                  setDailyMeals((prevState) => ({
                    ...prevState,
                    data: [
                      ...prevState.data,
                      {
                        suggestedDate: moment(
                          dailyMeals.data[dailyMeals.data.length - 1].suggestedDate,
                        )
                          .add(1, 'days')
                          .format('YYYY-MM-DD'),
                        orderLineIds: [],
                        note: '',
                      },
                    ],
                  }));
                }}
              >
                <img src="/icons/add-green.svg" alt="add Icon" />
              </div>
            </div>
          </div>
          <div className="pantry-meals-wrapper" ref={pantryMealsWrapper}>
            {dailyMeals?.data?.map((el, ind) => (
              <div
                className="meals-area-wrapper"
                style={{ width: mealsPlaceholderWidth }}
                key={ind}
              >
                <div className="meals-placeholder" ref={mealsPlaceholder}>
                  {[...Array(6)].map((_, index) => (
                    <div className="item" key={`meal+${index}`} />
                  ))}
                </div>
                <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="meals-area-container"
                      {...provided.droppableProps}
                    >
                      {el.orderLineIds?.length
                        ? el.orderLineIds?.map((meal, index) => (
                            <Meal
                              meal={meal}
                              key={meal?.uniqueKey}
                              draggableId={meal?.uniqueKey}
                              index={index}
                              removeMeal={() => removeCustomMeal(el, index)}
                              module="organzeUserPantry"
                            />
                          ))
                        : null}
                      {provided.placeholder}
                      {snapshot.isDraggingOver && (
                        <div
                          className="placeholder"
                          style={{
                            width: '40px;',
                            height: '40px',
                            background: 'red',
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
          <div className="pantry-notes-wrapper">
            <div className="pantry-notes-container">
              {dailyMeals?.data?.map((el, index) => (
                <div className="notes-wrapper" key={index}>
                  <div
                    className={classNames('notes-container', {
                      noted: dailyMeals?.data[index]?.notes?.length,
                    })}
                    onClick={() => generateNote(index)}
                  >
                    <img
                      src={`/icons/${
                        dailyMeals.data[index].notes?.length ? 'tick-circle' : 'message-add'
                      }.svg`}
                      alt="messageAdd Icon"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isNote && (
        <AddNotes
          setIsNote={setIsNote}
          noteIndex={noteIndex}
          setDailyMeals={setDailyMeals}
          dailyMeals={dailyMeals}
          note={note}
          setNote={setNote}
        />
      )}
    </OrganizeUserPantryWrapper>
  );
}
