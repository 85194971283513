import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/elements/button/Button';
import { useShopContext } from 'context/shopContext';
import { useRequest } from 'hooks/useRequest';
import { cloneDeep } from 'lodash';
import { useAppContext } from 'context/appContext';
import { toast } from 'react-toastify';
import ApplePayButton from './ApplePayButton';
import { useHistory } from 'react-router-dom';

const PayButtonStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  padding: 44px 16px 29px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
  button {
    font-family: 'Gilroy';
  }
  @media (min-width: 992px){
    position: relative;
    padding: 32px 34px 48px;
    button{
      width: 100%;
      max-width: 100%;
    }
  }
`;

const PayButton = ({
  userCompleteInfo,
  isPromoValid,
  mealOptions,
  isUserDeliveryError,
  setIsOrderSuccessful,
  setCardInputError,
  isApplePay,
  setIsOrderFailed,
  providerSubscriptionSelection ,
  setOrderFailError,
  handleCreateCustomer,
  customerId,
  isSaveCard
}) => {
  const {
    settings,
    cart,
    paymentInfo,
    setPaymentInfo,
    promoCodeInfo,
    setPromoCodeInfo,
    setAppliedCode,
    orderSummaryInfo,
    setUseNutrittCash
  } = useShopContext();
  const { userInfo, providerSettings } = settings;
  const [createOrder, { isLoading: isCreateOrderLoading, error }] = useRequest('order/create', 'post');
  const { user, setCustomQtyMeal, isDesktop } = useAppContext();
  const history = useHistory();

  const afterOrderSuccess = (orderSuccess) => {
    window.analytics.identify(user.id, {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: user.email,
      phone: orderSuccess?.mainOrder?.phoneNumber,
      lifecycle: 'madepurchase ',
    });
    setIsOrderSuccessful(true);
    localStorage.removeItem('viewedStoreUrl');
    history.push('/shop/checkout/thanks');
  };

  const submitOrder = async () => {
    if(isCreateOrderLoading) return;
    const items = cart.map(({ id, qty, isCombo, components, providerId }) => {
      let selectionIds = [];
      if (isCombo) selectionIds = components.map(({ id }) => id);
      return {
        mealId: id,
        qty,
        isCombo,
        selectionIds,
        providerId,
      };
    });
    const paymentInfoData = { ...paymentInfo };
    for (let key in paymentInfoData) {
      if (paymentInfoData[key]?.replace) {
        paymentInfoData[key] = paymentInfoData[key]?.replace(/\s/g, '');
      }
    }
    const {CustomerId, PaymentMethodId, method, taxRate, Token} = paymentInfoData;
    const updatedPaymentInfo = paymentInfoData?.PaymentMethodId ? { CustomerId, PaymentMethodId, method, taxRate, token: Token } : paymentInfoData
    try{
      if (isSaveCard) {
        try {
          await handleCreateCustomer(customerId, paymentInfo, orderSummaryInfo, userInfo);
        } catch (error) {
          console.log('Error handling create customer:', error);
          throw new Error('Error handling create customer');
        }
      }  
    const orderSuccess = await createOrder({
      body: {
        items,
        paymentInfo: updatedPaymentInfo,
        userInfo,
        providerSettings: cloneDeep(providerSettings).map((item) => {
          delete item.schedule;
          delete item.deliverableAreas;
          return item;
        }),
        // pCode: promoCodeInfo?.promoCode
        promoCodeInfo: {
          promoCode: promoCodeInfo?.promoCode,
        },
        ...(providerSubscriptionSelection && providerSubscriptionSelection.length > 0 && { subscriptionInfo: providerSubscriptionSelection }),
        ...(orderSummaryInfo?.orderSummary?.usedNutrittCash > 0 && { applyCashAmount: true }),
      },
    });
    setPaymentInfo({});
    setPromoCodeInfo({ promoCode: '', subTotal: '' });
    setAppliedCode('')
    setUseNutrittCash(false);
    setCustomQtyMeal(false);
    if (orderSuccess) {
      afterOrderSuccess(orderSuccess);
    }
    }catch(err){
        console.log('toast error', err);
        toast.error(err);
        if(err == 'Error - Invalid Card') {
            setCardInputError('cardNumber');
            return;
        }
        setIsOrderFailed(true);
    }
  };
  const isPlaceOrderDisabled = useMemo(() => {
    const isMissingUserInfo = userCompleteInfo;
    const isInvalidPromo = isPromoValid === false;
    const isMealOptionMissing = Object.values(mealOptions).includes(false);
    const isDeliveryError = isUserDeliveryError;
    return (
      isMissingUserInfo ||
      isInvalidPromo ||
      isMealOptionMissing ||
      isDeliveryError
    );
  }, [userCompleteInfo, isPromoValid, mealOptions, isUserDeliveryError,isDesktop]);

  return (
    <>
      <PayButtonStyle>
        {isApplePay == "apple pay"  ?  <ApplePayButton afterOrderSuccess={afterOrderSuccess} setIsOrderFailed={setIsOrderFailed} setOrderFailError={setOrderFailError}/> 
        :
        <Button
          title="Place order"
          type={'mobile_save'}
          isDisabled={isPlaceOrderDisabled}
          onClick={submitOrder}
        />
        }
      </PayButtonStyle>
    </>
  );
};

export default PayButton;
