import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import DropDown from 'components/elements/drop-down/Dropdown';
import Pagination from 'components/elements/pagination/Pagination';
import { useRequest } from 'hooks/useRequest';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import RegularInput from 'components/Input/RegularInput';
import { useDebounce } from 'use-debounce';
import { displayDateTime } from 'utils';
import { CircularProgress } from '@material-ui/core';
import { useAppContext } from 'context/appContext';
import PayoutDetails from 'pages/KitchenMobile/views/PayoutDetails';
import InfiniteScroll from 'react-infinite-scroll-component';

const HistoryPayoutsStyle = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
  background: ${({ theme }) => theme?.colors?.white};
  .title_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 21px;
    .input-wrapper{
      input{
        height: 40px;
      }
    }
  }
  .table_title {
    padding-top: 19px;
    padding-left: 20px;
    padding-bottom: 21px;
    padding-right: 25px;
    font-size: ${({ theme }) => theme?.typography?.fontBase};
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-weight: 600;
    line-height: 24px;
  }
  .table_header {
    display: flex;
    background-color: ${({ theme }) => theme?.colors?.grayLightest};
    .table_head {
      width: 16.66666%;
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      color: ${({ theme }) => theme?.colors?.grayDarker};
      font-weight: 600;
      line-height: 20px;
      padding: 14px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      position: relative;
      span {
        flex: 1;
      }
      .select-wrapper {
        width: auto;
        margin-right: 0;
        .options-dropdown {
          top: 100%;
        }
        .selected-img {
          display: block;
        }
      }
    }
  }
  .table_body {
    height: calc(100vh - 501px);
    overflow: auto;
    position: relative;
    .table_row {
      display: flex;
      border-top: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
      .table_data {
        padding: 20px 16px;
        color: ${({ theme }) => theme?.colors?.primaryDark};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        font-weight: 400;
        line-height: 20px;
        width: 16.66666%;
        .status{
            font-size: ${({ theme }) => theme?.typography?.fontSmall};
            font-weight: 600;
            line-height: 16px;
            display: inline-block;
            text-align: center;
            line-height: 24px;
            padding: 0 8px;
            height: 24px;
            border-radius: 4px;
        }
        .paid{
            color: ${({ theme }) => theme?.colors?.success};
            border: 1px solid #9EE09D;
            background: #EBF9EB;
        }
        .partiallypaid{
            color: #B68015;
            border: 1px solid #F9D48D;
            background: #FEF7E8;
        }
        .voided{
            color: ${({ theme }) => theme?.colors?.danger};
            border: 1px solid #F89B9C;
            background: #FEEBEB;
        }
      }
      &:last-child {
        border-bottom: 0px;
      }
    }
    .order-not-found-wrapper {
      overflow: hidden;
      height: 100%;
      display:flex;
      align-items: center;
      font-weight: 600; 
      justify-content: center;
    }
    }

  .first-child {
    padding-left: 44px !important;
  }
  .last-child {
    padding-right: 40px !important;
  }
`;

export const Progress = styled.div`
  flex: 1;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MobileHistoryPayoutsStyle = styled.div`
.earned-balance{
  padding: 16px;
  text-align: center;
  background: white;
  .balance_container{
      border-radius: 6px;
      background: ${({ theme }) => theme?.colors?.grayLightest};
      color: ${({ theme }) => theme?.colors?.primaryDefault};
      font-family: Gilroy;
      font-style: normal;
      height: 73px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .main-text{
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
      }  
      .amount{
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
      }      
  }
}
.infinite-scroll-component {
  ::-webkit-scrollbar-thumb{
    background-color: transparent; !important
  }
}
.card-div{
  display: flex;
  flex-direction: column;
  gap: 10px;  
  .order-not-found-wrapper {
    overflow: hidden;
    height: 100%;
    display:flex;
    font-weight: 600; 
    align-items: center;
    justify-content: center;
  }

}
.space_between{
  display: flex;
  justify-Content: space-between;
  align-items: center;
}
`
export const HistoryPayouts = ({ searchTerm, setSearchTerm, totalEarned }) => {
  const [getHistoryPayouts, {isLoading}] = useRequest();
  const [historyPayouts, setHistoryPayouts] = useState();
  const [payoutsPageNumber, setPayoutsPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  const [totalPayoutCount, setTotalPayoutCount] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [isDataGenerating, setIsDataGenerating] = useState(false);
  const [debounceSearchTerm] = useDebounce(searchTerm, 700);
  const [heightOrders, setHeightOrders] = useState();
  const defaultOptions = [
    { value: 'asc', label: 'Sort Ascending', imgSrc: '/icons/arrow-up-black.svg' },
    { value: 'desc', label: 'Sort Descending', imgSrc: '/icons/arrow-down-black.svg' },
  ];
  const icon = '/icons/Right-Icon.svg';
  const itemsPerPage = 8;
  const startItem = (payoutsPageNumber - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalPayoutCount);
  const [sortBy, setSortBy] = useState('');
  const [sort, setSort] = useState('');
  const { isSuper, isDesktop, isWebView, isPlatformIOS } = useAppContext();
  const imitateProviderStorage = sessionStorage.getItem("imitateProvider");
  const numberFromApi = parseFloat(totalEarned);
  let formattedNumber

  if (!isNaN(numberFromApi)) {
     formattedNumber = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numberFromApi);
  
    console.log(formattedNumber);
  } else {
    console.error('Invalid number format');
  }
  
  const imitateProviderId = useMemo(() => {
    const parsedId = JSON.parse(imitateProviderStorage)?.id;
    return parsedId
  }, [imitateProviderStorage]);

  const fetchUpcomingPayouts = async (pageNumber, searchTerm) => {
    setIsDataGenerating(true);
    const currentPage = 1;
    await getHistoryPayouts({path: `financials/disbursement-history?limit=${isDesktop ? '8' : '100'}${sortBy && sort ? `&${sortBy}=${sort}` : ''}&page=${pageNumber}${searchTerm ? `&orderNumber=${searchTerm}` : ''}${isSuper?`&providerId=${imitateProviderId}`:''}`, method: "get"})
    .then((data) => {
        setHistoryPayouts(data?.data?.disbursements);
        setIsDataGenerating(false);
        setTotalPageCount(data?.data?.totalPages);
        setTotalPayoutCount(data?.data?.totalRecords);
        if (currentPage === data?.data?.totalPages) {
          setHasMore(false);
          return;
        } else {
          setHasMore(true);
        }   
    })
    .catch((err) => {
        setHistoryPayouts([]);
        setIsDataGenerating(false);
        setTotalPageCount(0);
        setTotalPayoutCount(0)
        console.log('err', err);
    })
  }

  const fetchMore = async () => {
    const currentPage = payoutsPageNumber + 1;
    if (currentPage > totalPageCount) {
      setHasMore(false);
      return;
    }
    try {
      const data = await getHistoryPayouts({
        path: `financials/disbursement-history?limit=${isDesktop ? '8' : '100'}&page=${currentPage}${searchTerm ? `&orderNumber=${searchTerm}` : ''}${isSuper ? `&providerId=${imitateProviderId}` : ''}`,
        method: 'get',
      });
      setHistoryPayouts([...historyPayouts, ...data?.data?.disbursements]);
      setPayoutsPageNumber(currentPage);
    } catch (err) {
      setHistoryPayouts([]);
      setTotalPageCount(0);
      setTotalPayoutCount(0);
      console.error('Error fetching more data:', err);
    }
  };

  useEffect(() => {
    if(isDesktop){
    fetchUpcomingPayouts(payoutsPageNumber, debounceSearchTerm);
    }
  }, [debounceSearchTerm, payoutsPageNumber])

  useEffect(() => {
    if(!isDesktop){
      fetchUpcomingPayouts(payoutsPageNumber, debounceSearchTerm);
    }
  },[debounceSearchTerm])

  useEffect(() => {
    if(sortBy && sort && isDesktop){
      fetchUpcomingPayouts(payoutsPageNumber, debounceSearchTerm);
    }
  }, [sort, sortBy])

  const handlePageChange = (event, page) => {
    setPayoutsPageNumber(page);
  }

  const handleSearchChange = (e) => {
    const query = e?.target?.value;
    setSearchTerm(query);
  };

  useEffect(() => {
    isWebView && isPlatformIOS() ? setHeightOrders(window.innerHeight - 410) : setHeightOrders(window.innerHeight - 320);
  }, [])

  return (
    isDesktop ? 
    (
    <HistoryPayoutsStyle theme={theme}>
      <div className='title_flex'>
      <div className="table_title">History</div>
      <RegularInput className="search-input" width="270px" prefix={<img src='/icons/search-input2.svg' alt=" Search Icon" />} placeholder="Search for orders" value={searchTerm} onChange={handleSearchChange}/>
      </div>
      <div className="table_header">
        <div className="table_head">
          <span>Date paid</span> <DropDown updateValue={setSort} onChange={() => setSortBy('datePaid')} icon={icon} option={defaultOptions} />
        </div>
        <div className="table_head">
          <span>Period Start</span> <DropDown updateValue={setSort} onChange={() => setSortBy('periodStart')} icon={icon} option={defaultOptions} />
        </div>
        <div className="table_head">
          <span>Period End</span> <DropDown updateValue={setSort} onChange={() => setSortBy('periodEnd')} icon={icon} option={defaultOptions} />
        </div>
        <div className="table_head secondLast-child">
          <span>Amount</span>
        </div>
        <div className="table_head secondLast-child">
          <span>Paid Amount</span>
        </div>
        <div className="table_head last-child">
          <span>Status</span>
        </div>
      </div>
      <div className="table_body">
      {isLoading &  historyPayouts=== undefined?
        <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> 
              : (
                historyPayouts?.length > 0 ? historyPayouts?.map((payout) => {
          const { id, datePaid, periodStart, periodEnd, amount, paidAmount, status } = payout;
          return (
            <div className="table_row">
              <div className="table_data">{datePaid ? displayDateTime(datePaid) : 'N/A'}</div>
              <div className="table_data">{periodStart ? displayDateTime(periodStart) : 'N/A'}</div>
              <div className="table_data">{periodEnd ? displayDateTime(periodEnd) : 'N/A'}</div>
              <div className="table_data secondLast-child">${amount}</div>
              <div className="table_data secondLast-child">${paidAmount|| 0}</div>
              <div className="table_data last-child"><span className={`status ${status.toLowerCase()}`}>{status === 'partiallyPaid' ? 'Partly paid' : status}</span></div>
            </div>
          )
        }) : <div className='order-not-found-wrapper'>Payout history not found</div>)}
        {debounceSearchTerm && historyPayouts?.length <= 0 && <div className='table_row'><p className='table_data' style={{width: '100%', textAlign: 'center'}}>No results found</p></div>}
      </div>
      <Pagination handlePageChange={handlePageChange} totalPageCount={totalPageCount} totalCount={totalPayoutCount} startItem={startItem} endItem={endItem} />
    </HistoryPayoutsStyle>
    )
    :
    (
      <MobileHistoryPayoutsStyle theme={theme}>
      <div className='earned-balance'>
            <div className='balance_container'>
            {isDataGenerating ? <div style={{alignSelf: "center"}}><Skeleton variant="text" width={150} height={20} /> </div> :<span className='main-text'>Total earned</span>}
            {isDataGenerating ? <div style={{alignSelf: "center"}}><Skeleton variant="text" width={150} height={30} /> </div> : <span className='amount'>${formattedNumber}</span>}
            </div>
      </div>
      <div className='card-div' id='scrollableDiv'>
      {isDataGenerating ? (
          <>
            {Array.from({ length: 10 }, (_, index) => index + 1)?.map((item) => (
              <React.Fragment key={item}>
                <Stack style={{ padding: '16px' }}>
                  <div className='space_between' style={{marginBottom: "16px"}}>
                    <Skeleton variant="text" width={231} height={20} />
                    <Skeleton variant="text" width={78} height={20} />
                  </div>
                  <div className='space_between'>
                  <Skeleton variant="text" width={90} height={14} />
                  <Skeleton variant="text" width={90} height={14} />
                  </div>
                  <div className='space_between' style={{borderBottom:"1px solid #E9EAEC", paddingBottom: "10px"}}>
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={150} height={20} />
                  </div>
                  <div className='space_between' style={{marginTop: "10px"}}>
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={90} height={20} />
                  </div>
                  <div className='space_between' >
                  <Skeleton variant="text" width={90} height={20} />
                  <Skeleton variant="text" width={90} height={20} />
                  </div>
                </Stack>
                <Skeleton variant="rectangle" height={1} />
              </React.Fragment>
            ))}
          </>
        ) : 
        (
        historyPayouts?.length > 0 ? (
          <InfiniteScroll
          dataLength={historyPayouts?.length}
          next={fetchMore}
          hasMore={hasMore}
          height={heightOrders}
          loader={
            <>
            <Stack style={{ padding: '16px' }}>
            <div className='space_between' style={{marginBottom: "16px"}}>
              <Skeleton variant="text" width={231} height={20} />
              <Skeleton variant="text" width={78} height={20} />
            </div>
            <div className='space_between'>
            <Skeleton variant="text" width={90} height={14} />
            <Skeleton variant="text" width={90} height={14} />
            </div>
            <div className='space_between' style={{borderBottom:"1px solid #E9EAEC", paddingBottom: "10px"}}>
            <Skeleton variant="text" width={90} height={20} />
            <Skeleton variant="text" width={150} height={20} />
            </div>
            <div className='space_between' style={{marginTop: "10px"}}>
            <Skeleton variant="text" width={90} height={20} />
            <Skeleton variant="text" width={90} height={20} />
            </div>
            <div className='space_between' >
            <Skeleton variant="text" width={90} height={20} />
            <Skeleton variant="text" width={90} height={20} />
            </div>
          </Stack>
          <Skeleton variant="rectangle" height={1} />
          </>
          }
          scrollableTarget={scrollableDiv}
        >
          <div style={{display:"flex", flexDirection:"column", gap:"10px", background:"#F9F9F9"}}>
          {historyPayouts.map((payout, index) => (
            <div key={payout.id} style={{ marginTop: index === 0 ? "10px" : "0" }}>
            <PayoutDetails key={payout.id} Details={payout} />
            </div>
          ))}
          </div>
          </InfiniteScroll>
        ) : (
          <div className='order-not-found-wrapper'>No payment found</div>
        )
        )}
      </div>
      </MobileHistoryPayoutsStyle>
    )    
  );
};
