import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import Login from 'pages/auth/Login';
import KitchenLogin from 'pages/auth/KitchenLogin';
import Register from 'pages/auth/Register';
import ForgotPassword from 'pages/auth/ForgotPassword';
import VisitorLanding from 'pages/Landing/VisitorLanding';
import RegisterProvider from 'pages/auth/RegisterProvider';
import LandingPage from 'components/LandingPage';
import TermsOfUse from 'pages/TermsOfUse';
import TermsAndConditions from 'pages/termsAndConditions';
import NutritionistTermsAndConditions from 'pages/Nutritionist/NutritionistTermsAndConditions';
import NutrittPrivacyPolicy from 'pages/NutrittPrivacyPolicy';
import Footer from 'layout/Footer';
import RegisterNutritionist from 'pages/auth/RegisterNutritionist';
import ProviderTermsConditions from 'pages/provider/ProviderTerms&Conditions';
import Header from 'layout/Header';
import WellKnown from 'pages/well-known';
import ProviderLandingPage from 'pages/provider/LandingPage';
import BookingContextWrapper from 'pages/Booking';
import GoogleOauth from 'pages/GoogleOauth';
import Landing from 'pages/Landing';
import OnboardingFirstTimeWrapper from 'pages/onboardingFirstTime';
import { useNutritionistContext } from '.././context/nutritionistContext';
import { useEffect, useState } from 'react';
import GuestUser from 'pages/GuestUser';
import SessionLogin from 'pages/SessionLogin/SessionLogin';
import RegisterForm from 'components/auth/RegisterForm';
import { useAppContext } from 'context/appContext';
import ChatBot from 'pages/chatBot';
import ApplePayTest from 'pages/apple-pay';
import Share from 'components/Challange/Share';
import { VisitorUserNew } from 'pages/Visitor';
import UserOnboarding from 'pages/UserOnboarding';
import NewFile from 'pages/NewFile';
import { Kitchen } from 'pages/provider/Kitchen';
import UserDesktopOnboarding from 'pages/UserOnboarding/desktop';
import { VisitorContextProvider } from "context/visitorContext"
import FavoriteKitchen from 'pages/shop/FavoriteKitchen';
import ShopHeader from 'layout/ShopHeader_v2';
import { ShopStyle } from 'pages/shop';
import { GilroyFontWrapper } from 'shared/theme/style';
import CheckDeliveryAvailable from 'components/CheckDeliveryAvailable/CheckDeliveryAvailable';
import SignUp from 'pages/UserOnboarding/desktop/SignUp';
import { setKitchenToSession } from 'utils/setFavoriteKitchen';

const noFooterRoutes = ['/provider','/login','/forgot-password', '/kitchen-login']; 

const HandleRedirectToLogin = () => {
  const pathname = new useLocation().pathname;
  const search = new useLocation().search;
  const history = useHistory();
 
  if(Object.keys(search).length ){
  const kitchenValue = new URLSearchParams(search).get('kitchen');
  const kitchenUsername = sessionStorage.getItem('kitchenUsername');
  if( kitchenValue ){
    setKitchenToSession(storeUrl, history);
  }
  }


  const to = pathname === '/logout' ? '' : `/account/home?redirectTo=${pathname}`;
  return <Redirect to={to} />;
};

export default function AuthRoutes() {
  const { offerData: offeringClientData } = useNutritionistContext();
  const shouldRenderFooter = !noFooterRoutes.includes(window.location.pathname);
  const [showDietPlans, setShowDietPlans] = useState(false);
  const { user, isMobile, isDesktop } = useAppContext();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  const baseURL = window.location.protocol + "//" + window.location.host;


  return (
    <Router>
      <Switch>
        <Route exact path="/" component={VisitorLanding} />
        <Route exact path="/plan/:nutritionistId">
          {showDietPlans ? <Header /> : ''}
          {(!user?.id && (selectedOfferDetail?.userId == "undefined" || selectedOfferDetail?.userId == undefined)) &&
            <Header  />}
          <Register setShowDietPlans={setShowDietPlans} showDietPlans={showDietPlans} />
        </Route>
        <Route exact path="/store/:providerId">
          <ShopStyle>
          <GilroyFontWrapper>
            <VisitorContextProvider>
            {!isMobile &&
              <ShopHeader /> }
              <CheckDeliveryAvailable isHomepage={false}>
                <FavoriteKitchen />
              </CheckDeliveryAvailable>
            </VisitorContextProvider>
          </GilroyFontWrapper>
          </ShopStyle>
        </Route>
        <Route exact path="/challenge/:challangeId">
            <Share/>
        </Route>
        <Route exact path="/registerprovider">
          <RegisterProvider />
        </Route>
        <Route exact path="/registernutritionist">
          <RegisterNutritionist />
        </Route>
        <Route path="/account/">
          <UserDesktopOnboarding />
        </Route>
        <Route exact path="/signup">
          {isDesktop && <Header showSocialIcons={false}/>}
          <SignUp/>
        </Route>
        <Route exact path="/login">
          {baseURL === process.env.REACT_APP_MERCHANT_URL ? (
            <KitchenLogin />
          ) : (
            <Login />
          )}
        </Route>
        <Route path="/visitor/">
          <VisitorUserNew/>
        </Route>
        {isMobile &&
        <Route path="/self-onboarding/">
          <UserOnboarding/>
        </Route>
        }
        <Route exact path="/components-demo">
        <VisitorContextProvider>
        <NewFile />
        </VisitorContextProvider>
        </Route>
        <Route exact path="/terms" component={TermsOfUse} />
        <Route exact path="/provider/terms" component={ProviderTermsConditions} />
        <Route exact path="/users/terms-&-conditions" component={TermsAndConditions} />
        <Route exact path="/nutritionist/terms" component={NutritionistTermsAndConditions} />
        <Route exact path="/nutritt/privacy-policy" component={NutrittPrivacyPolicy} />
        <Route exact path="/nutritt/google-data" component={GoogleOauth} />
        <Route exact path="/session" component={SessionLogin} />
        <Route
          exact
          path="/.well-known/apple-developer-merchantid-domain-association"
          component={WellKnown}
        />
        <Route exact path="/providers" component={ProviderLandingPage} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/booking" component={BookingContextWrapper} />
        <Route path="/coach" component={Landing} />
        <Route path="/landing">
          <Redirect to="/coach" />
        </Route>
        <Route path="/guest" component={GuestUser} />
        <Route
          exact
          path="/onboarding/first-time"
          component={(props) => <OnboardingFirstTimeWrapper {...props} {...offeringClientData} />}
        />
        <Route exact path="/nutritt/support">
          <Header />
          <ChatBot />
        </Route>
        <Route path="/apple-pay-test" component={ApplePayTest} />
        <HandleRedirectToLogin />
      </Switch>
      {!showDietPlans && ''}
      {shouldRenderFooter && <Footer />}
    </Router>
  );
}


