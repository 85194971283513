import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Circle } from 'rc-progress';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { theme } from 'shared/theme/theme';
import { motion } from 'framer-motion';

const PersonalizedMacrosStyle = styled.div`
  padding: 53px 32px;
  text-align: center;
  height: 100vh;
  background: ${({ theme }) => theme?.colors?.white};
  .title {
    font-size: ${({ theme }) => theme?.typography?.fontLarge};
    font-weight: 700;
    color: ${({ theme }) => theme?.colors?.primaryDarker};
    text-align: center;
  }
  .progress_wrapper {
    margin-top: 117px;
    position: relative;
    .pentry-circle-percent {
      position: absolute;
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${({ theme }) => theme?.colors?.primaryDarker};
    }
    .rc-progress-circle {
      width: 250px;
      display: block;
      margin: auto;
    }
  }
`;

export default function PersonalizedMacros({ setActiveModalStack, nextScreen }) {
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const idCSS = 'gradient';
  const lastLoginUrl = history?.location?.state?.from;

  // const [visitorOnboard, { isLoading }] = useRequest('profile/visitor-onboard/v2', 'get');
  // const { setVisitorData } = useOnboardingContext();
  // const { setProfile } = useAppContext();

  // const visitorOnboardData = async () => {
  //     await visitorOnboard()
  //         .then((data) => {
  //             setVisitorData(data?.data);
  //             setProfile(data?.data?.profile);
  //         })
  //         .catch(console.log);
  // };

  // useEffect(() => {
  //     visitorOnboardData();
  // }, [])

  useEffect(() => {
    let cnt = 0;
    const counter = setInterval(() => {
      cnt += 1;
      if (cnt <= 99 && loading <= 99) {
        setLoading((prevState) => prevState + 1);
      }
    }, 50);

    if (loading >= 100) {
      setTimeout(() => {
        setActiveModalStack((prev) => [...prev, nextScreen])
      }, 500);
    }

    return () => {
      clearInterval(counter);
    };
  }, [loading]);

  return (
    <>
      <motion.div
        initial={{ x: 0, opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 0 }}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        <PersonalizedMacrosStyle theme={theme}>
          <h2 className="title">
            Calculating your personalized <br /> macros for optimal results...
          </h2>
          <div className="progress_wrapper">
            <GradientSVG lastLoginUrl={lastLoginUrl}/>
            <Circle
              percent={loading}
              strokeWidth="12"
              strokeColor={`url(#${idCSS})`}
              trailWidth="12"
              trailColor="#F0F2F4"
            />
            <p className="pentry-circle-percent" data-testid="pentry-circle-percent">
              {loading}%
            </p>
          </div>
        </PersonalizedMacrosStyle>
      </motion.div>
    </>
  );
}

function GradientSVG({lastLoginUrl}) {
  const idCSS = 'gradient';
  const gradientTransform = `rotate(90)`;

  return (
    <svg style={{ height: 0, position: 'absolute' }}>
      <defs>
        {lastLoginUrl === '/shop/track-quiz' ?
       <linearGradient id={idCSS} gradientTransform={gradientTransform}>
           <stop offset="16.29%" stopColor="#4CB5AB" />
           <stop offset="100%" stopColor="#000A2C00" />
       </linearGradient>
        :
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="16.29%" stopColor="#4CB5AB" />
          <stop offset="85.56%" stopColor="#EE786B" />
        </linearGradient>
        }
      </defs>
    </svg>
  );
}
