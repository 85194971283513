import { Box, Slider } from '@material-ui/core';
import { useAppContext } from 'context/appContext';
import { useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';

const ProfilePhotoWrapper = styled.div`
  input {
    display: none;
  }

  .profile-photo-container {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    gap: 11px;
  }

  .user-photo {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    object-fit: cover;
  }

  .edit-icon-wrapper {
    width: 28px;
    height: 28px;
    margin-left: -16px;
    background-color: #52c41a;
    border-radius: 50%;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 14px;
      height: 14px;
    }
  }

  .upload-photo-section {
    position: fixed;
    top: 52px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 115;
    padding: 0 !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar-editor {
      width: 100% !important;
      max-height: 60vh;
      cursor: grab;
      object-fit: cover;
    }

    .container {
      height: 40vh;
      padding: 23px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .zoom-wrapper {
      display: flex;
      align-items: center;

      .title {
        margin-right: 20px;
        color: #51635d;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
      }
    }

    .MuiSlider-rail {
      color: #52c41a66;
    }

    .MuiSlider-track {
      color: #52c41a;
    }

    .MuiSlider-thumb {
      background-color: #52c41a;
    }

    button {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 21px;
      line-height: 16px;
      text-transform: capitalize;
      cursor: pointer;
    }

    .show-result {
      background: #52c41a;
      color: #fff;
    }

    .cancel {
      margin-top: 10px;
      background: #ffffff;
      color: #51635d;
      border: 1px solid #97dc76 !important;
    }
  }

  @media (min-width: 991px) {
    .upload-photo-section {
      top: 70px;
      .container {
        width: 405px;
      }
    }
    .upload-photo-section .avatar-editor {
      width: 450px !important;
      height: 60vh !important;
    }

    .profile-photo-container{
      margin-bottom: 35px;
    }
  }

  @media(max-width:991px){
     .photo-label{
      label{
        display: flex;
        justify-content: center;
        .edit-icon-wrapper{
          position: relative;
          right: -66px;
          top: -122px;
          margin-left:0 !important;
        }
      }
     }
  }
  @media(min-width:992px){
    .photo-label{

    .profile-photo-container{
      width:150px;
      height:150px;
      background: #FFFFFF;
      border: 1px solid #E0E3E0;
      border-radius: 75px;
      align-items:center;
      .user-photo{
        width: 142px !important;
        height: 142px !important;
        border-radius: 75px !important;
      }
    }
    label{
      display: flex;
      justify-content: flex-end;
      .edit-icon-wrapper{
        position: relative;
        top: -50px;
      }
    }
  } 
  }
`;

export default function ProfilePhoto(props) {
  var editor = '';
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: '/images/man.svg',
  });
  const {user} = useAppContext();
  const isNutritionist = user?.type?.includes('nutritionist');


  useEffect(() => {
    if (props?.imageSrc) {
      setPicture({
        ...picture,
        croppedImg: props.imageSrc,
      });
    }
  }, [props.imageSrc]);

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const updatedData = {
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
        zoom: 1,
      };
      setPicture(updatedData);
      if (typeof props.handlePhotoUpload === 'function') {
        props.handlePhotoUpload(updatedData, user?.userId, props?.fileType);
      }
    }
  };

  const handleFileChange = (e) => {
    if (e?.target?.files[0]) {
      let url = window.URL.createObjectURL(e.target.files[0]);
      setPicture({
        ...picture,
        img: url,
        cropperOpen: true,
      });
    }
  };

  return (
    <ProfilePhotoWrapper>
      <div className={isNutritionist ? "photo-label" : ""}>
      <div className="profile-photo-container">
        <img
          src={picture.croppedImg || '/images/man.svg'}
          alt="User Photo"
          className="user-photo"
        />
      {!isNutritionist && <label for="upload">
          <div className="edit-icon-wrapper">
            <img src="/icons/edit-white.svg" alt="Edit Icon" />
          </div>
        </label>}
      </div>
      {isNutritionist && <label for="upload">
          <div className="edit-icon-wrapper">
            <img src="/icons/edit-white.svg" alt="Edit Icon" />
          </div>
        </label>}
        </div>
      <input
        type="file"
        id="upload"
        onChange={handleFileChange}
        accept="image/png, image/jpg, image/jpeg"
      />
      {picture.cropperOpen && (
        <Box display="block" className="upload-photo-section">
          <AvatarEditor
            ref={setEditorRef}
            image={picture.img}
            width={350}
            height={350}
            border={10}
            borderRadius={50 * 100}
            color={[0, 0, 0, 0.6]} // RGBA
            rotate={0}
            scale={picture.zoom}
            className="avatar-editor"
          />
          <div className="container">
            <div className="zoom-wrapper">
              <div className="title">Zoom</div>
              <Slider
                aria-label="raceSlider"
                value={picture.zoom}
                min={1}
                max={10}
                step={0.1}
                onChange={handleSlider}
              />
            </div>
            <div>
              <button className="show-result" onClick={handleSave}>
                Save
              </button>
              <button variant="contained" className="cancel" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      )}
    </ProfilePhotoWrapper>
  );
}

