import { useState, useEffect } from 'react';
import { Circle } from 'rc-progress';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import NutrittSteps from 'components/NutrittSteps';
import PantryFooter from 'components/Pantry/PantryFooter';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
 
const ThanksStyle = styled.div`
  padding: 24px 0 50px;
  color: #0b1c18;

  .visitor-navigate-button-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px; 
    button {
    width:80%;
    padding: 12px 38px;
    gap: 10px;
    background: #52C41A;
    color:#ffffff;
    border-radius: 21px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    }
    
  }
 

  @media (max-width:992px){
    .guest-mobile-wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 90%;
      height: 200px;
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 20px;
      margin-top: 10px;

    }
    .address{
      font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: #0B1D17;
    }
    .guest-info{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      display: flex;
      align-items: center;
      color: #51635D;
      margin-top: 15px;
      line-height: 20px;

    }
    .delivery-info{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #51635D;
      margin-top: 15px;
    }
    .thanks{
      font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #0B1D17;
    margin:10px;
   
    }
    .thanks-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #949D9B;
    margin-bottom: 20px;
    }
    .loading-title{
      width: 100%;
      disply:flex;
      align-items: center;
      display: flex;
      justify-content: center;
      p{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #51635D;
      }
      .loading-button{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 154px;
        height: 40px;
        background: #52C41A;
        border-radius: 21px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        margin-top: 25px;
        cursor:pointer;
      }         
    }
  }

  @media (min-width:992px){
    .desktop-steps{
      display: flex;
        justify-content: center;
        width: 100%;
    
      .back-arrow{
        width: 48px;
        height: 48px;
        background: #FFFFFF;
        box-shadow: 0px 4px 25px rgb(0 0 0 / 5%);
        border-radius: 50%;
        display: flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        position: fixed;
        left: 23px;
      
        img {
          width: 18px;
         }
      }
    }

    .thanks-title{
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      text-align: center;
      color: #949D9B;   
      margin-top: 10px;
    }
    .provider-info{
      display: grid;
      grid-template-columns: 45% 32%;
      justify-content: center;
      column-gap: 24px;

      .order-details{
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
        border-radius: 10px !important;
        padding: 0 !important;
        border:none !important;
        margin-bottom: 0px !important;
        padding-bottom: 25px !important;
        border-bottom: 1px solid #E0E3E0 !important;

        .order-name{
          border-bottom: 1px solid rgba(224, 227, 224, 1);
          padding: 12px 20px;
         font-size: 18px;
         color: #0B1D17;
         font-weight: 700;
       }
       
       .order-method{
          display: flex;
          gap: 25px;
          margin: 30px 0px 27px 20px;
         
         .method-type{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
            }

         .method-name{
           font-weight: 700;
           font-size: 14px;
           line-height: 16px;
           color: #51635D;
           }
        
        }
       
       .order-user-address{
        display: flex;
        gap: 21px;
        margin: 10px 0px 27px 20px;

        .user-address{
           font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
            }

         .user-info{
           font-weight: 700;
           font-size: 14px;
           line-height: 16px;
           color: #51635D;
         }   
       }

       .order-date{
         display: flex;
         gap: 41px;
         margin: 10px 0px 27px 20px;
         color: #51635D !important;

         .time-text{
            font-weight: 400;
           font-size: 14px;
           line-height: 16px;
           color: #949D9B;
          }

          .delivery-time-date{
            font-weight: 700 !important;
            font-size: 14px;
            line-height: 16px !important;
            color: #51635D !important;
          }
       }

       .order-note{
          display: flex;
          gap: 41px;
          margin: 10px 0px 27px 20px;
          color: #51635D !important;

          .note-text{
           font-weight: 400;
           font-size: 14px;
           line-height: 16px;
            color: #949D9B;
          }

          .note-content{
           font-weight: 700 !important;
           font-size: 14px;
           line-height: 16px !important;
           color: #51635D !important;
         }
       }

        
      }

        .loading-pantry{
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05) !important;
            border-radius: 10px;
            margin-bottom:0 !important;
            max-height:300px;

           .pentry-circle-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height:300px;
            
            }

            .loading-title{
              p{
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
                margin-top:31px;
              }

              .loading-button{
                display: flex;
                justify-content:center;
                align-items:center;
                width: 154px;
                height: 40px;
                background: #52C41A;
                border-radius: 21px;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #FFFFFF;
                margin-top: 25px;
                cursor:pointer;
              }         
            
            }
          }  
      
    }
  }
  .container {
    width: 90%;
    margin: 0 auto;

    .enable-pantry-btn-wrapper {
      margin-top: 36px;
      display: flex;
      justify-content: center;
      align-item: center;

      button {
        width: 144px;
        height: 40px;
        background-color: #52c41a;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        border-radius: 4px;
        box-shadow: 0 1px 0 #00000014;
        cursor: pointer;
      }
    }
    .thanks-title {
      margin-bottom: 14px;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }

    .order-details {
      margin-bottom: 21px;
      padding: 16px 19px;
      box-shadow: 0 3px 6px #00000010;
      border: 1px solid #c3cbc9;
      border-radius: 12px;

      p + p {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 600;

        span {
          display: block;
          line-height: 22px;
        }
      }
    }

    .order-title {
      margin-bottom: 11px;
      font-size: 16px;
      font-weight: 500;
    }

    .order-date {
      font-size: 16px;
      font-weight: 600;
      color: #52c41a;
    }

    .loading-title {
      margin-bottom: 16px;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
    }

    .loading-pantry {
      margin-bottom: 20px;
    }

    .pentry-circle-wrapper {
      display: flex;
      justify-content: center;
    }

    .pentry-circle {
      position: relative;
      width: 180px;
      display: grid;
      justify-items: center;
      align-items: center;

      p {
        position: absolute;
        text-align: center;

        span {
          display: block;

          &:first-child {
            color: #263238;
            font-size: 30px;
            font-weight: 700;
          }
        }
      }
    }

    .pantry-btn {
      font-size: 18px;
      font-weight: 700;
    }

  }
    .__react_component_tooltip{
      width:190px;
      text-align:center;
      word-break:break-all;
   }
`;

const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function Thanks() {
  const [loading, setLoading] = useState(0);
  const [pantryEnable, setPantryEnable] = useState(false);
  let history = useHistory();
  const { isMobile,user,isGuest,setupLocalUser , isVisitor } = useAppContext();
  const { settings, cart, updateCart, updateSettings } = useShopContext();
  const { providerSettings, userInfo } = settings;
  const [thanksCart, setThanksCart] = useState(cart);
  const [thanksProviderSettings, setThanksProviderSettings] = useState(providerSettings);
  const [thanksUserInfo, setThanksUserInfo] = useState(userInfo);
  const prevOrderCount = Number(localStorage.getItem('orderCount'));
  if(Number(prevOrderCount)<1){localStorage.setItem('orderCount', 1);}

  useEffect(() => {
    let cnt = 0;
    const counter = setInterval(() => {
      cnt += 1;
      if (cnt <= 99 && loading <= 99) {
        setLoading((prevState) => prevState + 1);
      }
    }, 50);
    return () => {
      clearInterval(counter);
    };
  }, [loading]);

  useEffect(async () => {
    if (loading >= 100) {
      setPantryEnable(true);
    }
  }, [loading]);

  useEffect(async () => {
    await updateSettings((prevSettings) => ({
      ...prevSettings,
      provider: {},
      providerSettings: [],
      mealPlan:[]
    }));
    await updateCart([]);
    await setupLocalUser();
  }, []);

  useEffect(() => {
    localStorage.setItem("displayStepsInPantry", true);
    window.scrollTo(0, 0);

    history.listen(() => {
      history.action === 'POP' && history.push('/shop/plan')
    })
  }, [])

  const cartData = thanksCart.map((i) => i.providerId);
  const providerInCart = [...new Set(cartData)];
  const provider = thanksProviderSettings.filter((i) => providerInCart.includes(i.providerId));

  useEffect(()=>{
if(!provider?.length){
  history.push("/shop/plan")
}
  },[provider])

  const ProviderPayment = ({ index }) => {
    const thisPro = provider[index];
    let providerIndex = thanksProviderSettings.findIndex(
      (i) => i.providerId === thisPro.providerId,
    );
    const thisProvider = thanksProviderSettings[providerIndex];
    const mealsInCart = thanksCart.filter((i) => i.providerId == thisProvider.providerId);
    const mealsQuantity = mealsInCart?.reduce((n, { qty }) => n + qty, 0);
    const fName = thanksUserInfo?.firstName;
    const lName =  thanksUserInfo?.lastName;

    useEffect(()=>{
      ReactTooltip.rebuild();
    },[])

    return (
      <>
      {
      isMobile ? (
         thisProvider.method === 'delivery' ? (
          <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%",paddingTop:"12px" }}>
            <div className='guest-mobile-wrapper'>
              <div>
                <h3 className="address">{mealsQuantity}  meal will be delivered to:</h3>

               {fName?.length>25 ? <h3 className='guest-info' data-tip={fName} data-for="fname" style={{cursor: 'pointer'}}>{fName.substring(0, 25) + "..."}</h3> : <h3 className='guest-info'>{fName}</h3> }
               {lName?.length>25 ? <h3 className='guest-info' data-tip={lName} data-for="lname" style={{cursor: 'pointer'}}>{lName.substring(0, 25) + "..."}</h3> : <h3 className='guest-info'>{lName}</h3> }
                <h5> <span>{`${thanksUserInfo.address},`}</span>
              <span>{`${thanksUserInfo?.address2 ?? ''},${thanksUserInfo?.city ?? ''}, ${thanksUserInfo?.state ?? ''}, ${
                thanksUserInfo?.zipcode ?? ''
              }`}</span></h5>
                <div className='delivery-info'>
                <p>{thisProvider.method}</p>
                <p>{`${moment(thisProvider.date).format('MMM DD, dddd')},${thisProvider.time}`}</p>
                </div>
              </div>

            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%",paddingTop:"12px" }}>
            <div className='guest-mobile-wrapper'>
              <div>
                <h3 className="address">{mealsQuantity}  meal will be picked up from:</h3>

                <h3 className='guest-info'>{`${thisProvider.name}`}</h3>
                <h5> <span>{`${thisProvider.address},`}</span></h5>
                <div className='delivery-info'>
                  <p>{thisProvider.method}</p>
                  <p>{`${moment(thisProvider.date).format('MMM DD, dddd')},${thisProvider.time}`}</p>
                </div>
              </div>
            </div>
          </div>
        )
      ) :
      (
        thisProvider.method === 'delivery' && (
          <div className="order-details">
             <p
              className="order-name"
              data-testid="pickup-meals-provider-name"
            >{`${thisProvider.name}`}</p>

            <p className="order-method" data-testid="order-method-delivery">
             <span className='method-type'>Method</span> <span className='method-name'> {thisProvider.method}</span>
            </p>
            <p className='order-user-address' data-testid="delivery-meals-user-address">
            <span className='user-address'>Address</span>   <span className='user-info'>{`${thanksUserInfo.address},`}
              {`${thanksUserInfo?.address2 ?? ''},${thanksUserInfo?.city ?? ''}, ${thanksUserInfo?.state ?? ''}, ${
                thanksUserInfo?.zipcode ?? ''
              }`}</span>
            </p>
         
            <p className="order-date" data-testid="order-delivery-date"><span className='time-text'>Time</span> <span className='delivery-time-date'>{`${moment(thisProvider.date).format('MMM DD, dddd')},${thisProvider.time}`}</span>  </p>

            <p className="order-note" data-testid="order-note">
             <span className='note-text'>Note</span> <span className='note-content'> {thisProvider.note ? thisProvider.note : "None" }</span>
            </p>
          </div>
        )
      ) }

       {!isMobile &&(        
        thisProvider.method === 'pickup' && (
          <div className="order-details">
            <p
              className="order-name"
              data-testid="pickup-meals-provider-name"
            >{`${thisProvider.name}`}</p>

            <p className="order-method" data-testid="order-method-pickup">
            <span className='method-type'>Method</span> <span className='method-name'> {thisProvider.method}</span>
            </p>

            <p className='order-user-address' data-testid="pickup-address">
            <span className='user-address'>Address</span> <span className='user-info'>{`${thisProvider.address}`}</span>
            </p>
           
            <p className="order-date" data-testid="order-pickup-date">
              <span className='time-text'>Time</span> <span className='delivery-time-date'>{`${moment(thisProvider.date).format('MMM DD, dddd')},${thisProvider.time}`} </span> 
            </p>
            <p className="order-note" data-testid="order-note">
             <span className='note-text'>Note</span> <span className='note-content'> {thisProvider.note ? thisProvider.note : "None" }</span>
             </p>

          </div>
        )
      ) } 
     
     <ReactTooltip id="fname" place="top" effect="solid">
     </ReactTooltip>
     <ReactTooltip id="lname" place="top" effect="solid">
     </ReactTooltip>
      </>
    );
  };


  return (
    <>
    <ThanksStyle>
      <div className="container">
      {!isMobile &&(   
         <div className='desktop-steps'>
           <NutrittSteps
            title={`Thank you`}
            description="Your orders have been placed!"
            activeStep={4}
            steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
            stepNumber={1}
            stepTitle="Let's specify your plan"
            isFooterHidden={true}
           />
          </div> 
      )
        } 
        {!isMobile && (
      <>    
       <div className='provider-info'>
         <div className='provider-map'>
          {provider.map((p, i) => (
          <ProviderPayment index={i} key={p.id} />
           ))}
         </div>
         <div className="loading-pantry" style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
          <div className="pentry-circle-wrapper">
            <div className="pentry-circle">
              <Circle
                percent={loading}
                strokeWidth="13"
                strokeColor={loading <100 ? "#52C41A" : "#97DC76"}
                trailWidth="13"
                trailColor="#52C41A4F"
              />
              <p data-testid="pentry-circle-percent">
                <span>{loading}%</span>
              </p>
            </div>
            <div className="loading-title">{loading<100 ? <p>Loading your meals to your pantry...</p>
             : <button className='loading-button'  onClick={() =>{
              updateSettings((prevSettings) => ({
                ...prevSettings,
                userInfo :{...userInfo , address2 :''},
              }));
                if(isGuest){
                  history.push("/shop/my-coach")
                } else if (isVisitor){
                  history.push("/shop/browse-plan")
                } else{
                  history.push('/shop/pantry')
                }
             }}>{isGuest ? "My Coach": "Go to pantry"}</button>}</div>
          </div>
        </div>
       </div>
       </>
       )}
        {isMobile && (
          <>
             {
              isGuest  ? (
                <div>
                  <p className="thanks">Thank you </p>
                  <p className="thanks-title">Your orders have been placed!</p>
                </div>
              ):
              (
                <>
                <div className='desktop-steps'>
                 <NutrittSteps
                  title={`Thank you`}
                  description="Your orders have been placed!"
                  activeStep={3}
                  steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
                  stepNumber={1}
                  stepTitle="Let's specify your plan"
                  isFooterHidden={true}
                 />
                </div>
              </>
              )
            }
            {loading<100 && <div className="loading-pantry">
              <div className="pentry-circle-wrapper">
                <div className="pentry-circle">
                  <Circle
                    percent={loading}
                    strokeWidth="13"
                    strokeColor="#52C41A"
                    trailWidth="8"
                    trailColor="#52C41A4F"
                  />
                  <p data-testid="pentry-circle-percent">
                    <span>{loading}%</span>
                  </p>
                </div>
              </div>
              <div className="loading-title">
                <div style={{display: 'flex', flexDirection: 'column', gap: '2px',
                  marginTop: '10px'}}><p>Loading your meals to</p>
                <p>your pantry...</p></div>
                </div>
            </div>}
              {provider.map((p, i) => (
                <ProviderPayment index={i} key={p.id} />
              ))}
              {!(loading<100) && 
              <div className='loading-title'>
              <button className='loading-button'  onClick={() =>history.push('/shop/pantry')}
               >{isGuest || isVisitor ? "My Coach": "Progress"}</button>
             </div>}
          </>
        )}
        </div>
       {(isGuest || isVisitor || (user.type.includes('user') && !(loading<100))) && isMobile &&  <PantryFooter/>}  
    </ThanksStyle>
    </>
  );
}
