import styled from 'styled-components';
import Select, { components } from 'react-select';
import Input from 'components/Input';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
  } from 'react-google-places-autocomplete';
import { fromAddress } from 'react-geocode';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import BringMealPrepModal from 'components/shop/CreateMealPlanForm/BringMealPrepModal';
import { useShopContext } from 'context/shopContext';
 
const AddressEditFormStyle = styled.div`
 label{
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #51635D;
 }
  input{
   margin-top:7px;
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 400;
   font-size: 14px !important;
   line-height: 16px;
   color:#51635D;
   &::placeholder{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
   }
 }
 .selectWrapper{
    display: flex;
    flex-direction: column;
    width:100% !important;
    margin-top:20px;

    .labels{
      display:flex;
      column-gap:2.49%;
      margin-top: 7px;

     .label-tag{
      // width:67px;
      width: 23.13%;
      height:32px;
      display:flex;
      align-items:center;
      justify-content:center;
      column-gap:8px;
      border: 1px solid #E0E3E0;
      border-radius: 16px;
      .image{
        margin-top: 2px;
      }
      p{
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #51635D;
        font-family: 'Roboto';
        font-style: normal;
        text-transform: capitalize;
      }
      &.active{
        border: 1px solid #CBEDBA;
        .image{
           img{
            filter: invert(57%) sepia(46%) saturate(1920%) hue-rotate(62deg) brightness(107%) contrast(80%);
           }
        }
        p{
          color: #52C41A;
        }
      }
     }
    }
      
  }
  .input2{
    margin-top:14px;
  }
  .input4{
    margin-top:5px;
  }

  .input3{
    textarea{
     height:111px;
     background: #FFFFFF;
     border-radius: 6px;
     border: 1px solid #E0E3E0;
     margin-top:7px;
     font-family: 'Roboto';
     font-style: normal;
     padding-top:12px;
     font-weight: 400;
     font-size: 14px !important;
     line-height: 16px;
     color:#51635D;
     padding-inline: 16px; 
     &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #949D9B;
     }      
    }
  }
  .button-wrapper{
    width: 100%;
    margin:32px 0 9px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    button{
        width: 95%;
        height: 40px;
        border-radius: 21px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        cursor:pointer;
      
        &:first-child{
          background-color: #52C41A; 
          color: #FFFFFF; 
        }
        &:nth-child(2){
          background: #FFFFFF;
          border: 1px solid #E0E3E0 !important;
          color: #51635D;
          margin-top:12px;
        } 
    }
    
  }
  .address-input{
     margin-top:10px;
  }

 `
 ;
 const GooglePlacesAutocompleteStyle = styled.div`
  margin-top:7px;
 .css-9ekey3-singleValue {
   padding: 0 35px 0 0;
   top:50%;
   font-weight: 400;
   font-size: 14px;
   color:#51635D;
 }
 .css-1uwmtyr{
  padding:0;
  margin:0;
 }
 .css-yk16xz-control .css-1hwfws3,
 .css-1pahdxg-control .css-1hwfws3 {
   padding: 0 30px 0 14px !important;
 }
 .css-yk16xz-control{
    border-color: #E0E3E0;
    border-radius: 6px;
 }
 .css-1l0hnm3-placeholder{
     padding-left:0;
     color:#949D9B;
     font-weight: 400;
     font-size: 14px;
 }
 .css-yk16xz-control,
 .css-1hwfws3,
 .css-1pahdxg-control {
   height: 44px;
   line-height: 44px !important;
   div {
     height: 44px;
     line-height: 44px;
   }
   input {
     position: relative;
     top: -7px;
     height: 44px;
     background-color: transparent;
   }
   .css-8sibub-placeholder {
     top: 20px;
     line-height: 44px;
   }
   .css-eeok4o {
     top: 50%;
     height: 44px;
     margin-top: 0;
     padding: 0 0 0 25px;
     line-height: 44px;
   }
 }
 .css-1pahdxg-control {
   height: 44px;
   border: 1px solid #52c41a;
   box-shadow: none;
   &:hover {
     border-color: #52c41a;
   }
 }
 .css-26l3qy-menu {
   postion: relative;
   top: 43px;
   bottom: auto;
   z-index: 4;
   border: 1px solid #52c41a;
   box-shadow: none;
   & > div > div {
     &:first-child {
       background-color: #52c41a5e;
       color: #000;
     }
     &:hover {
       background-color: #52c41a5e;
     }
   }
 }
 @media(max-width: 991px){
   .css-26l3qy-menu {
     overflow:scroll;
     max-height: 100px;
   }
 }
`
; 

 export default function AddressEditForm({setDeleteModal,createForm,form,setForm,handleDelete,setAddressId}){
   
    
   const [value, setValue] = useState('');
   const {user,setUser,userDeliveryAddress, setUserDeliveryAddress } = useAppContext();
   const [customvalue, setCustomvalue] = useState();
   const [saveDeliveryAddress,{isLoading}]=useRequest("user-delivery-address/","post");
   const history = useHistory();
   const {id} = useParams(); 
   const [getTaxRate] = useRequest();
   const [taxRate, setTaxRate] = useState(0);
   const [taxError, setTaxError] = useState(null);
   const [zip, setZip] = useState('');
   const [bringModal, setBringModal] = useState(false);
   
   useEffect(async()=>{
    if(id){
     const addressData = userDeliveryAddress?.find((item)=>item.id===Number(id))
    await  setForm({apartment:addressData.address.address.apartment,place_id:addressData.address.address.value.place_id,prevType:addressData.type,type:addressData.type,instruction:addressData.address.address.instruction,customType:addressData.name})
    setValue(addressData.address.address);
    setCustomvalue(addressData);
    }
   },[id])
  
      const Labels = [
        {id:1, Img: <img src='/icons/house.svg' />, Name: 'home' },
        {id:2, Img: <img src='/icons/buliding2.svg' />, Name: 'office' },
        {id:3, Img: <img src='/icons/gym.svg' />, Name: 'gym' },
        {id:4, Img: <img src='/icons/building.svg' />, Name: 'other' }
      ]
       

      const handleChange = (e, key) => {
        setForm({ ...form, [key]: e.target.value,place_id:customvalue?.address?.address?.value?.place_id,prevType:customvalue?.type});
      };

      const handletype = (value) => {
        setForm({ ...form, type: value,place_id:customvalue?.address?.address?.value?.place_id,prevType:customvalue?.type});
      };

      const handleSelect = async (value) => {
        setTaxRate(null);
        setTaxError(null);
        const [place] = await geocodeByPlaceId(value.value.place_id);
        const { long_name: postalCode = '' } =
        place.address_components.find((c) => c.types.includes('postal_code')) || {};
        setZip(postalCode);
        postalCode && await getTaxRate({ path: `payment/tax/${postalCode}` }).then((res) =>
       {
        if (res?.status === false) {
          setTaxError(res.message);
            console.log(res);
        }
        else {
          setTaxRate(res.rate);
          console.log(res);   
        }
       }
      ).catch((err)=>{
        console.log('error..........',err);
      });
      };
      
      useEffect(() => {
        if(value){
          setForm({ ...form, address:value,place_id:customvalue?.address?.address?.value?.place_id,prevType:customvalue?.type,});
          handleSelect(value);
        }
      }, [value]);

      const saveUserDeliveryAddress=async ()=>{
        await saveDeliveryAddress({body:{id:Number(id),address:{...form.address,apartment:form.apartment,instruction:form.instruction},type:form.type,customType:form.customType,prevType:form.prevType,place_id:form.place_id}})
        .then((result)=>{
          const updatedData=userDeliveryAddress?.map((item)=>{

            if(item.id===result.data.id){
            return result.data
            }
            else {
            return item;
            }
          })
  
          const doesIdExist=userDeliveryAddress?.find((item)=>item.id===result.data.id)
          if(!doesIdExist){
            setUserDeliveryAddress([...userDeliveryAddress, result?.data]);
          } else {
            setUserDeliveryAddress(updatedData)
          }
           toast.success('Address updated successfully');
          console.log(result)
        }).catch((err)=>{
          console.log(err)
        })
      }

      const handleClick = async () => {
        if (!zip){
          toast.error(`Couldn't find zip code for this address, Please try to add zip code with your address`);
       }
        else if(taxError){
         setBringModal(true)
       }
        else{
          await saveUserDeliveryAddress()
         .then((response)=>{
            history.push('/shop/profile');
          window.scrollTo(0, 0);
        }
         )
      };
    }

    return(
        <AddressEditFormStyle>
       
          <div className='address-input'>
          <label>
            Address 1 <span style={{ color: '#EB3223' }}>*</span>
          </label>
          <GooglePlacesAutocompleteStyle>
              <GooglePlacesAutocomplete
                GooglePlacesDetailsQuery={{ fields: 'geometry' }}
                fetchDetails={true}
                selectProps={{
                  value,
                  placeholder:'Address',
                  noOptionsMessage: () => null,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      height: '44x',
                      lineHeight: '44px',
                      // paddingLeft: '15px',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: 'none',
                      height: '47px',
                      lineHeight: '47px',
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      height: '47px',
                      lineHeight: '47px',
                      display: 'none',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      height: '47px',
                      lineHeight: '40px',
                      top: '50%',
                      paddingLeft: '15px',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      top: '50%',
                      marginTop: '0px',
                      paddingLeft: '15px',
                      height: '47px',
                      lineHeight: '37px',
                    }),
                  },
                  onChange: setValue,
                }}
              />
            </GooglePlacesAutocompleteStyle>
          </div>
        <Input
          name="apartment"
          value={form.apartment}
          onChange={(e) => handleChange(e, 'apartment')}
          bordered
          type="text"
          placeholder="Apartment, Suite or Floor"
          className='input2'
        />
        <div className='selectWrapper'>
        <label>
            Address Label 
        </label>
        <div className='labels'>
         {
          Labels.map((item)=>{
            return (
              <div className={`label-tag ${item.Name===form.type && 'active'}`} onClick={() => handletype(item.Name)}>
                <div className='image'>{item?.Img}</div>
                <p>{item?.Name}</p>
              </div>
            )
          })
         }
        </div>
        </div>
        {form.type==='other' &&
           <Input
           name="customType"
           value={form.customType}
           onChange={(e) => handleChange(e, 'customType')}
           bordered
           type="text"
           placeholder="Add name of label"
           className='input4'
           maxLength={14}

         />
        }
        
        <Input
          label="Special Instructions"
          name="instruction"
          value={form.instruction}
          onChange={(e) => handleChange(e, 'instruction')}
          bordered
          type="textarea"
          placeholder="Special instrcutions"
          className='input3'
        />

        <div className="button-wrapper">
          <button
           className={classNames({
              disabled:isLoading || !value  || form.type==='other' && form.customType===null || form.type==='other' && form.customType==='' || form.place_id===undefined,
            })}
           disabled={isLoading || !value  ||  form.type==='other' && form.customType===null || form.type==='other' && form.customType==='' || form.place_id===undefined}
           onClick={handleClick}>
           Update
          </button>
          <button onClick={()=>{setDeleteModal(true),setAddressId(id)}}>
            Delete Address
          </button>
        </div>

        {
          bringModal &&
          <BringMealPrepModal
            bringMealModal={bringModal}
            setBringMealModal={setBringModal}
            value={value}
            zip={zip}
          />
        }
      </AddressEditFormStyle>
    )
 }