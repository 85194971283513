import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const SaveAddressWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .saveAddress-header {
    margin: 16px 0 14px;
  }

  .input-mask {
    position: fixed;
    inset: 0;
    z-index: 2;
  }

  .saveAddress-container {
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  .address-map-wrapper {
    margin-bottom: 14px;
    padding: 0 19px;
    & > div:first-child {
      position: relative !important;
      height: 123px !important;
      & > div {
        border: 1px solid ${() => theme.colors.grayLight};
        border-radius: 10px;
      }
    }
    .gm-style {
      overflow: hidden;
      border-radius: 10px;
    }
    .address-info {
      padding: 14px 5px;
      border-bottom: 1px solid ${() => theme.colors.grayLight};
      font-family: ${() => theme.typography.fontGilroy};
      font-size: 16px;
      font-weight: 600;
      .address-first {
        color: ${() => theme.colors.primaryDarker};
      }
      .address-second {
        color: #51635d;
      }
    }
  }

  .address-details-wrapper {
    margin: 0 24px;
    label,
    input,
    textarea {
      color: ${() => theme.colors.primaryDarker};
      font-family: ${() => theme.typography.fontGilroy};
    }
    label {
      margin: 0;
      line-height: 32px;
      font-size: 14px;
      font-weight: 600;
    }
    input,
    textarea {
      position: relative;
      z-index: 3;
      height: 44px;
      padding: 0 14px;
      border-color: ${() => theme.colors.lighterGrey};
      font-weight: 500;
      &:focus,
      &:hover {
        border-color: ${() => theme.colors.primaryDarker} !important;
      }
    }
  }

  .apt-number,
  .address-type-wrapper {
    margin: 14px 0;
  }

  .address-type-wrapper {
    position: relative;
    overflow: hidden;
    .address-types {
      position: relative;
      z-index: 3;
      margin-top: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      transition: transform 0.5s linear;
      &.typesWithCustomInput {
        transform: translateX(-210px);
        transition: transform 0.5s linear;
      }
      .label-tag {
        max-width: 90px;
        height: 32px;
        padding: 0 10px;
        border: 1px solid #e0e3e0;
        border-radius: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
        &.active {
          border-color: ${() => theme.colors.primaryDefault};
        }
        .image img {
          width: 14px;
          height: 14px;
          display: flex;
          object-fit: cover;
        }
        .type {
          margin-top: 2px;
          color: ${() => theme.colors.primaryDarker};
          font-family: ${() => theme.typography.fontGilroy};
          font-size: 10px;
          font-weight: 700;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .custom-input {
      input {
        width: calc(100vw - 125px);
        height: 32px;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #e0e3e0;
      }
      &.customEditInput input {
        width: calc(100vw - 142px);
      }
    }
  }

  .delivery-instructions {
    textarea {
      height: 88px;
      padding: 9px 14px;
    }
  }

  .save-btn-wrapper {
    width: 100%;
    margin: 20px 0 35px;
    background-color: ${theme.colors.secondary};
    display: flex;
    justify-content: center;
    .save-address-btn {
      width: 271px;
      height: 42px;
      border-radius: 30px;
      background-color: ${() => theme.colors.primaryDefault};
      color: ${() => theme.colors.secondary};
      font-family: ${() => theme.typography.fontGilroy};
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      &[disabled] {
        background: ${() => theme.colors.lightGrey};
        color: ${() => theme.colors.grayDark};
      }
    }
  }

  .delete-address {
    margin-top: 15px;
    color: #eb3223;
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    display: inline-block;
  }

  @media (min-width: 992px) {
    .address-map-wrapper {
      padding: 0 16px;
    }

    .address-map-wrapper .address-info {
      padding: 8px 0 14px;
    }

    .address-save-body {
      overflow: auto;
      height: 497px;
    }

    .address-details-wrapper {
      margin: 0 16px;
    }

    .address-type-wrapper {
      .address-types .label-tag {
        cursor: pointer;
        .type {
          margin-top: 0;
        }
      } 
      .custom-input,
      .custom-input.customEditInput {
        input {
          width: 292px;
        }
      }
    }

    .save-btn-wrapper {
      margin: 24px 0 16px;
      padding: 0 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      .cancel-btn {
        height: 42px;
        border-radius: 30px;
        background-color: ${theme.colors.grayLighter};
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
      }
      .save-address-btn {
        width: auto;
        cursor: pointer;
      }
    }
  }
`;
