import styled from 'styled-components';
import { useState, useEffect } from "react";
import StackSlider from "./UserOnboarding/components/StackSlider";

const MacrosBreakdownStyle = styled.div`
    width: 100%;
    padding: 14px 20px 30px;
    flex: 1;

    .title{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color:#51635D;
        margin-bottom: 48px;
    }
   
`
export default function MacrosBreakdown({ preferredDiet, setPreferredDiet, setProfile }) {
    const [activePreference, setActivePreference] = useState('Balanced');
    const [type, setType] = useState('balanced');

    useEffect(() => {
        if (preferredDiet?.fatPercent == 30 && preferredDiet?.carbsPercent == 40 && preferredDiet?.proteinPercent == 30) {
            setActivePreference("Balanced");
            setType('balanced');
        } else if (preferredDiet?.fatPercent == 50 && preferredDiet?.carbsPercent == 20 && preferredDiet?.proteinPercent == 30) {
            setActivePreference("Low Carb");
            setType('low-carb');
        } else if (preferredDiet?.fatPercent == 20 && preferredDiet?.carbsPercent == 30 && preferredDiet?.proteinPercent == 50) {
            setActivePreference("Low Fat");
            setType('low-fat');
        } else {
            setActivePreference('');
            setType('custom');
        }
    }, [preferredDiet]);

    const macros = ['fatPercent', 'carbsPercent', 'proteinPercent'];

    const handleMacroChanges = (name, value) => {
        const index = macros?.indexOf(name);
        const diff = preferredDiet[name] - value;
        const newVals = macros?.reduce(
            ({ diff, changes, index }, _) => {
                if (diff == 0) return { diff, changes, index };
                const nextName = macros[(index + 1) % macros?.length];
                const nextVal = preferredDiet[nextName] + diff;
                if (nextVal <= 100 && nextVal >= 0)
                    return { diff: 0, changes: { ...changes, [nextName]: nextVal } };
                if (nextVal > 100)
                    return {
                        diff: nextVal - 100,
                        changes: { ...changes, [nextName]: 100, index: index + 1 },
                    };
                if (nextVal < 0)
                    console.log("less than 0", { nextName, nextVal, changes });
                return {
                    diff: nextVal,
                    changes: { ...changes, [nextName]: 0 },
                    index: index + 1,
                };
            },
            { diff, changes: { [name]: value }, index },
        );
        const newPrefDiet = {...preferredDiet, ...newVals.changes};
        setPreferredDiet(newPrefDiet);
        setProfile((prev) => ({
            ...prev,
            selfMealPlan: {
                ...prev.selfMealPlan,
                preferredDiet: newPrefDiet,
            }
        }))
    };

    return (
        <MacrosBreakdownStyle>

            <StackSlider preferredDiet={preferredDiet} type="fat" handleMacroChanges={handleMacroChanges} />
            <StackSlider preferredDiet={preferredDiet} type="protein" handleMacroChanges={handleMacroChanges} />
            <StackSlider preferredDiet={preferredDiet} type="carbs" handleMacroChanges={handleMacroChanges} />

        </MacrosBreakdownStyle>
    )
}