import classNames from 'classnames';
import styled from 'styled-components';
import PantryFooter from 'components/Pantry/PantryFooter';
import PlanListHeader from 'components/shop/CoachProfile/PlansList';
import PlanCard from 'components/shop/CoachProfile/PlansList/PlanCard';
import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { formatNumberDecimalPlaces } from '../../../utils';
import Header from 'layout/Header';
import { useLocation } from 'react-router-dom';
import MealCard from './../PublicUrlLandingPage/MealCard';
const OfferPlansListStyle = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0 50px !important;
  justify-content: space-between;
 
  .local{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #949D9B;
    display:flex;
    justify-content:center;
    margin: 15px 0px 25px;
  }
  .plan-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 991px) {
    min-height: calc(100vh - 32px);
    background-color: #f6f5f8;
    padding: 0px 0 50px !important;

    .local{
      margin: 15px 0px 15px !important;
    }
  
  }
`;

export default function CoachingPlanList() {
  const { offerList } = useAppContext();
  console.log('offr......',offerList)
  const { isMobile , user } = useAppContext();
  const { pathname } = useLocation();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));

  return (
    <>
      {/* <>
        {(pathname.startsWith('/guest') && isMobile) && 
        <Header
         activeSteps="2"
        />}
      </> */}
      <>
      {/* {!( user?.id || selectedOfferDetail?.userId ) && <Header activeSteps="2"/>} */}
      {(pathname.startsWith('/guest') ) && <Header activeSteps="2"/>}
      </>
      <OfferPlansListStyle>
      <div className='local'>
        <p>BOOK A SESSION WITH ME</p>
        </div>
        <div className={classNames('plan-card-wrapper')}>
        {offerList?.map((item) => {
          return (
            <MealCard
              OfferPlanId={item?.id}
              nutritionistId={item?.nutritionistId}
              name={item?.title}
              availability={item?.availability}
              duration_type="minutes"
              price={formatNumberDecimalPlaces(item?.price / 100)}
              introduction={item?.description}
              offerPlan={true}
              image={item?.img}
            />
          );
        })}
        </div>
      </OfferPlansListStyle>
      {(isMobile && user ) && <PantryFooter myCoach={true} />}
    </>
  );
}

