import { memo } from 'react';
import { RemoveAddressModalWrapper } from './style';
import { motion } from 'framer-motion';

function RemoveAddressModal({ setIsRemoveAddressModal, handleDelete, title }) {
  return (
    <RemoveAddressModalWrapper>
      <motion.div
        initial={{ opacity: 0, y: 500, width: '100%' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 500, transition: 'all .5 ease-out' }}
        transition={{
          duration: 0.5,
          delay: 0.3,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <div className="remove-address-container">
          <div className="close-modal-wrapper" onClick={() => setIsRemoveAddressModal(false)}>
            <img src="/icons/close-black.svg" alt="Close Icon" />
          </div>
          <p>{title}</p>
          <div className="control-btn-wrapper">
            <button onClick={handleDelete}>Yes</button>
            <button className="cancel" onClick={() => setIsRemoveAddressModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </motion.div>
    </RemoveAddressModalWrapper>
  );
}

export default memo(RemoveAddressModal);
