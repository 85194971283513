import React from 'react'

const HighProtein = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_3899_28099)">
                <path d="M6.68064 18.4835C5.80631 18.4835 4.70844 17.8916 3.28647 17.1256L2.6427 16.7789C1.85916 16.356 1.18258 15.9911 0.653251 15.7792C0.325186 15.6478 0.165883 15.2758 0.296804 14.9482C0.42803 14.6204 0.799888 14.4603 1.12826 14.592C1.72579 14.8307 2.43213 15.2117 3.2503 15.6534L3.89331 15.9998C5.33024 16.774 6.5717 17.4433 6.91121 17.1046C7.25041 16.765 6.58146 15.5242 5.80692 14.0876L5.46085 13.4453C4.31903 11.3266 3.56494 9.92709 4.57676 8.91528C5.58903 7.90224 6.98918 8.65756 9.10756 9.79999L9.7463 10.1439C11.1846 10.9192 12.4262 11.5885 12.7663 11.2497C13.1055 10.9105 12.4352 9.66769 11.6593 8.22863L11.3146 7.58852C10.1731 5.4712 9.41899 4.07273 10.4311 3.06046C11.4445 2.04727 12.8451 2.80274 14.9651 3.94623L15.599 4.28788C17.038 5.0641 18.2809 5.73518 18.6214 5.3946C18.9607 5.0554 18.2893 3.81073 17.5118 2.36969L17.3425 2.05551C17.1755 1.74438 17.2919 1.35681 17.6029 1.18942C17.9137 1.02279 18.3016 1.13891 18.469 1.44958L18.6378 1.76239C19.7823 3.88459 20.5386 5.28642 19.5255 6.299C18.512 7.31203 17.1117 6.55641 14.992 5.41322L14.3581 5.07173C12.9188 4.29536 11.6758 3.6258 11.3357 3.96454C10.9968 4.30375 11.6657 5.5446 12.4404 6.98122L12.7851 7.62102C13.9281 9.74078 14.6834 11.1409 13.6709 12.1538C12.6572 13.1661 11.258 12.4112 9.13976 11.2691L8.50087 10.9252C7.06288 10.1502 5.82172 9.48062 5.4816 9.81906C5.1424 10.1581 5.81165 11.3997 6.58665 12.8383L6.93242 13.4806C8.07423 15.5976 8.82863 16.9961 7.81575 18.0086C7.48326 18.3416 7.10881 18.4835 6.68064 18.4835Z" fill={color || "#757E89"} />
                <path d="M2.09471 18.2036C1.86705 18.2036 1.64641 18.0814 1.53105 17.8665L1.36 17.5491C0.217116 15.4298 -0.538042 14.0297 0.474532 13.0174C1.4868 12.0052 2.88375 12.7583 4.99969 13.8991L5.64239 14.2455C6.42806 14.6687 7.10632 15.0342 7.63702 15.2469C7.96478 15.3783 8.12408 15.7503 7.9927 16.078C7.86148 16.4056 7.48947 16.5651 7.16171 16.4342C6.5631 16.1948 5.8554 15.813 5.03585 15.3713L4.39254 15.0249C2.95729 14.251 1.71706 13.5817 1.37907 13.9215C1.03987 14.2607 1.71019 15.5029 2.4858 16.9423L2.65761 17.2611C2.82454 17.5717 2.70812 17.9601 2.39714 18.1271C2.30086 18.1787 2.19725 18.2036 2.09471 18.2036Z" fill={color || "#757E89"} />
                <path d="M19.1082 4.77063C19.0293 4.77063 18.9489 4.75613 18.8709 4.72501C18.2709 4.48483 17.5608 4.10168 16.738 3.65796L16.1118 3.32028C14.6717 2.5436 13.4284 1.87283 13.0878 2.21234L11.3357 3.96454C11.0859 4.21464 10.6803 4.2149 10.4309 3.96481C10.1811 3.71518 10.1813 3.30994 10.4311 3.06046L12.1837 1.30825C13.1966 0.29491 14.5983 1.05053 16.7189 2.19463L17.3459 2.53247C18.1334 2.95758 18.8141 3.3247 19.3462 3.53711C19.6738 3.66803 19.8336 4.0405 19.7024 4.36841C19.6026 4.61865 19.3621 4.77063 19.1082 4.77063Z" fill={color || "#757E89"} />
                <path d="M13.2181 12.342C13.0546 12.342 12.8911 12.2792 12.766 12.1549C12.5159 11.9051 12.5159 11.4998 12.7657 11.2503L14.5182 9.49755C14.8577 9.15804 14.1877 7.91551 13.4118 6.47675L13.0688 5.84045C12.6272 5.02075 12.2454 4.31274 12.006 3.71444C11.8747 3.38667 12.0344 3.01436 12.3621 2.88344C12.6894 2.75175 13.0622 2.91151 13.1931 3.23958C13.4055 3.77028 13.7713 4.44823 14.1947 5.23346L14.5373 5.8699C15.6805 7.98921 16.4356 9.38906 15.423 10.4021L13.6706 12.1549C13.5459 12.2792 13.382 12.342 13.2181 12.342Z" fill={color || "#757E89"} />
                <path d="M13.2514 10.6244C13.172 10.6244 13.0921 10.6095 13.0136 10.5783C12.4147 10.3386 11.7067 9.95685 10.8864 9.5145L10.2529 9.173C8.81487 8.3977 7.57357 7.72814 7.23345 8.06689L5.48097 9.81967C5.23119 10.0698 4.82622 10.0698 4.57643 9.81967C4.32664 9.56989 4.32697 9.16506 4.57676 8.91528L6.32891 7.16234C7.34087 6.14915 8.74133 6.90432 10.8597 8.04705L11.4933 8.38824C12.2794 8.81228 12.958 9.17819 13.489 9.39075C13.817 9.52213 13.9765 9.89414 13.8451 10.2222C13.7453 10.4724 13.505 10.6244 13.2514 10.6244Z" fill={color || "#757E89"} />
                <path d="M7.36328 18.1963C7.19986 18.1963 7.03628 18.1336 6.91116 18.0092C6.66107 17.7591 6.66142 17.3539 6.91121 17.1046L8.66332 15.3519C9.00283 15.0128 8.33465 13.7726 7.56042 12.337L7.21359 11.6934C6.772 10.8739 6.39053 10.1665 6.15158 9.56848C6.02035 9.24072 6.17996 8.86841 6.50772 8.73749C6.83502 8.60626 7.20779 8.76556 7.33871 9.09363C7.55111 9.62402 7.91626 10.3015 8.33953 11.0866L8.68621 11.7305C9.82696 13.8464 10.5804 15.2445 9.56786 16.2564L7.81575 18.0086C7.69109 18.1332 7.52716 18.1963 7.36328 18.1963Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_3899_28099">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HighProtein