import styled from 'styled-components';
import Card from './components/Card';
import Input from 'components/Input';
import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;

  .height-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 310px;
  }

  .questions-container {
    width: 60%;
  }

  .shape-info {
    label {
      display:none;
    }

    .input-wrapper {
      position: relative;
      margin-top: 0;
      margin-bottom: 24px;

      & > div {
        margin-top: 0;
      }

      .input-wrapper {
        margin-bottom: 0;
      }

      &:after {
        position: absolute;
        top: 10px;
        right: 20px;
        color: #747c7a;
        font-size: 20px;
        font-weight: 600;
      }

      &.age-input:after {
        content: 'years';
      }

      &.weight-input:after {
        content: 'lbs';
      }

      .height-feet-input:after {
        content: 'ft';
      }

      .height-inches-input:after {
        content: 'in';
      }
    }

    .height-input {
      width: 100%;
      gap: 8%;
    }

    input {
      position: relative;
      width: 100% !important;
      height: 47px;
      padding: 0 70px 0 16px;
      background-color: #fff;
      color: #0b1c18;
      font-size: 18px;
      border: 1px solid #e0e3e0;
      border-radius: 4px;
    }
  }

  @media (max-width: 991px) {
    padding: 0;

    .questions-container {
      width: 100%;
    }

    .sex-shape-wrapper {
      padding: 20px 20px 33px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);

      .sex-wrapper {
        & > div {
          overflow: hidden;
          height: 74px;
          margin-bottom: 20px;
          background: #ffffff;
          border: 1px solid #e0e3e0;
          border-radius: 6px;
          display: flex;
          grid-gap: 0;
          flex-wrap: nowrap;
          box-shadow: none;
        }

        div[name='Male'],
        div[name='Female'] {
          height: 74px;
          border: 0;
          border-radius: 0;
          box-shadow: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
          }

          .card-content {
            p {
              margin: 3px 0;
              color: #949d9b;
              font-size: 14px;
              font-family: 'Roboto';
              line-height: 17px;
            }
          }
        }

        div[name='Male'] {
          border-right: 1px solid #e0e3e0;
        }
      }
    }

    .shape-info {
      input {
        height: 44px;
        border-radius: 6px;
      }

      .input-wrapper {
        margin-bottom: 20px;

        &.weight-input {
          margin-bottom: 33px;
        }

        &:after {
          top: 14px;
          right: 16px;
          color: #949d9b;
          font-size: 14px;
          font-family: 'Roboto';
          font-weight: normal;
          line-height: 17px;
        }
      }

      .height-input {
        grid-gap: 11px;
      }
    }
  }
`;

const Heading = styled.h4`
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
  color: #51635d;
  span{
    color:red;
  }

  @media only screen and (max-width: 991px) {
    margin: 0 0 8px 0;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 14px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: '100%';

  @media only screen and (max-width: 991px) {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px 15px;

    button {
      margin: 0px 6px;
      margin-bottom: 16px;
    }

    .nutritions-wrapper {
      margin: 18px;
    }
  }
`;

export default function Questions({ questions, setQuestions, validationError, nextButton }) {
  const { goal, activity, gender, age, heightFeet, heightInches, weight } = questions;
  const toastId = useRef(null);
  const { isMobile } = useAppContext();

  const handleClick = (name, value) => {
    setQuestions((prevQuestions) => ({ ...prevQuestions, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === '' || value === undefined) {
      setQuestions((prevQuestions) => ({ ...prevQuestions, [name]: value }));
      return;
    }
    if (/^\d+$/.test(value)) {
      setQuestions((prevQuestions) => ({ ...prevQuestions, [name]: value }));
      return;
    } else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(`Only Numeric values allowed for this field`);
      }
    }
  };

  return (
    <Wrapper>
      {/* <div className="card-wrapper">
          <Heading>What is your goal?</Heading>
          <CardContainer>
            <Card
              icon={scale}
              name="Loose Weight"
              onSelect={() => handleClick("goal", "loose")}
              selected={goal === "loose"}
            />
            <Card
              icon={balance}
              name="Maintain"
              onSelect={() => handleClick("goal", "maintain")}
              selected={goal === "maintain"}
            />
            <Card
              icon={dumble}
              name="Gain Weight"
              onSelect={() => handleClick("goal", "gain")}
              selected={goal === "gain"}
            />
          </CardContainer>
        </div> */}

      {/* { value: 1, label: "None" },
              { value: 2, label: "Low" },
              { value: 3, label: "Average" },
              { value: 4, label: "High" },
              { value: 5, label: "Extreme" }, */}
      <div className="questions-container">
        <div>
          {!isMobile && <Heading>What does your typical day look like?</Heading>}
          <CardContainer>
            <Card
              icon={'icons/inactive.svg'}
              name="Inactive"
              desc="I need to move more"
              onSelect={() => handleClick('activity', 1)}
              selected={activity === 1}
              height="217px"
            />
            <Card
              icon={'icons/slightlyActive.svg'}
              name="Slightly Active"
              desc="Daily life activity only"
              onSelect={() => handleClick('activity', 2)}
              selected={activity === 2}
              height="217px"
            />
            <Card
              icon={'icons/moderatelyActive.svg'}
              name="Moderately Active"
              desc="Slow workouts"
              onSelect={() => handleClick('activity', 3)}
              selected={activity === 3}
              height="217px"
              iconWidth="40px"
            />
            <Card
              icon={'icons/active.svg'}
              name="Active"
              desc="Workout 3-4x weekly"
              onSelect={() => handleClick('activity', 4)}
              selected={activity === 4}
              height="217px"
            />
            <Card
              icon={'icons/extremelyActive.svg'}
              name="Extremely Active"
              desc="I'm always on the move!"
              onSelect={() => handleClick('activity', 5)}
              selected={activity === 5}
              height="217px"
            />
          </CardContainer>
        </div>
        <div className="sex-shape-wrapper">
          <div className="card-wrapper">
            {!isMobile ? <Heading>You are a <span>*</span></Heading> : <Heading>What's your gender <span>*</span></Heading>}
            <div className="sex-wrapper">
              <CardContainer>
                <Card
                  icon={'icons/male.svg'}
                  name="Male"
                  onSelect={() => handleClick('gender', 'm')}
                  selected={gender === 'm'}
                  iconWidth="40px"
                  maleOrFemale
                />
                <Card
                  icon={'icons/female.svg'}
                  name="Female"
                  onSelect={() => handleClick('gender', 'f')}
                  selected={gender === 'f'}
                  iconWidth="40px"
                  maleOrFemale
                />
              </CardContainer>
            </div>
          </div>
          <div className="shape-info">
            <Heading>Age <span>*</span></Heading>
            <div className="input-wrapper age-input">
              <Input
                // label="Age"
                name="age"
                value={age}
                onChange={handleChange}
                bordered
                required
                type="number"
                pattern="\d*"
                style={{ width: '310px' }}
                // min="1"

                // onBlur={(e) => {
                //   const { value } = e.target;
                //   if (!value) setName({ value, error: `Name is required` });
                // }}
              />
            </div>
            <Heading>Height <span>*</span></Heading>
            <div className="input-wrapper height-input">
              <div className="input-wrapper height-feet-input">
                <Input
                  // label="Feet"
                  name="heightFeet"
                  required
                  value={heightFeet}
                  onChange={handleChange}
                  type="number"
                  pattern="\d*"
                  bordered
                  style={{ width: '140px' }}
                />
              </div>
              <div className="input-wrapper height-inches-input">
                <Input
                  // label="Inches"
                  name="heightInches"
                  value={heightInches}
                  onChange={handleChange}
                  type="number"
                  pattern="\d*"
                  bordered
                />
              </div>
            </div>
            <Heading>Current weight <span>*</span></Heading>
            <div className="input-wrapper weight-input">
              <Input
                // label="Weight"
                name="weight"
                required
                value={weight}
                onChange={handleChange}
                type="number"
                pattern="\d*"
                bordered
                style={{ width: '310px' }}
              />
            </div>
          </div>
          {isMobile && nextButton}
        </div>
      </div>
    </Wrapper>
  );
}


