import React from 'react'
import styled from "styled-components";
import { theme } from 'shared/theme/theme';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

const DataCardStyle = styled.div`
    border-radius: 6px;
    outline: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    background-color: ${({ theme }) => theme?.colors?.white};
    padding-top: 22px;
    padding-bottom: 23px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    .pre_title_wrapper{
        display: flex;
        gap: 11px;
        align-items: center;
        color: ${({ theme }) => theme?.colors?.blackDefault};
        .dot_color{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
        }
        .pre_title{
            font-size: ${({ theme }) => theme?.typography?.fontBase};
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: ${({ theme }) => theme?.colors?.blackDefault};
        }
    }
    .title{
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 45px;
        color: ${({ theme }) => theme?.colors?.blackDefault};
        margin: 14px 0;
    }
    .post_title_wrapper{
        display: flex;
        align-items: center;
        gap: 20px;
        .data_percentage{
            font-size: ${({ theme }) => theme?.typography?.fontBase};
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 7px;
            &.up{
                color: ${({ theme }) => theme?.colors?.success};
                .icon{
                    color: ${({ theme }) => theme?.colors?.success};
                    display: block;
                }
            }
            &.down{
                color: ${({ theme }) => theme?.colors?.danger};
                .icon{
                    color: ${({ theme }) => theme?.colors?.danger};
                    display: block;
                }
            }
        }
        .vertical_line{
            width: 1px;
            height: 15px;
            background-color: ${({ theme }) => theme?.colors?.gray};
        }
        .profit_loss{
            color: ${({ theme }) => theme?.colors?.grayDarker};
            font-size: ${({ theme }) => theme?.typography?.fontBase};
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
    }
`

export const DataCard = ({ dotColor = '#2AA3EF', preTitle = 'Total Sales', title = '$12350.65', percentageType = 'up', percentageChange = '12%', dataChange = '$1253', showPercentageData = true }) => {
    return (
        <DataCardStyle className='data_card' theme={theme}>
            <div className='pre_title_wrapper'>
                {dotColor &&
                <span className='dot_color' style={{ backgroundColor: dotColor }}></span>}
                <p  className='pre_title'>{preTitle}</p>
            </div>
            <h2 className='title'>{title}</h2>
            {showPercentageData &&
            <div className='post_title_wrapper'>
                {dataChange && percentageChange && <>
                <div className={`data_percentage ${percentageType === 'up' ? 'up' : 'down'}`}>
                    {percentageType === 'up' ? <BsArrowUp className='icon' /> : <BsArrowDown className='icon' />} {percentageChange}
                </div>
                <div className='vertical_line'></div>
                <div className='profit_loss'>
                    {percentageType === 'up' ? '+' : '-'}{dataChange} this month
                </div></>
                }
            </div>
            }
        </DataCardStyle>
    )
}
