import React, { useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { useRequest } from 'hooks/useRequest';
import { BsCaretDownFill, BsCaretUp } from 'react-icons/bs';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TableWrapper = styled.div`
 display: flex;
  justify-content: center;
  margin-top: 40px;
  width:100%
  max-width: 95%;
  tr {
  text-align: center !important;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tr:hover {
    background-color: #ddd;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #52c41a;
    color: white;
    text-align: center !important;
    padding: 8px;
    width: 180px;
    border: 1px solid #ddd;
  }
  tr button {
    height: 40px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
`;
const HoverWrapper = styled.div`
  .pay_button {
    color: #52c41a;
  }
  .pay_button:hover {
    background-color: #52c41a !important;
    color: white;
  }
  .pay_button:focus {
    // box-shadow:none !important;
    box-shadow: 0 0 0 4px #52c41a !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export default function CoachingDisbursement({ disbursementData, isMealsReport }) {
  const [getDisbursement] = useRequest();
  const [expandedRows, setExpandedRows] = useState([]);
  const [offeringReport, setOfferingReport] = useState();
  const [expandState, setExpandState] = useState({});
  const [disable, setDisable] = useState(false);
  const handleExpandRow = (event, uuid) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(uuid);
    let obj = {};
    isRowExpanded ? (obj[uuid] = false) : (obj[uuid] = true);
    setExpandState(obj);
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== uuid)
      : currentExpandedRows.concat(uuid);
    setExpandedRows(newExpandedRows);
  };

  const getDisbursementFn = () => {
    const path = `financials/nutritionist-offer-payment-reports`;
    getDisbursement({ path, method: 'GET' })
      .then((data) => {
        setOfferingReport(data);
      })
      .catch(console.log);
  };

  const [updateDisbursement] = useRequest('financials/offering-disbursements/update', 'put');

  const onDisbursementClick = useCallback(
    (item) => {
      const ids = item.map((data) => data.id);
      updateDisbursement({ body: { ids, datePaid: new Date().toISOString() } })
        .then((result) => {
          toast.success('Disbursement has been made successfully');
          getDisbursementFn();
        })
        .catch((e) => {
          toast.error('Oops!, something went wrong');
        });
    },
    [isMealsReport],
  );

  const onVoidClick = useCallback((item = {}, isVoid) => {
    const ids = item.map((data) => data.id);
    updateDisbursement({ body: { ids, isVoid: true } })
      .then((result) => {
        toast.success('Record has been marked as void');
        getDisbursementFn();
      })
      .catch((e) => {
        toast.error('Oops!, something went wrong');
      });
  }, []);

  useEffect(getDisbursementFn, []);
  return (
    isMealsReport === 'offeringReport' && (
      <Row>
        <Col sm={12}>
          <Table>
            <thead>
              <tr>
                <th style={{ width: '450px' }}>Nutritionist</th>
                <th style={{ width: '150px' }}>Amount</th>
                <th style={{ width: '200px' }}>Period</th>
                <th style={{ width: '100px' }}></th>
                <th style={{ width: '100px' }}></th>
                <th style={{ width: '100px' }}></th>
              </tr>
            </thead>
            <tbody>
              {offeringReport?.length ? (
                offeringReport.map((item) => (
                  <>
                    <tr key={item.nutritionist.id}>
                      <td>{item?.nutritionist?.name}</td>
                      <td>${(item.total/100).toFixed(2)}</td>
                      <td>
                        {moment(item.periodStart).format('ll')} <strong>-</strong>{' '}
                        {moment(item.periodEnd).format('ll')}
                      </td>
                      <td>
                        <Button variant="link" onClick={() => handleExpandRow(event, item.uuid)}>
                          {expandState[item.uuid] ? <BsCaretUp /> : <BsCaretDownFill />}
                        </Button>
                      </td>
                      <td>
                        <HoverWrapper>
                          <Button
                            className="pay_button"
                            type="button"
                            variant="outline-primary"
                            onClick={() =>
                              onDisbursementClick(item.disbursementList) && setDisable(true)
                            }
                            disabled={disable}
                          >
                            Pay
                          </Button>
                        </HoverWrapper>
                      </td>
                      <td>
                        <Button
                          variant="outline-secondary"
                          onClick={() => onVoidClick(item.disbursementList)}
                        >
                          Void
                        </Button>
                      </td>
                    </tr>
                    <>
                      {expandedRows.includes(item.uuid) && (
                        <Table>
                          <thead>
                            <tr>
                              <th>Client Email</th>
                              <th style={{ width: '50px' }}>Coaching Disbursement Id</th>
                              <th style={{ width: '50px' }}>Nutritionist Meeting Id</th>
                              <th style={{ width: '70px' }}>Amount</th>
                              <th style={{ width: '140px' }}>Scheduled Date Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.disbursementList.map((item) => {
                              return (
                                <>
                                  <tr>
                                    <td>{item?.nutritionistMeeting?.user?.email}</td>
                                    <td>{item.id}</td>
                                    <td>{item?.nutritionistMeetingId}</td>
                                    <td>{(item.amount/100).toFixed(2)}</td>
                                    <td>
                                      {' '}
                                      {moment(
                                        item.nutritionistMeeting.NutritionistMeetingMapping[0]
                                          .scheduledDateTime,
                                      ).format('lll')}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                    </>
                  </>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td>No record</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  );
}
