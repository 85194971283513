import styled from 'styled-components';
import Tags from 'pages/Nutritionist/Followers/ClientsCart/Tags';
import { BsChevronRight } from 'react-icons/bs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOnboardingContext } from '..';

const DietaryModalStyle = styled.div`
    width: 100%;
    padding: 14px 20px 30px;
    flex: 1;

    .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #0B1D17;
        margin-bottom: 48px;
    }
    .subtitle{
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #51635D;
    }
    .skip{
        text-align: end;
        margin-bottom: 10px;
        span{
            display: inline-flex;
            align-items: center;
            gap: 6px;
            color: #52C41A;
            .icon{
                font-size: 18px;
            }
        }
    }
    &.active{
        animation: open 0.3s ease-in forwards;
    }
    &.inactive{
        animation: close 0.3s ease-in forwards;
    }
    @keyframes open{
        from{
            opacity: 0;
        }to{
            opacity: 1;
        }
    }
    @keyframes close{
        from{
            opacity: 1;
        }to{
            opacity: 0;
        }
    }
`

const TagsContainer = styled.div`
    margin: 32px 0px 0px;
    padding-bottom:30px;
    display: grid;
    grid-template-columns: repeat(3,minmax(77px,1fr));
    grid-row-gap: 25px;
    &:last-child{
        border-bottom:unset;
    }
    .icon-wrapper{
        &.selected{
            &.preference{
                border: 1px solid #97DC76;
                svg{
                    path{
                        fill: #52C41A;
                    }
                }
            }
            &.avoid{
                border: 1px solid rgba(254, 185, 90, 0.3);
                svg{
                    path{
                        fill: #FEB95A;
                    }
                }
            }
        }
    }
`;

export default function DietaryModal({ title, tags, setShowSelectedTags, showSelectedTags, type, isRestrictionModalActive, setIsRestrictionModalActive, animation, setAnimation }) {
    const [selectedTags, setSelectedTags] = useState({ tags: [], restrictions: [] });
    const { setOnboardingUser,isNewGoal } = useOnboardingContext();
    const history = useHistory();
    const handleTagClick = (selectedTag, isSelected, recomend) => {
        setSelectedTags((m) => ({
            ...m,
            tags: isSelected
                ? m.tags?.filter((tag) => tag !== selectedTag)
                : [...m.tags, selectedTag],
        }));

        setShowSelectedTags((prev) => ({
            ...prev,
            tags: isSelected ? prev.tags?.filter((tag) => tag !== selectedTag) : [...prev.tags, selectedTag]
        }))

    };

    const handleAlergyClick = (selectedTag, isSelected, avoid) => {
        setSelectedTags((m) => ({
            ...m,
            restrictions: isSelected
                ? m.restrictions?.filter((tag) => tag !== selectedTag)
                : [...m.restrictions, selectedTag],
        }));

        setShowSelectedTags((prev) => ({
            ...prev,
            restrictions: isSelected ? prev.restrictions?.filter((tag) => tag !== selectedTag) : [...prev.restrictions, selectedTag]
        }))

    };

    const onTagClick = (tag) => {
        if (type === 'preferences') {
            const isSelected = showSelectedTags?.tags?.indexOf(tag) !== -1;
            const recomend = showSelectedTags?.tags?.indexOf(tag) !== -1;
            handleTagClick(tag, isSelected, recomend)
        } else {
            const isSelected = showSelectedTags?.restrictions?.indexOf(tag) !== -1;
            const avoid = showSelectedTags?.restrictions?.indexOf(tag) !== -1;
            handleAlergyClick(tag, isSelected, avoid)
        }
    }

    const handleSkip = () => {
        if (!isRestrictionModalActive) {
            setIsRestrictionModalActive(true);
            setAnimation(true);
            setShowSelectedTags({ tags: [], restrictions: [] })
            return;
        }
        history.push(`/self-onboarding/preferred-diet${isNewGoal?'?newGoal=true':''}`);
        setShowSelectedTags({ tags: [], restrictions: [] })
    }

    return (
        <DietaryModalStyle className={`${isRestrictionModalActive && animation ? 'active' : ''} ${isRestrictionModalActive && !animation ? 'inactive' : ''}`}>
            <div className='skip'>
                <span onClick={handleSkip}>Skip <BsChevronRight className='icon' /></span>
            </div>
            <h2 className='title'>{title}</h2>
            <p className='subtitle'>Select all that apply</p>

            <TagsContainer>
                {tags?.map((tag, index) => (
                    <Tags tag={tag} key={index} onTagClick={() => onTagClick(tag)}
                        selectedTags={type === 'preferences' ? selectedTags?.tags : selectedTags?.restrictions}
                        isSelected={type === 'preferences' ? showSelectedTags?.tags?.indexOf(tag) !== -1 : showSelectedTags?.restrictions?.indexOf(tag) !== -1}
                        recomend={showSelectedTags?.tags?.indexOf(tag) !== -1}
                        avoid={showSelectedTags?.restrictions?.indexOf(tag) !== -1}
                        type={type === 'preferences' ? 'preference' : 'avoid'}
                    />
                ))}
            </TagsContainer>
        </DietaryModalStyle>
    )
}