import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';
import { useLocation } from 'react-router-dom';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .wrapper-1 {
    width: 150px;
    height: 150px;
    position: relative;
    margin-right: 16px;
    ${FlexCenter}
    p:nth-child(2) {
      top: 38%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      font-size: 16px;
      font-weight: bold;
    }
    p:nth-child(3) {
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      font-size: 12px;
      font-weight: bold;
    }
  }
  .wrapper-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    .dots-wrapper {
      display: flex;
      align-items: center;
      p:nth-child(2) {
        flex: 1;
      }
      .dots {
        width: 22px;
        height: 22px;
        border-radius: 11px;
        margin-right: 16px;
      }
      .dark {
        background-color: rgb(29, 166, 84);
      }
      .light {
        background-color: rgb(94, 201, 95);
      }
      .lighter {
        background-color: rgb(154, 228, 146);
      }
    }
  }
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e3e0;
  flex-direction: column;
  .wrapper-1 {
    position: relative;
    width: 205px;
    height: 205px;
    margin: 0 0 10px;
    .counting-calories-number {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      p:first-child {
        color: #0b1d17;
        font-size: 36px;
        font-family: 'Roboto';
        line-height: 43px;
      }
      p:last-child {
        position: static;
        color: #51635d;
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        transform: none;
      }
      & + div {
        ${FlexCenter}
      }
    }
    .tooltip-wrapper {
      height: 20px;
      padding: 0 5px;
      background-color: #f6f5f8;
      border-radius: 6px;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
      }
      span:first-child {
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
      span + span {
        margin-left: 5px;
      }
    }
  }
  .wrapper-2 {
    width: 205px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .legend-first {
      margin-bottom: 3px;
      color: #51635d;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
    }
    .legend-second {
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
    }
    .dots-wrapper {
      flex-direction: column;
      .dots {
        width: 5px;
        height: 5px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }
`;

const Nutritions = ({ calories, carbs, fat, protein }) => {
  const { pathname } = useLocation();

  const data = [
    {
      id: 'Fat',
      value: fat,
      color: '#FEB95A',
    },
    {
      id: 'Protein',
      value: protein,
      color: '#EE786B',
    },
    {
      id: 'Carbs',
      value: carbs,
      color: '#916AE2',
    },
  ];

  return (
    <Container>
      <div className="wrapper-1">
        <div className="counting-calories-number">
          <p>{calories.toLocaleString('en-US')}</p>
          <p>Calories</p>
        </div>
        <ResponsivePie
          width={pathname == '/shop/meals' ? 254 : 200}
          height={pathname == '/shop/meals' ? 254 : 200}
          data={data}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          valueFormat=" >-"
          innerRadius={0.7}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          activeOuterRadiusOffset={3}
          colors={['#FEB95A', '#EE786B', '#916AE2']}
          tooltip={(data) => {
            const { color, value, id } = data.datum;
            return (
              <div className="tooltip-wrapper">
                <span style={{ backgroundColor: color }} />
                <span>{id}</span>
                <span>{value}</span>
              </div>
            );
          }}
        />
        {/*
          <Doughnut
            width={70}
            height={70}
            data={{
              // labels: ["Fat", "Protein", "Carbs"],
              datasets: [
                {
                  label: 'My First Dataset',
                  data: [fat, protein, carbs],
                  backgroundColor: ['rgb(29, 166, 84)', 'rgb(94, 201, 95)', 'rgb(154, 228, 146)'],
                  hoverOffset: 4,
                  // cutout: "90%",
                },
              ],
            }}
            options={{
              responsive: true,
              cutoutPercentage: 80,
              maintainAspectRatio: true,
              tooltips: {
                callbacks: {
                  label: function (tooltipItems, data) {
                    // console.log(data);
                    return ' ' + data['datasets'][0]['data'][tooltipItems['index']] + 'g';
                  },
                },
              },
            }}
        />
        */}
      </div>
      <div className="wrapper-2">
        {data.map((legend) => {
          return (
            <div className="dots-wrapper" key={legend.id}>
              <div className="legend-first">
                <div className="dots" style={{ backgroundColor: legend.color }}></div>
                <p>{legend.id}</p>
              </div>
              <p className="legend-second">{legend.value}g</p>
            </div>
          );
        })}
        {/* 
            <div className="dots-wrapper">
              <div className="dots dark"></div>
              <p>FAT</p>
              <p>{fat}g</p>
            </div>
            <div className="dots-wrapper">
              <div className="dots light"></div>
              <p>PROTEIN</p>
              <p>{protein}g</p>
            </div>
            <div className="dots-wrapper">
              <div className="dots lighter"></div>
              <p>CARBS</p>
              <p>{carbs}g</p>
            </div>
          */}
      </div>
    </Container>
  );
};

export default Nutritions;
