import { Switch, Route, useLocation, useHistory } from "react-router-dom"
import SlidingBanner from "./SlidingBanner"
import SignIn from "./SignIn"
import DietaryPreference from "./DietaryPreference"
import PreferredDiet from "./PreferredDiet"
import HealthGoals from "./HealthGoals"
import CurrentWeight from "./CurrentWeight"
import CurrentHeight from "./CurrentHeight"
import UserAge from "./UserAge"
import IdealWeight from "./IdealWeight"
import UserGender from "./UserGender"
import ActiveStatus from "./ActiveStatus"
import SelectHealthGoal from './SelectHealthGoal';
import PhoneVerfication from "./PhoneVerification"
import { createContext, useContext, useState, useEffect } from "react"
import RecommendedCalories from './RecommendedCalories';
import { AnimatePresence } from 'framer-motion';
import PersonalizedMacros from "./PersonalizedMacros"
import { useAppContext } from "context/appContext"
import { useRequest } from "hooks/useRequest"
import { Address } from './Address'
import Header from 'layout/Header';
import SignUp from "./desktop/SignUp";
import EmailVerification from "./desktop/emailVerification";
import PasswordForm from "./desktop/PasswordForm";
import Details from './desktop/Details';
import PhoneVerify from './desktop/PhoneVerify';
import { ShopContextProvider } from "context/shopContext"

export default function UserOnboarding() {
    const {isMobile, isDesktop} = useAppContext();
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const NewGoal = params.get('newGoal');
    // useEffect(() => {
    //     !isMobile && history.goBack();
    //     if (JSON.parse(localStorage.getItem('login-type')) === "selfMealPlanOnboarding") {
    //         localStorage.setItem('last-login-url', JSON.stringify(location?.pathname));
    //     }
    // }, [location])
    // useEffect(() => {
    //     const lastLoginUrl = JSON.parse(localStorage.getItem('last-login-url'));
    //     if (lastLoginUrl && !NewGoal) {
    //         history.push(lastLoginUrl);
    //     }
    // }, [history]);

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {isDesktop && <Header />}
                <ShopContextProvider>
                <OnboardingContextProvider>
                    <Switch location={location} key={location.pathname}>
                        <Route path={'/self-onboarding/user-onboarding'} component={SlidingBanner} />
                        <Route path={'/self-onboarding/signin'} component={SignIn} />
                        <Route path={'/self-onboarding/phone-verfication'} component={PhoneVerfication} />
                        <Route path={'/self-onboarding/dietary-preference'} component={DietaryPreference} />
                        <Route path={'/self-onboarding/preferred-diet'} component={PreferredDiet} />
                        <Route path={'/self-onboarding/health-goals'} component={HealthGoals} />
                        <Route path={'/self-onboarding/current-weight'} component={CurrentWeight} />
                        <Route path={'/self-onboarding/current-height'} component={CurrentHeight} />
                        <Route path={'/self-onboarding/userAge'} component={UserAge} />
                        <Route path={'/self-onboarding/userGender'} component={UserGender} />
                        <Route path={'/self-onboarding/ideal-weight'} component={IdealWeight} />
                        <Route path={'/self-onboarding/active-status'} component={ActiveStatus} />
                        <Route path={'/self-onboarding/select-goal'} component={SelectHealthGoal} />
                        <Route path={'/self-onboarding/personalize-macros'} component={PersonalizedMacros} />
                        <Route path={'/self-onboarding/recommended-calories'} component={RecommendedCalories} />
                        <Route path={'/self-onboarding/address'} component={Address} /> 
                    </Switch>
                </OnboardingContextProvider>
                </ShopContextProvider>
            </AnimatePresence>
        </>
    )
}

const OnboardingContext = createContext();

const OnboardingContextProvider = ({ children }) => {
    const [isKeyboardActive, setIsKeyboardActive] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const { profile } = useAppContext();

    const userOpt = {
        name: JSON.parse(localStorage.getItem('selfOnboardingUser'))?.profile?.name || '',
        lastName: profile?.lastName || '',
        phoneNumber: profile?.phoneNumber || '',
    }
    const [onboardingUser, setOnboardingUser] = useState(JSON.parse(localStorage.getItem('selfonboarding-data')) || userOpt);
    const [visitorData, setVisitorData] = useState(null);
    const [getAllergies, { isLoading }] = useRequest('meal/allergies');
    const [dietaryTags, setDietaryTags] = useState(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isNewGoal = params.get('newGoal');
    const exitUrl = params.get('exitUrl');
    const [backUrl, setBackurl] = useState(exitUrl);
    const [isRestrictionModalActive, setIsRestrictionModalActive] = useState(false);
    const [animation, setAnimation] = useState(false);
    const [userDetails, setUserDetails] = useState({ name: '', email: '', password: '' });

    useEffect(async () => {
        try {
            const response = await getAllergies();
            setDietaryTags(response);
        } catch (err) {
            console.log(err);
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        document.body.classList.add("hide-body-scroll");
        document.getElementById("root").style.height = '100%';
        document.getElementById("root").style.minHeight = 'auto';
        return () => {
            window.removeEventListener('resize', handleResize);
            document.body.classList.remove("hide-body-scroll");
            document.getElementById("root").style.height = "null";
            document.getElementById("root").style.minHeight = 'calc(100vh - 32px)';
        };
    }, []);

    useEffect(() => {
      if(profile &&  profile?.selfMealPlan && Object.keys(profile?.selfMealPlan).length > 0 && !profile?.dietTemplateId && profile?.dietMetrics?.calories){
        setOnboardingUser((prevUser) => ({
          ...prevUser,
          name: profile?.name || '',
          lastName: profile?.lastName || '',
          phoneNumber: profile?.phoneNumber || '',
        }));
      }
    }, [profile]);
    
    useEffect(() => {
        localStorage.getItem('user') && localStorage.setItem('selfonboarding-data', JSON.stringify(onboardingUser));
    }, [onboardingUser])

    const handleResize = () => {
        const windowHeight = window?.visualViewport?.height.toFixed(2);
        const documentHeight = document?.documentElement?.clientHeight;
        const heightDifference = documentHeight - windowHeight;
        setKeyboardHeight(heightDifference);
        if (heightDifference > 1) {
            setIsKeyboardActive(true);
        } else {
            setIsKeyboardActive(false);
        }
    };

    return <OnboardingContext.Provider value={{ onboardingUser, setOnboardingUser, isKeyboardActive, setIsKeyboardActive, keyboardHeight, setKeyboardHeight, user, visitorData, setVisitorData, dietaryTags, setDietaryTags, isNewGoal, backUrl, isRestrictionModalActive,setIsRestrictionModalActive, animation, setAnimation,
        setUserDetails,
        userDetails }}>
        {children}
    </OnboardingContext.Provider>
}

const useOnboardingContext = () => useContext(OnboardingContext);
export { useOnboardingContext };