import { KitchenOurMissionWrapper } from './style';

export default function KitchenOurMission() {
  return (
    <KitchenOurMissionWrapper>
      <div className="kitchen-ourMission-container">
        <div className="our-mission-info">
          <div className="mission-title">Our mission</div>
          <p>
            Nutritt is committed to making healthy eating easy and enjoyable for you, so you can
            focus on living your best life.
          </p>
          <a href="https://apps.apple.com/us/app/nutritt/id1605156638" target='-blank' className="app-store-image">
            <img src="/images/app-store.svg" alt="Our Mission" />
          </a>
        </div>
        <div className="mission-image-wrapper">
          <img src="/images/our-mission.png" alt="Our Mission" />
        </div>
      </div>
    </KitchenOurMissionWrapper>
  );
}
