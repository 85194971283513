import classNames from 'classnames';
import styled from 'styled-components';
import ImageViewer from '../../../../components/ImageViewer';
import { useState } from 'react';
import Alert from 'components/Alert';
import { useRequest } from 'hooks/useRequest';	
import {toast} from "react-toastify";
import {useAppContext} from 'context/appContext';
import { useHistory } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import {ReactComponent as DollarSquare} from '../../../../assets/icons/dollar-square.svg'


const PlanCardStyle = styled.div`
  cursor: grab;

  .title {
    padding: 13px 16px;
    color: #0b1d17;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 17px;
    font-weight: 700 !important;
  }

  .meal-plan-image {
    width: 100%;
    height: 170px;
    object-fit: cover;
    // padding: 0 20px;
  }

  .card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px #0000000d;
    padding-bottom: 16px;
    position: static;
  }

  .meal-img-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stard-btn {
    margin-top: -20px;

    .primary-btn {
      width: 147px;
      height: 40px;
      padding: 0;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      margin-left: calc(50% - 74px);
      line-height: 17px;
      box-shadow: 0px 3px 7px rgb(0 0 0 / 12%);
    }
  }
}

  .days-tags {
    overflow: hidden;
    padding: 18px 11px 22px 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &.moreTags {
      padding: 40px 0 25px 14px;

      .days-wrapper {
        margin-right: 15px;
        flex-shrink: 0;
      }
    }

    .days-wrapper {
      height: 20px;
      display: flex;
      align-items: center;

      .calendar-icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        font-weight: 700;
      }
    }

    .days {
      color: #949d9b;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
      font-weight: 700;
      white-space: nowrap ;
    }

    .tags-wrapper {
      overflow-x: auto;
      display: flex;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }

    .showDescription-wrapper {
      overflow-x: auto;
      display: flex;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }

    .tag {
      height: 20px;
      margin-right: 3px;
      padding: 0 9px;
      color: #52c41a;
      border: 1px solid #cbedba;
      border-radius: 10px;
      font-size: 10px;
      font-family: RobRegular;
      line-height: 12px;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .show-more {
      justify-content: center;
      cursor: pointer;
  
      .text-wrapper {
        height: 20px;
        // padding: 0 9px;
        color: #52c41a;
        font-size: 10px;
        font-family: 'Roboto';
        line-height: 12px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
      }
    }
  }

  .details {
    padding: 20px 14px 20px 14px;
    border-top: 1px solid #EFF1EF;

    p {
      color: #949d9b;
      font-size: 12px;
      font-family: Roboto;
      line-height: 16px;
    }
  }

  .days-tags{
    .show-more{
      margin: 0 30px !important;
    }
    .days{
      width: 70px;
    }
  }

  @media (max-width: 991px) {
    .alert-container {
      width: 90%;

      .btn-wrapper {

        button:last-child {
          width: 130px;
        
        }
      }

      .title-desc-wrapper {
      padding: 43px 30px 8px !important;
      border-bottom: 1px solid #f6f5f8;
    }

    }

    .stard-btn .primary-btn {
      margin-bottom: 24px;
    }

    .day-tags{
      tags-wrapper{
        overflow: hidden;
      }
    }
  }
`;

export default function PlanCard({
  name,
  image,
  duration,
  duration_type,
  tags,
  introduction,
  mealPlan,
  offerPlan,
  availability,
  price,
  id,
  nutritionistId,
  OfferPlanId,
  index,
  setRegisterCard ,
  setMealPlanId
}) {
  const [showIntro, setShowIntro] = useState(false);
   const [open, setOpen] = useState(false);
   const [updateNewDietPlan] = useRequest('nutritionist/updateSubscribedDietPlan', 'put');
   const history = useHistory();
  const {  setupLocalUser } = useAppContext();
   const { showChangeDietPlan, getIsUserPlanExpiredFn } = useShopContext();
   const { user,isGuest , isVisitor ,profile,setProfile} = useAppContext()

 const RenderTags = ({ tag }) => {
    return (
      <>
        {mealPlan && (
          <div className="tags-wrapper">
            {tag?.map((t) => (
              <div className="tag">
                {t}
              </div>
            ))}
          </div>
        )}
        {offerPlan && (
          <div className="days-wrapper">
            <DollarSquare className="calendar-icon"/>
            <div className="days">
              <span className="days">{tag}</span>
            </div>
          </div>
        )}
      </>
    );
  };

 const handleClick = (e, id) => {
           const dietPlanPayload = {"updatedDietTemplateId": id}
           updateNewDietPlan({ body: dietPlanPayload})
           .then((response) => {toast.success("Diet Plan updated successfully")
            setupLocalUser();
            getIsUserPlanExpiredFn();
            history.push('/shop/plan')})
            .catch((error) => {toast.error("Something went wrong")
            handleClose();                  
           })
    };
    const onClick=()=>{
      if(user){
        if(!user.type.includes('user')){
          setProfile({...profile,dietTemplateId:id})
          history.push('/guest-onboarding')
        }else{
          setOpen(!open) 
        }
      } else{
        setRegisterCard(true) ,
         setMealPlanId(id)
      }
    }

    const handleClose = () => {
        setOpen(false);
         setRegisterCard(true)
    };
  return (
    <PlanCardStyle>
           <div className="card">
        <div className="title">{name}</div>
        <div className="meal-img-wrapper">
          <ImageViewer
            className="meal-plan-image"
            src={image || '/images/plan-cover.png'}
            fallbackSrc={'/images/plan-cover.png'}
            alt="Meal Plan"
          />
        </div>
        <div className={classNames('days-tags', {daysIntroduction: introduction})}>
          {mealPlan && (
            <div className="days-wrapper">
              <img className="calendar-icon" src="/icons/meal-calendar.svg" alt="calendar" />
              <span className="days">
                {duration > 999 ? '999' : duration} {duration_type}
              </span>
            </div>
          )}
          {offerPlan && (
            <div className="days-wrapper">
              <img className="calendar-icon" src="/icons/Vector.svg" alt="calendar" />
              <span className="days time">
                {availability} {duration_type}
              </span>
            </div>
          )}
          {introduction && (
            <div className="show-more">
              <div
                className={classNames('text-wrapper', { showDetails: showIntro })}
                onClick={() => setShowIntro(!showIntro)}
              >
                {showIntro ? (
                  <img src="/icons/show-less.svg" alt="show less" />
                ) : (
                  <img src="/icons/show-more.svg" alt="show more" />
                )}
              </div>
            </div>
          )}
          <div className="tags-wrapper">
            {mealPlan && <RenderTags tag={tags} mealPlan={true} />}
            {offerPlan && <RenderTags tag={price} offerPlan={true} />}
          </div>
        </div>
        {showIntro && (
          <div className="details">
            <p>{introduction}</p>
          </div>
        )}
      </div>
      {mealPlan && (
        <div className="stard-btn">
          <button className="primary-btn" 
          // onClick={() => { user ? !(user.type.includes('user')) ? history.push('/guest-onboarding') : setOpen(!open) : setRegisterCard(true) , setMealPlanId(id)}} data-toggle="modal">
           onClick={onClick}>
            Get started
          </button>
        </div>
      )}
      {offerPlan && (
        <div className="stard-btn">
          <button className="primary-btn" onClick={() => {
                    history.push(`/booking/one-to-one-meeting?offerId=${OfferPlanId}&nutritionistId=${nutritionistId}&clientEmail=${user?.email}&userId=${user?.id}&isUserExist=${true}`);
                  }}>
            Get started
          </button>
        </div>
      )}
      {open && (
        <div className="getStarted" onClick={() => setOpen(!open)}>
          <Alert
            title={mealPlan ? 'New Meal Plan' : 'New Offer Plan '}
            desc={
              mealPlan && !isGuest && !isVisitor
                ? 'You are currently subscribed to a different plan. Do you want to start over with this plan?'
                : `Do you want to choose ${name}?`
            }
            cancel={() => {
              setOpen(false);
            }}
            style={{color: "black"}}
            cancelText="Cancel"
            okText="Continue"
            ok={
              mealPlan
                ? (e) => {handleClick(e, id);}
                : ""
            }
            discardText="Discard"
          />
        </div>
      )}
    </PlanCardStyle>
  );
}
