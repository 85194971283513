import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const LimitedPerOrderWrapper = styled.div`
  padding: 0 28px 32px 24px;
  display: flex;
  justify-content: space-between;
  user-select: none;

  .limited-item-wrapper {
    display: flex;
    gap: 12px;
  }

  .order-title-wrapper {
    color: ${theme.colors.blackDefault};
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      font-weight: 600;
    }
    .content {
      max-width: 324px;
      font-weight: 400;
    }
  }
`;

export const SwitchWrapper = styled.div`
  .MuiSwitch-root {
    width: 32px;
    height: 18px;
    padding: 0;
    border-radius: 15px;
    background-color: #0000001a;
  }
  .MuiSwitch-switchBase {
    padding: 0;
    .MuiSwitch-thumb {
      width: 13px;
      height: 13px;
      margin: 2px;
      background-color: ${theme.colors.white};
      border-radius: 100%;
      box-shadow: none;
    }
  }
  .MuiSwitch-track {
    background-color: ${theme.colors.solitudeBlue};
    opacity: 1;
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #3cc13b;
    opacity: 1;
  }
  .MuiSwitch-switchBase:hover {
    background-color: transparent;
  }
`;

export const QuantityWrapper = styled.div`
  .label-wrapper {
    color: ${theme.colors.blackDefault};
    font-family: ${theme.typography.fontGilroy};
    font-size: 14px;
    line-height: 20px;
    span {
      color: ${theme.colors.danger};
    }
  }
  input {
    height: 40px;
    font-family: ${theme.typography.fontGilroy};
    border: 1px solid ${theme.colors.grayLight};
    &::placeholder {
      color: ${theme.colors.blackDefault} !important;
    }
    &[disabled] {
      background-color: ${theme.colors.grayLighter} !important;
      &:hover {
        border-color: ${theme.colors.grayLight} !important;
      }
      &::placeholder {
        color: ${theme.colors.blackLight} !important;
      }
    }
  }
`;
