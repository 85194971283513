import { getStateAbbreviation } from 'utils';
import { FiArrowRight } from 'react-icons/fi';
import { Button } from 'components/elements/button/Button';
import { KitchenCardDesktopWrapper } from './style';

export default function KitchenCardDesktop({
  className,
  cardImg,
  tag,
  title,
  date,
  mealPrepared,
  state,
  city,
  btnText,
  onClickBtn,
  onKitchenBannerImgClick
}) {
  const abbreviatedState = getStateAbbreviation(state);
  const modifiedCity = city?.length > 8 ? city?.slice(0, 8) + '...' : city;
  const features = [
    {
      id: 1,
      icon: <img src="/icons/meal.svg" alt="Meal Icon" />,
      title: `${mealPrepared}+`,
      info: 'Meals prepared',
    },
    {
      id: 2,
      icon: <img src="/icons/place.svg" alt="Place Icon" />,
      title: 'Locally sourced',
      info: modifiedCity
        ? abbreviatedState
          ? `${modifiedCity}, ${abbreviatedState}`
          : modifiedCity
        : abbreviatedState
        ? abbreviatedState
        : '',
    },
    {
      id: 3,
      icon: <img src="/icons/verified.svg" alt="Verified Icon" />,
      title: 'Certified',
      info: 'Dietitian approved',
    },
  ];

  return (
    <KitchenCardDesktopWrapper className={className}>
      <section className="kitchen-image-wrapper">
        <div className="kitchen-image-container">
          <img src={cardImg} alt="kitchen Image"  onClick={onKitchenBannerImgClick}/>
        </div>
        {tag && <div className="kitchen-tag"><div className='kitchen-tag-text'>{tag}</div></div>}
      </section>
      <section className="kitchen-details-wrapper">
        <div className="kitchen-title">{title}</div>
        <div className="delivery-date-wrapper">
          {date && (
            <>
              <img src="/icons/clock.svg" alt="Clock Img" className="clock-img" />
              <div className="delivery-date">
                Delivery by: <div className="date">{date}</div>
              </div>
            </>
          )}
        </div>
        <div className="features-wrapper">
          {features.map((feature) => (
            <div key={feature.id} className={`feature-details feature-${feature.id}`}>
              <div class="feature-title-wrapper">
                {feature.icon}
                <div className="feature-title">{feature.title}</div>
              </div>
              <div className="feature-info">{feature.info}</div>
            </div>
          ))}
        </div>
        <Button
          title={btnText}
          type="dark"
          iconRight={
            <FiArrowRight style={{ color: '#fff', fontSize: '20px', fontWeight: '600' }} />
          }
          onClick={onClickBtn}
        />
      </section>
    </KitchenCardDesktopWrapper>
  );
}
