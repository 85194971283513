import classNames from 'classnames';
import { memo } from 'react';

function BasicButton({ className, onClick, content, disabled }) {
    return (
        <button className={classNames('basic-button', { [className]: className })} onClick={onClick} disabled={disabled}>
            {content}
        </button>
    );
}
export default memo(BasicButton);