import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MultiScreenStyle from './MultiScreenStyle';
import { theme } from 'shared/theme/theme';
import MobileHeader from 'components/elements/MobileHeader';
import { motion } from 'framer-motion';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router';

const DesiredHealthGoalStyle = styled.div`
  .checkbox___card {
    margin: 6px 0;
  }
`;

const DesiredHealthGoal = ({ activeModalStack, setActiveModalStack, modal, nextScreen, totalPagesCount }) => {
  const [desiredHealthGoal, setDesiredHealthGoal] = useState('');
  const { healthGoal, setUpdatedHealthGoal, updatedHealthGoal } = useShopContext();
  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack((prev) => [
      ...prev.filter((item) => item?.modal !== modal?.modal),
    ])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleChange = (value) => {
    if (value === 'maintain-weight') {
      setUpdatedHealthGoal((prev) => ({
        ...prev,
        selfMealPlan: {
          ...prev.selfMealPlan,
          healthGoal: value,
          goal: 0,
          idealWeight: 0,
        },
      }));
    } else {
      setUpdatedHealthGoal((prev) => ({
        ...prev,
        selfMealPlan: {
          ...prev.selfMealPlan,
          healthGoal: value,
        },
      }));
    }
  };

  useEffect(() => {
    setDesiredHealthGoal([healthGoal?.selfMealPlan?.healthGoal]);
  }, [])

  console.log(desiredHealthGoal,'desiredHealthGoal')

  return (
    <>
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: '100%' }}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        <MultiScreenStyle>
          <DesiredHealthGoalStyle theme={theme}>
            <MobileHeader
              text={'Health goals'}
              pageNumber={`${activeModalStack?.length}/${totalPagesCount}`}
              onClickClose={handleClose}
            />
            <div className="containerr">
              <p className="subtitle">
                Let's customize your meal planning and <br /> recommendations to help you achieve
                your goals
              </p>
              <div className="goal__holder">
                <div className="checkbox___card">
                  <CheckboxCard
                    title={'Weight loss'}
                    type={'title_only'}
                    onClick={handleChange}
                    value={'weight-loss'}
                    radio={true}
                    selectedValue={desiredHealthGoal}
                    setSelectedValue={setDesiredHealthGoal}
                  />
                </div>
                <div className="checkbox___card">
                  <CheckboxCard
                    title={'Weight gain'}
                    type={'title_only'}
                    onClick={handleChange}
                    value={'weight-gain'}
                    radio={true}
                    selectedValue={desiredHealthGoal}
                    setSelectedValue={setDesiredHealthGoal}
                  />
                </div>
                <div className="checkbox___card">
                  <CheckboxCard
                    title={'Maintain healthy weight'}
                    type={'title_only'}
                    onClick={handleChange}
                    value={'maintain-weight'}
                    radio={true}
                    selectedValue={desiredHealthGoal}
                    setSelectedValue={setDesiredHealthGoal}
                  />
                </div>
              </div>
            </div>
            <div className="button_holder">
              <Button
                type={'button_combo'}
                iconRight={<ChevronRight />}
                comboRightDisabled={!updatedHealthGoal?.selfMealPlan?.healthGoal}
                comboRightTitle={'Continue'}
                comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}

              />
            </div>
          </DesiredHealthGoalStyle>
        </MultiScreenStyle>
      </motion.div>
    </>
  );
};

export default DesiredHealthGoal;
