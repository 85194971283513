import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { KitchenCardWrapper } from './style';
import useProgressiveImage from 'hooks/useProgressiveImage';
import { Skeleton } from '@mui/material';

function KitchenCard({ className, cardImg, label, providerImg, title, date, favorite,onKitchenBannerImgClick, ...props }) {
  const imgRef = useRef();
  const [src, { isImageLoaded }] = useProgressiveImage(cardImg, imgRef);

  return (
    <KitchenCardWrapper className={className} {...props}>
      <div className="card-img-wrapper">
        {!isImageLoaded && <Skeleton variant='rectangular' style={{ position: 'absolute', inset: 0, zIndex: 9 }} width={'100%'} height={'100%'}/>}
        <img src={src} ref={imgRef} decoding="async" alt="Card Img" className={`card-img ${isImageLoaded ? 'isFadeInAnimate': 'imgBlurred'}`} onClick={onKitchenBannerImgClick}/>
        {label && <label><div className='label-text'>{label}</div></label>}
        {favorite && (
          <div className="favorite-wrapper">
            <img src="/icons/favorite-heart.svg" alt="Favorite Img" className="favorite-img" />
          </div>
        )}
        <div className="provider-wrapper">
          <img src={providerImg} alt="Provider Img" className="provider-img" onError={(e) => { e.target.src = '/images/avatar.png' }}/>
        </div>
      </div>
      <div className="card-details">
        <div className="card-title">{title}</div>
        <div className="delivery-date-wrapper">
          {date && (
            <>
              <img src="/icons/clock.svg" alt="Clock Img" className="clock-img" />
              <div className="delivery-date">
                Delivery by: <div className="date">{date}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </KitchenCardWrapper>
  );
}

export default memo(KitchenCard);
