import { memo } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { AccordionWrapperStyled } from './style';

function AccordionWrapper({ title, id, children }) {
  return (
    <AccordionWrapperStyled>
      <Accordion>
        <AccordionSummary
          expandIcon={<img src="/icons/angle-accordion-bottom-black.svg" alt="Angle Icon" />}
          aria-controls={`panel${id}-content`}
          id={`panel${id}-header`}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </AccordionWrapperStyled>
  );
}


export default memo(AccordionWrapper)

