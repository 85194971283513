import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const EnderAddressModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Gilroy;

  .enter-address-mask {
    position: fixed;
    inset: 0;
    z-index: 5;
    background-color: rgba(81, 99, 93, 0.32);
  }

  .Enter-address-container {
    position: absolute;
    z-index: 5;
    width: 400px;
    padding: 16px;
    background-color: ${theme.colors.secondary};
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }

  .address-desktop-header {
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    gap: 11px;
    .address-close-wrapper {
      display: flex;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  .address-map-wrapper {
    & > div:first-child {
      position: relative !important;
      height: 123px !important;
      & > div:first-child {
        border: 1px solid ${theme.colors.grayLight};
        border-radius: 10px;
      }
    }
    .address-info {
      margin-top: 4px;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      .address-first {
        color: ${theme.colors.primaryDarker};
      }
      .address-second {
        color: ${theme.colors.mediumGrey};
      }
    }
  }

  .enter-address-divider {
    margin: 14px 0;
    border-top: 1px solid ${theme.colors.grayLight};
  }

  .address-details-wrapper {
    .apt-number,
    .address-type-wrapper {
      overflow: hidden;
      margin-bottom: 24px;
      label {
        margin: 0;
        color: ${theme.colors.primaryDarker};
        font-size: 14px;
        font-weight: 600;
        line-height: 32px;
      }
      input {
        height: 44px;
        padding: 0 14px;
        border-radius: 6px;
        border: 1px solid ${theme.colors.lighterGrey};
        background-color: ${theme.colors.secondary};
        &::placeholder {
          color: ${theme.colors.lighterGrey};
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
        &:focus,
        &:hover {
          border-color: ${theme.colors.lighterGrey} !important;
        }
      }
    }

    .custom-input-motion {
      position: relative;
      img {
        position: absolute;
        right: 4px;
        bottom: 4px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .custom-input-mask {
      position: fixed;
      inset: 0;
    }

    .address-types {
      position: relative;
      z-index: 3;
      margin-top: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      transition: transform 0.5s linear;
      &.typesWithCustomInput {
        transform: translateX(-210px);
        transition: transform 0.5s linear;
      }
      .label-tag {
        max-width: 90px;
        height: 32px;
        padding: 0 10px;
        border: 1px solid #e0e3e0;
        border-radius: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        &.active {
          border-color: ${() => theme.colors.primaryDefault};
        }
        .image img {
          width: 14px;
          height: 14px;
          display: flex;
          object-fit: cover;
        }
        .type {
          margin-top: 2px;
          color: ${() => theme.colors.primaryDarker};
          font-family: ${() => theme.typography.fontGilroy};
          font-size: 10px;
          font-weight: 700;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .custom-input {
      input {
        width: 290px;
        height: 32px;
        padding: 0 41px 0 14px;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #e0e3e0;
      }
    }
  }

  .save-address-btn {
    width: 100%;
    height: 42px;
    background-color: ${theme.colors.primaryDefault};
    color: ${theme.colors.secondary};
    border-radius: 30px;
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
`;
