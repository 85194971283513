import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GilroyFontWrapper } from 'shared/theme/style';
import MobileHeader from 'components/elements/MobileHeader/index.js';
import Toggle from 'components/elements/toggleModule/Toggle';
import { useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppContext } from 'context/appContext';

const NotificationsStyle = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #0B1D17
  }
  .sub-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--grey-2, #949d9b);
  }
  .row {
    flexdirection: row;
    display: flex;
    justify-content: space-between;
    margin-left: 24px;
    margin-right: 13px;
    margin-top: 26px;
  }
`;

export const Notifications = () => {
  const history = useHistory();
  const { user, setUser, isCheckedToggle } = useAppContext()
  const [isChecked, setIsChecked] = useState(user?.pushNotificationAllowed);

  const selectedItemsPay = useCallback(() => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ event: "toggle-switch", toggleStatus: isChecked }));
  }, [isChecked]);

  useEffect(() => {
   
    if (isCheckedToggle !== undefined) {
      setIsChecked(isCheckedToggle);
      const updatedUser = { ...user, pushNotificationAllowed: !user.pushNotificationAllowed };
      setUser(updatedUser);
    }
  }, [isCheckedToggle])
  const animation = {
    initial: {
      x: '100%',
      opacity: 0,
    },
    exit: {
      x: '-100%',
      opacity: 0,
    },
  };
  return (
    <NotificationsStyle>
    <GilroyFontWrapper>
      <AnimatePresence>
        <motion.div
          initial={animation?.initial}
          animate={{ opacity: 1, x: 0, y: 0 }}
          exit={animation?.exit}
          transition={{ duration: 0.7, type: 'spring' }}
        >
            <MobileHeader
              onClickLeft={() => {
                history.goBack();
              }}
              text={'Notifications'}
            />
            <div className="row">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <text className="title">Push-notifications</text>
                <text className="sub-title">{isChecked ? 'ON' : 'OFF'}</text>
              </div>
              <Toggle
                name="autoAcceptOrder"
                toggleColor={"#00092D"}
                type="switch"
                toggleWidth="38px"
                toggleHeight="20px"
                width="16px"
                height="16px"
                gap="12px"
                transform="18px"
                onChange={true}
                checked={isChecked}
                setIsChecked={setIsChecked}
                handleToggleSave={selectedItemsPay}
              />
            </div>
         
        </motion.div>
      </AnimatePresence>
    </GilroyFontWrapper>
    </NotificationsStyle>
  );
};
