import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { displayDateTime } from 'utils';
import { motion, useMotionValue } from 'framer-motion';
import { ReactComponent as CheckWhiteIcon } from 'assets/kitchen/check-white.svg';
import { useHistory } from 'react-router-dom'

const OrderCardStyle = styled.div`
  position: relative;
  &::after{
    content: '';
    width: 200%;
    height: 100%;
    background: ${({ theme }) => theme?.colors?.success};
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .order-container {
    padding: 19px 16px 0;
    background-color: #fff;
    z-index: 9;
    position: relative;
    &:last-child .order-card-container {
      border-bottom: 0;
    }
  }
  .order-card-container {
    padding-bottom: 11px;
    border-bottom: 1px solid #E9EAEC;
  }
  .sub {
    color: ${({ theme }) => theme?.colors?.grayDarker};
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.22px;
    text-transform: uppercase;
    margin-bottom: 2px;
    display: block;
    &.no_of_items {
      font-size: 11px;
    }
  }
  .order_details_flex {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;
    .order-number-wrapper {
      width: 213px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .order_number {
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-size: ${({ theme }) => theme?.typography?.fontBase};
      font-weight: 600;
      line-height: 24px;
    }
    .not_viewed {
      color: ${({ theme }) => theme?.colors?.deepForestGreen};
      font-size: 10px;
      font-weight: 600;
      display: inline-block;
      padding: 0 8px;
      height: 20px;
      border-radius: 80px;
      background: #b4ffff;
      display: flex;
      align-items: center;
      span {
        height: 10px;
        line-height: 1.1;
        display: block;
      }
    }
    .item {
      margin-left: auto;
    }
  }
  .item {
    color: ${({ theme }) => theme?.colors?.black};
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 500;
    line-height: 20px;
  }
  .scheduled {
    color: ${({ theme }) => theme?.colors?.primaryDefault};
  }
  .border {
    width: calc(100vw - 32px);
    height: 1px;
    margin: 0 auto;
    background-color: #e9eaec;
    transform: translateY(12px);
  }
  .order-mask-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100px;
    height: 112px;
  }
`;

const ButtonStyle = styled.div`
  &.buttons_holder {
    display: inline-flex;
    position: absolute;
    justify-content: flex-end;
    inset: 0;
    background-color: ${({ theme }) => theme?.colors?.success};
    z-index: 2;
  }
  .btns {
    width: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.accept {
      background-color: ${({ theme }) => theme?.colors?.success};
    }
    &.reject {
      background-color: ${({ theme }) => theme?.colors?.alizarinCrimson};
    }
    .icon {
      margin-bottom: 3px;
    }
    .title {
      color: ${({ theme }) => theme?.colors?.white};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

const OrderCard = (props) => {
  const {
    friendlyOrderNum,
    orderLine,
    providers: [{ Provider : { id: providerId } = {}, selectedDate, selectedTime, providerPayout, isOrderSeen } = {}],
    orderMainId,
    setOrderDataList,
    activeTab,
    setIsOrderStatusChanged,
    orderNumber,
    setSelectedOrder,
    setIsOrderDetails,
    selectedOrder,
    selectedOrderId,
    setSelectedOrderId,
    isAlert,
    setIsAlert,
    alertRef,
    selectedOrderIdRef,
    generateRequest
  } = props;
  const distanceX = useMotionValue(0);
  const constraintsRef = useRef(null);
  const [dragDistance, setDragDistance] = useState();
  const [hideOrder, setHideOrder] = useState(false);
  const [drag, setDrag] = useState(activeTab === "pending" ? "x" : false);
  const history = useHistory()
  const ORDER_DELETE_ANIMATION = { height: 0, opacity: 0.2, x: '-100%' };
  const ORDER_DELETE_TRANSITION = {
    opacity: {
      transition: {
        duration: 0,
      },
    },
  };

  const handleDragStart = (info) => {
    distanceX.set(info.offset.x);
    setDragDistance(info.offset.x);
    setHideOrder(false);
  };

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        setDrag(false);
      }
    })
  }, [drag])

  useEffect(() => { if(!selectedOrderId) setHideOrder(false) }, [selectedOrderId])

  const handleDragEnd = (info, orderId) => {
    const dragDistance = info.offset.x;
    const velocity = info.velocity.x;
    console.log({ dragDistance, velocity });
    if (info.point.x !== 0 && (dragDistance < -130 || velocity < -20)) {
      setHideOrder(true);
      generateRequest("accepted", providerId, orderId)
    }
  };

  const handleOrderDetails = () => {
    if (isAlert && selectedOrderId){
      setIsAlert(() => {alertRef.current = false ; return false});
      setOrderDataList((prev) => ({...prev, [activeTab]: {...prev[activeTab], orders: prev[activeTab].orders?.filter((item) => item?.orderMainId !== selectedOrderId)}}))
    }
      setSelectedOrder(props);
      setIsOrderDetails(true);
      setSelectedOrderId();
      selectedOrderIdRef.current = '';
  }

  const variants = {
    hidden: { height: 0, overflow: 'hidden', transition: { duration: 0.3, delay: 0.2 } },
    visible: { height: 112, overflow: 'static', transition: { duration: 0.3 } }
  }
  
  const getElementState = () => {
    if (hideOrder) return 'hidden';
    if (selectedOrder?.orderMainId !== orderMainId || !selectedOrderId) return 'visible'
    return 'hidden'
  }

  return (
    <motion.div
      initial={false}
      exit={!orderNumber && ORDER_DELETE_ANIMATION}
      transition={!orderNumber && ORDER_DELETE_TRANSITION}
      animate={getElementState()}
      style={{ position: 'relative' }}
      className='order-card-wrapper'
      variants={variants}
    >
      <OrderCardStyle className="order_card" ref={constraintsRef} theme={theme} onClick={handleOrderDetails}>
        <div className='order-mask-left' />
        <motion.div
          drag={drag}
          onDragEnd={(e, info) => {
            e.stopPropagation(); 
            handleDragEnd(info, orderMainId)
          }}
          onDragStart={(e) => e.stopPropagation()}
          onDragEnter={(e) => e.stopPropagation()}
          onDragOver={(e) => e.stopPropagation()}
          onDrag={(e, info) => {
            e.stopPropagation(); 
            handleDragStart(info)
          }}
          dragConstraints={constraintsRef}
          dragMomentum={false}
          dragElastic={{ left: 1, right: 0 }}
          className={`order-container`}
        >
        <div className='order-card-container'>
          <span className="no_of_items sub">
            {orderLine?.length} ITEM{orderLine?.length > 1 ? 'S' : ''}
          </span>
          <div className="order_details_flex">
            <div className='order-number-wrapper'>
              <h3 className="order_number">{friendlyOrderNum}</h3>
              {!isOrderSeen && <div className="not_viewed"><span>Not viewed</span></div>}
            </div>
            <p className="item">${providerPayout}</p>
          </div>
          <span className="sub">Scheduled for</span>
          <p className="scheduled item">
            {displayDateTime(selectedDate, 'MMM DD, YYYY')}{' '}
            {selectedTime?.toLowerCase().replace('-', ' - ')}
          </p>
          <div className="border" />
          </div>
        </motion.div>
        <ButtonStyle theme={theme} className="buttons_holder">
          <div className="accept btns">
            <CheckWhiteIcon className="icon" />
            <h3 className="title">Accept</h3>
          </div>
          {/* <div className="reject btns">
            <DeleteWhiteIcon className="icon" />
            <h3 className="title">Reject</h3>
          </div> */}
        </ButtonStyle>
      </OrderCardStyle>
    </motion.div>
  );
};

export default OrderCard;
