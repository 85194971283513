import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useRequest } from "hooks/useRequest";
import { useShopContext } from 'context/shopContext';
import EditDietPrefrences from './EditDietPrefrences';
import PantryFooter from 'components/Pantry/PantryFooter';

const ProfileViewWrapper = styled.div`
  min-height: calc(100vh - 84px);
  padding:${(props) => (props?.selfMealPlan ? '23px 0 80px' : '23px 0')};

  .basic-info-wrapper {
    padding: 24px 0;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    .title-edit-wrapper {
      padding: 0 20px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin-left: 10px;
        color: #0b1d17;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
      }

      .edit {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          //margin-left: 5px;
          color: #52c41a;
          font-size: 12px;
          line-height: 14px;
          &:first-child{
            width:14px;
            height:14px;
          }
        }
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
      }
    }

    .user-photo-wrapper {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }

    .user-info-wrapper {
      .item {
        padding: 8px 20px;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        &:nth-child(6){
         display: ${(props) => (props?.selfMealPlan && !props?.dietTemplateId ? 'none' : 'flex')};
        }

        &.diet-plan {
          margin: 20px 0 24px;
          padding: 25px 20px;
          border-top: 1px solid #51635d1a;
          border-bottom: 1px solid #51635d1a;
        }
      }

      .title {
        width: 90px;
        color: #949d9b;
      }

      .info {
        font-weight: bold;
        color: #51635d;
        @media(max-width:991px){
          width:207.68px;
          word-break: break-all;
        }
      }
    }

    .edit-btn-wrapper {
      display: flex;
      justify-content: center;

      .primary-btn {
        width: 80%;
        font-size: 14px;
        font-weight: bold;
        border-radius: 21px;
        line-height: 16px;
      }
    }
    .avatar {
      width: 128px;
      height: 128px;
      margin-right: 12px;
      border-radius: 50%;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
   .address-div{
     margin-top:25px;
     padding:20px 0 10px;
     .title-edit-wrapper{
        padding:0 20px;
        margin-bottom:15px;
        .title-wrapper{
           .title{
              margin-left:0;
           }
        }
     }
     .address-info-wrapper{
       .item{
         padding:20px 20px;
         align-items:flex-start;
         border-bottom: 1px solid #51635d1a;
        &:first-child{
           margin:0;
           border-top:unset;
           //padding:10px 0;
        }
        &:last-child{
          margin:0;
          border:unset;
          padding:20px 20px;

        }
       }
       .title{
        width:30%;
       }
       .title::first-letter {
         text-transform:capitalize;
       }
       .info{
        white-space:pre-line;
        width:66%;
       }
     }
     .edit-img{
       cursor:pointer;
     }
   }

   .__react_component_tooltip{
    left: 100px;
    top: 239px;
    width: 240px;
    word-break: break-all;
   }

   .diet_info_wrapper{
      background: #FFFFFF;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-top: 14px;
      margin-bottom: -11px;
      padding: 20px 0;
      >.title{
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #0B1D17;
        margin-bottom: 22px;
        padding: 0 20px;
      }
      .diet_info_holder{
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0px 20px;
        &.bb{
          padding-bottom: 7px;
          border-bottom: 1px solid rgba(81, 99, 93, 0.1);
        }
        &:not(.bb){
          padding-top: 6px;
        }
        .title{
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          color: #949D9B;
          width: 89px;
        }
        .value{
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #51635D;
          flex: 1;
        }
        .edit {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            //margin-left: 5px;
            color: #52c41a;
            font-size: 12px;
            line-height: 14px;
            &:first-child{
              width:14px;
              height:14px;
            }
          }
        }
      }
   }

  @media(min-width: 991px){
    .basic-info-wrapper{
      width: 35%;
      margin: 17px auto;
      padding: 30px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

      .user-photo-wrapper{
        margin-bottom: 35px;
      }

      .border-wrapper{
        border: 0.1px solid #51635D;
        opacity: 0.1;
        margin-bottom: 20px
      }

      .user-info-wrapper{
        .item{
          padding: 10px 20px;
          font-weight: 400px;
          font-size: 13px;
        }
        .title{
          width: 124px;
        }

        .info{
          font-weight: 700;
          width: 312.2px;
          word-break: break-all;

        }
      }

      .title-edit-wrapper{
        padding: 0px;
      }

      .title-wrapper{

        .title{
          font-size: 16px;
          line-height: 19px;
          font-weight: 700;
          font-family: 'Roboto';
          font-style: normal;
          margin-left: 0px;
        }
      }

        .btn-wrapper{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 18px;
          color: #FFFFFF;
          width: 80%;
          height: 50px;
          background: #52C41A;
          border-radius: 51px;
          margin-bottom: 7px;
        }
      .edit {
        img{
          height: 18px;
          margin-right: 2px;
        }
        span{
          font-size: 14px !important;
        }
      }  
    }
    .diet_info_wrapper{
      width:35%;
      margin:0 auto;
    }
  }
`;

export default function ProfileView({setCreateForm, createForm}) {
  const { profile, isMobile,user,userDeliveryAddress } = useAppContext();
  const [displayAllTags, setDisplayAllTags] = useState({});
  const [getAllergies] = useRequest('meal/allergies');
 
  const {
    profile: { name, gender, age, heightFeet, heightInches, weight, activity, dietTemplate,phoneNumber, selfMealPlan = {},dietTemplateId } = {},
  } = useAppContext();
  const history = useHistory();
  const [editPrefrenceModal, setEditPrefrenceModal] = useState(false);
  const generateActivity = () => {
    let level;
    switch (activity) {
      case 1:
        level = 'Inactive';
        break;
      case 2:
        level = 'Slightly Active';
        break;
      case 3:
        level = 'Moderately Active';
        break;
      case 4:
        level = 'Active';
        break;
      case 5:
        level = 'Extremely Active';
        break;
    }
    return level;
  };
  const heightInfo = heightFeet ? heightInches ? `${heightFeet} ft, ${heightInches} inch` : `${heightFeet} ft` : "n/a";

  
  const basicInfo = {
    Name: name,
    Gender: gender?gender == 'm' ? 'Male' : 'Female' : "n/a",
    Age: age?`${age} years`:"n/a",
    Height: heightInfo,
    Weight: weight ? `${weight} lb` : "n/a",
    phoneNumber:phoneNumber?phoneNumber:"N/A",
    'Activity level': generateActivity(),
    'Diet plan': dietTemplate?.name,
  };

  const AddressInfo = userDeliveryAddress;

  const handleClick = () => {
    history.push('/shop/profile/edit');
    window.scrollTo(0, 0);
  };

  const onClick = () => {
    history.push('/shop/profile/add-address');
    window.scrollTo(0, 0);
  };

  const editClick = (id) => {
    setCreateForm(true);
    history.push(`/shop/profile/edit-address/${id}`);
    window.scrollTo(0, 0);
  }

  useEffect(()=>{
    ReactTooltip.rebuild();
    window.scrollTo(0, 0);
  },[])

  useEffect(() => {
    
    if (editPrefrenceModal){
        document.body.classList.add('bodyFixed');
    } else {
        document.body.classList.remove('bodyFixed')
    }
 }, [editPrefrenceModal])

  useEffect(() => {
    getAllergies().then((res) => setDisplayAllTags(res));
  }, [])

  return (
    <>
    <ProfileViewWrapper selfMealPlan={selfMealPlan} dietTemplateId={dietTemplateId}>
      <div className="container">
        <div className="basic-info-wrapper">
          <div className="title-edit-wrapper">
            <div className="title-wrapper">
              {isMobile && <img
                src="/icons/arrow-left.svg"
                alt="Arrow Icon"
                onClick={() => history.goBack()}
              />}
              <div className="title">Basic info </div>
            </div>
            <div className="edit" onClick={handleClick}>
              <img src="/icons/edit.svg" alt="Edit icon" />
              <span>Edit Info</span>
            </div>
          </div>
          <div className="user-photo-wrapper">
            <img src={profile.img || '/images/man.png'} alt="Man Photo" className="avatar" />
          </div>
          <div className="border-wrapper"/>
          <div className="user-info-wrapper">
            {Object.keys(basicInfo).map(function (key,index) {
              if(basicInfo[key]){
              return (
                <div className={`item ${key == "Diet plan" ? "diet-plan" : ''}`}> 
                  <div className="title">{key=="phoneNumber"?"Phone Number":key}</div>
                  <div className="info">{key=='Name' ? name.length>30 ? <p data-tip={basicInfo.Name} data-for="name" style={{cursor: 'pointer'}}>{basicInfo.Name.substring(0, 30) + "..." }</p> : basicInfo.Name : basicInfo[key] }</div>
                </div>
              );
              }
            })}
          </div>
          {dietTemplateId &&
          <div className="edit-btn-wrapper">
            <button className={isMobile ? "primary-btn" : "btn-wrapper"} onClick={handleClick}>
              Edit info
            </button>
          </div> }
        </div>
      {(selfMealPlan?.id && !dietTemplateId) &&
        <div className='diet_info_wrapper'>
          <div className="title">Diet Info</div>
          <div className='diet_info_holder bb'>
            <div className='title'>Your goal</div>
            <div className='value'>{(selfMealPlan?.healthGoal)?.replace(/-/g, ' ')?.replace(/\b\w/g, c => c === selfMealPlan?.healthGoal?.split('')?.[0] ? c.toUpperCase() : c.toLowerCase())}</div>
            <div className='edit' onClick={()=>history.push('/self-onboarding/dietary-preference?newGoal=true&exitUrl=/shop/profile')} >
            <img src="/icons/edit.svg" alt="Edit icon" />
              <span>Edit Info</span>
            </div>
          </div>
          <div className='diet_info_holder'>
            <div className='title'>Diet preferences</div>
            <div className='value'></div>
            <div className='edit' onClick={()=>setEditPrefrenceModal(true)} >
            <img src="/icons/edit.svg" alt="Edit icon" />
              <span>Edit Info</span>
            </div>
          </div>
        </div>
      }
      {isMobile &&  <div className='basic-info-wrapper address-div'>
          <div className="title-edit-wrapper">
            <div className="title-wrapper">
              <div className="title">Delivery Info</div>
            </div>
            <div className="edit" onClick={onClick}>
              <span>+</span>
             {AddressInfo?.length ? <span> Add new Address</span> : <span> Add Address</span>}
            </div>
          </div>
          <div className="user-info-wrapper address-info-wrapper ">
            {userDeliveryAddress?.length > 0 && userDeliveryAddress?.map(function (item) {
          
              return (
                <div className="item"> 
                  <div className="title">{item?.type==='other' ? item?.name ? item?.name : 'other' : item?.type }</div>
                  <div className="info"> {item?.address?.address?.apartment ? item?.address?.address?.apartment + '\n' : ''}{item?.address?.label?.replace(/\,/, ',\n') || item?.address?.address?.label?.replace(/\,/, ',\n')} </div>
                  <img src="/icons/edit.svg" alt="Edit icon" className='edit-img' onClick={()=>editClick(item?.id)} />
                </div>
              );
            })}
          </div>
        </div>}
      </div>
      <ReactTooltip id="name" place="top" effect="solid">
     </ReactTooltip>
     {editPrefrenceModal &&
      <EditDietPrefrences editPrefrenceModal={editPrefrenceModal} setEditPrefrenceModal={setEditPrefrenceModal} selfMealPlan={selfMealPlan} displayAllTags={displayAllTags} />
     }
    </ProfileViewWrapper>

   {(selfMealPlan&& isMobile) &&
    <PantryFooter progress={false} shop={false} myCoach={false} />}

    </>
  );
}
