import styled from 'styled-components';
import Input from 'components/Input';

const PantrySearchWrapper = styled.div`
  position: relative;
  width: 60%;
  margin-right: 15px;
  display: flex;
  align-items: center;

  label {
    display: none;
  }

  img {
    position: absolute;
    top: auto;

    &:first-child {
      left: 2px;
    }

    &:last-child {
      right: 2px;
      width: 14px;
      cursor: pointer;
    }

    & + div {
      margin-top: 0;
    }
  }

  input {
    height: 30px;
    min-height: 30px;
    padding: 0 20px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #e0e3e0;
    border-radius: 0;

    ::placeholder {
      color: #afb5b4;
      font-size: 14px;
    }
  }

  @media (min-width: 992px) {
    width: auto;

    input {
      padding: 0 30px;
    }
  }
`;

export default function PantrySearch({
  value,
  handleChange,
  isRemove,
  setValue,
  setIsRemove,
  filterMeals,
  setSearchedMeal,
}) {
  const handleremove = () => {
    setValue('');
    setIsRemove(false);
    setSearchedMeal(filterMeals);
  };
  return (
    <PantrySearchWrapper>
      <img src="/icons/search-normal.svg" alt="Search Icon" />
      <Input placeholder="Search meal..." onChange={handleChange} value={value} />
      {isRemove && <img src="/icons/close-circle2.svg" alt="Close Icon" onClick={handleremove} />}
    </PantrySearchWrapper>
  );
}
