import { Typography } from '@mui/material';
import styled from 'styled-components';
import { useRequest } from '../../../hooks/useRequest';
import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useAppContext } from '../../../context/appContext';
import { sort } from 'ramda';
import { useLocation } from 'react-router-dom';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Select, { components } from 'react-select';
import DropdownArrow from '../../../components/Nutritionist/Coaching/DropdownArrow';
import { useNutritionistContext } from 'context/nutritionistContext';


const CreateOfferingWrapper = styled.div`
  @media (max-width: 992px) {
    .user-subscription-wrapper {
      margin-left: 11px;
      .user-subscription-title {
        margin-bottom: 11px;
      }
      .user-subscription-selection {
        margin-left: 16px;
      }
    }
  }
`;

const OfferingCardStyled = styled.div`
  margin-bottom: 25px;
  label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #51635d;
  }
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #949d9b;
  }

  .create-bttn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    width: 146px;
    height: 32px;
    background: #51635d;
    border-radius: 21px;
    cursor: pointer;
    margin-left: 15px;
    min-width: 150px;
    margin-bottom: 4px;
  }

  @media (max-width: 991px) {
    p {
      margin: 0 11px 10px;
    }

    .create-bttn {
      width: 146px;
      margin-left: 0;
    }

    .invite-select-wrapper .css-26l3qy-menu {
      box-shadow: none;

      & > div {
        background-color: #fff;
        border: 1px solid #E0E3E0;
        box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
        border-radius: 6px;

        div {
          margin-bottom: 5px;
          color: #51635D;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
`;
const OfferingSelectActions = styled.div`
  margin: 10px 0px 5px 0px;
  display: flex;
  align-items: flex-end;
  width: 610px;
  height: 54px;
  .field-wrapper {
    background: #ffffff;
    border: 1px solid #e0e3e0;
    box-sizing: border-box;
    border-radius: 6px;
    width: 450px;
    select {
      background: #ffffff;
    }
  }
  .selectWrapper {
    width: 800px;
  }
  .DropArrow {
    position: absolute;
    left: 425px !important;
    top: 15px !important;
  }
  label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #51635d;
  }
  .select-placeholder-text {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    color: #949d9b;
    left: 14px;
    right: 10px;
    top: 38px;
    bottom: 6px;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    margin: 0 0 10px;

      & + div {
        height: 44px;

        & > div {
          height: 100%
        }
      }
    } 

    .DropArrow {
      left: auto !important;
      right: 12px;
    }
  }
`;
const LabelFont = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #51635d;
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow />
    </components.DropdownIndicator>
  );
};


const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#52C41A',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff,28%,transparent 32%)',
    content: '""',
  },
});

export default function OfferingCard({
  setSelectedOffer,
  setSelectedMealPlan,
  userExist,
  persistInviteFormData,
  selectedMealPlan,
  selectedOffer,
  Recommended,
  setRecommended,
}) {
  const [offeringListData, setOfferingListData] = useState([]);
  const { user, isSuper, isMobile } = useAppContext();
  const [getCoachingOffers, { isLoading, error, setError }] = useRequest();
  const [allTemplates, setAllTemplates] = useState([]);
  const [getDietTemplates] = useRequest();
  const location = useLocation();
  const dataTemplates = allTemplates?.dietTemplates;
  const params = new URLSearchParams(location.search);
  const {imitateNutritionist} = useNutritionistContext()


  const getInitialData = (reqId) => {
    const getOfferListUrl = `coachingOffer/offers/${reqId}`;
    getCoachingOffers({
      path: `${getOfferListUrl}`,
    }).then((templates) => {
      let coachingOffer = templates?.getData && templates?.getData[0].coachingOffer;
      const createdOfferId = params.get('createdOfferId');
      if (createdOfferId && coachingOffer.length) {
        const item = coachingOffer.find(({ id }) => id == createdOfferId);
        if (item) {
          setSelectedOffer({ label: item.title, value: item.id });
        }
      }
      coachingOffer =
        coachingOffer && coachingOffer.length
          ? coachingOffer
          : [{ label: 'No Options available', value: null }];
      setOfferingListData(coachingOffer);
    });
  };

  useEffect(async () => {
    const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
    const reqId = isSuper ? imitateNutritionist?.userId : user?.id;
    getInitialData(reqId);
  }, []);

  useEffect(() => {
    const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
    const reqId = isSuper ? imitateNutritionist?.id : user?.id;
    const isNutritionistId = isSuper ? 'true' : 'false';
    const getDietTemplatesUrl = `nutritionist/getDietPlansSummary/${reqId}?isNutritionistId=${isNutritionistId}`;
    if (reqId) {
      getDietTemplates({
        path: `${getDietTemplatesUrl}`,
      }).then((templates) => {
        let mealOffer = templates.data;
        const createdPlanId = params.get('createdPlanId');
        if (createdPlanId && mealOffer?.dietTemplates.length) {
          const item = mealOffer?.dietTemplates.find(({ id }) => id == parseInt(createdPlanId));
          if (item) {
            setSelectedMealPlan({ label: item?.name, value: item.id });
          }
        }
        mealOffer.dietTemplates =
          mealOffer && mealOffer?.dietTemplates.length
            ? mealOffer.dietTemplates
            : [{ label: 'No Options available', value: null }];
        setAllTemplates(mealOffer);
      });
    }
  }, []);
  const dietTemplates = useMemo(() => {
    const dietTemplates = allTemplates?.dietTemplates;
    return dietTemplates ? sort((a, b) => a?.name?.localeCompare(b?.name), dietTemplates) : [];
  }, [allTemplates?.dietTemplates]);

  const handleClick = (event) => {
    if (event.target.value === 'Recommended_a_new_plan') {
      setRecommended(true);
    } else {
      setRecommended(false);
    }
  };

  const options = useMemo(() => {
    return offeringListData?.map((item) => {
      return { label: item.title, value: item.id };
    });
  }, [offeringListData]);

  const mealOptions = useMemo(() => {
    return dataTemplates?.map((item) => {
      return { label: item.name, value: item.id };
    });
  }, [dataTemplates]);

  const recommendedButton = (
    <button
      type="button"
      className="create-bttn"
      onClick={() => persistInviteFormData('mealPlan')}
    >
      + Create {isMobile ? 'Challenge' : 'Meal Plan'}
    </button>
  );
  function disableBtn() {
    return imitateNutritionist?.userId === null
  }
  return (
    <CreateOfferingWrapper>
      <OfferingCardStyled>
        <OfferingSelectActions>
          <div className="selectWrapper">
            <label>
              Name of offering <span style={{ color: 'red' }}>*</span>
            </label>
            <Select
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#E0E3E0',
                  primary: '#E0E3E0',
                },
              })}
              name="offering"
              value={selectedOffer}
              components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setSelectedOffer(e);
              }}
              className="invite-select-wrapper"
              options={options}
              placeholder={<div className="select-placeholder-text">Select offer</div>}
              type="select"
              bordered
              required
            />
          </div>
          {!isMobile &&
            <button
              type="button"
              className="create-bttn"
              onClick={() => persistInviteFormData('offer')}
              disabled={disableBtn()}
            >
              + Create offering
            </button>
          }
        </OfferingSelectActions>
        <Typography components="p">
          An offer is where you set your availability and price for this season
        </Typography>
        {isMobile &&
          <button
            type="button"
            className="create-bttn"
            onClick={() => persistInviteFormData('offer')}
          >
            + Create offering
          </button>
        }
      </OfferingCardStyled>

      {userExist && (
        <>
          <FormControl className="user-subscription-wrapper">
            <LabelFont className='user-subscription-title'>User is currently subscribed to a {isMobile ? 'challenge' : 'meal plan'}</LabelFont>
            <RadioGroup
              name="ExistingUserPlan"
              value={Recommended ? 'Recommended_a_new_plan' : 'Keep_existing_Plan'}
              className="user-subscription-selection"
            >
              <FormControlLabel
                className="checkmark"
                value="Keep_existing_Plan"
                control={
                  <Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} onClick={handleClick} />
                }
                label={<LabelFont>Keep existing Plan</LabelFont>}
              />

              <FormControlLabel
                className="checkmark"
                value="Recommended_a_new_plan"
                control={
                  <Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} onClick={handleClick} />
                }
                label={<LabelFont>Recommended a new plan</LabelFont>}
              />
            </RadioGroup>
          </FormControl>
          {Recommended && (
            <OfferingCardStyled>
              <OfferingSelectActions>
                <div className="selectWrapper">
                  <label>
                    Name of {isMobile ? 'Challenge' : 'Meal Plan'} <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#E0E3E0',
                        primary: '#E0E3E0',
                      },
                    })}
                    label={`Recommended new ${isMobile ? 'Challenges' : 'Meal Plan'}`}
                    name="mealplan"
                    value={selectedMealPlan}
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setSelectedMealPlan(e);
                    }}
                    className="invite-select-wrapper"
                    options={mealOptions}
                    placeholder={<div className="select-placeholder-text">Select {isMobile ? 'Challenge' : 'Meal Plan'}</div>}
                    type="select"
                    bordered
                    required
                  />
                </div>
                {!isMobile && (recommendedButton)}
              </OfferingSelectActions>
              <Typography components="p">
                A meal plan is where you set a plan based on your methedolegies and users goals
              </Typography>
              {isMobile && (recommendedButton)}
            </OfferingCardStyled>
          )}
        </>
      )}
      {!userExist && (
        <OfferingCardStyled>
          <OfferingSelectActions>
            <div className="selectWrapper">
              <label>
                Name of {isMobile ? 'challenge' : 'meal plan'} <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                name="mealplan"
                value={selectedMealPlan}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#E0E3E0',
                    primary: '#E0E3E0',
                  },
                })}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setSelectedMealPlan(e);
                }}
                className="invite-select-wrapper"
                options={mealOptions}
                placeholder={<div className="select-placeholder-text">Select {isMobile ? 'Challenge' : 'Meal Plan'}</div>}
                type="select"
                bordered
              />
            </div>
            {!isMobile &&
              <button
                type="button"
                className="create-bttn"
                onClick={() => persistInviteFormData('mealPlan')}
              >
                + Create {isMobile ? 'Challenge' : 'Meal Plan'}
              </button>
            }
          </OfferingSelectActions>
          <Typography components="p">
            A {isMobile ? 'Challenge' : 'Meal Plan'} is where you set a plan based on your methedolegies and users goals
          </Typography>
          {isMobile &&
            <button
              type="button"
              className="create-bttn"
              onClick={() => persistInviteFormData('mealPlan')}
            >
              + Create {isMobile ? 'Challenge' : 'Meal Plan'}
            </button>
          }
        </OfferingCardStyled>
      )}
    </CreateOfferingWrapper>
  );
}

