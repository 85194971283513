import { useState, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import styled from 'styled-components';
import MealTags from './MealTags';
import { Draggable } from 'react-beautiful-dnd';

import 'rc-tooltip/assets/bootstrap.css';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MealWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 44px;
  height: 55px;
  padding: 5px 7px;
  margin-right: 7px;
  background-color: #fff;
  border: 1px solid #e0e3e0;
  border-radius: 6px;
  filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.1));
  ${FlexCenter};

  &.meaWithTooltip {
    border: 1px solid #97dc76;
  }

  img {
    width: 28px;
    height: 43px;
    border-radius: 6px;
    object-fit: cover;

    &.customMeal {
      object-fit: contain;
    }
  }

  .meal-arrow {
    position: absolute;
    bottom: -22px;
    left: auto;
    width: 10px;
    height: 20px;
    background-color: #fff;
    transform: rotate(45deg);
    box-shadow: 0 0 4px rgb(0 0 0 / 6%);
  }

  @media (min-width: 992px) {
    width: 56px;
    height: 70px;
    margin-right: 30px;
    padding: 10px;

    img {
      width: 38px;
      height: 54px;
    }
  }
`;

const TooltipWrapper = styled.div`
  width: 250px;
  padding: 17px 20px 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  opacity: 1;

  .remove-custom-meal {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }

  .tooltip-title {
    color: #0b1d17;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
  }

  p {
    margin: 7px 0 15px;
    color: #51635d;
    font-size: 12px;
    line-height: 14px;
  }

  .meal-data-wrapper {
    width: fit-content;
    padding: 5px 0;
    border: 1px solid #f6f5f8;
    border-radius: 5px;
    display: flex;

    .item {
      padding: 0 10px;
      color: #51635d;
      font-size: 8px;
      line-height: 12px;
      letter-spacing: 0.05em;
      border-right: 1px solid #f6f5f8;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:last-child {
        border-right: 0;
      }

      span:first-child {
        font-size: 10px;
        font-family: 'Roboto';
      }
    }
  }

  .food-wrapper {
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export default function Meal({
  meal,
  key,
  draggableId,
  customMeal,
  index,
  resetTooltip,
  removeMeal,
  module,
}) {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = (visible) => {
    setVisible(visible);
  };

  useEffect(() => {
    setVisible(false);
  }, [resetTooltip]);

  const TooltipOverlay = (
    <TooltipWrapper>
      <div className="tooltip-title">{meal?.data.name}</div>
      {meal?.isCustomMeal && (
        <img
          src="/icons/gray-trash.svg"
          alt="Trash Icon"
          className={classNames('remove-custom-meal')}
          onClick={() => removeMeal(meal)}
        />
      )}
      <p>{meal?.data.description}</p>
      <div className="meal-data-wrapper">
        <div className="item">
          <span>{meal?.data.calories}</span>Cals
        </div>
        <div className="item">
          <span>{meal?.data.carbs}</span>Carbs
        </div>
        <div className="item">
          <span>{meal?.data.fat}</span>Fat
        </div>
        <div className="item">
          <span>{meal?.data.protein}</span>Protein
        </div>
      </div>
      {(meal?.data.restrictions?.length > 0 || meal?.data.tags?.length > 0) && (
        <div className="food-wrapper">
          <MealTags meal={meal.data} />
        </div>
      )}
    </TooltipWrapper>
  );

  return (
    <Draggable
      key={`${module}-available-meals-key-${draggableId}`}
      draggableId={`${module}-available-meals-draggableId-${draggableId}`}
      index={index}
    >
      {(provided, snapshot) => (
        <Tooltip
          visible={visible}
          onVisibleChange={onVisibleChange}
          trigger="click"
          overlay={TooltipOverlay}
          placement="bottom"
          overlayClassName="meal-tooltip-wrapper"
        >
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <MealWrapper className={classNames('meal-wrapper', { meaWithTooltip: visible })}>
              <img
                src={meal?.data.img || customMeal?.img || '/images/sample.jpg'}
                alt="Meal Image"
                className={classNames({ customMeal: meal?.isCustomMeal })}
              />
              {/* {visible && <div className="meal-arrow" />} */}
            </MealWrapper>
          </div>
        </Tooltip>
      )}
    </Draggable>
  );
}
