import { useState, useEffect, useRef, useCallback } from 'react';
import Styled from 'styled-components';
import { toast } from 'react-toastify';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import Empty from './Empty';
import PantryDate from './PantryDate';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import PantryMeals from 'components/Pantry/PantryMeals';
import { useShopContext } from 'context/shopContext';
import { GilroyFontWrapper } from 'shared/theme/style';

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PantryTabWrapper = Styled.div`
height: 100vh;
background: white;
  .List {
    transition: all 0.5s ease-in-out;
    &::-webkit-scrollbar {
      width: 0;
    }
    div > div::-webkit-scrollbar {
      width: 0;
    }
  }
  .meals-left-wrapper {
    margin: 0 14px 14px;
    color: #51635d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .meals-left-container {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 11px;
      line-height: 12px;
    }
    .meals-count {
      margin-left: 6px;
      border-radius: 20px;
      ${flexCenter};
      span {
        font-size: 11px;
        font-weight: 700;
        line-height: 12px
      }
    }
  }
  .swipeable-list-item__trailing-actions {
    background-color: #0B1D17;
  }
  .swipe-action__trailing {
    width: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 18px;
      height: 18px;
      margin-bottom: 10px;
    }
    span {
      color: #ffffff;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .swipe-action > * {
    flex: 0;
  }
  .meal-content {
    width: calc(100vw - 121px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .pantry-meal-title {
      margin-bottom: 20px;
      margin-left: 16px;
      color: #0B1D17;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
    }
    .meal-details > div:first-child {
      margin: 0;
      border-radius: 6px;
      flex-grow: 1;
    }
    .fresh-card-content {
      width: 100%;
      height: 37px;
    }
    .fresh-card-container {
      width: 100%;
      margin: 0;
      padding: 5px;
      font-size: 10px;
      &:last-child {
        border: 0;
      }
      span {
        color: #757E89;
        text-align: center;
        font-family: Gilroy;
        font-size: 9px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.45px;
      }
      span:first-child {
        color: #00092D;
        font-weight: 700;
        font-size: 11px;
        letter-spacing: 0.22px;
      }
    }
  }
  .pantry-search-empty {
    ${flexCenter};
    
    & > div {
      height: auto !important;
    }
  }
  @media (min-width: 992px) {
    .List::-webkit-scrollbar {
      width: 8px;
    }
  }
`;

const PantryMealsSnacksStyle = Styled.div`
  overflow-y: hidden;
  padding-top: 16px;
  .List{
    height:80vh !important;
  }
  .tap-to-refuel {
    height: 42px;
    background: #F0F2F4;
    padding: 13px 15px;
    color: #0B1D17;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    .icon-warning-wrapper {
      margin-right: 4px;
      display: flex;
    }
    svg {
      color: #51635d;
      font-size: 19px;
      transform: rotate(180deg);
    }
    span {
      color: #000A2C;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: underline;
    }
  }
  .img-wrapper {
    position: relative;
    width: 90px;
    height: 90px;
    text-align: center;
    background-color: #F8F8F8;
    border-radius: 10px;
    img {
      width: 60px;
      height: 60px;
      margin-top: 16px;
      object-fit: cover;
      }
    svg{
      width: 60px;
      height: 60px;
      margin-top: 16px

        }
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20px;
      height: 19.565px;
      padding: 3px 5px;
      color: #000A2C;
      text-align: center;
      font-family: Gilroy;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: #fff;
      border-radius: 10px;
      font-size: 8px;
      line-height: 17px;
    }
  }
  .awaitingReceiptSection{
    background-color: #F0F2F4 !important;
    display: flex;
    padding: 11px 16px;
    border:none !important;
    flex-direction: column;
    height: 57px !important;
    align-items: flex-start;
    gap: 4px;   
    p{
      font-family: Gilroy !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #0B1D17;
    } 
    a{
      color: #000A2C;
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      
    }
  }
`;

export default function PantryTab({
  activeDay,
  setActiveDay,
  isLogMealsModal,
  value,
  setIsLogMealsModal,
  updateFullDiaryMeals,
  revertFullDiaryMeals,
  getdiaryData
}) {
  const {
    pantryMeals,
    setPantryMeals,
    setPantryMealsCount,
    awaitingMeals,
    setawaitingMeals,
    pantryMealsCount,
    pantryMealsSource,
    awaitingMealsSource,
    loadPantryMeals,
    setPantryMealsSource
    } = useAppContext();
  const [searchPantryMeals, setSearchPantryMeals] = useState([]);
  const [searchAwaitingMeals, setSearchAwaitingMeals] = useState([]);
  const [remainPantryMealsCount, setRemainingPantryMealsCount] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [mealsCount, setMealsCount] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();
  const itemSize = (index) => (index == pantryMeals?.length ? 58 : 107);
  const listRef = useRef();
  const [mealId, setMealId] = useState();
  const [addOrderToDiary, { isLoading: isAddToDiaryLoading }] = useRequest();
  const [deletePantryMeals, { isLoading: isDeletePantryMeals }] = useRequest();
  const [isDisabled, setIsDisabled] = useState(false);
  const [listHeight, setListHeight] = useState(window.innerHeight - 234);
  const { consumedMealType } = useShopContext();

  useEffect( async ()=>{
    if(window.ReactNativeWebView){
      await loadPantryMeals()
    }

  },[])

  const setUpPantryData = (id) => {
    const remainingPantryData =
      pantryMealsSource.length &&
      pantryMealsSource
        .map((item) => {
          if (item.orderLineId === id) {
            const quantity = item.qty;
            if (quantity > 1) {
              return { ...item, qty: quantity - 1 };
            } else {
              return 'remove';
            }
          } else {
            return item;
          }
        })
        .filter((item) => item !== 'remove');
        if(searchValue) {
          const filteredMeals = remainingPantryData.filter(
            (meal) => !!meal?.name && meal?.name?.toLowerCase().includes(searchValue.toLowerCase()),
          );
          setPantryMeals(filteredMeals);
          setRemainingPantryMealsCount(filteredMeals?.reduce((acc, val) => acc + parseInt(val.qty), 0))
          setPantryMealsCount(remainingPantryData?.reduce((acc, val) => acc + parseInt(val.qty), 0));
        } else {

          setPantryMeals(remainingPantryData);
          setPantryMealsCount(remainingPantryData?.reduce((acc, val) => acc + parseInt(val.qty), 0));
        }

        setPantryMealsSource(remainingPantryData);
  };

  const onErrorPantryData = (id) => {
    const toupdate = pantryMeals.map((item) => {
      const data = pantryMeals.find((item) => item.orderLineId === id);
      if (item.orderLineId === id) {
        if (item.qty < 1) {
          return { ...item, qty: item.qty + 1 };
        } else {
          return data;
        }
      } else {
        return item;
      }
    });
    setPantryMeals(toupdate);
    setPantryMealsCount(toupdate?.reduce((acc, val) => acc + parseInt(val.qty), 0));
  };

  const setUpDiaryData = useCallback((id) => {
    const pantryMeal = pantryMeals.find((meal) => meal.orderLineId === id)
    if (pantryMeal) {
      updateFullDiaryMeals({ ...pantryMeal.data, createdAt: new Date(activeDay).toISOString() })
      setIsSuccess(true);
      setUpPantryData(id);
      setMealsCount(mealsCount + 1);
      setTimeout(() => {
        setIsDone(true);
        setListHeight(window.innerHeight - 324);
      }, 1000);
    }

  }, [pantryMeals])

  
  

  const moveMealToDiary = (id) => {
    const path = `diary/addOrderToDiary/${id}`;
    setMealId(id);
    setTimeout(() => {
    addOrderToDiary({
      path,
      method: 'POST',
      body: {
        createdAt: new Date(activeDay).toISOString(),
        categoryType: consumedMealType
      },
    })
    .then((res) => {
      getdiaryData();
      setIsSuccess(true);
      setUpPantryData(id);
      setMealsCount(mealsCount + 1);
      setIsDone(true);
      setListHeight(window.innerHeight - 324);
      setIsDisabled(false)
      setMealId();
  }).catch((err) => {
      console.log(err);
      setIsDisabled(false)
      onErrorPantryData(id);
      revertFullDiaryMeals(id)
      toast.error('Oops! something went wrong');
    });
    }, 1500);
  };

  const deletePantryMeal = (id) => {
    const path = `pantry/deletePantryMeals/${id}`;
    setUpPantryData(id);
    setTimeout(() => {
      toast.success('The meal was deleted successfully');
    }, 400);
    deletePantryMeals({
      path,
      method: 'Delete',
    })
      .then(() => {
        console.log('The meal was deleted successfully');
      })
      .catch(() => {
        toast.error('Oops! something went wrong');
        onErrorPantryData(id);
      });
  };

  useEffect(() => {
    listRef.current && listRef.current.resetAfterIndex(0);
  }, [pantryMeals.length])

  useEffect(() => {
    setRemainingPantryMealsCount(pantryMealsCount);
     setPantryMeals(pantryMealsSource);
    setSearchPantryMeals(pantryMealsSource);
    setawaitingMeals(awaitingMealsSource);
    setSearchAwaitingMeals(awaitingMealsSource);
    setSearchValue('');
    setIsDone(false);
    setMealsCount(0);
    setListHeight(window.innerHeight - 234);
  }, [isLogMealsModal, value]);

  useEffect(() => {
    setPantryMeals(searchPantryMeals);
    setRemainingPantryMealsCount(searchPantryMeals.reduce((acc, val) => acc + parseInt(val.qty), 0));
    listRef.current && listRef.current.resetAfterIndex(0);
  }, [searchPantryMeals.length]);

  useEffect(() => {
    setawaitingMeals(searchAwaitingMeals);
  }, [searchAwaitingMeals.length]);


  const PantryRow = ({ index, style }) => {
    if (index === pantryMeals.length && awaitingMeals.length !== 0) {
      return (
        <GilroyFontWrapper>
        <div style={{ ...style }}>
          <div className="awaitingReceiptSection">
            <Typography>
              Your meals are awaiting receipt...
              <Link to="/shop/order">Check Order Status</Link>
            </Typography>
          </div>
        </div>
        </GilroyFontWrapper>
      );
    }
    if (
      index === pantryMealsSource.length &&
      pantryMealsCount < 5 &&
      awaitingMealsSource.length === 0
    ) {
      return (
        <GilroyFontWrapper>
        <div style={style}>
          <div className="tap-to-refuel">
            <div className="icon-warning-wrapper">
              <RiErrorWarningLine />
            </div>
            Your are running low on meals,{' '}
            <span onClick={() => history.push('/shop/kitchen-home')}>Tap to refuel</span>
          </div>
        </div>
        </GilroyFontWrapper>
      );
    }
    if (index <= pantryMeals.length) {
      return (      
      <GilroyFontWrapper>
        <div style={style}>
          <PantryMeals
            {...pantryMeals[index]}
            moveToPantryDate={moveMealToDiary}
            deletePantryMeal={deletePantryMeal}
            isSuccess={isSuccess}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            mealId={mealId}
          />
        </div>
        </GilroyFontWrapper>
      );
    }
    return (
      <GilroyFontWrapper>
      <div style={style}>
        <PantryMeals
          {...awaitingMeals[index - (pantryMeals.length + 1)]}
          swipeAbleBoolean={false}
          isAwaitingMeal={true}
          moveToPantryDate={moveMealToDiary}
          style={{ zIndex: -1 }}
        />
      </div>
      </GilroyFontWrapper>
    );
  };

  return (
    <PantryTabWrapper>
      {pantryMealsSource?.length < 1 && awaitingMealsSource.length < 1 ? (
        <Empty
          img="/images/dish.png"
          title="Your pantry is empty"
          info="Refuel your pantry with local healthy meals"
          btnText="Shop meals"
          btnDirection={() => history.push('/shop/kitchen-home')}
        />
      ) : (
        <>
          {isLogMealsModal && (
            <PantryDate
              setActiveDay={setActiveDay}
              activeDay={activeDay}
              isLogMealsModal={isLogMealsModal}
            />
          )}

          {pantryMeals?.length < 1 && awaitingMeals?.length < 1 ? (
            <div className="pantry-search-empty" style={{height: window.innerHeight - 205}}>
              <Empty img="/images/dish.png" title={`No Results for "${searchValue}"`} />
            </div>
          ) : (
            <>
              <PantryMealsSnacksStyle>
                <VariableSizeList
                  className="List"
                  ref={listRef}
                  itemCount={
                    searchValue == ''
                      ? pantryMealsSource?.length + awaitingMealsSource?.length + 1
                      : pantryMeals?.length + awaitingMeals?.length + 1
                  }
                  itemSize={itemSize}
                  height={listHeight}
                >
                  {PantryRow}
                </VariableSizeList>
              </PantryMealsSnacksStyle>
            </>
          )}
          {isDone && (
            <div className='done-btn-wrapper'>
              <button onClick={() => setIsLogMealsModal(false)}>
                Done <span>{mealsCount}</span>
              </button>
            </div>
          )}
        </>
      )}
    </PantryTabWrapper>
  );
}
