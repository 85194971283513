import React, { useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import Nutrition from './Nutrition/Nutrition';
import Progress from 'components/elements/Progress';
import Pie from 'components/elements/Pie';
import { generatePieData } from 'utils';
import { useKitchenContext } from 'context/kitchenContext';

const NutritionistStyle = styled.div`
    background-color:${({ theme }) => theme?.colors?.white};
    padding: 4px 58px 25px 24px;
    max-width: 900px;
    border-radius: 6px;
    .title {
        font-size: ${({ theme }) => theme?.typography?.fontBase};
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 20px;
        color: ${({ theme }) => theme?.colors?.blackDefault};
    }
    .other-fact {
        color: '#283241';
        font-weight: 400;
        span {
        font-weight: 600;
        }
    }
`;
const NutritionistFact = ({ calculatedMealData, ingredientData }) => {
    const { mealData } = useKitchenContext() || {};  

    const pieData = useMemo(() => {
        const { fat, protein, carbohydrates } = calculatedMealData;
        return generatePieData(fat?.macroPercent, protein?.macroPercent, carbohydrates?.macroPercent);
    }, [calculatedMealData]);
    
    function formatNumber(number, decimalPlaces = 1) {
        if (typeof number !== 'number' || isNaN(number)) {
            return 0;
        }
        const formattedNumber = parseFloat(number).toFixed(decimalPlaces);
        return formattedNumber === '0.00' ? 0 : formattedNumber;
    };

    const progressLines = [
        {
            id: 'Fat',
            label: 'Fat',
            gram: `${calculatedMealData?.fat?.grams||0}g`,
            percent: `${calculatedMealData?.fat?.macroPercent}`,
            color: '#EE786B',
        },
        {
            id: 'Protein',
            label: 'Protein',
            gram: `${calculatedMealData?.protein?.grams||0}g`,
            percent: `${calculatedMealData?.protein?.macroPercent}`,
            color: '#2399BF'
        },
        {
            id: 'Carbs',
            label: 'Carbs',
            gram: `${calculatedMealData?.carbohydrates?.grams||0}g`,
            percent: `${calculatedMealData?.carbohydrates?.macroPercent}`,
            color: '#8ACEBF'
        },
      ];

  return (
    <NutritionistStyle theme={theme}>
        <div style={{display:"flex", justifyContent: "space-between"}}>
        <Nutrition calculatedMealData={calculatedMealData} ingredientData={ingredientData} mealData={mealData}/>
        <div style={{padding: "30px 20px", textAlign: "-webkit-center"}}>
                <Pie width={200} height={200} titleNumber={ ingredientData?.length ? calculatedMealData?.totalCalories : mealData?.calories ? mealData?.calories === 0 ? "-": mealData?.calories: "-" } titleText="Calories" data={pieData.data} marginTop={5} marginRight={5} marginBottom={5} marginLeft={5} innerRadius={.75} OuterRadiusHover={5} colors={pieData.colors} />
                <div style={{paddingRight:"15px"}}>
                {progressLines.map((line) => (
                        <Progress key={line?.id} width={296} label={line?.label} value={line?.gram} percent={line?.percent} strokeWidth={3} strokeColor={line?.color} trailWidth={3} trailColor='#E0E3E0' />
                    ))}
                    <div className="other-fact">
                            <span>{calculatedMealData?.carbohydrates?.fiberGrams}g</span> fiber,{' '}
                            <span>{calculatedMealData?.carbohydrates?.sugarGrams}g</span> sugar, and{' '}
                            <span>{calculatedMealData?.sodium?.mg}mg</span> sodium
                        </div>
                    </div>
            </div>
        </div>
    </NutritionistStyle>
  );
};

export default NutritionistFact;