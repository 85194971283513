import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useRequest } from "hooks/useRequest";
import Progress from 'components/Progress';
import TagsModal from './TagsModal';
import MacrosBreakdown from './MacrosBreakdown';
import { useAppContext } from '../context/appContext';
import { toast } from 'react-toastify';

const EditPrefrenceStyle = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
height:100%;
z-index: 121;
//padding-top: 21px;
transition: all .5s ease-in-out;
display: flex;
flex-direction: column;
align-items: center;
overflow:hidden;
font-family: 'Roboto';
font-style: normal;

::before{
    content:'';
    width:100%;
    height:100vh;
    position:absolute;
    left:0;
    background-color:#51635D;
    opacity:0.5;
}
.dietary-div{
    background:#ffffff;
    height: 100%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    overflow:auto;
    &::-webkit-scrollbar{
      display:none;
    }
    .macroBreakdownHidden{
        padding-bottom: 55px;
    }
}
.close-icon {
    padding:24px 0 0 23px;
}
.dietary{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    margin-bottom:31px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: -20px;
  }
.log-title {
    margin-bottom: 5px;
    color: #51635D;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }
  .macros{
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color:#51635D;
    margin-bottom: 14px;
}
.breakdown{
    margin-bottom:55px;
}
.update-div{
    background: #FFFFFF;
    border-top: 1px solid #F6F5F8;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    height:89px;
    width:100%;
    position: fixed;
    bottom: 0;
     button{
        width: 271px;
        height: 40px;
        background: #52C41A;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        cursor:pointer;
     }
  }
  .icon-wrapper{
        &.selected{
            &.preference{
                border: 1px solid #97DC76;
                svg{
                    path{
                        fill: #52C41A;
                    }
                }
            }
            &.avoid{
                border: 1px solid rgba(254, 185, 90, 0.3);
                svg{
                    path{
                        fill: #FEB95A;
                    }
                }
            }
        }
    }
`
export default function EditDietPrefrences({ setEditPrefrenceModal, editPrefrenceModal, selfMealPlan, showMacroBreakdown = true, buttonTitle = 'Update', preventUpdate = false }) {

    const { profile, setProfile,dietaryTags, setupUser, TimeZone } = useAppContext();
    const { tags, allergies } = dietaryTags || [];
    const [showSelectedTags, setShowSelectedTags] = useState({ tags: [], restrictions: [] });
    const [showAnim, setShowAnim] = useState(editPrefrenceModal);
    const [preferredDiet, setPreferredDiet] = useState(selfMealPlan?.preferredDiet)
    const [updateProfile, { isLoading: isUpdateLoading, error, setError }] = useRequest('profile', 'post');
    const [previousProfile, setPreviousProfile] = useState(profile);
    const [getUser] = useRequest();
    useEffect(async () => {
        setShowAnim(true);
    }, [editPrefrenceModal]);

    const handleclose = () => {
        setShowAnim(false);
        setTimeout(() => {
            setEditPrefrenceModal(false)
        }, 500);
    }

    useEffect(() => {
        setShowSelectedTags({
            tags: selfMealPlan?.dietaryPreferences || selfMealPlan?.tags,
            restrictions: selfMealPlan?.allergies || selfMealPlan?.food_to_avoid,
        })
    }, [editPrefrenceModal])

    const handleUpdateClick = async () => {
        if(preventUpdate){
            setShowAnim(false);
            setTimeout(() => {
                setEditPrefrenceModal(false);
            }, 500);
            return;
        }
        try {
            await updateProfile({ body: profile });
            toast.success('Diet Preferences Updated Successfully.')
            updateUser();
            setShowAnim(false);
            setTimeout(() => {
                setEditPrefrenceModal(false);
            }, 500);
        } catch (error) {
            toast.error('Oops, Something Went Wrong.')
            console.log(error);
        }
    };

    const updateUser = async () => {
        try {
            const path=`auth/user?TimeZone=${TimeZone}`
            const { user, token } = await getUser({
                path:path,
            });
            setupUser(user, token);
        }
        catch(err){
            console.log("error", err);
        }
    }

    return (
        <EditPrefrenceStyle className={showAnim ? "fadeInMiddle" : "fadeOutMiddle"}>
            <div className='dietary-div'>
                        <img
                            src="/icons/close-green.svg"
                            className="close-icon"
                            onClick={handleclose}
                        />

                        <div className='dietary'>
                            <p>Diet preferences</p>
                        </div>

                        <div className={!showMacroBreakdown ? 'macroBreakdownHidden' : ''}>
                            <TagsModal tags={tags} title={'Recommend'} showSelectedTags={showSelectedTags} setShowSelectedTags={setShowSelectedTags} type={'preferences'} setProfile={setProfile} />
                            <TagsModal tags={allergies} title={'Avoid'} showSelectedTags={showSelectedTags} setShowSelectedTags={setShowSelectedTags} type={'allergies'} setProfile={setProfile} />
                        </div>

                        {showMacroBreakdown && <>
                        <div className='macros'>
                            <p>Macro Breakdown</p>
                        </div>

                        <div className='breakdown'>
                            <MacrosBreakdown preferredDiet={preferredDiet} setPreferredDiet={setPreferredDiet} setProfile={setProfile} />
                        </div>
                        </>}

                        <div className='update-div'>
                            <button
                                className={JSON.stringify(profile) === JSON.stringify(previousProfile) ? 'disabled' : ''}
                                disabled={JSON.stringify(profile) === JSON.stringify(previousProfile)}
                                onClick={handleUpdateClick}
                            >
                                {buttonTitle ? buttonTitle : 'Update'}
                            </button>
                        </div>
            </div>

        </EditPrefrenceStyle>
    )
}