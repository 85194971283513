import React from 'react';
import { motion } from 'framer-motion';
import { useAppContext } from 'context/appContext';


const PageTransition = ({ children }) => {
  const { isMobile } = useAppContext();

  const customStyles = {
      backgroundColor: isMobile ? "#fff" : "",
      height: '100%',
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={customStyles}
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;