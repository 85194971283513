import React, { useState, useEffect ,useRef,useMemo} from 'react';
import styled from 'styled-components';
import { GilroyFontWrapper } from 'shared/theme/style';
import RegularInput from 'components/Input/RegularInput';
import SettingsRowItem from 'components/elements/settingsRowItem/SettingsRowItem';
import { ReactComponent as LogOutIcon } from 'assets/shop/myprofile-logout.svg';
import { ReactComponent as DeleteIcon } from 'assets/shop/myprofile-delete.svg';
import { ReactComponent as VerifiedIcon } from 'assets/shop/myprofile-verified.svg';
import { Button } from 'components/elements/button/Button';
import MobileHeader from 'components/elements/MobileHeader/index.js';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import Avatar from 'components/elements/avatar/Avatar';
import { Box } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import AvatarEditor from 'react-avatar-editor';
import { ReactComponent as Add } from 'assets/shop/add-dark.svg';
import { ReactComponent as Minus } from 'assets/shop/minus.svg';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import Alert from 'components/elements/alert/Alert';
import RemoveAddressModal from '../Addresses/RemoveAddressModal';
import { ASSET_TYPE } from '../../../constants'

const ProfileStyle = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transform: ${({ isProvider }) => ( isProvider ? 'translateY(-25px)' : 'translateY(0)')};
  position: absolute;
  transition: all 0.3s ease-in-out;
  right: ${({ isHidden }) => ( isHidden ? '-100%' : '0')};
  height: ${({ isProvider, isWebView, platform }) => isProvider ? (isWebView && platform ? "86vh" : "100%") : "100vh"};
  .delete-account{
    color: #EB3223;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 36px;
  }
  .heading-text{
    color: #000A2C;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .MuiSlider-rail {
    height: 4px;
  }
  .MuiSlider-track {
    height: 4px;
  }
  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
  }
  .MuiSlider-thumb {
    margin-top: -8px;
  }
  .MuiSlider-root {
    color: #283241;
  }

  .controls {
    position: fixed;
    left: 50%;
    width: 90%;
    gap: 13px;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px !important;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    width: 100%;
  }

  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #283241;
    background: #283241;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #283241;
    background: #283241;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }


  .user_avatar {
    justify-content: center;
    display: flex;
  }
  .text {
    color: #71a2a8;
    font-size: 40px;
    display: flex;
    justify-content: center;
    top: 10px;
    padding-top: 15px;
  }
  .name_info {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 14px;
  }
  .name {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .number_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 14px;
  }
  .verified {
    color: #00092d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .verified-row {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .bottom-box {
    padding-top: 13px;
    margin-top: 24px;
    border: 1px solid #e0e3e0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    .account_urls{
      svg:first-child{
        margin-top: -2px;
      }
    }
  }
  .custom_button {
    height: 42px;
    width: 271px;
    padding: 12px 16px;
    border-radius: 30px;
    position: absolute;
    bottom: ${({platform }) => platform ? "0": "30px"};
    right: 0;
    margin: 10px auto; /* Set left and right margin to "auto" */
    span{
      font-family: Gilroy;
      font-weight: 700;
      font-size: 16px;
      line-height: 19.81px;
    }
    cursor: pointer;
  }
  .name{
    color: #00092D !important;
    font-family: Gilroy;
  }
  .edit {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 52%;
    top: 18%;
    border-radius: 50%;
    background-color: var(--White, #fff);
    filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.08));
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    height: 44px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #e0e3e0;
    background: #fff;
    padding: 0px 14px;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #00092D;
    :hover, :focus {
      border-color: #00092D !important;
    }
  }
  .div_width {
    width: 100%;
  }
  .hasvalue{
    input{
      background: #F5F7F8 !important;
    }
  }

  .deleteAccountWrapper {
    .alert-container {
      position: absolute;
      bottom: 30px;
      .title-desc-wrapper {
        padding: 18px;
      }
      .btn-wrapper {
        padding: 24px 0;
        display: flex;
        justify-content: center;

        button: last-child {
          width: 40% !important;
          margin-top: 0px;
        }
      }

      p {
        margin-top: 21px;
      }
    }
  }
  .upload-photo-section {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 115;
    padding: 0 !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar-editor {
      width: 100% !important;
      max-height: 60vh;
      cursor: grab;
      object-fit: cover;
    }

    .container {
      height: 40vh;
      padding: 23px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .zoom-wrapper {
      display: flex;
      align-items: center;

      .title {
        margin-right: 20px;
        color: #51635d;
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
      }
    }

    button {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 21px;
      line-height: 16px;
      text-transform: capitalize;
      cursor: pointer;
    }

    .show-result {
      background: #000A2C;
      width: 270px;
      color: #fff;
    }

    .cancel {
      margin-top: 10px;
      width: 270px;
      background: #F0F2F4;
      color: black;
    }
  }

  @media (min-width: 991px) {
    margin: auto;
    height: calc(100vh - 175px);
    border-radius: 10px;
    overflow: auto;
    width: 960px;
    left: 0;
    .phone_value{
        font-weight: 600 !important;
    }
      .name{
        color: ${({isEdit}) => isEdit ? "#000A2C" : "#949D9B"} !Important;
      }
      .value{
        color: #00092D;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 9px;
      }
    .user_avatar{
      border-bottom: 1px solid #3240541A;
      padding: 16px 0;
      justify-content: left !important;
    }
    .basic_info_container{
      position: relative;
    }
    .basic-info-heading{
      margin: 24px 0 0;
    }
    .info-container{
      width: 504px;
    }
    .custom_button{
      position: absolute;
      top: 0;
      right: 0;
      width: 97px;
      height: 42px;
      gap: 10px;
      border-radius: 30px;
      border: 1px solid #757E89 !important;
      span{
        line-height: normal;
        color: #00092D;
      }
    }
    .btn_flex{
      .custom_button{
        width: 140px;
      }
    }
    .btn_flex > :first-child {
      .custom_button{
        right: 160px;
        border: 0px solid #DFE2E8 !important; 
        background: #F0F2F4;
      }
    }
    .btn_flex > :last-child {
      .custom_button{
        border:none !important;
        span{
          color: #FFF;
        }
      }
    }

      .upload-photo-section {
      top: 70px;
      .container {
        width: 405px;
      }
    }
    .upload-photo-section .avatar-editor {
      width: 450px !important;
      height: 60vh !important;
    }

    .profile-photo-container{
      margin-bottom: 35px;
    }
  }
  @media (max-width: 991px) {
    .custom_button {
      left: 0;
    }
    .custom_button{
      justify-content: center;
      align-items: center;
    }
  }  
`;

const MainContainer = styled.div`
overflow:hidden;
.alert {
  position: fixed;
  inset: 0;
  z-index: 10;
  text-align: -webkit-center;
  height: 76px;
  width: 100%;
  top: 12px;
}
@media (min-width: 991px) {
  height: calc(100vh - 78px);
  padding: 48px 0; 
  opacity:1;
}
`

export const MyProfile = () => {
  var editor = '';
  const history = useHistory();
  const fileInputRef = useRef(null);
  const { logout, profile, user, setProfile, uploadProfilePic, isMobile, isDesktop, isProvider, provider, setProvider, isWebView, isPlatformIOS} = useAppContext();
  const [open, setOpen] = useState(false);
  const [deleteAccount, { isLoading: isAccountDeleting }] = useRequest('auth/delete-user', 'post');
  const [formValue, setFormValue] = useState({});
  const [updateProfile, { isLoading, error, setError }] = useRequest('profile', 'post');
  const [isPictureBeingUploaded, setIsPictureBeingUploaded] = useState(false);
  const [isEdit, setIsEdit] = useState(isMobile ? true :false);
  const isSaveButtonDisabled = (profile?.name === formValue?.name) && profile?.lastName === formValue?.lastName || !formValue?.name || isLoading;
  const [errorMessage, setErrorMessage] = useState({});
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    setIsHidden(false);
  }, []);

  const userId = isProvider ? provider?.id : user?.id
  const fileType = isProvider ? ASSET_TYPE?.kitchenProfile : ASSET_TYPE?.userProfile;
  const [updatedProvider, { isLoading: isProviderLoading}] = useRequest(`provider/update`, 'post');
  
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: '',
  });
  const animation = {
    initial: {
      x: '100%',
      opacity: 0,
    },
    exit: {
      x: '-100%',
      opacity: 0,
    },
  };
  const {
    name,
    img,
    lastName
  } = profile || {};

  const { phoneNumber } = user;

  useEffect(() => {
    setFormValue((prevState) => ({
      ...prevState,
      name: isProvider ? provider?.name : name,
      lastName: isProvider ? provider?.lastName : lastName,
      img: isProvider ? provider?.images?.[0] : img,
      phoneNumber: isProvider ? provider?.phoneNumber : user?.phoneNumber
    }));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleDeleteAccount = async () => {
    await deleteAccount().then((res) => {
      if (res.success == true) {
        toast.success(res.message);
        setOpen(false);
        setTimeout(() => {
          logout();
        }, 1000);
      } else {
        toast.error(
          'We are unable to process your request at this moment. Please try again later.',
        );
        setOpen(false);
      }
    });
  };

  const handleDeleteAndClose = () => {
    handleDeleteAccount();
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phoneNumber') {
      const isValidPhoneNumber = /^\d{0,10}$/.test(value);
      if (!isValidPhoneNumber) {
        console.log('Invalid phone number. Please enter up to 10 digits.');
        return;
      }
    }
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const updatedFormValue = { ...formValue, email: user?.email, id: provider?.id };
      updatedFormValue.providerEmail = updatedFormValue.email;
      delete updatedFormValue.img;
      delete updatedFormValue.email;
      const res = await (isProvider ? updatedProvider({ body: updatedFormValue }) : updateProfile({ body: {...formValue, phoneNumber: undefined} }));
      isProvider
      ? (
          setProvider({ ...provider, ...updatedFormValue }),
          sessionStorage.setItem('imitateProvider', JSON.stringify({ ...provider, ...updatedFormValue })),
          history.goBack()
        )
      : (
          setProfile((prevState) => ({ ...prevState, name: formValue.name, lastName: formValue.lastName, img: formValue.img })),
          showSuccessMessage('You have successfully updated profile.'),
          setIsEdit(false),
          isMobile && history.push("/shop/account")
        );
        } catch (error) {
      console.error('Error:', error);
      showErrorMessage('Error occurred while saving changes');
      setError(error);
    }
  };
  
  const isSubmitButtonDisabled = useMemo(() => {
    return name === formValue.name && phoneNumber === formValue.phoneNumber && lastName === formValue.lastName;
  }, [name, formValue.name, phoneNumber, formValue.phoneNumber, lastName, formValue.lastName]);

  const isProviderButtonDisabled = useMemo(() => {
    const hasErrors = Object.values(errorMessage).some(value => value.length > 0);
    if(provider?.name === formValue.name && provider?.lastName === formValue.lastName){
      return true
    } 
    else if (formValue.name?.length < 1){
      return true
    }
    else if (isProviderLoading){
      return true
    }
    else {
      return hasErrors;
    }
  }, [name, formValue.name, phoneNumber, formValue.phoneNumber, lastName, formValue.lastName, errorMessage, isProviderLoading]);
  
  const handleFileChange = (e) => {
    if (e?.target?.files[0]) {
      let url = window.URL.createObjectURL(e.target.files[0]);
      setPicture({
        ...picture,
        img: url,
        cropperOpen: true,
      });
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  console.log(user,'user')
  const handlePhotoUpload = useCallback(
    async (picture, fileType, userId) => {
      const blob = await fetch(picture.croppedImg).then((r) => r.blob());
      const file = new File([blob], `${userId}.jpeg`, {
        type: 'image/jpeg',
      });
      let key;
      if (formValue?.img?.includes('.s3.')) {
        const urlPart = formValue?.img.split('/');
        console.log('i am getting called')
        key = urlPart[urlPart.length - 1];
      }
      setIsPictureBeingUploaded(true);
      uploadProfilePic(file, key, fileType)
        .then(async (uploadProfilePicResponse) => {
          setIsPictureBeingUploaded(false);
          setFormValue({
            ...formValue,
            img: uploadProfilePicResponse?.result?.location,
          });
          await updateProfile({
            body: {
              img: uploadProfilePicResponse?.result?.location,
            },
          });
          setProfile((prevState) => ({ ...prevState, img: uploadProfilePicResponse?.result?.location }));
          await setupLocalUser();
        })
        .catch((e) => {
          setIsPictureBeingUploaded(false);
          console.log(e);
        });
    },
    [formValue],
  );

  const handleSaved = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      const updatedData = {
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
        zoom: 1,
      };
      setPicture(updatedData);
      if (typeof handlePhotoUpload === 'function') {
        handlePhotoUpload(updatedData, fileType, userId);
      }
    }
  };

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const handleProfileCancel = () => {
    setIsEdit(false);
    setErrorMessage({});
    setFormValue({...formValue, name: profile?.name, lastName: profile?.lastName, img: profile?.img})
  };

  const handleIncreaseScale = () => {
    const maxScale = 3;
    const newScale = Math.min(picture?.zoom + 0.1, maxScale);
    setPicture({
      ...picture,
      zoom: newScale,
    });
  };
  
  const handleDecreaseScale = () => {
    const scaleStep = 0.1;
    const minScale = 1;
    let newScale = picture?.zoom - scaleStep;
    if (newScale < minScale) {
      newScale = minScale;
    }
    setPicture({
      ...picture,
      zoom: newScale,
    });
    };

    useEffect(() => {
      if(picture?.croppedImg !== ''){
        setFormValue({...formValue,img:picture?.croppedImg})
      }
    },[picture])

    const showSuccessMessage = (title) => {
      setAlertData({
        type: 'success',
        title: title,
      });
      setTimeout(() => {
        setAlertData({ type: '', title: '' });
      }, 5000);
    };
  
    const showErrorMessage = (title) => {
      setAlertData({
        type: 'error',
        title: title,
      });
      setTimeout(() => {
        setAlertData({ type: '', title: '' });
      }, 5000);
    };
  

  return (
    <GilroyFontWrapper>
      <MainContainer>
      {alertData.type && isDesktop && (
        <div className="alert">
          <Alert
            type={alertData.type}
            title={alertData.title}
            width="461px"
            onClose={() => setAlertData({ type: '', title: '' })}
          />
        </div>
      )}
          <ProfileStyle isHidden={isHidden} isEdit={isEdit} isProvider={isProvider} isWebView={isWebView} platform={isPlatformIOS() ? true : false}>
        {isMobile && <MobileHeader onClickLeft={() => history.goBack()} text={'My Profile'}/>}
        {picture.cropperOpen && (
        <Box display="block" className="upload-photo-section">
          <AvatarEditor
            ref={setEditorRef}
            image={picture.img}
            width={375}
            height={375}
            border={20}
            borderRadius={50 * 100}
            color={[11, 29, 23, 0.65]} // RGBA
            rotate={0}
            scale={picture.zoom}
            className="avatar-editor"
          />
          <div className="container">
            <div className="controls">
          <Minus onClick={handleDecreaseScale} />
            <Slider
              type="range"
              value={picture.zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="zoom"
              onChange={handleSlider}
              className="zoom-range"
            />
             <Add onClick={handleIncreaseScale} src='/icons/add-dark.svg'/>
          </div>

          <div style={{ transform: 'translateY(80%)', textAlign: "center" }}>
              <button className="show-result" onClick={handleSaved}>
                Save
              </button>
              <button variant="contained" className="cancel" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </div>
        </Box>
      )}
            <div style={isMobile ? { margin: '26px 24px 0px' } : {padding: '32px 32px 0px 32px'}}>
            {isDesktop && <h1 className='heading-text'>Account info</h1>}
              <div className='user_avatar'>
              <Avatar
                  name={formValue.name}
                  color={'#71A2A8'}
                  bgColor={'#E8F9FB'}
                  height={'75px'}
                  imgSrc={formValue.img}
                  width={'75px'}
                  icon={!isProvider && isMobile && '/icons/edit2.svg'}
                  numbgColor={'white'}
                  onClick={handleClick}
                />
                <input
                  type="file"
                  id="upload"
                  onChange={handleFileChange}
                  accept="image/png, image/jpg, image/jpeg"
                  style={{display: 'none'}}
                  ref={fileInputRef}
                />
              </div>
              <div className='basic_info_container'>
              {isDesktop && <h3 className='heading-text basic-info-heading'>Basic info</h3>}
              <div className='info-container'>
              {!isEdit ? (
              <Button
                title="Edit"
                type="secondary"
                className="custom_button"
                onClick={() => setIsEdit(true)}
              />
              ) : (
                isDesktop && 
                <div className="btn_flex">
                    <Button title="Cancel" size={"small"} type={'secondary'} onClick={handleProfileCancel} className="custom_button"/>
                    <Button title="Save"  size={"small"} type={"dark"} onClick={handleSave} className="custom_button" isDisabled={isSaveButtonDisabled}/>
                </div>
                
            )}

              <div className="name_info">
                <div className="div_width">
                  <p className="name">First name</p>        
                  {!isEdit ? 
                  <p className="value">{isDesktop ? formValue.name?.length > 50 ? formValue.name.substring(0, 50) + "..."  : formValue.name : formValue.name}</p>
                  :
                  <RegularInput
                    id="default"
                    type="text"
                    name={'name'}
                    placeholder="First name"
                    value={formValue.name}
                    message={errorMessage?.name}
                    onChange={(e) => {
                      if (e.target.value.length <= 250) {
                        handleChange(e);
                        setErrorMessage((prevState) => ({...prevState ,name:""}));
                      } else {
                        setErrorMessage((prevState) => ({...prevState ,name:"First name should not be greater than 250"}));
                      }
                      }}                 
                  />
                  }
                </div>
                <div className="div_width">
                  <p className="name">Last name</p>
                  {!isEdit ? 
                  <p className="value">{isDesktop ? formValue.lastName?.length > 50 ? formValue.lastName.substring(0, 50) + "..." : formValue.lastName : formValue.lastName}</p>
                  :
                  <RegularInput
                    id="default"
                    type="text"
                    name={'lastName'}
                    placeholder="Last name"
                    value={formValue.lastName}
                    message={errorMessage?.lastName}
                    onChange={(e) => {
                      if (e.target.value.length <= 250) {
                        handleChange(e);
                        setErrorMessage((prevState) => ({...prevState ,lastName:""}));
                      } else {
                        setErrorMessage((prevState) => ({...prevState ,lastName:"Last name should not be greater than 250"}));
                      }
                      }}                 
                  />
                  }
                </div>
              </div>
              <div className={`${formValue?.phoneNumber?.length > 0 && !isProvider ? 'hasvalue' : ''}`}>
                <div className="number_row">
                  <p className="name">Phone number</p>
                  {isMobile && user?.isPhoneNumberVerified && !isProvider &&
                  <div className="verified-row">
                    <VerifiedIcon />
                    <p className="verified">Verified</p>
                  </div>
                  }
                </div>
                <div className={'hasvalue'}>
                {!isEdit ? 
                  <p className="value phone_value">{phoneNumber}</p>
                  :
                <RegularInput
                  id="default"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formValue?.phoneNumber}
                  readOnly    
                  />
                }
              </div>
              </div>
              <div style={{ marginTop: 14 }} className={`${user?.email?.length > 0 ? 'hasvalue' : ''}`}>
                <p className="name">Email</p>
                {!isEdit ? 
                  <p className="value phone_value">{user?.email}</p>
                  :
                <RegularInput id="default" placeholder="Email" value={user?.email} readOnly/>
                }
              </div>
              {isProvider &&
              <div style={{ marginTop: 14 }} className={'hasvalue'}>
                <p className="name">Role</p>
                <RegularInput id="default" placeholder="Email" value={"Admin"} readOnly/>
              </div>
              }
              </div>
              </div>
            </div>
            {isMobile && !isProvider &&
            <div className="bottom-box">
              <SettingsRowItem
                icon={<LogOutIcon className="icon" />}
                onClick={() => logout()}
                title={'Log out'}
              />
              <SettingsRowItem
                icon={<DeleteIcon className="icon" />}
                title={'Delete account'}
                onClick={handleOpen}
              />
            </div>
            }
            {/* {isMobile && isProvider && 
            <div className='delete-account' >
              <text onClick={() => console.log('i am called')}>Delete account</text>
            </div>
            } */}
            {open && (
              <RemoveAddressModal
              title={"Are you sure you want to delete your account?"}
              setIsRemoveAddressModal={setOpen}
              handleDelete={handleDeleteAndClose}
            />
            )}
            {isMobile &&
            <Button
              isDisabled={isProvider ? isProviderButtonDisabled : isSubmitButtonDisabled || isLoading}
              title="Save"
              type="dark"
              className="custom_button"
              onClick={handleSave}
            />
            }
          </ProfileStyle>
      </MainContainer>
    </GilroyFontWrapper>
  );
};
