import { memo } from 'react';
import styled from 'styled-components';
import BasicButton from './BasicButton';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmationRemoveAccountWrapper = styled.section`
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: rgba(28, 28, 28, 0.6);
  ${FlexCenter};

  .mask {
    position: fixed;
    inset: 0;
  }

  .confirmation-modal-wrapper {
    position: relative;
    width: 335px;
    padding: 32px 0;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .close-modal {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }
    .remove-image-wrapper {
      width: 64px;
      height: 64px;
      background-color: rgb(240, 250, 235);
      border-radius: 50%;
      ${FlexCenter};
    }
    p {
      margin: 28px 0;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
    .basic-button {
      width: 150px;
      height: 40px;
      background: rgb(82, 196, 26);
      color: #fff;
      border-radius: 21px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      cursor: pointer;
      ${FlexCenter};
    }
  }
`;

function ConfirmationRemoveAccount({ setIsConfirmRemove, accountType, handleRemove, isDeleting }) {
    return (
        <ConfirmationRemoveAccountWrapper>
            <div className="mask" onClick={() => setIsConfirmRemove(false)} />
            <div className="confirmation-modal-wrapper">
                <img
                    className="close-modal"
                    src="/icons/Clear-circle-cross.svg"
                    alt="Remove Icon"
                    onClick={() => setIsConfirmRemove(false)}
                />
                <div className="remove-image-wrapper">
                    <img src="/images/trash-light.svg" alt="Remove Image" />
                </div>
                <p>
                    You are deleting {accountType} account
                    <br />
                    Are you sure?
                </p>
                <BasicButton content="Remove" onClick={handleRemove} disabled={isDeleting}/>
            </div>
        </ConfirmationRemoveAccountWrapper>
    );
}

export default memo(ConfirmationRemoveAccount);