import { memo, useMemo } from 'react';
import { motion } from "framer-motion";

import { InputMessageWrapper } from './style';

function InputMessage({ messageStatus, message }) {
  const messageImg = useMemo(() => {
    let statusImg;
    switch (messageStatus) {
      case 'error':
        statusImg = <img src="/icons/error.svg" alt="Error Icon" />;
        break;
      case 'warning':
        statusImg = <img src="/icons/warning.svg" alt="Warning Icon" />;
        break;
      case 'success':
        statusImg = <img src="/icons/success.svg" alt="Success Icon" />;
    }
    return statusImg;
  }, [messageStatus]);

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, x: 100}}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        duration: 0.5,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01]
      }}
    >
      <InputMessageWrapper className={`${messageStatus}Message`}>
        <div className="img-wrapper">{messageImg}</div>
        <p>{message}</p>
      </InputMessageWrapper>
    </motion.div>
  );
}

export default memo(InputMessage);