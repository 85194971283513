import { useState, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import tagIcons from 'components/TagIcons';
import MealTag from './MealTag';
import MealMoreTags from './MealMoreTags';
import { useAppContext } from 'context/appContext';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  @media (min-width:992px){
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 10px;
    border-top: 1px solid #E0E3E0;
    border-bottom: 1px solid #E0E3E0;
    padding: 34px 0px 34px 40px;

    .tag-list{
      display: flex;
      justify-content: start;
    }
  }
  .tagname{
    font-weight: 400;
    font-size: 16px;
    color: #51635D;
    margin-top:5px;
  }
  .tag-container {
    width: fit-content;
    margin-right: 5px;
    display: flex;
    align-items: center;
    // flex-direction: column;

    .desktopView{
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 10px;
    }
  }
  .icon-wrapper {
    width: 30px;
    height: 30px;
    border: 0.5px solid lightGray;
    border-radius: 6px;
    color: #939b99;
    display: grid;
    justify-items: center;
    align-items: center;
    flex-shrink: 0;
    svg {
      width: 14px;
      height: 14px;
      font-size: 20px !important;
    }
  }

  .tag-name {
    /* color: var(--lightGray); */
    font-size: 10px;
    display: none;
   
  }
  .moretag {
    margin-left: 5%;
    color: #52C41A;
    fontsize: 16px;
    white-space: nowrap;
  }

  .MuiTooltip-popperArrow .MuiTooltip-tooltipPlacementBottom {
    margin: 5px 0;
  }

  .iconList45 .MuiTooltip-popper {
    &[x-placement='top'] {
      top: 20px !important;
      left: 0 !important;
    }

    &[x-placement='bottom'] {
      top: -20px !important;
      left: 0 !important;
    }
  }

  &.MealsTag {
    margin-bottom: 11px;
  }

  @media (min-width: 992px) {
    .iconList45 {
      width: auto;
      max-width: fit-content;
    }
    &.MealsTag {
      border: 0;
      padding: 0;

      .tag-container .desktopView {
        display: flex;
      }

      .iconList45 {
        &.recommended .tag-container {
          .icon-wrapper {
            border-color: #97dc76;
            svg path {
              fill: #52C41A;
            }
          }
          &.recommded {
            background: #97DC76;
            border-radius: 6px;
            .icon-wrapper svg path {
              fill: #fff;
            }
          }
        }

        &.notRecommended .tag-container {
          .icon-wrapper {
            border-color: #FEB95A;
            svg path {
              fill: #F08B00;
            }
          }
          &.no-Recommended {
            background-color: #FEB95A;
            border-radius: 6px;
            .icon-wrapper svg path {
              fill: #fff;
            }
          }
        }

        &.recommended svg path,
        &.notRecommended svg path {
          fill: #fff;
        }

        .MuiTooltip-popper {
          top: 0 !important;
          left: -10px !important;
          width: max-content;

          div {
            background-color: #fff;
            color: #949d9b;
            border: 0.5px solid lightGray;
          }
        }

        &.notRecommended .MuiTooltip-popper div,
        &.recommended .MuiTooltip-popper div {
          color: #fff;
          border: 0 !important;
        }

        &.recommended .MuiTooltip-popper div {
          background-color: #52c41a;
        }

        &.notRecommended .MuiTooltip-popper div {
          background-color: #f08b00;
        }
      }

      &.recommended-list .notRecommended .tag-container.no-Recommended .icon-wrapper svg path,
      &.recommended-list .recommended .tag-container.recommded .icon-wrapper svg path {
        fill: #fff;
      }
    }
  }
`;

export default function Tags({ tags = [], recommendedTags = [], notRecommendedRestrictions = [], pickMealsModal, providerName, generateAllTags }) {
  const [displayTags, setDisplayTags] = useState([]);
  const { pathname } = useLocation();
  const { isMobile } = useAppContext();
  const [isActive, setIsActive] = useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);

  const handleClick = (e) => {
    setVisibleDetails(!visibleDetails)
    setIsActive(!isActive);
  }

  const clearItems = (item) => {
    return item.map((tag) => tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase());
  };

  const newTags = clearItems(tags);
  const newNotRecommendedRestrictions = clearItems(notRecommendedRestrictions);
  const newRecommendedTags = clearItems(recommendedTags);

  useEffect(() => {
    const selectTag = newTags.filter((tag) => {
      const result = newNotRecommendedRestrictions.includes(tag)
        ? newNotRecommendedRestrictions
        : newRecommendedTags;
      return result.includes(tag);
    });
    setDisplayTags(selectTag);
  }, [tags.length, recommendedTags.length, notRecommendedRestrictions.length]);


  return ( 
    // isMobile ?
    // (<Container
    //   className={classNames('tags-listing recommended-list', {
    //     MealsTag: pathname == '/shop/meals' || pathname == '/visitor/meals',
    //   })}
    // >
    //   {(pathname == '/shop/meals' || pathname == '/visitor/meals' || pickMealsModal)
    //     ? displayTags.map((tag) => (
    //         <MealTag
    //           Icon={tagIcons[tag]}
    //           tag={tag}
    //           recommendedTags={recommendedTags}
    //           notRecommendedRestrictions={notRecommendedRestrictions}
    //         />
    //       ))
    //     :tags.map((tag) => (
    //         <MealTag
    //           Icon={tagIcons[tag]}
    //           tag={tag}
    //           recommendedTags={recommendedTags}
    //           notRecommendedRestrictions={notRecommendedRestrictions}
    //         />
    //       ))}
    // </Container>) :
   <Container className={classNames('tags-listing recommended-list', {
                 MealsTag: pathname == '/shop/meals' || pathname == '/visitor/meals',
               })}>
                 
      {((pathname == '/shop/meals' || pathname == '/visitor/meals') ? generateAllTags : tags.slice(0,7)).map((tag, index) => (
    
        <div className='multiTags-listing' key={index}>
          <MealTag
              Icon={tagIcons[tag]}
              tag={tag}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
          />
         
      </div>
    
    ))}
      {!isMobile && pathname == '/shop/providers' &&
        <div className='multiTags-listing' style={{ display: tags.length <= 7 ? 'none' : '' }}>
   
             <div className='tag-container'
              onClick={(e)=>{
                handleClick();
                e.stopPropagation();
                }}>
             <span className="icon-wrapper">
              <img src = {!isActive ? '/icons/moretag.svg' : '/icons/arrowicon.svg' } alt="More Tag" />
              </span>
              <span className='moretag'>
               {!isActive ? ' +'+ tags.slice(7,12).length +' More' : 'Close '} 
             </span>
             </div>
        </div>
      }
        {visibleDetails ?( tags.slice(7,15)).map((tag, index) => (
      <div className='multiTags-listing' key={index}>
          <MealTag
              Icon={tagIcons[tag]}
              tag={tag}
              recommendedTags={recommendedTags}
              notRecommendedRestrictions={notRecommendedRestrictions}
          />
         
      </div>
    
    )):""}
   
  </Container>
  );
}
