import Slider from "react-slick";
import styled from 'styled-components';
import Review from "./Review";
import { useAppContext } from "context/appContext";
import { PeopleReviewsItems } from "./VisitorLandingData";
import { ReactComponent as AngleLeft } from 'icons/angle-left.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { theme } from "shared/theme/theme";
import { useState } from "react";

const PeopleReviewsWrapper = styled.div`
  padding: 64px 0 80px;  
  background-color: #F6F5F8;
  text-align: center;
  font-family: Gilroy;
  h3 {
    color: #283241;
    font-family: Gilroy;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .slick-dots {
    bottom: -45px;
  }

  .slick-slider{
    margin-top: 64px;
  }

  .green{
    color: var(--Local-green, #49A332);
  }

  .slick-arrow {
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: ${theme.colors.primaryDarker};
    display: flex !important;
    align-items: center;
    justify-content: center;

    &:before {
      display: none;
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        stroke: #fff
      }
    }

    &.slick-disabled {
      background-color: ${theme.colors.grayDarker};
    }
  }

  @media (min-width: 767px) {
    padding: 0;
    padding: 150px 0 150px;  
    background-color: #F0F2F4;
    @media (min-width: 767px) and (max-width: 1440px){
      padding: 100px 0 100px;  
    }

    h3{
      color: ${theme.colors.primaryDarker};
      font-size: 24px;
      font-weight: 700;
      line-height: normal;  
    }
    .slick-arrow {
      left: 27%;
    }
    .slide img{
      margin: 0 auto;
    }
    .slide{
      transform: scale(0.5);
      transition: transform 300ms;
      opacity: 0.5;
    }
    .activeSlide{
      transform: scale(1.1);
      opacity: 1;
    }
    
    .people-review-container {
      position: relative;
      width: auto;
      background-color: ${theme.colors.grayLighter};
    }

    .slider-wrapper {
      h3 {
        color: #283241;
        text-align: center;
        font-family: Gilroy;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (min-width: 768px) and (max-width: 1280px){
          font-size: 48px;
        }    
        }
    }

    .people-photo {
      position: absolute;
      bottom: 0;
      right: 51px;
      width: 425px;
      height: 557px;
    }


    .slick-next {
      left: 72% !important;
    }
  }
  @media (max-width: 767px) {
    .slick-slider{
      margin-left: 20px;
    }
    .slick-next {
      right: 3%;
    }
    .slick-prev {
      left: -3%;
  }  
  h3{
    padding: 0 29px;
    text-align: left;
  }
  }
`;

function SampleNextArrow(props) {
  const { className, style, onClick, autoplay } = props;

  const handleClick = () => {
    onClick();
    if (autoplay) {
      autoplay('forward');
    }
  };

  return (
    <div className={className} style={{ ...style }} onClick={handleClick}>
      <img src="/icons/arrow-right-white.svg" alt="Arrow Icon" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick, autoplay } = props;

  const handleClick = () => {
    onClick();
    if (autoplay) {
      autoplay('backward');
    }
  };

  return (
    <div className={className} style={{ ...style }} onClick={handleClick}>
      <AngleLeft />
    </div>
  );
}

export default function PeopleReviews() {
  const { isMobile } = useAppContext();
  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    dots: true,
    lazyLoad: true,
    slidesToShow: window.innerWidth < 768 ? 1 : 3,
    centerMode: true,
    centerPadding: 0,
    arrows: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (current, next) => setImageIndex(next)
  };

  return (
    <PeopleReviewsWrapper>
      <div className="people-review-container">
        <div className="slider-wrapper">
          <h3>food we’d feed <span className="green">our family.</span></h3>
          <Slider {...settings}>
            {PeopleReviewsItems.map((item, id) => (
              <div className={id === imageIndex ? "slide activeSlide" : "slide"}>
              <Review {...item} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </PeopleReviewsWrapper >
  )
}