import { lazy, Suspense } from 'react';
import styled from 'styled-components';
import { increasePeopleMoney } from './LandingData';
import OverlayLoader from 'components/overlayLoader';
const PlanSteps = lazy(() => import('components/Landing/PlanSteps'));
const HealthyFood = lazy(() => import('components/Landing/HealthyFood'));
const MealPlanning = lazy(() => import('components/Landing/MealPlanning'));
const NutrittLandingHeader = lazy(() => import('layout/NutrittLandingHeader'));
const FrequentlyAskedQuestions = lazy(() => import('components/Landing/FrequentlyAskedQuestions'));

const LandingWrapper = styled.div`
  min-height: calc(100vh - 32px);
  background-color: #fff;

  .container {
    width: 60%;
    margin: 0 auto;
  }

  img {
    object-fit: cover;
  }

  .increase-people-money-section {
    padding: 80px 0 48px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e0e3e0;
    justify-content: space-between;

    .img-wrapper {
      margin-bottom: 32px;
      text-align: center;

      img {
        width: 64px;
        height: 64px;
      }
    }

    p {
      width: 170px;
      color: #51635d;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @media(min-width:750px) and (max-width:850px){
        margin-left:93px;
      }
    }
  }

  .sign-up-section {
    padding: 70px 0;
    background-color: #f7f6f980;
    text-align: center;

    .title {
      margin-bottom: 25px;
      color: #0b1d17;
      font-size: 48px;
      font-family: 'Roboto';
      line-height: 56px;
      letter-spacing: 0.5px;
      font-weight:700;
    }

    button {
      width: 220px;
      height: 52px;
      background: #52c41a;
      color: #ffffff;
      border-radius: 26px;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      letter-spacing: 0.5px;
      cursor: pointer;

      &:hover {
        background: #cbedba;
        color: #51635d;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .note-section {
    margin-bottom: 17px;
    padding: 30px 0;

    p {
      color: #949d9b;
      font-size: 12px;
      line-height: 15px;

      span {
        color: #51635d;
        font-family: 'Roboto';
        letter-spacing: 0.5px;
      }
    }
  }

  @media (max-width: 1400px) {
    .container {
      width: 80%;
    }
  }

  @media (max-width: 991px) {
    .container {
      width: 90%;
    }

    .increase-people-money-section {
      padding: 48px 0;
      flex-direction: row;
      flex-wrap: wrap;
      .item{
        flex: 0 0 48%;
        max-width: 48%;
        margin-bottom: 12px;
      }

      .img-wrapper {
        height: 64px;
      }

      .item + .item {
        margin-top: 0px;
      }
    }

    .sign-up-section {
      margin-bottom: 10px;
      padding: 48px 0;

      .title {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .note-section p {
      font-size: 10px;
      line-height: 11px;
      text-align: center;
    }
  }
`;

export default function Landing() {
  return (
    <Suspense fallback={<OverlayLoader active />}>
      <LandingWrapper>
        <NutrittLandingHeader />
        <MealPlanning />
        <div className="container">
          <div className="increase-people-money-section">
            {increasePeopleMoney.map((item, index) => (
              <div key={index} className="item">
                <div className="img-wrapper">
                  <img src={`images/${item.title}.svg`} alt={`i${item.title} Image`} />
                </div>
                <p>{item.content}</p>
              </div>
            ))}
          </div>
        </div>
        <HealthyFood />
        <div className="container">
          <PlanSteps />
          <FrequentlyAskedQuestions />
        </div>
        <div className="sign-up-section">
          <div className="container">
            <div className="title">Ready to sign up?</div>
            <a href="https://nutritt.typeform.com/Trainer-Profile" target="_blank">
              <button>Get Started</button>
            </a>
          </div>
        </div>
        <div className="container">
          <div className="note-section">
            <p>
              <span>*Nutritt</span> does not guarantee income of any kind. Money that you make from
              the platform is based on your own performance, see our terms and conditions. Any
              results or testimonials in which others have made money are solely based on their
              experience and may not be typical.
            </p>
          </div>
        </div>
      </LandingWrapper>
    </Suspense>
  );
}
