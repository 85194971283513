import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'components/elements/button/Button';
import { RejectModalWrapper } from './style';
import { useAppContext } from 'context/appContext';

export default function RejectModal({ setIsRejectModal, generateRequest, providerId, orderMainId }) {
  const { isWebView } = useAppContext();

  return (
    <RejectModalWrapper isWebView={isWebView}>
      <div className="reject-mask" onClick={() => setIsRejectModal(false)} />
      <motion.div
        className="reject-modal-container"
        initial={{ y: 300 }}
        exit={{ y: 300 }}
        animate={{ y: 0 }}
        transition={{
          duration: 0.3,
          delay: 0.3,
          ease: 'linear',
        }}
      >
        <img
          src="/icons/add-black-modal.svg"
          alt="Close Icon"
          className="close-img"
          onClick={() => setIsRejectModal(false)}
        />
        <div className="reject-info">Are you sure you’d like to reject this order?</div>
        <div className="reject-btns-wrapper">
          <Button
            title="Yes"
            type="dark"
            onClick={() => generateRequest('refused', providerId, orderMainId)}
          />
          <Button title="Cancel" type="mobile_secondary" onClick={() => setIsRejectModal(false)} />
        </div>
      </motion.div>
    </RejectModalWrapper>
  );
}
