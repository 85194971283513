import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const CalenderStyle = styled.div`
    display: flex;
    gap: 46px;
    .react-datepicker__day--outside-month{
        color:#E0E4E8 !Important;
        visibility: hidden;
    }
    .react-datepicker-wrapper {
        display: flex;
        align-items: center;
    }
   &.notSelected .react-datepicker__month .react-datepicker__day--selected,
   &selected .react-datepicker__month .react-datepicker__day--today {
     color: #00092D;
     font-weight: 400;
   }
   .react-datepicker__month .react-datepicker__day.future-day {
     background-color: #00092D;
     color: #aaa;
   }
   .react-datepicker-wrapper {
     width: fit-content;
   }
   .current-date {
     color: #000000;
     font-size: 16px;
     font-family: Roboto;
     font-weight: 700;
     cursor: pointer;
   }
   .react-datepicker,
   .react-datepicker__month-container {
  
     font-family: "Helvetica Neue",helvetica,arial,sans-serif;
     font-size: .8rem;
     background-color: #fff;
     color: #000;
     border-radius: 0.3rem;
     display: inline-block;
     left: 0px;
     width: 237px;

    .react-datepicker__week{
        display:flex;
        height: 34px;
    }
   .react-datepicker__navigation {
     -webkit-align-items: center;
     align-items: center;
     background: none;
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
     justify-content: center;
     text-align: center;
     cursor: pointer;
     position: absolute;
     top: 2px;
     padding: 0;
     border: none;
     z-index: 1;
     height: 41px;
     width: 23px;
     text-indent: -999em;
     overflow: hidden;
   }
   }
   .react-datepicker__navigation-icon--next {
     left: -10px;
   }
   .react-datepicker__navigation-icon--previous {
    left: 10px;
      justify-content: center;
   }
   .react-datepicker-popper {
     width: 300px;
     z-index: 100;
   }
   .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
     border-bottom-color: #fff;
   }

   .react-datepicker {
     border: 0;
     display: flex;
     gap: 35px; 
     .react-datepicker__triangle::before,
     .react-datepicker__triangle::after {
        border-bottom-color: #fff;
       transform: translate3d(5px, 0px, 0px);
       visibility:hidden;
     }

   .react-datepicker__header {
     text-align: center;
     background-color: #fff;
     border-bottom: none;
     border-top-left-radius: 0.3rem;
     padding: 12px 0 0px;
     position: relative;
    }
     .react-datepicker__current-month {
       margin-top: 0;
       color: #283241;
       text-align: center;
       font-family: Gilroy;
       font-size: 14px;
       font-style: normal;
       font-weight: 600;
       line-height: 20px;
    }
     .react-datepicker__day-names {
       margin: 0px 6px -10px 6px;
       padding-top: 14px;
       color: #757E89;
       font-size: 11px;
       font-weight: 400;
       line-height: 12px;
       display:flex;
       .react-datepicker__day-name{
        color: #3B425D;
       }
     }
   }
   .react-datepicker__day-name {
     width: 32px;
     height: 25px;
     color: #000A2C;
     text-align: center;
     font-family: Gilroy;
     font-size: 12px;
     font-style: normal;
     font-weight: 400;
     line-height: 16px;
     margin: 5px;
   }
   .react-datepicker__navigation-icon::before {
     width: 7px;
     height: 5px;
     margin-top: 2px; 
     border-color: #9CA3AD;
     border-width: 2px 2px 3px 0px;
   }
   .react-datepicker__navigation-icon--previous::before {
    right: -6px;
   }

   .react-datepicker__month {
     margin: 6px;
     .react-datepicker__day,
     .react-datepicker__day--disabled,
     .react-datepicker__day--keyboard-selected,
     .react-datepicker__day--selected {
        width: 25px;
        height: 24.59px;
        border-radius: 100px;
        margin: 4px;
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
    }
   }
   .react-datepicker__navigation--previous {
     left: auto;
   }
 }
  .react-datepicker__year-text{
    color:000A2C;
    width: 25px;
    height: 24.59px;
    border-radius: 100px;
 }
 .react-datepicker__day--selected{
    background-color: #00092D;
 }
  .react-datepicker__quarter-text{
    color: #000A2C;
 }
 .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
    color: #E0E4E8;
 }
 .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color:#fff;
 }
  .react-datepicker__day--selected{
    color: #fff !important;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    color: #283241;
}
.react-datepicker__navigation--next{
    right: -270px;
}
.selected-date {
    background-color: #000A2C !important;
    color: #fff !important;
    border-radius: 50% !important;
}
.past-date{
    color:  #C9CCCF;
}
.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted{
  background-color: #000A2C !important;
}
`

export default function Calender({ selectedDates, setSelectedDates }) {
    const handleDateChange = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        const dateIndex = selectedDates.findIndex(selectedDate =>
            selectedDate === formattedDate
        );

        if (dateIndex > -1) {
            const newSelectedDates = [...selectedDates];
            newSelectedDates.splice(dateIndex, 1);
            setSelectedDates(newSelectedDates);
        } else {
          if (isPastDate(date)) {
            return;
          }
            setSelectedDates([...selectedDates, formattedDate]);
        }
    };

    const isPastDate = (date) => {
        const today = moment().startOf('day');
        return moment(date).isBefore(today);
    };

    const dayClassName = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        if (isPastDate(date)) {
            return 'past-date';
        } else if (selectedDates.includes(formattedDate)) {
            return 'selected-date';
        }
        return undefined;
    };

        
  return (
    <CalenderStyle>
            <DatePicker
                selected={null}
                onChange={handleDateChange}
                formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                monthsShown={2}
                inline
                highlightDates={selectedDates.map(date => moment(date).toDate())}
                dayClassName={dayClassName}
                filterDate={date => true} 
            />
 </CalenderStyle>
)
}