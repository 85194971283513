import { useAppContext } from 'context/appContext';
import styled from 'styled-components';
import SoicalMediaIcons from './SocialMediaIcons';
import { Link } from 'react-router-dom';
import { useChatContext } from 'context/chatContext';
import {useState, useEffect, useMemo} from 'react';
import ReactTooltip from 'react-tooltip';

const CoachProfileCardStyle = styled.div`
  padding: 27.7px 0 32px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px #0000000d;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 335px;
  height: 220px;
  top: 24px;
  justify-content:center;

  .active__icon{
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: #AFB5B4;
    border: 2px solid #fff;
    border-radius: 50%;
    right: 7px;
    bottom: 7px;

    &.active{
      background-color: #35A956;
    }
  }

  .coach-photo {
    width: 92.59px;
    height: 92.59px;
    left: 141.7px;
    top: 74px;
    border: 1px solid #E0E3E0;
    box-sizing: border-box;
    border-radius: 75px;
    padding: 2px;
    object-fit: cover;
    display: block;
  }
    .registered-title {
      text-align: center;
      margin-top: 11px;

      h4 {
        color: #0b1d17;
        font-size: 16px;
        font-family: 'Roboto';
        line-height: 19px;
      }

      span {
        color: #949d9b;
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .social-image-start,
    .social-image-end {
      cursor: pointer;
      margin-top: 20px;
      display: flex;
    }

    .social-image-start div {
      border-radius: 0px 15px 15px 0px;

      img {
        margin-left: 8px;
      }
    }

    .social-image-end div {
      border-radius: 15px 0 0 15px;
      justify-content: flex-end;
    }
    @media (min-width: 992px) {
      position: relative;
      // top: 62px;
    }

    .chat_with_coach_icon{
      position: relative;

      .unseenMsg{
        position: absolute;
        left: 20px;
        bottom: 14px;
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        text-align: center;
        background: #EB3223;
        border: 0.5px solid #EB3223;
        color: #fff;
        z-index: 2;
      }

      img{
        display: block;
        filter: invert(0%);
        margin: 0 9px 0 8px;
      }
    }

    .__react_component_tooltip{
      text-align: center;
      width: 250px;
      word-break: break-all;
    }
`;

export default function CoachProfileCard({nutritionist, coachPhoto, name, insta, faceBook: fb, tiktok : tk, title}) {
  // const {nutritionist} = useAppContext();
  const tiktok_ROOT = `https://www.tiktok.com/`;
  const instagram_ROOT = `https://www.instagram.com/`;
  const facebook_ROOT = `https://www.facebook.com/`;
  const {unreadMsgCount, isMobile, user, getAllUsersOfConversation} = useAppContext();
  const userType = user?.type?.toString();
  const [userIsActive, setUserisActive] = useState(false);

  useEffect(() => {
    if(getAllUsersOfConversation.length > 0){
      const isActive = Array.isArray(getAllUsersOfConversation)
        && user?.nutritionist?.userId
        && getAllUsersOfConversation.filter((item) => item?.identity == user?.nutritionist?.userId)
        .some((item) => item?.isOnline);
        setUserisActive(isActive);
    }
}, [getAllUsersOfConversation])

  const verifyLink = useMemo(() => {
    return (Link, name) => {
    const link1 = Link?.split('//');
    if(link1?.length > 1){
        return Link;
    } else{
      if(Link?.includes('.com')){
        var finalLink = 'https://'.concat(Link)
        return finalLink;
      } else{
        var name = name==='facebook' ? facebook_ROOT: name==='instagram'? instagram_ROOT : tiktok_ROOT;
        return name.concat(Link)
      }  
    }
  }
},[])
  var {instagram, tiktok, facebook }= nutritionist?.socialProfile || {};
  if(insta){instagram = verifyLink(instagram, 'instagram');}
  if(tk){tiktok= verifyLink(tiktok, 'tiktok');}
  if(fb){facebook= verifyLink(facebook, 'facebook');}

  useEffect(()=>{
    ReactTooltip.rebuild();
  },[])

  return (
    <CoachProfileCardStyle>
      <div style={{position: 'relative'}}>
      <img className="coach-photo" src={coachPhoto} alt="Registered Detitian" />
        <div className={`active__icon ${userIsActive?'active':''}`}></div>
      </div>
      <div className="registered-title">
        {name?.length>30 ? <h4 data-tip={name} data-for="name" style={{cursor: 'pointer'}} >{name.substring(0, 30) + "..."}</h4> :  <h4>{name}</h4>}

        <span>{title}</span>
      </div>
     
      <div className='social-image-start'>
        {userType === "user" && isMobile && user?.nutritionist && <Link to={"/shop/chat-with-coach"} className="chat_with_coach_icon">
          <img src='/icons/chat-dark.svg'/>
          {unreadMsgCount[0]?.conversationUnreadMsg > 0 && <span className='unseenMsg'>{unreadMsgCount[0]?.conversationUnreadMsg}</span>}
        </Link> }
        {insta &&
         ( window.ReactNativeWebView ?  
         <a onClick={(e)=>{
          e.preventDefault();
          window.ReactNativeWebView.postMessage(JSON.stringify({event:"social-link",screen:instagram}));
         }}>
          <SoicalMediaIcons img='/icons/InstagramGreen.svg'/></a>
         :<a href={instagram} target="_blank"><SoicalMediaIcons img='/icons/InstagramGreen.svg'/></a>)
        }
        {tk &&
      ( window.ReactNativeWebView ?  
        <a onClick={(e)=>{
        e.preventDefault();
        window.ReactNativeWebView.postMessage(JSON.stringify({event:"social-link",screen:tiktok}));
        }}><SoicalMediaIcons img='/icons/tiktok-icon.svg' /></a>
        :<a href={tiktok} target="_blank"><SoicalMediaIcons img='/icons/tiktok-icon.svg' /></a>
        )
        }
        {fb &&

        (window.ReactNativeWebView ?
          <a onClick={(e)=>{
            e.preventDefault();
            window.ReactNativeWebView.postMessage(JSON.stringify({event:"social-link",screen:facebook}));
          }}><SoicalMediaIcons img='/icons/FaceBook.svg'/></a>:
          <a href={facebook} target="_blank"><SoicalMediaIcons img='/icons/FaceBook.svg'/></a>
          )
        }
      </div>
      <ReactTooltip id="name" place="top" effect="solid">
     </ReactTooltip>
    </CoachProfileCardStyle>
  );
}
