import styled from 'styled-components';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { theme } from 'shared/theme/theme';

const StackSliderStyled = styled.div`
    margin-bottom: 8px;
    margin-left: 12px;
    margin-right: 12px;
    height: 62px;
   .card_detail{
        font-family: ${theme?.typography?.fontGilroy};
        font-size: ${theme?.typography?.fontSmall};
        color: ${theme?.colors?.primaryDarker};
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        height: 32px;
        margin-bottom: -8px;
        span{
            font-weight: 500;
            line-height: 32px;
            font-size: 12px;
        }
    }
    .slider_percent{
        padding: 16px 0 8px;
        span:nth-child(1){
          height:10px;
          background: #F6F5F8;
          opacity: 1;
        }
        span:nth-child(2){
          height:10px !important;
          color:#FEB95A; 
          transition: 0.3s all;
        }
        span:nth-child(3){
          color:white;
          transition: 0.3s all;
          border: 1px solid #AFB5B4;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
          border-radius: 9px;
          background-image: url('/icons/sliderDots.svg');
          background-position: center;
          background-size: 7px;
          background-repeat: no-repeat;
        }
        &.fatPercent{
            span:nth-child(2){
                color: ${theme?.colors?.coloradoBronze} !important;
            }
        }
        &.proteinPercent{
            span:nth-child(2){
                color: ${theme?.colors?.rockBlue} !important;
            }
        }
        &.carbsPercent{
            span:nth-child(2){
                color: ${theme?.colors?.majorBlue} !important;
            }
        }
    }
    .percent{
        display: flex;
        justify-content: space-between;
        span{
            font-weight: 500;
            font-size: 9px;
            line-height: 12px;
            letter-spacing: 0.05em;
            color: ${theme?.colors?.grayDarker};
            width: 25px;
            text-align: center;
            &:first-child{
                text-align: left;
            }
            &:last-child{
                text-align: end;
            }
        }
    }
`

export default function StackSlider({ handleMacroChanges, preferredDiet, type }) {
    let sliderValue, percent;
    switch (type) {
        case "fat":
            sliderValue = preferredDiet?.fatPercent;
            percent = "fatPercent";
            break;
        case "protein":
            sliderValue = preferredDiet?.proteinPercent;
            percent = "proteinPercent";
            break;
        case "carbs":
            sliderValue = preferredDiet?.carbsPercent;
            percent = "carbsPercent";
            break;
        default:
            sliderValue = 0;
            break;
    }

    return (
        <>
            <StackSliderStyled theme={theme}>
                <div className='card_detail'>
                    <p>{type.charAt(0).toUpperCase() + type.slice(1)}</p>
                    <span>{sliderValue}%</span>
                </div>
                <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <Slider value={sliderValue} className={`slider_percent ${percent}`} min={0}
                        onChange={(v) => handleMacroChanges(percent, v.target.value)}
                    />
                </Stack>
                <div className='percent'>
                    <span>0%</span>
                    <span>25%</span>
                    <span>50%</span>
                    <span>75%</span>
                    <span>100%</span>
                </div>
            </StackSliderStyled>
        </>
    )
}
