import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import Nutritions from 'pages/onboarding/components/Nutritions';
import tagIcons from 'components/TagIcons';
import MealTag from 'components/shop/MealTag';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';

const StyledTooltip = withStyles({
  tooltipPlacementBottom: {
    marginTop: '30px'
  },
})(Tooltip);


const FlexColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MoreUserDetailsWrapper = styled.div`
  width: 100%;
  ${FlexColumnCenter};

  .lose-days-wrapper {
    margin-bottom: 24px;
    padding: 24px 20px;
    border-top: 1px solid #e0e3e0;
    border-bottom: 1px solid #e0e3e0;
  }

  .more-title {
    margin-bottom: 24px;
    color: #51635d;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 19px;
    text-align: center;

    &.lose-days + div {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }

  .food-recommanded-wrapper {
    width: 100%;
  }

  .eat-avoid-wrapper {
    margin-bottom: 24px;
    display: flex;

    .food-wrapper {
      width: 50%;
      ${FlexColumnCenter};

      &:first-child .images-container {
        justify-content: flex-end;

        .image-wrapper {
          margin: 0 0 5px 5px;
        }
      }

      & + .food-wrapper {
        border-left: 1px solid #e0e3e0;
      }

      .food-title {
        margin-bottom: 11px;
        color: #51635d;
        font-size: 14px;
        line-height: 16px;
        display: block;
      }
    }

    .images-container {
      display: flex;
      flex-wrap: wrap;

      @media (max-width:992px){
         display: grid;
         grid-template-columns: auto auto auto;
         gap: 5px;
      }

      .image-wrapper {
        width: 24px;
        height: 24px;
        margin: 0 5px 5px 0;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .lose-days-wrapper {
      padding: 30px 10px;
    }

    .eat-avoid-wrapper {
      margin-bottom: 32px;
    }

    .food-wrapper {
      padding-left: 10px;
      margin-left: 10px;

      .icon-wrapper {
        display: flex;

        svg {
          width: 14px;
          font-size: 14px;
        }
      }
    }
  }
`;

const eat = ['soy', 'fish', 'halal'];
const avoid = ['tree-nuts', 'milk', 'Eggs'];

export default function MoreUserDetails({ setIsMore, profile }) {
  const { isMobile } = useAppContext(); 

  // const {
  //   dietTemplate: { name },
  //   dietMetrics: {
  //     calories,
  //     macros: { fat, protein, carbs },
  //   },
  // } = profile;

  return (
    <MoreUserDetailsWrapper>
      <div className="lose-days-wrapper">
        <div className="more-title lose-days">
          {profile?.dietTemplate?.name.charAt(0).toUpperCase() +
            profile?.dietTemplate?.name.slice(1).toLowerCase()}
        </div>
        <Nutritions
          calories={profile?.dietMetrics?.calories}
          carbs={profile?.dietMetrics?.macros?.carbs}
          fat={profile?.dietMetrics?.macros?.fat}
          protein={profile?.dietMetrics?.macros?.protein}
        />
      </div>
      <div className="food-recommanded-wrapper">
        <div className="more-title">Food recommendations</div>
        <div className="eat-avoid-wrapper">
          <div className="food-wrapper">
            <span className="food-title">Eat</span>
            <div className="images-container">
              {profile?.dietTemplate?.tags && (
                <>
                  {profile?.dietTemplate?.tags.map((food, index) => (
                    <StyledTooltip title={food} key={index} arrow>
                      <div className="image-wrapper">
                        <MealTag Icon={tagIcons[food]}/>
                      </div>
                    </StyledTooltip>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="food-wrapper">
            <span className="food-title">Avoid</span>
            <div className="images-container">
              {profile?.dietTemplate?.food_to_avoid && (
                <>
                  {profile?.dietTemplate?.food_to_avoid.map((food, index) => (
                    <StyledTooltip title={food} key={index} arrow>
                      <div className="image-wrapper" key={index}>
                        <MealTag Icon={tagIcons[food]}/>
                      </div>
                    </StyledTooltip>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <button className="more-button" onClick={() => setIsMore(false)}>
          Show less
        </button>
      )}
    </MoreUserDetailsWrapper>
  );
}
