import React, { useState } from 'react';
import MobileHeader from 'components/elements/MobileHeader';
import styled from 'styled-components';
import { ReactComponent as DollarIcon } from 'assets/shop/dollar.svg';
import { ReactComponent as CloseIcon } from 'assets/shop/Close.svg';
import RegularInput from 'components/Input/RegularInput';
import { Button } from 'components/elements/button/Button';
import Success from './Success';
import { AnimatePresence, motion } from 'framer-motion';
import { useRequest } from 'hooks/useRequest';
import { useShopContext } from 'context/shopContext';

const PromoCodeStyle = styled.div`
  background-color: #fff;
  height: 100vh;
  .mobile-header{
    height: 56px;
    display: flex;
    width: 100%;
    padding: 8px 0px 8px 16px;
    align-items: center;
    gap: 8px;
    .icon-wrapper{
        width: 40px;
        height: 40px;
    }
  }
  .dollar-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 20px;
  }
  .gift-card-text {
    padding: 0px 70px;
    text-align: center;
    color: #0b1d17;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .submit-button-container {
    margin: 24px 0px;
    button{
      font-family: 'Gilroy';
    }
  }
  .promo-code-input {
    padding: 0px 20px;
    margin-top: 16px;
    position: relative;
    input {
      font-size: 14px !important;
      font-weight: 500;
      font-family: 'Gilroy';
      height: 44px;
      &:focus {
        border: 1px solid #00092d !important;
      }
    }
    .ErrorMessage{
        margin-top: 7px;
        p{
          font-family: Gilroy;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
    }
  }
`;

const PromoCode = ({ setIsPromoCodeOpen }) => {
  const [isOpenSuccessMsg, setIsOpenSuccessMsg] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const { setCashBalance } = useShopContext();
  const [handleRequest] = useRequest();
  const [isPromoCodeInvalid, setIsPromoCodeInvalid] = useState(false);
  const [promocodeError, setPromocodeError] = useState('');

  const onCloseClick = () => {
    setPromoCode('');
    setIsPromoCodeInvalid(false);
  }

  const handlePromocodeSubmit = async (promoCode) => {
    try{
        if(!promoCode) return;
        const path = `promocode/cash`;
        const body = JSON.stringify({ code: promoCode });
        const response = await handleRequest({ path, method: "POST", body });
        if(response?.success){
            setCashBalance(response?.cashBalance);
            setIsOpenSuccessMsg(true);
        }
    }
    catch(err){
        setIsPromoCodeInvalid(true);
        setPromocodeError(err);
        console.log('err', err);
    }
  }

  return (
    <>
      <PromoCodeStyle>
        <MobileHeader
          className={'mobile-header'}
          text={'Enter promo code'}
          onClickLeft={() => setIsPromoCodeOpen(false)}
        />
        <div className="dollar-img">
          <DollarIcon alt="dollar-icon" />
        </div>
        <div className="gift-card-text">
          Enter your promotional code or gift <br /> card to redeem your Nutritt Cash! 🤗
        </div>
        <RegularInput
          className={`promo-code-input ${isPromoCodeInvalid ? 'error':''}`}
          label=""
          name="promo-code"
          value={promoCode}
          onSuffixClick={onCloseClick}
          icon={isPromoCodeInvalid && promoCode && "/images/Close.svg"}
          onChange={(e) => {
            setIsPromoCodeInvalid(false);
            setPromoCode(e.target.value);
          }}
          errorMessage={isPromoCodeInvalid && promoCode ? promocodeError || 'Invalid Gift Card' : ''}
        />
        <div className="submit-button-container">
          <Button
            type={'mobile_save'}
            disabled={!promoCode || isPromoCodeInvalid}
            title="Submit"
            onClick={() => handlePromocodeSubmit(promoCode)}
          />
        </div>
      </PromoCodeStyle>
      <AnimatePresence>
        {isOpenSuccessMsg && (
          <motion.div
            initial={{ x: '100vw', opacity: 0 }}
            exit={{ x: '100vw', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            style={{ position: 'fixed', inset: 0, zIndex: 113 }}
          >
            <Success setIsOpenSuccessMsg={setIsOpenSuccessMsg} setIsPromoCodeOpen={setIsPromoCodeOpen} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PromoCode;
