import styled from 'styled-components';
import { ReactComponent as UserIcon } from '../../../../assets/user.svg';
import { ReactComponent as SmsIcon } from '../../../../assets/sms.svg';
import { ReactComponent as CallIcon } from '../../../../assets/call.svg';
import { ReactComponent as AddressIcon } from '../../../../assets/address.svg';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { useState, useEffect, useRef, useMemo } from 'react';
import autoAnimate from '@formkit/auto-animate';
import { ImBookmark } from 'react-icons/im';
import { useAppContext } from 'context/appContext';
import CustomTooltip from './CustomTooltip';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { SubcriptionOptions } from '../../../../../src/constants';

const ProviderCardStyle = styled.div`
    padding: 24px 12px 28px;
    margin-bottom: 21px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    @media(min-width:992px){
        border-radius: 6px;
    }
    background-color: #fff;
    position: relative;
    .provider_num{
        position: absolute;
        top: 0px;
        right: 20px;
        .icon_counter{
            display: flex;
            justify-content: center;
            .icon{
            color: #E0E3E0;
            cursor: pointer;
                &.active{
                    color: #52C41A;
                }
            }
        }
        .number_couter{
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.05em;
            color: #51635D;
            margin-top: 5px;
            text-align: center;
        }
    }
    .card_header{
        display: flex;
        gap: 24px;
        margin-bottom: 27px;
        .provider_image{
            width: 114px;
            padding-left: 14px;
            img{
                width: 100%;
            }
        }
        .provider_details{   
            flex: 1;
            .title{
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #51635D;
                margin-bottom: 20px;
            }
            .name-img{
                display:flex;
                column-gap:7px;
                img{
                    margin-top: -22px;
                    cursor:pointer;
                }

            }
            .provider_details_wrapper{
                display: flex;
                row-gap: 10px;
                flex-wrap: wrap;
                .details{
                    display: flex;
                    width: 50%;
                    gap: 7px;
                    .text{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #51635D;
                        .bold{
                            font-weight: 700;
                            word-break:break-all;
                        }
                    }
                    @media(min-width:992px){
                     .email, .address{
                        color:#949D9B;
                     }
                    }
                }
            }
        }
    }
    .card_body{
        .coach_details, .customer_details{
            border-collapse: collapse;
            border-spacing: 0;
            table-layout: fixed ;
            width: 100%;
        }
        .coach_details{
            margin-top: 24px;
        }
        td{
            border: 1px solid #E0E3E0;
            width: 33.33% !important;
        }
        .box{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
            padding: 11px 14px;
            display: flex;
            gap: 5px;
            white-space: nowrap;
            span{
                color: #51635D;
                font-weight: 700;
                display: block;
                white-space: normal;
                word-break:break-all;
            }
            &.address{
                flex-wrap: wrap;
            }
            &.payout{
                background: #F6F5F8;
                position: relative;
                &.icon{
                    position: absolute;
                }
            }
        }
    }
    .chevron_down{
        position: absolute;
        bottom: -14px;
        background: #FFFFFF;
        border: 1px solid #CBEDBA;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        font-size: 16px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
        .icon{
            color: #51635D;
            &.reverse{
                transform: rotate(-180deg);
            }
        }
    }
    @media (min-width: 2300px){
        padding: 24px 48px;
        .card_header{
            .provider_details{
                .provider_details_wrapper{
                    .details{
                        width: 25%;
                    }
                }
            }
        }
    }
    @media (max-width: 1500px){
        .card_header{
            gap: 14px;
        }
        .card_body{
            td{
                width: 50% !important;
            }
        }
    }
    @media (max-width: 1280px){
        .card_body{
            .box{
                padding: 8px 14px;
            }
        }
    }
    @media(min-width:992px) and (max-width:1280px){
        .provider_image{
            img{
                width:90px !important;
                height:90px;
            }
        }
    }
    @media (max-width: 991px){
        padding: 44px 24px 24px;
        margin-bottom: 0;
        .provider_num{
            right: auto;
            left: 24px;
        }
        .card_header{
            flex-direction: row-reverse;
            gap: 0;
            margin-bottom: 15px;
            padding-bottom: 24px;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                bottom: 0;
                left: -24px;
                width: 99vw;
                height: 1px;
                background: #E0E3E0;
            }
            .provider_image{
                padding-left: 0;
                width: 50px;
                img{
                    width:48px !important;
                    height:48px;
                }
            }

            .provider_details{
                .provider_details_wrapper{
                    row-gap: 8px;
                    .details{
                        width: 100%;
                    }
                }
            }
        }
        .card_body{
            td{
                display: block;
                border: 0px;
                width: 100% !important;
            }
            .box{
                padding: 7px 0px;
                &.address{
                    flex-wrap: unset;
                }
                &.payout{
                    background: #fff;
                }
            }
            .coach_details{
                margin-top: 0;
            }
        }
    }
`

export default function ProviderCard({size, coachData, userData, orderData, order, providerOrder, activeOrderId, slider}) {
    const [isCoachDetailsActive, setIsCoachDetailsActive] = useState(false);
    const {isMobile} = useAppContext();
    const history = useHistory();
    const handleClick = () => {
        setIsCoachDetailsActive(!isCoachDetailsActive);
    }
    const parent = useRef(null);
    const { name: coachName } = coachData || {};
    const { name: userName } = userData || {};
    const { 
        address, 
        phoneNumber:providerNumber, 
        orderedAt, 
        image, 
        scheduledDate, 
        scheduledTime, 
        promoCode, 
        subTotal, 
        providerPayout, 
        coachCommission, 
        coachProfitPercentage, 
        creditAmount, 
        taxAmount, 
        serviceFee, 
        deliveryAddress, 
        deliveryCity, 
        deliveryState, 
        email:providerEmail, 
        zip, 
        deliveryFee, 
        name:providerName, 
        userMarkup, 
        userMarkupCommission,
        taxRate,
        deliveryZip, 
        isDelivery, 
        city,
        state,
        providerId,
        dateTimeOrdered ,
        orderPhoneNumber,
        intervaldays, 
        subscriptionDiscountPercent,
        repeatOrderDiscount
    } = order || {};

    const providerAddress = `${address}${address ? ', ' + city : ''}${state ? ', ' + state : ''}${zip ? ', ' + zip : ''}`;

    let providerNum;
    if(activeOrderId.includes('(')){
        let newIdIndex = activeOrderId.indexOf("(");
        let endIndex = activeOrderId.indexOf(")");
        providerNum = Number(activeOrderId.slice(newIdIndex + 1, endIndex));
    }

    useEffect(() => {
		parent.current && !isMobile && autoAnimate(parent.current);
        isMobile && setIsCoachDetailsActive(true);
	}, [parent])
    
    const time = dateTimeOrdered.split("+")[0];
    const Time = new Date(time);
    const formattedTime = moment(Time).format('h:00 a');

    const displayDateTime = (date) => {
        const dateSplit = date?.split("T");
        const toBeCompletedDate = dateSplit && dateSplit[0];
        return moment(toBeCompletedDate).format('M/D/YYYY')
    };

    const displayAddressInfo = (info) => {
        if (info) {
            return info
        } else {
            return ''
        }
    }

    const userAddress = useMemo(() => {
        if (isDelivery) {
            if(deliveryAddress){
                return `${displayAddressInfo(deliveryAddress)}`;
            }
           return `${displayAddressInfo(deliveryCity)}, ${displayAddressInfo(deliveryState)} ${displayAddressInfo(deliveryZip)}`;
        } else {
            return `${displayAddressInfo(providerAddress)}`;
        }
    }, [isDelivery, deliveryAddress, deliveryCity, deliveryState, deliveryZip, order, city]);     
   
    const SubscriptionInfo = useMemo(() => {
        const foundSubscriptionInfo = SubcriptionOptions?.find((subscription) => subscription?.intervaldays === intervaldays)
        return foundSubscriptionInfo && intervaldays ? `${foundSubscriptionInfo?.label} ${subscriptionDiscountPercent}%` : 'N/A';
      }, [SubcriptionOptions, intervaldays, subscriptionDiscountPercent]);
    
    return (
        <ProviderCardStyle>
            {providerOrder?.length > 1 && 
            <div className='provider_num'>
                <div className='icon_counter'>
                    {providerOrder?.map((item, index) => {
                        return (
                            <ImBookmark onClick={() => {slider?.current?.slickGoTo(index)}} className={`icon ${index + 1 === providerNum && 'active'}`}/>
                        )
                    })}
                </div>
                <div className='number_couter'>
                    Order {providerNum}/{providerOrder?.length}
                </div>
            </div>
            }
            <div className='card_header'>
                <div className='provider_image'>
                    <img src={image[0] || "/images/avatar.png"} onError={(e) => {e.target.src = '/images/avatar.png'}} />
                </div>
                <div className='provider_details'>
                  <span className='name-img'>
                    <h2 className='title'>{providerName?.split('')?.[0]?.toUpperCase() + providerName?.split('')?.slice(1,providerName?.length)?.join("")}</h2>
                        <img src='/icons/export-green.svg' alt='export icon' onClick={() => {
                                const url = `/kitchen?providerId=${providerId}`;
                                window.open(url, "_blank");
                        }} />
                  </span>
                    <div className='provider_details_wrapper'>
                        <div className='details'>
                            <div className='icon'>
                                <UserIcon />
                            </div>
                            <div className='text'>
                            <span>  {!isMobile ? window.innerWidth > 1280 ? "Contact person:" : "" :"Contact person:"}</span> <span className='bold'>{providerName?.split('')?.[0]?.toUpperCase() + providerName?.split('')?.slice(1,providerName?.length)?.join("")}</span>
                            </div>
                        </div>
                        <div className='details'>
                            <div className='icon'>
                                <SmsIcon />
                            </div>
                            <div className='text'>
                            <span className='email'>  {!isMobile ? window.innerWidth > 1280 ? "Email:" : "" :""} </span> <span className='bold'>{providerEmail}</span>
                            </div>
                        </div>
                        <div className='details'>
                            <div className='icon'>
                                <CallIcon />
                            </div>
                            <div className='text'>
                            <span> {!isMobile ? window.innerWidth > 1280 ? "Phone:" : "" :""} </span> <span className='bold'>{providerNumber}</span>
                            </div>
                        </div>
                        <div className='details'>
                            <div className='icon'>
                                <AddressIcon />
                            </div>
                            <div className='text'>
                            <span className='address'> {!isMobile ? window.innerWidth > 1280 ? "Address:" : "" :"Address:"}</span> <span className='bold'>{providerAddress}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card_body' ref={parent}>
                <table cellPadding={0} className='customer_details'>
                    <tr>
                        <td><div className='box'>{isMobile ? "Customer name" : "Customer"}: <span>{userName}</span> </div></td>
                        {(size <= 1280 && size > 991) &&
                            <td><div className='box'> Subtotal: <span>${subTotal}</span> </div></td>
                        }
                        {size > 1280 &&
                        <td><div className='box'> Scheduled for: <span style={{textTransform: 'lowercase'}}>{`${moment(scheduledDate).format('M/D/YYYY')},`} {scheduledTime.split(' ').join('')}</span> </div></td>
                        }
                        {size >= 2300 && <td><div className='box'> Order at: <span>{displayDateTime(orderedAt)} {formattedTime}</span> </div></td>}
                        {size > 1500 &&
                        <td rowSpan={3} style={{ verticalAlign: 'initial' }}><div className='box address'> {isDelivery ? "Delivery:" : "Pickup:"} <span>{userAddress}</span></div></td>
                        }
                    </tr>
                    <tr>
                        <td><div className='box'> {isMobile ? "Phone number" : "Phone"}: <span>{orderPhoneNumber}</span> </div></td>
                        {(size > 1280 && size < 2300) &&
                        <td><div className='box'> Order at: <span>{displayDateTime(orderedAt)} {formattedTime}</span> </div></td>
                        }
                        {(size <= 1280 && size > 991) &&
                        <td rowSpan={4} style={{ verticalAlign: 'initial' }}><div className='box address'> Delivery: <span>{userAddress}</span></div></td>
                        }
                        {size >= 2300 && <>
                            <td><div className='box'> Method: <span>{isDelivery ? "Delivery" : "Pickup"}</span> </div></td>
                            <td><div className='box'> Subtotal: <span>${subTotal}</span> </div></td>
                            </>
                        }
                    </tr>
                    {size < 2300 &&
                    <tr>
                        <td><div className='box'> Method: <span>{isDelivery ? "Delivery" : "Pickup"}</span> </div></td>
                        {(size <= 1500 && size > 1280) &&
                        <td rowSpan={2} style={{ verticalAlign: 'initial' }}><div className='box address'>{isDelivery ? "Delivery" : "Pickup"}: <span> {userAddress} </span></div></td>
                        }
                        {size > 1500 &&
                        <td><div className='box'> Subtotal: <span>${subTotal}</span> </div></td>
                        }
                    </tr>
                    }
                    {(size <= 1500 && size > 1280) &&
                    <tr><td><div className='box'> Subtotal: <span>${subTotal}</span> </div></td></tr>
                    }
                    {size <= 1280 && <>
                    <tr><td><div className='box'> Scheduled for: <span style={{textTransform: 'lowercase'}}>{`${moment(scheduledDate).format('M/D/YYYY')},`} {scheduledTime.split(' ').join('')}</span> </div></td></tr>
                    <tr><td><div className='box'> Order at: <span>{displayDateTime(orderedAt)} {formattedTime}</span> </div></td></tr>
                    </>
                    }
                    {isMobile &&
                    <tr><td><div className='box'> Subtotal: <span>${subTotal}</span> </div></td></tr>
                    }
                    {isMobile &&
                        <td rowSpan={4} style={{ verticalAlign: 'initial' }}><div className='box address'> Deliver to: <span>{userAddress}</span></div></td>
                    }
                </table>
                {isCoachDetailsActive &&
                <table cellPadding={0} className="coach_details">
                    <tr>
                        <td><div className='box'> Coach: <span>{coachName}</span> </div></td>
                       {!isMobile && <td style={{minWidth: 270, width: 270}}><div className='box'> Coach commission: <span>{coachProfitPercentage}% (${coachCommission})</span> </div></td>}
                        {size > 1500 &&
                        <td><div className='box'> User markup: <span> {(userMarkup * 100).toFixed(2)}% (${userMarkupCommission}) </span></div></td>
                        }
                        {size >= 2300 && <><td><div className='box'> Service Fee: <span>${serviceFee}</span> </div></td>
                        <td><div className='box'> Tax percentage: <span>{(taxRate*100).toFixed(2)}% (zip: {zip})</span> </div></td></>}
                    </tr>
                    <tr>
                        <td><div className='box'> Promocode: <span>{promoCode || 'N/A'}</span> </div></td>
                       {!isMobile && <td><div className='box'> Credit amount: <span>${creditAmount}</span> </div></td>}
                        {size > 1500 &&
                        <td><div className='box'> Delivery fee: <span>${deliveryFee}</span> </div></td>
                        }
                        {size >= 2300 && <> <td><div className='box'> Subscription: <span>{SubscriptionInfo}</span> </div></td> <td><div className='box'> Credit amount: <span style={{position: 'relative'}}>${repeatOrderDiscount}</span></div></td></>}
                    </tr>
                    {size > 2300 && <>
                        <tr>
                        <td><div className='box'> Tax amount: <span>${taxAmount}</span> </div></td>
                        <td><div className='box payout'> Provider payout: <span style={{position: 'relative'}}>${providerPayout} <CustomTooltip placement={'bottomLeft'} tooltipText={`The amount we have to pay to the provider, which includes delivery fees and taxes`} showArrow={false}/></span></div></td>
                    </tr>
                    </>
                    }
                    {size < 2300 && <>
                    <tr>
                        <td><div className='box'> Service Fee: <span>${serviceFee}</span> </div></td>
                       {isMobile && <td style={{minWidth: 270, width: 270}}><div className='box'> Coach commission: <span>{coachProfitPercentage}% (${coachCommission})</span> </div></td>}
                       {isMobile && <td><div className='box'> Credit amount: <span>${creditAmount}</span> </div></td>}
                        <td><div className='box'> Tax percentage: <span>{(taxRate*100).toFixed(2)}% (zip: {zip})</span> </div></td>
                        {size > 1500 &&
                        <td><div className='box'> Tax amount: <span>${taxAmount}</span> </div></td>
                        }
                    </tr>
                    {size <= 1500 &&
                    <tr>
                        <td><div className='box'> User markup: <span> {(userMarkup * 100).toFixed(2)}% (${userMarkupCommission}) </span></div></td>
                        <td><div className='box'> Delivery fee: <span>${deliveryFee}</span> </div></td>
                    </tr>
                    }
                    <tr>
                        <td><div className='box'> Subscription: <span>{SubscriptionInfo}</span> </div></td>
                        <td><div className='box'> Credit amount: <span>${repeatOrderDiscount}</span> </div></td>
                    </tr>
                    <tr>
                        {size > 1500 &&
                        <td style={{ border: 0, }}></td>
                        }
                        {size <= 1500 &&
                        <td><div className='box'> Tax amount: <span>${taxAmount}</span> </div></td>
                        }
                        <td><div className='box payout'> Provider payout: <span style={{position: 'relative'}}>${providerPayout} <CustomTooltip placement={'bottomLeft'} tooltipText={`The amount we have to pay to the provider, which includes delivery fees and taxes.`} showArrow={false}/></span></div></td>
                        {size > 1500 &&
                        <td style={{ border: 0 }}></td>
                        }
                    </tr>
                    </>
                    }
                </table>
                }
            </div>
            {!isMobile && 
            <div className='chevron_down' onClick={handleClick}>
                <HiOutlineChevronDown className={`icon ${isCoachDetailsActive ? 'reverse' : ''}`} />
            </div>
            }
        </ProviderCardStyle>
    )
}