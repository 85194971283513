import { useState, useEffect } from 'react';
import Tags from 'components/shop/MealTags';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import { getEarliestTime, toDateString, getNextDeliveryAndPickup } from 'pages/shop/providerTimes';
import { useMemo } from 'react';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LocationIcon from './../../../assets/Location.svg'
import { useAppContext } from 'context/appContext';
import MealMultiTags from 'components/shop/MealMultiTags';
import ImageViewer from '../../../components/ImageViewer';
import { useVisitorContext } from 'context/visitorContext';


const Container = styled.div`
  cursor: pointer;
  border: 1px solid #e8ebe8;
  background: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  /* margin-bottom: 26px; */
 
  @media (min-width:992px){
    .card-desktop-box{
      display: grid;
      padding-left:2%;
      grid-template-columns: 24% 69.9%;
      grid-column-gap:6.1%;
    }
    .picup-wrapper{
      width: 100%;
    display: flex;
      justify-content: center;
    }
    .wrapping-image{
      display: flex;
      align-items: center;

      img{
        border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 200px;
      }
    }
    .provider-card-info {
      padding:12px 0px 0 !important;
    }
    .map-pin-city {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      color: #51635D;
    }

    h3 {
      margin: 24px 0px 6px 0px;
      color: #0B1D17;
      font-size: 24px;
      font-weight: 700;
    }

    .min-order {
      width: 127px;
      height: 32px;
      margin-top: 22px;
      color: #949D9B;
      font-size: 11px;
      background: #F0FAEB;
      display: flex;
      justify-content: center;
      align-items:center;
      line-height: 16px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .deliveryinfo-sec {
      display: flex;
      justify-content: flex-start;
      align-items:center;
      height: 60px;
      margin-left: 92px;
      margin-right: 158px;
      margin: 0px;
      .map-pin {
        margin-top: 0;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .provider-date-divider {
        height: 32px;
        margin: 0 10px;
        border-left: 1px solid #E0E3E0;
      }
      .date-time-wrapper {
        margin-left: 10px;
      }
   .deliverLeft {
            width: 100%;
            display: flex;
            justify-content:space-between;
          }

          .pick-up {
              width: 100%;
              display: flex;
              justify-content: center;

              p {
                  color: #51635D;
                  font-size: 16px;
                  line-height: 19px;
                  display: flex;
                  span {
                      white-space: nowrap;
                      font-style: normal;
                      font-family: 'Roboto';
                      color: #51635D;
                      font-weight: 700 !important;
                  }

                  .time-separator {
                    margin: 2px 5px 0px;
                    border-left: 1px solid #E0E3E0;
                    height: 14px;
                  }
                  .date-time-wrapper{
                    white-space: normal;
                  }
           }
          }
      }

  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    // object-fit: cover;
    // object-fit: contain;
  }
  .provider-card-info {
    padding: 12px 12px 15px;
  }

  .city-wrapper {
    display: flex;
    font-size: 12px;
    justify-content: space-around;
  }
  .map-pin-city {
    display: flex;
    align-items: center;
    color: #51635D;
  }
  
  .map-pin-city span {
    font-size: 12px;
  }
  .map-pin {
    margin-right: 5px;
    margin-top: 3px;
    svg {
      font-size: 15px;
    }
  }
  .pick-delivery {
    position: relative;
    padding-left: 14px;
  }
  .pick-delivery:before {
    content: '';
    position: absolute;
    top: 25%;
    left: 0;
    width: 6px;
    height: 6px;
    background: var(--lightGray);
    border-radius: 50%;
  }
  .provider-min-order{
    display:flex;
    justify-content:space-between;
  }
 
  .pick-delivery-wraper {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      transform: rotate(180deg);
      color: #0b1c18;
    }
  }
  .meal-tooltip-icon {
    color: var(--nutritt-green);
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }
  .meal-tooltip-icon-png {
    width: 20px;
    height: 20px;
  }

  .pick-delivery-wraper .MuiTooltip-popper {
    width: 275px;

    .MuiTooltip-tooltipPlacementTop {
      margin: -60px 0;
    }
  }

   
`;

const InfoIcon = styled.span`
  margin-left: 12px;
  display: flex;

  img {
    width: 20px;
    height: 20px;
  }
`;

export default function VisitorProviderCard(provider) {
  const {
    id,
    name,
    images,
    tags,
    city,
    minOrderAmount,
    schedule,
    address,
    deliveryCoverage = [],
  } = provider;
  const {
    updateSettings,
    settings: { providerSettings, userInfo: { zipcode } = {} },
    noNavigation
  } = useVisitorContext();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();
  const earliestDeliveryTime = getEarliestTime(schedule, 'delivery');
  const earliestPickupTime = getEarliestTime(schedule, 'pickup');
  let pickupAndDelivery = useMemo(() => {
    if (earliestDeliveryTime && earliestPickupTime) {
      return 'Both';
    } else if (earliestPickupTime) {
      return 'Pickup';
    } else if (earliestDeliveryTime) {
      return 'delivery';
    } else {
      return '';
    }
  }, [earliestDeliveryTime, earliestPickupTime]);

  const handleUpdateProvider = () => {
    updateSettings(
      produce((draft) => {
        draft.provider = provider;
      }),
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
      setOpenModal(false);
    }, 5000);
  }, [open, openModal]);

  const isUserInsideDeliveryCoverage = useMemo(() => {
    return deliveryCoverage?.includes(zipcode);
  }, [zipcode]);

  // const deliveryText = useMemo(() => {
  //   return pickupAndDelivery === 'Both' && isUserInsideDeliveryCoverage
  //     ? ' Pickup & Delivery '
  //     : pickupAndDelivery === 'Pickup'
  //     ? ' Pickup '
  //     : pickupAndDelivery === 'delivery' && isUserInsideDeliveryCoverage
  //     ? 'Delivery'
  //     : isUserInsideDeliveryCoverage
  //     ? 'Shop Time is over for today'
  //     : 'Pickup';
  // }, [isUserInsideDeliveryCoverage, pickupAndDelivery]);

  const deliveryText = useMemo(() => {
    return pickupAndDelivery === 'Both'
      ? ' Pickup & Delivery '
      : pickupAndDelivery === 'Pickup'
        ? ' Pickup '
        : pickupAndDelivery === 'delivery'
          ? 'Delivery'
          : 'Shop Time is over for today';
  }, [isUserInsideDeliveryCoverage, pickupAndDelivery]);

  const ds = String(earliestDeliveryTime);
  const date = moment(new Date(ds.substr(0, 16))).format('MM-DD-YY');
  const today = moment(new Date()).format('MM-DD-YY');
  const displayTruck = date == today;

  const shopMeals = () => {
    handleUpdateProvider();
    !noNavigation ? history.push(`/visitor/meals`) : history.push(`/visitor/meals?navigation=false`);
    window.scrollTo(0, 0);
  };
  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const { isMobile } = useAppContext();

  function convertToHttps(imageUrl) {
    if (typeof imageUrl === 'string' && imageUrl.trim().length > 0 && imageUrl.indexOf("http://") === 0) {
      return "https://" + imageUrl.slice(7);
    }
    return imageUrl;
  }

  const deliveryTime=getNextDeliveryAndPickup(schedule,"delivery")
  const pickupTime=getNextDeliveryAndPickup(schedule,"pickup")

  const imageUrl = images[0];
  const secureImageUrl = convertToHttps(imageUrl);

  return pickupAndDelivery ? (
    <Container className='card-box' onClick={shopMeals}>
      <div className="card-inner-box card-desktop-box">
        <div className='wrapper-image wrapping-image'>
          <ImageViewer src={secureImageUrl || "/images/avatar.png"} fallbackSrc={"/images/avatar.png"} />
        </div>
        <div className="provider-card-info provider-desktop-info">
          {!isMobile ? <>
            <div className='provider-min-order'>
              <h3>{name}</h3>
              <div className="min-order">{`min. order: $${minOrderAmount || 0}`}</div>
            </div>

            <div className="map-pin-city">
              <div className="map-pin">{city && <img src='/images/Location.svg' />}</div>
              {city}
            </div>

            <div>
              <Tags tags={tags} />
            </div>


            <div className='deliveryinfo-sec'>

              {earliestDeliveryTime && (
                <div className="deliverLeft">
                  <div className="pick-up">
                    <div className="map-pin">
                      <img src="/icons/delivery-icon.svg" alt="Icon" className="card-icons" />
                    </div>
                    <p><span style={{whiteSpace: 'nowrap'}}>Delivery by:</span> <span className='date-time-wrapper'>{toDateString(earliestDeliveryTime)}<span className="time-separator" />{deliveryTime} </span></p>
                  </div>
                </div>
              )}
              {earliestDeliveryTime && earliestPickupTime && (
                <span className='provider-date-divider' />
              )}
              {earliestPickupTime && (
                <div className='picup-wrapper'>
                  <div className="city-wrapper">
                    <div className="pick-up">
                      <div className="map-pin">
                        <img src="/icons/pickup-icon.svg" alt="Icon" className="card-icons" />
                      </div>
                      <p><span style={{whiteSpace: 'nowrap'}}>Pick-up by:</span> <span className='date-time-wrapper'>{toDateString(earliestPickupTime)}<span className="time-separator" />{pickupTime} </span></p>
                    </div>
                  </div>
                </div>
              )}

            </div>


          </> :
            <>
              <div className='provider-card-top'>
                <div className='provider-card-head'>
                  <h3>{name}</h3>
                  <div className="map-pin-city">
                    <div className="map-pin">{city && <img src={LocationIcon} alt="Icon" className="card-icons" />}</div>
                    {city}
                  </div>
                </div>
                <div className='tag-min-order-sec'>
                  {
                    tags.length > 6 ?
                      <MealMultiTags tags={tags} providerName={name} />
                      :
                      <Tags tags={tags} />
                  }

                  <span className="min-order">{`min. order: $${minOrderAmount || 0}`}</span>
                </div>
              </div>
              <div className='deliveryinfo-sec'>
                {earliestDeliveryTime && (
                  <div className="city-wrapper deliverLeft">
                    <div className="pick-up">
                      <div className="map-pin">
                        <img src="/icons/delivery-icon.svg" alt="Icon" className="card-icons" />
                      </div>
                      <p>Delivery by: <span>{toDateString(earliestDeliveryTime)} <sub className="time-separator" /> {deliveryTime} </span></p>
                    </div>
                  </div>
                )}
                {earliestPickupTime && (
                  <div className="city-wrapper">
                    <div className="pick-up">
                      <div className="map-pin">
                        <img src="/icons/pickup-icon.svg" alt="Icon" className="card-icons" />
                      </div>
                      <p>Pick-up by: <span>{toDateString(earliestPickupTime)} <sub className="time-separator" /> {pickupTime} </span></p>
                    </div>
                  </div>
                )}
              </div>
            </>}
        </div>
      </div>
      <div>
        <Dialog
          open={openModal}
          onBackdropClick={handleCloseDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {' Please try another of our fabulous providers'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              “Ooops, this provider does not deliver to your area and does not offer pickup. Please
              try another of our fabulous providers.”
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </Container>
  ) : (
    ''
  );
}
