import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from "swiper";

export default function FractionalSlider({
  fractionalConstants, 
  setFractionSliderIndex,
  setActiveFractionalIndex,
  fractionalRef,
  servings,
  toFraction,
  servingSliderIndex,
  items,
  activeNumberQtyIndex,
  activeFractionalIndex,
  serving_description,
}) {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
 
  const getActiveUnitIndex = () => {
    const activeUnit = Array.isArray(servings)
      ? servings[servingSliderIndex]?.measureIndex?servings[servingSliderIndex]?.measureIndex:servings[servingSliderIndex]?.number_of_units
      : servings?.measureIndex?servings?.measureIndex:servings?.number_of_units;
    const numIndex = items.findIndex((item) => Number(item) === Number(activeUnit));
    const index = fractionalConstants.findIndex((item) => toFraction(item) === toFraction(activeUnit));
    return { numIndex, index };
  };

    useEffect(() => {
        if (fractionalRef.current) {
      const { index } = getActiveUnitIndex();
      fractionalRef.current.slideTo(index >=0 ? index : 0);
      }
      }, [fractionalRef, servingSliderIndex]);

      useEffect(()=>{
        if(fractionalRef.current){
         const { numIndex,index } = getActiveUnitIndex();
          if (activeFractionalIndex === 0 && numIndex === -1 && index === 2) {
          fractionalRef.current.slideTo(index);
          }
         if(servings?.[0]?.measureIndex){
          fractionalRef.current.slideTo(servings?.[0]?.measureIndex);
        } 
        }
       },[activeNumberQtyIndex])
    
    const handleSlideChange = (swiper) => {
      const { numIndex, index } = getActiveUnitIndex();
      const activeIndex = swiper.realIndex;
      let slideToIndex = activeIndex;

      if (serving_description === "100 g") {
        slideToIndex = 0;
      } else if (activeIndex === 0 && activeNumberQtyIndex === 0 && numIndex < 0) {
        slideToIndex = 1;
      }
       swiper.slideTo(slideToIndex);
      setActiveFractionalIndex(slideToIndex);
      setFractionSliderIndex(slideToIndex);
    }
    return (
        <>
            <Swiper
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={5}
              centeredSlides={true}
              className="mySwiper"
              onInit={(swiper) => fractionalRef.current = swiper}
              onSlideChange={handleSlideChange}
              onSwiper={setImagesNavSlider}
            >
            {fractionalConstants.map((item, index) => (
                    <SwiperSlide>
                    <span
                        key={index}
                    >
                        {item == '--' ? item : toFraction(item)}
                    </span>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper thumbs={{ swiper: imagesNavSlider }} modules={[Thumbs]} />
        </>
    )
}
