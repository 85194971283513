import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  max-width: 1584px;
  margin: 0 auto;
  height: 210px;

  .header-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .header-icons {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .favorite-share {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .icon-wrapper {
    width: 36px;
    height: 36px;
    background: ${() => theme.colors.secondary};
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .favorite-img,
    .share-img {
      width: 24px;
      height: 24px;
    }
  }
`;