import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { isEmailValid } from 'components/auth/utils';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import PageTransition from './components/PageTransition';
import RegularInput from 'components/Input/RegularInput';
import MobileHeader from 'components/elements/MobileHeader';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ArrowRight } from 'assets/shop/chevron-right.svg';
import { ReactComponent as SuccessImage } from 'assets/shop/success-image.svg';
import { GilroyFontWrapper } from 'shared/theme/style';
import OTPInput from "./components/OTPInput";
import { useOnboardingContext } from './index';
import Alert from 'components/elements/alert/Alert';
import MessageModal from 'components/elements/modal/MessageModal';
import { setFavoriteKitchen } from 'utils/setFavoriteKitchen';


const PhoneVerficationStyle = styled.div`
    padding-top: 12px;
    background-color: #FFFFFF;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    height: 100%;
    .alert {
      position: absolute;
      top: 16px;
      left: auto;
      width: 100%;
      z-index:100;
      .alert_bar {
        margin: auto;
      }
    }
    .form_body{
        padding: 0 18px;
        height: calc(100vh - 60px);
        .code-error{
          color: #EB3223;
          font-family: Gilroy;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom:16px;
        }
        .resend-code{
          color: #51635D;
          font-family: Gilroy;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: underline !important;
        }
    }
    .signIn{
        margin-bottom: 32px;
        .title{
            display:flex;
            flex-direction:column;
            padding-bottom:24px;
            padding-top:12px;
            gap:12px;
              .head-title{
                color: #000A2C;
                font-family: Gilroy;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                
              }
              .desc{
                color: #757E89;
                width: 305px;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              }
        }
    }
    .otpContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.otp-input-error {
        :focus {
          border-color: #EB3223;
          color: #EB3223;
          background-color: #fff;
          }
        input[type="tel"] {
          border: 1px solid #EB3223;
          color: #EB3223;
        }
      }
      &.otp-input-success{
        input[type="tel"] {
          background-color: #F0F2F4;
        }
      }
      
      input[type="tel"] {
        width: 48px;
        height: 66px;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid #DFE2E8;
        outline: none;
        color: #9CA3AD;
        text-align: center;
        font-family: Gilroy;
        font-size: 32px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
      :hover {
        border-color: #c2c9d1 !important;
      }  
      :focus{
        border-color: #000A2C !important;
      }
      transition: border-color 0.2s, color 0.2s; /* Equivalent to transition */
    }
  }
      .button{
        display: flex;
        align-items: center;
        justify-content: right;
        padding: 0px 18px 30px 18px;
        button{
          :disabled {
            background-color: #F0F2F4 !Important;
            color: #9CA3AD !Important;
        }
        }
        .btn{
            height: 42px;
            width:138px;  
            border-radius: 20px;  
        }
      }
`
const FormGroupStyle = styled.div`
  margin-bottom: 8px;
  width: 100%;
  label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom:9px;
    display: inline-block;
  }
  input {
    height: 44px;
    border-radius: 6px;
    border: 1px solid var(--Light-Grey, #E0E3E0);
    :hover, :focus {
      border-color: #00092D !important;
    }
    :focus::placeholder {
        color: transparent;
      }
      ::placeholder{
        color: #949D9B;
        font-style: normal;
        font-weight: 500;
      }

  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  .flex{
    display:flex;
    gap:13px;
  }
`;

const SuccessPageStyle = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: white;
display: flex;
flex-direction: column;
margin: 0 31px;
justify-content: center;
align-items: center;
gap:32px;
  .heading-text{
    color: var(--new-000-a-2-c, #000A2C);
    text-align: center;
    font-family: Gilroy;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .description{
    color: var(--new-00092-d, #00092D);
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const ActiveModalWrapper = styled.div`
position: absolute;
inset: 0;
z-index: 500;
background-color: #0B1D1760;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
.modal{
  width: 343px;
  padding: 0;
  height: 477px;
}
`;

export default function PhoneVerfication() {
    const history = useHistory();
    const {setProfile,user,setUser, profile, isWebView, TimeZone, allowNotification } = useAppContext();
    const [phoneNumber, setPhoneNumber] = useState({ value: profile?.phoneNumber ?? "", error: '' });
    const [codeGenerate, setCodeGenerate] = useState(false);
    const [otp, setOtp] = useState('');
    const inputRef = useRef(null);
    const [activeOTPIndex, setActiveOTPIndex] = useState(0);
    const [codeVerified, setCodeVerified] = useState(false);
    const {userDetails, setOnboardingUser} = useOnboardingContext();
    const [verifyPhoneNumber, {error: phoneVerificationError, setError: setPhoneVerificationError, isLoading: isSendOtpApiCalling}] = useRequest(`auth/v2/send-otp`, 'post');
    const [verifyOTP , {error, isLoading, setError }] = useRequest();
    const [registerUser] = useRequest('visitor/register', 'post');
  const [alertData, setAlertData] = useState({ type: '', title: '' })
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
  if (otp.length === 6) {
    OTPVerfication();
  }
  setError('');
  },[otp])

  useEffect(()=>{
    allowNotification && history.push("/self-onboarding/address")
  },[allowNotification])
  
    const phoneNumberVerfication = useCallback(({isResend=false}) => {
      verifyPhoneNumber({ body: { phoneNumber: phoneNumber?.value?.replace(/[^\d]/g, '').slice(-10) } })
      .then((result) => {
        setProfile((prev) => ({ ...prev, phoneNumber: phoneNumber?.value?.replace(/[^\d]/g, '').slice(-10) }));
        if(isResend){
          showSuccessMessage(result?.message)
        }
        setCodeGenerate(true); 
      })
      .catch((e) => {
        if (e === "Phone number is already verified. No further action is needed.") {
          history?.push('/self-onboarding/address')
        }
        console.log(e, 'error')
      })
    },[phoneNumber])

  const OTPVerfication = useCallback(() => {
    const userId = user?.id;
    let path = 'auth/v2/verify-phoneNumber'
    if (userId) {
      path += `?userId=${user.id}`
    }
    verifyOTP({ body: { phoneNumber: phoneNumber?.value?.replace(/[^\d]/g, '').slice(-10), otpCode: otp }, method: "post", path })
      .then(async (result) => {
        setCodeVerified(true)
        if (userId) {
          setUser((prevState) => ({ ...prevState, isPhoneNumberVerified: true, phoneNumber: phoneNumber?.value?.replace(/[^\d]/g, '').slice(-10) }))
          return history?.push('/shop/kitchen-home');
        } else {
          const res = await handleFormSubmit();
          const kitchenUsername = sessionStorage.getItem('kitchenUsername');
              if(kitchenUsername){
                try{
                  const response = await setFavoriteKitchen(res?.token?.token, kitchenUsername, false);
                  if(response?.data?.success && response?.data){
                    setProfile((prev) => ({...prev, favoriteKitchenData: response?.data?.favoriteKitchenData}))
                  }
                }catch(err){
                  console.log({err})
                  sessionStorage.removeItem('kitchenUsername')
                }
          }
          setTimeout(async() => {
            if(window.ReactNativeWebView){
              window.ReactNativeWebView.postMessage(JSON.stringify({ event: "swipe-block" }))
              setShowModal(true)
            }else {
              history?.push('/self-onboarding/address')
            }
           
          }, 3000)
        }

      })
      .catch((err) => {
        console.log(err, 'err')
      })


      
  }, [phoneNumber, otp, user])

    const handleFormSubmit = async () => {
      try {
        const data = await registerUser({
          body: {
            email: userDetails.email,
            password: userDetails.password,
            name: userDetails.name,
            lastName: userDetails.lastName,
            selfMealPlanOnboarding: true,
            TimeZone,
            phoneNumber: phoneNumber?.value?.replace(/[^\d]/g, '').slice(-10)
          },
        });
        const {token, user} = data;
        setUser(user);
        localStorage.setItem('user', JSON.stringify(token));
        localStorage.setItem('orderCount', 0);
        localStorage.setItem('login-type', JSON.stringify('selfMealPlanOnboarding'));
        localStorage.setItem('selfOnboardingUser', JSON.stringify(data?.user));
  
        isWebView &&
          isWebView.postMessage(JSON.stringify({ event: 'onBoarding_user_data', data: data }));
        setOnboardingUser((prev) => ({
          ...prev,
          name: data?.user?.profile?.name,
        }));
        return data
      } catch (error) {
        toast.error(error);
      }
    };
          
    useEffect(() => {
        inputRef.current?.focus()
    },[activeOTPIndex])   

    const showSuccessMessage = (title) => {
      setAlertData({
        type: 'success',
        title: title,
      });
      setTimeout(() => {
        setAlertData({ type: '', title: '' });
      }, 3000);
    };
    const handleResendClick=(e)=>{
      e.preventDefault();
      const isResend=true
      phoneNumberVerfication({isResend})
    }

    const handleContinueClick = () => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'allow_notification' }));
        return;
      }
    }

    const handleCancelClick = () => {
      history?.push('/self-onboarding/address');
    }
    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber({ value: inputValue });
        setPhoneVerificationError('');
    };
    return (
      <>
        {!codeGenerate ? (
          <PageTransition isWebView>
            <GilroyFontWrapper>
            <PhoneVerficationStyle>
            <MobileHeader text={"Sign up"} onClickLeft={() => {history.push('/self-onboarding/signin')}} />
              <div style={{ flex: 1 }}>
                <div className='form_body'>
                  <div className='signIn'>
                    <div className='title'>
                      <h2 className='head-title'>Phone number</h2>
                      <p className='desc'>You will receive an SMS with a verification code shortly</p>
                    </div>
                    <FormGroupStyle>
                      <label>Mobile number</label>
                      <RegularInput
                        type="tel"
                        name="phoneNumber"
                        placeholder="Mobile Number"
                        onChange={handlePhoneNumberChange} 
                        value={phoneNumber.value}
                        autoFocus={true}
                        iconPrefix={'/icons/call.svg'}
                        errorMessage={phoneVerificationError}
                        maxLength={15}
                        autocomplete="tel"
                      />
                    </FormGroupStyle>
                  </div>
                </div>
              </div>
              <div className={`button`} style={{position: "fixed", right: 10, bottom: 29 }}>
                <Button type={'dark'} title="Continue"  onClick={phoneNumberVerfication} iconRight={<ArrowRight />} isDisabled={phoneNumber?.value?.length < 10 || isSendOtpApiCalling || phoneVerificationError}/>
              </div>
            </PhoneVerficationStyle>
            </GilroyFontWrapper>
          </PageTransition>
        ) : (
          (!codeVerified) ? (
              <GilroyFontWrapper>
              <PhoneVerficationStyle>
              {alertData.type && (
                    <div className="alert">
                      <Alert
                        type={alertData.type}
                        title={alertData.title}
                        width="80%"
                        onClose={() => setAlertData({ type: '', title: '' })}
                      />
                    </div>
                  )}
                <MobileHeader text={"Sign up"} onClickLeft={() => { setCodeGenerate(false), setOtp('') }} />
                <PageTransition isWebView>
                <div style={{ flex: 1 }}>
                  <div className='form_body'>
                    <div className='signIn'>
                      <div className='title'>
                        <h2 className='head-title'>We sent you a code</h2>
                        <p className='desc'>{`Enter the 6-digit code sent to you at ${phoneNumber?.value} `}</p>
                      </div>
                      <OTPInput
                      autoFocus
                      isNumberInput
                      length={6}
                      className={`otpContainer ${error ? 'otp-input-error' : ''} ${isLoading ? 'otp-input-success' : ""}`}
                      inputClassName="otpInput"
                      onChangeOTP={(otp) => setOtp(otp)}
                      setOtp={setOtp}
                       />

                      {/* </div> */}
                    </div>
                    {(error) && (
                      <p className="code-error">{error}</p>
                    )}
                    <a onClick={handleResendClick} className="resend-code">Resend code</a>
                  </div>
                </div>
                </PageTransition>
              </PhoneVerficationStyle>
              </GilroyFontWrapper>
          ) : (
            <PageTransition isWebView>
              <GilroyFontWrapper>
              <SuccessPageStyle>
                <text className="heading-text">All set, <br />ready to go!</text>
                <SuccessImage />
                <text className="description">Nutritious and delicious, from a local place you know!</text>
              </SuccessPageStyle>
              {showModal && 
              <ActiveModalWrapper>
              <MessageModal success={false} img = {'/images/Notifications.svg'} title = "Allow push notification" firstBtnTitle="Continue" secondBtnTitle="No, thanks" description = {`Enable push notifications to get the most <br /> out of our app. You'll never miss a thing!`} firstBtnClick={handleContinueClick} secondBtnClick={handleCancelClick} />
             </ActiveModalWrapper>
              }
              </GilroyFontWrapper>
            </PageTransition>
          )
        )}
      </>
    );
}