import React from 'react';

const Kosher = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_3899_35694)">
        <path
          d="M1.30047 17.888L2.49973 16.7856C2.69828 16.6031 2.71129 16.2943 2.52879 16.0957C2.34629 15.8971 2.03738 15.8842 1.83887 16.0666L0.976562 16.8593V16.3867C0.976562 16.1171 0.75793 15.8984 0.488281 15.8984C0.218633 15.8984 0 16.1171 0 16.3867V19.5117C0 19.7814 0.218633 20 0.488281 20C0.75793 20 0.976562 19.7814 0.976562 19.5117V18.9325L1.94121 19.8631C2.03598 19.9545 2.15812 20 2.2802 20C2.40805 20 2.53582 19.95 2.63164 19.8507C2.81887 19.6566 2.81332 19.3475 2.61926 19.1603L1.30047 17.888Z"
          fill={color || "#757E89"}
        />
        <path
          d="M13.386 15.8984C13.1163 15.8984 12.8977 16.1171 12.8977 16.3867V17.4073H11.7117V16.3867C11.7117 16.1171 11.4931 15.8984 11.2234 15.8984C10.9538 15.8984 10.7352 16.1171 10.7352 16.3867V19.5117C10.7352 19.7814 10.9538 20 11.2234 20C11.4931 20 11.7117 19.7814 11.7117 19.5117V18.3839H12.8977V19.5117C12.8977 19.7814 13.1163 20 13.386 20C13.6556 20 13.8743 19.7814 13.8743 19.5117V16.3867C13.8743 16.1171 13.6556 15.8984 13.386 15.8984Z"
          fill={color || "#757E89"}
        />
        <path
          d="M16.2834 16.875C16.553 16.875 16.7716 16.6564 16.7716 16.3867C16.7716 16.1171 16.553 15.8984 16.2834 15.8984H14.9666C14.697 15.8984 14.4783 16.1171 14.4783 16.3867V19.5117C14.4783 19.7814 14.697 20 14.9666 20H16.2834C16.553 20 16.7716 19.7814 16.7716 19.5117C16.7716 19.2421 16.553 19.0234 16.2834 19.0234H15.4549V18.4375H16.1863C16.4559 18.4375 16.6746 18.2189 16.6746 17.9492C16.6746 17.6795 16.4559 17.4609 16.1863 17.4609H15.4549V16.875H16.2834Z"
          fill={color || "#757E89"}
        />
        <path
          d="M9.18652 17.5157C8.81988 17.3805 8.47738 17.2336 8.35488 17.1802C8.32054 17.147 8.31758 17.1053 8.32203 17.0741C8.33316 16.9967 8.39879 16.9345 8.50211 16.9034C8.50492 16.9025 8.51574 16.899 8.51843 16.8981C8.52207 16.897 8.89554 16.7895 9.28214 17.0924C9.37152 17.1624 9.47761 17.1963 9.58292 17.1963C9.72785 17.1963 9.87132 17.1321 9.96765 17.0091C10.134 16.7968 10.0967 16.49 9.88441 16.3236C9.2371 15.8164 8.52363 15.8649 8.2041 15.9736C8.20246 15.9741 8.2009 15.9749 8.19925 15.9755C7.74242 16.1213 7.41973 16.4869 7.35535 16.9354C7.29367 17.365 7.48004 17.7784 7.84172 18.0143C7.86332 18.0284 7.88594 18.0407 7.90945 18.0512C7.92765 18.0593 8.36086 18.2522 8.84851 18.432C9.03171 18.4996 9.21918 18.6182 9.19593 18.7487C9.17617 18.8595 9.01953 19.0234 8.74621 19.0234C8.46269 19.0234 8.19086 18.9102 8.01902 18.7206C7.83793 18.5208 7.52914 18.5056 7.32934 18.6867C7.12949 18.8678 7.1143 19.1766 7.29539 19.3764C7.65453 19.7727 8.18336 20 8.74621 20C9.45214 20 10.0456 19.5459 10.1573 18.9203C10.2421 18.4457 10.0316 17.8274 9.18652 17.5157Z"
          fill={color || "#757E89"}
        />
        <path
          d="M19.18 18.3957C19.6615 18.1974 20 17.7356 20 17.1989C20 16.4818 19.396 15.8984 18.6536 15.8984H17.8232C17.823 15.8984 17.8233 15.8984 17.8232 15.8984C17.823 15.8984 17.8223 15.8984 17.8222 15.8984C17.5525 15.8984 17.3339 16.1171 17.3339 16.3867V19.5117C17.3339 19.7814 17.5525 20 17.8222 20C18.0918 20 18.3105 19.7814 18.3105 19.5117V18.8859L19.1452 19.8343C19.2417 19.944 19.3765 20 19.5119 20C19.6265 20 19.7416 19.9599 19.8344 19.8782C20.0368 19.7001 20.0565 19.3916 19.8783 19.1891L19.18 18.3957ZM18.6536 16.875C18.8506 16.875 19.0234 17.0264 19.0234 17.1989C19.0234 17.3714 18.8506 17.5227 18.6536 17.5227C18.572 17.5227 18.4448 17.5232 18.3145 17.5237C18.3139 17.3983 18.3132 17.007 18.3129 16.875H18.6536Z"
          fill={color || "#757E89"}
        />
        <path
          d="M4.92188 15.8984C3.79109 15.8984 2.87109 16.8184 2.87109 17.9492C2.87109 19.08 3.79109 20 4.92188 20C6.05266 20 6.97266 19.08 6.97266 17.9492C6.97266 16.8184 6.05266 15.8984 4.92188 15.8984ZM4.92188 19.0234C4.32957 19.0234 3.84766 18.5416 3.84766 17.9492C3.84766 17.3569 4.32957 16.875 4.92188 16.875C5.51418 16.875 5.99609 17.3569 5.99609 17.9492C5.99609 18.5416 5.51418 19.0234 4.92188 19.0234Z"
          fill={color || "#757E89"}
        />
        <path
          d="M15.751 11.1328C15.9603 11.1328 16.1538 11.0211 16.2584 10.8398C16.3631 10.6586 16.3631 10.4352 16.2584 10.2539L14.5106 7.22656L16.2584 4.19922C16.3631 4.01793 16.3631 3.79457 16.2584 3.61328C16.1537 3.43199 15.9603 3.32031 15.751 3.32031H12.2553L10.5074 0.292969C10.4028 0.11168 10.2093 0 10 0C9.79067 0 9.59723 0.11168 9.49258 0.292969L7.74473 3.32031H4.24902C4.03969 3.32031 3.84625 3.43199 3.7416 3.61328C3.63695 3.79457 3.63691 4.01793 3.7416 4.19922L5.48945 7.22656L3.7416 10.2539C3.63691 10.4352 3.63691 10.6586 3.7416 10.8398C3.84629 11.0211 4.03969 11.1328 4.24902 11.1328H7.74473L9.49258 14.1602C9.59727 14.3414 9.79067 14.4531 10 14.4531C10.2093 14.4531 10.4028 14.3414 10.5074 14.1602L12.2553 11.1328H15.751ZM14.7361 9.96094H12.9318L13.834 8.39844L14.7361 9.96094ZM11.5787 9.96094H8.42129L6.84258 7.22656L8.42129 4.49219H11.5787L13.1574 7.22656L11.5787 9.96094ZM14.7361 4.49219L13.834 6.05469L12.9319 4.49219H14.7361ZM10 1.75781L10.9021 3.32031H9.09789L10 1.75781ZM5.26391 4.49219H7.06817L6.16606 6.05469L5.26391 4.49219ZM5.26391 9.96094L6.16602 8.39844L7.06813 9.96094H5.26391ZM10 12.6953L9.09789 11.1328H10.9021L10 12.6953Z"
          fill={color || "#757E89"}
        />
      </g>
      <defs>
        <clipPath id="clip0_3899_35694">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Kosher;
