import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const QuantityButtonWrapper = styled.div`
  width: 168px;
  height: 50px;
  padding: 0 5px;
  border-radius: 25px;
  font-family: ${() => theme.typography.fontGilroy};
  display: flex;
  align-items: center;
  justify-content: space-between;

  .decrement,
  .increment {
    width: 44px;
    height: 100%;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }

  .count {
    font-size: 18px;
    font-weight: 600;
  }

  &.default {
    background-color: ${() => theme.colors.grayLighter};
    color: ${() => theme.colors.primaryDarker};
    svg path {
      stroke: ${() => theme.colors.primaryDarker};
    }
    &:hover {
      background-color: #dfe2e8;
    }
    &:active {
      background-color: ${() => theme.colors.grayDarker};
    }
  }

  &.dark {
    background-color: ${() => theme.colors.primaryDarker};
    color: ${() => theme.colors.secondary};
    svg path {
      stroke: ${() => theme.colors.secondary};
    }
    &:hover {
      background-color: ${() => theme.colors.primaryDark};
    }
    &:active {
      background-color: ${() => theme.colors.primaryDarker};
    }
  }

  &.light {
    background-color: ${() => theme.colors.secondary};
    color: ${() => theme.colors.primaryDarker};
    border: 1px solid ${() => theme.colors.grayDarker};
    svg path {
      stroke: ${() => theme.colors.primaryDarker};
    }
    &:hover {
      color: ${() => theme.colors.grayDarker};
      border: 1px solid ${() => theme.colors.primaryDefault};
      svg path {
        stroke: ${() => theme.colors.grayDarker};
      }
    }
    &:active {
      color: ${() => theme.colors.primaryDefault};
      border: 1px solid ${() => theme.colors.primaryDarker};
      svg path {
        stroke: ${() => theme.colors.primaryDefault};
      }
    }
  }

  &.smallSize {
    width: 88px;
    height: 32px;
    border-radius: 20px;
    .decrement,
    .increment {
      width: 28px;
      font-size: 20px;
    }
    .count {
      font-size: 14px;
    }
  }

  &.disabled {
    background-color: #f5f7f8;
    color: ${() => theme.colors.grayDark};
    cursor: not-allowed;
    svg path {
      stroke: ${() => theme.colors.grayDark} !important;
    }
    .decrement,
    .increment {
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover {
      background-color: #f5f7f8;
    }
    &:active {
      background-color: #f5f7f8;
    }
  }

  &.light.disabled {
    background-color: ${() => theme.colors.secondary};
    border: 1px solid ${() => theme.colors.grayLight};
    color: ${() => theme.colors.grayDark};
  }

  .increment.disabled {
    color: ${theme.colors.grayLight};
    cursor: not-allowed;
  }

  @media (max-width: 991px) {
    &.default:hover,
    &.default:active {
      background-color: ${() => theme.colors.grayLighter};
    }

    &.dark:hover,
    &.dark:active {
      background-color: ${() => theme.colors.primaryDarker};
    }

    &.light:hover,
    &.light:active {
      color: ${() => theme.colors.primaryDarker};
      border: 1px solid ${() => theme.colors.grayDarker};
      svg path {
        stroke: ${() => theme.colors.primaryDarker};
      }
    }

    &.disabled:hover,
    &.disabled:active {
      background-color: #f5f7f8;
    }

    &.light.disabled {
      background-color: ${() => theme.colors.secondary};
      border: 1px solid ${() => theme.colors.grayLight};
      color: ${() => theme.colors.grayDark};
    }
  }
`;
