import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import DraggableImage from './DraggableImage';
import { useDragLayer } from 'react-dnd';

const ImagesDragDropStyle = styled.div`
  .drag_icon {
    cursor: grab;
  }
  .sub_image_upload, .main-image{
    padding: 0;
    img{
      user-select: none;
      object-fit: cover;
    }
  }
  .multi_sub_image{
    display: block !important;
    > div{
      float: left;
      margin-left: 10px;
      margin-top: 10px;
      &:first-child{
        height: 100%;
        margin-left: 0px;
      }
    }
  }
`;


const CustomDragPreview = ({imageRef}) => {
  const { itemType, isDragging, currentOffset, initialOffset, item } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getClientOffset(),
    initialOffset: monitor.getInitialClientOffset(),
    item: monitor.getItem(),
  }));

  if (!isDragging || itemType !== 'IMAGES' || !currentOffset || !initialOffset || !imageRef) {
    return null;
  }

  const x = currentOffset?.x;
  const y = currentOffset?.y;

  return (
    <div style={{
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 9999,
      left: x - 20,
      top: y - 10,
      opacity: 1,
      width: '130px',
      height: '85px',
      overflow: 'hidden',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.16)'
    }}>
      <img style={{ width: '100%', objectFit: 'cover', height: '100%' }} src={item?.imageFile}/>
    </div>
  )
}

const ImagesDragDrop = ({
  setIsRemoveImage,
  setDeleteIndex,
  handleFileClick,
  handleFileChange,
  mealData,
  fileInputRefs,
  setMealData,
  image,
  setImage,
  id
}) => {
  const ref = useRef(null);
  const [mealImages, setMealImages] = useState(mealData?.images);
  const [resultArray, setResultArray] = useState();
  const imageRef = useRef();

  useEffect(() => {
    setMealImages(mealData?.images);
    const imagesArray = Array.from({ length: 6 }, (_, index) => index < mealData?.images.length ? mealData?.images[index] : undefined);
    setResultArray(imagesArray);
  }, [mealData]);

  return (
    <ImagesDragDropStyle>
        <CustomDragPreview imageRef={imageRef}/>
        <div className="multi-images" ref={ref}>
          <div className="image-container">
            <div className="multi_sub_image">
              {resultArray?.map((item, index) => (
                <DraggableImage 
                  ref={imageRef}
                  key={item}
                  setIsRemoveImage={setIsRemoveImage} 
                  setDeleteIndex={setDeleteIndex} 
                  image={image} 
                  item={item}
                  index={index}
                  handleFileChange={handleFileChange}
                  fileInputRefs={fileInputRefs}
                  setImage={setImage}
                  mealData={mealData}
                  setMealData={setMealData}
                  handleFileClick={handleFileClick}
                  resultArray={resultArray}
                  setResultArray={setResultArray}
                  id={id}
                />
              ))}
            </div>
          </div>
        </div>
    </ImagesDragDropStyle>
  );
};

export default ImagesDragDrop;
