import styled from 'styled-components';
import { BsCheck2 } from 'react-icons/bs';

const MealCardStyle = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 12px 32px 12px 12px;
  margin-bottom: 6px;
  &.voided {
    opacity: 0.6;
  }
  &.paid {
    opacity: 0.6;
  }
  .image {
    position: relative;
  }
  .meal_img {
    width: 40px;
    height: 40px;
    margin-right: 14px;
    object-fit: contain;
    display: block;
  }
  .meal_name {
    font-size: 14px;
    line-height: 16px;
    color: #51635d;
    cursor: default;
    flex: 1;
  }
  .qty {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: #949d9b;
    width: 19%;
  }
  .amount {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #51635d;
    text-align: right;
    width: 7% !important;
  }
  .meal_checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .checkbox_container {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    .icon_1 {
      display: none;
    }
  }

  .checkbox_container::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 92%;
    height: 86%;
    border: 1px solid #afb5b4;
    border-radius: 5px; /* sets the border-radius of the square box to 5 pixels */
  }

  .checkbox_container::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #afb5b4;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .checkbox_container:hover::before {
    border-color: #666; /* changes border color on hover */
  }
  .icon_1 {
    font-size: 20px;
    padding-top: 2px;
  }
  .meal_checkbox:checked + .checkbox_container {
    .icon_1 {
      display: block;
      color: #52c41a;
    }
  }

  .acc_status {
    background: #ffffff;
    border-radius: 15px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 10px 30px;
    color: #949d9b;
    border: 1px solid rgba(175, 181, 180, 0.2);
    &.voided {
      color: #f3972f;
      opacity: 1 !important;
      border: 1px solid #f3972f;
    }
    &.paid {
      color: #52c41a;
      opacity: 1 !important;
      border: 1px solid #52c41a;
    }
  }

  @media (max-width: 991px) {
    padding: 12.5px 14px;
    width: 100% !important;
    align-items: flex-start;
    height: 88px !important;
    .meal_name {
      color: #0b1d17;
      margin-top: 5px;
    }
    .meal_img {
      margin-right: 10px;
      width: 60px !important;
      height: 60px !important;
    }
    .acc_status.voided {
      margin-right: 4px;
      padding: 8px 20px;
    }
    .acc_status.paid {
      margin-right: 10px;
      padding: 8px 20px;
    }

    .qty {
      position: absolute;
      font-weight: 700;
      font-size: 8px;
      line-height: 18px;
      color: #51635d;
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 1px solid #52c41a;
      border-radius: 10px;
      text-align: center;
      left: 0;
      bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }
    .amount {
      margin-top: 5px;
      margin-right: 5px;
    }

    .amount-wide {
      width: 19% !important;
    }

    .amount-normal {
      width: 13% !important;
    }
  }
`;

export default function MealCard({ isMobile, mealDetails, checked, onCheckboxChange }) {
  const voided = mealDetails.isVoided;
  const refundStatues = mealDetails.refundStatus;
  const datePaid = mealDetails.datePaid;

  function trimCharacter(string) {
    if (string.length > 34) {
      return string.slice(0, 34) + '...';
    } else {
      return string;
    }
  }
  var limitedText = trimCharacter(mealDetails?.data?.name);

  return (
    <MealCardStyle
      className={`${voided ? 'voided' : ''} ${refundStatues === 'completed' ? 'paid' : ''}`}
    >
      {!isMobile && (
        <label style={{ padding: '0px 25px 0px 10px' }}>
          <input
            type="checkbox"
            className="meal_checkbox"
            checked={(checked && !voided) || refundStatues === "completed"}
            disabled={voided}
            onChange={(event) => onCheckboxChange(event.target.checked)}
          />
          <span className="checkbox_container">{<BsCheck2 className="icon_1" />}</span>
        </label>
      )}
      {isMobile && (
        <label style={{ padding: '22px 17px 0px 6px' }}>
          <input
            type="checkbox"
            className="meal_checkbox"
            checked={checked || refundStatues === 'completed'}
            disabled={voided}
            onChange={(event) => onCheckboxChange(event.target.checked)}
          />
          <span className="checkbox_container">{<BsCheck2 className="icon_1" />}</span>
        </label>
      )}
      <div className="image">
        <img
          src={mealDetails.data.img ? mealDetails.data.img : '/images/dish.png'}
          className="meal_img"
        />
        {isMobile && <span className="qty">{mealDetails?.quantity}</span>}
      </div>
      <h2 className="meal_name">
        {voided || refundStatues === 'completed' ? limitedText : mealDetails?.data?.name}
      </h2>
      {voided ? (
        <span className={`acc_status ${voided ? 'voided' : ''}`}>{voided ? 'Voided' : ''}</span>
      ) : (
        ''
      )}
      {refundStatues === 'completed' ? (
        <span className={`acc_status ${refundStatues === 'completed' ? 'paid' : ''}`}>
          {refundStatues === 'completed' ? 'Paid' : ''}
        </span>
      ) : (
        ''
      )}
      {!isMobile && <p className="qty">x {mealDetails?.quantity}</p>}
      <h3 className={`amount ${mealDetails?.price > 999.99 ? 'amount-wide' : 'amount-normal'}`}>
        ${mealDetails?.price}
      </h3>
    </MealCardStyle>
  );
}
