import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { useState, useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Progress from 'components/Progress';
import moment from 'moment-timezone';
import BackButton from 'components/BackButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import Select from 'components/Select';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import BlurBackground from 'components/BlurBackground';
import PromocodeDeleteModal from './PromocodeDeleteModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDebounce } from 'use-debounce';

const UserManagementStyle = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 40px 68px 30px;
  background:#ffffff;
  font-family: 'Roboto';
  font-style: normal;
  display:flex;
  flex-direction:column;
  align-items:center;
  min-height: calc(100vh - 32px);

  .fJeUUY{
    position:fixed;
    top:23px !important;
  }
 
  .total-user{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    div{
      display:flex;
      background: rgb(246, 245, 248);
      gap:8px;
      padding:10px 10px;
      p{
        color: rgb(81, 99, 93) !important;
        font-weight: 700 !important;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .user{
    display:flex;
    justify-content:center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635D;
  }

  .search{
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
     margin-top:30px;
     align-items: center;
     width:100%;
     input{
        height:44px;
        border: 1px solid #E0E3E0;
        border-radius: 6px;
        width:100%;
        background:url('/icons/search-icon.svg') no-repeat 16px;
        padding-left: 40px;
        color: #AFB5B4;
        margin-top: -2px;
     }
     input:focus{
        outline:0px !important;
        -webkit-appearance:none;
        box-shadow: none;
     }
    }

  .heading{
    display:flex;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    margin-top:40px;
    margin-bottom:14px;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    gap:5px;
    p{
        word-break: break-word;
        width:140px;
    }
   
    p:first-child{
      width:70px !important;
    }
    p:nth-child(2){
      width:70px !important;
      padding-left:5px;
    } 
    p:nth-child(4){
      padding-left:10px;
    }      
  }

  .infinite-scroll-component__outerdiv{
    width:100%;
  }
  .details-div{
    width:100%;
  }

  .details{
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    padding: 16px 20px !important;
    background: #F6F5F8;
    border: 1px solid #E0E3E0;
    border-radius: 6px !important;
    margin-bottom:6px;
    gap:5px;
    width:100%;
    p{
      word-break: break-word;
      width:134px;
      font-size: 12px;
      line-height: 14px;
      color: #51635D;
    }
    .name{
      max-width: 180px;
      word-break: break-all;
    }
    .email{
      width: 200px;
      word-break: break-all;
      display: block;
    }
    span{
      font-size: 12px;
      line-height: 14px;
      color: #51635D;
    }
  }
  .details:nth-child(even){
    background:#ffffff;
  }

  .show-more{
    border: 1px solid #97DC76;
    border-radius: 21px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content:center;
    align-items:center;
    color: #51635D;
    width: 149px;
    height: 40px;
    margin-top:30px; 
    cursor: pointer;
  }

  .table-height{
    max-height: calc(100vh - 240px);
  }

  .MuiTable-root{
    border-spacing: 0 6px !important;
    border-collapse: separate !important;
  }

  .MuiTableRow-root th{
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    font-weight: 700;
    border-bottom: 0px;
  }

  .MuiTableRow-root td{
    border-top: 1px solid #E0E3E0;
    border-botoom: 1px solid #E0E3E0 !important;
  }

  .MuiTableRow-root td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid #E0E3E0;
  }

  .MuiTableRow-root td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid #E0E3E0;
  }

  @media (max-width: 991px) {
    width: 90%;
    padding: 24px 0;
    margin: 23px auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .fJeUUY{
      position:fixed;
      top:55px !important;
    }

    .user{
      font-size: 16px !important;
      line-height: 19px !important;
      color:#0B1D17 !important;
    }

    .infinite-scroll-component__outerdiv{
      width:90%;
    }

    .total-user{
      div{
        padding:8px 8px !important;
        p{
          font-size:14px !important;
        }
      }
    }
    
    .user-details{
      margin-bottom:6px;
      // border: 1px solid rgb(224, 227, 224);
      // border-radius: 6px;
     
      .MuiPaper-root{
        background-color:unset !important;
      }
      .MuiAccordionSummary-root {
        background:#F6F5F8;
        border-radius: 6px;
        border: 1px solid #E0E3E0;
      }
      .MuiIconButton-root{
        padding:0 12px 0 12px !important;
        margin-top: -20px;
      }
      .MuiAccordion-root.Mui-expanded{
        border: 1px solid #97DC76;
        border-radius:6px;
        background: #F0FAEB !important;
      }
      .MuiAccordionSummary-root.Mui-expanded{
        min-height:54px !important;
        background: #F0FAEB !important;
        border: unset;
      }
      .MuiAccordionSummary-content {
        margin:10px 0 !important;
        justify-content: space-between;
      }
      .MuiAccordionSummary-content.Mui-expanded {
        margin: 10px 0 !important;
      }
      .MuiAccordionDetails-root {
        padding: 0px 16px 10px !important;
        // background: #F0FAEB;
      }
      .MuiAccordionSummary-expandIcon.Mui-expanded{

      }

      .MuiPaper-elevation1{
        box-shadow:none !important;
      }

      .name, .coach{
        display:flex;
        gap:8px;
      }
      .name{
        margin-bottom:6px;
      }
      .user-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #51635D; 
      }
      .user-name{
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635D; 
      }
    }
    .user-details:nth-of-type(even){
      .MuiAccordionSummary-root{
        background: #ffffff;
      }
    }
    .search{
      display: grid;
      grid-template-columns: 44% 44% !important;
      justify-content:center;
   }
  }

  @media(min-width:992px) and (max-width:1300px){
    .details-div, .heading{
        width:100% !important;
    }
    .details p{
     //   width:auto !important;
    }

    .infinite-scroll-component__outerdiv{
      width:100% !important;
    }
  }
  @media(min-width:1300px) and (max-width:1500px){
    .details-div, .heading{
        width:100% !important;
    }
    .infinite-scroll-component__outerdiv{
      width:100% !important;
    }
  }
  @media(min-width:992px) and (max-width:1230px){
    .heading{
      font-size:8px !important;
    }
    .details{
       font-size:8px !important;
    }
  }
  @media(min-width:1230px) and (max-width:1295px){
    .heading{
      font-size:10px !important;
    }
    .details{
       font-size:9px !important;
    }
  }
  @media(min-width:1295px) and (max-width:1370px){
    .heading{
      font-size:11px !important;
    }
    .details{
       font-size:10px !important;
    }
  }
  @media(min-width:1600px) {
     .heading{
       p:nth-child(3){
        padding-left:5px !important;
       }
       p:nth-child(4){
        padding-left:13px !important;
       }
    }
  
  }
`;

const LoadingText = styled.p`
  font-size: 14px;
  line-height: 20px; 
  font-weight: 600;
`

export default function UserManagement() {
  const { isDesktop } = useAppContext()
  const [deleteUser,{isLoading:isDeleting}]=useRequest("/admin/delete/","delete")
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [filter, setFilter] = useState('all')
  const [expanded, setExpanded] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [userInfo, setUserInfo] = useState('');
  const infiniteScrolling = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [fetchUsersAPI]=useRequest();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 700);
  const [totalCount,setTotalCount]=useState(0)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if(isDesktop){
      document.body.classList.add("hide-scroll");
    }

    return () => {
      document.body.classList.remove("hide-scroll");
    }
  }, [])

  const getUserProfileName=(user)=>{
    if(user.type.includes('nutritionist')){
      if(user.nutritionistProfile && user.nutritionistProfile[0]){
        return user?.nutritionistProfile[0]?.name?.slice(0, 50);
      }
    } 
    else if(user.type.includes("provider")){
      return user?.provider?.name?.slice(0, 50);
    } else {
      return user?.profile?.name?.slice(0, 50);
    }
  }

  useEffect(()=>{

    ReactTooltip.rebuild();

  },[users])

  const formateDate = (date) => {
    return moment(new Date(date)).format('MMM DD YYYY, h:mm a')
  }
 const fetchMoreData = () => {
  setCurrentPage((prevState)=>prevState+1)
  };

  const allTypePhoneNumber = (user) => {
    if(user?.type.includes('provider')){
      return user?.provider?.phoneNumber
    } else {
      return user?.phoneNumber
    }
  }
  const displayColor=(userType)=>{
    switch (userType) {
      case 'guest': return 'orange';
      case 'visitor': return '#00D1D1';
      case 'user': return '#57e312';
      case "provider": return '#9B26B6';
      case 'nutritionist': return 'blue'; 
      default:
        return "red";
    }
  }
  const handleDeleteUser= async(id,type) =>{
 if(type==="user"|| type==="visitor"|| type=="guest"){
  await deleteUser({
    path:`admin/user/${id}`,
   }).then((result)=>{
    setUsers((prevUsers)=>prevUsers.filter((user)=>user.id !==id))
    toast.success(result.message);
    setIsDeletingUser(false);
   }).catch((err)=>{
    console.log(err)
   })
 } else {
  toast.error("Operation failed ! invalid user type")
  setIsDeletingUser(false);
 }
  }
  
  const columns = [
    {
      id: 'sno',
      label: 'S.no.',
      minWidth: 30,
    },
    { id: 'id', label: 'Id', minWidth: 30 },
    { id: 'email', label: 'Email' },
    { id: 'name', label: 'Name', minWidth: 180 },
    { id: 'timezone', label: 'Timezone', minWidth: 200 },
    { id: 'userType', label: 'User Type' },
    { id: 'phoneNumber', label: 'Phone Number', minWidth: 130 },
    { id: 'coach', label: 'Coach', minWidth: 180 },
    { id: 'dietPlan', label: 'Diet Plan', minWidth: 100 },
    { id: 'memberSinceDate', label: 'Member Since Date', minWidth: 200 },
    // { id: 'lastActive', label: 'Last Active', minWidth: 200},
    // { id: 'platform', label: 'Platform', minWidth: 120},
    { id: 'action', label: 'Action' },
  ];


  const handleScroll = useCallback(async() => {
      const scrollTop = infiniteScrolling.current.scrollTop;
      if (scrollTop >= (infiniteScrolling.current.scrollHeight - infiniteScrolling.current.clientHeight - 150)) {
        setCurrentPage((prevState)=>prevState+1)
        
      }
  }, [infiniteScrolling]);
  const handleSearchInput=(e)=>{
    const sanitizedInput = (e.target.value).trim();
    setSearchTerm(sanitizedInput);
    setCurrentPage(1)
  }

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetchUsersAPI({
        path: `admin/all-users?page=${currentPage}&limit=${pageSize}&searchText=${debouncedSearchTerm}&type=${filter}`
      });
      return response;
    } catch (error) {
      console.log('fetchUsers api error:', error);
      if (error !== 'jwt must be provided') {
        toast.error('Oops! Something went wrong.');
      }
      throw error;
    }
  }, [currentPage, debouncedSearchTerm, filter]);
  
  useEffect(() => {
    fetchUsers().then((result) => {
      if (currentPage === 1) {
        setUsers(result.data);
      } else {
        setUsers((prevState) => [...prevState, ...result.data]);
      }
      setTotalCount(result?.totalCount)
    }).catch((error) => {
      console.log(error);
      // Handle error if necessary
    });
  }, [currentPage, debouncedSearchTerm, filter]);
  
  
  
  
  return (
    <div className="">
      {false ? <Progress />
      :(
      <UserManagementStyle>
       {isDesktop && <BackButton/>}
       <div className='total-user'>
         <div> 
          <p>Total Users:</p> 
          <p>{totalCount}</p> 
         </div>
        </div>
        <div className='user'>
          <p>Users</p>
        </div>

        <div className='search'>
          <input type='text' placeholder='Search User' onChange={handleSearchInput} value={searchTerm} />
        <div>
          <Select
            key="type"
            defaultChecked={filter}
            label="Select User Type"
            icon={'SelectArrow' }
            options={[
              { label: 'All',  value:'all',labelColor:displayColor('all') },
              { label: 'Visitor ', value: 'visitor',labelColor:displayColor('visitor')},
              { label: 'User', value: 'user',labelColor:displayColor('user') },
              { label: 'guest ', value: 'guest',labelColor:displayColor('guest') },
              { label: 'Nutritionist ', value: 'nutritionist',labelColor:displayColor('nutritionist') },
              { label: 'Provider ', value: 'provider' ,labelColor:displayColor('provider')},
            ]}
            updateValue={(value) => {
              setFilter(value);
              setCurrentPage(1)

            }}
          />
          </div>
        </div>

        
          {isDesktop ? 
          <TableContainer ref={infiniteScrolling} onScroll={handleScroll} className='table-height'>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                .map((user, index) => {
                  const active = moment(user?.lastActive);
                  const lastActive = active?.format('MM/DD/YYYY h:mm a')
                  return (
                  (isDesktop ? (
                    <TableRow className='details' key={index}>
                      <TableCell><span>{index + 1}</span></TableCell>
                      <TableCell><span>{user?.id || 'N/A'}</span></TableCell>
                      <TableCell><span className='email'>{user?.email || 'N/A'}</span></TableCell>
                      <TableCell><p className='name'>{getUserProfileName(user) || 'N/A'}</p></TableCell>
                      <TableCell><p>{user?.TimeZone || 'N/A'}</p></TableCell>
                      <TableCell><p style={{ color: `${displayColor(user?.type?.toString())}` }}>{user?.type || 'N/A'}</p></TableCell>
                      <TableCell><p>{allTypePhoneNumber(user) || <span style={{ paddingLeft: '15px' }}>N/A</span>}</p></TableCell>
                      <TableCell><p className='name'>{user?.nutritionists[0]?.nutritionist?.name || 'N/A'}</p></TableCell>
                      <TableCell><p data-tip={user?.profile?.dietTemplate?.name} data-for="name" style={{ cursor: 'pointer' }}>{user?.profile?.dietTemplate?.name === undefined ? 'N/A' : user?.profile?.dietTemplate?.name.replace(/^(.{11}[^\s]*).*/, "$1") + '...'}</p></TableCell>
                      <TableCell><p>{formateDate(user?.createdAt)}</p></TableCell>
                      {/* <TableCell><p>{user?.lastActive ? lastActive : 'N/A'}</p></TableCell>
                      <TableCell><p>{user?.platform?.ios ? 'IoS' : user?.platform?.web ? 'Web' : user?.platform?.android ? 'Android' : 'N/A'}</p></TableCell> */}
                      <TableCell align='center'>{!(user?.type == "nutritionist" || user?.type == "provider")
                        && <p style={(user?.type === "nutritionist" || user?.type === "provider") ? { marginLeft: "38px" } : { margin: 'auto', cursor: "pointer", width: '20px' }}><img src={user ? '/icons/trash-green.svg' : '/icons/trash2.svg'} onClick={() => { setUserInfo(user), setIsDeletingUser(true) }} /></p>}</TableCell>
                    </TableRow>
                  ) :
                    ""
                  )
                )})}
            </TableBody>
          </Table>
          {(hasMore && filter==='all' && !searchTerm) && <LoadingText>Loading...</LoadingText>}
          </TableContainer>
          : (
          <>
          <InfiniteScroll
            dataLength={users?.length}
            next={fetchMoreData}
            pullDownToRefreshThreshold={50}
            hasMore={hasMore}
            loader={ filter==='all' && !searchTerm &&  <h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {users.map((user, index) => {
              const active = moment(user?.lastActive);
              const lastActive = active?.format('MM/DD/YYYY h:mm a')
              return (
              <div className='user-details' key={index}>
                <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                  <AccordionSummary
                    expandIcon={expanded === `panel${index}` ? <img src='/icons/hide-green.svg' alt='expand' /> : <img src='/icons/expand.svg' alt='expand' />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div>
                      <div className='name'> <p className='user-text'>Name:</p><p className='user-name'>{getUserProfileName(user) || 'N/A'}</p> </div>
                      <div className='coach'> <p className='user-text'>Coach:</p><p className='user-name'>{user?.nutritionists[0]?.nutritionist?.name || 'N/A'}</p> </div>
                    </div>
                    <div>
                      {!(user?.type == "nutritionist" || user?.type == "provider")
                        && <div
                          style={(user?.type === "nutritionist" || user?.type === "provider")
                            ? { marginLeft: "38px" }
                            : { marginLeft: 0, cursor: "pointer", float: 'right' }}>
                          <img src={user ? '/icons/trash-green.svg' :
                            '/icons/trash2.svg'}
                            onClick={() => { setUserInfo(user), setIsDeletingUser(true) }} />
                        </div>}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className='name'> <p className='user-text'>Diet Plan:</p><p className='user-name'>{user?.profile?.dietTemplate?.name || 'N/A'}</p> </div>
                      <div className='name'> <p className='user-text'>Phone Number:</p><p className='user-name'>{allTypePhoneNumber(user) || 'N/A'}</p> </div>
                      <div className='name'> <p className='user-text'>Email:</p><p className='user-name'>{user?.email || 'N/A'}</p> </div>
                      <div className='name'> <p className='user-text'>Member since Date:</p><p className='user-name'>{formateDate(user?.createdAt)}</p> </div>
                      <div className='name'> <p className='user-text'>Id:</p><p className='user-name'>{user?.id || 'N/A'}</p> </div>
                      <div className='name'> <p className='user-text'>User Type:</p><p style={user?.type == 'visitor' ? { color: 'orange', fontSize: '12px' } : user?.type == 'guest' ? { color: 'violet', fontSize: '12px' } : user?.type == 'user' ? { color: '#52C41A', fontSize: '12px' } : { color: '#51635D', fontSize: '12px' }}>{user?.type || 'N/A'}</p></div>
                      <div className='name'> <p className='user-text'>Timezone:</p><p className='user-name'>{user?.TimeZone}</p></div>
                      {/* <div className='name'> <p className='user-text'>Last Active:</p><p className='user-name'>{user?.lastActive ? lastActive : 'N/A'}</p></div> */}
                      {/* <div className='name'> <p className='user-text'>Platform:</p><p className='user-name'><p>{user?.platform?.ios ? 'IoS' : user?.platform?.web ? 'Web' : user?.platform?.android ? 'Android' : 'N/A'}</p></p></div> */}
                    </div>
                  </AccordionDetails>
                </Accordion>

              </div>
            )})}
          </InfiniteScroll>
          </>
          )}
          {isDesktop && <ReactTooltip id="name" place="top" effect="solid">
            </ReactTooltip>}
          {
            !users.length && (
              <p style={{marginTop:'40px',color:'#51635D',textAlign:'center'}}>No Record Found</p>
            )
          }
  {isDeletingUser && 
    <PromocodeDeleteModal
    isDeletingUser={isDeletingUser}
    setIsDeletingUser={setIsDeletingUser}
    userInfo={userInfo}
    handleDeleteUser={handleDeleteUser}
    />}
  </UserManagementStyle>
      )}


       {
        isDeleting &&
        <BlurBackground>
        <Progress />
      </BlurBackground>
       }
    </div>
    

    
  );
}

