import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const ProgressWrapper = styled.div`
  & + & {
    margin-top: 18px;
  }

  .progress-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .progress-label,
    .progress-value {
      margin-bottom: 8px;
      color: ${() => theme.colors.blackDefault};
      font-family: ${() => theme.typography.fontInter};
      font-size: 14px;
      font-weight: 500;
    }
    .progress-percent {
      font-weight: 400;
    }
  }
`;
