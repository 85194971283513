import ClientDashboard from 'components/Dashboard';
import SubmitButton from 'components/SubmitButton';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext} from "../../../context/nutritionistContext";
import moment from 'moment';
import { useEffect } from 'react';
import NutritionistEmptyPage from 'components/Nutritionist/NutritionistEmptyPage';
import { useRequest } from 'hooks/useRequest';
import { offeringList } from 'components/Nutritionist/Coaching/OfferingData';
import ImageViewer from 'components/ImageViewer';
import NutritionistOfferingList from 'pages/Nutritionist/NutritionistOfferingList';
import { FaRegCopy } from 'react-icons/fa';

const InviteButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn-end {
    border-radius: 31px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.22% 2.22% 0 0;
    gap: 5px;
    background: #52C41A;
    height: 40px;
    width: 126px;
    color: #FFFFFF;
  }

  .btn-start {
      background: #FFFFFF;
      color: #51635D;
      border: 1px solid #97DC76 !important;
      width: 104px;
      height: 40px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin: 2.22% 1% 0 0;
  }

  .inviteButton{
    display: flex;
    justify-content: center;
  }

  @media(max-width: 991px){
    justify-content: space-evenly;
    margin: 0 10% 23px 10%;
    gap: 8px;

    .btn-end{
      width: 50%;
      margin: 2.22% 0 0 0;
    }
    .btn-start{
      width: 50%;
      margin: 2.22% 3% 0 0;
      background: none !important;
    }
  }

  @media (min-width: 1281px){
    .btn-end{
      width: 136px;
    }
    .btn-start{
      width: 144px;
    } 
  }
  }
`;
const PageTitleStyled = styled.div`
  text-align: center;
  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635D;
    height: 32px;
    top: 76px;
    align-items: center;
  }

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #949D9B;
    margin: 8px 0 5% 0;
  }

  @media(max-width: 991px) {
    margin-top:60px;
    h1 {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
    }
    p{
      margin: 10px 29.94px 23px 29.94px;
      font-size:14px !important;
      line-height:16px !important;
    }
  }

  @media screen and (max-width: 1680px) and (min-width: 1439px) {
    p{
      margin: 8px 0 4% 0;
    }
  }
`;
const MainContentWrapper = styled.div`
  min-width: 600px;
  width: 68%;
  margin: auto;

  @media(max-width: 991px){
    width:100%;
    min-width: 375px;
    padding: 0px 7%;
  }
  @media(max-width: 479px){
    min-width: 346px;
    padding: 0px 4%;
  }
  @media screen and (max-width: 1680px) and (min-width: 1439px) {
    width: 50%;
  }
  @media screen and (max-width: 1920px) and (min-width: 1679px) {
    width: 46%;
  }
  @media (min-width: 1440px) {
    width: 43%;
  }
`;
const ClientFormWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;

  @media(max-width: 991px){
    gap: 10px;
    justify-content: space-between;
    display: flex;
    margin-bottom: 56px;

  }
`;
const ClientFormInputWrapper = styled.input`
  width: 85%;
  max-width: 470px;
  background: #ffffff;
  border: 1px solid #e0e3e0;
  box-sizing: border-box;
  border-radius: 21px;
  padding: 10px 20px;
  font-size: 14px !important;
`;
const ClientFormSearchWrapper = styled.button`
  padding: 12px 38px;
  width: 120px;
  height: 40px;
  background: #949d9b;
  border-radius: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;
const ClientListStyled = styled.div`
  // margin-bottom: 40px;
  h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 70px;
    color: #51635d;
    height : 60px;
    display: flex;
    justify-content: center;
  }
`;
const ClientListCardStyled = styled.div`
  background: #ffffff;
  // border: 1px solid #f6f5f8;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  border: 1px solid #E0E3E0;
  border-radius: 10px;
  padding: 24px 20px;
  display: grid;
  align-items: center;
  // justify-content: space-between;
  grid-template-columns: 36.33% 33.33% 30.33%;
  margin-bottom: 6px;
  // height: 60px;

  @media screen and (max-width: 1440px) and (min-width: 1280px) {
    height: 78px;
    padding: 20px;
  }

  @media screen and (max-width: 2560px) and (min-width: 1919px) {
    height: 5.55%;
  }

  @media(max-width: 991px){
    display: flex;
    // grid-template-columns: auto auto;
    justify-content: space-between;
  }
`;
const ClientCardDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 50%;
  }
  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #51635D;
  }
  @media(max-width: 991px){
      .nameTimeWrapper:{
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      p{
        font-famsily: 'Roboto';
        font-style: normal;
        font-size: 12px;
        font-weight: 400px;
        line-height: 14px;
        color: #51635D;
      }
  }
`;
const ClientCardActions = styled.div`
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  // gap: 4px;
  .btn-icon {
    background-color: transparent;
    cursor: pointer;
  }
  h4{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #51635D;
    margin-left: 11%;
  }
  h5{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #949D9B;
    margin-left: 4%;
  }
`;

const ClientCardMeeting = styled.div`
display: flex;
justify-content: space-between;
button{
    align-items: center;
    padding: 5%;
    gap: 10px;
    background: #3772E0;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    width: 60%;
    justify-content: center;
}
h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #51635D;
}

button: disabled {
  background: #E0E3E0;
  color: #FFFFFF;
  cursor: not-allowed !important;
}

.copy-icon{
  cursor: pointer
}
.copied-text {
  color:#52C41A;
  font-size:16px;
  position: absolute;
  margin-top: 35px;
  margin-left: -35px;
}

@media(max-width: 991px){
  gap: 6%;
  width:20%;
  button{
    width: 66%;
    height: 33px;
  }
  .copied-text{
    font-size:12px;
    margin-top:32px;
  }
}  
@media(max-width: 479px){
  width:40%;
  
  button{
    width: 66%;
    height: 33px;
  }
}  

@media screen and (max-width: 1680px) and (min-width: 1439px) {
  button{
    width: 59%; 
  }
}
`;

const ClientCard = styled.div`
    width: calc(100% - 300px);
    margin-left: 300px;
    background: #FFFFFF;
    height: 100%;
    min-height: calc(100vh - 32px);

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    padding-top: 25px;
    min-width:300px;
    background: #F6F5F8;
   // margin-top: 60px;
   padding-bottom: 60px;
    .hide-scroll{
        overflow:hidden !important;
    }
  }
`;

const SendInviteButton = styled.div`
display: flex;
justify-content: center;
align-items: center;
.btn-end {
  border-radius: 21px;
  width: 20.5%;
  height: 4.5vh;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.22% 2.22% 2% 0;
  gap: 5px;
  background: #52C41A;
  height: 6.5vh;
}

@media(max-width: 991px){
  .btn-end{
    width: 48%; 
  }
}

@media screen and (max-width: 1440px) and (min-width: 992px) {
  .btn-end{
    width: 20%;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1439px) {
  .btn-end{
    width: 23%;
  }
}
@media (min-width: 1438px) {
  .btn-end{
    width: 21.5%; 
    border-radius: 62px !important;
  }
}
`;

export default function DashboardClient() {
  const history = useHistory();
  const { activeClients, offeringListData, followersList } = useNutritionistContext();
  const [searchValue, setSearchValue] = useState('');
  const {nutritionistMeeting: activeClientsData} = activeClients;
  const [filterData, setfilterData] = useState(activeClients);
  const { isMobile } = useAppContext();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const [isCopied, setIsCopied] = useState(false);
  const [clickedItem, setClickedItem] = useState(0);
  const [clickedSection, setClickedSection] = useState(0);

  const URL_ROOT = `${window.origin}/plan/`;
  const [nutritionistBasicInfo, setNutritionistBasicInfo] = useState({   
    url: '',
  });
  const {
    nutritionist: nutritionistData, } = useNutritionistContext();

    useEffect(() => {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }, [isCopied]);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [])

  useEffect(() => {
    setNutritionistBasicInfo({
      url: nutritionistData?.nutritionist?.url,
    });
  }, [nutritionistData]);

  const { url,  } = nutritionistBasicInfo;
  const onCloseModal = () => {
    document.body.classList.remove('hide-scroll');
    setOpen(false);
  }

  const coachingOffers = offeringListData && offeringListData[0]?.coachingOffer?.length > 0;
  const offersList = (coachingOffers && !followersList?.length) || (!coachingOffers && !followersList?.length);

  const offeringList = (coachingOffers && !activeClients.length)

  useEffect(()=>{
    setfilterData(activeClients)
  },[activeClients]);
 
  const timeDifference =(scheduledDateTime) => {
    const compare = Math.abs(new Date(scheduledDateTime) - new Date());
    var minutes = Math.floor((compare/1000)/60);
    if(minutes < 11){
      return "Start in 10 minutes"
    }
  }

  
  const onSearchFn = (e) => {
    e.preventDefault();
    const filteredData = _.filter(activeClientsData, (data) => {
      return _.includes(
        _.lowerCase(_.values(data?.user?.profile)),
        _.lowerCase(searchValue),
      );
    });
    setfilterData(filteredData);
  };
  const onClickHandler = (e, id) => {
    e.preventDefault();
     history.push(`/nutritionist/clients/profile/${id}`);
  };
  useEffect(() => {
    if(open){
      document.body.classList.add('hide-scroll');
    }
  }, [open,onOpenModal])
  return (
    <ClientCard>
      {isMobile ? (
        <>
          <PageTitleStyled>
            <h1>{(offeringList) ? "Invite to Coaching" : "Upcoming Coaching Sessions"}</h1>
              {activeClients?.length >0 &&<p>Book 1 on 1 sessions with followers to scale your business.</p>}
          </PageTitleStyled>
          {activeClients?.length>0 &&
          <InviteButtonWrapper>
            <SubmitButton
              className="btn-end"
              onClick={() => history.push('/nutritionist/coaching/invite')}
            >
              <img src="/icons/profile-add.svg" alt="Profile Icon" />
              Send Invite
            </SubmitButton>
            <SubmitButton
              className="btn-start"
              // onClick={() => history.push('/nutritionist/coaching/offering')}
              onClick={onOpenModal}
            >
              Offerings
            </SubmitButton>
          </InviteButtonWrapper>}
        </>

      ) : (
        <>
          <InviteButtonWrapper>
            <SubmitButton
              className="btn-start"
              // onClick={() => history.push('/nutritionist/coaching/offering')}
              onClick={onOpenModal}
            >
              Offerings
            </SubmitButton>
            <SubmitButton
              className="btn-end"
              onClick={() => history.push('/nutritionist/coaching/invite')}
            >
              <img src="/icons/profile-add.svg" alt="Profile Icon" />
              Send Invite
            </SubmitButton>
          </InviteButtonWrapper>
          <PageTitleStyled>
            <h1>{(offersList)  ? "Invite to Personal Training" : "Upcoming Coaching Sessions"}</h1>
            {activeClients?.length > 0 &&<p>Schedule and manage your 1 on 1 coaching sessions here.</p>}
          </PageTitleStyled>
        </>
      )}
      <MainContentWrapper>
        {/* Hiding search from UI as latest figma design */}
        {/* <ClientFormWrapper>
          <ClientFormInputWrapper
            onChange={(e) => {
              const value = e.target.value;
              setSearchValue(value);
              if (!value) {
                setfilterData(activeClientsData);
              }
            }}
          ></ClientFormInputWrapper>
          <ClientFormSearchWrapper onClick={onSearchFn}>Search</ClientFormSearchWrapper>
        </ClientFormWrapper> */}
        <ClientListStyled>
          {activeClients?.length > 0 ? (
            <>
              
              {
                filterData?.length >0 && filterData?.map((item, index) => {
                
                  return (
                    <>
                    <h3>{moment(item[0]).format("MMMM, Do")}</h3>
                    {
                      item[1].sort((a, b) => moment(new Date(b?.NutritionistMeetingMapping[0]?.scheduledDateTime)) < moment(new Date(a?.NutritionistMeetingMapping[0]?.scheduledDateTime)) ? 1 : -1).map((subItem,subIndex)=>{
                        const meetingLink = subItem?.NutritionistMeetingMapping[0]?.event?.hangoutLink;
                        return (
                          <ClientListCardStyled
                          key={index}
                          // onClick={(e) => onClickHandler(e, subItem?.userId)}
                        >
                          <ClientCardDetailsStyled>
                            <ImageViewer
                            src={subItem?.user?.profile?.img || '/images/man.svg'}
                            alt={'man-image'}
                            fallbackSrc={'/images/man.svg'}
                            />
                            <div className='nameTimeWrapper'>
                            <h4 style={timeDifference(subItem?.NutritionistMeetingMapping[0]?.scheduledDateTime) ? {fontSize:'14px'} : {}}> {subItem?.user?.profile?.name} </h4>
                            {isMobile &&<p>at {moment(new Date(subItem?.NutritionistMeetingMapping[0]?.scheduledDateTime)).format("h:mma")}</p>}
                            </div>
                          </ClientCardDetailsStyled>
                          {!isMobile &&
                          <ClientCardActions>
                            <h4>at {moment(new Date(subItem?.NutritionistMeetingMapping[0]?.scheduledDateTime)).format("h:mma")}</h4>
                            <h5>{timeDifference(subItem?.NutritionistMeetingMapping[0]?.scheduledDateTime)}</h5>
                          </ClientCardActions>}
                          <ClientCardMeeting key={index}>
                            {!isMobile && <img src="/icons/Google Meet.svg" alt="Google Meet"/>}
                            <button onClick={()=> window.open(subItem?.NutritionistMeetingMapping[0]?.event?.hangoutLink)} 
                            disabled={!timeDifference(subItem?.NutritionistMeetingMapping[0]?.scheduledDateTime)} 
                            style={{cursor: "pointer"}}>{isMobile ? "Join" : "Join the Call"}</button>
                              <span
                                className="copy-icon"
                                onClick={() => {
                                  navigator.clipboard.writeText(meetingLink);
                                  setClickedSection(index)
                                  setClickedItem(subIndex)
                                  setIsCopied(true);
                                }}
                                 
                              >
                                {isCopied===true && subIndex===clickedItem && index===clickedSection ?
                                <img src="/icons/copy-green-icon.svg" alt="Copy Icon" /> 
                                :
                                <img src="/icons/copy-icon.svg" alt="Copy Icon" />
                                }
                              
                              {isCopied===true && subIndex===clickedItem && index===clickedSection && <span className="copied-text">Copied</span>}
                                 
                              </span>
                          </ClientCardMeeting>
                        </ClientListCardStyled>
                        )
                      })
                    }
                   
                    </>
                  );
                })
              }
              {/* remove button from bottom */}
              {/* <SendInviteButton>
              <SubmitButton
              className="btn-end"
              onClick={() => history.push('/nutritionist/coaching/invite')}
            >
              <img src="/icons/profile-add.svg" alt="Profile Icon" />
              Send Invite
            </SubmitButton>
            </SendInviteButton> */}
            </>
          ) : (
            isMobile ?
              (
            <NutritionistEmptyPage
            offeringBtn={offersList?true:false}
            offersList={offeringList}
            img={offeringList?"/icons/search.svg":"/icons/calendar-card.svg"}
            alt={"Calendar Icon"}
            title={offeringList? "No clients" : "No upcoming sessions"}
            description={offeringList?
            "Invite your followers to get paid":
            "Book 1 on 1 sessions with followers"}
            description2={offeringList?" 1 on 1 training sessions.": " to scale your business."}
            buttonText={offeringList?"+ Invite client" : "+ Create an offering"}
            buttonText2={offeringList? "Offering" : ""}
            redirectLink={()=> {offeringList?history.push('/nutritionist/coaching/invite'):
            history.push('/nutritionist/coaching/offering/create')}}
            redirectLink2={onOpenModal}
           />)
            : 
            <NutritionistEmptyPage
            offeringBtn={offersList?true:false}
            img={offersList?"/icons/search.svg":"/icons/calendar-card.svg"}
            alt={"Calendar Icon"}
            title={offersList? "No clients" : "No upcoming sessions"}
            description={offersList?
            "Invite your followers to get paid 1 on 1 training sessions. ":
            "Book 1 on 1 sessions with followers to scale your business."}
            buttonText={offersList?"+ Invite Client" : "+ Create an offering"}
            redirectLink={()=> {offersList?history.push('/nutritionist/coaching/invite'):
            history.push('/nutritionist/coaching/offering/create')
           }}/>
          )}
        </ClientListStyled>
        {/* <ClientListStyled>
                    <h3>Non-Active clients</h3>
                    {
                        ActiveClient.map((item, index) => {
                            return(
                                <ClientListCardStyled key={index}>
                                    <ClientCardDetailsStyled>
                                        <img src={item.clientProfile} alt="avtar" />
                                        <h4> {item.clientName} </h4>
                                    </ClientCardDetailsStyled>
                                    <ClientCardActions>
                                        <button type="button" className='btn-icon'> <img src="/icons/client-circle.svg" alt="Client Circle" /> </button>
                                        <button type="button" className='btn-icon'> <img src="/icons/client-message-icon.svg" alt="Client Message" /> </button>
                                    </ClientCardActions>
                                </ClientListCardStyled>
                            )
                        })
                    }
                </ClientListStyled> */}
                <NutritionistOfferingList open={open} onCloseModal={onCloseModal} />
      </MainContentWrapper>
    </ClientCard>
  );
}
