import { useAppContext } from 'context/appContext';
import styled from 'styled-components';

const OrderCardStyle = styled.div`
  background: #ffffff;
  border: 1px solid #e0e3e0;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
  &.active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      background-color: #52c41a;
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      border-radius: 6px 0 0 6px;
    }
    &.voided {
      &::before {
        background-color: #f3972f;
      }
    }
    .order_number,
    .order_amount {
      font-weight: 700 !important;
      color: #51635d !important;
    }
    .order_body {
      .order_details {
        p {
          &.value {
            color: #51635d !important;
          }
        }
      }
    }
  }
  .order_header {
    padding: 14px 14px 10px 16px;
    border-bottom: 1px solid #e0e3e0;
    display: flex;
    justify-content: space-between;
    .order_number,
    .order_amount {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #51635d;
    }
    .order_number1,
    .order_amount {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #51635d;
    }
    .order_number {
      color: #52c41a;
    }
  }
  .order_body {
    padding: 14px 14px 14px 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    .order_details {
      display: flex;
      gap: 2px;
      align-items: center;
      width: 50%;
      justify-content: flex-start;
      white-space: nowrap; /* Prevent text from wrapping */
      &:nth-child(even) {
        justify-content: flex-end;
        margin-left: auto; /* Align container to the right on even rows */
      }
      p {
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;
        color: #949d9b;
        &.value {
          color: #51635d;
          font-weight: 700;
        }
      }
    }
  }
  &.voided {
    position: relative;
    .order_header {
      .order_number,
      .order_amount {
        color: #f3972f;
        font-weight: 700;
      }
    }

    &.active {
      .order_header {
        .order_number {
          color: #f3972f;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .order_body {
      .order_details {
        &:nth-child(even) {
          justify-content: flex-end;
          margin-left: 0; /* Reset margin for even rows on mobile */
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 2;
        }
        &:nth-child(4) {
          order: 4;
        }
      }
    }
  }
`;

export default function OrderCard({ onClick, active = false, order, activeTab, disbursement }) {
  const { isMobile } = useAppContext();

  let periodStart = order?.periodStart;
  let periodEnd = order?.periodEnd;
  const [startYear, startMonth, startDay] = periodStart.split('-').map(Number);
  const [endYear, endMonth, endDay] = periodEnd.split('-').map(Number);

  const date = new Date(startYear, startMonth - 1, startDay);
  const date1 = new Date(endYear, endMonth - 1, endDay);

  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });

  const formattedDate1 = date1.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  return (
    <>
      {!isMobile ? (
        <OrderCardStyle
          onClick={onClick}
          className={`${active ? 'active' : ''} ${activeTab ? activeTab : ''} `}
        >
          <div className="order_header">
            <h2 className="order_number">{order?.provider?.name}</h2>
          </div>
          <div className="order_body">
            <div className="order_details">
              <p className="title">Period:</p>
              <p className="value">
                {formattedDate} – {formattedDate1}
              </p>
            </div>
            <div className="order_details">
              <p className="value">${order?.total}</p>
            </div>
          </div>
        </OrderCardStyle>
      ) : (
        <OrderCardStyle
          onClick={onClick}
          className={`${active ? 'active' : ''} ${activeTab ? activeTab : ''} `}
        >
          <div className="order_header">
            <h2 className="order_number">{order?.provider?.name}</h2>
            <h2 className="order_number1">
              {disbursement?.providerOrder?.orderMain?.friendlyOrderNum}
            </h2>
          </div>
          <div className="order_body">
            <div className="order_details">
              <p className="title">Period:</p>
              <p className="value">
                {formattedDate} – {formattedDate1}
              </p>
            </div>
            <div className="order_details">
              <p className="value">${order?.total}</p>
            </div>
          </div>
        </OrderCardStyle>
      )}
    </>
  );
}
