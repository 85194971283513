import React from 'react'
import { theme } from "shared/theme/theme";
import { CardOneStyle, CardTwoStyle, CardThreeStyle } from './CardStyles';
import { Button } from '../button/Button';
import cardDemoImage from 'assets/card.png';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsFillShareFill } from 'react-icons/bs';
import { FiMoreVertical } from 'react-icons/fi';

export const Card = ({
    type,
    title = "Demo title",
    description = "Card description. Lorem ipsum dolor sit amet, consectetur elit adipiscing, sed do eiusm tem dolor sit amet, consectetur.",
    imageSrc = cardDemoImage,
    preTitle = "Demo pretitle",
    coverImage = cardDemoImage
}) => {
    switch (type) {
        case 1:
            return CardOne({ title, description, imageSrc });
        case 2:
            return CardOne({ title, description, imageSrc, type });
        case 3:
            return CardOne({ title, description, imageSrc, type, preTitle });
        case 4:
            return CardOne({ title, description, imageSrc, type });
        case 5:
            return CardTwo({ title, description, imageSrc });
        case 6:
            return <h2>Card not found</h2>;
        case 7:
            return CardTwo({ title, description, imageSrc, type, coverImage });
        case 8:
            return CardThree({ title, description, imageSrc, type });
        case 9:
            return CardThree({ title, description, imageSrc, type });
        case 10:
            return CardThree({ title, description, type });
        case 11:
            return CardThree({ title, description, imageSrc, type });
        case 12:
            return CardThree({ title, description, imageSrc, type })
        default:
            return <h2>Card not found</h2>;
    }
}

const CardOne = ({ title, description, imageSrc, type = 1, preTitle }) => {
    return (
        <CardOneStyle theme={theme} type={type}>
            <div className='card_image'>
                <img src={imageSrc} style={{ display: 'block' }} />
            </div>
            <div className='card_body'>
                {type === 3 && <p className='card_pretitle'>{preTitle}</p>}
                <h2 className='card_title'>{title}</h2>
                {type !== 3 &&
                    <>
                        <p className='card_desc'>{description}</p>
                        {type !== 4 &&
                            <div className='action_btn'>
                                <Button type={'secondary'} title='Secondary' size='small' />
                                <Button type={'primary'} title='Primary' size='small' />
                            </div>
                        }
                    </>
                }
                {type === 4 &&
                    <div className='like_share'>
                        <div className='icon'>
                            <AiOutlineHeart className='i' /> Like
                        </div>
                        <div className='icon'>
                            <BsFillShareFill className='i' /> Share
                        </div>
                    </div>
                }
            </div>
        </CardOneStyle>
    )
}

const CardTwo = ({ title, imageSrc, description, coverImage, type }) => {
    return (
        <>
            <CardTwoStyle theme={theme} type={type}>
                {type === 7 && <img src={coverImage} className='coverImage' />}
                {type === 5 && <div className='more'>
                    <FiMoreVertical />
                </div>}
                <div className='image'>
                    <img src={imageSrc} />
                </div>
                <div className='card_data'>
                    <h2 className='title'>{title}</h2>
                    <p className='desc'>{description}</p>
                </div>
            </CardTwoStyle>
        </>
    )
}

const CardThree = ({ title, imageSrc, description, type }) => {
    return (
        <>
            <CardThreeStyle theme={theme} type={type}>
                {type !== 10 &&
                    <div className='image'>
                        <img src={imageSrc} />
                    </div>
                }
                <div className='card_data'>
                    <div>
                        <h2 className='title'>{title}</h2>
                        <p className='desc'>{description}</p>
                    </div>
                    {(type !== 11 && type !== 12) && <Button type={'tertiary'} style={{ padding: 0 }} />}
                </div>
            </CardThreeStyle>
        </>
    )
}