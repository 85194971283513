import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const flexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const KitchenMealDetailsWrapper = styled.div`
  .meal-details-header {
    position: relative;
    z-index: 3;
    padding-bottom: 14px;
    .close-image-wrapper {
      position: absolute;
      top: 10px;
      left: 17px;
      z-index: 5;
      width: 36px;
      height: 36px;
      background-color: ${theme.colors.secondary};
      border-radius: 70px;
      ${flexCenter};
    }
    .slick-slider {
      margin-bottom: 16px;
    }
    .meal-image-wrapper {
      height: 210px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slick-dots {
      bottom: -14px;
      li {
        width: 6px;
        height: 6px;
        margin: 0 10px 0 0;
        &:last-child {
          margin: 0;
        }
        &.slick-active button:before {
          color: ${theme.colors.primaryDarker};
        }
        button {
          width: 6px;
          height: 6px;
          padding: 0;
          &:before {
            width: 6px;
            height: 6px;
            color: ${theme.colors.grayLight};
            opacity: 1;
          }
        }
      }
    }
  }

  .meal-details-body {
    padding: 0 16px;
  }

  .meal-desc-wrapper {
    .meal-title {
      color: ${theme.colors.primaryDarker};
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .meal-tags {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      .tag {
        height: 19px;
        padding: 0 8px;
        background-color: #f5f7f8;
        color: ${theme.colors.primaryDarker};
        border-radius: 10px;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }
    .limit-order {
      margin: 8px 0 0;
      color: ${theme.colors.alizarinCrimson};
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
    }
    .meal-desc {
      margin-top: 12px;
      color: ${theme.colors.primaryDefault};
      font-size: 13px;
      font-family: Gilroy;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .macro-split-wrapper {
    padding: 21px 0;
    .macro-title {
      color: ${theme.colors.primaryDarker};
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .macro-pie-wrapper {
      .pie_legend_wrapper {
        padding: 12px 0 0 17px;
        border: 0;
        justify-content: space-between;
      }
      .legend-wrapper,
      .legend-container {
        width: 151px;
      }
      .legend-container {
        position: relative;
      }
      .title-number {
        margin: 0;
        color: ${theme.colors.primaryDarker};
        font-family: Gilroy;
        font-size: 32px !important;
        font-weight: 600;
        line-height: 32px;
      }
      .title-text {
        color: ${theme.colors.primaryDarker};
        font-family: Gilroy;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
      .legend-wrapper .legend-container + .legend-container {
        margin-top: 12px;
      }
      .legend-symbol {
        width: 12px;
        height: 12px;
      }
      .legend-label {
        width: 74px !important;
        margin: 0 0 0 12px;
        color: #404040;
        font-family: Gilroy;
        font-weight: 400;
        line-height: 20px;
      }
      .legend-size {
        position: absolute;
        top: auto;
        right: 0;
      }
      .legend-value {
        color: #0a0a0a;
        font-family: Gilroy;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  @media (min-width: 992px) {
    position: relative;
    width: 466px;

    .meal-details-container {
      overflow-y: auto;
      max-height: calc(100vh - 148px);
    }

    .header-sticky {
      position: absolute;
      inset: 0;
      bottom: auto;
      z-index: 4;
      height: 53.5px;
      padding: 0 16px;
      background-color: ${theme.colors.secondary};
      border-bottom: 1px solid #e1e1e1;
      display: flex;
      align-items: center;
      gap: 44px;
      transition: all 0.1s linear;
      .close-image-wrapper {
        display: flex;
        cursor: pointer;
      }
      .meal-title {
        overflow: hidden;
        color: ${theme.colors.primaryDarker};
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-overflow: ellipsis;
        text-transform: capitalize;
        white-space: nowrap;
      }
    }

    .meal-details-header {
      margin-bottom: 16px;
      padding-bottom: 0;
      .close-image-wrapper {
        top: 16px;
        left: 16px;
        width: 32px;
        height: 32px;
        background-color: white;
        transition: background 0.3s ease;
        :hover {
          background: rgba(255, 255, 255, 0.4);
        }
      }
      .meal-image-wrapper {
        height: 261px;
      }
    }

    .slick-slider {
      display: flex;
      align-items: center;
      .arrow {
        position: absolute;
        top: auto;
        z-index: 2;
        width: 32px;
        height: 32px;
        background: ${theme.colors.secondary};
        border-radius: 70px;
        cursor: pointer;
        ${flexCenter};
      }
      .arrow-prev {
        left: 16px;
      }
      .arrow-next {
        right: 16px;
      }
    }

    .meal-desc-wrapper {
      margin-bottom: 20px;
      .meal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
      }
      .limit-order {
        margin: 12px 0;
      }
    }

    .all-ingredients-wrapper {
      .ingredients-title {
        color: ${theme.colors.primaryDarker};
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }
      .ingredients-contains-wrapper {
        margin: 12px 0 8px;
        display: flex;
        .label {
          width: 68px;
          margin-top: 5px;
          color: ${theme.colors.grayDarker};
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          flex-shrink: 0;
        }
        .tags-wrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
        }
        .display-tag {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .icon-wrapper {
          width: 30px;
          height: 30px;
          background-color: #f5f7f8;
          border-radius: 6px;
          ${flexCenter};
          svg {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            path {
              fill: ${theme.colors.primaryDarker};
            }
          }
        }
        .tag-name {
          color: ${theme.colors.primaryDarker};
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
        }
      }
      .ingredients-name-wrapper {
        color: ${theme.colors.primaryDefault};
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .macro-split-wrapper {
      padding: 20px 0;
      .macro-pie-wrapper {
        .pie_legend_wrapper {
          padding: 16px 0 0 0;
          gap: 51px;
          justify-content: flex-start;
        }
        .legend-wrapper {
          margin-left: 0;
        }
      }
    }

    .add-cart-wrapper {
      height: 84px;
      padding: 0 20px;
      border-top: 1px solid ${theme.colors.grayLight};
      display: flex;
      align-items: center;
      gap: 12px;
      .meal-count {
        width: 181px;
        height: 42px;
      }
      .btn.dark {
        width: 233px;
        height: 42px;
        border-radius: 30px;
        font-family: ${theme.typography.fontGilroy};
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
`;
