import { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import SoicalMediaImage from './SoicalMediaImage';
import { useNutritionistContext } from 'context/nutritionistContext';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

const RegisteredDetitianCardStyle = styled.div`
  padding: 24px 0 16px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px #0000000d;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:45px;
  
  .dietitian-photo {
    width: 75px;
    height: 75px;
    margin-bottom: 11px;
    border-radius: 50%;
  }

  .social-dietatian-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.getCenter {
      justify-content: space-between;
    }

    .social-image-start,
    .social-image-end {
      cursor: pointer;
    }

    .social-image-start div {
      border-radius: 0px 15px 15px 0px;

      img {
        margin-left: 8px;
      }
    }

    .social-image-end div {
      border-radius: 15px 0 0 15px;
      justify-content: flex-end;

      img {
        margin-right: 8px;
      }
    }

    .registered-title {
      text-align: center;

      h4 {
        color: #0b1d17;
        font-size: 18px;
        font-family: 'Roboto';
        line-height: 24px;
      }

      span {
        color: #949d9b;
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }
  }
`;

export default function RegisteredDetitianCard({ dietitianPhoto, name, setMealPlanId, title, socialProfile}) {
  let location = useLocation();
  const { offerData: offeringData } = useNutritionistContext();
  const {nutritionist} = useAppContext();
  const [showInsta, setShowInsta] = useState(false);
  const [showTiktok, setShowTiktok] = useState(false);
  const tk_ROOT = `https://www.tiktok.com/`;
  const insta_ROOT = `https://www.instagram.com/`;

  useEffect(() => {
    if(nutritionist){
      setShowInsta(nutritionist?.socialProfile?.instagram);
      setShowTiktok(nutritionist?.socialProfile?.tiktok);
    } else{
      setShowInsta(offeringData?.nutritionist?.socialProfile?.instagram);
      setShowTiktok(offeringData?.nutritionist?.socialProfile?.tiktok);
    }
  }, [nutritionist, offeringData]);

  function verifiedLink(Link, name){
    const link = Link?.split('//');
    if(link?.length > 1){
        return Link;
    } else{
      if(Link?.includes('.com')){
        var finalLink = 'https://'.concat(Link)
        return finalLink;
      } else{
        var name = name=='instagram'? insta_ROOT : tk_ROOT;
        return name.concat(Link)
      }  
    }
  }

  var {instagram, tiktok } =  socialProfile ? socialProfile : (nutritionist?.socialProfile) || {};
  if(showInsta){instagram = verifiedLink(instagram, 'instagram');}
  if(showTiktok){tiktok= verifiedLink(tiktok, 'tiktok');}
  const history = useHistory();
  const { user } = useAppContext();
  const type = user?.type[0];

  const handleClick = () => {
    history.push('/shop/my-coach/offers');
  };

  return (
    <>
    {/* {type === "user" && <img src="/icons/arrow-left.svg" alt="Arrow Icon" onClick={handleClick} style={{marginBottom:"9px"}}/>} */}
    <RegisteredDetitianCardStyle>
      <img className="dietitian-photo" src={dietitianPhoto} alt="Registered Detitian" />
      <div className={classNames('social-dietatian-wrapper', { getCenter: setMealPlanId })}>
          <div className="social-image-start">
          {(showInsta || socialProfile?.instagram) && 
          <a href={instagram}  target="_blank"> <SoicalMediaImage img="/icons/instagram.svg" /></a>
          }        
          </div>
        <div className="registered-title">
          <h4>{name}</h4>
          <span>{title}</span>
        </div>
          <div className="social-image-end">
            {(showTiktok || socialProfile?.tiktok) && 
            <a href={tiktok}  target="_blank"><SoicalMediaImage img="/icons/tik-tok.svg" /></a> 
            }
          </div>
      </div>
    </RegisteredDetitianCardStyle>
    </>
  );
}
