import styled from 'styled-components';
import {ReactComponent as CloseIcon} from '../../../assets/close-cross-green.svg';
import { useState } from 'react';
import {ReactComponent as MapIcon} from '../../../assets/Map.svg'; 
import ExploreAppModal from './ExploreAppModal';
import { useRequest } from 'hooks/useRequest';
import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import { useVisitorContext } from 'context/visitorContext';

const BringMealModalStyle = styled.div`
font-family: 'Roboto';
font-style: normal;
position: fixed;
top:0;
left: 0;
right: 0;
bottom: 0;
z-index: 122;
padding:20px 32px;
background-color: #fff;
height: 100%;
overflow: auto;
&::-webkit-scrollbar {
  display:none;
}
.close-div{
    display:flex;
    .img{
        position: relative;
        left: -12px;
        top: 0px;
    }
}
.map{
    margin-top:7px;
    text-align: center;
}
.bummer{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635D;
    margin-top:52px;
    text-align: center;
}
.no-meal{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #51635D;
    margin-top:24px;
    text-align: center;
}
.bring-btn{
    margin-top:48px;
    display:flex;
    justify-content:center;
    button{
        width:80.34%;
        height: 62px;
        background: #52C41A;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius: 31px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
}

@media(min-width:992px){
  background:rgba(175,181,180,0.5);
   display:flex;
   justify-content:center;
   align-items:center;
   padding:0;
  .modal-div{
    background:#fff; 
    width:800px;
    height:576px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    @media(max-height:650px){
      height:90%;
    }
  }
  .close-div{
    display: flex;
    justify-content: flex-end;
    img{
      position:relative;
      top:25.25px;
      right:25.25px;
      cursor:pointer;
    }
  }
  .map{
    margin-top:24px;
  }
  .bummer{
    margin-top:48px;
    @media(max-height:650px){
      margin-top:30px;
    }
  }
  .no-meal{
   display:flex;
   justify-content:center;
   margin-top:25px;
   P{
    width:529px;
   }
  }
  .bring-btn{
    margin-top:32px;
    button{
      width:250px;
      cursor:pointer;
       
    }
  }
}

`;

export default function BringMealPrepModal({
  setBringMealModal,
  setAddressModal,
  addressModal,
  value,
  zip,
  deliveryModalAnim,
  setShowAnim,
   
  }){
    const [showbringAnim, setShowbringAnim] = useState(true);
    const [bringMealAnim, setBringMealAnim] = useState(true);
    const [exploreAppModal, setExploreAppModal] = useState(false);
    const { isMobile, user } = useAppContext();
    const [saveNotDeliverableAddress,{isLoading}]=useRequest("unavailable-delivery-locations/","post");
    const userId = user?.profile?.userId || null;
    const { setRowId } = useVisitorContext() || {};

    const saveUserNotDeliverableAddress=async ()=>{
        await saveNotDeliverableAddress({body:{address:value,zipcode:JSON.stringify(zip), ...(userId && { userId: userId }) }})
        .then((result)=>{
          console.log(result)
          if(result?.data?.success && !user){
            setRowId(result?.data?.data?.id);
          }
        }).catch((err)=>{
          console.log(err)
        })
      }

    const handleClick = async () => {
       await saveUserNotDeliverableAddress()
       .then((response)=>{
          console.log(response);
          setExploreAppModal(true);
       })
    }

    const closeBringMealmodal = () => {
      setShowbringAnim(false);
      setTimeout(() => {
        setBringMealModal(false);
      }, 500)
   }
 
    return(
        <BringMealModalStyle className={showbringAnim  ? "fadeInMiddle" : "fadeOutMiddle"}>
          <div className='modal-div'>
        <div className='close-div'>
      {isMobile ?  <CloseIcon onClick={closeBringMealmodal} className='img'/> : <img src='/icons/close-circle3.svg' onClick={closeBringMealmodal}/>}       
       </div>
        <div className='map'>
            <MapIcon/>
        </div>
        <div className='bummer'>
            <p>Aww, bummer!</p>
        </div>
        <div className='no-meal'>
            <p>No meal prep delivery in your area yet, but don't worry! Click 'Bring meal prep to me' and we'll notify you. Meanwhile, check out our other features!</p>
        </div>
        <div className='bring-btn'>
          <button
             className={classNames({disabled:isLoading})}
             disabled={isLoading}
             onClick={handleClick}
           >
            Bring meal prep to me
          </button>
        </div>
        </div>
       
        {
            exploreAppModal &&
            <ExploreAppModal
            exploreAppModal={exploreAppModal}
            setExploreAppModal={setExploreAppModal}
            setBringMealModal={setBringMealModal}
            setAddressModal={setAddressModal}
            addressModal={addressModal}
            setBringMealAnim={setBringMealAnim}
            deliveryModalAnim={deliveryModalAnim}
            bringMealAnim={bringMealAnim}
            setShowbringAnim={setShowbringAnim}
            setShowAnim={setShowAnim}
            />
        }
        </BringMealModalStyle>
    )
}
  