import styled from "styled-components"

const ProviderMethodWrapper = styled.div`
  height: 34px;
  border-top: 1px solid #F6F5F8;
  border-bottom: 1px solid #F6F5F8;
  display: flex;
  align-items: center;
  justify-content: center;
  & + & {
    border-left: 1px solid #F6F5F8;
  }
  .method-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  .provider-method {
    display: flex;
    align-items: center;
    span {
      color: #51635D;
      font-size: 12px;
      line-height: 14px;
      &.method-date {
        margin-left: 5px;
        font-weight: 700
      }
    }
  }
`;

export default function ProviderMethod({methodImg, methodBy, methodDate}) {
  return (
    <ProviderMethodWrapper>
      <img src={`/icons/${methodImg}`} alt="Method icon" className="method-icon" />
      <div className='provider-method'>
        <span>{methodBy}</span><span className='method-date'>{methodDate}</span>
      </div>
    </ProviderMethodWrapper> 
  )
}