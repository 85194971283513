import { useHistory } from 'react-router-dom';

import { useAppContext } from 'context/appContext';
import { Button } from 'components/elements/button/Button';

import { OrderLimitOopsWrapper, MealLimitDetailsWrapper } from './style';

export default function OrderLimitOops({
  mealAlertsList,
  kitchenUsername,
  setMealAlertList,
  setIsModal,
  isModal,
  setMealsExceedLimit,
  setMealNotAvailability,
  text
}) {
  const { isMobile } = useAppContext();
  const history = useHistory();

  const handleClose = () => {
    isMobile ? (setMealAlertList([]), setMealNotAvailability([]), setMealsExceedLimit([])) : history.push(`/store/${kitchenUsername}`);
    setIsModal(false);
  };

  return (
    <OrderLimitOopsWrapper
      open={mealAlertsList?.length && isModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="order-limit-container">
        <h3>Oops!</h3>
        <p>{text}</p>
        <MealLimitDetailsWrapper data-id="MealLimitDetailsWrapper">
          {mealAlertsList?.map((meal) => (
            <div className="meal-limit-container" key={meal?.id}>
              <div className="img-wrapper">
                <img
                  src={meal?.img?.startsWith('http') ? meal?.img : '/images/Kitchen-image.png'}
                  alt="Meal image"
                />
              </div>
              <div className="meal-details">
                <div className="meal-name">{meal?.name}</div>
                <div className="meal-price">${parseFloat(meal?.price / 100).toFixed(2)}</div>
              </div>
            </div>
          ))}
        </MealLimitDetailsWrapper>
        <div className="oops-button-wrapper">
          <Button title="Ok" type="dark" onClick={handleClose} />
        </div>
      </div>
    </OrderLimitOopsWrapper>
  );
}
