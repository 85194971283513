import { memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Pie from 'components/elements/Pie';
import Tag from '../DietaryPreference/Tag';
import Progress from 'components/elements/Progress';
import { motion, AnimatePresence } from 'framer-motion';
import { Modal } from 'components/elements/modal/Modal';
import { ReactComponent as More } from 'icons/more2.svg';
import ActionButton from 'components/elements/ActionButton';
import Toggle from 'components/elements/toggleModule/Toggle';
import { theme } from 'shared/theme/theme';
import { useHistory } from 'react-router-dom';
import {AVAILABLE_TAGS,SUPPORTED_ALLERGEN_TAGS} from '../../../../../.././constants'
import { ItemDetailsWrapper, ItemDetailsExpendedWrapper, ActiveModalWrapper } from './style';
import { useRequest } from 'hooks/useRequest';
import { useKitchenContext } from 'context/kitchenContext';
import NutritionModel from '../Nutrition/NutritionModel';
import { generatePieData } from 'utils';
import ImageViewer from 'components/ImageViewer';
import { useAppContext } from 'context/appContext';

function ItemDetails({
  id,
  img,
  name,
  price,
  category,
  status,
  lastUpdated,
  ingredients,
  items,
  setIsAlertMessage,
  selected,
  updateSelected,
  getItemList,
  nutritionLabel,
  categoryId,
  setIsSearch,
  activePageNumber,
  setSearchInput,
  parentRef
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenAction, setIsOpenAction] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isInactiveModal, setIsInactiveModal] = useState(false);
  const [updateMealApi,{isLoading}]=useRequest();
  const history = useHistory();
  const { setSelectedMealId, setActiveTab,imitateProviderCredentials, kitchenHasSubscription, setKitchenHasSubscription} = useKitchenContext() || {};  
  const providerId = imitateProviderCredentials?.providerId;
  const [deleteMealsApi,{isLoading:isDeleting}]=useRequest(`meal/delete?providerId=${providerId}`,'delete');
  const [showNutritionModel, setShowNutritonModel] = useState(false);
  const { provider, isKitchenHasSubscription } = useAppContext();
  const kitchenId = provider.id;

  const DropdownVariants = {
    hidden: {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },
    visible: {
      opacity: 1,
      height: 'auto',
      overflow: 'visible',
    },
  };
  const nutritionFactData =useMemo(()=>{
    const {fat,protein,carbohydrates}=nutritionLabel;
    return generatePieData(fat?.totalMacroCalories,protein?.totalMacroCalories,carbohydrates?.totalMacroCalories);
  },[nutritionLabel]);

  const progressLines = [
    {
        id: 'Fat',
        label: 'Fat',
        value: `${ingredients?.nutritionFact?.fat||0}`,
        color: '#EE786B',
        macroPercent: ingredients?.nutritionLabel?.fat?.macroPercent
    },
    {
        id: 'Protein',
        label: 'Protein',
        gram: `${ingredients?.nutritionFact?.protein || 0}`,
        color: '#2399BF',
        macroPercent: ingredients?.nutritionLabel?.protein?.macroPercent
    },
    {
        id: 'Carbs',
        label: 'Carbs',
        gram: `${ingredients?.nutritionFact?.carbs || 0}`,
        color: '#8ACEBF',
        macroPercent: ingredients?.nutritionLabel?.carbohydrates?.macroPercent
    },
  ];
  const handleDeleteItem = useCallback(async () => {
    try {
      await deleteMealsApi({body:{mealIds:[id]}})
      setIsDeleteModal(false);
      getItemList();
      setIsAlertMessage(true);
      setIsSearch(false);
      setSearchInput('');
    } catch (error) {
      console.log(error)
    }
  }, [items, isDeleteModal]);

  const handleUpdateMeal = useCallback(async () => {
    const businessProfileId=imitateProviderCredentials?.businessProfileId;
    const path=`meal/update${providerId?`?providerId=${providerId}&businessProfileId=${businessProfileId}`:''}`
    try {
      const body={
        id,
        isActive:status==="active"?'false':'true'
      }
      await updateMealApi({path,method:"patch",body})
      setIsInactiveModal(false);
      getItemList(activePageNumber,'','',providerId);
      setSearchInput('');
    } catch (error) {
      console.log(error)
    }
  }, [items, isInactiveModal,activePageNumber,imitateProviderCredentials])

  const fetchHasSubscriptionData = async () => {
    try {
      if (kitchenId) {
        const hasSubscription = await isKitchenHasSubscription({ kitchenId, mealId: id });
        setKitchenHasSubscription(hasSubscription);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const handleEditClick=()=>{
    setSelectedMealId(id);
    setActiveTab('items');
    fetchHasSubscriptionData();
    history.push(`/kitchen/menu/addItem?categoryId=${categoryId}&mealId=${id}`);
  }

  const handleStatusBtnClick = () => {
    fetchHasSubscriptionData();
      setIsInactiveModal(true);    
  }
const upperCaseTags = [...new Set(AVAILABLE_TAGS.map((tag) => tag.replace(/\s/g, '_').toUpperCase()))];

const dietTags = upperCaseTags.filter((tag) =>
  ingredients?.diets?.includes(tag)
);

  const uniqueAllergens = useMemo(() => {
    const tagMapping = {
      "GLUTEN": "CEREAL/GLUTEN",
      "CEREAL": "CEREAL/GLUTEN",
      "TREE_NUTS": "TREE_NUT",
      "EGGS": "EGG"
    };
    const dIngredients = Array.from(new Set(ingredients?.allergens || [])).map((tag, index) => {
      const formattedTag = tag.replace(/\s/g, '_').toUpperCase();
      const mappedTag = tagMapping[formattedTag] || formattedTag;
      if (SUPPORTED_ALLERGEN_TAGS.includes(mappedTag)) {
        return mappedTag;
      }
    })
    return Array.from(new Set(dIngredients)).filter((item) => item);
  }, [ingredients, SUPPORTED_ALLERGEN_TAGS])

  const inactiveModalDec = useMemo(() => {
    const subscriptionCount = kitchenHasSubscription?.subscriptionCount;
    return `${status === 'inactive' ? "Are you sure to mark this item as active?" : "Are you sure to mark this item as inactive? Inactive item will be removed from the menu."}
            ${kitchenHasSubscription?.hasSubsciption && status === 'active' ? ` Please note that the system detects ${subscriptionCount} active subscriptions for this kitchen, and any changes made may affect their recurring orders.` : ""}`;
  }, [status, kitchenHasSubscription]); 

  const deleteMealModalDesc = useMemo(() => {
    let message = 'Are you sure to delete the item? You can’t undo this action.'
    if (kitchenHasSubscription?.subscriptionCount > 0) {
      message += `Please note that the system detects ${kitchenHasSubscription.subscriptionCount} active subscriptions for this kitchen, and any changes made may affect their recurring orders.`
    }
    return message;
  }, [kitchenHasSubscription?.subscriptionCount]);

  const handleDeleteClick = () => {
    fetchHasSubscriptionData()
    setIsDeleteModal(true)
  }
  return (
    <>
      <ItemDetailsWrapper className={classNames({inactiveItem: status === 'inactive'})} onClick={() => setIsExpanded(!isExpanded)}>
        <div className="item-photo">
          <img
            className={classNames('expanded-icon', { expandedIcon: isExpanded && status === 'active' })}
            src="/icons/Chevron-up.svg"
            alt="icon"
          />
          <div onClick={(e) => e.stopPropagation()}>
            <Toggle
              className="header-checkbox"
              type="checkbox"
              onChange={(e) => {
                updateSelected({ id, selected: e.target.checked });
              }}
              checked={selected}
              toggleColor={theme.colors.primaryDefault}
              size={'18px'}
            />
          </div>
          <ImageViewer className="item-img" src={img} fallbackSrc={'/images/dish.png'} alt="Item-Img" />
        </div>
        <div className="item-name">{name.length > 25 ? `${name.slice(0, 25)}...` : name}</div>
        <div className="item-price">${price}</div>
        <div className="item-category">{category && category?.length > 8 ? `${category.slice(0, 8)}...` : category}</div>
        <div className="item-status">
          <div className="active" />
          {status}
        </div>
        <div className="item-update">
          {new Date(lastUpdated).toLocaleDateString('en-US')}
          <div
            className="dropdown-icon"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenAction(!isOpenAction);
            }}
          >
            <ActionButton
              className="dropdown-action"
              btnText={<More className={classNames({ openDropdown: isOpenAction })} />}
              handleEdit={handleEditClick}
              handleDelete={handleDeleteClick}
              handleMarkAsInActive={handleStatusBtnClick}
              markStatusLabel={status === 'active' ? 'mark as inactive' : 'mark as active'}
              parentRef={parentRef}
              setIsOpenAction={setIsOpenAction}
            />
          </div>
        </div>
      </ItemDetailsWrapper>
      <ItemDetailsExpendedWrapper>
        <AnimatePresence>
          {isExpanded && status === 'active' && (
            <motion.div
              className="DropDownContainer"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={DropdownVariants}
              transition={{ duration: 0.5, type: 'spring', damping: 20 }}
            >
              <div className="item-info-wrapper">
                <div className="item-info-container">
                  <div className="item-info-section">
                    <div className="item-ingredients">
                      <div className="item-ingredients-title">
                        <div className="title">Ingredients</div>
                        <div className="nutrition-label" onClick={()=> {setShowNutritonModel(true)}}>View nutrition label</div>
                      </div>
                      <div className="item-description">{ingredients?.name.join(', ')}</div>
                    </div>
                    {!!uniqueAllergens.length > 0 && (
                      <div className="item-allergens">
                        <div className="title">Allergens</div>
                        <div className="tags-wrapper">
                          {uniqueAllergens.map((tag, index) => (
                            <Tag tag={tag.replace(/_/g, ' ')} key={index} disabled={true} />
                          ))}
                        </div>
                      </div>
                    )}
                    {dietTags.length > 0 && (
                      <div className="item-diets">
                        <div className="title">Diets</div>
                        <div className="tags-wrapper">
                          {dietTags?.map((tag, index) => (
                            <Tag tag={tag} key={index} disabled={true}/>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="item-info-section">
                    <div className="fact-title-wrapper">
                      <div className="fact-title">
                        {ingredients?.nutritionFact && 'Nutrition fact'}
                      </div>
                      <div className="edit" onClick={handleEditClick}>
                        <img src="/icons/edit-black.svg" alt="Edit Icon"  />
                        Edit item
                      </div>
                    </div>
                    {ingredients?.nutritionFact && (
                      <div className="nutrition-fact-details">
                        <div className="pie-container">
                          <Pie
                            width={120}
                            height={120}
                            titleNumber={ingredients?.nutritionFact?.calories != null && !isNaN(ingredients?.nutritionFact?.calories) ? ingredients?.nutritionFact?.calories : '-'}
                            titleText="Calories"
                            data={nutritionFactData.data}
                            innerRadius={0.7}
                            OuterRadiusHover={5}
                            marginBottom={5}
                            marginTop={5}
                            marginLeft={5}
                            marginRight={5}
                            colors={nutritionFactData.colors}
                          />
                        </div>
                        <div className="progress-wrapper">
                          {progressLines.map((progress) => (
                            <Progress
                              key={progress?.id}
                              width={296}
                              label={progress?.label}
                              percent={progress?.macroPercent}
                              strokeWidth={2}
                              strokeColor={progress?.color}
                              trailWidth={2}
                              trailColor="#E9EAEC"
                            />
                          ))}
                          <div className="other-fact">
                            <span>{ingredients?.nutritionFact?.fiber}g</span> fiber,
                            <span> {ingredients?.nutritionFact?.sugar}g</span> sugar, and
                            <span> {ingredients?.nutritionFact?.sodium}mg</span> sodium
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ItemDetailsExpendedWrapper>
      {isDeleteModal && (
        <ActiveModalWrapper>
          <Modal
            title="Are you sure?"
            description= {deleteMealModalDesc}
            secondaryText="Cancel"
            onCancel={() => setIsDeleteModal(false)}
            onClose={() => setIsDeleteModal(false)}
            primaryText="Delete"
            onOk={handleDeleteItem}
            buttonType="danger"
          />
        </ActiveModalWrapper>
      )}
      {isInactiveModal && (
        <ActiveModalWrapper className='inactive-modal-wrapper'>
          <Modal
            title="Are you sure?"
            description={inactiveModalDec}
            secondaryText="Cancel"
            onCancel={() => setIsInactiveModal(false)}
            onClose={() => setIsInactiveModal(false)}
            primaryText="Ok"
            onOk={handleUpdateMeal}
            buttonType="dark"
          />
        </ActiveModalWrapper>
      )}
      {showNutritionModel && (
        <NutritionModel setShowNutritonModel={setShowNutritonModel} nutritionLabel={nutritionLabel}/>
      )}
    </>
  );
}

export default memo(ItemDetails);
