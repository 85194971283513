import classNames from 'classnames';
import styled from 'styled-components';
import Empty from './Empty';
import TrackerCard from 'components/TrackerCard';
import { ReactComponent as CornIcon } from 'assets/shop/corn.svg';
import { ReactComponent as TacoIcon } from 'assets/shop/taco.svg';
import { ReactComponent as FlatbreadIcon } from 'assets/shop/flatbread.svg';
import { ReactComponent as CrossiantIcon } from 'assets/shop/croissant.svg';
import { ReactComponent as Close } from 'assets/shop/close-cross.svg';
import UserMealCard from 'components/elements/MealCard/UserMealCard';
import MealMacrosInfo from './MealMacrosInfo';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'components/elements/button/Button';
import RegularInput from 'components/Input/RegularInput';
import { Modal } from 'react-responsive-modal';
import { useRequest } from 'hooks/useRequest';
import { calculateNutritionPerGram } from 'utils';

const DiaryTagContentWrapper = styled.div`
  &.isDiaryModal {
    padding: 24px 16px;
    height: 100%;
  }
  .diary-list {
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .empty-title + p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; 
  }
  .empty-wrapper > div {
    height: 100% !important;
    padding: 0;
    background-color: rgba(0,0,0,0);
  }
  .diary-meal-wrapper {
    margin: 0 auto;
    padding: 9px 20px 10px;
    display: flex;
    border-bottom: 1px solid #f6f5f8;
    & + & {
      border-top: 1px solid #7070704d;
    }
  }
  .img-wrapper {
    position: relative;
    height: 70px;
    margin-right: 20px;
    img {
      width: 50px;
      height: 100%;
      border-radius: 8px;
      object-fit: contain;
    }
  }
  .meal-content {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pantry-meal-title {
      margin-bottom: 9px;
      color: #0b1d17;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .meal-details > div:first-child {
      margin: 0;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      flex-grow: 1;
    }
    .fresh-card-content {
      width: 100%;
      height: 37px;
    }
    .fresh-card-container {
      width: 100%;
      margin: 0;
      padding: 5px;
      border-right: 1px solid #e0e3e0;
      font-size: 10px;
      &:last-child {
        border: 0;
      }
    }
  }
  .meals__wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    padding-bottom: 10px;
    &:nth-child(2){
      padding-top: 10px;
      border-top: 1px solid #F0F2F4;
    }
    &:last-child{
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }
  .List {
    &::-webkit-scrollbar {
      width: 0;
    }
    div > div::-webkit-scrollbar {
      width: 0;
    }
  }
  .empty-img-wrapper{
    gap: 12px;
    margin-bottom: 24px;
  }
  .empty-title{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000A2C; 
  }
  @media (min-width: 992px) {
    .diary-meal-wrapper {
      width: 100%;
      padding: 15px;
    }
    .diary-list::-webkit-scrollbar {
      width: 8px;
    }
  }
`;

const ServingCaloriesModel = styled.div`
  .modal {
    border-radius: 10px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 14px 0 24px 0px;
    .close-image{
      position: relative;
      left: 16px;
    }
      .container{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0px 8.976px 0px 8px;
        .delete-title{
          color: #0B1D17;
          text-align: center;
          font-family: Gilroy;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .delete-buttons{
          width: 220px;
          align-self: center;      
        }
        .macronutrients{
          margin-top:24px;
        }
        .serving{
          border: none !important;
        }
        .button{
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
          .size{
            color: #00092D !important;
            position: relative;
            right: 14px;        
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;  
          }
        .swiper-slide.swiper-slide-active{
          color: var(--new-000-a-2-c, #000A2C) !Important;
          text-align: center;
          /* Gilroy/Body Small – 12pt Regular */
          font-family: Gilroy;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          
        }
      }
  }
  
`;

const FormGroupStyle = styled.div`
  margin-bottom: 8px;
  width: 100%;
    label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom:9px;
    display: inline-block;
    }
   input {
    height: 44px;
    border-radius: 6px;
    border: 1px solid #E0E3E0;
    :first-child{
     .input-wrapper .suffix{
        right: 26px;
     }
    }
      :hover, :focus {
         border-color: #00092D !important;
      }
      :focus::placeholder {
        color: transparent;
      }
      ::placeholder{
        color: #949D9B;
        font-style: normal;
        font-weight: 500;
      }
    }
    .suffix{
      color: var(--grey-2, #949D9B);
      text-align: right;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  .value {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    }
    &:first-child {
      .input-wrapper .suffix {
        right: 26px;
      }
    }
  
`;


export default function DiaryTagContent({
  diaryMeals = [],
  isDiaryModal,
  setIsLogMealsModal,
  weeklyDiaryMeals,
  diarymealDate,
  setWeeklyDiaryMeals,
  setIsDiaryModal,
  updateFullDiaryMeals,
  removeMealById,
  updatePantryData,
  setActiveDay
}) {
  const [edit, setEdit] = useState(false);
  const [onDelete, setDelete] = useState(false);
  const [onMovePantry, setMovePantry] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [showMacros, setShowMacros] = useState(false);
  const numberQtyConstants = Array.from({length: 11}, (_, i) => i);
  const gramsUnitConstant = Array.from({length: 100}, (_, i) => (i + 1) * 10);
  var fractionalConstants = ["--",1/4,1/2,3/4]; 
  const [macrosInfo, setMacrosInfo] = useState({});
  const [searchQuantity,setSearchQuantity]= useState(1)
  const [changedValues, setChangedValues] = useState({});
  const [moveToPantry] = useRequest();
  const [removeItem] = useRequest();
  const [updateItem] = useRequest('diary/update', 'put');
  const [selectedSliderIndex, setSelectedSliderIndex] = useState(null)
  const [customData, setCustomData] = useState(null);
  const parentRef = useRef(null);

  const customStyles = {
    modalContainer: {
      position: 'relative',
      top: '28%',
      margin: '0 16px',
      overflowY: 'hidden',
      background: 'none !important',
    },
  };

  const deleteCustomStyles = {
    modalContainer: {
      position: 'relative',
      top:  '70%',
      margin: '0 16px',
      overflowY: 'hidden',
      background: 'none !important',
    },
  };

  
  
  const foodDiaryData = [
    {
      id: 0,
      title: 'Breakfast',
      icon: <CornIcon className='icon' />,
      consumedMealType: 'breakfast'
    },
    {
      id: 1,
      title: 'Lunch',
      icon: <TacoIcon className='icon' />,
      consumedMealType: 'lunch'
    },
    {
      id: 2,
      title: 'Dinner',
      icon: <FlatbreadIcon className='icon' />,
      consumedMealType: 'dinner'
    },
    {
      id: 3,
      title: 'Snack',
      icon: <CrossiantIcon className='icon' />,
      consumedMealType: 'snack'
    }
  ]

  const handleMoveToPantry = async (id, mealCategory) => {
    try {
      const path = `diary/removeOrderToDiary/${id}`
      const method = 'put'
      const result = await moveToPantry({ path, method });
      removeMealById(id,mealCategory);
      updatePantryData(selectedMeal?.orderLineId)
      setEdit(false);
      setDelete(false);
      setSelectedMeal(null);
  
    } catch (err) {
      console.log(err, 'err');
    }
  };
  
  const handleDeleteItem = async(id, mealCategory) => {
    try{
      const path = `customMealDiary/delete/${id}`
      const method = 'delete'
      await removeItem({ path, method });
      removeMealById(id,mealCategory);
      setEdit(false);
      setDelete(false);
      setSelectedMeal(null);
    } catch (err) {
      console.log(err, 'err');
    }
  }

  const editServing = useCallback((selectedMeal, selectedSliderIndex) => {
    const servings = selectedMeal?.fatSecretSearchResult?.servings?.serving;
    const servingToUpdate = servings?.find((item) => item?.serving_id === selectedSliderIndex?.serving_id);
    const servingIndex = servings.findIndex((item) => item?.serving_id === selectedSliderIndex?.serving_id);
    const updatedServing = { ...servingToUpdate, selectedQuantity: selectedSliderIndex?.selectedQuantity, measureIndex: selectedSliderIndex?.measureIndex }
    if (servingToUpdate) {
      servings[servingIndex] = updatedServing;
      return servings;
    } else {
      return servings;
    }
  }, [selectedSliderIndex, selectedMeal])
    
  const handleCustomEditItem = useCallback((id, mealCategory, changedValues) => {
    const payload = { id, ...changedValues };
    updateItem({ body: payload })
    .then((result) => {
      const updatedWeeklyDiaryMeals = JSON.parse(JSON.stringify(weeklyDiaryMeals));
      const dateIndex = updatedWeeklyDiaryMeals.findIndex((entry) => entry.date === diarymealDate);
      if (dateIndex !== -1) {
        const meals = updatedWeeklyDiaryMeals[dateIndex].meals;
        const mealList = meals?.[0]?.[mealCategory];
        if (Array.isArray(mealList)) {
          const mealIndex = mealList.findIndex((meal) => meal.id === id);
  
          if (mealIndex !== -1) {
            const updatedMeal = { ...mealList[mealIndex] };
            if (changedValues.calories) {
              updatedMeal.calories = changedValues.calories;
            }
            if (changedValues.carbs) {
              updatedMeal.carbs = changedValues.carbs;
            }
            if (changedValues.fat) {
              updatedMeal.fat = changedValues.fat;
            }
            if (changedValues.protein) {
              updatedMeal.protein = changedValues.protein;
            }
            updateFullDiaryMeals(updatedMeal)

          }
        }
      }
      
      setDelete(false);
      setSelectedMeal(null);
    })
    .catch((err) => {
      console.log(err,'err')
    });

  },[weeklyDiaryMeals, diarymealDate])

  const handleFatSecretEdit = useCallback((id, mealCategory, selectedMeal,selectedSliderIndex) => {
    const {fatSecretSearchResult} = selectedMeal;
    const updatedServings = editServing(selectedMeal,selectedSliderIndex);
    const caloriesValue = Number(Math.round(selectedSliderIndex.calories));
    const fatValue = Number(Math.round(selectedSliderIndex?.fats));
    const proteinsValue = Number(Math.round(selectedSliderIndex?.proteins));
    const carbsValue = Number(Math.round(selectedSliderIndex?.carbs));
  
    const payload = {
      id: selectedMeal?.id,
      calories: isNaN(caloriesValue) ? 0 : caloriesValue,
      fat: isNaN(fatValue) ? 0 : fatValue,
      protein: isNaN(proteinsValue) ? 0 : proteinsValue,
      carbs: isNaN(carbsValue) ? 0 : carbsValue,
      fatSecretSearchResult: {...fatSecretSearchResult,food:{...fatSecretSearchResult.food,food_id:fatSecretSearchResult?.food_id }, 
      servings: {
        serving: updatedServings
      }},
      };
    updateItem({ body: payload })
    .then((result) => {
      const updatedWeeklyDiaryMeals = JSON.parse(JSON.stringify(weeklyDiaryMeals));
      const dateIndex = updatedWeeklyDiaryMeals.findIndex((entry) => entry.date === diarymealDate);
      
      if (dateIndex !== -1) {
        const meals = updatedWeeklyDiaryMeals[dateIndex].meals;
        const mealList = meals?.[0]?.[mealCategory];
      
        if (Array.isArray(mealList)) {
          const mealIndex = mealList.findIndex((meal) => meal.id === id);
      
          if (mealIndex !== -1) {
            const updatedMeal = { ...mealList[mealIndex] };
              updatedMeal.calories = caloriesValue;
              updatedMeal.carbs = carbsValue;
              updatedMeal.fat = fatValue;
              updatedMeal.protein = proteinsValue;
            updateFullDiaryMeals(updatedMeal);
          }
        }
      }
      
      setWeeklyDiaryMeals(updatedWeeklyDiaryMeals);
      setDelete(false);
      setSelectedMeal(null);
    })
    .catch((err) => {
      console.log(err,'err')
    });
  },[selectedMeal,selectedSliderIndex])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "calories" || name === "fat" || name === "carbs" || name === "protein") {
      if (value > 5000 || value < 0) {
        return;
      } else {
        setChangedValues((prevState) => ({ ...prevState, [name]: parseInt(value) }))
      }
    }
    setChangedValues({
      ...changedValues,
      [name]: value,
    });
  };

  const handleEditSave = () => {
    if(selectedMeal?.fatSecretSearchResult?.food_id){
      handleFatSecretEdit(selectedMeal?.id, selectedMeal?.categoryType, selectedMeal, selectedSliderIndex)
    } else {
      handleCustomEditItem(selectedMeal?.id, selectedMeal?.categoryType, changedValues)
    }
    setEdit(false);
    setSelectedMeal(null); 
  }

  const filterServingData = (servingData) => {
    const uniqueServingData = [];
    const seenDescriptions = new Set();

    for (const serving of servingData) {
      if (!seenDescriptions.has(serving.serving_description)) {
        uniqueServingData.push(serving);
        seenDescriptions.add(serving.serving_description);
      }
    }

    return uniqueServingData;
  };
  const servingProps = useMemo(() => {
    if (selectedMeal?.fatSecretSearchResult?.servings?.serving) {
      const serving = selectedMeal.fatSecretSearchResult.servings.serving;
      if (Array.isArray(serving) && serving?.length > 1 ) {
        return filterServingData(serving);
      } 
       
    const servingObj = Array.isArray(serving) ? serving[0] : serving;
    if(!servingObj?.metric_serving_unit){
      return serving;
    }
    const {
      caloriesPerGram,
      fatPerGram,
      proteinPerGram,
      carbsPerGram,
    } = calculateNutritionPerGram(servingObj);
  
    return [
      ...serving,
      {
        ...servingObj,
        calories: caloriesPerGram,
        protein: proteinPerGram,
        fat: fatPerGram,
        carbohydrate: carbsPerGram,
        measurement_description: "g",
        number_of_units: "100.00",
        metric_serving_unit: "g",
        serving_description: "100 g",
        isCustom: true,
      },
    ];
  }
  }, [selectedMeal]);

  useEffect(() =>{
    if(selectedMeal?.fatSecretSearchResult?.servings?.serving?.length){
      const servingWithIsCustom = selectedMeal?.fatSecretSearchResult?.servings.serving.find(
        (serving) => serving?.isCustom === true
      );
      setCustomData(servingWithIsCustom);      
    } else {
      setChangedValues({
        calories: selectedMeal?.calories,
        carbs: selectedMeal?.carbs,
        fat: selectedMeal?.fat,
        protein: selectedMeal?.protein
      })
    }
  },[selectedMeal])

  const onAddPress = () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const diaryMealDateParts = diarymealDate.split("-");
    const year = parseInt(diaryMealDateParts[2], 10);
    const month = parseInt(diaryMealDateParts[0], 10) - 1; // Months are 0-indexed in JavaScript
    const day = parseInt(diaryMealDateParts[1], 10);

    const activeDayMidnight = new Date(year, month, day);
    activeDayMidnight.setHours(0, 0, 0, 0);

    const dayDifference = Math.floor((currentDate - activeDayMidnight) / (1000 * 60 * 60 * 24));

    if (dayDifference >= 0 && dayDifference <= 2) {
        setActiveDay(activeDayMidnight);
        setIsLogMealsModal(true);
        setIsDiaryModal(false);
    } else {
        console.log("Active day is not within the allowed range. Not proceeding.");
    }
};


  const handleEditClick = (meal) => {
    setEdit(true),
    setSelectedMeal(meal)
  }
  return (
    <>
    <DiaryTagContentWrapper className={classNames({ isDiaryModal })}>
      {Object.keys(diaryMeals?.[0]).length < 1 && (
        <div className="empty-wrapper">
          <Empty
            showImage={false}
            title="There are no meals in your diary"
            info="Start logging meals by adding them from the log menu"
          />
        </div>
      )}
      {foodDiaryData?.map((diary, index) => (
        <div
          key={diary?.id}
          style={{ marginBottom: foodDiaryData?.length - 1 !== index ? '10px' : '0' }}
        >
          <TrackerCard icon={diary?.icon} title={diary?.title} consumedMealCategory={diary?.consumedMealType} onPressAdd={onAddPress}/>
          {Object.keys(diaryMeals?.[0]).length > 0 &&
            Object.keys(diaryMeals?.[0]).map((objKey) =>{
              if(String(diary?.title).toLowerCase() === String(objKey).toLowerCase()){
                return diaryMeals?.[0]?.[objKey]?.map((meal, index) => 
                <div className='meals__wrapper' ref={parentRef}>
                <UserMealCard 
                  mealName={meal?.name} 
                  options={true} 
                  iconPositionEnd={14}
                  cals={meal?.calories}
                  fat={meal?.fat}
                  protein={meal?.protein}
                  carbs={meal?.carbs}
                  image={meal?.images?.length > 0 && meal.images[0]}
                  quantity={meal?.quantity}
                  providerId={meal.providerId}
                  onEdit={()=>handleEditClick(meal)}
                  onDelete={() => {setDelete(true), setSelectedMeal(meal)}}
                  handleMoveToPantry={() => {setMovePantry(true), setSelectedMeal(meal)}} 
                  parentRef={parentRef}
                  />
                  </div>
                )
              }
              return null;
            })
          }
        </div>
      ))}
        <Modal open={edit} blockScroll={false} center styles={{ modalContainer: customStyles.modalContainer }} showCloseIcon={false} >
          <ServingCaloriesModel>
            <div className='modal'>
              <div className='container'>
                {selectedMeal?.fatSecretSearchResult?.food_id ?
                  <MealMacrosInfo
                    showMacros={showMacros}
                    servings={servingProps}
                    showServingInfo={open}
                    setShowServingInfo={open}
                    numberQtyConstants={numberQtyConstants}
                    fractionalConstants={fractionalConstants}
                    setMacrosInfo={setMacrosInfo}
                    setSearchQuantity={setSearchQuantity}
                    gramsUnitConstant={gramsUnitConstant}
                    setSelectedSliderIndex={setSelectedSliderIndex}
                    hideProgressLine={true}
                    customData={customData}
                    isDiaryEdit={true}
                  // currentDayinfo={currentDayinfo}
                  />
                  :
                  <div className='macronutrients'>
                    <FormGroupStyle>
                      <label>Calories</label>
                      <RegularInput
                        type="tel"
                        name="calories"
                        placeholder="0"
                        pattern="\d*"
                        suffix="Cals"
                        value={changedValues?.calories ?? selectedMeal?.calories}
                        onChange={handleChange}
                      />
                    </FormGroupStyle>
                    <FormGroupStyle style={{ display: "flex", gap: "12px" }}>
                      <div>
                        <label>Carbs</label>
                        <RegularInput
                          type="tel"
                          name="carbs"
                          placeholder="0"
                          pattern="\d*"
                          suffix="g"
                          value={changedValues?.carbs ?? selectedMeal?.carbs}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label>Fat</label>
                        <RegularInput
                          type="tel"
                          name="fat"
                          placeholder="0"
                          pattern="\d*"
                          suffix="g"
                          value={changedValues?.fat ?? selectedMeal?.fat}
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label>Protein</label>
                        <RegularInput
                          type="tel"
                          name="protein"
                          placeholder="0"
                          pattern="\d*"
                          suffix="g"
                          value={changedValues?.protein ?? selectedMeal?.protein}
                          onChange={handleChange}
                        />
                      </div>
                    </FormGroupStyle>
                  </div>
                }
                <div>
                  <div className='button'>
                    <Button type={'mobile_save'} title="Save" onClick={handleEditSave} />
                    <Button type={'mobile_secondary'} title="Cancel" onClick={() => {setEdit(false), setSelectedMeal(null)}} />
                  </div>
                </div>
              </div>
            </div>
          </ServingCaloriesModel>
        </Modal>
        <Modal open={onDelete || onMovePantry} blockScroll={false} center styles={{ modalContainer: deleteCustomStyles.modalContainer }} showCloseIcon={false}>
        <ServingCaloriesModel onDelete={onDelete}>
            <div className='modal'>
            <Close className='close-image' onClick={() => {setDelete(false), setSelectedMeal(null), setMovePantry(false)}}/>
              <div className='container'>
                <p className='delete-title'>Are you sure you’d like <br/>{onMovePantry ? 'to move this item to pantry?' : 'to remove this item?'}</p>
              <div className='delete-buttons'>
                  <div className='button'>
                    <Button type={'mobile_save'} title="Yes" onClick={() => {onDelete ? (setDelete(false), setSelectedMeal(null), handleDeleteItem(selectedMeal?.id, selectedMeal?.categoryType)) : (setMovePantry(false), handleMoveToPantry(selectedMeal?.id, selectedMeal?.categoryType))}} />
                    <Button type={'mobile_secondary'} title="Cancel" onClick={() => {onDelete ? (setDelete(false), setSelectedMeal(null)) : (setMovePantry(false),setSelectedMeal(null)) }} />
                  </div>
                </div>
              </div>
              </div>
              </ServingCaloriesModel>
        </Modal>
      </DiaryTagContentWrapper>
    </>
  );
}


