import { useShopContext } from 'context/shopContext';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SelectOptions from './SelectOptions';
import produce from 'immer';
import './style.css';
import ErrorComponent from 'components/ErrorComponent';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import SetClientCart from 'hooks/setClientCart';
import Select from 'react-select';
import AddressDeliveryModal from './AddressDeliveryModal';
import BringMealPrepModal from './BringMealPrepModal';
import {ReactComponent as SelectArrow} from './../../../assets/SelectArrow.svg'

const GooglePlacesAutocompleteStyle = styled.div`
  .css-9ekey3-singleValue {
    padding: 0 35px 0 0;
  }

  .css-yk16xz-control .css-1hwfws3,
  .css-1pahdxg-control .css-1hwfws3 {
    padding: 0 30px 0 14px !important;
  }
  .css-yk16xz-control,
  .css-1hwfws3,
  .css-1pahdxg-control {
    height: 44px;
    line-height: 44px !important;
    div {
      height: 44px;
      line-height: 44px;
    }
    input {
      position: relative;
      top: -7px;
      height: 44px;
      background-color: transparent;
    }
    .css-8sibub-placeholder {
      top: 20px;
      line-height: 44px;
    }
    .css-eeok4o {
      top: 50%;
      height: 44px;
      margin-top: 0;
      padding: 0 0 0 25px;
      line-height: 44px;
    }
  }
  .css-1pahdxg-control {
    height: 44px;
    border: 1px solid #52c41a;
    box-shadow: none;
    &:hover {
      border-color: #52c41a;
    }
  }
  .css-26l3qy-menu {
    postion: relative;
    top: 43px;
    bottom: auto;
    z-index: 4;
    border: 1px solid #52c41a;
    box-shadow: none;
    & > div > div {
      &:first-child {
        background-color: #52c41a5e;
        color: #000;
      }
      &:hover {
        background-color: #52c41a5e;
      }
    }
  }
  @media(max-width: 991px){
    .css-26l3qy-menu {
      overflow:scroll;
      max-height: 75px;
    }

  }
`
const MealformStyle = styled.div`
   .delivery-input{
       height:44px;
       border: 1px solid #E0E3E0 !important;
       border-radius: 6px;
       background: #FFFFFF;
       width:100%;
       padding-left:14px;
       text-align:left;
       font-weight: 400;
       font-size: 14px;
       line-height: 16px;
       color: #949D9B;
   }
   .address_select{
    .css-yk16xz-control, .css-xd1qmr-control {
      width:100%;
      height:44px;
      border: 1px solid #E0E3E0;
      border-radius: 6px;
      margin-top:7px;
   }
   .select__value-container{
     padding:0px 14px !important;
     height: 44px;
     div:nth-child(2){
      margin:0;
      padding:0;
     }
   }
   .css-1wa3eu0-placeholder , .css-1uccc91-singleValue{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
   }
   .css-1wa3eu0-placeholder{
     color:#949D9B !important; 
   }
   .css-1uccc91-singleValue{
     color: #51635D;
     text-transform:capitalize;
     top:50%;
   } 
   .css-2613qy-menu{
      color:#949D9B !important;
   }
   .select__indicators{
    .image{
      width:18px;
      height:18px;
      margin-right: 15px;
    }
   }
   .select__control--is-focused{
    border: 1px solid #52C41A;
    box-shadow: unset;
  }
  .select__input{
    input{
      height:44px;
      margin-bottom:0;
    }
  }
  .css-26l3qy-menu{
   @media(max-height:800px){
    max-height:106px;
    overflow:auto;
    .select__menu-list{
      max-height:106px;
      overflow:auto;
    }
   }
   @media(min-height:800px) and (max-height:991px){
    max-height:120px;
    overflow:auto;
    .select__menu-list{
      max-height:120px;
      overflow:auto;
    }
   }
    
  }
  }
  .create-meal-form{
    .radio-button-container{
      border-right: 0px;
      margin: 0;

      &:last-child{
        border: 1.5px solid #E0E3E0;
        @media (max-width: 991px){
          border: 1.5px solid #c3cbc9;
        }
      }
    }

    .radio-checked{
      border: 1.5px solid #97DC76 !important;
      &:hover{
        margin: 0;
      }

      + div{
        border-left: 0 !important;
      }
    }
  }
  .address-container{
    .title{
      font-weight: 700;
    }
  }
`
;

export default function CreateMealPlanForm() {
  const history = useHistory();
  const { settings = {}, updateSettings, providerListCalled} = useShopContext();
  const {user,setUser,userDeliveryAddress, setUserDeliveryAddress,value,setValue,options,selectedValue,setSelectedValue}=useAppContext()
  const [error, setError] = useState(null);
  const [days, setDays] = useState(7);
  const [meals, setMeals] = useState(3);
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('New York City, Brooklyn');
  const [state, setState] = useState('New York');
  const [zip, setZip] = useState('');
  const [userAddressLatLong, setUserAddressLatLong] = useState([]);
  const { isMobile, isVisitor,isGuest ,isWebView} = useAppContext();
  const mobInnerBox2 = isMobile ? 'inner-box2' : undefined;
  const toastId = useRef(null);
  const [saveDeliveryAddress,{isLoading}]=useRequest("user-delivery-address/","post")
  const {loadClientCart} = SetClientCart();
  const [typedValue, setTypedValue] = useState('');
  const [addressModal, setAddressModal] = useState(false);
  const [addressFormModal, setAddressFormModal] = useState(false);
  const [checked, setChecked] = useState(true);
  const [getTaxRate] = useRequest();
  const [taxRate, setTaxRate] = useState(0);
  const [taxError, setTaxError] = useState(null);
  const [bringMealModal, setBringMealModal] = useState(false);
  const [showAnim, setShowAnim] = useState(true);
  const selectRef = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [getZip, setGetZip] = useState(false);
    
  const defaultForm = {
    address:{},
    apartment:'',
    type:'home',
    customType:null,
    instruction:'',   
   }
 const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    setDays(settings.days);
    setMeals(settings.meals);
    const { address, address2, zipcode, city, state, userAddressLatLong } = settings.userInfo;
    setAddress(address);
    setUserAddressLatLong(userAddressLatLong);
    setAddress2(address2);
    setZip(zipcode);
    setCity(city);
    setState(state);
  }, []);

  const saveAddress=async ()=>{
    try{
    const result  = await saveDeliveryAddress({body:{address:{...form.address,apartment:form.apartment,instruction:form.instruction},type:form.type,customType:form.customType}});
        setUserDeliveryAddress([...userDeliveryAddress, result?.data])
    
      toast.success('Address saved successfully');
      console.log(result)
    }catch (err){
      console.log(err)
    }
  }


  const handleClick = async () => {
    if (!zip){
      toastId.current = toast.error(`Couldn't find zip code for this address`);
      return setError('Please try to add zip code with your address.');
    }
    if(taxError && !isMobile){
      return setBringMealModal(true);
    }
    if(value && addressFormModal && isMobile && checked){
      isWebView && isWebView.postMessage(JSON.stringify({ event: "ModelClosed" }))
      await saveAddress()
    }
    setError(null);
   
  
   await updateSettings(
      produce((draft) => {
        draft.days = days;
        draft.meals = meals;
        draft.userInfo.userAddressLatLong = userAddressLatLong;
        draft.userInfo.address = address;
        draft.userInfo.address2 = address2;
        draft.userInfo.zipcode = zip;
        draft.userInfo.city = city;
        draft.userInfo.state = state;
      }),
    )
    setAddressModal(false)
    setAddressFormModal(false)
     history.push('/shop/providers');
    
    window.scrollTo(0, 0);
  };
  const handleSelect = async (value) => {
    try {
      setGetZip(true);
      const [place] = await geocodeByPlaceId(value?.value?.place_id);
      const userLatLong = await getLatLng(place);
      setUserAddressLatLong(userLatLong);
      const { long_name: postalCode = '' } =
        place.address_components.find((c) => c.types.includes('postal_code')) || {};
      const { long_name: state = '' } =
        place.address_components.find((c) => c.types.includes('administrative_area_level_1')) || {};
      const { long_name: city = '' } =
        place.address_components.find((c) => c.types.includes('locality')) ||
        place.address_components.find((c) => c.types.includes('sublocality_level_1')) ||
        {};
      setTaxRate(null);
      setTaxError(null);
      setCity(city);
      setState(state);
      setZip(postalCode);
      setAddress(value?.value?.structured_formatting?.main_text);
      if(postalCode){
        const res = await getTaxRate({ path: `payment/tax/${postalCode}` });
         if (res?.status === false) {
           setTaxError(res.message);
           console.log(res);
         }
         
         else {
           setTaxRate(res.rate);
           console.log(res);
         }   
       }
      setGetZip(false);
    } catch (error) {
      toast.error("oops! something went wrong")
      console.log(error)
    }

  };
  useEffect(() => {
    if(value) {
     handleSelect(value);
    }
  }, [value]);

  let draftedcity = settings?.userInfo ? settings?.userInfo.city : '';
  let draftedState = settings?.userInfo ? settings?.userInfo.state : '';
  let add = settings?.userInfo ? settings?.userInfo?.address : '';
  const newFullAddress = settings?.userInfo?.address
    ? add + ', ' + draftedcity + ', ' + draftedState
    : '';

    const DropdownIndicator = (props) => {
      return (
        typedValue ?  <img className='image' onClick={() => { setSelectedValue('') }} style={{ width: '16px' }} src='/icons/close-cross.svg' /> : <SelectArrow className='image'/>
      )
    };

   const selectValue = (e) => {
    setTypedValue(e);
  }

  const handleSelectChange = (selectedItem) => {
    if(selectedItem && selectedItem.label === 'Enter a new address'){
     isWebView && isWebView.postMessage(JSON.stringify({ event: "ModelOpened" }))
      setValue(null)
      setAddressModal(true);
      setShowAnim(true);
    }
    else if (selectedItem) {
      setSelectedValue(selectedItem.label)
      setValue(selectedItem.address.address.address)
      setMenuIsOpen(false);
    } else {
      setSelectedValue('')
      setValue('')
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#51635D' : "#51635D",
      backgroundColor: state.isFocused ? "#ffffff" : "#ffffff",
      textAlign: 'left',
      fontSize: '14px',
      textTransform:'capitalize'
    })
  }

  useEffect(() => {
    
    if ((addressModal || addressFormModal) && isMobile){
        document.body.classList.add('hide-scroll');
        window.scrollTo(0,0);
        document.body.style.touchAction = "none";
    }else {
        document.body.classList.remove('hide-scroll');
        document.body.style.touchAction = "unset";
    }
 }, [addressModal,addressFormModal])

  return (
    <MealformStyle>
    <div className="create-meal-form">
      <div className={`select-meals-days ${mobInnerBox2}`}>
        <SelectOptions
          id="meals"
          Icon= "/icons/dinner-gray.svg"
          title="How many meals per day?"
          options={[2, 3, 4]}
          value={meals}
          setValue={setMeals}
          valueCondition={1}
          customOptionVal={[5,9]}
        />
        <SelectOptions
          id="days"
          Icon="/icons/calendar-gray.svg"
          title={isMobile ? "For how many days?" : "What's your ideal plan length days?"}
          options={[4, 7, 12]}
          value={days}
          setValue={setDays}
          valueCondition={4}
          customOptionVal={[13,31]}
        />
        { isMobile ?
         (<div className='no-user-div'><p className='no-user'> = {meals * days} meals </p> </div>)
        :
       ( <h4>
          This will result in a plan of{' '}
          <span style={{ color: '#52c41a' }}> {meals * days} meals </span>
          <img src="/icons/food1.svg" alt="Food" className="foodi" />
          <img src="/icons/food2.svg" alt="Food" className="foodi" />
          <img src="/icons/food3.svg" alt="Food" className="foodi" />
        </h4>) }
      </div>
      <div className={mobInnerBox2}>
        <div className="address-wrapper">
          <div className="address-container">
            <div className="title">Where's it arriving?</div>
           {isMobile
            ?
           <>
                  {options?.length > 1 ?
                    <div
                      onTouchStart={() => {
                        setMenuIsOpen(true);
                        selectRef.current.focus();
                      }}
                      onBlur={() => setMenuIsOpen(false)}
                      tabIndex="-1"
                    >
                      <Select
                        className="address_select"
                        classNamePrefix="select"
                        isSearchable={true}
                        options={options}
                        placeholder={selectedValue ? selectedValue === 'Enter a new address' ? 'Home' : selectedValue : 'Home'}
                        styles={customStyles}
                        onInputChange={selectValue}
                        onChange={(selectedItem) => {
                          handleSelectChange(selectedItem);
                        }}
                        name="Address"
                        value={options.find(obj => obj.label === selectedValue)}
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        touchThreshold={100}
                        tabIndex={0}
                        menuIsOpen={menuIsOpen}
                        ref={selectRef}
                      />
                    </div>
                    :
                    <>
                      <img src="/icons/location.svg" className="locationIcon" alt="" />
                      <button
                        onClick={() => { setAddressModal(true), setShowAnim(true) }}
                        bordered
                        className='delivery-input'
                      >
                        {localStorage.getItem('login-type', JSON.stringify('selfMealPlanOnboarding')) ? "Enter a new address" : "Delivery address"}
                      </button>
                    </>
                  }
           </>
            :
            <>
            <img src="/icons/location.svg" className="locationIcon" alt="" />
            <GooglePlacesAutocompleteStyle>
              <GooglePlacesAutocomplete
                GooglePlacesDetailsQuery={{ fields: 'geometry' }}
                fetchDetails={true}
                selectProps={{
                  value,
                  placeholder:
                    !newFullAddress ||
                    newFullAddress.trim() === '' ||
                    (newFullAddress.trim() || '').length === 0 || 
                    isMobile && userDeliveryAddress?.length < 1
                      ? 'Delivery address'
                      : newFullAddress,
                      noOptionsMessage: () => null,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      height: '44x',
                      lineHeight: '44px',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: 'none',
                      height: '47px',
                      lineHeight: '47px',
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      height: '47px',
                      lineHeight: '47px',
                      display: 'none',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      height: '47px',
                      lineHeight: '40px',
                      top: '50%',
                      paddingLeft: '15px',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      top: '50%',
                      marginTop: '0px',
                      paddingLeft: '15px',
                      height: '47px',
                      lineHeight: '37px',
                    }),
                  },
                  onChange: setValue,
                }}
              />
            </GooglePlacesAutocompleteStyle>
            </>

            }
          </div>
        </div>
        <div className="button-wrapper">
        {isMobile  ?
        <button 
        className={classNames({
          disabled: !selectedValue || !(options?.length>= 2)|| getZip,
        })}
        disabled={!selectedValue || !(options?.length>= 2)|| getZip}
        onClick={handleClick}>
          Next
        </button>
        :
        <button
            className={classNames({
              disabled: (!address && !newFullAddress) || getZip,
            })}
            disabled={(!address && !newFullAddress) || getZip}
            onClick={handleClick}
          >
            Next
          </button>}
        </div>
        {error && <ErrorComponent error={error} />}
      </div>
    </div>
    {addressModal &&
     <AddressDeliveryModal
      addressModal={addressModal}
      setAddressModal={setAddressModal} 
      value={value} 
      setValue={setValue}
      form={form}
      setForm={setForm}
      addressFormModal={addressFormModal}
      setAddressFormModal={setAddressFormModal}
      handleClick={handleClick}
      checked={checked}
      setChecked={setChecked}
      isLoading={isLoading}
      zip={zip}
      taxError={taxError}
      bringMealModal={bringMealModal}
      setBringMealModal={setBringMealModal}
      showAnim={showAnim}
      setShowAnim={setShowAnim}
      getZip={getZip}
      setSelectedValue={setSelectedValue}
      />}
        {
              bringMealModal && !isMobile &&
              <BringMealPrepModal
              bringMealModal={bringMealModal}
              setBringMealModal={setBringMealModal}
               setAddressModal={setAddressModal}
               addressModal={addressModal}
              value={value}
              zip={zip}
              setShowAnim={setShowAnim}
              />
            }
    </MealformStyle>
  );
}