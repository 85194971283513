import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/close-gray.svg';
import { theme } from 'shared/theme/theme';
import Calender from './calender';
import { Button } from 'components/elements/button/Button';
import Fade from '@mui/material/Fade';

const ScheduleModalStyle = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 99;
  position: fixed;
  inset: 0;
  background-color: rgba(40, 50, 65, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    max-width: 562px;
    max-height: 452px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${theme?.colors?.grayLighter};
    background: ${theme?.colors?.white};
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    padding: 24px 24px 26px;
    display: flex;
    position: absolute;
    flex-direction: column;
  }
  .modal__header {
    .title {
      color: ${theme?.colors?.blackDefault};
      font-size: ${theme?.typography?.fontLarge};
      font-family: ${theme.typography.fontGilroy};
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      svg {
        display: block;
        width: 20px;
        cursor: pointer;
      }
    }
    .desc{
        color: ${theme?.colors?.blackDefault};
        font-size: ${theme?.typography?.fontRegular};
        font-family: ${theme.typography.fontGilroy};
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 16px;
    }
}
.save_button{
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    position: absolute;
    bottom: 27px;
    right: 23px;
    .secondary{
      border: 1px solid ${theme?.colors?.primaryDark} !Important;
      font-family: ${theme.typography.fontGilroy};
    }
    .dark{
      border: none !Important;
      font-family: ${theme.typography.fontGilroy};
      :disabled{
        background-color: ${theme.colors.grayLighter} !Important;
      }
    }
}
`

export default function SchduleModal({ isAddActive, setIsAddActive, mealData, setMealData }) {
    const [selectedDates, setSelectedDates] = useState(mealData?.rotationMenuSchedule || []);
    const arraysAreEqual = (arr1, arr2) => {
        if (arr1 && arr1.length === 0) arr1 = undefined;
        if (arr2 && arr2.length === 0) arr2 = undefined;  
        if (!arr1 && !arr2) return true;
        if (!arr1 || !arr2) return false;
        if (arr1.length !== arr2.length) return false;
    
        const sortedArr1 = arr1.slice().sort();
        const sortedArr2 = arr2.slice().sort();
    
        return sortedArr1.every((element, index) => element === sortedArr2[index]);
    }
    const handleSave = (selectedDates) => {
        setMealData(prevDetails => ({
            ...prevDetails,
            rotationMenuSchedule: selectedDates
        }));
        setIsAddActive(false);
    }
    
return (
    <ScheduleModalStyle theme={theme}>
      <Fade in={isAddActive}>
        <div className="modal">
          <div className="modal__header">
            <h2 className="title">
                Set up item schedule <Close onClick={() => setIsAddActive(false)}/>
            </h2>
            <h2 className="desc">
                Product will be available on 
            </h2>
          </div>
          <Calender selectedDates={selectedDates} setSelectedDates={setSelectedDates}/>
          <div className='save_button'>
            <Button type={'secondary'} title='Cancel' onClick={() => setIsAddActive(false)}/>
            <Button type={'dark'} title='Save schedule' onClick={() => handleSave(selectedDates)} isDisabled={arraysAreEqual(selectedDates, mealData?.rotationMenuSchedule)}/>
          </div>
        </div>
      </Fade>
    </ScheduleModalStyle>
  )
}
