import { useCallback, useState } from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import { displayDateTime } from 'utils';
import { theme } from 'shared/theme/theme';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { Modal } from 'components/elements/modal/Modal';
import { useKitchenContext } from 'context/kitchenContext';
import { Button } from 'components/elements/button/Button';
import OrderItems from 'components/elements/orderDetail/OrderItems';
import DeliveryInfo from 'components/elements/orderDetail/DeliveryInfo';
import { OrderActionModal } from 'pages/provider/storefront/orders/style';
import OrderNumberStatus from 'components/elements/orderDetail/OrderNumberStatus';
import { SplitPanelWrapper } from './style';

export default function SplitPanel({
  selectedOrderPanel,
  setSelectedOrderPanel,
  setAlertData,
  setIsOrderStatusChanged,
  setSelectedOrder,
  setIsSplit,
  selectedTabId,
  orderDataPageNumber,
  imitateProviderId,
  orderNumber,
  filterQuery,
  sort,
  sortBy,
}) {
  const {
    friendlyOrderNum,
    orderStatus,
    orderedAt,
    dateTimeOrdered,
    providers,
    orderMainId: orderId,
    orderLine,
    user,
  } = selectedOrderPanel;
  const { kitchenProvider } = useKitchenContext();
  const { isSuper } = useAppContext();
  const findProviderIndex = providers?.findIndex(
    (provider) => provider?.Provider?.id === kitchenProvider?.id,
  );
  const {
    selectedDate,
    selectedTime,
    isDelivery,
    deliveryData,
    phoneNumber,
    orderNote,
    isRecurringOrder,
    street,
    city,
    state,
    zip,
    Provider: { id: providerId } = {},
  } = Object.keys(selectedOrderPanel)?.length && providers[findProviderIndex];
  const [isOpen, setIsOpen] = useState(false);
  const orderStatusIndex = orderStatus?.findIndex((item) => item?.providerId === providerId);
  const orderType = Object.keys(selectedOrderPanel).length && orderStatus[orderStatusIndex]?.action;
  const formattedTime = moment(dateTimeOrdered).format('LT');
  const [acceptSelectedOrder, { isLoading }] = useRequest(`fulfill`, 'post');
  const { name, lastName } = Object.keys(selectedOrderPanel).length && user;
  const customerName = name + (lastName?.length ? ' ' + lastName : '');
  const method = isDelivery ? 'Delivery' : 'Pickup';

  const handleSave = useCallback(
    async (status, orderId) => {
      if (isLoading) return;
      try {
        const result = await acceptSelectedOrder({
          body: { newStatus: status, providerId, orderIds: [orderId] },
        });
        if (result) {
          setTimeout(
            () => showSuccessMessage(`You have successfully ${status} the new order`),
            1000,
          );
          setIsOrderStatusChanged((prev) => !prev);
          setSelectedOrderPanel({});
          setIsSplit(false);
        } else {
          setTimeout(() => showErrorMessage('Error occurred while saving changes'), 1000);
        }
        setIsOpen(false);
      } catch (e) {
        setIsOpen(false);
        showErrorMessage('Error occurred while saving changes');
      }
    },
    [isLoading, selectedOrderPanel],
  );

  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  return (
    <SplitPanelWrapper>
      {Object.keys(selectedOrderPanel).length ? (
        <div className="split-panel-container">
          <div className="order-info-controls">
            <div className="order-number-status">
              <OrderNumberStatus
                orderNumber={friendlyOrderNum}
                orderType={orderType}
                displayOrderDate={displayDateTime(orderedAt)}
                formattedTime={formattedTime}
                isRecurringOrder={isRecurringOrder}
              />
            </div>
            <div className="order-controls-wrapper">
              {(orderType === 'pending' || orderType === 'accepted') && (
                <Button
                  title={orderType === 'pending' ? 'Accept' : 'Complete'}
                  type="dark"
                  iconLeft={
                    <img
                      src={`/icons/${
                        orderType === 'pending' ? 'receipt-right' : 'receipt-edit'
                      }.svg`}
                      alt="Recept Icon"
                    />
                  }
                  onClick={() =>
                    orderType === 'pending'
                      ? handleSave('accepted', orderId)
                      : handleSave('completed', orderId)
                  }
                />
              )}
              {orderType === 'pending' && (
                <Tooltip
                  overlayClassName="kitchen-orders-tooltip"
                  placement="top"
                  overlay="Reject order"
                  arrowContent={<div />}
                >
                  <div className="remove-order-wrapper" onClick={() => setIsOpen(true)}>
                    <img
                      src="/icons/receipt-remove.svg"
                      alt="Receipt Icon"
                      className="receipt-remove-icon"
                    />
                  </div>
                </Tooltip>
              )}
              <Tooltip
                overlayClassName="kitchen-orders-tooltip"
                placement="top"
                overlay="In new tab"
                arrowContent={<div />}
              >
                <a
                  href={`/kitchen/orders/orderDetails${
                    isSuper ? `?providerId=${imitateProviderId}` : ''
                  }`}
                  target="_blank"
                >
                  <div
                    className="export-icon-wrapper"
                    onClick={() => {
                      localStorage.setItem('selectedOrder', JSON.stringify(selectedOrderPanel));
                      localStorage.setItem(
                        'selectedOrderDetails',
                        JSON.stringify({
                          orderDataPageNumber,
                          selectedTabId,
                          orderNumber,
                          filterQuery,
                          sort,
                          sortBy
                        }),
                      );
                    }}
                  >
                    <img src="/icons/export-button.svg" alt="Export Icon" />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="delivery-info-wrapper">
            <DeliveryInfo
              customerName={customerName}
              phoneNumber={phoneNumber}
              method={method}
              displaySelectedDate={displayDateTime(selectedDate)}
              selectedTime={selectedTime}
              deliveryData={deliveryData}
              orderNote={orderNote}
              street={street}
              city={city}
              state={state}
              zip={zip}
              orderType={orderType}
            />
          </div>
          <div className="order-items-wrapper">
            <OrderItems orderLine={orderLine} />
          </div>
        </div>
      ) : (
        <div className="no-order-selected">No order selected</div>
      )}
      {isOpen && (
        <OrderActionModal>
          <Modal
            description={'Are you sure you want to reject this order?'}
            width="500px"
            paddingBottom="30px"
            paddingTop="4px"
            color={theme.colors.blackDefault}
            isOpen={isOpen}
            onOk={() => handleSave('refused', orderId)}
            onClose={() => setIsOpen(!isOpen)}
            onCancel={() => setIsOpen(!isOpen)}
            title={'Reject the order'}
            primaryText={`Reject order`}
            secondaryText={'Cancel'}
            isBackdropEnabled={true}
            buttonType={'dark'}
            type="null"
          />
        </OrderActionModal>
      )}
    </SplitPanelWrapper>
  );
}
