import React from 'react';
import Meals from './Meals';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const HeaderWrapper = styled.div`
  h1,
  a {
    display: flex;
    justify-content: center;
    padding: 16px;
  }
`;

export default function Components({ provider, components, setComponents, allergies }) {
  return (
    <div>
      <HeaderWrapper>
        {!components?.length ? (
          <>
            <h1>No data available</h1>
            <NavLink exact to="/provider/manage-combo-component">
              Click here to create components
            </NavLink>
          </>
        ) : (
          <h1>Manage Combo List</h1>
        )}
      </HeaderWrapper>
      {components.map(({ name: componentName, id: componentId, comboSelection }) => (
        <div key={componentId}>
          <h2 style={{ textAlign: 'center' }}>{componentName}</h2>
          <Meals
            provider={provider}
            meals={comboSelection.map(({ selectionName: name, id, ...rest }) => ({
              name,
              id,
              ...rest,
              componentId,
              componentName,
            }))}
            setMeals={setComponents}
            allergies={allergies}
            isCombo
            parentComponentId={componentId}
            parentComponentName={componentName}
          />
        </div>
      ))}
    </div>
  );
}
