import styled from 'styled-components';

export const FormContainer = styled.div`
  // display: grid;
  /* height: calc(100vh - 200px); */
  /* min-height: calc(100vh - 128px); */
  height: 100%;
  justify-content: center;

  &.form-container{
    @media(min-width:992px){
     width: 39%;
     background: #FFFFFF;
     box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
     border-radius: 20px;
     .styled-form{
      margin:0;
      padding: 20px 40px;
      min-height: auto;
     .subheading, .already{
      color: #51635D;
     }
      label{
        display: flex;
        color: #51635D;
        gap: 2px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
      .round{
        display: flex;
        align-items: center;
      }
     }
    }
  }
  .already {
    margin-top: 20px;
    text-align: center;
  }

  a {
    color: var(--nutritt-green) !important;
    text-decoration: none !important;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .dietitian-card-wrapper {
      margin-bottom: 37px;
      margin-top: 20px;
    }
    .already {
      margin-top: 12px;
      color: #51635d;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
  }
`;
export const StyledForm = styled.form`
width: 100%;
min-height: calc(100vh - 216px);
margin: 75px auto 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-bottom: 24px;

.heading {
  font-weight: 700;
  margin: 16px 0 23px 0;
  font-size: 24px;
  line-height: 42px;
  font-family: 'Roboto';
  font-style: normal;
}
.form-wrapper{
  width: 39%;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 40px 40px 30px 40px;

  div:first-child {
    margin-top: 0px !important;
  }

  .google-login{
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 0 15% 0 15%;

    button{
      width: 100%;
      height: 50px;
      display: flex;
      gap: 6%;
      align-items: center;
      background: rgb(0, 133, 247) !important;
      border-radius: 28px !important;
      padding: 2px !important;
      box-shadow: none !important;
      opacity: 1 !important;
  
      svg{
        display: flex;
        justify-content: center;
        margin: auto;
      }
      div{
        width: 20%;
        height: 46px;
        display: flex;
        border-radius: 26px 0px 0px 26px !important;
        background: rgb(255, 255, 255) !important;
      }
      span{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
  }

  .or-login{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
    
      padding: 20px 0 20px 0;
    }

  }

  input{
    background: #FFFFFF;
    border: 1px solid #E0E3E0;
  }

  label{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    color: #51635D;
    padding: 2px 0 7px 0;
    gap: 2px;
  }

  label[for=agree]{
    margin-left: 33px !important;
    margin-top: -15px !important;
    font-weight: 400 !important;
  }

  #agree{
    width:4%;
    margin-top: -25px;
  }

  div input{
    ::placeholder {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #949D9B;
    }
  }

  .signup{
    width: 100%;
    margin-top: 14px;
    display: flex;
    gap: 3px;
    align-items: baseline;
    justify-content: center;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    p{
      color: #51635D;
    }
    a {
      color: #52C41A !important;
      float: right;
    }
  }

  .terms-wrapper{
    width: 100%;
    margin-top: 6px;
    font-family: 'Roboto'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    display: flex;
    gap: 3px;
    p{
      color: #51635D;
      margin-bottom: 36px;
    }
    a{
      color: #52C41A !important;
      float: right;
    }
    .policy{
      display:flex;
    }
  }

  .forget-password-wrapper{
    width: 100%;
    margin-top: 5px;
    a{
      font-family: 'Roboto'!important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #52C41A !important;
      float: right;
    }
  }     
}

  @media (max-width: 991px) {
    width: 90%;
    margin-top: 57px;
    padding-bottom: 0 !important;
    .heading {
      margin: 16px 0 5px 0;
    }
    .form-wrapper{
      overflow: hidden;
      width: 100%;
      padding: 28px 25px 28px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
      margin: 23px auto;

      .google-login{
        padding: 0!important;
      }

      label[for=agree]{
        margin-left: 0 !important;
        margin-top: 0 !important;
        font-weight: 400 !important;
      }
    }

    & > div {
      position: relative;
    }
  }
`;

export const HasAccount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: inherit;
  padding-bottom: 32px;
  gap: 3px;
  align-items: baseline;
  justify-content: center;
  font-family: 'Roboto'!important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  p{
    color: #51635D;
  }
  a {
    color: #52C41A !important;
    float: right;
  }
`;



