import { useEffect } from 'react';
import styled from 'styled-components';
import CartLine from './CartLine';
import SummaryTotals from './SummaryTotals';

const StyledProvider = styled.div`
  background: white;
  padding: 20px;
`;
const StyledRow = styled.div`
  display: grid;
  /* grid-template-columns: 120px 170px 70px 170px 150px; */
  grid-template-columns: 120px 3fr 70px 200px min-content;
  align-items: center;
  gap: 10px;
  img {
    border-radius: 5px;
    margin-left: 15px;
  }
`;
export default function ProviderSummary({ cart, taxRate, providerSettings }) {
  const { method, date, time, name } = providerSettings;

  // useEffect(() => {
  //   console.log({ cart, settings, selectedSchedules });
  // }, []);
  return (
    <div style={{ marginTop: 20 }}>
      <StyledProvider>
        <h3>{name}</h3>
        <b>Method</b> {method}
        <br />
        <b>Date</b> {date}
        <br />
        <b>Time</b> {time}
        <br />
        <div>
          <hr />
          <StyledRow>
            <h5 style={{ gridColumn: '1/3' }}>Meal</h5>
            <h5>Qty</h5>
            <h5>Nutritions</h5>
            <h5 style={{ justifySelf: 'end', marginRight: 10 }}>Price</h5>
          </StyledRow>
          <hr style={{ marginTop: 0 }} />
        </div>
        {cart.map((cartItem) => (
          <CartLine key={cartItem.id} StyledRow={StyledRow} cartItem={cartItem} />
        ))}
        <hr />
        <SummaryTotals cart={cart} taxRate={taxRate} />
      </StyledProvider>
    </div>
  );
}
