import { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { sort } from 'ramda';
import { toast } from 'react-toastify';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import NutritionistPlan from 'components/Nutritionist/NutritionistPlan';
import { useAppContext } from '../../context/appContext';
import { useRequest } from '../../hooks/useRequest';
import Progress from '../../components/Progress';
import { useNutritionistContext } from '../../context/nutritionistContext';

const NutritionistPlanStyle = styled.div`
  margin-left: 300px;
  padding: 70px 68px 30px;

  .create-plan-wrapper {
    margin-bottom: 46px;

    button {
      width: 219px;
      height: 57px;
      background-color: #52c41a;
      border-radius: 8px;
      color: #ffffff;
      font-size: 18px;
      cursor: pointer;
    }
  }

  h3 {
    margin-bottom: 21px;
    color: #0b1c18;
    font-size: 24px;
    font-weight: 500;
  }

  .plan-message-wrapper {
    p {
      margin-bottom: 24px;
      color: #000000;
      font-size: 24px;
    }

    textarea {
      width: 100%;
      min-height: 251px;
      margin-bottom: 36px;
      padding: 16px 21px;
      font-size: 24px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;
      resize: none;

      ::placeholder {
        color: #c3cbc9;
        font-size: 24px;
      }
    }

    button {
      width: 102px;
      height: 57px;
      background-color: #52c41a;
      color: #fff;
      font-size: 18px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    width: 90%;
    margin: 60px auto;
    padding: 52px 0;

    .create-plan-wrapper {
      margin-bottom: 30px;

      button {
        height: 44px;
        width: 100%;
        border-radius: 6px;
      }
    }

    h3,
    .plan-message-wrapper p {
      margin-bottom: 15px;
      font-size: 20px;
    }

    .plan-message-wrapper {
      textarea {
        min-height: 200px;
        margin-bottom: 24px;
        padding: 10px;
        font-size: 16px;

        ::placeholder {
          font-size: 16px;
        }
      }

      button {
        width: 100%;
        height: 44px;
        border-radius: 6px;
      }
    }
  }
`;

{
  TabPanel;
}

export default function NutritionistMealPlan() {
  const { nutritionist: nutritionistData, updateNutritionist, allTemplates, isDietPlanLoading } = useNutritionistContext();
  const [value, setValue] = useState(0);
  const [mantra, setMantra] = useState(nutritionistData?.nutritionist?.mantra || '');
  const [postData] = useRequest(
    `nutritionist/profile/edit/${nutritionistData?.nutritionist?.id}`,
    'post',
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSave = useCallback(async () => {
    if (mantra !== nutritionistData?.nutritionist?.mantra) {
      const result = await postData({
        body: {
          mantra,
        },
      });
      updateNutritionist({
        ...nutritionistData,
        nutritionist: {
          ...nutritionistData.nutritionist,
          mantra,
        },
      });
      if (result?.id) {
        toast.success('Mantra updated successfully');
      } else {
        toast.success('Oops! something went wrong');
      }
    }
  }, [mantra, nutritionistData?.nutritionist?.mantra]);

  const dietTemplates = useMemo(() => {
    const dietTemplates = allTemplates?.dietTemplates;
    return dietTemplates ? sort((a, b) => a?.name?.localeCompare(b?.name), dietTemplates) : [];
  }, [allTemplates?.dietTemplates]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <NutritionistPlanStyle>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="My Plans" />
        <Tab label="My Mantra" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className="nutritionist-plan-wrapper">
          <div className="create-plan-wrapper">
            <Link to="/nutritionist/meal-plan/new">
              <button>Create a New Plan</button>
            </Link>
          </div>
          {isDietPlanLoading ? (
            <Progress />
          ) : dietTemplates?.length ? (
            <>
              <h3>My Plans</h3>
              {dietTemplates?.map((prop) => (
                <NutritionistPlan {...prop} />
              ))}
            </>
          ) : (
            <h3>Create a plan to get started</h3>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="plan-message-wrapper">
          <p>Leave a message to tell your users why they should follow YOUR PROGRAM.</p>
          <form>
            <textarea
              placeholder="E.g. Working with people who have different nutrition purpose, I….."
              onChange={(e) => {
                setMantra(e.target.value);
              }}
              value={mantra}
            />
            <button type="button" disabled={!mantra} onClick={handleSave}>
              Save
            </button>
          </form>
        </div>
      </TabPanel>
    </NutritionistPlanStyle>
  );
}
