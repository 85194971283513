import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const AccordionWrapperStyled = styled.div`
  max-width: 900px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  .MuiPaper-root {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.grayLight};
    border-radius: 6px !important;
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    padding: 24px;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    color: ${theme.colors.blackDefault};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .Mui-expanded {
    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
    margin-top: -5px;
  }
`;
