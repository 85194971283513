import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { ReactComponent as ShoppingCart } from 'icons/shopping-cart-dark.svg';
import { ReactComponent as Place } from 'assets/shop/place.svg';
import { ReactComponent as ChevronDown } from 'assets/shop/chevron-down.svg';
import { theme } from 'shared/theme/theme';
import { useHistory, useLocation } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import { motion, AnimatePresence } from 'framer-motion';
import { BottomSheet } from 'react-spring-bottom-sheet';
import AddressesList from 'pages/shop/Addresses/AddressesList';
import 'react-spring-bottom-sheet/dist/style.css';
import { useAppContext } from 'context/appContext';
import Alert from 'components/elements/alert/Alert';
import { Button } from 'components/elements/button/Button';
import SideHeaderMenu from './SideHeaderMenu';
import { useVisitorContext } from 'context/visitorContext';
import DesktopCart from 'pages/shop/cart/DesktopCart';
import {useKitchenDeliveryInfo} from 'hooks/useKitchenDeliveryInfo';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShopHeaderStyle = styled.header`
  position: fixed;
  inset: 0;
  bottom: auto;
  transform: translatey(-1px);
  z-index: 3;
  height: 48px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 16px;
  padding-right: 20px;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayLight};
  background: ${({ theme }) => theme?.colors?.white};
  justify-content: space-between;
  .left-side {
    overflow: hidden;
  }
  .user_location {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 24px;
      height: 24px;
      ${FlexCenter};
      margin-right: 10px;
    }
    .location {
      overflow: hidden;
      .title {
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        color: ${({ theme }) => theme?.colors?.primaryDarker};
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
      }
      .address {
        color: ${({ theme }) => theme?.colors?.primaryDarker};
        font-size: ${({ theme }) => theme?.typography?.fontSmall};
        font-weight: 500;
        line-height: normal;
        ${FlexCenter};
        column-gap: 2px;
        .chev_down {
          display: block;
          font-size: 16px;
          color: ${({ theme }) => theme?.colors?.grayDarker};
        }
      }
      .address-desc {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }
  .cartIcon {
    position: relative;
    .icon {
      display: block;
    }
    .cart_count {
      min-width: 12px;
      height: 12px;
      border-radius: 8px;
      background-color: #ff0000;
      position: absolute;
      font-size: 8px;
      line-height: 13.5px;
      font-weight: 700;
      color: #fff;
      top: -4px;
      left: calc(100% - 9px);
      padding-left: 4px;
      padding-right: 4px;
      text-align: center;
    }
  }

  .alert {
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    .alert_bar {
      max-width: 100%;
      min-width: 300px;
    }
  }

  @media (min-width: 992px) {
    height: 78px;
    padding: 0 24px;
    border-color: ${({ theme }) => theme?.colors?.lightGrey};
    gap: 12px;
    z-index: 5;
    .left-side {
      display: flex;
    }
    .navigation-brand-section {
      display: flex;
      align-items: center;
      .navigation-icon-wrapper {
        margin-right: 16px;
        display: flex;
        cursor: pointer;
      }
      .nutritt-brand {
        display: flex;
        cursor: pointer;
      }
      .divider {
        height: 24px;
        margin: 0 24px 0 20px;
        border-right: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
      }
    }
    .user_location {
      gap: 10px;
      .icon {
        margin: 0;
      }
      .location {
        .title {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
    .cart-button-wrapper {
      display: flex;
      gap: 12px;
      button {
        width: 140px;
        height: 42px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .btn.dark {
        border-radius: 21px;
        background-color: ${({ theme }) => theme?.colors?.primaryDarker};
        .title-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .title {
          display: flex;
          span {
            width: 20px;
            margin-left: 3px;
            display: flex;
          }
        }
        &:disabled {
          background-color: ${({ theme }) => theme?.colors?.primaryDarker} !important;
          color: ${({ theme }) => theme?.colors?.secondary} !important;
          cursor: not-allowed;
        }
      }
    }
  }
`;
const AddressModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 6;
  background-color: #0b1d17a6;
  display: flex;
  align-items: center;
  justify-content: center;
  .address-mask {
    position: fixed;
    inset: 0;
  }
  .address-motion {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-color: ${theme.colors.secondary};
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;
export const CartDesktopWrapper = styled.div`
  .cart-desktop-mask {
    position: fixed;
    inset: 0;
    z-index: 5;
    height: 100vh;
  }
  .cart-desktop-container {
    position: fixed;
    top: 0;
    right: -400px;
    z-index: 6;
    width: 336px;
    height: 100vh;
    border-left: 1px solid ${theme?.colors?.lightGrey};
    background: ${theme.colors.secondary};
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.14);
    transition: all 0.4s linear;
    &.isCart {
      right: 0;
    }
    & > div {
      height: 100vh;
      max-height: 100vh;
    }
    .other__providers__cart {
      overflow: auto;
      height: calc(100vh - 179px);
      padding-top: 13px;
    }
  }
  .checkout__multiple__orders {
    position: absolute;
    width: 100%;
    button {
      width: 100%;
    }
  }
  .empty_div {
    height: auto;
    margin-top: 60px;
    svg {
      width: 200px;
      height: 200px;
    }
  }
  .add__button button {
    width: 100%;
  }
`;

const ShopHeader = () => {
  const { userDeliveryAddress, isMobile, selectedValue, isAddress, setIsAddress, setIsSideHeader, isSideHeader, user, headerAddress, setHeaderAddress, changeAddress, setIsCart, isCart, setSelectedProviderId, isKitchenDeliveryAvailable } = useAppContext();
  const { cartCount: cartCountFromContext, settings, kitchensList } = (user && !user?.type?.includes('provider')) ? useShopContext() : useVisitorContext();
  const history = useHistory();
  const {pathname} = useLocation();
  const {isCurrentKitchenDeliveryAvailable} = useKitchenDeliveryInfo();
  const noAddressRoutes = ['/shop/kitchen-home', '/visitor/kitchens'];

  const cartCount = useMemo(() => {
    return cartCountFromContext;
  }, [cartCountFromContext]);

  useEffect(() => {
    if(userDeliveryAddress?.length){
      const defaultAddress = userDeliveryAddress?.find(address => address?.id === selectedValue);
      setHeaderAddress(defaultAddress || userDeliveryAddress[0]);
    } else {
      setHeaderAddress({});
    }
  }, [userDeliveryAddress, selectedValue])

  const handleClick = () => {
    if (!user) {
      history.push('/account/home');
    }
  };

  useEffect(() => {
    if(changeAddress){
      setIsAddress(true);
    }
  }, [changeAddress])

  useEffect(() => {
    // Have to prevent the user from closing the form;
    if(kitchensList?.length){
      if((noAddressRoutes.includes(pathname) || window.location.pathname.split('/').includes("store")) && (headerAddress && Object?.keys(headerAddress)?.length == 0)){
        setIsAddress(true);
      }else{
        setIsAddress(false);
      }
    }
    
    return () => {
      setIsAddress(false);
    }

  }, [headerAddress, kitchensList])

  useEffect(() => {
    if(!isCurrentKitchenDeliveryAvailable && !isKitchenDeliveryAvailable) {
      setSelectedProviderId();
    }
  }, [isCurrentKitchenDeliveryAvailable, isKitchenDeliveryAvailable])

  return (
    <>
      <ShopHeaderStyle theme={theme}>
        <section className='left-side'>
        {!isMobile && (
          <div className="navigation-brand-section">
            <div className="navigation-icon-wrapper" onClick={() => setIsSideHeader(true)}>
              <img src="/icons/menu-black.svg" alt="Menu Icon" />
            </div>
            <SideHeaderMenu setIsSideMenu={setIsSideHeader} isSideMenu={isSideHeader} setIsAddress={setIsAddress} />
            <div className="nutritt-brand" onClick={() => history.push('/')}>
              <img src="/images/nutritt-logo-2.svg" alt="Logo" />
            </div>
            <div className="divider" />
          </div>
        )}
        {/* {user &&   */}
        <div className="user_location" onClick={() => setIsAddress(true)}>
          <div className="icon">
            <Place />
          </div>
          <div className="location">
            <h3 className="title">{headerAddress?.type || userDeliveryAddress[0]?.type}</h3>
            <p className="address">
              <div className="address-desc">
                {headerAddress?.address?.address?.value?.description}
              </div>
              {isMobile && (
                <span>
                  <ChevronDown className="chev_down" />
                </span>
              )}
            </p>
          </div>

          {(isMobile && user) ? (
            <BottomSheet
              open={isAddress}
              onDismiss={() => setIsAddress(false)}
              scrollLocking={false}
              expandOnContentDrag={true}
              snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
              className="addresses-bottomSheet"
            >
              <AddressesList
                open={isAddress}
                setOpen={setIsAddress}
                selectHeaderAddress={(address) => setHeaderAddress(address)}
              />
            </BottomSheet>
          ) : (
            <div className="angle-down-wrapper">
              <img src="/icons/angle-bottom-gray.svg" alt="Angle Icon" />
            </div>
          )}
        </div>
        {/* } */}
        </section>
        {isMobile ? (
          <div className="cartIcon" onClick={() => history.push('/shop/cartScreen')}>
            <ShoppingCart className="icon" />
            {cartCount > 0 && <div className="cart_count">{cartCount}</div>}
          </div>
        ) : (
          <div className="cart-button-wrapper">
            {!user && <Button title="Log in" type="mobile_secondary" onClick={handleClick}/>}
            <Button
              title={
                <div className="title-wrapper">
                  <img src="/icons/cart-white.svg" alt="Cart Icon" />
                  <div className="title">Cart ・ <span>{cartCount}</span></div>
                </div>
              }
              isDisabled={pathname.includes(`/store/${pathname.split('/')[2]}`) && ((isCurrentKitchenDeliveryAvailable && isKitchenDeliveryAvailable) || (settings?.provider && settings?.provider?.hasOwnProperty('approved') && !settings?.provider?.approved))}
              type="dark"
              onClick={() => setIsCart(true)}
            />
            <CartDesktopWrapper>
              {isCart && <div className="cart-desktop-mask" onClick={() => setIsCart(false)} />}
              <div className={classNames('cart-desktop-container', { isCart })}>
                <DesktopCart setIsCart={setIsCart} />
              </div>
            </CartDesktopWrapper>
          </div>
        )}
      </ShopHeaderStyle>
      <AnimatePresence>
        {(isAddress && !isMobile) && (
          <AddressModalWrapper>
            <div className='address-mask' onClick={() => {
              if(!user && Object?.keys(headerAddress)?.length <= 0){
                return;
              }
              setIsAddress(false)
            }} />
            <motion.div
              initial={{ opacity: 0, }}
              exit={{ opacity: 0  }}
              animate={{ opacity: 1}}
              className="address-motion"
            >
              <AddressesList
                setOpen={setIsAddress}
                selectHeaderAddress={(address) => setHeaderAddress(address)}
              />
            </motion.div>
          </AddressModalWrapper>
        )}
      </AnimatePresence>
    </>
  );
};

export default ShopHeader;
