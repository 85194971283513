import { useCallback, useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Input from 'components/Input';
import { DAYS_OF_WEEK } from '../../constants';
import styled from 'styled-components';
import { assocPath } from 'ramda';
import { set, differenceInMinutes } from 'date-fns';
import {toast} from "react-toastify";

const StyledGrid = styled.div`
  display: grid;
  /* grid-template-columns: repeat(7, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  justify-items: center;
  padding: 10px;
  /* @media (max-width: 1020px) {
    grid-template-columns: 150px 150px 150px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  @media (max-width: 800px) {
    grid-template-columns: 150px;
    gap: 20px;
    justify-content: center;
  } */
  max-height: 300px;
  overflow: auto;
  background: white;
`;

const normalizeTimeString = (ts) => (ts?.length < 5 ? '0' + ts : ts);
const ScheduleItem = ({
  start = '00:00',
  end = '23:59',
  lead,
  cutoffDay = 7,
  cutoffTime = '00:00',
  handleChange,
  disabled,
} = {}) => {
  const days = [...DAYS_OF_WEEK, 'no cutoff day']
  const toPair = useCallback((timeString = '') => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return { hours, minutes };
  }, []);

  const onChange = useCallback(
    (e) => {
      const name = e.target.name;
      let value = e.target.value;
      if (name === 'end') {
        if (!start) {
          setTimeout(() => {
            handleChange({ name, value });
            handleChange({ name: 'start', value });
          }, 2000);
          return;
        }
        const { hours: startHours, minutes: startMinutes } = toPair(start);
        const { hours: endHours, minutes: endMinutes } = toPair(value);
        const startDate = set(new Date(), {
          hours: startHours,
          minutes: startMinutes,
          milliseconds: 0,
        });
        const endDate = set(new Date(), { hours: endHours, minutes: endMinutes, milliseconds: 0 });
        const diff = differenceInMinutes(endDate, startDate);
        if (diff < 0) {
          alert('End time cannot be less than start time');
          return;
        }
      } else if (name === 'start') {
        if (!end) {
          setTimeout(() => {
            handleChange({ name, value });
            handleChange({ name: 'end', value });
          }, 2000);
          return;
        }
        const { hours: startHours, minutes: startMinutes } = toPair(value);
        const { hours: endHours, minutes: endMinutes } = toPair(end);
        const startDate = set(new Date(), {
          hours: startHours,
          minutes: startMinutes,
          milliseconds: 0,
        });
        const endDate = set(new Date(), { hours: endHours, minutes: endMinutes, milliseconds: 0 });
        const diff = differenceInMinutes(startDate, endDate);
        if (diff > 0) {
          alert('Start time cannot be greater than end time');
          return;
        }
      } else if(name === 'cutoffDay' && value === '7'){
          value = undefined;
          setTimeout(() => {
              handleChange({name: 'cutoffTime', value: '00:00'});
              toast.info('cutoffTime value has been removed');
          }, 500);
      } else if (name === 'cutoffDay' && value !== '7' && (!cutoffTime || cutoffTime === '00:00')) {
          setTimeout(() => {
              handleChange({name: 'cutoffTime', value: '17:00'});
              toast.info('cutoffTime default value has been set');
          }, 500);
      }
      handleChange({ name, value });
    },
    [start, end],
  );

  const startValue = normalizeTimeString(start);
  const endValue = normalizeTimeString(end);

  return (
    <StyledGrid>
      <Input
        label="From"
        name="start"
        value={startValue}
        onChange={onChange}
        type="time"
        bordered
        disabled={disabled}
      />
      <Input
        label="To"
        name="end"
        value={endValue}
        onChange={onChange}
        type="time"
        bordered
        disabled={disabled}
      />
      <Input
        label="Lead Time"
        name="lead"
        value={lead}
        onChange={onChange}
        type="number"
        bordered
        disabled={disabled}
      />
      <Input
        label="Cutoff Day"
        name="cutoffDay"
        value={cutoffDay}
        onChange={onChange}
        type="select"
        options={days.map((d, i) => ({ label: d, value: i }))}
        bordered
        disabled={disabled}
      />
      <Input
        label="Cutoff Time"
        name="cutoffTime"
        value={normalizeTimeString(cutoffTime)}
        onChange={onChange}
        type="time"
        bordered
        disabled={disabled}
      />
    </StyledGrid>
  );
};

const ScheduleDay = ({ handleChange, schedule: { pickup = {}, delivery = {} } = {} }) => {
  const [isPickupOn, setIsPickupOn] = useState(null);
  const [isDeliveryOn, setIsDeliveryOn] = useState(null);
  const pickupHandler = handleChange('pickup');
  const deliveryHandler = handleChange('delivery');

  useEffect(() => {
    const { isPickupOn = false } = pickup;
    const { isDeliveryOn = false } = delivery;
    setIsPickupOn(isPickupOn);
    setIsDeliveryOn(isDeliveryOn);
  }, [pickup, delivery]);

  const pickupSwitchHandler = useCallback((e) => {
    setIsPickupOn(e.target.checked);
    pickupHandler({ name: 'isPickupOn', value: e.target.checked });
  }, []);

  const deliverySwitchHandler = useCallback((e) => {
    setIsDeliveryOn(e.target.checked);
    deliveryHandler({ name: 'isDeliveryOn', value: e.target.checked });
  }, []);

  return (
    <div>
      Pickup
      <Switch
        checked={isPickupOn}
        onChange={pickupSwitchHandler}
        name="isPickupOn"
        color="primary"
      />
      <ScheduleItem handleChange={pickupHandler} {...pickup} disabled={!isPickupOn} />
      Delivery
      <Switch
        checked={isDeliveryOn}
        onChange={deliverySwitchHandler}
        name="isDeliveryOn"
        color="primary"
      />
      <ScheduleItem handleChange={deliveryHandler} {...delivery} disabled={!isDeliveryOn} />
    </div>
  );
};

const ScheduleWeek = ({ schedule, setSchedule }) => {
  const handleChange =
    (day) =>
    (type) =>
    ({ name, value }) =>
      setSchedule((s) => assocPath([day, type, name], value, s));
  return DAYS_OF_WEEK.map((d) => (
    <div key={d}>
      <h1>{d.toUpperCase()}</h1>
      <ScheduleDay day={d} handleChange={handleChange(d)} schedule={schedule[d]} />
    </div>
  ));
};
export default ScheduleWeek;
