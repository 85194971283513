import React from 'react'
import { PrimaryButtonStyle, DangerButtonStyle, SecondaryButtonStyle, TertiaryButtonStyle, DarkButtonStyle } from './ButtonStyles'
import styled from 'styled-components'
import { theme } from '../../../shared/theme/theme';

const ButtonStyle = styled.div`
    display: inline-block;
    .btn{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 10px 16px;
        border-radius: 8px;
        position: relative;
        overflow: clip;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        font-family: ${({ theme }) => theme?.typography?.fontInter};
        &::after{
            content: '';
            position: absolute;
            inset: 0;
            background-color: rgba(0,0,0);
            width: 100%; 
            height: 100%;
            opacity: 0;
            transition: all 0.3s;
        }
        &:hover::after{
            opacity: 0.25;
        }
        &:focus::after, &:active::after{
            opacity: 0.5;
        }
        &.icon_left{
            padding: 10px 16px 10px 8px;
        }
        &.icon_right{
            padding: 10px 8px 10px 16px;
        }
        &.small{
            padding: 8px 16px;
            font-size: 12px;
            line-height: 16px;
        }
        &.secondary{
            &::after{
                background-color: #ffffff;
            }
            &:hover, &:hover::after {
                background: var(--gray-lightest-2, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #C2C9D1);
            }
            &:active, &:active::after{
                background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
            }
        }
        &.tertiary{
            &::after{
                content: none;
            }
            &:hover {
                background: var(--primary-lightest-1, linear-gradient(0deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.95) 100%), #3E66FB);
            }
            &:active {
                background: var(--primary-lightest-2, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #3E66FB);
            }
        }
        &.dark{
            &::after{
                content: none;
            }
            &:hover, &:hover::after {
                background: ${({theme}) => theme?.colors?.primaryDark};
            }
            &:active, &:active::after{
                background: ${({theme}) => theme?.colors?.primaryDark};
            }  
        }
        .icon{
            svg, img{
                display: block;
            }
        }
    }
    span{
        position: relative;
        z-index: 1;
    }
`

const SaveButtonMobile = styled.div`
z-index:999;
    button{
        max-width: 271px;
        width: 100%;
        height: 42px;
        display: block;
        margin: auto;
        color: ${({theme}) => theme?.colors?.white};
        text-align: center;
        font-size: ${({theme}) => theme?.typography?.fontBase};
        font-weight: 700;
        background-color: ${({theme}) => theme?.colors?.primaryDefault};
        border-radius: 30px;
        transition: all 0.3s;
        &:hover{
            background-color: ${({theme}) => theme?.colors?.primaryDark};
            cursor:pointer;
        }
        &:focus, &:active{
            background-color: ${({theme}) => theme?.colors?.primaryDarker};
        }
        &:disabled{
            background-color: ${({theme}) => theme?.colors?.grayLighter};
            color: ${({theme}) => theme?.colors?.grayDark};
        }
    }
`

const ComboButtonMobile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    button{
        font-family: inherit;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: ${({theme}) => theme?.typography?.fontRegular};
        font-weight: 600;
        line-height: 20px;
        border-radius: 21px;
        height: 42px;
        &:hover{
            cursor:pointer;
        }
        &:first-child{
            color: ${({theme}) => theme?.colors?.primaryDarker};
            width: 103px;
            border: 1px solid ${({theme}) => theme?.colors?.grayLight} !important;
            background: ${({theme}) => theme?.colors?.white};
            padding-left: 16px;
            padding-right: 24px;
            visibility: ${({buttonVisible}) => buttonVisible ? "hidden" : ""}
        }
        &:last-child{
            width: 130px;
            padding-left: 24px;
            padding-right: 16px;
            background: ${({theme}) => theme?.colors?.primaryDarker};
            color: ${({theme}) => theme?.colors?.white};
            &:disabled{
                color: ${({theme}) => theme?.colors?.grayDark};
                background: #F5F7F8;
                .icon{
                    svg{
                        path{
                            stroke: #757E89;
                        }
                    }
                }
            }
        }
        :empty{
            visibility: hidden;
            opacity: 0;
        }
        .icon{
            svg{
                display: block;
            }
        }
    }
`

const MobileSecondaryStyle = styled.div`
    button{
        max-width: 271px;
        font-size: ${({theme}) => theme?.typography?.fontBase};
        font-weight: 700;
        width: 100%;
        height: 42px;
        display: block;
        margin: auto;
        padding: 0px 16px;
        color: ${({theme}) => theme?.colors?.primaryDarker};
        font-family: inherit;
        background-color: ${({theme}) => theme?.colors?.grayLighter};
        border-radius: 30px;
        transition: all 0.3s;
        cursor: pointer;
        &:hover{
            background-color: #DFE2E8;
        }
        &:active, &:focus{
            background-color: ${({theme}) => theme?.colors?.grayDarker};
        }
    }
`

export const Button = ({ title = 'Button', type, size = 'large', isDisabled, iconLeft, iconRight, comboLeftTitle, comboRightTitle, comboLeftDisabled, comboRightDisabled, comboLeftClick, comboRightClick, buttonVisible, ...props }) => {
    const hasIconLeft = !!iconLeft;
    const hasIconRight = !!iconRight;
    const iconClass = hasIconLeft ? 'icon_left' : hasIconRight ? 'icon_right' : '';

    if (type === 'primary') {
        return (
            <ButtonStyle theme={theme}>
                <PrimaryButtonStyle className={`btn ${size} ${iconClass}`} disabled={isDisabled} {...props}>
                    {hasIconLeft && <span className='icon'>{iconLeft}</span>}
                    <span>{title}</span>
                    {hasIconRight && <span className='icon'>{iconRight}</span>}
                </PrimaryButtonStyle>
            </ButtonStyle>
        )
    }

    if (type === 'danger') {
        return (
            <ButtonStyle theme={theme}>
                <DangerButtonStyle className={`btn ${size} ${iconClass}`} disabled={isDisabled} {...props}>
                    {hasIconLeft && <span className='icon'>{iconLeft}</span>}
                    <span>{title}</span>
                    {hasIconRight && <span className='icon'>{iconRight}</span>}
                </DangerButtonStyle>
            </ButtonStyle>
        )
    }

    if (type === 'secondary') {
        return (
            <ButtonStyle theme={theme}>
                <SecondaryButtonStyle className={`btn secondary ${size} ${iconClass}`} disabled={isDisabled} {...props}>
                    {hasIconLeft && <span className='icon'>{iconLeft}</span>}
                    <span>{title}</span>
                    {hasIconRight && <span className='icon'>{iconRight}</span>}
                </SecondaryButtonStyle>
            </ButtonStyle>
        )
    }

    if (type === 'tertiary') {
        return (
            <ButtonStyle theme={theme}>
                <TertiaryButtonStyle className={`btn tertiary ${size} ${iconClass}`} disabled={isDisabled} {...props}>
                    {hasIconLeft && <span className='icon'>{iconLeft}</span>}
                    <span>{title}</span>
                    {hasIconRight && <span className='icon'>{iconRight}</span>}
                </TertiaryButtonStyle>
            </ButtonStyle>
        )
    }

    if (type === 'dark') {
        return (
            <ButtonStyle theme={theme}>
                <DarkButtonStyle className={`btn dark ${size} ${iconClass}`} disabled={isDisabled} {...props}>
                    {hasIconLeft && <span className='icon'>{iconLeft}</span>}
                    <span>{title}</span>
                    {hasIconRight && <span className='icon'>{iconRight}</span>}
                </DarkButtonStyle>
            </ButtonStyle>
        )
    }

    if (type === 'mobile_save'){
        return (
            <SaveButtonMobile theme={theme}>
                <button disabled={isDisabled} {...props}>{title}</button>
            </SaveButtonMobile>
        )
    }

    if(type === 'button_combo'){
        return(
            <ComboButtonMobile theme={theme} buttonVisible={buttonVisible}>
                <button className='combo_left' disabled={comboLeftDisabled} onClick={comboLeftClick}>
                    {hasIconLeft && <span className='icon'>{iconLeft}</span>}
                    {comboLeftTitle}
                </button>
                <button className='combo_right' disabled={comboRightDisabled} onClick={comboRightClick}>
                    {comboRightTitle}
                    {hasIconRight && <span className='icon'>{iconRight}</span>}
                </button>
            </ComboButtonMobile>
        )
    }

    if(type === 'mobile_secondary'){
        return (
            <MobileSecondaryStyle theme={theme}>
                <button disabled={isDisabled} {...props}>
                    {title}
                </button>
            </MobileSecondaryStyle>
        )
    }

    return (
        <button disabled={isDisabled} {...props}>
            {hasIconLeft && <span className='icon'>{iconLeft}</span>}
            {title}
            {hasIconRight && <span className='icon'>{iconRight}</span>}
        </button>
    )
}
