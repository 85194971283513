import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import SuccessComponent from 'components/SuccessComponent';
import { useRequest } from 'hooks/useRequest';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ErrorComponent from 'components/ErrorComponent';
import { useHistory } from 'react-router-dom';
import { sortBy, prop, compose } from 'ramda';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  form,
  .success {
    width: 300px;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 300px 300px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 300px;
    gap: 20px;
    justify-content: center;
  }
`;

export default function inviteNutritionist() {
  const [email, setEmail] = useState('');
  const {setSelectedNutritionistId} = useAppContext();
  const [newNutritionistName, setNewNutritionistName] = useState('');
  const [nutritionists, setNutritionists] = useState([]);
  const [getNutritionistAll] = useRequest('nutritionist/');
  const history = useHistory();
  const loadNutritionists = () =>
    getNutritionistAll().then(compose(setNutritionists, sortBy(prop('id'))));
  useEffect(() => {
    loadNutritionists();
  }, []);
  const [inviteNutritionist, { isLoading, error, state, setState: setSuccess }] = useRequest(
    `nutritionist/invite`,
    'post',
  );
  const [approveNutritionist] = useRequest('nutritionist/approved', 'post');
  const handleInviteNutritionistSubmit = async (e) => {
    e.preventDefault();
    const res = await inviteNutritionist({ body: { email } });
  };
  const [createNutritionist, { error: createError }] = useRequest(`nutritionist/create`, 'post');
  if (createError) return <ErrorComponent error={createError} />;
  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    const res = await createNutritionist({ body: { name: newNutritionistName } });
    loadNutritionists();
  };

  const NutritionistsList = () => (
    <ul>
      {nutritionists.map((nutritionist) => (
        <li key={nutritionist.id}>
          <input
          type="checkbox"
          checked={nutritionist?.isApproved}
          onChange={() => {
            approveNutritionist({
              body: { id: nutritionist?.id, approved: !nutritionist?.isApproved },
            }).then((res) => {
              loadNutritionists(),
              toast.error(res.message)
            });
          }}
        />
          <a
            onClick={() => {
              localStorage.setItem('imitateNutritionist', JSON.stringify(nutritionist));
              setSelectedNutritionistId(nutritionist?.userId)
              history.push('/nutritionist');
            }}
          >{`${nutritionist.id} ${nutritionist.name} `}</a>
          {nutritionist.userId ? (
            <span style={{ color: 'green', fontSize: '0.5em' }}>claimed</span>
          ) : (
            <span style={{ color: 'red', fontSize: '0.5em' }}>not claimed</span>
          )}
        </li>
      ))}
    </ul>
  );

  if (state && state.success)
    return (
      <Container>
        <div className="success">
          <SuccessComponent
            style={{ marginBottom: '10px' }}
            message={`Successfully sent invite to ${email}`}
          />
          <SubmitButton
            onClick={() => {
              setEmail('');
              setSuccess(null);
            }}
          >
            Send another one
          </SubmitButton>
        </div>
      </Container>
    );
  return (
    <Container>
      <form onSubmit={handleInviteNutritionistSubmit}>
        <h1>Invite Nutritionist</h1>
        <Input
          type="email"
          label="Email address"
          required
          bordered
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus={true}
        />
        <SubmitButton
          disabled={isLoading || !email}
          style={{ marginTop: '10px' }}
          type="submit"
          error={error}
        >
          {isLoading ? 'Sending' : 'Send Invite'}
        </SubmitButton>
        {state && state.url && <textarea cols={100} rows={10} value={state.url} />}
      </form>
      <form onSubmit={handleCreateSubmit}>
        <h1>Create Nutritionist</h1>
        <Input
          type="text"
          label="Name"
          required
          bordered
          value={newNutritionistName}
          onChange={(e) => setNewNutritionistName(e.target.value)}
        />
        <SubmitButton
          disabled={
            // isLoading ||
            !newNutritionistName
          }
          style={{ marginTop: '10px' }}
          type="submit"
          error={error}
        >
          Create Nutritionist{/* {isLoading ? "Sending" : "Send Invite"} */}
        </SubmitButton>
      </form>
      <NutritionistsList />
    </Container>
  );
}