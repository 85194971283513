import React ,{useEffect} from 'react'
import { ProviderStorefrontSidebar } from './storefront/layout/Sidebar'
import { Switch, Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Settings } from './storefront/settings/Settings';
import Profile from './storefront/profile/Profile'
import { Home } from './storefront/home/Home';
import { theme } from 'shared/theme/theme';
import Orders from './storefront/orders/Orders';
import { useAppContext } from 'context/appContext';
import { KitchenContextProvider } from 'context/kitchenContext';
import { Kitchen } from './Kitchen';
import { useLocation } from 'react-router-dom';
import { Payouts } from './storefront/payouts/Payouts';
import { Schedules } from './storefront/schedules/Schedules';
import { useRequest } from 'hooks/useRequest';
import { Menu } from './storefront/menu/Menu';
import { AddItem } from './storefront/menu/AddItem';

const ProviderStorefrontStyle = styled.div`
    display: flex;
    font-family: ${({theme}) => theme?.typography?.fontInter};
    background-color: ${({ theme }) => theme?.colors?.lightBackground};
    .storefront_section{
        flex: 1;
        height: 100vh;
    }
`

export const ProviderStorefront = () => {
    const { user, setProvider, isSuper } = useAppContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const providerId = searchParams.get('providerId');
    const imitateProvider = JSON.parse(sessionStorage.getItem('imitateProvider'));
    const activeProviderId = providerId ? providerId : imitateProvider?.id;
    const [getProviders,{isLoading}] = useRequest(`provider/provider/${activeProviderId}`);
    const history = useHistory();
  
    const getProviderData = () => {
      getProviders()
        .then((data) => {
          setProvider(data);
          sessionStorage.setItem('imitateProvider', JSON.stringify(data));
        })
        .catch(console.log);
    };
  
    useEffect(() => {
      if (activeProviderId) {
        getProviderData();
      }else if(!activeProviderId && isSuper){
        history.goBack();
      }
    }, [activeProviderId]);

    return (
    <KitchenContextProvider>
        <ProviderStorefrontStyle theme={theme}>
            {location.pathname !== `/kitchen/${user?.username}` &&
            <ProviderStorefrontSidebar />
            }
            <section className='storefront_section'>
                <Switch>
                    <Route exact path={'/kitchen/menu'}>
                        <Menu />
                    </Route>
                    <Route path={'/kitchen/orders'}>
                        <Orders isSuper={isSuper} />
                    </Route>
                    <Route exact path={'/kitchen/settings'}>
                        <Settings />
                    </Route>
                    <Route exact path={"/kitchen/profile"}>
                        <Profile profileName="Joe Morris" profileEmail="Joe@gmail.com" />
                    </Route>
                    <Route exact path={'/kitchen/payouts'} component={Payouts}/>
                    <Route exact path={'/kitchen/schedules'} component={Schedules}/>
                    <Route exact path={'/kitchen'}>
                        <Home isSuper={isSuper} />
                    </Route>
                    <Route exact path={'/kitchen/menu/addItem'} component={AddItem}/>
                    {location.pathname === `/kitchen/${user?.username}` &&
                    <Route exact path={'/kitchen/:providerId'}>
                        <Kitchen />
                    </Route>
                    }
                </Switch>
            </section>
        </ProviderStorefrontStyle>
    </KitchenContextProvider>
    )
}