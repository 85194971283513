import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { ReactComponent as Flask } from '../../icons/flask.svg'
import { ReactComponent as Kasor } from '../../icons/kasor.svg'
import { ReactComponent as Halal } from '../../icons/halal.svg'
import { ReactComponent as Vegetarian } from '../../icons/vegetarian.svg'
import { ReactComponent as Highprotein } from '../../icons/high-protein.svg'
import { ReactComponent as Lawcarb } from '../../icons/lawcarb.svg'
import { ReactComponent as Ovo } from '../../icons/ovo.svg'
import { ReactComponent as Lacto } from '../../icons/lacto.svg'
import { ReactComponent as Paleo } from '../../icons/paleo.svg'
import { ReactComponent as Ketogenic } from '../../icons/ketogenic.svg'
import { ReactComponent as Gluten } from '../../icons/gluten.svg'
import { ReactComponent as Peanuts } from '../../icons/peanuts.svg'
import { ReactComponent as Treenuts } from '../../icons/treenuts.svg'
import { ReactComponent as Celery } from '../../icons/celery.svg'
import { ReactComponent as Mustard } from '../../icons/mustard.svg'
import { ReactComponent as Eggs } from '../../icons/eggs.svg'
import { ReactComponent as Sesame } from '../../icons/sesame.svg'
import { ReactComponent as Fish } from '../../icons/fish.svg'
import { ReactComponent as Moluscs } from '../../icons/moluscs.svg'
import { ReactComponent as Soy } from '../../icons/soy.svg'
import { ReactComponent as Sulphities } from '../../icons/sulphities.svg'
import { ReactComponent as Crus } from '../../icons/crus.svg'
import { ReactComponent as Lupin } from '../../icons/lupin.svg'
import { ReactComponent as Milk } from '../../icons/milk.svg'

let isLoading = true;
const recomendedJson = [
    { id: 0, icon: <Flask />, title: 'vegan' },
    { id: 1, icon: <Kasor />, title: 'kosher' },
    { id: 2, icon: <Halal />, title: 'halal' },
    { id: 3, icon: <Vegetarian />, title: 'vegetarian' },
    { id: 4, icon: <Highprotein />, title: 'high-protein' },
    { id: 5, icon: <Lawcarb />, title: 'low-carb' },
    { id: 6, icon: <Ovo />, title: 'ovo-vegeterian' },
    { id: 7, icon: <Lacto />, title: 'lacto-vegetarian' },
    { id: 8, icon: <Paleo />, title: 'paleo' },
    { id: 9, icon: <Ketogenic />, title: 'ketogenic' },

]
const food_to_avoid = [
    { id: 1, icon: <Gluten />, title: 'Gluten' },
    { id: 2, icon: <Peanuts />, title: 'peanuts' },
    { id: 3, icon: <Treenuts />, title: 'tree nuts' },
    { id: 4, icon: <Celery />, title: 'celery' },
    { id: 5, icon: <Mustard />, title: 'mustard' },
    { id: 6, icon: <Eggs />, title: 'eggs' },
    { id: 7, icon: <Milk />, title: 'milk' },
    { id: 8, icon: <Sesame />, title: 'sesame' },
    { id: 9, icon: <Fish />, title: 'fish' },
    { id: 10, icon: <Moluscs />, title: 'molluscs' },
    { id: 11, icon: <Soy />, title: 'soy' },
    { id: 12, icon: <Sulphities />, title: 'sulphites' },
    { id: 13, icon: <Crus />, title: 'crustaceans' },
    { id: 14, icon: <Lupin />, title: 'lupin' },

]
const CrearteRecomended = styled.div`
.container{
    max-width: 500px;
    margin: auto;
    width: 100% !important;
    margin:0 auto;
    padding-right: 24px;
    padding-left: 24px;
    @media (max-width:376px){
        padding-right: 20px;
        padding-left: 20px;
    }
    @media (max-width:321px){
        padding-right: 14px;
        padding-left: 14px;
    }
}
.Head-recomended{
    font-family: 'Roboto';
    font-style: normal;
    margin:14px 0px 26px 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #51635D;
}
.Main-card{
    margin-top:10px;
    width:100%;
    height: 92px;
    margin:0 5px;
    @media (max-width:820px){
        max-width:15%;
    }
    @media (max-width:576px){
    max-width: 21%;
    }
    @media (max-width:320px){
    max-width: 20%;
    }
}
.card{
    width:44px;
    height:44px;
    display:flex;
    justify-content:center;
    margin:0 auto;
    background-color:#fff;
    border-radius : 50%;
    border:none;
}

.Food_card{
    width:44px;
    height:44px;
    display:flex;
    justify-content:center;
    margin:0 auto;
    background-color:#fff;
    border-radius : 50%;
    border:none;
}
.card-img-top{
    text-align:center;
}
.card-title{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #51635D;
}
.d-flex{
    display:flex;
    align-items:center;
    // justify-content:space-between;
    flex-wrap:wrap;
}
.active{
    background-color:rgba(240, 139, 0, 0.1);;
    border:1px solid rgba(240, 139, 0, 0.3);
}
.activeRecomended{
    background-color:#F0FAEB;   
    border:1px solid #CBEDBA;
}
.fillColorSvg> svg > path{
    fill:#52C41A !important;
}
.foodSvgFill> svg > path{
    fill: #FEB95A !important;
}
.txtColor{
    font-weight: 700;
    color: #51635D;
}

`
const Recomended = ({ form, setForm }) => {
    const [isRecomended, setIsRecomeded] = useState([]);
    const [isFoodSelected, setIsFoodSelected] = useState([]);

    const foodSelected = (item) => {
        const itemIndex = isFoodSelected.indexOf(item)
          if (itemIndex < 0) {
            setIsFoodSelected([...isFoodSelected, item]);
            setForm({...form,food_to_avoid:[...isFoodSelected,item.toLowerCase()]})
          } else {
            setIsFoodSelected(isFoodSelected.filter((food) => food !== item));
            setForm({
              ...form,
              food_to_avoid: isFoodSelected.filter((food) => food !== item),
            });
        }}

    const RecomendedSelect = (item) => {
        isRecomended.indexOf(item) === -1
            ? (setIsRecomeded([...isRecomended, item]),
                form?.tags?.push(item))
            : (setIsRecomeded(isRecomended.filter((food) => food !== item)),
                setForm({
                    ...form,
                    tags: isRecomended.filter((food) => food !== item),
                }));

    };

    useEffect(() => {
        if (form?.tags?.length) {
            console.log('---form', form.tags);
            setIsRecomeded(form?.tags);
            isLoading = false;
        }
    }, [form?.tags]);

    useEffect(() => {
        if (form?.food_to_avoid?.length) {
            setIsFoodSelected(form?.food_to_avoid);
            isLoading = false;
        }
    }, [form?.food_to_avoid]);

    console.log('isRecomended----', isRecomended);
    console.log('isFoodSelected----', isFoodSelected);

    return (
        <>
            <CrearteRecomended>
                <div>
                    <h3 className="Head-recomended">Recomended</h3>
                </div>
                <div className="d-flex recomended-icon">
                    {
                        recomendedJson.map((item, index) => {
                            return (
                                <>
                                    <div className="Main-card">
                                        <div onClick={() => RecomendedSelect(item.title)}>
                                        <div className={classNames('card', {
                                            activeRecomended: isRecomended?.includes(item.title),
                                        })}>
                                            <div className={classNames('card-img-top', {
                                                fillColorSvg: isRecomended?.includes(item.title),
                                            })}>
                                                {item.icon}
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className={classNames('card-title', {
                                                txtColor: isRecomended?.includes(item.title),
                                            })}>{item.title}</h5>
                                        </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
                <div>
                    <h3 className="Head-recomended">Food To Avoid</h3>
                </div>
                <div className="d-flex recomended-icon">
                    {
                        food_to_avoid?.map((item) => {
                            return (
                                <>

                                    <div className="Main-card">
                                        <div  onClick={() => foodSelected((item?.title).toLowerCase())}>
                                        <div className={classNames('card', {
                                            active: isFoodSelected?.includes((item?.title).toLowerCase()),
                                        })}>
                                            <div className={classNames('card-img-top', {
                                                foodSvgFill: isFoodSelected?.includes((item?.title).toLowerCase()),
                                            })}>
                                                {item?.icon}
                                            </div>
                                        </div>
                                        <div>
                                            <h5 className={classNames('card-title', {
                                                txtColor: isFoodSelected?.includes((item?.title).toLowerCase()),
                                            })}>{item?.title}</h5>
                                        </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </CrearteRecomended>
        </>
    )
}

export default Recomended;
