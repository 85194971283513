import { useState, useRef, useEffect } from 'react';
import { useAppContext } from 'context/appContext';

export default function useVisibility(open) {
  const [isVisible, setIsVisible] = useState(true);
  const currentElement = useRef(null);
  const { isMobile } = useAppContext();

  const onScroll = () => {
    const top = currentElement.current?.getBoundingClientRect().top;
    (isMobile ? top < -200 : top < 0) && open ? setIsVisible(false) : setIsVisible(true);
  };

  useEffect(() => {
    setIsVisible(true);
    if (open) document.addEventListener('scroll', onScroll, open);
    return () => document.removeEventListener('scroll', onScroll, open);
  }, [open]);

  return [isVisible, currentElement];
}
