import Skeleton from '@mui/material/Skeleton';
import { HomeTabItemWrapper } from './style';

export default function HomeTabItem({ name, total, gettingSales }) {
  return (
    <HomeTabItemWrapper>
      <div className="item-name">{name}</div>
      {gettingSales ? (
        <Skeleton variant="text" width={150} height={24} />
      ) : (
        <div className="item-count">{total}</div>
      )}
    </HomeTabItemWrapper>
  );
}
