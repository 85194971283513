import { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import styled from 'styled-components';

const PlanningDateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .change-day {
    display: flex;
    cursor: pointer;

    &.disabled {
      visibility: hidden;
    }
  }

  .current-date {
    color: #52c41a;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
  }
`;

export default function PlanningDate({ dailyMeals, setNote, dayIndex, setDayIndex, updateNote }) {
  const [activeDay, setActiveDay] = useState(dailyMeals.data[dayIndex].suggestedDate);

  useEffect(() => {
    setActiveDay(dailyMeals.data[dayIndex].suggestedDate);
    setNote(dailyMeals.data[dayIndex].notes);
  }, [dayIndex]);

  return (
    <PlanningDateWrapper>
      <div
        className={classNames('change-day', { disabled: dayIndex < 1 })}
        onClick={() => {
          setDayIndex((prevState) => prevState - 1);
          updateNote();
        }}
      >
        <img src="/icons/angle-left.svg" alt="Angle Left Icon" />
      </div>
      <div className="current-date">
        {moment(activeDay).format('dddd, MMMM Do')?.split('th')}{' '}
      </div>
      <div
        className={classNames('change-day', { disabled: dayIndex === dailyMeals.data.length - 1 })}
        onClick={() => {
          setDayIndex((prevState) => prevState + 1);
          updateNote();
        }}
      >
        <img src="/icons/angle-right.svg" alt="Angle Right Icon" />
      </div>
    </PlanningDateWrapper>
  );
}
