import NavItems from './NavItems';
import styled from 'styled-components';
const StyledNav = styled.nav`
  display: flex;
  align-self: center;
  justify-self: end;
  a {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    align-items: center;
    width: min-content;
    color: gray;
    margin-right: 10px;
    font-size: 1rem;
    padding-right: 10px;
    text-decoration: none;
    :active {
      color: #52c41a;
    }
    svg {
      font-size: 1rem;
    }
  }
  .active {
    color: var(--nutritt-green);
  }
`;

export default function Nav({ updateProvider, provider, isAlert }) {
  return (
    <StyledNav>
      <NavItems updateProvider={updateProvider} provider={provider} isAlert={isAlert} />
    </StyledNav>
  );
}
