import React, { memo } from "react"
import { theme } from "shared/theme/theme";
import { Pagination as MuiPagination } from '@mui/material';
import styled from "styled-components";

const PaginationStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-top: 1px solid ${({theme}) => theme?.colors?.grayLighter};
    .number_of_items{
        font-size: ${({theme}) => theme?.typography?.fontSmall};
        color: ${({theme}) => theme?.colors?.blackLight};
        font-style: normal;
        font-weight: 400;
        line-height: 16px
    }
    nav{
        ul{
            li{
                button{
                    background-color: ${({theme}) => theme?.colors?.white} !important;
                    border: 1px solid ${({theme}) => theme?.colors?.grayLight} !important;
                    font-size: ${({theme}) => theme?.typography?.fontSmall} !important;
                    color: ${({theme}) => theme?.colors?.blackLight} !important;
                    border-radius: 4px !important;
                    font-weight: 600 !important;
                    &.Mui-selected{
                        background-color: ${({theme}) => theme?.colors?.primarylightest} !important;
                        color: ${({theme}) => theme?.colors?.primaryDefault} !important;
                    }
                }
            }
        }
    }
`

function Pagination({startItem, endItem, totalCount, handlePageChange, totalPageCount,activePageNumber}){
    return (
        <PaginationStyle theme={theme}>
            <div className='number_of_items'>
              { totalCount === 0 ? `Showing 0 items`: ` Showing ${startItem} - ${endItem ? endItem : '0'} of ${totalCount ? totalCount : 0} items`}
            </div>
            <MuiPagination
                count={totalPageCount}
                page={activePageNumber}
                onChange={handlePageChange}
            />
        </PaginationStyle>
    )
}

export default memo(Pagination);