import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenCardDesktopWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 352px;
  border-radius: 10px;
  border: 1px solid ${theme?.colors.grayLight};
  background-color: ${theme?.colors.secondary};
  display: flex;

  .kitchen-image-wrapper {
    position: relative;
    .kitchen-tag {
      position: absolute;
      top: 24px;
      left: 0;
      max-width: 70%;
      height: 30px;
      padding: 0 12px;
      border-radius: 0px 5px 5px 0px;
      background: ${theme?.colors.secondary};
      color: ${theme?.colors.primaryDarker};
      font-size: 15px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 3px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      .kitchen-tag-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .kitchen-image-container {
    width: 629px;
    height: 352px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .kitchen-details-wrapper {
    padding: 0 33px;
    .kitchen-title {
      height: 160px;
      color: ${theme?.colors.primaryDarker};
      font-size: 48px;
      font-weight: 700;
      line-height: 50px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
    .delivery-date-wrapper {
      height: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      .clock-img {
        width: 20px;
        height: 20px;
      }
      .delivery-date {
        display: flex;
        color: ${theme.colors.deepForestGreen};
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        .date {
          margin-left: 5px;
        }
      }
    }
  }

  .features-wrapper {
    margin: 12px 0 30px;
    display: grid;
    grid-template-columns: 180px 180px 170px;
    gap: 6px;
    .feature-details {
      height: 64px;
      border: 1px solid ${theme.colors.grayLight};
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.feature-1 {
        padding: 14px 8px 9px;
      }
      &.feature-2 {
        padding: 14px 11px 9px;
      }
      &.feature-3 {
        padding: 14px 6px 9px;
      }
    }
    .feature-title-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 14px;
        height: 14px;
      }
      .feature-title {
        color: ${theme.colors.black};
        font-size: 16px;
        font-weight: 500;
        line-height: 15px;
      }
    }
    .feature-info {
      color: ${theme.colors.grayDarker};
      font-size: 11px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .btn.dark {
    width: 195px;
    height: 42px;
    border-radius: 21px;
    background-color: ${theme.colors.primaryDarker};
  }
`;
