import classNames from 'classnames';
import styled from 'styled-components';
import ImageViewer from '../../../components/ImageViewer';
import { useState } from 'react';
import Alert from 'components/Alert';
import { useRequest } from 'hooks/useRequest';
import { toast } from "react-toastify";
import { useAppContext } from 'context/appContext';
import { useHistory } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import NutritionistMoreDetails from './NutritionistMoreDetails';

const PlanCardStyle = styled.div`
  cursor: grab;

  .title {
    padding: 32px 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #0B1D17;
  }
  .titlee {
    padding: 32px 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #0B1D17;
  }

  .meal-plan-image {
    width: 100%;
    height: 193px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    object-fit: cover;
  }

  .card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px #0000000d;
    object-fit:cover;
    display: grid;
    grid-template-columns: 30% 70%;
  }
  .expand-card{
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 4px 10px #0000000d;
    object-fit:cover;
    display: grid;
    grid-template-columns: 30% 70%;
    .meal-plan-image{
      width: 100%;
      height: 193px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0px;
    }
  }

   .meal-img-wrapper {
     position: relative;
     display: flex;
     flex-direction: column;
     align-items: center;
   }

  .stard-btn {
   
    .primary-btn {
      width: 147px;
      height: 40px;
      padding: 0;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 17px;
      box-shadow: 0px 3px 7px rgb(0 0 0 / 12%);
      &:hover {
        background: #cbedba;
        color: #51635d;
        transition: all 0.3s ease-in-out;
      }
    }

    .secondary-btn {
      background-color: #52c41a;
      width: 109px;
      height: 40px;
      padding: 0;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 17px;
      color: #FFFFFF;
      box-shadow: 0px 3px 7px rgb(0 0 0 / 12%);
      &:hover {
        background: #cbedba;
        color: #51635d;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  


  .days-tags {
    overflow: hidden;
    padding: 18px 25px 22px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &.moreTags {
      padding: 40px 0 25px 14px;

      .days-wrapper {
        margin-right: 15px;
        flex-shrink: 0;
      }
    }
    .days-div{
        display:flex;
        align-items: center;
        width:43%;
        justify-content:space-between;
    }
    .offer-div{
      width: 100%;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding-right:12px;
    }
    .offerplan{
      display: flex;
      width:45%;
      justify-content: space-between;
    }
    .days-wrapper {
      height: 20px;
      display: flex;
      align-items: center;

      .calendar-icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }

    .days {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
    }

    .tags-wrapper {
      overflow-x: auto;
      display: flex;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }

    .showDescription-wrapper {
      overflow-x: auto;
      display: flex;

      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }

    .tag {
      height: 20px;
      margin-right: 3px;
      padding: 0 9px;
      color: #52c41a;
      border: 1px solid #cbedba;
      border-radius: 10px;
      font-size: 10px;
      font-family: RobRegular;
      line-height: 12px;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .show-more {
        width: 141px;
        height: 40px;
        border: 1px solid #E0E3E0;
        border-radius: 20px;
        display:flex;
        justify-content: center;
        align-items:center;
        cursor: pointer;
  
      .text-wrapper {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
      }
    }
  }

  .detailss {
    background: #ffffff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .details{
    background: #FFFFFF;
    border-top:1px solid #D9D9D9;
    display:flex;
    justify-content:flex-end;
    padding:32px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;

    p{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #51635D;
      width:70%;
      text-align:justify;
      padding-left:32px;
    }
  }

  @media (max-width: 991px) {
    .title{
      text-align:center;
      padding: 20px 24px !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
    .titlee{
      padding: 20px 24px !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
    .expand-card{
      display:flex !important;
      flex-direction:column;
    }
    .card{
      display: flex !important;
      flex-direction: column;
    }

      .meal-plan-image{
        height:184px !important;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 10px;
      }

      .days-tags{
        display:flex;
        flex-direction:column !important;
        padding: 0px 25px 24px 22px !important;

        .days{
          font-size: 14px !important;
          line-height: 16px !important;
          
        }

        .days-div{
          width:100% !important;
        }
      }
     
     .btn-div{
      width:100% !important;
      .stard-btn{
        margin-top: 15px;
        .primary-btn, .secondary-btn{
          width: 100% !important;
          height: 40px !important;
        }
      }
    } 

    .offer-div{
      padding-right:0 !important;
    .offerplan{
      width:100% !important;
      justify-content:flex-start !important;
      .rendered-tag{
         padding-left:21px;
      }
    }
  }
  .details{
    justify-content: flex-start !important;
    padding: 14px 24px 14px !important;
    margin-top: 15px;
    width: 118%;
    p{
      padding-left:0 !important;
      width:100% !important;
    }
  }
     
  }

  @media (min-width:280px) and (max-width:300px){
    .rendered-tag{
      padding-left:10px !important;
    }
    .days-tags{
       .days{
        font-size:9px !important;
    }
   }
  }
  @media (min-width:992px) and (max-width:1050px){
    .days-div{
       width:60% !important;
    }
  }
  @media (min-width:1050px) and (max-width:1100px){
    .days-div{
       width:58% !important;
    }
  }
  @media (min-width:1100px) and (max-width:1200px){
    .days-div{
       width:55% !important;
    }
  }
  @media (min-width:1200px) and (max-width:1290px){
    .days-div{
       width:52% !important;
    }
  }
  @media (min-width:1290px) and (max-width:1400px){
    .days-div{
       width:43% !important;
    }
  }
  @media (min-width:1400px) and (max-width:1500px){
    .days-div{
       width:43% !important;
    }
  }
  @media (min-width:1500px) and (max-width:1700px){
    .days-div{
       width:40% !important;
    }
  }
  @media (min-width:1700px){
    .days-div{
       width:37% !important;
    }
  }
  @media (min-width:992px) and (max-width:1113px){
    .offer-div{
       .days{
         font-weight:700 !important;
         font-size:10px !important;
       }
    }
  }
  @media (min-width:1113px) and (max-width:1200px){
    .offer-div{
       .days{
         font-weight:700 !important;
         font-size:14px !important;
       }
    }
  }
  @media (min-width:1200px) and (max-width:1240px){
    .offer-div{
       .days{
         font-size:16px !important;
       }
    }
  }
`;

export default function MealCard({
  name,
  image,
  duration,
  duration_type,
  type,
  introduction,
  mealPlan,
  offerPlan,
  availability,
  price,
  id,
  nutritionistId,
  OfferPlanId,
  index,
  setRegisterCard,
  setMealPlanId,
  carbs,
  protein,
  fat,
  tags,
  food_to_avoid,
  tips,
  diet_formula,
  PAL,
  goal,
  goal_type,
}) {
  const { isMobile } = useAppContext();
  const [showIntro, setShowIntro] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateNewDietPlan] = useRequest('nutritionist/updateSubscribedDietPlan', 'put');
  const history = useHistory();
  const { setupLocalUser } = useAppContext();
  const { showChangeDietPlan, getIsUserPlanExpiredFn } = useShopContext();
  const { user, isGuest, isVisitor, profile, setProfile } = useAppContext()

  const RenderTags = ({ tag }) => {
    return (
      <>
        {mealPlan && (
          <div className="tags-wrapper">
            <div className="tag">{tag}</div>
          </div>
        )}
        {offerPlan && (
          <div className="days-wrapper">
            <img className="calendar-icon" src="/icons/cost1.svg" alt="doller" />
            <div className="days">
              <span className="days">{tag}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  //  const handleClick = (e, id) => {
  //            if(isGuest){
  //             setProfile({...profile,dietTemplateId:id})
  //             history.push("/guest-onboarding")
  //             return;
  //            }
  //            const dietPlanPayload = {"updatedDietTemplateId": id}
  //            updateNewDietPlan({ body: dietPlanPayload})
  //            .then((response) => {toast.success("Diet Plan updated successfully")
  //             setupLocalUser();
  //             getIsUserPlanExpiredFn();
  //             history.push('/shop/plan')})
  //             .catch((error) => {toast.error("Something went wrong")
  //             handleClose();                  
  //            })
  //     };

  const handleClose = () => {
    setOpen(false);
    setRegisterCard(true)
  };
  return (
    <PlanCardStyle>
      <div className={showIntro ? " expand-card" : "card"}>
        <div className="meal-img-wrapper">
          <ImageViewer
            className="meal-plan-image"
            src={image || '/images/plan-cover.png'}
            fallbackSrc={'/images/plan-cover.png'}
            alt="Meal Plan"
          />
        </div>
        <div className='week-details'>
          <div className={mealPlan ? "title" : "titlee"}>{name}</div>
          <div className={classNames('days-tags')}>
            <div className={mealPlan ? 'days-div' : 'offer-div'}>
              {mealPlan && (
                <div className="days-wrapper">
                  <img className="calendar-icon" src="/icons/Calendar1.svg" alt="calendar" />
                  <span className="days">
                    {duration} {duration_type}
                  </span>
                </div>
              )}
              {offerPlan && (
                <div className='offerplan'>
                  <div className="days-wrapper">
                    <img className="calendar-icon" src="/icons/clock1.svg" alt="calendar" />
                    <span className="days">
                      {availability} {duration_type}
                    </span>
                  </div>
                  <div className='rendered-tag'>
                    {/* {mealPlan && <RenderTags tag={type} mealPlan={true} />} */}
                    {offerPlan && <RenderTags tag={price} offerPlan={true} />}
                  </div>
                </div>
              )}


              <div className={isMobile ? "" : "show-more"}
                onClick={() => setShowIntro(!showIntro)}
              >
                <div
                  className={classNames('text-wrapper', { showDetails: showIntro })}
                >
                  {showIntro ? (
                    <img src={isMobile ? "/icons/Details-no.svg" : "/icons/Hide-Details.svg"} alt="show less" />
                  ) : (
                    <img src={isMobile ? "/images/Details-green.svg" : "/icons/Details.svg"} alt="show more" />
                  )}
                </div>
              </div>

            </div>
             
            {offerPlan && showIntro ? ( isMobile ? 
            <div className="details">
            <p>{introduction}</p>
          </div> : '')
           :
           ''
            }

            <div className='btn-div'>
              {mealPlan && (
                <div className="stard-btn">
                  <button className="primary-btn" onClick={() => { user ? setOpen(!open) : setRegisterCard(true), setMealPlanId(id) }} data-toggle="modal">
                    Get started
                  </button>
                </div>
              )}
              {offerPlan && (
                <div className="stard-btn">
                  <button className="secondary-btn" onClick={() => {
                    history.push(`/booking/one-to-one-meeting?offerId=${OfferPlanId}&nutritionistId=${nutritionistId}&clientEmail=${user?.email}&userId=${user?.id}&isUserExist=${true}`);
                  }}>
                    Book
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showIntro && (
        mealPlan ? (
          <div className="detailss">
            <NutritionistMoreDetails
              carbs={carbs}
              protein={protein}
              fat={fat}
              introduction={introduction}
              tags={tags}
              food_to_avoid={food_to_avoid}
              tips={tips}
              diet_formula={diet_formula}
              PAL={PAL}
              goal={goal}
              goal_type={goal_type}
            />
          </div>
        )
          :
          ( !isMobile && <div className="details">
            <p>{introduction}</p>
          </div>)

      )}
      {open && (
        <div className="getStarted" onClick={() => setOpen(!open)}>
          <Alert
            title={mealPlan ? 'New Meal Plan' : 'New Offer Plan '}
            desc={
              mealPlan && !isGuest && !isVisitor
                ? 'You are currently subsribed to a diffrent plan. Do you want to start over with this plan?'
                : `Do you want to choose ${name}?`
            }
            cancel={() => {
              setOpen(false);
            }}
            cancelText="Cancel"
            okText="Continue"
            ok={
              mealPlan
                ? (e) => { handleClick(e, id); }
                : ""
            }
            discardText="Discard"
          />
        </div>
      )}
    </PlanCardStyle>
  );
}
