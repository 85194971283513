import { Typography } from '@material-ui/core';
import ClientInActive from 'assets/svg-icons/clientInActive';
import ExtremelyIcon from 'assets/svg-icons/Extremelyicon';
import InviteActiveIcon from 'assets/svg-icons/InviteActiveIcon';
import ModertelyActiveIcon from 'assets/svg-icons/ModertelyActiveIcon';
import SlightlyActiveIcon from 'assets/svg-icons/SlightlyActiveIcon';
import styled from 'styled-components';

const ActiveCardBoxStyled = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 30px -15px;
    margin-bottom: 20px;

    @media (max-width: 991px) {
        margin: 20px 0 16px
    }
`
const ActiveCardSHStyled = styled.div`
    text-align: center;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 17px 12px;
    cursor: pointer;
    position: relative;
    .activeRadio{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        appearance: none;
        margin: 0px;
        border-radius: 10px;
        border: 1px solid #E0E3E0;
        padding: 0px;
        cursor: pointer;
        &:checked{
            background-color: #52C41A;
            & + div{
                position: relative;
                svg{
                    fill: #fff;
                    path{
                        fill: #fff;
                    }
                }
                .MuiTypography-root{
                    color: #fff;
                }
            }
        }
    }

    @media (max-width: 991px) {
        height: 100%;
        padding: 0 24px;
        display: flex;
        align-items: center;
        text-align: left;
    }
`
const ActiveCardMediaStyled = styled.div`
    svg{
        margin-bottom: 15px;
        fill: #52C41A;
        path{
            fill: #52C41A;
        }
    } 
    h4{
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 13px;
        line-height: 14px;
        color: #51635D;
        padding-bottom: 5px;
    }
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;
        color: #949D9B;
    }
    
    @media (max-width: 991px) {
        display: flex;
        align-items: center;

        svg {
            width: 32px;
            height: 32px;
            margin: 0 15px 0 0;
        }

        h4 {
            padding-bottom: 3px;
            font-size: 12px;
        }
    }
`
const ActiveCardBoxListStyled = styled.div`
    flex: 0 0 33%;
    padding: 0px 10px;
    margin-bottom: 15px;

    @media (max-width: 991px) {
        min-height: 64px;
        padding: 0;
        margin-bottom: 10px;
        flex: auto;
        flex-basis: 100%;
    }
`

const ActiveBox = [
    {
        media: <ClientInActive />,
        title: "Inactive",
        description: "I need to move more"
    },
    {
        media: <SlightlyActiveIcon />,
        title: "Slightly Active",
        description: "Daily life activity only"
    },
    {
        media: <ModertelyActiveIcon />,
        title: "Moderately Active",
        description: "Slow workouts"
    },
    {
        media: <InviteActiveIcon />,
        title: "Active",
        description: "Workout 3-4x weekly"
    },
    {
        media: <ExtremelyIcon />,
        title: "Extremely Active",
        description: "I'm always on the move!"
    }
]

export default function InviteActiveCard({setActivitySelect}) {
    const handleActive = (e) => {
        setActivitySelect(e.target.value)
    }
    return(
        <ActiveCardBoxStyled>
            {
                ActiveBox.map((item, index) => {
                    return(
                        <ActiveCardBoxListStyled key={index}>
                            <ActiveCardSHStyled>
                                <input type="radio" name="activeBox" value={index + 1} onChange={handleActive} className='activeRadio' />
                                <ActiveCardMediaStyled>
                                    {item.media}
                                    <div>
                                    <Typography component="h4"> {item.title} </Typography>
                                    <Typography component="p"> {item.description} </Typography>
                                    </div>
                                </ActiveCardMediaStyled>
                            </ActiveCardSHStyled>
                        </ActiveCardBoxListStyled>
                    )
                })
            }
        </ActiveCardBoxStyled>
    )
}
