import styled from 'styled-components';
import Title from './components/Title';
import Button from './components/Button';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PageTransition from './components/PageTransition';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from "swiper";
import 'swiper/swiper-bundle.css';
import { ReactComponent as Calendar } from '../../icons/calendar.svg';
import { useOnboardingContext } from '.';
import classNames from 'classnames';

const UserAgeStyle = styled.div`
    height: 100%;
    padding: 48px 32px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: overlay;
    .subtitle {
        margin: 0 auto;
    }
    .flex{
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .form_group{
        position: relative;
        margin-top: 56px;
        margin-left: 18px;
        margin-right: 18px;
        .form_control{
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            padding: 0 16px;
            font-weight: 400;
            font-size: 14px !important;
            line-height: 16px;
            color: #51635D;
            text-align: start;
            -webkit-appearance: none;
            &.focused {
                border-color: #52c41a;
            }
        }
        label{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
        }
    }
    .scroll-container {
        position: relative;
        padding-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }
    .scroll_wrapper{
        overflow: hidden;
        width: calc(100% + 28px);
        max-height: 206px;
        margin: 0 -14px;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        .picker-window {
            position: absolute;
            height: 32px;
            left: 0px;
            top: 54%;
            transform: translateY(-67%);
            width: 96%;
            margin: 0px 2%;
            background-color: #F7F5F8;
            border-radius: 7px;
            pointer-events: none;
        }
        .scroller{
            overflow: hidden;
            width: 30%;
            .swiper-slide {
                height:24px;
                text-align:center;
                font-size: 16px;
                line-height: 16px;
                background: rgba(0,0,0,0);
                color: #fff;
            }
            .swiper-slide.swiper-slide-active {
                margin: 3px 0;
                color: #000000;
                transform: scale(1.3);
            }
            .swiper-slide-prev {
                color: #A8A6A9;
                transform: perspective(200px) rotateX(20deg) scale(1.2);
            }
            .swiper-slide-next {
                color: #A8A6A9;
                transform: perspective(200px) rotateX(-20deg) scale(1.2);
            }
            .swiper-slide.firstVisibleSlide + .swiper-slide {
                color: #B5B3B6;
                transform: perspective(200px) rotateX(40deg) scale(1.1);
            }
            .swiper-slide-next + .swiper-slide {
                color: #B5B3B6;
                transform: perspective(200px) rotateX(-40deg) scale(1.1);
            }
            .swiper.swiper-vertical .swiper-wrapper .swiper-slide-next + .swiper-slide + .swiper-slide {
                color: #D7D7D9;
                transform: perspective(200px) rotateX(-60deg);
            }
            &:nth-child(2){
                width: 40%;
                .swiper{
                    .swiper-wrapper {
                        align-items: center;
                    }
                    .swiper-slide{
                        padding-left: 45px;
                        justify-content: flex-start;
                    }
                    .swiper-slide.swiper-slide-active {
                        transform: scale(1.3) translateX(-8px);
                    }
                    .swiper-slide-prev {
                        transform: perspective(200px) rotateX(20deg) scale(1.2) translateX(-6px);
                    }
                    .swiper-slide-next {
                        transform: perspective(200px) rotateX(-20deg) scale(1.2) translateX(-6px);
                    }
                    .swiper-slide.firstVisibleSlide + .swiper-slide {
                        transform: perspective(200px) rotateX(40deg) scale(1.1) translateX(-4px);
                    }
                    .swiper-slide-next + .swiper-slide {
                        transform: perspective(200px) rotateX(-40deg) scale(1.1) translateX(-4px);
                    }
                }
            }
            &:last-child{
                .swiper{
                    .swiper-slide{
                        padding-right: 30px;
                        justify-content: center;
                    }
                    .swiper-slide.swiper-slide-active {
                        transform: scale(1.3) translateX(8px);
                    }
                    .swiper-slide-prev {
                        transform: perspective(200px) rotateX(20deg) scale(1.2) translateX(6px);
                    }
                    .swiper-slide-next {
                        transform: perspective(200px) rotateX(-20deg) scale(1.2) translateX(6px);
                    }
                    .swiper-slide.firstVisibleSlide + .swiper-slide {
                        transform: perspective(200px) rotateX(40deg) scale(1.1) translateX(4px);
                    }
                    .swiper-slide-next + .swiper-slide {
                        transform: perspective(200px) rotateX(-40deg) scale(1.1) translateX(4px);
                    }
                }   
            }
            // .swiper.swiper-vertical .swiper-wrapper .swiper-slide:nth-child(1 of div.swiper-slide-visible),
            .swiper.swiper-vertical .swiper-wrapper .swiper-slide.firstVisibleSlide {
                color: #D7D7D9;
                transform: perspective(200px) rotateX(60deg);
            }
        }
    }
    .age_restriction{
        position: absolute;
        top: 20px;
        left: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #EB3223;
    }
`

export default function UserAge() {
    const history = useHistory();
    const [years, setYears] = useState(Array.from({ length: 60 }, (_, i) => i + new Date().getFullYear() - 60));
    const month = f => Array.from(Array(12), (e, i) => new Date(25e8 * ++i).toLocaleString('en-US', { month: f }));
    const [months, setMonths] = useState([...month`long`, "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", ...month`long`]);
    const [totalDays, setTotalDays] = useState(Array.from({ length: 31 }, (_, i) => i + 1));
    const [days, setDays] = useState(totalDays);
    const [monthsSlider, setMonthsSlider] = useState(null);
    const [daysSlider, setDaysSlider] = useState(null);
    const [yearsSlider, setYearsSlider] = useState(null);
    const [dob, setDob] = useState({ date: '', month: '', year: '' });
    const [copyDob, setCopyDob] = useState(null);
    const [age, setAge] = useState();
    const [generateIndex, setGenerateIndex] = useState({ month: '', day: '', year: '' });
    const { setOnboardingUser } = useOnboardingContext();
    const [isSwiper, setIsSwiper] = useState({ month: false, date: false, year: false });
    const userDob = JSON.parse(localStorage.getItem('selfOnboardingUserDOB'));
    const handleBack = () => {
        history.push('/self-onboarding/current-height');
    }

    const generateActiveIndex = (index, date, count) => {
        if (index == 0) {
            setGenerateIndex((prevState) => ({ ...prevState, [date]: count }));
        } else if (index == 1) {
            setGenerateIndex((prevState) => ({ ...prevState, [date]: count + 1 }));
        } else if (index == 2) {
            setGenerateIndex((prevState) => ({ ...prevState, [date]: count + 2 }));
        } else {
            setGenerateIndex((prevState) => ({ ...prevState, [date]: index - 3 }));
        }
    }

    const handleMonthChange = (swiper) => {
        setDob((prev) => ({
            ...prev,
            month: months[swiper?.realIndex]
        }))

        generateActiveIndex(swiper?.realIndex, 'month', months.length - 3);

        if (swiper?.realIndex == 3 || swiper?.realIndex == 5 || swiper?.realIndex == 8 || swiper?.realIndex == 10) {
            setDays([...days.slice(0, -1)])
            return;
        }

        if (swiper?.realIndex == 1) {
            setDays([...days.slice(0, -2)])
            return;
        }
        setDays(totalDays);
    }
    const handleDayChange = (swiper) => {
        if (!swiper?.destroyed) {
            setDob((prev) => ({
                ...prev,
                date: Number(days[swiper?.realIndex])
            }))
        }

        generateActiveIndex(swiper?.realIndex, 'day', days.length - 3);
    }
    const handleYearChange = (swiper) => {
        if (!swiper?.destroyed) {
            setDob((prev) => ({
                ...prev,
                year: Number(years[swiper?.realIndex])
            }))
        }

        generateActiveIndex(swiper?.realIndex, 'year', years.length - 3);
    }
    useEffect(() => {
        if (dob?.date || dob?.month || dob?.year) {
            setCopyDob(dob);
            calculateAge(dob);
            localStorage.setItem('selfOnboardingUserDOB', JSON.stringify(dob));
        }

        if (dob?.month && !dob?.date && !dob?.year) {
            setDob({ month: dob?.month, date: days[new Date().getDate() - 1], year: years[30] })
        } else if (!dob?.month && dob?.date && !dob?.year) {
            setDob({ month: months[new Date().getMonth()], date: dob?.date, year: years[30] })
        } else if (!dob?.month && !dob?.date && dob?.year) {
            setDob({ month: months[new Date().getMonth()], date: days[new Date().getDate() - 1], year: dob?.year })
        }
    }, [dob])

    const calculateAge = (x) => {
        const today = new Date();
        let age = today.getFullYear() - x.year;
        const monthDiff = today.getMonth() - months.indexOf(x.month);
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < x.date)) {
            age--;
        }
        setAge(String(age));
    };

    useEffect(() => {
        age && setOnboardingUser((prev) => ({ ...prev, age: age }));
    }, [age])

    useEffect(() => {
        setDob(JSON.parse(localStorage.getItem('selfOnboardingUserDOB')));
        setCopyDob(JSON.parse(localStorage.getItem('selfOnboardingUserDOB')));
    }, [])

    return (
        <PageTransition>
            <UserAgeStyle>
                <div className='flex'>
                    <Title title={"When were you born?"} subtitle={"Your age affects your calorie and nutrient needs. We use your date of birth to calculate them"} />

                    <div className="form_group">
                        <input id="age" type="button" readOnly value={(copyDob?.month || copyDob?.date || copyDob?.year) ? (copyDob?.month + ' ' + copyDob?.date + ',' + ' ' + copyDob?.year) : ''} className={classNames("form_control", { focused: dob?.year })} />
                        <label htmlFor="age"><Calendar /></label>
                    </div>
                    <>
                        {/* <div className='wrap' style={{ width: '100vw', height: '100vh', position: 'fixed', inset: 0, zIndex: 2 }} /> */}
                        <div className='scroll-container'>
                            {(age < 18) && <p className='age_restriction'>Sorry! <br /> Age Restriction Applies</p>}
                            <div className="scroll_wrapper" style={{ position: 'relative', zIndex: 9 }}>
                                <div className="picker-window" />
                                <div className='scroller'>
                                    <Swiper initialSlide={userDob ? months.indexOf(userDob?.month) : new Date().getMonth()} direction={"vertical"} onSlideChange={handleMonthChange} onTouchMove={() => setIsSwiper((prevState) => ({ ...prevState, month: true }))} loop={true} spaceBetween={0} slidesPerView={7} centeredSlides={true} onSwiper={setMonthsSlider}>
                                        {months?.map((item, index) => (
                                            <SwiperSlide className={classNames(`item`, { firstVisibleSlide: index == generateIndex?.month })} key={index}>
                                                <div>{item}</div>
                                            </SwiperSlide>
                                        )
                                        )}
                                    </Swiper>
                                    {isSwiper.month && <Swiper initialSlide={userDob ? months.indexOf(userDob?.month) + 2 : new Date().getMonth() + 2} thumbs={{ swiper: monthsSlider }} modules={[Thumbs]} />}
                                </div>
                                <div className='scroller'>
                                    <Swiper initialSlide={userDob ? days.indexOf(userDob?.date) : new Date().getDate() - 1} direction={"vertical"} onSlideChange={handleDayChange} onTouchMove={() => setIsSwiper((prevState) => ({ ...prevState, date: true }))} loop={true} spaceBetween={0} slidesPerView={7} centeredSlides={true} onSwiper={setDaysSlider}>
                                        {days?.map((item, index) => (
                                            <SwiperSlide className={classNames('item', { firstVisibleSlide: index == generateIndex?.day })} key={index}>
                                                <div>{item}</div>
                                            </SwiperSlide>
                                        )
                                        )}
                                    </Swiper>
                                    {isSwiper.date && <Swiper initialSlide={userDob ? days.indexOf(userDob?.date) + 2 : new Date().getDate() - 3} thumbs={{ swiper: daysSlider }} modules={[Thumbs]} />}
                                </div>
                                <div className='scroller'>
                                    <Swiper initialSlide={userDob ? years.indexOf(userDob?.year) : 30} direction={"vertical"} onSlideChange={handleYearChange} onTouchMove={() => setIsSwiper((prevState) => ({ ...prevState, year: true }))} loop={true} spaceBetween={0} slidesPerView={7} centeredSlides={true} onSwiper={setYearsSlider}>
                                        {years?.map((item, index) => (
                                            <SwiperSlide className={classNames('item', { firstVisibleSlide: index == generateIndex?.year })} key={index}>
                                                <div>{item}</div>
                                            </SwiperSlide>
                                        )
                                        )}
                                    </Swiper>
                                    {isSwiper.year && <Swiper initialSlide={userDob ? years.indexOf(userDob?.year) + 2 : 32} thumbs={{ swiper: yearsSlider }} modules={[Thumbs]} />}
                                </div>
                            </div>
                        </div>
                    </>
                    {/* } */}

                </div>
                <Button onBackClick={handleBack} disabled={!age || age < 18} onContinueClick={() => history.push('/self-onboarding/userGender')} />
            </UserAgeStyle>
        </PageTransition>
    )
}