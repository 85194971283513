import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { BusinessProfileCard } from './BusinessProfileCard';
import { Button } from 'components/elements/button/Button';
import { MdEdit } from 'react-icons/md';
import RegularInput from 'components/Input/RegularInput';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import Alert from 'components/elements/alert/Alert';
import { REACT_ENV} from '../../../../../constants';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';


const BusinessProfileStyle = styled.div`
  > .title {
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-size: ${({ theme }) => theme?.typography?.fontLarge};
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 32px;
  }
  .alert{
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top:12px;
  }
  ::-webkit-scrollbar-thumb{
    background-color: transparent; !important
  }
  .details {
    padding-top: 24px;
    position: relative;
    .btn_flex {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 0;
      top: 25px;
      .custom_button {
        position: relative;
        &:last-child {
          background: ${({ theme }) => theme?.colors?.primaryDefault};
          color: ${({ theme }) => theme?.colors?.white};
        }
      }
    }
    .details_holder {
      margin-bottom: 8px;
      max-width: 660px;
    }
    .details_title {
      font-size: ${({ theme }) => theme?.typography?.fontBase};
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .custom_button {
      padding: ${({ isEdit }) => (!isEdit ? '8px 8px 8px 16px' : '8px 16px')};
      font-size: ${({ theme }) => theme?.typography?.fontSmall};
      font-weight: 600;
      line-height: 16px;
      display: flex;
      gap: 8px;
      align-items: center;
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
      color: ${({ theme }) => theme?.colors?.primaryDark};
      background: ${({ theme }) => theme?.colors?.white};
      position: absolute;
      right: 0;
      top: 24px;
      z-index: 9;
      cursor: pointer;
      .icon {
        svg {
          display: block;
        }
      }
    }
  }
  .flex {
    display: flex;
    gap: 20px;
  }

  &.businessProfileEdit {
    label, input {
      color: ${({ theme }) => theme?.colors?.blackDefault};
    }
  }
`;

const FormGroupStyle = styled.div`
  margin-bottom: ${({ isEdit }) => (isEdit ? '16px' : '24px')};
  width: 100%;
  label {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.grayDark};
    margin-bottom: 8px;
    display: inline-block;
  }
  input {
    height: 40px;
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    overflow-wrap: anywhere;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
`;

export const BusinessProfile = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [changedValues, setChangedValues] = useState({});
    const [errorMessage, setErrorMessage] = useState({name: "", username: ""});
    const [isButtonEnabled, setIsButtonEnabled]=useState(true);
    const [updatedprovider,{isLoading:isBusinessProfileSaving}] = useRequest(`provider/businessProfile`, 'put');
    const { provider, setProvider, user, setUser, isSuper } = useAppContext();
    const { BusinessProfile } = provider || {};
    const [addressInfo] = BusinessProfile || [];
    const [providerImage, setProviderImage] = useState(BusinessProfile?.[0]?.image || '/images/avatar.png')
    const username = user?.type?.includes('super') ? provider?.user?.username : user?.username
    const imitateProviderStorage=sessionStorage.getItem("imitateProvider");
    const [alertData, setAlertData] = useState({
        type: '',
        title: '',
      });
      const enableButton = (data)=>{
        const { value } = data;
        const { name, street, zip, state, city } = addressInfo;
    
        const isMatching =
            name === value ||
            street === value ||
            zip === value ||
            state === value ||
            city === value ||
            username === value;
    
        setIsButtonEnabled(isMatching);
       
      }  

      const imitateProviderEmail = useMemo(() => {
        const parsedId = JSON.parse(imitateProviderStorage)?.user?.email;
        return parsedId
    }, [imitateProviderStorage]);
    
    const handleChange = (e) => {
      enableButton(e.target)
        const { name, value } = e.target;
        (name === "name" && value?.length > 0) && setErrorMessage((prevState) => ({...prevState, name: ""}));
        (name === "username" && value.length > 0) && setErrorMessage((prevState) => ({...prevState, username: ""}));
        if (name === 'phoneNumber') {
            const numericValue = value?.replace(/\D/g, '');
            const truncatedValue = numericValue.slice(0, 13);

            setChangedValues((prevState) => ({
                ...prevState,
                [name]: truncatedValue,
            }));
        } else {
          if (name === 'name' && value?.length > 40) return;
            setChangedValues((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        if(name === "username") {
          const trimValue = value.trim();
          setChangedValues((prevState) => ({
            ...prevState,
            [name]: trimValue,
        }));
        }
    };

    const handleSave = useCallback( async (changedValues) => {
        try {
          if (errorMessage?.name?.length || errorMessage?.username?.length || isBusinessProfileSaving) return;
            const updatedBusinessProfile = {
                ...addressInfo,
                ...changedValues,
                image:providerImage
            };
            updatedBusinessProfile.deliveryFee = provider.deliveryFee;
            setProvider((prevProvider) => ({
                ...prevProvider,
                BusinessProfile: [updatedBusinessProfile],
            }));
            setUser((prevUser) => ({
                ...prevUser,
                ...changedValues,
              }));
                const body = {
                ...changedValues, id:addressInfo?.id, image:providerImage
            };
            await updatedprovider({ body });
            showSuccessMessage('You have successfully updated business profile');
            setIsEdit(false);
            setIsButtonEnabled(true)
        } catch (error) {
            console.error('Error occurred while saving changes:', error);
            showErrorMessage(error.message);
            setIsButtonEnabled(true)
        }
    },[providerImage,isBusinessProfileSaving, addressInfo]);

    const showSuccessMessage = (title) => {
        setAlertData({
          type: 'success',
          title: title,
        });
        setTimeout(() => {
          setAlertData({ type: '', title: '' });
        }, 5000);
      };
      
      const showErrorMessage = (title) => {
        setAlertData({
          type: 'error',
          title: title,
        });
        setTimeout(() => {
          setAlertData({ type: '', title: '' });
        }, 5000);
      };

      const isMountedRef = useRef(false);
      useEffect(() => {
        if (isMountedRef.current) {
          handleSave();
        } else {
          isMountedRef.current = true;
        }
      }, [providerImage]);

      const handleBlur = useCallback((e, val) => {
        const {value} = e.target;
        (value.length < 1) && setErrorMessage((prevState) => ({...prevState, [val]: `${val} is not allowed to be empty`}));
      }, [errorMessage])

  function capitalizedHostname() {
    const isProduction = process.env.REACT_APP_ENV === REACT_ENV.PRODUCTION;
    const hostname = process.env.REACT_APP_CLIENT_URL;

    // if (isProduction) {
      const parts = hostname.split('//');
      parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
      return parts[1];
    // } else {
    //   return hostname.charAt(0).toUpperCase() + hostname.slice(1);
    // }
  };
    
  useEffect(()=>{
    ReactTooltip.rebuild();
    window.scrollTo(0, 0);
  },[])

    return (
        <BusinessProfileStyle theme={theme} isEdit={isEdit} className={classNames({businessProfileEdit: isEdit})}>
            {alertData.type && (
        <div className="alert">
          <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', title: '' })}  />
        </div>
      )}
            <h2 className="title">Business profile</h2>
            <BusinessProfileCard providerImage={providerImage} setProviderImage={setProviderImage} showSuccessMessage={showSuccessMessage} showErrorMessage={showErrorMessage}/>
            <div className="details">
                {!isEdit ? (
                    <Button
                        title="Edit"
                        className="custom_button"
                        onClick={() => setIsEdit(true)}
                        iconRight={<MdEdit />}
                    />
                ) : (
                    <div className="btn_flex">
                        <Button title="Cancel" size={"small"} type={'secondary'} onClick={() => setIsEdit(false)} />
                        <Button title="Save"  size={"small"} type={"dark"} onClick={() => handleSave(changedValues)} isDisabled={isButtonEnabled}/>
                    </div>
                )}
                <div className="details_holder">
                    <h3 className="details_title">Business Information</h3>

                    <FormGroupStyle theme={theme} isEdit={isEdit}>
                        <label>Store name</label>
                        {!isEdit ? (
                            <p className="value">{addressInfo?.name}</p>
                        ) : (
                            <RegularInput
                                type="text"
                                value={changedValues?.name ?? addressInfo?.name}
                                name="name"
                                onChange={handleChange}
                                message={errorMessage.name}
                                onBlur={(e) => handleBlur(e, "name")}
                            />
                        )}
                    </FormGroupStyle>

                    <div className="flex">
                        <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <label>Email address</label>
                            {!isEdit ? (
                                <p className="value">{isSuper ? imitateProviderEmail : user?.email}</p>
                            ) : (
                                <RegularInput
                                    type="text"
                                    value={isSuper ? (changedValues?.email ?? imitateProviderEmail) : (changedValues?.email ?? user?.email)}
                                />
                            )}
                        </FormGroupStyle>

                        <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <label>Phone</label>
                            {!isEdit ? (
                                <p className="value">{addressInfo?.phoneNumber}</p>
                            ) : (
                                <RegularInput
                                    type="text"
                                    value={changedValues?.phoneNumber ?? addressInfo?.phoneNumber}
                                    name="phoneNumber"
                                    onChange={handleChange}
                                />
                            )}
                        </FormGroupStyle>
                    </div>
                </div>
                <div className='details_holder'>
                    <h3 className='details_title'>URL</h3>
                    <div className='flex'>
                    <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <p className='value' data-tip={capitalizedHostname()+"/store/"+username} data-for="username">
                                {capitalizedHostname()}/store/
                                {isEdit ? (
                                    <div style={{ display: 'inline-block' , marginLeft: '11px'}}>
                                        <RegularInput type='text' name="username" onChange={handleChange} value={changedValues?.username ?? username}  width={"320px"}
                                        message={errorMessage.username}
                                        onBlur={(e) => handleBlur(e, "username")} 
                                        disabled={!isSuper}
                                        />
                                    </div>
                                ) : (
                                  username?.replace(/(.{20})..+/, "$1")+'...'
                                )}
                            </p>
                        </FormGroupStyle>

                    </div>
                </div>        
                <div className="details_holder">
                    <h3 className="details_title">Address</h3>
                    <div className="flex">
                        <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <label>Street</label>
                            {!isEdit ? (
                                <p className="value">{addressInfo?.street}</p>
                            ) : (
                                <RegularInput
                                    type="text"
                                    value={changedValues?.street ?? addressInfo?.street}
                                    name="street"
                                    message={errorMessage?.street}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 100) {
                                        handleChange(e);
                                        setErrorMessage((prevState) =>({...prevState, street:""}));
                                      } else {
                                        setErrorMessage((prevState) =>({...prevState, street:"street should not be greater than 100"}));
                                        setProvider((prevState) => ({
                                          ...prevState,
                                          ["street"]: e.target.value.substring(0, 100)
                                        }));
                                      }
                                      }}              
                                />
                            )}
                        </FormGroupStyle>

                        <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <label>City</label>
                            {!isEdit ? (
                                <p className="value">{addressInfo?.city}</p>
                            ) : (
                                <RegularInput
                                    type="text"
                                    value={changedValues?.city ?? addressInfo?.city}
                                    name="city"
                                    message={errorMessage?.city}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 100) {
                                        handleChange(e);
                                        setErrorMessage((prevState) => ({...prevState ,city:""}));
                                      } else {
                                        setErrorMessage((prevState) => ({...prevState ,city:"city should not be greater than 100"}));
                                        setProvider((prevState) => ({
                                          ...prevState,
                                          ["city"]: e.target.value.substring(0, 100)
                                        }));
                                      }
                                      }}          
                                />
                            )}{' '}
                        </FormGroupStyle>
                    </div>

                    <div className="flex">
                        <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <label>State</label>
                            {!isEdit ? (
                                <p className="value">{addressInfo?.state }</p>
                            ) : (
                                <RegularInput
                                    type="text"
                                    value={changedValues?.state ?? addressInfo?.state}
                                    name="state"
                                    message={errorMessage?.state}
                                    onChange={(e) => {
                                      if (e.target.value.length <= 100) {
                                        handleChange(e);
                                        setErrorMessage((prevState) => ({...prevState ,state:""}));
                                      } else {
                                        setErrorMessage((prevState) => ({...prevState ,state:"state should not be greater than 100"}));
                                        setProvider((prevState) => ({
                                          ...prevState,
                                          ["state"]: e.target.value.substring(0, 100)
                                        }));
                                      }
                                      }}                 
                                />
                            )}{' '}
                        </FormGroupStyle>
                        <FormGroupStyle theme={theme} isEdit={isEdit}>
                            <label>Zip code</label>
                            {!isEdit ? (
                                <p className="value">{addressInfo?.zip }</p>
                            ) : (
                              <RegularInput
                              type="number"
                              value={changedValues?.zip ?? addressInfo?.zip}
                              name="zip"
                              message={errorMessage?.zip}
                              pattern="[0-9]*"
                              onChange={(e) => {
                                  const input = e.target.value;
                                  if (input.length <= 10) {
                                      handleChange(e);
                                      setErrorMessage({ zip: "" });
                                  } else {
                                      setErrorMessage({ zip: "Zip must not exceed 10 characters" });
                                  }
                                  setProvider((prevState) => ({
                                      ...prevState,
                                      ["zip"]: input.substring(0, 10)
                                  }));
                              }}
                          />
                          )}
                        </FormGroupStyle>
                    </div>
                </div>
            </div>
            <ReactTooltip id="username" place="top" effect="solid">
            </ReactTooltip>
        </BusinessProfileStyle>
    );
};

