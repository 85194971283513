import { useEffect } from 'react';
import styled from 'styled-components';

const AlertWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 120;

  & + & {
    .mask {
      background-color: transparent;
    }
  }

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(81, 99, 93, 0.65) ;
  }

  .alert-container {
    z-index: 2;
    width: 474px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;

    .title-desc-wrapper {
      padding: 40px 0 30px;
      border-bottom: 1px solid #f6f5f8;
    }

    .title {
      margin-bottom: 15px;
      color: #0b1d17;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    p {
      color: #51635d;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .btn-wrapper {
      padding: 24px 0;
      display: flex;
      justify-content: center;
      margin-left: 35px;
    margin-right: 34px;
    .apply{
      border-radius: 21px;
      width: 138px;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display:flex;
      justify-content:center;
      align-items:center;
  }
      button {
        width: 130px;
        height: 40px;
        border-radius: 21px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        cursor: pointer;

        &.first-child{ 
          margin-left: 34px;
        },
        &.discard-btn {
          margin-right: 6px;
          margin-left: 0px;
          background-color: #fff;
          border: 1px solid #97dc76 !important;
        }

        &:last-child {
          background-color: #52c41a;
          color: #fff;
          border: 1px solid #52c41a !important;
        }
      }
    }
  }
  @media (max-width: 991px) {
    z-index:200;
    .alert-container {

      .btn-wrapper {
        display: flex;
        margin:0;

        button{
          width:39% !important;
        }

        button:first-child {
           margin-right: 6px;      
        }
     button:last-child {
        //  width: 266px;
          //margin-top: 14px;
          justify-content: center;
          align-items: center;
          // margin-left: 34px;
          //  margin-right: 35px;
        }

        button:disabled {
          background-color: #e0e3e0 !important;
          color: #949d9b !important;
          cursor: not-allowed !important;
          margin-right: 35px ;
        }
      }
    }
  }
  @media(max-width:479px){
    .alert-container {
      width: 90%;

    }

  }
  
`;

export default function Alert({
  title,
  desc,
  cancel,
  cancelText,
  discard,
  discardText,
  ok,
  okText,
  desc2,
  disabled,
  style
}) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.overflowY = "overlay"
    };
  }, []);
  
  return (
    <AlertWrapper>
      <div className="mask" />
      <div className="alert-container">
        <div className="title-desc-wrapper">
          <div className="title">{title}</div>
          <p>{desc}</p>
          <p>{desc2}</p>
        </div>
        <div className="btn-wrapper">
          {cancel && <button style={style} onClick={() => cancel()} >{cancelText}</button>}
        {discard && 
            <button className="discard-btn" onClick={() => discard()}>
              {discardText}
            </button>
          } 
          {<button className='apply' onClick={() => {
            ok();
          }} disabled={disabled}>{okText}</button>}
        </div>
      </div>
    </AlertWrapper>
  );
}
