import { useState, useEffect, useMemo, useCallback } from 'react';
import produce from 'immer';
import { useHistory } from 'react-router';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { motion, AnimatePresence } from 'framer-motion';
import HurryOrder from 'components/shop/KitchenHome/HurryOrder';
import KitchensList from 'components/shop/KitchenHome/KitchensList';
import KitchenMealDetails from 'components/shop/FavoriteKitchen/KitchenMealDetails';
import KitchenOurMission from 'components/shop/KitchenHomeDesktop/KitchenOurMission';
import KitchenCardDesktop from 'components/shop/KitchenHomeDesktop/KitchenCardDesktop';
import FavoriteKitchenCategories from 'components/shop/KitchenHome/FavoriteKitchenCategories';
import useVisibility from 'components/shop/FavoriteKitchen/KitchenMealDetails/VisibilityHook';
import { KitchenHomeDesktopWrapper, MealDetailsModalWrapper } from './style';
import { useVisitorContext } from 'context/visitorContext';
import { filterKitchens } from 'utils';
import { getEarliestTime } from '../providerTimes';
import moment from 'moment';

export default function kitchenHomeDesktop() {
  const history = useHistory();
  const [isAddWithoutQty, setIsAddWithoutQty] = useState(false);
  const [mealInfo, setMealInfo] = useState({});
  const [mealDetails, setMealDetails] = useState({});
  const [generateMealDetails] = useRequest();
  const { profile, isModal, setIsModal, user,currentDeliveryAddress} = useAppContext();
  const favoriteKitchenData = profile?.favoriteKitchenData;
  const {
    storeCategoriesMeals,
    kitchensList,
    updateSettings,
    settings: { provider }
  } = user ? useShopContext() : useVisitorContext();
  const [beforeCheckoutSubmitShown, beforeCheckoutSubmitRef] = useVisibility(isModal);
 
  const getMealDetails = async () => {
    const path = `meal/list?mealId=${mealDetails?.id}&providerId=${mealDetails?.providerId}`;
    await generateMealDetails({ path })
      .then((res) => {
        setMealInfo(res?.data?.data[0]);
      })
      .catch((err) => console.log({ err }));
  };

  const moveToMeals = () => {
    updateSettings(
      produce((draft) => {
        draft.provider = favoriteKitchenData;
      }),
    );
    history.push(`/store/${provider?.username}`);
    window.scrollTo(0, 0);
  };

  const closeMealDetails = () => {
    setIsModal(false);
    moveToMeals();
    setMealInfo({});
  };

  useEffect(() => {
    if (isModal) {
      getMealDetails();
    }
  }, [isModal]);

  const filteredKitchensList = useMemo(() => {
    const result = filterKitchens(kitchensList, currentDeliveryAddress);
    return result?.filteredKitchenList;
  }, [kitchensList, currentDeliveryAddress]);

  const earliestDelivery = useMemo(() => {
    let result = {};
    const deliveryDateTime = getEarliestTime(favoriteKitchenData?.schedule, "delivery");
    const momentEarliestDelivery = moment(deliveryDateTime);

    if (momentEarliestDelivery.isValid()) {
        result.stringDay = momentEarliestDelivery.format('dddd').toLowerCase();
        result.month = momentEarliestDelivery.format('MMMM');
        result.dayNumber = momentEarliestDelivery.date();
        result.year = momentEarliestDelivery.year();
    }

    return { dateTime: deliveryDateTime, ...result };
}, [favoriteKitchenData?.schedule]);

  const onKitchenBannerImgClick = useCallback(() => {
    const { username = null } = favoriteKitchenData;
    if (username) {
      const url = `/store/${username}`
      history.push(url);
    }
  }, [favoriteKitchenData?.username]);

  const onViewMenuButtonClient = () => {
    history.push(`/store/${provider.username}`);
    sessionStorage.setItem('redirectedFrom', 'kitchenListing');
  };

  return (
    <>
      <KitchenHomeDesktopWrapper>
        {favoriteKitchenData?.businessProfileId && (
          <>
            <div className="kitchen-home-container">
              <HurryOrder earliestDelivery={earliestDelivery} favoriteKitchenData={favoriteKitchenData} />
              <KitchenCardDesktop
                className="favorite-kitchen-card"
                cardImg={favoriteKitchenData?.storeImages[0] || '/images/sexy_just_eat_now.jpg'}
                tag={favoriteKitchenData?.tag}
                title={favoriteKitchenData?.name}
                date={`${earliestDelivery.stringDay}, ${earliestDelivery.month} ${earliestDelivery.dayNumber}`}
                mealPrepared={favoriteKitchenData?.mealPrepared}
                state={favoriteKitchenData?.state}
                city={favoriteKitchenData?.city}
                btnText="View menu"
                onClickBtn={onViewMenuButtonClient}
                onKitchenBannerImgClick={onKitchenBannerImgClick}
              />
              <FavoriteKitchenCategories
                storeCategoriesMeals={storeCategoriesMeals}
                setOpen={setIsModal}
                setMealDetails={setMealDetails}
                setIsAddWithoutQty={setIsAddWithoutQty}
                moveToMeals={moveToMeals}
              />
            </div>
            <KitchenOurMission />
          </>
        )}
        {!!kitchensList?.length && (
          <>
            <KitchensList
              kitchensList={filteredKitchensList}
              storeCategoriesMeals={storeCategoriesMeals}
              favoriteKitchenData={favoriteKitchenData}
            />
            {!favoriteKitchenData?.businessProfileId && <KitchenOurMission />}
          </>
        )}
      </KitchenHomeDesktopWrapper>
      <AnimatePresence>
        {isModal && Object.keys(mealInfo)?.length && (
          <MealDetailsModalWrapper>
            <div
              className="meal-details-mask"
              onClick={() => {
                setIsModal(false);
                setMealInfo({});
              }}
            />
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="meal-details-motion"
            >
              <KitchenMealDetails
                meal={mealDetails}
                mealInfo={mealInfo}
                open={isModal}
                setOpen={setIsModal}
                setMealInfo={setMealInfo}
                beforeCheckoutSubmitRef={beforeCheckoutSubmitRef}
                beforeCheckoutSubmitShown={beforeCheckoutSubmitShown}
                moveToMeals={moveToMeals}
              />
            </motion.div>
          </MealDetailsModalWrapper>
        )}
      </AnimatePresence>
    </>
  );
}
