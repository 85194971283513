import Styled from 'styled-components';
import ProgressMacroLine from './ProgressMacroLine';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import autoAnimate from '@formkit/auto-animate';
import { useAppContext } from 'context/appContext';
import FractionalSlider from './FractionalSlider';
import ServingSlider from './ServingSlider';
import NumberQuantitySlider from './NumberQuantitySlider';
import 'swiper/swiper-bundle.css';
import './swiperStyles.css'
import {calculateNutritionPerGram} from '../../../src/utils/index';
import PantryCalories from './PantryCalories';
import { ReactComponent as EditPencil } from 'assets/shop/pencil.svg';
import { useLocation } from 'react-router';

const MacrosInfoWrapper = Styled.div`
padding: 0 16px;
.serving{
    padding: 14px 0;
    display:flex;
    flex-direction: column;
    .size{
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #00092D;
        &:last-child{
            font-weight: 700;
            margin-left:4px;
            display: inline-flex;
            align-items: center;
            gap: 8px;
        }
    }
    .serving-info {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: rgba(81, 99, 93, 1);
      max-height:73px;
      margin-top: 11px;
     
    }
   
    .serve{
      text-align:center;
      width:50px;
      &:last-child{
        width:190px;
       // text-transform:capitalize;
      }
    }
    .main-div{
      display:flex;
      justify-content:space-between;
      max-height: 100px;
      position:relative;
      margin: 4px -10px 0 -10px;
      .picker-window {
        position: absolute;
        height: 24px;
        left: 0;
        top: 54%;
        transform: translateY(-65%);
        width: 94%;
        margin: 0 3%;
        background: #F6F5F8;
        border-radius: 4px;
        pointer-events: none;
      }
      &::after, &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2em;
        pointer-events: none;
        z-index: 3;
      }
      &::before {
        top: -1px;
        background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4));
      }
      &::after {
        bottom: -1px;
        background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.6));
      }
      .scroll{
        width: 20%;
        &:last-child{
          width: 60%;
        }
        .swiper-slide {
          color:#51635d;
          height:24px;
          text-align:center;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          line-height: 14px;
          background: rgba(0,0,0,0);
          transition: all .3s ease-in-out;
        }
        .swiper-slide.swiper-slide-active {
          opacity: 1;
          color: #51635D;
        }
        .swiper-slide-prev {
          transform: perspective(200px) rotateX(20deg);
        }
        .swiper-slide-next {
          transform: perspective(200px) rotateX(-20deg);
        }
        .swiper-slide-next + .swiper-slide {
          transform: perspective(200px) rotateX(-40deg);
        }
        .swiper-slide:not(.swiper-slide-prev, .swiper-slide-active, .swiper-slide-next, .swiper-slide-next + .swiper-slide) {
          transform: perspective(200px) rotateX(40deg);
        }
     }
    }
   
}
.progress-macros-wrapper{
  padding: 16px 4.79px 16px 6px;
  .isDiaryModal{
    &:first-child{
      margin-top: 0;
    }
    margin-top: 5px;
  }
}

.macros-info{
    padding: 16px 4.79px 16px 38px;
}
.macros-info div {
  padding:0 ;
  & + div{
    margin-top:6px;
  }
}
`;

export default function MealMacrosInfo({
  showMacros,
  servings,
  showServingInfo,
  setShowServingInfo,
  fractionalConstants,
  numberQtyConstants,
  setMacrosInfo,
  setSelectedSliderIndex,
  setSearchQuantity,
  gramsUnitConstant,
  currentDayinfo,
  hideProgressLine = false,
  isRecentLog=false,
  isDiaryEdit=false,
  mealInfo
}){
  const [activeIndex, setActiveIndex] = useState(0);
  const [fractionSliderIndex,setFractionSliderIndex]=useState(0)
  const [activeNumberQtyIndex, setActiveNumberQtyIndex] = useState(0);
  const [activeFractionalIndex, setActiveFractionalIndex] = useState(0);
  const {profile:{dietMetrics={}}}=useAppContext()
  const parent = useRef(null);
  const fractionalRef=useRef(null)
  const fullQtyRef=useRef(null)
  const servingSizeRef = useRef(null)
  const serving_description = Array.isArray(servings) ? servings[activeIndex]?.serving_description : servings?.serving_description;
  const servingSize = useMemo(() => {
    const serving = Array.isArray(servings) ? servings[activeIndex] : servings;
    if (!serving) {
      return "";
    }
    const descriptionSplit = serving_description.split(' ');
    const unit=descriptionSplit[1];
    const {number_of_units}=serving;
    if(activeFractionalIndex===0 && activeNumberQtyIndex===0 && unit!=="g"){
      return `${parseInt(number_of_units)} ${unit}`;
    }
    const qty = unit === 'g' ? gramsUnitConstant[activeNumberQtyIndex]: activeNumberQtyIndex * number_of_units + (activeFractionalIndex > 0 ? fractionalConstants[activeFractionalIndex] * number_of_units : 0);
    return `${qty} ${unit}`
  }, [activeIndex, servings, activeNumberQtyIndex,activeFractionalIndex,isRecentLog]);

  const toFraction = useCallback((decimal) => {
    if (decimal === 0) {
      return '0/1';
    }
  
    let sign = decimal < 0 ? '-' : '';
    decimal = Math.abs(decimal);
  
    const eps = 1.0E-6;
    let num = 1, denom = 1;
    let fraction = num / denom;
  
    while (Math.abs(decimal - fraction) > eps) {
      if (fraction < decimal) {
        num++;
      } else {
        denom++;
        num = Math.round(decimal * denom);
      }
  
      fraction = num / denom;
    }
  
    return sign + num + '/' + denom;
  }, []);
  

  function calculateMetricMultiplier(numberOfUnits) {
    const metricBase = 1; // 1 kg
    const servingBase = 1; // full serving
    
    if (numberOfUnits === servingBase) {
      return metricBase;
    } else {
      const multiplier = metricBase / numberOfUnits;
      return Math.round(multiplier);
    }
  }


  const calculateServingWithoutUnitOrAmount = useCallback((num = 0, fraction = 0, serving = {}) => {
    let calories = serving.calories;
    let fats = serving?.fat || serving?.fats;
    let proteins = serving.protein || serving?.proteins;
    let carbs = serving.carbohydrate || serving?.carbs;

    if (fraction > 0 && num > 0) {
      const multiplier = fraction + num;
      calories = calories * multiplier;
      fats = fats * multiplier;
      carbs = carbs * multiplier;
      proteins = proteins * multiplier;
    } else if (fraction > 0) {
      calories = calories * fraction;
      fats = fats * fraction;
      proteins = proteins * fraction;
      carbs = carbs * fraction;
    } else if (num > 0) {
      calories = calories * num;
      carbs = carbs * num;
      proteins = proteins * num;
      fats = fats * num;
    }
    setMacrosInfo({
      serving_id: serving.serving_id,
      calories: calories,
      fat: fats,
      protein: proteins,
      carbohydrate: carbs,
    });
    setSearchQuantity(num);
    setActiveNumberQtyIndex(num);
    return { calories, carbs, fats, proteins };

  }, []);
  const servingsMacros = useMemo(() => {
    const selectedServing = Array.isArray(servings)?servings[activeIndex]:servings;
    const selectedFraction = fractionalConstants[fractionSliderIndex];
    const selectedNumberUnit = numberQtyConstants[activeNumberQtyIndex];
    if (!showServingInfo && !isRecentLog) {
      const calories = selectedServing.calories;
      const carbs = selectedServing?.carbohydrate ;
      const fats = selectedServing?.fat;
      const proteins = selectedServing?.protein
      setMacrosInfo({
        serving_id: selectedServing.serving_id,
        calories,
        fat: fats,
        protein: proteins,
        carbohydrate: carbs
      })
      return { calories, fats, carbs, proteins }
    }
    if (!showServingInfo && isRecentLog) {
      const calories = mealInfo.cals;
      const fats = mealInfo.fat;
      const carbs = mealInfo.carbs;
      const proteins = mealInfo.protein;
      setMacrosInfo({
        serving_id: selectedServing.serving_id,
        calories,
        fat: fats,
        protein: proteins,
        carbohydrate: carbs
      })
      return { calories, fats, carbs, proteins }
    }
    if (!selectedServing?.metric_serving_amount || !selectedServing?.number_of_units) {
      return calculateServingWithoutUnitOrAmount(selectedNumberUnit, selectedFraction, selectedServing);

    }
    const {
      caloriesPerGram,
      fatPerGram,
      proteinPerGram,
      carbsPerGram,
    } = calculateNutritionPerGram(selectedServing);
    const isServingFractional = selectedServing.number_of_units % 1 !== 0;
    const metricMultiplier = calculateMetricMultiplier(selectedServing.number_of_units);
    const metricAmount = isServingFractional?selectedServing.metric_serving_amount* metricMultiplier: selectedServing.metric_serving_amount;
    const isHundredServingSelected=Number(selectedServing.metric_serving_amount)===100;
    
    if (selectedServing.metric_serving_unit === "oz") {
      let ozInGram=selectedServing.metric_serving_amount * 28.30 ;
      if (selectedNumberUnit > 0 && selectedFraction === "--") {
        ozInGram = ozInGram * selectedNumberUnit;
      } else if (selectedNumberUnit > 0 && selectedFraction !== "--") {
        ozInGram = ozInGram * (selectedNumberUnit + selectedFraction)
      } 
      const calories = caloriesPerGram * ozInGram;
      const fats = fatPerGram * ozInGram;
      const proteins = proteinPerGram * ozInGram;
      const carbs = carbsPerGram * ozInGram;
      setMacrosInfo({
        serving_id: selectedServing.serving_id,
        calories,
        fat: fats,
        protein: proteins,
        carbohydrate: carbs
      })
      return {
        calories,
        fats,
        proteins,
        carbs
      }
    }
    if(selectedServing.isCustom){
      const calories = selectedServing.calories * gramsUnitConstant[activeNumberQtyIndex];
      const carbs = selectedServing.carbohydrate * gramsUnitConstant[activeNumberQtyIndex];
      const fats = selectedServing.fat * gramsUnitConstant[activeNumberQtyIndex];
      const proteins = selectedServing.protein * gramsUnitConstant[activeNumberQtyIndex]
        setMacrosInfo({
          serving_id: "custom",
          calories,
          fat:fats,
          protein: proteins,
          carbohydrate: carbs,
        });
        setSearchQuantity(gramsUnitConstant[activeNumberQtyIndex],"g")
      return {
        calories,
        carbs,
        fats,
        proteins
      }
    }
    
    if(isHundredServingSelected ){
       const calories=caloriesPerGram*gramsUnitConstant[activeNumberQtyIndex];
       const carbs=carbsPerGram*gramsUnitConstant[activeNumberQtyIndex];
       const fats=fatPerGram*gramsUnitConstant[activeNumberQtyIndex];
       const proteins=proteinPerGram*gramsUnitConstant[activeNumberQtyIndex];
       setMacrosInfo({
        serving_id: selectedServing.serving_id,
        calories,
        fat:fats,
        protein: proteins,
        carbohydrate: carbs,
      });
      setSearchQuantity(gramsUnitConstant[activeNumberQtyIndex],"g")
      return {
        calories,
        carbs,
        fats,
        proteins      }}

    if (toFraction(selectedServing.number_of_units) === toFraction(selectedFraction) && selectedNumberUnit === 0) {
      setMacrosInfo({
        serving_id: selectedServing.serving_id,
        calories: selectedServing.calories,
        fat: selectedServing.fat,
        protein: selectedServing.protein,
        carbohydrate: selectedServing.carbohydrate,
      });
      setSearchQuantity(selectedFraction);
      return {
        calories: selectedServing.calories,
        fats: selectedServing.fat,
        proteins: selectedServing.protein,
        carbs: selectedServing.carbohydrate,
      };
    }
  
    let calories = selectedServing.calories;
    let fats = selectedServing.fat;
    let proteins = selectedServing.protein;
    let carbs = selectedServing.carbohydrate;

    if (selectedNumberUnit > 0) {
      if (selectedFraction !== "--" ) {
        calories = metricAmount * caloriesPerGram * (selectedNumberUnit + selectedFraction);
        fats = metricAmount * fatPerGram * (selectedNumberUnit + selectedFraction);
        proteins = metricAmount * proteinPerGram * (selectedNumberUnit + selectedFraction);
        carbs = metricAmount * carbsPerGram * (selectedNumberUnit + selectedFraction);
      } else {
        calories = metricAmount * caloriesPerGram * selectedNumberUnit;
        fats = metricAmount * fatPerGram * selectedNumberUnit;
        proteins = metricAmount * proteinPerGram * selectedNumberUnit
        carbs = metricAmount * carbsPerGram * selectedNumberUnit;
      }
    } else if (selectedFraction !== "--") {
      calories = metricAmount * caloriesPerGram * selectedFraction;
      fats = metricAmount * fatPerGram * selectedFraction;
      proteins = metricAmount * proteinPerGram * selectedFraction;
      carbs = metricAmount * carbsPerGram * selectedFraction;
    }
  
    setMacrosInfo({
      serving_id: selectedServing.serving_id,
      calories,
      fat: fats,
      protein: proteins,
      carbohydrate: carbs,
    });
  
    setSearchQuantity(selectedNumberUnit);
  
    return {
      calories,
      fats,
      proteins,
      carbs,
    };
  }, [activeIndex, fractionSliderIndex, activeNumberQtyIndex,showServingInfo,isRecentLog]);
  
  useEffect(() => {
    setSelectedSliderIndex({ number_of_units: servings[activeIndex]?.number_of_units, selectedQuantity: activeNumberQtyIndex.toFixed(3), measureIndex: activeFractionalIndex, serving_description: servings[activeIndex]?.serving_description, serving_id: servings[activeIndex]?.serving_id, ...servingsMacros, isCustom: true })
  }, [servingsMacros]);


  useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent]);


  const servingUnit = (index) =>{
    const serving = Array.isArray(servings) ? servings[index] : servings;
    if (!serving) {
      return '';
    }
    if(serving_description){
    const { metric_serving_unit, serving_description } = serving;  
    if(serving_description == '100 g'){
      return 'g'
    }
    else if(serving_description?.length > 25){
      return serving_description.substring(0, 25) + "...";
    }
    else{
      return serving_description;
    }
  }
    else{
    return '';
    }
  }

  const userConsumedMacros = useMemo(()=>{
     let calories = currentDayinfo?.totalCals;
     let carbs = currentDayinfo?.consumedInfo?.carbs;
     let fat = currentDayinfo?.consumedInfo?.fat;
     let protein = currentDayinfo?.consumedInfo?.protein;

     if(currentDayinfo){
      calories = dietMetrics?.calories - calories;
      carbs = dietMetrics?.macros?.carbs - carbs;
      fat = dietMetrics?.macros?.fat - fat;
      protein = dietMetrics?.macros?.protein - protein;
     }
     return {
      calories,
      carbs,
      fat,
      protein
     }
  },[currentDayinfo,dietMetrics])
    return(
        <MacrosInfoWrapper servings={servings}>
            <>
            <div className='serving' ref={parent} >
            <div onClick={(e)=>{
              e.stopPropagation();
              setShowServingInfo(!showServingInfo)
            }}>
            <span className='size'>Serving size:</span> 
            <span  className='size'>{servingSize} {!showServingInfo && <EditPencil />}</span>
            </div>
             {showServingInfo && (
              <>
                {/* {Array.isArray(servings) ? ( */}
                   
                    <div className='main-div'>
                      <div class="picker-window" />
                      <div className='scroll'>
                        <NumberQuantitySlider
                          items={serving_description ==="100 g" ? gramsUnitConstant : numberQtyConstants}
                          activeNumberQtyIndex={activeNumberQtyIndex}
                          setActiveNumberQtyIndex={setActiveNumberQtyIndex}
                          fullQtyRef={fullQtyRef}
                          servings={servings}
                          servingSliderIndex={activeIndex}
                          setservingSliderIndex={setActiveIndex}
                          fractionalConstants={fractionalConstants}
                          toFraction={toFraction}
                          activeFractionalIndex={activeFractionalIndex}
                        />
                      </div>
                      <div className='scroll'>
                        <FractionalSlider
                          fractionalConstants={fractionalConstants}
                          activeFractionalIndex={activeFractionalIndex}
                          setActiveFractionalIndex={setActiveFractionalIndex}
                          fractionalRef={fractionalRef}
                          servings={servings}
                          toFraction={toFraction}
                          servingSliderIndex={activeIndex}
                          setservingSliderIndex={setActiveIndex}
                          setFractionSliderIndex={setFractionSliderIndex}
                          items={serving_description ==="100 g" ? gramsUnitConstant : numberQtyConstants}
                          activeNumberQtyIndex={activeNumberQtyIndex}
                          serving_description={serving_description}
                        />
                      </div>
                      <div className='scroll'>
                      <ServingSlider
                          servings={servings}
                          activeIndex={activeIndex}
                          setActiveIndex={setActiveIndex}
                          servingUnit={servingUnit}
                          servingSizeRef={servingSizeRef}
                        />
                      </div>
                      </div>
                </>
             )
             }
            </div>
            {!hideProgressLine && 
            <div className="progress-macros-wrapper">
              <PantryCalories diaryMeals={servingsMacros?.calories || 0} isDiaryModal={true} />
              <div className="containerr">
                <ProgressMacroLine
                  itemContent="Fats"
                  consumedMealItem={servingsMacros?.fats}
                  userDietMacro={currentDayinfo?.consumedInfo?.fat ? userConsumedMacros?.fat > 0 ? Math.round(userConsumedMacros?.fat) : Math.round(dietMetrics?.macros?.fat) : Math.round(dietMetrics?.macros?.fat)}
                  lineColor={servingsMacros?.fats > 0 ? "#EE786B" : "#e0e3e0"}
                  isDiaryModal={true}
                  weight="g"
                  fixed
                  showMacros={showMacros}
                  dietMetricsValue={dietMetrics?.fat}
                  currentDayValue={currentDayinfo?.fat}
                />
                <ProgressMacroLine
                  itemContent="Proteins"
                  consumedMealItem={servingsMacros?.proteins}
                  userDietMacro={currentDayinfo?.consumedInfo?.protein ? userConsumedMacros?.protein > 0 ? Math.round(userConsumedMacros?.protein) : Math.round(dietMetrics?.macros?.protein) : Math.round(dietMetrics?.macros?.protein)}
                  lineColor={servingsMacros?.proteins > 0 ? "#8ACEBF" : "#e0e3e0"}
                  isDiaryModal={true}
                  weight="g"
                  fixed
                  showMacros={showMacros}
                  dietMetricsValue={dietMetrics?.protein}
                  currentDayValue={currentDayinfo?.consumedInfo?.protein}
                />
                <ProgressMacroLine
                  itemContent="Carbs"
                  consumedMealItem={servingsMacros?.carbs}
                  userDietMacro={currentDayinfo?.consumedInfo?.carbs ? userConsumedMacros?.carbs > 0 ? Math.round(userConsumedMacros?.carbs) : Math.round(dietMetrics?.macros?.carbs) : Math.round(dietMetrics?.macros?.carbs)}
                  lineColor={servingsMacros?.carbs > 0 ? "#2399BF" : "#e0e3e0"}
                  isDiaryModal={true}
                  weight="g"
                  fixed
                  showMacros={showMacros}
                  dietMetricsValue={dietMetrics?.carbs}
                  currentDayValue={currentDayinfo?.consumedInfo?.carbs}
                />
              </div>
            </div> 
            }
            </>
        </MacrosInfoWrapper>
    )
}

