import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const DeliveryAddressWrapper = styled.div`
  position: relative;
  font-family: Gilroy;

  .input-mask {
    position: fixed;
    inset: 0;
    z-index: 5;
  }
  .error-message {
    color: #eb3223;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .input-container {
    position: relative;
    &.error {
      margin: 14px 0 8px;
      input {
        border: 1px solid #fec8b4;
        background: #fef1f0;
        color: #eb3223;
      }
    }
    &:hover {
      button{
        background-color: #fff;
      }
    }
    img {
      position: absolute;
      left: 15px;
      top: 14px;
      width: ${({ landingPage }) => (landingPage ? '24px' : '16px')};
      height: ${({ landingPage }) => (landingPage ? '24px' : '16px')};
    }
    button{
      position: absolute;
      height: 38px;
      left: 450px;
      top: 8px;
      padding: 10px 16px 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 21px;
      color: var(--Primary-Darker, #00092D);
      text-align: center;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      transition: background-color 0.5s ease;
      border: 0px solid var(--New-757E89, #757E89);
      background: #F0F2F4;  
      @media (min-width: 1025px) and (max-width: 1140px) {
        left: 315px;
      }    
    }
    input {
      width: ${({ landingPage }) => (landingPage ? '565px' : '450px')};
      height: ${({ landingPage }) => (landingPage ? '54px' : '44px')};
      padding: ${({ landingPage }) => (landingPage ? '14px 24px 14px 49px' : '0 17px 0 41px')};
      background-color: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.grayLight};
      border-radius: ${({ landingPage }) => (landingPage ? '27px' : '6px')};
      transition: border 0.2s linear 0s;
      @media (min-width: 1025px) and (max-width: 1140px) {
        width: 431px;
      }  

      &::placeholder {
        color: var(--Medium-Grey, #51635D);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
              }
      &:focus {
        border: 3px solid ${theme.colors.grayLight} !important;
        box-shadow: ${theme.colors.grayLight};
      }
      &:hover {
        border: 3px solid #BAC9D7 !important;
        cursor: pointer;
      }

    }
  }

  ul {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 5;
    overflow-y: auto;
    width: 560px;
    border-radius: 24px;
    max-height: 333px;
    margin: 0;
    padding: 0 10px;
    background-color: ${theme.colors.secondary};
    border-radius: 24px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    list-style: none;
    li {
      height: 64px;
      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      & + li {
        border-top: 1px solid ${theme.colors.grayLight};
      }
      img {
        width: 24px;
        height: 24px;
      }
      p {
        color: ${theme.colors.primaryDefault};
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 1439px) {
    ul li {
      height: 52px;
    }
  }
  @media (max-width: 1280px) {
    ul {
      max-height: 210px;
      ::-webkit-scrollbar {
        width: 0; /* remove scrollbar */
      }
      
      ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
      }
      
      ::-webkit-scrollbar-track {
        background-color: transparent !important;
        border: none !important; /* optional */
      }      
    }
  }
`;
