import styled from 'styled-components';
import { useChatContext } from 'context/chatContext';
import Progress from 'components/Progress';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import ImageViewer from '../../components/ImageViewer';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from 'context/nutritionistContext';
import { ChatLoading } from './ChatLoading';
import 'animate.css';
import { BsCheck } from 'react-icons/bs';

const ChatsStyle = styled.div`

    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    align-items: flex-end;
    overflow: auto;
    height: 100vh;
    margin-top: 61px;
    margin-bottom: 70px;
    transition: all 0.2s;
    .chat_holder_outer{
        padding: 0 14px 14px;
        min-height: fit-content;
        max-height: 100%;
        width: 100%;
        transform: translateY(0);
        transition: all 0.2s;
    }
    .chats_day{
        margin-top: 20px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635D;
    }
    .chat{
        display: flex;
        flex-wrap: wrap;
        column-gap: 6px;
        align-items: end;
        width: 100%;
        margin-top: 14px;
        transform: translateY(0);
        transition: all 0.2s;
        &.sender{
            .message_body{
                background: #F0FAEB;
                border-radius: 24px 24px 0px 24px;
                margin-left: auto;
                word-break: break-word;
            }
            .chat_time{
                text-align: right;
                padding-right: 22px;
            }
        }
        &.receiver{
            .message_body{
                background: #F6F5F8;
                border-radius: 24px 24px 24px 0px;
            }
            .chat_time{
                text-align: left;
                // padding-left: 30px;
                padding-left: 38px;
            }
        }
        .chat_time{
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.02em;
            color: #AFB5B4;
            width: 100%;
            margin-top: 5px;
        }
    }
    .sender_profile{
        position: relative;
        .active{
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #35A956;
            border: 1px solid #FFFFFF;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: block;
        }
    }
    .message_body{
        padding: 20px 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;  
        color: #51635D;
        max-width: 245px;
        word-break: break-word;
        @media (min-width: 577px){
            max-width: 80%;
        }
    }

    .message_typing{
        padding: 12px 8px;
        display: flex;
        gap: 3px;
        span{
            width: 5px;
            height: 5px;
            border-radius: 50%;
            &:first-child{
                background: #949D9B;
            }
            &:nth-child(2){
                background: #AFB5B4;
            }
            &:last-child{
                background: #E0E3E0;
            }
        }
    }

    .delivery_report{
        .not_delivered{
            border: 4px solid #CBEDBA;
            border-radius: 50%;
            width: 16px;
            height: 16px;
        }
        .delivered{
            background-color: #CBEDBA;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`

export default function Chats({ followerProfile, user, nutritionist, btnHeight, autoScroll }) {
    const { updateMessageCount, participantTyping, readMessages } = useChatContext();
    const [loadMore, setLoadMore] = useState(false);
    const [styles, setStyles] = useState(null);
    const scrollToBottom = useRef();
    const scrollView = useRef();
    const location = useLocation();
    const [loadChatScreen, setLoadChatScreen] = useState(true);
    const {nutritionist: selectedNutritionist} = useNutritionistContext();
    const [userIsActive, setUserisActive] = useState(false);
    const { isMobile, getAllUsersOfConversation, chatsLoading, chatData, isSuper } = useAppContext();
    let currentUser = isSuper ? selectedNutritionist?.user?.id : user?.id;
    const [isMessageSeen, setIsMessageSeen] = useState();
    let frontUser;
    if(user.type[0] == 'nutritionist'){
        frontUser = followerProfile.userid
    }
    if(user.type[0] == 'user'){
        frontUser = user.nutritionist.userId
    }

    useEffect(() => {
        if(getAllUsersOfConversation.length > 0){
            const isActive = getAllUsersOfConversation?.filter((item) => item.identity == frontUser)[0]?.isOnline;
            setUserisActive(isActive);
        }
    }, [getAllUsersOfConversation])

    useEffect(() => {
        updateMessageCount();
        scrollView?.current?.scrollIntoView();
        setTimeout(() => {
            scrollView?.current?.scrollIntoView();
        }, 200)
        console.log("chat loading", chatsLoading);
        if (!chatsLoading) {
            setStyles({
                ...styles,
                scrollBehavior: 'smooth'
            })
        }else{
            setStyles({
                ...styles,
                scrollBehavior: 'unset'
            }) 
        }
    }, [chatData, autoScroll, chatsLoading])

    useEffect(()=>{
        if(participantTyping!=null){
            scrollView?.current?.scrollIntoView({behaviour: 'smooth'});
        }
    }, [participantTyping])

    useEffect(() => {
        // scrollToBottom.current.onscroll = function () {
        //     const reachedTop = () => {
        //         if (scrollToBottom.current.scrollTop <= 200) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     }
        //     setLoadMore(reachedTop());
        // }
        isMobile && setStyles({
            ...styles,
            marginBottom: btnHeight + 'px'
        })
    }, [btnHeight])

    useEffect(() => {
        if (location.pathname == "/shop/chat-with-coach" && isMobile) {
            setStyles({
                ...styles,
                marginBottom: '78px'
            })
        }
    }, [])

    return (
        <ChatsStyle style={styles}>
            <div style={{ width: '100%' }}>
                <div className='chat_holder_outer' ref={scrollToBottom}>
                    {chatData?.filter((message) => message.body !== '').map((message, i, arr) => {
                            const dateCreated = message && new Date(message?.dateCreated).toISOString();
                            const formatDate = moment(dateCreated).format('LL');
                            let dateToPrint = false;
                            if (i === 0) {
                                dateToPrint = true;
                            } else {
                                const prevDateCreated = new Date(arr[i - 1].dateCreated).toISOString();
                                const prevFormatDate = moment(prevDateCreated).format('LL');
                                if (formatDate == prevFormatDate) {
                                    dateToPrint = false;
                                } else {
                                    dateToPrint = true;
                                }
                            }
                            return (
                                <>
                                    {dateToPrint && <div className='chats_day'>{moment(dateCreated).format('LL')}</div>}
                                    <div className={`chat ${message.author == currentUser ? 'sender' : 'receiver'} `} key={message.index}>
                                        {message.author != currentUser &&
                                            <div className='sender_profile'>
                                                <ImageViewer src={followerProfile?.img || nutritionist?.img || '/images/man.svg'} fallbackSrc={'/images/man.svg'}/>

                                                {userIsActive && <div className='active'></div>}
                                            </div>
                                        }
                                        <div className={`message_body`}>
                                            {message.body}
                                        </div>
                                        {message.author == currentUser && (
                                            <div className='delivery_report'>
                                                {readMessages.filter((user) => String(user.identity) === String(frontUser))[0]?.lastReadMessageIndex >= message.index ?
                                                    <div className='delivered'>
                                                        <BsCheck style={{ color: '#51635D', fontSize: '12px' }} />
                                                    </div>
                                                    :
                                                    <div className='not_delivered'></div>
                                                }
                                            </div>
                                        )}
                                        <div className='chat_time'>
                                            {moment(new Date(dateCreated)).format("h:mma")}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    {participantTyping && String(participantTyping?.identity) !== String(currentUser) &&
                    <div className={`chat sender typing animate__animated animate__fadeIn`}>
                        <div className='sender_profile'>
                            <img src={followerProfile?.img || nutritionist?.img || '/images/man.svg'} />
                            {userIsActive && <div className='active'></div>}
                        </div>
                        <div className={`message_typing`}><span></span><span></span><span></span></div>
                    </div>}
                </div>
                <div ref={scrollView} style={{ width: '100%', height: '1px' }}></div>
            </div>
        </ChatsStyle>
    )

}