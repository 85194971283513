import { useEffect, useRef, useState, useMemo } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import NewCondition from './NewCondition';
import { Button } from 'components/elements/button/Button';
import { FiltersModalWrapper } from './style';
import { initConditions } from './Data';
import { ButtonBase } from '@mui/material';

export default function FiltersModal({
  setIsFilters,
  setFilterQuery,
  isFilters,
  filterQuery,
  setOrderDataPageNumber,
  setIsSplit,
}) {
  const [conditions, setConditions] = useState([{ id: Math.random(), ...initConditions }]);
  const prevConditions = useRef([]);

  const isAddConditionDisabled = useMemo(
    () =>
      conditions.some(
        (condition) =>
          (!condition.scheduledFor && !condition.total && !condition.orderAt && !condition.items) ||
          condition.total.includes('undefined') ||
          condition.items.includes('undefined') ||
          condition.total.startsWith('0') ||
          condition.items.startsWith('0')
      ),
    [conditions],
  );

  const onAddCondition = () => {
    setConditions([...conditions, { id: Math.random(), ...initConditions }]);
  };

  const onDeleteCondition = (conditionId) =>
    setConditions(conditions.filter((con) => con.id !== conditionId));

  const onCancelFilters = () => {
    setIsFilters(false);
    if (filterQuery?.length > 0) {
      setConditions([...prevConditions.current]);
    } else {
      setConditions([{ id: Math.random(), ...initConditions }]);
    }
  };

  const onApplyFilter = async () => {
    prevConditions.current = [...conditions];
    const filters = conditions.reduce((acc, condition) => {
      const availableConditions = _.omitBy(condition, (v) => v == '');
      const omittedCondition = _.omit(availableConditions, ['id']);
      return { ...acc, ...omittedCondition };
    }, {});

    const filterQuery = Object.entries(filters).reduce(
      (acc, [key, value], index) =>
        `${acc}${acc && '&'}filterType[${index}]=${key}&filterCondition[${index}]=${
          value.split(' ')[0]
        }&filterValue[${index}]=${value.split(' ')[1]}`,
      '',
    );
    setOrderDataPageNumber(1);
    setFilterQuery(filterQuery);
    setIsFilters(false);
    setIsSplit(false);
  };

  useEffect(() => {
    filterQuery?.length === 0 && setConditions([{ id: Math.random(), ...initConditions }]);
  }, [filterQuery]);

  return (
    <FiltersModalWrapper className={classNames({ isFilters })}>
      <div className="filters-mask" onClick={onCancelFilters} />
      <div className="filters-modal-container">
        <div className="filters-title">
          <div className="title">Add a filter</div>
          <img src="/icons/close-gray.svg" alt="Close Icon" onClick={onCancelFilters} />
        </div>
        <div className="filters-label">Show rows that match</div>
        {conditions.map((condition, index) => (
          <div key={condition.id} className="filter-condition-wrapper">
            <NewCondition setConditions={setConditions} condition={condition} />
            {/* {index !== 0 && <span onClick={() => onDeleteCondition(condition.id)}>delete</span>} */}
          </div>
        ))}
        <ButtonBase
          className={classNames({
            disabledAddCondition: isAddConditionDisabled || conditions?.length > 3,
          })}
          disabled={isAddConditionDisabled || conditions?.length > 3}
          onClick={onAddCondition}
        >
          <div className="new-condition-wrapper">
            <img src="/icons/add-black3.svg" alt="Add Icon" />
            <span>Add new condition</span>
          </div>
        </ButtonBase>
        <div className="actions-buttons-wrapper">
          <Button title="Cancel" type="secondary" onClick={onCancelFilters} />
          <Button
            title="Apply"
            type="dark"
            onClick={onApplyFilter}
            isDisabled={isAddConditionDisabled}
          />
        </div>
      </div>
    </FiltersModalWrapper>
  );
}
