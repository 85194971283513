import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as SearchIcon } from 'assets/kitchen/search.svg';
import { ReactComponent as FilterIcon } from 'assets/kitchen/filter.svg';
import classNames from 'classnames';
import RegularInput from 'components/Input/RegularInput';
import { UpcomingPayouts } from 'pages/provider/storefront/payouts/UpcomingPayouts';
import { HistoryPayouts } from 'pages/provider/storefront/payouts/HistoryPayouts';
import { displayDateTime } from 'utils';

const PayoutsStyled = styled.div`
  width: 100%;
  .search_input {
    position: relative;
    padding: 8px 13px 8px 14px;
    input {
      height: 40px;
      padding-right: 50px;
      border: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
      font-weight: 500;
      font-family: inherit;
    }
    .search-input-mask {
      position: fixed;
      inset: 0;
      z-index: 112;
    }
    .input-wrapper {
      svg {
        display: block;
      }
    }
    .searchInputIndex .input-wrapper {
      z-index: 113;
    }
    .filter-icon-wrapper {
      position: absolute;
      right: auto;
      top: auto;
      width: 50px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sort-sign {
      position: absolute;
      right: 19px;
      top: 19px;
      z-index: 112;
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme?.colors?.primaryDarker};
      color: ${({ theme }) => theme?.colors?.secondary};
      border-radius: 50%;
      font-size: 6px;
      font-weight: 700;
      line-height: 10px;
      letter-spacing: 0.3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .payout-body-div{
    transform: translateY(${({ selectedTabId }) => (selectedTabId === 0 ? '-50px' : '0')});
    transition: all 0.3s ease;
  }
  .nav_tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    gap: 10px;
    padding: 0 14px;
    overflow: auto;
    transition: top 0.3s ease;
    z-index: 99;
    background: white;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    .nav {
      flex: 0 0 125px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 500;
      line-height: 20px;
      position: relative;
      color: ${({ theme }) => theme?.colors?.primaryDefault};
      transition: color 0.3s ease, font-weight 0.3s ease;
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        bottom: 0;
        background: transparent;
        transition: background 0.3s ease;
      }
      &.active {
        color: ${({ theme }) => theme?.colors?.primaryDarker};
        font-weight: 600;
        
        &::before {
          background: ${({ theme }) => theme?.colors?.primaryDarker};
        }
      }
    }
      }
  .overflow_container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 217px);
    &::-webkit-scrollbar {
      display: none;
      width: 0;
    }
    .order_card {
      &:last-child {
        .border {
          display: none;
        }
      }
    }
    .infinite-scroll-component__outerdiv {
      overflow: hidden;
      .infinite-scroll-component {
        overflow-x: hidden !important;
      }
    }
  }
  .payout-list{
    background: #F9F9F9;
    position: relative;
    padding: 0 0 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .order-details-motion {
    position: absolute;
    width: 100%;
    background: #F9F9F9;
    z-index: 10;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    &.animate-left{
      left: -100%;
    }
  }
  
`;
const payoutsTabs = ['Upcoming', 'History']

const Payouts = ({selectedTabId, setSelectedTabId, currentBalance, setCurrentBalance, totalEarned, setTotalEarned, searchTerm, setSearchTerm}) => {
    const [isSearchInputMask, setIsSearchInputMask] = useState(false);
    const [sortBy, setSortBy] = useState();
    const searchRef = useRef();
    const [dataLoading, setDataLoading] = useState(false);   
    const [pageAnimation, setPageAnimation] = useState(false); 
    const handleInputChange = (e) => { setSearchTerm(e.target.value) }

    useEffect(() => {
      if(selectedTabId === 1){
        setPageAnimation(true);
      }
    },[selectedTabId])
    useEffect(() => {
      if(isSearchInputMask) {
        searchRef?.current.focus()
      } else {
        searchRef?.current.blur();
      }
    }, [isSearchInputMask])
  
    return (
    <PayoutsStyled theme={theme} selectedTabId={selectedTabId}>
      <div className="search_input">
        {sortBy && <div className='sort-sign'>1</div>}
        {isSearchInputMask && <div className='search-input-mask' 
          onMouseMove={() => setIsSearchInputMask(false)} 
          onTouchMove={() =>setIsSearchInputMask(false)} />
        }
        <RegularInput
          placeholder={'Search your orders'}
          className={classNames({searchInputIndex: isSearchInputMask})}
          prefix={<SearchIcon />}
          ref={searchRef}
          onChange={handleInputChange}
          value={searchTerm}
          onFocus={() => setIsSearchInputMask(true)}
          onBlur={() => setIsSearchInputMask(false)}
        />
      </div> 
      <div className='payout-body-div'> 
      <div className={`nav_tabs`}>
        {payoutsTabs?.map((tab) => (
          <div
            className={`nav ${selectedTabId === payoutsTabs.indexOf(tab) ? 'active' : ''}`}
            onClick={() => {
              setSelectedTabId(payoutsTabs.indexOf(tab));
            }}
          >
            {tab}
          </div>
        ))}
      </div>  
      <div className='payout-list'>
        <div
          className={`order-details-motion ${selectedTabId === 1 ? "animate-left": ''}`}
        >
          <UpcomingPayouts
            setTotalEarned={setTotalEarned}
            currentBalance={currentBalance}
            setCurrentBalance={setCurrentBalance}
            setDataLoading={setDataLoading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            pageAnimation={pageAnimation}
          />

        </div>
        <HistoryPayouts searchTerm={searchTerm} setSearchTerm={setSearchTerm} totalEarned={totalEarned}/>
      </div>
      </div>
      </PayoutsStyled>
  )
}

export { Payouts };
