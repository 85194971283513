import styled from 'styled-components';
import { ReactComponent as Clock } from 'icons/clock.svg';
import {formatNumberDecimalPlaces} from "../../utils";
import {useNutritionistContext} from "../../context/nutritionistContext";
import { useAppContext } from 'context/appContext';
const moment = require('moment-timezone'); 
import {useLocation} from 'react-router-dom';

const OrderInfoWrapper = styled.div`
  .item {
    display: flex;
    align-items: center;

    & + .item {
      margin-top: 10px;
    }

    img,
    svg {
      width: 16px;
      height: 16px;
      margin-right: 7px;
      object-fit: cover;

      path {
        fill: #949d9b;
      }
    }

    div {
      color: #51635d;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  @media(min-width: 992px){
    padding: 2px 16px;

    .timeDate-wrapper{
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      gap: 18px;

      .item{
        & + .item {
          margin-top: 0px !important;
        }
  
        div {
          font-family: 'Roboto';
          font-style: normal;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
`;

export default function OrderInfo() {
    const {offerData, selectedOfferDetails} = useNutritionistContext();
    const { user , selectedGuestOfferDetails} = useAppContext();
    const selectedOfferDate = selectedOfferDetails?.selectedDate ? moment(selectedOfferDetails.selectedDate).tz(selectedOfferDetails.selectedTimezone).format('LLLL') :  
    moment(selectedGuestOfferDetails.selectedDate).tz(selectedGuestOfferDetails.selectedTimezone).format('LLLL')
    const { pathname } = useLocation();
    const selectedOfferTimezone = selectedOfferDetails?.selectedTimezone ? selectedOfferDetails.selectedTimezone : selectedGuestOfferDetails.selectedTimezone;

  return (
    <OrderInfoWrapper>
      <div className={pathname === '/booking/booked' && "timeDate-wrapper" } >
      <div className="item">
        <img src="/icons/card.svg" alt="Card Icon" />
        <div>${formatNumberDecimalPlaces(offerData.price / 100)}</div>
      </div>
      <div className="item">
        <Clock />
        <div>{offerData.availability} minutes</div>
      </div>
      <div className="item">
        <img src="/icons/location.svg" alt="Location Icon" />
        <div>Video call by Google Meet</div>
      </div>
      <div className="item">
        <img src="/icons/calendar-gray.svg" alt="Calendar Icon" />
        <div>{selectedOfferDate}</div>
      </div>
      <div className="item">
        <Clock />
        <div>Timezone: {selectedOfferTimezone}</div>
      </div>
      </div>
    </OrderInfoWrapper>
  );
}
