import styled from 'styled-components';
import cards from '../../assets/card.png'

const Container = styled.div`
.container{
	max-width: 500px;
	margin: auto;
	width: 100% !important;
	margin:0 auto;
	padding-right: 24px;
	padding-left: 24px;
	@media (max-width:376px){
		padding-right: 20px;
		padding-left: 20px;
	}
	@media (max-width:321px){
		padding-right: 14px;
		padding-left: 14px;
	}
}
	.height-share-card{
		height: 72vh;;
		.Challanges {
			margin-top: 46px;
			h3{
				font-weight: 700;
				font-size: 24px;
				line-height: 28px;
				text-align: center;
				color: #51635D;
			}
			p{
				max-width: 285px;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #949D9B;
				margin: auto;
				margin-top: 12px;
			}
		}
	} 
  	.share-btn{
			border: 1px solid #97DC76 !important;
			border-radius: 21px;
			position: absolute;
			bottom: 0;
			left: 32%;
			width: 138px;
			height: 40px;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			background-color: #52C41A;
			color: white;
	}
	.user-share{
		width:15px;
		margin-right: 8px;
	}
	.close-btn{
		color: #97DC76;
		position: fixed;
		left: 13px
	}
	.card-img-top{
		background-size:contain;
		height:184px;
		width: 100%;
	}
	.card{
			margin-top: 24px;
			width:100%;
			box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
			border-radius: 10px;
			border: 0px;
			overflow: clip;
	}
	.card-title{
			font-family: 'Roboto';
			font-style: normal;
			color: #51635D;
			margin:0 7px;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
	}
	.card-text{
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			color: #0B1D17;
	}
	.cart-calendar{
		align-items:center;
	}
	.card-date {
		display: flex;
		margin-bottom:13px;
		align-items: center;
	}		
	.card-body{
		padding: 15px 20px 14px 20px;
		transition:all 0.5s !important;
	}
	.icon-calendar{
		color: #51635D;
		height: 16px;
	}
	.card{
		@media (max-width:1140px){
			margin:10px;
		}
		@media (max-width:572px){
			margin:14px 0px;
		}
	}
  `

export default function ShareCard({ isCreate, challangeData, challangeId, nutritionistUrl }) {
	
	const URL_ROOT = `${window.origin}/plan/`;
	const shareDATA = {
		title: `${challangeData?.name} Challenge`,
		text: `Follow ${challangeData?.duration} ${challangeData?.duration_type} ${challangeData?.name} challenge on Nutritt`,
		url: URL_ROOT + nutritionistUrl,
		icon: '/images/nutritt-logo.svg'
	}
	
	const shareData = async () => {
		try {
			if(window.ReactNativeWebView){
				window.ReactNativeWebView.postMessage(JSON.stringify({event:"challenge-congrats",data:shareDATA}))
				return;
			}
			await navigator.share(shareDATA)
		} catch (error) {
			console.log(error, 'error');
		}
	}
	return (
		<Container className='container'>
			<div className='height-share-card'>
				<div className="Challanges">
					<h3> {isCreate ? 'Congrats!' : 'Share your challenge'}</h3>
					<p> Your challenge is ready for people to follow. Share it on social!</p>
				</div>
				<div className="card">
					<img src={challangeData?.image ? challangeData.image : "/images/plan-cover.png"} className="card-img-top" alt="..." />
					<div className="card-body">
						<div className="card-date">
							<div className="cart-calendar">
								<img src="/icons/calendar-inactive.svg" className="icon-calendar" />
							</div>
							<h5 className="card-title">
								{challangeData?.duration} {challangeData?.duration_type} </h5>
						</div>
						<p className="card-text">{challangeData?.name}</p>
					</div>
				</div>
				<div>
					<button className='share-btn animate__animated animate__heartBeat animate__slow' onClick={shareData}>
						<img src="/icons/white-share.svg" className="user-share" />Share
					</button>
				</div>
			</div>
		</Container>
	)
}