import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const PantryDietProgressWrapper = styled.div`
  position: relative;
  top: 6px;
  left: auto;
  width: 100%;
  .flag-icon {
    position: absolute;
    right: 0;
    top: -20px;
  }

  .user-img {
    position: absolute;
    top: -9px;

    .user {
      width: 18px;
      height: 18px;
      border: 1px solid #52c41a;
      border-radius: 50%;
      box-shadow: none;
      position: absolute;
      top: -17px;
    }

    .poligon {
      left: 6px;
      position: absolute;
      width: 6px;
      height: 5px;
    }
  }

  svg {
    width: 100%;
  }

  .progress-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 9px;
    background-color: #E0E3E0;
    border-radius: 4px;
    .progress-line {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: #52c41a;
    }
  }

  .plan-info-wrapper {
    display: flex;
    justify-content: space-between;

    .name,
    .duration {
      color: #51635d;
      font-size: 12px;
      line-height: 14px;
    }

    .name {
      font-weight: bold;
    }
  }
`;

export default function PantryDietProgress({
  userProgress: { progressCount = 0, totalDays = 0 } = {},
} = {}) {
  const [percent, setPercent] = useState(0);
  const [intervalId, setIntervalId] = useState();
  const {
    profile: { dietTemplate, img },
    isMobile,
  } = useAppContext();
  const userGoalProgress = totalDays == 0 ? 0 : (progressCount / totalDays) * 100;
  useEffect(() => {
    setTimeout(() => {
      const progress = setInterval(() => {
        progressCount > 0 && setPercent((prevState) => prevState + 1);
      }, 50);
      setIntervalId(progress);
    }, 500)
  }, [progressCount]);

  useEffect(() => {
    if (percent >= userGoalProgress || percent >= 99) {
      clearInterval(intervalId);
    }
  }, [percent]);

  return (
    <PantryDietProgressWrapper data-userGoalProgress={userGoalProgress}>
      {(progressCount > 2 && userGoalProgress < 90) && <img src="/icons/cup.svg" alt="cup Icon" className="flag-icon" />}
      <div
        className="user-img"
        style={{ left: ` ${percent > 5 &&  percent - 6}%` }}
      >
        <img className="user" src={img || "/images/avatar.png"} alt="User" />
        <img className="poligon" src="/icons/Polygon.svg" alt="Poligon Icon" />
      </div>
      <div className='progress-wrapper'>
        <div className='progress-line' style={{ width: ` ${percent - 3}%` }}  />
      </div>
      {isMobile && (
        <div className="plan-info-wrapper">
          <div className="name">{dietTemplate?.name}</div>
          <div className="duration">
            {dietTemplate?.duration} {dietTemplate?.duration_type}
          </div>
        </div>
      )}
    </PantryDietProgressWrapper>
  );
}
