import React from 'react'
import styled from 'styled-components';


const CoachesStyle = styled.section`
position: relative;
font-family: 'Roboto';
width: calc(100% - 300px);
margin-left: 300px;
padding: 54px;
background: #ffffff;
min-height: calc(100vh - 32px);
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
scroll-behaviour: smooth;
.container_box{
    width: 100%;
    max-width: 900px;
    margin: auto;
}
.container_box{
    width: 100%;
    max-width: 900px;
    margin: auto;
}
.title{
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635D;
    text-align: center;
    margin-bottom: 54px;
}
@media (max-width:576px){
    min-height: 100vh;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    margin-bottom: 32px;
    padding: 24px 20px 32px;
    .title{
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 20px;
    }
    }
`
export const Coaches = () => {
  return (
    <CoachesStyle>
     <div className='container_box'>
    <h2 className='title'>Coaches</h2>
    <h1 className='title'>Coaches comming soon</h1>
</div>
    </CoachesStyle>
  )
}
