import React, { useEffect, useRef, useState, useCallback,useMemo } from 'react'
import styled from 'styled-components'
import { theme } from 'shared/theme/theme'
import { Button } from 'components/elements/button/Button'
import { DataCard } from './DataCard'
import SingleLineChart from 'components/elements/charts/SingleLineChart'
import { useRequest } from 'hooks/useRequest'
import Alert from 'components/elements/alert/Alert'
import moment from 'moment'
import { useAppContext } from 'context/appContext'
import { useHistory } from 'react-router-dom'
import ShareLinkModal from 'components/Provider/StoreFront/Home/ShareLinkModal';

const HomeStyle = styled.div`
    padding: 40px 27px 40px 27px;
    overflow: auto;
    height: 100vh;
    .top__section{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .button_wrapper{
            display: flex;
            gap: 8px;
        }
    }
    .greetings{
        font-size: ${({ theme }) => theme?.typography?.fontXl};
        color: ${({ theme }) => theme?.colors?.blackDefault};
        font-weight: 600;
        line-height: 32px;
    }
    .data__cards__holder{
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 24px;
    }
    .sales_charts{
        padding: 22px 36px;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
        background: ${({ theme }) => theme?.colors?.white};
        display: flex;
        .title{
            font-size: ${({ theme }) => theme?.colors?.fontLarge};
            color: ${({ theme }) => theme?.colors?.blackDefault};
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            padding-bottom: 8px;
        }
        .last_update{
            font-size: ${({ theme }) => theme?.colors?.fontBase};
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: ${({ theme }) => theme?.colors?.grayDarker};
            margin-bottom: 27px;
        }
        .recharts-wrapper{
            margin-left: -22px;
        }
        .chart_holder{
            flex: 1;
            .recharts-cartesian-grid-vertical line {
                stroke-dasharray: 3, 0;
                stroke: #E9EAEC;
            }
            .xAxis{
                .recharts-text{
                    color: #283241;
                    font-weight: 400 !important;
                }
            }
        }
        .vertical_line{
            width: 1px;
            margin: 10px 0;
            background-color: #E9EAEC;
        }
        .card_holder{
            padding-left: 30px;
            width: 254px;
            padding-right: 23px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .data_card{
                border: 0px;
                outline: 0px;
                padding: 36px 12px;
            }
            .hoz_line{
                height: 1px;
                background-color: #E9EAEC;
            }
        }
    }

    .shareLink-alert-wrapper {
        position: fixed;
        top: 16px;
        left: calc(50% + 144px);
        width: 461px; 
        transform: translateX(-50%);
        .alert_bar {
            max-width: 100%;
            border: 1px solid #CEF0CE; 
        }
    }
`



export const Home = ({isSuper}) => {
    const {user} = useAppContext();
    const [getSalesData, {isLoading: gettingSales}] = useRequest();
    const widthRef = useRef();
    const [chartWidth, setChartWidth] = useState();
    const [isCopied, setIsCopied] = useState();
    const [shareEnabled, setShareEnabled] = useState(false);
    const [salesData, setSalesData] = useState({
        pastSevenDaysTotalSale: 0,
        todaySale: 0,
        totalSale: {
            amount: 0,
            currentMonthSaleAmount: 0,
            percentIncrease: 0
        },
        totalOrders: {
            currentMonth: 0, 
            orders: 0, 
            percentIncrease: 0
        },
        monthlyProviderSales: [],
        storeVisits: {
            percentIncrease: 0,
            thisMonthStoreVisits: 0,
            totalStoreVisits: 0
        }
    });
    const { pastSevenDaysTotalSale, todaySale, 
        totalSale: {
            amount: totalSaleAmount, 
            currentMonthSaleAmount, 
            percentIncrease: totalSalePercentIncrease
        },
        totalOrders: {
            currentMonth: currentMonthTotalOrders, 
            orders: totalOrders, 
            percentIncrease: totalOrdersPercentIncrease
        },
        storeVisits: {
            percentIncrease: storeVisitsPercentIncrease,
            thisMonthStoreVisits,
            totalStoreVisits
        },
        monthlyProviderSales
    } = salesData;
    const [chartData, setChartData] = useState();
    const history = useHistory();
    const imitateProviderStorage=sessionStorage.getItem("imitateProvider");

    const imitateProviderId = useMemo(() => {
        const parsedId = JSON.parse(imitateProviderStorage)?.id;
        return parsedId
    }, [imitateProviderStorage]);

    const transformData = useCallback((data) => {
        const monthOrder = moment.monthsShort();
        const transformedData = data?.map(item => {
            const date = moment(item.date);
            const monthName = date.format('MMM'); 
            return {
                name: monthName,
                sales: parseFloat(item.amount),
                amt: item.totalOrders,
            };
        });
    
        transformedData?.sort((a, b) => {
            const monthIndexA = monthOrder?.indexOf(a.name);
            const monthIndexB = monthOrder?.indexOf(b.name);
            return monthIndexA - monthIndexB;
        });
    
        return transformedData;
    }, [monthlyProviderSales]);

    useEffect(() => {
        if(monthlyProviderSales){
            const chartData = transformData(monthlyProviderSales);
            setChartData(chartData);
        }
    }, [monthlyProviderSales])
    
    const getProviderSales = async (providerId) => {
        const path = providerId ? `provider/business-sales?providerId=${providerId}` : 'provider/business-sales'
        await getSalesData({
            path,
            method: "get"
        })
            .then((data) => {
                setSalesData(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        if (isSuper) {
            getProviderSales(imitateProviderId)
        } else {
            getProviderSales()
        }
    }, [imitateProviderId])

    useEffect(() => {
        widthRef?.current && setChartWidth(widthRef?.current?.clientWidth);
    }, [widthRef])

    const fullYearData = useMemo(() => {
        const months = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        if(chartData?.length){
        const defaultMonthData = Object.fromEntries(
          months.map(month => [month, { amt: 0, name: month, sales: 0 }])
        );
    
        const mergedData = chartData.reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, defaultMonthData);
    
        return Object.values(mergedData) ?? [];
    }
      }, [chartData]);
    return (
        <>
        <HomeStyle theme={theme}>
            <div className='top__section'>
                <h1 className='greetings'>
                    👋 Welcome Back!
                </h1>
                <div className='button_wrapper'>
                <a href={`${process.env.REACT_APP_CLIENT_URL}/store/${isSuper ? JSON.parse(imitateProviderStorage)?.user?.username : user?.username}`} target="_blank">
                            <Button type={'secondary'}
                            title='View store'/>
                        </a>
                    <Button type={'dark'} onClick={() => setShareEnabled(true)} title='Share store' />
                </div>
            </div>
            <div className='data__cards__holder'>
                <DataCard preTitle={'Total Sales'} title={`$${totalSaleAmount}`} dataChange={` $${currentMonthSaleAmount}`} percentageChange={`${totalSalePercentIncrease}%`}/>
                <DataCard preTitle={'Total Orders'} dotColor={'#B57BFF'} title={totalOrders} percentageType={'up'} percentageChange={`${totalOrdersPercentIncrease}%`} dataChange={` ${currentMonthTotalOrders}`} />
                <DataCard dotColor={'#2399BF'} preTitle={'Store Visiting'} title={totalStoreVisits} percentageType={'up'} percentageChange={`${storeVisitsPercentIncrease}%`} dataChange={` ${thisMonthStoreVisits}`} />
            </div>
            <div className='sales_charts'>
                <div className='chart_holder' ref={widthRef}>
                    <h3 className='title'>{moment().format('MMMM')}’s sales</h3>
                    <p className='last_update'>Last update {moment().format('MMMM D, YYYY')}</p>
                    <SingleLineChart isDataLoading={gettingSales} chartData={fullYearData} chartWidth={chartWidth - 25}/>
                </div>
                <div className='vertical_line'></div>
                <div className='card_holder'>
                    <DataCard dotColor='' percentageType='' percentageChange='' preTitle='Today’s sales' title={`$${todaySale}`} />
                    <div className='hoz_line'></div>
                    <DataCard dotColor='' percentageType='' percentageChange='' preTitle='Past 7 days' title={`$${pastSevenDaysTotalSale}`} />
                </div>
            </div>
            {isCopied && (
                <div className='shareLink-alert-wrapper'>
                    <Alert onClose={() => setIsCopied(false)} title="Link copied to clipboard" />
                </div>
            )}
        </HomeStyle>
        {shareEnabled &&
            <ShareLinkModal setShareEnabled={setShareEnabled} setIsCopied={setIsCopied} />
        }
        </>
    )
}

