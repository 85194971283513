import styled from 'styled-components';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import OrderInfo from 'components/Booking/OrderInfo';
import PaymentInfo from 'components/Booking/PaymentInfo';
import {useRequest} from "hooks/useRequest";
import {useNutritionistContext} from "../../context/nutritionistContext";
import {formatNumberDecimalPlaces} from "../../utils";
import Progress from 'components/Progress';
import {useState} from "react";
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';
import Steps from '../GuestUser/Steps';
import BackButton from 'components/BackButton';


const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BookingPaymentWrapper = styled.div`
  .desc {
    width: 80%;
    margin: 40px auto;
    text-align: center;

    h2 {
      margin-bottom: 5px;
      color: #0b1d17;
      font-family: 'Roboto';
    }

    p {
      color: #949d9b;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  form {
    padding-bottom: 74px;
  }
  button: disabled {
    background-color: #e0e3e0;
    color: #949d9b;
    cursor: not-allowed;
  }

  .info-container {
    padding: 20px 20px 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

    &:first-child {
      margin-bottom: 14px;
    }
  }

  .order-title {
    margin-bottom: 10px;
    color: #0b1d17;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
  }

  .services-total-wrapper {
    margin-top: 14px;

    .services,
    .total {
      border-bottom: 1px solid #51635d1a;
      font-family: 'Roboto';
      display: flex;
      justify-content: space-between;
    }

    .services {
      padding: 10px 20px;
      color: #51635d;
      border-top: 1px solid #51635d1a;
      font-size: 12px;
      line-height: 14px;
    }

    .total {
      padding: 14px 20px;
      color: #0b1d17;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .btn-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    ${FlexCenter};

    .primary-btn {
      width: 75%;
      height: 44px;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      ${FlexCenter};
    }
  }

  @media(min-width: 992px){
    .paymentInfo-wrapper{
      display: grid;
      grid-template-columns: 45% 30%;
      justify-content: center;
      gap: 24px;

      .payment-container{
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

        .btn-wrapper{
          position: unset;
          height: 24px;
          padding: 40px 32px 17px;
          z-index: unset !important;
          box-shadow: none;
          padding: 27px 109px 57px;
          border-radius: 20px;

          .primary-btn {
            height: 56px;
            width: 74%;
            border-radius: 28px;
            font-size: 16px;
            font-weight: 700;
            font-family: 'Roboto';
            font-style: normal;
            line-height: 24px;
          }
        }
      }

      .summary-container{
        width: 100%;
        padding: 0px 24px 24px;
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        height: fit-content;

        .order-title{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #0B1D17;
          border-bottom: 1.5px solid #ededed;
          padding: 12px 0px;
        }

        .services-total-wrapper{

          .services{
            padding: 13px 18px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;

            span:nth-of-type(2) {
              color: #0B1D17;
              font-weight: 500;
            }
          }

          .total{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #0B1D17;
            padding: 20px 18px 4px;
            border-bottom: 0px;

            span:nth-of-type(2) {
              color: #51635D;
            }
          }
        }
      }
    }
  }
`;


export default function BookingPayment() {
  const history = useHistory();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  const [paymentInfo, setPaymentInfo] = useState({method:"credit"});
  const [creditCardError, setCreditCardError] = useState(null);
  const {
    method,
    cardNumber,
    expiryDate,
    cvv
  } = paymentInfo;
  const {offerData, selectedOfferDetails} = useNutritionistContext();
  const { user ,guestId , isVisitor , selectedGuestOfferDetails, isMobile, setupLocalUser, setGuestInfo} = useAppContext();
  const {
    id,
    availability,
    nutritionistId,
    grandTotal,
    nutritionist,
    title,
    description
  } = offerData;

  const {selectedTimezone, selectedDate, clientEmail, userId, isUserExist} = guestId ? selectedGuestOfferDetails || {} : selectedOfferDetails || {} ;
  const [completePayment, { isLoading, error }] = user ? useRequest('coachingOffer/completePayment_v2', 'post') : useRequest('coachingOffer/completePayment', 'post');
  const [updateVisitor, { isLoading:visitorLoading, error :visitorError }] =useRequest('visitor/update-profile', 'post')
  const [createEvent] = user ? useRequest('googleAuth/createEvent_v2', 'post') :  useRequest('googleAuth/createEvent', 'post');
  const [verifyGoogleAuth , {isLoading:authLoading}]= useRequest('googleAuth/verifyGoogleAuth_token','post')
  let completePaymentBody = {
    nutritionistId: nutritionistId,
    userId: userId,
    coachingOfferId: id,
    paymentInfo: paymentInfo,
    scheduledDateTime: moment(selectedDate).utc(),
    billingAddress: paymentInfo.billingAddress,
    // token: offerData.token,
  };
  let createEventBody =  {
    userEmail: clientEmail,
    userId: userId,
    nutritionistUserId: nutritionist?.userId,
    summary: title,
    description,
    start: moment(selectedDate).utc(),
    end: moment(selectedDate).add(availability, 'minutes').utc(),
    timeZone: selectedTimezone,
    // nutritionistMeetingMappingId: orderSuccess.id,
  };

  const submitButton = async (e) => {
    let orderSuccess = undefined;
    e.preventDefault();
    await verifyGoogleAuth({
      body : {
        nutritionistUserId : nutritionist?.userId ,
        userId ,
        coachingOfferId: id ,
        scheduledDateTime: moment(selectedDate).utc()
      }
    }).then( async (verifyAuth)=>{
      if(verifyAuth.status == true){
        if(user){
          orderSuccess = await completePayment({
          body: completePaymentBody,
        }) 
        } else{
          // Object.assign(completePaymentBody, {token: offerData.token});
          orderSuccess = await completePayment({
            body: completePaymentBody,
          })
          setGuestInfo("");
        }
        if (orderSuccess) {
          if(user){
            if(isVisitor){
              await updateVisitor({
                body :{
                  nutritionistId
                }
              });
            }
            Object.assign(createEventBody, {nutritionistMeetingMappingId: orderSuccess.id});
            await createEvent({
              body: createEventBody,
            });
          } else{
            Object.assign(createEventBody, {nutritionistMeetingMappingId: orderSuccess.id});
            // Object.assign(createEventBody, {token: offerData.token});
            await createEvent({
              body: createEventBody,
            });
          }
          if(isUserExist){
            await setupLocalUser();
            if (user.type.toString()=="guest"){
             history.push('/shop/browse-plan');
             toast.success("Your Offer Plan was successfully booked.");
            } else{
              history.push('/shop/pantry');
              toast.success("Your Offer Plan was successfully booked.");
            }
          } else {
            if(selectedOfferDetail?.userId == "undefined"){
              history.push('/guest/create-password')
            }
            else{
              history.push(`/booking/booked`);
            }
          }
        }
      }
      if(verifyAuth.status == false){
        toast.error("Something went wrong , Please try again later.")
      }
    }).catch((err)=>{
      console.log(err);
      toast.error("Something went wrong , Please try again later.")
    })
  
  
  } ;
  if(error && !isLoading){
    toast.error(error);
  }

  const handlePaymentInfo = (key, value) => {
    setPaymentInfo({
      ...paymentInfo,
      [key]: value,
    });
  };

  
  return (
    <BookingPaymentWrapper>
      {(!user?.id && selectedOfferDetail?.userId == "undefined") || !user?.id ?
      isMobile ?
      <Steps
        title="Payment"
        description1="Select the payment option that works best"
        description2="for you"
        perviousLink={() => {
          history.goBack();
          window.scrollTo(0, 0);
        }}
      /> :
      <Steps
        title="Payment"
        description1="Select the payment option that works best for you"
        perviousLink={() => {
         history.goBack()
         window.scrollTo(0, 0);
        }}
      />
        : 
      <Steps
        title="Payment"
        description1="Select the payment option that works best"
        description2="for you"
        perviousLink={() => {
          history.goBack();
          window.scrollTo(0, 0);
        }}
      />
      }
      <form>
        <div className="paymentInfo-wrapper">
        <div className={isMobile? "info-container" : "payment-container"}>
          <PaymentInfo paymentInfo={paymentInfo}
            handlePaymentInfo={handlePaymentInfo}
            offerData={offerData}
            creditCardError={creditCardError}
            setCreditCardError={setCreditCardError}
            />
          {!isMobile &&
          <div className="btn-wrapper">
          <button
            className="primary-btn"
            onClick={submitButton}
            disabled={!method || !cardNumber || creditCardError || !expiryDate || !cvv || cardNumber.length <16 || isLoading || authLoading} 
            >
            {isLoading || authLoading ? 'Booking...' : 'Confirm booking'}
          </button>
         </div>
          }
        </div>
        <div className={isMobile? "info-container" : "summary-container"}>
          <div className="order-title">Order summary</div>
          <OrderInfo />
          <div className="services-total-wrapper">
            <div className="services">
              <span>Service fee</span>
              <span>${formatNumberDecimalPlaces(offerData.serviceFee / 100)}</span>
            </div>
            <div className="total">
              <span>Total</span>
              <span>${formatNumberDecimalPlaces(offerData.grandTotal / 100)}</span>
            </div>
          </div>
        </div>
        </div>
        {isMobile &&
        <div className="btn-wrapper">
          <button
            className="primary-btn"
            onClick={submitButton}
            disabled={!method || !cardNumber || creditCardError || !expiryDate || !cvv || cardNumber.length <16 || isLoading || authLoading} 
          >
            {isLoading || authLoading ? 'Booking...' : 'Confirm booking'}
          </button>
        </div>
        }
        <br/>
        {isLoading && <Progress/>}
      </form>
    </BookingPaymentWrapper>
  );
}
