import styled from 'styled-components';
// import MoreIcon from './../../assets/more.svg';
import tagIcons from 'components/TagIcons';
import MealTag from './MealTag';
import MealMoreTag from './MealMoreTag';

const Container = styled.div`
  margin-bottom: 11px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 40px));
  grid-row-gap: 10px;
  .tag-container {
    margin-right: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .icon-wrapper {
    width: 30px;
    height: 30px;
    border: 0.5px solid lightGray;
    border-radius: 6px;
    color: #939b99;
    display: grid;
    justify-items: center;
    align-items: center;

    svg {
      font-size: 20px !important;
    }
  }
  .tag-name {
    /* color: var(--lightGray); */
    font-size: 10px;
    display: none;
  }

  .MuiTooltip-popperArrow .MuiTooltip-tooltipPlacementBottom {
    margin: 5px 0;
  }

  @media (max-width: 991px) {
    .multiTags-listing {
      width: 35px;
      max-width: 35px;
    }
  }
`;

export default function MealMultiTags({ providerName, tags, recommendedTags, notRecommendedRestrictions, providerModal }) {
    const tagCount = tags.slice(0,5).map(
        (item, index) => <div key={index}>{item}</div>
    )

  return (
    <Container className='tags-listing'>
      {(window.innerWidth <= 320 ? tags.slice(0,4) : tags.slice(0,5)).map((tag, index) => (
        <div className='multiTags-listing' key={index}>
            <MealTag
                Icon={tagIcons[tag]}
                tag={tag}
                recommendedTags={recommendedTags}
                notRecommendedRestrictions={notRecommendedRestrictions}
            />
           
        </div>
      
      ))}
      <div className='multiTags-listing'>
          <MealMoreTag providerName={providerName} tags={tags} recommendedTags={recommendedTags} notRecommendedRestrictions={notRecommendedRestrictions} />
      </div>
    </Container>
  );
}
