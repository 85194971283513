import React from "react";
import {Typography} from '@material-ui/core';
import styled from "styled-components";

const GoogleOAuthPageWrapper = styled.div`
  .pageWrapper{
    background: #ffffff;
  }
  .MuiTypography-h3{
    font-size: 3rem;
    font-family: Roboto;
    font-weight: 700;
    line-height: 1.167;
    letter-spacing: 0em;
    display: grid;
    justify-content: center;
  }
  .MuiTypography-body1{
    font-weight: 600;
    font-size: 1.75rem;
    letter-spacing: 0em;
    display: grid;
    margin-top: 30px;
    padding: 0 70px;
  }
  .MuiTypography-h4{
    font-weight: 500;
    font-size: 1.25rem;
    letter-spacing: 0em;
    display: grid;
    margin-top: 25px;
    padding: 0 70px;
  }
  .MuiTypography-body2{
    font-weight: 400;
    font-size: 1rem;
    display: grid;
    margin-top: 5px;
    padding: 0 70px;
  }
  img{  
    width: 200px;
    display: flex;
    justify-content: center;
    margin-left: 43%;
  }
`;

const GoogleOauth = () => {
    return (
        <GoogleOAuthPageWrapper>
        <div className="pageWrapper">
            <img src="/icons/nutritt-logo-120px.png" alt="nutritt-logo" />

            <Typography variant="h3" component="h3">Google OAuth2 Homepage</Typography>
            <Typography variant="body1" component="body1">Accurately represents your app's identity: Nutritt is an app that offers meal prep, coaching sessions with nutritionists, and meal ordering services based on users nutritional plan in one place.</Typography>
            <Typography variant="h4" component="h4">What will this app do with user data?</Typography>
            <Typography variant="body2" component="body2">Nutritt will not sell any data. Data will be used for the purpose of providing a better user experience and functionality across the platform.</Typography>
            <Typography variant="h4" component="h4">How our app enhances user functionality</Typography>
            <Typography variant="body2" component="body2">Nutritt enhances user functionality by providing a service to order healthy meals and meet with your coach.</Typography>
            <Typography variant="h4" component="h4">Link to privacy policy -</Typography>
            <Typography variant="body2" component="body2">The full privacy policy can be found -
            <u><a href="/nutritt/privacy-policy" target="blank">here</a></u>
            </Typography>
            <Typography variant="h4" component="h4">Google OAuth2 Limited Use Disclosure</Typography>
            <Typography variant="body2" component="body2">This app doesn't request restricted scopes. The use of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements.</Typography>
            <Typography variant="h4" component="h4">How does this application access, use, store, or share Google user data?</Typography>
            <Typography variant="body2" component="body2">Nutritt will read the following data and use it in order to save scheduled coaching meetings in the users calendar: scope,  id_token, access_token and refresh_token</Typography>
        </div>
        </GoogleOAuthPageWrapper>
    )
};

export default GoogleOauth;