import styled from 'styled-components';
import { ReactComponent as ProgressIcon } from './../../assets/progress-icon.svg';
import { ReactComponent as ChartIcon } from './../../assets/chart-icon.svg';
import { ReactComponent as CoachIcon } from './../../assets/coach.svg';
import { ReactComponent as CoachGreen } from './../../assets/coach-green.svg';
import { ReactComponent as ShopGreen } from './../../assets/shop-green.svg';
import { ReactComponent as ShopGrey } from './../../assets/shop-grey.svg';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

const VisitorUserFooterStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  position: fixed;
  z-index: 110;
  bottom: 0px;
  background-color: #fff;
  .pantry-footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70px;
    padding: 0 20px;
    .pantry-footer-item {
      text-align: center;
      &.active {
        h5 {
          color: #52c41a;
        }
      }
      img, .icon {
        width: 20px;
        height: 20px;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #afb5b4;
      }
      .unseenMsg{
        position: absolute;
        right: 7px;
        top: -5px;
        font-weight: 400;
        font-size: 9px;
        line-height: 14px;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        text-align: center;
        background: #EB3223;
        border: 0.5px solid #EB3223;
        color: #fff;
        z-index: 2;
      }
      &.relative{
        position: relative;
      }
    }
  }
  @media (min-width: 992px) {
    position: static;
    z-index: 1;
    width: 35%;
    height: 69px;
    box-shadow: none;
    .pantry-footer-inner .pantry-footer-item {
      height: 69px;
      padding: 0 24px;
      border-bottom: 2px solid transparent;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      h5 {
        font-size: 14px;
        line-height: 16px;
      }
      &.active {
        border-color: #52c41a;
        svg path {
          stroke: #52c41a;
        }
      }
      .icon {
        margin-right: 10px
      }
    }
    .pantry-footer-item {
      .unseenMsg{
        right: 93px;
        top: 15px;
      }
    }
  }
`;
const VisitorUserFooter = ({ myCoach, shop, progress }) => {
  const { pathname } = useLocation();
  const { user } = useAppContext();
  const userType = user?.type?.toString();

  return (
    <VisitorUserFooterStyle>
      <div className="pantry-footer-inner">

        {(shop || pathname?.includes("/visitor/")) && pathname != "/visitor/get-started" ? (
          <Link to="/visitor/plan" className="pantry-footer-item active">
            <ShopGreen className='icon' style={{ margin: '0px 11px' }} />
            <h5>Shop</h5>
          </Link>
        ) : (
          <Link to="/visitor/plan" className="pantry-footer-item">
            <ShopGrey className='icon' style={{ margin: '0px 11px' }} />
            <h5>Shop</h5>
          </Link>
        )}

        <Link to={"/visitor/get-started?redirectTo=/shop/browse-plan"} className="pantry-footer-item">
          <ChartIcon className='icon' />
          <h5>Progress</h5>
        </Link>

        <Link to={"/visitor/get-started?redirectTo=/shop/all-coach"} className="pantry-footer-item">
          <CoachIcon className='icon' />
          <h5>My Coach</h5>
        </Link>
      </div>
    </VisitorUserFooterStyle>
  );
};

export default VisitorUserFooter;