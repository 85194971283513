import { PaymentBreakdownWrapper } from './style';

export default function PaymentBreakdown({
  providerSubTotal,
  deliveryFee,
  taxAmount,
  providerPayout,
  repeatOrderDiscount,
  isRecurringOrder,
}) {
  return (
    <PaymentBreakdownWrapper>
      <div className="section-title">Payment breakdown</div>
      <div className="payment-items-container">
        <div className="payment-item">
          <div className="title">Subtotal</div>
          <div className="info">${providerSubTotal}</div>
        </div>
        {isRecurringOrder && (
          <div className="payment-item">
            <div className="title">Repeat order discount</div>
            <div className="info repeat-order-discount">– ${repeatOrderDiscount}</div>
          </div>
        )}
        <div className="payment-item">
          <div className="title">Delivery Fee</div>
          <div className="info">${deliveryFee}</div>
        </div>
        <div className="payment-item">
          <div className="title">Tax</div>
          <div className="info">${taxAmount}</div>
        </div>
      </div>
      <div className="divider" />
      <div className="total-payment">
        <div className="title">Total payout</div>
        <div className="info">${providerPayout}</div>
      </div>
    </PaymentBreakdownWrapper>
  );
}
