import React, { useEffect, useState } from 'react';
import { BMR_FORMULAS_id, DEFAULT_ACTIVITY } from '../../constants';
import OverViewChallenge from './Challenges_Components/OverViewChallenges/OverViewChallenge'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import NutritionistControls from 'components/Nutritionist/Coaching/NutritionistControls';
import { useHistory } from 'react-router-dom';
import UploadWidget from 'components/UploadWidget';
import { Button, Tab, Tabs } from '@mui/material';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import { BMR_FORMULAS, createPlanItems, BMR_FORMULA_VARS } from '../../constants';
import Select from 'components/Select';
import { useRequest } from 'hooks/useRequest';
import Alert from 'components/Alert';
import { useNutritionistContext } from 'context/nutritionistContext';
import 'animate.css';
import closeIcon from '../../icons/Close-cross.svg'
import gallery from '../../icons/gallery.svg'
import MeasurementSystem from './Challenges_Components/SettingChallenges/MesurmentSystemCompo';
import AdvanceSetting from './Challenges_Components/SettingChallenges/AdvanceSetting';
import CustomizePalPopup from './Challenges_Components/SettingChallenges/CustomizePal';
const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FlexColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CreateChallangeWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  background:#ffffff; !important;
  margin-bottom:-70px !important;
  padding-bottom:50px !important;
  .challenge-introduction{
    margin-top: 14px;
  }
  .MuiBox-root {
    padding: 14px 0 0 !important;
  }
  .MuiTabs-indicator {
    height: 2px !important;
  }
  
  .offering-container {
    width: 60%;
    margin: 0 auto;
  }

 
.publish-create-btn{
  width: 287px;
  height: 42px;
  bottom: 21px;
  background: #52C41A;
  border-radius: 21px;
  color: white;
  margin-top: 29px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  text-transform: capitalize;
  &:hover{
    background: #52C41A;
  }
}
button: disabled { 
  width:100%;
  background-color: #e0e3e0 !important;
  color: #949d9b !important;
  cursor: not-allowed;
} 
.css-187mznn-MuiSlider-root{
  padding:9px 0px !important;
}

  .form-img-container {
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top: 10px;
    span{
      color:#fff;
    }
    & > img {
      object-fit: contain;
      height: 120px;
    }
  }

  .form-group .form-control {
    display: flex;
    postion:absolute;
  
    & > input.duration-number {
      width: 60%;
      border-radius: 6px 0 0 6px;
    }

    & > div {
      width: 40%;ss
      margin-bottom: 0;s
    }
    div.selected-value {
      border-radius: 0 6px 6px 0;
      border-left: 0;
    }
  }
   .btn-previous{
     background-color: #ffffff;
       position: absolute;
                top: 7px;
                left: 10px;
                img{
                    width: 20px;
                    height: 20px;
                }
            }

  .offering-title {
    margin-bottom: 45px;
    color: #51635d;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
  }

  form {
    .challanges {
      min-height:38px !important;
      div{
        div{
          height:38px;
          button{
            min-height:38px;
          }
        }
      }
    }
    label {
      font-family: 'Roboto';
      font-style: normal;
      margin-bottom: 7px;
      color: #51635d;
      font-size: 12px !important;
      font-weight: bold;
      line-height: 14px;
      display: block;
    }
    

    textarea,
    input {
      height: 44px;
      margin-bottom: 8px;
      padding: 0 10px 0 14px;
      border: 1px solid #e0e3e0;
      border-radius: 6px;

      ::placeholder {
        color: #949d9b;
      }
    }

    textarea {
      height: 68px;
      padding-top: 9px;
    }

    .upload-cover-img{
      position:relative;
      text-align: center;
      height: 184px;
      background: #F6F5F8;
      margin-top:24px;
      /* Light Grey */
      border: 1px solid #E0E3E0;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      img {
        border-radius: 0px;
      }
      .Cover-image-upload{
        position:absolute;
        width:100%;
        height:100%;
      }
      .Cover-form-image{
        width:100%;
        height:100%;
        object-fit:fill;
        border-radius:5px !important;
      }
      .gallery-icon{
        height: 28.67px;
      }

      .coverText {
        font-size: 18px;
        padding-top: 39px;
        margin-bottom: 3px !important;
        line-height: 24px;
        color: #51635D;
      }

      .coverDesc {
        font-weight: 400;
        font-size: 14px !important;
        line-height: 16.41px;
        color: #949D9B;
      }
    }
  }

  .offering-schedule-wrapper {
    margin-bottom: 32px;
  }

  .nutritionist-controls {
    display: flex;
    justify-content: center;
    left: calc( 50% - 143px);
    & > div {
      width:100%;
      position: static;
      .primary-btn {
        width: 100%;
      }
    }
  }

  .google-calendar-wrapper {
    margin-bottom: 32px;

    label span {
      color: #f00;
    }

    .google-calendar {
      padding: 30px 0;
      background-color: #f6f5f8;
      border: 1px solid #e0e3e0;
      border-radius: 10px;

      &.hasGoogleAccess {
        background-color: #f0faeb;
        border: 1px solid #cbedba;
      }
    }

    .google-calendar-container {
      width: 60%;
      margin: 0 auto;
      ${FlexColumnCenter};

      p {
        margin-bottom: 13px;
      }
    }

    p {
      color: #51635d;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }

    a {
      width: 200px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #97dc76;
      border-radius: 21px;
      ${FlexCenter};
    }

    button {
      margin-left: 10px;
      padding: 0;
      background-color: transparent;
      color: #51635d;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      cursor: pointer;
    }
  }

  .goolge-alert-wrapper {
    .alert-container {
      position: relative;
      width: 300px;
      padding: 0 30px;
    }

    .title {
      ${FlexCenter};

      .img-wrapper {
        width: 64px;
        height: 64px;
        background-color: #f0faeb;
        border-radius: 50%;
        ${FlexCenter};
      }
    }

    .title-desc-wrapper {
      border-bottom: 0;
      padding-bottom: 0;
    }

    p span {
      font-family: 'Roboto';
    }

    .btn-wrapper {
      button:first-child {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 24px;
        height: 24px;
        margin: 0;
        padding: 0;
        border: 0 !important;
        border-radius: 0;
      }

      button:last-child {
        position: relative;
        width: 204px;
        margin-bottom: 10px;
        padding-left: 40px;
        background-color: #0085f7;
        border: 2px solid #0085f7 !important;
      }import { format } from 'date-fns';


      .img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 36px;
        padding: 0 8px 0 14px;
        background-color: #fff;
        border-radius: 21px 0 0 21px;
        ${FlexCenter};
      }

      a {
        color: #fff;
      }
    }
  }

  .has-google-account {
    ${FlexColumnCenter};

    .google-account {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }

    p {
      margin-left: 7px;

      span {
        font-family: 'Roboto';
      }
    }
  }

  @media (max-width: 1270px) {
    .offering-container {
      width: 85%;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 24px 0 33px 0;

    .MuiTabs-indicator {
      height: 2px !important;
    }
    .offering-container {
      width: 90%;
    }

    .offering-title {
      margin-bottom: 12px;
    }

    form {
      padding: 0px 20px;
      background: #ffffff;
      border-radius: 6px;

      & > div:first-child {
        margin-top: 0;
      }

      input,
      textarea {
        margin-bottom: 0;
      }
    }

    .google-calendar-wrapper .google-calendar-container {
      width: 75%;
    }

    .has-google-account .google-account {
      width: 70%;
      margin-top: 20px;
      align-items: flex-start;
    }
  }
 .offering-title h2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #51635D;
  }
  .challange-create{
    margin-top: 10px;
  }
  .challange-create p{
    width:226px;
    margin:0 auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #51635D;
  }
 .challange-Settings{
    text-transform: capitalize;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
   color: #51635D !important;
    width: 50%;
 }
 .other-class{
  text-transform: capitalize;
  font-size: 12px;
  width: 50%;
 }

 .challanges{
    display: flex;
    justify-content: space-between;
    border-bottom:1px solid rgba(224, 227, 224, 0.5);
 } 
 .challange-Overview{
    text-transform: capitalize;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #51635D;
    width: 50%;
 }

 .Challange-duration2{
  margin-bottom: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px;
  color: #51635D;
  width:100%;
  @media (max-width:360px){
    padding:10px;
  }
 }

 .challange-Overview {
    color: #51635D !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 14px;
}
.intensity{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #51635D;
    padding-top:25px;
}

.card-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icon{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 9px;
  margin-top: 20px;
}

.Column {
    display: table-cell;
}
.Customize-position-styling{
  position:fixed;
}
  .MacroBreakdown{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #51635D;
}
 .MacroBreakdown1{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #51635D;
}
   .Calorie-btn{
    // width: 140px;
    height: 33px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #51635D;
    flex: none;
    order: 0;
    flex-grow: 0;
    background: #F6F5F8;
    border: 1px solid #E0E3E0 !important;
    border-radius: 6px;
    margin-bottom: 24px;
    z-index: 1;
    position: relative;
}
.Calorie-btn1{
  text-align: center;
  margin-top: 24px;
  position: relative;
  hr{
    position: relative;
    top: -40px;
    z-index: 0;
    margin: 0;
    border-radius: 50%;
    height: 1px;
    background: #51635D;
    opacity: 0.1;
  }
}

img, svg {
  vertical-align: middle;
  border-radius: 20px;
}
.card-img-top {
  svg {
    border-radius: 0px;
  }
}
.pro{
  width:100%;
  background-color: green;
  margin-top: -10px;
}
.Fat{
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #51635D;
}


    span, select, a {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      color: #51635D;
    }
  

  
  .selected-value > span{
    text-overflow : ellipsis !important;
    white-space : nowrap !important;
    overflow:hidden !important;
  }
  .selected-value > svg {
    border:1px solid #949D9B;
    border-radius:5px;
  }
  .createChallenge{
    z-index:10;
  }
  @keyframes OpenCreateModal {
    0%
     { 
      transform: translateY(100%);
     }
    100%
     { 
      transform: translateY(0%);  
     }
  }
  @keyframes CloseCreateModal {

    0%{
      transform: translateY(0);
    }
    100%{
      transform: translateY(120%);
    }
  }
`;
{
  TabPanel;
}

export default function CreateChallange({ isAlert, setIsAlert, link, getForm , direct}) {
  const [form, setForm] = useState(createPlanItems());
  const defaultBMRFormula = {
    energyFormula: 'PAL (Physical Activity Level)',
    targetCaloriesFormula: 'BMR * PAL',
    activityType: 'PAL',
  };
  const { activityType } = BMR_FORMULA_VARS[form.BMR_formula] || defaultBMRFormula;
  const [customizeValue, setCustomizeValue] = useState(DEFAULT_ACTIVITY[activityType]);
  const [useCustom, setUseCustom] = useState(false);
  const history = useHistory();
  const [disablePublishBtn, setDisablePublishBtn] = useState(false);
  const [show, setshow] = useState(false);
  const [progress, setprogress] = useState(false);
  const [Customize, setCustomize] = useState(false);
  const [isSubmitAlert, setIsSubmitAlert] = useState(false);
  const [isAlerts, setIsAlerts] = useState(false);
  const [disbale, setdisbaled] = useState(false)
  const { isMobile } = useAppContext();
  const [value, setValue] = useState(0);
  const [counter, SetCounter] = useState(2500);
  const { id } = useParams();
  const [intensity, setIntensity] = useState('')
  const [challenge_goal, setchallenge_goal] = useState('');
  const [breakdown, SetBreakdown] = useState();
  const [customizepal, SetCustomizepal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [alert, setAlert] = useState(false);

  const dataId = id;
  useEffect(() => {
    if (form?.PAL !== null) {
      setCustomizeValue(form?.PAL)
      setUseCustom(!useCustom)
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    const dietTemplates = nutritionistData?.nutritionist?.dietTemplates?.find(
      (item) => item.id == dataId,
    );
    if (dietTemplates) {
      setForm((prev) => ({
        ...prev,
        ...dietTemplates,
        BMR_formula: dietTemplates?.BMR_formula || BMR_FORMULAS[0].value,
      }));
    }
  }, [id]);
  const handleChange = (e, key) => {
    setForm({ ...form, [key]: e.target.value });
  };
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };

  const convertCustomizeValueToNumber = (customizeValue) => {
    const genders = ["men", "women"];
    const activityLevels = ["active", "extremely", "inactive", "moderately", "slightly"];
  
    const result = {};
  
    genders.forEach((gender) => {
      result[gender] = {};
  
      activityLevels.forEach((activityLevel) => {
        const value = customizeValue[gender][activityLevel];
  
        if (typeof value === "string") {
          result[gender][activityLevel] = Number(value);
        } else {
          result[gender][activityLevel] = value;
        }
      });
    });
  
    return result;
  };

  const items = createPlanItems();
  const { nutritionist: nutritionistData, updateNutritionist } = useNutritionistContext();
  const [postData] = useRequest('dietplan/create', 'post');
  const handleSubmit = async () => {
    let createdPlan;
    const updatedForm = { ...form, goal: Number(form.goal) }
    setdisbaled(!disbale)
    setForm(updatedForm);
    if (updatedForm.name.length < 1 || form.duration === "0") {
      setIsAlerts(false);
      setIsAlert(false);
      setIsSubmitAlert(true);
      return;
    }
    if (updatedForm) {
      if (dataId) {
        const body = { ...form, PAL: useCustom ? convertCustomizeValueToNumber(customizeValue) : null };
        const result = await updateDietPlan({
          body,
        });
        updateNutritionist({
          ...nutritionistData,
          nutritionist: {
            ...nutritionistData.nutritionist,
            dietTemplates: nutritionistData.nutritionist.dietTemplates.map((item) => {
              history.push({ pathname: `/nutritionist/Challanges/create/sharechallenge/${item.id}`, state: { nutritionistData } })
              if (item.id == result.id) {
                return result;
              }
              return item;
            }),
          },
        });
      } else {
        createdPlan = await postData({
          body: { ...updatedForm, PAL: useCustom ? convertCustomizeValueToNumber(customizeValue) : null },
        });
        nutritionistData?.nutritionist?.dietTemplates?.push(createdPlan);
        if (createdPlan) {
          history.push({ pathname: `/nutritionist/Challanges/create/sharechallenge/${createdPlan?.id}` })
        }
        updateNutritionist({
          ...nutritionistData,
          nutritionist: {
            ...nutritionistData.nutritionist,
          },
        });
      }
      !isAlert
    }
    else {
      console.log('Error');
    }
    setIsSubmitAlert(false);
    setIsAlerts(false);
    setIsAlert(false);
    getForm(setForm(items));
    const params = new URLSearchParams(location.search);
    const redirectBackTo = params.get('redirectBackTo');
    if (redirectBackTo) {
      history.push(`${redirectBackTo}?createdPlanId=${createdPlan.id}`);
    } else {
      isAlert && history.push(link);
    }
  };
  const generateAlertSave = () => {
    setIsSubmitAlert(false);
    setValue(0);

    setIsAlerts(false);
    isAlert && setIsAlert(false);
    window.scrollTo(0, 0);
  };


  const macros = ['fat_percent', 'carbs_percent', 'protein_percent'];
  const handleMacroChanges = (name, value) => {
   
    SetBreakdown(value)
    const index = macros?.indexOf(name);
    const diff = form[name] - value;

    const newVals = macros?.reduce(
      ({ diff, changes, index }, _) => {
        if (diff == 0) return { diff, changes, index };
        const nextName = macros[(index + 1) % macros?.length];
        const nextVal = form[nextName] + diff;
        if (nextVal <= 100 && nextVal >= 0)
          return { diff: 0, changes: { ...changes, [nextName]: nextVal } };
        if (nextVal > 100)
          return {
            diff: nextVal - 100,
            changes: { ...changes, [nextName]: 100, index: index + 1 },
          };
        if (nextVal < 0)
          return {
            diff: nextVal,
            changes: { ...changes, [nextName]: 0 },
            index: index + 1,
          };
      },
      { diff, changes: { [name]: value }, index },
    );
    setForm({
      ...form,
      ...newVals.changes,
    });
  };
  const CustomizePal = () => {
    SetCustomizepal(!customizepal)
  }
  const targetCalories = Math.round(counter);

  const setBackButton = () => {
    setTimeout( ()=>{
      history.push('/nutritionist/coaching/invite');
    } , 500) 
  }

  return (
    //animate__animated animate__zoomIn
    <div className="">
      <CreateChallangeWrapper className={`createChallenge nutritionist-challanges`}>
        <div className={`offering-container ${closeModal && 'close'}`} >
          {!isMobile && (
            <NutritionistControls
              undoIcon
              cancelLink="/nutritionist/coaching/offering"
              disablePublish={disablePublishBtn}
            />
          )}
          <div className="offering-title">
            {!direct &&
             <button
              type="button"
              className='btn-previous'
              onClick={setBackButton}
            >
              <img src={closeIcon} alt="img" />
            </button> }
            <h2> Create a Challenge </h2>
            <div className='challange-create'>
              <p> {value === 0 ? "Create a Challenge for your followers to get real results." : 'Use the Settings to tweak the specifics of your challenge.'} </p>
            </div>
          </div>
          <form className= "form-challenges-margin">
            <Tabs value={value} onChange={handleChanges} aria-label="simple tabs example" className='challanges'>
              <Tab label="Overview" className={value == 0 ? 'challange-Overview' : "other-class"} />
              <Tab label="Settings" className={value == 1 ? 'challange-Settings' : "other-class"} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <OverViewChallenge
                form={form}
                setForm={setForm}
                setIntensity={setIntensity}
                challenge_goal={challenge_goal}
                setchallenge_goal={setchallenge_goal}
              />
              <div className="upload-cover-img">
                <div className='Cover-image-upload'>{form?.image && <img className='Cover-form-image' src={form?.image} height="50px" alt="Diet picture" />}</div>
                <label className='coverText'><img src={gallery}></img></label>
                <label className='coverDesc'>Upload your cover image here </label>
                <div className='form-img-container'>
                  <UploadWidget
                    style={{
                      width: "auto",
                      height: "32px",
                      borderRadius: "21px",
                      padding: "12px 38px",
                      fontSize: "14px",
                      background: "#949D9B",
                      textTransform: "capitalize",
                      color: "#FFFFFF !important"
                    }}
                    buttonText='Upload'
                    afterUploadText='Update'
                    setNewImgUrl={(image) => {
                      setForm((prev) => ({ ...prev, image }));
                    }}
                    img={form?.image}
                  /></div>
              </div>
              <div className='challenge-introduction'>
                <label>Challenge introduction</label>
                <textarea
                  name="description"
                  type="textarea"
                  autoComplete="off"
                  placeholder="Add a brief description of your challenge here. How will it benefit your followers?"
                  className='Challange-duration2'
                  onChange={(e) => {
                    if (e?.target?.value?.length < 160) {
                      setForm({ ...form, introduction: e.target.value });
                    }
                  }}
                  maxLength={160}
                  value={form?.introduction}
                />
              </div>
              <div className="nutritionist-controls">
                <NutritionistControls
                  next={true}
                  cancelLink="/nutritionist/clients"
                  disablePublish={form.name === '' || form.duration === "" || form.type === "" || ((form.duration * 1).toString() === '0')}
                  setValue={setValue}
                />
                <br />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
            {form.type !== 'maintain' && form.type !== '' ?
              <>
                <MeasurementSystem
                  challenge_goal={challenge_goal}
                  intensity={intensity}
                  setIntensity={setIntensity}
                  targetCalories={targetCalories}
                  form={form}
                  setForm={setForm}
                  SetCounter={SetCounter}
                  counter={counter}
                  setshow={setshow}
                  show={show}
                />
              
               <div className='Calorie-btn1'>
                  <button className='Calorie-btn' type="button">
                    {
                    form.type === 'lose-weight' ? 
                    `${form.goal}${form?.goal_type === 'percent' ? "%" : ""} Calorie Deficit` :
                    `${form.goal ? '+' : ''}${form.goal}${form?.goal_type === 'percent' ? "%" : ""} Calorie Surplus`}
                  </button>
                  <hr/>
                </div>
              </>
              : <></>}
              <div className='MacroBreakdown1'>Macro Breakdown</div>
              <Select
                key="BMR"
                options={BMR_FORMULAS_id}
                updateValue={(e) => {
                  if (e === "Balanced(40% Carb, 30% Protein, 30% Fat)") {
                    form.carbs_percent = 40;
                    form.protein_percent = 30;
                    form.fat_percent = 30;
                  }
                  else if (e === 'Low Fat(30% Carb, 50% Protein, 20% Fat)') {
                    form.carbs_percent = 30;
                    form.protein_percent = 50;
                    form.fat_percent = 20;
                  }
                  else if (e === 'Low Carb(20% Carb, 30% Protein, 50% Fat)') {
                    form.carbs_percent = 20;
                    form.protein_percent = 30;
                    form.fat_percent = 50;
                  }
                 
                 
                  SetBreakdown(e)
                }}
                defaultSelected={breakdown}
              />
              <AdvanceSetting
                form={form}
                setForm={setForm}
                setCustomize={setCustomize}
                Customize={Customize}
                handleMacroChanges={handleMacroChanges}
                progress={progress}
                setprogress={setprogress}
                setCustomizeValue={setCustomizeValue}
                setUseCustom={setUseCustom}
              />

              <CustomizePalPopup 
                useCustom={useCustom}
                gender={'men'}
                customizeValue={customizeValue}
                setCustomizeValue={setCustomizeValue}
                activityType={activityType}
                form={form}
                setForm={setForm}
                Customize={Customize}
                CustomizePal={CustomizePal}
                handleChange={handleChange} 
                customizepal={customizepal}
                setCustomize ={setCustomize}
                setUseCustom={setUseCustom}
                alert={alert}
                setAlert={setAlert}

                />
                <CustomizePalPopup 
                useCustom={useCustom}
                gender={'women'}
                customizeValue={customizeValue}
                setCustomizeValue={setCustomizeValue}
                activityType={activityType}
                form={form}
                setForm={setForm}
                Customize={Customize}
                CustomizePal={CustomizePal}
                handleChange={handleChange} 
                customizepal={customizepal}
                setCustomize ={setCustomize}
                setUseCustom={setUseCustom}
                alert={alert}
                setAlert={setAlert}

                />
              {isSubmitAlert && (
                <Alert
                  title="Error"
                  desc={form.duration === '0' ? "Challenge Duration is not valid 0. May you write duration Perfectly, please?": "Challenge name is empty. May you write challenge name, please?"}
                  ok={generateAlertSave}
                  okText="Ok"
                />
              )}
              <div className="nutritionist-controls">
                <Button className='publish-create-btn'
                 disabled = {form.name === '' || form.duration === "" || form.type === "" ||  ((form.duration * 1).toString() === '0')}
                 onClick={handleSubmit}>Publish challenge</Button>
                <br />
              </div>
            </TabPanel>
          </form>
          <br />
        </div>
      </CreateChallangeWrapper>
      </div>
  );
}