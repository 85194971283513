import styled from 'styled-components';
import { useState, useRef, useEffect, useMemo } from 'react';
import MultiScreenStyle from './MultiScreenStyle';
import MobileHeader from 'components/elements/MobileHeader';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import RegularInput from 'components/Input/RegularInput';
import { theme } from 'shared/theme/theme';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router';
import { useAppContext } from 'context/appContext';

const HeightStyle = styled.div`
  .containerr {
    .input_holder {
      display: flex;
      gap: 14px;
    }
    input {
      height: 44px;
      border: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
      padding-left: 16px;
      &:hover,:focus {
        border-color: #00092D !important;
    }
    }
    .suffix {
      right: 16px;
      color: ${({ theme }) => theme?.colors?.lighterGrey};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export default function Height({ activeModalStack, setActiveModalStack, isHealthGoalFlow, nextScreen, modal, totalPagesCount }) {
  const [height, setHeight] = useState({ heightFeet: '', heightInches: '' });
  const { healthGoal, setUpdatedHealthGoal, saveHealthGoal, saveDietPreferences } = useShopContext();
  const { setupLocalUser } = useAppContext();
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });
  const inchRef = useRef();
  const feetRef = useRef();
  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;
  const isSaveDisabled = useMemo(() => {
    if (
      (height?.heightFeet === healthGoal?.heightFeet &&
        height?.heightInches === healthGoal?.heightInches) ||
      (!height?.heightFeet && height?.heightFeet !== 0) ||
      (!height?.heightInches && height?.heightInches !== 0)
    ) {
      return true;
    }
    return false;
  }, [height, healthGoal]);

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
    }, 0);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const regex = /^(0|[0-9]\d*)(,\d{0,2})?$/;
    if (name === 'heightFeet') {
      if (value.length === 1 && regex.test(value)) {
        setHeight({ ...height, [name]: value ? Number(value) : '' });
        setUpdatedHealthGoal((prev) => ({
          ...prev,
          [name]: Number(value),
        }));
      } else {
        const validHeight = value.slice(0, -1);
        setHeight({ ...height, [name]: validHeight ? Number(validHeight) : '' });
      }
    } else if (name === 'heightInches') {
      if (value.length <= 2 && regex.test(value)) {
        const updatedHeight = value <= 11 ? value : 11;
        setHeight({ ...height, [name]: updatedHeight ? Number(updatedHeight) : '' });
        setUpdatedHealthGoal((prev) => ({ ...prev, [name]: Number(updatedHeight) }));
      } else {
        const validHeight = value.slice(0, -1);
        const updatedHeight = validHeight ? Number(validHeight) : '';
        setHeight({ ...height, [name]: updatedHeight });
      }
    }
  };

  useEffect(() => {
    feetRef?.current?.value && inchRef?.current?.focus();
  }, [feetRef?.current?.value]);

  useEffect(() => {
    setHeight({
      heightFeet: healthGoal?.heightFeet || '',
      heightInches: healthGoal?.heightInches || '',
    });
  }, []);

  const handleSave = (e) => {
     e.preventDefault();
    saveDietPreferences();
    setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
  };

  useEffect(() => {
    feetRef?.current?.value && inchRef?.current?.focus();
  }, [feetRef?.current?.value])

  useEffect(() => {
    if(feetRef?.current){
      feetRef?.current?.focus();
    }
  }, [feetRef?.current])

  return (
    <>
      <motion.div
        initial={animation?.initial}
        animate={{ opacity: 1, x: 0 }}
        exit={animation?.exit}
        transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
      >
        <HeightStyle theme={theme}>
          <MultiScreenStyle>
            <MobileHeader
              text={'Height'}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={
                !isHealthGoalFlow
                  ? () =>
                      setActiveModalStack((prev) => [
                        ...prev.filter((item) => item?.modal !== modal?.modal),
                      ])
                  : false
              }
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
            />
            <div className="containerr">
              <p className="subtitle">
                The taller you are, the more your <br /> body needs
              </p>
              <div className="input_holder">
                <RegularInput
                  suffix={'ft'}
                  type="tel"
                  name="heightFeet"
                  onChange={handleChange}
                  value={height.heightFeet}
                  pattern="\d*"
                  autoFocus={true}
                  ref={feetRef}
                />
                <RegularInput
                  suffix={'in'}
                  type="tel"
                  name="heightInches"
                  onChange={handleChange}
                  value={height.heightInches}
                  pattern="\d*"
                  ref={inchRef}
                />
              </div>
            </div>
            <div className="button_holder">
              {isHealthGoalFlow ? (
                <Button
                  type={'button_combo'}
                  iconLeft={<ChevronLeft />}
                  iconRight={<ChevronRight />}
                  comboLeftTitle={'Back'}
                  comboLeftClick={handleBackClick}
                  comboRightClick={() => {
                    setActiveModalStack((prev) => [...prev, nextScreen]);
                    saveHealthGoal();
                  }}
                  comboRightTitle={'Continue'}
                  comboRightDisabled={!height?.heightFeet || (!height?.heightInches && height?.heightInches !== 0)}
                />
              ) : (
                <Button type={'mobile_save'} title={'Save'} isDisabled={isSaveDisabled} onClick={handleSave} />
              )}
            </div>
          </MultiScreenStyle>
        </HeightStyle>
      </motion.div>
    </>
  );
}
