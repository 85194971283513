import classNames from 'classnames';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import ClientDiaryMeal from '../ClientDiaryMeal';
import { useAppContext } from 'context/appContext';
import { useMemo } from 'react';
import moment from 'moment';

const ClientLoggedMealsWrapper = styled.div`
  margin: 0px 20px;
  @media screen and (min-width:1441px) and (max-width: 1920px){
    margin: 0px 50px;
  }
  .diary-list {
    
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .empty-title + p {
    max-width: 219px; 
  } 
  .diary-meal-wrapper {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    gap:20px;
    border-bottom: 1px solid #f6f5f8;
    & + & {
      border-top: 1px solid #7070704d;
    }
  }
  .empty-meal{
    min-height: calc(100vh - 560px);
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #949D9B;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - 422px);
      max-height: calc(100vh - 422px);
      .empty-text{
        margin-top:20px;
      }
    }
    .empty-text{
      word-break: break-all;
    }
  }
  @media (min-width: 992px) {
    height: calc(100vh - 520px);
    &.loggedMealWithMacros {
      height: calc(100vh - 684px);
    }
    .diary-list 
    {
       height:100% !important;
      overflow:auto;
    }
    ::-webkit-scrollbar {
      width: 8px;
    }
    .diary-meal-wrapper{
      width:75%;
    }
  }
  @media(max-width:991px){
    .diary-list{
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display:none;
      }
    }
  }
`;

export default function ClientLoggedMeals({ setValue, diaryMeals, followerProfile, showMacros,activeDay,currentDate,data}) {
  const { isMobile } = useAppContext();
  const listHeight = isMobile ? (showMacros ? (window.innerHeight - 437 - 71) : (window.innerHeight - 290 - 71)) : (window.innerHeight - 415)  ;
  const Row = ({ index, style }) => (
    <div style={style}>
          <ClientDiaryMeal {...diaryMeals[index]}  />
    </div>
  );

  const dayName = useMemo(() => {
    const currentDateMoment = moment(currentDate, 'MM-DD-YYYY');
    const activeDayMoment = moment(activeDay, 'YYYY-MM-DD');
    const dateStr = currentDate;
    const formattedDate = moment(dateStr, 'MM-DD-YYYY').format('DD-MM-YYYY');
    if (currentDateMoment.isSame(activeDayMoment, 'day')) {
      return 'today';
    } else {
      return formattedDate;
    }
  }, [currentDate]);

  return (
    <ClientLoggedMealsWrapper className={classNames({ loggedMealWithMacros: !isMobile && showMacros })}>
      {diaryMeals.length < 1 ? (
       <div className='empty-meal'>
        <div className='empty-text'><p>{followerProfile} hasn’t logged meals for {data?.length > 0 ? dayName : 'today'}</p></div>
       </div>
      ) : (
        <List className="diary-list" height={listHeight} itemCount={diaryMeals?.length}  itemSize={101}>
          {Row}
        </List>
       )} 
    </ClientLoggedMealsWrapper>
  );
}
