import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import Tag from './Tag';
import { Modal } from 'components/elements/modal/Modal';
import { useKitchenContext } from 'context/kitchenContext';

const AllergiesStyles = styled.div`
  background: ${({ theme }) => theme?.colors?.white};
  padding: 0 93px 24px 24px;
  max-width: 900px;
  border-radius: 6px;
  .icons {
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    display: grid;
  }
`;
const DeleteModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: rgb(40 50 65 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    width: 500px;
    max-width: 500px;
    padding: 24px 14px 27px 24px;
    background-color: ${() => theme.colors.secondary};
    border: 1px solid ${() => theme.colors.lightGrey};
    border-radius: 8px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    row-gap: 0;
  }
  .modal_header {
    .icon {
      display: none;
    }
    .title {
      color: ${() => theme.colors.blackDefault};
      font-size: 20px;
    }
    .cross {
      top: 28px;
      right: 24px;
    }
  }
  .modal_body {
    margin: 20px 0 0px;
    .desc {
      color: ${() => theme.colors.blackDefault};
    }
  }
  .btn {
    height: 40px;
    border-radius: 6px;
  }
`;
const Allergies = ({ setMealDetails, mealDetails, calculatedMealData,setEdmamDeletedTags }) => {
    const Tags = [
        'Cereal/Gluten',
        'Peanut',
        'Lupine',
        'Mollusk',
        'Milk',
        'Tree Nut',
        'Sesame',
        'Crustacean',
        'Fish',
        'Egg',
        'Celery',
        'Soy',
        'Mustard',
    ];
    const { items, selectedMealId } = useKitchenContext() || {};
    const recommendedTags = calculatedMealData.recommendedTags;
    const restrictions = calculatedMealData.restrictions;
    const combinedTags = [
      ...recommendedTags.filter((tag) => !tag.endsWith('_FREE')),
      ...restrictions,
    ];


const [isConfirm, setIsConfirm] = useState(false);
const [tagToDelete, setTagToDelete] = useState('');

const handleTagClick = (tag) => {
  const capitalizedTag = tag.toUpperCase();

  if (mealDetails?.restrictions.includes(capitalizedTag)) {
    if (combinedTags.includes(capitalizedTag)) {
      setIsConfirm(true);
      setTagToDelete(capitalizedTag);
    } else {
      const updatedRecommendedTags = mealDetails?.restrictions.filter(selectedTag => selectedTag !== capitalizedTag);
      setMealDetails({
        ...mealDetails,
        restrictions: updatedRecommendedTags,
      });
    }
  } else {
    const updatedRecommendedTags = [...mealDetails?.restrictions, capitalizedTag];
    setMealDetails({
      ...mealDetails,
      restrictions: updatedRecommendedTags,
    });
  }
};

const handleDelete = () => {
  const updatedRecommendedTags = mealDetails?.restrictions.filter(selectedTag => selectedTag !== tagToDelete);
  setMealDetails({
    ...mealDetails,
    restrictions: updatedRecommendedTags,
  });
  setEdmamDeletedTags((prev) => [...prev, tagToDelete]);
  setIsConfirm(false);
};

const selectedItem = useMemo(() => {
  return items.find(item => item.id === selectedMealId);
}, [items, selectedMealId]);

useEffect(() => {
  if (selectedItem) {
    setTimeout(() => {
      setMealDetails({ ...mealDetails, restrictions: selectedItem.ingredients?.allergens, recommendedTags: selectedItem.ingredients?.diets });
    },650) 
  }
}, [selectedItem]);


return (
  <>
    <AllergiesStyles theme={theme}>
      <div className="icons">
        {Tags?.map((tag, index) => (
          <Tag
            tag={tag}
            isSelected={mealDetails?.restrictions.includes(tag.replace(/\s/g, '_').toUpperCase())}
            onTagClick={() => handleTagClick(tag.replace(/\s/g, '_').toUpperCase())}
            avoid={mealDetails?.restrictions.includes(tag.replace(/\s/g, '_').toUpperCase())}
            key={index}
          />
        ))}
      </div>
    </AllergiesStyles>
    {isConfirm && (
      <DeleteModalWrapper>
        <Modal
          title="Are you sure?"
          description={`The ${tagToDelete} allergen was identified from the database. Are you sure to remove this allergen?`}
          secondaryText="Cancel"
          onCancel={() => setIsConfirm(false)}
          onClose={() => setIsConfirm(false)}
          primaryText="Confirm"
          onOk={handleDelete}
          buttonType="danger"
        />
      </DeleteModalWrapper>
    )}
  </>
);
};

export default Allergies;
