import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import AdminSidebar from './AdminSidebar';
import Progress from 'components/Progress';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import BackButton from 'components/BackButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import Select from 'components/Select';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import PromoCodeForm from './PromoCodeForm';
import { FormControl, FormControlLabel, RadioGroup, Radio, Hidden } from '@material-ui/core';
import { sum } from 'ramda';
import PromocodeDeleteModal from './PromocodeDeleteModal';
import { useDebounce } from 'use-debounce/lib';

const UserManagementStyle = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 40px 30px 30px;
  background:#ffffff;
  font-family: 'Roboto';
  font-style: normal;
  display:flex;
  flex-direction:column;
  align-items:center;
  min-height: calc(100vh - 32px);

  .fJeUUY{
    position:fixed;
    top:23px !important;
  }
 
  .total-user{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    div{
      display:flex;
      width: 171px;
      height: 40px;
      background: #52C41A;
      border-radius: 21px;
      align-items:center;
      justify-content:center;
      cursor:pointer;
      p{
        color: #FFFFFF !important;
        font-weight: 700 !important;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .user{
    display:flex;
    justify-content:center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #51635D;
  }

  .search{
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
     margin-top:30px;
     align-items: center;
     width:100%;
     input{
        height:44px;
        border: 1px solid #E0E3E0;
        border-radius: 6px;
        width:100%;
        background:url('/icons/search-icon.svg') no-repeat 16px;
        padding-left: 40px;
        color: #AFB5B4;
     }
     input:focus{
        outline:0px !important;
        -webkit-appearance:none;
        box-shadow: none;
     }
    }

    .heading-div{
      display:flex;
      width:100%;
      justify-content:start;
    }

  .heading{
    display:flex;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    margin-top:40px;
    margin-bottom:14px;
    width: 98%;
    justify-content: space-between;
    padding-left:20px ;
    gap:5px;
    p{
        word-break: break-word;
    }
    p:nth-child(1){
      width:40px;
    } 
    p:nth-child(2){
        width:47px;
        display:flex;
        gap:12px;
    }
    p:nth-child(3){
        width:39px;
    }
    p:nth-child(4){
        width:57px;
    }
    p:nth-child(5){
        width:48px;
        text-align:center;
    }
    p:nth-child(6){
      width: 43px;
      text-align: center;
    }
    p:nth-child(7){
        width:60px;
        //text-align:center;
    }
    p:nth-child(8){
      width:34px;
      text-align:center;
    }
    p:nth-child(9){
        width:61px;
        text-align:center;
    }
    p:nth-child(10){
        width:61px;
        text-align:center;
    }
    p:nth-child(11){
        width:41px;
    }
    p:nth-child(12){
        width:28px;
    }
    p:nth-child(13){
        width:75px;
    }
    p:nth-child(14){
        width:75px;
    }
    p:nth-child(15){
      width: 40px;
      text-align:right;
      cursor:pointer;
  }          

  }

  .infinite-scroll-component__outerdiv{
    width:100%;
  }
  .details-div{
    width:100%;
  }

  .details{
    display:flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    justify-content: space-between;
    padding: 16px 20px;
    background: #FFFFFF;
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    margin-bottom:6px;
    gap:5px;
    width:100%;
  }
  .detailed{
    display:flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #51635D;
    justify-content: space-between;
    padding: 16px 20px;
    background: #F0FAEB !important;
    border: 1px solid #97DC76;
    border-radius: 6px;
    margin-bottom:6px;
    gap:5px;
    width:100%;
  }

  .details , .detailed{
    p{
        word-break: break-word;
    }
    p:nth-child(1){
      width: 33px;
    } 
    p:nth-child(2){
        width:50px;
        display:flex;
        gap:10px;
        padding-left:2px;
    }
    p:nth-child(3){
        width:44px;
    }
    p:nth-child(4){
        width:48px;
    }
    p:nth-child(5){
        width:48px;
        text-align:center;
    }
    p:nth-child(6){
      width:60px;  
    }
    p:nth-child(7){
      width: 1px;
      margin-right: 0;
      margin-left: -61px;
  }
    p:nth-child(8){
      width: 60px;
      text-align:center;
    }
    p:nth-child(9){
        width:15px;
        padding-left: 7px;
    }
    p:nth-child(10){
        width:101px;
        text-align:center;
        padding-right: 14px;
    }
    p:nth-child(11){
        width:41px;
    }
    p:nth-child(12){
        width:28px;
    }
    p:nth-child(13){
        width:30px;
    }
    p:nth-child(14){
        width:80px;
    }
    p:nth-child(16){
      display:flex;
      gap:10px;
      img{
        width:14px;
        height:14px;
        cursor:pointer;
      }
  }          


  }
  .details:nth-child(even){
    background:#F6F5F8;
  }
  .more{
    width:100%;
    border-bottom:1px solid #E0E3E0;
    border-left:1px solid #E0E3E0;
    border-right:1px solid #E0E3E0;
    border-radius:6px;
    margin-bottom:6px;
  }
  .more-details{
    border:unset !important;
  }
  .more-details:nth-child(odd){
    background:#F0FAEB !important;
  }
  .more-details:nth-child(even){
    background:#ffffff !important;
  }

  .show-more{
    border: 1px solid #97DC76;
    border-radius: 21px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content:center;
    align-items:center;
    color: #51635D;
    width: 149px;
    height: 40px;
    margin-top:30px; 
    cursor: pointer;
  }
  .MuiFormControlLabel-root{
    margin-left:0 !important;
    margin-right:0 !important;
  }
  .MuiIconButton-root{
    padding:0 !important;
  }

  @media (max-width: 991px) {
    width: 90%;
    padding: 24px 0;
    margin: 23px auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .delete-div{
      display:flex;
      width: 90%;
      justify-content: space-between;
      padding: 0px 12px;
      margin-bottom:5px;
    }

    .fJeUUY{
      position:fixed;
      top:55px !important;
    }

    .user{
      font-size: 16px !important;
      line-height: 19px !important;
      color:#0B1D17 !important;
      margin-top: 10px;
    }

    .infinite-scroll-component__outerdiv{
      width:90%;
    }
    
    .user-details{
      margin-top:10px;
      width:90%;
      background:#F6F5F8;
      border: 1px solid #E0E3E0;
      border-radius: 6px;
      padding:10px 12px;
    }
    
    .user-detailss{
      margin-top:10px;
      width:90%;
      background:#F0FAEB !important;
      border: 1px solid #97DC76;
      border-radius: 6px;
      padding:10px 12px;
    }
     
      .name, .coach{
        display:flex;
        align-items:center;
        gap:5px;
      }
      .name{
        margin-bottom:6px;
        padding-left:30px;
      }
      .name:nth-child(1){
        padding-left:0 !important;
        align-items:flex-end !important;

        .user-text{
          display:flex;
          align-items:flex-end;
        }
        .user-name{
          margin-left:30px;
        }
      }
      .user-text{
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #51635D;
        width:106px; 
      }
      .user-name{
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635D; 
      }
    
    .user-details:nth-of-type(even){
        background: #ffffff;
    }
    .search{
      display: grid;
      grid-template-columns: 44% 44% !important;
      justify-content:center;

      input::placeholder{
        font-size:12px;
      }
   }
   .overtflowStylingNone{
    overflow:auto !important;
  }
  .overFLOWstyling{
   position:fixed;
  }
   .MuiIconButton-label{
      span{
       width:14px !important;
       height:14px !important;
      }
    }
    .MuiFormControlLabel-root{
      margin-bottom:0 !important;
    }
    .id{
      margin-left:17px;
    }
    .expand{
      margin-left:90px;
      display: flex;
    }
    .more{
      width:90% !important;
      padding-top:10px;
      margin-bottom:0 !important;
      border-top-left-radius:0 !important;
      border-top-right-radius:0 !important;
    }
    .dropdown{
      display:flex;
      padding:6px 0 6px 42px;
      img{
        width:14px;
        height:14px;
        cursor:pointer;
      }
      p:nth-child(3){
        margin-left:53px;
      }
      p:nth-child(4){
        margin-left:10px;
      }
    }
    .dropdown:nth-child(odd){
      background:#F0FAEB;
    }
    .count-text{
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #51635D;
    }
    .user-discount{
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #51635D;
      margin-left:60px;
      width:40px;
    }
  }
  .new-coupen-text{
    margin:0px;
  }

  @media(min-width:992px) and (max-width:1300px){
   
  .infinite-scroll-component__outerdiv{
      width:100% !important;
    }
  }
  @media(min-width:1300px) and (max-width:1500px){
   
    .infinite-scroll-component__outerdiv{
      width:100% !important;
    }
  }
  @media(min-width:992px) and (max-width:1230px){
    .heading{
      font-size:7px !important;
    }
    .details{
       font-size:7px !important;
    }
  }
  @media(min-width:1230px) and (max-width:1295px){
    .heading{
      font-size:10px !important;
    }
    .details{
       font-size:9px !important;
    }
  }
  @media(min-width:1295px) and (max-width:1370px){
    .heading{
      font-size:11px !important;
    }
    .details{
       font-size:10px !important;
    }
  }
  @media(min-width:1800px){
    .heading{
      width:99% !important;
    }
  }
  @media(min-width:992px) and (max-width:1100px){
    .heading{
      padding-right:20px;
    }
  }
  @media(min-width:1100px) and (max-width:1200px){
    .heading{
      padding-right:15px;
    }
  }
 
`
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  // backgroundColor: "#52C41A"
}));
const BpCheckedIcon = styled(BpIcon)({
  //backgroundColor: "#52C41A",
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage:  "url('/icons/tick.svg')",
      backgroundRepeat:'no-repeat',
      backgroundPosition:'50%',
      backgroundColor:'radial-gradient(#fff,#fff,28%,transparent 32%)',
      border:'1px solid #52C41A',
      borderRadius:'50%',
      content: '""',

  },

});

const BpCheckIcon = styled(BpIcon)({
  //backgroundColor: "#52C41A",
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
      display: 'block',
      width: 12,
      height: 12,
      backgroundImage:  "url('/icons/tick.svg')",
      backgroundRepeat:'no-repeat',
      backgroundPosition:'50%',
      backgroundColor:'radial-gradient(#fff,#fff,28%,transparent 32%)',
      border:'1px solid #52C41A',
      borderRadius:'50%',
      content: '""',

  },

});

;
const ButtonStyle = styled.div`
.total-user{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  div{
    display:flex;
    width: 171px;
    height: 40px;
    background: #52C41A;
    border-radius: 21px;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    p{
      color: #FFFFFF !important;
      font-weight: 700 !important;
      font-size: 14px;
      line-height: 16px;
    }
    .text-head{
      margin:0px;
    }
  }
}
`

export default function PromoCodeTable() {
  const { isDesktop, isMobile } = useAppContext()
  const [getAllPromocodes, { isLoading, error, state: success, setState: setSuccess }] = useRequest();
  const [promocodes, setPromocodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('')
  const [hasMore, setHasMore] = useState(true)
  const [filter, setFilter] = useState('all')
  const [expanded, setExpanded] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [deletePromoCode, {isLoading: isPromocdeDeleting}] = useRequest('promoCode/delete', 'post');
  const [idsToDelete, setIdsToDelete] = useState([]);
  const [allcheckedToDelete, setAllCheckedToDelete] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [SearchedValue] = useDebounce(searchTerm, 600);


  
  const selectRawBackground=(id)=>{
    const itemExist=idsToDelete.some((ids)=>ids===id)
    if(itemExist){
      return "user-detailss";
    }
  }

  const selectedRawBackground=(id)=>{
    const itemExist=idsToDelete.some((ids)=>ids===id)
    if(itemExist){
      return "detailed";
    }
  }



  const handleExpandRow = (event, uuid) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(uuid);

    let obj = {};
    isRowExpanded ? (obj[uuid] = false) : (obj[uuid] = true);
    setExpandState(obj);

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== uuid)
      : currentExpandedRows.concat(uuid);
    setExpandedRows(newExpandedRows);
  };

  const [promoCodeInfo,setpromoCodeInfo] = useState('')
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const deletePromoCodeFn = async()=> {
    idsToDelete?.length && await deletePromoCode({
      body: {idsToDelete}
    }).then((res)=>{
      if(res.success == true){
        console.log("success", res);
        toast.success(res.message);
      }
      setIdsToDelete([]);
      setIsOpen(false);
      fetchAllPromocodes();
    })
    .catch((e)=>  {
      console.log("e", e)
     toast.error("Something went wrong")
    })
  };
  const handleSelectedPromoId = (id) => {
    var index = idsToDelete.indexOf(id);
    if(index >-1 ){
     setIdsToDelete(()=>idsToDelete.filter((item)=>item!==id))
    } else {
      setIdsToDelete([...idsToDelete,id])
    }
  };


 
  const fetchAllPromocodes = async () => {
    try {
      let path = `admin/all-promo-codes?pageSize=30&page=${currentPage}&searchText=${SearchedValue}&code_type=${filter}`;
      const result = await getAllPromocodes({ path });
      setPromocodes(result.data);
      setTotalPages(result.totalPages);
    } catch (err) {
      console.log(err, 'err');
    }
  };
  
  const fetchMoreData = async () => {
      const nextPage = currentPage + 1;
      try {
        let path = `admin/all-promo-codes?pageSize=30&page=${nextPage}&searchText=${SearchedValue}&code_type=${filter}`;
        const result = await getAllPromocodes({ path });
        if (result.data.length > 0) {
        setPromocodes((prevData) => [...prevData, ...result.data]);
        setCurrentPage(nextPage);
        }
        if (currentPage == totalPages) {
          setHasMore(false);
        }
      } catch (err) {
        console.log(err, 'err');
      }
  };
  
  const formateDate = (date) => {
    return moment(new Date(date)).format('MMM DD, YYYY')
  }

  
  const handleAllSelectedPromoId = () => {
    const allIds= promocodes?.map( (item) =>item.id
    );
    if(allcheckedToDelete?.length === 0){
      setAllCheckedToDelete(allIds);
      setIdsToDelete(allIds);
    } else {
      setAllCheckedToDelete([]);
      setIdsToDelete([]);
    }
   }

   useEffect(() => {
    setCurrentPage(1);
      fetchAllPromocodes();
      setHasMore(true);
   },[filter,SearchedValue])

  useEffect(()=>{
    ReactTooltip.rebuild();
  },[promocodes])

  useEffect(() => {
    if (isModal || isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModal, isOpen]);

  const isPromoCodeEditable = (promoObj = {}) => {
    const { code_type = null, orderMain = null, data = {} } = promoObj;
  
    if (code_type !== 'giftCard') {
      return !(orderMain?.length > 0);
    } 
  
    return !(data?.usedAmount > 0);
  };  
    return (
      <>
      <ButtonStyle>
     {isMobile && <div className='total-user'>
      <div onClick={() => setIsModal(true)}> 
       <p className='text-head'>+  New Coupon</p> 
       <p></p> 
      </div>
     </div>}
     </ButtonStyle>
    <div style={{position : isModal ? 'fixed' : '',width : isModal ? '100%' : '',zIndex : isModal ? '5' : ''}}>
  
      <UserManagementStyle   >
       <BackButton/>
     {isDesktop &&  <div className='total-user'>
         <div onClick={() => setIsModal(true)}> 
          <p className='new-coupen-text'>+  New Coupon</p> 
          <p></p> 
         </div>
        </div>}
        <div className='user'>
          <p>Promo codes</p>
        </div>

        <div className='search'>
          <input type='text' placeholder='Search Promocode' onChange={(e) => {setSearchTerm(e.target.value),setCurrentPage(1)}} value={searchTerm} />
        <div className='select'>
          <Select
            key="type"
            defaultChecked={filter}
            label="Select Code Type"
            icon={'SelectArrow' }
            options={[
              { label: 'All',  value:'all' },
              { label: 'Specific ', value: 'specific'},
              { label: 'Common', value: 'common' },
              { label: 'Common Multi Use', value: 'commonMultiUse' },
              { label: 'Gift Card', value: 'giftCard' },
            ]}
            updateValue={(value) => {
              setCurrentPage(1);
              setFilter(value);
              if (allcheckedToDelete.length > 0) {
                setAllCheckedToDelete([]);
                setIdsToDelete([]);
              }
            }}
          />
          </div>
        </div>


        { isDesktop && 
        <div className='heading-div'>
        <div className='heading'>
          <p>S.no.</p>
          <p>
          <div className='radio-div'>
              <FormControl>
                   <RadioGroup row name="first-order">
                      <FormControlLabel
                         className='checkmark'
                         value={allcheckedToDelete?.length>1 ? true : false}
                         checked={allcheckedToDelete?.length>1 ? true : false}
                         control={<Radio  onClick={handleAllSelectedPromoId} checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />}
                      />
                   </RadioGroup>
                   </FormControl>
              </div>
          <span> ID</span> 
          </p>
          <p>Promo Code</p>
          <p>Code Type</p>
          <p>Amount</p>
          <p>Amount Used</p>
          <p>Disc. Type</p>
          <p>Count</p>
          <p>Min. Order Amt.</p>
          <p>Max. Order Limit</p>
          <p>Expired</p>
          <p>Used</p>
          <p>Validity starts </p>
          <p>Validity ends </p>
          <p><img src={idsToDelete?.length>0 ? '/icons/trash-green.svg' :  '/icons/trash2.svg'} onClick={() =>{idsToDelete.length>0 ? setIsOpen(true) : setIsOpen(false)}} /></p>

        </div>
        </div>} 

        {isMobile && 
        <div className='delete-div'>
             <div className='radio-div'>
              <FormControl>
                   <RadioGroup row name="first-order">
                      <FormControlLabel
                         className='checkmark'
                         value={allcheckedToDelete?.length>1 ? true : false}
                         checked={allcheckedToDelete?.length>1 ? true : false}
                         control={<Radio  onClick={handleAllSelectedPromoId} checkedIcon={<BpCheckIcon />} icon={<BpIcon />} />}
                      />
                   </RadioGroup>
                   </FormControl>
              </div>

              <div><img src={idsToDelete.length>0 ? '/icons/trash-green.svg' :  '/icons/trash2.svg'} onClick={() =>{idsToDelete.length>0 ? setIsOpen(true) : setIsOpen(false)}}/></div>
        </div>
        }  
            <InfiniteScroll
              dataLength={promocodes.length}
              next={fetchMoreData}
              hasMore={hasMore}
            >
{promocodes?.map((item, index) => (
  (isDesktop ? (
    <>
    <div className={`details ${selectedRawBackground(item?.id)}`}  key={index}>
      <p>{index + 1}</p>
      <p>
       
      <div className='radio-div'>
              <FormControl>
                   <RadioGroup row name="first-order" defaultValue="second">
                      <FormControlLabel
                         className='checkmark'
                         disabled={item.isUsed}
                         value={!idsToDelete.some((ids)=>ids==item.id)}
                         checked={idsToDelete.some((ids)=>ids==item.id)}
                         control={<Radio onClick={()=>handleSelectedPromoId(item?.id)}
                              checkedIcon={<BpCheckedIcon />} icon={<BpIcon />}
                         />}
                      />
                   </RadioGroup>
                   </FormControl>
              </div>
       <span>{item?.id}</span>  
        </p>
      <p data-tip={item?.code} data-for="name" style={{cursor: 'pointer'}}>{item?.code.replace(/(.{6})..+/, "$1")+'...' || 'N/A'}</p>
      <p>{item?.code_type || 'N/A'}</p>
      <p>{item?.discount ? item?.discount_type=="flat" ? 
      `$${item?.code_type == "commonMultiUse" ? item?.data?.maxUsedCount ?
      sum(item?.data?.maxUsedCount?.map((item)=>item?.discount)).toFixed(2) :0
      :item?.discount}`
      : `${item?.code_type == "commonMultiUse" ?
      "__" : `${item?.discount}%`}` 
      : 'N/A'}</p>
      <p>{item?.code_type === "giftCard" ?
    `$${item?.data.usedAmount?(item?.data.usedAmount).toFixed(2):"0"}` : "N/A"}
    </p>
      <p style={{cursor:"Pointer"}}>{(item?.data?.maxUsedCount?.length>1 && item?.code_type == "commonMultiUse") && <img src='/icons/expand-green.svg'  onClick={event => handleExpandRow(event, item.id)}/>}</p>
      <p>{item?.discount_type || 'N/A'}</p>
      <p>{(item?.code_type == "commonMultiUse" ? item?.data?.maxUsedCount?.length : item?.data?.usedCount) || (item?.data?.usedCount ? item?.data?.usedCount : "0") || ''}</p>
      <p>{item?.minOrderAmount ? `$${item?.minOrderAmount}` : 'N/A'}</p> 
      <p>{item?.data?.maximumOrderLimit ? `$${item?.data?.maximumOrderLimit}` : 'N/A'}</p>   
      <p>{item?.isExpired?.toString() || 'N/A'}</p>
      <p>{item?.isUsed?.toString() || 'N/A'}</p>
      <p>{item?.validityStartDate ? formateDate(item?.validityStartDate) : 'N/A'}</p>
      <p>{item?.validityEndDate ? formateDate(item?.validityEndDate) : 'N/A'}</p>
      <p style={(item?.orderMain?.length) ? {marginLeft:'38px'} : {marginLeft:'0'}} > 
      {(isPromoCodeEditable(item)===true ) &&
        <>
        <img src='/icons/edit-gray.svg' onClick={()=>{setpromoCodeInfo(item) , setIsModal(true)}}/>
        <img src='/icons/trash2.svg' onClick={() =>{setIdsToDelete([item.id]), setIsOpen(true), setAllCheckedToDelete([])}}/>
        </>
      }</p>

    </div>
 <> 
 
     {expandedRows.includes(item.id) &&

     <div className='more'>
    {item?.data?.maxUsedCount?.map((user, index)=>(
    <div className='details more-details' key={index}>
      <p></p>
      <p></p>
      <p>__</p>
      <p>__</p>
      <p>{item?.discount ? item?.discount_type=="flat" ? `$${user?.discount}` : `${user?.discount}%` : 'N/A'}</p>
      <p>__</p>
      <p></p>
      <span style={{width: "40px"}}></span>
      <p style={{width: "22px"}}>#{index +1}</p>
      <p>{user?.minOrderAmount ? `$${user?.minOrderAmount}` : 'N/A'}</p>
      <p>{user?.maxOrderAmount ? `$${user?.maxOrderAmount}` : 'N/A'}</p>
      <p>{user?.isExpired?.toString() || 'N/A'}</p>
      <p style={{marginRight:'49px'}}>{user?.isUsed?.toString() || 'N/A'}</p>
      <p>{user?.validityStart ? formateDate(user?.validityStart) : '__'}</p>
      <p style={{textAlign:'center'}}>{user?.validityEnd ? formateDate(user?.validityEnd) : '__'}</p>
      <p style={{marginLeft:'25px'}}>
        {"__" && "__"}</p>
    </div>))}
    </div>
    }
    </>

    </>
    ) : 
    <>
     <div className={`user-details ${selectRawBackground(item?.id)}`}>
  
      <div className='name'> <p className='user-text'>
          <FormControl>
            <RadioGroup row name="second-order">
              <FormControlLabel
                value={!idsToDelete.some((ids)=>ids==item.id)}
                checked={idsToDelete.some((ids)=>ids==item.id)}
                control={<Radio onClick={()=>{handleSelectedPromoId(item?.id)}}
                     checkedIcon={<BpCheckIcon />} icon={<BpIcon />}
                />}
              />
            </RadioGroup>
          </FormControl>
        <span className='id'>ID:</span> 
        </p>
      
      <p className='user-name'>{item?.id} </p>
      <span className='expand'>{item?.data?.maxUsedCount?.length>1 && <img src='/icons/expand-green.svg'  onClick={event => handleExpandRow(event, item.id)}/>}</span>
      
       </div>
      <div className='name'> <p className='user-text'>Discount type:</p><p className='user-name'>{item?.discount_type || 'N/A'} </p> </div>
      <div className='name'> <p className='user-text'> Promo code:</p><p className='user-name'>{item?.code || 'N/A'}</p> </div>
      <div className='name'> <p className='user-text'>Code type:</p><p className='user-name'>{item?.code_type || 'N/A'}</p> </div>
      <div className='name'> <p className='user-text'>Amount:</p><p className='user-name'>{item?.discount ? item?.discount_type=="flat" ? `$${item?.data?.maxUsedCount?.length>1 ? sum(item?.data?.maxUsedCount?.map((item)=>item?.discount))  :item?.discount}` : `${item?.data?.maxUsedCount?.length>1 ? "__" : `${item?.discount}%`}` : 'N/A'} </p> </div>
      <div className='name'> <p className='user-text'>Amount Used:</p><p className='user-name'>{item?.code_type === "giftCard" ?`$${item?.data.usedAmount?(item?.data.usedAmount).toFixed(2):"0"}` : "N/A"}</p> </div>
      <div className='name'> <p className='user-text'>Count:</p><p className='user-name'>{(item?.code_type == "commonMultiUse" ? item?.data?.maxUsedCount?.length : item?.data?.usedCount) || (item?.data?.usedCount ? item?.data?.usedCount : "0") || ''}</p></div>
      <div className='name'> <p className='user-text'>Min. Order Amount:</p><p className='user-name'>{item?.minOrderAmount ? `$${item?.minOrderAmount}` : 'N/A'} </p> </div>
      <div className='name'> <p className='user-text'>Max. Order Limit:</p><p className='user-name'>{item?.data?.maximumOrderLimit ? `$${item?.data?.maximumOrderLimit}` : 'N/A'} </p> </div>
      <div className='name'> <p className='user-text'>Expired:</p><p className='user-name'>{item?.isExpired.toString() || 'N/A'} </p></div>
      <div className='name'> <p className='user-text'>Used:</p><p className='user-name'>{item?.isUsed.toString() || 'N/A'} </p></div>
      <div className='name'> <p className='user-text'>Validity starts :</p><p className='user-name'>{formateDate(item?.validityStartDate)} </p></div>
      <div className='name'> <p className='user-text'>Validity ends :</p><p className='user-name'>{formateDate(item?.validityEndDate)} </p>
          {
            !item?.orderMain?.length && (
              <span className='expand'>
                <img src='/icons/edit-gray.svg' onClick={() => { setpromoCodeInfo(item), setIsModal(true) }} />
              </span>
            )
          }
      
      </div>
      
  
     </div>
       {expandedRows.includes(item.id) &&

        <div className='more'>
       {item?.data?.maxUsedCount.map((user, index)=>(
       <div className='dropdown' key={index}>
         <p className='count-text'>Count #{index +1}:</p>
         <p className='user-discount'>{item?.discount ? item?.discount_type=="flat" ? `$${user?.discount}` : `${user?.discount}%` : 'N/A'}</p>
         <p  onClick={() => { setpromoCodeInfo(item), setIsModal(true) }}>
          {(user?.isExpired == false && user?.isUsed == false) && <img src='/icons/edit-gray.svg' />}</p>
         <p style={user?.isExpired !== false || user?.isUsed !== false ? {marginLeft:'24px'} : {}} onClick={() =>{idsToDelete.length>0 ? setIsOpen(true) : setIsOpen(false)}}><img src='/icons/trash2.svg' /></p>
       </div>))}
       </div>
       }
       </>
    )
    ))}
    </InfiniteScroll>
   {isDesktop && <ReactTooltip id="name" place="top" effect="solid">
    </ReactTooltip>}
    {
      !promocodes.length && (
        <p style={{marginTop:'40px',color:'#51635D',textAlign:'center'}}>No Record Found</p>
      )
    }
  {isModal && <PromoCodeForm setIsModal={setIsModal} promoCodeInfo={promoCodeInfo} setpromoCodeInfo={setpromoCodeInfo} isModal={isModal} setPromocodes={setPromocodes} promocodes={promocodes} />}
  {isOpen && <PromocodeDeleteModal setIsOpen={setIsOpen}  isOpen={isOpen} deletePromoCodeFn={deletePromoCodeFn} idsToDelete={idsToDelete} />}
  </UserManagementStyle>

    </div>
    </>
  );
}

