import { useMemo } from 'react';
import Tags from 'components/shop/MealTags';
import styled from 'styled-components';
import LocationIcon from '../../../../assets/Location.svg'
import ImageViewer from '../../../../components/ImageViewer';
import MealMultiTags from 'components/shop/MealMultiTags';
import { getEarliestTime, toDateString, toTimeString, convertTimeTo12HrFormat } from '../../../../pages/shop/providerTimes';
import { useNutritionistContext } from 'context/nutritionistContext';
import { useAppContext } from 'context/appContext';

const Container = styled.div`
  cursor: pointer;
  border: 1px solid #e8ebe8;
  background: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  /* margin-bottom: 26px; */
 
  @media (min-width:992px){
    .card-desktop-box{
      display: grid;
      padding-left:2%;
      grid-template-columns: 24% 69.9%;
      grid-column-gap:6.1%;
    }
    .picup-wrapper{
      width: 100%;
    display: flex;
    }
    .wrapping-image{
      display: flex;
      align-items: center;

      img{
        border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 200px;
      }
    }
    .provider-card-info {
      padding:12px 0px 15px !important;
    }
    .map-pin-city {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      color: #51635D;
    }

    h3 {
      margin: 24px 0px 6px 0px;
      color: #0B1D17;
      font-size: 24px;
      font-weight: 700;
    }

    .min-order {
      width: 127px;
      height: 32px;
      margin-top: 22px;
      color: #949D9B;
      font-size: 11px;
      background: #F0FAEB;
      display: flex;
      justify-content: center;
      align-items:center;
      line-height: 16px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .deliveryinfo-sec {
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items:center;
      min-height: 22px;
      margin-left: 92px;
      margin-right: 158px;
      margin: 0px;
      padding: 0 38px;
      
   .deliverLeft {
            width: 100%;
            display: flex;
            justify-content:space-between;
          }

          .pick-up {
              width: 100%;
              display: flex;
              // justify-content: center;

              p {
                  color: #51635D;
                  font-size: 14px;

                  span {
                      white-space: nowrap;
                      font-style: normal;
                      padding-top: 3px;
                      font-family: 'Roboto';
                      ;
                      font-size: 12px;
                      line-height: 14px;
                      color: #51635D;
                      font-weight: 700 !important;
                  }

                  .time-separator {
                      position: relative;
                      margin: 0px 4px 0px 2px;

                      &::after {
                          content: '';
                          position: absolute;
                          width: 1px;
                          height: 11px;
                          background-color: #adafaf;
                          top: -3px;
                      }
                  }
           }
          }
      }

  }

  img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    max-height:84px !important;
    // object-fit: cover;
    // object-fit: contain;
  }
  .provider-card-info {
    padding: 12px 12px 15px;
    .multiTags-listing .icon-wrapper {
      width: 30px;
      height: 30px;
    }
  }

  .city-wrapper {
    display: flex;
    font-size: 12px;
    justify-content: space-around;
  }
  .map-pin-city {
    display: flex;
    align-items: center;
    color: #51635D;
    .map-pin{
      display:flex;
    }
  }
  
  .map-pin-city span {
    font-size: 12px;
  }
  .map-pin {
    margin-right: 5px;
    margin-top: 0px;
    svg {
      font-size: 15px;
    }
  }
  .pick-delivery {
    position: relative;
    padding-left: 14px;
  }
  .pick-delivery:before {
    content: '';
    position: absolute;
    top: 25%;
    left: 0;
    width: 6px;
    height: 6px;
    background: var(--lightGray);
    border-radius: 50%;
  }
  .provider-min-order{
    display:flex;
    justify-content:space-between;
  }
 
  .pick-delivery-wraper {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      transform: rotate(180deg);
      color: #0b1c18;
    }
  }
  .meal-tooltip-icon {
    color: var(--nutritt-green);
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }
  .meal-tooltip-icon-png {
    width: 20px;
    height: 20px;
  }

  .pick-delivery-wraper .MuiTooltip-popper {
    width: 275px;

    .MuiTooltip-tooltipPlacementTop {
      margin: -60px 0;
    }
  }

   
`;


export default function ProvidersCard({providerModal, provider, setPickMealsModal, setProviderId}) {

  const {setFreshMealsProvider} = useNutritionistContext();

  const {
    id,
    name,
    images,
    tags,
    city,
    minOrderAmount,
    schedule,
    address,
    deliveryCoverage = [],
  } = provider;
  
    const earliestDeliveryTime = getEarliestTime(schedule, 'delivery');
    const earliestPickupTime = getEarliestTime(schedule, 'pickup');
    const {convertToHttps} = useAppContext();
    let pickupAndDelivery = useMemo(() => {
      if (earliestDeliveryTime && earliestPickupTime) {
        return 'Both';
      } else if (earliestPickupTime) {
        return 'Pickup';
      } else if (earliestDeliveryTime) {
        return 'delivery';
      } else {
        return '';
      }
    }, [earliestDeliveryTime, earliestPickupTime]);

    const cardClick = () => {
      setFreshMealsProvider(provider);
      setProviderId(provider.id);
      setPickMealsModal(true);
    }

    const imageUrl = images?.[0];
    const secureImageUrl = convertToHttps(imageUrl);

    return pickupAndDelivery ? (
        <Container className='card-box' onClick={cardClick}>
            <div className="card-inner-box card-desktop-box card-border">
                <div className='wrapper-image wrapping-image'>
                    <ImageViewer src={secureImageUrl || "/images/avatar.png"} fallbacksrc={"/images/avatar.png"} />
                </div>
                <div className="provider-card-info provider-desktop-info">

                    <div className='provider-card-top'>
                        <div className='provider-card-head'>
                            <h3>{name}</h3>
                            <div className="map-pin-city">
                                <div className="map-pin">{city && <img src={LocationIcon} alt="Icon" className="card-icons" />}</div>
                                {city}
                            </div>
                        </div>
                        <div className='tag-min-order-sec'>
                            {
                                tags?.length > 6 ?
                                  <MealMultiTags tags={tags} providerName={name} providerModal={providerModal} />
                                :
                                <Tags  tags={tags}/>
                            }

                            <span className="min-order">{`min. order: $${minOrderAmount || 0}`}</span>
                        </div>
                    </div>
                    <div className='deliveryinfo-sec'>
                        {earliestDeliveryTime &&(
                            <div className="city-wrapper deliverLeft">
                                <div className="pick-up">
                                    <div className="map-pin">
                                        <img src="/icons/delivery-icon.svg" alt="Icon" className="card-icons" />
                                    </div>
                                    <p>Delivery by: <span>{toDateString(earliestDeliveryTime)} <sub className="time-separator" /> {convertTimeTo12HrFormat(toTimeString(earliestDeliveryTime))} </span></p>
                                </div>
                            </div>
                        )}
                        {earliestPickupTime && (
                            <div className="city-wrapper">
                                <div className="pick-up">
                                    <div className="map-pin">
                                        <img src="/icons/pickup-icon.svg" alt="Icon" className="card-icons" />
                                    </div>
                                    <p>Pick-up by: <span>{toDateString(earliestPickupTime)} <sub className="time-separator" /> {convertTimeTo12HrFormat(toTimeString(earliestPickupTime))} </span></p>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </Container>
      ) : (
        ''
      );
}
