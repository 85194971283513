import { memo, useEffect, useState,useCallback, useMemo, useRef } from 'react';
import ItemsTable from '../ItemsTable';
import ItemDetails from '../ItemDetails';
// import { ItemsData } from '../Items/Data';
import Alert from 'components/elements/alert/Alert';

import { ItemsWrapper, Progress } from './style';
import { useRequest } from 'hooks/useRequest';
import { debounce } from 'lodash';
import { useKitchenContext } from 'context/kitchenContext';
import NoItemScreen from '../NoItemScreen';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as NotFound } from 'assets/kitchen/NotFound.svg'
import { GilroyFontWrapper } from 'shared/theme/style';

function Items({categoryList, setCategoryList}) {
  const [isAlertMessage, setIsAlertMessage] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [getItemListApi, {isLoading :getItemListApiLoading}] = useRequest();
  const [searchInput,setSearchInput]=useState('');
  const [activePageNumber,setActivePageNumber]=useState(1)
  const { 
    isSuper, 
    setActiveTab, 
    setIsCreateCategory,            
    items,
    setItems,
    totalPages,
    setTotalPages,
    totalMealCount,
    setTotalMealCount,
    hasItem,
    imitateProviderCredentials
  } = useKitchenContext();
  const itemsPerPage = 8;
  const imitateProviderStorage=sessionStorage.getItem("imitateProvider");
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const providerId = imitateProviderCredentials?.providerId;
  const parentRef = useRef();

  const imitateProviderId = useMemo(() => {
    const parsedId = JSON.parse(imitateProviderStorage)?.id;
    return parsedId
}, [imitateProviderStorage]);

  useEffect(() => {
    setTimeout(() => {
      setIsAlertMessage(false);
    }, 5000);
  }, [isAlertMessage]);

  const updateSelected = ({ id, selected }) => {
    setItems(
      items.map((item) => {
        if (item.id === id) return { ...item, selected };
        return item;
      }),
    );
  };

  const updateSelectedAll = (selected) => {
    setItems(items.map((item) => ({ ...item, selected })));
  };

  const getItemList = async (activePageNumber,searchInput,sortBy,imitateProviderId) => {
    let path= `meal/list?pageSize=${itemsPerPage}${activePageNumber?`&pageNumber=${activePageNumber}`:''}${sortBy?`&category=${sortBy}`:''}${searchInput?`&searchText=${searchInput}`:''}`
    if(providerId){
      path += `&providerId=${providerId}`
    }
    try {
      const result = await getItemListApi({ path, method: "get" });
      const {data}=result;
      setItems(data?.data);
      setTotalPages(data?.totalPages);
      setTotalMealCount(data?.totalRecords);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setTotalPages(0);
      setTotalMealCount(0);
      setItems([])
      console.log(error, " no meal found ");


    }
  };  

  const debouncedFetchMealList = useCallback(debounce(getItemList, 300), []);


  useEffect(() => {
    if (isSuper) {
      debouncedFetchMealList(activePageNumber,searchInput,sortBy,imitateProviderId);
    } else {
      debouncedFetchMealList(activePageNumber,searchInput,sortBy);
    }
  }, [activePageNumber,searchInput,imitateProviderId,sortBy]);

  useEffect(() => {
    items.length && setItems(items.map((item) => ({ ...item, selected: false })));
  }, [items.length]);
  
  const handleAddCategory = () => {
    setIsCreateCategory(true);
    setActiveTab('overview');
  };

  if (items?.length < 1 && !isSearch && !hasItem) return <NoItemScreen handleCreateCategory={handleAddCategory}/>

  const NoMealFoundComponent = () => {
    return (
      <GilroyFontWrapper>
      <div className='order-not-found-wrapper'>
        <NotFound />
        <div className='order-not-found-text-wrapper'>
        <text>No result found</text>
        <p>Try to rephrasing or shortening your search</p>
        </div>
      </div>
      </GilroyFontWrapper>
    )
  }
  return (
    <ItemsWrapper>
      <ItemsTable
        items={items}
        setItems={setItems}
        isCheckAll={items?.every((item) => item?.selected)}
        setIsCheckAll={setIsCheckAll}
        updateSelectedAll={updateSelectedAll}
        totalPages={totalPages}
        totalMealCount={totalMealCount}
        setSearchInput={setSearchInput}
        activePageNumber={activePageNumber}
        setActivePageNumber={setActivePageNumber}
        itemsPerPage={itemsPerPage}
        setSortBy={setSortBy}
        getItemList={getItemList}
        setIsSearch={setIsSearch}
        searchInput={searchInput}
      >
        {(getItemListApiLoading && items?.length < 1) ?
          <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)' }} /></div></Progress>
          :
        <div className="item-details-wrapper" ref={parentRef}>
          {items?.length > 0 ? items.map((item) => (
            <ItemDetails
              key={item.id}
              items={items}
              setItems={setItems}
              setIsAlertMessage={setIsAlertMessage}
              updateSelected={updateSelected}
              getItemList={getItemList}
              setIsSearch={setIsSearch}
              activePageNumber={activePageNumber}
              setSearchInput={setSearchInput}
              parentRef={parentRef}
              {...item}
            />
          )) :<NoMealFoundComponent />}
        </div>
}
      </ItemsTable>
      {isAlertMessage && (
        <div className="alert-successDelete-wrapper">
          <Alert title="You have successfully delete this item" />
        </div>
      )}
    </ItemsWrapper>
  )    
}

export default memo(Items);
