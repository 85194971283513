import styled from 'styled-components';

const StyledCard = styled.div`
  width: 183px;
  height: ${(props) => props.height};
  margin-right: 28px;
  border: ${(props) => (props.selected ? '1.5px' : '1px')} solid
    ${(props) => (props.selected ? '#52C41A' : '#ededed')};
  border-radius: 8px;
  outline: none;
  box-shadow: 0px 3px 6px #0000000d;
  background-color: ${(props) => (props.selected ? '#EAF7E4' : 'white')};
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 28px;

  .card-content {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    height: 90%;
    padding-top: 15px;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 110px;
    margin-right: 0;
    margin-bottom: 0;
    padding: 15px 0;
    grid-template-rows: max-content;
    align-items: start;
    align-content: ${(props) => (props.maleOrFemale ? 'space-around' : '')};

    img {
      width: 32px;
      height: 32px;
    }

    .card-content {
      padding-top: 0;
    }
  }
`;

const Title = styled.p`
  color: black;
  font-size: 18px;
  margin-bottom: 5px;
  color: #51635d;

  @media (max-width: 991px) {
    margin: 12px 0 3px;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 14px;
    text-align: center;
  }
`;

const Desc = styled.p`
  color: #939b99;
  font-size: 14px;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 100%;
    color: #949d9b;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-align: center;
  }
`;

export default function Card(props) {
  const { selected, onSelect, name, desc, icon, height = '175px', iconWidth = '52px' } = props;

  return (
    <StyledCard onClick={onSelect} selected={selected} height={height} {...props}>
      <div>
        <img src={icon} width={iconWidth} alt="icon" />
      </div>
      <div className="card-content">
        <Title>{name}</Title>
        {desc ? <Desc>{desc}</Desc> : null}
      </div>
    </StyledCard>
  );
}
