import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const OrderDetailsPageWrapper = styled.div`
  padding: 32px 23px 50px;
  font-family: ${theme.typography.fontGilroy};

  .title-wrapper {
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      cursor: pointer;
    }
    .title {
      color: ${theme.colors.blackDefault};
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  .order-info-controls {
    max-width: 986px;
    padding: 10px 0;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    .order-controls-wrapper {
      height: 40px;
      display: flex;
      align-items: center;
      gap: 11px;
      button.dark {
        height: 40px;
        border-radius: 6px;
        border: 0 !important;
        font-family: ${theme.typography.fontGilroy};
        cursor: pointer;
        &::after {
          display: none;
        }
      }
    }
    .remove-order-wrapper,
    .print-button-wrapper {
      height: 40px;
      padding: 10px;
      cursor: pointer;
      &:hover {
        border-radius: 6px;
        background: var(
          --Primary-Lightest---2,
          linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%),
          #878a98
        );
      }
    }
    .divider {
      width: 1.5px;
      height: 25px;
      background-color: ${theme.colors.grayLight};
    }
    img {
      width: 20px;
      height: 20px;
    }
    .order-control {
      display: flex;
      gap: 11px;
      .arrow-wrapper {
        height: 40px;
        padding: 10px;
        cursor: pointer;
        &:hover {
          border-radius: 6px;
          background: var(
            --Primary-Lightest---2,
            linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%),
            #878a98
          );
        }
        &:active {
          background: #E9EAEC;
        }
        &.inActive {
          svg path {
            fill: #9499a0;
          }
          &:hover {
            background: transparent;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .order-details-container {
    display: flex;
    gap: 14px;
    .order-details-left {
      width: 630px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      .order-items-title,
      .order-items-details .order-line-container {
        margin-right: 26px;
      }
    }
  }

  .section-title {
    color: ${theme.colors.blackDefault};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .order-details-right {
    width: 340px;
    height: fit-content;
    padding: 16px 16px 24px;
    background-color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.grayLight};
    border-radius: 6px;
  }

  @media (min-width: 1440px) {
    .order-info-controls {
      max-width: 100%;
      justify-content: flex-start;
      gap: 16px;
      .order-controls-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }

    .order-details-container,
    .order-info-controls {
      .order-details-left,
      .order-number-status {
        width: 60%;
      }
      .order-details-right,
      .order-controls-wrapper {
        width: 30%;
      }
    }
  }
`;
