import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import Alert from 'components/elements/alert/Alert';
import { GilroyFontWrapper } from 'shared/theme/style';
import Tab from 'components/elements/tab/Tab';
import Subscriptions from './subscriptions';
import AllOrders from './allOrders';
import FooterDesktop from 'components/shop/FooterDesktop';
import { useShopContext } from 'context/shopContext';
import { theme } from 'shared/theme/theme';

const OrderContextWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 50px);
    overflow: auto;
    .space_between{
      display: flex;
      justify-Content: space-between;
      gap: 8px;
    }    
    .heading{
        color: ${theme?.colors?.primaryDarker};
        font-family: ${theme?.typography?.fontGilroy};
        font-size: ${theme?.typography?.fontXl};
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align-last: left;
        width: 960px;    
        margin: 48px auto 20px;
    }
    .category{
        font-size: ${theme?.typography?.fontMedium};
        font-weight: 600;
        margin: 24px auto;
    }
    .order-cards-container{
      @supports (-webkit-overflow-scrolling: touch) {
        padding-bottom:${({ isWebView }) => !isWebView ? "70px" : ""};
      }
    }
    .items-popup{
        position: fixed;
        bottom: 84px;
        width:97%;
        background: ${theme?.colors?.primaryDefault};
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 7px;
        left: 1.50%;
        z-index:100;
        p{
            padding-left:7px;
            color: ${theme?.colors?.secondary};
            font-family: ${theme?.typography?.fontGilroy};
            font-size: ${theme?.typography?.fontBase};
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            justify-content: center;
        }

        .alarm, .check{
           display:flex;
           align-items:center;
           font-family: ${theme?.typography?.fontGilroy};
           font-style: normal;
           font-weight: 400;
           font-size: ${theme?.typography?.fontRegular};
           line-height: 16px;
           color: ${theme?.colors?.secondary};
           img{
            height: 24px;
            width: 24px;
           }
        }
    }
    .middle-page{
        ::-webkit-scrollbar {
            width: 0;
            height: 0;
          }   
        ::-webkit-scrollbar-thumb{
            background-color: transparent; !important
          }
    .page-header{
        padding: 12px 0px 12px 16px;
        display: flex;
        background-color: ${theme?.colors?.white};
        width: 100%;
            h2{
                color: ${theme?.colors?.deepForestGreen};
                font-family: ${theme?.typography?.fontGilroy};
                height: 24px;
                font-size: ${theme?.typography?.fontMedium};
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
         @media(min-width:992px){
            width:30% !important;
            top:72px;
            right:0;
         }
    }
    .alert {
        position: absolute;
        top: 16px;
        left: auto;
        width: 100%;
        .alert_bar {
          margin: auto;
        }
      }
    @media(max-width:991px){
        height: calc(100vh - 70px);
        display: flex;
        flex-direction: column;
        align-items: center;
        ::-webkit-scrollbar-thumb{
          background-color: transparent; !important
        }
        .order-card-container-div:last-child .add-all {
          border-bottom: none;
        }   
        .middle-page{
            width:100%;
        }
        .items-popup p:nth-child(3) {
            right: 21px;
            position: fixed;
          }
          .items-popup{
            .check{
              p{
                font-family: ${theme?.typography?.fontGilroy};
                font-size: ${theme?.typography?.fontSmall};
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: ${theme?.colors?.white};
              }
            }
          }
        .middle-page{ 
          .page-header {
            padding: 16px 0px 16px 20px;
            h2{
                color: ${theme?.colors?.deepForestGreen};
                font-family: ${theme?.typography?.fontGilroy};
                font-size: ${theme?.typography?.fontMedium};
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
            img {
                width: 20px;
                height: 20px;
            }
        }
        .tab-container{
          background-color: ${theme?.colors?.white};
          height: 42px;
          padding: 6px 0 6px 15px;
          align-items: center   
        }
      }
      .category{
        color: ${theme?.colors?.deepForestGreen};
        background-color: ${theme?.colors?.white};
        font-size: ${theme?.typography?.fontMedium};
        width: 100%;
        font-weight: 600;
        padding: 16px 0px 16px 20px;
        line-height: 24px;
        margin: 0 !important;
      }       
    }
    .nav_item {
      padding: 5px 12px;
      background-color: transparent;
      &:nth-child {
        padding-left: 12px;
        padding-right: 12px;
      }
      &.active {
        position: relative;
        background-color: ${theme?.colors?.aliceBlue};
        transition: background-color 0.3s ease;
        border-radius: 15px;
        .text {
          color: ${theme?.colors?.primaryDarker};
        }
      }
      .text {
        color: ${theme?.colors?.primaryDarker};
        font-family: ${theme?.typography?.fontGilroy};
        font-size: ${theme?.typography?.fontRegular};
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
       }
    }

    @media(min-width: 992px){
        text-align: -webkit-center;
        height: 100%;
        overflow-y: hidden;
        background: ${theme?.colors?.white};
        .order-cards-container{
          padding-bottom: 76px;
        }
        .heading{
          height: 32px;
        }
        .middle-page{
            width: 100% !Important;
            .tab-container{
              margin: 24px auto;
              width: 960px;
              height: 36px;
              z-index: 0;
              position: relative;
                .nav_item{
                  padding: 8px 18px;
                  color: ${theme?.colors?.grayDarker};
                  text-align: center;
                  font-family: ${theme?.typography?.fontGilroy};
                  font-size: ${theme?.typography?.fontBase};
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  .text{
                    line-height: normal;
                    font-size: ${theme?.typography?.fontBase};
                    color: ${theme?.colors?.grayDarker};
                  }
                }
                .nav_item.active{
                  border-radius: 30px;
                  .text{
                    color: ${theme?.colors?.primaryDarker};
                  }
                }
            }    
        }
    }
`


export default function Order() {
    const {isMobile, isDesktop, isWebView} = useAppContext();
    const { orderList } = useShopContext();
    const [subscriptionlist, setSubscriptionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertData, setAlertData] = useState({
        type: '',
        title: '',
      });
      const orderTabs = [
        {
          id: 0,
          title: 'All orders',
          onClick: () => { handleTabSwitch('All orders')},
          render: () => <AllOrders />
        },
        {
          id: 1,
          title: 'Subscriptions',
          onClick: () => { handleTabSwitch('Subscriptions')},
          render: ({subscriptionlist, setSubscriptionList}) => <Subscriptions subscriptionlist={subscriptionlist} setSubscriptionList={setSubscriptionList} isLoading={isLoading} setIsLoading={setIsLoading}/>
        },
      ];
      const [selectedTabId, setSelectedTabId] = useState(orderTabs[0].id);
      const orderCardsHeight = selectedTabId === 0 ? !orderList?.orders.length ? "calc(100vh - 350px)" : "100%" : !subscriptionlist?.length ? "calc(100vh - 350px)" : "100%";
  
      const handleTabSwitch = useCallback((tab) => {
        if (tab === "All orders") {
          setSelectedTabId(0)
        } else if (tab === 'Subscriptions') {
          setSelectedTabId(1)
        }
      }, [orderTabs]);
          
    return (
      <GilroyFontWrapper>
        <OrderContextWrapper theme={theme} isWebView={isWebView}>
          {alertData.type && (
            <div className="alert">
              <Alert
                type={alertData.type}
                title={alertData.title}
                width="80%"
                onClose={() => setAlertData({ type: '', title: '' })}
              />
            </div>
          )}
          {isDesktop && <h2 className="heading">Orders</h2>}
          <div className="middle-page">
            {isMobile && (
              <div className="page-header">
                <h2>Orders</h2>
              </div>
            )}
            <div className="tab-container">
              <Tab
                type="horizontal"
                navItems={orderTabs}
                selectedTabId={selectedTabId}
                setSelectedTabId={setSelectedTabId}
                selectedTabBgColor={'rgba(0,0,0,0)'}
              />
            </div>
            <div className="order-cards-container" style={isDesktop ? { height: orderCardsHeight } : {}}>
              {orderTabs[selectedTabId].render({subscriptionlist, setSubscriptionList, isLoading, setIsLoading})}
            </div>
          </div>
          {isDesktop && (selectedTabId === 0 ? orderList?.orders?.length ? <FooterDesktop /> : '' : subscriptionlist?.length ? <FooterDesktop /> : "")}
        </OrderContextWrapper>
      </GilroyFontWrapper>
    );
}
