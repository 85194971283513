import { memo, useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import QuantityButton from '../QuantityButton';
import { NavHashLink } from 'react-router-hash-link';
import { MealCardWrapper } from './style';
import Tooltip from 'rc-tooltip';
import useProgressiveImage from 'hooks/useProgressiveImage';
import { Skeleton } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useAppContext } from 'context/appContext';

function MealCard({
  className,
  mealImg = '/images/sexy_just_eat_now.jpg',
  calories,
  title,
  tags,
  price,
  count,
  onClickRemove,
  onClickAdd,
  moveTo,
  isDisabled=false,
  order,
  orderLimit,
  ...props
}) {
  const [isQuantity, setIsQuantity] = useState(false);
  const imgRef = useRef();
  const [src, { isImageLoaded }] = useProgressiveImage(mealImg, imgRef);
  const {pathname} = useLocation();
  const {isMobile} = useAppContext();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -250;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const movingOnCart =
    isQuantity && count > 0 ? (
      <motion.div
        className="box"
        initial={{ opacity: 0, scaleX: 0.3 }}
        animate={{ opacity: 1, scaleX: 1 }}
        transition={{
          duration: 0.5,
          delay: 0,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <QuantityButton
          count={count}
          variant="light"
          size="small"
          onClickRemove={() => {
            onClickRemove();
            if (count === 1) setIsQuantity(false);
          }}
          remove={count === 1}
          onClickAdd={onClickAdd}
          orderLimit={orderLimit}
        />
      </motion.div>
    ) : (
      order ? (
      <div className='order-btn'>Order</div>
      ) : (
      <img
        draggable={false}
        src="/icons/add-black-rounded.svg"
        alt="Add Icon"
        className="add-icon"
        onClick={() => {
          setIsQuantity(true);
          onClickAdd();
        }}
      />
    ));

  useEffect(() => {
    count > 0 && setIsQuantity(true);
  }, [count]);

  return (
    <MealCardWrapper className={className} {...props}>
      <div className="meal-img-wrapper">
        {!isImageLoaded && <Skeleton variant='rectangular' style={{ position: 'absolute', inset: 0, zIndex: 9 }} width={'100%'} height={'100%'}/>}
        <img ref={imgRef} src={src} decoding="async" alt="Meal Img" className={`meal-img ${isImageLoaded ? 'isFadeInAnimate': 'imgBlurred'}`} />
        <div className="calories-wrapper">
          <img src="/icons/fire.svg" alt="Fire Icon" className="fire-icon" />
          <span>{calories} cal</span>
        </div>
      </div>
      <div className="meal-details">
        <div className="title">{title}</div>
        <div className="tags-wrapper">
          {tags &&
            tags.slice(0, 2).map((tag, index) => (
              <div key={index} className="tag">
                {tag[0].toUpperCase() + tag.slice(1).toLowerCase()}
              </div>
            ))}
          {tags?.length > 3 && 
          <Tooltip
            overlayInnerStyle={{
              backgroundColor: '#000A2C',
              borderRadius: '4px',
              color: '#FFF',
              fontSize: '12px',
              textAlign: 'left',
              maxWidth: '200px',
              width: '100%',
              fontWeight: "500",
              lineHeight: 'normal',
              fontFamily: 'Gilroy',
              padding: '4px 8px',
              minHeight: 'unset'
            }}
            overlay={tags.slice(2).map((tag, index) => {
              return tag[0].toUpperCase() + tag.slice(1).toLowerCase();
            }).join(", ")}
            placement={"top"}
            overlayClassName="tags__tooltip__style"
          >
            <div className="tag">+{tags.length - 2}</div>
          </Tooltip>}
        </div>
        <div className="cart-wrapper">
          <div className="price">
          ${price}
          <Fade in={orderLimit && orderLimit === count}><div className='limit-order'>Limit {orderLimit} {(pathname !== "/shop/kitchen-home" || !isMobile) && 'per order'}</div></Fade>
          </div>
          <div className="add-cart" onClick={(e) => e.stopPropagation()}>
            {moveTo ? (
              <NavHashLink smooth to={`${moveTo}`} scroll={(el) => scrollWithOffset(el)}>
                {movingOnCart}
              </NavHashLink>
            ) : (
              movingOnCart
            )}
          </div>
        </div>
      </div>
    </MealCardWrapper>
  );
}

export default memo(MealCard);
