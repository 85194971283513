import styled from 'styled-components';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from "react-toastify";
import Select, { components } from 'react-select';
import DropdownArrow from '../../../components/Nutritionist/Coaching/DropdownArrow';


const TimePickerWrapper = styled.div`
  .MuiFormControl-root input {
    width: 130px;
    margin-bottom: 0;
    color: #51635d;
    font-size: 14px;
    line-height: 16px;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  @media (max-width: 991px) {
    .MuiFormControl-root input {
      width: 105px;
      padding: 0 0 0 5px;
      display: flex;
      align-items: center;
    }
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer {
      padding: 0
    }
    .selectOfferTime {
      .css-yk16xz-control {
        height: 44px;
      }
      img {
        width: 15px;
        height: 15px;
      }
      .css-1uccc91-singleValue {
        margin-top: 2px;
        font-size: 14px;
      }
    }  
  }
`;
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow/>
    </components.DropdownIndicator>
  );
};
const slotGenerator = (selectedDate, slotInterval, openTime, closeTime) => {
  let x = {
    slotInterval,
    openTime,
    closeTime,
  };

  let startTime = moment(selectedDate).add(moment.duration(x.openTime));
  let endTime = moment(selectedDate).add(moment.duration(x.closeTime));

  let allTimes = [];
  while (startTime < endTime) {
    allTimes.push({ label: startTime.format('LT'), value: startTime.format('HH:mm') });
    startTime.add(x.slotInterval, 'minutes');
  }
  return allTimes;
};
const startTimeGen =  moment().startOf('day').toString();
const options = slotGenerator(startTimeGen, 15, '00:00', '24:00');
export default function TimePickerFunc({ defaultValue, onChange, to, from, isStart }) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);


  return (
    <TimePickerWrapper>
      <div className="selectOfferTime">
        <Select
        components={{ 
          DropdownIndicator,
          IndicatorSeparator: () => null
         }}
          value={options.find((option) => option.value === value)}
          options={options}
          onChange={(e) => {
            const value = e.value;
            if (isStart) {
              const isStartGreatorThanEnd = moment(value, ['HH:mm']).isAfter(moment(to, 'HH:mm'))
              const isSametime = moment(value, ['HH:mm']).diff(moment(to, 'HH:mm'), 'minutes') === 0;

              if ((isStartGreatorThanEnd) || isSametime) {
                toast.error('Start time cannot be greater than end time');
              } else {
                onChange(value);
                setValue(value);
              }
            } else {
              const isEndLessThanStart = (moment(value, ['HH:mm']).isBefore(moment(from, 'HH:mm')))
              const isSametime = moment(value, ['HH:mm']).diff(moment(from, 'HH:mm'), 'minutes') === 0;
              
              if (isEndLessThanStart || isSametime) {
                toast.error('End time cannot be less than start time');
              } else {
                onChange(value);
                setValue(value);
              }
            }
          }}
        />
      </div>
    </TimePickerWrapper>
  );
}
