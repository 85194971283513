import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const DeliveryInfoWrapper = styled.section`
  padding: 18px 0 30px 15px;
  background-color: ${theme.colors.secondary};
  border-radius: 6px;
  border: 1px solid ${theme.colors.grayLight};

  .section-title {
    margin: 0 0 20px 1px;
  }

  .delivery-info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .delivery-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .delivery-item {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;
    .label {
      color: ${theme.colors.grayDarker};
      font-family: ${theme.typography.fontGilroy};
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      text-transform: uppercase;
    }
    .info {
      overflow: hidden;
      color: ${theme.colors.primaryDefault};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: capitalize;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        text-transform: lowercase;
      }
      &.address {
        width: fit-content;
        white-space: inherit;
        text-transform: none;
      }
      &.note {
        max-width: 436px;
        white-space: inherit;
        text-transform: none;
      }
    }
  }
`;
