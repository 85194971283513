import styled from 'styled-components';
import icons from '../../../../components/TagIcons';

const Container = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  .icon-wrapper {
    width:44px;
    height:44px;
    margin-bottom: 8px;
    border: ${(props) => (props.recomend ? '1px solid #CBEDBA' : props.avoid ? '1px solid rgba(254, 185, 90, 0.3)' : '')};
    border-radius:${(props) => (props.isSelected ? '50%' : '')};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.isSelected ? '#39ab39' : '#939b99')};
    background: ${(props) => (props.recomend ? '#F0FAEB': props.avoid ? '#feb95a1a' : '' )};
    img{
      width:24px;
      height:24px;
      filter: ${(props) => (props.recomend ? 'invert(57%) sepia(46%) saturate(1920%) hue-rotate(62deg) brightness(107%) contrast(80%)' : props.avoid ? 'invert(71%) sepia(73%) saturate(429%) hue-rotate(335deg) brightness(102%) contrast(99%)' : '')};
    }
  }
  .tag-name {
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props?.isSelected ? '#51635d' : '#51635d'};
    font-weight: ${(props) => (props.isSelected ? '700' : '400')};
    text-align: center;
  }
`;


export default function Tags({ tag, selectedTags, onTagClick, isSelected, avoid, recomend, type = ''}) {
    const Icon = icons[tag];
    let formattedTag = tag;
    if (tag.includes('-')) {
        formattedTag = tag.split('-').join(' - ');
    }
    return (
        <Container key={tag} onClick={() => onTagClick(tag, isSelected,avoid,recomend)} isSelected={isSelected}  selectedTags={selectedTags} avoid={avoid} recomend={recomend}  >
            <span className={`icon-wrapper ${type} ${recomend || avoid ? 'selected':''}`}>
            {Icon && <Icon  />}
            </span>
            <span className="tag-name">{tag.includes('-') ? formattedTag : tag}</span>
        </Container>
    );
}