import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import NutrittSteps from 'components/NutrittSteps';
import { useRequest } from "hooks/useRequest";
import { prop, sum } from "ramda";
import ImageViewer from 'components/ImageViewer';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ArrowLeft } from 'assets/shop/chevron_left.svg';
import { ReactComponent as Delivery } from 'assets/shop/delivery.svg';
import { OrderItem } from 'components/elements/orderItems/OrderItem';
import { OrderSummary } from 'components/elements/orderSummary/OrderSummary';
import { theme } from 'shared/theme/theme';
import { AnimatePresence } from "framer-motion";
import { calculateOrderTotals } from 'utils';
import { GilroyFontWrapper } from 'shared/theme/style';
import { ReactComponent as RightArrow } from 'assets/shop/chevron-right.svg';
import { Modal } from 'react-responsive-modal';
import { ReactComponent as Close } from 'assets/shop/close-cross.svg';
import { Button } from 'components/elements/button/Button';
import  PauseSubscription  from './pauseSubscription'
import { ReactComponent as Recurring } from 'assets/shop/recurring-time.svg';
import FooterDesktop from 'components/shop/FooterDesktop';
import moment from 'moment';

const OrderContextWrapper = styled.div`
    width: 100%;
    z-index: 112;
    .middle-page-inner{
        ::-webkit-scrollbar-thumb{
            background-color: transparent; !important
          }  
        .page-header {
            display: flex;
            background: white;
            padding: 12px 16px;
            z-index: 4;
            position: relative;
           .icon{
                position: absolute;
                cursor: pointer;
                height: 40px;
                width: 40px; 
                top: 6px;
                left: 10px;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;                 
                &:active,
                &:active::after {
                  background: #f0f2f4;
                }  
                svg{
                    width: 20px;
                    height: 20px;
                    transform: translateY(4px);
                }  
            }
            a {
                position: absolute;
                top: 2px;
                left: 16px;
              }
               h2{
                font-family: ${theme?.typography?.fontGilroy};
                font-size: ${theme?.typography?.fontMedium};
                font-style: normal;
                color: ${theme?.colors?.primaryDarker};
                text-align: center;
                font-weight: 600;
                line-height: 24px;
                margin: 0 auto;
              }    
          }      
        }
        h4{
            color: ${theme?.colors?.primaryDarker};
            font-family: ${theme?.typography?.fontGilroy};
            font-size: ${theme?.typography?.fontBase};
            font-style: normal;
            font-weight: 600;
            line-height: normal;
         }
        p{
            font-family: ${theme?.typography?.fontGilroy};
            font-size: ${theme?.typography?.fontSmall};
            font-style: normal;
            font-weight: 700;
            line-height: normal;
         }
        span{
            font-style: normal;
            font-size: ${theme?.typography?.fontSmall};
            line-height: 15px;
            color: #757E89;
        }
        .card-shadow{
            background: ${theme?.colors?.secondary};
        }
        .next-order-date{
            padding: 0px 16px;
            text-align: left;
        }    
        .order-status-step{
            padding: 16px 24px 20px; 
            h4{
                justify-content: center;
                margin-bottom: 15px;
                text-align: center;
                color: ${theme?.colors?.primaryDarker};
                text-align: center;
                font-family: ${theme?.typography?.fontGilroy};
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .refused-desc{
                color: #757E89;
                text-align: center;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
            .step-header{
                display: none;
            }
            .Mui-active{
                font-weight:700;
            }
            .MuiStepLabel-label{
                line-height:14px;
            }
            .Mui-disabled{
                color:${theme?.colors?.mediumGrey};
            }
            &.isCanceled{
                .MuiStepper-root .MuiStep-root:first-child:before{
                    border-top:4px solid #EE786B;
                }
                .MuiStepper-root .MuiStep-root:first-child{
                    .MuiSvgIcon-root{
                        color:#EE786B;
                    }
                    .MuiStepLabel-iconContainer{
                        width:22px;
                        height:22px;
                        border-radius: 50%;
                        background: #EE786B;
                        z-index: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .cross-img{
                            width: 12px;
                            height: 12px;
                            filter:brightness(400%);
                        }
                    }
                }
            }
        }
        .order-delivery-address {
            padding: 24px 16px;
            h4 {
              margin-bottom: 10px;
            }
            p {
              font-family: ${theme?.typography?.fontGilroy};
              font-size: ${theme?.typography?.fontSmall};
              font-style: normal;
              font-weight: 500;
              color: ${theme?.colors?.grayDarker};
              line-height: 14.7px;
              text-transform: capitalize;
              b{
                line-height: 14.7px;
                font-weight: 500;
              }
              &:nth-child(2) {
                b{
                    font-weight: 600 !important;
                    line-height: 14.56px;    
                }
              }
            }
          } 
        .subscription-card{
            display: flex;
            padding: 20px 32px 16px 16px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 10px;
            background: #FFF;
            text-align: left;
            p{
                color: #757E89;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                span{
                    font-weight: 600;
                }
            }
            .discount{
                color: #757E89;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
            .status-paused{
                border-radius: 80px;
                background: #EBEBEB;
                color: ${theme?.colors?.deepForestGreen};
                text-align: center;
                font-family: ${theme?.typography?.fontGilroy};
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                display: inline-block;
                padding: 3px 8px;
                height: 20px;
                border-radius: 80px;
              }
              .paused-container{
                display: flex;
                gap: 11px;
              }            
        }         
        .order-item-card{
            padding: 16px;
            .card-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4{
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: #0B1D17;
                }
                a{
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    padding: 0px;
                }
            }    
            .order-items-media-list{
                padding-top: 6px;
                margin: 0px;
            }
        }
        .order-again{
            display:flex;
            justify-content:center;
            margin-bottom:12px;
            padding-bottom: 14px;
            background: white;
            button{
                width: 166px;
                height: 32px;
                padding: 18px 16px 16px 16px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                background: ${theme?.colors?.primaryDefault};
                border-radius: 30px;
                display: flex;
                justify-content:center;
                align-items:center;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;                
                color: ${theme?.colors?.secondary};
                cursor: pointer;
            }
        }
        .delivery-company{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 19px;
            border-bottom: 1px solid #F0F2F4;
            h4{
                margin: 0px;
                padding: 9px 0px;
                font-weight: 700;
                color: #00092D;
            }
        }
        .pickup-info{
            display: flex;
            justify-content: flex-start;
            align-items: center; 
            padding: 8px 16px;
            margin-bottom: 8px;
            img{
                margin-right: 5px;
            }
            p{
                color:#757E89;
                font-weight: 500;
                margin: 0 6px;
                span{
                    font-family: ${theme?.typography?.fontGilroy};
                    font-size: 12px;
                    font-weight: 600;           
                    font-style: normal;
                    line-height: normal;
                    color: #00092D;
                    white-space: nowrap;
                    display: inline-flex;
                    padding-left: 5px;
                    .pipe{
                        color: #E0E4E8;
                    }
                }
            }
        }
        .marked-completed{
            padding: 16px 20px 30px 20px;
            text-align: center;
            h4{
                color: #00092D;
                font-family: Gilroy;
                font-size: 16px;
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                margin-bottom: 20px;
                justify-content: center;
            }
            .btn{
                border-radius: 30px;
                background: #000A2C;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                min-width: 166px;
                display: inline-flex;
                height: 32px;
                padding: 18px 16px 16px 16px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                color:#fff;                
            }
        }
        .mt-6{
            margin-top: 48px;
        }
        .mb-3{
            margin-bottom: 8px;
        }
        .pt-3{
            padding-top: 14px;
        }
        .delivery-icon {
            img {
                height: 38px;
                width: 80px;
                box-sizing: border-box;
                display: block;
                object-fit: cover;
            }
        }
    }
    .order-summary-container{
        background: #fff;
        height: 100%;
        @supports (-webkit-overflow-scrolling: touch) {
            padding-bottom:${({ isWebView }) => !isWebView ? "100px" : ""};
        }
        text{
            color: #F03738;
            text-align: center;
            font-family: Gilroy;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: block;
            margin-bottom: 16px;
        }
    }
   @media(max-width:991px){
       display: flex;
       position: fixed;
       inset: 0;
       width: 100vw;
       height: 100vh;
       z-index: 112;
       background: rgb(245, 247, 248);
       flex-direction: column;
       .middle-page-inner{
           width:100%;
           height: 100%;
           overflow:auto
       }
       .detailed-container{
        height: calc(100vh - 563px);
       }        
       .order-status-step{
        &.isCanceled{
            h4{
                text-align: left;
                margin-bottom: 10px;
            }
        }
        .refused-desc{
            text-align: left;
        }
    }
       .cancel-pause-div{
        padding-bottom: 16px;
        background: #fff;
        button{
            font-family: Gilroy;
        }
       }
   }
   @media(min-width:991px){
    background: rgb(245, 247, 248);
    z-index: 999;
    .middle-page-inner{
      position: fixed;
      background: #fff; 
      overflow: auto;
      height: 100vh;
      inset: 0;
      padding-top: 78px;
    }
    .page-header{
       position: static !Important;
       width: 960px !important;
       margin: 48px auto 0px;
       padding: 12px 16px 12px 0px !important;
       .icon{
        position: static !important;
        height: 100% !important;
        width: auto !important;
        &:active,
        &:active::after {
          background: none;
        }    
        }
       h2{
        margin: 0 12px !Important;
        font-weight: 700 !important;
        font-size: 24px !important;
       }
    }
    .order-status-step {
        margin-top: 0px !Important;
    }
    .subscription-card{
        margin: auto;
        padding: 0 24px 24px 24px;
        gap: 8px;
    }
   .detailed-container{
    border-radius: 12px;
    border: 1px solid #E0E4E8;
    width: 960px;
   }
    .header{
        display: flex;
        margin: auto;
        background: #F5F7F8;
        padding: 9px 14px 9px 24px;
        border-radius: 10px 10px 0 0;
        justify-content: space-between;
        cursor: pointer;
        h4{
            font-weight: 700;
            color: #0B1D17;
        }
        .title-pill-container{
            display: flex;
            gap: 14px;
            .recurring-pill{
                display: flex;
                position: relative;
                width: 112px;
                height: 23px;
                padding: 3px 8px 4px 8px;
                align-items: center;
                gap: 4px;
                border-radius: 80px;
                background: #C2FDC2;
                .text{
                color: #000A2C;
                text-align: center;
                font-family: Gilroy;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                position: absolute;
                left: 26px;    
                }
            }
        }    
    }
    .next-order-date{
        padding: 0px 0px 24px 24px;
    }    
    .order-summary-card{
        margin: auto;
        .marked-completed{
            display: flex;
            justify-content: space-between;
            padding: 26px 24px;
            h4{
                align-self: center;
                margin-bottom: 0px;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
            }
        }
        .pickup-info{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            border: none;
            gap: 7px;
            margin-bottom: 24px;
            padding: 24px 0px 0px 24px;
            text-align: left;
            p{
                font-size: 14px;
                font-weight: 600;
                span{
                    padding: 0 !Important;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .method-icon{
                display:flex;
                gap: 8px;
                svg{
                    height: 21px;
                    width: 20px !Important;
                }
                p{
                    color: #00092D;
                    font-family: Gilroy;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            .pipe{
                margin: 0 10px;
            }
            p{
                margin: 0px;
            }
        }
    }
    .order-delivery-address{
        margin: auto;
        text-align: left;
        padding: 0px 24px 24px 24px;
        border-bottom: 1px solid #E0E4E8;
        h4{
            margin-bottom: 10px;
        }
    }
    .order-again{
        margin: 0px;
        margin: auto;
        padding-top: 11px;
        padding-right: 25px;
        justify-content: right;
    }
    .order-item-card{
        padding: 24px;
    }
    .order-status-step{
        margin-bottom: 0px;
    }
    .food-avtar{
        flex: 0 !important;
        height: 90px !important;
        width: 90px !important;
        margin-right: 29px !important;
        img{
            height: 90px !important;
            width: 90px !important;
        }
    }
    .cancel-pause-div{
        display: flex;
        gap: 43px;
        align-items: center;
        justify-content: flex-end; 
        margin: 32px 24px 24px 24px;
        cursor: pointer;
        text{
            margin: 0;
            height: 20px;
        }
        button{
            padding: 14px 16px 12px 16px;
            display: flex;
            align-items: center;
        }
    }
    .MuiStepper-root .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line{
        border-color: #E0E4E8 !important;
    }
      .MuiStepper-root .MuiStepLabel-root.Mui-disabled .MuiSvgIcon-root {
        color: #E0E4E8 !important;
      }
   }
`

const SubscriptionCancelModel = styled.div`
  .modal {
    border-radius: 10px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 14px 0 24px 0px;
    .close-image{
      position: relative;
      left: 16px;
      cursor: pointer;
    }
      .container{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0px 8.976px 0px 8px;
        .delete-title{
          color: #0B1D17;
          text-align: center;
          font-family: Gilroy;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .delete-buttons{
          width: 220px;
          align-self: center; 
          .button{
            button{
                height: 42px;
                font-family: Gilroy;
            }
        }
        }
        .macronutrients{
          margin-top:24px;
        }
        .serving{
          border: none !important;
        }
        .button{
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
          .size{
            color: #00092D !important;
            position: relative;
            right: 14px;        
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;  
          }
        .swiper-slide.swiper-slide-active{
          color: var(--new-000-a-2-c, #000A2C) !Important;
          text-align: center;
          /* Gilroy/Body Small – 12pt Regular */
          font-family: Gilroy;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          
        }
      }
  }
  @media(min-width:991px){
    .modal{
        width: 433px;
        height: 214px;
        .container{
            padding: 20px 50px 0px 36px;
            gap: 40px;
            .delete-title{
                height: 48px;
                font-size: 18px;
            }
            .delete-buttons{
                width: 100%;
                .button{
                    flex-direction: row-reverse;
                    button{
                        width: 155px;
                        height: 42px;
                        font-family: Gilroy;
                    }
                }
            }
        }
    }
  }
`;

const steps = ['Confirming', 'Preparing', 'Completed'];

export default function OrderStatus({
    orderItems,
    setShowOrderStatus,
    numOfMeals,
    mealsDiff,
    showOrderAnim,
    setShowOrderAnim,
    clicked,
    subscription,
    setClicked,
    setSubscriptionList
}) {
    const history = useHistory();
    const { isMobile, profile, isWebView } = useAppContext();
    const [stepStatus, setStepStatus] = useState(1);
    const { settings } = useShopContext();
    const { days } = settings;
    const [isCanceled, setIsCanceled] = useState(false);
    const { providerOrderAction, providersCount, kitchen, orderData, storeName, storeImage } = orderItems || {};
    const { isDelivery, deliveryAddress, pickupAddress, deliveryData } = subscription ? orderItems : orderData 
    const { providerOrderSummary } = subscription ? orderItems : orderItems?.orderData || {};
    const [openModal, setOpenModal] = useState(false);
    const totalMeals = sum(orderItems?.meals?.map(prop('qty')) || [0]);
    const { name, lastName } = profile;
    const [updateSubscriptionStatus, { isLoading }] = useRequest('subscription/status', 'patch')
    const [isPaymentModalActive, setIsPaymentModalActive] = useState(false);
    const [isSubscriptionPaused, setIsSubscriptionPaused] = useState(false);
    const pausedSubscriptionClose = () => {
        setIsSubscriptionPaused(false);  
        setIsPaymentModalActive(false);
        setShowOrderStatus(false);
    }

    const subscriptionStatus = async (id, status, pausedSubscriptionData) => {
        try {
          const requestBody = {
            subscriptionId: id,
            status,
            intervaldays: pausedSubscriptionData?.value,
            skip: pausedSubscriptionData?.orderSkipped
          };
      
          const response = await updateSubscriptionStatus({ body: requestBody });
          if (response?.data?.success) {
            setShowOrderAnim(false);
            if (!pausedSubscriptionData) {
                setSubscriptionList(prevState => {
                    const updatedSelection = [...prevState];
                    const index = updatedSelection.findIndex(item => item.id === id);
                    if (index !== -1) {
                      updatedSelection.splice(index, 1);
                    }
                    return updatedSelection;
                  });
                  !isMobile && (
                    setShowOrderStatus(false),
                    setClicked(true)
                  )
                  setTimeout(() => {
                    setShowOrderStatus(false);
                    setClicked(true);
                  }, 190);
            } else {
                setIsSubscriptionPaused(true);  
                setSubscriptionList(prevState => {
                    const updatedSelection = [...prevState];
                    const index = updatedSelection.findIndex(item => item.id === id);
                    if (index !== -1) {
                        updatedSelection[index] = { ...updatedSelection[index], status: "paused", subscriptionActivationDate: moment(pausedSubscriptionData?.desc1, 'MMMM DD YYYY').format('YYYY-MM-DD[T]HH:mm:ss[Z]')};
                      }
                      return updatedSelection;
                });
                setTimeout(() => {
                    pausedSubscriptionClose()
                }, 5000);
            }            
          } else {
            console.log('Failed to update subscription status:', response?.error);
          }
        } catch (err) {
          console.error('Error updating subscription status:', err);
        }
      };
      
    const customStyles = {
        modalContainer: {
          position: 'relative',
          top:  `${window.innerWidth < 380 ? "65%" : "70%"}`,
          margin: '0 16px',
          overflowY: 'hidden',
          background: 'none !important',
        },
      };
    
    const {
        tax,
        userSubTotal,
        deliveryFee,
        totalDiscount,
        actualDiscount,
        repeatOrderDiscount,
        serviceFee,
        grandTotal,
        userTotalAmount
      } = calculateOrderTotals(providerOrderSummary, providersCount);
      
    useEffect(() => {
        let firstStep = 'Confirming';
        if (
            providerOrderAction?.action === 'refused'
        ) {
            firstStep = 'Canceled';
            setIsCanceled(true);
        }
        steps[0] = firstStep;
        if (
            providerOrderAction?.action === 'accepted'
        ) {
            setStepStatus(2);
        }
        if (
            providerOrderAction?.action === 'completed'
        ) {
            setStepStatus(3);
        }

    }, [providerOrderAction])

    const deliveryAddressView = (
        <>
            <p><b>{name} {lastName}</b></p>
            {subscription ? 
                <p><b>{deliveryData?.deliveryFirstName} {deliveryData?.deliveryLastName}</b></p> :
                <p><b>{deliveryAddress?.deliveryFirstName} {deliveryAddress?.deliveryLastName}</b></p>
            }
            {subscription ?
                deliveryData?.deliveryAddress ?
                    <p><b>{deliveryData?.deliveryAddress}</b></p> :
                    <p>{deliveryData?.deliveryCity}, {deliveryData?.deliveryState}, {deliveryData?.deliveryZip}</p>
                :
                deliveryAddress?.deliveryAddress ?
                    <p><b>{deliveryAddress?.deliveryAddress}</b></p> :
                    <p>{deliveryAddress?.deliveryCity}, {deliveryAddress?.deliveryState}, {deliveryAddress?.deliveryZip}</p>
            }
        </>
    );
    
    const pickUpAddressView = (
        <div style={{overflow: 'hidden', textOverflow: 'ellipsis' }}>
             <p><b>{name} {lastName}</b></p>
            <p><b>{pickupAddress?.street}, {pickupAddress?.city}, {pickupAddress?.state}, {pickupAddress?.zip}</b></p>
        </div>
    );  

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
          document.body.style.overflow = 'auto';
          document.body.style.overflowY = "overlay"
        };
      }, []);

      console.log(orderItems?.status,'orderItems?.status')
    return (
        <GilroyFontWrapper>
        <OrderContextWrapper theme={theme} className={isMobile && `slide ${showOrderAnim ? 'slideFastInRight' : 'slideFastOutRight'}`} isWebView={isWebView}>
            <div className='middle-page-inner'>
                <div className='page-header'>
                    <div className='icon'>
                    {isMobile ? <ChevronLeft className='icon' onClick={() => { setShowOrderAnim(false),setTimeout(()=>{setShowOrderStatus(false)}, 490) }} /> :  <ArrowLeft className='icon' onClick={() => { setShowOrderAnim(false),setShowOrderStatus(false)}}/>}
                    </div>
                    <h2 className='order-status-page'>{subscription ? "Subscription" : stepStatus === 3 ? "View order" : !isMobile ? "Orders" : orderItems?.providerOrderAction?.action === "refused" ? "Canceled order" : "Upcoming order"}</h2>
                </div>
                {!subscription && 
                <div className={`order-status-step card-shadow mb-3 ${isCanceled && 'isCanceled'}`}>
                    <h4>
                        {stepStatus === 1 && isCanceled
                            ? "Your order was canceled"
                            : stepStatus === 2
                                ? "The provider confirmed your order and is preparing your meals"
                                : stepStatus === 3
                                    ? "Your order has been completed"
                                    : "Your order is awaiting kitchen confirmation"}
                    </h4>
                    {orderItems?.providerOrderAction?.action === "refused" && 
                    <h3 className='refused-desc'>
                          {isMobile ? 
                            'Refunded to your original payment method' : 
                            <>
                            We’re sorry, item(s) from your order were out of stock.
                            <br />
                            Refunded to your original payment method.
                            </>
                        }
                    </h3>}
                    {orderItems?.providerOrderAction?.action !== "refused" &&
                    <NutrittSteps
                        activeStep={stepStatus - 1}
                        steps={steps}
                        activeClass={stepStatus}
                        stepNumber={1}
                        stepTitle="Let's specify your plan"
                        isFooterHidden={true}
                        isCanceled={isCanceled}
                    />  
                    }
                </div>
                }
                <div className='detailed-container' style={!isMobile ? subscription ? { margin: "24px auto" } : { margin: "24px auto 96px auto"} : null}>
                <AnimatePresence>
                    {!isMobile && 
                        <div className='header'>
                          <div className='title-pill-container'>
                          <h4>{subscription ? storeName : kitchen?.name}</h4>
                          {orderItems?.orderData?.isRecurringOrder && 
                            <div className='recurring-pill'>
                                <Recurring />
                                <span className='text'>Recurring order</span>
                            </div>
                         }
                          </div>
                        </div>
                    }
                </AnimatePresence>
                {orderItems?.providerOrderAction?.action !== "refused" &&
                <div className='order-summary-card card-shadow '>
                    {isMobile && 
                    <div className="delivery-company">
                        <h4>{subscription ? storeName : kitchen?.name}</h4>
                        <div className='delivery-icon'>
                            <ImageViewer
                                src={subscription ? storeImage : kitchen?.image}
                                alt={subscription ? storeName : kitchen?.name}
                                fallbackSrc={'/images/avatar.png'}
                            />
                        </div>
                    </div>
                    }
                    <div className='pickup-info'>
                        <div className='method-icon'>
                        {isDelivery ? <Delivery style={{ display: 'block', width: '14px' }}/> : <img src='/icons/pickup-icon.svg' alt="pickup icon" width="14px" height="14px" />}
                        <p>{!isMobile && (subscription ? orderItems?.status === "paused" ? "Resumes on: " : "Next order:" : isDelivery ? stepStatus === 3 ? "Delivered on" : 'Scheduled for' : 'Pick-up by')}</p>
                        </div>
                        <p>
                            {isMobile && (subscription ? orderItems?.status === "paused" ? "Resumes on: " : "Next order:" : isDelivery ? stepStatus === 3 ? "Delivered on:" : 'Scheduled for:' : 'Pick-up by:')}
                            <span>
                            {subscription
                                ? orderItems?.status === "paused"
                                ? moment(orderItems?.subscriptionActivationDate).utc().format("MMM DD, YYYY")
                                : moment(orderItems?.selectedDate).format("MMM DD, YYYY")
                                : orderItems?.orderData?.scheduledDate}
                            {orderItems?.status !== "paused" && <p className="pipe">|</p>}
                            {subscription
                                ? orderItems?.status === "paused" ? ""
                                : (orderItems?.providerOrderSummary?.time ||
                                    orderItems?.orderData?.scheduledTime
                                  )
                                    ?.replace(
                                    /(\d{1,2}:\d{2})\s*([APMapm]{2})-(\d{1,2}:\d{2})\s*([APMapm]{2})/,
                                    "$1 $2 - $3 $4"
                                    )
                                    .toLowerCase()
                                : orderItems?.orderData?.scheduledTime?.replace(
                                    /(\d{1,2}:\d{2})\s*([APMapm]{2})-(\d{1,2}:\d{2})\s*([APMapm]{2})/,
                                    "$1 $2 - $3 $4"
                                )?.toLowerCase()}
                            </span>
                        </p>
                    </div>
                    {(process.env.REACT_APP_ENV === 'qa' && subscription) &&
                    <div className='next-order-date'>
                        <h4>Next order placement date:</h4>
                        <span>{moment(orderItems?.nextOrderDate).format('MMM DD, YYYY')}</span>
                    </div>
                    }
                </div>
                }
                {orderItems?.providerOrderAction?.action !== "refused" && (subscription ? (orderItems?.providerOrderSummary?.recurringOrder && orderItems?.discount) : (orderData?.isRecurringOrder && orderData?.recurringDiscountPercent)) && 
                <div className='subscription-card card-shadow mb-3 '>
                    <h4>Subscription type</h4>
                    {subscription ? 
                        <div className='paused-container'>
                        <p>{orderItems?.intervaldays === 7 ? "Every week" : orderItems?.intervaldays === 14 ? "Every other week" : orderItems?.intervaldays === 1 ? "Every day" : orderItems?.intervaldays === 2 ? "Every other day" : ''} <span> - {orderItems?.discount}% off</span></p>
                        {orderItems?.status === "paused" && <span className={`status-paused`}>Paused</span>}
                         </div>
                         :
                        <p>{orderData?.intervaldays === 7 ? "Every week" : orderData?.intervaldays === 14 ? "Every other week" : orderData?.intervaldays === 1 ? "Every day" : orderData?.intervaldays === 2 ? "Every other day" : ''} <span> - {orderData?.recurringDiscountPercent}% off</span></p>
                    }
                </div>
                }
                {orderItems?.providerOrderAction?.action !== "refused" && (
                    isDelivery ? <div className='order-delivery-address card-shadow mb-3'>
                        {stepStatus === 3 ? <h4>Your meals have been delivered to:</h4> : <h4>Your meals will be delivered to:</h4>}
                        {deliveryAddressView}
                    </div> : <div className='order-delivery-address card-shadow mb-3'>
                        <h4>Your meals can be picked up at:</h4>
                        {pickUpAddressView}
                    </div>
                )}
                <div className='order-item-card card-shadow mb-3'>
                    {isMobile && 
                    <div className='card-header'>
                        <h4>Items</h4>
                    </div>
                    }
                    <div className='order-items-media-list'>
                    {orderItems?.meals?.map((item) => {
                            return <OrderItem item={item} subscription={subscription} paused={orderItems?.status === "paused"}/>
                        })}
                    </div>
                </div>
                {orderItems?.providerOrderAction?.action !== "refused" && (
                <div className='order-summary-container'>
                <OrderSummary
                    totalPrice={userSubTotal}
                    meals={totalMeals}
                    days={days}
                    tax={tax}
                    actualDiscount={actualDiscount}
                    totalDiscount={totalDiscount}
                    deliveryFee={deliveryFee}
                    repeatOrderDiscount={repeatOrderDiscount}
                    serviceFee={serviceFee}
                    grandTotal={grandTotal}
                    userTotalAmount={userTotalAmount}
                />
                {(subscription && orderItems?.status !== "paused") &&
                <>
                    <div className='cancel-pause-div'>
                        <text onClick={() => {setOpenModal(true)}}>Cancel subscription</text>
                        <Button type={'mobile_secondary'} title="Pause subscription" onClick={() => setIsPaymentModalActive(true)}/>
                    </div>
                </>
                }
                </div>
                )}
                </div>
                {!isMobile && <FooterDesktop />}
            </div>
            {clicked &&
                <div className='items-popup'>
                    {mealsDiff > 0 &&
                        <div className='check'>
                            <img src='/icons/alarm-icon.svg' />
                            <p>{mealsDiff} items out of Stock</p>
                        </div>}
                    {numOfMeals > 0 &&
                        <div className='check'>
                            <img src='/icons/check-white.svg' />
                            <p>{numOfMeals} items have been added to your cart.</p>
                            <p onClick={() => history.push('/shop/cartScreen')}><u>View cart</u></p>
                        </div>
                    }
                </div>
            }        
        <Modal open={openModal} blockScroll={false} styles={isMobile && { modalContainer: customStyles.modalContainer }} center showCloseIcon={false}>
            <SubscriptionCancelModel>
                <div className='modal'>
                <Close className='close-image' onClick={() => setOpenModal(false)}/>
                <div className='container'>
                    {isMobile ? <p className='delete-title'>Are you sure you want to <br/> cancel the subscription? </p> : <p className='delete-title'>Are you sure you want to cancel the <br/> subscription? </p> }
                <div className='delete-buttons'>
                    <div className='button'>
                        <Button type={'mobile_save'} title="Yes" onClick={() => {subscriptionStatus(orderItems?.id, "canceled")}} isDisabled={isLoading}/>
                        <Button type={'mobile_secondary'} title="Cancel" onClick={() => setOpenModal(false)} />
                    </div>
                    </div>
                </div>
                </div>
            </SubscriptionCancelModel>
        </Modal>
        <PauseSubscription
            setIsPaymentModalActive={setIsPaymentModalActive}
            isPaymentModalActive={isPaymentModalActive} 
            subscriptionStatus={subscriptionStatus}
            orderItems={orderItems}
            isSubscriptionPaused={isSubscriptionPaused}
            setIsSubscriptionPaused={setIsSubscriptionPaused}
            pausedSubscriptionClose={pausedSubscriptionClose} 
            isLoading={isLoading}
        />
        </OrderContextWrapper>
        </GilroyFontWrapper>
    )
}
