import classNames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import Select from 'components/Select';
import OrderSummary from 'components/shop/Checkout/OrderSummary';
import CreditCardInput from 'react-credit-card-input';
import produce from 'immer';
import {
  toTimeString,
  validInNextNDays,
} from '../providerTimes';
import { isPointInPolygon } from 'utils/verify-coordinates-in-polygon.util';
import { all, any, groupBy, isEmpty } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from 'context/appContext';
import { toast } from 'react-toastify';
import { usePersistedState } from 'hooks/usePersistedState';
import { useRef } from 'react';
import MobileHeader from 'components/elements/MobileHeader';
import { AnimatePresence } from 'framer-motion';
import DeliveryAddress from './DeliveryAddress';
import PromoCodeMobile from 'components/shop/Checkout/PromoCodeMobile';
import { ReactComponent as CheckboxBlank } from 'assets/shop/checkbox_blank.svg';
import { ReactComponent as CheckboxChecked } from 'assets/shop/checkbox_checked.svg';
import { ReactComponent as CheckboxDisabled } from 'assets/shop/checkbox_disabled.svg';
import { ReactComponent as ArrowLeft } from 'assets/shop/Chevron.svg';
import PayButton from './PayButton';
import MessageModal from 'components/elements/modal/MessageModal';
import { ReactComponent as PaymentFailed } from 'assets/shop/payment-failed.svg';
import { ReactComponent as ChevronLeft } from 'assets/chevron-left.svg'
import { ReactComponent as Danger } from 'assets/shop/danger.svg';
import useCart from 'hooks/useCart';
import PaymentMethod from './PaymentMethod';
import { useRequest } from 'hooks/useRequest';
import { paymentMethod } from 'components/svg/PaymentMethod';
import { formatExpiryDate, transformExpiryFormat } from 'utils';
import Skeleton from '@mui/material/Skeleton';
import { supportsDeliveryAndPickup } from 'utils/supportsDeliveryAndPickup';
import RadioButton from 'components/elements/radioButton/radioButton';
import { SubcriptionOptions, SubcriptionDesktopOptions } from '../../../constants'


const CheckOrderStyle = styled.div`
  background-color: #f6f5f8;
  padding: 0px 0 00px;

  .select-payment{
    overflow: clip;
  }

  .alert{
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    width: 100%;
    top:12px;
    .alert_bar{
      height: 52px !important;
      width: 90% !important;
      .alert_header .title{
        align-items: center;
        width: 86% !Important;
      }
    }
  }

  .radio-wrapper::before{
    content: '';
    position: absolute;
    top: -15px;
    height: 50px;
    left: -20px;
    width: 100vw;
  }

  .pickup-from{
    color: #00092D;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    display: block;
    margin-top: 0;
  }

  .required{
    margin-left: 3px;
    justify-content: flex-end;
    color: red;
    font-size: 12px
  }

  .container {
    width: 100%;

    h3 {
      margin-bottom: 13px;
      color: #0b1c18;
      font-size: 16px;
      font-weight: 600;
    }

    .time-container {
      display: flex;
      .select-wrapper{
        margin-bottom: 12px;
      }
    }

    .note-wrapper {
      margin-bottom: 23px;
      margin-top: 23px;
    }

    .repeat-order-wrapper{
      margin-top: 8px;
      .css-1gu2tss-MuiButtonBase-root-MuiRadio-root{
        padding: 9px 8px 9px 12px;
      }
      .label{
        color: #51635D !important;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        .title-value{
          font-weight: 600;
        }
      }
    }

    .payment-title {
      margin-bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: flex-start !important;

      h3 {
        margin-bottom: 0;
        color: #51635D;
        font-size: 12px;
      }
    }

    .payment-edit {
      color: #52c41a;
      font-size: 14px;
    }

    .radio-wrapper {
      top: 0;
      margin-bottom: 0;
    }

    .radio-check {
      width: 16px;
      height: 16px;
      margin-top: 2px;
      border-width: 1px;
      &:after {
        width: 8px;
        height: 8px;
      }
    }
    .radio-wrapper {
      padding-left: 15px;
      flex-shrink: 0;
    }

    .select-payment,
    .img-wrapper {
      display: flex;
      align-items: center;
    }

    .select-payment {
      padding: 10px 14px;
      display : flex
      margin-bottom:15px;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      justify-content: space-between;
     &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }
    .img-wrapper {
      margin-left: 20px;

      img {
        width: 34px;
        height: 24px;
        margin-left: 5px;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .apple-pay {
      margin-bottom: 16px;

      .img-wrapper {
        margin-left: 28px;
      }
    }

    .radio-label {
      margin-left: 15px;
      color: #747c7a;
    }

    .payment-details {
      img{
        display: none;
      }
      .phoneNumber{
        padding-top: 16px;
      }
      .is-invalid{
        border: 0px !important;
      }
      .isInvalid{
        border: 1px solid #FEC8B4 !important;
        background-color: #FEF1F0;
        color: #EB3223;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;

      input {
        height: 42px;
        padding: 0 15px;
        color: #000A2C;
        border: 1px solid #e0e3e0;
        border-radius: 4px;

        &::placeholder {
          color: #949D9B;
          font-size: 14px;
        }
        &:focus{
          border-color: #00092D !important;
        }
      }

      p {
        color: #0b1c18;
        font-size: 14px;
      }
    }

    .form-group-wrapper {
      margin: 20px 0 35px;
      display: flex;
      margin: 0 auto; 

      .form-group {
        width: 48%;

        &:first-child {
          margin-right: 4%;
        }
      }
    }
  }
  .select-wrapper{
    .selected-value{
      &.selectedValue{
        border-color: #00092D;
        img{
          transform: rotate(180deg);
        }
      }
    }
  }

  .provider-details {
    margin-bottom: 10px;
    padding: 0 16px 15px;
    border-bottom: 1px solid #51635d1a;
    background-color: #fff;
    padding-top: 17px;
    &:first-child{
      padding-top: 0px;
    }

    .provider-details-select-wrapper {
      font-weight: 700;

      .dropdown-delivery{
        .select-wrapper{
          margin-bottom: 8px;
          margin-right: 0;
        }
      }
    }

    .provider_card{
      display: flex;
      align-items: center;
      gap: 8px;
      padding-top: 16px;
      padding-bottom: 21px;
      .image{
        width: 40px;
        height: 40px;
        border: 1.5px solid #fff;
        border-radius: 50px;
        overflow: clip;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
      .details{
        flex: 1;
      }
      .provider__name{
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #0B1D17;
        margin-bottom: 4px;
      }
      .provider__min__delivery{
        color: #757E89;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.28px;
      }
    }

    .not_delivering{
      background: #FEEBEB;
      padding: 12px 17px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #D13726;
      margin-bottom: 16px;
      span{
        font-weight: 700;
      }
    }

    .delivery__information{
      color: #0B1D17;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

  }

  .select-option,
  .selected-value {
    font-weight: 500;
    text-transform: none;
    color: #00092D;
  }

  .select-option {
    padding: 10px 11px;
  }

  .valueSelected {
    background: #F5F7F8;
    color: #00092D;
  }

  .title {
    margin-bottom: 0;
    color: #00092D !important;
    font-size: 14px;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
  }

  textarea {
    height: 95px;
    width: 100%;
    padding: 9px 14px;
    color: #00092D;
    border: 1px solid #e0e3e0;
    border-radius: 6px;
    font-family: inherit;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 20px;
    resize: vertical;
    display: block;
    &::placeholder{
      color: #949D9B;
    }
    &:focus{
      border-color: #00092D !important;
    }
  }

  .payment-details {
    margin-bottom: 0;
    margin-top: 16px;
    .credit__card__input{
      padding: 16px 16px 6px;
      border-radius: 6px;
      border: 1px solid #EEE;
      input{
        transition: all 0.3s;
        &:focus{
          border-color: #00092D !important;
        }
        &::placeholder{
          color: #949D9B;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .card__input{
        p:last-child{
          display: none;
        }
      }
      .save_card{
        margin-top: 4px;
        padding: 6px 0;
        display: flex;
        align-items: center;
        gap: 8px;
        .checkmark{
          svg{
            display: block;
          }
        }
        label{
          color: #283241;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          display: block;
          height: 18px;
        }
      }
    }

    .ttitle{
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 7px;
    }

    .error{
      padding: 12px 14px;
      background: #FEEBEB;
      margin-bottom: 7px;
      p{
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #D13726;
      }
      &:empty{
        display: none;
      }
    }

    & > div {
      width: 100%;
    }
  
  }

    div.selected-value {
      padding: 0 12.5px 0 14px;
      color: #00092D;
      font-weight: 500;
      img {
        width: 20px;
        height: 20px;
      }
      > span{
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .checkout_mobile_header{
      background-color: #fff;
    }

    .form__handler{
      height: ${({screenHeight}) => screenHeight - 48}px;
      overflow: auto;
      padding-bottom: 115px;
      background-color: #fff;
      &::-webkit-scrollbar{
        display: none;
      }
    }

    .container.checkout-order-wrapper {
      width: 100%;
      padding: 0px 0px 0px;
      background: #F5F7F8;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 0%);
      margin: auto !important;
      font-weight: 700;

       .provider-title {
        margin: 0 0 23px -5px;
        color: #0b1d17;
        font-size: 14px;
        
        font-weight: normal;
        line-height: 17px;
      }

      .dropdown-pickup, .time-container{
        .select-wrapper{
          margin-bottom: 8px !important;
        }
      }

      .note-wrapper {
        margin-top: 8px;
        margin-bottom: 0;
      }

      .payment-wrapper {
        padding: 0 20px;
        @media (max-width: 991px){
          background-color: #fff;
          padding: 16px;
        }
      }

      .payment-title {
        margin-bottom: 12px;

        h3 {
          color: #0B1D17;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .select-payment {
        height: 44px;
        margin-bottom: 12px;
        padding: 0 14px;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        justify-content: space-between;

        &.apple-pay {
          margin-bottom: 25px;
        }
        &:hover {
          background-color: #fff;
          cursor: pointer;
        }
        &.credit__checked, &.apple__pay__checked{
          border: 1px solid #000A2C;
          .radio-check{
            background-color: #00092D !important;
          }
        }
        &.apple__pay__checked{
          margin-bottom: 0;
        }
        &.apple__pay__checked + #paymentDetails{
          margin: 0;
        }
      }

      .radio-label {
        margin-left: 8px;
        color: #51635d;
        font-size: 12px;
        line-height: 14px;
      }

      .img-wrapper {
        margin: 0;

        img {
          width: 24px;
          height: 16px;
          margin-left: 10px;
        }
      }

      #field-wrapper {
        padding: 0;
        flex-wrap: wrap;

        label {
          width: 100%;
          margin: 0;
          display: block;

          :after {
            display: none;
          }

          &:nth-of-type(2) {
            width: 94px;
          }

          &:nth-of-type(3) {
            width: 96px;
          }

          &:nth-of-type(4) {
            width: calc(100% - 207px);
            margin-left: 7px;
          }
        }

        img {
          position: absolute;
          top: 14px;
          right: 16px;
          z-index: 3;
        }

        .down_icon{
          position: absolute;
          top: 12px;
          right: 13px;
          z-index: 3;
        }

        .credit-card-input {
          height: 44px;
          padding: 0 14px;
          position: static;
          border: 1px solid #e0e3e0;
          border-radius: 6px;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #000A2C;
        }

        #card-number {
          margin-bottom: 12px;
        }

        #cvc, #zip {
          margin-left: 8px;
        }
      }

      .billing-address {
        label {
          color: #00092D;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          height: 32px;
          display: block;
        }

        .row{
          display: flex;
          gap: 7px;
          .form_grp{
            flex: 1;
            input{
              width: 100%;
              font-size: 14px !important;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              font-family: 'Gilroy';
              color: #000A2C;
              border-radius: 6px;
              &:focus{
                border: 1px solid #00092D !important;
              }
              &::placeholder{
                color: #949D9B;
              }
            }
          }
          &.second{
            margin-top: 12px;
            gap: 7px;
          }
        }
      }

      .phoneNumber {
        span {
          color: #00092D;
          font-size: 14px;
          font-weight: 600;
          line-height: 32px;
        }

        input {
          height: 44px !important;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #000A2C;
          font-family: inherit;
          &::placeholder{
            color: #949D9B;
          }
          &:focus{
            border-color: #00092D !important;
          }
        }
      }

      .add_gift_card{
        text-align: center;
        padding: 8px 0;
        background-color: #fff;
        border-top: 1px solid #F0F2F4;
        border-bottom: 1px solid #F0F2F4;
        .promo_code{
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          text-decoration-line: underline;
          display: inline-block;
          max-width: 250px;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
          color:#000A2C;
        }
      }
    }
.saved-card{
  height: 52px;
  display: flex;
  align-items: center;
  .payment{
    width: 24px;
    height: 16px;
    margin-right: 14px;
    img{
      display: block !important;
    }
  }
  .change-text{
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
  .desc {
    flex: 1;
    margin-top: 13px;
    .card_details {
      color: #0B1D17;
      font-family: Gilroy;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .card_expiry {
      font-weight: 600;
    }
  }
  }
  .cvv-holder{
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    gap: 10px;
    width: 100px !important;  
  }

  @media (min-width: 992px) {
    background-color: #fff;
    margin-top: -78px;
    .repeat-order-wrapper{
      line-height: 20px;
      color: var(--Black, #0B1D17);
    }
    .check-order-containter {
      padding-top: 100px;
      max-width: 1080px;
      margin: auto;
      .page__title{
        color: #0B1D17;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 32px;
        display: inline-block;
        cursor: pointer;
        .icon{
          cursor: pointer;
        }
      }
      .checkout-order-wrapper{
        display: flex;
        gap: 48px;
        background-color: #fff;
        > div{
          &:first-child{
            flex: 1;
          }
        }
        .order__summary{
          width: 400px;
        }
        .payment-wrapper{
          padding: 24px 42px;
          border-radius: 12px;
          border: 1px solid #E0E4E8;
        }
        .payment-details{
          margin-top: 12px;
        }
        .credit__card__input{
          padding: 0;
          border-radius: 0;
          border: 0px;
          .ttitle{
            font-size: 14px;
            font-weight: 600;
            line-height: 32px;
            color: #00092D;
            height: 32px;
            margin-bottom: 0;
          }
          .save_card{
            margin-top: 20px;
          }
        }
        #field-wrapper {
          label{
            &:nth-of-type(2) {
              width: 204px !important;
            }

            &:nth-of-type(3) {
              width: 146px !important;
            }

            &:nth-of-type(4) {
              width: calc(100% - 367px) !important;
            }
          }
        }
      }
      .provider-details{
        border-radius: 12px;
        border: 1px solid #E0E4E8;
        padding: 16px 42px 24px;
        margin-bottom: 24px;
        .provider_card{
          padding: 0 0 16px;
          .image{
            width: 48px;
            height: 48px;
          }
          .provider__name{
            font-size: 20px;
            font-weight: 600;
          }
        }
        .divider{
          width: calc(100% + 84px);
          margin-left: -42px;
          height: 1px;
          background-color: #E0E4E8;
        }
        .delivery__information{
          margin-top: 24px;
          margin-bottom: 14px;
        }
        .provider-details-select-wrapper{
          .select-wrapper{
            margin-bottom: 12px;
          }
        }
        .selected-value{
          line-height: 20px;
          font-family: 'Gilroy';
          span{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;  
            overflow: hidden;
          }
        }
        .note-wrapper{
          margin: 12px 0 0;
        }
        .delivery__handler{
          display: flex;
          gap: 12px;
          > div{
            flex: 1;
          }
        }
        .not_delivering{
          display: flex;
          align-items: flex-start;
          margin-top: 24px;
          margin-bottom: -8px;
          gap: 23px;
          padding: 14px 25px;
          svg{
          }
          > div{
            flex: 1;
          }
          .remove_button{
            width: 88px;
            height: 31px;
            padding-left: 16px;
            padding-right: 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #00092D;
            border-radius: 30px;
            border: 1px solid #757E89 !important;
            display: flex;
            align-items: center;
            background: rgba(0,0,0,0);
            cursor: pointer;
          }
        }
      }
      .form__handler{
        height: auto;
        padding-bottom: 100px;
        .billing-address {
          .row{
            .form_grp{
              input{
                height: 44px;
                &:focus{
                  border: 1px solid #00092D !important;
                }
                &::placeholder{
                  color: #949D9B;
                }
              }
            }
            &.second{
              margin-top: 12px;
              gap: 7px;
            }
          }
        }
      }
      .order__summary{
        border-radius: 12px;
        border: 1px solid #E0E4E8;
      }
      .add_gift_card{
        border-top: 0px !important;
        border-bottom: 0px !important;
        padding: 0px !important;
      }
      .kitchen__summary{
        padding-top: 24px;
        .order-summary-wrapper{
          padding: 0;
        }
        .item-wrapper{
          margin-bottom: 0 !important;
          padding: 10px 0 !important;
          border-bottom: 1px solid #E0E3E0 !important;
          &:first-child{
            padding-top: 0 !important;
            span{
              font-weight: 700;
            }
          }
          &:last-child{
            padding-bottom: 0 !important;
            border-bottom: 0px !important;
          }
        }
      }
    }
  }
  @media (max-width: 992px) {
    background-color: #fff;
    .saved-card{
      height: 44px;
      padding: 0 16px;
      border-radius: 6px;
      border: 1px solid #E0E3E0;
      .desc {
        margin: 0 !important;
      }
    }
  }
`;
const DatePickerWrapper = styled.div`
  width: 100%;
  label {
    font-size: 12px;
    color: #939b99;
  }
  input {
    border: 1px solid #c3cbc9;
    width: 95%;
    height: 46px;
    border-radius: 4px;
    padding: 13px;
  }
`;

const FailedModalStyle = styled.div`
  background: rgba(11, 29, 23, 0.65);
  position: fixed;
  inset: 0;
  z-index: 112;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  .motion_modal{
    justify-content: center;
    width: 100%;
    display: flex;
  }
  .modal{
    gap: 24px;
    padding: 32px 33px;
    height: auto;
    .details{
      .description{
        padding: 0;
        padding-top: 8px;
        height: 41px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        justify-content: center;
        align-items: flex-start;
      }
      .title{
        font-size: 20px;
        line-height: 24px;
      }
    }
    .button{
      width: 220px;
    }
  }
`
const PaymentSkeletonStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  .flex-1{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`

const PaymentSkeleton = () => {
  return (
    <PaymentSkeletonStyle>
      <Skeleton width={24} height={14} variant="rounded" />
      <div className='flex-1'>
        <Skeleton width={80} height={18} variant="rounded" />
        <Skeleton width={100} height={18} variant="rounded" />
      </div>
      <Skeleton width={50} height={15} variant="rounded" />
    </PaymentSkeletonStyle>
  )
}

const ProviderSettings = ({ index, provider, providerSettings, updateSettings, userInfo, isUserDeliveryError, setUpdatedValue,setMealOptions, setIsUserDeliveryError, setDeliveryAddressList, providerSubscriptionList, setProviderSubscriptionSelection, isApplePay}) => {
  const thisPro = provider[index];
  let providerIndex = providerSettings?.findIndex((i) => i.providerId === thisPro.providerId);
  const thisProvider = providerSettings[providerIndex];
  const { isMobile, user } = useAppContext();
  const [providerDeliveryArea, setProviderDeliveryArea] = useState(thisPro?.deliveryCoverage);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState({});
  const [note, setNote] = useState(thisProvider?.note);
  const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(true);
  const { removeProviderFromCart } = useCart();
  const { cart } = useShopContext();
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const [isAnyDayOnForDelivery, setIsAnyDayOnForDelivery] = useState();
  const [isAnyDayOnForPickup, setIsAnyDayOnForPickup] = useState();
  const [settingsToUpdate, setSettingsToUpdate] = useState([]);
  const [selectedOption, setSelectedOption] = useState("once");
  const handleRadioChange = (e) => setSelectedOption(e.target.value);

  const handleRemoveProviderFromCart = (providerId) => {
    const meals = cart?.map(meal => {
      if(meal?.providerId === providerId){
        return meal;
      }
      return null;
    })?.filter((item) => item !== null);
    removeProviderFromCart(meals);
    setDeliveryAddressList((prev) => prev?.filter(item => item?.providerId !== providerId));
    setIsUserDeliveryError((prev) => prev?.filter(item => item?.providerId !== providerId));
  }

  const checkIfDeliveryAvailable = (selectedDeliveryAddress) => {
    const deliverableAreas = thisProvider?.deliverableAreas?.data || [];
    if (deliverableAreas.length > 0 && Object.keys(selectedDeliveryAddress).length > 0) {
      for (let polygon of deliverableAreas) {
        const polygonCoords = polygon?.map((coordinate) => [coordinate?.lat, coordinate?.lng]);
        const isPointInsidePolygon = selectedDeliveryAddress?.userAddressLatLong?.lat && selectedDeliveryAddress?.userAddressLatLong?.lng &&  isPointInPolygon(
          selectedDeliveryAddress?.userAddressLatLong?.lat,
          selectedDeliveryAddress?.userAddressLatLong?.lng,
          polygonCoords,
        );

        if (isPointInsidePolygon) {
          const zip = selectedDeliveryAddress?.zipcode;
          setProviderDeliveryArea((prev) => ([
            ...prev.filter(item => item !== zip),
            zip
          ]));
          return true; // Return true if delivery is available for this address
        }
      }
    }
    if (providerDeliveryArea?.includes(selectedDeliveryAddress?.zipcode)) {
      return true;
    }
    return false; // Return false if delivery is not available for any of the polygons
  }

  useEffect(() => {
    const providerId = thisPro.providerId;
    if(Object?.keys(selectedDeliveryAddress)?.length > 0 && thisProvider?.method == 'delivery'){
      setDeliveryAddressList((prev) => {
        const updatedAddresses = prev?.filter((item) => {
          const isDifferentLat = Number(item?.userAddressLatLong?.lat) !== Number(selectedDeliveryAddress?.userAddressLatLong?.lat);
          const isDifferentLng = Number(item?.userAddressLatLong?.lng) !== Number(selectedDeliveryAddress?.userAddressLatLong?.lng);
          const isDifferentProvider = item?.providerId !== thisProvider?.providerId;
          return isDifferentLat && isDifferentLng && isDifferentProvider;
        });
    
        const newAddress = {
          ...selectedDeliveryAddress
        };
    
        return [...updatedAddresses, newAddress];
      });
      const status = checkIfDeliveryAvailable(selectedDeliveryAddress);
      setIsDeliveryAvailable(status);
      setIsUserDeliveryError((prev) => [
        ...prev?.filter(item => item?.providerId !== providerId),
        {
          providerId: providerId,
          status: !status
        }
      ]);
    }
    else{
      setIsUserDeliveryError((prev) => [
        ...prev?.filter(item => item?.providerId !== providerId),
        {
          providerId: providerId,
          status: false
        }
      ]);
    }
  }, [selectedDeliveryAddress, thisPro])

  useEffect(() => {
    if(thisProvider?.method === "pickup"){
      setIsUserDeliveryError((prev) => [
        ...prev?.filter(item => item?.providerId !== thisPro.providerId),
        {
          providerId: thisPro.providerId,
          status: false
        }
      ]);
    }
  }, [thisProvider?.method])

  useEffect(() => {
    if(isUserDeliveryError && isUserDeliveryError?.length){
      const providerDelivery = isUserDeliveryError?.find(item => item?.providerId === thisPro.providerId);
      setIsDeliveryAvailable(!providerDelivery?.status);
    }
  }, [isUserDeliveryError])

  useEffect(() => {
    Object.keys(selectedDeliveryAddress)?.length && handleUpdateProviderSettings('deliveryInfo', selectedDeliveryAddress);
  }, [selectedDeliveryAddress])

  useEffect(() => {
    if(settingsToUpdate && settingsToUpdate?.length){
      const settingsKeyVal = {};
      settingsToUpdate?.forEach(item => {
        settingsKeyVal[item.key] = item.value;
      });
      updateSettings(
        produce((draft) => {
          draft.providerSettings[providerIndex] = {
            ...draft.providerSettings[providerIndex],
            ...settingsKeyVal,
          }
        }),
      );
    }
  }, [settingsToUpdate])

  const handleUpdateProviderSettings = (key, value) => {
    setSettingsToUpdate((prev) => [...prev?.filter(item => item?.key !== key), {key, value}]);
  }

  useEffect(() => {
    handleUpdateProviderSettings('orderPhoneNumber', phoneNumber);
    const defaultSelectedAdd = {...userInfo};
    delete defaultSelectedAdd.firstName;
    delete defaultSelectedAdd.lastName;
    delete defaultSelectedAdd.phone;
    setSelectedDeliveryAddress(defaultSelectedAdd);
    setUpdatedValue(userInfo);
  }, [userInfo]);

  const methodScheduleDates = useMemo(() => {
    const availableDates = validInNextNDays(14, thisProvider?.schedule, thisProvider?.method).reduce((acc, date) => {
      const formattedDate = moment(date).format('ddd, ll');
      if (!acc[formattedDate]) {
        acc[formattedDate] = [];
      }
      acc[formattedDate].push(date);
      return acc;
    }, {});
  
    const uniqueDates = new Set();
    for (let key in availableDates) {
      const data = availableDates[key];
      const formattedDate = moment(key).format('MMM D, dddd');
      const timeRange = `${moment(data[0]).format('hh:mm A')}-${moment(data[data.length-1]).format('hh:mm A')}`;
      const item = `${formattedDate}, ${timeRange}`;
      uniqueDates.add(item);
    }
  
    return Array.from(uniqueDates);
  }, [thisProvider?.method]);

  useEffect(async () => {
    const deliveryOrPickupAvailable = supportsDeliveryAndPickup(thisProvider);
    setIsAnyDayOnForDelivery(deliveryOrPickupAvailable?.isOnForDelivery)
    setIsAnyDayOnForPickup(deliveryOrPickupAvailable?.isOnForPickup)
    if (thisProvider?.method === "pickup" || isApplePay === "apple pay") {
      setProviderSubscriptionSelection(prevState => {
        const updatedSelection = [...prevState];
        const index = updatedSelection.findIndex(item => item.providerId === thisProvider?.id);
        if (index !== -1) {
          updatedSelection.splice(index, 1);
        }
        return updatedSelection;
      });
      setSelectedOption('once')
    }
  }, [selectedDeliveryAddress, thisProvider, isApplePay])

  const mealDeliveryOptions = useMemo(() => {
    return isAnyDayOnForPickup && isAnyDayOnForDelivery ? [
      { label: 'Delivery', value: 'delivery' },
      { label: 'Pick Up', value: 'pickup' },
    ] : isAnyDayOnForPickup && !isAnyDayOnForDelivery ? [{ label: 'Pick Up', value: 'pickup' }] :
      !isAnyDayOnForPickup && isAnyDayOnForDelivery ? [{ label: 'Delivery', value: 'delivery' }] : [];
  }, [thisProvider?.deliveryCoverage, isAnyDayOnForDelivery, isAnyDayOnForPickup]);

  useEffect(() => {
    if (mealDeliveryOptions.length > 0) {
      setMealOptions((mealOptions) => ({
        ...mealOptions,
        [index]: true,
      }));
    } else {
      setMealOptions((mealOptions) => ({
        ...mealOptions,
        [index]: false,
      }));
    }
  }, [mealDeliveryOptions, provider]);

  const getDefaultChecked = useMemo(() => {
    let dayChecked;
    if (localStorage.getItem('providerDeliveryDate')) {
      const providerDeliveryDates = JSON.parse(localStorage.getItem('providerDeliveryDate'));
      methodScheduleDates.map(date => {
        if (moment(date, 'MMM DD, dddd').valueOf() === providerDeliveryDates[Number(thisProvider?.id)]) {
          dayChecked = date;
        }
      })
    }else{
      methodScheduleDates.map(date => {
        if (moment(date, 'MMM DD, dddd').valueOf() == providerSettings[providerIndex].date) {
          dayChecked = date;
        }
      })
    }
    return dayChecked;
  }, [methodScheduleDates?.length])

  const handlePhoneNumberChange = (event) => {
    if (event.target.value.length > 13) return false;
    const onlyDigits = event.target.value.replace(/[^\d+]/g, "");
    setPhoneNumber(onlyDigits);
  };

  const filteredProviderSubscription = useMemo(() => {
    return providerSubscriptionList?.filter(sub => sub?.providerId === thisProvider?.id);
  }, [providerSubscriptionList, thisProvider]);

  if(!thisProvider){
    return <></>;
  }
  
  return (
    <>
    <div className="provider-details">
      <div className='provider_card'>
        <div className='image'>
          <img src={thisProvider?.image} onError={(e) => { e.target.src = '/images/avatar.png' }}/>
        </div>
        <div className='details'>
          <h2 className='provider__name'>{thisProvider?.name}</h2>
          <p className='provider__min__delivery'>{thisProvider?.minOrderAmount > 0 ? 'Min order: $' + thisProvider?.minOrderAmount : 'No minimum order'}</p>
        </div>
      </div>
      {!isMobile && <div className='divider' />}
      {!isDeliveryAvailable && thisProvider?.method == 'delivery' && selectedDeliveryAddress?.address &&
      <div className='not_delivering'>
        {!isMobile && <Danger />}
        <div>
        <span>{thisProvider?.name}</span> does not deliver to your location. Select an alternative kitchen or update your delivery address.
        </div>
        {!isMobile && <button className='remove_button' onClick={()=>handleRemoveProviderFromCart(thisProvider?.providerId)}>Remove</button>}
      </div>
      }
     {mealDeliveryOptions.length > 0 ? <>
      <div className='delivery__information'>Delivery information</div>
      <div className='provider-details-select-wrapper'>
      <div className="title">
          <span>Method</span>
          <span style={{color: 'red'}}>*</span>
        </div>
      <div className={`dropdown-${thisProvider?.method}`}>
      <Select
        key="method"
        icon='Chevron-down'
        options={mealDeliveryOptions}
        updateValue={(method) => {
          handleUpdateProviderSettings('method', method?.toLowerCase());
        }}
        defaultChecked={thisProvider?.method}
        isCheckout={true}
      />
      {thisProvider?.method == 'delivery' && isAnyDayOnForDelivery ? (
            <div className='delivery__handler'>
              <div className='deliveryBy-wrapper'>
                <div className="title">
                  <span>Delivery by</span>
                  <span className="required">*</span>
                </div>
                <div className="time-container">
                  {thisProvider?.schedule ? (
                    <Select
                      key="date"
                      icon='Chevron-down'
                      options={methodScheduleDates}
                      updateValue={(date) => { 
                        const dateSplit=date?.split(",")
                        const timeRange=dateSplit?.[dateSplit?.length-1]
                        handleUpdateProviderSettings('time', timeRange)
                        handleUpdateProviderSettings('date', moment(date, 'MMM DD, dddd').valueOf())
                      }}
                      defaultChecked={getDefaultChecked}
                    />
                  ) : (
                    <DatePickerWrapper>
                      <label>Time</label>
                      <input
                        type="time"
                        value={thisProvider?.time}
                        onChange={(e) => {
                          handleUpdateProviderSettings('time', e.target.value);
                        }}
                      />
                    </DatePickerWrapper>
                  )}
                </div>
              </div>
              <DeliveryAddress selectedDeliveryAddress={selectedDeliveryAddress} setSelectedDeliveryAddress={setSelectedDeliveryAddress} providerDeliveryArea={providerDeliveryArea} thisProvider={thisProvider} isDeliveryAvailable={isDeliveryAvailable} checkIfDeliveryAvailable={checkIfDeliveryAvailable} />
            </div>          
      ) : (
        <>
        {isAnyDayOnForPickup && <div>
        <div className="pickup-from-wrapper">
          <span className="pickup-from">Pickup from<span className="required">*</span></span>
        </div> 
        <Select
          key="address"
          icon='Chevron-down'
          options={[thisProvider?.address]}
          updateValue={(address) => {
            handleUpdateProviderSettings('address', address);
          }}
          defaultChecked={thisProvider?.address}
        />
        </div>}
        </>
      )}
      </div>
      </div></>
      :
      <div className='not_delivering' style={{ marginBottom: 0 }}> 
        {!isMobile && <Danger />}
        <div>
        Oops!, <span>${thisProvider?.name}</span> isn't available for delivery or pickup at your current address. To proceed, you can either remove them from your cart or select a different address.
        </div>
        {!isMobile && <button className='remove_button' onClick={()=>handleRemoveProviderFromCart(thisProvider?.providerId)}>Remove</button>}
      </div>
    }
      {mealDeliveryOptions.length > 0 && thisProvider?.method == 'pickup' &&
      <div className='deliveryBy-wrapper'>
        <div className="title">
          <span>Pickup by</span>
          <span className="required">*</span>
        </div>
        <div className="time-container">
          <Select
            key="date"
            icon='Chevron-down'
            options={methodScheduleDates}
            updateValue={(date) => { 
              const dateSplit=date?.split(",")
              const timeRange=dateSplit?.[dateSplit?.length-1]
              handleUpdateProviderSettings('time', timeRange)
              handleUpdateProviderSettings('date', moment(date, 'MMM DD, dddd').valueOf())
            }}
            defaultChecked={getDefaultChecked}
          />
          {thisProvider?.schedule ? (
            <></>
            // <Select
            //   key="time"
            //   // label="Time"
            //   icon='Chevron-down'
            //   options={(availableDates[thisProvider.date] || []).map(toTimeString)}
            //   updateValue={(time) => handleUpdateProviderSettings('time', time)}
            //   defaultChecked={thisProvider.time}
            // />
          ) : (

            <DatePickerWrapper>
              <label>Time</label>
              <input
                type="time"
                value={thisProvider?.time}
                onChange={(e) => {
                  handleUpdateProviderSettings('time', e.target.value);
                }}
              />
            </DatePickerWrapper>
          )}
        </div>
      </div>
      }
     {mealDeliveryOptions.length > 0 && <div className="form-group phoneNumber" id="billingAddress">
        <div className="title">
        <span>Phone Number</span>
        <span style={{color: 'red'}}>*</span>
        </div>
        <input
            type="text" 
            value={phoneNumber}
            placeholder="Phone Number"
            onChange={handlePhoneNumberChange}
            onKeyDown={(evt) => {
              (evt.key === 'e' || evt.key === '.') && evt.preventDefault();
            }}
            onBlur={() => {
              handleUpdateProviderSettings('orderPhoneNumber', phoneNumber);
            }}
          />
      </div>  }
     {mealDeliveryOptions.length > 0 && <div className="note-wrapper">
       <div className="title">Order notes</div>
        <textarea
        style={{ resize: 'none' }}
          placeholder="Any delivery notes? Add them here!"
          value={note}
          onChange={(event) => {
            event.preventDefault();
            setNote(event.target.value);
          }}
          onBlur={() => handleUpdateProviderSettings('note', note)}
        />      
    </div>}
    {mealDeliveryOptions.length > 0 && filteredProviderSubscription?.length > 0 && thisProvider?.method == 'delivery' && <div className="repeat-order-wrapper">
       <RadioButton 
        id={thisProvider?.id}
        label="✨ Repeat order & save" 
        options={isMobile ? SubcriptionOptions : SubcriptionDesktopOptions} 
        value={selectedOption}
        providerSubscriptionList={filteredProviderSubscription} 
        setProviderSubscriptionSelection={setProviderSubscriptionSelection}
        isApplePay={isApplePay}
        onChange={handleRadioChange} 
      />
    </div>}
    {provider?.length > 1 && !isMobile && 
      <div className='kitchen__summary'>
        <OrderSummary 
          isProviderSummary={true} 
          providerId={thisProvider?.providerId} 
          showTitle={false} 
          showDivider={false} 
          showServiceFee={false}
          providerCard={true}
        />
      </div>
    }
    </div>
    {(mealDeliveryOptions.length > 0 && provider?.length > 1 && isMobile) && <OrderSummary isProviderSummary={true} providerId={thisProvider?.providerId} providerCard={true}/>
    }
    </>
  );
};


export default function CheckOrder() {
  const history = useHistory();
  const { settings, updateSettings, cart, setPaymentInfo, paymentInfo, appliedCode, setAppliedCode, setPromoCodeInfo, orderSummaryInfo, taxRate, useNutrittCash } = useShopContext();
  const { userInfo, providerSettings } = settings;
  const { isMobile, isGuest, isVisitor, user, setRouteBeforeSignup } = useAppContext();
  const [updatedValue, setUpdatedValue] = useState(userInfo);
  const [isPromoValid,setIsPromoValid]=useState(true)
  const [isApplePay , setIsApplePay] = usePersistedState('method' ,'credit')
  const [cardInputError,setCardInputError] = useState(false)
  const [provider,setProvider]=useState([])
  const [mealOptions, setMealOptions] = useState({});
  const providersArray = providerSettings.map((i) => i);
  const mealsCart = cart.map((i) => i);
  const [showPromocode, setShowPromocode] = useState(false);
  const [isUserDeliveryError, setIsUserDeliveryError] = useState([]);
  const [isOrderSuccessful, setIsOrderSuccessful] = useState(false);
  const [isOrderFailed, setIsOrderFailed] = useState(false);
  const [orderFailError, setOrderFailError] = useState("")
  const [deliveryAddressList, setDeliveryAddressList] = useState([]);
  const [screenHeight, setScreenHeight] = useState();
  const formRef = useRef();
  const scrollHeightRef = useRef();
  const visitorSetting = localStorage.getItem("settings")
  const providerSettingsList = JSON.parse(visitorSetting)?.providerSettings
  const { removeProviderFromCart } = useCart();
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [isPaymentModalActive, setIsPaymentModalActive] = useState(false);
  const [ fetchSavedCards, { isLoading: isFetchingCards, error } ] = useRequest();
  const [savedCards, setSavedCards] = useState([]);
  const zipInputRef = useRef(null);
  const [activeSavedCard, setActiveSavedCard] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [isAddPayment, setIsAddPayment] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [createCustomer, { isLoading: isCustomerCreating, error: customerCreatingError }] = useRequest('paymentMethod/customer', 'post');
  const [addPaymentMethod, { isLoading: isPaymentAdding, error: paymentAddingError }] = useRequest('paymentMethod/add-payment-method', 'post');
  const [generateCustomerId, { isLoading: isCustomerIdGenerating, error: errorCustomerId }] = useRequest('');
  const zipRegrex = /^[a-zA-Z0-9]{4,5}$/
  const [providerSubscriptionSelection, setProviderSubscriptionSelection] = useState([])
  const [isCardAdded, setCardAdded] = useState(false);
  const [isOrderSummaryLoading, setIsOrderSummaryLoading] = useState(false);
  const [providerSubscriptionList, setProviderSubscriptionList] = useState([]);
  const prevProvider = useRef();
  const [fetchProviderSubscription] = useRequest('subscription/offerings', 'post')
  const [checkCardDetails, setCheckCardDetails] = useState(null);

  const getProviderSubscription = async ({provider}) => {
    try {
      const ids = provider.map(provider => provider.id);
      const response = await fetchProviderSubscription({ body: {providerIds:ids} });
      if (response?.data?.success) {
        const subscriptions = response.data?.subscriptions
        setProviderSubscriptionList(subscriptions)
      } else {
        console.log('Invalid API response structure');
      }
    } catch (err) {
      console.log('Error fetching saved cards', err);
      toast.error(err);
    }
  }  

  useEffect(() => {
    if(provider?.length > 0){
    if (!prevProvider.current && JSON.stringify(prevProvider.current) !== JSON.stringify(provider)) {
      getProviderSubscription({ provider });
      prevProvider.current = provider;
    }
  }
  }, [provider]);

  const handleCardZipBlur = () => {
    const value = zipInputRef.current.value;
    if (!zipRegrex.test(value)) {
      setCardInputError({...cardInputError, cardZIP: 'Zipcode is invalid'});
    }
  };


  function findDefaultPaymentMethod(paymentData) {
    const { PaymentMethods, DefaultPaymentMethodId } = paymentData;
    const matchedPaymentMethod = PaymentMethods.find(
      (method) => method.PaymentMethodId === DefaultPaymentMethodId
    );
    return matchedPaymentMethod || null;
  }

  const displayNumbers = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  const getSavedCards = async () => {
    try {
      const path = 'paymentMethod';
      const response = await fetchSavedCards({ path });
      if (response?.success && response.result) {
        setSavedCards(response.result.PaymentMethods || []);
        const matchedPaymentMethod = findDefaultPaymentMethod(response.result);
        setActiveSavedCard(matchedPaymentMethod);
        setIsPaymentModalActive(false);
      } else {
        console.log('Invalid API response structure');
      }
    } catch (err) {
      if(err !== "customer not found."){
        toast.error(err);
        console.log('Error fetching saved cards', err);
      }
    }
  };

  const getCustomerId = async () => {
    try {
      const path = 'paymentMethod/customer';
      const response = await generateCustomerId({ path });
      setCustomerId(response?.customer?.customerId);
      return response?.customer;
    } catch (err) {
      if(err !== "Customer not found"){
      console.log('Error in Generating Customer Id', err);
      toast.error(err);
      }
    }
  };

  const addPaymentMethodForCustomer = async ({ cardNumber, cvv, expiryDate, amount, zip, customerId }) => {
    try {
      const transformedExpiry = transformExpiryFormat(expiryDate);
      const response =  await addPaymentMethod({
        body: {
          cardNumber,
          cvv,
          expiry: transformedExpiry,
          amount: displayNumbers(amount),
          zip,
          customerId
        }
      });
      if (response && response.success) {
        if(activeSavedCard){
          getSavedCards();
          setIsAddPayment(false);
        } else {
          setCardAdded(true);
        }
      } 
    } catch (error) {
      console.error('Error adding payment method:', error);
      setErrorModal(true);
      setCardAdded(false);
      setCardInputError({cardCVC: error, cardExpiry: error, cardNumber: error, cardZIP: error})
      toast.error(error);
    }
  };
  
  const handleCreateCustomer = async (customerId, paymentInfo, orderSummaryInfo, userInfo) => {
    try {
      if (customerId) {
        await addPaymentMethodForCustomer({
          cardNumber: paymentInfo?.cardNumber,
          cvv: paymentInfo?.cvv,
          expiryDate: paymentInfo?.expiryDate,
          amount: orderSummaryInfo?.orderSummary?.grandTotal,
          zip: paymentInfo?.zipcode,
          customerId
        });
      } else {
        const response = await createCustomer({
          body: {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName
          }
        });
  
        await addPaymentMethodForCustomer({
          cardNumber: paymentInfo?.cardNumber,
          cvv: paymentInfo?.cvv,
          expiryDate: paymentInfo?.expiryDate,
          amount: orderSummaryInfo?.orderSummary?.grandTotal,
          zip: userInfo?.zipcode,
          customerId: response?.customerId
        });
      }
    } catch (error) {
        console.error('Error handling create customer:', error);
        toast.error(error);  
        throw error;
    }
  };

  useEffect(() => {
    setScreenHeight(window.innerHeight);
    getSavedCards();
    getCustomerId();
    const unfullfilledMeals =  JSON.parse(localStorage.getItem('unfullfilledOrders'));
    unfullfilledMeals?.length && removeProviderFromCart(unfullfilledMeals);
  }, [])

  useEffect(() => {
    setMealOptions({});
    setIsUserDeliveryError([]);
  }, [cart])

  useEffect(() => {
    if(cart.length < 1 && providerSettingsList.length < 1){
      setRouteBeforeSignup('');
      localStorage.removeItem('viewedStoreUrl');
      return history.push('/shop/kitchen-home');
    }
  }, [cart, providerSettings])

  useEffect(() => {
    if(formRef?.current){
      const screenHeight = scrollHeightRef?.current?.offsetHeight;
      formRef.current.scrollTop = screenHeight + 10;
    }
  }, [showPromocode])

  useEffect(() => {
    setPaymentInfo({...paymentInfo, ...activeSavedCard });
   },[activeSavedCard])

  const HandleCartMeal = () => {
    let mealForCheckout = [];
    cart.map((meal) => {
      const providersInCart = providersArray.filter((i) => i.providerId === meal.providerId);
      const mealGroup = mealsCart.filter((i) => {
        return providersInCart?.[0] && i?.providerId === providersInCart?.[0].providerId;
      });
      mealForCheckout.push(mealGroup);
    });
    let uniq = _.uniqWith(mealForCheckout, _.isEqual);
    return uniq;
  };
  const data = HandleCartMeal();

  const cartData = cart.map((i) => i.providerId);
  const providerInCart = [...new Set(cartData)];

  useEffect(() => {
    const filteredPaymentMethods = savedCards.filter(method => !method.applePay);
    if(filteredPaymentMethods?.length < 1){
      setPaymentInfo({
        method: '',
        cardNumber: '',
        expiryDate: '',
        cvv: "",
        zipcode:"",
        taxRate,
    });
    setActiveSavedCard(null);
  }
  },[savedCards])

  useEffect(() => {
    if (providerSettings) {
      const providerForTheOrder = providerSettings.filter((i) => providerInCart.includes(i.providerId));
      setProvider(providerForTheOrder)
    }
  }, [providerSettings])

  useEffect(() => {
    const isDeliveryProvider = provider?.some(provider => provider?.method === 'delivery')
    const defaultSelectedId = {...userInfo};
    delete defaultSelectedId.firstName;
    delete defaultSelectedId.lastName;
    delete defaultSelectedId.phone;

    // SETTING MULTIPLE BILLING ADDRESS FOR DIFFERENT PROVIDERS
    isDeliveryProvider && deliveryAddressList?.length !== provider?.length && provider?.map(item => {
      return setDeliveryAddressList(prev => [
        ...(prev || []).filter(x => x.providerId !== undefined && x.providerId !== item.providerId),
        {
          ...defaultSelectedId,
          providerId: item.providerId
        }
      ]);
    });
  }, [provider])
  
  const completeDeliveryInfo = (info) => {
    const hasBasicInfo = info && info.firstName && info.address && info.city && info.state;
    const hasZipcode = info?.zipcode;

    if(hasBasicInfo && hasZipcode){
      return true;
    }
    return false;
  };

  const isDeliveryInfoCompleted = completeDeliveryInfo(updatedValue);

  const userCompleteInfo =
    any((p) => (p.method == 'delivery' && !isDeliveryInfoCompleted) || !p?.orderPhoneNumber, provider,
    ) || showPromocode ||
    (paymentInfo?.PaymentMethodId
      ? false
      : (!paymentInfo.cardNumber ||
        !paymentInfo.expiryDate ||
        !paymentInfo.zipcode ||
        !paymentInfo.cvv || 
        isPaymentAdding ||
        isCustomerCreating ||
        Object.values(cardInputError).some(error => {
          return error && error.length;
        })
    ) && isApplePay == 'credit');
  
    const handleUpdatePaymentInfo =async(key, value) => {
      await setPaymentInfo((prev) => ({
        ...prev,
        [key]: value,
      }));
    };

  const notify = () => {
    const isInvalidPhoneNumber = provider.some((p) => p.orderPhoneNumber?.length < 10);
    if (isInvalidPhoneNumber) {
      toast.error('Invalid Phone Number.');
    } else {
      history.push(`/shop/checkout/checkPayment`);
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    isMobile && document.body.classList.add('hide-body-scroll');
    return () => {
      document.body.classList.remove('hide-body-scroll');
    }
  }, []);

  const handleInputClick = (event) => {
    event.preventDefault();
    setIsPaymentModalActive(true);
  };

  const handleSaveCard = (isSaveCard) => {
      if(!!providerSubscriptionSelection?.length){
        setIsSaveCard(true);
        return
      }
      setIsSaveCard(!isSaveCard);
  };

  const cardknoxCardError = useMemo(() => {
    const values = Object.values(cardInputError);
    const allEqual = values.every(value => value === values[0]);
    return allEqual && isSaveCard
  },[isSaveCard, cardInputError])

  const removeError = useCallback(() => {
    setCardInputError({ cardNumber: undefined, cvv: undefined, expiryDate: undefined, zip: undefined })
  },[])

  useEffect(() => {
    if(!!providerSubscriptionSelection?.length){
      handleUpdatePaymentInfo('method', 'credit');
      setIsApplePay('credit');
      setCheckCardDetails(activeSavedCard);
      setIsSaveCard(true);
    }
  },[providerSubscriptionSelection])

  const onPromocodeRemove = 
    () => {
      setPromoCodeInfo((prev) => ({...prev, promoCode: '' }));
      setAppliedCode('');
    }
      
  return (
    <>
    <AnimatePresence>
    <CheckOrderStyle screenHeight={screenHeight}>
      <div className="check-order-containter" >
        {isMobile ? (
          <MobileHeader className={'checkout_mobile_header'} text={'Check out'} onClickLeft={() => history.push('/shop/cartScreen', {fromCheckout: true})}/>
        ) :
          <div className='page__title' onClick={() => {
            setRouteBeforeSignup('');
            const goTo = JSON.parse(localStorage.getItem('viewedStoreUrl'));
            if(goTo){
              history.push(goTo);
            }else{
              history.goBack();
            }
            localStorage.removeItem('viewedStoreUrl');
          }}>
          <ChevronLeft className='icon' /> <span>Check out</span>
          </div>
        }
        <form ref={formRef} onSubmit={(e) => e.preventDefault()} className='form__handler'>
          <div ref={scrollHeightRef} className="container checkout-order-wrapper" id="checkoutOrder">
            <div>
            {provider?.map((p, i) => (
              <ProviderSettings 
              index={i} 
              key={p.id} 
              provider={provider} 
              providerSettings={providerSettings}
              userInfo={userInfo}
              completeDeliveryInfo={completeDeliveryInfo}
              updateSettings={updateSettings}
              paymentInfo={paymentInfo}
              handleUpdatePaymentInfo={handleUpdatePaymentInfo}
              setUpdatedValue={setUpdatedValue}
              updatedValue={updatedValue}
              isDeliveryInfoCompleted={isDeliveryInfoCompleted}
              setMealOptions={setMealOptions}
              isUserDeliveryError={isUserDeliveryError}
              setIsUserDeliveryError={setIsUserDeliveryError}
              setDeliveryAddressList={setDeliveryAddressList}
              deliveryAddressList={deliveryAddressList}
              settings={settings}
              providerSubscriptionList={providerSubscriptionList}
              setProviderSubscriptionSelection={setProviderSubscriptionSelection}
              activeSavedCard={activeSavedCard}
              isApplePay={isApplePay}
              savedCards={savedCards}
              isSaveCard={isSaveCard}
              />
            ))}
            <div className="payment-wrapper">
              <>
              <div className="payment-title">
                <h3>Payment method</h3>
              </div>
              <AnimatePresence>
              {showPromocode && 
                <PromoCodeMobile
                   setShowPromocode={setShowPromocode}
                   showPromocode={showPromocode}
                />
                }
              </AnimatePresence>
              </>
              <div className="payment-details" id="paymentDetails">            
                  <>
                  {isFetchingCards ? 
                  <PaymentSkeleton /> : <>
                  {activeSavedCard ? 
                  <div className='saved-card' onClick={(e)=>{
                    if(isMobile){
                      handleInputClick(e)
                    }
                  } }>
                    <div className="payment">{isApplePay === "apple pay" ? paymentMethod("ApplePay") : paymentMethod(activeSavedCard?.CardType)}</div>
                    <div className="desc">
                      <h2 className="card_details card_number">
                      {isApplePay === "apple pay" ? "Apple Pay" : <>
                        {activeSavedCard?.CardType} ...{activeSavedCard?.MaskedNumber.slice(-4)}</>}
                      </h2>
                        {!isMobile && <h3 className="card_details card_expiry">Exp. {formatExpiryDate(activeSavedCard?.Exp)}</h3>}
                      </div>
                    {!isMobile ?
                    <text className='change-text' onClick={handleInputClick}>Change</text>
                    :
                    <ArrowLeft />
                    }
                  </div>
                  :
                  <>
              {isMobile && !activeSavedCard &&
              <div className={`select-payment ${isApplePay == "credit" && 'credit__checked'}`}>
                <label className="radio-wrapper">
                  <span className="radio-label">Credit Card</span>
                  <input
                    type="radio"
                    name="payment"
                    value="credit"
                    checked={isApplePay == "credit" && true}
                    onChange={(event) => handleUpdatePaymentInfo('method', event.target.value)}
                    onClick = {(e) =>setIsApplePay(e.target.value)}
                  />
                  <span className="radio-check"></span>
                </label>
                <div className="img-wrapper">
                  <img src="/images/visa.svg" alt="Visa" />
                  <img src="/images/amex.svg" alt="Amex" />
                  <img src="/images/jcb.svg" alt="jcp" />
                  <img src="/images/discover.svg" alt="Discover" />
                </div>
              </div>}
              { window.ApplePaySession && isMobile && !activeSavedCard && !!!providerSubscriptionSelection?.length && <div className={`select-payment apple-pay ${isApplePay == "apple pay" && 'apple__pay__checked'}`}>
                <label className="radio-wrapper">
                  <span className="radio-label">Apple Pay</span>
                  <input
                    type="radio"
                    name="payment"
                    value="apple pay"
                    checked={isApplePay == "apple pay" && true}
                    onChange={(event) => handleUpdatePaymentInfo('method', event.target.value)}
                    onClick = {(e) =>setIsApplePay(e.target.value)}
                  />
                  <span className="radio-check"></span>
                </label>
                <div className="img-wrapper">
                  <img src="/images/apple-pay.svg" alt="Apple Pay" />
                </div>
              </div>}
                  {
                  (isApplePay !=="apple pay") && (
                  <div className='credit__card__input'>
                  <h2 className='ttitle'>Card information</h2>
                  <div className='error'>
                    {cardInputError === "cardNumber" ? 
                      <p>Invalid card number</p>
                      : cardInputError === "cardExpiry" ?
                      <p>Invalid card expiry</p>
                      : cardInputError === "cardCVC" ?
                      <p>Invalid card CVC</p> : ''
                    }
                  </div>
                  <CreditCardInput
                    onError={({ inputName, error }) => {
                      return setTimeout(() => {
                        setCardInputError((prev) => ({
                          ...prev,
                          [inputName]: error,
                        }));
                      }, 10);
                    }}
                  containerClassName="card__input"
                    cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                      <input
                        {...props}
                        value={paymentInfo.cardNumber}
                        onChange={handleCardNumberChange((event) =>{
                          cardknoxCardError ? removeError() :
                          setCardInputError((prev) => ({
                            ...prev,
                            cardNumber: undefined, 
                          }));
                          handleUpdatePaymentInfo('cardNumber', event.target.value)
                        }
                        )}
                        className={`credit-card-input ${cardInputError?.cardNumber ? 'isInvalid' : ''}`}
                        placeholder="XXXX . XXXX . XXXX . XXXX"
                      />
                    )}
                    cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                      <>
                        <input
                          {...props}
                          value={paymentInfo.expiryDate}
                          onChange={handleCardExpiryChange((event) =>{
                            cardknoxCardError ? removeError() :
                            setCardInputError((prev) => ({
                              ...prev,
                              cardExpiry: undefined, 
                            }));
                            handleUpdatePaymentInfo('expiryDate', event.target.value)
                          }
                          )}
                          className={`credit-card-input ${cardInputError?.cardExpiry ? 'isInvalid' : ''}`}
                          placeholder="MM / YY"
                        />
                      </>
                    )}
                    cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                      <>
                        <input
                          {...props}
                          value={paymentInfo.cvv}
                          onChange={handleCardCVCChange((event) =>{
                            cardknoxCardError ? removeError() :
                            setCardInputError((prev) => ({
                              ...prev,
                              cardCVC: undefined, 
                            }));
                             handleUpdatePaymentInfo('cvv', event.target.value)
                            if(event.target.value.length === 3){
                              zipInputRef.current.focus();
                            }
                          }
                          )}
                          className={`credit-card-input ${cardInputError?.cardCVC ? 'isInvalid' : ''}`}
                          placeholder="CVC"
                        />
                      </>
                    )}
                    cardZipInputRenderer={({ handleCardZipChange, props }) => (
                      <>
                        <input
                          {...props}
                          ref={zipInputRef}
                          value={paymentInfo.zipcode}
                          onBlur={handleCardZipBlur}
                          onChange={handleCardZipChange((event) =>{
                            cardknoxCardError ? removeError() :
                            setCardInputError((prev) => ({
                              ...prev,
                              cardZIP: undefined, 
                            }));
                             handleUpdatePaymentInfo('zipcode', event.target.value)
                          }
                          )}
                          className={`credit-card-input ${cardInputError?.cardZIP ? 'isInvalid' : ''}`}
                          placeholder="ZIP"
                          type='tel'
                        />
                      </>
                    )}
                  />
                  <div className='save_card'>
                    <input type='checkbox' id='save_card' name='save_card' onChange={() => handleSaveCard(isSaveCard)} style={{ display: "none", color: "red" }} />
                    <label htmlFor='save_card' className='checkmark'>
                      {isSaveCard ? 
                       !!!providerSubscriptionSelection?.length ? <CheckboxChecked className={'checked'} /> : <CheckboxDisabled />:
                       <CheckboxBlank className='blank'/>
                      }
                    </label>
                    <label htmlFor='save_card'>Save credit card for later use</label>
                  </div>
                  </div>
                  )
                  }
                  </>
                  }
                  </>
                  }
                  </>
              </div>
            </div>
            </div>
            <div>
              <div className='order__summary'>
              <OrderSummary providerSubscriptionSelection={providerSubscriptionSelection} setIsOrderSummaryLoading={setIsOrderSummaryLoading}/>
              <div className='add_gift_card'>
              {appliedCode?.promoCodeInfo?.code ? (
                <p className='promo_code' onClick={onPromocodeRemove}>Remove promo code or gift card</p>
              ) : (
                (useNutrittCash && !appliedCode?.promoCodeInfo?.code) && (
                  <p className='promo_code' onClick={() => setShowPromocode(true)}>Edit promo or gift card</p>
                )
              )}
              {!appliedCode?.promoCodeInfo?.code && !useNutrittCash && (
                <p className='promo_code' onClick={() => setShowPromocode(true)}>Add promo code or gift card</p>
              )}
            </div>
              <PayButton 
                userCompleteInfo={userCompleteInfo}
                isPromoValid={isPromoValid}
                mealOptions={mealOptions}
                isUserDeliveryError={isUserDeliveryError.some((obj) => obj.status === true)}
                setIsOrderSuccessful={setIsOrderSuccessful}
                setCardInputError={setCardInputError}
                isApplePay={isApplePay}
                setIsOrderFailed={setIsOrderFailed}
                savedCards={savedCards}
                providerSubscriptionSelection={providerSubscriptionSelection}
                setOrderFailError={setOrderFailError}
                handleCreateCustomer={handleCreateCustomer}
                customerId={customerId}
                isSaveCard={isSaveCard}
              />
              </div>
            </div>

          </div>
        </form>
      </div>
    </CheckOrderStyle>
    <PaymentMethod setIsPaymentModalActive={setIsPaymentModalActive} isPaymentModalActive={isPaymentModalActive} savedCards={savedCards} activeSavedCard={activeSavedCard} setSavedCards={setSavedCards} customerId={customerId} setActiveSavedCard={setActiveSavedCard} isApplePay={isApplePay} setIsApplePay={setIsApplePay} isAddPayment={isAddPayment} setIsAddPayment={setIsAddPayment} getSavedCards={getSavedCards} errorModal={errorModal} setErrorModal={setErrorModal} providerSubscriptionSelection={providerSubscriptionSelection} checkCardDetails={checkCardDetails} setCheckCardDetails={setCheckCardDetails}/>
    {isOrderFailed &&
    <FailedModalStyle>
      <MessageModal img={<PaymentFailed />} title='Oh no, payment failed!' description={`There was an error with your payment ${orderFailError || ""} `} firstBtnTitle='Ok' firstBtnClick={() => {setIsOrderFailed(false), setOrderFailError("")}} secondBtnTitle="Contact support" secondBtnClick={() => history.push('/nutritt/support')} />
    </FailedModalStyle>
    }
    </AnimatePresence>
    </>
  );
}
