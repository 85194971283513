import { memo, useEffect, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import classNames from 'classnames';
import { DescriptionWrapper } from './style';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { getStateAbbreviation } from 'utils';
import AddressesList  from 'pages/shop/Addresses/AddressesList';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import { useVisitorContext } from 'context/visitorContext';
import Bummer from '../Bummer';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getEarliestTime } from 'pages/shop/providerTimes';

function Description({ provider, showBummer, isDeliveryAvailable, showArea, setShowArea }) {
  const [lines, setLines] = useState(3);
  const [open, setOpen] = useState(false);
  const { isMobile, user, isWebView, headerAddress, setHeaderAddress } = useAppContext();
  const { setShowBummer } = user ? useShopContext() : useVisitorContext();
  const history = useHistory();

  const { name, minOrderAmount, earliestDelivery, description, mealPrepared, city, state , BusinessProfile} =
    provider || [];
    const abbreviatedState = getStateAbbreviation(state);
    const modifiedCity = city?.length > 8 ? city?.slice(0, 8) + '...' : city;

  const getNextDay = (date = new Date(), kitchen) => {
    let result = {};
    const deliveryDateTime = getEarliestTime(kitchen?.schedule, "delivery");
    const momentEarliestDelivery = moment(deliveryDateTime);

    if (momentEarliestDelivery.isValid()) {
        result.stringDay = momentEarliestDelivery.format('dddd').toLowerCase();
        result.month = momentEarliestDelivery.format('MMMM');
        result.dayNumber = momentEarliestDelivery.date();
        result.year = momentEarliestDelivery.year();
    }
    return `${result?.stringDay}, ${result.month} ${result?.dayNumber}`;
  };

  const features = [
    {
      id: 1,
      icon: <img src="/icons/meal.svg" alt="Meal Icon" />,
      title: `${mealPrepared}+`,
      info: 'Meals prepared',
    },
    {
      id: 2,
      icon: <img src="/icons/place.svg" alt="Place Icon" />,
      title: 'Locally sourced',
      info: modifiedCity ? (abbreviatedState ? `${modifiedCity}, ${abbreviatedState}` : modifiedCity) : abbreviatedState ? abbreviatedState : "",
    },
    {
      id: 3,
      icon: <img src="/icons/verified.svg" alt="Verified Icon" />,
      title: 'Certified',
      info: 'Dietitian approved',
    },
  ];

  useEffect(() => {
    if (user && !isMobile) {
      if (!isDeliveryAvailable) {
        setShowBummer(true);
      }

    }
  }, [isDeliveryAvailable])
  
 

  return (
    <DescriptionWrapper>
      <section className="title-wrapper">
        <div className="title">{name}</div>
        {
          minOrderAmount ? <p className="min-order">Minimum order ${minOrderAmount}</p> : ''
        }
        {!!earliestDelivery && Object.keys(earliestDelivery).length > 0 && (
          <div className="delivery-date-wrapper">
            <img src="/icons/clock.svg" alt="Clock Img" class="clock-img" />
            <div class="delivery-date">
              Delivery by:{' '}
              <div className="date">
                {getNextDay(new Date(), provider)}
              </div>
            </div>
          </div>
        )}
      </section>
      {(user && !isDeliveryAvailable) &&
      <section className='not-delivery'>
          <p>We do not currently provide delivery service to selected address. <span onClick={() => setOpen(true)}>Change address</span></p>
      </section>
      }
      <section className="features-wrapper">
        {features.map((feature) => (
          <div key={feature.id} className={`feature-details feature-${feature.id}`}>
            <div class="feature-title-wrapper">
              {feature.icon}
              <div className="feature-title">{feature.title}</div>
            </div>
            <div className="feature-info">{feature.info}</div>
          </div>
        ))}
      </section>
      {description && (
        <section className="description-text">
          <LinesEllipsis
            text={description}
            maxLine={lines}
            ellipsis={
              <span className="see-more" onClick={() => setLines(lines + 10)}>
                ... See more
              </span>
            }
            basedOn="letters"
            component="span"
            className={classNames({ textMoreLines: lines > 3 })}
          />
          {lines > 3 && (
            <span onClick={() => setLines(3)} className="hide-text">
              Hide
            </span>
          )}
        </section>
      )}
      {open &&
       <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        scrollLocking={false}
        expandOnContentDrag={true}
        snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
        className="addresses-bottomSheet"
      >
        <AddressesList setOpen={setOpen} selectHeaderAddress={(address) => setHeaderAddress(address)} setShowBummer={setShowBummer}/>
      </BottomSheet> }
      {
        showBummer && <Bummer providerName={provider?.name} setShowBummer={setShowBummer} setOpen={setOpen} showArea={showArea} setShowArea={setShowArea} homePage={true} onSecondaryClick={() => history.push('/shop/kitchen-home')} />
      }
    </DescriptionWrapper>
  );
}

export default memo(Description);
