import { useEffect, useRef } from 'react';
import ImageContainer from 'components/elements/imageUploader/ImageUpload';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DragHandler } from 'assets/drag-handle.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon-dark.svg';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ASSET_TYPE } from '../../../../../../constants'

const DraggableImage = ({
  setIsRemoveImage,
  setDeleteIndex,
  image,
  index,
  handleFileChange,
  fileInputRefs,
  setImage,
  mealData,
  setMealData,
  handleFileClick,
  item: imageFile,
  resultArray,
  setResultArray,
  id
}) => {
  const ref = useRef();
  const [{ isDragging, currentOffset }, drag, preview] = useDrag({
    type: 'IMAGES',
    item: { imageFile, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if(dropResult){
        setMealData((prevState) => ({
            ...prevState,
            images: resultArray?.filter(image => image),
        }));
      }
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const [, dropImages] = useDrop({
    accept: 'IMAGES',
    canDrop: () => true,
    hover(item, monitor) {
      if (!ref.current || !item || !imageFile) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardHandler(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = resultArray[dragIndex];
    if (dragItem) {
      setResultArray((prevState) => {
        const coppiedStateArray = [...prevState];
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        return coppiedStateArray;
      });
    }
  };

  dropImages(ref);

  return (
    <div style={{ display: 'flex', alignItems: 'center', opacity: isDragging ? 0.6 : 1 }}>
      <DragHandler className="drag_icon" ref={drag} />
      {imageFile ? (
        <div key={index} className={index === 0 ? "main-image" : "sub_image_upload"} ref={ref}>
          {imageFile && (
            <TrashIcon
              onClick={() => {
                setIsRemoveImage(true), setDeleteIndex(index);
              }}
              className="cross_icon"
            />
          )}
          <img src={imageFile} className={index === 0 ? "main-img" : "sub-img"} />
        </div>
      ) : (
        <div className="sub_image_holder">
          <div className="sub_image_upload" onClick={() => handleFileClick(index)}>
            <img
              src="/icons/add-image.svg"
              className="add_icon"
              alt={`Image ${mealData?.images?.length + index + 1}`}
            />
          </div>
          <input
            type="file"
            accept="image/*" // Restrict to image files
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={fileInputRefs[index]}
          />
        </div>
      )}
      {image && (
        <div className="modal-container">
          <ImageContainer
            image={image}
            setImage={setImage}
            setIsVisible={setImage}
            setNewImgUrl={(image) => {
              setMealData((prevState) => ({
                ...prevState,
                images: [...prevState.images, image],
              }));
            }}
            assetType={ASSET_TYPE?.kitchenMeal}
            id={id}
          />
        </div>
      )}
    </div>
  );
};

export default DraggableImage;
