import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenCardWrapper = styled.div`
  overflow: hidden;
  width: inherit;
  background-color: ${() => theme.colors.secondary};
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  .card-img-wrapper {
    position: relative;
    height: 192px;
    overflow: hidden;
  }

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  label {
    position: absolute;
    top: 11px;
    left: 0;
    height: 25px;
    padding: 0 12px 0 7px;
    background-color: ${() => theme.colors.secondary};
    color: ${() => theme.colors.primaryDefault};
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    .label-text {
      overflow: hidden;
      max-width: 250px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .favorite-wrapper {
    position: absolute;
    top: 11px;
    right: 15px;
    width: 32px;
    height: 32px;
    background-color: ${() => theme.colors.lightBackground};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .provider-wrapper {
    position: absolute;
    bottom: 12px;
    right: 15px;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border: 1.5px solid ${() => theme.colors.secondary};
    border-radius: 50px;
    .provider-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-details {
    padding: 8px 16px 17px;
    color: ${() => theme.colors.deepForestGreen};
    font-family: ${() => theme.typography.fontGilroy};
    .card-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-transform: capitalize;
    }
    .delivery-date-wrapper {
      height: 20px;
      display: flex;
      align-items: center;
      gap: 6px;
      .clock-img {
        width: 16px;
        height: 16px;
      }
      .delivery-date {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .date {
        text-transform: capitalize;
      }
    }
  }
`;
