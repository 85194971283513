import styled from "styled-components";

export const CardOneStyle = styled.div`
    border-radius: 8px;
    background: ${({ theme }) => theme?.colors?.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
    max-width: 350px;
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    overflow: clip;
    padding: ${({ type }) => (type === 2 || type === 3) ? '16px' : ''};
    .card_image{
        border-radius: ${({ type }) => (type === 2 || type === 3) ? '8px' : ''};
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .card_body{
        padding: ${({ type }) => (type === 2 || type === 3) ? '16px 0 0' : '16px'};
        .card_pretitle{
            font-size: ${({ theme }) => theme?.typography?.fontSmall};
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: rgba(0,0,0,0.5);
        }
        .card_title{
            font-size: ${({ theme, type }) => type === 3 ? '24px' : theme?.typography?.fontBase};
            color: ${({ theme }) => theme?.colors?.black};
            font-weight: ${({ type }) => type === 3 ? '700' : '600'};
            line-height: ${({ type }) => type === 3 ? '32px' : '24px'};
            margin-bottom: ${({ type }) => type === 3 ? '0' : '4px'};
        }
        .card_desc{
            font-size: 14px;
            color: rgba(0,0,0,0.5);
            font-weight: 400;
            margin-bottom: 16px;
        }
        .action_btn{
            display: flex;
            gap: 8px;
            justify-content: flex-end;
        }
    }
    .like_share{
        font-size: ${({ theme }) => theme.typography.fontSmall};
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.50);
        display: flex;
        align-items: center;
        gap: 14px;
        .icon{
            display: flex;
            align-items: center;
            gap: 8px;
            .i{
                font-size: 20px;
                color: rgba(0, 0, 0, 0.40);
            }
        }
    }
`

export const CardTwoStyle = styled.div`
    padding: 16px;
    background: var(--white-default, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    max-width: 350px;
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    position: relative;
    overflow: hidden;
    .coverImage{
        position: absolute;
        width: 100%;
        height: 121px;
        left: 0;
        top: 0;
        object-fit: cover;
    }
    .image{
        width: ${({ type }) => type === 7 ? '120px' : '126px'};
        height: ${({ type }) => type === 7 ? '120px' : '126px'};
        border-radius: 50%;
        display: block;
        margin: auto;
        margin-top: ${({ type }) => type === 7 && '44px'};
        overflow: clip;
        position: relative;
        z-index: 9;
        border: ${({ type, theme }) => type === 7 && `4px solid ${theme?.colors?.white}`};
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .card_data{
        margin-top: 16px;
        text-align: center;
        .title{
            font-size: ${({ theme }) => theme?.typography?.fontBase};
            font-weight: 600;
            line-height: 24px;
            color: ${({ theme }) => theme?.colors?.black};
            margin-bottom: 4px;
        }
        .desc{
            font-size: ${({ theme }) => theme?.typography?.fontSmall};
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.50);
        }
    }
    .more{
        width: 20px;
        height: 20px;
        position: absolute;
        right: 16px;
        top: 16px;
        color: rgba(0, 0, 0, 0.40);
    }
`

export const CardThreeStyle = styled.div`
    border-radius: 8px;
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    background: ${({ theme }) => theme?.colors?.white};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
    max-width: ${({ type }) => type === 10 ? '350px' : type === 11 || type === 12 ? '340px' : '730px'};
    width: 100%;
    overflow: hidden;
    display: flex;
    padding: ${({ type }) => type === 9 ? '16px 0 16px 16px' : type === 12 ? '8px 0 8px 8px' : ''};
    .image{
        max-width: ${({ type }) => type === 11 || type === 12 ? '72px' : '250px'};
        width: 100%;
        min-height: ${({ type }) => type === 11 || type === 12 ? '56px' : '160px'};
        height: auto;
        overflow: clip;
        border-radius: ${({ type }) => type === 9 ? '8px' : ''};
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .card_data{
        flex: 1;
        padding: ${({ type }) => type === 11 || type === 12 ? '8px' : '16px'};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title{
            font-size: ${({ theme, type }) => type === 11 || type === 12 ? '14px' : theme?.typography?.fontBase};
            font-weight: 600;
            line-height: ${({ type }) => type === 11 || type === 12 ? '20px' : '24px'};
            margin-bottom: 4px;
        }
        .desc{
            color: rgba(0, 0, 0, 0.50);
            font-size: ${({ theme, type }) => type === 11 || type === 12 ? theme?.typography?.fontSmall : '14px'};
            font-weight: 400;
            line-height: ${({ type }) => type === 11 || type === 12 ? '16px' : '20px'};
            margin-bottom: ${({ type }) => type === 10 ? '20px' : ''};
        }
    }
`