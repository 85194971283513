import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import moment from 'moment';

const PayoutContainer = styled.div`
  .main-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #52c41a;
    max-width: 600px;
    margin: auto;
  }
  .section1 {
    display: flex;
    width: 100%;
  }
  .section1-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 5px solid #52c41a;
    width: 50%;
  }
  .text1 {
    font-size: 22px;
    font-weight: 600;
  }
  .text2 {
    font-size: 22px;
    font-weight: 500;
  }
  .section2 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .cust-table {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .cust-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #52c41a;
    padding: 10px;
    align-items: center;
  }
  .cust-header span {
    width: 50%;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin-left: 10px;
  }
  .cust-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 250px;
    overflow: auto;
  }
  .no-data {
    font-size: 20px;
    text-align: center;
    padding: 4px;
  }
  .cust-body-inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #52c41a;
  }
  .cust-body-inner span {
    width: 50%;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    margin-left: 19px;
  }
  .p_10 {
    padding: 10px;
  }
`;
export default function PayoutsTable({pay, disbursements}) {
  const UpcomingDisbursement = () => {
    return (
      <div className="section2" data-testid="future-disbursements">
        <h1 className="p_10">Upcoming Disbursements</h1>
        <div className="cust-table">
          <div className="cust-header">
            <span>Disbursement Created Date</span>
            <span>Period Start</span>
            <span>Period End</span>
            <span>Amount</span>
            <span>Paid Amount</span>
            <span>Partially Paid</span>
          </div>
          {pay?.currentBalance === 0 ? (
            <div className="no-data">No Data Available</div>
          ) : (
            <div className="cust-body">
              {
                pay?.upcomingDisbursements.map((item, i) => (
                    <div className="cust-body-inner">
                      <span data-testid="future-disbursements-date">{
                        moment(item?.createdAt)
                            .utc()
                            .format('ll')
                      }</span>
                      <span data-testid="future-disbursements-periodstart">
                        {moment(item?.periodStart)
                            .utc()
                            .format('ll')}
                      </span>
                      <span data-testid="future-disbursements-periodend">
                        {moment(item?.periodEnd)
                            .utc()
                            .format('ll')}
                      </span>
                      <span data-testid="future-disbursements-amount">
                        ${(item.total )}
                      </span>
                      <span data-testid="future-disbursements-partial-amount">
                        ${(item.paidAmount)}
                      </span>
                      <span data-testid="future-disbursements-partially-paid">
                      { <span>{item?.paidAmount ? "Yes" : "No"}</span> }
                      </span>
                    </div>
                ))
              }
            </div>
          )}
        </div>
      </div>
    );
  };
  const DisbursementHistory = () => {
    const sortedDisbursements = disbursements.sort((a, b) => moment(b.periodEnd).utc() - moment(a.periodEnd).utc());
    return (
      <div className="section2">
        <h1 className="p_10">History</h1>
        <div className="cust-table">
          <div className="cust-header">
          <span style={{ width: "45%" }}>Id</span>
            <span>Date Paid</span>
            <span style={{marginLeft:"10px"}}>Period Start</span>
            <span>Period End</span>
            <span>Amount</span>
            <span>Paid Amount</span>
            <span>Voided</span>
          </div>
          {!disbursements || disbursements?.length == 0 ? (
            <div className="no-data">No Data Available</div>
          ) : (
            <div className="cust-body">
              {sortedDisbursements.map((d, i) => (
                  <div className="cust-body-inner">
                    <span data-testid="history-disbursements-date">
                        {d.id}
                      </span>
                      <span data-testid="history-disbursements-date">
                        {d.datePaid ? moment(d.datePaid).utc().format('ll') : 'N/A'}
                      </span>
                    <span data-testid="history-disbursements-periodstart">
                        {moment(d.periodStart).utc().format('ll')}
                      </span>
                    <span data-testid="history-disbursements-periodend">
                        {moment(d.periodEnd).utc().format('ll')}
                      </span>

                    <span data-testid="history-disbursements-amount">
                        ${(d.amount)}
                      </span>
                    <span data-testid="history-disbursements-amount">
                      ${(d.paidAmount)}
                    </span>  
                    <span data-testid="history-disbursements-amount">
                      {(d.isVoid === true && d.datePaid) ? "Partially Voided" : d.isVoid === true ? (
                      <p style={{ color: 'red' }}>Yes</p>
                    ) : "No"}
                      </span>
                  </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <PayoutContainer>
      <div className="main-layout">
        <div className="section1">
          <div className="section1-inner">
            <span className="text1">Current Balance</span>
            <span className="text2">
              ${(pay?.currentBalance)}
            </span>
          </div>
          <div className="section1-inner">
            <span className="text1">Total Earned</span>
            <span className="text2">${(pay?.totalEarnings)}</span>
          </div>
        </div>
        <UpcomingDisbursement />
        <DisbursementHistory />
      </div>
    </PayoutContainer>
  );
}
