import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import PantryFooter from 'components/Pantry/PantryFooter';


const BrowseDietPlanstyle = styled.div`
    .card-wrapper{
        display: flex;
        justify-content: center;
        width: 100%;

        .card{
            background: #FFFFFF;
            width: 90%;
            height: 386px;
            margin-top: 23px;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            font-family: 'Roboto';
            font-style: normal;
            align-items: center;
            padding: 0 30px 0 30px;

            span{
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            img{
                width: 192.79px;
                height: 99.91px;
            }
            h2{
                overflow: hidden;
                width: 100%;
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #51635D;
                margin-top: 50px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .des{
                margin-top: 15px;

                div{
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                text-align: center;
                color: #51635D;
                }

            }

            button{
                margin-top: 24px;
                width: 188px;
                height: 40px;
                background: #52C41A;
                border-radius: 21px;
                background: #52C41A;
                border-radius: 21px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #FFFFFF;
            }
        }
    }

    @media(min-width: 992px){
        .card-wrapper{
            padding: 3px 0 53px;
            min-height: 72vh;
            .card{
                width: 43%;
                margin-top: 48px;
                height: 393px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                border-radius: 20px;

                h2{
                    margin-top: 38px;
                }
                button{
                    width: 281px;
                    height: 56px;
                    border-radius: 28px;
                    font-size: 18px; 
                    line-height: 24px;
                }
            }
        }
    }
    @media(min-width: 320px){
        .card-wrapper{
            .card{
                width: 90%;
                margin-top: 48px;
                height: 440px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
                border-radius: 20px;
                .des{
                    margin-top: 15px;
                    div{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 23px;
                        text-align: center;
                        color: #51635D;
                    }
                }
            }
        }
    }
`;

export default function BrowseDietPlan() {
    const {user,isMobile , isVisitor, isGuest} = useAppContext();
    const history = useHistory();
    const isDesktop = !isMobile;
    return (
        <>
        <BrowseDietPlanstyle>
            <div className="card-wrapper">
                <div className="card">
                    <span>
                        <img src="/icons/calendar-card.svg" alt="Calendar Icon" />
                        <h2>Hey {user?.profile?.name.split(" ").slice(0, 1).join(' ')}!</h2>
                        <div className="des">
                            {isGuest ? 
                            <>
                            <div>To track your progress,</div>
                            <div>you need to follow a Diet plan</div> 
                            </>   
                            :
                            isDesktop ? <>
                            <div>Get more out of Nutritt by tracking your progress and goals.</div>
                            <div>Access this feature when you sign up with a coach and follow a diet plan.</div>
                            </>
                            : 
                            <>
                            <div>Get more out of Nutritt by tracking</div>
                            <div>your progress and goals.</div>
                            <div>Access this feature when you sign</div>
                            <div>up with a coach and follow a diet</div>
                            <div>plan.</div>
                            </>
                            }
                        </div>
                        <button onClick={() => history.push(isVisitor?"/shop/all-coach":'/shop/my-coach/mealplans')}>
                            {isGuest ?
                            "Browse diet plans" : "Get a coach"}
                        </button>
                    </span>
                </div>
            </div>
        </BrowseDietPlanstyle>
       {
        isMobile && <div style={{position:'absolute', bottom:'-31px',width:'100%'}}> <PantryFooter shop={false} myCoach={false} progress={true}/> </div>
       }
        </>
    );
}