import { ReactComponent as Visa } from 'assets/shop/payment-visa.svg';
import { ReactComponent as AmericanExpress } from 'assets/shop/payment-american-express.svg';
import { ReactComponent as Discover } from 'assets/shop/payment-discover.svg';
import { ReactComponent as MasterCard } from 'assets/shop/payment-matercard.svg';
import { ReactComponent as ApplePay } from 'assets/shop/appple-pay.svg';

export const paymentMethod = (type) => {

    if(type === "Visa"){
        return <Visa className='icon' />
    }

    if(type === "Amex"){
        return <AmericanExpress className='icon' />
    }

    if(type === "Discover"){
        return <Discover className='icon' />
    }

    if(type === "MasterCard"){
        return <MasterCard className='icon' />
    }
  
    if(type === "ApplePay"){
        return <ApplePay className='icon'/>
    }

}