import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from "components/VisitorLanding/Header";
import PeopleReviews from "components/VisitorLanding/PeopleReviews";
import DietPlanDelivered from "components/VisitorLanding/DietPlanDelivered";
import GetApp from 'components/VisitorLanding/GetApp';
import { theme } from 'shared/theme/theme';
import VisitorFooterDesktop from 'components/VisitorLanding/VisitorFooterDesktop';
import { Welcome } from 'components/VisitorLanding/Welcome';
import { LetsConnect } from 'components/VisitorLanding/LetsConnect';
import { GilroyFontWrapper } from 'shared/theme/style';

const VisitorLandingWrapper = styled.div`
height: 100vh;
background: #fff;
overflow: auto;
&.bodyOverflow {
  overflow: hidden !important;
}

::-webkit-scrollbar {
  width: 0; /* remove scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
  border: none !important; /* optional */
}
.slick-dots {
    li {
      margin: 0;

      button:before {
        color: ${theme.colors.grayLight};
        font-size: 8px;
        opacity: 1;
      }

      &.slick-active button:before {
        color: ${theme.colors.primaryDarker}
      }
    }
  }
`;


export default function VisitorLanding() {
  const [isQrOpen, setIsQrOpen] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <GilroyFontWrapper>

    <VisitorLandingWrapper className="visitor-landing-wrapper">
      <Header setIsQrOpen={setIsQrOpen}/>
      {isQrOpen && <GetApp setIsQrOpen={setIsQrOpen}/>}
      <DietPlanDelivered setIsQrOpen={setIsQrOpen}/>
      <Welcome />
      <PeopleReviews />
      <LetsConnect setIsQrOpen={setIsQrOpen}/>
      <VisitorFooterDesktop />
    </VisitorLandingWrapper>
    </GilroyFontWrapper>
  )
}