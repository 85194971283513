import Countdown from 'react-countdown';
import { useAppContext } from 'context/appContext';
import { Button } from 'components/elements/button/Button';
import { countdownTime } from './Data';
import { HurryOrderWrapper } from './style';

export default function HurryOrder({ earliestDelivery, moveToMeals }) {
  const { isMobile } = useAppContext();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown-time-wrapper">
        {countdownTime({ days, hours, minutes, seconds })?.map((time) => (
          <div className="time-wrapper" key={time.id}>
            <div className="time-container">
              <div className="time">{time.value}</div>
              <div className="label">{time.label}</div>
            </div>
            <div className="separate">:</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <HurryOrderWrapper>
      <div className="order-before-wrapper">
        <div className="main-text">Hurry up, Order before</div>
        <Countdown date={earliestDelivery?.dateTime} renderer={renderer} />
      </div>
      <div className="meals-freshly-wrapper">
        <div className="meals-freshly-title">
          <div className="title">Get your meals freshly delivered by:</div>
          <div className="date">{`${earliestDelivery?.stringDay}, ${
            earliestDelivery?.month
          } ${earliestDelivery?.dayNumber}`}</div>
        </div>
        {isMobile && (
          <div className="order-btn-wrapper">
            <Button title="Order now" type="dark" onClick={moveToMeals} />
          </div>
        )}
      </div>
    </HurryOrderWrapper>
  );
}
