import { memo, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Map } from 'icons/Map.svg';
import { ReactComponent as Cross } from 'assets/shop/cross-black.svg';
import { Button } from 'components/elements/button/Button';
import { useHistory } from 'react-router';
import { useShopContext } from 'context/shopContext';


const BummerStyle = styled.div`
position: fixed;
inset: 0;
background: white;
top: 0;
bottom: 0;
z-index: 100;
.close-icon{
    margin: 12px 0px 12px 16px;
}
.image-container{
    margin-top: 35px;
    text-align-last: center;
}
.desc{
    color: #00092D;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 298px;
    margin: auto;
    span{
        line-height: normal;
        font-weight: 600;
    }
}
.title{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 20px;
    margin-top: 32px;
}
.buttons{
    text-align: center;
    margin-top: 32px;
    display: flex;
    font-family: Gilroy;
    flex-direction: column;
    gap: 12px;
    button{
        max-width: 230px;
        font-family: Gilroy;
    }
}
`


function Bummer({ setOpen, providerName, title, subTitle, showCross = true, showButtons = true, onPrimaryClick, onSecondaryClick, className, filteredKitchenList, removeBodyScroll = true, dangerousTitle }) {
    const history = useHistory();
    const { setShowBummer } = useShopContext();

    useEffect(() => {
        removeBodyScroll && document.body.classList.add('hide-scroll');
        window.sessionStorage.setItem("isBummerModal", "true");
        return () => document.body.classList.remove('hide-scroll');
    }, [])
    const subtitleMessage = subTitle ?? `The <span>"${providerName ? providerName : "Green Leaf"}"</span> kitchen does not deliver to your location. Select an alternative kitchen or update your delivery address.`;
    const handleClick = () => {
        !!onPrimaryClick && onPrimaryClick()
        !!setOpen && setOpen(true)
    }
    const handleSecondaryClick = ()=>{
        !!onSecondaryClick && onSecondaryClick()
        if (typeof setShowBummer === 'function') {
            setShowBummer(false);
        }
    }

    const handleCancel = () => {
        setShowBummer(false);
        document.body.classList.remove('hide-scroll');
    }
    
    return (
        <BummerStyle className={className}>
            {showCross && <Cross className='close-icon' onClick={handleCancel} />}
            <div className='image-container'>
                <Map />
            </div>
            {title && <h1 className='title' >{title}</h1>}
            {dangerousTitle && <h1 className='title' dangerouslySetInnerHTML={{ __html: dangerousTitle }} />}
            <h1 className='desc' dangerouslySetInnerHTML={{ __html: subtitleMessage }} />
            {showButtons && <div className='buttons'>
                <Button type={'mobile_save'} title="Update address" onClick={handleClick} />
                <Button type={'mobile_secondary'} title="Discover another kitchen" onClick={handleSecondaryClick} />
            </div>}
        </BummerStyle>
    )
}

export default memo(Bummer);
