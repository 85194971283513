import { useState, useEffect,useRef ,useMemo,useCallback} from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import ProgressMacroLine from './ProgressMacroLine';
import CircularProgress from 'components/elements/circularProgress/CircularProgress';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FlexColumn = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DailyProgressWrapper = styled.div`
  overflow: hidden;
  min-height: 170px;
  padding: 0px 0 31.85px;
  background: #FFFFFF; 
  &.dailyProgressHeight {
    padding: 14px 0;
    .slick-dots {
      bottom: -28px;
    }
  }
  &.dailyProgressSmallHeight {
    .cals-wrapper {
      transform: scale(0.8);
    }

    .slick-dots {
      bottom: -30px;
    }
  }
  .slide,
  .calc-circle-wrapper {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .cals-wrapper {
    position: absolute;
    color: #51635D;
    ${FlexColumn};
    .cals-count {
      font-size: 30px;
      font-weight: 700;
      line-height: 39px;
      color: #00092D;
      font-family: Gilroy;
    }
    .cals {
      margin: 0px 0 10px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      line-height: normal;
      color: #000A2C;
      font-family: Gilroy;
    }
    .cals-left-wrapper {
      color: #757E89;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      font-family: Gilroy;
      ${FlexColumn};
      span{
        font-family: Gilroy;
      }
      span:last-child {
        line-height: normal;
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
  svg {
    min-width: 120px;
  }
  .slick-list{
    height: 176px !important;
  }
  .slick-dots {
    bottom: -33px;
    height: 32px;
    margin: 0;
    li {
      padding: 3px 0 28.85px;
      margin:0 5px;
      width:9px;
      height:9px;
      button{
        width:9px;
        height:9px;
      }
    }
    li button:before {
      color: #E0E4E8;
      font-size: 9.2px;
      opacity: 1;
      width:9px;
      height:9px;
    }
    li.slick-active button:before {
      color: #00092D;
      opacity: 1;
    }
  }
  .macros-wrapper {
    justify-content: center;
    ${FlexColumn};
  }
  .macros-title {
    color: #000A2C;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
    font-weight: 700;
  }
`;

export default function DailyProgress({ setIsDiaryModal, currentDayinfo, isGetdiaryMealLoading }) {
  const [percent, setPercent] = useState(0);
  const [slide, setSlide] = useState(0);
  const [isSwipe, setIsSwipe] = useState(false);
  const intervalId = useRef(null);
  const {
    user,
    profile: {
      dietMetrics: { calories, macros },
    },
  } = useAppContext();
  const heightCalc = window.innerHeight - 468;
  const currentDayTotalCals = currentDayinfo?.totalCals ?? 0;
  const calsPercent = (currentDayTotalCals / calories) * 100;
  const settings = useMemo(() => ({
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    onSwipe: () => setIsSwipe(!isSwipe),
    arrows: false,
    swipeToSlide: false,
    infinite: true,
    beforeChange: (next) => calsPercent > 0 && percent >= calsPercent && setSlide(next),
  }), [calsPercent, percent, isSwipe]);

  const progress = useCallback(() => {
    if (currentDayinfo?.totalCals >= 0) {
      setPercent((prevState) => prevState + 1);
    }
  }, [currentDayinfo?.totalCals]);

  useEffect(() => {
    if (!isGetdiaryMealLoading) {
      intervalId.current = setInterval(progress, 50);
    }
    return () => clearInterval(intervalId.current);
  }, [currentDayinfo?.totalCals, isGetdiaryMealLoading, progress]);

  useEffect(() => {
    if (percent >= calsPercent || (currentDayinfo?.totalCals < calories && percent === 98) || (currentDayinfo?.totalCals >= calories && percent === 100)) {
      clearInterval(intervalId.current);
    }
  }, [percent]);


  return (
    <>
    <DailyProgressWrapper>
      <div onClick={() => setIsDiaryModal(true)}>
        <Slider {...settings}>
          <div className="slide">
              <div className="calc-circle-wrapper" style={{ flexWrap: 'wrap' }}>
                <div style={{ width: '100%' }}>
                  <CircularProgress percentage={percent}/>
                </div>
                <div className="cals-wrapper">
                  <span className="cals-count">{currentDayTotalCals}</span>
                  <span className="cals">Calories</span>
                  <div className="cals-left-wrapper">
                    <span>
                      {!parseInt(calories) || currentDayinfo?.totalCals >= calories
                        ? 0
                        : Math.round(calories - currentDayTotalCals)}
                    </span>
                    <span>Calories left</span>
                  </div>
                </div>
              </div>
          </div>
          <div className="slide">
              <div className="macros-wrapper" >
                <div className="macros-title">Macros</div>
                <ProgressMacroLine
                  slide={slide}
                  item="Fat"
                  consumedMealItem={currentDayinfo?.consumedInfo?.fat}
                  userDietMacro={macros?.fat}
                  lineColor="#EE786B"
                  weight="g"
                  height='8px'
                />
                <ProgressMacroLine
                  slide={slide}
                  item="Protein"
                  consumedMealItem={currentDayinfo?.consumedInfo?.protein}
                  userDietMacro={macros?.protein}
                  lineColor="#8ACEBF"
                  weight="g"
                  height='8px'
                />
                <ProgressMacroLine
                  slide={slide}
                  item="Carbs"
                  consumedMealItem={currentDayinfo?.consumedInfo?.carbs}
                  userDietMacro={macros?.carbs}
                  lineColor="#2399BF"
                  weight="g"
                  height='8px'
                />
              </div>
          </div>
        </Slider>
      </div>
    </DailyProgressWrapper>
    </>
  );
}