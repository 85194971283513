import styled from 'styled-components';
import Input from 'components/Input';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import Header from 'layout/Header';
import Steps from './Steps';
import { useAppContext } from 'context/appContext';
import { toast } from 'react-toastify';
import Progress from 'components/Progress';

const GetUserInfoWrapper = styled.div`
  width: 90%;
  margin: 23px auto;
  padding: 4px 25px 40px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  label {
    margin-bottom: 7px;
    color: #51635d;
    font-size: 12px;
    font-family: 'Roboto';
    line-height: 14px;
  }

  button {
    height: 44px;
    margin-top: 32px;
    border-radius: 21px;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button: disabled {
    background-color: #e0e3e0;
    color: #949d9b;
    cursor: not-allowed;
  }

  @media(min-width: 992px){
    width: 45% !important;
    height: 400px !important;
    padding: 23px 72px 40px !important;
    padding-bottom: 24px !important;
    border-radius: 20px;
    margin: 23px auto;

    input{
      border: 1px solid #E0E3E0;
      height: 44px;
      border-radius: 6px;
    }

    button{
      height: 56px;
      width: 281px;
      border-radius: 28px;
      margin-top: 32px;
      padding: 18px 99px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    .button-wrapper{
      display: flex !important;
      justify-content: center !important;
    }

    button: disabled {
      background-color: #e0e3e0;
      color: #949d9b;
      cursor: not-allowed;
    }
  }
`;

export default function GetUserInfo() {
  const { nutritionistId, setGuestId, selectedGuestOfferDetails, updateSelectedGuestOfferDetails } = useAppContext();
  const history = useHistory()
  const { isMobile, guestInfo, setGuestInfo } = useAppContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isDisabled, setIsDisabled] = useState(false);

  const [registerGuestUser, { isLoading, error, setError }] = useRequest('guest/register', 'post');

  const emailValidation = (email) => {
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(String(email).toLowerCase());
  };

  const phoneValidate = (phone) => {
    const regEx = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g
    const result = regEx.test(phone)
    if (!result) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (!name.value || !email.value || !phoneNumber.value || isLoading) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [name, email, phoneNumber, isLoading])

  const handleSubmit = async (e) => {
    const oldValues = JSON.stringify({
      name: guestInfo?.name,
      email: guestInfo?.email,
      phoneNumber: guestInfo?.phoneNumber
    });
    const newValues = JSON.stringify({
      name: name.value,
      email: email.value,
      phoneNumber: phoneNumber.value
    });
    e.preventDefault();
    if (!emailValidation(email.value)) {
      toast.error("Please enter a valid email");
    } else if (!phoneValidate(phoneNumber.value)) {
      toast.error('Invalid Phone Number')
    }
    else {
      if(oldValues !== newValues){
        const res = await registerGuestUser({
          body: {
            name: name.value,
            email: email.value,
            phoneNumber: phoneNumber.value,
            nutritionistId: Number(nutritionistId),
          }
        });
        if (res?.success == true) {
          setGuestInfo({ name: name.value, email: email.value, phoneNumber: phoneNumber.value })
          toast.success("Info added successfully");
          setGuestId(res?.user?.id);
          await updateSelectedGuestOfferDetails({ ...selectedGuestOfferDetails, userId: res?.user?.id, name: name?.value, clientEmail: email?.value })
          setTimeout(() => {
            history.push("/booking/payment")
          }, 1000);
        } else {
          toast.error(res.message);
        }
      } else {
        history.push("/booking/payment")
      }
    }
  }
  const previousLink = () => {
    history.goBack()
  }


  useEffect(() => {
    if (guestInfo) {
      setName({ value: guestInfo.name })
      setEmail({ value: guestInfo.email })
      setPhoneNumber({ value: guestInfo.phoneNumber })
    }

  }, [])
  return (
    <>
      <Header
        activeSteps="3"
      />
      {isMobile ?
        <Steps
          title="Info"
          description1="Share who you are and the best way to"
          description2="get a hold of you."
          perviousLink={previousLink}
        /> :
        <Steps
          title="Info"
          description1="Share who you are and the best way to get a hold of you."
          perviousLink={previousLink}

        />
      }
      <GetUserInfoWrapper>
        <Input
          type="text"
          label="Name"
          name="name"
          value={name.value}
          onChange={(e) => setName({ value: e.target.value })}
          required
        />
        {isLoading && <Progress />}
        <Input
          type="email"
          label="Email"
          placeholder="Email address"
          name="email"
          value={email.value}
          onChange={(e) => setEmail({ value: e.target.value })}
          required
        />
        <Input
          type="Number"
          label="Phone Number"
          placeholder="+1 ...."
          name="phoneNumber"
          value={phoneNumber.value}
          onChange={(e) => {
            if (e.target.value.length == 13) return false;
            const onlyDigits = e.target.value.replace(/\D/g, "");
            setPhoneNumber({ value: onlyDigits })
          }}
          required
        />
        <div className='button-wrapper'>
          <button className="primary-btn" onClick={handleSubmit} disabled={isDisabled}>Next</button>
        </div>
      </GetUserInfoWrapper >
    </>
  );
};


