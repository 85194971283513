import { useState, useEffect } from 'react';
import { Line } from 'rc-progress';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const flexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PantryCaloriesStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0px 0 24px;
    background-color: #fff;
    .container {
        width: 90%;
        margin: 0 auto;
    }
    .daily-content {
        color: #0b1c18;
        font-size: 11px;
        span {
            font-size: 16px;
        }
    }
    .rc-progress-line{
        width: 100%;
        background: #E0E3E0;
        border-radius: 4px;
        display: block;
        .rc-progress-line-path{
            transition: stroke-dashoffset 3s linear 0.5s, stroke-dasharray 3s linear 0.5s, stroke 3s linear 0.5s, 1s !important;
        }
    }
    .daily-calories {
        ${flexBetween};
        margin-bottom: 6px;
        span {
            color: #000A2C;
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
        }
        .calculate {
            font-weight: 500;
        }
        
    }
}

  @media (min-width: 992px) {
    padding: 14px;
    border-bottom: 1px solid rgb(246, 245, 248);

    .container {
      width: 100%
    }
  }
`;

export default function PantryCalories({ diaryMeals = 0 }) {
  const {
    profile: {
      dietMetrics: { calories },
    },
    isMobile,
  } = useAppContext();

  return (
    <PantryCaloriesStyle>
      <div className="containerr">
        <div className="daily-progress">            
          <div className="daily-calories">
            <span className="title">{isMobile ? 'Calories' : 'Daily calories'}</span>
            <span className="calculate">
              {Math.round(diaryMeals).toLocaleString()} /{' '}
              {Math.round(calories).toLocaleString()}
            </span>
          </div>
          <Line
            percent={(diaryMeals / calories) * 100}
            strokeColor={Math.round(diaryMeals).toLocaleString() == 0 ? "#E0E3E0" : "#7CBB00"}
            trailColor="#E0E3E0"
            height="6px"
            strokeLinecap="round"
          /> 
        </div>
      </div>
    </PantryCaloriesStyle>
  );
}
