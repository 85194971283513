import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Select from 'components/Select';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';
import DropzoneUploader from 'components/Nutritionist/DropzoneUploader';
import { PLAN_PERIODS } from '../../constants';
import { PLAN_TYPES } from '../../constants';
import UploadWidget from 'components/UploadWidget';

const PlanInformationStyle = styled.div`
  .form-group-wrapper {
    width: 50%;

    &:first-child {
      margin-right: 100px;
    }
  }

  .form-group .form-control {
    display: flex;

    & > input.duration-number {
      width: 60%;
      border-radius: 6px 0 0 6px;
    }

    & > div {
      width: 40%;
      margin-bottom: 0;
    }

    div.selected-value {
      border-radius: 0 6px 6px 0;
      border-left: 0;
    }
  }

  .plan-type + div {
    margin-bottom: 0;
  }

  .tags-favorites-wrapper {
    display: flex;
    flex-wrap: wrap;

    span {
      margin: 0 16px 16px 0;
      padding: 6px 24px;
      color: #b8bcb6;
      font-size: 18px;
      border: 1px solid #d0d3cf;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 5px;
      }

      &.active {
        color: #52c41a;
        border-color: #52c41a;
      }
    }
  }

  .tags-favorites-wrapper .form-group.add-tag-wrapper {
    width: 100%;
    margin-bottom: 0;
    flex-direction: row;

    input {
      width: 80%;
      margin-right: 20px;
    }

    button {
      width: 20%;
      background-color: #52c41a;
      color: #fff;
      font-size: 18px;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    .form-section .form-group textarea {
      margin-bottom: 24px;
      padding: 10px !important;
    }

    .plan-info-type {
      margin-bottom: 24px !important;
    }

    .tags-favorites-wrapper {
      span {
        margin: 0px 10px 10px 0;
        padding: 6px 15px;
        font-size: 16px;
      }

      .form-group.add-tag-wrapper {
        flex-direction: column;

        input {
          width: 100%;
          margin: 10px 0 0;
        }

        button {
          width: 100%;
          height: 44px;
          margin-top: 15px;
          border-radius: 6px;
        }
      }
    }

    .form-section:last-child,
    .program-intro-area {
      margin-bottom: 0 !important;
    }
  }
`;

const period = PLAN_PERIODS;

const tags = [
  { label: 'Vegan', id: 'vegan' },
  { label: 'Kosher', id: 'kosher' },
  { label: 'Vegetarian', id: 'vegetarian' },
  { label: 'High-protein', id: 'high-protein' },
  { label: 'Low-carb', id: 'low-carb' },
  { label: 'Halal', id: 'halal' },
  { label: 'Ovo-vegetarian', id: 'ovo-vegetarian' },
  { label: 'Lacto-vegetarian', id: 'lacto-vegetarian' },
  { label: 'Paleo', id: 'paleo' },
  { label: 'Ketogenic', id: 'ketogenic' },
];
let isLoading = true;
export default function NutritionistPlanInfo({ form, setForm }) {
  const [isSelected, setIsSelected] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [value, setValue] = useState();
  const [planTags, setPlanTags] = useState(tags);

  const setSelectedTag = (selectedTag) => {
    if (isSelected.indexOf(selectedTag) > -1) {
      setIsSelected(isSelected.filter((tag) => tag !== selectedTag));
    } else {
      setIsSelected(prevState => [...prevState, selectedTag]);
    }
  };

  useEffect(() => {
      const existingTags = []
    planTags.map(tag=>{  
      existingTags.push(tag.label.toLocaleLowerCase())
    })
      const filteredTags = form?.tags.filter((item) => !existingTags.includes(item))
      filteredTags.map(tag=> setPlanTags(prevState => [...prevState, { label: tag[0].toUpperCase() + tag.slice(1, tag.length), id: tag.toLowerCase() }]))
  }, [form?.tags])

  useEffect(() => {
    form?.tags?.length && setIsSelected((prev) => [...prev, ...form?.tags]);
  }, [form?.tags.length]);

  const addNewValue = () => {
    if (value) {
      setPlanTags([...planTags, { label: value[0].toUpperCase() + value.slice(1, value.length).toLowerCase(), id: value.toLowerCase() }])
      setSelectedTag(value);
      setIsAdded(false);
    }
  };

  useEffect(() => {
    setForm({ ...form, tags: isSelected.filter((item, index) => isSelected.indexOf(item) === index) });
  }, [isSelected.length])

const handleDuration =(e)=>{
  const { value } = e.target;
  const isNumber = /^\d*\.?\d{0,2}$/.test(value);
  if(form.duration_type == "days" && value>365 && isNumber){
    setForm({ ...form, duration: 365 });
  }else if(form.duration_type == "weeks" && value>53 && isNumber){
    setForm({ ...form, duration: 53 });
  }else if(form.duration_type == "months" && value>24 && isNumber){
    setForm({ ...form, duration: 24 });
  }else if(value.length<10 && isNumber){
    setForm({ ...form, duration: value });
  }  
}
  const { name, introduction } = form;
  return (
    <PlanInformationStyle>
      <form>
        <div className="form-section">
          <div className="form-group-wrapper">
            <div className="form-group">
              <label>Program Name</label>
              <input
                type="text"
                autoComplete="off"
                onChange={(e) => {
                  if (e?.target?.value?.length < 40) {
                    setForm({ ...form, name: e.target.value });
                  }
                }}
                value={name}
              />
            </div>
          </div>
          <div className="form-group-wrapper">
            <div className="form-group">
              <label>Duration</label>
              <div class="form-control">
                <input
                  type="text"
                  autoComplete="off"
                  className="duration-number"
                  onChange={handleDuration}
                  value={form.duration}
                />
                <Select
                  options={PLAN_PERIODS}
                  updateValue={(e) => setForm({ ...form, duration: 0, duration_type: e })}
                  defaultSelected={form.duration_type}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="form-group-wrapper">
            <div className="form-group">
              <label>Program Introduction</label>
              <textarea
                onChange={(e) => {
                  if (e?.target?.value?.length < 160) {
                    setForm({ ...form, introduction: e.target.value });
                  }
                }}
                maxLength={160}
                value={introduction}
              />
            </div>
            <div className="form-group form-img">
              <label>Cover Image</label>
              {/*<DropzoneUploader />*/}
              {form?.image && <img src={form?.image} alt="Diet picture" />}
              <UploadWidget
                setNewImgUrl={(image) => {
                  setForm((prev) => ({ ...prev, image }));
                }}
                img={form?.image}
              />
            </div>
          </div>
          <div className="form-group-wrapper">
            {/* <div className="form-group plan-info-type">
              <label className="plan-type">Type</label>
              <Select
                options={PLAN_TYPES}
                updateValue={(e) => {
                  setForm({ ...form, type: e });
                }}
                defaultSelected={form.type}
              />
            </div> */}
            <div className="form-group">
              <label>
                <span>Tags</span>
                <span>(Favorable)</span>
              </label>
              <div className="tags-favorites-wrapper">
                {planTags.map((tag) => (
                  <span
                    key={tag.id}
                    className={classNames({
                      active: isSelected.indexOf(tag.id) > -1,
                    })}
                    onClick={() => setSelectedTag(tag.id)}
                  >
                    {tag.label}
                  </span>
                ))}
                <span
                  className={classNames({
                    active: isAdded,
                  })}
                  onClick={() => {
                    setIsAdded(!isAdded);
                    setValue('');
                  }}
                >
                  <AiOutlinePlus /> ADD
                </span>
                {isAdded && (
                  <div className="form-group add-tag-wrapper">
                    <input
                      type="text"
                      autoComplete="off"
                      value={value}
                      onChange={(e) => {
                        if (e?.target?.value?.length < 20) {
                          console.info(e?.target?.value?.length < 20, e?.target?.value?.length);
                          setValue(e.target.value.toLowerCase());
                        }
                      }}
                    />
                    <button type="button" onClick={addNewValue}>
                      Add
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </PlanInformationStyle>
  );
}
//triggr build
