import styled from 'styled-components';
import ShopIcon from './../../assets/shop.svg';
import ProgressIcon from './../../assets/progress-icon.svg';
import chartgreenIcon from './../../assets/chart-green.svg';
import { Link, useLocation } from 'react-router-dom';

const NutritionistFooterStyle = styled.div`
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 0px 0px;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  background-color: #fff;
  .nutritionist-footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 70px;
    .nutritionist-footer-item {
      text-align: center;
      &.active {
        h5 {
          color: #52c41a;
        }
      }
      img {
        width: 32px;
        height: 32px;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #afb5b4;
        margin-top: 2px;
      }
    }
  }

  @media (min-width: 992px) {
    position: static;
    z-index: 1;
    width: 35%;
    height: 69px;
    box-shadow: none;

    .nutritionist-footer-inner .nutritionist-footer-item {
      height: 69px;
      padding: 0 24px;
      border-bottom: 2px solid transparent;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }

      h5 {
        font-size: 14px;
        line-height: 16px;
      }

      &.active {
        border-color: #52c41a;

        svg path {
          stroke: #52c41a;
        }
      }
    }
  }
`;
const NutritionistFooter = ({ plans, coaching, reports }) => {
    const { pathname } = useLocation();

  return (
    <NutritionistFooterStyle>
      <div className="nutritionist-footer-inner">
        {(plans || !(pathname.includes("coaching") || pathname.includes("reports") || pathname.includes("home"))) ? (
          <Link to="/nutritionist/meal-plan" className="nutritionist-footer-item active">
            <img src={ShopIcon} alt="Shop icon" style={{ margin: '0px 11px' }} />
            <h5>Plans</h5>
          </Link>
        ) : (
          <Link to="/nutritionist/meal-plan" className="nutritionist-footer-item">
            <img src="/icons/meal-plan1.svg" alt="Shop icon" style={{ margin: '0px 11px' }} />
            <h5>Plans</h5>
          </Link>
        )}
        {coaching || pathname == '/nutritionist/coaching' ? (
          <Link to="/nutritionist/coaching" className="nutritionist-footer-item active">
            <img src={CoachIcon} alt="progress icon" />
            <h5>Coaching</h5>
          </Link>
        ) : (
          <Link to="/nutritionist/coaching" className="nutritionist-footer-item">
            <img src="/icons/coach-ing.svg" alt="progress icon" />
            <h5>Coaching</h5>
          </Link>
        )}
        {reports || pathname == '/nutritionist/reports' || pathname == '/nutritionist/home' ? (
          <Link to="/nutritionist/reports" className="nutritionist-footer-item active">
            <img src={chartgreenIcon}  alt="my coach icon" />
            <h5>Reports</h5>
          </Link>
        ) : (
          <Link to="/nutritionist/reports" className="nutritionist-footer-item">
            <img src="/icons/chart.svg" alt="my coach icon" />
            <h5>Reports</h5>
          </Link>
        )}
      </div>
    </NutritionistFooterStyle>
  );
};

export default NutritionistFooter;