import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from 'context/nutritionistContext';
import Progress from 'components/Progress';
import { useHistory ,useLocation} from 'react-router-dom';
import { useMemo } from 'react';
import NutritionistClientProfile from './Followers/NutritionistClientProfile';
import SubmitButton from 'components/SubmitButton';
import classNames from 'classnames';
import NutritionistEmptyPage from 'components/Nutritionist/NutritionistEmptyPage';
import FollowerList from './Followers/FollowerList';
import { Button } from 'react-bootstrap';


const FollowersStyle = styled.div`
font-family: 'Roboto';
font-style: normal;
.infinite-scroll-component {
    padding: 0px 15px;
    .follower-number{
        padding-bottom: 25px !important;
    }
    @media screen and (max-width: 767px){
        padding: 0px 24px;
    }
}
.followerList23{
    margin-bottom: 46px;
    display: flex;
    padding-top: 22px;
    justify-content: center;
    gap: 42px;
    width: 90%;
    margin: auto;
    overflow: hidden;
    @media screen and (max-width: 1280px){
        gap: 24px;
    }
    .followerListDesktop{ 
        height:89%;
        overflow-y:auto;
        min-width: 320px;
        @media screen and (min-width:1441px){
            max-width: 440px;
        }
        @media screen and (min-width:1281px) and (max-width: 1440px){
            max-width: 400px;
        }
        @media screen and (max-width: 1280px){
            max-width: 325px;
        }
    }
    .followerListDesktop2{ 
        height:94%;
        overflow-y:auto;
        min-width: 320px;
        @media screen and (min-width:1441px){
            max-width: 440px;
        }
        @media screen and (min-width:1281px) and (max-width: 1440px){
            max-width: 400px;
        }
        @media screen and (max-width: 1280px){
            max-width: 325px;
        }
    }
    .followerDetailsDesktop{
        height: calc(100vh - 155px);
        width: 100%;
        min-width: 330px;
        max-width: 620px;
    }
}
.infinite-scroll-component__outerdiv{
    width:100%;
}
.main-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.infinite-div{
     width:100%;
     @media(min-width:992px){
        .infinite-scroll-component{
            padding: 10px 15px 0;
        }
     }
}

.follower{
     display:flex;
     justify-content:center;
     font-weight: 700;
     font-size: 24px;
     line-height: 28px;
     color: #51635D;
     margin-top:24px;
     align-items:center;
     @media screen and (max-width: 767px){
        padding-bottom: 18px;
        margin-top: 70px;
     }
}

.emptyWrapper{
    width: 90%;
    margin-left: 5%;
} 

.copylink{
    display:flex;
    justify-content:center;
    font-weight: 700;
    font-size: 21px;
    line-height: 100px;
    color: #FFFFFF;
    width : 100%;
    height : 90%;
    margin :auto;
    margin-top 8%;
}
      .copy-active {
        background: #52C41A!important;
        font-size: 16px;
        line-height: 16px;
        color: #FFFFFF;
        top 20%;
        padding  : 10px 20px;
    }

.Search{
    display: flex;
    justify-content: center;
    margin: 14px 34px;
    width:85%;
    .input-img{
        width: 100%;
        border-bottom: 1px solid #E0E3E0;
        height: 44px;
        display: flex;
        align-items: center;
        gap:10px;
        padding-left:10px;
        span{
            padding-top:5px;
        }
      input{
        background:none;
        border:none;
        width:100%;
        min-width: 73px;
        ::placeholder{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #AFB5B4;
        }
    }
  }
 }

 .follower-number{
    width:100%;
    padding-left:10px;
    display:flex;
    margin-bottom:10px;
    @media screen and (min-width: 768px){
        margin-bottom: 0px;
    }
    @media screen and (max-width: 767px){
        width: 100%;
        height: 32px;
        padding: 0px 24px !important;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #51635D;
        margin-bottom:0px;
    }
    p{
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #51635D;
        @media screen and (min-width: 768px){
            font-size: 16px;
            line-height: 19px;
        }
    }
 }
    .number{
        background: #52C41A;
        border-radius: 5px;
        padding: 0 5px;
        height:16px;
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        color:#ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left:8px;
    }
 .card{
     height:94px;
     display:flex;
     justify-content:space-between;
     align-items:center;
     background: #FFFFFF;
     border-radius: 6px;
     padding: 15px 22px 15px 12px;
     margin: 0 auto 8px;
     cursor:pointer;
     width:100% !important;
     border: 1px solid #E0E3E0;
     transition: all .3s;
     @media screen and (max-width: 767px){
        height: 84px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        border: 1px solid transparent;
     }
    &:hover, &.active {
        box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.2);
        border: 1px solid transparent;
        border-right: 8px solid #CBEDBA;
        @media screen and (max-width: 767px){
            box-shadow: none;
            border-right: none;
        }
    }
     .img-name{
        display:flex;
        align-items:center;
        gap:14px;
        width: 100%;
        .follower-name{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #51635D;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            flex: 1;

            @media (min-width:990px ) and (max-width: 1280px){

                width: 165px;
                
            }
        }
        .circle{
            position:relative;
            height: 64px;
            span{
                position: absolute;
                left: 6px;
                top: 6px;
            }
            img{
                width:52px;
                height:52px;
                object-fit:cover;
                border-radius:50%;
            }
        }
        .relative{
            position: relative;
            margin-right: 10px;
        }
        .unseenMsg{
            position: absolute;
            right: -9px;
            top: -5px;
            font-weight: 400;
            font-size: 8px;
            line-height: 14px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            text-align: center;
            background: #EB3223;
            border: 0.5px solid #EB3223;
            color: #fff;
            z-index: 2;
        }
     }
     .star{
        img{
            width: 16px;
            height: 16px;
        }
     }

     
 }

 @media(min-width:992px){
    margin-left:300px;
    background:#FFFFFF;
    // padding-bottom: 36px;
    .follower-number{
        padding-left:20px !important;
        font-size: 16px !important;
        line-height: 19px !important;
        background-color: #fff;
        padding: 10px;
        position: sticky;
        top: 0;
        z-index: 9;
    }
     .follower{
         padding-top:35px;
         margin-top: 0;
     }
     .number{
        height:18px !important;
        padding:2px 4px !important;
        font-size: 12px !important;
        line-height: 14px !important;
     }

     .search-invite{
         width:100%;
         display:flex;
         justify-content:flex-end;
         padding-top: 0px;
         padding-right: 0px;
         position: absolute;
         top: 24px;
         right: 24px;
     }
     .Search{
        width:10% !important;
        margin:0 !important;
        .input-img{
             border-bottom:unset !important;
             span{
                img{
                    width:20px;
                    height:20px;
                }
             }
        }
     }

     .invite-button{
         width:136px !important;
         height:40px !important;
         border-radius:31px !important;
         margin:unset !important;
         margin-left: 25px !important;
         p{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
         }
     }
    .infinite-div::-webkit-scrollbar {
        width: 5px;
        scrollbar-color:#AFB5B4;
        border-radius: 3px;
    }
 }

 .desktopView{
    align-items: normal;
    padding-top: 29px;

    .copylink{
        display:flex;
        justify-content:center;
        font-weight: 700;
        font-size: 21px;
        line-height: 100px;
        color: #FFFFFF;
        width : 100%;
        height : 90%;
        margin :auto;
        // margin-top 30%;
    }
          .copy-active {
            background: #52C41A!important;
            font-size: 16px;
            line-height: 16px;
            color: #FFFFFF;
            // top 20%;
            padding  : 10px 20px;
        }
 }
 .followers-div{
    //width:50%;
    height:calc(100vh - 155px);
    min-width: 320px;
    width: 100%;
    @media screen and (min-width:1441px){
        max-width: 440px;
    }
    @media screen and (min-width:1281px) and (max-width: 1440px){
        max-width: 400px;
        hight : 1000px;
    }
    @media screen and (max-width: 1280px){
        max-width: 325px;
    }
 }
 .div1{
    height: ${props=> props?.favoriteList?.length === 1 ? "160px" : props?.favoriteList?.length > 1 && "262px"};
    overflow-y: auto;
    overflow-x: hidden;
 }
 .div2{
    //  max-height:96%;
    overflow-y: auto;
    overflow-x: hidden;
    // height: calc(100% - 37%);
     margin-top: ${props=> props?.favoriteList?.length>0?"20px":""};
    height: ${props=> props?.favoriteList?.length === 1 ? "calc(100% - 180px)" : props?.favoriteList?.length > 1 ? "calc(100% - 282px)" : "100%"};
    padding-top : 0px;
    .card:last-child {
        margin-bottom: 0;
    }
 }
 .margin-styling{
    margin:0px;
 }

    @media(min-width: 1400px) {
        .div1 {
            height: ${props=> props?.favoriteList?.length > 2 && "360px"};
        }
        .div2 {
            height: ${props=> props?.favoriteList?.length > 2 && "calc(100% - 380px)"};
        }
    }

    @media(min-width: 2000px) {
        .div1 {
            height: ${props=> props?.favoriteList?.length === 4 ? "460px": props?.favoriteList?.length > 4 && "560px"};
        }
        .div2 {
            height: ${props=> props?.favoriteList?.length === 4 ? "calc(100% - 480px)" : props?.favoriteList?.length > 4 && "calc(100% - 580px)"};
        }
    }
`

export default function NutritionistFollowers({clientModal, setClientModal}) {
    const [value, setValue] = useState('');
    const [image, setImage] = useState(false);
    const [fade, setFade] = useState(false)
    const history = useHistory();
    const [isCopied,setIsCopied] = useState(false);
    const [index, setIndex] = useState(10)
    const [hasMore, setHasMore] = useState(true)
    const [searchValue,setSearchValue] = useState('')
    const triggerFade = () => {
        setFade(prevState => {
            return !prevState
        })
    }
    const { user, isSuper, isMobile } = useAppContext();
    const { followersList, setFollowersList, isFollowersLoading, nutritionist } = useNutritionistContext();
    const [followerProfile, setFollowerProfile] = useState(null);
    const location=useLocation();
    const userId = new URLSearchParams(location.search).get('userId');
    const [isNotificationRedirect,setIsNotificationRedirect]=useState(false)
    const URL_ROOT = `${window.location.origin}/plan/`;

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const followersToShow = useMemo(() => {
        let favoriteList = [];
        let followers = [];

      if(followersList){
        followersList?.map((user) => {
            if(user?.isFavorite == true){
                favoriteList.push(user)
            } else {
                followers?.push(user);
            }
        });
        if(value){
            const findfavoriteFollower = favoriteList.filter((item) => item.name?.toLowerCase().indexOf(value.toLowerCase()) > -1);
           
             const findFollower = followers.filter((item) => item.name?.toLowerCase().indexOf(value.toLowerCase()) > -1);
             return {
                favoriteList:findfavoriteFollower,followers:findFollower
             }
        } else {
            
            return {favoriteList,followers}
        }
    
      
    }}, [followersList, index, value])


    const followersData = followersList && followersList?.length > 0;
    
    const fetchMoreData = () => {
        setTimeout(() => {
            setIndex(index + 10)
        }, 1500);
    };

    useEffect(() => {
        if (followersList.length > 0 && !isMobile) {
            setFollowerProfile(followersList[0])
            return;
        }
        if (userId) {
            const follower = followersList.find((follower) => follower.userid == userId);
            if (follower) {
                setFollowerProfile(follower);
                setIsNotificationRedirect(true);
                setClientModal(true);
            }
        }

    }, [followersList, userId]);

    let favoriteList = [];
    let followers = [];

    followersList?.map((user) => {
        if(user?.isFavorite == true){
            favoriteList.push(user)
        } else {
            followers?.push(user);
        }
    });

    useEffect(() => {
        if (clientModal && isMobile) {
            document.body.classList.add('bodyFixed');
        } else {
           document.body.classList.remove('bodyFixed')
        }
    }, [clientModal])

    useEffect(() => {
        document.body.classList.remove('hide-scroll');
        setClientModal(false); 
    }, [])


    if (isFollowersLoading) {
        return <Progress />
    }
		const favoriteDataFilter=favoriteList.filter((item) => item.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)?.length
		const followerDataFilter=followers.filter((item) => item.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)?.length
    return (
        <>
                <FollowersStyle favoriteList={favoriteList}>
                    <div className={classNames({mainDiv:true, desktopView: !isMobile})}>
                        {!isMobile && <div className='search-invite'>
                            <div className='Search'>
                                <div className='input-img'>
                                    <span><img src='/icons/search-normal.svg' alt='search' /></span>
                                    <input type='text' placeholder='Search...' onChange={handleChange} />
                                </div>
                            </div>
                            <SubmitButton
                                className="invite-button"
                                onClick={() => history.push('/nutritionist/coaching/invite')}
                            >
                                <img src="/icons/profile-add.svg" alt="Profile Icon" />
                                <p>Send Invite</p>
                            </SubmitButton>
                        </div>}
                            <div className='follower common-title'>
                            <p>Followers</p>
                            {!isMobile && followersData && <span className='number'>{followersList && followersList?.length}</span>}
                        </div>
                        {(isMobile && followersData) &&
                            <>
                                <div className='Search'>
                                    <div className='input-img'>
                                        <span><img src='/icons/search-name.svg' alt='search' /></span>
                                        <input type='text' value={searchValue} placeholder='Search by name' onChange={(e)=>setSearchValue(e.target.value)} />
                                    </div>
                                </div>
                                {
                                    favoriteList?.length>0 && (
                                        <div className='follower-number'>
                                    <p>Favorites</p>
                                   <span className='number'>{favoriteDataFilter}</span>
                                </div>
                                    )
                                }
                            </>
                        }
                        {isMobile ?
                         (followersList?.length) ?
                         <>
                            <FollowerList
                                isMobile={isMobile}
                                followersToShow={favoriteList}
                                fetchMoreData={fetchMoreData}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                hasMore={hasMore}
                                setClientModal={setClientModal}
                                triggerFade={triggerFade}
                                fade={fade}
                                image={image}
                                followerProfile={followerProfile}
                                setFollowerProfile={setFollowerProfile}
                               
                            /> 
                             <div className='follower-number'>
                                    <p className='margin-styling'>Followers</p>
                                    <span className='number'>{followerDataFilter}</span>
                                </div> 
                            <FollowerList
                            isMobile={isMobile}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            followersToShow={followers}
                            fetchMoreData={fetchMoreData}
                            hasMore={hasMore}
                            setClientModal={setClientModal}
                            triggerFade={triggerFade}
                            fade={fade}
                            image={image}
                            setFollowerProfile={setFollowerProfile}
                        />
                        </> : 
                            <div className='emptyWrapper'>
                            <NutritionistEmptyPage
                            img={"/icons/Share-link.svg"}
                            alt={"Share Icon"}
                            title={"No Followers yet"}
                            description={"Share your link so your followers can find"}
                            description3={"and follow you"}
                            buttonText={"Copy your profile link"}
                            redirectLink={() => {
                                navigator.clipboard.writeText(URL_ROOT + nutritionist?.nutritionist?.url).then(()=>{
                                    setIsCopied(true);
                                    setTimeout(()=> {
                                        setIsCopied(false);
                                    },1500);
                                })
                                .catch((err)=>{
                                    console.log(err);
                                });
                              }}
                            />
                            </div> :
                            followersData ?
                            <div className='followerList23'>
                                <div className='followers-div'>
                                {
                                favoriteList?.length>0 && (    
                                <div className='div1'>
                                        <div className='follower-number'>
                                    <p>Favorites</p>
                                   <span className='number'>{followersToShow?.favoriteList?.length}</span>
                                </div>
                                            <FollowerList
                                                isMobile={isMobile}
                                                followersToShow={followersToShow?.favoriteList}
                                                fetchMoreData={fetchMoreData}
                                                searchValue={searchValue}
                                                setSearchValue={setSearchValue}
                                                hasMore={hasMore}
                                                setClientModal={setClientModal}
                                                triggerFade={triggerFade}
                                                fade={fade}
                                                image={image}
                                                followerProfile={followerProfile}
                                                setFollowerProfile={setFollowerProfile}
                                                className={"followerListDesktop"}
                                                setHasMore={setHasMore}
                                                value={value}
                                            /> 
                                </div>
                                 )
                                }
                                <div className='div2'>
                                <div className='follower-number'>
                                    <p>Followers</p>
                                    <span className='number'>{followersToShow?.followers && followersToShow?.followers?.length}</span>
                                </div> 
                            <FollowerList
                            isMobile={isMobile}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            followersToShow={followersToShow?.followers}
                            fetchMoreData={fetchMoreData}
                            hasMore={hasMore}
                            setClientModal={setClientModal}
                            triggerFade={triggerFade}
                            fade={fade}
                            image={image}
                            setFollowerProfile={setFollowerProfile}
                            className={"followerListDesktop2"}
                            setHasMore={setHasMore}
                            value={value}
                        />
                                </div>
                                </div>
                                <FollowerDetails
                                    isMobile={isMobile}
                                    setClientModal={setClientModal}
                                    clientModal={clientModal}
                                    setFollowerProfile={setFollowerProfile}
                                    className={"followerDetailsDesktop"}
                                    followerProfile={followerProfile}
                                />
                            </div>
                            : 
                            <>
                            <NutritionistEmptyPage
                            followersBtn={!followersData?true:false}
                            img={"/icons/Share-icon.svg"}
                            alt={"Share Icon"}
                            title={"No Followers yet"}
                            description={"Share your link so your followers can find and follow you"}
                            buttonText={"Copy your profile link"}
                            redirectLink={() => {
                                navigator.clipboard.writeText(URL_ROOT + nutritionist?.nutritionist?.url).then(()=>{
                                    setIsCopied(true);
                                    setTimeout(()=> {
                                        setIsCopied(false);
                                    },1500);
                                })
                                .catch((err)=>{
                                    console.log(err);
                                });
                            }}
                            />
                  
                            </>
                        }
                        {
                            isCopied && (
                                <div className='copylink'><Button className= 'copy-active' >Link copied.</Button></div>
                            )
                        }
                    </div>
                    {
                 followersToShow?.followers?.length == 0 && value && (
                    <p style={{position:'relative',top:'0',color:'#51635D',textAlign:'center'}}>No Results Found</p>
                    )
                 }
                </FollowersStyle>

            {(clientModal && isMobile) &&
             <NutritionistClientProfile 
             setClientModal={setClientModal} 
             clientModal={clientModal} 
             followerProfile={followerProfile} 
             setFollowerProfile={setFollowerProfile}
             isNotificationRedirect={isNotificationRedirect}
             setIsNotificationRedirect={setIsNotificationRedirect}
             />
            }
            </>
    )
};


function FollowerDetails({ setFollowerProfile, className, clientModal, setClientModal, followerProfile}) {
    setClientModal(true);
    return (
        <div id='scrollableDiv1' className={`${className}`}>
            <NutritionistClientProfile setClientModal={setClientModal} clientModal={clientModal} followerProfile={followerProfile} setFollowerProfile={setFollowerProfile} />
        </div>
    )
}