import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import { GilroyFontWrapper } from 'shared/theme/style';
import RegularInput from 'components/Input/RegularInput';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import MultiScreenStyle from './MultiScreenStyle';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

const CurrentWeightStyle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  .containerr {
    input {
      height: 44px;
      border: 1px solid ${({ theme }) => theme?.colors?.lightGrey};
      padding-left: 16px;
      &:hover,:focus {
        border-color: #00092D !important;
    }
    }
    .suffix {
      right: 16px;
      color: ${({ theme }) => theme?.colors?.lighterGrey};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export default function CurrentWeight({ activeModalStack, setActiveModalStack, nextScreen, modal, isHealthGoalFlow, totalPagesCount }) {
  const [currentWeight, setCurrentWeight] = useState();
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });
  const { healthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [toastIsActive, setToastIsActive] = useState(false);
  const inputRef = useRef();

  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter(item => item?.modal !== modal?.modal)]);
    }, 0);
  };

  useEffect(() => {
      setCurrentWeight(healthGoal?.weight || '');
  }, [])

  const handleChange = (e) => {
    let weight = e?.target?.value;
    const regex = /^(0|[0-9]\d*)(,\d{0,2})?$/;

    if (weight.length <= 3 && regex.test(weight)) {
      setCurrentWeight(Number(weight));
      setUpdatedHealthGoal((prev) => ({
        ...prev,
        weight: Number(weight)
      }))
    } else {
      const validWeight = weight.slice(0, -2);
      setCurrentWeight(Number(validWeight));
    }
  };

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault(); 
    setActiveModalStack((prev) => [...prev.filter(item => item?.modal !== modal?.modal)]);
  }

  useEffect(() => {
    if (String(currentWeight).length === 0) return;
  
    const isWeightLossInvalid = (
      (healthGoal?.selfMealPlan?.healthGoal === "weight-loss" || healthGoal?.selfMealPlan?.healthGoal === 'lose-weight') && 
      Number(currentWeight) < healthGoal?.selfMealPlan?.idealWeight
    );
  
    const isWeightGainInvalid = (
      (healthGoal?.selfMealPlan?.healthGoal === 'weight-gain' || healthGoal?.selfMealPlan?.healthGoal === 'bulkup') && 
      Number(currentWeight) > healthGoal?.selfMealPlan?.idealWeight
    );
  
    if (isWeightLossInvalid || isWeightGainInvalid) {
      const message = isWeightLossInvalid
        ? 'Your current weight cannot be less than your target weight when you are tying to lose weight.'
        : 'Your current weight cannot be greater than your target weight when you are trying to gain weight.';
        
      if (!toastIsActive) {
        toast.error(
          `Error: ${message} Please make sure your current weight is appropriate for your goal.`,
          {
            toastId: 'custom-toast',
            onClose: () => {
              setToastIsActive(false);
            },
          }
        );
      }
      
      setIsSaveDisabled(true);
      setToastIsActive(true);
    } else {
      setIsSaveDisabled(false);
      toast.dismiss('custom-toast');
    }
  }, [currentWeight]);
  
  useEffect(() => {
    if(inputRef?.current){
      inputRef.current.focus();
    }
  }, [inputRef?.current])

  return (
    <motion.div
      initial={animation?.initial}
      animate={{ opacity: 1, x: 0 }}
      exit={animation?.exit}
      transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
    >
      <GilroyFontWrapper>
        <CurrentWeightStyle theme={theme}>
          <MultiScreenStyle>
            <MobileHeader
              text={'Current weight'}
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={!isHealthGoalFlow ? () => setActiveModalStack((prev) => [...prev.filter(item => item?.modal !== modal?.modal)]) : false}
            />
            <div className="containerr">
              <p className="subtitle">
                We are using your weights to calculate <br /> your daily calories
              </p>

              <RegularInput type="tel" onChange={handleChange} value={currentWeight} suffix={'lbs'} pattern="\d*" autoFocus={true} ref={inputRef}/>
            </div>
            <div className="button_holder">
              {isHealthGoalFlow ?
              <Button
                type={'button_combo'}
                iconLeft={<ChevronLeft />}
                iconRight={<ChevronRight />}
                comboLeftTitle={'Back'}
                comboLeftClick={handleBackClick}
                comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}
                comboRightTitle={'Continue'}
                comboRightDisabled={isSaveDisabled || !currentWeight || currentWeight === 0}
              />
              :
              <Button
                type={'mobile_save'}
                title={'Save'}
                onClick={handleSave}
                isDisabled={isSaveDisabled || currentWeight === 0 || currentWeight === healthGoal?.weight}
              />
              }
            </div>
          </MultiScreenStyle>
        </CurrentWeightStyle>
      </GilroyFontWrapper>
    </motion.div>
  );
}
