import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
  padding: 20px 0 32px 17px;
  display: flex;
  justify-content: space-between;

  .kitchen-info-wrapper {
    display: flex;
    gap: 24px;
    flex: 1;
    .provider-image-wrapper {
      display: flex;
      img {
        width: 99px;
        height: 99px;
        object-fit: cover;
        border: 1.5px solid ${() => theme.colors.grayLight};
        border-radius: 58px;
      }
    }
    .kitchen-details {
      h1 {
        color: ${() => theme.colors.primaryDarker};
        font-size: 36px;
        font-weight: 700;
        line-height: 46px;
      }
      .features-wrapper {
        height: 17px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 20px;
      }
      .feature-details {
        display: flex;
        align-items: center;
        gap: 4px;
        img {
          width: 14px;
          height: 14px;
        }
        &:first-child img {
          margin: 0 4px;
        }
      }
      .kitchen-tag .tag,
      .feature-title {
        width: max-content;
        color: ${() => theme.colors.primaryDarker};
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
      }
      .kitchen-tag {
        display: flex;
        align-items: center;
        .divider {
          height: 12px;
          margin-right: 20px;
          border-right: 1px solid ${() => theme.colors.grayLight};
        }
        .tag {
          width: auto;
          overflow: hidden;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;  
        }
      }
    }
    .description-text {
      position: relative;
      overflow: hidden;
      max-width: 600px;
      margin-top: 16px;
      color: ${() => theme.colors.primaryDarker};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      .LinesEllipsis {
        position: relative;
        overflow: hidden;
        width: 100%;
        display: block;
        &.LinesEllipsis--clamped {
          height: 61px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
        }
        .LinesEllipsis-ellipsis {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100px;
          background-color: ${() => theme.colors.secondary};
        }
        &.textMoreLines {
          height: auto;
          display: inline-flex;
        }
      }
      .see-more,
      .hide-text {
        font-weight: 600;
        cursor: pointer;
      }
      .hide-text {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        background-color: ${() => theme.colors.secondary};
        text-align: right;
      }
    }
  }

  @media (max-width: 1439px) {
    padding: 20px 4px 24px 20px;

    .kitchen-info-wrapper .description-text {
      max-width: 100%;
    }
  }

  @media (max-width: 1279px) {
    padding: 24px 0 32px;
    flex-direction: column;
    gap: 16px;

    .kitchen-info-wrapper {
      gap: 20px;
      .provider-image-wrapper img {
        width: 66px;
        height: 66px;
      }
      .kitchen-details .kitchen-tag .tag {
        max-width: 150px;
      }
    }
  }
`;
