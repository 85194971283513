export const socialMedia = [
  {
    id: 1,
    icon: '/icons/Insta.svg',
    link: 'https://www.instagram.com/nutritt_/'
  },
  {
    id: 2,
    icon: '/icons/Linkedin.svg',
    link: 'https://www.linkedin.com/company/nutrittofficial'
  },
  {
    id: 3,
    icon: '/icons/Facebook2.svg',
    link: 'https://www.facebook.com/nutrittofficial'
  },
  {
    id: 4,
    icon: '/icons/Twitter.svg',
    link: '#'
  },
];