import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useOnboardingContext } from '.';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const EditCaloriesStyle = styled.div`
    padding: 0 20px;
    position: fixed;
    z-index: 2;
    inset: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.6);

    &.fadeOut{
        animation: fadeOut 200ms ease forwards;
    }
    .wrap{
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        z-index: 1;
    }
    .edit_calories_popup{
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 24px;
        width: 100%;
        position: relative;
        z-index: 2;
        margin-bottom: 250px;
        .title{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #0B1D17;
        }
        .button{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            background-color: #52C41A;
            border-radius: 31px;
            gap: 8px;
            padding: 12px;
            width: 100%;
            color: #fff;
            &:disabled{
                background-color: #E0E3E0;
                color: #949D9B;
                .icon{
                    color: #949D9B;
                }
            }
        }
        &.animation{
            animation: fadeInOutBottom 400ms ease forwards;
        }
    }
    .form_group{
        position: relative;
        margin-top: 24px;
        margin-bottom: 32px;
        .form_control{
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            padding: 0 16px;
            font-weight: 400;
            font-size: 14px !important;
            line-height: 16px;
            color: #51635D;
        }
        label{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
        }
    }

    @keyframes fadeInOutBottom{
        from{
            transform: translateY(150%);
            opacity: 0;
        }to{
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @keyframes fadeOut{
        from{
            opacity: 1;
        }to{
            opacity: 0;
        }
    }
`

export default function EditCalories({ setIsEditCalories, isAnimation, setIsAnimation, visitorData, newCalories, setNewCalories }) {
    const caloriesRef = useRef();
    const { onboardingUser, setOnboardingUser, setVisitorData } = useOnboardingContext();
    const [updateCalories, { loading }] = useRequest('profile/visitor-onboard/v2', 'post');
    const [visitorOnboard, { isLoading }] = useRequest('profile/visitor-onboard/v2', 'get');
    const { profile, user, setUser, setProfile, isWebView } = useAppContext();
    
    useEffect(() => {
        if (caloriesRef?.current) {
            caloriesRef?.current?.focus();
        }
    }, [caloriesRef?.current])

    const handleChange = (e) => {
        const inputValue = e?.target?.value;
        if (inputValue > 9999) {
            return;
        }
        setNewCalories(inputValue);
    }

    const handleClick = async () => {
        try {
            const updatedOnboardingUser = {
                ...onboardingUser,
                selfMealPlan: {
                    ...onboardingUser.selfMealPlan,
                    customDietMetrics: {
                        ...onboardingUser.selfMealPlan.customDietMetrics,
                        customCalories: Number(newCalories),
                        isActive:true
                    },
                },
            };

            await setOnboardingUser(updatedOnboardingUser);
            const res = await updateCalories({ body: updatedOnboardingUser });
            if (res) {
                setProfile({...profile,dietMetrics:{...profile.dietMetrics,calories:newCalories},selfMealPlan:{...profile.selfMealPlan,customDietMetrics:{customCalories:newCalories}}})
                const data = await visitorOnboard();
                setVisitorData(data?.data)
               setProfile(data?.data?.profile)
                setIsAnimation(false);
                setTimeout(() => {
                    setIsEditCalories(false);
                }, 200);
            }
        } catch (err) {
            if(err!="jwt expired"){
                toast.error(err);
            }
        }
    }

    return (
        <EditCaloriesStyle className={`${!isAnimation ? 'fadeOut' : ''}`}>
            <div className='wrap' onClick={() => { setIsAnimation(false); setTimeout(() => { setIsEditCalories(false) }, 200) }}></div>
            <div className={`edit_calories_popup ${isAnimation ? 'animation' : ''}`} style={{marginBottom: !isWebView ? '250px':''}}>
                <div className='title'>Daily calories</div>
                <div className="form_group">
                    <input id="calories" type="number" pattern="\d*" onChange={handleChange} ref={caloriesRef} value={newCalories} name="" className="form_control" autoFocus />
                    <label for="calories">cal</label>
                </div>
                <button className='button' disabled={!newCalories || (newCalories === (visitorData?.profile?.dietMetrics?.calories || profile?.dietMetrics?.calories))} type="submit" onClick={handleClick}>Update</button>
            </div>
        </EditCaloriesStyle>
    )
}