import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { ReactComponent as Close } from 'assets/close-gray.svg';
import RegularInput from 'components/Input/RegularInput';
import { ReactComponent as Search } from 'assets/search-component.svg';
import Tab from 'components/elements/tab/Tab';
import { Button } from 'components/elements/button/Button';
import DropDown from 'components/elements/drop-down/Dropdown';
import { ReactComponent as Cross } from 'assets/cross-fill.svg';
import PieLegend from 'components/elements/Pie/PieLegend';
import { useRequest } from 'hooks/useRequest';
import { debounce } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { processRestrictionTags } from 'utils';


const IngredientsModalStyle = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 99;
  position: fixed;
  inset: 0;
  background-color: rgba(40, 50, 65, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    max-width: 909px;
    max-height: 749px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
    background: ${({ theme }) => theme?.colors?.white};
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    padding: 24px 24px 20px;
    display: flex;
    flex-direction: column;
  }
  .modal__header {
    .title {
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-size: ${({ theme }) => theme?.typography?.fontLarge};
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      svg {
        display: block;
        width: 20px;
      }
    }
    .modal__search {
      margin-bottom: 8px;
      display: flex;
      gap: 15px;
      align-items: center;
      width: calc(100% - 55px);
      .prefix {
        svg {
          display: block;
        }
      }
      input {
        height: 40px;
        &:hover,
        &:focus {
          border-color: #000a2c !important;
        }
      }
      .search_input {
        flex: 1;
      }
      .search_btn {
        height: 42px;
        line-height: 42px;
        cursor: pointer;
        color: ${({ theme }) => theme?.colors?.blackDefault};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        font-weight: 600;
      }
    }
  }
  .modal__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .nav_item {
      padding-left: 8px;
      padding-right: 8px;
      &:nth-child {
        padding-left: 12px;
        padding-right: 12px;
      }
      &.active {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: ${({ theme }) => theme?.colors?.blackDefault};
          z-index: 9;
        }
        border-radius: 0;
        .text {
          font-weight: 600;
          color: ${({ theme }) => theme?.colors?.blackDefault} !important;
        }
      }
      .text {
        font-size: ${({ theme }) => theme?.typography?.fontBase};
        font-weight: 400;
        color: ${({ theme }) => theme?.colors?.grayDarker};
      }
    }
    .add_ingredients_table {
      flex: 1;
      max-height: 447px;
      margin-top: 7px;
      border-radius: 6px;
      border: 1px solid rgba(50, 64, 84, 0.2);
      overflow: clip;
      display: flex;
      flex-direction: column;
      .table__header {
        background-color: ${({ theme }) => theme?.colors?.grayLightest};
        color: ${({ theme }) => theme?.colors?.grayDarker};
        font-size: ${({ theme }) => theme?.typography?.fontRegular};
        border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
        font-weight: 600;
        line-height: 20px;
        padding: 14px 30px 14px 16px;
      }
      .table__body {
        max-height: 399px;
        height: 100%;
        overflow: auto;
        .table__data {
          color: ${({ theme }) => theme?.colors?.blackDefault};
          font-size: ${({ theme }) => theme?.typography?.fontRegular};
          font-weight: 400;
          line-height: 20px;
          padding: 10px 16px;
          border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
          cursor: pointer;
          &:last-child {
            border-bottom: 0px;
          }
          &:hover {
            background-color: ${({ theme }) => theme?.colors?.grayLightest};
          }
        }
      }
    }
    .add_ingredients_serving {
      display: flex;
      margin-top: 40px;
      .serving_size {
        flex: 1;
        position: relative;
        .cross_icon {
          cursor: pointer;
          position: absolute;
          top: 4px;
          right: 28px;
          z-index: 9;
          width: 20px;
          height: 20px;
        }
        .text {
          color: ${({ theme }) => theme?.colors?.blackDefault};
          font-size: ${({ theme }) => theme?.typography?.fontRegular};
          line-height: 20px;
        }
        .ingredient_name {
          font-weight: 600;
          margin-bottom: 4px;
          font-size: 14px;
        }
        .serving_desc {
          margin-bottom: 20px;
        }
        .size,
        .unit {
          .text {
            width: 95px;
            font-weight: 500;
            color: ${({ theme }) => theme?.colors?.primaryDefault};
          }
          .servings_dropdown {
            flex: 1;
            max-width: 211px;
          }
        }
      }
      .macro_chart {
        width: 406px;
        .pie_legend_wrapper {
          padding: 20px 25px;
        }
      }
    }
  }
  .modal__footer {
    padding-right: 6px;
    padding-top: 20px;
    .action_button {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  }
  .flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .selected-value{
    color: ${({ theme }) => theme?.colors?.blackDefault}!important;
    background: ${({ theme }) => theme?.colors?.white} !important;
  }
  .options-dropdown{
    width: 211px !important;
    bottom: 42px;
  }
  .table__data.selected {
    background-color: #E9EAEC; /* or any other desired color */
  }
  .input-wrapper{
    height:40px;
  }
`;

export const Progress = styled.div`
  flex: 1;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const IngredientsModal = (
  {
   isLoading,
   setIsAddActive, 
   setIngredientData, 
   ingredientData, 
   editedIngredient,
   searchText,
   setSearchText,
   allIngredients, 
   setAllIngredients,
   selectedTabId,
   setSelectedTabId,
   ingredientTabs,
   selectedItemIndex,
   setSelectedItemIndex,
   recentDataList
   }) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [selectedIngredientData, setSelectedIngredientData] = useState(null);
  const [getNutritionFactApi, {isLoading: mealLoading}] = useRequest('meal/nutrition-facts', 'post');
  const [measureURI,setMeasureURI]=useState(null);
  const [quantity,setQuantity]=useState(1);
  const [unit, setUnit] = useState(null);

  const unitOptions = useMemo(() => {
    const measuresUi = selectedIngredientData?.measures?.map((item) => (`${item?.label}-${parseFloat(item?.weight).toFixed(2)}g`));
    return measuresUi;
  }, [selectedIngredientData]);
  

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedInputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setSearchText(capitalizedInputValue);
  };
  const handleQuantityChange = (e) => {
    let value = e.target.value;
    value = value.replace(/,/g, '.');
    value = value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 1) {
      value = parts[0] + '.' + parts[1].slice(0, 2);
    }

    const parsedValue = parseFloat(value);

    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
      setQuantity(parsedValue);
    } else {
      setQuantity('');
    }
  };

   
  const handleBlur = () => {
    if (quantity === '') {
      setQuantity('1');
    }
  };

  
  const getNutritionFact = async (foodId, quantity, measureURI) => {
    try {
      const result = await getNutritionFactApi({ body: { quantity: Number(quantity), measureURI, foodId } });
      setSelectedIngredient(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedGetNutritionFact = useCallback(debounce(getNutritionFact, 300), []);

  const handleIngredientClick = async (data) => {
    setSelectedIngredientData(data);
    setMeasureURI(data?.measures[0]?.uri);
    setUnit(`${data?.measures[0]?.label}-${parseFloat(data?.measures[0]?.weight).toFixed(2)}`)
  };

  const handleRecentIngredientClick = ((selectedItem) => {
    const itemIndex=recentDataList.findIndex((item)=>item.name===selectedItem.name);
    setSelectedItemIndex(itemIndex)
    const { foodId, amount, measureURI, measures, selectedUnit,name } = selectedItem;
    const obj = { food: { foodId,label:name }, measures }
    setSelectedIngredientData(obj);
    setQuantity(amount);
    setMeasureURI(measureURI)
    setUnit(selectedUnit);
  });

  const handleUnitChange = (value) => {
    const [label, weight] = value.split('-');
    const selectedItem = selectedIngredientData?.measures?.find(item =>
       item.label === label
    );
    if (selectedItem) {
      setMeasureURI(selectedItem?.uri);
    }
    setUnit(value)
  };


  const nutrientData = useMemo(() => {
    if (!selectedIngredient?.totalNutrients) return [];
    const totalNutrients = selectedIngredient?.totalNutrients;
    const totalCalories = Math.round(totalNutrients?.ENERC_KCAL?.quantity);
    const nutrientArray = [
      { id: 'FAT', label: 'Fat', value: 0, gram: 'g', color: '#EE786B', caloriesPerGram: 9 },
      { id: 'PROCNT', label: 'Protein', value: 0, gram: 'g', color: '#2399BF', caloriesPerGram: 4 },
      { id: 'CHOCDF', label: 'Carbs', value: 0, gram: 'g', color: '#8ACEBF', caloriesPerGram: 4 },
      { id: 'OTHER', label: 'Other', value: 0, gram: 'g', color: '#C2C9D1',caloriesPerGram: 4 },
    ];

    nutrientArray.forEach(nutrient => {
      const idMap = {
        FAT: 'FAT',
        PROCNT: 'PROCNT',
        CHOCDF: 'CHOCDF',
        OTHER: 'OTHER'
      };
      const macroValue = totalNutrients[idMap[nutrient.id]]?.quantity || 0;
      const totalMacroCalories = Math.floor(macroValue*nutrient.caloriesPerGram);
      const percentVal = totalCalories !== 0 ? (totalMacroCalories / totalCalories) * 100 : 0;

      nutrient.value = parseFloat(totalMacroCalories.toFixed(2));
      nutrient.gram = `${parseFloat(macroValue.toFixed(2))} g`;
      nutrient["id"] = nutrient.label;
      nutrient["macroPercent"] = parseFloat(percentVal.toFixed(2));
    });

    return nutrientArray;
  }, [selectedIngredient]);

  useEffect(() => {
    if (selectedTabId === 3) {
      setSearchText("");
      setAllIngredients([]);
    }
  }, [selectedTabId]);

  useEffect(() => {
    if (quantity && measureURI && selectedIngredientData?.food?.foodId) {
      debouncedGetNutritionFact(selectedIngredientData?.food?.foodId, quantity, measureURI)
    }
  }, [quantity, measureURI, selectedIngredientData?.food?.foodId, selectedIngredientData?.food?.label]);

  const handleAddIngredient = () => {
    if (!selectedIngredient || !selectedIngredientData) return;
    const macros = { ...selectedIngredient, foodId: selectedIngredientData?.food?.foodId, measures: selectedIngredientData};
    const allergies = processRestrictionTags(selectedIngredient?.healthLabels, selectedIngredient?.cautions)
    const newIngredient = {
      name: selectedIngredientData?.food?.label,
      amount: quantity,
      unit: unit,
      calories: selectedIngredient?.calories,
      weight: selectedIngredient?.totalWeight,
      allergies,
      macros,
    };
    if (editedIngredient) {
      newIngredient.id = editedIngredient?.id;
      const indexOfEditedIngredient = ingredientData.findIndex(item => item.name === editedIngredient.name);
    
      if (indexOfEditedIngredient !== -1) {
        const isNameExisting = ingredientData.some(
          existingItem => existingItem.name === newIngredient.name
        );
        const updatedData = [...ingredientData];
        if (isNameExisting) {
          const indexOfExistingItem = updatedData.findIndex(
            item => item.name === newIngredient.name
          );
          updatedData[indexOfExistingItem] = newIngredient;
        } else {
          updatedData[indexOfEditedIngredient] = newIngredient;
        }
        setIngredientData(updatedData);
      }
    }
    
     else {
      if (!ingredientData.some(item => item?.name === newIngredient?.name)) {
        setIngredientData(prevData => [...prevData, { ...newIngredient, isDeleted: false }]);
      } else {
        const indexOfMatchingItem = ingredientData.findIndex(item => item?.name === newIngredient?.name);
        if (indexOfMatchingItem !== -1) {
          const updatedIngredientData = ingredientData.map((item, index) => {
            if (index === indexOfMatchingItem) {
              return { ...newIngredient, isDeleted: false };
            }
            return item;
          });
          setIngredientData(updatedIngredientData);
        }
      }
            
    }
    
    setIsAddActive(false);
    setSearchText('');
    setAllIngredients([]);
  };

  useEffect(() => {
    const trimmedValue = editedIngredient?.unit?.substring(0, editedIngredient?.unit?.indexOf('-'));
    const filteredValue = editedIngredient?.macros?.measures?.measures?.find(item => item.label === trimmedValue);
    if (editedIngredient) {
      setSelectedIngredient(true);
      setMeasureURI(filteredValue?.uri)
      setSearchText(editedIngredient?.name)
      setQuantity(editedIngredient?.amount);
      setSelectedIngredientData(editedIngredient?.macros?.measures);
      handleUnitChange(editedIngredient?.unit);
      getNutritionFact(editedIngredient?.macros?.foodId,editedIngredient?.amount,editedIngredient?.macros?.ingredients?.[0]?.parsed?.[0]?.measureURI )
    }
  }, [editedIngredient]);

  const isAddButtonDisabled = useMemo(() => {
    const isValidQuantity = quantity && quantity > 0;
    return !selectedIngredient || !selectedIngredientData || !isValidQuantity || mealLoading;
  }, [quantity, selectedIngredient, selectedIngredientData, mealLoading]);
  return (
    <>
      <IngredientsModalStyle theme={theme}>
        <div className="modal">
          <div className="modal__header">
            <h2 className="title">
              Add ingredient to Recipe <Close onClick={() => {setIsAddActive(false), setSearchText(''), setAllIngredients([])}} />
            </h2>
            <div className="modal__search">
              <RegularInput
                className="search_input"
                placeholder={'Search all ingredients...'}
                value={searchText}
                onChange={handleInputChange}
                prefix={<Search />}
              />
              <div className="search_btn">search</div>
            </div>
          </div>
          <div className="modal__body">
            <Tab
              type="horizontal"
              navItems={ingredientTabs}
              selectedTabId={selectedTabId}
              setSelectedTabId={setSelectedTabId}
              selectedTabBgColor={'rgba(0,0,0,0)'}
            />
            <div className="add_ingredients_table">
              <div className="table__header">Description</div>
              {isLoading ?
        <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> 
              : (
                <div className="table__body">
                  {mealLoading && (
                    <Progress>
                      <div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div>
                    </Progress>
                  )}
                  
                  {selectedTabId === 0 && allIngredients.map((hint, index) => (
                    <div
                      key={index}
                      className={`table__data  ${selectedItemIndex === index ? 'selected' : ''}`}
                      onClick={() => {handleIngredientClick(hint); setSelectedItemIndex(index)}}
                    >
                      {hint.food.label}
                    </div>
                  ))}
                  
                  {selectedTabId === 1 && allIngredients.filter(hint => hint.food.category === "Generic foods").map((hint, index) => (
                    <div
                      key={index}
                      className={`table__data  ${selectedItemIndex === index ? 'selected' : ''}`}
                      onClick={() => {handleIngredientClick(hint); setSelectedItemIndex(index)}}
                    >
                      {hint.food.label}
                    </div>
                  ))}
                  
                  {selectedTabId === 2 && allIngredients.filter(hint => hint.food.category === "Packaged foods").map((hint, index) => (
                    <div
                      key={index}
                      className={`table__data  ${selectedItemIndex === index ? 'selected' : ''}`}
                      onClick={() => {handleIngredientClick(hint); setSelectedItemIndex(index)}}
                    >
                      {hint.food.label}
                    </div>
                  ))}
                  
                  {selectedTabId !== 0 && selectedTabId !== 1 && selectedTabId !== 2 && recentDataList?.map((hint, index) => (
                    <div
                      key={index}
                      className={`table__data ${selectedItemIndex === index ? 'selected' : ''}`}
                      onClick={() => handleRecentIngredientClick(hint)}
                    >
                      {hint.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {selectedIngredient && (
              <div className="add_ingredients_serving">
                <div className="serving_size">
                  <Cross className="cross_icon" onClick={() => setSelectedIngredient('')} />
                  <h3 className="ingredient_name">{selectedIngredientData?.food?.label}</h3>
                  <p className="serving_desc text">Select the serving size and diary group</p>
                  <div className="flex size align-center">
                    <div className="text">Size</div>
                    <RegularInput name="serving" value={quantity} onChange={handleQuantityChange} onBlur={handleBlur} type={"number"}/>
                  </div>
                  <div className="flex unit align-center">
                    <div className="text">Unit</div>
                    <div className="servings_dropdown">
                    <DropDown
                      option={unitOptions}
                      value={unit}
                      onChange={(value) => handleUnitChange(value)}
                      width={"211px"}
                    />
                    </div>
                  </div>
                </div>
                <div className="macro_chart">
                  <PieLegend
                    width={115}
                    height={115}
                    titleNumber={Math.round(selectedIngredient?.totalNutrients?.ENERC_KCAL?.quantity) || 0}
                    titleText="Cal"
                    data={nutrientData}
                    marginTop={5}
                    marginRight={5}
                    marginBottom={5}
                    marginLeft={5}
                    innerRadius={0.7}
                    OuterRadiusHover={5}
                    colors={['#EE786B', '#2399BF', '#8ACEBF', '#C2C9D1']}
                    legendLabelWidth={63}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="modal__footer">
            <div className="action_button">
              <Button type={'secondary'} title="Cancel" onClick={() => setIsAddActive(false)} />
              <Button type={'dark'} title={editedIngredient  ? "Update" : "Add ingredient"} onClick={handleAddIngredient} isDisabled={isAddButtonDisabled} />
            </div>
          </div>
        </div>
      </IngredientsModalStyle>
    </>
  );
};