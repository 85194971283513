import { useAppContext } from 'context/appContext';

import styled from 'styled-components';

const flexColumnCenter = `
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  margin: 15px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 100%;
  }

  .total {
    ${flexColumnCenter}
  }

  .type {
    color: var(--lightGray);
    font-size: 16px;
  }

  .value {
    color: #0b1c18;
    font-size: 16px;
    font-weight: 500;
  }

  .details {
    margin-left: 20px;
    padding-left: 30px;
    border-left: 2px solid #cad6e2;
    ${flexColumnCenter};

    div {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      :last-child {
        margin-bottom: 0;
      }
    }

    .type {
      width: 80px;
      text-align: left;
    }
  }

  span {
    text-align: center;
  }
`;

export default function CurrentPlanInfo() {
  const { profile: { dietMetrics: { calories, macros: { carbs, fat, protein } = {} } = {} } = {} } =
    useAppContext();

  return (
    <Container>
      <div className="total">
        <span className="value">{Math.round(calories)}</span>
        <span className="type">Cal</span>
      </div>
      <div className="details">
        <div>
          <span className="type">Protein:</span>
          <span className="value">{`${Math.round(protein)}g`}</span>
        </div>
        <div>
          <span className="type">Fat:</span>
          <span className="value">{`${Math.round(fat)}g`}</span>
        </div>
        <div>
          <span className="type">Carbs:</span>
          <span className="value">{`${Math.round(carbs)}g`}</span>
        </div>
      </div>
    </Container>
  );
}
