import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/close-gray.svg';
import { theme } from 'shared/theme/theme';

const IngredientsModalStyle = styled.div`
  width: 100vw;
  height: 100%;
  z-index: 99;
  position: fixed;
  inset: 0;
  background-color: rgba(40, 50, 65, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
    background: ${({ theme }) => theme?.colors?.white};
    padding: 24px 28px 0px 43px;
    display: flex;
    flex-direction: column;
    max-height: 1000px;
    width: 851px;
  }
  .modal__header {
    .title {
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-size: ${({ theme }) => theme?.typography?.fontLarge};
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 29px;
      svg {
        display: block;
        width: 20px;
      }
    }
  }
  .container {
    padding: 0px 67px 0px 52px;
    height: calc(100vh - 225px);
    overflow-x: hidden;
  }
  .custom-scrollbar {
    width: 100%; /* Adjust the width as needed */
    overflow-y: auto;
    flex-shrink: 0;
    position: relative;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #F0F2F4;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #C2C9D1;
    border-radius: 3px;
  }
  
  /* For Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #C2C9D1 #F0F2F4;
  }
  
  .nutrition_fact {
    border: 8px solid #272727;
    padding: 24px 24px 0;
    width: 661px;
    margin-bottom: 20px;
    .heading {
      color: #272727;
      font-family: Helvetica;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-bottom: 1px solid #272727;
      flex: 1;
    }
    label {
      color: #272727;
      font-family: Helvetica;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    text {
      color: #272727;
      font-family: Helvetica;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .flex {
      display: flex;
      place-content: space-between;
      align-items: center;
    }
    .border {
      height: 20px;
      background: #272727;
    }
    .calories {
      display: flex;
      flex-flow: column;
    }
    .paddingleft {
      padding-left: 20px;
    }
    .padding {
      padding-bottom: 12px;
      padding-top: 12px;
    }
    .gap {
      display: flex;
      gap: 8px;
    }
    .topBorder {
      border-top: 1px solid black;
      height: 34px;
    }
  }
`;

const NutritionModel = ({setShowNutritonModel,nutritionLabel}) => {
  console.log(nutritionLabel,'nutritionLabel')
  return (
    <IngredientsModalStyle theme={theme}>
      <div className="modal">
        <div className="modal__header">
          <h2 className="title">
            Nutrition fact label <Close style={{cursor:"pointer"}} onClick={() => setShowNutritonModel(false)}/>
          </h2>
        </div>
        <div className="container custom-scrollbar">
          <div className="nutrition_fact">
            <h3 className="heading">Nutrition Facts</h3>
            <div className="flex" style={{ padding: '12px 0px 0' }}>
            <label>1 Servings per container</label>
            </div>
            <div className="flex padding" style={{paddingTop:"0px"}}>
              <text>Serving size</text>
              <text>{nutritionLabel?.weight}g</text>
            </div>
            <div className="border" />
            <div className="flex" style={{ padding: '12px 0' }}>
              <div className="calories">
                <text>Amount per serving</text>
                <text style={{fontSize: "32px"}}>Calories</text>
              </div>
              <text style={{ fontSize: '48px' }}>{nutritionLabel?.totalCalories}</text>
            </div>
            <div style={{ height: '8px', background: 'black' }} />
            <div className='flex' style={{placeContent: "flex-end",margin: "7px 0 12px"}}>
            <text>% Daily Value*</text>
              </div>
            <div className="flex topBorder">
              <div className="gap">
                <text>Total Fat</text>
                <label>{nutritionLabel?.fat?.grams}g</label>
              </div>
              <text>{nutritionLabel?.fat?.percentage}%</text>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Saturated Fat</label>
                <label>{nutritionLabel?.fat?.saturatedFatGrams}g</label>
              </div>
              <text>{nutritionLabel?.fat?.saturatedFatPercent}%</text>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Trans Fat</label>
                <label>{nutritionLabel?.fat?.transFatGrams}g</label>
              </div>
            </div>
            <div className="flex topBorder">
              <div className="gap">
                <text>Cholesterol</text>
                <label>{nutritionLabel?.cholesterol?.mg}mg</label>
              </div>
              <text>{nutritionLabel?.cholesterol?.percentage}%</text>
            </div>
            <div className="flex topBorder">
              <div className="gap">
                <text>Sodium</text>
                <label>{nutritionLabel?.sodium?.mg}mg</label>
              </div>
              <text>{nutritionLabel?.sodium?.percentage}%</text>
            </div>
            <div className="flex topBorder">
              <div className="gap">
                <text>Total Carbohydrate</text>
                <label>{nutritionLabel?.carbohydrates?.grams}g</label>
              </div>
              <text>{nutritionLabel?.carbohydrates?.percentage}%</text>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Dietary Fiber</label>
                <label>{nutritionLabel?.carbohydrates?.fiberGrams}g</label>
              </div>
              <text>{nutritionLabel?.carbohydrates?.fiberPercent}%</text>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Total Sugars</label>
                <label>{nutritionLabel?.carbohydrates?.sugarGrams}g</label>
              </div>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Includes added sugars</label>
                <label>{nutritionLabel?.carbohydrates?.addedSugarGrams}g</label>
              </div>
              <text>{nutritionLabel?.carbohydrates?.addedSugarPercent}%</text>
            </div>
            <div className="flex topBorder">
              <div className="gap">
                <text>Protein</text>
                <label>{nutritionLabel?.protein?.grams}g</label>
              </div>
              <text>{nutritionLabel?.protein?.percentage}%</text>
            </div>
            <div className="border" />
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Vitamin D</label>
                <label>{nutritionLabel?.protein?.vitaminDMicrograms}µg</label>
              </div>
              <text>{nutritionLabel?.protein?.vitaminDPercentage}%</text>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Calcium</label>
                <label>{nutritionLabel?.protein?.calciumMg}mg</label>
              </div>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Iron</label>
                <label>{nutritionLabel?.protein?.ironMg}mg</label>
              </div>
              <text>{nutritionLabel?.protein?.ironPercent}%</text>
            </div>
            <div className="flex paddingleft topBorder">
              <div className="gap">
                <label>Potassium</label>
                <label>{nutritionLabel?.protein?.potassiumMg}mg</label>
              </div>
              <text>{nutritionLabel?.protein?.potassiumPercent}%</text>
            </div>
            <div style={{ height: '8px', background: 'black' }} />
            <div className="flex topBorder">
              <label>*Percent Daily values are based on a 2,000 calorie diet</label>
            </div>
          </div>
        </div>
      </div>
    </IngredientsModalStyle>
  );
};

export default NutritionModel;
