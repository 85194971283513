export const profile = {
  activity: 1,
  address: null,
  age: 45,
  bio: null,
  birthday: '2021-11-18T00:00:00.000Z',
  complete: false,
  createdAt: '2021-11-18T13:15:37.199Z',
  dietGoal: null,
  dietMetrics: {
    calories: 1410,
    macros: { fat: 67, protein: 123, carbs: 78 },
  },
  dietTemplate: {
    BMR_formula: 'mifflinJeor',
    PAL: null,
    PAL_formula: 'PAL',
    carbs_percent: 22,
    createdAt: '2021-11-18T13:26:06.271Z',
    duration: 6,
    duration_type: 'weeks',
    fat_percent: 43,
    food_to_avoid: ['SOY', 'TREE NUTS', 'CELERY', 'MOLLUSCS'],
    goal: 0,
    goal_type: 'number',
    id: 4,
    image:
      'http://res.cloudinary.com/nutritt/image/upload/v1642654256/production/download1_b3gugr.jpg',
    introduction: 'test program introduction',
    isDeleted: false,
    name: 'lose weight in 10 days',
    nutritionistId: 1,
    protein_percent: 35,
    tags: ['vegan', 'low-carb', 'halal'],
    tips: 'sdds skjdsk jksdkjs sbdhsbdj sjndjksbd sdjksbdkjb sjbdkjsbkjd sbdjksbkjd jkjsjdnjkd skjdhkshd jjshds sbdhxcgggg',
    type: 'lose-weight',
    updatedAt: '2022-03-31T14:20:15.744Z',
  },
  dietTemplateId: 4,
  gender: 'm',
  heightFeet: 6,
  heightInches: 5,
  id: 1,
  img: 'https://nutritt-app-bucket.s3.amazonaws.com/profile-pic/1_1647770868461_profilePic.jpeg',
  isDeleted: false,
  name: 'Jake Johnes',
  phoneNumber: null,
  updatedAt: '2022-04-08T09:40:58.526Z',
  userId: 1,
  weight: 38,
};

export const pantryMealsDummy = [
  {
    components: null,
    data: {
      calories: 94,
      carbs: 15.2,
      createdAt: '2021-11-18T13:15:37.268Z',
      description: 'Stir-Fry Chicken, Cauliflower Rice',
      fat: 2.08,
      id: 4,
      images: [],
      img: 'https://www.asweetpeachef.com/wp-content/uploads/2016/07/chicken-cauliflower-fried-rice-4.jpg',
      isDeleted: false,
      keywords: [],
      name: 'Stir-Fry Chicken',
      price: 490,
      protein: 5.2,
      providerId: 1,
      restrictions: ['Peanuts', 'Eggs', 'Soy', 'Dairy', 'Mustard', 'Celery'],
      tags: ['Natural', 'Halal', 'Vegetarian', 'Organic', 'Vegan'],
      updatedAt: '2021-11-18T13:15:37.268Z',
      weight: 5,
    },
    dateOrdered: '2022-02-04',
    id: 208,
    mainOrderId: 155,
    providerId: 1,
    quantity: 3,
    string_agg: 'completed, accepted',
  },
  {
    components: null,
    data: {
      calories: 548.6,
      carbs: 2.8,
      createdAt: '2021-11-18T13:15:37.268Z',
      description: 'Salmon, Brown Rice, and Mixed Vegetables',
      fat: 35.4,
      id: 9,
      images: [],
      img: 'https://res.cloudinary.com/hksqkdlah/image/upload/43111-sfs-brown-rice-bowl-vegetables-salmon-23.jpg',
      keywords: [],
      name: 'Salmon',
      price: 499,
      protein: 53.9,
      providerId: 1,
      restrictions: ['Mustard', 'Celery', 'Eggs'],
      tags: [],
      updatedAt: '2021-12-15T11:49:01.889Z',
      weight: 5,
    },
    dateOrdered: '2022-01-07',
    id: 88,
    mainOrderId: 69,
    providerId: 1,
    quantity: 6,
    string_agg: 'completed, accepted',
  },
  {
    components: null,
    data: {
      calories: 242,
      carbs: 799,
      createdAt: '2021-11-18T13:15:37.269Z',
      description: 'Turkey, Brown Rice, Mixed Vegetables',
      fat: 4.5,
      id: 17,
      images: [],
      img: 'https://i.pinimg.com/originals/38/b2/a4/38b2a44a9bfe295f60394ef8e36f7898.jpg',
      keywords: [],
      name: 'Turkey',
      price: 900,
      protein: 23.8,
      providerId: 3,
      restrictions: [],
      tags: ['Natural', 'Halal', 'Vegetarian', 'Organic', 'Vegan'],
      updatedAt: '2021-11-18T13:15:37.269Z',
      weight: 5,
    },
    dateOrdered: '2022-01-07',
    id: 89,
    mainOrderId: 70,
    providerId: 3,
    quantity: 12,
    string_agg: 'completed, accepted',
  },
  {
    components: null,
    data: {
      calories: 242,
      carbs: 799,
      createdAt: '2021-11-18T13:15:37.269Z',
      description: 'Turkey, Brown Rice, Mixed Vegetables',
      fat: 4.5,
      id: 17,
      images: [],
      img: 'https://i.pinimg.com/originals/38/b2/a4/38b2a44a9bfe295f60394ef8e36f7898.jpg',
      keywords: [],
      name: 'Turkey',
      price: 900,
      protein: 23.8,
      providerId: 3,
      restrictions: [],
      tags: [],
      updatedAt: '2021-11-18T13:15:37.269Z',
      weight: 5,
    },
    dateOrdered: '2022-01-02',
    id: 80,
    mainOrderId: 65,
    providerId: 3,
    quantity: 5,
    string_agg: 'completed, accepted',
  },
  {
    components: null,
    data: {
      calories: 425,
      carbs: 12.8,
      createdAt: '2021-11-18T13:15:37.268Z',
      description:
        'Chicken Portabella Meal with Peppers & Onions Chicken Portabella Meal with Peppers & Onions Chicken Portabella Meal with Peppers & Onions Chicken Portabella Meadls',
      fat: 9.9,
      id: 6,
      images: [],
      img: 'https://i2.wp.com/happyhealthymama.com/wp-content/uploads/2019/10/Skillet-Chicken-with-Mushrooms-and-Peppers.jpg?resize=683%2C1024&ssl=1',
      isDeleted: false,
      keywords: [],
      name: 'Chicken Portobello',
      price: 2000,
      protein: 72.3,
      providerId: 1,
      restrictions: ['Peanuts', 'Eggs', 'Soy', 'Dairy', 'Mustard', 'Celery'],
      tags: ['Natural', 'Halal', 'Vegetarian', 'Organic', 'Vegan'],
      updatedAt: '2022-02-26T09:09:45.927Z',
      weight: 5,
    },
    dateOrdered: '2022-03-22',
    id: 591,
    mainOrderId: 403,
    providerId: 1,
    quantity: 2,
    string_agg: 'completed, accepted',
  },
  {
    components: null,
    data: {
      calories: 94,
      carbs: 15.2,
      createdAt: '2021-11-18T13:15:37.268Z',
      description: 'Stir-Fry Chicken, Cauliflower Rice',
      fat: 2.08,
      id: 4,
      images: [],
      img: 'https://www.asweetpeachef.com/wp-content/uploads/2016/07/chicken-cauliflower-fried-rice-4.jpg',
      isDeleted: false,
      keywords: [],
      name: 'Stir-Fry Chicken',
      price: 490,
      protein: 5.2,
      providerId: 1,
      restrictions: [],
      tags: ['Natural', 'Halal', 'Vegetarian', 'Organic', 'Vegan'],
      updatedAt: '2021-11-18T13:15:37.268Z',
      weight: 5,
    },
    dateOrdered: '2022-02-04',
    id: 95,
    mainOrderId: 155,
    providerId: 1,
    quantity: 3,
    string_agg: 'completed, accepted',
  },
  {
    components: null,
    data: {
      calories: 548.6,
      carbs: 2.8,
      createdAt: '2021-11-18T13:15:37.268Z',
      description: 'Salmon, Brown Rice, and Mixed Vegetables',
      fat: 35.4,
      id: 9,
      images: [],
      img: 'https://res.cloudinary.com/hksqkdlah/image/upload/43111-sfs-brown-rice-bowl-vegetables-salmon-23.jpg',
      keywords: [],
      name: 'Salmon',
      price: 499,
      protein: 53.9,
      providerId: 1,
      restrictions: ['Mustard', 'Celery', 'Eggs'],
      tags: [],
      updatedAt: '2021-12-15T11:49:01.889Z',
      weight: 5,
    },
    dateOrdered: '2022-01-07',
    id: 90,
    mainOrderId: 69,
    providerId: 1,
    quantity: 6,
  },
];

export const dailyMealsDummy = {
  meals: 6,
  suggestedDates: 5,
  data: [
    { suggestedDate: 'Nov 1', orderLineIds: [], note: '' },
    { suggestedDate: 'Nov 2', orderLineIds: [], note: '' },
    { suggestedDate: 'Nov 3', orderLineIds: [], note: '' },
    { suggestedDate: 'Nov 4', orderLineIds: [], note: '' },
    { suggestedDate: 'Nov 5', orderLineIds: [], note: '' },
    { suggestedDate: 'Nov 6', orderLineIds: [], note: '' },
    { suggestedDate: 'Nov 7', orderLineIds: [], note: '' },
  ],
};
