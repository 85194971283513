import styled from 'styled-components';

const DietCardStyled = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-radius: 34px;
    border: 1px solid #E0E3E0;
    gap: 24px;
    margin-bottom: 10px;
    &.active{
        border: 3px solid #CBEDBA;
        background: #F0FAEB;
        .card_icon{
            svg{
                path{
                    fill: #52C41A;
                    stroke: #52C41A;
                }
            }
        }
    }
    .card_icon{
        width: 32px;
        height: 32px;
    }
    .card_content{
        .card_title{
            color: #51635D;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 2px;
        }
        .card_text{
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.02em;
            color: #949D9B;
        }
    }
`

export default function DietCard({ isActive, icon, title, desc, onClick, value, desc1 }) {
    return (
        <DietCardStyled onClick={onClick} value={value} className={`dietCard ${isActive && 'active'}`}>
            <div className="card_icon">{icon}</div>
            <div className="card_content">
                <h4 className='card_title'>{title}</h4>
                <p className='card_text'>{desc}</p>
                {desc1 && <p className='card_text'>{desc1}</p>}

            </div>
        </DietCardStyled>
    )
}