import { useState } from 'react';
import styled from 'styled-components';
import NutrittLogo from './NutrittLogo';
import ProfileMenu from './ProfileMenu';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import { useVisitorContext } from 'context/visitorContext';

const StyledHeader = styled.header`
  &.fixedHeader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
  
  position: relative;
  /* width: 100vw; */
  height: 52px;
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto min-content;
  // grid-gap: 15px;
  padding: 0px 16px;
  z-index: 110;
  // border-bottom: 1px solid var(--nutritt-green);
  background-color: #fff;

  img {
    justify-self: start;
  }

  &.fixed {
    position: fixed;
    width: 100%;
  }

  &.suspendedHeader{
    justify-content: flex-end;
    flex-direction: row-reverse;
    .header-sandwich-menu{
      width: 32px;
      height: 32px;
      margin-right: 24px;
    }

    @media (max-width: 992px) {
      justify-content: unset;
      .header-sandwich-menu{
        margin-right: 0;
      }
    }
  }

  @media (max-width: 992px) {
    position:fixed;
    top:0;
    width:100%;
  }

  @media (min-width: 992px) {
    height: 70px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
    

    .menu-logo-wrapper {
      display: flex;
    }

    .socialIcon-wrapper{
      img{
        padding: 6px;
      }
    }
  }
`;

const MobileStepperView = styled.div`
  position: fixed;
  top: 52px;
  right: 0;
  left: 0;
  z-index: 100;

  .MuiMobileStepper-root{
    padding: 0px !important;
    max-width: 100% !important;
    border-bottom: 0.5px solid #e0e3e0;
   }
  .MuiLinearProgress-root{
    background-color: #FFFFFF;
    width: 100%;
  }
  .css-5xe99f-MuiLinearProgress-bar1{
    background-color:#97DC76;
    transform: translateX 0% !important;
  }

  @media (min-width: 992px) {
    top: 70px
  }
`;

const socialMedia = [
  { 
    icon: 'facebook-icon', 
    url: 'https://www.facebook.com/nutrittofficial' 
  }, 
  { 
    icon: 'instagram-icon',
    url: 'https://www.instagram.com/nutritt_/'
  },
  {
    icon: 'linkedin-icon',
    url: 'https://www.linkedin.com/company/nutrittofficial'
  }];

// export default function Header({ children = () => <div></div> }) {
export default function Header({ children, updateProvider, provider, isAlert, activeSteps, style,showSocialIcons }) {
  const [isMenu, setIsMenu] = useState(false);
  const { pathname } = useLocation();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  const { isMobile, user, isUserSuspended } = useAppContext();
  const { noNavigation } = useVisitorContext() || {};

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const profileMenu = (
    <ProfileMenu
      updateProvider={updateProvider}
      passProvider={provider}
      isAlert={isAlert}
      isMenu={isMenu}
      setIsMenu={setIsMenu}
    />
  );

  return (
    <>
      <StyledHeader className={classNames({fixedHeader: !(pathname.includes('/shop/')), suspendedHeader: isUserSuspended && user})}>
      <div className="menu-logo-wrapper">
        {pathname.includes('/shop/') && !isMobile && profileMenu}
        <NutrittLogo isMenu={isMenu} />
      </div>
      {children}
      {pathname.includes('support') ? (
        <Link to="/">
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <div className="item-title">Home</div>
          </div>
        </Link>
      ) : (
        <>
          {!(pathname.startsWith('/provider') && isMobile) &&
            !(pathname.includes('/shop/') && !isMobile) &&
            !pathname.startsWith('/onboarding') &&
            !(pathname.startsWith('/plan')) &&
            !(pathname.startsWith('/guest')) &&
            !(pathname.startsWith('/booking')) &&
            !(pathname.includes("/login"))&&
            !(pathname.includes("/signup"))&&
            !(pathname.includes("/account"))&&
            !(pathname.includes("forgot-password"))&&
            !(pathname.includes("/registerprovider"))&&
             !(pathname.startsWith("/visitor"))&&
            profileMenu}

          {((showSocialIcons|| pathname.startsWith('/guest') || pathname.startsWith('/booking') || pathname.startsWith('/plan')) && !isMobile  || (pathname.includes("/visitor/get-started") && !isMobile) || pathname.includes("/registerprovider") || (pathname.includes("/login") && !isMobile) || (noNavigation && !isMobile)) && 
          <div className="socialIcon-wrapper">{socialMedia.map((icon, index) => (
            <a href={icon.url} target="_blank" ><img src={`/icons/${icon.icon}.svg`} alt={`${icon} Icon`} key={index} /></a>
          ))}</div>
          }  
        </>
      )}
    </StyledHeader>
    {(pathname.includes('/plan/')) ?
    <MobileStepperView>
    <MobileStepper  
      variant="progress"
      steps={9}
      position="static"
      activeStep={activeSteps}
      sx={{ maxWidth: 400, flexGrow: 1 }}
    />
    </MobileStepperView>
    : 
    <div style={{ borderBottom: "0.5px solid #f6f5f8"}}/>}
    </>
    
  );
}
