import styled from 'styled-components';

const SoicalMediaIconsStyle = styled.div`

  img {
    width: 18px;
    height: 18px;
    margin-left: 9px;
    margin-right: 9px;
    // margin-bottom: 2.80px;
  }
`;

export default function SoicalMediaIcons({ img }) {
  return (
    <SoicalMediaIconsStyle>
      <img src={img} alt="soical Media" />
    </SoicalMediaIconsStyle>
  );
}
