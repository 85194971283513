import { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

const DatePickerWrapper = styled.div`
  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
    border: 0;
  }

  .react-datepicker__navigation-icon--next {
    left: -10px;
  }

  .react-datepicker__navigation-icon--previous {
    right: -10px;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 0;

    .react-datepicker__current-month {
      margin-bottom: 13px;
      padding-left: 30px;
      color: #51635d;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      text-align: left;
    }

    .react-datepicker__day-names {
      margin-bottom: -15px;
      color: #51635d;
      font-size: 11px;
      font-weight: 400;
      line-height: 12px;
    }
  }

  .react-datepicker__day-name {
    width: 32px;
    height: 32px;
    margin: 5px;
  }

  .react-datepicker__navigation-icon::before {
    width: 7px;
    height: 7px;
    border-color: #52c41a;
  }

  .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
  }

  .react-datepicker__month {
    margin: 0;

    .react-datepicker__day,
    .react-datepicker__day--disabled,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      width: 32px;
      height: 32px;
      margin: 5px;
      border-radius: 50%;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 32px;
    }

    .react-datepicker__day,
    .react-datepicker__day--selected.react-datepicker__day--outside-month,
    .react-datepicker__day--keyboard-selected {
      background-color: #f0faeb;
      color: #52c41a;
    }
    .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .react-datepicker__day--disabled {
      background-color: #f6f5f8;
      color: #afb5b4;
    }

    .react-datepicker__day--selected {
      background-color: #52c41a;
      color: #ffffff;
    }
  }

  .react-datepicker__navigation--previous {
    left: auto;
    right: 60px;
  }

  @media (max-width: 350px) {
    .react-datepicker__day-name,
    .react-datepicker__month .react-datepicker__day,
    .react-datepicker__month .react-datepicker__day--disabled,
    .react-datepicker__month .react-datepicker__day--keyboard-selected,
    .react-datepicker__month .react-datepicker__day--selected {
      margin: 3px;
    }
  }

  @media(min-width: 992px){
    .react-datepicker__navigation--previous {
      left: 129px;
    }

    .react-datepicker__navigation--next{
      right: 141px;
    }

    .react-datepicker__current-month{
      display: flex;
      justify-content: center;
      padding-left: 0 !important;
    }
  }
`;

export default function InlineCalendar({excludeDateList, includeDates = [],selectedDate, minDate, setExcludeDate }) {
  const [selected, setSelectedDate] = useState(null);

  return (
    <DatePickerWrapper>
      <DatePicker
      formatWeekDay={nameOfDay => nameOfDay.substr(0,3).toUpperCase()}
        selected={selected}
        minDate={minDate}
        onChange={(date) => {
          setSelectedDate(date);
          selectedDate(moment(date));
        }}
        inline
        excludeDates={excludeDateList}
        placeholderText="Select a date other than today or yesterday"
        onMonthChange={(data) => {
            const date = moment(data).startOf('month').valueOf();
            setExcludeDate(date);
        }}
      />
    </DatePickerWrapper>
  );
}
