import { ReactComponent as Receipt } from 'icons/receipt.svg';
import { ReactComponent as Map } from 'icons/map2.svg';
import { ReactComponent as Help } from 'icons/help.svg';

export const sidebarLinks = [
  {
    id: 1,
    icon: <Receipt />,
    title: 'Orders',
    click: '/shop/order',
  },
  {
    id: 2,
    icon: <Map />,
    title: 'Addresses',
    click: '',
  },
  {
    id: 3,
    icon: <Help />,
    title: 'Help',
    click: '/shop/kitchen-home',
  },
];
