import styled from 'styled-components';
import BackButton from 'components/BackButton';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProviderHeaderStyle = styled.div`

  .step-header {
    margin-bottom: 0;
    font-family: 'Roboto';
    font-style: normal;

    .title-wrapper {
      position: relative;
      ${FlexCenter};
      .back-button{
        div{
            left: 13px;
            top: 13px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            position:fixed;
            z-index:2;
            background:#ffffff;
            border-radius:50%;
            margin-left: 0;
            img{
                width:20px;
                height:20px;
            }
        }
      }
      .cross-img{
        position: absolute;
        right: 9px;
        top: 0px;
      }
    }

    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
        text-align: center;
        margin-bottom:10px;
        padding: 0 50px;
    }

    .description {
      width: 100%;
      margin: 0 auto;
      padding: 0 40px;
      color:#949D9B;
      font-size: 14px;
      line-height: 16px;
      font-weight:400;
      display: flex;
      justify-content: center;
      P{
        margin-left:0;
      }
    }
  }

 
`;

export default function ProviderHeader({ title, description, closeModal, setProviderModal, setShowAnim, setModal, hideCrossButton, setPrevAnim,className }) {

    const closeAllModals = () => {
      setShowAnim && setShowAnim(false);
      setPrevAnim && setPrevAnim(false);

      setTimeout(() => {
        setModal(false);
      }, 500)
    }

    return (
        <ProviderHeaderStyle className={className}>
            <div className="step-header">
                <div className="title-wrapper">
                    <div className='back-button'> <BackButton setShowAnim={setShowAnim} closeModal={closeModal} /> </div>
                    <p className='title'>{title}</p>

                    {!hideCrossButton && <div className='cross-img'> <img src='/icons/close-cross-green.svg' onClick={closeAllModals}/> </div>}
                 
                </div>
                {description && <div className="description">{description}</div>}
            </div>
        </ProviderHeaderStyle>
    );
}


ProviderHeader.defaultProps = {
  hideCrossButton: false
}