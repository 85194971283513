import styled from 'styled-components'
import { useDrag } from 'react-dnd';
import produce from 'immer';
import { useNutritionistContext } from 'context/nutritionistContext';
import { useEffect } from 'react';
import { useAppContext } from 'context/appContext';

const PlannerCardItemStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #E0E3E0;
    border-radius: 5px;
    display: flex;
    gap: 8px;
    position: relative;
    width: 100%;
    user-select: none;

    &.dragging{
    }

    .food_img{
        width: 63px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 8px;

        img{
            width: 100%;
        }
    }

    .food_info{
        flex: 1;
        padding: 18px 24px 14px 0;

        .title{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #0B1D17;
            margin-bottom: 5px;
            text-transform: initial;
        }

        .nutrients_info{
            display: flex;
            column-gap: 13px;
            justify-content: space-between;

            .nutr_info{
                font-weight: 400;
                font-size: 9px;
                line-height: 12px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #51635D;
                position: relative;

                &::before{
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -7px;
                }

                &:nth-child(2)::before{
                    background-color: #916AE2;
                }

                &:nth-child(3)::before{
                    background-color: #FEB95A;
                }

                &:nth-child(4)::before{
                    background-color: #EE786B;
                }

                span{
                    font-weight: 700;
                }
            }
        }
    }

    .more__options{
        display: inherit;
        position: absolute;
        width: 14px;
        height: 14px;
        right: 6px;
        top: 6px;
    }


    @media (min-width: 390px) and (max-width: 576px){

        .food_info{
            .nutrients_info{
                column-gap: 19px;
            }
        }

    }

`

export default function PlannerCardItem({ autoScrollDiv, meal, setMealImg, setMoreOptionsModal, setData, setSelectday }) {
const {idx, id, day, img,} = meal;
    const {
        updateClientsettings,
        clientsettings: { mealPlan },
    } = useNutritionistContext();

    const changeItemColumn = (currentItem, dayName) => {
        let activeMealIndex;
        mealPlan.map((meal, index) => meal.idx === currentItem.idx && (activeMealIndex = index));
        let activeMeal = mealPlan[activeMealIndex];

        updateClientsettings(
            produce((draft) => {
                draft.mealPlan.splice(activeMealIndex, 1, {
                    ...activeMeal,
                    day: dayName,
                });
            }),
        );
    };
    
    const [{ isDragging }, drag] = useDrag({
        type: day,
        item: { id, idx },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            dropResult && changeItemColumn(item, dropResult.name);
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrop: () => true,
    });


    useEffect(() => {
        setMealImg(meal);
    }, [isDragging])

    const autoScroll = (e) => {
        const SCROLL_RATE = 20;
        let y;

        if(isDragging){
            if(e.type !== "mousemove"){
                y = e?.touches[0]?.clientY;
            }else{
                y = e?.clientY;
            }

            // scroll down
            if (y < 120) {
                autoScrollDiv.current.scroll({left: 0, top: autoScrollDiv.current.scrollTop - SCROLL_RATE, behaviour: 'smooth'})
            }
            // scroll up
            else if (y > window.innerHeight - 160) {
                autoScrollDiv.current.scroll({left: 0, top: autoScrollDiv.current.scrollTop + SCROLL_RATE, behaviour: 'smooth'});
            }
        }
    }

    return (
        <div className='reference__drag' onMouseMove={autoScroll} onTouchMove={autoScroll} ref={drag} style={{ opacity: isDragging ? 0.3 : 1, width: '100%' }}>
            <PlannerCardItemInfo isDragging={isDragging} meal={meal} setData={setData} setMoreOptionsModal={setMoreOptionsModal} setSelectday={setSelectday} />
        </div>
    )

}


export const PlannerCardItemInfo = ({setMoreOptionsModal, meal, setData, setSelectday, isDragging}) => {
    const {convertToHttps} = useAppContext();
    const moreHandle = () => {
        setData(meal)
        setSelectday(meal.day)
        setMoreOptionsModal(true)
    }
    const imageUrl = meal?.img;
    const secureImageUrl = convertToHttps(imageUrl);
    return (
        <PlannerCardItemStyle className={`${isDragging && 'dragging'}`}>
            <div className='food_img'>
                <img src={secureImageUrl ? secureImageUrl : '/images/dish.svg'} />
            </div>
            <div className='food_info'>
                <h3 className='title'>{meal.name}</h3>

                <div className='nutrients_info'>
                    <div className='nutr_info'>
                        Cals <span>{meal.calories}</span>
                    </div>

                    <div className='nutr_info'>
                        Carbs <span>{meal.carbs}g</span>
                    </div>

                    <div className='nutr_info'>
                        Fat <span>{meal.fat}g</span>
                    </div>

                    <div className='nutr_info'>
                        Protein  <span>{meal.protein}g</span>
                    </div>
                </div>
            </div>

            <span className='more__options'>
                <img src='/icons/more.svg' onClick={moreHandle}/>
            </span>

        </PlannerCardItemStyle>
    )
}
