import React, { useEffect, useState } from 'react'
import ImageViewer from 'components/ImageViewer';
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { ReactComponent as RightArrow } from 'assets/shop/chevron-right.svg';
import { ReactComponent as Recurring } from 'assets/shop/recurring-time.svg';
import { ReactComponent as Info } from 'assets/shop/info-circle.svg';
import { ReactComponent as Error } from 'assets/shop/error-icon.svg';
import { ReactComponent as GrayInfo } from 'assets/shop/gray-info.svg';
import { GilroyFontWrapper } from 'shared/theme/style';
import { calculateOrderTotals, roundToTwoDecimalPlaces } from 'utils';
import moment from 'moment';
import { theme } from 'shared/theme/theme';

const OrderCardStyle = styled.div`
width:100%;
.order-item-card{
    background: ${theme?.colors?.white};
    margin-bottom: ${({ subscription }) => ( subscription ? '10px' : '0')};
}
.status{
  color: ${theme?.colors?.deepForestGreen};
  text-align: center;
  font-family: ${theme?.typography?.fontGilroy};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  display: inline-block;
  padding: 3px 8px;
  height: 20px;
  border-radius: 80px;
}
.every-week{
  background: #B4FFFF;
}
.every-other-week{
  background: #FDE2C6;
}
.every-day{
  background: #F3AA1C;
}
.every-other-day{
  background: #2399BF;
}
.paused{
  background: #EBEBEB;
  margin: 12px 0px 6px 10px !important;
}
.header-text{
  display:flex;
  gap:24px;
  width: 100%;
  .recurring-pill{
    display: flex;
    position: relative;
    width: 119px;
    height: 24px;
    padding: 4px 12px 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 80px;
    background: #C2FDC2;
    .text{
      color: ${theme?.colors?.primaryDefault};
      text-align: center;
      font-family: ${theme?.typography?.fontGilroy};
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: ${theme?.typography?.fontBase};
      position: absolute;
      left: 26px;
    }
  }
}
.help-text{
    color: ${theme?.colors?.primaryDarker};
    text-align: center;
    font-family: ${theme?.typography?.fontGilroy};
    font-size: ${theme?.typography?.fontRegular};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration-line: underline;
}
.value{
    color: ${theme?.colors?.deepForestGreen};
    font-family: ${theme?.typography?.fontGilroy};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    line-height: 24px;
}
.flex{
    display:flex;
    flex-direction: column
}
.gap-10{
    gap:10px;
}
.order-details{
    display: flex;
    gap: 75px;
    margin: 24px 0 20px 24px; 
}
.subscription-items{
  margin: 16px 0 0 24px;
}
.space-between{
    justify-content: space-between;
}
.card-header{
    display: flex;
    padding: 9px 0;
    padding: ${({ subscription }) => ( subscription ? '9px 25px 14px 16px' : '18.5px 25px 14px 16px')};
    svg{
      cursor: pointer;
    }
    h4{
        color: ${theme?.colors?.deepForestGreen};
        font-family: ${theme?.typography?.fontGilroy};
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    a{
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0px;
        img{
            height: 25px;
        }
    }
}
.order-items-media-list{
    display: flex;
    align-items: center;
    padding: ${({ subscription }) => ( subscription ? '0px 25px 14px 16px' : '0px 25px 0px 16px')};
    .image-container{
        display: flex;
        gap: 12px;
    }
    .media-avtar{
        position: relative;
        width: 90px;
        height: 90px;
        flex-shrink: 0;   
        display: flex;
        border-radius: 10px;
        background: #F8F8F8;   
        opacity: ${({ paused}) => paused ? "0.6" : "1"}; 
        img {
          width: 90px;
          height: 90px;
          margin: auto;
          border-radius: 10px;      
          object-fit: cover;
        }
    }
    .moreOrder{
        background: #F0F2F4;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 5px 9px 6px;
        margin-left: 5px;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        h5{
            color: ${theme?.colors?.primaryDarker};
            font-family: ${theme?.typography?.fontGilroy};
            font-size: ${theme?.typography?.fontRegular};
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
         }      
    }
}
.add-all{
    gap: 12px;
    display: flex;
    padding: 16px 0px 16px 0px;
    margin: 0px 16px 0px 16px;
    border-bottom: 1px solid #F0F2F4;
}
.custom-button{
        width: 148px;
        height: 32px;
        padding: 14px 16px 12px 16px;
        gap: 10px;
        border-radius: 30px;
        border: #DFE2E8;
        background:  ${theme?.colors?.aliceBlue};
        display: flex;
        justify-content:center;
        align-items:center;
        color:  ${theme?.colors?.primaryDarker};
        cursor: pointer;
        text-align: center;
        font-family: ${theme?.typography?.fontGilroy};
        font-size: ${theme?.typography?.fontRegular};
        font-style: normal;
        font-weight: 600;
        line-height: 20px; 
        &:hover{
          background-color: #DFE2E8;
      }
       &:active{
          background-color: #757E89;
      }
}
.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.hidden{
    visibility: hidden;
}
.out-of-stock{
  display: flex;
  gap: 8px;
  padding: 0px 0px 14px 24px;
  align-items: center;
  justify-content: left;
  color: ${theme?.colors?.primaryDark};
  font-family: ${theme?.typography?.fontGilroy};
  font-size: ${theme?.typography?.fontRegular};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.error-div{
  display: flex;
  gap: 4px;
  padding: 10px 0px 10px 16px;
}
.cancelled{
  color: #F03738;
  display: block;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  justify-content: left;
}
@media (max-width: 991px){
  .header-text{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    .recurring-container{
      display: flex;
      gap: 10px;
    }
}
.status{
  margin: 12px 0px 6px 16px;
}
  .card-header{
    flex-direction: column;
    .scheduled-for{
      display: flex;
      color: ${theme?.colors?.grayDarker};
      font-family: ${theme?.typography?.fontGilroy};
      font-size: ${theme?.typography?.fontRegular};
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 5px;
    }
    .dot{
      height: 3px;
      width: 3px;
      background-color: ${theme?.colors?.grayDarker};
      border-radius: 50px;
      margin: auto 8px;
    }
  }
  .out-of-stock{
    color: ${theme?.colors?.blackLight};
    padding: 10px 0px 10px 16px;
  }
}
@media (min-width: 992px) {
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
  display: flex;
  .status{
    margin: 0;
  }  
  .header-text{
    position: relative;
    align-items: center;
    gap: 14px;
  }
  .order-title{
    text-align: left;
    display: inline-flex;
    color: ${theme?.colors?.deepForestGreen};
    font-family: ${theme?.typography?.fontGilroy};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .media-avtar{
    width: 110px !important;
    height: 110px !important;
    img {
      width: 110px !important;
      height: 110px !important;
    }
  }
  .order-item-card{
    height: 100% !important;
    border: 1px solid ${theme?.colors?.grayLight};
    width:960px;
    border-radius:12px
  }
  .error-div{
    padding: 0px 0px 14px 24px;
  }
  .card-header{
    border-bottom: 1px solid ${theme?.colors?.grayLight};
    background: #F5F7F8;
    height: 42px;
    padding: 0px 14px 0 24px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    .recurring-container{
      display: flex;
      gap: 14px
    }
      .right-arrow{
        position: absolute;
        right: 0;    
      }  
  }
  .order-items-media-list{
    border-top: ${({ subscription }) => subscription ? 'none' : '1px solid #E0E3E0'};
    padding: 20px 0px 24px 0px;
    justify-content: space-between;
    margin: 0px 24px;
    .custom-button{
       background: ${theme?.colors?.white};
       border: 1px solid ${theme?.colors?.grayDarker} !Important;
       &:focus, &:active{
        border: 1px solid ${theme?.colors?.grayLight} !important;
        color: ${theme?.colors?.grayDarker};
        background: ${theme?.colors?.white};
      } 
    }
  }
  .custom-button{
    width: 170px;
    line-height: normal;
    height: 42px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}
`

export const OrderCard = ({
  orderList, 
  setShowOrderStatus, 
  setOrderItems, 
  setOrderMainId, 
  setProviderId, 
  setShowOrderAnim, 
  handlePreOrder, 
  orderItem, 
  trackOrder, 
  subscription=false, 
  inProgressOrders 
}) => {
    const [isLimitOrder, setIsLimitOrder] = useState(false);
    const history = useHistory();
    const {userDeliveryAddress,handleOpenInterCom, isDesktop, isMobile} = useAppContext();
    const { settings, mealLoading, cart } = useShopContext();
    const settingAddress = settings?.userInfo?.address
    const { providersCount } = orderItem || {};
    const { providerOrderSummary } = orderItem?.orderData || {};
    const { grandTotal } = calculateOrderTotals(providerOrderSummary, providersCount);
    const [meals, setMeals] = useState(orderItem?.meals);
    const handleOrderClick = () => {
        setShowOrderStatus(true);
        setOrderItems(orderItem);
        !subscription && setOrderMainId(orderItem?.orderMainId);
        !subscription && setProviderId(orderItem?.providerId);
         setShowOrderAnim(true);  
    }

    const ErrorComponent = () => {
      return (
        <div className='error-div'>
        <Error />
        <text className='cancelled'>Order cancelled</text>
      </div>
      )
    }

  return (
    <GilroyFontWrapper>
    <OrderCardStyle isDesktop={isDesktop} subscription={subscription} paused={orderItem?.status === "paused"} theme={theme}>
         <div className='order-item-card'>
        {subscription && isMobile &&
          <span className={`status ${orderItem?.intervaldays === 7 ? 'every-week' : orderItem?.intervaldays === 14 ? "every-other-week" : orderItem?.intervaldays === 1 ? "every-day" : orderItem?.intervaldays === 2 ? "every-other-day" : ""}`}>
          {orderItem?.intervaldays === 7 ? 'Every week' : orderItem?.intervaldays === 14 ? "Every other week" :  orderItem?.intervaldays === 1 ? "Every day" : orderItem?.intervaldays === 2 ? "Every other day" : ""}
        </span>
        }
        {subscription && orderItem?.status && isMobile &&
          <span className={`status ${ orderItem?.status === "paused" ? "paused" : ""}`}>
          {orderItem?.status === "paused" ? `Resumes on: ${moment(orderItem?.subscriptionActivationDate).utc().format('MMM DD YYYY')}` : ""}
        </span>
        }
          <div className='card-header' onClick={()=>{
            if(isMobile){
              handleOrderClick()
            }
          }}>
            <div className='header-text'>
            <div className='recurring-container'>
            <h4>{subscription ? orderItem?.storeName : orderItem?.kitchen?.name}</h4>
            {orderItem?.orderData?.isRecurringOrder && 
            <div className='recurring-pill'>
              <Recurring />
              <span className='text'>Recurring order</span>
            </div>
            }
            </div>
            {subscription && isDesktop && 
            <div className='pill-container'>
              <>
                <span className={`status ${orderItem?.intervaldays === 7 ? 'every-week' : orderItem?.intervaldays === 14 ? "every-other-week" : orderItem?.intervaldays === 1 ? "every-day" : orderItem?.intervaldays === 2 ? "every-other-day" : ""}`}>
                  {orderItem?.intervaldays === 7 ? 'Every week' : orderItem?.intervaldays === 14 ? "Every other week" : orderItem?.intervaldays === 1 ? "Every day" : orderItem?.intervaldays === 2 ? "Every other day" : ""}
                </span>
                {orderItem?.status &&
                  <span className={`status ${orderItem?.status === "paused" ? "paused" : ""}`}>
                    {orderItem?.status === "paused" ? `Resumes on: ${moment(orderItem?.subscriptionActivationDate).utc().format('MMM DD YYYY')}` : ""}
                  </span>
                }
              </>
            </div>
              }
            {!subscription && isDesktop && <h3 className='help-text' onClick={handleOpenInterCom}>Get help?</h3>}
            <RightArrow
              className='right-arrow'
              onClick={
                subscription ? handleOrderClick : isDesktop ? orderItem?.providerOrderAction?.action === "refused" ? handleOrderClick : () => history.push(`/store/${orderItem?.kitchen?.username}`) : handleOrderClick
              }
            />
            </div>
            {isMobile && !subscription && <div className='scheduled-for'>{inProgressOrders ? "Scheduled for:" : "Delivered on:"} {orderItem?.orderData?.scheduledDate} <div className='dot'></div>${roundToTwoDecimalPlaces(grandTotal)} </div>}
          </div>
          {isDesktop && 
          <div className='space-between'>
            {!subscription ? 
            <div className='order-details'>
                <div className='flex gap-10'>
                    <h4 className='order-title'>{inProgressOrders ? "Scheduled for" : "Delivered on"}</h4>
                    <p className='value'>{moment(orderItem?.orderData?.scheduledDate).format("MMM D, YYYY")} {orderItem?.orderData?.scheduledTime}</p>
                </div>
                <div className='flex gap-10'>
                    <h4 className='order-title'>Items</h4>
                    <p className='value'>{meals?.length}</p>
                </div>
                <div className='flex gap-10'>
                    <h4 className='order-title'>Total</h4>
                    <p className='value'>${roundToTwoDecimalPlaces(roundToTwoDecimalPlaces(calculateOrderTotals(orderItem?.orderData?.providerOrderSummary, orderList?.providersCount).grandTotal))}</p>
                </div>
            </div>
            :
            <div className='subscription-items'>
                <div>
                    <h4 className='order-title'>Items:<p className='value' style={{marginLeft: "10px"}}>{meals?.length}</p></h4>
                </div>
            </div>
            }
            {!subscription && orderItem?.providerOrderAction?.action !== "refused" && <button className='custom-button' style={{marginRight: "24px"}} onClick={handleOrderClick}>{trackOrder ? "Track your order" : "Receipt order"}</button>}
          </div>
          }
          <div className={`order-items-media-list space-between`} onClick={()=>{
            if(isMobile){
              handleOrderClick()
            }
          }}>
          {isDesktop ? (
            <div className='image-container'>
              {meals.slice(0, 5).map((item) => (
                <div className='media-avtar' key={item.id}>
                  <ImageViewer
                    src={item?.img}
                    alt={"/images/Kitchen-image.png"}
                    fallbackSrc={'/images/Kitchen-image.png'}
                  />
                </div>
              ))}
            </div>
          ) : (
            meals.slice(0, 3).map((item) => (
              <div className='media-avtar' key={item.id}>
                <ImageViewer
                  src={item?.img}
                  alt={"/images/Kitchen-image.png"}
                  fallbackSrc={'/images/Kitchen-image.png'}
                />
              </div>
            ))
          )}
            {meals?.length <= 3 && (
              <div className='media-avtar moreOrder hidden' key={`placeholder-moreOrder`}>
              </div>
            )}
            {meals?.length > 3 && isMobile && (
              <div className='media-avtar moreOrder'>
                <h5>+{meals.length - 3}</h5>
              </div>
            )}
            {meals?.length <= 3 && [...Array(3 - meals?.length)].map((_, index) => (
              <div className='media-avtar hidden' key={`placeholder-${index}`}>
              </div>
            ))}
            {isDesktop && !subscription && orderItem?.providerOrderAction?.action !== "refused" && <button className='custom-button' disabled={mealLoading} onClick={() => {handlePreOrder(orderItem)}}>Add all to cart</button>}

          </div>
          {isMobile && orderItem?.providerOrderAction?.action === "refused" && 
            <ErrorComponent />
          }
          {meals.find(item => item.isAvailable === false) && (
            <div className='out-of-stock' onClick={isMobile && handleOrderClick}>
                {isMobile ? <GrayInfo /> : <Info />}
                Some items from this order were out of stock
            </div>
          )}
          {isDesktop && orderItem?.providerOrderAction?.action === "refused" &&
            <ErrorComponent />
          }
          {isMobile && !subscription && 
          <div className='add-all'>
            <button className='custom-button' onClick={() => {
              if (!userDeliveryAddress?.length && !settingAddress) {
                toast.error("Oops! It looks like you haven't saved any address yet.");
                return history.push("/shop/kitchen-home")
              }
              handlePreOrder(orderItem)
            }}>Add all to cart</button>
            {(orderItem?.providerOrderAction?.action === "pending" || orderItem?.providerOrderAction?.action === "accepted") && 
              <button style={{ width: "94px" }} className='custom-button' onClick={handleOpenInterCom}>Get help</button>}
          </div>
          }
        </div>
    </OrderCardStyle>
    </GilroyFontWrapper>
  )
}
