import React, { useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as Close } from 'assets/close-gray.svg';
import { ReactComponent as Crop } from 'assets/crop.svg';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import {getCroppedImgToBase64} from './Crop';
import {getCroppedImg} from './Crop';
import { useAppContext } from 'context/appContext';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

const ImageUploader = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 99;
  position: fixed;
  background-color: rgba(40,50,65,0.4);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .cropped-image{
    border-radius: ${({ circle }) => (circle ? "50%" : "")};
  }
  .fMIEJB .form_group input {
    padding: 0px !important;
  }
  .MuiSlider-rail {
    height: 4px;
  }
  .MuiSlider-track {
    height: 4px;
  }
  .MuiSlider-thumb {
    width: 24px;
    height: 24px;
  }
  .MuiSlider-thumb {
    margin-top: -10px;
  }
  .MuiSlider-root {
    color: #283241;
  }
  .cropped-image-container {
    height: 350px;
    align-self: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .reactEasyCrop_Container{
    background: #E0E4E8;
  }
  .reactEasyCrop_CropArea{
    border: ${({ circle }) => (!circle ? "20px solid pink" : "")};
    border-image-source: url(/icons/Rectangle-border.svg);
    border-image-slice: 20 22;
    color: rgba(255, 255, 255, 0.5) !Important;
  }
  .modal {
    max-width: 851px;
    max-height: 650px;
    background: white;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
    background: ${({ theme }) => theme?.colors?.white};
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    padding: 24px 0px 20px;
    display: flex;
    flex-direction: column;
  }
  .modal__header {
    .title {
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-size: ${({ theme }) => theme?.typography?.fontLarge};
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      svg {
        display: block;
        width: 20px;
      }
    }
  }
  .image-div {
    text-align-last: center;
    width: 100%;
    height: 350px;
    position: relative;
    align-items: center;
    background: #F0F2F4;
    justify-content: center;
    display: flex;
    .drag-drop{
      border-radius: 10px;
      border: 1px dashed var(--Medium-Grey, #51635D);
      width: 525px;
      height: 319px;
      flex-shrink: 0;
      position: absolute;
      cursor:pointer;
      z-index:1;
      .inner-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 119px;
        gap: 12px;
        .title{
          width:215px;
        }
        .recommend{
          margin-top:32px;
        }
      }
    }
    .icon{
      width: 32px;
      height: 32px;
    }
    text{
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #00092D;
    }
  }
  canvas {
    background: white;
  }
  .grey-btn{
    height: 42px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor:pointer;
    gap: 10px;
    border-radius: 21px;
    background: #F0F2F4;
    text{
      color: var(--new-00092-d, #00092D);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

  }
  .btn_flex {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 30px !important;
    top: 30px !important;
    .button {
      width: 100px;
      height: 40px;
      background-color: #fff;
      color: #51635d;
      border: 1px solid #e0e3e0 !important;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      & + .btn {
        margin-top: 10px;
      }
      &.save-btn {
        background-color: #52c41a;
        color: #fff;
      }
      &.cancel-btn {
        border-color: #97dc76 !important;
      }
      &[disabled] {
        background-color: #c3c3c3 !important;
        border: 1px solid #c3c3c3 !important;
        cursor: not-allowed;
      }
    }

    .custom_button {
      position: relative;
      &:last-child {
        background: ${({ theme }) => theme?.colors?.primaryDefault};
        color: ${({ theme }) => theme?.colors?.white};
      }
    }
    .btn{
      border: 1px solid #3B425D !important;
      width:100px;
    }
  }

  .scrolller {
    align-self: center;
    padding-top: 73px;
    display: flex;
    position: relative;
    width: 100%;
  }
  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 60%;
    gap: 13px;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
  }

  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px !important;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    width: 100%;
  }

  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #283241;
    background: #283241;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #283241;
    background: #283241;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  .controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
  .cropped-btn{
    align-self: center;
    .icon{
      height: 100% !important;
      width: 20px !important;
    }
  }
  @media(max-width: 1280px){
    .cropped-image-container {
      height: 280px;
    }
    .modal {
      max-height: 570px;
    }
    .image-div {
      height: 270px;
      .drag-drop{
        height: 220px;
        .inner-text{
          margin-top: 30px;
        }
      }
    }
  }
`;

export const Progress = styled.div`
  .icon {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1;
  }
`

export default function ImageContainer({ image, setImage, setNewImgUrl, setIsVisible, greenBtn, assetType, id, circle }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState('');
  const [base64image, setBase64Image] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 1,
    croppedImg: '/images/man.svg',
  });
  const { uploadProfilePic } = useAppContext();
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      const croppedImageBase64 = await getCroppedImgToBase64(image, croppedAreaPixels);
      setCroppedImage(croppedImage);
      setBase64Image(croppedImageBase64);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image]);

  const onRevert = useCallback(() => {
    setCroppedImage('');
    setBase64Image('');
  }, []);

  const onClose = useCallback(() => {
    setCroppedImage('');
    setImage(null);
    setIsVisible(false);
  }, []);

  const handleIncreaseScale = () => {
    const maxScale = 3;
    const newScale = Math.min(zoom + 0.1, maxScale);
    setZoom(newScale);
  };
  
  const handleDecreaseScale = () => {
    const scaleStep = 0.1;
    const minScale = 1;
    let newScale = zoom - scaleStep;
    if (newScale < minScale) {
      newScale = minScale;
    }
    setZoom(newScale);
  };

  const handlePhotoUpload = useCallback(
    async (picture, assetType, id) => {
      setIsloading(true)
      const blob = await fetch(picture.croppedImg).then((r) => r.blob());
      const file = new File([blob], `${id}.jpeg`, {
        type: 'image/jpeg',
      });
      let key;
      await uploadProfilePic(file, key, assetType)
        .then(async (uploadProfilePicResponse) => {
          setNewImgUrl(uploadProfilePicResponse?.result?.location)
          setCroppedImage('');
          setIsloading(false)
          setImage(null);
          setIsVisible(false)
        })
        .catch((e) => {
          console.log(e);
        });
    },[]);
  
  const handleUpload = async () => {
    try{
        const updatedData = {
            ...picture,
            img: null,
            cropperOpen: false,
            croppedImg: base64image,
            zoom: 1,
          };
          handlePhotoUpload(updatedData, assetType, id);
    } catch (e) {
        console.error(e);
      }  
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const imageFile = e.target.files[0];
    e.target.value = '';
    if (imageFile) {
      if (
        imageFile.type.startsWith("image/") &&
        imageFile.size <= 20 * 1024 * 1024
      ) {
        const imageUrl = URL.createObjectURL(imageFile);
        setImage(imageUrl);
      } else {
        console.error("Invalid file type or size");
        toast.error('Invalid file type or size');
      }
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
    e.dataTransfer.dropEffect = "copy";
  }

  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  }

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const imageFile = e.dataTransfer.files[0];
    if (imageFile) {
      if (
        imageFile.type.startsWith("image/") &&
        imageFile.size <= 20 * 1024 * 1024
      ) {
        const imageUrl = URL.createObjectURL(imageFile);
        fileInputRef.current.value = "";
        setImage(imageUrl);
      } else {
        console.error("Invalid file type or size");
        toast.error('Invalid file type or size');
      }
    }
  }
  

  return (
    <ImageUploader theme={theme} circle={circle}>
              {isLoading &&
        <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> 
              }
      <div className="modal">
        <div className="modal__header">
          <h2 className="title">
            Upload Image{' '}
            <Close
            style={{cursor: "Pointer"}}
            onClick={onClose}
            />
          </h2>
        </div>
        {croppedImage ? (
          <div className="cropped-image-container">
            <img className="cropped-image" src={croppedImage} alt="cropped" />
          </div>
        ) : (
            <div className="image-div">
              {!image ?
                <div className='drag-drop' onClick={handleClick} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                  <div className='inner-text'>
                    {isDragging ?
                      <text className='title'>Drop Image Here</text>
                      :
                      <>
                        <img src='/icons/arrow-up-upload.svg' className='icon'></img>
                        <text className='title'>Choose a file or drag and drop it here</text>
                        <text className='recommend'>We recommend using high quality .jpg files less than 20MB</text>
                      </>
                    }
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                </div>
            :
            <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            aspect={circle ? 1 : 16 / 9}
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={circle ? "round" : "rect"}
          />
            }
          </div>
        )}

        <div className="scrolller">
        {croppedImage.length < 1 && (

          <div className="controls">
          <img onClick={handleDecreaseScale} src='/icons/minus.svg'/>
            <Slider
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              className="zoom-range"
            />
             <img onClick={handleIncreaseScale} src='/icons/add-dark.svg'/>
          </div>
        )}
        </div>
        <div className={"cropped-btn"} style={{ alignSelf: 'center' }}>
          {croppedImage ? (
            greenBtn ?             
            <div className='grey-btn' onClick={onRevert} >
              <text>Revert</text>
              </div> :
            <Button title="Revert" type={'secondary'} onClick={onRevert} />
          ) : (
            greenBtn ?             
            <div className='grey-btn' onClick={showCroppedImage} >
              <Crop />
              <text>Crop photo</text>
              </div> :
            <Button  title="Crop photo" iconLeft={<Crop />} type={'secondary'} onClick={showCroppedImage} />
          )}
        </div>

        <div style={{ position: 'relative' }}>
          <div className="btn_flex">
            {greenBtn ?
              <>
                 <button className="button cancel-btn" onClick={() => {setCroppedImage(''), setImage(null), setIsVisible(false)}}>
                  Cancel
                </button>
                <button type="submit" disabled={croppedImage === '' || isLoading} onClick={handleUpload}  className="button save-btn">
                  Save
                </button>
              </>
            :
            <>
            <Button title="Cancel" type={'secondary'} onClick={() => {setCroppedImage(''), setImage(null), setIsVisible(false)}} />
            <Button title="Save" type={'dark'} onClick={handleUpload} isDisabled={croppedImage === '' || isLoading}/>
            </>
          }
          </div>
        </div>
      </div>
    </ImageUploader>
  );
}
