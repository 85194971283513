import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const OrderItemsWrapper = styled.section`
  height: inherit;
  padding-top: 16px;
  background-color: ${theme.colors.secondary};
  border: 1px solid ${theme.colors.grayLight};
  border-radius: 6px;

  .section-title {
    margin: 0 0 14px 16px;
  }

  .order-items-title {
    margin-right: 16px;
    border-bottom: 1px solid ${theme.colors.grayLighter};
    display: grid;
    grid-template-columns: 63% 18% 15%;
    .title-item {
      margin-bottom: 13px;
      padding-left: 16px;
      color: ${theme.colors.grayDarker};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      &:first-child {
        margin-left: 7px;
      }
    }
  }

  .order-items-details {
    overflow-y: auto;
    max-height: calc(100% - 71px);
    padding: 15px 0 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .order-line-container {
      margin-right: 16px;
      display: grid;
      grid-template-columns: 63% 18% 15%;
      align-items: center;
      .item-name {
        padding-left: 16px;
        display: flex;
        align-items: center;
        gap: 19px;
      }
      img {
        width: 65px;
        height: 65px;
        border-radius: 10px;
        object-fit: cover;
      }
      .order-name {
        color: ${theme.colors.primaryDarker};
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
    .item-qty,
    .item-price {
      padding-left: 18px;
      color: ${theme.colors.blackDefault};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;
