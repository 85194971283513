import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import ProviderHeader from './ProviderHeader';
import ProvidersCard from './ProvidersCard';
import { useRequest } from 'hooks/useRequest';
import { prop } from 'ramda';
import Progress from '../../../../components/Progress';
import  PickMeals  from './PickMeals';
import UseClientCart from 'hooks/UseClientCart';
import { useNutritionistContext } from 'context/nutritionistContext';
import Switch from '@material-ui/core/Switch';
import { isPointInPolygon } from 'utils/verify-coordinates-in-polygon.util';
import ReactTooltip from 'react-tooltip';

const ProviderListStyle = Styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 112;
//padding-top: 21px;
background-color:#f6f5f8;
transition: all .5s ease-in-out;
display: flex;
flex-direction: column;
align-items: center;
overflow:auto;
&.hidden{
  display:none;
}
&::-webkit-scrollbar{
  display:none;
}
.provider-header{
    padding:23px 8px 18px 8px;
    background:#ffffff;
    width:100%;
    //margin-bottom:18px;
    border-bottom: 1px solid #E0E3E0;
    position: fixed;
    z-index: 1;
}
.provider-change {
    display: flex;
    align-items: center;
    justify-content:space-between;
    width:80%;
    p{
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
    }
    .MuiSwitch-root{
      margin-right: -14px;
    }
  }

  .provider-cards {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(345px, 380px));
    gap: 24px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom:14px;

    .card-box .provider-card-info .tag-min-order-sec .tags-listing .smallPopper:first-child .MuiTooltip-popper {
      top: -20px !important;
      
    }

    .MuiTooltip-popper div {
     background-color: #52c41a !important;
     color: #fff !important;
     border: 0 !important;
   }


    @media (max-width: 767px) {
      width:auto;
      justify-content: center;
      .card-box{
        border-radius:10px;
      }
    }
  }
  .providers-card{
    .min-order{
      padding:4px 7px !important;
    }
    .icon-wrapper{
      width:24px;
      height:24px;
      img{
        width: 14px;
        height: 14px;
      }
    }
    .activeTooltip{
       img{
        filter: invert(57%) sepia(46%) saturate(1920%) hue-rotate(62deg) brightness(107%) contrast(80%);
       }
    }

     @media(max-width:321px){
      .provider-card-head{
        h3{
          word-break: break-all;
        }
      }
      .deliveryinfo-sec{
           display:flex;
           flex-direction:column;
           .city-wrapper{
             padding: 8px 0px 8px 12px;
           }
      }
      }

  }

  .name-tooltip{
    .__react_component_tooltip{
      width: 240px;
      word-break: break-all;
     }
  }
 
`;

const NoProviderAvailable = ({ text }) => {
  return (
    <div className="no-provider" style={{textAlign:'center'}}>
      <h3>{text}</h3>
    </div>
  );
};

   function shortName(clientName){
    useEffect(()=>{
      ReactTooltip.rebuild();
    },[])    
   
  return(
     <>
   { clientName?.length >10 ?  <p data-tip={clientName} data-for="name" style={{cursor: 'pointer'}}>Pick a fresh meals provider local to { clientName.substring(0, 10) + "..."} </p>
     :
    <p>Pick a fresh meals provider local to {clientName}</p>}
     <ReactTooltip id="name" place="top" effect="solid" className='name-tooltip'>
      </ReactTooltip>
    </>
  )
}

export default function ProviderList({ providerModal, setProviderModal, setMealsModal, followerProfile, setClientModal, setMealsModalsAnim, setClientAnim, zip, userAddressLatLong, setMealPlannerClose, setVisibility, visibility }) {
  
  const { isMobile } = useAppContext();
  const {availableProviders, setAvailableProviders} = useNutritionistContext();
  const mobileView = isMobile ? 'mobileView' : '';
  const [showAnim, setShowAnim] = useState(true);
  const [getProvidersAPICall, { isLoading }] = useRequest(`provider/providers`);
  const [pickMealsModal, setPickMealsModal] = useState(false);
  const {OnlyFirstName} = UseClientCart();
  const clientName = OnlyFirstName(followerProfile?.name);
  const [providerId, setProviderId] = useState();
  const [providerModalAnim, setProviderModalAnim] = useState(true);
  const [showOnlyDeliversToMe, setShowOnlyDeliversToMe] = useState(true);

  const getProviderFn = async() => {
    await getProvidersAPICall().then((data) => {
      setAvailableProviders({data, expiry: new Date().getTime() + (5 * 60 * 1000)});
    }).catch(console.log);
  }

  useEffect(()=>{
    const e = {
      target: {
        checked: true
      }
    };
  },[]);

  useEffect(() => {
    if (providerModal) {
      getProviderFn();
    }
  }, [providerModal]);

  const deliverToMeHandler = async (e) => {
    isMobile? setShowOnlyDeliversToMe(e.target.checked): setShowOnlyDeliversToMe(e)
    const target=isMobile?e.target.checked : e;
    if (target) {
      const newProviders = await getProvidersAPICall();
      setAvailableProviders({
        data: newProviders,
        expiry: new Date().getTime() + (5 * 60 * 1000)
      });
      const pro = await newProviders.filter((p) => {
        const dayWithDelivey = [];
        for (let dayKey in p.schedule) {
          dayWithDelivey.push(p.schedule[dayKey].delivery.isDeliveryOn)
        }
        const isAnyDayOnForDelivery = dayWithDelivey.some(item => item)
        let isProviderReadyToDeliver = p.deliveryCoverage?.includes(zip) && isAnyDayOnForDelivery;
        if (!isProviderReadyToDeliver) {
          const deliverableAreas = p?.deliverableAreas?.data || [];
          for (let polygon of deliverableAreas) {
            const polygonCords = polygon.map((coordinate) => ([coordinate.lat, coordinate.lng]));
            const isPointInsidePolygon = isPointInPolygon(
                userAddressLatLong.lat,
                userAddressLatLong.lng,
                polygonCords,
            );
            if (isPointInsidePolygon) {
                isProviderReadyToDeliver = true
              break;
            }
          }
        }
        return isProviderReadyToDeliver;
      });
      setAvailableProviders({data: pro, expiry: new Date().getTime() + (5 * 60 * 1000)});
    } else {
      await getProviderFn();
    }
  };

  useEffect(() => {
    if(providerModal){       
        document.body.classList.add('hide-scroll');
    }
    else{
      document.body.classList.remove('hide-scroll');
    }
}, [providerModal])
  
  return (
        <>
      <ProviderListStyle className={`${showAnim && providerModalAnim ? "fadeInMiddle" : "fadeOutMiddle"} ${visibility ? 'hidden' : ''}`}>
        <div className='provider-header'>
          <ProviderHeader
            title={`Pick meal provider`}
            description={shortName(clientName)}
            closeModal={setProviderModal}
            setModal={setMealsModal}
            setShowAnim={setShowAnim}
            setPrevAnim={setMealsModalsAnim}
            className={'name-tooltip'}
          />
        </div>
        <span className="provider-change">
             {/* <h1>Delivers to me</h1> */}
           {/* <Switch
               checked={showOnlyDeliversToMe}
               onChange={deliverToMeHandler}
               name="isPickupOn"
               color="primary"
             /> */}
        </span>
        {isLoading ? (
          <Progress />
        ) : availableProviders?.data?.length != 0 ? (
          <div className={`providerCard-listing ${mobileView} providers-card`}>
            {availableProviders?.data
              .filter(prop('schedule'))
              .filter(prop('approved')) 
              .map((provider) =>
                <div className='provider-cards' key={provider.id}>
                  <ProvidersCard providerModal={providerModal} 
                  provider={provider} key={provider.id}  
                  setPickMealsModal={setPickMealsModal} 
                  setProviderId={setProviderId} 
                  />
                </div>
              )
            }
          </div>
        ) : (
          <NoProviderAvailable text="Oops.. There are currently no providers available, try another address." />
        )}
      </ProviderListStyle>

    {pickMealsModal &&
     <PickMeals
     setProviderModalAnim={setProviderModalAnim}
     pickMealsModal={pickMealsModal} 
     setPickMealsModal={setPickMealsModal} 
     clientName={clientName} 
     followerProfile={followerProfile} 
     setClientModal={setClientModal} 
     setProviderModal={setProviderModal} 
     providerId={providerId} 
     setProviderId={setProviderId} 
     setMealsModalsAnim={setMealsModalsAnim}
     setClientAnim={setClientAnim}
     setMealPlannerClose={setMealPlannerClose}
     setVisibility={setVisibility}
     visibility={visibility}
     />
     }
     
   </>
  )
}