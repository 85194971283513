import styled from "styled-components";
import { useHistory } from 'react-router-dom';

const OnboardCardStyle = styled.div`
  background: #FFFFFF;
  width: 100vw;
  
  .title-des-wrapper{
    font-family: 'Roboto';
    font-style: normal;
    align-items: center;
    text-align: center;
  }

  img {
    height: 53vh;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  h2 {
    font-weight: 700;
    line-height: 28px;
    font-size: 26px;
    color: #51635D;
    width: 298px;
    height: 82px;
    padding-top: 23%;
    margin: auto;

  }

  p {
     font-weight: 400;
     font-size: 15px;
     line-height: 26px;
     color: #949D9B;
     width: 319px;
     height: 82px;
     margin: auto;
    padding-top: 14%; 
  }

  button {
    width: 140px;
    height: 40px;
    left: 118px;
    margin-top: 18%;
    background: #52C41A;
    border-radius: 21px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }

  @media(min-width: 992px){
    h2 {
      padding-top: 2%;
    }

    p{
      padding-top: 0%;
    }
    button{
      margin-top: 2% !important;
    }
  }

  @media(max-width: 380px) {
    button {
      margin-top: 11%;
    }
  }

  @media(min-width: 992px){
    width: 667px;
  }
`;

export default function (props) {
  const { icon, title, des, showButton, setShowDietPlans } = props;
  const history = useHistory();
  return (
    <OnboardCardStyle>
      <img src={icon} alt="icon"/>
      <div className="title-des-wrapper">
        <h2>{title}</h2>
        <p>{des}</p>
        { showButton && (
          <button onClick={() => setShowDietPlans? setShowDietPlans(true) : history.push('/booking/one-to-one-meeting')}>Get Started</button>
        )}
      </div>
    </OnboardCardStyle>
  )
}