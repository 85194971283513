import styled from 'styled-components';
import MealCard from './components/MealCard';
import { useAppContext } from 'context/appContext';
import { BsArrowLeft } from 'react-icons/bs';
import ProviderCard from './components/ProviderCard'
import CustomTooltip from './components/CustomTooltip';
import Slider from "react-slick";
import { BsArrowRight } from 'react-icons/bs';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState,useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useRef } from 'react';

const OrderDetailsSectionStyle = styled.div`
    position: relative;
    flex: 1;
    padding: 20px 0px 70px;
    background-color: #F6F5F8;
    overflow: hidden;
    height: 100vh;
    .order_header{
        margin-bottom: 20px;
        .title{
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #51635D;
        }
    }
    .total_amount{
        position: fixed;
        bottom: 0;
        display: flex;
        background: #FFFFFF;
        border-top: 1px solid #E0E3E0;
        height: 60px;
        width: calc(100vw - 744px);
        margin-left: -25px;
        padding: 0 80px 0 48px;
        align-items: center;
        justify-content: flex-end;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
        .nutritt_profit{
            display: flex;
            gap: 5px;
            font-size: 14px;
            line-height: 16px;
            .title{
                font-weight: 400;
                color: #949D9B;
            }
            .value{
                font-weight: 700;
                color: #51635D;
            }
        }
        .user_charge{
            display: flex;
            gap: 5px;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #51635D;
            .value{
                font-weight: 700;
            }
        }
    }
    .order_meals{
        flex: 1;
        padding-bottom: 60px;
        .heading{
            display: flex;
            padding: 0px 32px 12px 24px;
            .title{
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #51635D;
                width: 13%;
                &:first-child{
                    margin-left: 78px;
                    flex: 1;
                    width: auto;
                }
                &:last-child{
                    text-align: right;
                }
            }
        }
    }
    .details_wrapper{
        padding: 0 48px;
        height: calc(100vh - 64px);
        overflow: overlay;
        display: flex;
        flex-direction: column;
    }
    .slick-prev, .slick-next{
        top: -32px !important;
        color: #AFB5B4;
        width: 24px;
        z-index: 99;
    }
    .slick-next{
        right: 48px !important;
    }
    .slick-prev{
        left: 48px !important;
    }
    @media (min-width: 2300px){
        .details_wrapper{
            padding: 0 100px;
        }
        .slick-next{
            right: 100px !important;
        }
        .slick-prev{
            left: 100px !important;
        }
        .total_amount{
            padding: 0 150px 0 120px;
            margin-left: -100px;
        }
    }
    @media (min-width: 1441px) and (max-width: 2299px){
        .total_amount{
            margin-left: -48px;
        }
    }
    @media (max-width: 1440px){
        .details_wrapper{
            padding: 0 24px;
        }
        .slick-next{
            right: 30px !important;
        }
        .slick-prev{
            left: 30px !important;
        }
        .total_amount{
            width: calc(100vw - 705px);
        }
    }
    @media (max-width: 991px){
        padding: 63px 0px 0;
        position: fixed;
        inset: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        background-color: #F6F5F8;
        .order_header{
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            z-index: 9;
            border: 1px solid #E0E3E0;
            background-color: #fff;
            .title{
                padding: 22px 0 15px;
            }
            .icon{
                color: #52C41A;
                stroke-width: 0.5px;
                position: absolute;
                left: 18px;
                top: 20px;
                font-size: 18px;
            }
            .title{
                font-size: 16px;
            }
        }
        .total_amount{
            width: 100vw;
            padding: 0 34px 0 20px;
            margin-left: 0;
            .user_charge{
                font-size: 16px;
            }
        }
        .order_meals{
            padding: 24px 20px 120px;
        }
        .slick-prev, .slick-next{
            display: none !important;
        }
        .details_wrapper{
            padding: 0;
            height: calc(100vh - 124px);
        }
    }
`

const Progress = styled.div`
    position: relative;
    flex: 1;
    .icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @media (max-width: 991px){
        height: 90%;
    }
`

export default function OrderDetailsSection({setIsOrderDetailsActive, SearchedValue, searchData, setActiveOrderId, activeOrderId, size,sortedSearchOrders,activeTab,searchTerm,isOrdersLoading}){
    
    const {isMobile} = useAppContext();
    const [getOrderDetails, { isLoading, error, state: success, setState: setSuccess }] = useRequest();
    const handleBack = () => {
        setIsOrderDetailsActive(false);
    }
    const [providerOrderData, setProviderOrderData] = useState(null);
    const { coachData, orderData, providerOrder, userData } = providerOrderData || [];
    const sliderRef = useRef();
    let orderId, slickSlideTo, isInit;
    if(activeOrderId.includes('(')){
        let newIdIndex = activeOrderId.indexOf("(");
        let endIndex = activeOrderId.indexOf(")");
        orderId = Number(activeOrderId.slice(0, newIdIndex));
        slickSlideTo = Number(activeOrderId.slice(newIdIndex + 1, endIndex) - 1);
    }else{
        orderId = Number(activeOrderId);
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <BsArrowLeft/>,
        nextArrow: <BsArrowRight />,
        adaptiveHeight: true
    };

    const orderDetails = async () => {
        try{
            const res = await getOrderDetails({path: `admin/orderById/${orderId}`});
            setProviderOrderData(res?.data);
        }
        catch(err){
            if(err!="jwt expired"){
                toast.error("Something went wrong");
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setProviderOrderData(searchData);
            if (SearchedValue.length === 0) {
                orderId > 0 && orderDetails();
            }
        };
        fetchData();
    }, [searchData, SearchedValue, orderId]);

    useEffect(() => {
        sliderRef?.current?.slickGoTo(slickSlideTo);
    }, [slickSlideTo, sliderRef?.current])

    const handleInit = () => {
        isInit = true;
        setTimeout(() => {
            sliderRef?.current?.slickGoTo(slickSlideTo);
        }, 10)
    };

    const handleBeforeChange = (current, next) => {
        isInit = false;
        !isInit && setActiveOrderId(`${orderId}(${next + 1})`)
    };

    const orderDetailsData = useMemo(() =>{
        if(providerOrderData?.providerOrder?.length){
        const providerOrderArray = providerOrderData?.providerOrder?.filter(
            (order) => order?.providerOrderAction === activeTab
          );
          return { ...providerOrderData, providerOrder: providerOrderArray };
        }
        else{
            return providerOrderData;
        }
      },[activeTab,providerOrderData,searchTerm,sortedSearchOrders]);


    return(
        <>
        {(isLoading) && !isMobile && !isOrdersLoading ? <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> :
        <OrderDetailsSectionStyle>
            <div className='order_header'>
                {isMobile &&<BsArrowLeft className='icon' onClick={handleBack}/>}
                <h2 className='title'>{isMobile ? 'Order ID: ':''}{orderDetailsData?.providerOrder?.length ? orderDetailsData?.orderData?.friendlyOrderNum : ""}{orderDetailsData?.orderData?.isTestOrder?"(Test Order)":''}</h2>
            </div>
            {(isLoading || !orderDetailsData) && isMobile ? <Progress><div className='icon'><CircularProgress style={{ color: 'var(--nutritt-green)'}} /></div></Progress> : <>
           {orderDetailsData?.providerOrder?.length>1?
            <Slider ref={sliderRef} {...settings} onInit={handleInit} beforeChange={handleBeforeChange}>
                {orderDetailsData?.providerOrder?.map((order, index) => {
                    return (
                        <div>
                            <div className='details_wrapper' key={index}>
                                <ProviderCard slider={sliderRef} activeOrderId={activeOrderId} providerOrder={orderDetailsData?.providerOrder} order={order} userData={orderDetailsData?.userData} orderData={orderDetailsData?.orderData} coachData={orderDetailsData?.coachData} size={size}/>
                                <div className='order_meals'>
                                    {!isMobile &&
                                    <div className='heading'>
                                        <div className='title'>Meal name</div>
                                        <div className='title'>Qty</div>
                                        <div className='title'><span style={{position: 'relative'}}>Price <CustomTooltip tooltipText={'Price is what the end user pays that includes user markup.'} placement={'bottomRight'} showArrow={false}/></span></div>
                                    </div> }
                                    {order?.meals?.map((meal, index) => 
                                        <MealCard key={index} isMobile={isMobile} {...meal}/>
                                    )}
                                </div>
                                <div>
                                    <div className='total_amount'>
                                        <div className='user_charge'>
                                            <p className='title'>{!isMobile ? 'Total User Charge:':'Total Price:'}</p>
                                            <h3 className='value'>${order?.userTotalCharge}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
            :
            <>
            {orderDetailsData?.providerOrder?.map((order, index) => {
                    return (
                        <div>
                            <div className='details_wrapper' key={index}>
                                <ProviderCard slider={sliderRef} activeOrderId={activeOrderId} providerOrder={orderDetailsData?.providerOrder} order={order} userData={orderDetailsData?.userData} orderData={orderDetailsData?.orderData} coachData={orderDetailsData?.coachData} size={size}/>
                                <div className='order_meals'>
                                    {!isMobile &&
                                    <div className='heading'>
                                        <div className='title'>Meal name</div>
                                        <div className='title'>Qty</div>
                                        <div className='title'><span style={{position: 'relative'}}>Price <CustomTooltip tooltipText={'Price is what the end user pays that includes user markup.'} placement={'bottomRight'} showArrow={false}/></span></div>
                                    </div> }
                                    {order?.meals?.map((meal, index) => 
                                        <MealCard key={index} isMobile={isMobile} {...meal}/>
                                    )}
                                </div>
                                <div>
                                    <div className='total_amount'>
                                        <div className='user_charge'>
                                            <p className='title'>{!isMobile ? 'Total User Charge:':'Total Price:'}</p>
                                            <h3 className='value'>${order?.userTotalCharge}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
            }
            </>}
        </OrderDetailsSectionStyle>
        }
        </>
    )
}