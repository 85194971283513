import classNames from 'classnames';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const FilterMealsDataWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6;
  background-color: rgba(81, 99, 93, 0.65);
  display: flex;
  align-items: center;

  .filter-Meals-container {
    width: 90%;
    margin: 0 auto;
    padding: 24px 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  .filter-title {
    padding: 0 20px;
    color: #0b1d17;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 19px;
  }

  .filter-meals-options {
    margin: 30px 0;
    padding: 0 20px;
  }

  .filter-meal-item {
    color: #51635d;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;

    & + .filter-meal-item {
      margin-top: 24px;
    }

    &.active {
      color: #52c41a;
    }
  }

  @media (min-width: 992px) {
    position: relative;
    background-color: transparent;

    .mask {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
    }

    .filter-Meals-container {
      position: absolute;
      top: 0;
      right: 30px;
      z-index: 6;
      overflow: hidden;
      width: 106px;
      padding: 0;
      background: #ffffff;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    .filter-meals-options {
      margin: 0;
      padding: 0;

      .filter-meal-item {
        height: 32px;
        margin-top: 0;
        padding-left: 15px;
        color: #949d9b;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.active,
        &:hover {
          background-color: #f0faeb;
          color: #52c41a;
        }
      }
    }
  }
`;

export default function FilterMealsData({
  setIsFilterMealsData,
  setMealsDataItem,
  mealsData,
  active,
  setActive,
  setSelectedNutrients
}) {
  const { isMobile, isGuest, isVisitor } = useAppContext();

  return (
    <FilterMealsDataWrapper>
      {!isMobile && <div className="mask" onClick={() => setIsFilterMealsData(false)} />}
      <div className="filter-Meals-container">
        {isMobile && <div className="filter-title">Display</div>}
        <div className="filter-meals-options">
          {mealsData.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setActive(index)
                setSelectedNutrients(item)
              }}
              className={classNames('filter-meal-item', { active: active == index })}
            >
              <span>{item}</span> {(isMobile && active == index) && <img src="/icons/check.svg" alt="Check Icon" />}
            </div>
          ))}
        </div>
        {isMobile && (
          <div className="btns-wrapper">
            <button onClick={() => setIsFilterMealsData(false)}>Cancel</button>
            <button
              className="primary-btn"
              onClick={() => {
                setMealsDataItem(mealsData[active]);
                setIsFilterMealsData(false);
              }}
            >
              Apply
            </button>
          </div>
        )}
      </div>
    </FilterMealsDataWrapper>
  );
}
