import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const ActionButtonWrapper = styled.div`
  position: relative;

  .action-mask {
    overflow: auto;
    position: fixed;
    inset: 0;
    z-index: 9;
  }

  .btn {
    position: relative;
    z-index: 3;
    width: fit-content;
    height: 40px;
    padding: 0 16px;
    color: ${() => theme.colors.secondary};
    border-radius: 8px;
    font-family: ${() => theme.typography.fontOpenSans};
    font-size: ${() => theme.typography.fontRegular};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ isOpen }) => isOpen ? theme.colors.lightGrey : "white"};
  }

  .menu {
    position: absolute;
    top: auto;
    left: auto;
    z-index: 9;
    width: 150px;
    margin-top: 4px;
    padding: 8px 0;
    background-color: ${() => theme.colors.secondary};
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  }

  .menu-item {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:nth-child(even) {
      background-color: #f5f7ff;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    span {
      color: ${() => theme.colors.black};
      font-family: ${() => theme.typography.fontOpenSans};
      font-size: ${() => theme.typography.fontRegular};
      font-weight: 400;
    }
  }
`;
