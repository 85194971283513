import styled from "styled-components"

const TitleStyle = styled.div`
    margin-bottom: 19px;
    .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #0B1D17;
        margin-bottom: 24px;
    }
    .subtitle{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #51635D;
        max-width: 275px;
        margin: auto;
    }
`

export default function Title({ title, subtitle }) {

    return (
        <TitleStyle>
            {title && <h2 className='title'>{title}</h2>}
            {subtitle && <p className='subtitle'>{subtitle}</p>}
        </TitleStyle>
    )
}