import React, {useEffect} from "react";
import {
    useWindowSize,
} from '@react-hook/window-size'

export default function ChatBot() {


    useEffect(() => {
        Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,

        });
        Intercom("show")

        return ()=>{
            Intercom('shutdown');
        }

    }, []);

    



    const [width, height] = useWindowSize();
    return (
        <>
           
        {true}
        </>
    )
};