import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import Steps from 'components/shop/Steps';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Providers from './providers';
import Meals from './Meals';
import Cart from './Cart';
import { ShopContextProvider } from 'context/shopContext';
import ChooseMealPlan from 'pages/shop/ChooseMealPlan';
import ShopHeader from 'layout/ShopHeader_v2';
import Checkout from './Checkout';
import { useLocation, useParams } from 'react-router-dom';
import CheckOrder from './Checkout/CheckOrder';
import CheckPayment from './Checkout/CheckPayment';
import Thanks from './Checkout/Thanks';
import Pantry from 'pages/Pantry';
import { useAppContext } from 'context/appContext';
import TermsOfUse from 'pages/TermsOfUse';
import TermsAndConditions from 'pages/termsAndConditions';
import Profile from 'pages/Profile';
import ProfileEdit from 'pages/ProfileEdit';
import Order from './Order';
import OrderStatus from './Order/order-status';
import CoachProfile from './CoachProfile';
import MealPlansList from './CoachProfile/MealPlansList';
import OfferPlansList from './CoachProfile/OfferPlansList';
import BrowseDietPlan from 'pages/BrowseDietPlan';
import MyCoach from './VisitorUser/MyCoach';
import { useShopContext} from 'context/shopContext';
import ChatWithCoach from './ChatWithCoach';
import { ChatContextProvider } from 'context/chatContext';
import { BottomNavigation } from './BottomNavigation/BottomNavigation';
import { Account } from './account/Account';
import DietPreferences from './dietPreferences/DietPreferences';
import AddressEdit from 'pages/AddressEdit';
import { MyProfile } from './myProfile/MyProfile';
import { HealthGoal } from './healthGoal/HealthGoal';
import CurrentWeight from './healthGoal/CurrentWeight';
import { GilroyFontWrapper } from 'shared/theme/style';
import ActivityLevel from './healthGoal/ActivityLevel';
import DesiredGoalPace from './healthGoal/DesiredGoalPace';
import { Notifications } from './notifications/Notifications';
import UserGender from './healthGoal/Gender';
import Height from './healthGoal/Height';
import AccountRoutes from './account/AccountRoutes';
import FavoriteKitchen from 'pages/shop/FavoriteKitchen';
import { CartScreen } from './cart/CartScreen';
import TrackQuiz from 'pages/shop/TrackQuiz';
import KitchenHome from 'pages/shop/KitchenHome';
import Thankyou from './Checkout/Thankyou';
import FooterDesktop from 'components/shop/FooterDesktop';
import CheckDeliveryAvailable from 'components/CheckDeliveryAvailable/CheckDeliveryAvailable';
import PhoneNumber from 'pages/UserOnboarding/desktop/PhoneNumber';
import PhoneVerify from 'pages/UserOnboarding/desktop/PhoneVerify';
import { AnimatePresence, motion } from 'framer-motion';

export const ShopStyle = styled.div`
  min-height: calc(100vh - 141px);
  &.coachPlan{
    overflow-x: hidden;
 }
  padding-top: 76px;
  &.shopMeals {
    height: 100vh;
    padding-top: 0;
  }
  ${'' /* .shop-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 120;
  } */}
  .header-sandwich-menu {
    width: 32px;
    height: 32px;
    margin: 0 24px 0 0;
  }

  &.pantryHeader {
    width: 100%;
    height: 100vh;
    padding-top: 0;
    background-color: #f6f5f8 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .shop-header {
      transition: all 0.3s ease-in-out;  
      width: 100%;
      transition: all 0.3s ease-in-out;
    }
  }

  @media (max-width: 991px) {
    min-height: auto;
    padding-top: 0;
    &.shopMeals {
      height: auto;
    }
    &.pantryHeader header {
      background-color: ${props => props.showChangeDietPlan ? "#FFFFFF" : "#f6f6f8"};
    }
    .shop-header {
      padding-top: 48px;
    }
    .header-sandwich-menu {
      width: auto;
      height: auto;
      margin: 0 0 0 10px;
    }

    span.cart-number {
      left: 10px;
      top: -7px;
      z-index: 10;
      width: auto;
      min-width: 15px;
      height: auto;
      padding: 1px 2px;
      color: #ffffff;
      font-size: 9px;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.05em;
    }

    .place-order-wrapper {
      height: 80px;
      border-radius: 0;
      box-shadow: none;

      button {
        width: 287px;
        height: 42px;
        color: #fff;
        border-radius: 21px;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        flex-shrink: 0;
      }
    }
  }
`;


export default function Shop({ user }) {

  const [scrollUp, setScrollUp] = useState(false);
  const [prevScroll, setPrevScroll] = useState(window.scrollY);
  const location = useLocation();
  const { isMobile, isVisitor,setUser, routeBeforeSignup, removeBgColor} = useAppContext();
  const mobStyle = isMobile ? { background: '#F5F7F8' } : undefined;
  const { showChangeDietPlan } = useShopContext();
  const [createForm, setCreateForm] = useState(false);
  const [getForm, setGetForm] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [link, setLink] = useState();

  window.onscroll = function () {
    if (location.pathname === '/shop/providers') {
      const currScroll = window.scrollY;
      if (prevScroll < currScroll || currScroll < 110) {
        setScrollUp(false);
      } else if (prevScroll > currScroll) {
        setScrollUp(true);
      }
      setPrevScroll(currScroll);
    }
  };

  const bottomNavigationIncludedRoutes = ['/shop/plan', '/shop/pantry', '/shop/order', '/shop/account', '/shop/account/diet-preferences', '/shop/account/health-goal', '/shop/track-quiz', '/shop/kitchen-home'];
  const headerIncludedRoutes = ['/shop/plan', 'shop/pantry', '/shop/kitchen-home'];
  const desktopFooterRoutes = ['/shop/kitchen-home', '/shop/checkout/order']

  return (
    <ShopContextProvider>
      <ChatContextProvider>
      <GilroyFontWrapper>
      <ShopStyle location={location}
      showChangeDietPlan
        className={classNames({pantryHeader: location.pathname === '/shop/pantry',
          shopMeals: location.pathname === '/shop/meals', coachPlan: location.pathname === '/shop/my-coach/mealplans'
        })}
        style={mobStyle}
      >
        {(headerIncludedRoutes.includes(location.pathname) || !isMobile && location.pathname !== '/shop/checkout/order') &&
        <div
          className={classNames('shop-header', {
            headerFixed: location.pathname === '/shop/providers' && scrollUp,
          })}
        >
          <ShopHeader />
          {!isMobile && !location.pathname === '/shop/pantry' && <Steps />}
        </div>
        }
        <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/store/:providerId" render={() => (
            <CheckDeliveryAvailable isHomepage={false}>
              <FavoriteKitchen />
            </CheckDeliveryAvailable>
          )} />
          <Route exact path="/shop">
            {routeBeforeSignup ? 
              <Redirect to={routeBeforeSignup} />
              :
              <Redirect to="/shop/kitchen-home" />
          }
            {/* {user?.orderCount > 0 ? <Redirect to="/shop/pantry" /> : <Redirect to="/shop/plan" />} */}
          </Route>
          <Route exact path="/shop/phoneNumber" component={PhoneNumber} />
          <Route exact path="/shop/verify-phone" component={PhoneVerify} />
          <Route exact path="/shop/plan" component={ChooseMealPlan} />
          <Route exact path="/shop/providers" component={Providers} />
          <Route exact path="/shop/meals" component={Meals} />
          <Route exact path="/shop/cart" component={Cart} />
          <Route exact path="/shop/checkout" render={() => <Redirect to="/shop/cartscreen" />} />
          <Route exact path="/shop/checkout/order" component={CheckOrder} />
          <Route exact path="/shop/checkout/checkPayment" component={CheckPayment} />
          <Route exact path="/shop/checkout/thanks" component={Thankyou} />
          <Route exact path="/shop/pantry" component={Pantry} />
          <Route exact path="/shop/browse-plan" component={BrowseDietPlan} />
          <Route exact path="/shop/myProfile" component={MyProfile}/>
          <Route exact path="/shop/notifications" component={Notifications}/>
          <Route exact path="/shop/cartscreen" component={CartScreen}/>

          <Route exact path="/shop/profile">
          <Profile setCreateForm={setCreateForm} createForm={createForm} />
          </Route>
          <Route exact path="/shop/profile/edit" component={ProfileEdit} />
          <Route exact path="/shop/profile/add-address">
          <AddressEdit 
          createForm={createForm} 
          setCreateForm={setCreateForm}
          //getForm={setGetForm}
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          link={link}
          />
          </Route> 
          <Route exact path="/shop/profile/edit-address/:id">
          <AddressEdit 
          createForm={createForm} 
          setCreateForm={setCreateForm}
          //getForm={(value) => setGetForm(value)}
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          link={link}
          />
          </Route> 
          <Route exact path="/shop/order" component={Order} />
          <Route exact path="/shop/order-status" component={OrderStatus} />
          <Route exact path="/shop/my-coach" component={CoachProfile} />
          <Route exact path="/shop/chat-with-coach" component={ChatWithCoach} />
          <Route exact path="/shop/all-coach" component={MyCoach} />
          <Route exact path="/shop/my-coach/mealplans" component={MealPlansList} />
          <Route exact path="/shop/my-coach/offers" component={OfferPlansList} />
          <Route exact path="/shop/kitchen-meals" render={() => (
            <CheckDeliveryAvailable>
              <FavoriteKitchen />
            </CheckDeliveryAvailable>
          )} />
          <Route exact path="/shop/kitchen-home" render={() => (
            <CheckDeliveryAvailable>
              <KitchenHome />
            </CheckDeliveryAvailable>
          )} />
          <Route exact path="/shop/track-quiz" component={TrackQuiz} />
          <Route path="/shop/account">
            <AccountRoutes />
          </Route>
          <Redirect to="/shop/kitchen-home" />
        </Switch>
        </AnimatePresence>
        {bottomNavigationIncludedRoutes.includes(location.pathname) && isMobile && <BottomNavigation />}
        {!isMobile && desktopFooterRoutes.includes(location.pathname) && <FooterDesktop />}
      </ShopStyle>
      </GilroyFontWrapper>
      </ChatContextProvider>
    </ShopContextProvider>
  );
}

 
