import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
  padding: 14px 16px 15px;
  font-family: ${() => theme.typography.fontGilroy};

  .title-wrapper {
    color: ${() => theme.colors.deepForestGreen};
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      text-transform: capitalize;
    }
    .min-order {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
    }
  }
.not-delivery{
  padding: 12px 15px 15px 17px;
  background: #FEEBEB;
  p{
    color: #D13726;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    span{
      line-height: normal;
      text-decoration-line: underline;
      text-underline-offset: 5px;
    }
  }
}
  .delivery-date-wrapper {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    .delivery-date {
      display: flex;
    }
    .date {
      margin-left: 5px;
      text-transform: capitalize;
    }
  }

  .features-wrapper {
    margin-top: 15px;
    display: flex;
    justify-items: center;
    gap: 7.5px;
    .feature-details {
      height: 64px;
      background-color: #f5f7f8;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .feature-1 {
      width: 29%;
    }
    .feature-2 {
      width: 38%;
    }
    .feature-3 {
      width: 33%;
    }
    .feature-title-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;
      .feature-title {
        color: ${() => theme.colors.black};
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
      }
    }
    .feature-info {
      color: ${() => theme.colors.grayDarker};
      font-size: 11px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .description-text {
    position: relative;
    overflow: hidden;
    margin-top: 12px;
    color: ${() => theme.colors.primaryDefault};
    font-size: 14px;
    font-family: Gilroy;
    font-weight: 400;
    line-height: 20px;
    .LinesEllipsis {
      position: relative;
      overflow: hidden;
      width: 100%;
      display: block;
      &.LinesEllipsis--clamped {
        height: 60px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
      }
      .LinesEllipsis-ellipsis {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        background-color: ${() => theme.colors.secondary};
      }
      &.textMoreLines {
        height: auto;
        display: inline-flex;
      }
    }
    .see-more,
    .hide-text {
      font-weight: 600;
    }
    .hide-text {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      background-color: ${() => theme.colors.secondary};
      text-align: right;
    }
  }
`;
