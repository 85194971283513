import { useState } from 'react';
import styled from 'styled-components';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

const NutritionistPopularPlanStyle = styled.div`
@media screen and (max-width: 767px){
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%; 
  margin-bottom: 24px;
}
.upcoming {
  .upcoming-head{
    @media screen and (max-width: 767px){
      height: 60px; 
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #E0E3E0;
    }
    p{
      @media screen and (max-width: 767px){
        font-size:16px;
        font-weight: 700;
      }
    }
    .MuiTabs-flexContainer {
      justify-content: center;
      .MuiTab-root {
        padding: 0px !important;
        width: 147.5px;
      }
    }
  }
  .session {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #52c41a;
    margin: 20px 0px 0px 20px;
    cursor:pointer;
    @media screen and (max-width: 767px){
      font-size: 16px;
      padding: 30px 20px 30px 24px;
      u{
        text-decoration:none;
      }
    }
  }
}
.upcoming {
  .upcoming-head{
    @media screen and (min-width:1441px) and (max-width: 1920px){
      margin: 0px 48px !important;
    }
  }
  .session{
    justify-content: flex-start !important;
    left: 48px;
    width:auto !important;
  }
  .plan-section{
    padding: 20px;
    @media screen and (min-width:1441px) and (max-width: 1920px){
      padding: 20px 48px
    }
    @media screen and (max-width: 767px){
      padding: 20px 24px 10px;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .content {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #51635D;
    }
  }
  .upcoming-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #e0e3e0;
    p {
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #0b1d17;
    }
  }
  .MuiTabs-flexContainer {
    justify-content: center;
    .MuiTab-root {
      padding: 0px !important;
      width: 147.5px;
    }
  }
}
.no-plan{
  padding:10px 20px;
  color: #52c41a;
  font-weight: 400;
  font-size: 16px;
  cursor:pointer;
  @media(min-width:992px){
    margin-top:30px;
    margin-left:20px;
   font-size: 14px !important;
     
  }
}
@media(min-width:992px){
  .session1{
    position: absolute;
    bottom: 15px;
  }
}
`
{
  TabPanel;
}

export default function NutritionistPopularPlan({ mostPopularMealPlan, modalShow, setModalshow }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { isMobile } = useAppContext();
  const history = useHistory();
  const mostPopPlan = mostPopularMealPlan?.maxOrderedMeal && Object.keys(mostPopularMealPlan?.maxOrderedMeal).length;
  const handleClick = () =>{
    if (isMobile) {
      history.push('/nutritionist/Challanges');
      setModalshow(true);
    } else {
      history.push('/nutritionist/meal-plan/new');
    }
  }
  return (
    <NutritionistPopularPlanStyle>
      <div className='upcoming'>
        <div className='upcoming-head'>
          <p className='plan-title'>Most Popular meal plan</p>
        </div>
        <div className='plan-section'>
          {/* <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Meal Plan" />
           </Tabs>
           <TabPanel value={value} index={0}> */}
          <h3>{mostPopularMealPlan?.maxOrderedMeal?.name}</h3>
          {/* <h3 className='content'> {mostPopularMealPlan?.maxOrderedMeal?.introduction}</h3> */}
          <MealPlan mostPopularMealPlan={mostPopularMealPlan} />
          {/* </TabPanel> */}
        </div>
        {
         ( mostPopularMealPlan && mostPopPlan) ? (
            <div className='session' onClick={() => isMobile ? (history.push('Challanges'), window.scrollTo(0,0)) : history.push('meal-plan')}>
              <p>{isMobile ? "See all Challenges" : "See all Meal Plans"}</p>
            </div>
          )
            :
            <div className='session1' onClick={handleClick}>
              <p className='no-plan'><u>{isMobile ? "Create a Challenge" : "Create a Meal Plan"}</u></p>
            </div>
        }

      </div>

    </NutritionistPopularPlanStyle>
  )
}

const MealPlanStyle = styled.div`
display:flex;
flex-direction:column;
align-items:center;
.meal-plan-div{
    width:100%;
 .plan-name{
    p{
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #0B1D17;      
    }
 }
 .plan-description{
    margin-top:10px;
    p{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #51635D;   
    }
 }
 .section3{
    display: grid;
    grid-template-rows: 75px 75px 75px;
    justify-content: center;
    border-radius: 10px;
    margin-top:10px;
    grid-template-columns: 100%;
    @media screen and (max-width: 767px){
      display: block;
    }
    .earnings{
        background:#FFFFFF;
        color:#FFFFFF;
        display: flex;
        margin-top:30px;
        align-items: center;
        width: 100%;
        column-gap: 15px;
        @media screen and (max-width: 767px){
          margin-top: 34px;
        }
        .icon{
            width: 48px;
            height: 48px;
        }

        .current{
            p{
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color:#949D9B;
                width:170px;
            }
           span{
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 24px;
            line-height: 38px;
            color: #0B1D17;
           }
        }
    }

    .clients{
        background:#FFFFFF;
        display: flex;
        align-items: center;
        width: 100%;
        margin-top:55px;
        column-gap: 15px;
        @media screen and (max-width: 767px){
          margin-top: 34px;
        }
        .icon{
            width: 48px;
            height: 48px;
        }

        .current{
            p{
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color:#949D9B;
                width:170px;
            }
           span{
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 24px;
            line-height: 38px;
           }
        }
    }

    .orders{
        background:#FFFFFF;
        display: flex;
        align-items: center;
        width: 100%;
        margin-top:80px;
        column-gap: 15px;
        @media screen and (max-width: 767px){
          margin-top: 34px;
        }
        .icon{
            width: 48px;
            height: 48px;
        }
        .current{
            p{
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color:#949D9B;
                width:170px;

            }
           span{
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 24px;
            line-height: 38px;
           }
        }
    }
}

}
@media (min-width:992px){
    .section3{
        display:grid;
    grid-template-rows: 60px 60px 60px !important;
    }
}
@media (min-width:992px) and (max-width:1340px){
    .meal-plan-div{
        .plan-name p{
            font-size:15px !important;
            line-height:20px !important;
        }
        .plan-description{
            margin-top:6px !important;
            p{
                font-size: 13px !important;
                line-height: 16px !important;
            }
        }
        .section3{
            display:grid;
    grid-template-rows: 50px 50px 50px !important;
            margin-top:5px !important;
            .earnings{
                .current{
                    p{
                        font-size: 15px !important;
                        line-height: 19px !important;
                    }
                    span{
                        font-size: 18px !important;
                    line-height: 28px !important;
                    }
                }
            }
            .clients{
                .current{
                    p{
                        font-size: 15px !important;
                        line-height: 19px !important;
                    }
                    span{
                        font-size: 18px !important;
                    line-height: 28px !important;
                    }
                }
            }
            .orders{
                .current{
                    p{
                        font-size: 15px !important;
                        line-height: 19px !important;
                    }
                    span{
                        font-size: 18px !important;
                    line-height: 28px !important;
                    }
                }
            }
        }
    }

}
`

export function MealPlan({ mostPopularMealPlan }) {
  return (
    <MealPlanStyle>
      <div className='meal-plan-div'>
        {/* <div className='plan-name'>
          <p>{mostPopularMealPlan?.maxOrderedMeal?.name || ''}</p>
        </div> */}
        <div className='plan-description'>
          <p>{mostPopularMealPlan?.maxOrderedMeal?.introduction}</p>
        </div>
        <div className='section3'>
          <div className='earnings'>
            <img className='icon' src="/icons/dollar-icon.svg" alt="Arrow Icon" />
            <div className='current'>
              <p>Earnings</p>
              <span>${!isNaN(mostPopularMealPlan?.totalEarnings) ? (mostPopularMealPlan?.totalEarnings / 100).toFixed(2) : '0.00'}</span>
            </div>
          </div>
          <div className='clients'>
            <img className='icon' src="/icons/people-icon.svg" alt="Arrow Icon" />
            <div className='current'>
              <p>Clients</p>
              <span>{mostPopularMealPlan?.totalClients || 0}</span>
            </div>
          </div>
          <div className='orders'>
            <img className='icon' src="/icons/Icon-cart.png" alt="Arrow Icon" />
            <div className='current'>
              <p>Orders</p>
              <span>{mostPopularMealPlan?.totalOrders || 0}</span>
            </div>
          </div>
        </div>
      </div>
    </MealPlanStyle>
  )
}