import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Select from 'components/Select';
import DatePicker from 'react-multi-date-picker';
import RegularInput from 'components/Input/RegularInput';
import { NewConditionWrapper } from './style';
import { FieldTypes, TotalFields, WeekDays, initConditions, FieldKeys } from '../Data';

export default function NewCondition({ setConditions, condition, setFilterDisabled }) {
  const [isCalendar, setIsCalendar] = useState(false);
  const [filterKey, setFilterKey] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [filterTotal, setFilterTotal] = useState(null);
  const [inputNumber, setInputNumber] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const calendarRef = useRef();

  const updateCondition = (newCondition) =>
    setConditions((prev) =>
      prev.map((con) =>
        con.id === condition.id ? { ...condition, ...newCondition, id: condition.id } : con,
      ),
    );

  const renderInputs = () => {
    if (filterKey === FieldKeys.orderAt || filterKey === FieldKeys.scheduledFor) {
      return (
        <>
          <RegularInput width={window.innerWidth > 1400 ? '204px' : '180px'} value="Between" />
          <div className={classNames('date-range-wrapper', { displayCalendar: isCalendar })}>
            <DatePicker
              ref={calendarRef}
              value={dateRange}
              onChange={(dateObjects) => {
                setDateRange(dateObjects);
                const firstDate = moment(dateObjects[0]?.format('MM-DD-YYYY hh:mm a'))
                  .toJSON()
                  .split('T')[0];
                const secondDate = moment(dateObjects[1]?.format('MM-DD-YYYY hh:mm a'))
                  .toJSON()
                  .split('T')[0];
                updateCondition({ [filterKey]: `between ${firstDate}_${secondDate}` });
                dateObjects[1] && calendarRef.current.closeCalendar();
              }}
              format="MMMM D"
              dateSeparator=" - "
              placeholder="Select date range"
              arrow={false}
              onOpen={() => setIsCalendar(true)}
              onClose={() => setIsCalendar(false)}
              monthYearSeparator=" "
              weekDays={WeekDays}
              editable={false}
              maxDate={new Date()}
              renderButton={(direction, handleClick) => (
                <div onClick={handleClick} className="direction-month-icon">
                  {direction === 'right' ? (
                    <img src="/icons/angle-right-grayDark.svg" alt="Angle Icon" />
                  ) : (
                    <img src="/icons/angle-left-grayDark.svg" alt="Angle Icon" />
                  )}
                </div>
              )}
              showOtherDays
              range
            />
            <img
              src="/icons/angle-down-lighterGrey.svg"
              alt="Angle Icon"
              className="input-arrow"
              onClick={() => !isCalendar && calendarRef.current.openCalendar()}
            />
          </div>
        </>
      );
    }
    if (filterKey === FieldKeys.total || filterKey === FieldKeys.items) {
      return (
        <>
          <Select
            key={`${filterKey}-select`}
            placeholder="Select"
            options={['', ...Object.values(TotalFields)]}
            updateValue={(value) => {
              const splitValue = value.split(' ');
              const formatValue =
                value?.length &&
                splitValue[0].toLowerCase() +
                  splitValue[1][0].toUpperCase() +
                  splitValue[1].slice(1);
              setFilterTotal(formatValue);
              updateCondition({ [filterKey]: `${formatValue} ${inputNumber}` });
            }}
            value={filterTotal}
            icon="angle-down-lighterGrey"
          />
          <RegularInput
            key={`${filterKey}-number`}
            type="number"
            placeholder="Input"
            width={window.innerWidth > 1400 ? '204px' : '180px'}
            onChange={(e) => {
              setInputNumber(e.target.value);
              updateCondition({ [filterKey]: `${filterTotal} ${e.target.value || 0}` });
            }}
            value={inputNumber}
          />
        </>
      );
    }

    return (
      <>
        <Select placeholder="Select" icon="angle-down-lighterGrey" isDisabled />
        <RegularInput
          id="disabled"
          placeholder="Input"
          width={window.innerWidth > 1400 ? '204px' : '180px'}
          disabled
        />
      </>
    );
  };

  return (
    <NewConditionWrapper>
      <Select
        placeholder="Select field"
        options={['', ...Object.values(FieldTypes)]}
        updateValue={(value) => {
          if (value !== filterValue) {
            setFilterValue(value);
            setFilterKey(Object.keys(FieldTypes).find((key) => FieldTypes[key] === value));
            updateCondition(initConditions);
            setInputNumber();
            setDateRange();
          }
        }}
        value={filterValue}
        icon="angle-down-lighterGrey"
      />
      {renderInputs()}
    </NewConditionWrapper>
  );
}
