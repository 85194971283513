import { useState, useEffect } from 'react';
import { Line } from 'rc-progress';
import classNames from 'classnames';
import styled from 'styled-components';

const ProgressMacroLineWrapper = styled.div`
  padding: 0 40px 0 39px;
  &.isDiaryModal {
    display: flex;
    align-items: center;
    padding: 0;
    .title-progress-wrapper {
      display: flex;
      align-items: center;
    }
    .item-content {
      width: 50px;
      color: #000A2C;
      font-size: 12px;
      line-height: 14px;
      flex-shrink: 0;
      font-weight: 600;
      margin-right: 6px;
    }
    .diary-percent {
      width: 70px;
      text-align: right;
      flex-shrink: 0;
      white-space: nowrap;
    }
  }
  &.progressMacroHeight + &.progressMacroHeight {
    margin-top: 8px;
  }
  & + & {
    margin-top: 14px;
  }
  .item-wrapper {
    color: #000A2C;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    &:empty{
      display: none;
    }
  }
  span {
    color: #000A2C;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    &:last-child {
      font-weight: 500;
    }
  }
`;

export default function ProgressMacroLine({
  slide,
  item,
  consumedMealItem = 0,
  userDietMacro,
  lineColor,
  isDiaryModal,
  itemContent,
  weight,
  fixed,
  showMacros,
  dietMetricsValue,
  currentDayValue,
  isSwipe,
  height = "6px",
  animate = true
}) {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (!isDiaryModal && !fixed && !showMacros) {
      const macroPercent = userDietMacro !== 0 ? (consumedMealItem / userDietMacro) * 100 : 100;
      animateProgressBar(macroPercent);
    }
  }, [slide, consumedMealItem, isDiaryModal, fixed, showMacros]);

  const animateProgressBar = (targetPercent) => {
    const duration = 1000; // Animation duration in milliseconds (adjust as needed)
    const startTime = Date.now();

    const animate = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const currentPercent = Math.min((elapsed / duration) * targetPercent, targetPercent);

      setPercent(currentPercent);

      if (elapsed < duration) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };
  return (
    <ProgressMacroLineWrapper
      className={classNames({ progressMacroHeight: window.innerHeight < 650 }, { isDiaryModal })}
    >
      <div className="title-progress-wrapper">
        <div className="item-wrapper">
          {item && <span>{item}</span>}
          {/* {isMacroItem && !isDiaryModal && ( */}
          {!isDiaryModal && (
            <span>
         {isSwipe ? `${Math.round(userDietMacro)}`   : `${parseInt(consumedMealItem)}`}
              {weight}
            </span>
          )}
        </div>
        {itemContent && <div className="item-content">{itemContent}</div>}
        <Line
          percent={(isDiaryModal || fixed || showMacros) ? currentDayValue >= dietMetricsValue ? (currentDayValue / userDietMacro) * 100 : (consumedMealItem / userDietMacro) * 100 : percent}
          strokeColor={lineColor}
          trailColor="#E0E3E0"
          height={height}
          strokeLinecap="round"
          style={{ width: '100%', borderRadius: '10px', display: 'block' }}
        />
      </div>
      <span className="diary-percent">
        {isDiaryModal && (
          <>
            {consumedMealItem > 99999 ? '99999' : Math.round(consumedMealItem)} / {userDietMacro}g
          </>
        )}
      </span>
    </ProgressMacroLineWrapper>
  );
}