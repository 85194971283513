import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const HurryOrderWrapper = styled.div`
  padding: 0 16px;
  background-color: #f5f7f8;

  .order-before-wrapper {
    padding: 22px 0 10px;
    border-bottom: 1px solid #cad8d7;
    display: flex;
    justify-content: space-between;
    .main-text {
      width: 113px;
      color: ${() => theme.colors.primaryDarker};
      font-family: 'Bebas Neue';
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.45);
    }
  }

  .countdown-time-wrapper {
    display: flex;
    .time-wrapper {
      display: flex;
      &:last-child .separate {
        display: none;
      }
    }
    .time-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      .time {
        width: 41px;
        height: 40px;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.8);
        color: ${() => theme.colors.primaryDarker};
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        box-shadow: 1px 1px 0px 0px rgba(35, 153, 191, 0.26);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .label {
        width: 41px;
        color: ${() => theme.colors.grayDarker};
        font-size: 8px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.56px;
        text-transform: uppercase;
      }
    }
    .separate {
      width: 4px;
      height: 40px;
      margin: 0 5px;
      color: ${() => theme.colors.grayDarker};
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
  }

  .meals-freshly-wrapper {
    padding: 8px 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .meals-freshly-title {
      font-family: 'Bebas Neue';
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      gap: 2px;
      .title {
        color: ${() => theme.colors.grayDarker};
        line-height: 20px;
      }
      .date {
        color: ${() => theme.colors.primaryDefault};
      }
    }
  }

  .order-btn-wrapper {
    .btn.dark {
      width: 104px;
      height: 32px;
      padding: 4px 12px 4px 12px;
      border-radius: 20px;
      font-size: 15px;
      font-family: Gilroy;
    }
  }

  @media (min-width: 992px) {
    height: 92px;
    margin-bottom: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .order-before-wrapper {
      margin-right: 13px;
      padding: 0;
      border-bottom: 0;
      align-items: center;
      gap: 13px;
      .main-text {
        width: 100%;
        font-size: 48px;
        line-height: 36px;
      }
    }

    .countdown-time-wrapper {
      .time-container .time {
        height: 41px;
      }
    }

    .meals-freshly-wrapper {
      padding: 0;
      .meals-freshly-title {
        font-size: 32px;
        flex-direction: row;
        align-items: center;
        .title {

        }
      }
    }
  }
`;
