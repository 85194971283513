import React from 'react';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { useAppContext } from 'context/appContext';
import Progress from 'components/Progress';
import ErrorComponent from 'components/ErrorComponent';
import PayoutsTable from './PayoutsTable';

export default function Payouts() {
  const { provider } = useAppContext();
  const [pay, setPay] = useState();
  const [disbursements, setdisbursements] = useState();
  const [upcomingDisbursement, { isLoading, error }] = useRequest(`financials/upcoming-disbursement?providerId=${provider?.id}&limit=${10000}`);
  const [getDisbursements, { isLoading: isLoaded }] = useRequest(
    `financials/disbursement-history?providerId=${provider?.id}&limit=${10000}`,
  );

  useEffect(async () => {
    await upcomingDisbursement().then((res) => {
      setPay(res);
    });
    await getDisbursements().then((res) => {
      setdisbursements(res?.data?.disbursements);
    });
  }, []);
  if (isLoading || isLoaded) return <Progress />;
  if (error) return <ErrorComponent error={error} />;

  return !disbursements && !pay ? (
    <Progress />
  ) : (
    <PayoutsTable disbursements={disbursements} pay={pay} />
  );
}

