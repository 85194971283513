import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { isEmailValid } from 'components/auth/utils';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import { useOnboardingContext } from '../index';
import PageTransition from '../components/PageTransition';
import RegularInput from 'components/Input/RegularInput';
import { GilroyFontWrapper } from 'shared/theme/style';
import { Button } from 'components/elements/button/Button';
import GoogleLogin from 'react-google-login';
import { setKitchenToSession } from 'utils/setFavoriteKitchen';


const SignInStyled = styled.div`
width: 100vw;
height: 100vh;
z-index: 99;
position: fixed;
inset: 0;
background: #F0F2F4;
display: flex;
justify-content: center;
align-items: center;
.modal {
  max-width: 536px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 58px 48px 48px 48px;
  display: flex;
  flex-direction: column;
   .heading{
     display: flex;
     flex-direction: column;
     gap: 12px;
    .title{
      color: #000A2C;
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .desc{
      color: #757E89;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
   }
   .button{
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 81px;
    button{
        span{
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
      :disabled {
        background-color: #F0F2F4 !Important;
        color: #9CA3AD !Important;
      }
    }
    .btn{
        height: 42px;
        width:153px;  
        border-radius: 21px;  
    }
  }

  .OR {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #6F7380;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &:before, &:after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 120px;
      background: #00000044;
      position: absolute;
      top: 50%;
    }
    &:before {
      transform: translate(-62%, -50%);
    }
    &:after {
      transform: translate(62%, -50%);
    }
  }
  
  .google-login{
    width: 100%;
    justify-content: center;
    display: flex;

    button{
      height: 42px;
      width: 270px;
      display: flex;
      gap: 6%;
      align-items: center;
      border-radius: 30px !important;
      border: 1px solid #E0E4E8 !important;
      background: #FCFCFD!important;
      padding: 14px 16px 12px 16px !important;
      box-shadow: none !important;
      opacity: 1 !important;
  
      svg{
        display: flex;
        justify-content: center;
        margin: auto;
        width: 24px;
        height: 24px;
      }
      div{
        height: 36px;
        display: flex;
        background: #FCFCFD!important;
        padding: 10px 0 !important;
        transform: translate(80%,0%);
      }
      span{
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        color: #00092D;
      }
    }
  }
  .footer{
    margin-top: 64px;
    text-align: center;
    color: var(--Medium-Grey, #51635D);
    text-align: center;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
     a{
        color: #00092D;
       text-decoration: underline !important;
      }
  }


}

`
const FormGroupStyle = styled.div`
  margin-top: 14px;
  margin-bottom: 24px;
  width: 100%;
  display:flex;
  flex-direction: column;
  gap: 14px;
  label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: inline-block;
  }
  input {
    height: 44px;
    padding: 9px 14px !important;
    border: 1px solid #e0e3e0;
    :hover, :focus {
      border-color: #00092d !important;
    }
    :focus::placeholder {
      color: transparent;
    }
    ::placeholder {
      font-family: Gilroy;
      color: #AFB5B4;
      font-style: normal;
      font-weight: 500;
    }
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 500;
    line-height: 20px;
    color: #000A2C;
  }
`;

export default function SignUp() {
  console.log("signup called.............................");
  const history = useHistory();
  const { setDesktopOnboardingUser, desktopOnboardingUser, userEmailExist, setProfileObj } = useAppContext();
  const [isEmailExist, { isLoading: isEmailVerifying }] = useRequest('auth/isEmailPhoneNumberExist', 'get');
  const validEmail = useMemo(() => isEmailValid(desktopOnboardingUser?.email), [desktopOnboardingUser?.email]);
  const [verifyPhoneNumber, { isLoading: isVerifyPhoneNumber }] = useRequest(`auth/send-otp`, 'post');
  const [signupPhoneVerify, { isLoading: isSignupPhoneVerify }] = useRequest(`auth/v2/send-otp`, 'post');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDesktopOnboardingUser({
      [name]: value,
    });
    setErrorMessage('');
  };

  const handleButtonClick = useCallback(async () => {
      const input = desktopOnboardingUser?.phoneNumber;
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const phoneRegex = /^\+?[0-9]*$/;

      if (emailRegex.test(input)) {
        const res = await isEmailExist({ path: `auth/isEmailPhoneNumberExist?email=${input}` });
        if (res.data.isEmailPhoneNumberExist) {
          history.push(`/account/password?email=${input}`, { from: "/account/home" || "/signup" })
        }
        else {
          history.push('/account/user-details');
        }
        setDesktopOnboardingUser({
          email: input,
        });
      } else if (phoneRegex.test(input)) {
         verifyPhoneNumber({ body: { phoneNumber: desktopOnboardingUser?.phoneNumber } })
          .then((result) => {
            history.push('/account/phone-verify', { from: "/account/home" });
            setDesktopOnboardingUser({
              phoneNumber: extractedNumbers
            })
          })
          .catch((e) => {
            if (e.message === "Phone Number not found.") {
              signupPhoneVerify({ body: { phoneNumber: desktopOnboardingUser?.phoneNumber } })
              .then((result) => {
                history.push('/account/phone-verify');
                setDesktopOnboardingUser({
                  phoneNumber: extractedNumbers
                })
              })
              .catch((e) => {
                console.log(e,'error')
              });
              }
              else{
                setErrorMessage(e.message);
              }
            });
      }
  }, [desktopOnboardingUser, history]);

  useEffect(() => {
    if (!userEmailExist) {
      history.push('/account/phoneNumber');
    }
  }, [userEmailExist])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  useEffect(()=>{
    const params = new URLSearchParams(window?.location?.search);
    const kitchenValue = params?.get('kitchen');
    const kitchenUsername = sessionStorage.getItem('kitchenUsername');
    if( kitchenValue){
      setKitchenToSession(storeUrl, history);
    }
  },[])


  return (
      <GilroyFontWrapper>
        <SignInStyled>
          <div className='modal'>
            <div className='heading'>
              <h1 className='title'>What’s your phone number or email?</h1>
            </div>
            <FormGroupStyle>
              <RegularInput
                type="text"
                name="phoneNumber"
                onChange={handleInputChange}
                value={desktopOnboardingUser?.phoneNumber}
                placeholder="Enter phone number or email"
                onKeyPress={handleKeyPress}
                errorMessage={errorMessage}
              />
            </FormGroupStyle>
            <Button type={'mobile_save'} title="Continue" onClick={handleButtonClick} isDisabled={desktopOnboardingUser?.phoneNumber === '' || isSignupPhoneVerify || isEmailVerifying || isVerifyPhoneNumber || !desktopOnboardingUser} />
            <div className={'OR'}>or</div>
            <div className='google-login'>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Continue with Google"
                onSuccess={(responseGoogle) => {
                  setProfileObj({ responseGoogle });
                }}
                onFailure={(responseGoogle) => {
                  console.log({ responseGoogle, fail: true })
                }}
                cookiePolicy={'single_host_origin'}
              />
            </div>
            <div className="footer">
              <span className="copyright">By continuing you agree with </span>
              <span className="link">
                <a href={'/users/terms-&-conditions'} target="_blank" rel="noopener noreferrer">terms and conditions</a>
              </span>
              <br />
              <span className="link">
                and <a href={"/nutritt/privacy-policy"} target="_blank" rel="noopener noreferrer">privacy policy</a>
              </span>
            </div>

          </div>
        </SignInStyled>
      </GilroyFontWrapper>
  );
}
