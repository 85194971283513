import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { dietPlanDeliveredImages, dietPlanDeliveredMobileImages, dietPlanTabletImages } from './VisitorLandingData';
import { useHistory } from 'react-router-dom';
import DownloadAppStore from '../../assets/download-app-store.png';
import GetOnGooglePlay from '../../assets/get-on-google-play.png';
import { useAppContext } from 'context/appContext';
import DeliveryAddress from './DeliveryAddress';

const FlexColumnCenter = `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DietPlanDeliveredWrapper = styled.div`
  position: relative;
  height: 924px;
  background-image: url(/images/backgroundImage.png);
  background-size: cover;
  background-repeat: no-repeat;
  .delivery-address-wrapper{
    padding: 0px 15px 0px 20px;
  }
  .container {
    ${FlexColumnCenter};
  }

  .absolute{
    position: absolute;
  }

  .fadeIn {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    &.isDisplayImage {
      opacity: 1;
    }
  }

  .dietPlan-imgs-wrapper {
    position: relative;
    width: 100%;
    height: 299px;
    margin: 0 auto 49px;
    text-align: right;
    overflow: clip;
    .nutritt__mat{
      max-width: 825px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      opacity: ${({ isImgLoaded }) => (isImgLoaded ? '1' : '0')};
      animation: ${({ isImgLoaded }) => (isImgLoaded ? "slideRightToLeft 0.3s ease forwards" : "")};
      @media (min-width: 1270px) and (max-width: 1500px){
        max-width: 625px;
      }
    }
  }

  .dietPlan-content-wrapper {
    text-align: center;
    transition-delay: .6s;

    .green{
      color: ${theme.colors.localGreen};
    }
    .dietPlan-btns-wrapper {
      margin-top: 53px;
      gap: 14px;
      ${FlexColumnCenter};

      button {
        width: 220px;
        height: 48px;
        background-color: ${theme.colors.primaryDefault};
        color: ${theme.colors.secondary};
        border-radius: 30px;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
      }

      // button:last-child {
      //   background: #fff;
      //   color: #51635D;
      //   border: 1px solid #52C41A !important;
      // }
    }
  }

  .background-shadow {
    position: absolute;
    bottom: 0;
    left: auto;
    width: 100%;
    height: 108px;
  }

  .download_btns {
    display: flex;
    margin-top: 39px;
    gap: 10px;
    position: relative;
    z-index: 2;
    .app_store {
      max-width: 105px;
      img {
        width: 100%;
        display: block;
      }
    }
    .google_play {
      max-width: 105px;
      img {
        width: 100%;
        display: block;
      }
    }
  }

  @media (min-width: 1025px) {
    font-family: Gilroy;

    .container {
      padding-left: 59px;
      margin: 0;
      flex-direction: row;
      position: relative;
      justify-content: flex-end;
    }

    .dietPlan-imgs-wrapper {
      height: 924px;
      margin: 0;
      flex: 0 0 53%;
        .diet-plan-delivered-5 {
          top: 0;
          left: 20%;
        }
    
        .diet-plan-delivered-6 {
          right: 0;
          max-width: 410px;
          transition-delay: 0.5s;
          opacity: ${({ isImgLoaded }) => (isImgLoaded ? '1' : '0')};
          animation: ${({ isImgLoaded }) => (isImgLoaded ? "diagonalDownward 0.8s linear forwards" : "")};
          @media (min-width: 1270px) and (max-width: 1500px){
            max-width: 360px;
          }    
        }
    
        .diet-plan-delivered-7 {
          bottom: 0;
          right: 0;
          transition-delay: 0.2s;
          max-width: 445px;
          opacity: ${({ isImgLoaded }) => (isImgLoaded ? '1' : '0')};
          animation: ${({ isImgLoaded }) => (isImgLoaded ? "diagonalUpward 0.8s ease forwards" : "")};
          @media (min-width: 1270px) and (max-width: 1500px){
            max-width: 350px;
            bottom: 16%;
          }    
        }
    }

    .dietPlan-content-wrapper {
      text-align: left;
      display: inline-block;
      position: absolute;
      left: 59px;
      max-width: 820px;
      h1 {
        color: ${theme.colors.blackDefault};
        font-family: Gilroy;
        font-size: 120px;
        font-style: normal;
        font-weight: 700;
        line-height: 125px;
        @media (min-width: 1280px) and (max-width: 1650px){
          font-size: 110px;
          line-height: 104px;
        }
        @media (min-width: 1261px) and (max-width: 1399px) {
          font-size: 90px;
          line-height: 95px
        }
        @media (min-width: 1025px) and (max-width: 1261px) {
          font-size: 80px;
          line-height: 85px
        }
        @media (min-width: 1025px) and (max-width: 1140px) {
          font-size: 70px;
          line-height: 75px;
         }
      }
      .sub-text{
        font-size: 36px;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 22px;
        margin-left: 11px;
        margin-top: 3px;
        @media (min-width: 1261px) and (max-width: 1399px) {
          font-size: 36px;
        }
        @media (min-width: 1025px) and (max-width: 1260px) {
          font-size: 30px;
        }
        @media (min-width: 1025px) and (max-width: 1140px) {
          font-size: 25px;
        }
      }
  
      .dietPlan-btns-wrapper {
        margin-top: 40px;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
    .login-wrapper {
      width: fit-content;
      margin-top: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      .login-label {
        color: ${theme.colors.primaryDarker};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        font-family: Gilroy;
        font-style: normal;    
      }
    }
    .download_btns {
      margin-top: 46px;
      justify-content: flex-start;
      gap: 20px;
      .app_store {
        max-width: 138px;
      }
      .google_play {
        max-width: 138px;
      }
    }
  }
  @media (max-width: 1024px) {
    height: 631px;
    @media (min-width: 768px) and (max-width: 1024px) {
      height: 842px;
    }
    .container{
      width: 100% !important;
    }
    .dietPlan-content-wrapper {
      margin-top: 142px;  
      .sub-text{
        text-align: center;
        font-family: Gilroy;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 12px;
        @media (min-width: 768px) and (max-width: 1024px) {
          font-size: 36px !important;
        }
      }
      h1 {
        color: ${theme.colors.blackDefault};
        text-align: center;
        font-family: Gilroy;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 45px;
        @media (min-width: 768px) and (max-width: 1024px) {
          font-size: 90px !important;
          line-height: 95px;
        }
        }
  
    }
    .dietPlan-imgs-wrapper {
      margin: 0;
      height: 268px;
      @media (min-width: 768px) and (max-width: 1024px) {
        height: 353px;
      }    
      .nutritt__mat{
        position: absolute;
        right: 0;
        height: 125%;
        width: auto;
      }
        .diet-plan-delivered-10 {
          position: absolute;
          right: 0;
          bottom: 0;
          @media (min-width: 1280px) and (max-width: 1650px){
            font-size: 110px;
            line-height: 104px;
          }  
        }
    
        .diet-plan-delivered-8 {
          position: absolute;
          left: 0;
          transition-delay: 0.5s;
        }

        .diet-plan-delivered-11 {
          position: absolute;
          left: 0;
          transition-delay: 0.5s;
          height: 85%;
        }

  
        .diet-plan-delivered-9 {
          position: absolute;
          left: 0;
          bottom: 0;
          transition-delay: 1.5s;
        }
        @media (min-width: 990px) and (max-width: 1025px) {
          .diet-plan-delivered-9 {
            position: absolute;
            left: 0;
            left: 20%;
            height: 40%;
            z-index: 1;
            bottom: 0;
            transition-delay: 1.5s;  
        }
        .diet-plan-delivered-11 {
          position: absolute;
          left: 0;
          bottom: 0;
          transition-delay: 1.5s;
        }
    }
    }

  }
  @media (min-width: 1360px) and (max-width: 1440px) {
    height: 835px;
    .dietPlan-imgs-wrapper {
        .nutritt__mat {
          max-width: 660px;
        }
    
        .diet-plan-delivered-6 {
          max-width: 375px;
        }
    
        .diet-plan-delivered-7 {
          max-width: 360px;
          bottom: 133px;
        }
    }
  }
  @media (min-width: 1025px) and (max-width: 1359px) {
    height: 796px;
    .dietPlan-imgs-wrapper {
      .nutritt__mat {
        max-width: 543px;
      }
      .diet-plan-delivered-6 {
        max-width: 356px;
      }
  
      .diet-plan-delivered-7 {
        max-width: 334px;
        right: -10%;
        bottom: 170px;
          }
  }
}
@media (min-width: 1025px) and (max-width: 1140px) {
  height: 725px;
  .dietPlan-content-wrapper{
    transform: translateY(-46px);
  }
  .dietPlan-imgs-wrapper{
    height: 800px;
  }  
  .dietPlan-imgs-wrapper {
    .nutritt__mat {
      max-width: 543px;
    }
    .diet-plan-delivered-6 {
      max-width: 310px;
    }

    .diet-plan-delivered-7 {
      max-width: 334px;
      right: -10%;
      bottom: 115px;
    }
}
}
  @keyframes diagonalUpward {
    0% {
      transform: translateX(100%) translateY(100%);
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  
  @keyframes diagonalDownward {
    0% {
      transform: translateX(100%) translateY(-100%); 
    }
    100% {
      transform: translateX(0) translateY(0);
    }
  }
  

  @keyframes slideRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
`;

export default function DietPlanDelivered({ setIsQrOpen }) {
  const [isDisplayImage, setIsDisplayImage] = useState(false);
  const history = useHistory();
  const { userSchemeNavigation, isMobile, setUserDeliveryAddress, setHeaderAddress } = useAppContext();
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const handleImageLoad = () => {
      setIsImgLoaded(true);
    };


  useEffect(() => {
    setIsDisplayImage(true);
    setUserDeliveryAddress([]);
    setHeaderAddress([]);
    localStorage.removeItem("visitorDeliveryAddress")
  }, []);

  return (
    <DietPlanDeliveredWrapper isImgLoaded={isImgLoaded}>
      <div id="topOfPage"></div>
      <div className="container">

          <div className={"dietPlan-content-wrapper"}>
          <h1>We’re Serving</h1>
          <h1 className='green'>Peace of Mind.</h1>
          <h1 className='sub-text'>Mindful meals delivered right to your door.</h1>
          {isMobile || ( window.innerWidth < 1025) ? (
            <div className="dietPlan-btns-wrapper">
          <button
            className="get-started"
            onClick={() => {
              if (window.innerWidth > 777 && window.innerWidth < 1025) {
                setIsQrOpen(true);
              } else {
                userSchemeNavigation({
                  appStoreLink: 'https://apps.apple.com/in/app/nutritt/id1605156638',
                  playStoreLink:
                    'https://play.google.com/store/apps/details?id=com.nutritt&hl=en&gl=US',
                  schemeURL: 'nutrittapp://',
                })
              }
            }}
          >
            Get started
          </button>
              {/* <button>Browse diet plans</button> */}
            </div>
          ) : (
            <div className="delivery-address-wrapper">
              <DeliveryAddress />
              <div className="login-wrapper" onClick={() => history.push('/account/home')}>
                <img src="/icons/person_black.svg" alt="Person Icon" />
                <div className="login-label">
                  Log in for saved addresses
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="dietPlan-imgs-wrapper">
        {isMobile ? (
        // Render images for mobile screens
        <>
          {dietPlanDeliveredMobileImages.map((img, index) => (
            <img
              key={index}
              src={`/images/${img}.png`}
              alt="DietPlan image"
              className={classNames(img, { isDisplayImage })}
            />
          ))}
        </>
      ) : window.innerWidth > 768 && window.innerWidth < 1025 ? (
        <>
          <img src='/images/diet-plan-delivered-5.png' className='nutritt__mat' onLoad={handleImageLoad}/>
          {dietPlanTabletImages.map((img, index) => (
            <img
              key={index}
              src={`/images/${img}.png`}
              alt="DietPlan image"
              className={classNames(`absolute ${img}`, { isDisplayImage })}
            />
          ))}
        </>
      ) : (
        // Render images for desktop screens
        <>
          <img src='/images/diet-plan-delivered-5.png' className='nutritt__mat' onLoad={handleImageLoad}/>
          {dietPlanDeliveredImages.map((img, index) => (
            <img
              key={index}
              src={`/images/${img}.png`}
              alt="DietPlan image"
              className={classNames(`absolute ${img}`, { isDisplayImage })}
            />
          ))}
        </>
      )}
        </div>
      </div>
      <img src="/images/border-shadow.png" alt="Shadow image" className="background-shadow" />
    </DietPlanDeliveredWrapper>
  );
}

