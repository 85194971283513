import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const HomeWrapper = styled.div`
  padding-top: 10px;

  .MuiTabs-root {
    padding: 2px 0 24px;
    .MuiTabs-scroller {
      overflow: auto !important;
      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }
    }
    .MuiTabs-flexContainer {
      width: max-content;
      padding: 0 16px;
      gap: 12px;
    }
    .MuiButtonBase-root {
      z-index: 1;
      min-width: auto;
      min-height: auto;
      padding: 5px 12px;
      color: ${() => theme.colors.primaryDarker};
      font-family: ${() => theme?.typography.fontGilroy};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: normal;
      text-transform: none;
      &.Mui-selected {
        font-weight: 600;
      }
    }
    .MuiTouchRipple-root {
      display: none;
    }
    .MuiTabs-indicator {
      width: 64px;
      height: 30px !important;
      border-radius: 15px;
      background-color: #f5f7f8 !important;
    }
  }

  .tabs-count-wrapper {
    margin-bottom: 14px;
    display: flex !important;
    justify-content: center;
    div {
      width: 100%;
    }
  }

  .recharts-wrapper {
    margin: 21px 0 32px;
    .recharts-surface {
      transform: translateX(-10px);
    }
    .recharts-layer {
      text {
        fill: ${() => theme.colors.grayDarker};
        font-size: 11px !important;
        font-weight: 500 !important;
        line-height: 12px;
        letter-spacing: 0.22px;
      }
    }
    .recharts-active-dot .recharts-dot {
      stroke: #00092D;
      fill: #FFF;
    }
    .custom-tooltip {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  .skeleton-chart-wrapper {
    .MuiSkeleton-root {
      width: 343px;
      height: 300px;
      margin: 0 auto;
      transform: scale(1);
    }
  }

  .slick-slider {
    .slick-slide {
      transition: all 0.5s linear;
      .tabs-count-wrapper div {
        .item-name,
        .item-count {
          color: ${() => theme.colors.mediumGray};
        }
      }
      &.slick-active {
        display: flex;
        justify-content: center;
        .tabs-count-wrapper div {
          .item-name {
            color: ${() => theme.colors.grayDarker};
            text-align: center;
          }
          .item-count {
            color: ${() => theme.colors.blackDefault};
            text-align: center;
          }
        }
      }
    }
    [data-index='0'] {
      &:not(.slick-active) {
        display: flex;
        justify-content: flex-end;
        .item-name,
        .item-count {
          text-align: right;
        }
        & + [data-index='1']:not(.slick-active) {
          display: flex;
          justify-content: flex-end;
          .item-name,
          .item-count {
            text-align: right;
          }
        }
      }
      &.slick-active + [data-index='1'] .tabs-count-wrapper > div {
        align-items: flex-start;
      }
    }
  }
`;
