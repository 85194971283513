import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowLeft } from 'assets/shop/wrong email.svg';
import { Button } from 'components/elements/button/Button';
import { motion } from 'framer-motion';


const MessageStyled = styled.div`
.modal {
  padding: ${({ success }) => success ? '32px 17px' : '32px 33px'};
  height: ${({ success }) => success ? '356px' : '425.91px'};
  width: ${({ success }) => success ? '334px' : 'calc(100vw - 32px)'};
  text-align: center;
  gap:24px;
  align-items: center;
  background:#fff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
    .details{
        .title{
            color: #00092D;
            text-align: center;
            font-family: Gilroy;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .description{
            width: 100% !important;
            color: #00092D;
            text-align: center;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }
    }
    .button{
        display: flex;
        gap: 8px;
        flex-direction: column;
        width: ${({ success }) => success ? '182px' : '220px'};
        button{
            font-family: Gilroy !important;
        }
    }
}
@media(min-width: 992px) {
    .modal {
        width: 500px;
    }
 }

`
export default function MessageModal({ success, img = '/images/envelope-times.png', title = "Oops!", firstBtnTitle="Retry", secondBtnTitle="Contact Support", description = `Email not found. <br />Try again or contact support.`, firstBtnClick, secondBtnClick}) {
    const baseURL = window.location.protocol + "//" + window.location.host;

    useEffect(() => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ event: "swipe-block" }))
        }
    }, []);
    
    return (
        <>
        <motion.div 
            initial={baseURL === process.env.REACT_APP_MERCHANT_URL ? { opacity: 0 } : { opacity: 0, scale: 0.5 }}
            animate={baseURL === process.env.REACT_APP_MERCHANT_URL ? { opacity: 1 } : { opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
            transition={baseURL === process.env.REACT_APP_MERCHANT_URL ? { duration: 0.5 } : {
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                    restDelta: 0.001
                }
            }}
            className='motion_modal'
        >        
        <MessageStyled success={success}>
            <div className='modal'>
                {img && (
                <span className='icon'>
                    {typeof img === 'string' ? (
                    <img src={img} alt="Icon" />
                    ) : (
                    img
                    )}
                </span>
                )}
                <div className='details'>
                    <h2 className='title'>{title}</h2>
                    <p className='description' dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
                <div className='button'>
                    <Button type={'mobile_save'} title={firstBtnTitle} onClick={firstBtnClick}/>
                    {!success && 
                    <Button type={'mobile_secondary'} title={secondBtnTitle} onClick={secondBtnClick}/>
                    }
                </div>
            </div>
        </MessageStyled>
        </motion.div>
        </>
    )
}
