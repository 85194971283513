import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteButton from '../DeleteButton';
import { useRequest } from 'hooks/useRequest';
import DrawerForm from './DrawerForm';

const StyledHeader = styled.div`
  background: #47c547;
  color: white;
  text-align: center;
  padding: 11px;
  font-size: 26px;
`;
const StyledButton = styled.div`
  color: ${({ prevMeal, apiPath }) => (apiPath ? '#47c547' : !prevMeal ? 'white' : '#47c547')};
  cursor: pointer;
  background: ${({ prevMeal, apiPath }) =>
    apiPath ? 'transparent' : !prevMeal ? '#47c547' : 'transparent'};
  div {
    display: flex;
    align-items: center;
    margin-left: 5px;
    svg {
      font-size: 18px;
    }
  }
`;

export default function MealDrawer({
  provider,
  meal: prevMeal,
  setMeals,
  meals,
  postMethod,
  isCombo = false,
  parentComponentId,
  parentComponentName,
  allergies,
  cb,
  apiPath,
  drawerTitle,
  btnTitle,
  selectedComponentId,
  openForm,
  setIsDelete,
  getData
}) {

  const defaultMeal = {
    name: '',
    description: '',
    price: 0,
    carbs: 0,
    calories: 0,
    protein: 0,
    fat: 0,
    weight: 0,
    img: '',
    restrictions: [],
    tags: [],
    isActive: true,
    componentId: parentComponentId,
    componentName: parentComponentName,
  };

  const [meal, setMeal] = useState(defaultMeal);
  const [isOpen, setIsOpen] = useState(openForm);

  const [saveMeal, { isLoading, error, setError }] = useRequest();
  const businessProfileId = provider?.BusinessProfile[0]?.id || null;
  useEffect(() => {
    if (!isOpen) {
      if (error) setError(null);
      // if (newMeals && setMeals) setMeals(newMeals);
    }
  }, [isOpen]);
  useEffect(() => {
    if (!prevMeal) return;
    setMeal({ ...prevMeal, price: prevMeal.price / 100 });
  }, [prevMeal]);
  const { componentId = selectedComponentId, componentName } = meal;
  const handleSubmit = async () => {
    if (!meal.name || !meal.price) return setError('Please fill in all required fields');
    const path = apiPath ? apiPath : prevMeal ? `meal/update?providerId=${provider?.id}&businessProfileId=${businessProfileId}` : `meal/add?providerId=${provider?.id}&businessProfileId=${businessProfileId}`;
    const method = apiPath ? apiPath : prevMeal ? `patch` : 'post';
    delete meal.orderIndex;
    delete meal.createdAt;
    delete meal.updatedAt;
    delete meal.isDeleted;
    delete meal.keywords;
    delete meal.MealIngredients;
    const newMeals = await saveMeal({
      path,
      method,
      body: {
        ...meal,
        providerId: provider.id,
        ...(isCombo ? { componentId } : {}),
      },
    });
    if(prevMeal) {
      const updatedmeals = await getData();
      // await setMeals(updatedmeals);
    } else {
      const updatedmeals = await getData();
      // await setMeals(updatedmeals);
      setMeal(defaultMeal);
    }
    await postMethod?.();
    setIsOpen(false);
    if (typeof cb === 'function') {
      cb(newMeals);
    }
  };

  const title = !isCombo ? 'meal' : componentName;
  return (
    <div>
      {meals?.length>0 &&
      <StyledButton
        data-testId="open_drawer_btn"
        prevMeal={prevMeal}
        apiPath={apiPath}
        onClick={() => setIsOpen(true)}
      >
        {apiPath ? (
          <div>
            <AddIcon /> {btnTitle}
          </div>
        ) : prevMeal ? (
          <div>
            <EditIcon /> Edit
          </div>
        ) : (
          <div>
            <AddIcon /> Add
          </div>
        )}
      </StyledButton>
      }
      <Drawer
        className='addEditDrawer'
        // disableBackdropClick={true}
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {isOpen && (
          <>
            <StyledHeader>
              {drawerTitle ? drawerTitle : prevMeal ? 'Edit ' : 'Add '}
              {title}
            </StyledHeader>
            <DrawerForm
              meal={meal}
              handleSubmit={handleSubmit}
              setMeal={setMeal}
              allTags={allergies}
              error={error}
              isLoading={isLoading}
            />
            {prevMeal && (
              <DeleteButton
                provider={provider}
                setMeals={setMeals}
                getData={getData}
                setIsOpen={setIsOpen}
                mealId={meal.id}
                componentId={componentId}
                setIsDelete={setIsDelete}
              />
            )}
          </>
        )}
      </Drawer>
    </div>
  );
}
