import classNames from 'classnames';
import { useRequest } from 'hooks/useRequest';
import Styled from 'styled-components';
import CheckRight from '../CheckRight';
import { useState,useMemo,useEffect,useRef} from 'react';
import { isArray } from 'lodash';
import MealMacrosInfo from './MealMacrosInfo';
import autoAnimate from '@formkit/auto-animate';
import { debounce } from "lodash";
import { calculateNutritionPerGram } from 'utils';
import UserMealCard from 'components/elements/MealCard/UserMealCard';

const RecentLoggedMealWrapper = Styled.div`
  //max-height: 110px;
  margin: 0 auto;
  opacity: 1;
  &.moveMeal {
    //max-height: 0;
    opacity: 0;
    transition: max-height 1s ease-in-out 1s, opacity .7s ease-in-out 1s;
  }
  .meal-details-wrapper {
    margin: 0 auto;
    display: flex;
    justify-content:flex-start;
    .meal-img-wrapper {
      margin-right: 20px;
      display: flex;
      img {
        width: 60px;
        height: 70px;
        object-fit: contain;
      }
    }
    .meal-content-wrapper {
      overflow:hidden;
      z-index:${(props) => (props?.index === props?.openMealIndex ? '1' : 'unset')};
      width:100%;
      padding-left: 16px;
      padding-right: 16px;
      .meal-title {
        margin-bottom: 3px;
        color: #0B1D17;
        font-size: 14px;
        line-height: 16px;
        padding-right: 25px;
        .meal-name{
           width:85%;
        }
      }
      .brand-name {
        margin-bottom: 9px;
        color: #949D9B;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
      }
      .meal-info-add {
        display: flex;
        align-items: center;
        justify-content: space-between;

      }
      .meal-info {
        height: 100%;
        margin-right: 10px;
        // border: 1px solid #E0E3E0;
        // border-radius: 6px;
        display: flex;
        // column-gap: 14px;
      }
      .item1{
        margin-right:14px;
      }
      .item {
        position: relative;
        width: auto;
        height: auto; 
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
         // width: 136px;
          // border-left: 1px solid #E0E3E0;
          span{
            color: #51635D;
            font-weight: 400;
          }
          .item-count{
            margin-left:4px;
          }
        }
        span {
          color: #52C41A;
          font-size: 11px;
          line-height: 12px;
          letter-spacing: 0.02em;
          font-weight: 700;
        }
        .item-count {
          // margin-left: 4px;
          font-weight: 700 !important;
        }
      }
      .edit-icon {
        position: absolute;
        right: 3px;
        top: 3px;
        width: 10px;
        height: 10px;
      }
      .add-icon-wrapper {
        width: 28px;
        height: 28px;
        padding: 0;
        border: 1px solid #CBEDBA !important;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        flex-shrink: 0;
        margin-top: -14px;
        margin-right:4px;
      }
      .add-icon {
        width: 14px;
        height: 14px;
        display: flex;
      }
      button: disabled{
        cursor: not-allowed;
      }
    }
  }
`;

export default function RecentLoggedMeals({
  meal,
  meal_name,
  calories,
  protein,
  fat,
  carbs,
  img,
  servings,
  setMealInfo,
  setFoodId,
  setMealMacroInfo,
  setCustomMealInfo,
  customfoodId,
  mealId,
  fatSecretMealId,
  setSearchedFoodDetails,
  setLogMealFromList,
  setIsRecentMeal,
  setCustomForm,
  setValue,
  setIsFoodDetailsLoading,
  setSearchQuantity,
  handleMealClick,
  openMealIndex,
  index,
  currentDayinfo,
  showServingInfo,
  setShowServingInfo,
  isCustomMealSaving,
  setSelectedSliderIndex
}) {
    const serving = servings?.serving;
    const caloriesNumber = serving ? Array.isArray(servings?.serving)? parseInt(servings?.serving[0]?.calories) : servings?.serving?.calories : calories;
    const [showMacros, setShowMacros] = useState(false);
    const numberQtyConstants = Array.from({length: 11}, (_, i) => i);
    const gramsUnitConstant = Array.from({length: 100}, (_, i) => (i + 1) * 10);
    var fractionalConstants = ["--",1/4,1/2,3/4]; 
    const [macrosInfo, setMacrosInfo] = useState({});
    const parent = useRef(null);
     
    const accessMealDetails =  () => {
      setFoodId(customfoodId);
      setMealInfo({ foodName: meal_name, caloriesNumber });
      setIsFoodDetailsLoading(true);
      setSearchedFoodDetails(meal?.fatSecretSearchResult);
      setIsRecentMeal(true);
      if (showMacros) {
        setMealMacroInfo(macrosInfo);
      } else {
        const selectedServing = Array.isArray(serving) ? serving[0] : serving;
        setMealMacroInfo({ calories: selectedServing?.calories, fat: selectedServing?.fat, carbohydrate: selectedServing?.carbohydrate, protein: selectedServing?.protein, serving_id: selectedServing?.serving_id });
      }
    };

    const accessCustomMealsDetails = async () => {
      setIsFoodDetailsLoading(true);    
      setCustomMealInfo({food_name:meal_name,calories:calories,protein:protein,fat:fat,carbs:carbs,img:img,id:customfoodId});
    }

    const handleClick = (e) => {
      e.stopPropagation();
      if (isCustomMealSaving) return;
      if(fatSecretMealId){
        setLogMealFromList(true);
         accessMealDetails();
         
      }
      else{
       setLogMealFromList(true);
        accessCustomMealsDetails();
        
      }
    }

    const handleMealTitleClick = debounce(async (e) => {
      e.stopPropagation();
      const isFatSecretMeal = servings?.serving;
      if (!isFatSecretMeal) {
        const obj = { calories: meal?.calories, carbs: meal?.carbs, fat: meal?.fat, name: meal?.name, protein: meal?.protein, img: meal?.img };
        setCustomForm(obj);
        setValue(1);
        return;
      }
      if (openMealIndex === index) {
        setShowMacros(!showMacros);
        setShowServingInfo(false);
      } else {
        setShowMacros(true);
        setShowServingInfo(false);
        handleMealClick(index);
      }
    }, 300);


    const filterServingData = (servingData) => {
      const uniqueServingData = [];
      const seenDescriptions = new Set();

      for (const serving of servingData) {
        if (!seenDescriptions.has(serving.serving_description)) {
          uniqueServingData.push(serving);
          seenDescriptions.add(serving.serving_description);
        } 
      }

      return uniqueServingData;
    };
  const servingProps = useMemo(() => {
    if (serving && Array.isArray(serving) && serving?.length > 1) {
      return filterServingData(serving);
    } else {
      const servingObj = Array.isArray(serving) ? serving[0] : serving;
      if (!servingObj?.metric_serving_unit) {
        return serving;
      }
      const {
        caloriesPerGram,
        fatPerGram,
        proteinPerGram,
        carbsPerGram,
      } = calculateNutritionPerGram(servingObj);

      return [
        ...serving,
        {
          ...servingObj,
          calories: caloriesPerGram,
          protein: proteinPerGram,
          fat: fatPerGram,
          carbohydrate: carbsPerGram,
          measurement_description: "g",
          number_of_units: "100.00",
          metric_serving_unit: "g",
          serving_description: "100 g",
          isCustom: true
        },
      ];
    }
  }, [serving]);

    useEffect(() => {
      parent.current && autoAnimate(parent.current);  
    }, [parent])

  return (
    <RecentLoggedMealWrapper className={classNames({ moveMeal: mealId == customfoodId })} index={index} openMealIndex={openMealIndex}>
      <div className="meal-details-wrapper">
        <div className="meal-content-wrapper" ref={parent}>
          <div className='meal-content-container' onClick={handleMealTitleClick}>
            <UserMealCard 
              mealName={meal_name} 
              cals={caloriesNumber} 
              fat={meal?.fat} 
              protein={meal?.protein} 
              carbs={meal?.carbs} 
              onAddClick={handleClick} 
              imageDimension={65} 
              iconPositionEnd={16}  
            />
          </div>
          {showMacros && index===openMealIndex && serving && (
            <MealMacrosInfo 
            showMacros={showMacros} 
            servings={servingProps}
            showServingInfo={showServingInfo}
            setShowServingInfo={setShowServingInfo}
            numberQtyConstants={numberQtyConstants}
            fractionalConstants={fractionalConstants}
            setMacrosInfo={setMacrosInfo}
            setSearchQuantity={setSearchQuantity}
            gramsUnitConstant={gramsUnitConstant}
            currentDayinfo={currentDayinfo}
            setSelectedSliderIndex={setSelectedSliderIndex}
            mealInfo={{
              carbs: meal?.carbs,
              cals: caloriesNumber,
              fat: meal?.fat,
              protein: meal?.protein
            }}
            isRecentLog={true}
            />
          )}
          <div className='border' style={{ padding: '6px 0px' }}>
            <div style={{ height: '1px', width: '100%', backgroundColor: '#E0E3E0' }}></div>
          </div>
        </div>
      </div>
    </RecentLoggedMealWrapper>
  );
}