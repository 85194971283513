import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const PrintHeaderWrapper = styled.div`
  display: none;
`;

export const PrintShapeWrapper = styled.div`
  width: 630px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #e0e4e8;
  border-radius: 6px;

  .order-number-wrapper {
    padding: 17px 24px 20px;
    border-bottom: 1px solid ${theme.colors.grayLight};
  }

  section {
    border: 0;
  }

  .section-title {
    color: ${theme.colors.blackDefault};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .delivery-info-wrapper {
    & > section {
      padding: 10px 24px 0;
    }
  }

  .orders-items-wrapper {
    margin-bottom: 34px;
    & > section {
      padding: 22px 24px 0 16px;
    }
    .section-title {
      margin: 0 0 14px 9px;
    }
    .order-items-title {
      margin-right: 0;
      grid-template-columns: 67% 18% 15%;
      .title-item:first-child {
        padding-left: 0;
      }
      .title-item:last-child {
        text-align: right;
      }
    }
    .order-items-details {
      padding: 15px 0 0 0;
      gap: 20px;
      .order-line-container {
        margin-right: 0;
        grid-template-columns: 67% 18% 15%;
      }
      .order-line-container .item-name {
        padding-left: 8px;
        .order-img {
          display: none;
        }
        .order-name {
          font-weight: 400;
        }
      }
      .item-qty,
      .item-price {
        padding-left: 17px;
      }
      .item-price {
        text-align: right;
      }
    }
  }

  .payment-breakdown-wrapper {
    padding: 0 24px 0 16px;
    .section-title,
    .payment-items-container {
      padding-left: 9px;
      .payment-item {
        font-weight: 400;
      }
      .payment-item .title {
        line-height: normal;
      }
    }
    .divider {
      margin: 17px 0 16px;
    }
    .total-payment {
      padding-left: 9px;
      .title {
        line-height: normal;
      }
      .info {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
`;
