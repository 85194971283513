import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { ProviderCardItem } from 'components/providerCardItem/ProviderCardItem';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import EmptyCart from './EmptyCart';
import OtherProviderCart from './OtherProviderCart';
import { useVisitorContext } from 'context/visitorContext';
import useCart from 'hooks/useCart';
import { useHistory, useParams } from 'react-router-dom';

const DesktopCartStyle = styled.div`
  max-height: calc(100vh - 78px);
  height: ${({hasOtherProvidersMeals}) => hasOtherProvidersMeals ? 'auto' : '100%'};
  overflow: auto;
  position: relative;
  .cart-title-wrapper {
    height:77px;
    padding: 0 17px;
    display: flex;
    align-items: center;
    gap: 12px;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .title {
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .current__provider__cart {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 16px 16px 16px 17px;
    background-color: ${({ theme }) => theme?.colors?.white};
    height: 100%;
    overflow: auto;
    .subtitle {
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: ${({ theme }) => theme?.colors?.grayDarker};
    }
    .provider__info {
      .row_style {
        gap: 12px;
      }
      .provider-img-wrapper {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: 1.5px solid #fff;
        }
      }
      .divider {
        margin: 0;
        padding: 12px 0;
      }
      .column_style {
        justify-content: center;
        .name {
          font-size: ${({ theme }) => theme?.typography?.fontLarge};
          line-height: 24px;
        }
      }
    }
    .empty_div {
      max-height: 445px;
      max-width: 200px;
      padding-top: 94px;
      margin: auto;
    }
  }
  .other__providers__cart {
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .ttitle {
      font-size: ${({ theme }) => theme?.typography?.fontXl};
      color: ${({ theme }) => theme?.colors?.deepForestGreen};
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .checkout__multiple__orders{
    width: 336px;
    height: 102px;
    padding: 22px 16px 11px;
    background-color: ${({ theme }) => theme?.colors?.white};
    position: fixed;
    top: calc(100vh - 102px);
    border-top: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    button{
      height: 42px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 14px 16px 12px 16px;
      border-radius: 30px;
      border: 1px solid ${({ theme }) => theme?.colors?.grayDarker} !important;
      cursor: pointer;
      font-size: ${({ theme }) => theme?.typography?.fontBase};
      font-weight: 700;
      background-color: ${({ theme }) => theme?.colors?.white};
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      transition: all 0.3s;
      font-family: 'Gilroy';
      &:hover{
        color: ${({ theme }) => theme?.colors?.grayDarker};
        border-color: ${({ theme }) => theme?.colors?.primaryDefault};
      }
      &:focus{
        border-color: ${({ theme }) => theme?.colors?.grayLight};
      }
    }
    .min_not_met{
      font-size: ${({ theme }) => theme?.typography?.fontSmall};
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.deepForestGreen};
      text-align: center;
      margin-top: 12px;
      span{
        color: ${({ theme }) => theme?.colors?.alizarinCrimson};
        font-weight: 400;
        text-decoration: underline;
      }
    }
  }
`;

const DesktopCart = ({ setIsCart }) => {
  const {
    user,
    profile,
    selectedProviderId,
    isShoppingUser,
    setRouteBeforeSignup,
    routeBeforeSignup
  } = useAppContext();
  const {favoriteKitchenData = {}} = profile || {};
  const { cart, settings, updateSettings, kitchensList } = (user && isShoppingUser) ? useShopContext() : useVisitorContext();
  const { addMultipleToCart } = useCart();
  const { provider, providerSettings } = settings;
  const mealsCart = cart;
  const [otherProvidersArray, setOtherProvidersArray] = useState([]);
  const [hasOtherProvidersMeals, setHasOtherProvidersMeals] = useState(mealsCart?.find(meal => meal?.providerId !== selectedProviderId) ? true : false);
  const [mealsMap, setMealsMap] = useState({});
  const [minimumOrderMet, setMinimumOrderMet] = useState([]);
  const [minimumOrderNotMet, setMinimumOrderNotMet] = useState();
  const [isMinimumOrderMet, setIsMinimunOrderMet] = useState(true); //kitchen id's meet minimum orders;
  const [kitchenWithMeals, setKitchenWithMeals] = useState([]);
  const history = useHistory();
  const [multipleCheckoutClicked, setMultipleCheckoutClicked] = useState(false);
  const {providerId: providerUsername} = useParams();

  // Check if there are unfullfilled orders from previous cart.
  useEffect(() => {
    const unfullfilledOrders = JSON.parse(localStorage.getItem('unfullfilledOrders'));
    localStorage.removeItem('unfullfilledOrders');
    if(unfullfilledOrders?.length && !routeBeforeSignup){
      addMultipleToCart(unfullfilledOrders)
        .then((result) => {
          if(result.success){
            localStorage.removeItem('unfullfilledOrders');
          }
        })
        .catch((err) => console.log(err))
    }
  }, [])

  const checkMinOrderMet = (kitchensList, mealsMap, providerSettings) => {
    const filteredKitchens = kitchensList?.filter(obj => {
      const existsInProviderSettings = providerSettings.some(obj2 => Number(obj2.id) === Number(obj.id));
      return existsInProviderSettings;
    });
    const providerIds = [];
    const notMetProviderIds = [];
    filteredKitchens?.forEach((kitchen) => {
      Object.keys(mealsMap)?.forEach((key) => {
        if (Number(kitchen?.id) === Number(key)) {
          const meals = mealsMap[kitchen.id];
          const totalOrderAmount = meals.reduce((acc, curr) => {
            const currAdd = curr.price * curr.qty;
            return acc + currAdd;
          }, 0);
    
          const isMinOrderMet = totalOrderAmount >= kitchen?.minOrderAmount;
    
          if (isMinOrderMet) {
            providerIds?.push(kitchen.id);
          }else{
            notMetProviderIds?.push(kitchen.id);
          }
        }
      });
    });

    return [providerIds, notMetProviderIds]
  }

  useEffect(() => {
    const [isMet, notMet] = checkMinOrderMet(kitchensList, mealsMap, providerSettings);
    setMinimumOrderMet(isMet);
    setIsMinimunOrderMet(notMet?.length <= 0);
    setMinimumOrderNotMet(notMet)
  }, [kitchensList, mealsMap, providerSettings])

  useEffect(() => {
    if (!kitchensList || !kitchensList.length) {
      return;
    }

    const mealsMap = mealsCart.reduce((map, meal) => {
      const providerId = meal.providerId;
      if (!map[providerId]) {
        map[providerId] = [];
      }
      map[providerId].push(meal);
      return map;
    }, {});
    setMealsMap(mealsMap);
  
    const filteredArray = providerSettings.map(provider => ({
        description: provider?.description,
        image: provider?.image,
        name: provider?.name,
        minOrderAmount: provider?.minOrderAmount,
        id: provider?.providerId,
        username: provider?.username,
        settings: {...provider}
      })
    );
  
    const hasOtherProvidersMeals = filteredArray?.filter(provider => Number(provider.id) !== Number(selectedProviderId)).length > 0;
    setHasOtherProvidersMeals(hasOtherProvidersMeals);
  
    const updatedProviders = filteredArray.map(provider => ({
      ...provider,
      meals: mealsMap[provider.id] || [],
    }));

    setOtherProvidersArray(updatedProviders?.filter(provider => Number(provider.id) !== Number(selectedProviderId)));
    setKitchenWithMeals(updatedProviders);

  }, [mealsCart, selectedProviderId, kitchensList, providerSettings]);

  const handleCartMeal = () => {
    let mealForCheckout = [];
    cart.forEach((meal) => {
      if (meal.providerId === selectedProviderId) {
        const mealWithoutCustomQty = { ...meal, customQty: undefined };
        mealForCheckout.push(mealWithoutCustomQty);
      }
    });

    return mealForCheckout;
  };

  const uniqMealData = handleCartMeal();

  const handleMultipleCheckout = () => {
    if(!isMinimumOrderMet){
      setMultipleCheckoutClicked(true);
      return;
    }
    (typeof setIsCart === 'function') && setIsCart(false);
    if(location.pathname.includes('store') && providerUsername){
      localStorage.setItem('viewedStoreUrl', JSON.stringify(`/store/${providerUsername}`));
    }
    localStorage.removeItem('unfullfilledOrders');
    user ? history.push('/shop/checkout/order') : (history.push('/account/home'), setRouteBeforeSignup('/shop/checkout/order'))
  }

  return (
    <>
      <DesktopCartStyle hasOtherProvidersMeals={hasOtherProvidersMeals} theme={theme}>
        {setIsCart ? (
          <div className='cart-title-wrapper'>
          <img src="/icons/close-black.svg" alt="Close Icon" onClick={() => setIsCart(false)} />
          <div className='title'>Your cart</div>
          </div>
        ) : (
        <div className="current__provider__cart">
          <p className="subtitle">Your cart from</p>
          <div className="provider__info">
            <ProviderCardItem
              logo={provider?.image || '/images/green-plate.png'}
              name={provider?.name}
              uniqMealData={uniqMealData}
              minOrderAmount={provider?.minOrderAmount}
              orderData={
                provider?.minOrderAmount
                  ? `Min order: $${provider?.minOrderAmount}`
                  : 'No minimum order'
              }
              otherKitchens={otherProvidersArray}
              buttonGotPressed={multipleCheckoutClicked}
              userName={provider?.username}
            />
          </div>
          {!uniqMealData?.length && <EmptyCart />}
        </div>
        )}
        {otherProvidersArray?.length > 0 ? (
        <>
        <div className="other__providers__cart">
          {!setIsCart && <h2 className="ttitle">Other carts</h2>}
          {otherProvidersArray?.map((item) => {
            const {name, minOrderAmount, image, meals} = item;
            return <OtherProviderCart 
              providerImage={image} 
              providerName={name} 
              minValueNumber={minOrderAmount}
              mealList={meals}
              provider={item?.settings}
              buttonGotPressed={multipleCheckoutClicked}
            />
          })}
        </div>
        <div style={{ height: '102px' }}></div>
        <div className='checkout__multiple__orders'>
          <button type='button' onClick={handleMultipleCheckout}>
            {minimumOrderMet?.length < 2 ? "Checkout" : ` Checkout ${minimumOrderMet?.length} orders`}
            {!isMinimumOrderMet &&
            <sup>
              <span style={{
                backgroundColor: '#EB3223', 
                width: '8px', 
                height: '8px', 
                display: 'inline-block', 
                borderRadius: '50%',
                marginLeft: '4px',
                transform: 'translateY(-5px)'
              }} />
            </sup> }
          </button>
          {minimumOrderNotMet?.length > 0 && <p className='min_not_met'>Minimum order not met for <span>{minimumOrderNotMet?.length} orders</span></p>}
        </div>
        </>
        ) : (
          setIsCart && <EmptyCart />
        )}
      </DesktopCartStyle>
    </>
  );
};

export default DesktopCart;



