import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const NutritionCardStyle = styled.div`
  background: ${({ theme }) => theme?.colors?.white};
  border-radius: 8px;
  width: 414px;
  border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
  padding: 17px 13px 18px 16px;
  .nutrition-heading {
    color: ${({ theme }) => theme?.colors?.primaryDark};
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    font-size: ${({ theme }) => theme?.typography?.fontBase};
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
  }
  .table-list {
    display: flex;
    justify-content: space-between;
    border-bottom: ;
  }
  .daily_value {
    display: flex;
    justify-content: flex-end;
  }
  text {
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  label {
    color: ${({ theme }) => theme?.colors?.grayDarker};
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .daily_value_item {
    display: flex;
    gap: 33px;
    justify-content: space-between;
  }
  .sub_list {
    margin-left: 34px;
  }
  .border-bottom {
    gap: 8px;
    display: flex;
    padding-bottom: 8px;
    padding-top: 8px;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    :first-child{
        margin-bottom:12px;
        border-bottom: 1px solid ${({ theme }) => theme?.colors?.grayDarker};
    }
    :last-child{
        padding-bottom:8px;
    }
  }
`;

const Nutrition = ({ calculatedMealData, ingredientData, mealData }) => {
  return (
    <NutritionCardStyle theme={theme}>
        <div className='border-bottom'>
      <h2 className="nutrition-heading">Nutrition Facts</h2>
      <div className="table-list">
        <label>1 Serving Per Container</label>
        <label>Serving Size: 1</label>
      </div>
      <div className="table-list">
        <text>Calories</text>
        <text>{ingredientData?.length ? !isNaN(calculatedMealData?.totalCalories) ? calculatedMealData?.totalCalories : 0 : mealData?.calories ?? "-"}</text>
      </div>
      </div>
      <label className="daily_value">% Daily Value*</label>
      <div className="border-bottom">
        <div className="table-list">
          <text>Total Fat</text>
          <div className="daily_value_item">
            <label>{calculatedMealData?.fat?.grams? calculatedMealData?.fat?.grams:'-'}g</label>
            <text>{calculatedMealData?.fat?.percentage? calculatedMealData.fat.percentage:'-'}%</text>
          </div>
        </div>
        <div className="table-list">
          <label className="sub_list">Saturated Fat</label>
          <div className="daily_value_item">
            <label>{calculatedMealData?.fat?.saturatedFatGrams? calculatedMealData?.fat?.saturatedFatGrams:'-'}g</label>
            <text>{calculatedMealData?.fat?.saturatedFatPercent? calculatedMealData.fat.saturatedFatPercent:'-'}%</text>
          </div>
        </div>
        <div className="table-list">
          <label className="sub_list">Trans Fat</label>
          <div className="daily_value_item" style={{width:"112px"}}>
            <label>{calculatedMealData?.fat?.transFatGrams? calculatedMealData.fat.transFatGrams:'-'}g</label>
          </div>
        </div>
      </div>
      <div className="border-bottom">
        <div className="table-list">
          <text>Cholesterol</text>
          <div className="daily_value_item">
            <label>{calculatedMealData?.cholesterol?.mg? calculatedMealData.cholesterol.mg:'-'}mg</label>
            <text>{calculatedMealData?.cholesterol?.percentage? calculatedMealData.cholesterol.percentage:'-'}%</text>
          </div>
        </div>
      </div>
      <div className="border-bottom">
        <div className="table-list">
          <text>Sodium</text>
          <div className="daily_value_item">
            <label>{calculatedMealData?.sodium?.mg? calculatedMealData.sodium.mg:'-'}mg</label>
            <text>{calculatedMealData?.sodium?.percentage? calculatedMealData.sodium.percentage:'-'}%</text>
          </div>
        </div>
      </div>
      <div className="border-bottom">
        <div className="table-list">
          <text>Total Carbohydrate</text>
          <div className="daily_value_item">
            <label>{calculatedMealData?.carbohydrates?.grams? calculatedMealData.carbohydrates.grams:'-'}g</label>
            <text>{calculatedMealData?.carbohydrates?.percentage? calculatedMealData.carbohydrates.percentage:'-'}%</text>
          </div>
        </div>
        <div className="table-list">
          <label className="sub_list">Dietary Fiber</label>
          <div className="daily_value_item">
            <label>{calculatedMealData?.carbohydrates?.fiberGrams? calculatedMealData.carbohydrates.fiberGrams:'-'}g</label>
            <text>{calculatedMealData?.carbohydrates?.fiberPercent? calculatedMealData.carbohydrates.fiberPercent:'-'}%</text>
          </div>
        </div>
        <div className="table-list">
          <label className="sub_list">Total Sugars</label>
          <div className="daily_value_item" style={{width:"112px"}}>
            <label>{calculatedMealData?.carbohydrates?.sugarGrams? calculatedMealData.carbohydrates.sugarGrams:'-'}g</label>
          </div>
        </div>
        <div className="table-list">
          <label className="sub_list">Includes added sugars</label>
          <div className="daily_value_item">
          <label>{calculatedMealData?.carbohydrates?.addedSugarGrams? calculatedMealData.carbohydrates.addedSugarGrams:'-'}g</label>
            <text>{calculatedMealData?.carbohydrates?.addedSugarPercent? calculatedMealData.carbohydrates.addedSugarPercent:'-'}%</text>
             </div>
        </div>
      </div>
      <div className="border-bottom">
        <div className="table-list">
          <text>Protein</text>
          <div className="daily_value_item">
            <label>{calculatedMealData?.protein?.grams? calculatedMealData.protein.grams:'-'}g</label>
            <text>{calculatedMealData?.protein?.percentage? calculatedMealData.protein.percentage:'-'}%</text>
          </div>
        </div>
      </div>
      <div className="border-bottom">
      <div className="table-list">
        <label className="sub_list">Vitamin D</label>
        <div className="daily_value_item">
          <label>{calculatedMealData?.protein?.vitaminDMicrograms? calculatedMealData.protein.vitaminDMicrograms:'-'}µg</label>
          {/* {it should be in µg micrograms not in mg  } */}
          <text>{calculatedMealData?.protein?.vitaminDPercentage? calculatedMealData.protein.vitaminDPercentage:'-'}%</text>
        </div>
      </div>
      <div className="table-list">
        <label className="sub_list">Calcium</label>
        <div className="daily_value_item">
          <label>{calculatedMealData?.protein?.calciumMg? calculatedMealData.protein.calciumMg:'-'}mg</label>
          <text>{calculatedMealData?.protein?.calciumPercent? calculatedMealData.protein.calciumPercent:'-'}%</text>{' '}
        </div>
      </div>
      <div className="table-list">
        <label className="sub_list">Iron</label>
        <div className="daily_value_item">
          <label>{calculatedMealData?.protein?.ironMg? calculatedMealData.protein.ironMg:'-'}mg</label>
          <text>{calculatedMealData?.protein?.ironPercent? calculatedMealData.protein.ironPercent:'-'}%</text>{' '}
        </div>
      </div>
      <div className="table-list">
        <label className="sub_list">Potassium</label>
        <div className="daily_value_item">
          <label>{calculatedMealData?.protein?.potassiumMg? calculatedMealData.protein.potassiumMg:'-'}mg</label>
          <text>{calculatedMealData?.protein?.potassiumPercent? calculatedMealData.protein.potassiumPercent:'-'}%</text>
        </div>
      </div>
      </div>
      <label>*Percent Daily values are based on a 2,000 calorie diet</label>
    </NutritionCardStyle>
  );
};

export default Nutrition;
