import { useState, useEffect, useRef } from 'react';
import Typist from 'react-typist';
import classNames from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { usePreview } from 'react-dnd-preview';
import { ImSpoonKnife } from 'react-icons/im';
import MealList from './MealList';
import { useAppContext } from 'context/appContext';
import ImageViewer from '../../../components/ImageViewer';
import FilterMealsData from 'components/Nutritionist/Planning/FilterMealsData'

import './style.css';

const mealsData = ['calories', 'protein', 'fat', 'carbs'];

export default function MealPlan() {
  const [mealImg, setMealImg] = useState('');
  const [generateHeight, setGenerateHeight] = useState();
  const [active, setActive] = useState(0);
  const [isFilterMealsData, setIsFilterMealsData] = useState(false);
  const [mealsDataItem, setMealsDataItem] = useState(mealsData[0]);
  const [selectedNutrients, setSelectedNutrients] = useState("calories")
  const { isMobile, isGuest, isVisitor } = useAppContext();
  const mealPlanBodyRef = useRef();
  const mealPlanColumnsRef = useRef();

  useEffect(() => {
    setGenerateHeight(window.innerHeight - 546);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setMealsDataItem(mealsData[active]);
      setIsFilterMealsData(false);
    }
  }, [active]);

  const MyPreview = () => {
    const { display, style } = usePreview();
    const translateY = style?.transform.split('px')[1].slice(2);
    const translateX = style?.transform.split('(')[1].split('px')[0];
    const mealBodyColumnsWidth = window.innerWidth - 15;
    if (!display) {
      return null;
    }
    if (translateY >= window.innerHeight - 95) mealPlanBodyRef.current.scrollBy({ top: 100, behavior: "smooth" });
    if (translateY <= window.innerHeight - 428) mealPlanBodyRef.current.scrollBy({ top: -100, behavior: "smooth" });
    if (translateX >= window.innerWidth - 66) mealPlanColumnsRef.current.scrollBy({ left: 100, behavior: "smooth" });
    if (translateX <= window.innerWidth - mealBodyColumnsWidth) mealPlanColumnsRef.current.scrollBy({ left: -100, behavior: "smooth" });
    
    return (
      <div class="item-list__item" style={style}>
        {mealImg ? (
          <img src={mealImg} alt="Meal Img" style={{ top: `-${generateHeight}px` }} />
        ) : (
          <div className="img-repalce" style={{ top: `-${generateHeight}px` }}>
            <ImSpoonKnife />
          </div>
        )}
      </div>
    );
  };

  const settingIcon = <div className='setting-icon-wrapper' onClick={() => setIsFilterMealsData(true)}>
    <img src="/icons/setting-4.svg" alt="Setting Icon" />
  </div>;

  return (
    <div className={classNames({ MealDragDropGuest: isGuest || isVisitor })}>
      {/* <div className="meal-plan-heading"> */}
      {/* <div className="tip-wrapper">
            <div className="tip-container">
              <Typist avgTypingDelay={100}>
                <div className="tip-content">
                  <Typist.Delay ms={1000} />
                  {tips || 'Full of energy you will feel, after eating a healthy meal'}
                </div>
              </Typist>
            </div>
            <div className="img-wrapper-section">
              <div className="img-wrapper">
                <ImageViewer src={img || '/images/avatar.png'} fallbackSrc={'/img_coming_soon.png'} alt="Avatar" className="avatar" />
              </div>
            </div>
          </div> */}
      {/* <div className="calories-color-wrapper">
            <span className="calories-color">Pefect</span>
            <span className="item pefect"></span>
            <span className="item enough"></span>
            <span className="item slightly"></span>
            <span className="item do-better"></span>
          </div>
          <p>Drag and drop meals to play out meal plan scenarios</p> */}
      {/* </div> */}
      {isMobile ? (
        <div className="description">
          <div className='description-content'>
            <img src="/icons/export.svg" alt="Export Icon" />
            <p>Drag n' drop meals to fit them within your plan</p>
          </div>  
          {settingIcon}
        </div>
      ) : (<>{settingIcon}</>)}
      {isFilterMealsData && <FilterMealsData
        mealsData={mealsData}
        setMealsDataItem={setMealsDataItem}
        setIsFilterMealsData={setIsFilterMealsData}
        active={active}
        setActive={setActive}
        setSelectedNutrients={setSelectedNutrients}
      />}
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={{ enableTouchEvents: true, enableMouseEvents: true, delayTouchStart: 300 }}
      >
        <MealList setMealImg={setMealImg} mealsDataItem={mealsDataItem} mealPlanBodyRef={mealPlanBodyRef} mealPlanColumnsRef={mealPlanColumnsRef} />
        {isMobile && <MyPreview />}
      </DndProvider>
    </div>
  );
}

