import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as AreaImage } from 'icons/Area.svg';
import { ReactComponent as Cross } from 'assets/shop/cross-black.svg';
import { ReactComponent as People } from 'icons/people.svg';
import { ReactComponent as Star } from 'icons/star.svg';
import { Button } from 'components/elements/button/Button';
import { useHistory } from 'react-router';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';

const AreaStyle = styled.div`
position: fixed;
inset: 0;
background: white;
top: 0;
bottom: 0;
z-index: 999;
.close-icon{
    margin: 12px 0px 12px 16px;
}
.image-container{
    margin-top: ${({ userJoin }) => userJoin ? '58px' : '35px'};
    text-align-last: center;
}
.details{
    height: calc(60vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title{
    color: #0B1D17;
    text-align: center;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: ${({ userJoin }) => userJoin ? '40px' : '28.08px'};
    margin-bottom: 24px;
}
.desc{
    color: #000A2C;
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: ${({ userJoin }) => !userJoin ? "250px" : "320px"};
    margin: auto;
}
.buttons{
    text-align: center;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    .joined{
        display: flex;
        gap: 8px;
        align-self: center;
        color: #000A2C;
    }
    button{
        max-width: 230px;
    }
}
`

function Area({filteredKitchenList}) {
    const [userJoin, setUserJoin] = useState(false);
    const history = useHistory();
    const { waitingList, setWaitingList} = useShopContext();
    
    useEffect(() => {
        document.body.classList.add('hide-scroll');
        return () => document.body.classList.remove('hide-scroll');
     }, [])
    
     const handleClose = () => {
        setWaitingList(false);
        document.body.classList.remove('hide-scroll');
        if(filteredKitchenList?.length === 0){
            history.push('/self-onboarding/address')
        }
     }

    return (
        <>
            <AreaStyle userJoin={userJoin}>
                {!userJoin ? (
                    <>
                        <Cross className='close-icon' onClick={handleClose}/>
                        <div className='image-container'>
                            <AreaImage />
                        </div>
                        <div className='details'>
                        <div className='info'>
                        <h3 className='title'>We are coming to your area!</h3>
                        <h1 className='desc'>Join the waiting list and be the first to know</h1>
                        </div>
                        <div className='buttons'>
                            <div className='joined'>
                                <People />
                                638 already joined
                            </div>
                            <Button type={'mobile_save'} title="Join waiting list" onClick={() => setUserJoin(true)} />
                        </div>
                        </div>
                    </>
                )
                    :
                    (
                        <>
                            <Cross className='close-icon' onClick={handleClose}/>
                            <div className='image-container'>
                                <Star />
                            </div>
                            <h3 className='title'>You are on the list now!</h3>
                            <h1 className='desc'>Your spot on the waiting list is secured. We’re working hard to bring Nutritt to you.</h1>
                            <h1 className='desc' style={{ marginTop: '20px' }}>Stay tuned!</h1>
                        </>
                    )
                }
            </AreaStyle>
        </>
    )
}

export default memo(Area);
