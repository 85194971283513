import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import Tag from './Tag';
import { useKitchenContext } from 'context/kitchenContext';
import { useMemo } from 'react';
import { useEffect } from 'react';

const DietsStyles = styled.div`
  background: ${({ theme }) => theme?.colors?.white};
  padding: 0 80px 24px 24px;
  max-width: 900px;
  border-radius: 6px;
  .icons {
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    display: grid;
    white-space: nowrap;
  }
`;

const Diets = ({ setMealDetails, mealDetails }) => {
    const Diet = [
      'Vegan',
      'Kosher',
      'Halal',
      'Vegetarian',
      'Ovo-vegetarian',
      'High-protein',
      'Paleo',
      'Ketogenic',
      'Low-carb',
      'Lacto-vegetarian',
    ];
        
      const handleTagClick = (tag) => {
      const capitalizedTag = tag.toUpperCase();
    
        if (mealDetails.recommendedTags.includes(capitalizedTag)) {
        const updatedRecommendedTags = mealDetails.recommendedTags.filter(
          selectedTag => selectedTag !== capitalizedTag
        );
        setMealDetails({
          ...mealDetails,
          recommendedTags: updatedRecommendedTags,
        });
      } else {
        const updatedRecommendedTags = [...mealDetails.recommendedTags, capitalizedTag];
        setMealDetails({
          ...mealDetails,
          recommendedTags: updatedRecommendedTags,
        });
      }
    };


    return (
<DietsStyles theme={theme}>
    <div className="icons">
        {Diet?.map((tag, index) => (
            <Tag
                key={index}
                tag={tag}
                isSelected={mealDetails?.recommendedTags.includes(tag.toUpperCase())}
                onTagClick={(tag, isSelected, avoid, recomend) => handleTagClick(tag, isSelected, avoid, recomend)}
                recomend={mealDetails?.recommendedTags.includes(tag.toUpperCase())}
                />
        ))}
    </div>
</DietsStyles>
    );
      };
  
  export default Diets;
  