import { useEffect } from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import OrderItems from '../OrderItems';
import { displayDateTime } from 'utils';
import { Skeleton } from '@mui/material';
import DeliveryInfo from '../DeliveryInfo';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import PaymentBreakdown from '../PaymentBreakdown';
import OrderNumberStatus from '../OrderNumberStatus';
import { theme } from '../../../../shared/theme/theme';
import { Modal } from 'components/elements/modal/Modal';
import { Button } from 'components/elements/button/Button';
import { useKitchenContext } from 'context/kitchenContext';
import { ReactComponent as Left } from 'icons/angleLeft.svg';
import { ReactComponent as Right } from 'icons/angleRight.svg';
import { OrderActionModal } from 'pages/provider/storefront/orders/style';
import { OrderDetailsPageWrapper } from './style';

export default function OrderDetailsPage({
  isOpen,
  setIsOpen,
  selectedOrder,
  setSelectedOrder,
  handleSave,
  orderIndex,
  setOrderIndex,
  generateOrders,
  handleOrderSeen,
  imitateProviderId,
  isOrdersApiCallProcessing,
  setPageNumber,
  pageNumber,
  setOrderMove,
  orderNumber,
  selectedTabId,
  filterQuery,
  sort,
  sortBy,
  setFilterQuery
}) {
  const {
    friendlyOrderNum,
    orderStatus,
    orderedAt,
    dateTimeOrdered,
    providers,
    orderMainId: orderId,
    orderLine,
    user,
  } = selectedOrder;
  const { isSuper } = useAppContext();
  const { kitchenProvider } = useKitchenContext();
  const history = useHistory();
  const {pathname} = useLocation();
  const findProviderIndex = providers?.findIndex(
    (provider) => provider?.Provider?.id === kitchenProvider?.id,
  );
  const {
    selectedDate,
    selectedTime,
    taxAmount,
    providerSubTotal,
    providerPayout,
    isDelivery,
    deliveryFee,
    deliveryData,
    phoneNumber,
    orderNote,
    isRecurringOrder,
    street,
    city,
    state,
    zip,
    id,
    isOrderSeen,
    Provider: { id: providerId } = {},
    repeatOrderDiscount,
  } = isSuper ? providers[0] : providers[findProviderIndex];
  const formattedTime = moment(dateTimeOrdered).format('LT');
  const orderStatusIndex = orderStatus.findIndex((item) => item?.providerId === providerId);
  const orderType = orderStatus[orderStatusIndex]?.action;
  const { name, lastName } = user;
  const customerName = name + (lastName?.length ? ' ' + lastName : '');
  const method = isDelivery ? 'Delivery' : 'Pickup';
  const checkFirstOrder = orderIndex === 0 && pageNumber === 1;
  const checkLastOrder =
    orderIndex === generateOrders?.orders?.length - 1 && pageNumber === generateOrders?.totalPages;

  useEffect(() => {
    handleOrderSeen(id, providerId, isOrderSeen);
  }, [selectedOrder]);

  const handlePreviousOrder = () => {
    if (checkFirstOrder || isOrdersApiCallProcessing) return;
    if (orderIndex === 0) {
      setPageNumber(pageNumber - 1);
      setOrderMove('previous');
    } else {
      setOrderIndex(orderIndex - 1);
      setOrderMove('');
    }
  };

  const handleNextOrder = () => {
    if (checkLastOrder || isOrdersApiCallProcessing) return;
    if (orderIndex === 49) {
      setPageNumber(pageNumber + 1);
      setOrderMove('next');
    } else {
      setOrderIndex(orderIndex + 1);
      setOrderMove('');
    }
  };

  return (
    <OrderDetailsPageWrapper>
      <div className="title-wrapper">
        <img
          src="/icons/arrow-left-gray.svg"
          alt="Arrow Icon"
          onClick={() => {
            setSelectedOrder({});
            history.push('/kitchen/orders');
            if (pathname === '/kitchen/orders/orderDetails' || localStorage.getItem('orderDetails')) setFilterQuery('');
          }}
        />
        <div className="title">Order details</div>
      </div>
      <div className="order-info-controls">
        <div className="order-number-status">
          <OrderNumberStatus
            orderNumber={friendlyOrderNum}
            orderType={orderType}
            displayOrderDate={displayDateTime(orderedAt)}
            formattedTime={formattedTime}
            isRecurringOrder={isRecurringOrder}
          />
        </div>
        <div className="order-controls-wrapper">
          {(orderType === 'pending' || orderType === 'accepted') && (
            <Button
              title={orderType === 'pending' ? 'Accept' : 'Complete'}
              type="dark"
              iconLeft={
                <img
                  src={`/icons/${orderType === 'pending' ? 'receipt-right' : 'receipt-edit'}.svg`}
                  alt="Recept Icon"
                />
              }
              onClick={() =>
                orderType === 'pending'
                  ? handleSave('accepted', orderId)
                  : handleSave('completed', orderId)
              }
            />
          )}
          {orderType === 'pending' && (
            <Tooltip
              overlayClassName="kitchen-orders-tooltip"
              placement="top"
              overlay="Reject order"
              arrowContent={<div />}
            >
              <div className="remove-order-wrapper" onClick={() => setIsOpen(true)}>
                <img
                  src="/icons/receipt-remove.svg"
                  alt="Receipt Icon"
                  className="receipt-remove-icon"
                />
              </div>
            </Tooltip>
          )}
          <Tooltip
            overlayClassName="kitchen-orders-tooltip"
            placement="top"
            overlay="Print order"
            arrowContent={<div />}
          >
            <a
              href={`/kitchen/orders/print${isSuper ? `?providerId=${imitateProviderId}` : ''}`}
              target="_blank"
            >
              <div
                className="print-button-wrapper"
                onClick={() => {
                  localStorage.setItem('selectedOrder', JSON.stringify(selectedOrder));
                  localStorage.setItem(
                    'orderDetails',
                    JSON.stringify({ orderNumber, pageNumber, selectedTabId, filterQuery, sort, sortBy}),
                  );
                }}
              >
                <img src="/icons/printer.svg" alt="Printer Icon" />
              </div>
            </a>
          </Tooltip>
          <div className="divider" />
          {isOrdersApiCallProcessing ? (
            <Skeleton variant="rectangle" width={91} height={20} />
          ) : (
            <div className="order-control">
              <Tooltip
                overlayClassName="kitchen-orders-tooltip"
                placement="top"
                overlay="Previous"
                arrowContent={<div />}
              >
                <div
                  className={`arrow-wrapper ${checkFirstOrder ? 'inActive' : ''}`}
                  onClick={handlePreviousOrder}
                >
                  <Left />
                </div>
              </Tooltip>
              <Tooltip
                overlayClassName="kitchen-orders-tooltip"
                placement="top"
                overlay="Next"
                arrowContent={<div />}
              >
                <div
                  className={`arrow-wrapper ${checkLastOrder ? 'inActive' : ''}`}
                  onClick={handleNextOrder}
                >
                  <Right />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="order-details-container">
        <div className="order-details-left">
          <DeliveryInfo
            customerName={customerName}
            phoneNumber={phoneNumber}
            method={method}
            displaySelectedDate={displayDateTime(selectedDate)}
            selectedTime={selectedTime}
            deliveryData={deliveryData}
            orderNote={orderNote}
            street={street}
            city={city}
            state={state}
            zip={zip}
            orderType={orderType}
          />
          <OrderItems orderLine={orderLine} />
        </div>
        <section className="order-details-right">
          <PaymentBreakdown
            providerSubTotal={providerSubTotal}
            deliveryFee={deliveryFee}
            taxAmount={taxAmount}
            providerPayout={providerPayout}
            repeatOrderDiscount={repeatOrderDiscount}
            isRecurringOrder={isRecurringOrder}
          />
        </section>
      </div>
      {isOpen && (
        <OrderActionModal>
          <Modal
            description={'Are you sure you want to reject this order?'}
            width="500px"
            paddingBottom="30px"
            paddingTop="4px"
            color={theme.colors.blackDefault}
            isOpen={isOpen}
            onOk={() => handleSave('refused', orderId)}
            onClose={() => setIsOpen(!isOpen)}
            onCancel={() => setIsOpen(!isOpen)}
            title={'Reject the order'}
            primaryText={`Reject order`}
            secondaryText={'Cancel'}
            isBackdropEnabled={true}
            buttonType={'dark'}
            type="null"
          />
        </OrderActionModal>
      )}
    </OrderDetailsPageWrapper>
  );
}
