import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NutritionistControlsWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;

  .undo-icon {
    width: 40px;
    height: 40px;
    background-color: #51635d;
    border-radius: 50%;
    cursor: pointer;
    ${FlexCenter};
  }

  .primary-btn,
  .cancel-btn {
    width: 140px;
    height: 40px;
    padding: 0;
    border-radius: 21px;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  }

  @media(min-width:992px){
    .primary-btn{
      margin-left:14px;
    }  
  }

  .primary-btn {
    // width:100%;
    // margin-top:20px;
  }

  .cancel-btn {
    background-color: #ffffff;
    color: #51635d;
    border: 1px solid #97dc76 !important;
    cursor: pointer;
    ${FlexCenter};
  }
  button: disabled { 
    width:100%;
    background-color: #e0e3e0;
    color: #949d9b;
    cursor: not-allowed;
  }

  @media (max-width: 370px) {
    width: 100%;
    justify-content: center;
    place-items: baseline;

    .primary-btn,
    .cancel-btn {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
`;

export default function NutritionistControls({ cancelBtn, undoIcon, cancelLink, undoChanges, publishHandler, disablePublish, challage,next ,setValue}) {
  const history = useHistory();

  const handleCancelLink = () => {
    history.push(cancelLink);
    window.scrollTo(0, 0);
  };

  return (
    <NutritionistControlsWrapper>
      {cancelBtn && (
        <div className="cancel-btn" onClick={handleCancelLink}>
          Cancel
        </div>
      )}
      {undoIcon && (
        <div className="undo-icon" onClick={undoChanges}>
          <img src="/icons/undo.svg" alt="Undo Icon" />
        </div>
      )}
      {!next&&<button className="primary-btn" disabled={disablePublish} onClick={publishHandler}>{challage ? 'Publish challenge' : 'Publish'}</button>}
      {next&&<button className="primary-btn" disabled={disablePublish} onClick={()=>{setValue(1) ;  window.scrollTo(0, 0);}}> Next</button>}
    </NutritionistControlsWrapper>
  );
}
