import { memo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { useAppContext } from 'context/appContext';
import { PieWrapper } from './style';
import { useLocation } from 'react-router';

function Pie({
  width, // number
  height, // number
  titleNumber,
  titleText,
  data, // array of object
  OuterRadiusHover = 0, // number
  marginTop = 0, // number - must be use if use OuterRadiusHover
  marginRight = 0, // number - must be use if use OuterRadiusHover
  marginBottom = 0, // number - must be use if use OuterRadiusHover
  marginLeft = 0, // number - must be use if use OuterRadiusHover
  innerRadius, // number - less than 1
  colors, // array of string
  padAngle
}) {
  const {isUser} = useAppContext();
  const Activelocation = useLocation();
  return (
    <PieWrapper
      style={{
        width: `${width + marginRight + marginLeft}px`,
        height: `${height + marginTop + marginBottom}px`,
      }}
    >
      <div className="title-wrapper">
        
<div style={{ fontSize: (() => {
    const numberLength = titleNumber?.toString().length;
  switch (numberLength) {
    case 5:
      return 20;
    case 6:
      return 18;
    default:
      return 20; // Default fontSize if none of the above conditions match
  }
})() }} className="title-number">
  {titleNumber}
</div>
        <div className="title-text">{titleText}</div>
      </div>
     
      <ResponsivePie
        width={width}
        height={height}
        data={data}
        margin={{ top: marginTop, right: marginRight, bottom: marginBottom, left: marginLeft }}
        innerRadius={innerRadius}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={OuterRadiusHover}
        valueFormat={(value) => `${value}g`}
        colors={colors}
        padAngle={padAngle}
        valueFormat={(value) => `${value}${Activelocation.pathname==='/shop/account/health-goal'?"%":''}`}
      />
    </PieWrapper>
  );
}

export default memo(Pie);
