import React, { memo, useCallback, useEffect } from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import { Skeleton } from '@mui/material';
import { theme } from 'shared/theme/theme';
import { ReactComponent as Next } from 'icons/angleRight.svg';
import { ReactComponent as Previous } from 'icons/angleLeft.svg';
import styled from 'styled-components';

const PaginationStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .number_of_items {
    font-family: ${theme.typography.fontGilroy};
    font-size: 14px;
    color: ${theme.colors.grayDarker};
    font-weight: 400;
    line-height: 20px;
  }
  .table-pagination-wrapper {
    display: flex;
    gap: 12px;
    .pagination-arrow {
      padding: 10px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          #878a98;
      }
      &:active {
        background: #e9eaec;
      }

      &.inactive {
        svg path {
          fill: ${theme.colors.grayDark};
        }
        cursor: not-allowed;
        &:hover,
        &:active {
          background: transparent;
        }
      }
    }
  }
`;

function Pagination({
  startItem,
  endItem,
  totalCount,
  isOrdersApiCallProcessing,
  orderDataPageNumber,
  setOrderDataPageNumber,
  totalPages,
  setIsSplit,
}) {
  const handlePageNext = useCallback(() => {
    if (
      isOrdersApiCallProcessing ||
      orderDataPageNumber >= totalPages ||
      totalPages === 1 ||
      totalPages === 0
    )
      return;
    setOrderDataPageNumber(orderDataPageNumber + 1);
    setIsSplit(false);
  });

  const handlePagePervious = useCallback(() => {
    if (isOrdersApiCallProcessing || orderDataPageNumber === 1 || totalPages === 1) return;
    setOrderDataPageNumber(orderDataPageNumber - 1);
    setIsSplit(false);
  });

  useEffect(() => {
    if (orderDataPageNumber > totalPages) setOrderDataPageNumber(orderDataPageNumber - 1);
  }, [orderDataPageNumber]);

  return (
    <PaginationStyle theme={theme}>
      <div className="number_of_items">
        {isOrdersApiCallProcessing ? (
          <Skeleton variant="rectangle" width={100} height={20} />
        ) : totalCount === 0 || totalCount === undefined ? (
          `Showing 0 items`
        ) : (
          `${startItem}-${endItem ? endItem : '0'} of ${totalCount}`
        )}
      </div>
      {isOrdersApiCallProcessing ? (
        <Skeleton variant="rectangle" width={80} height={20} />
      ) : (
        <div className="table-pagination-wrapper">
          <Tooltip
            overlayClassName="kitchen-orders-tooltip"
            placement="top"
            overlay="Previous"
            arrowContent={<div />}
          >
            <div
              className={classNames('pagination-arrow', {
                inactive: orderDataPageNumber === 1 || totalPages === 1,
              })}
              onClick={handlePagePervious}
            >
              <Previous />
            </div>
          </Tooltip>
          <Tooltip
            overlayClassName="kitchen-orders-tooltip"
            placement="top"
            overlay="Next"
            arrowContent={<div />}
          >
            <div
              className={classNames('pagination-arrow', {
                inactive: orderDataPageNumber >= totalPages || totalPages === 1 || totalPages === 0,
              })}
              onClick={handlePageNext}
            >
              <Next />
            </div>
          </Tooltip>
        </div>
      )}
    </PaginationStyle>
  );
}

export default memo(Pagination);
