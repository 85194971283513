import React, { useEffect, useState,useCallback } from 'react';
import styled from 'styled-components';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useHistory, useLocation } from 'react-router-dom';
import { theme } from 'shared/theme/theme';
import RegularInput from 'components/Input/RegularInput';
import { ReactComponent as CloudUpload } from 'assets/cloud-upload.svg';
import { Ingredients } from './components/Ingredients';
import { Button } from 'components/elements/button/Button';
import DropDown from 'components/elements/drop-down/Dropdown';
import NutritionistFact from './components/NutritionistFact';
import Allergies from './components/DietaryPreference/Allergies';
import Diets from './components/DietaryPreference/Diets';
import { useRequest } from 'hooks/useRequest';
import Alert from 'components/elements/alert/Alert';
import { useMemo } from 'react';
import { calculateMealData } from 'utils';
import { useKitchenContext } from 'context/kitchenContext';
import { Modal } from 'components/elements/modal/Modal';
import ImageContainer from 'components/elements/imageUploader/ImageUpload';
import ImagesDragDrop from './components/ImagesDragDrop';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useAppContext } from 'context/appContext';
import { ASSET_TYPE } from '../../../../constants';
import AccordionWrapper from './components/AccordionWrapper';
import LimitedPerOrder from './components/LimitedPerOrder';
import Availability from './components/Availability';

const AddItemStyle = styled.div`
  padding-top: 28px;
  padding-left: 24px;
  height: 100vh;
  background-color:#F9F9F9;
  overflow: hidden;
  font-family: ${theme.typography.fontGilroy};
    .alert {
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top: 12px;
  }
  .add_item_header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    max-width: 945px;
    .save_button{
        display: flex;
        gap: 8px;
      .btn {
        height: 40px;
        border: 1px solid ${theme.colors.primaryDark} !important;
        border-radius: 6px;
        font-family: ${theme.typography.fontGilroy};
        &.dark {
          border: 0 !important;
        }
      }
    }
  }
  .main-container{
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 88px);
  }
  .add_item_form {
    padding: 24px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    background: ${({ theme }) => theme?.colors?.white};
    max-width: 900px;
    margin-bottom: 20px;
  }
  .add_item_title {
    display: flex;
    align-items: center;
    gap: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-size: ${({ theme }) => theme?.typography?.fontXl};
    font-weight: 600;
    line-height: 32px;
    .icon {
      font-size: 24px;
      display: block;
      cursor: pointer;
      color: ${({ theme }) => theme?.colors?.grayDark};
    }
  }
  .form_row {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .form_group {
    flex: 1;
    label {
      color: ${theme.colors.blackDefault};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
      display: block;
      span {
        color: ${({ theme }) => theme?.colors?.danger};
      }
    }
    input {
      height: 40px;
      color: ${({ theme }) => theme?.colors?.blackDefault};
      border-radius: 8px;
      padding: 10px 8px;
      border-color: ${theme.colors.grayLight};
      font-family: ${theme.typography.fontGilroy};
      ::webkit-placeholder {
        color: ${({ theme }) => theme?.colors?.blackLight};
      }
    }
    input[name="price"]{
      border-radius: 6px;
    }
    .custom_textarea {
      padding: 10px 14px 10px 8px;
      height: 175px;
      resize:none;
      width: 100%;
      border-radius: 6px;
      border: 1px solid rgba(50, 64, 84, 0.2);
      font-family: ${({ theme }) => theme?.typography?.fontInter};
      font-size: ${({ theme }) => theme?.typography?.fontRegular} !important;
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme?.colors?.blackDefault};
      ::placeholder {
        color: ${({ theme }) => theme?.colors?.blackLight};
      }
      :hover{
        border: 1px solid #3e66fb;
      }
      &:focus {
        border-color: #3e66fb !important;
      }
    }
    .multi-images{
      border-radius: 6px;
      border: 1px solid  ${({ theme }) => theme?.colors?.grayLight};
      padding: 2px 0px 13px 14px;
      .main-image{
        position: relative;
        display: flex;
        align-items: center;
        gap: 6px;
        .cross_icon{
          position: absolute;
          top: 4px;
          right: 6px;
          cursor: pointer;
        }
      }
      .main-img{
        width: 320px;
        height: 179px;
        border-radius: 6px;
        cursor: grab;
      }
      .image-container{
        display:flex;
          .sub_image_upload{
              display: inline-flex;
              width: 127px;
              height: 85px;
              padding: 29px 28px 28px 29px;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #878A98;
              position: relative;
              overflow: clip;
              img{
                width: 127px;
                height: 85px;
              }
              .add_icon{
                cursor: pointer;
              }
              .cross_icon{
                position: absolute;
                top: 5px;
                right: 3px;
                cursor: pointer;
              }
           }
          .multi_sub_image{
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              cursor:pointer;
              margin-left: 10px;
           }
        }
      }
    }
    .product_images {
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px dashed #c9cccf;
      background: ${({ theme }) => theme?.colors?.blackLightest};
      cursor: pointer;
      .icon {
        margin-bottom: 24px;
      }
      .text {
        font-size: ${({ theme }) => theme?.typography?.fontSmall};
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme?.colors?.primaryDefault};
      }
    }
  .category {
    max-width: 305px;
    width: 100%;
  }
  .selected-value{
    color: ${({ theme }) => theme?.colors?.blackDefault}!important;
    background: ${({ theme }) => theme?.colors?.white} !important;
  }
  .tags{
    display:flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .add_icon{
    height:20px !important;
    width:20px !important;
  }
  .options-dropdown input{
    margin: 10px 8px;
    &:hover,
      &:focus {
        border-color: #000A2C !important;
      }
  }
  .description-container{
    display:flex;
    justify-content: space-between;
  .textArea-number{
    color:#757E89;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
  .MuiAccordionDetails-root .add_item_form {
    margin: 0;
    border: 0;
    &.ingredients{
      padding-top: 0;
    }
  }
`;

const DeleteModalStyle = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 50, 65, 0.4);
`

const ConfirmationStyle = styled.div`
.modal-position {
  position: fixed;
  inset: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(40, 50, 65, 0.6);
}
`;

export const AddItem = () => {
  const history = useHistory();
  const [getAllOption] = useRequest();
  const [options, setOptions] = useState([]);
  const [categories, setCategories] = useState('');
  const [ingredientData, setIngredientData] = useState([]);
  const [saveMealApi, { isLoading }] = useRequest();
  const [displayMessage, setDisplayMessage] = useState({ type: '', body: '' })
  const {kitchenProvider,mealData, setMealData,selectedMealId,setSelectedMealId,defaultMealState, kitchenHasSubscription} = useKitchenContext() || {};  
  const [addressInfo] = kitchenProvider?.BusinessProfile;
  const [getMealDetailsApi]=useRequest(`meal/details/${selectedMealId}`,'get')
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryId = params.get('categoryId')
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isPriceEmpty, setIsPriceEmpty] = useState(false);
  const [isQuantityEmpty, setIsQuantityEmpty] = useState(false);
  const [isRemoveImage, setIsRemoveImage] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [orderLimit, setOrderLimit] = useState(null);
  const [isCheckedOrderLimit, setIsCheckedOrderLimit] = useState(false);
  const { isMobile, isKitchenHasSubscription, provider } = useAppContext();
  const mealId = params.get("mealId");
  const fileInputRefs = Array.from({ length: 6 }, () => React.createRef());
  const [edmamDeletedTags,setEdmamDeletedTags]=useState([]);
  const handleFileClick = (index) => {
    if (fileInputRefs[index]?.current) {
      fileInputRefs[index]?.current.click();
    }
  };
  const [prevMealData, setPrevMealData] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);


  useEffect(() => {
    setSelectedMealId(mealId);
  }, [mealId]);

const handleFileChange = async (e) => {
  const imageFile = e.target.files[0];
  console.log(imageFile, 'imageFile');
  e.target.value = '';

  if (imageFile) {
    if (
      imageFile.type.startsWith("image/") &&
      imageFile.size <= 5 * 1024 * 1024
    ) {
      const imageUrl = URL.createObjectURL(imageFile);
      console.log(imageUrl, 'imageUrl');
      setImage(imageUrl);
    } else {
      console.error("Invalid file type or size");
      setDisplayMessage({ type: "error", body: "Invalid file type or size" });
      setTimeout(()=>{
        setDisplayMessage({ type: '', body: '' })
      }, 2000);
    }
  }
};
  

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (categoryId !== null) {
      const matchingOption = options.find(option => option.value === parseInt(categoryId));
      if (matchingOption) {
          setCategories(matchingOption);
          setMealData((prev) =>  ({...prev,mealCategoryId: matchingOption?.value}))
      }
  }
  },[options,categoryId])
  const fetchAllOption = async () => {
    try {
      let path = `meal/categories?businessProfileId=${addressInfo?.id}`;
      const result = await getAllOption({ path });
      const res = result.data;
      setOptions(res.map(order => ({ label: order.name, value: order.id })));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllOption();
  }, []);

  const handleInputChange = (e) => {
    setIsNameEmpty(false);
    setIsPriceEmpty(false);
    const { name, value } = e.target;
    if (name === "description" && value.length > 400) {
      return;
    }
  
    if (name === "name" && value.length > 50) {
      return;
    }
    if (name === "price") {
      const cleanedValue = value.replace(/[^0-9.]/g, '');
      const decimalCount = (cleanedValue.split('.')[1] || []).length;
      if (parseFloat(cleanedValue) >= 99999) {
        return;
      }
      if (decimalCount > 2) {
        return;
      }
      setMealData((prevState) => ({
        ...prevState,
        [name]: cleanedValue,
      }));
    } else {
      setMealData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleCategoriesChange = (value) => {
    setMealData((prevState) => ({
      ...prevState,
      mealCategoryId: value
    }));
    setCategories(value)
  };
 
 
  const calculatedMealData = useMemo(() => {
    const filteredIngredients = ingredientData?.filter(ingredient => !ingredient.isDeleted);
    if (filteredIngredients.length < 1) {
      setMealData({ ...mealData, restrictions: [], tags: [] });
    }
    return calculateMealData(filteredIngredients);
  }, [ingredientData]);
  
  const [mealDetails, setMealDetails] = useState(calculatedMealData);

  const buildMealPayload = useCallback((mealId, mealData, ingredientData) => {
    const { totalCalories, carbohydrates, protein, fat,totalWeight } = calculatedMealData;
    const updatedMealIngredients = ingredientData.map(({ selectedIngredientData, ...rest }) => rest);
    const updatedIngredients = updatedMealIngredients.map(item => {
      if (item.allergies) {
          const filteredAllergies = item.allergies.filter(tag => !edmamDeletedTags.includes(tag));
          return { ...item, allergies: filteredAllergies };
      } else {
          return item;
      }
    });

    // Iterate over mealIngredients array
    const ingredientsWithRequiredMeasures = updatedIngredients?.map((ingredient) => {
      // Extract unit label from the ingredient unit
      const unitLabel = ingredient?.unit?.split('-')[0];

      // Find the matching measure in macros
      const measures = ingredient.macros.measures.measures;
      const matchedMeasures = measures.filter((item) => item?.label === unitLabel);

      // Update the measures array with only the matched measures
      ingredient.macros.measures.measures = matchedMeasures;
      return ingredient;
    });

    const payload = {
      ...mealData,
      calories: ingredientData?.length > 0 ? totalCalories : 0,
      carbs: carbohydrates.grams,
      protein: protein.grams,
      fat: fat.grams,
      weight:totalWeight,
      restrictions: ingredientData?.length < 1 ? [] : mealDetails?.restrictions,
      tags: mealDetails?.recommendedTags,
      mealIngredients: ingredientsWithRequiredMeasures,
      orderLimit: Number(orderLimit),
      isRotationMenu: mealData?.rotationMenuSchedule?.length < 1 ? false : mealData?.isRotationMenu
    };

    if (mealId) {
      payload.id = mealId;
    }

    return payload;
  },
    [calculatedMealData,mealDetails,edmamDeletedTags, orderLimit]);

  const handleSaveMeal = async () => {
    setIsAlertOpen(false);
    if(!mealData.name){
      setIsNameEmpty(true);
    }
    if(!mealData.price){
      setIsPriceEmpty(true);
    }
    if(isCheckedOrderLimit && !orderLimit) {
      setIsQuantityEmpty(true);
    }
    if(!mealData.name || !mealData.price || (isCheckedOrderLimit && !orderLimit)) {
      return;
    }
    try {
      const bodyPayload = buildMealPayload(selectedMealId, mealData, ingredientData);
      const options = { body: bodyPayload, path: selectedMealId ? `meal/update?providerId=${kitchenProvider?.id}&businessProfileId=${addressInfo?.id}` : `meal/add?providerId=${kitchenProvider?.id}&businessProfileId=${addressInfo?.id}`, method: selectedMealId ? 'patch' : 'post' }
      const result = await saveMealApi({ ...options });
      setDisplayMessage({ type: "success", body: result.message });
      setTimeout(() => {
        setSelectedMealId(null);
        setMealData(defaultMealState);
        setIngredientData([]);
        setEdmamDeletedTags([]);
        history.push('/kitchen/menu');
      }, 2000); 
     
    } catch (error) {
      setDisplayMessage({ type: "error", body: error?.length ? error[0] : error });
      console.log(error);
      setTimeout(()=>{
        setDisplayMessage({ type: '', body: '' })
      }, 2000);
    }
  };

  const handleSaveMealFn = async () => {
    const isMealPriceUpdated = prevMealData?.price && Number(prevMealData.price) !== Number(mealData.price);
    const isOrderLimitUpdated = prevMealData?.orderLimit && Number(prevMealData?.orderLimit) > Number(orderLimit)
    const { upcomingDeliveryDate = null, hasSubsciption = false } = kitchenHasSubscription || {};
    const isDeliveryDateNotMatch = (mealData?.isRotationMenu === true && mealData.rotationMenuSchedule?.length > 0) && !mealData.rotationMenuSchedule.includes(upcomingDeliveryDate);
    if (hasSubsciption && selectedMealId && (isMealPriceUpdated || isOrderLimitUpdated || isDeliveryDateNotMatch)) {
      setIsAlertOpen(true);
    } else {
      handleSaveMeal();
    }
  };

  useEffect(() => {
    const combinedTags = {
      restrictions: [...new Set([...calculatedMealData.restrictions, ...mealData.restrictions])],
      tags: [...mealData.tags],
    };
    setMealDetails({
      ...calculatedMealData,
      restrictions: combinedTags.restrictions,
      recommendedTags: combinedTags.tags
    });
  }, [calculatedMealData, mealData]);

  const getMealDetails = useCallback(async () => {
    try {
      const result = await getMealDetailsApi();
      const {data}=result;
      const details=data?.mealDetails;
      setMealData({name:details?.name,price:details.price,mealCategoryId:details?.mealCategoryId,images:details?.images,description:details?.description,restrictions:details?.restrictions, tags: details?.tags, calories: details?.calories, rotationMenuSchedule: details?.rotationMenuSchedule, isRotationMenu: details?.isRotationMenu, orderLimit:details?.orderLimit});
      const isValidMealPrice = details.price !== '' ;
      const isValidOrderLimit = details?.orderLimit;
      if (isValidMealPrice || isValidOrderLimit) {
        setPrevMealData({ price: details?.price, orderLimit: details?.orderLimit });
      }
      setIngredientData(data?.mealDetails?.MealIngredients)
      if (details?.orderLimit > 0) {
        setOrderLimit(details?.orderLimit);
        setIsCheckedOrderLimit(true);
      } else {
        setOrderLimit(null);
      }
    } catch (error) {
      console.log(error)
    }
  }, [selectedMealId]);

useEffect(() => {
    if (selectedMealId) {
      getMealDetails()
    }
  }, [selectedMealId])

  const handleGoBackClick = () => {
    setSelectedMealId(null),
    setMealData(defaultMealState);
    setEdmamDeletedTags([]);
    setPrevMealData(null);
    history.goBack()
  }
  const handleCancelClick = () => {
    setSelectedMealId(null);
    setMealData(defaultMealState);
    setEdmamDeletedTags([]);
    setPrevMealData(null);
    history.push('/kitchen/menu')
  }

  const handleDeleteCancel = () => {
    setIsRemoveImage(false);
  }

  const handleDelete = () => {
    if (deleteIndex !== -1) {
      const updatedImages = [...mealData.images];
      updatedImages.splice(deleteIndex, 1);
      const updatedMealData = { ...mealData, images: updatedImages };
      setMealData(updatedMealData);
      setIsRemoveImage(false);
      setDeleteIndex(-1); 
    }
  };
  const subscriptionCount = kitchenHasSubscription?.subscriptionCount;

  const modalDes = `The system detects ${subscriptionCount} active subscriptions for this kitchen, and any changes made may affect their recurring orders. Are you sure you want to proceed?`

  const handleModalClose = () => {
    setOrderLimit(prevMealData?.orderLimit);
    setMealData((prevState) => ({
      ...prevState,
      price: prevMealData?.price
    }));
    setIsAlertOpen(!isAlertOpen);
  }
  return (
    <>
      <AddItemStyle theme={theme}>
        {
          displayMessage.type && (
            <div className='alert'>
            <Alert type={displayMessage.type} title={displayMessage.body} width="461px" onClose={() => setDisplayMessage({ type: '', body: '' })} />
            </div>
          )
        }
        <div className="add_item_header">
          <h2 className="add_item_title">
            <BiLeftArrowAlt onClick={handleGoBackClick} className="icon" />{selectedMealId? 'Update a item':'Add a item'}
          </h2>
          <div className='save_button'>
            <Button type={'secondary'} title='Cancel' onClick={handleCancelClick} />
            <Button type={'dark'} title='Save' onClick={handleSaveMealFn} isDisabled={isLoading} />
          </div>
        </div>
        <div className='main-container'>
        <div className="add_item_form">
          <div className="form_row">
            <div className="form_group">
              <label>
                Name of the product <span>*</span>
              </label>
              <RegularInput message={isNameEmpty ? "Name of the product is required" : ''} placeholder={'Input name of product'} name='name' value={mealData.name} onChange={handleInputChange} />
            </div>

            <div className="form_group category">
              <label>
                Category 
              </label>
              <DropDown
                option={[...options, { label: 'Select or add category', isPlaceholder: true }]}
                value={categories ? categories : { label: 'Select or add category', isPlaceholder: true }}
                onChange={(value) => handleCategoriesChange(value)}
                enableAdd={true} 
                kitchenProvider={kitchenProvider}
                setDisplayMessage={setDisplayMessage}
                />
            </div>
          </div>
          <div className="form_row">
            <div className="form_group">
              <div className='description-container'>
                <label>Description</label>
                <h5 className='textArea-number'>{mealData.description.length}/400</h5>
              </div>
              <textarea
                  className="custom_textarea"
                  placeholder={'Input description here'}
                  name='description'
                  value={mealData.description}
                  onChange={handleInputChange}
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                ></textarea>
            </div>
          </div>
          <div className="form_row">
            <div className="form_group">
              <label>Product images ({mealData?.images?.length ||0}/6)</label>
              <DndProvider
                backend={isMobile ? TouchBackend : HTML5Backend}
                options={{ enableTouchEvents: true, enableMouseEvents: true }}
              >
              {mealData?.images?.length > 0 ?
                  <ImagesDragDrop 
                    mealData={mealData}
                    setDeleteIndex={setDeleteIndex} 
                    setIsRemoveImage={setIsRemoveImage} 
                    handleFileClick={handleFileClick}
                    handleFileChange={handleFileChange}
                    fileInputRefs={fileInputRefs}
                    setMealData={setMealData}
                    image={image}
                    setImage={setImage}
                    id={mealId}
                  />
                  :
                  <>
                    <div onClick={() => handleFileClick(0)} className="product_images"><CloudUpload className="icon" /><p className="text">Drop your photos here, or browse computer</p></div>
                    <input
                      type="file"
                      accept="image/*" 
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      ref={fileInputRefs[0]}
                    />
                    {image && (
                      <div className="modal-container">
                        <ImageContainer
                          image={image}
                          setImage={setImage}
                          setIsVisible={setImage}
                          setNewImgUrl={(image) => {
                            setMealData((prevState) => ({
                              ...prevState,
                              images: [...prevState.images, image]
                            }));
                          }}
                          assetType={ASSET_TYPE?.kitchenMeal}
                          id={mealId}
                        />
                      </div>
                    )}
                  </>
                }
              </DndProvider>
              </div>
            </div>
          <div className="form_row">
            <div className="form_group">
              <label>
                Price <span>*</span>
              </label>
              <RegularInput type={"number"} placeholder={'$ 0.00'} name='price' value={mealData.price} onChange={handleInputChange}  message={isPriceEmpty ? "Price of the product is required" : ''} />
            </div>

            <div className="form_group category"></div>
          </div>
        </div>
        <AccordionWrapper title="Order settings" id={1}>
          <LimitedPerOrder 
            orderLimit={orderLimit} 
            setOrderLimit={setOrderLimit} 
            isCheckedOrderLimit={isCheckedOrderLimit} 
            setIsCheckedOrderLimit={setIsCheckedOrderLimit} 
            isQuantityEmpty={isQuantityEmpty} 
            setIsQuantityEmpty={setIsQuantityEmpty}
          />
        </AccordionWrapper>
        <AccordionWrapper title="Availability" id={2}>
          <Availability mealData={mealData} setMealData={setMealData}/>
        </AccordionWrapper>
        <AccordionWrapper title="Ingredients" id={3}>
        <Ingredients ingredientData={ingredientData} setIngredientData={setIngredientData} setDeleteIndex={setDeleteIndex} mealData={mealData} setMealData={setMealData}/>
        </AccordionWrapper>
        <AccordionWrapper title="Nutrition Facts" id={4}>
        <NutritionistFact calculatedMealData={calculatedMealData} ingredientData={ingredientData}/>
        </AccordionWrapper>
        <div className='tags'>
          <AccordionWrapper title="Allergens" id={5}>
          <Allergies mealDetails={mealDetails} setMealDetails={setMealDetails} calculatedMealData={calculatedMealData} setEdmamDeletedTags={setEdmamDeletedTags}/>
          </AccordionWrapper>
          <AccordionWrapper title="Diets" id={6}>
          <Diets  mealDetails={mealDetails} setMealDetails={setMealDetails} calculatedMealData={calculatedMealData} />
          </AccordionWrapper>
        </div>
        </div>
      </AddItemStyle>
      <ConfirmationStyle>
      {isAlertOpen && (
          <div className="modal-position">
            <Modal
              size="large"
              type="error"
              description={modalDes}
              onOk={handleSaveMeal}
              isOpen={isAlertOpen}
              onClose={handleModalClose}
              onCancel={handleModalClose}
              title={'Are you sure?'}
              primaryText={`Save`}
              secondaryText={'Cancel'}
              isBackdropEnabled={true}
              buttonType={'dark'}
            />
          </div>
        )}
        </ConfirmationStyle>
      {isRemoveImage && <DeleteModalStyle><Modal type='' title='Are you sure?' description={'Are you sure you want to delete this image? This will delete this image permanently, you can’t undo this action.'} secondaryText={'Cancel'} onClose={handleDeleteCancel} onCancel={() => {handleDeleteCancel(),setDeleteIndex(-1)}} onOk={handleDelete} primaryText={'Ok'} buttonType={'dark'}/></DeleteModalStyle>}
    </>
  );
};
