import React, { useEffect } from 'react'
import styled from 'styled-components'
import ChatScreen from 'components/chat/ChatScreen'

const ChatScreenStyle = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 111;
  &.slideInBottom{
    animation: slideInBottom 500ms ease;
  }
  @keyframes slideInBottom{
    0% {
      transform: translateY(100%);
      opacity: 0.5;
    }
    100%{
      transform: translateY(0);
      opacity: 1;
    }
  }
  &.slideOutBottom{
    animation-duration: 300ms;
  }
`

export const ChatWithFollower = (props) => {
    const { followerProfile, setShowChatAnim, setShowChatScreen, showChatAnim, clientId, setBgAnim, bgAnim } = props;

    useEffect(() => {
        return () => {
          setBgAnim({
            ...bgAnim,
            open: false,
            close: false
          })
        }
    }, []);

    return (
        <ChatScreenStyle className={showChatAnim ? 'slideInBottom' : 'slideOutBottom'}>
            <ChatScreen bgAnim={bgAnim} setBgAnim={setBgAnim} followerProfile={followerProfile} setShowChatAnim={setShowChatAnim} setShowChatScreen={setShowChatScreen} />
        </ChatScreenStyle>
    )
}
