import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const ItemsTableWrapper = styled.div`
  overflow: hidden;
  height: calc(100vh - 161px);
  background: #ffff;
  margin-right: 25px;
  border: 1px solid ${() => theme.colors.grayLight};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  input {
    height: 40px;
  }
  .table-title {
    height: 65px;
    padding: 0 23px 0 39px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .items-length {
      color: ${() => theme.colors.blackDefault};
      font-family: ${() => theme.typography.fontInter};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    .table-search-newItem {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .delete-inactive-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
      .divider {
        height: 17px;
        margin-left: 24px;
        border-right: 1px solid ${() => theme.colors.gray};
      }
      .delete-inactive {
        padding: 4px 8px 4px 4px;
        color: ${() => theme.colors.grayDarker};
        border-radius: 6px;
        font-family: ${() => theme.typography.fontInter};
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        &:hover {
          background-color: ${() => theme.colors.lightGrey};
        }
      }
    }
  }

  .table-header {
    height: 48px;
    background-color: ${() => theme.colors.grayLightest};
    display: flex;
    align-items: center;
    .header-photo,
    .header-name,
    .header-price,
    .header-category,
    .header-status,
    .header-update {
      padding-left: 16px;
      color: ${() => theme.colors.grayDarker};
      font-family: ${() => theme.typography.fontInter};
      font-size: 14px;
      font-weight: 600;
    }
    .header-photo {
      padding-left: 41px;
      width: 16%;
      display: flex;
      align-items: center;
    }
    .header-checkbox {
      width: 20px;
      font-size: 16px;
      margin-right: 12px;
      .PrivateSwitchBase-input {
        height: 24px;
        top: 0;
        left: 0
      }
      div[type="checkbox"],
      .custom-checkbox-label {
        width: 18px;
        height: 18px;
      }
      #check {
        margin: 0;
        flex-shrink: 0;
      }
      input[type="checkbox"]:checked {
        background-color: ${() => theme.colors.primaryDefault}
      }
      &.MuiCheckbox-root.MuiCheckbox-colorPrimary {
        padding: 0;
        color: ${() => theme.colors.primaryDefault};
        &:hover {
          background-color: transparent;
        }
      }
      &.MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: #B5B9C8;
      }
    }
    .header-name {
      width: 34%;
    }
    .header-price {
      width: 12%;
    }
    .header-category {
      width: 12%;
      display:flex;
      position: relative;
    }
    .header-status {
      width: 12%;
    }
    .header-update {
      width: 14%;
    }
  }

  .header-checkbox input {
    cursor: pointer;
  }
`;

export const DeleteModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 4;
  background-color: rgb(40 50 65 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  .motion_modal{
    width: 500px;
  }
`