import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';

const SwitchOnOffWrapper = styled.div`
  .MuiSwitch-root {
    padding: 8px;
  }

  .MuiSwitch-switchBase {
    top: 1px;
    left: 2px;

    &.MuiSwitch-switchBase.Mui-checked {
      transform: translateX(19px) !important;
    }
  }

  .MuiSwitch-thumb {
    width: 17px;
    height: 16px;
    color: #fff;
  }

  .MuiSwitch-track {
    width: 43px;
    height: 20px;
    background-color: #e0e3e0;
    border-radius: 21px;
    opacity: 1;
  }
`;

export default function SwitchOnOff({ checked, onChange }) {
  return (
    <SwitchOnOffWrapper>
      <Switch color="primary" checked={checked} onChange={() => onChange(!checked)} />
    </SwitchOnOffWrapper>
  );
}
