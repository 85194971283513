import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  position: relative;
  height: 210px;
  overflow: clip;

  .header-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-icons {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    .favorite-share {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .icon-wrapper {
    width: 36px;
    height: 36px;
    background: ${() => theme.colors.secondary};
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active, &:active::after{
      background: #F0F2F4;
  }
    .favorite-img,
    .share-img {
      width: 24px;
      height: 24px;
    }
  }

  .provider-img {
    position: absolute;
    bottom: 12px;
    right: 15px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 1.5px solid ${() => theme.colors.secondary};
  }
`;
