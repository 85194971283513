import React from 'react'

const Halal = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_3899_36167)">
                <path d="M9.99998 20C9.84455 20 9.69553 19.9382 9.58569 19.8284L6.99998 17.2427H3.34322C3.01963 17.2427 2.75729 16.9804 2.75729 16.6568V13L0.171621 10.4143C-0.057207 10.1855 -0.057207 9.81453 0.171621 9.5857L2.75729 7V3.34328C2.75729 3.01969 3.01963 2.75734 3.34322 2.75734H6.99998L9.58565 0.171641C9.69553 0.0617578 9.84459 0 9.99998 0C10.1554 0 10.3044 0.0617578 10.4143 0.171641L13 2.75734H16.6567C16.9803 2.75734 17.2427 3.01969 17.2427 3.34328V7L19.8284 9.5857C20.0572 9.81453 20.0572 10.1855 19.8284 10.4143L17.2427 13V16.6568C17.2427 16.9804 16.9803 17.2427 16.6567 17.2427H13L10.4143 19.8284C10.3044 19.9383 10.1554 20 9.99998 20ZM3.92916 16.0708H7.24268C7.39811 16.0708 7.54713 16.1326 7.65698 16.2425L9.99995 18.5854L12.3429 16.2425C12.4528 16.1326 12.6018 16.0708 12.7572 16.0708H16.0707V12.7573C16.0707 12.602 16.1324 12.4529 16.2424 12.343L18.5854 10L16.2424 7.65699C16.1326 7.54711 16.0708 7.39809 16.0708 7.2427V3.92922H12.7573C12.6019 3.92922 12.4528 3.86746 12.343 3.75758L9.99998 1.41461L7.65702 3.75758C7.54713 3.86746 7.39811 3.92922 7.24272 3.92922H3.9292V7.2427C3.9292 7.39809 3.86748 7.54711 3.75756 7.65699L1.41455 10L3.75752 12.343C3.8674 12.4529 3.92916 12.6019 3.92916 12.7573V16.0708Z" fill={color || "#757E89"} />
                <path d="M6.3706 12.823C5.93228 12.823 5.42181 12.6614 5.0365 12.2974C4.67283 11.9539 4.15271 11.1685 4.75467 9.76855C4.88252 9.47129 5.22713 9.33391 5.52443 9.46176C5.8217 9.58957 5.95908 9.93422 5.83123 10.2315C5.67092 10.6043 5.53674 11.1117 5.80806 11.4117C6.00506 11.6295 6.34478 11.6753 6.44853 11.6471C7.38388 11.1635 7.01064 8.63531 6.83095 7.4184C6.78783 7.12648 6.7506 6.87437 6.72967 6.66922C6.69681 6.34727 6.93119 6.05969 7.2531 6.02684C7.57564 5.99414 7.86263 6.22832 7.89549 6.55027C7.91373 6.72926 7.94916 6.9693 7.99021 7.24723C8.24431 8.96816 8.66963 11.8485 6.96302 12.7002C6.80267 12.7802 6.59619 12.823 6.3706 12.823Z" fill={color || "#757E89"} />
                <path d="M9.00865 13.1951C8.77158 13.1951 8.54841 13.0502 8.46009 12.8151C8.34627 12.5122 8.49955 12.1744 8.80248 12.0605C9.90314 11.647 10.6219 10.2408 11.0001 7.76164C11.0426 7.48285 11.2776 7.27391 11.5594 7.26437C11.8415 7.25402 12.0899 7.44738 12.1512 7.72266C12.213 8.00016 12.2406 8.34934 12.2726 8.75363C12.324 9.40242 12.388 10.2098 12.6213 10.8009C12.8985 11.503 13.2962 11.5991 13.6495 11.5991H13.653C13.741 11.5989 13.9011 11.5485 14.0846 11.4728C13.8204 11.2333 13.588 11.053 13.484 11.0159C13.1781 10.9104 13.0156 10.5769 13.1211 10.271C13.2266 9.96504 13.5602 9.80258 13.8661 9.90801C14.4254 10.1009 15.2622 10.9683 15.5054 11.2296C15.6259 11.3591 15.6815 11.5361 15.6566 11.7112C15.6317 11.8863 15.529 12.0409 15.3772 12.1316C15.1268 12.2814 14.2627 12.7699 13.6552 12.771H13.6503C12.9597 12.771 12.0333 12.5032 11.5313 11.2312C11.4806 11.1026 11.4364 10.9697 11.3977 10.8341C11.3899 10.8522 11.382 10.8702 11.374 10.8881C10.8591 12.0491 10.1326 12.8127 9.2147 13.1576C9.14678 13.183 9.07713 13.1951 9.00865 13.1951Z" fill={color || "#757E89"} />
                <path d="M9.69033 9.76161C9.39049 9.76161 9.1349 9.53266 9.10744 9.22824C9.07838 8.90582 8.92869 8.5684 8.72603 8.36863C8.4956 8.14145 8.49295 7.77047 8.72017 7.54004C8.94732 7.30961 9.31834 7.30695 9.54881 7.53418C9.94916 7.92891 10.2205 8.52289 10.2746 9.12301C10.3037 9.44532 10.0659 9.73012 9.74362 9.75922C9.7258 9.76082 9.70799 9.76161 9.69033 9.76161Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_3899_36167">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Halal