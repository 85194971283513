import styled from "styled-components";
import Slider from "react-slick";
import SliderCard from "./components/SliderCard";
import { useHistory } from "react-router-dom";
import PageTransition from "./components/PageTransition";
import { useAppContext } from "context/appContext";
import { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Button } from 'components/elements/button/Button';

const SliderStyle = styled.div`
    display: flex;
    flex-direction: column;
    overflow: overlay;
    height: 100%;
    background-color: #fff;
    .swiper-slide{
        background: #F6F5F8;
        overflow: visible;
    }
    .swiper-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        .swiper-pagination-bullet{
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #E0E3E0;
            opacity: 1;
            margin: 0 4px;
        }
        .swiper-pagination-bullet-active{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #949D9B;
        }
    }
    .login_btns{
        padding: 33px 16px 32px 16px;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 13px;
        background-color:white;
        button{
            width:165px;
        }
    }
    .key-0{
        overflow: visible;
        .image{
            overflow: visible;
            img{
                transform: scaleX(1.25);
                object-fit: unset;
            }
        }
    }
`

const sliderData = [
    {
        id: 1,
        title: "Optimize Your Nutrition, \n Your Way",
        description: "Discover nutritious meal options tailored to \n your dietary needs and preferences from \n local providers.",
        imgUrl: '/img/optimize-nutrition2.png'
    },
    {
        id: 2,
        title: "Empower Your \n Health Journey",
        description: "Unlock the power of nutrition with our \n platform's evidence-based approach to \n health and wellness.",
        imgUrl: '/img/empower-journey.png'
    },
    {
        id: 3,
        title: "Access Exclusive Local \n Meal Providers",
        description: "Enjoy a curated selection of top-quality, \n healthy meals from local providers, \n handpicked for their excellence and \n commitment to well-being",
        imgUrl: '/img/exclusive-meal-provider.png'
    },
    {
        id: 4,
        title: "Expert Guidance \n and Meal Plans",
        description: "Work with our trusted diet coaches and \n nutritionists to receive expert guidance \n and meal planning, tailored to your \n unique needs and preferences.",
        imgUrl: '/img/expert-guidance.png'
    }
]

export default function SlidingBanner() {
    const history = useHistory();
    const { isWebView, profile } = useAppContext();
    const handleLoginClick = () => {
        if (isWebView) {
            isWebView.postMessage(JSON.stringify({ event: "onboarding_login", screen: "login" }))
            return;
        }
        history.push('/login')
    }
    return (
        <PageTransition>
            <SliderStyle>
            <div className="swiper__slider">

      <Swiper
        slidesPerView={1}
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            translate: ["-70%", 0, -1],
            opacity: 0,
          },
          next: {
            translate: ["100%", 0, 0],
            opacity: 0,
          },
        }}
        className="mySwiper"
        loop={true}
         >
        {sliderData?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <SliderCard {...item} keyy={index} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>

                <div className="login_btns">
                    <Button type={'mobile_secondary'} title="Login" onClick={handleLoginClick} />
                    <Button type={'mobile_save'} title="Get Started"  onClick={() => { history.push('/self-onboarding/signin') }}/>
                </div>
            </SliderStyle>
        </PageTransition>
    )
}