import React, { useEffect, useState, useRef } from 'react';
import RegularInput from 'components/Input/RegularInput';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as Cross } from 'assets/cross.svg';
import { ReactComponent as Check } from 'assets/check.svg';
import { ReactComponent as DragIcon } from 'assets/drag-handle.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right2.svg';
import { ReactComponent as More } from 'assets/more-vertical.svg';
import { BiPlus } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import ActionButton from 'components/elements/ActionButton';
import classNames from 'classnames';
import { Modal } from 'components/elements/modal/Modal';
import { motion, AnimatePresence } from 'framer-motion';
import { useRequest } from 'hooks/useRequest';
import { useKitchenContext } from 'context/kitchenContext';
import Meal from 'components/elements/meal/Meal';
import Alert from 'components/elements/alert/Alert';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend'

export const CategoryCardStyle = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
  background: ${({ theme }) => theme?.colors?.white};
  padding:24px 36px 24px 25px;
  margin-bottom: 20px;
  width: 100%;
  .alert {
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top: 12px;
  }
  .category_title_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .drag_input_holder {
    max-width: 434px;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: center;
    svg {
      display: block;
      cursor: grab;
    }
    .category_title {
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      font-weight: 600;
      line-height: 20px;
      color: ${() => theme.colors.blackDefault};
    }
  }
  .category_card_input {
    max-width: 402px;
    width: 100%;
    flex: 1;
    input {
      height: 40px;
    }
  }
  .button_arrow_holder {
    display: flex;
    align-items: center;
    gap: ${({ isEdit }) => (isEdit ? '16px' : '8px')};
    .more {
      cursor: pointer;
    }
  }
  .action_buttons {
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLighter};
    background: ${({ theme }) => theme?.colors?.blackLightest};
    display: flex;
    align-items: center;
    .button {
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 4px;
      background: rgba(0, 0, 0, 0);
      cursor: pointer;
      .icon {
        svg {
          display: block;
        }
      }
      &:first-child {
        padding-right: 7.5px;
        padding-left: 9px;
      }
      &:last-child {
        padding-left: 7.5px;
        padding-right: 12px;
      }
    }
    .hr_rule {
      width: 1px;
      height: 24px;
      background: ${({ theme }) => theme?.colors?.gray};
    }
  }
  .accordion_handler {
    cursor: pointer;
    svg {
      display: block;
      transition: all 0.3s;
    }
  }
  .dropdown-action {
    .btn {
      height: auto;
      padding: 0;
    }
    .menu {
      right: 0;
      width: 200px;
      margin-top: 10px;
      padding: 4px 0;
      .menu-item span {
        color: ${() => theme.colors.blackDefault};
      }
      .menu-item:nth-child(even) {
        background-color: ${() => theme.colors.background};
      }
    }
    .openDropdown {
      background-color: ${() => theme.colors.lightGrey};
    }
  }
`;

const DeleteModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: rgb(40 50 65 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    width: 500px;
    max-width: 500px;
    padding: 24px 14px 27px 24px;
    background-color: ${() => theme.colors.secondary};
    border: 1px solid ${() => theme.colors.lightGrey};
    border-radius: 8px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    row-gap: 0;
  }
  .modal_header {
    .icon {
      display: none;
    }
    .title {
      color: ${() => theme.colors.blackDefault};
      font-size: 20px;
    }
    .cross {
      top: 28px;
      right: 24px;
    }
  }
  .modal_body {
    .desc {
      color: ${() => theme.colors.blackDefault};
      margin: 20px 0px 51px 0px;
    }
  }
  .btn {
    height: 40px;
    border-radius: 6px;
  }
  ${props => props.isDeleteError && `
    .modal_actions > :first-child {
      display: none;
    }
  `}
`;

const MenuItemsStyle = styled.div`
  padding-left: 20px;
  .add_items{
    padding-top: 12px;
    padding-left: 23px;
    .btn{
      color: ${({theme}) => theme?.colors?.primaryDark};
    }
    .plus_icon{
      font-size: 16px;
    }
  }
`

export const CategoryCard = ({categoryList, setCategoryList, editing = false, category, mealsData, setMealsData, index, mealCardRef, profileId}) => {
  const { categoryId: id, categoryName, meals=[] } = category;
  const { kitchenProvider, setSelectedMealId, mealCategoriesList,imitateProviderCredentials,isSuper } = useKitchenContext();
  const [categoryNamee, setCategoryNamee] = useState(categoryName);
  const [isEdit, setIsEdit] = useState(editing);
  const [isShowMeal, setIsShowMeal] = useState(false);
  const history = useHistory();
  const [isOpenAction, setIsOpenAction] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDeleteError, setIsDeleteError] = useState(false);
  const [createCategory] = useRequest(`meal/category`, 'post');
  const businessProfileId = kitchenProvider.BusinessProfile[0].id;
  const [updateMeal] = useRequest(`meal/update?providerId=${kitchenProvider?.id}&businessProfileId=${businessProfileId}`, 'patch');
  const [updateCategory] = useRequest();
  const [deleteCategory] = useRequest();
  const isCategoryAlreadyExist = containsObjectWithId(categoryList, id);
  const [alertData, setAlertData] = useState({ type: '', body: '' })
  const ref = useRef(null);
  const [changeCategoryIndex] = useRequest();
  const [mealsIndexList, setMealsIndexList] = useState(meals);

  useEffect(() => {
    setMealsIndexList(category?.meals);
  }, [categoryList])

  const updateCategoryIndex = async (categoryId, updatedCategoryIndex) => {
    await changeCategoryIndex({path: profileId ? `meal/category/${categoryId}?businessProfileId=${profileId}` : `meal/category/${categoryId}`, method: 'PUT', body: updatedCategoryIndex})
    .then((res) => {
      console.log('res', res)
    })
    .catch((err) => {
      console.log('err', err);
      showErrorMessage(err.message)
    })
  }

  function containsObjectWithId(arr, targetId) {
    return arr?.some(obj => Number(obj.categoryId) === targetId);
  }

  const handleSave = async () => {
    setIsEdit(false);
    if(isCategoryAlreadyExist){
      await updateCategory({path: `meal/category/${id}`, method: 'put', body: { 
        name: categoryNamee,
        categoryId: id, 
        businessProfileId: kitchenProvider?.BusinessProfile[0]?.id 
      }})
      .then((data) => {
        if(data?.data?.success){
          setCategoryList(prev =>
            prev?.map(category => {
              if (category.categoryId === id) {
                const { editing, ...rest } = category;
                const updatedCategory = { ...rest, categoryName: categoryNamee };
                const updatedMeals = Array.isArray(category?.meals)
                  ? category.meals.map((meal) => ({
                      ...meal,
                      category: categoryNamee,
                    }))
                  : [];
                return { ...updatedCategory, meals: updatedMeals };
              }
              return category;
            })
          );
        }
      })
      .catch((error) => {   
        showErrorMessage(error.message) 
        console.log(error);
      })
    }else{
      setCategoryList(prev =>
        prev?.map(category => {
          if (category.categoryId === id) {
            const { editing, ...rest } = category;
            return { ...rest, categoryName: categoryNamee };
          }
            return category;
        })
      );
      await createCategory({ body: { categoryName: categoryNamee, providerId: kitchenProvider?.id, businessProfileId: kitchenProvider?.BusinessProfile[0]?.id } })
      .then((data) => {
        if(data?.data?.success){
          setCategoryList(prev =>
            prev?.map(category => {
              if (category.categoryId === id) {
                const { editing, ...rest } = category;
                return { ...rest, categoryName: categoryNamee, categoryId: data?.data?.createdCategory?.id };
              }
              return category;
            })
          );
        }
      })
      .catch((err) => {
        setCategoryList((prev) => prev?.filter((item) => item.categoryId !== id));
        console.log('err', err);
      })
    }
  }

  const handleCancel = () => {
    console.log('isCategoryAlreadyExist', isCategoryAlreadyExist);
    if(isCategoryAlreadyExist){
      setIsEdit(false);
    }else{
      setCategoryList((prev) => prev?.filter((item) => item.categoryId !== id));
    }
  }

  const handleDelete = async () => {
    let path = `meal/category/${id}`
    if (isSuper) {
      path += `?businessProfileId=${imitateProviderCredentials?.businessProfileId}`
    }
    await deleteCategory({path , method: 'delete'})
    .then((result) => {
      if(result?.data?.success){
        setCategoryList((prev) => prev?.filter((item) => item.categoryId !== id));
      }
    })
    .catch((err) => {
      console.log('err', err);
      setIsDeleteError(true);
      showErrorMessage(err.message)
    })
    setIsDeleteModal(false);
  }

  const handleAddItems = () => {
    history.push(`/kitchen/menu/addItem?categoryId=${id}`);
  }

  const DropdownVariants = {
    hidden: {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },
    visible: {
      opacity: 1,
      height: 'auto',
      overflow: 'visible',
    },
  };

  const onEdit = (data) => {
    setSelectedMealId(data);
    history.push(`/kitchen/menu/addItem?categoryId=${id}&mealId=${data}`);
  }

  const handleRemove = async (mealId) => {
    try {
      await updateMeal({ body: { id: mealId, mealCategoryId: null } })
      const updatedCategoryMealList = category?.meals?.filter(meal => meal.id !== mealId);
      const updatedCategory = { ...category, meals: updatedCategoryMealList }
      const updatedList = categoryList.map(category => {
        if (category?.categoryId === id) {
          return updatedCategory;
        }
        return category;
      });
      setCategoryList(updatedList);
      showSuccessMessage(`You have successfully deleted Item`);
    } catch (error) {
      console.log(error)
      showErrorMessage(error.message)
    }
  }
  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const [{ isDragging, currentOffset }, drag, preview] = useDrag({
    type: 'CATEGORY',
    item: {...category, index},
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset()
    }),
    canDrag: !!categoryName,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if(dropResult){
        updateCategoryIndex(item?.categoryId, {newIndex: item?.index})
      }
    },
  });

  useEffect(() => {
    isDragging ? document.body.style.cursor = 'grabbing' :  document.body.style.cursor = 'unset'
  }, [isDragging])

  const [{ canDrop, isOverCurrent }, dropMeal] = useDrop({
    accept: 'ITEM',
    drop: () => ({ name: categoryName, categoryId: id }),
    canDrop: () => true,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const renderItemsForCategory = (categoryName) => {
    return mealsData
    ?.filter((meal) => meal.category === categoryName.trim())
    ?.sort((a, b) => a.mealOrderIndex - b.mealOrderIndex)
    ?.map((item, index) => {
        return (
            <Meal mealItem={item} setIsShowMeal={setIsShowMeal} setMealsData={setMealsData} key={index} id={item?.id} name={item?.name} price={item?.price} img={item?.images?.length && item?.images[0]} onEdit={() => onEdit(item?.id)} onDelete={() => handleRemove(item?.id)} index={index} categoryId={id} mealsData={mealsData} mealsIndexList={mealsIndexList} setMealsIndexList={setMealsIndexList} categoryList={categoryList} setCategoryList={setCategoryList} mealCardRef={mealCardRef}/>
        );
      });
  };
  const handleCategoryChange = (e) => {
    const {name, value} = e.target;
    if(value?.length > 50) return;
    const categoryName = value.replace(/ {2,}/g, ' ');
    setCategoryNamee(categoryName);
  }

  useEffect(() => {
    isOverCurrent && setIsShowMeal(true);
  }, [isOverCurrent])

  const [, dropCategory] = useDrop({
    accept: "CATEGORY",
    canDrop: () => true,
    hover(item, monitor) {
      if (!ref.current || !categoryName) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCardHandler(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = categoryList[dragIndex];
    if (dragItem) {
      setCategoryList((prevState) => {
        const coppiedStateArray = [...prevState];
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        coppiedStateArray.splice(dragIndex, 1, prevItem[0])
        coppiedStateArray.forEach((item, index) => {
          item.orderIndex = index;
        })
        return coppiedStateArray;
      });
    }
  };

  dropCategory(ref);

  return (
    <>
      <div style={{ width: '100%' }} ref={ref}>
      <CategoryCardStyle isEdit={isEdit} theme={theme}>
      {
          alertData.type && (
            <div className='alert'>
            <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', body: '' })} />
            </div>
          )
        }
        <div ref={dropMeal}>
        <div className="category_title_holder">
          <div className="drag_input_holder">
            <div className='drag_icon' ref={drag} style={{ boxShadow: 'none !important', cursor: isDragging ? 'grabbing' : 'grab' }}>
            <DragIcon />
            </div>
            {isEdit ? (
              <RegularInput
                value={categoryNamee}
                name="categoryName"
                className={'category_card_input'}
                placeholder={'Input category here'}
                onChange={(e) => handleCategoryChange(e)}
              />
            ) : (
              <h2 className="category_title">{categoryName}</h2>
            )}
          </div>
          <div className="button_arrow_holder">

            {isEdit ? (
              <div className="action_buttons">
                <Button className="button" onClick={handleCancel} iconLeft={<Cross />} title="Cancel" />
                <div className="hr_rule"></div>
                <Button isDisabled={!categoryNamee} className="button" onClick={handleSave} iconLeft={<Check />} title="Save" />
              </div>
            ) : (
              <div
              className="dropdown-icon"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenAction(!isOpenAction);
              }}
            >
              <ActionButton
                className="dropdown-action"
                btnText={<More  />}
                handleEdit={() => { setIsEdit(true); setCategoryNamee(categoryName) }}
                handleDelete={() => setIsDeleteModal(true)}
                setIsOpenAction={setIsOpenAction}
              />
            </div>
              )}
            <div className="accordion_handler">
              <ArrowRight
                onClick={() => setIsShowMeal(!isShowMeal)}
                style={{ transform: isShowMeal ? 'rotate(90deg)' : 'rotate(0)' }}
              />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {categoryName && isShowMeal &&
            <motion.div
              className="DropDownContainer"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={DropdownVariants}
              transition={{ duration: 0.5, type: 'spring', damping: 20 }}
            >
              <MenuItemsStyle theme={theme} className='menu_items_holder'>
                {renderItemsForCategory(categoryName)}
                <div className='add_items'>
                  <Button title='Add items' onClick={handleAddItems} size='small' type={"tertiary"} iconLeft={<BiPlus className='plus_icon' />} />
                </div>
              </MenuItemsStyle>
            </motion.div>
          }
        </AnimatePresence>
        </div>
      </CategoryCardStyle>
      </div>
      {isDeleteModal && (
        <DeleteModalWrapper>
          <Modal
            title="Are you sure?"
            description="Are you sure to delete the category? You can’t undo this action."
            secondaryText="Cancel"
            onCancel={() => setIsDeleteModal(false)}
            onClose={() => setIsDeleteModal(false)}
            primaryText="Delete"
            onOk={handleDelete}
            buttonType="danger"
          />
        </DeleteModalWrapper>
      )}
      {isDeleteError && (
         <DeleteModalWrapper isDeleteError={isDeleteError}>
         <Modal
         title="Unable to delete"
         description="You are unable to delete a category with items"
         onClose={() => setIsDeleteError(false)}
         primaryText=
         "Ok"
         isSecondaryButton={false}
         onOk={() => setIsDeleteError(false)}
         buttonType="danger"
       />
       </DeleteModalWrapper>
      )}
    </>
  );
};

