import React, { memo } from 'react';
import useCart from 'hooks/useCart';
import { useAppContext } from 'context/appContext';
import MealCard from 'components/elements/MealCard';
import { useShopContext } from 'context/shopContext';
import { KitchenMealCardWrapper } from './style';
import { useVisitorContext } from 'context/visitorContext';
import useVisitorCart from 'hooks/useVisitorCart';
import { useHistory } from 'react-router-dom';

function KitchenMealCard({ meal, setMealDetails, setIsAddWithoutQty, setOpen, isDeliveryAvailable }) {
  const { convertToHttps, isMobile, user } = useAppContext();
  const { images, calories, name, price, id, tags, orderLimit } = meal;
  const { cart } = user ? useShopContext() : useVisitorContext();
  const { addToCart, updateMealQty, removeFromCart } = user ? useCart() : useVisitorCart();
  const mealInCart = cart?.find((meal) => meal.id === id);
  const { setShowBummer } = user ? useShopContext() : useVisitorContext();
  const history = useHistory();
  const getMeal = () => {
    setOpen(true);
    setMealDetails(meal);
    updateUrl();
  };

  const updateUrl = () => {
    const currentUrl = history.location.pathname;
    const updatedUrl = `${currentUrl}?mealId=${id}`;
    history.push(updatedUrl);
  };

  return (
    <KitchenMealCardWrapper onClick={getMeal}>
      <MealCard
        id={id}
        mealImg={
          images?.length && images[0]?.startsWith('http')
            ? convertToHttps(images[0])
            : '/images/sexy_just_eat_now.jpg'
        }
        calories={calories}
        title={name}
        tags={tags}
        price={price.toFixed(2)}
        count={mealInCart?.qty}
        orderLimit={orderLimit}
        onClickRemove={() =>
          mealInCart.qty == 1 ? removeFromCart(meal.id) : updateMealQty(meal, +mealInCart.qty - 1)
        }
        onClickAdd={() => (isMobile && !isDeliveryAvailable && user) ? setShowBummer(true) : addToCart(meal)}
        setShowBummer={setShowBummer}
      />
    </KitchenMealCardWrapper>
  );
}

export default memo(KitchenMealCard);
