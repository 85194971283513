import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const HomeTabItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  .item-name {
    color: ${() => theme.colors.grayDarker};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .item-count {
    color: ${() => theme.colors.blackDefault};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  .MuiSkeleton-root {
    transform: scale(1);
  }
`;
