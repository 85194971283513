import React from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { ReactComponent as DefaultMeal } from 'assets/shop/defaultMeal.svg';
import { ReactComponent as MoreVertical } from 'assets/more-vertical.svg';
import { ReactComponent as AddIcon } from 'assets/shop/Tracker-AddIcon.svg';
import { useState } from 'react';
import ActionButton from '../ActionButton';
import classNames from 'classnames';


const UserMealCardStyle = styled.div`
  display: flex;
  background-color: #fff;
  gap: ${({ noPadding }) => (noPadding ? '8px' : '6px')};
  padding-left: ${({ noPadding }) => (noPadding ? '0' : '16px')};
  padding-right: ${({ noPadding }) => (noPadding ? '0' : '14px')};
  padding-top: ${({ noPadding }) => (noPadding ? '0' : '8px')};
  position: relative;
  .meal__card__img {
    width: 65px;
    height: 65px;
    flex: 0 0 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #f8f8f8;
    margin-bottom: ${({ noPadding }) => (noPadding ? '0' : '9px')};
    position: relative;
    .meal__img {
      width: 100%;
      display: block;
    }
    .quantity{
      position: absolute;
      font-size: 10px;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.primaryDefault};
      padding: 3px 5px;
      background-color: #fff;
      width: 20px;
      height: 18px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      left: 0;
      bottom: 0;
    }
  }
  .mealName {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
    padding-left: 10px;
    color: ${({ theme }) => theme?.colors?.primaryDarker};
    padding-right: ${({ noPadding }) => (noPadding ? '50px' : '22px')};
  }
  .diet__info__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .diet__info {
      padding: 3px 8px;
      width: 40px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 9px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.45px;
      position: relative;
      span {
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 0.22px;
      }
      &::after {
        content: '';
        width: 1px;
        height: 14px;
        background-color: ${({ theme }) => theme?.colors?.grayLight};
        position: absolute;
        right: -4px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        width: 48px;
      }
      &:last-child {
        width: 43px;
        &::after {
          display: none;
        }
      }
    }
  }
  .meal__card__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: calc(100% - ${({ imageDimension }) => imageDimension || 0 + 'px'});
    flex: 1;
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &.add {
      right: ${({ iconPositionEnd }) => (iconPositionEnd ? iconPositionEnd + 'px' : 0)};
      path {
        fill: ${({ theme }) => theme?.colors?.grayDarker};
      }
    }
    &.more {
      right: ${({ iconPositionEnd }) => (iconPositionEnd ? iconPositionEnd + 'px' : 0)};
    }
  }
  .dropdown-action {
    .btn {
      background-color: transparent;
      left: 23px;
    }
    .menu {
      right: 0;
      width: 200px;
      margin-top: 10px;
      padding: 4px 0;
      .menu-item{
        background-color: #fff;
      }
      .menu-item span {
        color: ${() => theme.colors.blackDefault};
      }
      .menu-item:hover {
        background-color: ${() => theme.colors.lightGrey};
      }
    }
    .openDropdown {
      background-color: ${() => theme.colors.lightGrey};
    }
  }
  .dropdown-icon{
    .btn{
      padding: 0px;
      left: auto;
    }
  }
`;
  
const UserMealCard = (props) => {
  const {
    image,
    imageDimension,
    mealName,
    cals,
    fat,
    protein,
    carbs,
    noPadding = false,
    options = false,
    onAddClick,
    iconPositionEnd,
    quantity = 0,
    onEdit,
    onDelete,
    providerId,
    handleMoveToPantry,
    parentRef
  } = props;
  const [open, setOpen] = useState(false);
  const [isOpenAction, setIsOpenAction] = useState(false);
  return (
    <>
      <UserMealCardStyle
        theme={theme}
        noPadding={noPadding}
        imageDimension={imageDimension}
        iconPositionEnd={iconPositionEnd}
      >
        <div
          className="meal__card__img"
          style={{ width: imageDimension + 'px', height: imageDimension + 'px' }}
        >
          {image && typeof image === 'string' ? (
            <img src={image} className="meal__img" />
          ) : image && typeof image !== 'string' ? (
            { image }
          ) : (
            <DefaultMeal className="meal_img" />
          )}
          {quantity > 1 && <div className='quantity'><span>{quantity}</span></div>}
        </div>
        <div className="meal__card__content">
          <h2 className="mealName">{mealName || 'Demo Meal'}</h2>

          <div className="diet__info__wrapper">
            <div className="diet__info">
              <span>{cals}</span>
              Cals
            </div>
            <div className="diet__info">
              <span>{fat ? fat + 'g' : '0g'}</span>
              Fat
            </div>
            <div className="diet__info">
              <span>{protein ? protein + 'g' : '0g'}</span>
              Protein
            </div>
            <div className="diet__info">
              <span>{carbs ? carbs + 'g' : '0g'}</span>
              Carbs
            </div>
          </div>
        </div>
        {options ? (
          <div
            className="dropdown-icon"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenAction(!isOpenAction);
            }}
          >
            {providerId ?
              <ActionButton
                className="dropdown-action"
                btnText={<MoreVertical className={classNames({ openDropdown: isOpenAction })} />}
                handleMoveToPantry={handleMoveToPantry}
                setIsOpenAction={setIsOpenAction}
                parentRef={parentRef}
               />  
                :
              <ActionButton
              className="dropdown-action"
              btnText={<MoreVertical className={classNames({ openDropdown: isOpenAction })} />}
              handleEdit={onEdit}
              handleDelete={onDelete}
              setIsOpenAction={setIsOpenAction}
              parentRef={parentRef}
              />
            }
          </div>
        ) : (
          <AddIcon style={{ height: 32, width: 32 }} onClick={onAddClick} className="icon add" />
        )}   
      </UserMealCardStyle>
    </>
  );
};

export default UserMealCard;
