import { useState, memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import DropDown from 'components/elements/drop-down/Dropdown';
import { MdEdit } from 'react-icons/md';
import { useKitchenContext } from 'context/kitchenContext';
import { useRequest } from 'hooks/useRequest';
import Alert from 'components/elements/alert/Alert';
import moment from 'moment-timezone';
import { useAppContext } from 'context/appContext';

const TimeZoneWrapper = styled.div`
  position: relative;
  width: 100%;
  .alert{
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: center;
    height: 76px;
    width: 100%;
    top:12px;
  }
  .title-wrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .title {
      color: ${() => theme?.colors?.blackDefault};
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    .custom_button {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      height: 32px;
      padding: ${({ isEdit }) => (!isEdit ? '0 8px 0 16px' : '0 16px')};
      background: ${() => theme?.colors?.white};
      color: ${() => theme?.colors?.primaryDark};
      font-family: ${() => theme?.colors?.fontInter};
      font-size: ${() => theme?.typography?.fontSmall};
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px solid ${() => theme?.colors?.primaryDark} !important;
      border-radius: 6px;
      cursor: pointer;
      .icon {
        svg {
          display: block;
        }
      }
    }
    .btn_flex {
      display: flex;
      gap: 12px;
      position: absolute;
      right: 0;
      top: 0;
      .custom_button {
        position: relative;
        padding: 0 16px;
        &.save {
          background: ${() => theme?.colors?.primaryDefault};
          color: ${() => theme?.colors?.white};
        }
      }
    }
  }

  .time-zone-view,
  .select-wrapper .selected-value {
    height: 40px;
    width: 537px;
    padding: 0 8px;
    background: ${() => theme?.colors?.lightGrey};
    color: ${() => theme?.colors?.blackDefault};
    border: 1px solid ${() => theme?.colors?.grayLight};
    border-radius: 6px;
    font-family: ${() => theme?.colors?.fontInter};
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .select-wrapper {
    height: 40px;
    width: 537px;
    margin: 0;
    cursor: pointer;
    .selected-value {
      height: 40px;
      width: 100%;
      background-color: ${() => theme?.colors?.white};
      &.selectedValue {
        border-color: #000A2C;
      }
    }
  }

  @media (max-width: 1125px) {
    .time-zone-view {
      width: 100%;
    }
  }
`;

function TimeZone() {
  const [isEdit, setIsEdit] = useState(false);
  const { kitchenProvider } = useKitchenContext();
  const { setProvider } = useAppContext();
  const [addressInfo] = kitchenProvider?.BusinessProfile;
  const [settingsInfo] = kitchenProvider?.BusinessProfileSettings;

  const formatTimezoneLabel = useCallback((timezone) => {
    const now = moment().tz(timezone);
    const offset = now.format('Z');
    const zoneName = timezone.replace('America/', '').replace('_', ' ');
    const zoneAbbr = now.format('zz');
    return `(GMT${offset})  ${zoneAbbr} - ${zoneName}`;
}, [settingsInfo]);

  const defaultTimezone = settingsInfo?.timeZone||Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimezone, setSelectedTimezone] = useState({value:defaultTimezone,label:formatTimezoneLabel(defaultTimezone)});
  const [updateBusinessProfile,{isLoading:isBusinessProfileSaving}] = useRequest(`provider/businessProfile`, 'put');
  const [isButtonEnabled, setIsButtonEnabled]=useState(true);
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
 
  const allTimezones = moment.tz.names();
  const americanTimezones = allTimezones.filter(timezone => timezone.startsWith('America/'));
  const americanOptions = americanTimezones.map(timezone => ({
    value: timezone,
    label: formatTimezoneLabel(timezone),
  }));

  const handleCancel = () => {
    setIsEdit(false);
    setSelectedTimezone({ value: defaultTimezone, label: formatTimezoneLabel(defaultTimezone) });
  };
  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const handleSave = async () => {
    if (isBusinessProfileSaving) return;
    try {
      const body = { id: addressInfo?.id, timeZone: selectedTimezone?.value };
      const result = await updateBusinessProfile({ body });
      if (result?.data?.success) {
        setProvider((prevProvider) => ({
          ...prevProvider,
          BusinessProfileSettings: [
            {
              ...prevProvider.BusinessProfileSettings[0],
              timeZone: selectedTimezone?.value,
            },
          ],
        }));
        showSuccessMessage('You have successfully updated Time zone');
        setIsEdit(false);
        setIsButtonEnabled(true)
      }
    } catch (error) {
      showErrorMessage('Error occurred while saving changes');
      setIsButtonEnabled(true)
      console.log(error);
    }
  }
  useEffect(()=>{
    settingsInfo?.timeZone === selectedTimezone ? setIsButtonEnabled(true) : setIsButtonEnabled(false);
  },[selectedTimezone]);

  return (
    <TimeZoneWrapper>
      {alertData.type && (
        <div className="alert">
          <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', title: '' })} />
        </div>
      )}
      <div className="title-wrapper">
        <h2 className="title">Time zone</h2>
        {isEdit ? (
          <div className="btn_flex">
            <Button title="Save"  size={"small"} type={"dark"} onClick={handleSave} isDisabled={isButtonEnabled}/>
            <Button title="Cancel" size={"small"} type={'secondary'} onClick={handleCancel}/>
          </div>
        ) : (
          <Button
            title="Edit"
            className="custom_button"
            onClick={() => setIsEdit(true)}
            iconRight={<MdEdit />}
          />
        )}
      </div>
      {isEdit ? (
        <DropDown
          updateValue={(value) => {
            setSelectedTimezone({value, label: formatTimezoneLabel(value)});
          }}
          value={selectedTimezone?.value}
          option={americanOptions}
        />
      ) : (
        <div className="time-zone-view">{formatTimezoneLabel(selectedTimezone.value)}</div>
      )}
    </TimeZoneWrapper>
  );
}

export default memo(TimeZone);
