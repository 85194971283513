import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Column from '../Column';
import MovableItem from '../MovableItem';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { useVisitorContext } from 'context/visitorContext';
import { Line } from 'rc-progress';
import { calcProgress, renderTotalCalories } from 'hooks/caloriesColorRules';

import './style.css';

export default function MealList({ setMealImg, mealsDataItem, mealPlanBodyRef, mealPlanColumnsRef }) {
  const scrollRef = useRef();
  const {user} = useAppContext();
  const context = user ? useShopContext() : useVisitorContext();
  const {
    settings: { mealPlan, days, meals },
  } = context || {};
 
    const useAppContextResult = useAppContext();
    const isMobile = useAppContextResult.isMobile || false;
    const profile = useAppContextResult.profile || {};
    const dietMetrics = profile.dietMetrics || {};
    const calories = dietMetrics.calories || {};
    const macros = dietMetrics.macros || {};
    const carbs = macros.carbs || 0;
    const fat = macros.fat || 0;
    const protein = macros.protein || 0;
    const isGuest = useAppContextResult.isGuest || false;
    const isVisitor = useAppContextResult.isVisitor || false;

  const [currentDays, setcurrentDays] = useState([]);
  const [currentDragDay, setCurrentDragDay] = useState();
  const [mealInfoMetrics, setMealInfoMetrics] = useState(calories);

  useEffect(() => {
    setcurrentDays([]);
    [...Array(days)].map((_, i) => setcurrentDays((prevState) => [...prevState, `Day ${i + 1}`]));
  }, []);

  useEffect(() => {
    switch (mealsDataItem) {
      case 'calories':
        setMealInfoMetrics(calories);
      break;
      case 'protein':
        setMealInfoMetrics(protein);
      break;
      case 'fat':
        setMealInfoMetrics(fat);
      break;
      case 'carbs':
        setMealInfoMetrics(carbs);
      break;
    }
  }, [mealsDataItem])

  useEffect(() => {
    scrollRef.current && scrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
  }, [mealPlan?.length])

  const renderItemsForColumns = (day) => {
    return mealPlan
      .filter((meal) => meal.day === day)
      .map((meal, index) => {
        return (
          <>
            <MovableItem {...meal} setMealImg={setMealImg} key={`${meal.id}-${index}`} day={day} />
            {mealPlan[mealPlan.length - 1]?.day === day && <span className='mel-ref' ref={scrollRef}/>}
          </>
        );
      });
  };

  const getAcceptedDays = (currentDay) => currentDays.filter((day) => day !== currentDay);

  return (
    <div
      className="meal-plan-body"
      onClick={(e) => e.stopPropagation()}
      onDragStart={(e) => isMobile && e.stopPropagation()}
      ref={mealPlanBodyRef}
    >
      <div className="days-container">
        {currentDays.map((day) => (
          <div className="meal-plan-item" id={`Day ${day.split(' ')[1]}`}>
            <div
              className={classNames('meal-item', {
                active: isMobile && currentDragDay === day,
              })}
            >
              <span>Day</span>
              <span>{day.split(' ')[1]}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="container meal-plan-columns" ref={mealPlanColumnsRef}>
        {/* {!isMobile  && (
          <div className="meal-plan-column meal-plan-column-first">
            {[...Array(meals)].map((_, i) => (
              <div className="movable-item">{`Meal ${i + 1}`}</div>
            ))}
          </div>
        )} */}
        <div className="meal-plan-column-wrapper">
          {currentDays.map((day) => (
            <>
              <div className="meal-plan-column">
                <div className="meal-placeholder-wrapper">
                  {[...Array(meals)].map((_, i) => (
                    <div key={i} className="meal-placeholder" />
                  ))}
                </div>
                <Column
                  title={day}
                  accept={getAcceptedDays(day)}
                  currentDays={currentDays}
                  setCurrentDragDay={setCurrentDragDay}
                >
                  {!!mealPlan.length && renderItemsForColumns(day)}
                </Column>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="meal-calories-wrapper">
        {currentDays.map((day) => (
          <div className="meal-calories">
            <div className={classNames('meal-calories-container')}>
              <span>{renderTotalCalories(mealsDataItem, mealPlan, day)}</span>
              {!(isGuest || isVisitor || !user) &&
                <Line
                  percent={calcProgress(mealsDataItem, mealPlan, mealInfoMetrics, day).precent}
                  strokeWidth="15"
                  strokeColor={calcProgress(mealsDataItem, mealPlan, mealInfoMetrics, day).color}
                  trailWidth="15"
                  height={isMobile ? "6px" : "8px"}
                  trailColor="#E0E3E0"
                />
              }
              <span>{mealsDataItem}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
