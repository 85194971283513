import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { equals, propEq } from 'ramda';
import { useAppContext } from 'context/appContext';
import classNames from 'classnames';

const StyledSelectContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 10px 23px 0;
  font-size: 14px;
  text-transform: capitalize;

  .isPlaceholder{
    display: none !important;
  }
  &:last-child {
    margin-right: 0;
  }

  .select-label {
    margin-bottom: 7px;
    color: #747c7a;
  }

  .selected-value,
  .options-dropdown {
    border: 1px solid #e0e3e0;
    border-radius: 6px;
    cursor: pointer;
  }

  .selected-value {
    height: 44px;
    padding: 0 14px;
    color:#949D9B;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.selectedValue {
      border-color: #52c41a;
    }

    svg {
      color: #939b99;
      font-size: 16px;
    }

    @media (max-width:768px){
      padding: 0 11px;
      gap:13px;
      color: #949D9B;
      font-weight: 400;
    }
  }

  .options-dropdown {
    position: absolute;
    top: auto;
    left: auto;
    z-index: 2;
    overflow: auto;
    width: 100%;
    max-height: 160px;
    margin-top: 6px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  .select-option {
    min-height: 38px;
    padding: 0 14px;
    color: #949d9b;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    word-break: break-all;

    &:hover {
      background-color: #f0faeb;
      color: #52c41a;
    }
  }
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;

    .select-label {
      color: #51635d;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
    }
  }

  @media (min-width: 992px) {
    margin-top:0 !important;
    .selected-value{
      font-family: 'Roboto';
      font-style: normal;
      color: #51635D;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
    .select-label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px !important;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #51635D;
    }
  }
`;

const findFromValue = (val, options) =>
  options.find(equals(val)) || options.find(propEq('value', val));
export default function Select({
  label,
  options = [1, 2, 3, 4],
  updateValue,
  defaultSelected,
  defaultChecked = defaultSelected,
  addIcon,
  icon,
  isDisabled = false,
  placeholder,
  isCheckout = false
}) {
  const [selectedValue, setSelectedValue] = useState(
    placeholder ? placeholder : findFromValue(defaultChecked, options) || options[0]?.value || options[0],
  );
  const { isMobile } = useAppContext();

  useEffect(() => {                                   
    if (defaultChecked) {
      const selectedOption =
        findFromValue(defaultChecked, options) || options[0]?.value || options[0];
      setSelectedValue(selectedOption);
    }
  }, [defaultChecked]);

  useEffect(() => {
    const opt = isCheckout ? findFromValue(defaultChecked, options) || options[0] || options[0]?.label : findFromValue(defaultChecked, options) || options[0]?.label || options[0];
    updateValue && updateValue(opt?.value || opt);
    equals(opt, selectedValue) || !placeholder && setSelectedValue(opt);
  }, [options.length]);

  const [displayOptions, toggleDisplayOptions] = useState(false);

  const handleToggleDisplayOptions = () => toggleDisplayOptions(!displayOptions);

  const handleChange = (option) => {
    setSelectedValue(option);
    handleToggleDisplayOptions();
    updateValue && updateValue(option.value || option);
  };

  return (
    <StyledSelectContainer className={classNames('select-wrapper', {disabledContainer: isDisabled}, {displayPlaceholder: placeholder === selectedValue})}>
      {label && <div className="select-label">{label}</div>}
      <div
        className={classNames('selected-value', { selectedValue: displayOptions }, {disabled: isDisabled})}
        onClick={() => handleToggleDisplayOptions()}
        style={{color: selectedValue?.labelColor ? selectedValue.labelColor:'', pointerEvents: isDisabled ? 'none' : ''}}
      >
        <span className={`${selectedValue?.isPlaceholder ? 'select-placeholder' : ''}`}>{selectedValue?.label || selectedValue}</span>
        {/* {isMobile ? (
          addIcon ? (
            <img src={`/icons/${icon}.svg`} alt="icon" />
          ) : ( 
            <img src="/icons/select-arrow.svg" alt="Select Arrow" />
          )
        ) : */}
          { icon ? <img src={`/icons/${icon}.svg`} alt="icon" /> : 
        displayOptions ? (
          <IoIosArrowUp />
        ) : (
          <IoIosArrowDown />
        )}
        {/* } */}
      </div>
      {displayOptions && (
        <>
          <div className="mask" onClick={() => toggleDisplayOptions(false)} />
          <div className="options-dropdown">
            {options.map((option) => (
              <div key={option.value} className={classNames('select-option', { isPlaceholder: option.isPlaceholder }, { valueSelected: selectedValue == option })}
                onClick={() => handleChange(option)} style={{ color: option?.labelColor ? option?.labelColor : '' }}>
                {option.label || option}
              </div>
            ))}
          </div>
        </>
      )}
    </StyledSelectContainer>
  );
}




