export default function SuccessComponent({ message, title, style = {} }) {
  return (
    <div
      style={{
        marginTop: '10px',
        padding: 10,
        background: '#d4f5d4',
        border: '1px solid #80bd80',
        borderRadius: '5px',
        ...style,
      }}
    >
      <b>{title || 'Success'}</b>
      <br />
      {message}
    </div>
  );
}

