
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import styled from 'styled-components';
import { VscInfo } from 'react-icons/vsc';

const TooltipStyle = styled.div`
    left: calc(100% + 100px);
    display: inline-block;
    path{
        fill: #757E89;
      }
    
`

const overlayInnerStyle = {
    backgroundColor: '#283241',
    border: '1px solid #E0E3E0',
    borderRadius: '4px',
    color: 'white',
    fontSize: '11px',
    textAlign: 'left',
    maxWidth: '170px',
    width: '100%',
    marginLeft:'140px'
}

export default function CustomToolTip({tooltipText, placement}){

    return (
        <TooltipStyle>
            <Tooltip overlayInnerStyle={overlayInnerStyle} placement={placement} overlay={<span>{tooltipText}</span>}>
                <VscInfo style={{fontSize: '8.5px', display: 'block'}} />
            </Tooltip>
        </TooltipStyle>
    )
}