import { useEffect, useRef, useState } from 'react';

import Switch from '@mui/material/Switch';

import RegularInput from 'components/Input/RegularInput';
import { LimitedPerOrderWrapper, SwitchWrapper, QuantityWrapper } from './style';

export default function LimitedPerOrder({
  orderLimit,
  setOrderLimit,
  isCheckedOrderLimit,
  setIsCheckedOrderLimit,
  isQuantityEmpty,
  setIsQuantityEmpty,
}) {
  const orderLimitRef = useRef();

  useEffect(() => {
    if (isCheckedOrderLimit) {
      orderLimitRef.current.focus();
    } else {
      setOrderLimit(null);
      setIsQuantityEmpty(false);
    }
  }, [isCheckedOrderLimit]);

  return (
    <LimitedPerOrderWrapper>
      <div className="limited-item-wrapper">
        <SwitchWrapper>
          <Switch
            onChange={() => setIsCheckedOrderLimit(!isCheckedOrderLimit)}
            checked={isCheckedOrderLimit}
          />
        </SwitchWrapper>
        <div className="order-title-wrapper">
          <div className="title">Limited item per order</div>
          <div className="content">
            Set up a limit on the number of items a customer can buy per order.
          </div>
        </div>
      </div>
      <QuantityWrapper>
        <RegularInput
          label={
            <div className="label-wrapper">
              Quantity <span>*</span>
            </div>
          }
          type="number"
          width="238px"
          value={isCheckedOrderLimit ? orderLimit : ''}
          onChange={(e) => {
            const { value } = e.target;
            if (value > 9999) return;
            setIsQuantityEmpty(false);
            isCheckedOrderLimit && setOrderLimit(value);
          }}
          onKeyDown={(e) => {
            if (['e', 'E', '+', '-', '.'].includes(e.key)) e.preventDefault();
          }}
          disabled={!isCheckedOrderLimit}
          placeholder="0"
          ref={orderLimitRef}
          message={isQuantityEmpty && 'Quantity of the product is required'}
        />
      </QuantityWrapper>
    </LimitedPerOrderWrapper>
  );
}
