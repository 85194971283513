import TimePicker from './TimePicker';
import styled from 'styled-components';

const SelectTimeFromToWrapper = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 24px;
  }

  .select-time {
    display: flex;
    align-items: center;
  }

  .to {
    margin: 0 14px;
    color: #51635d;
    font-size: 14px;
    line-height: 16px;
  }

  .trash-icon {
    position: absolute;
    top: auto;
    right: 24px;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    margin-left: 2px;

    .trash-icon {
      right: 15px;
    }
  }

  @media (max-width: 400px) {
    .to {
      margin: 0 10px;
    }

    .trash-icon {
      right: 11px;
    }
  }

  @media (max-width: 370px) {
    .select-time {
      flex-wrap: wrap;

      input {
        margin-bottom: 5px;
      }
    }
  }
`;

export default function SelectTimeFromTo({
  from,
  to,
  onChangeTimeFrom,
  onChangeTimeTo,
  removeSlots,
}) {
  return (
    <SelectTimeFromToWrapper>
      <div className="select-time">
        <TimePicker defaultValue={from} onChange={onChangeTimeFrom} from={from} to={to} isStart={true} />
        <span className="to">to</span>
        <TimePicker defaultValue={to} onChange={onChangeTimeTo} from={from} to={to} />
      </div>
      <img
        src="/icons/gray-trash.svg"
        alt="Trash Icon"
        className="trash-icon"
        onClick={removeSlots}
      />
    </SelectTimeFromToWrapper>
  );
}
