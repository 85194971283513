import Slider from 'react-rangeslider';
import styled from 'styled-components';
import 'react-rangeslider/lib/index.css';
import { mapAccum } from 'ramda';

const MacronutrientBreakdownStyle = styled.div`
  .micronutrit-title {
    margin-bottom: 25px;
    color: #000;
    font-size: 20px;
  }

  .micronutrit-sections-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .micronutrit-sections {
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 33px;
      color: #747c7a;
      font-size: 18px;
    }

    .content {
      height: 40px;
      margin-bottom: 43px;
      color: #0b1c18;
      font-size: 18px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    input {
      width: 50px;
      height: 40px;
      margin-right: 12px;
      border: 1px solid #c3cbc9;
      font-size: 16px;
      border-radius: 5px;
      text-align: center;
    }

    .progress-wrapper {
      width: 100%;

      .rangeslider-horizontal {
        width: 220px;
        height: 10px;
        margin: 5px 0;
        background: #8fe9a46e;
        box-shadow: none;
      }

      .rangeslider__fill {
        background-color: #90e8a4;
        box-shadow: none;
      }

      .rangeslider__handle {
        width: 22px;
        height: 22px;
        background: #90e9a5;
        border: 0;
        box-shadow: -3px 3px 6px #855c8b2c;

        &:after {
          display: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;

    .micronutrit-title {
      font-size: 18px;
    }

    .micronutrit-sections-wrapper {
      overflow-x: auto;

      .micronutrit-sections {
        margin-right: 40px;
        flex-shrink: 0;

        .title,
        .content {
          font-size: 16px;
        }

        .content:last-child {
          margin-bottom: 25px;
        }

        &:last-child {
          margin-right: 10px;
        }
      }
    }
  }
`;

export default function MacronutrientBreakdown({ form, setForm }) {
  const handleChangeStart = () => {
    console.log('Change event started');
  };

  const handleChangeComplete = () => {
    console.log('Change event completed');
  };
  const macros = ['fat_percent', 'carbs_percent', 'protein_percent'];
  //fat, crbs, protein
  const handleMacroChange = (name, value) => {
    const index = macros.indexOf(name);
    const diff = form[name] - value;
    const newVals = macros.reduce(
      ({ diff, changes, index }, _) => {
        if (diff == 0) return { diff, changes, index };
        const nextName = macros[(index + 1) % macros.length];
        const nextVal = form[nextName] + diff;
        if (nextVal <= 100 && nextVal >= 0)
          return { diff: 0, changes: { ...changes, [nextName]: nextVal } };
        if (nextVal > 100)
          return {
            diff: nextVal - 100,
            changes: { ...changes, [nextName]: 100, index: index + 1 },
          };
        if (nextVal < 0)
          return {
            diff: nextVal,
            changes: { ...changes, [nextName]: 0 },
            index: index + 1,
          };
      },
      { diff, changes: { [name]: value }, index },
    );

    setForm({
      ...form,
      ...newVals.changes,
    });
  };
  return (
    <MacronutrientBreakdownStyle>
      <div className="micronutrit-title">Macronutrient Breakdown</div>
      <div className="micronutrit-sections-wrapper">
        <div className="micronutrit-sections">
          <div className="title">Macros</div>
          <div className="content">Fat</div>
          <div className="content">Carbs</div>
          <div className="content">Protein</div>
        </div>
        <div className="micronutrit-sections">
          <div className="title">Suggested</div>
          <div className="content">20% - 35%</div>
          <div className="content">40% - 65%</div>
          <div className="content">10% - 35%</div>
        </div>
        <div className="micronutrit-sections">
          <div className="title"></div>
          <div className="content">
            <div className="progress-wrapper">
              <span>{form.fat_percent}%</span>
              <Slider
                min={0}
                max={100}
                tooltip={false}
                value={form.fat_percent}
                onChangeStart={handleChangeStart}
                onChange={(v) => handleMacroChange('fat_percent', v)}
                onChangeComplete={handleChangeComplete}
              />
            </div>
          </div>
          <div className="content">
            <div className="progress-wrapper">
              <span>{form.carbs_percent}%</span>
              <Slider
                min={0}
                max={100}
                tooltip={false}
                value={form.carbs_percent}
                onChangeStart={handleChangeStart}
                onChange={(v) => handleMacroChange('carbs_percent', v)}
                onChangeComplete={handleChangeComplete}
              />
            </div>
          </div>
          <div className="content">
            <div className="progress-wrapper">
              <span>{form.protein_percent}%</span>
              <Slider
                min={0}
                max={100}
                tooltip={false}
                value={form.protein_percent}
                onChangeStart={handleChangeStart}
                onChange={(v) => handleMacroChange('protein_percent', v)}
                onChangeComplete={handleChangeComplete}
              />
            </div>
          </div>
        </div>
        <div className="micronutrit-sections">
          {/* <div className="title">Updated %</div>
          <div className="content">
            <input
              type="number"
              autoComplete="off"
              value={form.fat_percent}
              onChange={(e) =>
                setForm({ ...form, fat_percent: e.target.value })
              }
            />
            %
          </div>
          <div className="content">
            <input
              type="number"
              autoComplete="off"
              value={form.carbs_percent}
              onChange={(e) =>
                setForm({ ...form, carbs_percent: e.target.value })
              }
            />
            %
          </div>
          <div className="content">
            <input
              type="number"
              autoComplete="off"
              value={form.protein_percent}
              onChange={(e) =>
                setForm({ ...form, protein_percent: e.target.value })
              }
            />
            %
          </div> */}
        </div>
        <div className="micronutrit-sections">
          {/* <div className="title">Updated g/lb</div>
          <div className="content">
            <input type="number" autoComplete="off" value="3.45" />
            g/lb
          </div>
          <div className="content">
            <input type="number" autoComplete="off" value="4.15" />
            g/lb
          </div>
          <div className="content">
            <input type="number" autoComplete="off" value="6.05" />
            g/lb
          </div> */}
        </div>
      </div>
    </MacronutrientBreakdownStyle>
  );
}
