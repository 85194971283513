import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as BlackDollar } from 'assets/shop/black-dollar.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right.svg';
import { ReactComponent as HealthGoalsIcon } from 'assets/shop/health-goals.svg';
import { ReactComponent as DietPreferences } from 'assets/shop/diet-preferences.svg';
import { ReactComponent as PersonalCoach } from 'assets/shop/personal-coach.svg';
import { ReactComponent as Addresses } from 'assets/shop/addresses.svg';
import { ReactComponent as Notification } from 'assets/shop/notification.svg';
import { ReactComponent as TermsOfService } from 'assets/shop/terms-of-service.svg';
import { ReactComponent as PrivacyPolicy } from 'assets/shop/privacy-policy.svg';
import SettingsRowItem from 'components/elements/settingsRowItem/SettingsRowItem';
import { BottomSheet } from 'react-spring-bottom-sheet';
import AddressesList from 'pages/shop/Addresses/AddressesList';
import 'react-spring-bottom-sheet/dist/style.css';
import { GilroyFontWrapper } from 'shared/theme/style';
import { useAppContext } from 'context/appContext';
import MobileHeader from 'components/elements/MobileHeader';
import Avatar from 'components/elements/avatar/Avatar';
import moment from 'moment';
import { useShopContext } from 'context/shopContext';
import PromoCode from '../promocode/PromoCode';
import { AnimatePresence, motion } from 'framer-motion';

const AccountStyle = styled.div`
  overflow: auto;
  .account_header {
    background-color: #fff;
    padding: 0px 16px 18px;
    .goto_profile {
      padding: 12px;
      display: flex;
      gap: 14px;
      align-items: center;
      margin-bottom: 10px;
      .user_avatar {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #e8f9fb;
        font-size: 26px;
        font-weight: 500;
        text-align: center;
        line-height: 52px;
      }
      .user_detail {
        flex: 1;
        .name {
          color: #0b1d17;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
        .profile {
          color: #51635d;
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
        }
      }
      .chevron {
        width: 24px;
      }
    }
    .nutritt_cash_balance {
      border-radius: 7px;
      background: #f5f7f8;
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 14px 14px 14px 10px;
      height: 48px;
      .title {
        color: #0b1d17;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        flex: 1;
        margin-bottom: -2px;
      }
      .cash_balance {
        color: #0b1d17;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: -2px;
      }
      &.isCashAvailable {
        background: #F0FAEB;
      }
    }
  }
  .account_body {
    margin: 12px 0;
  }
  .need_help {
    background-color: #fff;
    padding: 16px;
    .ttitle {
      color: #0b1d17;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 12px;
    }
    .help_btns {
      display: flex;
      gap: 15px;
      .btn {
        width: 50%;
        padding: 14px 16px 12px;
        border-radius: 30px;
        background: #f0f2f4;
        font-size: 16px;
        font-weight: 700;
        color: #00092d;
        text-align: center;
        display: inline-block;
        font-family: 'Gilroy';
        &:last-child{
          padding-top: 16px;
        }
      }
    }
  }
  .account_urls{
    .title{
      margin-bottom: -2px;
    }
  }

  .alert {
    position: absolute;
    top: 16px;
    left: auto;
    width: 100%;
    .alert_bar {
      margin: auto;
    }
  }
`;

const MobileHeaderStyle = styled.div`
  background-color: #fff;
  .align_left{
    justify-content: flex-start;
  }
`

const AccountStyleWrapper = styled.div`
  height: calc(100vh - 70px);
  overflow: auto;
  &::-webkit-scrollbar{
    display: none;
  }
`

export const Account = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
const { profile, user, isWebView ,handleOpenInterCom, setHeaderAddress, selectedValue ,userDeliveryAddress} = useAppContext();
  const [isPromoCodeOpen, setIsPromoCodeOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const { cashBalance} = useShopContext();
  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, [open])
  const nativeEvent = (name, link) => {
    isWebView.postMessage(JSON.stringify({ event: name, url: link }))
  }
  const accountBalance = useMemo(() => {
    const giftCardBalance = cashBalance;
    if (giftCardBalance) {
      const formattedDate = moment(giftCardBalance?.expirationTime).format('ddd, MMM D');
      const formattedTime = moment(giftCardBalance?.expirationTime).format('hh:mm A');
      const formattedExpiration = `${formattedDate} | ${formattedTime}`;
      return {
        leftAmount: giftCardBalance.leftAmount,
        expirationTime: formattedExpiration
      };
    }
    return { leftAmount: 0, expirationTime: null };
  }, [user, cashBalance]);

  const displayname = useMemo(()=>{
    let name = profile?.name 
    if( profile?.lastName){
      name = profile?.name +' '+ profile?.lastName; 
    }
    return name?.length > 15 ? name?.slice(0, 15) + "..." : name 
  },[profile])

  useEffect(() => {
    if(userDeliveryAddress?.length){
      const defaultAddress = userDeliveryAddress?.find(address => address?.id === selectedValue);
      setHeaderAddress(defaultAddress || userDeliveryAddress[0]);
    } else {
      setHeaderAddress({});
    }
  }, [userDeliveryAddress, selectedValue])

  return (
    <AccountStyleWrapper>
    <GilroyFontWrapper>
      <MobileHeaderStyle>
        <MobileHeader className={'align_left'} text={'Account'} />
      </MobileHeaderStyle>
      <AccountStyle>
        <div className="account_header">
          <div
            className="goto_profile"
            onClick={() => {
              history.push('/shop/myProfile');
            }}
          >
            <Avatar
              name={profile?.name}
              color={'#71A2A8'}
              bgColor={'#E8F9FB'}
              height={'52px'}
              imgSrc={profile?.img}
              width={'52px'}
            />
            <div className="user_detail">
              <h2 className="name">{displayname}</h2>
              <Link className="profile">My profile</Link>
            </div>
            <div className="chevron">
              <ChevronRight />
            </div>
          </div>
          <div
            onClick={() => setIsPromoCodeOpen(true)}
            className={`nutritt_cash_balance ${accountBalance?.leftAmount > 0 ? 'isCashAvailable' : ''}`}
          >
            <BlackDollar className="dollar_icon" />
            <p className="title">Nutritt Cash Balance</p>
            <span className="cash_balance">
              {accountBalance?.leftAmount > 0 ? '$' + accountBalance?.leftAmount.toFixed(2) : '0.00'}
            </span>
            <ChevronRight />
          </div>
        </div>
        <div className="account_body">
          <SettingsRowItem
            icon={<DietPreferences className="icon" />}
            title={'Diet preferences'}
            onClick={() => {
              history.push('/shop/account/diet-preferences');
            }}
          />
          <SettingsRowItem
            icon={<HealthGoalsIcon className="icon" />}
            title={'Health goals'}
            onClick={() => {
              if (user?.type.includes('visitor')) {
                history.push('/shop/track-quiz');
              }
              else {
                history.push('/shop/account/health-goal');
              }
            }}
          />
          <SettingsRowItem icon={<PersonalCoach className="icon" />} title={'Personal coach'} onClick={() => {
            isWebView ? nativeEvent("Personal_Coach", "https://form.typeform.com/to/urcjya9G") : window.location.href = "https://form.typeform.com/to/urcjya9G"
          }} />
        </div>
        <div className="account_body">
          <SettingsRowItem
            icon={<Addresses className="icon" />}
            title={'Addresses'}
            onClick={() => setOpen(true)}
          />
          <BottomSheet
            open={open}
            onDismiss={() => setOpen(false)}
            scrollLocking={false}
            expandOnContentDrag={true}
            snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
            className="addresses-bottomSheet"
          >
            <AddressesList
              open={open}
              setOpen={setOpen}
              selectHeaderAddress={(address) => {
                setHeaderAddress(address)
               
              }}
            />
          </BottomSheet>
          <SettingsRowItem
            icon={<Notification className="icon" />}
            title={'Notifications'}
            onClick={() => {
              history.push('/shop/notifications');
            }}
          />
          <SettingsRowItem icon={<TermsOfService className="icon" />} title={'Terms of service'} onClick={() => {
            isWebView ? nativeEvent("terms_and_services", "/users/terms-&-conditions") : history.push('/terms');
          }} />
          <SettingsRowItem icon={<PrivacyPolicy className="icon" />} title={'Privacy policy'} onClick={() => {
            isWebView ? nativeEvent("privacy_policy", "/nutritt/privacy-policy") : history.push('/nutritt/privacy-policy');
          }} />
        </div>
        <div className="need_help">
          <h3 className="ttitle" onClick={()=>{console.log(process.env.REACT_APP_NUTRITT_NUMBER)}}>Need help?</h3>
          <div className="help_btns">
            <button className="btn" onClick={handleOpenInterCom}>Chat</button>
            <a href={`tel: ${process.env.REACT_APP_NUTRITT_NUMBER}`} className="btn">
              Call
            </a>
          </div>
        </div>
      </AccountStyle>
      <AnimatePresence>
        {isPromoCodeOpen && (
          <motion.div
            initial={{ x: "100vw", opacity: 0 }}
            exit={{ x: "100vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            style={{ position: 'fixed', inset: 0, zIndex: 113 }}
          >
            <PromoCode setIsPromoCodeOpen={setIsPromoCodeOpen} />
          </motion.div>
        )}
      </AnimatePresence>
    </GilroyFontWrapper>
    </AccountStyleWrapper>
  );
};
