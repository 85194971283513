import styled from "styled-components"
import Title from "./components/Title"
import { useState, useEffect } from "react"
import { Circle } from 'rc-progress';
import { useHistory } from "react-router-dom";
import PageTransition from "./components/PageTransition";
import { useRequest } from 'hooks/useRequest'
import { useOnboardingContext } from ".";
import { useAppContext } from "context/appContext";

const PersonalizedMacrosStyle = styled.div`
    padding: 48px 32px;
    text-align: center;
    height: 100%;
    .progress_wrapper{
        margin-top: 69px;
        position: relative;
        .pentry-circle-percent{
            position: absolute;
            font-weight: 700;
            font-size: 36px;
            line-height: 42px;
            color: #0B1D17;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .rc-progress-circle{
            width: 200px;
            display: block;
            margin: auto;
            path{
                stroke-width: 12;
            }
        }
    }
`

export default function PersonalizedMacros() {
    const [loading, setLoading] = useState(0);
    const history = useHistory();
    const [visitorOnboard, { isLoading }] = useRequest('profile/visitor-onboard/v2', 'get');
    const { setVisitorData } = useOnboardingContext();
    const { setProfile } = useAppContext();

    const visitorOnboardData = async () => {
        await visitorOnboard()
            .then((data) => {
                setVisitorData(data?.data);
                setProfile(data?.data?.profile);
            })
            .catch(console.log);
    };

    useEffect(() => {
        visitorOnboardData();
    }, [])

    useEffect(() => {
        let cnt = 0;
        const counter = setInterval(() => {
            cnt += 1;
            if (cnt <= 99 && loading <= 99) {
                setLoading((prevState) => prevState + 1);
            }
        }, 50);

        if (loading >= 100) {
            setTimeout(() => {
                localStorage.removeItem("last-login-url");
                history.push('/self-onboarding/recommended-calories');
                // window.location.reload();
            }, 500)
        }

        return () => {
            clearInterval(counter);
        };
    }, [loading]);

    return (
        <PageTransition>
            <PersonalizedMacrosStyle>
                <Title title="Calculating your personalized macros for optimal results..." />
                <div className="progress_wrapper">
                    <Circle
                        percent={loading}
                        strokeWidth="13"
                        strokeColor="#52C41A"
                        trailWidth="8"
                        trailColor="#52C41A4F"
                    />
                    <p className="pentry-circle-percent" data-testid="pentry-circle-percent">
                        {loading}%
                    </p>
                </div>
            </PersonalizedMacrosStyle>
        </PageTransition>
    )
}