import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';

function TableRowLoading({ columns, style }) {
    return (
        <div style={{ ...style, display: 'flex' }} className="table-row-loading">
            {columns.map((column) => (
                <TableCell
                    component="div"
                    key={column.id}
                    sx={{ width: column.width || `${100 / column.length}%` || '20%', border: 'none' }}
                >
                    <Skeleton variant="rectangular" key={column.id} />
                </TableCell>
            ))}
        </div>
    );
}

export default memo(TableRowLoading);