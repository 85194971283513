import { memo } from 'react';
import TableRowLoading from './TableRowLoading';

function TableLoading({ columns }) {
    return (
        <div style={{ height: '100%', overflow: 'auto' }}>
            {[...Array(50)].map((_, index) => (
                <TableRowLoading columns={columns} key={`i-${index}`} />
            ))}
        </div>
    );
}

export default memo(TableLoading);