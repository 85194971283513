import { useEffect } from 'react';
import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import { ImSpoonKnife } from 'react-icons/im';
import produce from 'immer';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import { useVisitorContext } from 'context/visitorContext';
import './style.css';
import ImageViewer from 'components/ImageViewer';

export default function MovableItem({ idx, id, day, img, setMealImg }) {
  const {user} = useAppContext();
  const {
    updateSettings,
    settings: { mealPlan },
  } = user ? useShopContext() : useVisitorContext();
  const changeItemColumn = (currentItem, dayName) => {
    let activeMealIndex;
    mealPlan.map((meal, index) => meal.idx === currentItem.idx && (activeMealIndex = index));
    let activeMeal = mealPlan[activeMealIndex];

    updateSettings(
      produce((draft) => {
        draft.mealPlan.splice(activeMealIndex, 1, {
          ...activeMeal,
          day: dayName,
        });
      }),
    );
  };

  const [{ isDragging }, drag] = useDrag({
    type: day,
    item: { id, idx },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      console.log({ dropResult });
      dropResult && changeItemColumn(item, dropResult.name);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrop: () => true,
  });

  useEffect(() => {
    setMealImg(img);
  }, [isDragging]);

  return (
    <div
      ref={drag}
      className={classNames('movable-item', {
        'dragging-item': isDragging
      })}
    >
      {img ? (
        <div
          className="drag-meal"
          style={{ backgroundImage: `url(${img})`, opacity: isDragging ? 0.2 : 1 }}
        />
      ) : (
        // <ImSpoonKnife />
        <div className="drag-meal sample-meal" style={{backgroundImage:  "url('/images/dish.png')"}} />
      )}
    </div>
  );
}
