import React from 'react'
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { roundToTwoDecimalPlaces } from 'utils';
import { useAppContext } from 'context/appContext';

const OrderSummaryStyle = styled.div`
.order-summary-card{
    margin-bottom: 37px !important;
    .card-header{
        padding: 0px 24px 10px 24px;
        h4{
            color: ${theme.colors.primaryDarker};
            font-family: ${theme.typography.fontGilroy};
            font-size: ${theme.typography.fontBase};
            font-style: normal;
            text-align: left;
            font-weight: 600;
            line-height: 24px;
        }
     }
    .summary-items{
        padding-left: 0px;
        margin: 0 24px 10px 24px;
        @media(max-width: 991px){
            margin: 0 24px 4px 24px;
        }
        :nth-child(2){
            border-bottom: 1px solid ${theme?.colors?.grayLight}; 
        }
        :nth-child(3){
            border-bottom: 1px solid ${theme?.colors?.aliceBlue};
        }
        :nth-child(4){
            margin-bottom: 0px;
            li{
                padding-bottom: 0px;
            }
        }
        .green{
            color: #52C41A;
            text-align: right;
            font-family: ${theme?.typography?.fontGilroy};
            font-size: ${theme?.typography?.fontRegular};
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        li{
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 8px;
            @media(max-width: 991px){
                padding-bottom: 4px;
            }
            :nth-child(4){
                padding-bottom: 6px;
            }
            p{
                color: ${theme?.colors?.grayDarker};
                font-size: ${theme?.typography?.fontRegular};
                font-family: ${theme?.typography?.fontGilroy};
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }
            span{
                color: ${theme?.colors?.grayDarker};
                text-align: right;
                font-family: ${theme?.typography?.fontGilroy};
                font-size: ${theme?.typography?.fontRegular};
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                 &.final-amount{
                    font-size: ${theme.typography.fontBase};
                    font-weight: 600;
                    line-height: normal;
                    b{
                        color: ${theme?.colors?.primaryDarker};
                        font-family: ${theme?.typography?.fontGilroy};
                        font-size: ${theme.typography.fontBase};
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
@media(max-width: 991px){
    .order-summary-card{
        margin-bottom: 0 !important;
        padding-bottom: 37px;
    }
    .card-header{
        padding: 16px 14.887px 15px 16px !important;
        h4{
            color: ${theme?.colors?.deepForestGreen} !important;
            font-size: 18px !important;
        }
    }
    .order-card{
        padding: 16px 14.887px 15px 16px;
    }
    .order-summary-card .summary-items{
        margin: 0 14.887px 16px 16px;
        :nth-child(2){
            border-bottom: none; 
        }
        :nth-child(3){
            border-bottom: 1px solid #51635D10;
        }
        :nth-child(4){
            margin-bottom: 24px;
            h4{
                color: ${theme?.colors?.deepForestGreen};
            }
            .final-amount, b{
                color: ${theme?.colors?.deepForestGreen} !Important; 
            }
        } 
        .green{
            font-weight: 500;
        }   
    li {
        padding-bottom: 12px;
        p {
            font-size: ${theme?.typography?.fontRegular} !important;
            font-weight: 500;
            color: ${theme?.colors?.primaryDarker};
            b{
                font-weight: 500;
                color: ${theme?.colors?.mediumGrey};
                line-height: 16.98px
            }
        }
        span{
            font-weight: 500;
            line-height: 16.98px;
            color: ${theme?.colors?.primaryDarker};
        }
    }
}
}
`

export const OrderSummary = ({ totalPrice, meals, tax, actualDiscount, totalDiscount, deliveryFee, serviceFee, grandTotal, repeatOrderDiscount, userTotalAmount }) => {
    const { isDesktop } = useAppContext();

    return (
        <OrderSummaryStyle theme={theme}>
                <div className='order-summary-card card-shadow'>
                    <div className='card-header'>
                        <h4>{isDesktop ? "Order summary" : "Summary"}</h4>
                    </div>
                    <ul className='summary-items' style={!isDesktop ? { marginBottom: "0px" } : null}>
                        <li>
                            <p><b>{isDesktop ? `${meals} Meals` : "Subtotal"}</b></p>
                            <span><b>${roundToTwoDecimalPlaces(totalPrice)}</b></span>
                        </li>
                    </ul>
                    <ul className='summary-items'>
                    {totalDiscount ? <li>
                        <p><b>Discount</b></p>
                        <span className='green'>- ${roundToTwoDecimalPlaces(actualDiscount)}</span>
                    </li> : ""}
                    {repeatOrderDiscount ? <li>
                        <p><b>Repeat order discount</b></p>
                        <span className='green'>- ${roundToTwoDecimalPlaces(repeatOrderDiscount)}</span>
                    </li> : ""}
                        {userTotalAmount ? 
                        <>
                        <li>
                        <p><b>Delivery</b></p>
                        <span>${roundToTwoDecimalPlaces(deliveryFee)}</span>
                        </li>
                        <li>
                        <p><b>Tax</b></p>
                        <span>${roundToTwoDecimalPlaces(tax)}</span>
                        </li>
                        <li>
                            <p><b>Service Fee</b></p>
                            <span>${roundToTwoDecimalPlaces(serviceFee)}</span>
                        </li>
                        </>: ""
                        }

                        {!userTotalAmount ?
                        <>
                        <li>
                            <p><b>Tax</b></p>
                            <span>${roundToTwoDecimalPlaces(tax)}</span>
                        </li>
                        <li>
                            <p><b>Delivery</b></p>
                            <span>${roundToTwoDecimalPlaces(deliveryFee)}</span>
                        </li>
                        </> : ""
                        }
                    </ul>
                    <ul className='summary-items'>
                        <li>
                            <h4>Total</h4>
                            <span className='final-amount'><b>${roundToTwoDecimalPlaces(grandTotal)}</b></span>
                        </li>
                    </ul>

                </div>
        </OrderSummaryStyle>
    )
}
