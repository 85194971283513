import { Button } from '@material-ui/core';
import { useState, useEffect } from 'react';
import ErrorComponent from './ErrorComponent';

export default function UploadWidget({ imgOnly=false, setNewImgUrl, img, buttonText = 'Upload Image', afterUploadText = 'Change image', customButton, style = { width: '100%', marginBottom: '10px', marginTop: '10px' }}) {
  const [error, setError] = useState(false);
  const [widget, setWidget] = useState(null);

  useEffect(() => {
    if (!window.cloudinary) setError('We have an uploading issue, right now 😒');
    const uploadPreset = process.env.NODE_ENV === 'production' ? 'produc' : 'devmode';
    const data = window.cloudinary?.createUploadWidget(
      {
        cloudName: 'nutritt',
        uploadPreset,
        multiple: false,
      },
      (error, result) => {
        if (error) {
          console.log(`error`, error);
        }
        if (!error && result?.event === 'success') {
          console.log('Done! Here is the image info: ', result.info);
          setNewImgUrl(result.info.secure_url);
        }
      },
    );

    if (imgOnly) {
      data?.update({ maxFileSize: 5240000,  clientAllowedFormats: ['gif', 'jpg', 'jpeg', 'png', 'webp'] });
    }
    setWidget(data);
  }, []);  

  return (
    <>
      {customButton ? <div onClick={() => widget?.open()}>{customButton}</div> :
      <Button
        disabled={error}
        style={style}
        variant="contained"
        onClick={() => widget?.open()}
      >
        {img ? afterUploadText : buttonText}
      </Button>
      }
      {error && (
        <ErrorComponent error={error} style={{ marginTop: '-10px', marginBottom: '10px' }} />
      )}
    </>
  );
}
