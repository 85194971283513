import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import 'animate.css';
import autoAnimate from '@formkit/auto-animate';
import cards from '../../assets/card.png';
import Modal from "react-responsive-modal";
import EditChallenge from "./EditChallenge"


const Container = styled.div` 
	.card{
		margin-top: 14px;
		width:100%;
		box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
		border-radius: 10px;
		border: 0px;
		overflow: clip;
	}
	
	.card-img-top{
		background-size:contain;
		height:184px;
		width: 100%;
	}

	.card-title{
			font-family: 'Roboto';
			font-style: normal;
			color: #51635D;
			margin:0 7px;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			position: relative;
    		top: 1px;
	}

	.card-text{
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			color: #0B1D17;
			margin-bottom: 13px;
	}
	.cart-calendar{
		align-items:center;
	}
	.card-date {
		display: flex;
		margin-bottom:13px;
		align-items: center;
		.icon-vector{
			height: 16px;
		}
	}		
	.card-body{
		padding: 15px 20px 14px 20px;
		transition:all 0.5s !important;
	}
	.icon-calendar{
		color: #51635D;
		height: 16px;
	}
	.Row {
		display: table;
		width: 100%; /*Optional*/
		table-layout: fixed; /*Optional*/
		border-spacing: 10px; /*Optional*/
	}
	.Column {
			display: table-cell;
			// background-color: red; /*Optional*/
	}
	.cart-number{
		// width: 32px;
		height: 16px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		color: #949D9B;
		// margin-left: -9px;
	}
	.cart-number1{
			// width: 32px;
			height: 14px;
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 14px;
			display: flex;
			align-items: center;
			color: #949D9B;
	}
	.cart-Details{
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		text-align: right;
		color: #52C41A;
		transition : all 0.5s;
		display: flex;
		align-items: center;
		column-gap: 4px;
		span{
			font-size: 10px;
		}
	}

	.card-detail{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.card-detail1{
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin: 24px 0px 0;
	// margin-top: 24px;
	animation: Openmodal 0.4s ease-in forwards;
	position: relative;
	.p25{
		position: relative;
		left: 3px;
	}
	.p50{
		position: relative;
		left: 8px;
	}
	.p75{
		position: relative;
		left: 10px;
	}
	}
	.card-detail1:before{
		// border-top: 1px solid #f0f0f0;
	// padding-top: 12px;
		width:calc(100% + 40px);
		left: -20px;
		content: '';
		position: absolute;
		top: -12px;
		border-top: 1px solid #f6f5f8;
		padding-top: -8px;
		// width: 240px;
	}
	.card-price{
		margin:0 5px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;			
		display: flex;
		align-items: center;			
		color: #949D9B;
		position: relative;
		top: 1px;
	}
	.card-price1{
		font-weight: 400;
		font-size: 12px;
		line-height: 14px; 
		color: #51635D;
		margin: 0;
		position: relative;
		top: 1px;
		margin-left: 5px;
	}
	.dollar-icon{
		height: 16px;
	}
	.arrow-icon{
		height: 14px;
	}
	.profile-user{
		height: 16px;
	}
	.edit-user{
		color: #52C41A;
		height: 12px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		display: flex;
		align-items: center;
	}
	.edit-Details{
		font-weight: 400;
		font-size: 12px;
		color: #52C41A;
		margin: 0px;
		margin-left: 5px;
		position: relative;

	}
	.card-month{
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		color: #AFB5B4;
		opacity: 0.5;
		margin-top: 3px;
	}

	hr{
		margin : 5px;
	}
	.d-none{
		display:none;
	}
	.cart-calendar{
		width:100%;
		display:flex;
		align-items:center;
	}
	.card-body-height{
		flex:1 1 auto;
		padding: 1rem 1rem;
		height:130px;
		margin:0px !important;
	}
	.card{
		@media (max-width:1140px){
			margin:10px;
		}
		@media (max-width:572px){
			margin:14px 0px;
		}
	}
  `

  export default function ChallangeCard({ item, index, nutritionistUrl}) {
	const [show, setshow] = useState(false);
	const history = useHistory()
	const [editformShow , setEditformshow]=useState(false)
	const parent = useRef(null);
	const URL_ROOT = `${window.origin}/plan/`;
	const shareDATA = {
		title: `${item?.name} Challenge`,
		text: `Follow ${item?.duration} ${item?.duration_type} ${item?.name} challenge on Nutritt`,
		url: URL_ROOT + nutritionistUrl,
		icon: '/images/nutritt-logo.svg'
	}
	const shareData = async () => {
		try {
			if(window.ReactNativeWebView){
				window.ReactNativeWebView.postMessage(JSON.stringify({event:"challenge-share",data:shareDATA}))
				return ;
			  }
			await navigator.share(shareDATA)
		} catch (error) {
			console.log(error, 'error');
		}
	}
	const getMediaType = (url) => {
		const extension = url.split('.').pop().toLowerCase();
		switch (extension) {
			case 'mp4':
			case 'webm':
			case 'ogg':
				return 'video';
			case 'mp3':
			case 'wav':
			case 'ogg':
				return 'audio';
			default:
				return 'unknown';
		}
	}
	function convertToHttps(imageUrl) {
		if (typeof imageUrl === 'string' && imageUrl.trim().length > 0) {
			const isCloudinaryUrl = imageUrl.includes('cloudinary.com');
			if (isCloudinaryUrl && imageUrl.startsWith('http://')) {
			  return imageUrl.replace('http://', 'https://');
			}
		  }
		
		return imageUrl;
	  }
	
	  const imageUrl = item?.image
	const secureImageUrl = convertToHttps(imageUrl);
	useEffect(() => {
		parent.current && autoAnimate(parent.current);
	}, [parent])
	return (
		<>
		<Container>
			<div className="card" key={index} >
			{secureImageUrl == null ? (
		<img src={'/images/plan-cover.png'} className="card-img-top" alt="..." />
	) : (getMediaType(secureImageUrl) === 'video') ? (
		<video
		autoPlay
		muted
		loop
		playsInline
		onError={(e) => { e.target.src = 'http://res.cloudinary.com/nutritt/video/upload/v1671744427/production/trim.0465111B-2470-4740-A9B3-9ABE7A80A180_ockfqo.mov' }}
		src={secureImageUrl}
		type="video/mp4"
		className="card-video-top"
		></video>
	) : (getMediaType(secureImageUrl) === 'audio') ? (
		<audio
		controls
		src={secureImageUrl}
		type="audio/mpeg"
		className="card-audio-top"
		></audio>
	) : (
		<img src={secureImageUrl} className="card-img-top" alt="..." />
	)}
				<div ref={parent} className='card-body'>
					<div className="card-date">
						<div className="cart-calendar"><img src="/icons/calendar-inactive.svg" className="icon-calendar" /> <h5 className="card-title">{item.duration} {item.duration_type}</h5>
						</div>
						{/* <Link to={`/nutritionist/Challanges/share/${item?.id}`}> */}
							<img src="/icons/share.svg" className="icon-vector" onClick={shareData}/>
						{/* </Link> */}
					</div>
					{/* {item.introduction ? */}
					<p className="card-text">{item.name}</p>
					{/* : ''} */}
					<div class="Row card-detail">
						<div class="Column"><div className="cart-number"><img src="/icons/dollar-square.svg" className="dollar-icon" />
							<h5 className="card-price">{(item?.totalEarning / 100)?.toFixed(2)}</h5>
						</div>
						</div>
						<div class="Column"><div className="cart-number1"><img src="/icons/profile-2user.svg" className="profile-user" />
							<h5 className="card-price">{item?.totalClient}</h5>
						</div> </div>
						<div class="Column"><div className="cart-Details" onClick={() => {
							setshow(!show);
						}}>Details <span>{show ? '↑' : '↓'}</span></div></div>
					</div>
					{show ?
						<div class="card-detail1" >
							<div class="Column"><div className="cart-number"><img src="/icons/arrow-up.svg" className="arrow-icon" />
								<h5 className="card-price1">$ {(item?.totalEarning / 100)?.toFixed(2)}</h5>
							</div>
								<div className="card-month">last 3 months</div>
							</div>
							<div class="Column"><div className="cart-number1"><img src="/icons/arrow-up.svg" className="arrow-icon" />
								<h5 className="card-price1">+{item?.lastThreeMonthsUsers}</h5>
							</div>
								<div className="card-month">last 3 months</div>
							</div>
							<div class="Column">
								<div className="edit-user">
									<img src="/icons/edit-2-active.svg" className="edit-user" />
									{/* <h5 className="edit-Details" onClick={() => history.push(`/nutritionist/Challanges/edit/${item?.id}`)}>Edit</h5> */}
									<h5 className="edit-Details" onClick={() => setEditformshow(true)}>Edit</h5>
								</div>
							</div>
						</div>
						: ''}
				</div>
			</div>
		</Container>
		{editformShow && <Modal blockScroll={false} open={editformShow}  onClose={()=>setEditformshow(false)}><EditChallenge editformShow={editformShow} setEditformshow={setEditformshow} Itemid={item?.id}/></Modal>}
		</>
	)
}