import { useAppContext } from 'context/appContext';
import { NutritionFactsWrapper } from './style';

export default function NutritionFacts({ ingredients, nutritionLabel, name }) {
  const { isMobile } = useAppContext();

  return (
    <NutritionFactsWrapper>
      <div className="nutrition-title">Nutrition facts</div>
      <div className="nutrition-facts-container">
        <div className="item-wrapper serving">
          <div className="item-text">1 Serving per container</div>
        </div>
        <div className="item-wrapper">
          <div className="item-text bolder">Calories</div>
          <div className="item-value bolder">{ingredients?.nutritionFact?.calories>0?+ingredients?.nutritionFact?.calories:"-"}</div>
        </div>
        <div className="divider main" />
        <div className="item-wrapper">
          <div className="item-text bold">Total Fat</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.fat?.grams>0?+nutritionLabel?.fat?.grams:"-"}g</div>
            <div className="percent">{nutritionLabel?.fat?.percentage>0? +nutritionLabel?.fat?.percentage:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Saturated Fat</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.fat?.saturatedFatGrams>0?+nutritionLabel?.fat?.saturatedFatGrams:"-"}g</div>
            <div className="percent">{nutritionLabel?.fat?.saturatedFatPercent>0?+nutritionLabel?.fat?.saturatedFatPercent:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Trans Fat</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.fat?.transFatGrams>0?+nutritionLabel?.fat?.transFatGrams:"-"}g</div>
            <div className="percent" />
          </div>
        </div>
        <div className="divider" />
        <div className="item-wrapper">
          <div className="item-text bold">Cholesterol</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.cholesterol?.mg>0?+nutritionLabel?.cholesterol?.mg:"-"}mg</div>
            <div className="percent">{nutritionLabel?.cholesterol?.percentage>0?+nutritionLabel?.cholesterol?.percentage:"-"}%</div>
          </div>
        </div>
        <div className="divider" />
        <div className="item-wrapper">
          <div className="item-text bold">Sodium</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.sodium?.mg>0?+nutritionLabel?.sodium?.mg:"-"}mg</div>
            <div className="percent">{nutritionLabel?.sodium?.percentage>0?+nutritionLabel?.sodium?.percentage:"-"}%</div>
          </div>
        </div>
        <div className="divider" />
        <div className="item-wrapper">
          <div className="item-text bold">Total Carbohydrate</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.carbohydrates?.grams>0?+nutritionLabel?.carbohydrates?.grams:"-"}g</div>
            <div className="percent">{nutritionLabel?.carbohydrates?.percentage>0?+nutritionLabel?.carbohydrates?.percentage:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Dietary Fiber</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.carbohydrates?.fiberGrams>0?+nutritionLabel?.carbohydrates?.fiberGrams:"-"}g</div>
            <div className="percent">{nutritionLabel?.carbohydrates?.fiberPercent>0?+nutritionLabel?.carbohydrates?.fiberPercent:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Total Sugars</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.carbohydrates?.sugarGrams>0?+nutritionLabel?.carbohydrates?.sugarGrams:"-"}g</div>
            <div className="percent" />
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Includes added sugars</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.carbohydrates?.addedSugarGrams>0?+nutritionLabel?.carbohydrates?.addedSugarGrams:"-"}g</div>
            <div className="percent">{nutritionLabel?.carbohydrates?.addedSugarPercent>0?+nutritionLabel?.carbohydrates?.addedSugarPercent:"-"}%</div>
          </div>
        </div>
        <div className="divider" />
        <div className="item-wrapper">
          <div className="item-text bold">Protein</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.protein?.grams>0?+nutritionLabel?.protein?.grams:"-"}g</div>
            <div className="percent">{nutritionLabel?.protein?.percentage>0?+nutritionLabel?.protein?.percentage:"-"}%</div>
          </div>
        </div>
        <div className="divider" />
        <div className="item-wrapper">
          <div className="item-text">Vitamin D</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.protein?.vitaminDMicrograms>0?+nutritionLabel?.protein?.vitaminDMicrograms:"-"}µg</div>
            <div className="percent">{nutritionLabel?.protein?.vitaminDPercentage>0?+nutritionLabel?.protein?.vitaminDPercentage:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Calcium</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.protein?.calciumMg>0?+nutritionLabel?.protein?.calciumMg:"-"}mg</div>
            <div className="percent">{nutritionLabel?.protein?.calciumPercent>0?+nutritionLabel?.protein?.calciumPercent:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Iron</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.protein?.ironMg>0?+nutritionLabel?.protein?.ironMg:"-"}mg</div>
            <div className="percent">{nutritionLabel?.protein?.ironPercent>0?+nutritionLabel?.protein?.ironPercent:"-"}%</div>
          </div>
        </div>
        <div className="item-wrapper">
          <div className="item-text">Potassium</div>
          <div className="item-value">
            <div className="gram">{nutritionLabel?.protein?.potassiumMg>0?+nutritionLabel?.protein?.potassiumMg:"-"}mg</div>
            <div className="percent">{nutritionLabel?.protein?.potassiumPercent>0?+nutritionLabel?.protein?.potassiumPercent:"-"}%</div>
          </div>
        </div>
        <div className="divider" />
        <div className="daily-values">*Percent Daily values are based on a 2,000 calorie diet</div>
      </div>
      {isMobile && (
        <div className="ingredients-wrapper">
          <div className="ingredients-title">All ingredients</div>
          <div className="ingredients-contains">
            <img src="/icons/danger-gray.svg" alt="Danger Icon" />
            <div className="ingredients-text">
              Contains {ingredients?.allergens?.join(', ').toLowerCase()}
            </div>
          </div>
          <p>{ingredients?.name?.join(', ')}</p>
        </div>
      )}
    </NutritionFactsWrapper>
  );
}
