import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CartIcon } from 'assets/shop/emptycarticon.svg';
import { Button } from 'components/elements/button/Button';

const EmptyCartStyle = styled.div`
  .empty_div {
    display: flex;
    flex-direction: column;
    margin: 48px 53px 0px 53px;
    align-items: center;
    height: 100vh;
  }
  .empty-cart-title {
    color: #0b1d17;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.6px;
    margin: 2px 0px -15px 0px;
  }
`;

const ButtonStyle = styled.div`
  span {
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .button_style {
    padding-bottom: 25px;
    margin-top: 49px;
    justify-content: center;
    display: flex;
  }
  .order_button {
    display: flex;
    width: 205px;
    height: 42px;
    padding: 18px 16px 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
  }
`;

const EmptyCart = ({ showButton, onButtonClick }) => {
  return (
    <EmptyCartStyle>
      <div className="empty_div">
        <CartIcon />
        <text className="empty-cart-title">
          Your cart is empty<br />
          Add items to get started
        </text>
        {showButton && (
          <ButtonStyle>
            <div className="button_style">
              <Button
                className="order_button"
                title="Add items"
                type="dark"
                onClick={() => {
                  onButtonClick();
                }}
              />
            </div>
          </ButtonStyle>
        )}
      </div>
    </EmptyCartStyle>
  );
};

export default EmptyCart;
