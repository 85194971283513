import styled from "styled-components";
import Title from "./components/Title";
import Button from "./components/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useOnboardingContext } from "./index";
import PageTransition from "./components/PageTransition";
import { CrossButton } from "./components/CrossButton";

const CurrentWeightStyle = styled.div`
    padding: 48px 32px 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    overflow: overlay;
    height: 100%;
    position: relative;
    touch-action:none;
    .flex{
        flex: 1;
    }
    .form_group{
        position: relative;
        margin: 75px 18px 0;
        .form_control{
            width: 100%;
            height: 44px;
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            padding: 0 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
        }
        label{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #949D9B;
        }
    }
`

export default function CurrentWeight() {
    const history = useHistory();
    const [currentWeight, setCurrentWeight] = useState();
    const { onboardingUser, setOnboardingUser } = useOnboardingContext();
    const weightRef = useRef();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isNewGoal = params.get('newGoal');

    const handleBack = () => {
        isNewGoal ? history.push('/self-onboarding/health-goals?newGoal=true') : history.push('/self-onboarding/health-goals');
    }

    useEffect(() => {
        setCurrentWeight(onboardingUser?.weight || '');
    }, [])

    useEffect(() => {
        if (weightRef?.current) {
            weightRef?.current?.focus();
        }
    }, [weightRef?.current])

    const handleChange = (e) => {
        let weight = e?.target?.value;
        const regex = /^(0|[0-9]\d*)(,\d{0,2})?$/;

        if (weight.length <= 3 && regex.test(weight)) {
            setCurrentWeight(weight);
            setOnboardingUser((prev) => ({
                ...prev,
                weight: Number(weight)
            }));
        } else {
            const validWeight = weight.slice(0, -2);
            setCurrentWeight(validWeight);
        }
    }

    const handleContinue = () => {
        if (isNewGoal === "true") {
            if (onboardingUser?.selfMealPlan?.healthGoal === "maintain-weight") {
                history.push('/self-onboarding/active-status?newGoal=true');
                setOnboardingUser((prev) => ({
                    ...prev,
                    selfMealPlan: {
                        ...prev.selfMealPlan,
                        idealWeight: 0
                    }
                }))
            } else {
                history.push('/self-onboarding/ideal-weight?newGoal=true')
            }
        } else {
            history.push('/self-onboarding/current-height')
        }
    }

    return (
        <PageTransition>
            <CurrentWeightStyle>
                {isNewGoal && <CrossButton />}
                <div className="flex">
                    <Title title={"What is your current weight?"} subtitle={"We are using your weights to calculate your daily calories"} />

                    <div className="form_group">
                        <input id="weight" ref={weightRef} autoFocus pattern="\d*" type="number" onChange={handleChange} value={currentWeight} className="form_control" />
                        <label for="weight">lbs</label>
                    </div>
                </div>

                <Button onBackClick={handleBack} disabled={(currentWeight <= 0 || !currentWeight)} onContinueClick={handleContinue} />
            </CurrentWeightStyle>
        </PageTransition>
    )
}