import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

const NutrittLogoStyle = styled.div`
  a {
    height: 100%;
    display: flex;
    align-items: center;

    .nutritt-logo {
      height: 28.38px;
      @media(max-width:991px){
        width:71px;
      }
    }
  }

  @media (min-width: 992px) {
    width: 71.93px;
    display: inline-flex;

    img.isMenu {
      position: absolute;
      top: auto;
      left: 230px;
    }
  }

  @media (min-width: 1200px) {
    img.isMenu {
      left: 296px;
    }
  }
`;

export default function NutrittLogo({ isMenu }) {
  const { pathname } = useLocation();

  return (
    <NutrittLogoStyle>
      <Link to="/">
        <img
          className={classNames('nutritt-logo', {
            isMenu: isMenu && !pathname.startsWith('/provider'),
          })}
          src="/images/nutritt-logo.svg"
          alt="Nutritt Logo"
        />
      </Link>
    </NutrittLogoStyle>
  );
}
