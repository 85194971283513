import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Footer = styled.div`
position: fixed;
left: 0;
right: 0;
bottom: 0;
text-align: center;
transform: translateY(-85px);  
color: #0B1D17 !Important;
text-align: center;
font-family: Gilroy;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.2px;
  a{
    color: #00092D !important;
    text-decoration: underline !important;
  }
  .link{
    a {
      color: #757E89 !important;
      text-decoration: none !important;
    }
    font-weight: 500;
  }

`

export default function FooterLinks() {
  return (
    <Footer>
        <span className='copyright'>Copyright </span> <span className='and'>&copy;</span> <span className='link'><a href={window.location.origin}>Nutritt Inc</a></span> {' '}
          {new Date().getFullYear()}
          <br />
          <Link to="/nutritt/support">Contact Us</Link>
    </Footer>
  )
}
