import styled from 'styled-components';
import NutrittSteps from 'components/NutrittSteps';
import { Link } from 'react-router-dom';
import BackButton from 'components/BackButton';
import ShopSteps from 'components/NutrittSteps/ShopSteps';
import { useAppContext } from 'context/appContext';

const flexCenter = `
  display: flex;
  flext-direction: row;
  justify-content: center;
  align-items: center;
`;

const CartWrapperStyle = styled.div`
  padding: 24px 0;
  .container {
    padding: 0;
    justify-content: center;
    align-items: center;
    // ${flexCenter};
  }
`;

export default function VisitorHeader({ path, title, description }) {
  const { isMobile } = useAppContext();
  return (
    <CartWrapperStyle>
      <div className="container">
      { isMobile  ? <ShopSteps
            title={title}
            description={description}          
        /> 
        : 
        <NutrittSteps
          title={title}
          description={description}
          isFooterHidden={true}
        />}
      </div>
    </CartWrapperStyle>
  );
}
