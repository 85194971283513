import { useState, useEffect, useMemo } from "react";
import styled from 'styled-components';
import {  useHistory } from 'react-router-dom';
import { useNutritionistContext } from 'context/nutritionistContext';
import 'animate.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactTooltip from 'react-tooltip';
import ChallangeCard from './ChallangeCard';
import { useRequest } from "hooks/useRequest";
import { useAppContext } from '../../context/appContext';
import Progress from "components/Progress";
import CreateChallange from "./CreateChallenage";
import Modal from "react-responsive-modal";

const Container = styled.div`

.container{
	max-width: 500px;
	margin: auto;
	margin:0 auto 50px !important;
	padding-right: 24px;
	padding-left: 24px;
	
	.noResults{
		font-family: 'Roboto';
		font-style: normal;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		color: #51635D;
		margin-top: 24px;
	}
	@media (max-width:376px){
		padding-right: 20px;
		padding-left: 20px;
	}
	@media (max-width:321px){
		padding-right: 14px;
		padding-left: 14px;
	}

	.Challanges{
		margin-top: 70px;
		h3{
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 700;
			font-size: 18px;
			line-height: 24px;
			text-align: center;
			color: #51635D;
			margin: 0px;
		}
	}
}
.cart-btn{
	position: fixed;
	left: auto;
	top: auto;
	right: 20px;
	bottom: 94px;
	width: 48px;
	background: #52C41A;
	box-shadow: 0px 1px 15px rgb(0 0 0 / 8%);
	height: 48px;
	border-radius: 50%;
	text-align-last: center;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width:325px){
		right: 20px;
		bottom: 94px;
	}
	@media (min-width:326px) and (max-width:360px){
		left: 81%;
	right: 0%;
	top: 80%;
	}
	@media (min-width:361px) and (max-width:430px){
		right: 20px;
		bottom: 94px;
	}	
}
span.cart-icon {
	font-size: 24px;
	color: #fff;
}

`
export default function Challanges( {isAlert, setIsAlert, link, getForm,modalShow,setModalshow} ) {
	const history = useHistory();
	const [hasMore, setHasMore] = useState(true)
	const [currentSlice, setCurrentSlice] = useState(20)
	const { nutritionist: nutritionistData, allTemplates, setAllTemplates} = useNutritionistContext();
	const [getDietTemplates, { isLoading, error, setError }] = useRequest();
	const { user, isSuper } = useAppContext();


	useEffect(() => {
		const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
		const reqId = isSuper ? imitateNutritionist?.id : user?.id;
		const isNutritionistId = isSuper ? 'true' : 'false';
		const getDietTemplatesUrl = `nutritionist/getDietPlansSummary/${reqId}?isNutritionistId=${isNutritionistId}`;
		if (reqId) {
			getDietTemplates({
				path: `${getDietTemplatesUrl}`,
			}).then((templates) => {
				setAllTemplates(templates.data);
			});
		}
	}, []);
	const fetchMoreData = () => {
		setTimeout(() => {
			setCurrentSlice(currentSlice + 10)
		}, 1500);
	};

	const usersToShow = useMemo(() => {
		return allTemplates?.dietTemplates?.slice(0, currentSlice);
	}, [allTemplates?.dietTemplates, currentSlice])

	useEffect(() => {
		ReactTooltip.rebuild();
		if (usersToShow?.length === allTemplates?.dietTemplates?.length) {
			setHasMore(false);
			return;
		} else {
			setHasMore(true);
			return;
		}
	}, [usersToShow, allTemplates?.dietTemplates])


	return (
		<>
		<Container className="animate__animated animate__fadeIn animate__fast">
			{usersToShow === undefined ? (
				<Progress />
			) : (
				nutritionistData?.nutritionist?.dietTemplates?.length ?
					(<InfiniteScroll
						dataLength={usersToShow?.length}
						next={fetchMoreData}
						pullDownToRefreshThreshold={50}
						hasMore={hasMore}
						loader={<h4>Loading...</h4>}
						scrollableTarget="scrollableDiv"
					>
						<div className="container nutritionist-challanges">
							<div className="Challanges">
								<h3>Challenges</h3>
							</div>
							<div>
								{usersToShow?.map((item, index) => {
									return <ChallangeCard nutritionistUrl={nutritionistData?.nutritionist?.url} item={item} index={index} />
								})}
							</div>
						</div>
					</InfiniteScroll>
					) : (
						<div className="container nutritionist-challanges">
							<div className="Challanges">
								<h3>Challenges</h3>
							</div>
							<p className="noResults">You don't have any challenges.</p>
						</div>
					)
			)}

			<div className="cart-btn" onClick={() => setModalshow(true)}>
				<span className="cart-icon">+</span>
			</div>

		</Container>
		
				{modalShow && <Modal blockScroll={true} open={modalShow} classNames={{modal: 'createModalShow', overlay: 'createModalOverlay'}} className="create-modal" onClose={()=>setModalshow(false)}><CreateChallange direct={true} modalShow={modalShow} setModalshow={setModalshow} isAlert={isAlert} setIsAlert={setIsAlert} link={link} getForm={getForm}/></Modal>}
		</>
	)
}