import { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { IoMdClose } from 'react-icons/io';
import Drawer from '@material-ui/core/Drawer';
import { GiForkKnifeSpoon } from 'react-icons/gi';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useAppContext } from 'context/appContext';
import MealDragDrop from 'components/shop/MealDragDrop';
import NutritionalPlan from 'components/shop/NutritionalPlan';
import CurrentPlanInfo from 'components/shop/CurrentPlanInfo';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import { useHistory } from 'react-router-dom';
import { useVisitorContext } from 'context/visitorContext';
import DietPlannerCheckout from 'components/shop/MealDragDrop/DietPlannerCheckout';
import DailyRecommendedCals from 'components/shop/MealDragDrop/DailyRecommendedCals';

import styled from 'styled-components';
import './style.css';

const NutPlanStyle = styled.div`
  .healthy-wrapper {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-wrapper {
      margin-right: 26px;

      svg {
        color: #52c41a;
        font-size: 40px;
      }
    }

    span {
      color: #0b1c18;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .btn-wrapper {
    margin: 8px 0 16px;
    padding: 8px 0;
    border: 1.5px solid #52c41a;
    border-radius: 4px;
    box-shadow: 0 1px #00000014;
    text-align: center;
    cursor: pointer;
  }
`;

const DietPlannerWrapper = styled.div`
  @media (max-width: 991px) {
    position: fixed;
    bottom: 0;
    left: 0px;
    right: 0px;
    z-index: 105;
    transform: translate(0px, 440px);
    padding: 0;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.14);
    cursor: move;
    transition: transform 0.3s ease-in-out;

    &.setHeight {
      height: auto;
      transform: translate(0px, 0px);
      bottom: 2px;
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
    }

    button {
      position: absolute;
      top: 18px;
      width: 98px;
      height: 32px;
      background-color: var(--nutritt-green);
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      border-radius: 21px;
      line-height: 17px;
      cursor: pointer;

      &.disabled {
        background-color: #afb5b4 !important;
        color: #fff !important;
      }
    }

    .drag-icon-wrapper {
      padding: 6px 0 9px;
      display: flex;
      justify-content: center;
    }

    .drag-icon {
      width: 50px;
      height: 4px;
      background-color: #e0e3e0;
      border-radius: 2px;
      display: block;
    }

    .drawer-container {
      background-color: #f5f6f6;
      border-radius: 10px 10px 0 0;
    }
  }
`;

export default function NutPlanVisitor({ displayImg, mealPlan, setIsPlannerOpen }) {
  const [isOpen, setIsOpen] = useState(false);
  const [deltaPositionY, setDeltaPositionY] = useState(0);
  const history = useHistory();
  const { isMobile, isGuest, isVisitor, user } = useAppContext();

  useEffect(() => {
    if(deltaPositionY < -80){
      document.body.classList.add('dietPlanner'); 
      setIsPlannerOpen(true);
    }else{
      document.body.classList.remove('dietPlanner')
      setIsPlannerOpen(false);
    }
  }, [deltaPositionY])

  let { cartCount, settings, cart } = useVisitorContext();
  const maxMealsCount = settings.days * settings.meals;

  const handleDrag = (e, ui) => {
    setDeltaPositionY(deltaPositionY + ui.deltaY);
  };
   return (
    <>
      <div className={classNames('draggable-mask', { displayMask: deltaPositionY < -80 })} />
      <Draggable axis="y" onDrag={handleDrag} handle="strong" defaultPosition={{ y: 440 }}>
        <DietPlannerWrapper
          className={classNames('no-cursor', { setHeight: deltaPositionY < -80, draggableGuest: isGuest || isVisitor })}
        >
          <div className="container btn-container">
            <button
              className={classNames({ disabled: !cartCount })}
              disabled={!cartCount}
              onClick={(e) => {
                e.stopPropagation();
                document.body.classList.remove('dietPlanner');
                history.push('/visitor/get-started?redirectTo=/shop/cartScreen');
                window.scrollTo(0, 0);
              }}
            >
              Checkout
            </button>
          </div>
          <strong className="cursor">
            <div className="drag-icon-wrapper">
              <span className="drag-icon" />
            </div>
            <DietPlannerCheckout />
          </strong>
          <div className="drawer-container" onClick={(e) => e.stopPropagation()}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <MealDragDrop />
            </DndProvider>
          </div>
          {user ? <DailyRecommendedCals /> : <div style={{ height: "75px" }}></div>}
        </DietPlannerWrapper>
      </Draggable>
    </>
  );
}
