import classNames from 'classnames';
import styled from 'styled-components';

const StyledInputContainer = styled.div`
  display: grid;
  width: 100%;
  margin-top: 20px;
  & .bg-white{
    background-color: #fff !important;
  }
  input,
  textarea,
  select,
  option {
    width: 100%;
    /* border: ${(props) => (props.bordered ? '1px solid #E0E3E0' : 'none')}; */
    border: none;
    background: ${(props) => (props.error ? '#FFF4F4' : props.bordered ? '#f4f6f4' : 'white')};
    min-height: 40px;
    border-radius: 5px;
    padding-inline: 10px;
    :focus-visible {
      outline-color: lightgray;
    }
  }
  textarea {
    padding-top: 5px;
  }
  label {
    color: #0b1c18;
    margin-bottom: 2px;
    span {
      color: red;
    }
  }
  .inputWithIcon {
    display: grid;
    grid-template-columns: 30px 1fr;
    background: #f4f6f4;
    border-radius: 5px;
  }
  .inputWithIcon svg {
    color: gray;
    font-size: 0.8rem;
    align-self: center;
    justify-self: center;
  }
  .inputWithIcon input {
    padding-left: 0;
  }

  @media (max-width: 991px) {
    .field-wrapper {
      position: relative;
      display: flex;
      align-items: center;

      img {
        position: absolute;
        top: auto;
        right: 16px;
      }
    }

    input {
      height: 44px;
      padding: 0 14px;
      background-color: #fff;
      border: 1px solid #e0e3e0;
      border-radius: 6px;

      &::placeholder {
        color: #949d9b;
      }
    }

    .input-wrapper {
      width: 100%;

      &.inputInfo {
        position: relative;
        display: flex;
        align-items: center;

        input {
          padding-right: 50px;
        }

        span {
          position: absolute;
          top: auto;
          right: 14px;
          color: #949d9b;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
        }
      }
    }
  }  
`;

const StyledCheckboxContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 25px;
  // cursor: pointer;
  user-select: none;
  input {
    margin-right: 5px;
    cursor: pointer;
  }
  label {
    color: #0b1c18;
    cursor: pointer;
    span {
      color: red;
    }
  }
 
  @media (max-width: 991px) {
    margin: 20px 0 25px;
   
    .round {
      position: relative;
      display: flex;
      align-items: center;

      label {
        margin: 0;
        color: #51635d;
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .round-checkbox {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      margin: 0;
      border: 1px solid #949d9b;
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 6px;
        height: 3px;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        opacity: 0;
      }
    }

    input[type='checkbox'] {
      height: 16px;
      margin: 0 8px 0 0;
      visibility: hidden;

      &:checked + .round-checkbox {
        background-color: #52c41a;
        border-color: #52c41a;

        &:after {
          opacity: 1;
        }
      }
    }
  }
`;

const StyledErrorText = styled.div`
  color: #f5222d;
  font-size: 13px;

  @media (max-width: 991px) {
    position: absolute;
    left: 0;
    bottom: -15px;
    margin-left:7%;
  }
`;
export default function Input({ 
  type = 'text', 
  label,
  name,
  value,
  checked,
  onChange,
  Icon,
  options,
  bordered,
  required,
  placeholder,
  error,
  fieldIcon,
  imgSrc,
  info,
  className,
  ...props
}) {
  const id = props.id || name;
  
  if (type === 'checkbox')
    return (
      <StyledCheckboxContainer>
        <div class="round">
          <input type="checkbox" id={id} name={name} checked={checked} onChange={onChange} />
          <label for={id} className="round-checkbox" />
          <label htmlFor={id}>
            {label} {required && <span>*</span>}
          </label>
        </div>
        {error && <StyledErrorText>{error}</StyledErrorText>}
      </StyledCheckboxContainer>
    );
  return (
    <StyledInputContainer bordered={bordered} error={error} className={`input-container ${className}`}>
      <div if={id}>
      <label htmlFor={id}>
        {label} {required && <span>*</span>}
      </label>
      <div className={classNames('field-wrapper', { Icon: 'inputWithIcon' })}>
        {Icon && <Icon />}
        {fieldIcon && <img src={imgSrc} alt="Icon" />}
        {type === 'textarea' ? (
          <textarea
            id={id}
            name={name}
            type="text"
            onChange={onChange}
            value={value || ''}
            placeholder={placeholder || label}
            autoComplete="off"
            {...props}
          />
        ) : type === 'select' ? (
          <select
            id={id}
            name={name}
            // type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder || label}
            {...props}
          >
            {options &&
              options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
        ) : type === 'number' ? (
          <div className={classNames('input-wrapper', { inputInfo: info })}>
            <input
              id={id}
              name={name}
              type="number"
              onChange={onChange}
              value={value}
              min={0}
              placeholder={placeholder || label}
              autoComplete="off"
              {...props}
            />
            {info && <span>{info}</span>}
          </div>
        ) : (
          <input
            id={id}
            name={name}
            type={type}
            onChange={onChange}
            value={value || ''}
            placeholder={placeholder || label}
            autoComplete="off"
            {...props}
          />
        )}
      </div>
      </div>
      {error && <StyledErrorText>{error}</StyledErrorText>}
    </StyledInputContainer>
  );
}
