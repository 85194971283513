import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const ShareLinkModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(40, 50, 65, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .motion_modal {
    width: 598px;
    .modal {
      max-width: 598px;
      padding: 24px 24px 50px;
      row-gap: 20px;
      .title,
      .desc {
        color: ${() => theme?.colors?.blackDefault};
      }
      .btn {
        height: 40px;
      }
      .cross {
        top: 28px;
        right: 24px;
      }
    }
  }
`;

export const ActionButtonStyle = styled.div`
  display: flex;
  gap: 19px;
  .link {
    overflow: hidden;
    height: 40px;
    padding-left: 8px;
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    background: ${({ theme }) => theme?.colors?.white};
    flex: 1;
    color: ${({ theme }) => theme?.colors?.blackDefault};
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`;
