import styled from 'styled-components';
import { useState, useEffect, useRef, useMemo } from 'react';
import autoAnimate from '@formkit/auto-animate';
import { useAppContext } from 'context/appContext';
import MealCard from './MealCard';
import { BsCheck2 } from 'react-icons/bs';
import CustomToolTip from './CustomToolTIp';
import moment from 'moment';
import { VscInfo } from 'react-icons/vsc';
import Tooltip from 'rc-tooltip';
import { SubcriptionOptions } from '../../../../../src/constants';

const DetailCardStyle = styled.div`
padding: 0px 12px 0px;
margin-bottom: 5px;
box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    &.expand{
padding-bottom:10px;
margin-bottom: 10px;

&:first-child {
    margin-top: 0 !important;
  }
margin-top: 15px;
.provider_details{
    padding-bottom: 0px !important;
    .order_info{
        display:none;
    }
    .card_body{
        transition: background-color 1s ease-in-out;
        display: block;
    }
}
    };
    .card_header{
        display: flex;
        gap: 24px;
        &.expand {
          margin-bottom: 5px;
        }
        .provider_image{
            width: 114px;
            padding-left: 14px;
            img{
                width: 100%;
            }
        }
        .provider_details{   
          padding: 4px 0px 4px;
          display: flex;
            text-align: center;
            justify-content:space-between;
            flex: 1;
            .title{
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #51635D;
                margin-bottom: 20px;
                cursor:pointer;
            }
            .start_details{
                align-items: center;
                display: flex;
            }
            .meal_checkbox {
                position: absolute;
                opacity: 0;
                width: 0;
                height: 0;
              }
              
              .checkbox_container {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                .icon_1{
                    display:none;
                }
              }
              
              .checkbox_container::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 86%;
                height: 82%;
                border: 1px solid #AFB5B4;
                border-radius: 5px; /* sets the border-radius of the square box to 5 pixels */
              }
              
              .checkbox_container::after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                background-color: #AFB5B4;
                border-radius: 50%;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
              }
              
              .checkbox_container:hover::before {
                border-color: #666; /* changes border color on hover */
              }
              .icon_1{
                font-size: 20px;
                padding-top: 2px;
            }
              .meal_checkbox:checked + .checkbox_container {
                .icon_1{
                    display:block;
                    color:#52C41A;
                }
                // background-color: #52C41A; /* changes background color when checkbox is checked */
              }
        .order_num{
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
            cursor:default;
            flex: 1;
            padding-left: 10px;
        }
        .order_info {
            border-collapse: collapse;
            border-spacing: 0;
            table-layout: fixed ;
        }
        .order_details{
            display: flex;
            padding-top: 10px;
        flex-wrap: wrap;
            gap: 2px;
            align-items: center;
            justify-content: flex-end;
            p{
                font-weight: 400;
                font-size: 14px;
                line-height: 12px;
                letter-spacing: 0.02em;
                color: #949D9B;
                padding:0px 4px;
                &.value{
                    color: #51635D;
                    font-weight: 700;
                }
            }
            &:nth-child(even){
                justify-content: flex-end;
            }
        }
        .icon_img{
            margin-bottom: 18px;
    margin-right: 3px;
    cursor:pointer;
        }
        .amount{
            font-weight: 400;
                font-size: 11px;
                line-height: 12px;
                letter-spacing: 0.02em;
                color: #949D9B;
                padding:0px 4px;
            width: 13%;
            text-align: right;
        }
        }
    }
    .card_body{
        .coach_details, .customer_details{
            border-collapse: collapse;
            border-spacing: 0;
            justify-content:center
            table-layout: fixed ;
            width: 100%;
        }
        .coach_details{
            margin-top: 7px;
        }
        td{
            border: 1px solid #E0E3E0;
        }
        .box{
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #949D9B;
          padding: 11px 14px;
          display: flex;
          gap: 5px;
          white-space: nowrap;
          span{
              color: #51635D;
              font-weight: 700;
              display: block;
              white-space: normal;
              word-break:break-all;
          }
          &.address{
              flex-wrap: wrap;
          }
            &.payout{
                background: #F6F5F8;
            }
        }
    }

    .card_body{
      td{
          border: 1px solid #E0E3E0;
      }
      .boxtotal{
        font-weight: 400;
        font-size: 14px;
        padding: 10px 27px !Important;
        margin-top:2px;
        width: 140px;
        justify-content: center;
        line-height: 16px;
        color: #949D9B;
        padding: 11px 14px;
        display: flex;
        gap: 5px;
        span{
            color: #51635D;
            font-weight: 700;
            display: block;
        }
      }
  }

    @media (min-width: 2300px){
      .card_header{
          .provider_details{
              .provider_details_wrapper{
                  .details{
                      width: 25%;
                  }
              }
          }
      }
  }
  @media (max-width: 1500px){
      .card_header{
          gap: 14px;
      }
      .card_body{
          td{
              width: 50% !important;
          }
      }
  }
  @media only screen and (max-width: 1342px) {
    .title{
      display:none
    }
  }

  @media (max-width: 1280px){
      .card_body{
          .box{
              padding: 8px 14px;
          }
      }
      .card_body{
        .boxtotal{
          padding: 8px 14px;
        }
      }
  }
  @media (max-width: 991px){
    padding: 28px 24px 24px;
    margin-bottom: 0;
    .provider_num{
        right: auto;
        left: 24px;
    }
    .card_header{
        flex-direction: row-reverse;
        gap: 0;
        margin-bottom: 15px;
        padding-bottom: 24px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            bottom: 0;
            left: -24px;
            width: 99vw;
            height: 1px;
            background: #E0E3E0;
        }
        .provider_image{
            padding-left: 0;
            width: 50px;
            img{
                width:48px !important;
                height:48px;
            }
        }

        .provider_details{
            .provider_details_wrapper{
                row-gap: 8px;
                .details{
                    width: 100%;
                }
            }
        }
    }
    .card_body{
        td{
            display: block;
            border: 0px;
            width: 100% !important;
        }
        .box{
            padding: 7px 0px;
            &.address{
                flex-wrap: unset;
            }
            &.payout{
                background: #fff;
            }
        }
        .coach_details{
            margin-top: 0;
        }
    }
    .card_body {
      td{
        display: block;
        border: 0px;
        width: 100% !important;
    }
    .boxtotal{
      padding: 7px 0px;
    }
    }
}
`;
const MealCardStyle = styled.div`
  .order_meals {
    padding: 24px 20px 70px;
    margin-bottom: 40px;
  }
`;
const overlayInnerStyle = {
  backgroundColor: '#F0FAEB',
  border: '1px solid #E0E3E0',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  color: '#51635D',
  fontSize: '11px',
  textAlign: 'left',
  maxWidth: '250px',
  width: '100%'
}
export default function DetailCard({
  deliveryData,
  orderNumber,
  coachDetail,
  userDetail,
  size,
  checked,
  onChange,
  isChildExpanded,
  onChildExpand,
  checkboxes,
  onCheckboxChange,
  setSelectedMeals,
  selectedMeals,
  setSelectedNonMeals,
  providerDetail,
  selectedNonMeals,
  setTotalMeals,
  activeTab
}) {
  const { isMobile } = useAppContext();
  const { deliveryAddress, deliveryState, deliveryZip } = deliveryData || {};
  const { name } = userDetail?.providerOrder?.orderMain?.user || {};
  const { orderPhoneNumber } = userDetail?.providerOrder || {};
  const { selectedDate, selectedTime, isDelivery } = userDetail?.providerOrder || {};
  const { dateOrdered, orderLine, id, dateTimeOrdered } =
    userDetail?.providerOrder?.orderMain || {};
  let address;

  if (isMobile) {
    if (userDetail && typeof userDetail === 'object' && userDetail.provider) {
      address = userDetail.provider.address || '';
    }
  } else {
    if (providerDetail && typeof providerDetail === 'object' && providerDetail.provider) {
      const { address: providerAddress, city, state, zip } = providerDetail.provider;
      address = providerAddress + ', ' + city + ', ' + state + ', ' + zip || '';
    }
  }

  const {
    coachProfitPercentage,
    subTotal,
    providerTaxAmount,
    amount,
    taxRate,
    deliveryFee,
    serviceFee,
    creditAmount,
    promoCode,
    userMarkupPercent,
    coachCommission,
    userMarkupAmount,
    promoCodeType = "",
    intervaldays, 
    subscriptionDiscountPercent,
    repeatOrderDiscount
  } = coachDetail || {};
  const [isCoachDetailsActive, setIsCoachDetailsActive] = useState(false);
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
    isMobile && setIsCoachDetailsActive(true);
  }, [parent]);

  const Time = new Date(dateTimeOrdered);
  const formattedTime = moment(Time).format('h:00 a');

  const handleCheckboxChange = (e) => {

      handleOrderCheckboxChange(e.target.checked);
      onChange(e.target.checked);
  };

  const handleOrderCheckboxChange = (checked) => {
    const orderId = id;

    setSelectedMeals((prevSelectedMeals) => {
      const updatedSelectedMeals = { ...prevSelectedMeals };
      const orderMeals = orderLine || [];
      const newOrderMeals = orderMeals.map((meal) => ({
        ...meal,
        isSelected: checked,
      })).filter((item) => item.refundStatus !== 'voided' && item.refundStatus !== 'completed');
      
      if (checked) {
        updatedSelectedMeals[orderId] = { data: newOrderMeals, disbursementId: coachDetail?.id };
      } else {
        delete updatedSelectedMeals[orderId];
      }

      if (checked) {
        const newSelectedMeals = { ...updatedSelectedMeals };
        newSelectedMeals[orderId].data = newOrderMeals;
        return newSelectedMeals;
      }

      return updatedSelectedMeals;
    });

    setSelectedNonMeals((prevSelectedNonMeals) => {
      const updatedSelectedNonMeals = { ...prevSelectedNonMeals };
      const orderMeals = orderLine || [];
      const newOrderMeals = orderMeals.map((meal) => {
        if (meal.refundStatus === 'completed' || meal.refundStatus === 'voided') {
          return meal;
        } else {
          return {
            ...meal,
            isSelected: !checked,
          };
        }
      });

      if (!checked) {
        updatedSelectedNonMeals[orderId] = { data: newOrderMeals, disbursementId: coachDetail?.id };
      } else {
        delete updatedSelectedNonMeals[orderId];
      }

      if (!checked) {
        const newSelectedNonMeals = { ...updatedSelectedNonMeals };
        newSelectedNonMeals[orderId].data = newOrderMeals;
        return newSelectedNonMeals;
      }

      return updatedSelectedNonMeals;
    });
  };

  function onCheckboxChange(index, checked, orderId) {
    const meal = orderLine[index];
    const mealId = meal.mealId;
    setSelectedMeals((prevSelectedMeals) => {
      const updatedSelectedMeals = { ...prevSelectedMeals };
      const mealIds = (updatedSelectedMeals[orderId] && updatedSelectedMeals[orderId].data) || [];

      if (checked) {
        const data = [...mealIds, meal];
        updatedSelectedMeals[orderId] = { data: data, disbursementId: coachDetail?.id };
      } else {
        const mealIndex = mealIds.findIndex((selectedMeal) => selectedMeal.mealId === mealId);
        if (mealIndex > -1) {
          mealIds.splice(mealIndex, 1);
        }
        if (mealIds.length === 0) {
          delete updatedSelectedMeals[orderId];
        } else {
          updatedSelectedMeals[orderId].data = mealIds;
        }
      }
      return updatedSelectedMeals;
    });
    setSelectedNonMeals((prevSelectedNonMeals) => {
      const updatedSelectedNonMeals = { ...prevSelectedNonMeals };
      const mealIds =
        (updatedSelectedNonMeals[orderId] && updatedSelectedNonMeals[orderId].data) || [];

      if (!checked) {
        const data = [...mealIds, meal];
        updatedSelectedNonMeals[orderId] = { data: data, disbursementId: coachDetail?.id };
      } else {
        const mealIndex = mealIds.findIndex((selectedMeal) => selectedMeal.mealId === mealId);
        if (mealIndex > -1) {
          mealIds.splice(mealIndex, 1);
        }
        if (mealIds.length === 0) {
          delete updatedSelectedNonMeals[orderId];
        } else {
          updatedSelectedNonMeals[orderId].data = mealIds;
        }
      }
      return updatedSelectedNonMeals;
    });
  }

  const displayDateTime = (date) => {
    const dateSplit = date?.split('T');
    const toBeCompletedDate = dateSplit && dateSplit[0];
    return moment(toBeCompletedDate).format('M/D/YYYY');
  };

  useEffect(() => {
    handleOrderCheckboxChange(checked);
  }, [checked, checkboxes]);

  const subscriptionInfo = useMemo(() => {
    const foundSubscriptionInfo = SubcriptionOptions?.find(subscription => subscription?.intervaldays === intervaldays);
    return foundSubscriptionInfo && intervaldays ? `${foundSubscriptionInfo?.label} ${subscriptionDiscountPercent}%` : 'N/A';
  }, [SubcriptionOptions, intervaldays, subscriptionDiscountPercent]);

  return (
    <>
      <DetailCardStyle className={`${isChildExpanded ? 'expand' : ''}`}>
        {!isMobile ? (
          <div className="card_header">
            <div className="provider_details" ref={parent}>
              <div className="start_details">
                <label style={{ padding: '2px 2px 0px 8px' }}>
                  <input
                    type="checkbox"
                    className="meal_checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="checkbox_container">{<BsCheck2 className="icon_1" />}</span>
                </label>
                <h2 className="order_num">{orderNumber}</h2>
              </div>
              <div className="card_body" style={{ paddingTop: '5px' }}>
                <table cellPadding={0} className="order_info">
                  <tr>
                    <td>
                      <div className="boxtotal" style={{ padding: '16px 34px' }}>
                        Items: <span>{orderLine.length}</span>
                      </div>
                    </td>
                    <td>
                      <div className="boxtotal">
                        Subtotal: <span>${subTotal}</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="order_details" onClick={onChildExpand}>
                <p className="title">{isChildExpanded ? 'Collapse' : 'Expand'}</p>
                <div className="icon_img">
                  {isChildExpanded ? (
                    <img src="/images/scrollX.svg" alt="ScrollIconX Icon" />
                  ) : (
                    <img src="/images/scroll.svg" alt="Scroll Icon" />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {!isMobile ? (
          <div className="card_body">
            {isChildExpanded && (
              <table cellPadding={0} className="customer_details">
                <tr>
                  <td>
                    <div className="box">
                      Customer : <span>{name}</span>
                    </div>
                  </td>
                  {size <= 1280 && (
                    <td>
                      <div className="box">
                        Subtotal: <span>${subTotal}</span>
                      </div>
                    </td>
                  )}
                  {size > 1280 && (
                    <td>
                      <div className="box">
                        Scheduled for:
                        <span style={{ textTransform: 'lowercase' }}>
                        {`${moment(selectedDate).format('M/D/YYYY')}`}, {selectedTime}
                        </span>
                      </div>
                    </td>
                  )}
                  {size >= 2300 && (
                    <td>
                      <div className="box">
                        Order at:{' '}
                        <span>
                          {displayDateTime(dateTimeOrdered)} {formattedTime}
                        </span>
                      </div>
                    </td>
                  )}
                  {size > 1500 && (
                    <td rowSpan={3} style={{ verticalAlign: 'initial' }}>
                      <div className="box address">
                        {isDelivery ? 'Delivery:' : 'Pickup:'}
                        <span>
                          {isDelivery
                            ? deliveryAddress ? `${deliveryAddress}` : `${deliveryState}, ${deliveryZip}`
                            : `${address}`}
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
                <tr>
                  <td>
                    <div className="box">
                      Phone:
                      <span>{orderPhoneNumber ? <span>{orderPhoneNumber}</span> : <span>NA</span>}</span>
                    </div>
                  </td>
                  {size > 1280 && size < 2300 && (
                    <td>
                      <div className="box">
                        Order at:{' '}
                        <span>
                          {displayDateTime(dateTimeOrdered)} {formattedTime}
                        </span>
                      </div>
                    </td>
                  )}
                  {size <= 1280 && (
                    <td rowSpan={4} style={{ verticalAlign: 'initial' }}>
                      <div className="box address">
                        {isDelivery ? 'Delivery:' : 'Pickup:'}
                        <span>
                          {isDelivery
                            ? deliveryAddress ? `${deliveryAddress}` : `${deliveryState}, ${deliveryZip}`
                            : `${address}`}
                        </span>
                      </div>
                    </td>
                  )}
                  {size >= 2300 && (
                    <>
                      <td>
                        <div className="box">
                          Method: <span>{isDelivery ? 'Delivery' : 'Pickup'}</span>
                        </div>
                      </td>
                      <td>
                        <div className="box">
                          Subtotal: <span>${subTotal}</span>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                {size < 2300 && (
                  <tr>
                    <td>
                      <div className="box">
                        Method: <span>{isDelivery ? 'Delivery' : 'Pickup'}</span>
                      </div>
                    </td>
                    {size <= 1500 && size > 1280 && (
                      <td rowSpan={2} style={{ verticalAlign: 'initial' }}>
                        <div className="box address">
                          {isDelivery ? 'Delivery:' : 'Pickup:'}
                          <span>
                            {isDelivery
                              ? deliveryAddress ? `${deliveryAddress}` : `${deliveryState}, ${deliveryZip}`
                              : `${address}`}
                          </span>
                        </div>
                      </td>
                    )}
                    {size > 1500 && (
                      <td>
                        <div className="box">
                          Subtotal: <span>${subTotal}</span>
                        </div>
                      </td>
                    )}
                  </tr>
                )}
                {size <= 1500 && size > 1280 && (
                  <tr>
                    <td>
                      <div className="box">
                        Subtotal: <span>${subTotal}</span>
                      </div>
                    </td>
                  </tr>
                )}
                {size <= 1280 && (
                  <>
                    <tr>
                      <td>
                        <div className="box">
                          Scheduled for:
                          <span style={{ textTransform: 'lowercase' }}>
                          {`${moment(selectedDate).format('M/D/YYYY')}`}, {selectedTime}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="box">
                          Order at:{' '}
                          <span>
                            {displayDateTime(dateTimeOrdered)} {formattedTime}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </table>
            )}

            {isChildExpanded && (
              <table cellPadding={0} className="coach_details">
                <tr>
                  <td>
                    <div className="box">
                      Coach:
                      <span>
                        {coachDetail?.providerOrder?.orderMain?.nutritionist?.name || 'NA'}
                      </span>
                    </div>
                  </td>
                  <td style={{ minWidth: 270, width: 270 }}>
                    <div className="box">
                      Coach commission:
                      <span>
                        {parseFloat(coachProfitPercentage).toFixed(1)}% (${coachCommission})
                      </span>
                    </div>
                  </td>
                  {size > 1500 && (
                    <td>
                      <div className="box">
                        User markup:
                        <span>
                          {parseFloat(userMarkupPercent).toFixed(1)}% (${userMarkupAmount})
                        </span>
                      </div>
                    </td>
                  )}
                  {size >= 2300 && (
                    <>
                      <td>
                        <div className="box">
                          Service Fee: <span>${serviceFee}</span>
                        </div>
                      </td>
                      <td>
                        <div className="box">
                          Tax percentage:
                          <span>
                            {taxRate * 100} (zip: {deliveryZip})
                          </span>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <td>
                    <div className="box">
                    {promoCodeType === "giftCard" ? 'Giftcard' : 'Promocode'}
                      {promoCode ? <span>{coachDetail.promoCode}</span> : <span>NA</span>}
                    </div>
                  </td>
                  <td>
                    <div className="box">
                      Credit amount: <span>${creditAmount}</span>
                    </div>
                  </td>
                  {size > 1500 && (
                    <td>
                      <div className="box">
                        Delivery fee: <span>${deliveryFee}</span>
                      </div>
                    </td>
                  )}
                  {size >= 2300 && (
                    <>
                      <td>
                        <div className="box">
                          Subscription: <span>{subscriptionInfo}</span>
                        </div>
                      </td>
                      <td>
                        <div className="box">
                          Credit Amount: <span style={{ position: 'relative' }}>${repeatOrderDiscount}</span>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                {size >= 2300 && (
                    <>
                      <td>
                        <div className="box">
                          Tax amount: <span>${providerTaxAmount}</span>
                        </div>
                      </td>
                      <td>
                        <div className="box payout">
                          {activeTab === "voided" ? "Voided Provider payout:" : "Provider payout:"} <span style={{ position: 'relative' }}>${amount}</span>
                          <Tooltip  overlayInnerStyle={overlayInnerStyle} overlay={`The amount we have to pay to the provider, which includes delivery fees and taxes`}>
                          <VscInfo style={{fontSize: '8.5px', display: 'block'}} />
                        </Tooltip>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                {size < 2300 && (
                  <>
                    <tr>
                      <td>
                        <div className="box">
                          Service Fee: <span>${serviceFee}</span>
                        </div>
                      </td>
                      <td>
                        <div className="box">
                          Tax percentage:
                          <span>
                            {taxRate * 100} (zip: {deliveryZip})
                          </span>
                        </div>
                      </td>
                      {size > 1500 && (
                        <td>
                          <div className="box">
                            Tax amount: <span>${providerTaxAmount}</span>
                          </div>
                        </td>
                      )}
                    </tr>
                    {size <= 1500 && (
                      <tr>
                        <td>
                          <div className="box">
                            User markup:
                            <span>
                              {parseFloat(userMarkupPercent).toFixed(1)}% (${userMarkupAmount})
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="box">
                            Delivery fee: <span>${deliveryFee}</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <div className="box">
                         Subscription:
                          <span>
                            {subscriptionInfo}
                          </span>
                        </div>
                      </td>
                        <td>
                          <div className="box">
                            Credit Amount: <span>${repeatOrderDiscount}</span>
                          </div>
                        </td>
                    </tr>
                    <tr>
                      {size > 1500 && <td style={{ border: 0 }}></td>}
                      {size <= 1500 && (
                        <td>
                          <div className="box">
                            Tax amount: <span>${providerTaxAmount}</span>
                          </div>
                        </td>
                      )}
                      <td>
                        <div className="box payout">
                          {activeTab === "voided" ? "Voided Provider payout:" : "Provider payout:"}
                          <span >
                            ${amount}
                          </span>
                          <Tooltip  overlayInnerStyle={overlayInnerStyle} overlay={`The amount we have to pay to the provider, which includes delivery fees and taxes`}>
                          <VscInfo style={{fontSize: '8.5px', display: 'block'}} />
                        </Tooltip>
                        </div>
                      </td>
                      {size > 1500 && <td style={{ border: 0 }}></td>}
                    </tr>
                  </>
                )}
              </table>
            )}
          </div>
        ) : (
          <div className="card_body">
            {isChildExpanded && (
              <table cellPadding={0} className="customer_details">
                <tr>
                  <tr>
                    <td>
                      <div className="box">
                        Order at:{' '}
                        <span>
                          {displayDateTime(dateTimeOrdered)} {formattedTime}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <td>
                    <div className="box">
                      Delivery fee: <span>${deliveryFee}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="box">
                      Method: <span>{isDelivery ? 'Delivery' : 'Pickup'}</span>
                    </div>
                  </td>
                  <td>
                    <div className="box">
                      Subtotal: <span>${subTotal}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="box">
                      Tax : <span style={{ textTransform: 'lowercase' }}>${providerTaxAmount}</span>
                    </div>
                  </td>
                </tr>
              </table>
            )}
            {isChildExpanded && (
              <table cellPadding={0} className="coach_details">
                <tr>
                  <td>
                    <div className="box">
                      Promo code:
                      <span>
                        {promoCode ? <span>{coachDetail.promoCode}</span> : <span>NA</span>}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="box">
                      Customer name: <span>{name}</span>
                    </div>
                  </td>
                  <td>
                    <div className="box">
                      Phone number:
                      <span>{orderPhoneNumber ? <span>{orderPhoneNumber}</span> : <span>NA</span>}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="box">
                      Coach:
                      <span>
                        {coachDetail?.providerOrder?.orderMain?.nutritionist?.name || 'NA'}
                      </span>
                    </div>
                  </td>
                  <div className="box">
                    Coach commission:
                    <span>
                      {parseFloat(coachProfitPercentage).toFixed(1)}% (${coachCommission})
                    </span>
                  </div>
                </tr>
                <tr>
                  <td>
                    <div className="box">
                      Subscription:
                      <span>
                        {subscriptionInfo}
                      </span>
                    </div>
                  </td>
                  <div className="box">
                    Credit Amount:
                    <span>
                      ${repeatOrderDiscount}
                    </span>
                  </div>
                </tr>
                <tr>
                  <td rowSpan={4} style={{ verticalAlign: 'initial' }}>
                    <div className="box address">
                      {isDelivery ? 'Delivery:' : 'Pickup:'}
                      <span>
                        {isDelivery
                          ? deliveryAddress ? `${deliveryAddress}` : `${deliveryState}, ${deliveryZip}`
                          : `${address}`}
                      </span>
                    </div>
                  </td>
                </tr>
              </table>
            )}
          </div>
        )}
      </DetailCardStyle>
      <MealCardStyle>
        {!isMobile ? (
          orderLine?.map((meal, index) =>
            isChildExpanded ? (
              <MealCard
                key={index}
                isMobile={isMobile}
                mealDetails={meal}
                checked={
                  selectedMeals[id] &&
                  selectedMeals[id].data.some((selectedMeal) => selectedMeal.mealId === meal.mealId)
                }
                onCheckboxChange={(checked) => onCheckboxChange(index, checked, id)}
              />
            ) : null,
          )
        ) : (
          <div className="order_meals">
            {orderLine?.map((meal, index) =>
              isChildExpanded ? (
                <MealCard
                  key={index}
                  isMobile={isMobile}
                  mealDetails={meal}
                  checked={
                    selectedMeals[id] &&
                    selectedMeals[id].data.some(
                      (selectedMeal) => selectedMeal.mealId === meal.mealId,
                    )
                  }
                  onCheckboxChange={(checked) => onCheckboxChange(index, checked, id)}
                />
              ) : null,
            )}
          </div>
        )}
      </MealCardStyle>
    </>
  );
}
