import styled from 'styled-components';
import { useMemo } from 'react';
import Input from 'components/Input';
import { useEffect, useRef } from 'react';

const PromoCodeMultipleFormStyle = styled.div`
width:570px;
display:flex;
flex-direction:column;
align-items:center;
border-top: 1px solid #E0E3E0;
margin-top:24px;
padding-bottom:20px;


.multiple-form{
   margin-top:24px;
   
   .order{
      display:flex;
      width:476px;
      justify-content:space-between;
     
      p{
         font-weight: 700;
         font-size: 14px;
         color: #0B1D17;
      }

      img{
         cursor:pointer;
      }
   }

   .name-type{
      display:flex;
      justify-content:center;
      align-items: center;
      gap:16px;
      margin-bottom:24px;
      margin-top:24px;

      input{
         background:url('/icons/dolar.svg') no-repeat !important;
         background-position: 93% 50% !important;
       }
   }

   .amt-div{

      input{
         background:url('/icons/dolar.svg') no-repeat;
         background-position: 93% 50%;
       }

   }


}

@media(max-width:992px){
   width:100% !important;

   .multiple-form{
      width:80% !important;
      
      .order{
         width:100% !important;
      }

      .name-type{
         display:flex;
         flex-direction:row !important;
      }

      div:nth-child(2) {
         div:nth-child(2){
            width: 100% !important;
         }
      }
   }
}
`

export function PromoCodeMultipleForm({id,index, multiInputs,setMultiInputs, promoCodeInfo, preventPasteNegative, handleKeyPress, setpromoCodeInfo, form}){
     const order = useMemo(()=>{
       if(index==1){
          return "Second"
       }
       else if(index==2){
          return "Third"
       }
       else if(index==3){
          return "Fourth"
       }
       else if(index==4){
          return "Fifth"
       }
       else if(index==5){
          return "Sixth"
       }
       else if(index==6){
          return "Seventh"
       }
       else if(index==7){
          return "Eighth"
       } else if(index==8){
          return "Ninth"
       }
       else if(index==9){
          return "Tenth"
       }
       else{
          return ""
       }
     },[id])

   const handleInputChange = (e, index) => {
      const { name, value: nonTrimValue } = e.target;
      let value = nonTrimValue?.trim();
      const decimalRegex = /^\d+(\.\d{1,2})?$/;
      if (!decimalRegex.test(value)) {
         value = parseFloat(value).toFixed(2);
      }
      const list = [...multiInputs];
      list[index][name] = Number(value);
      if (form.discount_type === "percentage" && name === "discount" && value > 100) {
         setMultiInputs((prevList) =>
            prevList.map((item) => ({
               ...item,
               discount: 100
            }))
         );
      } else {
         setMultiInputs(list);
      }
   }
    const handleRemove= index=>{
      const list=[...multiInputs];
      list.splice(index,1);
      for (let counter = 0; counter < list.length; counter++) {
         const element = list[counter];
         element.id=counter+1;
         
      }
      setMultiInputs(list);
      setpromoCodeInfo({...promoCodeInfo, data: {maxUsedCount: list}})
    }

    const renderValue=(name,index)=>{
      let val=multiInputs[index][name];
      return val;
    }
    
    const scrollSpan = useRef(null);

     useEffect(() => {
     scrollSpan.current.scrollIntoView({ behavior: "smooth" });
  }, []);

    return(
       <PromoCodeMultipleFormStyle>
        <div className='multiple-form' ref={scrollSpan}>
         <div className='order'>
          <p>{order} order</p>
          <img src='/icons/trash2.svg' onClick={() => handleRemove(index)}/>
         </div>
         <div className='name-type'>
                <Input
                   label="Min order amount"
                   name="minOrderAmount"
                   value={renderValue('minOrderAmount',index)}
                   bordered
                   type="Number"
                   onChange={ e=>handleInputChange(e,index) }
                   placeholder="0"
                   onPaste={preventPasteNegative}
                   onKeyPress={handleKeyPress}
                   onWheel={(e) => e.target.blur()}
                />
    
                <Input
                   label="Max order amount"
                   name="maxOrderAmount"
                   value={renderValue('maxOrderAmount',index)}
                   onChange={ e=>handleInputChange(e,index) }
                   bordered
                   type="Number"
                   placeholder="0"
                   className='max-input'
                   onPaste={preventPasteNegative}
                   onKeyPress={handleKeyPress}
                   onWheel={(e) => e.target.blur()}
                />
         </div>
         <div className='amt-div'>
                <Input
                   required
                   label="Amount"
                   name="discount"
                   value={renderValue('discount',index)}
                   onChange={ e=>handleInputChange(e,index) }
                   bordered
                   type="Number"
                   placeholder="0"
                   onPaste={preventPasteNegative}
                   onKeyPress={handleKeyPress}
                   onWheel={(e) => e.target.blur()}
                />
         </div>
        </div>
       </PromoCodeMultipleFormStyle>
    )
 }