import { useVisitorContext } from 'context/visitorContext';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SelectOptions from 'components/shop/CreateMealPlanForm/SelectOptions';
import ErrorComponent from 'components/ErrorComponent';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import produce from 'immer';
import AddressDeliveryModal from 'components/shop/CreateMealPlanForm/AddressDeliveryModal';
import BringMealPrepModal from 'components/shop/CreateMealPlanForm/BringMealPrepModal';

const GooglePlacesAutocompleteStyle = styled.div`
  .css-9ekey3-singleValue {
    padding: 0 35px 0 0;
  }

  .css-1l0hnm3-placeholder{
    font-size: 14px;
    line-height: 22px;
  }

  .css-yk16xz-control .css-1hwfws3,
  .css-1pahdxg-control .css-1hwfws3 {
    padding: 0 30px 0 14px !important;
  }
  .css-yk16xz-control,
  .css-1hwfws3,
  .css-1pahdxg-control {
    height: 44px;
    line-height: 44px !important;
    div {
      height: 44px;
      line-height: 44px;
    }
    input {
      position: relative;
      top: -7px;
      height: 44px;
      background-color: transparent;
    }
    .css-8sibub-placeholder {
      top: 20px;
      line-height: 44px;
    }
    .css-eeok4o {
      top: 50%;
      height: 44px;
      margin-top: 0;
      padding: 0 0 0 25px;
      line-height: 44px;
    }
  }
  .css-1pahdxg-control {
    height: 44px;
    border: 1px solid #52c41a;
    box-shadow: none;
    &:hover {
      border-color: #52c41a;
    }
  }
  .css-26l3qy-menu {
    postion: relative;
    top: 43px;
    bottom: auto;
    z-index: 4;
    border: 1px solid #52c41a;
    box-shadow: none;
    & > div > div {
      &:first-child {
        background-color: #52c41a5e;
        color: #000;
      }
      &:hover {
        background-color: #52c41a5e;
      }
    }
  }
  @media(max-width: 991px){
    .css-26l3qy-menu {
      overflow:scroll;
      max-height: 75px;
    }
    .css-1l0hnm3-placeholder{
      width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`
const MealformStyle = styled.div`
   .delivery-input{
       height:44px;
       border: 1px solid #E0E3E0 !important;
       border-radius: 6px;
       background: #FFFFFF;
       width:100%;
       padding-left:14px;
       text-align:left;
       font-weight: 400;
       font-size: 14px;
       line-height: 16px;
       color: #949D9B;
   }
   .address_select{
    .css-yk16xz-control, .css-xd1qmr-control {
      width:100%;
      height:44px;
      border: 1px solid #E0E3E0;
      border-radius: 6px;
      margin-top:7px;
   }
   .select__value-container{
     padding:0px 14px !important;
     height: 44px;
     div:nth-child(2){
      margin:0;
      padding:0;
     }
   }
   .css-1wa3eu0-placeholder , .css-1uccc91-singleValue{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
   }
   .css-1wa3eu0-placeholder{
     color:#949D9B !important; 
   }
   .css-1uccc91-singleValue{
     color: #51635D;
     text-transform:capitalize;
     top:50%;
   } 
   .css-2613qy-menu{
      color:#949D9B !important;
   }
   .select__indicators{
    img{
      width:18px;
      height:18px;
      margin-right: 15px;
    }
   }
   .select__control--is-focused{
    border: 1px solid #52C41A;
    box-shadow: unset;
  }
  .select__input{
    input{
      height:44px;
      margin-bottom:0;
    }
  }
  .css-26l3qy-menu{
   @media(max-height:800px){
    max-height:80px;
    overflow:auto;
    .select__menu-list{
      max-height:80px;
      overflow:auto;
    }
   }
   @media(min-height:800px) and (max-height:991px){
    max-height:120px;
    overflow:auto;
    .select__menu-list{
      max-height:120px;
      overflow:auto;
    }
   }
    
  }
  }
  .address-container{
    .title{
      font-weight: 700;
    }
  }
`
  ;

export default function CreateMealPlanVisitor() {
  const history = useHistory();
  const { settings, updateSettings, noNavigation, setDeliveryUnavailable, zip, setZip, value, setValue } = useVisitorContext();
  const [error, setError] = useState(null);
  const [days, setDays] = useState(7);
  const [meals, setMeals] = useState(3);
  // useEffect(() => {
  //   console.log({ days, meals });
  // }, [days, meals]);
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('New York City, Brooklyn');
  const [state, setState] = useState('New York');
  const [userAddressLatLong, setUserAddressLatLong] = useState([]);
  const { isMobile } = useAppContext();
  const mobInnerBox2 = isMobile ? 'inner-box2' : undefined;
  const toastId = useRef(null);
  const [getTaxRate] = useRequest();
  const [taxRate, setTaxRate] = useState(0);
  const [taxError, setTaxError] = useState(null);
  const [addressModal, setAddressModal] = useState(false);
  const [addressFormModal, setAddressFormModal] = useState(false);
  const [showAnim, setShowAnim] = useState(true);
  const [checked, setChecked] = useState(true);
  const [addressValue, setAddressValue] = useState(null);
  const [bringMealModal, setBringMealModal] = useState(false);
  const [getZip, setGetZip] = useState(false);
  const [type, setType] = useState('')
  const defaultForm = {
    address: {},
    apartment: '',
    type: 'home',
    customType: null,
    instruction: '',
  }
  const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    setDays(settings.days);
    setMeals(settings.meals);
    const { address, address2, zipcode, city, state, userAddressLatLong, addressType, address_Value } = settings.userInfo;
    setAddress(address);
    setUserAddressLatLong(userAddressLatLong);
    setAddress2(address2);
    setZip(zipcode);
    setState(state);
    setType(addressType);
    setAddressValue(address_Value);
  }, []);

  const handleClick = async () => {
    // Save Data in State or Local Storage
    if (!zip) {
      toastId.current = toast.error(`Couldn't find zip code for this address`);
      return setError('Please try to add zip code with your address.');
    }
    await updateSettings(
      produce((draft) => {
        draft.days = days;
        draft.meals = meals;
        draft.userInfo.userAddressLatLong = userAddressLatLong;
        draft.userInfo.address = address;
        draft.userInfo.address2 = address2;
        draft.userInfo.zipcode = zip;
        draft.userInfo.city = city;
        draft.userInfo.state = state;
        draft.userInfo.addressType = form.type;
        draft.userInfo.address_Value = addressValue;
      }),
    )

    if(taxError && !isMobile){
      return setBringMealModal(true);
    }
    setError(null);
    !noNavigation ? history.push('/visitor/providers') : history.push('/visitor/providers?navigation=false');
    window.scrollTo(0, 0);
  };
  const handleSelect = async (value) => {
     try {
    setGetZip(true);
    const [place] = await geocodeByPlaceId(value?.value?.place_id);
    const userLatLong = await getLatLng(place);
    setUserAddressLatLong(userLatLong);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) || {};
    const { long_name: state = '' } =
      place.address_components.find((c) => c.types.includes('administrative_area_level_1')) || {};
    const { long_name: city = '' } =
      place.address_components.find((c) => c.types.includes('locality')) ||
      place.address_components.find((c) => c.types.includes('sublocality_level_1')) ||
      {};
    setTaxRate(null);
    setTaxError(null);
    setCity(city);
    setState(state);
    setZip(postalCode);
    setAddress(value?.value?.structured_formatting?.main_text);
    setAddressValue(value);    
    postalCode && await getTaxRate({ path: `payment/tax/${postalCode}` }).then((res) => {
      if (res?.status === false) {
        setTaxError(res.message);
        setDeliveryUnavailable(true);
        console.log(res);
      }
      else {
        setTaxRate(res.rate);
        setDeliveryUnavailable(false);
        console.log(res);
      }
      setGetZip(false);
    }).catch((err) => {
      console.log('error..........', err);
    });
  } catch (error) {
      console.log(error)
  }
  };
  useEffect(() => {
    value && handleSelect(value);
  }, [value]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#51635D' : "#51635D",
      backgroundColor: state.isFocused ? "#ffffff" : "#ffffff",
      textAlign: 'left',
      fontSize: '14px',
      textTransform: 'capitalize'
    })
  }

  return (
    <MealformStyle>
      <div className="create-meal-form">
        <div className={`select-meals-days ${mobInnerBox2}`}>
          <SelectOptions
            id="meals"
            Icon="/icons/dinner-gray.svg"
            title="How many meals per day?"
            options={[2, 3, 4]}
            value={meals}
            setValue={setMeals}
            valueCondition={1}
            customOptionVal={[5, 9]}
          />
          <SelectOptions
            id="days"
            Icon="/icons/calendar-gray.svg"
            title={isMobile ? "For how many days?" : "What's your ideal plan length days?"}
            options={[4, 7, 12]}
            value={days}
            setValue={setDays}
            valueCondition={4}
            customOptionVal={[13, 31]}
          />
          {isMobile ?
            (<div className='no-user-div'><p className='no-user'> = {meals * days} meals </p> </div>)
            :
            (<h4>
              This will result in a plan of{' '}
              <span style={{ color: '#52c41a' }}> {meals * days} meals </span>
              <img src="/icons/food1.svg" alt="Food" className="foodi" />
              <img src="/icons/food2.svg" alt="Food" className="foodi" />
              <img src="/icons/food3.svg" alt="Food" className="foodi" />
            </h4>)}
        </div>
        <div className={mobInnerBox2}>
          <div className="address-wrapper">
            <div className="address-container">
              <div className="title">Where's it arriving?</div>
              {isMobile ? 
              <>
                <img src="/icons/location.svg" className="locationIcon" alt="" />
                <button
                  onClick={() => { setAddressModal(true), setShowAnim(true) }}
                  bordered
                  className='delivery-input'
                >
                  {!addressValue ? 'Delivery address' : addressValue?.label}
                </button>
              </>
              :
              <>
                <img src="/icons/location.svg" className="locationIcon" alt="" />
                <GooglePlacesAutocompleteStyle>
                  <GooglePlacesAutocomplete
                    GooglePlacesDetailsQuery={{ fields: 'geometry' }}
                    fetchDetails={true}
                    selectProps={{
                      value,
                      placeholder: address?.label || 'Delivery address',
                      noOptionsMessage: () => null,
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          height: '44x',
                          lineHeight: '44px',
                          fontSize: '14px',
                          lineHeight: '22px'
                          // paddingLeft: '15px',
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: 'none',
                          height: '47px',
                          lineHeight: '47px',
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          height: '47px',
                          lineHeight: '47px',
                          display: 'none',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          height: '47px',
                          lineHeight: '40px',
                          top: '50%',
                          paddingLeft: '15px',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          top: '50%',
                          marginTop: '0px',
                          paddingLeft: '15px',
                          height: '47px',
                          lineHeight: '37px',
                        }),
                      },
                      onChange: setValue,
                    }}
                  />
                </GooglePlacesAutocompleteStyle>
              </>
              }
            </div>
          </div>
          <div className="button-wrapper">
            <button
              className={classNames({
                disabled: !addressValue?.label || getZip,
              })}
              disabled={!addressValue?.label || getZip}
              onClick={handleClick}
            >
              Next
            </button>
          </div>
          {error && <ErrorComponent error={error} />}
        </div>
      </div>
      {addressModal &&
      <AddressDeliveryModal
        addressModal={addressModal}
        setAddressModal={setAddressModal} 
        value={value} 
        setValue={setValue}
        getZip={getZip}
        form={form}
        setForm={setForm}
        addressFormModal={addressFormModal}
        setAddressFormModal={setAddressFormModal}
        handleClick={handleClick}
        checked={checked}
        setChecked={setChecked}
        isLoading={false}
        zip={zip}
        setZip={setZip}
        taxRate={taxRate}
        setTaxRate={setTaxRate}
        taxError={taxError}
        bringMealModal={bringMealModal}
        setBringMealModal={setBringMealModal}
        showAnim={showAnim}
        setShowAnim={setShowAnim}
        />}
        {
              bringMealModal && !isMobile &&
              <BringMealPrepModal
              bringMealModal={bringMealModal}
              setBringMealModal={setBringMealModal}
               setAddressModal={setAddressModal}
               addressModal={addressModal}
              value={value}
              zip={zip}
              setShowAnim={setShowAnim}
              />
            }
    </MealformStyle>
  );
}



