import React from 'react';

const Eggs = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_4095_11310)">
                <path d="M16.3567 2.27707C14.0414 0.368048 10.9946 -0.390824 7.99789 0.19177C4.08726 0.950642 0.9505 4.08736 0.191627 7.99799C-0.390967 10.9947 0.369156 14.0415 2.27697 16.3568C4.18603 18.6722 7.00023 19.9999 9.99822 19.9999C11.746 19.9999 13.195 18.6885 13.37 16.9494L13.5213 15.4367C13.6226 14.4278 14.4277 13.6226 15.4353 13.5226L16.9481 13.3714C18.6884 13.1951 19.9998 11.7461 19.9998 9.99831C19.9998 7.00033 18.6721 4.18613 16.3567 2.27707ZM16.8243 12.1262L15.3116 12.2774C13.7126 12.4362 12.4361 13.7127 12.2773 15.3117L12.1261 16.8244C12.016 17.9221 11.1009 18.7497 9.99822 18.7497C7.37529 18.7497 4.91239 17.587 3.24212 15.5617C1.57185 13.5364 0.907994 10.866 1.41808 8.23553C2.08068 4.82123 4.82113 2.08079 8.23543 1.41818C8.81678 1.30566 9.39937 1.2494 9.97696 1.2494C12.0135 1.2494 13.9839 1.94076 15.5616 3.24097C17.5869 4.91249 18.7496 7.37539 18.7496 9.99831C18.7496 11.101 17.922 12.0162 16.8243 12.1262Z" fill={color || "#757E89"} />
                <path d="M8.74806 4.99756C6.68024 4.99756 4.99748 6.68035 4.99748 8.74819C4.99748 10.816 6.68024 12.4988 8.74806 12.4988C10.8159 12.4988 12.4987 10.816 12.4987 8.74819C12.4987 6.68031 10.8159 4.99756 8.74806 4.99756ZM8.74806 11.2486C7.3691 11.2486 6.24767 10.1272 6.24767 8.74819C6.24767 7.36921 7.3691 6.24777 8.74806 6.24777C10.127 6.24777 11.2485 7.36921 11.2485 8.74819C11.2485 10.1272 10.127 11.2486 8.74806 11.2486Z" fill={color || "#757E89"} />
                <path d="M8.74808 6.87287C7.71417 6.87287 6.87278 7.71425 6.87278 8.74817C6.87278 9.09323 7.15283 9.37327 7.49788 9.37327C7.84294 9.37327 8.12298 9.09323 8.12298 8.74817C8.12298 8.40312 8.40303 8.12307 8.74808 8.12307C9.09314 8.12307 9.37319 7.84303 9.37319 7.49797C9.37319 7.15287 9.09314 6.87287 8.74808 6.87287Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_4095_11310">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Eggs;
