import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../shared/theme/theme';

const TabContainer = styled.div`
  display: flex;
  gap: ${({ type }) => (type === 'horizontal' ? '8px' : '')};
  flex-direction: ${({ type }) => (type === 'horizontal' ? '' : 'column')};
  width: ${({ type }) => (type === 'horizontal' ? '' : '100%')};
  position: relative;
  text-wrap: nowrap;
  min-width:${({ type }) => (type === 'horizontal' ? '' : '120px')};
  z-index:1;
`;

const TabButton = styled.button`
  padding: ${({ type }) => type === 'horizontal' ? '6px 16px' : '6px 24px 6px 8px'};
  background-color: ${theme.colors.white};
  color: ${({ isActive }) => isActive ? theme.colors.primary : theme.colors.black+"80"};
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid #333' : 'none')};
  cursor: pointer;
  outline: none;
  border-radius: 8px;
  text-align: ${({ type }) => (type === 'horizontal' ? '' : 'left')};
  min-width: 0px !important;
  position: relative;
  z-index: 2;
`;

const Text = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const TabIndicator = styled.div`
  position: absolute;
  left: ${({ type, indicatorPosition }) => type === 'horizontal' ? `${indicatorPosition}%` : 0};
  width: ${({tabBounding, type}) => type === 'horizontal' ? `${tabBounding?.width}px` : '100%'};
  min-width: ${({tabBounding, type}) => type === 'horizontal' ? `${tabBounding?.width}px` : '100%'} !important;
  top: ${({ type, indicatorPosition }) => type === 'horizontal' ? 0 :`${indicatorPosition}%`};
  height: ${({type}) => type === 'horizontal' ? '100%' : '30px'};
  background-color: ${({theme, selectedTabBgColor}) => selectedTabBgColor ? selectedTabBgColor : theme?.colors?.blackLightest};
  border-radius: 8px;
  transition: all 0.3s;
  z-index: 1;
`;


const Tab = ({ navItems, selectedTabId, setSelectedTabId, type, selectedTabBgColor }) => {
    const demoItems = [
      {
        id: 0,
        title: 'Tab name',
        onClick: () => {},
      },
      {
        id: 1,
        title: 'Tab name',
        onClick: () => {},
      },
      {
        id: 2,
        title: 'Tab name',
        onClick: () => {},
      },
      {
        id: 3,
        title: 'Tab name',
        onClick: () => {},
      },
    ];
  
    const navLinks = navItems || demoItems;
    const tabContainerRef = useRef();
    const [indicatorPosition, setIndicatorPosition] = useState(0);
    const [tabBounding, setTabBounding] = useState();
  
    useEffect(() => {
        const selectedTab = tabContainerRef.current.querySelector('.active');
        if (selectedTab) {
          const tabRect = selectedTab.getBoundingClientRect();
          const containerRect = tabContainerRef.current.getBoundingClientRect();
          let newPosition;
            if(type === 'horizontal'){
              newPosition = ((tabRect.left - containerRect.left) / containerRect.width) * 100;
            } else{
              newPosition = ((tabRect.top - containerRect.top) / containerRect.height) * 100;
            }
          setTabBounding(tabRect);
          setIndicatorPosition(newPosition);
        }
      }, [selectedTabId]);
              
    const handleSelected = (id) => {
      setSelectedTabId(id);
    };
  
    return (
        <div>
          <TabContainer type={type} className="tab_container" ref={tabContainerRef} theme={theme}>
            {navLinks?.map((item) => {
              return (
                <TabButton
                  onClick={() => {
                    handleSelected(item.id);
                    item.onClick && item.onClick();
                  }}
                  isActive={item.id === selectedTabId}
                  type={type}
                  key={item.id}
                  theme={theme}
                  className={`nav_item ${(item.id === selectedTabId) && 'active'}`}
                >
                  <Text className="text">{item.title}</Text>
                </TabButton>
              );
            })}
            <TabIndicator className='tab-indicator' type={type} tabBounding={tabBounding} indicatorPosition={indicatorPosition} theme={theme} selectedTabBgColor={selectedTabBgColor}/>   
          </TabContainer>
        </div>
      );
    };
    
  export default Tab;