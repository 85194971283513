import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import { dayHours, groupBy } from 'utils';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useRequest } from 'hooks/useRequest';
import Skeleton from '@mui/material/Skeleton';
import CustomTabPanel from '../../components/CustomTabPanel';
import HomeTabItem from 'pages/KitchenMobile/components/HomeTabItem';
import SingleLineChart from 'components/elements/charts/SingleLineChart';
import { HomeWrapper } from './style';

const saleToDate = ['today', 'yesterday', 'weekToDate', 'monthToDate'];

export default function Home() {
  const [value, setValue] = useState(0);
  const [salesData, setSalesData] = useState({});
  const [slideIndex, setSlideIndex] = useState(0);
  const [getSalesData, { isLoading: gettingSales }] = useRequest();
  const tabContainerRef = useRef();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const weekDays = moment.weekdays().map((day) => day.slice(0, 3));
  const arrayDaysOfCurrentMonth = Array.from({ length: moment().daysInMonth() }, (_, i) => i + 1);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    afterChange: (index) => setSlideIndex(index),
  };

  const getProviderSales = async (value) => {
    await getSalesData({
      path: `provider/business-sales?saleFor=${saleToDate[value]}`,
      method: 'get',
    })
      .then((data) => {
        setSalesData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProviderSales(value);
  }, [value]);

  const chartData = useMemo(() => {
    if (!!salesData?.providerOrderData || !!salesData?.storeVisitData) {
      const formatValues = ((
        slideIndex === 2 && (value === 0 || value === 1)
          ? salesData?.storeVisitData
          : salesData?.providerOrderData
      ) ?? [])?.map((day) => ({
        name:
          value === 2
            ? moment(day?.month).format('ddd')
            : value === 3
            ? Number(moment(day.date).format('DD'))
            : slideIndex === 2
            ? `${moment(day?.time).format('H')}:00`
            : `${moment(day?.createdAt).format('H')}:00`,
        sales:
          (value === 2 || value === 3) && slideIndex === 1
            ? day?.orderCount
            : (value === 2 || value === 3) && slideIndex === 2
            ? day?.storeVisitsCount
            : (value === 2 || value === 3) && slideIndex === 0
            ? Number(day?.saleAmount?.toFixed(2))
            : Number(day?.saleAmount),
      }));
      const groupedData = (value === 0 || value === 1) && groupBy(formatValues, 'name');
      let handleFormatDay;
      if (slideIndex === 0 && (value === 0 || value === 1)) {
        const calculateValues = (list) => list.reduce((acc, item) => acc + item.sales, 0);
        const mergedDataSales = Object.keys(groupedData).map((item) => ({
          name: item,
          sales: Number(calculateValues(groupedData[item])?.toFixed(2)),
        }));
        handleFormatDay = mergedDataSales?.length ? mergedDataSales : [{ name: '', sales: 0 }];
      }
      if ((slideIndex === 1 || slideIndex === 2) && (value === 0 || value === 1)) {
        const mergedDataOrders = (Object.values(groupedData) ?? [])?.map((item) => ({
          name: item[0].name,
          sales: item.length,
        }));
        handleFormatDay = mergedDataOrders?.length ? mergedDataOrders : [{ name: '', sales: 0 }];
      }

      return value === 2 || value === 3 ? formatValues : handleFormatDay;
    }
  }, [salesData?.providerOrderData, salesData?.storeVisitData, slideIndex]);

  const fullChartData = useMemo(() => {
    if (chartData?.length) {
      const defaultData = Object.fromEntries(
        (value === 3 ? arrayDaysOfCurrentMonth : value === 2 ? weekDays : dayHours())?.map(
          (item) => [item, { name: item, sales: 0 }],
        ),
      );
      const mergedData = chartData.reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc;
      }, defaultData);
      const mergedDataArray = Object.values(mergedData) ?? [];
      const dataWithNoChart = mergedDataArray.slice(0, mergedDataArray?.length - 1);
      return chartData[0].name?.length === 0 ? dataWithNoChart : mergedDataArray;
    }
  }, [chartData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const selectedTab = tabContainerRef.current.querySelector('.MuiTabs-scroller');
    if (value === 0) {
      selectedTab.scrollTo({ left: 0, behavior: 'smooth' });
    }
    if (value === 3) {
      selectedTab.scrollTo({ left: selectedTab?.offsetWidth, behavior: 'smooth' });
    }
  }, [value]);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  return (
    <HomeWrapper>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        ref={tabContainerRef}
      >
        <Tab label="Today" />
        <Tab label="Yesterday" />
        <Tab label="Week to date" />
        <Tab label="Month to date" />
      </Tabs>
      <Slider
        asNavFor={nav2}
        ref={slider1}
        className="center"
        slidesToShow={1}
        centerMode={true}
        focusOnSelect={true}
        swipeToSlide={true}
        speed={800}
        {...settings}
      >
        <div className="tabs-count-wrapper">
          <HomeTabItem
            name="Sales"
            total={
              salesData?.totalSaleAmount > 0
                ? `$${Number(salesData?.totalSaleAmount)?.toFixed(2)}`
                : `$0`
            }
            gettingSales={gettingSales}
          />
        </div>
        <div className="tabs-count-wrapper">
          <HomeTabItem
            name="Total orders"
            total={salesData?.totalOrderCount}
            gettingSales={gettingSales}
          />
        </div>
        <div className="tabs-count-wrapper">
          <HomeTabItem
            name="Store visiting"
            total={salesData?.totalStoreVisits}
            gettingSales={gettingSales}
          />
        </div>
      </Slider>
      <Slider asNavFor={nav1} ref={slider2} slidesToShow={1} swipeToSlide={true} {...settings}>
        {Array.from({ length: 3 }).map((_, i) => (
          <div>
            {!gettingSales ? (
              <CustomTabPanel value={value} index={value}>
                <SingleLineChart
                  isDataLoading={gettingSales}
                  chartData={fullChartData}
                  chartWidth={window.innerWidth - 16}
                  slideIndex={slideIndex}
                  value={value}
                />
              </CustomTabPanel>
            ) : (
              <div className="skeleton-chart-wrapper">
                <Skeleton variant="text" width={window.innerWidth - 32} height={278} />
              </div>
            )}
          </div>
        ))}
      </Slider>
    </HomeWrapper>
  );
}