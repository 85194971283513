import React from 'react'
import styled from 'styled-components';
// import { ReactComponent as MealImage } from 'assets/shop/image 5.svg';
import { Button } from 'components/elements/button/Button';
import { useHistory } from 'react-router-dom';
import {usenavigate} from 'react-router-dom'

const TrackerQuizStyle = styled.div`
background: #fff;
height: calc(100vh - 70px);
padding: 36px 23px 29px 25px;
.container{
    display: flex;
    padding: 39px 23px 35px 24px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    background: #F5F7F8;
.main-head{
    display: flex;
    gap: 8px;
    flex-direction: column;
    .title{
        color: #000;
        font-family: Gilroy;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px; 
    }
    .desc{
        color: #5B5C60;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: break-spaces;
    }
}
.custom_button {
    width: 100%;
    padding: 12px 16px;
  }
}
`

export default function TrackQuiz() {
    const history = useHistory();
    return (
        <TrackerQuizStyle>
        <div className='container'>
         <div className='main-head'>
            <h2 className='title'>Track your macros and reach your health goals.</h2>
            <p className='desc'>Nutritt intuitive tracker make it easy to monitor your daily nutrient intake and help you make informed choices about what you eat.</p>
         </div>
         <div className='image'>
            <img src='/images/mealImage.jpg' style={{borderRadius: '100px'}}></img>
         </div>
         <div className='custom_button'>
         <Button title='Take quiz' type="mobile_save" onClick={() => { history.push( '/shop/account/health-goal', { from: "/shop/track-quiz" }) }}/>
         </div>
        </div>
        </TrackerQuizStyle>
    )
}