import styled from 'styled-components';
import moment from 'moment';
import { useAppContext } from 'context/appContext';

const ProfileCardStyle = styled.div`
    width: 335px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 4px 10px #0000000d;
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    // margin-top:42px;

    .registered-title {
      margin-left: 21px;
      margin-top : 10px;
      h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 35px;
        margin-top : 10px;
        color: #52C41A;
      }

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        // padding: 2px;
        color: #51635D;
      }

      .social-image-start {
          border-radius:0px 10px 10px 0px;
      }
      
      img {
          text-align: center;
          padding-top: 32px;
          padding-left: 9px;
      }
    }

    @media (min-width: 992px) {
      margin-top: 53px;
    }
  `;

export default function ProfileCard({text, description,endTime, arrow, time}) {
  const { user ,isMobile} = useAppContext();

  const renderClass=()=>{
    if(isMobile){
      return 'arrow-wrapper';
    } else{
      return "arrow-wrapper-desktop";
    }
    
  }

    return (
      <ProfileCardStyle>
        <div className="registered-title">
          <h3>{text}</h3>
            {!endTime ? (
            <span className='descript'>{description}</span>
            ): (
            <span>
            <p>{moment(time).format('MMMM D, YYYY | HH:mm')}</p>
            {"  "}
            {description}
            {"  "}
            <p>{endTime}</p>
            </span>
            )}
          {arrow &&
          <div className={user ? 'social-image-start' : renderClass()}>
            <img src='/icons/right-arrow.svg' alt="right-arrow" />
          </div>
          }
        </div>
      </ProfileCardStyle>
    );
}
