import React, { useState } from 'react';
import styled from 'styled-components';
import MobileHeader from '../MobileHeader';
import SearchMeal from 'components/Pantry/SearchMeal';
import { AnimatePresence, motion } from 'framer-motion';

const BarcodeSearchResultStyle = styled.div`
  background-color: #fff;
  position: fixed;
  inset: 0;
  z-index: 9;
  .header {
    background-color: #fff;
  }
  .search__result{
    padding-left: 16px;
    padding-right: 16px;
    flex: 1;
    .border{
      display: none;
    }
  }
  .flex-column{
    display: flex;
    flex-direction: column;
    min-height: ${window.innerHeight + 'px'};
  }
  .done-btn-wrapper{
    position: relative;
  }
`;

const BarcodeSearchResult = ({
  memorizedMeals,
  searchValue,
  setIsMacrosModal,
  setMealInfo,
  moveMealFormSearch,
  mealId,
  disabled,
  setFoodId,
  handleMacrosBtnClick,
  setMealMacroInfo,
  setSearchedFoodDetails,
  searchedFoodDetails,
  mealMacroInfo,
  form,
  setForm,
  setLogMealFromList,
  showServingInfo,
  setShowServingInfo,
  isFoodDetailsLoading,
  setIsFoodDetailsLoading,
  setSearchQuantity,
  handleMealClick,
  openMealIndex,
  currentDayinfo,
  setBarCodeResultModal,
  setResultMeals,
  setIsLogMealsModal,
  setSelectedSliderIndex
}) => {
  const [accessMealDetails, setAccessMealDetails] = useState(false);

  return (
  <BarcodeSearchResultStyle>
    <AnimatePresence>
    <motion.div
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, type: 'spring' }}
    >
      <div className='flex-column'>
        <div className="header">
          <MobileHeader
            text={'Log food'}
            onClickLeft={() => {
              setBarCodeResultModal(false);
              setResultMeals([]);
            }}
            onCloseRight={() => {
              setIsLogMealsModal(false);
            }}
          />
        </div>
        <div className="search__result">
          {memorizedMeals?.map((meal, index) => (
            <SearchMeal
              key={meal?.food_id}
              meal={meal}
              searchValue={searchValue}
              setIsMacrosModal={setIsMacrosModal}
              setMealInfo={setMealInfo}
              moveMealFormSearch={moveMealFormSearch}
              mealId={mealId}
              disabled={disabled}
              setFoodId={setFoodId}
              handleMacrosBtnClick={handleMacrosBtnClick}
              setMealMacroInfo={setMealMacroInfo}
              setSearchedFoodDetails={setSearchedFoodDetails}
              searchedFoodDetails={searchedFoodDetails}
              mealMacroInfo={mealMacroInfo}
              form={form}
              setForm={setForm}
              setLogMealFromList={setLogMealFromList}
              showServingInfo={showServingInfo}
              setShowServingInfo={setShowServingInfo}
              isFoodDetailsLoading={isFoodDetailsLoading}
              setIsFoodDetailsLoading={setIsFoodDetailsLoading}
              setSearchQuantity={setSearchQuantity}
              handleMealClick={handleMealClick}
              openMealIndex={openMealIndex}
              index={index}
              currentDayinfo={currentDayinfo}
              logMealBarcode={accessMealDetails}
              setAccessMealDetails={setAccessMealDetails}
              setSelectedSliderIndex={setSelectedSliderIndex}
            />
          ))}
        </div>
        <div className="done-btn-wrapper">
          <button onClick={() =>{
              setAccessMealDetails(true);
              setLogMealFromList(true);
              setTimeout(() => {
                setBarCodeResultModal(false);
                setResultMeals([])
              }, 100)
          }}>
            Log
          </button>
        </div>
      </div>
    </motion.div>
    </AnimatePresence>
  </BarcodeSearchResultStyle>
  );
};

export default BarcodeSearchResult;
