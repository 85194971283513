import React, { memo, useState, useEffect, useMemo, useCallback } from 'react'
import { theme } from 'shared/theme/theme';
import Alert from 'components/elements/alert/Alert';
import DropDown from 'components/elements/drop-down/Dropdown'
import RegularInput from 'components/Input/RegularInput';
import Checkbox from '@mui/material/Checkbox';
import Toggle from 'components/elements/toggleModule/Toggle';
import Tooltip from 'rc-tooltip';
import { motion, AnimatePresence } from 'framer-motion';
import { useRequest } from 'hooks/useRequest';
import OrdersPagination from './OrdersPagination';
import { Modal } from 'components/elements/modal/Modal';
import { useAppContext } from 'context/appContext';
import { OrderActionModal, OrderTableWrapper } from 'pages/provider/storefront/orders/style';

function OrderTable({ tab, totalNumber, totalOrdersCount, orderDataPageNumber, children, setOrderDataPageNumber, onChange, totalPages,orderNumber, setSortBy, setSort, isCheckAll, setIsCheckAll, updateSelectedAll, orders, fetchOrder, activeTab, sort, sortBy, orderFound,isOrdersApiCallProcessing, isSplit, setIsSplit, setIsFilters, imitateProviderId, filterQuery, setFilterQuery, isFilters}) {
    const [totalPageNum, setTotalPageNum] = useState(totalPages);
    const [acceptSelectedOrder,{isLoading}] = useRequest(`fulfill`, 'post');
    const [isOpen, setIsOpen] = useState(false);
    const itemsPerPage = 50;
    const startItem = (orderDataPageNumber - 1) * itemsPerPage + 1;
    const endItem = Math.min(startItem + itemsPerPage - 1, totalOrdersCount);
    const {isSuper} = useAppContext();
    const defaultOptions = [
        { value: 'asc', label: 'Sort Ascending', imgSrc: '/icons/arrow-up-black.svg' },
        { value: 'desc', label: 'Sort Descending', imgSrc: '/icons/arrow-down-black.svg' }
    ];
    const icon = "/icons/Right-Icon.svg";
    const [alertData, setAlertData] = useState({
        type: '',
        title: '',
      });

    const showSuccessMessage = (title) => {
        setAlertData({
        type: 'success',
        title: title,
        });
        setTimeout(() => {
        setAlertData({ type: '', title: '' });
        }, 5000);
    };
    
    const showErrorMessage = (title) => {
        setAlertData({
        type: 'error',
        title: title,
        });
        setTimeout(() => {
        setAlertData({ type: '', title: '' });
        }, 5000);
    };

    const orderStatusAction = (status) => {
        let allSelected = [];
        allSelected = orders?.filter((order) => order.selected == true);
        return allSelected?.length && allSelected?.every((order) => {
            const index = order?.orderStatus?.findIndex((item) => item?.providerId === order?.providers[0]?.Provider?.id);
           return  order?.orderStatus[index]?.action === status;
        });
    }

    const selectedOrdersAction = useMemo(() => {
        if (orderStatusAction('pending')) {
            return 'pending';
        }
        if (orderStatusAction('accepted')) {
            return 'accepted';
        }
        return false;
    }, [orders]);

    const countSelectedOrders = useMemo(() => {
        let allSelected = [];
        allSelected = orders?.map((order) => order.selected);
        const countSelected = allSelected?.filter((order) => order === true).length;
        return countSelected;
    }, [orders]);

    const handleSave = useCallback(async (status) => {
        if(isLoading) return ;
        try {
            let allSelected = [];
            allSelected = orders?.filter((order) => order.selected === true);
            const providerId = allSelected[0]?.providers[0]?.Provider.id;
            const ordersId = allSelected.map((order) => order.orderMainId);
            const result = await acceptSelectedOrder({ body: { newStatus: status, providerId ,orderIds:ordersId} });
            if (result) {
                setTimeout(() =>  showSuccessMessage(`You have successfully ${status} the new order`), 1000);
                fetchOrder(activeTab,orderDataPageNumber,orderNumber, sort, sortBy);
            } else {
                setTimeout(() =>  showErrorMessage('Error occurred while saving changes'), 1000);
            }
            setIsOpen(false);
        } catch (e) {
            console.log(e,"error")
            showErrorMessage(e);
            setIsOpen(false);
        }
    }, [orders,isLoading]);

    const handleCancel = () => {
        if (isCheckAll) {
            updateSelectedAll(!isCheckAll);
            setIsCheckAll(!isCheckAll);
        } else {
            updateSelectedAll(isCheckAll);
        }
    }

    useEffect(() => {
        setTotalPageNum(totalPages);
    }, [totalPages]);

    return (
        <OrderTableWrapper>
            <div className="container">
            <AnimatePresence>
            {countSelectedOrders > 0 ?
                <motion.div
                    className='selected-orders-motion'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                <div className='selected-items-Wrapper'>
                    <div className='selected-items'>{countSelectedOrders} {countSelectedOrders > 1 ? 'items' : 'item'} selected</div>
                    <div className="selected-actions-wrapper">
                        {selectedOrdersAction === 'pending'  && (
                            <div className="order-action">
                            <Tooltip
                                overlayClassName="kitchen-orders-tooltip"
                                placement="top"
                                overlay='Accept the order'
                                arrowContent={<div />}
                            >
                            <div className="action-wrapper" onClick={() => handleSave("accepted")}>
                                <img src="/icons/receipt-right-black.svg" alt="Receipt Icon" />
                            </div>
                            </Tooltip>
                            <Tooltip
                                overlayClassName="kitchen-orders-tooltip"
                                placement="top"
                                overlay='Reject the order'
                                arrowContent={<div />}
                            >
                            <div className="action-wrapper" onClick={() => setIsOpen(true)}>
                                <img src="/icons/receipt-remove.svg" alt="Receipt Icon" />
                            </div>
                            </Tooltip>
                            </div>
                        )}
                        {selectedOrdersAction === 'accepted' && (
                            <div className="order-action">
                            <Tooltip
                                overlayClassName="kitchen-orders-tooltip"
                                placement="top"
                                overlay='Complete the order'
                                arrowContent={<div />}
                            >
                            <div className="action-wrapper" onClick={() => handleSave('completed')}>
                                <img src="/icons/receipt-edit-black.svg" alt="Receipt Icon" />
                            </div>
                            </Tooltip>
                            </div>
                        )}
                        <Tooltip
                            overlayClassName="kitchen-orders-tooltip"
                            placement="top"
                            overlay='Print order details'
                            arrowContent={<div />}
                        >
                            <a href={`/kitchen/orders/print${isSuper ? `?providerId=${imitateProviderId}` : ''}`} target="_blank">
                                <div className="action-wrapper" onClick={() => {
                                    localStorage.setItem('checkedOrders', JSON.stringify(orders?.filter((order) => order.selected)));
                                    if (localStorage.getItem("orderDetails")) localStorage.removeItem('orderDetails');
                                }}>
                                    <img src="/icons/printer.svg" alt="Printer Icon" />
                                </div>
                            </a>
                        </Tooltip>
                        <div className='divider' />
                        <div className='cancel-button' onClick={handleCancel}>Cancel</div>
                    </div>
                </div>
                </motion.div>
                :
                <>
            <div className='search-filter-wrapper'>
            {onChange &&  <RegularInput className="search-input" width="270px" prefix={<img src='/icons/search-input2.svg' alt=" Search Icon" />} value={orderNumber} placeholder="Search for orders" onChange={onChange} />}
            <div className={`filter-wrapper ${isFilters ? 'displayFilter' : ''} ${filterQuery?.length ? 'filterWithConditions' : ''}`} onClick={() => setIsFilters(true)}>
                <img src='/icons/filter-lines.svg' alt='Filter Icon' />
                <div className='label'>Filters</div>
                {!!filterQuery?.length && 
                    <>
                        <div className='filters-counts'>{filterQuery?.split('filterType')?.length - 1}</div>
                        <div className='filter-img-wrapper'><img src='/icons/close-gray.svg' alt='Close Icon' onClick={(e) => {
                            e.stopPropagation();
                            setFilterQuery('');
                            setIsSplit(false);
                        }} /></div>
                    </>
                }
            </div>
            </div>
            <div className='pagination-split-order'>
                <OrdersPagination startItem={startItem} endItem={endItem} totalCount={totalOrdersCount} isOrdersApiCallProcessing={isOrdersApiCallProcessing} setOrderDataPageNumber={setOrderDataPageNumber} orderDataPageNumber={orderDataPageNumber} totalPages={totalPages} setIsSplit={setIsSplit} />
                <Tooltip
                    overlayClassName="kitchen-orders-tooltip"
                    placement="top"
                    overlay='Toggle split panel mode'
                    arrowContent={<div />}
                >
                    <div className='sandwich-icon-wrapper' onClick={() => setIsSplit(!isSplit)}>
                        <img src='/icons/sandwich-split.svg' alt="Sandwich Icon" />
                    </div>
                </Tooltip>
            </div>
            </>
            }
            </AnimatePresence>
            </div>
            <div className="heading-div">
            <div className="heading">
                    <p>
                        <Checkbox
                            className="header-checkbox"
                            icon={<Toggle type="checkbox" size={'18px'} />}
                            checked={isCheckAll}
                            indeterminate={countSelectedOrders > 0 && countSelectedOrders < orders?.length}
                            onChange={() => {
                                setIsCheckAll(!isCheckAll);
                                updateSelectedAll(!isCheckAll);
                            }}
                            disableRipple={true}
                        />
                        Order ID
                    </p>
                    <p>Order at <DropDown onChange={(value) => {setSortBy('orderedAt'), setSort(value), setIsSplit(false)}}  icon={icon} option={defaultOptions}/></p>
                    <p>{tab === 'Completed' ? 'Delivered at' : 'Scheduled for'} <DropDown onChange={(value) => {setSortBy('scheduledFor'), setSort(value), setIsSplit(false)}} icon={icon} option={defaultOptions}/></p>
                    <p>Order status</p>
                    <p>Customer</p>
                    <p>Total <DropDown onChange={(value) => {setSortBy('providerPayout'), setSort(value), setIsSplit(false)}} icon={icon} option={defaultOptions}/></p>
                    <p>Items</p>
                </div>
            </div>
            {orderFound ? <div className='order-found-wrapper'>{orderFound}</div> : children}
            {alertData.type && (
                <div className="alert">
                    <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', title: '' })} />
                </div>
            )}
            {isOpen && (
                <OrderActionModal>
                    <Modal
                        description={
                            'Are you sure you want to reject this order?'
                        }
                        width="500px"
                        paddingBottom='30px'
                        paddingTop='4px'
                        color={theme.colors.blackDefault}
                        isOpen={isOpen}
                        onOk={() =>  handleSave("refused")}
                        onClose={() => setIsOpen(false)}
                        onCancel={() => setIsOpen(false)}
                        title={'Reject the order'}
                        primaryText={`Reject order`}
                        secondaryText={'Cancel'}
                        isBackdropEnabled={true}
                        buttonType={'dark'}
                        type="null"
                    />
                </OrderActionModal>
            )}
        </OrderTableWrapper>
    )
}

export default memo(OrderTable);