const { useRequest } = require('hooks/useRequest');
import React, { useState } from 'react';
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  margin-left: 300px;
  padding: 12px 70px;
`;

const ClaimNutritionistAccount = () => {
  const [email, setEmail] = useState('');
  const [inviteNutritionist, { isLoading, error, state: success, setState: setSuccess }] =
    useRequest(`nutritionist/invite`, 'post');
  const toastId = React.useRef(null);
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const nutritionistId = imitateNutritionist.id;
  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    const res = await inviteNutritionist({ body: { email, nutritionistId } });
    if (!error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(`Successfully sent invite to ${email}`);
      }
    }
  };
  return (
    <Container>
      <form onSubmit={handleInviteSubmit}>
        <h1>Invite nutritionist</h1>
        <Input
          type="email"
          label="Email address"
          required
          bordered
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus={true}
        />
        <SubmitButton
          disabled={isLoading || !email}
          style={{ marginTop: '10px' }}
          type="submit"
          error={error}
        >
          {isLoading ? 'Sending' : 'Send Invite'}
        </SubmitButton>
      </form>
      <ToastContainer />
    </Container>
  );
};

export default ClaimNutritionistAccount;
