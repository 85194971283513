import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { ReactComponent as RadioOutline } from 'assets/radio-outline.svg';
import { ReactComponent as RadioOutlineChecked } from 'assets/radio-outline-checked.svg';

const alignCenter = `
    justify-content: center;
    align-items: center;
    display: flex;
`

const CheckboxCardStyle = styled.div`
  height: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '74px' : icon ? '60px' : '68px'};
  display: flex;
  gap: ${({secondarySubtitle}) => secondarySubtitle ? '8px' : '16px'};
  border-radius: 34px;
  border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
  background: ${({ theme }) => theme?.colors?.white};
  align-items: center;
  padding-left: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '18px' : icon ? '12px' : '30px'};
  padding-right: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '18px' : icon ? '16px' : '18px'};
  transition: all 0.3s;
  &.checked {
    background: ${({ theme }) => theme?.colors?.grayLighter};
    border: 1px solid ${({ theme, icon, secondarySubtitle }) => icon && secondarySubtitle ? theme?.colors?.grayLight : icon ? theme?.colors?.grayLighter : theme?.colors?.grayLight};
    .icon {
      background-color: ${({ theme }) => theme?.colors?.white};
    }
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    ${alignCenter};
    transition: all 0.3s;
    span {
      width: 24px;
      height: 24px;
      ${alignCenter};
      > * {
        width: 100%;
      }
    }
  }
  .number-circle{
    width: 14px;
    height: 14px;
    transform: translate(22px,11px);
    position: absolute;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
    background-color: ${({ theme }) => theme?.colors?.white};
    span{
      color: ${({ theme }) => theme?.colors?.lighterGrey};
      font-family: ${({ theme }) => theme?.typography?.fontGilroy};
      font-size: ${({ theme }) => theme?.typography?.fontMobile};
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.22px;
      line-height: 15px;
    }
  }
  .content {
    flex: 1;
    .title {
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
      height: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '24px' : icon ? '16px' : '24px'};
      font-weight: 600;
      line-height: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '24px' : icon ? '20px' : '24px'};
      margin-bottom: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '0px' : icon ? '4px' : '0px'};
    }
    .subtitle {
      color: ${({ theme }) => theme?.colors?.primaryDarker};
      font-size: ${({ theme }) => theme?.typography?.fontMobile};
      font-weight: 500;
      margin-bottom: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '-1px' : '0px'};
      line-height: ${({ icon, secondarySubtitle }) => icon && secondarySubtitle ? '11px' : '12px'};
      letter-spacing: 0.22px;
    }
    .secondarySubtitle{
      height: 24px;
      line-height: 22px;
    }
  }
  .checkbox{
    width: 20px;
    .svg_icon{
        display: block;
    }
  }
`;

const SingleRowCheckbox = styled.div`
  border-radius: 50px;
  border: 1px solid ${({theme}) => theme?.colors?.grayLight};
  background: ${({theme}) => theme?.colors?.white};
  height: 50px;
  padding: 8px 16px 8px 10px;
  padding-left: ${({icon}) => icon ? '10px':'21px'};
  ${alignCenter};
  gap: 16px;
  transition: all 0.3s;
  &.checked {
    background: #F5F7F8;
    border: 1px solid #F5F7F8;
    .icon {
      background-color: ${({ theme }) => theme?.colors?.white};
    }
    .checkbox{
      .svg_icon{
        path{
          fill: ${({ theme }) => theme?.colors?.primaryDefault};
        }
      }
    }
  }
  .icon {
    width: 34px;
    height: 34px;
    background-color: ${({ theme }) => theme?.colors?.grayLighter};
    border-radius: 50px;
    ${alignCenter};
    transition: all 0.3s;
    span {
      width: 20px;
      height: 20px;
      ${alignCenter};
      > * {
        width: 100%;
      }
    }
  }
  .content{
    flex: 1;
    .title{
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme?.colors?.primaryDarker};
    }
  }
  .checkbox{
    width: 20px;
    .svg_icon{
      display: block;
      path{
        fill: ${({ theme }) => theme?.colors?.primaryLight};
      }
    }
  }
`

export const CheckboxCard = ({ type, disabled=false, title, subtitle, secondarySubtitle, icon, activeIcon, value, radio, checked = false, onClick: externalClick, selectedValue, setSelectedValue, handleMaintainWeightToast = () => {}, count = false, orderSkipped, className }) => {
  const [isChecked, setIsChecked] = useState(checked);  
  const handleCheckboxClick = (value, x) => {
    if (disabled) {
      handleMaintainWeightToast()
      return;
    }
    externalClick && externalClick(value);
    if(radio){
      setIsChecked(true);
      setSelectedValue([value]);
      return;
    }
    setIsChecked(!isChecked);
    if(selectedValue && setSelectedValue){
      if(selectedValue?.includes(value)){
        const prevSelectedValues = [...selectedValue];
        const updatedValues = prevSelectedValues?.filter((item) => {
            return item !== value
        });
        setSelectedValue(updatedValues);
      }else{
        setSelectedValue((prev) => [...prev, value]);
      }
    }
  }

  useEffect(() => {
    if(radio){
      // To hide other selected item if radio is true
      selectedValue?.includes(value) ? setIsChecked(true) : setIsChecked(false);
    }
  }, [selectedValue])

  if(type == 'title_only'){
    return (
      <>
        <SingleRowCheckbox icon={icon} onClick={() => handleCheckboxClick(value)} theme={theme} className={`${isChecked ? 'checked' : ''}`}>
          {icon && <div className="icon">
            <span>{isChecked && activeIcon ? activeIcon : icon}</span>
          </div>}
          <div className="content">
            <h3 className="title">{title}</h3>
          </div>
          <div className="checkbox">{isChecked ? <RadioOutlineChecked className='svg_icon'/> : <RadioOutline className='svg_icon'/>}</div>
        </SingleRowCheckbox>
      </>
    )
  }

  return (
    <>
      <CheckboxCardStyle onClick={() => handleCheckboxClick(value)} secondarySubtitle={secondarySubtitle} icon={icon} theme={theme} className={`${className} ${isChecked ? 'checked' : ''}`}>
        {icon && (
          <div className="icon">
            <span>{isChecked && activeIcon ? activeIcon : icon}</span>
          </div>
        )}
        {count && (
  <div className='number-circle'>
    <span>{orderSkipped}</span>
  </div>
)}

        <div className="content">
          <h3 className="title">{title}</h3>
          {subtitle &&
          <p className="subtitle">{subtitle}</p> }
          {secondarySubtitle &&
          <p className="subtitle secondarySubtitle">{secondarySubtitle}</p> }
        </div>
        <div className="checkbox">{isChecked ? <RadioOutlineChecked className='svg_icon'/> : <RadioOutline className='svg_icon'/>}</div>
      </CheckboxCardStyle>
    </>
  );
};
