import React from 'react'
import styled from 'styled-components'
import { theme } from 'shared/theme/theme'
import { ReactComponent as Info } from 'assets/info-blue.svg';
import { ReactComponent as Error } from 'assets/error-red.svg';
import { ReactComponent as Close } from 'assets/close-gray.svg';
import { ReactComponent as Success } from 'assets/success-green.svg';
import { Button } from '../button/Button';
import { motion } from 'framer-motion';

const ModalStyle = styled.div`
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    padding: 16px;
    padding-bottom:27px;
    padding-top: ${({ alignment }) => alignment === 'center' ? '52px' : '24px'};
    max-width: ${({ size }) => size === 'small' ? '350px' : '500px'};
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index:200;
    .modal_header{
        display: flex;
        gap: ${({ alignment }) => alignment === 'center' ? '16px' : '12px'};
        align-items: center;
        flex-direction: ${({ alignment }) => alignment === 'center' ? 'column' : 'row'};
        .icon{
            > * {
                width: ${({ size, alignment }) => (size === 'small' && alignment === 'left') ? '24px' : alignment === 'center' ? '64px' : '28px'};
                height: ${({ size, alignment }) => (size === 'small' && alignment === 'left') ? '24px' : alignment === 'center' ? '64px' : '28px'};
            }
        }
        .title{
            font-size: ${({ size }) => size === 'small' ? '16px' : '20px'};
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            font-family: ${({ theme }) => theme.typography.fontInter};
            color: ${({ theme }) => theme.colors.black};
            flex: 1;
        }
        .cross{
            cursor: pointer;    
            position: absolute;
            right: 16px;
            top: 16px;        
        }
    }
    .modal_body{
        .desc{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #283241;
            padding-left: ${({ alignment }) => alignment === 'center' ? '10px' : '0px'};
            padding-right: '10px';
            text-align: ${({ alignment }) => alignment === 'center' ? 'center' : 'left'};
            padding-top:${({ paddingTop }) => paddingTop || '0px'};
            padding-bottom:${({ paddingBottom }) => paddingBottom || '0px'};
            margin: 20px 0px 32px 0px;
        }
    }
    .modal_actions{
        display: flex;
        gap: 8px;
        justify-content: ${({ alignment }) => alignment === 'center' ? 'center' : 'flex-end'};
    }
`


export const Modal = ({ isOpen, primaryText, secondaryText, buttonType, alignment = "left", size = "large", type = 'info', title = 'Modal Title', description, onClose, onOk, onCancel, isBackdropEnabled = false, customAction, secondaryButtonType = 'secondary', width, paddingBottom, paddingTop, color, isSecondaryButton = true }) => {
    // Type = ['info','error','success']
    // Size = ['large', 'small']
    // Alignment = ['left', 'center']
    return (
        <>
            <motion.div 
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                    restDelta: 0.001
                }
            }}
            className='motion_modal' style={{ width: width }}>
            <ModalStyle className='modal' theme={theme} size={size} alignment={alignment} paddingTop={paddingTop} paddingBottom={ paddingBottom} color={color}>
                <div className='modal_header'>
                    {type === 'info' &&
                        <div className='icon'>
                            <Info style={{ display: 'block' }} />
                        </div>
                    }
                    {type === 'error' &&
                        <div className='icon'>
                            <Error style={{ display: 'block' }} />
                        </div>
                    }
                    {type === 'success' &&
                        <div className='icon'>
                            <Success style={{ display: 'block' }} />
                        </div>
                    }
                    <h2 className='title'>{title}</h2>
                    <div className='cross' onClick={onClose}>
                        <Close style={{ display: 'block' }} />
                    </div>
                </div>
                <div className='modal_body'>
                    <p className='desc'>
                        {description || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, sit scelerisque vestibulum magnis. Auctor dolor, tincidunt enim pharetra. Ornare amet suspendisse id sit malesuada. Nec platea tellus mollis diam ut elit consequat adipiscing at.'}
                    </p>
                </div>
                {customAction ? customAction :
                <div className='modal_actions'>
                    {(alignment !== "center" || isSecondaryButton) && <Button title={secondaryText} onClick={onCancel} size={size} type={secondaryButtonType} />}
                    <Button title={primaryText} onClick={onOk} size={size} type={buttonType ? buttonType : 'primary'} />
                </div>
                }
            </ModalStyle>
            </motion.div>
        </>
    )
}