import styled from 'styled-components';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlertAssignMealsWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: #949d9b80;
  ${FlexCenter};

  .alert-assign-container {
    width: 90%;
    margin: 0 auto;
    padding: 24px 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #f0faeb;
    ${FlexCenter};

    img {
      width: 35px;
    }
  }

  p {
    color: #51635d;
    padding: 28px 0;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  .primary-btn {
    width: 150px;
    height: 40px;
    border-radius: 21px;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 16px;
    cursor: pointer;
    ${FlexCenter};
  }

  @media (min-width: 992px) {
    .alert-assign-container {
      width: 335px;
    }
  }
`;

export default function AlertAssignMeals({ setIsCompleted }) {
  return (
    <AlertAssignMealsWrapper>
      <div className="alert-assign-container">
        <div className="img-wrapper">
          <img src="/icons/info-circle.svg" alt="Info Icon" />
        </div>
        <p>You can only assign meals scheduled after pickup/delivery date</p>
        <button className="primary-btn" onClick={() => setIsCompleted(false)}>
          OK
        </button>
      </div>
    </AlertAssignMealsWrapper>
  );
}
