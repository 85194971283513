import { ResponsivePie } from '@nivo/pie';
import { useAppContext } from 'context/appContext';

import './style.css';

export default function NutritionalPlan() {
  const {
    profile: {
      dietMetrics: {
        calories,
        macros: { carbs, fat, protein },
      },
    },
  } = useAppContext();

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '18px',
          fontWeight: '700',
          color: '#0b1c18',
        }}
      >
        {calories} Cal
      </text>
    );
  };

  const data = [
    {
      id: 'FAT',
      value: fat,
      label: `FAT ${fat}g`,
      color: '#0CA750',
    },
    {
      id: 'PROTEIN',
      value: protein,
      label: `PROTEIN ${protein}g`,
      color: '#59CB59',
    },
    {
      id: 'CARBS',
      value: carbs,
      label: `CARBS ${carbs}g`,
      color: '#98E58E',
    },
  ];

  return (
    <div className="nutritional-wrapper">
      <h3>My Plan</h3>
      <div className="pie-wrapper">
        <ResponsivePie
          data={data}
          margin={{ top: 10, right: 200, bottom: 10, left: 0 }}
          valueFormat=" >-"
          startAngle={-80}
          innerRadius={0.8}
          activeInnerRadiusOffset={0}
          activeOuterRadiusOffset={5}
          arcLinkLabel={(e) => `${e.value} g`}
          arcLinkLabelsTextOffset={3}
          arcLinkLabelsTextColor="#333333"
          enableArcLinkLabels={false}
          arcLabel={(e) => `${e.value}g`}
          colors={['#0CA750', '#59CB59', '#98E58E']}
          arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
          tooltip={function (e) {
            return true;
          }}
          legends={[
            {
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 70,
              translateY: 0,
              itemWidth: 60,
              itemHeight: 20,
              itemsSpacing: 20,
              symbolSize: 12,
              itemDirection: 'left-to-right',
              symbolShape: 'circle',
            },
          ]}
          enableArcLabels={false}
          enableSliceLabels={false}
          radialLabel={(d) => `${d.id} (${d.formattedValue})`}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
        />
      </div>
    </div>
  );
}
