import styled from 'styled-components';
import SwitchOnOff from './SwitchOnOff';
import SelectTimeFromTo from './SelectTimeFromTo';

const DaySlotsPanelWrapper = styled.div`
  position: relative;
  padding: 20px 54px 20px 24px;
  border: 1px solid #f6f5f8;
  border-radius: 10px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);

  & + & {
    margin-top: 10px;
  }

  .day-slots-panel {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .day-wrapper {
    margin-top: 5px;
    display: flex;
    align-items: center;

    .day-name {
      margin-left: 5px;
      color: #51635d;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
    }
  }

  .slots-wrapper {
    & > div:first-child .trash-icon {
      display: none;
    }
  }

  .new-interval {
    margin-top: 10px;
    color: #949d9b;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    padding: 20px 14px 15px;

    .day-slots-panel {
      flex-direction: column;
    }

    .day-wrapper {
      width: calc(100% + 10px);
      margin: 0 0 5px 0;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .new-interval {
      padding-left: 30px;
      text-align: left;
    }
  }
`;

export default function DaySlotsPanel({
  day,
  available,
  slots,
  onChangeSwitch,
  onChangeTime,
  addNewSlots,
  removeSlots,
}) {
  return (
    <DaySlotsPanelWrapper>
      <div className="day-slots-panel">
        <div className="day-wrapper">
          <SwitchOnOff checked={available} onChange={onChangeSwitch} />
          <span className="day-name">{day}</span>
        </div>
        <div className="slots-wrapper">
          {slots.map((slot, index) => (
            <SelectTimeFromTo
              key={index + 'd'}
              from={slot.from}
              to={slot.to}
              onChangeTimeFrom={(from) => onChangeTime({ from, index })}
              onChangeTimeTo={(to) => onChangeTime({ to, index })}
              removeSlots={() => removeSlots(index)}
            />
          ))}
        </div>
      </div>
      <div className="new-interval" onClick={addNewSlots}>
        + New interval
      </div>
    </DaySlotsPanelWrapper>
  );
}
