import React, { useEffect } from "react";
import Select from 'components/Select';
import { CHALLANGES, PLAN_PERIODS } from '../../../../constants';
import styled from "styled-components";

const OverViewComponent = styled.div`
.MacroBreakdown{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #51635D;
}
.required-color{
    margin:0 2px;
    color:red;
  }
  .challange-duration {
    display: flex;
    align-items: end;
 }

 .Challange-duration2{
  margin-bottom: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px;
  color: #51635D;
  width:100%;
  @media (max-width:360px){
    padding:10px;
  }
 }
 .Challange-duration1{
    width:90%;
    margin-right: 10px;
    margin-bottom: 20px;
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px;
    color: #51635D;
 }
 .challange-name{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #51635D !important;
    width:100%;
  }

`

const OverViewChallengee = ({form,setForm,setIntensity}) =>{
  useEffect(() =>{
    setForm(form)
  }, [])
  const handleDuration =(e)=> {
    const { value } = e.target;
    const isNumber = /^\d*\.?\d{0,2}$/.test(value);
    if(form.duration_type == "days" && value>365 && isNumber){
      setForm({ ...form, duration: 365 });
    }else if(form.duration_type == "weeks" && value>53 && isNumber){
      setForm({ ...form, duration: 53 });
    }else if(form.duration_type == "months" && value>24 && isNumber){
      setForm({ ...form, duration: 24 });
    }else if(value.length<10 && isNumber){
      setForm({ ...form, duration: value });
    }  
  }
    return(
        <>
           <OverViewComponent>
           <div className='MacroBreakdown'>Challenge goal <span className='required-color'> * </span></div>
            <Select
              key="BMR"
              options={CHALLANGES}
              updateValue={(e) => {
                if (form.type === 'bulkup') {
                  setIntensity(0)
                } else if (form.type === 'lose-weight') {
                  setIntensity(0)
                }
                setForm({ ...form,type : e, goal: 0});
              }}
              defaultSelected={form?.type}
            />
            <div className='MacroBreakdown'>Challenge Duration <span className='required-color'> * </span></div>
            <div className='challange-duration'>
              <input
               onChange={handleDuration}
                value={form?.duration}
                className='Challange-duration1'
                pattern="\d*"
                type="number"
              />
              <Select
                name="description"
                type="title"
                autoComplete="off"
                placeholder="Days"
                className='Challange-duration2'
                options={PLAN_PERIODS}
                updateValue={(e) => setForm({ ...form, duration: 0, duration_type: e })}
                defaultSelected={form?.duration_type}
              />
            </div>
            <div>
              <label>Challenge name <span className='required-color'> * </span></label>
              <input
                name="name"
                type="text"
                className="challange-name"
                value={form?.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                placeholder="Add a clever challenge title"
                autoComplete="off"
              />
            </div>
           </OverViewComponent>
        </>
    )
}

export default OverViewChallengee;