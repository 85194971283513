import { GiJellyfish } from 'react-icons/gi';
import {ReactComponent as Fish} from 'icons/fish.svg';
import {ReactComponent as Milk} from 'icons/milk.svg';
import {ReactComponent as Mustard} from 'icons/mustard.svg';
import {ReactComponent as Peanuts} from 'icons/peanuts.svg';
import {ReactComponent as Crustaceans} from 'icons/crustaceans.svg';
import {ReactComponent as Celery} from 'icons/celery.svg';
import {ReactComponent as TreeNuts} from 'icons/treenuts.svg';
import {ReactComponent as Eggs} from 'icons/eggs.svg';
import {ReactComponent as Soy} from 'icons/soy.svg';
import {ReactComponent as Dairy} from 'icons/diary.svg';
import {ReactComponent as Gluten} from 'icons/gluten.svg';
import {ReactComponent as Molluscs} from 'icons/molluscs.svg';
import {ReactComponent as Sesame} from 'icons/sesame.svg';
import {ReactComponent as Lupin} from 'icons/lupin.svg';
import {ReactComponent as Sulphites} from 'icons/sulphites.svg';
import {ReactComponent as Halal} from 'icons/halal.svg';
import {ReactComponent as HighProtein} from 'icons/high-protein.svg';
import {ReactComponent as LowCarb} from 'icons/low carb.svg';
import {ReactComponent as Natural} from 'icons/natural.svg';
import {ReactComponent as Organic} from 'icons/organic.svg';
import { ReactComponent as Vegetarian } from 'icons/vegetarian.svg';
import { ReactComponent as OvoVegetarian } from 'icons/Ovo-vegeterian.svg';
import { ReactComponent as LactoVegetarian } from 'icons/lacto vegetarian.svg';
import { ReactComponent as Vegan } from 'icons/vegan.svg';
import { ReactComponent as Kosher } from 'icons/kosher.svg';
import { ReactComponent as Ketogenic } from 'icons/ketogenic.svg';
import { ReactComponent as Paleo } from 'icons/paleo.svg';

const icons = {
  Fish: ({ color }) => (
    
    <Fish />

  ),
  Milk: ({ color }) => (
    
    <Milk />

  ),
  Mustard: ({ color }) => (
   
    <Mustard />

  ),
  Peanuts: ({ color }) => (
   
    <Peanuts />

  ),
  Peanut: ({ color }) => (
   
    <Peanuts />

  ),
  Crustaceans: ({ color }) => (
  
    <Crustaceans />

  ),
  Crustacean: ({ color }) => (
  
    <Crustaceans />

  ),
  Celery: ({ color }) => (
   
    <Celery />

  ),
  TreeNuts: ({ color }) => (
    
    <TreeNuts />

  ),
  Tree_Nut: ({ color }) => (
    
    <TreeNuts />

  ),
  Eggs: ({ color }) => (
   
    <Eggs />

  ),
  Egg: ({ color }) => (
   
    <Eggs />

  ),
  Soy: ({ color }) => (
  
    <Soy />

  ),
  Dairy: ({ color }) => (
   
    <Dairy />

  ),
  Gluten: ({ color }) => (
   
    <Gluten />

  ),
  Molluscs: ({ color }) => (
   
    <Molluscs />

  ),
  Mollusk: ({ color }) => (
   
    <Molluscs />

  ),
  Sesame: ({ color }) => (
   
    <Sesame />

  ),
  Lupin: ({ color }) => (
    
    <Lupin />
    
  ),
  Lupine: ({ color }) => (
    
    <Lupin />
    
  ),
  Sulphites: ({ color }) => (
   
    <Sulphites />

  ),
  Sulfites: ({ color }) => (
   
    <Sulphites />

  ),
  Halal: ({ color }) => (
   
    <Halal />

  ),
  'High-protein': ({ color }) => (
    
    <HighProtein />

  ),
  LowCarb: ({ color }) => (
    
    <LowCarb />
  ),
  Natural: ({ color }) => (
    
    <Natural />
  ),
  Organic: ({ color }) => (
   
    <Organic />
  ),
  Vegetarian: ({ color }) => (
    
    <Vegetarian />

  ),
  OvoVegetarian: ({ color }) => (
   
    <OvoVegetarian />

  ),
  LactoVegetarian: ({ color }) => (
   
    <LactoVegetarian />
     
  ),
  Vegan: ({ color }) => (
   
    <Vegan />

  ),
  Kosher: ({ color }) => (
   
    <Kosher />
  ),
  Ketogenic: ({ color }) => (
   
   <Ketogenic />
 
  ),
  Paleo: ({ color }) => (
    
   <Paleo />
     
  ),
  HighProtein: ({ color }) => (
   
    <HighProtein />

  ),
};


function convertLowercaseToUppercaseVersions(object) {
  const result = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      result[key.toUpperCase()] = object[key];
    }
  }
  return result;
}
export default {
    ...icons,
    'Ovo vegetarian': icons.OvoVegetarian,
    'Lacto vegetarian': icons.LactoVegetarian,
    Seafood: GiJellyfish,
    Vegan: icons.Vegan,
    Kosher: icons.Kosher,
    'High protein': icons.HighProtein,
    'Low carb': icons.LowCarb,
    Ketogenic: icons.Ketogenic,
    'Ovo-vegetarian': icons.OvoVegetarian,
    'Lacto-vegetarian': icons.LactoVegetarian,
    'Low-carb': icons.LowCarb,
    'LOW-CARB': icons.LowCarb,
    'vegan' : icons.Vegan,
    'low-carb' : icons.LowCarb,
    'kosher' :  icons.Kosher,
    'high-protein' :  icons.HighProtein,
    'vegetarian' : icons.Vegetarian,
    'Egg' : icons.Eggs,
    'EGG' : icons.Eggs,
    'Lupine': icons.Lupin,
    'LUPINE': icons.Lupin,
    'MILK' : icons.Milk,
    'MOLLUSCS' : icons.Molluscs,
    'Cereal/Gluten': icons.Gluten,
    'CEREAL/GLUTEN':icons.Gluten,
    'SOY' : icons.Soy,
    'FISH' : icons.Fish,
    'CELERY' : icons.Celery,
    'MUSTARD' : icons.Mustard,
    'SESAME' : icons.Sesame,
    'SULPHITES' : icons.Sulphites,
    'Peanut' : icons.Peanuts,
    'PEANUT' : icons.Peanuts,
    'CRUSTACEAN' : icons.Crustaceans,
    'Crustacean' : icons.Crustaceans,
    'ketogenic' :  icons.Ketogenic,
    'paleo' : icons.Paleo,
    'lacto-vegetarian' : icons.LactoVegetarian,
    'LACTO-VEGETARIAN': icons.LactoVegetarian,
    'halal' : icons.Halal,
    'ovo-vegetarian' : icons.OvoVegetarian,
    "OVO-VEGETARIAN": icons.OvoVegetarian,
    'Tree Nut' : icons.TreeNuts,
    'TreeNut' : icons.TreeNuts,
    'Mollusk': icons.Molluscs,
    'MOLLUSK': icons.Molluscs,
    'TREE NUT' : icons.TreeNuts,
    ...convertLowercaseToUppercaseVersions(icons),

};

