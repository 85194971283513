import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

const FlexBetween = `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemDetailsWrapper = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${() => theme.colors.lightGrey};
  cursor: pointer;

  .item-name,
  .item-price,
  .item-category,
  .item-status,
  .item-update {
    padding-left: 16px;
    color: ${() => theme.colors.primaryDark};
    font-family: ${() => theme.typography.fontInter};
    font-size: 14px;
    font-weight: 400;
  }

  .item-photo {
    width: 16%;
    padding-left: 12px;
    display: flex;
    align-items: center;
    .header-checkbox {
      width: 20px;
      margin: 0 16px 0 6px;
    }
    .item-img {
      width: 70px;
      height: 80px;
      object-fit: cover;
    }
    .expanded-icon {
      transition: all 0.3s ease-in-out;
    }
    .expandedIcon {
      transform: rotate(90deg);
    }
  }

  .item-name {
    width: 34%;
  }

  .item-price {
    width: 12%;
  }

  .item-category {
    width: 12%;
  }

  .item-status {
    width: 12%;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    .active {
      width: 8px;
      height: 8px;
      margin-right: 6px;
      background-color: ${() => theme.colors.success};
      border-radius: 50%;
    }
  }

  .item-update {
    width: 14%;
    padding-right: 16px;
    ${FlexBetween};
    .dropdown-action {
      .btn {
        height: auto;
        padding: 0;
        background-color: transparent;
      }
      .menu {
        right: 0;
        width: 200px;
        margin-top: 10px;
        padding: 4px 0;
        .menu-item span {
          color: ${() => theme.colors.blackDefault};
        }
        .menu-item:nth-child(even) {
          background-color: transparent;
        }
        .menu-item:hover {
          background-color: ${() => theme.colors.background};
        }
      }
      .openDropdown {
        background-color: ${() => theme.colors.lightGrey};
      }
    }
  }

  &.inactiveItem {
    cursor: auto;
    .item-name, .item-price, .item-category, .item-status, .item-update {
      color: ${() => theme.colors.gray};
    }
    .item-status .active {
      background-color: #B5B9C8;
    }
  }
`;

export const ItemDetailsExpendedWrapper = styled.div`
  font-family: ${() => theme.typography.fontInter};
  font-size: 14px;
  font-weight: 600;

  .item-info-wrapper {
    padding: 12px 32px 32px;
  }

  .item-info-container {
    padding: 20px 24px 24px;
    border: 1px solid ${() => theme.colors.grayLight};
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
    transition: all 0.5s ease-in-out;
  }

  .item-ingredients {
    .item-ingredients-title {
      margin-bottom: 8px;
      ${FlexBetween};
      .title {
        color: ${() => theme.colors.grayDarker};
      }
      .nutrition-label {
        color: ${() => theme.colors.primaryDark};
        font-size: 12px;
        cursor: pointer;
      }
    }
    .item-description {
      color: ${() => theme.colors.blackDefault};
      font-weight: 400;
    }
  }

  .item-allergens,
  .item-diets {
    margin: 16px 0;
    .title {
      margin-bottom: 8px;
      color: ${() => theme.colors.grayDarker};
    }
    .tags-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      [type='checkbox'] {
        display: none;
      }
    }
    .tags-wrapper .tag-name {
      color: ${() => theme.colors.grayDarker};
    }
  }

  .item-diets {
    margin: 0;
  }

  .fact-title-wrapper {
    margin-bottom: 8px;
    ${FlexBetween};
    .fact-title {
      color: ${() => theme.colors.grayDarker};
    }
    .edit {
      color: ${() => theme.colors.primaryDark};
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }

  .nutrition-fact-details {
    padding: 16px 24px;
    border: 1px solid ${() => theme.colors.grayLight};
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    .pie-container {
      .title-number {
        margin: 0;
        font-size: 24px;
        line-height: 32px;
      }
      .title-text {
        font-size: 10px;
        font-wight: 500;
      }
    }
    .progress-wrapper {
      & > div + div {
        margin-top: 12px;
      }
      .progress-info .progress-label {
        margin-bottom: 0;
      }
      .other-fact {
        color: ${() => theme.colors.blackDefault};
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
    }
  }
`;

export const ActiveModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: rgb(40 50 65 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    width: 500px;
    max-width: 500px;
    padding: 24px 14px 27px 24px;
    background-color: ${() => theme.colors.secondary};
    border: 1px solid ${() => theme.colors.lightGrey};
    border-radius: 8px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    row-gap: 0;
  }
  .modal_header {
    .icon {
      display: none;
    }
    .title {
      color: ${() => theme.colors.blackDefault};
      font-size: 20px;
    }
    .cross {
      top: 28px;
      right: 24px;
    }
  }
  .modal_body {
    .desc {
      color: ${() => theme.colors.blackDefault};
    }
  }
  .btn {
    height: 40px;
    border-radius: 6px;
  }
  .modal_body {
    .desc {
      margin: 20px 0px 51px 0px;
    }
  }
`;
