import { useEffect } from 'react';
import NutPlan from '../shop/Meals/NutPlan';
import { useAppContext } from 'context/appContext';
import CreateMealPlanForm from 'components/shop/CreateMealPlanForm';
import NutrittSteps from 'components/NutrittSteps';
import styled from 'styled-components';
import PantryFooter from 'components/Pantry/PantryFooter';
import Progress from 'components/Progress';

const Container = styled.div`
  width: 100%;
  padding: 24px 0 70px;
  background-color: #f6f5f8;

  .logo {
    margin-bottom: 46px;
  }

  button a {
    color: #fff;
  }

  .meal-plan-form-container {
    width: 50%;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 1199px) {
    .meal-plan-form-container {
      width: 75%;
    }
  }

  @media (max-width: 991px) {
    width: 90%;
    margin: auto;
    padding: 26px 0 80px;
    min-height: calc(100vh - 89px);

    .meal-plan-form-container {
      width: 100%;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }
`;
const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function ChooseMealPlan() {
  const { isMobile, isVisitor, isGuest, isAddressLoading, user } = useAppContext();
  const mobileView = isMobile ? 'mobileView' : '';
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if(isMobile && isAddressLoading){
    return <Progress/>
  }

  return (
    <>
    <Container>
      <div className={mobileView}>
        <div className="meal-plan-form">
          <NutrittSteps
            title="Meal planning"
            description="It's easy to plan 100% of your meals using Nutritt!"
            activeStep={0}
            steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
            stepNumber={1}
            stepTitle="Let's specify your plan"
            isFooterHidden={true}
          />
          <div className="meal-plan-form-container">
            {isMobile && user?.type?.includes('user') && <NutPlan />}
            <CreateMealPlanForm />
          </div>
        </div>
      </div>
    </Container>
    { isMobile &&
    <PantryFooter shop={true} myCoach={false} progress={false}/>
    }
    </>
  );
}
