import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {ReactComponent as Loading} from '../../assets/loading.svg';
import {ReactComponent as Headphone} from '../../assets/headphones.svg';
import { useLocation } from 'react-router-dom';

const LoadingStyle = styled.div`
    position: fixed;
    top: 61px;
    height: calc(100vh - 61px);
    z-index: 99;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-direction: column;

    &.user{
        height: calc(100vh - 61px - 70px);
    }

    .rotate{
        animation: rotate 1.5s linear infinite;
    }

    @keyframes rotate{
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(360deg);
        }
    }

    .loading_text{
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
        text-align: center;
        margin-top: 48px;
        span{
            animation: loading 1.5s infinite ease-in-out;
            display: inline-block;
            &:nth-child(2){
                animation-delay: 0.5s;
            }
            &:nth-child(3){
                animation-delay: 1s;
            }
        }
        @keyframes loading{
            0%{
                opacity: 0.4;
                transform: scale(1);
            }
            50%{
                opacity: 1;
                transform: translateY(-1.5%) scale(1.05);
            }
            100%{
                opacity: 0.4;
                transform: scale(1);
            }
        }
    }
    .loading_subText{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #51635D;
        margin: 12px 48px 0;
    }
    .svg_icon{
        position: relative;
        .headphone{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`
export const ChatLoading = ({loadingText, loadingSubtext, headphone}) => {
    const location = useLocation();
    const [style, setStyle] = useState(false);
    useEffect(() => {
        if (location.pathname == "/shop/chat-with-coach") {
            setStyle(true);
        }
    }, [])

  return (
    <LoadingStyle className={style ? 'user' : ''}>
        <div className='svg_icon'>
            <Loading className='rotate'/>
            {headphone && <Headphone className='headphone'/>}
        </div>
        {loadingText && <h2 className='loading_text'>{loadingText}<span>.</span><span>.</span><span>.</span></h2>}
        {loadingSubtext && <p className='loading_subText'>{loadingSubtext}</p>}
    </LoadingStyle>
  )
}
