import { Switch, Route, useLocation, useHistory } from "react-router-dom"
import { useAppContext } from "context/appContext"
import Header from 'layout/Header';
import EmailVerification from "./emailVerification";
import PasswordForm from "./PasswordForm";
import Details from './Details';
import SignUp from "./SignUp";
import PhoneVerify from './PhoneVerify';
import PhoneNumber from "./PhoneNumber";

export default function UserDesktopOnboarding() {
    const {isMobile, isDesktop} = useAppContext();
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const NewGoal = params.get('newGoal');

    return (
        <>
                {isDesktop && <Header showSocialIcons={false}/>}
                    <Switch location={location} key={location.pathname}>
                        <Route path={'/account/home'} component={SignUp} />
                        <Route path={'/account/user-details'} component={Details} />
                        <Route path={'/account/phoneNumber'} component={PhoneNumber} />
                        <Route path={'/account/phone-verify'} component={PhoneVerify} />
                        <Route path={'/account/email-verification'} component={EmailVerification} />
                        <Route path={'/account/password'} component={PasswordForm} />
                    </Switch>
        </>
    )
}
