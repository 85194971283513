import { useState } from 'react';
import styled from 'styled-components';
import MealPlanDuration from 'components/Pantry/MealPlanDuration';
import { ReactComponent as Close } from 'icons/close-circle-green.svg';

const NutritionistPlanProgressWrapper = styled.div`
  position: relative;
  z-index: 10;

  .nutritionist-note-wrapper {
    position: absolute;
    bottom: 86px;
    left: auto;
    z-index: 5;
    margin: 0 14px;
    padding: 14px 20px;
    background: #f6f5f8;
    color: #51635d;
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    display: none;

    .close-icon {
      position: absolute;
      top: 7px;
      right: 6px;
      cursor: pointer;

      path:first-child {
        fill: transparent;
      }
    }

    p {
      color: #51635d;
      font-size: 14px;
      line-height: 16px;

      .food-icons {
        display: inline-flex;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    span {
      position: absolute;
      bottom: -10px;
      left: 29px;
      width: 0;
      height: 0;
      border-top: 11px solid #f6f5f8;
      border-right: 16px solid transparent;
    }
  }

  .pantry-shop-wrapper {
    width: 100%;
    flex-wrap: wrap;
    align-items: end;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .user-progress-wrapper {
      padding: 6px 12px 6px 14px;
      border-top: 1px solid #f6f5f8;
      display: flex;
      align-items: center;
      width: 100%;
      .user-avtar {
        height: 58px;
        margin-right: 9px;

        img {
          border-radius: 50%;
          object-fit: cover;
          height: 58px;
          width: 58px;
        }

        & + div {
          flex: auto;
        }
      }

      .meal-plan-progress {
        height: auto;
      }
    }

    .user-note {
      flex: 0 0 100%;
      padding: 0px 14px;
      margin-top: 14px;
      p {
        max-height: 100px;
        overflow-y: auto;

        ::-webkit-scrollbar {
          width: 3px;
        }
        ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        background: #f6f5f8;
        padding: 18px;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #51635d;
      }
      img {
        left: 30px;
        top: -4px;
        position: relative;
      }
    }
  }

  @media(min-width: 992px) {
    height: auto;

    .nutritionist-note-wrapper {
      position: absolute;
      bottom: 88px;
      left: auto;
      right: auto;
      max-width: 400px;
    }

    div.pantry-shop-wrapper {
      position: static;
      width: 100%;
    }
  }
`;

const foodIcons = ['food1', 'food2', 'food3'];

export default function NutritionistPlanProgress({ personalizedMeals, user, userProgress }) {
  const [isNote, setIsNote] = useState(true);

  return (
    <NutritionistPlanProgressWrapper>
      {isNote && (
        <div className="nutritionist-note-wrapper">
          <Close className="close-icon" onClick={() => setIsNote(false)} />
          <p>
            This is a personal note from the nutritionist, this will be displayed each day a
            diffrent note.{' '}
            <div className="food-icons">
              {foodIcons.map((icon, index) => (
                <img src={`/icons/${icon}.svg`} alt={`${icon} Icon`} key={index} />
              ))}
            </div>
          </p>
          <span />
        </div>
      )}
      <div className="pantry-shop-wrapper">
        {personalizedMeals.notes && (
          <div className="user-note">
            <p>${personalizedMeals.notes}</p>
            <img className="poligon" src="/icons/note-triangle.svg" alt="Poligon Icon" />
          </div>
        )}
        <div className="user-progress-wrapper">
          <div className="user-avtar">
            <img src={user?.nutritionist?.img || '/images/avatar.png'} alt="user" />
          </div>
          <MealPlanDuration userProgress={userProgress} />
        </div>
      </div>
    </NutritionistPlanProgressWrapper>
  );
}