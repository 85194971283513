import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import styled from 'styled-components'
import Tags from 'components/shop/MealTags'
import useDisplayAmount from 'hooks/useDisplayAmount';
import { useNutritionistContext } from 'context/nutritionistContext';
import UseClientCart from 'hooks/UseClientCart';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import LinesEllipsis from 'react-lines-ellipsis';
import { useAppContext } from 'context/appContext';

const MealCardStyle = styled.div`

    padding: 4px;
    position: relative;
    margin-bottom: 4px;

    &:not(.recommendedOrNotRecommended) + &:not(.recommendedOrNotRecommended) {
        border-top: 1px solid #E0E3E0;
    }
    &.recommended.recommendedOrNotRecommended {
        border-top: 0;
    }

    .recommended_icon{
        width: 64px;
        height: 64px;
        position: absolute;
        right: -4px;
        top: -4px;
    }

    .holder{
        padding: 10px;
        display: flex;
        gap: 12px;

        &.recommended{
            border: 1px solid #CBEDBA;
        }

        &.not_recommended{
            border: 1px solid #FEB95A;
        }
    }

    .meal__img{
        width: 105px;
        img{
            width: 100%;
        }

        @media (max-width: 359px){
            
            width: 95px;

        }
    }

    .meal__info{
        flex: 1;

        .title{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #0B1D17;
            margin-bottom: 8px;
            width: 90%;
        }

        .desc{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            margin-bottom: 8px;
            width: 100%;

            span{
                color: rgb(82, 196, 26);
            }
        }

        .nutritional__info{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 8px;

            .info__card{
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #51635D;
                text-align: center;
                border-left: 1px solid #F6F5F8;
                padding: 0 8px;

                span{
                    font-weight: 400;
                    display: block;
                    font-size: 8px;
                }

                &:first-child{
                    padding-left: 0;
                    border-left: 0px;
                }

            }
        }

        .tags{
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
        }

        .price_cart{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;

            .price{
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #0B1D17;
            }

            .add__to__cart{
                width: 28px;
                height: 28px;
                background: #52C41A;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                img{
                    width: 14px;
                    height: 14px;
                }
            }

        }

        
    }

    .tags{
        .tags-listing{
            width: fit-content;
            display: flex;
            gap: 5px;
            flex-wrap: wrap;

            .tag-container{
                margin-right: 0;

                .icon-wrapper{
                    width: 24px;
                    height: 24px;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .notRecommended{
            span{
                border: 1px solid rgba(254, 185, 90, 0.3);
                img{
                    filter: invert(70%) sepia(50%) saturate(608%) hue-rotate(360deg) brightness(140%) contrast(110%);
                }
            }
        }

        .recommended{
            span{
                border: 1px solid #CBEDBA;
                img{
                    filter: invert(57%) sepia(46%) saturate(1920%) hue-rotate(62deg) brightness(107%) contrast(80%)
                }
            }
        }
    }
    .add-to-cart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .price{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #0B1D17;
        }
        .math-wrapper {
          width: 100px;
          height: 32px;
          background-color: var(--nutritt-green);
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          border: 0;
          border-radius: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 1px 0 #00000014;
          cursor: pointer;
          :disabled {
            cursor: not-allowed;
            background-color: #c3cbc9;
          }
    
          &.math-wrapper {
            justify-content: space-between;
            cursor: auto;
    
            .math {
              margin-top: 3px;
              cursor: pointer;
            }
          }
        }
         .plus-btn{
            width: 32px!important;
            border-radius: 50%!important;
            font-size: 14px!important;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            background-color: var(--nutritt-green);
            img{
                width:14px;
                height:14px;
            }
         }
      }

`

export default function MealCard({ meal, pickMealsModal, dietTemplate}) {
    const { name, img, description, calories, carbs, fat, protein, price, id, restrictions, tags } = meal;
    const [notRecommendedRestrictions, setNotRecommendedRestrictions] = useState([]);
    const [recommendedTags, setRecommendedTags] = useState([]);
    const { clientcart } = useNutritionistContext();
    const { addToClientCart, updateClientMealQty, removeFromClientCart } = UseClientCart();
    const mealInCart = clientcart.find((meal) => meal.id === id);
    const [maxLines, setMaxLines] = useState(2);
    const {convertToHttps} = useAppContext();

    function getNotRecommendedRestrictions() {
        let notRecommendedMeal = [];

        return new Promise((resolve) => {
            restrictions.length &&
                dietTemplate?.food_to_avoid?.map((food) => {
                    restrictions.forEach((restriction) => {
                        if (restriction.toLowerCase() === food.toLowerCase()) {
                            !notRecommendedRestrictions.includes(food) && notRecommendedMeal.push(food);
                        }
                    });
                });
            setNotRecommendedRestrictions(notRecommendedMeal);
            resolve(true);
        });
    }

    const clearString = (string) => string.replace(/-|\s/g, '');

    function getRecommendedTags() {
        let recommendedMeal = [];

        tags.length &&
            dietTemplate?.tags?.forEach((dietTag) => {
                let clearDietTag = clearString(dietTag);
                tags.forEach((tag) => {
                    let clearTag = clearString(tag);
                    if (clearTag.toLowerCase() === clearDietTag.toLowerCase()) {
                        !recommendedTags.includes(tag) && recommendedMeal.push(tag);
                    }
                });
            });
        setRecommendedTags(recommendedMeal);
    }

    useEffect(async () => {
        await getNotRecommendedRestrictions();

        if (notRecommendedRestrictions.length !== 0) {
            return;
        }

        getRecommendedTags();
    }, []);

    const secureImageUrl = convertToHttps(img);

    return (
        <MealCardStyle className={classNames('recommended', {recommendedOrNotRecommended: notRecommendedRestrictions.length === 0 && recommendedTags.length !== 0 || notRecommendedRestrictions.length})}>
            {notRecommendedRestrictions.length === 0 && recommendedTags.length !== 0 && (
                <div className="recommended_icon only" style={{ backgroundImage: 'url("/images/recommanded-shape.svg")' }}>
                </div>
            )}

            <div className={`holder ${notRecommendedRestrictions.length === 0 && recommendedTags.length !== 0 && 'recommended'} ${notRecommendedRestrictions.length > 0 && 'not_recommended'}`}>
                <div className='meal__img'>
                    <img src={secureImageUrl ? secureImageUrl : '/images/dish.svg'} alt={name} />
                </div>
                <div className='meal__info'>

                    {name && <h3 className='title'>{name}</h3>}

                    {description && <LinesEllipsis
                        text={description}
                        ellipsis='...more'
                        maxLine={maxLines}
                        className='desc'
                        onClick={() => setMaxLines(maxLines + 8)}
                        />}

                    <div className='nutritional__info'>
                        {calories > 0 &&
                            <div className='info__card'>
                                {calories} <span>Cals</span>
                            </div>
                        }
                        {carbs > 0 &&
                            <div className='info__card'>
                                {carbs}g <span>Carbs</span>
                            </div>
                        }
                        {fat > 0 &&
                            <div className='info__card'>
                                {fat}g <span>Fat</span>
                            </div>
                        }
                        {protein > 0 &&
                            <div className='info__card'>
                                {protein}g <span>Protein</span>
                            </div>
                        }
                    </div>

                    <div className='tags'>
                        {recommendedTags?.length > 0 &&
                        <Tags
                            tags={tags}
                            recommendedTags={recommendedTags}
                            notRecommendedRestrictions={notRecommendedRestrictions}
                            pickMealsModal={pickMealsModal}
                        />
                        }
                        {notRecommendedRestrictions?.length > 0 &&
                        <Tags
                            tags={restrictions}
                            recommendedTags={recommendedTags}
                            notRecommendedRestrictions={notRecommendedRestrictions}
                            pickMealsModal={pickMealsModal}
                        />
                        }
                    </div>


                    <div className="add-to-cart">
                        <div className="price">{useDisplayAmount(price / 100)}</div>
                        {mealInCart && mealInCart.qty >= 1 ? (
                            <button className="math-wrapper" onClick={(e) => e.stopPropagation()}>
                                <span
                                    className="math"
                                    onClick={() =>
                                        mealInCart.qty == 1
                                            ? removeFromClientCart(meal.id)
                                            : updateClientMealQty(meal, +mealInCart.qty - 1)
                                    }
                                >
                                    <CgMathMinus />
                                </span>
                                <span>{mealInCart.qty}</span>
                                <span className="math" onClick={() => addToClientCart(meal)}>
                                    <CgMathPlus />
                                </span>
                            </button>
                        ) : (
                            <button className='plus-btn' onClick={(e) => {
                                e.stopPropagation();
                                addToClientCart(meal)
                            }}> <img src="/icons/add.svg" alt="Add Cart" />  </button>
                        )}
                    </div>
                </div>
            </div>
        </MealCardStyle>
    )
}
