import styled from 'styled-components';
import ProfileCard from 'components/shop/CoachProfile/MealPlanCoachCard/ProfileCard';
import { useHistory, useLocation } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import { useState, useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import moment from 'moment';
import PantryFooter from 'components/Pantry/PantryFooter';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import CoachCard from './CoachCard';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import MealCardList from './MealCardList';
import { FormContainer } from 'components/auth/StyledLoginForm';
import NutritionistQuote from 'components/NutritionistQuote';
import NutrittSteps from 'components/NutrittSteps';
import RegisterForm from 'components/auth/RegisterForm';
import CoachingPlanList from './CoachingPlanList';
import { ReactComponent as SadEmojiBlue } from '../../../assets/sad-emoji-blue.svg';

const UserNutritionistProfileStyle = styled.div`
  margin: 57px 0 70px;
  background-color: #e5e5e5;
  justify-content: space-between;
  @media (max-width: 991px) {
    min-height: calc(100vh - 102px);
    background-color: #f6f5f8;
    .container {
      padding: 20px 0;
      width: 90%;
      height: auto;
      margin: auto;
    }

    .tabs{
      margin-top:20px;
      display:flex;
      justify-content:center;
      .diet-coaching{
        width:90%;
      }
      .tab-panel{
        border-bottom: 1px solid #AFB5B4;
      }
      .MuiTabs-flexContainer{
        justify-content: center;
      }
      .MuiBox-root{
        padding:0 !important;
      }
    }
  }
  .not-plan{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #51635D;
    text-align:center;
    margin-bottom:10px;
  }
  .list-start {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .list-end {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .upcoming-event {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -87px;
  }
  .list-start
  {
     cursor: pointer;
    margin-top: 10px;
  }
  .list-end {
    cursor: pointer;
    margin-top: 10px;
    .social-image-start {
      background-color: #f0faeb;
      width: 25px;
      height: 80px;
      margin-left: 292px;
      position: absolute;
      img {
        text-align: center;
        padding-top: 32px;
        padding-left: 9px;
      }
    }
  }
  .list-start div {
    top: 355px;
  }
  .list-end div {
    margin-top: 10px;
    width: 335px;
    height: 80px;
    .social-image-start {
      background-color: #f0faeb;
      width: 25px;
      height: 81px;
      margin-left: 290px;
      margin-top: 10px;
      position: absolute;
      top: 440px;
      img {
        text-align: center;
        padding-top: 32px;
        padding-left: 9px;
      }
    }
  }
  .upcoming-events-title {
    // margin-bottom:30px;
    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      text-align: center;
      color: #949d9b;
      // margin-left: 124px;
      // position: absolute;
      // top: 545px;
      padding: 23px;
      display: flex;
      flex-direction: column;
    }
  }
  .card1 {
    margin-bottom: 51px;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 141px);
    background-color: #f6f5f8;
    margin: 0;
    padding-top: 70px;

    .MuiBox-root{
      padding:0 !important;
    }
    .container {
      width: 100%;
      height: auto;
      display: flex;
      justify-content:center;
    }
    .list-start {
      margin-top: 67px;
    }
    .list-start div {
      top: 363px;
    }
    .list-end div{
      .social-image-start {
        margin-top: 17px;
      }
    }
    .upcoming-events-title{
      padding-top: 14px !important;
    }

    .upcoming-event{
      margin-top: -40px;

    }
    .tabs{
      width:100%;
      display:flex;
      justify-content: center;
      margin-top: 90px;
     .diet-coaching{
      width: 70%;
     }
     .tab-panel{
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #AFB5B4;
     }
   }
   .not-plan{
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 28px !important;
  }
  }
  @media(min-width:992px) and (max-width:1290px){
    .diet-coaching{
      width:85% !important;
    }
  }
  @media(min-width:1290px) and (max-width:1550px){
    .diet-coaching{
      width:75% !important;
    }
  }
  @media(min-width:1920px)and (max-width:2550px){
    .diet-coaching{
      width:60% !important;
    }
  }
  @media(min-width:2550px){
    .diet-coaching{
      width:56% !important;
    }
  }
`;

const CoachNotFoundStyle = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: calc(100vh - 52px);
    height: 100%;
    width: 100%;
    background-color: #F6F5F8;
    margin-top: 52px;
    .container{
        font-family: 'Roboto';
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 53px 26px 48px;
        text-align: center;
        max-width: 627px;
        width: 100%;
        display: block;
        .heading{
          margin-top: 40px;
          margin-bottom: 16px;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #51635D;
        }
        .sub_heading{
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #51635D;
          margin-bottom: 24px;
        }
    }
    @media (min-width: 991px){
      min-height: calc(100vh - 52px - 140px);
      .container{
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        padding: 48px 26px 48px;
        .heading{
          margin-bottom: 15px;
        }
        .sub_heading{
          margin-bottom: 55px;
        }
      }
    }
`

export default function MyCoach({publicNutritionist,selectPlan, setShowDietPlans}) {
  const [value, setValue] = useState(0);
  const { nutritionist, offerList, isMobile,user, nutritionistInfo, nutritionistId } = useAppContext();
  const [getUpcomingUserEvents] = useRequest();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [myCoach, setMyCoach] = useState(false);
  const history = useHistory();
  const [insta, setInsta] = useState(false);
  const [faceBook, setFaceBook] = useState(false);
  const [tiktok, setTiktok] = useState(false);
  const [registerCard,setRegisterCard] = useState(false)
  const [mealPlanId,setMealPlanId]=useState(null)

  useEffect(() => {
   getUpcomingEvents();
    setMyCoach(true);
    if (nutritionistInfo) {
    setInsta(nutritionistInfo?.socialProfile?.instagram);
    setFaceBook(nutritionistInfo?.socialProfile?.facebook);
    setTiktok(nutritionistInfo?.socialProfile?.tiktok);
    }
  }, []);
  const mealPlanUrl = user ? '/shop/my-coach/mealplans' : '/guest/mealPlan' ;
  const coachingUrl = user ? '/shop/my-coach/offers' : '/guest/coaching' ;
  const commonNutritionist = nutritionist?nutritionist:publicNutritionist ;
  const {pathname} = useLocation()
  const getUpcomingEvents = () => {
    const path = `coachingOffer/getUpcomingEvents?nutritionistId=${commonNutritionist?.id}`;
    if(user){
      getUpcomingUserEvents({
        path,
      })
        .then((data) => {
          setUpcomingEvents(data);
        })
        .catch(console.log);
    }

  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [register, { isLoading, error, setError }] = useRequest('auth/register', 'post');

  return registerCard? <>
  {isMobile && (
    <div style={{marginTop:"60px"}}>
    <NutrittSteps
      title="Let's get started"
      description="Create an account and begin your health journey"
      activeStep={1}
      stepTitle="Create your account"
      perviousLink={() => {
        setMealPlanId(null);
        window.scrollTo(0, 0);
      }}
    />
    </div>
  )}
  <RegisterForm register={register}  error={error} setError={setError} isLoading={isLoading} nutritionistId={nutritionistId} mealPlanId={mealPlanId}/> </> : (
    <>
    {nutritionistInfo?.isSuspended ? <CoachNotFound/> : 
    <UserNutritionistProfileStyle>
      <div className="container">
        <CoachCard
         coachPhoto={commonNutritionist?.img || '/images/avatar.png'}
         name={commonNutritionist?.name}
         insta={insta}
         faceBook={faceBook}
         tiktok={tiktok}
         nutritionist={commonNutritionist}
         title={commonNutritionist?.title }
         description={commonNutritionist?.description}
         experience={commonNutritionist?.experience}
         experience_type={commonNutritionist?.experience_type}
        />
      </div>
      <div className='tabs'>
      <div className='diet-coaching'>
        <div className={ offerList.length>0 || nutritionistInfo?.dietTemplates.length>0 ? 'tab-panel' : ""}>
    
    {offerList.length>0 && nutritionistInfo?.dietTemplates.length>0 ?
     <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
           <Tab label="Challenges" />
           <Tab label="Coaching" />
            </Tabs>
            :
            offerList.length>0 && <p className='not-plan'>Coaching</p>  || nutritionistInfo?.dietTemplates.length>0 && <p className='not-plan'>Challenges</p> }
            </div>
            <TabPanel value={value} index={0}>
              {nutritionistInfo?.dietTemplates.length>0 &&
              <MealCardList 
                setRegisterCard={setRegisterCard}
                setMealPlanId={setMealPlanId}
              />}
            </TabPanel>
            <TabPanel value={value} index={nutritionistInfo?.dietTemplates.length==0 ? 0 : 1}>
             {offerList.length>0 && <CoachingPlanList/>}
            </TabPanel>
      </div>
      </div>

      {upcomingEvents?.length > 0 &&
      <>
      <div className='upcoming-events-title'>
      <span>Upcoming events</span>
      </div>
      </>
      }
      {upcomingEvents?.map((item) => (
        <div className='upcoming-event'>
        <ProfileCard
         text={item?.coachingOffer?.title}
         description={" - "}
         time={item?.NutritionistMeetingMapping[0]?.scheduledDateTime}  
         endTime = {(moment(item?.NutritionistMeetingMapping[0]?.scheduledDateTime).add(item.coachingOffer?.availability,'minutes'))._d} 
         arrow = {false}
         />
         </div>))}
      </UserNutritionistProfileStyle>
    }
      {(isMobile && user) &&
      <PantryFooter myCoach={true} shop={false} progress={false} />
      }
    </>
  )
}

function CoachNotFound(){
  return(
    <>
    <CoachNotFoundStyle>
        <div className='container'>
            <SadEmojiBlue />
            <h2 className='heading'>Oops!!</h2>
            <p className='sub_heading'>Coach not found.</p>
        </div>
    </CoachNotFoundStyle>
    </>
  )
}