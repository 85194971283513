import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import { IoSettingsOutline } from 'react-icons/io5';
import Tooltip from 'rc-tooltip';

export const providersFilter = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Unlisted', value: 'unlisted' },
    { label: 'Unclaimed ', value: 'unclaimed' },
    {label:"suspended",value:"suspended"}
];

export const providerStatus = [
    { label: 'Active', value: 'active' },
    { label: 'Unlisted', value: 'unlisted' },
    {label:"Suspended",value:"suspended"}
];

export const providerDisabled = [{ label: 'Unclaimed ', value: 'unclaimed' }];

export const columns = [
    {
        id: 'name',
        header: 'Name',
        render: ({ name, image, id: providerId }) => (
            <div className="provider-name-wrapper">
                <img src={image || '/images/man.png'} alt="provider Img" onError={(e) => { e.target.src = '/images/man.png' }}/>
                {name?.length > 50 ? 
                <Tooltip placement="bottomLeft" trigger={['hover']} overlay={name}>
                <div
                    className="provider-name"
                    onClick={() => {
                        const url = `/kitchen?providerId=${providerId}`;
                        window.open(url, '_blank');
                    }}
                >
                    {name}
                </div>
                </Tooltip>
                :
                <div
                    className="provider-name"
                    onClick={() => {
                        const url = `/kitchen?providerId=${providerId}`;
                        window.open(url, '_blank');
                    }}
                >
                    {name}
                </div>
                }
            </div>
        ),
        width: '30%',
    },
    {
        id: 'pendingOrders',
        header: 'Pending Orders',
        render: ({ pendingOrdersCount }) => {
            const { isMobile } = useAppContext();
            return (
                <div className="pending-order-number">
                    <div>
                        {isMobile ? (
                            <>
                                Pending orders: <div className="item">{pendingOrdersCount || 0}</div>
                            </>
                        ) : (
                            pendingOrdersCount || 0
                        )}
                    </div>
                </div>
            );
        },
        width: '14%',
    },
    {
        id: 'fee',
        header: '% Fee',
        render: ({ providerFee }) => {
            const { isMobile } = useAppContext();
            return (
                <div className="item-number">
                    {isMobile ? (
                        <>
                            %Fee: <div>{providerFee}</div>
                        </>
                    ) : (
                        providerFee
                    )}
                </div>
            );
        },
        width: '14%',
    },
    {
        id: 'markup',
        header: '% Markup',
        render: ({ userMarkup }) => {
            const { isMobile } = useAppContext();
            return (
                <div className="item-number">
                    {isMobile ? (
                        <>
                            %Markup: <div>{userMarkup}</div>
                        </>
                    ) : (
                        userMarkup
                    )}
                </div>
            );
        },
        width: '14%',
    },
    {
        id: 'accountStatus',
        header: 'Account Status',
        render: ({ accountStatus }) => (
            <div className="account-status">
                <div
                    className={classNames({
                        unlisted: accountStatus === 'unlisted',
                        unclaimed: accountStatus === 'unclaimed',
                    })}
                >
                    {accountStatus || 'Unclaimed'}
                </div>
            </div>
        ),
        width: '14%',
    },
    {
        id: 'manage',
        header: 'Manage',
        render: (provider, { handleSettings }) => (
            <div className="manage-wrapper">
                <div className="setting-icon-wrapper" onClick={() => handleSettings(provider)}>
                    <IoSettingsOutline className="setting-icon" />
                </div>
            </div>
        ),
        width: '14%',
    },
];