import { useState } from 'react';

const tryParse = (str) => {
  try {
    if (str == undefined || str == undefined) return str;
    if (str == 'undefined') return undefined;
    if (str == 'null') return null;
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};
export const usePersistedState = (key, initialState) => {
  const val = tryParse(localStorage.getItem(key));
  // console.log(key, "parsedval", val);

  const [state, setState] = useState(val || initialState);
  const setPersistedState = (newState) => {
    if (newState == undefined) {
      localStorage.removeItem(key);
      setState(initialState);
      return;
    }
    setState(newState);
    const nextState = typeof newState == 'function' ? newState(state) : newState;
    // console.log(key, JSON.stringify(nextState));
    localStorage.setItem(key, JSON.stringify(nextState));
  };
  return [state, setPersistedState];
};

