import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchensListWrapper = styled.div`
  .kitchens-btn-wrapper {
    padding: 15px 0;
    display: flex;
    justify-content: center;
    .btn.dark {
      width: 265px;
      height: 42px;
      padding: 0;
      border-radius: 30px;
      font-family: ${theme.typography.fontGilroy};
      font-size: 16px;
    }
  }

  .kitchens-list-wrapper {
    padding: 0 16px 32px;
    @supports (-webkit-overflow-scrolling: touch) {
      padding:${({ isWebView }) => isWebView ? "0 16px 32px" : "0 16px 120px"};
    }
    background-color: ${() => theme.colors.secondary};
    .kitchens-title {
      padding: 19px 0;
      color: ${() => theme.colors.primaryDarker};
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .kitchens-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &.displayKitchenMeals {
    padding-top: 8px;
    padding-bottom: 0;
  }

  @media (min-width: 992px) {
    max-width: 1254px;
    margin: 0 auto;
    padding-bottom: 0;

    &.displayKitchenMeals {
      padding: 100px 0;
    }

    &.kitchensListNoFavoriteKitchen {
      padding-top: 0;
      .kitchens-title {
        padding: 8px 0 24px;
      }
    }

    .kitchens-btn-wrapper {
      padding: 48px 0;
      .btn.dark {
        width: 195px;
        height: 42px;
        font-weight: 700;
      }
    }

    .kitchens-list-wrapper {
      padding: 0;
      .kitchens-title {
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .kitchens-list-container {
      gap: 32px;
    }
  }
  @media (max-width: 991px) {
    padding-bottom: 37px;
    @supports (-webkit-overflow-scrolling: touch) {
      padding-bottom:${({ isWebView }) => isWebView ? "37px" : "100px"};
    }
  }

`;
