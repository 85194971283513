import { useState } from 'react';
import classNames from 'classnames';
import Styled from 'styled-components';
import { isArray } from 'lodash';

const HowMuchModalWrapper = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 119;
  padding: 178px 20px 0;
  background-color: #51635d80;
  .how-much-Wrapper {
    position: relative;
    padding: 21px 20px; 24px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    .how-much-title {
      margin-bottom: 24px;
      color: #51635D;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
    .how-much-items {
      overflow: auto;
      max-height: 300px;
      &::-webkit-scrollbar {
        width: 0
      }
    }
    .item-wrapper {
      height: 59px;
      padding: 0 10px 0 16.73px;
      border-bottom: 1px solid #F6F5F8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        opacity: 0;
      }
    }
    .item-title-wrapper {
      display: flex;
      flex-direction: column;
    }
    .item-title {
      color: #0B1D17;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .plate-qty {
      color: #949D9B;
      font-size: 12px;
      line-height: 14px;
    }
    img.displaySelected {
      transition: all .5s ease-in-out;
      opacity: 1;
    }
  }
`;

export default function HowMuchModal({ setIsHowMuchModal, setMealMacroInfo, searchedFoodDetails }) {
  const [selected, setSelected] = useState();
  const macrosServingData = searchedFoodDetails;
  const isServingArray=isArray(macrosServingData);
  let servingData=[]
  if(!isServingArray){
    servingData.push(macrosServingData)
  }
  else{
    servingData=[...macrosServingData]
  }
  const handleServingDescription = (item) => {
    const servingDescriptionSplit = item?.serving_description.split(' (');
    return servingDescriptionSplit[0];
  }

  const handleSelect = (item) => {
    setMealMacroInfo(item);
    setSelected(item?.serving_id)
    setTimeout(() => {
      setIsHowMuchModal(false);
    }, 800);
  };

  return (
    <HowMuchModalWrapper>
      <div className="how-much-Wrapper">
        <img
          src="/icons/close-green.svg"
          className="close-icon"
          onClick={() => setIsHowMuchModal(false)}
        />
        <h3 className="how-much-title">How much</h3>
        <div className="how-much-items">
          {servingData?.map((item) => (
            <div key={item?.serving_id} className="item-wrapper" onClick={() => handleSelect(item)}>
              <div className="item-title-wrapper">
                <span className="item-title">{item?.serving_description}</span>
              </div>
              <img
                src="/icons/check.svg"
                alt="Check Icon"
                className={classNames({ displaySelected: selected == item?.serving_id })}
              />
            </div>
          ))}
        </div>
      </div>
    </HowMuchModalWrapper>
  );
}
