import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Line } from 'rc-progress';
import classNames from 'classnames';
import styled from 'styled-components';
import { profile } from './PlanningData';
import { useAppContext } from 'context/appContext';
import FilterMealsData from './FilterMealsData';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiaryWrapper = styled.div`
  padding-top: 7px;
  background: #f5f6f6;
  border-radius: 10px;

  .diary-header-section {
    .date-duration-wrapper {
      margin-bottom: 15px;
      padding: 0 5px;
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      align-items: center;

      .current-week {
        color: #51635d;
        font-size: 12px;
        font-family: 'Roboto';
        letter-spacing: 0.5px;
        justify-self: center;
      }

      .next-week-icon {
        justify-self: right;
      }

      .arrow-icon-wrapper {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
        ${FlexCenter};

        img {
          width: 10px;
        }
      }
    }

    .diary-header-row {
      position: relative;
      padding-bottom: 8px;
      box-shadow: 0px 8px 10px rgb(0 0 0 / 6%);
      display: flex;
      align-items: center;
    }

    .meals-data-title {
      width: 60px;
      color: #949d9b;
      font-size: 9px;
      line-height: 12px;
      letter-spacing: 0.05em;
      flex-shrink: 0;
      text-transform: uppercase;
      ${FlexCenter};

      img {
        width: 8px;
        margin-left: 3px;
        transition: all 0.3s ease-in-out;
      }
    }

    .meals-count-wrapper {
      overflow: hidden;
      padding-left: 10px;
      display: flex;

      ::-webkit-scrollbar {
        height: 0;
      }

      span {
        width: 44px;
        margin-right: 10px;
        color: #949d9b;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.05em;
        flex-shrink: 0;
        text-align: center;
      }
    }

    .diary-header-shadow {
      width: 100%;
      height: 7px;
      background: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(3, 3, 3, 0));
      opacity: 0.7;
      position: absolute;
      top: 20px;
      left: auto;
    }
  }
  
  .diary-body-div{
    height:338px;
    overflow-y:auto;
  .diary-body-section {
    display: flex;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #afb5b4;
    }

    .meal-info-column {
      width: 60px;
      padding-top: 10px;
      box-shadow: 6px 0 4px -4px rgb(0 0 0 / 5%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .day-progress-wrapper {
      width: 44px;
      height: 55px;
      margin-bottom: 14px;
      padding: 2px 0;
      background-color: #fff;
      color: #51635d;
      border-radius: 6px;
      box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
      text-align: center;
    }

    .day-container {
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #949d9b;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.05em;
      }

      span:last-child {
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
        letter-spacing: 0.5px;
      }
    }

    .day-progress {
      margin-top: -9px;
    }

    .total-info {
      margin-top: -2px;
      font-size: 10px;
      line-height: 12px;
      display: block;
    }

    .meal-data-column {
      overflow-y: hidden;
      overflow-x: auto;
      width: calc(100% - 60px);
      height: max-content;
      padding: 10px 0 0 10px;

      ::-webkit-scrollbar {
        height: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #afb5b4;
      }
    }

    .meals-row {
      height: 55px;
      margin-bottom: 14px;
      display: flex;
    }

    .meal-wrapper {
      position: relative;
      z-index: 2;
      width: 44px;
      height: 55px;
      padding: 5px 7px;
      margin-right: 10px;
      background-color: #fff;
      border: 1px solid #e0e3e0;
      border-radius: 6px;
      -webkit-filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.1));
      ${FlexCenter};

      img {
        width: 28px;
        height: 43px;
        border-radius: 6px;
        object-fit: cover;
        font-size:11px;

        &.customMeal {
          object-fit: contain;
        }
      }
    }
  }
 }

  @media (min-width: 992px) {
    padding-top: 20px;

    .diary-header-section {
      .date-duration-wrapper {
        padding: 0 26px;

        .arrow-icon-wrapper {
          width: 28px;
          height: 28px;
          cursor: pointer;

          img {
            width: 14px;
          }
        }

        .current-week {
          font-size: 16px;
        }
      }

      .meals-data-title {
        width: auto;
        padding: 0 30px 0 40px;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;

        .date-column {
          width: 60px;
          text-align: center;
        }

        .cals-wrapper {
          width: 80px;
          cursor: pointer;
          ${FlexCenter};

          img {
            width: 10px;

            &.isFilterMealsData {
              transform: rotate(180deg);
            }
          }
        }
      }

      .meals-count-wrapper {
        padding-left: 28px;

        span {
          width: 56px;
          margin-right: 30px;
          font-size: 11px;
          text-transform: uppercase;
        }
      }
    }
    .diary-body-div{
       height:514px;
      overflow-y:auto;
    .diary-body-section {
      height: max-content;

      .meal-info-column {
        width: fit-content;
        height: max-content;
        padding: 15px 30px 0 40px;
      }

      .data-column-wrapper {
        width: fit-content;
        height: 70px;
        min-height: 70px;
        margin-bottom: 14px;
        ${FlexCenter};
      }

      .day-progress-wrapper {
        width: 140px;
        height: 56px;
        margin: 0;
        ${FlexCenter};
      }

      .day-container {
        width: 60px;
        flex-shrink: 0;
        height: 100%;
        justify-content: center;

        span:last-child {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .day-progress {
        width: 80px;
        height: 100%;
        margin: 0px;
        padding: 0px 10px;
        border-left: 1px solid #f6f5f8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-flow: column-reverse;
      }

      .total-info {
        margin: 0 0 5px;
        font-size: 14px;
        line-height: 16px;
      }

      .meal-data-column {
        padding: 15px 0 0 28px;
      }

      .meals-row {
        width: max-content;
        height: 70px;
        margin-bottom: 14px;
      }

      .meal-wrapper {
        width: 56px;
        height: 70px;
        margin-right: 30px;

        img {
          width: 38px;
          height: 54px;
        }
      }
    }
   }
  }
`;

const mealsData = ['calories', 'protein', 'fat', 'carbs'];

export default function Diary({ diaryData }) {
  if(!diaryData){
    return "No Data Available";
  }
  const [dateSelected, setDateSelected] = useState(diaryData[0].suggestedDate);
  const previousWeek = moment(dateSelected).subtract(7, 'days').format('YYYY-MM-DD');
  const nextWeek = moment(dateSelected).add(7, 'days').format('YYYY-MM-DD');
  const [endDate, setEndDate] = useState();
  const [active, setActive] = useState(0);
  const [mealsDataItem, setMealsDataItem] = useState(mealsData[0]);
  const [isFilterMealsData, setIsFilterMealsData] = useState(false);
  const diaryMealsWrapper = useRef();
  const mealsTitleWrapper = useRef();
  const {
    dietMetrics: { calories, macros },
  } = profile;
  const { isMobile } = useAppContext();
  const [selectedMonthDiaries, setSelectedMonthDiaries] = useState();
  const [selectedMonth, setSelectedMonth] = useState(diaryData[0] && Object.keys(diaryData[0])[0])
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(selectedMonth && Object.keys(diaryData[0]).findIndex((date) => date == selectedMonth))
  const [highestMeals, setHighestMeals] = useState(0)
  const [selectedNutrients, setSelectedNutrients] = useState("calories")
  const [showNextButton, setShowNextButton] = useState(true)
  const [showPrevButton, setShowPrevButton] = useState(true)




  const calculateNutrients = (data, type) => {
    if (type == "calories") {
      return data.reduce((a, b) => +a + +b.calories, 0)
    } else if (type == "carbs") {
      return data.reduce((a, b) => +a + +b.carbs, 0)
    }
    else if (type == "protein") {
      return data.reduce((a, b) => +a + +b.protein, 0)
    }
    else if (type == "fat") {
      return data.reduce((a, b) => +a + +b.fat, 0)

    }

  }

  const progressColor = (data, type) => {
    let color = '';
    let precent = 0;
    const profiledataItem = type == 'calories' ? calories : macros[type];
    precent = (calculateNutrients(data, type) / profiledataItem) * 100;

    if (calculateNutrients(data, type) == 0) {
      color = '#e0e3e0';
    } else if (calculateNutrients(data, type) > 0 && calculateNutrients(data, type) <= profiledataItem) {
      color = '#52C41A';
    } else if (
      calculateNutrients(data, type) > profiledataItem &&
      calculateNutrients(data, type) <= profiledataItem + 125
    ) {
      color = '#35A956';
    } else if (
      calculateNutrients(data, type) > profiledataItem + 125 &&
      calculateNutrients(data, type) <= profiledataItem + 250
    ) {
      color = '#FFCA06';
    } else if (calculateNutrients(data, type) > profiledataItem + 250) {
      color = '#FF4F00';
    }

    return { color, precent };
  };



  const filterDataByMonth = (month) => {
    const element = diaryData[0];
    for (let keys in element) {
      if (keys == month) {
        const data = diaryData[0][keys]
        let mostMeals = []
        const sorted = Object.keys(data).map(date => {
          const hasMaxMeal = data[date].reduce(function (prev, current) {
            return (prev.y > current.y) ? prev : current
          })
          mostMeals.push(hasMaxMeal)
          return {
            date: date,
            data: data[date],
            nutrients: Math.round(calculateNutrients(data[date], selectedNutrients))
          }
        });
        setHighestMeals(mostMeals.length)

        setSelectedMonthDiaries(sorted)
      }
    }

  };


  useEffect(() => {
    if (diaryData) {
      filterDataByMonth(selectedMonth);
    }
  }, [diaryData, selectedMonth, selectedNutrients]);

  useEffect(() => {
    const onScroll = () => {
      mealsTitleWrapper.current.scrollLeft = diaryMealsWrapper.current.scrollLeft;
    };
    diaryMealsWrapper?.current?.addEventListener('scroll', onScroll);
  });

  useEffect(() => {
    isMobile && isFilterMealsData
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [isFilterMealsData]);

  useEffect(() => {
    if (!isMobile) {
      setMealsDataItem(mealsData[active]);
      setIsFilterMealsData(false);
    }
  }, [active]);

  
  return (
    <DiaryWrapper>
      <div className="diary-header-section">
        <div className="date-duration-wrapper">
          <div className="previous-week-icon">
            {diaryData && showPrevButton && (
              <div className="arrow-icon-wrapper" onClick={() => {
                if (selectedMonthIndex < (Object.keys(diaryData[0]).length - 1)) {
                  setShowNextButton(true)
                  setSelectedMonthIndex(selectedMonthIndex + 1)
                }

              }}>
                <img src="/icons/angle-left.svg" alt="Arrow Icon" />
              </div>
            )}
          </div>
          <div className="current-week">{selectedMonth}</div>
          <div className="next-week-icon">
            {diaryData && showNextButton && (
              <div className="arrow-icon-wrapper" onClick={() => {
                if (selectedMonthIndex > 0) {
                  setSelectedMonthIndex(selectedMonthIndex - 1)

                }
              }}>
                <img src="/icons/angle-right.svg" alt="Arrow Icon" />
              </div>
            )}
          </div>
        </div>
        <div className="diary-header-row">
          <div className="diary-header-shadow" />
          <div className="meals-data-title" onClick={() => setIsFilterMealsData(true)}>
            {!isMobile && <div className="date-column">DATE</div>}
            <div className="cals-wrapper">
              {isMobile ? (
                <span>{mealsDataItem == mealsData[0] ? 'Cals' : mealsDataItem}</span>
              ) : (
                <span>{mealsDataItem}</span>
              )}
              <img
                src="/icons/arrow-down.svg"
                alt="Arrow Icon"
                className={classNames({ isFilterMealsData })}
              />
            </div>
          </div>
          {isFilterMealsData && (
            <FilterMealsData
              setIsFilterMealsData={setIsFilterMealsData}
              setMealsDataItem={setMealsDataItem}
              mealsData={mealsData}
              active={active}
              setActive={setActive}
              setSelectedNutrients={setSelectedNutrients}
            />
          )}

          <div className="meals-count-wrapper">
            {[...Array(highestMeals)].map((_, index) => (
              <span key={index}>Meal {index + 1}</span>
            ))}
          </div>
        </div>
      </div>
      <div className='diary-body-div'>
        {
          selectedMonthDiaries?.map((meal) => {
            return (
              <div>
                <div className="diary-body-section">
                  <div className="meal-info-column">
                    <div className="data-column-wrapper">
                      <div className="day-progress-wrapper">
                        <div className="day-container">
                          <span>
                            {
                              moment(meal.date).format("ll").split(" ")[0]
                            }
                          </span>
                          <span>
                            {
                              moment(meal.date).format("ll").split(" ")[1].slice(0, -1)
                            }

                          </span>
                        </div>
                        <div className="day-progress">
                          <Line
                            percent={progressColor(meal?.data, selectedNutrients).precent}
                            strokeWidth="15"
                            strokeColor={progressColor(meal.data, selectedNutrients).color}
                            trailWidth="15"
                            height="6px"
                            trailColor="#e0e3e0"
                          />
                          <div className="total-info">{meal.nutrients}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="meal-data-column">
                    <div className="meals-row">
                      {
                        meal?.data?.map((item) => (

                          <div className="meal-wrapper">
                            <img
                              src={item.img}
                              alt="Meal Image"
                              className={classNames({ customMeal: false })}
                            />
                          </div>
                        ))
                      }

                    </div>

                  </div>
                </div>
              </div>
            )

          })
        }
      </div>




    </DiaryWrapper>
  );
}

