import { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { useAppContext } from 'context/appContext';
import NutritionistNewPlan from './NutritionistNewPlan';
import NutritionistMealPlan from './NutritionistMealPlan';
import NutritionistSettings from './NutritionistSettings';
import NutritionistReports from './NutritionistReports';
import NutristionistSidebar from 'components/Nutritionist/NutristionistSidebar';
import { NutritionistContextProvider, useNutritionistContext } from '../../context/nutritionistContext';
import ClaimNutritionistAccount from 'components/admin/ClaimNutritionistAccount';
import NutritionistOfferingEdit from './NutritionistOfferingEdit';
import NutritionistOfferingList from './NutritionistOfferingList';
// import NutritionistCoachingClients from './NutritionistCoaching';
import NutritionistPlanning from './NutritionistPlanning';
import DashboardClientInvite from 'pages/dashboard/clients/invite';
import DashboardClient from 'pages/dashboard/clients/index';
import NutritionistPayReports from './NutritionistPayReports';
import NutritionistOfferingReport from './NutritionistOfferingReport';
import NutritionistHome from './NutritionistHome';
import NutritionistClientProfile from './Followers/NutritionistClientProfile';
import { Link, useLocation } from 'react-router-dom';
import NutritionistFollowers from './NutritionistFollowers';
import NutritionistNavigationBar from './NutritionistNavigationBar';
import NutritionistProfilePage from './NutritionistProfilePage';
import Challanges from 'components/Challange/Challanges';
import CreateChallenge from 'components/Challange/CreateChallenage'
import EditChallenges from 'components/Challange/EditChallenge'
import Share from 'components/Challange/Share';
import SendMealsModal from "./Followers/ClientsCart/SendMealsModal"
import ProviderList from './Followers/ClientsCart/ProviderList';
import MealPlanner from './Followers/ClientsCart/MealPlanner';
import { ChatContextProvider } from 'context/chatContext';


const NutritionistStyle = styled.div`
  overflow-x: hidden;
  min-height: calc(100vh - 32px);
  .nutritionistbody{
    background:#ffffff !important;
    min-height: calc(100vh - 32px);
  }
  .main-screen {
    width: 100%;
  }
  
  .is-admin {
    background-color: red;
    width: calc(100% - 300px);
    text-align: center;
    margin: 0 0 0 auto;
  }
  header {
    height: 52px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px #0000001a;

    .icon-wrapper {
      display: flex;
      cursor: pointer;

      svg {
        font-size: 20px;
      }
    }

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .container {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 991px) {
    header {
      position: fixed;
      width: 100%;
      top:0;
      z-index:200;
      background-color: #fff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

      .img-wrapper {
        display: flex;
        img{
          width: 71px;
          height: 28.38px;
        }
      }
    }
    .nutritionist-image{
      object-fit:cover;
      width: 50%;
      display: flex;
      justify-content: right;
      img{
        width:25px;
        height:25px;
        object-fit:cover;
        border-radius:50%;
      }
    }

    .nutritionist-wrapper {
      min-height: calc(100vh - 32px);

      .MuiDrawer-paperAnchorBottom{
        height:80% !important;
      }
    }
    .challenge-background{
      background-color: #F6F5F8 !important;
    }
    .MuiTabs-flexContainer {
      justify-content: space-between;

      .MuiTab-root {
        padding: 0 12px;
      }

      .MuiButtonBase-root .MuiTab-wrapper {
        font-size: 18px;
      }
    }

    .MuiTabs-indicator {
      height: 3px !important;
    }

    .form-section {
      margin-bottom: 24px;
      flex-direction: column;

      .form-group-wrapper + .form-group-wrapper {
        margin-top: 24px;
      }

      .form-group {
        margin-bottom: 24px;

        &.form-group-section {
          width: 100%;
        }

        &.form-group-section:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .form-group {
      margin-bottom: 24px;
    }

    label {
      margin-bottom: 7px !important;
      font-size: 12px !important;
    }

    .save-btn,
    .plan-btn button {
      width: 100%;
      height: 44px;
      padding: 0;
      border-radius: 6px;
    }

    .notification{
       display:flex;
    }

    .total-notify{
      width:10px;
      height:10px;
      background: #EB3223;
      font-weight: 700;
      font-size: 7px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content:center;
      color: #FFFFFF;
      border-radius:125px;
      margin-left: -7px;
      margin-top: -4px;
    }
    .screen{
      padding-bottom:70px;
    }
  }
`;

export default function Nutritionist() {
  const { isMobile, isDesktop, isSuper } = useAppContext();
  const [displayMobileSidebar, setDisplayMobileSidebar] = useState(false);
  const [getForm, setGetForm] = useState();
  const [isAlert, setIsAlert] = useState(false);
  const [link, setLink] = useState();
  const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
  const localnutritionist = JSON.parse(localStorage.getItem('nutritionist'));
  const { pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams()
  const{modalShow,setModalshow} = useAppContext();
   
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDisplayMobileSidebar(open);
  };
  const [clientModal, setClientModal] = useState(false);
  const [locationKeys, setLocationKeys] = useState([])
  
  useEffect(() => {
    return history.listen(location => {

      if (history.action === 'POP') {
        localStorage.removeItem('activeClients')
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
          setClientModal(false)
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          setClientModal(false)
        }
      }
    })
  }, [locationKeys,])
  return (
    <NutritionistContextProvider>
      <ChatContextProvider>
      <NutritionistStyle>
        <div className={pathname === "/nutritionist/home" && !isMobile ? 'nutritionistbody' : ''}>
          {pathname === '/nutritionist/Challanges/create' || pathname.includes(`/nutritionist/Challanges/edit`) || pathname.includes(`/nutritionist/Challanges/share`) || pathname.includes(`/nutritionist/Challanges/create/sharechallenge`) ? ' ' :
          isMobile && (
            !clientModal &&  <header>
              <div className="container">
                <div className="header-wrapper">
                  <div className="img-wrapper">
                    <img className="nutritt-logo" src="/images/nutritt-logo.svg" alt="logo" />
                  </div>
                  {/* <div className='nutritionist-image'>
              <img src={localnutritionist?.nutritionist?.img || "/images/avatar.png"} alt="logo" width="100" />
                </div>
                <div className="icon-wrapper" onClick={toggleDrawer(true)}>
                  <img src="/icons/menu.svg" alt="Menu Icon" />
                </div> */}
                  {/* {initially commenting the bell icon because its hardcoded} */}
                  {/* <div className='notification'>
                <img src='/icons/bell.svg' alt='notification'/>
                <p className='total-notify'>3</p>
                </div>  */}
                </div>
              </div>
            </header> 
          )}
          <div className={pathname.includes("/nutritionist/Challanges") ? 
              pathname.includes("/sharechallenge/") ? 
              "nutritionist-wrapper nutritionist-challanges-wrap sharechallenge" :
              "nutritionist-wrapper nutritionist-challanges-wrap" : 
              "nutritionist-wrapper"}>
            <Drawer anchor="bottom" open={displayMobileSidebar} onClose={toggleDrawer(false)}>
              <NutristionistSidebar
                getForm={getForm}
                isAlert={(link) => {
                  setIsAlert(true);
                  setLink(link);
                }}
                closeSidebar={() => setDisplayMobileSidebar(false)}
              />
            </Drawer>
            <div className={pathname === "/nutritionist/Challanges"  ? `main-screen-wrapper ${!clientModal && pathname !== "/nutritionist/clients" ? 'screen' : ''}` : `main-screen ${!clientModal && pathname !== "/nutritionist/clients" ? 'screen' : ''}`}>
              {isDesktop && (
                <NutristionistSidebar
                  getForm={getForm}
                  isAlert={(link) => {
                    setIsAlert(true);
                    setLink(link);
                  }}
                />
              )}
              {isSuper && (
                <p className="is-admin">
                  ADMIN
                </p>
              )}
              {isSuper && !(imitateNutritionist?.userId != null) && <ClaimNutritionistAccount />}
              <Switch>
                <Route exact path="/nutritionist">
                  <Redirect to="/nutritionist/home" />
                </Route>
                <Route exact path={isMobile ? '/nutritionist/Challanges' : <Redirect to="/nutritionist/home" />}
                >
                  <Challanges 
                  getForm={setGetForm}
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  link={link}
                  modalShow={modalShow}
                  setModalshow={setModalshow}
                  />
                </Route>
                <Route exact path="/nutritionist/Challanges/share/:challangeId" component={Share} />
                <Route exact path="/nutritionist/Challanges/create"><CreateChallenge getForm={setGetForm}
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  link={link} /> </Route>
                <Route exact path="/nutritionist/Challanges/edit/:id"><EditChallenges getForm={setGetForm}
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  link={link} /> </Route>
                <Route exact path="/nutritionist/Challanges/create/sharechallenge/:challangeId" component={Share} />
              <Route
                exact
                path="/nutritionist/coaching/offering"
                component={NutritionistOfferingList}
              />
              <Route
                exact
                path="/nutritionist/coaching/offering/create"
                component={NutritionistOfferingEdit}
              />
              <Route
                exact
                path="/nutritionist/coaching/offering/edit/:id"
                component={NutritionistOfferingEdit}
              />
              <Route exact path="/nutritionist/reports/offering" component={NutritionistOfferingReport}/>
              <Route exact path="/nutritionist/reports/meals" component={NutritionistReports}/>
              <Route exact path="/nutritionist/coaching/clients" component={DashboardClient}/>
              <Route exact path="/nutritionist/clients" component={DashboardClient} />
              <Route exact path="/nutritionist/reports" component={NutritionistPayReports} />
              <Route exact path="/nutritionist/settings" component={NutritionistSettings} />
              <Route exact path="/nutritionist/home">
              <NutritionistHome 
              modalShow={modalShow} 
              setModalshow={setModalshow}
              getForm={getForm}
              isAlert={isAlert}
              setIsAlert={setIsAlert}
              link={link} 
              />
              </Route>
              <Route exact path="/nutritionist/clients/profile/:userId" component={NutritionistClientProfile} />
              <Route exact path="/nutritionist/planning/:userId" component={NutritionistPlanning} />
              <Route exact path="/nutritionist/coaching/invite">
                
                <DashboardClientInvite />
            
              </Route>
              <Route exact path="/nutritionist/followers"> <NutritionistFollowers setClientModal={setClientModal} clientModal={clientModal}/></Route>
              <Route exact path="/nutritionist/profile" component={NutritionistProfilePage} />

                
                {isMobile ? <Route exact path="/nutritionist/Challanges" component={Challanges} /> : 
                <Route exact path="/nutritionist/meal-plan" component={NutritionistMealPlan} />}

                <Route exact path="/nutritionist/meal-plan/new">
                  <NutritionistNewPlan
                    getForm={setGetForm}
                    isAlert={isAlert}
                    setIsAlert={setIsAlert}
                    link={link}
                  />
                </Route>
                <Route exact path="/nutritionist/meal-plan/edit/:id">
                  <NutritionistNewPlan
                    getForm={(value) => setGetForm(value)}
                    isAlert={isAlert}
                    setIsAlert={setIsAlert}
                    link={link}
                  />
                </Route>

              </Switch>
            </div>
          </div>
        </div>
        {isMobile && <NutritionistNavigationBar toggleDrawer={toggleDrawer} setModalshow={setModalshow}/>}
        {/* {isMobile && !clientModal && <NutritionistNavigationBar toggleDrawer={toggleDrawer} />} */}
      </NutritionistStyle>
      </ChatContextProvider>
    </NutritionistContextProvider>
  );
}


