import GetUserInfo from './GetUserInfo';
import styled from 'styled-components';
import Footer from 'layout/Footer';
import { Link } from 'react-router-dom';
import { Route, Switch} from 'react-router-dom';
import CreatePassword from './CreatePassword';
import MealPlansList from 'pages/shop/CoachProfile/MealPlansList';
import OfferPlansList from 'pages/shop/CoachProfile/OfferPlansList';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import Header from 'layout/Header';

const GuestUserWrapper = styled.div`
    margin-top: 57px;

    .has-account {
        margin-bottom: 12px;
        color: #51635d;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        font-weight: 400;
        a{
           color: #52c41a !important;
        }
    }

@media(min-width: 992px){
    min-height: 75vh;
    margin-top: 75px;
}
`;

export default function GuestUser() {
    const {isMobile, user} = useAppContext();
    const { pathname } = useLocation();
    return (
        <GuestUserWrapper>
            {(!user && pathname == "/guest/mealPlan" ) && <Header/>}
            <Switch>
                <Route 
                   exect 
                   path="/guest/mealPlan" 
                   component={MealPlansList}
                />
                <Route 
                   exect 
                   path="/guest/coaching" 
                   component={OfferPlansList}
                />
                <Route
                   exact
                   path="/guest/info"
                   component={GetUserInfo}
                />
                <Route
                   exact
                   path="/guest/create-password"
                   component={CreatePassword}
                />
            </Switch>
            { (pathname.includes('/guest/info')) &&
                <div className="has-account">
                Already have an account? <Link to="/login">Log in</Link>
            </div>
            }
            {(!user && isMobile && pathname !== "/guest/coaching" ) && <Footer />}
        </GuestUserWrapper>
    );
};