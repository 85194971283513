import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Circle } from 'rc-progress';
import { theme } from 'shared/theme/theme';

const CircularProgressStyle = styled.div`
  position: relative;
  margin: auto;
  .circular_div {
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme?.colors?.grayLighter};
    padding: 6px;
    svg{
      width: 100%;
    }
  }
  svg {
    display: block;
  }
`;

const CircularProgress = ({
  percentage,
  currentDayTotalCals,
  strokeWidth = 4,
  trailWidth = 4,
  capColor = '#FFF',
  capWidthHeight = '14px',
  width = '176px',
  height = '176px',
}) => {
  const [circlePosition, setCirclePosition] = useState(0);

  useEffect(() => {
    const newPosition = (percentage / 100) * 360;
    setCirclePosition(newPosition);
  }, [percentage]);

  return (
    <>
      <CircularProgressStyle theme={theme} style={{ width, height }}>
        <div className="circular_div">
          <Circle
            percent={percentage}
            strokeWidth={strokeWidth}
            strokeColor={Math.round(currentDayTotalCals) === 0 ? '#EBEBEB' : '#7CBB00'}
            trailWidth={trailWidth}
            trailColor="#EBEBEB"
            strokeLinecap="round"
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              inset: 0,
              transform: `rotate(${circlePosition}deg)`,
            }}
          >
            <div
              style={{
                width: capWidthHeight,
                height: capWidthHeight,
                backgroundColor: capColor,
                borderRadius: '50%',
                position: 'absolute',
                top: '8px',
                left: '50%',
                transform: 'translate(-50%, -25%)',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
              }}
            ></div>
          </div>
        </div>
      </CircularProgressStyle>
    </>
  );
};

export default CircularProgress;
