import { useEffect, useState } from 'react';
import produce from 'immer';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import KitchenCard from 'components/elements/KitchenCard';
import { Button } from 'components/elements/button/Button';
import KitchenCardDesktop from 'components/shop/KitchenHomeDesktop/KitchenCardDesktop';
import { KitchensListWrapper } from './style';
import { useVisitorContext } from 'context/visitorContext';
import { getEarliestTime } from 'pages/shop/providerTimes';
import moment from 'moment';

export default function KitchensList({ kitchensList, storeCategoriesMeals, favoriteKitchenData, showKitchenList = false, setToDetailsPage, showDeliveringKitchensList }) {
  const [isKitchenList, setIsKitchenList] = useState(showKitchenList);
  const { profile, isMobile ,user, isWebView} = useAppContext();
  const { updateSettings } =user? useShopContext():useVisitorContext();
  const history = useHistory();

  const getNextDay = (date = new Date(), kitchen) => {
    let result = {};
    const deliveryDateTime = getEarliestTime(kitchen?.schedule, "delivery");
    const momentEarliestDelivery = moment(deliveryDateTime);

    if (momentEarliestDelivery.isValid()) {
        result.stringDay = momentEarliestDelivery.format('dddd').toLowerCase();
        result.month = momentEarliestDelivery.format('MMMM');
        result.dayNumber = momentEarliestDelivery.date();
        result.year = momentEarliestDelivery.year();
    }
    return `${result?.stringDay}, ${result.month} ${result?.dayNumber}`;
  };

  const generateKitchenMeals = (provider) => {
    if(user){
      updateSettings(
        produce((draft) => {
          draft.provider = provider;
        }),
      );
    }
    setToDetailsPage && setToDetailsPage(true);
    history.push(`/store/${provider?.username}`)
    sessionStorage.setItem('redirectedFrom', 'kitchenListing');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!profile?.favoriteKitchenData?.businessProfileId) {
      setIsKitchenList(true);
    }
  }, []);

  useEffect(() => {
    setIsKitchenList(showKitchenList)
  },[showKitchenList])

  useEffect(() => {
    window.scrollTo(0, 0);
  },[kitchensList])

  return (
    <KitchensListWrapper
      className={classNames(
        { displayKitchenMeals: isKitchenList && !showDeliveringKitchensList },
        { kitchensListNoFavoriteKitchen: !favoriteKitchenData?.businessProfileId },
      )}
      style={{ paddingBottom: showDeliveringKitchensList && isKitchenList ? "100px" : '' }}
      isWebView={isWebView}
    >
      {!isKitchenList && storeCategoriesMeals && !!Object.keys(storeCategoriesMeals)?.length && (
        <div className="kitchens-btn-wrapper">
          <Button title="View all kitchens" type="dark" onClick={() => setIsKitchenList(true)} />
        </div>
      )}
      {isKitchenList &&
        <div className="kitchens-list-wrapper">
          {(!profile?.favoriteKitchenData?.businessProfileId || showDeliveringKitchensList) && (
            <div className="kitchens-title">Local and healthy</div>
          )}
          <div className="kitchens-list-container">
            {kitchensList?.map((kitchen) =>
              isMobile ? (
                <KitchenCard
                  cardImg={
                    kitchen?.storeImages?.length && kitchen?.storeImages[0].startsWith('http')
                      ? kitchen?.storeImages[0]
                      : '/images/sexy_just_eat_now.jpg'
                  }
                  label={kitchen?.tag}
                  providerImg={kitchen?.image || '/images/green-plate.png'}
                  title={kitchen?.name}
                  date={
                    !!Object.keys(kitchen?.earliestDelivery)?.length &&
                    getNextDay(new Date(), kitchen)
                  }
                  onClick={() => generateKitchenMeals(kitchen)}
                />
              ) : (
                <KitchenCardDesktop
                  cardImg={
                    kitchen?.storeImages?.length && kitchen?.storeImages[0].startsWith('http')
                      ? kitchen?.storeImages[0]
                      : '/images/sexy_just_eat_now.jpg'
                  }
                  tag={kitchen?.tag}
                  title={kitchen?.name}
                  date={
                    !!Object.keys(kitchen?.earliestDelivery)?.length &&
                    getNextDay(new Date(), kitchen)
                  }
                  mealPrepared={kitchen?.mealPrepared}
                  state={kitchen?.state}
                  city={kitchen?.city}
                  btnText="View menu"
                  onClickBtn={() => generateKitchenMeals(kitchen)}
                />
              ),
            )}
          </div>
        </div>
      }
    </KitchensListWrapper>
  );
}
