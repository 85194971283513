import styled from 'styled-components';

const StyledQuote = styled.div`
  margin: 20px;
  height: fit-content;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  gap: 20px;

  img {
    width: 76px;
    height: 76px;
    border-radius: 38px;
  }

  .content {
    max-width: 540px;
    margin: 0;
    color: #0b1c18;
    font-size: 20px;
    font-weight: 600;
    align-self: center;
  }

  @media (max-width: 767px) {
    margin: 0 0 16px 0;
    justify-content: start;
    align-items: start;
    grid-gap: 12px;

    img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
    }

    .content {
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

export default function NutritionistQuote({ image, text, children }) {
  return (
    <StyledQuote>
      
      <img alt="Nut img" src={image} />
     <div className="content">{text ? <p>{text}</p> : children}</div>
    </StyledQuote>
  );
}
