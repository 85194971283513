import { useHistory } from 'react-router-dom';
import { socialMedia } from './Data';
import { FooterDesktopWrapper } from './style';
import { Link } from 'react-router-dom';
import DownloadAppStore from '../../../assets/download-app-store.png';
import { useAppContext } from 'context/appContext';


export default function VisitorFooterDesktop() {
  const history = useHistory();
  const { isMobile } = useAppContext()

  return (
    <FooterDesktopWrapper>
      <div className="footer-container">
        <div className="footer-links-wrapper">
          <div className="logo-wrapper">
            <div className="nutritt-brand" onClick={() => history.push('/')}>
              <img src="/images/nutritt-black.svg" alt="Logo" />
            </div>
            <div className="slogan">Celebrate the end of diet fatigue</div>
          </div>
          {window.innerWidth > 767 &&
          <div className='mobile-app-wrapper'>
            <div className="footer-title">Mobile Apps</div>
            <div className="app-download">
              <a
                href="https://apps.apple.com/us/app/nutritt/id1605156638"
                target="-blank"
                className="app-store-image"
              >
                <img src={DownloadAppStore} alt="App Store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nutritt"
                target="-blank"
                className="google-play-image"
                style={{marginLeft: "10px"}}
              >
                <img src="/images/google-play.svg" alt="Google Play" />
              </a>
            </div>
          </div>
          }
          <div className="follow-wrapper">
            <div className="footer-title">Follow us</div>
            <div className="social-media-wrapper">
              {socialMedia.map((icon) => (
                <a href={icon.link} target={icon.link !== "#" ? '_blank' : '_self'} key={icon.id} className='icon-wrapper'>
                  <img src={icon.icon} alt="SocialMedia Icon" />
                </a>
              ))}
            </div>
            {window.innerWidth < 768 &&
            <div className="app-download">
              <a
                href="https://apps.apple.com/us/app/nutritt/id1605156638"
                target="-blank"
                className="app-store-image"
              >
                <img src={DownloadAppStore} alt="App Store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.nutritt"
                target="-blank"
                className="google-play-image"
              >
                <img src="/images/google-play.svg" alt="Google Play" />
              </a>
            </div>
            }
          </div>
          {window.innerWidth > 767 && window.innerWidth < 1025 && <div />}
          <div className="support-wrapper">
            <div className="footer-title">Support</div>
            <Link to='/nutritt/support' className="footer-info">Contact Support</Link>
          </div>
          <div className="about-wrapper">
            <div className="footer-title">About</div>
            <a href='https://nutritt.typeform.com/careers' className="footer-info">About us</a>
          </div>
        </div>
        <div className="footer-copyright-wrapper">
          <div className="copyright">© 2021-2024 Nutritt.com – All Rights Reserved</div>
          <div className="terms-links">
            <Link to='/nutritt/privacy-policy' className="link" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
            <Link to="/users/terms-&-conditions" className="link" target="_blank" rel="noopener noreferrer">Terms of Use</Link>
          </div>
        </div>
      </div>
    </FooterDesktopWrapper>
  );
}

