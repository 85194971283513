import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useAppContext } from 'context/appContext';
import Progress from 'components/Progress';
import { useRequest } from 'hooks/useRequest';



const SessionLogin = () => {
    const location = useLocation()
    const {setPlatForm,setupUser, setProfile,getdiaryData} = useAppContext()
    const history = useHistory();
    const search =useLocation().search
    const params = new URLSearchParams(search);
    const [getUser] = useRequest("auth/user","get");
    const [markStoreFavorite] = useRequest("profile/mark-store-favorite", "patch");

    const readQueryString = async() => {
        const token=params.get("authorization")
        if (token) {
          const businessProfileId = parseInt(params.get('businessProfileId'));
          localStorage.setItem("user", JSON.stringify({token: token.replace(/"/g, '')}));
          if(businessProfileId){
                 await markStoreFavorite({ body: {
                  businessProfileId: businessProfileId
                 } });
          }       
          await getUser().then( async (res)=>{
          if(res?.user?.type?.includes("user")){
            await getdiaryData()
          }
          const {user, token,orderCount} = res;
          setProfile(user?.profile);
          setupUser(user, token,orderCount)
        });
      }
    }

  useEffect(async () => {
    const logmeal = params.get('logmeal');
    const barcode = params.get('barcode');
    await readQueryString()
    const platform = params.get('platform');
    setPlatForm(platform)
    localStorage.setItem('steps', 'true');
    window.scrollTo(0, 0);
    if (logmeal == "true" || barcode == "true") {
      history.push(`/shop/pantry?logmeal=${logmeal}&barcode=${barcode}`);
    }
  }, []);


    return (
       <Progress /> 
    )
}

export default SessionLogin