import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import Tab from 'components/elements/tab/Tab';
import { Button } from 'components/elements/button/Button';
import { MdEdit } from 'react-icons/md';
import { DeliverySchedule } from './DeliverySchedule';
import { PickupSchedule } from './PickupSchedule';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import Alert from 'components/elements/alert/Alert';
import { useKitchenContext } from 'context/kitchenContext';
import { Modal } from 'components/elements/modal/Modal';

const SchedulesStyle = styled.section`
  overflow: auto;
  height: 100vh;
  padding-top: 29px;
  padding-left: 25px;
  padding-bottom: 29px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  .page_title {
    font-size: ${({ theme }) => theme?.typography?.fontXl};
    font-weight: 600;
    line-height: 32px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
    margin-bottom: 20px;
  }
  .tab_container{
    height:32px;
    place-items: center;
  }
  .btn_flex {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    .custom_button {
      position: relative;
      max-width: 80px;
      &:last-child {
        background: ${({ theme }) => theme?.colors?.primaryDefault};
        color: ${({ theme }) => theme?.colors?.white};
      }
    }
    .btn {
      height: 40px;
      padding: 0 16px;
    }
  }
  .custom_button {
    height: 40px;
    padding: 0 16px;
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
    color: ${({ theme }) => theme?.colors?.primaryDark};
    background: ${({ theme }) => theme?.colors?.white};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
  .container {
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.grayLight};
    background: ${({ theme }) => theme?.colors?.white};
    height: 100%;
    flex: 1;
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }

    .content {
      padding: 24px 32px 0px 32px;
      width: 100%;
      .heading-content {
        gap: 8px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        border-bottom: 1px solid ${() => theme.colors.borderLight};
        .title {
          color: ${({ theme }) => theme?.colors?.blackDefault};
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        p {
          color: ${({ theme }) => theme?.colors?.blackDefault};
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .tabs {
        margin: 21px 0;
        position: relative;
        .nav_item {
          background-color: transparent;
          color: ${() => theme?.colors?.blackLight};
          .text {
            font-weight: 500;
          }
          &.active {
            color: ${() => theme?.colors?.primaryDefault};
          }
        }
        .edit_btn {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
          .btn {
            width: 80px;
            height: 41px;
            .icon {
              svg {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .alert {
    position: absolute;
    top: 16px;
    left: calc(50% - 86px);
    z-index: 9;
    width: 30%;
  }
`;

const ConfirmationStyle = styled.div`
.modal-position {
  position: fixed;
  inset: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(40, 50, 65, 0.6);
}
`;

export const Schedules = () => {
  const { kitchenProvider, kitchenHasSubscription, setKitchenHasSubscription } = useKitchenContext();
  const {setProvider, isKitchenHasSubscription, provider} = useAppContext();
  const { BusinessProfile, BusinessProfileSettings } = kitchenProvider || {};
  const { pickupSchedule, deliverySchedule: schedule, id } = BusinessProfileSettings?.[0] || {};
  const { id: businessProfileId } = BusinessProfile?.[0] || {};
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [updateSchedule,{isLoading:isScheduleUpdating}] = useRequest(`provider/update_schedule`, 'put');
  const [selectedTimes, setSelectedTimes] = useState({});
  const filteredPickupSelectedTime = Object.fromEntries(
    Object.entries(pickupSchedule || {}).filter(([day, value]) => value !== null),
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isButtonDisabledPickup, setIsButtonDisabledPickup] = useState(true)
  const [pickupSelectedTime, setPickupSelectedTime] = useState(filteredPickupSelectedTime);
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
  const parentRef = useRef();
  
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const kitchenId = provider.id;

  const isAllDeliveryDayOff = (deliverySchedule) => {
    for (const day in deliverySchedule) {
      if (deliverySchedule.hasOwnProperty(day) && deliverySchedule[day].isDeliveryOn) {
        return false;
      }
    }
    return true;
  };

  const fetchHasSubscriptionData = async () => {
    try {
      if (kitchenId) {
        const hasSubscription = await isKitchenHasSubscription({ kitchenId });
        setKitchenHasSubscription(hasSubscription);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };
  
  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const scheduledTabs = [
    {
      id: 0,
      title: 'Delivery schedules',
      onClick: () => {},
      render: (schedule, isEdit, setIsEdit) => (
        <DeliverySchedule
          schedule={schedule}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          selectedTabId={selectedTabId}
          selectedTimes={selectedTimes}
          setSelectedTimes={setSelectedTimes}
          isButtonDisabled={isButtonDisabled}
          setIsButtonDisabled={setIsButtonDisabled}
          parentRef={parentRef}
        />
      ),
    },
    {
      id: 1,
      title: 'Pickup schedules',
      onClick: () => {},
      render: (pickupSchedule, isEdit, setIsEdit) => (
        <PickupSchedule
          pickupSchedule={pickupSchedule}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          selectedTabId={selectedTabId}
          pickupSelectedTime={pickupSelectedTime}
          setPickupSelectedTime={setPickupSelectedTime}
          isButtonDisabledPickup={isButtonDisabledPickup}
          setIsButtonDisabledPickup={setIsButtonDisabledPickup}
          filteredPickupSelectedTime={filteredPickupSelectedTime}
          parentRef={parentRef}
        />
      ),
    },
  ];

  const updatedeliverySchedule = (selectedTimes) => {
    setProvider((prevProvider) => {
      const updatedBusinessProfileSettings = prevProvider.BusinessProfileSettings.map(
        (businessProfile) => {
            return {
              ...businessProfile,
              deliverySchedule: selectedTimes,
            };
        },
      );
      return {
        ...prevProvider,
        BusinessProfileSettings: updatedBusinessProfileSettings,
      };
    });
  };

  const updatePickupSchedule = (updatedSchedule) => {
    setProvider((prevProvider) => {
      const updatedBusinessProfileSettings = prevProvider.BusinessProfileSettings.map(
        (businessProfile) => {
            return {
              ...businessProfile,
              pickupSchedule: updatedSchedule,
            };
        },
      );
      return {
        ...prevProvider,
        BusinessProfileSettings: updatedBusinessProfileSettings,
      };
    });
  };

  const handleSave = async () => {
    if (isScheduleUpdating) return;
    setIsAlertOpen(false);
    try {
      let updatedSchedule;

      if (selectedTabId === 0) {
        updatedSchedule = await updateSchedule({
          body: { id: businessProfileId, deliverySchedule: selectedTimes },
        });
        updatedeliverySchedule(selectedTimes);
      } else {
        updatedSchedule = await updateSchedule({
          body: { id: businessProfileId, pickupSchedule: pickupSelectedTime },
        });
        updatePickupSchedule(pickupSelectedTime);
      }
      setIsEdit(false);
      showSuccessMessage(`You have successfully updated ${selectedTabId === 0 ? "delivery" : "pickup"} schedules`);
    } catch (e) {
      if (e.message !== 'jwt expired') {
        console.log('error', e);
        showErrorMessage(e.message);
      }
    }
  };

  const handleSaveFn = async () => {
    const isAllDeliveryOff = isAllDeliveryDayOff(selectedTimes);
    if (kitchenHasSubscription?.hasSubsciption && isAllDeliveryOff && selectedTabId === 0) {
      setIsAlertOpen(true);
    } else {
      handleSave();
    }
  };

  const handleEdit = () => {
    setIsEdit(true);
    fetchHasSubscriptionData();
  };

  const subscriptionCount = kitchenHasSubscription?.subscriptionCount;

  return (
    <SchedulesStyle theme={theme}>
      <h2 className="page_title">Schedules</h2>
      <div className="container" ref={parentRef}>
        <div className="content">
          <div className="heading-content">
            <h2 className="title">Customize schedules</h2>
            <p> Set the days and times when you want to open your business for delivery service </p>
          </div>
          <div className="tabs">
            {schedule && Object.keys(schedule).filter((day) => schedule[day]?.isDeliveryOn === true).length >
              0 &&
              selectedTabId === 0 &&
              !isEdit && (
                <div className="edit_btn">
                  <Button
                    type={'secondary'}
                    title="Edit"
                    onClick={() => handleEdit()}
                    iconRight={<MdEdit />}
                  />
                </div>
              )}
            {Object.keys(pickupSelectedTime).filter(
              (day) => pickupSelectedTime[day]?.isPickupOn === true,
            ).length > 0 &&
              selectedTabId === 1 &&
              !isEdit && (
                <div className="edit_btn">
                  <Button
                    type={'secondary'}
                    title="Edit"
                    onClick={() => setIsEdit(true)}
                    iconRight={<MdEdit />}
                  />
                </div>
              )}
            {isEdit && (
              <div className="btn_flex">
                <Button title="Cancel" className="custom_button" onClick={() => setIsEdit(false)} />
                <Button title="Save" type={'dark'} onClick={handleSaveFn} isDisabled={selectedTabId === 0 ? isButtonDisabled : isButtonDisabledPickup}/>
              </div>
            )}
            <Tab
              type="horizontal"
              navItems={scheduledTabs}
              selectedTabId={selectedTabId}
              setSelectedTabId={setSelectedTabId}
            />
          </div>
          <div className="schedule_data">
            {scheduledTabs[selectedTabId].render(schedule, isEdit, setIsEdit)}
          </div>
        </div>
      </div>
      {alertData.type && (
        <div className="alert">
          <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', title: '' })} />
        </div>
      )}
       {isAlertOpen && (
        <ConfirmationStyle>
            <div className="modal-position">
                <Modal
                  size="large"
                  type="error"
                  description={`The system detects ${subscriptionCount} active subscriptions for this kitchen, and any changes made may affect their recurring orders. Are you sure you want to proceed?`}
                  onOk={handleSave}
                  isOpen={isAlertOpen}
                  onClose={() => setIsAlertOpen(!isAlertOpen)}
                  onCancel={() => setIsAlertOpen(!isAlertOpen)}
                  title={'Are you sure?'}
                  primaryText={`Save`}
                  secondaryText={'Cancel'}
                  isBackdropEnabled={true}
                  buttonType={'dark'}
                />
              </div>
        </ConfirmationStyle>
            )}
    </SchedulesStyle>
  );
};
