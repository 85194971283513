import { useEffect, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import styled from 'styled-components';
import Progress from 'components/Progress';
import Meals from './Meals';
import Components from './Components';
import { useLocation } from 'react-router-dom';
import ErrorComponent from 'components/ErrorComponent';
import { useAppContext } from 'context/appContext';

const TableContainer = styled.div`
  /* max-width: 100vw; */
  padding: 10px;
`;
export default function Menu() {
  const { provider } = useAppContext();
  const [meals, setMeals] = useState([]);
  const [components, setComponents] = useState([]);
  const [allergies, setAllergies] = useState([]);

  const [getMeals, { isLoading, error }] = useRequest();
  const [getAllergies] = useRequest('meal/allergies');
  const { pathname } = useLocation();
  const setMealAndComponents = ({ meals, components }) => {
    setMeals(meals);
    if (components?.length) return setComponents(components);
  };
  const getData = () => {
    getMeals({ path: `provider/meals/${provider.id}` }).then(setMealAndComponents);
  };
  useEffect(() => {
    if (!provider) return;
    getData();
    getAllergies().then((as) => setAllergies(as));
  }, []);

  if (isLoading) return <Progress />;
  if (error) return <ErrorComponent error={error} />;
  return (
    <div>
      <TableContainer>
        {pathname.endsWith('/components') ? (
          <Components
            provider={provider}
            components={components}
            setComponents={setMealAndComponents}
            allergies={allergies}
            getData={getData}
          />
        ) : (
          <Meals
            provider={provider}
            meals={meals}
            setMeals={setMealAndComponents}
            allergies={allergies}
            getData={getData}
          />
        )}
      </TableContainer>
    </div>
  );
}
