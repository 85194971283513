import { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import ProfilePhoto from 'components/Profile/ProfilePhoto';
import { FaRegCopy } from 'react-icons/fa';
import { useNutritionistContext } from 'context/nutritionistContext';
import { useRequest } from 'hooks/useRequest';
import Progress from '../../components/Progress';
import SuccessComponent from 'components/SuccessComponent';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';
import { ASSET_TYPE } from '../../constants'

const NutritionistProfileStyle = styled.div`
font-family: 'Roboto';
font-style: normal;
  .upload-image {
    justify-content: center;

    .profile-photo-container {
      margin: 0;
    }
  }

  .form-section {
    margin-bottom: 40px;
    display: flex;

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;

      &.form-group-section {
        width: 45.6%;

        &:first-child {
          margin-right: 75px;
        }
      }

      label {
        margin-bottom: 14px;
        color: #000000;
        font-size: 20px;
      }

      input {
        height: 46px;
        padding: 0 16px;
        font-size: 16px;
        border: 1px solid #c3cbc9;
        border-radius: 6px;

        ::placeholder {
          color: #939b99;
        }
      }
    }

    .rename-url {
      margin-bottom: 14px;
      font-size: 16px;
    }

    .input-wrapper {
      position: relative;

      input {
        width: 100%;
        padding-right: 40px;
        text-overflow: ellipsis;
       overflow: hidden;
       -webkit-box-orient: vertical;
        white-space: normal;
        display: -webkit-box !important;

        &.copied {
          background-color: #52c41a33;
        }
      }

      .copied-text {
        position: absolute;
        bottom: -20px;
        right: 5px;
        color: #52c41a;
      }

      .copy-icon {
        position: absolute;
        right: 13px;
        top: 13px;
        color: #52c41a;
        cursor: pointer;
      }

      svg {
        font-size: 20px;
      }
    }

    .add-specialization {
      // position: absolute;
      // bottom: 11px;
      //right: 15px;
      margin-top:5px;
      color: #52c41a;
      font-size: 16px;
      cursor: pointer;
    }

    .specialization-container {
      margin-bottom: 12px;
      display: flex;
      flex-wrap: wrap;
    }

    .specialization-wrapper-item {
      height: 20px;
      margin: 5px 5px 0px 0;
      padding: 0 10px;
      color:  #52c41a;
      border: 1px solid  #52c41a;
      border-radius: 20px;
      font-size: 10px;
      display: flex;
      align-items: center;   

      img {
        margin-left: 5px;
        color:  #52c41a;
        cursor: pointer;
      }
    }
  }

  .save-btn {
    padding: 15px 24px;
    background-color: #52c41a;
    color: #fff;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .form-section {
      .rename-url {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    .form-section {
      .form-group {
        &.form-group-section {
          width: 100%;
        }
      }
    }
  }

  .disabled-btn {
    background: #dddddd;
    pointer-events: none;
  }
  .about-input{
    height:118px !important;
    padding: 10px 16px;
    font-size: 16px;
    border: 1px solid #c3cbc9;
    border-radius: 6px;
  }

  .form-group-wrapper {
    width: 45%;
    margin-bottom:40px;
    label{
      margin-bottom: 14px;
    color: #000000;
    font-size: 20px;
    }
    .form-control{
      margin-top:14px;
    }
  }
  
  .select-label{
    margin-bottom:0px !important;
  }
  .form-groups .form-control {
    display: flex;

    & > input.duration-number {
      width: 50%;
      border-radius: 6px;
      border: 1px solid #c3cbc9;
      padding-left: 10px;
    }

    & > div {
      width: 50%;
      margin-bottom: 0;
    }

    div.selected-value {
      border-radius:6px;
      border: 1px solid #c3cbc9;
    }
  }
 @media(max-width:991px){
  .form-group-wrapper{
    width:100% !important;
  }
 }

 @media(min-width:992px){
  display:flex;
  justify-content:center;
  column-gap: 48px;
  width:100%;
  .form-div{
    width:54.5%;
  }
  .form-section{
    margin-bottom: 20px !important;
    .form-group{
      label{
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 14px !important;
        color: #51635D !important;
      }
      input{
        height:44px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px;
        color: #51635D;
        border: 1px solid #E0E3E0 !important;
      }
      textarea{
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 19px;
        color: #AFB5B4;
        font-family: 'Roboto';
        font-style: normal;
        resize: none;
      }
      .rename-url{
        font-weight: 400;
        font-size: 9px !important;
        line-height: 12px;
        color: #949D9B;
        margin-top:5px;
        margin-left:10px;
      }
    }
  }
  .form-group-section{
    width:49.6% !important;
    &:first-child{
      margin-right:0 !important;
    }
    &:nth-child(2){
       margin-left:14px !important;
    }
  }
  .social-input{
    width:100% !important;
    input::placeholder{
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px;
      color: #51635D !important;
    }
  }
  .save-btn{
    width:26.5% !important;
    height: 40px;
    padding:0 !important;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius: 21px !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .name-title{
    input::placeholder{
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px;
      color: #51635D !important;
    } 
  }
  .specialization{
    input::placeholder{
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 16px;
      color: #949D9B;
    } 
  }
  .about-input{
    border: 1px solid #E0E3E0 !important;
  }
 }
`;

export default function NutritionistProfile() {
  const {
    nutritionist: nutritionistData,
    isLoading: contextLoading,
    updateNutritionist,
  } = useNutritionistContext();
  const [addSpecialization, setAddSpecialization] = useState();
  const [newProfilePic, setNewProfilePic] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [isSavingProfilePic, setIsSavingProfilePic] = useState(false);
  const [isLoading, setIsLoading] = useState(contextLoading);
  const { isMobile, uploadProfilePic } = useAppContext();
  const [isModified , setIsModified] = useState(false);
  const URL_ROOT = `${window.origin}/plan/`;

  const [nutritionistBasicInfo, setNutritionistBasicInfo] = useState({
    name: '',
    url: '',
    specialization: [],
    img: '',
    title: '',
    instagram: '',
    tiktok: '',
    facebook: '',
    description: '',
  });
  const [success, setSuccess] = useState();
  const [postData] = useRequest(
    `nutritionist/profile/edit/${nutritionistData?.nutritionist?.id}`,
    'post',
  );
  useEffect(() => {
    setIsLoading(contextLoading);
  }, [contextLoading]);

  useEffect(() => {
    setNutritionistBasicInfo({
      name: nutritionistData?.nutritionist?.name,
      url: nutritionistData?.nutritionist?.url,
      specialization: nutritionistData?.nutritionist?.specialization || [],
      img: nutritionistData?.nutritionist?.img || '',
      title: nutritionistData?.nutritionist?.title,
      instagram: nutritionistData?.nutritionist?.socialProfile?.instagram,
      tiktok: nutritionistData?.nutritionist?.socialProfile?.tiktok,
      facebook: nutritionistData?.nutritionist?.socialProfile?.facebook,
      description: nutritionistData?.nutritionist?.description,
    });
  }, [nutritionistData]);

  const handleSubmit = useCallback(async () => {
    setIsModified(false);
    try {
    setIsLoading(true);
    let uploadProfilePicResponse;
    let location;
    setIsSavingProfilePic(true);
    if (newProfilePic) {
      let key;
      if (nutritionistBasicInfo?.img?.includes('.s3.')) {
        const urlPart = nutritionistBasicInfo?.img.split('/');
        key = urlPart[urlPart.length - 1];
      }
      uploadProfilePicResponse = await uploadProfilePic(newProfilePic, key);
      location = uploadProfilePicResponse?.result?.location;
      setNewProfilePic(null);
    }
    if (!socialMediaUrls()) {
      setIsSavingProfilePic(false);
      setIsLoading(false);
      toast.error('Invalid Social Media Url.');
      return 
    }
    if (nutritionistBasicInfo.name || location) {
        const data = await postData({
          body: {
            ...nutritionistBasicInfo,
            url: nutritionistBasicInfo.url,
            img: location ? location : nutritionistBasicInfo.img,
          },
        });
      if(data.success){
        toast.error(data.message)
        setSuccess(false)
        setIsLoading(false)
        setIsSavingProfilePic(false)
        return
      }
      updateNutritionist({
        ...nutritionistData,
        nutritionist: {
          ...nutritionistData.nutritionist,
          ...data,
        },
      });
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
    setIsSavingProfilePic(false);
    setIsLoading(false);
    
  } catch (error) {
     // Handle error when required fields are missing
     if (error && error === "Required fields are missing") {
      toast.error("Required fields are missing");
    } else {
      toast.error("Something went wrong.");
    }
    setSuccess(false)
    setIsLoading(false)
    setIsSavingProfilePic(false)
  }
  }, [nutritionistBasicInfo, newProfilePic]);

  const handleChange = useCallback((e) => {
    const name=e.target.name
    setIsModified(true);
    if(name==="name"){
     const MAX_LENGTH=100
     if(e.target.value.length>=MAX_LENGTH){
      return
     } else {
      setNutritionistBasicInfo((info) => ({
        ...info,
        name: e.target.value,
      }));
     }
    }
    setNutritionistBasicInfo((info) => ({
      ...info,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const handleImage = useCallback(async (img, userId) => {
    const blob = await fetch(img.croppedImg).then((r) => r.blob());
    const file = new File([blob], `${userId}.jpeg`, {
      type: 'image/jpeg',
    });
    setNewProfilePic(file);
    setIsModified(true);
  }, []);

  const isSaveBtnDisabled = useMemo(() => {
    const oldValues = JSON.stringify({
      name: nutritionistData?.nutritionist?.name,
      url: nutritionistData?.nutritionist?.url,
      specialization: nutritionistData?.nutritionist?.specialization || [],
      img: newProfilePic ? newProfilePic : nutritionistData?.nutritionist?.img,
      title: nutritionistData?.nutritionist?.title,
      instagram: nutritionistData?.nutritionist?.socialProfile?.instagram || '',
      tiktok: nutritionistData?.nutritionist?.socialProfile?.tiktok || '',
      facebook: nutritionistData?.nutritionist?.socialProfile?.facebook || '',
      description: nutritionistData?.nutritionist?.description,
    });
    const newValues = JSON.stringify(nutritionistBasicInfo);
    return oldValues === newValues;
  }, [nutritionistData, nutritionistBasicInfo, newProfilePic]);

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [isCopied]);

  const { name, url, specialization, title, instagram, tiktok, facebook , description } = nutritionistBasicInfo;


  function socialMediaUrls() {
    var insta = document.getElementById('insta').value;
    var tiktok = document.getElementById('tok').value;
    var fb = document.getElementById('fb').value;

    // var p = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
    if ( insta=='' || regex.test(insta)){
      if(tiktok=='' || regex.test(tiktok)){
        if(fb=='' || regex.test(fb) ){
          setSuccess(true);
          return true;
        } else{toast.info('Valid url example: https://facebook.com/username');}
      } else{toast.info('Valid url example: https://facebook.com/username');}
    } else {toast.info('Valid url example: https://instagram.com/username');}
    setSuccess(false);
    return false;
  }
  return (
    <NutritionistProfileStyle>
      <div className="form-section upload-image">
        {/* <ImageUpload
          imageSrc={nutritionistData?.nutritionist?.img}
          setNewProfilePic={handleImage}
        /> */}
        <ProfilePhoto
          imageSrc={nutritionistData?.nutritionist?.img}
          handlePhotoUpload={handleImage}
          fileType={ASSET_TYPE?.coachProfile}
        />
      </div>
      <div className='form-div'>
      <div className="form-section">
        <div className="form-group form-group-section name-title">
          <label>Name</label>
          <input name="name" type="text" autoComplete="off" value={name} onChange={handleChange} />
        </div>
        <div className="form-group form-group-section name-title">
          <label>Title</label>
          <input
            name="title"
            type="text"
            autoComplete="off"
            placeholder="Nutritionist, Health Expert etc"
            value={title}
            onChange={handleChange}
          />
        </div>
      </div>

      {isLoading && <Progress />}
      <div className="form-section">
        <div className="form-group specialization">
          <label>Specialization</label>
          <div class="specialization-container">
            {specialization?.map((item, index) => (
              <span className="specialization-wrapper-item" key={index}>
                {item}
                <img src='/icons/close-circles.svg'
                  onClick={() => {
                    handleChange({
                      target: {
                        name: 'specialization',
                        value: specialization.filter((spec) => spec !== item),
                      },
                    });
                    setIsModified(true);
                  }}
                />
              </span>
            ))}
          </div>
          <input
            name="specialization"
            type="text"
            autoComplete="off"
            value={addSpecialization}
            placeholder="e.g. emotional eating"
            onChange={(e) => {setAddSpecialization(e.target.value)
              setIsModified(true);
            }}
          />
          {addSpecialization && (
            <span
              className="add-specialization"
              onClick={() => {
                handleChange({
                  target: {
                    name: 'specialization',
                    value: [...specialization, addSpecialization],
                  },
                });
                setAddSpecialization('');
              }}
            >
              Add
            </span>
          )}
        </div>
      </div>

      <div className="form-section">
        <div className="form-group">
          <label>About</label>
          <textarea name="description" placeholder='Tell the world about yourself' className='about-input' type="text" autoComplete="off" value={description} onChange={handleChange} />
        </div>
      </div>

      <div className="form-section">
        <div className="form-group">
          <label>Rename your URL</label>
         {isMobile && <p className="rename-url">Rename the URL to make it more personalized!</p>}
          <div className="input-wrapper">
            <input
              name="url"
              className={classNames({ copied: isCopied })}
              type="text"
              autoComplete="off"
              value={URL_ROOT + url}
              onChange={(e) =>
                handleChange({
                  ...e,
                  target: { name: e.target.name, value: e.target.value.slice(URL_ROOT.length) },
                })
              }
            />
            <span
              className="copy-icon"
              onClick={() => {
                navigator.clipboard.writeText(URL_ROOT + url);
                setIsCopied(true);
              }}
            >
              {isMobile ? <FaRegCopy /> : <img src='/icons/copy-green.svg'/>}
            </span>
            {isCopied && <span className="copied-text">Copied</span>}
          </div>
          {!isMobile && <p className="rename-url">Rename the URL to make it more personalized!</p>}
        </div>
      </div>

      <div className="form-section">
        <div className="form-group form-group-section social-input">
          <label>Facebook</label>
          <input
            name="facebook"
            id="fb"
            type="text"
            placeholder="https://facebook.com/username"
            autoComplete="off"
            value={facebook}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="form-section">
        <div className="form-group form-group-section social-input">
          <label>Tiktok</label>
          <input
            name="tiktok"
            type="text"
            id="tok"
            placeholder="https://tiktok.com/username"
            autoComplete="off"
            value={tiktok}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="form-section">
        <div className="form-group form-group-section social-input">
          <label>Instagram</label>
          <input
            name="instagram"
            type="text"
            id="insta"
            placeholder="https://instagram.com/username"
            autoComplete="off"
            value={instagram}
            onChange={handleChange}
          />
        </div>
      </div>
      <button
        type="button"
        className={`save-btn  ${ (isModified  && name.trim() !== "" && url.trim() !== "")? ' ' : 'disabled-btn'} `}
        onClick={handleSubmit}
      >
        Save
      </button>
      {success && isLoading===false &&(
        <SuccessComponent style={{ marginBottom: '10px' }} message={`Updated Successfully`}/>
      )}
      </div>
    </NutritionistProfileStyle>
  );
}

