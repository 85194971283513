import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { isEmailValid } from 'components/auth/utils';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import PageTransition from '../components/PageTransition';
import RegularInput from 'components/Input/RegularInput';
import { GilroyFontWrapper } from 'shared/theme/style';
import { ReactComponent as ArrowRight } from 'assets/shop/chevron-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/shop/chevronLeft.svg';
import { Button } from 'components/elements/button/Button';
import { setFavoriteKitchen } from 'utils/setFavoriteKitchen';

const SignInStyled = styled.div`
width: 100vw;
height: 100vh;
z-index: 99;
position: fixed;
background: #F0F2F4;
inset: 0;
display: flex;
justify-content: center;
align-items: center;
.modal {
  max-width: 536px;
  width: 100%;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 58px 48px 48px 48px;
  display: flex;
  flex-direction: column;
   .heading{
     display: flex;
     flex-direction: column;
     gap: 12px;
    .title{
      color: #000A2C;
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .desc{
      color: #757E89;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
   }
   .password-recommendation{
    margin-top: 14px;
    color: var(--grey-2, #949D9B);
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
ul{
  margin: 0 !Important;
  padding-left: 26px;
}

   }
   .resend-code{
    cursor: pointer;
    margin-top: 14px;
    color: #51635D;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline !important;
  }
   .button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    button{
        span{
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
      :disabled {
        background-color: #F0F2F4 !Important;
        color: #9CA3AD !Important;
      }
    }
    .btn{
        height: 42px;
        width:124px;  
        border-radius: 21px;  
    }
  }

  .first-button{
    width: 103px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid #E0E4E8;
    background: #FFF;
    display: flex;
    height: 42px;
    padding: 13px 24px 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
      .icon{
        top: 2px;
      }
    }
    }
}

`
const FormGroupStyle = styled.div`
  margin-top: 14px;
  width: 100%;
  display:flex;
  flex-direction: column;
  gap: 14px;
  label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: inline-block;
  }
  input {
    height: 44px;
    border: 1px solid #e0e3e0;
    :hover, :focus {
      border-color: #00092d !important;
    }
    :focus::placeholder {
      color: transparent;
    }
    ::placeholder {
      font-family: Gilroy;
      color: #AFB5B4;
      font-style: normal;
      font-weight: 500;
    }
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 500;
    line-height: 20px;
    color: #000A2C;
  }
`;

export default function PhoneVerify() {
  const history = useHistory();
  const { setDesktopOnboardingUser, desktopOnboardingUser, afterLogin, profileObj, setUserEmailExist, setLogin, user, setProfile } = useAppContext();
  const [verifyOTP, { error: errorPhoneVerify, isLoading }] = useRequest();
  const [verifyPhoneNumber, { error: sendOTPError, isLoading: isVerifyPhoneNumber }] = useRequest(`auth/send-otp`, 'post');
  const [signupPhoneVerify, { error: signupSendOTPError, isLoading: isSignupPhoneVerify }] = useRequest(`auth/v2/send-otp`, 'post');
  const [saveToken_v2, { isLoading: isSaving }] = useRequest('visitor/saveToken_v2', 'post');
  const [phoneNumberLogin, {isLoading: isPhoneNumberLogin, error: errorLoginWithPhone}] = useRequest('auth/login-with-phone','post');

  const lastUrl = history?.location?.state?.from;

  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(value)) {
      setDesktopOnboardingUser({
        ...desktopOnboardingUser,
        [name]: value,
      });
    }
    };

  const handleButtonClick = async () => {
    if (lastUrl === '/account/home') {
      phoneNumberLogin({
        body: {
          phoneNumber: desktopOnboardingUser?.phoneNumber,
          otpCode: desktopOnboardingUser?.code,
        },
      })
        .then(async (loginRes) => {
          localStorage.setItem('user', JSON.stringify(loginRes.token));
          afterLogin(loginRes);
          setLogin(true);
          sessionStorage.removeItem('storeUrl');
          sessionStorage.removeItem('redirectedFrom');
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    } else {
      let path = 'auth/v2/verify-phoneNumber';
      if (user?.id) {
        path += `?userId=${user.id}`;
      }
      verifyOTP({
        body: {
          phoneNumber: desktopOnboardingUser?.phoneNumber,
          otpCode: desktopOnboardingUser?.code,
        },
        method: 'post',
        path,
      })
        .then(async (result) => {
          if (desktopOnboardingUser?.name) {
            history.push('/account/password');
          } else if (profileObj) {
            const saveTokenResponse = await saveToken_v2({ body: { googleRes: profileObj } });

            if (saveTokenResponse?.error) {
              toast.error(saveTokenResponse.error);
            } else if (
              Array.isArray(saveTokenResponse?.user?.profile?.selfMealPlan) &&
              saveTokenResponse.user.profile.selfMealPlan.length === 0
            ) {
              localStorage.setItem('login-type', JSON.stringify('selfMealPlanOnboarding'));
              localStorage.setItem('selfOnboardingUser', JSON.stringify(saveTokenResponse.user));
            }
            await afterLogin(saveTokenResponse);
            const username = sessionStorage.getItem('kitchenUsername');
            if (username) {
              const response = await setFavoriteKitchen(saveTokenResponse?.token?.token, username);
              if (response?.data?.success && response?.data) {
                setProfile((prev) => ({
                  ...prev,
                  favoriteKitchenData: response?.data?.favoriteKitchenData,
                }));
              }
            }
            setUserEmailExist(true);
          } else if (user?.id) {
            history.push('/shop/kitchen');
          } else {
            history.push('/account/user-details');
          }
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    }
  };

  const phoneNumberVerfication = useCallback(() => {
    if (lastUrl === '/account/home'){
    verifyPhoneNumber({ body: { phoneNumber: desktopOnboardingUser?.phoneNumber } })
      .then((result) => {
        console.log('otp send')
      })
      .catch((e) => {
        console.log(e, 'error')
      })
    }
    else {
      signupPhoneVerify({ body: { phoneNumber: desktopOnboardingUser?.phoneNumber } })
      .then((result) => {
        console.log('otp send')
      })
      .catch((e) => {
        console.log(e, 'error')
      })
    }
  }, [desktopOnboardingUser])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  const isButtonDisabled = () => {
    return (
      desktopOnboardingUser?.code === '' ||
      isPhoneNumberLogin ||
      isSaving ||
      isVerifyPhoneNumber ||
      isLoading ||
      isSignupPhoneVerify ||
      !desktopOnboardingUser?.code
    );
  };

  return (
    <GilroyFontWrapper>
      <SignInStyled>
        <div className='modal'>
          <div className='heading'>
            <h1 className='title'>We sent you a code</h1>
            <text className='desc'>Enter the 6-digit code sent to you at {desktopOnboardingUser?.phoneNumber}</text>
          </div>
          <FormGroupStyle>
            <RegularInput
              type="text"
              name="code"
              onChange={handleInputChange}
              value={desktopOnboardingUser?.code}
              placeholder="Input code here"
              maxLength={6}
              onKeyPress={handleKeyPress}
              errorMessage={errorPhoneVerify || sendOTPError || errorLoginWithPhone || signupSendOTPError}
              autoFocus
            />
          </FormGroupStyle>
          <a className="resend-code" onClick={(e) => { e.preventDefault(); phoneNumberVerfication(); }}>Resend code</a>
          <div className={`button`}>
            <Button type={'secondary'} title="Back" iconLeft={<ArrowLeft />} onClick={() => history.goBack()} className="first-button" />
            <Button type={'dark'} title="Continue" iconRight={<ArrowRight />} onClick={handleButtonClick} isDisabled={isButtonDisabled()} />
          </div>
        </div>
      </SignInStyled>
    </GilroyFontWrapper>
  );
}

