import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrdersWrapper = styled.div`
  padding: 28px 24px 55px;
  height: 100vh;
  overflow: hidden;
  .orders-title {
    color: ${theme.colors.blackDefault};
    font-family: ${() => theme?.typography?.fontGilroy};
    font-size: ${() => theme?.typography?.fontXl};
    font-weight: 600;
    line-height: 32px;
  }

  .alert {
    position: fixed;
    top: 19px;
    bottom: auto;
    left: 70px;
    right: 0px;
    .alert_bar {
      margin: 0 auto;
    }
    .cross {
      position: static;
      margin-left: 24px;
      transform: none;
    }
  }

  .orders-tabs {
    margin: 24px 0 17px;
    .nav_item {
      height: 32px;
      padding: 0 16px;
      background-color: transparent;
      color: ${() => theme?.colors?.blackLight};
      .text {
        font-family: ${theme.typography.fontGilroy};
        font-weight: 500;
      }
      &.active,
      &:hover {
        color: ${() => theme?.colors?.primaryDefault};
      }
    }
    .tab-indicator {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%),
        #878a98;
    }
  }

  .order-print-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: auto;
    width: calc(100vw - 288px);
    height: 100vh;
    margin-left: 288px;
    background-color: ${theme.colors.background};
  }
`;
export const ParentContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
  outline: 1px solid ${theme.colors.grayLight};
  background-color: ${theme.colors.white};
  .container {
    display: flex;
    place-content: space-between;
    width: 100%;
    height: 64px;
    padding: 0 19px 0 0;
    display: flex;
    align-items: center;
  }

  .search-div {
    width: 286px;
    height: 40px;
    padding-left: 14px;
    padding-top: 12px;
    margin-top: 15px;
    border: 1px solid ${theme.colors.grayLight};
    margin-right: 17px;
    margin-bottom: 12px;
    border-radius: 6px;
    label {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .heading-div {
    display: flex;
    width: 100%;
    height: 48px;
    justify-content: start;
    background-color: ${theme.colors.grayLightest};
  }
  .MuiTablePagination-spacer {
    display: none;
  }
  .MuiTablePagination-toolbar {
    justify-content: space-between;
    min-height: 55px;
    padding-left: 16px;
    padding-right: 8px;
  }
  .MuiTablePagination-displayedRows {
    color: ${theme.colors.blackLight};
    font-size: ${theme.typography.fontSmall};
  }
  .table_holder {
    height: calc(100vh - 300px);
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: ${theme.colors.grayLighter};
    }
  }

  .order-found-wrapper {
    overflow: hidden;
    height: calc(100vh - 331.5px);
    font-weight: 600;
    ${FlexCenter};
  }

  .order-details-motion {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    overflow-y: auto;
    width: calc(100vw - 288px);
    height: 100vh;
    margin-left: 288px;
    background-color: ${theme.colors.background};
  }

  .heading {
    display: flex;
    font-family: ${theme.typography.fontGilroy};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #757e89;
    align-self: center;
    width: 100%;
    height: 100%;
    align-items: center;
    p {
      height: 100%;
      padding: 0 13px 0 16px;
      position: relative;
      word-break: break-word;
      justify-content: space-between;
      display: flex;
      white-space: nowrap;
      align-items: center;
      .select-wrapper {
        width: 20px;
        margin: 0;
      }
    }
    p:nth-child(1) {
      width: 23%;
      padding: 0 0 0 18px;
      justify-content: flex-start;
      gap: 15px;
      .header-checkbox {
        width: 20px;
        height: 20px;
        font-size: 16px;
        .PrivateSwitchBase-input {
          top: 0;
          left: 0;
          height: 20px;
        }
        div[type='checkbox'],
        .custom-checkbox-label {
          width: 18px;
          height: 18px;
        }
        #check {
          margin: 0;
          flex-shrink: 0;
        }
        input[type='checkbox']:checked {
          background-color: ${() => theme.colors.primaryDefault};
        }
        &.MuiCheckbox-root.MuiCheckbox-colorPrimary {
          padding: 0;
          color: ${() => theme.colors.primaryDefault};
          &:hover {
            background-color: transparent;
          }
        }
        &.MuiCheckbox-root.MuiCheckbox-indeterminate {
          color: ${() => theme.colors.primaryDefault};
        }
      }
    }
    p:nth-child(2) {
      width: 13%;
    }
    p:nth-child(3) {
      width: 13%;
    }
    p:nth-child(4) {
      width: 11%;
    }
    p:nth-child(5) {
      width: 17%;
      .options-dropdown {
        right: 0 !important;
      }
    }
    p:nth-child(6) {
      width: 10%;
      .options-dropdown {
        right: 0 !important;
      }
    }
    p:nth-child(7) {
      width: 13%;
    }
  }

  .modal {
    padding: 24px 24px 27px;
    font-family: ${theme.typography.fontGilroy};
    .modal_header .title {
      color: ${theme.colors.blackDefault};
    }
    .modal_body .desc {
      padding-top: 0;
      padding-bottom: 20px;
    }
    button {
      font-family: ${theme.typography.fontGilroy};
      height: 40px;
      &.btn.dark {
        border: 0 !important;
      }
      &:after {
        display: none;
      }
    }
  }
`;

export const OrderActionModal = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(40, 50, 65, 0.6);
  ${FlexCenter};
`;

export const OrderTableWrapper = styled.div`
  .container {
    height: 64px;
    padding: 0 33px 0 21px;
  }
  .selected-orders-motion {
    width: 100%;
  }
  .selected-items-Wrapper {
    padding-left: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .selected-items {
      color: ${theme.colors.blackDefault};
      font-family: ${theme.typography.fontGilroy};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    .order-action,
    .selected-actions-wrapper {
      display: flex;
      align-items: center;
      gap: 13px;
    }
    .action-wrapper {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        border-radius: 6px;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          #878a98;
      }
      &:active {
        background: #e9eaec;
      }
    }
    .divider {
      height: 25px;
      border-right: 1.5px solid ${theme.colors.grayLight};
    }
    .cancel-button {
      height: 40px;
      padding: 0 16px;
      color: ${theme.colors.primaryDark};
      font-family: ${theme.typography.fontGilroy};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        border-radius: 6px;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          #878a98;
      }
      &:active {
        background: #e9eaec;
      }
    }
  }
  .search-filter-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    input {
      height: 40px;
      border-radius: 8px;
      border-color: ${theme.colors.grayLight};
    }
    .filter-wrapper {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      font-family: ${theme.typography.fontGilroy};
      gap: 8px;
      cursor: pointer;
      &.displayFilter {
        background-color: ${theme.colors.background};
        border-radius: 6px;
      }
      .label {
        color: #344054;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      &.filterWithConditions {
        padding: 10px 16px 10px 8px;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          #878a98;
        border-radius: 6px;
        .filters-counts {
          min-width: 20px;
          height: 20px;
          padding: 0 6px;
          border-radius: 2000px;
          background: ${theme.colors.primaryDark};
          color: ${theme.colors.secondary};
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .filter-img-wrapper {
          display: flex;
        }
      }
    }
  }
  .pagination-split-order {
    display: flex;
    align-items: center;
    gap: 12px;
    .sandwich-icon-wrapper {
      width: 40px;
      height: 40px;
      cursor: pointer;
      ${FlexCenter};
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0.9) 100%
          ),
          #878a98;
        border-radius: 6px;
      }
      &:active {
        background: #e9eaec;
      }
    }
  }

  .skeleton-wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    .skeleton-item {
      padding-left: 16px;
      &:nth-of-type(2) {
        width: 13%;
      }
      &:nth-of-type(3) {
        width: 13%;
      }
      &:nth-of-type(4) {
        width: 11%;
      }
      &:nth-of-type(5) {
        width: 17%;
      }
      &:nth-of-type(6) {
        width: 10%;
      }
      &:nth-of-type(7) {
        width: 13%;
      }
    }
    .skeleton-item:nth-of-type(1) {
      width: 23%;
      padding-left: 20px;
    }
  }
`;
