import { useEffect, useState, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { NavHashLink } from 'react-router-hash-link';
import { useShopContext } from 'context/shopContext';
import Tab from 'components/shop/FavoriteKitchen/stickyTab';
import Header from 'components/shop/KitchenMealsDesktop/Header';
import Description from 'components/shop/KitchenMealsDesktop/Description';
import DeliveryDates from 'components/shop/KitchenMealsDesktop/DeliveryDates';
import KitchenMealCard from 'components/shop/FavoriteKitchen/KitchenMealCard';
import KitchenMealDetails from 'components/shop/FavoriteKitchen/KitchenMealDetails';
import useVisibility from 'components/shop/FavoriteKitchen/KitchenMealDetails/VisibilityHook';
import { KitchenMealsDesktopWrapper, MealDetailsModalWrapper } from './style';
import DesktopCart from '../cart/DesktopCart';
import { useVisitorContext } from 'context/visitorContext';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function KitchenMealsDesktop({ categoriesHaveMeals, storeUrl, isFetchingKitchenMeals }) {
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTabClick, setIsTabClick] = useState(false);
  const [mealInfo, setMealInfo] = useState({});
  const [mealDetails, setMealDetails] = useState({});
  const [isMoreCategories, setIsMoreCategories] = useState(false);
  const [isMoreStickyCategories, setIsMoreStickyCategories] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState(3);
  const itemsRef = useRef([]);
  const categoriesTabsRef = useRef();
  const descriptionRef = useRef();
  const [generateMealDetails] = useRequest();
  const {
    profile,
    isModal,
    setIsModal,
    user,
    isShoppingUser,
    favoriteKitchenMeals,
    countStoreVisit
  } = useAppContext();
  const {favoriteKitchenData = {}} = profile || {};
  const {
    settings: { mealPlan, provider },
  } = (user && isShoppingUser) ? useShopContext() : useVisitorContext();
  const [beforeCheckoutSubmitShown, beforeCheckoutSubmitRef] = useVisibility(isModal);
  const endOfCategorySticky =
    itemsRef.current[itemsRef.current.length - 1]?.offsetTop +
    itemsRef.current[itemsRef.current.length - 1]?.clientHeight;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mealId = searchParams.get('mealId');
  const [isDotsActive, setIsDotsActive] = useState(false);
  
  const getMealDetails = async () => {
    const path = `meal/list?mealId=${mealDetails?.id}&providerId=${mealDetails?.providerId}`;
    await generateMealDetails({ path })
      .then((res) => {
        setMealInfo(res?.data?.data[0]);
      })
      .catch((err) => console.log({ err }));
  };

  const closeMealDetails = () => {
    setIsModal(false);
    setMealInfo({});
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -180;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const categoriesItems =
    categoriesHaveMeals.slice(0, visibleCategories).map((category) => {
          return {
            id: category?.id,
            title: (
              <div className="category-wrapper">
                <NavHashLink smooth to={`#${category?.name}`} scroll={(el) => scrollWithOffset(el)}>
                  {category?.name.toLowerCase()}
                </NavHashLink>
              </div>
            ),
            onClick: () => {
              setSelectedTabId(category?.id);
              setIsTabClick(true);
              setIsMoreCategories(false);
              setTimeout(() => {
                setIsTabClick(false);
              }, 1000);
            },
          };
        })

  const generateCategoriesContainer = (category) => (
    <div
      key={category?.id}
      className="category-container"
      onClick={() => {
        setIsTabClick(true);
        setTimeout(() => {
          setIsTabClick(false);
          setSelectedTabId(category?.id);
          setIsMoreCategories(false);
        }, 1000);
      }}
    >
      <NavHashLink to={`#${category?.name}`} scroll={(el) => scrollWithOffset(el)}>
        {category?.name.toLowerCase()}
      </NavHashLink>
    </div>
  );

  useEffect(() => {
    if (isModal) {
      getMealDetails();
    }
  }, [isModal]);

  useEffect(() => {
    if(mealId && favoriteKitchenMeals?.length){
      const found = favoriteKitchenMeals?.find(meal => Number(meal?.id) === Number(mealId));
      if(found && Object.keys(found)?.length){
        setMealDetails(found);
        setIsModal(true);
      }
    }
  }, [mealId, favoriteKitchenMeals])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    categoriesHaveMeals.map((category, index) => {
      const itemsOffset = itemsRef.current[index]?.offsetTop - 200;
      if (scrollPosition > itemsOffset && !isTabClick) {
        setSelectedTabId(category?.id);
      }
    });

    const FiveCategories = categoriesHaveMeals.slice(0, visibleCategories).map((category) => category?.id);
    (FiveCategories.includes(selectedTabId) ||
      !selectedTabId ||
      scrollPosition > endOfCategorySticky - 210)
      && setIsMoreStickyCategories(false)

    if (scrollPosition < descriptionRef.current?.offsetTop + 100) setSelectedTabId(null);
    if(selectedTabId && FiveCategories?.length && !FiveCategories.includes(selectedTabId)){
      setIsDotsActive(true);
    }else{
      setIsDotsActive(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    countStoreVisit(storeUrl);
  }, [])

  const hasMealsWithoutCategory = useMemo(() => {
    if(favoriteKitchenMeals?.length){
      return favoriteKitchenMeals.some((meal) => meal?.category?.id === null);
    }
  }, [favoriteKitchenMeals]);

  function calculateWidth(text, extraSpaces) {
    let finalWidth = extraSpaces;
    text?.split("")?.forEach((item) => {
      finalWidth += 8;
    });
    return finalWidth;
  }

  useEffect(() => {
    const handleResize = () => {
      if(categoriesHaveMeals?.length && categoriesTabsRef?.current){
        let containerWidth = categoriesTabsRef?.current?.offsetWidth;
        let maxVisibleCategories = 0;
        let categoryLength = 0;
        const stickyContainer = scrollPosition > descriptionRef.current?.offsetTop + 100;
        if(!stickyContainer){
          containerWidth = containerWidth - 246;
        }else{
          setIsMoreCategories(false);
        }
        categoriesHaveMeals?.forEach((item) => {
          categoryLength += calculateWidth(item?.name, stickyContainer ? 36 : 60)
          if(categoryLength > containerWidth){
            return;
          }
          maxVisibleCategories += 1;
        })
        setVisibleCategories(Math.min(maxVisibleCategories, categoriesHaveMeals.length))
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [categoriesHaveMeals, isFetchingKitchenMeals, scrollPosition, descriptionRef]);

  return (
    <>
      <KitchenMealsDesktopWrapper>
        <div className="kitchen-meals-mask" />
        <section className="kitchen-meals-wrapper">
          <Header favoriteKitchenData={favoriteKitchenData} provider={provider} />
          {!!categoriesHaveMeals.length &&
            scrollPosition > descriptionRef.current?.offsetTop + 100 && (
              <div
                className={classNames('categories-container', {
                  categoriesSticky: scrollPosition > categoriesTabsRef.current?.offsetTop - 100,
                })}
              >
                <div className="categories-sticky-wrapper" style={{ display: 'flex' }}>
                  <Tab
                    type="horizontal"
                    navItems={categoriesItems.slice(0, visibleCategories)}
                    selectedTabId={selectedTabId}
                    setSelectedTabId={setSelectedTabId}
                  />
                  {categoriesHaveMeals.length > visibleCategories && (
                    <div className="more-categories">
                      {isMoreStickyCategories && (
                        <div
                          className="categories-mask"
                          onClick={() => setIsMoreStickyCategories(false)}
                        />
                      )}
                      <div
                        className={classNames('categories-dots', {
                          active: isMoreStickyCategories || isDotsActive,
                        })}
                        onClick={() => setIsMoreStickyCategories(true)}
                      >
                        ...
                      </div>
                      <AnimatePresence>
                        {isMoreStickyCategories && (
                          <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            exit={{ opacity: 0, y: 100 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                              duration: 0.5,
                              delay: 0,
                              ease: [0, 0.71, 0.2, 1.01],
                            }}
                            className="categories-menu-wrapper"
                          >
                            {categoriesHaveMeals.slice(visibleCategories).map((category) => (
                              <div
                                key={category?.id}
                                className="category-container"
                                onClick={() => {
                                  setIsTabClick(true);
                                  setTimeout(() => {
                                    setIsTabClick(false);
                                    setSelectedTabId(category?.id);
                                    setIsMoreStickyCategories(false);
                                  }, 1000);
                                }}
                              >
                                <NavHashLink
                                  to={`#${category?.name}`}
                                  scroll={(el) => scrollWithOffset(el)}
                                  className={classNames({
                                    selected: selectedTabId === category?.id,
                                  })}
                                >
                                  {category?.name.toLowerCase()}
                                </NavHashLink>
                              </div>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  )}
                </div>
              </div>
            )}

          <div className="kitchen-meals-container">
            <div ref={descriptionRef}>
              <Description favoriteKitchenData={favoriteKitchenData} provider={provider} />
            </div>
            {isFetchingKitchenMeals ? (
              <div style={{ display: 'flex', height: 'calc(100vh - 500px)', justifyContent: 'center', alignItems: 'center', color: '#00092D' }}>
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <>
                <div className="categories-tabs-wrapper" ref={categoriesTabsRef}>
                  <div className="categories-tabs-container">
                    {categoriesHaveMeals
                      .slice(0, visibleCategories)
                      .map((category) => generateCategoriesContainer(category))}
                    {categoriesHaveMeals.length > visibleCategories && (
                      <>
                        <div className="more-categories">
                          {isMoreCategories && (
                            <div
                              className="categories-mask"
                              onClick={() => setIsMoreCategories(false)}
                            />
                          )}
                          <div
                            className={classNames('categories-dots', { active: isMoreCategories })}
                            onClick={() => setIsMoreCategories(true)}
                          >
                            ...
                          </div>
                          <AnimatePresence>
                            {isMoreCategories && (
                              <motion.div
                                initial={{ opacity: 0, y: 100 }}
                                exit={{ opacity: 0, y: 100 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{
                                  duration: 0.5,
                                  delay: 0,
                                  ease: [0, 0.71, 0.2, 1.01],
                                }}
                                className="categories-menu-wrapper"
                              >
                                {categoriesHaveMeals
                                  .slice(visibleCategories)
                                  .map((category) => generateCategoriesContainer(category))}
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="delivery-dates-wrapper">
                    <DeliveryDates provider={provider} />
                  </div>
                </div>

                <div className="categories-dishes-wrapper">
                  {categoriesHaveMeals.map((category, index) => (
                    <div
                      key={category?.id}
                      className="category-wrapper"
                      id={category?.name}
                      ref={(el) => (itemsRef.current[index] = el)}
                    >
                      <div className="dishes-title">{category?.name.toLowerCase()}</div>
                      <div className="dishes-container">
                        {favoriteKitchenMeals
                          ?.filter((meal) => meal?.category?.id === category?.id)
                          .sort((a, b) => a.orderIndex - b.orderIndex)
                          ?.map(
                            (meal) =>
                              meal?.category?.id === category?.id && (
                                <KitchenMealCard
                                  key={meal?.id}
                                  meal={meal}
                                  setMealDetails={setMealDetails}
                                  setOpen={setIsModal}
                                />
                              ),
                          )}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={classNames(
                    'meals-no-categories-wrapper',
                    { displayCartBtn: mealPlan?.length },
                    { noCategories: categoriesHaveMeals?.length < 1 },
                  )}
                >
                  {!!categoriesHaveMeals.length && hasMealsWithoutCategory && (
                    <div
                      className={classNames('dishes-title', {
                        mealsTitle: favoriteKitchenMeals?.map((meal) => !meal?.category?.id),
                      })}
                    >
                      Meals
                    </div>
                  )}
                  <div className="dishes-container">
                    {favoriteKitchenMeals?.map(
                      (meal) =>
                        !meal?.category?.id && (
                          <KitchenMealCard
                            key={meal?.id}
                            meal={meal}
                            setMealDetails={setMealDetails}
                            setOpen={setIsModal}
                          />
                        ),
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        <aside>
          <DesktopCart />
        </aside>
      </KitchenMealsDesktopWrapper>
      <AnimatePresence>
        {isModal && Object.keys(mealInfo)?.length && (
          <MealDetailsModalWrapper>
            <div className="meal-details-mask" onClick={closeMealDetails} />
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="meal-details-motion"
            >
              <KitchenMealDetails
                meal={mealDetails}
                mealInfo={mealInfo}
                open={isModal}
                setOpen={setIsModal}
                setMealInfo={setMealInfo}
                beforeCheckoutSubmitRef={beforeCheckoutSubmitRef}
                beforeCheckoutSubmitShown={beforeCheckoutSubmitShown}
              />
            </motion.div>
          </MealDetailsModalWrapper>
        )}
      </AnimatePresence>
    </>
  );
}



