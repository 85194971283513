import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import { BsArrowLeft } from 'react-icons/bs';
import ProviderCard from './components/ProviderCard';
import SubmitButton from 'components/SubmitButton';
import DetailCard from './components/DetailCard';
import { useEffect, useMemo, useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import VoidForm from './components/VoidForm';
import PayForm from './components/PayForm';

const ProviderDetailsSectionStyle = styled.div`
  flex: 1;
  padding: 20px 48px 70px;
  background-color: #f6f5f8;
  overflow: overlay;
  height: 100vh;
  position: relative;
  .order_header {
    margin-bottom: 20px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #51635d;
    }
  }
  .meal_checkbox {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .checkbox_container {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    .icon_1 {
      display: none;
    }
  }

  .checkbox_container::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 86%;
    height: 82%;
    border: 1px solid #afb5b4;
    border-radius: 5px; /* sets the border-radius of the square box to 5 pixels */
  }

  .checkbox_container::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #afb5b4;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .checkbox_container:hover::before {
    border-color: #666; /* changes border color on hover */
  }
  .icon_1 {
    font-size: 20px;
    padding-top: 2px;
  }
  .meal_checkbox:checked + .checkbox_container {
    .icon_1 {
      display: block;
      color: #52c41a;
    }
    // background-color: #52C41A; /* changes background color when checkbox is checked */
  }
  .total_amount {
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #ffffff;
    border-top: 1px solid #e0e3e0;
    height: 60px;
    width: calc(100vw - 744px);
    padding: 0 80px 0 48px;
    align-items: center;
    // justify-content: space-between;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);

    .nutritt_profit {
      display: flex;
      gap: 5px;
      font-size: 14px;
      line-height: 16px;

      .title {
        font-weight: 400;
        color: #949d9b;
      }

      .value {
        font-weight: 700;
        color: #51635d;
      }
    }

    .user_charge {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #51635d;

      .value {
        font-weight: 700;
      }

      margin-left: auto;
    }
  }
  .order_body {
    padding: 14px 14px 14px 16px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    .order_details {
      display: flex;
      gap: 2px;
      align-items: center;
      width: 50%;
      justify-content: flex-start;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16.41px;
        letter-spacing: 0.02em;
        color: #949d9b;
        padding: 0px 4px;
        &.value {
          color: #51635d;
          font-weight: 700;
        }
      }
      &:nth-child(even) {
        justify-content: flex-end;
      }
    }
  }
  .order_meals {
    .heading {
      display: flex;
      padding: 0px 32px 12px 24px;
      .title {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #51635d;
        width: 30%;
        &:first-child {
          margin-left: 78px;
          flex: 1;
          width: auto;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .btn-end {
    border-radius: 31px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.22% 2.22% 0 0;
    gap: 5px;
    background: #52c41a;
    height: 40px;
    width: 126px;
    color: #ffffff;
    &:disabled {
      background-color: #c3c3c3 !important;
      border: 1px solid #c3c3c3 !important;
    }
  }
  .btn-end1 {
    border-radius: 31px;
    border: 1px solid #97dc76 !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.22% 2.22% 0 0;
    gap: 5px;
    background: #ffffff;
    height: 40px;
    width: 126px;
    color: black;
    &:disabled {
      background-color: #c3c3c3 !important;
      border: 1px solid #c3c3c3 !important;
      color: #ffffff !important;
    }
  }
  .details_wrapper {
    padding: 0 48px;
    height: calc(100vh - 64px);
    overflow: overlay;
    display: flex;
    flex-direction: column;
  }
  .slick-prev,
  .slick-next {
    top: -32px !important;
    color: #afb5b4;
    width: 24px;
    z-index: 99;
  }
  .slick-next {
    right: 48px !important;
  }
  .slick-prev {
    left: 48px !important;
  }
  @media (min-width: 2300px) {
    .details_wrapper {
      padding: 0 100px;
    }
    .slick-next {
      right: 100px !important;
    }
    .slick-prev {
      left: 100px !important;
    }
    .total_amount {
      padding: 0 150px 0 120px;
      margin-left: -100px;
    }
  }
  @media (min-width: 1441px) and (max-width: 2299px) {
    .total_amount {
      margin-left: -48px;
    }
  }
  @media (max-width: 1440px) {
    padding: 20px 20px 70px;

    .details_wrapper {
      padding: 0 24px;
    }
    .slick-next {
      right: 30px !important;
    }
    .slick-prev {
      left: 30px !important;
    }
    .total_amount {
      width: calc(100vw - 700px);
      padding-right: 20px;
    }
  }
  @media (max-width: 991px) {
    padding: 63px 0px 0;
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: #f6f5f8;
    .order_header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      z-index: 9;
      border: 1px solid #e0e3e0;
      background-color: #fff;
      .title {
        padding: 22px 0 15px;
      }
      .icon {
        color: #52c41a;
        stroke-width: 0.5px;
        position: absolute;
        left: 18px;
        top: 20px;
        font-size: 18px;
      }
      .title {
        font-size: 16px;
      }
    }
    .total_amount {
      width: 100vw;
      padding: 0 34px 0 20px;
      margin-left: 0;
      .user_charge {
        font-size: 16px;
      }
    }
    .order_meals {
      padding: 24px 20px 70px;
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .details_wrapper {
      padding: 0;
    }
  }
`;
const Progress = styled.div`
  position: relative;
  flex: 1;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export default function ProviderDetailsSection({
  setIsOrderDetailsActive,
  orderDetailsData: order,
  size,
  fetchAllProviders,
  activeTab,
}) {
  const { isMobile } = useAppContext();
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [childExpandStatus, setChildExpandStatus] = useState(
    Array(order.disbursementList.length).fill(false),
  );
  const [isModal, setIsModal] = useState(false);
  const [selectedMeals, setSelectedMeals] = useState({});
  const [selectedNonMeals, setSelectedNonMeals] = useState({});
  const [isPayModal, setPayIsModal] = useState(false);
  const [selectedMealsIds, setSelectedMealsIds] = useState({});
  const [checkboxes, setCheckboxes] = useState(
    Array(
      order.disbursementList.map((order) => {
        order.providerOrder.orderMain.length;
      }),
    ).fill(false),
  );

  const totalAmount = useMemo(() => {
    return parseFloat(order.total).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }, [order.total]);

  const handleBack = () => {
    setIsOrderDetailsActive(false);
  };

  const handleCheckboxChange = (index, checked) => {
    setCheckboxes((prevState) => {
      const newCheckboxes = [...prevState];
      newCheckboxes[index] = checked;
      return newCheckboxes;
    });
  };
  const checkAll = () => {
    setCheckboxes(Array(order.disbursementList.length).fill(true));
  };

  const uncheckAll = () => {
    setCheckboxes(Array(order.disbursementList.length).fill(false));
  };

  useEffect(() => {
    uncheckAll();
    setIsExpandAll(false);
    setChildExpandStatus(Array(order.disbursementList.length).fill(false));
    setSelectedNonMeals({});
    setSelectedMeals({});
  }, [order]);

  const toggleAll = () => {
    if (checkboxes.some((checked) => checked)) {
      uncheckAll();
    } else {
      checkAll();
    }
  };
  const handleExpandAll = () => {
    const newChildExpandStatus = Array(order.disbursementList.length).fill(!isExpandAll);
    setIsExpandAll(!isExpandAll);
    setChildExpandStatus(newChildExpandStatus);
  };

  const handleChildExpand = (index) => {
    const newChildExpandStatus = [...childExpandStatus];
    newChildExpandStatus[index] = !newChildExpandStatus[index];
    setChildExpandStatus(newChildExpandStatus);
  };

  const sum = useMemo(() => {
    let total = 0;

    order?.disbursementList.forEach((order) => {
      const meals = order.providerOrder.orderMain.orderLine;
      const filteredMeals = meals.filter((meal) => meal.refundStatus === 'pending');  
      total += filteredMeals.length;
    });

    return total;
  }, [order?.disbursementList]);

  useEffect(() => {
    const allTrue = childExpandStatus.every((status) => status);
    const allFalse = childExpandStatus.every((status) => !status);
    if (allTrue) {
      setIsExpandAll(true);
    }
    if (allFalse) {
      setIsExpandAll(false);
    }
  }, [childExpandStatus]);

  return (
    <>
      <ProviderDetailsSectionStyle>
        <div className="order_header">
          {isMobile && <BsArrowLeft className="icon" onClick={handleBack} />}
          {isMobile && (
            <h2 className="title">
              {isMobile ? 'Order ID: ' : ''}
              {order.disbursementList[0].providerOrder.orderMain.friendlyOrderNum}
            </h2>
          )}
        </div>
        <ProviderCard
          order={order}
          size={size}
          fetchAllProviders={fetchAllProviders}
          sum={sum}
          selectedMeals={selectedMeals}
          selectedNonMeals={selectedNonMeals}
          activeTab={activeTab}
        />
        {!isMobile && (
          <div className="order_body">
            <div className="order_details">
              <label style={{ padding: '2px 2px 0px 4px' }}>
                <input
                  type="checkbox"
                  className="meal_checkbox"
                  onChange={toggleAll}
                  checked={checkboxes.every((checked) => checked)}
                />
                <span className="checkbox_container">
                  {checkboxes.every((checked) => checked) && <BsCheck2 className="icon_1" />}
                </span>
              </label>
              <p className="title">Select all</p>
            </div>
            <div className="order_details">
              <p className="title" onClick={handleExpandAll} style={{ cursor: 'pointer' }}>
                {isExpandAll ? 'Collapse all' : 'Expand all'}
              </p>
              {isExpandAll ? (
                <span onClick={handleExpandAll} style={{ cursor: 'pointer' }}>
                  <img src="/images/scrollX.svg" alt="ScrollIconX Icon" />
                </span>
              ) : (
                <span onClick={handleExpandAll} style={{ cursor: 'pointer' }}>
                  <img src="/images/scroll.svg" alt="Scroll Icon" />
                </span>
              )}
            </div>
          </div>
        )}
        <div className="order_meals">
          {order?.disbursementList.map((disbursement, index) => {
            const orderDetails = disbursement.providerOrder;
            return (
              <DetailCard
                key={index}
                id={index}
                checked={checkboxes[index]}
                onChange={(checked) => handleCheckboxChange(index, checked)}
                deliveryData={orderDetails.deliveryData}
                orderNumber={orderDetails.orderMain.friendlyOrderNum}
                coachDetail={disbursement}
                userDetail={disbursement}
                providerDetail={order}
                size={size}
                isChildExpanded={childExpandStatus[index]}
                onChildExpand={() => handleChildExpand(index)}
                selectedMeals={selectedMeals}
                setSelectedMeals={setSelectedMeals}
                setSelectedMealsIds={setSelectedMealsIds}
                selectedNonMeals={selectedNonMeals}
                setSelectedNonMeals={setSelectedNonMeals}
                checkboxes={checkboxes}
                activeTab={activeTab}
              />
            );
          })}
        </div>
        <div className="total_amount">
          {Object.keys(selectedMeals).length < 1 ? (
            <SubmitButton className="btn-end" disabled={true} title={'Select at least one item'}>
              <img src="/images/dollar-white.svg" alt="dollar Icon" />
              Pay
            </SubmitButton>
          ) : (
            <SubmitButton
              className="btn-end"
              onClick={() => setPayIsModal(true)}
              disabled={activeTab === 'paid' || activeTab === 'voided'}
              title={
                activeTab === 'paid'
                  ? 'Items are already paid'
                  : activeTab === 'voided'
                  ? "Items can't pay here"
                  : ''
              }
            >
              <img src="/images/dollar-white.svg" alt="dollar Icon" />
              Pay
            </SubmitButton>
          )}

          {Object.keys(selectedMeals).length < 1 ? (
            <SubmitButton className="btn-end1" disabled={true} title={'Select at least one item'}>
              <img src="/images/Close-cross.svg" alt="Cross Icon" />
              Void
            </SubmitButton>
          ) : (
            <SubmitButton
              className="btn-end1"
              onClick={() => setIsModal(true)}
              disabled={activeTab === 'paid' || activeTab === 'voided'}
              title={
                activeTab === 'paid'
                  ? "Items can't be voided here"
                  : activeTab === 'voided'
                  ? 'Items are already voided'
                  : ''
              }
            >
              {activeTab !== 'paid' && activeTab !== 'voided' ? (
                <img src="/images/Close-cross2.svg" alt="Cross Icon" />
              ) : (
                <img src="/images/Close-cross.svg" alt="CrossIcon" />
              )}
              Void
            </SubmitButton>
          )}

          <div className="user_charge">
            <p className="title">{activeTab === 'voided' ? 'Total Voided Provider Payout:' : 'Total Provider Payout:'}</p>
            <h3 className="value">{totalAmount || 'N/A'}</h3>
          </div>
        </div>

        {isModal && (
          <VoidForm
            setIsModal={setIsModal}
            isModal={isModal}
            selectedMeals={selectedMeals}
            setSelectedMeals={setSelectedMeals}
            order={order}
            selectedMealsIds={selectedMealsIds}
            fetchAllProviders={fetchAllProviders}
          />
        )}
        {isPayModal && (
          <PayForm
            setPayIsModal={setPayIsModal}
            isPayModal={isPayModal}
            selectedMeals={selectedMeals}
            setSelectedMeals={setSelectedMeals}
            order={order}
            selectedMealsIds={selectedMealsIds}
            fetchAllProviders={fetchAllProviders}
          />
        )}
      </ProviderDetailsSectionStyle>
    </>
  );
}
