import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from 'components/Input';
import Alert from 'components/Alert';
import { Button } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai'
import { DEFAULT_ACTIVITY } from "../../../../constants";
import { toast } from "react-toastify";
import classNames from 'classnames';


const CustommizePAlStyling = styled.div`
.inset{
    width:100%;
    height:100vh;
    position:fixed;
    bottom:0;
    left:0;
    background-color: #51635d4d;
    z-index:1;
    transition : all 0.5s;
  }
  .opacity-0{
    opacity:0;
    transition : all 0.5s;
  }
  .close-icon{
    color:#52C41A;
    padding:10px 0;
    transition:all 1s;
  }
  .customize-div{
    width: calc( 100% - 40px);
  }
  .customize-heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #51635D;
  }
  .head-wrapper{
    display : flex;
    align-items:center;
    margin-top:15px;
    
  }
  h5.customize-gender{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #51635D;
  }
  p.customize-gender{
    color: #51635D;
  }
  .customize-gender{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding:5px 5px;
    font-weight:100;
    margin:0px;
    margin-top: 14px;
    @media (max-width:325px){
      padding:2px;
        }
  }
  .flex-wrapper{
    display : flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }
  .flex-wrapper div:nth-child(4){
    width:48% !important;
  }
  .flex-wrapper div:nth-child(5){
    width:48% !important;

  }

  .input-margin{
    margin : 0 2px;
    width:31%;
    margin-top: 20px;
  }
  
  .save-button{
    background-color: #52C41A;
    width:100%;
    max-width:98%;
    border-radius:20px;
    margin:45px 0px 10px 0px;
    margin-bottom: 45px;
  }
  .box{
    width : 100%;
    height: 90vh;
    overflow:auto;
    z-index: 2;
    position :fixed;
    bottom:0px;
    left : 0px;
    border-radius : 20px 20px 0 0 ;
    background-color : white;
    transition : all 0.5s;
    animation: openModal 0.4s ease-in forwards;
    @media (max-width:325px){
    height: 520px;
    }
  }
  .box2{
      position:fixed;
      bottom:-200px;
      opacity:0;    
    transition:all 0.5s;
    animation: closeModal 0.4s ease-out forwards;
}
@keyframes closeModal {
    0%
     { transform: translateY(0%);
     }
    100%
     { 
      transform: translateY(100%);  
     }
    
  }
  @keyframes openModal {
    0%
     { transform: translateY(100%);
     }
    100%
     { 
      transform: translateY(0%);  
     }
    
  }
  .Challange-formula {  
    font-size: 14px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    color: #51635D;
    background: #F6F5F8;
}
hr{
  margin:40px 0;
}
`

const CustomizePalPopup = ({
    form,
    setForm,
    useCustom,
    gender,
    customizeValue,
    setCustomizeValue,
    activityType,
    Customize,
    setCustomize,
    customizepal,
    CustomizePal,
    handleChange,
    setUseCustom,
    alert,
    setAlert,
}) => {
 
    const [disabled,setDisabled] = useState(true)
    const customValue = (e, key, gender) => {
        let value = e.target.value;
        if(value===""){
          toast.error("Please Enter a valid Number") 
        }
        if(value==="1.." || value==="1,,") value = 1.0;
        if (value > 1.9) value = 1.9;
        setCustomizeValue({ 
          ...customizeValue,
          [gender]: { ...customizeValue[gender], [key]:value},
        });
        setDisabled(false)
      };

      useEffect(()=>{
        setForm({...form, PAL: customizeValue, PAL_formula: activityType})
      },[customizeValue]);

      const isEqual = (obj1, obj2) => {
    // Check if the two objects have the same keys
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length || !keys1.every(key => keys2.includes(key))) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!isEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  }

  
  useEffect(()=>{
    if (form.id && form.PAL) {
      const defaultActivity = DEFAULT_ACTIVITY[form.PAL_formula];
      const updatedPal = form.PAL;
      const isPalEqual = isEqual(defaultActivity, updatedPal);
      if(!isPalEqual){
        setUseCustom(true);
      }
    }
  },[form]);


      const renderInput = (key, gender) => {
      
        return (
          <input
            type="text"
            className="Challange-formula"
            pattern="[0-9]+([.,][0-9]+)?"
            value={(useCustom ? form.PAL : DEFAULT_ACTIVITY[activityType])[gender][key]}
            onChange={(e) => customValue(e, key, gender)}
            inputMode="decimal"
          />
        );
      };
      
      
      const options = [
        { key: 'inactive', label: 'Inactive' },
        { key: 'active', label: 'Active' },
        { key: 'slightly', label: 'Slightly Active' },
        { key: 'extremely', label: 'Extremely Active' },
        { key: 'moderately', label: 'Moderately Active' },
      ];

      
    return (
        <>
            <CustommizePAlStyling>
                <div className={Customize ? 'inset ' : 'opacity-0'} >
                    <div className={Customize ? 'box' : 'box2'} >
                        <div className='container'>
                            <div className='head-wrapper'>
                                <div onClick={() => setCustomize(!Customize)} className='close-icon '>
                                    <AiOutlineClose />
                                </div>
                                <div className='customize-div'>
                                    <h4 className='customize-heading'>Customize PAL</h4>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div>
                                <h5 className='customize-gender'>Sex</h5>
                                <p className='customize-gender'>Male</p>
                            </div>
                            <div className='flex-wrapper '>
                                    {options.map(({ key, label }, i) => (
                                        <div key={`form-group-id-${i}`} className="input-margin  ">
                                            <label>{label}</label>
                                            <div className="field-wrapper">
                                            {renderInput(key, "men")}
                                            </div>
                                        </div>
                                    ))}
                                
                            </div>
                           
                            <hr />
                            <div>
                                <p className='customize-gender'>Female</p>
                            </div>
                            <div className='flex-wrapper'>
                            {options.map(({ key, label }, i) => (
                                        <div key={`form-group-id-${i}`} className="input-margin  ">
                                            <label>{label}</label>
                                            <div className="field-wrapper">

                                            {renderInput(key, 'women')}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        
                            <Button variant="contained" disabled={disabled || Object.values(form.PAL.men).some((value) => value === "") ||Object.values(form.PAL.women).some((value) => value === "")}    className={`save-button ${classNames({disabled:disabled})}`} onClick={() =>{ CustomizePal(),setAlert(true)}}>Save</Button>
                            {
                                alert ? <>
                                    <Alert
                                        title="Success"
                                        desc="Saved Successfully"
                                        ok={() => {setCustomize(!Customize),setAlert(false)}}
                                        okText="Ok"
                                    />
                                </> : <></>
                            }
                        </div>
                    </div>
                </div>
            </CustommizePAlStyling>
        </>
    )
}

export default  CustomizePalPopup;