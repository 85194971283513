import { useState } from 'react';
import styled from 'styled-components';
import CreatePasswordPanel from 'components/Booking/CreatePasswordPanel';
 import {useRequest} from "hooks/useRequest";

const CreatePasswordWrapper = styled.div`
  .desc {
    width: 80%;
    margin: 23px auto;
    text-align: center;

    h2 {
      margin-bottom: 5px;
      color: #0b1d17;
      font-family: 'Roboto';
    }

    p {
      color: #949d9b;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }
`;

export default function CreatePassword() {
  const [form, setForm] = useState({ createPass: '', confirmPass: '' });
  const [createPassword, { isLoading }] = useRequest('auth/set-password', 'post');


  return (
    <CreatePasswordWrapper>
      <div className="desc">
        <h2>Create password</h2>
        <p>Set a memorable password for accessing your Nutritt account</p>
      </div>
      <CreatePasswordPanel form={form} setForm={setForm} createPassword={createPassword} isLoading={isLoading} />
    </CreatePasswordWrapper>
  );
}

