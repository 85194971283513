import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../shared/theme/theme';

const ChipWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({leftIcon, rightIcon}) => ((leftIcon && rightIcon) ? '2px 1px' : '2px' )};
  background-color: ${({ isActive,color, theme }) => (isActive ? theme?.colors?.lightBlue : color || '#f0f1f2')}; 
  color: #333;
  padding-right:${({leftIcon, rightIcon}) => ((leftIcon && rightIcon) ? '' : '8px' )};
  border-radius: 50px;
  padding-left: 8px; 
  font-size: 14px;
  font-weight: bold;
  column-gap:6px;
  cursor: pointer;
  max-width: fit-content;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  svg{
  }
  &:hover {
    background-color: #e1e4e8;
    svg{
      color: ${theme.colors.steelGray} !important;
    }
  }
`;

const TextWrapperStyle = styled.div`
  flex-grow: 1;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
`;

const IconWrapperStyle = styled.span`
  svg {
    display: block;
    width: 20px;
    height: 20px;
    color: ${({ isActive }) => (isActive ? theme.colors.primary : theme.colors.slateGray)} !important;
    transition: color 0.3s;
  }
`;

export default function Chips({ label, leftIcon, rightIcon, showActive = true ,color, disabled}) { 
    const [isActive, setIsActive] = useState(false);

    const handleChipClick = () => {
        setIsActive((prevState) => !prevState);
    };

    return (
        <ChipWrapperStyle className='chip_wrapper' disabled={disabled} isActive={isActive} onClick={showActive && handleChipClick} color={color} theme={theme} leftIcon={leftIcon} rightIcon={rightIcon}> 
            {leftIcon && <IconWrapperStyle isActive={isActive} theme={theme}>{leftIcon}</IconWrapperStyle>}
            <TextWrapperStyle className='text'>{label}</TextWrapperStyle>
            {rightIcon && <IconWrapperStyle isActive={isActive} theme={theme}>{rightIcon}</IconWrapperStyle>}
        </ChipWrapperStyle>
    );
}; 
