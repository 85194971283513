import styled from 'styled-components'
import Title from './components/Title'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Button from './components/Button'
import { useOnboardingContext } from './index';
import PageTransition from './components/PageTransition'
import { CrossButton } from './components/CrossButton'

const HealthGoalsStyle = styled.div`
    padding: 48px 32px 0;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    background-color: #fff;
    height: 100%;
    position: relative;
    .flex{
        flex: 1;
    }
    .goals_wrapper{
        max-width: 220px;
        margin: auto;
        margin-top: 95px;
        .goal_card{
            margin-bottom: 10px;
            .radio_control{
                display: none;
                &:checked + label{
                    background: #F0FAEB;
                    border: 3px solid #CBEDBA;
                }
            }
            label{
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #51635D;
                background: #FFFFFF;
                border: 1px solid #E0E3E0;
                border-radius: 30px;
                height: 60px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }
`

export default function HealthGoals() {
    const history = useHistory();
    const [healthGoal, setHealthGoal] = useState('');
    const { onboardingUser, setOnboardingUser, isNewGoal,backUrl } = useOnboardingContext();

    const handleBack = () => {
        isNewGoal === 'true' && backUrl !=="/shop/profile" ? history.push('/shop/pantry') : history.push(`/self-onboarding/preferred-diet${isNewGoal?'?newGoal=true':''}`);
    }

    const handleChange = (e) => {
        setHealthGoal(e.target.value);
        if(e.target.value === 'maintain-weight'){
            setOnboardingUser((prev) => ({
                ...prev,
                selfMealPlan: {
                    ...prev.selfMealPlan,
                    healthGoal: e.target.value,
                    goal: 0,
                    idealWeight: 0
                }
            }))
        }else{
            setOnboardingUser((prev) => ({
                ...prev,
                selfMealPlan: {
                    ...prev.selfMealPlan,
                    healthGoal: e.target.value
                }
            }))
        }
    }

    useEffect(() => {
        setHealthGoal(onboardingUser?.selfMealPlan?.healthGoal);
    }, [])

    return (
        <PageTransition>
            <HealthGoalsStyle>
                {isNewGoal && <CrossButton />}
                <div className='flex'>
                    <Title title="What is your health goal?" subtitle={"Let's customize your meal planning and recommendations to help you achieve your goal"} />

                    <div className='goals_wrapper'>
                        <div className='goal_card'>
                            <input type="radio" value={'weight-loss'} checked={healthGoal === "weight-loss"} onChange={handleChange} className='radio_control' name='health_goal' id="health_goal1" />
                            <label for="health_goal1">Weight loss</label>
                        </div>

                        <div className='goal_card'>
                            <input type="radio" value={'weight-gain'} checked={healthGoal === "weight-gain"} onChange={handleChange} className='radio_control' name='health_goal' id="health_goal2" />
                            <label for="health_goal2">Weight gain</label>
                        </div>

                        <div className='goal_card'>
                            <input type="radio" value={'maintain-weight'} checked={healthGoal === "maintain-weight"} onChange={handleChange} className='radio_control' name='health_goal' id="health_goal3" />
                            <label for="health_goal3">Improved overall health</label>
                        </div>
                    </div>
                </div>
                <Button onBackClick={handleBack} onContinueClick={() => history.push(`/self-onboarding/current-weight${isNewGoal === 'true' ? '?newGoal=true' : ''}`)} disabled={!healthGoal} />
            </HealthGoalsStyle>
        </PageTransition>
    )
}