import { KitchenOurMissionWrapper } from './style';

export default function KitchenOurMission() {
  return (
    <KitchenOurMissionWrapper>
      <div className="our-mission-header">
        <div className="mission-image-wrapper">
          <img src="/images/ourMission.png" alt="Our Mission" />
        </div>
        <div className="mission-title">Our mission</div>
      </div>
      <div className="our-mission-desc">
        <p>
          Nutritt is committed to making healthy eating easy and enjoyable for you, so you can focus
          on living your best life.
        </p>
      </div>
    </KitchenOurMissionWrapper>
  );
}
