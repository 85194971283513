import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { ReactComponent as EmptySubscription } from 'assets/shop/empty-subscription.svg';
import { useHistory } from 'react-router';
import { Button } from 'components/elements/button/Button';
import { useRequest } from 'hooks/useRequest';
import { OrderCard } from 'components/elements/orderCard/OrderCard';
import OrderStatus from './order-status';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { useAppContext } from 'context/appContext';
import Progress from 'components/Progress';
import { motion } from 'framer-motion';
import { theme } from 'shared/theme/theme';

const EmptyWrapper = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:24px;
  &::-webkit-scrollbar {
    width: 0;
  }
  .empty-img-wrapper {
    display: flex;
    flex-direction: column;
    gap: 28.12px;
    align-items: center;
    margin-bottom: 24px;
    svg{
      height: 121px;
    }
  }
  .text{
    display:flex;
    flex-direction: column;
    gap: 8px;
  }
  .empty-title {
    color: ${theme?.colors?.primaryDarker};
    text-align: center;
    font-family: ${theme?.typography?.fontGilroy};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    }
  p {
    color: ${theme?.colors?.primaryDarker};
    text-align: center;
    font-family: ${theme?.typography?.fontGilroy};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 270px;
    height: 19px;
    }
  @media (min-width: 992px) {
    height: 100%;
    justify-content: normal;
    align-items: normal;
    margin-top: 5%;
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
`;

const ButtonWrapper = styled.div`
button {
    width: 220px;
    height: 42px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 0px solid #DFE2E8;
    background: ${theme?.colors?.primaryDefault};
    cursor: pointer;
    font-family: ${theme?.typography?.fontGilroy};
}
`;

const subscriptions = ({ subscriptionlist, setSubscriptionList, isLoading, setIsLoading }) => {
const history = useHistory();
const [ fetchSubscriptionList ] = useRequest();
const [showOrderStatus, setShowOrderStatus] = useState(false);
const [orderItems, setOrderItems] = useState({});
const [showOrderAnim, setShowOrderAnim] = useState(true);
const [clicked, setClicked] = useState(false);
const { isMobile } = useAppContext();

const getSubscriptionList = async () => {
    try {
      const path = 'subscription/recurring-orders';
      const response = await fetchSubscriptionList({ path });
      setSubscriptionList(response.recurringOrders || []);
      setIsLoading(false);
    } catch (err) {
        console.log('Error fetching Subscription list', err);
        setIsLoading(false);
    }
  };

  useEffect(() => {
    if(!subscriptionlist?.length){
      getSubscriptionList();
    }
  },[])

  useEffect(() => {
    setTimeout(() => {
      setClicked(false);
    }, [6000]);
  }, [clicked]);


  return (
    <>
      {isLoading ? (
        <>
          {isMobile ? (
            Array.from({ length: 5 }, (_, index) => index + 1)?.map((item) => (
              <React.Fragment key={item}>
                <Stack style={{ padding: '12px 25px 14px 16px', background: '#fff' }}>
                  <div style={{ marginBottom: '17.5px' }}>
                    <Skeleton variant="text" width={68} height={20} />
                  </div>
                  <div style={{ marginBottom: '9px' }}>
                    <Skeleton variant="text" width={200} height={20} />
                  </div>
                  <div className="space_between">
                    {Array.from({ length: 3 }, (_, index) => index + 1)?.map((rectItem) => (
                      <Skeleton key={rectItem} variant="rectangular" width={90} height={90} />
                    ))}
                    <Skeleton
                      variant="rectangular"
                      width={32}
                      height={32}
                      style={{ visibility: 'hidden' }}
                    />
                  </div>
                </Stack>
                <Skeleton variant="rectangle" height={10} />
              </React.Fragment>
            ))
          ) : (
            <div style={{ backgroundColor: '#fff', width: '100vw', height: 'calc(100vh - 76px)' }}>
              <Progress />
            </div>
          )}
        </>
      ) : subscriptionlist?.length > 0 ? (
        <div style={!isMobile ? { display: "flex", flexDirection: "column", gap: "24px" } : null}>
        {subscriptionlist.map((orderItem, index) => (
            <OrderCard
              key={index}
              orderItem={orderItem}
              setOrderItems={setOrderItems}
              setShowOrderStatus={setShowOrderStatus}
              setShowOrderAnim={setShowOrderAnim}
              subscription={true}
            />
          ))}
        </div>
      ) : (
        <EmptyWrapper theme={theme}>
          <div className="empty-img-wrapper">
            <EmptySubscription />
            <div className="text">
              <h2 className="empty-title">No subscriptions</h2>
              <p>Explore our subscription options at checkout for extra savings!</p>
            </div>
          </div>
          <ButtonWrapper theme={theme}>
            <Button
              type={'mobile_save'}
              title={'Order now'}
              onClick={() => history.push('/shop/kitchen-home')}
            />
          </ButtonWrapper>
        </EmptyWrapper>
      )}
      {showOrderStatus && (
        <OrderStatus
          setShowOrderStatus={setShowOrderStatus}
          orderItems={orderItems}
          showOrderAnim={showOrderAnim}
          setShowOrderAnim={setShowOrderAnim}
          subscription={true}
          setClicked={setClicked}
          setSubscriptionList={setSubscriptionList}
        />
      )}
      {isMobile && clicked && (
        <motion.div
          className="items-popup"
            initial={{ y: 130 }}
            exit={{ y: 130 }}
            animate={{ y: 0 }}
            transition={{
              duration: 0.3,
              delay: 0,
              ease: 'linear',
            }}
          >      
          <div className="check">
            <img src="/icons/check-white.svg" />
            <p>Subscription canceled!</p>
          </div>
          </motion.div>
      )}
    </>
  );
}

export default subscriptions