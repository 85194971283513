import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import ImageViewer from 'components/ImageViewer';
import { IoSettingsOutline } from 'react-icons/io5'
import { ManageProfileModal } from './ManageProfileModal';
import { ReactComponent as Chat } from '../../assets/chat.svg';
import { ReactComponent as Export } from '../../assets/export.svg';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Progress from 'components/Progress';
import { CreateNutritionistModal } from './HandleNutritionistModal';
import { InviteNutritionistModal } from './HandleNutritionistModal';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Alert from 'components/Alert';
import {toast} from "react-toastify";

const CoachManagementStyle = styled.section`
    position: relative;
    font-family: 'Roboto';
    width: calc(100% - 300px);
    margin-left: 300px;
    padding: 54px;
    background: #ffffff;
    min-height: calc(100vh - 32px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    scroll-behaviour: smooth;
    .container_box{
        width: 100%;
        max-width: 900px;
        margin: auto;
    }
    .title{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #51635D;
        text-align: center;
        margin-bottom: 54px;
    }
    .search_box{
        max-width: 500px;
        width: 100%;
        margin: auto;
        position: relative;
        .form_control{
            padding: 14px 15px 14px 41px;
            background: #FFFFFF;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            font-weight: 400;
            font-size: 14px !important;
            line-height: 16px;
            color: #51635D;
            width: 100%;
            height: 44px;
            &::placeholder{
                color: #AFB5B4;
            }
        }
        .search_icon{
            position: absolute;
            left: 15px;
            top: 14px;
            z-index: 4;
        }
    }
    .user_table{
        margin-top: 30px;
        width: 100%;
        border: none;
        border-spacing: 0 5px;
        padding: 0 4px;
        thead{
            th{
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #51635D;
                padding: 14px 0 9px;
                :first-child{
                    width: 60px;
                }
                &:nth-child(2){
                    text-align: start;
                }
            }
        }
        tbody{
            tr{
                background: #F6F5F8;
                transition: all 0.5s;
                border-radius: 6px;
                &:nth-child(2n){
                    background: #FFFFFF;
                }
                &.active{
                    background: #F0FAEB;
                    box-shadow: 0px 0px 1px 1px #CBEDBA;
                    td{
                        border-top: 1px solid #CBEDBA;
                        border-bottom: 1px solid #CBEDBA;
                        &:first-child{
                            border-left: 1px solid #CBEDBA;
                        }
                        &:last-child{
                            border-right: 1px solid #CBEDBA;
                        }
                    }
                }
            }
            td{
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #51635D;
                text-align: center;
                vertical-align: middle;
                height: 48px;
                border-top: 1px solid #E0E3E0;
                border-bottom: 1px solid #E0E3E0;
                padding: 8px 0;
                &.name{
                    text-align: start;
                    max-width: 100px;
                    cursor: pointer;
                    span{
                        text-decoration-line: underline;
                        text-transform: capitalize;
                        text-underline-offset: 1.5px;
                        word-break: break-all;
                    }
                    .link{
                        transform: translateY(2px);
                        opacity: 0;
                        transition: all 0.2s;
                    }
                    &:hover{
                        .link{
                            opacity: 1;
                        }
                    }
                }
                .online{
                    position: relative;
                    &::before{
                        content: '';
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #35A956;
                        display: inline-block;
                        margin-right: 6px;
                        transform: translateY(-0.7px);
                    }
                }
                .user_img{
                    max-width: 32px;
                    max-height: 32px;
                    width: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                    display: block;
                    margin: auto;
                }
                &:first-child{
                    border-radius: 6px 0 0 6px;
                    border-left: 1px solid #E0E3E0;
                    width: 60px;
                }
                &:last-child{
                    border-radius: 0px 6px 6px 0px;
                    border-right: 1px solid #E0E3E0;
                }
                .settings_icon, .chat_icon{
                    font-size: 12px;
                    color: #52C41A;
                    border-radius: 50%;
                    background-color: #fff;
                    width: 32px;
                    height: 32px;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 1px solid #F6F5F8;
                    .icon{
                        width: 16px;
                        height: 16px;
                    }
                }
                .acc_status{
                    background: #FFFFFF;
                    border-radius: 15px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    padding: 4px 9px;
                    color: #949D9B;
                    border: 1px solid rgba(175, 181, 180, 0.2);
                    &.active{
                        color: #35A956;
                        border: 1px solid rgba(53, 169, 86, 0.2);
                    }
                    &.suspended{
                        color: #FF4F00;
                        border: 1px solid rgba(255, 79, 0, 0.2);
                    }
                }
            }
        }
    }
    @media (max-width:576px){
    min-height: 100vh;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    margin-bottom: 32px;
    padding: 24px 20px 32px;
    .title{
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 20px;
    }
    }
`
const ButtonStyles = styled.div`
    position: absolute;
    right: 24px;
    top: 24px;
    text-align: center;
    z-index: 2;
    .btn_green{
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        background: #52C41A;
        border-radius: 25px;
        padding: 12px 34px;
        margin-left: 10px;
        display: inline-block;
        &:first-child{
            margin-left: 0px;
        }
    }
    @media (max-width:576px){
        position: relative;
        right: auto;
        top: auto;
        margin: 24px 0;
    }
`

const UserCardStyle = styled.div`
    margin: 5px 0;
    padding: 16px 14px;
    background: #F6F5F8;
    border: 1px solid #E0E3E0;
    border-radius: 6px;
    font-size: 12px;
    line-height: 14px;
    &:first-child{
        margin-top: 24px;
    }
    &:nth-child(2n){
        background: #FFFFFF;
    }
    .card_header{
        display: flex;
        align-items: center;
        gap: 7px;
        margin-bottom: 16px;
        .card_img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
        .name{
            font-weight: 400;
            color: #51635D;
            flex: 1;
            text-decoration: underline;
            word-break: break-all;
            text-transform: capitalize;
        }
        .status{
            font-weight: 400;
            border: 1px solid rgba(175, 181, 180, 0.2);
            background: #FFFFFF;
            border-radius: 10px;
            padding: 4px 9px;
            color: #949D9B;
            font-size: 10px;
            line-height: 12px;
            &.active{
                color: #35A956;
                border: 1px solid rgba(53, 169, 86, 0.2);
            }
            &.suspended{
                color: #FF4F00;
                border: 1px solid rgba(255, 79, 0, 0.2);
            }
        }
    }
    .card_body{
        .followers{
            font-weight: 400;
            color: #51635D;
            .bold{
                font-weight: 700;
            }
        }
    }
    .card_footer{
        display: flex;
        gap: 10px;
        align-items: center;
        .online_status{
            font-weight: 700 !important;
            color: #51635D;
            flex: 1;
            .online{
                position: relative;
                &::before{
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: #35A956;
                    display: inline-block;
                    margin-right: 6px;
                    transform: translateY(-0.7px);
                }
            }
        }
        .settings_icon, .chat_icon{
            font-size: 12px;
            color: #52C41A;
            border-radius: 50%;
            background-color: #fff;
            width: 32px;
            height: 32px;
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid #F6F5F8;
            .icon{
                width: 16px;
                height: 16px;
            }
        }
    }
`

export const CoachManagement = () => {
    const [showManageModal, setShowManageModal] = useState(false);
    const [users, setUsers] = useState([]);
    const {isDesktop, setSelectedNutritionistId} = useAppContext();
    const [getAllNutritionist, { isLoading, error, state: success, setState: setSuccess }] = useRequest('nutritionist/coaches/all');
    const [currentSlice, setCurrentSlice] = useState(20);
    const [searchTerm, setSearchTerm] = useState('')
    const [hasMore, setHasMore] = useState(true)
    const [userToManage, setUserToManage] = useState('');
    const [isCreateActive, setIsCreateActive] = useState(false);
    const [isInviteActive, setIsInviteActive] = useState(false);
    const [activeRow, setActiveRow] = useState();
    const history = useHistory();
    const [isAlert, setIsAlert] = useState(false);
    const [coachIsSuspended, setCoachIsSuspended] = useState(null);
    const [suspendNutritionist, {isLoading: isSuspending, suspendedError}] = useRequest('admin/account-suspended', 'put');

    useEffect(() => {
        if (showManageModal) {
            document.body.classList.add('hide-scroll');
        } else {
            document.body.classList.remove('hide-scroll');
        }
        return () => {
            document.body.classList.remove('hide-scroll');
        }
    }, [showManageModal])
    useEffect(() => {
        fetchAllNutritionist();
    }, [])
    const fetchAllNutritionist = async () => {
        await getAllNutritionist().then((response) => {
            const sortByDate = response?.coaches?.sort((a, b) => {
                return new Date(b?.createdAt) - new Date(a?.createdAt)
            })
            setUsers(sortByDate);
        }).catch((error) => { console.log(error.message) });
    }
    const getUserProfileName = (user) => {
        return user?.name;
    }
    const searchByNameOrEmail = (user) => {
        return user?.email?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 || getUserProfileName(user)?.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    }
    const usersToShow = useMemo(() => {
        if (searchTerm.length > 0 && users.length) {
            const filteredData = users?.filter((user) => searchByNameOrEmail(user));
            filteredData.sort((a, b) => {
                const aExactMatch = a.name.toLowerCase() === searchTerm.toLowerCase();
                const bExactMatch = b.name.toLowerCase() === searchTerm.toLowerCase();
                if (aExactMatch && !bExactMatch) {
                  return -1;
                } else if (!aExactMatch && bExactMatch) {
                  return 1;
                } else {
                  return 0;
                }
            });
            return filteredData.slice(0, currentSlice);
        } else {
            return users?.slice(0, currentSlice);
        }
    }, [users, searchTerm, currentSlice])
    const fetchMoreData = () => {
        setTimeout(() => {
            setCurrentSlice(currentSlice + 10)
        }, 1500);
    };
    useEffect(() => {
        if (usersToShow.length === users.length) {
            setHasMore(false);
            return;
        } else {
            setHasMore(true);
            return;
        }
    }, [usersToShow, users])
    
    const handleSettings = (user, index) => {
        setUserToManage(user);
        setShowManageModal(true);
        setActiveRow(index);
    }

    const handleProfileClick = (user) => {
        localStorage.setItem('imitateNutritionist', JSON.stringify(user));
        setSelectedNutritionistId(user?.userId);
        window.open('/nutritionist', '_blank')
    }

    const cancelUpdate = () => {
        setIsAlert(false);
    }

    const handleOk = async () => {
        await suspendNutritionist({
            body: {
                ...coachIsSuspended
            }
        }).then((res) => {
            if (res.success == false) {
                toast.error(res.message);
            }
        })
        fetchAllNutritionist();
        setIsAlert(false);
        setShowManageModal(false);
    }

    return (
        <>
        {isLoading && users?.length <= 0 ? <Progress/> : 
            <>
                <ButtonStyles>
                    <Link className='btn_green' onClick={(e) => {
                        e.preventDefault();
                        setIsCreateActive(true);
                    }}>+ Create</Link>
                    <Link className='btn_green' onClick={(e) => {
                        e.preventDefault();
                        setIsInviteActive(true);
                    }}>+ Invite</Link>
                </ButtonStyles>
                <CoachManagementStyle>
                    <div className='container_box'>
                        <h2 className='title'>{`Coaches – ${searchTerm !== '' ? usersToShow?.length : users?.length}`}</h2>
                        <div className='search_box'>
                            <input type='text' className='form_control' onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} placeholder='Search coach...' />
                            <SearchIcon className='search_icon' />
                        </div>
                        <InfiniteScroll
                            dataLength={usersToShow?.length}
                            next={fetchMoreData}
                            pullDownToRefreshThreshold={50}
                            hasMore={hasMore}
                            loader={(hasMore && !searchTerm) && <h4>Loading...</h4>}
                        >
                            {isDesktop ? 
                            <table className='user_table' cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Followers</th>
                                        {/* <th>Last Active ↓</th> */}
                                        <th>Account Status</th>
                                        <th>Manage</th>
                                        {/* <th>Contact</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {usersToShow.length > 0 ? usersToShow?.map((user, index) => {
                                        let activeStatus;
                                        const active = moment(user?.lastActive);
                                        const lastActive = active?.format('MM/DD/YYYY h:mm a');
                                        const currDT = new Date();
                                        const currDTMoment = moment(currDT.toISOString());
                                        const isActiveNow = lastActive == currDTMoment?.format('MM/DD/YYYY h:mm a');
                                        if(isActiveNow){
                                            activeStatus = "Active now"
                                        }else{
                                            activeStatus = lastActive
                                        }
                                        return (
                                            <tr className={activeRow === index ? 'active':''} key={index}>
                                                <td><ImageViewer src={user?.img || '/images/manss.svg'} className="user_img" fallbackSrc={'/images/man.svg'} /></td>
                                                <td className='name' onClick={() => {handleProfileClick(user)}}><span>{user?.name || 'N/A'}</span>&nbsp;<Export className='link' /></td>
                                                <td>{user?.followerscount || 0}</td>
                                                {/* <td><span className={(activeStatus == "Active now") && 'online' }>{user?.lastActive ? activeStatus : 'N/A'}</span></td> */}
                                                <td><span className={`acc_status ${user?.isApproved ? 'active' : user?.isSuspended ? 'suspended' : ''}`}>{user?.isApproved ? 'Active' : user?.isSuspended ? 'Suspended' : 'Unlisted'}</span></td>
                                                <td><div className='settings_icon' onClick={() => {handleSettings(user, index)}}><IoSettingsOutline className='icon' /></div></td>
                                                {/* <td><div className='chat_icon'><Chat className='icon' /></div></td> */}
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="7">No coach found</td>
                                    </tr>}
                                </tbody>
                            </table>
                            :
                            <>
                            {usersToShow.length > 0 ? usersToShow?.map((user, index) => {
                                let activeStatus;
                                const active = moment(user?.lastActive);
                                const lastActive = active?.format('MM/DD/YYYY h:mm a');
                                const currDT = new Date();
                                const currDTMoment = moment(currDT.toISOString());
                                const isActiveNow = lastActive == currDTMoment?.format('MM/DD/YYYY h:mm a');
                                if(isActiveNow){
                                    activeStatus = "Active now"
                                }else{
                                    activeStatus = lastActive
                                }
                                return (
                                <UserCardStyle key={index} className='user_card'>
                                    <div className='card_header'>
                                        <ImageViewer src={user?.img || '/images/manss.svg'} fallbackSrc={'/images/man.svg'} className='card_img'/>
                                        <p className='name' onClick={() => {handleProfileClick(user)}}>{user?.name || 'N/A'}</p>
                                        <span  className={`status ${user?.isApproved ? 'active' : user?.isSuspended ? 'suspended' : ''}`}>{user?.isApproved ? 'Active' : user?.isSuspended ? 'Suspended' : 'Unlisted'}</span>
                                    </div>
                                    <div className='card_body'>
                                        <p className='followers'>Followers: <span className='bold'>{user?.followerscount || 0}</span></p>
                                    </div>
                                    <div className='card_footer'>
                                        {/* <p className='online_status'><span className={(activeStatus == "Active now") && 'online' }>{user?.lastActive ? activeStatus : 'N/A'}</span></p> */}
                                        <div className='settings_icon' onClick={() => {handleSettings(user)}}><IoSettingsOutline className='icon' /></div>
                                        {/* <div className='chat_icon'><Chat className='icon' /></div> */}
                                    </div>
                                </UserCardStyle>
                                )
                            }) : <UserCardStyle className='user_card'>
                                    <p>No coach found</p>
                                </UserCardStyle>}
                            </>
                            }
                        </InfiniteScroll>
                            
                    </div>
                </CoachManagementStyle>
                {(showManageModal && userToManage) && <ManageProfileModal setActiveRow={setActiveRow} fetchAllNutritionist={fetchAllNutritionist} setUsers={setUsers} userToManage={userToManage} setShowManageModal={setShowManageModal} setIsAlert={setIsAlert} setCoachIsSuspended={setCoachIsSuspended} coachIsSuspended={coachIsSuspended} handleProfileClick={handleProfileClick}/>}
                {isCreateActive && <CreateNutritionistModal setIsCreateActive={setIsCreateActive} setUsers={setUsers}/>}
                {isInviteActive && <InviteNutritionistModal setIsInviteActive={setIsInviteActive}/>}
                {isAlert && <Alert 
                    title={'Suspend account'}
                    desc={'Are you sure you want to suspend the account?'}
                    desc2={'This will prevent the coach to use account & remove followers.'}
                    cancel={cancelUpdate}
                    cancelText={'Cancel'}
                    ok={handleOk}
                    okText={'Suspend'}
                />}
                {isSuspending && <Progress/>}
            </>
        }
        </>
    )
}