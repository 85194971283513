import { useEffect } from 'react';
import ErrorComponent from 'components/ErrorComponent';
import { FormContainer } from 'components/auth/StyledLoginForm';
import { useLocation } from 'react-router-dom';
import Progress from 'components/Progress';

import { useRequest } from 'hooks/useRequest';
import RegisterForm from 'components/auth/RegisterForm';
import NutrittLogo from 'layout/NutrittLogo';
import Header from 'layout/Header';

const RegisterProvider = () => {
  const params = new URLSearchParams(useLocation().search);
  const email = params.get('email');
  const providerId = params.get('providerId');
  const token = params.get('code');

  const [verifyInvite, { isLoading: isInviteLoading, state, error: inviteError }] = useRequest(
    `provider/verifyinvite`,
    'post',
  );

  useEffect(() => {
    verifyInvite({ body: { email, providerId, token } });
  }, []);

  const [register, { isLoading, error, setError }] = useRequest('provider/register', 'post');

  if (isInviteLoading) return <Progress />;
  if (inviteError) return <ErrorComponent error={inviteError} />;

  if (state && !state.success) return <ErrorComponent error={'Invalid Invitation'} />;

  return (
    <>
         <Header/>
          <RegisterForm
            register={register}
            isLoading={isLoading}
            error={error}
            setError={setError}
            providerRegister={{ token, providerEmail: email, providerId }}
          />
     </>
  );
};

export default RegisterProvider;
