import styled from 'styled-components'
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import useDisplayAmount from 'hooks/useDisplayAmount';
import UseClientCart from 'hooks/UseClientCart';
import { useNutritionistContext } from 'context/nutritionistContext';
import { useAppContext } from 'context/appContext';

const CartCardItemStyle = styled.div`
    background: #FFFFFF;
    border: 1px solid #E0E3E0;
    border-radius: 5px;
    display: flex;
    gap: 8px;
    position: relative;
    width: 100%;

    .food_img{
        width: 63px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 8px;

        img{
            width: 100%;
        }
    }

    .food_info{
        flex: 1;
        padding: 18px 24px 14px 0;

        .title{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #0B1D17;
            margin-bottom: 5px;
            text-transform: initial;
        }

        .nutrients_info{
            display: flex;
            column-gap: 13px;
            justify-content: space-between;

            .nutr_info{
                font-weight: 400;
                font-size: 9px;
                line-height: 12px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #51635D;
                position: relative;

                &::before{
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -7px;
                }

                &:nth-child(2)::before{
                    background-color: #916AE2;
                }

                &:nth-child(3)::before{
                    background-color: #FEB95A;
                }

                &:nth-child(4)::before{
                    background-color: #EE786B;
                }

                span{
                    font-weight: 700;
                }
            }
        }
    }

    .more__options{
        display: inherit;
        position: absolute;
        width: 14px;
        height: 14px;
        right: 6px;
        top: 6px;
    }

    .cart_func_holder{
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cartIncDec{
        width: 150px;
        height: 32px;
        display: flex;
        background: #FFFFFF;
        border: 1px solid #E0E3E0;
        border-radius: 6px;

        .counter_icon{
            width: 40px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 12px;
            color: #949D9B;
        }

        input{
            flex: 1;
            width: 100%;
            border: 0px;
            text-align: center;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 14px;
            color: #0B1D17;
        }
    }

    .total_amount{
        font-weight: 700;
        font-size: 12px;
        line-height: 32px;
        color: #51635D;
        padding-right: 8px;
        text-align: center;
    }

`

export default function CartCardItem({cartItem}) {

    const { name: mealName, img: mealImg, price, calories, carbs, id, fat, protein, qty } = cartItem;
    const { addToClientCart, updateClientMealQty, removeFromClientCart } = UseClientCart();
    const { clientcart } = useNutritionistContext();
    const mealInCart = clientcart.find((meal) => meal.id === id);
    const {convertToHttps} = useAppContext();
    const secureImageUrl = convertToHttps(mealImg);

    return (
        <CartCardItemStyle>
            <div className='food_img'>
                <img src={secureImageUrl ? secureImageUrl : '/images/dish.svg'} />
            </div>
            <div className='food_info'>
                <h3 className='title'>{mealName}</h3>

                <div className='nutrients_info'>
                    <div className='nutr_info'>
                        Cals <span>{calories}</span>
                    </div>
                    <div className='nutr_info'>
                        Carbs <span>{carbs}g</span>
                    </div>
                    <div className='nutr_info'>
                        Fat <span>{fat}g</span>
                    </div>
                    <div className='nutr_info'>
                        Protein  <span>{protein}g</span>
                    </div>
                </div>

                <div className='cart_func_holder'>
                    <div className='cartIncDec'>
                        <span className='counter_icon'
                           onClick={() =>
                            mealInCart.qty == 1
                                ? removeFromClientCart(cartItem.id)
                                : updateClientMealQty(cartItem, +mealInCart.qty - 1)
                        }
                        >
                            <CgMathMinus />
                        </span>
                        <input
                            data-testid="qty-content"
                            type="number"
                            pattern="\d*"
                            autoComplete="off"
                            className="math"
                            value={qty}
                            onChange={(e) => {
                                if(e.target.value>1000) return 
                                return updateClientMealQty(cartItem, +e.target.value)
                            }}
                        />
                        <span className='counter_icon' onClick={() => addToClientCart(cartItem)}>
                            <CgMathPlus />
                        </span>
                    </div>

                    <div className='total_amount'>
                    {useDisplayAmount(price / 100)}
                    </div>
                </div>
            </div>

            <span className='more__options'>
                <img src='/icons/trash2.svg' onClick={() => {removeFromClientCart(id)}} />
            </span>
        </CartCardItemStyle>
    )

}