import { Line } from 'rc-progress';
import styled from 'styled-components';

const flexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NutritionistClientCaloriesStyle = styled.div`
    width: 100%;
    margin: 0 auto;
    //padding: 14px;
    background-color: #fff;
    //border-bottom: 1px solid #F6F5F8;
    display: flex;
    flex-direction: column;
    align-items: center;

    .daily-content {
        color: #0b1c18;
        font-size: 11px;

        span {
            font-size: 16px;
        }
    }
    .daily-progress{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 14px;
        border-bottom: 1px solid #E0E3E0;
        width:100%;
        .rc-progress-line{
            width: 90%;
            background: #E0E3E0;
            border-radius: 5px;
            .rc-progress-line-path{
                transition: stroke-dashoffset 3s linear 0.5s, stroke-dasharray 3s linear 0.5s, stroke 3s linear 0.5s, 1s !important;
            }
        }
    }
    .daily-calories {
        ${flexBetween};
        width:90%;
        padding-bottom: 6px;
        span {
            color: #51635d;
            font-size: 12px;
            line-height: 14px;
            font-family: 'Roboto';
        }

        .calculate {
            font-weight: bold;
        }
        
    }
    .calorie-value {
        ${flexBetween};
        margin-bottom:6px;
        span {
            color: #51635d;
            font-size: 12px;
            line-height: 14px;
            font-family: 'Roboto';
            white-space: nowrap;
            // margin-left: 10px;
        }
        .title{
            width:65px;
        }

        .calculate {
            font-weight: 700;
            width:70px;
            text-align: right;
        }
        
    }

    .progress-macros-wrapper {
        width: 83%;
        //margin: 0 auto;
        padding: 14px 0;
       // border-bottom: 1px solid #E0E3E0;

       .rc-progress-line{
         width: 80%;
         background: #E0E3E0;
         border-radius: 4px;
         .rc-progress-line-path{
             transition: stroke-dashoffset 3s linear 0.5s, stroke-dasharray 3s linear 0.5s, stroke 3s linear 0.5s, 1s !important;
           }
        }
      }
}
@media(max-width:991px){
    .calorie-value{
        .title{
            max-width:15%;
            width:15%;
            margin-right:2%;
        }
        .rc-progress-line{
            width:58%;
        }
        .calculate{
            max-width:23%;
            width:23%;
            margin-left:2%;
        }
    }
}
 
`;



export default function NutritionistClientCalories({ recommndedNutrients, diaryMeals }) {


    const displayNumbers = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2);
    };
  const isMobile = true;
  return (
    <NutritionistClientCaloriesStyle>
            <div className="daily-progress">
            {isMobile && (
                <div className="daily-calories">
                    <span className="title">Calories</span>
                    <span className="calculate">
                    {diaryMeals?.totalCals}/ {recommndedNutrients?.calories || 0}
                    </span>
                </div>
            )}
            <Line
                percent={Math.round((diaryMeals?.totalCals / recommndedNutrients?.calories ) * 100)}
                strokeColor="#52c41a"
                trailColor="#E0E3E0"
                height="10px"
                strokeLinecap='round'
            />
            </div>
          <div className="progress-macros-wrapper">

              <div className='calorie-value'>
                  <span className="title">Fats</span>

                  <Line
                      percent={Math.round((diaryMeals?.consumedInfo?.fat/recommndedNutrients?.macros?.fat ||0 ) * 100)}
                      strokeColor="#FEB95A"
                      trailColor="#E0E3E0"
                      height="5px"
                      strokeLinecap='round'
                  />
                  <span className="calculate">
                     {displayNumbers(diaryMeals?.consumedInfo?.fat)} / {recommndedNutrients?.macros?.fat}g
                  </span>
              </div>

              <div className='calorie-value'>

                  <span className="title">Proteins</span>

                  <Line
                      percent={Math.round((diaryMeals.consumedInfo?.protein/recommndedNutrients?.macros?.protein) * 100)}
                      strokeColor="#EE786B"
                      trailColor="#E0E3E0"
                      height="5px"
                      strokeLinecap='round'
                  />
                  <span className="calculate">
                      { displayNumbers(diaryMeals.consumedInfo?.protein)} / {recommndedNutrients?.macros?.protein}g
                  </span>
              </div>

              <div className='calorie-value'>
                  <span className="title">Carbs</span>

                  <Line
                      percent={Math.round((diaryMeals.consumedInfo?.carbs/recommndedNutrients?.macros?.carbs) * 100)}
                      strokeColor="#916AE2"
                      trailColor="#E0E3E0"
                      height="5px"
                      strokeLinecap='round'
                  />
                  <span className="calculate">
                      {displayNumbers(diaryMeals.consumedInfo?.carbs)} / {recommndedNutrients?.macros?.carbs}g
                  </span>

              </div>

          </div>
    </NutritionistClientCaloriesStyle>
  );
}
