import classNames from 'classnames';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useShopContext } from 'context/shopContext';

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 6px var(--grayShadow);
  background-color: #fff;

  .link-wrapper {
    display: flex;
    align-items: center;
    span {
      color: var(--dark);
      font-size: 14px;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .active > span {
    color: var(--nutritt-green);
  }

  .icon-wrapper {
    margin: 0 10px;
    display: flex;
    align-items: center;
    svg {
      font-size: 14px;
    }
  }

  .disabled span {
    color: var(--disabled);
    cursor: not-allowed;
  }
`;

export default function Steps() {
  const { settings, cartCount } = useShopContext();
  const { userInfo, provider } = settings;

  const { pathname } = useLocation();
  const history = useHistory();
  const links = [
    {
      label: 'Plan',
      step: 1,
      path: '/shop/plan',
    },
    {
      label: 'Meal Providers',
      step: 2,
      path: '/shop/providers',
      disabled: !userInfo?.zipcode,
    },
    {
      label: 'Meals',
      step: 3,
      path: '/shop/meals',
      disabled: !provider?.id || !userInfo?.zipcode,
    },
    {
      label: 'Check Out',
      step: 4,
      path: '/shop/checkout',
      disabled: cartCount < settings.days * settings.meals,
    },
  ];
  useEffect(() => {
    /*if (pathname === '/shop/plan') return;
    if (!provider) return history.push('/shop/providers');
    if (!userInfo.zipcode) return history.push('/shop/plan');*/

    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Container>
      {links.map(({ label, path, disabled }, i) => (
        <div
          onClick={() => !disabled && history.push(path)}
          key={i}
          className={classNames('link-wrapper', {
            active: pathname.includes(path),
            disabled: disabled && pathname !== path,
          })}
        >
          <span>{label}</span>
          {links.length - 1 > i && <span className="icon-wrapper">&#62;</span>}
        </div>
      ))}
    </Container>
  );
}

