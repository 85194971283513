import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const SplitPanelWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 0;
  overflow-y: auto;
  width: 610px;
  height: calc(100vh - 252px);
  background-color: ${theme.colors.secondary};
  border-top: 1px solid ${theme.colors.grayLighter};
  border-left: 1px solid ${theme.colors.grayLighter};
  border-radius: 0px 0px 9px 0px;
  font-family: ${theme.typography.fontGilroy};

  .split-panel-container {
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .order-info-controls {
    padding: 15px 22px 15px 25px;
    display: flex;
    justify-content: space-between;
    .order-controls-wrapper {
      display: flex;
      align-items: center;
      gap: 11px;
      .btn.dark {
        border: 0 !important;
        font-family: ${theme.typography.fontGilroy};
        &:after {
          display: none;
        }
      }
      .remove-order-wrapper,
      .export-icon-wrapper {
        height: 40px;
        padding: 10px;
        cursor: pointer;
        &:hover {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(255, 255, 255, 0.9) 100%
            ),
            #878a98;
          border-radius: 6px;
        }
        &:active {
          background: #e9eaec;
        }
      }
    }
    .recurring-order-container {
      height: 20px;
      margin-left: 0;
      padding: 3px 4px 4px 4px;
      display: flex;
      align-items: center;
      .recurring-label {
        display: none;
      }
    }
  }

  .delivery-info-wrapper,
  .order-items-wrapper {
    padding: 0 24px;

    .section-title {
      color: ${theme.colors.blackDefault};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .no-order-selected {
    margin-top: 70px;
    color: ${theme.colors.primaryDark};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
  }
`;
