import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import CartCardItem from './CartCardItem';
import { getEarliestTime, toDateString } from '../../../../pages/shop/providerTimes';
import { useNutritionistContext } from 'context/nutritionistContext';
import Drawer from '@mui/material/Drawer';
import autoAnimate from '@formkit/auto-animate';
import { useAppContext } from 'context/appContext';

const CartCardStyle = styled.div`
    background-color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: auto;
    margin-bottom: 18px;

    &::-webkit-scrollbar{
        display: none;
    }

    .provider__card__small{

        .about__provider{
            display: flex;
            gap: 10px;
            padding: 10px 16px;
            align-items: center;
            border-bottom: 1px solid #F6F5F8;
            position: relative;
        }

        .provider__image{
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background-color: #e0e5e0;
            overflow: clip;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .provider__name{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #0B1D17;
        }

        .delivery__pickup{
            display: flex;
            flex-wrap: wrap;
        }

        .min__order{
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.02em;
            color: #949D9B;
            border-radius: 4px 0px 0px 4px;
            padding: 2px 0;
        }

        .icon__with__text{
            display: flex;
            width: 50%;
            gap: 6px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            padding: 10px 10px 10px 16px;
            justify-content: center;
            position: relative;

            &:nth-child(2){
                padding: 10px 16px 10px 11px;
                
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #F6F5F8;
                }
            }

            img{
                width: 14px;
                height: 14px;
            }

            p{
                flex: 1;
                white-space: nowrap;
            }

            span{
                font-weight: 700;
            }
        }
    }

    .cart__from__provider{
        display: flex;
        justify-content: space-between;
        padding: 13px 17px;
        border-top: 1px solid #F6F5F8;

        .no_of_meals{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
        }

        .clear_all_meals{
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: right;
            color: #52C41A;
        }
    }

    .add_more_meal{
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #52C41A;
        text-align: center;
        padding-top: 14px;
        padding-bottom: 14px;
    }

    .cart__items__holder{
        padding: 0px 10px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 6px;
    }

    @media (min-width: 390px) and (max-width: 576px){

        .provider__card__small{

            .icon__with__text{
                justify-content: flex-start;

                &:nth-child(2){
                    justify-content: flex-end;
                }
                p{
                    flex: none;
                }
            }
        }
        
    }

    @media (max-width: 359px){

        .provider__card__small{

            .about__provider{
                padding: 10px 14px;
            }

            .icon__with__text{
                width: 100%;
                padding: 7px 14px;

                &:nth-child(2){
                    padding: 7px 14px;
    
                    &::before{
                        left: 3%;
                        width: 94%;
                        height: 1px;
                        background-color: #F6F5F8;
                    }
                }
            }
        }

    }

`
const SelectModalStyle = styled.div`
 padding:20px 15px;

.choose-provider {
    width:92%;
    height:auto;
    background:#fff;
    margin: 0 auto;
    padding:24px 24px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    max-height:calc(100vh - 40px);
    overflow:auto;
    position: absolute;
    bottom: 20px;
    &::-webkit-scrollbar {
        display:none;
      }

    .provider{
       font-weight: 700;
       font-size: 16px;
       line-height: 19px;
       color: #0B1D17;
       margin-bottom:28px;
    }
    .name{
       height: 42px;
       font-weight: 400;
       font-size: 14px;
       line-height: 16px;
       color: #51635D;
       display:flex;
       align-items:center;
       justify-content:space-between;
       padding:0 18px;
       margin-bottom:11px;
    }
    .selected-name{
        border: 1px solid #CBEDBA;
       border-radius: 21px;
    }
   .btn-div{
       display:flex;
       justify-content:space-between;
       align-items:center;
       .cancel, .apply{
           border-radius: 21px;
           width: 138px;
           height: 40px;
           font-weight: 700;
           font-size: 14px;
           line-height: 16px;
           display:flex;
           justify-content:center;
           align-items:center;
       }
       .apply{
           background: #52C41A;
           color: #FFFFFF;
       }
       .cancel{
           background:#FFFFFF;
           border:1px solid #97DC76 !important;
           color: #51635D;
       }
   }
    
   }

`

export default function CartCard({ provider, setMealPlannerModal, setShowAnim, providerSettings, providerId, setProviderId, length}) {

    const { name: providerName, minOrderAmount, schedule } = provider;
    const earliestDeliveryTime = getEarliestTime(schedule, 'delivery')
    const earliestPickupTime = getEarliestTime(schedule, 'pickup')
    const [mealsAmount, setMealsAmount] = useState(0);
    const { clientcart, updateClientcart, clientsettings, updateClientsettings } = useNutritionistContext();
    const [selectModal, setSelectModal] = useState(false);
    const {convertToHttps} = useAppContext();

    useEffect(() => {
        const noOfMeals = clientcart.filter(item => item.providerId === provider.id).map((item) => item.qty).reduce((acc, curr) => {
           return acc += curr;
        }, 0);
        setMealsAmount(noOfMeals);
    }, [clientcart]);

    const clearAllMeals = () => {
        const updatedClientCart = clientcart.filter(item => item.providerId !== provider.id);
        updateClientcart(updatedClientCart);
        const updatedMealPlan = clientsettings?.mealPlan?.filter(item => item.providerId !== provider.id);
        updateClientsettings({...clientsettings, mealPlan: updatedMealPlan })
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSelectModal(open);
    };

    const animateParent = useRef(null);

    useEffect(() => {
        animateParent.current && autoAnimate(animateParent.current)
    }, [animateParent])

    const imageUrl = provider?.images?.[0];
    const secureImageUrl = convertToHttps(imageUrl);

    return (
        <CartCardStyle>
            <div className="provider__card__small">
                <div className='about__provider'>
                    <div className='provider__image'>
                        <img src={secureImageUrl ? secureImageUrl : "/images/avatar.png"} />
                    </div>
                    <div className='provider__name'>
                        {providerName}
                        <div className='min__order'>
                            {`min. order $${minOrderAmount}`}
                        </div>
                    </div>

                </div>
                <div className='delivery__pickup'>
                    {earliestDeliveryTime &&
                        <div className="icon__with__text">
                            <img src='/icons/delivery-icon.svg' />
                            <p>Delivery by: <span>{toDateString(earliestDeliveryTime)}</span></p>
                        </div>
                    }

                    {earliestPickupTime &&
                        <div className="icon__with__text">
                            <img src='/icons/pickup-icon.svg' />
                            <p>Pick-up by: <span>{toDateString(earliestPickupTime)}</span></p>
                        </div>
                    }
                </div>
            </div>

            <div className='cart__from__provider'>
                <p className='no_of_meals'>{`${mealsAmount} meal${mealsAmount > 1 ? 's' : ''}`}</p>
                <p className='clear_all_meals' onClick={clearAllMeals}>Clear all</p>
            </div>

            {/* Cart Food Item */}
            <div className='cart__items__holder' ref={animateParent}>
                {clientcart.filter(item => item.providerId === provider.id).map((cartItem, index) => {
                    return <CartCardItem cartItem={cartItem} key={index}/>
                })}
            </div>

            <div className='add_more_meal' onClick={length>1 ? () =>{setSelectModal(true), setProviderId(provider?.id)} : ()=>{setMealPlannerModal(false)}}><span>+ Add more meal</span></div>

            <Drawer anchor='bottom' overlayStyle={{ backgroundColor: "yellow" }} transitionDuration={500} open={selectModal} onClose={toggleDrawer(false)} className='another-modal'
                        >
                     { selectModal &&
                            <SelectModalStyle>
                                <div className='choose-provider'>
                                    <div className='provider'>
                                        <p>Select meal provider</p>
                                    </div>
                                { providerSettings.map((provider)=>{ 
                                     return <div key={provider.id} className={`name ${provider.id===providerId ? 'selected-name': ''}`} onClick={()=>setProviderId(provider.id)}>
                                        <p>{provider.name}</p>
                                        {provider.id===providerId  && <img src='/icons/check.svg' />}
                                    </div>
                                })
                                }
                                    <div className='btn-div'>
                                        <button className='cancel' onClick={()=>setSelectModal(false)}>Cancel</button>
                                        <button className='apply' onClick={()=>{setMealPlannerModal(false)}}>Apply</button>
                                    </div>
                                </div>
                           </SelectModalStyle>
                     }
                        
                        </Drawer>

        </CartCardStyle>
    )

}