import { ReactComponent as Facebook } from 'icons/facebook.svg';
import { ReactComponent as Instagram } from 'icons/instagram.svg';
import { ReactComponent as Linkedin } from 'icons/linkedin.svg';

export const socialMediaIcons = [
  { id: 1, title: <Facebook />, link: 'https://www.facebook.com/nutrittofficial' },
  { id: 2, title: <Instagram />, link: 'https://www.instagram.com/nutritt_' },
  { id: 3, title: <Linkedin />, link: 'https://www.linkedin.com/company/nutrittofficial' },
];

export const dietPlanDeliveredImages = ["diet-plan-delivered-6", "diet-plan-delivered-7"];
export const dietPlanDeliveredMobileImages = ["diet-plan-delivered-10","diet-plan-delivered-8", "diet-plan-delivered-9"];
export const featureCardImage = ["feature-image-1","feature-image-2","feature-image-3"]
export const dietPlanTabletImages = ["diet-plan-delivered-9", "diet-plan-delivered-11"]

export const NutrittFeaturesItems = [
  {
    id: 1,
    name: "nutritt-feature-1",
    description: "Discover meals from premium local healthy chefs"
  },
  {
    id: 2,
    name: "nutritt-feature-2",
    description: "Plan meals based on your diet goals"
  },
  {
    id: 3,
    name: "nutritt-feature-3",
    description: "Track your macros and progress"
  },
  {
    id: 4,
    name: "nutritt-feature-4",
    description: "Connect with the best coaches"
  }
];

export const noExcusesdishes = ["no-excuses-1", "no-excuses-2", "no-excuses-3"];

export const PeopleReviewsItems = [
  {
    id: 1,
    img: "people-review-1",
    review: "Nutritt is the BEST solution for eating the most delicious, healthy food on the go. I never have time to cook healthy food and eating out is so unhealthy and expensive. Nutritt has absolutely changed my lifestyle and has made eating healthy food so convenient. Food is DELICIOUS.",
    name: "Robert Ninari",
    location: "Brooklyn, NY"
  },
  {
    id: 2,
    img: "people-review-2",
    review: "Impressed with Nutritt's gourmet meals and local focus. The variety is fantastic, and supporting community kitchens feels great. Fresh, flavorful, and sustainable – exactly what I was looking for!",
    name: "Bessie Cooper",
    location: "Garden City, NY"
  },
  {
    id: 3,
    img: "people-review-3",
    review:  (
      <div>
        Nutritt is exactly what I've been seeking! It's a joy to find a platform that showcases outstanding local kitchens.<br />
        Every meal is not only delicious but also nutritious – it's a rare find!
      </div>
    ),
    name: "Ronald Richards",
    location: "Manhattan, NY"
  },
  {
    id: 4,
    img: "people-review-3",
    review: "Nutritt has transformed my meal times! Love the easy access to healthy, local meals. It's convenient, nutritious, and always delicious.",
    name: "Marvin McKinney",
    location: "West Village, NY"
  },
  {
    id: 5,
    img: "people-review-3",
    review: "With Nutritt, healthy eating is a joy. Great variety, fresh local ingredients, and every dish is a tasty, nutritious delight!",
    name: "Floyd Miles",
    location: "Lenox Hill, NY"
  }

];

export const partnerKitchenItems = [
  {
    id: 1,
    image: "kitchen-1"
  },
  {
    id: 2,
    image: "kitchen-2"
  },
  {
    id: 3,
    image: "kitchen-3"
  },
  {
    id: 4,
    image: "kitchen-4"
  },
  {
    id: 5,
    image: "kitchen-5"
  },
  {
    id: 6,
    image: "kitchen-6"
  },
  {
    id: 7,
    image: "kitchen-7"
  },
  {
    id: 8,
    image: "kitchen-8"
  },
  {
    id: 9,
    image: "kitchen-9"
  },
]

export const subscriptionProblemItems = [
  {
    id: 1,
    name: "subscription-problem-1",
    title: "No Membership Fees"
  },
  {
    id: 2,
    name: "subscription-problem-2",
    title: "Only Fresh, Healthy Meals!"
  },
  {
    id: 3,
    name: "subscription-problem-3",
    title: "No Subscriptions"
  }
];

export const mealPlans = [
  {
    id: 1,
    plan_type: "A Simple weight-loss Plan",
    duration: 10,
    duration_type: "weeks",
    plan_img: "plan-1",
    img: "nutritionist-1",
    name: "Jane Cooper",
    title: ["Fitnness Coach", "Nutritionist"],
    category: ["Diet Plans", "Coaching"],
    followers: 326,
  },
  {
    id: 2,
    plan_type: "Gain Muscle Mass",
    duration: 5,
    duration_type: "weeks",
    plan_img: "plan-2",
    img: "nutritionist-2",
    name: "Cameron Williamson",
    title: ["Fitnness Coach", "Nutritionist"],
    category: ["Diet Plans", "Coaching"],
    followers: 326,
  },
  {
    id: 3,
    plan_type: "Shred",
    duration: 15,
    duration_type: "weeks",
    plan_img: "plan-3",
    img: "nutritionist-3",
    name: "Courtney Henry",
    title: ["Fitnness Coach", "Nutritionist"],
    category: ["Diet Plans", "Coaching"],
    followers: 326,
  },
  {
    id: 4,
    plan_type: "Courtney Henry",
    duration: 12,
    duration_type: "weeks",
    plan_img: "plan-4",
    img: "nutritionist-4",
    name: "Kathryn Murphy",
    title: ["Fitnness Coach", "Nutritionist"],
    category: ["Diet Plans", "Coaching"],
    followers: 326,
  },
];

export const LetsConnectData = [
  {
    id: 1,
    img: "orders",
    title: "Make an Order",
    desc: (
      <div>
        Make the switch to more balanced eating.<br />
        Click below to start your Nutritt Journey.
      </div>
    ),
    text: 'Get app'
  },
  {
    id: 2,
    img: "partners",
    title: "Become a Partner",
    desc: (
      <div>
        Get connected with our customers<br />
        looking for mindful eating.
      </div>
    ),
    text: "Join"
  },
  {
    id: 3,
    img: "teams",
    title: "Join our Team",
    desc: (
      <div>
        We’re always growing. Visit our career <br /> page
        to see where you may fit.
      </div>
    ),
    text: "Apply"
  }
];

export const footerLinks = {
  //Hide links as we do not have support
  // product: [
  //   { id: 1, title: "For  coaches", link: "" },
  //   { id: 2, title: "For meal providers", link: "" }
  // ],
  support: [
    // { id: 1, title: "Help Center", link: "" },
    { id: 2, title: "Contact Support", link: "/nutritt/support" }
  ],
  about: [
    // { id: 1, title: "Press", link: "" },
    { id: 2, target:true, title: "Career", link: "https://nutritt.typeform.com/careers" }
  ]
};