import { DeliveryInfoWrapper } from './style';

export default function DeliveryInfo({
  customerName,
  phoneNumber,
  method,
  displaySelectedDate,
  selectedTime,
  deliveryData,
  orderNote,
  street,
  city,
  state,
  zip,
  orderType
}) {
  return (
    <DeliveryInfoWrapper>
      <div className="section-title">Delivery information</div>
      <div className="delivery-info-container">
        <div className="delivery-group">
          <div className="delivery-item">
            <div className="label">Customer name</div>
            <div className="info">{customerName}</div>
          </div>
          <div className="delivery-item">
            <div className="label">Phone number</div>
            <div className="info">{phoneNumber}</div>
          </div>
        </div>
        <div className="delivery-group">
          <div className="delivery-item">
            <div className="label">method</div>
            <div className="info">{method}</div>
          </div>
          <div className="delivery-item">
            <div className="label">{orderType === 'completed' ? 'Delivered at' : 'Scheduled for'}</div>
            <div className="info">
              {displaySelectedDate} <span>{selectedTime?.split('-').join(' - ')}</span>
            </div>
          </div>
        </div>
        <div className="delivery-item">
          <div className="delivery-item">
            <div className="label">{method} address</div>
            <div className="info address">
              <div>
                {method === 'Pickup' ? (
                  <>
                    {street && `${street}, `}
                    {city && `${city}, `}
                    {state && `${state}, `}
                    {zip && zip}
                  </>
                ) : (
                  <>{deliveryData?.deliveryAddress}</>
                )}
              </div>
            </div>
          </div>
        </div>
        {orderNote && (
          <div className="delivery-item">
            <div className="delivery-item">
              <div className="label">order notes</div>
              <div className="info note">{orderNote}</div>
            </div>
          </div>
        )}
      </div>
    </DeliveryInfoWrapper>
  );
}
