import styled from 'styled-components';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@material-ui/core/StepIcon';

const NutrittStepsStyle = styled.div`
  .step-header {
    height: 60px;

    h2 {
      // margin-bottom: 10px;
      color: #0b1d17;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      text-align: center;
    }

    .description {
      width: 335px;
      margin: 0 auto;
      color: #949d9b;
      font-size: 14px;
      line-height: 0.71px;
      text-align: center;
      justify-content: center;
    }
  }

  .MuiStepper-root {
    margin-bottom: 15px;

    .MuiStep-root:first-child:before,
    .MuiStep-root:last-child:after {
      content: '';
      position: absolute;
      top: 10px;
    }

    .MuiStep-root:first-child:before {
      left: 0;
      right: 50%;
      border-top: 4px solid #00092D;
      border-radius: 2px 0 0 2px;
    }

    .MuiStep-root:last-child:after {
      right: 0;
      left: 50%;
      border-top: 4px solid #F0F2F4;
      border-radius: 0 2px 2px 0;
    }
    .MuiStep-root.activeStep3:last-child:after {
      border-top: 4px solid #00092D;
    }

    .MuiStepConnector-root {
      .MuiStepConnector-line {
        border-color: #00092D;
      }

      &.Mui-disabled .MuiStepConnector-line {
        border-color: #F0F2F4;
      }
    }

    .MuiStepLabel-root {
      .MuiSvgIcon-root {
        color: #00092D;

        .MuiStepIcon-text {
          fill: #fff;
        }
      }

      &.Mui-disabled .MuiSvgIcon-root {
        color: #F0F2F4;

        .MuiStepIcon-text {
          fill: #757E89;
        }
      }
    }

    .MuiSvgIcon-root {
      z-index: 3;
      width: 22px;
      height: 22px;

      &.Mui-completed {
        background-color: #fff;
        color: #00092D;
        border-radius: 50%;
      }

      .MuiStepIcon-text {
        font-size: 12px;
        font-family: 'Roboto';
        line-height: 14px;
      }
    }

    .MuiStepConnector-root {
      width: 100%;
      left: -50%;
      right: 50%;
      top: 10px;

      .MuiStepConnector-line {
        border-top-width: 4px;
      }
    }

    .MuiStepLabel-label {
      font-family: 'Gilroy';
      margin-top: 6px !important;
      color: #757E89;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.7px;
    }

    .MuiStepLabel-label.Mui-active {
      font-weight: 600;
      color:#757E89;
    }

    .MuiStep-root {
      padding: 0 !important;
    }

    .MuiStepLabel-label.Mui-disabled {
      color: #757E89;
      font-weight: 500;
    }
  }

  .step-label {
    font-size: 12px;
  }

  .step-footer {
    .step-number {
      margin-bottom: 5px;
      color: #00092D;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      text-transform: uppercase;
    }

    .previous-title {
      margin-bottom: 20px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        position: absolute;
        left: 0;
        top: auto;
        cursor: pointer;
      }

      .title {
        font-size: 18px;
        font-family: 'Roboto';
        line-height: 24px;
        text-align: center;
        color: #949d9b;
      }
    }
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column-reverse;

    .step-header {
      margin-bottom: 35px;

      h2 {
        font-size: 24px;
        line-height: 28px;
      }

      .description {
        font-size: 18px;
        line-height: 5px;
      }
    }

    .MuiStepper-root {
      width: 335px;
      margin: 0 auto 32px;
    }
  }

  @media(max-width:370px){
    .step-header{
      .description{
        font-size:12px;
        line-height:14px;
        font-weight: 400;
      }
    }
  }
`;

export default function NutrittSteps({
  title,
  description,
  activeStep,
  steps,
  stepNumber,
  stepTitle,
  perviousLink,
  isFooterHidden,
  activeClass,
  isCanceled,
}) {
 
  return (
    <NutrittStepsStyle>
      <div className="step-header">
        <h2>{title}</h2>
        <div className="description">{description}</div>
      </div>
      {steps && (
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step className={`activeStep${activeClass}`} key={label}>
              <StepLabel
                icon={(index < activeClass) && !isCanceled ? null : <img src='/icons/close.svg' className='cross-img' />}
                className="step-label"
                StepIconComponent={(props) => (
                  <StepIcon
                    {...props}
                    icon={index + 1}
                    active={activeClass}
                    completed={false}
                  />
                )}  
              
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {!isFooterHidden && (
        <div className="step-footer">
          {stepNumber && <div className="step-number">step {stepNumber}</div>}
          <div className="previous-title">
            {perviousLink ? (
              <img src="/icons/arrow-left.svg" alt="Arrow Left" onClick={perviousLink} />
            ) : (
              ''
            )}
            <div className="title">{stepTitle}</div>
          </div>
        </div>
      )}
    </NutrittStepsStyle>
  );
}
