export const availabilityTimes = ['15 min', '30 min', '45 min', '60 min'];

export const formItems = {
  title: '',
  description: '',
  price: Number,
  availability: Number,
  schedule: [
    {
      Monday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
    {
      Tuesday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
    {
      Wednesday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
    {
      Thursday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
    {
      Friday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
    {
      Saturday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
    {
      Sunday: {
        slots: [
          {
            from: '09:00',
            to: '17:00',
          },
        ],
        available: false,
      },
    },
  ],
  excludeDates: [],
};

export const offeringList = [
  {
    title: 'Coaching Intensive Practicum',
    description:
      'Elevate your coaching skills and complete your educational requirements to apply to sit for the National Board for Health and Wellness Coaching (NBHWC) certifying exam in this immersive, small group course with 22 live learning sessions',
    price: 89.99,
  },
  {
    title: 'Gut Health Course',
    description:
      'Discover how to achieve optimal health, stronger immunity, and enhanced wellbeing by cultivating a healthy gut microbiome and adopting powerful practices for better digestion and a balanced lifestyle',
    price: 249.99,
  },
  {
    title: 'Hormone Health Course',
    description:
      'Explore how to boost physical, mental, and emotional health by learning how to promote hormonal balance through simple but powerful diet and lifestyle shifts',
    price: 499.99,
  },
];
