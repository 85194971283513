import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MultiScreenStyle from './MultiScreenStyle';
import MobileHeader from 'components/elements/MobileHeader';
import Level from 'components/svg/Level';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ChevronLeft } from 'assets/shop/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/shop/chevron-right-light.svg';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { motion } from 'framer-motion';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

const DesiredGoalPaceStyle = styled.div`
  .checkbox___card {
    margin: 6px 0;
  }
`;

const DesiredGoalPace = ({ activeModalStack, setActiveModalStack, isHealthGoalFlow, nextScreen, modal, totalPagesCount }) => {
  const { healthGoal, updatedHealthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const [activePreference, setActivePreference] = useState(null);
  const preferenceValuesToCheck = [-0.5, 0.5, -1, -2, 1, 2];
  const toastId = useRef(null);
  const isSaveDisabled = !activePreference?.some((value) =>
    preferenceValuesToCheck.includes(value),
  ) || healthGoal?.selfMealPlan?.goal === activePreference?.[0]

  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;

  const handleClose = () => {
    setActiveModalStack([])
    if(lastLoginUrl === '/shop/track-quiz'){
      history.push('/shop/track-quiz')
    }
  }

  function useCalculateTargetWeek(targetWeight, currentWeight) {
    const results = useMemo(() => {
      if (isNaN(targetWeight) || isNaN(currentWeight)) {
        return;
      }
      const intensities = [0.5, 1, 2];

      return intensities.reduce((acc, intensity) => {
        if (isNaN(intensity) || intensity <= 0) {
          throw new Error('Invalid intensity');
        }

        const weightDiff = Math.abs(currentWeight - targetWeight);
        const weeksToGoal = weightDiff / intensity;
        const today = new Date();
        const targetWeekEndDate = new Date();
        targetWeekEndDate.setDate(today.getDate() + weeksToGoal * 7);

        const formattedDate = targetWeekEndDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

        acc[intensity] = {
          weeksToGoal: weeksToGoal.toFixed(2),
          targetWeekEndDate: formattedDate,
        };
        return acc;
      }, {});
    }, [targetWeight, currentWeight]);

    return results;
  }

  const targetWeight = updatedHealthGoal?.selfMealPlan?.idealWeight;
  const currentWeight = updatedHealthGoal?.weight;
  const targetDate = useCalculateTargetWeek(targetWeight, currentWeight);

  const activityLevels = [
    {
      icon: <Level difficulty={'easy'} />,
      title: 'Easy',
      desc: `${
        updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-gain' ? 'Gain' : 'Lose'
      } 0.5 lb/week`,
      desc1: `Target ideal weight date ${targetDate && targetDate[0.5]?.targetWeekEndDate}`,
      goal: updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-loss' ? -0.5 : 0.5,
    },
    {
      icon: <Level difficulty={'medium'} />,
      title: 'Medium',
      desc: `${
        updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-gain' ? 'Gain' : 'Lose'
      } 1 lb/week`,
      desc1: `Target ideal weight date ${targetDate && targetDate[1]?.targetWeekEndDate}`,
      goal: updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-loss' ? -1 : 1,
    },
    {
      icon: <Level />,
      title: 'Extreme',
      desc: `${
        updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-gain' ? 'Gain' : 'Lose'
      } 2 lb/week`,
      desc1: `Target ideal weight date ${targetDate && targetDate[2]?.targetWeekEndDate}`,
      goal: updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-loss' ? -2 : 2,
    },
  ];
  const [animation, setAnimation] = useState({
    initial: { x: !isHealthGoalFlow ? '100%' : 0, opacity: 0 },
    exit: { opacity: 0, x: isHealthGoalFlow ? 0 : '100%', y: isHealthGoalFlow ? '100%' : 0 },
  });

  useEffect(() => {
    if (updatedHealthGoal?.selfMealPlan?.healthGoal === 'weight-loss') {
      setActivePreference([-Math.abs(healthGoal?.selfMealPlan?.goal)]);
    } else {
      setActivePreference([Math.abs(healthGoal?.selfMealPlan?.goal)]);
    }
  }, []);

  const handleBackClick = () => {
    setAnimation((prev) => ({
      ...prev,
      exit: {
        opacity: 0,
        x: 0,
        y: 0,
      },
    }));
    setTimeout(() => {
      setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
    }, 0);
  };

  useEffect(() => {
    const preference = activePreference?.[0];
    handleClick(preference);
  }, [activePreference])

  const handleClick = (value) => {
    setUpdatedHealthGoal((prev) => ({
      ...prev,
      selfMealPlan: {
        ...prev.selfMealPlan,
        goal: value,
      },
    }));
  };

  const handleMaintainWeightToast = () => {
    if (updatedHealthGoal?.selfMealPlan?.healthGoal === "maintain-weight") {
      if (toast.isActive(toastId.current)) {
        toast.dismiss(toastId.current);
      }
      toastId.current = toast.error(
        'Please change your Heath goal to other than Maintain a healthy weight.',
      );
      return true;
    }
    return false;
  };
  
  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack((prev) => [...prev.filter((item) => item?.modal !== modal?.modal)]);
  };

  return (
    <>
      <motion.div
        initial={animation?.initial}
        animate={{ opacity: 1, x: 0 }}
        exit={animation?.exit}
        transition={{ duration: isHealthGoalFlow ? 0.5 : 0.7, type: 'spring' }}
      >
        <DesiredGoalPaceStyle>
          <MultiScreenStyle>
            <MobileHeader
              text={'Desired goal pace'}
              onClickClose={isHealthGoalFlow ? handleClose : false}
              onClickLeft={
                !isHealthGoalFlow
                  ? () =>
                      setActiveModalStack((prev) => [
                        ...prev.filter((item) => item?.modal !== modal?.modal),
                      ])
                  : false
              }
              pageNumber={isHealthGoalFlow ? `${activeModalStack?.length}/${totalPagesCount}` : ''}
            />
            <div className="containerr">
              <p className="subtitle">
                Choosing a more intense goal can help <br /> you reach your target weight faster,
                but <br /> requires more effort
              </p>
              <div className="levels_holder">
                {activityLevels?.map((level, index) => (
                  <div className="checkbox___card" key={index}>
                    <CheckboxCard
                      icon={level?.icon}
                      title={level?.title}
                      subtitle={level?.desc}
                      secondarySubtitle={level?.desc1}
                      selectedValue={activePreference}
                      setSelectedValue={setActivePreference}
                      radio={true}
                      checked={activePreference?.includes(level?.goal)}
                      value={level?.goal}
                      disabled={updatedHealthGoal?.selfMealPlan?.healthGoal === "maintain-weight"}
                      handleMaintainWeightToast={handleMaintainWeightToast}
                      onClick={handleClick}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="button_holder">
              {isHealthGoalFlow ? (
                <Button
                  type={'button_combo'}
                  iconLeft={<ChevronLeft />}
                  iconRight={<ChevronRight />}
                  comboLeftTitle={'Back'}
                  comboLeftClick={handleBackClick}
                  comboRightClick={() => setActiveModalStack((prev) => [...prev, nextScreen])}
                  comboRightTitle={'Continue'}
                  comboRightDisabled={!activePreference?.[0]}
                />
              ) : (
                <Button
                  type={'mobile_save'}
                  title={'Save'}
                  isDisabled={isSaveDisabled}
                  onClick={handleSave}
                />
              )}
            </div>
          </MultiScreenStyle>
        </DesiredGoalPaceStyle>
      </motion.div>
    </>
  );
};

export default DesiredGoalPace;
