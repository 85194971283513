import { useState, useEffect, useRef, useCallback } from 'react';
import ErrorComponent from 'components/ErrorComponent';
import styled from 'styled-components';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import UpdateProviderButton from './UpdateProviderButton';
import Input from 'components/Input';
import UploadWidget from 'components/UploadWidget';
import Zone, { DeliveryCoverage } from 'components/onboarding/Zone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubmitButton from 'components/SubmitButton';
import {useHistory } from 'react-router-dom';
import Alert from 'components/Alert';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';

const StyledForm = styled.div`
  display: grid;
  grid-template-columns: 300px 300px auto;
  gap: 40px;
  @media (max-width: 1020px) {
    grid-template-columns: 300px 300px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 300px;
    gap: 20px;
    justify-content: center;
  }
`;

export default function BasicInfo({
  provider: contextProvider,
  setProvider: setContextProvider,
  path,
  updateProvider,
  isAlert,
  setIsAlert,
  link,
}) {
  const [provider, setProvider] = useState(contextProvider);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [showModifiedAlert, setShowModifiedAlert] = useState(false);
  const {user} = useAppContext();
  const [updatedProvider] = useRequest("provider/update", 'post');

  const body = {...provider, updatedProfile};

   const toastId = useRef(null);
  // if (!provider) return "Loading...";
  // const [
  //   getProvider,
  //   { isLoading, state: provider, setState: setProvider, error },
  // ] = useRequest("provider/provider");

  const [error, setError] = useState(false);

  const history = useHistory();

  useEffect(async() => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const p = await JSON.parse(sessionStorage.getItem('imitateProvider'));
    console.log('p...................',p)
    if (p && p.id != provider.id) {
      /*Update Provider DATA*/
      setContextProvider(p);
      setProvider(p);
    }
  }, [contextProvider]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setIsModified(true);
    setProvider((prevProvider) => ({ ...prevProvider, [id]: value }));
    setUpdatedProfile((prevProvider) => ({ ...prevProvider, [id]: value }));
  };
  const handleInputChange = useCallback((e) => {
    const { id, value } = e.target;
    const isNumber = /^\d*\.?\d{0,2}$/.test(value);
    if ((isNumber || value === '') && value.length < 11) {
      const roundNumber = Math.trunc(value);
      const data = roundNumber >= 0 ? value : '';
      setProvider((prevProvider) => ({ ...prevProvider, [id]: data }));
      setUpdatedProfile((prevProvider) => ({ ...prevProvider, [id]: value }));
    } else {
      e.preventDefault();
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(`Only Numaric values allowed for this field`);
      }
    }
  }, []);

  const setNewImgUrl = (url) => {
    setProvider((prevProvider) => ({ ...prevProvider, images: [url] }));
  };

  // if (isLoading || !provider) return <Progress height="400px" />;
  if (error) return <ErrorComponent error={error} />;

  useEffect(() => {
    updateProvider && updateProvider(provider);
  }, [provider]);

  const {
    name,
    address,
    city,
    zip,
    description,
    type,
    maxDistance,
    deliveryCoverage = [],
    minOrderAmount,
    leadTime,
    images,
    bankAccountNumber,
    bankRoutingNumber,
    phoneNumber,
    deliveryFee,
  } = provider;

const handleUpdate = async (body) => {
    const {bankAccountNumber, zip} = body
    if(zip && bankAccountNumber) {
      setContextProvider && setContextProvider(body);
      await updatedProvider({ body });
      toast.success('Changes saved successfully');
        setTimeout(() => {
         history.push('/provider/deliverable-areas');
       }, 5000);
         
    } else(error)=>{
      console.log(error);
    }

  };
  
   function saveChanges(){
    if(isModified){
      setShowModifiedAlert(true);
    } else {
      history.push('/provider/deliverable-areas');
      window.location.reload();
    }
  }
  function discardChanges(){
         history.push('/provider/deliverable-areas');
    
  }

  const ProviderPath = `provider/update?providerEmail=${user?.email}`;
  return (
    <div style={{ padding: "13px 30px", backgroundColor: "#ffffff" }}>
      <h1 style={{ color: 'gray', textAlign: 'center' }}>Provider info</h1>
      <StyledForm>
        <div>
          <Input label="Name" name="name" value={name} onChange={handleChange} bordered />
          <Input
            label="Description"
            name="description"
            value={description}
            onChange={handleChange}
            type="textarea"
            bordered
            rows="4"
          />
          <Input label="Address" name="address" value={address} onChange={handleChange} bordered required/>
          <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 20 }}>
            <Input label="City" name="city" value={city} onChange={handleChange} bordered />
            <Input
              style={{ paddingLeft: '10px' }}
              label="Zip"
              name="zip"
              value={zip}
              onChange={handleChange}
              bordered
              required="required"
            />
          </div>
          <Input
            label="Bank Routing Number"
            name="bankRoutingNumber"
            value={bankRoutingNumber}
            onChange={handleChange}
            bordered
          />
          <Input
            label="Bank Account Number"
            name="bankAccountNumber"
            value={bankAccountNumber}
            onChange={handleChange}
            bordered
            required
            type="number"
          />
          <Input
            label="Phone Number"
            name="phoneNumber"
            type="number"
            value={phoneNumber}
            onChange={handleChange}
            bordered
          />
        </div>
        <div>
          {/* <Input
            label="Type"
            name="type"
            value={type}
            onChange={handleChange}
            options={[{ label: "", value: "1" }]}
            type="select"
          /> */}

          <Input
            label="Minimum order amount"
            name="minOrderAmount"
            type="text"
            value={minOrderAmount}
            onChange={handleInputChange}
            Icon={AttachMoneyIcon}
            bordered
          />
          {/* <Input
            label="Lead time"
            name="leadTime"
            value={leadTime}
            onChange={handleChange}
            Icon={ScheduleIcon}
            type="number"
            bordered
          /> */}
          {/*<Input*/}
          {/*  label="Max delivery distance (mi)"*/}
          {/*  name="maxDistance"*/}
          {/*  value={maxDistance}*/}
          {/*  onChange={handleChange}*/}
          {/*  Icon={MyLocationIcon}*/}
          {/*  type="number"*/}
          {/*  bordered*/}
          {/*/>*/}
          <Input
            label="Delivery Fees"
            name="deliveryFee"
            value={deliveryFee}
            onChange={handleInputChange}
            Icon={AttachMoneyIcon}
            bordered
          />
          <div>
            <img
              width="300"
              src={images?.[0] || '/images/avatar.png'}
              style={{ borderRadius: '5px', marginTop: '40px' }}
            />

            <UploadWidget setNewImgUrl={setNewImgUrl} img={images?.[0] || ''} />
          </div>
        </div>
        <div style={{ maxWidth: '500px' }}>
          {/*<Zone
            providerAddress={`${address}, ${city}`}
            setZipcodes={(zips = []) =>
              setProvider((prevProvider) => ({
                ...prevProvider,
                deliveryCoverage: zips?.map((item)=>item.value),
              }))
            }
            zipcodes={deliveryCoverage}
            radius={maxDistance}
          />*/}

          <DeliveryCoverage zipcodes={deliveryCoverage} handleChange={handleChange} />

           {
             showModifiedAlert && (
                <Alert
          title="Unsaved Changes"
          desc="Do you want to save the changes?"
          cancel={() => {
           setShowModifiedAlert(false)
          }}
          
          cancelText="Cancel"
          discard={()=>{discardChanges()}}
          discardText="Discard"
          ok={()=>{handleUpdate(provider)}
           }
         
          okText="Save changes"
        />
             )
           }
          <SubmitButton
            disabled={false}
            // type="submit"
            onClick={saveChanges}
          error={null}
          >
            Select Deliverable Areas
          </SubmitButton>
        </div>
        <UpdateProviderButton
          body={body}
          updatedProfile={updatedProfile}
          path={ProviderPath}
          setContextProvider={setContextProvider}
          setProvider={setProvider}
          contextProvider={contextProvider}
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          link={link}
          setUpdatedProfile={setUpdatedProfile}
          profileUpdating={true}
          method={"post"}
        />
      </StyledForm>
    </div>
  );
}


