import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import NutritionistCoachingInvite from './NutritionistCoachingInvite';
import 'rc-tooltip/assets/bootstrap.css';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from '../../context/nutritionistContext';
import FollowersData from './Followers/FollowersData';


const NutritionistFollowersStyle = styled.div`
@media screen and (max-width: 767px){
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%; 
  margin-bottom: 24px;
}
.upcoming {
  .upcoming-head{
    @media screen and (max-width: 767px){
      height: 60px; 
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #d9d9d9;
    }
    p{
      @media screen and (max-width: 767px){
        font-size:16px;
        font-weight: 700;
      }
    }
    .MuiTabs-flexContainer {
      justify-content: center;
      .MuiTab-root {
        padding: 0px !important;
        width: 147.5px;
      }
    }
  }
  .session {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #52c41a;
    margin: 20px 0px 0px 20px;
    cursor:pointer;
    @media screen and (max-width: 767px){
      font-size: 16px;
      margin: 13px 20px;
      u{
        text-decoration:none;
      }
    }
  }
  .empty-state {
    .profile-image {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .no-clients {
      margin-top: 23px;
      display: flex;
      justify-content: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #51635d;
    }
    .invite-client {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #51635d;
    }
    .nutritionist-email {
      margin-top: 24px;
      display: block;
      position: relative;
      input {
        color:#51635D;
        border: 1px solid #E0E3E0;
        border-radius: 6px;
        width:307px;
        height:44px;
        padding-left:14px;
        background-position: 275px; 
        &.copied {
               background-color: #52c41a33;
             }
      }
      .copy-icon{
        position: relative;
        right: 13px;
        top: 13px;
      }
      .copied-text{
        position: absolute;
        bottom: 42%;
      
      }
    }
  }

  .empty-state { 
    padding: 0px 20px;
    min-height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px){
      min-height: auto;
      max-height: initial;
      padding: 25px 20px;
    }
    .no-clients {
      margin-top: 25px !important;
      font-size: 18px !important;
      line-height: 24px !important;
    }
    .invite-client {
      margin-top: 12px !important;
      font-size: 16px !important;
      line-height: 19px !important;
      margin-left: 46px;
      margin-right: 46px;
      .session {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #52c41a;
        margin: 20px 0px 0px 20px;
        cursor:pointer;
        @media screen and (max-width: 767px){
          font-size: 16px;
          padding: 30px 20px 30px 24px;
          u{
            text-decoration:none;
          }
        }
      }
    }
    .nutritionist-email {
      margin-top: 24px !important;
      width: 100%;
    }
    .copy-url {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      button {
        height: 42px;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #97dc76 !important;
        border-radius: 21px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #51635d !important;
        cursor:pointer;
        &:hover {
          background: #cbedba;
          color: #51635d;
          transition: all 0.3s ease-in-out;
        }
      }
      .copy-active {
        background: #52c41a33!important;
        border: 1px solid #97dc76!important;
        border-radius: 21px;
        font-size: 14px;
        line-height: 16px;
    }
  }
`;


export default function NutritionistFavoriteFollowers({ followersList }) {
  const [isCopied, setIsCopied] = useState(false);
  const { isMobile, user } = useAppContext();
  const history = useHistory();
  const { nutritionist } = useNutritionistContext();
  const URL_ROOT = `${window.location.origin}/plan/`;
  const [nutritionistMeetingList, setNutritionistMeetingList] = useState(false);
  nutritionistMeetingList && window.scroll(0, 0);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [isCopied]);

  let allFavoriteUser = [];
  followersList?.map((user) => {
    if(user?.isFavorite == true){
      allFavoriteUser.push(user)
    }
  });
  const favoriteUser = allFavoriteUser?.slice(0, 6); 

  return (
    <NutritionistFollowersStyle>
      <div className={!favoriteUser?.length == 0 ? "upcoming pb-0" : "upcoming"}>
        <div className="upcoming-head"> 
          <p className="">Favorites</p>
          {!isMobile && <p className='follower-number' style={{background:'#F3972F'}}>{favoriteUser && favoriteUser?.length}</p>}
        </div>
        {!favoriteUser?.length == 0 ? (
          <div>
            <div className="upcoming-list">
              {favoriteUser?.map((user, index) => (
                <FollowersData key={index} user={user} />
              ))}
            </div>
            <div className="session">
              <span>
                {isMobile ?
                  <u onClick={() => {history.push('/nutritionist/followers'), window.scrollTo(0,0)}}>{followersList.length > 5 ? 'See all Followers' : ''}</u>
                  :
                  <u onClick={() => history.push('/nutritionist/followers')}>See all Followers</u>
                }
              </span>
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="profile-image">
              <img src="/icons/add-to-favorites 2.svg" alt="profile image" />
            </div>
            <div className="no-clients">
              <p>No Favorites</p>
            </div>
            <div className="invite-client">
              {isMobile ? (
                <p>
                  Keep track of your followers by clicking the <br /> "favorite" star by their name.
                </p>
              ) : (
                <p>Keep track of your followers by clicking the "favorite" star by their name.</p>
              )}
            </div>
            <div className='session' onClick={() => history.push('/nutritionist/followers')}><u>See all Followers</u></div>
          </div>
        )}
      </div>
      {isModal && <NutritionistCoachingInvite setIsModal={setIsModal} isModal={isModal} />}
    </NutritionistFollowersStyle>
  );
}

