import React, { useEffect } from 'react'
import { useRequest } from 'hooks/useRequest'
import { useParams } from 'react-router-dom';
import { useAppContext } from 'context/appContext';

export const Kitchen = () => {
    const [countStoreVisits] = useRequest();
    const { providerId: providerId } = useParams();
    const { user } = useAppContext() ?? {};

    async function countStoreVisit() {
        await countStoreVisits({
            path: `provider/kitchen/${providerId}`,
            method: 'post',
            body: {
                "userId": user?.provider?.userId
            }
        })
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log('error', error);
            })
    }

    useEffect(() => {
        countStoreVisit();
    }, [])

    return (
        <h2 style={{ textAlign: 'center', paddingTop: (window.innerHeight / 2) - 20 }}>This is a provider store url</h2>
    )
}
