import PantryFooter from 'components/Pantry/PantryFooter';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import {toast} from "react-toastify";
import Alert from 'components/Alert';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import Progress from 'components/Progress';

const ChangeDietPlanStyle = styled.div`
display: flex;
align-items: center;
justify-content: center;
.spinner{
  position: absolute;
  top: 50%;
  left: 49%;
}
  .maindiv {
    width: 100%;
    height: 100vh;
    background: #f6f5f8;
    display: flex;
    justify-content: center;
  }
  .changebox {
    width: 90%;
    height: fit-content;
    padding: 43px 0;
    display:flex;
    flex-direction:column;
    align-items:center;
    margin: 48px auto 0;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 15px 0px #0000001a;
    align-items: center;
    flex-direction: column;

    .congrats{
        width: 269.08px;
        height: 28px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #52C41A;
    }

    .complete{
      margin-top:5px;
      height: 16px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
    color: #51635D;
   }
    .circle{
     width:128px;
     height:128px;
     margin: 29px 0 28px;
     border-radius:50%;
     display:flex;
     justify-content:center;
     background: #CBEDBA;

     .calendar-icon{
      width: 64px;
      height: 128px
     }
 }

  .question {
    color: #51635D;
    font-size: 14px;
    line-height: 16px;
  }

.repeat-div{
    p{
        padding:11px 0px 11px 18px ;
        border: 1px solid #F6F5F8;
        width:335px;
        font-size:14px;
        font-weight:400;
    }
}

.next-step{

width: 269.08px;
height: 16px;
margin-top:12px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
text-align: center;
color: #51635D;

}
.button-wrapper{
.repeat-btn{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 27px;
width: 206px;
height: 40px;
background: #52C41A;
border-radius: 21px;

p{
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 16px;
display: flex;
align-items: center;
color: #FFFFFF;
}
}

    .choose-btn {
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      width: 206px;
      height: 40px;
      margin: auto;
      margin-top: 7px;
      background: #ffffff;
      border: 1px solid #97dc76;
      border-radius: 21px;
      display: flex;

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        color: #51635d;
        justify-content: center;
        top : 12;
        // margin: auto;
      }
    }
  }
  }
  }

  @media(min-width: 992px){
  
    .changebox {
      height: 425px;
      width: 511px;
      margin-top: 45px;
      
      .button-wrapper{
        display: flex;
        margin-top: 27px !important;
        padding: 0px 49px;
        gap: 14px;

        .repeat-btn {
         margin-top: 0 !important;
         cursor: pointer;
        } 
        
        .choose-btn{
          margin-top: 0 !important;
          cursor: pointer;
          p{
            padding: 10px 30px;
          }
        }
      }
    } 
  }
`;

export default function ChangeDietPlan() {
    const { user } = useAppContext();
    const dietTemplateId = user.profile.dietTemplate.id;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [updateNewDietPlan,{isLoading}] = useRequest('nutritionist/updateSubscribedDietPlan', 'put');
  const { getIsUserPlanExpiredFn } = useShopContext();

  const handleClick = (id) => {
    console.log('repeat this plan', id);
    const dietPlanPayload = {"updatedDietTemplateId": id}
    updateNewDietPlan({
        body: dietPlanPayload
    }).then((response) => {toast.success("Diet Plan updated successfully")
    getIsUserPlanExpiredFn();
        history.push('/shop/plan')
    })
    .catch((error) => {toast.error("Something went wrong");
    setOpen(false);
    })
  };


  return (
    <>
      <ChangeDietPlanStyle>
        {isLoading && (
          <div className="spinner">
            <Progress />
          </div>
        )}
        <div className="maindiv">
          <div className="changebox">
            <p className="congrats">Congratulations</p>

            <p className="complete">You've completed the meal plan.</p>

            <div className="circle">
              <img className="calendar-icon" src="/icons/calendar-icon.svg" alt="Calendar Icon" />
            </div>
            <div className="question">Okay, what about next step?</div>
            <div className="button-wrapper">
              {isLoading ? (
                <div className="repeat-btn">
                  <p>Repeat this plan</p>
                </div>
              ) : (
                <div className="repeat-btn" onClick={() => handleClick(dietTemplateId)}>
                  <p>Repeat this plan</p>
                </div>
              )}
              <div className="choose-btn" onClick={() => {!isLoading && history.push('/shop/my-coach/mealplans')}}>
                <p>Choose another plan</p>
              </div>
            </div>
            {/* {open && (
              <div className="getStarted" onClick={() => setOpen(!open)}>
                <Alert
                  title="Repeat plan confirmation"
                  desc="Are you sure you want to repeat this plan?"
                  cancel={() => {
                    setOpen(false);
                  }}
                  cancelText="Cancel"
                  okText="Continue"
                  ok={(e) => handleClick(e, dietTemplateId)}
                  discardText="Discard"
                />
              </div>
            )} */}
          </div>
        </div>
      </ChangeDietPlanStyle>

      <PantryFooter />
    </>
  );
}

