import React, { useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GilroyFontWrapper } from 'shared/theme/style';
import { ProviderCardItem } from 'components/providerCardItem/ProviderCardItem';
import { Button } from 'components/elements/button/Button';
import { useShopContext } from 'context/shopContext';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import MobileHeader from 'components/elements/MobileHeader';
import { AnimatePresence, motion } from 'framer-motion';
import EmptyCart from './EmptyCart';
import { useAppContext } from 'context/appContext';

const CartScreenStyle = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  .qty_box {
    width: 88px;
    height: 32px;
    border-radius: 20px;
    border: 1px solid #757e89;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
  }
  .qty {
    flex: 1 1 0%;
    text-align: center;
  }
  .empty-cart-title {
    color: #0B1D17;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.6px;
    margin: 2px 0px -15px 0px;
  }
  .empty_div {
    display: flex;
    flex-direction: column;
    margin: 48px 53px 0px 53px;
    align-items: center;
    height: 100vh;
  }
`;
const ButtonStyle = styled.div`
  span {
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .button_style {
    background-color: #fff;
    padding-bottom: 25px;
    margin-top: 49px;
    justify-content: center;
    display: flex;
  }
  .checkout_button {
    background-color: #fff;
    padding-top: 15px;
    bottom: 0;
    flex-direction: column;
    left: 0;
    position: absolute;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .custom_button {
    display: flex;
    width: 250px;
    height: 42px;
    padding: 14px 16px 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background-color: #00092d;
  }
  .order_button {
    display: flex;
    width: 230px;
    height: 42px;
    padding: 18px 16px 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
  }
  .light_button {
    display: flex;
    width: 250px;
    height: 42px;
    padding: 14px 16px 12px 16px;
    justify-content: center;
    background-color: #f0f2f4;
    align-items: center;
    gap: 10px;
    margin-top: -13px;
    border-radius: 30px;
  }
  .bottom_text {
    color: #0b1d17;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .red_text {
    color: #eb3223;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 2px;
  }
`;
const HeaderStyle = styled.div`
  background-color: #ffffff;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
`;
export const CartScreen = () => {
  const { cart, settings, updateSettings } = useShopContext();
  const { provider: prevSelectedProvider } = settings;
  const [buttonGotPressed, setButtonGotPressed] = useState(false);
  const history = useHistory();
  const providersArray = settings.providerSettings.map((i) => i);
  const mealsCart = cart;
  const [screenHeight, setScreenHeight] = useState();
  window.scrollTo(0,0);

  const handleCartMeal = () => {
    let mealForCheckout = [];
    cart.map((meal) => {
      const providersInCart = providersArray.filter((i) => i.providerId === meal.providerId);
      const mealGroup = mealsCart.filter((i) => {
        delete i.customQty;
        return providersInCart?.[0] && i?.providerId === providersInCart?.[0].providerId;
      });
      mealForCheckout.push(mealGroup);
    });
    let uniq = _.uniqWith(mealForCheckout, _.isEqual);
    return uniq;
  };
  const uniqMealData = handleCartMeal();

  const countProvidersWithMinOrders = useMemo(() => {
    const updatedMeals = new Map();

    uniqMealData?.forEach((meals) => {
      meals?.forEach((meal) => {
        const matchedProvider = providersArray.find((provider) => provider.id === meal.providerId);
        if (matchedProvider) {
          const providerId = meal.providerId;
          if (!updatedMeals.has(providerId)) {
            updatedMeals.set(providerId, {
              mealsArray: [],
              minOrderAmount: matchedProvider?.minOrderAmount
            });
          }
          updatedMeals.get(providerId).mealsArray.push(meal);
        }
      });
    });

    const filteredMealsArray = Array.from(updatedMeals.values()).filter(
      ({ mealsArray, minOrderAmount }) => {
        const totalPrice = mealsArray.reduce((acc, meal) => acc + meal.price * meal.qty, 0);
        return totalPrice < minOrderAmount;
      },
    );

    return filteredMealsArray.length;
  }, [uniqMealData, providersArray]);

  useEffect(() => {
    setTimeout(() => {
      setScreenHeight(window.innerHeight);
    }, 300)
  }, [])

  const handleUpdateProvider = async (machedProvider) => {
    const providerSelected = machedProvider[0];
    const foundProvider = providersArray?.find((x) => {
      if((providerSelected?.businessProfileId && x?.businessProfileId === providerSelected?.businessProfileId) || (providerSelected?.BusinessProfile && x?.businessProfileId === providerSelected?.BusinessProfile[0]?.idproviderSelected?.BusinessProfile && x?.businessProfileId === providerSelected?.BusinessProfile[0]?.id)){
        return x
      }
    })
    const kitchenUsername = foundProvider.username; 
    history.push(`/store/${kitchenUsername}`, { fromCart: true });
    await updateSettings(produce(draft => { draft.provider = foundProvider }));
  };

  return (
    <motion.div
      initial= {{ opacity: history.location?.state?.fromCheckout ? 1 : 0, y: history.location?.state?.fromCheckout ? '0' : '100%' }}
      exit= {{ opacity: buttonGotPressed ? 1 : 0.6, y: buttonGotPressed ? 0 : '100%' }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: buttonGotPressed ? 0 : 0.4, type: 'ease-in-out' }}
      style={{ height: "100vh", width: '100%', position: 'fixed', inset: 0, zIndex: 113, backgroundColor: '#F5F7F8' }}
    >
    <GilroyFontWrapper>
      <HeaderStyle>
        <MobileHeader text={'Your cart'} onClickClose={() => {
          setButtonGotPressed(false);
          ((history.location?.state?.fromCheckout || history.location?.state?.fromMealListing)) ? prevSelectedProvider?.username ? history.push(`/store/${prevSelectedProvider?.username}`, { fromCart: true }) : history.push('/shop/kitchen-home', { fromCart: true }) : history.push('/shop/kitchen-home', { fromCart: true });
        }} />
      </HeaderStyle>
      <div
        style={{
          paddingTop: '48px',
          paddingBottom: '89px',
          height: screenHeight,
          position: 'relative'
        }}
      >
        <AnimatePresence>
        {uniqMealData.length > 0 && (<>
          <motion.div
            initial= {{ opacity: 0 }}
            exit= {{ opacity: 0, x: 0, y: '100%', transition : { duration: 0.4 } }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: buttonGotPressed ? 0 : 0.4, type: 'ease-in-out' }}
            style={{ position: 'absolute', height: screenHeight - 138, overflow: 'auto', width: '100%', backgroundColor: '#F5F7F8' }}
          >
            <div>
            {uniqMealData.map((mealData, i) => {
              const machedProvider = providersArray.filter(
                (i) => mealData?.[0] && i.providerId === mealData?.[0].providerId,
              );
              const shopMeals = async () => {
                await handleUpdateProvider(machedProvider);
                window.scrollTo(0, 0);
              };

              return mealData[0]?.id && machedProvider?.[0]?.name ? (
                <CartScreenStyle>
                  <ProviderCardItem
                    logo={machedProvider?.[0]?.image || `/images/dish.png`}
                    name={machedProvider?.[0]?.name}
                    orderData={
                      machedProvider?.[0]?.minOrderAmount
                        ? `Min order: $${machedProvider?.[0]?.minOrderAmount}`
                        : 'No minimum order'
                    }
                    uniqMealData={mealData}
                    shopMeals={shopMeals}
                    minOrderAmount={machedProvider?.[0]?.minOrderAmount}
                    buttonGotPressed={buttonGotPressed}
                  />
                </CartScreenStyle>
              ) : (
                ''
              );
            })}
            </div>
          </motion.div>
        {screenHeight && 
        <ButtonStyle>
          <div className="checkout_button">
            <Button
              className="custom_button"
              title="Check out"
              type="dark"
              isDisabled={buttonGotPressed && countProvidersWithMinOrders > 0 ? true : false}
              onClick={() => {
                setButtonGotPressed(true);
                countProvidersWithMinOrders === 0 && history.push(`/shop/checkout/order`);
                window.scrollTo(0, 0);
              }}
            />
            {
              <text className="bottom_text">
                {buttonGotPressed &&
                  countProvidersWithMinOrders > 0 &&
                  'Minimum order not met for'}{' '}
                <text className="red_text">
                  {buttonGotPressed &&
                    countProvidersWithMinOrders > 0 &&
                    `${countProvidersWithMinOrders} ${
                      countProvidersWithMinOrders === 1 ? 'order' : 'orders'
                    }`}
                </text>
              </text>
            }
          </div>
        </ButtonStyle>
        }
        </>)}
        </AnimatePresence>
        {uniqMealData.length <= 0 &&
          <EmptyCart showButton={true} onButtonClick={() => history.push('/shop/kitchen-home')} />
        }
      </div>
    </GilroyFontWrapper>
    </motion.div>
  );
};
