import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const OrderAlertWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 343px;
  height: 38px;
  padding: 0 14px;
  background-color: ${theme.colors.primaryDarker};
  color: ${theme.colors.secondary};
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;

  .undo {
    font-weight: 400;
    text-decoration-line: underline;
  }

  .rc-progress-line {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
