// /nutritionist/offering/reports
import React, { useEffect, useState } from 'react';
import NutritionistReportsPayTable from './NutritionistReportsPayTable';
import { useRequest } from 'hooks/useRequest';
import Progress from 'components/Progress';
import ErrorComponent from 'components/ErrorComponent';
import { useNutritionistContext } from '../../context/nutritionistContext';

export default function NutritionistOfferingReport() {
  const { nutritionist: nutritionistData, isClient, setIsClient, coachingPay: pay, coachingError: error } = useNutritionistContext();
  const [disbursements, setdisbursements] = useState();
  const [getDisbursementsHistory, { isLoading: isLoaded, error: err }] = useRequest(
    `financials/coaching-disbursement-history/${nutritionistData?.nutritionist?.id}`,
  );
  useEffect(async () => {
    if (nutritionistData) {

      await getDisbursementsHistory().then((res) => {
        setdisbursements(res);
      });
      setIsClient(false);
    }
  }, [nutritionistData]);
  if (isLoaded) return <Progress />;
  if (error) return <ErrorComponent error={error} />;
  return !disbursements ? (
    <Progress />
  ) : (
    <NutritionistReportsPayTable disbursements={disbursements} pay={pay}  isClient={isClient}/>
  );
}
