import { useShopContext } from "context/shopContext";
import { useState, useEffect } from "react";
import useCart from "./useCart";
import {geocodeByPlaceId,getLatLng} from 'react-google-places-autocomplete';
import { useAppContext } from "context/appContext";
import produce from 'immer';

export default function SetClientCart(){
    const {addToCart, getMealDay, updateMealQty} = useCart();
    const {cart, updateCart, cartCount, setProviderListCalled, settings, updateSettings, availableProviders,loadCart} = useShopContext();
    const {user,userDeliveryAddress,setCustomQtyMeal} = useAppContext();
    //const loadCart = user?.loadCart;
    const [days, setDays] = useState(7);
    const [meals, setMeals] = useState(3);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('New York City, Brooklyn');
    const [state, setState] = useState('New York');
    const [zip, setZip] = useState('');
    const [value, setValue] = useState(null);
    const [userAddressLatLong, setUserAddressLatLong] = useState([]);
    
    const handleSelect = async (value) => {
        const [place] = await geocodeByPlaceId(value.value.place_id);
        const userLatLong = await getLatLng(place);
        setUserAddressLatLong(userLatLong);
        const { long_name: postalCode = '' } =
          place.address_components.find((c) => c.types.includes('postal_code')) || {};
        const { long_name: state = '' } =
          place.address_components.find((c) => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: city = '' } =
          place.address_components.find((c) => c.types.includes('locality')) ||
          place.address_components.find((c) => c.types.includes('sublocality_level_1')) ||
          {};
        setCity(city);
        setState(state);
        setZip(postalCode);
        setAddress(value?.value?.structured_formatting?.main_text);
        
    };
    useEffect(()=>{
      if(settings?.userInfo?.zipcode==="" && settings?.userInfo?.address===""){
        updateSettings(
            produce((draft) => {
              draft.days = days;
              draft.meals = meals;
              draft.userInfo.userAddressLatLong = userAddressLatLong;
              draft.userInfo.address = address;
              draft.userInfo.address2 = address2;
              draft.userInfo.zipcode = zip;
              draft.userInfo.city = city;
              draft.userInfo.state = state;
            }),
          );
      }

    },[days,meals,userAddressLatLong,address,address2,zip,city,state])

    useEffect(async() => {
        value && handleSelect(value);
    }, [value]);

    useEffect(async() => {
        const { address, address2, zipcode, city, state, userAddressLatLong } = settings.userInfo;
        setDays(settings.days);
        setMeals(settings.meals);
        await setAddress(address);
        setUserAddressLatLong(userAddressLatLong);
        setAddress2(address2);
        setZip(zipcode);
        setCity(city);
        setState(state);
        if(userDeliveryAddress?.length){
          setValue(userDeliveryAddress[0].address.address)
        }
    }, []);

    const loadClientCart = async() => {
      const mealData = [...loadCart?.mealData];
      for (const meal of mealData) {
        if(cart.find((item) => item.id === meal.id)){
            setTimeout(() => {
              updateMealQty(meal, meal?.customQty);
            }, 1000);
        } else {
            setTimeout(() => {
              addToCart(meal, true);
            }, 1000);
        }
      }  
       
       let totalMeals = [...settings?.mealPlan];
       let provider;
       mealData?.map((meal)=>{
        provider= availableProviders?.data?.length && availableProviders?.data?.filter((provider)=> provider.id ===meal?.providerId);
          for(let i=0; i<meal?.customQty; i++){
            totalMeals.push({
              ...meal,
              idx: `${meal.id}-${i}`,
              day: getMealDay(totalMeals),
            });
          }
      })
      updateSettings({...settings, 
        mealPlan: totalMeals, 
        days: loadCart?.days, 
        meals: loadCart?.meals,
        provider: provider[0]
      })
      setCustomQtyMeal(true);
      setProviderListCalled(false);
    };

    return {loadClientCart};
}