
import { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import ProviderHeader from './ProviderHeader'
import { FreshMeals } from './FreshMeals'
import { useNutritionistContext } from 'context/nutritionistContext'
import { getEarliestTime, toDateString } from '../../../../pages/shop/providerTimes';
import DietaryModal from './DietaryModal';
import MealPlanner from './MealPlanner'
import { prop, sum } from 'ramda';
import Tooltip from 'rc-tooltip'
import ReactTooltip from 'react-tooltip';
import { useAppContext } from 'context/appContext'
 
const PickMealsStyle = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 113;
    overflow-y: auto;
    height: 100vh;
    background-color: #F6F5F8;
    &.hidden{
        display:none;
      }

    ::-webkit-scrollbar{
        display: none;
    }

    .header__holder{
        padding:24px 8px;
        background-color: #fff;
        border-bottom: 1px solid #E0E3E0;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
    }

    .provider__card__small{
        background-color: #fff;
        margin: 129px 20px 14px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .about__provider{
            display: flex;
            gap: 10px;
            padding: 10px 16px;
            align-items: center;
            border-bottom: 1px solid #F6F5F8;
            position: relative;
        }

        .provider__image{
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background-color: #e0e5e0;
            overflow: clip;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .provider__name{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #0B1D17;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - 135px);
            margin-right: 81px;
        }

        .delivery__pickup{
            display: flex;
            flex-wrap: wrap;
        }

        .min__order{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.02em;
            color: #949D9B;
            background: #F0FAEB;
            border-radius: 4px 0px 0px 4px;
            padding: 4px;
        }

        .icon__with__text{
            display: flex;
            width: 50%;
            gap: 6px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            padding: 10px 10px 10px 16px;
            justify-content: center;
            position: relative;

            &:nth-child(2){
                padding: 10px 16px 10px 11px;

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: #F6F5F8;
                }
            }

            img{
                width: 14px;
                height: 14px;
            }

            p{
                flex: 1;
                white-space: nowrap;
            }

            span{
                font-weight: 700;
            }
        }
    }

    @media (min-width: 390px) and (max-width: 576px){

        .provider__card__small{
            .icon__with__text{
                justify-content: flex-start;

                &:nth-child(2){
                    justify-content: flex-end;
                }
                p{
                    flex: none;
                }
            }
        }
        
    }

    @media (max-width: 359px){

        .provider__card__small{
            margin: 20px 14px 14px;

            .about__provider{
                padding: 10px 14px;
            }

            .icon__with__text{
                width: 100%;
                padding: 7px 14px;

                &:nth-child(2){
                    padding: 7px 14px;
    
                    &::before{
                        left: 3%;
                        width: 94%;
                        height: 1px;
                        background-color: #F6F5F8;
                    }
                }
            }
        }

    }

    @media (max-width: 374px){

        .provider__card__small{
            margin: 32px 14px 14px;
        }

    }

   
        .__react_component_tooltip{
          width: 240px !important;
          word-break: break-all;
         }
     

`

const MealPlannerButton = styled.div`

    padding: 12px 30px 24px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    border-top: 1px solid #F6F5F8;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    &.hidden{
        display:none;
      }

    .cart__info{
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.02em;
        color: #949D9B;
        margin-bottom: 12px;
        display: flex;
        gap: 6px;
        align-items: center;
        padding-left: 6px;

        span{
            color: #52C41A;
        }
    }

    button{
        background-color: #52C41A;
        width: 100%;
        padding: 13px;
        border-radius: 50px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }

`
function shortName(clientName){
    useEffect(()=>{
      ReactTooltip.rebuild();
    },[])    
   
  return(
     <>
   { clientName?.length >10 ?  <p data-tip={clientName} data-for="name" style={{cursor: 'pointer'}}>Pick Meals for { clientName.substring(0, 10) + "..."} </p>
     :
    <p>Pick Meals for {clientName}</p>}
     <ReactTooltip id="name" place="bottom" effect="solid" className='tool-tip'>
      </ReactTooltip>
    </>
  )
}

function shortDesc(clientName){
    useEffect(()=>{
      ReactTooltip.rebuild();
    },[])    
   
  return(
     <>
   { clientName?.length >7 ?  <p data-tip={clientName} data-for="short-desc" style={{cursor: 'pointer'}}>Add fresh, healthy meals to { clientName.substring(0, 7) + "..."}'s meal plan </p>
     :
    <p>Add fresh, healthy meals to {clientName}'s meal plan</p>}
     <ReactTooltip id="short-desc" place="top" effect="solid" className='tool-tip'>
      </ReactTooltip>
    </>
  )
}

export default function PickMeals({ mealModal, clientName, setPickMealsModal, pickMealsModal, followerProfile, setClientModal, setProviderModal, providerId, setProviderId, setProviderModalAnim, setClientAnim, setMealsModalsAnim, setMealPlannerClose, setVisibility, visibility }) {

    const [showAnim, setShowAnim] = useState(true)
    const [pickMealsAnim, setPickMealsAnim] = useState(true);
    const { allTemplates, availableProviders } = useNutritionistContext()
    const providerInfo = availableProviders?.data?.find(item=> item?.id===providerId);
    const { name: providerName, minOrderAmount, images, schedule } = providerInfo;
    const [dietaryModal, setDietaryModal] = useState(false)
    const [mealPlannerModal, setMealPlannerModal] = useState(false)
    const earliestDeliveryTime = getEarliestTime(schedule, 'delivery')
    const { clientsettings, clientcart } = useNutritionistContext();
    const earliestPickupTime = getEarliestTime(schedule, 'pickup')
    const totalPrice =
    sum(clientsettings?.mealPlan?.map(prop('price')) || [0]) / 100;
    let pickupAndDelivery = useMemo(() => {
        if (earliestDeliveryTime && earliestPickupTime) {
            return 'Both'
        } else if (earliestPickupTime) {
            return 'Pickup'
        } else if (earliestDeliveryTime) {
            return 'delivery'
        } else {
            return ''
        }
    }, [earliestDeliveryTime, earliestPickupTime]);
    const {convertToHttps} = useAppContext();
    const dietTemplate = allTemplates?.dietTemplates?.find(dietPlan=> dietPlan?.id === followerProfile?.dietTemplateId);

    useEffect(()=>{
        ReactTooltip.rebuild();
      },[])
      
      useEffect(() => {
        if(pickMealsModal){
            document.body.classList.add('bodyFixed');
          document.body.classList.remove('hide-scroll');
        }
        else{
          document.body.classList.remove('bodyFixed');
        }
    }, [pickMealsModal])

    const imageUrl = images?.[0];
    const secureImageUrl = convertToHttps(imageUrl);
    
    return (
        <>
            <PickMealsStyle className={`${showAnim && pickMealsAnim ? "fadeInMiddle" : "fadeOutMiddle"} ${visibility ? 'hidden' : ''}`}>
                <div className='header__holder'>
                    <ProviderHeader
                        title={shortName(clientName)}
                        description={shortDesc(clientName)}
                        setModal={setProviderModal}
                        setShowAnim={setShowAnim}
                        closeModal={setPickMealsModal}
                        setPrevAnim={setProviderModalAnim} />
                </div>
                <div className="provider__card__small">
                    <div className='about__provider'>
                        <div className='provider__image'>
                            <img src={secureImageUrl || "/images/avatar.png"} />
                        </div>
                        <Tooltip placement="bottom" trigger={['hover', 'click']} overlay={<span>{providerName}</span>}>
                        <div className='provider__name'>
                            {providerName}
                        </div>
                        </Tooltip>

                        <span className='min__order'>
                            {`min. order $${minOrderAmount || 0}`}
                        </span>
                    </div>
                    <div className='delivery__pickup'>
                        {earliestDeliveryTime && (
                            <div className="icon__with__text">
                                <img src='/icons/delivery-icon.svg' />
                                <p>Delivery by: <span>{toDateString(earliestDeliveryTime)}</span></p>
                            </div>
                        )}

                        {earliestPickupTime && (
                            <div className="icon__with__text">
                                <img src='/icons/pickup-icon.svg' />
                                <p>Pick-up by: <span>{toDateString(earliestPickupTime)}</span></p>
                            </div>
                        )}
                    </div>
                </div>

                <FreshMeals setDietaryModal={setDietaryModal} dietaryModal={dietaryModal} pickMealsModal={pickMealsModal} dietTemplate={dietTemplate} setProviderId={setProviderId} providerId={providerId}/>
                <MealPlannerButton>
                    <div className='cart__info'> <img src='/icons/info-circle-gray.svg' /><p>You added <span>{clientsettings?.mealPlan.length}</span> meals to {clientName?.length > 10 ? <span data-tip={clientName} data-for="cName" style={{cursor: 'pointer'}}>{clientName.substring(0,10) + "..."}</span> : <span>{clientName}</span>}'s cart ({`$${totalPrice.toFixed(2)}`})</p></div>

                    <button className={ clientcart.length<1 ? 'disabled' : ''} disabled={clientcart.length<1} onClick={() => {setMealPlannerModal(true)}}>Diet Planner</button>
                </MealPlannerButton>
                <ReactTooltip id="cName" place="top" effect="solid">
                </ReactTooltip>
            </PickMealsStyle>
            {dietaryModal && <DietaryModal dietaryModal={dietaryModal} setDietaryModal={setDietaryModal} dietTemplate={dietTemplate}/>}
            {mealPlannerModal && 
            <MealPlanner 
            setMealPlannerModal={setMealPlannerModal}
            clientName={clientName}
            setClientModal={setClientModal}
            setProviderModal={setProviderModal}
            setPickMealsModal={setPickMealsModal}
            providerId={providerId}
            setProviderId={setProviderId}
            setProviderModalAnim={setProviderModalAnim}
            setPickMealsAnim={setPickMealsAnim}
            setMealsModalsAnim={setMealsModalsAnim}
            setClientAnim={setClientAnim}
            mealPlannerModal={mealPlannerModal}
            setMealPlannerClose={setMealPlannerClose}
            setVisibility={setVisibility}
            />}
        </>

    )
}
