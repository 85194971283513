import { Line } from 'rc-progress';
import classNames from 'classnames';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { useVisitorContext } from 'context/visitorContext';

const flexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DietPlannerCheckoutWrapper = styled.div`
  padding-bottom: 10px;
  background-color: #fff;

  .title-btn {
    padding: 7px 0;
    ${flexBetween};

    .title {
      color: #0b1d17;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
    }
  }

  .rc-progress-line {
    width: 100%;
    margin: 10px 0 6px;
  }

  .days-meals-wrapper {
    ${flexBetween};

    p {
      font-size: 12px;
      line-height: 14px;

      &:first-child {
        font-family: 'Roboto';
        color: #51635d;
      }

      &:last-child {
        color: #949d9b;
        font-weight: 400;
      }
    }
  }

  @media (min-width: 992px) {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 5;
    width: 430px;
    padding: 25px 32px 32px;
    border-top: 1px solid #e0e3e0;
    box-shadow: rgb(0 0 0 / 10%) -6px 0px 4px -2px;

    .btn-container {
      margin-top: 24px;
    }

    button {
      width: 100%;
      height: 56px;
      background-color: var(--nutritt-green);
      color: #fff;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      border-radius: 28px;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        background-color: #c3cbc9;
      }
    }

    .days-meals-wrapper {
      p{
        &:first-child{
          font-weight: 700;
        }
      }
    }
  }
`;

export default function DietPlannerCheckout(props) {
  const history = useHistory();
  const { isMobile, user } = useAppContext();
  const context = user ? useShopContext() : useVisitorContext();
  let { cartCount, settings, cart } = context || {}
  const maxMealsCount = settings?.days * settings?.meals;
  return (
    <DietPlannerCheckoutWrapper {...props}>
      <div className="container">
        {isMobile && (
          <div className="title-btn">
            <div className="title">Diet planner</div>
          </div>
        )}
        <Line
          percent={(cartCount / maxMealsCount) * 100}
          strokeWidth="3"
          strokeColor="#52C41A"
          trailWidth="3"
          minWidth="150px"
          height="10px"
          trailColor="#E0E3E0"
        />
        <div className="days-meals-wrapper">
          <p>
            {settings.days} days / {settings.meals} meals per day
          </p>
          <p>
            {cartCount}/{maxMealsCount} meals
          </p>
        </div>
      </div>
      {!isMobile &&
        <div className="container btn-container">
          <button
            className={classNames({ disabled: !cartCount })}
            disabled={!cartCount}
            onClick={(e) => {
              e.stopPropagation();
              history.push(user?'/shop/checkout':'/visitor/get-started?redirectTo=/shop/cartScreen');
              window.scrollTo(0, 0);
            }}
          >
            Checkout
          </button>
        </div>
      }
    </DietPlannerCheckoutWrapper>
  );
}
