import { memo } from 'react';

function TableHeader({ columns }) {
    return (
        <div className="table-Header" style={{ display: 'flex' }}>
            {columns.map(({ header, id, width }) => (
                <div key={id} style={{ width }} className="table-cell">
                    <strong>{header}</strong>
                </div>
            ))}
        </div>
    );
}
export default memo(TableHeader);