import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const SortSheetWrapper = styled.div`
  padding: 0 16px;
  color: ${theme.colors.primaryDarker};
  font-family: Gilroy;
  font-weight: 600;
  .sort-header {
    position: relative;
    height: 48px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-img {
      position: absolute;
      left: 0;
      top: auto;
    }
    .header-title {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .sort-title {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: normal;
  }

  .sort-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .sort-item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sort-item-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .sort-info {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .apply-btn-wrapper {
    position: fixed;
    bottom: 23px;
    left: auto;
    width: calc(100vw - 32px);
    display: flex;
    justify-content: center;
    .btn {
      width: 271px;
      height: 42px;
      border-radius: 30px;
      font-family: ${theme.typography.fontGilroy};
      font-size: 16px;
      font-weight: 700;
      background-color: ${theme.colors.primaryDefault};
      &::after {
        display: none;
      }
    }
  }
`;
