import styled from 'styled-components';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProvidersManagementWrapper = styled.div`
  position: relative;
  z-index: 5;
  height: calc(100vh - 32px);
  margin-left: 300px;
  background-color: #ffffff;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
  .modal-position {
    position: fixed;
    inset: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(40, 50, 65, 0.6);
  }
  .providers-title {
    margin-bottom: 54px;
    padding-top: 54px;
    color: #51635d;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .providers-management-container {
    width: 896px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 198px);

    .empty-provider-list{
      text-align:center;
    }

    .loading-dots {
      animation: blink 1s infinite;
    }
    
    @keyframes blink {
      0%, 100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  }

  .provider-search-wrapper {
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;

    .search-wrapper {
      position: relative;
      display: flex;
      align-items: flex-end;
      input {
        width: 100%;
        height: 44px;
        padding-left: 41px;
        border: 1px solid #e0e3e0 !important;
        border-radius: 6px;
        font-size: 14px !important;
      }
      .Icon svg {
        position: absolute;
        bottom: 14px;
        left: 15px;
      }
    }
    

    .select-wrapper {
      margin-bottom: 0;
      .select-label {
        font-weight: 700;
      }
    }
  }

  .valueSelected {
    background: #f0faeb;
    color: #52c41a;
  }

  .provider-name-wrapper {
    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .table-Header {
    margin-bottom: 14px;

    .table-cell {
      color: #51635d;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      text-align: center;
    }

    .table-cell:first-child {
      padding-left: 56px;
      text-align: left;
    }

    .table-cell:last-child {
      padding-right: 20px;
      text-align: right;
    }
  }

  .List {
    .table-row-wrapper {
      padding-bottom: 10px;
      &:last-child {
        padding: 0;
      }
    }
    padding-bottom: 20px;
    .table-row {
      height: 50px;
      background-color: #f5f7f8;
      border: 1px solid #e0e3e0;
      border-radius: 10px;
      align-items: center;
      &.even {
        background: #ffffff;
      }
      &.active {
        background-color: #f0faeb;
        border-color: #52c41a;
      }
    }

    .provider-name-wrapper {
      margin-left: 14px;
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 50%;
      }

      .provider-name {
        position: relative;
        color: #51635d;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        &:after {
          content: '';
          position: absolute;
          bottom: 1.25px;
          left: 0;
          right: 0;
          border-top: 1px solid #51635d;
        }
      }
    }

    .pending-order-number {
      ${FlexCenter};
      div {
        padding: 1px 4px;
        background-color: #eb3223;
        color: #ffffff;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .item-number {
      color: #51635d;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: center;
    }

    .account-status {
      ${FlexCenter};
      div {
        padding: 4px 9px;
        color: #35a956;
        border: 1px solid rgba(53, 169, 86, 0.2);
        border-radius: 10px;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        text-transform: capitalize;
        background-color: #fff;
      }
      .unlisted {
        color: #949d9b;
      }
      .unclaimed {
        color: #eb3223;
      }
    }

    .manage-wrapper {
      display: flex;
      justify-content: flex-end;
      .setting-icon-wrapper {
        width: 32px;
        height: 32px;
        margin-right: 24px;
        background: #ffffff;
        border: 1px solid #f6f5f8;
        border-radius: 50%;
        cursor: pointer;
        ${FlexCenter};
      }
      svg {
        color: #52c41a;
      }
    }
  }

  .select-wrapper{
    .selected-value{
      &.selectedValue{
        img{
          transform: rotate(180deg);
        }
      }
    }
  }

  @media (max-width: 1250px) {
    .providers-management-container {
      width: 95%;
    }
  }

  @media (max-width: 991px) {
    height: auto;
    margin-left: 0;
    background-color: #f6f5f8;
    box-shadow: none;

    .providers-management-wrapper {
      overflow: hidden;
      margin: 0 20px 24px;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px;
      .providers-title {
        margin-bottom: 24px;
        padding-top: 0;
        color: #0b1d17;
        font-size: 16px;
        line-height: 18.75px;
      }
    }

    .providers-management-container {
      width: auto;
      .table-Header {
        display: none !important;
      }
      .List {
        &::-webkit-scrollbar {
          display: none;
        }
        .table-row {
          position: relative;
          height: auto;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          & > div {
            width: 100% !important;
          }
        }
        .provider-name-wrapper {
          margin: 0 0 10px;
          width: calc(100% - 70px);
          .provider-name {
            overflow: hidden;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:after {
              display: none;
            }
          }
        }
        .pending-order-number {
          justify-content: flex-start;
          div {
            padding: 0;
            background-color: transparent;
            color: #51635d;
            font-weight: 400;
            display: flex;
            align-items: center;
          }
          .item {
            margin-left: 8px;
            padding: 1px 4px;
            background-color: #eb3223;
            color: #ffffff;
            border-radius: 4px;
            font-weight: 700;
            line-height: normal;
          }
        }
        .item-number {
          margin-top: 5px;
          text-align: left;
          display: flex;
          align-items: center;
          div {
            margin-left: 5px;
            font-weight: 700;
          }
        }
        .account-status {
          position: absolute;
          top: 22px;
          right: 10px;
          div {
            background-color: #fff;
          }
        }
        .manage-wrapper {
          position: absolute;
          bottom: 10px;
          right: 10px;
          .setting-icon-wrapper {
            margin: 0;
          }
        }
      }
    }

    .provider-search-wrapper {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .input-container {
        margin: 0;
      }
    }

    .table-row-loading {
      height: 130px;
      margin-bottom: 10px;
      flex-direction: column;
      overflow: hidden;
      .MuiTableCell-root  {
        width: 100%; !important;
      }
    }
  }
`;

export const ButtonStyles = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  text-align: center;
  z-index: 2;

  .basic-button {
    width: 120px;
    height: 40px;
    margin-left: 10px;
    background: #52c41a;
    color: #ffffff;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;
    &:first-child {
      margin-left: 0px;
    }
  }

  @media (max-width: 991px) {
    position: static;
    margin: 24px 0px;
    display: flex;
    justify-content: center;
  }
`;

export const Mask = styled.div`
  position: fixed;
  inset: 0;
`;

export const ManageModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  overflow-y: auto;
  width: 370px;
  background: #ffffff;
  height: 100%;
  padding: 28px 48px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  --animate-duration: 0.5s;

  .provider-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-item: center;
    h3 {
      color: #51635d;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
    img {
      cursor: pointer;
    }
  }

  .provider-image-wrapper {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
      .image-container{
        width: 274px;
        height: 154px;
      }
      .main-image{
        position: relative;
        .cross_icon{
          position: absolute;
          top: 4px;
          right: 6px;
          cursor: pointer;
        }
        .main-img{
          width: 274px;
          height: 154px;
          border-radius:6px;
        }
      }
    .profile-name {
      position: relative;
      color: #52c41a;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      cursor: pointer;
      max-width: 100%;
      overflow: hidden;
      word-break: break-all;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        border-top: 1px solid #52c41a;
        left: 0;
        bottom: 1px;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .form-inputs {
      margin-bottom: 18px;
    }

    label,
    .select-label {
      margin-bottom: 0;
      padding: 9px 0;
      color: #51635d;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      display: flex;
    }

    .input-container {
      margin: 0 0 14px;
      input,
      input[disabled] {
        width: 100%;
        height: 44px;
        padding: 9px 10px 9px 14px;
        background: #ffffff !important;
        color: #51635d !important;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 16px;
        ::placeholder{
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #51635d !important;
        }
      }
      .input-wrapper input {
        height: 38px;
        min-height: 38px;
      }
    }

    .field-wrapper {
      position: relative;
      img {
        position: absolute;
        top: 16px;
        right: 14px;
      }
    }

    .select-wrapper {
      margin-bottom: 14px;
    }

    .btn {
      width: 100%;
      height: 40px;
      background-color: #fff;
      color: #51635d;
      border: 1px solid #e0e3e0 !important;
      border-radius: 50px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      & + .btn {
        margin-top: 10px;
      }
      &.save-btn {
        background-color: #52c41a;
        color: #fff;
      }
      &.cancel-btn {
        border-color: #97dc76 !important;
      }
      &[disabled] {
        background-color: #c3c3c3 !important;
        border: 1px solid #c3c3c3 !important;
        cursor: not-allowed;
      }
    }
  }

  .select-wrapper{
    .selected-value{
      &.selectedValue{
        img{
          transform: rotate(180deg);
        }
      }
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    padding: 29px 32px 32px;

    .form-inputs {
      label {
        margin-bottom: 0 !important;
      }
      .selected-value {
        padding: 0px 14px;
        color: #51635d;
      }
    }
  }
`;