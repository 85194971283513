import { memo } from 'react';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import TableLoading from './TableLoading';
import TableRowLoading from './TableRowLoading';
import InfiniteScroll from 'react-infinite-scroll-component';

/* const Columns = [{
  id: string // required
  header?: string | function 
  width?: string | number
  render?: function
}] */

function VirtualTable({
    data,
    isLoading,
    columns,
    loadMoreItems,
    threshold = 10,
    hasNextPage,
    actions,
    id,
    tableHeight,
}) {
    const lastRowIndex = data.length - 1;
    const tableLoading = isLoading && data.length === 0;

    const Item = ({ index, style }) => {
        const isFetching = index === lastRowIndex && isLoading;
        if (isFetching) return <TableRowLoading style={style} columns={columns} />;
        return (
            <TableRow
                style={style}
                columns={columns}
                row={data[index]}
                index={index}
                actions={actions}
                id={id}
            />
        );
    };

    return (
        <div style={{ flex: '1 1 auto' }}>
            <TableHeader columns={columns} />
            {tableLoading ? (
                <TableLoading columns={columns} />
            ) : (
                <InfiniteScroll
                    dataLength={data?.length}
                    next={loadMoreItems}
                    pullDownToRefreshThreshold={threshold}
                    hasMore={hasNextPage}
                    scrollableTarget="scrollableDiv"
                    className="List"
                    height={tableHeight}
                >
                    {data?.map((item, index) => (
                        <Item key={item.id} index={index} {...item} style={{ width: '100%' }} />
                    ))}
                </InfiniteScroll>
            )}
        </div>
    );
}

export default memo(VirtualTable);