import styled from 'styled-components';

const MealCardContentStyle = styled.div`
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fresh-card-content {
    display: flex;
    width: 100%;
    height: 37px;
  }
  .fresh-card-container {
    width: 100%;
    margin: 0px;
    padding: 5px;
    border-right: 1px solid rgb(224, 227, 224);
    font-size: 9px;
    line-height: 12px;
    color:#51635D;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 37px;
    justify-content: center;
    :last-child {
      padding-right: 0;
      border: 0;
    }
    span {
      :first-child {
        margin-bottom: 3px;
        font-weight: 700;
      }
      :last-child {
        text-transform: capitalize;
        font-weight: 400;
      }
    }
  }

  @media (min-width: 992px) {
    .fresh-card-content {
      width: 100%;
      height: auto;
      border: 1px solid #f6f5f8;
      border-radius: 5px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .fresh-card-container {
        padding: 0;
        span:first-child {
          font-size: 10px;
          font-family: 'Roboto';
          line-height: 12px;
        }
        span:last-child {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
`;

export default function ClientDiaryContent({ calories = 0, carbs = 0, fat = 0, protein = 0 }) {
  return (
    <MealCardContentStyle>
      <div className="fresh-card-content">
        <div className="fresh-card-container">
          <span>{Math.round(calories)}</span>
          <span>cals</span>
        </div>
        <div className="fresh-card-container">
          <span>{Math.round(carbs)}g</span>
          <span>carbs</span>
        </div>
        <div className="fresh-card-container">
          <span>{Math.round(fat)}g</span>
          <span>fat</span>
        </div>
        <div className="fresh-card-container">
          <span>{Math.round(protein)}g</span>
          <span>protein</span>
        </div>
      </div>
      {/* <div className="oz-number">5 oz</div> */}
    </MealCardContentStyle>
  );
}
