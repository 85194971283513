import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenOurMissionWrapper = styled.div`
  padding: 100px 0;
  background-color: #f5f7f8;

  .kitchen-ourMission-container {
    overflow: hidden;
    width: 1070px;
    height: 364px;
    margin: 0 auto;
    background: ${theme.colors.secondary};
    border-radius: 20px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    justify-content: space-between;
  }

  .our-mission-info {
    margin: 85px 0 70px 82px;
    color: ${theme.colors.primaryDarker};
    .mission-title {
      font-size: 48px;
      font-weight: 700;
      line-height: normal;
    }
    p {
      width: 337px;
      margin: 1px 0 38px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .app-store-image {
      display: flex;
    }
  }

  .mission-image-wrapper {
    width: 533px;
    height: 364px;
  }
`;
