import styled from 'styled-components';
import { GrAddCircle } from 'react-icons/gr';
import Tooltip from '@material-ui/core/Tooltip';
import ImageViewer from '../../components/ImageViewer';

const MealCard = styled.div`
  width: 724px;
  max-width: 800px;
  display: grid;
  grid-template-columns: auto 1fr;
  height: auto;
  border: 1px solid #e0e3e0;
  border-radius: 10px;
  cursor: pointer;

  & + & {
    margin-top: 24px;
  }

  :hover {
    border: 1px solid var(--nutritt-green);
  }

  img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 100%;
    height: 256px;
  }

  @media (max-width: 767px) {
    overflow: hidden;
    width: 100%;
    grid-template-columns: 1fr;

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 0;
    }
  }
`;


const CardContent = styled.div`
  grid-template-rows: auto auto 24px;
  padding: 20px;
  h2 {
    margin: 0;
  }
  .lengthAndTags {
    margin-top: 10px;
    display: grid;
    justify-content: end;
  }
  p {
    margin: 0;
  }
  .description {
    color: #939b99;
    align-self: center;
    margin-bottom: 15px;
    min-height: 100px;
  }
  .label {
    color: #939b99;
    align-self: center;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    padding: 12px;

    h2 {
      font-size: 18px;
    }

    .description {
      margin: 12px 0;
    }
  }
`;

const Tags = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 6px;
  justify-content: end;
  margin-right: 6px;
`;
const Tag = styled.span`
  padding: 0 6px;
  margin: auto;
  height: 25px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #52c41a;
  border: 1px solid #52c41a;
`;
const GoShoppingTag = styled.span`
  justify-self: end;
  padding: 0 12px;
  height: 25px;
  color: #0b1c18;
  border-radius: 10px;
  font-size: 12px;
  background: #52c41a1d;
  display: flex;
  align-items: center;
`;

const TagsWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
`;

const MoreTagsWrapper = styled.div`
 
`;

const RenderTags = ({ tags, justEat }) => {
  return justEat ? (
    <GoShoppingTag>Go shopping</GoShoppingTag>
  ) : (
    <Tags tagCount={tags.length}>
      {tags.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
    </Tags>
  );
};

export default function PlanCard({ selectPlan, plan }) {
  const {
    id,
    name,
    introduction,
    duration,
    duration_type,
    type,
    tags = [],
    carbs_percent,
    protein_percent,
    fat_percent,
    tips,
    food_to_avoid,
    justEat,
    image,
    goal_type,
    goal,
    PAL,
    PAL_formula,
    BMR_formula,
    nutritionistId,
  } = plan;

  const handleSelectPlan=()=>{
    selectPlan(id)
    window.analytics.identify({
      mealplan: [name],
    });
  }
  return (
    <MealCard onClick={handleSelectPlan} key={id}>
      <ImageViewer src={image || '/img_coming_soon.png'} fallbackSrc={'/img_coming_soon.png'} alt="meal" width="300" />
      <CardContent>
        <h2>{name}</h2>
        <p className="description">{introduction}</p>
        <TagsWrapper>
          <p className="label">
            {' '}
            {duration} {duration_type}{' '}
          </p>
          <MoreTagsWrapper>
            {!!tags?.length && (
              <div className="label lengthAndTags">
                <RenderTags tags={tags} justEat={justEat} />
              </div>
            )}
         </MoreTagsWrapper>
        </TagsWrapper>
      </CardContent>
    </MealCard>
  );
}

