import React, { useState, useEffect, useCallback,useMemo } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import moment from 'moment';
import { Button } from 'components/elements/button/Button';
import { DeliveryDayHours } from './components/DeliveryDayHours';
import { EditSchedule } from './EditSchedule';
import Alert from 'components/elements/alert/Alert';
import { slotGenerator, hourGenerator } from 'utils';

const DeliveryScheduleStyle = styled.div`
.alert{
  position: fixed;
  top: 19px;
  bottom: auto;
  right: 0px;
}
.left {
    width: 242px;
  }
  .right {
    flex: 1;
  }
  .details {
    margin-top: 85px;
    text-align: center;

    p {
      width: 300px;
      margin: 0 auto;
      color: ${({ theme }) => theme?.colors?.blackDefault};
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding-bottom: 12px;
    }
  }
  .schedules_date {
  display: flex;
  flex-direction:column;
  gap: 40px;
  padding-bottom: 8px;
}
.data_title {
  display: flex;
  margin-bottom: 12px;
  .titles {
      color: ${({ theme, selectedDays,isEdit }) =>
  (selectedDays.length > 0 && isEdit) ? theme?.colors?.blackDefault : theme?.colors?.grayDarker};
      font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
  }
}
`;

export const DeliverySchedule = ({ schedule, isEdit, setIsEdit, setData, setSelectedTimes, selectedTimes,setIsButtonDisabled, parentRef }) => {
  const [showDetails, setShowDetails] = useState(true);
  const [selectedDays, setSelectedDays] = useState([]);
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const [selectedRadio, setSelectedRadio] = useState({});
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });

  const hasNonNullData = useMemo(() => {
    if (schedule !== undefined && schedule !== null) {
      return Object.values(schedule)?.some((value) => value !== null && value.isDeliveryOn === true);
    }
    return false;
  }, [schedule]);

  useEffect(() => {
    const selectedDay = schedule !== undefined && schedule !== null && Object.keys(schedule)?.filter((day) => schedule[day]?.isDeliveryOn === true);

    setSelectedRadio((prevSelectedRadio) => {
      const newSelectedRadio = { ...prevSelectedRadio };

      selectedDay && selectedDay?.forEach((day) => {
        const hasLeadTime = schedule[day]?.lead;
        const hasCutOffTime = schedule[day]?.cutoffTime;

        if (!hasLeadTime && !hasCutOffTime) {
          delete newSelectedRadio[day];
        } else if (hasLeadTime) {
          newSelectedRadio[day] = 'Same day';
        } else if (hasCutOffTime) {
          newSelectedRadio[day] = 'Cut-off time';
        }
      });

      return newSelectedRadio;
    });

    setSelectedTimes((prevSelectedTimes) => {
      const newSelectedTimes = { ...prevSelectedTimes };

      selectedDay && selectedDay?.forEach((day) => {
        if (schedule[day] && schedule[day].start && schedule[day].end) {
          newSelectedTimes[day] = {
            start: schedule[day].start,
            end: schedule[day].end,
            lead: schedule[day].lead || '',
            cutoffDay: schedule[day].cutoffDay || '',
            cutoffTime: schedule[day].cutoffTime || '',
            isDeliveryOn: true
          };
        }
      });
      return newSelectedTimes;
    });
    setSelectedDays(selectedDay);
  }, [schedule, isEdit]);


  const startTimeGen = moment().startOf('day').toString();
  const options = slotGenerator(startTimeGen, 30, '00:00', '24:00');


  const hourOptions = hourGenerator(1);
  const daysOptions = days.map((day) => ({ label: day, value: day }));

  const handleButtonClick = () => {
    setShowDetails(false);
    setIsEdit(true);
  };


  const handleDayToggle = (day) => {
    if (Array.isArray(selectedDays) && selectedDays.includes(day)) {
      setSelectedDays((prevSelectedDays) => {
        if (Array.isArray(prevSelectedDays) && prevSelectedDays.includes(day)) {
          return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
        } else {
          return [...prevSelectedDays, day];
        }
      });

      setSelectedRadio((prevSelectedRadio) => ({
        ...prevSelectedRadio,
        [day]: 'Same day',
      }));

      setSelectedTimes((prevSelectedTimes) => {
        const updatedTimes = { ...prevSelectedTimes };

        if (!updatedTimes[day]) {
          updatedTimes[day] = { start: '', end: '' };
        }

        if (selectedDays.includes(day) && updatedTimes[day].start === '' && updatedTimes[day].end === '') {
          delete updatedTimes[day];
        } else {
          updatedTimes[day].isDeliveryOn = !selectedDays.includes(day);
        }

        return updatedTimes;
      });
    } else {
      setSelectedDays(prevSelectedDays => {
        if (Array.isArray(prevSelectedDays)) {
          return [...prevSelectedDays, day];
        } else {
          return [day];
        }
      });

      setSelectedRadio((prevSelectedRadio) => ({
        ...prevSelectedRadio,
        [day]: 'Same day',
      }));

      setSelectedTimes((prevSelectedTimes) => {
        const updatedTimes = { ...prevSelectedTimes };
        if (!updatedTimes[day]) {
          updatedTimes[day] = { start: '', end: '', isDeliveryOn: true };
        }
        else {
          updatedTimes[day] = { ...prevSelectedTimes[day], isDeliveryOn: true };
        }
        return updatedTimes;
      });
    }
  };
  

  const handleRadioToggle = (day, radio) => {
    setSelectedRadio((prevSelectedRadio) => ({
      ...prevSelectedRadio,
      [day]: radio,
    }));
  };

  const handleTimeChange = (day, type, value) => {
    setIsButtonDisabled(false);
    setSelectedTimes((prevSelectedTimes) => {
      const updatedDay = {
        ...prevSelectedTimes[day],
        [type]: value,
      };

      if (type === 'start' && updatedDay.start && updatedDay.start > updatedDay.end && updatedDay.end !== "") {
        showErrorMessage('From time cannot be greater than To time');
        setIsButtonDisabled(true);
        return prevSelectedTimes;
      }
  
      if (type === 'end' && updatedDay.end && updatedDay.end < updatedDay.start) {
        setIsButtonDisabled(true);
        showErrorMessage('To time cannot be less than From time');
        return prevSelectedTimes;
      }
  
      return {
        ...prevSelectedTimes,
        [day]: updatedDay,
      };
    });
  };
  
  const handleLeadTimeChange = (day, value, isCutoffTime) => {
    const leadHours = parseInt(value.split(':')[0], 10);
    const leadHoursString = leadHours.toString();
  
    setSelectedTimes((prevSelectedTimes) => {
      const newSelectedTimes = { ...prevSelectedTimes };
      const selectedDay = newSelectedTimes[day];
  
      newSelectedTimes[day] = {
        ...selectedDay,
        lead: leadHoursString,
        isDeliveryOn: true,
        cutoffDay: isCutoffTime ? selectedDay.cutoffDay : undefined,
        cutoffTime: isCutoffTime ? selectedDay.cutoffTime : undefined,
      };
  
      return newSelectedTimes;
    });
  };
  

  const handleCutoffDateChange = (day, date) => {
    const dayIndex = ((days.findIndex((dayName) => dayName.toLowerCase() === date.toLowerCase()) + 1) % 7).toString();
    if (dayIndex !== -1) {
      setSelectedTimes((prevSelectedTimes) => ({
        ...prevSelectedTimes,
        [day]: {
          ...prevSelectedTimes[day],
          isDeliveryOn: true,
          cutoffDay: dayIndex,
          lead: "",
        },
      }));
    }
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const buttonDisabled = useCallback((day) => {
    const dayData = selectedTimes[day];
    if (!dayData?.start || !dayData?.end) {
      return true;
    }

    const radioValue = selectedRadio[day];
  
    if (radioValue === 'Same day' && !dayData.lead) {
      return true;
    }
    if (radioValue === 'Cut-off time' && (!dayData.cutoffDay || !dayData.cutoffTime)) {
      return true; 
    }
    return false;
  },[selectedTimes, selectedRadio]);

  useEffect(() => {
    if (!Array.isArray(selectedDays)) {
      setIsButtonDisabled(false);
      return;
    }
  
    const allDisabled = selectedDays.some((day) => buttonDisabled(day));
    setIsButtonDisabled(allDisabled);
  }, [selectedDays, buttonDisabled]);

  return (
    <DeliveryScheduleStyle
      theme={theme}
      showDetails={showDetails}
      selectedDays={selectedDays}
      isEdit={isEdit}
    >
      {alertData.type && (
        <div className="alert">
          <Alert
            type={alertData.type}
            title={alertData.title}
            width="461px"
            onClose={() => setAlertData({ type: '', title: '' })}
          />
        </div>
      )}
      {!isEdit && (
        <>
          {hasNonNullData ? (
            <>
              <div className="data_title">
                <div className="titles left">Day</div>
                <div className="titles right">Delivery hour</div>
              </div>
              <div className="schedules_date">
                {Object.entries(schedule)
                  .sort((a, b) => {
                    return days.indexOf(a[0]) - days.indexOf(b[0]);
                  })
                  .map(([day, data]) => {
                    const capitalizedDay = day.charAt(0).toUpperCase() + day.slice(1);
                    if (data?.isDeliveryOn) {
                      return (
                        <DeliveryDayHours
                          key={day}
                          day={capitalizedDay}
                          data={data}
                          selectedRadio={selectedRadio}
                          days={days}
                        />
                      );
                    }
                    return null;
                  })}
              </div>
            </>
          ) : (
            <>
              <div className="details">
                <img src="/images/Library.svg"></img>
                <p>Oops, you haven’t set up your delivery schedule</p>
                <Button
                  type="dark"
                  title="Set up Delivery Schedule"
                  onClick={handleButtonClick}
                ></Button>
              </div>
            </>
          )}
        </>
      )}
      {isEdit && (
        <EditSchedule
          days={days}
          schedule={schedule}
          selectedRadio={selectedRadio}
          selectedDays={selectedDays}
          options={options}
          hourOptions={hourOptions}
          daysOptions={daysOptions}
          handleDayToggle={handleDayToggle}
          handleRadioToggle={handleRadioToggle}
          handleTimeChange={handleTimeChange}
          selectedTimes={selectedTimes}
          setSelectedTimes={setSelectedTimes}
          handleLeadTimeChange={handleLeadTimeChange}
          handleCutoffDateChange={handleCutoffDateChange}
          parentRef={parentRef}
        />
      )}
    </DeliveryScheduleStyle>
  );
};

