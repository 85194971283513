import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useOnboardingContext } from "..";

const CrossButtonStyle = styled.div`
    .close_cross{
        width: 18px;
        height: 18px;
        position: absolute;
        top: 20px;
        left: 20px;
        img{
            display: block;
            width: 100%;
        }
    }
`

const CrossButton = () => {
    const history = useHistory();
    const {backUrl} = useOnboardingContext();

    const handleBack = () => {
        history.push(backUrl || '/shop/pantry')
    }

    return (
        <CrossButtonStyle>
            <span className='close_cross' onClick={handleBack}><img src='/icons/close-cross-green.svg' /></span>
        </CrossButtonStyle>
    )
}

export { CrossButton };