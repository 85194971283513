import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { profile } from './PlanningData';
import Nutritions from 'pages/onboarding/components/Nutritions';
import {
  NutritionistContextProvider,
  useNutritionistContext,
} from '../../../context/nutritionistContext';
import { useParams } from 'react-router-dom';
import MoreUserDetails from './MoreUserDetails';
import { useAppContext } from 'context/appContext';

const UserDetailsWrapper = styled.div`
  padding: 0 25px 24px;
  border-bottom: 1px solid #f5f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .user-image {
    position: absolute;
    top: -37.5px;
    left: auto;
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }

  h2 {
    margin-bottom: 10px;
    color: #51635d;
    font-family: 'Roboto';
    line-height: 28px;
  }

  .user-shape {
    margin-bottom: 5px;

    span {
      color: #51635d;
      font-size: 14px;
      line-height: 16px;

      & + span:before {
        content: '|';
        margin: 0 10px;
        color: #e0e3e0;
      }
    }
  }

  .user-life-style {
    margin-bottom: 18px;
    color: #51635d;
    font-size: 14px;
    line-height: 16px;
  }

  .more-button {
    width: 130px;
    height: 32px;
    background: #ffffff;
    color: #51635d;
    font-size: 14px;
    font-family: 'Roboto';
    border: 1px solid #97dc76 !important;
    border-radius: 21px;
    line-height: 16px;
    cursor: pointer;

    &.isMore {
      display: none;
    }
  }

  @media (min-width: 992px) {
    padding: 0 25px;

    .user-image {
      position: static;
      width: 120px;
      height: 120px;
    }

    h2 {
      margin: 20px 0 25px;
    }

    .user-about-wrapper {
      width: 100%;
      padding: 24px 0;
      border-top: 1px solid #e0e3e0;
      text-align: center;

      .user-about-title {
        color: #51635d;
        font-size: 16px;
        font-family: 'Roboto';
        line-height: 19px;
      }

      .user-shape {
        margin: 13px 0 7px;

        span + span:before {
          margin: 0 14px;
        }
      }

      .user-life-style {
        margin-bottom: 0;
      }
    }
  }
`;

export default function UserDetails() {
  const { userId } = useParams();
  const { activeClients } = useNutritionistContext();
  const userData = activeClients?.nutritionistMeeting?.filter((item) => item?.userId == userId)[0];
  const [isMore, setIsMore] = useState(false);
  const { pathname } = useLocation();
   const profile = userData?.user?.profile;
  const { isMobile } = useAppContext();

  const generateActivity = () => {
    let level;
    switch (profile?.activity) {
      case 1:
        level = 'Inactive';
        break;
      case 2:
        level = 'Slighty Active';
        break;
      case 3:
        level = 'Moderately Active';
        break;
      case 4:
        level = 'Active';
        break;
      case 5:
        level = 'Extremely Active';
        break;
    }
    return level;
  };

  return (
    <UserDetailsWrapper>
      <img
        src={profile?.img || '/images/man.svg'}
        alt="Man Image"
        className="user-image"
        id="userProfile"
      />
      <h2>{profile?.name}</h2>
      <div className="user-about-wrapper">
        {!isMobile && <div className="user-about-title">About</div>}
        <div className="user-shape">
          <span>{profile?.age} y/o</span>
          <span>{profile?.heightFeet} ft{profile?.heightInches && ","} {profile?.heightInches} {profile?.heightInches && "inch"}
          </span>
          <span>{profile?.weight} lbs</span>
        </div>
        <div className="user-life-style">Lifestyle: {generateActivity()}</div>
      </div>
      {isMobile && (
        <HashLink smooth to={`${pathname}#userProfile`}>
          <button
            className={classNames('more-button', { isMore })}
            onClick={() => {
              setIsMore(true);
            }}
          >
            Show more
          </button>
        </HashLink>
      )}
      {isMobile ? (
        isMore && <MoreUserDetails setIsMore={setIsMore} profile={profile} />
      ) : (
        <MoreUserDetails setIsMore={setIsMore} profile={profile} />
      )}
    </UserDetailsWrapper>
  );
}
