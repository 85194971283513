import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import MobileHeader from 'components/elements/MobileHeader';
import RegularInput from 'components/Input/RegularInput';
import { onBlurEmail } from 'components/auth/utils';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/elements/button/Button';
import  FooterLink  from 'layout/FooterLinks' 
import { GilroyFontWrapper } from 'shared/theme/style';
import GoogleLogin from 'react-google-login';


const LoginForm = styled.form`
background:white;
height: 100vh;
position: fixed;
overflow: hidden;
width: 100%;
.OR {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #6F7380;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  &:before, &:after {
    content: '';
    display: inline-block;
    height: 1px;
    width: 120px;
    background: #00000044;
    position: absolute;
    top: 50%;
  }
  &:before {
    transform: translate(-62%, -50%);
  }
  &:after {
    transform: translate(62%, -50%);
  }
}
.form{
    padding: 12px 16px 0px 16px;
    width: 39%;
    display: flex;
    flex-direction: column;
    margin: auto;
   }
  .forget-password-wrapper{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 24px;
      a{
      color: #00092D;
      text-align: right;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      float: right;
      }
  } 
  .Signup-Wrapper{
    display:flex;
    place-content:center;
    gap: 4px;
    margin-top:26px;
    color: #00092D;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
      .signUp{
        color: #00092D;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
  }
  .google-login{
    width: 100%;
    justify-content: center;
    display: flex;

    button{
      height: 42px;
      width: 270px;
      display: flex;
      gap: 6%;
      align-items: center;
      border-radius: 30px !important;
      border: 1px solid #E0E4E8 !important;
      background: #FCFCFD!important;
      padding: 14px 16px 12px 16px !important;
      box-shadow: none !important;
      opacity: 1 !important;
  
      svg{
        display: flex;
        justify-content: center;
        margin: auto;
        width: 24px;
        height: 24px;
      }
      div{
        height: 36px;
        display: flex;
        background: #FCFCFD!important;
        padding: 10px 0 !important;
        transform: translate(80%,0%);
      }
      span{
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        color: #00092D;
      }
    }
  }
    @media (max-width: 991px) {
      .form{
        width: 100% !important;
      }
    }
`
const FormGroupStyle = styled.div`
  margin-bottom: 8px;
  width: 100%;
    label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom:9px;
    display: inline-block;
    }
   input {
    height: 44px;
    border-radius: 6px;
    border: 1px solid #E0E3E0;
      :hover, :focus {
         border-color: #00092D !important;
      }
      :focus::placeholder {
        color: transparent;
      }
      ::placeholder{
        color: #949D9B;
        font-style: normal;
        font-weight: 500;
      }
    }
  .value {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    }
`;

export default function Login() {
  const history = useHistory();
  const {
    afterLogin, isMobile, setProfileObj,
    isSaving, loadPantryMeals, getdiaryData,
    isGetMealLoading, TimeZone,
    isGetdiaryMealLoading, setLogin, platformObject } = useAppContext();
  const [email, setEmail] = useState({ value: '', error: '' });
  const [password, setPassword] = useState({ value: '', error: '' });
  const [login, { isLoading, error, setError }] = useRequest('auth/login', 'post');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const isEmailValid = (email) => {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const onChangeEmail = (value, setEmail) => {
    if (!value) return setEmail({ value, error: 'Email is required' });
    const { error } = email;
    if (error === 'Invalid email' && !isEmailValid(value)) return setEmail({ value, error });
    setEmail({ value });
  };

  const isDesktop = !isMobile;

  const handleLogin = async (e) => {
    e.preventDefault();
    const { value: emailValue, error: emailError } = email;
    if (!emailValue) setEmail({ value: emailValue, error: 'Email is required' });

    const { value: passwordValue, error: passwordError } = password;
    if (!passwordValue) setPassword({ value: passwordValue, error: 'Password is required' });

    if (emailError || passwordError) return setError('Some fields are empty or invalid');
    await login({
      body: { email: emailValue, password: passwordValue, TimeZone },
    }).then(async (loginRes) => {
      if (loginRes?.user?.type.toString() === 'user') {
        localStorage.setItem('user', JSON.stringify(loginRes.token));
        isMobile && await getdiaryData()
        await loadPantryMeals().then((response) => {
          afterLogin(loginRes);
          setLogin(true);
        })
      } else {
        afterLogin(loginRes);
        setLogin(true);
      }
    }).catch((err) => {
      console.log(err);
    })
    localStorage.setItem('steps', 'true');
    window.scrollTo(0, 0);
  };

  console.log(error,'error')
  return (
    <>
     <GilroyFontWrapper>
      <LoginForm >
        <MobileHeader text={"Log in"} onClickLeft={() => { history.goBack() }} />
        <div className='form'>
          <FormGroupStyle>
            <label>Email</label>
            <RegularInput
              type="email"
              name="email"
              placeholder="Email Address"
              value={email.value}
              onChange={(e) => {onChangeEmail(e.target.value, setEmail),setError('')}}
              onBlur={(e) => onBlurEmail(e.target.value, setEmail)}
              errorMessage={ error.length ? ' ' : ''}         
            />
          </FormGroupStyle>
          <FormGroupStyle>
            <label>Password</label>
            <RegularInput
              type="password"
              name="password"
              placeholder="Password"
              icon={!passwordVisible ? '/icons/eye-slash.svg' : '/icons/eye.svg'}
              onSuffixClick={togglePasswordVisibility}
              passwordVisible={passwordVisible}
              value={password.value}
              errorMessage={error}
              onChange={(e) => {setPassword({ value: e.target.value }),setError('')}}
              onBlur={(e) => {
                const { value } = e.target;
                if (!value) setPassword({ value, error: `Password is required` });
              }}
            />
          </FormGroupStyle>
          <div className="forget-password-wrapper">
            <Link to="/forgot-password">Forgot password</Link> <br />
          </div>
          <Button type={'mobile_save'} title="Log in" onClick={handleLogin} disabled={isLoading || isSaving ||isGetMealLoading || !email.value || !password.value}/>
          <div className={'OR'}>or</div>
          <div className='google-login'>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Continue with Google"
            onSuccess={(responseGoogle) => {
              setProfileObj({ responseGoogle });
            }}
            onFailure={(responseGoogle) => {
              console.log({ responseGoogle, fail: true })
            }}
            cookiePolicy={'single_host_origin'}
          />
          </div>
          <text className='Signup-Wrapper'>Don’t have an account?<Link to={isMobile?'/self-onboarding/signin' : '/account/home'} className='signUp'>Sign up</Link></text>

        </div>
        <FooterLink />
      </LoginForm>
      </GilroyFontWrapper>
    </>
  );
}