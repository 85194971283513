import NutritionistQuote from 'components/NutritionistQuote';
import NutrittLogo from 'layout/NutrittLogo';
import styled from 'styled-components';
import PlanCard from './PlanCard';
import { useMemo } from 'react';
import { sort } from 'ramda';
import { useAppContext } from 'context/appContext';
import Landing from 'pages/onboarding/OnboardingPlans';

const Container = styled.div`
  padding: 20px;

  @media (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
`;

const MealCards = styled.div`
  display: grid;
  justify-items: center;
`;

const SelectAPlanStyle = styled.div`
  @media (max-width: 767px) {
    header {
      height: 60px;
      box-shadow: 0 3px 6px #0000001a;
      display: flex;
      align-items: center;
    }

    .select-plan-body {
      padding: 24px 0;
    }

    h4 {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

const justEat = {
  id: 'justEat',
  name: 'I just want to eat healthy',
  image: '/images/sexy_just_eat_now.jpg',
  description:
    'Skip setup and take me to a variety of fresh and healthy food from our local meal providers!',
  duration: 'Immediately',
  justEat: true,
};
export default function SelectAPlan({ nutritionist, selectPlan, setShowDietPlans, showDietPlans }) {
  const { img, dietTemplates = [], name,title,socialProfile} = nutritionist || {};
  const sortedDietTemplates = useMemo(() => {
    return sort((a, b) => a?.name?.localeCompare(b?.name), dietTemplates);
  }, [dietTemplates]);
  const { isMobile } = useAppContext();

  return (
    <>
      {!isMobile ? (
        <SelectAPlanStyle>
          {/* <header>
            <Container>
              <NutrittLogo style={{ padding: '14px 19px' }} />
            </Container>
          </header> */}
          <div className="select-plan-body">
            <Container>
              <NutritionistQuote image={img || '/images/avatar.png'} text="Select a free personalized meal plan" />
              <MealCards>
                <h4>Select one of the plans you like</h4>
                {sortedDietTemplates?.map((plan, index) => {
                  return <PlanCard key={plan.id} selectPlan={selectPlan} plan={plan} />;
                })}
                {/* <div>
                  <hr style={{ color: '#656565', margin: '24px 0' }} />
                  <PlanCard selectPlan={selectPlan} plan={justEat} />
                </div> */}
              </MealCards>
            </Container>
          </div>
        </SelectAPlanStyle>
      ) : (
        <Landing
          dietitianPhoto={img}
          sortedDietTemplates={sortedDietTemplates}
          selectPlan={selectPlan}
          name={name}
          title={title}
          socialProfile={socialProfile}
          setShowDietPlans={setShowDietPlans}
          showDietPlans={showDietPlans}
        />
      )}
    </>
  );
}
