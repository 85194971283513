import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const NewConditionWrapper = styled.div`
  display: flex;
  gap: 9px;
  .select-wrapper {
    width: fit-content;
    margin: 0 !important;
    &.disabledContainer {
      cursor: not-allowed;
    }
    &.displayPlaceholder .selected-value span {
      color: ${theme.colors.grayDarker};
    }
    span.placeholder {
      color: ${theme.colors.lighterGrey};
      font-family: ${theme.typography.fontGilroy};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .selected-value {
      padding: 0 13px 0 14px;
      color: ${theme.colors.primaryDarker};
      font-family: ${theme.typography.fontGilroy};
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      &.selectedValue {
        border-color: ${theme.colors.primaryDarker};
      }
      &.disabled {
        background-color: #f5f7f8;
      }
    }
  }
  .select-label {
    margin: 0;
  }
  input,
  .selected-value {
    width: 204px;
    min-width: 204px;
    height: 44px;
  }
  .options-dropdown {
    position: fixed;
    max-width: 204px;
    max-height: fit-content;
    margin-top: 5px;
    padding: 8px 0;
    background: ${theme.colors.secondary};
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    .select-option {
      height: 40px;
      min-height: 40px;
      padding: 10px 12px;
      color: ${theme.colors.blackDefault};
      font-weight: 400;
      line-height: 20px;
      text-transform: none;
      &:hover {
        background-color: ${theme.colors.background};
      }
    }
    div:empty {
      display: none !important;
    }
  }
  input {
    height: 44px;
    margin: 0;
    padding: 0 13px 0 14px;
    color: ${theme.colors.primaryDefault};
    border-color: ${theme.colors.lightGrey};
    font-size: 14px !important;
    font-weight: 500;
    line-height: 20px;
    &[disabled] {
      background: #f5f7f8 !important;
      cursor: not-allowed;
      &:hover {
        border-color: ${theme.colors.lightGrey} !important;
      }
    }
    &:hover,
    &:focus {
      border-color: ${theme.colors.primaryDefault} !important;
      box-shadow: none;
    }
    &::placeholder {
      color: ${theme.colors.grayDarker};
      font-family: ${theme.typography.fontGilroy};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;
