import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Button = `
  height: 44px;
  color: white !important;
  font-size: 18px;
  font-weight: 700;
  border-radius: 5px;
  text-align: center;
  line-height: 44px;
  display: block;
`;

const Container = styled.div`
  padding: 10px;

  .shop-waitlist-wrapper {
    padding-top: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 700;
    }

    .waitlist {
      width: 50%;
      margin-bottom: 20px;
      background: #52c41a;
      ${Button}
    }
  }
`;
const StyledLink = styled(Link)`
  width: 30%;
  background: #52c41a;
  ${Button}
`;

export default function LandingPage() {
  return (
    <Container>
      <iframe
        src="/nutritt.html"
        width="100%"
        height="100%"
        scro
        style={{
          height: 'calc(100vh - 198px)',
          border: 'none',
          overflow: 'hidden',
        }}
      />
      <div className="shop-waitlist-wrapper">
        <a className="waitlist" href="https://forms.gle/jNRYmxCPU5GkXPhY8" target="_blank">
          JOIN WAITLIST
        </a>
        <p>Already a member?</p>
        <StyledLink to="/login">LOGIN</StyledLink>
      </div>
    </Container>
  );
}
