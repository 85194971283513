import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { MdEdit } from 'react-icons/md';
import RegularInput from 'components/Input/RegularInput';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import Alert from 'components/elements/alert/Alert';
import { useKitchenContext } from 'context/kitchenContext';

const BankAccountModel = styled.div`
  position: relative;
  width: 100%;
  .errorMessage {
    margin-top:4px !important;
    }
    .input-wrapper:has(+ .box .errorMessage) input {
      border-color: #F89B9C;
  }
  input{
    color:#283241;
  }  
  .alert{
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top:12px;
  }
  .btn_flex {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0;
    top: 0;
    .custom_button {
      position: relative;
      &:last-child {
        background: ${({ theme }) => theme?.colors?.primaryDefault};
        color: ${({ theme }) => theme?.colors?.white};
      }
    }
  }
  .main_text {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  .sub_text {
    display: flex;
    height: 16px;
    padding-top: 24px;
    gap: 20px;
    padding-right: 0px;
    flex-shrink: 0;
    flex-direction: column;
  }

  .account-info {
    flex-direction: column;
    display: flex;
    gap: 8px;
    max-width: 560px;
  }
  .account-info span {
    width: 250px;
    height: 16px;
    font-size: 14px;
    gap: 8px;
    padding-right: 0px;
    color: ${({ theme, isEdit }) =>
        isEdit ? theme?.colors?.blackDefault : theme?.colors?.grayDark};
  }
   .value{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  p{
    color: ${({ theme }) => theme?.colors?.blackLight};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  .custom_button {
    padding: ${({ isEdit }) => (!isEdit ? '8px 8px 8px 16px' : '8px 16px')};
    font-size: ${({ theme }) => theme?.typography?.fontSmall};
    font-weight: 600;
    line-height: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
    color: ${({ theme }) => theme?.colors?.primaryDark};
    background: ${({ theme }) => theme?.colors?.white};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
`;

function BankAccount() {
    const [isEdit, setIsEdit] = useState(false);
    const { provider, setProvider, user, isSuper } = useAppContext();
  const { kitchenProvider } = useKitchenContext();
    const [updatedProvider,{isLoading:isProviderUpdating}] = useRequest(`provider/update`, 'post');
    const [isButtonEnabled, setIsButtonEnabled]=useState(true);
  const [bankDetails, setBankDetails] = useState({});
  const [alertData, setAlertData] = useState({
      type: '',
      title: '',
    });
    const [matchAccountNumber, setMatchAccountNumber] = useState(""); 

  const profileEmail = isSuper ? kitchenProvider?.user?.email : user?.email;  

    const maskedAccountNumber =
    provider?.bankAccountNumber
      ? provider?.bankAccountNumber.slice(0, -4).replace(/./g, '*') + provider?.bankAccountNumber.slice(-4)
      : '';
      const enableButton = (data)=>{
        const { value } = data;
        const { bankRoutingNumber, bankAccountNumber } = provider;
        const isMatching =
        bankRoutingNumber === value ||
        bankAccountNumber === value ;
        setIsButtonEnabled(isMatching);
      }  
      const handleChange = (e) => {
        enableButton(e.target)
        const { name, value } = e.target;
        const numericValue = value.replace(/[^0-9]/g, "");
        if (numericValue.length > 20) return;
        setBankDetails((prevState) => ({
            ...prevState,
            [name]: numericValue,
        }));
        setMatchAccountNumber('');
    };

    const handleSave = async (bankDetails) => {
       if(isProviderUpdating) return;
      try {
        if (bankDetails.confirmedAccountNumber !== bankDetails.bankAccountNumber) {
          setMatchAccountNumber('account number doesn’t match');
          return;
        }
        const {confirmedAccountNumber, ...rest} = bankDetails
        const updatedBody = {
          id: provider?.id,
          providerEmail: profileEmail,
          ...rest
        };
    
        await updatedProvider({ body: updatedBody });
        setProvider((prev) => ({
          ...provider,
          ...rest
        }));
        setBankDetails({})
        showSuccessMessage('You have successfully updated bank information');
        setIsEdit(false);
        setIsButtonEnabled(true)
      } catch (error) {
        console.log(error);
        setIsButtonEnabled(true)
        showErrorMessage('Error occurred while saving changes');
      }
    };
    
    const showSuccessMessage = (title) => {
      setAlertData({
        type: 'success',
        title: title,
      });
      setTimeout(() => {
        setAlertData({ type: '', title: '' });
      }, 5000);
    };
    
    const showErrorMessage = (title) => {
      setAlertData({
        type: 'error',
        title: title,
      });
      setTimeout(() => {
        setAlertData({ type: '', title: '' });
      }, 5000);
    };
    
    const handleCancel = () => {
        setBankDetails({})
        setIsEdit(false);
    };

    return (
        <BankAccountModel theme={theme} isEdit={isEdit}>
          {alertData.type && (
        <div className="alert">
          <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', title: '' })} />
        </div>
      )}
            <div>
                <div className="main_text">Bank account</div>
                {!isEdit ? (
                    <Button
                        title="Edit"
                        className="custom_button"
                        onClick={() => setIsEdit(true)}
                        iconRight={<MdEdit />}
                    />
                ) : (
                    <div className="btn_flex">
                        <Button title="Cancel" size={"small"} type={'secondary'} onClick={handleCancel} />
                        <Button title="Save" size={"small"} type={"dark"} onClick={() => handleSave(bankDetails)} isDisabled={isButtonEnabled||isProviderUpdating}/>
                    </div>
                )}{' '}
            </div>
            <div className="sub_text">
                <div className="account-info">
                    <span>Bank routing number</span>
                    {!isEdit ? (
                        <p className="value">{provider?.bankRoutingNumber}</p>
                    ) : (
                        <RegularInput
                            type="text"
                            name="bankRoutingNumber"
                            onChange={handleChange}
                            value={bankDetails.bankRoutingNumber ?? provider?.bankRoutingNumber}
                        />
                    )}
                </div>
                
                <div className="account-info">
                    <span>Bank account number</span>
                    {isEdit ? (
                        <RegularInput
                            updatedProfile
                            value={bankDetails.bankAccountNumber ?? provider?.bankAccountNumber}
                            name="bankAccountNumber"
                            onChange={handleChange}
                        />
                    ) : (
                        <p className="value">{maskedAccountNumber}</p>
                    )}
                </div>
                {isEdit && (
                    <div className="account-info">
                        <span>Confirm account number</span>
                        <RegularInput
                            updatedProfile
                            value={bankDetails.confirmedAccountNumber ?? provider?.bankAccountNumber}
                            name="confirmedAccountNumber"
                            onChange={handleChange}
                            message={matchAccountNumber}
                        />
                    </div>
                )}
            </div>
        </BankAccountModel>
    );
}


export default BankAccount;
