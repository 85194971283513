import classNames from 'classnames';
import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from '../../context/nutritionistContext';
import { createPlanItems } from '../../constants';
import { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

// import '../../components/Nutritionist/style.scss';

const AdminSidebarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  width: 300px;
  padding-top: 3%;
  background-color: #f7f6f7;
  @media (max-height : 600px){
    padding-top:25px;
  }
  .coach{
     padding: 0px 2px 0px 2px;
      display: flex;
   
      cursor: pointer;
      outline: none;

      @media (max-width: 768px) {
        .MuiIconButton-edgeEnd {
        margin-right:-19px;
         
        }
      }
      @media(min-width: 992px){
        .MuiAccordionSummary-conten {
          margin: 0;
        }
        .MuiIconButton-edgeEnd {
          margin-right:-111px;  
        }
      }

      .MuiAccordionSummary-content {
        margin: 0!important;
      }
      .MuiAccordionDetails-root {
        padding: 0px 29px 0;
      }
      .Mui-expanded {
        min-height:0px !important;
      }
  }
     .coach-div{
       margin-left:15px;
       margin-top: 2px;
     }
     .coach:hover{
        border-radius: 10px; 
      }

  .container {
    width: 100% !important;
    height: calc(100vh - 70px);
    padding: 0 29px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nutritionist-info {
      margin-bottom: 15px;
      padding-bottom: 15%;
      border-bottom: 1.5px solid #E0E3E0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

       .admin-img {
        width: 75px;
        height: 75px;
        top: 50px;
        margin-bottom: 10px;
        object-fit: cover;
        border-radius: 75px;
        background:url('/images/green-circle.svg');
        display:flex;
        justify-content:center;
        align-items:center;
      }

      span {
        margin: 5px 0;
        color: #949d9b;
        font-size: 12px;
        line-height: 14px;
        display: block;
      }
    }

    .nutritionist-btns a {
      display: block;
      padding: 9px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .clients-wrapper{
      border-bottom: 1.5px solid #E0E3E0;
      padding-top: 5%;
      padding-bottom: 3%;
    }

    .nut-name {
      color: #0b1d17;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }

    .item-wrapper {
      padding: 4% 8%;
      display: flex;
      align-items: center;
      cursor: pointer;
      outline: none;

      svg {
        width: 22px;
        height: 22px;
        color: #51635d;
      }

      img,
      svg {
        margin-right: 11px;
      }

      &:hover,
      &.active {
        color: #52C41A;
        border-radius: 10px;
      }

      &.sub-menu {
        padding: 6% 0 !important;
      }

      &.have-menu:hover {
        background-color: transparent;
      }

      .content-wrapper {
         overflow: hidden;     
      }

    }
    }
    
    .item-title {
      color: #51635d;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      &.active {
        color: #52C41A;
      }
    }
    }

    .DropArrow{
      padding-left : 96px;
    }

    .logout-wrapper{
      padding: 3% 0;
    }
  }
    
  }

  @media (max-width: 991px) {
    // position: static;
    top:unset !important;
    width: 100%;
    background-color: #F6F5F8;
    top: 52px !important;
    .logout-wrapper{
      padding-bottom:25px;
    }
    .coach-div{
      margin-left: 25px;
    }
    .coach{
      padding: 1% 3% !important;
      #panel1a-header{
        padding:0px;
      }
      img,
      svg {
       margin-right: 7%;
      }
      p {
        margin-left: 17px;
    font-size: 18px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
      }
    }
    .MuiPaper-root{
      background-color:#F6F5F8 !important;
    }
    .MuiDrawer-root .MuiDrawer-paper{
      height:90vh !important;
    }
    .MuiDrawer-root{
     .MuiDrawer-paper{
       height:90vh !important;
       position:fixed;
       bottom:0px !important;
       top:unset !important;

       div {
        padding-top: 41%;
       }
      } 
    }

    .container {
      display:flex;
      justify-content:space-between !important;
      height: calc(100vh - 130px) !important;

      .item-wrapper{
        padding: 5% 3% !important;

        img,
        svg {
         margin-right: 7%;
        }

        @media only screen and (device-width: 375px) {
            padding: 5% 3% !important;
        }
        @media only screen and (device-width: 414px) {
          padding: 5% 3% !important;
      }
      }
      .item-client{
        padding-bottom:20px;
        border-bottom:1px solid #D9D9D9;
      }
      .nutritionist-info {
        margin-bottom: 16px;
      }

      .item-title {
        font-size: 18px !important;
        line-height: 24px !important;
        font-weight: 400 !important;
      }

      .close-icon {
        position: absolute;
        right: 6%;
        top: 87%;
        display: flex;
        cursor: pointer;

        svg {
          font-size: 20px;
        }
      }
    }
  }
  }
`;

export default function AdminSidebar({ closeSidebar, getForm, isAlert }) {
  const { pathname } = useLocation();
  const { logout, isMobile } = useAppContext();
  const history = useHistory();
  const pushLink = (link) => {
      history.push(link);
    window.scroll(0, 0);
    isMobile && closeSidebar(false);
  };
  const [clickedReport, setClickedReport] = useState(false);
  const toggle = (clicked, setClicked) => {
    if (clicked === true) {
      return setClicked(false);
    }
    setClicked(true);
  };

  return (
    <AdminSidebarStyle>
      <div className="container">
            <>
              <div className="nutritionist-data">
                { !isMobile &&
                <div className="nutritionist-info">
                <div className='admin-img'> <img src='/images/big-n.svg'/></div>
                <div className="nut-name">Nutritt</div>
                <span>Admin</span>
              </div>
                }
                <div className="nutritionist-btns">
                <div onClick={() => {
                  pushLink('/admin/users/coach-management')
                 }}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('/coach-management'),
                      })}
                    >
                      <img src={ pathname.includes('/coach-management') ? "/icons/user-square.svg" :"/images/user-square.svg"  } alt="Home Icon"/>
                      <div className={`item-title`} style={pathname.includes('/coach-management') ? {color:'#52C41A'} : {}}>Coaches management</div>
                    </div>
                  </div>
                  <div onClick={() => {
                    pushLink('/admin/users/provider-management')
                    
                  }}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('/provider-management'),
                      })}
                    >
                      <img src={ pathname.includes('/provider-management') ? "/icons/shop.svg" : "/images/shop.svg" } alt="Note Favorite Icon" />
                      <div className={`item-title`} style={pathname.includes('/provider-management') ? {color:'#52C41A'} : {}}>Providers management</div>
                    </div>
                  </div>
                  <>
                  <div onClick={() => {
                    pushLink('/admin/users/order-management')
                   }}>
                  <div
                    className={classNames('item-wrapper', {
                      active: pathname.includes('/order-management'),
                    })}
                  >
                    <img src={pathname.includes('/order-management') ? "/icons/delivery.svg" : "/images/delivery.svg"} alt="Device Message Icon" />
                    <div className={`item-title`} style={pathname.includes('/order-management') ? {color:'#52C41A'} : {}}>Order management</div>
                  </div>
                  </div>
                    <div className="coach">
                    <Accordion
                      expanded={clickedReport}
                      onChange={() => toggle(clickedReport, setClickedReport)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="MuiAccordionSummary-content"
                      >
                        <div>
                          {' '}
                          <img src={clickedReport ? "/images/disbursement.svg" : "/images/disbursement-black.svg"} alt="Chart Icon" />
                        </div>

                        <div className="coach-div ">
                          {' '}
                          <div className={`item-title`} style={{ color: clickedReport ? ' var(--nutritt-green)' : '#51635D' }}>Disbursements</div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="">
                          <div
                            onClick={() => {
                              pushLink(`/admin/users/coaches`)
                            }}
                          >
                            <div
                              className={classNames('item-wrapper sub-menu', {
                                active: pathname.includes('/coaches'),
                              })}
                            >
                              <img src={pathname.includes('/coaches')?"/images/weight.svg": "/images/weight-black.svg"} alt="Moniter Icon" />
                              <div className={`item-title`} style={pathname.includes('/coaches')? {color: "var(--nutritt-green)"}: {}}>Coaches</div>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              pushLink(`/admin/users/providers`)
                            }}
                          >
                            <div
                              className={classNames('item-wrapper sub-menu', {
                                active: pathname.includes('/providers'),
                              })}
                            >
                              <img src={pathname.includes('/providers')?"/images/building3.svg":"/images/building-black.svg"} alt="Profile User Icon" />
                              <div className={`item-title`} style={pathname.includes('/providers')?{color: "var(--nutritt-green)"}: {}}>Providers</div>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div onClick={() => {
                    pushLink('/admin/users/promocodes')
                    }}>
                  <div
                    className={classNames('item-wrapper', {
                      active: pathname.includes('/promocodes'),
                    })}
                  >
                    <img src={pathname.includes('/promocodes') ? "/icons/promocode.svg" : "/images/promocode.svg" } alt="Profile User Icon" />
                    <div className={`item-title`} style={pathname.includes('/promocodes') ? {color:'#52C41A'} : {}}>Promo codes</div>
                  </div>
                  </div>

                  <div onClick={() => {
                    pushLink('/admin/users/user-management')
                   }}>
                  <div
                    className={classNames('item-wrapper', {
                      active: pathname.includes('/user-management'),
                    })}
                  >
                    <img src={pathname.includes('/user-management') ? "/images/profile-2user.svg" : "/icons/profile-2user.svg"} alt="Profile Icon" />
                    <div className={`item-title`} style={pathname.includes('/user-management') ? {color:'#52C41A'} : {}}>User management</div>
                  </div>
                  </div>
                  {!isMobile && <div className="clients-wrapper"/>}
                  </>
                 
                </div>
              </div>
              <div className="logout-wrapper">
                <Link to="/logout" onClick={logout}>
                  <div className="item-wrapper">
                    <img src="/icons/logout.svg" alt="Logout Icon" />
                    <div className="item-title">Log out</div>
                  </div>
                </Link>
              </div>
            </>
      </div>
    </AdminSidebarStyle>
  );
}

