import styled from 'styled-components';
import CheckoutHeader from 'components/shop/Checkout/CheckoutHeader';
import VisitorHeader from './VisitorHeader';
import NutritionistList from './NutritionistList';
import { useAppContext } from 'context/appContext';
import { useEffect, useState } from 'react';
import NutritionistCoachingInvite from 'pages/Nutritionist/NutritionistCoachingInvite';
import Progress from 'components/Progress';
import PantryFooter from 'components/Pantry/PantryFooter';

const MyCoachStyle = styled.div`
 min-height:95vh;
 font-family: 'Roboto';
 font-style: normal;
 padding-bottom:70px;
 .header-coach{
    display: flex;
    justify-content: space-between;
    padding-right:14px;
    .header{
        width: 75%;
        display: flex;
        justify-content: flex-end;
        margin-top:48px;
    }
 }
 .find-coach{
    background:#ffffff;
    width: 220px;
    height: 125px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-top: 14px;
    .coach{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #51635D;    
    }
    .change{
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #AFB5B4;
        margin: 8px 0 10px;
    }
    button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 156px;
        height: 38px;
        background: #52C41A;
        border-radius: 21px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        cursor:pointer;
        &:hover {
            background: #cbedba;
            color: #51635d;
            transition: all 0.3s ease-in-out;
          }
    }
 }
 .Search{
    display: flex;
    justify-content: center;
    margin: 10px 0 40px;
    .input-img{
        width: 50%;
        border-bottom: 1px solid #E0E3E0;
        height: 44px;
        display: flex;
        align-items: center;
    input{
        background:none;
        border:none;
        width:97%;
        ::placeholder{
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #51635D;
        }
    }
  }
 }

 @media(max-width:992px){
    padding-bottom:100px !important;
  .header-coach{
     display:flex;
     justify-content:center !important;
     padding-right:0 !important;

     .header{
        margin-top:0 !important;
        width:100% !important;
        display:flex;
        justify-content:center !important;
        .description{
            margin-top:5px !important;
            width:95% !important;
        }
     }
    }
 .Search{
     margin:0px 0 20px !important;
    .input-img{
        width:75% !important;
    }
 }
 .invite-div{
    display:flex;
    justify-content:center;
    margin-bottom:20px;
    img{
        height:11px;
    }
 }
 }

 @media (min-width:500px) and (max-width:991px){
    .header-coach{
  .header{
    .description{
        width:70% !important;
    }
  }
 }
}

@media (min-width:390px) and (max-width:415px){
    .header-coach{
  .header{
    .description{
        width:85% !important;
    }
  }
 }
}
   
  @media (min-width:350px) and (max-width:370px){
    .header-coach{
        .header{
          .description{
              width:92% !important;
          }
        }
       }
  }
  @media (min-width:280px) and (max-width:300px){
    .header-coach{
        .header{
          .description{
              width:100% !important;
          }
        }
       }
  }

@media (min-width:992px) and (max-width:1100px){
    .header{
        .description{
            width:460px !important;
            line-height: 20px;
        }
    }
}
@media (min-width:1100px) and (max-width:1300px){
    .header{
        .description{
            width:560px !important;
            line-height: 20px;
        }
    }
}
@media (min-width:1300px) and (max-width:1450px){
    .header{
        .description{
            width:660px !important;
            line-height: 20px;
        }
    }
}
@media (min-width:1450px) and (max-width:1600px){
    .header{
        .description{
            width:760px !important;
        }
    }
}
@media (min-width:1600px) and (max-width:1850px){
    .header{
        .description{
            width:860px !important;
        }
    }
}
@media (min-width:1850px) and (max-width:2000px){
    .header{
        .description{
            width:960px !important;
        }
    }
}
@media (min-width:2000px) and (max-width:2250px){
    .header{
        .description{
            width:1060px !important;
        }
    }
}
@media (min-width:2250px) and (max-width:2400px){
    .header{
        .description{
            width:1160px !important;
        }
    }
}
@media (min-width:2400px) and (max-width:2700px){
    .header{
        .description{
            width:1260px !important;
        }
    }
}
@media (min-width:2700px){
    .header{
        .description{
            width:1300px !important;
        }
    }
}
@media (min-width:1920px){
   .input-img{
     width:30% !important;
   }
}
`;
export default function MyCoach() {

    const { isMobile, allNutritionist } = useAppContext();

    const [isModal, setIsModal] = useState(false)

    const [value, setValue] = useState('');
    const [isRemove, setIsRemove] = useState(false);
    const [searchedNutritionist, setSearchedNutritionist] = useState(allNutritionist);

    const handleChange = (e) => {
        setValue(e.target.value);
        e.target.value ? setIsRemove(true) : setIsRemove(false);
        const NutritionistClone = Array.from(allNutritionist);
        setSearchedNutritionist(
            NutritionistClone.filter(
                (item) =>
                    item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1,
            ),
        );
    };


    return (
        <>
        <MyCoachStyle>
            <div className='header-coach'>
                <div className='header'>
                    <VisitorHeader path={``} title={isMobile ? "Find the right coach for you" : "Discover your coach"} description="Discover your ideal coach and follow their meal plan or book a session with them" />

                </div>
                {isMobile ? ' '
                    :
                    <div className='find-coach'>
                        <p className='coach'>Can’t find your coach?</p>
                        <p className='change'>No need to change coach</p>
                        <button onClick={() => { setIsModal(true) }}>
                            INVITE YOUR COACH
                        </button>
                    </div>}
            </div>
            <div className='Search'>
                <div className='input-img'>
                    <input type='text' placeholder='Find popular coaches' onChange={handleChange} value={value} />
                    <span><img src='/icons/search-icon.svg' alt='search' /></span>
                </div>
            </div>
            {isMobile && <div className='invite-div'>
                <img onClick={() => { setIsModal(true) }} src='/icons/invite-coach.svg' alt='search' />
            </div>}

            {allNutritionist?.length === 0 ? <Progress /> : <div className='all-list'>
                <NutritionistList searchedNutritionist={searchedNutritionist} setSearchedNutritionist={setSearchedNutritionist} value={value} />
            </div>
            }
            {isModal && <NutritionistCoachingInvite setIsModal={setIsModal} isModal={isModal} />}
        </MyCoachStyle>
         { isMobile &&
          <div style={{position:'fixed', bottom:'0',width:'100%'}}><PantryFooter shop={false} myCoach={true} progress={false}/>
          </div>   }
         </>
    )
}