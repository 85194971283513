import { DEFAULT_PROVIDER_SCHEDULE } from '../../constants';
import { useEffect, useState } from 'react';
import ScheduleWeek from './ScheduleItem';
import UpdateProviderButton from './UpdateProviderButton';

export default function Schedule({ provider, setProvider }) {
  const [schedule, setSchedule] = useState(DEFAULT_PROVIDER_SCHEDULE);

  useEffect(() => {
    provider?.schedule && setSchedule(provider.schedule);
  }, [provider?.schedule]);

  if (!provider) return 'Loading...';

const businessprofileId = provider?.BusinessProfile[0]?.id;

  function splitSchedule(schedule) {
    const deliverySchedule = {};
    const pickupSchedule = {};

    for (const dayOfWeek in schedule) {
      const daySchedule = schedule[dayOfWeek];

      if (daySchedule.delivery) {
        deliverySchedule[dayOfWeek] = daySchedule.delivery;
      }

      if (daySchedule.pickup) {
        pickupSchedule[dayOfWeek] = daySchedule.pickup;
      }
    }
    return {
      deliverySchedule,
      pickupSchedule
    };
  };

  const { deliverySchedule, pickupSchedule } = splitSchedule(schedule);

  return (
    <>
      <ScheduleWeek schedule={schedule} setSchedule={setSchedule} />

      <UpdateProviderButton
        label={'Update Schedule'}
        body={{ id: businessprofileId, deliverySchedule, pickupSchedule }}
        path="provider/update_schedule"
        method={"put"}
        successCb={()=>{
            setProvider({...provider, schedule});
        }}
      />
    </>
  );
}


