import React from 'react';
import Bummer from 'components/shop/FavoriteKitchen/Bummer';
import styled from 'styled-components';

const DeliveryNotAvailableStyled = styled.div`
  .bummer__style {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
  }
  .image-container {
    margin-top: 0;
    svg {
      width: 126px;
      height: 126px;
    }
  }
  .title {
    margin-top: 25px;
  }
  .desc{
    margin: 0 auto !important;
  }
`;

const DeliveryNotAvailable = () => {
  return (
    <DeliveryNotAvailableStyled>
      <Bummer
        className={'bummer__style'}
        showCross={false}
        showButtons={false}
        dangerousTitle={'Sorry! <br/> We’re not there yet😞'}
        subTitle={
          "We're working hard to expand our area. However, we're not in this location yet. So sorry about this! Check back with us soon"
        }
      />
    </DeliveryNotAvailableStyled>
  );
};

export default DeliveryNotAvailable;
