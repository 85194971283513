const { useRequest } = require('hooks/useRequest');
const { useState, useRef } = require('react');
import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import { ToastContainer, toast } from 'react-toastify';

const ClaimAccount = ({ providerId }) => {
  const [email, setEmail] = useState('');
  const [inviteProvider, { isLoading, error, state: success, setState: setSuccess }] = useRequest(
    `provider/invite`,
    'post',
  );
  const toastId = useRef(null);
  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await inviteProvider({ body: { email, providerId } });
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(`Successfully sent invite to ${email}`);
      }
    } catch (error) {
      console.log(error)
    }

  };
  return (
    <div>
      <form onSubmit={handleInviteSubmit}>
        <h1>Invite provider</h1>
        <Input
          type="email"
          label="Email address"
          required
          bordered
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus={true}
        />
        <SubmitButton
          disabled={isLoading || !email}
          style={{ marginTop: '10px', marginBottom:'20px' }}
          type="submit"
          error={error}
        >
          {isLoading ? 'Sending' : 'Send Invite'}
        </SubmitButton>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ClaimAccount;
