import styled from 'styled-components';
import SendChatButton from './SendChatButton';
import Chats from './Chats';
import { useAppContext } from 'context/appContext';
import { useState, useEffect } from 'react';
import { ChatLoading } from './ChatLoading';

const ChatScreenFlex = styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const ChatHeader = styled.div`

    padding: 10px 14px;
    border-bottom: 1px solid rgba(224, 227, 224, 0.4);
    column-gap: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;

    .profile__icon{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: block;
    }

    .name{
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #51635D;
        flex: 1;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .more{
        width: 24px;
        height: 24px;
        padding: 3px;
        display: inline-flex;

        img{
            width: 100%;
        }
    }

`

const ChatError = styled.div`
    text-align: center;
    margin: auto;
    padding: 20px;
    color: #949D9B;
    font-weight: 500;
    font-size: 18px;
    .error{
        text-transform: capitalize;
    }
`;

export default function ChatScreen({ setShowChatScreen, followerProfile, nutritionist, setShowPantryFooter, setShowChatAnim, setBgAnim, bgAnim }) {
    const { user, isTwilioConnectionError, chatsLoading, twilioError } = useAppContext();
    const [btnHeight, setBtnHeight] = useState('');
    const [autoScroll, setAutoScroll] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.body.classList.add('hide-scroll');
        if(chatsLoading) return;
        setLoading(false);
        return () => {
            setLoading(true);
            document.body.classList.remove('hide-scroll')
        }
    }, [chatsLoading])

    return (
        <ChatScreenFlex>
            <ChatHeader>
                {(user.type.includes('nutritionist') || followerProfile != null) &&
                    <img src='/icons/angle-left-green.svg' onClick={() => {setBgAnim({...bgAnim, close: true, open: false}); setShowChatAnim(false); setTimeout(()=>{setShowChatScreen(false)}, 300) }} />}

                <img src={followerProfile?.img || nutritionist?.img || '/images/man.svg'} className='profile__icon' />
                <h2 className='name'>{followerProfile?.name || nutritionist?.name}</h2>
            </ChatHeader>
            {(chatsLoading) ? 
                <ChatLoading loadingText={`Chat loading`}/>
                : (
                    (!isTwilioConnectionError) ?
                        <>
                        <Chats autoScroll={autoScroll} btnHeight={btnHeight} user={user} followerProfile={followerProfile} nutritionist={nutritionist} />
                        {!loading && <SendChatButton autoScroll={autoScroll} setAutoScroll={setAutoScroll} setBtnHeight={setBtnHeight} setShowPantryFooter={setShowPantryFooter} followerProfile={followerProfile} user={user}/>}
                        </> : 
                        <ChatLoading headphone={true} loadingText={`Connection Error`} loadingSubtext={twilioError || 'Try reconnect to the internet or reload the app, if still not fixed please contact support.'}/>
                )
            }
            
        </ChatScreenFlex>
    )
} 
