import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import NutritionistCoachingInvite from './NutritionistCoachingInvite';
import 'rc-tooltip/assets/bootstrap.css';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from '../../context/nutritionistContext';

const NutritionistCoachingStyle = styled.div`
  @media screen and (max-width: 767px){
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%; 
    margin-bottom: 24px;
  }
  .upcoming {
    .upcoming-head{
      @media screen and (max-width: 767px){
        height: 60px; 
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #d9d9d9;
      }
      p{
        @media screen and (max-width: 767px){
          font-size:18px;
          font-weight: 700;
        }
      }
    }
    .MuiTabs-flexContainer {
      justify-content: center;
      .MuiTab-root {
        padding: 0px !important;
        width: 147.5px;
      }
    }
    .session {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #52c41a;
      margin: 20px 0px 0px 20px;
      cursor:pointer;
      @media screen and (max-width: 767px){
        font-size: 14px;
        margin: 13px 20px;
        u{
          text-decoration:none;
        }
      }
    }
  }
  .empty-state {
    padding: 0px 20px;
    min-height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px){
      min-height: auto;
      max-height: initial;
      padding: 25px 20px;
    }
    .profile-image {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .no-clients {
      margin-top: 23px;
      display: flex;
      justify-content: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #51635d;
    }
    .invite-client {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #51635d;
    }
    .nutritionist-email {
      margin-top: 24px;
      display: block;
      position: relative;
      input {
        color:#51635D;
        border: 1px solid #E0E3E0;
        border-radius: 6px;
        width:100%;
        height:44px;
        padding-left:14px;
        background-position: 275px; 
        &.copied {
          background-color: #52c41a33;
        }
      }
      .copy-icon{
        position: absolute;
        right: 13px;
        top: 13px;
      }
      .copied-text{
        position: absolute;
        bottom: 42%;
        @media screen and (max-width: 767px){
          right: 0;
          bottom: -20px;
          color: #51635D;
          font-size: 14px;
        }
      }
    }
  }
  
  .upcoming-list {
    .client-card{
      padding-top:16px;
      padding-bottom:16px;
    }
  }

  .empty-state {
    .profile-image {
      margin-top: 35% !important;
    }
    .no-clients {
      margin-top: 25px !important;
      font-size: 16px !important;
      line-height: 22px !important;
    }
    .invite-client {
      margin-top: 12px !important;
      font-size: 16px !important;
      line-height: 19px !important;
    }
    .nutritionist-email {
      margin-top: 24px !important;
      width: 100%;
    }
    .copy-url {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      width: 100%;
      button {
        width: 100%;
        height: 42px;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #97dc76 !important;
        border-radius: 21px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #51635d !important;
        cursor:pointer;
        &:hover {
          background: #cbedba;
          color: #51635d;
          transition: all 0.3s ease-in-out;
        }
      }
      .copy-active {
        background: #52c41a33!important;
        border: 1px solid #97dc76!important;
        border-radius: 21px;
        font-size: 14px;
        line-height: 16px;
    }
  }
`;
const TooltipStyle = styled.div`
  .rc-tooltip {
    position: absolute;
    left: 0px;
    top: 432px !important;
  }
`;
{
  TabPanel;
}


export default function NutritionistCoaching({ nutritionistMeeting, setIsModal, isModal }) {
  const [openForm, setOpenForm] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { isMobile, user } = useAppContext();
  const history = useHistory();
  const {nutritionist} = useNutritionistContext();
  const URL_ROOT = `${window.location.origin}/plan/`;
  const [nutritionistMeetingList, setNutritionistMeetingList] = useState(false);
  nutritionistMeetingList && window.scroll(0, 0);

  const sortedNutritionistMeeting = nutritionistMeeting?.sort((a, b) => {
    return (
      new Date(a.NutritionistMeetingMapping[0]?.scheduledDateTime) -
      new Date(b.NutritionistMeetingMapping[0]?.scheduledDateTime)
    );
  });
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [isCopied]);
  console.log(sortedNutritionistMeeting.length)
  return (
    <NutritionistCoachingStyle>
      <div className={!sortedNutritionistMeeting?.length == 0 ? "upcoming pb-0" : "upcoming"}>
        <div className="upcoming-head">
          <p className="">Upcoming Coaching</p>
        { !isMobile &&  <p className='follower-number'>{sortedNutritionistMeeting?.length}</p>}
        </div>
        {!sortedNutritionistMeeting?.length == 0 ? ( 
          <div>
            <div className="upcoming-list">
              {sortedNutritionistMeeting?.map((item, index) => (
                <CoachingData key={index} nutritionistMeeting={item} />
              ))}
            </div>
            <div className="session">
              <span> 
                {isMobile ?
                  <u onClick={() => history.push('/nutritionist/coaching/clients')}>{nutritionistMeeting.length > 5 ? 'See all Sessions' : 'See all Sessions'}</u>
                  :
                  <u onClick={() => history.push('/nutritionist/coaching/clients')}>See all Sessions</u>
                }
              </span>
            </div>
          </div>
        ) : (
          <div className="empty-state">
            <div className="profile-image">
              <img src="/icons/Avatar.svg" alt="profile image" />
            </div>
            {/* <div className="no-clients">
              <p>There are no clients in your schedule</p>
            </div> */}
            <div className="invite-client">
              {isMobile ? (
                <p>
                  Start invite clients by sending them <br /> your invite link
                </p>
              ) : (
                <p>Start invite clients by sending them your invite link</p>
              )}
            </div>

            <div className='nutritionist-email'>
              <input
                type="text"
                value={(URL_ROOT + nutritionist?.nutritionist?.url).slice(0, 33) + "..."}
                id="fname"
                placeholder='Nutritionist Email'
                name="fname"
                className={isCopied && "copied"}
              />

              <span
                className="copy-icon"
                onClick={() => {
                  navigator.clipboard.writeText(URL_ROOT + nutritionist?.nutritionist?.url);
                  setIsCopied(true);
                }}
              >
                <img src='/icons/copy.svg' />
              </span>
              {isCopied && isMobile && <span className="copied-text">Copied</span>}
            </div>


            {!isMobile && (
              <div className="copy-url">
                <button className={isCopied && 'copy-active'} onClick={() => {
                  navigator.clipboard.writeText(URL_ROOT + nutritionist?.nutritionist?.url);
                  setIsCopied(true);
                }}>
                  <p>{isCopied ? "Copied" : "Copy URL"}</p>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {isModal && <NutritionistCoachingInvite setIsModal={setIsModal} isModal={isModal} />}
    </NutritionistCoachingStyle>
  );
}

const CoachingDataStyle = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin:0 14px;
border-bottom: 1px solid #d9d9d9;
@media screen and (min-width:1441px) and (max-width: 1920px){
  margin:0 24px;
}
@media screen and (max-width: 767px){
  margin:0 20px;
  .client-info {
    margin-left:12px !important;
  }
}
.client-card {
  display: flex;
  width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
  img{
    width: 40px;
    height: 40px;
    border-radius:50%;
    @media screen and (min-width:1441px) and (max-width: 1920px){
      width: 64px;
      height: 64px;
    }
  }

  .client-info {
    width: calc(100% - 60px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 20px;
    row-gap: 5px;
    @media screen and (min-width:1441px) and (max-width: 1920px){
      width: calc(100% - 84px);
    }
    .date-time {
      p {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #51635d;
      }
    }
    .client-name {
      p {
        overflow: hidden;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        color:  #51635D;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
`;
export function CoachingData({ nutritionistMeeting }) {
  const { NutritionistMeetingMapping, user } = nutritionistMeeting;
  let favorite ;

  const profileImg = user?.profile?.img ? user.profile.img : '/images/avatar.png';
  const scheduledDateTime = NutritionistMeetingMapping[0]?.scheduledDateTime;
  if(user && user?.UserSubscribedDietPlan){
    favorite=user?.UserSubscribedDietPlan[0]?.isFavorite
  }
  return (
    <CoachingDataStyle>
      <div className="client-card">
        <img
          src={profileImg}
          alt="ellipse-271"
        />
        <div className="client-info">
        <div className="client-name">
            <p>{user?.profile?.name}</p>
          </div>
          <div className="date-time">
            <p>
              {moment(scheduledDateTime).format('ll').split(',')[0]} at{' '}
              {moment(scheduledDateTime).format('LT')}
            </p>
          </div>
        </div>
      </div>
      <div className='star-Icon'>
        {/* <img src="/icons/Group 3688.svg"/> */}
        <img src={favorite?"/icons/star_golden.svg": "/icons/star_grey.svg"}/>
      </div>
    </CoachingDataStyle>
  );
}


