import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { useAppContext } from 'context/appContext';

const PlanListHeaderStyle = styled.div`
  width: 100% !important;
  padding: 20px 15px 26px 15px !important;
  .header {
    // margin-bottom: 15px;
    margin-top :0%
    .title-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      margin-bottom: 5px;
      color: #0b1d17;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      text-align: center;
    }

    .description {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      color: #949d9b;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      justify-content: center
    }
  }

  @media (max-width: 991px) {
    padding: 0 0 24px 0 !important;
  }
`;

export default function PlanListHeader({ planTitle, planDescription }) {
  const history = useHistory();
  const {user} = useAppContext();
  return (
    <PlanListHeaderStyle>
      <div className="header">
        <div className="title-wrapper">
          {user ?
          <BackButton perviousLink="/shop/my-coach" /> : 
          <BackButton/>
          }
          <h2>{planTitle}</h2>
        </div>
        <div className="description">{planDescription}</div>
      </div>
    </PlanListHeaderStyle>
  );
}
