import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const OrderItemWrapper = styled.div`
  height: 65px;
  display: flex;
  justify-content: space-between;

  .order-image-title {
    padding-left: 6px;
    display: flex;
    gap: 11px;
  }

  .order-img-wrapper {
    position: relative;
    img {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      object-fit: cover;
    }
    .qty-wrapper {
      position: absolute;
      bottom: 4px;
      left: -6px;
      width: 20px;
      height: 20px;
      background-color: ${theme.colors.grayLighter};
      color: ${theme.colors.primaryDefault};
      border: 1px solid ${theme.colors.grayLight};
      border-radius: 10px;
      font-family: ${theme.typography.fontRoboto};
      font-size: 8px;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .order-name {
    max-width: 197px;
    margin-top: 15px;
    color: ${theme.colors.primaryDefault};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .order-price {
    margin-top: 15px;
    color: ${theme.colors.blackDefault};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;
