import React from "react";
import styled from "styled-components";
import { BREAKDOWN } from '../../../../constants';
import Select from 'components/Select';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { useState } from "react";
import { useEffect } from "react";



const Mesurment = styled.div`
.intensity{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #51635D;
    padding-top:25px;
}
.intensity-progress{
    vertical-align: middle;
    width: 100%;
    border-radius: 5px;
    div{
      margin-bottom:0px;
    }
}
.intensive-progressBar {
    margin-top: 8px;
    span:nth-child(1){
      height:10px;
    }
    span:nth-child(2){
      height:10px !important;
      color:#52C41A; 
      transition: 0.1s all;
    }
    span:nth-child(3){
      color:white;
      transition: 0.1s all;
    }
  }
  .card-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
}  
.Column {
    display: table-cell;
} 
.card-price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #949D9B;
  }
  .progress-Advance{
    height: 32px;
    left: 44px;
    right: 192px;
    top: 308px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #52C41A;
    margin-bottom: 14px;
    margin-top: 14px;
}
.card-amount{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    border: 1px solid #d7d7d7;
    height: 45px;
    padding: 0 15px;
    border-radius: 6px;
  }
  .amount{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #949D9B;
}

  .input-amount{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #949D9B;
    border: none;
    height: 43px;
  }
  .input-amount:focus{
    border:none;
  }
`

const MeasurementSystem = ({challenge_goal,intensity,setIntensity,targetCalories,form,setForm,SetCounter,counter,show,setshow})=>{

  const [formgoal , setFormgoal] = useState(form?.goal);
  useEffect(()=>{
    setFormgoal(Math.abs(form?.goal))
  },[form?.goal])

    return (
        <>
        <Mesurment>
        {form.type !== 'maintain' && form.type !== '' ? <>
              <div className='intensity'>Intensity</div>
              <div className='intensity-progress'>
                {
                  form.type === 'bulkup' ? <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <Slider defaultValue={0}  aria-label="Volume"  value={ form.goal_type === "percent" || form.goal_type === "number" ? formgoal : 0} min={0} max={form.goal_type === "number" ? "1500" : "40"} className='intensive-progressBar'                                       
                     onChange={(e) => {
                      setFormgoal(Number(e.target.value));
                      setForm({ ...form, goal: form.type === 'lose-weight' ?
                        -1 * Math.abs(Number(e.target.value)) : Number(e.target.value)})
                      }}
                    />
                  </Stack> : <></>
                }
                {
                  form.type === 'lose-weight' ? <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <Slider defaultValue={0} aria-label="Volume" value={form.goal_type === "percent" || form.goal_type === "number" ? formgoal : 0} min={0} max={form.goal_type === "number" ? "1500" : "40"} className='intensive-progressBar' 
                      onChange={(e) => {
                        setFormgoal(Number(e.target.value));
                        setForm({ ...form, goal: form.type === 'lose-weight' ?
                          -1 * Math.abs(Number(e.target.value)) : Number(e.target.value)})
                        }} />
                  </Stack> : <></>
                }
                {form.type === '' ? <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                  <Slider defaultValue={0} aria-label="Volume" value={form.goal_type === "percent" || form.goal_type === "number" ? form.goal : 0} min={0} max={form.goal_type === "number" ? "1500" : "40"} className='intensive-progressBar'  
                    onChange={(e) => {
                      setFormgoal(Number(e.target.value));
                      setForm({ ...form, goal: form.type === 'lose-weight' ?
                        -1 * Math.abs(Number(e.target.value)) : Number(e.target.value)})
                      }} />
                </Stack> : <></>
                }
              </div>
              <div class="Row card-detail">
                <div class="Column">
                  <h5 className="card-price">Easy</h5>
                </div>
                <div class="Column">
                  <h5 className="card-price">Moderate</h5>
                </div>
                <div class="Column">
                  <h5 className="card-price">Extreme</h5></div>
              </div>
            </> : <></>}
            {
              form.type !== 'maintain' && form.type !== '' ?
              <div className='progress-Advance' onClick={() => setshow(!show)}>
                {show ? '-' : '+'} Advance
              </div>
              : <></>
            }
            {
              show ?
                <div>
                  <div className='MacroBreakdown'>Measurement system</div>
                  <Select
                    key="BMR"
                    options={BREAKDOWN}
                    updateValue={(e) => setForm({ ...form, goal_type: e, goal : 0 })}
                    defaultSelected={form?.goal_type}
                  />
                  <div className='MacroBreakdown'>Customize Target Calories</div>
                  <div class="Row card-amount">
                    <div class="Column" onClick={() => {
                        if(form.goal_type == "number" && form.goal > 10){
                          setForm({ ...form, goal: Number(form.goal) - 10 })
                        } else if(form.goal_type == "percent" && form.goal > 0 ){
                          setForm({ ...form, goal: Number(form.goal) - 1 })
                        }
                       }}>
                      <div className="amount"                
                      >-</div>
                    </div>
                    <div class="Column">
                      <input className="input-amount" 
                      maxLength={form.goal_type === "percent" ? form.type === 'lose-weight' ? 3 : 2 : form.type === 'lose-weight' ? 5 : 4}
                      value={form.goal ? form.goal : 0 }
                      onChange={(e)=>{
                        if(form.type !== 'lose-weight' && form.goal_type == "number" && Number(e.target.value) <= 1500 || 
                          form.type === 'lose-weight' && form.goal_type == "number" && Number(e.target.value) >= -1500 || 
                          form.type !== 'lose-weight' && form.goal_type == "percent" && Number(e.target.value) <= 40 || 
                          form.type === 'lose-weight' && form.goal_type == "percent" && Number(e.target.value) >= -40
                          ) {
                          setForm({ ...form, goal: form.type === 'lose-weight' ? -1 * Math.abs(Number(e.target.value)) 
                          : Number(e.target.value)})
                        }
                      }}
                      />
                    </div>

                    <div class="Column" onClick={() => {
                        if(form.goal_type === "number" && form.goal < 1490){
                          setForm({ ...form, goal: Number(form.goal) + 10 })
                        } else if(form.goal_type == "percent" && form.goal < 40 ){
                          setForm({ ...form, goal: Number(form.goal) + 1 })
                        }
                      }
                        }>
                      <div className="amount"
                      >+</div></div>
                  </div>
                </div>
                : ""
            }
        </Mesurment>
        </>
    )
}

export default MeasurementSystem;