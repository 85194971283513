import styled from 'styled-components';
import { useNutritionistContext } from '../../context/nutritionistContext';
import { useState,useEffect,useCallback} from 'react';
import classNames from 'classnames';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const InviteModal = styled.div`
    position: fixed;
    inset: 0px;
    z-index: 111;
    background-color: rgb(81, 99,93, 0.65);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    //padding-top:204px;
   
 .invite-box{
    position: fixed;
    width:355px;
    padding-bottom:24px;
    background:#FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    @media(max-width:991px){
       bottom:10px;
    }
    .close-img-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        img{
         cursor:pointer;
        }
    }
    .send-invite{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #0B1D17;
        p{
         padding-left:24px; 
        }  
    }
    .client-email{
         margin-top: 24px;
         padding:0 24px;   
         display: flex;
         align-items: center;
         gap: 10px;
         input{
            color:#949D9B;
            -webkit-text-fill-color:#949D9B;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            width: 100%;
            height:44px;
            padding-left:14px;
            font-size:14px !important;
            
         }
         button:disabled {
            background-color: #949d9b !important;
            cursor: not-allowed;
          }
         button{
            background: #52C41A;
            border-radius: 21px;
            width: 80px;
            Min-width: 80px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items:center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
            cursor:pointer;
            &:hover {
               background: #cbedba;
               color: #51635d;
               transition: all 0.3s ease-in-out;
             }
         }
    }
    .nutritionist-profile{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #51635D;
        padding:24px;      
    }
    .nutritionist-email{
        display: flex;
        justify-content: center;
        input{
            color:rgb(81,99,93) !important;
            -webkit-text-fill-color:rgb(81,99,93) !important;
            opacity: 1;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            width:87%;
            height:44px;
            padding-left:14px;
            font-size: 14px !important;
            &.copied {
               background-color: #52c41a33;
             }
         }
         .copy-icon {
            position: absolute;
            right: 40px;
            top: 206px;
            color: #52c41a;
            cursor: pointer;
          }
          .copied-text {
            position: absolute;
            bottom: 10px;
            right: 5px;
            color: #52c41a;
          }       
    }
    .social-icon{
         border-top:1px solid #D9D9D9;
         margin-top:24px;
         height:100px;
         display:flex;
         justify-content: space-around;
         align-items:center;
         .facebook-icon{ 
         img{
            width:48px;
            height:48px;
         }
        }
        .tiktok-icon{ 
            img{
               width:48px;
               height:48px;
            }
        }
        .instagram-icon{ 
            img{
               width:48px;
               height:48px;
            }
           } 
    }
 }
 @media(min-width:992px){
   padding-top:0px !important;
   .invite-box{
      width:570px !important;
      padding-bottom:35px;
      .send-invite{
         padding-bottom: 11px !important;
         border-bottom: 1px solid #F6F5F8;
         margin-bottom: 24px;
         padding-left: 20px;
         p{
            padding-left:0px !important;
         }
      }
      .client{
         font-family: 'Roboto';
         font-style: normal;
         font-weight: 700;
         font-size: 12px;
         line-height: 14px;
         display: flex;
         align-items: center;
         color: #51635D;
         padding:12px 37px 10px;
      }
      .client-email{
      display: grid;
        grid-template-columns: 64% auto !important;
        padding:0 37px !important;
        margin-top:0px !important;   
         input{
            width:310px !important;
            
         }
         button{
            height:42px !important;
            width:150px !important;
            margin-left:29px !important;
         }
      }
      .nutritionist-profile{
         padding: 24px 37px 10px !important;
      }
      .nutritionist{
         display: flex;
         justify-content: space-evenly;
         margin: 0 37px;
         gap: 33px !important;
      .nutritionist-email{
         display: flex;
         justify-content: center;
         input{
            color:rgb(81,99,93) !important;
            -webkit-text-fill-color:rgb(81,99,93) !important;
            opacity: 1;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            width:307px;
            height:44px;
            padding-left:14px;
            font-size: 14px !important;
            &.copied {
               background-color: #52c41a33;
             }
         }

         .copy-icon{
            position:absolute;
            right:244px !important;
            top:240px !important;
         }
         .copied-text {
            position: absolute;
            right: 15px !important;
          }
      }
      .copy-url{
         border-radius: 21px;
         width: 100%;
         height: 42px;
         display: flex;
         justify-content: center;
         align-items:center;
         font-family: 'Roboto';
         font-style: normal;
         font-weight: 700;
         font-size: 14px;
         line-height: 16px;
         color: #51635D;
         cursor:pointer;
         background: #FFFFFF;
         border: 1px solid #97DC76;
         &:hover {
            background: #cbedba;
            color: #51635d;
            transition: all 0.3s ease-in-out;
          }
      }
    }
   }
 }
 
 @keyframes popup {
   from {bottom: -400px;}
   to {bottom: 0px;}
 }
 @media (min-width:414px) and (max-width:500px){
   padding-top:415px !important;
     .invite-box{
        position:fixed;
        width:380px !important;
     }
 }
 @media (min-width:390px) and (max-width:400px){
   padding-top:371px !important;
    .invite-box{
       position:fixed;
    }
}
@media (min-width:350px) and (max-width:370px){
   padding-top:262px !important;
    .invite-box{
       position:fixed;
       width:340px !important;
    }
}
@media (min-width:400px) and (max-width:413px){
   padding-top:424px !important;
    .invite-box{
       position:fixed;
       width:375px !important;
    }
}
`

export default function NutritionistCoachingInvite({setIsModal,isModal}){
  const { isMobile , isVisitor, profile, isUser, isNutritionist} = useAppContext() || {};
  const URL_ROOT = `${window.origin}/plan/`;
  const {nutritionist} = useNutritionistContext();
  const url = nutritionist?.nutritionist?.url;
  const [isCopied, setIsCopied] = useState(false);
  const [clientBasicInfo, setClientBasicInfo] = useState({
     email:''
 });
  const [postData] = useRequest(`nutritionist/inviteAllTypeUser`, 'post');
const [inviteNutritionist] = useRequest(`nutritionist/invite`, 'post')
if(isModal){
   document.body.classList.add('hide-scroll')
}
const dietTemplate = profile ? profile.dietTemplate : null;

 const handleSubmit = useCallback(async () => {
   if (isVisitor || nutritionist?.nutritionist?.id || (isUser && !dietTemplate.id)) {
    var Pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        var email = document.getElementById('email').value;
    if (clientBasicInfo.email=='' || Pattern.test(email) == false){
      toast.error('Invalid Email')
    }
    else{
      if(isVisitor || (isUser && !dietTemplate.id)){
         const result = await inviteNutritionist({
            body :{
               email:clientBasicInfo?.email
            }
         })
         result?.success == false && toast.error(result.message)
         result?.success && (toast.success("Invited Successfully"), setIsModal(false,document.body.classList.remove('hide-scroll')))
      }
    else{
      const data = await postData({
         body: {
           nutritionistId:nutritionist?.nutritionist?.id,
           email:clientBasicInfo?.email
         },
       });
       setIsModal(false)
       toast.success('Invited Successfully')
    }
       }
  setClientBasicInfo({email:''})

      }
 }, [clientBasicInfo,nutritionist?.nutritionist?.id]);

 const handleChange = useCallback((e) => {
   setClientBasicInfo(() => ({
     [e.target.name]: e.target.value,
   }));
 }, []);

  useEffect(() => {
   setTimeout(() => {
     setIsCopied(false);
   }, 3000);
 }, [isCopied]);

 useEffect(() => {
   document.body.classList.add('hide-scroll');
   return () => document.body.classList.remove('hide-scroll');
}, [])

  const {email} = clientBasicInfo;

    return(
        <InviteModal>
          <div className='invite-box'>
           <div className='close-img-div'><img src={ isMobile ?'/icons/close-cross.svg' : '/icons/close-circle3.svg' } alt='close-image' onClick={()=>setIsModal(false,document.body.classList.remove('hide-scroll'))}/></div>
           <div className='send-invite'>
            <p>Send Invite</p>
           </div>
           {!isMobile && 
           <div className='client'>
           {
            (isVisitor || (isUser && !dietTemplate?.id)) ?  <p>Nutritionist's email</p>: <p>Client's email</p>
           }
            </div>
            }   
           <div className='client-email'>
           <input 
           type="text"
            placeholder={(isVisitor || (isUser && !dietTemplate?.id)) ? "Your coach's email" : isMobile ? " Client's email" :'email...'}
             name="email"
             id='email'
             value={email}
             autoComplete="off"
             onChange={handleChange}
            
             />
           <button
           type='button'
           onClick={handleSubmit}
           disabled={email.length === 0}
           >
           {isMobile? 'Send' : 'Send invite'} 
            </button>
           </div>
           {
            (isNutritionist) && <>
            <div className='nutritionist-profile'>
              <p>My profile</p>
           </div>
           <div className='nutritionist'>
           <div className='nutritionist-email'>
           <input 
           type="text"
            value={(URL_ROOT + url).slice(0,33)+"..."} 
            id="fname" 
            placeholder='Nutritionist Email'
            name="fname"
            className={classNames({ copied: isCopied })}
            disabled
            />   

           <span
              className="copy-icon"
              onClick={() => {
                navigator.clipboard.writeText(URL_ROOT + url);
                setIsCopied(true);
              }}
            >
            <img src='/icons/copy.svg'/>   
            </span>
            {isCopied && <span className="copied-text">Copied</span>}
           </div>
           {!isMobile &&
           <div className='copy-url' 
            onClick={() => {
            navigator.clipboard.writeText(URL_ROOT + url);
            setIsCopied(true);
          }}>
            <p>Copy URL</p>
           </div>
           }
           </div>
            </>
           }
          </div>
          </InviteModal>
        )
}