import styled from 'styled-components';
import { FiChevronLeft } from 'react-icons/fi';
import { BsArrowRight } from 'react-icons/bs';
import GoogleLogin from 'react-google-login';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { isEmailValid } from 'components/auth/utils';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import { HiOutlineEye } from 'react-icons/hi';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { useOnboardingContext } from './index';
import PageTransition from './components/PageTransition';
import Progress from 'components/Progress';
import RegularInput from 'components/Input/RegularInput';
import MobileHeader from 'components/elements/MobileHeader';
import { Button } from 'components/elements/button/Button';
import { ReactComponent as ArrowRight } from 'assets/shop/chevron-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/shop/chevron-left.svg';
import { GilroyFontWrapper } from 'shared/theme/style';
import { motion, AnimatePresence } from "framer-motion";
import { setKitchenToSession } from 'utils/setFavoriteKitchen';

const SignInStyled = styled.div`
    padding-top: 12px;
    background-color: #FFFFFF;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    height: 100%;
    .form_body{
        padding: 0 18px;
        height: calc(85vh - 100px);
        overflow: auto; 
      }
    .signIn{
        margin-bottom: 32px;
        .title{
            display:flex;
            flex-direction:column; 
            padding-bottom:24px;
            padding-top:12px;
            gap:12px;
              .head-title{
                color: #000A2C;
                font-family: Gilroy;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                
              }
              .desc{
                color: #757E89;
                width: 305px;
                font-family: Gilroy;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              }
        }
        .terms{
            color: #00092D;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin-top: 11px;
            padding-bottom: 24px;
            a{
                font-weight: 600;
                display:inline-block;
            }
        }

        .form_group{
            margin-bottom: 14px;
            label{
                display: block;
                height: 32px;
                font-size: 12px;
                line-height: 32px;
                color: #51635D;
                font-weight: 700;
            }
            .form_control{
                background: #FFFFFF;
                border: 1px solid #E0E3E0;
                border-radius: 6px;
                width: 100%;
                height: 44px;
                padding: 9px 10px 9px 14px;
                font-weight: 400;
                font-size: 14px !important;
                line-height: 16px;
                color: #51635D !important;
                &::placeholder{
                    font-size: 14px;
                    line-height: 16px;
                    color: #AFB5B4;
                }
                &::-internal-autofill-selected{
                    color: #51635D !important;
                }
            }
            .pass-input{
                padding: 9px 35px 9px 14px;
            }
            .terms{
                font-weight: 400;
                font-size: 11px;
                line-height: 12px;
                letter-spacing: 0.02em;
                color: #51635D;
                margin-top: 11px;
                span{
                    color: #52C41A;
                }
                a{
                    color: #52C41A;
                    display:inline-block;
                }
            }
            .showPassword{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: #949D9B;
                right: 15px;
                font-size: 15px;
            }
        }
    }
    .login_btns{
        padding: 32px 18px 0;
        .google_login{
            button{
                width: 100% !important;
                border: 1px solid #E0E3E0 !important;
                border-radius: 21px !important;
                box-shadow: unset !important;
                justify-content: center !important;
                > div{
                    margin-right: 0 !important;
                    svg{
                        display: block !important;
                    }
                }
                > span{
                    font-weight: 700 !important;
                }
            }
        }
    }
    .login_btns_mobile{
        padding: 0;
        .google_login{
            button{
                width: 100% !important;
                border: 1px solid #E0E3E0 !important;
                border-radius: 21px !important;
                box-shadow: unset !important;
                justify-content: center !important;
                > div{
                    margin-right: 0 !important;
                    svg{
                        display: block !important;
                    }
                }
                > span{
                    font-weight: 700 !important;
                }
            }
        }
    }
    .button{
      display: flex;
      padding: 0px 18px 30px 18px;
      margin-top: ${({ isKeyboardActive }) => isKeyboardActive ? '30px' : ''};
      position: relative;
      button{ 
          height: 42px;
          width:138px;  
          margin-bottom:24px;
          &: disabled {
            .icon {
              svg {
                path {
                  stroke: none !important;
                }
              }
            }
          }  
          &:first-child{
              background: #F0F2F4;
              width:111px;
          }
      }
      .back{
          font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
            padding: 8px 20px 8px 12px;
            display: inline-flex;
            align-items: center;
            border: 1px solid #97DC76;
            border-radius: 21px;
            .icon{
                color: #51635D;
                font-size: 22px;
            }
    }
    .footer{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        color: var(--Medium-Grey, #51635D);
        text-align: center;
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
         a{
            color: #00092D;
           text-decoration: underline !important;
          }
      }
    }
`;
const FormGroupStyle = styled.div`
  margin-bottom: 8px;
  width: 100%;
  label {
    color: #00092d;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 9px;
    display: inline-block;
  }
  input {
    height: 44px;
    border-radius: 6px;
    border: 1px solid #e0e3e0;
    :hover, :focus {
      border-color: #00092d !important;
    }
    :focus::placeholder {
      color: transparent;
    }
    ::placeholder {
      color: #949d9b;
      font-style: normal;
      font-weight: 500;
    }
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  .flex {
    display: grid;
    grid-auto-flow: column;
    gap: 13px;
  }
`;

export default function SignIn() {
  const history = useHistory();
  const [isEmailExist, { isLoading: isEmailVerifying }] = useRequest('auth/isEmailPhoneNumberExist', 'get');
  const { setProfileObj, isWebView, isSaving, TimeZone, setupUser, afterLogin } = useAppContext();
  const {userDetails, setUserDetails} = useOnboardingContext();
  const { setOnboardingUser, isKeyboardActive, keyboardHeight } = useOnboardingContext();
  const validEmail = useMemo(() => isEmailValid(userDetails?.email), [userDetails.email]);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [password, setPassword] = useState(false);
  const [prevEmail, setPrevEmail] = useState('');
  const [emailExist, setEmailExist] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const formBodyRef = useRef();
  const [height, setHeight] = useState(0);
  const nameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const passwordRef = useRef();


  useEffect(() => {
      var scrollTarget = formBodyRef.current.scrollHeight - height;
        formBodyRef.current.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
  }, [isKeyboardActive, height, showPasswordField]);
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
    if (name === 'email') {
      setErrorMessage('');
      !showPasswordField && setPrevEmail(value);
    }
  };


  const handleButtonClick = useCallback(async () => {
    setEmailExist(false);
    try {
      if ((prevEmail !== userDetails?.email && showPasswordField) || !showPasswordField) {
        const res = await isEmailExist({ path: `auth/isEmailPhoneNumberExist?email=${userDetails?.email}` });
        if (res.data.isEmailPhoneNumberExist) {
          setEmailExist(true);
          setErrorMessage('Email already exists. Try with another email.')
          return;
        }
      }
      setShowPasswordField(true);
      if (userDetails.name && userDetails.email && userDetails.password && validEmail) {
        history.push('/self-onboarding/phone-verfication');
      }
    } catch (error) {
      toast.error(error);
    }
  }, [prevEmail, showPasswordField, userDetails, validEmail]);

  const handleShowPassword = useCallback(() => {
    setPassword((prevPassword) => !prevPassword);
  }, []);

  const handleGoBackClick = () => {
    if (isWebView) {
      isWebView.postMessage(
        JSON.stringify({ event: 'signUp_back_clicked', screen: 'OnBoardingWebView' }),
      );
    } else {
      history.goBack();
    }
  };

  const handleLoginClick = () => {
    if (isWebView) {
      isWebView.postMessage(JSON.stringify({ event: 'OnBoarding_Log_IN_Click', screen: 'Login' }));
      return;
    }
    history.push('/login');
  };

  useEffect(()=>{
    const params = new URLSearchParams(window?.location?.search);
    const kitchenValue = params?.get('kitchen');
    const kitchenUsername = sessionStorage.getItem('kitchenUsername');
    if( kitchenValue){
      setKitchenToSession(storeUrl, history);
    }
  },[])

  
  const handleOnKeyDown = (e, currentInputRef, nextInputRef) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      }
    }
  };
  
  useEffect(() => {
    const handleInputFocus = () => {
      setInputFocus(true);
    };

    const handleInputBlur = () => {
      setInputFocus(false);
    };

    const inputFields = [nameInputRef, lastNameInputRef, emailInputRef, passwordRef];

    inputFields.forEach((inputRef) => {
      console.log(inputRef,'inputRef')
      if (inputRef.current) {
        inputRef.current.addEventListener('focus', handleInputFocus);
        inputRef.current.addEventListener('blur', handleInputBlur);
      }
    });
  
    return () => {
      inputFields.forEach((inputRef) => {
        if (inputRef.current) {
          inputRef.current.removeEventListener('focus', handleInputFocus);
          inputRef.current.removeEventListener('blur', handleInputBlur);
        }
      });
    };
  }, [nameInputRef, lastNameInputRef, emailInputRef, passwordRef, showPasswordField]);

  useEffect(() => {
    if (userDetails?.password?.length) {
      setShowPasswordField(true);
    }
  }, [userDetails])
  

  useEffect(() => {
      nameInputRef.current.focus();
      setTimeout(() =>{
        var scrollTarget = formBodyRef.current.scrollHeight;
          formBodyRef.current.scrollTo({
          top: scrollTarget,
          behavior: 'smooth',
        });
      },300)
  },[])

  useEffect(() => {
    passwordRef?.current?.focus();
    setTimeout(() =>{
    var scrollTarget = formBodyRef.current.scrollHeight;
      formBodyRef.current.scrollTo({
      top: scrollTarget,
      behavior: 'smooth',
    });
    passwordRef?.current?.focus();
  },300)
  },[showPasswordField])

  return (
    <PageTransition isWebView>
      <GilroyFontWrapper>
      <SignInStyled isKeyboardActive={isKeyboardActive} inputFocus={inputFocus}>
        <MobileHeader
          text={'Sign up'}
          onClickLeft={handleGoBackClick}
        />
          <div className="form_body" ref={formBodyRef}>
            <form className="signIn">
              <div className="title">
                <h2 className="head-title">Glad to have you join us!</h2>
                <p className="desc">
                  Let's work together to simplify and enhance the joy of nutritious and delightful
                  eating
                </p>
              </div>
              <FormGroupStyle>
                <label>Name</label>
                <div className="flex">
                  <RegularInput
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    value={userDetails?.name}
                    placeholder="First name"
                    onFocus={() => showPasswordField ? setHeight(400) : setHeight(300)}
                    ref={nameInputRef}
                    onKeyDown={(e) => handleOnKeyDown(e, nameInputRef, lastNameInputRef)}
                    />
                  <RegularInput
                    type="text"
                    name="lastName"
                    onChange={handleInputChange}
                    placeholder="Last name"
                    value={userDetails?.lastName}
                    onKeyDown={(e) => handleOnKeyDown(e, lastNameInputRef, emailInputRef)}
                    ref={lastNameInputRef}
                    onFocus={() => showPasswordField ? setHeight(400) : setHeight(300)}
                  />
                </div>
              </FormGroupStyle>
              <FormGroupStyle>
                <label>Email</label>
                <RegularInput
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleInputChange}
                  value={userDetails?.email}
                  ref={emailInputRef}
                  errorMessage={errorMessage}
                  onFocus={() => setHeight(300)}
                  onKeyDown={(e) => {
                    if(!showPasswordField){
                    handleOnKeyDown(e, emailInputRef, null);
                    if (e.key === 'Enter' && validEmail && userDetails?.name) {
                      handleButtonClick();
                      e.target.blur();
                    }
                  }
                  else{
                    handleOnKeyDown(e, emailInputRef, passwordRef);
                  }
                  }}              
                />
              </FormGroupStyle>
              <AnimatePresence>
              {showPasswordField && (
                    <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    transition={{ duration: 0.5 }}
                  >              
                <FormGroupStyle>
                  <label>Password</label>
                  <RegularInput
                    type="password"
                    name="password"
                    ref={passwordRef}
                    placeholder="Password"
                    onChange={handleInputChange}
                    value={userDetails?.password}
                    onSuffixClick={handleShowPassword}
                    passwordVisible={password}
                    icon={!password ? '/icons/eye-slash.svg' : '/icons/eye.svg'}
                    onFocus={() => setHeight(200)}
                    onKeyDown={(e) => {
                      handleOnKeyDown(e, passwordRef, null);
                      if (e.key === 'Enter') {
                        handleButtonClick();
                      }                  
                    }}              
  
                  />
                </FormGroupStyle>
                <p className="terms">
                Already have an account?{' '}
                <a onClick={handleLoginClick}>
                  <p>Log in</p>{' '}
                </a>{' '}
              </p>
                </motion.div>
              )}
              </AnimatePresence>
              {!showPasswordField &&
              <p className="terms">
                Already have an account?{' '}
                <a onClick={handleLoginClick}>
                  <p>Log in</p>{' '}
                </a>{' '}
              </p>
}
            </form>
          </div>
        <div
          className={`button`}
          style={{
          position: "fixed",
          right: 0,
          left: 0,
          bottom: 0
       }}
        >
          <Button
            type={'button_combo'}
            comboLeftTitle="Back"
            comboRightClick={handleButtonClick}
            comboLeftClick={() => {
              setShowPasswordField(false)
            }}
            iconLeft={<ArrowLeft />}
            buttonVisible={!showPasswordField}
            iconRight={<ArrowRight />}
            comboRightTitle="Continue"
            onMouseDown={handleButtonClick}
            comboRightDisabled={
              !userDetails?.name ||
              !validEmail ||
              (showPasswordField && !userDetails?.password) ||
              isEmailVerifying
            }
          />
          { !inputFocus &&
          <div className="footer">
            <span className="copyright">By continuing you agree with </span>
            <span className="link">
              <a onClick={() => nativeEvent("terms_and_services", "/users/terms-&-conditions")}>terms and conditions</a>
            </span>
            <br />
            <span className="link">
              and <a onClick={() => { nativeEvent("privacy_policy", "/nutritt/privacy-policy") }}>privacy policy</a>
            </span>
          </div>
          }
        </div>
      </SignInStyled>
      </GilroyFontWrapper>
    </PageTransition>
  );
}