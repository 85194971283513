import { zeroPad } from 'react-countdown';

export const countdownTime = ({ days, hours, minutes, seconds }) => [
  {
    id: 1,
    label: 'days',
    value: days,
  },
  {
    id: 2,
    label: 'hours',
    value: zeroPad(hours),
  },
  {
    id: 3,
    label: 'minutes',
    value: zeroPad(minutes),
  },
  {
    id: 4,
    label: 'seconds',
    value: zeroPad(seconds),
  },
];
