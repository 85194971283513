import { forwardRef } from 'react';
import InputMessage from 'components/Input/InputMessage';

import { TextareaWrapper } from './style';

function Textarea({
  className,
  id,
  label,
  hint = '',
  width,
  text,
  messageStatus = 'error',
  message,
  ...props
}, ref) {
  return (
    <TextareaWrapper className={className} style={{ width: `${width}` }}>
      {label && (
        <label htmlFor={id}>
          {label}
          {hint && <div className="hint">{hint}</div>}
        </label>
      )}
      <textarea id={id} {...props} ref={ref} />
      {message && <InputMessage messageStatus={messageStatus} message={message} />}
    </TextareaWrapper>
  );
}

export default forwardRef(Textarea);
