import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import LogMealsModal from 'components/Pantry/LogMealsModal';
import DailyProgress from 'components/Pantry/DailyProgress';
import PantryFooter from 'components/Pantry/PantryFooter';
import DiaryModal from 'components/Pantry/DiaryModal';
import { useShopContext} from 'context/shopContext';
import ChangeDietPlan from 'pages/changedietplan/ChangeDietPlan';
import {useLocation} from "react-router-dom";
import { GilroyFontWrapper } from 'shared/theme/style';
import MobileHeader from 'components/elements/MobileHeader';
import TrackerCard from 'components/TrackerCard';
import { ReactComponent as CornIcon } from 'assets/shop/corn.svg';
import { ReactComponent as TacoIcon } from 'assets/shop/taco.svg';
import { ReactComponent as FlatbreadIcon } from 'assets/shop/flatbread.svg';
import { ReactComponent as CrossiantIcon } from 'assets/shop/croissant.svg';
import { prop, sum } from 'ramda';

const PantryStyle = styled.div`
  position: relative;
  width: 100%;
  background-color: #f6f5f8;
  overflow: hidden;
  @media (max-width: 576px){
    .align_left{
      justify-content: flex-start;
      background-color: #fff;
    }
    .pantry__wrappper{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
    }
  }
  &.bigIndex{
    z-index: 121;
  }
  @media(max-width:376px){
    overflow:auto;
    padding-bottom:150px;
  }
  .title-wrapper {
    padding: 14px 0 24px;
    color: #51635d;
    text-align: center;
    .title {
      overflow: hidden;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        text-transform: capitalize;
      }
    }
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .progressHeight .title-wrapper {
    padding: 14px 0;
  }
  .progressSmallHeight {
    .title-wrapper {
      padding: 7px 0;
      transform: scale(0.9);

      .title {
        margin-bottom: 10px;
      }
    }
    .dailyProgressSmallHeight {
      margin-bottom: 7px;
    }
    .diet-plan-wrapper {
      .diet-plan,
      .plan-duration {
        font-size: 12px;
      }
    }
    .diet-progress-wrapper {
      height: 78px;
      padding-top: 25px;

      .user-img {
        top: 21px;
      }
    }
    .log-btn-wrapper {
      margin-top: 0;
      .log-btn {
        height: 46px;
      }
    }
  }
  .log-btn-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .log-btn {
    width: 196px;
    height: 56px;
    min-height: 35px;
    background: #52c41a;
    color: #ffffff;
    border: 1px solid #52c41a !important;
    border-radius: 32px;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
  }
  &.isLogMealsModal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 121;
    padding-top: 53px;
  }
  &.displaySteps {
    overflow-y: auto;
    height: calc(100vh - 122px);
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .pantry-inner-content {
    max-width: 400px;
    height: inherit;
    margin: auto;
    position: relative;
    width: 100%;
    background-color: #f6f5f8;
    z-index: 9;
    .pantry-date-calories-box {
      z-index: 99;
      width: 100%;
      background-color: #f6f5f8;
    }
    .pantry-shadow-box {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      ::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
  .container {
    padding-bottom: 0px;
    height: 100%;
    &.mealsWrapper {
      margin-top: 0px;
      padding-bottom: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .MuiStepper-root .MuiStep-root:last-child:after {
    border-color: #52c41a;
  }
  .pantry-content {
    padding: 24px 0;
    margin-bottom: 26px;
  }
  ${'' /* p,
  span,
  div {
    font-family: 'Roboto' !important;
  } */}
  .pantry-shop-wrapper {
    z-index: 4;
    max-width: 400px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #f6f5f8;
    .shop-btn {
      font-size: 18px;
      font-weight: 800;
    }
  }
  .pantry-content {
    padding: 0;
    background-color: #fff;
    border-radius: 0px;
    margin-bottom: 0px;
    flex-grow: 1;
  }
  .pantry-shop-wrapper {
    .shop-btn {
      width: 80px;
      height: 30px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 21px;
      line-height: 16px;
    }
  }
  .goal_completed{
    text-align: center;
    padding: 33px 39px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    .title{
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #51635D;
      margin-bottom: 18px;
    }
    .new_goal{
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #52C41A;
      display: inline-block;
    }
    .goal{
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #51635D;
      span{
        font-weight: 700;
      }
    }
  }
  .improve_health{
    padding: 23px 24px;
    .disabled_progress{
      position: relative;
      margin-top: 22px;
      margin-bottom: 5px;
      .flag{
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #E0E3E0;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 13.54px;
          display: block;
          margin-left: 3px;
        }
      }
      .Mui-disabled{
        color: #E0E3E0 !important;
      }
      .MuiSlider-root{
        padding: 15px 0 !important;
        display: block !important;
      }
    }
  }
  @media (min-width: 992px) {
    top: 70px;
    height: calc(100vh - 69px);
    &.displaySteps {
      height: calc(100vh - 70px);
      &::-webkit-scrollbar {
        width: 8px;
      }
    }
    .container .pantry-inner-content .pantry-shadow-box {
      height: auto;
    }
  }
`;

const FoodDiaryStyle = styled.div`
  padding: 24px 16px 16px 16px;
  .main-icon{
    font-size: 22px;
  }
  .diary__title{
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    height: 32px;
    color: #000A2C;
    margin-bottom: 1px;
  }
`

export default function ProgressMobile() {
  const {
    loadPantryMeals,setLogin,
    fullDiaryMeals,setFullDiaryMeals, 
    isGetdiaryMealLoading,getdiaryData,
    isMobile,
    setPantryMeals,
    setPantryMealsCount,
    setPantryMealsSource,
    pantryMealsSource,
    setRouteBeforeSignup,
    isWebView
  } = useAppContext();
  const [activeDay, setActiveDay] = useState(new Date());
  const [isLogMealsModal, setIsLogMealsModal] = useState(false);
  const [greeting, setGreeting] = useState('');
  const [getDailyConsumedCalories] = useRequest();
  const [displaySteps, setDisplaySteps] = useState(false);
  const [isDiaryModal, setIsDiaryModal] = useState(false);
  const history = useHistory();
  const [progressHeight, setProgressHeight] = useState();
  const {
    user,
    profile: { name, selfMealPlan, dietTemplateId, dietMetrics: { calories }, },
  } = useAppContext();

  const [weeklyDiaryMeals, setWeeklyDiaryMeals] = useState([]);
  const [days, setDays] = useState([]);
  const [currentDayinfo, setCurrentDayInfo] = useState();
  const { showChangeDietPlan, providerListCalled } = useShopContext();
  const params = new URLSearchParams(useLocation().search);
  const LogMeal = params.get('logmeal');
  const Barcode = params.get('barcode');
 
   useEffect(()=>{
    if (LogMeal == 'true' || Barcode == 'true') {
      setIsLogMealsModal(true)
     }
   },[LogMeal,Barcode])

  useEffect(() => {

    let startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    let days = [];

    while (startOfWeek <= endOfWeek) {
      days.push(moment(startOfWeek).format('MM-DD-YYYY'));
      startOfWeek = moment(startOfWeek).add(1, 'd');
    }
    setDays(days);
    if(setLogin(false)){
      loadPantryMeals();
    }
    if(!fullDiaryMeals?.length && fullDiaryMeals && setLogin(false)){
      getdiaryData();
    }
    setProgressHeight(window.innerHeight - 118);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    moment.tz.setDefault(user?.TimeZone)
    const currentHour = moment().format('HH');
    if (currentHour >= 3 && currentHour < 12) {
      setGreeting('Good morning');
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting('Good afternoon');
    } else if ((currentHour >= 17 && currentHour < 24) || (currentHour >= 0 && currentHour < 3)) {
      setGreeting('Good evening');
    } else {
      setGreeting('Hello');
    }

    if (localStorage.getItem('displayStepsInPantry')) {
      setDisplaySteps(true);
      localStorage.removeItem('displayStepsInPantry');
    }
    setRouteBeforeSignup(null);
  }, []);


  const updateFullDiaryMeals = (data) => {
    const mealYear = moment(data.createdAt).format('MM-YYYY');
    const mealDate = moment(data.createdAt).format('MM-DD-YYYY');
    const changedCategory = data?.categoryType;
    const mealId = data?.id;
    setFullDiaryMeals((prevFullDiaryMeals) => {
      const updatedFullDiaryMeals = [...(prevFullDiaryMeals || [])];
      const yearIndex = updatedFullDiaryMeals.findIndex((year) => year[mealYear] !== undefined);
      if (yearIndex === -1) {
        const newYear = { [mealYear]: { [mealDate]: { [changedCategory]: [{ ...data, id: mealId }] } } };
        updatedFullDiaryMeals.push(newYear);
      } else {
        const year = updatedFullDiaryMeals[yearIndex];
        const dateIndex = year[mealYear][mealDate] !== undefined ? year[mealYear][mealDate] : -1;
        if (dateIndex === -1) {
          year[mealYear][mealDate] = { [changedCategory]: [{ ...data, id: mealId }] };
        } else {
          const categoryMeals = year[mealYear][mealDate][changedCategory] || [];
          const mealIndex = categoryMeals.findIndex((meal) => meal.id === mealId);
          if (mealIndex !== -1) {
            categoryMeals[mealIndex] = { ...data, id: mealId };
          } else {
            categoryMeals.push({ ...data, id: mealId });
          }
          year[mealYear][mealDate][changedCategory] = categoryMeals;
        }
        updatedFullDiaryMeals[yearIndex] = year;
      }

      return updatedFullDiaryMeals;
    });
  };
  
  

  const revertFullDiaryMeals = (mealId, mealCategory) => {
    const newData = fullDiaryMeals.map((meal) => {
      let updatedMeal = { ...meal };
      const mealYear = Object.keys(updatedMeal)[0]; // get the year for the meal
      const mealDates = Object.keys(updatedMeal[mealYear]); // get all the dates for the year
      // loop through all the meal dates for the year
      mealDates.forEach((date) => {
        if (updatedMeal[mealYear][date][mealCategory]) {
          updatedMeal[mealYear][date][mealCategory] = updatedMeal[mealYear][date][mealCategory].filter((mealData) => mealData.orderLineId !== mealId);
        }
      });
  
      return updatedMeal;
    });
    setFullDiaryMeals(newData);
  };
  
  const removeMealById = (mealId, mealCategory) => {
    const newData = fullDiaryMeals.map((meal) => {
      let updatedMeal = { ...meal };
      const mealYear = Object.keys(updatedMeal)[0]; 
      const mealDates = Object.keys(updatedMeal[mealYear]);
      mealDates.forEach((date) => {
        if (updatedMeal[mealYear][date][mealCategory]) {
          updatedMeal[mealYear][date][mealCategory] = updatedMeal[mealYear][date][mealCategory].filter((mealData) => mealData.id !== mealId);
        }
      });
  
      return updatedMeal;
    });
    setFullDiaryMeals(newData);
  };

  useEffect(() => {
    isMobile && setActiveDay(new Date());
  }, [])

  const categoryCalories = useMemo(() => {
    const meals = currentDayinfo?.meals || [];
    if (!meals[0] || typeof meals[0] !== 'object') {
      return {
        breakfast: 0,
        lunch: 0,
        dinner: 0,
        snack: 0,
      };
    }
  
    return Object.keys(meals[0]).reduce((accumulator, categoryType) => {
      const categoryMeals = meals[0][categoryType];
      const categoryCalories = Array.isArray(categoryMeals) && categoryMeals.length > 0
        ? sum(categoryMeals?.map(prop('calories')) || [0]) : 0;
  
      return { ...accumulator, [categoryType]: categoryCalories };
    }, {});
  }, [currentDayinfo?.meals]);
    

  useEffect(() => {
    const isPlatformWeb = () => {
        return !(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    const isPlatformIOS = () => {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    };
    const isPlatformAndroid = () => {
        return /Android/i.test(navigator.userAgent);
    };
    // Define the app store URLs for iOS and Android
    const appStoreURL = isPlatformIOS() ? "https://apps.apple.com/in/app/nutritt/id1605156638" : 'https://play.google.com/store/apps/details?id=com.nutritt&hl=en&gl=US';
    // Define the custom URL scheme for your app
    const scheme = 'nutrittapp://';
    // Try to open the app using the custom URL scheme  
          if (!isPlatformWeb()) {
        if ((isPlatformIOS() || isPlatformAndroid()) && !isWebView) {
                window.location.href = scheme;
                setTimeout(() => {
                    // If the app did not open, redirect to the app store or play store
                    window.location.href = appStoreURL;
                }, 500);            
        }
  }
}, [])

  const foodDiaryData = [
    {
      id: 0,
      title: 'Breakfast',
      icon: <CornIcon className='icon' />,
      consumedCalories: categoryCalories?.breakfast,
      consumedMealType: 'breakfast'
    },
    {
      id: 1,
      title: 'Lunch',
      icon: <TacoIcon className='icon' />,
      consumedCalories: categoryCalories?.lunch,
      consumedMealType: 'lunch'
    },
    {
      id: 2,
      title: 'Dinner',
      icon: <FlatbreadIcon className='icon' />,
      consumedCalories: categoryCalories?.dinner,
      consumedMealType: 'dinner'
    },
    {
      id: 3,
      title: 'Snack',
      icon: <CrossiantIcon className='icon' />,
      consumedCalories: categoryCalories?.snack,
      consumedMealType: 'snack'
    }
  ]

  const updatePantryData = (orderLineId) => {
    const isMealExist=pantryMealsSource.find((item)=>item.orderLineId===orderLineId);
    if (!isMealExist) {
      loadPantryMeals();
      return;
    }
    const updatedPantryData = pantryMealsSource
      .map((item) => {
        if (item.orderLineId === orderLineId) {
          const updatedItem = { ...item, qty:  item.qty + 1 };
          return updatedItem.qty > 0 ? updatedItem : null;
        } else {
          return item;
        }
      })
      .filter((item) => item !== null);
    
    setPantryMeals(updatedPantryData);
    setPantryMealsCount(updatedPantryData?.reduce((acc, val) => acc + parseInt(val.qty), 0));
    setPantryMealsSource(updatedPantryData);
  };
  

  return (
    <>
      <GilroyFontWrapper>
      {showChangeDietPlan ? (
        <ChangeDietPlan/>
      ) : (
      <PantryStyle className={classNames({ displaySteps }, { isLogMealsModal }, {bigIndex: isDiaryModal})}>
        {!isLogMealsModal && !isDiaryModal && (<>
          <MobileHeader text='Tracker' className={'align_left'}/>
          <div
            style={{ height: progressHeight }}
            className={classNames('pantry__wrappper', {
              progressHeight: window.innerHeight < 700,
              progressSmallHeight: window.innerHeight < 600,
            })}
          >
            <DailyProgress setIsDiaryModal={setIsDiaryModal} currentDayinfo={currentDayinfo} isGetdiaryMealLoading={isGetdiaryMealLoading} />

            <FoodDiaryStyle>
              <div className='food__diary'>
                <h2 className='diary__title'>
                  Food Diary
                </h2>
                {foodDiaryData?.map((diary, index) => (
                  <div key={diary?.id} style={{ marginBottom: foodDiaryData?.length - 1 !== index ? '10px' : '0' }}>
                    <TrackerCard icon={diary?.icon} consumedCalories={diary?.consumedCalories} totalCalories={calories} title={diary?.title} onClick={() =>setIsDiaryModal(true)} onPressAdd={() =>setIsLogMealsModal(true)} consumedMealCategory={diary?.consumedMealType} />
                  </div>
                ))}
                
              </div>
            </FoodDiaryStyle>
          </div></>
        )}
        <DiaryModal
          isDiaryModal={isDiaryModal}
          setIsDiaryModal={setIsDiaryModal}
          activeDay={activeDay}
          days={days}
          setDays={setDays}
          fullDiaryMeals={fullDiaryMeals}
          setIsLogMealsModal={setIsLogMealsModal}
          weeklyDiaryMeals={weeklyDiaryMeals}
          setWeeklyDiaryMeals={setWeeklyDiaryMeals}
          setCurrentDayInfo={setCurrentDayInfo}
          setActiveDay={setActiveDay}
          updateFullDiaryMeals={updateFullDiaryMeals}
          removeMealById={removeMealById}
          updatePantryData={updatePantryData}
        />
        <LogMealsModal
          setIsLogMealsModal={setIsLogMealsModal}
          isLogMealsModal={isLogMealsModal}
          setActiveDay={setActiveDay}
          activeDay={activeDay}
          getdiaryData={getdiaryData}
          currentDayinfo={currentDayinfo}
          updateFullDiaryMeals={updateFullDiaryMeals}
          revertFullDiaryMeals={revertFullDiaryMeals}
        />
      </PantryStyle>
      )}
      {!isLogMealsModal && !isDiaryModal && (
        <PantryFooter progress={true} shop={false} myCoach={false} />
      )}
      </GilroyFontWrapper>
    </>
  );
}

