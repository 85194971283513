import ShopSteps from 'components/NutrittSteps/ShopSteps';
import classNames from 'classnames';
import { useAppContext } from 'context/appContext';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getEarliestTime,
  toDateString,
  toTimeString,
  convertTimeTo12HrFormat,
} from '../providerTimes';
import { useMemo } from 'react';
import { useShopContext } from 'context/shopContext';
import ImageViewer from 'components/ImageViewer';
import SetClientCart from 'hooks/setClientCart';
import BackButton from "components/BackButton";


const ProviderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  img {
    object-fit: cover;
  }

  @media (min-width: 992px) {
    &.mealsProviderSection {
      .provider-sectionCard {
        margin-bottom: 32px;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      }

      .innerCard-media {
        width: 350px;
        height: 225px;
        margin: 10px 10px 10px 0;
        padding: 10px;
        border: 1px solid #e0e3e0;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;


        img {
          width: 230px;
          height: 138px;
          object-fit: contain;
        }
      }

      .provider-info-wrapper {
        width: calc(100% - 225px);
        padding: 24px 10px 14px;

        h3 {
          margin: 0 0 24px;
          padding: 0;
          color: #0b1d17;
          font-size: 36px;
          font-family: 'Roboto';
          line-height: 42px;
          text-transform: capitalize;
        }

        p {
          color: #51635d;
          font-size: 14px;
          line-height: 16px;
        }

        .provider-tags-wrapper {
          margin: 14px 0 21px;
          display: flex;
          flex-wrap: wrap;

          .tag-container {
            padding: 2px 9px;
            margin: 0 5px 5px 0;
            border: 1px solid #cbedba;
            border-radius: 10px;
            display: flex;
            align-items: center;
          }

          span {
            color: #52c41a;
            font-size: 10px;
            line-height: 12px;
          }
        }

        .delivery-sectionBar {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          & > div {
            margin: 0 40px 5px 0;
          }

          & > div:last-child {
            margin-right: 0;
          }

          .delivery-icon {
            margin-right: 5px;
            display: flex;
          }

          .time-separator {
            display: none;
          }

          p {
            font-size: 12px;
            line-height: 14.06px;

            span {
              font-family: 'Roboto';

              span {
                color: #afb5b4;
              }
            }
          }
        }

        .delivery-sec45,
        .min-order-wrapper {
          display: flex;
          align-items: center;

          img {
            width: 16px;
          }
        }
      }
    }

    .step-header .back-icon-wrapper {
      position: fixed;
      top: 94px;
      left: 2%;
      z-index: 5
    }
  }

  .provider-name{
    border: none!important;
    border-top: 2px solid #F6F5F8!important;
    border-bottom: 2px solid #F6F5F8!important;
  }

  @media (min-width: 1200px) {
    &.mealsProviderSection .provider-info-wrapper {
      width: calc(100% - 350px);
      padding: 24px 42px 14px;
    }
  }
`;

const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function SelectedProvider({ provider }) {
  const {
    name,
    minOrderAmount,
    images,
    leadTime,
    description,
    tags,
    schedule,
    deliveryCoverage = [],
  } = provider || {};
  const { isMobile, isGuest, isVisitor } = useAppContext();
  const [active, setActive] = useState(0);
  const history = useHistory();
  const { pathname } = useLocation();
  const earliestDeliveryTime = getEarliestTime(schedule, 'delivery');
  const earliestPickupTime = getEarliestTime(schedule, 'pickup');
  const {
    settings: { userInfo: { zipcode } = {} }, providerListCalled
  } = useShopContext();
  const isUserInsideDeliveryCoverage = useMemo(() => {
    return deliveryCoverage?.includes(zipcode);
  }, [zipcode]);
  const mobileView = isMobile ? 'mobileView' : '';
  const getPreprationMessage = (hours) => {
    hours = parseInt(hours);

    return `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  };
  function convertToHttps(imageUrl) {
    if (typeof imageUrl === 'string' && imageUrl.trim().length > 0 && imageUrl.indexOf("http://") === 0) {
      return "https://" + imageUrl.slice(7);
    }
    return imageUrl;
  }
  
  const secureImageUrl = images ? images.map(convertToHttps) : [];
  return (
    <ProviderContainer
      className={classNames({
        mealsProviderSection: pathname == '/shop/meals',
      })}
    >
      <BackButton perviousLink="/shop/providers" />
      <ShopSteps
        title="Pick meals"
        description="Enjoy the freshest meals from your local providers!"
        activeStep={2}
        stepNumber={1}
        steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
      />
      <div className={`pickmeal-wrapper ${mobileView}`}>
        <div className="provider-sectionCard">
          <div className="innerCard-media">
            <ImageViewer
              src={secureImageUrl || '/images/avatar.png'}
              fallbackSrc={'/images/avatar.png'}
              alt="Provider" 
              style = {{height: "100%"}}
            />
          </div>
          <div className="provider-info-wrapper">
            <div className="inner-cardline provider-name">
              <h3>{name}</h3>
              {!isMobile ? (
                <>
                  <p>{description}</p>
                  <div className="provider-tags-wrapper">
                    {tags?.map((tag, index) => (
                      <div key={index} className="tag-container">
                        <span>{tag}</span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <span>
                  {minOrderAmount ? <p>min. order ${minOrderAmount}</p> : <p>No minimum order</p>}
                </span>
              )}
            </div>
            <div className="delivery-sectionBar">
              {earliestDeliveryTime && (
                <div className="delivery-sec45 leftDelivery-sec">
                  <div className="delivery-icon">
                    <img src="/icons/delivery-icon.svg" alt="pickup icon" />
                  </div>
                  <div className="delivery-info">
                    <p>
                      Delivery by:{' '}
                      <span>
                        {toDateString(earliestDeliveryTime)} <sub className="time-separator" />
                        <span style={{color:'#E0E3E0'}}> | </span>
                        {convertTimeTo12HrFormat(toTimeString(earliestDeliveryTime))}
                      </span>{' '}
                    </p>
                  </div>
                </div>
              )}
              {earliestPickupTime && (
                <div className="delivery-sec45">
                  <div className="delivery-icon">
                    <img src="/icons/pickup-icon.svg" alt="pickup icon" />
                  </div>
                  <div className="delivery-info">
                    <p>
                      Pick-up by:{' '}
                      <span>
                        {' '}
                        {toDateString(earliestPickupTime)}
                        <span style={{color:'#E0E3E0'}}> | </span>
                        {convertTimeTo12HrFormat(toTimeString(earliestPickupTime))}
                      </span>{' '}
                    </p>
                  </div>
                </div>
              )}
              {!isMobile && (
                <div className="min-order-wrapper">
                  <div className="delivery-icon">
                    <img src="/icons/dollar-square.svg" alt="Dollar Icon" />
                  </div>
                  <span>
                    {minOrderAmount ? (
                      <p>
                        Min order:<span> ${minOrderAmount}</span>
                      </p>
                    ) : (
                      <p>No minimum order</p>
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ProviderContainer>
  );
}
