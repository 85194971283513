import classNames from 'classnames';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useNutritionistContext } from '../../context/nutritionistContext';
import { createPlanItems } from '../../constants';
import { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import './style.scss';

const NutristionistSidebarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  width: 300px;
  // padding-top: 33px;
  background-color: #f7f6f7;
  box-shadow: -6px 0 4px -2px rgb(0 0 0 / 10%) inset;
  @media (max-height : 600px){
    padding-top:25px;
  }
  .coach{
     padding: 10px 2px 0px 2px;
      display: flex;
   
      cursor: pointer;
      outline: none;

      @media (max-width: 768px) {
        .MuiIconButton-edgeEnd {
        margin-right:-19px;
         
        }
      }
      @media(min-width: 992px){
        .MuiAccordionSummary-conten {
          margin: 0;
        }
        .MuiIconButton-edgeEnd {
          margin-right:-86px;  
        }
      }

      .MuiAccordionSummary-content {
        margin: 0!important;
      }
      .MuiAccordionDetails-root {
        padding: 0px 29px 0;
      }
  }
     .coach-div{
       margin-left:9px;
     }
     .coach:hover{
       background-color: #ececee80;
        border-radius: 10px; 
      }

  .container {
    width: 100% !important;
    //  height: calc(100vh - 30px);
    height: 100vh;
    padding: 0 29px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nutritionist-info {
      margin-bottom: 6px;
      padding-bottom: 13%;
      border-bottom: 1.5px solid #E0E3E0;
      text-align: center;

      img {
        width: 75px;
        height: 75px;
        top: 50px;
        margin-bottom: 10px;
        object-fit: cover;
        background: #FFFFFF;
        border: 1px solid #E0E3E0;
        border-radius: 75px;
        padding: 2px;
      }

      span {
        margin: 5px 0;
        color: #949d9b;
        font-size: 12px;
        line-height: 14px;
        display: block;
      }
    }

    .nutritionist-btns a {
      display: block;
      padding: 9px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .clients-wrapper{
      border-bottom: 1.5px solid #E0E3E0;
      padding-top: 5%;
      padding-bottom: 3%;
    }

    .nut-name {
      color: #0b1d17;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }

    .item-wrapper {
      height: 40px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      outline: none;

      svg {
        width: 22px;
        height: 22px;
        color: #51635d;
      }

      img,
      svg {
        margin-right: 11px;
      }

      &:hover,
      &.active {
        color: #52C41A;
        border-radius: 10px;
      }

      &.sub-menu {
        padding: 6% 0 !important;
      }

      &.have-menu:hover {
        background-color: transparent;
      }

      .content-wrapper {
         overflow: hidden;     
      }

    }
    }
    
    .item-title {
      color: #51635d;
      font-size: 16px;
      font-weight: 400;
      &.active {
        color: #52C41A;
      }
    }
    }

    .DropArrow{
      padding-left : 96px;
    }

    .logout-wrapper{
      // padding: 3% 0;
    }
  }
    
  }

  @media (max-width: 991px) {
    position: static;
    width: 100%;
    // padding-top: 20px;
    background-color: #FFFFFF;
    // top:37% !important;
    box-shadow:unset !important;
    .logout-wrapper{
      // padding-top:15px;
    }
    .MuiDrawer-root{
     .MuiDrawer-paper{
       height:80vh;
       position:fixed;
       bottom:0px !important;
       top:unset !important;

       div {
        padding-top: 41%;
       }
      } 
    }

    .container {
      // height: 45%;
      display:flex;
      justify-content: flex-start !important;
      // padding-top: 5%;
      padding:0 !important;

      .item-wrapper{
        // padding: 32px 22px !important;
        margin-bottom : 32px;
        margin-top : 32px;

        img,
        svg {
         margin-right: 7%;
        }

      //   @media only screen and (device-width: 375px) {
      //       padding: 8% 3% !important;
      //   }
      //   @media only screen and (device-width: 414px) {
      //     padding: 10% 3% !important;
      // }
      }
      .item-client{
        // padding-bottom:20px;
      //  border-bottom:1px solid #D9D9D9;
        &:last-child{
          border-bottom:1px solid #D9D9D9;
          // margin: 0 22px;
          .item-wrapper{
            // padding:32px 0 !important;
            margin-bottom : 32px;
            margin-top : 32px;
          }
        }
      }
      .nutritionist-info {
        margin-bottom: 16px;
      }

      .item-title {
        font-size: 18px !important;
        line-height: 24px !important;
        font-weight: 400 !important;
      }

      .close-icon {
        position: absolute;
        right: 7%;
        top: 82%;
        display: flex;
        cursor: pointer;

        svg {
          font-size: 20px;
        }
      }
    }
  }
  .home-div{
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  }
`;

export default function NutristionistSidebar({ closeSidebar, getForm, isAlert }) {
  const { pathname } = useLocation();
  const { logout, user, isMobile } = useAppContext();
  const { nutritionist: nutritionistData } = useNutritionistContext();
  const [currentActive,setCurrentActive]=useState("home")
  const history = useHistory();
  const items = createPlanItems();
  const [isMeals, setIsMeals] = useState('');
  const [clicked, setClicked] = useState(
    pathname === '/nutritionist/coaching/offering' || pathname === '/nutritionist/coaching/clients'
      ? true
      : false,
  );
  const [clickedReport, setClickedReport] = useState(false);
  const Data = [{ Parent: 'Coaching', child1: 'Offering', child2: 'Followers' }];
  const pushLink = (link) => {
    if (getForm) {
      getForm.type=''
      JSON.stringify(getForm) === JSON.stringify(items) ? history.push(link) : isAlert(link);
    } else {
      history.push(link);
    }
    window.scroll(0, 0);
    isMobile && closeSidebar(false);
  };
  const toggle = (clicked, setClicked) => {
    if (clicked === true) {
      return setClicked(false);
    }
    setClicked(true);
  };

  const name=user?.nutritionistProfile?.name
  return (
    <NutristionistSidebarStyle>
      <div className="container">
        {Data.map((item, index) => {
          return (
            <>
              <div className="nutritionist-data" key={index}>
                { !isMobile &&
                <div className="nutritionist-info">
                <img
                  src={nutritionistData?.nutritionist?.img || '/images/avatar.png'}
                  alt="Nutritionist Photo"
                />
                <div className="nut-name">{name?.length>25?name?.substr(0,25):name}</div>
                <span>{nutritionistData?.nutritionist?.title}</span>
              </div>
                }
                <div className="nutritionist-btns">
              { !isMobile &&
                <>
              <div onClick={() => {
                  pushLink('/nutritionist/home')
                  }}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('home'),
                      })}
                    >
                      <img src={ pathname.includes('/home') ? "/icons/Home-green.svg" :"/icons/Home-icon.svg"} alt="Home Icon"/>
                      <div className={`item-title`} style={pathname.includes('/home')?{color: '#52C41A'}:{}}>Home</div>
                    </div>
                  </div>
                  <div className='home-div' onClick={() => {
                    pushLink('/nutritionist/meal-plan')
                  }}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('meal-plan'),
                      })}
                    >
                      <img src={pathname.includes('/meal-plan')? "/images/note-favorite.svg": "/icons/note-favorite.svg"} alt="Note Favorite Icon" />
                      <div className={`item-title`} style={pathname.includes('/meal-plan')? {color: "#52C41A"}: {}}>Meal Plan</div>
                    </div>
                  </div>
                  <>
                  <div onClick={() => {
                    pushLink('/nutritionist/clients')}}>
                  <div
                    className={classNames('item-wrapper', {
                      active: pathname.includes('/clients'),
                    })}
                  >
                    <img src={pathname.includes('/clients') ?"/images/device-message.svg": "/icons/device-message.svg"} alt="Device Message Icon" />
                    <div className={`item-title`} style={pathname.includes('/clients')? {color: "#52C41A"}: {}}>{item.Parent}</div>
                  </div>
                  </div>
                  
                  <div onClick={() => {
                    pushLink('/nutritionist/followers')}}>
                  <div
                    className={classNames('item-wrapper', {
                      active: pathname.includes('/followers'),
                    })}
                  >
                    <img src={pathname.includes('/followers')?"/icons/selected-profile-2user.svg":"/icons/profile-2user.svg"} alt="Profile User Icon" />
                    <div className={`item-title`} style={pathname.includes('/followers')? {color: "#52C41A"}: {}}>{item.child2}</div>
                  </div>
                  </div>
                  </>
                  
                  {!isMobile && <div className="clients-wrapper"/>}
                  </>}
                  {!isMobile &&
                    <div className="coach">
                    <Accordion
                      expanded={clickedReport}
                      onChange={() => toggle(clickedReport, setClickedReport)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="MuiAccordionSummary-content"
                      >
                        <div>
                          {' '}
                          <img src="/icons/chart.svg" alt="Chart Icon" />{' '}
                        </div>

                        <div className="coach-div ">
                          {' '}
                          <Typography color="#51635D">Reports</Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="">
                          <div
                            onClick={() => {
                              pushLink(`/nutritionist/reports/meals`)
                            }}
                          >
                            <div
                              className={classNames('item-wrapper sub-menu', {
                                active: pathname.includes('coaching/offering'),
                              })}
                            >
                              <img src={pathname.includes('/reports/meals')?"/images/note-favorite.svg": "/icons/note-favorite.svg"} alt="Moniter Icon" />
                              <div className={`item-title`} style={pathname.includes('/reports/meals')? {color: "#52C41A"}: {}}>Meal Plans</div>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              pushLink(`/nutritionist/reports/offering`)
                            }}
                          >
                            <div
                              className={classNames('item-wrapper sub-menu', {
                                active: pathname.includes('coaching/clients'),
                              })}
                            >
                              <img src={pathname.includes('/reports/offering')?"/images/device-message.svg":"/icons/device-message.svg"} alt="Profile User Icon" />
                              <div className={`item-title`} style={pathname.includes('/reports/offering')?{color: "#52C41A"}: {}}>Coaching</div>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>}
                  {isMobile && <div onClick={() => pushLink('/nutritionist/reports')}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('reports'),
                      })}
                    >
                      <img src={pathname.includes('/nutritionist/reports')?"/images/chart.svg":"/icons/chart.svg"} alt="Chart Icon" />
                      <div className="item-title" style={pathname.includes('reports')?{color: "#52C41A"}: {}}>Reports</div>
                    </div>
                  </div>}
                  {isMobile &&   <div  onClick={() => {
                    pushLink('/nutritt/support')}}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('support'),
                      })}
                    >
                      <img src={pathname.includes('support')?"/images/support.svg":"/icons/support.svg"} alt="support" />
                      <div className={`item-title`} style={pathname.includes('support')? {color: "52C41A"}: {}}>Support</div>
                    </div>
                  </div>}
                  <div onClick={() => {
                    pushLink('/nutritionist/settings')}}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('settings'),
                      })}
                    >
                      <img src={pathname.includes('settings')?"/images/setting-2.svg":"/icons/setting-2.svg"} alt="Setting Icon" />
                      <div className={`item-title`} style={pathname.includes('settings')? {color: "#52C41A"} : {}}>Settings</div>
                    </div>
                  </div>
                  {/* Hide profile from UI*/}
                    <div  onClick={() => {
                      pushLink('/nutritionist/profile')}} className={isMobile ? 'item-client' : ''}>
                    <div
                      className={classNames('item-wrapper', {
                        active: pathname.includes('profile'),
                      })}
                    >
                      <img src={pathname.includes('profile')?"/images/nutritionist-profile.svg":"/icons/nutritionist-profile.svg"} alt="Profile Icon" />
                      <div className={`item-title`} style={pathname.includes('profile')? {color: "#52C41A"} : {}}>Profile</div>
                    </div>
                  </div>
                {!isMobile && <div className='item-client' onClick={() => {
                  pushLink('/nutritt/support')}}>
                  <div
                    className={classNames('item-wrapper', {
                      active: pathname.includes('support'),
                    })}
                  >
                    <img src={pathname.includes('support')?"/images/support.svg":"/icons/support.svg"} alt="support" />
                    <div className={`item-title`} style={pathname.includes('support')? {color: "52C41A"}: {}}>Support</div>
                  </div>
                </div>}
                  {!isMobile && <div className="clients-wrapper"/>}
                </div>
              </div>
              <div className="logout-wrapper">
                <Link to="/logout" onClick={logout}>
                  <div className="item-wrapper">
                    <img src="/icons/logout.svg" alt="Logout Icon" />
                    <div className="item-title">Log out</div>
                  </div>
                </Link>
              </div>
              {isMobile && (
                  <div className="close-icon" onClick={() => closeSidebar(false)}>
                    <img src="/icons/cross-menu.svg" alt="Cross Menu" />
                  </div>
                )}
            </>
          );
        })}
      </div>
    </NutristionistSidebarStyle>
  );
}
