import React, { useState } from 'react'
import { Button } from 'components/elements/button/Button'
import { ReactComponent as ArrowLeft } from '../assets/arrow-left2.svg';
import { FiArrowRight } from 'react-icons/fi'
import Chip from "../components/elements/chipModule/Chip";
import Toggle from "components/elements/toggleModule/Toggle";
import { Sidebar } from 'components/elements/sidebar/Sidebar';
import Profile from "../components/elements/profileCard/Profile";
import Tab from "../components/elements/tab/Tab";
import { Modal } from 'components/elements/modal/Modal';
import Avatar from "../components/elements/avatar/Avatar"
import { MdCheck, MdCancel } from 'react-icons/md'
import { Card } from 'components/elements/card/Card';
import Header from 'components/elements/header/Header'
import { theme } from '../shared/theme/theme';
import SingleLineChart from 'components/elements/charts/SingleLineChart';
import Alert from 'components/elements/alert/Alert';
import RegularInput from 'components/Input/RegularInput';
import Textarea from 'components/elements/textarea/Textarea';
import OrderDetails from 'components/elements/orderDetail/orderDetails/OrderDetails';
import DropDown from 'components/elements/drop-down/Dropdown';
import ActionButton from 'components/elements/ActionButton';
import Pie from 'components/elements/Pie';
import PieLegend from 'components/elements/Pie/PieLegend';
import Progress from 'components/elements/Progress';
import MobileHeader from 'components/elements/MobileHeader';
import { AddressBlock } from 'components/elements/addressBlock/AddressBlock';
import ListWithArrow from 'components/elements/testComp/ListWithArrow'
import KitchenCard from 'components/elements/KitchenCard';
import QuantityButton from 'components/elements/QuantityButton';
import MealCard from 'components/elements/MealCard';
import { CartItem } from 'components/elements/cartItem/CartItem';
import useDisplayAmount from 'hooks/useDisplayAmount';
import SettingsRowItem from 'components/elements/settingsRowItem/SettingsRowItem';
import { ReactComponent as DietPreferences } from 'assets/shop/diet-preferences.svg';
import { ReactComponent as Corn } from 'assets/shop/corn.svg';
import TrackerCard from 'components/TrackerCard';
import Calender from 'components/elements/calender/Calender';
import MessageModal from 'components/elements/modal/MessageModal';
import { ReactComponent as Lock } from 'assets/shop/Lock.svg';
import UserMealCard from 'components/elements/MealCard/UserMealCard';

function NewFile() {
    const [selectedTabId, setSelectedTabId] = useState(0);
    const [cardType, setCardType] = useState(1);
    const [mealCount, setMealCount] = useState({default: 1, dark: 1, light: 1, smallLight: 1, smallLightRemove: 1, mealCard: 1 });
    const pieData = [
        {
            id: 'Fat',
            value: 20,
        },
        {
            id: 'Protein',
            value: 30,
        },
        {
            id: 'Carbs',
            value: 50,
        },
    ];
    const pieDataWithLegend = [
        {
            id: 'Fat',
            label: 'Fat',
            value: 23.5,
            gram: `${0.1} g`,
            color: '#EE786B',
        },
        {
            id: 'Protein',
            label: 'Protein',
            value: 35.3,
            gram: `${0.1}`,
            color: '#2399BF',
        },
        {
            id: 'Carbs',
            label: 'Carbs',
            value: 42.3,
            gram: `${0.1}g`,
            color: '#8ACEBF',
        },
    ];
    const progressLines = [
        {
            id: 'Fat',
            label: 'Fat',
            gram: `${9}g`,
            percent: 20,
            color: '#EE786B',
        },
        {
            id: 'Protein',
            label: 'Protein',
            gram: `${0}g`,
            percent: 30,
            color: '#2399BF'
        },
        {
            id: 'Carbs',
            label: 'Carbs',
            gram: `${1}g`,
            percent: 50,
            color: '#8ACEBF'
        },
      ];

    const mealCardTags = ["Gluten free", "Vegan", "Gluten free", "Vegan"];
    
    return (
        <>
            <Header />
            <div style={{ height: 20 }} />
            <CartItem
                svg={"/images/no-excuses-1.png"}
                title={"Vibrant Strawberry Spinach Salad"}
                price={useDisplayAmount(1000 / 100)}
                meal={[]}
            />
            <SettingsRowItem
                icon={<DietPreferences className="icon" />}
                title={'Diet prefrences'}
                onClick={() => {
                }}
            />
            <div style={{ height: 20 }} />
            <SettingsRowItem
                title={'Health goal'}
                subTitle={"Weight gain"}
                onClick={() => { }}
            />
            <div style={{ height: 20 }} />
            <Calender />
            <TrackerCard icon={<Corn />} title={"Breakfast"} onPressAdd={() => { }} calories={"213/1980"} onClick={() => { }} />
            <div style={{ marginTop: "10px", gap:" 8px",flexDirection: "column",display: "flex"}}>
            <Alert type="success"/>
            <Alert type="error"/>
            <Alert type="info"/>
            </div>
            <div style={{width:"10%"}}>
            <DropDown placeholderText="Left"/>
            </div>
            <div style={{width:"10%"}}>
            <DropDown icon= "/icons/Right-Icon.svg" />
            </div>
            <div style={{backgroundColor:"white"}}>
            <OrderDetails />
            </div>
            <div style={{ margin: '10px' }}>
                Primary: <Button type={'primary'} iconLeft={<ArrowLeft />} />
                Primary disabled: <Button type={'primary'} isDisabled={true} />
                Primary small: <Button type={'primary'} size={'small'} />
            </div>
            <div style={{ margin: '10px' }}>
                Secondary: <Button type={'secondary'} />
                Secondary disabled: <Button type={'secondary'} isDisabled={true} />
                Secondary small: <Button type={'secondary'} size={'small'} />
            </div>
            <div style={{ margin: '10px' }}>
                Tertiary: <Button type={'tertiary'} />
                Tertiary disabled: <Button type={'tertiary'} isDisabled={true} />
                Tertiary disabled: <Button type={'tertiary'} size={'small'} />
            </div>
            <div style={{ margin: '10px' }}>
                Danger:{' '}
                <Button
                    type={'danger'}
                    iconRight={
                        <FiArrowRight style={{ color: '#fff', fontSize: '20px', fontWeight: '600' }} />
                    }
                />
                Danger disabled: <Button type={'danger'} isDisabled={true} />
                Danger small: <Button type={'danger'} size={'small'} />
            </div>
            <div style={{ margin: '10px' }}>
            <Chip label="Chip Text" />
                <Chip label="Chip Text" leftIcon={<MdCheck style={{ color: theme?.colors?.grayDark }}/>} />
                <Chip label="Chip Text" leftIcon={<MdCheck style={{ color: theme?.colors?.grayDark }}/>} rightIcon={<MdCancel />} />
                <Chip label="Chip Text" rightIcon={<MdCancel style={{ color: theme?.colors?.grayDark }} />} />
            </div>
            <div>
                <Toggle text="Checkbox item" type="checkbox"/>
                <Toggle text="Radio button item"  type="radio" />
                <Toggle text="Switch item" toggleColor={theme.colors.success} placement="left" type="switch" />
                <Toggle text="Switch item" toggleColor={theme.colors.success} placement="right" type="switch" />
            </div>
            <div style={{ margin: '10px', width: '100px' }}>
                <Tab selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
            </div>
            <div style={{ margin: '10px', width: '100px' }}>
                <Tab type="horizontal" selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} />
            </div>
            <div style={{ paddingLeft: "250px" }}>
                <Profile
                    img={'/images/avatar.png'}
                    profileEmail={'john.doe@example.com'}
                    profileName={'John Doe'}
                    showLogout
                    showProfile
                    showBilling
                    showSetting
                    redColor
                />
            </div>


            <Avatar imgSrc="/images/avatar.png" name={"bunty moris"} tooltipColor={"white"} tooltipTextColor={"black"} tooltipDisplay="true" />

            <Sidebar selectedTabId={selectedTabId} setSelectedTabId={setSelectedTabId} navItemsGap={10} />
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ padding: '20px' }}>
                    <Modal />
                </div>
                <div style={{ padding: '20px' }}>
                    <Modal size='small' type='error' description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, sit scelerisque vestib.'} />
                </div>
                <div style={{ padding: '20px' }}>
                    <Modal alignment='center' type='success' />
                </div>

                <div>
                    <Avatar imgSrc="/images/avatar.png" />
                    <Avatar name={"asdf bsdbb"} color={"#b57bff"} bgColor={"pink"} />
                    <Avatar imgSrc="/images/building-black.svg" />
                    <Avatar imgSrc="/images/avatar.png" status="green" />
                    <Avatar imgSrc="/images/avatar.png" status="yellow" />
                    <Avatar imgSrc="/images/avatar.png" status="red" />
                    <Avatar imgSrc="/images/avatar.png" count="2" numbgColor={"#3E66FB"} />
                    <Avatar imgSrc="/images/avatar.png" count="2" status="green" numbgColor={"blue"} />
                    <Avatar imgSrc="/images/avatar.png" name={"bunty moris"} tooltipColor={"white"} tooltipTextColor={"black"} />
                    <Avatar imgSrc="/images/avatar.png" name={"john moris"} color={"yellow"} bgColor={"blue"} count="2" status="green" numbgColor={"pink"} textColor={"black"} />
                </div>
            </div>
            <div style={{ padding: '20px' }}>
                <select onChange={(e) => setCardType(Number(e.target.value))}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>

                <Card type={cardType} />
            </div>
            <SingleLineChart />
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled</div>
                        <RegularInput id="disabled" label="Label" placeholder="Placeholder text" width="250px" disabled />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" disabled />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only</div>
                        <RegularInput id="disabled" label="Label" value="Input text" width="250px" readOnly />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" readOnly />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input With Left Icon</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled</div>
                        <RegularInput id="disabled" label="Label" placeholder="Placeholder text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} disabled />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} disabled />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only</div>
                        <RegularInput id="disabled" label="Label" value="Input text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} readOnly />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} readOnly />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input With Right Icon</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled</div>
                        <RegularInput id="disabled" label="Label" placeholder="Placeholder text" width="250px" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} disabled />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} disabled />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only</div>
                        <RegularInput id="disabled" label="Label" value="Input text" width="250px" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} readOnly />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} readOnly />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input With Double Icon</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled</div>
                        <RegularInput id="disabled" label="Label" placeholder="Placeholder text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} disabled />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} disabled />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only</div>
                        <RegularInput id="disabled" label="Label" value="Input text" width="250px" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} readOnly />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" prefix={<img src='/icons/search-input2.svg'  alt='Icon'/>} suffix={<img src='/icons/angle-down.svg'  alt='Icon'/>} readOnly />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input With Error Message</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" message="Error message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" message="Error message" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" message="Error message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" message="Error message" />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input With Warning Message</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" messageStatus="warning" message="Warning message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" messageStatus="warning" message="Warning message" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" messageStatus="warning" message="Warning message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" messageStatus="warning" message="Warning message" />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Regular Input With Success Message</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" messageStatus="success" message="Success message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <RegularInput id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" messageStatus="success" message="Success message" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <RegularInput id="filled" label="Label" value="Input text" width="250px" messageStatus="success" message="Success message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <RegularInput id="default" label="Label" value="Input text" width="250px" hint="Hint" messageStatus="success" message="Success message" />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Textarea</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <Textarea id="filled" label="Label" value="Input text" width="250px" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <Textarea id="default" label="Label" value="Input text" width="250px" hint="Hint" />
                    </div>
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled</div>
                        <Textarea id="disabled" label="Label" placeholder="Placeholder text" width="250px" disabled />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Disabled (hint)</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" disabled />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only</div>
                        <Textarea id="disabled" label="Label" value="Input text" width="250px" readOnly />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>View only (hint)</div>
                        <Textarea id="default" label="Label" value="Input text" width="250px" hint="Hint" readOnly />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Textarea With Error Message</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" message="Error message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" message="Error message" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <Textarea id="filled" label="Label" value="Input text" width="250px" message="Error message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <Textarea id="default" label="Label" value="Input text" width="250px" hint="Hint" message="Error message" />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Textarea With Warning Message</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" messageStatus="warning" message="Warning message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" messageStatus="warning" message="Warning message" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <Textarea id="filled" label="Label" value="Input text" width="250px" messageStatus="warning" message="Warning message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <Textarea id="default" label="Label" value="Input text" width="250px" hint="Hint" messageStatus="warning" message="Warning message" />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{margin: '0 0 30px 120px', paddingBottom: '16px', fontSize: "16px", fontWeight: "600", color: "#000000", borderBottom: "1px solid #C2C9D1"}}>Textarea With Success Message</div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" messageStatus="success" message="Success message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Default (hint)</div>
                        <Textarea id="default" label="Label" placeholder="Placeholder text" width="250px" hint="Hint" messageStatus="success" message="Success message" />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '100px'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled</div>
                        <Textarea id="filled" label="Label" value="Input text" width="250px" messageStatus="success" message="Success message" />
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: "120px", color: "rgba(0, 0, 0, 0.50)", fontSize: '12px', fontWeight: '600'}}>Filled (hint)</div>
                        <Textarea id="default" label="Label" value="Input text" width="250px" hint="Hint" messageStatus="success" message="Success message" />
                    </div>
                </div>
            </div>
            <div style={{marginBottom: '150px', padding: "30px 20px", display: 'flex'}}>
                <div div style={{padding: '10px 30px 0 0', fontSize: "16px", fontWeight: "600", color: "#000000"}}>Action Button with Dropdown:</div>
                <ActionButton btnText="Action" handleSave={() => console.log('save')} handleEdit={() => console.log('edit')} handleDelete={() => console.log('delete')} />
            </div>
            <div style={{padding: "30px 20px", display: 'flex', alignItems: 'center'}}>
                <div div style={{paddingRight: '30px', fontSize: "16px", fontWeight: "600", color: "#000000"}}>Pie Chart:</div>
                <Pie width={200} height={200} titleNumber={160} titleText="Calories" data={pieData} marginTop={5} marginRight={5} marginBottom={5} marginLeft={5} innerRadius={.75} OuterRadiusHover={5} colors={['#EE786B', '#8ACEBF', '#2399BF']} />
            </div>
            <div style={{padding: "30px 20px", display: 'flex', alignItems: 'center'}}>
                <div div style={{paddingRight: '30px', fontSize: "16px", fontWeight: "600", color: "#000000"}}>Pie Chart with Legend:</div>
                <PieLegend width={150} height={150} titleNumber={2} titleText="Cal" data={pieDataWithLegend} marginTop={5} marginRight={5} marginBottom={5} marginLeft={5} innerRadius={.7} OuterRadiusHover={5} colors={['#EE786B', '#8ACEBF', '#2399BF']} legendLabelWidth={63} />
            </div>
            <div style={{padding: "30px 20px", display: 'flex', alignItems: 'center'}}>
                <div div style={{paddingRight: '30px', fontSize: "16px", fontWeight: "600", color: "#000000"}}>Progress Bar:</div>
                <div>
                    {progressLines.map((line) => (
                        <Progress key={line?.id} width={296} label={line?.label} value={line?.gram} percent={line?.percent} strokeWidth={3} strokeColor={line?.color} trailWidth={3} trailColor='#E9EAEC' />
                    ))}
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)'}}>Mobile Header with Left Icon:</div>
                    <div style={{width: '375px' }}>
                        <MobileHeader text="Health goals" onClickLeft={() => console.log('left')} />
                    </div>
                </div>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)'}}>Mobile Header with Right Icon:</div>
                    <div style={{width: '375px' }}>
                        <MobileHeader text="Health goals" onClickRight={() => console.log('right')} />
                    </div>
                </div>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)'}}>Mobile Header with Right Icon:</div>
                    <div style={{width: '375px' }}>
                        <MobileHeader text="Health goals" className='header-without-icons' />
                    </div>
                </div>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)'}}>Mobile Header with Both Icons:</div>
                    <div style={{width: '375px' }}>
                        <MobileHeader text="Health goals" onClickLeft={() => console.log('left')} onClickRight={() => console.log('right')} />
                    </div>
                </div>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)'}}>Mobile Header with Page Number:</div>
                    <div style={{width: '375px' }}>
                        <MobileHeader text="Health goals" onClickLeft={() => console.log('left')} pageNumber="1/8" />
                    </div>
                </div>
            </div>
            <div style={{width:'352px'}}>
            <AddressBlock />
            </div>
            <div style={{width: '327px'}}>
            <ListWithArrow />
            <div style={{padding: "30px 20px"}}>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)', flexShrink: 0}}>Kitchen Card without Favorite:</div>
                    <div style={{width: '343px', padding: '0 16px' }}>
                        <KitchenCard cardImg="/images/sample.jpg" label="Vegan Kitchen" providerImg="/images/green-plate.png" title="The Green Plate" date="Sunday, MONTH, DAY 12" />
                    </div>
                </div>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)', flexShrink: 0}}>Kitchen Card with Favorite:</div>
                    <div style={{width: '343px', padding: '0 16px' }}>
                        <KitchenCard cardImg="/images/sample.jpg" label="Vegan Kitchen" providerImg="/images/green-plate.png" title="The Green Plate" date="Sunday, MONTH, DAY 12" favorite />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{fontSize: '18px', fontWeight: '600', color: 'rgb(0, 0, 0)', width: 'max-content'}}>Button with Adjustment Item Quantity</div>
                <hr style={{marginBottom: "20px"}} />
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)', flexShrink: 0}}>Enable:</div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={mealCount.default} variant='default' onClickRemove={() => {if(mealCount.default === 1) return; setMealCount({...mealCount, default: mealCount.default - 1})}} onClickAdd={() => setMealCount({...mealCount, default: mealCount.default + 1})} />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={mealCount.dark} variant='dark' onClickRemove={() => {if(mealCount.dark === 1) return; setMealCount({...mealCount, dark: mealCount.dark - 1})}} onClickAdd={() => setMealCount({...mealCount, dark: mealCount.dark + 1})} />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={mealCount.light} variant='light' onClickRemove={() => {if(mealCount.light === 1) return; setMealCount({...mealCount, light: mealCount.light - 1})}} onClickAdd={() => setMealCount({...mealCount, light: mealCount.light + 1})} />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={mealCount.smallLight} variant='light' size='small' onClickRemove={() => {if(mealCount.smallLight === 1) return; setMealCount({...mealCount, smallLight: mealCount.smallLight - 1})}} onClickAdd={() => setMealCount({...mealCount, smallLight: mealCount.smallLight + 1})} />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={mealCount.smallLightRemove} variant='light' size='small' remove onClickRemove={() => {if(mealCount.smallLightRemove === 1) return; setMealCount({...mealCount, smallLightRemove: mealCount.smallLightRemove - 1})}} onClickAdd={() => setMealCount({...mealCount, smallLightRemove: mealCount.smallLightRemove + 1})} />
                    </div>
                </div>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)', flexShrink: 0}}>Disable:</div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={1} variant='default' disabled />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={1} variant='dark' disabled />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={1} variant='light' disabled />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={1} variant='light' size='small' disabled />
                    </div>
                    <div style={{ marginRight: '30px' }}>
                        <QuantityButton count={1} variant='light' size='small' remove disabled />
                    </div>
                </div>
            </div>
            <div style={{padding: "30px 20px"}}>
                <div style={{paddingBottom: "20px", display: 'flex', alignItems: 'center'}}>
                    <div style={{paddingRight: '30px', fontSize: '16px', fontWeight: '600', color: 'rgb(0, 0, 0)', flexShrink: 0}}>Meal Card:</div>
                    <div style={{width: '343px', padding: '0 16px' }}>
                        <MealCard mealImg='/images/sexy_just_eat_now.jpg' calories={640} title="Blossoming Spring Mix" tags={mealCardTags} price={21} count={mealCount.mealCard} onClickRemove={() => {if(mealCount.mealCard === 1) return; setMealCount({...mealCount, mealCard: mealCount.mealCard - 1})}} onClickAdd={() => setMealCount({...mealCount, mealCard: mealCount.mealCard + 1})} />
                    </div>
                </div>
            </div>
            </div>
            <div style={{display: "inline-flex", gap:"10px"}}>
                <MessageModal />
                <MessageModal img={<Lock />} title={"Success"} description='Your password has been updated' firstBtnTitle={"Ok"} firstBtnClick={() => history.push('/login')} success={true}/>         
          </div>
          <div style={{ maxWidth: '343px' }}>
            <UserMealCard imageDimension={95} noPadding={true} hasOptions={false}/>
            <UserMealCard noPadding={false} options={true} iconPositionEnd={14}/>
          </div>
        </>
    );
}
export default NewFile