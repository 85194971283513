import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useAppContext } from 'context/appContext';
import MealDragDrop from 'components/shop/MealDragDrop';
import DietPlannerCheckout from 'components/shop/MealDragDrop/DietPlannerCheckout';

const VisitorMealsPlannerWrapper = styled.div`
  width: 430px;
  padding-top: 90px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) -6px 0px 4px -2px;

  .title-wrapper {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      margin-left: 12px;
      color: #0b1d17;
    }
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid #e0e3e0;
    justify-content: center;

    .MuiTab-textColorInherit {
      margin-bottom: 10px;

      span {
        color: #51635d;
        font-size: 18px;
        font-family: 'Roboto';
        line-height: 24px;
      }

      &.Mui-selected span {
        color: #52c41a;
        font-family: 'Roboto';
      }
    }
  }

  .MuiTabs-indicator {
    height: 3px !important;
  }

  @media (min-width: 992px) {
    .title-wrapper {
      margin-bottom: 0px;
    }

    .setting-icon-wrapper{
      top: 132px; 
    }

    .meal-plan-body{
      height: calc(100vh - 360px);
    }
  }
`;

export default function VisitorMealsPlanner() {
  return (
    <VisitorMealsPlannerWrapper>
      <div className="title-wrapper">
        <img src="/icons/menu-board-black.svg" alt="menuBoard icon" />
        <h2>Diet planner</h2>
      </div>
      <div style={{"height": '61px', "border-bottom": "1px solid #E0E3E0"}}></div>
      <MealDragDrop />
      <DietPlannerCheckout />
    </VisitorMealsPlannerWrapper>
  );
}
