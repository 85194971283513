import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'shared/theme/theme'
import Chip from 'components/elements/chipModule/Chip'
import { MdEdit, MdLocationOn, MdCancel } from 'react-icons/md'
import { Button } from 'components/elements/button/Button'
import { Map } from 'components/elements/map/Map'
import RegularInput from 'components/Input/RegularInput'
import { Modal } from 'components/elements/modal/Modal'
import Alert from 'components/elements/alert/Alert'
import { useAppContext } from 'context/appContext'
import { useRequest } from 'hooks/useRequest'
import { useKitchenContext } from 'context/kitchenContext'
import { pointInPolygon } from 'utils/supportsDeliveryAndPickup';

const DeliveryAreaStyled = styled.div`
    position: relative;
    .btn_flex{
        display: flex;
        gap: 8px;
        position: absolute;
        right: 0;
        top: 0;
        .custom_button{
            position: relative;
            &:last-child{
                background: ${({ theme }) => theme?.colors?.primaryDefault};
                color: ${({ theme }) => theme?.colors?.white};
            }
        }
    }
    .custom_button{
        height: 35px;
        padding: ${({ isEdit }) => !isEdit ? '8px 8px 8px 16px' : '8px 16px'};
        font-size: ${({ theme }) => theme?.typography?.fontSmall};
        font-weight: 600;
        line-height: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
        color: ${({ theme }) => theme?.colors?.primaryDark};
        background: ${({ theme }) => theme?.colors?.white};
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 9;
        cursor: pointer;
        .icon{
            svg{
                display: block;
            }
        }
    }
    > .title{
        color: ${({ theme }) => theme?.colors?.blackDefault};
        font-size: ${({ theme }) => theme?.typography?.fontLarge};
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 22px;
    }
    >.subtitle{
        color: ${({ theme }) => theme?.colors?.grayDark};
        margin-bottom: 12px;
    }
    .zipCode_holder{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 24px;
        .chip_wrapper{
            gap: 4px;
            padding-right: 8px;
            margin-right: 0;
            .text{
                color: ${({ theme }) => theme?.colors?.blackDefault};
            }
        }
    }
    .map_holder{
        #map{
            height: calc(100vh - 445px);
            width: 99.5%;
        }
    }
    .zip_edit_holder{
        display: flex;
        justify-content: space-between;
        margin: 24px 0;
        .input-wrapper{
            width: 374px;
            .suffix{
                right: 13px;
                font-size: ${({ theme }) => theme?.typography?.fontRegular};
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;
            }
        }
    }
    .rel{
        position: relative;
    }
`

const CancelStyle = styled.div`
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgba(40, 50, 65, 0.6);
`

const AlertStyle = styled.div`
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    .alert_bar{
        max-width: 461px;
        width: 461px;
        top: 19px;
        height: 40px;
        padding: 0;
        justify-content: center;
        .cross{
            top: 50%;
            transform: translateY(-50%);
        }
    }
`

const ConfirmationStyle = styled.div`
.modal-position {
  position: fixed;
  inset: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(40, 50, 65, 0.6);
}
`;

export const DeliveryArea = () => {
    const { provider: {BusinessProfileSettings, BusinessProfile}, provider, setProvider, user, isKitchenHasSubscription } = useAppContext();
    const { deliveryCoverage, deliveryFee, providerId, id } = BusinessProfileSettings[0] || [];
    const { id: businessProfileId } = BusinessProfile[0] || [];
    const [deliveryZipcodes, setDeliveryZipcodes] = useState(deliveryCoverage);
    const [isEdit, setIsEdit] = useState(false);
    const [mapEdit, setMapEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [newZip, setNewZip] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled]=useState(true);
    const [updatedDeliveryArea, setUpdatedDeliveryArea] = useState();
    const [updateProvider,{isLoading:isBusinessProfileSaving}] = useRequest(`provider/businessProfile`, 'put');
    const [isCancelled, setIsCancelled] = useState(false);
    const [isErrorUpdating, setIsErrorUpdating] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const kitchenId = provider.id;
    const { kitchenHasSubscription, setKitchenHasSubscription } = useKitchenContext();
    useEffect(() => {
        if(isCancelled){
            setDeliveryZipcodes(deliveryCoverage);
            setIsCancelled(false);
        }
    }, [isCancelled])

    const handleSave = async () => {
        if (isBusinessProfileSaving) return;
        setIsOpen(false);
        const body = {
            id: businessProfileId,
            deliverableAreas: {
                data: updatedDeliveryArea
            },
            deliveryCoverage: deliveryZipcodes
        }
        await updateProvider({ body }).then((data) => {
            if(data?.data?.success){
                setProvider((prev) => ({
                    ...prev,
                    BusinessProfileSettings: [
                        {
                            ...prev?.BusinessProfileSettings[0],
                            deliverableAreas: {
                                data: updatedDeliveryArea
                            },
                            deliveryCoverage: deliveryZipcodes
                        }
                    ]
                }))
                setIsSuccess(true);
                setIsEdit(false);
                setMapEdit(false);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 2000)
            }
            setIsButtonEnabled(true)
        }).catch((err) => {
            setIsButtonEnabled(true)
            setIsErrorUpdating(true);
            setTimeout(() => {
                setIsErrorUpdating(false);
            }, 2000)
        })
    }

    const handleCancel = () => {
        if(deliveryZipcodes !== deliveryCoverage){
            setIsCancel(true);
        } else if (!isButtonEnabled) {
            setIsCancel(true);
        }
        else {
            setIsEdit(false);
        }
    }

    const handleZipAdd = () => {
        if (deliveryZipcodes.includes(newZip)) return;
        setDeliveryZipcodes((prev) => [...prev, newZip]);
        setNewZip('');
    }

    const handleZipRemove = (zipcode) => {
        const newZip = deliveryZipcodes?.filter((item) => item !== zipcode);
        setDeliveryZipcodes(newZip);
    }

    const handleChange = (e) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, '');
        const trimValue = numericValue.slice(0, 5);
        setNewZip(trimValue);
      };
      useEffect(() => {
        const isMatch = deliveryZipcodes?.includes(newZip);
        const isZipValid = /^\d{3,5}$/.test(newZip);
        
        const dataIsDifferent = JSON.stringify(deliveryCoverage) !== JSON.stringify(deliveryZipcodes);
    
        if (!dataIsDifferent) {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(isMatch && isZipValid);
        }
    }, [deliveryZipcodes]);

    const handleSaveFn = () => {
      let hasSubsciption=false;
      kitchenHasSubscription?.hasSubsciption && kitchenHasSubscription.deliveryInfo.map((address) => {
        const isDeliveryCoverageInRange = deliveryZipcodes.includes(address.zipcode);
        const isDeliverableAreaInRange = pointInPolygon(
          address?.userAddressLatLong,
          updatedDeliveryArea,
        );
        if (!isDeliveryCoverageInRange && !isDeliverableAreaInRange && address.isDelivery) {
          hasSubsciption = true;
          return;
        }
      });
      if (kitchenHasSubscription.hasSubsciption && hasSubsciption) {
        setIsOpen(true);
      } else {
        handleSave();
      }
    };

    const fetchHasSubscriptionData = async () => {
      try {
        if (kitchenId) {
          const hasSubscription = await isKitchenHasSubscription({ kitchenId });
          setKitchenHasSubscription(hasSubscription);
        }
      } catch (err) {
        console.log(err, 'err');
      }
    };

    const handleEdit = () =>{
        setIsEdit(true);
        fetchHasSubscriptionData();
    };
    const subscriptionCount = kitchenHasSubscription?.subscriptionCount;
    return (
        <>
        <DeliveryAreaStyled theme={theme} isEdit={isEdit}>
            {!isEdit ?
                <Button title='Edit' className="custom_button" onClick={handleEdit} iconRight={<MdEdit />} />
                :
                <div className='btn_flex'>
                    <Button title='Cancel' size={"small"} type={'secondary'} onClick={handleCancel} />
                    <Button title='Save' size={"small"} type={'dark'} onClick={handleSaveFn} isDisabled={isButtonEnabled} />
                </div>
            }
            <h2 className='title'>Delivery area</h2>
            <p className='subtitle'>Delivery zipcodes</p>
            <div className='zipCode_holder'>
                {deliveryZipcodes?.map((zipcode) => <Chip label={zipcode} 
                leftIcon={<MdLocationOn style={{ color: theme?.colors?.grayDark }} />} 
                rightIcon={isEdit && <MdCancel style={{ color: theme?.colors?.grayDark }} onClick={() => handleZipRemove(zipcode)}/>} color={theme.colors.grayLighter}/>)}
            </div>
            {isEdit && <div className='zip_edit_holder'>
                <div><RegularInput placeholder="E.g. 11221" value={newZip} onChange={handleChange} suffix="Add" onSuffixClick={handleZipAdd} 
                messageStatus="warning" message={deliveryZipcodes?.includes(newZip) && "Delivery zipcode exists"} /></div>
                <Button title='Draw deliverable area' className="custom_button rel" onClick={() => setMapEdit(true)} iconRight={<MdEdit />} />
            </div>}
            <div className='map_holder'>
                <Map isCancelled={isCancelled} isMapEditable={mapEdit} setIsMapEditable={setMapEdit} setUpdatedArea={setUpdatedDeliveryArea} setIsButtonEnabled={setIsButtonEnabled}deliveryZipcodes={deliveryZipcodes}/>
            </div>
        </DeliveryAreaStyled>
        { isCancel && <CancelChanges setIsCancel={setIsCancel} setIsEdit={setIsEdit} setMapEdit={setMapEdit} setIsCancelled={setIsCancelled}/> }
        {isSuccess &&
        <AlertStyle className='changes_saved'>
            <Alert onClose={() => setIsSuccess(false)} title='You have successfully updated delivery area' />
        </AlertStyle> }
        {isErrorUpdating &&
        <AlertStyle className='changes_saved'>
            <Alert type={'error'} onClose={() => setIsErrorUpdating(false)} title='Error updating delivery area' />
        </AlertStyle> }
        {isOpen && (
        <ConfirmationStyle>
            <div className="modal-position">
                <Modal
                  size="large"
                  type="error"
                  description={`The system detects ${subscriptionCount} active subscriptions for this kitchen, and any changes made may cancel their recurring orders. Are you sure you want to proceed?`}
                  onOk={handleSave}
                  isOpen={isOpen}
                  onClose={() => setIsOpen(!isOpen)}
                  onCancel={() => setIsOpen(!isOpen)}
                  title={'Are you sure?'}
                  primaryText={`Save`}
                  secondaryText={'Cancel'}
                  isBackdropEnabled={true}
                  buttonType={'dark'}
                />
              </div>
        </ConfirmationStyle>
            )}
        </>
    )
}

const CancelChanges = ({ setIsCancel, setIsEdit, setMapEdit, setIsCancelled }) => {
    const handleCancel = () => {
        setIsCancel(false);
    }

    const handleOk = () => {
        setIsCancel(false);
        setIsEdit(false);
        setMapEdit(false);
        setIsCancelled(true);
    }

    return(
        <>
            <CancelStyle>
                <Modal type='error' title='Unsaved changes' description={'Are you sure to cancel? Your changes to the delivery area will not be saved'} primaryText={'Ok'} secondaryText={'Cancel'} buttonType={'dark'} onOk={handleOk} secondaryButtonType={'secondary'} onClose={handleCancel} onCancel={handleCancel}/>
            </CancelStyle>
        </>
    )
}