import { useState, useEffect, useRef } from 'react';

const useProgressiveImage = (image, imageReference) => {
  const [src, setSrc] = useState(image);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const handleImageLoaded = () => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setIsImageLoaded(true);
      };
    };

    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setIsInView(true);
        observerRef.current.disconnect();
        handleImageLoaded();
      } else {
        setIsInView(false);
      }
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.2,
    });

    if (imageReference?.current) {
      observerRef.current.observe(imageReference.current);
    }

    return () => {
      if (observerRef.current && imageReference?.current) {
        observerRef.current.disconnect();
      }
    };
  }, [src, imageReference]);

  useEffect(() => {
    setSrc(image);
    setIsImageLoaded(false);
    setIsInView(false);
  }, [image]);

  return [src, { isImageLoaded, isInView }];
};

export default useProgressiveImage;
