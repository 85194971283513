import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import { MdEdit } from 'react-icons/md';
import Toggle from 'components/elements/toggleModule/Toggle';
import RegularInput from 'components/Input/RegularInput';
import { Modal } from 'components/elements/modal/Modal';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import Alert from 'components/elements/alert/Alert';

const OrdersModel = styled.div`
  position: relative;
  width: 100%;
  .modal-position {
    position: fixed;
    inset: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(40, 50, 65, 0.6);
  }
  .alert {
    position: fixed;
    inset: 0;
    z-index: 10;
    text-align: -webkit-center;
    height: 76px;
    width: 100%;
    top: 12px;
  }
  .btn_flex {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0;
    top: 24px;
    .custom_button {
      position: relative;
      &:last-child {
        background: ${({ theme }) => theme?.colors?.primaryDefault};
        color: ${({ theme }) => theme?.colors?.white};
      }
    }
  }
  .heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
  .delivery-info {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 8px;
    .toggle-mask {
      position: absolute;
      bottom: 30px;
      left: auto;
      z-index: 1;
      width: 33px;
      height: 20px;
      cursor: pointer;
    }
  }
  text {
    width: 250px;
    height: 16px;
    gap: 8px;
    padding-right: 0px;
    color: ${({ theme }) => theme?.colors?.grayDark};
  }
  .custom_button {
    padding: ${({ isEdit, isMininumOrderEdit }) =>
      isEdit || isMininumOrderEdit ? '8px 8px 8px 16px' : '8px 16px'};
    font-size: ${({ theme }) => theme?.typography?.fontSmall};
    font-weight: 600;
    line-height: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme?.colors?.primaryDark} !important;
    color: ${({ theme }) => theme?.colors?.primaryDark};
    background: ${({ theme }) => theme?.colors?.white};
    position: absolute;
    right: 0;
    top: 24px;
    z-index: 9;
    cursor: pointer;
    .icon {
      svg {
        display: block;
      }
    }
  }
`;
const FormGroupStyle = styled.div`
  position: relative;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid ${({ theme }) => theme?.colors?.borderLight};
  width: 100%;
  label {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme,isMininumOrderEdit, isEdit }) => (isMininumOrderEdit || isEdit) ? theme?.colors?.blackDefault : theme?.colors?.grayDark};
    margin-bottom: 8px;
    display: inline-block;
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 400;
    line-height: 20px;
    color: ${({ theme }) => theme?.colors?.blackDefault};
  }
`;
function Orders() {
  const [isMininumOrderEdit, setIsMininumOrderEdit] = useState(false);
  const { provider, setProvider } = useAppContext();
  const [updatedProvider,{isLoading:isBusinessProfileSaving}] = useRequest(`provider/businessProfile`, 'put');
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOrderAmountButtonEnabled, setIsOrderAmountButtonEnabled]=useState(true);
  const [isDeliveryButtonEnabled, setIsDeliveryButtonEnabled]=useState(true);
  const { BusinessProfileSettings, BusinessProfile } = provider || {};
  const {autoAcceptOrder, minOrderAmount, deliveryFee} = BusinessProfileSettings[0] || [];
  const [addressInfo] = BusinessProfile || [];
  const [isChecked, setIsChecked] = useState(autoAcceptOrder);
  const kitchenId = provider.id;
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
  const [orderDetails, setOrderDetails] = useState({
    minOrderAmount: minOrderAmount,
    deliveryFee: deliveryFee,
  });
  const [isAlertOpen, setIsAlertOpen] = useState({isOpen: false, key: ''});
  const { isKitchenHasSubscription } = useAppContext();
  const [kitchenHasSubscription, setKitchenHasSubscription] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (kitchenId) {
          const isSubscription = await isKitchenHasSubscription({ kitchenId });
          setKitchenHasSubscription(isSubscription);
        }
      } catch (err) {
        console.log(err, 'err');
      }
    };
    fetchData();
  }, []);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9.]/g, '');
    const fixedValue = numericValue.indexOf(".") >= 0 ? numericValue.slice(0, numericValue.indexOf(".") + 3) : numericValue;

    setOrderDetails((prevState) => ({
      ...prevState,
      [name]: fixedValue,
    }));
  
    if (name === 'minOrderAmount') {
      setIsOrderAmountButtonEnabled(fixedValue == minOrderAmount);
    }
    if (name === 'deliveryFee') {
      setIsDeliveryButtonEnabled(fixedValue == deliveryFee);
    }
  };

const handleSectionSave = async (sectionType, changedValues, buttonText, setIsEditing, setIsButtonEnabled) => {
  if (isBusinessProfileSaving) return;
  try {
    const body = {
      ...changedValues,
      id: addressInfo?.id,
    };
    setIsOpen(false);
    setIsAlertOpen({ ...isAlertOpen, isOpen: false });
    await updatedProvider({ body });
    setProvider((prevProvider) => {
      const newBusinessProfileSettings = [...prevProvider.BusinessProfileSettings];
      newBusinessProfileSettings[0] = {
        ...newBusinessProfileSettings[0],
        ...changedValues,
      };
      return {
        ...prevProvider,
        BusinessProfileSettings: newBusinessProfileSettings,
      };
    });
    setIsEditing(false);
    setIsButtonEnabled(true);
    setIsChecked(changedValues.autoAcceptOrder !== undefined ? changedValues.autoAcceptOrder : isChecked);
    showSuccessMessage(`You have successfully updated ${sectionType} information`);
  } catch (error) {
    console.error('Error occurred while saving changes:', error);
    showErrorMessage('Error occurred while saving changes');
  }
};

const handleToggleSave = async (newCheckedValue) => {
  const updatedValue = !newCheckedValue; // Toggle the value
  handleSectionSave('order', { autoAcceptOrder: updatedValue }, 'order', setIsOpen, setIsChecked);
};

const increasedMinOrderAmount = Number(orderDetails.minOrderAmount) > minOrderAmount;
const handleSave = async () => {
  if (kitchenHasSubscription?.hasSubsciption && increasedMinOrderAmount) {
    setIsAlertOpen({ isOpen: true, key: "minOrderAmount"});
  } else {
    handleSectionSave('order', { minOrderAmount: Number(orderDetails.minOrderAmount) }, 'order', setIsMininumOrderEdit, setIsOrderAmountButtonEnabled);
  }
};

const handleDeliveryFeeEdit = async () => {
  if (kitchenHasSubscription?.hasSubsciption && Number(deliveryFee) !== Number(orderDetails.deliveryFee)) {
    setIsAlertOpen({isOpen: true, key: "deliveryFee"});
  } else {
    handleSectionSave(
      'delivery',
      { deliveryFee: Number(orderDetails.deliveryFee) },
      'delivery',
      setIsEdit,
      setIsDeliveryButtonEnabled,
    );
  }
};

  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const handleSaveFn = () =>{
    if(isAlertOpen?.isOpen && isAlertOpen.key === "minOrderAmount"){
      handleSectionSave(
        'order', 
        { minOrderAmount: Number(orderDetails.minOrderAmount) }, 
        'order', 
        setIsMininumOrderEdit, 
        setIsOrderAmountButtonEnabled
      );
    };
    if(isAlertOpen?.isOpen && isAlertOpen.key === "deliveryFee") {
      handleSectionSave(
        'delivery',
        { deliveryFee: Number(orderDetails.deliveryFee) },
        'delivery',
        setIsEdit,
        setIsDeliveryButtonEnabled,
      )
    }
  };

  const subscriptionCount = kitchenHasSubscription?.subscriptionCount;
  const modalDes = `The system detects ${subscriptionCount} active subscriptions for this kitchen, and any ${isAlertOpen.key === "minOrderAmount" ? "increment to the value may cancel their recurring orders." : "changes made may affect their recurring orders."} Are you sure you want to proceed?`

  return (
    <OrdersModel theme={theme}>
      {isOpen && (
        <div className="modal-position">
          <Modal
            size="large"
            type="error"
            description={
              autoAcceptOrder ?
              `Are you sure to turn off auto-accept orders? If you turn off auto-accept orders, you have to accept orders manually.`
              :
              `Are you sure you want to turn on auto-accept orders? If you turn on auto-accept orders, incoming orders will be automatically accepted without manual intervention.
              `
            }
            onOk={() => handleToggleSave(isChecked)}
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
            onCancel={() => setIsOpen(!isOpen)}
            title={'Are you sure?'}
            primaryText={`${isChecked ? 'Turn off' : 'Turn On'}`}
            secondaryText={'Cancel'}
            isBackdropEnabled={true}
            buttonType={'dark'}
          />
        </div>
      )}
       {isAlertOpen.isOpen && (
          <div className="modal-position">
            <Modal
              size="large"
              type="error"
              description={modalDes}
              onOk={handleSaveFn}
              isOpen={isAlertOpen}
              onClose={() => setIsAlertOpen({ ...isAlertOpen, isOpen: !isAlertOpen })}
              onCancel={() => setIsAlertOpen({...isAlertOpen, isOpen: !isAlertOpen})}
              title={'Are you sure?'}
              primaryText={`Save`}
              secondaryText={'Cancel'}
              isBackdropEnabled={true}
              buttonType={'dark'}
            />
          </div>
        )}
      {alertData.type && (
        <div className="alert">
          <Alert
            type={alertData.type}
            title={alertData.title}
            width="461px"
            onClose={() => setAlertData({ type: '', title: '' })}
          />
        </div>
      )}
      <h2 className="heading">Orders</h2>
      <div className="order_amount">
        <FormGroupStyle theme={theme} isMininumOrderEdit={isMininumOrderEdit}>
          <label>Minimum order amount</label>
          {!isMininumOrderEdit ? (
            <Button
              title="Edit"
              className="custom_button"
              onClick={() => setIsMininumOrderEdit(true)}
              iconRight={<MdEdit />}
            />
          ) : (
            <div className="btn_flex">
              <Button
                title="Cancel"
                size={"small"} type={'secondary'}
                onClick={() => {setIsMininumOrderEdit(false),setOrderDetails((prev)=>({...prev,minOrderAmount: minOrderAmount }));
                }}
              />
              <Button
                title="Save"
                size={"small"} type={'dark'}
                onClick={() => handleSave(orderDetails)}
                isDisabled={isOrderAmountButtonEnabled||isBusinessProfileSaving}

              />
            </div>
          )}{' '}
          {!isMininumOrderEdit ? (
            <p>{orderDetails?.minOrderAmount ?`$${orderDetails?.minOrderAmount}` : 0}</p>
          ) : (
            <RegularInput
              type="text"
              value={`$${orderDetails?.minOrderAmount ? orderDetails.minOrderAmount : " "}`}
              width={'280px'}
              name="minOrderAmount"
              onChange={handleChange}
            />
          )}{' '}
        </FormGroupStyle>
        <FormGroupStyle theme={theme} isEdit={isEdit}>
          <label>Delivery fee $</label>
          {!isEdit ? (
            <Button
              title="Edit"
              className="custom_button"
              onClick={() => setIsEdit(true)}
              iconRight={<MdEdit />}
            />
          ) : (
            <div className="btn_flex">
              <Button
                title="Cancel"
                size={"small"} type={'secondary'}
                onClick={() => {setIsEdit(false),setOrderDetails((prev)=>({...prev,deliveryFee: deliveryFee }))}}
              />
              <Button
                title="Save"
                size={"small"} type={'dark'}
                onClick={() => handleDeliveryFeeEdit(orderDetails)}
                isDisabled={isDeliveryButtonEnabled||isBusinessProfileSaving}
              />
            </div>
          )}{' '}
          {!isEdit ? (
            <p>{orderDetails?.deliveryFee}</p>
          ) : (
            <RegularInput
              type="text"
              value={orderDetails?.deliveryFee}
              width={'280px'}
              name="deliveryFee"
              onChange={handleChange}
            />
          )}{' '}
        </FormGroupStyle>
        <div className="delivery-info">
          <text>Auto-accept orders </text>
          <div className='toggle-mask' onClick={() => setIsOpen(true)} />
          <Toggle
            name="autoAcceptOrder"
            text={isChecked ? 'On' : 'Off'}
            toggleColor={theme.colors.success}
            type="switch"
            toggleWidth="32px"
            toggleHeight="18px"
            width="13.33px"
            height="14.33px"
            gap="12px"
            transform="14px"
            onChange={true}
            checked={isChecked}
            setIsChecked={setIsChecked}
            handleToggleSave={false}
          />
          <Button
            title="Edit"
            className="custom_button"
            onClick={() => setIsOpen(true)}
            iconRight={<MdEdit />}
          />
        </div>
      </div>
    </OrdersModel>
  );
}

export default Orders;
