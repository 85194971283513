import classNames from 'classnames';
import styled from 'styled-components';
import PantryFooter from 'components/Pantry/PantryFooter';
import PlanListHeader from 'components/shop/CoachProfile/PlansList';
import PlanCard from 'components/shop/CoachProfile/PlansList/PlanCard';
import MealCard from './MealCard';
import { useAppContext } from 'context/appContext';
import { useRequest } from 'hooks/useRequest';
import React, { useState, useEffect } from 'react';
import RegisterForm from 'components/auth/RegisterForm';
import Register from 'pages/auth/Register';
import { useLocation } from 'react-router-dom';
import Header from 'layout/Header';
import { FormContainer } from 'components/auth/StyledLoginForm';
import RegisteredDetitianCard from 'components/OnboardingPlans/RegisteredDetitianCard';
import NutritionistQuote from 'components/NutritionistQuote';
import NutrittSteps from 'components/NutrittSteps';

const MealPlansListStyle = styled.div`
  width: 100%;
  padding: 20px 0 50px !important;
  justify-content: center;

  .local{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #949D9B;
    display:flex;
    justify-content:center;
    margin: 15px 0px 25px;
  }
  .plan-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 991px) {
    min-height: calc(100vh - 32px);
    background-color: #f6f5f8;
  padding: 0px 0 50px !important;

  .local{
    margin: 15px 0px 15px !important;
  }

  }
  @media (min-width:280px) and (max-width:300px){
    .local{
      text-align:center;
    }
  }
`;


export default function MealCardList({setRegisterCard , setMealPlanId}) {
  const { user , nutritionistId, isMobile, nutritionistInfo: nutritionist, isVisitor} = useAppContext();
  const [getMealPlanList] = useRequest();
  const [mealList, setMealList] = useState();
  const { pathname } = useLocation();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  useEffect(() => {
    getMealPlanListData();
  }, []);
  const getMealPlanListData = () => {
    const selectedNutritionistId = (!isVisitor && user && user?.nutritionist) ? user?.nutritionist?.id : nutritionistId;
    const route = `nutritionist/dietTemplate?nutritionistId=${selectedNutritionistId}`;
    getMealPlanList({
      path: route,
    })
      .then((data) => {
        setMealList(data?.dietTemplates);
      })
      .catch(console.log);
  };
  return  (
  <>
      <MealPlansListStyle>
        <div className='local'>
        <p>DELIVERED BY PREMIUM LOCAL CHEFS</p>
        </div>
        <div className={classNames('plan-card-wrapper')}>
        {nutritionist?.dietTemplates?.map((item, index) => {
      
          return (
            <MealCard
              name={item?.name}
              image={item?.image}
              duration={item?.duration}
              duration_type={item?.duration_type}
              type={item?.type}
              introduction={item.introduction}
              mealPlan={true}
              id={item?.id}
              key={index}
              setRegisterCard={setRegisterCard}
              setMealPlanId={setMealPlanId}
              carbs={item?.carbs_percent}
              protein={item?.protein_percent}
              fat={item?.fat_percent}
              tags={item?.tags}
              food_to_avoid={item?.food_to_avoid}
              tips={item?.tips}
              diet_formula={item?.BMR_formula}
              PAL={item?.PAL}
              goal={item?.goal}
              goal_type={item?.goal_type}
            />
          );
        })}
        </div>
      </MealPlansListStyle>
      {/* {isMobile && user?.id && <PantryFooter myCoach={true} shop={false} progress={false}/>} */}
    </>
  );
}

