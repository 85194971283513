import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import SubmitButton from 'components/SubmitButton';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const PayAllModal = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 111;
  display: flex;
  -webkit-box-align: center;
  background-color: rgb(28 28 28 / 60%);
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 0px;
  font-family: 'Roboto';
  font-style: normal;

  .invite-box {
    position: fixed;
    height: 280px;
    width: 370px;
    padding-bottom: 35px;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .close-img-div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      img {
        cursor: pointer;
      }
    }
    .qusetionMark {
      transform: translateY(-25px);
      margin: auto;
      background: #f0faeb;
      width: 64px;
      border-radius: 35px;
      height: 64px;
    }
    .questionIcon {
      width: 38px;
      height: 38px;
      margin: 12px;
    }
    .send-invite {
      text-align: center;
      margin: 0px 32px;
      padding-top: 5px;
    }

    .coupon-form {
      display: flex;
      height: 40%;

      .publish {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: auto;
        .btn {
          margin: 10px;
        }
        .icon {
          margin-right: 9px;
        }
        button {
          width: 140px;
          height: 42px;
          background: #52c41a;
          border-radius: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          cursor: pointer;
        }
        button1 {
          width: 140px;
          height: 42px;
          border: 1px solid #97dc76 !important;
          border-radius: 21px;
          background: #ffffff;
          color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          cursor: pointer;
        }
        .btn-end1 {
          border-radius: 31px;
          border: 1px solid #97dc76 !important;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.22% 2.22% 0 0;
          gap: 5px;
          background: #ffffff;
          height: 47px;
          width: 140px;
          color: black;
        }
        button: disabled {
          background-color: #e0e3e0;
          color: #949d9b;
          cursor: not-allowed;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .invite-box {
      width: 88%% !important;
      height: 50%;
      border-radius: 0 !important;
      padding-bottom: 0 !important;

      .send-invite {
        display: flex;
        justify-content: center;
        padding-left: 0 !important;
        color: #51635d !important;
        font-size: 18px !important;
        line-height: 24px;
      }

      .close-img-div {
        display: flex;
        justify-content: flex-start !important;
        padding: 20px 0px 0px 20px !important;
        img {
          cursor: pointer;
        }
      }
    }
    .coupon-form {
      height: calc(100vh - 200px);

      .multiple-form-div {
        height: calc(100vh - 225px) !important;
        height: auto !important;

        .sc-hgIrPW {
          .input-container {
            div:nth-child(1) {
              width: 100% !important;
            }
          }
        }
      }
    }

    .publish {
      button {
        width: 155px !important;
      }
    }
  }
`;
export default function PayAllForm({
  setPayIsModal,
  order,
  fetchAllProviders,
  selectedMeals,
  sum,
  selectedNonMeals,
  setActiveOrderId,
  setIsOrderDetailsActive,
}) {
  const [updateDisbursement] = useRequest(`financials/disbursements/pay`, 'put');
  const { isMobile } = useAppContext();
  const orderLineData = order.disbursementList.map((data) => {
    const orderLines = data.providerOrder.orderMain.orderLine;
    return {
      disbursementId: data.id,
      orderLines: orderLines,
    };
  });

  const filteredData = orderLineData.reduce((result, data) => {
    const refundStatus = data.orderLines.filter((item) => item.refundStatus === 'pending');
    const orderMainId = refundStatus.length > 0 ? refundStatus[0].mainOrderId : null;
    const disbursementId = data.disbursementId;

    const existingEntry = result.find((entry) => entry.disbursementId === disbursementId);
    if (existingEntry) {
      existingEntry.orderLineIds.push(...refundStatus.map((item) => ({ id: item.id })));
    } else {
      result.push({
        disbursementId: disbursementId,
        orderMainId: orderMainId,
        orderLineIds: refundStatus.map((item) => ({ id: item.id })),
      });
    }

    return result;
  }, []);

  const onDisbursementClick = useCallback((filteredData) => {
    updateDisbursement({ body: { orders: filteredData, datePaid: new Date().toISOString() } })
      .then((result) => {
        toast.success('Disbursement has been made successfully');
        fetchAllProviders();
        setPayIsModal(false);
      })
      .catch((err) => {
        if (err.message != 'jwt expired') {
          toast.error('Oops!, something went wrong');
        }
      });
  }, []);

  const [updateNewDisbursement] = useRequest(`financials/disbursements/void`, 'put');
  const [updateSelectedPay] = useRequest(`financials/disbursements/pay`, 'put');

  const ordersToMap = useMemo(() => {
    let resp = [];
    for (let key in selectedMeals) {
      const item = selectedMeals[key];
      for (let index = 0; index < item.data.length; index++) {
        const element = item.data[index];
        resp.push(element);
      }
    }
    return resp;
  }, [selectedMeals]);

  let mealSelectedLength = ordersToMap.length;
  let itemIsNotSelected = sum - mealSelectedLength;

  const finalObject = Object.entries(selectedMeals).map(([orderId, meals]) => {
    return {
      orderMainId: Number(orderId),
      disbursementId: meals.disbursementId,
      orderLineIds: meals.data.map((meal) => ({ id: meal.id })),
    };
  });

  const finalObject2 = Object.entries(selectedNonMeals).map(([orderId, meals]) => {
    const filteredData = meals.data.filter(meal => meal.refundStatus !== "voided" && meal.refundStatus !== "completed");
    return {
      orderMainId: Number(orderId),
      disbursementId: meals.disbursementId,
      orderLineIds: filteredData.map((meal) => ({ id: meal.id })),
    };
  });
  let itemIsNotSelectedMobile;
  if (isMobile && finalObject2.length > 0) {
    itemIsNotSelectedMobile = finalObject2[0].orderLineIds.length;
  }

  const combinedArray = finalObject.concat(finalObject2);

  const payAll = useCallback((combinedArray) => {
    const Pay = updateSelectedPay({
      body: { orders: combinedArray, datePaid: new Date().toISOString() },
    })
      .then(() => {
        fetchAllProviders();
        toast.success('All record has been paid');
      })
      .catch((err) => {
        if (err.message != 'jwt expired') {
          toast.error('Oops!, something went wrong');
          console.log(err);
        }
      });
  });

  const onVoidClick = useCallback((finalObject, finalObject2) => {
    const promises = [];

    if (finalObject2.length > 0) {
      const promise1 = updateNewDisbursement({ body: { orders: finalObject2, isVoided: true } })
        .then(() => {
          !isMobile && toast.success(`${itemIsNotSelected} Record has been marked as void`);
          isMobile && toast.success(`${itemIsNotSelectedMobile} Record has been marked as void`);
        })
        .catch((e) => {
          if (e.message != 'jwt expired') {
            toast.error('Oops!, something went wrong');
            throw e;
          }
        });
      promises.push(promise1);
    }

    if (finalObject.length > 0) {
      const promise2 = updateSelectedPay({
        body: { orders: finalObject, datePaid: new Date().toISOString() },
      })
        .then(() => {
          toast.success(`${mealSelectedLength} Record has been marked as paid`);
        })
        .catch((e) => {
          if (e.message != 'jwt expired') {
            toast.error('Oops!, something went wrong');
            throw e;
          }
        });
      promises.push(promise2);
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          fetchAllProviders();
          setPayIsModal(false);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  const handleFormClose = () => {
    setPayIsModal(false);
  };

  return (
    <PayAllModal>
      <div className="invite-box">
        <div className="close-img-div">
          <img src="/images/close-circle.svg" alt="close-circle Icon" onClick={handleFormClose} />
        </div>
        <div className="qusetionMark">
          <img src="/images/Question.svg" alt="questionMark Icon" className="questionIcon" />
        </div>

        <div className="send-invite">
          {!isMobile && (
            <>
              {itemIsNotSelected > 0 ? (
                <>
                  <p>
                    You have {itemIsNotSelected} items in this order that have not been selected to
                    pay. Do you want to void the {itemIsNotSelected} items? or select them all to
                    pay?
                  </p>
                </>
              ) : (
                <p>Are you sure you want to pay all items?</p>
              )}
            </>
          )}

          {isMobile && (
            <>
              {itemIsNotSelectedMobile > 0 ? (
                <>
                  <p>
                    You have {itemIsNotSelectedMobile} items in this order that have not been
                    selected to pay. Do you want to void the {itemIsNotSelectedMobile} items? or
                    select them all to pay?
                  </p>
                </>
              ) : (
                <p>Are you sure you want to pay all items?</p>
              )}
            </>
          )}
        </div>
        {isMobile ? (
          <div className="coupon-form">
            <div className="publish">
              <SubmitButton
                className="btn-end"
                onClick={() => {
                  payAll(combinedArray),
                    handleFormClose(),
                    setIsOrderDetailsActive(false),
                    setActiveOrderId(order?.id)
                }}
              >
                <img src="/images/dollar-white.svg" alt="dollar Icon" />
                Pay All
              </SubmitButton>
              {itemIsNotSelectedMobile > 0 && (
                <SubmitButton
                  className="btn-end1"
                  onClick={() => {
                    onVoidClick(finalObject, finalObject2),
                      handleFormClose(),
                      setIsOrderDetailsActive(false),
                      setActiveOrderId(order?.id);
                  }}
                >
                  <img src="/images/add.svg" alt="cross Icon" />
                  Void {itemIsNotSelectedMobile} items
                </SubmitButton>
              )}
            </div>
          </div>
        ) : (
          <div className="coupon-form">
            <div className="publish">
              <SubmitButton
                className="btn-end"
                onClick={() => {
                  onDisbursementClick(filteredData), handleFormClose();
                }}
              >
                <img src="/images/dollar-white.svg" alt="dollar Icon" />
                Pay All
              </SubmitButton>
              {itemIsNotSelected > 0 && (
                <SubmitButton
                  className="btn-end1"
                  onClick={() => {
                    onVoidClick(finalObject, finalObject2), handleFormClose();
                  }}
                >
                  <img src="/images/add.svg" alt="cross Icon" />
                  Void {itemIsNotSelected} items
                </SubmitButton>
              )}
            </div>
          </div>
        )}
      </div>
    </PayAllModal>
  );
}
