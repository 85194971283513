import { memo } from 'react';
import TableCell from './TableCell';
import classNames from 'classnames';

function TableRow({ columns, row, style, index, actions, id }) {
    return (
        <div className="table-row-wrapper" style={style}>
            <div
                className={classNames('table-row', { even: index % 2 }, { active: row.id === id })}
                style={{ display: 'flex' }}
            >
                {columns.map((column) => (
                    <TableCell key={column.id} column={column} row={row} actions={actions} />
                ))}
            </div>
        </div>
    );
}

export default memo(TableRow);