import { usePersistedState } from 'hooks/usePersistedState';
import { useRequest } from 'hooks/useRequest';
import { createContext, useContext, useState, useEffect } from 'react';
import { useAppContext } from './appContext';
import produce from "immer";
import { useHistory } from 'react-router';
import getAddress from 'utils/getAddress';

const ShopContext = createContext({});

const ShopContextProvider = ({ children, values = {} }) => {
  const [getTaxRate] = useRequest();
  const [getIsUserPlanExpired] = useRequest();
  const [cartCount, setCartCount] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [orderList, setOrderList] = useState();
  const [kitchenUserName , setKitchenUserName] = useState();
  const { profile, user,userDeliveryAddress, setUser, selectedValue,setProfile, setHeaderAddress,isShoppingUser} = useAppContext();
  const [cart, updateCart] = usePersistedState('cart', []);
  const [showChangeDietPlan, setShowChangeDietPlan] = usePersistedState('showChangeDietPlan',false);
  const [availableProviders, setAvailableProviders] = usePersistedState('availableProviders', {
      data: [],
      expiry: new Date().getTime() + (5 * 60 * 1000)
  });
  const [providerListCalled, setProviderListCalled] = useState(false);
  const [getProvidersAPICall, { isLoading: isProvidersListLoading }] = useRequest(`provider/providers`);
  const [paymentInfo, setPaymentInfo] = useState({
      method: '', // ["apple-pay", "credit"]
      cardNumber: '',
      expiryDate: '',
      cvv: "",
      zipcode:"",
      taxRate,
  });
  const [VerifyProviderAndMeals, { isLoading: mealLoading }] = useRequest(`userOrders/verifyProviderAndMeals`, 'post');
  const [verifiedMeals, setVerifiedMeals] = useState();
const [isActiveRoute,setIsActiveRoute]= useState(false)

  const [orderSummaryInfo, setOrderSummaryInfo] = useState({});
  const [promoCodeInfo, setPromoCodeInfo] = useState({ promoCode: "", subTotal: "" });
  const [appliedCode, setAppliedCode] = useState({});
  const [loadCart, setLoadCart] = usePersistedState('loadCart',{});
  const [userOrders, {isLoading: contextLoading}] = useRequest(`userOrders/getUserOrderList`, 'GET');
  const [healthGoal, setHealthGoal] = useState({});
  const [updatedHealthGoal, setUpdatedHealthGoal] = useState({});
  const [visitorOnboard, { loading }] = useRequest('profile/visitor-onboard/v2', 'post');
  const [updateDietPreferrences] = useRequest('profile/visitor-dietaryPreferences', 'post');
  const [updatedProfile, setUpdatedProfile] = useState(profile);
  const [storeCategoriesMeals, setStoreCategoriesMeals] = useState({});
  const [kitchensList, setKitchensList] = useState([]);
  const [generateStoreCategoriesMeals,{isLoading:isCategorizedMealGenerating}] = useRequest();
  const [generateKitchensList,{isLoading:isKitchenListGenerating}] = useRequest();
  const history = useHistory();
  const lastLoginUrl = history?.location?.state?.from;
  const [consumedMealType, setConsumedMealType] = useState(''); //One from 'breakfast','lunch','dinner','snack';
  const {favoriteKitchenData} = profile || {};
  const [showBummer, setShowBummer] = useState(false);
  const [waitingList, setWaitingList] = useState(false);
  const [isFavoriteKitchenDelivered, setIsFavoriteKitchenDelivered] = useState(true);
  const [getProviderbyUsername, {isLoading: isProviderLoading}] = useRequest();
  const [storeUrl, setStoreUrl] = useState('');
  const [isFetchingKitchenDetails, setIsFetchingKitchenDetails] = useState(true);
  const [isKitchenNotFound, setIsKitchenNotfound] = useState(false);
  const [isAnyKitchenDeliveryAvailable, setIsAnykitchenDeliveryAvailable] = useState(true);
  const [cashBalance, setCashBalance] = useState(user?.cashBalance);
  const [useNutrittCash, setUseNutrittCash] = useState(false) ;
  const [providerSubscriptionList, setProviderSubscriptionList] = useState([]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart])

  const updateConsumedMealType = (mealType) => {
    if (['breakfast', 'lunch', 'dinner', 'snack'].includes(mealType)) {
      setConsumedMealType(mealType);
    } else {
      throw new Error("The meal type must be one of the following options: 'breakfast', 'lunch', 'dinner', or 'snack'.");
    }
  }
  
  const [settings, updateSettings] = usePersistedState('settings', {
    days: 7,
    meals: 3,
    provider: null,
    providerSettings: [], //{providerId, method ["pickup/delivery"], date, time,note,name,schedule}
    userInfo: {
      firstName: profile?.name || '',
      lastName: profile?.lastName || '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '' || '',
      userAddressLatLong: {},
      orderPhone: ''
    },
    mealPlan: [],
    pantryDatesCalories: [],
  });

  useEffect(() => {
    if (userDeliveryAddress?.length) {
      const defaultAddress = userDeliveryAddress?.find(address => address?.id === selectedValue);
      setHeaderAddress(defaultAddress);
    } else {
      updateSettings(
        produce((draft) => {
          draft.userInfo.userAddressLatLong = "";
          draft.userInfo.address = "";
          draft.userInfo.zipcode = "";
          draft.userInfo.city = "";
          draft.userInfo.state = "";
        }),
      );
    }
  }, [userDeliveryAddress]);

  const getPlace=async (value)=>{
    try{
      const userAddress = await getAddress(value.value.place_id, '');
      const {userAddressLatLong, address, city, state, zipcode} = userAddress;
      updateSettings(
        produce((draft) => {
          draft.userInfo.userAddressLatLong = userAddressLatLong;
          draft.userInfo.address = address;
          draft.userInfo.zipcode = zipcode;
          draft.userInfo.city = city;
          draft.userInfo.state = state;
        }),
      );
    }catch (error) {
    console.log(error)
    }
  }
  
  useEffect(()=>{
    if (userDeliveryAddress?.length > 0) {
      let selectedAddress;
    
      if (selectedValue) {
        selectedAddress = userDeliveryAddress.find((address) => address.id === selectedValue);
      if (!selectedAddress) {
          selectedAddress = userDeliveryAddress[0];
        }
      }
      if (selectedAddress) {
        getPlace(selectedAddress.address.address);
      }
    }    
  },[userDeliveryAddress,selectedValue]);

  const VerifyProviderAndMealsFn = async (body) => {
    return VerifyProviderAndMeals({body})
  };

  const getIsUserPlanExpiredFn = () => {
    const route = `nutritionist/isUserPlanExpired`;
    getIsUserPlanExpired({
      path: route,
    })
      .then((data) => {
        setShowChangeDietPlan(data.isExpred);
      })
      .catch(console.log);
  };

  const getProviderFn = () => {
    getProvidersAPICall().then((data) => {
      setAvailableProviders({
        data,
        expiry: new Date().getTime() + (5 * 60 * 1000)
      });
      setProviderListCalled(true)
    }).catch(console.log);
  };

  const getUserOrders = () => { 
    userOrders().then(data => {
      setOrderList(data);
  }).catch(console.log);
  }

  useEffect(() => {
    if (isShoppingUser) {
      getUserOrders();
    }
  }, [isShoppingUser]);

  useEffect(async () => {
    await getProviderFn()
  },[]);

  useEffect(() => {
    getIsUserPlanExpiredFn();
  }, []);

  useEffect(() => {
    const uniqueProviderIds = [...new Set(cart?.map(({ providerId }) => providerId))];
    const cartProviders = uniqueProviderIds.flatMap((providerId) => {
      const providerIndex = settings?.providerSettings?.findIndex(
        (prov) => prov?.id === providerId,
      );
      if (providerIndex !== -1) {
        return [settings.providerSettings[providerIndex]];
      } else {
        const provSettings = kitchensList?.find((kitchen) => kitchen?.id === providerId);
        const storedProviders = JSON.parse(localStorage.getItem('unfullfilledProviders'));
        if(!provSettings){
          if (settings?.provider && settings?.provider?.id === providerId) {
            return [settings.provider];
          }else {
            const unlistedProviderSettings = storedProviders?.find((kitchen) => kitchen?.id === providerId)
            return [unlistedProviderSettings?.settings];
          }
        }else{
          return [provSettings];
        }
      }
    });

    const updatedProviderSettings = cartProviders.map((item) => {
      const prevSettingsIndex = settings?.providerSettings?.findIndex(
        (provider) => provider?.providerId === item?.id,
      );
      const updates = {
        ...item,
        address: `${item?.street ? item?.street + ', ':''}${item?.city ? item?.city + ', ':''}${item?.state ? item?.state + ', ':''}${item?.zip ? item?.zip : ''}`,
        deliveryCoverage: item?.deliveryCoverage,
        deliverableAreas: item?.deliverableAreas,
        minOrderAmount: item?.minOrderAmount,
        name: item?.name,
        providerId: item?.id,
        schedule: item?.schedule,
      };
      return prevSettingsIndex !== -1
        ? { ...settings.providerSettings[prevSettingsIndex], ...updates }
        : { ...updates, date: Date.now() };
    });

    updateSettings(
      produce((draft) => {
        draft.providerSettings = uniqueProviderIds.length ? updatedProviderSettings : [];
      }),
    );
  }, [cart, kitchensList]);
  
  useEffect(() => {
    if (settings.userInfo.zipcode) {
      getTaxRate({ path: `payment/tax/${settings.userInfo.zipcode}` }).then((res) => {
        setTaxRate(res.rate);
      }).catch((error) => console.log(error));
    }
  }, [settings.userInfo.zipcode]);

  //function that counts the items in cart by "qty"
  useEffect(() => {
    let count = 0;
    for (let i = 0; i < cart.length; i++) {
      count += cart[i].qty;
    }
    setCartCount(count);
  }, [cart]);
  
  useEffect(() =>{
    updateSettings(
      produce((draft) => {
        draft.userInfo.firstName = profile?.name || '';
        draft.userInfo.lastName = profile?.lastName || ''; 
      }),
    );
  },[profile])

  useEffect(() => {
  // ACCOUNT HEALTH GOAL FLOW FOR USER
    if (updatedProfile) {
      const updatedHealthGoal = {
        weight: updatedProfile.weight || null,
        gender: updatedProfile.gender || null,
        age: updatedProfile.age || null,
        birthday: updatedProfile.birthday || null,
        heightFeet: updatedProfile.heightFeet || null,
        heightInches: updatedProfile.heightInches !== undefined ? updatedProfile.heightInches : null,
        activity: updatedProfile.activity || null,
          };

      if (updatedProfile && updatedProfile?.selfMealPlan && Object.keys(updatedProfile?.selfMealPlan)?.length) {
        let newObj = {...updatedProfile.selfMealPlan};
        delete newObj.id;
        delete newObj.startDate;
        delete newObj.targetWeekEndDate;
        delete newObj.targetWeeks;
        updatedHealthGoal.selfMealPlan = {
          ...newObj,
          allergies: newObj?.allergies?.map(item => item.toUpperCase()) ?? [],
          dietaryPreferences: newObj?.dietaryPreferences?.map(item => item.toUpperCase()) ?? []
        };
      }else{
        const newHealthGoal = updatedProfile?.dietTemplate?.type === "bulkup" ? "weight-gain" : updatedProfile?.dietTemplate?.type === "lose-weight" ? "weight-loss" : updatedProfile?.dietTemplate?.type === "" ? '' : "maintain-weight";
        const carbPercentage = updatedProfile?.dietTemplate?.carbs_percent || 40;
        const proteinPercentage = updatedProfile?.dietTemplate?.protein_percent || 30;
        const fatPercentage = updatedProfile?.dietTemplate?.fat_percent || 30;
        updatedHealthGoal.selfMealPlan = {
          healthGoal: newHealthGoal,
          goal: updatedProfile?.dietTemplate?.goal,
          idealWeight: updatedProfile?.dietTemplate?.idealWeight,
          preferredDiet: {
            carbsPercent: carbPercentage,
            proteinPercent: proteinPercentage,
            fatPercent: fatPercentage,
            type: carbPercentage === 40 && proteinPercentage === 30 && fatPercentage === 30
              ? "balanced"
              : carbPercentage === 20 && proteinPercentage === 30 && fatPercentage === 50
                ? "low-carb"
                : carbPercentage === 30 && proteinPercentage === 50 && fatPercentage === 20
                  ? "low-fat"
                  : "custom"
          },
          customDietMetrics: {
            customCalories: 0
          },
          goalType: "number",
          allergies: [...(updatedProfile?.dietTemplate?.food_to_avoid || [])?.map(item => item?.toUpperCase())],
          dietaryPreferences: [...(updatedProfile?.dietTemplate?.tags || [])?.map(item => item?.toUpperCase())],
        }
      }
      setHealthGoal(updatedHealthGoal);
      setUpdatedHealthGoal(updatedHealthGoal);
    }
  }, [updatedProfile])

  const saveHealthGoal = async () => {
    try {
      const newUpdatedHealthGoal = updatedHealthGoal.selfMealPlan?.customDietMetrics?.customCalories <= 0
      ? {
          ...updatedHealthGoal,
          selfMealPlan: {
            ...updatedHealthGoal.selfMealPlan,
            customDietMetrics: {
              ...updatedHealthGoal.selfMealPlan.customDietMetrics,
              isActive: false,
            },
          },
        }
        : (user?.type.includes('visitor') && lastLoginUrl === '/shop/track-quiz')
        ? {
          ...updatedHealthGoal,
          selfMealPlan: {
            ...updatedHealthGoal.selfMealPlan,
            preferredDiet: updatedHealthGoal.selfMealPlan.preferredDiet
            ? updatedHealthGoal.selfMealPlan.preferredDiet
            : {
              carbsPercent: 40,
              proteinPercent: 30,
              fatPercent: 30,
              type: "balanced",
            },
            customDietMetrics:{
              isActive:false,
              customCalories:0
            },
          },
        }
        : updatedHealthGoal;
      const res = await visitorOnboard({ body: { ...newUpdatedHealthGoal } });
      if(res?.data?.success){
        setUpdatedProfile((prev) => ({...prev, ...res?.data?.profile}));
        setProfile((prev) => ({...prev, ...res?.data?.profile}))
        return res?.data?.success;
      }
    } catch (err) {
        if (err != "jwt expired") {
          console.log(err,"err")
        }
    }
  }

  const saveDietPreferences = async () => {
    try {
      const { selfMealPlan, weight, gender, birthday, age, heightFeet, heightInches, activity } = updatedHealthGoal;
      const body = {
        selfMealPlan,
        weight,
        gender,
        birthday,
        age, 
        heightFeet, 
        heightInches, 
        activity
      }
      const res = await updateDietPreferrences({ body });
      if (res?.data?.success) {
        const isAllergiesUpdated = (profile?.selfMealPlan?.allergies && profile?.selfMealPlan?.allergies.length !== selfMealPlan?.allergies?.length);
        if (isAllergiesUpdated && profile?.favoriteKitchen) {
          await getStoreCategoriesMeals()
        }
        setUpdatedProfile((prev) => ({...prev, ...res?.data?.profile}));
        setProfile((prev) => ({...prev, ...res?.data?.profile}))
        return res?.data?.success;
      }
    } catch (err) {
      if (err != "jwt expired") {
        console.log(err, "err")
      }
    }
  }

  const getStoreCategoriesMeals = async () => {
    const path = `provider/categorized-meal?businessProfileId=${favoriteKitchenData?.businessProfileId}`;
    await generateStoreCategoriesMeals({path})
      .then((res) => {
        if (res?.data?.success) {
          setStoreCategoriesMeals(res?.data?.storeData);
        }})
      .catch((err) => console.log({ err }));
  }

  const getKitchensList = async () => {
    const path = `provider/list`;
    await generateKitchensList({path})
      .then((res) => {
        if (res?.data?.success) {
          setKitchensList(res?.data?.kitchens);
        }})
      .catch((err) => console.log({ err }));
  }

  useEffect(() => {
    if (favoriteKitchenData?.businessProfileId) {
      getStoreCategoriesMeals();
    }
  }, [favoriteKitchenData]);

  useEffect(() => {
    getKitchensList();
  }, []);

  const getProviderByUsernameFn = async (storeUrl) => {
    setIsKitchenNotfound(false)
    const path = `provider/username/${storeUrl}`;
    await getProviderbyUsername({ path })
    .then((res) => {
        updateSettings(
            produce((draft) => {
                draft.provider = res.data;
            }),
        );
    })
    .catch((err) => {
      console.log({ err })
      setIsKitchenNotfound(true);
    });
    setIsFetchingKitchenDetails(false);
  };

  useEffect(() => {
    // Setting the provider so that if user directly hits the url.
    if(storeUrl){
      if(settings?.provider && Object.keys(settings?.provider)?.length && settings?.provider.username === storeUrl) return '';
      const kitchenToUpdate = kitchensList?.length && kitchensList?.find(kitchen => kitchen?.username === storeUrl);
      if(kitchenToUpdate){
        updateSettings(
          produce((draft) => {
            draft.provider = kitchenToUpdate?.settings || kitchenToUpdate;
          }),
        );
      }else{
        getProviderByUsernameFn(storeUrl);
      }
    }
    setIsFetchingKitchenDetails(false);
  }, [kitchensList, storeUrl, settings?.provider])

  useEffect(() => {
    let updatedKitchenList = [...kitchensList];
    const favProvider = updatedKitchenList?.findIndex(item => item?.id === favoriteKitchenData?.providerId);
    if((favProvider === -1) && updatedKitchenList?.length){
      updatedKitchenList.push({...favoriteKitchenData, id: favoriteKitchenData?.providerId});
      setKitchensList(updatedKitchenList);
    }
  }, [kitchensList, favoriteKitchenData])


  return (
    <ShopContext.Provider
      value={{
        settings,
        updateSettings,
        cart,
        updateCart,
        cartCount,
        taxRate,
        availableProviders,
        setAvailableProviders,
        setPaymentInfo,
        paymentInfo,
        showChangeDietPlan,
        getIsUserPlanExpiredFn,
        setOrderSummaryInfo,
        orderSummaryInfo,
        setPromoCodeInfo,
        promoCodeInfo,
        setAppliedCode,
        appliedCode,
        VerifyProviderAndMealsFn,
        verifiedMeals,
        isActiveRoute,
        setIsActiveRoute,
        providerListCalled,
        setProviderListCalled,
        loadCart,
        setLoadCart,
        orderList,
        setOrderList,
        getUserOrders,
        healthGoal,
        setHealthGoal,
        saveHealthGoal,
        updatedHealthGoal,
        updatedProfile, 
        setUpdatedProfile,
        setUpdatedHealthGoal,
        storeCategoriesMeals,
        kitchensList,
        showBummer,
        setShowBummer,
        mealLoading,
        waitingList,
        setWaitingList,
        isFavoriteKitchenDelivered, 
        setIsFavoriteKitchenDelivered,
        ...values,
        kitchenUserName,
        setKitchenUserName,
        consumedMealType,
        updateConsumedMealType,
        saveDietPreferences,
        isKitchenListGenerating,
        isCategorizedMealGenerating,
        getProviderByUsernameFn,
        setStoreUrl,
        isFetchingKitchenDetails,
        isKitchenNotFound,
        isAnyKitchenDeliveryAvailable, 
        setIsAnykitchenDeliveryAvailable,
        setCashBalance,
        cashBalance,
        setKitchensList,
        useNutrittCash, 
        setUseNutrittCash,
        providerSubscriptionList,
        setProviderSubscriptionList
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

const useShopContext = () => useContext(ShopContext);

export { ShopContextProvider, useShopContext };
