import {useState, useEffect} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router-dom';
import Progress from 'components/Progress';
import OfferPanel from 'components/Nutritionist/Coaching/OfferPanel';
import {useAppContext} from '../../context/appContext';
import {useRequest} from '../../hooks/useRequest';
import {toast} from "react-toastify";
import { useNutritionistContext } from 'context/nutritionistContext';
import { Modal } from 'react-responsive-modal';

// const Modal = styled.div`
//     max-width: 85%;
//     min-width: 85%;
// `;
const NutritionistOfferingListWrapper = styled.div`
  width: 100%;
  @media(max-width:767px){
    //height:83vh;
    height: 100%;
  }
  .nutritionist-offering-list {
    width: 100%;
    padding: 36px 60px;
    @media (max-width: 767px) {
      padding: 9px 20px;
      width: 100% !important;
      height: 100%;
    }
  }
  .create-offer {
    position: absolute;
    bottom: 36px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      bottom: 24px;
    }
  }
  .primary-btn {
    width: 200px;
    height: 40px;
    padding: 0;
    border-radius: 21px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  .offering-list-title {
    margin-bottom: 35px;
    color: #51635d;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.offerListTitle {
      margin: 0;
    }
  }
  .offering-list-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    max-height: calc(100vh - 280px);
    overflow-y: scroll;
    margin-bottom: 60px;
    padding-right: 6px;
    @media (max-width: 767px) {
      padding-right: 0px;
      -webkit-overflow-scrolling: touch;
    }
  }

  .create-offer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    p {
      margin-bottom: 20px;
      color: #949d9b;
      font-size: 36px;
      font-weight: 400;
      line-height: 42px;
    }
  }

  @media (max-width: 1350px) {
    .nutritionist-offering-list {
      width: 90%;
    }
  }

  @media (max-width: 1150px) {
    .offering-list-wrapper {
      grid-template-columns: 48.5% 48.5%;
    }
  }

  @media (max-width: 991px) {
    .offering-list-title {
      margin-bottom: 14px;
      font-size: 18px;
    }
   
    .offering-list-wrapper {
      margin-bottom: 10px;
      grid-template-columns: 100%;
      grid-row-gap: 14px;
      //max-height: calc(100vh - 240px);
      height:100%;
    }
    @media (max-width: 768px){
      .offering-list-wrapper {
        margin-bottom: 33px !important;
        max-height:  calc(100vh - 220px) !important;
    }
    }
    @media (max-width: 479px){
      .offering-list-wrapper {
        margin-bottom: 15px !important;
        max-height:  calc(100vh - 192px) !important;
    }
    }
    @media (max-width: 391px){
      .offering-list-wrapper {
        margin-bottom: 55px !important;
        max-height: calc(100vh - 215px) !important;
    }
    }

    .create-offer-wrapper {
      width: 70%;
      height: calc(100vh - 169px);
      margin: 0 auto;

      p {
        font-size: 24px;
        line-height: 28px;
        text-align: center;
      }
    }

    .create-new-wrapper {
      padding-top: 10px;
      display: flex;
      justify-content: center;

      .primary-btn.create-new {
        position: static;
      }
    }
  }
`;

export default function NutritionistOfferingList({onCloseModal, open}) {
    const {offeringListData, setOfferingListData} = useNutritionistContext();
    const {user, isSuper} = useAppContext();
    const location = useLocation();
    const [getCoachingOffers, {isLoading, error, setError}] = useRequest();
    const history = useHistory();
    const {isMobile} = useAppContext();
    const [deleteCoachingOffers, { isLoading: isDeleteCoachingOffers }] = useRequest();

    const nutritionistCoachingPlan = (offeringListData || [])[0];

    const getInitialData = (reqId) => {
        const getOfferListUrl = `coachingOffer/offers/${reqId}`;
        getCoachingOffers({
            path: `${getOfferListUrl}`,
        }).then((templates) => {
            setOfferingListData(templates.getData);
        });
    }

    useEffect(async () => {
        const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
        const reqId = isSuper ? imitateNutritionist?.userId : user?.id;
         getInitialData(reqId);
    }, []);
    const handleNewOffering = () => {
        document.body.classList.remove('hide-scroll');
        history.push('/nutritionist/coaching/offering/create');
        window.scrollTo(0, 0);
    };

    const deleteCoachingOffer = (id) => {
        const imitateNutritionist = JSON.parse(localStorage.getItem('imitateNutritionist'));
        const reqId = isSuper ? imitateNutritionist?.userId : user?.id;
        const path = `coachingOffer/delete/${id}`;
        deleteCoachingOffers({
            path,
            method: 'POST',
        })
            .then(() => {
                getInitialData(reqId);
                toast.success('The offer was deleted successfully');
            })
            .catch(() => {
                toast.error('Oops! something went wrong');
            });
    };


    if (isLoading) {
        return <Progress/>
    }

    return (
        <Modal 
          open={open} 
          onClose={onCloseModal}
          blockScroll={false} 
          classNames={{
            overlay: 'modal-overly',
            modal: 'modal-large',
          }}
          center>
          <NutritionistOfferingListWrapper>
            <div className="nutritionist-offering-list">
                {!isMobile && offeringListData && offeringListData[0]?.coachingOffer?.length ? (
                  <div className='create-offer'>
                    <button className="primary-btn" onClick={handleNewOffering}>
                        + Create an offer
                    </button>
                  </div>
                ) : null}
                <div
                    className={classNames('offering-list-title common-title', {
                        offerListTitle: offeringListData?.length < 1,
                    })}
                >
                    My Offerings
                </div>
                <div className="offering-list-wrapper">
                    {offeringListData && offeringListData?.map((offer, index) => (
                        <OfferPanel
                            key={index}
                            offer={offer}
                            deleteCoachingOffer={deleteCoachingOffer}

                        />
                    ))}
                </div>
                {isMobile && offeringListData && offeringListData[0]?.coachingOffer?.length ? (
                    <div className="create-new-wrapper">
                        <button className="primary-btn create-new" onClick={handleNewOffering}>
                            + Create a New Offerings
                        </button>
                    </div>
                ) : null}
                {nutritionistCoachingPlan?.coachingOffer?.length == 0 && (
                    <div className="create-offer-wrapper">
                        <p>You currently don't have any offerings</p>
                        <button className="primary-btn" onClick={handleNewOffering}>
                            + Create an offer
                        </button>
                    </div>
                )}
            </div>
        </NutritionistOfferingListWrapper>
      </Modal>
    );
}