import { useShopContext } from 'context/shopContext';
import { memo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderWrapper } from './style';
import { useAppContext } from 'context/appContext';
import useProgressiveImage from 'hooks/useProgressiveImage';
import { Skeleton } from '@mui/material';
import { ReactComponent as AngleLeftBlack } from "assets/shop/angle-left-black.svg";

function Header({ favoriteKitchenData, provider }) {
  const { storeImages, image, username } = provider || {};
  const {kitchenUserName} = useShopContext()
  const {convertToHttps}=useAppContext();
  const history = useHistory();
  const shareFavKitchen = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "share-fav-kitchen", kitchenUrl: `${process.env.REACT_APP_CLIENT_URL}/store/${kitchenUserName}` }))
      return;
    }
  }
  const storeImage = Array.isArray(storeImages) && storeImages[0] || '/images/sexy_just_eat_now.jpg'
  const imageRef = useRef();
  const [src, {isImageLoaded}] = useProgressiveImage(storeImage, imageRef);

  return (
    <HeaderWrapper>
      {!isImageLoaded && <Skeleton variant='rectangular' style={{ position: 'absolute', inset: 0, zIndex: 9 }} width={'100%'} height={'100%'}/>}
      <img
        src={src}
        alt="Header Img"
        className={`header-img ${isImageLoaded ? 'isFadeInAnimate': 'imgBlurred'}`}
        decoding="async"
        ref={imageRef}
      />
      <div className="header-icons">
        <div className="icon-wrapper" onClick={() => {
          history.push('/shop/kitchen-home',{fromStore:true});
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ event:"clear_url"}));
            return;
          }
          }}>
          <AngleLeftBlack
            alt="angle Img"
            className="angle-img"
          />
        </div>
        <div className="favorite-share">
          {provider?.businessProfileId === favoriteKitchenData?.businessProfileId &&
          <div className="icon-wrapper">
            <img 
              src={`/icons/${provider?.businessProfileId === favoriteKitchenData?.businessProfileId ? 'favorite-heart' : 'heart-gray'}.svg`} 
              alt="Favorite Img" className="favorite-img"
            />
          </div>
          }
          <div className="icon-wrapper" onClick={shareFavKitchen}>
            <img src="/icons/share-black.svg" alt="Share Img" className="share-img" />
          </div>
        </div>
      </div>
      <img src={convertToHttps(image) || '/images/green-plate.png'} alt="Provider Img" class="provider-img" onError={(e) => { e.target.src = '/images/avatar.png' }}/>
    </HeaderWrapper>
  );
}

export default memo(Header);
