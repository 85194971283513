import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Sidebar } from 'components/elements/sidebar/Sidebar'
import { theme } from 'shared/theme/theme'
import { ReactComponent as HomeIcon } from 'assets/n-home.svg';
import { ReactComponent as MenuIcon } from 'assets/n-menu.svg';
import { ReactComponent as OrderIcon } from 'assets/n-order.svg';
import { ReactComponent as ScheduleIcon } from 'assets/n-schedule.svg';
import { ReactComponent as PayoutIcon } from 'assets/n-payout.svg';
import { ReactComponent as SettingsIcon } from 'assets/n-settings.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useKitchenContext } from 'context/kitchenContext';
import Profile from 'components/elements/profileCard/Profile';

const ProviderSidebarStyle = styled.div`
  width: 288px;
  padding: 14px 8px 14px 14px;
  background-color: ${theme.colors.secondary};
  border-right: ${({ theme }) => `1px solid ${theme.colors.borderLight}`};
  section {
    height: auto;
  }
  .logo {
    height: auto;
    margin-bottom: 21px;
    padding: 0;
    border-bottom: 0;
    img {
        width: 120px;
        height: 51px;
    }
  }
  nav{
    z-index: auto;
    padding: 0;
    height: calc(100vh - 100px);
    .container {
        margin-left: 6px;
        font-family: ${theme.typography.fontGilroy};
        div:last-child {
            border-radius: 4px;
        }
    }
    .nav_item{
      padding: 10px 12px;
      height: 44px;
      .extra_number {
        padding: 2px 6px;
        font-family: ${theme.typography.fontInter};
      }
      .title {
        font-weight: 500;
        line-height: 24px;
      }
      &.active .title {
        color: ${theme.colors.primaryDarker} !important;
      }
      .icon{
        &.active{
          svg, path{
            fill: ${theme.colors.primaryDarker};
          }
        }
      }
    }
    button {
        width: 100%;
        padding: 12px;
        align-items: center;
        gap: 8px;
        font-family: ${theme.typography.fontInter};
        img {
            margin: 0;
        }
        .info {
            margin: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .name {
                overflow: hidden;
                color: ${theme.colors.primaryGray};
                font-weight: 400;
                line-height: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }
            .email {
                color: #71839B;
                line-height: 14px;
            }
        }
    }
  }
`

export const ProviderStorefrontSidebar = () => {
    const { newOrdersCount } = useKitchenContext();
    const [selectedNavId, setSelectedNavId] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const sidebarNav = [
        {
            id: 0,
            title: 'Home',
            icon: <HomeIcon />,
            onClick: () => { history.push('/kitchen') },
        },
        {
            id: 1,
            title: 'Menu',
            icon: <MenuIcon />,
            onClick: () => { history.push('/kitchen/menu') }
        },
        {
            id: 2,
            title: 'Orders',
            icon: <OrderIcon />,
            onClick: () => { history.push('/kitchen/orders') },
            number: newOrdersCount
        },
        {
            id: 3,
            title: 'Schedules',
            icon: <ScheduleIcon />,
            onClick: () => { history.push('/kitchen/schedules') }
        },
        {
            id: 4,
            title: 'Payouts',
            icon: <PayoutIcon />,
            onClick: () => { history.push('/kitchen/payouts') }
        },
        {
            id: 5,
            title: 'Settings',
            icon: <SettingsIcon />,
            onClick: () => { history.push('/kitchen/settings') }
        }
    ]

    useEffect(() => {
        if (location.pathname === '/kitchen/settings') {
            setSelectedNavId(5);
        } else if (location.pathname === '/kitchen/payouts') {
            setSelectedNavId(4);
        } else if (location.pathname === '/kitchen/schedules') {
            setSelectedNavId(3);
        } else if (location.pathname.includes('/kitchen/orders')) {
            setSelectedNavId(2);
        } else if (location.pathname.includes('/kitchen/menu')) {
            setSelectedNavId(1);
        } else {
            setSelectedNavId(0);
        }
    }, [location])

    return (
        <ProviderSidebarStyle theme={theme}>
            <Sidebar logo={
                <img src='/images/nutritt-black.svg' alt="Nutritt Logo" />
            } selectedTabId={selectedNavId} setSelectedTabId={setSelectedNavId} sidebarWidth={288} navItems={sidebarNav} navBgColor={theme?.colors?.white} activeNavBgColor={theme.colors.background} navItemTextColor={'#757E89'} activeNavTextColor={theme?.colors?.primaryDefault} size={'large'} navItemsGap={12} />
        </ProviderSidebarStyle>
    )
}