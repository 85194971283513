import { useState } from 'react';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabPanel } from 'components/Nutritionist/TabPanel';
import NutritionistProfile from 'components/Nutritionist/NutritionistProfile';
import { useAppContext } from 'context/appContext';

const NutritionistProfilePageStyle = styled.div`
  width: calc(100% - 300px);
  margin-left: 300px;
  padding: 15px 68px 30px;

  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.form-group-section {
      width: 50%;

      &:first-child {
        margin-right: 75px;
      }
    }

    label {
      margin-bottom: 14px;
      color: #000000;
      font-size: 20px;
    }

    input {
      height: 46px;
      padding: 0 16px;
      font-size: 16px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;

      ::placeholder {
        color: #939b99;
      }
    }
  }

  @media (max-width: 767px) {
    width: 90%;
    padding: 24px 0;
    margin: 60px auto;
    .profile{
        margin-bottom:10px;
    }
  }
  .profile{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    width:100%;
    display: flex;
    justify-content:center;
    color: #51635D;
}

  @media(min-width:992px){
    background: #FFFFFF;
    min-height: calc(100vh - 32px);
    .profile{
        padding-top:29px;
        margin-bottom:37px;
    }
  }
`;

{
  TabPanel;
}

export default function NutritionistProfilePage() {
    const { isMobile } = useAppContext();
  
  return (
    <NutritionistProfilePageStyle>
     <div className='profile'>
        <p>Profile</p>
     </div>
      <form>
          <NutritionistProfile />
      </form>
    </NutritionistProfilePageStyle>
  );
}
