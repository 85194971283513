import moment from 'moment';
import { State_Abbreviation, SUPPORTED_ALLERGEN_TAGS } from '../constants'
import { isPointInPolygon } from 'utils/verify-coordinates-in-polygon.util';

export const addTimeInDate = (date = new Date(), time = '') => {
  const d = new Date(date);
  const timeParts = time.split(':');
  let [hours = 0, minutes = 0, milliseconds = 0] = [0, 0, 0];

  if (timeParts.length >= 1) {
    hours = parseInt(timeParts[0], 10) || 0;
  }

  if (timeParts.length >= 2) {
    minutes = parseInt(timeParts[1], 10) || 0;
  }

  if (timeParts.length >= 3) {
    milliseconds = parseInt(timeParts[2], 10) || 0;
  }

  d.setHours(hours);
  d.setMinutes(minutes);
  d.setMilliseconds(milliseconds);
  return d;
};
export const getUserName = (user) => {
  const type = user.type[0];
  if (type == 'nutritionist') return user.nutritionistProfile.name;
  if (type == 'provider') return user.provider.name;
  if (type == 'user') return user.profile.name;
};

export const getMaskedNumber = (str = '') => {
  return str.replace(/.(?=.{4})/g, 'x');
};


export const formatNumberDecimalPlaces = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
}

export function debounce(func, wait, immediate) {
  // 'private' variable for instance
  // The returned function will be able to reference this due to closure.
  // Each call to the returned function will share this common timer.
  var timeout;

  // Calling debounce returns a new anonymous function
  return function() {
    // reference the context and args for the setTimeout function
    var context = this,
        args = arguments;

    // Should the function be called now? If immediate is true
    //   and not already in a timeout then the answer is: Yes
    var callNow = immediate && !timeout;

    // This is the basic debounce behaviour where you can call this
    //   function several times, but it will only execute once
    //   [before or after imposing a delay].
    //   Each time the returned function is called, the timer starts over.
    clearTimeout(timeout);

    // Set the new timeout
    timeout = setTimeout(function() {

      // Inside the timeout function, clear the timeout variable
      // which will let the next execution run when in 'immediate' mode
      timeout = null;

      // Check if the function already ran with the immediate flag
      if (!immediate) {
        // Call the original function with apply
        // apply lets you define the 'this' object as well as the arguments
        //    (both captured before setTimeout)
        func.apply(context, args);
      }
    }, wait);

    // Immediate mode and no wait timer? Execute the function..
    if (callNow) func.apply(context, args);
  }
}

export const sendDataToReactNativeApp= (message="")=>{
  if(window.ReactNativeWebView){
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
   }
}

export function calculateNutritionPerGram(payload) {
  const { calories, fat, protein, carbohydrate, metric_serving_amount, metric_serving_unit } = payload;
  const servingAmount = parseFloat(metric_serving_amount);
  const servingUnit = metric_serving_unit?.toLowerCase();
  let gramsPerServing;
  switch (servingUnit) {
    case 'g':
      gramsPerServing = servingAmount;
      break;
    case 'oz':
      gramsPerServing = servingAmount * 28.35;
      break;
    case 'fl oz':
      // 1 fluid ounce of water weighs approximately 28.41 grams
      gramsPerServing = servingAmount * 28.41;
      break;
    case 'ml':
      gramsPerServing = servingAmount;
      break;
    default:
      gramsPerServing = servingAmount;
  }

  return {
    caloriesPerGram: (calories / gramsPerServing),
    fatPerGram: (fat / gramsPerServing),
    proteinPerGram: (protein / gramsPerServing),
    carbsPerGram: (carbohydrate / gramsPerServing),
  };
}

export function displayDateTime(date, form = 'M/D/YYYY') {
  const dateSplit = date?.split("T");
  const datePart = dateSplit && dateSplit[0];
  return moment(datePart).format(form)
};

export function slotGenerator(selectedDate, slotInterval, openTime, closeTime) {
  let x = {
    slotInterval,
    openTime,
    closeTime,
  };

  let startTime = moment(selectedDate).add(moment.duration(x.openTime));
  let endTime = moment(selectedDate).add(moment.duration(x.closeTime));

  let allTimes = [];
  while (startTime < endTime) {
    const timeValue = startTime.format('HH:mm'); // Set the timeValue to the time string
    allTimes.push({ label: startTime.format('LT'), value: timeValue });
    startTime.add(x.slotInterval, 'minutes');
  }
  return allTimes;
};

export function hourGenerator(slotInterval) {
  const allTimes = [];
  let hoursDiff = 1;

  while (hoursDiff <= 10) {
    const label = `${hoursDiff} hour`;
    const timeValue = hoursDiff < 10 ? `0${hoursDiff}:00` : `${hoursDiff}:00`;
    allTimes.push({ label: label, value: timeValue });
    hoursDiff += slotInterval;
  }
  return allTimes;
};

function formatNumber(number) {
  if (typeof number !== 'number' || isNaN(number)) {
    return 0;
  }
  const formattedNumber = parseInt(Math.round(number));
  return formattedNumber === '0.00' ? 0 : formattedNumber;
};

export const processRestrictionTags = (propsTags,cautions) => {
  const Tags = [
    'CEREAL/GLUTEN',
    'PEANUT',
    'LUPINE',
    'MOLLUSK',
    'MILK',
    'TREE_NUT',
    'SESAME',
    'CRUSTACEAN',
    'FISH',
    'EGG',
    'CELERY',
    'SOY',
    'MUSTARD',
    ];
    const tagMapping = {
      "GLUTEN": "CEREAL/GLUTEN",
      "CEREAL":"CEREAL/GLUTEN",
      "TREE_NUTS":"TREE_NUT",
      "EGGS":"EGG"
    };  

    const transformEdamamToTags = (edamamTags) => {
      return edamamTags.map(tag => tagMapping[tag] || tag);
    };
    const removedFree = propsTags.map((tag) => tag.replace(/_FREE$/, '').toUpperCase());
    const edamamSelectedTags = transformEdamamToTags(removedFree);
    const remainingTags = Tags.filter(tag => !edamamSelectedTags.includes(tag.toUpperCase()));
    const cautionTag = transformEdamamToTags(cautions)
  return [...remainingTags, ...cautionTag];
}

function getUniqueAllergens(allergen, SUPPORTED_ALLERGEN_TAGS) {
  const tagMapping = {
    "GLUTEN": "CEREAL/GLUTEN",
    "CEREAL": "CEREAL/GLUTEN",
    "TREE_NUTS": "TREE_NUT",
    "EGGS": "EGG"
  };

  const dIngredients = Array.from(new Set(allergen || [])).map((tag, index) => {
    const formattedTag = tag.replace(/\s/g, '_').toUpperCase();
    const mappedTag = tagMapping[formattedTag] || formattedTag;
    if (SUPPORTED_ALLERGEN_TAGS.includes(mappedTag)) {
      return mappedTag;
    }
  });

  return Array.from(new Set(dIngredients)).filter((item) => item);
}

export const getStateAbbreviation = (fullStateName) => {
  if (fullStateName) {
    const lowercaseStateName = fullStateName.toLowerCase();
    const abbreviation = State_Abbreviation[lowercaseStateName];
    
    if (abbreviation) {
      return abbreviation;
    } else {
      if(fullStateName?.length > 8){
        return fullStateName.slice(0,8) + '...';
      }
      else return fullStateName
    }
  } else {
    return '';
  }
}

function capValueAt100(value) {
  return Math.min(value, 100);
}

export  const calculateMealData = (ingredientData) => {
  let totalCalories = 0;
  let totalFat = 0;
  let totalDailyFatPercent = 0;
  let saturatedFatGrams = 0;
  let saturatedFatPercent = 0;
  let transFatGrams = 0;
  let cholesterolInMg = 0;
  let cholesterolPercent = 0;
  let sodiumMg = 0;
  let sodiumPercent = 0;
  let carbohydrateGrams = 0;
  let carbohydratePercent = 0;
  let fiberGrams = 0;
  let fiberPercent = 0;
  let sugarGrams = 0;
  let addedSugarGrams = 0;
  let addedSugarPercent = 0;
  let proteinGrams = 0;
  let proteinPercent = 0;
  let vitaminDMicrograms = 0;
  let vitaminDPercent = 0;
  let calciumMg=0;
  let calciumPercent=0;
  let ironMg=0;
  let ironPercent=0;
  let potassiumMg=0;
  let potassiumPercent=0;
  let totalWeight=0;
  let ingredientCount=1;
  
  const recommendedTags = new Set();
  const restrictions = new Set();

  if (ingredientData.length > 0) {
    ingredientCount = ingredientData.length;
    ingredientData.forEach(ingredient => {
      const edmamNutritionData = ingredient.macros;
      const nutrients = edmamNutritionData.totalNutrients;
      const dailyNutrients = edmamNutritionData.totalDaily;
      totalCalories += Math.round(nutrients?.ENERC_KCAL?.quantity);
      totalDailyFatPercent += dailyNutrients?.FAT?.quantity;
      saturatedFatGrams += nutrients?.FASAT?.quantity;
      saturatedFatPercent += dailyNutrients?.FASAT?.quantity;
      totalFat += nutrients?.FAT?.quantity;
      transFatGrams += nutrients?.FATRN?.quantity;
      cholesterolInMg += nutrients?.CHOLE?.quantity;
      cholesterolPercent += dailyNutrients?.CHOLE?.quantity;
      sodiumMg += nutrients?.NA?.quantity;
      sodiumPercent += dailyNutrients?.NA?.quantity;
      carbohydrateGrams += nutrients?.CHOCDF?.quantity;
      carbohydratePercent += dailyNutrients?.CHOCDF?.quantity;
      fiberGrams += nutrients?.FIBTG?.quantity;
      fiberPercent += dailyNutrients?.FIBTG?.quantity;
      sugarGrams += nutrients?.SUGAR?.quantity;
      addedSugarGrams += nutrients.SUGAR?.added?.quantity;
      addedSugarPercent += dailyNutrients?.SUGAR?.added?.quantity;
      proteinGrams += nutrients?.PROCNT?.quantity;
      proteinPercent += dailyNutrients?.PROCNT?.quantity;
      vitaminDMicrograms += nutrients?.VITD?.quantity;
      vitaminDPercent += dailyNutrients?.VITD?.quantity;
      calciumMg +=nutrients?.CA?.quantity;
      calciumPercent+=dailyNutrients?.CA?.quantity;
      ironMg +=nutrients?.FE?.quantity;
      ironPercent += dailyNutrients?.FE?.quantity;
      potassiumMg += nutrients?.K?.quantity;
      potassiumPercent += dailyNutrients?.K?.quantity;
      totalWeight += Number(ingredient?.weight);
      edmamNutritionData.healthLabels.forEach(label => recommendedTags.add(label));
      if(ingredient?.id){
        const uniqueAllergens = getUniqueAllergens(ingredient.allergies, SUPPORTED_ALLERGEN_TAGS);
        uniqueAllergens.forEach((tag) => restrictions.add(tag));
        }else{
        const processedRestrictionTags = processRestrictionTags(edmamNutritionData.healthLabels, edmamNutritionData.cautions);
        processedRestrictionTags.forEach(caution => restrictions.add(caution));
      }
      
    });
  }

  const fatObject = {
    grams: formatNumber(totalFat),
    percentage: formatNumber(totalDailyFatPercent/ingredientCount),
    saturatedFatGrams: formatNumber(saturatedFatGrams),
    saturatedFatPercent: formatNumber(saturatedFatPercent/ingredientCount),
    transFatGrams: formatNumber(transFatGrams),
    macroPercent: (totalFat === 0 || isNaN(totalFat)) ? 0 : capValueAt100(parseFloat(Math.floor((totalFat*9)/totalCalories*100).toFixed(2))),

  };

  const cholesterolObject = {
    mg: formatNumber(cholesterolInMg),
    percentage: formatNumber(cholesterolPercent/ingredientCount)
  };

  const sodium = {
    mg: formatNumber(sodiumMg),
    percentage: formatNumber(sodiumPercent/ingredientCount)
  };

  const carbohydrates = {
    grams: formatNumber(carbohydrateGrams),
    percentage: formatNumber(carbohydratePercent/ingredientCount),
    fiberGrams: formatNumber(fiberGrams),
    fiberPercent: formatNumber(fiberPercent/ingredientCount),
    sugarGrams: formatNumber(sugarGrams),
    addedSugarGrams: formatNumber(addedSugarGrams),
    addedSugarPercent: formatNumber(addedSugarPercent/ingredientCount),
    macroPercent: (carbohydrateGrams === 0 || isNaN(carbohydrateGrams)) ? 0 : capValueAt100(parseFloat(Math.floor(carbohydrateGrams*4)/totalCalories*100).toFixed(2)),
  };

  const protein = {
    grams: formatNumber(proteinGrams),
    percentage: formatNumber(proteinPercent/ingredientCount),
    vitaminDMicrograms: formatNumber(vitaminDMicrograms),
    vitaminDPercentage: formatNumber(vitaminDPercent/ingredientCount),
    calciumMg:formatNumber(calciumMg),
    calciumPercent:formatNumber(calciumPercent/ingredientCount),
    ironMg:formatNumber(ironMg),
    ironPercent:formatNumber(ironPercent/ingredientCount),
    potassiumMg:formatNumber(potassiumMg),
    potassiumPercent:formatNumber(potassiumPercent/ingredientCount),
    macroPercent: (proteinGrams === 0 || isNaN(proteinGrams)) ? 0 : capValueAt100(parseFloat(Math.floor(proteinGrams*4)/totalCalories*100).toFixed(2)),

  };

  const mealData = {
    totalCalories: !isNaN(totalCalories) ? Math.round(totalCalories): 0,
    fat: fatObject,
    cholesterol: cholesterolObject,
    sodium,
    carbohydrates,
    protein,
    totalWeight,
    recommendedTags: Array.from(recommendedTags),
    restrictions: Array.from(restrictions)
  };

  return mealData;
};


export const generatePieData=(fat, protein, carbs, other)=> {
  const hasPositiveValue = fat > 0 || protein > 0 || carbs > 0 || other > 0;

  if (hasPositiveValue) {
    const colors = ['#EE786B', '#2399BF', '#8ACEBF', '#C2C9D1'];
    const data = [
      { id: 'Fat', value: fat, label: 'FAT' },
      { id: 'Protein', value: protein, label: 'PROTEIN' },
      { id: 'Carbs', value: carbs, label: 'CARBS' },
      { id: 'Other', value: other, label: 'OTHER' }
    ];

    return { data, colors };
  } else {
    const data = [{ id: 'No nutrition', label: '', value: true }];
    const colors = ['#E0E3E0'];

    return { data, colors };
  }
}

export function filterKitchens(kitchensList, userInfo) {
  if(kitchensList?.length){
  const filteredKitchenList = kitchensList.filter((kitchen) => {
    if (
      userInfo.zipcode &&
      kitchen.deliveryCoverage &&
      kitchen.deliveryCoverage.includes(userInfo.zipcode)
    ) {
      return true;
    }
    const userLatLong = userInfo.userAddressLatLong;
    if (userLatLong && userLatLong.lat && userLatLong.lng) {
      const deliverableAreas = kitchen.deliverableAreas?.data || [];
      for (let polygon of deliverableAreas) {
        const polygonCoords = polygon?.map((coordinate) => [
          coordinate?.lat,
          coordinate?.lng,
        ]);
        const isPointInsidePolygon = isPointInPolygon(
          userLatLong.lat,
          userLatLong.lng,
          polygonCoords
        );

        if (isPointInsidePolygon) {
          return true;
        }
      }
    }

    return false;
  });

  const isDeliveryAvailable = filteredKitchenList?.length > 0;
  return { isDeliveryAvailable, filteredKitchenList };
}
}

export function roundToTwoDecimalPlaces(num){
  return ((num * 100) / 100).toFixed(2);
};


export function formatExpiryDate(expiry){
  if (!expiry || typeof expiry !== 'string') {
    return '';
  }
  const month = expiry.slice(0, 2);
  const year = `20${expiry.slice(2)}`;
  return `${month}/${year}`;
};

export function transformExpiryFormat(expiry) {
  const [month, year] = expiry.split('/').map(part => part.trim());
  const formattedMonth = month.padStart(2, '0');
  const formattedYear = year.length === 2 ? year : (year.length === 4 ? year.slice(2) : '');
  return formattedMonth + formattedYear;
};

export function calculateOrderTotals(providerOrderSummary, providersCount) {
  const getAmountInDollars = (amountInCents) => amountInCents / 100;

  const tax = getAmountInDollars(providerOrderSummary?.taxAmount || 0);
  const deliveryFee = getAmountInDollars(providerOrderSummary?.deliveryFee || 0);
  // const totalMeals = order?.orderLine ? order.orderLine.reduce((total, item) => total + item.quantity, 0) : 0
  const userSubTotalforAll = getAmountInDollars((providerOrderSummary?.userSubTotal) + (providerOrderSummary?.deliveryFee) + (providerOrderSummary?.taxAmount)).toFixed(3);
  const userTotalAmountForOldOrders = providerOrderSummary?.subTotal + providerOrderSummary?.taxAmount + providerOrderSummary?.deliveryFee;
  const userTotalAmountForNewOldOrders = getAmountInDollars((providerOrderSummary?.userTotalAmount ? providerOrderSummary?.userTotalAmount : userTotalAmountForOldOrders).toFixed(3));
  const userSubTotal = getAmountInDollars(providerOrderSummary?.userSubTotal || providerOrderSummary?.subTotal || 0);
  const repeatOrderDiscount = getAmountInDollars(providerOrderSummary?.repeatOrderDiscount || 0);
  const totalAmount = userSubTotal + deliveryFee + tax - repeatOrderDiscount;
  const netTotalAmount = getAmountInDollars((providerOrderSummary?.userTotalAmount || 0) - (providerOrderSummary?.repeatOrderDiscount || 0));
  const userTotalAmount = getAmountInDollars(providerOrderSummary?.userTotalAmount || 0);
  const beforeDiscountedTotal = getAmountInDollars(providerOrderSummary?.beforeDiscountedTotal || 0);
  const totalDiscount = getAmountInDollars(providerOrderSummary?.totalDiscount || 0);

  let actualDiscount;
  if (beforeDiscountedTotal < totalDiscount) {
    actualDiscount = netTotalAmount;
  } else {
    const discountPercent = beforeDiscountedTotal ? (netTotalAmount / beforeDiscountedTotal) * 100 : 0;
    actualDiscount = (totalDiscount * discountPercent) / 100;
  }

  let finalTotalAmount;
  const totalAmountAfterDiscount = userTotalAmountForNewOldOrders - actualDiscount;
  if (userSubTotalforAll > userTotalAmountForNewOldOrders) {
    finalTotalAmount = (parseFloat(totalAmountAfterDiscount) + parseFloat(tax) + parseFloat(deliveryFee) - parseFloat(repeatOrderDiscount)).toFixed(4);
  } else {
    finalTotalAmount = totalAmountAfterDiscount - repeatOrderDiscount;
  }
  const serviceFee = userTotalAmount
    ? finalTotalAmount > 0
      ? finalTotalAmount * 0.035
      : 1 / providersCount
    : totalAmount * 0.035;

  const grandTotal = userTotalAmount
    ? finalTotalAmount > 0
      ? (parseFloat(finalTotalAmount) + parseFloat(serviceFee))
      : serviceFee
    : getAmountInDollars(providerOrderSummary?.totalAmount || 0) + serviceFee;

  return {
    tax,
    deliveryFee,
    // totalMeals,
    userSubTotal,
    totalAmount,
    netTotalAmount,
    beforeDiscountedTotal,
    totalDiscount,
    actualDiscount,
    finalTotalAmount,
    repeatOrderDiscount,
    serviceFee,
    grandTotal,
    userTotalAmount
  };
}

export const dayHours = () => {
  const startOfDay = moment().startOf('day');
  const hours = Array(24).fill(null).map((_, i) => startOfDay.add(1, 'hours').format('H:mm'));
  hours.unshift(startOfDay.format('H:mm'));
  hours.pop();
  return hours; // return [, 0:00, 1:00, 2:00, ...., 22:00, 23:00]; 
} // render dayHours()

export const groupBy = function (xs, key) {
  return xs?.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {}); // return {similarKey: [{...}, {...}], similarKey: [{...}, {...}]}
}; // render groupBy([{...}, {...}, {...}, {...}], similarKey) ex: groupBy(formatValues, 'name')
