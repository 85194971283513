import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import MobileHeader from 'components/elements/MobileHeader';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import CardInformation from './CardInformation';
import { useAppContext } from 'context/appContext';
import { ReactComponent as ArrowLineLeft } from 'assets/shop/arrow-line-left.svg';
import { useShopContext } from 'context/shopContext';
import { useRequest } from 'hooks/useRequest';
import { Modal } from 'react-responsive-modal';
import { ReactComponent as Close } from 'assets/shop/close-cross.svg';
import { transformExpiryFormat } from 'utils';

const AddPaymentMethodStyle = styled.div`
  background-color: #fff;
  height: 100%;
  .main_heading {
    padding: 16px 16px 12px;
    font-size: ${({ theme }) => theme?.typography?.fontMedium};
    line-height: 24px;
    color: ${({ theme }) => theme?.colors?.deepForestGreen};
  }
  .mobile_save {
    position: fixed;
    width: 100%;
    bottom: 16px;
    padding: 0 16px;
    display: flex;
    gap: 11px;
    > div {
      flex: 1;
    }
    button {
      width: 100%;
      font-size: 16px;
      height: 42px;
      border-radius: 30px;
    }
  }
  @media (min-width: 991px) {
    width: 400px;
    max-height: 350px;
    border-radius: 8px;
    overflow: clip;
    display: ${({ errorModal }) => (errorModal ? 'none' : '')};
    .containerr{
      height: 100%;
    }
    .main_heading{
      padding-top: 0 !important;
    }
    .back_icon{
      padding: 16px;
      .icon{
        display: block;
        cursor: pointer;
      }
    }
  }
`;

const PaymentModal = styled.div`
  .modal {
    border-radius: 10px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 14px 0 24px 0px;
    .close-image{
      position: relative;
      left: 16px;
      cursor: pointer;
    }
      .container{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0px 8.976px 0px 8px;
        .text-container{
          gap: 20px;
          display: flex;
          flex-direction: column;
        }
        .desc{
          color: #00092D;
          text-align: center;
          font-family: Gilroy;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
         }
        .delete-title{
          color: #0B1D17;
          text-align: center;
          font-family: Gilroy;
          font-size: ${({ cardError }) => (cardError ? "20px" : "16px")};
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        .delete-buttons{
          align-self: center;      
        }
        .button{
          display: flex;
          flex-direction: column;
          gap: 8px;
          button{
            font-family: Gilroy !Important;
            line-height: normal;
          }
        }
      }
  }
  @media (max-width: 991px){
    .modal{ 
      .delete-buttons{
        width: 220px;
        align-self: center;      
      }
    }
  }
  @media (min-width: 992px) {
    .modal{
      width: 343px;
      .delete-buttons{
        align-self: center;
      }
      .button{
        flex-direction: row !Important;
        button{
          min-width: ${({ cardError }) => (cardError ? "220px" : "129.5px")};
        }
      }
    }
  }
`;


const ManagePaymentMethod = ({ isEdit, handleLeftClick, paymentInfo, setPaymentInfo, getSavedCards, customerId, handleDeleteCard, errorModal, setErrorModal, setIsAddPayment, setIsPaymentModalActive }) => {
  const { isMobile } = useAppContext();
  const { settings: { userInfo }, orderSummaryInfo } = useShopContext();
  const [modal, showModal] = useState(false);
  const [cardInputError, setCardInputError] = useState();

  const [updateCardDetails, { isLoading: isCardUpdating, error: cardUpdatingError }] = useRequest('paymentMethod/update-payment-method', 'Patch');
  const [addPaymentMethod, { isLoading: isPaymentAdding, error: paymentAddingError }] = useRequest('paymentMethod/add-payment-method', 'post');

  const displayNumbers = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  useEffect(() => {
    return () => {isEdit && setPaymentInfo({})}
  }, [])

  const customStyles = {
    modalContainer: {
      position: 'relative',
      top: isMobile ? '38%' : '',
      margin: '0 16px',
      overflowY: 'hidden',
      background: 'none !important',
    },
  };

  const deleteCustomStyles = {
    modalContainer: {
      position: 'relative',
      top: isMobile ? 'calc(100vh - 248px)' : '',
      margin: '0 16px',
      overflowY: 'hidden',
      background: 'none !important',
    },
  };

  const extractPaymentInfo = (paymentInfo) => {
    const { paymentMethodId, expiryDate, setAsDefault, zipcode } = paymentInfo;
    return {
      paymentMethodId,
      expiry: expiryDate.replace(" / ", ""),
      zip: zipcode,
      setAsDefault,
    };
  };
  
  const handleUpdatePaymentInfo = (key, value) => {
    setPaymentInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateCard = async () => {
    try {
      const response = await updateCardDetails({body: extractPaymentInfo(paymentInfo)});
      if(response?.success){
        await getSavedCards()
        setIsAddPayment(false);
      }
    }  catch (err) {
      console.log('Error fetching saved cards', err);
      setErrorModal(true);
    }
  }


  const addPaymentMethodForCustomer = async ({ paymentInfo, customerId, orderSummaryInfo }) => {
     const { cardNumber, cvv, expiryDate, zipcode } = paymentInfo;
    try {
      const transformedExpiry = transformExpiryFormat(expiryDate);
      const response =  await addPaymentMethod({
        body: {
          cardNumber,
          cvv,
          expiry: transformedExpiry,
          amount: displayNumbers(orderSummaryInfo?.orderSummary?.grandTotal),
          zip: zipcode,
          customerId
        }
      });
      if (response && response.success) {
        getSavedCards();
        setIsAddPayment(false);
      } 
    } catch (error) {
      console.error('Error adding payment method:', error);
      setErrorModal(true);
    }
  };

  const handleErrorModalClose = () => {
    setErrorModal(false);
    if (!isMobile) {
      setIsAddPayment(false);
      setIsPaymentModalActive(false);
    }
  }
  
  const isButtonDisabled = useMemo(() => {
    return (
      !paymentInfo?.cardNumber ||
      !paymentInfo?.cvv ||
      !paymentInfo?.expiryDate ||
      !paymentInfo?.zipcode || cardInputError &&
      Object.values(cardInputError).some(error => {
        return error && error.length;
      })
      );
  }, [paymentInfo, cardInputError]);

  const isEditSaveButtonDisabled = useMemo(() => {
    return (
      !paymentInfo?.cardNumber ||
      !paymentInfo?.expiryDate ||
      !paymentInfo?.zipcode ||
      cardInputError?.length
    );
  }, [paymentInfo, cardInputError]);

  return (
    <AddPaymentMethodStyle theme={theme} errorModal={errorModal}>
      {isMobile ? 
      <MobileHeader
        text={isEdit ? 'Edit payment' : 'Add payment'}
        onClickLeft={handleLeftClick}
      />
      : 
      <div className='back_icon'>
        <ArrowLineLeft className='icon' onClick={handleLeftClick} />
      </div>
      }
      <h2 className="main_heading">{isMobile ? 'Card information' : 'Add debit / credit card'}</h2>
      <CardInformation
        paymentInfo={paymentInfo}
        handleUpdatePaymentInfo={handleUpdatePaymentInfo}
        cardInputError={cardInputError}
        setCardInputError={setCardInputError}
      />

      <div className="mobile_save">
        {!isEdit ? (
          <>
            <Button type={'mobile_secondary'} title="Cancel" onClick={handleLeftClick} />
            <Button type={'mobile_save'} title="Save" isDisabled={isButtonDisabled || isPaymentAdding} onClick={() => addPaymentMethodForCustomer({ paymentInfo, orderSummaryInfo, customerId })} />
          </>
        ) : (
          <>
            <Button type={'danger'} title="Delete" onClick={() => showModal(true)}/>
            <Button type={'mobile_save'} title='Save' isDisabled={isEditSaveButtonDisabled} onClick={() => updateCard()}/>
          </>
        )}
      </div>
      <Modal open={modal} blockScroll={false} center styles={{ modalContainer: deleteCustomStyles.modalContainer }} showCloseIcon={false}>
        <PaymentModal>
            <div className='modal'>
            <Close className='close-image' onClick={() => showModal(false)}/>
              <div className='container'>
                <p className='delete-title'>Are you sure you’d like <br/>to delete the card?</p>
              <div className='delete-buttons'>
                  <div className='button'>
                    <Button type={'mobile_save'} title="Yes" onClick={() => handleDeleteCard(paymentInfo?.paymentMethodId)}/>
                    <Button type={'mobile_secondary'} title="Cancel" onClick={() => showModal(false)}/>
                  </div>
                </div>
              </div>
              </div>
              </PaymentModal>
        </Modal>
        <Modal open={errorModal} blockScroll={false} center styles={{ modalContainer: customStyles.modalContainer }} showCloseIcon={false}>
        <PaymentModal cardError={true}>
            <div className='modal' style={isMobile ? {padding: "32px 0 24px 0px"}: {paddingBlock: "24px"}}>
            {!isMobile && <Close className='close-image' onClick={handleErrorModalClose}/>}
              <div className='container'>
                <div className='text-container'>
                <p className='delete-title'>Your card is declined</p>
                <p className='desc'>Please check your card <br/> information or try a different card. </p>
                </div>
              <div className='delete-buttons'>
                  <div className='button'>
                    <Button type={'mobile_save'} title="Ok" onClick={handleErrorModalClose} />
                  </div>
                </div>
              </div>
              </div>
              </PaymentModal>
        </Modal>
    </AddPaymentMethodStyle>
  );
};

export default ManagePaymentMethod;
