import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const FiltersModalWrapper = styled.div`
  font-family: ${theme.typography.fontGilroy};
  display: none;

  &.isFilters {
    display: flex;
  }

  .filters-mask {
    position: fixed;
    inset: 0;
    z-index: 4;
  }

  .filters-modal-container {
    position: absolute;
    top: 62px;
    left: 302px;
    z-index: 4;
    overflow-y: auto;
    width: fit-content;
    max-height: 435px;
    padding: 24px;
    background-color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.grayLighter};
    border-radius: 8px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
  }

  .filters-title {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: ${theme.colors.blackDefault};
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    img {
      cursor: pointer;
    }
  }

  .filters-label {
    color: ${theme.colors.primaryDarker};
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
  }

  .filter-condition-wrapper + .filter-condition-wrapper {
    margin-top: 14px;
  }

  .new-condition-wrapper {
    width: fit-content;
    margin: 9px 0 12px;
    padding: 8px 16px 8px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: ${theme.colors.primaryDark};
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }

  .actions-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .btn {
      border-radius: 6px;
      font-family: ${theme.typography.fontGilroy};
      &.dark {
        border: 0 !important;
        &:after {
          display: none;
        }
      }
      &[disabled] {
        cursor: not-allowed;
      }
    }
  }

  .date-range-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    &.displayCalendar input {
      border-color: ${theme.colors.primaryDefault};
    }
    .input-arrow {
      position: absolute;
      top: 14px;
      right: 13px;
      cursor: pointer;
    }
    .rmdp-input + div {
      position: fixed !important;
      top: auto !important;
      left: auto !important;
      transform: translate(0px, 5px) !important;
    }
    .rmdp-wrapper {
      border: 1px solid ${theme.colors.grayLighter};
      border-radius: 8px;
      box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
    }
    .rmdp-calendar {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 17.18px;
      .rmdp-header {
        margin: 0;
        padding: 0;
      }
      .rmdp-arrow-container {
        margin: 0;
      }
      .direction-month-icon {
        display: flex;
        cursor: pointer;
      }
      .rmdp-header-values span {
        color: ${theme.colors.blackDefault};
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .rmdp-day-picker {
      .rmdp-week {
        margin-bottom: 8.2px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .rmdp-week-day,
      .rmdp-day {
        color: ${theme.colors.blackDefault};
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        span {
          width: 100%;
          height: 100%;
          inset: 0;
        }
        &.rmdp-today span {
          background-color: ${theme.colors.grayDark};
        }
        &.rmdp-range {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(255, 255, 255, 0.9) 100%
            ),
            #878a98;
          box-shadow: none;
        }
        &.rmdp-range.start span,
        &.rmdp-range.end span,
        span:hover {
          background-color: ${theme.colors.primaryDefault};
          border-radius: 100px;
          color: ${theme.colors.secondary};
        }
        &.rmdp-disabled {
          color: ${theme.colors.blackLighter};
          span:hover {
            color: ${theme.colors.blackLighter};
            background-color: transparent;
            cursor: not-allowed;
          }
        }
      }
      .rmdp-week-day {
        color: ${theme.colors.primaryDefault};
        font-weight: 400;
      }
      .rmdp-deactive {
        color: ${theme.colors.blackLighter};
      }
    }
  }

  .disabledAddCondition {
    cursor: not-allowed !important;
    &.Mui-disabled {
      pointer-events: unset;
    }
  }

  @media (max-width: 1400px) {
    .selected-value, input, .options-dropdown {
      min-width: 180px;
      width: 180px;
      max-width: 204px;
    }
  } 

  @media (max-width: 1290px) {
    .rmdp-input + div {
      right: 0;
    }
  }
`;
