import { OrderNumberStatusWrapper } from './style';

export default function OrderNumberStatus({
  orderNumber,
  orderType,
  displayOrderDate,
  formattedTime,
  isRecurringOrder,
}) {
  return (
    <OrderNumberStatusWrapper>
      <div className="order-number-status">
        <div className="order-number">{orderNumber}</div>
        <div className={`order-status ${orderType}`}>
          {orderType === 'pending' ? 'Not Accepted' : orderType === 'refused' ? 'Rejected' : orderType}
        </div>
        {isRecurringOrder && (
          <div className="recurring-order-container">
            <img src="/icons/recurring.svg" alt="Recurring Icon" />
            <span className='recurring-label'>Recurring order</span>
          </div>
        )}
      </div>
      <div className="order-time">
        {displayOrderDate} {formattedTime}
      </div>
    </OrderNumberStatusWrapper>
  );
}
