import styled from 'styled-components';
import ErrorComponent from '../../../components/ErrorComponent';
import { useRequest } from '../../../hooks/useRequest';

const StyledButton = styled.button`
  background: pink;
  /* margin-top: 20px; */
  color: red;
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 100%;
  font-size: 1rem;

  :hover {
    background: red;
    color: white;
  }
  :active {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
      1px -1px 20px 0px rgb(0 0 0 / 12%);
  }
`;
export default function DeleteButton({ mealId,componentId,setIsDelete, provider,getData }) {
  setIsDelete(true);
  const [deleteMeal, { isLoading, error }] = useRequest(`meal/delete?providerId=${provider?.id}`, 'delete');
  const handleDelete = () => {
    deleteMeal({ body: { componentId,mealIds:[mealId] } }).then(async (res) => {
      await getData()
    });
  };
  return (
    <div style={{ width: '400px', padding: '30px' }}>
      {error?.message && <ErrorComponent title="Error" error={error.message[0]} style={{ marginBottom: 10 }} />}
      <StyledButton onClick={handleDelete}>{isLoading ? 'Deleting...' : 'Delete'}</StyledButton>
    </div>
  );
}
