import React, { useMemo, useEffect, useCallback, useState } from 'react';
import Tooltip from 'rc-tooltip';
import styled from 'styled-components';
import { theme } from '../../../../shared/theme/theme';
import Alert from 'components/elements/alert/Alert';
import { AnimatePresence } from 'framer-motion';
import { useRequest } from 'hooks/useRequest';
import { displayDateTime } from 'utils';
import ReactTooltip from 'react-tooltip';
import Toggle from 'components/elements/toggleModule/Toggle';
import { useKitchenContext } from 'context/kitchenContext';
import { Skeleton } from '@mui/material';
import { useAppContext } from 'context/appContext';
import { OrderActionModal } from 'pages/provider/storefront/orders/style';
import { Modal } from 'components/elements/modal/Modal';

const DetailCardStyle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: start;
  height: 60px;
  border-bottom: 1px solid ${theme.colors.grayLighter};
  cursor: pointer;
  &.active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 3px;
      background-color: ${theme.colors.success};
      height: 100%;
    }
    .order-number p {
      font-weight: 600;
    }
  }
  &.selectedPanel {
    background-color: #E9EAEC;
  }
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    background-color: ${theme.colors.grayLightest};
    .order-actions-wrapper {
      opacity: 1;
    }
    input[type='checkbox'] {
      border-color: ${theme.colors.primaryDarker} !important;
    }
  }
  &:active {
    background-color: #E9EAEC;
  }
  .new {
    margin-right: 16px;
    display: flex;
  }
  .order-number-wrapper {
    display: flex;
    font-family: ${({ theme }) => theme?.typography?.fontInter};
    color: ${({ theme }) => theme?.colors?.blackDefault};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 23%;
    height: 100%;
    padding: 0 10px 0 18px;
    word-break: break-all;
    align-items: center;
    justify-content: space-between;
    .order-number {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    label {
      width: 20px;
      height: 20px;
      padding: 1px;
      gap: 0 !important;
      input[type='checkbox']:checked:after {
        width: 93%;
        height: 96%;
      }
    }
    input {
      width: 100%;
      height: 100%;
      margin: 0;
    }
    p {
      padding: 0;
      color: ${theme.colors.blackDefault};
      font-weight: 500;
    }
    .badge {
      height: 18px;
      padding: 2px 5px;
      color: ${theme.colors.success};
      background-color: ${theme.colors.lightSuccess};
      border: 1px solid #9ee09d;
      border-radius: 4px;
      font-family: ${theme.typography.fontGilroy};
      font-size: 10px;
      font-weight: 600;
      line-height: 14px;
      white-space: nowrap;
    }
  }
  p {
    height: 100%;
    padding: 0 13px 0 16px;
    font-family: ${theme.typography.fontGilroy};
    color: ${({ theme }) => theme?.colors?.primaryDefault};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    display: flex;
    align-items: center;
    .order-status-wrapper {
      height: 20px;
      padding: 0 8px;
      border-radius: 80px;
      color: ${theme.colors.deepForestGreen};
      font-family: ${theme.typography.fontGilroy};
      font-size: 10px;
      font-weight: 600;
      line-height: 20px;
      text-transform: capitalize;
      flex-shrink: 0;
      &.pending {
        background-color: #ffd580;
      }
      &.accepted {
        background-color: #a0deff;
      }
      &.completed {
        background-color: #90f290;
      }
      &.refused {
        background-color: #ebebeb;
      }
    }
  }
  > p:nth-child(2) {
    width: 13%;
  }
  > p:nth-child(3) {
    width: 13%;
  }
  > p:nth-child(4) {
    width: 11%;
  }
  > p:nth-child(5) {
    overflow: hidden;
    width: 17%;
    height: 20px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-transform: capitalize;
  }
  > p:nth-child(6) {
    width: 10%;
  }

  > p:nth-child(7) {
    width: 13%;
  }
  .icon {
    margin-left: 18px;
    display: flex;
  }

  .orderAt {
    display: flex;
    color: ${({ theme }) => theme?.colors?.grayDarker};
    padding: 0px 21px;
    flex-direction: column;
    justify-content: flex-end;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-right: 40px;
    gap: 19px;
    width: 39%;
    word-break: break-all;
  }
  
  .order-actions-wrapper {
    position: absolute;
    right: 12px;
    top: auto;
    background-color: inherit;
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    .order-action {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .action-wrapper {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%), #878a98;
      }
      &:active {
        background: #e9eaec;
      }
    }
  }
`;

function OrderDetails({
  total = '138',
  orderNumber = '23935783965981-32473857',
  selectedDate,
  orderId,
  orderedAt,
  providerId,
  selected,
  updateSelected,
  isOrderSeen,
  providerOrderId,
  isTestOrder,
  order,
  setSelectedOrder,
  alertData,
  customerName,
  orderStatus,
  orderLine,
  isOrdersApiCallProcessing,
  setIsOrderStatusChanged,
  setAlertData,
  isSplit,
  selectedOrderPanel,
  setSelectedOrderPanel,
  imitateProviderId
}) {
  const [updateOrderStatus] = useRequest();
  const [isOpen, setIsOpen] = useState(false);
  const [acceptSelectedOrder, { isLoading }] = useRequest(`fulfill`, 'post');
  const { setNewOrdersCount, newOrdersCount, setOrderData, orderData } = useKitchenContext() || [];
  const orderStatusIndex = orderStatus?.findIndex((item) => item?.providerId === providerId);
  const {isSuper} = useAppContext();

  useEffect(() => {
    ReactTooltip.rebuild();
    window.scrollTo(0, 0);
  }, []);

  const handleOrderExpandClick = async (id, providerId, isOrderSeen) => {
    if (isSplit) {
      setSelectedOrderPanel(order);
    } else {
      setSelectedOrder(order);
      try {
        if (!isOrderSeen) {
          await updateOrderStatus({
            path: `fulfill/provider-order/${id}`,
            method: 'put',
            body: {
              providerId,
              isOrderSeen: !isOrderSeen,
            },
          });
          const updatedOrderData = { ...orderData };
          const orderToUpdate = updatedOrderData.orders.find(
            (order) => order.orderMainId === orderId,
          );
          orderToUpdate.providers.map((item) => {
            if (item.id === providerOrderId) {
              item['isOrderSeen'] = !isOrderSeen;
            }
          });
          setOrderData(updatedOrderData);
          const updatedOrdersCount = newOrdersCount - 1;
          setNewOrdersCount(updatedOrdersCount);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSave = useCallback(
    async (status, orderId) => {
      if (isLoading) return;
      try {
        setSelectedOrder([]);
        const result = await acceptSelectedOrder({
          body: { newStatus: status, providerId, orderIds: [orderId] },
        });
        if (result) {
          setTimeout(() =>  showSuccessMessage(`You have successfully ${status} the new order`), 1000);
          setIsOrderStatusChanged((prev) => !prev);
        } else {
          showErrorMessage('Error occurred while saving changes');
        }
        setIsOpen(false);
      } catch (e) {
        setIsOpen(false);
        setTimeout(() =>  showErrorMessage('Error occurred while saving changes'), 1000);
      }
    },
    [isLoading],
  );

  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const calcOrdersQty = useMemo(() => {
    return orderLine?.reduce((acc, curr) => curr?.quantity + acc, 0);
  }, [orderLine]);

  return (
    <>
      <DetailCardStyle
        theme={theme}
        className={`${!isOrderSeen ? 'active' : ''} ${
          selectedOrderPanel?.orderMainId === orderId ? 'selectedPanel' : ''
        }`}
        onClick={() => handleOrderExpandClick(providerOrderId, providerId, isOrderSeen)}
      >
        <div className="order-number-wrapper">
          <div className="order-number">
            <div onClick={(e) => e.stopPropagation()}>
              <Toggle
                className="header-checkbox"
                type="checkbox"
                onChange={(e) => {
                  updateSelected({ id: orderId, selected: e.target.checked });
                }}
                checked={selected}
                toggleColor={theme.colors.primaryDefault}
                size={'18px'}
              />
            </div>
            <p>{`${orderNumber} ${isTestOrder ? '(Test Order)' : ''}`}</p>
          </div>
          {!isOrderSeen && <div className="badge">New</div>}
        </div>
        <p>{displayDateTime(orderedAt, 'MMM D, YYYY')}</p>
        <p>{displayDateTime(selectedDate, 'MMM D, YYYY')}</p>
        <p>
          {!!orderStatus && (
            <div className={`order-status-wrapper ${orderStatus[orderStatusIndex]?.action}`}>
              {orderStatus[orderStatusIndex]?.action === 'pending'
                ? 'Not Accepted'
                : orderStatus[orderStatusIndex]?.action === 'refused'
                ? 'Rejected'
                : orderStatus[orderStatusIndex]?.action}
            </div>
          )}
        </p>
        <p>{customerName}</p>
        <p>${total}</p>
        <p>{orderLine?.length}</p>
        <div className="order-actions-wrapper" onClick={(e) => e.stopPropagation()}>
          {orderStatus[orderStatusIndex]?.action === 'pending' && (
            <div className="order-action">
              <Tooltip
                overlayClassName="kitchen-orders-tooltip"
                placement="top"
                overlay="Accept the order"
                arrowContent={<div />}
              >
                <div className="action-wrapper" onClick={() => handleSave('accepted', orderId)}>
                  <img src="/icons/receipt-right-black.svg" alt="Receipt Icon" />
                </div>
              </Tooltip>
              <Tooltip
                overlayClassName="kitchen-orders-tooltip"
                placement="top"
                overlay="Reject the order"
                arrowContent={<div />}
              >
                <div className="action-wrapper" onClick={() => setIsOpen(true)}>
                  <img src="/icons/receipt-remove.svg" alt="Receipt Icon" />
                </div>
              </Tooltip>
            </div>
          )}
          {orderStatus[orderStatusIndex]?.action === 'accepted' && (
            <Tooltip
              overlayClassName="kitchen-orders-tooltip"
              placement="top"
              overlay="Complete the order"
              arrowContent={<div />}
            >
              <div className="action-wrapper" onClick={() => handleSave('completed', orderId)}>
                <img src="/icons/receipt-edit-black.svg" alt="Receipt Icon" />
              </div>
            </Tooltip>
          )}
          <a href={`/kitchen/orders/print${isSuper ? `?providerId=${imitateProviderId}` : ''}`} target="_blank">
            <Tooltip
              overlayClassName="kitchen-orders-tooltip"
              placement="top"
              overlay="Print order details"
              arrowContent={<div />}
            >
              <div
                className="action-wrapper"
                onClick={() => {
                  localStorage.setItem('checkedOrders', JSON.stringify([order]))
                  if (localStorage.getItem("orderDetails")) localStorage.removeItem('orderDetails');
                }}
              >
                <img src="/icons/printer.svg" alt="Printer Icon" />
              </div>
            </Tooltip>
          </a>
        </div>
      </DetailCardStyle>
      <AnimatePresence>
        {alertData?.type && (
          <div className="alert">
            <Alert
              type={alertData.type}
              title={alertData.title}
              width="461px"
              onClose={() => setAlertData({ type: '', title: '' })}
            />
          </div>
        )}
      </AnimatePresence>
      {isOpen && (
        <OrderActionModal>
          <Modal
            description={'Are you sure you want to reject this order?'}
            width="500px"
            paddingBottom="30px"
            paddingTop="4px"
            color={theme.colors.blackDefault}
            isOpen={isOpen}
            onOk={() => handleSave('refused', orderId)}
            onClose={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            title={'Reject the order'}
            primaryText={`Reject order`}
            secondaryText={'Cancel'}
            isBackdropEnabled={true}
            buttonType={'dark'}
            type="null"
          />
        </OrderActionModal>
      )}
    </>
  );
}

export default OrderDetails;