import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { ReactComponent as SandwichLogo } from 'icons/Sandwich.svg';
import classNames from 'classnames';
import { HiOutlineLogout } from 'react-icons/hi';
import { useShopContext } from 'context/shopContext';


const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  justify-self: end;
  display: flex;

  .nav-div {
    color:grey;
  }
  .nav-svg{
    color:grey;
    svg {
      stroke:grey;
      }
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 14px;
    cursor: pointer;
  }

  &:hover svg path {
    stroke: #52c41a;
  }

  &.isProvider {
    img {
      width: 35px;
      height: 35px;
      margin: 0;
      border-radius: 50%;
    }

    .menu-links-wrapper {
      position: absolute;
      top: 45px;
      right: 0;
      left: auto;
      width: 200px;
    }

    .menu-links {
      padding-top: 20px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 4px;
      box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
    }

    a,
    a span {
      color: #52c41a;
    }
  }

  @media (max-width: 991px) {
    img {
      width: 34px;
      height: 34px;
      padding: 5px;
      margin-left: 10px;

      &.menuOpened {
        z-index: 14;
        width: 34px;
        height: 34px;
        padding: 5px;
        background-color: #c4c4c440;
        border-radius: 50%;
      }
    }
  }
`;
const Overlay = styled.div`
  height: 100vh;
  /* background: #bbbbbb70; */
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;

  @media (max-width: 991px) {
    cursor: none;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #52c41a;

  & + & {
    margin-top: 32px;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 991px) {
    padding: 0;
    color: #51635d;
    font-size: 18px;
    line-height: 24px;
    &:last-child {
      color: #949d9b;
    }
    & + & {
      margin-top: 32px;
    }
  }
`;

const StyledMenu = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;

  .close-menu-wrapper {
    margin: 25px 0 40px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  span {
    color: #51635d;
    font-size: 16px;
    line-height: 19px;
  }
  .logoutbtn{
    color: #949D9B;
  }

  .menu-links {
    overflow-y: auto;
    max-height: calc(100vh - 87px);
    padding: 0 32px 20px;
  }

  @media (max-width: 1199px) {
    width: 220px;

    .close-menu-wrapper {
      padding: 0 20px;
    }

    .menu-links {
      padding: 0 20px 20px;
    }
  }

  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 13;
    width: 100%;
    box-shadow: 0px 4px 10px inset rgb(0 0 0 / 5%);
    ${FlexCenter};

    .menu-links {
      flex-direction: column;
      ${FlexCenter};
    }
  }
`;

export default function ProfileMenu({ updateProvider, passProvider, isAlert, setIsMenu, isMenu }) {
  const { logout, provider, isProvider,isVisitor, profile, user, isMobile, isUserSuspended } = useAppContext();
  const{isActiveRoute,setIsActiveRoute} = useShopContext()
  const { pathname } = useLocation();
  const history = useHistory();
  
  const orderCount = useMemo(()=>{
    return localStorage.getItem('orderCount');
  },[localStorage.getItem("orderCount")])

  const pushLink = (link) => {
    setIsMenu(false);
    if (pathname == '/provider') {
      JSON.stringify(updateProvider) === JSON.stringify(passProvider)
        ? history.push(link)
        : isAlert(link);
    } else {
      history.push(link);
    }
  };

  useEffect(() => {
    isMenu && isMobile
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isMenu]);

  return (
    <>
      {isMenu && (isMobile ? <Overlay /> : <Overlay onClick={() => setIsMenu(false)} />)}
      <Container isMenu={isMenu} className={classNames({ isProvider: isProvider })}>
        {pathname.includes('/plan/') ||
        (!user && pathname.includes('/booking/')) ||
        pathname == '/onboarding' ? (
          <SandwichLogo />
        ) : (
          pathname.startsWith('/provider') && !isMobile ? 
          (
            <div>
            <Link to="/logout" className="nav-div" onClick={logout}>
            <div className='nav-svg'>
                <HiOutlineLogout /> Logout
            </div>
        </Link>
        </div>
          ) :
          <img
            onClick={() => {
              setIsMenu(!isMenu);
            }}
            src={
              (isProvider && provider?.images && provider.images.length > 0)
                ? provider?.images[0] || '/images/avatar.png'
                : isMenu
                ? '/icons/gray-close circle.svg'
                : '/icons/Sandwich.svg'
            }
            alt="Sandwich Icon"
            className={classNames('header-sandwich-menu', { menuOpened: isMenu })}
          />
          
        )}
        {isMenu && (
          <StyledMenu className="menu-links-wrapper">
            {!isMobile && !isProvider && (
              <div className="close-menu-wrapper" onClick={() => setIsMenu(false)}>
                <img src="/icons/close.svg" alt="Close Icon" /> <span>Close Menu</span>
              </div>
            )}
            <div className="menu-links">
              {(Number(orderCount) > 0 && !isUserSuspended) && (
                <StyledLink to="/shop/order" onClick={() => {setIsMenu(false),setIsActiveRoute(!isActiveRoute)}}>
                  {!isMobile && <img src="/icons/document.svg" alt="Document Icon" />}
                  <span>My Orders</span>
                </StyledLink>
              )}
              {((Number(orderCount)>0 || isVisitor) && !isUserSuspended) && 
                <StyledLink to="/shop/profile" onClick={() => setIsMenu(false)}>
                  {!isMobile && <img src="/icons/profile-circle.svg" alt="Profile Icon" />}
                  <span>Profile</span>
                </StyledLink>
              }
              {!isProvider && (
                <>
                  <StyledLink to="/users/terms">
                    {!isMobile && <img src="/icons/menu-board.svg" alt="Menu Icon" />}
                    <span>Terms</span>
                  </StyledLink>
                  <StyledLink to="/nutritt/privacy-policy">
                    {!isMobile && <img src="/icons/lock-green.svg" alt="Lock Icon" />}
                    <span>Privacy</span>
                  </StyledLink>
                </>
              )}
              <StyledLink to="/logout" onClick={logout}>
                {!isMobile && !isProvider && (
                  <img src="/icons/logout-green.svg" alt="Logout Icon" />
                )}
                <span className='logoutbtn'>Logout</span>
              </StyledLink>
            </div>
          </StyledMenu>
        )}
      </Container>
    </>
  );
}

