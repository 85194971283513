import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import NutrittLogo from './NutrittLogo';
import { useAppContext } from 'context/appContext';
import { path } from 'ramda';

const FlexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const StyledFooter = styled.footer`
display: flex;
.pagefooter{
  display: flex!important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute;
  bottom: -32px;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #ffffff;
  color: #0b1d17;
  height: 32px;
  width: 100%;
  font-size: 11px;
  font-family: 'Roboto';
  line-height: 12px;
  letter-spacing: 0.02em;
  color: #0b1d17;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #52c41a;
  }
}
  .nutritionistfooter{
    position: absolute;
    bottom: 11px !important;
    left: 0;
    right: 0;
    z-index: 3;
    background:none !important;
    color: #0b1d17;
    height: 32px;
    width: 100%;
    font-size: 15px;
    font-family: 'Roboto';
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #0b1d17;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: #52c41a;
    }
    .copyright,.and,.link {
       margin-right:7px;
    }
    .contact{
      margin-left:7px;
    }
    .line{
      margin-left:5px;
    }
  }
  @media (min-width: 992px) {
    background-color: #ffffff;
    .nutritionistfooter{
      z-index:-1 !important;
    }
    &.shopFooter {
      height: fit-content;
      bottom: -135px;
      background:#ffffff;
    }

    &.shopMeals {
      width: 100%;
      display: none;


      .footer-container {
        width: 95%;

        .footer-details {
          padding: 34px 5px;
        }

        .footer-links a + a {
          margin-left: 30px;
        }

        .footer-copyright-links a:first-child {
          margin-right: 30px;
        }
      }
    }

    .footer-container {
      width: 90%;
      margin: 0 auto;
      .footer-details {
        width: 100%;
        padding: 34px 20px;
        ${FlexBetween};
        border-bottom: 1px solid #afb5b44d;
      }
      .footer-links a {
        color: #0b1d17;
        font-size: 14px;
        font-family: 'Roboto';
        line-height: 16px;
        & + a {
          margin-left: 75px;
        }
      }
      .footer-socialMedia-wrapper {
        display: flex;
        align-items: center;
        span {
          margin-right: 25px;
          color: #51635d;
          font-size: 14px;
          line-height: 16px;
        }
        img {
          cursor: pointer;
          & + img {
            margin-left: 10px;
          }
        }
      }
      .footer-copyright {
        padding: 10px 20px;
        ${FlexBetween};
        p {
          font-size: 12px;
          line-height: 14px;
          a {
            font-size: 12px;
            line-height: 14px;
            color: #51635d;
          }
        }
      }
      .footer-copyright-links a {
        color: #51635d;
        font-size: 12px;
        line-height: 14px;
        &:first-child {
          margin-right: 75px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    &.shopMeals .footer-container {
      width: 90%;

      .footer-details {
        padding: 34px 20px;
      }

      .footer-links a + a {
        margin-left: 75px;
      }

      .footer-copyright-links a:first-child {
        margin-right: 75px;
      }
    }

    .footer-container {
      width: 65%;
    }
  }
`;

const socialMedia = ['facebook-icon', 'instagram-icon', 'linkedin-icon'];

export default function Footer({ showTerms }) {
  const { isMobile, user, isVisitor, isGuest, isUserSuspended,profile } = useAppContext();
  const { pathname } = useLocation();
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  if (pathname === '/') return '';
  if (pathname === '/terms') return '';
  // if (pathname === '/shop/checkout') return '';
  if (pathname === '/shop/plan' && isMobile) return '';
  if (pathname ==="/") return '';
  if (pathname === '/shop/my-coach' && isMobile) return '';
  if (pathname === '/shop/pantry') return '';
  if (pathname === '/shop/my-coach/mealplans' && isMobile) return '';
  if (pathname === '/shop/my-coach/offers' && isMobile) return '';
  if (pathname === '/dashboard/clients/invite') return '';
  if (pathname === '/onboarding/first-time') return '';
  if (pathname.includes('/nutritionist/planning') && !isMobile) return '';
  if (pathname === '/shop/all-coach' && isMobile) return '';
  if (pathname === '/shop/browse-plan' && isMobile) return '';
  if (pathname === '/admin/users' && !isMobile) return '';
  if (pathname === '/nutritionist/followers') return '';
  if (pathname.includes('/shop/kitchen')) return '';
  if (pathname === '/shop/profile' && profile?.selfMealPlan && isMobile) return '';
  if(pathname.includes('/visitor') || pathname.includes('/store')) return '';
  if(pathname.includes('/kitchen')) return '';
  if(pathname === "/session") return '';


  const showFooter = (path) => {
    if (path.includes("/shop")  || path==="/login" || (pathname.includes('/guest/')) 
    || pathname.includes('/booking/') || pathname.startsWith('/plan') 
    || path==="/signup" || pathname.includes('/registerprovider')
    || pathname.includes('/claim-suspended-account')) {
      return true;
    }
    else {
      return false;
    }
  }
  

    return !(!isMobile && showFooter( pathname )) ? (
        (!isUserSuspended && (pathname !== '/visitor/get-started') && (!pathname.includes('/self-onboarding/'))) && (pathname !== '/login') && <StyledFooter>
        <div className={`pagefooter`}>
        <span className='copyright'>Copyright </span> <span className='and'>&copy;</span> <span className='link'><a href={window.location.origin}>Nutritt Inc</a></span> {' '}
          {new Date().getFullYear()}
        <span className='line'>{' | '}</span> 
          {pathname.includes('/nutritionist') ? '' :
                  showTerms && (
                    <>
                      <Link className="terms" to="/terms">
                        Terms of use
                      </Link>
                      {' | '}
                    </>
                  )
                  }
          <span className='contact'><a href="https://forms.gle/5JqVAHyuahpXNomC9" target="_blank">Contact Us</a> </span>         
          {pathname.includes('/nutritionist') ? '' :
          <Link className="terms" to="/nutritt/privacy-policy">
            {' | '} Privacy Policy
          </Link>
          }
        </div>
      </StyledFooter>
    ) : (
      <StyledFooter className={classNames({ shopMeals: pathname == '/shop/meals' || pathname == '/visitor/meals', shopFooter: pathname.startsWith('/shop') })}>
      <div className="footer-container">
        <div className="footer-details">
          <NutrittLogo />
          <div className="footer-links">
            <Link>About</Link>
            <Link to="/nutritt/support">Contact</Link>
            <a href='https://nutritt.typeform.com/careers' target='_blank'>Careers</a>
          </div>
          <div className="footer-socialMedia-wrapper">
            <span>Follow Us</span>
            {socialMedia.map((icon, index) => (
              <img src={`/icons/${icon}.svg`} alt={`${icon} Icon`} key={index} />
            ))}
          </div>
        </div>
        <div className="footer-copyright">
          <div className="footer-copyright-links">
            <Link to="/users/terms">Terms of Services</Link>
            <Link  to="/nutritt/privacy-policy">Privacy Policy</Link>
          </div>
          <div>
            <p>
              &copy; 2020-{new Date().getFullYear()} <Link to="/">Nutritt.com</Link> – All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}