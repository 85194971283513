import { useState, useEffect, useMemo, useCallback } from 'react';
import produce from 'immer';
import useCart from 'hooks/useCart';
import { useHistory } from 'react-router';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { useShopContext } from 'context/shopContext';
import { NavHashLink } from 'react-router-hash-link';
import KitchenHomeDesktop from '../KitchenHomeDesktop';
import { BottomSheet } from 'react-spring-bottom-sheet';
import KitchenCard from 'components/elements/KitchenCard';
import { Button } from 'components/elements/button/Button';
import QuantityButton from 'components/elements/QuantityButton';
import HurryOrder from 'components/shop/KitchenHome/HurryOrder';
import KitchensList from 'components/shop/KitchenHome/KitchensList';
import KitchenOurMission from 'components/shop/KitchenHome/KitchenOurMission';
import KitchenMealDetails from 'components/shop/FavoriteKitchen/KitchenMealDetails';
import FavoriteKitchenCategories from 'components/shop/KitchenHome/FavoriteKitchenCategories';
import useVisibility from 'components/shop/FavoriteKitchen/KitchenMealDetails/VisibilityHook';
import { KitchenHomeWrapper, ProgressWrapper } from './style';
import { useVisitorContext } from 'context/visitorContext';
import Bummer from 'components/shop/FavoriteKitchen/Bummer';
import AddressesList  from 'pages/shop/Addresses/AddressesList';
import { filterKitchens } from 'utils';
import HomeSkeleton from 'skeletons/user/HomeSkeleton';
import { motion } from 'framer-motion';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { getEarliestTime } from '../providerTimes';
import DeliveryNotAvailable from '../DeliveryNotAvailable';
import styled from 'styled-components';

const BummerStyle = styled.div`
  .bummer__style{
    z-index: 112;
  }
`

export default function KitchenHome() {
  const [open, setOpen] = useState(false);
  const [mealInfo, setMealInfo] = useState({});
  const [initialQty, setInitialQty] = useState();
  const [mealDetails, setMealDetails] = useState({});
  const [count, setCount] = useState();
  const {
    profile,
    isMobile,
    user,
    setFavoriteKitchenMeals,
  } = useAppContext() ?? {};
  const favoriteKitchenData =profile?.favoriteKitchenData;
  const {
    settings,
    storeCategoriesMeals,
    kitchensList,
    isKitchenListGenerating,
    isCategorizedMealGenerating,
    updateSettings,
    setKitchenUserName,
  } = user ? useShopContext() : useVisitorContext();
  const history = useHistory();
  const { cart, setHeaderAddress, showBummer, setShowBummer, isFavoriteKitchenDelivered, setIsFavoriteKitchenDelivered, isAnyKitchenDeliveryAvailable, setIsAnykitchenDeliveryAvailable } = useShopContext();
  const { addToCart, updateMealQty, removeFromCart } = useCart();
  const { userInfo, provider, mealPlan} = settings;
  const mealInCart = cart?.find((meal) => meal?.id === mealDetails?.id);
  const [beforeCheckoutSubmitShown, beforeCheckoutSubmitRef] = useVisibility(open);
  const [generateMealDetails,{isLoading:isMealDetailsGenerating}] = useRequest();
  const [filteredKitchenList, setFilterKitchenList] = useState(null);
  const [addressModel, setAddressModel] = useState(false)
  const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(true);
  const [toDetailsPage, setToDetailsPage] = useState(false);

  const earliestDelivery = useMemo(() => {
    let result = {};
    const deliveryDateTime = getEarliestTime(favoriteKitchenData?.schedule, "delivery");
    const momentEarliestDelivery = moment(deliveryDateTime);

    if (momentEarliestDelivery.isValid()) {
        result.stringDay = momentEarliestDelivery.format('dddd').toLowerCase();
        result.month = momentEarliestDelivery.format('MMMM');
        result.dayNumber = momentEarliestDelivery.date();
        result.year = momentEarliestDelivery.year();
    }

    return { dateTime: deliveryDateTime, ...result };
}, [favoriteKitchenData?.schedule]);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -88;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const getMealDetails = async () => {
    const path = `meal/list?mealId=${mealDetails?.id}&providerId=${mealDetails?.providerId}`;
    await generateMealDetails({ path })
      .then((res) => {
        setMealInfo(res?.data?.data[0]);
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(()=>{
    setToDetailsPage(false);
    updateSettings(
      produce((draft) => {
        draft.provider = {...favoriteKitchenData, id: favoriteKitchenData?.providerId};
      }),
    );
  },[]);

  const moveToMeals = () => {
    updateSettings(
      produce((draft) => {
        draft.provider = {...favoriteKitchenData, id: favoriteKitchenData?.providerId};
      }),
    );
    setToDetailsPage && setToDetailsPage(true);
    history.push(`/store/${provider?.username}`);
    window.scrollTo(0, 0);
  }

  const closeMealDetails = () => {
    setOpen(false);
    setToDetailsPage(true);
    moveToMeals();
    setMealInfo({});
  };

  const checkIfKitchenDelivering = (filteredKitchenList) => {
    if(favoriteKitchenData){
      const isFavoriteKitchenDelivered = filteredKitchenList.some(
        (kitchen) => kitchen?.businessProfileId === favoriteKitchenData?.businessProfileId
      );
      setIsFavoriteKitchenDelivered(isFavoriteKitchenDelivered);
      return isFavoriteKitchenDelivered;
    }
    setIsFavoriteKitchenDelivered(false)
    return filteredKitchenList.length > 0;
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
    setFavoriteKitchenMeals([]);
    setKitchenUserName();
  }, []);

  useEffect(() => {
    if (open) {
      getMealDetails();
    }
  }, [open]);

  useEffect(() => {
    setInitialQty(mealInCart?.qty);
    mealInCart ? setCount(mealInCart?.qty) : setCount(1);
  }, [open, mealInfo]);
  
  useEffect(() => {
    if((userInfo?.userAddressLatLong?.length > 0 || userInfo?.zipcode !== '') && kitchensList.length > 0){
      const result = filterKitchens(kitchensList, userInfo);
      const isKitchenDelivering = checkIfKitchenDelivering(result.filteredKitchenList);
      setFilterKitchenList(result.filteredKitchenList);
      const isBummerAlreadyShown = window.sessionStorage.getItem("isBummerModal");
      if(!result?.isDeliveryAvailable){
        if(favoriteKitchenData && !history.location?.state?.fromStore){
          isBummerAlreadyShown === "true" ? setShowBummer(false) : setShowBummer(!isKitchenDelivering);
        }else{
          setShowBummer(false);
        }
        setIsAnykitchenDeliveryAvailable(false);
        return;
      }
      setIsAnykitchenDeliveryAvailable(true);
      (favoriteKitchenData && !history.location?.state?.fromStore) && isBummerAlreadyShown === "true" ? setShowBummer(false) : setShowBummer(!isKitchenDelivering);
    }
    history.replace('/shop/kitchen-home', { fromStore: false });
  },[userInfo, favoriteKitchenData, kitchensList])

  const handleShowOtherKitchens = () => {
    const result = filterKitchens(kitchensList, userInfo);
    if(!result?.isDeliveryAvailable){
      setIsAnykitchenDeliveryAvailable(false);
    }
  }

  const onKitchenBannerImgClick = useCallback(() => {
    const { username = null } = favoriteKitchenData;
    if (username) {
      const url = `/store/${username}`
      history.push(url);
    }
  }, [favoriteKitchenData?.username]);

  if (isKitchenListGenerating || isCategorizedMealGenerating) {
    if (isMobile) {
      return <HomeSkeleton />;
    }
    return (
      <ProgressWrapper>
        <ProgressWrapper style={{ height: window.innerHeight - 312 }}>
          <CircularProgress
            style={{ color: '#000A2C', top: '45%', left: '45%', position: 'absolute' }}
          />
        </ProgressWrapper>
      </ProgressWrapper>
    );
  }

  return isMobile ? (
    <>
      <motion.div
        initial={{
          x: history.location?.state?.fromStore && !history.location?.state?.fromCart ? '-100%' : '0%', 
          opacity: history.location?.state?.fromStore ? 0 : 1 
        }}
        animate={{ opacity: 1, x: 0, y: 0 }}
        transition={{ duration:  history.location?.state?.fromStore || toDetailsPage ? 0.7 : 0, type: 'spring' }}
        exit={{ x: toDetailsPage ? '-100%' : 0 }}
      >
      <KitchenHomeWrapper>
        {!isAnyKitchenDeliveryAvailable ? <DeliveryNotAvailable /> : <>
        {(favoriteKitchenData?.businessProfileId && isFavoriteKitchenDelivered) && (
          <>
            <KitchenCard
              className="favorite-kitchen-card"
              cardImg={favoriteKitchenData?.storeImages[0] || '/images/sexy_just_eat_now.jpg'}
              label={favoriteKitchenData?.tag}
              providerImg={favoriteKitchenData?.image || '/images/green-plate.png'}
              title={favoriteKitchenData?.name}
              date={`${earliestDelivery.stringDay}, ${earliestDelivery.month} ${earliestDelivery.dayNumber}`}
              favorite
              onKitchenBannerImgClick={onKitchenBannerImgClick}
            />
              <>
                <HurryOrder
                  earliestDelivery={earliestDelivery}
                  favoriteKitchenData={favoriteKitchenData}
                  moveToMeals={moveToMeals}
                />
                <FavoriteKitchenCategories
                  storeCategoriesMeals={storeCategoriesMeals}
                  setOpen={setOpen}
                  setMealDetails={setMealDetails}
                  moveToMeals={moveToMeals}
                  isDisabled={isDeliveryAvailable}
                />
                <KitchenOurMission />
              </>
          </>
        )}
        {!!filteredKitchenList?.length && (
          <KitchensList kitchensList={filteredKitchenList} storeCategoriesMeals={storeCategoriesMeals} showKitchenList={!isFavoriteKitchenDelivered} setToDetailsPage={setToDetailsPage} />
          )}
          </>
        }
      </KitchenHomeWrapper>
      </motion.div>
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        scrollLocking={false}
        expandOnContentDrag={true}
        blocking={true}
        snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
        className="kitchenMealDetails-bottomSheet"
        header={
          beforeCheckoutSubmitShown ? (
            false
          ) : (
            <div className="close-sticky">
              <NavHashLink to={`/store/${provider?.username}#${mealInfo?.id}`} scroll={(el) => scrollWithOffset(el)}>
                <img src="/icons/close-black.svg" alt="Close Icon" onClick={closeMealDetails} />
              </NavHashLink>
            </div>
          )
        }
        footer={
          <div className="add-cart-wrapper">
            <QuantityButton
              count={count}
              variant="default"
              onClickRemove={() => {
                if (count === 1) return;
                setCount(count - 1);
              }}
              onClickAdd={() => {
                setCount(count + 1)
              }
              }
              className="meal-count"
              orderLimit={mealDetails?.orderLimit}
            />
            <NavHashLink
              to={`/store/${provider?.username}#${mealInfo?.id}`}
              scroll={(el) => scrollWithOffset(el)}
            >
              <Button
                title="Add to cart"
                type="dark"
                isDisabled={initialQty < 1}
                onClick={() => {
                  setOpen(false);
                  setToDetailsPage(true);
                  if(!mealInCart){
                    addToCart({...mealDetails, customQty: count})
                  } else {
                    updateMealQty(mealDetails, count); 
                  }
                  setMealInfo({});
                }}
              />
            </NavHashLink>
          </div>
        }
      >
        <KitchenMealDetails
          meal={mealDetails}
          mealInfo={mealInfo}
          setOpen={setOpen}
          open={open}
          beforeCheckoutSubmitRef={beforeCheckoutSubmitRef}
          moveToMeals={moveToMeals}
          setMealInfo={setMealInfo}
          isMealGenerating={isMealDetailsGenerating}
          countMeal={count}
        />
      </BottomSheet>
      {addressModel &&
       <BottomSheet
        open={addressModel}
        onDismiss={() => setAddressModel(false)}
        scrollLocking={false}
        expandOnContentDrag={true}
        snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
        className="addresses-bottomSheet"
      >
        <AddressesList open={addressModel} setOpen={setAddressModel} selectHeaderAddress={(address) => setHeaderAddress(address)}/>
      </BottomSheet> 
      }
      {
        showBummer && <BummerStyle>
        <Bummer className={'bummer__style'} providerName={provider?.name} setOpen={setAddressModel} filteredKitchenList={filteredKitchenList} onSecondaryClick={handleShowOtherKitchens}/>
        </BummerStyle>
      }
    </>
  ) : (
    <KitchenHomeDesktop />
  );
}
