import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  ${FlexCenter};

  .header-text {
    color: #00092d;
    font-family: ${() => theme.typography.fontGilroy};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .icon-wrapper {
    position: absolute;
    top: auto;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100px;
    ${FlexCenter};
    &.left{
      left: 16px;
      &:active, &:active::after{
        background: #F0F2F4;
    }  
    }
    &.close {
      left: 16px;
    }
    &.right {
      right: 16px;
    }
    &.close_right{
      right: 16px;
    }
  }

  .page-number {
    position: absolute;
    top: auto;
    right: 16px;
    color: ${() => theme.colors.blackDefault};
    font-family: ${() => theme.typography.fontInter};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }
`;
