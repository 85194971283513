import styled from 'styled-components';

const SoicalMediaImageStyle = styled.div`
  width: 34px;
  height: 30px;
  background: #f6f5f8;
  display: flex;
  align-items: center;

  img {
    width: 14px;
    height: 14px;
  }
`;

export default function SoicalMediaImage({ img }) {
  return (
    <SoicalMediaImageStyle>
      <img src={img} alt="soical Media" />
    </SoicalMediaImageStyle>
  );
}
