import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { featureCardImage, partnerKitchenItems } from './VisitorLandingData';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as AngleLeft } from 'icons/slider-arrow-left.svg';
import { ReactComponent as AngleRight } from 'icons/slider-arrow-right.svg';
import { useAppContext } from 'context/appContext';
import { theme } from 'shared/theme/theme';
import DownloadAppStore from '../../assets/download-app-store.png';
import GetOnGooglePlay from '../../assets/get-on-google-play.png';
import useProgressiveImage from 'hooks/useProgressiveImage';

const NutrittFeaturesWrapper = styled.div`
  overflow: hidden;
  padding-top: 28px;
  background-color: #fff;
  font-family: Gilroy;
  padding-top: 150px;
  @media (min-width: 768px) and (max-width: 1280px) {
    padding-top: 75px;
  }
  .heading-title {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  h2 {
    color: #757e89;
    text-align: center;
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 16px;
  }
  .sub-text {
    color: #283241;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .unit-now {
    color: #49a332;
  }

  @media(min-width: 1025px) and (max-width: 1280px) {
    .heading-title{
        h2{
            letter-spacing: 12px;
            font-size: 24px;
        }
        .sub-text{
            font-size: 48px;
            letter-spacing: normal;
        }
    }
}

@media(min-width: 992px) and (max-width: 1024px) {
    .heading-title{
        h2{
            letter-spacing: 10px;
            font-size: 20px;
        }
        .sub-text{
            letter-spacing: normal;
            font-size: 48px;
        }
    }

}

@media(min-width: 768px) and (max-width: 1255px) {
    .container .slider{
        width: 276px !important;
        height: 64px !important;
        .slick-arrow{
            top: 26%;
        }
        .slick-next{
            left: 272px !important;
        }
    }
}
  @media (max-width: 767px) {
    padding-top: 33px;
    .heading-title {
      margin: 0 24px;
      padding-bottom: 46px;
      border-bottom: 1px solid #f0f2f4;
    }
    h2 {
      font-size: 16px;
      letter-spacing: 8px;
    }
    .sub-text {
      font-size: 26px;
      line-height: normal;
      letter-spacing: normal;
    }
  }
`;
const FeatureCardWrapper = styled.div`
  .container{
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;    
    padding-top: 250px;
    padding-left: 132px;
    .isDisplayImage {
        opacity: 1 !important;
      }
    
    .flex{
        display: flex;
        gap: 24px;
        flex-direction: column;    
    }
    .feature-text{
        width: 50%;
        transform: translateY(-95px);
        h2{
            color: #283241;
            text-align: left;
            font-family: Gilroy;
            font-size: 96px;
            font-style: normal;
            font-weight: 500;
            line-height: 100px;
            letter-spacing: 0px;
            @media (min-width: 1261px) and (max-width: 1399px) {
              font-size: 64px;
              line-height: 70px;
                }    
            @media (min-width: 768px) and (max-width: 1261px){
                font-size: 60px;
                line-height: 64px;
              }  
        }
        .green {
          color: #49a332;
          font-size: 32px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 16px;
          @media (min-width: 1281px) and (max-width: 1920px){
            font-size: 32px;
            letter-spacing: 16px;
          }
          @media (min-width: 1261px) and (max-width: 1280px) {
            font-size: 24px;
            line-height: 12px;
          }    
          @media (min-width: 1025px) and (max-width: 1260px) {
            font-size: 28px;
            letter-spacing: 11px;
          }  
          @media (min-width: 768px) and (max-width: 1024px){
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 10px;
          }
        }
          .text{
            color: #757E89;
            font-size: 24px;
            font-weight: 400;
            line-height: 42px;
            @media (min-width: 1024px) and (max-width: 1280px){
                font-size: 20px;
                line-height: 38px;
            }
            @media (min-width: 768px) and (max-width: 1024px){
              font-size: 18px;
              line-height: 34px;
            }    
        }
        text{
            color: var(--Local-green, #49A332);
            font-family: Gilroy;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            line-height: 42px;
            text-decoration-line: underline;
            @media (min-width: 768px) and (max-width: 1024px) {
            position: absolute;
            z-index: 999;    
            }    
        }
    }
    .slider{
        width: 491px;
        margin: 32px 40px;
        .slick-arrow {
            position: absolute;
            left: -57px;
            z-index: 3;
            width: 64px;
            height: 64px;
            border-radius: 50px;
            display: flex !important;
            align-items: center;
            justify-content: center;
      
            &:before {
              display: none;
            }
                      }
      
          .slick-next {
            left: 477px;
            top: 36px;
         }
        }
      
        img{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            padding: 0 5px;
            text-align: center;
        }
    }
    
    .feature-image{
      width: 100%;
      background-image: url(/images/Ellipse.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      .mobile__main__image{
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        max-width: 500px;
        width: 100%;
        @media (min-width: 768px) and (max-width: 1050px){
          max-width: 300px;
        }
        @media (min-width: 1051px) and (max-width: 1500px){
          max-width: 400px;
        }
        .feature-image-4{
          position: relative;
          z-index: 2;
          width: 100%;  
          opacity: 0;
          transform: translateY(100%);
          transition: opacity 1s, transform 1s;       
        }
      }
      img {
        position: absolute;
        display: block;
      }
      .feature-image-3{
        left: -177px;
        bottom: 1px;
        max-width: 340px;    
        width: 100%;
        z-index: 3;
      }
      .feature-image-1{
        left: -143px;
        top: 76px;
        z-index: 1;
        max-width: 222px;
        width: 100%;
      }
      .feature-image-2{
        right: -181px;
        top: 55%;
        max-width: 353px;    
        transform: translateY(-50%);
        z-index: 1;
        max-width: 312px;
        width: 100%;
      }
    }
  }
}
@media (max-width: 767px) {
.container{
    padding: 64px 0 0 24px;
    height: 835px;
    flex-direction: column;
    .feature-text{
        width: 100%;
        transform: translateY(0) !important;
        h2{
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 55px;
            letter-spacing: 0px;
        }
        .green{
            line-height: normal;
            letter-spacing: 8px;
            font-size: 16px;
        }
        .text{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: normal;
        }
        text{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 42px;
        }
    }
    .slider{
        margin: 16px 0 16px 40px;
        width: 80%;
        .slick-next{
            left: 98%;
            top: 33px;        
        }
    }
    .feature-image{
        height: 566px;
        background-image: url(/images/feature-background-mobile.jpg);
        .feature-image-1 {
            display: none;
      }
    
        .feature-image-2 {
            width: 122px;
            height: 134px;
            right: 0;
            top: 43%;
       }

        .feature-image-3{
            width: 155px;
            height: 133.61px;
            left: -22px;
            bottom: -6px;

        }

        .feature-image-4{
            width: 219px;
       }
    }
}
}
@media (min-width: 1255px) and (max-width: 1920px){

    .container{
        .slider{
            width: 441px;
            .slick-next{
                left: 434px;
            }
        }  
    } 
}

@media only screen and (max-width: 1708px) {
    .container{
        padding-left: 32px;
        .feature-text{
            width: 85%;
            transform: translateY(-100px);
        }
    }
}
.animate-in {
    opacity: 1 !important;
    transform: translateY(0) !Important;
  }
    
`;
const FeatureCardWrapper1 = styled.div`
  .container {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row-reverse;
    padding: 194px 0;
    margin-top: 89px;
    background-image: url(/images/feature-background-2.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    @media (min-width: 1280px) and (max-width: 1650px){
        font-size: 110px;
        line-height: 104px;
        margin-top: 0px;
        padding: 178px 0;
      }
      @media (min-width: 768px) and (max-width: 1279px){
        margin-top: 0px;
        padding: 150px 0;
      }
    .isDisplayImage {
      opacity: 1 !important;
    }

    .flex {
      display: flex;
      gap: 24px;
      flex-direction: column;
    }
    .feature-text {
        margin-right: 134px;
        @media only screen and (max-width: 1708px) {
            margin-right: 34px;
            width: 42%;
        }        
    h2 {
        color: #283241;
        text-align: left;
        font-family: Gilroy;
        font-size: 96px;
        font-style: normal;
        font-weight: 500;
        line-height: 100px;
        letter-spacing: 0px;
        @media (min-width: 1261px) and (max-width: 1399px) {
          font-size: 64px;
          line-height: 70px;
        }    
        @media (min-width: 768px) and (max-width: 1261px){
          font-size: 60px;
              line-height: 64px;
         }  
    }
      .green {
        color: #49a332;
        font-size: 32px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 16px;
        @media (min-width: 1281px) and (max-width: 1920px){
          font-size: 32px;
          letter-spacing: 16px;
        }
        @media (min-width: 1261px) and (max-width: 1280px) {
          font-size: 24px;
          line-height: 12px;
        }    
        @media (min-width: 1025px) and (max-width: 1260px) {
          font-size: 28px;
          letter-spacing: 11px;
        }  
      @media (min-width: 768px) and (max-width: 1024px){
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 10px;
        }
      }
      .text {
        color: #757e89;
        font-size: 24px;
        font-weight: 400;
        line-height: 42px;
        @media (min-width: 1024px) and (max-width: 1280px){
            font-size: 20px;
            line-height: 38px;
        }
        @media (min-width: 768px) and (max-width: 1024px){
          font-size: 18px;
          line-height: 34px;
        }
      }
      text {
        color: var(--Local-green, #49a332);
        font-family: Gilroy;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px; /* 175% */
        text-decoration-line: underline;
      }
    }

    .feature-image {
      width: 60%; // here change
      position: relative;

      img {
        position: absolute;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        left: 53%;
        bottom: 60%;
      }
    }
  }
  @media (max-width: 767px) {
    .container {
        padding: 96px 27px 0 24px;
        margin: 0;
        background-image: none;
        height: 795px;
      flex-direction: column;
      .feature-text {
        width: 100%;
        h2 {
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 55px;
        }
        .green {
          line-height: normal;
          letter-spacing: 8px;
          font-size: 16px;
        }
        .text {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: normal;
        }
      }
      .feature-image {
        height: 566px;
        background-image: none;
        img{
            opacity: 1;
            left: -12%;
            min-width: 433px;
            bottom: 0;        
        }
      }
    }
  }
  @media(min-width: 1025px) and (max-width: 1280px) {
    .heading-title{
        h2{
            letter-spacing: 12px;
            font-size: 24px;
        }
        .sub-text{
            font-size: 48px;
            letter-spacing: normal;
        }
    }
}

@media(min-width: 992px) and (max-width: 1024px) {
    .heading-title{
        h2{
            letter-spacing: 10px;
            font-size: 20px;
        }
        .sub-text{
            letter-spacing: normal;
            font-size: 48px;
        }
    }

}

`;

const FeatureCardWrapper2 = styled.div`
  .container {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    .isDisplayImage {
      opacity: 1 !important;
    }

    .flex {
      display: flex;
      gap: 24px;
      flex-direction: column;
    }
    .download_btns {
      display: flex;
      margin-top: 32px;
      gap: 32px;
      position: relative;
      z-index: 2;
      @media (max-width: 1280px){
        gap: 14px;
        .app_store {
            img {
              width: 112px !important;
              height: 32px;
              display: block;
            }
          }
          .google_play {
            img {
              width: 112px !important;
              height: 32px;
              display: block;
            }
          }
    
      }
      .app_store {
        img {
          width: 100%;
          display: block;
        }
      }
      .google_play {
        img {
          width: 100%;
          display: block;
        }
      }
    }

    .feature-text {
      width: 50%;
      h2 {
        color: #283241;
        text-align: left;
        font-family: Gilroy;
        font-size: 96px;
        font-style: normal;
        font-weight: 500;
        line-height: 100px;
        letter-spacing: 0px;
        @media (min-width: 1261px) and (max-width: 1399px) {
          font-size: 64px;
          line-height: 70px;
        }    
        @media (min-width: 768px) and (max-width: 1261px){
          font-size: 60px;
              line-height: 64px;
            }  
    }
    .green {
      color: #49a332;
      font-size: 32px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 16px;
      @media (min-width: 1281px) and (max-width: 1920px){
        font-size: 32px;
        letter-spacing: 16px;
      }
      @media (min-width: 1261px) and (max-width: 1280px) {
        font-size: 24px;
        line-height: 12px;
      }    
      @media (min-width: 1025px) and (max-width: 1260px) {
        font-size: 28px;
        letter-spacing: 11px;
      }  
      @media (min-width: 768px) and (max-width: 1024px){
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 10px;
      }
    }
    .text {
        color: #757e89;
        font-size: 24px;
        font-weight: 400;
        line-height: 42px;
        @media (min-width: 1024px) and (max-width: 1280px){
            font-size: 20px;
            line-height: 38px;
        }
        @media (min-width: 767px) and (max-width: 1024px){
          font-size: 18px;
          line-height: 34px;
        }
      }
      text {
        color: var(--Local-green, #49a332);
        font-family: Gilroy;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px; /* 175% */
        text-decoration-line: underline;
      }
    }

    .feature-image {
      width: 50%;
      position: relative;

      img {
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        width: 100%;
        position: absolute;
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      padding: 64px 27px 0 24px;
      height: 625px;
      flex-direction: column;
      .feature-text {
        width: 100% !important;
        transform: translateY(0px) !important;
        h2 {
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 55px;
        }
        .green {
          line-height: normal;
          letter-spacing: 8px;
          font-size: 16px;
        }
        .text {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: normal;
        }
        .download_btns{
            display: none;
        }
        text {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 42px;
        }
      }
      .feature-image {
        width: 119%;
        background-image: none;
        img{
            position: absolute;
            left: -8%;    
            top: 30px;
        }
      }
    }
  }
  @media (min-width: 768px){
    .container{
      justify-content: flex-end;
      .feature-text{
        position: absolute;
        max-width: 724px;
        left: 130px;
        z-index: 3;
        top: 50%;
        transform: translateY(-50%);
      }
      .feature-image {
        flex: 0 0 60%;
        img {
          position: relative;
          display: block;
        }
      }
    }
  }
  @media (min-width: 767px) and (max-width: 1708px) {
    .container{
        padding-left: 32px;
        .feature-text{
            width: 82%;
            left: 40px;
            transform: translateY(-227px);
        }
    }
}

`;

function SampleNextArrow(props) {
    const { className, style, onClick, autoplay } = props;
  
    const handleClick = () => {
      onClick();
      if (autoplay) {
        autoplay('forward');
      }
    };
  
    return (
      <div className={className} style={{ ...style }} onClick={handleClick}>
        <AngleRight />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick, autoplay } = props;
  
    const handleClick = () => {
      onClick();
      if (autoplay) {
        autoplay('backward');
      }
    };
  
    return (
      <div className={className} style={{ ...style }} onClick={handleClick}>
        <AngleLeft />
      </div>
    );
  }
  export const Welcome = () => {
  const [isDisplayImage, setIsDisplayImage] = useState(false);
  const { isMobile } = useAppContext();
  const imgRef = useRef();
  const [src, { isImageLoaded }] = useProgressiveImage("/images/feature-image-4.png", imgRef);
  useEffect(() => {
    setIsDisplayImage(true);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <NutrittFeaturesWrapper>
      <div className="heading-title">
        <h2>WELCOME</h2>
        <h2 className="sub-text">
          {isMobile ? 'healthy isn’t easy.' : 'healthy isn’t easy. '}
          {isMobile && <br />}
          <span className="unit-now">until now.</span>
        </h2>
      </div>
      <FeatureCardWrapper>
        <div className="container flex">
          <div className="feature-text">
            <div className="flex">
              <h2 className="green">LOCAL</h2>
              <h2>
                know where <br />
                it’s from.
              </h2>
              <h2 className="text">
                Choose from our partner kitchens, <br /> preparing locally sourced meals, <br />
                delivered right to your doorstep.
              </h2>
            </div>
            <div className="slider">
              <Slider {...settings}>
                {partnerKitchenItems.map((item) => (
                  <img key={item?.id} src={`/images/${item?.image}.png`} alt="DietPlan image" loading='lazy'/>
                ))}
              </Slider>
            </div>
            <text   onClick={() => document.getElementById('topOfPage').scrollIntoView({ behavior: 'smooth' })}>See our partner kitchens</text>
          </div>
          <div className="feature-image" ref={imgRef}>
            <div className='mobile__main__image' >
            <img
                src={src}
                alt="DietPlan image"
                  className={`feature-image-4 ${isImageLoaded ? 'animate-in': 'animate-out'}`} 
                    />
              {featureCardImage.map((img, index) => {
                return <img
                  key={index}
                  src={`/images/${img}.png`}
                  alt="DietPlan image"
                  className={classNames(img, { isDisplayImage })}
                  loading='lazy'
                />
              })}
            </div>
          </div>
        </div>
      </FeatureCardWrapper>
      <FeatureCardWrapper1>
        <div className="container flex">
          <div className="feature-text">
            <div className="flex">
              <h2 className="green">TRANSPARENT</h2>
              <h2>
                know what <br /> your eating.
              </h2>
              {isMobile || (window.innerWidth < 1280 ) ? 
              <h2 className="text">
                Tailor your plans based on nutritional <br /> and habitual goals, and receive <br />
                suggestions to achieve them.
              </h2>
              :
              <h2 className="text">
              Tailor your plans based on nutritional and habitual <br /> goals, and receive
              suggestions to achieve them.
            </h2>
              }
            </div>
          </div>
          <div className="feature-image">
            {isMobile && <img src='/images/background-feature-image2.png' loading='lazy' />}
          </div>
        </div>
      </FeatureCardWrapper1>
      <FeatureCardWrapper2>
        <div className="container flex">
          <div className="feature-text">
            <div className="flex">
              <h2 className="green">RESULTS</h2>
              <h2>know it’s for you.</h2>
              {isMobile ? 
              <h2 className="text">
                Tailor your Nuttrit experience around <br /> your habits, schedule and goals. See <br />
                your measurable results.
              </h2>
              :
              <h2 className="text">
              Tailor your Nuttrit experience around your habits, <br /> schedule and goals. See
              your measurable results.
            </h2>
              }
            </div>
            <div className="download_btns">
              <div className="google_play">
                <a href="https://play.google.com/store/apps/details?id=com.nutritt" target="_blank">
                  <img src={GetOnGooglePlay} className="img" loading='lazy'/>
                </a>
              </div>
              <div className="app_store">
                <a href="https://apps.apple.com/us/app/nutritt/id1605156638" target="_blank">
                  <img src={DownloadAppStore} className="img" loading='lazy'/>
                </a>
              </div>
            </div>
          </div>
          <div className="feature-image">
            <img
              src={`/images/feature-image-5.jpg`} 
              alt="DietPlan image"
              className={classNames({ isDisplayImage })}
              loading='lazy'
            />
          </div>
        </div>
      </FeatureCardWrapper2>
    </NutrittFeaturesWrapper>
  );
};
