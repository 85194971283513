import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { theme } from 'shared/theme/theme';
import Peanuts from 'components/svg/Peanuts';
import CerealGluten from 'components/svg/CerealGluten';
import Eggs from 'components/svg/Eggs';
import Soy from 'components/svg/Soy';
import Crustaceans from 'components/svg/Crustaceans';
import Mollusc from 'components/svg/Mollusc';
import Sesame from 'components/svg/Sesame';
import SulphurDioxide from 'components/svg/SulphurDioxide';
import Fish from 'components/svg/Fish';
import Mustard from 'components/svg/Mustard';
import Celery from 'components/svg/Celery';
import TreeNuts from 'components/svg/TreeNuts';
import Lupin from 'components/svg/Lupin';
import Milk from 'components/svg/Milk';
import MobileHeader from 'components/elements/MobileHeader';
import { CheckboxCard } from 'components/elements/checkboxCard/CheckboxCard';
import { Button } from 'components/elements/button/Button';
import { GilroyFontWrapper } from 'shared/theme/style';
import { motion } from 'framer-motion';
import MultiScreenStyle from '../healthGoal/MultiScreenStyle';
import { useShopContext } from 'context/shopContext';
import arraysAreEqual from 'utils/equalArrays';
import { useAppContext } from 'context/appContext';

const AllergiesStyle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.white};
  .containerr {
    padding: 24px;
    padding-bottom: 115px;
    height: ${({window}) => window?.innerHeight - 48}px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item_list {
      margin: 6px 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .save__btn {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    z-index: 150;
  }
`;

const Allergies = ({ setActiveModalStack, modal }) => {
  const { saveHealthGoal, healthGoal, setUpdatedHealthGoal, saveDietPreferences } = useShopContext();
  const [selectedAllergies, setSelectedAllergies] = useState(
    healthGoal?.selfMealPlan?.allergies || [],
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const { isVisitor } = useAppContext();
 
  let isSaveDisabled = arraysAreEqual(healthGoal?.selfMealPlan?.allergies, selectedAllergies) || isDisabled;

  const allergens = [
    {
      id: 0,
      title: 'Peanut',
      icon: <Peanuts color={theme?.colors?.grayDarker} />,
      activeIcon: <Peanuts color={'#51635D'} />,
      value: 'PEANUT'
    },
    {
      id: 1,
      title: 'Cereal/Gluten',
      icon: <CerealGluten color={theme?.colors?.grayDarker} />,
      activeIcon: <CerealGluten color={'#51635D'} />,
      value: 'CEREAL/GLUTEN'
    },
    {
      id: 2,
      title: 'Egg',
      icon: <Eggs color={theme?.colors?.grayDarker} />,
      activeIcon: <Eggs color={'#51635D'} />,
      value: 'EGG'
    },
    {
      id: 3,
      title: 'Soy',
      icon: <Soy color={theme?.colors?.grayDarker} />,
      activeIcon: <Soy color={'#51635D'} />,
      value: 'SOY'
    },
    {
      id: 4,
      title: 'Crustacean',
      icon: <Crustaceans color={theme?.colors?.grayDarker} />,
      activeIcon: <Crustaceans color={'#51635D'} />,
      value: 'CRUSTACEAN'
    },
    {
      id: 5,
      title: 'Mollusk',
      icon: <Mollusc color={theme?.colors?.grayDarker} />,
      activeIcon: <Mollusc color={'#51635D'} />,
      value: 'MOLLUSk'
    },
    {
      id: 6,
      title: 'Sesame',
      icon: <Sesame color={theme?.colors?.grayDarker} />,
      activeIcon: <Sesame color={'#51635D'} />,
      value: 'SESAME'
    },
    {
      id: 7,
      title: 'Fish',
      icon: <Fish color={theme?.colors?.grayDarker} />,
      activeIcon: <Fish color={'#51635D'} />,
      value: 'FISH'
    },
    {
      id: 8,
      title: 'Mustard',
      icon: <Mustard color={theme?.colors?.grayDarker} />,
      activeIcon: <Mustard color={'#51635D'} />,
      value: 'MUSTARD'
    },
    {
      id: 9,
      title: 'Celery',
      icon: <Celery color={theme?.colors?.grayDarker} />,
      activeIcon: <Celery color={'#51635D'} />,
      value: 'CELERY'
    },
    {
      id: 10,
      title: 'Tree Nut',
      icon: <TreeNuts color={theme?.colors?.grayDarker} />,
      activeIcon: <TreeNuts color={'#51635D'} />,
      value: 'TREE_NUT'
    },
    {
      id: 11,
      title: 'Lupine',
      icon: <Lupin color={theme?.colors?.grayDarker} />,
      activeIcon: <Lupin color={'#51635D'} />,
      value: 'LUPINE'
    },
    {
      id: 12,
      title: 'Milk',
      icon: <Milk color={theme?.colors?.grayDarker} />,
      activeIcon: <Milk color={'#51635D'} />,
      value: 'MILK'
    },
  ];

  useEffect(() => {
    setUpdatedHealthGoal((prev) => ({
      ...prev,
      selfMealPlan: { ...prev.selfMealPlan, allergies: selectedAllergies?.map((item) => item?.toUpperCase())},
    }));
  }, [selectedAllergies]);

  const handleSave = (e) => {
    // Save & close the modal & prevent reclick using prevent default
    saveDietPreferences();
    e.preventDefault();
    setActiveModalStack([]);
  };

  return (
    <>
      <motion.div
        initial={{ x: '100%', opacity: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ x: '100%', opacity: 0 }}
        transition={{ duration: 0.7, type: 'spring' }}
      >
        <GilroyFontWrapper>
          <MultiScreenStyle>
            <AllergiesStyle theme={theme} window={window}>
              <MobileHeader onClickLeft={() => setActiveModalStack([])} text={'Allergies'} />
              <div className="containerr">
                {allergens?.map((item, index) => (
                  <div key={index} className="item_list">
                    <CheckboxCard
                      type={'title_only'}
                      title={item?.title}
                      icon={item?.icon}
                      activeIcon={item?.activeIcon}
                      value={item?.value.toUpperCase()}
                      checked={selectedAllergies?.includes(item?.value.toUpperCase())}
                      selectedValue={selectedAllergies}
                      setSelectedValue={setSelectedAllergies}
                      onClick={() => setIsDisabled(false)}
                    />
                  </div>
                ))}
              </div>
              <div className="button_holder">
                <Button
                  type={'mobile_save'}
                  title={'Save'}
                  onClick={handleSave}
                  isDisabled={isSaveDisabled}
                />
              </div>
            </AllergiesStyle>
          </MultiScreenStyle>
        </GilroyFontWrapper>
      </motion.div>
    </>
  );
};

export default Allergies;
