import React, { useState, useEffect } from 'react';
import { useShopContext } from 'context/shopContext';
import { useVisitorContext } from 'context/visitorContext';
import { useAppContext } from 'context/appContext';
import { filterKitchens } from 'utils';
import Bummer from 'components/shop/FavoriteKitchen/Bummer';
import styled from 'styled-components';
import KitchensList from 'components/shop/KitchenHome/KitchensList';
import Progress from 'components/Progress';
import { useHistory, useLocation } from 'react-router-dom';
import FooterDesktop from 'components/shop/FooterDesktop';
import { useParams } from 'react-router-dom';
import KitchenOurMission from 'components/shop/KitchenHomeDesktop/KitchenOurMission';

const BummerStyle = styled.div`
  display: flex;
  padding: 120px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top: 1px solid #e0e3e0;
  .bummer__style {
    position: relative;
    z-index: 2;
    .image-container {
      margin-top: 0;
    }
  }
  .join__list {
    width: 424px;
    .desc {
      width: 100%;
    }
  }
  button {
    cursor: pointer;
  }
`;

const CheckDeliveryAvailable = ({ children, isHomepage = true, showAvailableOnly = false }) => {
  const {
    user,
    currentDeliveryAddress,
    profile = {},
    setChangeAddress,
    isDesktop,
    isShoppingUser,
    isDeliveryLoading,
    setIsKitchenDeliveryAvailable,
    countStoreVisit
  } = useAppContext();
  const { favoriteKitchenData = {} } = profile || {};
  const { storeCategoriesMeals, kitchensList, setStoreUrl = () => {}, isKitchenNotFound, settings: {provider} } = (user && isShoppingUser) ? useShopContext() : useVisitorContext();
  const [isCurrentKitchenDeliveryAvailable, setIsCurrentKitchenDeliveryAvailable] = useState(true);
  const [isAnyKitchenDeliveryAvailable, setIsAnykitchenDeliveryAvailable] = useState(true);
  const [isJoinWaitingList, setIsJoinWaitingList] = useState(false);
  const [showDeliveringKitchensList, setShowDeliveringKitchensList] = useState(false);
  const [filteredKitchens, setFilteredKitchens] = useState([]);
  const [resultAvailable, setResultAvailable] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [currentKitchenName, setCurrentKitchenName] = useState('');
  const [selectedProviderId, setSelectedProviderId] = useState(provider?.id);
  let { providerId } = useParams();
  const localStorageSettings = JSON.parse(localStorage.getItem('settings'));
  const currentProvider = localStorageSettings?.provider;
  const isUnlistedKitchen = currentProvider && currentProvider.hasOwnProperty('approved') && !currentProvider?.approved && !isHomepage;

  useEffect(() => {
    setStoreUrl(providerId);
    if(providerId){
      countStoreVisit(providerId);
    }
    if(kitchensList?.length){
      const foundKitchen = kitchensList?.find(kitchen => kitchen?.username === providerId);
      setSelectedProviderId(foundKitchen?.id);
    }
  }, [kitchensList, providerId])

  const checkIfKitchenDelivering = (filteredKitchenList, selectedProviderId) => {
    return !!filteredKitchenList.find(
      (kitchen) => {
        if(kitchen.businessProfileId === favoriteKitchenData.businessProfileId && !providerId){
          setSelectedProviderId(kitchen?.id);
        }
        if(providerId){
          return kitchen?.id === selectedProviderId;
        }
        return kitchen.businessProfileId === favoriteKitchenData.businessProfileId || kitchen?.id === selectedProviderId;
      }
    );
  };

  useEffect(() => {
    if(kitchensList?.length) {
      kitchensList?.find((kitchen) => {
        if(Number(kitchen?.id) === Number(selectedProviderId)){
          setCurrentKitchenName(kitchen?.name);
        }
      })
    }
    return () => setCurrentKitchenName('');
  }, [selectedProviderId,kitchensList])

  const handleDiscoverAnotherKitchen = () => {
    if (!isAnyKitchenDeliveryAvailable) {
      setIsJoinWaitingList(true);
    } else {
      if (user) {
        history.push('/shop/kitchen-home');
      } else {
        history.push('/visitor/kitchens')
      }
      setShowDeliveringKitchensList(true);
    }
  };

  useEffect(() => {
    if(user && isShoppingUser && !isUnlistedKitchen){
      setIsKitchenDeliveryAvailable(isCurrentKitchenDeliveryAvailable);
    }else{
      setIsKitchenDeliveryAvailable(true);
    }
  }, [isCurrentKitchenDeliveryAvailable])

  const renderKitchenNotAvailable = (currentKitchenName) => {
    return (
      <>
      <BummerStyle>
        <Bummer
          showCross={false}
          providerName={currentKitchenName ? currentKitchenName : favoriteKitchenData?.name}
          onSecondaryClick={handleDiscoverAnotherKitchen}
          className="bummer__style"
          onPrimaryClick={() => setChangeAddress(true)}
          removeBodyScroll={false}
        />
      </BummerStyle>
      {!location.pathname.includes('kitchen-home') && !location.pathname.includes('visitor/kitchens') &&
      <FooterDesktop />}
      </>
    );
  };

  const renderJoinWaitingList = () => {
    return (
      <>
      <BummerStyle>
        <Bummer
          showCross={false}
          showButtons={false}
          title={`Sorry! We’re not there yet 😞`}
          subTitle={`We're working hard to expand our area. <br />However, we're not in this location yet. So sorry about this! <br /> Check back with us soon`}
          className="bummer__style join__list"
          removeBodyScroll={false}
        />
      </BummerStyle>
      {!location.pathname.includes('kitchen-home') && !location.pathname.includes('visitor/kitchens') &&
      <FooterDesktop />}
      </>
    );
  };

  const renderKitchensList = () => {
    return (
      <div style={{ backgroundColor: '#fff', paddingBottom: '10px', paddingTop: favoriteKitchenData && !showDeliveringKitchensList ? '0px' : '13px' }}>
      <KitchensList
        kitchensList={filteredKitchens}
        storeCategoriesMeals={storeCategoriesMeals}
        favoriteKitchenData={favoriteKitchenData}
        showKitchenList={true}
        showDeliveringKitchensList={showDeliveringKitchensList}
      />
      <KitchenOurMission />
      </div>
    );
  };

  useEffect(() => {
    setIsJoinWaitingList(false);
    setShowDeliveringKitchensList(false);
    if (kitchensList?.length) {
      const result = filterKitchens(kitchensList, currentDeliveryAddress);
      setIsAnykitchenDeliveryAvailable(result.isDeliveryAvailable);
      setFilteredKitchens(result?.filteredKitchenList);
      if (favoriteKitchenData && Object?.keys(favoriteKitchenData).length > 0 || selectedProviderId) {
        const isKitchenDelivering = checkIfKitchenDelivering(result.filteredKitchenList, selectedProviderId);
        setIsCurrentKitchenDeliveryAvailable(isKitchenDelivering);
      }else{
        setIsCurrentKitchenDeliveryAvailable(false);
        if(result.isDeliveryAvailable){
          setShowDeliveringKitchensList(true);
        }
      }
      setTimeout(() => {
        setResultAvailable(true);
      }, 200)
    }
  }, [kitchensList, currentDeliveryAddress, selectedProviderId]);

  if(!isDesktop || (!Object.keys(currentDeliveryAddress).length && !isHomepage) || isUnlistedKitchen){
    // if mobile || provider not approved || no delivery address and isNotHomepage then return the children.
    return children;
  }

  if(!resultAvailable || (!selectedProviderId && !isHomepage)){
    // show progress if result is not available or selected provider id is not available at the kitchen details page.
    return <div style={{ backgroundColor: '#fff', width: '100vw', height: 'calc(100vh - 76px)' }}>
      <Progress />
    </div>
  }

  if(isKitchenNotFound){
    return (<>
      <BummerStyle>
        <Bummer 
          showCross={false}
          showButtons={false}
          title={`Kitchen not found 😞`}
          subTitle={`Uh-oh! It seems like the URL you entered couldn't locate a kitchen. Please ensure you're on the right kitchen URL.`}
          className="bummer__style"
          removeBodyScroll={false}
        />
      </BummerStyle>
      <FooterDesktop />
    </>)
  }

  if(showDeliveringKitchensList && isHomepage){
    return renderKitchensList();
  }

  if(isJoinWaitingList || (isHomepage && !isAnyKitchenDeliveryAvailable)){
    return renderJoinWaitingList();
  }

  if(!isCurrentKitchenDeliveryAvailable && resultAvailable){
    if((favoriteKitchenData && Object.keys(favoriteKitchenData).length > 0) || !isHomepage){
      return renderKitchenNotAvailable(currentKitchenName)
    }
  }

  return children;
};

export default CheckDeliveryAvailable;
