import ChatScreen from "components/chat/ChatScreen"
import { useAppContext } from 'context/appContext';
import PantryFooter from "components/Pantry/PantryFooter";
import styled from 'styled-components';
import { useState, useEffect } from "react";
import { useChatContext } from "context/chatContext";

const ChatScreenHolder = styled.div`
    height: calc(100% - 70px);
    position: fixed;
    top: 0;
    z-index: 121;
    width: 100%;
    transform: translateY(0);
    background-color: #fff; 
`
const ChatWithCoachStyle = styled.div`

    .jddEjj{
        z-index: 122;
    }
`
export default function ChatWithCoach() {

    const { nutritionist, user, twilioClient, isMobile, twilioConnectionState } = useAppContext();
    const [showPantryFooter, setShowPantryFooter] = useState(true);
    const {activateConversation, reActivateConversation} = useChatContext();

    let updatedStyle;

    showPantryFooter && isMobile ? updatedStyle = {
        height: 'calc(100% - 70px)',
        transition: 'none',
    } : updatedStyle = {
        height: '100%',
        transition: 'all 0.2s',
    }

    useEffect(() => {
      document.body.classList.add("hide-scroll");
      return () => {
        document.body.classList.remove("hide-scroll");
      }
    }, []);

  useEffect(()=>{
    console.log("twilioConnectionState", twilioConnectionState);
    if(twilioClient && twilioConnectionState == 'connected'){
    const env = process.env.REACT_APP_ENV;
    const roomName = `nutritt_${env}_${user?.id}_${nutritionist?.userId}`;
    console.log("roomName...User....", roomName);
    activateConversation(roomName, nutritionist?.userId);   
    }
  },[twilioClient, twilioConnectionState]);
    
    return (
        <>
            <ChatWithCoachStyle>
                <ChatScreenHolder style={updatedStyle}>
                    <ChatScreen user={user} nutritionist={nutritionist} setShowPantryFooter={setShowPantryFooter}/>
                </ChatScreenHolder>
                <PantryFooter showPantryFooter={showPantryFooter} shop={false} myCoach={false} progress={false} coachChat={true} />
            </ChatWithCoachStyle>
        </>
    )
}