import React from 'react'
import styled from 'styled-components'
import { theme } from 'shared/theme/theme'
import { ReactComponent as Info } from 'assets/info-blue.svg';
import { ReactComponent as Error } from 'assets/error-red.svg';
import { ReactComponent as Close } from 'assets/close-gray.svg';
import { ReactComponent as Success } from 'assets/success-green.svg';
import { motion } from 'framer-motion';


const ModalStyle = styled.div`
    border-radius: 4px;
    background: ${({ theme, type }) =>
        type === "success"
            ? theme.colors.lightSuccess
            : type === "error"
                ? theme.colors.lightDanger
                : type === "info"
                    ? theme.colors.lightBlue
                    : theme.colors.white};
    padding: 10px 8px 10px 8px;
    border: 1px solid ${({type}) =>
    type === 'error' ? "#F03738" :
    type === 'info' ? '#3E66FB' :
    type === 'success' ? "#3CC13B" : ''};
      display: flex;
    justify-content: center;
    row-gap: 16px;
    flex-direction: column;
    position: relative;
    z-index:200;
    max-width: ${({ width }) => width || '300px'};
    height: 40px;
    margin: auto;
    .alert_header{
        display: flex;
        align-items: center;
        flex-direction: row;
        .icon{
            > * {
                width: 34px;
                height: 34px;        
            }
            svg {
                rect {
                    display: ${({type}) => type === 'error' ? 'block' : 'none'};
                }
            }
            }
        .title{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            font-family: ${({ theme }) => theme.typography.fontInter};
            color: ${({ theme }) => theme.colors.black};
            flex: 1;
            text-align:left;
        }
        .cross{
            cursor: pointer;    
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);        
        }
    }
`

function Alert({ isOpen, type = 'success', title = 'Modal Title', onClose, width }) {
    return (
    <motion.div 
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
                type: "spring",
                damping: 10,
                stiffness: 100,
                restDelta: 0.001
            }
        }}>
        <ModalStyle className='alert_bar' theme={theme} type={type} width={width}>
            <div className='alert_header'>
                {type === 'info' &&
                    <div className='icon'>
                        <Info style={{ display: 'block' }} />
                    </div>
                }
               {type === 'error' &&
                        <div className='icon'>
                            <Error style={{ display: 'block' }} />
                        </div>
                    }
                {type === 'success' &&
                    <div className='icon'>
                        <Success style={{ display: 'block' }} />
                    </div>
                }
                <h2 className='title'>{title}</h2>
                <div className='cross' onClick={onClose}>
                    <Close style={{ display: 'block' }} />
                </div>
            </div>
        </ModalStyle>
    </motion.div>
    )
}

export default Alert