import React from 'react';
import Navigation from 'components/elements/navigation/Navigation';
import { useLocation } from 'react-router-dom';
import { ReactComponent as NavHome } from 'assets/nav-home.svg';
import { ReactComponent as NavOrders } from 'assets/nav-orders.svg';
import { ReactComponent as NavPayouts } from 'assets/nav-payouts.svg';

const KitchenNavigation = () => {
  const location = useLocation();
  const hitBuzzer = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'navigation_click' }));
      return;
    }
  };
  const navLinks = [
    {
      to: '/kitchen',
      onClick: hitBuzzer,
      active: location.pathname === '/kitchen',
      icon: <NavHome className="icon" />,
      title: 'Home',
    },
    {
      to: '/kitchen/orders',
      onClick: hitBuzzer,
      active: location.pathname === '/kitchen/orders' && 'active',
      icon: <NavOrders className="icon" />,
      title: 'Orders',
    },
    {
      to: '/kitchen/payouts',
      onClick: hitBuzzer,
      active: location.pathname === '/kitchen/payouts' && 'active',
      icon: <NavPayouts className="icon" />,
      title: 'Payouts',
    },
  ];
  return <Navigation navLinks={navLinks} kitchen={true}/>;
};

export default KitchenNavigation;
