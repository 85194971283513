import styled from 'styled-components';

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: repeat(5, 1fr);
  column-gap: 20px;
`;
export default function MealNutritions({ nutritions }) {
  const { calories, carbs, fat, protein, weight } = nutritions;
  return (
    <StyledDiv>
      <b>Calories</b> {Math.round(calories)}
      <b>Carbs</b> {Math.round(carbs)}
      <b>Fat</b> {Math.round(fat)}
      <b>Protein</b> {Math.round(protein)}
      <b>Portion size</b> {Math.round(weight)}oz
    </StyledDiv>
  );
}
