import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useShopContext } from 'context/shopContext';
import ProviderCardAdded from 'components/shop/Checkout/ProviderCardAdded';
import classNames from 'classnames';
import { sum, multiply } from 'ramda';
import { useAppContext } from 'context/appContext';
import _ from 'lodash';
import ShopSteps from 'components/NutrittSteps/ShopSteps';
import NutrittSteps from 'components/NutrittSteps';
import BackButton from "components/BackButton";
import CoachAddedMeals from 'components/shop/Cart/CoachAddedMeals';
import { useRequest } from 'hooks/useRequest';
import Progress from 'components/Progress';

const CheckoutStyle = styled.div`
.desktop-steps{
  display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 24px;

  .back-arrow{
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 5%);
    border-radius: 50%;
    display: flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    position: fixed;
    left: 23px;
  
    img {
      width: 18px;
     }
  }
}
  .container {
    width: 100%;
    margin: 0 auto;
  }

  .empty-cart{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:#0b1d17;
    font-family: 'Roboto';
    font-size: 20px;
    line-height: 25px;

    @media(max-width:768px){
      font-size: 16px;
      line-height: 22px;
    }
  }

  .cart-items-wrapper{
    overflow: hidden;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .provider-head-name {
      margin-bottom: 20px;
      color: #0b1c18;
      font-size: 16px;
    }
    @media(min-width:992px){
      .container {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;
      
        .place-order-wrapper {
          position: relative !important;
          height: 100px;
          background-color: #fff;
          border-radius: 10px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none !important;
      
          button {
            width: 238px;
            height: 52px;
            background-color: #52c41a;
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
            border-radius: 28px;
            cursor: pointer;
          }
        }
      }
      margin: auto;
      width: 60%;
      padding:0 !important;
    }
  }
  .mobileView{
    .container{
      margin: 0px;
      width: 100%;
    }
  }

  .place-order-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  }
`;

const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function Checkout() {
  const { isMobile,orderCount, isGuest, isVisitor,setUser,user } = useAppContext();
  const { cart, cartCount, settings, providerListCalled, setLoadCart } = useShopContext();
  const history = useHistory();
  const { provider } = settings;
  const [buttonState, setButtonState] = useState(false);
  const maxMealsCount = settings.days * settings.meals;
  const [disableCheckoutBtn,setDisableCheckoutBtn] = useState(false);
  const [getLoadCart, { isLoading:isLoadCartLoading,}] = useRequest();
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [isCartLoaded, setIsCartloaded] = useState(false);
  const providersArray = settings.providerSettings;
  const mealsCart = cart;
  const HandleCartMeal = () => {
    let mealForCheckout = [];
    cart.map((meal) => {
      const providersInCart = providersArray.filter((i) => i.providerId === meal.providerId);
      const mealGroup = mealsCart.filter((i) => {
        return providersInCart?.[0] && i?.providerId === providersInCart?.[0].providerId;
      });
      mealForCheckout.push(mealGroup);
    });
    let uniq = _.uniqWith(mealForCheckout, _.isEqual);
    return uniq;
  };
  const data = HandleCartMeal();
  const HandleMealPrice = () => {
    let minOrderAmountMetArr = [];
    data.map(async (mealData, i) => {
      const machedProvider = providersArray.filter(
        (i) => mealData?.[0] && i.providerId === mealData?.[0].providerId,
      );
      const mealPrice = sum(mealData.map((i) => multiply(i.price, i.qty)));
      const minOrderAmountMet = mealPrice / 100 < machedProvider?.[0]?.minOrderAmount;
      minOrderAmountMetArr.push(minOrderAmountMet);
    });
    return minOrderAmountMetArr;
  };
  const minOrderAmountMetData = HandleMealPrice();
  const minOrderNotMatch = [];
  providersArray.forEach(providerItem => {

    const minOrderAmount = providerItem.minOrderAmount || 0;
    const cartOrderAmount = cart.filter(item => item.providerId==providerItem.providerId).reduce((partialSum, item) => partialSum + item.qty*item.price/100, 0);

    if(cartOrderAmount < minOrderAmount) {
      minOrderNotMatch.push(providerItem);
    }
  })

  useEffect(() => {
    if(minOrderNotMatch.length == 0){
      setButtonState(false);
    } else if (cartCount < maxMealsCount) {
      setButtonState(true);
    } else if (minOrderAmountMetData.includes(true)) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [cartCount]);

  const getLoadCartMeal = async() => {
    const route = 'loadcart/meals';
    await getLoadCart({
      path: route,
    })
      .then((data) => {
        setLoadCart(data?.data?.result);
        Object.keys(data?.data?.result).length && setIsCartloaded(true);
      })
      .catch(console.log);
  };

  useEffect(async()=>{
   await getLoadCartMeal();
   window.scrollTo(0, 0);
  },[])

  const mobileView = isMobile ? 'mobileView' : '';
 
  return (
        <CheckoutStyle className={`desktopPageInner ${mobileView}`}>
          {
          isMobile ?
          <ShopSteps
              title={`My cart (${cartCount})`}
              description="Enjoy the freshest meals from your local providers!"
              activeStep={2}
              steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
              perviousLink="/shop/meals"
          />
          :
          // <CheckoutHeader path={`/shop/meals`} title={`My Cart (${cartCount})`} />
          <div className='desktop-steps'>
            <BackButton perviousLink="/shop/meals" />
           <NutrittSteps
            title={`My Cart (${cartCount})`}
            description="Enjoy the freshest meals from your local providers!"
            activeStep={3}
            steps={(isVisitor || isGuest) ? steps.slice(0, -1) : steps}
            stepNumber={1}
            stepTitle="Let's specify your plan"
            isFooterHidden={true}
          />
          </div>
          }
          {isCartLoaded && <CoachAddedMeals setIsCartLoading={setIsCartLoading} setIsCartloaded={setIsCartloaded}/>}
          <form>
            <div className={`cart-items-wrapper ${mobileView}`}>
              <div className="container">
            {isCartLoading ? (
              <Progress />
            ) : (
              <>
                {data.length !== 0 ? (
                  <ProviderCardAdded
                    uniqMealData={data}
                    setDisableCheckoutBtn={setDisableCheckoutBtn}
                    disableCheckoutBtn={disableCheckoutBtn}
                  />
                ) : (
                  <div className="empty-cart">
                    <p>Seems that your cart is empty,<br />
                      Fill Your Cart with healthy and tasty meals.</p>
                  </div>
                )}
              </>
            )}

                {!isMobile &&
                <div className="place-order-wrapper ">
                {
                  cart.length>=1?(
                    <button
                  type="button"
                  onClick={() => {
                    history.push(`/shop/checkout/order`);
                    window.scrollTo(0, 0);
                  }}
                  className={classNames({
                    disabled: cartCount==0 || disableCheckoutBtn ,
                  })}
                  disabled={cartCount==0 || disableCheckoutBtn }
                >
                 <p> Checkout</p>
                </button>
                  ):(
                    <button
                  type="button"
                  onClick={() => {
                    history.push(`/shop/providers`);
                    window.scrollTo(0, 0);
                  }}
                  className={classNames({
                    disabled: buttonState,
                  })}
                  disabled={buttonState}
                >
                 <p> Shop</p>
                </button>
                  )
                }
              </div>
              }
              </div>

            </div>
           {isMobile &&
            <div className={` place-order-wrapper ${mobileView}`}>
            {
              cart.length>=1?(
                <button
              type="button"
              onClick={() => {
                history.push(`/shop/checkout/order`);
                window.scrollTo(0, 0);
              }}
              className={classNames({
                disabled: cartCount==0 || disableCheckoutBtn,
              })}
              disabled={cartCount==0 || disableCheckoutBtn}
            >
             Checkout
            </button>
              ):(
                <button
              type="button"
              onClick={() => {
                history.push(`/shop/providers`);
                window.scrollTo(0, 0);
              }}
              className={classNames({
                disabled: buttonState,
              })}
              disabled={buttonState}
            >
             <p>Shop</p>
            </button>
              )
            }
          </div>
           }
          </form>
        </CheckoutStyle>

  );
}
