import { useHistory } from 'react-router-dom';
import { HeaderWrapper } from './style';
import { useShopContext } from 'context/shopContext';
import { useAppContext } from 'context/appContext';
import { ReactComponent as AngleLeftBlack } from "assets/shop/angle-left-black.svg";

export default function Header({ favoriteKitchenData, provider }) {
  const { storeImages, image, username } = provider || {};
  const storeImage = Array.isArray(storeImages) && storeImages[0];
  const { kitchenUserName } = useShopContext();
  const { user, convertToHttps, isShoppingUser } = useAppContext();
  const history = useHistory();
  const shareFavKitchen = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "share-fav-kitchen", kitchenUrl: `${process.env.REACT_APP_CLIENT_URL}/store/${kitchenUserName}` }))
      return;
    }
  };

  return (
    <HeaderWrapper>
      <img
        src={storeImage || '/images/sexy_just_eat_now.jpg'}
        alt="Header Img"
        className="header-img"
      />
      <div className="header-icons">
        <div className="icon-wrapper" onClick={() => {
          user && isShoppingUser ? history.push('/shop/kitchen-home') : (user?.type?.includes('super') || user?.type?.includes('provider')) ? history.push('/') : history.push('/visitor/kitchens');
        }}>
          <AngleLeftBlack alt="angle Img" className="angle-img" />
        </div>
        <div className="favorite-share">
          {provider?.businessProfileId === favoriteKitchenData?.businessProfileId && (
            <div className="icon-wrapper">
              <img
                src={`/icons/${
                  provider?.businessProfileId === favoriteKitchenData?.businessProfileId
                    ? 'favorite-heart'
                    : 'heart-gray'
                }.svg`}
                alt="Favorite Img"
                className="favorite-img"
              />
            </div>
          )}
          <div className="icon-wrapper" onClick={shareFavKitchen}>
            <img src="/icons/share-black.svg" alt="Share Img" className="share-img" />
          </div>
        </div>
      </div>
    </HeaderWrapper>
  );
}
