import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import DateRangeIcon from '@material-ui/icons/DateRange';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { BsCaretDownFill } from 'react-icons/bs';
import { HiOutlineLogout } from 'react-icons/hi';
import { NavLink, useHistory, useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { useAppContext } from 'context/appContext';

const NavItemWrapper = styled.div`
  display: flex;

  .nav-item {
    margin: 0 10px 0 20px;
    color: gray;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 24px;
    }
  }

  @media (max-width: 991px) {
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    padding: 30px 20px 0;
    flex-direction: column;

    .provider-info {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 75px;
        height: 75px;
        margin-bottom: 20px;
        object-fit: cover;
        border-radius: 40px;
      }
    }

    .nav-item + .nav-item {
      margin-top: 20px;
    }
  }
`;

const StyledMenu = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 25px;
  left: -55px;
  background: white;
  max-width: 200px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
`;

export default function NavItems({ isMobile, updateProvider, provider, isAlert }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const { logout, user } = useAppContext();

  const toggleIsOpen = () => setIsOpen(!isOpen);  

  const pushLink = (link) => {
    if (pathname == '/provider') {
      JSON.stringify(updateProvider) === JSON.stringify(provider)
        ? history.push(link)
        : isAlert(link);
    } else {
      history.push(link);
    }
  };

  return (
    <NavItemWrapper>
      {isMobile && (
        <div className="provider-info">
          <img src={provider?.images?.[0] || '/images/avatar.png'} />
          <div>{provider?.name}</div>
        </div>
      )}
      <div className="nav-item" onClick={() => pushLink('/provider')}>
        <StorefrontIcon />
        Profile
      </div>
      {isMobile ? (
        <>
          <div className="nav-item" onClick={() => pushLink('/provider/menu/meals')}>
            <RestaurantIcon />
            Meals
          </div>
          {/* <div className="nav-item" onClick={() => pushLink('/provider/manage-combo-component')}>
            <ListAltIcon />
            Components
          </div>
          <div className="nav-item" onClick={() => pushLink('/provider/menu/components')}>
            <ListAltIcon />
            Combos
          </div> */}
        </>
      ) : (
        // <a
        //   onClick={toggleIsOpen}
        //   // onMouseEnter={toggleIsOpen}
        //   // onMouseLeave={toggleIsOpen}          
        //   style={{ position: 'relative', cursor: 'pointer' }}
        // >
        //   Menu
        //   <StyledMenu isOpen={isOpen}>
        //     <div className="nav-item" onClick={() => pushLink('/provider/menu/meals')}>
        //       <RestaurantIcon />
        //       Meals
        //     </div>
        //     <div style={{ height: '10px' }} />
        //     <div className="nav-item" onClick={() => pushLink('/provider/manage-combo-component')}>
        //       <ListAltIcon />
        //       Components
        //     </div>
        //     <div style={{ height: '10px' }} />
        //     <div className="nav-item" onClick={() => pushLink('/provider/menu/components')}>
        //       <ListAltIcon />
        //       Combos
        //     </div>
        //   </StyledMenu>
        //   <BsCaretDownFill />
        // </a>
        <div className="nav-item" onClick={() => pushLink('/provider/menu/meals')}>
          <RestaurantIcon />
          Meals
        </div>
      )}
      <div className="nav-item" onClick={() => pushLink('/provider/schedule')}>
        <DateRangeIcon />
        Schedule
      </div>
      <div className="nav-item" onClick={() => pushLink('/provider/orders')}>
        <ShoppingCartIcon />
        Orders
      </div>
      <div className="nav-item" onClick={() => pushLink('/provider/payouts')}>
        <AttachMoneyIcon />
        Payouts
      </div>
      {isMobile && (
          <Link to="/logout" onClick={logout}>
              <div className="nav-item">
                  <HiOutlineLogout /> Logout
              </div>
          </Link>
      )}
    </NavItemWrapper>
  );
}
