export const isEmailValid = (email) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
};

export const onChangeEmail = (value, setEmail) => {
  if (!value) return setEmail({ value, error: 'Email is required' });
  const { error } = email;
  if (error === 'Invalid email' && !isEmailValid(value)) return setEmail({ value, error });
  setEmail({ value });
};

export const onBlurEmail = (value, setEmail) => {
  if (!value) return setEmail({ value, error: 'Email is required' });
  if (!isEmailValid(value)) setEmail({ value, error: 'Invalid email' });
};
