import { forwardRef, useEffect, useState } from 'react';
import { Line } from 'rc-progress';
import { motion } from 'framer-motion';
import { OrderAlertWrapper } from './style';

function OrderAlert(
  {
    status,
    setIsAlert,
    setSelectedOrderId,
    selectedOrderIdRef,
    selectedAllOrderIdRef,
    selectedOrderId,
  },
  ref,
) {
  const [percent, setPercent] = useState(100);

  useEffect(() => {
    setInterval(() => {
      setPercent((prev) => prev - 1);
    }, 50)

    return () => {
      clearInterval(percent);
    } 
  }, [])

  const handleUndo = () => {
    setIsAlert(() => {
      ref.current = false;
      return false;
    });
    setSelectedOrderId();
    selectedOrderIdRef.current = '';
    selectedAllOrderIdRef.current = selectedAllOrderIdRef.current.filter(
      (selected) => selected !== selectedOrderId,
    );
  };

  return (
    <motion.div
      className="order-alert-container"
      initial={{ y: 130 }}
      exit={{ y: 130 }}
      animate={{ y: 0 }}
      transition={{
        duration: 0.3,
        delay: 0,
        ease: 'linear',
      }}
    >
      <OrderAlertWrapper>
        <div className="order-status">Order has been {status}</div>
        <div className="undo" onClick={handleUndo}>
          Undo
        </div>
        <Line
          percent={percent}
          strokeColor="#757E89"
          trailColor="#00092D"
          strokeLinecap="square"
        />
      </OrderAlertWrapper>
    </motion.div>
  );
}

export default forwardRef(OrderAlert);
