import { useCallback, useEffect, useState } from 'react';
import { useRequest } from 'hooks/useRequest';
import { useAppContext } from 'context/appContext';
import { useKitchenContext } from 'context/kitchenContext';
import OrderDetailsPage from 'components/elements/orderDetail/OrderDetailsPage';

function OrderDetailsPageContainer({
  selectedOrder,
  setSelectedOrder,
  setIsOrderStatusChanged,
  setAlertData,
  orderDataPageNumber,
  tab,
  imitateProviderId,
  sort,
  sortBy,
  orderNumber,
  filterQuery,
  setIsSplit,
  selectedTabId,
  setFilterQuery
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [orderIndex, setOrderIndex] = useState();
  const [generateOrders, setGenerateOrders] = useState();
  const [acceptSelectedOrder, { isLoading }] = useRequest(`fulfill`, 'post');
  const [fetchOrderData, { isLoading: isOrdersApiCallProcessing }] = useRequest();
  const { providers, orderStatus } = selectedOrder;
  const { setNewOrdersCount, newOrdersCount, setOrderData, orderData } = useKitchenContext() || [];
  const [updateOrderStatus] = useRequest();
  const { isSuper } = useAppContext();
  const {
    Provider: { id: providerId },
  } = providers[0];
  const orderStatusIndex = orderStatus.findIndex((item) => item?.providerId === providerId);
  const [orderMove, setOrderMove] = useState('');
  const [pageNumber, setPageNumber] = useState(orderDataPageNumber);

  const handleSave = useCallback(
    async (status, orderId) => {
      if (isLoading) return;
      try {
        setIsSplit(false);
        setSelectedOrder({});
        const result = await acceptSelectedOrder({
          body: { newStatus: status, providerId, orderIds: [orderId] },
        });
        if (result) {
          setTimeout(
            () => showSuccessMessage(`You have successfully ${status} the new order`),
            1000,
          );
          setIsOrderStatusChanged((prev) => !prev);
        } else {
          setTimeout(() => showErrorMessage('Error occurred while saving changes'), 1000);
        }
        setIsOpen(false);
      } catch (e) {
        setIsOpen(false);
        showErrorMessage(e);
      }
    },
    [isLoading],
  );

  const handleOrderSeen = async (id, providerId, isOrderSeen) => {
    try {
      if (!isOrderSeen) {
        await updateOrderStatus({
          path: `fulfill/provider-order/${id}`,
          method: 'put',
          body: {
            providerId,
            isOrderSeen: !isOrderSeen,
          },
        });
        const updatedOrderData = { ...orderData };
        const orderToUpdate = updatedOrderData.orders.find(
          (order) => order.orderMainId === selectedOrder?.orderMainId,
        );
        orderToUpdate.providers.map((item) => {
          if (item.id === id) {
            item['isOrderSeen'] = !isOrderSeen;
          }
        });
        setOrderData(updatedOrderData);
        const updatedOrdersCount = newOrdersCount - 1;
        setNewOrdersCount(updatedOrdersCount);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showSuccessMessage = (title) => {
    setAlertData({
      type: 'success',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const showErrorMessage = (title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' });
    }, 5000);
  };

  const fetchOrder = async () => {
    try {
      const path = `provider/orders?pageSize=50&pageNumber=${pageNumber}&action=${
        tab === 'All' ? 'all' : selectedOrder?.orderStatus[orderStatusIndex]?.action
      }${sortBy && sort ? `&${sortBy}=${sort}` : ''}${
        orderNumber ? `&orderNumber=${orderNumber}` : ''
      }${filterQuery ? `&${filterQuery}` : ''}${isSuper ? `&providerId=${imitateProviderId}` : ''}`;
      const { data } = await fetchOrderData({ path }, 'get');
      setGenerateOrders(data);
      if (localStorage.getItem('orderDetails')) setOrderData(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [pageNumber]);

  useEffect(() => {
    if (generateOrders?.orders?.length) {
      const getIndex = generateOrders?.orders?.findIndex(
        (order) => order?.orderMainId === selectedOrder?.orderMainId,
      );
      setOrderIndex(getIndex);
    }
  }, [generateOrders?.orders]);

  useEffect(() => {
    if (orderIndex > -1) {
      if (orderMove === '') setSelectedOrder(generateOrders?.orders[orderIndex]);
    } else {
      if (orderMove === 'previous') {
        setSelectedOrder(generateOrders?.orders[49]);
        setOrderIndex(49);
      }
      if (orderMove === 'next') {
        setSelectedOrder(generateOrders?.orders[0]);
        setOrderIndex(0);
      }
    }
  }, [orderIndex]);

  return (
    <OrderDetailsPage
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      selectedOrder={selectedOrder}
      setSelectedOrder={setSelectedOrder}
      handleSave={handleSave}
      orderIndex={orderIndex}
      setOrderIndex={setOrderIndex}
      generateOrders={generateOrders}
      handleOrderSeen={handleOrderSeen}
      imitateProviderId={imitateProviderId}
      isOrdersApiCallProcessing={isOrdersApiCallProcessing}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      setOrderMove={setOrderMove}
      orderNumber={orderNumber}
      selectedTabId={selectedTabId}
      filterQuery={filterQuery}
      sort={sort}
      sortBy={sortBy}
      setFilterQuery={setFilterQuery}
    />
  );
}

export default OrderDetailsPageContainer;
