import React, { useCallback, useState } from 'react';
import { theme } from 'shared/theme/theme';
import { Button } from 'components/elements/button/Button';
import Toggle from 'components/elements/toggleModule/Toggle';
import RegularInput from 'components/Input/RegularInput';
import { GilroyFontWrapper } from 'shared/theme/style';
import { useRequest } from 'hooks/useRequest';
import { useKitchenContext } from 'context/kitchenContext';
import { ReactComponent as Edit } from 'assets/kitchen/Edit.svg';
import Alert from 'components/elements/alert/Alert';
import { SubscriptionWrapper } from './style';
import { SubcriptionOptions } from '../../../../../../constants'
import { Modal } from 'components/elements/modal/Modal';

export const Subscription = () => {
  const { subscriptionList, setSubscriptionList, imitateProviderCredentials } = useKitchenContext();
  const [editSubscriptionList, setEditSubscriptionList] = useState(subscriptionList);
  const [hasNonNullData, setHasNonNullData] = useState(
    subscriptionList.some((sub) => sub.isActive),
  );
  const [createSubscription, {isLoading}] = useRequest();
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    type: '',
    title: '',
  });
  
  const handleInputChange = useCallback(
    (e, intervaldays) => {
      const { value } = e.target;
      let parsedValue = parseFloat(value.trim().replace(/[^0-9.]/g, ''));
      parsedValue = isNaN(parsedValue) ? 0 : parsedValue;
      parsedValue = Math.min(Math.max(parsedValue, 0), 100);
  
      let isExistingSubscription = false;
      const updatedSubscriptionList = editSubscriptionList.map((sub) => {
        if (sub.intervaldays === intervaldays) {
          isExistingSubscription = true;
          return { ...sub, discount: parsedValue };
        }
        return sub;
      });

      if (!isExistingSubscription) {
        updatedSubscriptionList.push({ intervaldays, discount: parsedValue });
      }

      setEditSubscriptionList(updatedSubscriptionList);
    },
    [editSubscriptionList],
  );

  const toggleSubscriptionIsActive = useCallback(
    (intervaldays) => {
      const updatedSubscriptionList = editSubscriptionList.map((sub) => {
        if (sub?.intervaldays === intervaldays) {
          return { ...sub, isActive: !sub.isActive };
        }
        return sub;
      });

      const foundSubscription = updatedSubscriptionList.find(
        (sub) => sub?.intervaldays === intervaldays
      );
       if (!foundSubscription) {
        updatedSubscriptionList.push({ intervaldays, isActive: true });
      } else if (!foundSubscription.discount && foundSubscription && !foundSubscription.isActive && foundSubscription.discount !==0) {
        const indexToRemove = updatedSubscriptionList.findIndex(sub => sub.intervaldays === foundSubscription.intervaldays);
        if (indexToRemove !== -1) {
          updatedSubscriptionList.splice(indexToRemove, 1);
        }
    }

      setEditSubscriptionList(updatedSubscriptionList);
    },
    [editSubscriptionList],
  );

  const createSubscriptions = async ({ editSubscriptionList, imitateProviderCredentials }) => {
    try {
      const subscriptions = Array.isArray(editSubscriptionList)
        ? editSubscriptionList
        : [editSubscriptionList];
      const payloads = subscriptions.map(({ isActive, intervaldays, discount, discountType }) => ({
        isActive,
        intervaldays,
        discount,
        discountType,
      }));
      const path = `subscription/create/${imitateProviderCredentials?.providerId}`
      const response = await createSubscription({ path, method: 'POST', body: payloads });
      setSubscriptionList(editSubscriptionList);
      setHasNonNullData(editSubscriptionList.some((sub) => sub.isActive));
      setIsEdit(false);
      setIsOpen(false);
    } catch (error) {
      console.error('Error creating subscriptions:', error);
      showErrorMessage(error.message);
    }
  };

  function handleInput(e) {
    const value = e.target.value.trim();
    const regex = /^\d{0,4}(\.\d{0,13})?$/;
  
    if (!regex.test(value)) {
      e.target.value = value.slice(0, -1);
    } else if (parseFloat(value) > 100) {
      e.target.value = '100';
    } else if (parseFloat(value) < 0) {
      e.target.value = '0';
    }
    }  

  const renderCheckbox = (day) => {
    const subscription = editSubscriptionList.find((sub) => sub.intervaldays === day.intervaldays);
    const inputWrapperValue = !subscription?.discount === null || !subscription?.discount === undefined ? 0 : subscription?.discount
    return (
      <div className="schedules_date_time" key={day.id}>
        <div className="toggle">
          {isEdit ? (
            <Toggle
              text={day.label}
              type="checkbox"
              toggleColor="black"
              className={'header-checkbox'}
              checked={subscription?.isActive || false}
              onChange={() => toggleSubscriptionIsActive(day.intervaldays)}
            />
          ) : (
            <p className="value">{day.label}</p>
          )}
        </div>
        {!isEdit ? (
          <p className="value">{subscription?.discount}%</p>
        ) : (
          <RegularInput
          placeholder={subscription?.discount === null || subscription?.discount === undefined ? `0%` : ''}
          inputWrapperValue={inputWrapperValue}
          type="number"
          onChange={(e) => handleInputChange(e, day.intervaldays)}
          onInput={handleInput}
          value={subscription?.discount}
          onKeyDown={(e) => {
            if (e.key === 'Backspace' || e.key === ' ') {
              e.preventDefault();
              const newValue = e.target.value.slice(0, -1);
              handleInputChange({ target: { value: newValue } }, day.intervaldays);
            }
          }}
          />
        )}
      </div>
    );
  };

  const areSubscriptionsUnchanged = useCallback((subscriptionList, editSubscriptionList) => {
    const unchanged = editSubscriptionList.every((sub, index) => {
      const editSub = subscriptionList[index];
      return JSON.stringify(sub) === JSON.stringify(editSub);
  });
    const hasNotActiveZeroDiscount = editSubscriptionList.some(subscription => subscription.discount === 0 && subscription.isActive);
    const hasNoDiscount = editSubscriptionList.filter(item => item.hasOwnProperty('discount'))?.length < 1;
    return unchanged || hasNotActiveZeroDiscount || hasNoDiscount ;
  }, [subscriptionList, editSubscriptionList]);
  
  const showErrorMessage = useCallback((title) => {
    setAlertData({
      type: 'error',
      title: title,
    });
    setTimeout(() => {
      setAlertData({ type: '', title: '' }); 

    }, 5000);
  },[]);
  return (
    <GilroyFontWrapper>
      <SubscriptionWrapper theme={theme} isEdit={isEdit}>
      {alertData.type && (
        <div className="alert">
          <Alert type={alertData.type} title={alertData.title} width="461px" onClose={() => setAlertData({ type: '', title: '' })}  />
        </div>
      )}
      {isOpen && (
        <div className="modal-position">
            <Modal
              size="large"
              type="error"
              description={`Please be informed that the subscription recently changes applies only to new subscriptions and not existing ones. `}
              onOk={() => createSubscriptions({ editSubscriptionList, imitateProviderCredentials })}
              isOpen={isOpen}
              onClose={() => setIsOpen(!isOpen)}
              onCancel={() => setIsOpen(!isOpen)}
              title={'Are you sure?'}
              primaryText={`Save`}
              secondaryText={'Cancel'}
              isBackdropEnabled={true}
              buttonType={'dark'}
            />
        </div>
      )}

        <h2 className="title">Subscription</h2>
        {isEdit &&
        <h2 className="sub_text">
          Encourage repeat orders (subscriptions) and loyalty by offering an exclusive discount on
          recurring orders.
        </h2>
        }
        {hasNonNullData ? (
          <>
            {isEdit ? (
              <div className="btn_flex">
                <Button
                  title="Cancel"
                  className="custom_button"
                  onClick={() => (
                    setHasNonNullData(subscriptionList?.some((sub) => sub.isActive)),
                    setIsEdit(false),
                    setEditSubscriptionList(subscriptionList)
                  )}
                />
                <Button
                  title="Save"
                  type={'dark'}
                  onClick={() => subscriptionList?.length ? setIsOpen(true) : createSubscriptions({ editSubscriptionList, imitateProviderCredentials })}
                  isDisabled={isLoading || areSubscriptionsUnchanged(subscriptionList, editSubscriptionList)}
                />
              </div>
            ) : (
              <Button
                title="Edit"
                className="custom_button"
                onClick={() => setIsEdit(true)}
                iconRight={<Edit />}
              />
            )}
            <div className="data_title">
              <div className="titles left">Delivery interval</div>
              <div className="titles right">Discount amount</div>
            </div>
            {!isEdit
              ? SubcriptionOptions
                  .filter((day) =>
                    editSubscriptionList.find(
                      (sub) => sub.intervaldays === day.intervaldays && sub.isActive,
                    ),
                  )
                  .map((day) => renderCheckbox(day))
              : SubcriptionOptions.filter(option => option.value !== 'once').map((day) => renderCheckbox(day))}
            {isEdit && (
              <div className="subscription-preview">
                <h2 className="note">Note:</h2>
                <h2 className="sub_text gap">
                  Preview how your customers will experience savings! <br />
                  Take a look at the 'Repeat Order and Save' option they'll see during checkout.
                </h2>
                <img src="/images/subcription-preview.png" className='preview-img'/>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="details">
              <img src="/images/Subscription.svg" />
              <text>Add subscriptions</text>
              <p>
                Setup subscriptions in your kitchen to elevate the customer experience and stimulate
                a higher frequency of recurring orders.
              </p>
              <Button
                type="dark"
                title="Set up subscription"
                onClick={() => {
                  setHasNonNullData(true);
                  setIsEdit(true);
                }}
              ></Button>
            </div>
          </>
        )}
      </SubscriptionWrapper>
    </GilroyFontWrapper>
  );
};
