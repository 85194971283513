import Header from 'layout/Header';
import Steps from './Steps';
import styled from 'styled-components';
import Input from 'components/Input';
import { useState } from "react";
import { useRequest } from 'hooks/useRequest';
import { toast } from "react-toastify";
import { useAppContext } from 'context/appContext';
import { useHistory } from 'react-router-dom';
import Progress from 'components/Progress';

const CreatePasswordWrapper = styled.div`
    width: 90%;
    margin: 23px auto;
    padding: 4px 25px 40px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  
    label {
      margin-bottom: 7px;
      color: #51635d;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
      font-wieght: 700 !important;
    }
  
    button {
      height: 44px;
      margin-top: 32px;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button: disabled {
        background-color: #e0e3e0;
        color: #949d9b;
        cursor: not-allowed;
    }

    @media(min-width: 992px){
        width: 45%;
        height: 319px;
        padding: 23px 72px 40px !important;
        border-radius: 20px;
        
        input{
            border: 1px solid #E0E3E0;
            height: 44px;
        }
        .button-wrapper{
            display: flex;
            justify-content: center;
        }
        button{
            height: 56px;
            border-radius: 28px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

export default function CreatePassword() {
    const history = useHistory()
    const {guestId ,afterLogin , selectedGuestOfferDetails , setLoginInfo , loginInfo} = useAppContext()
    const [data, setData] = useState({ createPassword: '', confirmPassword: '' });
    const [setPassword, { isLoading }] = useRequest('guest/set-password', 'post');
    const [passFormError, setPassFormError] = useState({});
    const { isMobile } = useAppContext();

    const Validations = (createPass, confirmPass) => {
        const errors = {};
        if (!createPass) {
            errors.createPass = "Password is required!"
        }
        if (!confirmPass) {
            errors.confirmPass = "Confirm Password is required!"
        }
        if ((createPass !== confirmPass)) {
            errors.confirmPass = "Password mismatch!"
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (Validations.errors) {
            return alert(Validations.errors)
        }
        if ((data?.createPassword && data?.confirmPassword) && (data?.createPassword === data.confirmPassword)) {
            const res = await setPassword({
                body: {
                    userId: Number(guestId),
                    password: data.createPassword,
                    confirmPassword: data.confirmPassword
                },
            })
            if (res.success === true) {
                toast.success('Password Created successfully');
                 await setLoginInfo({...loginInfo , email : selectedGuestOfferDetails.clientEmail ,  password: data.confirmPassword});
                history.push(`/booking/booked`);
            } else {
                toast.error('Oops! something went wrong');
            }
        }
        setPassFormError(Validations(data?.createPassword, data?.confirmPassword));
    };

    return (
        <>
            <Header
                activeSteps="6"
            />
            {isMobile ? <Steps
                title="Nice, you're all booked!"
                description1="Create a password to view and manage"
                description2="your booking"
                perviousLink={()=>{
                    history.goBack();
                   }}
            /> :
            <Steps
                title="Nice, you're all booked!"
                description1="Create a password to view and manage your booking"
                perviousLink={()=>{
                    history.goBack();
                   }}
            />}
            <CreatePasswordWrapper>
                <Input
                    type="password"
                    label="Create password"
                    placeholder="Password"
                    required
                    value={data.createPassword}
                    error={data.error}
                    onChange={(e) => setData({ ...data, createPassword: e.target.value })}
                />
                {isLoading && <Progress />}
                <h5 style={{ color: 'red', paddingTop: 0, margin: 0 }}>{passFormError.createPass}</h5>
                <Input
                    type="password"
                    label="Confirm your password"
                    placeholder="Confirm Password"
                    required
                    value={data.confirmPassword}
                    error={data.error}
                    onChange={(e) => setData({ ...data, confirmPassword: e.target.value })}
                />
                <h5 style={{ color: 'red', paddingTop: 0, margin: 0 }}>{passFormError.confirmPass}</h5>
                <div className='button-wrapper'>
                <button className="primary-btn" onClick={handleSubmit} disabled={!data.createPassword || !data.confirmPassword || isLoading}>View confirmation</button>
                </div>
            </CreatePasswordWrapper>
        </>
    );
}