import styled from 'styled-components';
import NutritionistCoaching from './NutritionistCoaching';
import NutritionistPopularPlan from './NutritionistPopularPlan';
import NutritionistFooter from './NutritionistFooter';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { useNutritionistContext } from '../../context/nutritionistContext';
import Progress from 'components/Progress';
import { useAppContext } from 'context/appContext';
import NutritionistFavoriteFollowers from './NutritionistFavoriteFollowers';
import UseClientCart from 'hooks/UseClientCart';

const NutritionistHomeStyle = styled.div`
display: flex;
flex-direction: column;
padding-bottom: 10px;
margin-left: 300px;
background: #ffffff;
padding: 0px 24px;
@media screen and (min-width:1441px) and (max-width: 1920px){
    padding: 0px 98px; 
}
@media screen and (max-width: 767px){
    margin-left: 0px;
    background: #F6F5F8;
    padding: 35px 20px;
}
.invite-overlay {
    position: absolute;
    top: 24px;
    right: 24px;
    @media screen and (max-width: 767px){
        position: relative;
        right: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        top: 0px;
        margin: 20px 0px;
    }
    button {
      float:right;
      width: 147px;
      height: 40px;
      background: #52c41a;
      border-radius: 21px;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background: #cbedba;
        color: #51635d;
        transition: all 0.3s ease-in-out;
      }
    }
  }

.section1{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-template-rows:100px !important;
    grid-column-gap: 14px;
    @media screen and (min-width:1441px) and (max-width: 1920px){
        grid-template-rows:155px !important;
        grid-column-gap: 24px;
    }
    @media screen and (max-width: 767px){
        display: block;
        filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
        border-radius: 10px;
        background-color: #fff !important;
    }
    .card-grey1{
        background-color: #F6F5F8 !important;
        border-radius: 10px !important;
        padding: 24px;
        &:first-child{
            @media screen and (max-width: 767px){
                background-color: #52C41A !important;
                border-top-left-radius: 10px !important;
                border-top-right-radius: 10px !important;
            }
            .current{
                p{
                    @media screen and (max-width: 767px){
                        color: #fff !important;
                        font-weight: 400 !important;
                    }
                }
                span{
                    @media screen and (max-width: 767px){
                        color: #fff !important;
                        font-size: 32px !important;
                        line-height: 38px !important;
                    }
                }
            }
        }
        @media screen and (max-width: 767px){
            background-color: transparent !important;
            border-radius: 0px !important;
        }
        .image-div{
            background:url('/icons/Ellipse 246.svg') no-repeat;
            background-size: 32px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 14px;
            right: 14px;
            @media screen and (min-width:1441px) and (max-width: 1920px){
                width: 48px;
                height: 48px;
                background-size: 48px;
            }
            img{
                width: 16px;
                height: 16px;
                @media screen and (min-width:1441px) and (max-width: 1920px){
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .current{
            width:260px !important;
            p{
                color: #51635D;
                font-weight: 700 !important;
                @media screen and (min-width:1441px) and (max-width: 1920px){
                    font-size: 20px;
                    line-height: 24px;
                }
                @media(max-width:991px){
                    font-weight: 400 !important;
                    color: #949D9B !important;
                }
            }
        }
        &:nth-child(2){
            @media(max-width:991px){
                border-bottom: 1px solid #DFDEE1;
            }
        }
    }
}
.section23{
    display: grid !important;
    grid-template-columns: repeat(3, 32%);
    margin-top: 24px !important;
    grid-column-gap: 14px;
    @media screen and (min-width:1441px) and (max-width: 1920px){
        grid-column-gap: 24px;
    }
    @media screen and (max-width: 767px){
        display: block !important;
        margin-top: 0px !important;
    }
    .upcoming {
        overflow-y:hidden;
        background: #ffffff;
        width: 100%;
        border-radius: 10px;
        padding: 20px 0px;
        height:100%;
        border: 1px solid #E5E5E5;
        position: relative;
        padding-bottom: 55px;
        @media screen and (max-width: 767px){
            padding: 0px;
            height: auto;
        }
        .upcoming-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e0e3e0;
            padding-bottom: 18px !important;
            margin: 0px 20px;
            @media screen and (min-width:1441px) and (max-width: 1920px){
                margin: 0px 24px;
            }
            .follower-number{
                background: #52C41A;
                border-radius: 5px;
                height: 16px;
                padding: 2px 6px;
                font-weight: 700;
                font-size: 11px;
                line-height: 12px;
                display:flex;
                justify-content:center;
                align-items:center;
                color:#ffffff;
                @media screen and (min-width:1441px) and (max-width: 1920px){
                    font-size: 14px;
                    width: 41px;
                    height: 28px;
                }
            }
            p {
                font-family: 'Roboto';
                font-weight: 700;
                font-size: 18px;
                line-height: 19px;
                color: #0b1d17;
                @media screen and (min-width:1441px) and (max-width: 1920px){
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
        .upcoming-list{
            min-height: calc(100vh - 380px);
            max-height: calc(100vh - 380px);
            overflow: auto;
            @media screen and (min-width:1441px) and (max-width: 1920px){
                min-height: calc(100vh - 490px);
                max-height: calc(100vh - 490px);
            }
            .star-Icon{
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                @media screen and (min-width:1441px) and (max-width: 1920px){
                    width: 48px;
                    height: 48px;
                }
            }
        }
        .plan-section{
            min-height: calc(100vh - 380px);
            max-height: calc(100vh - 380px);
            overflow-y: auto;
            @media screen and (min-width:1441px) and (max-width: 1920px){
                min-height: calc(100vh - 490px);
                max-height: calc(100vh - 490px);
            }  
        }
        .session{
            position: absolute;
            bottom: 15px;
            width: 100%;
            margin: 0px;
            line-height: 35px;
            display: flex;
            justify-content: center;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            color: #52c41a;
            cursor:pointer;
            @media screen and (min-width:1441px) and (max-width: 1920px){
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}

.welcome-nutritionist{
    width:100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 76px;
    color: #0B1D17;
    margin-top: 30px;
    text-align: center;
    @media screen and (max-width: 767px){
        text-align: left;
        margin-top: 32px;
        margin-bottom: 22px;
    }
}

.section1{
    .card-grey1{
        color:#FFFFFF;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        .icon{
            width: 48px;
        }
        .current{
            width: 230px;
            @media screen and (max-width: 767px){
                padding-left: 15px;
            }
            p{
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
           span{
            color: #51635D;
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 36px;
            line-height: 43px;
            @media screen and (min-width:1441px) and (max-width: 1920px){
                font-size: 60px;
                line-height: 70px;
            }
            @media(max-width:991px){
                font-size: 32px !important;
                line-height: 38px !important;
                color: #000000 !important;
            }
           }
        }
    }
}
.section-23{
    display: flex;
    flex-direction: column;
    align-items: center;
}
`

export default function NutritionistHome({modalShow,setModalshow}) {
    const { isMobile } = useAppContext();
    const { disbursements,pay,isLoaded,error,activeClients, nutritionist, mostPopularMealPlan, followersList, coachingPay,ismostPopularMealPlanLoading,isCoachingPayLoading,isActiveClientsLoading,isLoadingDisbursement} = useNutritionistContext();
    const [clients,setClients]=useState([]);
    const [isModal, setIsModal] = useState(false);
    const {OnlyFirstName} = UseClientCart();
    const mealAndCoachingCurrentBalance = ((coachingPay?.currentBalance+pay?.currentBalance)/100 ||0).toFixed(2);
    const mealAndCoachingTotalEarned = ((coachingPay?.totalEarnings?._sum?.amount+pay?.totalEarnings?._sum?.amount)/100||0).toFixed(2);

    useEffect(() => {
        if (activeClients.length > 0) {
            setClients([])
            activeClients.forEach((user) => {
                user[1].forEach((item) => {
                   setClients((prevState)=>[...prevState,item])
                })
            })
        } else {
            setClients([])
        }
    }, [activeClients])
 
  if (isLoaded || ismostPopularMealPlanLoading || isLoadingDisbursement || isActiveClientsLoading || isCoachingPayLoading) return <Progress/>;
  if (error) return console.log(error);
  const name=nutritionist?.nutritionist?.name

  return !disbursements ? (
    <Progress />
  ):(
        <NutritionistHomeStyle>
            <div className="welcome-nutritionist common-title"> Welcome back, {name?.length>25 ? name.substr(0,25):OnlyFirstName(name)} !
            </div>
            {/* section1 */}
            <div className='section1'>
                <div className='card-grey1'>
                    {isMobile ?  <img className='icon' src="/icons/wallet-icon.svg" alt="Arrow Icon" /> :
                        <div className='image-div'>
                        <img className='icon' src="/icons/wallet.svg" alt="Arrow Icon" />
                        </div>    
                    }
                    <div className='current'>
                        <p>Current Balance</p>
                        <span> ${mealAndCoachingCurrentBalance}</span>
                    </div>
                </div>
                <div className='card-grey1'>
                    {isMobile ?  <img className='icon' src="/icons/dollar-icon.svg" alt="Arrow Icon" />
                        :
                    <div className='image-div'>
                        <img className='icon' src="/icons/cost.svg" alt="Arrow Icon" />
                    </div>    
                    }
                    <div className='current'>
                        <p>Total Earned</p>
                        <span>${mealAndCoachingTotalEarned}</span>
                    </div>
                </div>
                <div className='card-grey1'>
                    {isMobile ?  <img className='icon' src="/icons/people-icon.svg" alt="Arrow Icon" />
                        :
                    <div className='image-div'>
                        <img className='icon' src="/icons/users.svg" alt="Arrow Icon" />
                    </div>    
                    }
                    <div className='current'>
                        <p>Followers</p>
                        <span>{pay?.clientCount || 0}</span>
                    </div>
                </div>
            </div>
            <div className="invite-overlay">
                <button onClick={() => setIsModal(true)}>Send invite</button>
            </div>
            <div className={!isMobile ? 'section23' : 'section-23'}>
            {/* section2 */}
            <NutritionistCoaching nutritionistMeeting={clients} setIsModal={setIsModal} isModal={isModal}/>
            <NutritionistFavoriteFollowers followersList={followersList}/>
            {/* section3 */}
            <NutritionistPopularPlan mostPopularMealPlan={mostPopularMealPlan} modalShow={modalShow} setModalshow={setModalshow} />
            </div>
        </NutritionistHomeStyle>          
    )
}