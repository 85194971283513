import Styled from 'styled-components';
import classNames from 'classnames';
import SetClientCart from 'hooks/setClientCart';
import { useRequest } from 'hooks/useRequest';
import { useState } from 'react';
import { useShopContext } from 'context/shopContext';

const FlexCenter = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CoachAddedMealsWrapper = Styled.div`
  margin-bottom: 15px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  .coach-meals-title {
    height: 48px;
    padding: 0 24px;
    color: #0B1D17;
    border: 1px solid #F6F5F8;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
  }
  .coach-meals-content {
    padding: 12.5px 10px 20.5px;
    display: flex;
    gap: 5px;
    &.moreFourMeals {
      justify-content: space-between;
    }
    .meal-wrapper {
      width: 60px;
      height: 60px;
      ${FlexCenter};
      img {
        width: 42px;
        height: 58px;
        border-radius: 5px;
        object-fit: cover;
      }
    }
    .coach-meal-remaining {
      width: 42px;
      height: 58px;
      background: #F6F5F8;
      color: #51635D;
      border-radius: 5px;
      font-size: 12px;
      line-height: 14px;
      ${FlexCenter}; 
    }
  }
  .add-cart-wrapper {
    padding-right: 14px;
    display: flex;
    justify-content: flex-end;
    button {
      width: 148px;
      height: 40px;
      margin-bottom: 15px;
      background: #FFFFFF;
      color: #51635D;
      border: 1px solid #97DC76 !important;
      border-radius: 21px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      ${FlexCenter};
    }
    button: disabled{
      background-color: #e0e3e0;
      color: #949d9b;
      cursor: not-allowed;
    }
  }
  @media(min-width: 992px) {
    width: 60%;
    margin: 0 auto 24px;
  }
`;

export default function coachAddedMeals({ coachAddMeals,setIsCartLoading, setIsCartloaded }) {
  // const loadCart = JSON.parse(localStorage.getItem('loadCart'));
  const { loadCart } = useShopContext();
  const coachAddMealsClone = loadCart?.mealData?.length >0 ? [...loadCart?.mealData] : [];
  const fourFirstCoachMeals = coachAddMealsClone.splice(0, 4);
  const {loadClientCart} = SetClientCart();
  const [updateClientCartApi] = useRequest('meal/updateClientCart', 'post');
  const [isAddToCartBtnDisabled, setIsAddToCartBtnDisabled] = useState(false);
  const coachMeal = (meal) => (
    <div key={meal?.id} className="meal-wrapper">
      <img src={meal?.img ? meal?.img : `/images/dish.png`} alt="Meal Img" />
    </div>
  );

  const addCoachMealsInCart = () => {
    loadClientCart();
    if(loadCart?.clientCartId){
    setIsAddToCartBtnDisabled(true)
    localStorage.setItem("loadCart", "{}");
    setIsCartLoading(true);
    updateClientCartApi({body: 
      {
        clientCartId: loadCart?.clientCartId
      }
    })
    .then((res) => {
      console.log(res);
      setIsCartLoading(false);
      setIsCartloaded(false);
    })
    .catch((err) => {
      console.log(err);
      setIsCartLoading(false);
    });
    }
  };


  return (
    <CoachAddedMealsWrapper>
      <div className="coach-meals-title">Coach added Meals</div>
      <div
        className={classNames('coach-meals-content', { moreFourMeals: loadCart?.mealData?.length > 4 })}
      >
        {loadCart?.mealData?.length > 4 ? (
          <>
            {fourFirstCoachMeals.map((meal) => coachMeal(meal))}
            <div className="meal-wrapper">
              <div className="coach-meal-remaining">+ {loadCart?.mealData?.length - 4}</div>
            </div>
          </>
        ) : (
          loadCart?.mealData?.map((meal) => coachMeal(meal))
        )}
      </div>
      <div className="add-cart-wrapper">
        <button onClick={addCoachMealsInCart} disabled={isAddToCartBtnDisabled}>Add to Cart</button>
      </div>
    </CoachAddedMealsWrapper>
  );
}