import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const DeliveryDatesWrapper = styled.div`
  position: relative;
  .select-wrapper {
    position: relative;
    margin: 0;
    display: flex;
    align-items: center;
    .select-label {
      margin: 0 16px 0 0;
      color: ${() => theme.colors.primaryDarker};
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      flex-shrink: 0;
    }
    .selected-value {
      width: 150px;
      min-width: 150px;
      height: 42px;
      padding: 0 16px;
      background: #f5f7f8;
      color: ${() => theme.colors.primaryDarker};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border: 0;
      border-radius: 90px;
      gap: 12px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .options-dropdown {
    top: 47px;
    right: -16px;
    width: 166px;
    min-width: 166px;
    max-height: 209px;
    margin-top: 0;
    padding: 8px 0 0 0;
    background: ${() => theme.colors.secondary};
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    .valueSelected,
    .valueSelected:hover {
      background-color: #f5f7f8;
      font-family: ${() => theme.typography.fontGilroy};
    }
    .select-option {
      font-weight: 500;
    }
  }

  @media (max-width: 1439px) {
    display: none;
  }
`;
