import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useRequest } from 'hooks/useRequest';
import produce from "immer";
import Select from 'react-select';

const GooglePlacesAutocompleteStyle = styled.div`
  .css-9ekey3-singleValue {
    padding: 0 35px 0 0;
  }

  .css-yk16xz-control .css-1hwfws3,
  .css-1pahdxg-control .css-1hwfws3 {
    padding: 0 30px 0 14px !important;
  }
  .css-yk16xz-control,
  .css-1hwfws3,
  .css-1pahdxg-control {
    height: 44px;
    line-height: 44px !important;
    div {
      height: 44px;
      line-height: 44px;
    }
    input {
      position: relative;
      top: -7px;
      height: 44px;
      background-color: transparent;
    }
    .css-8sibub-placeholder {
      top: 20px;
      line-height: 44px;
    }
    .css-eeok4o {
      top: 50%;
      height: 44px;
      margin-top: 0;
      padding: 0 0 0 25px;
      line-height: 44px;
    }
  }
  .css-1pahdxg-control {
    height: 44px;
    border: 1px solid #52c41a;
    box-shadow: none;
    &:hover {
      border-color: #52c41a;
    }
  }
  .css-26l3qy-menu {
    postion: relative;
    top: 43px;
    bottom: auto;
    z-index: 4;
    box-shadow: none;

    & > div > div {
      position: relative;
      padding-left: 25px;

      &::before{
        content: '';
        background-image: url('/icons/location.svg');
        width: 18px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 5px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &:first-child {
        background-color: rgba(0,0,0,0);
        color: #000;
      }
      &:hover {
        background-color: rgba(0,0,0,0);
      }
    }
  }

  .css-1l0hnm3-placeholder{
    width: 82%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  @media(max-width: 991px){
    .css-26l3qy-menu {
      overflow:scroll;
      max-height: 120px;
    }
  }
`;

const AddressWrap = styled.div`

  margin-top: 32px;

  .address-wrapper{
    margin-bottom: 124px;

    &.margin-btm{
      margin-bottom: 200px;
    }

    @supports  (selector(:nth-child(1 of x))) or (-webkit-touch-callout: none) {
      margin-bottom: 180px;
      &.margin-btm{
        margin-bottom: 250px;
      }
    }
  }

  .address-container{
    position: relative;

    .title{
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #51635D;
        text-align: left;
        margin-bottom: 12px;
    }

    .locationIcon{
        position: absolute;
        right: 0px;
        z-index:1;
        width: 14px;
        height:14px;
        bottom: 15px;
        right: 16px;
    }

    .css-1l0hnm3-placeholder{
        padding-left: 0;
        font-size: 14px;
        color: hsl(0, 0%, 20%);
    }
  }

  .css-9ekey3-singleValue{
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .button-wrapper{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 24px 0;
    background-color: #fff;
    z-index: 9;
    border-top: 1px solid #F6F5F8;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    button{
        max-width: 290px;
        width: 100%;
        height: 40px;
        border-radius: 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #FFFFFF;
        background-color: #52C41A;
    }
  }

  .pac-container{
    border: 1px solid red;
  }

  .address_select{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;

    .select__control{
      height: 44px;
    }

    .select__indicators{
      margin-right: 10px;
    }

    .select__control--is-focused{
      border: 1px solid #52C41A;
      box-shadow: unset;
    }

    .select__menu{
      border: 0px;
      //box-shadow: unset;
      max-height:120px;
      overflow:auto;
    }
    .select__menu-list{
      max-height:120px;
      overflow:auto;
    }
    .select__placeholder{
      text-transform: none;
    }
  }

`

export function AddressWrapper({ setFocus, userDeliveryAddress, clientId, setProviderModal, updateSettings, days, meals, setMealsModal, setUserAddressLatLong, setZip,clientName }) {

  const [value, setValue] = useState(null);
  const [saveDeliveryAddress, { isLoading }] = useRequest("user-delivery-address/", "post");
  const [newAddress, setNewAddress] = useState(null);
  const [autoplaces, setAutoplaces] = useState(false);
  const [userAddress, setUserAddress] = useState({});
  const [typedValue, setTypedValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [addresstype, setAddresstype] = useState(false);
  const dropdownValues = [{value:'new',label:'Enter a new address',address:null}];
  if (userAddress?.length > 0) [
    userAddress?.map((item) => {
      dropdownValues.push({ value: item.address.address.label, label:item.type==='other' ? item.name : item.type ,address:item });
    }),
    dropdownValues.push(dropdownValues.shift())
  ]

  const handleClick = async () => {
    if (value) {
      await saveUserDeliveryAddress()
    }
    updateSettings(
      produce((draft) => {
        draft.days = days;
        draft.meals = meals;
      }),
    );
    setProviderModal(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {

    if (userDeliveryAddress?.length) {
      setUserAddress(userDeliveryAddress);
      setNewAddress(userDeliveryAddress[0].address.address);
      setAddresstype(true);
    }

  }, [])



  const saveUserDeliveryAddress = async () => {
    await saveDeliveryAddress({ body: { address: value, type: "home", clientId } })
      .then((result) => {
        console.log(result)
      }).catch((err) => {
        console.log(err)
      })
  }

  const handleGooglePlacesClick = () => {
    setAutoplaces(true);
    setFocus(true);
  }

  const handleGooglePlacesBlur = () => {
    setAutoplaces(false);
    setFocus(false);
  }

  const DropdownIndicator = (props) => {
    return (
      typedValue ? <img className='image' onClick={() => { setSelectedValue('') }} style={{ width: '16px' }} src='/icons/close-cross.svg' /> : <img className='image' src='/icons/SelectArrow.svg' />
    )
  };


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#51635D' : "#51635D",
      backgroundColor: state.isFocused ? "#ffffff" : "#ffffff",
      textAlign: 'left',
      fontSize: '14px',
    })
  }

  const selectValue = (e) => {
    setTypedValue(e);
  }

  const handleSelectChange = (e) => {
    if(e?.label==='Enter a new address'){
      setAddresstype(false);
      setNewAddress(null);
    }
    else{
    setSelectedValue(e?.label);
    setNewAddress(e?.address?.address?.address)
    }
  }

  const handleSelect = async (newAddress) => {
    const [place] = await geocodeByPlaceId(newAddress.value.place_id);
    const userLatLong = await getLatLng(place);
    setUserAddressLatLong(userLatLong);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) || {};
    const { long_name: state = '' } =
      place.address_components.find((c) => c.types.includes('administrative_area_level_1')) || {};
    const { long_name: city = '' } =
      place.address_components.find((c) => c.types.includes('locality')) ||
      place.address_components.find((c) => c.types.includes('sublocality_level_1')) ||
      {};
    setZip(postalCode);
  };

  useEffect(() => {
    if(newAddress){
       newAddress && handleSelect(newAddress);
    }
    if(value){
      value && handleSelect(value);
    }
  }, [newAddress,value]);

  return (
    <>
      <AddressWrap>
        <div className={`address-wrapper ${autoplaces && 'margin-btm'}`}>
          <div className="address-container">
            <div className="title">Where's it arriving?</div>

            {addresstype ?
              <Select
                className="address_select"
                classNamePrefix="select"
                isSearchable={true}
                options={dropdownValues}
                placeholder={`${clientName?.length>12 ? clientName.substring(0, 12) + "..." : clientName}’s delivery address`}
                styles={customStyles}
                onBlur={handleGooglePlacesBlur}
                onFocus={handleGooglePlacesClick}
                onInputChange={selectValue}
                onChange={handleSelectChange}
                name="Address"
                value={dropdownValues.filter(obj => obj.label === selectedValue)}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
              />
              :
              <>
             {value ? <img src="/icons/close-cross.svg" className="locationIcon" alt="close" onClick={()=>setValue(null)}/> : <img src="/icons/location.svg" className="locationIcon" alt="" />}
                <GooglePlacesAutocompleteStyle >
                  <GooglePlacesAutocomplete
                    GooglePlacesDetailsQuery={{ fields: 'geometry' }}
                    fetchDetails={true}
                    selectProps={{
                      value,
                      placeholder: `${clientName?.length>12 ? clientName.substring(0, 12) + "..." : clientName}’s delivery address`,
                      noOptionsMessage: () => null,
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          height: '44px',
                          lineHeight: '44px',
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: 'none',
                          height: '47px',
                          lineHeight: '47px',
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          height: '47px',
                          lineHeight: '47px',
                          display: 'none',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          height: '47px',
                          lineHeight: '40px',
                          top: '50%',
                          paddingLeft: '15px',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          top: '50%',
                          marginTop: '0px',
                          paddingLeft: '15px',
                          height: '47px',
                          lineHeight: '37px',
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: '#51635D',
                          fontSize: '14px',
                          lineHeight: '16px',
                          fontWeight: '700',
                          textAlign: 'left'
                        })
                      },
                      onChange: setValue,
                      onFocus: handleGooglePlacesClick,
                      onBlur: handleGooglePlacesBlur
                    }}
                  />
                </GooglePlacesAutocompleteStyle>
              </>
            }
          </div>
        </div>
        <div className="button-wrapper">
          <button
            className={classNames({
              disabled: !selectedValue && !value,
            })}
            disabled={!selectedValue && !value}
            onClick={handleClick}
          >
            Next
          </button>
        </div>
      </AddressWrap>
    </>
  )
}
