import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { CgMathMinus, CgMathPlus, CgMathPercent } from 'react-icons/cg';
import Select from 'components/Select';
import CustomizePal from 'components/Nutritionist/CustomizePal';
import MacronutrientBreakdown from 'components/Nutritionist/MacronutrientBreakdown';
import { useRequest } from 'hooks/useRequest';
import SuccessComponent from 'components/SuccessComponent';
import { BMR_FORMULAS, BMR_FORMULA_VARS } from '../../constants';
import { useNutritionistContext } from '../../context/nutritionistContext';
import Progress from 'components/Progress';
import { useHistory } from 'react-router-dom';
import { set } from 'lodash';
import { DEFAULT_ACTIVITY } from '../../constants';

const flexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NutritionistMetricStyle = styled.div`
  .formula-wrapper {
    justify-content: space-between;

    .form-group {
      width: 30%;
    }
  }

  .selected-value {
    font-size: 16px;
  }

  .title-wrapper {
    margin-bottom: 30px;
    ${flexBetween};

    .title {
      color: #000;
      font-size: 20px;
    }
  }

  .target-calories-wrapper {
    flex-direction: column;

    .btn-switcher div.btns-wrapper {
      width: 210px;

      .switcher:after {
        content: 'Percentage';
        width: 105px;
        font-size: 16px;
        line-height: 28px;
      }

      .checkbox:checked + .switcher:after {
        content: 'Number';
        left: 100px;
        line-height: 28px;
      }

      .layer:before {
        content: 'Number';
        right: 10px;
        font-size: 16px;
      }

      .layer:after {
        content: 'Percentage';
        left: 10px;
        font-size: 16px;
      }
    }

    .form-group-wrapper {
      ${flexBetween};
    }

    .form-control {
      display: flex;
      align-items: center;
    }

    .math2 {
      width: 50px;
      height: 43px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;
      cursor: pointer;
      ${flexCenter};

      svg {
        color: #939b99;
        font-size: 20px;
      }

    }

    .math {
      width: 56px;
      height: 43px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;
      cursor: pointer;
      ${flexCenter};

      svg {
        color: #939b99;
        font-size: 20px;
      }

      &:hover,
      &:active,
      &.active {
        border-color: #52c41a;

        svg {
          color: #52c41a;
        }
      }
    }

    .math + input {
      width: 136px;
      margin: 0 12px;
      font-size: 18px;
      text-align: center;
    }

    .form-group {
      width: 100%;
    }

    .updated-calories {
      width: 40%;

      input {
        width: 100%;
        min-width: 200px;
        font-size: 18px;
      }
    }
  }

  .form-section-wrapper {
    margin-bottom: 41px;

    .section-title {
      margin-bottom: 25px;
      color: #000;
      font-size: 20px;
    }

    .icons-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .icon-container {
      width: 115px;
      height: 92px;
      margin: 0 10px 10px 0;
      border: 1.5px solid transparent;
      border-radius: 6px;
      flex-direction: column;
      cursor: pointer;
      background: #ffffff;
      ${flexCenter};

      img {
        width: 45px;
        height: 45px;
        margin-bottom: 11px;
      }

      span {
        font-size: 14px;
      }

      &.active {
        background-color: #effaef;
        border: 1.5px solid #52c41a;
      }
    }
    textarea {
      width: 100%;
      height: 168px;
      padding: 17px 19px;
      border: 1px solid #c3cbc9;
      border-radius: 6px;
      font-size: 18px;

      ::placeholder {
        font-size: 18px;
      }
    }
  }

   .plan-btn{
    button: disabled {
    background: #dddddd;
    cursor:not-allowed;
  }
    }

  @media (max-width: 767px) {
    .formula-wrapper.form-section {
      margin-bottom: 40px;

      .form-group {
        margin-bottom: 0;
      }

      .form-group:last-child {
        margin-top: 24px;
      }
    }

    .target-calories-wrapper {
      margin-bottom: 40px;

      .form-group-wrapper .form-group {
        margin-bottom: 0;
        align-items: center;
      }

      .updated-calories {
        margin-top: 15px;
      }

      .title-wrapper {
        margin-bottom: 10px;
        flex-wrap: wrap;

        .title {
          margin-bottom: 15px;
          font-size: 18px;
        }
      }

      .form-group-wrapper {
        flex-direction: column;

        .form-control {
          justify-content: center;
        }
      }
    }

    .form-section-wrapper {
      .section-title {
        margin-bottom: 16px;
        font-size: 18px;
      }

      .icons-wrapper {
        justify-content: space-around;
      }

      textarea {
        padding: 10px;

        ::placeholder {
          font-size: 16px;
        }
      }
    }
}
`;
const foodAvoid = [
  { id: 'GLUTEN', label: 'GLUTEN', image: 'gluten.svg' },
  { id: 'PEANUTS', label: 'PEANUTS', image: 'peanut.svg' },
  { id: 'TREE NUTS', label: 'TREE NUTS', image: 'tree nuts.svg' },
  { id: 'CELERY', label: 'CELERY', image: 'celery.svg' },
  { id: 'MUSTARD', label: 'MUSTARD', image: 'mustard.svg' },
  { id: 'EGGS', label: 'EGGS', image: 'Eggs.svg' },
  { id: 'MILK', label: 'MILK', image: 'milk.svg' },
  { id: 'SESAME', label: 'SESAME', image: 'sesame.svg' },
  { id: 'FISH', label: 'FISH', image: 'fish.svg' },
  { id: 'MOLLUSCS', label: 'MOLLUSCS', image: 'molluscs.svg' },
  { id: 'SOY', label: 'SOY', image: 'soy.svg' },
  { id: 'SULPHITES', label: 'SULPHITES', image: 'sulphites.svg' },
  { id: 'CRUSTACEANS', label: 'CRUSTACEANS', image: 'crustaceans.svg' },
  { id: 'LUPIN', label: 'LUPIN', image: 'lupin.svg' },
];

let isLoading = true;
const defaultBMRFormula = {
  energyFormula: 'PAL (Physical Activity Level)',
  targetCaloriesFormula: 'BMR * PAL',
  activityType: 'PAL',
};
export default function NutritionistMetric({
  form,
  setForm,
  dataId,
  handleSubmit,
  success,
  useCustom,
  setUseCustom,
  customizeValue,
  setCustomizeValue,
}) {
  const [isFoodSelected, setIsFoodSelected] = useState([]);
  const { energyFormula, targetCaloriesFormula, activityType } =
    BMR_FORMULA_VARS[form.BMR_formula] || defaultBMRFormula;
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const handleCountChange = (e) => {
    if(e.target.value > 0){
      setForm({ ...form, goal: (form.goal_type === "percent" ? (e.target.value > 40 ? 40 : e.target.value) : (e.target.value > 1500 ? 1500 : e.target.value)), type: "bulkup"})
    }else if(e.target.value < 0){
      setForm({ ...form, goal: (form.goal_type === "percent" ? (e.target.value < -40 ? -40 : e.target.value) : (e.target.value < -1500 ? -1500 : e.target.value)), type: "lose-weight"})
    }else{
      setForm({ ...form, goal: e.target.value, type: "maintain" })
    }
  };
  
  useEffect(() => {
    return () => {
      isLoading = true;
    };
  }, []);

  useEffect(()=> {
    handleCountChange({ target: { value: form.goal } });
  }, [form.goal])
  
  useEffect(() => {
    if (!!form?.food_to_avoid?.length && isLoading) {
      // setIsFoodSelected((prev) => [...prev, ...form?.food_to_avoid]);
      // form?.food_to_avoid.map((item) => {
      //   isFoodSelected.push(item.toLowerCase());
      // })
      isLoading = false;
    }
    if (success) {
      setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const createdPlanId = params.get('createdPlanId');
        if(!createdPlanId){history.push('/nutritionist/meal-plan');}
      }, 3000);
    }
  }, [form?.food_to_avoid, success]);

  useEffect(()=>{
    if(form.duration == 0 || form.duration === ''|| !form.name){
      setDisable(true)
    }
    else{
      setDisable(false)
    }
  },[form.duration,form.name])

  const foodSelected = (item) => {
    const itemIndex = isFoodSelected.indexOf(item)
      if (itemIndex < 0) {
        setIsFoodSelected([...isFoodSelected, item]);
        setForm({...form,food_to_avoid:[...isFoodSelected,item.toLowerCase()]})
      } else {
        setIsFoodSelected(isFoodSelected.filter((food) => food !== item));
        setForm({
          ...form,
          food_to_avoid: isFoodSelected.filter((food) => food !== item),
        });
    }}
  const [postData, { isLoading: isInLoading }] = useRequest('dietplan/create', 'post');
  const [updateDietPlan, { isLoading: isInProgress }] = useRequest(
    `dietplan/update/${dataId}`,
    'put',
  );

  const handleUpdateForm = (e) => {
    let PAL_formula, defaultActivity;
    if(e === "instituteOfMedicine"){
      PAL_formula = "PA";
      defaultActivity = DEFAULT_ACTIVITY["PA"];
    } else {
      PAL_formula = "PAL";
      defaultActivity = DEFAULT_ACTIVITY["PAL"];
    }
    setForm({...form, BMR_formula: e, PAL_formula, PAL: defaultActivity});
    setCustomizeValue(defaultActivity);
    setUseCustom(false);
  }

  return (
    <NutritionistMetricStyle>
      <div className="form-section formula-wrapper">
        <div className="form-group">
          <label>BMR Formula</label>
          <Select
            key="BMR"
            options={BMR_FORMULAS}
            updateValue={handleUpdateForm}
            defaultSelected={form.BMR_formula}
          />
        </div>
        <div className="form-group">
          <label>Energy Formula</label>
          <input type="text" value={energyFormula} readOnly />
        </div>
        <div className="form-group">
          <label>Target Calories Formula</label>
          <input type="text" value={targetCaloriesFormula} readOnly />
        </div>
      </div>
      <CustomizePal
        form={form}
        setForm={setForm}
        customizeValue={customizeValue}
        setCustomizeValue={setCustomizeValue}
        useCustom={useCustom}
        setUseCustom={setUseCustom}
        activityType={activityType}
      />
      <div className="form-section target-calories-wrapper">
        <div className="title-wrapper">
          <div className="title">Customize Target Calories</div>
          <div className="btn-switcher">
            <div className="btns-wrapper">
              <input
                type="checkbox"
                className="checkbox"
                onChange={(e) => {
                  setForm({
                    ...form,
                    goal_type: e.target.checked ? 'number' : 'percent',
                  });
                }}
                checked={form.goal_type == 'number'}
              />
              <div className="switcher"></div>
              <div className="layer"></div>
            </div>
          </div>
        </div>
        <div className="form-group-wrapper">
          <div className="form-group">
            <label>Customize</label>
            <div className="form-control">
              <div
                className="math"
                onClick={() => setForm({ ...form, goal: Number(form.goal) - 1 })}
              >
                <CgMathMinus />
              </div>
              <input
                type="number"
                autoComplete="off"
                value={form.goal}
                onChange={handleCountChange}
              />
              {form.goal_type=='percent' &&
                <div className="math2"><CgMathPercent /></div>
              }
              <div
                className="math"
                onClick={() => setForm({ ...form, goal: Number(form.goal) + 1 })}
              >
                <CgMathPlus />
              </div>
            </div>
          </div>
          <div className="form-group-wrapper updated-calories">
            <div className="form-group">
              <label>Updated Calories</label>
              <input
                type="text"
                value={
                  form.goal_type == 'number'
                    ? `${targetCaloriesFormula} ${
                        form.goal_type == 'percent'
                          ? Number(form.goal) - 100 > 0
                            ? Number(form.goal) - 100
                            : 100
                          : Number(form.goal) >=0
                          ? '+'
                          : ' - '
                      } ${Math.abs(
                        // form.goal_type == 'percent' ? Number(form.goal) - 100 : Number(form.goal),
                         Number(form.goal),
                      )}`
                    :`${
                        (form.goal_type == 'percent'
                          ? Number(form.goal)  < 100
                            ? ((Number(form.goal) /100)+1).toFixed(2)
                            : Number(form.goal) /100
                          : (((Number(form.goal)) -100)/100 + 1).toFixed(2)
                          )
                      } * ${targetCaloriesFormula}`
                }
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <MacronutrientBreakdown form={form} setForm={setForm} />
      <div className="form-section-wrapper">
        <div className="section-title">Food to avoid</div>
        <div className="icons-wrapper">
          {foodAvoid.map((food) => (
            <div
              className={classNames('icon-container', {
                active: isFoodSelected.indexOf((food.id).toLowerCase()) > -1,
              })}
              key={food.id}
              onClick={() => foodSelected(food.id.toLowerCase())}
            >
              <img src={`/icons/tags/${food.image}`} alt="Food" />
              <span>{food.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="form-section-wrapper">
        <div className="section-title">Tips</div>
        <textarea
          placeholder="Leave some tips to your clients"
          className="tips-area"
          onChange={(e) => setForm({ ...form, tips: e.target.value })}
          value={form?.tips}
        />
      </div>
      <div className="plan-btn">
        <button
          type="button"
          onClick={() => handleSubmit() && setDisable(true)}
          disabled={disable}
        >
          {isInProgress ||
            (form.name.length > 0 && isInLoading
              ? 'Saving...'
              : form?.id
              ? 'Update Plan'
              : 'Create Plan')}
        </button>
      </div>
      {(isInProgress || (form.name.length > 0 && isInLoading)) && <Progress />}
      {success && (
        <SuccessComponent
          style={{ marginBottom: '10px' }}
          message={dataId ? 'Diet Plan updated Successfully' : 'Diet Plan created Successfully'}
        />
      )}
    </NutritionistMetricStyle>
  );
}



