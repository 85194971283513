import styled from 'styled-components';
import { useHistory ,useLocation} from 'react-router-dom';
import { useAppContext } from 'context/appContext';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as ArrowLeft } from 'assets/shop/chevronLeft.svg';
import RegularInput from 'components/Input/RegularInput';
import { GilroyFontWrapper } from 'shared/theme/style';
import { ReactComponent as ArrowRight } from 'assets/shop/chevron-right.svg';
import { Button } from 'components/elements/button/Button';
import { useRequest } from 'hooks/useRequest';
import { Link } from 'react-router-dom';
import { setFavoriteKitchen } from 'utils/setFavoriteKitchen';

const SignInStyled = styled.div`
width: 100vw;
height: 100vh;
z-index: 99;
position: fixed;
background: #F0F2F4;
inset: 0;
display: flex;
justify-content: center;
align-items: center;
.modal {
  max-width: 536px;
  width: 100%;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
  padding: 58px 48px 48px 48px;
  display: flex;
  flex-direction: column;
   .heading{
     display: flex;
     flex-direction: column;
     gap: 12px;
    .title{
      color: #000A2C;
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .desc{
      color: #757E89;
      font-family: Gilroy;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
   }
   .password-recommendation{
    height: 59px;
    margin-top: 14px;
    color: var(--grey-2, #949D9B);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    &.error{
      color: #EF6F64;
   }
  ul{
    margin: 0 !Important;
    padding-left: 26px;
  }

   }
   .forget-password-wrapper{
    width: 100%;
    padding-top: 14px;
      a{
      color: #00092D;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline !important;
    }
  } 

   .button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 41px;
    button{
        span{
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
      :disabled {
        background-color: #F0F2F4 !Important;
        color: #9CA3AD !Important;
      }
    }
    .btn{
        height: 42px;
        width:124px;  
        border-radius: 21px;  
    }
  }
  .first-button{
    width: 103px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid #E0E4E8;
    background: #FFF;
    display: flex;
    height: 42px;
    padding: 13px 24px 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
      .icon{
        top: 2px;
      }
    }
}

`
const FormGroupStyle = styled.div`
  margin-top: 14px;
  width: 100%;
  display:flex;
  flex-direction: column;
  gap: 14px;
  label {
    color: #00092D;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    display: inline-block;
  }
  input {
    height: 44px;
    border: 1px solid #e0e3e0;
    border: ${({passwordNotMet}) =>  passwordNotMet ? '#FEC8B4' : '' };
    background: ${({passwordNotMet}) =>  passwordNotMet ? '#FEF1F0' : '' };
    :hover, :focus {
      border-color: #00092d !important;
    }
    :focus::placeholder {
      color: transparent;
    }
    ::placeholder {
      font-family: Gilroy;
      color: #AFB5B4;
      font-style: normal;
      font-weight: 500;
    }
  }
  .value {
    font-size: ${({ theme }) => theme?.typography?.fontRegular};
    font-weight: 500;
    line-height: 20px;
    color: #000A2C;
  }
`;

export default function PasswordForm() {
  const history = useHistory();
  const { setDesktopOnboardingUser, desktopOnboardingUser, afterLogin, isMobile, loadPantryMeals, getdiaryData, TimeZone, setLogin, setSelectedValue, setProfile} = useAppContext();
  const [login, { isLoading, error, setError }] = useRequest('auth/login', 'post');
  const [registerUser, {isLoading: isRegister}] = useRequest('visitor/register', 'post');
  const [saveDeliveryAddress]=useRequest("user-delivery-address/","post");
  const [passwordNotMet, setPasswordNotMet] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const lastUrl = history?.location?.state?.from;
  const location = useLocation();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  function isPasswordValid(password) {
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  }
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setError('');
    setPasswordNotMet(false);
    setDesktopOnboardingUser({
      ...desktopOnboardingUser,
      [name]: value,
    });
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();

    if (lastUrl === '/account/home') {
      // user exist
      const queryParams = new URLSearchParams(location.search);
      const email = queryParams.get('email');

      await login({
        body: { email: email, password: desktopOnboardingUser?.password, TimeZone },
      })
        .then(async (loginRes) => {
          if (window.clarity) {
            window.clarity('identify', loginRes?.user?.email);
          }
          if (loginRes?.user?.type?.includes('user') || loginRes?.user?.type?.includes('visitor')) {
            localStorage.setItem('user', JSON.stringify(loginRes.token));
            const deliveryAddress = localStorage.getItem('visitorDeliveryAddress');
            if (deliveryAddress && !isMobile) {
              const parsedAddress = JSON.parse(deliveryAddress);
              const response = await saveDeliveryAddress({ body: { ...parsedAddress } });
              setSelectedValue(response?.data?.id);
              localStorage.removeItem('visitorDeliveryAddress');
            }
            if (loginRes?.user?.type?.includes('user')) {
              isMobile && (await getdiaryData());
              await loadPantryMeals().then((response) => {
                afterLogin(loginRes);
                setLogin(true);
              });
            } else {
              afterLogin(loginRes);
              setLogin(true);
            }
          } else {
            afterLogin(loginRes);
            setLogin(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (isPasswordValid(desktopOnboardingUser?.password)) {
        await registerUser({
          body: {
            ...(({ code, ...rest }) => rest)(desktopOnboardingUser),
            TimeZone,
          },
        })
          .then(async (data) => {
            const deliveryAddress = localStorage.getItem('visitorDeliveryAddress');
            if (deliveryAddress) {
              const parsedAddress = JSON.parse(deliveryAddress);
              const { token } = data;
              localStorage.setItem('user', JSON.stringify(token));
              await saveDeliveryAddress({ body: { ...parsedAddress } });
              afterLogin(data);
              localStorage.removeItem('visitorDeliveryAddress');
              history.push('/shop/checkout/order');
            } else {
              afterLogin(data);
            }
            const username = sessionStorage.getItem('kitchenUsername');
            if (username) {
              const response = await setFavoriteKitchen(data?.token?.token, username);
              if (response?.data?.success && response?.data) {
                setProfile((prev) => ({
                  ...prev,
                  favoriteKitchenData: response?.data?.favoriteKitchenData,
                }));
              }
            }
          })
          .catch((e) => {
            toast.error(e);
          });

        localStorage.setItem('steps', 'true');
        window.scrollTo(0, 0);
      } else {
        setPasswordNotMet(true);
      }
    }
    sessionStorage.removeItem('storeUrl');
    sessionStorage.removeItem('redirectedFrom');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick(e);
    }
  };


  return (
      <GilroyFontWrapper>
        <SignInStyled>
          <div className='modal'>
            <div className='heading'>
              <h1 className='title'>{(lastUrl !== '/account/home' && "/signup") ? "Create your password" : "Input your password"}</h1>
            </div>
            <FormGroupStyle passwordNotMet={passwordNotMet}>
              <label>Password</label>
              <RegularInput
                type="password"
                name="password"
                placeholder="Password"
                icon={!passwordVisible ? '/icons/eye-slash.svg' : '/icons/eye.svg'}
                onSuffixClick={togglePasswordVisibility}
                passwordVisible={passwordVisible}
                value={desktopOnboardingUser?.password}
                errorMessage={error}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                autoFocus
              />
            </FormGroupStyle>
            {error &&
          <div className="forget-password-wrapper">
            <Link to="/forgot-password">Forgot password</Link> <br />
          </div>
            }
            <div class={`password-recommendation ${passwordNotMet ? "error" : ''}`}>
              {lastUrl !== '/account/home' &&
                <>
                  <p>We recommend choosing a password that:</p>
                  <ul>
                    <li>Is 8 characters long or more</li>
                    <li>Uses numbers (0-9) and special symbols (!#@$...)</li>
                  </ul>
                </>
              }

            </div>
            <div className={`button`}>
              <Button type={'secondary'} title="Back" iconLeft={<ArrowLeft />} onClick={() => history.goBack()} className="first-button" />
              <Button type={'dark'} title="Continue" iconRight={<ArrowRight />} onClick={handleButtonClick} isDisabled={desktopOnboardingUser?.password === '' || isLoading || isRegister || !desktopOnboardingUser?.password} />
            </div>
          </div>
        </SignInStyled>
      </GilroyFontWrapper>
  );
}

