import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const FlexBetween = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentBreakdownWrapper = styled.div`
  .section-title {
    margin-bottom: 20px;
  }
  .payment-items-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .payment-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    ${FlexBetween};
    .title {
      color: ${theme.colors.primaryDark};
    }
    .info {
      color: ${theme.colors.primaryDarker};
    }
    .repeat-order-discount {
      color: ${theme.colors.success};
    }
  }
  .divider {
    margin: 16px 0;
    border-bottom: 1px solid ${theme.colors.mediumGrey};
    opacity: 0.1;
  }
  .total-payment {
    color: ${theme.colors.deepForestGreen};
    font-family: ${theme.typography.fontGilroy};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    ${FlexBetween};
  }
`;
