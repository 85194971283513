import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenOurMissionWrapper = styled.div`
  .our-mission-header {
    padding: 22px 0 19px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
  }

  .mission-image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    img {
      width: 123px;
      height: 123px;
    }
  }

  .mission-title {
    color: ${() => theme?.colors.black};
    font-family: 'Bebas Neue';
    font-size: 32px;
    font-weight: 400;
    line-height: 24px; /* 75% */
  }

  .our-mission-desc {
    padding: 27px 0 23px;
    p {
      width: 265px;
      margin: 0 auto;
      text-align: center;
      color: ${() => theme?.colors.black};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;
