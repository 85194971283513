import styled from 'styled-components';

const MealCardStyle = styled.div`
    background-color: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    min-height: 88px;
    display: flex;
    align-items: center;
    padding: 12px 32px 12px 24px;
    margin-bottom: 6px;
    .image{
        position: relative;
    }
    .meal_img{
        width: 64px;
        height: 64px;
        margin-right: 14px;
        object-fit: contain;
        display: block;
    }
    .meal_name{
        font-size: 14px;
        line-height: 16px;
        color: #51635D;
        flex: 1;
    }
    .qty{
        font-size: 14px;
        line-height: 16px;
        color: #949D9B;
        width: 13%;
    }
    .amount{
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #51635D;
        width: 13%;
        text-align: right;
        word-break: break-all;
    }
    @media (max-width: 991px){
        padding: 12.5px 14px;
        align-items: flex-start;
        .meal_name{
            color: #0B1D17;
            margin-top: 5px;
            margin-right: 10px;
        }
        .meal_img{
            margin-right: 10px;
            width: 60px;
        }
        .qty{
            position: absolute;
            font-weight: 700;
            font-size: 8px;
            line-height: 18px;
            color: #51635D;
            width: 20px;
            height: 20px;
            background: #FFFFFF;
            border: 1px solid #52C41A;
            border-radius: 10px;
            text-align: center;
            left: 0;
            bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }
        .amount{
            margin-top: 5px;
            width: auto;
        }
    }
`

export default function MealCard({isMobile, img, name, qty, price}){

    return(
        <MealCardStyle>
            <div className='image'>
                <img src={img || '/images/dish.png'} className='meal_img'/>
                {isMobile && <span className='qty'>{qty}</span>}
            </div>
            <h2 className='meal_name'>{name}</h2>
            {!isMobile && <p className='qty'>x {qty}</p>}
            <h3 className='amount'>${parseFloat(price * qty).toFixed(2)}</h3>
        </MealCardStyle>
    )
}