import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const FavoriteKitchenCategoriesWrapper = styled.div`
  padding: 14px 0 19px;
  display: flex;
  flex-direction: column;
  gap: 45px;

  .kitchen-categories-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .kitchen-category-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .category-title-wrapper {
    padding: 0 16px;
    color: ${() => theme.colors.primaryDarker};
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .category-title {
      font-size: 18px;
      font-weight: 600;
    }
    .total-meals {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .category-meals-wrapper {
    overflow-x: auto;
    padding: 10px 16px;
    border-radius: 10px;
    display: flex;
    gap: 12px;
    &::-webkit-scrollbar {
      height: 0;
      display: none;
    }
  }

  .meal-card-container {
    width: 232px;
    flex-shrink: 0;
    .meal-img-wrapper {
      height: 130px;
    }
    .calories-wrapper {
      display: none;
    }
    .meal-details {
      .title {
        margin-bottom: 12px;
        color: ${() => theme.colors.primaryDefault};
      }
      .tags-wrapper {
        display: none;
      }
      .cart-wrapper {
        align-items: center;
      }
      .add-cart {
        .add-icon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .full-menu-btn-wrapper {
    display: flex;
    justify-content: center;
    .btn.secondary {
      width: 250px;
      height: 42px;
      padding: 0;
      color: ${() => theme.colors.primaryDarker};
      border-color: ${() => theme.colors.grayDarker} !important;
      border-radius: 30px;
      font-family: ${theme.typography.fontGilroy};
      font-size: 16px;
      font-weight: 700;
    }
  }

  @media (min-width: 992px) {
    padding: 32px 0 48px;
    gap: 32px;

    .kitchen-category-container {
      gap: 14px;
    }

    .lessMeal .slick-list,
    .lessMeal .slick-track {
      width: 100% !important;
    }
    
    .category-title-wrapper {
      height: 36px;
      padding: 0 104px 0 0;
      .category-title {
        font-size: 24px;
        font-weight: 700;
      }
      .total-meals {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .slick-slider {
      .slick-arrow {
        position: absolute;
        top: -32px;
        left: auto;
        width: 36px;
        height: 36px;
        &.slick-disabled {
          background-color: ${theme.colors.grayLighter};
          border-radius: 70px;
          .arrow svg path {
            fill: ${theme.colors.mediumGray};
          }
        }
        &.slick-prev {
          right: 46px;
        }
        &.slick-next {
          right: 0;
        }
        .arrow {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &.arrow-prev svg {
            transform: rotate(180deg);
          }
        }
      }
      .slick-prev:before,
      .slick-next:before {
        content: none;
      }
      .slick-track {
        display: flex;
        gap: 20px;
      }
      .slick-slide:first-child {
        margin-left: -20px;
      }
    }

    .meal-card-container {
      width: 298px !important;
      cursor: pointer;
      .meal-img-wrapper{
        height: 167px;
      }
      .meal-details {
        padding: 8px 16px 16px;
        .title {
          height: 50px;
          margin: 0;
        }
        .add-cart .light {
          height: 32px;
        }
      }
    }
  }
`;
