import React from 'react';

const Mustard = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_4095_18579)">
                <path d="M14.6858 14.6839C14.4441 11.3716 13.1436 7.11633 12.9295 6.43309V4.17969C12.9295 3.85609 12.6672 3.59375 12.3436 3.59375H11.6699L11.1641 0.491641C11.1178 0.208164 10.873 0 10.5858 0H9.41388C9.12666 0 8.88178 0.208164 8.83557 0.491641L8.32982 3.59375H7.65607C7.33248 3.59375 7.07014 3.85609 7.07014 4.17969V6.43309C6.856 7.11637 5.55549 11.3716 5.31389 14.6839C5.20077 16.235 5.38202 17.5601 5.8526 18.6225C6.22276 19.4581 6.64924 19.8263 6.69666 19.8655C6.8017 19.9525 6.93381 20 7.07018 20H12.9295C13.0659 20 13.198 19.9525 13.3031 19.8655C13.3505 19.8263 13.777 19.4582 14.1471 18.6225C14.6177 17.5601 14.7989 16.235 14.6858 14.6839ZM6.91108 11.7969H13.0887C13.2436 12.5801 13.3759 13.3806 13.4591 14.1406H6.54057C6.62373 13.3808 6.75615 12.5802 6.91108 11.7969ZM8.08908 7.10938H11.9106C12.1041 7.7532 12.49 9.09082 12.839 10.625H7.1608C7.50979 9.09105 7.89561 7.75324 8.08908 7.10938ZM9.91205 1.17188H10.0876L10.4825 3.59375H9.51717L9.91205 1.17188ZM8.24201 4.76562H11.7576V5.9375H8.24201V4.76562ZM12.6738 18.8281H7.3258C7.04799 18.4766 6.40932 17.4315 6.45697 15.3125H13.5427C13.5904 17.4315 12.9517 18.4766 12.6738 18.8281Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_4095_18579">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Mustard;
