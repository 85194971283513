import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

const FlexBetween = `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OrderDetailsStatusWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .order-title {
    ${FlexBetween};
    gap: 30px;
    .order-info {
      .title {
        width: calc(100vw - 144px);
        color: ${theme.colors.blackDefault};
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .items-count-wrapper {
      color: ${theme.colors.grayDarker};
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      .point {
        width: 3px;
        height: 3px;
        margin: 5px;
        background-color: ${theme.colors.grayDarker};
        border-radius: 50%;
      }
    }
    .order-contact {
      display: flex;
      gap: 10px;
      .icon-wrapper {
        width: 36px;
        height: 36px;
        background-color: ${theme.colors.grayLighter};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .order-status-placed {
    ${FlexBetween};
    .order-status {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .status-info {
        height: 20px;
        padding: 0 8px;
        background-color: #a0deff;
        color: ${theme.colors.deepForestGreen};
        border-radius: 80px;
        font-size: 10px;
        font-weight: 600;
        display: flex;
        align-items: center;
        span {
          height: 10px;
          line-height: 1.1;
          display: block;
        }
        &.Completed {
          background-color: #90F290;
        }
        &.InProgress {
          background-color: #a0deff;
        }
        &.NotAccepted {
          background-color: #FFD580;
        }
        &.Rejected {
          background-color: #EBEBEB;
        }
      }
    }
    .order-placed {
      display: flex;
      flex-direction: column;
      gap: 2px;
      .label {
        text-align: right;
      }
    }
  }

  .order-delivered,
  .order-amount {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;
