import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

export const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    color: ${theme.colors.grayDarker};
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.22px;
    text-transform: uppercase;
  }

  .info {
    color: ${theme.colors.primaryDefault};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .order-details-title {
    color: ${theme.colors.blackDefault};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  .order-details-body {
    width: 100%;
    overflow: auto;
    padding-bottom: 88px;
    &::-webkit-scrollbar {
      width: auto;
      display: none;
    }
  }

  .delivery-info-wrapper {
    padding: 16px;
    border-top: 10px solid ${theme.colors.grayLightest};
    border-bottom: 10px solid ${theme.colors.grayLightest};

    .order-details-title {
      margin-bottom: 12px;
    }

    .delivery-section {
      display: flex;
      flex-direction: column;
      gap: 2px;
      & + .delivery-section {
        margin-top: 10px;
      }
    }
  }

  .order-items-wrapper {
    padding: 16px;
    border-bottom: 10px solid ${theme.colors.grayLightest};
    .order-details-title {
      margin-bottom: 6px;
    }
    .order-container:last-child .divider {
      border-top: 0;
    }
  }

  .divider {
    margin: 12px 0;
    border-top: 1px solid #e9eaec;
  }

  .payment-breakdown-wrapper {
    padding: 16px;
    .order-details-title {
      margin-bottom: 12px;
    }
    .payment-breakdown-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .payment-breakdown-item {
      display: flex;
      justify-content: space-between;
      .payment-title {
        color: ${theme.colors.grayDarker};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      .payment-amount {
        color: ${theme.colors.black};
        font-family: ${theme.typography.fontInter};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        &.discountOrder {
          color: #3CC13B;
        }
      }
    }
    .total-payout-wrapper {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      justify-content: space-between;
      .payout-title {
        color: ${theme.colors.primaryDefault};
      }
      .payout-amount {
        color: ${theme.colors.black};
      }
    }
  }

  .mark-complete-wrapper {
    position: fixed;
    left: auto;
    bottom: ${({ isWebView, platform}) => isWebView && platform ? '70px' : "0"};
    width: 100%;
    padding: 23px 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 24.08%);
    display: flex;
    justify-content: center;
    transition: all 0.1s linear;
    .btn.dark {
      width: 271px;
      height: 42px;
      border-radius: 30px;
      color: ${theme.colors.secondary};
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      &::after {
        display: none;
      }
    }
    .reject-accepted-wrapper {
      width: 100%;
      padding: 0 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 13px;
      .btn {
        width: 100%;
        &:hover {
          background-color: ${theme.colors.primaryDefault};
        }
      }
      & div:first-child button:hover {
        background-color: ${theme.colors.grayLighter};
      }
    }
  }
`;
