import styled from 'styled-components';
import CoachProfileCard from 'components/shop/CoachProfile/CoachProfileCard';
import ProfileCard from 'components/shop/CoachProfile/MealPlanCoachCard/ProfileCard';
import { useHistory, useLocation } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import { useState, useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import moment from 'moment';
import PantryFooter from 'components/Pantry/PantryFooter';
import Header from 'layout/Header';
import Footer from 'layout/Footer';
import BackButton from 'components/BackButton';

const UserNutritionistProfileStyle = styled.div`
  background-color: #e5e5e5;
  justify-content: space-between;
  @media (max-width: 991px) {
    // position: relative;
    top: -52px;
        height: 220px;
    min-height: calc(100vh - 102px);
    background-color: #f6f5f8;
    margin-bottom: 80px;
    .container {
      padding: 36px 0;
      width: 335px;
      height: 270px;
      margin: auto;
    }
    .back-btn{
      img{
      position:absolute;
      margin:12px;
      top:auto;
      }

    }
    .list-start{
      .descript{
        width:90%;
      }
    }
  }
  .list-start {
    // margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .list-end {
     margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .upcoming-event {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -17px;
  }
  .list-start,
  .list-end {
    cursor: pointer;
    // margin-top: 52px;
    .social-image-start {
      background-color: #f0faeb;
      width: 25px;
      height: 80px;
      margin-left: 292px;
      position: absolute;
      img {
        text-align: center;
        padding-top: 32px;
        padding-left: 9px;
      }
    }
    .arrow-wrapper{
      background-color: #f0faeb;
      width: 25px;
      height: 80px;
      margin-left: 292px;
      position: absolute;
      top: 410px;
    }
    .arrow-wrapper-desktop{
      background-color: #f0faeb;
      width: 25px;
      height: 80px;
      margin-left: 292px;
      position: absolute;
      top: 52.8%;
    }
  }
  .list-start div {
    top: 270px;
  }
  .list-end div {
    margin-top: 5px;
    height: 80px;
    .social-image-start {
      background-color: #f0faeb;
      width: 25px;
      height: 81px;
      margin-left: 290px;
      margin-top: 14px;
      position: absolute;
      top: 352px;
      img {
        text-align: center;
        padding-top: 32px;
        padding-left: 9px;
      }
    }

    .arrow-wrapper{
      background-color: #f0faeb;
      width: 25px;
      height: 81px;
      margin-left: 290px;
      margin-top: 10px;
      position: absolute;
      top: 495px;
      img {
        padding-top: 32px;
        padding-left: 9px;
      }
    }

    .arrow-wrapper-desktop{
      background-color: #f0faeb;
      width: 25px;
      height: 81px;
      margin-left: 290px;
      margin-top: 10px;
      position: absolute;
      top: 63%;
      img {
        padding-top: 32px;
        padding-left: 9px;
      }
    }
  }
  .upcoming-events-title {
    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      text-align: center;
      color: #949d9b;
      padding: 23px;
      display: flex;
      flex-direction: column;
    }
  }
  .card1 {
    margin-bottom: 51px;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 210px);
    max-height: 100%;
    background-color: #f6f5f8;
    margin-bottom: 35px;
    .container {
      padding: 36px 0;
      width: 335px;
      height: 243px;
      margin: auto;
    }
    .list-start {
      margin-top: 67px;
    }
    .list-start div {
      top: 433px;
    }
    .list-end div{
      .social-image-start {
        margin-top: 17px;
        top: 512px !important;
      }
    }
    .upcoming-events-title{
      padding-top: 14px !important;
    }
    .upcoming-event{
      margin-top: -40px;

    }
  }
`;

export default function CoachProfile({publicNutritionist,selectPlan, }) {
  const { nutritionist, offerList, isMobile,user , isVisitor, selectedNutritionist} = useAppContext();
  const [getUpcomingUserEvents] = useRequest();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const history = useHistory();
  // const [insta, setInsta] = useState(false);
  // const [faceBook, setFaceBook] = useState(false);
  // const [tiktok, setTiktok] = useState(false);
  const {instagram = '', facebook = '', tiktok = ''} = nutritionist?.socialProfile || {};
  const {coaching} = selectedNutritionist ? selectedNutritionist : {};

  useEffect(() => {
   getUpcomingEvents();
  }, []);
  const mealPlanUrl = user ? '/shop/my-coach/mealplans' : '/guest/mealPlan' ;
  const coachingUrl = user ? '/shop/my-coach/offers' : '/guest/coaching' ;
  const nutritionistProfile = nutritionist?nutritionist:publicNutritionist ;
  const commonNutritionist = nutritionistProfile ? nutritionistProfile : selectedNutritionist;
  const selectedOfferDetail = JSON.parse(localStorage.getItem('selectedOfferDetails'));
  const getUpcomingEvents = () => {
    const path = `coachingOffer/getUpcomingEvents?nutritionistId=${commonNutritionist?.id}`;
    if(user && !isVisitor){
      getUpcomingUserEvents({
        path,
      })
        .then((data) => {
          setUpcomingEvents(data);
        })
        .catch(console.log);
    }

  };
  return (
    <>
    <UserNutritionistProfileStyle>

      {isVisitor && <div className='back-btn'>
          
        {isMobile? 
        <img src='/icons/arrow-left.svg' alt='image' onClick={()=>history.push("/shop/all-coach")}/> :
        <BackButton perviousLink={"/shop/all-coach"}></BackButton>}
        </div>
        }
      <div className="container">
        <CoachProfileCard
         coachPhoto={commonNutritionist?.img || '/images/avatar.png'}
         name={commonNutritionist?.name}
         insta={instagram}
         faceBook={facebook}
         tiktok={tiktok}
         nutritionist={commonNutritionist}
         title={commonNutritionist?.title}
        />
      </div>
      <div className='list-start ' onClick={() => history.push(mealPlanUrl)}>
        <ProfileCard selectPlan={selectPlan} text={'Meal Plans'} description={'Delivered by a variety of local meal providers'} arrow = {true} />
      </div>
      {(publicNutritionist || (offerList && offerList.length > 0) || (coaching && coaching.length > 0)) && (
        <div className='list-end' onClick={() => history.push(coachingUrl)}>
        <ProfileCard selectedOfferDetail={selectedOfferDetail} user={user} text={'Coaching'} description={'Schedule 1:1 with me'} arrow = {true}/>
      </div>
      )}
      
      {upcomingEvents?.length > 0 &&
      <>
      <div className='upcoming-events-title'>
      <span>Upcoming events</span>
      </div>
      </>
      }
      {upcomingEvents?.length>0 && upcomingEvents?.map((item) => (
        <div className='upcoming-event'>
        <ProfileCard
         text={item?.coachingOffer?.title}
         description={" - "}
         time={item?.NutritionistMeetingMapping[0]?.scheduledDateTime}  
         endTime = {(moment(item?.NutritionistMeetingMapping[0]?.scheduledDateTime).add(item.coachingOffer?.availability,'minutes')).format('HH:mm a')}  
         arrow = {false}
         />
         </div>))}
      </UserNutritionistProfileStyle>
      {(isMobile && user) &&
      <PantryFooter myCoach={true} shop={false} progress={false} />
      }
    </>
  );
}
