import styled from "styled-components";
import { useRequest } from "hooks/useRequest";
import { useEffect, useState,useRef } from "react";
import { toast } from "react-toastify";
import DietaryModal from "./components/DietaryModal";
import { useHistory, useLocation } from "react-router-dom";
import Button from "./components/Button";
import { useOnboardingContext } from "./index";
import PageTransition from "./components/PageTransition";
import Progress from "components/Progress";
import { useAppContext } from "context/appContext";
import { CrossButton } from "./components/CrossButton";

const DietaryPreferenceStyle = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    height: 100%;
    .button{
        padding: 0px 32px 48px;
    }
    .close_cross{
        z-index:1;
    }
`

export default function DietaryPreference() {
    const [showSelectedTags, setShowSelectedTags] = useState({ tags: [], restrictions: [] });
    const history = useHistory();
    const {onboardingUser, setOnboardingUser, dietaryTags, isNewGoal, isRestrictionModalActive, setIsRestrictionModalActive, animation, setAnimation} = useOnboardingContext();
    const { tags, allergies } = dietaryTags || [];
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isRestriction = searchParams.get('isRestriction');
    const dietaryPreferenceRef = useRef(null);

    const handleButtonClick = (e) => {
        e?.preventDefault();
        setOnboardingUser((prev) => ({
            ...prev,
            selfMealPlan: {
                ...prev.selfMealPlan,
                allergies: showSelectedTags?.restrictions,
                dietaryPreferences: showSelectedTags?.tags
              }
          }));
        if (!isRestrictionModalActive) {
            setIsRestrictionModalActive(true);
            setAnimation(true);
            return;
        }
        history.push(`/self-onboarding/preferred-diet${isNewGoal?'?newGoal=true':''}`);
    }

    const handleBack = () => {
        if (isRestrictionModalActive) {
            setAnimation(false);
            setTimeout(() => {
                setIsRestrictionModalActive(false);
            }, 300)
            return;
        }
        history.goBack();
    }

    useEffect(() => {
        setShowSelectedTags({
            tags: onboardingUser?.selfMealPlan?.dietaryPreferences,
            restrictions: onboardingUser?.selfMealPlan?.allergies,
        })
        if(isRestriction){
            setIsRestrictionModalActive(true);
            setAnimation(true);
        }
    }, [onboardingUser])

    useEffect(() => {
        const dietaryPreferenceElement = dietaryPreferenceRef?.current;
        dietaryPreferenceElement.scrollTop = 0;
      }, [isRestrictionModalActive]);

    return (
        <PageTransition>
            <DietaryPreferenceStyle ref={dietaryPreferenceRef}>
                {isNewGoal && <CrossButton goBack={'/shop/profile'}/>}
                    <>
                        {!isRestrictionModalActive &&
                            <DietaryModal setAnimation={setAnimation} isRestrictionModalActive={isRestrictionModalActive} setIsRestrictionModalActive={setIsRestrictionModalActive} title={'What are your dietary preferences?'} showSelectedTags={showSelectedTags} setShowSelectedTags={setShowSelectedTags} tags={tags} type={'preferences'} />
                        }

                        {isRestrictionModalActive &&
                            <DietaryModal animation={animation} setAnimation={setAnimation} isRestrictionModalActive={isRestrictionModalActive} setIsRestrictionModalActive={setIsRestrictionModalActive} title={'Do you have any food allergies or restrictions?'} showSelectedTags={showSelectedTags} setShowSelectedTags={setShowSelectedTags} tags={allergies} type={'allergies'} />
                        }

                        <Button showBackButton={isRestrictionModalActive ? true : false} onBackClick={handleBack} onContinueClick={handleButtonClick} />
                    </>
            </DietaryPreferenceStyle>
        </PageTransition>
    )
}