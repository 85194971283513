import { useState, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PantryDate from 'components/Pantry/PantryDate';
import PantryMealsSnacks from 'components/Pantry/PantryMealsSnacks';
import { useAppContext } from 'context/appContext';
import ShopSteps from 'components/NutrittSteps/ShopSteps';
import PantryCalories from 'components/Pantry/PantryCalories';
import PantryFooter from 'components/Pantry/PantryFooter';
import ChangeDietPlan from 'pages/changedietplan/ChangeDietPlan';
import { useShopContext } from 'context/shopContext';
import NutritionistPlanProgress from 'components/Pantry/NutritionistPlanProgress';

const PantryStyle = styled.div`
  position: relative;
  width: 100%;
  background-color: #f6f5f8;
  overflow: hidden;
  &.isLogMealsModal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 115;
    padding-top: 53px
  }
  &.displaySteps {
    overflow-y: auto;
    .container {
      height: auto;
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .pantry-inner-content{
    max-width: 400px;
    height: inherit;
    margin: auto;
    position: relative;
    width: 100%;
    background-color: #f6f5f8;
    z-index: 9;
    .pantry-date-calories-box {
      z-index: 99;
      width: 100%;
      background-color: #f6f5f8;
    }
    .pantry-shadow-box {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      ::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  .container {
    padding-bottom: 0px;
    height: 100%;
    &.mealsWrapper {
      margin-top: 0px;
      padding-bottom: 0px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .MuiStepper-root .MuiStep-root:last-child:after {
    border-color: #52c41a;
  }
  .pantry-content {
    padding: 24px 0;
    margin-bottom: 26px;
  }
  p,
  span,
  div {
    font-family: 'Roboto' !important;
  }
  .pantry-shop-wrapper {
    z-index: 4;
    max-width: 400px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #F6F5F8;
    .shop-btn {
      font-size: 18px;
      font-weight: 800;
    }
  }
    .pantry-content {
      padding: 0;
      background-color: #fff;
      border-radius: 0px;
      margin-bottom: 0px;
      flex-grow: 1;
    }
    .pantry-shop-wrapper {
      .shop-btn {
        width: 80px;
        height: 30px;
        font-size: 13px;
        font-weight: 500;
        border-radius: 21px;
        line-height: 16px;
      }
    }
  @media (min-width:992px) {
    top: 70px;
    height: calc(100vh - 70px);
    &.displaySteps {
      height: calc(100vh - 70px);
      &::-webkit-scrollbar {
        width: 8px;
      }
    }
    .container .pantry-inner-content .pantry-shadow-box {
      height: auto;
    }
  }
`;

const steps = ['Plan', 'Providers', 'Meals', 'Checkout', 'Progress'];

export default function Pantry() {
  const {isGuest,isVisitor} = useAppContext();
  const [activeDay, setActiveDay] = useState(new Date());
  const [userProgress, setUserProgress] = useState(0);
  const [personalizedMeals, setPersonalizedMeals] = useState([]);
  const [isLogMealsModal, setIsLogMealsModal] = useState(false);
  const [displaySteps, setDisplaySteps] = useState(false);
  const { showChangeDietPlan, providerListCalled } = useShopContext();
  const history = useHistory();
  const { user } = useAppContext();
  const { isMobile } = useAppContext();
  const PanelHeight = window.innerHeight - 179;
  const PanelHeightWithSteps = window.innerHeight - 340;
  const [diaryMeals, setDiaryMeals] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('displayStepsInPantry')) {
      setDisplaySteps(true);
      localStorage.removeItem('displayStepsInPantry');
    }

    if(isGuest || isVisitor){
      history.push("/shop/my-coach")
    }
  }, []);

  return (
    <>
      <PantryStyle className={classNames({displaySteps}, {isLogMealsModal})}>
        {showChangeDietPlan ? (
          <ChangeDietPlan />
        ) : (
          <div className="container">
              { displaySteps && (
                <ShopSteps
                title="My Pantry"
                description="Get ready for the newest day of your healthy journey!"
                activeStep={5}
                steps={steps}
              />
            )}
            <div className="pantry-inner-content">
              <div className="pantry-date-calories-box">
                <PantryDate setActiveDay={setActiveDay} activeDay={activeDay} />
              </div>
                <div className="pantry-shadow-box" style={{height: displaySteps ? PanelHeightWithSteps : PanelHeight}}>
                <PantryCalories diaryMeals={diaryMeals?.totalCals}  />
                <div className="pantry-content">
                  <PantryMealsSnacks
                    activeDay={activeDay}
                    isLogMealsModal={isLogMealsModal}
                    setIsLogMealsModal={setIsLogMealsModal}
                    diaryMeals={diaryMeals}
                    setDiaryMeals={setDiaryMeals}
                    displaySteps={displaySteps}
                  />
                </div>
                <NutritionistPlanProgress
                  personalizedMeals={personalizedMeals}
                  user={user}
                  userProgress={userProgress}
                /> 
              </div>
            </div>
            <div id="diaryFooter" />
          </div>
        )}
      </PantryStyle>
    {isMobile && <PantryFooter progress={true} shop={false} myCoach={false}/>}
    </>
  );
}