import Styled from 'styled-components';
import GooglePlacesAutocomplete, {
    getLatLng,
  } from 'react-google-places-autocomplete';
import classNames from 'classnames';
import DeliveryAddressForm from './DeliveryAddressForm';
import {ReactComponent as CloseIcon} from '../../../assets/close-cross-green.svg';
import BringMealPrepModal from './BringMealPrepModal';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';

const AddressModalStyle = Styled.div`
font-family: 'Roboto';
font-style: normal;
position: fixed;
top:0;
left: 0;
right: 0;
bottom: 0;
z-index: 121;
padding-top:20px;
background-color: #fff;
height: 100%;
height: 100vh;
height: calc(var(--vh, 1vh) * 100);
.image-div{
    display:flex;
    .img{
        position: relative;
        left: 20px;
        top: 15px;
    }
}
.delivery-text{
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #51635D;
    margin-bottom:35px;
}
.btn-div{
    position: relative;
    top: 50%;
    button{
        width: 271px;
        height: 40px;
        border-radius: 21px;
        background: #52C41A;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
    }
}
.cross-Icon{
    width:18px;
    height:18px;
    position: absolute;
    z-index: 1;
    right: 38px;
    margin-top: 13px;
}
`
;

const GooglePlacesAutocompleteStyle = Styled.div`
padding:0 24px;
.css-yk16xz-control{
    border: 1px solid #E0E3E0;
    border-radius: 6px;
}
.css-1pahdxg-control input{
    color: #51635D !important;
    font-weight: 400;
    font-size: 14px !important;
}
.css-1l0hnm3-placeholder{
    text-align: left;
    padding-left:0;
    color: #AFB5B4;
    font-size: 12px;
    font-weight: 400;
}
.css-9ekey3-singleValue {
  padding: 0 50px 0 0;
  color: #51635D;
  font-weight: 400;
  font-size: 14px;
  text-align:left;
}

.css-yk16xz-control .css-1hwfws3,
.css-1pahdxg-control .css-1hwfws3 {
  padding: 0 30px 0 14px !important;
}
.css-yk16xz-control,
.css-1hwfws3,
.css-1pahdxg-control {
  height: 44px;
  line-height: 44px !important;
  div {
    height: 44px;
    line-height: 44px;
  }
  input {
    position: relative;
    top: -7px;
    height: 44px;
    background-color: transparent;
  }
  .css-8sibub-placeholder {
    top: 20px;
    line-height: 44px;
  }
  .css-eeok4o {
    top: 50%;
    height: 44px;
    margin-top: 0;
    padding: 0 0 0 25px;
    line-height: 44px;
  }
}
.css-1pahdxg-control {
  height: 44px;
  border: 1px solid #52c41a;
  box-shadow: none;
  &:hover {
    border-color: #52c41a;
  }
}
.css-26l3qy-menu {
  postion: relative;
  top: 43px;
  bottom: auto;
  z-index: 4;
  border: 1px solid #52c41a;
  box-shadow: none;
  & > div > div {
    &:first-child {
      background-color: #52c41a5e;
      color: #000;
    }
    &:hover {
      background-color: #52c41a5e;
    }
  }
}
.css-26l3qy-menu {
    border:unset;
    font-weight: 700;
    font-size: 14px;
    color: #51635D;
    text-align:left;
    & > div > div {
        position: relative;
        padding-left: 44px;
  
        &::before{
          content: '';
          background-image: url('/icons/location.svg');
          width: 18px;
          height: 20px;
          position: absolute;
          left: 14px;
          top: 5px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
  
        &:first-child {
          background-color: rgba(0,0,0,0);
          color: #51635D;
        }
        &:hover {
          background-color: rgba(0,0,0,0);
        }
      }
  }

`
;

export default function AddressDeliveryModal({
  setAddressModal,
  value,
  setValue,
  form,
  setForm,
  addressFormModal,
  setAddressFormModal,
  handleClick,
  checked,
  setChecked,
  isLoading,
  zip,
  taxError,
  addressModal,
  bringMealModal,
  setBringMealModal,
  showAnim,
  setShowAnim,
  getZip,
  setSelectedValue
}){
  const {isWebView,userDeliveryAddress} = useAppContext()
  const closeAddressModal = () => {
    isWebView && isWebView.postMessage(JSON.stringify({ event: "ModelClosed" }));
    if (!zip && userDeliveryAddress[0]) {
      setValue(userDeliveryAddress[0]?.address?.address);
      setSelectedValue(userDeliveryAddress[0]?.type);
      setShowAnim(false);
    }
    setTimeout(() => {
      setAddressModal(false);
    }, 500)
 }

  const handleCheck = ()=>{
    if (!zip){
       toast.error(`Couldn't find zip code for this address, Please try to add zip code with your address`);
    }
     else if(taxError){
      setBringMealModal(true)
    }
    else{
      setAddressFormModal(true);
    }
  }
 
    return(
       <AddressModalStyle className={showAnim   ? "fadeInMiddle" : "fadeOutMiddle"}>
        <div className='image-div'>
        <CloseIcon onClick={closeAddressModal} className='img'/>
        </div>
        <div className='delivery-text'>
            <p>Add delivery address</p>
        </div>
        {value && <img src="/icons/close-cross.svg" className="cross-Icon" alt="" onClick={()=>setValue(null)} />}
        <GooglePlacesAutocompleteStyle>
              <GooglePlacesAutocomplete
                GooglePlacesDetailsQuery={{ fields: 'geometry' }}
                fetchDetails={true}
                selectProps={{
                  value,
                  autoFocus: true,
                  placeholder: `${localStorage.getItem('login-type') === JSON.stringify('selfMealPlanOnboarding') ? "Address" : "Delivery address"}`,
                  noOptionsMessage: () => null,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      height: '44x',
                      lineHeight: '44px',
                      // paddingLeft: '15px',
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      display: 'none',
                      height: '47px',
                      lineHeight: '47px',
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      height: '47px',
                      lineHeight: '47px',
                      display: 'none',
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      height: '47px',
                      lineHeight: '40px',
                      top: '50%',
                      paddingLeft: '15px',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      top: '50%',
                      marginTop: '0px',
                      paddingLeft: '15px',
                      height: '47px',
                      lineHeight: '37px',
                    }),
                  },
                  onChange: setValue,
                }}
              />
            </GooglePlacesAutocompleteStyle>
            <div className='btn-div'>
                <button
                   className={classNames({
                    disabled:!value || getZip,
                  })}
                  disabled={!value || getZip}
                  onClick={handleCheck}
                >
                    Next
                </button>
            </div>
            {addressFormModal && 
            <DeliveryAddressForm 
            addressFormModal={addressFormModal} 
            setAddressFormModal={setAddressFormModal} 
            value={value} 
            form={form}
            setForm={setForm}
            handleClick={handleClick}
            checked={checked}
            setChecked={setChecked}
            isLoading={isLoading} 
            />}

            {
              bringMealModal &&
              <BringMealPrepModal
              bringMealModal={bringMealModal}
              setBringMealModal={setBringMealModal}
              setAddressModal={setAddressModal}
              addressModal={addressModal}
              value={value}
              zip={zip}
              setShowAnim={setShowAnim}
              />
            }
       </AddressModalStyle> 
    )
}