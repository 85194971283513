import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import UpdateProviderButton from "../UpdateProviderButton";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import "./map.css";

const ButtonWrapper = styled.button`
  margin-top: 20px !important;
  width: 35% !important;
  margin-left: 10px !important;
  background: transparent;
  height: 44px;
`;

const ButtonContainer = styled.span`
  margin: 0px 6px;
`;

export default function SelectDeliverableAreaOnMap({
                                                     provider: contextProvider,
                                                     setProvider: setContextProvider,
                                                   }) {
  const [provider, setProvider] = useState(contextProvider);
  const [isPolygonDrawn, setPolygonDrawn] = useState(false);
  const history = useHistory();

  const setMapWithDrawingTool = () => {
    const drawingManagerConfig = {
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          // google.maps.drawing.OverlayType.MARKER,
          // google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.POLYGON,
          // google.maps.drawing.OverlayType.POLYLINE,
          // google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      markerOptions: {
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      circleOptions: {
        fillColor: "#ffff00",
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    };

    const map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 37.0902, lng: -95.7129 },
      zoom: 4,
    });
    const drawingManager = new google.maps.drawing.DrawingManager(
        drawingManagerConfig
    );
    drawingManager.setMap(map);
    new google.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        overlayCompleteListener
    );
    setTimeout(() => setPreviousSelectedAres(map), 1000);
    const p = JSON.parse(sessionStorage.getItem("imitateProvider"));
    if (p && p.id != provider.id) {
      /*Update Provider DATA*/
      setContextProvider(p);
      setProvider(p);
    }
  };

  const overlayCompleteListener = (event) => {
    if (event.type === "polygon") {
      const cords = event.overlay
          .getPath()
          .getArray()
          .map((cord) => {
            return {
              lat: cord.lat(),
              lng: cord.lng(),
            };
          });

      setProvider((prevProvider) => {
        const prevDeliverableAreas = prevProvider?.deliverableAreas?.data || [];
        const deliverableAreas = [...prevDeliverableAreas, cords].filter(
            Array.isArray
        );
        return {
          ...prevProvider,
          deliverableAreas: {data: deliverableAreas},
        };
      });
      setPolygonDrawn(true);
    }
  };

  useEffect(setMapWithDrawingTool, []);

  const setPreviousSelectedAres = (map) => {
    let allCords =
        provider.deliverableAreas?.data?.map((cords) => {
          return cords.map(({ lat, lng }) => new google.maps.LatLng(lat, lng));
        }) || [];
    const dataPolygon = new google.maps.Polygon({
      paths: allCords,
      strokeWeight: 0,
      fillColor: "#2701f6",
      fillOpacity: 0.6,
    });
    dataPolygon.setMap(map);
    function resetMap (){
      setProvider((prevProvider) => {
        return {
          ...prevProvider,
          deliverableAreas: {data: []},
        };
      });
      dataPolygon.setMap(null);
      setPolygonDrawn(true);
    }
    document.getElementById("remove-polygon").addEventListener("click", resetMap);
  };

  return (
      <>
        <div id="map" className="App"></div>
        <div className="save-btn">
          <ButtonContainer>
          <Button variant='contained' onClick={()=>{
            history.goBack();
          }}>
            Go back
          </Button>
          </ButtonContainer>
        <ButtonContainer>
          <Button id='remove-polygon' variant='contained'>
            Clear
          </Button>
        </ButtonContainer>
          <ButtonWrapper>
          {isPolygonDrawn && (
              <UpdateProviderButton
                  body={provider}
                  navigateBack={true}
                  path="provider/update"
                  setContextProvider={setContextProvider}
                  label='Update Areas'
                  method={'post'}
              />
          )}
          </ButtonWrapper>
        </div>
      </>
  );
}
