import styled from 'styled-components'
import Title from './components/Title'
import { useHistory, useLocation } from 'react-router-dom'
import { useState, useMemo, useEffect } from 'react'
import Button from './components/Button'
import DietCard from "./components/DietCard"
import { ReactComponent as Easy } from "./../../assets/Frame 3710.svg"
import { ReactComponent as Medium } from "./../../assets/Frame 3711.svg"
import { ReactComponent as Extreme } from "./../../assets/Frame 3711 (1).svg"
import { ReactComponent as EasyGreen } from "./../../assets/Frame 3710 (1).svg"
import { ReactComponent as MediumGreen } from "./../../assets/Frame 3711 (2).svg"
import { ReactComponent as ExtremeGreen } from "./../../assets/Frame 3711 (3).svg"
import { useOnboardingContext } from './index'
import PageTransition from './components/PageTransition'
import { useRequest } from 'hooks/useRequest'
import { toast } from 'react-toastify'
import { CrossButton } from './components/CrossButton'
import { useAppContext } from 'context/appContext'

const SelectHealthGoalStyle = styled.div`
    padding: 48px 30px 0;
    display: flex;
    flex-direction: column;
    overflow: overlay;
    background-color: #fff;
    height: 100%;
    position: relative;
    .flex{
        flex: 1;
    }
    .diet-cards{
        margin-top: 68px;
        .dietCard{
            height:74px;
            padding:0 25px;
            border-radius:37px;
        }
    }
`

export default function SelectHealthGoal() {
    const history = useHistory();
    const [activePreference, setActivePreference] = useState(null);
    const { onboardingUser, setOnboardingUser } = useOnboardingContext();
    const [visitorOnboard, { loading }] = useRequest('profile/visitor-onboard/v2', 'post');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const isNewGoal = params.get('newGoal');
    const { setupLocalUser, setProfile } = useAppContext();

    const handleBack = () => {
        if (isNewGoal === "true") {
            history.push('/self-onboarding/active-status?newGoal=true');
        } else {
            history.push('/self-onboarding/active-status');
        }
    }

    function useCalculateTargetWeek(targetWeight, currentWeight) {
        const results = useMemo(() => {
            if (isNaN(targetWeight) || isNaN(currentWeight)) {
                return
            }
            const intensities = [0.5, 1, 2];

            return intensities.reduce((acc, intensity) => {
                if (isNaN(intensity) || intensity <= 0) {
                    throw new Error('Invalid intensity');
                }

                const weightDiff = Math.abs(currentWeight - targetWeight);
                const weeksToGoal = weightDiff / intensity;
                const today = new Date();
                const targetWeekEndDate = new Date();
                targetWeekEndDate.setDate(today.getDate() + (weeksToGoal * 7));

                const formattedDate = targetWeekEndDate.toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });

                acc[intensity] = {
                    weeksToGoal: weeksToGoal.toFixed(2),
                    targetWeekEndDate: formattedDate
                };
                return acc;
            }, {});
        }, [targetWeight, currentWeight]);

        return results;
    };

    const targetWeight = onboardingUser?.selfMealPlan?.idealWeight;
    const currentWeight = onboardingUser?.weight;
    const targetDate = useCalculateTargetWeek(targetWeight, currentWeight);

    useEffect(() => {
        if (onboardingUser?.selfMealPlan?.healthGoal === "weight-loss") {
          setActivePreference(-Math.abs(onboardingUser?.selfMealPlan?.goal));
        } else {
          setActivePreference(Math.abs(onboardingUser?.selfMealPlan?.goal));
        }
      }, []);

    const activeStatusData = [
        {
            icon: activePreference === 0.5 || activePreference === -0.5 ? <EasyGreen /> : <Easy />,
            title: "Easy",
            desc: `${onboardingUser?.selfMealPlan?.healthGoal === 'weight-gain' ? 'Gain' : 'Lose'} 0.5 lb/week`,
            desc1: `Target ideal weight date ${targetDate && targetDate[0.5]?.targetWeekEndDate}`,
            goal: onboardingUser?.selfMealPlan?.healthGoal === 'weight-loss' ? -0.5 : 0.5
        },
        {
            icon: activePreference === 1 || activePreference === -1 ? <MediumGreen /> : <Medium />,
            title: "Medium",
            desc: `${onboardingUser?.selfMealPlan?.healthGoal === 'weight-gain' ? 'Gain' : 'Lose'} 1 lb/week`,
            desc1: `Target ideal weight date ${targetDate && targetDate[1]?.targetWeekEndDate}`,
            goal: onboardingUser?.selfMealPlan?.healthGoal === 'weight-loss' ? -1 : 1
        },
        {
            icon: activePreference === 2 || activePreference === -2 ? <ExtremeGreen /> : <Extreme />,
            title: "Extreme",
            desc: `${onboardingUser?.selfMealPlan?.healthGoal === 'weight-gain' ? 'Gain' : 'Lose'} 2 lb/week`,
            desc1: `Target ideal weight date ${targetDate && targetDate[2]?.targetWeekEndDate}`,
            goal: onboardingUser?.selfMealPlan?.healthGoal === 'weight-loss' ? -2 : 2
        },
    ]

    const handleClick = (value) => {
        setActivePreference(value);
        setOnboardingUser((prev) => ({
            ...prev,
            selfMealPlan: {
                ...prev.selfMealPlan,
                goal: value
            }
        }))
    }

    const handleContinueClick = async () => {
        try {
            const res = await visitorOnboard({ body: { ...onboardingUser } })
            if (res?.data?.success) { 
                history.push('/self-onboarding/personalize-macros');
            }
        } catch (err) {
            if (err != "jwt expired") {
                toast.error(err);
            }
        }

    }

    return (
        <PageTransition>
            <SelectHealthGoalStyle>
                {isNewGoal === "true" && <CrossButton />}
                <div className='flex'>
                    <Title title="How quickly do you want to reach your goal weight?" subtitle={"Choosing a more intense goal can help you reach your target weight faster, but requires more effort"} />

                    <div className="diet-cards">
                        {activeStatusData?.map((item) => {
                            return <DietCard onClick={() => handleClick(item.goal)} isActive={activePreference === item.goal} {...item} />
                        })}
                    </div>
                </div>
                <Button onBackClick={handleBack} onContinueClick={handleContinueClick} disabled={!activePreference} />
            </SelectHealthGoalStyle>
        </PageTransition>
    )
}