import { useState } from 'react';
import classNames from 'classnames';
import DeliveryDates from '../DeliveryDates';
import LinesEllipsis from 'react-lines-ellipsis';
import { DescriptionWrapper } from './style';

export default function Description({ provider }) {
  const [lines, setLines] = useState(3);
  const { image , name, description, mealPrepared, tag } = provider || {};

  const features = [
    {
      id: 1,
      icon: <img src="/icons/meal.svg" alt="Meal Icon" />,
      title: `${mealPrepared}+ Meals prepared`,
    },
    {
      id: 2,
      icon: <img src="/icons/place.svg" alt="Place Icon" />,
      title: 'Locally sourced',
    },
    {
      id: 3,
      icon: <img src="/icons/verified.svg" alt="Verified Icon" />,
      title: 'Certified',
    },
  ];

  return (
    <DescriptionWrapper>
      <section className="kitchen-info-wrapper">
        <div className="provider-image-wrapper">
          <img src={image  || '/images/green-plate.png'} alt="Provider Img" class="provider-img" onError={(e) => { e.target.src = '/images/avatar.png' }}/>
        </div>
        <div className="kitchen-details">
          <h1>{name?.[0].toUpperCase() + name?.slice(1).toLowerCase()}</h1>
          <div className="features-wrapper">
            {features.map((feature) => (
              <div key={feature.id} className="feature-details">
                {feature.icon}
                <div className="feature-title">{feature.title}</div>
              </div>
            ))}
            {tag && (
              <div className="kitchen-tag">
                <div className="divider" />
                <div className="tag">{tag}</div>
              </div>
            )}
          </div>
          {description && (
            <section className="description-text">
              <LinesEllipsis
                text={description}
                maxLine={lines}
                ellipsis={
                  <span className="see-more" onClick={() => setLines(lines + 10)}>
                    ... See more
                  </span>
                }
                basedOn="letters"
                component="span"
                className={classNames({ textMoreLines: lines > 3 })}
              />
              {lines > 3 && (
                <span onClick={() => setLines(3)} className="hide-text">
                  Hide
                </span>
              )}
            </section>
          )}
        </div>
      </section>
      <DeliveryDates provider={provider} />
    </DescriptionWrapper>
  );
}
