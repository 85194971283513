export const theme = {
    colors: {
        primary: '#3E66FB',
        primaryDarker:'#00092D',
        secondary: '#ffffff',
        success: '#3CC13B',
        localGreen:'#49A332',
        successDark: '#2D912C',
        successLightest: '#F5FCF5',
        danger: '#F03738',
        warning: '#F3AA1C',
        coloradoBronze: '#EE786B',
        rockBlue:'#8ACEBF',
        majorBlue:'#2399BF',
        gray: '#C2C9D1',
        white: '#ffffff',
        black: '#000000',
        lightBlue: '#ECF0FF',
        lightGrey: '#F0F2F4',
        blackDefault: '#283241',
        blackLighter:'#C9CCCF',
        blackLight: '#9499A0',
        grayDark: '#9CA3AD',
        steelGray: '#616569',
        grayDarker: '#757E89',
        grayLight: '#E0E4E8',
        grayLighter: '#F0F2F4',
        grayLightest: '#F9F9F9',
        slateGray: '#92979d',
        primaryDark: '#3B425D',
        primaryDefault: '#000A2C',
        borderLight: '#3240541A',
        lightDanger: '#FDEBEB',
        lightSuccess: '#EBF9EB',
        background: "#FBFBFC",
        primarylightest: "#F3F3F5",
        blackLightest: "#F4F5F6",
        lightBackground: "#FCFCFD",
        deepForestGreen: "#0B1D17",
        lighterGrey:"#949D9B",
        primaryLight: "#B5B9C8",
        mediumGrey: "#51635D",
        lightGrey: "#E0E3E0",
        aliceBlue:"#F0F2F4",
        intrepidGrey:"#e0e3e0",
        blueBlack:"#00092d",
        mediumGray:"#AFB5B4",
        paleRed:'#FEC8B4',
        whiteSmoke:'#FEF1F0',
        alizarinCrimson:'#EB3223',
        primaryGray: '#324054',
        darkBlue: '#000a2c',
        solitudeBlue: '#E9EAEC'
    },
    typography: {
        fontOpenSans: "'Open Sans', sans-serif",
        fontInter: "'Inter', sans-serif",
        fontRoboto: "'Roboto', sans-serif;",
        fontGilroy: "Gilroy",
        fontRegular: '14px',
        fontBase: '16px',
        fontMedium: '18px',
        fontSmall: '12px',
        fontMobile: '11px',
        fontLarge: '20px',
        fontXl: '24px'
    },
};