import React from 'react';

const LactoVegetarian = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_3899_30613)">
                <path d="M8.14815 4.11649V4.07112C8.79478 3.84179 9.25926 3.22427 9.25926 2.50001C9.25926 1.58101 8.51159 0.833344 7.59259 0.833344H2.40741C1.48841 0.833344 0.740741 1.58101 0.740741 2.50001C0.740741 3.22427 1.20522 3.84179 1.85185 4.07112V4.11649C1.85185 4.81249 1.58081 5.46686 1.08867 5.95905C0.38663 6.66105 0 7.59442 0 8.58723V18.1296C0 19.0486 0.747667 19.7963 1.66667 19.7963H8.33333C9.25233 19.7963 10 19.0486 10 18.1296V8.58723C10 7.59442 9.61337 6.66105 8.91133 5.95901C8.41919 5.46686 8.14815 4.81249 8.14815 4.11649ZM2.40741 1.94445H7.59259C7.89893 1.94445 8.14815 2.19368 8.14815 2.50001C8.14815 2.80634 7.89893 3.05557 7.59259 3.05557H2.40741C2.10107 3.05557 1.85185 2.80634 1.85185 2.50001C1.85185 2.19368 2.10107 1.94445 2.40741 1.94445ZM1.11111 8.58723C1.11111 7.89123 1.38215 7.23686 1.8743 6.74468C2.56452 6.05449 2.94985 5.14075 2.96263 4.16668H7.03737C7.05015 5.14075 7.43552 6.05449 8.1257 6.74468C8.54441 7.16338 8.80233 7.69968 8.87022 8.27897C7.40563 8.30375 5.97159 8.7136 4.71418 9.46805C3.62407 10.1221 2.38078 10.4764 1.11111 10.4979V8.58723ZM8.88889 18.1296C8.88889 18.436 8.63967 18.6852 8.33333 18.6852H1.66667C1.36033 18.6852 1.11111 18.436 1.11111 18.1296V11.6101C2.58219 11.5885 4.02307 11.1785 5.28581 10.4208C6.37593 9.76679 7.61919 9.41249 8.88889 9.39097V18.1296Z" fill={color || "#757E89"} />
                <path d="M19.2877 7.905C19.2694 7.71969 19.1228 7.57305 18.9374 7.55466C17.5963 7.42222 16.3622 7.7502 15.4098 8.46874C15.4099 8.45402 15.4102 8.43921 15.4102 8.42449C15.4102 6.78531 14.6018 5.31162 13.3141 4.25533C13.1701 4.13715 12.9626 4.13715 12.8187 4.25533C11.5308 5.31154 10.7226 6.78531 10.7226 8.42449C10.7226 9.98676 11.458 11.4163 12.6757 12.4727V13.776C12.6757 14.0175 12.8973 14.2076 13.1426 14.159C13.1493 14.1577 13.1557 14.1559 13.1622 14.1542C13.2342 14.1361 13.2944 14.1003 13.3426 14.0522L14.1541 13.2407C14.2847 13.2495 14.4151 13.2543 14.5434 13.2543C15.9639 13.2543 17.2322 12.7179 18.1147 11.744C19.0173 10.7478 19.4339 9.38442 19.2877 7.905ZM13.457 12.8329V12.4729C13.5015 12.4341 13.5453 12.395 13.5886 12.3553C13.5896 12.4656 13.5939 12.5767 13.6015 12.6885L13.457 12.8329ZM13.7859 10.9658L13.784 10.9684C13.6835 11.1086 13.5742 11.2449 13.457 11.3771V8.42449C13.457 8.20873 13.2821 8.03386 13.0663 8.03386C12.8506 8.03386 12.6757 8.20873 12.6757 8.42449V11.3774C11.9149 10.5207 11.5039 9.4968 11.5039 8.42449C11.5039 7.18097 12.0562 6.00239 13.0663 5.07435C14.0765 6.00246 14.6288 7.18113 14.6288 8.42449C14.6288 9.35352 14.3165 10.2256 13.7859 10.9658ZM17.5357 11.2194C16.8849 11.9377 15.9734 12.3689 14.9386 12.4562L16.7284 10.6664C16.881 10.5139 16.881 10.2665 16.7284 10.1139C16.5759 9.96143 16.3286 9.96143 16.176 10.1139L14.3857 11.9042C14.4013 11.7178 14.428 11.5349 14.466 11.3565C14.8222 10.8449 15.0815 10.2933 15.2379 9.71439C16.0057 8.76606 17.1892 8.26412 18.5321 8.3102C18.5706 9.43836 18.2224 10.4617 17.5357 11.2194Z" fill={color || "#757E89"} />
            </g>
            <defs>
                <clipPath id="clip0_3899_30613">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LactoVegetarian;
