import { useState, useContext, createContext, useEffect } from 'react';
import { usePersistedState } from 'hooks/usePersistedState';
import { useLocation, useParams } from 'react-router-dom';
import { useRequest } from 'hooks/useRequest';
import produce from 'immer';
import { useAppContext } from './appContext';

const VisitorContext = createContext();

function VisitorContextProvider({ children }) {
    const [cartCount, setCartCount] = useState(0);
    const [cart, updateCart] = usePersistedState('cart', []);
    const [zip, setZip] = useState('');
    const [value, setValue] = useState(null);
    const [availableProviders, setAvailableProviders] = usePersistedState('availableProviders', {
        data: [],
        expiry: new Date().getTime() + (5 * 60 * 1000)
    });
    const [rowId, setRowId] = useState('');
    const [deliveryUnavailable, setDeliveryUnavailable] = useState(false);
    const [settings, updateSettings] = usePersistedState('settings',{
        days: 7,
        meals: 3,
        provider: null,
        providerSettings: [], //{providerId, method ["pickup/delivery"], date, time,note,name,schedule}
        userInfo: {
            firstName: '',
            lastName: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            phone: '' || '',
            userAddressLatLong: {},
            addressType: '',
            address_Value: ''
        },
        mealPlan: [],
        pantryDatesCalories: [],
    });
    const [noNavigation, setNoNavigation] = useState(false);
    const {search} = useLocation();
    const [getProviderbyUsername, {isLoading: isProviderLoading}] = useRequest();
    const [publicProviderData, setPublicProviderData] = useState({});
    const {providerId: storeUrl}= useParams();
    const [showBummer, setShowBummer] = useState(false);
    const {userDeliveryAddress,setUserDeliveryAddress, selectedValue, headerAddress, setHeaderAddress,user} = useAppContext();
    const [generateKitchensList] = useRequest();
    const [kitchensList, setKitchensList] = useState([]);
    const [kitchenUserName , setKitchenUserName] = useState();
    const anonymousUserAddress = localStorage.getItem("visitorDeliveryAddress");
    const [isFetchingKitchenDetails, setIsFetchingKitchenDetails] = useState(true);
    const [isKitchenNotFound, setIsKitchenNotfound] = useState(false);

    useEffect(() => {
        if (userDeliveryAddress?.length && !anonymousUserAddress) {
            const defaultAddress = userDeliveryAddress?.find(address => address?.id === selectedValue);
            setHeaderAddress(defaultAddress);
        }
    }, [userDeliveryAddress,anonymousUserAddress]);

    useEffect(()=>{
        if(anonymousUserAddress){
            const parsedAddress=JSON.parse(anonymousUserAddress);
            const address={...parsedAddress,address:{address:parsedAddress?.address}}
            setUserDeliveryAddress([address])
            setHeaderAddress(address);
        }
    },[anonymousUserAddress])

    useEffect(() => {
        const params = new URLSearchParams(search);
        const isNavActive = params.get('navigation');
        if(isNavActive !== 'false'){
            return;
        }
        setNoNavigation(true);
    }, [search])

    useEffect(() => {
        let count = 0;
        for (let i = 0; i < cart.length; i++) {
          count += cart[i].qty;
        }
        setCartCount(count);
      }, [cart]);

    const getKitchensList = async () => {
        const path = `provider/list`;
        await generateKitchensList({ path })
            .then((res) => {
                if (res?.data?.success) {
                    setKitchensList(res?.data?.kitchens);
                }
            })
            .catch((err) => console.log({ err }));
    };

    const getProviderByUsernameFn = async () => {
      setIsKitchenNotfound(false);
        const path = `provider/username/${storeUrl}`;
        await getProviderbyUsername({ path })
            .then((res) => {
                setPublicProviderData(res.data);
                updateSettings(
                    produce((draft) => {
                        draft.provider = res.data;
                    }),
                );
            })
            .catch((err) => {
              console.log({ err })
              setIsKitchenNotfound(true);
            });
            setIsFetchingKitchenDetails(false);
    };  

    useEffect(() => {
        if (!user && kitchensList?.length < 1) {
            getKitchensList();
        }
        
    }, [user, kitchensList]);
    
    useEffect(() => {
      if (!user && storeUrl) {
          getProviderByUsernameFn();
      }
    }, [user, storeUrl])

    useEffect(() => {
      const uniqueProviderIds = [...new Set(cart?.map(({ providerId }) => providerId))];
      const cartProviders = uniqueProviderIds.flatMap((providerId) => {
        const providerIndex = settings?.providerSettings?.findIndex(
          (prov) => prov?.id === providerId,
        );
        if (providerIndex !== -1) {
          return [settings.providerSettings[providerIndex]];
        } else {
          const provSettings = kitchensList?.find((kitchen) => kitchen?.id === providerId);
          if (!provSettings) {
            if (settings?.provider && settings?.provider?.id === providerId) {
              return [settings.provider];
            } else {
              return [provSettings];
            }
          } else {
            return [provSettings];
          }
        }
      });

      const updatedProviderSettings = cartProviders.map((item) => {
        const prevSettingsIndex = settings?.providerSettings?.findIndex(
          (provider) => provider?.providerId === item?.id,
        );
        const updates = {
          ...item,
          address: `${item?.address ?? ''}${item?.address ? ', ' + item?.city : ''}${
            item?.state ? ', ' + item?.state : ''
          }${item?.zip ? ' ' + item?.zip : ''}`,
          deliveryCoverage: item?.deliveryCoverage,
          deliverableAreas: item?.deliverableAreas,
          minOrderAmount: item?.minOrderAmount,
          name: item?.name,
          providerId: item?.id,
          schedule: item?.schedule,
        };
        return prevSettingsIndex !== -1
          ? { ...settings.providerSettings[prevSettingsIndex], ...updates }
          : { ...updates, date: Date.now() };
      });

      updateSettings(
        produce((draft) => {
          draft.providerSettings = uniqueProviderIds.length ? updatedProviderSettings : [];
        }),
      );
    }, [cart]);

    return (
        <VisitorContext.Provider value={{ cart, updateCart, cartCount, settings, updateSettings, availableProviders, setAvailableProviders, rowId, setRowId, deliveryUnavailable, setDeliveryUnavailable, zip, setZip, value, setValue, noNavigation,
            setShowBummer,
            showBummer,
            isProviderLoading,
            setKitchensList,
            kitchensList,
            kitchenUserName,
            setKitchenUserName,
            isFetchingKitchenDetails,
            isKitchenNotFound
             }}>
            {children}
        </VisitorContext.Provider>
    )
}

const useVisitorContext = () => useContext(VisitorContext);

export { VisitorContextProvider, useVisitorContext }
