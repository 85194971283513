import styled from 'styled-components';
import { useAppContext } from 'context/appContext';

const FlexWrapper = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepsWrapper = styled.div`
  width: 90%;
  margin: 23px auto;
  .back-icon-wrapper {
    position: fixed;
    left: 0;
    top: auto;
    cursor: pointer;
    margin-left : 24px;
    margin-bottom : 20px;
  }
  .step-header {
    padding: 23px 0 23px !important;
    width: 100% !important;
    margin: auto;

    .title-wrapper {
      position: relative;
      ${FlexWrapper};
    }

    h2 {
      margin-bottom: 11px;
      color: #0b1d17;
      font-size: 18px;
      font-family: 'Roboto';
      line-height: 24px;
      text-align: center;
    }

    .description1 {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      color: #949d9b;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }

    .description2 {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        color: #949d9b;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
  }
  }

@media(min-width: 992px){
  width: 100% !important;
  margin: 0px !important;

  .title-wrapper{
    width: 1
    margin: 0 auto;
    min-height: calc(100vh-210px);
    .backStep{
      width: 94px !important;
      height: 88px !important; 
    }
  }

  .step-header{
    padding: 23px 0 32px !important;
    h2{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
    }

    .description1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
}
`;

export default function Steps({ title, description1, description2, perviousLink }) {
  const { isMobile } = useAppContext();
    return (
      <StepsWrapper>
        <div className="step-header">
          <div className="title-wrapper">
            {isMobile ?
              <div className="back-icon-wrapper" onClick={perviousLink}>
                <img src="/icons/arrow-left.svg" alt="Arrow Left" className="backStep" />
              </div> :
              <div className="back-icon-wrapper" onClick={perviousLink}>
                <img src="/icons/Checkout-left-arrow.svg" alt="Arrow Left" className="backStep" />
              </div>
            }
            <h2>{title}</h2>
          </div>
          <div className="description1">{description1}</div>
          <div className="description2">{description2}</div>
        </div>
      </StepsWrapper>
    );
}
// {!isMobile && <div className='back-arrow'>
//                 <div className='back-arrow-div' onClick={() => history.push('/shop/plan')} >
//                    <img
//         src="/icons/arrow-left.svg"
//         alt="Arrow Left"
//         className="back-Step"
//       />
//       </div>
//       </div>
//         }
