import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const RejectModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  padding: 0 16px;

  .reject-mask {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(11, 29, 23, 0.65);
  }

  .reject-modal-container {
    position: absolute;
    bottom: ${({ isWebView }) => ( isWebView ? '90px' : '27px')};
    left: auto;
    width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 32px 0 24px;
    background-color: ${theme.colors.secondary};
    border-radius: 12px;
    .close-img {
      position: absolute;
      top: 12px;
      left: 16px;
    }
    .reject-info {
      width: 175px;
      margin: 0 auto 24px;
      color: ${theme.colors.deepForestGreen};
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
    .reject-btns-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      button {
        width: 220px;
        height: 42px;
        border-radius: 30px;
        font-family: ${() => theme.typography.fontGilroy};
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        &::after {
          display: none;
        }
      }
      div:last-child button:hover {
        background-color: ${theme.colors.grayLighter};
      }
      div:first-child button:hover {
        background-color: ${theme.colors.primaryDefault};
      }
    }
  }
`;
