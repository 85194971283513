import styled from 'styled-components'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState, useMemo,useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg'
import { ReactComponent as SortIcon } from '../../../assets/sort.svg'
import OrderCard from './components/OrderCard';
import { useEffect } from 'react';
import { useAppContext } from 'context/appContext';
import OrderDetailsSection from './OrderDetailsSection';
import { useRequest } from 'hooks/useRequest';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';
import { sort } from 'ramda';
import Progress from 'components/Progress';
import { useDebounce } from 'use-debounce/lib';
import Toggle from 'components/elements/toggleModule/Toggle';

const OrderManagementStyle = styled.section`
    display: flex;
    width: calc(100% - 300px);
    margin-left: auto;
    @media (max-width: 991px){
        width: 100%;
        padding: 24px 20px 0;
    }
`

const OrderListSectionStyle = styled.div`
    max-width: 444px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;
    z-index: 2;
    .section_header{
        background: #FFFFFF;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
        padding: 49px 32px 19px;
        .toogle-div{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .title{
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #51635D;
            margin-bottom: 24px;
        }
        .select_header, .status_select{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            margin-bottom: 14px;
        }
        .status_select{
            font-weight: 400;
            & > div{
                // padding-right: 12px;
                border: 1px solid #E0E3E0;
                border-radius: 6px;
            }
            .status-select__menu{
                div{
                    background-color: #fff;
                }
            }
        }
        .status-select__control{
            padding-right:12px;
        }
        .MuiTabs-root{
            background: #F6F5F8;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
            padding: 0 5px;
            .MuiTabs-indicator{
                height: 36px !important;
                background-color: #fff !important;
                border-radius: 4px !important;
                bottom: 5px;
            }
            .MuiTabs-flexContainer{
                z-index: 4;
                position: relative;
            }
            .MuiTabs-scroller{
                padding: 5px 0;
            }
            .MuiTab-root{
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #51635D;
                text-transform: initial;
                min-width: auto;
                min-height: auto;
                padding: 8px 20px;
                position: relative;
                &:last-child{
                    color: #949D9B;
                }
            }
            .Mui-selected{
                font-weight: 700;
                overflow: visible;
                &.showNum{
                    &::before{
                        content: attr(neworders);
                        position: absolute;
                        top: -4px;
                        right: -4px;
                        background: #F08B00;
                        border-radius: 5px;
                        min-width: 14px;
                        height: 14px;
                        font-size: 9px;
                        letter-spacing: 0.05em;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 15px;
                        padding: 0 2px;
                    }
                    &.rejected{
                        &::before{
                            right: 0;
                        }
                    }
                }
            }
        }
        .search_sort{
            margin-top: 20px;
            display: flex;
            align-items: center;
            position: relative;
            .search{
                flex: 1;
                label{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .form-control{
                    font-weight: 400;
                    font-size: 14px !important;
                    line-height: 16px !important;
                    padding-left: 0;
                    width: 100%;
                    color: #51635D;
                    &::placeholder{
                        font-size: 14px;
                        line-height: 16px;
                        color: #AFB5B4;
                    }
                }
            }
            .sort{
                display: inline-flex;
                cursor: pointer;
                gap: 10px;
                align-items: center;
                p{
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: #51635D;
                }
                .sortIcon{
                    &.active{
                        filter: invert(69%) sepia(33%) saturate(6234%) hue-rotate(62deg) brightness(175%) contrast(80%);
                    }
                }
            }
            .wrap{
                position: fixed;
                inset: 0;
                width: 100vw;
                height: 100vh;
                z-index: 2;
            }
            .sortable_options{
                background: #FFFFFF;
                border: 1px solid #E0E3E0;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: inline-block;
                position: absolute;
                top: calc(100% + 10px);
                right: 0;
                z-index: 99;
                max-width: 135px;
                width: 100%;
                .opt{
                    font-size: 14px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    color: #51635D;
                    padding: 10px 13px;
                    cursor: pointer;
                    user-select: none;
                    &.active{
                        color: #52C41A;
                        background: #F0FAEB;
                    }
                }
            }
        }
    }
    .section_body{
        padding: 32px 32px 0;
        height: calc(100vh - 208px);
        overflow: auto;
    }
    @media (max-width: 1440px){
        max-width: 398px;
        .section_body{
            height: calc(100vh - 230px);
        }
    }
    @media (max-width: 991px){
        border-radius: 10px;
        overflow: hidden;
        .section_header{
            padding: 24px 16px 20px;
            box-shadow: unset;
            .title{
                font-size: 16px;
                line-height: 20px;
                text-align: center;
            }
            .MuiTabs-root{
                min-height: auto;
                .MuiTab-root{
                    font-size: 12px;
                    line-height: 14px;
                    padding: 8px 14px;
                }
                .MuiTabs-indicator{
                    height: 30px !important;
                }
            }
            .search_sort{
                .search{
                    label{
                        margin-bottom: 0 !important;
                    }
                    .form-control{
                        font-size: 12px !important;
                        line-height: 14px !important;
                        &::placeholder{
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
                .sort{
                    p{
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }
        }
        .section_body{
            padding: 8px 20px 80px;
            height: calc(100vh - 270px);
        }
    }
`

export default function OrderManagement() {
    const [size, setSize] = useState();
    const [value, setValue] = useState(0);
    const [ordersValue, setOrdersValue] = useState(0);
    const [activeOrderId, setActiveOrderId] = useState("0");
    const [isOrderDetailsActive, setIsOrderDetailsActive] = useState(false);
    const {isMobile} = useAppContext();
    const [getAllOrders, { isLoading:isOrdersLoading, error, state: success, setState: setSuccess }] = useRequest();
    const [orderData, setOrderData] = useState(null);
    const [activeTab, setActiveTab] = useState('pending');
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [localOrderId, setLocalOrderId] = useState([]);
    const [isSortOptionsActive, setIsSortOptionsActive] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [SearchedValue] = useDebounce(searchTerm, 750);
    const [getOrderDetails, { isLoading:orderDetailsLoading}] = useRequest();
    const [orderDetails, setOrderDetails] = useState({});
    const [selectedOption, setSelectedOption] = useState(0);
    const [orderCountByCat,setOrderCountByCat]=useState({})
    const scrollableDivRef = useRef(null);
    const allActions = ['refused', 'completed', 'pending', 'accepted'];
    const [showTestOrder,setShowTestOrder]=useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentPage(1);
        switch (newValue) {
            case 0:
                setActiveTab("pending");
                break;
            case 1:
                setActiveTab("accepted");
                break;
            case 2:
                setActiveTab("completed");
                break;
            case 3:
                setActiveTab("refused");
                break;
            default:
                setActiveTab("pending");
                break;
        }
        if (scrollableDivRef?.current && !isMobile) {
            scrollableDivRef.current.scrollTo(0, 0);
          }
    };

    useEffect(() => {
        isMobile && setActiveOrderId("0");
        !isMobile && setIsOrderDetailsActive(true);
        document.body.classList.add('hide-scroll');
        return () => document.body.classList.remove('hide-scroll');
    }, [])

    const orderDataList = useMemo(() => {
        const matchingOrder = orderData?.map(order => {
            const matchingStatus = order?.orderStatus?.filter(item => item.action === activeTab)
            const matchingProviders = order?.providers?.filter(provider => {
              const providerStatus = order?.orderStatus?.find(status => status.providerId === provider?.Provider?.id)
              return providerStatus?.action === activeTab
            })
            if (matchingStatus?.length > 0) {
              return { ...order, orderStatus: matchingStatus, providers: matchingProviders }
            }
            return null
          }).filter(item => item !== null)
    
        let dataList;
        dataList = matchingOrder?.filter((item) => item);
       
        if(sortBy){
            switch (sortBy){
                case 'newest':
                    dataList?.sort((a,b) => moment(b.orderedAt) - moment(a.orderedAt));
                    break;
                case 'oldest':
                    dataList?.sort((a,b) => moment(a.orderedAt) - moment(b.orderedAt));
                    break;
                case 'unread':
                    const seenIds = JSON.parse(localStorage.getItem('orderId')) || [];
                    const mappedResponse = dataList.map(item => {
                        const seenItem = seenIds.find(seen => seen.orderId === item.orderMainId);
                        const status = seenItem?.seen ? 'seen' : 'unseen';
                        return { ...item, status };
                    });
                    const sortedResponse = mappedResponse.sort((a, b) => {
                        if (a.status === b.status) {
                            return b.orderId - a.orderId;
                        } else {
                            return a.status === 'unseen' ? -1 : 1;
                        }
                    });
                    dataList = sortedResponse;
                default:
                    dataList;
                    break;
            }
        }
            return dataList;
    }, [activeTab, orderData, sortBy, SearchedValue])

    const isNoOrderFound = !(orderDataList?.length) || (searchTerm?.length && orderDetails && (!orderDetails?.providerOrder?.length || orderDetails?.providerOrder?.every(item => item?.providerOrderAction !== activeTab)));

    useEffect(() => {
        if(orderDataList?.length && !SearchedValue?.length){
            setOrdersValue((orderDataList?.filter(item => item?.orderStatus?.map(status => status.action) == 'pending')).length)
            setTimeout(() => {
                    !isMobile && 
                    ( orderDataList?.[0]?.providers?.length>1 ?
                    handleOrderClick(orderDataList?.[0]?.orderMainId,1) : handleOrderClick(orderDataList?.[0]?.orderMainId));
            }, 10)        
        }
    }, [activeTab, orderDataList, sortBy,SearchedValue])
    
    const handleOrderClick = (id, multiple) => {
        if(!multiple){
            setActiveOrderId(String(id))
        }else{
            setActiveOrderId(String(id + "(" + multiple + ")"))
        }
        setIsOrderDetailsActive(true);
        const localOrderId = JSON.parse(localStorage.getItem('orderId'));
        const findIndex = localOrderId.findIndex(item => item.orderId === id);
        if(findIndex !== -1){
            localOrderId[findIndex].seen = true;
        }
        localStorage.setItem('orderId', JSON.stringify(localOrderId));
    }
    const fetchAllOrders = async () => {
        try{
            const res = await getAllOrders({path: `admin/all-orders?pageSize=500&pageNumber=${currentPage}&action=${activeTab}&showTestOrder=${showTestOrder}`});
            setOrderData(res?.data?.orders);
            setTotalPages(res?.data?.totalPages);
            const categoriesCount=res?.data?.ordersCount
            const orderCountByCat = categoriesCount.reduce((obj, cat) => {
                obj[cat.action] = cat.total_count;
                return obj;
              }, {});
            allActions.forEach(action => {
            if (!orderCountByCat.hasOwnProperty(action)) {
                orderCountByCat[action] = 0;
            }
            setOrderCountByCat(orderCountByCat);
            });

        }catch(err){
            toast.error(err);
        }
    }

    useEffect(() => {
        fetchAllOrders();
        setSize(window.innerWidth);
        window.addEventListener('resize', updateSize);
        function updateSize() {
            setSize(window.innerWidth);
        }
        return () => window.removeEventListener('resize', updateSize);
    }, [activeTab,showTestOrder]);
    
    const fetchMore = async () => {
        const nextPage = currentPage + 1;
        try{
            const res = await getAllOrders({path: `admin/all-orders?pageSize=500&pageNumber=${nextPage}&action=${activeTab}&showTestOrder=${showTestOrder}`});
            setOrderData([...orderData, ...res?.data?.orders]);
            setCurrentPage(nextPage);
            if(currentPage == totalPages){
                setHasMore(false);
            }
        }catch(err){
            toast.error(err);
        }
    }

    const DropdownIndicator = () => {
        return  <img className='image' src='/icons/SelectArrow.svg' />
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#51635D' : "#51635D",
            backgroundColor: state.isFocused ? "#ffffff" : "#ffffff",
            textAlign: 'left',
            "&:hover": {
                color: "rgb(82, 196, 26)",
                backgroundColor:"#F0FAEB !important"
            }
        })
    }

    const handleStatusChange = (e) => {
        setCurrentPage(1);
        setSelectedOption(e.index);
        switch (e.value) {
            case "pending":
                setActiveTab("pending");
                break;
            case "accepted":
                setActiveTab("accepted");
                break;
            case "completed":
                setActiveTab("completed");
                break;
            case "refused":
                setActiveTab("refused");
                break;
            default:
                setActiveTab("pending");
                break;
        }
    }

    useEffect(() => {
        orderData?.length && checkIsInLocal();
    }, [orderData])

    useEffect(() => {
        const localId = JSON.parse(localStorage.getItem('orderId'));
        setLocalOrderId(localId);
    }, [localStorage.getItem('orderId')])

    const checkIsInLocal = () => {
        const localObject = [];
        if (!localOrderId) {
            const data = orderData?.map((order) => {
                return {
                    orderId: order?.orderMainId,
                    seen: false,
                }
            })
            localObject.push(...data);
        } else {
            const newUniqueId = [];
            orderData?.forEach(order => {
                const orderExist = localOrderId.find((item) => item?.orderId === order?.orderMainId);
                if (!orderExist) {
                    newUniqueId.push({
                        orderId: order?.orderMainId,
                        seen: false
                    })
                }
            });
            localObject.push(...localOrderId, ...newUniqueId);
        }
        localStorage.setItem('orderId', JSON.stringify(localObject));
    }

    const orderDetailsData = async () => {
        try{
            const res = await getOrderDetails({path: `admin/orderById/${SearchedValue}`});
            setOrderDetails(res.data);
        }
        catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        SearchedValue && orderDetailsData();
    }, [SearchedValue])

      const sortedSearchOrders = useMemo(() =>{
        if(SearchedValue?.length){
        const providerOrderArray = orderDetails?.providerOrder?.filter(
            (order) => order?.providerOrderAction === activeTab
          );
          return { ...orderDetails, providerOrder: providerOrderArray };
        }
      },[SearchedValue,activeTab,orderDetails]);

    
      useEffect(() => {
        if(sortedSearchOrders?.providerOrder?.length){
            setTimeout(() => {
                    !isMobile &&
                    ( sortedSearchOrders?.providerOrder?.length>1 ?
                        handleOrderClick(sortedSearchOrders?.orderData?.id, 1) : handleOrderClick(sortedSearchOrders?.orderData?.id))
            }, 10)        
        }
    }, [activeTab, sortBy,orderDetails,SearchedValue])


      const selectOptions = [
        {
            label: `${`New - ${orderCountByCat?.pending>0 ? orderCountByCat?.pending : 0}`}`,
            value: 'pending',
            index:0
        },
        {
            label: `Accepted - ${orderCountByCat?.accepted>0 ? orderCountByCat?.accepted : 0}`,
            value: 'accepted',
            index:1,
        },
        {
            label: `Completed - ${orderCountByCat?.completed >0? orderCountByCat?.completed: 0}`,
            value: 'completed',
            index:2,
        },
        {
            label: `Rejected - ${orderCountByCat?.refused>0?orderCountByCat?.refused: 0}`,
            value: 'refused',
            index:3,
        }
    ]

    const handleToggleChange=(checked)=>{
        setShowTestOrder(!checked)
    }

    return (
        <OrderManagementStyle>
            <OrderListSectionStyle>
                <div className='section_header'>
                    <h2 className='title'>Orders</h2>
                    <div className='toogle-div'>
                        <text>Show Test Orders </text>
                        <Toggle
                        name="autoAcceptOrder"
                        text={showTestOrder ? 'On' : 'Off'}
                        toggleColor={ '#3CC13B'}
                        type="switch"
                        toggleWidth="38px"
                        toggleHeight="18px"
                        width="13.33px"
                        height="14.33px"
                        gap="12px"
                        transform="20px"
                        onChange={handleToggleChange}
                        checked={showTestOrder}
                        handleToggleSave={handleToggleChange}
                    />
                    </div>                                                              
                    {size > 1500 ?
                    <Tabs value={value} onChange={handleChange}>
                        <Tab disableRipple newOrders={orderCountByCat?.pending} className={ 'showNum'} label="New" />
                        <Tab disableRipple newOrders={orderCountByCat?.accepted} className={'showNum'} label="Accepted" />
                        <Tab disableRipple newOrders={orderCountByCat?.completed} className={'showNum'} label="Completed" />
                        <Tab disableRipple newOrders={orderCountByCat?.refused} className={'showNum rejected'} label="Rejected" />
                        
                    </Tabs>
                                    :
                    <>
                    <p className='select_header'>{isMobile ? "Order status" : ""}</p>
                    <Select
                        className="status_select"
                        classNamePrefix="status-select"
                        options={selectOptions}
                        styles={customStyles}
                        defaultValue={"New"}
                         value={selectOptions[selectedOption]}
                        onChange={handleStatusChange}
                        components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                        }}
                        isSearchable={false}
                    />
                    </>
                    }
                    <div className='search_sort'>
                        <div className='search'>
                            <label for='search'>
                                <SearchIcon />
                                <input className='form-control' id='search' type='text' placeholder='Search order...' onChange={(e) => setSearchTerm(e.target.value.trim())} />
                            </label>
                        </div>
                        <div className='sort' onClick={() => {setIsSortOptionsActive(!isSortOptionsActive)}}>
                            <p>Sort by</p>
                            <SortIcon className={`sortIcon ${isSortOptionsActive ? 'active':''}`} />
                        </div>
                        {isSortOptionsActive && <>
                        <div className='wrap' onClick={() => {setIsSortOptionsActive(!isSortOptionsActive)}}></div>
                        <div className='sortable_options'>
                            <div className={`opt ${sortBy === 'newest' && 'active'}`} onClick={() => {setSortBy('newest'); setIsSortOptionsActive(false)}}>Newest</div>
                            <div className={`opt ${sortBy === 'oldest' && 'active'}`} onClick={() => {setSortBy('oldest'); setIsSortOptionsActive(false)}}>Oldest</div>
                            <div className={`opt ${sortBy === 'unread' && 'active'}`} onClick={() => {setSortBy('unread'); setIsSortOptionsActive(false)}}>Unread</div>
                        </div>
                        </>
                        }
                    </div>
                </div>
                <div className='section_body' id="scrollableDiv" ref={scrollableDivRef}>
                    {(orderDataList?.length > 0) && !searchTerm?.length > 0  &&
                    <InfiniteScroll
                    dataLength={orderDataList.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv"
                    loader={!searchTerm && isOrdersLoading}
                    >
                    {orderDataList?.map((order, index) => {
                        const isSeen = localOrderId?.find(item => item.orderId === order.orderMainId)?.seen;
                        if(order?.providers?.length > 1){
                            return order?.providers?.map((item, i) => {
                                return (
                                <OrderCard activeTab={activeTab} isSeen={isSeen} onClick={() => handleOrderClick(order?.orderMainId,i+1)} active={activeOrderId == order?.orderMainId + "(" + (i + 1) + ")"} order={order} key={i} providers={item}/>
                                )
                                }
                            )
                        }else{
                            return <OrderCard activeTab={activeTab} isSeen={isSeen} onClick={() => handleOrderClick(order?.orderMainId)} active={String(activeOrderId) === String(order?.orderMainId)} providers={order?.providers?.[0]} order={order} key={index}/>
                        }
                    })}
                    </InfiniteScroll>
                    }
                    {
                        searchTerm?.length > 0 &&
                            <>
                                {sortedSearchOrders?.providerOrder?.length > 0 &&
                                    sortedSearchOrders?.providerOrder?.map((order, index) => {
                                        const isSeen = localOrderId?.find(item => item.orderId === sortedSearchOrders.orderData.id)?.seen;
                                        if (sortedSearchOrders?.providerOrder?.length > 1) {

                                            return (
                                                <OrderCard activeTab={activeTab} isSeen={isSeen} onClick={() => handleOrderClick(sortedSearchOrders?.orderData?.id, index + 1)} active={activeOrderId == sortedSearchOrders?.orderData?.id + "(" + (index + 1) + ")"} order={sortedSearchOrders} key={index} providers={order} />
                                            )
                                        } else {
                                            return <OrderCard activeTab={activeTab} isSeen={isSeen} onClick={() => handleOrderClick(sortedSearchOrders.orderData.id)} active={String(activeOrderId) === String(sortedSearchOrders.orderData.id)} providers={order} order={sortedSearchOrders} key={index} />
                                        }
                                    })
                                }
                            </>
                    }
                    {
                        isNoOrderFound === true &&
                        <p style={{ fontSize: "14px", textAlign: 'center' }}>No order found.</p>
                    }
                </div>
                {(isOrdersLoading ||  orderDetailsLoading) &&
                    <Progress />
                }
            </OrderListSectionStyle>
            {isOrderDetailsActive && <OrderDetailsSection searchData={orderDetails} SearchedValue={SearchedValue} size={size} activeOrderId={activeOrderId} setActiveOrderId={setActiveOrderId} setIsOrderDetailsActive={setIsOrderDetailsActive} sortedSearchOrders={sortedSearchOrders} activeTab={activeTab} searchTerm={searchTerm} isOrdersLoading={isOrdersLoading}/>
            }
        </OrderManagementStyle>
    )
}