import React, { useEffect, useRef, useState } from 'react'
import { theme } from 'shared/theme/theme'
import styled from 'styled-components'
import { AiOutlineHome } from 'react-icons/ai';
import Profile from '../profileCard/Profile';
import { useAppContext } from 'context/appContext';
import { useKitchenContext } from 'context/kitchenContext';

const SidebarStyle = styled.section`
    max-width: ${({ sidebarWidth }) => `${sidebarWidth}px` || '250px'};
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: ${({ navBgColor, theme }) => navBgColor || theme.colors.primary};
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        background-color: ${({ theme }) => theme.colors.black};
        opacity: 0.5;
        display: ${({ navBgColor }) => navBgColor ? 'none' : 'block'};
    }
    > * {
        position: relative;
        z-index: 2;
    }
`

const HeaderStyle = styled.div`
    width: 100%;
    height: 55px;
    border-bottom: 1px solid rgba(255,255,255,0.35);
    padding: 12px 16px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    > * {
        display: block;
    }
`

const NavItemsStyle = styled.nav`
    width: 100%;
    height: calc(100% - 64px);
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .nav_item{
        width: 100%;
        display: flex;
        padding: 8px;
        align-items: center;
        column-gap: ${({ size }) => size === 'large' ? '16px' : '8px'};
        border-radius: 8px;
        position: relative;
        z-index: 2;
        cursor: pointer;
        transition: all 0.3s;
        .icon{
            width: ${({ size }) => size === 'large' ? '20px' : '16px'};
            height: ${({ size }) => size === 'large' ? '20px' : '16px'};
            img, svg {
                display: block;
            }
        }
        .title{
            font-size: ${({ theme, size }) => size === 'large' ? theme.typography.fontBase : theme.typography.fontSmall};
            color: ${({ navItemTextColor, theme }) => navItemTextColor || theme.colors.white};
            font-weight: 400;
            line-height: 16px;
            transition: all 0.2s;
            flex: 1;
        }
        &:hover{
            background: ${({theme}) => theme?.colors?.grayLightest};
        }
        &.active:hover{
            background: rgba(0,0,0,0);
        }
        .extra_number{
            min-width: 20px;
            width: auto;
            padding: 0 6px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:  ${({ theme }) => theme.typography.fontSmall};
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #fff;
            background: #FF472E;
            border-radius: 2000px;
        }
    }
    .container{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: ${({ navItemsGap }) => `${navItemsGap || 0}px`};
    }
`

const NavItemSlider = styled.div`
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: ${({ height }) => height}px;
    background:  ${({ bgColor }) => bgColor};
    transition: 0.3s;
    transform: ${({ height, index, navItemsGap }) => `translateY(${(height * index) + (navItemsGap * index)}px)`}
`

export const Sidebar = ({ logo, navItems, selectedTabId, setSelectedTabId, navBgColor, activeNavBgColor, navItemTextColor, activeNavTextColor, navItemsGap = 0, sidebarWidth, size }) => {
    const demoItems = [
        {
            id: 0,
            title: 'Demo1',
            icon: <AiOutlineHome style={{ color: theme.colors.white, opacity: 0.5, fontSize: '16px' }} />,
            onClick: () => { },
        },
        {
            id: 1,
            title: 'Demo2',
            icon: <AiOutlineHome style={{ color: theme.colors.white, opacity: 0.5, fontSize: '16px' }} />,
            onClick: () => { }
        },
        {
            id: 2,
            title: 'Demo3',
            icon: <AiOutlineHome style={{ color: theme.colors.white, opacity: 0.5, fontSize: '16px' }} />,
            onClick: () => { }
        },
        {
            id: 3,
            title: 'Demo4',
            icon: <AiOutlineHome style={{ color: theme.colors.white, opacity: 0.5, fontSize: '16px' }} />,
            onClick: () => { }
        }
    ];

    const navLinks = navItems ? navItems : demoItems;
    const containerRef = useRef();
    const [containerHeight, setContainerHeight] = useState();
    const sliderHeight = (containerHeight - (navItemsGap * (navLinks.length - 1))) / navLinks.length;
    const { user, isSuper } = useAppContext() || {};
    const {kitchenProvider} = useKitchenContext() || {};    
    
    useEffect(() => {
        setContainerHeight(containerRef?.current?.getBoundingClientRect().height);
    }, [containerRef])

    const handleSelected = (e) => {
        setSelectedTabId(e);
    }

    const profileEmail = isSuper? kitchenProvider?.user?.email : user?.email;

    return (
        <SidebarStyle theme={theme} navBgColor={navBgColor} sidebarWidth={sidebarWidth}>
            <HeaderStyle className='logo' theme={theme}>
                {logo || 'LOGO HERE'}
            </HeaderStyle>
            <NavItemsStyle theme={theme} navItemTextColor={navItemTextColor} navItemsGap={navItemsGap} size={size}>
                <div className='container' ref={containerRef}>
                    {navLinks?.map((item, index) => {
                        return (
                            <div onClick={() => { handleSelected(item.id); item.onClick() }} key={index} className={`nav_item ${(selectedTabId || demoItems[0].id) === item.id ? 'active' : ''}`}>
                                <div className={`icon ${(selectedTabId || demoItems[0].id) === item.id ? 'active' : ''}`}>
                                    {item?.icon}
                                </div>
                                <div className='title' style={{ color: ((selectedTabId || demoItems[0].id) === item.id && activeNavTextColor) ? activeNavTextColor : '' }}>
                                    {item?.title}
                                </div>
                                {item?.number > 0 && 
                                <span className='extra_number'>{item.number}</span>}
                            </div>
                        )
                    })}
                    <NavItemSlider height={sliderHeight} navItemsGap={navItemsGap} bgColor={activeNavBgColor || theme?.colors?.primary} index={selectedTabId || demoItems[0].id} />
                </div>
                <Profile profileName={`${kitchenProvider?.name} ${kitchenProvider?.lastName ?? ''}`} profileEmail={profileEmail} img={kitchenProvider?.images?.[0]} showLogout showProfile />
            </NavItemsStyle>
        </SidebarStyle>
    )
}