import { useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import ImageViewer from '../../../components/ImageViewer';
import { ReactComponent as Arrow } from 'icons/arrow-left.svg';

const MealPlanCardStyle = styled.div`
  margin-bottom: 35px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px #0000000d;
  cursor: grab;

  .title {
    padding: 13px 16px;
    color: #0b1d17;
    font-size: 14px;
    font-family: 'Roboto';
    line-height: 17px;
  }

  .meal-plan-image {
    width: 100%;
    height: 170px;
    object-fit: cover;
  }

  .meal-img-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .stard-btn {
      position: absolute;
      bottom: -20px;
    }

    .primary-btn {
      width: 148px;
      height: 41px;
      padding: 0;
      border-radius: 21px;
      font-size: 14px;
      font-family: 'Roboto';
      line-height: 17px;
      box-shadow: 0px 3px 7px rgb(0 0 0 / 12%);
    }
  }

  .days-tags {
    overflow: hidden;
    padding: 40px 16px 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    .days-wrapper {
      height: 20px;
      display: flex;
      align-items: center;

      .calendar-icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }

    .days {
      color: #949d9b;
      font-size: 12px;
      font-family: 'Roboto';
    }

    .show-more-wrapper {
      color: #52c41a;
      font-size: 12px;
      justify-self: center;

      .show-more {
        display: flex;
        align-items: center;

        &.showLess svg {
          transform: rotate(90deg);
        }
      }

      svg {
        width: 14px;
        transform: rotate(-90deg);
        transition: all 0.3s ease-in-out;

        path {
          stroke: #52c41a;
        }
      }
    }

    .tag {
      margin: 0;
      justify-self: right;
    }
  }

  .tags-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    height: 20px;
    margin: 0 5px 5px 0;
    padding: 0 9px;
    color: #52c41a;
    border: 1px solid #cbedba;
    border-radius: 10px;
    font-size: 10px;
    font-family: RobRegular;
    line-height: 12px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .details {
    padding: 24px 16px;
    border-top: 1px solid #e0e3e0;
    display: grid;
    grid-row-gap: 10px;

    p {
      color: #949d9b;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 16px;
    }
  }
`;

const RenderTags = ({ tags, justEat }) => {
  return justEat ? (
    <GoShoppingTag>Go shopping</GoShoppingTag>
  ) : (
    <div className="tags-wrapper" tagCount={tags.length} onTouchMove={(e) => e.stopPropagation()}>
      {tags.map((tag) => (
        <div className="tag" key={tag}>
          {tag}
        </div>
      ))}
    </div>
  );
};

export default function MealPlanCard({ selectPlan, plan }) {
  const { name, image, id, duration, duration_type, tags = [], justEat, introduction } = plan;
  const [displayDetails, setDisplayDetails] = useState(false);

  return (
    <MealPlanCardStyle key={id}>
      <div className="title">{name}</div>
      <div className="meal-img-wrapper">
        <ImageViewer className="meal-plan-image" src={image || '/img_coming_soon.png'} fallbackSrc={'/img_coming_soon.png'} alt="Meal Plan" />
        <div className="stard-btn">
          <button
            className="primary-btn"
            onClick={() => {
              selectPlan(id);
              window.scrollTo(0, 0);
            }}
          >
            Get started
          </button>
        </div>
      </div>
      <div className="days-tags">
        <div className="days-wrapper">
          <img className="calendar-icon" src="/icons/calendar-gray.svg" alt="calendar" />
          <span className="days">
            {duration} {duration_type}
          </span>
        </div>
        <div className="show-more-wrapper">
          {(introduction || !!tags?.length) && (
            <div
              className={classNames('show-more', { showLess: displayDetails })}
              onClick={() => setDisplayDetails(!displayDetails)}
            >
              Show {displayDetails ? 'less' : 'more'} <Arrow />
            </div>
          )}
        </div>
        {!!tags.length && <div className="tag">{tags[0]}</div>}
      </div>
      {(introduction || !!tags?.length) && displayDetails && (
        <div className="details">
          {!!tags?.length && <RenderTags tags={tags} justEat={justEat} />}
          {introduction && <p>{introduction}</p>}
        </div>
      )}
    </MealPlanCardStyle>
  );
}

