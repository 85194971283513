import React from "react";
import {Typography} from '@material-ui/core';
import styled from "styled-components";

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  width:100%
table {
    width: 100%;
    border: "1 px solid black",
}
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #696969;
    color: white;
    text-align: center !important;
    padding: 8px;
    width: 200px;
  }
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }
`;

const NutrittPrivacyPolicy = () => { 
    return(
        <div style={{
            marginLeft: "80px",
            marginRight: "80px",
        }}>
            <div  style={{
            display: "grid",
            justifyContent: "center",
            color: "#52c41a",
            }}>
                <Typography variant="h2" component="h2"><b>Nutritt</b></Typography>
            </div>
            <br/>
            <div style={{
            display: "grid",
            justifyContent: "center",
        }}>
            <Typography variant="h3" component="h3"><b>Privacy Policy</b></Typography>
        </div>
        <br/>
        <div style={{
            fontStyle: "italic"
        }}>
            <Typography variant="h6" component="h6">
                Last Updated: March 11, 2022
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 1 – Introduction</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
                <p>1.1. <b>Intro.</b>This Privacy Policy describes the Personal Information (as defined below) collected or processed when you interact with Nutritt (as defined below). It also explains how your Personal Information is used, shared, and is protected by us, and what choices you have relating to your Personal Information.
                <br/><br/>
                If you have any questions about this Privacy Policy or your Personal Information, please contact us at any of the contact methods listed on our Website.
                <br/><br/>
                This Privacy Policy governs your privacy rights. We recommend reading this Privacy Policy in its entirety. By using our Website or our Services, you consent to this Privacy Policy (and any subsequent amendments). If you do not agree to this Privacy Policy, please do not use our Website or sign up for our Services.</p>
                <br/>
                <p>1.2 <b>Ownership & Services. </b>Our Website is owned and operated by Nutritt, Inc., a Delaware corporation (referred to herein as “Nutritt”, “we”, “us”, or “our”). Nutritt is an online platform designed to help our members achieve their nutritional goals by utilizing a customized nutrition plan created by third-party nutrition influencers in conjunction with healthy food delivery options fulfilled by our third-party food vendors (our “Services”).
                <br/><br/>
                1.3 <b>Scope. </b>This Privacy Policy applies to all users of our website, <u><a href="https://www.nutritt.com/" target="_blank">www.nutritt.com</a></u> (our “Website”), our mobile website, our social media accounts, and other platform(s) not in effect or herein after created.
                <br/><br/>
                1.4 <b>Additional.</b> Our Website may contain links to third party sites that are not owned or controlled by Nutritt. Please be aware that we are not responsible for the practices of such other sites or entities. We encourage you to read the privacy statements of every website that you visit as these websites and entities have no affiliation with Nutritt, cannot bind Nutritt, and may have privacy policies that contain different terms from what is included here.
                </p>
                </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 2 – Information We Collect.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            2.1 <b>Information Provided by You.</b> You have provided us information through various means. We collect “Personal Information”, which identifies you as an individual or you as an entity. The information we collect may include the following: name, address, telephone number(s), email address(es), date of birth, age, location, country, payment/billing information, dietary restrictions, food preferences, background health and wellness information, and inferences drawn from any or all of the foregoing. This information is collected directly from you when you (a) visit our Website; or (b) sign up for our Services.
            <br/><br/>
            2.2 <b>Information We Collect from You.</b> We collect certain information automatically as you use our Website, including your Internet Protocol address, browser type, computer type, type of mobile device, mobile carrier, computer operating system, the site from where you navigated to our Website, the time and date of using our Website, how long you use (or have used) our Website and the pages that you view.
            <br/><br/>
            2.3 <b>Cookies.</b> When you visit or use our Website, we may send one or more “cookies” to your computer or other device. A cookie allows that site to recognize your browser. Cookies may store unique identifiers, user preferences and other information. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do, some Website features may not function properly without cookies.
            <br/><br/>
            We use cookies for a number of reasons, including without limitation:
            <ol style={{
                listStyleType: "inherit"
            }}>
                <li>Authenticating and identifying you so we can provide you services;</li>
                <li>Keeping track of information you have provided to us;</li>
                <li>Remembering your preferences or where you left off;</li>
                <li>Measuring your use of our Website so we can improve its quality; and</li>
                <li>Understanding what you may be interested in.</li>
            </ol>
            2.4 <b>Pixel Tags.</b> We may use "pixel tags," which are small graphic files that allow us to monitor the use of our Website. A pixel tag can collect information such as the IP address of the computer that downloaded the page on which the tag appears; the URL of the page on which the pixel tag appears; the time the page containing the pixel tag was viewed; the type of browser that fetched the pixel tag; and the identification number of any cookie on the computer previously placed by that server.
            <br/><br/>
            2.5 <b>Physical Location.</b> We may collect the physical location of your device by, for example, using satellite, cell phone tower, or Wi-Fi signals. We may use your device’s physical location to better understand who you are.
            <br/><br/>
            2.6 <b>Age Restriction.</b> Our Website and our Services are not intended for or directed to children under the age of thirteen (13) and we do not knowingly collect Personal Information from children under the age of thirteen (13) on our Website. If we become aware that a child under the age of thirteen (13) has provided us with Personal Information, we will delete the information from our records.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
            <u><b>Section 3 – How Nutritt Uses Your Information.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            3.1 <b>To Provide Services and Information.</b> As described above, we collect or have collected information from you so that we can effectively offer our Services to you, so that our third-party affiliates can assist in offering Services to you, for data or analytic reasons, data trends, usage, to determine the effectiveness of our Services, or any reasonable reason(s) now or hereinafter adopted.
            <br/><br/>
            3.2 <b>Our Business Purposes.</b> We may use your information for our Services and other reasons such as developing new Services, sending marketing campaigns, enhancing, improving or modifying our Website, identifying usage trends, determining the effectiveness of our campaigns and any reasonable reason(s) now or hereinafter adopted.
            <br/><br/>
            3.3 <b>Other Purposes.</b> We may use non-Personal Information for other purposes, except when not permitted by applicable law. We may use analytics services, such as Google Analytics, on our Website, our social media pages, or our mobile website to help us evaluate and analyze how visitors use our Website. For specific details on how Google collects and uses information on our website, social media pages, or mobile applications, please visit Google’s website.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 4 – How Your Information is Shared.</b></u>
                </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            4.1 <b>Our Third-Party Service Providers.</b> We may share your information with our third-party service providers, who provide services including, without limitation, providing the Services, or some of them, website hosting, data analytics, payment processing, customer service, email delivery services, or other services that are used in connection with the Services that we offer.
            <br/><br/>
            4.2 <b>Legal Requests or Requirements and to PreventHarm.</b> We reserve the right to disclose your Personal Information and any other information collected by you as required by law or when that disclosure is necessary to protect our rights, the rights of a third-party, or to comply with a judicial proceeding or otherwise.
            <br/><br/>
            4.3 <b>Transfer of Your Personal Information.</b> We reserve the right to transfer your information in the event of a sale of Nutritt.
            <br/><br/>
            4.4 <b>Other Sharing of Non-Personally Identifiable Information.</b> We may share non-personally identifiable information with third parties unless you opt out or unless barred by applicable law.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 5 - Access to Your Information and Opt Out.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            Subject to applicable law, you may have the right to request access to and receive details about the Personal Information we have collected and maintain about you, update and correct inaccuracies in your Personal Information, and have the Personal Information deleted. These rights may be limited in some circumstances by local law. We may take reasonable steps to verify your identity before granting access or making corrections. 
            <br/><br/>
            You can opt-out of us using your Personal Information by sending us an email to <u><a href = "mailto: support@nutritt.com">support@nutritt.com</a></u> or by unsubscribing on the communication you receive. If you send us an email, please be sure to provide (i) sufficient information that allows us to verify that you are the person whom we collected Personal Information; and (ii) sufficiently describe your request. Please note if your opt-out request is specific to a particular type of Personal Information.
            <br/><br/>
            Finally, we are not obligated to delete Personal Information if we are unable to verify that you are the person whom we collected Personal Information, if we have reason to believe that you are a bot and not a person, or for any other reason that indicates that you may not be the person whom we collected Personal Information.
            <br/><br/>
            We will endeavor to comply with your request(s) as soon as reasonably practicable, which may depend on the number of requests we have received at or around the time of your request. If we need additional time, which we reasonably expect to be completed within thirty (30) days, we will contact you in writing and inform you of the reason for the delay.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 6 - Security of Your Information.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            We use industry standard security measures designed to protect your personally identifiable information. However, no data transmission or storage system is impenetrable, and you acknowledge this. If you have reason to believe that the security of your Personal Information might have with us has been compromised, please immediately notify us.
            <br/><br/>
            Unless you’re using an encrypted email, we strongly advise you not to communicate any confidential information in your emails to us.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 7 – Changes to Our Privacy Policy.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            If we decide to change our Privacy Policy, we will post those changes on our Website so that you are aware of what information we collect, how we use it, and under what circumstances we disclose or transfer it. We reserve the right to modify this Privacy Policy at any time, so please review the Privacy Policy from time to time.
            <br/><br/>
            Any changes to this Privacy Policy will become effective immediately upon being made public.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 8 – Additional Information If You Live in California.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            Under California privacy laws, including the California Consumer Privacy Act ("CCPA"), we are required to notify California residents about how we use their Personal Information.
            <br/><br/>
            Under the CCPA, "Personal Information" is any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular California resident or household.
            <br/><br/>
            We collect the Personal Information in accordance withSection 2, above.
            <br/><br/>
            <b>California residents have the right to opt-out of our transfer or use of their Personal Information by contacting us at via email at the email listed above.</b> Please be prepared to provide proof of your residence in California.
            <br/><br/>
            We do not use or transfer Personal Information about California residents under 16 years old without opt-in consent.
            <br/><br/>
            We are required to notify California residents, at or before the point of collection of their Personal Information, the categories of Personal Information collected and the purposes for which such information is used.
            <br/><br/>
            <b>Verifiable Requests to Delete, and Requests to Know.</b> Subject to certain exceptions, California residents have the right to make the following requests, up to twice every twelve (12) months:
            <ol style={{
                listStyleType: "inherit"
            }}>
                <li><u>Right of Deletion:</u> California residents have the right to request that we delete their Personal Information that we have collected about them, subject to certain exemptions.</li>
                <br/>
                <li><u>Right to Know & to Receive a Copy:</u> California residents have the right to request a copy of the specific pieces of Personal Information that we have collected about them in the prior twelve (12) months and to have this information sent to you via email.</li>
                <br/>
                <li><u>Right to Know & Right to Information:</u> California residents have the right to request that we provide them certain information about how we have handled their Personal Information in the prior twelve (12) months.</li>
            </ol>
            <b>Submitting Requests.</b> Requests to exercise the right of deletion, right to a copy, or the right to information may be submitted by contacting us at the email listed above.
            <br/><br/>
            <b>Right to Non-Discrimination.</b> The CCPA prohibits discrimination against California residents for exercising their rights under the CCPA. We do not and will not discriminate against you for exercising any of your CCPA rights.
            <br/><br/>
            <b>Financial Incentive.</b> We may offer you various financial incentives, free content, or other special offers when you provide us with contact information and identifiers such as your name and email address. You may withdraw from an incentive at any time by opting out. Generally, we do not assign monetary or other value to Personal Information, however, California law requires that we assign such value in the context of financial incentives. In such context, the value of the Personal Information is related to the estimated cost of providing the relevant financial incentive(s) for which the information was collected.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 9 – Notice for Residents of Nevada.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            Nevada law, specifically Senate Bill 220, allows customers who are residents of the state of Nevada to “opt out” of the sale or transfer of certain Personal Information, called “covered information.”
            <br/><br/>
            You have the right to opt out at any time and direct us not to use or transfer your Personal Information.
            <br/><br/>
            Requests to exercise your right to opt out may be submitted by contacting us at via email listed on our Website.
            <br/><br/>
            We will endeavor to comply with your request(s) as soon as reasonably practicable, which may depend on the number of requests we have received at or around the time of your request. If we need additional time, which we reasonably expect to be completed within thirty (30) days, we will contact you in writing and inform you of the reason for the delay.
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 10 – Notice for Residents of Europe and the European Union.</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            If you are a current resident of the European Union (“EU”), United Kingdom, Lichtenstein, Norway, or Iceland (“Covered European Countries”), you may have additional rights under the EU General Data Protection Regulation (the “GDPR”) with respect to your Personal Information, as we have outlined below.   
            <br/><br/>
            For individuals who are located in the Covered European Countries, we rely on legal bases for processing your information under Article 6 of the EU GDPR which can be found here: <u><a href="https://gdpr-info.eu/" target="_blank">https://gdpr-info.eu/.</a></u> We generally only process your data where: (a) we are legally required to; (b) where processing is necessary to perform any contracts we entered into with you; (c) for our legitimate interests to operate our business; or (d) or to protect Nutritt or your, property, rights, or safety, or where we have obtained your consent to do so.
            <br/><br/>
            Below is a list of the purposes described in our GDPR Privacy Policy with the corresponding legal bases for processing.
            </Typography>
        </div>
        <br/>
        <Typography style={{
            fontSize: '1.25rem'
        }}>
        <TableWrapper>
        <table style={{
            width: "100%",
        }}>
            <tr>
                <th>Category of Personal Information & Collection</th>
                <th>Why We Share Your Personal Information</th>
                </tr>
                <tr>
                    <td><b>Identifiers such as Personal IdentificationInformation</b> <br/><br/>
                    Automatically collected & Information You ProvideUs or our Third-Party Source.
                    </td>
                    <td>To maintain legal and regulatory compliance; To provide Services; To provide customer service; To ensure quality control; To enhance your user experience; To engage in marketing activities; To transfer to our third-party partners or other entities; and for any other legal purpose.</td>
                </tr>
                <tr>
                    <td>Customer records <br/><br/>
                    Automatically collected & Information You ProvideUs or our Third-Party Source.
                    </td>
                    <td>To maintain legal and regulatory compliance; To enforce our terms in our user agreement and other agreements; To provide Services; To provide customer services; To transfer to our third-party partners or other entities; To ensure quality control.</td>
                </tr>
                <tr>
                    <td>Protected classifications under California and federal <br/> law (ex- gender, age and citizenship) <br/> <br/>
                    Automatically collected & Information You Provide Us or our Third-Party Source.
                    </td>
                    <td>To maintain legal and regulatory compliance</td>
                </tr>
                <tr>
                    <td>Commercial information <br/> <br/> 
                    Automatically collected & Information You Provide Us or our Third-Party Source.
                    </td>
                    <td>To maintain legal and regulatory compliance; To provide Services; To provide customer services; To ensure quality control; For development purposes; To enhance your user experience; To engage in marketing activities; To transfer to our third-party partners or other entities; and for any other legal purpose.</td>
                </tr>
                <tr>
                    <td>Biometric information <br/> <br/> 
                    Automatically collected & Information You Provide Us or our Third-Party Source.
                    </td>
                    <td>To maintain legal and regulatory compliance; To provide Services.</td>
                </tr>
                <tr>
                    <td>Usage Data <br/> <br/>
                    Automatically collected
                    </td>
                    <td>To enforce our terms in our user agreement and other agreements; To provide Services; To provide customer services; To ensure quality control; For development purposes; To enhance your user experience; To transfer to our third-party partners or other entities; To engage in marketing activities; and for any other legal purpose.</td>
                </tr>
                <tr>
                    <td>Online Identifiers <br/> <br/> 
                    Automatically collected & Information You Provide Us or our Third-Party Source.
                    </td>
                    <td>To maintain legal and regulatory compliance.</td>
                </tr>
                <tr>
                    <td>Sensory data (ex – audio and visual information) <br/><br/>
                    Automatically collected & Information You Provide Us.
                    </td>
                    <td>To provide Services; To transfer to our third-party partners or other entities; To provide customer services; To ensure quality control.</td>
                    </tr>
                <tr>
                    <td>Professional or employment-related information <br/> <br/>
                    Information You Provide Us or our Third-PartySource.
                    </td>
                    <td>To maintain legal and regulatory compliance; To engage in marketing activities.</td>
                </tr>
                <tr>
                    <td>Inferences about preferences & characteristics <br/> <br/>
                    Automatically collected & Information You Provide Us or our Third-Party Source.
                    </td>
                    <td>To ensure quality control; For research and development purposes; To transfer to our third-party partners or other entities; To enhance your user experience.</td>
                </tr>
        </table>
        </TableWrapper>
        </Typography>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            <b>HOW AND WITH WHOM WE SHARE YOUR DATA</b>
            <br/><br/>
            We share Personal Information with our employees and agents who work on our behalf and provide us or you with services, as well as third parties who assist in the provision of your Services.
            <br/><br/>
            These parties include, without limitation:
            <ol style={{
                listStyleType: "inherit"
            }}>
                <li>Payment processors; and</li>
                <li>Hosting service providers; and</li>
                <li>Marketing service providers; and</li>
                <li>Product development service providers; and</li>
                <li>Customer success providers.</li>
                We also share Personal Information when we believe it is necessary to:
                <li>Comply with applicable law or respond to valid legal process, including requests from law enforcement or government agencies; and</li>
                <li>Protect us, our business or our users; and</li>
                <li>Maintain the security of our Services.</li>
            </ol>
            We also share information with third parties when you give us consent to do so.
            <br/><br/>
            <b>OUR SECURITY MEASURES.</b> <br/><br/>
            We seek to protect Personal Information using appropriate technical and organizational measures based on the type of Personal Information and applicable processing activity. We take security very seriously and have a robust security system in place to protect your Personal Information.
            <br/><br/>
            <b>HOW LONG WE RETAIN YOUR DATA FOR?</b>
            <br/><br/>
            We retain your Personal Information for as long as necessary to provide our Services to our clients. In some cases, we may retain your Personal Information past this time period, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation.
            <br/><br/>
            <b>YOUR PERSONAL INFORMATION RIGHTS </b>
            <br/><br/>
            You have certain rights with respect to your Personal Information, including those set forth below. For more information about these rights, or to submit a request by contacting us at the email listed above or any of the contact methods on our Website. 
            <br/><br/>
            Please give us a reasonable time to respond. Please also not that we are unable to respond to requests that are outside the scope of what is permissible or are frivolous.
            <br/><br/>
            In some cases, we may also need you to provide us with additional information, which may include Personal Information, if necessary to verify your identity and the nature of your request.  
            <ol style={{
                listStyleType: "inherit"
            }}>
                <li><b>Access to Your Information:</b> You can request information about the Personal Information we hold about you and request a copy of your Personal Information by emailing us at the email listed on our Website.</li><br/>
                <li><b>Modification:</b> If you believe that any Personal Information we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data.</li><br/>
                <li><b>Deletion:</b> You can request that we delete some or all of your Personal Information from our systems.  </li><br/>
                <li><b>Withdrawal of Consent:</b> If we are processing your Personal Information based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note that withdrawal of your consent is not permissible if we are required to turn over your Personal Information by virtue of law, legal proceeding, to protect Nutritt or an agent, or other reason as permitted by law.</li><br/>
                <li><b>Limited Purposes:</b> You can contact us to let us know that you object to the use or disclosure of your Personal Information for certain purposes.</li><br/>
                <li><b>Restriction of Processing:</b> You can ask us to restrict further processing of your Personal Information.</li><br/>
                <li><b>Right to File Complaint:</b> You have the right to lodge a complaint about Nutritt’ practices with respect to your Personal Information with the supervisory authority of your country.</li>
            </ol>
            <b>Transfers of Personal Information:</b> By using our Website, you acknowledge that any Personal Information about you, regardless of whether provided by you or obtained from a third party, is being provided to Nutritt in the United States and will be hosted on United States-based servers, and you authorize Nutritt to store and process your information to and in the United States. 
            <br />
            <br />
            <b>Third Party Tools:</b> Nutritt use and transfer to any other app of information received from Google APIs will adhere to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="blank">Google API Services User Data Policy</a>, including the Limited Use requirements.
            <br/>
            <br/>
            <b>How we use google data?</b> Can be found - <a href="/nutritt/google-data" target="_blank">here</a>
            <br/>
            <br/>
            <b>How does this application access, use, store, or share Google user data?</b> Nutritt will read the following data and use it in order to save scheduled coaching meetings in the users calendar: scope,  id_token, access_token and refresh_token
            </Typography>
        </div>
        <br/>
        <div>
            <Typography variant="h6" component="h6">
                <u><b>Section 11 – Questions?</b></u>
            </Typography>
        </div>
        <br/>
        <div>
            <Typography style={{
            fontSize: '1.25rem'
        }}>
            If you have any questions or concerns about our Privacy Policy, please email us at Support@nutritt.com
            </Typography>
        </div>



        </div>
    );

};


export default NutrittPrivacyPolicy;