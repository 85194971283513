import Tags from './Tags';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`;
const TagsContainer = styled.div`
margin: 40px 18px 24px 18px;
padding-bottom:30px;
display: grid;
grid-template-columns: repeat(4,minmax(77px,1fr));
grid-row-gap: 25px;
border-bottom: 1px solid #E0E3E0;
&:last-child{
    border-bottom:unset;
}
`;
export default function DietaryTags({ allTags, meal, setMeal, dietTemplate, setSelected, setNotSelected, notSelected, selected, showSelectedTags, setShowSelectedTags }) {
    const { tags, allergies } = allTags;
    const [selectedTags,setSelectedTags]=useState({tags:dietTemplate?.tags,restrictions:dietTemplate?.food_to_avoid})
    
useEffect(()=>{
    dietTemplate.tags=selectedTags?.tags;
    dietTemplate.food_to_avoid=selectedTags?.restrictions
    setMeal(selectedTags)
},[selectedTags])

    const handleTagClick = (selectedTag, isSelected, recomend) => {
     // !isSelected?setSelected(selected+1):setSelected(selected-1)
        setSelectedTags((m) => ({
            ...m,
            tags: isSelected
              ? m.tags?.filter((tag) => tag !== selectedTag)
              : [...m.tags, selectedTag],
          }));
        
          setShowSelectedTags((prev) => ({
            ...prev,
            tags: isSelected ? prev.tags?.filter((tag) => tag !== selectedTag) : [...prev.tags,selectedTag]
          }))
      };

    const handleAlergyClick = (selectedTag, isSelected,avoid) => {
      //  !isSelected?setNotSelected(notSelected+1):setNotSelected(notSelected-1)
        setSelectedTags((m) => ({
            ...m,
            restrictions: isSelected
              ? m.restrictions?.filter((tag) => tag !== selectedTag)
              : [...m.restrictions, selectedTag],
          }));

          setShowSelectedTags((prev) => ({
            ...prev,
            restrictions: isSelected ? prev.restrictions?.filter((tag) => tag !== selectedTag) : [...prev.restrictions,selectedTag]
          }))
      };
     
    return (
        <Container>
            <p>Recommend</p>
            <TagsContainer>
                {tags?.map((tag) => (             
                    <Tags tag={tag}
                        key={tag}
                        onTagClick={() => {
                            const isSelected = showSelectedTags?.tags?.indexOf(tag) !== -1;
                            const recomend = showSelectedTags?.tags?.indexOf(tag) !== -1;
                            handleTagClick(tag, isSelected,recomend)
                        }}
                        selectedTags={selectedTags?.tags}
                         isSelected={showSelectedTags?.tags?.indexOf(tag) !== -1}
                         recomend={showSelectedTags?.tags?.indexOf(tag) !== -1}
                        />
                ))}
            </TagsContainer>

            <p>Avoid</p>
            <TagsContainer>
                {allergies?.map((tag) => (
                    <Tags
                        tag={tag}
                        key={tag}
                        onTagClick={()=>{
                            const isSelected= showSelectedTags?.restrictions?.indexOf(tag) !== -1;
                            const avoid = showSelectedTags?.restrictions?.indexOf(tag) !== -1;
                            handleAlergyClick(tag,isSelected,avoid)
                        }}
                        selectedTags={selectedTags?.restrictions}
                         isSelected={showSelectedTags?.restrictions?.indexOf(tag) !== -1}
                         avoid={showSelectedTags?.restrictions?.indexOf(tag) !== -1}
                    />
                ))}
            </TagsContainer>

        </Container>
    );
}
