import styled from 'styled-components';
import { theme } from 'shared/theme/theme';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PieWrapper = styled.div`
  position: relative;
  ${FlexCenter};

  .title-wrapper {
    position: absolute;
    top: auto;
    left: auto;
    text-align: center;
    .title-number {
      margin-bottom: 10px;
      color: #3b425d;
      font-family: ${() => theme.typography.fontInter};
      font-size: 32px;
      font-weight: 700;
    }
    .title-text {
      font-family: ${() => theme.typography.fontInter};
      font-size: 14px;
      font-weight: 600;
    }

    & + div {
      ${FlexCenter};
    }
  }

  svg {
    display: flex;
  }
`;

export const PieLegendWrapper = styled.div`
  padding: 25px 50px 25px 25px;
  border: 1px solid ${() => theme.colors.grayLight};
  border-radius: 8px;
  display: flex;
  align-items: center;

  .title-wrapper {
    .title-number {
      margin-bottom: 7px;
      color: ${() => theme.colors.blackDefault};
      font-size: 20px;
    }
    .title-text {
      color: ${() => theme.colors.blackDefault};
      font-weight: 400;
    }
  }

  .legend-wrapper {
    margin-left: 20px;
    .legend-container {
      display: flex;
      align-items: center;
      & + .legend-container {
        margin-top: 16px;
      }
    }
    .legend-symbol {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    .legend-label {
      margin: 0 18px 0 11px;
      font-family: ${() => theme.typography.fontInter};
      font-size: 14px;
      font-weight: 500;
    }
    .legend-size {
      font-family: ${() => theme.typography.fontInter};
      font-size: 14px;
      font-weight: 400;
    }
    .legend-value {
      color: ${() => theme.colors.grayDarker};
    }
  }
`;
