import { useRequest } from 'hooks/useRequest';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from 'context/appContext';
import Table from 'rc-table';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Switch,
} from '@material-ui/core';
import { sort } from 'ramda';

const ManageComboComponentWrapper = styled.div`
  h1 {
    text-align: left;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  button {
    margin-left: 6px;
    background: #52c41a;
    color: white;
  }
`;
const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  td,
  th {
    text-align: center !important;
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    cursor: pointer;
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #52c41a;
    color: white;
  }
`;

const EditComboComponentDialog = ({
  title = 'Edit Combo Component',
  openDialog,
  handleClose,
  handleChange,
  handleSubmit,
  selectedValue,
}) => {
  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          name="name"
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          value={selectedValue?.name}
          onChange={(e) => {
            handleChange((oldValues) => ({ ...oldValues, name: e.target.value }));
          }}
          required
        />
        <Typography variant="body2" color="textSecondary">
          Required
        </Typography>
        <Switch
          checked={selectedValue?.isRequired}
          value={selectedValue?.isRequired}
          onChange={(e) => {
            handleChange((oldValues) => {
              const data = { ...oldValues, isRequired: !selectedValue?.isRequired };
              console.log({ data });
              return data;
            });
          }}
          name="isRequired"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateComboComponentDialog = EditComboComponentDialog;

const onRowDoubleClick = (record, index) => {
  console.log(`Double click nth(${index}) row of parent, record.name: ${record.name}`);
};

const ManageComboComponent = (props) => {
  const { provider } = useAppContext();
  const [comboComponent, setComboComponent] = useState([]);
  const [getAllComponents] = useRequest('provider/components');
  const [updateComboComponent] = useRequest('provider/combo-component', 'put');
  const [createComboComponent] = useRequest('provider/combo-component', 'post');
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const fetchAllComponent = useCallback(() => {
    getAllComponents().then((result = []) => {
      const data = result?.map((item, i) => ({
        key: i + 1,
        ...item,
        delete: 'Delete',
        isRequiredLabel: item.isRequired ? 'Yes' : 'No',
      }));
      setComboComponent(data);
    });
  }, []);

  useEffect(fetchAllComponent, []);

  const columns = useMemo(() => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 400,
      },
      {
        title: 'Required',
        dataIndex: 'isRequiredLabel',
        key: 'isRequiredLabel',
        width: 100,
      },
      {
        title: 'Action',
        dataIndex: 'delete',
        key: 'delete',
        width: 100,
        onCell: (record, index) => ({
          onClick(e) {
            e.stopPropagation();
            setSelectedValue(record);
            setShowDeleteConfirmation(true);
          },
        }),
      },
    ];
  }, [showModal, selectedValue]);

  const onRowClick = useCallback((record, index, event) => {
    setShowModal(true);
    setSelectedValue(record);
    if (event.shiftKey) {
      console.log('Shift + mouse click triggered.');
    }
  }, []);

  const saveComboComponentChanges = useCallback(
    (record = {}, isDelete) => {
      delete selectedValue.delete;
      updateComboComponent({ body: { ...record, ...selectedValue, isDelete } })
        .then((result) => {
          setShowModal(false);
          setSelectedValue({});
          fetchAllComponent();
          toast.success(result.message);
        })
        .catch((e) => {
          setShowModal(false);
          setSelectedValue({});
          toast.error('Oops!, something went wrong');
        });
    },
    [selectedValue],
  );

  const createComboComponentHandler = useCallback(() => {
    delete selectedValue.delete;
    if (selectedValue?.name) {
      createComboComponent({ body: { ...selectedValue, id: provider.id } })
        .then((result) => {
          setShowCreateModal(false);
          setSelectedValue({});
          fetchAllComponent();
          toast.success(result.message);
        })
        .catch((e) => {
          setShowModal(false);
          setSelectedValue({});
          toast.error('Oops!, something went wrong');
        });
    } else {
      toast.error('Please fill all the necessary details');
    }
  }, [selectedValue]);

  return (
    <ManageComboComponentWrapper>
      <HeaderWrapper>
        <h1>Manage Combo Components</h1>
        <Button color={'secondary'} onClick={() => setShowCreateModal(true)}>
          Add Combo Component
        </Button>
      </HeaderWrapper>
      <TableWrapper>
        <Table
          columns={columns}
          data={comboComponent}
          onRow={(record, index) => ({
            onClick: onRowClick.bind(null, record, index),
            onDoubleClick: onRowDoubleClick.bind(null, record, index),
          })}
        />
        <CreateComboComponentDialog
          title="Create Combo Component"
          openDialog={showCreateModal}
          selectedValue={selectedValue}
          handleSubmit={createComboComponentHandler}
          handleClose={() => setShowCreateModal(false)}
          handleChange={setSelectedValue}
        />
        <EditComboComponentDialog
          title="Edit Combo Component"
          openDialog={showModal}
          selectedValue={selectedValue}
          handleSubmit={() => saveComboComponentChanges()}
          handleClose={() => setShowModal(false)}
          handleChange={setSelectedValue}
        />
      </TableWrapper>
      <Dialog open={showDeleteConfirmation} onClose={()=>{
        setShowDeleteConfirmation(false);
      }}>
        <DialogTitle>Delete combo component ?</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary">
            This action can't be undone. All the referenced combo selection will also be deleted. Are you sure you want to
            delete this combo component?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowDeleteConfirmation(false);
          }}>Cancel</Button>
          <Button onClick={() => {
            saveComboComponentChanges({}, true);
            setShowDeleteConfirmation(false);
          }}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </ManageComboComponentWrapper>
  );
};

export default ManageComboComponent;
