import { useState } from 'react';
import styled from 'styled-components';
import PlanningDate from './PlanningDate';
import { useAppContext } from 'context/appContext';

const FlexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddNotesWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background-color: #949d9b80;
  ${FlexCenter};

  .add-notes-container {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 35px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  .title-wrapper {
    position: relative;
    padding: 28px 20px 10px;
    border-bottom: 1px solid #f6f5f8;

    img {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .note-title {
      color: #0b1d17;
      font-size: 16px;
      font-family: 'Roboto';
      line-height: 19px;
    }
  }

  .date-note-wrapper {
    padding: 24px 20px 0;
  }

  .notes-btn-wrapper {
    height: 300px;
    margin-top: 20px;
    padding: 14px;
    border: 1px solid #e0e3e0;
    border-radius: 6px;

    textarea {
      width: 100%;
      height: 80%;
      border: 0;

      ::placeholder {
        color: #949d9b;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .primary-btn {
      height: 42px;
      border-radius: 31px;
      ${FlexCenter};

      span {
        margin-right: 10px;
        font-size: 16px;
        font-family: 'Roboto';
        line-height: 19px;
      }
    }
  }

  @media (min-width: 992px) {
    .add-notes-container {
      width: 646px;
    }

    .title-wrapper {
      padding: 28px 24px 11px;
    }

    .date-note-wrapper {
      padding: 24px 48px 0;
    }

    label {
      margin-top: 24px;
      color: #51635d;
      font-size: 12px;
      font-family: 'Roboto';
      line-height: 14px;
      display: flex;
    }

    .notes-btn-wrapper {
      height: auto;
      margin-top: 7px;
      padding: 0;
      border: 0;

      textarea {
        height: 142px;
        margin-bottom: 24px;
        padding: 9px 14px;
        border: 1px solid #e0e3e0;
        border-radius: 6px;
      }
    }
  }
`;

export default function AddNotes({
  setIsNote,
  noteIndex,
  setDailyMeals,
  dailyMeals,
  setNote,
  note,
}) {
  const [dayIndex, setDayIndex] = useState(noteIndex);
  const { isMobile } = useAppContext();

  const updateNote = () => {
    setDailyMeals((prevState) => {
      const prevData = prevState.data;
      prevData[dayIndex] = { ...prevData[dayIndex], notes: note };
      return {
        ...prevState,
        data: prevData,
      };
    });
  };

  const handleSubmit = () => {
    updateNote();
    setIsNote(false);
  };

  console.log({ dailyMeals });

  return (
    <AddNotesWrapper>
      <div className="add-notes-container">
        <div className="title-wrapper">
          <img src="/icons/close-circle2.svg" alt="Close Icon" onClick={() => setIsNote(false)} />
          <div className="note-title">Add notes</div>
        </div>
        <div className="date-note-wrapper">
          <PlanningDate
            dailyMeals={dailyMeals}
            setNote={setNote}
            dayIndex={dayIndex}
            setDayIndex={setDayIndex}
            updateNote={updateNote}
          />
          {!isMobile && <label>Note</label>}
          <div className="notes-btn-wrapper">
            <textarea
              placeholder="Share today's tips, tasks or updates with your client..."
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />
            {isMobile ? (
              <button className="primary-btn" onClick={handleSubmit}>
                <span>Submit note</span>
                <img src="/icons/send-icon.svg" alt="Send Icon" />
              </button>
            ) : (
              <div className="btns-wrapper">
                <button onClick={() => setIsNote(false)}>Cancel</button>
                <button className="primary-btn" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </AddNotesWrapper>
  );
}
