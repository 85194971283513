import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GilroyFontWrapper } from 'shared/theme/style';
import { CartItem } from 'components/elements/cartItem/CartItem';
import { Button } from 'components/elements/button/Button';
import useDisplayAmount from 'hooks/useDisplayAmount';
import useCart from 'hooks/useCart';
import { useAppContext } from 'context/appContext';
import MinimumOrderBar from 'components/minimumOrderBar/MinimumOrderBar';
import { useHistory } from 'react-router-dom';

const ProviderItemStyle = styled.div`
  .name {
    color: #0b1d17;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .order_info {
    color: #757e89;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.28px;
  }
  .row_style {
    display: flex;
    gap: 8px;
  }
  .title_style {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color:#00092D;
  }
  .item_row {
    display: flex;
    justify-content: space-between;
    margin: 12px 16px 20px 16px;
    color: #0B1D17;
    }
    .item_row :nth-child(2) {
      color: #00092D
    }
  .column_style {
    flex-direction: column;
    display: flex;
    gap: 4px;
    flex: 1;
  }
  .remove__holder{
    justify-content: end;
    display: flex;
  }
  .remove {
    color: #00092d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    text-underline-offset: 4px;
    margin-right: 16px;
    cursor: pointer;
  }
  .total_sum {
    color: #00092d;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-underline-offset: 4px;
  }
  .custom_button {
    display: flex;
    width: 250px;
    height: 42px;
    padding: 14px 16px 12px 16px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    color: #00092D;
  }
  .button_style {
    display: flex;
    margin-top: 24px;
    margin-bottom: 18px;
    justify-content: center;
  }
  .provider-img-wrapper {
    margin-right: 10px;
    img {
      width: 40px;
      height: 40px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      object-fit: cover;
    }
  }
  .divider {
    padding-bottom: 14px;
    padding-top: 14px;
    border-bottom: 1px solid #e0e3e0;
    margin-left: 16px;
    margin-right: 16px;
  }
  .error_box {
    background-color: #fef1f0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 12px;
    padding-bottom: 5px;
  }
  .desktop_checkout_button{
    max-width: 100% !important;
    margin-top: 12px;
    font-family: 'Gilroy';
  }
`;

export const ProviderCardItem = ({
  logo,
  name,
  orderData,
  uniqMealData,
  shopMeals,
  minOrderAmount,
  buttonGotPressed,
  otherKitchens = [],
  userName
}) => {
  const { removeProviderFromCart, updateMealQty, removeFromCart } = useCart();
  const [totalSum, setTotalSum] = useState();
  const minValue = useDisplayAmount(minOrderAmount);
  const { isDesktop, user, setRouteBeforeSignup } = useAppContext();
  const history = useHistory();
  const [buttonGotPressedDesktop, setButtonGotPressedDesktop] = useState(false);

  var minValueNumber = parseFloat(minValue?.replace('$', ''));
  var totalSumNumber = parseFloat(totalSum?.replace('$', '').replace(',', ''));
  const calculateTotalCost = () => {
    let total = 0;
    uniqMealData?.forEach((item) => {
      const itemTotal = item.price * item.qty;
      total += itemTotal;
    });
    const sum = useDisplayAmount(total);
    setTotalSum(sum);
  };
  useEffect(() => {
    calculateTotalCost();
  }, [uniqMealData]);

  const handleCheckout = () => {
    localStorage.removeItem('unfullfilledOrders');
    if(minValueNumber > totalSumNumber){
      setButtonGotPressedDesktop(true);
      return;
    }
    const unfullfilledMeals = otherKitchens.map(kitchen => kitchen?.meals).flat();
    localStorage.setItem('unfullfilledOrders', JSON.stringify(unfullfilledMeals));
    localStorage.setItem('unfullfilledProviders', JSON.stringify(otherKitchens));

    localStorage.setItem('viewedStoreUrl', JSON.stringify(`/store/${userName}`));
    user ? history.push('/shop/checkout/order') : (history.push('/account/home'), setRouteBeforeSignup('/shop/checkout/order'))
    window.scrollTo(0,0);
  }
  
  return (
    <GilroyFontWrapper>
      <ProviderItemStyle>
        <div>
          <div className="divider">
            <div className="row_style">
              <div className="provider-img-wrapper">
                <img src={logo} onError={(e) => { e.target.src = '/images/avatar.png' }}/>
              </div>
              <div className="column_style">
                <text className="name">{name}</text>
                <text className="order_info">{orderData}</text>
              </div>
            </div>
            {isDesktop && <Button isDisabled={!uniqMealData?.length || (minValueNumber > totalSumNumber && buttonGotPressedDesktop)} className={'desktop_checkout_button'} type={'mobile_save'} title={'Check out'} onClick={handleCheckout} />}
            {uniqMealData?.length > 0 && minValueNumber > totalSumNumber && 
            <MinimumOrderBar
              totalSumNumber={totalSumNumber}
              minValueNumber={minValueNumber}
              buttonGotPressed={buttonGotPressed || buttonGotPressedDesktop}
            />}
          </div>
          {uniqMealData?.length > 0 && <>
            <div className="item_row">
              <text className="title_style">{uniqMealData?.length} items</text>
              <text
                className="total_sum"
              >
                ${totalSumNumber}
              </text>
            </div>
            {uniqMealData?.map((item, index) => {
              return (
                <CartItem
                  index={index}
                  svg={item?.img || item.images[0]}
                  title={item?.name}
                  price={useDisplayAmount(item?.price)}
                  meal={item}
                  calculateTotalAmount={calculateTotalCost}
                  updateMealQty={updateMealQty}
                  mealID={item.id}
                  removeFromCart={removeFromCart}
                  orderLimit={item?.orderLimit}
                />
              );
            })}
            <div className='remove__holder'>
            <text
              className="remove"
              onClick={() => {
                removeProviderFromCart(uniqMealData);
              }}
            >
              Remove all
            </text>
            </div>
            {!isDesktop &&
            <div className="button_style">
              <Button title="Add more" className="custom_button" onClick={shopMeals} />
            </div> }
          </>}
        </div>
      </ProviderItemStyle>
    </GilroyFontWrapper>
  );
};

