import { theme } from 'shared/theme/theme';
import styled from 'styled-components';

export const KitchenMealsDesktopWrapper = styled.div`
  overflow: hidden;
  display: flex;

  .kitchen-meals-wrapper {
    overflow: auto;
    width: calc(100vw - 336px);
    background-color: ${() => theme.colors.secondary};
  }

  .categories-sticky-wrapper {
    max-width: 1105px;
    margin: 0 auto;
    padding: 10px 0 0 24px;
  }

  .categories-container {
    position: fixed;
    top: -53px;
    left: 0;
    right: 0;
    z-index: 4;
    width: calc(100vw - 340px);
    height: 52px;
    /* padding: 10px 0 0 24px; */
    background-color: ${() => theme.colors.secondary};
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease-in-out;
    &.categoriesSticky {
      top: 76px;
      transition: all 0.5s ease-in-out;
    }
    .tab_container {
      position: relative;
      z-index: 1;
      overflow: auto;
      padding-right: 16px;
      display: flex;
      align-items: center;
      gap: 20px;
      &::-webkit-scrollbar {
        height: 0;
        display: none;
      }
      .nav_item {
        height: 42px;
        padding: 0;
        border-bottom: 2px solid ${() => theme.colors.secondary} !important;
        border-radius: 0;
        transition: all 0.3s ease-in-out;
        &.active {
          color: #00092d;
          border-color: #00092d !important;
          a {
            font-weight: 600;
          }
        }
        .category-wrapper {
          width: max-content;
        }
        a {
          height: 40px;
          padding: 0 12px;
          background-color: ${() => theme.colors.secondary};
          color: #000a2c;
          border: 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }
      }
    }
    &.endOfCategorySticky {
      top: -48px;
      transition: all 0.5s ease-in-out;
    }
  }

  .kitchen-meals-container {
    max-width: 1105px;
    margin: 0 auto;
    padding: 0 24px 70px;
    overflow: clip;
  }

  .categories-tabs-container {
    display: flex;
    gap: 20px;
    white-space: nowrap;
    .category-container {
      width: max-content;
      height: 36px;
      padding: 0 32px;
      background-color: ${() => theme.colors.secondary};
      border-radius: 30px;
      display: flex;
      align-items: center;
      a {
        color: ${() => theme.colors.grayDarker};
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
      }
      &:first-child {
        background-color: ${() => theme.colors.grayLighter};
        a {
          color: ${() => theme.colors.primaryDarker};
        }
      }
    }
  }

  .delivery-dates-wrapper {
    display: none;
  }

  .categories-dishes-wrapper,
  .meals-no-categories-wrapper {
    .category-wrapper {
      margin-top: 32px;
    }
    .dishes-title {
      margin-bottom: 20px;
      color: ${() => theme.colors.primaryDarker};
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      text-transform: capitalize;
    }
    .dishes-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px 14px;
    }
  }

  .meals-no-categories-wrapper {
    padding-top: 32px;
    padding-bottom: 12px;
  }

  aside {
    position: fixed;
    top: auto;
    right: 0;
    z-index: 3;
    width: 336px;
    height: 100%;
    background-color: ${() => theme.colors.grayLighter};
    border-left: 1px solid ${() => theme.colors.grayLighter};
  }

  @media (max-width: 1439px) {
    .kitchen-meals-container {
      max-width: 944px;
      padding: 0 20px 50px;
    }

    .categories-sticky-wrapper {
      max-width: 944px;
      padding-left: 20px;
    }

    .categories-tabs-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .delivery-dates-wrapper {
        display: flex;
        & > div {
          display: flex;
        }
      }
    }

    .categories-tabs-container {
      gap: 0;
      .category-container {
        padding: 0 18px;
      }
    }

    .categories-dishes-wrapper .dishes-container,
    .meals-no-categories-wrapper .dishes-container {
      gap: 32px 24px;
    }
  }

  .more-categories {
    position: relative;
    .categories-mask {
      position: fixed;
      inset: 0;
      z-index: 2;
    }
    .categories-dots {
      padding: 0 12px;
      color: ${theme.colors.grayDarker};
      font-size: 16px;
      font-weight: 600;
      line-height: 36px;
      cursor: pointer;
      &.active {
        background-color: #e0e4e8;
        border-radius: 30px;
      }
    }
    .categories-menu-wrapper {
      position: absolute;
      top: 41px;
      left: 0;
      z-index: 3;
      overflow: auto;
      width: 191px;
      max-height: 206px;
      padding: 6px 0 8px;
      border: 1px solid ${theme.colors.lightGrey};
      border-radius: 6px;
      background-color: ${theme.colors.secondary};
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
      ::-webkit-scrollbar-thumb{
        background-color: transparent; !important
      }
    }
    .category-container {
      width: 100%;
      padding: 0;
      &:first-child {
        background-color: ${theme.colors.secondary};
      }
      a {
        width: 100%;
        height: 32px;
        padding: 0 12px 0 10px;
        color: ${theme.colors.primaryDarker};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        transition: all 0.2s linear;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        cursor: pointer;
        &.selected {
          background-color: ${theme.colors.grayLighter};
        }
      }
    }
  }
  @media (max-width: 1279px) {
    .kitchen-meals-container,
    .categories-sticky-wrapper {
      max-width: 688px;
    }

    .categories-dishes-wrapper .dishes-container,
    .meals-no-categories-wrapper .dishes-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 32px 14px;
    }
  }
`;

export const MealDetailsModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: #0b1d17a6;
  display: flex;
  align-items: center;
  justify-content: center;

  .meal-details-mask {
    position: fixed;
    inset: 0;
  }

  .meal-details-motion {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-color: ${theme.colors.secondary};
    border-radius: 10px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;
