import Slider from 'react-slick';
import useCart from 'hooks/useCart';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { useAppContext } from 'context/appContext';
import MealCard from 'components/elements/MealCard';
import { useShopContext } from 'context/shopContext';
import { ReactComponent as Angle } from 'icons/angle.svg';
import { FavoriteKitchenCategoriesWrapper } from './style';
import { Button } from 'components/elements/button/Button';
import produce from 'immer';
import Bummer from 'components/shop/FavoriteKitchen/Bummer';
import Area from 'components/shop/FavoriteKitchen/Area';
import { useEffect, useState } from 'react';
import AddressesList  from 'pages/shop/Addresses/AddressesList';
import { BottomSheet } from 'react-spring-bottom-sheet';

function SampleNextArrow({ onClick }) {
  return (
    <div className={classNames("arrow arrow-next")} onClick={onClick}>
      <Angle />
    </div>
  );
}

function SamplePrevArrow({ onClick }) {
  return (
    <div className={classNames("arrow arrow-prev")} onClick={onClick}>
      <Angle />
    </div>
  );
}

export default function FavoriteKitchenCategories({
  storeCategoriesMeals,
  setOpen,
  setMealDetails,
  setIsAddWithoutQty,
  moveToMeals,
  isDisabled=false
}) {
  const history = useHistory();
  const { cart, numBummer, setNumBummer, updateSettings, setShowBummer, showBummer} = useShopContext();
  const { profile: { favoriteKitchenData }, setHeaderAddress, showArea, setShowArea } = useAppContext();
  const { addToCart, updateMealQty, removeFromCart } = useCart();
  const [openAddress, setOpenAddress] = useState();
  const { isMobile, isDesktop } = useAppContext();

  const [showMealsTab, setShowMealsTab  ] = useState({orderHistoryMeals:[],
    mostPopularMeals:[],
    otherMeals:[]})

  useEffect(() => {
    let obj = {
      orderHistoryMeals:[],
      mostPopularMeals:[],
      otherMeals:[],
  
    }
    Object.keys(storeCategoriesMeals).forEach((category, index)=>{
     if(category === 'mostPopularMeals' ){
        if(!storeCategoriesMeals[category] || storeCategoriesMeals[category]?.length === 0 ){
          obj = {...obj,mostPopularMeals :storeCategoriesMeals['otherMeals']?.reverse()}
        }else{
          obj = {...obj,mostPopularMeals :storeCategoriesMeals[category]}
        }
     }else{
         obj = {...obj,[category] :storeCategoriesMeals[category]}
     }

    })
    setShowMealsTab(obj)
  
   
  }, [storeCategoriesMeals])

  const categoriesTitle = (category) => {
    let title = '';
    if (category === 'orderHistoryMeals') {
      title = 'Buy again';
    } else if (category === 'mostPopularMeals') {
      title = 'Most popular';
    } else if (category === 'otherMeals') {
      title = 'You also might like';
    }
    return title;
  };

  const getMeal = (meal, mealInCart) => {
    setOpen(true);
    setMealDetails(meal);
  };

  const settings = {
    dots: isMobile ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    nextArrow: <div><SampleNextArrow /></div>,
    prevArrow: <div><SamplePrevArrow /></div>,
  };

  return (
    <FavoriteKitchenCategoriesWrapper>
      <div className="kitchen-categories-wrapper">
        {Object.keys(showMealsTab)?.map((category, index) => (     
      !Array.isArray(showMealsTab[category]) 
            || (Array.isArray(showMealsTab[category]) && showMealsTab[category]?.length <=0) ? <></> :

            <div className={classNames('kitchen-category-container', { lessMeal: showMealsTab[category]?.length < 5 })} key={index}>
                { 
                <div className="category-title-wrapper">
                  <div className="category-title">{categoriesTitle(category)}</div>
                  <div className="total-meals" onClick={moveToMeals}>
                    See all
                  </div>
                  </div>
                }

            {isMobile ? (
              <div className="category-meals-wrapper">
                {showMealsTab[category]?.slice(0, 5)?.map((meal) => {
                  const { images, name, price, id } = meal;
                  const mealInCart = cart?.find((meal) => meal.id === id);
                  return (
                    <MealCard
                      key={id}
                      className="meal-card-container"
                      mealImg={
                        images?.length && images[0]?.startsWith('http')
                          ? images[0]
                          : '/images/sexy_just_eat_now.jpg'
                      }
                      title={name}
                      price={price.toFixed(2)}
                      count={mealInCart?.qty}
                      onClickRemove={() => {
                        if (mealInCart.qty == 1) {
                          removeFromCart(meal.id);
                        } else {
                          updateMealQty(meal, +mealInCart.qty - 1);
                        }
                      }}
                      onClickAdd={() => {
                        if (isDisabled || isDesktop) {
                          (!mealInCart || mealInCart?.qty < mealInCart?.orderLimit) && addToCart(meal);
                          } else {
                            setShowBummer(true);
                          }              
                      }}
                      moveTo={isDisabled ? `/store/${favoriteKitchenData?.username}#${id}`: ''}
                      onClick={() => {
                        if(isDisabled || isDesktop){
                          getMeal(meal, mealInCart)
                          updateSettings(
                            produce((draft) => {
                              draft.provider = favoriteKitchenData;
                            }),
                          );      
                        } else {
                          setShowBummer(true);
                        }
                      }}
                      orderLimit={meal?.orderLimit}
                      />
                  );
                })}
              </div>
            ) : (
              <Slider {...settings}>
                {showMealsTab[category]?.slice(0, 5)?.map((meal) => {
                  const { images, name, price, id } = meal;
                  const mealInCart = cart?.find((meal) => meal.id === id);
                  return (
                    <MealCard
                      key={id}
                      className="meal-card-container"
                      mealImg={
                        images?.length && images[0]?.startsWith('http')
                          ? images[0]
                          : '/images/sexy_just_eat_now.jpg'
                      }
                      title={name}
                      price={price.toFixed(2)}
                      count={mealInCart?.qty}
                      onClickRemove={() => {
                        if (mealInCart.qty == 1) {
                          removeFromCart(meal.id);
                          moveToMeals();
                        } else {
                          updateMealQty(meal, +mealInCart.qty - 1);
                          moveToMeals();
                        }
                      }}
                      onClickAdd={() => {
                        mealInCart?.qty < mealInCart?.orderLimit && addToCart(meal);
                        moveToMeals();
                      }}
                      moveTo={`/store/${favoriteKitchenData?.username}#${id}`}
                      onClick={() => getMeal(meal, mealInCart)}
                      orderLimit={meal?.orderLimit}
                      order
                    />
                  );
                })}
              </Slider>
            )}
          </div>
        ))}
      </div>
      <div className="full-menu-btn-wrapper">
        <Button
          title={`View full menu (${storeCategoriesMeals?.totalMealCount})`}
          type="secondary"
          onClick={moveToMeals}
        />
      </div>
      {openAddress &&
       <BottomSheet
        open={openAddress}
        onDismiss={() => setOpenAddress(false)}
        scrollLocking={false}
        expandOnContentDrag={true}
        snapPoints={({ maxHeight }) => [maxHeight * 0.99]}
        className="addresses-bottomSheet"
      >
        <AddressesList open={openAddress} setOpen={setOpenAddress} selectHeaderAddress={(address) => setHeaderAddress(address)} setShowBummer={setShowBummer} />
      </BottomSheet> 
      }
      {
        showBummer && isMobile && <Bummer providerName={favoriteKitchenData?.name} setShowBummer={setShowBummer} setOpen={setOpenAddress} />
      }
     {
        (showArea && isDisabled) && <Area showArea={showArea} setShowArea={setShowArea}/>
      }

    </FavoriteKitchenCategoriesWrapper>
  );
}
