import React from 'react'
import styled from 'styled-components';
import moment from 'moment';
import { useAppContext } from 'context/appContext';
import { displayDateTime } from 'utils';

const PayoutDetailsStyle = styled.div`
display: flex;
height: 172px;
padding: 16px 15px 16px 16px;
flex-direction: column;
justify-content: center;
gap: 16px;
background: #FFF;
.status{
    .orderNumber{
        color: ${({ upcomingPayout }) => (upcomingPayout ? '#00092D' : '#283241')};
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .status-pill{
        display: flex;
        padding: 3px 8px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 80px;
        color: #0B1D17;
        text-align: center;
        font-family: Gilroy;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }
    .paid{
        background: #C2FDC2;
    }
    .partiallypaid{
        background: #FDE2C6;
    }
    .voided{
        background: #FDBCB4;
    }
}
.flex{
    display: flex;
    justify-content: space-between;
}
.orderData{
    .date-time{
        border-bottom: 1px solid #E9EAEC;
        padding-bottom: 10px;
    }
    .title{
        color: #757E89;
        font-family: Gilroy;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.22px;
    }
    .desc{
        color: #000A2C;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .amount{
        font-size: 14px !important;
        font-weight: 500 !important;
        line-height: 20px !important;     
    }
    .value{
        font-weight: 400 !important;
    }
}
`
const PayoutDetails = ({ Details, upcomingPayout=false }) => {
    const { id, datePaid, periodStart, periodEnd, amount, paidAmount, status, createdAt, friendlyOrderNum } = Details;
    const { isMobile } = useAppContext();
  return (
    <PayoutDetailsStyle upcomingPayout={upcomingPayout}>
      <div className='status flex'>
            <text className='orderNumber'>{friendlyOrderNum}</text>
            <div className={`status-pill ${status.toLowerCase()}`}>
            {status === 'partiallyPaid' ? isMobile ? 'Partially paid' : 'Partly paid' : status === 'pending' ? '' : status}
            </div>
        </div>
        <div className='orderData'>
          <div className='date-time'>
            <div className='title flex'>
                <text>CREATED DATE</text>
                <text>PERIOD START - END</text>
            </div>
            <div className='desc flex'>
                <span>{displayDateTime(createdAt,'MMM D, YYYY')}</span>
                <span>{displayDateTime(periodStart, 'MMM D, YYYY')} – {displayDateTime(periodEnd,'MMM D, YYYY')}</span>
            </div>
          </div>
            <div style={{paddingTop:"10px"}}>
                <div className='flex'>
                    <text className='title amount'>Amount</text>
                    <span className='desc value'>${typeof amount === 'number' ? parseFloat(amount.toFixed(2)) : amount}</span>
                </div>
                <div className='flex'>
                    <text className='title amount'>Paid amount</text>
                    <span className='desc'>${paidAmount > 0 ? paidAmount : 0}</span>
                </div>
            </div>
        </div>
    </PayoutDetailsStyle>
    )
}

export default PayoutDetails