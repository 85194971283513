import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as NavAccount } from 'assets/nav-account.svg';
import { ReactComponent as NavHome } from 'assets/nav-home.svg';
import { ReactComponent as NavTracker } from 'assets/nav-tracker.svg';
import { ReactComponent as NavOrders } from 'assets/nav-orders.svg';
import { useLocation } from 'react-router-dom';
import { GilroyFontWrapper } from 'shared/theme/style';
import { useAppContext } from 'context/appContext';
import Navigation from 'components/elements/navigation/Navigation';

const BottomNavigationNewStyle = styled.div`
  height: 70px;
`

export const BottomNavigation = () => {
  const location = useLocation();
  const { user } = useAppContext();
  const userType = user.type.toString();
  const activeRoute = userType === "guest" || userType === "visitor" ? "/shop/track-quiz" : "/shop/pantry";

  const hitBuzzer = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: 'navigation_click' }));
      return;
    }
  };

  const navLinks = [
    {
      to: '/shop/kitchen-home',
      onClick: hitBuzzer,
      active: (location.pathname === '/shop/kitchen-home'),
      icon: <NavHome className="icon" />,
      title: "Home"
    },
    {
      to: userType === "guest" || userType === "visitor" ? "/shop/track-quiz" : "/shop/pantry",
      onClick: hitBuzzer,
      active: (location.pathname === activeRoute) && 'active',
      icon: <NavTracker className="icon" />,
      title: "Tracker"
    },
    {
      to: '/shop/order',
      onClick: hitBuzzer,
      active: (location.pathname === '/shop/order') && 'active',
      icon: <NavOrders className="icon" />,
      title: "Orders"
    },
    {
      to: '/shop/account',
      onClick: hitBuzzer,
      active: location.pathname.includes('/shop/account') && 'active',
      icon: <NavAccount className="icon" />,
      title: "Account"
    }
  ]

  return (
    <GilroyFontWrapper>
      <BottomNavigationNewStyle></BottomNavigationNewStyle>
      <Navigation navLinks={navLinks}/>
    </GilroyFontWrapper>
  );
};
