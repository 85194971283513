import { getDetails } from 'use-places-autocomplete';

const getAddress = async (placeId, label) => {
    const placeDetails = await getDetails({ placeId });
    let clickedAddress = {
        address: placeDetails.formatted_address,
        address2: '',
        city: placeDetails.address_components.find((component) =>
            component.types.includes('locality') || component.types.includes('sublocality'),
        )?.long_name,
        state: placeDetails.address_components.find((component) =>
            component.types.includes('administrative_area_level_1'),
        )?.long_name,
        zipcode: placeDetails.address_components.find((component) =>
            component.types.includes('postal_code'),
        )?.short_name,
        userAddressLatLong: {
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
        },
        label
    }
    return clickedAddress;
}

export default getAddress;