import Input from 'components/Input';
import styled from 'styled-components';
import { useState, useEffect, useMemo ,useCallback} from 'react';
import { useRequest } from 'hooks/useRequest';
import { toast } from 'react-toastify';
import { useAppContext } from 'context/appContext';
import DatePicker from 'react-datepicker';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import moment from 'moment';
import Progress from 'components/Progress';
import Select from 'react-select';
import { PromoCodeMultipleForm } from './PromoCodeMultiForm';
import autoAnimate from '@formkit/auto-animate';
import { useRef } from 'react';

const PromoCodeModal = styled.div`
    position: fixed;
    inset: 0px;
    z-index: 111;
    background-color: rgb(28 28 28 / 60%);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top:0px;
    font-family: 'Roboto';
    font-style: normal;
    
 .invite-box{
    position: fixed;
    width:570px !important;
    padding-bottom:35px;
    background:#FFFFFF;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .close-img-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        img{
         cursor:pointer;
        }
    }
    .send-invite{
        font-weight: 700;
        font-size: 16px;
        color: #0B1D17;
        padding-bottom: 11px;
        border-bottom: 1px solid #F6F5F8;
        margin-bottom: 24px;
        padding-left: 20px;
        p{
           padding-left:0px !important;
        }  
    }

   

   .coupon-form{
      display:flex;
      flex-direction:column;
      transition: height 0.3s ease-in-out;


      .multiple-form-div{
         position:static !important;
         height: auto;
         overflow-y: scroll;
         overflow-x:hidden;
         scroll-behavior: smooth;
         max-height: 350px;
         transition: height 0.3s ease-in-out;

         ::-webkit-scrollbar {
            width: 5px;
            height: 100px;
          }
      }

      .input-container{
         margin-top:0 !important;
         width:auto;
         label{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            margin-bottom:7px;
         }
         div:nth-child(1){
            display: flex;
            flex-direction: column;
         }
      }

      input{
         width:230px;
         height:44px;
         background: rgb(255, 255, 255);
         border: 1px solid rgb(224, 227, 224);
      }
      .cEOuE{ 
        width:230px;
        margin:0;
        .select-option:hover {
          background: #F6F5F8;
          color:#949D9B;
        }
        .selected-value.selectedValue {
          border-color: #E0E3E0;
        }
        .selected-value{
         color: #949D9B;
        }
      }

      .name-type{
         display:flex;
         justify-content:center;
         align-items: center;
         gap:16px;
         margin-bottom:24px;
      }

      .selectWrapper{
         display: flex;
         flex-direction: column;
         label{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #51635D;
            margin-bottom: 7px;
         }
         .css-yk16xz-control, .css-xd1qmr-control {
            width:230px;
            height:44px;
            border: 1px solid #E0E3E0;
            border-radius: 6px;
         }
         .css-g1d714-ValueContainer{
            height: 100%;
         }
         .css-1wa3eu0-placeholder , .css-1uccc91-singleValue{
            color:#949D9B !important;
         }
         // .css-1hb7zxy-IndicatorsContainer{
         //    padding-right: 10px;
         //    margin-top: -16px;
         // }
         .css-2613qy-menu{
            color:#949D9B !important;
         }
         .image{
            margin-right: 10px;
            cursor:pointer;
         }
      }

      .max-input{
         input{
            background-color: #F6F5F8 !important;
         }
      }

      .name-type: nth-child(3){
         input{
           background:url('/icons/dolar.svg') no-repeat;
           background-position: 93% 50%;
         }
         input:nth-child(2){
           background: #F6F5F8 !important;
        }
     }
     
     .name-type: nth-child(4){
        gap:5px !important;
        padding-left:14px;
        margin-bottom: 12px !important;
       .input-span{
        display:flex;
        .calendar{
           position: relative;
           right: 27px;
           top: 32px;
           cursor:pointer;
        }

         &.isLogMealsModal {
            margin-bottom: -15px;
            padding: 0;
            .react-datepicker-wrapper {
              display: flex;
              align-items: center;
            }
            .current-date {
              color: #52C41A;
              font-weight: 400;
            }
          }
          &.notSelected .react-datepicker__month .react-datepicker__day--selected,
          &selected .react-datepicker__month .react-datepicker__day--today {
            // background-color: #f0faeb;
            color: #216ba5;
            font-weight: 400;
          }
          &.selected .react-datepicker__month .react-datepicker__day--selected,
          &.selected .react-datepicker__month .future-day.react-datepicker__day--selected,
          &.notSelected .react-datepicker__month .react-datepicker__day--today {
            // background-color: #52c41a;
            color: #ffffff;
            font-weight: 700;
          }
          .react-datepicker__month .react-datepicker__day.future-day {
            background-color: #216ba5;
            color: #aaa;
          }
          .react-datepicker-wrapper {
            width: fit-content;
          }
          .current-date {
            color: #000000;
            font-size: 16px;
            font-family: Roboto;
            font-weight: 700;
            cursor: pointer;
          }
          .react-datepicker,
          .react-datepicker__month-container {
         
            font-family: "Helvetica Neue",helvetica,arial,sans-serif;
            font-size: .8rem;
            background-color: #fff;
            color: #000;
            border: 1px solid #aeaeae;
            border-radius: 0.3rem;
            display: inline-block;
         

          .react-datepicker__navigation {
            -webkit-align-items: center;
            align-items: center;
            background: none;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            position: absolute;
            top: 2px;
            padding: 0;
            border: none;
            z-index: 1;
            height: 32px;
            width: 32px;
            text-indent: -999em;
            overflow: hidden;
          }
          }
          .react-datepicker__navigation-icon--next {
            left: -10px;
          }
          .react-datepicker__navigation-icon--previous {
           left: 10px;
             justify-content: center;
          }
          .react-datepicker-popper {
            width: 300px;
            z-index: 100;

          
          }
          .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
            border-bottom-color: #fff;
          }

          
          .react-datepicker {
            border: 0;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
            transform: translate3d(-18%, 36px, 0px);

            .react-datepicker__triangle::before,
            .react-datepicker__triangle::after {
               border-bottom-color: #fff;
              transform: translate3d(5px, 0px, 0px);
            }

            
          .react-datepicker__header {
   
            text-align: center;
            background-color: #f0f0f0;
            border-bottom: 1px solid #aeaeae;
            border-top-left-radius: 0.3rem;
            padding: 8px 0;
            position: relative;
        }
            .react-datepicker__current-month {
              margin-top: 0;
              color: #000;
              font-weight: 700;
              font-size: .944rem;
            }
            .react-datepicker__day-names {
              margin-bottom: -15px;
              color: #51635d;
              font-size: 11px;
              font-weight: 400;
              line-height: 12px;
            }
          }
          .react-datepicker__day-name {
            width: 32px;
            height: 32px;
            margin: 5px;
          }
          .react-datepicker__navigation-icon::before {
            width: 8px;
            height: 8px;
           
          }
          .react-datepicker__navigation-icon::before {
            border-width: 2px 2px 0 0;
          }
          .react-datepicker__month {
            margin: 0;
            .react-datepicker__day,
            .react-datepicker__day--disabled,
            .react-datepicker__day--keyboard-selected,
            .react-datepicker__day--selected {
              width: 32px;
              height: 32px;
              margin: 5px;
             
              font-size: 14px;
              font-family: 'Roboto';
              line-height: 32px;
            }
            .react-datepicker__day,
            .react-datepicker__day--selected.react-datepicker__day--outside-month,
            .react-datepicker__day--keyboard-selected {
         
           
            }
            .react-datepicker__day--outside-month {
              visibility: hidden;
            }
          }
          .react-datepicker__navigation--previous {
            left: auto;
            // right: 60px;
          }
        }
      }
      .amount-type{
         display:flex;
         gap:16px;
         margin-bottom:24px;
         margin-left:44px;
         display:flex;
         flex-direction:row !important;
         align-items:center !important;
         .calendar{
            position: relative;
            right: 41px;
            top: 13px;
            cursor:pointer;
         }
         &.isLogMealsModal {
            margin-bottom: -15px;
            padding: 0;
            .react-datepicker-wrapper {
              display: flex;
              align-items: center;
            }
            .current-date {
              color: #52C41A;
              font-weight: 400;
            }
          }
          &.notSelected .react-datepicker__month .react-datepicker__day--selected,
          &selected .react-datepicker__month .react-datepicker__day--today {
            // background-color: #f0faeb;
            color: #216ba5;
            font-weight: 400;
          }
          &.selected .react-datepicker__month .react-datepicker__day--selected,
          &.selected .react-datepicker__month .future-day.react-datepicker__day--selected,
          &.notSelected .react-datepicker__month .react-datepicker__day--today {
            // background-color: #52c41a;
            color: #ffffff;
            font-weight: 700;
          }
          .react-datepicker__month .react-datepicker__day.future-day {
            background-color: #216ba5;
            color: #aaa;
          }
          .react-datepicker-wrapper {
            width: fit-content;
          }
          .current-date {
            color: #000000;
            font-size: 16px;
            font-family: Roboto;
            font-weight: 700;
            cursor: pointer;
          }
          .react-datepicker,
          .react-datepicker__month-container {
         
            font-family: "Helvetica Neue",helvetica,arial,sans-serif;
            font-size: .8rem;
            background-color: #fff;
            color: #000;
            border: 1px solid #aeaeae;
            border-radius: 0.3rem;
            display: inline-block;
         

          .react-datepicker__navigation {
            -webkit-align-items: center;
            align-items: center;
            background: none;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            position: absolute;
            top: 2px;
            padding: 0;
            border: none;
            z-index: 1;
            height: 32px;
            width: 32px;
            text-indent: -999em;
            overflow: hidden;
          }
          }
          .react-datepicker__navigation-icon--next {
            left: -10px;
          }
          .react-datepicker__navigation-icon--previous {
           left: 10px;
             justify-content: center;
          }
          .react-datepicker-popper {
            width: 300px;
            z-index: 100;

          
          }
          .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
            border-bottom-color: #fff;
          }

          
          .react-datepicker {
            border: 0;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
            transform: translate3d(-18%, 36px, 0px);

            .react-datepicker__triangle::before,
            .react-datepicker__triangle::after {
               border-bottom-color: #fff;
              transform: translate3d(5px, 0px, 0px);
            }

            
          .react-datepicker__header {
   
            text-align: center;
            background-color: #f0f0f0;
            border-bottom: 1px solid #aeaeae;
            border-top-left-radius: 0.3rem;
            padding: 8px 0;
            position: relative;
        }
            .react-datepicker__current-month {
              margin-top: 0;
              color: #000;
              font-weight: 700;
              font-size: .944rem;
            }
            .react-datepicker__day-names {
              margin-bottom: -15px;
              color: #51635d;
              font-size: 11px;
              font-weight: 400;
              line-height: 12px;
            }
          }
          .react-datepicker__day-name {
            width: 32px;
            height: 32px;
            margin: 5px;
          }
          .react-datepicker__navigation-icon::before {
            width: 8px;
            height: 8px;
           
          }
          .react-datepicker__navigation-icon::before {
            border-width: 2px 2px 0 0;
          }
          .react-datepicker__month {
            margin: 0;
            .react-datepicker__day,
            .react-datepicker__day--disabled,
            .react-datepicker__day--keyboard-selected,
            .react-datepicker__day--selected {
              width: 32px;
              height: 32px;
              margin: 5px;
             
              font-size: 14px;
              font-family: 'Roboto';
              line-height: 32px;
            }
            .react-datepicker__day,
            .react-datepicker__day--selected.react-datepicker__day--outside-month,
            .react-datepicker__day--keyboard-selected {
         
           
            }
            .react-datepicker__day--outside-month {
              visibility: hidden;
            }
          }
          .react-datepicker__navigation--previous {
            left: auto;
          }
        }
      }
      .date-type{
         display:flex;
         gap:16px;
         margin-bottom:24px;
         display:flex;
         margin-left: 28px;
         flex-direction:row !important;
         gap:5px !important;
         padding-left:14px;
         margin-bottom: 12px !important;
        .input-span{
         display:flex;
         .calendar{
            position: relative;
            right: 27px;
            top: 32px;
            cursor:pointer;
         }
         &.isLogMealsModal {
            margin-bottom: -15px;
            padding: 0;
            .react-datepicker-wrapper {
              display: flex;
              align-items: center;
            }
            .current-date {
              color: #52C41A;
              font-weight: 400;
            }
          }
          &.notSelected .react-datepicker__month .react-datepicker__day--selected,
          &selected .react-datepicker__month .react-datepicker__day--today {
            color: #216ba5;
            font-weight: 400;
          }
          &.selected .react-datepicker__month .react-datepicker__day--selected,
          &.selected .react-datepicker__month .future-day.react-datepicker__day--selected,
          &.notSelected .react-datepicker__month .react-datepicker__day--today {
            color: #ffffff;
            font-weight: 700;
          }
          .react-datepicker__month .react-datepicker__day.future-day {
            background-color: #216ba5;
            color: #aaa;
          }
          .react-datepicker-wrapper {
            width: fit-content;
          }
          .current-date {
            color: #000000;
            font-size: 16px;
            font-family: Roboto;
            font-weight: 700;
            cursor: pointer;
          }
          .react-datepicker,
          .react-datepicker__month-container {
         
            font-family: "Helvetica Neue",helvetica,arial,sans-serif;
            font-size: .8rem;
            background-color: #fff;
            color: #000;
            border: 1px solid #aeaeae;
            border-radius: 0.3rem;
            display: inline-block;
         

          .react-datepicker__navigation {
            -webkit-align-items: center;
            align-items: center;
            background: none;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            position: absolute;
            top: 2px;
            padding: 0;
            border: none;
            z-index: 1;
            height: 32px;
            width: 32px;
            text-indent: -999em;
            overflow: hidden;
          }
          }
          .react-datepicker__navigation-icon--next {
            left: -10px;
          }
          .react-datepicker__navigation-icon--previous {
           left: 10px;
             justify-content: center;
          }
          .react-datepicker-popper {
            width: 300px;
            z-index: 100;

          
          }
          .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
            border-bottom-color: #fff;
          }

          
          .react-datepicker {
            border: 0;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
            transform: translate3d(-18%, 36px, 0px);

            .react-datepicker__triangle::before,
            .react-datepicker__triangle::after {
               border-bottom-color: #fff;
              transform: translate3d(5px, 0px, 0px);
            }

            
          .react-datepicker__header {
   
            text-align: center;
            background-color: #f0f0f0;
            border-bottom: 1px solid #aeaeae;
            border-top-left-radius: 0.3rem;
            padding: 8px 0;
            position: relative;
        }
            .react-datepicker__current-month {
              margin-top: 0;
              color: #000;
              font-weight: 700;
              font-size: .944rem;
            }
            .react-datepicker__day-names {
              margin-bottom: -15px;
              color: #51635d;
              font-size: 11px;
              font-weight: 400;
              line-height: 12px;
            }
          }
          .react-datepicker__day-name {
            width: 32px;
            height: 32px;
            margin: 5px;
          }
          .react-datepicker__navigation-icon::before {
            width: 8px;
            height: 8px;
           
          }
          .react-datepicker__navigation-icon::before {
            border-width: 2px 2px 0 0;
          }
          .react-datepicker__month {
            margin: 0;
            .react-datepicker__day,
            .react-datepicker__day--disabled,
            .react-datepicker__day--keyboard-selected,
            .react-datepicker__day--selected {
              width: 32px;
              height: 32px;
              margin: 5px;
             
              font-size: 14px;
              font-family: 'Roboto';
              line-height: 32px;
            }
            .react-datepicker__day,
            .react-datepicker__day--selected.react-datepicker__day--outside-month,
            .react-datepicker__day--keyboard-selected {
         
           
            }
            .react-datepicker__day--outside-month {
              visibility: hidden;
            }
          }
          .react-datepicker__navigation--previous {
            left: auto;
            // right: 60px;
          }
        }
        }
      .radio-div{
         padding-left: 60px;
         .first{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #51635D;
            margin-left:5px;
         }
      }

      .add-another{
         font-weight: 400;
         font-size: 10px;
         line-height: 12px;
         color: #949D9B;
         padding-left:48px;
         margin-top:15px;
         p{
            cursor:pointer;
            width: fit-content;
         } 
      }

      .publish{
         display:flex;
         justify-content:center;
         margin-top:24px;

         button{
            width: 124px;
            height: 40px;
            background: #52C41A;
            border-radius: 21px;
            display:flex;
            justify-content:center;
            align-items:center;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
            cursor:pointer;
         }

         button: disabled {
            background-color: #e0e3e0;
            color: #949d9b;
            cursor: not-allowed;
         }
      }
      
    }
    
 }
 
 @media(max-width:992px){

   .invite-box{
      width:100% !important;
      height:100%;
      border-radius:0 !important;
      padding-bottom:0 !important;
      
      .send-invite{
         display: flex;
         justify-content: center;
         padding-left:0 !important;
         color:#51635D !important;
         font-size: 18px !important;
         line-height: 24px;
      }

      .close-img-div{
         display:flex;
         justify-content:flex-start !important;
         padding: 20px 0px 0px 20px !important;
      }
   }
   .name-type{
      display:flex;
      flex-direction:column !important;
      align-items:center !important;
   }
   .name-type:nth-child(3){
      display:flex;
      flex-direction:row !important;
      .input-container{
         justify-content:flex-end !important;
      }
      .input-container:nth-child(2){
         justify-content:flex-start !important;
      }
   }
   .name-type:nth-child(4){
      display:flex;
      flex-direction:row !important;
      gap:16px !important;
      padding-left:0 !important;

      .input-span{
         display: -webkit-box !important;
         margin-right:2px;

         .input-container{
            justify-content:flex-end !important;
         }
      }
      .input-span:nth-child(2){
         margin-left:12px;
         .input-container{
            justify-content:flex-start !important;
         }
         .calendar{
            right:60px !important;
         }
      }
   }
   .amount-type{
      gap: 0px !important;
      padding: 0px 4px 0px 19px;
      margin-left: 0 !important;

      .calender {
      right: 28px !important;
      }

      
      .react-datepicker {
         left: 10px !important;
         display:initial !important;
         position: initial !important;
         .react-datepicker__triangle{
            display:none;
         }
      }
   }
   .date-type{
      display:flex;
      width: 211px;
      margin-left:0px !Important;
      .input-span{
         .calendar{
            right: 48px !important;
            top: 36px !important;
         }
         .react-datepicker {
            left: 5px !important;
            display:initial !important;
            position: initial !important;
            .react-datepicker__triangle{
               display:none;
            }
         }
      }
      
   }
   .coupon-form {
      height: calc(100vh - 200px);
       
      .multiple-form-div{
          height:auto !important;
          max-height: unset !important;

         .sc-hgIrPW{
            .input-container{
               div:nth-child(1){
                  width:100% !important;
               }
            }
         }
      }
      .input-container {
         width:100% !important;
         display: flex;
         justify-content: center;

         div:nth-child(1){
            width:80% !important;
         }
      }
      input{
         width:100% !important;
      }

      .selectWrapper{
         width:80% !important;

         .css-yk16xz-control, .css-xd1qmr-control{
            width:100% !important;
         }
      }
      .add-another{
         padding-left:40px !important;
      }

   }
   
   .publish{
      button{
         width:311px !important;
      }
   }
 }

`
export default function PromoCodeForm({ setIsModal, promoCodeInfo,setpromoCodeInfo, setPromocodes, promocodes }) {
   const { isMobile } = useAppContext();
   const [CreatePromoCode, { isLoading: isPromoCodeCreating }] = useRequest('promoCode/create', 'post');
   const [firstOrder, setFirstOrder] = useState(true);
   const [updatePromoCode, {isLoading: isPromoCodeUpdating}] = useRequest('promoCode/updatePromoCode', 'post');
   const [multiInputs, setMultiInputs] = useState([{ minOrderAmount: 0, maxOrderAmount: 0, discount: 0 }]);
   const parent = useRef(null);
   const { startDateISOString, endDateISOString } = useMemo(() => {
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setMonth(startDate.getMonth() + 6);
      const startDateISOString = startDate.toISOString();
      const endDateISOString = endDate.toISOString();
  
      return { startDateISOString, endDateISOString };
    }, []);

   const [form, setForm] = useState({
      code: "",
      discount: 0,
      minOrderAmount: 0,
      code_type: "",
      discount_type: "",
      data: {},
      validityStartDate: startDateISOString,
      validityEndDate: endDateISOString
   });
   
   useEffect(() => {
		parent.current && !isMobile && autoAnimate(parent.current);
	}, [parent])

   useEffect(() => {
      if (form?.code_type === "giftCard") {
        setForm((prevForm) => ({
          ...prevForm,
          discount_type: "flat",
          minOrderAmount:0,
          data: {...prevForm.data,
          maximumOrderLimit: 0,
          "usedCount": 0,
          "usedAmount": 0}
        }));
      }
    }, [form?.code_type]);
   useEffect(() => {
      if (form?.code_type !== "giftCard") {
      setForm(prevForm => ({
        ...prevForm,
        data: {
          ...prevForm?.data,
          maxUsedCount: multiInputs,
          firstOrderOnly: promoCodeInfo?.form?.data?.firstOrder
        }
      }));
   }
    }, [multiInputs,promoCodeInfo?.form?.data?.firstOrder]);

    useEffect(() => {
      if(promoCodeInfo?.id) {
        const { createdAt, updatedAt, isDeleted, orderMain, ...promoCodeData } = promoCodeInfo;
        setForm(promoCodeData);
        if (form?.code_type !== "giftCard") {
        setMultiInputs(promoCodeData?.data?.maxUsedCount);
        }
      }
    }, [promoCodeInfo]);

   const handleKeyPress = (e) => {
      const keyCode = e.which || e.keyCode;
      const keyValue = String.fromCharCode(keyCode);

      if (e.code === 'Minus' || keyValue.toLowerCase() === 'e') {
         e.preventDefault();
      }
   };

  const preventPasteNegative = (e) => {
   const clipboardData = e.clipboardData || window.clipboardData;
   const pastedData = parseFloat(clipboardData.getData('text'));

   if (pastedData < 0) {
       e.preventDefault();
   }
  };

   const existingCode = useMemo(() => {
      const isPromoCodeExist = promocodes.find(item => item.code === form.code);
      const isDuplicateCode = isPromoCodeExist && isPromoCodeExist?.id !== form?.id;
      
      if (isDuplicateCode) {
         toast.error("Duplicate coupon code");
         return isDuplicateCode;
      }
      return isDuplicateCode;
   }, [promocodes, form.code, form.id]);

  const handleChange = useCallback((e) => {
   const { name, value:nonTrimValue } = e.target;
   let value = nonTrimValue?.trim();
   value = value.replace(" ", "");

   if((name === "discount" ) && form.discount_type === "percentage" && value > 100){
      value = 100;
   }  
     const allowedProperties = ["maxOrderAmount", "minOrderAmount", "discount"];
     if (allowedProperties.includes(name)) {
        const decimalRegex = /^\d+(\.\d{1,2})?$/;
        if (!decimalRegex.test(value)) {
           value = parseFloat(value).toFixed(2);
        }
        if (value.length > 10) {
           return;
        }
     }

   if (promoCodeInfo) {
     const index = 0;
     const list = multiInputs?.slice() ?? [];
     list[index] = { ...list[index], [name]: Number(value?.trim()) };
     setMultiInputs(list);

     if (name === "maxOrderAmount") {
       setForm((prevState) => ({
         ...prevState,
         data: { ...prevState?.data, maximumOrderLimit:  Number(parseFloat(value?.trim()).toFixed(2)) || 0 },
       }));
     }  else if (name === "discount") {
       if (form.discount_type === "flat" && value.length > 10) {
         return;
       }
       setForm((prevState) => ({ ...prevState, discount: Number(parseFloat(value?.trim()).toFixed(2)) }));
     } else {
      setForm((prevState) => ({ ...prevState, [name]: value }));
     }
   } else {
      if(["maxOrderAmount", "minOrderAmount", "discount"].includes(name)){
         const index = 0;
         const list = multiInputs && [...multiInputs];
         list && (
            list[index][name] = Number(value)
         )
         setMultiInputs(list)
      }
      if (name === "maxOrderAmount") {
         setForm((prevState) => ({
           ...prevState,
           data: { ...prevState?.data, maximumOrderLimit: parseInt(value) || 0 },
         }));
         return;
      }
     setForm((prevState) => ({ ...prevState, [name]: value }));
   }
 }, [form, multiInputs, promoCodeInfo]);

   const handleClick = () => {
      CreatePromoCode({
         body: {
            ...form,
            discount: Number(parseFloat(form.discount).toFixed(2)),
            minOrderAmount: form.code_type === "giftCard" ? 0 : parseInt(form.minOrderAmount)
         }
      }).then((res) => {      
         if (res.success == true) {
            toast.success('code created successfully')
            setForm(res.result);
            setPromocodes([res.result, ...promocodes]);
            setIsModal(false)
         } else {
            toast.error(res.error)
         }
      })
         .catch((error) => toast.error("Something went wrong"))
   };
   const handleUpdate=()=>{
      updatePromoCode({
         body:{
            ...form,
            discount: Number(parseFloat(form.discount).toFixed(2)),
            minOrderAmount: form.code_type === "giftCard" ? 0 : parseInt(form.minOrderAmount)
         }
      }).then((res) => {
         if(res.success == true){
            toast.success('Code updated successfully')
            const updatedData=promocodes.map((item)=>{
               if(item.id===form.id){
                  return form;
               }else {
                  return item;
               }
            })
            setPromocodes(updatedData);
            setIsModal(false);  
            setpromoCodeInfo("")
         }else {
            toast.error(res.error)
         }
      })
        .catch((error) => toast.error("Something wrong"))
   }

   const handleFormClose =()=>{
      setIsModal(false);
      setpromoCodeInfo("");
   }

   const formatDate = (date) => {
      let formatedDate = null;
      if (date) formatedDate = moment(date).format('ll');
      return formatedDate;
   }

   const handleFirstOrderOnly = () => {
      setFirstOrder(!firstOrder);
      setForm({ ...form, data: { ...form.data, firstOrderOnly: firstOrder } })
   }


   const hasInvalidDiscount = useCallback((value) => {
      if (form?.discount_type === "percentage" && form.discount > 100) {
         return true;
      }
      if (form.discount_type === 'flat' && form.code_type !== 'giftCard') {
        if (value.discount > value?.maxOrderAmount && value.maxOrderAmount !== 0) {
          return true;
        }
      }
      if (value.minOrderAmount > value?.maxOrderAmount) {
         return true;
      }
      if (value.discount === 0) {
         return true;
      }
      return false;
   }, [multiInputs, form]);

   const handleDisableButton = useMemo(() => {
      return (
         !form?.code ||
         !form?.code_type ||
         !form?.discount_type ||
         !form?.discount ||
         isPromoCodeCreating ||
         isPromoCodeUpdating ||
         !form?.validityStartDate ||
         !form?.validityEndDate ||
         existingCode ||
         multiInputs?.length > 0 && multiInputs?.some(hasInvalidDiscount)
      )

   }, [
      form,
      isPromoCodeCreating,
      isPromoCodeUpdating,
      existingCode,
      multiInputs,
      hasInvalidDiscount
   ]);

   const DateInput = <span className='calendar'><img src='/icons/Calendarr.svg' /> </span>;

   const BpIcon = styled('span')(({ theme }) => ({
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
   }));
   const BpCheckedIcon = styled(BpIcon)({
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
         display: 'block',
         width: 14,
         height: 14,
         backgroundImage: "url('/icons/tick.svg')",
         backgroundRepeat: 'no-repeat',
         backgroundPosition: '50%',
         backgroundColor: 'radial-gradient(#fff,#fff,28%,transparent 32%)',
         border: '1px solid #52C41A',
         borderRadius: '50%',
         content: '""',

      },

   });

   const DropdownIndicator = ({ selectProps, ...props }) => {
      return (
        <div>
          {selectProps.menuIsOpen ? (
            <img style={{paddingRight: "11px", display: 'block'}} src='/icons/arrow-square-up.svg' alt='Open Arrow' />
          ) : (
            <img style={{paddingRight: "11px", display: 'block'}} className='image-close' src='/icons/arrow-square-down.svg' alt='Select Arrow' />
          )}
        </div>
      );
    };

   const options = [
      { label: 'Common', value: 'common' },
      { label: 'Specific', value: 'specific' },
      { label: 'Common Multi Use', value: 'commonMultiUse' },
      { label: 'Gift Card', value: 'giftCard'}
   ]
   const option = [
      { label: 'Flat', value: 'flat' },
      { label: 'Percentage', value: 'percentage' },
   ]
   const customStyles = {
      option: (provided, state) => ({
         ...provided,
         color: state.isSelected ? '#949D9B' : "#949D9B",
         backgroundColor: state.isFocused ? "#F6F5F8" : "#ffffff",
      })
   }

   const HandleEndDateChange = (endDate) => {
      let endDateISO = endDate.toISOString();
      let startDate = promoCodeInfo ? promoCodeInfo.validityStartDate : form.validityStartDate;
      if (endDateISO.split('T')[0] < startDate.split('T')[0]) {
         toast.error('End date should not be smaller than start date');
      }
      if (promoCodeInfo) {
        setpromoCodeInfo({
          ...form,
          validityEndDate: endDateISO
        });
      } else {
        setForm({
          ...form,
          validityEndDate: endDateISO
        });
      }

   }

   const HandleStartDateChange = (startDate) => {
      let startDateISO = startDate.toISOString();
      let endDate = promoCodeInfo ? promoCodeInfo.validityEndDate : form.validityEndDate;
      if (startDateISO > endDate) {
         if(promoCodeInfo){
            startDateISO = promoCodeInfo?.validityStartDate
         }
         else{
         startDateISO = endDate;
         }
         toast.error('Start date should be smaller than end date');
       }
      if (promoCodeInfo) {
         setpromoCodeInfo({
           ...form,
           validityStartDate: startDateISO
         });
       } else {
         setForm({
           ...form,
           validityStartDate: startDateISO
         });
       }

   }

   return (
      <PromoCodeModal>
         <div className='invite-box'>
            <div className='close-img-div'><img src={isMobile ? '/icons/close-cross-green.svg' : '/icons/close-circle3.svg'} alt='close-image' onClick={handleFormClose} /></div>
            <div className='send-invite'>
               {promoCodeInfo ? <p>Update promo code</p> : <p>Create a New coupon</p>}
            </div>
            <div className='coupon-form'>
               <div className='multiple-form-div' ref={parent}>
                  <div className='name-type'>
                     <Input
                        label="Coupon Name"
                        name="code"
                        value={form?.code}
                        onChange={handleChange}
                        bordered
                        type="text"
                        placeholder="for ex. gonutritt2022"
                        required
                     />
                     <div className="selectWrapper">
                        <label>
                           Coupon type <span style={{color:'red'}}>*</span>
                        </label>
                        <Select
                           theme={(theme) => ({
                              ...theme,
                              colors: {
                                 ...theme.colors,
                                 text: '#949D9B',
                                 primary25: '#E0E3E0',
                                 primary: '#E0E3E0',
                              },
                           })}
                           name="code_type"
                           components={{
                              DropdownIndicator,
                              IndicatorSeparator: () => null,
                           }}
                           className="invite-select-wrapper"
                           options={options}
                           placeholder={<div className="select-placeholder-text">{promoCodeInfo ?
                              promoCodeInfo?.code_type : "Select type"}</div>}
                           type="select"
                           bordered
                           required
                           isSearchable={false}
                           styles={customStyles}
                           onChange={(e) => {
                              setForm(prevState => ({
                                ...prevState,
                                code_type: e.value,
                              }));
                            }}   
                        />
                     </div>
                  </div>

                  <div className={form?.code_type==='giftCard'?'amount-type':"name-type"}>
                  {
                  form?.code_type==='giftCard'?" ":
                     <div className="selectWrapper">
                        <label>
                           Discount type <span style={{color:'red'}}>*</span>
                        </label>
                        <Select
                           theme={(theme) => ({
                              ...theme,
                              colors: {
                                 ...theme.colors,
                                 primary25: '#E0E3E0',
                                 primary: '#E0E3E0',
                              },
                           })}
                           name="discount_type"
                           components={{
                              DropdownIndicator,
                              IndicatorSeparator: () => null,
                           }}
                           className="invite-select-wrapper"
                           options={option}
                           placeholder={<div className="select-placeholder-text">{promoCodeInfo ?
                              promoCodeInfo.discount_type : "Select type"}</div>}
                           type="select"
                           bordered
                           required
                           styles={customStyles}
                           isSearchable={false}
                           onChange={(e) => { 
                              if(form?.discount > 100 && e.value === "percentage"){
                                 setForm((prevForm) => ({
                                    ...prevForm,
                                    discount_type: e.value,
                                    discount: 100,
                                    data: {
                                      ...prevForm.data,
                                      maxUsedCount: prevForm?.data?.maxUsedCount?.map((item) => ({
                                            ...item,
                                            discount: 100
                                       })),
                                    },
                                  }));   
                                  setMultiInputs((prevList) =>
                                  prevList.map((item) => ({
                                     ...item,
                                     discount: 100
                                  })))                   
                              } else {
                                 setForm({...form, discount_type: e.value }) }}
                              }
                        />
                     </div>
                     }
                     <Input
                        required
                        label="Amount"
                        name="discount"
                        value={form.discount}
                        onChange={handleChange}
                        bordered
                        type="Number"
                        placeholder="0"
                        onPaste={preventPasteNegative}
                        onKeyPress={handleKeyPress}
                        onWheel={(e) => e.target.blur()}
                     />
                    {form?.code_type === 'giftCard' ? (
                        <>
                           <Input
                              label="Validity starts"
                              name="validityStartDate"
                              value={promoCodeInfo ? formatDate(promoCodeInfo.validityStartDate) : formatDate(form.validityStartDate)}
                              onChange={handleChange}
                              bordered
                              type="text"
                              disabled
                              placeholder="Select date"
                              required
                           />
                           <DatePicker
                              minDate={new Date()}
                              selected={form?.validityStartDate ? new Date(form?.validityStartDate) : new Date()}
                              onChange={(date) => HandleStartDateChange(date)}
                              customInput={DateInput}
                           />
                        </>
                     ) : null}
                  </div>
                  {
                  form?.code_type==='giftCard'?" ":
                  <div className='name-type'>
                     <Input
                        label="Min order amount"
                        name="minOrderAmount"
                        value={form?.minOrderAmount}
                        onChange={handleChange}
                        bordered
                        type="Number"
                        placeholder="0"
                        onPaste={preventPasteNegative}
                        onKeyPress={handleKeyPress}
                        onWheel={(e) => e.target.blur()}
                     />
                     <Input
                        label="Max order amount"
                        name="maxOrderAmount"
                        value={form?.data?.maximumOrderLimit}
                        onChange={handleChange}
                        bordered
                        type="Number"
                        placeholder="0"
                        className='max-input'
                        onPaste={preventPasteNegative}
                        onKeyPress={handleKeyPress}
                        onWheel={(e) => e.target.blur()}
                     />
                  </div>
                  }
                  <div className={form?.code_type==='giftCard' ? "date-type" :"name-type"}>
                     {form?.code_type==='giftCard'?'':
                     <div className='input-span'>
                        <Input
                           label="Validity starts"
                           name="validityStartDate"
                           value={promoCodeInfo ? formatDate(promoCodeInfo.validityStartDate):formatDate(form.validityStartDate)}
                           onChange={handleChange}
                           bordered
                           type="text"
                           disabled
                           placeholder='Select date'
                           required
                        />
                        <DatePicker
                           minDate={new Date()}
                           selected={form?.validityStartDate ? new Date(form?.validityStartDate) : new Date()}
                           onChange={(date)=>HandleStartDateChange(date)}
                           customInput={DateInput}
                        />
                     </div>
                     }
                     {(isPromoCodeCreating || isPromoCodeUpdating) && <Progress />}
                     <div className='input-span'>
                        <Input
                           label="Validity ends"
                           name="validityEndDate"
                           value={promoCodeInfo ? formatDate(promoCodeInfo?.validityEndDate):formatDate(form.validityEndDate)}
                           onChange={handleChange}
                           bordered
                           type="text"
                           disabled
                           placeholder='Select date'
                           required
                        />
                        <DatePicker
                           minDate={new Date()}
                           selected={form?.validityEndDate ? new Date(form?.validityEndDate) : new Date()}
                           onChange={(date)=>HandleEndDateChange(date)}
                           customInput={DateInput}
                        />
                     </div>
                  </div>
                  {form?.code_type == "specific" && 
                  <div className='radio-div'>
                  <FormControl>
                     <RadioGroup row name="first-order">
                        <FormControlLabel
                           className='checkmark'
                           name="firstOrderOnly"
                           value={firstOrder}
                           checked={!firstOrder}
                           control={<Radio onClick={handleFirstOrderOnly}
                              checkedIcon={<BpCheckedIcon />} icon={<BpIcon />}
                           />}
                           label={<span className='first'>First order only</span>}
                        />
                     </RadioGroup>
                  </FormControl>
                  </div>
                  }
                  {(multiInputs && form?.code_type == "commonMultiUse") && multiInputs?.map((value, index) => {
                     if(index==0) return ;
                     return (
                        <PromoCodeMultipleForm
                           id={index}
                           index={index}
                           multiInputs={multiInputs}
                           setMultiInputs={setMultiInputs}
                           promoCodeInfo={promoCodeInfo}
                           preventPasteNegative={preventPasteNegative}
                           handleKeyPress={handleKeyPress}
                           setpromoCodeInfo={setpromoCodeInfo}
                           form={form}
                        />
                     )
                  }
                  )}
               </div>
               {(form?.code_type == "commonMultiUse") && <div className='add-another'>
                  <p onClick={() => {
                     if(promoCodeInfo.id){
                        const max = promoCodeInfo?.data?.maxUsedCount?.reduce((prev, current) => (prev.id > current.id) ? prev : current)
                        multiInputs && setMultiInputs([...multiInputs, { id:max.id+1,minOrderAmount: 0, maxOrderAmount: 0, discount: 0 }]);
                     }
                     else {
                        multiInputs && setMultiInputs([...multiInputs, { minOrderAmount: 0, maxOrderAmount: 0, discount: 0 }]);
                     window.scroll(0, 240)
                     }
                  }}>
                     + Add another order
                  </p>
               </div>
               }
               <div className='publish'>
               { promoCodeInfo ?<button onClick={handleUpdate} disabled={handleDisableButton}>Update</button> : <button onClick={handleClick} disabled={handleDisableButton}>Publish</button>}              
               </div>
            </div>
         </div>
      </PromoCodeModal>
   )
}